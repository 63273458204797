{
  "PR": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "PR",
          "stateFull": "Puerto Rico",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "PR",
            "stateFull": "Puerto Rico",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-PR---",
            "builtID": "pr--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-PR---": 0 }
    },
    "SENATE": {},
    "HOUSE": {
      "00": {
        "members": [
          {
            "API_ID": "G000582",
            "in_office": true,
            "firstName": "Jenniffer",
            "lastName": "González-Colón",
            "middleName": null,
            "fullName": "Jenniffer González-Colón",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000582.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "00",
              "chamber": "",
              "hashID": "HOUSE-PR-00--G000582",
              "builtID": "pr--00",
              "externalID": "G000582",
              "geometry": null,
              "geoid": "7298"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repjenniffer",
              "youtube": null,
              "instagram": null,
              "facebook": "RepJennifferGonzalezColon",
              "urls": ["https://gonzalez-colon.house.gov"],
              "rss_url": "https://gonzalez-colon.house.gov/rss.xml"
            },
            "title": "PR House Member",
            "short_title": "R.C.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000582.json",
            "govtrack_id": "412723",
            "cspan_id": "67353",
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00037615",
            "google_entity_id": "/m/027s3vf",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-08-05",
            "leadership_role": null,
            "fec_candidate_id": null,
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 499,
            "missed_votes": 143,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 28.66,
            "votes_with_party_pct": 61.78,
            "votes_against_party_pct": 37.93,
            "ocd_id": "ocd-division/country:us/state:pr/cd:80",
            "at_large": true
          }
        ],
        "hash": { "HOUSE-PR-00--G000582": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/929bc23b-ebba-42aa-8335-7ddca4fef705",
            "firstName": "Eddie",
            "lastName": "Charbonier Chinea",
            "fullName": "Eddie Charbonier",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/REP.-EDDIE-CHARBONIER-DIST-1-1.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-PR-1-lower-ocd-person-929bc23b-ebba-42aa-8335-7ddca4fef705",
              "builtID": "pr-lower-1",
              "externalID": "ocd-person/929bc23b-ebba-42aa-8335-7ddca4fef705",
              "geometry": null
            },
            "contactInfo": {
              "email": "echarbonier@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=191",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/eddie-charbonier-chinea/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-1-lower-ocd-person-929bc23b-ebba-42aa-8335-7ddca4fef705": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/7f1942e0-984a-4c6e-b9dd-33052b92b25d",
            "firstName": "Luis Raul",
            "lastName": "Torres Cruz",
            "fullName": "Luis Torres Cruz",
            "gender": "Male",
            "party": "independent",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/Rep._LUIS_RAUL_TORRES_DIST._2_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-PR-2-lower-ocd-person-7f1942e0-984a-4c6e-b9dd-33052b92b25d",
              "builtID": "pr-lower-2",
              "externalID": "ocd-person/7f1942e0-984a-4c6e-b9dd-33052b92b25d",
              "geometry": null
            },
            "contactInfo": {
              "email": "ltorres@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=8",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/luis-r-torres-cruz/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-2-lower-ocd-person-7f1942e0-984a-4c6e-b9dd-33052b92b25d": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/8cd266da-71f0-4b96-aa14-d88569c76176",
            "firstName": "Jose",
            "lastName": "Hernandez Concepcion",
            "fullName": "Cheíto Hernández",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/JOSE-CHEITO-HERNANDEZ-DIST-3.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-PR-3-lower-ocd-person-8cd266da-71f0-4b96-aa14-d88569c76176",
              "builtID": "pr-lower-3",
              "externalID": "ocd-person/8cd266da-71f0-4b96-aa14-d88569c76176",
              "geometry": null
            },
            "contactInfo": {
              "email": "jhernandez@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "jose.hernandezconcepcion",
              "urls": [
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/jose-cheito-hernandez/",
                "https://www.camara.pr.gov/team/jose-hernandez-concepcion/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-3-lower-ocd-person-8cd266da-71f0-4b96-aa14-d88569c76176": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/90109872-9f06-48fb-8118-25453deb69c7",
            "firstName": "Victor L.",
            "lastName": "Pares Otero",
            "fullName": "Víctor Parés",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._VICTOR_PARES_DIST_4_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-PR-4-lower-ocd-person-90109872-9f06-48fb-8118-25453deb69c7",
              "builtID": "pr-lower-4",
              "externalID": "ocd-person/90109872-9f06-48fb-8118-25453deb69c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "vpares@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=193",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/victor-l-pares-otero/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-4-lower-ocd-person-90109872-9f06-48fb-8118-25453deb69c7": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/4243af78-477d-4221-ad97-b22e662e86d5",
            "firstName": "Jorge Luis",
            "lastName": "Navarro Suarez",
            "fullName": "Jorge Navarro Suárez",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._JORGE_NAVARRO_DIST._5_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-PR-5-lower-ocd-person-4243af78-477d-4221-ad97-b22e662e86d5",
              "builtID": "pr-lower-5",
              "externalID": "ocd-person/4243af78-477d-4221-ad97-b22e662e86d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "jnavarro@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "jorgenavarro.51",
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=10",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/jorge-navarro-suarez/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-5-lower-ocd-person-4243af78-477d-4221-ad97-b22e662e86d5": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/e99c49b9-28da-46d9-9f6e-4c2ea65162a4",
            "firstName": "Angel",
            "lastName": "Morey Noble",
            "fullName": "Ángel Morey Noble",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._ANGEL_MOREY_DIST._6.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-PR-6-lower-ocd-person-e99c49b9-28da-46d9-9f6e-4c2ea65162a4",
              "builtID": "pr-lower-6",
              "externalID": "ocd-person/e99c49b9-28da-46d9-9f6e-4c2ea65162a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "amorey@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/angel-morey-noble/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-6-lower-ocd-person-e99c49b9-28da-46d9-9f6e-4c2ea65162a4": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/76b29cd3-7c7d-4362-90f3-0067ece6bdca",
            "firstName": "Luis",
            "lastName": "Perez Ortiz",
            "fullName": "Luis Pérez",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._LUIS_JR._PEREZ_DIST._7_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-PR-7-lower-ocd-person-76b29cd3-7c7d-4362-90f3-0067ece6bdca",
              "builtID": "pr-lower-7",
              "externalID": "ocd-person/76b29cd3-7c7d-4362-90f3-0067ece6bdca",
              "geometry": null
            },
            "contactInfo": {
              "email": "lperez@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "jrperezortiz",
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=12",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/luis-perez-ortiz/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-7-lower-ocd-person-76b29cd3-7c7d-4362-90f3-0067ece6bdca": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/8d832fa0-e534-4a0e-ad30-0251f23c8ae5",
            "firstName": "Yashira M.",
            "lastName": "Lebron Rodriguez",
            "fullName": "Yashira Lebrón",
            "gender": "Female",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/REP.-YASHIRA-LEBRON-DIST.-8-1.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-PR-8-lower-ocd-person-8d832fa0-e534-4a0e-ad30-0251f23c8ae5",
              "builtID": "pr-lower-8",
              "externalID": "ocd-person/8d832fa0-e534-4a0e-ad30-0251f23c8ae5",
              "geometry": null
            },
            "contactInfo": {
              "email": "ylebron@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepresentanteYashiraLebron",
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=185",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/yashira-lebron-rodriguez/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-8-lower-ocd-person-8d832fa0-e534-4a0e-ad30-0251f23c8ae5": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/42788383-8253-4e73-95da-134b911edc69",
            "firstName": "Er Yazzer",
            "lastName": "Morales Diaz",
            "fullName": "Yazzer Morales",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/REP.-YAZZER-MORALES-DIST-9-1.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-PR-9-lower-ocd-person-42788383-8253-4e73-95da-134b911edc69",
              "builtID": "pr-lower-9",
              "externalID": "ocd-person/42788383-8253-4e73-95da-134b911edc69",
              "geometry": null
            },
            "contactInfo": {
              "email": "ermorales@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=719",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/er-yazzer-morales-diaz/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-9-lower-ocd-person-42788383-8253-4e73-95da-134b911edc69": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/7408df66-3e8f-45a1-9844-aa6b1add8c60",
            "firstName": "Deborah",
            "lastName": "Soto Arroyo",
            "fullName": "Deborah Soto Arroyo",
            "gender": "Female",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._DEBORAH_SOTO_DIST._10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-PR-10-lower-ocd-person-7408df66-3e8f-45a1-9844-aa6b1add8c60",
              "builtID": "pr-lower-10",
              "externalID": "ocd-person/7408df66-3e8f-45a1-9844-aa6b1add8c60",
              "geometry": null
            },
            "contactInfo": {
              "email": "dsoto@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "debbie.soto.1000",
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=720",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/deborah-soto-arroyo/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-10-lower-ocd-person-7408df66-3e8f-45a1-9844-aa6b1add8c60": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/ff6cf822-1916-4aec-ae06-8574ee9e9b49",
            "firstName": "Rafael",
            "lastName": "Hernandez Montanez",
            "fullName": "Tatito Hernández",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/PRESIDENTE_DIST._11.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-PR-11-lower-ocd-person-ff6cf822-1916-4aec-ae06-8574ee9e9b49",
              "builtID": "pr-lower-11",
              "externalID": "ocd-person/ff6cf822-1916-4aec-ae06-8574ee9e9b49",
              "geometry": null
            },
            "contactInfo": {
              "email": "presidencia@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "tatitohdz",
              "youtube": null,
              "instagram": null,
              "facebook": "tatitohdz",
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=50",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/rafael-hernandez-montanez/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-11-lower-ocd-person-ff6cf822-1916-4aec-ae06-8574ee9e9b49": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/43fcbc03-037f-4ecf-916f-6a5925692a79",
            "firstName": "Edgardo",
            "lastName": "Feliciano Sanchez",
            "fullName": "Edgardo Feliciano",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._EDGARDO_FELICIANO_DIST_12_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-PR-12-lower-ocd-person-43fcbc03-037f-4ecf-916f-6a5925692a79",
              "builtID": "pr-lower-12",
              "externalID": "ocd-person/43fcbc03-037f-4ecf-916f-6a5925692a79",
              "geometry": null
            },
            "contactInfo": {
              "email": "efeliciano@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "FelicianoEdg",
              "youtube": null,
              "instagram": null,
              "facebook": "Edgardo-Feliciano-Representante-Distrito-12-2100072300291099",
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=722",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/edgardo-feliciano-sanchez/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-12-lower-ocd-person-43fcbc03-037f-4ecf-916f-6a5925692a79": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/3916fe08-dc43-497a-a286-ffafc0b5776b",
            "firstName": "Gabriel",
            "lastName": "Rodriguez Aguilo",
            "fullName": "Gabriel Rodríguez Aguiló",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._GABRIEL_RODRIGUEZ_AGUILO_DIST_13_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-PR-13-lower-ocd-person-3916fe08-dc43-497a-a286-ffafc0b5776b",
              "builtID": "pr-lower-13",
              "externalID": "ocd-person/3916fe08-dc43-497a-a286-ffafc0b5776b",
              "geometry": null
            },
            "contactInfo": {
              "email": "gfrodriguez@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "gabriel.rodriguezaguilo",
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=17",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/gabriel-rodriguez-aguilo/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-13-lower-ocd-person-3916fe08-dc43-497a-a286-ffafc0b5776b": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/6f7d7eb2-7dcf-41df-b412-93aaf0f6bd27",
            "firstName": "Jose O.",
            "lastName": "Gonzalez Mercado",
            "fullName": "Memo González",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/REP.-JOSE-MEMO-GONZALEZ-DIST-14-1.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-PR-14-lower-ocd-person-6f7d7eb2-7dcf-41df-b412-93aaf0f6bd27",
              "builtID": "pr-lower-14",
              "externalID": "ocd-person/6f7d7eb2-7dcf-41df-b412-93aaf0f6bd27",
              "geometry": null
            },
            "contactInfo": {
              "email": "jogonzalez@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=212",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/jose-o-gonzalez-mercado/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-14-lower-ocd-person-6f7d7eb2-7dcf-41df-b412-93aaf0f6bd27": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/5bc0f1ac-d5bf-4915-be70-fec1a6693174",
            "firstName": "Joel I.",
            "lastName": "Franqui Atiles",
            "fullName": "Joel Franqui Atiles",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/REP.-JOEL-FRANQUI-ATILES-DIST.-15-1.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-PR-15-lower-ocd-person-5bc0f1ac-d5bf-4915-be70-fec1a6693174",
              "builtID": "pr-lower-15",
              "externalID": "ocd-person/5bc0f1ac-d5bf-4915-be70-fec1a6693174",
              "geometry": null
            },
            "contactInfo": {
              "email": "jfranqui@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=196",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/joel-i-franqui-atiles/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-15-lower-ocd-person-5bc0f1ac-d5bf-4915-be70-fec1a6693174": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/48b0040b-5345-4ef0-aa27-e68e38b29131",
            "firstName": "Eladio J.",
            "lastName": "Cardona Quiles",
            "fullName": "Layito Cardona",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._ELADIO_LAYITO_CARDONA_DIST_16_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-PR-16-lower-ocd-person-48b0040b-5345-4ef0-aa27-e68e38b29131",
              "builtID": "pr-lower-16",
              "externalID": "ocd-person/48b0040b-5345-4ef0-aa27-e68e38b29131",
              "geometry": null
            },
            "contactInfo": {
              "email": "ecardona@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "Eladio-Layito-Cardona-159854527917617",
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=726",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/eladio-j-cardona-quiles/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-16-lower-ocd-person-48b0040b-5345-4ef0-aa27-e68e38b29131": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/a8c74dd2-a9e8-44f3-9398-4b8b08f2f064",
            "firstName": "Wilson Javier",
            "lastName": "Roman Lopez",
            "fullName": "Wilson Román",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/Wilson_Roman_420px.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-PR-17-lower-ocd-person-a8c74dd2-a9e8-44f3-9398-4b8b08f2f064",
              "builtID": "pr-lower-17",
              "externalID": "ocd-person/a8c74dd2-a9e8-44f3-9398-4b8b08f2f064",
              "geometry": null
            },
            "contactInfo": {
              "email": "wroman@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=231",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/wilson-j-roman-lopez/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-17-lower-ocd-person-a8c74dd2-a9e8-44f3-9398-4b8b08f2f064": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/00a3deb7-1213-488b-a338-7b543c042153",
            "firstName": "Jessie",
            "lastName": "Cortes Ramos",
            "fullName": "Jessie Cortés Ramos",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._JESSIE_CORTES_DIST_18_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-PR-18-lower-ocd-person-00a3deb7-1213-488b-a338-7b543c042153",
              "builtID": "pr-lower-18",
              "externalID": "ocd-person/00a3deb7-1213-488b-a338-7b543c042153",
              "geometry": null
            },
            "contactInfo": {
              "email": "jcortes@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=728",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/jessie-cortes-ramos/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-18-lower-ocd-person-00a3deb7-1213-488b-a338-7b543c042153": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/e0600ac5-be13-4718-a006-71b87027cd7d",
            "firstName": "Jocelyne M.",
            "lastName": "Rodriguez Negron",
            "fullName": "Jocelyne Rodríguez Negrón",
            "gender": "Female",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/REP.-JOCELYNE-RODRIGUEZ-DIST-19-1-1.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-PR-19-lower-ocd-person-e0600ac5-be13-4718-a006-71b87027cd7d",
              "builtID": "pr-lower-19",
              "externalID": "ocd-person/e0600ac5-be13-4718-a006-71b87027cd7d",
              "geometry": null
            },
            "contactInfo": {
              "email": "jmrodriguez@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "JocelyneRodriguezNegron",
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=729",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/jocelyne-m-rodriguez-negron/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-19-lower-ocd-person-e0600ac5-be13-4718-a006-71b87027cd7d": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/637253b8-3a75-4af1-80bd-de83a5dd0269",
            "firstName": "Joel",
            "lastName": "Sanchez Ayala",
            "fullName": "Joel Sánchez Ayala",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/JOEL-SANCHEZ-AYALA.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-PR-20-lower-ocd-person-637253b8-3a75-4af1-80bd-de83a5dd0269",
              "builtID": "pr-lower-20",
              "externalID": "ocd-person/637253b8-3a75-4af1-80bd-de83a5dd0269",
              "geometry": null
            },
            "contactInfo": {
              "email": "joelsanchez@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/joel-sanchez-ayala/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-20-lower-ocd-person-637253b8-3a75-4af1-80bd-de83a5dd0269": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/df7ddf47-3b00-4d0c-94ce-d7bce284ea5c",
            "firstName": "Lydia R.",
            "lastName": "Mendez Silva",
            "fullName": "Lydia Méndez Silva",
            "gender": "Female",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP.-LYDIA-MENDEZ1-DIST-21.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-PR-21-lower-ocd-person-df7ddf47-3b00-4d0c-94ce-d7bce284ea5c",
              "builtID": "pr-lower-21",
              "externalID": "ocd-person/df7ddf47-3b00-4d0c-94ce-d7bce284ea5c",
              "geometry": null
            },
            "contactInfo": {
              "email": "lmendez@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "lydia.representante",
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=24",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/lydia-mendez-silva/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-21-lower-ocd-person-df7ddf47-3b00-4d0c-94ce-d7bce284ea5c": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/a600d7fd-3e2a-4148-aabf-c805b436db43",
            "firstName": "Jorge Alfredo",
            "lastName": "Rivera Segarra",
            "fullName": "Jorge Alfredo Rivera Segarra",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._JORGE_ALFREDO_RIVERA_DIST_22_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-PR-22-lower-ocd-person-a600d7fd-3e2a-4148-aabf-c805b436db43",
              "builtID": "pr-lower-22",
              "externalID": "ocd-person/a600d7fd-3e2a-4148-aabf-c805b436db43",
              "geometry": null
            },
            "contactInfo": {
              "email": "jsegarra@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=732",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/jorge-a-rivera-segarra/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-22-lower-ocd-person-a600d7fd-3e2a-4148-aabf-c805b436db43": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/92012861-cfe9-4dfa-9811-6e4302d01435",
            "firstName": "Jose H.",
            "lastName": "Rivera Madera",
            "fullName": "Cheito Rivera Madera",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/Rivera_Madera_420px.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-PR-23-lower-ocd-person-92012861-cfe9-4dfa-9811-6e4302d01435",
              "builtID": "pr-lower-23",
              "externalID": "ocd-person/92012861-cfe9-4dfa-9811-6e4302d01435",
              "geometry": null
            },
            "contactInfo": {
              "email": "johriveram@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "CheitoRiveraMad",
              "youtube": null,
              "instagram": null,
              "facebook": "CheitoRiveraMadera",
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=733",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/jose-h-rivera-madera/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-23-lower-ocd-person-92012861-cfe9-4dfa-9811-6e4302d01435": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/4c98369a-5291-4b17-90da-e967d5d92657",
            "firstName": "Angel A.",
            "lastName": "Fourquet Cordero",
            "fullName": "Ángel Fourquet",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._ANGEL_FOURQUET_DIST._24_1_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-PR-24-lower-ocd-person-4c98369a-5291-4b17-90da-e967d5d92657",
              "builtID": "pr-lower-24",
              "externalID": "ocd-person/4c98369a-5291-4b17-90da-e967d5d92657",
              "geometry": null
            },
            "contactInfo": {
              "email": "afourquet@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepTitoFourquet",
              "youtube": null,
              "instagram": "angelfourquet/?hl=es",
              "facebook": "angeltito.fourquetcordero",
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=734",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/angel-a-fourquet-cordero/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-24-lower-ocd-person-4c98369a-5291-4b17-90da-e967d5d92657": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/0bb123e5-c41d-4fb4-a6ee-7efb453d245a",
            "firstName": "Domingo J.",
            "lastName": "Torres Garcia",
            "fullName": "Domingo Torres García",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._DOMINGO_TORRES_DIST._25_6_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-PR-25-lower-ocd-person-0bb123e5-c41d-4fb4-a6ee-7efb453d245a",
              "builtID": "pr-lower-25",
              "externalID": "ocd-person/0bb123e5-c41d-4fb4-a6ee-7efb453d245a",
              "geometry": null
            },
            "contactInfo": {
              "email": "djtorres@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "djtorresgarcia",
              "youtube": null,
              "instagram": null,
              "facebook": "domingotorres25",
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=735",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/domingo-j-torres-garcia/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-25-lower-ocd-person-0bb123e5-c41d-4fb4-a6ee-7efb453d245a": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/2dac9b09-eb22-4f67-a1a7-d9de6980b39f",
            "firstName": "Jesus A.",
            "lastName": "Hernandez Arroyo",
            "fullName": "Jesús Hernández Arroyo",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/REP.-JESUS-HERNANDEZ-DIST.-26.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-PR-26-lower-ocd-person-2dac9b09-eb22-4f67-a1a7-d9de6980b39f",
              "builtID": "pr-lower-26",
              "externalID": "ocd-person/2dac9b09-eb22-4f67-a1a7-d9de6980b39f",
              "geometry": null
            },
            "contactInfo": {
              "email": "jehernandez@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/jesus-hernandez-arroyo/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-26-lower-ocd-person-2dac9b09-eb22-4f67-a1a7-d9de6980b39f": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/a5b90d78-5c49-42e3-bc1c-44e6bb6cb85d",
            "firstName": "Estrella",
            "lastName": "Martinez Soto",
            "fullName": "Estrella Martínez Soto",
            "gender": "Female",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._ESTRELLA_MARTINEZ_DIST_27_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-PR-27-lower-ocd-person-a5b90d78-5c49-42e3-bc1c-44e6bb6cb85d",
              "builtID": "pr-lower-27",
              "externalID": "ocd-person/a5b90d78-5c49-42e3-bc1c-44e6bb6cb85d",
              "geometry": null
            },
            "contactInfo": {
              "email": "emartinez@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "EstrellaRepresentanteD27",
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=737",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/estrella-martinez-soto/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-27-lower-ocd-person-a5b90d78-5c49-42e3-bc1c-44e6bb6cb85d": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/31630aa4-8a2a-400b-b49f-3ff341e79fc6",
            "firstName": "Juan Jose",
            "lastName": "Santiago Nieves",
            "fullName": "Juan José Santiago Nieves",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/REP.-JUAN-JOSE-SANTIAGO-DIST.-28-1.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-PR-28-lower-ocd-person-31630aa4-8a2a-400b-b49f-3ff341e79fc6",
              "builtID": "pr-lower-28",
              "externalID": "ocd-person/31630aa4-8a2a-400b-b49f-3ff341e79fc6",
              "geometry": null
            },
            "contactInfo": {
              "email": "jjsantiago@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "juanjosedistrito28",
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=738",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/juan-j-santiago-nieves/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-28-lower-ocd-person-31630aa4-8a2a-400b-b49f-3ff341e79fc6": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/c084494a-9915-4ae7-b755-92c226472145",
            "firstName": "Luis R.",
            "lastName": "Ortiz Lugo",
            "fullName": "Narmito Ortiz Lugo",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._LUIS_NARMITO_ORTIZ_LUGO_DIST_30_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-PR-30-lower-ocd-person-c084494a-9915-4ae7-b755-92c226472145",
              "builtID": "pr-lower-30",
              "externalID": "ocd-person/c084494a-9915-4ae7-b755-92c226472145",
              "geometry": null
            },
            "contactInfo": {
              "email": "ortizlugo@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room Primer Piso, El Capitolio P.O. Box 9022228, San Juan, PR 00902",
              "phone1": null,
              "phone2": "787-721-5545",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=90",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/luis-r-ortiz-lugo/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-30-lower-ocd-person-c084494a-9915-4ae7-b755-92c226472145": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/80462691-ac86-4972-b729-feb82ff3ee37",
            "firstName": "Jesus F.",
            "lastName": "Santa Rodriguez",
            "fullName": "Jesús Santa Rodríguez",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._JESUS_SANTA_DIST_31_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-PR-31-lower-ocd-person-80462691-ac86-4972-b729-feb82ff3ee37",
              "builtID": "pr-lower-31",
              "externalID": "ocd-person/80462691-ac86-4972-b729-feb82ff3ee37",
              "geometry": null
            },
            "contactInfo": {
              "email": "jsanta@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "rep_jesus_santa",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=81",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/jesus-santa-rodriguez/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-31-lower-ocd-person-80462691-ac86-4972-b729-feb82ff3ee37": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/a6de4da4-59cb-4214-8753-8b92683f3df4",
            "firstName": "Jose Manuel",
            "lastName": "Varela Fernandez",
            "fullName": "Conny Varela",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP.-JOSE-CONNY-VARELA-DIST.-32-1.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-PR-32-lower-ocd-person-a6de4da4-59cb-4214-8753-8b92683f3df4",
              "builtID": "pr-lower-32",
              "externalID": "ocd-person/a6de4da4-59cb-4214-8753-8b92683f3df4",
              "geometry": null
            },
            "contactInfo": {
              "email": "jvarela@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "connyvarelarep",
              "youtube": null,
              "instagram": "connyvarelarep/",
              "facebook": "conny.representante",
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=35",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/jose-varela/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-32-lower-ocd-person-a6de4da4-59cb-4214-8753-8b92683f3df4": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/d32dffc7-d1f4-41fe-80a5-f1581d34d090",
            "firstName": "Angel R.",
            "lastName": "Pena Ramirez",
            "fullName": "Ángel Peña Ramírez",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._ANGEL_PENA_DIST._33.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-PR-33-lower-ocd-person-d32dffc7-d1f4-41fe-80a5-f1581d34d090",
              "builtID": "pr-lower-33",
              "externalID": "ocd-person/d32dffc7-d1f4-41fe-80a5-f1581d34d090",
              "geometry": null
            },
            "contactInfo": {
              "email": "anpena@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "angelpenajr",
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=27",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/angel-r-pena-ramirez/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-33-lower-ocd-person-d32dffc7-d1f4-41fe-80a5-f1581d34d090": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/ddb82ffc-8b5e-4bd1-8a40-3e51c789ced6",
            "firstName": "Ramon Luis",
            "lastName": "Cruz Burgos",
            "fullName": "Ramón Luis Cruz Burgos",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/REP.-RAMON-CRUZ-BURGOS-DIST-34-1.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-PR-34-lower-ocd-person-ddb82ffc-8b5e-4bd1-8a40-3e51c789ced6",
              "builtID": "pr-lower-34",
              "externalID": "ocd-person/ddb82ffc-8b5e-4bd1-8a40-3e51c789ced6",
              "geometry": null
            },
            "contactInfo": {
              "email": "rcruz@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "ramonluispr",
              "youtube": null,
              "instagram": "ramonluispr/",
              "facebook": "ramonluispr",
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=102",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/ramon-l-cruz-burgos/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-34-lower-ocd-person-ddb82ffc-8b5e-4bd1-8a40-3e51c789ced6": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/18257138-221f-4901-89d3-948b5c914950",
            "firstName": "Sol Yamiz",
            "lastName": "Higgins Cuadrado",
            "fullName": "Sol Higgins",
            "gender": "Female",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._SOL_HIGGINS_CUADRADO_DIST_35_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-PR-35-lower-ocd-person-18257138-221f-4901-89d3-948b5c914950",
              "builtID": "pr-lower-35",
              "externalID": "ocd-person/18257138-221f-4901-89d3-948b5c914950",
              "geometry": null
            },
            "contactInfo": {
              "email": "shiggins@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=745",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/sol-y-higgins-cuadrado/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-35-lower-ocd-person-18257138-221f-4901-89d3-948b5c914950": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/668ff3c6-68b5-4c95-b982-a03fd9c8b1a3",
            "firstName": "Carlos",
            "lastName": "Mendez Nunez",
            "fullName": "Johnny Méndez Núñez",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/Carlos_Mendez_420px.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-PR-36-lower-ocd-person-668ff3c6-68b5-4c95-b982-a03fd9c8b1a3",
              "builtID": "pr-lower-36",
              "externalID": "ocd-person/668ff3c6-68b5-4c95-b982-a03fd9c8b1a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "cmendez@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=39",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/carlos-johnny-mendez-nunez/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-36-lower-ocd-person-668ff3c6-68b5-4c95-b982-a03fd9c8b1a3": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/dbfd900c-4b48-4805-b60a-1c3acfc9ecca",
            "firstName": "Angel L.",
            "lastName": "Bulerin Ramos",
            "fullName": "Angel Bulerín Ramos",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._ANGEL_BULERIN_DIST._37.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-PR-37-lower-ocd-person-dbfd900c-4b48-4805-b60a-1c3acfc9ecca",
              "builtID": "pr-lower-37",
              "externalID": "ocd-person/dbfd900c-4b48-4805-b60a-1c3acfc9ecca",
              "geometry": null
            },
            "contactInfo": {
              "email": "abulerin@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=40",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/angel-bulerin-ramos/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-37-lower-ocd-person-dbfd900c-4b48-4805-b60a-1c3acfc9ecca": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/ebbc5719-4209-4c46-9ae5-19f0753e1259",
            "firstName": "Wanda",
            "lastName": "Del Valle Correa",
            "fullName": "Wanda Del Valle Correa",
            "gender": "Female",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/REP.-WANDA-DEL-VALLE-DIST-38-1.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-PR-38-lower-ocd-person-ebbc5719-4209-4c46-9ae5-19f0753e1259",
              "builtID": "pr-lower-38",
              "externalID": "ocd-person/ebbc5719-4209-4c46-9ae5-19f0753e1259",
              "geometry": null
            },
            "contactInfo": {
              "email": "wdelvalle@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=748",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/wanda-del-valle-correa/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-38-lower-ocd-person-ebbc5719-4209-4c46-9ae5-19f0753e1259": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/c93982f8-324b-4201-9666-92f60f890817",
            "firstName": "Roberto",
            "lastName": "Rivera Ruiz de Porras",
            "fullName": "Roberto Rivera Ruiz de Porras",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._ROBERTO_RIVERA_DIST_39_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-PR-39-lower-ocd-person-c93982f8-324b-4201-9666-92f60f890817",
              "builtID": "pr-lower-39",
              "externalID": "ocd-person/c93982f8-324b-4201-9666-92f60f890817",
              "geometry": null
            },
            "contactInfo": {
              "email": "rrivera@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.camaraderepresentantes.org/cr_legs.asp",
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=42",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/roberto-rivera-ruiz/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-39-lower-ocd-person-c93982f8-324b-4201-9666-92f60f890817": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/b86bed36-d8ce-4c24-8542-4d3c49d7e2be",
            "firstName": "Angel N.",
            "lastName": "Matos Garcia",
            "fullName": "Angel Matos García",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/ANGEL-MATOS-DIST40.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-PR-40-lower-ocd-person-b86bed36-d8ce-4c24-8542-4d3c49d7e2be",
              "builtID": "pr-lower-40",
              "externalID": "ocd-person/b86bed36-d8ce-4c24-8542-4d3c49d7e2be",
              "geometry": null
            },
            "contactInfo": {
              "email": "amatos@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "angelmatos",
              "youtube": null,
              "instagram": null,
              "facebook": "angelnmatos",
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=66",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/angel-matos/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-40-lower-ocd-person-b86bed36-d8ce-4c24-8542-4d3c49d7e2be": 0
        }
      },
      "At Large": {
        "members": [
          {
            "API_ID": "ocd-person/dee6d0ff-ee3c-429c-bfa4-6b255acd8472",
            "firstName": "Denis",
            "lastName": "Marquez Lebron",
            "fullName": "Denis Márquez",
            "gender": "Male",
            "party": "partido independentista puertorriqueño",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/REP.-DENNIS-MARQUEZ-ACUMULACION-2.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "lower",
              "hashID": "SLDL-PR-At Large-lower-ocd-person-dee6d0ff-ee3c-429c-bfa4-6b255acd8472",
              "builtID": "pr-lower-At-Large",
              "externalID": "ocd-person/dee6d0ff-ee3c-429c-bfa4-6b255acd8472",
              "geometry": null
            },
            "contactInfo": {
              "email": "dmarquez@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "denismarquezpip",
              "youtube": null,
              "instagram": null,
              "facebook": "denismarquezpip",
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=210",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/denis-marquez-lebron/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0c318d9b-2b74-42c7-8ec2-7c89ee41955e",
            "firstName": "Hector Enrique",
            "lastName": "Ferrer Santiago",
            "fullName": "Héctor Ferrer",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._HECTOR_FERRER_JR._ACUMULACION_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "lower",
              "hashID": "SLDL-PR-At Large-lower-ocd-person-0c318d9b-2b74-42c7-8ec2-7c89ee41955e",
              "builtID": "pr-lower-At-Large",
              "externalID": "ocd-person/0c318d9b-2b74-42c7-8ec2-7c89ee41955e",
              "geometry": null
            },
            "contactInfo": {
              "email": "hferrer@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "HectorFerrerPR",
              "youtube": null,
              "instagram": "hectorferrerpr/",
              "facebook": "HectorFerrerPR",
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=751",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/hector-e-ferrer-santiago/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c9361851-f74e-468c-9c2f-7d802ca50ea0",
            "firstName": "Jesus Manuel",
            "lastName": "Ortiz Gonzalez",
            "fullName": "Jesús Manuel Ortiz",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._JESUS_MANUEL_ORTIZ_ACUMULACION_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "lower",
              "hashID": "SLDL-PR-At Large-lower-ocd-person-c9361851-f74e-468c-9c2f-7d802ca50ea0",
              "builtID": "pr-lower-At-Large",
              "externalID": "ocd-person/c9361851-f74e-468c-9c2f-7d802ca50ea0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jortiz@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "jmanuelortiz",
              "youtube": null,
              "instagram": "jmortizpr/",
              "facebook": "jesusmanuelortizpr",
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=209",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/jesus-ortiz-gonzalez/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/51a95b4d-0006-49e8-83ae-6c139a1d5b1b",
            "firstName": "Jose Bernardo",
            "lastName": "Marquez Reyes",
            "fullName": "José Bernardo Márquez",
            "gender": "Male",
            "party": "movimiento victoria ciudadana",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._JOSE_BERNARDO_MARQUEZ_ACUMULACION_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "lower",
              "hashID": "SLDL-PR-At Large-lower-ocd-person-51a95b4d-0006-49e8-83ae-6c139a1d5b1b",
              "builtID": "pr-lower-At-Large",
              "externalID": "ocd-person/51a95b4d-0006-49e8-83ae-6c139a1d5b1b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jmarquez@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "josebernardo_pr",
              "youtube": null,
              "instagram": "josebernardopr/",
              "facebook": "josebernardopr",
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=758",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/jose-b-marquez-reyes/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1913492c-2726-4c58-aaba-4dfe55a34bc2",
            "firstName": "Jose Enrique",
            "lastName": "Melendez Ortiz",
            "fullName": "Quiquito Meléndez",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/REP.-JOSE-ENRIQUE-MELENDEZ-ACUMULACION-1.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "lower",
              "hashID": "SLDL-PR-At Large-lower-ocd-person-1913492c-2726-4c58-aaba-4dfe55a34bc2",
              "builtID": "pr-lower-At-Large",
              "externalID": "ocd-person/1913492c-2726-4c58-aaba-4dfe55a34bc2",
              "geometry": null
            },
            "contactInfo": {
              "email": "jem@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=4",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/jose-e-melendez-ortiz/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1ca4e7f3-fe25-4412-abe0-eba62eda6d1a",
            "firstName": "Jose Ernesto",
            "lastName": "Torres Zamora",
            "fullName": "Pichy Torres Zamora",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._JOSE_PICHY_TORRES_ZAMORA_ACUMULACION_2_20.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "lower",
              "hashID": "SLDL-PR-At Large-lower-ocd-person-1ca4e7f3-fe25-4412-abe0-eba62eda6d1a",
              "builtID": "pr-lower-At-Large",
              "externalID": "ocd-person/1ca4e7f3-fe25-4412-abe0-eba62eda6d1a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jotorres@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=190",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/jose-e-torres-zamora/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/2cdd7282-81fe-4c07-b50a-b162a16c4918",
            "firstName": "Jose Fernando",
            "lastName": "Aponte Hernandez",
            "fullName": "José Aponte Hernández",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._JOSE_APONTE_HERNADEZ_ACUMULACION_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "lower",
              "hashID": "SLDL-PR-At Large-lower-ocd-person-2cdd7282-81fe-4c07-b50a-b162a16c4918",
              "builtID": "pr-lower-At-Large",
              "externalID": "ocd-person/2cdd7282-81fe-4c07-b50a-b162a16c4918",
              "geometry": null
            },
            "contactInfo": {
              "email": "japonte@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=30",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/jose-f-aponte-hernandez/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/65f91ca5-2401-4bd4-b146-79b08b12fad6",
            "firstName": "Jose J.",
            "lastName": "Perez Cordero",
            "fullName": "José Pérez Cordero",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/REP.-JOSE-CHE-PEREZ-ACUMULACION-1.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "lower",
              "hashID": "SLDL-PR-At Large-lower-ocd-person-65f91ca5-2401-4bd4-b146-79b08b12fad6",
              "builtID": "pr-lower-At-Large",
              "externalID": "ocd-person/65f91ca5-2401-4bd4-b146-79b08b12fad6",
              "geometry": null
            },
            "contactInfo": {
              "email": "joperez@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/jose-j-perez-cordero/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/05dee06f-a941-43cc-a4ce-fef9f3132784",
            "firstName": "Lisie Janet",
            "lastName": "Burgos Muniz",
            "fullName": "Lisie Burgos",
            "gender": "Female",
            "party": "proyecto dignidad",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._LISIE_J._BURGOS_ACUMULACION_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "lower",
              "hashID": "SLDL-PR-At Large-lower-ocd-person-05dee06f-a941-43cc-a4ce-fef9f3132784",
              "builtID": "pr-lower-At-Large",
              "externalID": "ocd-person/05dee06f-a941-43cc-a4ce-fef9f3132784",
              "geometry": null
            },
            "contactInfo": {
              "email": "lburgos@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "lisieburgospr",
              "youtube": null,
              "instagram": null,
              "facebook": "Lisieburgospr",
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=754",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/lisie-j-burgos-muniz/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/6978a4c8-ae95-48f2-aa28-0013484df43e",
            "firstName": "Maria de Lourdes",
            "lastName": "Ramos Rivera",
            "fullName": "Lourdes Ramos",
            "gender": "Female",
            "party": "partido nuevo progresista",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/REP.-LOURDES-RAMOS-ACUMULACION-1.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "lower",
              "hashID": "SLDL-PR-At Large-lower-ocd-person-6978a4c8-ae95-48f2-aa28-0013484df43e",
              "builtID": "pr-lower-At-Large",
              "externalID": "ocd-person/6978a4c8-ae95-48f2-aa28-0013484df43e",
              "geometry": null
            },
            "contactInfo": {
              "email": "lramos@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=44",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/maria-de-lourdes-ramos-rivera/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/13349eb9-b441-419a-8687-671729d39010",
            "firstName": "Mariana",
            "lastName": "Nogales Molinelli",
            "fullName": "Mariana Nogales Molinelli",
            "gender": "Female",
            "party": "movimiento victoria ciudadana",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2020/07/REP._MARIANA_NOGALES_ACUMULACION_1_10.jpg",
            "title": "PR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "lower",
              "hashID": "SLDL-PR-At Large-lower-ocd-person-13349eb9-b441-419a-8687-671729d39010",
              "builtID": "pr-lower-At-Large",
              "externalID": "ocd-person/13349eb9-b441-419a-8687-671729d39010",
              "geometry": null
            },
            "contactInfo": {
              "email": "mnogales@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "repmariananogales",
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=753",
                "https://rfsp.eticapr.com/Busqueda",
                "https://www.camara.pr.gov/team/mariana-nogales-molinelli/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PR-At Large-lower-ocd-person-dee6d0ff-ee3c-429c-bfa4-6b255acd8472": 0,
          "SLDL-PR-At Large-lower-ocd-person-0c318d9b-2b74-42c7-8ec2-7c89ee41955e": 1,
          "SLDL-PR-At Large-lower-ocd-person-c9361851-f74e-468c-9c2f-7d802ca50ea0": 2,
          "SLDL-PR-At Large-lower-ocd-person-51a95b4d-0006-49e8-83ae-6c139a1d5b1b": 3,
          "SLDL-PR-At Large-lower-ocd-person-1913492c-2726-4c58-aaba-4dfe55a34bc2": 4,
          "SLDL-PR-At Large-lower-ocd-person-1ca4e7f3-fe25-4412-abe0-eba62eda6d1a": 5,
          "SLDL-PR-At Large-lower-ocd-person-2cdd7282-81fe-4c07-b50a-b162a16c4918": 6,
          "SLDL-PR-At Large-lower-ocd-person-65f91ca5-2401-4bd4-b146-79b08b12fad6": 7,
          "SLDL-PR-At Large-lower-ocd-person-05dee06f-a941-43cc-a4ce-fef9f3132784": 8,
          "SLDL-PR-At Large-lower-ocd-person-6978a4c8-ae95-48f2-aa28-0013484df43e": 9,
          "SLDL-PR-At Large-lower-ocd-person-13349eb9-b441-419a-8687-671729d39010": 10
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/99800ecf-b7d7-43f5-ace4-53a770ee77eb",
            "firstName": "Juan Oscar",
            "lastName": "Morales Rodriguez",
            "fullName": "Juan Oscar Morales",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": null,
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-PR-1-upper-ocd-person-99800ecf-b7d7-43f5-ace4-53a770ee77eb",
              "builtID": "pr-upper-1",
              "externalID": "ocd-person/99800ecf-b7d7-43f5-ace4-53a770ee77eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "jumorales@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senado.pr.gov/senators/hon-juan-oscar-morales"],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/02d88e4b-3717-43c5-b7fd-14e6a2224043",
            "firstName": "Nitza",
            "lastName": "Moran Trinidad",
            "fullName": "Nitza Morán",
            "gender": "Female",
            "party": "partido nuevo progresista",
            "image": "https://senado.pr.gov/document_vault/senator/23/FourYearPeriods/25/photo/NITZA%20MOR%C3%81N.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-PR-1-upper-ocd-person-02d88e4b-3717-43c5-b7fd-14e6a2224043",
              "builtID": "pr-upper-1",
              "externalID": "ocd-person/02d88e4b-3717-43c5-b7fd-14e6a2224043",
              "geometry": null
            },
            "contactInfo": {
              "email": "nmoran@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=685",
                "https://senado.pr.gov/senators/hon-nitza-morán-trinidad",
                "https://senado.pr.gov/senators/hon-nitza-mor%C3%A1n-trinidad"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PR-1-upper-ocd-person-99800ecf-b7d7-43f5-ace4-53a770ee77eb": 0,
          "SLDU-PR-1-upper-ocd-person-02d88e4b-3717-43c5-b7fd-14e6a2224043": 1
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/bb7a8a74-faec-492d-87f7-a7e8d3c11ac3",
            "firstName": "Carmelo J.",
            "lastName": "Rios Santiago",
            "fullName": "Carmelo Ríos Santiago",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://senado.pr.gov/document_vault/senator/17/FourYearPeriods/19/thumbnail/CARMELO%20R%C3%8DOS.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-PR-2-upper-ocd-person-bb7a8a74-faec-492d-87f7-a7e8d3c11ac3",
              "builtID": "pr-upper-2",
              "externalID": "ocd-person/bb7a8a74-faec-492d-87f7-a7e8d3c11ac3",
              "geometry": null
            },
            "contactInfo": {
              "email": "crios@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senadopr.us/Pages/Senadores%20Distrito%20II.aspx",
                "https://senado.pr.gov/senators/hon-carmelo-j-ríos-santiago",
                "https://senado.pr.gov/senators/hon-carmelo-j-r%C3%ADos-santiago"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b451e173-36b4-4664-ada7-ac5057ef8c9f",
            "firstName": "Migdalia",
            "lastName": "Padilla Alvelo",
            "fullName": "Migdalia Padilla Alvelo",
            "gender": "Female",
            "party": "partido nuevo progresista",
            "image": "https://senado.pr.gov/document_vault/senator/22/FourYearPeriods/24/photo/Foto%20Oficial%20Sen%20Migdalia%20Padilla.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-PR-2-upper-ocd-person-b451e173-36b4-4664-ada7-ac5057ef8c9f",
              "builtID": "pr-upper-2",
              "externalID": "ocd-person/b451e173-36b4-4664-ada7-ac5057ef8c9f",
              "geometry": null
            },
            "contactInfo": {
              "email": "mpadilla@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senado.pr.gov/Pages/Senators/HON--MIGDALIA-PADILLA-ALVELO.aspx",
                "https://senado.pr.gov/senators/hon-migdalia-padilla-alvelo"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PR-2-upper-ocd-person-bb7a8a74-faec-492d-87f7-a7e8d3c11ac3": 0,
          "SLDU-PR-2-upper-ocd-person-b451e173-36b4-4664-ada7-ac5057ef8c9f": 1
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/f6cbbf9c-03d1-4d0e-9acb-58ca7a0a0e18",
            "firstName": "Elizabeth",
            "lastName": "Rosa Velez",
            "fullName": "Elizabeth Rosa Vélez",
            "gender": "Female",
            "party": "partido popular democrático",
            "image": "https://senado.pr.gov/document_vault/senator/7/FourYearPeriods/10/photo/ELIZABETH ROSA.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-PR-3-upper-ocd-person-f6cbbf9c-03d1-4d0e-9acb-58ca7a0a0e18",
              "builtID": "pr-upper-3",
              "externalID": "ocd-person/f6cbbf9c-03d1-4d0e-9acb-58ca7a0a0e18",
              "geometry": null
            },
            "contactInfo": {
              "email": "erosa@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=688",
                "https://senado.pr.gov/senators/hon-elizabeth-rosa-vélez",
                "https://senado.pr.gov/senators/hon-elizabeth-rosa-v%C3%A9lez"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e34ad4c3-324f-48a0-8c7c-9cc3c641d163",
            "firstName": "Ruben",
            "lastName": "Soto Rivera",
            "fullName": "Rubén Soto Rivera",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://senado.pr.gov/document_vault/senator/16/FourYearPeriods/18/photo/Foto%20Oficial%20Hon.%20Ruben%20Soto.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-PR-3-upper-ocd-person-e34ad4c3-324f-48a0-8c7c-9cc3c641d163",
              "builtID": "pr-upper-3",
              "externalID": "ocd-person/e34ad4c3-324f-48a0-8c7c-9cc3c641d163",
              "geometry": null
            },
            "contactInfo": {
              "email": "rsoto@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=689",
                "https://senado.pr.gov/senators/hon-rubén-soto-rivera",
                "https://senado.pr.gov/senators/hon-rub%C3%A9n-soto-rivera"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PR-3-upper-ocd-person-f6cbbf9c-03d1-4d0e-9acb-58ca7a0a0e18": 0,
          "SLDU-PR-3-upper-ocd-person-e34ad4c3-324f-48a0-8c7c-9cc3c641d163": 1
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/e7e195f3-09ac-4958-bb0b-e4e215e4c9fa",
            "firstName": "Ada I.",
            "lastName": "Garcia Montes",
            "fullName": "Ada García Montes",
            "gender": "Female",
            "party": "partido popular democrático",
            "image": "https://senado.pr.gov/document_vault/senator/5/FourYearPeriods/8/photo/ADA%20GARCIA.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-PR-4-upper-ocd-person-e7e195f3-09ac-4958-bb0b-e4e215e4c9fa",
              "builtID": "pr-upper-4",
              "externalID": "ocd-person/e7e195f3-09ac-4958-bb0b-e4e215e4c9fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "agarcia@senado.pr.go",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=690",
                "https://senado.pr.gov/senators/hon-ada-i-garcía-montes",
                "https://senado.pr.gov/senators/hon-ada-i-garc%C3%ADa-montes"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8b62caa9-ef8b-44ff-872c-030c6e33096c",
            "firstName": "Migdalia I.",
            "lastName": "Gonzalez Arroyo",
            "fullName": "Migdalia González",
            "gender": "Female",
            "party": "partido popular democrático",
            "image": null,
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-PR-4-upper-ocd-person-8b62caa9-ef8b-44ff-872c-030c6e33096c",
              "builtID": "pr-upper-4",
              "externalID": "ocd-person/8b62caa9-ef8b-44ff-872c-030c6e33096c",
              "geometry": null
            },
            "contactInfo": {
              "email": "mgonzalez@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senado.pr.gov/senators/hon-migdalia-i-gonz%C3%A1lez-arroyo"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PR-4-upper-ocd-person-e7e195f3-09ac-4958-bb0b-e4e215e4c9fa": 0,
          "SLDU-PR-4-upper-ocd-person-8b62caa9-ef8b-44ff-872c-030c6e33096c": 1
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/dd5cfa44-9cb3-414b-a780-ea182b4fe217",
            "firstName": "Marially",
            "lastName": "Gonzalez Huertas",
            "fullName": "Marially González",
            "gender": "Female",
            "party": "partido popular democrático",
            "image": "https://senado.pr.gov/document_vault/senator/12/FourYearPeriods/14/photo/MARIALLY%20GONZALEZ.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-PR-5-upper-ocd-person-dd5cfa44-9cb3-414b-a780-ea182b4fe217",
              "builtID": "pr-upper-5",
              "externalID": "ocd-person/dd5cfa44-9cb3-414b-a780-ea182b4fe217",
              "geometry": null
            },
            "contactInfo": {
              "email": "magonzalez@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=692",
                "https://senado.pr.gov/senators/hon-marially-gonzález-huertas",
                "https://senado.pr.gov/senators/hon-marially-gonz%C3%A1lez-huertas"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1c1195f6-af0a-438f-9e00-2b7428e73cd0",
            "firstName": "Ramon",
            "lastName": "Ruiz Nieves",
            "fullName": "Ramón Ruiz",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://senado.pr.gov/document_vault/senator/14/FourYearPeriods/16/photo/RAMON%20RUIZ.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-PR-5-upper-ocd-person-1c1195f6-af0a-438f-9e00-2b7428e73cd0",
              "builtID": "pr-upper-5",
              "externalID": "ocd-person/1c1195f6-af0a-438f-9e00-2b7428e73cd0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rruiz@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=693",
                "https://senado.pr.gov/senators/hon-ramón-ruiz-nieves",
                "https://senado.pr.gov/senators/hon-ram%C3%B3n-ruiz-nieves"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PR-5-upper-ocd-person-dd5cfa44-9cb3-414b-a780-ea182b4fe217": 0,
          "SLDU-PR-5-upper-ocd-person-1c1195f6-af0a-438f-9e00-2b7428e73cd0": 1
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/9a94f5b3-93a2-404d-9d3e-036b12d13ef4",
            "firstName": "Albert",
            "lastName": "Torres Berrios",
            "fullName": "Albert Torres Berríos",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://senado.pr.gov/document_vault/senator/6/FourYearPeriods/9/photo/ALBERT%20TORRES.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-PR-6-upper-ocd-person-9a94f5b3-93a2-404d-9d3e-036b12d13ef4",
              "builtID": "pr-upper-6",
              "externalID": "ocd-person/9a94f5b3-93a2-404d-9d3e-036b12d13ef4",
              "geometry": null
            },
            "contactInfo": {
              "email": "altorres@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=695",
                "https://senado.pr.gov/senators/hon-albert-torres-berríos",
                "https://senado.pr.gov/senators/hon-albert-torres-berr%C3%ADos"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/76dae9be-424c-4da4-bf9f-9842fd3da06f",
            "firstName": "Gretchen",
            "lastName": "Hau",
            "fullName": "Gretchen Hau",
            "gender": "Female",
            "party": "partido popular democrático",
            "image": "https://www.camara.pr.gov/wp-content/uploads/2021/08/REP-GRETCHEN-HAU-DIST-29.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-PR-6-upper-ocd-person-76dae9be-424c-4da4-bf9f-9842fd3da06f",
              "builtID": "pr-upper-6",
              "externalID": "ocd-person/76dae9be-424c-4da4-bf9f-9842fd3da06f",
              "geometry": null
            },
            "contactInfo": {
              "email": "ghau@camara.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=694",
                "https://www.camara.pr.gov/team/gretchen-hau/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PR-6-upper-ocd-person-9a94f5b3-93a2-404d-9d3e-036b12d13ef4": 0,
          "SLDU-PR-6-upper-ocd-person-76dae9be-424c-4da4-bf9f-9842fd3da06f": 1
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/e64987be-382b-4def-bea1-90a84d39360f",
            "firstName": "Rosamar",
            "lastName": "Trujillo Plumey",
            "fullName": "Rosamar Trujillo Plumey",
            "gender": "Female",
            "party": "partido popular democrático",
            "image": "https://senado.pr.gov/document_vault/senator/15/FourYearPeriods/17/thumbnail/Foto%20Oficial%20Hon%20Rosamar%20Trujillo.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-PR-7-upper-ocd-person-e64987be-382b-4def-bea1-90a84d39360f",
              "builtID": "pr-upper-7",
              "externalID": "ocd-person/e64987be-382b-4def-bea1-90a84d39360f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rtrujillo@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=696",
                "https://senado.pr.gov/senators/hon-rosamar-trujillo-plumey"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/7b837260-0288-4b92-823e-1c07dc2f49d3",
            "firstName": "Wanda",
            "lastName": "Soto Tolentino",
            "fullName": "Wandy Soto",
            "gender": "Female",
            "party": "partido popular democrático",
            "image": "https://senado.pr.gov/document_vault/senator/24/FourYearPeriods/26/thumbnail/WANDA%20SOTO.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-PR-7-upper-ocd-person-7b837260-0288-4b92-823e-1c07dc2f49d3",
              "builtID": "pr-upper-7",
              "externalID": "ocd-person/7b837260-0288-4b92-823e-1c07dc2f49d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "wsoto@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=697",
                "https://senado.pr.gov/senators/hon-wanda-m-soto-tolentino"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PR-7-upper-ocd-person-e64987be-382b-4def-bea1-90a84d39360f": 0,
          "SLDU-PR-7-upper-ocd-person-7b837260-0288-4b92-823e-1c07dc2f49d3": 1
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/b85194f9-4f39-4585-8a7f-199b9fd8db7e",
            "firstName": "Javier A.",
            "lastName": "Aponte Dalmau",
            "fullName": "Javier Aponte Dalmau",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://senado.pr.gov/document_vault/senator/10/FourYearPeriods/12/photo/JAVIER%20APONTE%20DALMAU.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-PR-8-upper-ocd-person-b85194f9-4f39-4585-8a7f-199b9fd8db7e",
              "builtID": "pr-upper-8",
              "externalID": "ocd-person/b85194f9-4f39-4585-8a7f-199b9fd8db7e",
              "geometry": null
            },
            "contactInfo": {
              "email": "japonte@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=698",
                "https://www.tucamarapr.org/dnncamara/ComposiciondelaCamara/biografia.aspx?rep=80",
                "https://senado.pr.gov/senators/hon-javier-a-aponte-dalmau"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/9a9ebf96-43e3-41b9-976c-ffed31f5c0ec",
            "firstName": "Marissa",
            "lastName": "Jimenez Santoni",
            "fullName": "Marissa Jiménez",
            "gender": "Female",
            "party": "partido nuevo progresista",
            "image": "https://senado.pr.gov/document_vault/senator/21/FourYearPeriods/23/photo/MARISSA%20JIMENEZ.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-PR-8-upper-ocd-person-9a9ebf96-43e3-41b9-976c-ffed31f5c0ec",
              "builtID": "pr-upper-8",
              "externalID": "ocd-person/9a9ebf96-43e3-41b9-976c-ffed31f5c0ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "majimenez@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=699",
                "https://senado.pr.gov/senators/hon-marissa-jiménez-santoni",
                "https://senado.pr.gov/senators/hon-marissa-jim%C3%A9nez-santoni"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PR-8-upper-ocd-person-b85194f9-4f39-4585-8a7f-199b9fd8db7e": 0,
          "SLDU-PR-8-upper-ocd-person-9a9ebf96-43e3-41b9-976c-ffed31f5c0ec": 1
        }
      },
      "At Large": {
        "members": [
          {
            "API_ID": "ocd-person/a61f5354-ceaa-4fc0-9dc7-73caf5d83d1f",
            "firstName": "Ana Irma",
            "lastName": "Rivera Lassen",
            "fullName": "Ana Rivera Lassén",
            "gender": "Female",
            "party": "movimiento victoria ciudadana",
            "image": "https://senado.pr.gov/document_vault/senator/26/FourYearPeriods/28/photo/RIVERA LASEN.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "upper",
              "hashID": "SLDU-PR-At Large-upper-ocd-person-a61f5354-ceaa-4fc0-9dc7-73caf5d83d1f",
              "builtID": "pr-upper-At-Large",
              "externalID": "ocd-person/a61f5354-ceaa-4fc0-9dc7-73caf5d83d1f",
              "geometry": null
            },
            "contactInfo": {
              "email": "airivera@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=707",
                "https://senado.pr.gov/senators/hon-ana-irma-rivera-lassén",
                "https://senado.pr.gov/senators/hon-ana-irma-rivera-lass%C3%A9n"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/beb1660c-fd16-4f3b-851f-1b6f40bdd62d",
            "firstName": "Gregorio",
            "lastName": "Matias Rosario",
            "fullName": "Gregorio Matías",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://senado.pr.gov/document_vault/senator/18/FourYearPeriods/20/photo/GREGORIO MATIAS.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "upper",
              "hashID": "SLDU-PR-At Large-upper-ocd-person-beb1660c-fd16-4f3b-851f-1b6f40bdd62d",
              "builtID": "pr-upper-At-Large",
              "externalID": "ocd-person/beb1660c-fd16-4f3b-851f-1b6f40bdd62d",
              "geometry": null
            },
            "contactInfo": {
              "email": "gmatias@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=709",
                "https://senado.pr.gov/senators/hon-gregorio-matias-rosario"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a67879ee-0450-4270-a99f-d186bed3f783",
            "firstName": "Joanne Marie",
            "lastName": "Rodriguez Veve",
            "fullName": "Joanne Rodríguez Veve",
            "gender": "Female",
            "party": "proyecto dignidad",
            "image": "https://senado.pr.gov/document_vault/senator/29/FourYearPeriods/31/thumbnail/JOANNE%20RODR%C3%8DGUEZ%20VEVE.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "upper",
              "hashID": "SLDU-PR-At Large-upper-ocd-person-a67879ee-0450-4270-a99f-d186bed3f783",
              "builtID": "pr-upper-At-Large",
              "externalID": "ocd-person/a67879ee-0450-4270-a99f-d186bed3f783",
              "geometry": null
            },
            "contactInfo": {
              "email": "joarodriguez@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=701",
                "https://senado.pr.gov/senators/hon-joanne-m-rodríguez-veve",
                "https://senado.pr.gov/senators/hon-joanne-m-rodr%C3%ADguez-veve"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/317eb390-2a61-4ba5-89fa-ffc88ab955c4",
            "firstName": "Jose Antonio",
            "lastName": "Vargas Vidot",
            "fullName": "José Antonio Vargas Vidot",
            "gender": "Male",
            "party": "independent",
            "image": "https://senado.pr.gov/document_vault/senator/30/FourYearPeriods/32/photo/VARGAS VIDOT.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "upper",
              "hashID": "SLDU-PR-At Large-upper-ocd-person-317eb390-2a61-4ba5-89fa-ffc88ab955c4",
              "builtID": "pr-upper-At-Large",
              "externalID": "ocd-person/317eb390-2a61-4ba5-89fa-ffc88ab955c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "jvargas@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senado.pr.gov/Pages/Senators/HON--JOSE-ANTONIO-VARGAS-VIDOT.aspx",
                "https://senado.pr.gov/senators/hon-josé-antonio-vargas-vidot",
                "https://senado.pr.gov/senators/hon-jos%C3%A9-antonio-vargas-vidot"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/6f8348ec-b184-4c8d-b0dc-d82ef8dcb566",
            "firstName": "Jose Luis",
            "lastName": "Dalmau Santiago",
            "fullName": "José Dalmau Santiago",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://senado.pr.gov/document_vault/senator/4/FourYearPeriods/7/photo/JOSE L DALMAU.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "upper",
              "hashID": "SLDU-PR-At Large-upper-ocd-person-6f8348ec-b184-4c8d-b0dc-d82ef8dcb566",
              "builtID": "pr-upper-At-Large",
              "externalID": "ocd-person/6f8348ec-b184-4c8d-b0dc-d82ef8dcb566",
              "geometry": null
            },
            "contactInfo": {
              "email": "jldalmau@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senado.pr.gov/Pages/Senators/HON--JOSE-L--DALMAU-SANTIAGO.aspx",
                "https://senado.pr.gov/senators/hon-josé-l-dalmau-santiago",
                "https://senado.pr.gov/senators/hon-jos%C3%A9-l-dalmau-santiago"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/968b51ad-f1ad-41d8-84c2-e76a4f450e70",
            "firstName": "Juan C.",
            "lastName": "Zaragoza Gomez",
            "fullName": "Juan Zaragoza",
            "gender": "Male",
            "party": "partido popular democrático",
            "image": "https://senado.pr.gov/document_vault/senator/11/FourYearPeriods/13/photo/JUAN ZARAGOZA.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "upper",
              "hashID": "SLDU-PR-At Large-upper-ocd-person-968b51ad-f1ad-41d8-84c2-e76a4f450e70",
              "builtID": "pr-upper-At-Large",
              "externalID": "ocd-person/968b51ad-f1ad-41d8-84c2-e76a4f450e70",
              "geometry": null
            },
            "contactInfo": {
              "email": "jzaragoza@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=704",
                "https://senado.pr.gov/senators/hon-juan-zaragoza-gómez",
                "https://senado.pr.gov/senators/hon-juan-zaragoza-g%C3%B3mez"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/9b6a68d4-b93e-4d8d-be05-c7597e691b65",
            "firstName": "Keren L.",
            "lastName": "Riquelme Cabrera",
            "fullName": "Keren Riquelme",
            "gender": "Female",
            "party": "partido nuevo progresista",
            "image": "https://senado.pr.gov/document_vault/senator/20/FourYearPeriods/22/photo/Foto%20oficial%20Senadora%20Keren%20Riquelme.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "upper",
              "hashID": "SLDU-PR-At Large-upper-ocd-person-9b6a68d4-b93e-4d8d-be05-c7597e691b65",
              "builtID": "pr-upper-At-Large",
              "externalID": "ocd-person/9b6a68d4-b93e-4d8d-be05-c7597e691b65",
              "geometry": null
            },
            "contactInfo": {
              "email": "klcabrera@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=710",
                "https://senado.pr.gov/senators/hon-keren-l-riquelme-cabrera"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f583f4dd-fabe-4211-a565-657f7e690f2c",
            "firstName": "Maria de Lourdes",
            "lastName": "Santiago Negron",
            "fullName": "María de Lourdes Santiago",
            "gender": "Female",
            "party": "partido independentista puertorriqueño",
            "image": null,
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "upper",
              "hashID": "SLDU-PR-At Large-upper-ocd-person-f583f4dd-fabe-4211-a565-657f7e690f2c",
              "builtID": "pr-upper-At-Large",
              "externalID": "ocd-person/f583f4dd-fabe-4211-a565-657f7e690f2c",
              "geometry": null
            },
            "contactInfo": {
              "email": "mlsantiago@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senado.pr.gov/senators/hon-mar%C3%ADa-de-l-santiago-negr%C3%B3n"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/27133d78-0b35-4578-b4a5-6c324353000d",
            "firstName": "Rafael",
            "lastName": "Bernabe Riefkohl",
            "fullName": "Rafael Bernabe",
            "gender": "Male",
            "party": "movimiento victoria ciudadana",
            "image": "https://senado.pr.gov/document_vault/senator/27/FourYearPeriods/29/photo/RAFAEL%20BERNABE%20500x599.jpg",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "upper",
              "hashID": "SLDU-PR-At Large-upper-ocd-person-27133d78-0b35-4578-b4a5-6c324353000d",
              "builtID": "pr-upper-At-Large",
              "externalID": "ocd-person/27133d78-0b35-4578-b4a5-6c324353000d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rbernabe@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=708",
                "https://senado.pr.gov/senators/hon-rafael-bernabe-riefkohl"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/3caf98b9-5210-45ab-bbde-794edfc41451",
            "firstName": "Thomas",
            "lastName": "Rivera Schatz",
            "fullName": "Thomas Rivera Schatz",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://senado.pr.gov/document_vault/senator/31/FourYearPeriods/33/photo/Foto oficial Hon. Thomas Rivera Schatz 500x599.JPG",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "upper",
              "hashID": "SLDU-PR-At Large-upper-ocd-person-3caf98b9-5210-45ab-bbde-794edfc41451",
              "builtID": "pr-upper-At-Large",
              "externalID": "ocd-person/3caf98b9-5210-45ab-bbde-794edfc41451",
              "geometry": null
            },
            "contactInfo": {
              "email": "trivera@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senado.pr.gov/Pages/Senators/HON--THOMAS-RIVERA-SCHATZ.aspx",
                "https://senado.pr.gov/senators/hon-thomas-rivera-schatz"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/115b8adb-d51e-459f-85c1-bc71f0ffa95a",
            "firstName": "William Ely",
            "lastName": "Villafane Ramos",
            "fullName": "William Villafañe",
            "gender": "Male",
            "party": "partido nuevo progresista",
            "image": "https://sutra.oslpr.org/osl/SUTRA/foto_legislador/2021/703/Screen%20Shot%202021-08-17%20at%209.46.37%20PM.png",
            "title": "PR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PR",
              "stateFull": "Puerto Rico",
              "district": "At Large",
              "chamber": "upper",
              "hashID": "SLDU-PR-At Large-upper-ocd-person-115b8adb-d51e-459f-85c1-bc71f0ffa95a",
              "builtID": "pr-upper-At-Large",
              "externalID": "ocd-person/115b8adb-d51e-459f-85c1-bc71f0ffa95a",
              "geometry": null
            },
            "contactInfo": {
              "email": "wvillafane@senado.pr.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sutra.oslpr.org/osl/esutra/AdmLegisladorReg.aspx?rid=703",
                "https://senado.pr.gov/senators/hon-william-e-villafañe-ramos",
                "https://senado.pr.gov/senators/hon-william-e-villafa%C3%B1e-ramos"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PR-At Large-upper-ocd-person-a61f5354-ceaa-4fc0-9dc7-73caf5d83d1f": 0,
          "SLDU-PR-At Large-upper-ocd-person-beb1660c-fd16-4f3b-851f-1b6f40bdd62d": 1,
          "SLDU-PR-At Large-upper-ocd-person-a67879ee-0450-4270-a99f-d186bed3f783": 2,
          "SLDU-PR-At Large-upper-ocd-person-317eb390-2a61-4ba5-89fa-ffc88ab955c4": 3,
          "SLDU-PR-At Large-upper-ocd-person-6f8348ec-b184-4c8d-b0dc-d82ef8dcb566": 4,
          "SLDU-PR-At Large-upper-ocd-person-968b51ad-f1ad-41d8-84c2-e76a4f450e70": 5,
          "SLDU-PR-At Large-upper-ocd-person-9b6a68d4-b93e-4d8d-be05-c7597e691b65": 6,
          "SLDU-PR-At Large-upper-ocd-person-f583f4dd-fabe-4211-a565-657f7e690f2c": 7,
          "SLDU-PR-At Large-upper-ocd-person-27133d78-0b35-4578-b4a5-6c324353000d": 8,
          "SLDU-PR-At Large-upper-ocd-person-3caf98b9-5210-45ab-bbde-794edfc41451": 9,
          "SLDU-PR-At Large-upper-ocd-person-115b8adb-d51e-459f-85c1-bc71f0ffa95a": 10
        }
      }
    }
  },
  "AS": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "AS",
          "stateFull": "American Samoa",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "AS",
            "stateFull": "American Samoa",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-AS---",
            "builtID": "as--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-AS---": 0 }
    },
    "SENATE": {},
    "HOUSE": {
      "00": {
        "members": [
          {
            "API_ID": "R000600",
            "in_office": true,
            "firstName": "Amata",
            "lastName": "Radewagen",
            "middleName": "Coleman",
            "fullName": "Aumua Amata Coleman Radewagen",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/R000600.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AS",
              "stateFull": "American Samoa",
              "district": "00",
              "chamber": "",
              "hashID": "HOUSE-AS-00--R000600",
              "builtID": "as--00",
              "externalID": "R000600",
              "geometry": null,
              "geoid": "6098"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAmata",
              "youtube": null,
              "instagram": null,
              "facebook": "aumuaamata",
              "urls": ["https://radewagen.house.gov"],
              "rss_url": "https://radewagen.house.gov/rss.xml"
            },
            "title": "AS House Member",
            "short_title": "Del.",
            "api_uri": "https://api.propublica.org/congress/v1/members/R000600.json",
            "govtrack_id": "412664",
            "cspan_id": "5188",
            "votesmart_id": "128380",
            "icpsr_id": null,
            "crp_id": "N00007635",
            "google_entity_id": "/m/012964p3",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1947-12-29",
            "leadership_role": null,
            "fec_candidate_id": null,
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 498,
            "missed_votes": 289,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 58.03,
            "votes_with_party_pct": 73.4,
            "votes_against_party_pct": 26.6,
            "ocd_id": "ocd-division/country:us/state:as/cd:79",
            "at_large": true
          }
        ],
        "hash": { "HOUSE-AS-00--R000600": 0 }
      }
    },
    "SLDL": {},
    "SLDU": {}
  },
  "GU": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "GU",
          "stateFull": "Guam",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "GU",
            "stateFull": "Guam",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-GU---",
            "builtID": "gu--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-GU---": 0 }
    },
    "SENATE": {},
    "HOUSE": {
      "": {
        "members": [
          {
            "API_ID": "M001219",
            "in_office": true,
            "firstName": "Jim",
            "lastName": "Moylan",
            "middleName": null,
            "fullName": "James C. Moylan",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001219.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "GU",
              "stateFull": "Guam",
              "district": "",
              "chamber": "",
              "hashID": "HOUSE-GU---M001219",
              "builtID": "gu--",
              "externalID": "M001219",
              "geometry": null,
              "geoid": "6698"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://moylan.house.gov/"],
              "rss_url": null
            },
            "title": "GU House Member",
            "short_title": "Del.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001219.json",
            "govtrack_id": "456896",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1962-07-18",
            "leadership_role": null,
            "fec_candidate_id": null,
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 499,
            "missed_votes": 158,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 31.66,
            "votes_with_party_pct": 80.3,
            "votes_against_party_pct": 19.7,
            "ocd_id": "ocd-division/country:us/state:gu/cd:",
            "at_large": true
          }
        ],
        "hash": { "HOUSE-GU---M001219": 0 }
      }
    },
    "SLDL": {},
    "SLDU": {}
  },
  "VI": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "VI",
          "stateFull": "Virgin Islands",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "VI",
            "stateFull": "Virgin Islands",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-VI---",
            "builtID": "vi--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-VI---": 0 }
    },
    "SENATE": {},
    "HOUSE": {
      "00": {
        "members": [
          {
            "API_ID": "P000610",
            "in_office": true,
            "firstName": "Stacey",
            "lastName": "Plaskett",
            "middleName": null,
            "fullName": "Stacey E. Plaskett",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/P000610.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "VI",
              "stateFull": "Virgin Islands",
              "district": "00",
              "chamber": "",
              "hashID": "HOUSE-VI-00--P000610",
              "builtID": "vi--00",
              "externalID": "P000610",
              "geometry": null,
              "geoid": "7898"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "staceyplaskett",
              "youtube": null,
              "instagram": null,
              "facebook": "repstaceyplaskett",
              "urls": ["https://plaskett.house.gov"],
              "rss_url": null
            },
            "title": "VI House Member",
            "short_title": "Del.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000610.json",
            "govtrack_id": "412659",
            "cspan_id": "79090",
            "votesmart_id": "155929",
            "icpsr_id": null,
            "crp_id": "N00035000",
            "google_entity_id": "/m/012844_c",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1966-05-13",
            "leadership_role": null,
            "fec_candidate_id": null,
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 498,
            "missed_votes": 46,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 9.24,
            "votes_with_party_pct": 96.01,
            "votes_against_party_pct": 3.99,
            "ocd_id": "ocd-division/country:us/state:vi/cd:79",
            "at_large": true
          }
        ],
        "hash": { "HOUSE-VI-00--P000610": 0 }
      }
    },
    "SLDL": {},
    "SLDU": {}
  },
  "MP": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "MP",
          "stateFull": "Northern Mariana Islands",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "MP",
            "stateFull": "Northern Mariana Islands",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-MP---",
            "builtID": "mp--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-MP---": 0 }
    },
    "SENATE": {},
    "HOUSE": {
      "00": {
        "members": [
          {
            "API_ID": "S001177",
            "in_office": true,
            "firstName": "Gregorio",
            "lastName": "Sablan",
            "middleName": "Kilili Camacho",
            "fullName": "Gregorio Kilili Camacho Sablan",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001177.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MP",
              "stateFull": "Northern Mariana Islands",
              "district": "00",
              "chamber": "",
              "hashID": "HOUSE-MP-00--S001177",
              "builtID": "mp--00",
              "externalID": "S001177",
              "geometry": null,
              "geoid": "6998"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Kilili_Sablan",
              "youtube": "CongressmanSablan",
              "instagram": null,
              "facebook": "153423912663",
              "urls": ["https://sablan.house.gov"],
              "rss_url": "https://sablan.house.gov/rss.xml"
            },
            "title": "MP House Member",
            "short_title": "Del.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001177.json",
            "govtrack_id": "412312",
            "cspan_id": "1031366",
            "votesmart_id": "110903",
            "icpsr_id": null,
            "crp_id": "N00030418",
            "google_entity_id": "/m/04ycmvy",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1955-01-19",
            "leadership_role": null,
            "fec_candidate_id": null,
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "16",
            "next_election": "2024",
            "total_votes": 498,
            "missed_votes": 109,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 21.89,
            "votes_with_party_pct": 97.67,
            "votes_against_party_pct": 2.33,
            "ocd_id": "ocd-division/country:us/state:mp/cd:79",
            "at_large": true
          }
        ],
        "hash": { "HOUSE-MP-00--S001177": 0 }
      }
    },
    "SLDL": {},
    "SLDU": {}
  },
  "AZ": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "AZ",
          "stateFull": "Arizona",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "AZ",
            "stateFull": "Arizona",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-AZ---",
            "builtID": "az--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-AZ---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "K000377",
          "in_office": true,
          "firstName": "Mark",
          "lastName": "Kelly",
          "middleName": null,
          "fullName": "Mark Kelly",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/K000377.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "AZ",
            "stateFull": "Arizona",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-AZ---K000377",
            "builtID": "az--",
            "externalID": "K000377",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenMarkKelly",
            "youtube": "SenMarkKelly",
            "instagram": null,
            "facebook": "SenMarkKelly",
            "urls": ["https://www.kelly.senate.gov"],
            "rss_url": null
          },
          "title": "AZ Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/K000377.json",
          "govtrack_id": "456794",
          "cspan_id": null,
          "votesmart_id": "190594",
          "icpsr_id": null,
          "crp_id": "N00044223",
          "google_entity_id": "/m/02wsxy",
          "state_rank": "junior",
          "lis_id": "S406",
          "suffix": null,
          "date_of_birth": "1964-02-21",
          "leadership_role": null,
          "fec_candidate_id": "S0AZ00350",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "3",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 23,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 4.15,
          "votes_with_party_pct": 98.49,
          "votes_against_party_pct": 1.51,
          "ocd_id": "ocd-division/country:us/state:az"
        },
        {
          "API_ID": "S001191",
          "in_office": true,
          "firstName": "Kyrsten",
          "lastName": "Sinema",
          "middleName": null,
          "fullName": "Kyrsten Sinema",
          "gender": "F",
          "party": "Independent",
          "image": "https://theunitedstates.io/images/congress/225x275/S001191.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "AZ",
            "stateFull": "Arizona",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-AZ---S001191",
            "builtID": "az--",
            "externalID": "S001191",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.sinema.senate.gov/contact-kyrsten",
            "address1": "317 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-4521",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorSinema",
            "youtube": "repsinema",
            "instagram": null,
            "facebook": "CongresswomanSinema",
            "urls": ["https://www.sinema.senate.gov"],
            "rss_url": null
          },
          "title": "AZ Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/S001191.json",
          "govtrack_id": "412509",
          "cspan_id": "68489",
          "votesmart_id": "28338",
          "icpsr_id": "21300",
          "crp_id": "N00033983",
          "google_entity_id": "/m/03qnndb",
          "state_rank": "senior",
          "lis_id": "S403",
          "suffix": null,
          "date_of_birth": "1976-07-12",
          "leadership_role": null,
          "fec_candidate_id": "H2AZ09019",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "5",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 28,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 5.05,
          "votes_with_party_pct": 93.13,
          "votes_against_party_pct": 6.87,
          "ocd_id": "ocd-division/country:us/state:az"
        }
      ],
      "hash": { "SENATE-AZ---K000377": 0, "SENATE-AZ---S001191": 1 }
    },
    "HOUSE": {
      "05": {
        "members": [
          {
            "API_ID": "B001302",
            "in_office": true,
            "firstName": "Andy",
            "lastName": "Biggs",
            "middleName": null,
            "fullName": "Andy Biggs",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001302.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-AZ-05--B001302",
              "builtID": "az--05",
              "externalID": "B001302",
              "geometry": null,
              "geoid": "0405"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "252 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2635",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAndyBiggsAZ",
              "youtube": null,
              "instagram": null,
              "facebook": "RepAndyBiggs",
              "urls": ["https://biggs.house.gov"],
              "rss_url": "https://biggs.house.gov/rss.xml"
            },
            "title": "AZ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001302.json",
            "govtrack_id": "412683",
            "cspan_id": "105145",
            "votesmart_id": "28088",
            "icpsr_id": "21705",
            "crp_id": "N00039293",
            "google_entity_id": "/m/0_1m23v",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1958-11-07",
            "leadership_role": null,
            "fec_candidate_id": "H6AZ05083",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 5,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.47,
            "votes_with_party_pct": 79.32,
            "votes_against_party_pct": 18.83,
            "ocd_id": "ocd-division/country:us/state:az/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AZ-05--B001302": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "C001133",
            "in_office": true,
            "firstName": "Juan",
            "lastName": "Ciscomani",
            "middleName": null,
            "fullName": "Juan Ciscomani",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001133.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-AZ-06--C001133",
              "builtID": "az--06",
              "externalID": "C001133",
              "geometry": null,
              "geoid": "0406"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1429 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2542",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepCiscomani",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ciscomani.house.gov/"],
              "rss_url": null
            },
            "title": "AZ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001133.json",
            "govtrack_id": "456880",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1982-08-30",
            "leadership_role": null,
            "fec_candidate_id": "H2AZ02360",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 21,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.99,
            "votes_with_party_pct": 84.22,
            "votes_against_party_pct": 13.81,
            "ocd_id": "ocd-division/country:us/state:az/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AZ-06--C001133": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "C001132",
            "in_office": true,
            "firstName": "Eli",
            "lastName": "Crane",
            "middleName": null,
            "fullName": "Elijah Crane",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001132.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-AZ-02--C001132",
              "builtID": "az--02",
              "externalID": "C001132",
              "geometry": null,
              "geoid": "0402"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1229 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3361",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepEliCrane",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://crane.house.gov/"],
              "rss_url": null
            },
            "title": "AZ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001132.json",
            "govtrack_id": "456879",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1980-01-03",
            "leadership_role": null,
            "fec_candidate_id": "H2AZ01354",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 9,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 81.58,
            "votes_against_party_pct": 16.57,
            "ocd_id": "ocd-division/country:us/state:az/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AZ-02--C001132": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "G000574",
            "in_office": true,
            "firstName": "Ruben",
            "lastName": "Gallego",
            "middleName": null,
            "fullName": "Ruben Gallego",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/G000574.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-AZ-03--G000574",
              "builtID": "az--03",
              "externalID": "G000574",
              "geometry": null,
              "geoid": "0403"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1114 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4065",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRubenGallego",
              "youtube": null,
              "instagram": null,
              "facebook": "RepRubenGallego",
              "urls": ["https://rubengallego.house.gov"],
              "rss_url": "https://rubengallego.house.gov/rss.xml"
            },
            "title": "AZ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000574.json",
            "govtrack_id": "412612",
            "cspan_id": "77233",
            "votesmart_id": "123732",
            "icpsr_id": "21502",
            "crp_id": "N00036097",
            "google_entity_id": "/m/0wy0mz5",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1979-11-20",
            "leadership_role": null,
            "fec_candidate_id": "H4AZ07043",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 142,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 13.46,
            "votes_with_party_pct": 92.37,
            "votes_against_party_pct": 5.53,
            "ocd_id": "ocd-division/country:us/state:az/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AZ-03--G000574": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "G000565",
            "in_office": true,
            "firstName": "Paul",
            "lastName": "Gosar",
            "middleName": null,
            "fullName": "Paul A. Gosar",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000565.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-AZ-09--G000565",
              "builtID": "az--09",
              "externalID": "G000565",
              "geometry": null,
              "geoid": "0409"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2057 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2315",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGosar",
              "youtube": "repgosar",
              "instagram": null,
              "facebook": "repgosar",
              "urls": ["https://gosar.house.gov"],
              "rss_url": null
            },
            "title": "AZ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000565.json",
            "govtrack_id": "412397",
            "cspan_id": "62470",
            "votesmart_id": "123491",
            "icpsr_id": "21103",
            "crp_id": "N00030771",
            "google_entity_id": "/m/0czdqz0",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1958-11-22",
            "leadership_role": null,
            "fec_candidate_id": "H0AZ01259",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 65,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 6.16,
            "votes_with_party_pct": 82.66,
            "votes_against_party_pct": 15.38,
            "ocd_id": "ocd-division/country:us/state:az/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AZ-09--G000565": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "G000551",
            "in_office": true,
            "firstName": "Raúl",
            "lastName": "Grijalva",
            "middleName": "M.",
            "fullName": "Raúl M. Grijalva",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/G000551.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-AZ-07--G000551",
              "builtID": "az--07",
              "externalID": "G000551",
              "geometry": null,
              "geoid": "0407"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1203 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2435",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepraulGrijalva",
              "youtube": "raulgrijalvaaz07",
              "instagram": null,
              "facebook": "Rep.Grijalva",
              "urls": ["https://grijalva.house.gov"],
              "rss_url": null
            },
            "title": "AZ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000551.json",
            "govtrack_id": "400162",
            "cspan_id": "1003551",
            "votesmart_id": "28253",
            "icpsr_id": "20305",
            "crp_id": "N00025284",
            "google_entity_id": "/m/024s1z",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1948-02-19",
            "leadership_role": null,
            "fec_candidate_id": "H2AZ07070",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "22",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 313,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 29.67,
            "votes_with_party_pct": 94.16,
            "votes_against_party_pct": 3.26,
            "ocd_id": "ocd-division/country:us/state:az/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AZ-07--G000551": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "L000589",
            "in_office": true,
            "firstName": "Debbie",
            "lastName": "Lesko",
            "middleName": null,
            "fullName": "Debbie Lesko",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/L000589.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-AZ-08--L000589",
              "builtID": "az--08",
              "externalID": "L000589",
              "geometry": null,
              "geoid": "0408"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1214 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4576",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDLesko",
              "youtube": null,
              "instagram": null,
              "facebook": "RepDebbieLesko",
              "urls": ["https://lesko.house.gov"],
              "rss_url": null
            },
            "title": "AZ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000589.json",
            "govtrack_id": "412745",
            "cspan_id": null,
            "votesmart_id": "106483",
            "icpsr_id": "21757",
            "crp_id": "N00042056",
            "google_entity_id": "/m/0zwmkgq",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1958-11-14",
            "leadership_role": null,
            "fec_candidate_id": "H8AZ08158",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 94,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 8.9,
            "votes_with_party_pct": 91.21,
            "votes_against_party_pct": 6.67,
            "ocd_id": "ocd-division/country:us/state:az/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AZ-08--L000589": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "S001183",
            "in_office": true,
            "firstName": "David",
            "lastName": "Schweikert",
            "middleName": null,
            "fullName": "David Schweikert",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S001183.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-AZ-01--S001183",
              "builtID": "az--01",
              "externalID": "S001183",
              "geometry": null,
              "geoid": "0401"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "460 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2190",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDavid",
              "youtube": "RepDavidSchweikert",
              "instagram": null,
              "facebook": "repdavidschweikert",
              "urls": ["https://schweikert.house.gov"],
              "rss_url": "https://schweikert.house.gov/rss.xml"
            },
            "title": "AZ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001183.json",
            "govtrack_id": "412399",
            "cspan_id": "5205",
            "votesmart_id": "106387",
            "icpsr_id": "21105",
            "crp_id": "N00006460",
            "google_entity_id": "/m/03wbpxj",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1962-03-03",
            "leadership_role": null,
            "fec_candidate_id": "H4AZ06045",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 8,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.76,
            "votes_with_party_pct": 89.08,
            "votes_against_party_pct": 9.06,
            "ocd_id": "ocd-division/country:us/state:az/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AZ-01--S001183": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "S001211",
            "in_office": true,
            "firstName": "Greg",
            "lastName": "Stanton",
            "middleName": null,
            "fullName": "Greg Stanton",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001211.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-AZ-04--S001211",
              "builtID": "az--04",
              "externalID": "S001211",
              "geometry": null,
              "geoid": "0404"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "207 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-9888",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGregStanton",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://stanton.house.gov"],
              "rss_url": null
            },
            "title": "AZ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001211.json",
            "govtrack_id": "412753",
            "cspan_id": null,
            "votesmart_id": "72030",
            "icpsr_id": null,
            "crp_id": "N00041750",
            "google_entity_id": "/m/0hgrxqb",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1970-03-08",
            "leadership_role": null,
            "fec_candidate_id": "H8AZ09040",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 16,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.52,
            "votes_with_party_pct": 92.25,
            "votes_against_party_pct": 5.81,
            "ocd_id": "ocd-division/country:us/state:az/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AZ-04--S001211": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/a4f969ab-e79a-4364-9a93-ad8c75ccf668",
            "firstName": "Quang H.",
            "lastName": "Nguyen",
            "fullName": "Quang Nguyen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/NGUYEN.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-AZ-1-lower-ocd-person-a4f969ab-e79a-4364-9a93-ad8c75ccf668",
              "builtID": "az-lower-1",
              "externalID": "ocd-person/a4f969ab-e79a-4364-9a93-ad8c75ccf668",
              "geometry": null
            },
            "contactInfo": {
              "email": "qnguyen@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2063",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2063",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2184",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2063"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/bdcadc0a-7e86-4e6a-8e27-4896dbb094ce",
            "firstName": "Selina",
            "lastName": "Bliss",
            "fullName": "Selina Bliss",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/rsz_mar162020527pm_80182230_435a2599nwnbbliss2.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-AZ-1-lower-ocd-person-bdcadc0a-7e86-4e6a-8e27-4896dbb094ce",
              "builtID": "az-lower-1",
              "externalID": "ocd-person/bdcadc0a-7e86-4e6a-8e27-4896dbb094ce",
              "geometry": null
            },
            "contactInfo": {
              "email": "sbliss@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2149",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2149",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2149"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-1-lower-ocd-person-a4f969ab-e79a-4364-9a93-ad8c75ccf668": 0,
          "SLDL-AZ-1-lower-ocd-person-bdcadc0a-7e86-4e6a-8e27-4896dbb094ce": 1
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/8e5bee66-d53d-4167-b9a8-de8f54145bf2",
            "firstName": "Judy",
            "lastName": "Schwiebert",
            "fullName": "Judy Schwiebert",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/SCHWIEBERT.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-AZ-2-lower-ocd-person-8e5bee66-d53d-4167-b9a8-de8f54145bf2",
              "builtID": "az-lower-2",
              "externalID": "ocd-person/8e5bee66-d53d-4167-b9a8-de8f54145bf2",
              "geometry": null
            },
            "contactInfo": {
              "email": "jschwiebert@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2070",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2070",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2195",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2195",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2070"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/2706e746-45b7-4586-8d06-8b80e99d3869",
            "firstName": "Justin",
            "lastName": "Wilmeth",
            "fullName": "Justin Wilmeth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/WILMETH.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-AZ-2-lower-ocd-person-2706e746-45b7-4586-8d06-8b80e99d3869",
              "builtID": "az-lower-2",
              "externalID": "ocd-person/2706e746-45b7-4586-8d06-8b80e99d3869",
              "geometry": null
            },
            "contactInfo": {
              "email": "jwilmeth@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2068",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2068",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2205",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2205",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2068"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-2-lower-ocd-person-8e5bee66-d53d-4167-b9a8-de8f54145bf2": 0,
          "SLDL-AZ-2-lower-ocd-person-2706e746-45b7-4586-8d06-8b80e99d3869": 1
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/102ffcb1-3028-401f-b74f-6f45db25e05e",
            "firstName": "Alexander",
            "lastName": "Kolodin",
            "fullName": "Alexander Kolodin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Alexander_Kolodin.jpeg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-AZ-3-lower-ocd-person-102ffcb1-3028-401f-b74f-6f45db25e05e",
              "builtID": "az-lower-3",
              "externalID": "ocd-person/102ffcb1-3028-401f-b74f-6f45db25e05e",
              "geometry": null
            },
            "contactInfo": {
              "email": "akolodin@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2174",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2174",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2174"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/2cfee9f0-0fab-418e-b14e-0e001a1414fa",
            "firstName": "Joseph",
            "lastName": "Chaplik",
            "fullName": "Joseph Chaplik",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/CHAPLIK.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-AZ-3-lower-ocd-person-2cfee9f0-0fab-418e-b14e-0e001a1414fa",
              "builtID": "az-lower-3",
              "externalID": "ocd-person/2cfee9f0-0fab-418e-b14e-0e001a1414fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "jchaplik@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2058",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2058",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2154",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2154",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2058"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-3-lower-ocd-person-102ffcb1-3028-401f-b74f-6f45db25e05e": 0,
          "SLDL-AZ-3-lower-ocd-person-2cfee9f0-0fab-418e-b14e-0e001a1414fa": 1
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/bfb503ce-dcb1-444b-9484-8a90e73c6a6f",
            "firstName": "Matt",
            "lastName": "Gress",
            "fullName": "Matt Gress",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/MattGress.png",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-AZ-4-lower-ocd-person-bfb503ce-dcb1-444b-9484-8a90e73c6a6f",
              "builtID": "az-lower-4",
              "externalID": "ocd-person/bfb503ce-dcb1-444b-9484-8a90e73c6a6f",
              "geometry": null
            },
            "contactInfo": {
              "email": "mgress@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2163",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2163",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2163"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-4-lower-ocd-person-bfb503ce-dcb1-444b-9484-8a90e73c6a6f": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/d5e0918a-5246-481c-8a2d-248cf954d331",
            "firstName": "Charles Walter",
            "lastName": "Lucking",
            "fullName": "Charles Lucking",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Charles_Lucking.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-AZ-5-lower-ocd-person-d5e0918a-5246-481c-8a2d-248cf954d331",
              "builtID": "az-lower-5",
              "externalID": "ocd-person/d5e0918a-5246-481c-8a2d-248cf954d331",
              "geometry": null
            },
            "contactInfo": {
              "email": "clucking@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/house-member/?legislature=56&session=128&legislator=2280"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/99f91f84-b1db-4a48-897a-4f87593357ea",
            "firstName": "Sarah",
            "lastName": "Liguori",
            "fullName": "Sarah Liguori",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/LIGUORI.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-AZ-5-lower-ocd-person-99f91f84-b1db-4a48-897a-4f87593357ea",
              "builtID": "az-lower-5",
              "externalID": "ocd-person/99f91f84-b1db-4a48-897a-4f87593357ea",
              "geometry": null
            },
            "contactInfo": {
              "email": "sliguori@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/house-member/?legislature=56&session=128&legislator=2279"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-5-lower-ocd-person-d5e0918a-5246-481c-8a2d-248cf954d331": 0,
          "SLDL-AZ-5-lower-ocd-person-99f91f84-b1db-4a48-897a-4f87593357ea": 1
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/bcc217f5-fe08-4213-b8a5-78d1e4d64184",
            "firstName": "Mae",
            "lastName": "Peshlakai",
            "fullName": "Mae Peshlakai",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://azleg.gov/alisImages/MemberPhotos/56leg/House/PESHLAKAI.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-AZ-6-lower-ocd-person-bcc217f5-fe08-4213-b8a5-78d1e4d64184",
              "builtID": "az-lower-6",
              "externalID": "ocd-person/bcc217f5-fe08-4213-b8a5-78d1e4d64184",
              "geometry": null
            },
            "contactInfo": {
              "email": "mpeshlakai@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2190",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2190",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2190"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a437a3ce-e3ba-44ec-af75-3308e70f5a87",
            "firstName": "Myron",
            "lastName": "Tsosie",
            "fullName": "Myron Tsosie",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://azleg.gov/alisImages/MemberPhotos/56leg/House/TSOSIE.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-AZ-6-lower-ocd-person-a437a3ce-e3ba-44ec-af75-3308e70f5a87",
              "builtID": "az-lower-6",
              "externalID": "ocd-person/a437a3ce-e3ba-44ec-af75-3308e70f5a87",
              "geometry": null
            },
            "contactInfo": {
              "email": "mtsosie@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "myrontsosield7",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=54&session=122&legislator=1897",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2037",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2037",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2204",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2204",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2037"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-6-lower-ocd-person-bcc217f5-fe08-4213-b8a5-78d1e4d64184": 0,
          "SLDL-AZ-6-lower-ocd-person-a437a3ce-e3ba-44ec-af75-3308e70f5a87": 1
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/b7d285d8-63a8-4e65-8889-2aa064eda4ff",
            "firstName": "David L.",
            "lastName": "Cook",
            "fullName": "David Cook",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/COOK.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-AZ-7-lower-ocd-person-b7d285d8-63a8-4e65-8889-2aa064eda4ff",
              "builtID": "az-lower-7",
              "externalID": "ocd-person/b7d285d8-63a8-4e65-8889-2aa064eda4ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "dcook@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repdavidcook",
              "youtube": null,
              "instagram": null,
              "facebook": "cookforarizona",
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=54&session=122&legislator=1876",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2017",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2017",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2155",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2017"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8bc6f450-7fc4-4258-bf5a-86465e85b9fb",
            "firstName": "David",
            "lastName": "Marshall",
            "fullName": "David Marshall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.chicago2.vip.townnews.com/wmicentral.com/content/tncms/assets/v3/editorial/e/e3/ee34f09e-6e18-5409-8edd-19fb587b482c/610ad5a6b0f2e.image.jpg?resize=410%2C500",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-AZ-7-lower-ocd-person-8bc6f450-7fc4-4258-bf5a-86465e85b9fb",
              "builtID": "az-lower-7",
              "externalID": "ocd-person/8bc6f450-7fc4-4258-bf5a-86465e85b9fb",
              "geometry": null
            },
            "contactInfo": {
              "email": "dmarshall@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2179",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2179"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-7-lower-ocd-person-b7d285d8-63a8-4e65-8889-2aa064eda4ff": 0,
          "SLDL-AZ-7-lower-ocd-person-8bc6f450-7fc4-4258-bf5a-86465e85b9fb": 1
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/12f66188-2560-4f3b-b247-d74ad4a78bbd",
            "firstName": "Deborah",
            "lastName": "Nardozzi",
            "fullName": "Deborah Nardozzi",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://media.kjzz.org/s3fs-public/styles/special_story_images_aspect_switcher/public/Dedorah-Nardozzi-crop-20240403.jpg?itok=L5LXMgNA",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-AZ-8-lower-ocd-person-12f66188-2560-4f3b-b247-d74ad4a78bbd",
              "builtID": "az-lower-8",
              "externalID": "ocd-person/12f66188-2560-4f3b-b247-d74ad4a78bbd",
              "geometry": null
            },
            "contactInfo": {
              "email": "dnardozzi@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/house-member/?legislature=56&session=128&legislator=2285"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/6f247e22-b02f-45f9-9b2c-27e507928c0e",
            "firstName": "Melody",
            "lastName": "Hernandez",
            "fullName": "Melody Hernandez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/HERNANDEZ_M.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-AZ-8-lower-ocd-person-6f247e22-b02f-45f9-9b2c-27e507928c0e",
              "builtID": "az-lower-8",
              "externalID": "ocd-person/6f247e22-b02f-45f9-9b2c-27e507928c0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "mhernandez@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2072",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2072",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2171",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2171",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2072"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-8-lower-ocd-person-12f66188-2560-4f3b-b247-d74ad4a78bbd": 0,
          "SLDL-AZ-8-lower-ocd-person-6f247e22-b02f-45f9-9b2c-27e507928c0e": 1
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/90a36fec-df56-4f22-bf16-790866d5b227",
            "firstName": "Lorena",
            "lastName": "Austin",
            "fullName": "Lorena Austin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://thestoryexchange.org/app/uploads/2022/08/Lorena-Austin.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-AZ-9-lower-ocd-person-90a36fec-df56-4f22-bf16-790866d5b227",
              "builtID": "az-lower-9",
              "externalID": "ocd-person/90a36fec-df56-4f22-bf16-790866d5b227",
              "geometry": null
            },
            "contactInfo": {
              "email": "laustin@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2206",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2206",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2206"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4b29d125-27ee-46c7-a6ac-ed870c1f10a7",
            "firstName": "Seth",
            "lastName": "Blattman",
            "fullName": "Seth Blattman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://cdn.runforsomething.net/candidates/2020/w9466-blattman.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-AZ-9-lower-ocd-person-4b29d125-27ee-46c7-a6ac-ed870c1f10a7",
              "builtID": "az-lower-9",
              "externalID": "ocd-person/4b29d125-27ee-46c7-a6ac-ed870c1f10a7",
              "geometry": null
            },
            "contactInfo": {
              "email": "sblattman@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2148",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2148",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2148"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-9-lower-ocd-person-90a36fec-df56-4f22-bf16-790866d5b227": 0,
          "SLDL-AZ-9-lower-ocd-person-4b29d125-27ee-46c7-a6ac-ed870c1f10a7": 1
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/e632ebef-383b-4f87-a2b6-14fdfa235df6",
            "firstName": "Barbara",
            "lastName": "Parker",
            "fullName": "Barbara Parker",
            "gender": "Female",
            "party": "Republican",
            "image": "https://static.wixstatic.com/media/32f253_3d9b123809194292a2a4dc918082082c~mv2.jpeg/v1/crop/x_0,y_345,w_2330,h_2326/fill/w_354,h_353,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Barbara%20Parker%201.jpeg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-AZ-10-lower-ocd-person-e632ebef-383b-4f87-a2b6-14fdfa235df6",
              "builtID": "az-lower-10",
              "externalID": "ocd-person/e632ebef-383b-4f87-a2b6-14fdfa235df6",
              "geometry": null
            },
            "contactInfo": {
              "email": "bparker@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2185",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2185",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2185"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/44a57af9-ca23-408a-8e36-ed0c4bac37c9",
            "firstName": "Justin",
            "lastName": "Heap",
            "fullName": "Justin Heap",
            "gender": "Male",
            "party": "Republican",
            "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/Justin_Heap_by_Gage_Skidmore.jpg/220px-Justin_Heap_by_Gage_Skidmore.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-AZ-10-lower-ocd-person-44a57af9-ca23-408a-8e36-ed0c4bac37c9",
              "builtID": "az-lower-10",
              "externalID": "ocd-person/44a57af9-ca23-408a-8e36-ed0c4bac37c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "jheap@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2167",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2167",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2167"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-10-lower-ocd-person-e632ebef-383b-4f87-a2b6-14fdfa235df6": 0,
          "SLDL-AZ-10-lower-ocd-person-44a57af9-ca23-408a-8e36-ed0c4bac37c9": 1
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/94895484-6eda-42d7-823b-a4eab17071e8",
            "firstName": "Junelle",
            "lastName": "Cavero Harnal",
            "fullName": "Junelle Cavero",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://static.wixstatic.com/media/d096d8_c9a25023041540c296a3b81e09b137e8~mv2.png/v1/crop/x_0,y_247,w_2800,h_2553/fill/w_327,h_298,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Junelle%20Cavero_KO.png",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-AZ-11-lower-ocd-person-94895484-6eda-42d7-823b-a4eab17071e8",
              "builtID": "az-lower-11",
              "externalID": "ocd-person/94895484-6eda-42d7-823b-a4eab17071e8",
              "geometry": null
            },
            "contactInfo": {
              "email": "jcavero@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/house-member/?legislature=56&session=128&legislator=2287"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a4fabea6-f50e-4fe9-8696-8b4505a481e8",
            "firstName": "Oscar",
            "lastName": "De Los Santos",
            "fullName": "Oscar De Los Santos",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://azleg.gov/alisImages/MemberPhotos/56leg/House/DE_LOS_SANTOS.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-AZ-11-lower-ocd-person-a4fabea6-f50e-4fe9-8696-8b4505a481e8",
              "builtID": "az-lower-11",
              "externalID": "ocd-person/a4fabea6-f50e-4fe9-8696-8b4505a481e8",
              "geometry": null
            },
            "contactInfo": {
              "email": "odelossantos@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2158",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2158",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2158"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-11-lower-ocd-person-94895484-6eda-42d7-823b-a4eab17071e8": 0,
          "SLDL-AZ-11-lower-ocd-person-a4fabea6-f50e-4fe9-8696-8b4505a481e8": 1
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/61dc96d8-140a-4042-b832-ac51bf0796f3",
            "firstName": "Patricia",
            "lastName": "Contreras",
            "fullName": "Patty Contreras",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Patty_Contreras.jpeg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-AZ-12-lower-ocd-person-61dc96d8-140a-4042-b832-ac51bf0796f3",
              "builtID": "az-lower-12",
              "externalID": "ocd-person/61dc96d8-140a-4042-b832-ac51bf0796f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "pcontreras@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2157",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2157",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2157"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/7bd58fc8-e6df-4091-86c6-c64f54b96c35",
            "firstName": "Anastasia",
            "lastName": "Travers",
            "fullName": "Stacey Travers",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Anastasia_Travers.JPG",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-AZ-12-lower-ocd-person-7bd58fc8-e6df-4091-86c6-c64f54b96c35",
              "builtID": "az-lower-12",
              "externalID": "ocd-person/7bd58fc8-e6df-4091-86c6-c64f54b96c35",
              "geometry": null
            },
            "contactInfo": {
              "email": "stravers@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2203",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2203",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2203"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-12-lower-ocd-person-61dc96d8-140a-4042-b832-ac51bf0796f3": 0,
          "SLDL-AZ-12-lower-ocd-person-7bd58fc8-e6df-4091-86c6-c64f54b96c35": 1
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/3bdf978d-8979-4379-95b3-7a4d4cc770ce",
            "firstName": "Jennifer",
            "lastName": "Pawlik",
            "fullName": "Jennifer Pawlik",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/PAWLIK.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-AZ-13-lower-ocd-person-3bdf978d-8979-4379-95b3-7a4d4cc770ce",
              "builtID": "az-lower-13",
              "externalID": "ocd-person/3bdf978d-8979-4379-95b3-7a4d4cc770ce",
              "geometry": null
            },
            "contactInfo": {
              "email": "jpawlik@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "jennifer_pawlik",
              "youtube": null,
              "instagram": "jennifer.pawlik",
              "facebook": "Rep.JenniferPawlik",
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=54&session=122&legislator=1902",
                "https://www.linkedin.com/in/jennifer-pawlik-b1b0a3153/",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2042",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2042",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2187",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2187",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2042"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d19ab410-5bc3-4160-b279-765e25d959ab",
            "firstName": "Julie",
            "lastName": "Willoughby",
            "fullName": "Julie Willoughby",
            "gender": "Female",
            "party": "Republican",
            "image": "https://azleg.gov/alisImages/MemberPhotos/56leg/House/WILLOUGHBY.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-AZ-13-lower-ocd-person-d19ab410-5bc3-4160-b279-765e25d959ab",
              "builtID": "az-lower-13",
              "externalID": "ocd-person/d19ab410-5bc3-4160-b279-765e25d959ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "jwilloughby@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2270",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2270",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2270"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-13-lower-ocd-person-3bdf978d-8979-4379-95b3-7a4d4cc770ce": 0,
          "SLDL-AZ-13-lower-ocd-person-d19ab410-5bc3-4160-b279-765e25d959ab": 1
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/45d0b998-57ed-4c6f-ae22-bdbc4262f3e3",
            "firstName": "Laurin",
            "lastName": "Hendrix",
            "fullName": "Laurin Hendrix",
            "gender": "Male",
            "party": "Republican",
            "image": "https://azleg.gov/alisImages/MemberPhotos/56leg/House/HENDRIX.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-AZ-14-lower-ocd-person-45d0b998-57ed-4c6f-ae22-bdbc4262f3e3",
              "builtID": "az-lower-14",
              "externalID": "ocd-person/45d0b998-57ed-4c6f-ae22-bdbc4262f3e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "lhendrix@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2172",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2172",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2172"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f94da191-f569-4d35-a9d4-edb04a1efc02",
            "firstName": "Travis W.",
            "lastName": "Grantham",
            "fullName": "Travis Grantham",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/GRANTHAM.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-AZ-14-lower-ocd-person-f94da191-f569-4d35-a9d4-edb04a1efc02",
              "builtID": "az-lower-14",
              "externalID": "ocd-person/f94da191-f569-4d35-a9d4-edb04a1efc02",
              "geometry": null
            },
            "contactInfo": {
              "email": "tgrantham@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "travisgrantham",
              "youtube": null,
              "instagram": null,
              "facebook": "granthamforaz",
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=54&session=122&legislator=1879",
                "https://www.linkedin.com/in/travis-grantham-29a1b490/",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2020",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2020",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2162",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2162",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2020"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-14-lower-ocd-person-45d0b998-57ed-4c6f-ae22-bdbc4262f3e3": 0,
          "SLDL-AZ-14-lower-ocd-person-f94da191-f569-4d35-a9d4-edb04a1efc02": 1
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/02d71038-e99b-4bbc-babd-d1de68f189f1",
            "firstName": "Jacqueline",
            "lastName": "Parker",
            "fullName": "Jacqueline Parker",
            "gender": "Female",
            "party": "Republican",
            "image": "https://azleg.gov/alisImages/MemberPhotos/56leg/House/PARKER_J.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-AZ-15-lower-ocd-person-02d71038-e99b-4bbc-babd-d1de68f189f1",
              "builtID": "az-lower-15",
              "externalID": "ocd-person/02d71038-e99b-4bbc-babd-d1de68f189f1",
              "geometry": null
            },
            "contactInfo": {
              "email": "jparker@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2069",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2069",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2186",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2186",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2186"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/17f344ec-ffe3-46fe-ba7a-8efdc5fbf2fd",
            "firstName": "Neal K.",
            "lastName": "Carter",
            "fullName": "Neal Carter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/CARTER.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-AZ-15-lower-ocd-person-17f344ec-ffe3-46fe-ba7a-8efdc5fbf2fd",
              "builtID": "az-lower-15",
              "externalID": "ocd-person/17f344ec-ffe3-46fe-ba7a-8efdc5fbf2fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "ncarter@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2098",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2152",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2152",
                "https://www.azleg.gov/house-member/?legislature=55&session=123&legislator=2098"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-15-lower-ocd-person-02d71038-e99b-4bbc-babd-d1de68f189f1": 0,
          "SLDL-AZ-15-lower-ocd-person-17f344ec-ffe3-46fe-ba7a-8efdc5fbf2fd": 1
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/378d7047-ec0c-4c8d-9c62-22a33c940a0e",
            "firstName": "Keith",
            "lastName": "Seaman",
            "fullName": "Keith Seaman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://media.azpm.org/master/image/2022/7/27/portrait/kseaman.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-AZ-16-lower-ocd-person-378d7047-ec0c-4c8d-9c62-22a33c940a0e",
              "builtID": "az-lower-16",
              "externalID": "ocd-person/378d7047-ec0c-4c8d-9c62-22a33c940a0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "kseaman@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2196",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2196",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2196"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0fa874b7-acab-4f29-b800-56bd54f375fe",
            "firstName": "Teresa A.",
            "lastName": "Martinez",
            "fullName": "Teresa Martinez",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/56leg/House/MARTINEZ.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-AZ-16-lower-ocd-person-0fa874b7-acab-4f29-b800-56bd54f375fe",
              "builtID": "az-lower-16",
              "externalID": "ocd-person/0fa874b7-acab-4f29-b800-56bd54f375fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "tmartinez@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2096",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2181",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2181",
                "https://www.azleg.gov/house-member/?legislature=55&session=123&legislator=2096"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-16-lower-ocd-person-378d7047-ec0c-4c8d-9c62-22a33c940a0e": 0,
          "SLDL-AZ-16-lower-ocd-person-0fa874b7-acab-4f29-b800-56bd54f375fe": 1
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/f200e859-7563-46d4-922f-68580e1a1fb5",
            "firstName": "Cory",
            "lastName": "McGarr",
            "fullName": "Cory McGarr",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/CoryMcGarr.png",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-AZ-17-lower-ocd-person-f200e859-7563-46d4-922f-68580e1a1fb5",
              "builtID": "az-lower-17",
              "externalID": "ocd-person/f200e859-7563-46d4-922f-68580e1a1fb5",
              "geometry": null
            },
            "contactInfo": {
              "email": "cmcgarr@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2182",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2182",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2182"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/11b313a8-0f20-4d81-8060-4ec7e65f1a29",
            "firstName": "Rachel",
            "lastName": "Jones",
            "fullName": "Rachel Jones",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Jul420221226AM_122386209_RachelJonesforStateHouse.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-AZ-17-lower-ocd-person-11b313a8-0f20-4d81-8060-4ec7e65f1a29",
              "builtID": "az-lower-17",
              "externalID": "ocd-person/11b313a8-0f20-4d81-8060-4ec7e65f1a29",
              "geometry": null
            },
            "contactInfo": {
              "email": "rachel.jones@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2173",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2173",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2173"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-17-lower-ocd-person-f200e859-7563-46d4-922f-68580e1a1fb5": 0,
          "SLDL-AZ-17-lower-ocd-person-11b313a8-0f20-4d81-8060-4ec7e65f1a29": 1
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/b2ed48a3-b9ae-42be-a2c8-22a47477acfd",
            "firstName": "Christopher Haley",
            "lastName": "Mathis",
            "fullName": "Chris Mathis",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/MATHIS.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-AZ-18-lower-ocd-person-b2ed48a3-b9ae-42be-a2c8-22a47477acfd",
              "builtID": "az-lower-18",
              "externalID": "ocd-person/b2ed48a3-b9ae-42be-a2c8-22a47477acfd",
              "geometry": null
            },
            "contactInfo": {
              "email": "cmathis@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2103",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2180",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2180",
                "https://www.azleg.gov/house-member/?legislature=55&session=123&legislator=2103"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f9216229-6490-412d-aa30-8baac5c2be3e",
            "firstName": "Nancy Burke",
            "lastName": "Gutierrez",
            "fullName": "Nancy Gutierrez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://azleg.gov/alisImages/MemberPhotos/56leg/House/GUTIERREZ.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-AZ-18-lower-ocd-person-f9216229-6490-412d-aa30-8baac5c2be3e",
              "builtID": "az-lower-18",
              "externalID": "ocd-person/f9216229-6490-412d-aa30-8baac5c2be3e",
              "geometry": null
            },
            "contactInfo": {
              "email": "ngutierrez@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2165",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2165",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2165"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-18-lower-ocd-person-b2ed48a3-b9ae-42be-a2c8-22a47477acfd": 0,
          "SLDL-AZ-18-lower-ocd-person-f9216229-6490-412d-aa30-8baac5c2be3e": 1
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/1e39adcf-868d-4722-a07b-09fcd36ad9ed",
            "firstName": "Gail",
            "lastName": "Griffin",
            "fullName": "Gail Griffin",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/GRIFFIN.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-AZ-19-lower-ocd-person-1e39adcf-868d-4722-a07b-09fcd36ad9ed",
              "builtID": "az-lower-19",
              "externalID": "ocd-person/1e39adcf-868d-4722-a07b-09fcd36ad9ed",
              "geometry": null
            },
            "contactInfo": {
              "email": "ggriffin@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "Gail-Griffin-161930173930830",
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=54&session=122&legislator=1913",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2053",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2053",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2164",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2164",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2053"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/bc52f516-a13b-4490-a12c-7b98fa3969e5",
            "firstName": "Lupe",
            "lastName": "Diaz",
            "fullName": "Lupe Diaz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/DIAZ.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-AZ-19-lower-ocd-person-bc52f516-a13b-4490-a12c-7b98fa3969e5",
              "builtID": "az-lower-19",
              "externalID": "ocd-person/bc52f516-a13b-4490-a12c-7b98fa3969e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "ldiaz@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2100",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2159",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2159",
                "https://www.azleg.gov/house-member/?legislature=55&session=123&legislator=2100"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-19-lower-ocd-person-1e39adcf-868d-4722-a07b-09fcd36ad9ed": 0,
          "SLDL-AZ-19-lower-ocd-person-bc52f516-a13b-4490-a12c-7b98fa3969e5": 1
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/6dba6afa-b274-4273-b8c6-270d27214eee",
            "firstName": "Alma",
            "lastName": "Hernandez",
            "fullName": "Alma Hernández",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/HERNANDEZ_A.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-AZ-20-lower-ocd-person-6dba6afa-b274-4273-b8c6-270d27214eee",
              "builtID": "az-lower-20",
              "externalID": "ocd-person/6dba6afa-b274-4273-b8c6-270d27214eee",
              "geometry": null
            },
            "contactInfo": {
              "email": "ahernandez@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "almaforarizona",
              "youtube": null,
              "instagram": "repahernandez",
              "facebook": "AlmaforAZ",
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=54&session=122&legislator=1893",
                "https://www.linkedin.com/in/alma-hernandez-48139a188/",
                "https://www.youtube.com/channel/UCbBfWrdnhNiGe0NPq2j-3NQ/featured",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2033",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2033",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2168",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2168",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2033"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/81d12f3a-b635-4111-be8d-a71d2f7565f2",
            "firstName": "Betty J.",
            "lastName": "Villegas",
            "fullName": "Betty Villegas",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://upload.wikimedia.org/wikipedia/commons/3/36/Betty_Villegas_by_Gage_Skidmore.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-AZ-20-lower-ocd-person-81d12f3a-b635-4111-be8d-a71d2f7565f2",
              "builtID": "az-lower-20",
              "externalID": "ocd-person/81d12f3a-b635-4111-be8d-a71d2f7565f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "bvillegas@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2274",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2274"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-20-lower-ocd-person-6dba6afa-b274-4273-b8c6-270d27214eee": 0,
          "SLDL-AZ-20-lower-ocd-person-81d12f3a-b635-4111-be8d-a71d2f7565f2": 1
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/0a4e4b0a-a16e-475b-8aa1-75088657d8c1",
            "firstName": "Consuelo",
            "lastName": "Hernandez",
            "fullName": "Consuelo Hernandez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.consueloforarizona.com/uploads/1/4/0/8/140827050/xh18695_orig.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-AZ-21-lower-ocd-person-0a4e4b0a-a16e-475b-8aa1-75088657d8c1",
              "builtID": "az-lower-21",
              "externalID": "ocd-person/0a4e4b0a-a16e-475b-8aa1-75088657d8c1",
              "geometry": null
            },
            "contactInfo": {
              "email": "chernandez@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2169",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2169",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2169"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/df482d8a-1ed9-41ae-b4ba-d73d4cd9aa39",
            "firstName": "Stephanie",
            "lastName": "Stahl Hamilton",
            "fullName": "Stephanie Stahl Hamilton",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/STAHL_HAMILTON.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-AZ-21-lower-ocd-person-df482d8a-1ed9-41ae-b4ba-d73d4cd9aa39",
              "builtID": "az-lower-21",
              "externalID": "ocd-person/df482d8a-1ed9-41ae-b4ba-d73d4cd9aa39",
              "geometry": null
            },
            "contactInfo": {
              "email": "sstahlhamilton@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2062",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2062",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=125&legislator=2093",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2199",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2199",
                "https://www.azleg.gov/senate-member/?legislature=55&legislator=2093"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-21-lower-ocd-person-0a4e4b0a-a16e-475b-8aa1-75088657d8c1": 0,
          "SLDL-AZ-21-lower-ocd-person-df482d8a-1ed9-41ae-b4ba-d73d4cd9aa39": 1
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/93db82ca-c8b1-43e4-98ce-ba52f4d0f635",
            "firstName": "Elda",
            "lastName": "Luna-Najera",
            "fullName": "Elda Luna-Nájera",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/EldaLunaNajera2024.JPG",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-AZ-22-lower-ocd-person-93db82ca-c8b1-43e4-98ce-ba52f4d0f635",
              "builtID": "az-lower-22",
              "externalID": "ocd-person/93db82ca-c8b1-43e4-98ce-ba52f4d0f635",
              "geometry": null
            },
            "contactInfo": {
              "email": "eluna-najera@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2281"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/095a6754-d7ca-4f1f-b562-974d92e7eb3c",
            "firstName": "Lupe Chavira",
            "lastName": "Contreras",
            "fullName": "Lupe Contreras",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.azleg.gov/alisImages/MemberPhotos/52leg/Senate/CONTRERAS.gif",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-AZ-22-lower-ocd-person-095a6754-d7ca-4f1f-b562-974d92e7eb3c",
              "builtID": "az-lower-22",
              "externalID": "ocd-person/095a6754-d7ca-4f1f-b562-974d92e7eb3c",
              "geometry": null
            },
            "contactInfo": {
              "email": "lcontreras@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "sencontreras",
              "youtube": null,
              "instagram": null,
              "facebook": "FriendsForLupeContreras",
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=54&session=122&legislator=1935",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=123&legislator=1973",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=125&legislator=1973",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2156",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2156",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2156"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-22-lower-ocd-person-93db82ca-c8b1-43e4-98ce-ba52f4d0f635": 0,
          "SLDL-AZ-22-lower-ocd-person-095a6754-d7ca-4f1f-b562-974d92e7eb3c": 1
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/3b6fa6b2-0a60-4f2e-9fd8-0882b04d9e0b",
            "firstName": "Mariana",
            "lastName": "Sandoval",
            "fullName": "Mariana Sandoval",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://azleg.gov/alisImages/MemberPhotos/56leg/House/SANDOVAL.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-AZ-23-lower-ocd-person-3b6fa6b2-0a60-4f2e-9fd8-0882b04d9e0b",
              "builtID": "az-lower-23",
              "externalID": "ocd-person/3b6fa6b2-0a60-4f2e-9fd8-0882b04d9e0b",
              "geometry": null
            },
            "contactInfo": {
              "email": "msandoval@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2194",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2194",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2194"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/68c38dfc-a12b-4c51-8b70-c4e02931cddf",
            "firstName": "Michele",
            "lastName": "Pena",
            "fullName": "Michele Peña",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/MichelePena.png",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-AZ-23-lower-ocd-person-68c38dfc-a12b-4c51-8b70-c4e02931cddf",
              "builtID": "az-lower-23",
              "externalID": "ocd-person/68c38dfc-a12b-4c51-8b70-c4e02931cddf",
              "geometry": null
            },
            "contactInfo": {
              "email": "mpena@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2189",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2189",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2189"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-23-lower-ocd-person-3b6fa6b2-0a60-4f2e-9fd8-0882b04d9e0b": 0,
          "SLDL-AZ-23-lower-ocd-person-68c38dfc-a12b-4c51-8b70-c4e02931cddf": 1
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/87e6206a-c16e-4038-a86f-0d5c88b993e3",
            "firstName": "Analise",
            "lastName": "Ortiz",
            "fullName": "Analise Ortiz",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://cdn.runforsomething.net/candidates/2022/04/campaign/2552.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-AZ-24-lower-ocd-person-87e6206a-c16e-4038-a86f-0d5c88b993e3",
              "builtID": "az-lower-24",
              "externalID": "ocd-person/87e6206a-c16e-4038-a86f-0d5c88b993e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "analise.ortiz@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2178",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2178",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2178"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/7a33e2bd-8394-45d9-967b-0c39430ff39b",
            "firstName": "Lydia",
            "lastName": "Hernandez",
            "fullName": "Lydia Hernandez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Lydia_Hernandez.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-AZ-24-lower-ocd-person-7a33e2bd-8394-45d9-967b-0c39430ff39b",
              "builtID": "az-lower-24",
              "externalID": "ocd-person/7a33e2bd-8394-45d9-967b-0c39430ff39b",
              "geometry": null
            },
            "contactInfo": {
              "email": "lhernandez@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2170",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2170",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2170"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-24-lower-ocd-person-87e6206a-c16e-4038-a86f-0d5c88b993e3": 0,
          "SLDL-AZ-24-lower-ocd-person-7a33e2bd-8394-45d9-967b-0c39430ff39b": 1
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/8db06598-a7e1-4b25-9e76-6a2be0d9eae3",
            "firstName": "Michael",
            "lastName": "Carbone",
            "fullName": "Michael Carbone",
            "gender": "Male",
            "party": "Republican",
            "image": "https://img1.wsimg.com/isteam/ip/90ff6c0a-eada-444e-95a8-df5a61018603/blob-1521a3a.png/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:600,cg:true",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-AZ-25-lower-ocd-person-8db06598-a7e1-4b25-9e76-6a2be0d9eae3",
              "builtID": "az-lower-25",
              "externalID": "ocd-person/8db06598-a7e1-4b25-9e76-6a2be0d9eae3",
              "geometry": null
            },
            "contactInfo": {
              "email": "mcarbone@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2153",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2153",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2153"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0b8c9973-1ae5-4af3-8a73-713c8365eccd",
            "firstName": "Timothy Marvin",
            "lastName": "Dunn",
            "fullName": "Tim Dunn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/DUNN.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-AZ-25-lower-ocd-person-0b8c9973-1ae5-4af3-8a73-713c8365eccd",
              "builtID": "az-lower-25",
              "externalID": "ocd-person/0b8c9973-1ae5-4af3-8a73-713c8365eccd",
              "geometry": null
            },
            "contactInfo": {
              "email": "tdunn@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "ElectDunn",
              "youtube": null,
              "instagram": null,
              "facebook": "TimDunnForAZHouse",
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=54&session=122&legislator=1890",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2031",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2160",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-25-lower-ocd-person-8db06598-a7e1-4b25-9e76-6a2be0d9eae3": 0,
          "SLDL-AZ-25-lower-ocd-person-0b8c9973-1ae5-4af3-8a73-713c8365eccd": 1
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/b1da595a-8c97-42f8-ae58-694c17efa3f3",
            "firstName": "Cesar",
            "lastName": "Aguilar",
            "fullName": "Cesar Aguilar",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.aguilar4az.com/uploads/1/4/0/9/140989307/published/a-7.jpg?1654980064",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-AZ-26-lower-ocd-person-b1da595a-8c97-42f8-ae58-694c17efa3f3",
              "builtID": "az-lower-26",
              "externalID": "ocd-person/b1da595a-8c97-42f8-ae58-694c17efa3f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "caguilar@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2146",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2146",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2146"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0fb2e73b-b140-4669-a9bd-b75facb342dd",
            "firstName": "Quanta",
            "lastName": "Crews",
            "fullName": "Quantá Crews",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://static.wixstatic.com/media/adaad5_bffecd22c43440d18ef9083b2464b1fd~mv2.jpg/v1/fill/w_925,h_1156,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/adaad5_bffecd22c43440d18ef9083b2464b1fd~mv2.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-AZ-26-lower-ocd-person-0fb2e73b-b140-4669-a9bd-b75facb342dd",
              "builtID": "az-lower-26",
              "externalID": "ocd-person/0fb2e73b-b140-4669-a9bd-b75facb342dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "qcrews@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2272",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2272",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2272"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-26-lower-ocd-person-b1da595a-8c97-42f8-ae58-694c17efa3f3": 0,
          "SLDL-AZ-26-lower-ocd-person-0fb2e73b-b140-4669-a9bd-b75facb342dd": 1
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/6d71c8d3-c677-4f4b-96eb-411fbc464c2c",
            "firstName": "Ben",
            "lastName": "Toma",
            "fullName": "Ben Toma",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/TOMA.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-AZ-27-lower-ocd-person-6d71c8d3-c677-4f4b-96eb-411fbc464c2c",
              "builtID": "az-lower-27",
              "externalID": "ocd-person/6d71c8d3-c677-4f4b-96eb-411fbc464c2c",
              "geometry": null
            },
            "contactInfo": {
              "email": "btoma@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBenToma",
              "youtube": null,
              "instagram": null,
              "facebook": "ben.toma.37",
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=54&session=122&legislator=1888",
                "https://www.linkedin.com/in/ben-toma-1b840a12/",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2029",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2029",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2202",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2202",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2029"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4b336587-cb71-4a49-9cf8-514cdd2771ff",
            "firstName": "Kevin",
            "lastName": "Payne",
            "fullName": "Kevin Payne",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/PAYNE.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-AZ-27-lower-ocd-person-4b336587-cb71-4a49-9cf8-514cdd2771ff",
              "builtID": "az-lower-27",
              "externalID": "ocd-person/4b336587-cb71-4a49-9cf8-514cdd2771ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "kpayne@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "kevinpayneld21",
              "youtube": null,
              "instagram": null,
              "facebook": "electkevinpayne",
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=54&session=122&legislator=1882",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2023",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2023",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2188",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2188",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2023"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-27-lower-ocd-person-6d71c8d3-c677-4f4b-96eb-411fbc464c2c": 0,
          "SLDL-AZ-27-lower-ocd-person-4b336587-cb71-4a49-9cf8-514cdd2771ff": 1
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/77be0e97-4c93-4332-a9f9-9348560fdf98",
            "firstName": "Beverly",
            "lastName": "Pingerelli",
            "fullName": "Beverly Pingerelli",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/PINGERELLI.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-AZ-28-lower-ocd-person-77be0e97-4c93-4332-a9f9-9348560fdf98",
              "builtID": "az-lower-28",
              "externalID": "ocd-person/77be0e97-4c93-4332-a9f9-9348560fdf98",
              "geometry": null
            },
            "contactInfo": {
              "email": "bpingerelli@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2071",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2071",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2191",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2191",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2071"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/6e8f2c0d-c9d3-4cb5-ae59-5264cb6d61a7",
            "firstName": "David Alan",
            "lastName": "Livingston",
            "fullName": "David Livingston",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/Senate/LIVINGSTON.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-AZ-28-lower-ocd-person-6e8f2c0d-c9d3-4cb5-ae59-5264cb6d61a7",
              "builtID": "az-lower-28",
              "externalID": "ocd-person/6e8f2c0d-c9d3-4cb5-ae59-5264cb6d61a7",
              "geometry": null
            },
            "contactInfo": {
              "email": "dlivingston@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "livingstonld22",
              "youtube": null,
              "instagram": null,
              "facebook": "votelivingston",
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=54&session=122&legislator=1938",
                "https://www.linkedin.com/in/davidalivingston/",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=123&legislator=1976",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=125&legislator=1976",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2175",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2175",
                "https://www.azleg.gov/senate-member/?legislature=55&legislator=1976"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-28-lower-ocd-person-77be0e97-4c93-4332-a9f9-9348560fdf98": 0,
          "SLDL-AZ-28-lower-ocd-person-6e8f2c0d-c9d3-4cb5-ae59-5264cb6d61a7": 1
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/2af31c38-60fa-45ff-8d84-2406888ec74c",
            "firstName": "Austin",
            "lastName": "Smith",
            "fullName": "Austin Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://azleg.gov/alisImages/MemberPhotos/56leg/House/SMITH.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-AZ-29-lower-ocd-person-2af31c38-60fa-45ff-8d84-2406888ec74c",
              "builtID": "az-lower-29",
              "externalID": "ocd-person/2af31c38-60fa-45ff-8d84-2406888ec74c",
              "geometry": null
            },
            "contactInfo": {
              "email": "austin.smith@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2198",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2198",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2198"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/37f8815d-1672-42dd-9e9b-305449d7bb8b",
            "firstName": "Steve B.",
            "lastName": "Montenegro",
            "fullName": "Steve Montenegro",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.azleg.gov/alisImages/MemberPhotos/49leg/House/MONTENEGRO.gif",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-AZ-29-lower-ocd-person-37f8815d-1672-42dd-9e9b-305449d7bb8b",
              "builtID": "az-lower-29",
              "externalID": "ocd-person/37f8815d-1672-42dd-9e9b-305449d7bb8b",
              "geometry": null
            },
            "contactInfo": {
              "email": "smontenegro@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=53&session=119&legislator=1786",
                "https://www.azleg.gov/MembersPage.asp?Member_ID=66&Legislature=52&Session_ID=115",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2183",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2183",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2183"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-29-lower-ocd-person-2af31c38-60fa-45ff-8d84-2406888ec74c": 0,
          "SLDL-AZ-29-lower-ocd-person-37f8815d-1672-42dd-9e9b-305449d7bb8b": 1
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/fd68e4c2-0463-477c-81ea-e2e36273e12b",
            "firstName": "John",
            "lastName": "Gillette",
            "fullName": "John Gillette",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.chicago2.vip.townnews.com/havasunews.com/content/tncms/assets/v3/editorial/6/1b/61ba5bf8-e969-11e5-953b-3ba1ad504638/56e5e78dbc277.image.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-AZ-30-lower-ocd-person-fd68e4c2-0463-477c-81ea-e2e36273e12b",
              "builtID": "az-lower-30",
              "externalID": "ocd-person/fd68e4c2-0463-477c-81ea-e2e36273e12b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jgillette@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2161",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2161",
                "https://www.azleg.gov/house-member/?legislature=56&session=127&legislator=2161"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/433aab5d-35be-48cc-80a9-f468d5279022",
            "firstName": "Leo",
            "lastName": "Biasiucci",
            "fullName": "Leo Biasiucci",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/BIASIUCCI.jpg",
            "title": "AZ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-AZ-30-lower-ocd-person-433aab5d-35be-48cc-80a9-f468d5279022",
              "builtID": "az-lower-30",
              "externalID": "ocd-person/433aab5d-35be-48cc-80a9-f468d5279022",
              "geometry": null
            },
            "contactInfo": {
              "email": "lbiasiucci@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Leo4AzHouse",
              "youtube": null,
              "instagram": null,
              "facebook": "Leo4AzHouse",
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=54&session=122&legislator=1894",
                "https://www.linkedin.com/in/leo4azhouse/",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2034",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2034",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2147",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=128&legislator=2147",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2034"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AZ-30-lower-ocd-person-fd68e4c2-0463-477c-81ea-e2e36273e12b": 0,
          "SLDL-AZ-30-lower-ocd-person-433aab5d-35be-48cc-80a9-f468d5279022": 1
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/e0798d71-d83a-4fa7-82c8-0644ec9f96ac",
            "firstName": "Kenneth Roy",
            "lastName": "Bennett",
            "fullName": "Ken Bennett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/56leg/Senate/BENNETT.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-AZ-1-upper-ocd-person-e0798d71-d83a-4fa7-82c8-0644ec9f96ac",
              "builtID": "az-upper-1",
              "externalID": "ocd-person/e0798d71-d83a-4fa7-82c8-0644ec9f96ac",
              "geometry": null
            },
            "contactInfo": {
              "email": "kbennett@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2117",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2117",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2117"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-1-upper-ocd-person-e0798d71-d83a-4fa7-82c8-0644ec9f96ac": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/355be281-2f88-4108-b894-62c54b67fba3",
            "firstName": "Shawnna",
            "lastName": "Bolick",
            "fullName": "Shawnna Bolick",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/BOLICK.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-AZ-2-upper-ocd-person-355be281-2f88-4108-b894-62c54b67fba3",
              "builtID": "az-upper-2",
              "externalID": "ocd-person/355be281-2f88-4108-b894-62c54b67fba3",
              "geometry": null
            },
            "contactInfo": {
              "email": "sbolick@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2273",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2273"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-2-upper-ocd-person-355be281-2f88-4108-b894-62c54b67fba3": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/b6144266-5383-491f-8c87-df8fd11805ea",
            "firstName": "John",
            "lastName": "Kavanagh",
            "fullName": "John Kavanagh",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/KAVANAGH.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-AZ-3-upper-ocd-person-b6144266-5383-491f-8c87-df8fd11805ea",
              "builtID": "az-upper-3",
              "externalID": "ocd-person/b6144266-5383-491f-8c87-df8fd11805ea",
              "geometry": null
            },
            "contactInfo": {
              "email": "jkavanagh@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "johnkavanagh_az",
              "youtube": null,
              "instagram": null,
              "facebook": "John.KavanaghAZ",
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=54&session=122&legislator=1916",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2056",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2056",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2132",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2132",
                "https://www.azleg.gov/house-member/?legislature=55&legislator=2056"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-3-upper-ocd-person-b6144266-5383-491f-8c87-df8fd11805ea": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/597de349-4f1f-4e72-a537-d0626b5abddc",
            "firstName": "Christine Porter",
            "lastName": "Marsh",
            "fullName": "Christine Marsh",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/Senate/MARSH.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-AZ-4-upper-ocd-person-597de349-4f1f-4e72-a537-d0626b5abddc",
              "builtID": "az-upper-4",
              "externalID": "ocd-person/597de349-4f1f-4e72-a537-d0626b5abddc",
              "geometry": null
            },
            "contactInfo": {
              "email": "cmarsh@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2135",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2135",
                "https://www.azleg.gov/senate-member/?legislature=55&legislator=1982"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-4-upper-ocd-person-597de349-4f1f-4e72-a537-d0626b5abddc": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/030638cb-5fcd-4637-8c59-c129e28db988",
            "firstName": "Lela",
            "lastName": "Alston",
            "fullName": "Lela Alston",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.azleg.gov/alisImages/MemberPhotos/55leg/Senate/ALSTON.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-AZ-5-upper-ocd-person-030638cb-5fcd-4637-8c59-c129e28db988",
              "builtID": "az-upper-5",
              "externalID": "ocd-person/030638cb-5fcd-4637-8c59-c129e28db988",
              "geometry": null
            },
            "contactInfo": {
              "email": "lalston@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2116",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2116",
                "https://www.azleg.gov/senate-member/?legislature=55&legislator=1978"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-5-upper-ocd-person-030638cb-5fcd-4637-8c59-c129e28db988": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/384e8b4c-d85b-4414-9372-40116a10b3b6",
            "firstName": "Theresa",
            "lastName": "Hatathlie-Delmar",
            "fullName": "Theresa Hatathlie",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/56leg/Senate/HATATHLIE.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-AZ-6-upper-ocd-person-384e8b4c-d85b-4414-9372-40116a10b3b6",
              "builtID": "az-upper-6",
              "externalID": "ocd-person/384e8b4c-d85b-4414-9372-40116a10b3b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "thatathlie@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=125&legislator=2114",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2128",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2128",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2128"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-6-upper-ocd-person-384e8b4c-d85b-4414-9372-40116a10b3b6": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/417c9599-7617-4b6d-ac0a-1d1b2a005f4e",
            "firstName": "Wendy",
            "lastName": "Rogers",
            "fullName": "Wendy Rogers",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/Senate/ROGERS.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-AZ-7-upper-ocd-person-417c9599-7617-4b6d-ac0a-1d1b2a005f4e",
              "builtID": "az-upper-7",
              "externalID": "ocd-person/417c9599-7617-4b6d-ac0a-1d1b2a005f4e",
              "geometry": null
            },
            "contactInfo": {
              "email": "wrogers@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=123&legislator=1960",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=125&legislator=1960",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2140",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2140",
                "https://www.azleg.gov/senate-member/?legislature=55&legislator=1960"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-7-upper-ocd-person-417c9599-7617-4b6d-ac0a-1d1b2a005f4e": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/535e197f-a602-4194-83cc-f820756caa17",
            "firstName": "Juan Jose",
            "lastName": "Mendez",
            "fullName": "Juan Mendez",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.azleg.gov/alisImages/MemberPhotos/52leg/House/MENDEZ.gif",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-AZ-8-upper-ocd-person-535e197f-a602-4194-83cc-f820756caa17",
              "builtID": "az-upper-8",
              "externalID": "ocd-person/535e197f-a602-4194-83cc-f820756caa17",
              "geometry": null
            },
            "contactInfo": {
              "email": "jmendez@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "mendezforaz",
              "youtube": null,
              "instagram": "sen.mendez",
              "facebook": "mendez.juan",
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=54&session=122&legislator=1942",
                "https://www.linkedin.com/in/juanmendez/",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=123&legislator=1980",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=125&legislator=1980",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2136",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2136",
                "https://www.azleg.gov/senate-member/?legislature=55&legislator=1980"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-8-upper-ocd-person-535e197f-a602-4194-83cc-f820756caa17": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/81fc749a-e845-49cd-be39-ca9795831417",
            "firstName": "Eva",
            "lastName": "Burch",
            "fullName": "Eva Burch",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/56leg/Senate/BURCH.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-AZ-9-upper-ocd-person-81fc749a-e845-49cd-be39-ca9795831417",
              "builtID": "az-upper-9",
              "externalID": "ocd-person/81fc749a-e845-49cd-be39-ca9795831417",
              "geometry": null
            },
            "contactInfo": {
              "email": "eburch@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2119",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2119",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2119"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-9-upper-ocd-person-81fc749a-e845-49cd-be39-ca9795831417": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/263ebe9c-566a-4b5c-bfbc-ea1f1d0dc3f2",
            "firstName": "David Christian",
            "lastName": "Farnsworth",
            "fullName": "Dave Farnsworth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/56leg/Senate/FARNSWORTH.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-AZ-10-upper-ocd-person-263ebe9c-566a-4b5c-bfbc-ea1f1d0dc3f2",
              "builtID": "az-upper-10",
              "externalID": "ocd-person/263ebe9c-566a-4b5c-bfbc-ea1f1d0dc3f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "dfarnsworth@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2123",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2123",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2123"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-10-upper-ocd-person-263ebe9c-566a-4b5c-bfbc-ea1f1d0dc3f2": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/6241112b-910b-4ad8-9c0d-9caad318874f",
            "firstName": "Catherine H.",
            "lastName": "Miranda",
            "fullName": "Catherine Miranda",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/56leg/Senate/MIRANDA.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-AZ-11-upper-ocd-person-6241112b-910b-4ad8-9c0d-9caad318874f",
              "builtID": "az-upper-11",
              "externalID": "ocd-person/6241112b-910b-4ad8-9c0d-9caad318874f",
              "geometry": null
            },
            "contactInfo": {
              "email": "cmiranda@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2138",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2138",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2138"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-11-upper-ocd-person-6241112b-910b-4ad8-9c0d-9caad318874f": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/f0175b18-52a0-407d-af38-c1fe118e209d",
            "firstName": "Denise",
            "lastName": "Epstein",
            "fullName": "Mitzi Epstein",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/EPSTEIN.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-AZ-12-upper-ocd-person-f0175b18-52a0-407d-af38-c1fe118e209d",
              "builtID": "az-upper-12",
              "externalID": "ocd-person/f0175b18-52a0-407d-af38-c1fe118e209d",
              "geometry": null
            },
            "contactInfo": {
              "email": "mepstein@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "MitziEpstein",
              "youtube": null,
              "instagram": null,
              "facebook": "MitziEpsteinforAZ",
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=54&session=122&legislator=1881",
                "https://www.linkedin.com/in/mitziepstein/",
                "https://www.youtube.com/channel/UCY91YAoAbmGYtBtz0tTQCMw",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2022",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2022",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2122",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2122",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2122"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-12-upper-ocd-person-f0175b18-52a0-407d-af38-c1fe118e209d": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/06adeea1-c37c-4dd1-8be5-92e9b5d3556e",
            "firstName": "Javan Daniel",
            "lastName": "Mesnard",
            "fullName": "J.D. Mesnard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/56leg/Senate/MESNARD.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-AZ-13-upper-ocd-person-06adeea1-c37c-4dd1-8be5-92e9b5d3556e",
              "builtID": "az-upper-13",
              "externalID": "ocd-person/06adeea1-c37c-4dd1-8be5-92e9b5d3556e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jmesnard@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "jdmesnard",
              "youtube": null,
              "instagram": null,
              "facebook": "javanmesnard",
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=54&session=122&legislator=1933",
                "https://www.linkedin.com/in/javanmesnard/",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=123&legislator=1971",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=125&legislator=1971",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2137",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2137",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2137"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-13-upper-ocd-person-06adeea1-c37c-4dd1-8be5-92e9b5d3556e": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/66e496a4-a407-4af1-add3-ef6899b89807",
            "firstName": "Warren H.",
            "lastName": "Petersen",
            "fullName": "Warren Petersen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/Senate/PETERSEN.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-AZ-14-upper-ocd-person-66e496a4-a407-4af1-add3-ef6899b89807",
              "builtID": "az-upper-14",
              "externalID": "ocd-person/66e496a4-a407-4af1-add3-ef6899b89807",
              "geometry": null
            },
            "contactInfo": {
              "email": "wpetersen@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2139",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2139",
                "https://www.azleg.gov/senate-member/?legislature=55&legislator=1966"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-14-upper-ocd-person-66e496a4-a407-4af1-add3-ef6899b89807": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/35f7056c-9f29-4b66-9ae0-f041fe6e7e05",
            "firstName": "Jake",
            "lastName": "Hoffman",
            "fullName": "Jake Hoffman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/56leg/Senate/HOFFMAN.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-AZ-15-upper-ocd-person-35f7056c-9f29-4b66-9ae0-f041fe6e7e05",
              "builtID": "az-upper-15",
              "externalID": "ocd-person/35f7056c-9f29-4b66-9ae0-f041fe6e7e05",
              "geometry": null
            },
            "contactInfo": {
              "email": "jake.hoffman@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2066",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2066",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2130",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2130",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2130"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-15-upper-ocd-person-35f7056c-9f29-4b66-9ae0-f041fe6e7e05": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/bde26535-68ee-4eef-bbc8-bfa8a4380015",
            "firstName": "Thomas Ray",
            "lastName": "Shope",
            "fullName": "T.J. Shope",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/54leg/House/SHOPE.png",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-AZ-16-upper-ocd-person-bde26535-68ee-4eef-bbc8-bfa8a4380015",
              "builtID": "az-upper-16",
              "externalID": "ocd-person/bde26535-68ee-4eef-bbc8-bfa8a4380015",
              "geometry": null
            },
            "contactInfo": {
              "email": "tshope@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "tjshopeforaz",
              "youtube": null,
              "instagram": null,
              "facebook": "TJSHOPEFORARIZONA",
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=54&session=122&legislator=1871",
                "https://www.linkedin.com/in/t-j-shope-76585a132/",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=123&legislator=1962",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=125&legislator=1962",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2142",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2142",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2142"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-16-upper-ocd-person-bde26535-68ee-4eef-bbc8-bfa8a4380015": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/52dbdd42-ca89-4d28-9642-f43e3b1e6358",
            "firstName": "Justine",
            "lastName": "Wadsack",
            "fullName": "Justine Wadsack",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/56leg/Senate/WADSACK.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-AZ-17-upper-ocd-person-52dbdd42-ca89-4d28-9642-f43e3b1e6358",
              "builtID": "az-upper-17",
              "externalID": "ocd-person/52dbdd42-ca89-4d28-9642-f43e3b1e6358",
              "geometry": null
            },
            "contactInfo": {
              "email": "jwadsack@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2145",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2145",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2145"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-17-upper-ocd-person-52dbdd42-ca89-4d28-9642-f43e3b1e6358": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/7ff01dde-5112-436f-a267-dd7a7f91ec9b",
            "firstName": "Priyanka Malur",
            "lastName": "Sundareshan",
            "fullName": "Priya Sundareshan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://law.arizona.edu/sites/default/files/styles/az_medium/public/images/people/Sundareshan_Priyanka_600x600web.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-AZ-18-upper-ocd-person-7ff01dde-5112-436f-a267-dd7a7f91ec9b",
              "builtID": "az-upper-18",
              "externalID": "ocd-person/7ff01dde-5112-436f-a267-dd7a7f91ec9b",
              "geometry": null
            },
            "contactInfo": {
              "email": "psundareshan@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2143",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2143",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2143"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-18-upper-ocd-person-7ff01dde-5112-436f-a267-dd7a7f91ec9b": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/51520de4-0e87-475b-91d2-99aa18addf72",
            "firstName": "David Mathew",
            "lastName": "Gowan",
            "fullName": "David Gowan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/Senate/GOWAN.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-AZ-19-upper-ocd-person-51520de4-0e87-475b-91d2-99aa18addf72",
              "builtID": "az-upper-19",
              "externalID": "ocd-person/51520de4-0e87-475b-91d2-99aa18addf72",
              "geometry": null
            },
            "contactInfo": {
              "email": "dgowan@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2127",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2127",
                "https://www.azleg.gov/senate-member/?legislature=55&legislator=1968"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-19-upper-ocd-person-51520de4-0e87-475b-91d2-99aa18addf72": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/3c965e03-1e51-4f10-a037-4fb2f35292c5",
            "firstName": "Sally Ann",
            "lastName": "Gonzales",
            "fullName": "Sally Gonzales",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://azleg.gov/alisImages/MemberPhotos/53leg/House/GONZALES.gif",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-AZ-20-upper-ocd-person-3c965e03-1e51-4f10-a037-4fb2f35292c5",
              "builtID": "az-upper-20",
              "externalID": "ocd-person/3c965e03-1e51-4f10-a037-4fb2f35292c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "sgonzales@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "GonzalesSally",
              "youtube": null,
              "instagram": null,
              "facebook": "AZStateHouse",
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=54&session=122&legislator=1919",
                "https://www.linkedin.com/in/sally-ann-gonzales-1452a532/",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=123&legislator=1957",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=125&legislator=1957",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2126",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2126",
                "https://www.azleg.gov/senate-member/?legislature=55&legislator=1957"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-20-upper-ocd-person-3c965e03-1e51-4f10-a037-4fb2f35292c5": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/6de57177-1b9e-4864-bb87-97c528842030",
            "firstName": "Rosanna Rodriguez",
            "lastName": "Gabaldon",
            "fullName": "Rosanna Gabaldón",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/54leg/House/GABALD%C3%93N.gif",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-AZ-21-upper-ocd-person-6de57177-1b9e-4864-bb87-97c528842030",
              "builtID": "az-upper-21",
              "externalID": "ocd-person/6de57177-1b9e-4864-bb87-97c528842030",
              "geometry": null
            },
            "contactInfo": {
              "email": "rgabaldon@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2125",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2125",
                "https://www.azleg.gov/senate-member/?legislature=55&legislator=1956"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-21-upper-ocd-person-6de57177-1b9e-4864-bb87-97c528842030": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/e1cd66fa-a0de-45fd-ae53-a4db7d178d21",
            "firstName": "Eva",
            "lastName": "Diaz",
            "fullName": "Eva Diaz",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/56leg/Senate/DIAZ.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-AZ-22-upper-ocd-person-e1cd66fa-a0de-45fd-ae53-a4db7d178d21",
              "builtID": "az-upper-22",
              "externalID": "ocd-person/e1cd66fa-a0de-45fd-ae53-a4db7d178d21",
              "geometry": null
            },
            "contactInfo": {
              "email": "eva.diaz@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2121",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2121",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2121"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-22-upper-ocd-person-e1cd66fa-a0de-45fd-ae53-a4db7d178d21": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/5425612b-fb2c-4857-8f75-31523345eb2b",
            "firstName": "Brian S.",
            "lastName": "Fernandez",
            "fullName": "Brian Fernandez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/House/FERNANDEZ_B.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-AZ-23-upper-ocd-person-5425612b-fb2c-4857-8f75-31523345eb2b",
              "builtID": "az-upper-23",
              "externalID": "ocd-person/5425612b-fb2c-4857-8f75-31523345eb2b",
              "geometry": null
            },
            "contactInfo": {
              "email": "bfernandez@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2101",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2124",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2124",
                "https://www.azleg.gov/house-member/?legislature=55&session=123&legislator=2101"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-23-upper-ocd-person-5425612b-fb2c-4857-8f75-31523345eb2b": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/ddfd6808-4722-4fd4-880a-2053ac4e7223",
            "firstName": "Anna",
            "lastName": "Hernandez",
            "fullName": "Anna Hernandez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://annahernandezfor24.com/assets/img/headshot.JPG",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-AZ-24-upper-ocd-person-ddfd6808-4722-4fd4-880a-2053ac4e7223",
              "builtID": "az-upper-24",
              "externalID": "ocd-person/ddfd6808-4722-4fd4-880a-2053ac4e7223",
              "geometry": null
            },
            "contactInfo": {
              "email": "anna.hernandez@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2129",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2129",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2129"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-24-upper-ocd-person-ddfd6808-4722-4fd4-880a-2053ac4e7223": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/b58992d4-8f2f-4df7-a29d-9f68f3bedbb5",
            "firstName": "Sine",
            "lastName": "Kerr",
            "fullName": "Sine Kerr",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/Senate/KERR.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-AZ-25-upper-ocd-person-b58992d4-8f2f-4df7-a29d-9f68f3bedbb5",
              "builtID": "az-upper-25",
              "externalID": "ocd-person/b58992d4-8f2f-4df7-a29d-9f68f3bedbb5",
              "geometry": null
            },
            "contactInfo": {
              "email": "skerr@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SineKerr",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=54&session=122&legislator=1929",
                "https://www.linkedin.com/in/bill-sine-kerr-2aa7b47b/",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=123&legislator=1967",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=125&legislator=1967",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2134",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2134",
                "https://www.azleg.gov/senate-member/?legislature=55&legislator=1967"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-25-upper-ocd-person-b58992d4-8f2f-4df7-a29d-9f68f3bedbb5": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/36cc8d88-e944-4889-b8ab-cf5610f3f03e",
            "firstName": "Flavio G.",
            "lastName": "Bravo",
            "fullName": "Flavio Bravo",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://chci.org/wp-content/uploads/2019/09/Bravo-Flavio.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-AZ-26-upper-ocd-person-36cc8d88-e944-4889-b8ab-cf5610f3f03e",
              "builtID": "az-upper-26",
              "externalID": "ocd-person/36cc8d88-e944-4889-b8ab-cf5610f3f03e",
              "geometry": null
            },
            "contactInfo": {
              "email": "fbravo@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2271",
                "https://www.azleg.gov/House/House-member/?legislature=56&session=127&legislator=2150",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2271",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2271"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-26-upper-ocd-person-36cc8d88-e944-4889-b8ab-cf5610f3f03e": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/f4ea36ef-3e74-4753-be45-43d3029a8a0d",
            "firstName": "Anthony T.",
            "lastName": "Kern",
            "fullName": "Anthony Kern",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.azleg.gov/alisImages/MemberPhotos/52leg/House/KERN.gif",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-AZ-27-upper-ocd-person-f4ea36ef-3e74-4753-be45-43d3029a8a0d",
              "builtID": "az-upper-27",
              "externalID": "ocd-person/f4ea36ef-3e74-4753-be45-43d3029a8a0d",
              "geometry": null
            },
            "contactInfo": {
              "email": "akern@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2133",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2133",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2133"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-27-upper-ocd-person-f4ea36ef-3e74-4753-be45-43d3029a8a0d": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/faca0001-6681-450b-9577-6185f4f49a3d",
            "firstName": "Frank P.",
            "lastName": "Carroll",
            "fullName": "Frank Carroll",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/56leg/Senate/CARROLL.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-AZ-28-upper-ocd-person-faca0001-6681-450b-9577-6185f4f49a3d",
              "builtID": "az-upper-28",
              "externalID": "ocd-person/faca0001-6681-450b-9577-6185f4f49a3d",
              "geometry": null
            },
            "contactInfo": {
              "email": "fcarroll@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repfrankcarroll",
              "youtube": null,
              "instagram": null,
              "facebook": "RepFrankCarroll",
              "urls": [
                "https://www.azleg.gov/House/House-member/?legislature=54&session=122&legislator=1906",
                "https://www.linkedin.com/in/frank-carroll-b1b2a821/",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=123&legislator=2046",
                "https://www.azleg.gov/House/House-member/?legislature=55&session=125&legislator=2046",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2120",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2120",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2120"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-28-upper-ocd-person-faca0001-6681-450b-9577-6185f4f49a3d": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/c94f542a-52e0-4831-8479-02793a97ccd9",
            "firstName": "Janae",
            "lastName": "Shamp",
            "fullName": "Janae Shamp",
            "gender": "Female",
            "party": "Republican",
            "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Janae_Shamp_by_Gage_Skidmore.jpg/800px-Janae_Shamp_by_Gage_Skidmore.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-AZ-29-upper-ocd-person-c94f542a-52e0-4831-8479-02793a97ccd9",
              "builtID": "az-upper-29",
              "externalID": "ocd-person/c94f542a-52e0-4831-8479-02793a97ccd9",
              "geometry": null
            },
            "contactInfo": {
              "email": "jshamp@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2141",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2141",
                "https://www.azleg.gov/senate-member/?legislature=56&legislator=2141"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-29-upper-ocd-person-c94f542a-52e0-4831-8479-02793a97ccd9": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/b861ef92-d8da-4510-98ec-c40f36d06acd",
            "firstName": "Sonny",
            "lastName": "Borrelli",
            "fullName": "Sonny Borrelli",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.azleg.gov/alisImages/MemberPhotos/55leg/Senate/BORRELLI.jpg",
            "title": "AZ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AZ",
              "stateFull": "Arizona",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-AZ-30-upper-ocd-person-b861ef92-d8da-4510-98ec-c40f36d06acd",
              "builtID": "az-upper-30",
              "externalID": "ocd-person/b861ef92-d8da-4510-98ec-c40f36d06acd",
              "geometry": null
            },
            "contactInfo": {
              "email": "sborrelli@azleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SonnyBorrelli",
              "youtube": null,
              "instagram": null,
              "facebook": "sonny.borrelli",
              "urls": [
                "https://www.azleg.gov/Senate/Senate-member/?legislature=54&session=122&legislator=1921",
                "https://www.linkedin.com/in/sonnyborrelli/",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=123&legislator=1959",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=55&session=125&legislator=1959",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=127&legislator=2118",
                "https://www.azleg.gov/Senate/Senate-member/?legislature=56&session=128&legislator=2118",
                "https://www.azleg.gov/senate-member/?legislature=55&legislator=1959"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AZ-30-upper-ocd-person-b861ef92-d8da-4510-98ec-c40f36d06acd": 0
        }
      }
    }
  },
  "AL": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "AL",
          "stateFull": "Alabama",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "AL",
            "stateFull": "Alabama",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-AL---",
            "builtID": "al--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-AL---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "B001319",
          "in_office": true,
          "firstName": "Katie",
          "lastName": "Britt",
          "middleName": null,
          "fullName": "Katie Boyd Britt",
          "gender": "F",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/B001319.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "AL",
            "stateFull": "Alabama",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-AL---B001319",
            "builtID": "al--",
            "externalID": "B001319",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": null,
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.britt.senate.gov/"],
            "rss_url": null
          },
          "title": "AL Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/B001319.json",
          "govtrack_id": "456874",
          "cspan_id": null,
          "votesmart_id": null,
          "icpsr_id": null,
          "crp_id": null,
          "google_entity_id": null,
          "state_rank": "junior",
          "lis_id": "S416",
          "suffix": null,
          "date_of_birth": "1982-02-02",
          "leadership_role": null,
          "fec_candidate_id": "S2AL00145",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 34,
          "total_present": 0,
          "last_updated": "2024-06-20 15:30:30 -0400",
          "missed_votes_pct": 6.14,
          "votes_with_party_pct": 94.65,
          "votes_against_party_pct": 5.35,
          "ocd_id": "ocd-division/country:us/state:al"
        },
        {
          "API_ID": "T000278",
          "in_office": true,
          "firstName": "Tommy",
          "lastName": "Tuberville",
          "middleName": null,
          "fullName": "Tommy Tuberville",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/T000278.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "AL",
            "stateFull": "Alabama",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-AL---T000278",
            "builtID": "al--",
            "externalID": "T000278",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.tuberville.senate.gov/contact/contact-form/",
            "address1": "142 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-4124",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenTuberville",
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.tuberville.senate.gov"],
            "rss_url": "https://www.tuberville.senate.gov/feed/?post_type=press_releases"
          },
          "title": "AL Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/T000278.json",
          "govtrack_id": "456796",
          "cspan_id": null,
          "votesmart_id": "188306",
          "icpsr_id": null,
          "crp_id": "N00044434",
          "google_entity_id": "/m/04tmr4",
          "state_rank": "senior",
          "lis_id": "S412",
          "suffix": null,
          "date_of_birth": "1954-09-18",
          "leadership_role": null,
          "fec_candidate_id": "S0AL00230",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 32,
          "total_present": 0,
          "last_updated": "2024-06-20 15:30:29 -0400",
          "missed_votes_pct": 5.78,
          "votes_with_party_pct": 88.04,
          "votes_against_party_pct": 11.96,
          "ocd_id": "ocd-division/country:us/state:al"
        }
      ],
      "hash": { "SENATE-AL---B001319": 0, "SENATE-AL---T000278": 1 }
    },
    "HOUSE": {
      "04": {
        "members": [
          {
            "API_ID": "A000055",
            "in_office": true,
            "firstName": "Robert",
            "lastName": "Aderholt",
            "middleName": "B.",
            "fullName": "Robert B. Aderholt",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/A000055.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-AL-04--A000055",
              "builtID": "al--04",
              "externalID": "A000055",
              "geometry": null,
              "geoid": "0104"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "266 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-4876",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Robert_Aderholt",
              "youtube": "RobertAderholt",
              "instagram": null,
              "facebook": "RobertAderholt",
              "urls": ["https://aderholt.house.gov"],
              "rss_url": "https://aderholt.house.gov/rss.xml"
            },
            "title": "AL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/A000055.json",
            "govtrack_id": "400004",
            "cspan_id": "45516",
            "votesmart_id": "441",
            "icpsr_id": "29701",
            "crp_id": "N00003028",
            "google_entity_id": "/m/024p03",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1965-07-22",
            "leadership_role": null,
            "fec_candidate_id": "H6AL04098",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "28",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 28,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.65,
            "votes_with_party_pct": 92.95,
            "votes_against_party_pct": 5.16,
            "ocd_id": "ocd-division/country:us/state:al/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AL-04--A000055": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "C001054",
            "in_office": true,
            "firstName": "Jerry",
            "lastName": "Carl",
            "middleName": null,
            "fullName": "Jerry L. Carl",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001054.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-AL-01--C001054",
              "builtID": "al--01",
              "externalID": "C001054",
              "geometry": null,
              "geoid": "0101"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1330 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4931",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJerryCarl",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://carl.house.gov"],
              "rss_url": null
            },
            "title": "AL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001054.json",
            "govtrack_id": "456799",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00044245",
            "google_entity_id": "/g/11j2j1brnb",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1958-06-17",
            "leadership_role": null,
            "fec_candidate_id": "H0AL01055",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 8,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.76,
            "votes_with_party_pct": 93.77,
            "votes_against_party_pct": 4.28,
            "ocd_id": "ocd-division/country:us/state:al/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AL-01--C001054": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "M001212",
            "in_office": true,
            "firstName": "Barry",
            "lastName": "Moore",
            "middleName": null,
            "fullName": "Barry Moore",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001212.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-AL-02--M001212",
              "builtID": "al--02",
              "externalID": "M001212",
              "geometry": null,
              "geoid": "0102"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1504 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2901",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBarryMoore",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://barrymoore.house.gov"],
              "rss_url": null
            },
            "title": "AL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001212.json",
            "govtrack_id": "456800",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00041295",
            "google_entity_id": "/g/11fkw7vjdf",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1966-09-26",
            "leadership_role": null,
            "fec_candidate_id": "H8AL02171",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 3,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.28,
            "votes_with_party_pct": 87.32,
            "votes_against_party_pct": 10.75,
            "ocd_id": "ocd-division/country:us/state:al/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AL-02--M001212": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "P000609",
            "in_office": true,
            "firstName": "Gary",
            "lastName": "Palmer",
            "middleName": null,
            "fullName": "Gary J. Palmer",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/P000609.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-AL-06--P000609",
              "builtID": "al--06",
              "externalID": "P000609",
              "geometry": null,
              "geoid": "0106"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "170 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-4921",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "USRepGaryPalmer",
              "youtube": null,
              "instagram": null,
              "facebook": "CongressmanGaryPalmer",
              "urls": ["https://palmer.house.gov"],
              "rss_url": null
            },
            "title": "AL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000609.json",
            "govtrack_id": "412608",
            "cspan_id": "76094",
            "votesmart_id": "146274",
            "icpsr_id": "21500",
            "crp_id": "N00035691",
            "google_entity_id": "/m/0115nklq",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1954-05-14",
            "leadership_role": "House Republican Policy Committee Chair",
            "fec_candidate_id": "H4AL06098",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 24,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.27,
            "votes_with_party_pct": 93.28,
            "votes_against_party_pct": 4.84,
            "ocd_id": "ocd-division/country:us/state:al/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AL-06--P000609": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "R000575",
            "in_office": true,
            "firstName": "Mike",
            "lastName": "Rogers",
            "middleName": "D.",
            "fullName": "Mike Rogers",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/R000575.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-AL-03--R000575",
              "builtID": "al--03",
              "externalID": "R000575",
              "geometry": null,
              "geoid": "0103"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2469 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3261",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMikeRogersAL",
              "youtube": "MikeRogersAL03",
              "instagram": null,
              "facebook": "CongressmanMikeDRogers",
              "urls": ["https://mikerogers.house.gov"],
              "rss_url": null
            },
            "title": "AL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/R000575.json",
            "govtrack_id": "400341",
            "cspan_id": "1014740",
            "votesmart_id": "5705",
            "icpsr_id": "20301",
            "crp_id": "N00024759",
            "google_entity_id": "/m/024n_s",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1958-07-16",
            "leadership_role": null,
            "fec_candidate_id": "H2AL03032",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "24",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 13,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.23,
            "votes_with_party_pct": 91.88,
            "votes_against_party_pct": 6.26,
            "ocd_id": "ocd-division/country:us/state:al/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AL-03--R000575": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "S001185",
            "in_office": true,
            "firstName": "Terri",
            "lastName": "Sewell",
            "middleName": null,
            "fullName": "Terri A. Sewell",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001185.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-AL-07--S001185",
              "builtID": "al--07",
              "externalID": "S001185",
              "geometry": null,
              "geoid": "0107"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1035 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2665",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepTerriSewell",
              "youtube": "RepSewell",
              "instagram": null,
              "facebook": "RepSewell",
              "urls": ["https://sewell.house.gov"],
              "rss_url": "https://sewell.house.gov/rss.xml"
            },
            "title": "AL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001185.json",
            "govtrack_id": "412396",
            "cspan_id": "623257",
            "votesmart_id": "121621",
            "icpsr_id": "21102",
            "crp_id": "N00030622",
            "google_entity_id": "/m/05pbszt",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1965-01-01",
            "leadership_role": null,
            "fec_candidate_id": "H0AL07086",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 72,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 6.82,
            "votes_with_party_pct": 96.92,
            "votes_against_party_pct": 1.13,
            "ocd_id": "ocd-division/country:us/state:al/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AL-07--S001185": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "S001220",
            "in_office": true,
            "firstName": "Dale",
            "lastName": "Strong",
            "middleName": null,
            "fullName": "Dale W. Strong",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S001220.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-AL-05--S001220",
              "builtID": "al--05",
              "externalID": "S001220",
              "geometry": null,
              "geoid": "0105"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1337 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4801",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://strong.house.gov/"],
              "rss_url": null
            },
            "title": "AL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001220.json",
            "govtrack_id": "456878",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1970-05-08",
            "leadership_role": null,
            "fec_candidate_id": "H2AL05102",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 11,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.04,
            "votes_with_party_pct": 94.24,
            "votes_against_party_pct": 3.81,
            "ocd_id": "ocd-division/country:us/state:al/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AL-05--S001220": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/a84a3fe8-5444-4db3-9f64-06ea0cba0e90",
            "firstName": "Phillip",
            "lastName": "Pettus",
            "fullName": "Phillip Pettus",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Pettus_1.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-AL-1-lower-ocd-person-a84a3fe8-5444-4db3-9f64-06ea0cba0e90",
              "builtID": "al-lower-1",
              "externalID": "ocd-person/a84a3fe8-5444-4db3-9f64-06ea0cba0e90",
              "geometry": null
            },
            "contactInfo": {
              "email": "phillip.pettus@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "771 County Road 144, Killen, AL 35645",
              "phone1": null,
              "phone2": "256-757-6679",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100481&OID_PERSON=7700",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-1-lower-ocd-person-a84a3fe8-5444-4db3-9f64-06ea0cba0e90": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/a2413268-fe7d-4a14-839c-14e9723da23a",
            "firstName": "Ben",
            "lastName": "Harrison",
            "fullName": "Ben Harrison",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Harrison_2.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-AL-2-lower-ocd-person-a2413268-fe7d-4a14-839c-14e9723da23a",
              "builtID": "al-lower-2",
              "externalID": "ocd-person/a2413268-fe7d-4a14-839c-14e9723da23a",
              "geometry": null
            },
            "contactInfo": {
              "email": "votebenharrison@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "23029 Ala. Highway 99, Elkmont, AL 35620",
              "phone1": null,
              "phone2": "256-614-9087",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-2-lower-ocd-person-a2413268-fe7d-4a14-839c-14e9723da23a": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/3828d8e6-1f22-4cb1-be51-b3d3a0c36b98",
            "firstName": "Kerry Lee",
            "lastName": "Underwood",
            "fullName": "Bubba Underwood",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Underwood_3.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-AL-3-lower-ocd-person-3828d8e6-1f22-4cb1-be51-b3d3a0c36b98",
              "builtID": "al-lower-3",
              "externalID": "ocd-person/3828d8e6-1f22-4cb1-be51-b3d3a0c36b98",
              "geometry": null
            },
            "contactInfo": {
              "email": "kerryucpa@me.com",
              "contactForm": null,
              "address1": null,
              "address2": "102 N. Main St., Tuscumbia, AL 35674",
              "phone1": null,
              "phone2": "256-248-4891",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-3-lower-ocd-person-3828d8e6-1f22-4cb1-be51-b3d3a0c36b98": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/95e63d6f-1815-4364-8817-0d26e360013f",
            "firstName": "Parker Duncan",
            "lastName": "Moore",
            "fullName": "Parker Moore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Moore_4.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-AL-4-lower-ocd-person-95e63d6f-1815-4364-8817-0d26e360013f",
              "builtID": "al-lower-4",
              "externalID": "ocd-person/95e63d6f-1815-4364-8817-0d26e360013f",
              "geometry": null
            },
            "contactInfo": {
              "email": "parker.moore@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100484&OID_PERSON=8461",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-4-lower-ocd-person-95e63d6f-1815-4364-8817-0d26e360013f": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/749e2cb2-e087-4920-aea3-b40d28f56db5",
            "firstName": "Danny",
            "lastName": "Crawford",
            "fullName": "Danny Crawford",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Crawford_5.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-AL-5-lower-ocd-person-749e2cb2-e087-4920-aea3-b40d28f56db5",
              "builtID": "al-lower-5",
              "externalID": "ocd-person/749e2cb2-e087-4920-aea3-b40d28f56db5",
              "geometry": null
            },
            "contactInfo": {
              "email": "danny.crawford@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "113 Lindsay Lane North, Athens, AL 35613",
              "phone1": null,
              "phone2": "256-871-1944",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100485&OID_PERSON=8000",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-5-lower-ocd-person-749e2cb2-e087-4920-aea3-b40d28f56db5": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/c128ed12-dd1b-4b5f-8c64-9d86bf9d6580",
            "firstName": "Andy",
            "lastName": "Whitt",
            "fullName": "Andy Whitt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Whitt_6.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-AL-6-lower-ocd-person-c128ed12-dd1b-4b5f-8c64-9d86bf9d6580",
              "builtID": "al-lower-6",
              "externalID": "ocd-person/c128ed12-dd1b-4b5f-8c64-9d86bf9d6580",
              "geometry": null
            },
            "contactInfo": {
              "email": "andy.whitt@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Harvest, AL 35749",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100486&OID_PERSON=8490",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-6-lower-ocd-person-c128ed12-dd1b-4b5f-8c64-9d86bf9d6580": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/5658aa05-6ffa-4774-a8a4-f49c169769d2",
            "firstName": "Ernie",
            "lastName": "Yarbrough",
            "fullName": "Ernie Yarbrough",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Yarbrough_7.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-AL-7-lower-ocd-person-5658aa05-6ffa-4774-a8a4-f49c169769d2",
              "builtID": "al-lower-7",
              "externalID": "ocd-person/5658aa05-6ffa-4774-a8a4-f49c169769d2",
              "geometry": null
            },
            "contactInfo": {
              "email": "yarbrough4house@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "1332 Old Highway 24, Trinity, AL 35673",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-7-lower-ocd-person-5658aa05-6ffa-4774-a8a4-f49c169769d2": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/902f0f81-a34c-4714-a3eb-bf563abbea96",
            "firstName": "Terri",
            "lastName": "Collins",
            "fullName": "Terri Collins",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Collins_8.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-AL-8-lower-ocd-person-902f0f81-a34c-4714-a3eb-bf563abbea96",
              "builtID": "al-lower-8",
              "externalID": "ocd-person/902f0f81-a34c-4714-a3eb-bf563abbea96",
              "geometry": null
            },
            "contactInfo": {
              "email": "terri@terricollins.org",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 21, Decatur, AL 35602",
              "phone1": null,
              "phone2": "256-227-0360",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100488&OID_PERSON=6661",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-8-lower-ocd-person-902f0f81-a34c-4714-a3eb-bf563abbea96": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/9877dd5b-c3ee-459f-bb8d-d9d954ae343c",
            "firstName": "Gregory Scott",
            "lastName": "Stadthagen",
            "fullName": "Scott Stadthagen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Stadthagen_9.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-AL-9-lower-ocd-person-9877dd5b-c3ee-459f-bb8d-d9d954ae343c",
              "builtID": "al-lower-9",
              "externalID": "ocd-person/9877dd5b-c3ee-459f-bb8d-d9d954ae343c",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.stadthagen@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Hartselle, AL 35640",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100489&OID_PERSON=8492",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-9-lower-ocd-person-9877dd5b-c3ee-459f-bb8d-d9d954ae343c": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/eb947430-2674-4158-8290-0d973c59e834",
            "firstName": "Marilyn",
            "lastName": "Lands",
            "fullName": "Marilyn Lands",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://alison.legislature.state.al.us/files/pdf/house/members/Lands_10.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-AL-10-lower-ocd-person-eb947430-2674-4158-8290-0d973c59e834",
              "builtID": "al-lower-10",
              "externalID": "ocd-person/eb947430-2674-4158-8290-0d973c59e834",
              "geometry": null
            },
            "contactInfo": {
              "email": "marilyn.lands@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "726 Madison St., Huntsville, AL 35801",
              "phone1": null,
              "phone2": "256-539-5441",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [""],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-10-lower-ocd-person-eb947430-2674-4158-8290-0d973c59e834": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/87484b9e-8b03-4b92-90a9-0de7b51d1240",
            "firstName": "Randall",
            "lastName": "Shedd",
            "fullName": "Randall Shedd",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Shedd_11.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-AL-11-lower-ocd-person-87484b9e-8b03-4b92-90a9-0de7b51d1240",
              "builtID": "al-lower-11",
              "externalID": "ocd-person/87484b9e-8b03-4b92-90a9-0de7b51d1240",
              "geometry": null
            },
            "contactInfo": {
              "email": "randall.shedd@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 345, Baileyton, AL 35019",
              "phone1": null,
              "phone2": "256-531-6641",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100491&OID_PERSON=7404",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-11-lower-ocd-person-87484b9e-8b03-4b92-90a9-0de7b51d1240": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/2e6c07ff-be85-441b-b733-48eb1b607675",
            "firstName": "Corey",
            "lastName": "Harbison",
            "fullName": "Corey Harbison",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Harbison_12.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-AL-12-lower-ocd-person-2e6c07ff-be85-441b-b733-48eb1b607675",
              "builtID": "al-lower-12",
              "externalID": "ocd-person/2e6c07ff-be85-441b-b733-48eb1b607675",
              "geometry": null
            },
            "contactInfo": {
              "email": "corey_harbison@yahoo.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 472, Cullman, AL 35056",
              "phone1": null,
              "phone2": "256-385-5510",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100492&OID_PERSON=7695",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-12-lower-ocd-person-2e6c07ff-be85-441b-b733-48eb1b607675": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/e6c4edb0-48af-4db8-aa0a-57570f0aef1e",
            "firstName": "Matthew",
            "lastName": "Woods",
            "fullName": "Matt Woods",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Woods_13.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-AL-13-lower-ocd-person-e6c4edb0-48af-4db8-aa0a-57570f0aef1e",
              "builtID": "al-lower-13",
              "externalID": "ocd-person/e6c4edb0-48af-4db8-aa0a-57570f0aef1e",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.woods@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1627, Jasper, AL 35502",
              "phone1": null,
              "phone2": "334-261-0495",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-13-lower-ocd-person-e6c4edb0-48af-4db8-aa0a-57570f0aef1e": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/04f7bd8e-094b-433c-9e91-7ebe64c75604",
            "firstName": "Timothy Ray",
            "lastName": "Wadsworth",
            "fullName": "Tim Wadsworth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Wadsworth_14.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-AL-14-lower-ocd-person-04f7bd8e-094b-433c-9e91-7ebe64c75604",
              "builtID": "al-lower-14",
              "externalID": "ocd-person/04f7bd8e-094b-433c-9e91-7ebe64c75604",
              "geometry": null
            },
            "contactInfo": {
              "email": "wadsworth.tim654321@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "11754 Helicon Road, Arley, AL 35541",
              "phone1": null,
              "phone2": "205-300-4008",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100494&OID_PERSON=7704",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-14-lower-ocd-person-04f7bd8e-094b-433c-9e91-7ebe64c75604": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/f2489dbf-ac57-427f-8200-4cf6e6610980",
            "firstName": "Leigh Griffith",
            "lastName": "Hulsey",
            "fullName": "Leigh Hulsey",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Hulsey_15.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-AL-15-lower-ocd-person-f2489dbf-ac57-427f-8200-4cf6e6610980",
              "builtID": "al-lower-15",
              "externalID": "ocd-person/f2489dbf-ac57-427f-8200-4cf6e6610980",
              "geometry": null
            },
            "contactInfo": {
              "email": "leigh.hulsey@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1134 County Services Drive, Pelham, AL 35124",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-15-lower-ocd-person-f2489dbf-ac57-427f-8200-4cf6e6610980": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/ed6f9cbb-3180-4e8e-b9c1-c56dd888ce38",
            "firstName": "Bryan Scott",
            "lastName": "Brinyark",
            "fullName": "Bryan Brinyark",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Brinyark_16.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-AL-16-lower-ocd-person-ed6f9cbb-3180-4e8e-b9c1-c56dd888ce38",
              "builtID": "al-lower-16",
              "externalID": "ocd-person/ed6f9cbb-3180-4e8e-b9c1-c56dd888ce38",
              "geometry": null
            },
            "contactInfo": {
              "email": "bryan.brinyark@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "31 McFarland Blvd. #200, Northport, AL 35476",
              "phone1": null,
              "phone2": "205-759-5773",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [""],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-16-lower-ocd-person-ed6f9cbb-3180-4e8e-b9c1-c56dd888ce38": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/25308613-cda5-4e1c-aef7-821ada580575",
            "firstName": "John Tracy",
            "lastName": "Estes",
            "fullName": "Tracy Estes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Estes_17.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-AL-17-lower-ocd-person-25308613-cda5-4e1c-aef7-821ada580575",
              "builtID": "al-lower-17",
              "externalID": "ocd-person/25308613-cda5-4e1c-aef7-821ada580575",
              "geometry": null
            },
            "contactInfo": {
              "email": "tracy.estes@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Winfield, AL 35594",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100497&OID_PERSON=8494",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-17-lower-ocd-person-25308613-cda5-4e1c-aef7-821ada580575": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/390ee0ed-0b5e-4f45-a740-63511c4fa3fd",
            "firstName": "Jamie Glenn",
            "lastName": "Kiel",
            "fullName": "Jamie Kiel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Kiel_18.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-AL-18-lower-ocd-person-390ee0ed-0b5e-4f45-a740-63511c4fa3fd",
              "builtID": "al-lower-18",
              "externalID": "ocd-person/390ee0ed-0b5e-4f45-a740-63511c4fa3fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "jamie.kiel@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Russellville, AL 35653",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100498&OID_PERSON=8495",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-18-lower-ocd-person-390ee0ed-0b5e-4f45-a740-63511c4fa3fd": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/9314859d-000f-447c-9aa7-4993b13e8010",
            "firstName": "Laura",
            "lastName": "Hall",
            "fullName": "Laura Hall",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Hall_19.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-AL-19-lower-ocd-person-9314859d-000f-447c-9aa7-4993b13e8010",
              "builtID": "al-lower-19",
              "externalID": "ocd-person/9314859d-000f-447c-9aa7-4993b13e8010",
              "geometry": null
            },
            "contactInfo": {
              "email": "laura.hall@alhouse.org",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 3367, Huntsville, AL 35810",
              "phone1": null,
              "phone2": "256-859-2234",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100499&OID_PERSON=1068",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-19-lower-ocd-person-9314859d-000f-447c-9aa7-4993b13e8010": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/d54b8dfc-7921-4f35-ad22-ad2efdaad100",
            "firstName": "James",
            "lastName": "Lomax",
            "fullName": "James Lomax",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Lomax_20.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-AL-20-lower-ocd-person-d54b8dfc-7921-4f35-ad22-ad2efdaad100",
              "builtID": "al-lower-20",
              "externalID": "ocd-person/d54b8dfc-7921-4f35-ad22-ad2efdaad100",
              "geometry": null
            },
            "contactInfo": {
              "email": "jamesoliverlomax@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 875, Huntsville, AL 35804",
              "phone1": null,
              "phone2": "256-503-6088",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-20-lower-ocd-person-d54b8dfc-7921-4f35-ad22-ad2efdaad100": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/e95b6e20-1207-4cdc-9875-15d14bfd72d2",
            "firstName": "Rex Brian",
            "lastName": "Reynolds",
            "fullName": "Rex Reynolds",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Reynolds_21.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-AL-21-lower-ocd-person-e95b6e20-1207-4cdc-9875-15d14bfd72d2",
              "builtID": "al-lower-21",
              "externalID": "ocd-person/e95b6e20-1207-4cdc-9875-15d14bfd72d2",
              "geometry": null
            },
            "contactInfo": {
              "email": "rex.reynolds@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Huntsville, AL",
              "phone1": null,
              "phone2": "256-539-5441",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100501&OID_PERSON=8445",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-21-lower-ocd-person-e95b6e20-1207-4cdc-9875-15d14bfd72d2": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/7e00e45e-24bf-4939-83b8-a56a90e3ea61",
            "firstName": "Ritchie",
            "lastName": "Whorton",
            "fullName": "Ritchie Whorton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Whorton_22.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-AL-22-lower-ocd-person-7e00e45e-24bf-4939-83b8-a56a90e3ea61",
              "builtID": "al-lower-22",
              "externalID": "ocd-person/7e00e45e-24bf-4939-83b8-a56a90e3ea61",
              "geometry": null
            },
            "contactInfo": {
              "email": "ritchie.whorton@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "100 E. Peachtree St., Scottsboro, AL 35768",
              "phone1": null,
              "phone2": "256-218-3090",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100502&OID_PERSON=7705",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-22-lower-ocd-person-7e00e45e-24bf-4939-83b8-a56a90e3ea61": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/c2e0fa74-36df-4d34-8203-962c546442fa",
            "firstName": "Mike",
            "lastName": "Kirkland",
            "fullName": "Mike Kirkland",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Kirkland_23.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-AL-23-lower-ocd-person-c2e0fa74-36df-4d34-8203-962c546442fa",
              "builtID": "al-lower-23",
              "externalID": "ocd-person/c2e0fa74-36df-4d34-8203-962c546442fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "kirklandmike75@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "100 E. Peachtree St., Scottsboro, AL 35768",
              "phone1": null,
              "phone2": "256-218-3090",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-23-lower-ocd-person-c2e0fa74-36df-4d34-8203-962c546442fa": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/097b764f-5d8c-462e-a962-3330c50721a4",
            "firstName": "Nathaniel",
            "lastName": "Ledbetter",
            "fullName": "Nathaniel Ledbetter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Ledbetter_24.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-AL-24-lower-ocd-person-097b764f-5d8c-462e-a962-3330c50721a4",
              "builtID": "al-lower-24",
              "externalID": "ocd-person/097b764f-5d8c-462e-a962-3330c50721a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "nathaniel.ledbetter@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 725, Rainsville, AL 35986",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100504&OID_PERSON=7698",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-24-lower-ocd-person-097b764f-5d8c-462e-a962-3330c50721a4": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/78f07b0c-9939-4e03-81f8-f851511a46cb",
            "firstName": "Phillip",
            "lastName": "Rigsby",
            "fullName": "Phillip Rigsby",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Rigsby_25.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-AL-25-lower-ocd-person-78f07b0c-9939-4e03-81f8-f851511a46cb",
              "builtID": "al-lower-25",
              "externalID": "ocd-person/78f07b0c-9939-4e03-81f8-f851511a46cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "reprigsbydistrict25@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "800 Dr. Joseph E. Lowery Blvd. Suite D, Huntsville, AL 35801",
              "phone1": null,
              "phone2": "256-714-6376",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-25-lower-ocd-person-78f07b0c-9939-4e03-81f8-f851511a46cb": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/aaffbe89-25dd-4454-85f7-81e4ed89c749",
            "firstName": "Brock",
            "lastName": "Colvin",
            "fullName": "Brock Colvin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Colvin_26.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-AL-26-lower-ocd-person-aaffbe89-25dd-4454-85f7-81e4ed89c749",
              "builtID": "al-lower-26",
              "externalID": "ocd-person/aaffbe89-25dd-4454-85f7-81e4ed89c749",
              "geometry": null
            },
            "contactInfo": {
              "email": "brock.colvin@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "404 S. Broad St. Apartment B, Albertville, AL 35950",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-26-lower-ocd-person-aaffbe89-25dd-4454-85f7-81e4ed89c749": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/70d0256c-91eb-4338-b86d-181e1db4d8f7",
            "firstName": "Jeana",
            "lastName": "Ross",
            "fullName": "Jeana Ross",
            "gender": "Female",
            "party": "Republican",
            "image": "https://education.ua.edu/wp-content/uploads/2023/02/Jeana-Ross.jpg",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-AL-27-lower-ocd-person-70d0256c-91eb-4338-b86d-181e1db4d8f7",
              "builtID": "al-lower-27",
              "externalID": "ocd-person/70d0256c-91eb-4338-b86d-181e1db4d8f7",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeana.ross@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "524 Gunter Ave., Guntersville, AL 35976",
              "phone1": null,
              "phone2": "256-582-0619",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [""],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-27-lower-ocd-person-70d0256c-91eb-4338-b86d-181e1db4d8f7": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/5071243c-3e2d-4acb-ac8d-0d3a8af3eb8a",
            "firstName": "Mack",
            "lastName": "Butler",
            "fullName": "Mack Butler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Butler_28.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-AL-28-lower-ocd-person-5071243c-3e2d-4acb-ac8d-0d3a8af3eb8a",
              "builtID": "al-lower-28",
              "externalID": "ocd-person/5071243c-3e2d-4acb-ac8d-0d3a8af3eb8a",
              "geometry": null
            },
            "contactInfo": {
              "email": "mack.butler@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 7184, Rainbow City, AL 35906",
              "phone1": null,
              "phone2": "256-312-3128",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-28-lower-ocd-person-5071243c-3e2d-4acb-ac8d-0d3a8af3eb8a": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/97514731-a41d-4328-841f-afdcaac1c6e2",
            "firstName": "Mark",
            "lastName": "Gidley",
            "fullName": "Mark Gidley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Gidley_29.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-AL-29-lower-ocd-person-97514731-a41d-4328-841f-afdcaac1c6e2",
              "builtID": "al-lower-29",
              "externalID": "ocd-person/97514731-a41d-4328-841f-afdcaac1c6e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.gidley@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 2671, Gadsden, AL 35903",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-29-lower-ocd-person-97514731-a41d-4328-841f-afdcaac1c6e2": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/63e7d9b8-136c-4ebc-bb87-19660eb7b624",
            "firstName": "Brandon Craig",
            "lastName": "Lipscomb",
            "fullName": "Craig Lipscomb",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Lipscomb_30.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-AL-30-lower-ocd-person-63e7d9b8-136c-4ebc-bb87-19660eb7b624",
              "builtID": "al-lower-30",
              "externalID": "ocd-person/63e7d9b8-136c-4ebc-bb87-19660eb7b624",
              "geometry": null
            },
            "contactInfo": {
              "email": "craig.lipscomb@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Gadsden, AL 35902",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100511&OID_PERSON=8499",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-30-lower-ocd-person-63e7d9b8-136c-4ebc-bb87-19660eb7b624": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/82715f9c-12a1-4e99-8020-a47b37dfa92b",
            "firstName": "Troy",
            "lastName": "Stubbs",
            "fullName": "Troy Stubbs",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Stubbs_31.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-AL-31-lower-ocd-person-82715f9c-12a1-4e99-8020-a47b37dfa92b",
              "builtID": "al-lower-31",
              "externalID": "ocd-person/82715f9c-12a1-4e99-8020-a47b37dfa92b",
              "geometry": null
            },
            "contactInfo": {
              "email": "troy.stubbs@hotmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "1761 E. Main St., Prattville, AL 36066",
              "phone1": null,
              "phone2": "334-451-4589",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-31-lower-ocd-person-82715f9c-12a1-4e99-8020-a47b37dfa92b": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/a1033b9f-f53e-46b8-ab4e-508d87e0d8e8",
            "firstName": "Barbara B.",
            "lastName": "Boyd",
            "fullName": "Barbara Boyd",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Boyd_32.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-AL-32-lower-ocd-person-a1033b9f-f53e-46b8-ab4e-508d87e0d8e8",
              "builtID": "al-lower-32",
              "externalID": "ocd-person/a1033b9f-f53e-46b8-ab4e-508d87e0d8e8",
              "geometry": null
            },
            "contactInfo": {
              "email": "barbara.boyd@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 4085, Anniston, AL 36204",
              "phone1": null,
              "phone2": "256-741-8683",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100513&OID_PERSON=1022",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-32-lower-ocd-person-a1033b9f-f53e-46b8-ab4e-508d87e0d8e8": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/7da5c799-bd67-458a-b3b1-a0cdda8f474e",
            "firstName": "Benjamin Wayne",
            "lastName": "Robbins",
            "fullName": "Ben Robbins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Robbins_33.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-AL-33-lower-ocd-person-7da5c799-bd67-458a-b3b1-a0cdda8f474e",
              "builtID": "al-lower-33",
              "externalID": "ocd-person/7da5c799-bd67-458a-b3b1-a0cdda8f474e",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.robbins@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "29 W. 3rd St. Suite C, Sylacauga, AL 35150",
              "phone1": null,
              "phone2": "205-907-5091",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?NAME=District%2033&OID_SPONSOR=9100514&OID_PERSON=91090&SESSNAME=",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-33-lower-ocd-person-7da5c799-bd67-458a-b3b1-a0cdda8f474e": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/0efb60b3-60e6-4248-a264-41a2a1d35036",
            "firstName": "David",
            "lastName": "Standridge",
            "fullName": "David Standridge",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Standridge_34.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-AL-34-lower-ocd-person-0efb60b3-60e6-4248-a264-41a2a1d35036",
              "builtID": "al-lower-34",
              "externalID": "ocd-person/0efb60b3-60e6-4248-a264-41a2a1d35036",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.standridge@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 76, Hayden, AL 35079",
              "phone1": null,
              "phone2": "205-543-0647",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100515&OID_PERSON=7217",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-34-lower-ocd-person-0efb60b3-60e6-4248-a264-41a2a1d35036": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/4f0a4d5d-b27a-4f33-b394-f27d306f8a42",
            "firstName": "Steve",
            "lastName": "Hurst",
            "fullName": "Steve Hurst",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Hurst_35.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-AL-35-lower-ocd-person-4f0a4d5d-b27a-4f33-b394-f27d306f8a42",
              "builtID": "al-lower-35",
              "externalID": "ocd-person/4f0a4d5d-b27a-4f33-b394-f27d306f8a42",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.hurst@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "155 Quail Run Road, Munford, AL 36268",
              "phone1": null,
              "phone2": "256-761-1935",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100516&OID_PERSON=3294",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-35-lower-ocd-person-4f0a4d5d-b27a-4f33-b394-f27d306f8a42": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/451a4cb7-3585-46d3-86c7-61b345f7a601",
            "firstName": "Randy",
            "lastName": "Wood",
            "fullName": "Randy Wood",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Wood_36.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-AL-36-lower-ocd-person-451a4cb7-3585-46d3-86c7-61b345f7a601",
              "builtID": "al-lower-36",
              "externalID": "ocd-person/451a4cb7-3585-46d3-86c7-61b345f7a601",
              "geometry": null
            },
            "contactInfo": {
              "email": "strep36@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 4432, Anniston, AL 36204",
              "phone1": null,
              "phone2": "256-239-9190",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100517&OID_PERSON=4457",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-36-lower-ocd-person-451a4cb7-3585-46d3-86c7-61b345f7a601": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/03d6bca3-9630-4173-a3c0-4ab4b5aaaedb",
            "firstName": "Bob",
            "lastName": "Fincher",
            "fullName": "Bob Fincher",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Fincher_37.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-AL-37-lower-ocd-person-03d6bca3-9630-4173-a3c0-4ab4b5aaaedb",
              "builtID": "al-lower-37",
              "externalID": "ocd-person/03d6bca3-9630-4173-a3c0-4ab4b5aaaedb",
              "geometry": null
            },
            "contactInfo": {
              "email": "rsfincher77@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "11823 County Road 59, Woodland, AL 36280",
              "phone1": null,
              "phone2": "256-449-6149",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100518&OID_PERSON=3191",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-37-lower-ocd-person-03d6bca3-9630-4173-a3c0-4ab4b5aaaedb": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/52addd6c-0137-4c38-8234-f4849008eaa6",
            "firstName": "Debbie Hamby",
            "lastName": "Wood",
            "fullName": "Debbie Wood",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Wood_38.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-AL-38-lower-ocd-person-52addd6c-0137-4c38-8234-f4849008eaa6",
              "builtID": "al-lower-38",
              "externalID": "ocd-person/52addd6c-0137-4c38-8234-f4849008eaa6",
              "geometry": null
            },
            "contactInfo": {
              "email": "debbie.wood@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Valley, AL 36854",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100519&OID_PERSON=8500",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-38-lower-ocd-person-52addd6c-0137-4c38-8234-f4849008eaa6": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/08fce57e-b511-4572-bcb8-7c712d8e7d05",
            "firstName": "Ginny",
            "lastName": "Shaver",
            "fullName": "Ginny Shaver",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Shaver_39.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-AL-39-lower-ocd-person-08fce57e-b511-4572-bcb8-7c712d8e7d05",
              "builtID": "al-lower-39",
              "externalID": "ocd-person/08fce57e-b511-4572-bcb8-7c712d8e7d05",
              "geometry": null
            },
            "contactInfo": {
              "email": "ginny.shaver@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Leesburg, AL 35983",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100520&OID_PERSON=8501",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-39-lower-ocd-person-08fce57e-b511-4572-bcb8-7c712d8e7d05": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/60ec1e11-da78-43be-90e5-5d850bf3edc7",
            "firstName": "Chad",
            "lastName": "Robertson",
            "fullName": "Chad Robertson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Robertson_40.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-AL-40-lower-ocd-person-60ec1e11-da78-43be-90e5-5d850bf3edc7",
              "builtID": "al-lower-40",
              "externalID": "ocd-person/60ec1e11-da78-43be-90e5-5d850bf3edc7",
              "geometry": null
            },
            "contactInfo": {
              "email": "chadrobertsond40@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "21 Anna Faith Lane, Heflin, AL 36264",
              "phone1": null,
              "phone2": "256-201-1116",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-40-lower-ocd-person-60ec1e11-da78-43be-90e5-5d850bf3edc7": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/3b1a631d-35d4-47bd-a37c-a98fed7a5bd4",
            "firstName": "Corley",
            "lastName": "Ellis",
            "fullName": "Corley Ellis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Ellis_41.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-AL-41-lower-ocd-person-3b1a631d-35d4-47bd-a37c-a98fed7a5bd4",
              "builtID": "al-lower-41",
              "externalID": "ocd-person/3b1a631d-35d4-47bd-a37c-a98fed7a5bd4",
              "geometry": null
            },
            "contactInfo": {
              "email": "corley.ellis@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1177, Columbiana, AL 35051",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100522&OID_PERSON=8129",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-41-lower-ocd-person-3b1a631d-35d4-47bd-a37c-a98fed7a5bd4": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/2e9b60b3-d931-412e-a9f6-803aa7c7b085",
            "firstName": "Ivan",
            "lastName": "Smith",
            "fullName": "Van Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Smith_42.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-AL-42-lower-ocd-person-2e9b60b3-d931-412e-a9f6-803aa7c7b085",
              "builtID": "al-lower-42",
              "externalID": "ocd-person/2e9b60b3-d931-412e-a9f6-803aa7c7b085",
              "geometry": null
            },
            "contactInfo": {
              "email": "ivan.smith@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2223 County Road 19 North, Prattville, AL 36067",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?NAME=Smith&OID_SPONSOR=105207&OID_PERSON=8811&SESSNAME=Regular%20Session%202021",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-42-lower-ocd-person-2e9b60b3-d931-412e-a9f6-803aa7c7b085": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/02655110-0839-4b2e-9f4b-b3a43788e275",
            "firstName": "Arnold Gaston",
            "lastName": "Mooney",
            "fullName": "Arnold Mooney",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Mooney_43.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-AL-43-lower-ocd-person-02655110-0839-4b2e-9f4b-b3a43788e275",
              "builtID": "al-lower-43",
              "externalID": "ocd-person/02655110-0839-4b2e-9f4b-b3a43788e275",
              "geometry": null
            },
            "contactInfo": {
              "email": "arnold.mooney@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 382466, Birmingham, AL 35238",
              "phone1": null,
              "phone2": "205-620-6610",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100524&OID_PERSON=7699",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-43-lower-ocd-person-02655110-0839-4b2e-9f4b-b3a43788e275": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/4aab5f96-d36a-4274-9591-9922a353c10b",
            "firstName": "J. Daniel",
            "lastName": "Garrett",
            "fullName": "Danny Garrett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Garrett_44.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-AL-44-lower-ocd-person-4aab5f96-d36a-4274-9591-9922a353c10b",
              "builtID": "al-lower-44",
              "externalID": "ocd-person/4aab5f96-d36a-4274-9591-9922a353c10b",
              "geometry": null
            },
            "contactInfo": {
              "email": "dannygarrett44@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 531, Trussville, AL 35173",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100525&OID_PERSON=7713",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-44-lower-ocd-person-4aab5f96-d36a-4274-9591-9922a353c10b": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/03612130-d61e-43dd-9768-ddca8f27d4ea",
            "firstName": "Susan",
            "lastName": "DuBose",
            "fullName": "Susan DuBose",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/DuBose_45.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-AL-45-lower-ocd-person-03612130-d61e-43dd-9768-ddca8f27d4ea",
              "builtID": "al-lower-45",
              "externalID": "ocd-person/03612130-d61e-43dd-9768-ddca8f27d4ea",
              "geometry": null
            },
            "contactInfo": {
              "email": "susandubose2@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "5378 Greystone Way, Hoover, AL 35242",
              "phone1": null,
              "phone2": "205-612-2433",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-45-lower-ocd-person-03612130-d61e-43dd-9768-ddca8f27d4ea": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/9b9fd235-1045-4935-a097-bd5e51d2c592",
            "firstName": "David",
            "lastName": "Faulkner",
            "fullName": "David Faulkner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Faulkner_46.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-AL-46-lower-ocd-person-9b9fd235-1045-4935-a097-bd5e51d2c592",
              "builtID": "al-lower-46",
              "externalID": "ocd-person/9b9fd235-1045-4935-a097-bd5e51d2c592",
              "geometry": null
            },
            "contactInfo": {
              "email": "david@davidfaulkneral46.com",
              "contactForm": null,
              "address1": null,
              "address2": "505 N. 20th St. Suite 1800, Birmingham, AL 35203",
              "phone1": null,
              "phone2": "205-870-9577",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100527&OID_PERSON=7693",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-46-lower-ocd-person-9b9fd235-1045-4935-a097-bd5e51d2c592": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/cff09a3a-b81e-433d-80f5-45f6472f4642",
            "firstName": "Mike",
            "lastName": "Shaw",
            "fullName": "Mike Shaw",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Shaw_47.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-AL-47-lower-ocd-person-cff09a3a-b81e-433d-80f5-45f6472f4642",
              "builtID": "al-lower-47",
              "externalID": "ocd-person/cff09a3a-b81e-433d-80f5-45f6472f4642",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.shaw@alabama47.com",
              "contactForm": null,
              "address1": null,
              "address2": "2040 Valleydale Road, Birmingham, AL 35244",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-47-lower-ocd-person-cff09a3a-b81e-433d-80f5-45f6472f4642": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/0d7f5989-df17-4c90-8267-4914f5598712",
            "firstName": "Jim",
            "lastName": "Carns",
            "fullName": "Jim Carns",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Carns_48.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-AL-48-lower-ocd-person-0d7f5989-df17-4c90-8267-4914f5598712",
              "builtID": "al-lower-48",
              "externalID": "ocd-person/0d7f5989-df17-4c90-8267-4914f5598712",
              "geometry": null
            },
            "contactInfo": {
              "email": "jwcarns@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 43797, Birmingham, AL 35243",
              "phone1": null,
              "phone2": "205-967-3571",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100529&OID_PERSON=1028",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-48-lower-ocd-person-0d7f5989-df17-4c90-8267-4914f5598712": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/af4e689f-d9f8-4a90-a883-1ed68e476aa9",
            "firstName": "Russell",
            "lastName": "Bedsole",
            "fullName": "Russell Bedsole",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Bedsole_49.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-AL-49-lower-ocd-person-af4e689f-d9f8-4a90-a883-1ed68e476aa9",
              "builtID": "al-lower-49",
              "externalID": "ocd-person/af4e689f-d9f8-4a90-a883-1ed68e476aa9",
              "geometry": null
            },
            "contactInfo": {
              "email": "russell.bedsole@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Alabaster, AL",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?NAME=Bedsole&OID_SPONSOR=107543&OID_PERSON=9140&SESSNAME=Regular%20Session%202021",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-49-lower-ocd-person-af4e689f-d9f8-4a90-a883-1ed68e476aa9": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/1fda4118-bfb1-4ffa-b71a-a9d131feec6b",
            "firstName": "James E.",
            "lastName": "Hill",
            "fullName": "Jim Hill",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Hill_50.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-AL-50-lower-ocd-person-1fda4118-bfb1-4ffa-b71a-a9d131feec6b",
              "builtID": "al-lower-50",
              "externalID": "ocd-person/1fda4118-bfb1-4ffa-b71a-a9d131feec6b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.hill@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 310, Moody, AL 35004",
              "phone1": null,
              "phone2": "205-629-5325",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100531&OID_PERSON=7696",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-50-lower-ocd-person-1fda4118-bfb1-4ffa-b71a-a9d131feec6b": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/3f96a690-e9a3-4774-8484-49a5a079b18c",
            "firstName": "Benjamin Allen",
            "lastName": "Treadaway",
            "fullName": "Allen Treadaway",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Treadaway_51.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-AL-51-lower-ocd-person-3f96a690-e9a3-4774-8484-49a5a079b18c",
              "builtID": "al-lower-51",
              "externalID": "ocd-person/3f96a690-e9a3-4774-8484-49a5a079b18c",
              "geometry": null
            },
            "contactInfo": {
              "email": "allen.treadaway@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 126, Morris, AL 35116",
              "phone1": null,
              "phone2": "205-254-1700",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100532&OID_PERSON=5536",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-51-lower-ocd-person-3f96a690-e9a3-4774-8484-49a5a079b18c": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/95f7541d-5429-41aa-85e5-7fc9cf33d688",
            "firstName": "Anthony L.",
            "lastName": "Daniels",
            "fullName": "Anthony Daniels",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Daniels_53.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-AL-53-lower-ocd-person-95f7541d-5429-41aa-85e5-7fc9cf33d688",
              "builtID": "al-lower-53",
              "externalID": "ocd-person/95f7541d-5429-41aa-85e5-7fc9cf33d688",
              "geometry": null
            },
            "contactInfo": {
              "email": "anthony.daniels@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 18711, Huntsville, AL 35801",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100534&OID_PERSON=7711",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-53-lower-ocd-person-95f7541d-5429-41aa-85e5-7fc9cf33d688": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/dc6e41ae-d1b3-47c7-b225-1d5ec88a9fdc",
            "firstName": "Neil",
            "lastName": "Rafferty",
            "fullName": "Neil Rafferty",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Rafferty_54.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-AL-54-lower-ocd-person-dc6e41ae-d1b3-47c7-b225-1d5ec88a9fdc",
              "builtID": "al-lower-54",
              "externalID": "ocd-person/dc6e41ae-d1b3-47c7-b225-1d5ec88a9fdc",
              "geometry": null
            },
            "contactInfo": {
              "email": "reprafferty@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 321579, Birmingham, AL 35232",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100535&OID_PERSON=8503",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-54-lower-ocd-person-dc6e41ae-d1b3-47c7-b225-1d5ec88a9fdc": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/03ab8e26-365c-4dab-8e49-df2a8b7613f8",
            "firstName": "Travis T.",
            "lastName": "Hendrix",
            "fullName": "Travis Hendrix",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Hendrix_55.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-AL-55-lower-ocd-person-03ab8e26-365c-4dab-8e49-df2a8b7613f8",
              "builtID": "al-lower-55",
              "externalID": "ocd-person/03ab8e26-365c-4dab-8e49-df2a8b7613f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "hendrixfor55@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "1710 1st Ave., Birmingham, AL 35203",
              "phone1": null,
              "phone2": "205-254-2684",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-55-lower-ocd-person-03ab8e26-365c-4dab-8e49-df2a8b7613f8": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/a86c6f40-304e-49fd-a312-3aa30660fcdf",
            "firstName": "Ontario J.",
            "lastName": "Tillman",
            "fullName": "Ontario Tillman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Tillman_56.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-AL-56-lower-ocd-person-a86c6f40-304e-49fd-a312-3aa30660fcdf",
              "builtID": "al-lower-56",
              "externalID": "ocd-person/a86c6f40-304e-49fd-a312-3aa30660fcdf",
              "geometry": null
            },
            "contactInfo": {
              "email": "ojtillman@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "2326 2nd Ave. North, Birmingham, AL 35020",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-56-lower-ocd-person-a86c6f40-304e-49fd-a312-3aa30660fcdf": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/19244bfa-5953-4304-8955-cc58d0c5d16d",
            "firstName": "Patrick L.",
            "lastName": "Sellers",
            "fullName": "Patrick Sellers",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Sellers_57.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-AL-57-lower-ocd-person-19244bfa-5953-4304-8955-cc58d0c5d16d",
              "builtID": "al-lower-57",
              "externalID": "ocd-person/19244bfa-5953-4304-8955-cc58d0c5d16d",
              "geometry": null
            },
            "contactInfo": {
              "email": "sellersfor57@yahoo.com",
              "contactForm": null,
              "address1": null,
              "address2": "3428 Maple Ave SW, Birmingham, AL 35221",
              "phone1": null,
              "phone2": "205-370-5370",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-57-lower-ocd-person-19244bfa-5953-4304-8955-cc58d0c5d16d": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/721421f8-e118-4163-bae9-72f3662696e9",
            "firstName": "Rolanda M.",
            "lastName": "Hollis",
            "fullName": "Rolanda Hollis",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Hollis_58.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-AL-58-lower-ocd-person-721421f8-e118-4163-bae9-72f3662696e9",
              "builtID": "al-lower-58",
              "externalID": "ocd-person/721421f8-e118-4163-bae9-72f3662696e9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rolanda.hollis@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "524 Red Lane Road, Birmingham, AL 35215",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100539&OID_PERSON=8220",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-58-lower-ocd-person-721421f8-e118-4163-bae9-72f3662696e9": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/63c58557-7c03-41f5-934a-866daed33ec8",
            "firstName": "Mary",
            "lastName": "Moore",
            "fullName": "Mary Moore",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Moore_59.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-AL-59-lower-ocd-person-63c58557-7c03-41f5-934a-866daed33ec8",
              "builtID": "al-lower-59",
              "externalID": "ocd-person/63c58557-7c03-41f5-934a-866daed33ec8",
              "geometry": null
            },
            "contactInfo": {
              "email": "mamoor48@bellsouth.net",
              "contactForm": null,
              "address1": null,
              "address2": "1622 36th Ave. North, Birmingham, AL 35207",
              "phone1": null,
              "phone2": "205-229-9966",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100540&OID_PERSON=4462",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-59-lower-ocd-person-63c58557-7c03-41f5-934a-866daed33ec8": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/73cebf90-bf5a-444a-857e-b5bbddc374bf",
            "firstName": "Juandalynn",
            "lastName": "Givan",
            "fullName": "Juandalynn Givan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Givan_60.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-AL-60-lower-ocd-person-73cebf90-bf5a-444a-857e-b5bbddc374bf",
              "builtID": "al-lower-60",
              "externalID": "ocd-person/73cebf90-bf5a-444a-857e-b5bbddc374bf",
              "geometry": null
            },
            "contactInfo": {
              "email": "juandalynn.givan@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 13803, Birmingham, AL 35202",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100541&OID_PERSON=6665",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-60-lower-ocd-person-73cebf90-bf5a-444a-857e-b5bbddc374bf": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/b94526a8-1564-43f5-ad38-98b795e95f02",
            "firstName": "Ronald",
            "lastName": "Bolton",
            "fullName": "Ron Bolton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Bolton_61.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-AL-61-lower-ocd-person-b94526a8-1564-43f5-ad38-98b795e95f02",
              "builtID": "al-lower-61",
              "externalID": "ocd-person/b94526a8-1564-43f5-ad38-98b795e95f02",
              "geometry": null
            },
            "contactInfo": {
              "email": "ronaldbolton1@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "11240 Davis Place, Northport, AL 35476",
              "phone1": null,
              "phone2": "205-242-9896",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-61-lower-ocd-person-b94526a8-1564-43f5-ad38-98b795e95f02": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/f470053d-6f95-4ed1-b641-5d0e7b236ed5",
            "firstName": "Bill",
            "lastName": "Lamb",
            "fullName": "Bill Lamb",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Lamb_62.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-AL-62-lower-ocd-person-f470053d-6f95-4ed1-b641-5d0e7b236ed5",
              "builtID": "al-lower-62",
              "externalID": "ocd-person/f470053d-6f95-4ed1-b641-5d0e7b236ed5",
              "geometry": null
            },
            "contactInfo": {
              "email": "lambbama@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "7402 Marigold Lane, Tuscaloosa, AL 35405",
              "phone1": null,
              "phone2": "205-361-5262",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-62-lower-ocd-person-f470053d-6f95-4ed1-b641-5d0e7b236ed5": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/ad8462f8-3f3d-483e-8171-6211cd6b6bad",
            "firstName": "Cynthia Lee",
            "lastName": "Almond",
            "fullName": "Cynthia Almond",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Almond_63.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-AL-63-lower-ocd-person-ad8462f8-3f3d-483e-8171-6211cd6b6bad",
              "builtID": "al-lower-63",
              "externalID": "ocd-person/ad8462f8-3f3d-483e-8171-6211cd6b6bad",
              "geometry": null
            },
            "contactInfo": {
              "email": "cynthia.almond@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2704 8th St., Tuscaloosa, AL 35401",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-63-lower-ocd-person-ad8462f8-3f3d-483e-8171-6211cd6b6bad": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/ef19bb65-dc3c-4815-ac71-b1ffd9abf28b",
            "firstName": "Donna",
            "lastName": "Givens",
            "fullName": "Donna Givens",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Givens_64.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-AL-64-lower-ocd-person-ef19bb65-dc3c-4815-ac71-b1ffd9abf28b",
              "builtID": "al-lower-64",
              "externalID": "ocd-person/ef19bb65-dc3c-4815-ac71-b1ffd9abf28b",
              "geometry": null
            },
            "contactInfo": {
              "email": "donnagivens64@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 826, Loxley, AL 36551",
              "phone1": null,
              "phone2": "251-952-2210",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-64-lower-ocd-person-ef19bb65-dc3c-4815-ac71-b1ffd9abf28b": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/def57a9e-a907-4e62-b990-41dc19661c06",
            "firstName": "Brett",
            "lastName": "Easterbrook",
            "fullName": "Brett Easterbrook",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Easterbrook_65.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-AL-65-lower-ocd-person-def57a9e-a907-4e62-b990-41dc19661c06",
              "builtID": "al-lower-65",
              "externalID": "ocd-person/def57a9e-a907-4e62-b990-41dc19661c06",
              "geometry": null
            },
            "contactInfo": {
              "email": "brett@bretteasterbrook.com",
              "contactForm": null,
              "address1": null,
              "address2": "Fruitdale, AL 36539",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100547&OID_PERSON=8505",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-65-lower-ocd-person-def57a9e-a907-4e62-b990-41dc19661c06": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/cb2488d5-f0b6-4e9f-948b-ff7e9f4e1907",
            "firstName": "Alan",
            "lastName": "Baker",
            "fullName": "Alan Baker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Baker_66.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-AL-66-lower-ocd-person-cb2488d5-f0b6-4e9f-948b-ff7e9f4e1907",
              "builtID": "al-lower-66",
              "externalID": "ocd-person/cb2488d5-f0b6-4e9f-948b-ff7e9f4e1907",
              "geometry": null
            },
            "contactInfo": {
              "email": "staterep@co.escambia.al.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100548&OID_PERSON=8303",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-66-lower-ocd-person-cb2488d5-f0b6-4e9f-948b-ff7e9f4e1907": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/b1e60061-fb98-4c20-8532-b734d08cdbc3",
            "firstName": "Prince",
            "lastName": "Chestnut",
            "fullName": "Prince Chestnut",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Chestnut_67.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-AL-67-lower-ocd-person-b1e60061-fb98-4c20-8532-b734d08cdbc3",
              "builtID": "al-lower-67",
              "externalID": "ocd-person/b1e60061-fb98-4c20-8532-b734d08cdbc3",
              "geometry": null
            },
            "contactInfo": {
              "email": "chestnut4house@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 628, Selma, AL 36702",
              "phone1": null,
              "phone2": "334-261-0500",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100549&OID_PERSON=8261",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-67-lower-ocd-person-b1e60061-fb98-4c20-8532-b734d08cdbc3": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/3d737a2a-6fdf-4939-838d-4f035a647e23",
            "firstName": "Thomas E.",
            "lastName": "Jackson",
            "fullName": "Thomas Jackson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Jackson_68.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-AL-68-lower-ocd-person-3d737a2a-6fdf-4939-838d-4f035a647e23",
              "builtID": "al-lower-68",
              "externalID": "ocd-person/3d737a2a-6fdf-4939-838d-4f035a647e23",
              "geometry": null
            },
            "contactInfo": {
              "email": "jthomase67@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 656, Thomasville, AL 36784",
              "phone1": null,
              "phone2": "334-636-8119",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100550&OID_PERSON=1088",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-68-lower-ocd-person-3d737a2a-6fdf-4939-838d-4f035a647e23": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/0e1643ee-370a-499d-80df-96116d63c216",
            "firstName": "Kelvin J.",
            "lastName": "Lawrence",
            "fullName": "Kelvin Lawrence",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Lawrence_69.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-AL-69-lower-ocd-person-0e1643ee-370a-499d-80df-96116d63c216",
              "builtID": "al-lower-69",
              "externalID": "ocd-person/0e1643ee-370a-499d-80df-96116d63c216",
              "geometry": null
            },
            "contactInfo": {
              "email": "kelvinj73@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "50 Miller Circle, Hayneville, AL 36040",
              "phone1": null,
              "phone2": "334-548-6125",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100551&OID_PERSON=7697",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-69-lower-ocd-person-0e1643ee-370a-499d-80df-96116d63c216": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/b57cc6b9-3627-431f-8a01-4c3c7ba973d4",
            "firstName": "Christopher John",
            "lastName": "England",
            "fullName": "Chris England",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/England_70.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-AL-70-lower-ocd-person-b57cc6b9-3627-431f-8a01-4c3c7ba973d4",
              "builtID": "al-lower-70",
              "externalID": "ocd-person/b57cc6b9-3627-431f-8a01-4c3c7ba973d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "cengland1@hotmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "1681 Ozier Drive, Tuscaloosa, AL 35405",
              "phone1": null,
              "phone2": "205-535-4859",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100552&OID_PERSON=5543",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-70-lower-ocd-person-b57cc6b9-3627-431f-8a01-4c3c7ba973d4": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/474c3552-b3cd-4015-ac15-3c6301a0ce0e",
            "firstName": "Artis J.",
            "lastName": "McCampbell",
            "fullName": "A.J. McCampbell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/McCampbell_71.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-AL-71-lower-ocd-person-474c3552-b3cd-4015-ac15-3c6301a0ce0e",
              "builtID": "al-lower-71",
              "externalID": "ocd-person/474c3552-b3cd-4015-ac15-3c6301a0ce0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "artis.mccampbell@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1469, Livingston, AL 35470",
              "phone1": null,
              "phone2": "334-652-6531",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100553&OID_PERSON=5544",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-71-lower-ocd-person-474c3552-b3cd-4015-ac15-3c6301a0ce0e": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/583c130d-a97a-48c9-9a18-03e94aa93930",
            "firstName": "Curtis L.",
            "lastName": "Travis",
            "fullName": "Curtis Travis",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Travis_72.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-AL-72-lower-ocd-person-583c130d-a97a-48c9-9a18-03e94aa93930",
              "builtID": "al-lower-72",
              "externalID": "ocd-person/583c130d-a97a-48c9-9a18-03e94aa93930",
              "geometry": null
            },
            "contactInfo": {
              "email": "cltravis620@charter.net",
              "contactForm": null,
              "address1": null,
              "address2": "620 Haymarket Lane, Tuscaloosa, AL 35405",
              "phone1": null,
              "phone2": "205-349-9183",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-72-lower-ocd-person-583c130d-a97a-48c9-9a18-03e94aa93930": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/ab6d4114-8d0a-4439-934b-10197234473c",
            "firstName": "Kenneth",
            "lastName": "Paschal",
            "fullName": "Kenneth Paschal",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Paschal_73.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-AL-73-lower-ocd-person-ab6d4114-8d0a-4439-934b-10197234473c",
              "builtID": "al-lower-73",
              "externalID": "ocd-person/ab6d4114-8d0a-4439-934b-10197234473c",
              "geometry": null
            },
            "contactInfo": {
              "email": "kenneth.paschal@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1134 County Services Drive, Pelham, AL 35124",
              "phone1": null,
              "phone2": "205-626-9458",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=9100555&OID_PERSON=97694",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-73-lower-ocd-person-ab6d4114-8d0a-4439-934b-10197234473c": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/397cb6b4-54fe-42e2-9135-7b440d925649",
            "firstName": "Phillip",
            "lastName": "Ensler",
            "fullName": "Phillip Ensler",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Ensler_74.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-AL-74-lower-ocd-person-397cb6b4-54fe-42e2-9135-7b440d925649",
              "builtID": "al-lower-74",
              "externalID": "ocd-person/397cb6b4-54fe-42e2-9135-7b440d925649",
              "geometry": null
            },
            "contactInfo": {
              "email": "phillip.ensler@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 20185, Montgomery, AL 36120",
              "phone1": null,
              "phone2": "334-235-9814",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-74-lower-ocd-person-397cb6b4-54fe-42e2-9135-7b440d925649": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/bd08d7a5-b1f3-484f-80c1-bddf2d636413",
            "firstName": "Robert Reed",
            "lastName": "Ingram",
            "fullName": "Reed Ingram",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Ingram_75.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-AL-75-lower-ocd-person-bd08d7a5-b1f3-484f-80c1-bddf2d636413",
              "builtID": "al-lower-75",
              "externalID": "ocd-person/bd08d7a5-b1f3-484f-80c1-bddf2d636413",
              "geometry": null
            },
            "contactInfo": {
              "email": "reedingram75@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "85 Meriwether Road, Pike Road, AL 36064",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100557&OID_PERSON=6167",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-75-lower-ocd-person-bd08d7a5-b1f3-484f-80c1-bddf2d636413": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/c4b563a3-2b20-4c2d-8f54-2b7ed6a3af3f",
            "firstName": "Patrice Ernita",
            "lastName": "McClammy",
            "fullName": "Penni McClammy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/mcclammy.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-AL-76-lower-ocd-person-c4b563a3-2b20-4c2d-8f54-2b7ed6a3af3f",
              "builtID": "al-lower-76",
              "externalID": "ocd-person/c4b563a3-2b20-4c2d-8f54-2b7ed6a3af3f",
              "geometry": null
            },
            "contactInfo": {
              "email": "patrice.mcclammy@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "190 Duncan Drive, Montgomery, AL 36105",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-76-lower-ocd-person-c4b563a3-2b20-4c2d-8f54-2b7ed6a3af3f": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/2ba6dc9a-31db-4371-9d69-7fa000e7a08a",
            "firstName": "TaShina",
            "lastName": "Morris",
            "fullName": "TaShina Morris",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Morris_77.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-AL-77-lower-ocd-person-2ba6dc9a-31db-4371-9d69-7fa000e7a08a",
              "builtID": "al-lower-77",
              "externalID": "ocd-person/2ba6dc9a-31db-4371-9d69-7fa000e7a08a",
              "geometry": null
            },
            "contactInfo": {
              "email": "tashina.morris@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 4122, Montgomery, AL 36103",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100559&OID_PERSON=8506",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-77-lower-ocd-person-2ba6dc9a-31db-4371-9d69-7fa000e7a08a": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/63c5bc01-757d-474a-9c75-286da0076e3e",
            "firstName": "Kenyatte",
            "lastName": "Hassell",
            "fullName": "Kenyatté Hassell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/kenyatte-hassell.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-AL-78-lower-ocd-person-63c5bc01-757d-474a-9c75-286da0076e3e",
              "builtID": "al-lower-78",
              "externalID": "ocd-person/63c5bc01-757d-474a-9c75-286da0076e3e",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.hassell@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1200 Hugh St., Montgomery, AL 36108",
              "phone1": null,
              "phone2": "334-834-8494",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?NAME=Hassell&OID_SPONSOR=9100560&OID_PERSON=998507&SESSNAME=Regular%20Session%202022",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-78-lower-ocd-person-63c5bc01-757d-474a-9c75-286da0076e3e": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/972dcfd0-f2a2-438b-b5de-276ceb398a91",
            "firstName": "Joe",
            "lastName": "Lovvorn",
            "fullName": "Joe Lovvorn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Lovvorn_79.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-AL-79-lower-ocd-person-972dcfd0-f2a2-438b-b5de-276ceb398a91",
              "builtID": "al-lower-79",
              "externalID": "ocd-person/972dcfd0-f2a2-438b-b5de-276ceb398a91",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.lovvorn@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Auburn, AL 36830",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100561&OID_PERSON=8119",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-79-lower-ocd-person-972dcfd0-f2a2-438b-b5de-276ceb398a91": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/6b644492-bb54-496c-8ad3-22ec002056d4",
            "firstName": "Chris",
            "lastName": "Blackshear",
            "fullName": "Chris Blackshear",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Blackshear_80.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-AL-80-lower-ocd-person-6b644492-bb54-496c-8ad3-22ec002056d4",
              "builtID": "al-lower-80",
              "externalID": "ocd-person/6b644492-bb54-496c-8ad3-22ec002056d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.blackshear@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1178, Smiths Station, AL 36877",
              "phone1": null,
              "phone2": "706-464-8941",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100562&OID_PERSON=7981",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-80-lower-ocd-person-6b644492-bb54-496c-8ad3-22ec002056d4": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/9ec989cb-f503-46c1-aa2a-2913e2bc16bd",
            "firstName": "Ed",
            "lastName": "Oliver",
            "fullName": "Ed Oliver",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Oliver_81.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-AL-81-lower-ocd-person-9ec989cb-f503-46c1-aa2a-2913e2bc16bd",
              "builtID": "al-lower-81",
              "externalID": "ocd-person/9ec989cb-f503-46c1-aa2a-2913e2bc16bd",
              "geometry": null
            },
            "contactInfo": {
              "email": "ed.oliver@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 277, Dadeville, AL 36853",
              "phone1": null,
              "phone2": "334-803-1889",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100563&OID_PERSON=8508",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-81-lower-ocd-person-9ec989cb-f503-46c1-aa2a-2913e2bc16bd": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/7c93c20f-6b0b-4743-8118-3cb4e9194c26",
            "firstName": "Pebblin W.",
            "lastName": "Warren",
            "fullName": "Pebblin Warren",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Warren_82.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-AL-82-lower-ocd-person-7c93c20f-6b0b-4743-8118-3cb4e9194c26",
              "builtID": "al-lower-82",
              "externalID": "ocd-person/7c93c20f-6b0b-4743-8118-3cb4e9194c26",
              "geometry": null
            },
            "contactInfo": {
              "email": "tiger9127@bellsouth.net",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1328, Tuskegee Institute, AL 36087",
              "phone1": null,
              "phone2": "334-727-9127",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100564&OID_PERSON=5238",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-82-lower-ocd-person-7c93c20f-6b0b-4743-8118-3cb4e9194c26": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/15372a5d-6a78-40f2-aebf-fd7692c41cb0",
            "firstName": "Jeremy",
            "lastName": "Gray",
            "fullName": "Jeremy Gray",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Gray_83.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-AL-83-lower-ocd-person-15372a5d-6a78-40f2-aebf-fd7692c41cb0",
              "builtID": "al-lower-83",
              "externalID": "ocd-person/15372a5d-6a78-40f2-aebf-fd7692c41cb0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeremy.gray@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Opelika, AL 36804",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100565&OID_PERSON=8509",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-83-lower-ocd-person-15372a5d-6a78-40f2-aebf-fd7692c41cb0": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/a5d0ab78-e643-4ea1-86f2-2dad948b498a",
            "firstName": "Berry",
            "lastName": "Forte",
            "fullName": "Berry Forte",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Forte_84.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-AL-84-lower-ocd-person-a5d0ab78-e643-4ea1-86f2-2dad948b498a",
              "builtID": "al-lower-84",
              "externalID": "ocd-person/a5d0ab78-e643-4ea1-86f2-2dad948b498a",
              "geometry": null
            },
            "contactInfo": {
              "email": "berry.forte@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "620 Davis Lane, Eufaula, AL 36027",
              "phone1": null,
              "phone2": "334-687-9985",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100566&OID_PERSON=6664",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-84-lower-ocd-person-a5d0ab78-e643-4ea1-86f2-2dad948b498a": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/31629cc7-81e6-4422-9aa3-89e6b9073259",
            "firstName": "Richard",
            "lastName": "Rehm",
            "fullName": "Rick Rehm",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Rehm_85.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-AL-85-lower-ocd-person-31629cc7-81e6-4422-9aa3-89e6b9073259",
              "builtID": "al-lower-85",
              "externalID": "ocd-person/31629cc7-81e6-4422-9aa3-89e6b9073259",
              "geometry": null
            },
            "contactInfo": {
              "email": "rrehm46@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "2115 Cecily St., Dothan, AL 36303",
              "phone1": null,
              "phone2": "334-797-7770",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-85-lower-ocd-person-31629cc7-81e6-4422-9aa3-89e6b9073259": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/37a5dfa8-e69f-4567-b8c0-3ea35a69f181",
            "firstName": "Paul W.",
            "lastName": "Lee",
            "fullName": "Paul Lee",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Lee_86.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-AL-86-lower-ocd-person-37a5dfa8-e69f-4567-b8c0-3ea35a69f181",
              "builtID": "al-lower-86",
              "externalID": "ocd-person/37a5dfa8-e69f-4567-b8c0-3ea35a69f181",
              "geometry": null
            },
            "contactInfo": {
              "email": "plee@wrcjobs.com",
              "contactForm": null,
              "address1": null,
              "address2": "304 Ashborough Circle, Dothan, AL 36301",
              "phone1": null,
              "phone2": "334-792-9682",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100568&OID_PERSON=6672",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-86-lower-ocd-person-37a5dfa8-e69f-4567-b8c0-3ea35a69f181": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/18af5645-53f2-4571-b24d-3173abf2b567",
            "firstName": "Jeff",
            "lastName": "Sorrells",
            "fullName": "Jeff Sorrells",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Sorrells_87.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-AL-87-lower-ocd-person-18af5645-53f2-4571-b24d-3173abf2b567",
              "builtID": "al-lower-87",
              "externalID": "ocd-person/18af5645-53f2-4571-b24d-3173abf2b567",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.sorrells@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Hartford, AL 36344",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100569&OID_PERSON=8510",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-87-lower-ocd-person-18af5645-53f2-4571-b24d-3173abf2b567": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/8394a967-dcf1-4901-a8d5-9ca120da382e",
            "firstName": "Jerry",
            "lastName": "Starnes",
            "fullName": "Jerry Starnes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Starnes_88.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-AL-88-lower-ocd-person-8394a967-dcf1-4901-a8d5-9ca120da382e",
              "builtID": "al-lower-88",
              "externalID": "ocd-person/8394a967-dcf1-4901-a8d5-9ca120da382e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jerry.starnes@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1695 Windstone Way, Prattville, AL 36066",
              "phone1": null,
              "phone2": "334-549-0782",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-88-lower-ocd-person-8394a967-dcf1-4901-a8d5-9ca120da382e": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/723a971e-91d9-4717-8f19-45f16e18690f",
            "firstName": "Marcus",
            "lastName": "Paramore",
            "fullName": "Marcus Paramore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Paramore_89.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-AL-89-lower-ocd-person-723a971e-91d9-4717-8f19-45f16e18690f",
              "builtID": "al-lower-89",
              "externalID": "ocd-person/723a971e-91d9-4717-8f19-45f16e18690f",
              "geometry": null
            },
            "contactInfo": {
              "email": "marcusforhouse@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 211, Troy, AL 36081",
              "phone1": null,
              "phone2": "334-546-1242",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-89-lower-ocd-person-723a971e-91d9-4717-8f19-45f16e18690f": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/39a26498-6bb6-4627-8c49-d9c453c9aab8",
            "firstName": "Chris",
            "lastName": "Sells",
            "fullName": "Chris Sells",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Sells_90.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-AL-90-lower-ocd-person-39a26498-6bb6-4627-8c49-d9c453c9aab8",
              "builtID": "al-lower-90",
              "externalID": "ocd-person/39a26498-6bb6-4627-8c49-d9c453c9aab8",
              "geometry": null
            },
            "contactInfo": {
              "email": "csea@centurytel.net",
              "contactForm": null,
              "address1": null,
              "address2": "1609 E. Commerce St., Greenville, AL 36037",
              "phone1": null,
              "phone2": "334-371-9304",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100572&OID_PERSON=7702",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-90-lower-ocd-person-39a26498-6bb6-4627-8c49-d9c453c9aab8": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/d9d5a3a8-71f6-4c2e-8805-3fa715b315f4",
            "firstName": "Rhett",
            "lastName": "Marques",
            "fullName": "Rhett Marques",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Marques_91.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-AL-91-lower-ocd-person-d9d5a3a8-71f6-4c2e-8805-3fa715b315f4",
              "builtID": "al-lower-91",
              "externalID": "ocd-person/d9d5a3a8-71f6-4c2e-8805-3fa715b315f4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rhett.marques@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Enterprise, AL 36330",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100573&OID_PERSON=8513",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-91-lower-ocd-person-d9d5a3a8-71f6-4c2e-8805-3fa715b315f4": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/ff14c161-755d-44c8-a1e5-c2e3930a5317",
            "firstName": "Matthew",
            "lastName": "Hammett",
            "fullName": "Matthew Hammett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Hammett_92.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-AL-92-lower-ocd-person-ff14c161-755d-44c8-a1e5-c2e3930a5317",
              "builtID": "al-lower-92",
              "externalID": "ocd-person/ff14c161-755d-44c8-a1e5-c2e3930a5317",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.hammett@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "24901 County Road 62, Dozier, AL 36028",
              "phone1": null,
              "phone2": "334-496-3649",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-92-lower-ocd-person-ff14c161-755d-44c8-a1e5-c2e3930a5317": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/6b191fda-698d-4061-8396-fef9771847ee",
            "firstName": "Steve",
            "lastName": "Clouse",
            "fullName": "Steve Clouse",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Clouse_93.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-AL-93-lower-ocd-person-6b191fda-698d-4061-8396-fef9771847ee",
              "builtID": "al-lower-93",
              "externalID": "ocd-person/6b191fda-698d-4061-8396-fef9771847ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.clouse@alhouse.org",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 818, Ozark, AL 36361",
              "phone1": null,
              "phone2": "334-774-7384",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100575&OID_PERSON=1035",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-93-lower-ocd-person-6b191fda-698d-4061-8396-fef9771847ee": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/48d9f4e1-ec8b-4353-9207-a70db34449a9",
            "firstName": "Jennifer",
            "lastName": "Fidler",
            "fullName": "Jennifer Fidler",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Fidler_94.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-AL-94-lower-ocd-person-48d9f4e1-ec8b-4353-9207-a70db34449a9",
              "builtID": "al-lower-94",
              "externalID": "ocd-person/48d9f4e1-ec8b-4353-9207-a70db34449a9",
              "geometry": null
            },
            "contactInfo": {
              "email": "jennifer.fidler@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1100 Fairhope Ave., Fairhope, AL 36532",
              "phone1": null,
              "phone2": "251-620-9384",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-94-lower-ocd-person-48d9f4e1-ec8b-4353-9207-a70db34449a9": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/3bc2e263-71cf-41e2-80c5-3f97cad999ff",
            "firstName": "Frances",
            "lastName": "Holk-Jones",
            "fullName": "Frances Holk-Jones",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Holk-Jones_95.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-AL-95-lower-ocd-person-3bc2e263-71cf-41e2-80c5-3f97cad999ff",
              "builtID": "al-lower-95",
              "externalID": "ocd-person/3bc2e263-71cf-41e2-80c5-3f97cad999ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "frances.holk-jones@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "315 E. Laurel Ave. Suite 101, Foley, AL 36535",
              "phone1": null,
              "phone2": "251-620-7470",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-95-lower-ocd-person-3bc2e263-71cf-41e2-80c5-3f97cad999ff": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/52046189-0464-4140-9cdf-b6c66312d49d",
            "firstName": "Matthew",
            "lastName": "Simpson",
            "fullName": "Matt Simpson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Simpson_96.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-AL-96-lower-ocd-person-52046189-0464-4140-9cdf-b6c66312d49d",
              "builtID": "al-lower-96",
              "externalID": "ocd-person/52046189-0464-4140-9cdf-b6c66312d49d",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.simpson@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1791, Daphne, AL 36526",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100578&OID_PERSON=8514",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-96-lower-ocd-person-52046189-0464-4140-9cdf-b6c66312d49d": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/4cb1aeb5-d822-4f2c-a7e6-ae2a132acf17",
            "firstName": "Adline Cecelia",
            "lastName": "Clarke",
            "fullName": "Adline Clarke",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Clarke_97.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-AL-97-lower-ocd-person-4cb1aeb5-d822-4f2c-a7e6-ae2a132acf17",
              "builtID": "al-lower-97",
              "externalID": "ocd-person/4cb1aeb5-d822-4f2c-a7e6-ae2a132acf17",
              "geometry": null
            },
            "contactInfo": {
              "email": "adline.clarke@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 40758, Mobile, AL 36640",
              "phone1": null,
              "phone2": "251-377-9990",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100579&OID_PERSON=7425",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-97-lower-ocd-person-4cb1aeb5-d822-4f2c-a7e6-ae2a132acf17": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/947c7906-7825-442f-9241-d777b9bbe999",
            "firstName": "Napoleon",
            "lastName": "Bracy",
            "fullName": "Napoleon Bracy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Bracy_98.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-AL-98-lower-ocd-person-947c7906-7825-442f-9241-d777b9bbe999",
              "builtID": "al-lower-98",
              "externalID": "ocd-person/947c7906-7825-442f-9241-d777b9bbe999",
              "geometry": null
            },
            "contactInfo": {
              "email": "napoleon.bracy@alhouse.org",
              "contactForm": null,
              "address1": null,
              "address2": "104 S. Lawrence St., Mobile, AL 36602",
              "phone1": null,
              "phone2": "251-208-5480",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100580&OID_PERSON=6657",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-98-lower-ocd-person-947c7906-7825-442f-9241-d777b9bbe999": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/1c825e04-ad1e-4009-a8d8-157abc001f3e",
            "firstName": "Samuel Leon",
            "lastName": "Jones",
            "fullName": "Sam Jones",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Jones_99.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-AL-99-lower-ocd-person-1c825e04-ad1e-4009-a8d8-157abc001f3e",
              "builtID": "al-lower-99",
              "externalID": "ocd-person/1c825e04-ad1e-4009-a8d8-157abc001f3e",
              "geometry": null
            },
            "contactInfo": {
              "email": "sam.jones@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Mobile, AL 36640",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100581&OID_PERSON=8515",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-99-lower-ocd-person-1c825e04-ad1e-4009-a8d8-157abc001f3e": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/41debaee-f705-453d-a46f-3e8282b8a4b0",
            "firstName": "Mark",
            "lastName": "Shirey",
            "fullName": "Mark Shirey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Shirey_100.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-AL-100-lower-ocd-person-41debaee-f705-453d-a46f-3e8282b8a4b0",
              "builtID": "al-lower-100",
              "externalID": "ocd-person/41debaee-f705-453d-a46f-3e8282b8a4b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.shirey@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 301 312 Schillinger Road South, Suite T, Mobile, AL 36608",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-100-lower-ocd-person-41debaee-f705-453d-a46f-3e8282b8a4b0": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/f4133b43-f822-4d95-b1e0-a0a524de56f6",
            "firstName": "Christopher Paul",
            "lastName": "Pringle",
            "fullName": "Chris Pringle",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Pringle_101.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-AL-101-lower-ocd-person-f4133b43-f822-4d95-b1e0-a0a524de56f6",
              "builtID": "al-lower-101",
              "externalID": "ocd-person/f4133b43-f822-4d95-b1e0-a0a524de56f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.pringle@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4 Princess Anne Road, Mobile, AL 36608",
              "phone1": null,
              "phone2": "251-341-1110",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100583&OID_PERSON=1109",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-101-lower-ocd-person-f4133b43-f822-4d95-b1e0-a0a524de56f6": 0
        }
      },
      "102": {
        "members": [
          {
            "API_ID": "ocd-person/6a24df4b-b8e2-449a-8f18-d12d15f2bc5b",
            "firstName": "Shane",
            "lastName": "Stringer",
            "fullName": "Shane Stringer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Stringer_102.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "102",
              "chamber": "lower",
              "hashID": "SLDL-AL-102-lower-ocd-person-6a24df4b-b8e2-449a-8f18-d12d15f2bc5b",
              "builtID": "al-lower-102",
              "externalID": "ocd-person/6a24df4b-b8e2-449a-8f18-d12d15f2bc5b",
              "geometry": null
            },
            "contactInfo": {
              "email": "shane.stringer@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "104 S. Lawrence St., Mobile, AL 36602",
              "phone1": null,
              "phone2": "251-208-5480",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100584&OID_PERSON=8516",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-102-lower-ocd-person-6a24df4b-b8e2-449a-8f18-d12d15f2bc5b": 0
        }
      },
      "103": {
        "members": [
          {
            "API_ID": "ocd-person/375419e1-4626-4a9a-92ed-15962de5be09",
            "firstName": "Barbara",
            "lastName": "Drummond",
            "fullName": "Barbara Drummond",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Drummond_103.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "103",
              "chamber": "lower",
              "hashID": "SLDL-AL-103-lower-ocd-person-375419e1-4626-4a9a-92ed-15962de5be09",
              "builtID": "al-lower-103",
              "externalID": "ocd-person/375419e1-4626-4a9a-92ed-15962de5be09",
              "geometry": null
            },
            "contactInfo": {
              "email": "drummondbarbara@att.net",
              "contactForm": null,
              "address1": null,
              "address2": "1266 Horton Drive, Mobile, AL 36605",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100585&OID_PERSON=7712",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-103-lower-ocd-person-375419e1-4626-4a9a-92ed-15962de5be09": 0
        }
      },
      "104": {
        "members": [
          {
            "API_ID": "ocd-person/050e1a72-402d-46ae-a9c3-1c5dff003ee6",
            "firstName": "Julia Margaret",
            "lastName": "Wilcox",
            "fullName": "Margie Wilcox",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Wilcox_104.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "104",
              "chamber": "lower",
              "hashID": "SLDL-AL-104-lower-ocd-person-050e1a72-402d-46ae-a9c3-1c5dff003ee6",
              "builtID": "al-lower-104",
              "externalID": "ocd-person/050e1a72-402d-46ae-a9c3-1c5dff003ee6",
              "geometry": null
            },
            "contactInfo": {
              "email": "margie.wilcox@alhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2101 Marchfield Drive East, Mobile, AL 36693",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100586&OID_PERSON=7547",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-104-lower-ocd-person-050e1a72-402d-46ae-a9c3-1c5dff003ee6": 0
        }
      },
      "105": {
        "members": [
          {
            "API_ID": "ocd-person/04a1794d-93f0-435f-8c68-1b435f2fc1a0",
            "firstName": "Chip",
            "lastName": "Brown",
            "fullName": "Chip Brown",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Brown_105.png",
            "title": "AL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "105",
              "chamber": "lower",
              "hashID": "SLDL-AL-105-lower-ocd-person-04a1794d-93f0-435f-8c68-1b435f2fc1a0",
              "builtID": "al-lower-105",
              "externalID": "ocd-person/04a1794d-93f0-435f-8c68-1b435f2fc1a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "chip@chipbrown2018.com",
              "contactForm": null,
              "address1": null,
              "address2": "104 S. Lawrence St., Mobile, AL 36602",
              "phone1": null,
              "phone2": "251-208-5480",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100587&OID_PERSON=8517",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AL-105-lower-ocd-person-04a1794d-93f0-435f-8c68-1b435f2fc1a0": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/2f934a90-dc27-4289-b571-36e80ea4384b",
            "firstName": "Timothy Ivan",
            "lastName": "Melson",
            "fullName": "Tim Melson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Melson_1.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-AL-1-upper-ocd-person-2f934a90-dc27-4289-b571-36e80ea4384b",
              "builtID": "al-upper-1",
              "externalID": "ocd-person/2f934a90-dc27-4289-b571-36e80ea4384b",
              "geometry": null
            },
            "contactInfo": {
              "email": "tim.melson@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100444&OID_PERSON=7688",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-1-upper-ocd-person-2f934a90-dc27-4289-b571-36e80ea4384b": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/32e10f9c-d38c-472f-8fb0-03450c4b538a",
            "firstName": "Thomas Wayne",
            "lastName": "Butler",
            "fullName": "Tom Butler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Butler_2.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-AL-2-upper-ocd-person-32e10f9c-d38c-472f-8fb0-03450c4b538a",
              "builtID": "al-upper-2",
              "externalID": "ocd-person/32e10f9c-d38c-472f-8fb0-03450c4b538a",
              "geometry": null
            },
            "contactInfo": {
              "email": "senbutler@aol.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100445&OID_PERSON=1010",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-2-upper-ocd-person-32e10f9c-d38c-472f-8fb0-03450c4b538a": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/b38546ec-f8fb-4e99-8e0c-46715b2e8578",
            "firstName": "Arthur",
            "lastName": "Orr",
            "fullName": "Arthur Orr",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Orr_3.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-AL-3-upper-ocd-person-b38546ec-f8fb-4e99-8e0c-46715b2e8578",
              "builtID": "al-upper-3",
              "externalID": "ocd-person/b38546ec-f8fb-4e99-8e0c-46715b2e8578",
              "geometry": null
            },
            "contactInfo": {
              "email": "arthur.orr@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100446&OID_PERSON=5527",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-3-upper-ocd-person-b38546ec-f8fb-4e99-8e0c-46715b2e8578": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/cf025dad-05a0-4a54-ba0b-bbeecb87c10e",
            "firstName": "Garlan",
            "lastName": "Gudger",
            "fullName": "Garlan Gudger",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Gudger_4.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-AL-4-upper-ocd-person-cf025dad-05a0-4a54-ba0b-bbeecb87c10e",
              "builtID": "al-upper-4",
              "externalID": "ocd-person/cf025dad-05a0-4a54-ba0b-bbeecb87c10e",
              "geometry": null
            },
            "contactInfo": {
              "email": "garlan.gudger@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100447&OID_PERSON=8481",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-4-upper-ocd-person-cf025dad-05a0-4a54-ba0b-bbeecb87c10e": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/1f20eba9-8e3a-4042-8a3b-5717029f6c0b",
            "firstName": "Greg J.",
            "lastName": "Reed",
            "fullName": "Greg Reed",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Reed_5.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-AL-5-upper-ocd-person-1f20eba9-8e3a-4042-8a3b-5717029f6c0b",
              "builtID": "al-upper-5",
              "externalID": "ocd-person/1f20eba9-8e3a-4042-8a3b-5717029f6c0b",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.reed@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100448&OID_PERSON=6688",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-5-upper-ocd-person-1f20eba9-8e3a-4042-8a3b-5717029f6c0b": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/abc69c3a-3f86-4880-a1ea-a2a52677b877",
            "firstName": "Larry Collins",
            "lastName": "Stutts",
            "fullName": "Larry Stutts",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Stutts_6.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-AL-6-upper-ocd-person-abc69c3a-3f86-4880-a1ea-a2a52677b877",
              "builtID": "al-upper-6",
              "externalID": "ocd-person/abc69c3a-3f86-4880-a1ea-a2a52677b877",
              "geometry": null
            },
            "contactInfo": {
              "email": "larry.stutts@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100449&OID_PERSON=7718",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-6-upper-ocd-person-abc69c3a-3f86-4880-a1ea-a2a52677b877": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/21753744-c2a0-45d4-90f8-3d4082c794d4",
            "firstName": "Samuel Houston",
            "lastName": "Givhan",
            "fullName": "Sam Givhan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Givhan_7.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-AL-7-upper-ocd-person-21753744-c2a0-45d4-90f8-3d4082c794d4",
              "builtID": "al-upper-7",
              "externalID": "ocd-person/21753744-c2a0-45d4-90f8-3d4082c794d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "sam.givhan@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100450&OID_PERSON=8482",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-7-upper-ocd-person-21753744-c2a0-45d4-90f8-3d4082c794d4": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/dd765598-9bed-4301-9f49-7e06fffd7fce",
            "firstName": "Steve",
            "lastName": "Livingston",
            "fullName": "Steve Livingston",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Livingston_8.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-AL-8-upper-ocd-person-dd765598-9bed-4301-9f49-7e06fffd7fce",
              "builtID": "al-upper-8",
              "externalID": "ocd-person/dd765598-9bed-4301-9f49-7e06fffd7fce",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.livingston@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100451&OID_PERSON=7689",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-8-upper-ocd-person-dd765598-9bed-4301-9f49-7e06fffd7fce": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/3f143edf-eac6-4bee-89b3-c5ac0b0b856e",
            "firstName": "Wesley Thomas",
            "lastName": "Kitchens",
            "fullName": "Wes Kitchens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Kitchens_9.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-AL-9-upper-ocd-person-3f143edf-eac6-4bee-89b3-c5ac0b0b856e",
              "builtID": "al-upper-9",
              "externalID": "ocd-person/3f143edf-eac6-4bee-89b3-c5ac0b0b856e",
              "geometry": null
            },
            "contactInfo": {
              "email": "weskitchens@mclo.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100507&OID_PERSON=8496",
                "https://alison.legislature.state.al.us/house-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-9-upper-ocd-person-3f143edf-eac6-4bee-89b3-c5ac0b0b856e": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/84d43460-8578-4b85-b2ee-7f6849e270d8",
            "firstName": "Andrew",
            "lastName": "Jones",
            "fullName": "Andrew Jones",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Jones_10.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-AL-10-upper-ocd-person-84d43460-8578-4b85-b2ee-7f6849e270d8",
              "builtID": "al-upper-10",
              "externalID": "ocd-person/84d43460-8578-4b85-b2ee-7f6849e270d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "andrew.jones@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100453&OID_PERSON=8483",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-10-upper-ocd-person-84d43460-8578-4b85-b2ee-7f6849e270d8": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/889eac7b-10b6-4f00-9bd6-e93a93d93211",
            "firstName": "Robert Lance",
            "lastName": "Bell",
            "fullName": "Lance Bell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Bell_11.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-AL-11-upper-ocd-person-889eac7b-10b6-4f00-9bd6-e93a93d93211",
              "builtID": "al-upper-11",
              "externalID": "ocd-person/889eac7b-10b6-4f00-9bd6-e93a93d93211",
              "geometry": null
            },
            "contactInfo": {
              "email": "lance.bell@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-11-upper-ocd-person-889eac7b-10b6-4f00-9bd6-e93a93d93211": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/e259674a-2a78-4423-b8d0-3d759ee90912",
            "firstName": "Keith",
            "lastName": "Kelley",
            "fullName": "Keith Kelley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Kelley_12.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-AL-12-upper-ocd-person-e259674a-2a78-4423-b8d0-3d759ee90912",
              "builtID": "al-upper-12",
              "externalID": "ocd-person/e259674a-2a78-4423-b8d0-3d759ee90912",
              "geometry": null
            },
            "contactInfo": {
              "email": "keith.kelley@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-12-upper-ocd-person-e259674a-2a78-4423-b8d0-3d759ee90912": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/040b7935-b32a-4d6d-9fa4-ec86e5ffd303",
            "firstName": "Randy",
            "lastName": "Price",
            "fullName": "Randy Price",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Price_13.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-AL-13-upper-ocd-person-040b7935-b32a-4d6d-9fa4-ec86e5ffd303",
              "builtID": "al-upper-13",
              "externalID": "ocd-person/040b7935-b32a-4d6d-9fa4-ec86e5ffd303",
              "geometry": null
            },
            "contactInfo": {
              "email": "randyprice.sd13@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100456&OID_PERSON=8484",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-13-upper-ocd-person-040b7935-b32a-4d6d-9fa4-ec86e5ffd303": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/15ebe01e-1447-4bdb-8dfb-e9d5948b872d",
            "firstName": "April",
            "lastName": "Weaver",
            "fullName": "April Weaver",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Weaver_14.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-AL-14-upper-ocd-person-15ebe01e-1447-4bdb-8dfb-e9d5948b872d",
              "builtID": "al-upper-14",
              "externalID": "ocd-person/15ebe01e-1447-4bdb-8dfb-e9d5948b872d",
              "geometry": null
            },
            "contactInfo": {
              "email": "april.weaver@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100530&OID_PERSON=6684",
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=9100457&OID_PERSON=94459",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-14-upper-ocd-person-15ebe01e-1447-4bdb-8dfb-e9d5948b872d": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/c58c02cd-0f5c-4454-b5d9-6eb0e604ea25",
            "firstName": "Dan",
            "lastName": "Roberts",
            "fullName": "Dan Roberts",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Roberts_15.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-AL-15-upper-ocd-person-c58c02cd-0f5c-4454-b5d9-6eb0e604ea25",
              "builtID": "al-upper-15",
              "externalID": "ocd-person/c58c02cd-0f5c-4454-b5d9-6eb0e604ea25",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.roberts@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100458&OID_PERSON=8485",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-15-upper-ocd-person-c58c02cd-0f5c-4454-b5d9-6eb0e604ea25": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/bdfa9580-2ce0-4828-8f9e-52e6f066fbce",
            "firstName": "James Thomas",
            "lastName": "Waggoner",
            "fullName": "Jabo Waggoner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Waggoner_16.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-AL-16-upper-ocd-person-bdfa9580-2ce0-4828-8f9e-52e6f066fbce",
              "builtID": "al-upper-16",
              "externalID": "ocd-person/bdfa9580-2ce0-4828-8f9e-52e6f066fbce",
              "geometry": null
            },
            "contactInfo": {
              "email": "jabo.waggoner@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100459&OID_PERSON=1141",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-16-upper-ocd-person-bdfa9580-2ce0-4828-8f9e-52e6f066fbce": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/9487b441-352c-43be-a2bb-8abc45e20671",
            "firstName": "Shay",
            "lastName": "Shelnutt",
            "fullName": "Shay Shelnutt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Shelnutt_17.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-AL-17-upper-ocd-person-9487b441-352c-43be-a2bb-8abc45e20671",
              "builtID": "al-upper-17",
              "externalID": "ocd-person/9487b441-352c-43be-a2bb-8abc45e20671",
              "geometry": null
            },
            "contactInfo": {
              "email": "shay.sd17@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100460&OID_PERSON=7690",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-17-upper-ocd-person-9487b441-352c-43be-a2bb-8abc45e20671": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/3b925820-55f7-4d24-b4df-d924fd3d1bf7",
            "firstName": "Rodger Mell",
            "lastName": "Smitherman",
            "fullName": "Rodger Smitherman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Smitherman_18.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-AL-18-upper-ocd-person-3b925820-55f7-4d24-b4df-d924fd3d1bf7",
              "builtID": "al-upper-18",
              "externalID": "ocd-person/3b925820-55f7-4d24-b4df-d924fd3d1bf7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rodger.smitherman@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100461&OID_PERSON=1139",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-18-upper-ocd-person-3b925820-55f7-4d24-b4df-d924fd3d1bf7": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/764f926b-598f-458e-84c4-5cc13c2302e5",
            "firstName": "Merika",
            "lastName": "Coleman",
            "fullName": "Merika Coleman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Coleman_19.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-AL-19-upper-ocd-person-764f926b-598f-458e-84c4-5cc13c2302e5",
              "builtID": "al-upper-19",
              "externalID": "ocd-person/764f926b-598f-458e-84c4-5cc13c2302e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "merika.coleman@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-19-upper-ocd-person-764f926b-598f-458e-84c4-5cc13c2302e5": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/8ffbb88c-c323-436f-aab0-91a41bf6c8dd",
            "firstName": "Linda",
            "lastName": "Coleman-Madison",
            "fullName": "Linda Coleman-Madison",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/house/members/Wilcox_104.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-AL-20-upper-ocd-person-8ffbb88c-c323-436f-aab0-91a41bf6c8dd",
              "builtID": "al-upper-20",
              "externalID": "ocd-person/8ffbb88c-c323-436f-aab0-91a41bf6c8dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "lcolemanmadison926@yahoo.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100463&OID_PERSON=4463",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-20-upper-ocd-person-8ffbb88c-c323-436f-aab0-91a41bf6c8dd": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/adbf5614-9045-4cb7-86eb-2d88f25a357a",
            "firstName": "Gerald H.",
            "lastName": "Allen",
            "fullName": "Gerald Allen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Allen_21.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-AL-21-upper-ocd-person-adbf5614-9045-4cb7-86eb-2d88f25a357a",
              "builtID": "al-upper-21",
              "externalID": "ocd-person/adbf5614-9045-4cb7-86eb-2d88f25a357a",
              "geometry": null
            },
            "contactInfo": {
              "email": "gerald.allen@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "AL",
              "phone1": null,
              "phone2": "334-261-0861",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100464&OID_PERSON=1014",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-21-upper-ocd-person-adbf5614-9045-4cb7-86eb-2d88f25a357a": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/7b9adc85-fd70-470a-b1bf-44ed412f3ba0",
            "firstName": "Gregory",
            "lastName": "Albritton",
            "fullName": "Greg Albritton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Albritton_22.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-AL-22-upper-ocd-person-7b9adc85-fd70-470a-b1bf-44ed412f3ba0",
              "builtID": "al-upper-22",
              "externalID": "ocd-person/7b9adc85-fd70-470a-b1bf-44ed412f3ba0",
              "geometry": null
            },
            "contactInfo": {
              "email": "galbritton@att.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100465&OID_PERSON=7691",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-22-upper-ocd-person-7b9adc85-fd70-470a-b1bf-44ed412f3ba0": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/cd2d1a70-0bb3-49b3-871c-9e227392653d",
            "firstName": "Robert L.",
            "lastName": "Stewart",
            "fullName": "Rob Stewart",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Stewart_23.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-AL-23-upper-ocd-person-cd2d1a70-0bb3-49b3-871c-9e227392653d",
              "builtID": "al-upper-23",
              "externalID": "ocd-person/cd2d1a70-0bb3-49b3-871c-9e227392653d",
              "geometry": null
            },
            "contactInfo": {
              "email": "robert.stewart@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-23-upper-ocd-person-cd2d1a70-0bb3-49b3-871c-9e227392653d": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/6e6d5e12-363b-42dc-b0d1-191c82aa6493",
            "firstName": "Bobby D.",
            "lastName": "Singleton",
            "fullName": "Bobby Singleton",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Singleton_24.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-AL-24-upper-ocd-person-6e6d5e12-363b-42dc-b0d1-191c82aa6493",
              "builtID": "al-upper-24",
              "externalID": "ocd-person/6e6d5e12-363b-42dc-b0d1-191c82aa6493",
              "geometry": null
            },
            "contactInfo": {
              "email": "bsingle362@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100467&OID_PERSON=4471",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-24-upper-ocd-person-6e6d5e12-363b-42dc-b0d1-191c82aa6493": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/8cca1495-936e-4ac5-920a-cc4aa22e495e",
            "firstName": "Will",
            "lastName": "Barfoot",
            "fullName": "Will Barfoot",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Barfoot_25.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-AL-25-upper-ocd-person-8cca1495-936e-4ac5-920a-cc4aa22e495e",
              "builtID": "al-upper-25",
              "externalID": "ocd-person/8cca1495-936e-4ac5-920a-cc4aa22e495e",
              "geometry": null
            },
            "contactInfo": {
              "email": "will.barfoot@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100468&OID_PERSON=8486",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-25-upper-ocd-person-8cca1495-936e-4ac5-920a-cc4aa22e495e": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/e0a28b79-3df1-491b-af90-4c0414ba72c8",
            "firstName": "Kirk",
            "lastName": "Hatcher",
            "fullName": "Kirk Hatcher",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Hatcher_26.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-AL-26-upper-ocd-person-e0a28b79-3df1-491b-af90-4c0414ba72c8",
              "builtID": "al-upper-26",
              "externalID": "ocd-person/e0a28b79-3df1-491b-af90-4c0414ba72c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "kirk.hatcher@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALRepresentative.aspx?OID_SPONSOR=100560&OID_PERSON=8507",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-26-upper-ocd-person-e0a28b79-3df1-491b-af90-4c0414ba72c8": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/d581a562-c33c-44c0-a707-c21dddf140aa",
            "firstName": "Jay",
            "lastName": "Hovey",
            "fullName": "Jay Hovey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Hovey_27.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-AL-27-upper-ocd-person-d581a562-c33c-44c0-a707-c21dddf140aa",
              "builtID": "al-upper-27",
              "externalID": "ocd-person/d581a562-c33c-44c0-a707-c21dddf140aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "jay.hovey@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-27-upper-ocd-person-d581a562-c33c-44c0-a707-c21dddf140aa": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/38c5b433-f53c-4073-a2da-a969e4c31696",
            "firstName": "William M.",
            "lastName": "Beasley",
            "fullName": "Billy Beasley",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Beasley_28.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-AL-28-upper-ocd-person-38c5b433-f53c-4073-a2da-a969e4c31696",
              "builtID": "al-upper-28",
              "externalID": "ocd-person/38c5b433-f53c-4073-a2da-a969e4c31696",
              "geometry": null
            },
            "contactInfo": {
              "email": "billy.beasley@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100471&OID_PERSON=3302",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-28-upper-ocd-person-38c5b433-f53c-4073-a2da-a969e4c31696": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/c1ce8b3f-ea56-4c8f-ab74-2a02b8d10063",
            "firstName": "James Donald",
            "lastName": "Chesteen",
            "fullName": "Donnie Chesteen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Chesteen_29.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-AL-29-upper-ocd-person-c1ce8b3f-ea56-4c8f-ab74-2a02b8d10063",
              "builtID": "al-upper-29",
              "externalID": "ocd-person/c1ce8b3f-ea56-4c8f-ab74-2a02b8d10063",
              "geometry": null
            },
            "contactInfo": {
              "email": "donnie.chesteen@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100472&OID_PERSON=6660",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-29-upper-ocd-person-c1ce8b3f-ea56-4c8f-ab74-2a02b8d10063": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/d1307771-6d50-4d56-9995-b5d799917d7b",
            "firstName": "Clyde",
            "lastName": "Chambliss",
            "fullName": "Clyde Chambliss",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Chambliss_30.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-AL-30-upper-ocd-person-d1307771-6d50-4d56-9995-b5d799917d7b",
              "builtID": "al-upper-30",
              "externalID": "ocd-person/d1307771-6d50-4d56-9995-b5d799917d7b",
              "geometry": null
            },
            "contactInfo": {
              "email": "clyde.chambliss@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100473&OID_PERSON=7692",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-30-upper-ocd-person-d1307771-6d50-4d56-9995-b5d799917d7b": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/51b33504-a943-4091-a3bd-f0b8feb124be",
            "firstName": "Josh",
            "lastName": "Carnley",
            "fullName": "Josh Carnley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Carnley_31.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-AL-31-upper-ocd-person-51b33504-a943-4091-a3bd-f0b8feb124be",
              "builtID": "al-upper-31",
              "externalID": "ocd-person/51b33504-a943-4091-a3bd-f0b8feb124be",
              "geometry": null
            },
            "contactInfo": {
              "email": "josh.carnley@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-31-upper-ocd-person-51b33504-a943-4091-a3bd-f0b8feb124be": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/67479855-f23e-4119-b886-91dddc09a47a",
            "firstName": "Thomas Christopher",
            "lastName": "Elliott",
            "fullName": "Chris Elliott",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Elliott_32.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-AL-32-upper-ocd-person-67479855-f23e-4119-b886-91dddc09a47a",
              "builtID": "al-upper-32",
              "externalID": "ocd-person/67479855-f23e-4119-b886-91dddc09a47a",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.elliott@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100475&OID_PERSON=8487",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-32-upper-ocd-person-67479855-f23e-4119-b886-91dddc09a47a": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/63d0a35f-ec68-48e2-be94-d5e3cb60b3c3",
            "firstName": "Vivian Davis",
            "lastName": "Figures",
            "fullName": "Vivian Figures",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Figures_33.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-AL-33-upper-ocd-person-63d0a35f-ec68-48e2-be94-d5e3cb60b3c3",
              "builtID": "al-upper-33",
              "externalID": "ocd-person/63d0a35f-ec68-48e2-be94-d5e3cb60b3c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "vivian.figures@alsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100476&OID_PERSON=1027",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-33-upper-ocd-person-63d0a35f-ec68-48e2-be94-d5e3cb60b3c3": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/a7e333c5-03f2-4343-9981-956bcc1ea38e",
            "firstName": "Jack W.",
            "lastName": "Williams",
            "fullName": "Jack Williams",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Williams_34.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-AL-34-upper-ocd-person-a7e333c5-03f2-4343-9981-956bcc1ea38e",
              "builtID": "al-upper-34",
              "externalID": "ocd-person/a7e333c5-03f2-4343-9981-956bcc1ea38e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jackwilliams55@icloud.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100477&OID_PERSON=7707",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-34-upper-ocd-person-a7e333c5-03f2-4343-9981-956bcc1ea38e": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/fa2c59ea-1a07-429d-85e4-9c60652919aa",
            "firstName": "David R.",
            "lastName": "Sessions",
            "fullName": "David Sessions",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.state.al.us/pdf/senate/members/Sessions_35.png",
            "title": "AL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AL",
              "stateFull": "Alabama",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-AL-35-upper-ocd-person-fa2c59ea-1a07-429d-85e4-9c60652919aa",
              "builtID": "al-upper-35",
              "externalID": "ocd-person/fa2c59ea-1a07-429d-85e4-9c60652919aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "d.r.sessions@att.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legislature.state.al.us/aliswww/ISD/ALSenator.aspx?OID_SPONSOR=100478&OID_PERSON=6832",
                "https://alison.legislature.state.al.us/senate-leaders-members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AL-35-upper-ocd-person-fa2c59ea-1a07-429d-85e4-9c60652919aa": 0
        }
      }
    }
  },
  "AK": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "AK",
          "stateFull": "Alaska",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "AK",
            "stateFull": "Alaska",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-AK---",
            "builtID": "ak--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-AK---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "M001153",
          "in_office": true,
          "firstName": "Lisa",
          "lastName": "Murkowski",
          "middleName": null,
          "fullName": "Lisa Murkowski",
          "gender": "F",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/M001153.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "AK",
            "stateFull": "Alaska",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-AK---M001153",
            "builtID": "ak--",
            "externalID": "M001153",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "LisaMurkowski",
            "youtube": "senatormurkowski",
            "instagram": null,
            "facebook": "SenLisaMurkowski",
            "urls": ["https://www.murkowski.senate.gov"],
            "rss_url": null
          },
          "title": "AK Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/M001153.json",
          "govtrack_id": "300075",
          "cspan_id": "1004138",
          "votesmart_id": "15841",
          "icpsr_id": "40300",
          "crp_id": "N00026050",
          "google_entity_id": "/m/0202kt",
          "state_rank": "senior",
          "lis_id": "S288",
          "suffix": null,
          "date_of_birth": "1957-05-22",
          "leadership_role": null,
          "fec_candidate_id": "S4AK00099",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "23",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 22,
          "total_present": 1,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 3.97,
          "votes_with_party_pct": 53.88,
          "votes_against_party_pct": 46.12,
          "ocd_id": "ocd-division/country:us/state:ak"
        },
        {
          "API_ID": "S001198",
          "in_office": true,
          "firstName": "Dan",
          "lastName": "Sullivan",
          "middleName": null,
          "fullName": "Dan Sullivan",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/S001198.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "AK",
            "stateFull": "Alaska",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-AK---S001198",
            "builtID": "ak--",
            "externalID": "S001198",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.sullivan.senate.gov/contact/email",
            "address1": "302 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-3004",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenDanSullivan",
            "youtube": null,
            "instagram": null,
            "facebook": "SenDanSullivan",
            "urls": ["https://www.sullivan.senate.gov"],
            "rss_url": null
          },
          "title": "AK Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/S001198.json",
          "govtrack_id": "412665",
          "cspan_id": "1023262",
          "votesmart_id": "114964",
          "icpsr_id": "41500",
          "crp_id": "N00035774",
          "google_entity_id": "/m/07s4r4q",
          "state_rank": "junior",
          "lis_id": "S383",
          "suffix": null,
          "date_of_birth": "1964-11-13",
          "leadership_role": null,
          "fec_candidate_id": "S4AK00214",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "9",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 31,
          "total_present": 0,
          "last_updated": "2024-06-20 15:30:29 -0400",
          "missed_votes_pct": 5.6,
          "votes_with_party_pct": 88.63,
          "votes_against_party_pct": 11.37,
          "ocd_id": "ocd-division/country:us/state:ak"
        }
      ],
      "hash": { "SENATE-AK---M001153": 0, "SENATE-AK---S001198": 1 }
    },
    "HOUSE": {
      "00": {
        "members": [
          {
            "API_ID": "P000619",
            "in_office": true,
            "firstName": "Mary",
            "lastName": "Peltola",
            "middleName": null,
            "fullName": "Mary Sattler Peltola",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/P000619.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "00",
              "chamber": "",
              "hashID": "HOUSE-AK-00--P000619",
              "builtID": "ak--00",
              "externalID": "P000619",
              "geometry": null,
              "geoid": "0200"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "153 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5765",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Rep_Peltola",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://peltola.house.gov"],
              "rss_url": ""
            },
            "title": "AK House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000619.json",
            "govtrack_id": "456870",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00050780",
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1973-08-31",
            "leadership_role": null,
            "fec_candidate_id": "H2AK01158",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 189,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 17.9,
            "votes_with_party_pct": 83.31,
            "votes_against_party_pct": 14.35,
            "ocd_id": "ocd-division/country:us/state:ak/cd:1",
            "at_large": true
          }
        ],
        "hash": { "HOUSE-AK-00--P000619": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/00fe56b6-c6b4-4b63-a9a1-9d1eadda0ff5",
            "firstName": "Daniel H.",
            "lastName": "Ortiz",
            "fullName": "Dan Ortiz",
            "gender": "Male",
            "party": "independent",
            "image": "http://akleg.gov/images/legislators/450x600/ORT.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-AK-1-lower-ocd-person-00fe56b6-c6b4-4b63-a9a1-9d1eadda0ff5",
              "builtID": "ak-lower-1",
              "externalID": "ocd-person/00fe56b6-c6b4-4b63-a9a1-9d1eadda0ff5",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.dan.ortiz@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1900 1st Ave. Suite 310, Ketchikan, AK 99901",
              "phone1": null,
              "phone2": "907-247-4672",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=ort",
                "https://akhouse.org/rep_ortiz/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-1-lower-ocd-person-00fe56b6-c6b4-4b63-a9a1-9d1eadda0ff5": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/5637be3f-7a59-48cc-8f8a-ad7a8bac8ee9",
            "firstName": "Rebecca",
            "lastName": "Himschoot",
            "fullName": "Rebecca Himschoot",
            "gender": "Female",
            "party": "independent",
            "image": "http://akleg.gov/images/legislators/450x600/HMS.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-AK-2-lower-ocd-person-5637be3f-7a59-48cc-8f8a-ad7a8bac8ee9",
              "builtID": "ak-lower-2",
              "externalID": "ocd-person/5637be3f-7a59-48cc-8f8a-ad7a8bac8ee9",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.rebecca.himschoot@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 844, Sitka, AK 99835",
              "phone1": null,
              "phone2": "907-747-4665",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=hms",
                "https://akhouse.org/rep-himschoot/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-2-lower-ocd-person-5637be3f-7a59-48cc-8f8a-ad7a8bac8ee9": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/7db414a5-dd7e-48a9-aba2-9c0204f93a5c",
            "firstName": "Andrea Douglas",
            "lastName": "Story",
            "fullName": "Andi Story",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/STO.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-AK-3-lower-ocd-person-7db414a5-dd7e-48a9-aba2-9c0204f93a5c",
              "builtID": "ak-lower-3",
              "externalID": "ocd-person/7db414a5-dd7e-48a9-aba2-9c0204f93a5c",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.andi.story@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/31?code=sto",
                "https://www.akleg.gov/basis/Member/Detail/32?code=sto",
                "https://www.akleg.gov/basis/Member/Detail/33?code=STO",
                "https://www.akleg.gov/basis/Member/Detail/33?code=sto",
                "https://akhouse.org/rep-story/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-3-lower-ocd-person-7db414a5-dd7e-48a9-aba2-9c0204f93a5c": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/6cec7228-ed13-47f6-b896-59f927789f6d",
            "firstName": "Sara T.",
            "lastName": "Hannan",
            "fullName": "Sara Hannan",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/HNN.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-AK-4-lower-ocd-person-6cec7228-ed13-47f6-b896-59f927789f6d",
              "builtID": "ak-lower-4",
              "externalID": "ocd-person/6cec7228-ed13-47f6-b896-59f927789f6d",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.sara.hannan@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/31?code=hnn",
                "https://www.akleg.gov/basis/Member/Detail/32?code=hnn",
                "https://www.akleg.gov/basis/Member/Detail/33?code=hnn",
                "https://akhouse.org/rep-hannan/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-4-lower-ocd-person-6cec7228-ed13-47f6-b896-59f927789f6d": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/2d74c456-7818-42bc-a284-63026fbbfc56",
            "firstName": "Louise Ball",
            "lastName": "Stutes",
            "fullName": "Louise Stutes",
            "gender": "Female",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/STS.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-AK-5-lower-ocd-person-2d74c456-7818-42bc-a284-63026fbbfc56",
              "builtID": "ak-lower-5",
              "externalID": "ocd-person/2d74c456-7818-42bc-a284-63026fbbfc56",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.louise.stutes@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "305 Center Ave. Suite 1, Kodiak, AK 99615",
              "phone1": null,
              "phone2": "907-486-8872",
              "fax1": null,
              "fax2": "907-486-5264",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=sts",
                "https://www.akleg.gov/basis/Member/Detail/31?code=sts",
                "https://www.akleg.gov/basis/Member/Detail/32?code=sts",
                "https://www.akleg.gov/basis/Member/Detail/33?code=STS",
                "https://www.akleg.gov/basis/Member/Detail/33?code=sts",
                "https://akhouse.org/rep_stutes"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-5-lower-ocd-person-2d74c456-7818-42bc-a284-63026fbbfc56": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/e8756722-e9e9-4246-95e3-13019cb3bd50",
            "firstName": "Sarah Lynn",
            "lastName": "Vance",
            "fullName": "Sarah Vance",
            "gender": "Female",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/VAN.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-AK-6-lower-ocd-person-e8756722-e9e9-4246-95e3-13019cb3bd50",
              "builtID": "ak-lower-6",
              "externalID": "ocd-person/e8756722-e9e9-4246-95e3-13019cb3bd50",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.sarah.vance@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/31?code=van",
                "https://www.akleg.gov/basis/Member/Detail/32?code=van",
                "https://www.akleg.gov/basis/Member/Detail/33?code=van",
                "https://akhousemajority.com/rep-vance/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-6-lower-ocd-person-e8756722-e9e9-4246-95e3-13019cb3bd50": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/722ec3e9-7e0d-4dda-bf19-11d873df7031",
            "firstName": "Justin",
            "lastName": "Ruffridge",
            "fullName": "Justin Ruffridge",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/RUF.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-AK-7-lower-ocd-person-722ec3e9-7e0d-4dda-bf19-11d873df7031",
              "builtID": "ak-lower-7",
              "externalID": "ocd-person/722ec3e9-7e0d-4dda-bf19-11d873df7031",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.justin.ruffridge@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "145 Main St. Loop, Kenai, AK 99611",
              "phone1": null,
              "phone2": "907-465-2693",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=ruf",
                "https://www.repruffridge.com/",
                "https://akhousemajority.com/rep-ruffridge/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-7-lower-ocd-person-722ec3e9-7e0d-4dda-bf19-11d873df7031": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/64873656-b474-406d-a93a-e46fa281bc19",
            "firstName": "Benjamin E.",
            "lastName": "Carpenter",
            "fullName": "Ben Carpenter",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/CAE.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-AK-8-lower-ocd-person-64873656-b474-406d-a93a-e46fa281bc19",
              "builtID": "ak-lower-8",
              "externalID": "ocd-person/64873656-b474-406d-a93a-e46fa281bc19",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.ben.carpenter@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "145 Main St. Loop Suite 223, Kenai, AK 99611",
              "phone1": null,
              "phone2": "907-283-7223",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/31?code=cae",
                "https://www.akleg.gov/basis/Member/Detail/32?code=cae",
                "https://www.akleg.gov/basis/Member/Detail/33?code=CAE",
                "https://www.akleg.gov/basis/Member/Detail/33?code=cae",
                "https://akhousemajority.com/rep-carpenter/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-8-lower-ocd-person-64873656-b474-406d-a93a-e46fa281bc19": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/ef814d49-0425-4d61-944a-bf1fb4b1b7d5",
            "firstName": "Laddie",
            "lastName": "Shaw",
            "fullName": "Laddie Shaw",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/SHW.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-AK-9-lower-ocd-person-ef814d49-0425-4d61-944a-bf1fb4b1b7d5",
              "builtID": "ak-lower-9",
              "externalID": "ocd-person/ef814d49-0425-4d61-944a-bf1fb4b1b7d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.laddie.shaw@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0199",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/31?code=shw",
                "https://www.akleg.gov/basis/Member/Detail/32?code=shw",
                "https://www.akleg.gov/basis/Member/Detail/33?code=shw",
                "https://akhousemajority.com/rep-shaw/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-9-lower-ocd-person-ef814d49-0425-4d61-944a-bf1fb4b1b7d5": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/e33dc1cd-7483-4101-aa36-942247c8aab7",
            "firstName": "Craig",
            "lastName": "Johnson",
            "fullName": "Craig Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/JHN.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-AK-10-lower-ocd-person-e33dc1cd-7483-4101-aa36-942247c8aab7",
              "builtID": "ak-lower-10",
              "externalID": "ocd-person/e33dc1cd-7483-4101-aa36-942247c8aab7",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.craig.johnson@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0234",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.legis.state.ak.us/rep.php?id=jhn",
                "https://www.akleg.gov/basis/Member/Detail/33?code=JHN",
                "https://www.akleg.gov/basis/Member/Detail/33?code=jhn",
                "https://akhousemajority.com/rep-c-johnson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-10-lower-ocd-person-e33dc1cd-7483-4101-aa36-942247c8aab7": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/a66f1539-56d3-458c-8ac0-3aff6907a69d",
            "firstName": "Julie",
            "lastName": "Coulombe",
            "fullName": "Julie Coulombe",
            "gender": "Female",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/COU.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-AK-11-lower-ocd-person-a66f1539-56d3-458c-8ac0-3aff6907a69d",
              "builtID": "ak-lower-11",
              "externalID": "ocd-person/a66f1539-56d3-458c-8ac0-3aff6907a69d",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.julie.coulombe@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0222",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=cou",
                "https://akhousemajority.com/rep-coulombe/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-11-lower-ocd-person-a66f1539-56d3-458c-8ac0-3aff6907a69d": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/8ed150a0-45be-4844-abe5-d921c9f5a435",
            "firstName": "Calvin",
            "lastName": "Schrage",
            "fullName": "Calvin Schrage",
            "gender": "Male",
            "party": "independent",
            "image": "http://akleg.gov/images/legislators/450x600/SCG.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-AK-12-lower-ocd-person-8ed150a0-45be-4844-abe5-d921c9f5a435",
              "builtID": "ak-lower-12",
              "externalID": "ocd-person/8ed150a0-45be-4844-abe5-d921c9f5a435",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.calvin.schrage@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0205",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/32?code=scg",
                "https://www.akleg.gov/basis/Member/Detail/33?code=SCG",
                "https://www.akleg.gov/basis/Member/Detail/33?code=scg",
                "https://akhouse.org/rep-schrage/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-12-lower-ocd-person-8ed150a0-45be-4844-abe5-d921c9f5a435": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/e1989648-fdd3-4e88-a0f0-9e27e75c0f03",
            "firstName": "Andrew Lewis",
            "lastName": "Josephson",
            "fullName": "Andy Josephson",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/JOS.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-AK-13-lower-ocd-person-e1989648-fdd3-4e88-a0f0-9e27e75c0f03",
              "builtID": "ak-lower-13",
              "externalID": "ocd-person/e1989648-fdd3-4e88-a0f0-9e27e75c0f03",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.andy.josephson@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0265",
              "fax1": null,
              "fax2": "907-269-0264",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=jos",
                "https://www.akleg.gov/basis/Member/Detail/31?code=jos",
                "https://www.akleg.gov/basis/Member/Detail/32?code=jos",
                "https://www.akleg.gov/basis/Member/Detail/33?code=jos",
                "https://akhouse.org/rep_josephson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-13-lower-ocd-person-e1989648-fdd3-4e88-a0f0-9e27e75c0f03": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/2a1a6b8f-1f9c-4a1b-9059-7b2f8cc93ed9",
            "firstName": "Alyse Surratt",
            "lastName": "Galvin",
            "fullName": "Alyse Galvin",
            "gender": "Female",
            "party": "independent",
            "image": "http://akleg.gov/images/legislators/450x600/GAL.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-AK-14-lower-ocd-person-2a1a6b8f-1f9c-4a1b-9059-7b2f8cc93ed9",
              "builtID": "ak-lower-14",
              "externalID": "ocd-person/2a1a6b8f-1f9c-4a1b-9059-7b2f8cc93ed9",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.alyse.galvin@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0190",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=gal",
                "https://akhouse.org/rep-galvin/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-14-lower-ocd-person-2a1a6b8f-1f9c-4a1b-9059-7b2f8cc93ed9": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/60762d79-1ddd-4a7b-b385-0f31b0e49670",
            "firstName": "Thomas",
            "lastName": "McKay",
            "fullName": "Tom McKay",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/MCK.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-AK-15-lower-ocd-person-60762d79-1ddd-4a7b-b385-0f31b0e49670",
              "builtID": "ak-lower-15",
              "externalID": "ocd-person/60762d79-1ddd-4a7b-b385-0f31b0e49670",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.tom.mckay@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0200",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/32?code=mck",
                "https://www.akleg.gov/basis/Member/Detail/33?code=mck",
                "https://akhousemajority.com/rep-mckay/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-15-lower-ocd-person-60762d79-1ddd-4a7b-b385-0f31b0e49670": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/cee8f12c-3d69-410c-a9eb-5df9e15f16fe",
            "firstName": "Jennifer",
            "lastName": "Armstrong",
            "fullName": "Jennie Armstrong",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/ARJ.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-AK-16-lower-ocd-person-cee8f12c-3d69-410c-a9eb-5df9e15f16fe",
              "builtID": "ak-lower-16",
              "externalID": "ocd-person/cee8f12c-3d69-410c-a9eb-5df9e15f16fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.jennie.armstrong@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0117",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=arj",
                "https://akhouse.org/rep-armstrong/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-16-lower-ocd-person-cee8f12c-3d69-410c-a9eb-5df9e15f16fe": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/c1d170a4-d099-43cb-b7e5-5a9db63ae716",
            "firstName": "William Zachary",
            "lastName": "Fields",
            "fullName": "Zack Fields",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/FIE.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-AK-17-lower-ocd-person-c1d170a4-d099-43cb-b7e5-5a9db63ae716",
              "builtID": "ak-lower-17",
              "externalID": "ocd-person/c1d170a4-d099-43cb-b7e5-5a9db63ae716",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.zack.fields@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0106",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/32?code=fie",
                "https://www.akleg.gov/basis/Member/Detail/33?code=fie",
                "https://akhouse.org/rep-fields/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-17-lower-ocd-person-c1d170a4-d099-43cb-b7e5-5a9db63ae716": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/cc9101e6-beed-4463-92df-d9e479a6894d",
            "firstName": "Cliff",
            "lastName": "Groh",
            "fullName": "Cliff Groh",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/GRO.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-AK-18-lower-ocd-person-cc9101e6-beed-4463-92df-d9e479a6894d",
              "builtID": "ak-lower-18",
              "externalID": "ocd-person/cc9101e6-beed-4463-92df-d9e479a6894d",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.cliff.groh@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0216",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=gro",
                "https://akhouse.org/rep-groh/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-18-lower-ocd-person-cc9101e6-beed-4463-92df-d9e479a6894d": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/a1adb2e5-3c63-4032-81e8-9e787976ca63",
            "firstName": "Genevieve",
            "lastName": "Mina",
            "fullName": "Genevieve Mina",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/MNA.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-AK-19-lower-ocd-person-a1adb2e5-3c63-4032-81e8-9e787976ca63",
              "builtID": "ak-lower-19",
              "externalID": "ocd-person/a1adb2e5-3c63-4032-81e8-9e787976ca63",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.genevieve.mina@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0144",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=mna",
                "https://akhouse.org/rep-mina/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-19-lower-ocd-person-a1adb2e5-3c63-4032-81e8-9e787976ca63": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/822df52b-f962-478d-8bf5-3bbe3c9dd811",
            "firstName": "Andrew Timothy",
            "lastName": "Gray",
            "fullName": "Andrew Gray",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/GRY.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-AK-20-lower-ocd-person-822df52b-f962-478d-8bf5-3bbe3c9dd811",
              "builtID": "ak-lower-20",
              "externalID": "ocd-person/822df52b-f962-478d-8bf5-3bbe3c9dd811",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.andrew.gray@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0123",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=gry",
                "https://akhouse.org/rep-gray/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-20-lower-ocd-person-822df52b-f962-478d-8bf5-3bbe3c9dd811": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/fbe52f5e-1dd2-4d44-9205-b2ebbbe39eee",
            "firstName": "Donna C.",
            "lastName": "Mears",
            "fullName": "Donna Mears",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/MEA.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-AK-21-lower-ocd-person-fbe52f5e-1dd2-4d44-9205-b2ebbbe39eee",
              "builtID": "ak-lower-21",
              "externalID": "ocd-person/fbe52f5e-1dd2-4d44-9205-b2ebbbe39eee",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.donna.mears@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0100",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=mea",
                "https://akhouse.org/rep-mears/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-21-lower-ocd-person-fbe52f5e-1dd2-4d44-9205-b2ebbbe39eee": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/99750f78-3460-47bb-baa2-adc98acf17d6",
            "firstName": "Stanley A.",
            "lastName": "Wright",
            "fullName": "Stanley Wright",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/WRG.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-AK-22-lower-ocd-person-99750f78-3460-47bb-baa2-adc98acf17d6",
              "builtID": "ak-lower-22",
              "externalID": "ocd-person/99750f78-3460-47bb-baa2-adc98acf17d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.stanley.wright@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0240",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=wrg",
                "https://akhousemajority.com/rep-wright/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-22-lower-ocd-person-99750f78-3460-47bb-baa2-adc98acf17d6": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/9d56634b-f2d0-43fe-b20e-ed2465b0ad9c",
            "firstName": "Jamie",
            "lastName": "Allard",
            "fullName": "Jamie Allard",
            "gender": "Female",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/ALR.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-AK-23-lower-ocd-person-9d56634b-f2d0-43fe-b20e-ed2465b0ad9c",
              "builtID": "ak-lower-23",
              "externalID": "ocd-person/9d56634b-f2d0-43fe-b20e-ed2465b0ad9c",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.jamie.allard@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0250",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=alr",
                "https://akhousemajority.com/rep-allard/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-23-lower-ocd-person-9d56634b-f2d0-43fe-b20e-ed2465b0ad9c": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/7dcdf7b8-530a-4b81-85b6-745af9224eb1",
            "firstName": "Dan",
            "lastName": "Saddler",
            "fullName": "Dan Saddler",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/SAD.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-AK-24-lower-ocd-person-7dcdf7b8-530a-4b81-85b6-745af9224eb1",
              "builtID": "ak-lower-24",
              "externalID": "ocd-person/7dcdf7b8-530a-4b81-85b6-745af9224eb1",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.dan.saddler@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-334-2630",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=sad",
                "https://www.akleg.gov/basis/Member/Detail/33?code=SAD",
                "https://www.akleg.gov/basis/Member/Detail/33?code=sad",
                "https://akhousemajority.com/rep-saddler/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-24-lower-ocd-person-7dcdf7b8-530a-4b81-85b6-745af9224eb1": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/d7d4a21d-942e-46f7-92b6-237f1741b025",
            "firstName": "DeLena",
            "lastName": "Johnson",
            "fullName": "DeLena Johnson",
            "gender": "Female",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/JHS.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-AK-25-lower-ocd-person-d7d4a21d-942e-46f7-92b6-237f1741b025",
              "builtID": "ak-lower-25",
              "externalID": "ocd-person/d7d4a21d-942e-46f7-92b6-237f1741b025",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.delena.johnson@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "600 E. Railroad Ave., Wasilla, AK 99654",
              "phone1": null,
              "phone2": "907-376-4958",
              "fax1": null,
              "fax2": "907-376-4928",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=jhs",
                "https://www.akleg.gov/basis/Member/Detail/31?code=jhs",
                "https://www.akleg.gov/basis/Member/Detail/32?code=jhs",
                "https://www.akleg.gov/basis/Member/Detail/33?code=JHS",
                "https://www.akleg.gov/basis/Member/Detail/33?code=jhs",
                "https://akhousemajority.com/rep-d-johnson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-25-lower-ocd-person-d7d4a21d-942e-46f7-92b6-237f1741b025": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/f1816065-31d1-4757-b8b9-714e15daeccf",
            "firstName": "Cathy L.",
            "lastName": "Tilton",
            "fullName": "Cathy Tilton",
            "gender": "Female",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/TLT.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-AK-26-lower-ocd-person-f1816065-31d1-4757-b8b9-714e15daeccf",
              "builtID": "ak-lower-26",
              "externalID": "ocd-person/f1816065-31d1-4757-b8b9-714e15daeccf",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.cathy.tilton@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "600 E. Railroad Ave., Wasilla, AK 99654",
              "phone1": null,
              "phone2": "907-373-6298",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=tlt",
                "https://www.akleg.gov/basis/Member/Detail/31?code=tlt",
                "https://www.akleg.gov/basis/Member/Detail/32?code=tlt",
                "https://www.akleg.gov/basis/Member/Detail/33?code=tlt",
                "https://akhousemajority.com/rep-tilton/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-26-lower-ocd-person-f1816065-31d1-4757-b8b9-714e15daeccf": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/cf5cefd3-8283-411d-806d-b5076ade039d",
            "firstName": "David Clark",
            "lastName": "Eastman",
            "fullName": "David Eastman",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/EAS.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-AK-27-lower-ocd-person-cf5cefd3-8283-411d-806d-b5076ade039d",
              "builtID": "ak-lower-27",
              "externalID": "ocd-person/cf5cefd3-8283-411d-806d-b5076ade039d",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.david.eastman@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "600 E. Railroad Ave., Wasilla, AK 99654",
              "phone1": null,
              "phone2": "907-373-1842",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=eas",
                "https://www.akleg.gov/basis/Member/Detail/31?code=eas",
                "https://www.akleg.gov/basis/Member/Detail/32?code=eas",
                "https://www.akleg.gov/basis/Member/Detail/33?code=eas"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-27-lower-ocd-person-cf5cefd3-8283-411d-806d-b5076ade039d": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/12d61cae-e15a-4e37-9977-c00e6cc16750",
            "firstName": "Jesse",
            "lastName": "Sumner",
            "fullName": "Jesse Sumner",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/SUJ.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-AK-28-lower-ocd-person-12d61cae-e15a-4e37-9977-c00e6cc16750",
              "builtID": "ak-lower-28",
              "externalID": "ocd-person/12d61cae-e15a-4e37-9977-c00e6cc16750",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.jesse.sumner@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "600 E. Railroad Ave., Wasilla, AK 99654",
              "phone1": null,
              "phone2": "907-373-6285",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.akleg.gov/basis/Member/Detail/33?code=suj"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-28-lower-ocd-person-12d61cae-e15a-4e37-9977-c00e6cc16750": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/627922d3-e631-4c32-841f-148f061b0c75",
            "firstName": "George",
            "lastName": "Rauscher",
            "fullName": "George Rauscher",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/RAU.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-AK-29-lower-ocd-person-627922d3-e631-4c32-841f-148f061b0c75",
              "builtID": "ak-lower-29",
              "externalID": "ocd-person/627922d3-e631-4c32-841f-148f061b0c75",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.george.rauscher@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "600 E. Railroad Ave., Wasilla, AK 99654",
              "phone1": null,
              "phone2": "907-373-6287",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=rau",
                "https://www.akleg.gov/basis/Member/Detail/31?code=rau",
                "https://www.akleg.gov/basis/Member/Detail/32?code=rau",
                "https://www.akleg.gov/basis/Member/Detail/33?code=RAU",
                "https://www.akleg.gov/basis/Member/Detail/33?code=rau",
                "https://akhousemajority.com/rep-rauscher/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-29-lower-ocd-person-627922d3-e631-4c32-841f-148f061b0c75": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/0160c6d2-4090-4143-8e23-f8501501bdd0",
            "firstName": "Kevin J.",
            "lastName": "McCabe",
            "fullName": "Kevin McCabe",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/MCA.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-AK-30-lower-ocd-person-0160c6d2-4090-4143-8e23-f8501501bdd0",
              "builtID": "ak-lower-30",
              "externalID": "ocd-person/0160c6d2-4090-4143-8e23-f8501501bdd0",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.kevin.mccabe@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "600 E. Railroad Ave., Wasilla, AK 99654",
              "phone1": null,
              "phone2": "907-376-2679",
              "fax1": null,
              "fax2": "907-373-4745",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/32?code=mca",
                "https://www.akleg.gov/basis/Member/Detail/33?code=mca",
                "https://akhousemajority.com/rep-mccabe/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-30-lower-ocd-person-0160c6d2-4090-4143-8e23-f8501501bdd0": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/5ef3ef20-0fd1-41a3-be56-7844d169bba8",
            "firstName": "Maxine",
            "lastName": "Dibert",
            "fullName": "Maxine Dibert",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/DIB.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-AK-31-lower-ocd-person-5ef3ef20-0fd1-41a3-be56-7844d169bba8",
              "builtID": "ak-lower-31",
              "externalID": "ocd-person/5ef3ef20-0fd1-41a3-be56-7844d169bba8",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.maxine.dibert@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1292 Sadler Way Suite 308, Fairbanks, AK 99701",
              "phone1": null,
              "phone2": "907-451-4347",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=dib",
                "https://akhouse.org/rep-dibert/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-31-lower-ocd-person-5ef3ef20-0fd1-41a3-be56-7844d169bba8": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/ede005ce-9d29-4b8e-8b24-b2136d05edf6",
            "firstName": "Will",
            "lastName": "Stapp",
            "fullName": "Will Stapp",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/SPP.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-AK-32-lower-ocd-person-ede005ce-9d29-4b8e-8b24-b2136d05edf6",
              "builtID": "ak-lower-32",
              "externalID": "ocd-person/ede005ce-9d29-4b8e-8b24-b2136d05edf6",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.will.stapp@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1292 Sadler Way Suite 308, Fairbanks, AK 99701",
              "phone1": null,
              "phone2": "907-452-1088",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=spp",
                "https://akhousemajority.com/rep-stapp/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-32-lower-ocd-person-ede005ce-9d29-4b8e-8b24-b2136d05edf6": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/3f4670ef-c5b7-4a5b-bd91-9f8605a0affa",
            "firstName": "Glenn Mike",
            "lastName": "Prax",
            "fullName": "Mike Prax",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/PRA.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-AK-33-lower-ocd-person-3f4670ef-c5b7-4a5b-bd91-9f8605a0affa",
              "builtID": "ak-lower-33",
              "externalID": "ocd-person/3f4670ef-c5b7-4a5b-bd91-9f8605a0affa",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.mike.prax@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1292 Sadler Way, Suite 308, Fairbanks, AK 99701",
              "phone1": null,
              "phone2": "907-451-2723",
              "fax1": null,
              "fax2": "907-456-3346",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/32?code=pra",
                "https://www.akleg.gov/basis/Member/Detail/33?code=pra",
                "https://akhousemajority.com/rep-prax/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-33-lower-ocd-person-3f4670ef-c5b7-4a5b-bd91-9f8605a0affa": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/498eadb3-9999-4055-9358-84ccfe64f788",
            "firstName": "Frank",
            "lastName": "Tomaszewski",
            "fullName": "Frank Tomaszewski",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/TMZ.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-AK-34-lower-ocd-person-498eadb3-9999-4055-9358-84ccfe64f788",
              "builtID": "ak-lower-34",
              "externalID": "ocd-person/498eadb3-9999-4055-9358-84ccfe64f788",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.frank.tomaszewski@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1292 Sadler Way Suite 308, Fairbanks, AK 99701",
              "phone1": null,
              "phone2": "907-456-8172",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.akleg.gov/basis/Member/Detail/33?code=tmz"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-34-lower-ocd-person-498eadb3-9999-4055-9358-84ccfe64f788": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/1ac31dd3-6e42-43b1-91b0-4da4a1399543",
            "firstName": "Ashley",
            "lastName": "Carrick",
            "fullName": "Ashley Carrick",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/CAI.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-AK-35-lower-ocd-person-1ac31dd3-6e42-43b1-91b0-4da4a1399543",
              "builtID": "ak-lower-35",
              "externalID": "ocd-person/1ac31dd3-6e42-43b1-91b0-4da4a1399543",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.ashley.carrick@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1292 Sadler Way Suite 308, Fairbanks, AK 99701",
              "phone1": null,
              "phone2": "907-452-6084",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=cai",
                "https://akhouse.org/rep-carrick/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-35-lower-ocd-person-1ac31dd3-6e42-43b1-91b0-4da4a1399543": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/c50de9c3-90a3-4c6d-9003-50e12c6c5246",
            "firstName": "Mike",
            "lastName": "Cronk",
            "fullName": "Mike Cronk",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/CRN.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-AK-36-lower-ocd-person-c50de9c3-90a3-4c6d-9003-50e12c6c5246",
              "builtID": "ak-lower-36",
              "externalID": "ocd-person/c50de9c3-90a3-4c6d-9003-50e12c6c5246",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.mike.cronk@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 658 MP 1314 Alaska Highway, Tok, AK 99780",
              "phone1": null,
              "phone2": "907-465-4527",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/32?code=crn",
                "https://www.akleg.gov/basis/Member/Detail/33?code=crn",
                "https://akhousemajority.com/rep-cronk/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-36-lower-ocd-person-c50de9c3-90a3-4c6d-9003-50e12c6c5246": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/b097e069-345d-42da-b821-0c2c9747ccfc",
            "firstName": "Bryce",
            "lastName": "Edgmon",
            "fullName": "Bryce Edgmon",
            "gender": "Male",
            "party": "independent",
            "image": "http://akleg.gov/images/legislators/450x600/EDG.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-AK-37-lower-ocd-person-b097e069-345d-42da-b821-0c2c9747ccfc",
              "builtID": "ak-lower-37",
              "externalID": "ocd-person/b097e069-345d-42da-b821-0c2c9747ccfc",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.bryce.edgmon@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0275",
              "fax1": null,
              "fax2": "907-269-0274",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=edg",
                "https://www.akleg.gov/basis/Member/Detail/31?code=edg",
                "https://www.akleg.gov/basis/Member/Detail/32?code=edg",
                "https://www.akleg.gov/basis/Member/Detail/33?code=edg",
                "https://akhousemajority.com/rep-edgmon/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-37-lower-ocd-person-b097e069-345d-42da-b821-0c2c9747ccfc": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/fad7e605-7e5a-4251-88b6-edd021968bff",
            "firstName": "Conrad J.",
            "lastName": "McCormick",
            "fullName": "C.J. McCormick",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/MCO.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-AK-38-lower-ocd-person-fad7e605-7e5a-4251-88b6-edd021968bff",
              "builtID": "ak-lower-38",
              "externalID": "ocd-person/fad7e605-7e5a-4251-88b6-edd021968bff",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.cj.mccormick@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 886, Bethel, AK 99559",
              "phone1": null,
              "phone2": "907-465-4942",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=mco",
                "https://akhousemajority.com/rep-mccormick/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-38-lower-ocd-person-fad7e605-7e5a-4251-88b6-edd021968bff": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/14f39e67-1604-4eb4-8000-99c699f196a0",
            "firstName": "Neal Winston",
            "lastName": "Foster",
            "fullName": "Neal Foster",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/FON.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-AK-39-lower-ocd-person-14f39e67-1604-4eb4-8000-99c699f196a0",
              "builtID": "ak-lower-39",
              "externalID": "ocd-person/14f39e67-1604-4eb4-8000-99c699f196a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.neal.foster@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "103 E. Front St., Nome, AK 99762",
              "phone1": null,
              "phone2": "907-443-5036",
              "fax1": null,
              "fax2": "907-443-2162",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=fon",
                "https://www.akleg.gov/basis/Member/Detail/31?code=fon",
                "https://www.akleg.gov/basis/Member/Detail/32?code=fon",
                "https://akleg.gov/legislator.php?id=fon",
                "https://www.akleg.gov/basis/Member/Detail/33?code=fon",
                "https://akhousemajority.com/rep-foster/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-39-lower-ocd-person-14f39e67-1604-4eb4-8000-99c699f196a0": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/08482886-6b5c-4890-9dae-36ab92946eb7",
            "firstName": "Thomas",
            "lastName": "Baker",
            "fullName": "Thomas Baker",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/BAK.jpg",
            "title": "AK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-AK-40-lower-ocd-person-08482886-6b5c-4890-9dae-36ab92946eb7",
              "builtID": "ak-lower-40",
              "externalID": "ocd-person/08482886-6b5c-4890-9dae-36ab92946eb7",
              "geometry": null
            },
            "contactInfo": {
              "email": "representative.thomas.baker@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.akleg.gov/basis/Member/Detail/33?code=bak"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AK-40-lower-ocd-person-08482886-6b5c-4890-9dae-36ab92946eb7": 0
        }
      }
    },
    "SLDU": {
      "A": {
        "members": [
          {
            "API_ID": "ocd-person/bfdc2ee2-7760-4119-9d68-4962f1e1a285",
            "firstName": "Bert",
            "lastName": "Stedman",
            "fullName": "Bert Stedman",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/SMN.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "A",
              "chamber": "upper",
              "hashID": "SLDU-AK-A-upper-ocd-person-bfdc2ee2-7760-4119-9d68-4962f1e1a285",
              "builtID": "ak-upper-a",
              "externalID": "ocd-person/bfdc2ee2-7760-4119-9d68-4962f1e1a285",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.bert.stedman@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1900 1st Ave. Suite 310, Ketchikan, AK 99901",
              "phone1": null,
              "phone2": "907-225-8088",
              "fax1": null,
              "fax2": "907-225-0713",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=smn",
                "https://www.akleg.gov/basis/Member/Detail/31?code=smn",
                "https://www.akleg.gov/basis/Member/Detail/32?code=smn",
                "https://www.akleg.gov/basis/Member/Detail/33?code=smn",
                "https://alaskasenate.org/stedman/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-A-upper-ocd-person-bfdc2ee2-7760-4119-9d68-4962f1e1a285": 0
        }
      },
      "K": {
        "members": [
          {
            "API_ID": "ocd-person/57554431-caac-4074-a8d8-40a4e6289ec1",
            "firstName": "Bill P.",
            "lastName": "Wielechowski",
            "fullName": "Bill Wielechowski",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/WIE.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "K",
              "chamber": "upper",
              "hashID": "SLDU-AK-K-upper-ocd-person-57554431-caac-4074-a8d8-40a4e6289ec1",
              "builtID": "ak-upper-k",
              "externalID": "ocd-person/57554431-caac-4074-a8d8-40a4e6289ec1",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.bill.wielechowski@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99501",
              "phone1": null,
              "phone2": "907-269-0120",
              "fax1": null,
              "fax2": "907-269-0122",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=wie",
                "https://www.akleg.gov/basis/Member/Detail/31?code=wie",
                "https://www.akleg.gov/basis/Member/Detail/32?code=wie",
                "https://www.akleg.gov/basis/Member/Detail/33?code=wie",
                "https://senatorbill.org/",
                "https://alaskasenate.org/wielechowski/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-K-upper-ocd-person-57554431-caac-4074-a8d8-40a4e6289ec1": 0
        }
      },
      "E": {
        "members": [
          {
            "API_ID": "ocd-person/4ac9a4bf-e2d9-4257-a360-9e91dc234deb",
            "firstName": "Catherine Andrea",
            "lastName": "Giessel",
            "fullName": "Cathy Giessel",
            "gender": "Female",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/GIE.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "E",
              "chamber": "upper",
              "hashID": "SLDU-AK-E-upper-ocd-person-4ac9a4bf-e2d9-4257-a360-9e91dc234deb",
              "builtID": "ak-upper-e",
              "externalID": "ocd-person/4ac9a4bf-e2d9-4257-a360-9e91dc234deb",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.cathy.giessel@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99501",
              "phone1": null,
              "phone2": "907-269-0181",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=gie",
                "https://www.akleg.gov/basis/Member/Detail/31?code=gie",
                "https://www.akleg.gov/basis/Member/Detail/33?code=GIE",
                "https://www.akleg.gov/basis/Member/Detail/33?code=gie",
                "https://alaskasenate.org/giessel/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-E-upper-ocd-person-4ac9a4bf-e2d9-4257-a360-9e91dc234deb": 0
        }
      },
      "R": {
        "members": [
          {
            "API_ID": "ocd-person/df5e57e6-9664-4a5b-ad78-b8537765a3ff",
            "firstName": "Clark C.",
            "lastName": "Bishop",
            "fullName": "Click Bishop",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/BIS.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "R",
              "chamber": "upper",
              "hashID": "SLDU-AK-R-upper-ocd-person-df5e57e6-9664-4a5b-ad78-b8537765a3ff",
              "builtID": "ak-upper-r",
              "externalID": "ocd-person/df5e57e6-9664-4a5b-ad78-b8537765a3ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.click.bishop@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1292 Sadler Way Suite 308, Fairbanks, AK 99701",
              "phone1": null,
              "phone2": "907-456-8161",
              "fax1": null,
              "fax2": "907-456-3346",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=bis",
                "https://www.akleg.gov/basis/Member/Detail/31?code=bis",
                "https://www.akleg.gov/basis/Member/Detail/32?code=bis",
                "https://www.akleg.gov/basis/mbr_info.asp?session=33",
                "https://www.akleg.gov/basis/Member/Detail/33?code=bis",
                "https://alaskasenate.org/bishop/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-R-upper-ocd-person-df5e57e6-9664-4a5b-ad78-b8537765a3ff": 0
        }
      },
      "N": {
        "members": [
          {
            "API_ID": "ocd-person/d19f85d6-4018-49c7-9f3c-a477dd8096e6",
            "firstName": "David S.",
            "lastName": "Wilson",
            "fullName": "David Wilson",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/WLN.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "N",
              "chamber": "upper",
              "hashID": "SLDU-AK-N-upper-ocd-person-d19f85d6-4018-49c7-9f3c-a477dd8096e6",
              "builtID": "ak-upper-n",
              "externalID": "ocd-person/d19f85d6-4018-49c7-9f3c-a477dd8096e6",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.david.wilson@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "600 E. Railroad Ave. Suite 1, Wasilla, AK 99654",
              "phone1": null,
              "phone2": "907-376-4866",
              "fax1": null,
              "fax2": "907-373-4724",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=wln",
                "https://www.akleg.gov/basis/Member/Detail/31?code=wln",
                "https://www.akleg.gov/basis/Member/Detail/32?code=wln",
                "https://www.akleg.gov/basis/Member/Detail/33?code=WLN",
                "https://www.akleg.gov/basis/Member/Detail/33?code=wln",
                "https://alaskasenate.org/wilson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-N-upper-ocd-person-d19f85d6-4018-49c7-9f3c-a477dd8096e6": 0
        }
      },
      "T": {
        "members": [
          {
            "API_ID": "ocd-person/cd3ca264-553d-4e21-a993-e3f5097b1b77",
            "firstName": "Donald C.",
            "lastName": "Olson",
            "fullName": "Donny Olson",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/OLS.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "T",
              "chamber": "upper",
              "hashID": "SLDU-AK-T-upper-ocd-person-cd3ca264-553d-4e21-a993-e3f5097b1b77",
              "builtID": "ak-upper-t",
              "externalID": "ocd-person/cd3ca264-553d-4e21-a993-e3f5097b1b77",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.donald.olson@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1630, Nome, AK 99762",
              "phone1": null,
              "phone2": "907-269-0254",
              "fax1": null,
              "fax2": "907-269-2031",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=ols",
                "https://www.akleg.gov/basis/Member/Detail/31?code=ols",
                "https://www.akleg.gov/basis/Member/Detail/32?code=ols",
                "https://www.akleg.gov/basis/Member/Detail/33?code=ols",
                "https://alaskasenate.org/olson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-T-upper-ocd-person-cd3ca264-553d-4e21-a993-e3f5097b1b77": 0
        }
      },
      "G": {
        "members": [
          {
            "API_ID": "ocd-person/2ba2d14f-a08a-4f05-a9d4-5ca63d09561e",
            "firstName": "Elvi",
            "lastName": "Gray-Jackson",
            "fullName": "Elvi Gray-Jackson",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/GRA.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "G",
              "chamber": "upper",
              "hashID": "SLDU-AK-G-upper-ocd-person-2ba2d14f-a08a-4f05-a9d4-5ca63d09561e",
              "builtID": "ak-upper-g",
              "externalID": "ocd-person/2ba2d14f-a08a-4f05-a9d4-5ca63d09561e",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.elvi.gray-jackson@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0174",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/31?code=gra",
                "https://www.akleg.gov/basis/Member/Detail/32?code=gra",
                "https://www.akleg.gov/basis/Member/Detail/33?code=GRA",
                "https://www.akleg.gov/basis/Member/Detail/33?code=gra",
                "https://alaskasenate.org/gray-jackson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-G-upper-ocd-person-2ba2d14f-a08a-4f05-a9d4-5ca63d09561e": 0
        }
      },
      "J": {
        "members": [
          {
            "API_ID": "ocd-person/4511370e-1ac8-4135-a622-d5b533faeb43",
            "firstName": "Forrest A.",
            "lastName": "Dunbar",
            "fullName": "Forrest Dunbar",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/DNB.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "J",
              "chamber": "upper",
              "hashID": "SLDU-AK-J-upper-ocd-person-4511370e-1ac8-4135-a622-d5b533faeb43",
              "builtID": "ak-upper-j",
              "externalID": "ocd-person/4511370e-1ac8-4135-a622-d5b533faeb43",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.forrest.dunbar@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 428, 1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0246",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=dnb",
                "https://alaskasenate.org/dunbar/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-J-upper-ocd-person-4511370e-1ac8-4135-a622-d5b533faeb43": 0
        }
      },
      "C": {
        "members": [
          {
            "API_ID": "ocd-person/f5c83068-a809-4f57-8b77-1e77c87ee93b",
            "firstName": "Gary Lee",
            "lastName": "Stevens",
            "fullName": "Gary Stevens",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/STG.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "C",
              "chamber": "upper",
              "hashID": "SLDU-AK-C-upper-ocd-person-f5c83068-a809-4f57-8b77-1e77c87ee93b",
              "builtID": "ak-upper-c",
              "externalID": "ocd-person/f5c83068-a809-4f57-8b77-1e77c87ee93b",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.gary.stevens@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "305 Center Ave. Suite 1, Kodiak, AK 99615",
              "phone1": null,
              "phone2": "907-486-4925",
              "fax1": null,
              "fax2": "907-486-5264",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=stg",
                "https://www.akleg.gov/basis/Member/Detail/31?code=stg",
                "https://www.akleg.gov/basis/Member/Detail/32?code=stg",
                "https://www.akleg.gov/basis/Member/Detail/33?code=stg"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-C-upper-ocd-person-f5c83068-a809-4f57-8b77-1e77c87ee93b": 0
        }
      },
      "F": {
        "members": [
          {
            "API_ID": "ocd-person/f6cd7b64-a3f3-44ec-89a1-68c17fa6d4c9",
            "firstName": "James D.",
            "lastName": "Kaufman",
            "fullName": "James Kaufman",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/KAU.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "F",
              "chamber": "upper",
              "hashID": "SLDU-AK-F-upper-ocd-person-f6cd7b64-a3f3-44ec-89a1-68c17fa6d4c9",
              "builtID": "ak-upper-f",
              "externalID": "ocd-person/f6cd7b64-a3f3-44ec-89a1-68c17fa6d4c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.james.kaufman@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0244",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/32?code=kau",
                "https://www.akleg.gov/basis/Member/Detail/33?code=KAU",
                "https://www.akleg.gov/basis/Member/Detail/33?code=kau",
                "https://alaskasenate.org/kaufman/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-F-upper-ocd-person-f6cd7b64-a3f3-44ec-89a1-68c17fa6d4c9": 0
        }
      },
      "D": {
        "members": [
          {
            "API_ID": "ocd-person/e14b5980-9a38-4420-8903-70738802edf6",
            "firstName": "Jesse",
            "lastName": "Bjorkman",
            "fullName": "Jesse Bjorkman",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/BJK.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "D",
              "chamber": "upper",
              "hashID": "SLDU-AK-D-upper-ocd-person-e14b5980-9a38-4420-8903-70738802edf6",
              "builtID": "ak-upper-d",
              "externalID": "ocd-person/e14b5980-9a38-4420-8903-70738802edf6",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.jesse.bjorkman@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "145 Main St. Loop, Kenai, AK 99611",
              "phone1": null,
              "phone2": "907-283-7996",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=bjk",
                "https://alaskasenate.org/bjorkman/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-D-upper-ocd-person-e14b5980-9a38-4420-8903-70738802edf6": 0
        }
      },
      "B": {
        "members": [
          {
            "API_ID": "ocd-person/1ae3974e-660d-4526-b351-fff9b689b51a",
            "firstName": "Jesse Will",
            "lastName": "Kiehl",
            "fullName": "Jesse Kiehl",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/KIE.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "B",
              "chamber": "upper",
              "hashID": "SLDU-AK-B-upper-ocd-person-1ae3974e-660d-4526-b351-fff9b689b51a",
              "builtID": "ak-upper-b",
              "externalID": "ocd-person/1ae3974e-660d-4526-b351-fff9b689b51a",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.jesse.kiehl@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/31?code=kie",
                "https://www.akleg.gov/basis/Member/Detail/32?code=kie",
                "https://www.akleg.gov/basis/Member/Detail/33?code=kie",
                "https://alaskasenate.org/kiehl/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-B-upper-ocd-person-1ae3974e-660d-4526-b351-fff9b689b51a": 0
        }
      },
      "L": {
        "members": [
          {
            "API_ID": "ocd-person/ddad148b-d8ed-4eff-826d-3861709eb500",
            "firstName": "Kelly Richards",
            "lastName": "Merrick",
            "fullName": "Kelly Merrick",
            "gender": "Female",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/MRR.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "L",
              "chamber": "upper",
              "hashID": "SLDU-AK-L-upper-ocd-person-ddad148b-d8ed-4eff-826d-3861709eb500",
              "builtID": "ak-upper-l",
              "externalID": "ocd-person/ddad148b-d8ed-4eff-826d-3861709eb500",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.kelly.merrick@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-3630",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/31?code=mrr",
                "https://www.akleg.gov/basis/Member/Detail/32?code=mrr",
                "https://www.akleg.gov/basis/Member/Detail/33?code=MRR",
                "https://www.akleg.gov/basis/Member/Detail/33?code=mrr",
                "https://alaskasenate.org/merrick/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-L-upper-ocd-person-ddad148b-d8ed-4eff-826d-3861709eb500": 0
        }
      },
      "I": {
        "members": [
          {
            "API_ID": "ocd-person/c66c309d-1612-4eab-863c-59e34b643e2c",
            "firstName": "Loki Gale",
            "lastName": "Tobin",
            "fullName": "Löki Tobin",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/TOB.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "I",
              "chamber": "upper",
              "hashID": "SLDU-AK-I-upper-ocd-person-c66c309d-1612-4eab-863c-59e34b643e2c",
              "builtID": "ak-upper-i",
              "externalID": "ocd-person/c66c309d-1612-4eab-863c-59e34b643e2c",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.loki.tobin@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0169",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/33?code=tob",
                "https://alaskasenate.org/tobin/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-I-upper-ocd-person-c66c309d-1612-4eab-863c-59e34b643e2c": 0
        }
      },
      "S": {
        "members": [
          {
            "API_ID": "ocd-person/e187d603-9202-4f64-80b0-6366702aec11",
            "firstName": "Lyman F.",
            "lastName": "Hoffman",
            "fullName": "Lyman Hoffman",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/HOF.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "S",
              "chamber": "upper",
              "hashID": "SLDU-AK-S-upper-ocd-person-e187d603-9202-4f64-80b0-6366702aec11",
              "builtID": "ak-upper-s",
              "externalID": "ocd-person/e187d603-9202-4f64-80b0-6366702aec11",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.lyman.hoffman@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 886, Bethel, AK 99559",
              "phone1": null,
              "phone2": "907-465-4453",
              "fax1": null,
              "fax2": "907-465-4523",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=hof",
                "https://www.akleg.gov/basis/Member/Detail/31?code=hof",
                "https://www.akleg.gov/basis/Member/Detail/32?code=hof",
                "https://www.akleg.gov/basis/Member/Detail/33?code=hof",
                "https://alaskasenate.org/hoffman/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-S-upper-ocd-person-e187d603-9202-4f64-80b0-6366702aec11": 0
        }
      },
      "H": {
        "members": [
          {
            "API_ID": "ocd-person/0b2d5ec3-c1b2-4b09-8444-144c92125cc4",
            "firstName": "Matthew W.",
            "lastName": "Claman",
            "fullName": "Matt Claman",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/CLA.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "H",
              "chamber": "upper",
              "hashID": "SLDU-AK-H-upper-ocd-person-0b2d5ec3-c1b2-4b09-8444-144c92125cc4",
              "builtID": "ak-upper-h",
              "externalID": "ocd-person/0b2d5ec3-c1b2-4b09-8444-144c92125cc4",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.matt.claman@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1500 W. Benson Blvd., Anchorage, AK 99503",
              "phone1": null,
              "phone2": "907-269-0130",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=cla",
                "https://www.akleg.gov/basis/Member/Detail/31?code=cla",
                "https://www.akleg.gov/basis/Member/Detail/32?code=cla",
                "https://www.akleg.gov/basis/Member/Detail/33?code=CLA",
                "https://www.akleg.gov/basis/Member/Detail/33?code=cla",
                "https://alaskasenate.org/matt-claman/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-H-upper-ocd-person-0b2d5ec3-c1b2-4b09-8444-144c92125cc4": 0
        }
      },
      "O": {
        "members": [
          {
            "API_ID": "ocd-person/f8593ecf-bd23-47b9-92e4-ae6910979e00",
            "firstName": "Michael Karl",
            "lastName": "Shower",
            "fullName": "Mike Shower",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/SHO.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "O",
              "chamber": "upper",
              "hashID": "SLDU-AK-O-upper-ocd-person-f8593ecf-bd23-47b9-92e4-ae6910979e00",
              "builtID": "ak-upper-o",
              "externalID": "ocd-person/f8593ecf-bd23-47b9-92e4-ae6910979e00",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.mike.shower@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "600 E. Railroad Ave., Wasilla, AK 99654",
              "phone1": null,
              "phone2": "907-376-3370",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=sho",
                "https://www.akleg.gov/basis/Member/Detail/31?code=sho",
                "https://www.akleg.gov/basis/Member/Detail/33?code=SHO",
                "https://akleg.gov/legislator.php?id=sho",
                "https://www.akleg.gov/basis/Member/Detail/33?code=sho",
                "https://www.akleg.gov/basis/Member/Detail/32?code=sho"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-O-upper-ocd-person-f8593ecf-bd23-47b9-92e4-ae6910979e00": 0
        }
      },
      "Q": {
        "members": [
          {
            "API_ID": "ocd-person/697cb834-6aa1-44f1-9c6e-9cf57ad30774",
            "firstName": "Robert",
            "lastName": "Myers",
            "fullName": "Robert Myers",
            "gender": "Male",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/MYE.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "Q",
              "chamber": "upper",
              "hashID": "SLDU-AK-Q-upper-ocd-person-697cb834-6aa1-44f1-9c6e-9cf57ad30774",
              "builtID": "ak-upper-q",
              "externalID": "ocd-person/697cb834-6aa1-44f1-9c6e-9cf57ad30774",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.robert.myers@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1292 Sadler Way Suite 308, Fairbanks, AK 99701",
              "phone1": null,
              "phone2": "907-451-2157",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/32?code=mye",
                "https://www.akleg.gov/basis/Member/Detail/33?code=MYE",
                "https://www.akleg.gov/basis/Member/Detail/33?code=mye"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-Q-upper-ocd-person-697cb834-6aa1-44f1-9c6e-9cf57ad30774": 0
        }
      },
      "P": {
        "members": [
          {
            "API_ID": "ocd-person/53f7b86b-ce31-4199-86ef-f566ae9aa02c",
            "firstName": "Scott Jiu Wo",
            "lastName": "Kawasaki",
            "fullName": "Scott Kawasaki",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://akleg.gov/images/legislators/450x600/KAW.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "P",
              "chamber": "upper",
              "hashID": "SLDU-AK-P-upper-ocd-person-53f7b86b-ce31-4199-86ef-f566ae9aa02c",
              "builtID": "ak-upper-p",
              "externalID": "ocd-person/53f7b86b-ce31-4199-86ef-f566ae9aa02c",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.scott.kawasaki@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1292 Sadler Way Suite 308, Fairbanks, AK 99701",
              "phone1": null,
              "phone2": "907-456-7423",
              "fax1": null,
              "fax2": "907-456-3346",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=kaw",
                "https://www.akleg.gov/basis/Member/Detail/31?code=kaw",
                "https://www.akleg.gov/basis/Member/Detail/32?code=kaw",
                "https://www.akleg.gov/basis/mbr_info.asp?session=33",
                "https://www.akleg.gov/basis/Member/Detail/33?code=kaw",
                "https://aksenatedems.com/sen-kawasaki/",
                "https://alaskasenate.org/kawasaki/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-P-upper-ocd-person-53f7b86b-ce31-4199-86ef-f566ae9aa02c": 0
        }
      },
      "M": {
        "members": [
          {
            "API_ID": "ocd-person/f0e5bb6f-c8d1-43c1-a671-9c15d23781a1",
            "firstName": "Shelley Steiner",
            "lastName": "Hughes",
            "fullName": "Shelley Hughes",
            "gender": "Female",
            "party": "Republican",
            "image": "http://akleg.gov/images/legislators/450x600/HUS.jpg",
            "title": "AK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AK",
              "stateFull": "Alaska",
              "district": "M",
              "chamber": "upper",
              "hashID": "SLDU-AK-M-upper-ocd-person-f0e5bb6f-c8d1-43c1-a671-9c15d23781a1",
              "builtID": "ak-upper-m",
              "externalID": "ocd-person/f0e5bb6f-c8d1-43c1-a671-9c15d23781a1",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.shelley.hughes@akleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": "600 E. Railroad Ave., Wasilla, AK 99654",
              "phone1": null,
              "phone2": "907-376-3725",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.akleg.gov/basis/Member/Detail/30?code=hus",
                "https://www.akleg.gov/basis/Member/Detail/31?code=hus",
                "https://www.akleg.gov/basis/Member/Detail/32?code=hus",
                "https://www.akleg.gov/basis/Member/Detail/33?code=hus"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AK-M-upper-ocd-person-f0e5bb6f-c8d1-43c1-a671-9c15d23781a1": 0
        }
      }
    }
  },
  "AR": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "AR",
          "stateFull": "Arkansas",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "AR",
            "stateFull": "Arkansas",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-AR---",
            "builtID": "ar--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-AR---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "B001236",
          "in_office": true,
          "firstName": "John",
          "lastName": "Boozman",
          "middleName": null,
          "fullName": "John Boozman",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/B001236.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "AR",
            "stateFull": "Arkansas",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-AR---B001236",
            "builtID": "ar--",
            "externalID": "B001236",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "JohnBoozman",
            "youtube": "BoozmanPressOffice",
            "instagram": null,
            "facebook": "JohnBoozman",
            "urls": ["https://www.boozman.senate.gov/public"],
            "rss_url": "https://www.boozman.senate.gov/public/?a=RSS.Feed"
          },
          "title": "AR Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/B001236.json",
          "govtrack_id": "400040",
          "cspan_id": "92069",
          "votesmart_id": "27958",
          "icpsr_id": "20101",
          "crp_id": "N00013873",
          "google_entity_id": "/m/024s3v",
          "state_rank": "senior",
          "lis_id": "S343",
          "suffix": null,
          "date_of_birth": "1950-12-10",
          "leadership_role": null,
          "fec_candidate_id": "H2AR03176",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "13",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 11,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 1.99,
          "votes_with_party_pct": 95.31,
          "votes_against_party_pct": 4.69,
          "ocd_id": "ocd-division/country:us/state:ar"
        },
        {
          "API_ID": "C001095",
          "in_office": true,
          "firstName": "Tom",
          "lastName": "Cotton",
          "middleName": null,
          "fullName": "Tom Cotton",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/C001095.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "AR",
            "stateFull": "Arkansas",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-AR---C001095",
            "builtID": "ar--",
            "externalID": "C001095",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.cotton.senate.gov/contact/contact-tom",
            "address1": "326 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-2353",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenTomCotton",
            "youtube": "RepTomCotton",
            "instagram": null,
            "facebook": "SenatorTomCotton",
            "urls": ["https://www.cotton.senate.gov"],
            "rss_url": null
          },
          "title": "AR Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/C001095.json",
          "govtrack_id": "412508",
          "cspan_id": "63928",
          "votesmart_id": "135651",
          "icpsr_id": "21301",
          "crp_id": "N00033363",
          "google_entity_id": "/m/02g3ds",
          "state_rank": "junior",
          "lis_id": "S374",
          "suffix": null,
          "date_of_birth": "1977-05-13",
          "leadership_role": null,
          "fec_candidate_id": "H2AR04083",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "9",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 25,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 4.51,
          "votes_with_party_pct": 97.86,
          "votes_against_party_pct": 2.14,
          "ocd_id": "ocd-division/country:us/state:ar"
        }
      ],
      "hash": { "SENATE-AR---B001236": 0, "SENATE-AR---C001095": 1 }
    },
    "HOUSE": {
      "01": {
        "members": [
          {
            "API_ID": "C001087",
            "in_office": true,
            "firstName": "Rick",
            "lastName": "Crawford",
            "middleName": null,
            "fullName": "Eric A. \"Rick\" Crawford",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001087.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-AR-01--C001087",
              "builtID": "ar--01",
              "externalID": "C001087",
              "geometry": null,
              "geoid": "0501"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2422 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4076",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRickCrawford",
              "youtube": "RepRickCrawford",
              "instagram": null,
              "facebook": "RepRickCrawford",
              "urls": ["https://crawford.house.gov"],
              "rss_url": "https://crawford.house.gov/news/rss.aspx"
            },
            "title": "AR House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001087.json",
            "govtrack_id": "412400",
            "cspan_id": "623259",
            "votesmart_id": "119208",
            "icpsr_id": "21106",
            "crp_id": "N00030770",
            "google_entity_id": "/m/0czb3pp",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1966-01-22",
            "leadership_role": null,
            "fec_candidate_id": "H0AR01083",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 16,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.52,
            "votes_with_party_pct": 92.93,
            "votes_against_party_pct": 5.21,
            "ocd_id": "ocd-division/country:us/state:ar/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AR-01--C001087": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "H001072",
            "in_office": true,
            "firstName": "French",
            "lastName": "Hill",
            "middleName": null,
            "fullName": "J. French Hill",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/H001072.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-AR-02--H001072",
              "builtID": "ar--02",
              "externalID": "H001072",
              "geometry": null,
              "geoid": "0502"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1533 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2506",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepFrenchHill",
              "youtube": null,
              "instagram": null,
              "facebook": "RepFrenchHill",
              "urls": ["https://hill.house.gov"],
              "rss_url": null
            },
            "title": "AR House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001072.json",
            "govtrack_id": "412609",
            "cspan_id": "9265172",
            "votesmart_id": "146290",
            "icpsr_id": "21503",
            "crp_id": "N00035792",
            "google_entity_id": "/m/0127p1zk",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1956-12-05",
            "leadership_role": null,
            "fec_candidate_id": "H4AR02141",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 10,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.95,
            "votes_with_party_pct": 91.5,
            "votes_against_party_pct": 6.64,
            "ocd_id": "ocd-division/country:us/state:ar/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AR-02--H001072": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "W000821",
            "in_office": true,
            "firstName": "Bruce",
            "lastName": "Westerman",
            "middleName": null,
            "fullName": "Bruce Westerman",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/W000821.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-AR-04--W000821",
              "builtID": "ar--04",
              "externalID": "W000821",
              "geometry": null,
              "geoid": "0504"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "202 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3772",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepWesterman",
              "youtube": null,
              "instagram": null,
              "facebook": "RepWesterman",
              "urls": ["https://westerman.house.gov"],
              "rss_url": "https://westerman.house.gov/rss.xml"
            },
            "title": "AR House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000821.json",
            "govtrack_id": "412610",
            "cspan_id": "76097",
            "votesmart_id": "119120",
            "icpsr_id": "21563",
            "crp_id": "N00035527",
            "google_entity_id": "/m/0wklff5",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1967-11-18",
            "leadership_role": null,
            "fec_candidate_id": "H4AR04048",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 5,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.47,
            "votes_with_party_pct": 94.47,
            "votes_against_party_pct": 3.59,
            "ocd_id": "ocd-division/country:us/state:ar/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AR-04--W000821": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "W000809",
            "in_office": true,
            "firstName": "Steve",
            "lastName": "Womack",
            "middleName": null,
            "fullName": "Steve Womack",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/W000809.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-AR-03--W000809",
              "builtID": "ar--03",
              "externalID": "W000809",
              "geometry": null,
              "geoid": "0503"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2412 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4301",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Rep_SteveWomack",
              "youtube": "CongressmanWomack",
              "instagram": null,
              "facebook": "RepSteveWomack",
              "urls": ["https://womack.house.gov"],
              "rss_url": "https://womack.house.gov/news/rss.aspx"
            },
            "title": "AR House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000809.json",
            "govtrack_id": "412402",
            "cspan_id": "1033625",
            "votesmart_id": "71815",
            "icpsr_id": "21108",
            "crp_id": "N00031857",
            "google_entity_id": "/m/0cmcvkz",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1957-02-18",
            "leadership_role": null,
            "fec_candidate_id": "H0AR03055",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 0,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0,
            "votes_with_party_pct": 86.17,
            "votes_against_party_pct": 11.99,
            "ocd_id": "ocd-division/country:us/state:ar/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-AR-03--W000809": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/bbb656fb-9de4-4ee7-aad5-dd713cba2e1c",
            "firstName": "Jeremy",
            "lastName": "Wooldridge",
            "fullName": "Jeremy Wooldridge",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/601_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-AR-1-lower-ocd-person-bbb656fb-9de4-4ee7-aad5-dd713cba2e1c",
              "builtID": "ar-lower-1",
              "externalID": "ocd-person/bbb656fb-9de4-4ee7-aad5-dd713cba2e1c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeremy.wooldridge@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkansashouse.org/district/1",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Wooldridge&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-1-lower-ocd-person-bbb656fb-9de4-4ee7-aad5-dd713cba2e1c": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/9d4e09cb-8d9f-4c8c-a8df-481af146436d",
            "firstName": "Trey",
            "lastName": "Steimel",
            "fullName": "Trey Steimel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/602_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-AR-2-lower-ocd-person-9d4e09cb-8d9f-4c8c-a8df-481af146436d",
              "builtID": "ar-lower-2",
              "externalID": "ocd-person/9d4e09cb-8d9f-4c8c-a8df-481af146436d",
              "geometry": null
            },
            "contactInfo": {
              "email": "trey.steimel@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Steimel&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/2"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-2-lower-ocd-person-9d4e09cb-8d9f-4c8c-a8df-481af146436d": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/120ce349-5d8a-4fa6-902e-4fa39945d7d7",
            "firstName": "Stetson C.",
            "lastName": "Painter",
            "fullName": "Stetson Painter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/603_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-AR-3-lower-ocd-person-120ce349-5d8a-4fa6-902e-4fa39945d7d7",
              "builtID": "ar-lower-3",
              "externalID": "ocd-person/120ce349-5d8a-4fa6-902e-4fa39945d7d7",
              "geometry": null
            },
            "contactInfo": {
              "email": "stetson.painter@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Painter&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/3"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-3-lower-ocd-person-120ce349-5d8a-4fa6-902e-4fa39945d7d7": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/840b90cf-140f-49e3-bf7d-5c6abff3a66f",
            "firstName": "Jack",
            "lastName": "Fortner",
            "fullName": "Jack Fortner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/513_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-AR-4-lower-ocd-person-840b90cf-140f-49e3-bf7d-5c6abff3a66f",
              "builtID": "ar-lower-4",
              "externalID": "ocd-person/840b90cf-140f-49e3-bf7d-5c6abff3a66f",
              "geometry": null
            },
            "contactInfo": {
              "email": "jack.fortner@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "Jack Fortner for Arkansas State Representative",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Fortner&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Fortner&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Fortner",
                "https://www.arkansashouse.org/district/4"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-4-lower-ocd-person-840b90cf-140f-49e3-bf7d-5c6abff3a66f": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/c45493e9-afbd-4f5e-bb69-e6caed58936a",
            "firstName": "Ronald Dean",
            "lastName": "McNair",
            "fullName": "Ron McNair",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/492_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-AR-5-lower-ocd-person-c45493e9-afbd-4f5e-bb69-e6caed58936a",
              "builtID": "ar-lower-5",
              "externalID": "ocd-person/c45493e9-afbd-4f5e-bb69-e6caed58936a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rmcnair1950@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=McNair",
                "https://www.arkansashouse.org/district/5"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-5-lower-ocd-person-c45493e9-afbd-4f5e-bb69-e6caed58936a": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/72ce8f2a-95f2-4c1c-8e91-203653d0610a",
            "firstName": "Harlan",
            "lastName": "Breaux",
            "fullName": "Harlan Breaux",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/567_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-AR-6-lower-ocd-person-72ce8f2a-95f2-4c1c-8e91-203653d0610a",
              "builtID": "ar-lower-6",
              "externalID": "ocd-person/72ce8f2a-95f2-4c1c-8e91-203653d0610a",
              "geometry": null
            },
            "contactInfo": {
              "email": "harlan.breaux@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "Harlan Breaux for State Representative",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Breaux&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Breaux&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Breaux",
                "https://www.arkansashouse.org/district/6"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-6-lower-ocd-person-72ce8f2a-95f2-4c1c-8e91-203653d0610a": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/0fc23ae8-f37f-4684-b8f5-083c8e36e19d",
            "firstName": "John Brittain",
            "lastName": "McKenzie",
            "fullName": "Brit McKenzie",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/604_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-AR-7-lower-ocd-person-0fc23ae8-f37f-4684-b8f5-083c8e36e19d",
              "builtID": "ar-lower-7",
              "externalID": "ocd-person/0fc23ae8-f37f-4684-b8f5-083c8e36e19d",
              "geometry": null
            },
            "contactInfo": {
              "email": "brit.mckenzie@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=B.+McKenzie&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/7"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-7-lower-ocd-person-0fc23ae8-f37f-4684-b8f5-083c8e36e19d": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/30ea7268-a48a-424e-aadf-007b61874985",
            "firstName": "Austin",
            "lastName": "McCollum",
            "fullName": "Austin McCollum",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/531_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-AR-8-lower-ocd-person-30ea7268-a48a-424e-aadf-007b61874985",
              "builtID": "ar-lower-8",
              "externalID": "ocd-person/30ea7268-a48a-424e-aadf-007b61874985",
              "geometry": null
            },
            "contactInfo": {
              "email": "austin.mccollum@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "austinmcc13",
              "facebook": "McCollumForArkansas",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=McCollum&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=McCollum&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=McCollum",
                "https://www.arkansashouse.org/district/8"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-8-lower-ocd-person-30ea7268-a48a-424e-aadf-007b61874985": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/2412f4a9-d6f6-4752-a9b6-9b923533c4ba",
            "firstName": "DeAnna Harris",
            "lastName": "Hodges",
            "fullName": "De Hodges",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/641_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-AR-9-lower-ocd-person-2412f4a9-d6f6-4752-a9b6-9b923533c4ba",
              "builtID": "ar-lower-9",
              "externalID": "ocd-person/2412f4a9-d6f6-4752-a9b6-9b923533c4ba",
              "geometry": null
            },
            "contactInfo": {
              "email": "deanna.hodges@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=D.+Hodges&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/9"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-9-lower-ocd-person-2412f4a9-d6f6-4752-a9b6-9b923533c4ba": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/5f09e022-f775-4da8-aaad-224793aa2230",
            "firstName": "Mindy",
            "lastName": "McAlindon",
            "fullName": "Mindy McAlindon",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/605_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-AR-10-lower-ocd-person-5f09e022-f775-4da8-aaad-224793aa2230",
              "builtID": "ar-lower-10",
              "externalID": "ocd-person/5f09e022-f775-4da8-aaad-224793aa2230",
              "geometry": null
            },
            "contactInfo": {
              "email": "mindy.mcalindon@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkansashouse.org/district/10",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=McAlindon&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-10-lower-ocd-person-5f09e022-f775-4da8-aaad-224793aa2230": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/8f012092-5f3f-4047-8047-f2520efe0fad",
            "firstName": "Rebecca",
            "lastName": "Burkes",
            "fullName": "Rebecca Burkes",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/606_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-AR-11-lower-ocd-person-8f012092-5f3f-4047-8047-f2520efe0fad",
              "builtID": "ar-lower-11",
              "externalID": "ocd-person/8f012092-5f3f-4047-8047-f2520efe0fad",
              "geometry": null
            },
            "contactInfo": {
              "email": "rebecca.burkes@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkansashouse.org/district/11",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Burkes&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-11-lower-ocd-person-8f012092-5f3f-4047-8047-f2520efe0fad": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/8f4c182b-d257-45b7-8c34-c4c1f12bb4f6",
            "firstName": "Hope Hendren",
            "lastName": "Duke",
            "fullName": "Hope Duke",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/607_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-AR-12-lower-ocd-person-8f4c182b-d257-45b7-8c34-c4c1f12bb4f6",
              "builtID": "ar-lower-12",
              "externalID": "ocd-person/8f4c182b-d257-45b7-8c34-c4c1f12bb4f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "hope.duke@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Duke&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/12"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-12-lower-ocd-person-8f4c182b-d257-45b7-8c34-c4c1f12bb4f6": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/302953f6-213a-4bca-94a6-08a36b801d6c",
            "firstName": "Scott",
            "lastName": "Richardson",
            "fullName": "Scott Richardson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/608_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-AR-13-lower-ocd-person-302953f6-213a-4bca-94a6-08a36b801d6c",
              "builtID": "ar-lower-13",
              "externalID": "ocd-person/302953f6-213a-4bca-94a6-08a36b801d6c",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.richardson@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": "4106 SW Rhinestone Blvd., Bentonville, AR 72713",
              "phone1": null,
              "phone2": "479-308-8260",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkansashouse.org/district/13",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=R.+Scott+Richardson&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-13-lower-ocd-person-302953f6-213a-4bca-94a6-08a36b801d6c": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/fffc4c69-eca0-47c2-81b0-1f7c7a60f07f",
            "firstName": "Grant Logan",
            "lastName": "Hodges",
            "fullName": "Grant Hodges",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/609_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-AR-14-lower-ocd-person-fffc4c69-eca0-47c2-81b0-1f7c7a60f07f",
              "builtID": "ar-lower-14",
              "externalID": "ocd-person/fffc4c69-eca0-47c2-81b0-1f7c7a60f07f",
              "geometry": null
            },
            "contactInfo": {
              "email": "grant.hodges@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 325, Centerton, AR 72719",
              "phone1": null,
              "phone2": "479-381-9091",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=G.+Hodges",
                "https://www.arkansashouse.org/district/14"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-14-lower-ocd-person-fffc4c69-eca0-47c2-81b0-1f7c7a60f07f": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/dfa1d0ff-842e-49d2-baa7-41525816070e",
            "firstName": "John Paul",
            "lastName": "Carr",
            "fullName": "John Carr",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/594_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-AR-15-lower-ocd-person-dfa1d0ff-842e-49d2-baa7-41525816070e",
              "builtID": "ar-lower-15",
              "externalID": "ocd-person/dfa1d0ff-842e-49d2-baa7-41525816070e",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.carr@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "StateRepJohnPCarr",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Carr&ddBienniumSession=2021%2F2021R",
                "https://johncarrforarkansas.com/",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Carr",
                "https://www.arkansashouse.org/district/15"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-15-lower-ocd-person-dfa1d0ff-842e-49d2-baa7-41525816070e": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/42e0cedc-8165-45c0-988a-d3a414a521c6",
            "firstName": "Kendon Reese",
            "lastName": "Underwood",
            "fullName": "Kendon Underwood",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/592_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-AR-16-lower-ocd-person-42e0cedc-8165-45c0-988a-d3a414a521c6",
              "builtID": "ar-lower-16",
              "externalID": "ocd-person/42e0cedc-8165-45c0-988a-d3a414a521c6",
              "geometry": null
            },
            "contactInfo": {
              "email": "kendon.underwood@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "KendonReese",
              "youtube": null,
              "instagram": "underwoodforarkansas",
              "facebook": "UnderwoodforArkansas",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Underwood&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Underwood&ddBienniumSession=2023%2F2023R",
                "https://www.underwood4arkansas.com",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Underwood",
                "https://www.arkansashouse.org/district/16"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-16-lower-ocd-person-42e0cedc-8165-45c0-988a-d3a414a521c6": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/6efed20f-d62e-461e-b4de-6885ac04feae",
            "firstName": "Delia J.",
            "lastName": "Haak",
            "fullName": "Delia Haak",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/593_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-AR-17-lower-ocd-person-6efed20f-d62e-461e-b4de-6885ac04feae",
              "builtID": "ar-lower-17",
              "externalID": "ocd-person/6efed20f-d62e-461e-b4de-6885ac04feae",
              "geometry": null
            },
            "contactInfo": {
              "email": "delia.haak@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": "UCKDv-SQOrQIJBmirbLSLozQ",
              "instagram": "delia.haak",
              "facebook": "DeliaHaakAR",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Haak&ddBienniumSession=2021%2F2021R",
                "https://www.deliahaak.com/",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Haak",
                "https://www.arkansashouse.org/district/17"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-17-lower-ocd-person-6efed20f-d62e-461e-b4de-6885ac04feae": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/c26f1f0e-232e-4bf2-99f8-be3e80bfcdcc",
            "firstName": "Robin Dale Hall",
            "lastName": "Lundstrum",
            "fullName": "Robin Lundstrum",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/490_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-AR-18-lower-ocd-person-c26f1f0e-232e-4bf2-99f8-be3e80bfcdcc",
              "builtID": "ar-lower-18",
              "externalID": "ocd-person/c26f1f0e-232e-4bf2-99f8-be3e80bfcdcc",
              "geometry": null
            },
            "contactInfo": {
              "email": "robin.lundstrum@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RobinLundstrum",
              "youtube": null,
              "instagram": null,
              "facebook": "Robin-Lundstrum-457537847631425",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Lundstrum&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Lundstrum&ddBienniumSession=2021%2F2021R",
                "https://lundstrum4arkansas.com",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Lundstrum",
                "https://www.arkansashouse.org/district/18"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-18-lower-ocd-person-c26f1f0e-232e-4bf2-99f8-be3e80bfcdcc": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/1d522f48-2a04-40ed-860f-3c580d9c87fa",
            "firstName": "Steven",
            "lastName": "Unger",
            "fullName": "Steve Unger",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/610_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-AR-19-lower-ocd-person-1d522f48-2a04-40ed-860f-3c580d9c87fa",
              "builtID": "ar-lower-19",
              "externalID": "ocd-person/1d522f48-2a04-40ed-860f-3c580d9c87fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.unger@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkansashouse.org/district/19",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Unger&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-19-lower-ocd-person-1d522f48-2a04-40ed-860f-3c580d9c87fa": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/e8e66020-d749-42e0-9e4e-35962d7f31f4",
            "firstName": "Denise Firmin",
            "lastName": "Garner",
            "fullName": "Denise Garner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/555_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-AR-20-lower-ocd-person-e8e66020-d749-42e0-9e4e-35962d7f31f4",
              "builtID": "ar-lower-20",
              "externalID": "ocd-person/e8e66020-d749-42e0-9e4e-35962d7f31f4",
              "geometry": null
            },
            "contactInfo": {
              "email": "denise.garner@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "DeniseforAR",
              "youtube": null,
              "instagram": "deniseforarkansas",
              "facebook": "deniseforArkansas84",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=D.+Garner&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=D.+Garner&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=D.+Garner&ddBienniumSession=2023%2F2023R",
                "https://denisegarner.org/",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=D.+Garner",
                "https://www.arkansashouse.org/district/20"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-20-lower-ocd-person-e8e66020-d749-42e0-9e4e-35962d7f31f4": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/c3be3a52-2646-4ea6-9aef-e4ff36a16762",
            "firstName": "Nicole Lefrancois",
            "lastName": "Clowney",
            "fullName": "Nicole Clowney",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/559_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-AR-21-lower-ocd-person-c3be3a52-2646-4ea6-9aef-e4ff36a16762",
              "builtID": "ar-lower-21",
              "externalID": "ocd-person/c3be3a52-2646-4ea6-9aef-e4ff36a16762",
              "geometry": null
            },
            "contactInfo": {
              "email": "nicole.clowney@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "NicoleClowneyAR",
              "youtube": null,
              "instagram": "nicoleclowneyar",
              "facebook": "NicoleClowneyAR",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Clowney&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Clowney&ddBienniumSession=2021%2F2021R",
                "https://www.nicoleclowney.org",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Clowney",
                "https://www.arkansashouse.org/district/21"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-21-lower-ocd-person-c3be3a52-2646-4ea6-9aef-e4ff36a16762": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/7c229d62-dd8f-491c-8aab-15118335cfe9",
            "firstName": "David Jeffrey",
            "lastName": "Whitaker",
            "fullName": "David Whitaker",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/440_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-AR-22-lower-ocd-person-7c229d62-dd8f-491c-8aab-15118335cfe9",
              "builtID": "ar-lower-22",
              "externalID": "ocd-person/7c229d62-dd8f-491c-8aab-15118335cfe9",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.whitaker@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repwhitaker85",
              "youtube": null,
              "instagram": "repwhitaker85",
              "facebook": "Whitakerfor85",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=D.+Whitaker&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=D.+Whitaker&ddBienniumSession=2021%2F2021R",
                "https://www.davidforar.com/",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=D.+Whitaker",
                "https://www.arkansashouse.org/district/22"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-22-lower-ocd-person-7c229d62-dd8f-491c-8aab-15118335cfe9": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/46a1908a-c372-4f84-8ee2-361d767b982a",
            "firstName": "Kendra",
            "lastName": "Moore",
            "fullName": "Kendra Moore",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/611_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-AR-23-lower-ocd-person-46a1908a-c372-4f84-8ee2-361d767b982a",
              "builtID": "ar-lower-23",
              "externalID": "ocd-person/46a1908a-c372-4f84-8ee2-361d767b982a",
              "geometry": null
            },
            "contactInfo": {
              "email": "kendra.moore@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=K.+Moore&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/23"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-23-lower-ocd-person-46a1908a-c372-4f84-8ee2-361d767b982a": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/7bf20505-b606-475f-ac90-35d723ddc15d",
            "firstName": "Edna Charlene Harris Barnes",
            "lastName": "Fite",
            "fullName": "Charlene Fite",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/422_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-AR-24-lower-ocd-person-7bf20505-b606-475f-ac90-35d723ddc15d",
              "builtID": "ar-lower-24",
              "externalID": "ocd-person/7bf20505-b606-475f-ac90-35d723ddc15d",
              "geometry": null
            },
            "contactInfo": {
              "email": "charlene.fite@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "charlenefitear",
              "youtube": null,
              "instagram": "charlene.staterepresentative",
              "facebook": "charlene.staterepresentative",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=C.+Fite&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=C.+Fite&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=C.+Fite",
                "https://www.arkansashouse.org/district/24"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-24-lower-ocd-person-7bf20505-b606-475f-ac90-35d723ddc15d": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/53cecb4b-96b5-4cbe-bc72-6eab07cbdeea",
            "firstName": "Chad",
            "lastName": "Puryear",
            "fullName": "Chad Puryear",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/612_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-AR-25-lower-ocd-person-53cecb4b-96b5-4cbe-bc72-6eab07cbdeea",
              "builtID": "ar-lower-25",
              "externalID": "ocd-person/53cecb4b-96b5-4cbe-bc72-6eab07cbdeea",
              "geometry": null
            },
            "contactInfo": {
              "email": "chad.puryear@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Puryear&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/25"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-25-lower-ocd-person-53cecb4b-96b5-4cbe-bc72-6eab07cbdeea": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/b0180c3f-2b64-43ad-9da1-29993de58ef7",
            "firstName": "Mark H.",
            "lastName": "Berry",
            "fullName": "Mark Berry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2021/House/591_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-AR-26-lower-ocd-person-b0180c3f-2b64-43ad-9da1-29993de58ef7",
              "builtID": "ar-lower-26",
              "externalID": "ocd-person/b0180c3f-2b64-43ad-9da1-29993de58ef7",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.berry@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "GeneralMarkHBerry",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=M.+Berry&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=M.+Berry",
                "https://www.arkansashouse.org/district/26"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-26-lower-ocd-person-b0180c3f-2b64-43ad-9da1-29993de58ef7": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/155853e2-b93f-442f-b551-5084b5c2105f",
            "firstName": "Steven",
            "lastName": "Walker",
            "fullName": "Steven Walker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/613_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-AR-27-lower-ocd-person-155853e2-b93f-442f-b551-5084b5c2105f",
              "builtID": "ar-lower-27",
              "externalID": "ocd-person/155853e2-b93f-442f-b551-5084b5c2105f",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.walker@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Walker&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/27"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-27-lower-ocd-person-155853e2-b93f-442f-b551-5084b5c2105f": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/4f5cafe6-850c-42b3-8a75-cd13c8871baf",
            "firstName": "Bart",
            "lastName": "Schulz",
            "fullName": "Bart Schulz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/614_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-AR-28-lower-ocd-person-4f5cafe6-850c-42b3-8a75-cd13c8871baf",
              "builtID": "ar-lower-28",
              "externalID": "ocd-person/4f5cafe6-850c-42b3-8a75-cd13c8871baf",
              "geometry": null
            },
            "contactInfo": {
              "email": "bart.schulz@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Schulz&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/28"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-28-lower-ocd-person-4f5cafe6-850c-42b3-8a75-cd13c8871baf": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/9b76655d-a7c5-474d-8a35-5a30e8bf9d3a",
            "firstName": "Rick",
            "lastName": "McClure",
            "fullName": "Rick McClure",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/586_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-AR-29-lower-ocd-person-9b76655d-a7c5-474d-8a35-5a30e8bf9d3a",
              "builtID": "ar-lower-29",
              "externalID": "ocd-person/9b76655d-a7c5-474d-8a35-5a30e8bf9d3a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.mcclure@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=McClure",
                "https://en.wikipedia.org/wiki/Rick_McClure",
                "https://www.arkansashouse.org/district/29"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-29-lower-ocd-person-9b76655d-a7c5-474d-8a35-5a30e8bf9d3a": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/f3fd86cb-bfa0-47e0-8318-d53b2958c19c",
            "firstName": "Frances Wells",
            "lastName": "Cavenaugh",
            "fullName": "Fran Cavenaugh",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/522_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-AR-30-lower-ocd-person-f3fd86cb-bfa0-47e0-8318-d53b2958c19c",
              "builtID": "ar-lower-30",
              "externalID": "ocd-person/f3fd86cb-bfa0-47e0-8318-d53b2958c19c",
              "geometry": null
            },
            "contactInfo": {
              "email": "frances.cavenaugh@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "francavenaugh",
              "youtube": null,
              "instagram": null,
              "facebook": "FranCavenaughAR",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Cavenaugh&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Cavenaugh&ddBienniumSession=2021%2F2021R",
                "https://francavenaugh.com/",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Cavenaugh",
                "https://www.arkansashouse.org/district/30"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-30-lower-ocd-person-f3fd86cb-bfa0-47e0-8318-d53b2958c19c": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/5b260e70-f46b-4d1f-83d9-bd242c11a645",
            "firstName": "Jimmy D.",
            "lastName": "Gazaway",
            "fullName": "Jimmy Gazaway",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/526_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-AR-31-lower-ocd-person-5b260e70-f46b-4d1f-83d9-bd242c11a645",
              "builtID": "ar-lower-31",
              "externalID": "ocd-person/5b260e70-f46b-4d1f-83d9-bd242c11a645",
              "geometry": null
            },
            "contactInfo": {
              "email": "jimmy.gazaway@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "jimmygazaway",
              "youtube": null,
              "instagram": null,
              "facebook": "JimmyGazawayAR",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Gazaway&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Gazaway&ddBienniumSession=2021%2F2021R",
                "https://www.jimmygazaway.com/",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Gazaway",
                "https://www.arkansashouse.org/district/31"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-31-lower-ocd-person-5b260e70-f46b-4d1f-83d9-bd242c11a645": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/acc25f3e-2151-45ae-8c48-26e598718a47",
            "firstName": "Jack R.",
            "lastName": "Ladyman",
            "fullName": "Jack Ladyman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/488_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-AR-32-lower-ocd-person-acc25f3e-2151-45ae-8c48-26e598718a47",
              "builtID": "ar-lower-32",
              "externalID": "ocd-person/acc25f3e-2151-45ae-8c48-26e598718a47",
              "geometry": null
            },
            "contactInfo": {
              "email": "jackladyman@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "JackLadyman",
              "youtube": null,
              "instagram": null,
              "facebook": "jackladymancampaign",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Ladyman&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Ladyman&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Ladyman",
                "https://www.arkansashouse.org/district/32"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-32-lower-ocd-person-acc25f3e-2151-45ae-8c48-26e598718a47": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/2920db4d-9f68-411a-bd01-9ca6e9089ef7",
            "firstName": "Jon",
            "lastName": "Milligan",
            "fullName": "Jon Milligan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/590_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-AR-33-lower-ocd-person-2920db4d-9f68-411a-bd01-9ca6e9089ef7",
              "builtID": "ar-lower-33",
              "externalID": "ocd-person/2920db4d-9f68-411a-bd01-9ca6e9089ef7",
              "geometry": null
            },
            "contactInfo": {
              "email": "jonmilligan@ymail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "jon.milligan.545",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Milligan&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Milligan",
                "https://www.arkansashouse.org/district/33",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Milligan&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-33-lower-ocd-person-2920db4d-9f68-411a-bd01-9ca6e9089ef7": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/99d5591e-13f9-40af-90f4-97c5f958031a",
            "firstName": "Joey L.",
            "lastName": "Carr",
            "fullName": "Joey Carr",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/615_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-AR-34-lower-ocd-person-99d5591e-13f9-40af-90f4-97c5f958031a",
              "builtID": "ar-lower-34",
              "externalID": "ocd-person/99d5591e-13f9-40af-90f4-97c5f958031a",
              "geometry": null
            },
            "contactInfo": {
              "email": "joey.carr@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Joey+Carr&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/34"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-34-lower-ocd-person-99d5591e-13f9-40af-90f4-97c5f958031a": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/2c84867a-5002-4d73-aba6-c446c6b908c4",
            "firstName": "Milton",
            "lastName": "Nicks",
            "fullName": "Milton Nicks",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/493_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-AR-35-lower-ocd-person-2c84867a-5002-4d73-aba6-c446c6b908c4",
              "builtID": "ar-lower-35",
              "externalID": "ocd-person/2c84867a-5002-4d73-aba6-c446c6b908c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "milton.nicks@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "nicks4arkansas",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Nicks&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Nicks&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Nicks",
                "https://www.arkansashouse.org/district/35"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-35-lower-ocd-person-2c84867a-5002-4d73-aba6-c446c6b908c4": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/3ce18467-b330-4d64-98d6-79c31414e7ca",
            "firstName": "Johnny",
            "lastName": "Rye",
            "fullName": "Johnny Rye",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/534_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-AR-36-lower-ocd-person-3ce18467-b330-4d64-98d6-79c31414e7ca",
              "builtID": "ar-lower-36",
              "externalID": "ocd-person/3ce18467-b330-4d64-98d6-79c31414e7ca",
              "geometry": null
            },
            "contactInfo": {
              "email": "johnny.rye@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "100058104337901",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Rye&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Rye&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Rye",
                "https://www.arkansashouse.org/district/36"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-36-lower-ocd-person-3ce18467-b330-4d64-98d6-79c31414e7ca": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/6eae19da-9b27-462c-af4b-28615d8d2f54",
            "firstName": "Steve",
            "lastName": "Hollowell",
            "fullName": "Steve Hollowell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/527_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-AR-37-lower-ocd-person-6eae19da-9b27-462c-af4b-28615d8d2f54",
              "builtID": "ar-lower-37",
              "externalID": "ocd-person/6eae19da-9b27-462c-af4b-28615d8d2f54",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.hollowell@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SteveHollowell4",
              "youtube": null,
              "instagram": null,
              "facebook": "steve.hollowell.7",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Hollowell&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Hollowell&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Hollowell",
                "https://www.arkansashouse.org/district/37"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-37-lower-ocd-person-6eae19da-9b27-462c-af4b-28615d8d2f54": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/38a8f4c9-1138-461c-a8a1-ac853c79d3ca",
            "firstName": "Dwight Crandall",
            "lastName": "Tosh",
            "fullName": "Dwight Tosh",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/503_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-AR-38-lower-ocd-person-38a8f4c9-1138-461c-a8a1-ac853c79d3ca",
              "builtID": "ar-lower-38",
              "externalID": "ocd-person/38a8f4c9-1138-461c-a8a1-ac853c79d3ca",
              "geometry": null
            },
            "contactInfo": {
              "email": "dwight.tosh@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "State Representative Dwight Tosh",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Tosh&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Tosh&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Tosh",
                "https://www.arkansashouse.org/district/38"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-38-lower-ocd-person-38a8f4c9-1138-461c-a8a1-ac853c79d3ca": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/e8865ad0-46d2-49a0-8273-da14fe929f3e",
            "firstName": "Wayne",
            "lastName": "Long",
            "fullName": "Wayne Long",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/616_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-AR-39-lower-ocd-person-e8865ad0-46d2-49a0-8273-da14fe929f3e",
              "builtID": "ar-lower-39",
              "externalID": "ocd-person/e8865ad0-46d2-49a0-8273-da14fe929f3e",
              "geometry": null
            },
            "contactInfo": {
              "email": "wayne.long@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Long&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/39"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-39-lower-ocd-person-e8865ad0-46d2-49a0-8273-da14fe929f3e": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/4f64d1ad-6e2b-443d-b8cb-64164febff31",
            "firstName": "Shad",
            "lastName": "Pearce",
            "fullName": "Shad Pearce",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/617_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-AR-40-lower-ocd-person-4f64d1ad-6e2b-443d-b8cb-64164febff31",
              "builtID": "ar-lower-40",
              "externalID": "ocd-person/4f64d1ad-6e2b-443d-b8cb-64164febff31",
              "geometry": null
            },
            "contactInfo": {
              "email": "shad.pearce@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkansashouse.org/district/40",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Pearce&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-40-lower-ocd-person-4f64d1ad-6e2b-443d-b8cb-64164febff31": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/ad87b082-c8f3-4ad3-9051-4f765b4836b6",
            "firstName": "Joshua D.",
            "lastName": "Miller",
            "fullName": "Josh Miller",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/432_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-AR-41-lower-ocd-person-ad87b082-c8f3-4ad3-9051-4f765b4836b6",
              "builtID": "ar-lower-41",
              "externalID": "ocd-person/ad87b082-c8f3-4ad3-9051-4f765b4836b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "josh.miller@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Miller",
                "https://www.arkansashouse.org/district/41"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-41-lower-ocd-person-ad87b082-c8f3-4ad3-9051-4f765b4836b6": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/8b64758a-b71a-4bb4-ad5d-d4ea812ebf58",
            "firstName": "Stephen A.",
            "lastName": "Meeks",
            "fullName": "Stephen Meeks",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/374_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-AR-42-lower-ocd-person-8b64758a-b71a-4bb4-ad5d-d4ea812ebf58",
              "builtID": "ar-lower-42",
              "externalID": "ocd-person/8b64758a-b71a-4bb4-ad5d-d4ea812ebf58",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephen.meeks@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": "51 Wadesfield Drive, Greenbrier, AR 72058",
              "phone1": null,
              "phone2": "501-314-9250",
              "fax1": null,
              "fax2": null,
              "twitter": "repstephenmeeks",
              "youtube": null,
              "instagram": null,
              "facebook": "RepStephenMeeks",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=S.+Meeks&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=S.+Meeks&ddBienniumSession=2021%2F2021R",
                "https://stephenmeeks.org",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=S.+Meeks",
                "https://www.arkansashouse.org/district/42"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-42-lower-ocd-person-8b64758a-b71a-4bb4-ad5d-d4ea812ebf58": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/5559a783-5b5f-4dc5-b800-4b9493b7148b",
            "firstName": "Richard Charles",
            "lastName": "Beck",
            "fullName": "Rick Beck",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/467_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-AR-43-lower-ocd-person-5559a783-5b5f-4dc5-b800-4b9493b7148b",
              "builtID": "ar-lower-43",
              "externalID": "ocd-person/5559a783-5b5f-4dc5-b800-4b9493b7148b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.beck@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "100064776606249",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Beck&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Beck&ddBienniumSession=2021%2F2021R",
                "https://www.rickbeck.org",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Beck",
                "https://www.arkansashouse.org/district/43"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-43-lower-ocd-person-5559a783-5b5f-4dc5-b800-4b9493b7148b": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/f738465d-9f00-4ea5-9e93-dbfeb4e8f47d",
            "firstName": "Stan",
            "lastName": "Berry",
            "fullName": "Stan Berry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/549_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-AR-44-lower-ocd-person-f738465d-9f00-4ea5-9e93-dbfeb4e8f47d",
              "builtID": "ar-lower-44",
              "externalID": "ocd-person/f738465d-9f00-4ea5-9e93-dbfeb4e8f47d",
              "geometry": null
            },
            "contactInfo": {
              "email": "stan.berry@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "Stan-Berry-District-68-State-Representative-430082414073546",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Berry&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Berry&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=S.+Berry",
                "https://www.arkansashouse.org/district/44"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-44-lower-ocd-person-f738465d-9f00-4ea5-9e93-dbfeb4e8f47d": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/7dff69f1-609b-4230-9937-b8bbc239f5b5",
            "firstName": "Aaron Michel",
            "lastName": "Pilkington",
            "fullName": "Aaron Pilkington",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/533_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-AR-45-lower-ocd-person-7dff69f1-609b-4230-9937-b8bbc239f5b5",
              "builtID": "ar-lower-45",
              "externalID": "ocd-person/7dff69f1-609b-4230-9937-b8bbc239f5b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "aaron.pilkington@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "pilkingtonforar",
              "youtube": "Aaron Pilkington",
              "instagram": "ampilkington",
              "facebook": "PilkingtonforAR",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Pilkington&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Pilkington&ddBienniumSession=2021%2F2021R",
                "https://www.arkansashouse.org/district/45",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Pilkington&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-45-lower-ocd-person-7dff69f1-609b-4230-9937-b8bbc239f5b5": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/3220c1b9-f133-47fa-8ef9-9944493fbb2c",
            "firstName": "Jon Scott",
            "lastName": "Eubanks",
            "fullName": "Jon Eubanks",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/357_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-AR-46-lower-ocd-person-3220c1b9-f133-47fa-8ef9-9944493fbb2c",
              "builtID": "ar-lower-46",
              "externalID": "ocd-person/3220c1b9-f133-47fa-8ef9-9944493fbb2c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jon.eubanks@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Eubanks",
                "https://www.arkansashouse.org/district/46"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-46-lower-ocd-person-3220c1b9-f133-47fa-8ef9-9944493fbb2c": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/4a794d08-4a71-424a-a17d-d35af3c979ae",
            "firstName": "Lee",
            "lastName": "Johnson",
            "fullName": "Lee Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/556_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-AR-47-lower-ocd-person-4a794d08-4a71-424a-a17d-d35af3c979ae",
              "builtID": "ar-lower-47",
              "externalID": "ocd-person/4a794d08-4a71-424a-a17d-d35af3c979ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "lee.johnson@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=L.+Johnson",
                "https://www.arkansashouse.org/district/47"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-47-lower-ocd-person-4a794d08-4a71-424a-a17d-d35af3c979ae": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/91123b36-1cbd-4696-b6d1-b9973c9d920f",
            "firstName": "Ryan",
            "lastName": "Rose",
            "fullName": "Ryan Rose",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/618_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-AR-48-lower-ocd-person-91123b36-1cbd-4696-b6d1-b9973c9d920f",
              "builtID": "ar-lower-48",
              "externalID": "ocd-person/91123b36-1cbd-4696-b6d1-b9973c9d920f",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryan.rose@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": "819 Powderhorn Circle, Van Buren, AR 72956",
              "phone1": null,
              "phone2": "479-285-6383",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkansashouse.org/district/48",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Rose&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-48-lower-ocd-person-91123b36-1cbd-4696-b6d1-b9973c9d920f": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/65961429-87cb-4c8c-b9c9-006622ea63c7",
            "firstName": "James Dennis",
            "lastName": "Richardson",
            "fullName": "Jay Richardson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/562_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-AR-49-lower-ocd-person-65961429-87cb-4c8c-b9c9-006622ea63c7",
              "builtID": "ar-lower-49",
              "externalID": "ocd-person/65961429-87cb-4c8c-b9c9-006622ea63c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "jay.richardson@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=J.+Richardson&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/49"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-49-lower-ocd-person-65961429-87cb-4c8c-b9c9-006622ea63c7": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/417afb72-31e7-4152-bf79-0ba67e3cb63f",
            "firstName": "Zachary E.",
            "lastName": "Gramlich",
            "fullName": "Zack Gramlich",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/619_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-AR-50-lower-ocd-person-417afb72-31e7-4152-bf79-0ba67e3cb63f",
              "builtID": "ar-lower-50",
              "externalID": "ocd-person/417afb72-31e7-4152-bf79-0ba67e3cb63f",
              "geometry": null
            },
            "contactInfo": {
              "email": "zack.gramlich@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Gramlich&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/50"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-50-lower-ocd-person-417afb72-31e7-4152-bf79-0ba67e3cb63f": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/c0936ba9-d863-4262-8fd1-3e08fb1013c8",
            "firstName": "Cindy",
            "lastName": "Crawford",
            "fullName": "Cindy Crawford",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/551_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-AR-51-lower-ocd-person-c0936ba9-d863-4262-8fd1-3e08fb1013c8",
              "builtID": "ar-lower-51",
              "externalID": "ocd-person/c0936ba9-d863-4262-8fd1-3e08fb1013c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "cindy.crawford@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 180628, Fort Smith, AR 72918",
              "phone1": null,
              "phone2": "479-883-1667",
              "fax1": null,
              "fax2": null,
              "twitter": "cindycrawford_",
              "youtube": null,
              "instagram": null,
              "facebook": "CrawfordForDistrict76",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Crawford&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Crawford&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Crawford",
                "https://www.arkansashouse.org/district/51"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-51-lower-ocd-person-c0936ba9-d863-4262-8fd1-3e08fb1013c8": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/41f3a186-d3b7-4b46-b3dc-2da6f89c902e",
            "firstName": "Marcus Edward",
            "lastName": "Richmond",
            "fullName": "Marcus Richmond",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/496_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-AR-52-lower-ocd-person-41f3a186-d3b7-4b46-b3dc-2da6f89c902e",
              "builtID": "ar-lower-52",
              "externalID": "ocd-person/41f3a186-d3b7-4b46-b3dc-2da6f89c902e",
              "geometry": null
            },
            "contactInfo": {
              "email": "richmond4staterep@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "Richmond4StateRep",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Richmond&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Richmond&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Richmond",
                "https://www.arkansashouse.org/district/52"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-52-lower-ocd-person-41f3a186-d3b7-4b46-b3dc-2da6f89c902e": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/487bf09e-e78f-467c-b671-3b4c881f4341",
            "firstName": "Matt",
            "lastName": "Duffield",
            "fullName": "Matt Duffield",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/620_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-AR-53-lower-ocd-person-487bf09e-e78f-467c-b671-3b4c881f4341",
              "builtID": "ar-lower-53",
              "externalID": "ocd-person/487bf09e-e78f-467c-b671-3b4c881f4341",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.duffield@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Duffield&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/53"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-53-lower-ocd-person-487bf09e-e78f-467c-b671-3b4c881f4341": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/7e778aa7-3d02-4d6c-9373-42470990fdea",
            "firstName": "Mary Elizabeth",
            "lastName": "Bentley",
            "fullName": "Mary Bentley",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/469_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-AR-54-lower-ocd-person-7e778aa7-3d02-4d6c-9373-42470990fdea",
              "builtID": "ar-lower-54",
              "externalID": "ocd-person/7e778aa7-3d02-4d6c-9373-42470990fdea",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.bentley@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "MaryBentleyAR",
              "youtube": "UCeLj0zTgt3jyDIYL3IAzLpQ",
              "instagram": null,
              "facebook": "mary.bentley.39",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Bentley&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Bentley&ddBienniumSession=2021%2F2021R",
                "https://www.marybentleyar.com",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Bentley",
                "https://www.arkansashouse.org/district/54"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-54-lower-ocd-person-7e778aa7-3d02-4d6c-9373-42470990fdea": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/d1b32d51-8ec6-4493-afd2-1bc5feb44034",
            "firstName": "Matthew",
            "lastName": "Brown",
            "fullName": "Matt Brown",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/621_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-AR-55-lower-ocd-person-d1b32d51-8ec6-4493-afd2-1bc5feb44034",
              "builtID": "ar-lower-55",
              "externalID": "ocd-person/d1b32d51-8ec6-4493-afd2-1bc5feb44034",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.brown@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=M.+Brown",
                "https://www.arkansashouse.org/district/55"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-55-lower-ocd-person-d1b32d51-8ec6-4493-afd2-1bc5feb44034": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/d7828297-0ad0-491b-88a6-93369de7acca",
            "firstName": "Stephen",
            "lastName": "Magie",
            "fullName": "Steve Magie",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/421_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-AR-56-lower-ocd-person-d7828297-0ad0-491b-88a6-93369de7acca",
              "builtID": "ar-lower-56",
              "externalID": "ocd-person/d7828297-0ad0-491b-88a6-93369de7acca",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephen.magie@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "stevemagie",
              "youtube": null,
              "instagram": null,
              "facebook": "stevemagie",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Magie&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Magie&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Magie",
                "https://www.arkansashouse.org/district/56"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-56-lower-ocd-person-d7828297-0ad0-491b-88a6-93369de7acca": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/8b544682-2d9b-4759-bdc1-e560cced87fa",
            "firstName": "Cameron",
            "lastName": "Cooper",
            "fullName": "Cameron Cooper",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/561_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-AR-57-lower-ocd-person-8b544682-2d9b-4759-bdc1-e560cced87fa",
              "builtID": "ar-lower-57",
              "externalID": "ocd-person/8b544682-2d9b-4759-bdc1-e560cced87fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "cameron.cooper@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "cameron.cooper.5477",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=C.+Cooper&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=C.+Cooper&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=C.+Cooper",
                "https://www.arkansashouse.org/district/57"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-57-lower-ocd-person-8b544682-2d9b-4759-bdc1-e560cced87fa": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/7d85c10d-346f-4c4f-a4e5-470799f7b21c",
            "firstName": "Leslie Dale",
            "lastName": "Eaves",
            "fullName": "Les Eaves",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/476_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-AR-58-lower-ocd-person-7d85c10d-346f-4c4f-a4e5-470799f7b21c",
              "builtID": "ar-lower-58",
              "externalID": "ocd-person/7d85c10d-346f-4c4f-a4e5-470799f7b21c",
              "geometry": null
            },
            "contactInfo": {
              "email": "les.eaves@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Eaves",
                "https://www.arkansashouse.org/district/58"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-58-lower-ocd-person-7d85c10d-346f-4c4f-a4e5-470799f7b21c": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/3016494b-fa9f-4f79-8530-7bd26120a31e",
            "firstName": "James Earl",
            "lastName": "Wooten",
            "fullName": "Jim Wooten",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/547_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-AR-59-lower-ocd-person-3016494b-fa9f-4f79-8530-7bd26120a31e",
              "builtID": "ar-lower-59",
              "externalID": "ocd-person/3016494b-fa9f-4f79-8530-7bd26120a31e",
              "geometry": null
            },
            "contactInfo": {
              "email": "wjewooten@aol.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Wooten&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Wooten&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Wooten",
                "https://www.arkansashouse.org/district/59"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-59-lower-ocd-person-3016494b-fa9f-4f79-8530-7bd26120a31e": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/de934263-fe58-4281-bd70-1291fcbd0e25",
            "firstName": "Roger D.",
            "lastName": "Lynch",
            "fullName": "Roger Lynch",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/528_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-AR-60-lower-ocd-person-de934263-fe58-4281-bd70-1291fcbd0e25",
              "builtID": "ar-lower-60",
              "externalID": "ocd-person/de934263-fe58-4281-bd70-1291fcbd0e25",
              "geometry": null
            },
            "contactInfo": {
              "email": "roger.lynch@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "rogerdlynch",
              "youtube": null,
              "instagram": null,
              "facebook": "rogerDLynch",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Lynch&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Lynch&ddBienniumSession=2021%2F2021R",
                "https://www.rogerdlynch.com",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Lynch",
                "https://www.arkansashouse.org/district/60"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-60-lower-ocd-person-de934263-fe58-4281-bd70-1291fcbd0e25": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/982babbb-33e1-48ad-a199-10a86e6db5e6",
            "firstName": "Jeremiah Lee",
            "lastName": "Moore",
            "fullName": "Jeremiah Moore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/622_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-AR-61-lower-ocd-person-982babbb-33e1-48ad-a199-10a86e6db5e6",
              "builtID": "ar-lower-61",
              "externalID": "ocd-person/982babbb-33e1-48ad-a199-10a86e6db5e6",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeremiah.moore@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkansashouse.org/district/61",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=J.+Moore&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-61-lower-ocd-person-982babbb-33e1-48ad-a199-10a86e6db5e6": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/fc30e79a-ee65-4e5b-9cb8-f27ef06c951d",
            "firstName": "Mark D.",
            "lastName": "McElroy",
            "fullName": "Mark McElroy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/585_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-AR-62-lower-ocd-person-fc30e79a-ee65-4e5b-9cb8-f27ef06c951d",
              "builtID": "ar-lower-62",
              "externalID": "ocd-person/fc30e79a-ee65-4e5b-9cb8-f27ef06c951d",
              "geometry": null
            },
            "contactInfo": {
              "email": "mdmcelroy1@yahoo.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "MarkMcElroyARStateRep",
              "urls": [
                "https://www.arkleg.state.ar.us/assembly/2019/2019R/Pages/MemberProfile.aspx?member=McElroy",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=M.+McElroy&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=M.+McElroy",
                "https://www.arkansashouse.org/district/62"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-62-lower-ocd-person-fc30e79a-ee65-4e5b-9cb8-f27ef06c951d": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/e91e8723-8f91-4702-8680-f40d181b52f6",
            "firstName": "Deborah",
            "lastName": "Ferguson",
            "fullName": "Deborah Ferguson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/437_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-AR-63-lower-ocd-person-e91e8723-8f91-4702-8680-f40d181b52f6",
              "builtID": "ar-lower-63",
              "externalID": "ocd-person/e91e8723-8f91-4702-8680-f40d181b52f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "deborah.ferguson@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Ferguson4Ark",
              "youtube": null,
              "instagram": null,
              "facebook": "DeborahFergusonforArkansas",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=D.+Ferguson&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=D.+Ferguson&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=D.+Ferguson",
                "https://www.arkansashouse.org/district/63"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-63-lower-ocd-person-e91e8723-8f91-4702-8680-f40d181b52f6": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/58b100fe-13ff-4b17-a210-88c13cbd3217",
            "firstName": "Kenneth Bernard",
            "lastName": "Ferguson",
            "fullName": "Ken Ferguson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/477_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-AR-64-lower-ocd-person-58b100fe-13ff-4b17-a210-88c13cbd3217",
              "builtID": "ar-lower-64",
              "externalID": "ocd-person/58b100fe-13ff-4b17-a210-88c13cbd3217",
              "geometry": null
            },
            "contactInfo": {
              "email": "kenneth.ferguson@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=K.+Ferguson",
                "https://www.arkansashouse.org/district/64"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-64-lower-ocd-person-58b100fe-13ff-4b17-a210-88c13cbd3217": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/b4d16d03-05cb-41a4-a90c-1bbf63228f21",
            "firstName": "Vivian Laveda",
            "lastName": "Flowers",
            "fullName": "Vivian Flowers",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/479_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-AR-65-lower-ocd-person-b4d16d03-05cb-41a4-a90c-1bbf63228f21",
              "builtID": "ar-lower-65",
              "externalID": "ocd-person/b4d16d03-05cb-41a4-a90c-1bbf63228f21",
              "geometry": null
            },
            "contactInfo": {
              "email": "vivian.flowers@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repvivflowers",
              "youtube": null,
              "instagram": "vivianlavedaflowers",
              "facebook": "VoteVivianFlowers",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=V.+Flowers&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=V.+Flowers&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=V.+Flowers",
                "https://www.arkansashouse.org/district/65"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-65-lower-ocd-person-b4d16d03-05cb-41a4-a90c-1bbf63228f21": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/4b44cfe9-fb9b-4c18-ba6e-34cf679ac3ef",
            "firstName": "Mark Wilson",
            "lastName": "Perry",
            "fullName": "Mark Perry",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/548_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-AR-66-lower-ocd-person-4b44cfe9-fb9b-4c18-ba6e-34cf679ac3ef",
              "builtID": "ar-lower-66",
              "externalID": "ocd-person/4b44cfe9-fb9b-4c18-ba6e-34cf679ac3ef",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.perry@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "MakingOurMarkTogether",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Perry&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Perry&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Perry",
                "https://www.arkansashouse.org/district/66"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-66-lower-ocd-person-4b44cfe9-fb9b-4c18-ba6e-34cf679ac3ef": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/dfbad34c-8e78-4a93-9029-9e50cb0ccb51",
            "firstName": "Karilyn Mae Boggan Peterson",
            "lastName": "Brown",
            "fullName": "Karilyn Brown",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/466_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-AR-67-lower-ocd-person-dfbad34c-8e78-4a93-9029-9e50cb0ccb51",
              "builtID": "ar-lower-67",
              "externalID": "ocd-person/dfbad34c-8e78-4a93-9029-9e50cb0ccb51",
              "geometry": null
            },
            "contactInfo": {
              "email": "karilyn.brown@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "karilynbrown1",
              "youtube": null,
              "instagram": null,
              "facebook": "KarilynBB",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Brown&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Brown&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=K.+Brown&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/67"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-67-lower-ocd-person-dfbad34c-8e78-4a93-9029-9e50cb0ccb51": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/a1614a7c-f5f0-4fe1-a2b1-c8390e4bf106",
            "firstName": "Brian S.",
            "lastName": "Evans",
            "fullName": "Brian Evans",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/568_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-AR-68-lower-ocd-person-a1614a7c-f5f0-4fe1-a2b1-c8390e4bf106",
              "builtID": "ar-lower-68",
              "externalID": "ocd-person/a1614a7c-f5f0-4fe1-a2b1-c8390e4bf106",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.evans@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Evans",
                "https://www.arkansashouse.org/district/68"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-68-lower-ocd-person-a1614a7c-f5f0-4fe1-a2b1-c8390e4bf106": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/4b6071ae-58c1-4400-a49f-416579c6371e",
            "firstName": "David",
            "lastName": "Ray",
            "fullName": "David Ray",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/589_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-AR-69-lower-ocd-person-4b6071ae-58c1-4400-a49f-416579c6371e",
              "builtID": "ar-lower-69",
              "externalID": "ocd-person/4b6071ae-58c1-4400-a49f-416579c6371e",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.ray@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "DavidARay",
              "youtube": null,
              "instagram": null,
              "facebook": "RayforArkansas",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Ray&ddBienniumSession=2021%2F2021R",
                "https://rayforarkansas.com/",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Ray",
                "https://www.arkansashouse.org/district/69"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-69-lower-ocd-person-4b6071ae-58c1-4400-a49f-416579c6371e": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/22fd401f-6227-4a26-ab44-1dbce5ce728a",
            "firstName": "Carlton",
            "lastName": "Wing",
            "fullName": "Carlton Wing",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/538_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-AR-70-lower-ocd-person-22fd401f-6227-4a26-ab44-1dbce5ce728a",
              "builtID": "ar-lower-70",
              "externalID": "ocd-person/22fd401f-6227-4a26-ab44-1dbce5ce728a",
              "geometry": null
            },
            "contactInfo": {
              "email": "carlton@carltonwing.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "carltonwing",
              "youtube": "Carlton Wing",
              "instagram": "carltonwingstaterep",
              "facebook": "carltonwingarkansas",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Wing&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Wing&ddBienniumSession=2021%2F2021R",
                "https://carltonwing.com/",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Wing",
                "https://www.arkansashouse.org/district/70"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-70-lower-ocd-person-22fd401f-6227-4a26-ab44-1dbce5ce728a": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/9bce7e7f-cd90-4b10-a557-c37d82923be2",
            "firstName": "Brandon C.",
            "lastName": "Achor",
            "fullName": "Brandon Achor",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/623_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-AR-71-lower-ocd-person-9bce7e7f-cd90-4b10-a557-c37d82923be2",
              "builtID": "ar-lower-71",
              "externalID": "ocd-person/9bce7e7f-cd90-4b10-a557-c37d82923be2",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandon.achor@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkansashouse.org/district/71",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Achor&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-71-lower-ocd-person-9bce7e7f-cd90-4b10-a557-c37d82923be2": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/71affd8a-77ef-45b1-96ab-c5724f34dfa5",
            "firstName": "Jamie Aleshia",
            "lastName": "Scott",
            "fullName": "Jamie Scott",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/552_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-AR-72-lower-ocd-person-71affd8a-77ef-45b1-96ab-c5724f34dfa5",
              "builtID": "ar-lower-72",
              "externalID": "ocd-person/71affd8a-77ef-45b1-96ab-c5724f34dfa5",
              "geometry": null
            },
            "contactInfo": {
              "email": "jamie.scott@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Anointed08",
              "youtube": null,
              "instagram": "jamiescott4arkansas",
              "facebook": "Jamie Scott",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Scott&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Scott&ddBienniumSession=2021%2F2021R",
                "https://jamiescottforarkansas.com/",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Scott",
                "https://www.arkansashouse.org/district/72"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-72-lower-ocd-person-71affd8a-77ef-45b1-96ab-c5724f34dfa5": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/5143965d-c539-411e-8a15-74ebbcbb7cd7",
            "firstName": "Andrew Joseph",
            "lastName": "Collins",
            "fullName": "Andrew Collins",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/566_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-AR-73-lower-ocd-person-5143965d-c539-411e-8a15-74ebbcbb7cd7",
              "builtID": "ar-lower-73",
              "externalID": "ocd-person/5143965d-c539-411e-8a15-74ebbcbb7cd7",
              "geometry": null
            },
            "contactInfo": {
              "email": "andrew.collins@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "andrewcollinsAR",
              "youtube": null,
              "instagram": "andrewcollinsAR",
              "facebook": "andrewcollinsAR",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=A.+Collins&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=A.+Collins&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=A.+Collins",
                "https://www.arkansashouse.org/district/73"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-73-lower-ocd-person-5143965d-c539-411e-8a15-74ebbcbb7cd7": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/03241c54-792d-42c6-ba4c-7b81d02b24a9",
            "firstName": "Tippi Lynn",
            "lastName": "McCullough",
            "fullName": "Tippi McCullough",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/570_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-AR-74-lower-ocd-person-03241c54-792d-42c6-ba4c-7b81d02b24a9",
              "builtID": "ar-lower-74",
              "externalID": "ocd-person/03241c54-792d-42c6-ba4c-7b81d02b24a9",
              "geometry": null
            },
            "contactInfo": {
              "email": "tippi.mccullough@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "tippi31",
              "youtube": null,
              "instagram": null,
              "facebook": "TippiStateRep33",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=McCullough&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=McCullough&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=McCullough",
                "https://www.arkansashouse.org/district/74"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-74-lower-ocd-person-03241c54-792d-42c6-ba4c-7b81d02b24a9": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/0e0e799b-70de-4472-b289-4a0c2aba3a26",
            "firstName": "Ashley Welch",
            "lastName": "Hudson",
            "fullName": "Ashley Hudson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/566_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-AR-75-lower-ocd-person-0e0e799b-70de-4472-b289-4a0c2aba3a26",
              "builtID": "ar-lower-75",
              "externalID": "ocd-person/0e0e799b-70de-4472-b289-4a0c2aba3a26",
              "geometry": null
            },
            "contactInfo": {
              "email": "ashley.hudson@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Hudson",
                "https://www.arkansashouse.org/district/75"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-75-lower-ocd-person-0e0e799b-70de-4472-b289-4a0c2aba3a26": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/e5cf38cc-b933-4bbb-928a-8089d83a6d56",
            "firstName": "Joy Charles",
            "lastName": "Springer",
            "fullName": "Joy Springer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/579_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-AR-76-lower-ocd-person-e5cf38cc-b933-4bbb-928a-8089d83a6d56",
              "builtID": "ar-lower-76",
              "externalID": "ocd-person/e5cf38cc-b933-4bbb-928a-8089d83a6d56",
              "geometry": null
            },
            "contactInfo": {
              "email": "joy.springer@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "electjoycspringer",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Springer&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Springer&ddBienniumSession=2021%2F2021R",
                "https://joyspringer.com/",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Springer",
                "https://www.arkansashouse.org/district/76"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-76-lower-ocd-person-e5cf38cc-b933-4bbb-928a-8089d83a6d56": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/b325559c-79b9-4ce3-a548-4c0c4116815e",
            "firstName": "Fred",
            "lastName": "Allen",
            "fullName": "Fred Allen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/519_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-AR-77-lower-ocd-person-b325559c-79b9-4ce3-a548-4c0c4116815e",
              "builtID": "ar-lower-77",
              "externalID": "ocd-person/b325559c-79b9-4ce3-a548-4c0c4116815e",
              "geometry": null
            },
            "contactInfo": {
              "email": "fredallen99@comcast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "fredallendist30",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=F.+Allen&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=F.+Allen&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=F.+Allen",
                "https://www.arkansashouse.org/district/77"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-77-lower-ocd-person-b325559c-79b9-4ce3-a548-4c0c4116815e": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/d4c881c3-3d11-4cc4-a40d-64d3d0e11c60",
            "firstName": "Keith",
            "lastName": "Brooks",
            "fullName": "Keith Brooks",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/588_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-AR-78-lower-ocd-person-d4c881c3-3d11-4cc4-a40d-64d3d0e11c60",
              "builtID": "ar-lower-78",
              "externalID": "ocd-person/d4c881c3-3d11-4cc4-a40d-64d3d0e11c60",
              "geometry": null
            },
            "contactInfo": {
              "email": "keith.brooks@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Brooks",
                "https://www.arkansashouse.org/district/78"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-78-lower-ocd-person-d4c881c3-3d11-4cc4-a40d-64d3d0e11c60": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/18f06e22-dd2c-4247-81c8-a40938de3ea1",
            "firstName": "Tara",
            "lastName": "Shephard",
            "fullName": "Tara Shephard",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/624_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-AR-79-lower-ocd-person-18f06e22-dd2c-4247-81c8-a40938de3ea1",
              "builtID": "ar-lower-79",
              "externalID": "ocd-person/18f06e22-dd2c-4247-81c8-a40938de3ea1",
              "geometry": null
            },
            "contactInfo": {
              "email": "tara.shephard@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 56468, Little Rock, AR 72215",
              "phone1": null,
              "phone2": "501-682-9800",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkansashouse.org/district/79",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=T.+Shephard&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-79-lower-ocd-person-18f06e22-dd2c-4247-81c8-a40938de3ea1": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/44cca040-d890-4cf3-9a00-bc8f6f3ac3fc",
            "firstName": "Denise Jones",
            "lastName": "Ennett",
            "fullName": "Denise Ennett",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/577_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-AR-80-lower-ocd-person-44cca040-d890-4cf3-9a00-bc8f6f3ac3fc",
              "builtID": "ar-lower-80",
              "externalID": "ocd-person/44cca040-d890-4cf3-9a00-bc8f6f3ac3fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "denise.ennett@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "denisefor36",
              "youtube": null,
              "instagram": "denisefor36",
              "facebook": "Denisefor36",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Ennett&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Ennett&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Ennett",
                "https://www.arkansashouse.org/district/80"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-80-lower-ocd-person-44cca040-d890-4cf3-9a00-bc8f6f3ac3fc": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/1bbfdd71-5072-4978-89bb-6aa19558c3f7",
            "firstName": "R.J.",
            "lastName": "Hawk",
            "fullName": "R.J. Hawk",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/625_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-AR-81-lower-ocd-person-1bbfdd71-5072-4978-89bb-6aa19558c3f7",
              "builtID": "ar-lower-81",
              "externalID": "ocd-person/1bbfdd71-5072-4978-89bb-6aa19558c3f7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rj.hawk@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Hawk&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/81"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-81-lower-ocd-person-1bbfdd71-5072-4978-89bb-6aa19558c3f7": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/55779b79-fd86-4991-882f-05819d80a87d",
            "firstName": "Anthony",
            "lastName": "Furman",
            "fullName": "Tony Furman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/587_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-AR-82-lower-ocd-person-55779b79-fd86-4991-882f-05819d80a87d",
              "builtID": "ar-lower-82",
              "externalID": "ocd-person/55779b79-fd86-4991-882f-05819d80a87d",
              "geometry": null
            },
            "contactInfo": {
              "email": "tony.furman@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Furman",
                "https://www.arkansashouse.org/district/82"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-82-lower-ocd-person-55779b79-fd86-4991-882f-05819d80a87d": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/5f80e184-fd48-4998-8d0b-ef9f4a5453c1",
            "firstName": "Lanny E.",
            "lastName": "Fite",
            "fullName": "Lanny Fite",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/478_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-AR-83-lower-ocd-person-5f80e184-fd48-4998-8d0b-ef9f4a5453c1",
              "builtID": "ar-lower-83",
              "externalID": "ocd-person/5f80e184-fd48-4998-8d0b-ef9f4a5453c1",
              "geometry": null
            },
            "contactInfo": {
              "email": "lanny.fite@att.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "lanny.fite",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=L.+Fite&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=L.+Fite&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=L.+Fite",
                "https://www.arkansashouse.org/district/83"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-83-lower-ocd-person-5f80e184-fd48-4998-8d0b-ef9f4a5453c1": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/5e063acb-6720-4bb0-8f6e-1df169c856e7",
            "firstName": "Leslie Allen",
            "lastName": "Warren",
            "fullName": "Les Warren",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/535_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-AR-84-lower-ocd-person-5e063acb-6720-4bb0-8f6e-1df169c856e7",
              "builtID": "ar-lower-84",
              "externalID": "ocd-person/5e063acb-6720-4bb0-8f6e-1df169c856e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "les.warren@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "les.warren.902",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Warren&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Warren&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Warren",
                "https://www.arkansashouse.org/district/84"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-84-lower-ocd-person-5e063acb-6720-4bb0-8f6e-1df169c856e7": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/7f9f1a8b-47a7-456d-bc5d-1e6ec32e8572",
            "firstName": "Richard",
            "lastName": "McGrew",
            "fullName": "Richard McGrew",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/578_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-AR-85-lower-ocd-person-7f9f1a8b-47a7-456d-bc5d-1e6ec32e8572",
              "builtID": "ar-lower-85",
              "externalID": "ocd-person/7f9f1a8b-47a7-456d-bc5d-1e6ec32e8572",
              "geometry": null
            },
            "contactInfo": {
              "email": "mcgrewrep22@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "richard.mcgrew.94",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=McGrew&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=McGrew&ddBienniumSession=2021%2F2021R",
                "https://www.richardmcgrewfordistrict22.com",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=McGrew",
                "https://www.arkansashouse.org/district/85"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-85-lower-ocd-person-7f9f1a8b-47a7-456d-bc5d-1e6ec32e8572": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/240d9f6e-15f1-41eb-acbb-733861936e5b",
            "firstName": "John D.",
            "lastName": "Maddox",
            "fullName": "John Maddox",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/529_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-AR-86-lower-ocd-person-240d9f6e-15f1-41eb-acbb-733861936e5b",
              "builtID": "ar-lower-86",
              "externalID": "ocd-person/240d9f6e-15f1-41eb-acbb-733861936e5b",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.maddox@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "johnmaddoxjohn",
              "youtube": null,
              "instagram": null,
              "facebook": "john.maddox.9275",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Maddox&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Maddox&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Maddox",
                "https://www.arkansashouse.org/district/86"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-86-lower-ocd-person-240d9f6e-15f1-41eb-acbb-733861936e5b": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/b165a0c6-fe5a-4505-9f6f-aaafce582b6a",
            "firstName": "DeAnn Kay",
            "lastName": "Vaught",
            "fullName": "DeAnn Vaught",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/505_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-AR-87-lower-ocd-person-b165a0c6-fe5a-4505-9f6f-aaafce582b6a",
              "builtID": "ar-lower-87",
              "externalID": "ocd-person/b165a0c6-fe5a-4505-9f6f-aaafce582b6a",
              "geometry": null
            },
            "contactInfo": {
              "email": "deann.vaught@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "DeAnnVaughtHD4",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Vaught&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Vaught&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Vaught",
                "https://www.arkansashouse.org/district/87"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-87-lower-ocd-person-b165a0c6-fe5a-4505-9f6f-aaafce582b6a": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/6dbac93f-c202-42a6-bd91-87baf5c1bb86",
            "firstName": "Danny",
            "lastName": "Watson",
            "fullName": "Danny Watson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/536_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-AR-88-lower-ocd-person-6dbac93f-c202-42a6-bd91-87baf5c1bb86",
              "builtID": "ar-lower-88",
              "externalID": "ocd-person/6dbac93f-c202-42a6-bd91-87baf5c1bb86",
              "geometry": null
            },
            "contactInfo": {
              "email": "danny.watson@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Watson",
                "https://www.arkansashouse.org/district/88"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-88-lower-ocd-person-6dbac93f-c202-42a6-bd91-87baf5c1bb86": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/96dbbbb4-8a04-42bc-a553-e178d47ce838",
            "firstName": "Justin Rory",
            "lastName": "Gonzales",
            "fullName": "Justin Gonzales",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/481_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-AR-89-lower-ocd-person-96dbbbb4-8a04-42bc-a553-e178d47ce838",
              "builtID": "ar-lower-89",
              "externalID": "ocd-person/96dbbbb4-8a04-42bc-a553-e178d47ce838",
              "geometry": null
            },
            "contactInfo": {
              "email": "justinrory@yahoo.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Gonzales",
                "https://www.arkansashouse.org/district/89"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-89-lower-ocd-person-96dbbbb4-8a04-42bc-a553-e178d47ce838": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/f2b10588-1d21-4f8f-b313-d668bf58d967",
            "firstName": "William Richard",
            "lastName": "Womack",
            "fullName": "Richard Womack",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/436_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-AR-90-lower-ocd-person-f2b10588-1d21-4f8f-b313-d668bf58d967",
              "builtID": "ar-lower-90",
              "externalID": "ocd-person/f2b10588-1d21-4f8f-b313-d668bf58d967",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard@richardwomack.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "richard.womack.731",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Womack&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Womack&ddBienniumSession=2021%2F2021R",
                "https://www.richardwomack.com",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Womack",
                "https://www.arkansashouse.org/district/90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-90-lower-ocd-person-f2b10588-1d21-4f8f-b313-d668bf58d967": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/49608f14-5f53-40fc-b662-0d48a2aa1340",
            "firstName": "Bruce Alan",
            "lastName": "Cozart",
            "fullName": "Bruce Cozart",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/392_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-AR-91-lower-ocd-person-49608f14-5f53-40fc-b662-0d48a2aa1340",
              "builtID": "ar-lower-91",
              "externalID": "ocd-person/49608f14-5f53-40fc-b662-0d48a2aa1340",
              "geometry": null
            },
            "contactInfo": {
              "email": "bccci@cablelynx.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repbrucecozart",
              "youtube": null,
              "instagram": null,
              "facebook": "bruce.cozart",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Cozart&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Cozart&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Cozart",
                "https://www.arkansashouse.org/district/91"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-91-lower-ocd-person-49608f14-5f53-40fc-b662-0d48a2aa1340": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/1621cc3a-d18c-486f-96fb-57c6a2890da1",
            "firstName": "Julie Ann",
            "lastName": "Mayberry",
            "fullName": "Julie Mayberry",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/491_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-AR-92-lower-ocd-person-1621cc3a-d18c-486f-96fb-57c6a2890da1",
              "builtID": "ar-lower-92",
              "externalID": "ocd-person/1621cc3a-d18c-486f-96fb-57c6a2890da1",
              "geometry": null
            },
            "contactInfo": {
              "email": "julie.mayberry@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "repjuliemayberry",
              "facebook": "juliemayberrypolitical",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=J.+Mayberry&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=J.+Mayberry&ddBienniumSession=2021%2F2021R",
                "https://juliemayberry.com",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=J.+Mayberry",
                "https://www.arkansashouse.org/district/92"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-92-lower-ocd-person-1621cc3a-d18c-486f-96fb-57c6a2890da1": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/1bf7a3a7-5871-4777-af71-dbdfd358e41b",
            "firstName": "Mike",
            "lastName": "Holcomb",
            "fullName": "Mike Holcomb",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/434_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-AR-93-lower-ocd-person-1bf7a3a7-5871-4777-af71-dbdfd358e41b",
              "builtID": "ar-lower-93",
              "externalID": "ocd-person/1bf7a3a7-5871-4777-af71-dbdfd358e41b",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.holcomb@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Holcomb&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Holcomb&ddBienniumSession=2021%2F2021R",
                "https://www.arkansashouse.org/district/93",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Holcomb"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-93-lower-ocd-person-1bf7a3a7-5871-4777-af71-dbdfd358e41b": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/80a1486d-1661-4409-adf4-63aacf4df148",
            "firstName": "Jeffrey Reed",
            "lastName": "Wardlaw",
            "fullName": "Jeff Wardlaw",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/387_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-AR-94-lower-ocd-person-80a1486d-1661-4409-adf4-63aacf4df148",
              "builtID": "ar-lower-94",
              "externalID": "ocd-person/80a1486d-1661-4409-adf4-63aacf4df148",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff@jeffwardlaw.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "jeff_wardlaw",
              "youtube": null,
              "instagram": "wardlaw2",
              "facebook": "StateRepJeffWardlaw",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Wardlaw&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Wardlaw&ddBienniumSession=2021%2F2021R",
                "https://www.wardlaw4arkansas.com/",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Wardlaw",
                "https://www.arkansashouse.org/district/8",
                "https://www.arkansashouse.org/district/94"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-94-lower-ocd-person-80a1486d-1661-4409-adf4-63aacf4df148": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/95d21faa-0431-4285-8cee-36fc918959b6",
            "firstName": "Howard M.",
            "lastName": "Beaty",
            "fullName": "Howard Beaty",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/584_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-AR-95-lower-ocd-person-95d21faa-0431-4285-8cee-36fc918959b6",
              "builtID": "ar-lower-95",
              "externalID": "ocd-person/95d21faa-0431-4285-8cee-36fc918959b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "howard.beaty@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "HBEATYJR",
              "youtube": null,
              "instagram": null,
              "facebook": "beatyforrep",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Beaty+Jr.&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Beaty+Jr.",
                "https://www.arkansashouse.org/district/95"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-95-lower-ocd-person-95d21faa-0431-4285-8cee-36fc918959b6": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/bd3c22f6-b1b5-4089-b03b-f8198ca843a2",
            "firstName": "Sonia Eubanks",
            "lastName": "Barker",
            "fullName": "Sonia Barker",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/520_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-AR-96-lower-ocd-person-bd3c22f6-b1b5-4089-b03b-f8198ca843a2",
              "builtID": "ar-lower-96",
              "externalID": "ocd-person/bd3c22f6-b1b5-4089-b03b-f8198ca843a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "sonia.barker@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Barker",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Barker&ddBienniumSession=2023%2F2023R",
                "https://www.arkansashouse.org/district/96"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-96-lower-ocd-person-bd3c22f6-b1b5-4089-b03b-f8198ca843a2": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/2774b34a-6b89-4ee9-b8af-32b7a7d8e174",
            "firstName": "Matthew Joseph",
            "lastName": "Shepherd",
            "fullName": "Matthew Shepherd",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/380_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-AR-97-lower-ocd-person-2774b34a-6b89-4ee9-b8af-32b7a7d8e174",
              "builtID": "ar-lower-97",
              "externalID": "ocd-person/2774b34a-6b89-4ee9-b8af-32b7a7d8e174",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.shepherd@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Matt_J_Shepherd",
              "youtube": null,
              "instagram": null,
              "facebook": "Matthew-Shepherd-130774645804",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Shepherd&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Shepherd&ddBienniumSession=2021%2F2021R",
                "https://matthewshepherd.org",
                "https://www.arkansashouse.org/district/97",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=M.+Shepherd&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-97-lower-ocd-person-2774b34a-6b89-4ee9-b8af-32b7a7d8e174": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/9762816a-27bf-4209-864b-d15e2e39212a",
            "firstName": "Wade",
            "lastName": "Andrews",
            "fullName": "Wade Andrews",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/626_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-AR-98-lower-ocd-person-9762816a-27bf-4209-864b-d15e2e39212a",
              "builtID": "ar-lower-98",
              "externalID": "ocd-person/9762816a-27bf-4209-864b-d15e2e39212a",
              "geometry": null
            },
            "contactInfo": {
              "email": "wade.andrews@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkansashouse.org/district/98",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Andrews&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-98-lower-ocd-person-9762816a-27bf-4209-864b-d15e2e39212a": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/e04b2e49-1a8c-46e5-b9fc-c23d35e10393",
            "firstName": "Samuel Lane",
            "lastName": "Jean",
            "fullName": "Lane Jean",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/364_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-AR-99-lower-ocd-person-e04b2e49-1a8c-46e5-b9fc-c23d35e10393",
              "builtID": "ar-lower-99",
              "externalID": "ocd-person/e04b2e49-1a8c-46e5-b9fc-c23d35e10393",
              "geometry": null
            },
            "contactInfo": {
              "email": "l_jean@sbcglobal.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Jean",
                "https://www.arkansashouse.org/district/99"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-99-lower-ocd-person-e04b2e49-1a8c-46e5-b9fc-c23d35e10393": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/247b430e-d46a-474a-87bc-24d03c636fd8",
            "firstName": "Carol",
            "lastName": "Dalby",
            "fullName": "Carol Dalby",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/House/525_sm.jpg",
            "title": "AR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-AR-100-lower-ocd-person-247b430e-d46a-474a-87bc-24d03c636fd8",
              "builtID": "ar-lower-100",
              "externalID": "ocd-person/247b430e-d46a-474a-87bc-24d03c636fd8",
              "geometry": null
            },
            "contactInfo": {
              "email": "carol.dalby@arkansashouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Dalby",
                "https://www.arkansashouse.org/district/100"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-AR-100-lower-ocd-person-247b430e-d46a-474a-87bc-24d03c636fd8": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/821d4077-0022-48a9-86b1-eb4d3bdef2e7",
            "firstName": "Benjamin L.",
            "lastName": "Gilmore",
            "fullName": "Ben Gilmore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/583_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-AR-1-upper-ocd-person-821d4077-0022-48a9-86b1-eb4d3bdef2e7",
              "builtID": "ar-upper-1",
              "externalID": "ocd-person/821d4077-0022-48a9-86b1-eb4d3bdef2e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.gilmore@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "lbengilmore",
              "youtube": null,
              "instagram": "lbengilmore",
              "facebook": "Ben4StateSenate",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Gilmore&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+Gilmore+-+Sentor+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2021%2FMember%20Profiles%2FSenator+Gilmore+-+Sentor+Bio.pdf",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Gilmore+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Gilmore+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Gilmore",
                "https://senate.arkansas.gov/senators/ben-gilmore/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-1-upper-ocd-person-821d4077-0022-48a9-86b1-eb4d3bdef2e7": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/2b67c2a6-d80e-48f4-9bc7-a40d7e5bc2bc",
            "firstName": "Matt",
            "lastName": "Stone",
            "fullName": "Matt Stone",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/632_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-AR-2-upper-ocd-person-2b67c2a6-d80e-48f4-9bc7-a40d7e5bc2bc",
              "builtID": "ar-upper-2",
              "externalID": "ocd-person/2b67c2a6-d80e-48f4-9bc7-a40d7e5bc2bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.stone@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Stone+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Stone+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Stone&ddBienniumSession=2023%2F2023R",
                "https://senate.arkansas.gov/senators/matt-stone/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-2-upper-ocd-person-2b67c2a6-d80e-48f4-9bc7-a40d7e5bc2bc": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/33b10c1d-6f19-44fe-83e7-827fdb1c4e78",
            "firstName": "Steve",
            "lastName": "Crowell",
            "fullName": "Steve Crowell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/627_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-AR-3-upper-ocd-person-33b10c1d-6f19-44fe-83e7-827fdb1c4e78",
              "builtID": "ar-upper-3",
              "externalID": "ocd-person/33b10c1d-6f19-44fe-83e7-827fdb1c4e78",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.crowell@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Crowell+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Crowell+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Crowell&ddBienniumSession=2023%2F2023R",
                "https://senate.arkansas.gov/senators/steve-crowell/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-3-upper-ocd-person-33b10c1d-6f19-44fe-83e7-827fdb1c4e78": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/c083823a-b0f6-43dd-87b9-f1f398b84a00",
            "firstName": "Milton Jimmy",
            "lastName": "Hickey",
            "fullName": "Jimmy Hickey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/409_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-AR-4-upper-ocd-person-c083823a-b0f6-43dd-87b9-f1f398b84a00",
              "builtID": "ar-upper-4",
              "externalID": "ocd-person/c083823a-b0f6-43dd-87b9-f1f398b84a00",
              "geometry": null
            },
            "contactInfo": {
              "email": "jimmy.hickey@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Hickey+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Hickey+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Hickey",
                "https://senate.arkansas.gov/senators/jimmy-hickey-jr/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-4-upper-ocd-person-c083823a-b0f6-43dd-87b9-f1f398b84a00": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/65d8b209-ce03-403c-8437-7c4d7a2e7185",
            "firstName": "Terry Wilfred",
            "lastName": "Rice",
            "fullName": "Terry Rice",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/510_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-AR-5-upper-ocd-person-65d8b209-ce03-403c-8437-7c4d7a2e7185",
              "builtID": "ar-upper-5",
              "externalID": "ocd-person/65d8b209-ce03-403c-8437-7c4d7a2e7185",
              "geometry": null
            },
            "contactInfo": {
              "email": "terry.rice@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "TerryRice6",
              "youtube": null,
              "instagram": null,
              "facebook": "terry.rice.7906",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Rice&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Rice&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+Rice+-+Senate+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2021%2FMember%20Profiles%2FSenator+Rice+-+Senate+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Rice+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Rice",
                "https://senate.arkansas.gov/senators/terry-rice/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-5-upper-ocd-person-65d8b209-ce03-403c-8437-7c4d7a2e7185": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/dfe71972-1012-4330-af2a-98482d9aa3bc",
            "firstName": "Matt",
            "lastName": "McKee",
            "fullName": "Matt McKee",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/630_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-AR-6-upper-ocd-person-dfe71972-1012-4330-af2a-98482d9aa3bc",
              "builtID": "ar-upper-6",
              "externalID": "ocd-person/dfe71972-1012-4330-af2a-98482d9aa3bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.mckee@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+McKee+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+McKee+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=M.+McKee",
                "https://senate.arkansas.gov/senators/matt-mckee/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-6-upper-ocd-person-dfe71972-1012-4330-af2a-98482d9aa3bc": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/6448d3df-2802-44a1-a142-7c3cc136d0a5",
            "firstName": "Byron Alan",
            "lastName": "Clark",
            "fullName": "Alan Clark",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/401_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-AR-7-upper-ocd-person-6448d3df-2802-44a1-a142-7c3cc136d0a5",
              "builtID": "ar-upper-7",
              "externalID": "ocd-person/6448d3df-2802-44a1-a142-7c3cc136d0a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "alan.clark@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "AlanClark4AR",
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorAlanClark",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=A.+Clark&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=A.+Clark&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+Clark+-+Senate+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Clark+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Clark+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=A.+Clark",
                "https://senate.arkansas.gov/senators/alan-clark/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-7-upper-ocd-person-6448d3df-2802-44a1-a142-7c3cc136d0a5": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/536c6536-294f-499b-a261-120a685297f0",
            "firstName": "Stephanie Anne",
            "lastName": "Flowers",
            "fullName": "Stephanie Flowers",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/339_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-AR-8-upper-ocd-person-536c6536-294f-499b-a261-120a685297f0",
              "builtID": "ar-upper-8",
              "externalID": "ocd-person/536c6536-294f-499b-a261-120a685297f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephanie.flowers@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Flowers+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Flowers+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=S.+Flowers",
                "https://senate.arkansas.gov/senators/stephanie-flowers/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-8-upper-ocd-person-536c6536-294f-499b-a261-120a685297f0": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/f148aa6d-abfe-4577-9c15-44f2ea79c707",
            "firstName": "Reginald",
            "lastName": "Murdock",
            "fullName": "Reginald Murdock",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/639_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-AR-9-upper-ocd-person-f148aa6d-abfe-4577-9c15-44f2ea79c707",
              "builtID": "ar-upper-9",
              "externalID": "ocd-person/f148aa6d-abfe-4577-9c15-44f2ea79c707",
              "geometry": null
            },
            "contactInfo": {
              "email": "rkm_72390@yahoo.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "ReginaldMurdockAR",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Murdock&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Murdock&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Murdock+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Murdock+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=R.+Murdock&ddBienniumSession=2023%2F2023R",
                "https://senate.arkansas.gov/senators/reginald-murdock/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-9-upper-ocd-person-f148aa6d-abfe-4577-9c15-44f2ea79c707": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/a7626d1d-fcc7-4c01-ad87-48a28faf7245",
            "firstName": "Ronald",
            "lastName": "Caldwell",
            "fullName": "Ron Caldwell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/397_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-AR-10-upper-ocd-person-a7626d1d-fcc7-4c01-ad87-48a28faf7245",
              "builtID": "ar-upper-10",
              "externalID": "ocd-person/a7626d1d-fcc7-4c01-ad87-48a28faf7245",
              "geometry": null
            },
            "contactInfo": {
              "email": "ronald.caldwell@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RonCaldwell23",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Caldwell&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Caldwell&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+Caldwell+-+Senate+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2021%2FMember%20Profiles%2FSenator+Caldwell+-+Senate+Bio.pdf",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Caldwell+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Caldwell+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Caldwell",
                "https://senate.arkansas.gov/senators/ronald-caldwell/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-10-upper-ocd-person-a7626d1d-fcc7-4c01-ad87-48a28faf7245": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/faf89935-181a-4d19-9e4b-dce4657d4d3a",
            "firstName": "Ricky",
            "lastName": "Hill",
            "fullName": "Ricky Hill",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/545_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-AR-11-upper-ocd-person-faf89935-181a-4d19-9e4b-dce4657d4d3a",
              "builtID": "ar-upper-11",
              "externalID": "ocd-person/faf89935-181a-4d19-9e4b-dce4657d4d3a",
              "geometry": null
            },
            "contactInfo": {
              "email": "ricky.hill@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Hill+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Hill+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Hill",
                "https://senate.arkansas.gov/senators/ricky-hill/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-11-upper-ocd-person-faf89935-181a-4d19-9e4b-dce4657d4d3a": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/607c2015-9000-4946-87b8-4f6630f6e72e",
            "firstName": "Linda Ann Pondexter",
            "lastName": "Chesterfield",
            "fullName": "Linda Chesterfield",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/336_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-AR-12-upper-ocd-person-607c2015-9000-4946-87b8-4f6630f6e72e",
              "builtID": "ar-upper-12",
              "externalID": "ocd-person/607c2015-9000-4946-87b8-4f6630f6e72e",
              "geometry": null
            },
            "contactInfo": {
              "email": "lchesterfield47@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "100006131598466",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=L.+Chesterfield&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=L.+Chesterfield&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+Chesterfield+-+Senate+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2021%2FMember%20Profiles%2FSenator+Chesterfield+-+Senate+Bio.pdf",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Chesterfield+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Chesterfield+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=L.+Chesterfield",
                "https://senate.arkansas.gov/senators/linda-chesterfield/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-12-upper-ocd-person-607c2015-9000-4946-87b8-4f6630f6e72e": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/627a5f2e-efc6-4084-982e-b165a79c8e21",
            "firstName": "Elizabeth Jane",
            "lastName": "English",
            "fullName": "Jane English",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/405_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-AR-13-upper-ocd-person-627a5f2e-efc6-4084-982e-b165a79c8e21",
              "builtID": "ar-upper-13",
              "externalID": "ocd-person/627a5f2e-efc6-4084-982e-b165a79c8e21",
              "geometry": null
            },
            "contactInfo": {
              "email": "jane.english@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "jane.english.18",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=J.+English&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=J.+English&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+English+-+Senate+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2021%2FMember%20Profiles%2FSenator+English+-+Senate+Bio.pdf",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+English+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+English+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=J.+English",
                "https://senate.arkansas.gov/senators/jane-english/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-13-upper-ocd-person-627a5f2e-efc6-4084-982e-b165a79c8e21": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/cdb599f1-f956-430d-9ba2-87ce18d3fd53",
            "firstName": "Everett Clarke",
            "lastName": "Tucker",
            "fullName": "Clarke Tucker",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/582_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-AR-14-upper-ocd-person-cdb599f1-f956-430d-9ba2-87ce18d3fd53",
              "builtID": "ar-upper-14",
              "externalID": "ocd-person/cdb599f1-f956-430d-9ba2-87ce18d3fd53",
              "geometry": null
            },
            "contactInfo": {
              "email": "clarke.tucker@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Tucker+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Tucker+-+Senator+Bio.pdf",
                "https://senate.arkansas.gov/senators/clarke-tucker/",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=C.+Tucker&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-14-upper-ocd-person-cdb599f1-f956-430d-9ba2-87ce18d3fd53": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/b35d0a49-a7f2-4013-83f0-a2feb42adba3",
            "firstName": "Fredrick J.",
            "lastName": "Love",
            "fullName": "Fred Love",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/640_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-AR-15-upper-ocd-person-b35d0a49-a7f2-4013-83f0-a2feb42adba3",
              "builtID": "ar-upper-15",
              "externalID": "ocd-person/b35d0a49-a7f2-4013-83f0-a2feb42adba3",
              "geometry": null
            },
            "contactInfo": {
              "email": "fred.love@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Love+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Love+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=F.+Love&ddBienniumSession=2023%2F2023R",
                "https://senate.arkansas.gov/senators/fredrick-j-love/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-15-upper-ocd-person-b35d0a49-a7f2-4013-83f0-a2feb42adba3": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/29e74f04-ceb4-45e1-a803-049e57699923",
            "firstName": "Kim David",
            "lastName": "Hammer",
            "fullName": "Kim Hammer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/576_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-AR-16-upper-ocd-person-29e74f04-ceb4-45e1-a803-049e57699923",
              "builtID": "ar-upper-16",
              "externalID": "ocd-person/29e74f04-ceb4-45e1-a803-049e57699923",
              "geometry": null
            },
            "contactInfo": {
              "email": "kim.hammer@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "kimhammerforstatesenate",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=K.+Hammer&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=K.+Hammer&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+Hammer+-+Senate+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://www.hammer4senate.com",
                "https://thekimhammershow.com/",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2021%2FMember%20Profiles%2FSenator+Hammer+-+Senate+Bio.pdf",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Hammer+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Hammer+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=K.+Hammer",
                "https://senate.arkansas.gov/senators/kim-hammer/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-16-upper-ocd-person-29e74f04-ceb4-45e1-a803-049e57699923": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/927a87ee-64b3-49b8-8794-9b0857581d1c",
            "firstName": "Mark",
            "lastName": "Johnson",
            "fullName": "Mark Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/574_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-AR-17-upper-ocd-person-927a87ee-64b3-49b8-8794-9b0857581d1c",
              "builtID": "ar-upper-17",
              "externalID": "ocd-person/927a87ee-64b3-49b8-8794-9b0857581d1c",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.johnson@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "markjohnsonforarkansas",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=M.+Johnson&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=M.+Johnson&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+Mark+Johnson++-+Senate+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://www.markjohnsonforarkansas.com",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2021%2FMember%20Profiles%2FSenator+Mark+Johnson++-+Senate+Bio.pdf",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Mark+Johnson+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Mark+Johnson+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=M.+Johnson",
                "https://senate.arkansas.gov/senators/mark-johnson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-17-upper-ocd-person-927a87ee-64b3-49b8-8794-9b0857581d1c": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/75b8a2ef-c463-4317-ba03-a368a2f203f2",
            "firstName": "Jonathan",
            "lastName": "Dismang",
            "fullName": "Jonathan Dismang",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/337_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-AR-18-upper-ocd-person-75b8a2ef-c463-4317-ba03-a368a2f203f2",
              "builtID": "ar-upper-18",
              "externalID": "ocd-person/75b8a2ef-c463-4317-ba03-a368a2f203f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "dismang28@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "dismang",
              "youtube": null,
              "instagram": null,
              "facebook": "dismang",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=J.+Dismang&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=J.+Dismang&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+Dismang+-+Senate+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://www.jonathandismang.com/",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2021%2FMember%20Profiles%2FSenator+Dismang+-+Senate+Bio.pdf",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Dismang+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Dismang+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=J.+Dismang",
                "https://senate.arkansas.gov/senators/jonathan-dismang/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-18-upper-ocd-person-75b8a2ef-c463-4317-ba03-a368a2f203f2": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/f009d442-b840-4308-b48e-f38591e5d0d4",
            "firstName": "David Ray",
            "lastName": "Wallace",
            "fullName": "Dave Wallace",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/518_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-AR-19-upper-ocd-person-f009d442-b840-4308-b48e-f38591e5d0d4",
              "builtID": "ar-upper-19",
              "externalID": "ocd-person/f009d442-b840-4308-b48e-f38591e5d0d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.wallace@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SenDaveWallace",
              "youtube": null,
              "instagram": "senatordavewallace",
              "facebook": "electdave",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=D.+Wallace&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=D.+Wallace&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+Wallace+-+Senate+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://www.daveforarkansas.com/",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Wallace+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Wallace+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=D.+Wallace",
                "https://senate.arkansas.gov/senators/david-wallace/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-19-upper-ocd-person-f009d442-b840-4308-b48e-f38591e5d0d4": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/fea1d80a-0cfb-4b26-9123-2a6c2e951d60",
            "firstName": "Dan Alan",
            "lastName": "Sullivan",
            "fullName": "Dan Sullivan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/581_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-AR-20-upper-ocd-person-fea1d80a-0cfb-4b26-9123-2a6c2e951d60",
              "builtID": "ar-upper-20",
              "externalID": "ocd-person/fea1d80a-0cfb-4b26-9123-2a6c2e951d60",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.sullivan@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SullivanforAR",
              "youtube": null,
              "instagram": null,
              "facebook": "SullivanForArkansas",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Sullivan&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=D.+Sullivan&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+Sullivan+-+Senate+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Sullivan+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Sullivan+-+Senator+Bio.pdf",
                "https://senate.arkansas.gov/senators/dan-sullivan/",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=D.+Sullivan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-20-upper-ocd-person-fea1d80a-0cfb-4b26-9123-2a6c2e951d60": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/6bbe436a-67bc-4003-80eb-1ce79c52413f",
            "firstName": "Lowell Blake",
            "lastName": "Johnson",
            "fullName": "Blake Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/509_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-AR-21-upper-ocd-person-6bbe436a-67bc-4003-80eb-1ce79c52413f",
              "builtID": "ar-upper-21",
              "externalID": "ocd-person/6bbe436a-67bc-4003-80eb-1ce79c52413f",
              "geometry": null
            },
            "contactInfo": {
              "email": "blake.johnson@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "farmerblake20",
              "youtube": null,
              "instagram": null,
              "facebook": "BlakeJohnsonForStateSenate",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=B.+Johnson&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=B.+Johnson&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+Blake+Johnson++-+Senate+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2021%2FMember%20Profiles%2FSenator+Blake+Johnson++-+Senate+Bio.pdf",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Blake+Johnson+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Blake+Johnson+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=B.+Johnson",
                "https://senate.arkansas.gov/senators/blake-johnson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-21-upper-ocd-person-6bbe436a-67bc-4003-80eb-1ce79c52413f": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/2f040802-82dc-4ca8-959f-f1b66cf53b16",
            "firstName": "John Russell",
            "lastName": "Payton",
            "fullName": "John Payton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/638_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-AR-22-upper-ocd-person-2f040802-82dc-4ca8-959f-f1b66cf53b16",
              "builtID": "ar-upper-22",
              "externalID": "ocd-person/2f040802-82dc-4ca8-959f-f1b66cf53b16",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.payton@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "JohnPaytonForStateSenate",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Payton&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Payton&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Payton+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Payton+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=J.+Payton&ddBienniumSession=2023%2F2023R",
                "https://senate.arkansas.gov/senators/john-payton/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-22-upper-ocd-person-2f040802-82dc-4ca8-959f-f1b66cf53b16": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/b61d9a4f-d5d1-43b4-84b2-91cb5741b153",
            "firstName": "Scott Patterson",
            "lastName": "Flippo",
            "fullName": "Scott Flippo",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/508_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-AR-23-upper-ocd-person-b61d9a4f-d5d1-43b4-84b2-91cb5741b153",
              "builtID": "ar-upper-23",
              "externalID": "ocd-person/b61d9a4f-d5d1-43b4-84b2-91cb5741b153",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.flippo@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "ScottFlippo",
              "youtube": null,
              "instagram": null,
              "facebook": "scott.flippo.9",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Flippo&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Flippo&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+Flippo+-+Senate+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2021%2FMember%20Profiles%2FSenator+Flippo+-+Senate+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Flippo+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Flippo",
                "https://senate.arkansas.gov/senators/scott-flippo/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-23-upper-ocd-person-b61d9a4f-d5d1-43b4-84b2-91cb5741b153": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/d7dbf486-9ad6-411a-a2ad-a599d0606f2b",
            "firstName": "Missy Thomas",
            "lastName": "Irvin",
            "fullName": "Missy Irvin",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/343_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-AR-24-upper-ocd-person-d7dbf486-9ad6-411a-a2ad-a599d0606f2b",
              "builtID": "ar-upper-24",
              "externalID": "ocd-person/d7dbf486-9ad6-411a-a2ad-a599d0606f2b",
              "geometry": null
            },
            "contactInfo": {
              "email": "missy.irvin@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "irvin4senate",
              "youtube": null,
              "instagram": "senmissyirvin",
              "facebook": "senatorirvin",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Irvin&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Irvin&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+Irvin+-+Senate+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://www.senatorirvin.com",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2021%2FMember%20Profiles%2FSenator+Irvin+-+Senate+Bio.pdf",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Irvin+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Irvin+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Irvin",
                "https://senate.arkansas.gov/senators/missy-irvin/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-24-upper-ocd-person-d7dbf486-9ad6-411a-a2ad-a599d0606f2b": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/2eebf3d3-1e64-4c5b-b5fc-62924bc324fd",
            "firstName": "Breanne",
            "lastName": "Davis",
            "fullName": "Breanne Davis",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/544_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-AR-25-upper-ocd-person-2eebf3d3-1e64-4c5b-b5fc-62924bc324fd",
              "builtID": "ar-upper-25",
              "externalID": "ocd-person/2eebf3d3-1e64-4c5b-b5fc-62924bc324fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "breanne.davis@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "BreanneDavis",
              "youtube": null,
              "instagram": "breannedavis",
              "facebook": "State Senator Breanne Davis",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=B.+Davis&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=B.+Davis&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+Davis+-+Senate+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Davis+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Davis+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=B.+Davis",
                "https://senate.arkansas.gov/senators/breanne-davis/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-25-upper-ocd-person-2eebf3d3-1e64-4c5b-b5fc-62924bc324fd": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/b4087806-94ad-43fd-b4a1-31a4f923bf2f",
            "firstName": "Gary Don",
            "lastName": "Stubblefield",
            "fullName": "Gary Stubblefield",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/406_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-AR-26-upper-ocd-person-b4087806-94ad-43fd-b4a1-31a4f923bf2f",
              "builtID": "ar-upper-26",
              "externalID": "ocd-person/b4087806-94ad-43fd-b4a1-31a4f923bf2f",
              "geometry": null
            },
            "contactInfo": {
              "email": "gary.stubblefield@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Stubblefield+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Stubblefield+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=G.+Stubblefield",
                "https://senate.arkansas.gov/senators/gary-stubblefield/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-26-upper-ocd-person-b4087806-94ad-43fd-b4a1-31a4f923bf2f": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/fb2fcf8c-ed42-46f0-b473-4940692f8e40",
            "firstName": "Phillip Justin",
            "lastName": "Boyd",
            "fullName": "Justin Boyd",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/634_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-AR-27-upper-ocd-person-fb2fcf8c-ed42-46f0-b473-4940692f8e40",
              "builtID": "ar-upper-27",
              "externalID": "ocd-person/fb2fcf8c-ed42-46f0-b473-4940692f8e40",
              "geometry": null
            },
            "contactInfo": {
              "email": "justin.boyd@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "JustinBoydAR",
              "youtube": null,
              "instagram": null,
              "facebook": "boyd4AR",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Boyd&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Boyd&ddBienniumSession=2021%2F2021R",
                "https://www.boyd4ar.com",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Boyd+-+Senator+Bio.pdf",
                "https://senate.arkansas.gov/senators/justin-boyd/",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=J.+Boyd&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-27-upper-ocd-person-fb2fcf8c-ed42-46f0-b473-4940692f8e40": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/ac9a90a0-dfba-4bf0-a07d-ca2232b21a90",
            "firstName": "Bryan B.",
            "lastName": "King",
            "fullName": "Bryan King",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/629_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-AR-28-upper-ocd-person-ac9a90a0-dfba-4bf0-a07d-ca2232b21a90",
              "builtID": "ar-upper-28",
              "externalID": "ocd-person/ac9a90a0-dfba-4bf0-a07d-ca2232b21a90",
              "geometry": null
            },
            "contactInfo": {
              "email": "bryan.king@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+King+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+King+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=B.+King&ddBienniumSession=2023%2F2023R",
                "https://senate.arkansas.gov/senators/bryan-king/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-28-upper-ocd-person-ac9a90a0-dfba-4bf0-a07d-ca2232b21a90": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/6b90e69a-b1af-402f-a219-daacb99f3926",
            "firstName": "Jim",
            "lastName": "Petty",
            "fullName": "Jim Petty",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/631_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-AR-29-upper-ocd-person-6b90e69a-b1af-402f-a219-daacb99f3926",
              "builtID": "ar-upper-29",
              "externalID": "ocd-person/6b90e69a-b1af-402f-a219-daacb99f3926",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.petty@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Petty+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Petty+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=J.+Petty&ddBienniumSession=2023%2F2023R",
                "https://senate.arkansas.gov/senators/jim-petty/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-29-upper-ocd-person-6b90e69a-b1af-402f-a219-daacb99f3926": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/afd73d93-f65e-480d-ba40-3bc65f4fe95b",
            "firstName": "Greg",
            "lastName": "Leding",
            "fullName": "Greg Leding",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/572_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-AR-30-upper-ocd-person-afd73d93-f65e-480d-ba40-3bc65f4fe95b",
              "builtID": "ar-upper-30",
              "externalID": "ocd-person/afd73d93-f65e-480d-ba40-3bc65f4fe95b",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.leding@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "GregLeding",
              "youtube": null,
              "instagram": "gregleding",
              "facebook": "gregleding",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=G.+Leding&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=G.+Leding&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+Leding+-+Senate+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://gregleding.com/",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2021%2FMember%20Profiles%2FSenator+Leding+-+Senate+Bio.pdf",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Leding+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Leding+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=G.+Leding",
                "https://senate.arkansas.gov/senators/greg-leding/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-30-upper-ocd-person-afd73d93-f65e-480d-ba40-3bc65f4fe95b": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/6c773215-c5d7-4039-bcb2-d2abe9053e39",
            "firstName": "Clint",
            "lastName": "Penzo",
            "fullName": "Clint Penzo",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/637_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-AR-31-upper-ocd-person-6c773215-c5d7-4039-bcb2-d2abe9053e39",
              "builtID": "ar-upper-31",
              "externalID": "ocd-person/6c773215-c5d7-4039-bcb2-d2abe9053e39",
              "geometry": null
            },
            "contactInfo": {
              "email": "clint.penzo@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Penzo+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Penzo+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=C.+Penzo&ddBienniumSession=2023%2F2023R",
                "https://senate.arkansas.gov/senators/clint-penzo/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-31-upper-ocd-person-6c773215-c5d7-4039-bcb2-d2abe9053e39": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/e0045c85-0471-4128-ba41-0454ff1470e6",
            "firstName": "Joshua Paul",
            "lastName": "Bryant",
            "fullName": "Josh Bryant",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/633_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-AR-32-upper-ocd-person-e0045c85-0471-4128-ba41-0454ff1470e6",
              "builtID": "ar-upper-32",
              "externalID": "ocd-person/e0045c85-0471-4128-ba41-0454ff1470e6",
              "geometry": null
            },
            "contactInfo": {
              "email": "joshua.bryant@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Bryant+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Bryant+-+Senator+Bio.pdf",
                "https://senate.arkansas.gov/senators/joshua-bryant/",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=J.+Bryant&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-32-upper-ocd-person-e0045c85-0471-4128-ba41-0454ff1470e6": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/0dee8795-58dd-48a8-980e-044d8d2ac646",
            "firstName": "Bart Franklin",
            "lastName": "Hester",
            "fullName": "Bart Hester",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/396_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-AR-33-upper-ocd-person-0dee8795-58dd-48a8-980e-044d8d2ac646",
              "builtID": "ar-upper-33",
              "externalID": "ocd-person/0dee8795-58dd-48a8-980e-044d8d2ac646",
              "geometry": null
            },
            "contactInfo": {
              "email": "bart.hester@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "barthester",
              "youtube": null,
              "instagram": "StateSenatorBartHester",
              "facebook": "BartHesterforStateSenate",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Hester&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Hester&ddBienniumSession=2021%2F2021R",
                "https://www.arkleg.state.ar.us/Legislators/Document?id=&filename=Senator+Hester+-+Senate+Bio.pdf&source=Member+Profiles&ddBienniumSession=2021",
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2021%2FMember%20Profiles%2FSenator+Hester+-+Senate+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Hester+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Hester",
                "https://senate.arkansas.gov/senators/bart-hester/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-33-upper-ocd-person-0dee8795-58dd-48a8-980e-044d8d2ac646": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/f2259c7e-4c43-41c3-ae29-3aedd5b88eef",
            "firstName": "James Michael",
            "lastName": "Dotson",
            "fullName": "Jim Dotson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/636_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-AR-34-upper-ocd-person-f2259c7e-4c43-41c3-ae29-3aedd5b88eef",
              "builtID": "ar-upper-34",
              "externalID": "ocd-person/f2259c7e-4c43-41c3-ae29-3aedd5b88eef",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.dotson@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "JimDotson_",
              "youtube": null,
              "instagram": "repjimdotson",
              "facebook": "dotsonsenate",
              "urls": [
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Dotson&ddBienniumSession=2019%2F2020F",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Dotson&ddBienniumSession=2021%2F2021R",
                "https://www.dotsonforarkansas.com/",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Dotson+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Dotson",
                "https://senate.arkansas.gov/senators/jim-dotson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-34-upper-ocd-person-f2259c7e-4c43-41c3-ae29-3aedd5b88eef": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/11d34a89-689c-4b2f-b953-670ce11ae3bb",
            "firstName": "Tyler",
            "lastName": "Dees",
            "fullName": "Tyler Dees",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.arkleg.state.ar.us/Content/photos/2023/Senate/628_sm.jpg",
            "title": "AR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "AR",
              "stateFull": "Arkansas",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-AR-35-upper-ocd-person-11d34a89-689c-4b2f-b953-670ce11ae3bb",
              "builtID": "ar-upper-35",
              "externalID": "ocd-person/11d34a89-689c-4b2f-b953-670ce11ae3bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "tyler.dees@senate.ar.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Dees+-+Senator+Bio.pdf",
                "https://www.arkleg.state.ar.us/Home/FTPDocument?path=%2FAssembly%2F2023%2FMember%20Profiles%2FSenator+Dees+-+Senator+Bio.pdf",
                "https://senate.arkansas.gov/senators/tyler-dees/",
                "https://www.arkleg.state.ar.us/Legislators/Detail?member=Dees&ddBienniumSession=2023%2F2023R"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-AR-35-upper-ocd-person-11d34a89-689c-4b2f-b953-670ce11ae3bb": 0
        }
      }
    }
  },
  "CA": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "CA",
          "stateFull": "California",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "CA",
            "stateFull": "California",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-CA---",
            "builtID": "ca--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-CA---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "B001320",
          "in_office": true,
          "firstName": "Laphonza",
          "lastName": "Butler",
          "middleName": null,
          "fullName": "Laphonza R. Butler",
          "gender": "F",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/B001320.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "CA",
            "stateFull": "California",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-CA---B001320",
            "builtID": "ca--",
            "externalID": "B001320",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": null,
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": [],
            "rss_url": ""
          },
          "title": "CA Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/B001320.json",
          "govtrack_id": "456954",
          "cspan_id": null,
          "votesmart_id": null,
          "icpsr_id": null,
          "crp_id": null,
          "google_entity_id": null,
          "state_rank": null,
          "lis_id": "S424",
          "suffix": null,
          "date_of_birth": "1979-05-11",
          "leadership_role": null,
          "fec_candidate_id": null,
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": "2024",
          "total_votes": 307,
          "missed_votes": 15,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 4.89,
          "votes_with_party_pct": 98.63,
          "votes_against_party_pct": 1.37,
          "ocd_id": "ocd-division/country:us/state:ca"
        },
        {
          "API_ID": "P000145",
          "in_office": true,
          "firstName": "Alex",
          "lastName": "Padilla",
          "middleName": null,
          "fullName": "Alex Padilla",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/P000145.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "CA",
            "stateFull": "California",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-CA---P000145",
            "builtID": "ca--",
            "externalID": "P000145",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenAlexPadilla",
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.padilla.senate.gov"],
            "rss_url": "https://www.padilla.senate.gov/feed/"
          },
          "title": "CA Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/P000145.json",
          "govtrack_id": "456856",
          "cspan_id": null,
          "votesmart_id": "59742",
          "icpsr_id": null,
          "crp_id": "N00047888",
          "google_entity_id": "/m/0749wj",
          "state_rank": "junior",
          "lis_id": "S413",
          "suffix": null,
          "date_of_birth": "1973-03-22",
          "leadership_role": null,
          "fec_candidate_id": "S2CA00955",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "3",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 27,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 4.87,
          "votes_with_party_pct": 98.48,
          "votes_against_party_pct": 1.52,
          "ocd_id": "ocd-division/country:us/state:ca"
        }
      ],
      "hash": { "SENATE-CA---B001320": 0, "SENATE-CA---P000145": 1 }
    },
    "HOUSE": {
      "10": {
        "members": [
          {
            "API_ID": "D000623",
            "in_office": true,
            "firstName": "Mark",
            "lastName": "DeSaulnier",
            "middleName": null,
            "fullName": "Mark DeSaulnier",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/D000623.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "10",
              "chamber": "",
              "hashID": "HOUSE-CA-10--D000623",
              "builtID": "ca--10",
              "externalID": "D000623",
              "geometry": null,
              "geoid": "0610"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "503 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2095",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDeSaulnier",
              "youtube": null,
              "instagram": null,
              "facebook": "RepMarkDeSaulnier",
              "urls": ["https://desaulnier.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000623.json",
            "govtrack_id": "412613",
            "cspan_id": "61736",
            "votesmart_id": "69477",
            "icpsr_id": "21504",
            "crp_id": "N00030709",
            "google_entity_id": "/m/0289gkw",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1952-03-31",
            "leadership_role": null,
            "fec_candidate_id": "H0CA10073",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 20,
            "total_present": 4,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.9,
            "votes_with_party_pct": 94.73,
            "votes_against_party_pct": 3.41,
            "ocd_id": "ocd-division/country:us/state:ca/cd:10",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-10--D000623": 0 }
      },
      "11": {
        "members": [
          {
            "API_ID": "P000197",
            "in_office": true,
            "firstName": "Nancy",
            "lastName": "Pelosi",
            "middleName": null,
            "fullName": "Nancy Pelosi",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/P000197.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "11",
              "chamber": "",
              "hashID": "HOUSE-CA-11--P000197",
              "builtID": "ca--11",
              "externalID": "P000197",
              "geometry": null,
              "geoid": "0611"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SpeakerPelosi",
              "youtube": "nancypelosi",
              "instagram": null,
              "facebook": "NancyPelosi",
              "urls": ["https://pelosi.house.gov"],
              "rss_url": "https://pelosi.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000197.json",
            "govtrack_id": "400314",
            "cspan_id": "6153",
            "votesmart_id": "26732",
            "icpsr_id": "15448",
            "crp_id": "N00007360",
            "google_entity_id": "/m/012v1t",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1940-03-26",
            "leadership_role": null,
            "fec_candidate_id": null,
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "36",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 102,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 9.66,
            "votes_with_party_pct": 97.04,
            "votes_against_party_pct": 0.85,
            "ocd_id": "ocd-division/country:us/state:ca/cd:11",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-11--P000197": 0 }
      },
      "12": {
        "members": [
          {
            "API_ID": "L000551",
            "in_office": true,
            "firstName": "Barbara",
            "lastName": "Lee",
            "middleName": null,
            "fullName": "Barbara Lee",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/L000551.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "12",
              "chamber": "",
              "hashID": "HOUSE-CA-12--L000551",
              "builtID": "ca--12",
              "externalID": "L000551",
              "geometry": null,
              "geoid": "0612"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2470 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2661",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBarbaraLee",
              "youtube": "RepLee",
              "instagram": null,
              "facebook": "RepBarbaraLee",
              "urls": ["https://lee.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000551.json",
            "govtrack_id": "400237",
            "cspan_id": "54579",
            "votesmart_id": "8315",
            "icpsr_id": "29778",
            "crp_id": "N00008046",
            "google_entity_id": "/m/024sjy",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1946-07-16",
            "leadership_role": null,
            "fec_candidate_id": "H8CA09060",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "28",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 60,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 5.69,
            "votes_with_party_pct": 93,
            "votes_against_party_pct": 5.07,
            "ocd_id": "ocd-division/country:us/state:ca/cd:12",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-12--L000551": 0 }
      },
      "13": {
        "members": [
          {
            "API_ID": "D000633",
            "in_office": true,
            "firstName": "John",
            "lastName": "Duarte",
            "middleName": null,
            "fullName": "John S. Duarte",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/D000633.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "13",
              "chamber": "",
              "hashID": "HOUSE-CA-13--D000633",
              "builtID": "ca--13",
              "externalID": "D000633",
              "geometry": null,
              "geoid": "0613"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1535 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-1947",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://duarte.house.gov/"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000633.json",
            "govtrack_id": "456882",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1966-09-06",
            "leadership_role": null,
            "fec_candidate_id": "H2CA13131",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 12,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.14,
            "votes_with_party_pct": 89.35,
            "votes_against_party_pct": 8.7,
            "ocd_id": "ocd-division/country:us/state:ca/cd:13",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-13--D000633": 0 }
      },
      "14": {
        "members": [
          {
            "API_ID": "S001193",
            "in_office": true,
            "firstName": "Eric",
            "lastName": "Swalwell",
            "middleName": null,
            "fullName": "Eric Swalwell",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001193.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "14",
              "chamber": "",
              "hashID": "HOUSE-CA-14--S001193",
              "builtID": "ca--14",
              "externalID": "S001193",
              "geometry": null,
              "geoid": "0614"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "174 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5065",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepSwalwell",
              "youtube": "ericswalwell",
              "instagram": null,
              "facebook": "CongressmanEricSwalwell",
              "urls": ["https://swalwell.house.gov"],
              "rss_url": "https://swalwell.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001193.json",
            "govtrack_id": "412514",
            "cspan_id": "79729",
            "votesmart_id": "129529",
            "icpsr_id": "21306",
            "crp_id": "N00033508",
            "google_entity_id": "/m/0ncq55g",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1980-11-16",
            "leadership_role": null,
            "fec_candidate_id": "H2CA15094",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 106,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 10.05,
            "votes_with_party_pct": 95.32,
            "votes_against_party_pct": 2.66,
            "ocd_id": "ocd-division/country:us/state:ca/cd:14",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-14--S001193": 0 }
      },
      "15": {
        "members": [
          {
            "API_ID": "M001225",
            "in_office": true,
            "firstName": "Kevin",
            "lastName": "Mullin",
            "middleName": null,
            "fullName": "Kevin Mullin",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M001225.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "15",
              "chamber": "",
              "hashID": "HOUSE-CA-15--M001225",
              "builtID": "ca--15",
              "externalID": "M001225",
              "geometry": null,
              "geoid": "0615"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1404 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3531",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepKevinMullin",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://kevinmullin.house.gov/"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001225.json",
            "govtrack_id": "456883",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1970-06-15",
            "leadership_role": null,
            "fec_candidate_id": "H2CA14162",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 63,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 5.97,
            "votes_with_party_pct": 96.54,
            "votes_against_party_pct": 1.42,
            "ocd_id": "ocd-division/country:us/state:ca/cd:15",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-15--M001225": 0 }
      },
      "16": {
        "members": [
          {
            "API_ID": "E000215",
            "in_office": true,
            "firstName": "Anna",
            "lastName": "Eshoo",
            "middleName": "G.",
            "fullName": "Anna G. Eshoo",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/E000215.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "16",
              "chamber": "",
              "hashID": "HOUSE-CA-16--E000215",
              "builtID": "ca--16",
              "externalID": "E000215",
              "geometry": null,
              "geoid": "0616"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "272 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-8104",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAnnaEshoo",
              "youtube": "RepAnnaEshoo",
              "instagram": null,
              "facebook": "RepAnnaEshoo",
              "urls": ["https://eshoo.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/E000215.json",
            "govtrack_id": "400124",
            "cspan_id": "26130",
            "votesmart_id": "26741",
            "icpsr_id": "29312",
            "crp_id": "N00007335",
            "google_entity_id": "/m/024t6x",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1942-12-13",
            "leadership_role": null,
            "fec_candidate_id": "H8CA12098",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "32",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 18,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.71,
            "votes_with_party_pct": 96.6,
            "votes_against_party_pct": 1.56,
            "ocd_id": "ocd-division/country:us/state:ca/cd:16",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-16--E000215": 0 }
      },
      "17": {
        "members": [
          {
            "API_ID": "K000389",
            "in_office": true,
            "firstName": "Ro",
            "lastName": "Khanna",
            "middleName": null,
            "fullName": "Ro Khanna",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/K000389.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "17",
              "chamber": "",
              "hashID": "HOUSE-CA-17--K000389",
              "builtID": "ca--17",
              "externalID": "K000389",
              "geometry": null,
              "geoid": "0617"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "306 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2631",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRoKhanna",
              "youtube": null,
              "instagram": null,
              "facebook": "RepRoKhanna",
              "urls": ["https://khanna.house.gov"],
              "rss_url": "https://khanna.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000389.json",
            "govtrack_id": "412684",
            "cspan_id": "31129",
            "votesmart_id": "29473",
            "icpsr_id": "21728",
            "crp_id": "N00026427",
            "google_entity_id": "/m/0q3z5xy",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-09-13",
            "leadership_role": null,
            "fec_candidate_id": "H4CA12055",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 10,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.95,
            "votes_with_party_pct": 94.79,
            "votes_against_party_pct": 3.28,
            "ocd_id": "ocd-division/country:us/state:ca/cd:17",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-17--K000389": 0 }
      },
      "18": {
        "members": [
          {
            "API_ID": "L000397",
            "in_office": true,
            "firstName": "Zoe",
            "lastName": "Lofgren",
            "middleName": null,
            "fullName": "Zoe Lofgren",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/L000397.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "18",
              "chamber": "",
              "hashID": "HOUSE-CA-18--L000397",
              "builtID": "ca--18",
              "externalID": "L000397",
              "geometry": null,
              "geoid": "0618"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1401 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3072",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepZoeLofgren",
              "youtube": "RepZoeLofgren",
              "instagram": null,
              "facebook": "zoelofgren",
              "urls": ["https://lofgren.house.gov"],
              "rss_url": "https://lofgren.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000397.json",
            "govtrack_id": "400245",
            "cspan_id": "36520",
            "votesmart_id": "21899",
            "icpsr_id": "29504",
            "crp_id": "N00007479",
            "google_entity_id": "/m/024t94",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1947-12-21",
            "leadership_role": null,
            "fec_candidate_id": "H4CA16049",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "30",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 48,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 4.55,
            "votes_with_party_pct": 95.49,
            "votes_against_party_pct": 2.61,
            "ocd_id": "ocd-division/country:us/state:ca/cd:18",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-18--L000397": 0 }
      },
      "19": {
        "members": [
          {
            "API_ID": "P000613",
            "in_office": true,
            "firstName": "Jimmy",
            "lastName": "Panetta",
            "middleName": null,
            "fullName": "Jimmy Panetta",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/P000613.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "19",
              "chamber": "",
              "hashID": "HOUSE-CA-19--P000613",
              "builtID": "ca--19",
              "externalID": "P000613",
              "geometry": null,
              "geoid": "0619"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "304 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2861",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJimmyPanetta",
              "youtube": null,
              "instagram": null,
              "facebook": "RepJimmyPanetta",
              "urls": ["https://panetta.house.gov"],
              "rss_url": "https://panetta.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000613.json",
            "govtrack_id": "412685",
            "cspan_id": "104727",
            "votesmart_id": "169078",
            "icpsr_id": "21740",
            "crp_id": "N00038601",
            "google_entity_id": "/m/0pyy9y4",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1969-10-01",
            "leadership_role": null,
            "fec_candidate_id": "H6CA20152",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 5,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.47,
            "votes_with_party_pct": 92.99,
            "votes_against_party_pct": 5.09,
            "ocd_id": "ocd-division/country:us/state:ca/cd:19",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-19--P000613": 0 }
      },
      "20": {
        "members": [
          {
            "API_ID": "F000480",
            "in_office": true,
            "firstName": "Vince",
            "lastName": "Fong",
            "middleName": null,
            "fullName": "Vince Fong",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/F000480.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "20",
              "chamber": "",
              "hashID": "HOUSE-CA-20--F000480",
              "builtID": "ca--20",
              "externalID": "F000480",
              "geometry": null,
              "geoid": "0620"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [],
              "rss_url": ""
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000480.json",
            "govtrack_id": null,
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1979-10-24",
            "leadership_role": null,
            "fec_candidate_id": null,
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": null,
            "total_votes": 102,
            "missed_votes": 0,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0,
            "votes_with_party_pct": 92.71,
            "votes_against_party_pct": 6.25,
            "ocd_id": "ocd-division/country:us/state:ca/cd:20",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-20--F000480": 0 }
      },
      "21": {
        "members": [
          {
            "API_ID": "C001059",
            "in_office": true,
            "firstName": "Jim",
            "lastName": "Costa",
            "middleName": null,
            "fullName": "Jim Costa",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001059.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "21",
              "chamber": "",
              "hashID": "HOUSE-CA-21--C001059",
              "builtID": "ca--21",
              "externalID": "C001059",
              "geometry": null,
              "geoid": "0621"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2081 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3341",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJimCosta",
              "youtube": "RepJimCostaCA20",
              "instagram": null,
              "facebook": "RepJimCosta",
              "urls": ["https://costa.house.gov"],
              "rss_url": "https://costa.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001059.json",
            "govtrack_id": "400618",
            "cspan_id": "19599",
            "votesmart_id": "3577",
            "icpsr_id": "20501",
            "crp_id": "N00026341",
            "google_entity_id": "/m/04chm2",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1952-04-13",
            "leadership_role": null,
            "fec_candidate_id": "H4CA20082",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "20",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 69,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 6.54,
            "votes_with_party_pct": 90.58,
            "votes_against_party_pct": 7.47,
            "ocd_id": "ocd-division/country:us/state:ca/cd:21",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-21--C001059": 0 }
      },
      "22": {
        "members": [
          {
            "API_ID": "V000129",
            "in_office": true,
            "firstName": "David",
            "lastName": "Valadao",
            "middleName": null,
            "fullName": "David G. Valadao",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/V000129.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "22",
              "chamber": "",
              "hashID": "HOUSE-CA-22--V000129",
              "builtID": "ca--22",
              "externalID": "V000129",
              "geometry": null,
              "geoid": "0622"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2465 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4695",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDavidValadao",
              "youtube": "congressmanvaladao",
              "instagram": null,
              "facebook": "CongressmanDavidValadao",
              "urls": ["https://valadao.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/V000129.json",
            "govtrack_id": "412515",
            "cspan_id": "623702",
            "votesmart_id": "120200",
            "icpsr_id": "21307",
            "crp_id": "N00033367",
            "google_entity_id": "/m/0g55rlx",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1977-04-14",
            "leadership_role": null,
            "fec_candidate_id": "H2CA20094",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 19,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.8,
            "votes_with_party_pct": 83.27,
            "votes_against_party_pct": 14.76,
            "ocd_id": "ocd-division/country:us/state:ca/cd:22",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-22--V000129": 0 }
      },
      "23": {
        "members": [
          {
            "API_ID": "O000019",
            "in_office": true,
            "firstName": "Jay",
            "lastName": "Obernolte",
            "middleName": null,
            "fullName": "Jay Obernolte",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/O000019.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "23",
              "chamber": "",
              "hashID": "HOUSE-CA-23--O000019",
              "builtID": "ca--23",
              "externalID": "O000019",
              "geometry": null,
              "geoid": "0623"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1029 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5861",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "JayObernolte",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://obernolte.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/O000019.json",
            "govtrack_id": "456801",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00045377",
            "google_entity_id": "/m/02hpvtf",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1970-08-18",
            "leadership_role": null,
            "fec_candidate_id": "H0CA08135",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 12,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.14,
            "votes_with_party_pct": 85.63,
            "votes_against_party_pct": 12.41,
            "ocd_id": "ocd-division/country:us/state:ca/cd:23",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-23--O000019": 0 }
      },
      "24": {
        "members": [
          {
            "API_ID": "C001112",
            "in_office": true,
            "firstName": "Salud",
            "lastName": "Carbajal",
            "middleName": null,
            "fullName": "Salud O. Carbajal",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001112.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "24",
              "chamber": "",
              "hashID": "HOUSE-CA-24--C001112",
              "builtID": "ca--24",
              "externalID": "C001112",
              "geometry": null,
              "geoid": "0624"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2331 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3601",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepCarbajal",
              "youtube": "repcarbajal",
              "instagram": null,
              "facebook": "repsaludcarbajal",
              "urls": ["https://carbajal.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001112.json",
            "govtrack_id": "412686",
            "cspan_id": "104728",
            "votesmart_id": "81569",
            "icpsr_id": "21709",
            "crp_id": "N00037015",
            "google_entity_id": "/g/11c2q53dct",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1964-11-18",
            "leadership_role": null,
            "fec_candidate_id": "H6CA24303",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.57,
            "votes_with_party_pct": 97.02,
            "votes_against_party_pct": 1.06,
            "ocd_id": "ocd-division/country:us/state:ca/cd:24",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-24--C001112": 0 }
      },
      "25": {
        "members": [
          {
            "API_ID": "R000599",
            "in_office": true,
            "firstName": "Raul",
            "lastName": "Ruiz",
            "middleName": null,
            "fullName": "Raul Ruiz",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/R000599.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "25",
              "chamber": "",
              "hashID": "HOUSE-CA-25--R000599",
              "builtID": "ca--25",
              "externalID": "R000599",
              "geometry": null,
              "geoid": "0625"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2342 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5330",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "CongressmanRuiz",
              "youtube": "repraulruiz",
              "instagram": null,
              "facebook": "CongressmanRaulRuizMD",
              "urls": ["https://ruiz.house.gov"],
              "rss_url": "https://ruiz.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/R000599.json",
            "govtrack_id": "412519",
            "cspan_id": "79727",
            "votesmart_id": "136407",
            "icpsr_id": "21311",
            "crp_id": "N00033510",
            "google_entity_id": "/m/02p8pz0",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1972-08-25",
            "leadership_role": null,
            "fec_candidate_id": "H2CA36439",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 9,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 96.43,
            "votes_against_party_pct": 1.74,
            "ocd_id": "ocd-division/country:us/state:ca/cd:25",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-25--R000599": 0 }
      },
      "26": {
        "members": [
          {
            "API_ID": "B001285",
            "in_office": true,
            "firstName": "Julia",
            "lastName": "Brownley",
            "middleName": null,
            "fullName": "Julia Brownley",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/B001285.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "26",
              "chamber": "",
              "hashID": "HOUSE-CA-26--B001285",
              "builtID": "ca--26",
              "externalID": "B001285",
              "geometry": null,
              "geoid": "0626"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2262 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5811",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBrownley",
              "youtube": "RepJuliaBrownley",
              "instagram": null,
              "facebook": "RepJuliaBrownley",
              "urls": ["https://juliabrownley.house.gov"],
              "rss_url": "https://juliabrownley.house.gov/category/press-releases/feed/"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001285.json",
            "govtrack_id": "412516",
            "cspan_id": "79783",
            "votesmart_id": "59904",
            "icpsr_id": "21308",
            "crp_id": "N00034254",
            "google_entity_id": "/m/027z42z",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1952-08-28",
            "leadership_role": null,
            "fec_candidate_id": "H2CA00120",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 13,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.23,
            "votes_with_party_pct": 97,
            "votes_against_party_pct": 1.16,
            "ocd_id": "ocd-division/country:us/state:ca/cd:26",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-26--B001285": 0 }
      },
      "27": {
        "members": [
          {
            "API_ID": "G000061",
            "in_office": true,
            "firstName": "Mike",
            "lastName": "Garcia",
            "middleName": null,
            "fullName": "Mike Garcia",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000061.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "27",
              "chamber": "",
              "hashID": "HOUSE-CA-27--G000061",
              "builtID": "ca--27",
              "externalID": "G000061",
              "geometry": null,
              "geoid": "0627"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "144 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-1956",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMikeGarcia",
              "youtube": null,
              "instagram": null,
              "facebook": "RepMikeGarcia",
              "urls": ["https://mikegarcia.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000061.json",
            "govtrack_id": "456792",
            "cspan_id": null,
            "votesmart_id": "188664",
            "icpsr_id": null,
            "crp_id": "N00044298",
            "google_entity_id": "/g/11j2j3x1pl",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-04-24",
            "leadership_role": null,
            "fec_candidate_id": "H0CA25105",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.57,
            "votes_with_party_pct": 88.05,
            "votes_against_party_pct": 10.01,
            "ocd_id": "ocd-division/country:us/state:ca/cd:27",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-27--G000061": 0 }
      },
      "28": {
        "members": [
          {
            "API_ID": "C001080",
            "in_office": true,
            "firstName": "Judy",
            "lastName": "Chu",
            "middleName": null,
            "fullName": "Judy Chu",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001080.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "28",
              "chamber": "",
              "hashID": "HOUSE-CA-28--C001080",
              "builtID": "ca--28",
              "externalID": "C001080",
              "geometry": null,
              "geoid": "0628"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2423 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5464",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJudyChu",
              "youtube": "RepJudyChu",
              "instagram": null,
              "facebook": "RepJudyChu",
              "urls": ["https://chu.house.gov"],
              "rss_url": "https://chu.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001080.json",
            "govtrack_id": "412379",
            "cspan_id": "92573",
            "votesmart_id": "16539",
            "icpsr_id": "20955",
            "crp_id": "N00030600",
            "google_entity_id": "/m/0krsk2",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1953-07-07",
            "leadership_role": null,
            "fec_candidate_id": "H0CA32101",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "18",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 23,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.18,
            "votes_with_party_pct": 94.62,
            "votes_against_party_pct": 3.52,
            "ocd_id": "ocd-division/country:us/state:ca/cd:28",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-28--C001080": 0 }
      },
      "29": {
        "members": [
          {
            "API_ID": "C001097",
            "in_office": true,
            "firstName": "Tony",
            "lastName": "Cárdenas",
            "middleName": null,
            "fullName": "Tony Cárdenas",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001097.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "29",
              "chamber": "",
              "hashID": "HOUSE-CA-29--C001097",
              "builtID": "ca--29",
              "externalID": "C001097",
              "geometry": null,
              "geoid": "0629"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2181 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6131",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepCardenas",
              "youtube": "repcardenas",
              "instagram": null,
              "facebook": "repcardenas",
              "urls": ["https://cardenas.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001097.json",
            "govtrack_id": "412517",
            "cspan_id": "63934",
            "votesmart_id": "9754",
            "icpsr_id": "21309",
            "crp_id": "N00033373",
            "google_entity_id": "/m/0cgzsw",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1963-03-31",
            "leadership_role": null,
            "fec_candidate_id": "H2CA28113",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 31,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.94,
            "votes_with_party_pct": 94.87,
            "votes_against_party_pct": 3.25,
            "ocd_id": "ocd-division/country:us/state:ca/cd:29",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-29--C001097": 0 }
      },
      "30": {
        "members": [
          {
            "API_ID": "S001150",
            "in_office": true,
            "firstName": "Adam",
            "lastName": "Schiff",
            "middleName": "B.",
            "fullName": "Adam B. Schiff",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001150.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "30",
              "chamber": "",
              "hashID": "HOUSE-CA-30--S001150",
              "builtID": "ca--30",
              "externalID": "S001150",
              "geometry": null,
              "geoid": "0630"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2309 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4176",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAdamSchiff",
              "youtube": "adamschiff",
              "instagram": null,
              "facebook": "RepAdamSchiff",
              "urls": ["https://schiff.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001150.json",
            "govtrack_id": "400361",
            "cspan_id": "90167",
            "votesmart_id": "9489",
            "icpsr_id": "20104",
            "crp_id": "N00009585",
            "google_entity_id": "/m/024tls",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1960-06-22",
            "leadership_role": null,
            "fec_candidate_id": "H0CA27085",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "24",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 34,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.22,
            "votes_with_party_pct": 96.44,
            "votes_against_party_pct": 1.68,
            "ocd_id": "ocd-division/country:us/state:ca/cd:30",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-30--S001150": 0 }
      },
      "31": {
        "members": [
          {
            "API_ID": "N000179",
            "in_office": true,
            "firstName": "Grace",
            "lastName": "Napolitano",
            "middleName": "F.",
            "fullName": "Grace F. Napolitano",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/N000179.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "31",
              "chamber": "",
              "hashID": "HOUSE-CA-31--N000179",
              "builtID": "ca--31",
              "externalID": "N000179",
              "geometry": null,
              "geoid": "0631"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1610 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5256",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "GraceNapolitano",
              "youtube": "RepGraceNapolitano",
              "instagram": null,
              "facebook": "RepGraceNapolitano",
              "urls": ["https://napolitano.house.gov"],
              "rss_url": "https://napolitano.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/N000179.json",
            "govtrack_id": "400290",
            "cspan_id": "57873",
            "votesmart_id": "8393",
            "icpsr_id": "29903",
            "crp_id": "N00006789",
            "google_entity_id": "/m/024v0s",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1936-12-04",
            "leadership_role": null,
            "fec_candidate_id": "H8CA34068",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "26",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 52,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 4.93,
            "votes_with_party_pct": 95.97,
            "votes_against_party_pct": 2.11,
            "ocd_id": "ocd-division/country:us/state:ca/cd:31",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-31--N000179": 0 }
      },
      "32": {
        "members": [
          {
            "API_ID": "S000344",
            "in_office": true,
            "firstName": "Brad",
            "lastName": "Sherman",
            "middleName": null,
            "fullName": "Brad Sherman",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S000344.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "32",
              "chamber": "",
              "hashID": "HOUSE-CA-32--S000344",
              "builtID": "ca--32",
              "externalID": "S000344",
              "geometry": null,
              "geoid": "0632"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2365 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5911",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "BradSherman",
              "youtube": "shermanca27",
              "instagram": null,
              "facebook": "63158229861",
              "urls": ["https://sherman.house.gov"],
              "rss_url": "https://sherman.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S000344.json",
            "govtrack_id": "400371",
            "cspan_id": "45124",
            "votesmart_id": "142",
            "icpsr_id": "29707",
            "crp_id": "N00006897",
            "google_entity_id": "/m/024tkr",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1954-10-24",
            "leadership_role": null,
            "fec_candidate_id": "H6CA24113",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "28",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 16,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.52,
            "votes_with_party_pct": 95.72,
            "votes_against_party_pct": 2.43,
            "ocd_id": "ocd-division/country:us/state:ca/cd:32",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-32--S000344": 0 }
      },
      "33": {
        "members": [
          {
            "API_ID": "A000371",
            "in_office": true,
            "firstName": "Pete",
            "lastName": "Aguilar",
            "middleName": null,
            "fullName": "Pete Aguilar",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/A000371.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "33",
              "chamber": "",
              "hashID": "HOUSE-CA-33--A000371",
              "builtID": "ca--33",
              "externalID": "A000371",
              "geometry": null,
              "geoid": "0633"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "108 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3201",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "reppeteaguilar",
              "youtube": null,
              "instagram": null,
              "facebook": "reppeteaguilar",
              "urls": ["https://aguilar.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/A000371.json",
            "govtrack_id": "412615",
            "cspan_id": "79994",
            "votesmart_id": "70114",
            "icpsr_id": "21506",
            "crp_id": "N00033997",
            "google_entity_id": "/m/0jwv0xf",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1979-06-19",
            "leadership_role": "House Democratic Caucus Vice Chair",
            "fec_candidate_id": "H2CA31125",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 2,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.19,
            "votes_with_party_pct": 96.65,
            "votes_against_party_pct": 1.44,
            "ocd_id": "ocd-division/country:us/state:ca/cd:33",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-33--A000371": 0 }
      },
      "34": {
        "members": [
          {
            "API_ID": "G000585",
            "in_office": true,
            "firstName": "Jimmy",
            "lastName": "Gomez",
            "middleName": null,
            "fullName": "Jimmy Gomez",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/G000585.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "34",
              "chamber": "",
              "hashID": "HOUSE-CA-34--G000585",
              "builtID": "ca--34",
              "externalID": "G000585",
              "geometry": null,
              "geoid": "0634"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "506 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-6235",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJimmyGomez",
              "youtube": null,
              "instagram": null,
              "facebook": "RepJimmyGomez",
              "urls": ["https://gomez.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000585.json",
            "govtrack_id": "412739",
            "cspan_id": "108883",
            "votesmart_id": "138524",
            "icpsr_id": "21754",
            "crp_id": "N00040597",
            "google_entity_id": "/m/0n_73h1",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1974-11-25",
            "leadership_role": null,
            "fec_candidate_id": "H8CA34266",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 32,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.03,
            "votes_with_party_pct": 94.58,
            "votes_against_party_pct": 3.45,
            "ocd_id": "ocd-division/country:us/state:ca/cd:34",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-34--G000585": 0 }
      },
      "35": {
        "members": [
          {
            "API_ID": "T000474",
            "in_office": true,
            "firstName": "Norma",
            "lastName": "Torres",
            "middleName": null,
            "fullName": "Norma J. Torres",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/T000474.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "35",
              "chamber": "",
              "hashID": "HOUSE-CA-35--T000474",
              "builtID": "ca--35",
              "externalID": "T000474",
              "geometry": null,
              "geoid": "0635"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2227 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6161",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "NormaJTorres",
              "youtube": null,
              "instagram": null,
              "facebook": "RepNormaTorres",
              "urls": ["https://torres.house.gov"],
              "rss_url": "https://torres.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000474.json",
            "govtrack_id": "412617",
            "cspan_id": "76129",
            "votesmart_id": "71284",
            "icpsr_id": "21508",
            "crp_id": "N00036107",
            "google_entity_id": "/m/03wd1hs",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1965-04-04",
            "leadership_role": null,
            "fec_candidate_id": "H4CA35031",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 10,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.95,
            "votes_with_party_pct": 96.24,
            "votes_against_party_pct": 1.83,
            "ocd_id": "ocd-division/country:us/state:ca/cd:35",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-35--T000474": 0 }
      },
      "36": {
        "members": [
          {
            "API_ID": "L000582",
            "in_office": true,
            "firstName": "Ted",
            "lastName": "Lieu",
            "middleName": null,
            "fullName": "Ted Lieu",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/L000582.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "36",
              "chamber": "",
              "hashID": "HOUSE-CA-36--L000582",
              "builtID": "ca--36",
              "externalID": "L000582",
              "geometry": null,
              "geoid": "0636"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2454 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3976",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepTedLieu",
              "youtube": null,
              "instagram": null,
              "facebook": "RepTedLieu",
              "urls": ["https://lieu.house.gov"],
              "rss_url": "https://lieu.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000582.json",
            "govtrack_id": "412616",
            "cspan_id": "28076",
            "votesmart_id": "1516",
            "icpsr_id": "21507",
            "crp_id": "N00035825",
            "google_entity_id": "/m/0f3y2w",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1969-03-29",
            "leadership_role": null,
            "fec_candidate_id": "H4CA33119",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 26,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.46,
            "votes_with_party_pct": 95.68,
            "votes_against_party_pct": 2.45,
            "ocd_id": "ocd-division/country:us/state:ca/cd:36",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-36--L000582": 0 }
      },
      "37": {
        "members": [
          {
            "API_ID": "K000400",
            "in_office": true,
            "firstName": "Sydney",
            "lastName": "Kamlager-Dove",
            "middleName": null,
            "fullName": "Sydney Kamlager-Dove",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/K000400.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "37",
              "chamber": "",
              "hashID": "HOUSE-CA-37--K000400",
              "builtID": "ca--37",
              "externalID": "K000400",
              "geometry": null,
              "geoid": "0637"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1419 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-7084",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://kamlager-dove.house.gov/"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000400.json",
            "govtrack_id": "456884",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1972-07-20",
            "leadership_role": null,
            "fec_candidate_id": "H2CA37304",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 37,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 3.5,
            "votes_with_party_pct": 93.86,
            "votes_against_party_pct": 4.26,
            "ocd_id": "ocd-division/country:us/state:ca/cd:37",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-37--K000400": 0 }
      },
      "38": {
        "members": [
          {
            "API_ID": "S001156",
            "in_office": true,
            "firstName": "Linda",
            "lastName": "Sánchez",
            "middleName": "T.",
            "fullName": "Linda T. Sánchez",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001156.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "38",
              "chamber": "",
              "hashID": "HOUSE-CA-38--S001156",
              "builtID": "ca--38",
              "externalID": "S001156",
              "geometry": null,
              "geoid": "0638"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2428 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6676",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLindaSanchez",
              "youtube": "LindaTSanchez",
              "instagram": null,
              "facebook": "CongresswomanLindaSanchez",
              "urls": ["https://lindasanchez.house.gov"],
              "rss_url": "https://lindasanchez.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001156.json",
            "govtrack_id": "400355",
            "cspan_id": "1003554",
            "votesmart_id": "29674",
            "icpsr_id": "20310",
            "crp_id": "N00024870",
            "google_entity_id": "/m/024xc7",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1969-01-28",
            "leadership_role": null,
            "fec_candidate_id": "H2CA39078",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "22",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 13,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.23,
            "votes_with_party_pct": 95.83,
            "votes_against_party_pct": 2.33,
            "ocd_id": "ocd-division/country:us/state:ca/cd:38",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-38--S001156": 0 }
      },
      "39": {
        "members": [
          {
            "API_ID": "T000472",
            "in_office": true,
            "firstName": "Mark",
            "lastName": "Takano",
            "middleName": null,
            "fullName": "Mark Takano",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/T000472.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "39",
              "chamber": "",
              "hashID": "HOUSE-CA-39--T000472",
              "builtID": "ca--39",
              "externalID": "T000472",
              "geometry": null,
              "geoid": "0639"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2078 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2305",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMarkTakano",
              "youtube": "RepMarkTakano",
              "instagram": null,
              "facebook": "RepMarkTakano",
              "urls": ["https://takano.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000472.json",
            "govtrack_id": "412520",
            "cspan_id": "2737",
            "votesmart_id": "22337",
            "icpsr_id": "21312",
            "crp_id": "N00006701",
            "google_entity_id": "/m/0k3lj_y",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1960-12-10",
            "leadership_role": null,
            "fec_candidate_id": "H2CA43245",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 9,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 94.59,
            "votes_against_party_pct": 3.57,
            "ocd_id": "ocd-division/country:us/state:ca/cd:39",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-39--T000472": 0 }
      },
      "40": {
        "members": [
          {
            "API_ID": "K000397",
            "in_office": true,
            "firstName": "Young",
            "lastName": "Kim",
            "middleName": null,
            "fullName": "Young Kim",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/K000397.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "40",
              "chamber": "",
              "hashID": "HOUSE-CA-40--K000397",
              "builtID": "ca--40",
              "externalID": "K000397",
              "geometry": null,
              "geoid": "0640"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1306 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4111",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepYoungKim",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://youngkim.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000397.json",
            "govtrack_id": "456802",
            "cspan_id": null,
            "votesmart_id": "151787",
            "icpsr_id": null,
            "crp_id": "N00042386",
            "google_entity_id": "/m/012c98r4",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1962-10-18",
            "leadership_role": null,
            "fec_candidate_id": "H8CA39240",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 11,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.04,
            "votes_with_party_pct": 82.71,
            "votes_against_party_pct": 15.33,
            "ocd_id": "ocd-division/country:us/state:ca/cd:40",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-40--K000397": 0 }
      },
      "41": {
        "members": [
          {
            "API_ID": "C000059",
            "in_office": true,
            "firstName": "Ken",
            "lastName": "Calvert",
            "middleName": null,
            "fullName": "Ken Calvert",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C000059.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "41",
              "chamber": "",
              "hashID": "HOUSE-CA-41--C000059",
              "builtID": "ca--41",
              "externalID": "C000059",
              "geometry": null,
              "geoid": "0641"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2205 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-1986",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "KenCalvert",
              "youtube": "RepKenCalvert",
              "instagram": null,
              "facebook": "70063393423",
              "urls": ["https://calvert.house.gov"],
              "rss_url": "https://calvert.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C000059.json",
            "govtrack_id": "400057",
            "cspan_id": "26709",
            "votesmart_id": "26777",
            "icpsr_id": "29323",
            "crp_id": "N00007099",
            "google_entity_id": "/m/024xl1",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1953-06-08",
            "leadership_role": null,
            "fec_candidate_id": "H2CA37023",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "32",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 3,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.28,
            "votes_with_party_pct": 86.13,
            "votes_against_party_pct": 12.03,
            "ocd_id": "ocd-division/country:us/state:ca/cd:41",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-41--C000059": 0 }
      },
      "42": {
        "members": [
          {
            "API_ID": "G000598",
            "in_office": true,
            "firstName": "Robert",
            "lastName": "Garcia",
            "middleName": null,
            "fullName": "Robert Garcia",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/G000598.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "42",
              "chamber": "",
              "hashID": "HOUSE-CA-42--G000598",
              "builtID": "ca--42",
              "externalID": "G000598",
              "geometry": null,
              "geoid": "0642"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1305 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-7924",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRobertGarcia",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://robertgarcia.house.gov/"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000598.json",
            "govtrack_id": "456885",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1977-12-02",
            "leadership_role": null,
            "fec_candidate_id": "H2CA47188",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 52,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 4.92,
            "votes_with_party_pct": 93.26,
            "votes_against_party_pct": 4.73,
            "ocd_id": "ocd-division/country:us/state:ca/cd:42",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-42--G000598": 0 }
      },
      "43": {
        "members": [
          {
            "API_ID": "W000187",
            "in_office": true,
            "firstName": "Maxine",
            "lastName": "Waters",
            "middleName": null,
            "fullName": "Maxine Waters",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/W000187.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "43",
              "chamber": "",
              "hashID": "HOUSE-CA-43--W000187",
              "builtID": "ca--43",
              "externalID": "W000187",
              "geometry": null,
              "geoid": "0643"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2221 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2201",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMaxineWaters",
              "youtube": "MaxineWaters",
              "instagram": null,
              "facebook": "MaxineWaters",
              "urls": ["https://waters.house.gov"],
              "rss_url": "https://waters.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000187.json",
            "govtrack_id": "400422",
            "cspan_id": "1953",
            "votesmart_id": "26759",
            "icpsr_id": "29106",
            "crp_id": "N00006690",
            "google_entity_id": "/m/024tyk",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1938-08-15",
            "leadership_role": null,
            "fec_candidate_id": "H4CA23011",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "34",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 41,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.89,
            "votes_with_party_pct": 94.43,
            "votes_against_party_pct": 3.68,
            "ocd_id": "ocd-division/country:us/state:ca/cd:43",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-43--W000187": 0 }
      },
      "44": {
        "members": [
          {
            "API_ID": "B001300",
            "in_office": true,
            "firstName": "Nanette",
            "lastName": "Barragán",
            "middleName": null,
            "fullName": "Nanette Diaz Barragán",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/B001300.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "44",
              "chamber": "",
              "hashID": "HOUSE-CA-44--B001300",
              "builtID": "ca--44",
              "externalID": "B001300",
              "geometry": null,
              "geoid": "0644"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2312 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-8220",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBarragan",
              "youtube": null,
              "instagram": null,
              "facebook": "CongresswomanBarragan",
              "urls": ["https://barragan.house.gov"],
              "rss_url": "https://barragan.house.gov/feed/"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001300.json",
            "govtrack_id": "412687",
            "cspan_id": "104730",
            "votesmart_id": "166270",
            "icpsr_id": "21703",
            "crp_id": "N00037019",
            "google_entity_id": "/g/11bx55ybbz",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-09-15",
            "leadership_role": null,
            "fec_candidate_id": "H6CA44103",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 17,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.61,
            "votes_with_party_pct": 95.15,
            "votes_against_party_pct": 2.91,
            "ocd_id": "ocd-division/country:us/state:ca/cd:44",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-44--B001300": 0 }
      },
      "45": {
        "members": [
          {
            "API_ID": "S001135",
            "in_office": true,
            "firstName": "Michelle",
            "lastName": "Steel",
            "middleName": null,
            "fullName": "Michelle Steel",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S001135.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "45",
              "chamber": "",
              "hashID": "HOUSE-CA-45--S001135",
              "builtID": "ca--45",
              "externalID": "S001135",
              "geometry": null,
              "geoid": "0645"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1127 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2415",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepSteel",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://steel.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001135.json",
            "govtrack_id": "456803",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00044501",
            "google_entity_id": "/m/028bz6v",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1955-06-21",
            "leadership_role": null,
            "fec_candidate_id": "H0CA48198",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 9,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 88.01,
            "votes_against_party_pct": 10.04,
            "ocd_id": "ocd-division/country:us/state:ca/cd:45",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-45--S001135": 0 }
      },
      "46": {
        "members": [
          {
            "API_ID": "C001110",
            "in_office": true,
            "firstName": "J.",
            "lastName": "Correa",
            "middleName": "Luis",
            "fullName": "J. Luis Correa",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001110.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "46",
              "chamber": "",
              "hashID": "HOUSE-CA-46--C001110",
              "builtID": "ca--46",
              "externalID": "C001110",
              "geometry": null,
              "geoid": "0646"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2301 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2965",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "reploucorrea",
              "youtube": null,
              "instagram": null,
              "facebook": "RepLouCorrea",
              "urls": ["https://correa.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001110.json",
            "govtrack_id": "412688",
            "cspan_id": "46310",
            "votesmart_id": "9732",
            "icpsr_id": "21711",
            "crp_id": "N00037260",
            "google_entity_id": "/m/02q_np2",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1958-01-24",
            "leadership_role": null,
            "fec_candidate_id": "H6CA46116",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 41,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.88,
            "votes_with_party_pct": 94.14,
            "votes_against_party_pct": 3.98,
            "ocd_id": "ocd-division/country:us/state:ca/cd:46",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-46--C001110": 0 }
      },
      "47": {
        "members": [
          {
            "API_ID": "P000618",
            "in_office": true,
            "firstName": "Katie",
            "lastName": "Porter",
            "middleName": null,
            "fullName": "Katie Porter",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/P000618.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "47",
              "chamber": "",
              "hashID": "HOUSE-CA-47--P000618",
              "builtID": "ca--47",
              "externalID": "P000618",
              "geometry": null,
              "geoid": "0647"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1233 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5611",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepKatiePorter",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://porter.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000618.json",
            "govtrack_id": "412758",
            "cspan_id": null,
            "votesmart_id": "179393",
            "icpsr_id": null,
            "crp_id": "N00040865",
            "google_entity_id": "/g/11gxfcsxrs",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1974-01-03",
            "leadership_role": null,
            "fec_candidate_id": "H8CA45130",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 93,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 8.81,
            "votes_with_party_pct": 95.08,
            "votes_against_party_pct": 2.83,
            "ocd_id": "ocd-division/country:us/state:ca/cd:47",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-47--P000618": 0 }
      },
      "48": {
        "members": [
          {
            "API_ID": "I000056",
            "in_office": true,
            "firstName": "Darrell",
            "lastName": "Issa",
            "middleName": null,
            "fullName": "Darrell Issa",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/I000056.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "48",
              "chamber": "",
              "hashID": "HOUSE-CA-48--I000056",
              "builtID": "ca--48",
              "externalID": "I000056",
              "geometry": null,
              "geoid": "0648"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2108 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5672",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "DarrellIssa",
              "youtube": "repdarrellissa",
              "instagram": null,
              "facebook": "darrellissa",
              "urls": ["https://issa.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/I000056.json",
            "govtrack_id": "400196",
            "cspan_id": "90066",
            "votesmart_id": "16553",
            "icpsr_id": "20107",
            "crp_id": "N00007017",
            "google_entity_id": "/m/01r7wc",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1953-11-01",
            "leadership_role": null,
            "fec_candidate_id": "H0CA48024",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 31,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.94,
            "votes_with_party_pct": 92.64,
            "votes_against_party_pct": 5.37,
            "ocd_id": "ocd-division/country:us/state:ca/cd:48",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-48--I000056": 0 }
      },
      "49": {
        "members": [
          {
            "API_ID": "L000593",
            "in_office": true,
            "firstName": "Mike",
            "lastName": "Levin",
            "middleName": null,
            "fullName": "Mike Levin",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/L000593.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "49",
              "chamber": "",
              "hashID": "HOUSE-CA-49--L000593",
              "builtID": "ca--49",
              "externalID": "L000593",
              "geometry": null,
              "geoid": "0649"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2352 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3906",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMikeLevin",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://mikelevin.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000593.json",
            "govtrack_id": "412760",
            "cspan_id": null,
            "votesmart_id": "179416",
            "icpsr_id": null,
            "crp_id": "N00040667",
            "google_entity_id": "/g/11gjhfrvyp",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1978-10-28",
            "leadership_role": null,
            "fec_candidate_id": "H8CA49058",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 1,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.09,
            "votes_with_party_pct": 94.45,
            "votes_against_party_pct": 3.64,
            "ocd_id": "ocd-division/country:us/state:ca/cd:49",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-49--L000593": 0 }
      },
      "50": {
        "members": [
          {
            "API_ID": "P000608",
            "in_office": true,
            "firstName": "Scott",
            "lastName": "Peters",
            "middleName": null,
            "fullName": "Scott H. Peters",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/P000608.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "50",
              "chamber": "",
              "hashID": "HOUSE-CA-50--P000608",
              "builtID": "ca--50",
              "externalID": "P000608",
              "geometry": null,
              "geoid": "0650"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1201 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-0508",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepScottPeters",
              "youtube": null,
              "instagram": null,
              "facebook": "RepScottPeters",
              "urls": ["https://scottpeters.house.gov"],
              "rss_url": "https://scottpeters.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000608.json",
            "govtrack_id": "412523",
            "cspan_id": "79661",
            "votesmart_id": "70351",
            "icpsr_id": "21315",
            "crp_id": "N00033591",
            "google_entity_id": "/m/02rymxd",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1958-06-17",
            "leadership_role": null,
            "fec_candidate_id": "H2CA52089",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 20,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.9,
            "votes_with_party_pct": 95.22,
            "votes_against_party_pct": 2.93,
            "ocd_id": "ocd-division/country:us/state:ca/cd:50",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-50--P000608": 0 }
      },
      "51": {
        "members": [
          {
            "API_ID": "J000305",
            "in_office": true,
            "firstName": "Sara",
            "lastName": "Jacobs",
            "middleName": null,
            "fullName": "Sara Jacobs",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/J000305.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "51",
              "chamber": "",
              "hashID": "HOUSE-CA-51--J000305",
              "builtID": "ca--51",
              "externalID": "J000305",
              "geometry": null,
              "geoid": "0651"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1314 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2040",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepSaraJacobs",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://sarajacobs.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/J000305.json",
            "govtrack_id": "456804",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00042081",
            "google_entity_id": "/g/11ghs2x3tm",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1989-02-01",
            "leadership_role": null,
            "fec_candidate_id": "H8CA49074",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 28,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.65,
            "votes_with_party_pct": 94.79,
            "votes_against_party_pct": 3.24,
            "ocd_id": "ocd-division/country:us/state:ca/cd:51",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-51--J000305": 0 }
      },
      "52": {
        "members": [
          {
            "API_ID": "V000130",
            "in_office": true,
            "firstName": "Juan",
            "lastName": "Vargas",
            "middleName": null,
            "fullName": "Juan Vargas",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/V000130.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "52",
              "chamber": "",
              "hashID": "HOUSE-CA-52--V000130",
              "builtID": "ca--52",
              "externalID": "V000130",
              "geometry": null,
              "geoid": "0652"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2334 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-8045",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJuanVargas",
              "youtube": "RepJuanVargas",
              "instagram": null,
              "facebook": "RepJuanVargas",
              "urls": ["https://vargas.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/V000130.json",
            "govtrack_id": "412522",
            "cspan_id": "8297",
            "votesmart_id": "29100",
            "icpsr_id": "21314",
            "crp_id": "N00007021",
            "google_entity_id": "/m/076bp4",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1961-03-07",
            "leadership_role": null,
            "fec_candidate_id": "H2CA50026",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 23,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.18,
            "votes_with_party_pct": 95.01,
            "votes_against_party_pct": 3.13,
            "ocd_id": "ocd-division/country:us/state:ca/cd:52",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-52--V000130": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "B001287",
            "in_office": true,
            "firstName": "Ami",
            "lastName": "Bera",
            "middleName": null,
            "fullName": "Ami Bera",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/B001287.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-CA-06--B001287",
              "builtID": "ca--06",
              "externalID": "B001287",
              "geometry": null,
              "geoid": "0606"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "172 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5716",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBera",
              "youtube": "repamibera",
              "instagram": null,
              "facebook": "RepAmiBera",
              "urls": ["https://bera.house.gov"],
              "rss_url": "https://bera.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001287.json",
            "govtrack_id": "412512",
            "cspan_id": "1033636",
            "votesmart_id": "120030",
            "icpsr_id": "21304",
            "crp_id": "N00030717",
            "google_entity_id": "/m/0dgqnts",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1965-03-02",
            "leadership_role": null,
            "fec_candidate_id": "H0CA03078",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 5,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.47,
            "votes_with_party_pct": 95.96,
            "votes_against_party_pct": 2.21,
            "ocd_id": "ocd-division/country:us/state:ca/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-06--B001287": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "G000559",
            "in_office": true,
            "firstName": "John",
            "lastName": "Garamendi",
            "middleName": null,
            "fullName": "John Garamendi",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/G000559.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-CA-08--G000559",
              "builtID": "ca--08",
              "externalID": "G000559",
              "geometry": null,
              "geoid": "0608"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2004 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-1880",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGaramendi",
              "youtube": "garamendiCA10",
              "instagram": null,
              "facebook": "repgaramendi",
              "urls": ["https://garamendi.house.gov"],
              "rss_url": "https://garamendi.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000559.json",
            "govtrack_id": "412382",
            "cspan_id": "18413",
            "votesmart_id": "29664",
            "icpsr_id": "20958",
            "crp_id": "N00030856",
            "google_entity_id": "/m/05fvls",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1945-01-24",
            "leadership_role": null,
            "fec_candidate_id": "H0CA10149",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "16",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 52,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 4.93,
            "votes_with_party_pct": 95.67,
            "votes_against_party_pct": 2.42,
            "ocd_id": "ocd-division/country:us/state:ca/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-08--G000559": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "H001090",
            "in_office": true,
            "firstName": "Josh",
            "lastName": "Harder",
            "middleName": null,
            "fullName": "Josh Harder",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/H001090.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-CA-09--H001090",
              "builtID": "ca--09",
              "externalID": "H001090",
              "geometry": null,
              "geoid": "0609"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "209 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-4540",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJoshHarder",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://harder.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001090.json",
            "govtrack_id": "412754",
            "cspan_id": null,
            "votesmart_id": "179326",
            "icpsr_id": null,
            "crp_id": "N00040853",
            "google_entity_id": "/g/11fhwknffg",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1986-08-01",
            "leadership_role": null,
            "fec_candidate_id": "H8CA10126",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 24,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.27,
            "votes_with_party_pct": 91.98,
            "votes_against_party_pct": 6.07,
            "ocd_id": "ocd-division/country:us/state:ca/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-09--H001090": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "H001068",
            "in_office": true,
            "firstName": "Jared",
            "lastName": "Huffman",
            "middleName": null,
            "fullName": "Jared Huffman",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/H001068.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-CA-02--H001068",
              "builtID": "ca--02",
              "externalID": "H001068",
              "geometry": null,
              "geoid": "0602"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2445 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5161",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepHuffman",
              "youtube": "rephuffman",
              "instagram": null,
              "facebook": "RepHuffman",
              "urls": ["https://huffman.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001068.json",
            "govtrack_id": "412511",
            "cspan_id": "622431",
            "votesmart_id": "59849",
            "icpsr_id": "21303",
            "crp_id": "N00033030",
            "google_entity_id": "/m/0ksfyx",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1964-02-18",
            "leadership_role": null,
            "fec_candidate_id": "H2CA06259",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 37,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.51,
            "votes_with_party_pct": 94.75,
            "votes_against_party_pct": 3.37,
            "ocd_id": "ocd-division/country:us/state:ca/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-02--H001068": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "K000401",
            "in_office": true,
            "firstName": "Kevin",
            "lastName": "Kiley",
            "middleName": null,
            "fullName": "Kevin Kiley",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/K000401.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-CA-03--K000401",
              "builtID": "ca--03",
              "externalID": "K000401",
              "geometry": null,
              "geoid": "0603"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1032 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2523",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepKiley",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://kiley.house.gov/"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000401.json",
            "govtrack_id": "456881",
            "cspan_id": null,
            "votesmart_id": "169303",
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1985-01-30",
            "leadership_role": null,
            "fec_candidate_id": "H2CA03157",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 3,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.28,
            "votes_with_party_pct": 83.33,
            "votes_against_party_pct": 14.73,
            "ocd_id": "ocd-division/country:us/state:ca/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-03--K000401": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "L000578",
            "in_office": true,
            "firstName": "Doug",
            "lastName": "LaMalfa",
            "middleName": null,
            "fullName": "Doug LaMalfa",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/L000578.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-CA-01--L000578",
              "builtID": "ca--01",
              "externalID": "L000578",
              "geometry": null,
              "geoid": "0601"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "408 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3076",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLaMalfa",
              "youtube": "RepLaMalfa",
              "instagram": null,
              "facebook": "RepLaMalfa",
              "urls": ["https://lamalfa.house.gov"],
              "rss_url": "https://lamalfa.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000578.json",
            "govtrack_id": "412510",
            "cspan_id": "68493",
            "votesmart_id": "29713",
            "icpsr_id": "21302",
            "crp_id": "N00033987",
            "google_entity_id": "/m/0288tnx",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1960-07-02",
            "leadership_role": null,
            "fec_candidate_id": "H2CA02142",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 75,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 7.11,
            "votes_with_party_pct": 92.09,
            "votes_against_party_pct": 5.93,
            "ocd_id": "ocd-division/country:us/state:ca/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-01--L000578": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "M001163",
            "in_office": true,
            "firstName": "Doris",
            "lastName": "Matsui",
            "middleName": null,
            "fullName": "Doris O. Matsui",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M001163.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-CA-07--M001163",
              "builtID": "ca--07",
              "externalID": "M001163",
              "geometry": null,
              "geoid": "0607"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2311 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-7163",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "DorisMatsui",
              "youtube": "RepDorisMatsui",
              "instagram": null,
              "facebook": "doris.matsui",
              "urls": ["https://matsui.house.gov"],
              "rss_url": null
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001163.json",
            "govtrack_id": "400663",
            "cspan_id": "26602",
            "votesmart_id": "28593",
            "icpsr_id": "20538",
            "crp_id": "N00027459",
            "google_entity_id": "/m/059hdf",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1944-09-25",
            "leadership_role": null,
            "fec_candidate_id": "H6CA05195",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "20",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 30,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.84,
            "votes_with_party_pct": 96.75,
            "votes_against_party_pct": 1.38,
            "ocd_id": "ocd-division/country:us/state:ca/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-07--M001163": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "M001177",
            "in_office": true,
            "firstName": "Tom",
            "lastName": "McClintock",
            "middleName": null,
            "fullName": "Tom McClintock",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001177.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-CA-05--M001177",
              "builtID": "ca--05",
              "externalID": "M001177",
              "geometry": null,
              "geoid": "0605"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2256 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2511",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMcClintock",
              "youtube": "McClintockCA04",
              "instagram": null,
              "facebook": "81125319109",
              "urls": ["https://mcclintock.house.gov"],
              "rss_url": "https://mcclintock.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001177.json",
            "govtrack_id": "412295",
            "cspan_id": "30359",
            "votesmart_id": "9715",
            "icpsr_id": "20903",
            "crp_id": "N00006863",
            "google_entity_id": "/m/01r6jp",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1956-07-10",
            "leadership_role": null,
            "fec_candidate_id": "H8CA04152",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "16",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 20,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.9,
            "votes_with_party_pct": 87.7,
            "votes_against_party_pct": 10.43,
            "ocd_id": "ocd-division/country:us/state:ca/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-05--M001177": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "T000460",
            "in_office": true,
            "firstName": "Mike",
            "lastName": "Thompson",
            "middleName": null,
            "fullName": "Mike Thompson",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/T000460.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-CA-04--T000460",
              "builtID": "ca--04",
              "externalID": "T000460",
              "geometry": null,
              "geoid": "0604"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "268 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3311",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepThompson",
              "youtube": "CongressmanMThompson",
              "instagram": null,
              "facebook": "RepMikeThompson",
              "urls": ["https://mikethompson.house.gov"],
              "rss_url": "https://mikethompson.house.gov/rss.xml"
            },
            "title": "CA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000460.json",
            "govtrack_id": "400403",
            "cspan_id": "57872",
            "votesmart_id": "3564",
            "icpsr_id": "29901",
            "crp_id": "N00007419",
            "google_entity_id": "/m/024sdl",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1951-01-24",
            "leadership_role": null,
            "fec_candidate_id": "H8CA01109",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "26",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 7,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.66,
            "votes_with_party_pct": 96.34,
            "votes_against_party_pct": 1.83,
            "ocd_id": "ocd-division/country:us/state:ca/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CA-04--T000460": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/1509d608-4d2b-4ebc-be73-b4cd6c06b7c6",
            "firstName": "Megan Elizabeth",
            "lastName": "Dahle",
            "fullName": "Megan Dahle",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/mdahle-ad01.gif",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-CA-1-lower-ocd-person-1509d608-4d2b-4ebc-be73-b4cd6c06b7c6",
              "builtID": "ca-lower-1",
              "externalID": "ocd-person/1509d608-4d2b-4ebc-be73-b4cd6c06b7c6",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.dahle@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "930 Executive Way Suite 201, Redding, CA 96002",
              "phone1": null,
              "phone2": "530-223-6300",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ad01.asmrc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-1-lower-ocd-person-1509d608-4d2b-4ebc-be73-b4cd6c06b7c6": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/798893c7-4969-4b39-b9d3-f920806424f3",
            "firstName": "Jim",
            "lastName": "Wood",
            "fullName": "Jim Wood",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad-02_wood_ad2_028_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-CA-2-lower-ocd-person-798893c7-4969-4b39-b9d3-f920806424f3",
              "builtID": "ca-lower-2",
              "externalID": "ocd-person/798893c7-4969-4b39-b9d3-f920806424f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.wood@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "50 D St. Suite 450, Santa Rosa, CA 95404",
              "phone1": null,
              "phone2": "707-576-2526",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a02.asmdc.org", "https://a02.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-2-lower-ocd-person-798893c7-4969-4b39-b9d3-f920806424f3": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/5d93a1cb-7a01-43ab-a26c-a2abecdee135",
            "firstName": "James",
            "lastName": "Gallagher",
            "fullName": "James Gallagher",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/Gallagher_LDC_headshot.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-CA-3-lower-ocd-person-5d93a1cb-7a01-43ab-a26c-a2abecdee135",
              "builtID": "ca-lower-3",
              "externalID": "ocd-person/5d93a1cb-7a01-43ab-a26c-a2abecdee135",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.gallagher@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2060 Talbert Drive Suite 110, Chico, CA 95928",
              "phone1": null,
              "phone2": "530-895-4217",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ad03.asmrc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-3-lower-ocd-person-5d93a1cb-7a01-43ab-a26c-a2abecdee135": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/434bcfb4-1279-40cc-916b-f89af5805a9c",
            "firstName": "Cecilia M.",
            "lastName": "Aguiar-Curry",
            "fullName": "Cecilia Aguiar-Curry",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad-04_aguiar-curry_c18_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-CA-4-lower-ocd-person-434bcfb4-1279-40cc-916b-f89af5805a9c",
              "builtID": "ca-lower-4",
              "externalID": "ocd-person/434bcfb4-1279-40cc-916b-f89af5805a9c",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.aguiar-curry@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "600 A St. Suite D, Davis, CA 95616",
              "phone1": null,
              "phone2": "530-757-1034",
              "fax1": null,
              "fax2": "530-757-1174",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a04.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-4-lower-ocd-person-434bcfb4-1279-40cc-916b-f89af5805a9c": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/c7a404d9-03f7-4e82-b730-1623da31d4cc",
            "firstName": "Joe",
            "lastName": "Patterson",
            "fullName": "Joe Patterson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/patterson_a25i1438_resized.png",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-CA-5-lower-ocd-person-c7a404d9-03f7-4e82-b730-1623da31d4cc",
              "builtID": "ca-lower-5",
              "externalID": "ocd-person/c7a404d9-03f7-4e82-b730-1623da31d4cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.joepatterson@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Rocklin Corporate Plaza 6030 W. Oaks Blvd., Suite 145, Rocklin, CA 95765",
              "phone1": null,
              "phone2": "916-435-0501",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ad05.asmrc.org", "https://ad05.asmrc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-5-lower-ocd-person-c7a404d9-03f7-4e82-b730-1623da31d4cc": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/723bd312-e1c3-490c-9a8c-65388f834092",
            "firstName": "Kevin",
            "lastName": "McCarty",
            "fullName": "Kevin McCarty",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/AD07_McCarty_Portrait150_20141201.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-CA-6-lower-ocd-person-723bd312-e1c3-490c-9a8c-65388f834092",
              "builtID": "ca-lower-6",
              "externalID": "ocd-person/723bd312-e1c3-490c-9a8c-65388f834092",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.mccarty@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "915 L St. Suite 110, Sacramento, CA 95814",
              "phone1": null,
              "phone2": "916-324-4676",
              "fax1": null,
              "fax2": "916-327-3338",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a07.asmdc.org", "https://a06.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-6-lower-ocd-person-723bd312-e1c3-490c-9a8c-65388f834092": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/71aa54c0-3fad-499c-928e-ffbb692a970b",
            "firstName": "Joshua Ryan",
            "lastName": "Hoover",
            "fullName": "Joshua Hoover",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-CA-7-lower-ocd-person-71aa54c0-3fad-499c-928e-ffbb692a970b",
              "builtID": "ca-lower-7",
              "externalID": "ocd-person/71aa54c0-3fad-499c-928e-ffbb692a970b",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.hoover@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "13405 Folsom Blvd., Building 700 Suite A, Folsom, CA 95630",
              "phone1": null,
              "phone2": "916-294-9774",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ad07.asmrc.org", "https://ad07.asmrc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-7-lower-ocd-person-71aa54c0-3fad-499c-928e-ffbb692a970b": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/2cb9d19b-360d-4568-a781-b54a483b081e",
            "firstName": "James Norwood",
            "lastName": "Patterson",
            "fullName": "Jim Patterson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/AD23-Jim_Patterson.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-CA-8-lower-ocd-person-2cb9d19b-360d-4568-a781-b54a483b081e",
              "builtID": "ca-lower-8",
              "externalID": "ocd-person/2cb9d19b-360d-4568-a781-b54a483b081e",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.patterson@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6245 N. Fresno St. Suite 106, Fresno, CA 93710",
              "phone1": null,
              "phone2": "559-446-2029",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ad23.asmrc.org/", "https://ad08.asmrc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-8-lower-ocd-person-2cb9d19b-360d-4568-a781-b54a483b081e": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/c830a90c-8964-4c0f-b31b-729cab1144fd",
            "firstName": "Heath Hubert",
            "lastName": "Flora",
            "fullName": "Heath Flora",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/flora.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-CA-9-lower-ocd-person-c830a90c-8964-4c0f-b31b-729cab1144fd",
              "builtID": "ca-lower-9",
              "externalID": "ocd-person/c830a90c-8964-4c0f-b31b-729cab1144fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.flora@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "578 N. Wilma Ave. Suite B, Ripon, CA 95366",
              "phone1": null,
              "phone2": "209-599-2112",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ad12.asmrc.org", "https://ad09.asmrc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-9-lower-ocd-person-c830a90c-8964-4c0f-b31b-729cab1144fd": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/62102f53-b531-4290-9699-05a0d7687cae",
            "firstName": "Stephanie",
            "lastName": "Nguyen",
            "fullName": "Stephanie Nguyen",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/nguyen_515_11-30-22_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-CA-10-lower-ocd-person-62102f53-b531-4290-9699-05a0d7687cae",
              "builtID": "ca-lower-10",
              "externalID": "ocd-person/62102f53-b531-4290-9699-05a0d7687cae",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.stephanienguyen@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 220, 9250 Laguna Springs Drive, Elk Grove, CA 95758",
              "phone1": null,
              "phone2": "916-670-7888",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a10.asmdc.org", "https://a10.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-10-lower-ocd-person-62102f53-b531-4290-9699-05a0d7687cae": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/926d1337-0318-4c12-a291-178f10199b23",
            "firstName": "Lori D.",
            "lastName": "Wilson",
            "fullName": "Lori Wilson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad11-wilson_006_portraits_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-CA-11-lower-ocd-person-926d1337-0318-4c12-a291-178f10199b23",
              "builtID": "ca-lower-11",
              "externalID": "ocd-person/926d1337-0318-4c12-a291-178f10199b23",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.wilson@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1 Harbor Center Suite 270, Suisun City, CA 94585",
              "phone1": null,
              "phone2": "707-438-7359",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a11.asmdc.org", "https://a11.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-11-lower-ocd-person-926d1337-0318-4c12-a291-178f10199b23": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/32e06e60-77ac-4c5e-af1e-2110b602dc97",
            "firstName": "Damon",
            "lastName": "Connolly",
            "fullName": "Damon Connolly",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/connolly_509_11-30-22_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-CA-12-lower-ocd-person-32e06e60-77ac-4c5e-af1e-2110b602dc97",
              "builtID": "ca-lower-12",
              "externalID": "ocd-person/32e06e60-77ac-4c5e-af1e-2110b602dc97",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.connolly@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 412, County of Marin Civic Center Building 3501 Civic Center Drive, San Rafael, CA 94903",
              "phone1": null,
              "phone2": "415-479-4920",
              "fax1": null,
              "fax2": "415-479-2123",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a12.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-12-lower-ocd-person-32e06e60-77ac-4c5e-af1e-2110b602dc97": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/2ebacc3c-fd77-4289-b106-789f4e2c2f87",
            "firstName": "Carlos",
            "lastName": "Villapudua",
            "fullName": "Carlos Villapudua",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/villapudua_approved_headshot_2022.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-CA-13-lower-ocd-person-2ebacc3c-fd77-4289-b106-789f4e2c2f87",
              "builtID": "ca-lower-13",
              "externalID": "ocd-person/2ebacc3c-fd77-4289-b106-789f4e2c2f87",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.villapudua@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4643 Quail Lakes Drive Suite 200, Stockton, CA 95207",
              "phone1": null,
              "phone2": "209-948-7479",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a13.asmdc.org", "https://a13.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-13-lower-ocd-person-2ebacc3c-fd77-4289-b106-789f4e2c2f87": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/8230907e-9ae3-4ae9-b52a-c8464a157f64",
            "firstName": "Buffy Jo Christina",
            "lastName": "Wicks",
            "fullName": "Buffy Wicks",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/a15_b_wicks.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-CA-14-lower-ocd-person-8230907e-9ae3-4ae9-b52a-c8464a157f64",
              "builtID": "ca-lower-14",
              "externalID": "ocd-person/8230907e-9ae3-4ae9-b52a-c8464a157f64",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.wicks@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1515 Clay St. Suite 2201, Oakland, CA 94612",
              "phone1": null,
              "phone2": "510-286-1400",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://a15.asmdc.org",
                "https://a14.asmdc.org",
                "https://a14.asmdc.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-14-lower-ocd-person-8230907e-9ae3-4ae9-b52a-c8464a157f64": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/972cffb0-32a6-4cef-8a0f-a8cd901395b0",
            "firstName": "Timothy S.",
            "lastName": "Grayson",
            "fullName": "Tim Grayson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad14_header.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-CA-15-lower-ocd-person-972cffb0-32a6-4cef-8a0f-a8cd901395b0",
              "builtID": "ca-lower-15",
              "externalID": "ocd-person/972cffb0-32a6-4cef-8a0f-a8cd901395b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.grayson@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2151 Salvio St. Suite P, Concord, CA 94520",
              "phone1": null,
              "phone2": "925-521-1511",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://a14.asmdc.org",
                "https://a15.asmdc.org",
                "https://a15.asmdc.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-15-lower-ocd-person-972cffb0-32a6-4cef-8a0f-a8cd901395b0": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/2918acca-40a2-4819-a79a-b2873c7895bc",
            "firstName": "Rebecca",
            "lastName": "Bauer-Kahan",
            "fullName": "Rebecca Bauer-Kahan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/a16_r_b_kahan.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-CA-16-lower-ocd-person-2918acca-40a2-4819-a79a-b2873c7895bc",
              "builtID": "ca-lower-16",
              "externalID": "ocd-person/2918acca-40a2-4819-a79a-b2873c7895bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.bauer-kahan@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "12677 Alcosta Blvd. Suite 395, San Ramon, CA 94583",
              "phone1": null,
              "phone2": "925-244-1600",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a16.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-16-lower-ocd-person-2918acca-40a2-4819-a79a-b2873c7895bc": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/cc6eb833-e16b-472a-83ec-31e7df1b1cd9",
            "firstName": "Matthew Craig",
            "lastName": "Haney",
            "fullName": "Matt Haney",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad17_haney.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-CA-17-lower-ocd-person-cc6eb833-e16b-472a-83ec-31e7df1b1cd9",
              "builtID": "ca-lower-17",
              "externalID": "ocd-person/cc6eb833-e16b-472a-83ec-31e7df1b1cd9",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.haney@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "455 Golden Gate Ave. Suite 14300, San Francisco, CA 94102",
              "phone1": null,
              "phone2": "415-557-3013",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a17.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-17-lower-ocd-person-cc6eb833-e16b-472a-83ec-31e7df1b1cd9": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/a467d09d-289d-4e5a-a2af-a0ce20af14e1",
            "firstName": "Mialisa Tania",
            "lastName": "Bonta",
            "fullName": "Mia Bonta",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad-18_bonta_m._portraits_edit_155_11-5-21_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-CA-18-lower-ocd-person-a467d09d-289d-4e5a-a2af-a0ce20af14e1",
              "builtID": "ca-lower-18",
              "externalID": "ocd-person/a467d09d-289d-4e5a-a2af-a0ce20af14e1",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.mbonta@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1515 Clay St. Suite 2204, Oakland, CA 94612",
              "phone1": null,
              "phone2": "510-286-1670",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a18.asmdc.org", "https://a18.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-18-lower-ocd-person-a467d09d-289d-4e5a-a2af-a0ce20af14e1": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/bf88659d-b2dd-462e-84b3-82ead47ff360",
            "firstName": "Philip Yu-Li",
            "lastName": "Ting",
            "fullName": "Phil Ting",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad19-ting_0.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-CA-19-lower-ocd-person-bf88659d-b2dd-462e-84b3-82ead47ff360",
              "builtID": "ca-lower-19",
              "externalID": "ocd-person/bf88659d-b2dd-462e-84b3-82ead47ff360",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.ting@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "455 Golden Gate Ave. Suite 14600, San Francisco, CA 94102",
              "phone1": null,
              "phone2": "415-557-2312",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a19.asmdc.org", "https://a19.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-19-lower-ocd-person-bf88659d-b2dd-462e-84b3-82ead47ff360": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/54e48d10-22e1-43da-b114-c1d3f741faff",
            "firstName": "Elizabeth",
            "lastName": "Ortega-Toro",
            "fullName": "Liz Ortega",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ortega_004_11-30-22_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-CA-20-lower-ocd-person-54e48d10-22e1-43da-b114-c1d3f741faff",
              "builtID": "ca-lower-20",
              "externalID": "ocd-person/54e48d10-22e1-43da-b114-c1d3f741faff",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.ortega@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "22320 Foothill Blvd., Suite 540, Hayward, CA 94541",
              "phone1": null,
              "phone2": "510-583-8818",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a20.asmdc.org", "https://a20.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-20-lower-ocd-person-54e48d10-22e1-43da-b114-c1d3f741faff": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/20c72bef-f518-4677-bbcb-83c502c36ede",
            "firstName": "Diane",
            "lastName": "Papan",
            "fullName": "Diane Papan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/papan_002_11-30-22_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-CA-21-lower-ocd-person-20c72bef-f518-4677-bbcb-83c502c36ede",
              "builtID": "ca-lower-21",
              "externalID": "ocd-person/20c72bef-f518-4677-bbcb-83c502c36ede",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.papan@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1528 S. El Camino Real, Suite 302, San Mateo, CA 94402",
              "phone1": null,
              "phone2": "650-349-2200",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a21.asmdc.org", "https://a21.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-21-lower-ocd-person-20c72bef-f518-4677-bbcb-83c502c36ede": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/dfb00d6c-1d0d-410f-8a06-d54841495a62",
            "firstName": "Juan",
            "lastName": "Alanis",
            "fullName": "Juan Alanis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/alanis_a25i1397_resized.png",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-CA-22-lower-ocd-person-dfb00d6c-1d0d-410f-8a06-d54841495a62",
              "builtID": "ca-lower-22",
              "externalID": "ocd-person/dfb00d6c-1d0d-410f-8a06-d54841495a62",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.alanis@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1010 10th St., Suite 5800, Modesto, CA 95354",
              "phone1": null,
              "phone2": "209-521-2201",
              "fax1": null,
              "fax2": "209-521-2209",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ad22.asmrc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-22-lower-ocd-person-dfb00d6c-1d0d-410f-8a06-d54841495a62": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/24facf9e-7fb1-4475-9120-05cd0145c7c1",
            "firstName": "Marc",
            "lastName": "Berman",
            "fullName": "Marc Berman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad24_berman_roster150_20161205.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-CA-23-lower-ocd-person-24facf9e-7fb1-4475-9120-05cd0145c7c1",
              "builtID": "ca-lower-23",
              "externalID": "ocd-person/24facf9e-7fb1-4475-9120-05cd0145c7c1",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.berman@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "721 Colorado Ave. Suite 101, Palo Alto, CA 94303",
              "phone1": null,
              "phone2": "650-324-0224",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a24.asmdc.org/", "https://a23.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-23-lower-ocd-person-24facf9e-7fb1-4475-9120-05cd0145c7c1": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/5d0057a2-e04d-44ca-b1d5-fc55e113351a",
            "firstName": "Alex Tinming",
            "lastName": "Lee",
            "fullName": "Alex Lee",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/lee-025.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-CA-24-lower-ocd-person-5d0057a2-e04d-44ca-b1d5-fc55e113351a",
              "builtID": "ca-lower-24",
              "externalID": "ocd-person/5d0057a2-e04d-44ca-b1d5-fc55e113351a",
              "geometry": null
            },
            "contactInfo": {
              "email": "alex.lee@asm.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1313 N. Milpitas Blvd. Suite 255, Milpitas, CA 95035",
              "phone1": null,
              "phone2": "408-262-2501",
              "fax1": null,
              "fax2": "408-262-2512",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a25.asmdc.org/", "https://a24.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-24-lower-ocd-person-5d0057a2-e04d-44ca-b1d5-fc55e113351a": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/820619fd-d053-4915-b099-1d134a880228",
            "firstName": "Ash",
            "lastName": "Kalra",
            "fullName": "Ash Kalra",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad27_karla_roster150_20161205.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-CA-25-lower-ocd-person-820619fd-d053-4915-b099-1d134a880228",
              "builtID": "ca-lower-25",
              "externalID": "ocd-person/820619fd-d053-4915-b099-1d134a880228",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.kalra@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "111 W. Saint John St. Suite 1150, San Jose, CA 95113",
              "phone1": null,
              "phone2": "408-286-2535",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a27.asmdc.org/", "https://a25.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-25-lower-ocd-person-820619fd-d053-4915-b099-1d134a880228": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/b6e6e287-12e9-4067-a607-28bf5c481935",
            "firstName": "Evan",
            "lastName": "Low",
            "fullName": "Evan Low",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad28-low.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-CA-26-lower-ocd-person-b6e6e287-12e9-4067-a607-28bf5c481935",
              "builtID": "ca-lower-26",
              "externalID": "ocd-person/b6e6e287-12e9-4067-a607-28bf5c481935",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.low@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "20111 Stevens Creek Blvd. Suite 220, Cupertino, CA 95014",
              "phone1": null,
              "phone2": "408-446-2810",
              "fax1": null,
              "fax2": "408-446-2815",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a28.asmdc.org/", "https://a26.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-26-lower-ocd-person-b6e6e287-12e9-4067-a607-28bf5c481935": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/d2a8e29a-5eac-4e2a-a686-d1bdb04927e7",
            "firstName": "Esmeralda",
            "lastName": "Zamudio Soria",
            "fullName": "Esmeralda Soria",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/soria_025_edit_11-29-22_resized_0.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-CA-27-lower-ocd-person-d2a8e29a-5eac-4e2a-a686-d1bdb04927e7",
              "builtID": "ca-lower-27",
              "externalID": "ocd-person/d2a8e29a-5eac-4e2a-a686-d1bdb04927e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.soria@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "512 W. 18th St. Suite 512, Merced, CA 95340",
              "phone1": null,
              "phone2": "209-726-5465",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a27.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-27-lower-ocd-person-d2a8e29a-5eac-4e2a-a686-d1bdb04927e7": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/2944d19a-c288-4f32-b6a0-72e35f48e570",
            "firstName": "Gail",
            "lastName": "Pellerin",
            "fullName": "Gail Pellerin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/pellerin_502_11-30-22_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-CA-28-lower-ocd-person-2944d19a-c288-4f32-b6a0-72e35f48e570",
              "builtID": "ca-lower-28",
              "externalID": "ocd-person/2944d19a-c288-4f32-b6a0-72e35f48e570",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.pellerin@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 318B, 701 Ocean St., Santa Cruz, CA 95060",
              "phone1": null,
              "phone2": "831-425-1503",
              "fax1": null,
              "fax2": "831-425-2570",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a28.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-28-lower-ocd-person-2944d19a-c288-4f32-b6a0-72e35f48e570": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/68f5ee30-aa70-489b-84b7-6501e6188751",
            "firstName": "Robert A.",
            "lastName": "Rivas",
            "fullName": "Robert Rivas",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad-29_rivas_r19_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-CA-29-lower-ocd-person-68f5ee30-aa70-489b-84b7-6501e6188751",
              "builtID": "ca-lower-29",
              "externalID": "ocd-person/68f5ee30-aa70-489b-84b7-6501e6188751",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.robertrivas@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "60 W. Market St. Suite 110, Salinas, CA 93901",
              "phone1": null,
              "phone2": "831-759-8676",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://a30.asmdc.org/",
                "https://a29.asmdc.org/",
                "https://speaker.asmdc.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-29-lower-ocd-person-68f5ee30-aa70-489b-84b7-6501e6188751": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/eddfcfb5-2d07-4e5b-81a0-88e5703dc0bb",
            "firstName": "Dawn",
            "lastName": "Addis",
            "fullName": "Dawn Addis",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/addis_003_11-30-22_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-CA-30-lower-ocd-person-eddfcfb5-2d07-4e5b-81a0-88e5703dc0bb",
              "builtID": "ca-lower-30",
              "externalID": "ocd-person/eddfcfb5-2d07-4e5b-81a0-88e5703dc0bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "dawn.addis@asm.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "99 Pacific St. Suite 575G, Monterey, CA 93940",
              "phone1": null,
              "phone2": "831-649-2832",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a30.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-30-lower-ocd-person-eddfcfb5-2d07-4e5b-81a0-88e5703dc0bb": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/e2da0f08-1bd8-4ac2-95f1-002870238527",
            "firstName": "Joaquin",
            "lastName": "Arambula",
            "fullName": "Joaquin Arambula",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/asm-arambula.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-CA-31-lower-ocd-person-e2da0f08-1bd8-4ac2-95f1-002870238527",
              "builtID": "ca-lower-31",
              "externalID": "ocd-person/e2da0f08-1bd8-4ac2-95f1-002870238527",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.arambula@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Hugh Burns State Building 2550 Mariposa Mall, Suite 5031, Fresno, CA 93721",
              "phone1": null,
              "phone2": "559-445-5532",
              "fax1": null,
              "fax2": "559-445-6006",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a31.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-31-lower-ocd-person-e2da0f08-1bd8-4ac2-95f1-002870238527": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/3ffead8c-6856-4592-8594-182e295b8e87",
            "firstName": "Devon John",
            "lastName": "Mathis",
            "fullName": "Devon Mathis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/mathis.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-CA-33-lower-ocd-person-3ffead8c-6856-4592-8594-182e295b8e87",
              "builtID": "ca-lower-33",
              "externalID": "ocd-person/3ffead8c-6856-4592-8594-182e295b8e87",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.mathis@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "100 W. Willow Plaza Suite 405, Visalia, CA 93291",
              "phone1": null,
              "phone2": "559-636-3440",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ad26.asmrc.org/", "https://ad33.asmrc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-33-lower-ocd-person-3ffead8c-6856-4592-8594-182e295b8e87": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/a8879d70-713c-4d0c-b723-77d0e96c18df",
            "firstName": "Thomas W.",
            "lastName": "Lackey",
            "fullName": "Tom Lackey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/lackey.png",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-CA-34-lower-ocd-person-a8879d70-713c-4d0c-b723-77d0e96c18df",
              "builtID": "ca-lower-34",
              "externalID": "ocd-person/a8879d70-713c-4d0c-b723-77d0e96c18df",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.lackey@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "41301 W. 12th St. Suite F, Palmdale, CA 93551",
              "phone1": null,
              "phone2": "661-267-7636",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ad36.asmrc.org/", "https://ad34.asmrc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-34-lower-ocd-person-a8879d70-713c-4d0c-b723-77d0e96c18df": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/b08199e4-63d9-4bd4-9be3-99e628fa7059",
            "firstName": "Jasmeet",
            "lastName": "Bains",
            "fullName": "Jasmeet Bains",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-CA-35-lower-ocd-person-b08199e4-63d9-4bd4-9be3-99e628fa7059",
              "builtID": "ca-lower-35",
              "externalID": "ocd-person/b08199e4-63d9-4bd4-9be3-99e628fa7059",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.bains@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1430 Truxtun Ave. Suite 803, Bakersfield, CA 93301",
              "phone1": null,
              "phone2": "661-335-0302",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a35.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-35-lower-ocd-person-b08199e4-63d9-4bd4-9be3-99e628fa7059": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/c4f4c9c1-1f1b-4cb6-854b-434c53495006",
            "firstName": "Eduardo",
            "lastName": "Garcia",
            "fullName": "Eduardo Garcia",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/AD56_Garcia_Portrait150_20141201.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-CA-36-lower-ocd-person-c4f4c9c1-1f1b-4cb6-854b-434c53495006",
              "builtID": "ca-lower-36",
              "externalID": "ocd-person/c4f4c9c1-1f1b-4cb6-854b-434c53495006",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.eduardogarcia@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "48220 Jackson St. Suite A3, Coachella, CA 92236",
              "phone1": null,
              "phone2": "760-347-2360",
              "fax1": null,
              "fax2": "760-347-5704",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a56.asmdc.org/", "https://a36.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-36-lower-ocd-person-c4f4c9c1-1f1b-4cb6-854b-434c53495006": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/57f76b8e-63ba-4aa7-93c9-0b0853ce2279",
            "firstName": "Gregg",
            "lastName": "Hart",
            "fullName": "Gregg Hart",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/hart_027_11-30-22_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-CA-37-lower-ocd-person-57f76b8e-63ba-4aa7-93c9-0b0853ce2279",
              "builtID": "ca-lower-37",
              "externalID": "ocd-person/57f76b8e-63ba-4aa7-93c9-0b0853ce2279",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.hart@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1111 S. Broadway, Suite 101, Santa Maria, CA 93454",
              "phone1": null,
              "phone2": "805-346-1237",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a37.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-37-lower-ocd-person-57f76b8e-63ba-4aa7-93c9-0b0853ce2279": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/1e0ccfc0-2d57-4e57-8d16-589d07c1bc72",
            "firstName": "Steve",
            "lastName": "Bennett",
            "fullName": "Steve Bennett",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/official_portrait_0.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-CA-38-lower-ocd-person-1e0ccfc0-2d57-4e57-8d16-589d07c1bc72",
              "builtID": "ca-lower-38",
              "externalID": "ocd-person/1e0ccfc0-2d57-4e57-8d16-589d07c1bc72",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.bennett@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "300 E. Esplanade Drive Suite 1790, Oxnard, CA 93036",
              "phone1": null,
              "phone2": "805-485-4745",
              "fax1": null,
              "fax2": "805-485-4779",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a37.asmdc.org/", "https://a38.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-38-lower-ocd-person-1e0ccfc0-2d57-4e57-8d16-589d07c1bc72": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/092bda0e-23a9-4d25-92a9-5808e996702b",
            "firstName": "Juan",
            "lastName": "Carrillo",
            "fullName": "Juan Carrillo",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/carrillo_001_11-30-29_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-CA-39-lower-ocd-person-092bda0e-23a9-4d25-92a9-5808e996702b",
              "builtID": "ca-lower-39",
              "externalID": "ocd-person/092bda0e-23a9-4d25-92a9-5808e996702b",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.juancarrillo@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "823 East Ave. Q-9 Suite B, Palmdale, CA 93550",
              "phone1": null,
              "phone2": "661-266-3908",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a39.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-39-lower-ocd-person-092bda0e-23a9-4d25-92a9-5808e996702b": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/d12d3275-2086-4bf5-886b-c4de3823fbf9",
            "firstName": "Pilar",
            "lastName": "Schiavo",
            "fullName": "Pilar Schiavo",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/schiavo_030_11-29-22_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-CA-40-lower-ocd-person-d12d3275-2086-4bf5-886b-c4de3823fbf9",
              "builtID": "ca-lower-40",
              "externalID": "ocd-person/d12d3275-2086-4bf5-886b-c4de3823fbf9",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.schiavo@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "27441 Tourney Road Suite 240, Santa Clarita, CA 91355",
              "phone1": null,
              "phone2": "661-286-1565",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a40.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-40-lower-ocd-person-d12d3275-2086-4bf5-886b-c4de3823fbf9": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/154c4712-4a1f-4a57-bd37-b75ee5bfbab5",
            "firstName": "Christopher R.",
            "lastName": "Holden",
            "fullName": "Chris Holden",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/AD41-Holden.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-CA-41-lower-ocd-person-154c4712-4a1f-4a57-bd37-b75ee5bfbab5",
              "builtID": "ca-lower-41",
              "externalID": "ocd-person/154c4712-4a1f-4a57-bd37-b75ee5bfbab5",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.holden@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "600 N. Rosemead Blvd. Suite 117, Pasadena, CA 91107",
              "phone1": null,
              "phone2": "626-351-1917",
              "fax1": null,
              "fax2": "626-351-6176",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a41.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-41-lower-ocd-person-154c4712-4a1f-4a57-bd37-b75ee5bfbab5": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/e7ad99fd-fda5-4114-af3e-320f5be9be3c",
            "firstName": "Jacqui Van Egmond",
            "lastName": "Irwin",
            "fullName": "Jacqui Irwin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/AD44_Irwin_Portrait150_20141201.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-CA-42-lower-ocd-person-e7ad99fd-fda5-4114-af3e-320f5be9be3c",
              "builtID": "ca-lower-42",
              "externalID": "ocd-person/e7ad99fd-fda5-4114-af3e-320f5be9be3c",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.irwin@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "The Atrium Building 860 Via De La Paz, Suite E3-D, Pacific Palisades,, CA 90272",
              "phone1": null,
              "phone2": "424-238-2421",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a44.asmdc.org/", "https://a42.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-42-lower-ocd-person-e7ad99fd-fda5-4114-af3e-320f5be9be3c": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/db75b62c-0713-4075-89cb-1437a9648b00",
            "firstName": "Luz Maria",
            "lastName": "Rivas",
            "fullName": "Luz Rivas",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/a39_l_rivas.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-CA-43-lower-ocd-person-db75b62c-0713-4075-89cb-1437a9648b00",
              "builtID": "ca-lower-43",
              "externalID": "ocd-person/db75b62c-0713-4075-89cb-1437a9648b00",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.rivas@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "9300 Laurel Canyon Blvd. First Floor, Arleta, CA 91331",
              "phone1": null,
              "phone2": "818-504-3911",
              "fax1": null,
              "fax2": "818-767-3907",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a39.asmdc.org/", "https://a43.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-43-lower-ocd-person-db75b62c-0713-4075-89cb-1437a9648b00": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/e4fdea57-14a2-4fa7-a40c-92cf06c07edd",
            "firstName": "Laura Syril",
            "lastName": "Friedman",
            "fullName": "Laura Friedman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad43_friedman.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-CA-44-lower-ocd-person-e4fdea57-14a2-4fa7-a40c-92cf06c07edd",
              "builtID": "ca-lower-44",
              "externalID": "ocd-person/e4fdea57-14a2-4fa7-a40c-92cf06c07edd",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.friedman@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "300 E. Magnolia Blvd. Suite 504, Burbank, CA 91502",
              "phone1": null,
              "phone2": "818-558-3043",
              "fax1": null,
              "fax2": "818-558-3042",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a43.asmdc.org", "https://a44.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-44-lower-ocd-person-e4fdea57-14a2-4fa7-a40c-92cf06c07edd": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/156660b1-4c3f-45e3-8384-53cda60d7ea5",
            "firstName": "James C.",
            "lastName": "Ramos",
            "fullName": "James Ramos",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/a40_jc_ramos.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-CA-45-lower-ocd-person-156660b1-4c3f-45e3-8384-53cda60d7ea5",
              "builtID": "ca-lower-45",
              "externalID": "ocd-person/156660b1-4c3f-45e3-8384-53cda60d7ea5",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.ramos@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "290 N. D St. Suite 805, San Bernardino, CA 92401",
              "phone1": null,
              "phone2": "909-889-7145",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a40.asmdc.org/", "https://a45.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-45-lower-ocd-person-156660b1-4c3f-45e3-8384-53cda60d7ea5": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/407f6fda-8638-4cca-9704-7aa8df7de26e",
            "firstName": "Jesse Samuel",
            "lastName": "Gabriel",
            "fullName": "Jesse Gabriel",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad-46_gabriel_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-CA-46-lower-ocd-person-407f6fda-8638-4cca-9704-7aa8df7de26e",
              "builtID": "ca-lower-46",
              "externalID": "ocd-person/407f6fda-8638-4cca-9704-7aa8df7de26e",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.gabriel@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "16501 Ventura Blvd. Suite 620, Encino, CA 91436",
              "phone1": null,
              "phone2": "818-380-2460",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a45.asmdc.org/", "https://a46.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-46-lower-ocd-person-407f6fda-8638-4cca-9704-7aa8df7de26e": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/50e55c91-8b18-45ea-a074-bf500e7a299e",
            "firstName": "Gregory Robert",
            "lastName": "Wallis",
            "fullName": "Greg Wallis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/wallis_greg_0.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-CA-47-lower-ocd-person-50e55c91-8b18-45ea-a074-bf500e7a299e",
              "builtID": "ca-lower-47",
              "externalID": "ocd-person/50e55c91-8b18-45ea-a074-bf500e7a299e",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.wallis@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "41-608 Indian Trail Road Suite D-1, Rancho Mirage, CA 92270",
              "phone1": null,
              "phone2": "760-346-6342",
              "fax1": null,
              "fax2": "760-346-6506",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ad47.asmrc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-47-lower-ocd-person-50e55c91-8b18-45ea-a074-bf500e7a299e": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/82cbb6e9-8486-44ec-8450-86d1e700f828",
            "firstName": "Blanca Estela",
            "lastName": "Rubio",
            "fullName": "Blanca Rubio",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/rubio.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-CA-48-lower-ocd-person-82cbb6e9-8486-44ec-8450-86d1e700f828",
              "builtID": "ca-lower-48",
              "externalID": "ocd-person/82cbb6e9-8486-44ec-8450-86d1e700f828",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.rubio@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "100 N. Barranca St. Suite 895, West Covina, CA 91791",
              "phone1": null,
              "phone2": "626-960-4457",
              "fax1": null,
              "fax2": "626-960-1310",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a48.asmdc.org", "https://a48.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-48-lower-ocd-person-82cbb6e9-8486-44ec-8450-86d1e700f828": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/3a7bde9c-8709-49c7-89e4-0835ea1f9eb6",
            "firstName": "Mike",
            "lastName": "Fong",
            "fullName": "Mike Fong",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad-49_fong_m._037_2022_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-CA-49-lower-ocd-person-3a7bde9c-8709-49c7-89e4-0835ea1f9eb6",
              "builtID": "ca-lower-49",
              "externalID": "ocd-person/3a7bde9c-8709-49c7-89e4-0835ea1f9eb6",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.mikefong@asm.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1411 S. Garfield Ave. Suite 305 and 307, Alhambra, CA 91801",
              "phone1": null,
              "phone2": "626-457-4918",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a49.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-49-lower-ocd-person-3a7bde9c-8709-49c7-89e4-0835ea1f9eb6": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/19f694d8-b409-40e5-9f87-d8e3e84afc7f",
            "firstName": "Eloise Gomez",
            "lastName": "Reyes",
            "fullName": "Eloise Reyes",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad-50_reyes_e17_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-CA-50-lower-ocd-person-19f694d8-b409-40e5-9f87-d8e3e84afc7f",
              "builtID": "ca-lower-50",
              "externalID": "ocd-person/19f694d8-b409-40e5-9f87-d8e3e84afc7f",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.gomezreyes@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Santa Clara County, CA",
              "phone1": null,
              "phone2": "909-381-3238",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://a47.asmdc.org",
                "https://a50.asmdc.org",
                "https://a50.asmdc.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-50-lower-ocd-person-19f694d8-b409-40e5-9f87-d8e3e84afc7f": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/6b7e3458-361f-4fce-8a8c-069282db2af7",
            "firstName": "Richard Chavez",
            "lastName": "Zbur",
            "fullName": "Rick Zbur",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/chavez-zbur_510_11-30-22_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-CA-51-lower-ocd-person-6b7e3458-361f-4fce-8a8c-069282db2af7",
              "builtID": "ca-lower-51",
              "externalID": "ocd-person/6b7e3458-361f-4fce-8a8c-069282db2af7",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.zbur@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1445 N. Stanley Ave. Third Floor, Los Angeles, CA 90046",
              "phone1": null,
              "phone2": "323-436-5184",
              "fax1": null,
              "fax2": "323-436-5189",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a51.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-51-lower-ocd-person-6b7e3458-361f-4fce-8a8c-069282db2af7": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/70b66c63-35f1-416d-9816-79d5752481a6",
            "firstName": "Wendy Maria",
            "lastName": "Carrillo Dono",
            "fullName": "Wendy Carrillo",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad51_carrillo_1.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-CA-52-lower-ocd-person-70b66c63-35f1-416d-9816-79d5752481a6",
              "builtID": "ca-lower-52",
              "externalID": "ocd-person/70b66c63-35f1-416d-9816-79d5752481a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.carrillo@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1910 W. Sunset Blvd. Suite 810, Los Angeles, CA 90026",
              "phone1": null,
              "phone2": "213-483-5252",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a51.asmdc.org/", "https://a52.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-52-lower-ocd-person-70b66c63-35f1-416d-9816-79d5752481a6": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/b1d10c0c-3d3d-4ae1-8c7e-02227cd18aa0",
            "firstName": "Freddie",
            "lastName": "Rodriguez",
            "fullName": "Freddie Rodriguez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/AD52_Rodriguez_0.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-CA-53-lower-ocd-person-b1d10c0c-3d3d-4ae1-8c7e-02227cd18aa0",
              "builtID": "ca-lower-53",
              "externalID": "ocd-person/b1d10c0c-3d3d-4ae1-8c7e-02227cd18aa0",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.rodriguez@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "13160 7th St., Chino, CA 91710",
              "phone1": null,
              "phone2": "909-902-9606",
              "fax1": null,
              "fax2": "909-902-9761",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a52.asmdc.org/", "https://a53.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-53-lower-ocd-person-b1d10c0c-3d3d-4ae1-8c7e-02227cd18aa0": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/2e27cd32-daea-4a11-987b-bef3c4068e02",
            "firstName": "Miguel",
            "lastName": "Santiago",
            "fullName": "Miguel Santiago",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/AD53_Santiago_Portrait150_20141201.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-CA-54-lower-ocd-person-2e27cd32-daea-4a11-987b-bef3c4068e02",
              "builtID": "ca-lower-54",
              "externalID": "ocd-person/2e27cd32-daea-4a11-987b-bef3c4068e02",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.santiago@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1050, 320 W. 4th St., Los Angeles, CA 90013",
              "phone1": null,
              "phone2": "213-620-4646",
              "fax1": null,
              "fax2": "213-620-6319",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a53.asmdc.org/", "https://a54.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-54-lower-ocd-person-2e27cd32-daea-4a11-987b-bef3c4068e02": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/e9e56c3e-1c7d-4eab-a3ac-35cfc7c6a651",
            "firstName": "Isaac Gregory",
            "lastName": "Bryan",
            "fullName": "Isaac Bryan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/isaacbryan.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-CA-55-lower-ocd-person-e9e56c3e-1c7d-4eab-a3ac-35cfc7c6a651",
              "builtID": "ca-lower-55",
              "externalID": "ocd-person/e9e56c3e-1c7d-4eab-a3ac-35cfc7c6a651",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.bryan@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5601 W. Slauson Ave. Suite 200, Culver City, CA 90230",
              "phone1": null,
              "phone2": "310-641-5410",
              "fax1": null,
              "fax2": "310-641-5415",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://a54.asmdc.org/",
                "https://a54.asmdc.org",
                "https://a55.asmdc.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-55-lower-ocd-person-e9e56c3e-1c7d-4eab-a3ac-35cfc7c6a651": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/ed58ae30-ffe5-4395-9353-141e4018cbe3",
            "firstName": "Lisa Yvette",
            "lastName": "Calderon",
            "fullName": "Lisa Calderon",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/calderon-057.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-CA-56-lower-ocd-person-ed58ae30-ffe5-4395-9353-141e4018cbe3",
              "builtID": "ca-lower-56",
              "externalID": "ocd-person/ed58ae30-ffe5-4395-9353-141e4018cbe3",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.calderon@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "13181 Crossroads Parkway North Suite 160, City of Industry, CA 91746",
              "phone1": null,
              "phone2": "562-692-5858",
              "fax1": null,
              "fax2": "562-695-5852",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a57.asmdc.org/", "https://a56.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-56-lower-ocd-person-ed58ae30-ffe5-4395-9353-141e4018cbe3": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/9f520ee9-8314-4029-8752-e6107f2e734e",
            "firstName": "Reginald Byron",
            "lastName": "Jones-Sawyer",
            "fullName": "Reggie Jones-Sawyer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/jones-sawyer-014-02-06-19.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-CA-57-lower-ocd-person-9f520ee9-8314-4029-8752-e6107f2e734e",
              "builtID": "ca-lower-57",
              "externalID": "ocd-person/9f520ee9-8314-4029-8752-e6107f2e734e",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.jones-sawyer@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "700 Exposition Park Drive, Los Angeles, CA 90037",
              "phone1": null,
              "phone2": "213-744-2111",
              "fax1": null,
              "fax2": "213-744-2122",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a59.asmdc.org/", "https://a57.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-57-lower-ocd-person-9f520ee9-8314-4029-8752-e6107f2e734e": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/61cc77e3-551f-40e6-bb6f-16edd89b04db",
            "firstName": "Sabrina",
            "lastName": "Cervantes",
            "fullName": "Sabrina Cervantes",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad60_cervantes_roster150_20161205.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-CA-58-lower-ocd-person-61cc77e3-551f-40e6-bb6f-16edd89b04db",
              "builtID": "ca-lower-58",
              "externalID": "ocd-person/61cc77e3-551f-40e6-bb6f-16edd89b04db",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.cervantes@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "391 N. Main St. Suite 210, Corona, CA 92878",
              "phone1": null,
              "phone2": "951-371-6860",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a60.asmdc.org/", "https://a58.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-58-lower-ocd-person-61cc77e3-551f-40e6-bb6f-16edd89b04db": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/6ba768a4-2756-42b5-a5c5-f6b8b5db0491",
            "firstName": "Phillip",
            "lastName": "Chen",
            "fullName": "Phillip Chen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/chen.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-CA-59-lower-ocd-person-6ba768a4-2756-42b5-a5c5-f6b8b5db0491",
              "builtID": "ca-lower-59",
              "externalID": "ocd-person/6ba768a4-2756-42b5-a5c5-f6b8b5db0491",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.chen@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3 Pointe Drive Suite #313, Brea, CA 92821",
              "phone1": null,
              "phone2": "714-529-5502",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ad55.asmrc.org",
                "https://ad59.asmrc.org",
                "https://ad59.asmrc.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-59-lower-ocd-person-6ba768a4-2756-42b5-a5c5-f6b8b5db0491": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/e351eaa3-8aa1-4f26-a8ff-2a260a0ad383",
            "firstName": "Corey A.",
            "lastName": "Jackson",
            "fullName": "Corey Jackson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/jackson_027_11-30-22_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-CA-60-lower-ocd-person-e351eaa3-8aa1-4f26-a8ff-2a260a0ad383",
              "builtID": "ca-lower-60",
              "externalID": "ocd-person/e351eaa3-8aa1-4f26-a8ff-2a260a0ad383",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.jackson@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "22690 Cactus Ave. Suite 280, Moreno Valley, CA 92553",
              "phone1": null,
              "phone2": "951-653-0960",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a60.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-60-lower-ocd-person-e351eaa3-8aa1-4f26-a8ff-2a260a0ad383": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/1aa5dff4-3d71-4642-a5e6-e3d00a5b99f9",
            "firstName": "Tina Simone",
            "lastName": "McKinnor",
            "fullName": "Tina McKinnor",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/mckinnon_portrait_web.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-CA-61-lower-ocd-person-1aa5dff4-3d71-4642-a5e6-e3d00a5b99f9",
              "builtID": "ca-lower-61",
              "externalID": "ocd-person/1aa5dff4-3d71-4642-a5e6-e3d00a5b99f9",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.mckinnor@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "One W. Manchester Blvd. Suite 601, Inglewood, CA 90301",
              "phone1": null,
              "phone2": "310-412-6400",
              "fax1": null,
              "fax2": "310-412-6354",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a62.asmdc.org/", "https://a61.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-61-lower-ocd-person-1aa5dff4-3d71-4642-a5e6-e3d00a5b99f9": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/27c957dd-86a1-4bbe-9007-f4ebf931a795",
            "firstName": "Anthony",
            "lastName": "Rendon",
            "fullName": "Anthony Rendon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad63-rendon_0.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-CA-62-lower-ocd-person-27c957dd-86a1-4bbe-9007-f4ebf931a795",
              "builtID": "ca-lower-62",
              "externalID": "ocd-person/27c957dd-86a1-4bbe-9007-f4ebf931a795",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.rendon@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4909 Lakewood Blvd. Suite 400, Lakewood, CA 90712",
              "phone1": null,
              "phone2": "562-529-3250",
              "fax1": null,
              "fax2": "562-529-3255",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://speaker.asmdc.org/", "https://a62.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-62-lower-ocd-person-27c957dd-86a1-4bbe-9007-f4ebf931a795": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/d50e0a21-1f6a-4625-9e76-10adbb654064",
            "firstName": "Bilal Ali",
            "lastName": "Essayli",
            "fullName": "Bill Essayli",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/essayli_a25i1292_resized.png",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-CA-63-lower-ocd-person-d50e0a21-1f6a-4625-9e76-10adbb654064",
              "builtID": "ca-lower-63",
              "externalID": "ocd-person/d50e0a21-1f6a-4625-9e76-10adbb654064",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.essayli@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4160 Temescal Canyon Road Suite 110, Corona, CA 92883",
              "phone1": null,
              "phone2": "951-277-3639",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ad63.asmrc.org", "https://ad63.asmrc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-63-lower-ocd-person-d50e0a21-1f6a-4625-9e76-10adbb654064": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/f2ba8154-6bf3-4e5e-af05-9412e2a189f2",
            "firstName": "Blanca",
            "lastName": "Pacheco",
            "fullName": "Blanca Pacheco",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/pachecho_007_11-30-22_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-CA-64-lower-ocd-person-f2ba8154-6bf3-4e5e-af05-9412e2a189f2",
              "builtID": "ca-lower-64",
              "externalID": "ocd-person/f2ba8154-6bf3-4e5e-af05-9412e2a189f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.pacheco@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "8255 Firestone Blvd., Suite 203, Downey, CA 90241",
              "phone1": null,
              "phone2": "562-861-5803",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a64.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-64-lower-ocd-person-f2ba8154-6bf3-4e5e-af05-9412e2a189f2": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/404dbee4-6b74-4249-ab26-9644cab7c7d5",
            "firstName": "Mike A.",
            "lastName": "Gipson",
            "fullName": "Mike Gipson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/AD64_Gipson_Portrait150_20141201.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-CA-65-lower-ocd-person-404dbee4-6b74-4249-ab26-9644cab7c7d5",
              "builtID": "ca-lower-65",
              "externalID": "ocd-person/404dbee4-6b74-4249-ab26-9644cab7c7d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.gipson@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "879 W. 190th St. Suite 920, Gardena, CA 90248",
              "phone1": null,
              "phone2": "310-324-6408",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a64.asmdc.org/", "https://a65.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-65-lower-ocd-person-404dbee4-6b74-4249-ab26-9644cab7c7d5": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/78610d0e-c790-4fc9-ab8e-0887adbbe063",
            "firstName": "Albert Yasuro",
            "lastName": "Muratsuchi",
            "fullName": "Al Muratsuchi",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad66.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-CA-66-lower-ocd-person-78610d0e-c790-4fc9-ab8e-0887adbbe063",
              "builtID": "ca-lower-66",
              "externalID": "ocd-person/78610d0e-c790-4fc9-ab8e-0887adbbe063",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.muratsuchi@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3424 W. Carson St. Suite 450, Torrance, CA 90503",
              "phone1": null,
              "phone2": "310-375-0691",
              "fax1": null,
              "fax2": "310-375-8245",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a66.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-66-lower-ocd-person-78610d0e-c790-4fc9-ab8e-0887adbbe063": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/840aa009-3ae4-4484-b9f0-5c5f5060e7db",
            "firstName": "Sharon",
            "lastName": "Quirk-Silva",
            "fullName": "Sharon Quirk-Silva",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad65_quirk-silva_siteportrait.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-CA-67-lower-ocd-person-840aa009-3ae4-4484-b9f0-5c5f5060e7db",
              "builtID": "ca-lower-67",
              "externalID": "ocd-person/840aa009-3ae4-4484-b9f0-5c5f5060e7db",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.quirk-silva@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4 Centerpointe Drive Suite 120, La Palma, CA 90623",
              "phone1": null,
              "phone2": "714-521-6713",
              "fax1": null,
              "fax2": "714-521-6718",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a65.asmdc.org/", "https://a67.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-67-lower-ocd-person-840aa009-3ae4-4484-b9f0-5c5f5060e7db": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/87062971-b583-4983-8ac5-4af98bef354d",
            "firstName": "Avelino",
            "lastName": "Valencia",
            "fullName": "Avelino Valencia",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/valencia_518_11-30-22_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-CA-68-lower-ocd-person-87062971-b583-4983-8ac5-4af98bef354d",
              "builtID": "ca-lower-68",
              "externalID": "ocd-person/87062971-b583-4983-8ac5-4af98bef354d",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.valencia@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2400 E. Katella Ave. Suite 640, Anaheim, CA 92806",
              "phone1": null,
              "phone2": "714-939-8469",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a68.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-68-lower-ocd-person-87062971-b583-4983-8ac5-4af98bef354d": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/b20254b0-437a-4d3d-9aa9-baa5db2bc5ef",
            "firstName": "Josh",
            "lastName": "Lowenthal",
            "fullName": "Josh Lowenthal",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/lowenthal_009_11-30-22_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-CA-69-lower-ocd-person-b20254b0-437a-4d3d-9aa9-baa5db2bc5ef",
              "builtID": "ca-lower-69",
              "externalID": "ocd-person/b20254b0-437a-4d3d-9aa9-baa5db2bc5ef",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.lowenthal@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5000 E. Spring St. Suite 550, Long Beach, CA 90815",
              "phone1": null,
              "phone2": "562-429-0470",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a69.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-69-lower-ocd-person-b20254b0-437a-4d3d-9aa9-baa5db2bc5ef": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/75229920-399d-4216-9b76-c27ea398665a",
            "firstName": "Tri D.",
            "lastName": "Ta",
            "fullName": "Tri Ta",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/tri_ta_a25i1353_resized.png",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-CA-70-lower-ocd-person-75229920-399d-4216-9b76-c27ea398665a",
              "builtID": "ca-lower-70",
              "externalID": "ocd-person/75229920-399d-4216-9b76-c27ea398665a",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.ta@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "14361 Beach Blvd. Suite 211, Westminster, CA 92683",
              "phone1": null,
              "phone2": "714-379-0970",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ci.westminster.ca.us/",
                "https://ad70.asmrc.org",
                "https://ad70.asmrc.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-70-lower-ocd-person-75229920-399d-4216-9b76-c27ea398665a": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/76378b56-7ad9-463c-a4fd-47c665b26ee8",
            "firstName": "Kathryn A.",
            "lastName": "Sanchez",
            "fullName": "Kate Sanchez",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/sanchez_a25i1518_resized.png",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-CA-71-lower-ocd-person-76378b56-7ad9-463c-a4fd-47c665b26ee8",
              "builtID": "ca-lower-71",
              "externalID": "ocd-person/76378b56-7ad9-463c-a4fd-47c665b26ee8",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.sanchez@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "24630 Washington Ave. Suite 106, Murrieta, CA 92562",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ad71.asmrc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-71-lower-ocd-person-76378b56-7ad9-463c-a4fd-47c665b26ee8": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/0c4e1b43-c3d7-4a13-87c4-92e29903cb73",
            "firstName": "Diane Brooks",
            "lastName": "Dixon",
            "fullName": "Diane Dixon",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/dixon_a25i1340_resized.png",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-CA-72-lower-ocd-person-0c4e1b43-c3d7-4a13-87c4-92e29903cb73",
              "builtID": "ca-lower-72",
              "externalID": "ocd-person/0c4e1b43-c3d7-4a13-87c4-92e29903cb73",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.dixon@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4100 MacArthur Blvd. Suite 340, Newport Beach, CA 92660",
              "phone1": null,
              "phone2": "949-798-7221",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ad72.asmrc.org", "https://ad72.asmrc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-72-lower-ocd-person-0c4e1b43-c3d7-4a13-87c4-92e29903cb73": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/c766abf8-06ca-4a98-9665-82b87149ca96",
            "firstName": "Catherine Ann",
            "lastName": "Petrie-Norris",
            "fullName": "Cottie Petrie-Norris",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/a74_c_p_norris.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-CA-73-lower-ocd-person-c766abf8-06ca-4a98-9665-82b87149ca96",
              "builtID": "ca-lower-73",
              "externalID": "ocd-person/c766abf8-06ca-4a98-9665-82b87149ca96",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.petrie-norris@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "19712 MacArthur Blvd. Suite 150, Irvine, CA 92612",
              "phone1": null,
              "phone2": "949-251-0074",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a74.asmdc.org/", "https://a73.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-73-lower-ocd-person-c766abf8-06ca-4a98-9665-82b87149ca96": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/1b3ac39e-7b50-4263-bd1d-add1e91d71b9",
            "firstName": "Laurie Ann",
            "lastName": "Davies",
            "fullName": "Laurie Davies",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/arc_new_member_headshots_asm.ca_.gov_120x150px_davies.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-CA-74-lower-ocd-person-1b3ac39e-7b50-4263-bd1d-add1e91d71b9",
              "builtID": "ca-lower-74",
              "externalID": "ocd-person/1b3ac39e-7b50-4263-bd1d-add1e91d71b9",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.davies@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "32332 Camino Capistrano Suite 102A, San Juan Capistrano, CA 92675",
              "phone1": null,
              "phone2": "949-284-6371",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ad73.asmrc.org/", "https://ad74.asmrc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-74-lower-ocd-person-1b3ac39e-7b50-4263-bd1d-add1e91d71b9": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/9df241a1-0f02-456a-9dbf-8706e0b6c15c",
            "firstName": "Marie",
            "lastName": "Waldron",
            "fullName": "Marie Waldron",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/AD75-Marie_Waldron.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-CA-75-lower-ocd-person-9df241a1-0f02-456a-9dbf-8706e0b6c15c",
              "builtID": "ca-lower-75",
              "externalID": "ocd-person/9df241a1-0f02-456a-9dbf-8706e0b6c15c",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.waldron@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "9820 Willow Creek Road Suite 240, San Diego, CA 92131",
              "phone1": null,
              "phone2": "858-566-7538",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ad75.asmrc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-75-lower-ocd-person-9df241a1-0f02-456a-9dbf-8706e0b6c15c": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/4c0ad3f7-b949-4206-a550-99633351236c",
            "firstName": "Brian",
            "lastName": "Maienschein",
            "fullName": "Brian Maienschein",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad-76_brian_maienschein_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-CA-76-lower-ocd-person-4c0ad3f7-b949-4206-a550-99633351236c",
              "builtID": "ca-lower-76",
              "externalID": "ocd-person/4c0ad3f7-b949-4206-a550-99633351236c",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.maienschein@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "12396 World Trade Drive Suite 118, San Diego, CA 92128",
              "phone1": null,
              "phone2": "858-675-0760",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a77.asmdc.org/", "https://a76.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-76-lower-ocd-person-4c0ad3f7-b949-4206-a550-99633351236c": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/9e69729e-4383-410b-840e-ad4ea0f1ab4a",
            "firstName": "Tasha Nicole",
            "lastName": "Boerner",
            "fullName": "Tasha Boerner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/a76_t_b_horvath.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-CA-77-lower-ocd-person-9e69729e-4383-410b-840e-ad4ea0f1ab4a",
              "builtID": "ca-lower-77",
              "externalID": "ocd-person/9e69729e-4383-410b-840e-ad4ea0f1ab4a",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.boerner@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "505 Lomas Santa Fe Drive Suite 110, Solana Beach, CA 92075",
              "phone1": null,
              "phone2": "858-481-7704",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a77.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-77-lower-ocd-person-9e69729e-4383-410b-840e-ad4ea0f1ab4a": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/71590420-10d6-4deb-8d59-453256d625b6",
            "firstName": "Christopher M.",
            "lastName": "Ward",
            "fullName": "Chris Ward",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ward-006.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-CA-78-lower-ocd-person-71590420-10d6-4deb-8d59-453256d625b6",
              "builtID": "ca-lower-78",
              "externalID": "ocd-person/71590420-10d6-4deb-8d59-453256d625b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.ward@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2700 Adams Ave. Suite 102, San Diego, CA 92116",
              "phone1": null,
              "phone2": "619-280-7801",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a78.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-78-lower-ocd-person-71590420-10d6-4deb-8d59-453256d625b6": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/6c2a35d5-c04c-44d0-8bd9-92a625e2b525",
            "firstName": "Akilah Faizah",
            "lastName": "Weber",
            "fullName": "Akilah Weber",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad-79_weber_a._005_resized.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-CA-79-lower-ocd-person-6c2a35d5-c04c-44d0-8bd9-92a625e2b525",
              "builtID": "ca-lower-79",
              "externalID": "ocd-person/6c2a35d5-c04c-44d0-8bd9-92a625e2b525",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.aweber@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4700 Spring St. Suite 301, La Mesa, CA 91942",
              "phone1": null,
              "phone2": "619-465-7903",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a79.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-79-lower-ocd-person-6c2a35d5-c04c-44d0-8bd9-92a625e2b525": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/52f6ccc1-eb4a-4c09-8614-5e4022395605",
            "firstName": "David A.",
            "lastName": "Alvarez",
            "fullName": "David Alvarez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad80_alvarez_008_06-16-22.jpg",
            "title": "CA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-CA-80-lower-ocd-person-52f6ccc1-eb4a-4c09-8614-5e4022395605",
              "builtID": "ca-lower-80",
              "externalID": "ocd-person/52f6ccc1-eb4a-4c09-8614-5e4022395605",
              "geometry": null
            },
            "contactInfo": {
              "email": "assemblymember.alvarez@assembly.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "276 Church Ave. Suite D, Chula Vista, CA 91910",
              "phone1": null,
              "phone2": "619-498-8580",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://a80.asmdc.org/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CA-80-lower-ocd-person-52f6ccc1-eb4a-4c09-8614-5e4022395605": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/91915f3b-c93f-4f55-a164-58b8c1587d6d",
            "firstName": "Brian",
            "lastName": "Dahle",
            "fullName": "Brian Dahle",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/senator-dahle.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-CA-1-upper-ocd-person-91915f3b-c93f-4f55-a164-58b8c1587d6d",
              "builtID": "ca-upper-1",
              "externalID": "ocd-person/91915f3b-c93f-4f55-a164-58b8c1587d6d",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.dahle@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2635 Forest Ave., Chico, CA 95928",
              "phone1": null,
              "phone2": "530-879-7424",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd01",
                "https://sr01.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-1-upper-ocd-person-91915f3b-c93f-4f55-a164-58b8c1587d6d": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/a1394aeb-094a-4fc0-b4d5-005104ceddc0",
            "firstName": "Michael T.",
            "lastName": "McGuire",
            "fullName": "Mike McGuire",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/mcguire-mike.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-CA-2-upper-ocd-person-a1394aeb-094a-4fc0-b4d5-005104ceddc0",
              "builtID": "ca-upper-2",
              "externalID": "ocd-person/a1394aeb-094a-4fc0-b4d5-005104ceddc0",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.mcguire@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "885 Lakeport Blvd., Lakeport, CA 95453",
              "phone1": null,
              "phone2": "707-468-8914",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd02",
                "https://sd02.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-2-upper-ocd-person-a1394aeb-094a-4fc0-b4d5-005104ceddc0": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/7ecd4221-a627-42dc-9029-bbc543ef3735",
            "firstName": "William Harold",
            "lastName": "Dodd",
            "fullName": "Bill Dodd",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/sen_bill_dodd.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-CA-3-upper-ocd-person-7ecd4221-a627-42dc-9029-bbc543ef3735",
              "builtID": "ca-upper-3",
              "externalID": "ocd-person/7ecd4221-a627-42dc-9029-bbc543ef3735",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.dodd@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "555 Mason St. Suite 275, Vacaville, CA 95688",
              "phone1": null,
              "phone2": "707-454-3808",
              "fax1": null,
              "fax2": "707-454-3811",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd03",
                "https://sd03.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-3-upper-ocd-person-7ecd4221-a627-42dc-9029-bbc543ef3735": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/b6907bd6-05e1-44a4-bfc6-db4b672ff8b4",
            "firstName": "Marie",
            "lastName": "Alvarado-Gil",
            "fullName": "Marie Alvarado-Gil",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/alvarado-gill_marie_d-04.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-CA-4-upper-ocd-person-b6907bd6-05e1-44a4-bfc6-db4b672ff8b4",
              "builtID": "ca-upper-4",
              "externalID": "ocd-person/b6907bd6-05e1-44a4-bfc6-db4b672ff8b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.alvarado-gil@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4364 Town Center Blvd. Suite 313, El Dorado Hills, CA 95762",
              "phone1": null,
              "phone2": "916-933-8680",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd04",
                "https://sd04.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-4-upper-ocd-person-b6907bd6-05e1-44a4-bfc6-db4b672ff8b4": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/796593d1-498e-4980-bd5e-2db033754c62",
            "firstName": "Susan Talamantes",
            "lastName": "Eggman",
            "fullName": "Susan Eggman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/eggman-susan.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-CA-5-upper-ocd-person-796593d1-498e-4980-bd5e-2db033754c62",
              "builtID": "ca-upper-5",
              "externalID": "ocd-person/796593d1-498e-4980-bd5e-2db033754c62",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.eggman@sen.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2291 W. March Lane Suite B 200, Stockton, CA 95207",
              "phone1": null,
              "phone2": "209-472-9535",
              "fax1": null,
              "fax2": "209-472-9530",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd05",
                "https://sd05.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-5-upper-ocd-person-796593d1-498e-4980-bd5e-2db033754c62": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/ee85bc79-20ee-4046-905e-ad797995fa41",
            "firstName": "Roger W.",
            "lastName": "Niello",
            "fullName": "Roger Niello",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/niello_roger_r-06.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-CA-6-upper-ocd-person-ee85bc79-20ee-4046-905e-ad797995fa41",
              "builtID": "ca-upper-6",
              "externalID": "ocd-person/ee85bc79-20ee-4046-905e-ad797995fa41",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.niello@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "855 Harter Parkway Suite 245, Yuba City, CA 95993",
              "phone1": null,
              "phone2": "530-701-8114",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd06",
                "https://sr06.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-6-upper-ocd-person-ee85bc79-20ee-4046-905e-ad797995fa41": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/9521850c-594d-415b-becf-002add076e86",
            "firstName": "Steven Mitchell",
            "lastName": "Glazer",
            "fullName": "Steve Glazer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/glazer-steven.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-CA-7-upper-ocd-person-9521850c-594d-415b-becf-002add076e86",
              "builtID": "ca-upper-7",
              "externalID": "ocd-person/9521850c-594d-415b-becf-002add076e86",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.glazer@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "51 Moraga Way Suite 2, Orinda, CA 94563",
              "phone1": null,
              "phone2": "925-258-1176",
              "fax1": null,
              "fax2": "925-258-9982",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd07",
                "https://sd07.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-7-upper-ocd-person-9521850c-594d-415b-becf-002add076e86": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/564f2203-7cae-4a40-bc93-5c05a935b98f",
            "firstName": "Angelique V.",
            "lastName": "Ashby",
            "fullName": "Angelique Ashby",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/ashby_angelique_d-08.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-CA-8-upper-ocd-person-564f2203-7cae-4a40-bc93-5c05a935b98f",
              "builtID": "ca-upper-8",
              "externalID": "ocd-person/564f2203-7cae-4a40-bc93-5c05a935b98f",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.ashby@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 568, 1020 N. St., Sacramento, CA 95814",
              "phone1": null,
              "phone2": "916-651-1529",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd08",
                "https://sd08.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-8-upper-ocd-person-564f2203-7cae-4a40-bc93-5c05a935b98f": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/da008178-5da9-4adb-a690-1360a9bce2db",
            "firstName": "Nancy",
            "lastName": "Skinner",
            "fullName": "Nancy Skinner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/skinner.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-CA-9-upper-ocd-person-da008178-5da9-4adb-a690-1360a9bce2db",
              "builtID": "ca-upper-9",
              "externalID": "ocd-person/da008178-5da9-4adb-a690-1360a9bce2db",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.skinner@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1515 Clay St. Suite 2202, Oakland, CA 94612",
              "phone1": null,
              "phone2": "510-286-1333",
              "fax1": null,
              "fax2": "510-286-3885",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd09",
                "https://sd09.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-9-upper-ocd-person-da008178-5da9-4adb-a690-1360a9bce2db": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/295965df-6c71-4e11-806f-2b7d5be5d45c",
            "firstName": "Aisha",
            "lastName": "Wahab",
            "fullName": "Aisha Wahab",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/wahab_aisha_d-10_-_crop.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-CA-10-upper-ocd-person-295965df-6c71-4e11-806f-2b7d5be5d45c",
              "builtID": "ca-upper-10",
              "externalID": "ocd-person/295965df-6c71-4e11-806f-2b7d5be5d45c",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.wahab@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Fremont, CA",
              "phone1": null,
              "phone2": "510-794-3900",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd10",
                "https://sd10.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-10-upper-ocd-person-295965df-6c71-4e11-806f-2b7d5be5d45c": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/de84277e-1c23-4036-bd64-b27c310a1c0e",
            "firstName": "Scott",
            "lastName": "Wiener",
            "fullName": "Scott Wiener",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/wiener.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-CA-11-upper-ocd-person-de84277e-1c23-4036-bd64-b27c310a1c0e",
              "builtID": "ca-upper-11",
              "externalID": "ocd-person/de84277e-1c23-4036-bd64-b27c310a1c0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.wiener@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "455 Golden Gate Ave. Suite 14800, San Francisco, CA 94102",
              "phone1": null,
              "phone2": "415-557-1300",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd11",
                "https://sd11.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-11-upper-ocd-person-de84277e-1c23-4036-bd64-b27c310a1c0e": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/b4b8e8fb-831b-4cae-9081-b8f75477fcd0",
            "firstName": "Shannon Lee",
            "lastName": "Grove",
            "fullName": "Shannon Grove",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/grove_hs_0625_lls.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-CA-12-upper-ocd-person-b4b8e8fb-831b-4cae-9081-b8f75477fcd0",
              "builtID": "ca-upper-12",
              "externalID": "ocd-person/b4b8e8fb-831b-4cae-9081-b8f75477fcd0",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.grove@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7211 Joshua Lane Suite 8, Yucca Valley, CA 92284",
              "phone1": null,
              "phone2": "760-228-3136",
              "fax1": null,
              "fax2": "760-228-2462",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd16",
                "https://senate.ca.gov/sd12",
                "https://sr12.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-12-upper-ocd-person-b4b8e8fb-831b-4cae-9081-b8f75477fcd0": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/c71137b2-3fc3-48bf-b0ad-6c29aff98268",
            "firstName": "Josh",
            "lastName": "Becker",
            "fullName": "Josh Becker",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/sd13_becker_0.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-CA-13-upper-ocd-person-c71137b2-3fc3-48bf-b0ad-6c29aff98268",
              "builtID": "ca-upper-13",
              "externalID": "ocd-person/c71137b2-3fc3-48bf-b0ad-6c29aff98268",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.becker@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3525 Alameda de las Pulgas, Menlo Park, CA 94025",
              "phone1": null,
              "phone2": "650-233-2724",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd13",
                "https://sd13.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-13-upper-ocd-person-c71137b2-3fc3-48bf-b0ad-6c29aff98268": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/fe462917-df55-4a43-9fd8-970541544ca6",
            "firstName": "Anna Marie",
            "lastName": "Caballero",
            "fullName": "Anna Caballero",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/2016-ad30_annacaballero.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-CA-14-upper-ocd-person-fe462917-df55-4a43-9fd8-970541544ca6",
              "builtID": "ca-upper-14",
              "externalID": "ocd-person/fe462917-df55-4a43-9fd8-970541544ca6",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.caballero@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "510 W. Main St. Suite E, Merced, CA 95340",
              "phone1": null,
              "phone2": "209-726-5495",
              "fax1": null,
              "fax2": "209-726-5498",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd12",
                "https://sd14.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-14-upper-ocd-person-fe462917-df55-4a43-9fd8-970541544ca6": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/0fa65d2c-6020-4586-a6f1-5dfd41db38ff",
            "firstName": "David Dominic",
            "lastName": "Cortese",
            "fullName": "Dave Cortese",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/sd15_cortese_0.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-CA-15-upper-ocd-person-0fa65d2c-6020-4586-a6f1-5dfd41db38ff",
              "builtID": "ca-upper-15",
              "externalID": "ocd-person/0fa65d2c-6020-4586-a6f1-5dfd41db38ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.cortese@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2105 S. Bascom Ave. Suite 154, Campbell, CA 95008",
              "phone1": null,
              "phone2": "408-558-1295",
              "fax1": null,
              "fax2": "408-558-1296",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd15",
                "https://sd15.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-15-upper-ocd-person-0fa65d2c-6020-4586-a6f1-5dfd41db38ff": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/ee9910a2-0b27-46e0-be87-43c800596b41",
            "firstName": "Melissa",
            "lastName": "Hurtado",
            "fullName": "Melissa Hurtado",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/senator_hurtado_0.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-CA-16-upper-ocd-person-ee9910a2-0b27-46e0-be87-43c800596b41",
              "builtID": "ca-upper-16",
              "externalID": "ocd-person/ee9910a2-0b27-46e0-be87-43c800596b41",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.hurtado@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "611 N. Douty St., Hanford, CA 93230",
              "phone1": null,
              "phone2": "559-585-7161",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd14",
                "https://senate.ca.gov/sd16",
                "https://sd16.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-16-upper-ocd-person-ee9910a2-0b27-46e0-be87-43c800596b41": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/7476789f-49a4-491a-b294-9d1486f24193",
            "firstName": "John",
            "lastName": "Laird",
            "fullName": "John Laird",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/sd17_laird_0.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-CA-17-upper-ocd-person-7476789f-49a4-491a-b294-9d1486f24193",
              "builtID": "ca-upper-17",
              "externalID": "ocd-person/7476789f-49a4-491a-b294-9d1486f24193",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.laird@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "CA",
              "phone1": null,
              "phone2": "408-847-6101",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd17",
                "https://sd17.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-17-upper-ocd-person-7476789f-49a4-491a-b294-9d1486f24193": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/eb745b9e-4ced-494a-a298-fdffa9207b0d",
            "firstName": "Steve",
            "lastName": "Padilla",
            "fullName": "Steve Padilla",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/padilla_steve_d-18.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-CA-18-upper-ocd-person-eb745b9e-4ced-494a-a298-fdffa9207b0d",
              "builtID": "ca-upper-18",
              "externalID": "ocd-person/eb745b9e-4ced-494a-a298-fdffa9207b0d",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.padilla@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "82013 Dr. Carreon Blvd. Suite L, Indio, CA 92201",
              "phone1": null,
              "phone2": "760-398-6442",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd18",
                "https://sd18.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-18-upper-ocd-person-eb745b9e-4ced-494a-a298-fdffa9207b0d": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/e924a3ec-c7c0-4094-8504-7611bcf8979f",
            "firstName": "S. Monique",
            "lastName": "Limon",
            "fullName": "Monique Limón",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/limon_monique_d-19_2.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-CA-19-upper-ocd-person-e924a3ec-c7c0-4094-8504-7611bcf8979f",
              "builtID": "ca-upper-19",
              "externalID": "ocd-person/e924a3ec-c7c0-4094-8504-7611bcf8979f",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.limon@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "300 E. Esplanade Drive Suite 430, Oxnard, CA 93036",
              "phone1": null,
              "phone2": "805-988-1940",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd19",
                "https://sd19.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-19-upper-ocd-person-e924a3ec-c7c0-4094-8504-7611bcf8979f": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/b19c0361-ca41-4cba-bcf4-b7342a67f28b",
            "firstName": "Caroline",
            "lastName": "Menjivar",
            "fullName": "Caroline Menjivar",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/menjivar_caroline_d-20.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-CA-20-upper-ocd-person-b19c0361-ca41-4cba-bcf4-b7342a67f28b",
              "builtID": "ca-upper-20",
              "externalID": "ocd-person/b19c0361-ca41-4cba-bcf4-b7342a67f28b",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.menjivar@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6150 Van Nuys Blvd., Suite 400, Van Nuys, CA 91401",
              "phone1": null,
              "phone2": "818-901-5588",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd20",
                "https://sd20.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-20-upper-ocd-person-b19c0361-ca41-4cba-bcf4-b7342a67f28b": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/f82658a8-e4e5-4f12-807b-b1eec79782d1",
            "firstName": "Scott Thomas",
            "lastName": "Wilk",
            "fullName": "Scott Wilk",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/wilk.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-CA-21-upper-ocd-person-f82658a8-e4e5-4f12-807b-b1eec79782d1",
              "builtID": "ca-upper-21",
              "externalID": "ocd-person/f82658a8-e4e5-4f12-807b-b1eec79782d1",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.wilk@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "848 W. Lancaster Blvd. Suite 101, Lancaster, CA 93534",
              "phone1": null,
              "phone2": "661-729-6232",
              "fax1": null,
              "fax2": "661-729-1683",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd21",
                "https://sr21.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-21-upper-ocd-person-f82658a8-e4e5-4f12-807b-b1eec79782d1": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/cb0f6477-568f-49db-aba3-f88e7bccbefc",
            "firstName": "Susan",
            "lastName": "Rubio",
            "fullName": "Susan Rubio",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/senator_rubio.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-CA-22-upper-ocd-person-cb0f6477-568f-49db-aba3-f88e7bccbefc",
              "builtID": "ca-upper-22",
              "externalID": "ocd-person/cb0f6477-568f-49db-aba3-f88e7bccbefc",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.rubio@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1520 N. Mountain Ave. Building E, Suite 201, Ontario, CA 91762",
              "phone1": null,
              "phone2": "909-460-6064",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd22",
                "https://sd22.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-22-upper-ocd-person-cb0f6477-568f-49db-aba3-f88e7bccbefc": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/cb5b9356-6798-44d2-8dc2-caf5651ac4e3",
            "firstName": "Rosilicie",
            "lastName": "Ochoa Bogh",
            "fullName": "Rosilicie Ochoa Bogh",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/sd23_ochoa_bogh.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-CA-23-upper-ocd-person-cb5b9356-6798-44d2-8dc2-caf5651ac4e3",
              "builtID": "ca-upper-23",
              "externalID": "ocd-person/cb5b9356-6798-44d2-8dc2-caf5651ac4e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.ochoabogh@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1758 Orange Tree Lane Suite B, Redlands, CA 92374",
              "phone1": null,
              "phone2": "909-335-0271",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd23",
                "https://sr23.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-23-upper-ocd-person-cb5b9356-6798-44d2-8dc2-caf5651ac4e3": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/e725b91e-1f7f-4510-a9cc-0b9a39d283c3",
            "firstName": "Benjamin J.",
            "lastName": "Allen",
            "fullName": "Ben Allen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/allen_ben_d-26.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-CA-24-upper-ocd-person-e725b91e-1f7f-4510-a9cc-0b9a39d283c3",
              "builtID": "ca-upper-24",
              "externalID": "ocd-person/e725b91e-1f7f-4510-a9cc-0b9a39d283c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.allen@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "111 Penn St. Suite 101, El Segundo, CA 90245",
              "phone1": null,
              "phone2": "310-414-8190",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd26",
                "https://sd24.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-24-upper-ocd-person-e725b91e-1f7f-4510-a9cc-0b9a39d283c3": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/84f0dbbd-99dd-4ef2-9772-eb06047d847b",
            "firstName": "Anthony J.",
            "lastName": "Portantino",
            "fullName": "Anthony Portantino",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/portantino_0331_lscr.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-CA-25-upper-ocd-person-84f0dbbd-99dd-4ef2-9772-eb06047d847b",
              "builtID": "ca-upper-25",
              "externalID": "ocd-person/84f0dbbd-99dd-4ef2-9772-eb06047d847b",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.portantino@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "601 E. Glenoaks Blvd. Suite 210, Glendale, CA 91207",
              "phone1": null,
              "phone2": "818-409-0400",
              "fax1": null,
              "fax2": "818-409-1256",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd25",
                "https://sd25.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-25-upper-ocd-person-84f0dbbd-99dd-4ef2-9772-eb06047d847b": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/b3f55460-f87b-4db4-b6a5-35a735f34076",
            "firstName": "Maria Elena",
            "lastName": "Durazo",
            "fullName": "María Elena Durazo",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/senator_durazo.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-CA-26-upper-ocd-person-b3f55460-f87b-4db4-b6a5-35a735f34076",
              "builtID": "ca-upper-26",
              "externalID": "ocd-person/b3f55460-f87b-4db4-b6a5-35a735f34076",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.durazo@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1808 W. Sunset Blvd., Los Angeles, CA 90026",
              "phone1": null,
              "phone2": "213-483-9300",
              "fax1": null,
              "fax2": "213-483-9305",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd24",
                "https://senate.ca.gov/sd26",
                "https://sd26.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-26-upper-ocd-person-b3f55460-f87b-4db4-b6a5-35a735f34076": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/66bd4b32-5536-4bae-ab55-08c0b70ca5fd",
            "firstName": "Henry I.",
            "lastName": "Stern",
            "fullName": "Henry Stern",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/stern.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-CA-27-upper-ocd-person-66bd4b32-5536-4bae-ab55-08c0b70ca5fd",
              "builtID": "ca-upper-27",
              "externalID": "ocd-person/66bd4b32-5536-4bae-ab55-08c0b70ca5fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.stern@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5016 N. Parkway Calabasas Suite 222, Calabasas, CA 91302",
              "phone1": null,
              "phone2": "818-876-3352",
              "fax1": null,
              "fax2": "818-876-0802",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd27",
                "https://sd27.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-27-upper-ocd-person-66bd4b32-5536-4bae-ab55-08c0b70ca5fd": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/37f2dc9f-d788-4f71-b794-093c8816333b",
            "firstName": "Lola",
            "lastName": "Smallwood-Cuevas",
            "fullName": "Lola Smallwood-Cuevas",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/smallwood-cuevas_lola_d-28.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-CA-28-upper-ocd-person-37f2dc9f-d788-4f71-b794-093c8816333b",
              "builtID": "ca-upper-28",
              "externalID": "ocd-person/37f2dc9f-d788-4f71-b794-093c8816333b",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.smallwood-cuevas@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "700 Exposition Park Drive, Los Angeles, CA 90037",
              "phone1": null,
              "phone2": "213-745-6656",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd28",
                "https://sd28.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-28-upper-ocd-person-37f2dc9f-d788-4f71-b794-093c8816333b": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/ed40c798-40c1-4fe6-9fb8-c3fee65b48e0",
            "firstName": "Josh",
            "lastName": "Newman",
            "fullName": "Josh Newman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/sd29_newman.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-CA-29-upper-ocd-person-ed40c798-40c1-4fe6-9fb8-c3fee65b48e0",
              "builtID": "ca-upper-29",
              "externalID": "ocd-person/ed40c798-40c1-4fe6-9fb8-c3fee65b48e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.newman@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "203 N. Harbor Blvd., Fullerton, CA 92832",
              "phone1": null,
              "phone2": "714-525-2342",
              "fax1": null,
              "fax2": "714-525-2367",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd29",
                "https://sd29.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-29-upper-ocd-person-ed40c798-40c1-4fe6-9fb8-c3fee65b48e0": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/a76028df-5730-47b0-b617-1565621def41",
            "firstName": "Bobby Jerry",
            "lastName": "Archuleta",
            "fullName": "Bob Archuleta",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/archuleta_bob.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-CA-30-upper-ocd-person-a76028df-5730-47b0-b617-1565621def41",
              "builtID": "ca-upper-30",
              "externalID": "ocd-person/a76028df-5730-47b0-b617-1565621def41",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.archuleta@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "12501 Imperial Highway Suite 110, Norwalk, CA 90650",
              "phone1": null,
              "phone2": "562-406-1001",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd32",
                "https://senate.ca.gov/sd30",
                "https://sd30.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-30-upper-ocd-person-a76028df-5730-47b0-b617-1565621def41": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/26cbb5a7-e485-41f6-9cb6-be82cbef73db",
            "firstName": "Richard Dale",
            "lastName": "Roth",
            "fullName": "Richard Roth",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/roth-richard.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-CA-31-upper-ocd-person-26cbb5a7-e485-41f6-9cb6-be82cbef73db",
              "builtID": "ca-upper-31",
              "externalID": "ocd-person/26cbb5a7-e485-41f6-9cb6-be82cbef73db",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.roth@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "8353 Sierra Ave. Suite 142, Fontana, CA 92335",
              "phone1": null,
              "phone2": "909-574-4120",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd31",
                "https://sd31.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-31-upper-ocd-person-26cbb5a7-e485-41f6-9cb6-be82cbef73db": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/d9462f08-2b27-4969-8436-9a1c21534a40",
            "firstName": "Dennis Kelly",
            "lastName": "Seyarto",
            "fullName": "Kelly Seyarto",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/seyarto_kelly_r-32c.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-CA-32-upper-ocd-person-d9462f08-2b27-4969-8436-9a1c21534a40",
              "builtID": "ca-upper-32",
              "externalID": "ocd-person/d9462f08-2b27-4969-8436-9a1c21534a40",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.seyarto@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "777 E. Tahquitz Canyon Way Suite 200-12, Palm Springs, CA 92262",
              "phone1": null,
              "phone2": "760-229-0346",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ad67.asmrc.org/",
                "https://senate.ca.gov/sd32",
                "https://sr32.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-32-upper-ocd-person-d9462f08-2b27-4969-8436-9a1c21534a40": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/cb6cc457-59f8-4dd8-aeb5-691c3795ffb7",
            "firstName": "Lena Adriana",
            "lastName": "Gonzalez",
            "fullName": "Lena Gonzalez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/senator-lena-gonzalez.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-CA-33-upper-ocd-person-cb6cc457-59f8-4dd8-aeb5-691c3795ffb7",
              "builtID": "ca-upper-33",
              "externalID": "ocd-person/cb6cc457-59f8-4dd8-aeb5-691c3795ffb7",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.gonzalez@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3939 Atlantic Ave. Suite 107, Long Beach, CA 90807",
              "phone1": null,
              "phone2": "562-256-7921",
              "fax1": null,
              "fax2": "562-256-9991",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd33",
                "https://sd33.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-33-upper-ocd-person-cb6cc457-59f8-4dd8-aeb5-691c3795ffb7": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/cecabb81-51ab-46f5-9815-cc3486da0632",
            "firstName": "Thomas John",
            "lastName": "Umberg",
            "fullName": "Tom Umberg",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/senator_umberg.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-CA-34-upper-ocd-person-cecabb81-51ab-46f5-9815-cc3486da0632",
              "builtID": "ca-upper-34",
              "externalID": "ocd-person/cecabb81-51ab-46f5-9815-cc3486da0632",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.umberg@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1000 E. Santa Ana Blvd. Suite 220B, Santa Ana, CA 92701",
              "phone1": null,
              "phone2": "714-558-3785",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd34",
                "https://sd34.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-34-upper-ocd-person-cecabb81-51ab-46f5-9815-cc3486da0632": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/99fd8cce-55ba-493a-b787-e339a57de7df",
            "firstName": "Steven",
            "lastName": "Bradford",
            "fullName": "Steve Bradford",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/bradford.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-CA-35-upper-ocd-person-99fd8cce-55ba-493a-b787-e339a57de7df",
              "builtID": "ca-upper-35",
              "externalID": "ocd-person/99fd8cce-55ba-493a-b787-e339a57de7df",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.bradford@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "302 W. 5th St. Suite 203, San Pedro, CA 90731",
              "phone1": null,
              "phone2": "310-514-8573",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd35",
                "https://sd35.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-35-upper-ocd-person-99fd8cce-55ba-493a-b787-e339a57de7df": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/c6471995-41db-497c-8953-dd3ea5871870",
            "firstName": "Janet Q.",
            "lastName": "Nguyen",
            "fullName": "Janet Nguyen",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.assembly.ca.gov/sites/assembly.ca.gov/files/memberphotos/ad72_janet_nguyen.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-CA-36-upper-ocd-person-c6471995-41db-497c-8953-dd3ea5871870",
              "builtID": "ca-upper-36",
              "externalID": "ocd-person/c6471995-41db-497c-8953-dd3ea5871870",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.nguyen@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "301 Main St. Suite 212, Huntington Beach, CA 92648",
              "phone1": null,
              "phone2": "714-374-4000",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ad72.asmrc.org/",
                "https://sr36.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-36-upper-ocd-person-c6471995-41db-497c-8953-dd3ea5871870": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/393d31b9-3336-4e2a-b397-fe12898052c2",
            "firstName": "David Kunnghee",
            "lastName": "Min",
            "fullName": "Dave Min",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/sd37_min.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-CA-37-upper-ocd-person-393d31b9-3336-4e2a-b397-fe12898052c2",
              "builtID": "ca-upper-37",
              "externalID": "ocd-person/393d31b9-3336-4e2a-b397-fe12898052c2",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.min@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2151 Michelson Drive Suite 258, Irvine, CA 92612",
              "phone1": null,
              "phone2": "949-223-5472",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd37",
                "https://sd37.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-37-upper-ocd-person-393d31b9-3336-4e2a-b397-fe12898052c2": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/39e3ce0f-c2e2-4480-9e99-76e1e62f366d",
            "firstName": "Catherine S.",
            "lastName": "Blakespear",
            "fullName": "Catherine Blakespear",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/blakespear_catherine_d-38.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-CA-38-upper-ocd-person-39e3ce0f-c2e2-4480-9e99-76e1e62f366d",
              "builtID": "ca-upper-38",
              "externalID": "ocd-person/39e3ce0f-c2e2-4480-9e99-76e1e62f366d",
              "geometry": null
            },
            "contactInfo": {
              "email": "catherine.blakespear@sen.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "24031 El Toro Road, Suite 201A, Laguna Hills, CA 92653",
              "phone1": null,
              "phone2": "949-598-5850",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd38",
                "https://sd38.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-38-upper-ocd-person-39e3ce0f-c2e2-4480-9e99-76e1e62f366d": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/426d94ce-e49c-4d56-a185-9eb9c628c006",
            "firstName": "Toni Gayle",
            "lastName": "Atkins",
            "fullName": "Toni Atkins",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/atkins_018_after_09-28-20.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-CA-39-upper-ocd-person-426d94ce-e49c-4d56-a185-9eb9c628c006",
              "builtID": "ca-upper-39",
              "externalID": "ocd-person/426d94ce-e49c-4d56-a185-9eb9c628c006",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.atkins@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7575 Metropolitan Drive Suite 100, San Diego, CA 92108",
              "phone1": null,
              "phone2": "619-645-3133",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd39",
                "https://sd39.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-39-upper-ocd-person-426d94ce-e49c-4d56-a185-9eb9c628c006": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/ea5c3b3d-bbad-41f6-a912-69f60f1553a4",
            "firstName": "Brian W.",
            "lastName": "Jones",
            "fullName": "Brian Jones",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.ca.gov/sites/senate.ca.gov/files/senator_photos/brian_jones_headshot_blue.jpg",
            "title": "CA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CA",
              "stateFull": "California",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-CA-40-upper-ocd-person-ea5c3b3d-bbad-41f6-a912-69f60f1553a4",
              "builtID": "ca-upper-40",
              "externalID": "ocd-person/ea5c3b3d-bbad-41f6-a912-69f60f1553a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.jones@senate.ca.gov",
              "contactForm": null,
              "address1": null,
              "address2": "720 N. Broadway Suite 110, Escondido, CA 92025",
              "phone1": null,
              "phone2": "760-796-4655",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.ca.gov/sd38",
                "https://senate.ca.gov/sd40",
                "https://sr40.senate.ca.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CA-40-upper-ocd-person-ea5c3b3d-bbad-41f6-a912-69f60f1553a4": 0
        }
      }
    }
  },
  "CO": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "CO",
          "stateFull": "Colorado",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "CO",
            "stateFull": "Colorado",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-CO---",
            "builtID": "co--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-CO---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "B001267",
          "in_office": true,
          "firstName": "Michael",
          "lastName": "Bennet",
          "middleName": null,
          "fullName": "Michael F. Bennet",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/B001267.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "CO",
            "stateFull": "Colorado",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-CO---B001267",
            "builtID": "co--",
            "externalID": "B001267",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenBennetCo",
            "youtube": "SenatorBennet",
            "instagram": null,
            "facebook": "senbennetco",
            "urls": ["https://www.bennet.senate.gov/public"],
            "rss_url": null
          },
          "title": "CO Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/B001267.json",
          "govtrack_id": "412330",
          "cspan_id": "1031622",
          "votesmart_id": "110942",
          "icpsr_id": "40910",
          "crp_id": "N00030608",
          "google_entity_id": "/m/05b60qf",
          "state_rank": "senior",
          "lis_id": "S330",
          "suffix": null,
          "date_of_birth": "1964-11-28",
          "leadership_role": null,
          "fec_candidate_id": "S0CO00211",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "15",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 4,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 0.72,
          "votes_with_party_pct": 98.54,
          "votes_against_party_pct": 1.46,
          "ocd_id": "ocd-division/country:us/state:co"
        },
        {
          "API_ID": "H000273",
          "in_office": true,
          "firstName": "John",
          "lastName": "Hickenlooper",
          "middleName": null,
          "fullName": "John W. Hickenlooper",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/H000273.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "CO",
            "stateFull": "Colorado",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-CO---H000273",
            "builtID": "co--",
            "externalID": "H000273",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.hickenlooper.senate.gov/contact/",
            "address1": "374 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-5941",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorHick",
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.hickenlooper.senate.gov"],
            "rss_url": null
          },
          "title": "CO Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/H000273.json",
          "govtrack_id": "456797",
          "cspan_id": null,
          "votesmart_id": null,
          "icpsr_id": null,
          "crp_id": "N00044206",
          "google_entity_id": "/m/04g_1z",
          "state_rank": "junior",
          "lis_id": "S408",
          "suffix": null,
          "date_of_birth": "1952-02-07",
          "leadership_role": null,
          "fec_candidate_id": "S0CO00575",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 14,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 2.53,
          "votes_with_party_pct": 98.7,
          "votes_against_party_pct": 1.3,
          "ocd_id": "ocd-division/country:us/state:co"
        }
      ],
      "hash": { "SENATE-CO---B001267": 0, "SENATE-CO---H000273": 1 }
    },
    "HOUSE": {
      "03": {
        "members": [
          {
            "API_ID": "B000825",
            "in_office": true,
            "firstName": "Lauren",
            "lastName": "Boebert",
            "middleName": null,
            "fullName": "Lauren Boebert",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B000825.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-CO-03--B000825",
              "builtID": "co--03",
              "externalID": "B000825",
              "geometry": null,
              "geoid": "0803"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1713 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4761",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBoebert",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://boebert.house.gov"],
              "rss_url": null
            },
            "title": "CO House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B000825.json",
            "govtrack_id": "456805",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00045974",
            "google_entity_id": "/g/11j2v06x6p",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1986-12-15",
            "leadership_role": null,
            "fec_candidate_id": "H0CO03165",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 62,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 5.87,
            "votes_with_party_pct": 85.01,
            "votes_against_party_pct": 13.14,
            "ocd_id": "ocd-division/country:us/state:co/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CO-03--B000825": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "L000604",
            "in_office": true,
            "firstName": "Greg",
            "lastName": "Lopez",
            "middleName": null,
            "fullName": "Greg Lopez",
            "gender": "M",
            "party": "Republican",
            "image": "https://clerk.house.gov/images/members/L000604.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-CO-04--L000604",
              "builtID": "co--04",
              "externalID": "L000604",
              "geometry": null,
              "geoid": "0804"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2455 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4676",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "LopezforCO",
              "youtube": "@greglopezforallcolorado",
              "instagram": null,
              "facebook": "GregLopezForAllColorado",
              "urls": ["https://lopez.house.gov"],
              "rss_url": null
            },
            "title": "CO House Member",
            "short_title": "Rep.",
            "api_uri": null,
            "govtrack_id": null,
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1964-06-07",
            "leadership_role": null,
            "fec_candidate_id": null,
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": null,
            "next_election": "2025",
            "total_votes": null,
            "missed_votes": null,
            "total_present": null,
            "last_updated": null,
            "missed_votes_pct": null,
            "votes_with_party_pct": null,
            "votes_against_party_pct": null,
            "ocd_id": null,
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CO-04--L000604": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "C001134",
            "in_office": true,
            "firstName": "Yadira",
            "lastName": "Caraveo",
            "middleName": null,
            "fullName": "Yadira Caraveo",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001134.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-CO-08--C001134",
              "builtID": "co--08",
              "externalID": "C001134",
              "geometry": null,
              "geoid": "0808"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1024 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5625",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepCaraveoMD",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://caraveo.house.gov/"],
              "rss_url": null
            },
            "title": "CO House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001134.json",
            "govtrack_id": "456887",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1980-12-23",
            "leadership_role": null,
            "fec_candidate_id": "H2CO08020",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 31,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 2.94,
            "votes_with_party_pct": 89.18,
            "votes_against_party_pct": 8.85,
            "ocd_id": "ocd-division/country:us/state:co/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CO-08--C001134": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "C001121",
            "in_office": true,
            "firstName": "Jason",
            "lastName": "Crow",
            "middleName": null,
            "fullName": "Jason Crow",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001121.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-CO-06--C001121",
              "builtID": "co--06",
              "externalID": "C001121",
              "geometry": null,
              "geoid": "0806"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1323 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-7882",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJasonCrow",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://crow.house.gov"],
              "rss_url": null
            },
            "title": "CO House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001121.json",
            "govtrack_id": "412762",
            "cspan_id": null,
            "votesmart_id": "180218",
            "icpsr_id": null,
            "crp_id": "N00040876",
            "google_entity_id": "/g/11gzqdw_wg",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1979-03-15",
            "leadership_role": null,
            "fec_candidate_id": "H8CO06229",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 15,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.42,
            "votes_with_party_pct": 97.19,
            "votes_against_party_pct": 0.87,
            "ocd_id": "ocd-division/country:us/state:co/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CO-06--C001121": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "D000197",
            "in_office": true,
            "firstName": "Diana",
            "lastName": "DeGette",
            "middleName": null,
            "fullName": "Diana DeGette",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/D000197.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-CO-01--D000197",
              "builtID": "co--01",
              "externalID": "D000197",
              "geometry": null,
              "geoid": "0801"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2111 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4431",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDianaDeGette",
              "youtube": "RepDianaDeGette",
              "instagram": null,
              "facebook": "DianaDeGette",
              "urls": ["https://degette.house.gov"],
              "rss_url": "https://degette.house.gov/rss.xml"
            },
            "title": "CO House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000197.json",
            "govtrack_id": "400101",
            "cspan_id": "90293",
            "votesmart_id": "561",
            "icpsr_id": "29710",
            "crp_id": "N00006134",
            "google_entity_id": "/m/024z95",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1957-07-29",
            "leadership_role": null,
            "fec_candidate_id": "H6CO01141",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "28",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 12,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.14,
            "votes_with_party_pct": 95.64,
            "votes_against_party_pct": 2.52,
            "ocd_id": "ocd-division/country:us/state:co/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CO-01--D000197": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "L000564",
            "in_office": true,
            "firstName": "Doug",
            "lastName": "Lamborn",
            "middleName": null,
            "fullName": "Doug Lamborn",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/L000564.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-CO-05--L000564",
              "builtID": "co--05",
              "externalID": "L000564",
              "geometry": null,
              "geoid": "0805"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2371 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4422",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDLamborn",
              "youtube": "CongressmanLamborn",
              "instagram": null,
              "facebook": "CongressmanDougLamborn",
              "urls": ["https://lamborn.house.gov"],
              "rss_url": null
            },
            "title": "CO House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000564.json",
            "govtrack_id": "412191",
            "cspan_id": "1022846",
            "votesmart_id": "2698",
            "icpsr_id": "20704",
            "crp_id": "N00028133",
            "google_entity_id": "/m/0fsgdd",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1954-05-24",
            "leadership_role": null,
            "fec_candidate_id": "H6CO05159",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "18",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 24,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.27,
            "votes_with_party_pct": 93.67,
            "votes_against_party_pct": 4.45,
            "ocd_id": "ocd-division/country:us/state:co/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CO-05--L000564": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "N000191",
            "in_office": true,
            "firstName": "Joe",
            "lastName": "Neguse",
            "middleName": null,
            "fullName": "Joe Neguse",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/N000191.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-CO-02--N000191",
              "builtID": "co--02",
              "externalID": "N000191",
              "geometry": null,
              "geoid": "0802"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2400 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2161",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJoeNeguse",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://neguse.house.gov"],
              "rss_url": null
            },
            "title": "CO House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/N000191.json",
            "govtrack_id": "412761",
            "cspan_id": null,
            "votesmart_id": "151075",
            "icpsr_id": null,
            "crp_id": "N00041080",
            "google_entity_id": "/g/11gnsnch0m",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1984-05-13",
            "leadership_role": null,
            "fec_candidate_id": "H8CO02160",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 2,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.19,
            "votes_with_party_pct": 97.41,
            "votes_against_party_pct": 0.67,
            "ocd_id": "ocd-division/country:us/state:co/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CO-02--N000191": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "P000620",
            "in_office": true,
            "firstName": "Brittany",
            "lastName": "Pettersen",
            "middleName": null,
            "fullName": "Brittany Pettersen",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/P000620.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-CO-07--P000620",
              "builtID": "co--07",
              "externalID": "P000620",
              "geometry": null,
              "geoid": "0807"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1230 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2645",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://pettersen.house.gov/"],
              "rss_url": null
            },
            "title": "CO House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000620.json",
            "govtrack_id": "456886",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1981-12-06",
            "leadership_role": null,
            "fec_candidate_id": "H8CO07045",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.57,
            "votes_with_party_pct": 95,
            "votes_against_party_pct": 3.07,
            "ocd_id": "ocd-division/country:us/state:co/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CO-07--P000620": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/d2b25169-0772-4e97-aa9e-49f82f0f5d08",
            "firstName": "Javier",
            "lastName": "Mabrey",
            "fullName": "Javier Mabrey",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_mabrey%2Cjavier-.jpg?itok=nfzx9teU",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-CO-1-lower-ocd-person-d2b25169-0772-4e97-aa9e-49f82f0f5d08",
              "builtID": "co-lower-1",
              "externalID": "ocd-person/d2b25169-0772-4e97-aa9e-49f82f0f5d08",
              "geometry": null
            },
            "contactInfo": {
              "email": "javier.mabrey.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/javier-mabrey"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-1-lower-ocd-person-d2b25169-0772-4e97-aa9e-49f82f0f5d08": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/09af4a19-c07e-49b2-b320-104f3784705b",
            "firstName": "Steven Lezell",
            "lastName": "Woodrow",
            "fullName": "Steven Woodrow",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_woodrow%2Csteven-.jpg?itok=s0Qxv3Oj",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-CO-2-lower-ocd-person-09af4a19-c07e-49b2-b320-104f3784705b",
              "builtID": "co-lower-2",
              "externalID": "ocd-person/09af4a19-c07e-49b2-b320-104f3784705b",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.woodrow.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/steven-woodrow"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-2-lower-ocd-person-09af4a19-c07e-49b2-b320-104f3784705b": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/8e6bc6cb-fe7e-4640-a938-eb32536e95c5",
            "firstName": "Meg M.",
            "lastName": "Froelich",
            "fullName": "Meg Froelich",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_froelich%2Cmeg.jpg?itok=YaGZpLnx",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-CO-3-lower-ocd-person-8e6bc6cb-fe7e-4640-a938-eb32536e95c5",
              "builtID": "co-lower-3",
              "externalID": "ocd-person/8e6bc6cb-fe7e-4640-a938-eb32536e95c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "meg.froelich.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/meg-froelich"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-3-lower-ocd-person-8e6bc6cb-fe7e-4640-a938-eb32536e95c5": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/c0af3c46-a58a-41c0-bdd6-02523931566b",
            "firstName": "Tim",
            "lastName": "Hernandez",
            "fullName": "Tim Hernández",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_tim%20hernandez2.jpg?itok=wCEX0ioX",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-CO-4-lower-ocd-person-c0af3c46-a58a-41c0-bdd6-02523931566b",
              "builtID": "co-lower-4",
              "externalID": "ocd-person/c0af3c46-a58a-41c0-bdd6-02523931566b",
              "geometry": null
            },
            "contactInfo": {
              "email": "tim.hernandez.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.colorado.gov/legislators/tim-hern%C3%A1ndez",
                "https://leg.colorado.gov/legislators/tim-hernandez"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-4-lower-ocd-person-c0af3c46-a58a-41c0-bdd6-02523931566b": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/45c957a4-0017-4c0c-8650-393d656fc5a0",
            "firstName": "Alex James",
            "lastName": "Valdez",
            "fullName": "Alex Valdez",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_valdez%2Calex.jpg?itok=NsEe2BaG",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-CO-5-lower-ocd-person-45c957a4-0017-4c0c-8650-393d656fc5a0",
              "builtID": "co-lower-5",
              "externalID": "ocd-person/45c957a4-0017-4c0c-8650-393d656fc5a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "alex.valdez.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/alex-valdez"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-5-lower-ocd-person-45c957a4-0017-4c0c-8650-393d656fc5a0": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/ba5add52-4c4b-49d4-9d71-2d40be0838ff",
            "firstName": "Elisabeth",
            "lastName": "Epps",
            "fullName": "Elisabeth Epps",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_rep%20epps.jpeg?itok=UCt3JnoB",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-CO-6-lower-ocd-person-ba5add52-4c4b-49d4-9d71-2d40be0838ff",
              "builtID": "co-lower-6",
              "externalID": "ocd-person/ba5add52-4c4b-49d4-9d71-2d40be0838ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "elisabeth.epps.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/elisabeth-epps"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-6-lower-ocd-person-ba5add52-4c4b-49d4-9d71-2d40be0838ff": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/4aba3e11-9554-405d-b926-5435eb77fa26",
            "firstName": "Jennifer",
            "lastName": "Bacon",
            "fullName": "Jennifer Bacon",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_bacon%2Cjennifer.jpg?itok=IifAHPYj",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-CO-7-lower-ocd-person-4aba3e11-9554-405d-b926-5435eb77fa26",
              "builtID": "co-lower-7",
              "externalID": "ocd-person/4aba3e11-9554-405d-b926-5435eb77fa26",
              "geometry": null
            },
            "contactInfo": {
              "email": "jennifer.bacon.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/jennifer-bacon"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-7-lower-ocd-person-4aba3e11-9554-405d-b926-5435eb77fa26": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/a91826de-a61a-44e8-84b1-9f31f3dd32cb",
            "firstName": "Leslie",
            "lastName": "Herod",
            "fullName": "Leslie Herod",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_herod%2Cleslie.jpg?itok=VdIKST8_",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-CO-8-lower-ocd-person-a91826de-a61a-44e8-84b1-9f31f3dd32cb",
              "builtID": "co-lower-8",
              "externalID": "ocd-person/a91826de-a61a-44e8-84b1-9f31f3dd32cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "leslie.herod.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/leslie-herod"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-8-lower-ocd-person-a91826de-a61a-44e8-84b1-9f31f3dd32cb": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/c8c1a9c5-c7c2-4894-8829-fc83251bcaf2",
            "firstName": "Emily Lipp",
            "lastName": "Sirota",
            "fullName": "Emily Sirota",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_sirota%2Cemily.jpg?itok=lU6YsziF",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-CO-9-lower-ocd-person-c8c1a9c5-c7c2-4894-8829-fc83251bcaf2",
              "builtID": "co-lower-9",
              "externalID": "ocd-person/c8c1a9c5-c7c2-4894-8829-fc83251bcaf2",
              "geometry": null
            },
            "contactInfo": {
              "email": "emily.sirota.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/emily-sirota"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-9-lower-ocd-person-c8c1a9c5-c7c2-4894-8829-fc83251bcaf2": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/0c2271df-5808-42ff-9550-700f454b8b3a",
            "firstName": "Junie",
            "lastName": "Joseph",
            "fullName": "Junie Joseph",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_joseph%2Cjunie.jpg?itok=Lx4YgUSv",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-CO-10-lower-ocd-person-0c2271df-5808-42ff-9550-700f454b8b3a",
              "builtID": "co-lower-10",
              "externalID": "ocd-person/0c2271df-5808-42ff-9550-700f454b8b3a",
              "geometry": null
            },
            "contactInfo": {
              "email": "junie.joseph.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/junie-joseph"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-10-lower-ocd-person-0c2271df-5808-42ff-9550-700f454b8b3a": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/7631027c-2467-4112-8f03-abad6c6ed8f2",
            "firstName": "Karen A.",
            "lastName": "McCormick",
            "fullName": "Karen McCormick",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_mccormick%2Ckaren.jpg?itok=b2GRKW9h",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-CO-11-lower-ocd-person-7631027c-2467-4112-8f03-abad6c6ed8f2",
              "builtID": "co-lower-11",
              "externalID": "ocd-person/7631027c-2467-4112-8f03-abad6c6ed8f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "karen.mccormick.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/karen-mccormick"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-11-lower-ocd-person-7631027c-2467-4112-8f03-abad6c6ed8f2": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/f376a75e-e0b0-4bf7-95b1-0931ade3bdb8",
            "firstName": "Kyle",
            "lastName": "Brown",
            "fullName": "Kyle Brown",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_kyle%20brown.jpg?itok=wfdtmHtz",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-CO-12-lower-ocd-person-f376a75e-e0b0-4bf7-95b1-0931ade3bdb8",
              "builtID": "co-lower-12",
              "externalID": "ocd-person/f376a75e-e0b0-4bf7-95b1-0931ade3bdb8",
              "geometry": null
            },
            "contactInfo": {
              "email": "kyle.brown.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/kyle-brown"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-12-lower-ocd-person-f376a75e-e0b0-4bf7-95b1-0931ade3bdb8": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/53facaaf-5673-4c76-b15a-6b5061965ae1",
            "firstName": "Julie Lynn",
            "lastName": "McCluskie",
            "fullName": "Julie McCluskie",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_mccluskie%2C%20julie%20-%20speaker.jpg?itok=Gnk_5Fmt",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-CO-13-lower-ocd-person-53facaaf-5673-4c76-b15a-6b5061965ae1",
              "builtID": "co-lower-13",
              "externalID": "ocd-person/53facaaf-5673-4c76-b15a-6b5061965ae1",
              "geometry": null
            },
            "contactInfo": {
              "email": "julie.mccluskie.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/julie-mccluskie"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-13-lower-ocd-person-53facaaf-5673-4c76-b15a-6b5061965ae1": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/fff006df-2e97-49e4-a0a8-8ace6a38c3ac",
            "firstName": "Rose Femia",
            "lastName": "Pugliese",
            "fullName": "Rose Pugliese",
            "gender": "Female",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_pugliese%2Crose.jpg?itok=u58QR1cb",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-CO-14-lower-ocd-person-fff006df-2e97-49e4-a0a8-8ace6a38c3ac",
              "builtID": "co-lower-14",
              "externalID": "ocd-person/fff006df-2e97-49e4-a0a8-8ace6a38c3ac",
              "geometry": null
            },
            "contactInfo": {
              "email": "rose.pugliese.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/rose-pugliese"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-14-lower-ocd-person-fff006df-2e97-49e4-a0a8-8ace6a38c3ac": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/66254480-6178-4312-8a18-268ec84153cc",
            "firstName": "Scott T.",
            "lastName": "Bottoms",
            "fullName": "Scott Bottoms",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_scott%20bottoms.jpg?itok=q9FETlLN",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-CO-15-lower-ocd-person-66254480-6178-4312-8a18-268ec84153cc",
              "builtID": "co-lower-15",
              "externalID": "ocd-person/66254480-6178-4312-8a18-268ec84153cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.bottoms.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/scott-bottoms"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-15-lower-ocd-person-66254480-6178-4312-8a18-268ec84153cc": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/1be59924-d43f-4b3c-83e7-e344999b7c22",
            "firstName": "Stephanie",
            "lastName": "Vigil",
            "fullName": "Stephanie Vigil",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_vigil%2Cstephanie.jpg?itok=LrJtS_2V",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-CO-16-lower-ocd-person-1be59924-d43f-4b3c-83e7-e344999b7c22",
              "builtID": "co-lower-16",
              "externalID": "ocd-person/1be59924-d43f-4b3c-83e7-e344999b7c22",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephanie.vigil.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/stephanie-vigil"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-16-lower-ocd-person-1be59924-d43f-4b3c-83e7-e344999b7c22": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/d5be5146-f7a9-46eb-86f9-b0d1cebc4e9c",
            "firstName": "Regina",
            "lastName": "English",
            "fullName": "Regina English",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_english%2Cregina.jpg?itok=JnUXbH3O",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-CO-17-lower-ocd-person-d5be5146-f7a9-46eb-86f9-b0d1cebc4e9c",
              "builtID": "co-lower-17",
              "externalID": "ocd-person/d5be5146-f7a9-46eb-86f9-b0d1cebc4e9c",
              "geometry": null
            },
            "contactInfo": {
              "email": "regina.english.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/regina-english"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-17-lower-ocd-person-d5be5146-f7a9-46eb-86f9-b0d1cebc4e9c": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/5dbb7ac2-a4c8-42b8-bfb1-1b012737fa74",
            "firstName": "Marc Alan",
            "lastName": "Snyder",
            "fullName": "Marc Snyder",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_snyder%2C%20marc.jpg?itok=H0ekSgX2",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-CO-18-lower-ocd-person-5dbb7ac2-a4c8-42b8-bfb1-1b012737fa74",
              "builtID": "co-lower-18",
              "externalID": "ocd-person/5dbb7ac2-a4c8-42b8-bfb1-1b012737fa74",
              "geometry": null
            },
            "contactInfo": {
              "email": "marc.snyder.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/marc-snyder"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-18-lower-ocd-person-5dbb7ac2-a4c8-42b8-bfb1-1b012737fa74": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/aee8f504-13dc-4902-bad5-d86e089c995a",
            "firstName": "Jennifer",
            "lastName": "Parenti",
            "fullName": "Jennifer Parenti",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_parenti%2Cjennifer-.jpg?itok=Hk7OhXAg",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-CO-19-lower-ocd-person-aee8f504-13dc-4902-bad5-d86e089c995a",
              "builtID": "co-lower-19",
              "externalID": "ocd-person/aee8f504-13dc-4902-bad5-d86e089c995a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jennifer.parenti.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.colorado.gov/legislators/jennifer-lea-parenti"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-19-lower-ocd-person-aee8f504-13dc-4902-bad5-d86e089c995a": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/5a795fb2-8fe6-47ef-b5ee-c3af3124e413",
            "firstName": "Don",
            "lastName": "Wilson",
            "fullName": "Don Wilson",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_wilson%2Cdon-.jpg?itok=1itn3OYm",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-CO-20-lower-ocd-person-5a795fb2-8fe6-47ef-b5ee-c3af3124e413",
              "builtID": "co-lower-20",
              "externalID": "ocd-person/5a795fb2-8fe6-47ef-b5ee-c3af3124e413",
              "geometry": null
            },
            "contactInfo": {
              "email": "don.wilson.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/don-wilson"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-20-lower-ocd-person-5a795fb2-8fe6-47ef-b5ee-c3af3124e413": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/9e1ed481-cc42-4186-a8fe-891abba60162",
            "firstName": "Mary",
            "lastName": "Bradfield",
            "fullName": "Mary Bradfield",
            "gender": "Female",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_bradfield%2Cmary.jpg?itok=QsKqEtTp",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-CO-21-lower-ocd-person-9e1ed481-cc42-4186-a8fe-891abba60162",
              "builtID": "co-lower-21",
              "externalID": "ocd-person/9e1ed481-cc42-4186-a8fe-891abba60162",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.bradfield.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/mary-bradfield"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-21-lower-ocd-person-9e1ed481-cc42-4186-a8fe-891abba60162": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/767c8676-7cf0-45bf-9e20-e615d4d13c5e",
            "firstName": "Kenneth G.",
            "lastName": "DeGraaf",
            "fullName": "Ken DeGraaf",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_degraaf%2Cken.jpg?itok=kBCJZk7B",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-CO-22-lower-ocd-person-767c8676-7cf0-45bf-9e20-e615d4d13c5e",
              "builtID": "co-lower-22",
              "externalID": "ocd-person/767c8676-7cf0-45bf-9e20-e615d4d13c5e",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.degraaf.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/ken-degraaf"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-22-lower-ocd-person-767c8676-7cf0-45bf-9e20-e615d4d13c5e": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/d8b6809c-61d9-4ea3-b21b-c846051468c2",
            "firstName": "Monica Irasema",
            "lastName": "Duran",
            "fullName": "Monica Duran",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_duran%2C%20monica.jpg?itok=weFukimD",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-CO-23-lower-ocd-person-d8b6809c-61d9-4ea3-b21b-c846051468c2",
              "builtID": "co-lower-23",
              "externalID": "ocd-person/d8b6809c-61d9-4ea3-b21b-c846051468c2",
              "geometry": null
            },
            "contactInfo": {
              "email": "monica.duran.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/monica-duran"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-23-lower-ocd-person-d8b6809c-61d9-4ea3-b21b-c846051468c2": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/2de0a3a1-a1e3-477f-88b9-3212fa52fb9c",
            "firstName": "Lindsey Nichole",
            "lastName": "Daugherty",
            "fullName": "Lindsey Daugherty",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_daugherty%2Clindsey.jpg?itok=27KHw-jU",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-CO-24-lower-ocd-person-2de0a3a1-a1e3-477f-88b9-3212fa52fb9c",
              "builtID": "co-lower-24",
              "externalID": "ocd-person/2de0a3a1-a1e3-477f-88b9-3212fa52fb9c",
              "geometry": null
            },
            "contactInfo": {
              "email": "lindsey.daugherty.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.colorado.gov/legislators/lindsey-daugherty"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-24-lower-ocd-person-2de0a3a1-a1e3-477f-88b9-3212fa52fb9c": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/489b3afa-1e08-40f4-918a-f01c186b6f73",
            "firstName": "Tamara Lee",
            "lastName": "Story",
            "fullName": "Tammy Story",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_story%2Ctammy.jpg?itok=NP2YRnLu",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-CO-25-lower-ocd-person-489b3afa-1e08-40f4-918a-f01c186b6f73",
              "builtID": "co-lower-25",
              "externalID": "ocd-person/489b3afa-1e08-40f4-918a-f01c186b6f73",
              "geometry": null
            },
            "contactInfo": {
              "email": "tammy.story.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/tammy-story"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-25-lower-ocd-person-489b3afa-1e08-40f4-918a-f01c186b6f73": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/c1a18202-08ad-487f-99bb-b1a606c15a22",
            "firstName": "Meghan",
            "lastName": "Lukens",
            "fullName": "Meghan Lukens",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_lukens%2Cmeghan.jpg?itok=uumAlRsU",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-CO-26-lower-ocd-person-c1a18202-08ad-487f-99bb-b1a606c15a22",
              "builtID": "co-lower-26",
              "externalID": "ocd-person/c1a18202-08ad-487f-99bb-b1a606c15a22",
              "geometry": null
            },
            "contactInfo": {
              "email": "meghan.lukens.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/meghan-lukens"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-26-lower-ocd-person-c1a18202-08ad-487f-99bb-b1a606c15a22": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/fa9b604d-5981-4de1-97bc-9400e03909c9",
            "firstName": "Brianna",
            "lastName": "Titone",
            "fullName": "Brianna Titone",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_titone%2Cbrianna.jpg?itok=uJAlJK9O",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-CO-27-lower-ocd-person-fa9b604d-5981-4de1-97bc-9400e03909c9",
              "builtID": "co-lower-27",
              "externalID": "ocd-person/fa9b604d-5981-4de1-97bc-9400e03909c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "brianna.titone.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/brianna-titone"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-27-lower-ocd-person-fa9b604d-5981-4de1-97bc-9400e03909c9": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/b44045eb-e2fb-4723-9e91-d68fb55c0bf7",
            "firstName": "Sheila",
            "lastName": "Lieder",
            "fullName": "Sheila Lieder",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_lieder%2Csheila.jpg?itok=eytxsbcE",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-CO-28-lower-ocd-person-b44045eb-e2fb-4723-9e91-d68fb55c0bf7",
              "builtID": "co-lower-28",
              "externalID": "ocd-person/b44045eb-e2fb-4723-9e91-d68fb55c0bf7",
              "geometry": null
            },
            "contactInfo": {
              "email": "sheila.lieder.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/sheila-lieder"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-28-lower-ocd-person-b44045eb-e2fb-4723-9e91-d68fb55c0bf7": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/308dfb14-bd0d-4e11-b86b-384960e3152c",
            "firstName": "Shannon Kathleen",
            "lastName": "Bird",
            "fullName": "Shannon Bird",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_bird%2Cshannon.jpg?itok=CealA-8A",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-CO-29-lower-ocd-person-308dfb14-bd0d-4e11-b86b-384960e3152c",
              "builtID": "co-lower-29",
              "externalID": "ocd-person/308dfb14-bd0d-4e11-b86b-384960e3152c",
              "geometry": null
            },
            "contactInfo": {
              "email": "shannon.bird.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/shannon-bird"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-29-lower-ocd-person-308dfb14-bd0d-4e11-b86b-384960e3152c": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/92715424-bafa-4ad8-b311-eca3e575c86f",
            "firstName": "Christopher deGruy",
            "lastName": "Kennedy",
            "fullName": "Chris Kennedy",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_degruy%20kennedy%2Cchris.jpg?itok=uc7A-4tt",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-CO-30-lower-ocd-person-92715424-bafa-4ad8-b311-eca3e575c86f",
              "builtID": "co-lower-30",
              "externalID": "ocd-person/92715424-bafa-4ad8-b311-eca3e575c86f",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.kennedy.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.colorado.gov/legislators/chris-kennedy",
                "https://leg.colorado.gov/legislators/chris-kennedy",
                "https://leg.colorado.gov/legislators/chris-degruy-kennedy"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-30-lower-ocd-person-92715424-bafa-4ad8-b311-eca3e575c86f": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/7ae447e4-dee5-4dc2-b0be-18b075ae4fba",
            "firstName": "Julia",
            "lastName": "Marvin",
            "fullName": "Julia Marvin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://leg.colorado.gov/sites/default/files/styles/width_300/public/2024a_julia%20marvin.jpg?itok=B1_mHx8I",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-CO-31-lower-ocd-person-7ae447e4-dee5-4dc2-b0be-18b075ae4fba",
              "builtID": "co-lower-31",
              "externalID": "ocd-person/7ae447e4-dee5-4dc2-b0be-18b075ae4fba",
              "geometry": null
            },
            "contactInfo": {
              "email": "julia.marvin.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/julia-marvin"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-31-lower-ocd-person-7ae447e4-dee5-4dc2-b0be-18b075ae4fba": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/ce4bd827-c1bc-4736-a580-f4f0215e727d",
            "firstName": "Tonty",
            "lastName": "Rutinel",
            "fullName": "Manny Rutinel",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_rep%20rutinel%20v2.png?itok=m5Xp301H",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-CO-32-lower-ocd-person-ce4bd827-c1bc-4736-a580-f4f0215e727d",
              "builtID": "co-lower-32",
              "externalID": "ocd-person/ce4bd827-c1bc-4736-a580-f4f0215e727d",
              "geometry": null
            },
            "contactInfo": {
              "email": "manny.rutinel.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/manny-rutinel"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-32-lower-ocd-person-ce4bd827-c1bc-4736-a580-f4f0215e727d": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/dbe86f29-2ecb-449e-aaf6-3fc91baf2e0e",
            "firstName": "William",
            "lastName": "Lindstedt",
            "fullName": "William Lindstedt",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_lindstedt%2Cwilliam.jpg?itok=UVhLAS88",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-CO-33-lower-ocd-person-dbe86f29-2ecb-449e-aaf6-3fc91baf2e0e",
              "builtID": "co-lower-33",
              "externalID": "ocd-person/dbe86f29-2ecb-449e-aaf6-3fc91baf2e0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.lindstedt.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.colorado.gov/legislators/william-lindstedt"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-33-lower-ocd-person-dbe86f29-2ecb-449e-aaf6-3fc91baf2e0e": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/655c629d-72f6-45d1-bd8f-a241e200d089",
            "firstName": "Jenny Clay",
            "lastName": "Willford",
            "fullName": "Jenny Willford",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_willford%2Cjenny.jpg?itok=ydfmMYJj",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-CO-34-lower-ocd-person-655c629d-72f6-45d1-bd8f-a241e200d089",
              "builtID": "co-lower-34",
              "externalID": "ocd-person/655c629d-72f6-45d1-bd8f-a241e200d089",
              "geometry": null
            },
            "contactInfo": {
              "email": "jenny.willford.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/jenny-willford"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-34-lower-ocd-person-655c629d-72f6-45d1-bd8f-a241e200d089": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/d7569723-ddde-4706-a857-422804198fa8",
            "firstName": "Lorena",
            "lastName": "Garcia",
            "fullName": "Lorena García",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_garcia%2Clorena.jpg?itok=AB_Ecmz-",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-CO-35-lower-ocd-person-d7569723-ddde-4706-a857-422804198fa8",
              "builtID": "co-lower-35",
              "externalID": "ocd-person/d7569723-ddde-4706-a857-422804198fa8",
              "geometry": null
            },
            "contactInfo": {
              "email": "lorena.garcia.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.colorado.gov/legislators/lorena-garc%C3%ADa",
                "https://leg.colorado.gov/legislators/lorena-garcia"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-35-lower-ocd-person-d7569723-ddde-4706-a857-422804198fa8": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/b680cb39-442c-4dd2-81bd-88696cfe9df2",
            "firstName": "Mike",
            "lastName": "Weissman",
            "fullName": "Mike Weissman",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_weissman%2Cmike.jpg?itok=7RFIpBao",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-CO-36-lower-ocd-person-b680cb39-442c-4dd2-81bd-88696cfe9df2",
              "builtID": "co-lower-36",
              "externalID": "ocd-person/b680cb39-442c-4dd2-81bd-88696cfe9df2",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.weissman.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/mike-weissman"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-36-lower-ocd-person-b680cb39-442c-4dd2-81bd-88696cfe9df2": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/faaae748-7c57-47df-bdf7-1811e0a61458",
            "firstName": "Chad",
            "lastName": "Clifford",
            "fullName": "Chad Clifford",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-CO-37-lower-ocd-person-faaae748-7c57-47df-bdf7-1811e0a61458",
              "builtID": "co-lower-37",
              "externalID": "ocd-person/faaae748-7c57-47df-bdf7-1811e0a61458",
              "geometry": null
            },
            "contactInfo": {
              "email": "chad.clifford.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/chad-clifford"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-37-lower-ocd-person-faaae748-7c57-47df-bdf7-1811e0a61458": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/67afefca-857c-4feb-8739-f7c68d6e6b71",
            "firstName": "David Daniel",
            "lastName": "Ortiz",
            "fullName": "David Ortiz",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_ortiz%2Cdavid.jpg?itok=9b7YEvLT",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-CO-38-lower-ocd-person-67afefca-857c-4feb-8739-f7c68d6e6b71",
              "builtID": "co-lower-38",
              "externalID": "ocd-person/67afefca-857c-4feb-8739-f7c68d6e6b71",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.ortiz.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/david-ortiz"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-38-lower-ocd-person-67afefca-857c-4feb-8739-f7c68d6e6b71": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/5a396024-8dcf-438c-beca-a730e972d9d8",
            "firstName": "Brandi",
            "lastName": "Bradley",
            "fullName": "Brandi Bradley",
            "gender": "Female",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_bradley%2Cbrandi.jpg?itok=gkSqfLu8",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-CO-39-lower-ocd-person-5a396024-8dcf-438c-beca-a730e972d9d8",
              "builtID": "co-lower-39",
              "externalID": "ocd-person/5a396024-8dcf-438c-beca-a730e972d9d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandi.bradley.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/brandi-bradley"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-39-lower-ocd-person-5a396024-8dcf-438c-beca-a730e972d9d8": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/64f01ef4-238f-4e48-9657-e221e0d030c0",
            "firstName": "Naquetta",
            "lastName": "Ricks",
            "fullName": "Naquetta Ricks",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_ricks%2Cnaquetta-.jpg?itok=ZtahH919",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-CO-40-lower-ocd-person-64f01ef4-238f-4e48-9657-e221e0d030c0",
              "builtID": "co-lower-40",
              "externalID": "ocd-person/64f01ef4-238f-4e48-9657-e221e0d030c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "naquetta.ricks.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/naquetta-ricks"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-40-lower-ocd-person-64f01ef4-238f-4e48-9657-e221e0d030c0": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/506f18ea-3611-408b-aa0e-73adf9681efe",
            "firstName": "Iman Mohamad",
            "lastName": "Jodeh",
            "fullName": "Iman Jodeh",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_jodeh%2C%20iman.jpg?itok=rVcBxpYT",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-CO-41-lower-ocd-person-506f18ea-3611-408b-aa0e-73adf9681efe",
              "builtID": "co-lower-41",
              "externalID": "ocd-person/506f18ea-3611-408b-aa0e-73adf9681efe",
              "geometry": null
            },
            "contactInfo": {
              "email": "iman.jodeh.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/iman-jodeh"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-41-lower-ocd-person-506f18ea-3611-408b-aa0e-73adf9681efe": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/2582d3dd-20c0-4d15-83a0-3b6dab1e98d2",
            "firstName": "Mandy",
            "lastName": "Lindsay",
            "fullName": "Mandy Lindsay",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_lindsay%2Cmandy.jpg?itok=FHjKFjNG",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-CO-42-lower-ocd-person-2582d3dd-20c0-4d15-83a0-3b6dab1e98d2",
              "builtID": "co-lower-42",
              "externalID": "ocd-person/2582d3dd-20c0-4d15-83a0-3b6dab1e98d2",
              "geometry": null
            },
            "contactInfo": {
              "email": "mandy.lindsay.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/mandy-lindsay"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-42-lower-ocd-person-2582d3dd-20c0-4d15-83a0-3b6dab1e98d2": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/ade56d2d-41d7-436d-b76b-85051f036c7a",
            "firstName": "Robert",
            "lastName": "Marshall",
            "fullName": "Bob Marshall",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_marshall%2C%20bob.jpg?itok=5ohSNQXK",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-CO-43-lower-ocd-person-ade56d2d-41d7-436d-b76b-85051f036c7a",
              "builtID": "co-lower-43",
              "externalID": "ocd-person/ade56d2d-41d7-436d-b76b-85051f036c7a",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.marshall.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/bob-marshall"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-43-lower-ocd-person-ade56d2d-41d7-436d-b76b-85051f036c7a": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/561892b1-c618-43cb-9767-9237514f0a92",
            "firstName": "Anthony J.",
            "lastName": "Hartsook",
            "fullName": "Tony Hartsook",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_hartsook%2Canthony.jpg?itok=jDCFtuOk",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-CO-44-lower-ocd-person-561892b1-c618-43cb-9767-9237514f0a92",
              "builtID": "co-lower-44",
              "externalID": "ocd-person/561892b1-c618-43cb-9767-9237514f0a92",
              "geometry": null
            },
            "contactInfo": {
              "email": "anthony.hartsook.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/anthony-hartsook"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-44-lower-ocd-person-561892b1-c618-43cb-9767-9237514f0a92": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/fce5c955-f43d-4f80-bad5-8369223bc0ea",
            "firstName": "Elizabeth Norris",
            "lastName": "Frizell",
            "fullName": "Lisa Frizell",
            "gender": "Female",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_frizell%2Clisa.jpg?itok=1nUPX3uM",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-CO-45-lower-ocd-person-fce5c955-f43d-4f80-bad5-8369223bc0ea",
              "builtID": "co-lower-45",
              "externalID": "ocd-person/fce5c955-f43d-4f80-bad5-8369223bc0ea",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisa.frizell.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/lisa-frizell"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-45-lower-ocd-person-fce5c955-f43d-4f80-bad5-8369223bc0ea": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/851b5f31-0694-4674-a3b8-468b6eb1f0a8",
            "firstName": "Tisha",
            "lastName": "Mauro",
            "fullName": "Tisha Mauro",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_t%20mauro.jpg?itok=05-hhChK",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-CO-46-lower-ocd-person-851b5f31-0694-4674-a3b8-468b6eb1f0a8",
              "builtID": "co-lower-46",
              "externalID": "ocd-person/851b5f31-0694-4674-a3b8-468b6eb1f0a8",
              "geometry": null
            },
            "contactInfo": {
              "email": "tisha.mauro.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/tisha-mauro"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-46-lower-ocd-person-851b5f31-0694-4674-a3b8-468b6eb1f0a8": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/f3cd2006-7729-41b0-a009-0c5cf64e95e0",
            "firstName": "Ty",
            "lastName": "Winter",
            "fullName": "Ty Winter",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_winter%2Cty.jpg?itok=aGQTR3tx",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-CO-47-lower-ocd-person-f3cd2006-7729-41b0-a009-0c5cf64e95e0",
              "builtID": "co-lower-47",
              "externalID": "ocd-person/f3cd2006-7729-41b0-a009-0c5cf64e95e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "ty.winter.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/ty-winter"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-47-lower-ocd-person-f3cd2006-7729-41b0-a009-0c5cf64e95e0": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/2b736767-4c7e-4cd2-bd94-686a9302bba0",
            "firstName": "Timothy Gabriel Joseph",
            "lastName": "Evans",
            "fullName": "Gabe Evans",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_evans%2Cgabe.jpg?itok=Co4Gpe6X",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-CO-48-lower-ocd-person-2b736767-4c7e-4cd2-bd94-686a9302bba0",
              "builtID": "co-lower-48",
              "externalID": "ocd-person/2b736767-4c7e-4cd2-bd94-686a9302bba0",
              "geometry": null
            },
            "contactInfo": {
              "email": "gabe.evans.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/gabe-evans"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-48-lower-ocd-person-2b736767-4c7e-4cd2-bd94-686a9302bba0": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/829879ea-a9a3-42b6-8f5f-d209b1cc961d",
            "firstName": "Judith",
            "lastName": "Amabile",
            "fullName": "Judy Amabile",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_amabile%2Cjudy.jpg?itok=7ZbVKo7E",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-CO-49-lower-ocd-person-829879ea-a9a3-42b6-8f5f-d209b1cc961d",
              "builtID": "co-lower-49",
              "externalID": "ocd-person/829879ea-a9a3-42b6-8f5f-d209b1cc961d",
              "geometry": null
            },
            "contactInfo": {
              "email": "judy.amabile.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/judy-amabile"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-49-lower-ocd-person-829879ea-a9a3-42b6-8f5f-d209b1cc961d": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/ffc5caa4-29c4-475a-b662-a814bae9f8c5",
            "firstName": "Mary McNamara",
            "lastName": "Young",
            "fullName": "Mary Young",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_young%2Cmary.jpg?itok=ZUDtijEn",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-CO-50-lower-ocd-person-ffc5caa4-29c4-475a-b662-a814bae9f8c5",
              "builtID": "co-lower-50",
              "externalID": "ocd-person/ffc5caa4-29c4-475a-b662-a814bae9f8c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.young.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/mary-young"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-50-lower-ocd-person-ffc5caa4-29c4-475a-b662-a814bae9f8c5": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/9b1256a4-11c6-4c9e-81e6-2e0a3c5871be",
            "firstName": "Ron",
            "lastName": "Weinberg",
            "fullName": "Ron Weinberg",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_rep%20ron%20weinberg.jpg?itok=7Z-RCYNp",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-CO-51-lower-ocd-person-9b1256a4-11c6-4c9e-81e6-2e0a3c5871be",
              "builtID": "co-lower-51",
              "externalID": "ocd-person/9b1256a4-11c6-4c9e-81e6-2e0a3c5871be",
              "geometry": null
            },
            "contactInfo": {
              "email": "ron.weinberg.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/ron-weinberg"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-51-lower-ocd-person-9b1256a4-11c6-4c9e-81e6-2e0a3c5871be": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/0ddf3b75-c450-419e-8235-553157770e49",
            "firstName": "Cathy",
            "lastName": "Kipp",
            "fullName": "Cathy Kipp",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_kipp%2Ccathy-.jpg?itok=5TZvGZWf",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-CO-52-lower-ocd-person-0ddf3b75-c450-419e-8235-553157770e49",
              "builtID": "co-lower-52",
              "externalID": "ocd-person/0ddf3b75-c450-419e-8235-553157770e49",
              "geometry": null
            },
            "contactInfo": {
              "email": "cathy.kipp.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/cathy-kipp"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-52-lower-ocd-person-0ddf3b75-c450-419e-8235-553157770e49": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/278f042b-4fe3-4325-ad59-bc240e4fda0c",
            "firstName": "Andrew",
            "lastName": "Boesenecker",
            "fullName": "Andy Boesenecker",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_boesenecker%2Candrew.jpg?itok=3jMO1y9L",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-CO-53-lower-ocd-person-278f042b-4fe3-4325-ad59-bc240e4fda0c",
              "builtID": "co-lower-53",
              "externalID": "ocd-person/278f042b-4fe3-4325-ad59-bc240e4fda0c",
              "geometry": null
            },
            "contactInfo": {
              "email": "andrew.boesenecker.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.colorado.gov/legislators/andrew-boesenecker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-53-lower-ocd-person-278f042b-4fe3-4325-ad59-bc240e4fda0c": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/9e529237-916b-407c-a60e-abd8e0708329",
            "firstName": "Matt",
            "lastName": "Soper",
            "fullName": "Matt Soper",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_soper%2C%20matt.jpg?itok=n4TTkQb0",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-CO-54-lower-ocd-person-9e529237-916b-407c-a60e-abd8e0708329",
              "builtID": "co-lower-54",
              "externalID": "ocd-person/9e529237-916b-407c-a60e-abd8e0708329",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.soper.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/matt-soper"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-54-lower-ocd-person-9e529237-916b-407c-a60e-abd8e0708329": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/5dae0c7f-430e-42a2-906d-9e177782f91b",
            "firstName": "John Merrick",
            "lastName": "Taggart",
            "fullName": "Rick Taggart",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_taggartrick.jpg?itok=nCWfv2vG",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-CO-55-lower-ocd-person-5dae0c7f-430e-42a2-906d-9e177782f91b",
              "builtID": "co-lower-55",
              "externalID": "ocd-person/5dae0c7f-430e-42a2-906d-9e177782f91b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.taggart.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/rick-taggart"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-55-lower-ocd-person-5dae0c7f-430e-42a2-906d-9e177782f91b": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/134e76cb-3f07-48cf-8bee-580e00786b82",
            "firstName": "Rod",
            "lastName": "Bockenfeld",
            "fullName": "Rod Bockenfeld",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_bockenfeld%2Crod.jpg?itok=ha797UxR",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-CO-56-lower-ocd-person-134e76cb-3f07-48cf-8bee-580e00786b82",
              "builtID": "co-lower-56",
              "externalID": "ocd-person/134e76cb-3f07-48cf-8bee-580e00786b82",
              "geometry": null
            },
            "contactInfo": {
              "email": "rod.bockenfeld.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/rod-bockenfeld"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-56-lower-ocd-person-134e76cb-3f07-48cf-8bee-580e00786b82": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/bd50bf12-cf63-4a3d-a887-93008333e694",
            "firstName": "Elizabeth",
            "lastName": "Velasco",
            "fullName": "Elizabeth Velasco",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_velasco%2Celizabeth.jpg?itok=y0rtvQsw",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-CO-57-lower-ocd-person-bd50bf12-cf63-4a3d-a887-93008333e694",
              "builtID": "co-lower-57",
              "externalID": "ocd-person/bd50bf12-cf63-4a3d-a887-93008333e694",
              "geometry": null
            },
            "contactInfo": {
              "email": "elizabeth.velasco.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.colorado.gov/legislators/elizabeth-velasco"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-57-lower-ocd-person-bd50bf12-cf63-4a3d-a887-93008333e694": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/c0cfa7d3-2c5e-4b68-8692-2e1b6f1b3372",
            "firstName": "Marc",
            "lastName": "Catlin",
            "fullName": "Marc Catlin",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_catlin%2Cmarc.jpg?itok=H8c5qcvv",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-CO-58-lower-ocd-person-c0cfa7d3-2c5e-4b68-8692-2e1b6f1b3372",
              "builtID": "co-lower-58",
              "externalID": "ocd-person/c0cfa7d3-2c5e-4b68-8692-2e1b6f1b3372",
              "geometry": null
            },
            "contactInfo": {
              "email": "marc.catlin.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/marc-catlin"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-58-lower-ocd-person-c0cfa7d3-2c5e-4b68-8692-2e1b6f1b3372": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/4ee693c2-1cdc-4631-a6e5-b541e7f9c797",
            "firstName": "Barbara",
            "lastName": "McLachlan",
            "fullName": "Barbara McLachlan",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_mclachlan%2Cbarbara.jpg?itok=3uCeUxVp",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-CO-59-lower-ocd-person-4ee693c2-1cdc-4631-a6e5-b541e7f9c797",
              "builtID": "co-lower-59",
              "externalID": "ocd-person/4ee693c2-1cdc-4631-a6e5-b541e7f9c797",
              "geometry": null
            },
            "contactInfo": {
              "email": "barbara.mclachlan.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.colorado.gov/legislators/barbara-mclachlan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-59-lower-ocd-person-4ee693c2-1cdc-4631-a6e5-b541e7f9c797": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/3e7145a3-8729-497c-a168-db98d22cf015",
            "firstName": "Stephanie",
            "lastName": "Luck",
            "fullName": "Stephanie Luck",
            "gender": "Female",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_luck%2C%20stephanie.jpg?itok=b1DCrqcw",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-CO-60-lower-ocd-person-3e7145a3-8729-497c-a168-db98d22cf015",
              "builtID": "co-lower-60",
              "externalID": "ocd-person/3e7145a3-8729-497c-a168-db98d22cf015",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephanie.luck.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/stephanie-luck"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-60-lower-ocd-person-3e7145a3-8729-497c-a168-db98d22cf015": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/c02b7bf5-a583-43c0-b6d6-2e757c7d42b7",
            "firstName": "Eliza C.",
            "lastName": "Hamrick",
            "fullName": "Eliza Hamrick",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_hamrick%2Celiza-.jpg?itok=x__dbth_",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-CO-61-lower-ocd-person-c02b7bf5-a583-43c0-b6d6-2e757c7d42b7",
              "builtID": "co-lower-61",
              "externalID": "ocd-person/c02b7bf5-a583-43c0-b6d6-2e757c7d42b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "eliza.hamrick.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/eliza-hamrick"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-61-lower-ocd-person-c02b7bf5-a583-43c0-b6d6-2e757c7d42b7": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/27716b46-14de-4097-a29c-1a882ae3cd3a",
            "firstName": "Matthew",
            "lastName": "Martinez",
            "fullName": "Matt Martinez",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_martinez%2Cmatthew.jpg?itok=JOH2bZF1",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-CO-62-lower-ocd-person-27716b46-14de-4097-a29c-1a882ae3cd3a",
              "builtID": "co-lower-62",
              "externalID": "ocd-person/27716b46-14de-4097-a29c-1a882ae3cd3a",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.martinez.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/matthew-martinez"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-62-lower-ocd-person-27716b46-14de-4097-a29c-1a882ae3cd3a": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/6ce16578-2d36-4bd6-a456-4b9f8a68bb3e",
            "firstName": "Richard M.",
            "lastName": "Alonso Holtorf",
            "fullName": "Richard Holtorf",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_holtorf%2Crichard-.jpg?itok=dRWYudQ7",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-CO-63-lower-ocd-person-6ce16578-2d36-4bd6-a456-4b9f8a68bb3e",
              "builtID": "co-lower-63",
              "externalID": "ocd-person/6ce16578-2d36-4bd6-a456-4b9f8a68bb3e",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.holtorf.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/richard-holtorf"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-63-lower-ocd-person-6ce16578-2d36-4bd6-a456-4b9f8a68bb3e": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/dd44278d-c02f-4c51-b686-ed8c85bb6718",
            "firstName": "Ryan",
            "lastName": "Armagost",
            "fullName": "Ryan Armagost",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_armagost%2Cryan.jpg?itok=XZOPNKg2",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-CO-64-lower-ocd-person-dd44278d-c02f-4c51-b686-ed8c85bb6718",
              "builtID": "co-lower-64",
              "externalID": "ocd-person/dd44278d-c02f-4c51-b686-ed8c85bb6718",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryan.armagost.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/ryan-armagost"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-64-lower-ocd-person-dd44278d-c02f-4c51-b686-ed8c85bb6718": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/551d839e-de95-4f9f-bb64-a36bb05fa396",
            "firstName": "Michael",
            "lastName": "Lynch",
            "fullName": "Mike Lynch",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_lynch%2C%20mike.jpg?itok=EXQUZdab",
            "title": "CO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-CO-65-lower-ocd-person-551d839e-de95-4f9f-bb64-a36bb05fa396",
              "builtID": "co-lower-65",
              "externalID": "ocd-person/551d839e-de95-4f9f-bb64-a36bb05fa396",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.lynch.house@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/mike-lynch"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CO-65-lower-ocd-person-551d839e-de95-4f9f-bb64-a36bb05fa396": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/2d0d060c-6d5f-45da-a069-fe2be14547eb",
            "firstName": "Byron H.",
            "lastName": "Pelton",
            "fullName": "Byron Pelton",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_pelton%2Cbyron-.jpg?itok=dSwGCsUR",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-CO-1-upper-ocd-person-2d0d060c-6d5f-45da-a069-fe2be14547eb",
              "builtID": "co-upper-1",
              "externalID": "ocd-person/2d0d060c-6d5f-45da-a069-fe2be14547eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "byron.pelton.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/byron-pelton"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-1-upper-ocd-person-2d0d060c-6d5f-45da-a069-fe2be14547eb": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/e389192e-edc9-448f-a6ca-6a9eb800ae8e",
            "firstName": "James",
            "lastName": "Smallwood",
            "fullName": "Jim Smallwood",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_smallwood%2C%20jim.jpg?itok=OpIRo2BF",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-CO-2-upper-ocd-person-e389192e-edc9-448f-a6ca-6a9eb800ae8e",
              "builtID": "co-upper-2",
              "externalID": "ocd-person/e389192e-edc9-448f-a6ca-6a9eb800ae8e",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorsmallwood@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/jim-smallwood"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-2-upper-ocd-person-e389192e-edc9-448f-a6ca-6a9eb800ae8e": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/4d753f6e-b7e3-420d-a684-e9a27f7ab27b",
            "firstName": "Nick",
            "lastName": "Hinrichsen",
            "fullName": "Nick Hinrichsen",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_hinrichsen%2Cnick.jpg?itok=dwNkn_3k",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-CO-3-upper-ocd-person-4d753f6e-b7e3-420d-a684-e9a27f7ab27b",
              "builtID": "co-upper-3",
              "externalID": "ocd-person/4d753f6e-b7e3-420d-a684-e9a27f7ab27b",
              "geometry": null
            },
            "contactInfo": {
              "email": "nick.hinrichsen.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/nick-hinrichsen"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-3-upper-ocd-person-4d753f6e-b7e3-420d-a684-e9a27f7ab27b": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/b4002b84-79a0-481a-94a8-00136c2dabb6",
            "firstName": "Mark",
            "lastName": "Baisley",
            "fullName": "Mark Baisley",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_baisley%2Cmark.jpg?itok=_MDPUMlG",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-CO-4-upper-ocd-person-b4002b84-79a0-481a-94a8-00136c2dabb6",
              "builtID": "co-upper-4",
              "externalID": "ocd-person/b4002b84-79a0-481a-94a8-00136c2dabb6",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.baisley@senate.co.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/mark-baisley"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-4-upper-ocd-person-b4002b84-79a0-481a-94a8-00136c2dabb6": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/c4686ac9-e2d6-454a-b101-d33b9f32255f",
            "firstName": "Perry",
            "lastName": "Will",
            "fullName": "Perry Will",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_will%2Cperry.jpg?itok=yEo_xyAI",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-CO-5-upper-ocd-person-c4686ac9-e2d6-454a-b101-d33b9f32255f",
              "builtID": "co-upper-5",
              "externalID": "ocd-person/c4686ac9-e2d6-454a-b101-d33b9f32255f",
              "geometry": null
            },
            "contactInfo": {
              "email": "perry.will.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/perry-will"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-5-upper-ocd-person-c4686ac9-e2d6-454a-b101-d33b9f32255f": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/715df6f2-cef6-4b88-b745-0207c8ffb4ff",
            "firstName": "Cleave",
            "lastName": "Simpson",
            "fullName": "Cleave Simpson",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_simpson%2Ccleave.jpg?itok=tY6QiPQW",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-CO-6-upper-ocd-person-715df6f2-cef6-4b88-b745-0207c8ffb4ff",
              "builtID": "co-upper-6",
              "externalID": "ocd-person/715df6f2-cef6-4b88-b745-0207c8ffb4ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "cleave.simpson.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/cleave-simpson"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-6-upper-ocd-person-715df6f2-cef6-4b88-b745-0207c8ffb4ff": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/2372415a-2aea-4423-8a3a-bed2d40493a6",
            "firstName": "Janice",
            "lastName": "Rich",
            "fullName": "Janice Rich",
            "gender": "Female",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_rich%2Cjanice-.jpg?itok=2ZTgyDv7",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-CO-7-upper-ocd-person-2372415a-2aea-4423-8a3a-bed2d40493a6",
              "builtID": "co-upper-7",
              "externalID": "ocd-person/2372415a-2aea-4423-8a3a-bed2d40493a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "janicerichsd7@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/janice-rich"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-7-upper-ocd-person-2372415a-2aea-4423-8a3a-bed2d40493a6": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/4be512fb-5ebc-422c-a858-d250cb5568ad",
            "firstName": "Dylan",
            "lastName": "Roberts",
            "fullName": "Dylan Roberts",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_roberts%2Cdylan.jpg?itok=reORJBqi",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-CO-8-upper-ocd-person-4be512fb-5ebc-422c-a858-d250cb5568ad",
              "builtID": "co-upper-8",
              "externalID": "ocd-person/4be512fb-5ebc-422c-a858-d250cb5568ad",
              "geometry": null
            },
            "contactInfo": {
              "email": "dylan.roberts.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/dylan-roberts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-8-upper-ocd-person-4be512fb-5ebc-422c-a858-d250cb5568ad": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/a58d77ac-807b-456e-8584-e4e08e98bf61",
            "firstName": "Paul",
            "lastName": "Lundeen",
            "fullName": "Paul Lundeen",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_lundeen%2Cpaul.jpg?itok=5vhWVd0B",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-CO-9-upper-ocd-person-a58d77ac-807b-456e-8584-e4e08e98bf61",
              "builtID": "co-upper-9",
              "externalID": "ocd-person/a58d77ac-807b-456e-8584-e4e08e98bf61",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.lundeen.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/paul-lundeen"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-9-upper-ocd-person-a58d77ac-807b-456e-8584-e4e08e98bf61": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/c9fd6b10-91a3-44ec-ac6a-48f73eee3ed1",
            "firstName": "Lawrence G.",
            "lastName": "Liston",
            "fullName": "Larry Liston",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_liston%2Clarry.jpg?itok=iMiSIx7h",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-CO-10-upper-ocd-person-c9fd6b10-91a3-44ec-ac6a-48f73eee3ed1",
              "builtID": "co-upper-10",
              "externalID": "ocd-person/c9fd6b10-91a3-44ec-ac6a-48f73eee3ed1",
              "geometry": null
            },
            "contactInfo": {
              "email": "larry.liston.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/larry-liston"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-10-upper-ocd-person-c9fd6b10-91a3-44ec-ac6a-48f73eee3ed1": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/473bd095-778d-4418-8e61-96a2b8005c8e",
            "firstName": "Thomas",
            "lastName": "Exum",
            "fullName": "Tony Exum",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_exum%2Ctony%20sr.jpg?itok=NGfbn-0x",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-CO-11-upper-ocd-person-473bd095-778d-4418-8e61-96a2b8005c8e",
              "builtID": "co-upper-11",
              "externalID": "ocd-person/473bd095-778d-4418-8e61-96a2b8005c8e",
              "geometry": null
            },
            "contactInfo": {
              "email": "tony.exum.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/tony-exum"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-11-upper-ocd-person-473bd095-778d-4418-8e61-96a2b8005c8e": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/167975f6-bf0d-49ea-885c-baea0fc0e4cd",
            "firstName": "Robert S.",
            "lastName": "Gardner",
            "fullName": "Bob Gardner",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_gardner%2Cbob.jpg?itok=LQYxknDj",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-CO-12-upper-ocd-person-167975f6-bf0d-49ea-885c-baea0fc0e4cd",
              "builtID": "co-upper-12",
              "externalID": "ocd-person/167975f6-bf0d-49ea-885c-baea0fc0e4cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.gardner.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/bob-gardner"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-12-upper-ocd-person-167975f6-bf0d-49ea-885c-baea0fc0e4cd": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/a68ea419-4fcb-4f7f-9797-77dd2b57ebca",
            "firstName": "Kevin",
            "lastName": "Priola",
            "fullName": "Kevin Priola",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_priola%2Ckevin.jpg?itok=isjSGRTv",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-CO-13-upper-ocd-person-a68ea419-4fcb-4f7f-9797-77dd2b57ebca",
              "builtID": "co-upper-13",
              "externalID": "ocd-person/a68ea419-4fcb-4f7f-9797-77dd2b57ebca",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.priola.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/kevin-priola"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-13-upper-ocd-person-a68ea419-4fcb-4f7f-9797-77dd2b57ebca": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/a9bfb020-3b00-4b9d-8c22-447e365c3b0f",
            "firstName": "Joann",
            "lastName": "Ginal",
            "fullName": "Joann Ginal",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_ginal%2Cjoann-crop.jpg?itok=_pn8tbc2",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-CO-14-upper-ocd-person-a9bfb020-3b00-4b9d-8c22-447e365c3b0f",
              "builtID": "co-upper-14",
              "externalID": "ocd-person/a9bfb020-3b00-4b9d-8c22-447e365c3b0f",
              "geometry": null
            },
            "contactInfo": {
              "email": "joann.ginal.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.colorado.gov/legislators/joann-ginal",
                "https://leg.colorado.gov/legislators/joann-ginal"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-14-upper-ocd-person-a9bfb020-3b00-4b9d-8c22-447e365c3b0f": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/2718c918-a38c-49c2-a120-5503164f959f",
            "firstName": "Janice",
            "lastName": "Marchman",
            "fullName": "Janice Marchman",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_marchman%2Cjanice.jpg?itok=Of-P3JI-",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-CO-15-upper-ocd-person-2718c918-a38c-49c2-a120-5503164f959f",
              "builtID": "co-upper-15",
              "externalID": "ocd-person/2718c918-a38c-49c2-a120-5503164f959f",
              "geometry": null
            },
            "contactInfo": {
              "email": "janice.marchman.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/janice-marchman"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-15-upper-ocd-person-2718c918-a38c-49c2-a120-5503164f959f": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/f72691ec-a5a0-413b-a344-e452009551a2",
            "firstName": "Christopher",
            "lastName": "Kolker",
            "fullName": "Chris Kolker",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_kolker%2Cchris.jpg?itok=DVQOTDRw",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-CO-16-upper-ocd-person-f72691ec-a5a0-413b-a344-e452009551a2",
              "builtID": "co-upper-16",
              "externalID": "ocd-person/f72691ec-a5a0-413b-a344-e452009551a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.kolker.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/chris-kolker"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-16-upper-ocd-person-f72691ec-a5a0-413b-a344-e452009551a2": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/b061ed52-3e18-4085-8648-abf154bb1ca4",
            "firstName": "Sonya",
            "lastName": "Jaquez Lewis",
            "fullName": "Sonya Jaquez Lewis",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_jaquez-lewis%2Csonya.jpg?itok=9UtH_VCP",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-CO-17-upper-ocd-person-b061ed52-3e18-4085-8648-abf154bb1ca4",
              "builtID": "co-upper-17",
              "externalID": "ocd-person/b061ed52-3e18-4085-8648-abf154bb1ca4",
              "geometry": null
            },
            "contactInfo": {
              "email": "sonya.jaquez.lewis.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.colorado.gov/legislators/sonya-jaquez-lewis"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-17-upper-ocd-person-b061ed52-3e18-4085-8648-abf154bb1ca4": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/cda35383-f5af-40b7-ab1f-41798fe6444e",
            "firstName": "Stephen",
            "lastName": "Fenberg",
            "fullName": "Steve Fenberg",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_fenberg%2Csteve-president.jpg?itok=zfZCd7pD",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-CO-18-upper-ocd-person-cda35383-f5af-40b7-ab1f-41798fe6444e",
              "builtID": "co-upper-18",
              "externalID": "ocd-person/cda35383-f5af-40b7-ab1f-41798fe6444e",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephen.fenberg.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.colorado.gov/legislators/stephen-fenberg",
                "https://leg.colorado.gov/legislators/stephen-fenberg",
                "https://leg.colorado.gov/legislators/steve-fenberg"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-18-upper-ocd-person-cda35383-f5af-40b7-ab1f-41798fe6444e": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/f7486270-9e45-4213-9520-aa7150e34baa",
            "firstName": "Rachel",
            "lastName": "Zenzinger",
            "fullName": "Rachel Zenzinger",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_zenzinger%2Crachel.jpg?itok=80CpB4V9",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-CO-19-upper-ocd-person-f7486270-9e45-4213-9520-aa7150e34baa",
              "builtID": "co-upper-19",
              "externalID": "ocd-person/f7486270-9e45-4213-9520-aa7150e34baa",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorrachelz@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/rachel-zenzinger"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-19-upper-ocd-person-f7486270-9e45-4213-9520-aa7150e34baa": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/5226ebdd-0710-49da-b446-61fac19765ce",
            "firstName": "Lisa Ann",
            "lastName": "Cutter",
            "fullName": "Lisa Cutter",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_cutter%2Clisa.jpg?itok=9pY76BLB",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-CO-20-upper-ocd-person-5226ebdd-0710-49da-b446-61fac19765ce",
              "builtID": "co-upper-20",
              "externalID": "ocd-person/5226ebdd-0710-49da-b446-61fac19765ce",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisa.cutter.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/lisa-cutter"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-20-upper-ocd-person-5226ebdd-0710-49da-b446-61fac19765ce": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/3818c80e-ca06-40d5-be21-ae570f6a21b5",
            "firstName": "Dafna",
            "lastName": "Michaelson Jenet",
            "fullName": "Dafna Michaelson Jenet",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_michaelson%20jenet%2Cdafna-.jpg?itok=3WaYGuub",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-CO-21-upper-ocd-person-3818c80e-ca06-40d5-be21-ae570f6a21b5",
              "builtID": "co-upper-21",
              "externalID": "ocd-person/3818c80e-ca06-40d5-be21-ae570f6a21b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "dafna.michaelson.jenet.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.colorado.gov/legislators/dafna-michaelson-jenet"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-21-upper-ocd-person-3818c80e-ca06-40d5-be21-ae570f6a21b5": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/c06af980-c553-4f79-a39d-295b7226f029",
            "firstName": "Jessie",
            "lastName": "Danielson",
            "fullName": "Jessie Danielson",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_danielson%2Cjessie.jpg?itok=PNVRiYDq",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-CO-22-upper-ocd-person-c06af980-c553-4f79-a39d-295b7226f029",
              "builtID": "co-upper-22",
              "externalID": "ocd-person/c06af980-c553-4f79-a39d-295b7226f029",
              "geometry": null
            },
            "contactInfo": {
              "email": "jessie.danielson.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/jessie-danielson"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-22-upper-ocd-person-c06af980-c553-4f79-a39d-295b7226f029": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/6d1fcaf0-ed3d-4772-876f-09dc5e931556",
            "firstName": "Barbara",
            "lastName": "Kirkmeyer",
            "fullName": "Barbara Kirkmeyer",
            "gender": "Female",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_kirkmeyer%2Cbarb-.jpg?itok=0RWY0AqY",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-CO-23-upper-ocd-person-6d1fcaf0-ed3d-4772-876f-09dc5e931556",
              "builtID": "co-upper-23",
              "externalID": "ocd-person/6d1fcaf0-ed3d-4772-876f-09dc5e931556",
              "geometry": null
            },
            "contactInfo": {
              "email": "barbara.kirkmeyer.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.colorado.gov/legislators/barbara-kirkmeyer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-23-upper-ocd-person-6d1fcaf0-ed3d-4772-876f-09dc5e931556": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/ebf0c3de-2f58-436f-af67-12863f9cb552",
            "firstName": "Kyle Alan John",
            "lastName": "Mullica",
            "fullName": "Kyle Mullica",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_mullica%2Ckyle.jpg?itok=8ek9BxJC",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-CO-24-upper-ocd-person-ebf0c3de-2f58-436f-af67-12863f9cb552",
              "builtID": "co-upper-24",
              "externalID": "ocd-person/ebf0c3de-2f58-436f-af67-12863f9cb552",
              "geometry": null
            },
            "contactInfo": {
              "email": "kyle.mullica.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/kyle-mullica"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-24-upper-ocd-person-ebf0c3de-2f58-436f-af67-12863f9cb552": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/d8091b89-e076-404f-a2a3-886f5cb9b89c",
            "firstName": "Faith",
            "lastName": "Winter",
            "fullName": "Faith Winter",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_winter%2Cfaith.jpg?itok=Gj2wPzoG",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-CO-25-upper-ocd-person-d8091b89-e076-404f-a2a3-886f5cb9b89c",
              "builtID": "co-upper-25",
              "externalID": "ocd-person/d8091b89-e076-404f-a2a3-886f5cb9b89c",
              "geometry": null
            },
            "contactInfo": {
              "email": "faith.winter.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.colorado.gov/legislators/faith-winter",
                "https://leg.colorado.gov/legislators/faith-winter"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-25-upper-ocd-person-d8091b89-e076-404f-a2a3-886f5cb9b89c": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/c2cade07-9c95-4439-938c-77ac4ed44fab",
            "firstName": "Jeff",
            "lastName": "Bridges",
            "fullName": "Jeff Bridges",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_bridges%2Cjeff.jpg?itok=7ZsoC6-F",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-CO-26-upper-ocd-person-c2cade07-9c95-4439-938c-77ac4ed44fab",
              "builtID": "co-upper-26",
              "externalID": "ocd-person/c2cade07-9c95-4439-938c-77ac4ed44fab",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.bridges.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/jeff-bridges"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-26-upper-ocd-person-c2cade07-9c95-4439-938c-77ac4ed44fab": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/dc9056b8-fb9c-4a66-99b8-210acce64bff",
            "firstName": "Leo Thomas",
            "lastName": "Sullivan",
            "fullName": "Tom Sullivan",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_sullivan%2Ctom.jpg?itok=rbln5IBq",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-CO-27-upper-ocd-person-dc9056b8-fb9c-4a66-99b8-210acce64bff",
              "builtID": "co-upper-27",
              "externalID": "ocd-person/dc9056b8-fb9c-4a66-99b8-210acce64bff",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.sullivan.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/tom-sullivan"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-27-upper-ocd-person-dc9056b8-fb9c-4a66-99b8-210acce64bff": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/654b2a04-55a4-4158-b45f-2aefdef39a1c",
            "firstName": "Rhonda Marshall",
            "lastName": "Fields",
            "fullName": "Rhonda Fields",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_fields%2C%20rhonda.jpg?itok=pdMecNVn",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-CO-28-upper-ocd-person-654b2a04-55a4-4158-b45f-2aefdef39a1c",
              "builtID": "co-upper-28",
              "externalID": "ocd-person/654b2a04-55a4-4158-b45f-2aefdef39a1c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rhonda.fields.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/rhonda-fields"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-28-upper-ocd-person-654b2a04-55a4-4158-b45f-2aefdef39a1c": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/a7d01880-beeb-4183-8dfa-a3300600c062",
            "firstName": "Janet Thomas",
            "lastName": "Buckner",
            "fullName": "Janet Buckner",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_buckner%2Cjanet-.jpg?itok=YQO9Xl0j",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-CO-29-upper-ocd-person-a7d01880-beeb-4183-8dfa-a3300600c062",
              "builtID": "co-upper-29",
              "externalID": "ocd-person/a7d01880-beeb-4183-8dfa-a3300600c062",
              "geometry": null
            },
            "contactInfo": {
              "email": "janet.buckner.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.colorado.gov/legislators/janet-p-buckner",
                "https://leg.colorado.gov/legislators/janet-buckner"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-29-upper-ocd-person-a7d01880-beeb-4183-8dfa-a3300600c062": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/f4af69ce-6bef-4127-bbf7-bb92c5b80465",
            "firstName": "Kevin",
            "lastName": "Van Winkle",
            "fullName": "Kevin Van Winkle",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_van%20winkle%2Ckevin.jpg?itok=Cbw4h5LY",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-CO-30-upper-ocd-person-f4af69ce-6bef-4127-bbf7-bb92c5b80465",
              "builtID": "co-upper-30",
              "externalID": "ocd-person/f4af69ce-6bef-4127-bbf7-bb92c5b80465",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.vanwinkle.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/kevin-van-winkle"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-30-upper-ocd-person-f4af69ce-6bef-4127-bbf7-bb92c5b80465": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/38bd0587-d043-4341-bad9-1bfa903d9dcd",
            "firstName": "Christopher Joshi",
            "lastName": "Hansen",
            "fullName": "Chris Hansen",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_hansen%2Cchris.jpg?itok=hPJJh1df",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-CO-31-upper-ocd-person-38bd0587-d043-4341-bad9-1bfa903d9dcd",
              "builtID": "co-upper-31",
              "externalID": "ocd-person/38bd0587-d043-4341-bad9-1bfa903d9dcd",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.hansen.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/chris-hansen"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-31-upper-ocd-person-38bd0587-d043-4341-bad9-1bfa903d9dcd": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/ab377224-ab70-4c20-a535-baa429520e7c",
            "firstName": "Robert",
            "lastName": "Rodriguez",
            "fullName": "Robert Rodriguez",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_rodriguez%2Crobert.jpg?itok=n2lsSdht",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-CO-32-upper-ocd-person-ab377224-ab70-4c20-a535-baa429520e7c",
              "builtID": "co-upper-32",
              "externalID": "ocd-person/ab377224-ab70-4c20-a535-baa429520e7c",
              "geometry": null
            },
            "contactInfo": {
              "email": "robert.rodriguez.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/robert-rodriguez"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-32-upper-ocd-person-ab377224-ab70-4c20-a535-baa429520e7c": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/e4e1f1a9-7134-4d90-9fed-2aa7a0e3a307",
            "firstName": "James Rashad",
            "lastName": "Coleman",
            "fullName": "James Coleman",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_coleman%2Cjames.jpg?itok=U8FI_CeH",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-CO-33-upper-ocd-person-e4e1f1a9-7134-4d90-9fed-2aa7a0e3a307",
              "builtID": "co-upper-33",
              "externalID": "ocd-person/e4e1f1a9-7134-4d90-9fed-2aa7a0e3a307",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.coleman.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/james-coleman"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-33-upper-ocd-person-e4e1f1a9-7134-4d90-9fed-2aa7a0e3a307": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/8dae5ba5-6741-4aca-8c1e-24f014fda187",
            "firstName": "Julie",
            "lastName": "Gonzales",
            "fullName": "Julie Gonzales",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_gonzales%2Cjulie.jpg?itok=YD-7SxZl",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-CO-34-upper-ocd-person-8dae5ba5-6741-4aca-8c1e-24f014fda187",
              "builtID": "co-upper-34",
              "externalID": "ocd-person/8dae5ba5-6741-4aca-8c1e-24f014fda187",
              "geometry": null
            },
            "contactInfo": {
              "email": "julie.gonzales.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/julie-gonzales"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-34-upper-ocd-person-8dae5ba5-6741-4aca-8c1e-24f014fda187": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/45cd06eb-9967-4468-9e4e-677549e7638d",
            "firstName": "Rod",
            "lastName": "Pelton",
            "fullName": "Rod Pelton",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.colorado.gov/sites/default/files/styles/width_300/public/2023b_pelton%2C%20rod.jpg?itok=UiBGO0En",
            "title": "CO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CO",
              "stateFull": "Colorado",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-CO-35-upper-ocd-person-45cd06eb-9967-4468-9e4e-677549e7638d",
              "builtID": "co-upper-35",
              "externalID": "ocd-person/45cd06eb-9967-4468-9e4e-677549e7638d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rod.pelton.senate@coleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://leg.colorado.gov/legislators/rod-pelton"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CO-35-upper-ocd-person-45cd06eb-9967-4468-9e4e-677549e7638d": 0
        }
      }
    }
  },
  "CT": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "CT",
          "stateFull": "Connecticut",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "CT",
            "stateFull": "Connecticut",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-CT---",
            "builtID": "ct--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-CT---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "B001277",
          "in_office": true,
          "firstName": "Richard",
          "lastName": "Blumenthal",
          "middleName": null,
          "fullName": "Richard Blumenthal",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/B001277.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "CT",
            "stateFull": "Connecticut",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-CT---B001277",
            "builtID": "ct--",
            "externalID": "B001277",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenBlumenthal",
            "youtube": "SenatorBlumenthal",
            "instagram": null,
            "facebook": "SenBlumenthal",
            "urls": ["https://www.blumenthal.senate.gov"],
            "rss_url": "https://www.blumenthal.senate.gov/rss/feeds/?type=press"
          },
          "title": "CT Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/B001277.json",
          "govtrack_id": "412490",
          "cspan_id": "21799",
          "votesmart_id": "1568",
          "icpsr_id": "41101",
          "crp_id": "N00031685",
          "google_entity_id": "/m/03tg8m",
          "state_rank": "senior",
          "lis_id": "S341",
          "suffix": null,
          "date_of_birth": "1946-02-13",
          "leadership_role": null,
          "fec_candidate_id": "S0CT00177",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "13",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 4,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 0.72,
          "votes_with_party_pct": 99.82,
          "votes_against_party_pct": 0.18,
          "ocd_id": "ocd-division/country:us/state:ct"
        },
        {
          "API_ID": "M001169",
          "in_office": true,
          "firstName": "Christopher",
          "lastName": "Murphy",
          "middleName": "S.",
          "fullName": "Christopher Murphy",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/M001169.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "CT",
            "stateFull": "Connecticut",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-CT---M001169",
            "builtID": "ct--",
            "externalID": "M001169",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.murphy.senate.gov/contact",
            "address1": "136 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-4041",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "senmurphyoffice",
            "youtube": "senchrismurphy",
            "instagram": null,
            "facebook": "chrismurphyct",
            "urls": ["https://www.murphy.senate.gov"],
            "rss_url": null
          },
          "title": "CT Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/M001169.json",
          "govtrack_id": "412194",
          "cspan_id": "1021270",
          "votesmart_id": "17189",
          "icpsr_id": "20707",
          "crp_id": "N00027566",
          "google_entity_id": "/m/0cy_dh",
          "state_rank": "junior",
          "lis_id": "S364",
          "suffix": null,
          "date_of_birth": "1973-08-03",
          "leadership_role": null,
          "fec_candidate_id": "H6CT05124",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "11",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 3,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 0.54,
          "votes_with_party_pct": 97.63,
          "votes_against_party_pct": 2.37,
          "ocd_id": "ocd-division/country:us/state:ct"
        }
      ],
      "hash": { "SENATE-CT---B001277": 0, "SENATE-CT---M001169": 1 }
    },
    "HOUSE": {
      "02": {
        "members": [
          {
            "API_ID": "C001069",
            "in_office": true,
            "firstName": "Joe",
            "lastName": "Courtney",
            "middleName": null,
            "fullName": "Joe Courtney",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001069.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-CT-02--C001069",
              "builtID": "ct--02",
              "externalID": "C001069",
              "geometry": null,
              "geoid": "0902"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2449 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2076",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJoeCourtney",
              "youtube": "repcourtney",
              "instagram": null,
              "facebook": "joecourtney",
              "urls": ["https://courtney.house.gov"],
              "rss_url": "https://courtney.house.gov/rss.xml"
            },
            "title": "CT House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001069.json",
            "govtrack_id": "412193",
            "cspan_id": "1021284",
            "votesmart_id": "30333",
            "icpsr_id": "20706",
            "crp_id": "N00024842",
            "google_entity_id": "/m/0700nd",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1953-04-06",
            "leadership_role": null,
            "fec_candidate_id": "H2CT02112",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "18",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 9,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 95.17,
            "votes_against_party_pct": 2.99,
            "ocd_id": "ocd-division/country:us/state:ct/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CT-02--C001069": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "D000216",
            "in_office": true,
            "firstName": "Rosa",
            "lastName": "DeLauro",
            "middleName": null,
            "fullName": "Rosa L. DeLauro",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/D000216.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-CT-03--D000216",
              "builtID": "ct--03",
              "externalID": "D000216",
              "geometry": null,
              "geoid": "0903"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2413 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3661",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RosaDeLauro",
              "youtube": "rosadelauro",
              "instagram": null,
              "facebook": "CongresswomanRosaDeLauro",
              "urls": ["https://delauro.house.gov"],
              "rss_url": "https://delauro.house.gov/rss.xml"
            },
            "title": "CT House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000216.json",
            "govtrack_id": "400103",
            "cspan_id": "19040",
            "votesmart_id": "26788",
            "icpsr_id": "29109",
            "crp_id": "N00000615",
            "google_entity_id": "/m/024zn0",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1943-03-02",
            "leadership_role": null,
            "fec_candidate_id": "H0CT03072",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "34",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 11,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.04,
            "votes_with_party_pct": 96.42,
            "votes_against_party_pct": 1.74,
            "ocd_id": "ocd-division/country:us/state:ct/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CT-03--D000216": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "H001081",
            "in_office": true,
            "firstName": "Jahana",
            "lastName": "Hayes",
            "middleName": null,
            "fullName": "Jahana Hayes",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/H001081.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-CT-05--H001081",
              "builtID": "ct--05",
              "externalID": "H001081",
              "geometry": null,
              "geoid": "0905"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2458 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4476",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJahanaHayes",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://hayes.house.gov"],
              "rss_url": null
            },
            "title": "CT House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001081.json",
            "govtrack_id": "412763",
            "cspan_id": null,
            "votesmart_id": "181744",
            "icpsr_id": null,
            "crp_id": "N00043421",
            "google_entity_id": "/g/11cn8wstn6",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1973-03-08",
            "leadership_role": null,
            "fec_candidate_id": "H8CT05245",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 3,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.28,
            "votes_with_party_pct": 95.01,
            "votes_against_party_pct": 3.07,
            "ocd_id": "ocd-division/country:us/state:ct/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CT-05--H001081": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "H001047",
            "in_office": true,
            "firstName": "Jim",
            "lastName": "Himes",
            "middleName": null,
            "fullName": "James A. Himes",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/H001047.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-CT-04--H001047",
              "builtID": "ct--04",
              "externalID": "H001047",
              "geometry": null,
              "geoid": "0904"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2137 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5541",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "JAHimes",
              "youtube": "congressmanhimes",
              "instagram": null,
              "facebook": "CongressmanJimHimes",
              "urls": ["https://himes.house.gov"],
              "rss_url": null
            },
            "title": "CT House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001047.json",
            "govtrack_id": "412282",
            "cspan_id": "1031341",
            "votesmart_id": "106744",
            "icpsr_id": "20907",
            "crp_id": "N00029070",
            "google_entity_id": "/m/03w9x9v",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1966-07-05",
            "leadership_role": null,
            "fec_candidate_id": "H8CT04172",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "16",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 14,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.33,
            "votes_with_party_pct": 94.38,
            "votes_against_party_pct": 3.78,
            "ocd_id": "ocd-division/country:us/state:ct/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CT-04--H001047": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "L000557",
            "in_office": true,
            "firstName": "John",
            "lastName": "Larson",
            "middleName": "B.",
            "fullName": "John B. Larson",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/L000557.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-CT-01--L000557",
              "builtID": "ct--01",
              "externalID": "L000557",
              "geometry": null,
              "geoid": "0901"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1501 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2265",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJohnLarson",
              "youtube": "RepJohnLarson",
              "instagram": null,
              "facebook": "RepJohnLarson",
              "urls": ["https://larson.house.gov"],
              "rss_url": "https://larson.house.gov/rss.xml"
            },
            "title": "CT House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000557.json",
            "govtrack_id": "400233",
            "cspan_id": "36596",
            "votesmart_id": "17188",
            "icpsr_id": "29908",
            "crp_id": "N00000575",
            "google_entity_id": "/m/024zl9",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1948-07-22",
            "leadership_role": null,
            "fec_candidate_id": "H8CT01046",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "26",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 27,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.56,
            "votes_with_party_pct": 96.27,
            "votes_against_party_pct": 1.86,
            "ocd_id": "ocd-division/country:us/state:ct/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-CT-01--L000557": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/2a91c3d6-433a-4e8f-b5a5-38fc80b8ea57",
            "firstName": "Matthew Delis",
            "lastName": "Ritter",
            "fullName": "Matthew Ritter",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Ritter/images/Ritter_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-CT-1-lower-ocd-person-2a91c3d6-433a-4e8f-b5a5-38fc80b8ea57",
              "builtID": "ct-lower-1",
              "externalID": "ocd-person/2a91c3d6-433a-4e8f-b5a5-38fc80b8ea57",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.ritter@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27001%27&dist_name=001%20-%Ritter,%Matt",
                "https://www.housedems.ct.gov/Ritter"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-1-lower-ocd-person-2a91c3d6-433a-4e8f-b5a5-38fc80b8ea57": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/1a01451b-8c85-4bde-9cef-ab083d99ae84",
            "firstName": "Raghib Ismail",
            "lastName": "Allie-Brennan",
            "fullName": "Raghib Allie-Brennan",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Allie-Brennan/images/Allie-Brennan_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-CT-2-lower-ocd-person-1a01451b-8c85-4bde-9cef-ab083d99ae84",
              "builtID": "ct-lower-2",
              "externalID": "ocd-person/1a01451b-8c85-4bde-9cef-ab083d99ae84",
              "geometry": null
            },
            "contactInfo": {
              "email": "raghib.allie-brennan@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.housedems.ct.gov/allie-brennan",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27002%27&dist_name=Allie-Brennan,%20Raghib",
                "https://www.housedems.ct.gov/Allie-Brennan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-2-lower-ocd-person-1a01451b-8c85-4bde-9cef-ab083d99ae84": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/57900db1-ade5-4107-8025-a0c664f41a32",
            "firstName": "Guillermina",
            "lastName": "Gonzalez",
            "fullName": "Minnie Gonzalez",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Gonzalez/images/Gonzalez_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-CT-3-lower-ocd-person-57900db1-ade5-4107-8025-a0c664f41a32",
              "builtID": "ct-lower-3",
              "externalID": "ocd-person/57900db1-ade5-4107-8025-a0c664f41a32",
              "geometry": null
            },
            "contactInfo": {
              "email": "minnie.gonzalez@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27003%27&dist_name=Gonzalez,%20Minnie",
                "https://www.housedems.ct.gov/Gonzalez"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-3-lower-ocd-person-57900db1-ade5-4107-8025-a0c664f41a32": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/d44bd84a-b02c-4c1b-94ee-05c8b1008e57",
            "firstName": "Julio A.",
            "lastName": "Concepcion",
            "fullName": "Julio Concepción",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Concepcion/images/Concepcion_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-CT-4-lower-ocd-person-d44bd84a-b02c-4c1b-94ee-05c8b1008e57",
              "builtID": "ct-lower-4",
              "externalID": "ocd-person/d44bd84a-b02c-4c1b-94ee-05c8b1008e57",
              "geometry": null
            },
            "contactInfo": {
              "email": "julio.concepcion@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27004%27&dist_name=004%20-%Concepcion,%Julio",
                "https://www.housedems.ct.gov/concepcion"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-4-lower-ocd-person-d44bd84a-b02c-4c1b-94ee-05c8b1008e57": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/298d6d03-248e-44c2-be42-269bc417745c",
            "firstName": "Maryam",
            "lastName": "Khan",
            "fullName": "Maryam Khan",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Khan/images/Khan_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-CT-5-lower-ocd-person-298d6d03-248e-44c2-be42-269bc417745c",
              "builtID": "ct-lower-5",
              "externalID": "ocd-person/298d6d03-248e-44c2-be42-269bc417745c",
              "geometry": null
            },
            "contactInfo": {
              "email": "maryam.khan@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27005%27&dist_name=005%20-%Khan,%Maryam",
                "https://www.housedems.ct.gov/khan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-5-lower-ocd-person-298d6d03-248e-44c2-be42-269bc417745c": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/37cdaa0f-2531-44f6-95d0-41f9eefa6c97",
            "firstName": "James B.",
            "lastName": "Sanchez",
            "fullName": "Jimmy Sánchez",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-CT-6-lower-ocd-person-37cdaa0f-2531-44f6-95d0-41f9eefa6c97",
              "builtID": "ct-lower-6",
              "externalID": "ocd-person/37cdaa0f-2531-44f6-95d0-41f9eefa6c97",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.sanchez@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.housedems.ct.gov/sanchezJ"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-6-lower-ocd-person-37cdaa0f-2531-44f6-95d0-41f9eefa6c97": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/acb9a83b-c921-4631-826e-ffd02e2203b9",
            "firstName": "Joshua Malik",
            "lastName": "Hall",
            "fullName": "Joshua Hall",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Hall/images/Hall_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-CT-7-lower-ocd-person-acb9a83b-c921-4631-826e-ffd02e2203b9",
              "builtID": "ct-lower-7",
              "externalID": "ocd-person/acb9a83b-c921-4631-826e-ffd02e2203b9",
              "geometry": null
            },
            "contactInfo": {
              "email": "joshua.hall@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27007%27&dist_name=007%20-%Hall,%Joshua",
                "https://www.housedems.ct.gov/Hall"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-7-lower-ocd-person-acb9a83b-c921-4631-826e-ffd02e2203b9": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/d458d504-9053-4709-bf43-d955083a18ab",
            "firstName": "Timothy",
            "lastName": "Ackert",
            "fullName": "Tim Ackert",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cga.ct.gov/legpics/008.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-CT-8-lower-ocd-person-d458d504-9053-4709-bf43-d955083a18ab",
              "builtID": "ct-lower-8",
              "externalID": "ocd-person/d458d504-9053-4709-bf43-d955083a18ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "tim.ackert@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27008%27&dist_name=Ackert,%20Tim",
                "https://www.cthousegop.com/Ackert/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-8-lower-ocd-person-d458d504-9053-4709-bf43-d955083a18ab": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/94b3daf5-cbc7-4016-9297-8c0b8bc54944",
            "firstName": "Jason",
            "lastName": "Rojas",
            "fullName": "Jason Rojas",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Rojas/images/Rojas_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-CT-9-lower-ocd-person-94b3daf5-cbc7-4016-9297-8c0b8bc54944",
              "builtID": "ct-lower-9",
              "externalID": "ocd-person/94b3daf5-cbc7-4016-9297-8c0b8bc54944",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.rojas@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27009%27&dist_name=009%20-%Rojas,%Jason",
                "https://www.housedems.ct.gov/Rojas"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-9-lower-ocd-person-94b3daf5-cbc7-4016-9297-8c0b8bc54944": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/c07f613b-b79d-4e11-b959-589c8cefac41",
            "firstName": "Henry J.",
            "lastName": "Genga",
            "fullName": "Henry Genga",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Genga/images/Genga_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-CT-10-lower-ocd-person-c07f613b-b79d-4e11-b959-589c8cefac41",
              "builtID": "ct-lower-10",
              "externalID": "ocd-person/c07f613b-b79d-4e11-b959-589c8cefac41",
              "geometry": null
            },
            "contactInfo": {
              "email": "henry.genga@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27010%27&dist_name=Genga,%20Henry%20J.",
                "https://www.housedems.ct.gov/Genga"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-10-lower-ocd-person-c07f613b-b79d-4e11-b959-589c8cefac41": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/0589b926-29e0-4281-b0f9-73369fe7f989",
            "firstName": "Jeffrey A.",
            "lastName": "Currey",
            "fullName": "Jeff Currey",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Currey/images/Currey_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-CT-11-lower-ocd-person-0589b926-29e0-4281-b0f9-73369fe7f989",
              "builtID": "ct-lower-11",
              "externalID": "ocd-person/0589b926-29e0-4281-b0f9-73369fe7f989",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.currey@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27011%27&dist_name=011%20-%Currey,%Jeff",
                "https://www.housedems.ct.gov/Currey"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-11-lower-ocd-person-0589b926-29e0-4281-b0f9-73369fe7f989": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/37f6cb6c-888c-44c1-8c21-b273d967a62d",
            "firstName": "Geoffrey R.",
            "lastName": "Luxenberg",
            "fullName": "Geoff Luxenberg",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Luxenberg/images/Luxenberg_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-CT-12-lower-ocd-person-37f6cb6c-888c-44c1-8c21-b273d967a62d",
              "builtID": "ct-lower-12",
              "externalID": "ocd-person/37f6cb6c-888c-44c1-8c21-b273d967a62d",
              "geometry": null
            },
            "contactInfo": {
              "email": "geoff.luxenberg@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27012%27&dist_name=Luxenberg,%20Geoff",
                "https://www.housedems.ct.gov/luxenberg"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-12-lower-ocd-person-37f6cb6c-888c-44c1-8c21-b273d967a62d": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/33a50ad7-61f8-4e5a-92fc-a24efddc15f1",
            "firstName": "Jason Edward",
            "lastName": "Doucette",
            "fullName": "Jason Doucette",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Doucette/images/Doucette_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-CT-13-lower-ocd-person-33a50ad7-61f8-4e5a-92fc-a24efddc15f1",
              "builtID": "ct-lower-13",
              "externalID": "ocd-person/33a50ad7-61f8-4e5a-92fc-a24efddc15f1",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.doucette@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.ct.gov/doucette",
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27013%27&dist_name=013%20-%Doucette,%Jason",
                "https://www.housedems.ct.gov/doucette"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-13-lower-ocd-person-33a50ad7-61f8-4e5a-92fc-a24efddc15f1": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/8bf7e55e-afc1-4f5c-99c3-9b7a1356fbc3",
            "firstName": "Thomas A.",
            "lastName": "Delnicki",
            "fullName": "Tom Delnicki",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cga.ct.gov/legpics/014.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-CT-14-lower-ocd-person-8bf7e55e-afc1-4f5c-99c3-9b7a1356fbc3",
              "builtID": "ct-lower-14",
              "externalID": "ocd-person/8bf7e55e-afc1-4f5c-99c3-9b7a1356fbc3",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.delnicki@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Delnicki/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27014%27&dist_name=Delnicki,%20Tom",
                "https://www.cthousegop.com/Delnicki/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-14-lower-ocd-person-8bf7e55e-afc1-4f5c-99c3-9b7a1356fbc3": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/6e4977fe-2ed9-4e1c-93ce-c837766d2896",
            "firstName": "Bobby G.",
            "lastName": "Gibson",
            "fullName": "Bobby Gibson",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Gibson/images/Gibson_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-CT-15-lower-ocd-person-6e4977fe-2ed9-4e1c-93ce-c837766d2896",
              "builtID": "ct-lower-15",
              "externalID": "ocd-person/6e4977fe-2ed9-4e1c-93ce-c837766d2896",
              "geometry": null
            },
            "contactInfo": {
              "email": "bobby.gibson@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.housedems.ct.gov/gibson",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27015%27&dist_name=Gibson,%20Bobby%20G.",
                "https://www.housedems.ct.gov/Gibson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-15-lower-ocd-person-6e4977fe-2ed9-4e1c-93ce-c837766d2896": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/d16242a4-d3e1-45b3-9f95-8741980310cc",
            "firstName": "Melissa E.",
            "lastName": "Osborne",
            "fullName": "Melissa Osborne",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-CT-16-lower-ocd-person-d16242a4-d3e1-45b3-9f95-8741980310cc",
              "builtID": "ct-lower-16",
              "externalID": "ocd-person/d16242a4-d3e1-45b3-9f95-8741980310cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "melissa.osborne@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.housedems.ct.gov/Osborne"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-16-lower-ocd-person-d16242a4-d3e1-45b3-9f95-8741980310cc": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/2a3c062a-ac41-4295-9c26-195f78369242",
            "firstName": "Eleni Kavros",
            "lastName": "DeGraw",
            "fullName": "Eleni DeGraw",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "http://www2.housedems.ct.gov/KavrosDeGraw/images/KavrosDeGraw_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-CT-17-lower-ocd-person-2a3c062a-ac41-4295-9c26-195f78369242",
              "builtID": "ct-lower-17",
              "externalID": "ocd-person/2a3c062a-ac41-4295-9c26-195f78369242",
              "geometry": null
            },
            "contactInfo": {
              "email": "elenikavros.degraw@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27017%27&dist_name=Kavros%20DeGraw,%20Eleni",
                "https://www.housedems.ct.gov/KavrosDegraw"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-17-lower-ocd-person-2a3c062a-ac41-4295-9c26-195f78369242": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/26149cc4-f15a-46fb-a9a5-7bc0544dc287",
            "firstName": "Jillian Marie",
            "lastName": "Gilchrest",
            "fullName": "Jillian Gilchrest",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Gilchrest/images/Gilchrest_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-CT-18-lower-ocd-person-26149cc4-f15a-46fb-a9a5-7bc0544dc287",
              "builtID": "ct-lower-18",
              "externalID": "ocd-person/26149cc4-f15a-46fb-a9a5-7bc0544dc287",
              "geometry": null
            },
            "contactInfo": {
              "email": "jillian.gilchrest@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.housedems.ct.gov/gilchrest",
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27018%27&dist_name=018%20-%Gilchrest,%Jillian",
                "https://www.housedems.ct.gov/Gilchrest"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-18-lower-ocd-person-26149cc4-f15a-46fb-a9a5-7bc0544dc287": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/8872e78b-25b4-406b-84b2-949c700f7b56",
            "firstName": "Tammy Rush",
            "lastName": "Exum",
            "fullName": "Tammy Exum",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Exum/images/Exum_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-CT-19-lower-ocd-person-8872e78b-25b4-406b-84b2-949c700f7b56",
              "builtID": "ct-lower-19",
              "externalID": "ocd-person/8872e78b-25b4-406b-84b2-949c700f7b56",
              "geometry": null
            },
            "contactInfo": {
              "email": "tammy.exum@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27019%27&dist_name=Exum,%20Tammy%20R.",
                "https://www.housedems.ct.gov/exum"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-19-lower-ocd-person-8872e78b-25b4-406b-84b2-949c700f7b56": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/96fa444a-7e86-4ead-9d2e-c3ffef258be2",
            "firstName": "Kate C.",
            "lastName": "Farrar",
            "fullName": "Kate Farrar",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "http://www2.housedems.ct.gov/Farrar/images/Farrar_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-CT-20-lower-ocd-person-96fa444a-7e86-4ead-9d2e-c3ffef258be2",
              "builtID": "ct-lower-20",
              "externalID": "ocd-person/96fa444a-7e86-4ead-9d2e-c3ffef258be2",
              "geometry": null
            },
            "contactInfo": {
              "email": "kate.farrar@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27020%27&dist_name=020%20-%Farrar,%Kate",
                "https://www.housedems.ct.gov/Farrar"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-20-lower-ocd-person-96fa444a-7e86-4ead-9d2e-c3ffef258be2": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/ca9357d4-dffa-456a-a715-d4a7671c1f84",
            "firstName": "Mike",
            "lastName": "Demicco",
            "fullName": "Mike Demicco",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Demicco/images/Demicco_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-CT-21-lower-ocd-person-ca9357d4-dffa-456a-a715-d4a7671c1f84",
              "builtID": "ct-lower-21",
              "externalID": "ocd-person/ca9357d4-dffa-456a-a715-d4a7671c1f84",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.demicco@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27021%27&dist_name=Demicco,%20Mike",
                "https://www.housedems.ct.gov/Demicco"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-21-lower-ocd-person-ca9357d4-dffa-456a-a715-d4a7671c1f84": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/8cad853c-a838-4451-a5ce-268f905699c0",
            "firstName": "Francis Rexford",
            "lastName": "Cooley",
            "fullName": "Francis Rexford Cooley",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-CT-22-lower-ocd-person-8cad853c-a838-4451-a5ce-268f905699c0",
              "builtID": "ct-lower-22",
              "externalID": "ocd-person/8cad853c-a838-4451-a5ce-268f905699c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "francis.cooley@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.cthousegop.com/Cooley/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-22-lower-ocd-person-8cad853c-a838-4451-a5ce-268f905699c0": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/79d4059c-5faa-443f-a842-7b494ea2d57e",
            "firstName": "Devin R.",
            "lastName": "Carney",
            "fullName": "Devin Carney",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cthousegop.com/carney/wp-content/uploads/sites/13/2016/11/Carney-2017-Web-Bio-Pict-216x300.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-CT-23-lower-ocd-person-79d4059c-5faa-443f-a842-7b494ea2d57e",
              "builtID": "ct-lower-23",
              "externalID": "ocd-person/79d4059c-5faa-443f-a842-7b494ea2d57e",
              "geometry": null
            },
            "contactInfo": {
              "email": "devin.carney@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Carney/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27023%27&dist_name=Carney,%20Devin%20R.",
                "https://www.cthousegop.com/Carney/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-23-lower-ocd-person-79d4059c-5faa-443f-a842-7b494ea2d57e": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/140d2d97-aae9-433a-b012-ce3775aa81a8",
            "firstName": "Emmanuel",
            "lastName": "Sanchez",
            "fullName": "Manny Sanchez",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "http://www2.housedems.ct.gov/SanchezM/images/SanchezM_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-CT-24-lower-ocd-person-140d2d97-aae9-433a-b012-ce3775aa81a8",
              "builtID": "ct-lower-24",
              "externalID": "ocd-person/140d2d97-aae9-433a-b012-ce3775aa81a8",
              "geometry": null
            },
            "contactInfo": {
              "email": "emmanuel.sanchez@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27024%27&dist_name=Sanchez,%20Emmanuel",
                "https://www.housedems.ct.gov/SanchezM"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-24-lower-ocd-person-140d2d97-aae9-433a-b012-ce3775aa81a8": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/8703dbac-ef19-414d-afe4-1081451f124e",
            "firstName": "Robert C.",
            "lastName": "Sanchez",
            "fullName": "Bobby Sanchez",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Sanchez/images/Sanchez_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-CT-25-lower-ocd-person-8703dbac-ef19-414d-afe4-1081451f124e",
              "builtID": "ct-lower-25",
              "externalID": "ocd-person/8703dbac-ef19-414d-afe4-1081451f124e",
              "geometry": null
            },
            "contactInfo": {
              "email": "bobby.sanchez@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27025%27&dist_name=Sanchez,%20Robert",
                "https://www.housedems.ct.gov/Sanchez"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-25-lower-ocd-person-8703dbac-ef19-414d-afe4-1081451f124e": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/0a4ae3cc-c2dc-4ef4-8ba8-95be03126a02",
            "firstName": "Peter A.",
            "lastName": "Tercyak",
            "fullName": "Peter Tercyak",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Tercyak/images/Tercyak_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-CT-26-lower-ocd-person-0a4ae3cc-c2dc-4ef4-8ba8-95be03126a02",
              "builtID": "ct-lower-26",
              "externalID": "ocd-person/0a4ae3cc-c2dc-4ef4-8ba8-95be03126a02",
              "geometry": null
            },
            "contactInfo": {
              "email": "peter.tercyak@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27026%27&dist_name=Tercyak,%20Peter%20A.",
                "https://www.housedems.ct.gov/Tercyak"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-26-lower-ocd-person-0a4ae3cc-c2dc-4ef4-8ba8-95be03126a02": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/36b9eb8c-9fd8-4f6e-ba6b-fddb03bf3be3",
            "firstName": "Gary A.",
            "lastName": "Turco",
            "fullName": "Gary Turco",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Turco/images/Turco_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-CT-27-lower-ocd-person-36b9eb8c-9fd8-4f6e-ba6b-fddb03bf3be3",
              "builtID": "ct-lower-27",
              "externalID": "ocd-person/36b9eb8c-9fd8-4f6e-ba6b-fddb03bf3be3",
              "geometry": null
            },
            "contactInfo": {
              "email": "gary.turco@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27027%27&dist_name=Turco,%20Gary%20A.",
                "https://www.housedems.ct.gov/turco"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-27-lower-ocd-person-36b9eb8c-9fd8-4f6e-ba6b-fddb03bf3be3": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/ff1c4d90-5cc3-4ff9-8f31-454d03803816",
            "firstName": "Amy",
            "lastName": "Morrin Bello",
            "fullName": "Amy Morrin Bello",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "http://www2.housedems.ct.gov/MorrinBello/images/MorrinBello_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-CT-28-lower-ocd-person-ff1c4d90-5cc3-4ff9-8f31-454d03803816",
              "builtID": "ct-lower-28",
              "externalID": "ocd-person/ff1c4d90-5cc3-4ff9-8f31-454d03803816",
              "geometry": null
            },
            "contactInfo": {
              "email": "amy.morrinbello@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27028%27&dist_name=Morrin%20Bello,%20Amy",
                "https://www.housedems.ct.gov/morrinbello"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-28-lower-ocd-person-ff1c4d90-5cc3-4ff9-8f31-454d03803816": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/d71060b1-2bb9-4239-ab9b-fad7d1a4d903",
            "firstName": "Kerry Szeps",
            "lastName": "Wood",
            "fullName": "Kerry Wood",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Wood/images/Wood_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-CT-29-lower-ocd-person-d71060b1-2bb9-4239-ab9b-fad7d1a4d903",
              "builtID": "ct-lower-29",
              "externalID": "ocd-person/d71060b1-2bb9-4239-ab9b-fad7d1a4d903",
              "geometry": null
            },
            "contactInfo": {
              "email": "kerry.wood@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27029%27&dist_name=029%20-%Wood,%Kerry",
                "https://www.housedems.ct.gov/wood"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-29-lower-ocd-person-d71060b1-2bb9-4239-ab9b-fad7d1a4d903": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/63461ed0-da6d-4423-82bc-5b83c2a811cf",
            "firstName": "Donna",
            "lastName": "Veach",
            "fullName": "Donna Veach",
            "gender": "Female",
            "party": "Republican",
            "image": "https://cga.ct.gov/legpics/030.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-CT-30-lower-ocd-person-63461ed0-da6d-4423-82bc-5b83c2a811cf",
              "builtID": "ct-lower-30",
              "externalID": "ocd-person/63461ed0-da6d-4423-82bc-5b83c2a811cf",
              "geometry": null
            },
            "contactInfo": {
              "email": "donna.veach@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/veach",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27030%27&dist_name=Veach,%20Donna",
                "https://www.cthousegop.com/veach/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-30-lower-ocd-person-63461ed0-da6d-4423-82bc-5b83c2a811cf": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/629f89a2-138d-43b1-a39f-b420bd7bc061",
            "firstName": "Jill",
            "lastName": "Barry",
            "fullName": "Jill Barry",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Barry/images/Barry_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-CT-31-lower-ocd-person-629f89a2-138d-43b1-a39f-b420bd7bc061",
              "builtID": "ct-lower-31",
              "externalID": "ocd-person/629f89a2-138d-43b1-a39f-b420bd7bc061",
              "geometry": null
            },
            "contactInfo": {
              "email": "jill.barry@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27031%27&dist_name=031%20-%Barry,%Jill",
                "https://www.housedems.ct.gov/barry"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-31-lower-ocd-person-629f89a2-138d-43b1-a39f-b420bd7bc061": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/88d052d8-3d97-4e23-8abd-53311294acfd",
            "firstName": "Christie M.",
            "lastName": "Carpino",
            "fullName": "Christie Carpino",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.cga.ct.gov/legpics/032.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-CT-32-lower-ocd-person-88d052d8-3d97-4e23-8abd-53311294acfd",
              "builtID": "ct-lower-32",
              "externalID": "ocd-person/88d052d8-3d97-4e23-8abd-53311294acfd",
              "geometry": null
            },
            "contactInfo": {
              "email": "christie.carpino@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Carpino/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27032%27&dist_name=Carpino,%20Christie%20M.",
                "https://www.cthousegop.com/Carpino/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-32-lower-ocd-person-88d052d8-3d97-4e23-8abd-53311294acfd": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/e8ce793e-671a-41fa-818a-42b534fa9c6b",
            "firstName": "Brandon",
            "lastName": "Chafee",
            "fullName": "Brandon Chafee",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "http://www2.housedems.ct.gov/Chafee/images/Chafee_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-CT-33-lower-ocd-person-e8ce793e-671a-41fa-818a-42b534fa9c6b",
              "builtID": "ct-lower-33",
              "externalID": "ocd-person/e8ce793e-671a-41fa-818a-42b534fa9c6b",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandon.chafee@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27033%27&dist_name=Chafee,%20Brandon",
                "https://www.housedems.ct.gov/Chafee"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-33-lower-ocd-person-e8ce793e-671a-41fa-818a-42b534fa9c6b": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/02cd12d5-6a88-4834-b9b5-11fb491f6b4b",
            "firstName": "Irene M.",
            "lastName": "Haines",
            "fullName": "Irene Haines",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.cthousegop.com/haines/wp-content/uploads/sites/77/2021/12/BD3A1188-sized-232x300.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-CT-34-lower-ocd-person-02cd12d5-6a88-4834-b9b5-11fb491f6b4b",
              "builtID": "ct-lower-34",
              "externalID": "ocd-person/02cd12d5-6a88-4834-b9b5-11fb491f6b4b",
              "geometry": null
            },
            "contactInfo": {
              "email": "irene.haines@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27034%27&dist_name=Haines,%20Irene%20M.",
                "https://www.cthousegop.com/haines/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-34-lower-ocd-person-02cd12d5-6a88-4834-b9b5-11fb491f6b4b": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/3dac820b-e022-4ee6-a5d5-4b194f8988ec",
            "firstName": "Chris",
            "lastName": "Aniskovich",
            "fullName": "Chris Aniskovich",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-CT-35-lower-ocd-person-3dac820b-e022-4ee6-a5d5-4b194f8988ec",
              "builtID": "ct-lower-35",
              "externalID": "ocd-person/3dac820b-e022-4ee6-a5d5-4b194f8988ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.aniskovich@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/aniskovich/",
                "https://www.cthousegop.com/Aniskovich/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-35-lower-ocd-person-3dac820b-e022-4ee6-a5d5-4b194f8988ec": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/d125d3d7-3ad3-487e-a993-c0e3107c9202",
            "firstName": "Christine A.",
            "lastName": "Palm",
            "fullName": "Christine Palm",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Palm/images/Palm_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-CT-36-lower-ocd-person-d125d3d7-3ad3-487e-a993-c0e3107c9202",
              "builtID": "ct-lower-36",
              "externalID": "ocd-person/d125d3d7-3ad3-487e-a993-c0e3107c9202",
              "geometry": null
            },
            "contactInfo": {
              "email": "christine.palm@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27036%27&dist_name=Palm,%20Christine",
                "https://www.housedems.ct.gov/palm"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-36-lower-ocd-person-d125d3d7-3ad3-487e-a993-c0e3107c9202": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/4382243c-e60c-4b2a-b41b-cd3b6d658f9d",
            "firstName": "Holly H.",
            "lastName": "Cheeseman",
            "fullName": "Holly Cheeseman",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.cga.ct.gov/legpics/037.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-CT-37-lower-ocd-person-4382243c-e60c-4b2a-b41b-cd3b6d658f9d",
              "builtID": "ct-lower-37",
              "externalID": "ocd-person/4382243c-e60c-4b2a-b41b-cd3b6d658f9d",
              "geometry": null
            },
            "contactInfo": {
              "email": "holly.cheeseman@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Cheeseman/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27037%27&dist_name=Cheeseman,%20Holly%20H.",
                "https://www.cthousegop.com/Cheeseman/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-37-lower-ocd-person-4382243c-e60c-4b2a-b41b-cd3b6d658f9d": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/f04c3831-624c-49a1-a7c2-3185a43de06f",
            "firstName": "Kathleen M.",
            "lastName": "McCarty",
            "fullName": "Kathleen McCarty",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.cthousegop.com/mccarty/wp-content/uploads/sites/44/2016/11/McCarty-Bio-Pict-Web.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-CT-38-lower-ocd-person-f04c3831-624c-49a1-a7c2-3185a43de06f",
              "builtID": "ct-lower-38",
              "externalID": "ocd-person/f04c3831-624c-49a1-a7c2-3185a43de06f",
              "geometry": null
            },
            "contactInfo": {
              "email": "kathleen.mccarty@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/McCarty/",
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27038%27&dist_name=McCarty,%20Kathleen%20M",
                "https://www.cthousegop.com/McCarty/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-38-lower-ocd-person-f04c3831-624c-49a1-a7c2-3185a43de06f": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/c7160abf-d376-484d-b87e-600a398ab503",
            "firstName": "Anthony L.",
            "lastName": "Nolan",
            "fullName": "Anthony Nolan",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Nolan/images/Nolan_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-CT-39-lower-ocd-person-c7160abf-d376-484d-b87e-600a398ab503",
              "builtID": "ct-lower-39",
              "externalID": "ocd-person/c7160abf-d376-484d-b87e-600a398ab503",
              "geometry": null
            },
            "contactInfo": {
              "email": "anthony.nolan@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.housedems.ct.gov/nolan",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27039%27&dist_name=Nolan,%20Anthony%20L.",
                "https://www.housedems.ct.gov/Nolan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-39-lower-ocd-person-c7160abf-d376-484d-b87e-600a398ab503": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/276eaeb3-5dae-40f3-acbb-3effe375988b",
            "firstName": "Christine",
            "lastName": "Conley",
            "fullName": "Christine Conley",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Conley/images/Conley_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-CT-40-lower-ocd-person-276eaeb3-5dae-40f3-acbb-3effe375988b",
              "builtID": "ct-lower-40",
              "externalID": "ocd-person/276eaeb3-5dae-40f3-acbb-3effe375988b",
              "geometry": null
            },
            "contactInfo": {
              "email": "christine.conley@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27040%27&dist_name=Conley,%20Christine",
                "https://www.housedems.ct.gov/Conley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-40-lower-ocd-person-276eaeb3-5dae-40f3-acbb-3effe375988b": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/32ad6577-b1ee-411f-9dca-e40e06226703",
            "firstName": "Aundre",
            "lastName": "Bumgardner",
            "fullName": "Aundré Bumgardner",
            "gender": "Male",
            "party": "democratic/working families",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-CT-41-lower-ocd-person-32ad6577-b1ee-411f-9dca-e40e06226703",
              "builtID": "ct-lower-41",
              "externalID": "ocd-person/32ad6577-b1ee-411f-9dca-e40e06226703",
              "geometry": null
            },
            "contactInfo": {
              "email": "aundre.bumgardner@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.housedems.ct.gov/Bumgardner"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-41-lower-ocd-person-32ad6577-b1ee-411f-9dca-e40e06226703": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/9eeb217c-d4f1-4761-93f3-1cc0f7f44b00",
            "firstName": "Keith",
            "lastName": "Denning",
            "fullName": "Keith Denning",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-CT-42-lower-ocd-person-9eeb217c-d4f1-4761-93f3-1cc0f7f44b00",
              "builtID": "ct-lower-42",
              "externalID": "ocd-person/9eeb217c-d4f1-4761-93f3-1cc0f7f44b00",
              "geometry": null
            },
            "contactInfo": {
              "email": "keith.denning@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.housedems.ct.gov/Denning"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-42-lower-ocd-person-9eeb217c-d4f1-4761-93f3-1cc0f7f44b00": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/d8829ada-0b6e-4cbf-8aa6-e88c70d76e89",
            "firstName": "Greg",
            "lastName": "Howard",
            "fullName": "Greg Howard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cga.ct.gov/legpics/043.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-CT-43-lower-ocd-person-d8829ada-0b6e-4cbf-8aa6-e88c70d76e89",
              "builtID": "ct-lower-43",
              "externalID": "ocd-person/d8829ada-0b6e-4cbf-8aa6-e88c70d76e89",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.howard@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/howard",
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27043%27&dist_name=Howard,%20Greg%20S.",
                "https://www.cthousegop.com/howard/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-43-lower-ocd-person-d8829ada-0b6e-4cbf-8aa6-e88c70d76e89": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/087a813d-b14a-4205-a08f-cec795647318",
            "firstName": "Anne Dubay",
            "lastName": "Dauphinais",
            "fullName": "Anne Dauphinais",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.cga.ct.gov/legpics/044.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-CT-44-lower-ocd-person-087a813d-b14a-4205-a08f-cec795647318",
              "builtID": "ct-lower-44",
              "externalID": "ocd-person/087a813d-b14a-4205-a08f-cec795647318",
              "geometry": null
            },
            "contactInfo": {
              "email": "anne.dauphinais@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Dauphinais/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27044%27&dist_name=Dauphinais,%20Anne",
                "https://www.cthousegop.com/Dauphinais/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-44-lower-ocd-person-087a813d-b14a-4205-a08f-cec795647318": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/fc3ba144-870e-4603-b2a4-26d3e80b5c70",
            "firstName": "Brian L.",
            "lastName": "Lanoue",
            "fullName": "Brian Lanoue",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cga.ct.gov/legpics/045.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-CT-45-lower-ocd-person-fc3ba144-870e-4603-b2a4-26d3e80b5c70",
              "builtID": "ct-lower-45",
              "externalID": "ocd-person/fc3ba144-870e-4603-b2a4-26d3e80b5c70",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.lanoue@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27045%27&dist_name=Lanoue,%20Brian",
                "https://www.cthousegop.com/lanoue/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-45-lower-ocd-person-fc3ba144-870e-4603-b2a4-26d3e80b5c70": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/56582153-80dd-4de6-9b79-2427316eeb6c",
            "firstName": "Derell",
            "lastName": "Wilson",
            "fullName": "Derell Wilson",
            "gender": "Male",
            "party": "democratic/working families",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-CT-46-lower-ocd-person-56582153-80dd-4de6-9b79-2427316eeb6c",
              "builtID": "ct-lower-46",
              "externalID": "ocd-person/56582153-80dd-4de6-9b79-2427316eeb6c",
              "geometry": null
            },
            "contactInfo": {
              "email": "derell.wilson@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.housedems.ct.gov/Wilson"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-46-lower-ocd-person-56582153-80dd-4de6-9b79-2427316eeb6c": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/2342cde8-078f-4dd8-8879-f9fdaa036a94",
            "firstName": "Douglas",
            "lastName": "Dubitsky",
            "fullName": "Doug Dubitsky",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cthousegop.com/dubitsky/wp-content/uploads/sites/23/2019/01/2-Dubitsky-headshot-cropped-v2-050318-5673-265x300.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-CT-47-lower-ocd-person-2342cde8-078f-4dd8-8879-f9fdaa036a94",
              "builtID": "ct-lower-47",
              "externalID": "ocd-person/2342cde8-078f-4dd8-8879-f9fdaa036a94",
              "geometry": null
            },
            "contactInfo": {
              "email": "doug.dubitsky@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Dubitsky/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27047%27&dist_name=Dubitsky,%20Doug",
                "https://www.cthousegop.com/Dubitsky/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-47-lower-ocd-person-2342cde8-078f-4dd8-8879-f9fdaa036a94": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/6130c80a-07b7-438e-8f5b-005e0a6b7034",
            "firstName": "Mark",
            "lastName": "DeCaprio",
            "fullName": "Mark DeCaprio",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-CT-48-lower-ocd-person-6130c80a-07b7-438e-8f5b-005e0a6b7034",
              "builtID": "ct-lower-48",
              "externalID": "ocd-person/6130c80a-07b7-438e-8f5b-005e0a6b7034",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.decaprio@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/decaprio/",
                "https://www.cthousegop.com/DeCaprio/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-48-lower-ocd-person-6130c80a-07b7-438e-8f5b-005e0a6b7034": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/454e5979-c5bb-4f4e-9f3d-dc11f5e5b6ca",
            "firstName": "Susan M.",
            "lastName": "Johnson",
            "fullName": "Susan Johnson",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Johnson/images/Johnson_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-CT-49-lower-ocd-person-454e5979-c5bb-4f4e-9f3d-dc11f5e5b6ca",
              "builtID": "ct-lower-49",
              "externalID": "ocd-person/454e5979-c5bb-4f4e-9f3d-dc11f5e5b6ca",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.johnson@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27049%27&dist_name=Johnson,%20Susan%20M.",
                "https://www.housedems.ct.gov/Johnson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-49-lower-ocd-person-454e5979-c5bb-4f4e-9f3d-dc11f5e5b6ca": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/50e78f89-a9e7-43f5-9566-a9f2cb23a5c9",
            "firstName": "Patrick S.",
            "lastName": "Boyd",
            "fullName": "Pat Boyd",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Boyd/images/Boyd_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-CT-50-lower-ocd-person-50e78f89-a9e7-43f5-9566-a9f2cb23a5c9",
              "builtID": "ct-lower-50",
              "externalID": "ocd-person/50e78f89-a9e7-43f5-9566-a9f2cb23a5c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "pat.boyd@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27050%27&dist_name=Boyd,%20Patrick%20S.",
                "https://www.housedems.ct.gov/Boyd"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-50-lower-ocd-person-50e78f89-a9e7-43f5-9566-a9f2cb23a5c9": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/c30bb3b3-de40-4c0a-89a5-da36532694c9",
            "firstName": "Ricky L.",
            "lastName": "Hayes",
            "fullName": "Rick Hayes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cthousegop.com/hayes/wp-content/uploads/sites/78/2019/01/Re.-Elect-Rick-Hayes-201x300.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-CT-51-lower-ocd-person-c30bb3b3-de40-4c0a-89a5-da36532694c9",
              "builtID": "ct-lower-51",
              "externalID": "ocd-person/c30bb3b3-de40-4c0a-89a5-da36532694c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.hayes@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27051%27&dist_name=Hayes,%20Rick%20L.",
                "https://www.cthousegop.com/hayes/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-51-lower-ocd-person-c30bb3b3-de40-4c0a-89a5-da36532694c9": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/ffa361d0-7471-450e-aee8-cb0c32ee2742",
            "firstName": "Kurt",
            "lastName": "Vail",
            "fullName": "Kurt Vail",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cthousegop.com/vail/wp-content/uploads/sites/66/2016/11/Vail-Bio-Web.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-CT-52-lower-ocd-person-ffa361d0-7471-450e-aee8-cb0c32ee2742",
              "builtID": "ct-lower-52",
              "externalID": "ocd-person/ffa361d0-7471-450e-aee8-cb0c32ee2742",
              "geometry": null
            },
            "contactInfo": {
              "email": "kurt.vail@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Vail/",
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27052%27&dist_name=Kurt,%20Vail",
                "https://www.cthousegop.com/Vail/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-52-lower-ocd-person-ffa361d0-7471-450e-aee8-cb0c32ee2742": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/cbdde335-cd3e-4769-b477-5893c46d96b6",
            "firstName": "Tammy Thomas",
            "lastName": "Nuccio",
            "fullName": "Tammy Nuccio",
            "gender": "Female",
            "party": "Republican",
            "image": "https://cga.ct.gov/legpics/053.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-CT-53-lower-ocd-person-cbdde335-cd3e-4769-b477-5893c46d96b6",
              "builtID": "ct-lower-53",
              "externalID": "ocd-person/cbdde335-cd3e-4769-b477-5893c46d96b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "tammy.nuccio@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Nuccio",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27053%27&dist_name=Nuccio,%20Tammy",
                "https://www.cthousegop.com/Nuccio/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-53-lower-ocd-person-cbdde335-cd3e-4769-b477-5893c46d96b6": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/63a8e4ab-dfcd-4a69-8317-53b8e9a69729",
            "firstName": "Gregory S.",
            "lastName": "Haddad",
            "fullName": "Gregg Haddad",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Haddad/images/Haddad_19.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-CT-54-lower-ocd-person-63a8e4ab-dfcd-4a69-8317-53b8e9a69729",
              "builtID": "ct-lower-54",
              "externalID": "ocd-person/63a8e4ab-dfcd-4a69-8317-53b8e9a69729",
              "geometry": null
            },
            "contactInfo": {
              "email": "gregory.haddad@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27054%27&dist_name=Haddad,%20Gregory",
                "https://www.housedems.ct.gov/haddad"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-54-lower-ocd-person-63a8e4ab-dfcd-4a69-8317-53b8e9a69729": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/755c489b-dcb4-4037-8df7-d9e62006e844",
            "firstName": "Stephen",
            "lastName": "Weir",
            "fullName": "Steve Weir",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-CT-55-lower-ocd-person-755c489b-dcb4-4037-8df7-d9e62006e844",
              "builtID": "ct-lower-55",
              "externalID": "ocd-person/755c489b-dcb4-4037-8df7-d9e62006e844",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.weir@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.cthousegop.com/Weir/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-55-lower-ocd-person-755c489b-dcb4-4037-8df7-d9e62006e844": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/ac2991dd-302d-430f-8878-0fbd393a5909",
            "firstName": "Kevin",
            "lastName": "Brown",
            "fullName": "Kevin Brown",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-CT-56-lower-ocd-person-ac2991dd-302d-430f-8878-0fbd393a5909",
              "builtID": "ct-lower-56",
              "externalID": "ocd-person/ac2991dd-302d-430f-8878-0fbd393a5909",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.brown@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.housedems.ct.gov/BrownK"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-56-lower-ocd-person-ac2991dd-302d-430f-8878-0fbd393a5909": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/784a12cd-3ecc-4fed-8b47-4e090038c2f8",
            "firstName": "Jaime Smith",
            "lastName": "Foster",
            "fullName": "Jaime Foster",
            "gender": "Female",
            "party": "democratic/independence/working families",
            "image": "http://www2.housedems.ct.gov/Foster/images/Foster_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-CT-57-lower-ocd-person-784a12cd-3ecc-4fed-8b47-4e090038c2f8",
              "builtID": "ct-lower-57",
              "externalID": "ocd-person/784a12cd-3ecc-4fed-8b47-4e090038c2f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "jaime.foster@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27057%27&dist_name=057%20-%Foster,%Jaime",
                "https://www.housedems.ct.gov/Foster"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-57-lower-ocd-person-784a12cd-3ecc-4fed-8b47-4e090038c2f8": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/ea621753-2aca-412d-a221-d4fc5e48ba80",
            "firstName": "Thomas",
            "lastName": "Arnone",
            "fullName": "Tom Arnone",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://cga.ct.gov/legpics/058.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-CT-58-lower-ocd-person-ea621753-2aca-412d-a221-d4fc5e48ba80",
              "builtID": "ct-lower-58",
              "externalID": "ocd-person/ea621753-2aca-412d-a221-d4fc5e48ba80",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.arnone@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27058%27&dist_name=Arnone,%20Tom",
                "https://www.housedems.ct.gov/arnone"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-58-lower-ocd-person-ea621753-2aca-412d-a221-d4fc5e48ba80": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/5e47f657-ab56-4673-bebb-5a7c1a0410db",
            "firstName": "Carol A.",
            "lastName": "Hall",
            "fullName": "Carol Hall",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.cthousegop.com/hall/wp-content/uploads/sites/34/2016/11/hall-bio-pict-big.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-CT-59-lower-ocd-person-5e47f657-ab56-4673-bebb-5a7c1a0410db",
              "builtID": "ct-lower-59",
              "externalID": "ocd-person/5e47f657-ab56-4673-bebb-5a7c1a0410db",
              "geometry": null
            },
            "contactInfo": {
              "email": "carol.hall@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Hall/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27059%27&dist_name=Hall,%20Carol",
                "https://www.cthousegop.com/Hall/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-59-lower-ocd-person-5e47f657-ab56-4673-bebb-5a7c1a0410db": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/fee3a7d8-7e51-4c5a-af29-190694152ccc",
            "firstName": "Jane Marie",
            "lastName": "Garibay",
            "fullName": "Jane Garibay",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Garibay/images/Garibay_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-CT-60-lower-ocd-person-fee3a7d8-7e51-4c5a-af29-190694152ccc",
              "builtID": "ct-lower-60",
              "externalID": "ocd-person/fee3a7d8-7e51-4c5a-af29-190694152ccc",
              "geometry": null
            },
            "contactInfo": {
              "email": "jane.garibay@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27060%27&dist_name=060%20-%Garibay,%Jane",
                "https://www.housedems.ct.gov/garibay"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-60-lower-ocd-person-fee3a7d8-7e51-4c5a-af29-190694152ccc": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/b57c3599-c0b0-4fd3-a48c-2bfd32d95071",
            "firstName": "Tami W.",
            "lastName": "Zawistowski",
            "fullName": "Tami Zawistowski",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.cthousegop.com/zawistowski/wp-content/uploads/sites/71/2016/11/Zawistowski-Bio-Web.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-CT-61-lower-ocd-person-b57c3599-c0b0-4fd3-a48c-2bfd32d95071",
              "builtID": "ct-lower-61",
              "externalID": "ocd-person/b57c3599-c0b0-4fd3-a48c-2bfd32d95071",
              "geometry": null
            },
            "contactInfo": {
              "email": "tami.zawistowski@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Zawistowski/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27061%27&dist_name=Zawistowski,%20Tami",
                "https://www.cthousegop.com/Zawistowski/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-61-lower-ocd-person-b57c3599-c0b0-4fd3-a48c-2bfd32d95071": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/135d2f43-c892-4a34-9651-a523e85dd6de",
            "firstName": "Mark Werner",
            "lastName": "Anderson",
            "fullName": "Mark Anderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cga.ct.gov/legpics/062.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-CT-62-lower-ocd-person-135d2f43-c892-4a34-9651-a523e85dd6de",
              "builtID": "ct-lower-62",
              "externalID": "ocd-person/135d2f43-c892-4a34-9651-a523e85dd6de",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.anderson@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Anderson",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27062%27&dist_name=Anderson,%20Mark%20W",
                "https://www.cthousegop.com/Anderson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-62-lower-ocd-person-135d2f43-c892-4a34-9651-a523e85dd6de": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/fc3cb4e1-f784-46ac-9d54-1dd44cb817b0",
            "firstName": "Jay",
            "lastName": "Case",
            "fullName": "Jay Case",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cthousegop.com/case/wp-content/uploads/sites/15/2016/11/Case-2017-Web-Bio-Pict.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-CT-63-lower-ocd-person-fc3cb4e1-f784-46ac-9d54-1dd44cb817b0",
              "builtID": "ct-lower-63",
              "externalID": "ocd-person/fc3cb4e1-f784-46ac-9d54-1dd44cb817b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jay.case@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Case/",
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27063%27&dist_name=Jay,%20Case",
                "https://www.cthousegop.com/Case/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-63-lower-ocd-person-fc3cb4e1-f784-46ac-9d54-1dd44cb817b0": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/283ad876-68be-4235-a108-e0aedbadde40",
            "firstName": "Maria P.",
            "lastName": "Horn",
            "fullName": "Maria Horn",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Horn/images/Horn_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-CT-64-lower-ocd-person-283ad876-68be-4235-a108-e0aedbadde40",
              "builtID": "ct-lower-64",
              "externalID": "ocd-person/283ad876-68be-4235-a108-e0aedbadde40",
              "geometry": null
            },
            "contactInfo": {
              "email": "maria.horn@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27064%27&dist_name=064%20-%Horn,%Maria",
                "https://www.housedems.ct.gov/horn"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-64-lower-ocd-person-283ad876-68be-4235-a108-e0aedbadde40": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/daf39ec5-3fcd-4d88-bd79-a5a9da8e45e6",
            "firstName": "Michelle L.",
            "lastName": "Cook",
            "fullName": "Michelle Cook",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Cook/images/Cook_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-CT-65-lower-ocd-person-daf39ec5-3fcd-4d88-bd79-a5a9da8e45e6",
              "builtID": "ct-lower-65",
              "externalID": "ocd-person/daf39ec5-3fcd-4d88-bd79-a5a9da8e45e6",
              "geometry": null
            },
            "contactInfo": {
              "email": "michelle.cook@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27065%27&dist_name=065%20-%Cook,%Michelle%20L.",
                "https://www.housedems.ct.gov/Cook"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-65-lower-ocd-person-daf39ec5-3fcd-4d88-bd79-a5a9da8e45e6": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/56d4db3c-b047-4480-99ae-c99ed0131d69",
            "firstName": "Karen",
            "lastName": "Reddington-Hughes",
            "fullName": "Karen Reddington-Hughes",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-CT-66-lower-ocd-person-56d4db3c-b047-4480-99ae-c99ed0131d69",
              "builtID": "ct-lower-66",
              "externalID": "ocd-person/56d4db3c-b047-4480-99ae-c99ed0131d69",
              "geometry": null
            },
            "contactInfo": {
              "email": "karen.reddington-hughes@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.cthousegop.com/Reddington-Hughes/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-66-lower-ocd-person-56d4db3c-b047-4480-99ae-c99ed0131d69": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/1c270bf3-7cc6-4e11-93fb-e35f5d9a1774",
            "firstName": "William J.",
            "lastName": "Buckbee",
            "fullName": "Billy Buckbee",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cga.ct.gov/legpics/067.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-CT-67-lower-ocd-person-1c270bf3-7cc6-4e11-93fb-e35f5d9a1774",
              "builtID": "ct-lower-67",
              "externalID": "ocd-person/1c270bf3-7cc6-4e11-93fb-e35f5d9a1774",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.buckbee@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Buckbee/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27067%27&dist_name=Buckbee,%20Bill",
                "https://www.cthousegop.com/Buckbee/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-67-lower-ocd-person-1c270bf3-7cc6-4e11-93fb-e35f5d9a1774": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/df318645-047c-46a5-be61-682a7272e6b6",
            "firstName": "Joseph",
            "lastName": "Polletta",
            "fullName": "Joe Polletta",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cthousegop.com/polletta/wp-content/uploads/sites/74/2017/04/Polletta-Web-Bio.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-CT-68-lower-ocd-person-df318645-047c-46a5-be61-682a7272e6b6",
              "builtID": "ct-lower-68",
              "externalID": "ocd-person/df318645-047c-46a5-be61-682a7272e6b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.polletta@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Polletta/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27068%27&dist_name=Polletta,%20Joe",
                "https://www.cthousegop.com/Polletta/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-68-lower-ocd-person-df318645-047c-46a5-be61-682a7272e6b6": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/7aae96d8-45b7-4be6-9483-3ade2191578b",
            "firstName": "Cindy L.",
            "lastName": "Harrison",
            "fullName": "Cindy Harrison",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.cga.ct.gov/legpics/069.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-CT-69-lower-ocd-person-7aae96d8-45b7-4be6-9483-3ade2191578b",
              "builtID": "ct-lower-69",
              "externalID": "ocd-person/7aae96d8-45b7-4be6-9483-3ade2191578b",
              "geometry": null
            },
            "contactInfo": {
              "email": "cindy.harrison@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Harrison",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27069%27&dist_name=Harrison,%20Cindy",
                "https://www.cthousegop.com/Harrison/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-69-lower-ocd-person-7aae96d8-45b7-4be6-9483-3ade2191578b": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/a0e90d7c-cc41-469e-a27a-7efa009ef801",
            "firstName": "Seth",
            "lastName": "Bronko",
            "fullName": "Seth Bronko",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-CT-70-lower-ocd-person-a0e90d7c-cc41-469e-a27a-7efa009ef801",
              "builtID": "ct-lower-70",
              "externalID": "ocd-person/a0e90d7c-cc41-469e-a27a-7efa009ef801",
              "geometry": null
            },
            "contactInfo": {
              "email": "seth.bronko@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.cthousegop.com/Bronko/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-70-lower-ocd-person-a0e90d7c-cc41-469e-a27a-7efa009ef801": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/f7127b57-a72f-4b84-abf6-77b021cddf3f",
            "firstName": "William",
            "lastName": "Pizzuto",
            "fullName": "Bill Pizzuto",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-CT-71-lower-ocd-person-f7127b57-a72f-4b84-abf6-77b021cddf3f",
              "builtID": "ct-lower-71",
              "externalID": "ocd-person/f7127b57-a72f-4b84-abf6-77b021cddf3f",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.pizzuto@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cthousegop.com/Pizzuto",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27071%27&dist_name=Pizzuto,%20William",
                "https://www.cthousegop.com/Pizzuto/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-71-lower-ocd-person-f7127b57-a72f-4b84-abf6-77b021cddf3f": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/47282285-14de-4306-b33f-a82754702ec5",
            "firstName": "Larry B.",
            "lastName": "Butler",
            "fullName": "Larry Butler",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Butler/images/Butler_HS.jpg?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-CT-72-lower-ocd-person-47282285-14de-4306-b33f-a82754702ec5",
              "builtID": "ct-lower-72",
              "externalID": "ocd-person/47282285-14de-4306-b33f-a82754702ec5",
              "geometry": null
            },
            "contactInfo": {
              "email": "larry.butler@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27072%27&dist_name=072%20-%Butler,%Larry%20B.",
                "https://www.housedems.ct.gov/Butler"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-72-lower-ocd-person-47282285-14de-4306-b33f-a82754702ec5": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/71fb3504-679f-419d-86bf-d1ae7d573244",
            "firstName": "Ronald A.",
            "lastName": "Napoli",
            "fullName": "Ron Napoli",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Napoli/images/Napoli_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-CT-73-lower-ocd-person-71fb3504-679f-419d-86bf-d1ae7d573244",
              "builtID": "ct-lower-73",
              "externalID": "ocd-person/71fb3504-679f-419d-86bf-d1ae7d573244",
              "geometry": null
            },
            "contactInfo": {
              "email": "ron.napoli@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27073%27&dist_name=Napoli,%20Ronald%20A.",
                "https://www.housedems.ct.gov/napoli"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-73-lower-ocd-person-71fb3504-679f-419d-86bf-d1ae7d573244": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/1e5a518e-ddf5-4976-b239-9a5324339a47",
            "firstName": "Michael",
            "lastName": "DiGiovancarlo",
            "fullName": "Michael DiGiovancarlo",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "http://www2.housedems.ct.gov/DiGiovancarlo/images/DiGiovancarlo_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-CT-74-lower-ocd-person-1e5a518e-ddf5-4976-b239-9a5324339a47",
              "builtID": "ct-lower-74",
              "externalID": "ocd-person/1e5a518e-ddf5-4976-b239-9a5324339a47",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.digiovancarlo@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27074%27&dist_name=074%20-%DiGiovancarlo,%Michael",
                "https://www.housedems.ct.gov/digiovancarlo"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-74-lower-ocd-person-1e5a518e-ddf5-4976-b239-9a5324339a47": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/f11b5a17-e613-4aa8-9b91-7e2583e1ced5",
            "firstName": "Geraldo C.",
            "lastName": "Reyes",
            "fullName": "Geraldo Reyes",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Reyes/images/Reyes_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-CT-75-lower-ocd-person-f11b5a17-e613-4aa8-9b91-7e2583e1ced5",
              "builtID": "ct-lower-75",
              "externalID": "ocd-person/f11b5a17-e613-4aa8-9b91-7e2583e1ced5",
              "geometry": null
            },
            "contactInfo": {
              "email": "geraldo.reyes@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27075%27&dist_name=Reyes,%20Geraldo%20C.",
                "https://www.housedems.ct.gov/Reyes"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-75-lower-ocd-person-f11b5a17-e613-4aa8-9b91-7e2583e1ced5": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/e7f7ad52-69a8-4306-8dfe-02368f74d75b",
            "firstName": "John E.",
            "lastName": "Piscopo",
            "fullName": "John Piscopo",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cthousegop.com/piscopo/wp-content/uploads/sites/52/2016/11/Piscopo-Bio-Pict-Web.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-CT-76-lower-ocd-person-e7f7ad52-69a8-4306-8dfe-02368f74d75b",
              "builtID": "ct-lower-76",
              "externalID": "ocd-person/e7f7ad52-69a8-4306-8dfe-02368f74d75b",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.piscopo@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Piscopo/",
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27076%27&dist_name=Piscopo,%20John",
                "https://www.cthousegop.com/Piscopo/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-76-lower-ocd-person-e7f7ad52-69a8-4306-8dfe-02368f74d75b": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/e9c9ef28-807a-4efe-983f-d194c54ed41a",
            "firstName": "Cara Christine",
            "lastName": "Pavalock-D'Amato",
            "fullName": "Cara Pavalock-D'Amato",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.cga.ct.gov/legpics/077.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-CT-77-lower-ocd-person-e9c9ef28-807a-4efe-983f-d194c54ed41a",
              "builtID": "ct-lower-77",
              "externalID": "ocd-person/e9c9ef28-807a-4efe-983f-d194c54ed41a",
              "geometry": null
            },
            "contactInfo": {
              "email": "cara.pavalock@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Pavalock/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27077%27&dist_name=Pavalock-D%27Amato,%20Cara%20Christine",
                "https://www.cthousegop.com/Pavalock/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-77-lower-ocd-person-e9c9ef28-807a-4efe-983f-d194c54ed41a": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/ab33209c-a8c8-4a6e-a480-ef298eeaa74a",
            "firstName": "Joe",
            "lastName": "Hoxha",
            "fullName": "Joe Hoxha",
            "gender": "Male",
            "party": "Republican",
            "image": "http://t2.gstatic.com/licensed-image?q=tbn:ANd9GcTiG4g-g9POWTgtx8A4J0Lqexxlv1P-9szsikgdxOyyK0B5OwuIx6ZRFBR-S-A3Js6L",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-CT-78-lower-ocd-person-ab33209c-a8c8-4a6e-a480-ef298eeaa74a",
              "builtID": "ct-lower-78",
              "externalID": "ocd-person/ab33209c-a8c8-4a6e-a480-ef298eeaa74a",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.hoxha@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/hoxha/",
                "https://www.cthousegop.com/Hoxha/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-78-lower-ocd-person-ab33209c-a8c8-4a6e-a480-ef298eeaa74a": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/dba46a90-f765-4508-a9e1-9bfdeb4a03dd",
            "firstName": "Mary B.",
            "lastName": "Fortier",
            "fullName": "Mary Fortier",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-CT-79-lower-ocd-person-dba46a90-f765-4508-a9e1-9bfdeb4a03dd",
              "builtID": "ct-lower-79",
              "externalID": "ocd-person/dba46a90-f765-4508-a9e1-9bfdeb4a03dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.fortier@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.housedems.ct.gov/Fortier"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-79-lower-ocd-person-dba46a90-f765-4508-a9e1-9bfdeb4a03dd": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/c60ec8d2-fcc3-414e-a122-cca6aeef901d",
            "firstName": "Gale Lanza",
            "lastName": "Mastrofrancesco",
            "fullName": "Gale Mastrofrancesco",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.cga.ct.gov/legpics/080.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-CT-80-lower-ocd-person-c60ec8d2-fcc3-414e-a122-cca6aeef901d",
              "builtID": "ct-lower-80",
              "externalID": "ocd-person/c60ec8d2-fcc3-414e-a122-cca6aeef901d",
              "geometry": null
            },
            "contactInfo": {
              "email": "gale.mastrofrancesco@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27080%27&dist_name=Mastrofrancesco,%20Gale%20L.",
                "https://www.cthousegop.com/mastrofrancesco/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-80-lower-ocd-person-c60ec8d2-fcc3-414e-a122-cca6aeef901d": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/7fda8e36-f73d-43e2-bf43-8365c617d94e",
            "firstName": "Christopher",
            "lastName": "Poulos",
            "fullName": "Chris Poulos",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-CT-81-lower-ocd-person-7fda8e36-f73d-43e2-bf43-8365c617d94e",
              "builtID": "ct-lower-81",
              "externalID": "ocd-person/7fda8e36-f73d-43e2-bf43-8365c617d94e",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.poulos@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.housedems.ct.gov/poulos",
                "https://www.housedems.ct.gov/Poulos"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-81-lower-ocd-person-7fda8e36-f73d-43e2-bf43-8365c617d94e": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/155c99fd-9d59-47e7-9134-12872a72426f",
            "firstName": "Michael D.",
            "lastName": "Quinn",
            "fullName": "Michael Quinn",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "http://www2.housedems.ct.gov/Quinn/images/Quinn_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-CT-82-lower-ocd-person-155c99fd-9d59-47e7-9134-12872a72426f",
              "builtID": "ct-lower-82",
              "externalID": "ocd-person/155c99fd-9d59-47e7-9134-12872a72426f",
              "geometry": null
            },
            "contactInfo": {
              "email": "michaeld.quinn@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27082%27&dist_name=082%20-%Quinn,%Michael%20D.",
                "https://www.housedems.ct.gov/Quinn"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-82-lower-ocd-person-155c99fd-9d59-47e7-9134-12872a72426f": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/cab156d4-4d6c-4c5f-ae17-c380f063d2b0",
            "firstName": "Jonathan",
            "lastName": "Fazzino",
            "fullName": "Jack Fazzino",
            "gender": "Male",
            "party": "democratic/working families",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-CT-83-lower-ocd-person-cab156d4-4d6c-4c5f-ae17-c380f063d2b0",
              "builtID": "ct-lower-83",
              "externalID": "ocd-person/cab156d4-4d6c-4c5f-ae17-c380f063d2b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jack.fazzino@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.housedems.ct.gov/Fazzino"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-83-lower-ocd-person-cab156d4-4d6c-4c5f-ae17-c380f063d2b0": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/568f232d-2133-4eee-a886-c1927f7f88bc",
            "firstName": "Hilda E.",
            "lastName": "Santiago",
            "fullName": "Hilda Santiago",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/SantiagoH/images/SantiagoH_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-CT-84-lower-ocd-person-568f232d-2133-4eee-a886-c1927f7f88bc",
              "builtID": "ct-lower-84",
              "externalID": "ocd-person/568f232d-2133-4eee-a886-c1927f7f88bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "hilda.santiago@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27084%27&dist_name=Santiago,%20Hilda%20E.",
                "https://www.housedems.ct.gov/SantiagoH"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-84-lower-ocd-person-568f232d-2133-4eee-a886-c1927f7f88bc": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/817e17bf-a01c-4bf1-b70f-3e16aeef088e",
            "firstName": "Mary M.",
            "lastName": "Mushinsky",
            "fullName": "Mary Mushinsky",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Mushinsky/images/Mushinsky_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-CT-85-lower-ocd-person-817e17bf-a01c-4bf1-b70f-3e16aeef088e",
              "builtID": "ct-lower-85",
              "externalID": "ocd-person/817e17bf-a01c-4bf1-b70f-3e16aeef088e",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.mushinsky@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27085%27&dist_name=085%20-%Mushinsky,%Mary%20M.",
                "https://www.housedems.ct.gov/Mushinsky"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-85-lower-ocd-person-817e17bf-a01c-4bf1-b70f-3e16aeef088e": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/1d02baaa-ab25-4607-b33f-40948893f928",
            "firstName": "Vincent J.",
            "lastName": "Candelora",
            "fullName": "Vincent Candelora",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cthousegop.com/candelora/wp-content/uploads/sites/12/2022/02/Candelora-2022-Bio-Photo-Web.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-CT-86-lower-ocd-person-1d02baaa-ab25-4607-b33f-40948893f928",
              "builtID": "ct-lower-86",
              "externalID": "ocd-person/1d02baaa-ab25-4607-b33f-40948893f928",
              "geometry": null
            },
            "contactInfo": {
              "email": "vincent.candelora@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Candelora/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27086%27&dist_name=Candelora,%20Vincent%20J.",
                "https://www.cthousegop.com/Candelora/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-86-lower-ocd-person-1d02baaa-ab25-4607-b33f-40948893f928": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/12835342-bb95-428a-ace3-78c51c3e8443",
            "firstName": "David W.",
            "lastName": "Yaccarino",
            "fullName": "Dave Yaccarino",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cthousegop.com/yaccarino/wp-content/uploads/sites/70/2020/11/Yaccarino-Headshot-240x300.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-CT-87-lower-ocd-person-12835342-bb95-428a-ace3-78c51c3e8443",
              "builtID": "ct-lower-87",
              "externalID": "ocd-person/12835342-bb95-428a-ace3-78c51c3e8443",
              "geometry": null
            },
            "contactInfo": {
              "email": "dave.yaccarino@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Yaccarino/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27087%27&dist_name=Yaccarino,%20Dave%20W.",
                "https://www.cthousegop.com/Yaccarino/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-87-lower-ocd-person-12835342-bb95-428a-ace3-78c51c3e8443": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/57461c01-e706-4ce5-9dfd-8982e1f29021",
            "firstName": "Joshua A.",
            "lastName": "Elliott",
            "fullName": "Josh Elliott",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Elliott/images/Elliott_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-CT-88-lower-ocd-person-57461c01-e706-4ce5-9dfd-8982e1f29021",
              "builtID": "ct-lower-88",
              "externalID": "ocd-person/57461c01-e706-4ce5-9dfd-8982e1f29021",
              "geometry": null
            },
            "contactInfo": {
              "email": "josh.elliott@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27088%27&dist_name=088%20-%Elliott,%Josh",
                "https://www.housedems.ct.gov/Elliott"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-88-lower-ocd-person-57461c01-e706-4ce5-9dfd-8982e1f29021": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/cddc0b8f-51e5-4ed1-af05-41de718801f3",
            "firstName": "Lezlye W.",
            "lastName": "Zupkus",
            "fullName": "Lezlye Zupkus",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.cthousegop.com/zupkus/wp-content/uploads/sites/73/2016/11/Zupkus_Bio_Web.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-CT-89-lower-ocd-person-cddc0b8f-51e5-4ed1-af05-41de718801f3",
              "builtID": "ct-lower-89",
              "externalID": "ocd-person/cddc0b8f-51e5-4ed1-af05-41de718801f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "lezlye.zupkus@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Zupkus/",
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27089%27&dist_name=089-Lezlye%20Zupkus",
                "https://www.cthousegop.com/Zupkus/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-89-lower-ocd-person-cddc0b8f-51e5-4ed1-af05-41de718801f3": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/02ce0fa3-ac25-42dd-9f5e-ee44b7e9d542",
            "firstName": "Craig Charles",
            "lastName": "Fishbein",
            "fullName": "Craig Fishbein",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cthousegop.com/fishbein/wp-content/uploads/sites/28/2019/01/sm_Fishbein-headshot-020117-7754.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-CT-90-lower-ocd-person-02ce0fa3-ac25-42dd-9f5e-ee44b7e9d542",
              "builtID": "ct-lower-90",
              "externalID": "ocd-person/02ce0fa3-ac25-42dd-9f5e-ee44b7e9d542",
              "geometry": null
            },
            "contactInfo": {
              "email": "craig.fishbein@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Fishbein/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27090%27&dist_name=Fishbein,%20Craig%20C.",
                "https://www.cthousegop.com/Fishbein/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-90-lower-ocd-person-02ce0fa3-ac25-42dd-9f5e-ee44b7e9d542": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/62869ad5-5744-4a50-95f5-56ecf5549e31",
            "firstName": "Michael C.",
            "lastName": "D'Agostino",
            "fullName": "Mike D'Agostino",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/DAgostino/images/DAgostino_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-CT-91-lower-ocd-person-62869ad5-5744-4a50-95f5-56ecf5549e31",
              "builtID": "ct-lower-91",
              "externalID": "ocd-person/62869ad5-5744-4a50-95f5-56ecf5549e31",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.dagostino@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27091%27&dist_name=091%20-%D%27Agostino,%Mike",
                "https://www.housedems.ct.gov/DAgostino"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-91-lower-ocd-person-62869ad5-5744-4a50-95f5-56ecf5549e31": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/23d08488-597c-4c35-b41a-f8aef1c264c4",
            "firstName": "Patricia A.",
            "lastName": "Dillon",
            "fullName": "Pat Dillon",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Dillon/images/Dillon_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-CT-92-lower-ocd-person-23d08488-597c-4c35-b41a-f8aef1c264c4",
              "builtID": "ct-lower-92",
              "externalID": "ocd-person/23d08488-597c-4c35-b41a-f8aef1c264c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "patricia.dillon@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27092%27&dist_name=Dillon,%20Patricia%20A.",
                "https://www.housedems.ct.gov/Dillon"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-92-lower-ocd-person-23d08488-597c-4c35-b41a-f8aef1c264c4": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/59460c80-5324-423f-a06c-158d69a30b02",
            "firstName": "Toni Edmonds",
            "lastName": "Walker",
            "fullName": "Toni Walker",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Walker/images/Walker_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-CT-93-lower-ocd-person-59460c80-5324-423f-a06c-158d69a30b02",
              "builtID": "ct-lower-93",
              "externalID": "ocd-person/59460c80-5324-423f-a06c-158d69a30b02",
              "geometry": null
            },
            "contactInfo": {
              "email": "toni.walker@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27093%27&dist_name=Walker,%20Toni%20E.",
                "https://www.housedems.ct.gov/Walker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-93-lower-ocd-person-59460c80-5324-423f-a06c-158d69a30b02": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/c78a6285-02a8-4b73-bac9-9f5807c2aacf",
            "firstName": "Robyn Artifaye",
            "lastName": "Porter",
            "fullName": "Robyn Porter",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Porter/images/Porter_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-CT-94-lower-ocd-person-c78a6285-02a8-4b73-bac9-9f5807c2aacf",
              "builtID": "ct-lower-94",
              "externalID": "ocd-person/c78a6285-02a8-4b73-bac9-9f5807c2aacf",
              "geometry": null
            },
            "contactInfo": {
              "email": "robyn.porter@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27094%27&dist_name=Porter,%20Robyn%20A.",
                "https://www.housedems.ct.gov/Porter"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-94-lower-ocd-person-c78a6285-02a8-4b73-bac9-9f5807c2aacf": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/6af561a7-e6d7-4a29-8f65-9a22a5734639",
            "firstName": "Juan R.",
            "lastName": "Candelaria",
            "fullName": "Juan Candelaria",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Candelaria/images/Candelaria_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-CT-95-lower-ocd-person-6af561a7-e6d7-4a29-8f65-9a22a5734639",
              "builtID": "ct-lower-95",
              "externalID": "ocd-person/6af561a7-e6d7-4a29-8f65-9a22a5734639",
              "geometry": null
            },
            "contactInfo": {
              "email": "juan.candelaria@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27095%27&dist_name=095%20-%Candelaria,%Juan%20R.",
                "https://www.housedems.ct.gov/Candelaria"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-95-lower-ocd-person-6af561a7-e6d7-4a29-8f65-9a22a5734639": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/273e9a65-2254-43f1-ae88-744e1fa3738a",
            "firstName": "Roland J.",
            "lastName": "Lemar",
            "fullName": "Roland Lemar",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Lemar/images/Lemar_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-CT-96-lower-ocd-person-273e9a65-2254-43f1-ae88-744e1fa3738a",
              "builtID": "ct-lower-96",
              "externalID": "ocd-person/273e9a65-2254-43f1-ae88-744e1fa3738a",
              "geometry": null
            },
            "contactInfo": {
              "email": "roland.lemar@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27096%27&dist_name=Lemar,%20Roland%20J.",
                "https://www.housedems.ct.gov/lemar"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-96-lower-ocd-person-273e9a65-2254-43f1-ae88-744e1fa3738a": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/3e0a00e8-0ec8-418d-a2e3-a7eef0a5d186",
            "firstName": "Alphonse",
            "lastName": "Paolillo",
            "fullName": "Al Paolillo",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Paolillo/images/Paolillo_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-CT-97-lower-ocd-person-3e0a00e8-0ec8-418d-a2e3-a7eef0a5d186",
              "builtID": "ct-lower-97",
              "externalID": "ocd-person/3e0a00e8-0ec8-418d-a2e3-a7eef0a5d186",
              "geometry": null
            },
            "contactInfo": {
              "email": "alphonse.paolillo@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27097%27&dist_name=Paolillo,%20Alphonse",
                "https://www.housedems.ct.gov/Paolillo"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-97-lower-ocd-person-3e0a00e8-0ec8-418d-a2e3-a7eef0a5d186": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/adb6f661-e282-42a5-8b55-cb1e5f56aa5a",
            "firstName": "Moira Meenaghan",
            "lastName": "Rader",
            "fullName": "Moira Rader",
            "gender": "Female",
            "party": "democratic/working families",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-CT-98-lower-ocd-person-adb6f661-e282-42a5-8b55-cb1e5f56aa5a",
              "builtID": "ct-lower-98",
              "externalID": "ocd-person/adb6f661-e282-42a5-8b55-cb1e5f56aa5a",
              "geometry": null
            },
            "contactInfo": {
              "email": "moira.rader@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.housedems.ct.gov/Rader"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-98-lower-ocd-person-adb6f661-e282-42a5-8b55-cb1e5f56aa5a": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/391a83ee-0e23-4669-b3ae-b29bd9c77294",
            "firstName": "Joseph H.",
            "lastName": "Zullo",
            "fullName": "Joe Zullo",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cga.ct.gov/legpics/099.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-CT-99-lower-ocd-person-391a83ee-0e23-4669-b3ae-b29bd9c77294",
              "builtID": "ct-lower-99",
              "externalID": "ocd-person/391a83ee-0e23-4669-b3ae-b29bd9c77294",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.zullo@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27099%27&dist_name=Zullo,%20Joseph%20H.",
                "https://www.cthousegop.com/zullo/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-99-lower-ocd-person-391a83ee-0e23-4669-b3ae-b29bd9c77294": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/75ee19d6-dea2-4ef3-9682-0bfa9f53fb38",
            "firstName": "Kai Juanna",
            "lastName": "Belton",
            "fullName": "Kai Belton",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-CT-100-lower-ocd-person-75ee19d6-dea2-4ef3-9682-0bfa9f53fb38",
              "builtID": "ct-lower-100",
              "externalID": "ocd-person/75ee19d6-dea2-4ef3-9682-0bfa9f53fb38",
              "geometry": null
            },
            "contactInfo": {
              "email": "kai.belton@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.ct.gov/belton"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-100-lower-ocd-person-75ee19d6-dea2-4ef3-9682-0bfa9f53fb38": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/7e0711c4-57d1-405e-afe7-33df0bf064d6",
            "firstName": "John-Michael",
            "lastName": "Parker",
            "fullName": "John-Michael Parker",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Parker/images/Parker_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-CT-101-lower-ocd-person-7e0711c4-57d1-405e-afe7-33df0bf064d6",
              "builtID": "ct-lower-101",
              "externalID": "ocd-person/7e0711c4-57d1-405e-afe7-33df0bf064d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "john-michael.parker@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27101%27&dist_name=101%20-%Parker,%John-Michael",
                "https://www.housedems.ct.gov/Parker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-101-lower-ocd-person-7e0711c4-57d1-405e-afe7-33df0bf064d6": 0
        }
      },
      "102": {
        "members": [
          {
            "API_ID": "ocd-person/5d963d6d-74c1-4282-9ad4-592c3760bead",
            "firstName": "Robin Elaine",
            "lastName": "Comey",
            "fullName": "Robin Comey",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Comey/images/Comey_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "102",
              "chamber": "lower",
              "hashID": "SLDL-CT-102-lower-ocd-person-5d963d6d-74c1-4282-9ad4-592c3760bead",
              "builtID": "ct-lower-102",
              "externalID": "ocd-person/5d963d6d-74c1-4282-9ad4-592c3760bead",
              "geometry": null
            },
            "contactInfo": {
              "email": "robin.comey@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.ct.gov/comey",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27102%27&dist_name=Comey,%20Robin%20E.",
                "https://www.housedems.ct.gov/comey"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-102-lower-ocd-person-5d963d6d-74c1-4282-9ad4-592c3760bead": 0
        }
      },
      "103": {
        "members": [
          {
            "API_ID": "ocd-person/96e6350e-71c4-4031-8af8-a989d0ec3a01",
            "firstName": "Elizabeth F.",
            "lastName": "Linehan",
            "fullName": "Liz Linehan",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Linehan/images/Linehan_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "103",
              "chamber": "lower",
              "hashID": "SLDL-CT-103-lower-ocd-person-96e6350e-71c4-4031-8af8-a989d0ec3a01",
              "builtID": "ct-lower-103",
              "externalID": "ocd-person/96e6350e-71c4-4031-8af8-a989d0ec3a01",
              "geometry": null
            },
            "contactInfo": {
              "email": "liz.linehan@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27103%27&dist_name=103%20-%Linehan,%Liz",
                "https://www.housedems.ct.gov/Linehan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-103-lower-ocd-person-96e6350e-71c4-4031-8af8-a989d0ec3a01": 0
        }
      },
      "104": {
        "members": [
          {
            "API_ID": "ocd-person/3969673d-4a3e-487e-a572-42ce9f79e6dd",
            "firstName": "Kara Elise",
            "lastName": "Rochelle",
            "fullName": "Kara Rochelle",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Rochelle/images/Rochelle_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "104",
              "chamber": "lower",
              "hashID": "SLDL-CT-104-lower-ocd-person-3969673d-4a3e-487e-a572-42ce9f79e6dd",
              "builtID": "ct-lower-104",
              "externalID": "ocd-person/3969673d-4a3e-487e-a572-42ce9f79e6dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "kara.rochelle@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27104%27&dist_name=104%20-%Rochelle,%Kara",
                "https://www.housedems.ct.gov/rochelle"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-104-lower-ocd-person-3969673d-4a3e-487e-a572-42ce9f79e6dd": 0
        }
      },
      "105": {
        "members": [
          {
            "API_ID": "ocd-person/71c15656-a6f4-4b95-9537-69a82b2cb54c",
            "firstName": "Nicole",
            "lastName": "Klarides-Ditria",
            "fullName": "Nicole Klarides-Ditria",
            "gender": "Female",
            "party": "Republican",
            "image": "https://cga.ct.gov/legpics/105.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "105",
              "chamber": "lower",
              "hashID": "SLDL-CT-105-lower-ocd-person-71c15656-a6f4-4b95-9537-69a82b2cb54c",
              "builtID": "ct-lower-105",
              "externalID": "ocd-person/71c15656-a6f4-4b95-9537-69a82b2cb54c",
              "geometry": null
            },
            "contactInfo": {
              "email": "nicole.klarides-ditria@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Klarides-Ditria/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27105%27&dist_name=Klarides-Ditria,%20Nicole",
                "https://www.cthousegop.com/Klarides-Ditria/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-105-lower-ocd-person-71c15656-a6f4-4b95-9537-69a82b2cb54c": 0
        }
      },
      "106": {
        "members": [
          {
            "API_ID": "ocd-person/5af50689-4e90-49b1-8544-7a00048dad99",
            "firstName": "Mitchell S.",
            "lastName": "Bolinsky",
            "fullName": "Mitch Bolinsky",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cthousegop.com/bolinsky/wp-content/uploads/sites/8/2016/12/Bolinsky-Bio-Web-Sized.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "106",
              "chamber": "lower",
              "hashID": "SLDL-CT-106-lower-ocd-person-5af50689-4e90-49b1-8544-7a00048dad99",
              "builtID": "ct-lower-106",
              "externalID": "ocd-person/5af50689-4e90-49b1-8544-7a00048dad99",
              "geometry": null
            },
            "contactInfo": {
              "email": "mitch.bolinsky@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Bolinsky/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27106%27&dist_name=Bolinsky,%20Mitch",
                "https://www.cthousegop.com/Bolinsky/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-106-lower-ocd-person-5af50689-4e90-49b1-8544-7a00048dad99": 0
        }
      },
      "107": {
        "members": [
          {
            "API_ID": "ocd-person/f3b77c59-132b-4e51-a148-641a891a48a6",
            "firstName": "Martin",
            "lastName": "Foncello",
            "fullName": "Marty Foncello",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "107",
              "chamber": "lower",
              "hashID": "SLDL-CT-107-lower-ocd-person-f3b77c59-132b-4e51-a148-641a891a48a6",
              "builtID": "ct-lower-107",
              "externalID": "ocd-person/f3b77c59-132b-4e51-a148-641a891a48a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "martin.foncello@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.cthousegop.com/Foncello/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-107-lower-ocd-person-f3b77c59-132b-4e51-a148-641a891a48a6": 0
        }
      },
      "108": {
        "members": [
          {
            "API_ID": "ocd-person/72191c68-6a23-4fa2-93d4-ba8dc07c2025",
            "firstName": "Patrick",
            "lastName": "Callahan",
            "fullName": "Pat Callahan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cga.ct.gov/legpics/108.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "108",
              "chamber": "lower",
              "hashID": "SLDL-CT-108-lower-ocd-person-72191c68-6a23-4fa2-93d4-ba8dc07c2025",
              "builtID": "ct-lower-108",
              "externalID": "ocd-person/72191c68-6a23-4fa2-93d4-ba8dc07c2025",
              "geometry": null
            },
            "contactInfo": {
              "email": "patrick.callahan@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Callahan",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27108%27&dist_name=Callahan,%20Patrick%20E.",
                "https://www.cthousegop.com/Callahan/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-108-lower-ocd-person-72191c68-6a23-4fa2-93d4-ba8dc07c2025": 0
        }
      },
      "109": {
        "members": [
          {
            "API_ID": "ocd-person/b9f6e7c0-0413-4490-a3a4-3fb63620b4cc",
            "firstName": "Farley",
            "lastName": "Santos",
            "fullName": "Farley Santos",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "109",
              "chamber": "lower",
              "hashID": "SLDL-CT-109-lower-ocd-person-b9f6e7c0-0413-4490-a3a4-3fb63620b4cc",
              "builtID": "ct-lower-109",
              "externalID": "ocd-person/b9f6e7c0-0413-4490-a3a4-3fb63620b4cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "farley.santos@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.housedems.ct.gov/Santos"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-109-lower-ocd-person-b9f6e7c0-0413-4490-a3a4-3fb63620b4cc": 0
        }
      },
      "110": {
        "members": [
          {
            "API_ID": "ocd-person/97e1ed70-218e-41d6-abad-73cf8c717c5b",
            "firstName": "Robert D.",
            "lastName": "Godfrey",
            "fullName": "Bob Godfrey",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Godfrey/images/Godfrey_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "110",
              "chamber": "lower",
              "hashID": "SLDL-CT-110-lower-ocd-person-97e1ed70-218e-41d6-abad-73cf8c717c5b",
              "builtID": "ct-lower-110",
              "externalID": "ocd-person/97e1ed70-218e-41d6-abad-73cf8c717c5b",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.godfrey@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27110%27&dist_name=Godfrey,%20Bob",
                "https://www.housedems.ct.gov/Godfrey"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-110-lower-ocd-person-97e1ed70-218e-41d6-abad-73cf8c717c5b": 0
        }
      },
      "111": {
        "members": [
          {
            "API_ID": "ocd-person/b43c741a-89b2-4e46-abfc-1d75f26189fe",
            "firstName": "Aimee",
            "lastName": "Berger-Girvalo",
            "fullName": "Aimee Berger-Girvalo",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Berger-Girvalo/images/Berger-Girvalo_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "111",
              "chamber": "lower",
              "hashID": "SLDL-CT-111-lower-ocd-person-b43c741a-89b2-4e46-abfc-1d75f26189fe",
              "builtID": "ct-lower-111",
              "externalID": "ocd-person/b43c741a-89b2-4e46-abfc-1d75f26189fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "aimee.berger-girvalo@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27111%27&dist_name=Berger-Girvalo,%20Aimee",
                "https://www.housedems.ct.gov/Berger-Girvalo"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-111-lower-ocd-person-b43c741a-89b2-4e46-abfc-1d75f26189fe": 0
        }
      },
      "112": {
        "members": [
          {
            "API_ID": "ocd-person/bc3200fa-f97b-433f-bb0b-ac15f71b2e92",
            "firstName": "Tony",
            "lastName": "Scott",
            "fullName": "Tony Scott",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cga.ct.gov/legpics/112.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "112",
              "chamber": "lower",
              "hashID": "SLDL-CT-112-lower-ocd-person-bc3200fa-f97b-433f-bb0b-ac15f71b2e92",
              "builtID": "ct-lower-112",
              "externalID": "ocd-person/bc3200fa-f97b-433f-bb0b-ac15f71b2e92",
              "geometry": null
            },
            "contactInfo": {
              "email": "tony.scott@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Scott",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27112%27&dist_name=Scott,%20Tony%20J",
                "https://www.cthousegop.com/Scott/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-112-lower-ocd-person-bc3200fa-f97b-433f-bb0b-ac15f71b2e92": 0
        }
      },
      "113": {
        "members": [
          {
            "API_ID": "ocd-person/78bb88a9-cde1-4691-aa7a-e423ee16a147",
            "firstName": "Jason",
            "lastName": "Perillo",
            "fullName": "Jason Perillo",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cga.ct.gov/legpics/113.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "113",
              "chamber": "lower",
              "hashID": "SLDL-CT-113-lower-ocd-person-78bb88a9-cde1-4691-aa7a-e423ee16a147",
              "builtID": "ct-lower-113",
              "externalID": "ocd-person/78bb88a9-cde1-4691-aa7a-e423ee16a147",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.perillo@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Perillo/",
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27113%27&dist_name=Jason,%20Perillo",
                "https://www.cthousegop.com/Perillo/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-113-lower-ocd-person-78bb88a9-cde1-4691-aa7a-e423ee16a147": 0
        }
      },
      "114": {
        "members": [
          {
            "API_ID": "ocd-person/53fa49be-9042-4e23-a1ff-a85f98a3d9f7",
            "firstName": "Mary",
            "lastName": "Welander",
            "fullName": "Mary Welander",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "http://www2.housedems.ct.gov/Welander/images/Welander_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "114",
              "chamber": "lower",
              "hashID": "SLDL-CT-114-lower-ocd-person-53fa49be-9042-4e23-a1ff-a85f98a3d9f7",
              "builtID": "ct-lower-114",
              "externalID": "ocd-person/53fa49be-9042-4e23-a1ff-a85f98a3d9f7",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.welander@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27114%27&dist_name=114%20-%Welander,%Mary",
                "https://www.housedems.ct.gov/Welander"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-114-lower-ocd-person-53fa49be-9042-4e23-a1ff-a85f98a3d9f7": 0
        }
      },
      "115": {
        "members": [
          {
            "API_ID": "ocd-person/e4ee574a-8df3-46b3-93cb-3ec24cb49144",
            "firstName": "William J.",
            "lastName": "Heffernan",
            "fullName": "Bill Heffernan",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "115",
              "chamber": "lower",
              "hashID": "SLDL-CT-115-lower-ocd-person-e4ee574a-8df3-46b3-93cb-3ec24cb49144",
              "builtID": "ct-lower-115",
              "externalID": "ocd-person/e4ee574a-8df3-46b3-93cb-3ec24cb49144",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.heffernan@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.housedems.ct.gov/heffernan"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-115-lower-ocd-person-e4ee574a-8df3-46b3-93cb-3ec24cb49144": 0
        }
      },
      "116": {
        "members": [
          {
            "API_ID": "ocd-person/5a41c633-8d04-43c6-b3c2-64cbbbbefcc4",
            "firstName": "Trenee D.",
            "lastName": "McGee",
            "fullName": "Treneé McGee",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/McGeeT/images/McGeeT_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "116",
              "chamber": "lower",
              "hashID": "SLDL-CT-116-lower-ocd-person-5a41c633-8d04-43c6-b3c2-64cbbbbefcc4",
              "builtID": "ct-lower-116",
              "externalID": "ocd-person/5a41c633-8d04-43c6-b3c2-64cbbbbefcc4",
              "geometry": null
            },
            "contactInfo": {
              "email": "trenee.mcgee@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.housedems.ct.gov/McgeeT",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27116%27&dist_name=McGee,%20Trenee",
                "https://www.housedems.ct.gov/McGeeT"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-116-lower-ocd-person-5a41c633-8d04-43c6-b3c2-64cbbbbefcc4": 0
        }
      },
      "117": {
        "members": [
          {
            "API_ID": "ocd-person/bc0526f4-43f7-4ad1-b518-ceb1680771f8",
            "firstName": "Charles J.",
            "lastName": "Ferraro",
            "fullName": "Charles Ferraro",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cthousegop.com/ferraro/wp-content/uploads/sites/27/2016/11/Ferraro_Web_Bio.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "117",
              "chamber": "lower",
              "hashID": "SLDL-CT-117-lower-ocd-person-bc0526f4-43f7-4ad1-b518-ceb1680771f8",
              "builtID": "ct-lower-117",
              "externalID": "ocd-person/bc0526f4-43f7-4ad1-b518-ceb1680771f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.ferraro@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Ferraro/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27117%27&dist_name=Ferraro,%20Charles%20J.",
                "https://www.cthousegop.com/Ferraro/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-117-lower-ocd-person-bc0526f4-43f7-4ad1-b518-ceb1680771f8": 0
        }
      },
      "118": {
        "members": [
          {
            "API_ID": "ocd-person/c09f368c-d954-48e1-b94b-18728ab9b6bd",
            "firstName": "Frank J.",
            "lastName": "Smith",
            "fullName": "Frank Smith",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "http://www2.housedems.ct.gov/SmithF/images/SmithF_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "118",
              "chamber": "lower",
              "hashID": "SLDL-CT-118-lower-ocd-person-c09f368c-d954-48e1-b94b-18728ab9b6bd",
              "builtID": "ct-lower-118",
              "externalID": "ocd-person/c09f368c-d954-48e1-b94b-18728ab9b6bd",
              "geometry": null
            },
            "contactInfo": {
              "email": "frank.smith@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27118%27&dist_name=Smith,%20Frank",
                "https://www.housedems.ct.gov/SmithF"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-118-lower-ocd-person-c09f368c-d954-48e1-b94b-18728ab9b6bd": 0
        }
      },
      "119": {
        "members": [
          {
            "API_ID": "ocd-person/f0517411-fc60-49ad-86e6-1f025ab4579b",
            "firstName": "Kathleen A.",
            "lastName": "Kennedy",
            "fullName": "Kathy Kennedy",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.cga.ct.gov/legpics/119.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "119",
              "chamber": "lower",
              "hashID": "SLDL-CT-119-lower-ocd-person-f0517411-fc60-49ad-86e6-1f025ab4579b",
              "builtID": "ct-lower-119",
              "externalID": "ocd-person/f0517411-fc60-49ad-86e6-1f025ab4579b",
              "geometry": null
            },
            "contactInfo": {
              "email": "kathy.kennedy@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27119%27",
                "https://www.cthousegop.com/kennedy/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-119-lower-ocd-person-f0517411-fc60-49ad-86e6-1f025ab4579b": 0
        }
      },
      "120": {
        "members": [
          {
            "API_ID": "ocd-person/fa435f05-311f-40a2-b8b4-400486a89f1d",
            "firstName": "Laura",
            "lastName": "Dancho",
            "fullName": "Laura Dancho",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "120",
              "chamber": "lower",
              "hashID": "SLDL-CT-120-lower-ocd-person-fa435f05-311f-40a2-b8b4-400486a89f1d",
              "builtID": "ct-lower-120",
              "externalID": "ocd-person/fa435f05-311f-40a2-b8b4-400486a89f1d",
              "geometry": null
            },
            "contactInfo": {
              "email": "laura.dancho@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/dancho/",
                "https://www.cthousegop.com/Dancho/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-120-lower-ocd-person-fa435f05-311f-40a2-b8b4-400486a89f1d": 0
        }
      },
      "121": {
        "members": [
          {
            "API_ID": "ocd-person/06bb6ad8-3238-4ba7-8f83-47507541a300",
            "firstName": "Joseph Paul",
            "lastName": "Gresko",
            "fullName": "Joe Gresko",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Gresko/images/gresko_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "121",
              "chamber": "lower",
              "hashID": "SLDL-CT-121-lower-ocd-person-06bb6ad8-3238-4ba7-8f83-47507541a300",
              "builtID": "ct-lower-121",
              "externalID": "ocd-person/06bb6ad8-3238-4ba7-8f83-47507541a300",
              "geometry": null
            },
            "contactInfo": {
              "email": "joseph.gresko@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27121%27&dist_name=121%20-%Gresko,%Joseph%20P.",
                "https://www.housedems.ct.gov/Gresko"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-121-lower-ocd-person-06bb6ad8-3238-4ba7-8f83-47507541a300": 0
        }
      },
      "122": {
        "members": [
          {
            "API_ID": "ocd-person/240d52a3-33df-495c-b1a0-142cc43f3fce",
            "firstName": "Bernard H.",
            "lastName": "McGorty",
            "fullName": "Ben McGorty",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cga.ct.gov/legpics/122.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "122",
              "chamber": "lower",
              "hashID": "SLDL-CT-122-lower-ocd-person-240d52a3-33df-495c-b1a0-142cc43f3fce",
              "builtID": "ct-lower-122",
              "externalID": "ocd-person/240d52a3-33df-495c-b1a0-142cc43f3fce",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.mcgorty@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/McGorty/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27122%27&dist_name=McGorty,%20Ben",
                "https://www.cthousegop.com/McGorty/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-122-lower-ocd-person-240d52a3-33df-495c-b1a0-142cc43f3fce": 0
        }
      },
      "123": {
        "members": [
          {
            "API_ID": "ocd-person/01bfbaf3-d536-45f0-8478-96cd8fbd4518",
            "firstName": "David J.",
            "lastName": "Rutigliano",
            "fullName": "Dave Rutigliano",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cga.ct.gov/legpics/123.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "123",
              "chamber": "lower",
              "hashID": "SLDL-CT-123-lower-ocd-person-01bfbaf3-d536-45f0-8478-96cd8fbd4518",
              "builtID": "ct-lower-123",
              "externalID": "ocd-person/01bfbaf3-d536-45f0-8478-96cd8fbd4518",
              "geometry": null
            },
            "contactInfo": {
              "email": "dave.rutigliano@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Rutigliano/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27123%27&dist_name=Rutigliano,%20David",
                "https://www.cthousegop.com/Rutigliano/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-123-lower-ocd-person-01bfbaf3-d536-45f0-8478-96cd8fbd4518": 0
        }
      },
      "124": {
        "members": [
          {
            "API_ID": "ocd-person/e823b0bf-1132-4ed8-b860-e0febf9a275a",
            "firstName": "Andre F.",
            "lastName": "Baker",
            "fullName": "Andre Baker",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Baker/images/Baker_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "124",
              "chamber": "lower",
              "hashID": "SLDL-CT-124-lower-ocd-person-e823b0bf-1132-4ed8-b860-e0febf9a275a",
              "builtID": "ct-lower-124",
              "externalID": "ocd-person/e823b0bf-1132-4ed8-b860-e0febf9a275a",
              "geometry": null
            },
            "contactInfo": {
              "email": "andre.baker@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27124%27&dist_name=Baker,%20Andre%20F.",
                "https://www.housedems.ct.gov/Baker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-124-lower-ocd-person-e823b0bf-1132-4ed8-b860-e0febf9a275a": 0
        }
      },
      "125": {
        "members": [
          {
            "API_ID": "ocd-person/03642345-7acb-4294-91b5-84dcc3c18cc7",
            "firstName": "Thomas Patrick",
            "lastName": "O'Dea",
            "fullName": "Tom O'Dea",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cthousegop.com/odea/wp-content/uploads/sites/46/2018/05/ODea-Headshot-683x1024.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "125",
              "chamber": "lower",
              "hashID": "SLDL-CT-125-lower-ocd-person-03642345-7acb-4294-91b5-84dcc3c18cc7",
              "builtID": "ct-lower-125",
              "externalID": "ocd-person/03642345-7acb-4294-91b5-84dcc3c18cc7",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.odea@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/ODea/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27125%27&dist_name=O%27Dea,%20Tom",
                "https://www.cthousegop.com/odea/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-125-lower-ocd-person-03642345-7acb-4294-91b5-84dcc3c18cc7": 0
        }
      },
      "126": {
        "members": [
          {
            "API_ID": "ocd-person/2d97c881-725d-47a8-9bea-8d3a5f14fbd0",
            "firstName": "Fred Douglas",
            "lastName": "Gee",
            "fullName": "Fred Gee",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "126",
              "chamber": "lower",
              "hashID": "SLDL-CT-126-lower-ocd-person-2d97c881-725d-47a8-9bea-8d3a5f14fbd0",
              "builtID": "ct-lower-126",
              "externalID": "ocd-person/2d97c881-725d-47a8-9bea-8d3a5f14fbd0",
              "geometry": null
            },
            "contactInfo": {
              "email": "fred.gee@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.housedems.ct.gov/gee",
                "https://www.housedems.ct.gov/Gee"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-126-lower-ocd-person-2d97c881-725d-47a8-9bea-8d3a5f14fbd0": 0
        }
      },
      "127": {
        "members": [
          {
            "API_ID": "ocd-person/4e77c536-5645-4e15-99b2-00a781896717",
            "firstName": "Marcus A.",
            "lastName": "Brown Harrigan",
            "fullName": "Marcus Brown",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "127",
              "chamber": "lower",
              "hashID": "SLDL-CT-127-lower-ocd-person-4e77c536-5645-4e15-99b2-00a781896717",
              "builtID": "ct-lower-127",
              "externalID": "ocd-person/4e77c536-5645-4e15-99b2-00a781896717",
              "geometry": null
            },
            "contactInfo": {
              "email": "marcus.brown@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.housedems.ct.gov/BrownM"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-127-lower-ocd-person-4e77c536-5645-4e15-99b2-00a781896717": 0
        }
      },
      "128": {
        "members": [
          {
            "API_ID": "ocd-person/ea0027a9-6952-4b74-a771-a14ced4e647e",
            "firstName": "Christopher",
            "lastName": "Rosario",
            "fullName": "Christopher Rosario",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Rosario/images/Rosario_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "128",
              "chamber": "lower",
              "hashID": "SLDL-CT-128-lower-ocd-person-ea0027a9-6952-4b74-a771-a14ced4e647e",
              "builtID": "ct-lower-128",
              "externalID": "ocd-person/ea0027a9-6952-4b74-a771-a14ced4e647e",
              "geometry": null
            },
            "contactInfo": {
              "email": "christopher.rosario@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27128%27&dist_name=Rosario,%20Christopher",
                "https://www.housedems.ct.gov/Rosario"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-128-lower-ocd-person-ea0027a9-6952-4b74-a771-a14ced4e647e": 0
        }
      },
      "129": {
        "members": [
          {
            "API_ID": "ocd-person/cb252e7b-fffb-4bda-b951-d2b54a3c37e3",
            "firstName": "Steven J.",
            "lastName": "Stafstrom",
            "fullName": "Steve Stafstrom",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Stafstrom/images/Stafstrom_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "129",
              "chamber": "lower",
              "hashID": "SLDL-CT-129-lower-ocd-person-cb252e7b-fffb-4bda-b951-d2b54a3c37e3",
              "builtID": "ct-lower-129",
              "externalID": "ocd-person/cb252e7b-fffb-4bda-b951-d2b54a3c37e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.stafstrom@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27129%27&dist_name=Stafstrom,%20Steven%20J.",
                "https://www.housedems.ct.gov/Stafstrom"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-129-lower-ocd-person-cb252e7b-fffb-4bda-b951-d2b54a3c37e3": 0
        }
      },
      "130": {
        "members": [
          {
            "API_ID": "ocd-person/caaba025-7a5a-401d-8714-73f627651196",
            "firstName": "Antonio D.",
            "lastName": "Felipe",
            "fullName": "Antonio Felipe",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Felipe/images/Felipe_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "130",
              "chamber": "lower",
              "hashID": "SLDL-CT-130-lower-ocd-person-caaba025-7a5a-401d-8714-73f627651196",
              "builtID": "ct-lower-130",
              "externalID": "ocd-person/caaba025-7a5a-401d-8714-73f627651196",
              "geometry": null
            },
            "contactInfo": {
              "email": "antonio.felipe@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27130%27&dist_name=Felipe,%20Antonio",
                "https://www.housedems.ct.gov/felipe"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-130-lower-ocd-person-caaba025-7a5a-401d-8714-73f627651196": 0
        }
      },
      "131": {
        "members": [
          {
            "API_ID": "ocd-person/0d2a3f7f-31d4-4e47-ba1f-7bfce484f6f9",
            "firstName": "David K.",
            "lastName": "Labriola",
            "fullName": "David Labriola",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cga.ct.gov/legpics/131.png?ver=",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "131",
              "chamber": "lower",
              "hashID": "SLDL-CT-131-lower-ocd-person-0d2a3f7f-31d4-4e47-ba1f-7bfce484f6f9",
              "builtID": "ct-lower-131",
              "externalID": "ocd-person/0d2a3f7f-31d4-4e47-ba1f-7bfce484f6f9",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.labriola@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Labriola/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27131%27&dist_name=Labriola,%20David%20K.",
                "https://www.cthousegop.com/Labriola/main/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-131-lower-ocd-person-0d2a3f7f-31d4-4e47-ba1f-7bfce484f6f9": 0
        }
      },
      "132": {
        "members": [
          {
            "API_ID": "ocd-person/15c5ac01-eb03-4dd3-8ab6-d162e7420559",
            "firstName": "Jennifer Marie",
            "lastName": "Leeper",
            "fullName": "Jenn Leeper",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "http://www2.housedems.ct.gov/Leeper/images/Leeper_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "132",
              "chamber": "lower",
              "hashID": "SLDL-CT-132-lower-ocd-person-15c5ac01-eb03-4dd3-8ab6-d162e7420559",
              "builtID": "ct-lower-132",
              "externalID": "ocd-person/15c5ac01-eb03-4dd3-8ab6-d162e7420559",
              "geometry": null
            },
            "contactInfo": {
              "email": "jennifer.leeper@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27132%27&dist_name=132%20-%Leeper,%Jennifer",
                "https://www.housedems.ct.gov/Leeper"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-132-lower-ocd-person-15c5ac01-eb03-4dd3-8ab6-d162e7420559": 0
        }
      },
      "133": {
        "members": [
          {
            "API_ID": "ocd-person/dfc1bead-50cd-4c34-9dc6-5d80c7a09589",
            "firstName": "Cristin",
            "lastName": "McCarthy Vahey",
            "fullName": "Cristin McCarthy Vahey",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/McCarthyVahey/images/McCarthyVahey_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "133",
              "chamber": "lower",
              "hashID": "SLDL-CT-133-lower-ocd-person-dfc1bead-50cd-4c34-9dc6-5d80c7a09589",
              "builtID": "ct-lower-133",
              "externalID": "ocd-person/dfc1bead-50cd-4c34-9dc6-5d80c7a09589",
              "geometry": null
            },
            "contactInfo": {
              "email": "cristin.mccarthyvahey@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27133%27&dist_name=McCarthy%20Vahey,%20Cristin",
                "https://www.housedems.ct.gov/McCarthyVahey"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-133-lower-ocd-person-dfc1bead-50cd-4c34-9dc6-5d80c7a09589": 0
        }
      },
      "134": {
        "members": [
          {
            "API_ID": "ocd-person/7af92839-ae80-4370-a84a-e63799ed5a48",
            "firstName": "Sarah",
            "lastName": "Keitt",
            "fullName": "Sarah Keitt",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "134",
              "chamber": "lower",
              "hashID": "SLDL-CT-134-lower-ocd-person-7af92839-ae80-4370-a84a-e63799ed5a48",
              "builtID": "ct-lower-134",
              "externalID": "ocd-person/7af92839-ae80-4370-a84a-e63799ed5a48",
              "geometry": null
            },
            "contactInfo": {
              "email": "sarah.keitt@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.housedems.ct.gov/keitt",
                "https://www.housedems.ct.gov/Keitt"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-134-lower-ocd-person-7af92839-ae80-4370-a84a-e63799ed5a48": 0
        }
      },
      "135": {
        "members": [
          {
            "API_ID": "ocd-person/ce2070f7-9487-4856-976b-9bbd6a2f1617",
            "firstName": "Anne Meiman",
            "lastName": "Hughes",
            "fullName": "Anne Hughes",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Hughes/images/Hughes_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "135",
              "chamber": "lower",
              "hashID": "SLDL-CT-135-lower-ocd-person-ce2070f7-9487-4856-976b-9bbd6a2f1617",
              "builtID": "ct-lower-135",
              "externalID": "ocd-person/ce2070f7-9487-4856-976b-9bbd6a2f1617",
              "geometry": null
            },
            "contactInfo": {
              "email": "anne.hughes@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27135%27&dist_name=Hughes,%20Anne%20M.",
                "https://www.housedems.ct.gov/hughes"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-135-lower-ocd-person-ce2070f7-9487-4856-976b-9bbd6a2f1617": 0
        }
      },
      "136": {
        "members": [
          {
            "API_ID": "ocd-person/22b7b95d-3488-4c14-8365-c5157b3cb099",
            "firstName": "Jonathan Philip",
            "lastName": "Steinberg",
            "fullName": "Jonathan Steinberg",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Steinberg/images/Steinberg_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "136",
              "chamber": "lower",
              "hashID": "SLDL-CT-136-lower-ocd-person-22b7b95d-3488-4c14-8365-c5157b3cb099",
              "builtID": "ct-lower-136",
              "externalID": "ocd-person/22b7b95d-3488-4c14-8365-c5157b3cb099",
              "geometry": null
            },
            "contactInfo": {
              "email": "jonathan.steinberg@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27136%27&dist_name=136%20-%Steinberg,%Jonathan",
                "https://www.housedems.ct.gov/Steinberg"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-136-lower-ocd-person-22b7b95d-3488-4c14-8365-c5157b3cb099": 0
        }
      },
      "137": {
        "members": [
          {
            "API_ID": "ocd-person/86852522-4c4d-46ae-8a59-d81a16ad179a",
            "firstName": "Kadeem",
            "lastName": "Roberts",
            "fullName": "Kadeem Roberts",
            "gender": "Male",
            "party": "democratic/working families",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "137",
              "chamber": "lower",
              "hashID": "SLDL-CT-137-lower-ocd-person-86852522-4c4d-46ae-8a59-d81a16ad179a",
              "builtID": "ct-lower-137",
              "externalID": "ocd-person/86852522-4c4d-46ae-8a59-d81a16ad179a",
              "geometry": null
            },
            "contactInfo": {
              "email": "kadeem.roberts@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.housedems.ct.gov/Roberts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-137-lower-ocd-person-86852522-4c4d-46ae-8a59-d81a16ad179a": 0
        }
      },
      "138": {
        "members": [
          {
            "API_ID": "ocd-person/141243eb-0829-4182-a625-15459678fae3",
            "firstName": "Rachel",
            "lastName": "Chaleski",
            "fullName": "Rachel Chaleski",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "138",
              "chamber": "lower",
              "hashID": "SLDL-CT-138-lower-ocd-person-141243eb-0829-4182-a625-15459678fae3",
              "builtID": "ct-lower-138",
              "externalID": "ocd-person/141243eb-0829-4182-a625-15459678fae3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rachel.chaleski@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Chaleski",
                "https://www.cthousegop.com/Chaleski/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-138-lower-ocd-person-141243eb-0829-4182-a625-15459678fae3": 0
        }
      },
      "139": {
        "members": [
          {
            "API_ID": "ocd-person/2d99a0ff-740d-4c97-8d0c-524842f99f73",
            "firstName": "Kevin",
            "lastName": "Ryan",
            "fullName": "Kevin Ryan",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Ryan/images/Ryan_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "139",
              "chamber": "lower",
              "hashID": "SLDL-CT-139-lower-ocd-person-2d99a0ff-740d-4c97-8d0c-524842f99f73",
              "builtID": "ct-lower-139",
              "externalID": "ocd-person/2d99a0ff-740d-4c97-8d0c-524842f99f73",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.ryan@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27139%27&dist_name=139%20-%Ryan,%Kevin",
                "https://www.housedems.ct.gov/Ryan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-139-lower-ocd-person-2d99a0ff-740d-4c97-8d0c-524842f99f73": 0
        }
      },
      "140": {
        "members": [
          {
            "API_ID": "ocd-person/9e9d6f02-ee29-4ff3-a6fe-1b8a0cec0612",
            "firstName": "Travis L.",
            "lastName": "Simms",
            "fullName": "Travis Simms",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Simms/images/Simms_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "140",
              "chamber": "lower",
              "hashID": "SLDL-CT-140-lower-ocd-person-9e9d6f02-ee29-4ff3-a6fe-1b8a0cec0612",
              "builtID": "ct-lower-140",
              "externalID": "ocd-person/9e9d6f02-ee29-4ff3-a6fe-1b8a0cec0612",
              "geometry": null
            },
            "contactInfo": {
              "email": "travis.simms@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27140%27&dist_name=Simms,%20Travis",
                "https://www.housedems.ct.gov/simms"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-140-lower-ocd-person-9e9d6f02-ee29-4ff3-a6fe-1b8a0cec0612": 0
        }
      },
      "141": {
        "members": [
          {
            "API_ID": "ocd-person/fd032e0e-7926-4f81-9477-6fdee260014b",
            "firstName": "Tracy Hunt",
            "lastName": "Marra",
            "fullName": "Tracy Marra",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "141",
              "chamber": "lower",
              "hashID": "SLDL-CT-141-lower-ocd-person-fd032e0e-7926-4f81-9477-6fdee260014b",
              "builtID": "ct-lower-141",
              "externalID": "ocd-person/fd032e0e-7926-4f81-9477-6fdee260014b",
              "geometry": null
            },
            "contactInfo": {
              "email": "tracy.marra@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.cthousegop.com/Marra/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-141-lower-ocd-person-fd032e0e-7926-4f81-9477-6fdee260014b": 0
        }
      },
      "142": {
        "members": [
          {
            "API_ID": "ocd-person/2ed1f7b0-a1e3-4754-93a5-46b5a3fb9836",
            "firstName": "Lucia S.",
            "lastName": "Dathan",
            "fullName": "Lucy Dathan",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Dathan/images/Dathan_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "142",
              "chamber": "lower",
              "hashID": "SLDL-CT-142-lower-ocd-person-2ed1f7b0-a1e3-4754-93a5-46b5a3fb9836",
              "builtID": "ct-lower-142",
              "externalID": "ocd-person/2ed1f7b0-a1e3-4754-93a5-46b5a3fb9836",
              "geometry": null
            },
            "contactInfo": {
              "email": "lucy.dathan@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27142%27&dist_name=142%20-%Dathan,%Lucy",
                "https://www.housedems.ct.gov/dathan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-142-lower-ocd-person-2ed1f7b0-a1e3-4754-93a5-46b5a3fb9836": 0
        }
      },
      "143": {
        "members": [
          {
            "API_ID": "ocd-person/d4c20e08-7210-40b8-8a4c-7aac8b59af7b",
            "firstName": "Dominique E.",
            "lastName": "Johnson",
            "fullName": "Dominique Johnson",
            "gender": "Female",
            "party": "democratic/working families",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "143",
              "chamber": "lower",
              "hashID": "SLDL-CT-143-lower-ocd-person-d4c20e08-7210-40b8-8a4c-7aac8b59af7b",
              "builtID": "ct-lower-143",
              "externalID": "ocd-person/d4c20e08-7210-40b8-8a4c-7aac8b59af7b",
              "geometry": null
            },
            "contactInfo": {
              "email": "dominique.johnson@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.housedems.ct.gov/JohnsonD"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-143-lower-ocd-person-d4c20e08-7210-40b8-8a4c-7aac8b59af7b": 0
        }
      },
      "144": {
        "members": [
          {
            "API_ID": "ocd-person/b666accd-c7f7-41e9-ade0-f64f6e438669",
            "firstName": "Hubert Douglas",
            "lastName": "Delany",
            "fullName": "Hubert Delany",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Delany/images/Delany_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "144",
              "chamber": "lower",
              "hashID": "SLDL-CT-144-lower-ocd-person-b666accd-c7f7-41e9-ade0-f64f6e438669",
              "builtID": "ct-lower-144",
              "externalID": "ocd-person/b666accd-c7f7-41e9-ade0-f64f6e438669",
              "geometry": null
            },
            "contactInfo": {
              "email": "hubert.delany@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.housedems.ct.gov/delany",
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27144%27&dist_name=144%20-%Delany,%Hubert",
                "https://www.housedems.ct.gov/Delany"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-144-lower-ocd-person-b666accd-c7f7-41e9-ade0-f64f6e438669": 0
        }
      },
      "145": {
        "members": [
          {
            "API_ID": "ocd-person/fcc3d3fa-4b4d-4d3c-b512-003a58657a85",
            "firstName": "Corey Phillip",
            "lastName": "Paris",
            "fullName": "Corey Paris",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Paris/images/Paris_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "145",
              "chamber": "lower",
              "hashID": "SLDL-CT-145-lower-ocd-person-fcc3d3fa-4b4d-4d3c-b512-003a58657a85",
              "builtID": "ct-lower-145",
              "externalID": "ocd-person/fcc3d3fa-4b4d-4d3c-b512-003a58657a85",
              "geometry": null
            },
            "contactInfo": {
              "email": "corey.paris@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.housedems.ct.gov/paris",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27145%27&dist_name=Paris,%20Corey%20P.",
                "https://www.housedems.ct.gov/Paris"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-145-lower-ocd-person-fcc3d3fa-4b4d-4d3c-b512-003a58657a85": 0
        }
      },
      "146": {
        "members": [
          {
            "API_ID": "ocd-person/2e21841e-7d65-4010-a0b2-c3bb779523c5",
            "firstName": "David",
            "lastName": "Michel",
            "fullName": "David Michel",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Michel/images/Michel_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "146",
              "chamber": "lower",
              "hashID": "SLDL-CT-146-lower-ocd-person-2e21841e-7d65-4010-a0b2-c3bb779523c5",
              "builtID": "ct-lower-146",
              "externalID": "ocd-person/2e21841e-7d65-4010-a0b2-c3bb779523c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.michel@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27146%27&dist_name=Michel,%20David",
                "https://www.housedems.ct.gov/michel"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-146-lower-ocd-person-2e21841e-7d65-4010-a0b2-c3bb779523c5": 0
        }
      },
      "147": {
        "members": [
          {
            "API_ID": "ocd-person/9c5e5d75-a622-41d2-b607-2adbc12ee11f",
            "firstName": "Matthew S.",
            "lastName": "Blumenthal",
            "fullName": "Matt Blumenthal",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Blumenthal/images/Blumenthal_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "147",
              "chamber": "lower",
              "hashID": "SLDL-CT-147-lower-ocd-person-9c5e5d75-a622-41d2-b607-2adbc12ee11f",
              "builtID": "ct-lower-147",
              "externalID": "ocd-person/9c5e5d75-a622-41d2-b607-2adbc12ee11f",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.blumenthal@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27147%27&dist_name=147%20-%Blumenthal,%Matt",
                "https://www.housedems.ct.gov/blumenthal"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-147-lower-ocd-person-9c5e5d75-a622-41d2-b607-2adbc12ee11f": 0
        }
      },
      "148": {
        "members": [
          {
            "API_ID": "ocd-person/3f4ac3de-8414-4046-b7b5-dda73046b72a",
            "firstName": "Anabel D.",
            "lastName": "Figueroa",
            "fullName": "Anabel Figueroa",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "148",
              "chamber": "lower",
              "hashID": "SLDL-CT-148-lower-ocd-person-3f4ac3de-8414-4046-b7b5-dda73046b72a",
              "builtID": "ct-lower-148",
              "externalID": "ocd-person/3f4ac3de-8414-4046-b7b5-dda73046b72a",
              "geometry": null
            },
            "contactInfo": {
              "email": "anabel.figueroa@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.ct.gov/figueroa",
                "https://www.housedems.ct.gov/figueroa"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-148-lower-ocd-person-3f4ac3de-8414-4046-b7b5-dda73046b72a": 0
        }
      },
      "149": {
        "members": [
          {
            "API_ID": "ocd-person/d5b9965b-6143-4358-b7bc-6a6178f03a22",
            "firstName": "Rachel",
            "lastName": "Khanna",
            "fullName": "Rachel Khanna",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "149",
              "chamber": "lower",
              "hashID": "SLDL-CT-149-lower-ocd-person-d5b9965b-6143-4358-b7bc-6a6178f03a22",
              "builtID": "ct-lower-149",
              "externalID": "ocd-person/d5b9965b-6143-4358-b7bc-6a6178f03a22",
              "geometry": null
            },
            "contactInfo": {
              "email": "rachel.khanna@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.housedems.ct.gov/khanna",
                "https://www.housedems.ct.gov/Khanna"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-149-lower-ocd-person-d5b9965b-6143-4358-b7bc-6a6178f03a22": 0
        }
      },
      "150": {
        "members": [
          {
            "API_ID": "ocd-person/77dbf0fd-d45c-445c-9966-0135fff8feaa",
            "firstName": "Stephen Robert",
            "lastName": "Meskers",
            "fullName": "Steve Meskers",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www2.housedems.ct.gov/Meskers/images/Meskers_HS.jpg",
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "150",
              "chamber": "lower",
              "hashID": "SLDL-CT-150-lower-ocd-person-77dbf0fd-d45c-445c-9966-0135fff8feaa",
              "builtID": "ct-lower-150",
              "externalID": "ocd-person/77dbf0fd-d45c-445c-9966-0135fff8feaa",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephen.meskers@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27150%27&dist_name=Meskers,%20Stephen%20R",
                "https://www.housedems.ct.gov/meskers"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-150-lower-ocd-person-77dbf0fd-d45c-445c-9966-0135fff8feaa": 0
        }
      },
      "151": {
        "members": [
          {
            "API_ID": "ocd-person/f0ce3c59-536e-4e79-9fe6-20eb59d66c06",
            "firstName": "Hector",
            "lastName": "Arzeno",
            "fullName": "Hector Arzeno",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "CT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "151",
              "chamber": "lower",
              "hashID": "SLDL-CT-151-lower-ocd-person-f0ce3c59-536e-4e79-9fe6-20eb59d66c06",
              "builtID": "ct-lower-151",
              "externalID": "ocd-person/f0ce3c59-536e-4e79-9fe6-20eb59d66c06",
              "geometry": null
            },
            "contactInfo": {
              "email": "hector.arzeno@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.housedems.ct.gov/arzeno",
                "https://www.housedems.ct.gov/Arzeno"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-CT-151-lower-ocd-person-f0ce3c59-536e-4e79-9fe6-20eb59d66c06": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/749be8b3-0b40-41ec-8670-7dfbfbc90b6e",
            "firstName": "John Walter",
            "lastName": "Fonfara",
            "fullName": "John Fonfara",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/fonfara/images/fonfara-hi.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-CT-1-upper-ocd-person-749be8b3-0b40-41ec-8670-7dfbfbc90b6e",
              "builtID": "ct-upper-1",
              "externalID": "ocd-person/749be8b3-0b40-41ec-8670-7dfbfbc90b6e",
              "geometry": null
            },
            "contactInfo": {
              "email": "fonfara@senatedems.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatedems.ct.gov/Fonfara",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S01%27&dist_name=Fonfara,%20John%20W.",
                "https://www.senatedems.ct.gov/fonfara",
                "https://www.senatedems.ct.gov/fonfara-161024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-1-upper-ocd-person-749be8b3-0b40-41ec-8670-7dfbfbc90b6e": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/83a4e876-4df7-48ef-8a84-7d7940585af9",
            "firstName": "Douglas M.",
            "lastName": "McCrory",
            "fullName": "Doug McCrory",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/mccrory/images/mccrory-hi.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-CT-2-upper-ocd-person-83a4e876-4df7-48ef-8a84-7d7940585af9",
              "builtID": "ct-upper-2",
              "externalID": "ocd-person/83a4e876-4df7-48ef-8a84-7d7940585af9",
              "geometry": null
            },
            "contactInfo": {
              "email": "douglas.mccrory@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatedems.ct.gov/McCrory",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S02%27&dist_name=McCrory,%20Douglas",
                "https://www.senatedems.ct.gov/mccrory",
                "https://www.senatedems.ct.gov/senator/doug-mccrory"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-2-upper-ocd-person-83a4e876-4df7-48ef-8a84-7d7940585af9": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/686df3d6-3199-4d48-a27e-7b3e7c65de66",
            "firstName": "M. Saud",
            "lastName": "Anwar",
            "fullName": "Saud Anwar",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/anwar/images/anwar-hi.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-CT-3-upper-ocd-person-686df3d6-3199-4d48-a27e-7b3e7c65de66",
              "builtID": "ct-upper-3",
              "externalID": "ocd-person/686df3d6-3199-4d48-a27e-7b3e7c65de66",
              "geometry": null
            },
            "contactInfo": {
              "email": "saud.anwar@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S03%27&dist_name=Anwar,%20Saud",
                "https://www.senatedems.ct.gov/anwar",
                "https://www.senatedems.ct.gov/anwar-190301"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-3-upper-ocd-person-686df3d6-3199-4d48-a27e-7b3e7c65de66": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/277414ca-35b8-41dd-ae2e-e99fe9ecdb9b",
            "firstName": "M.D. Masudur",
            "lastName": "Rahman",
            "fullName": "M.D. Rahman",
            "gender": "Male",
            "party": "democratic/working families",
            "image": null,
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-CT-4-upper-ocd-person-277414ca-35b8-41dd-ae2e-e99fe9ecdb9b",
              "builtID": "ct-upper-4",
              "externalID": "ocd-person/277414ca-35b8-41dd-ae2e-e99fe9ecdb9b",
              "geometry": null
            },
            "contactInfo": {
              "email": "md.rahman@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatedems.ct.gov/Rahman",
                "https://www.senatedems.ct.gov/rahman",
                "https://www.senatedems.ct.gov/senator/md-rahman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-4-upper-ocd-person-277414ca-35b8-41dd-ae2e-e99fe9ecdb9b": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/a33a2113-e60b-4000-9dc7-eb58a775d986",
            "firstName": "Derek",
            "lastName": "Slap",
            "fullName": "Derek Slap",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/slap/images/slap-hi.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-CT-5-upper-ocd-person-a33a2113-e60b-4000-9dc7-eb58a775d986",
              "builtID": "ct-upper-5",
              "externalID": "ocd-person/a33a2113-e60b-4000-9dc7-eb58a775d986",
              "geometry": null
            },
            "contactInfo": {
              "email": "derek.slap@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S05%27&dist_name=Slap,%20Derek",
                "https://www.senatedems.ct.gov/slap",
                "https://www.senatedems.ct.gov/senator/derek-slap"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-5-upper-ocd-person-a33a2113-e60b-4000-9dc7-eb58a775d986": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/bdc5f5a0-0285-45d2-a6fd-d29d8b31cd43",
            "firstName": "Richard P.",
            "lastName": "Lopes",
            "fullName": "Rick Lopes",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/lopes/images/lopes-hi.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-CT-6-upper-ocd-person-bdc5f5a0-0285-45d2-a6fd-d29d8b31cd43",
              "builtID": "ct-upper-6",
              "externalID": "ocd-person/bdc5f5a0-0285-45d2-a6fd-d29d8b31cd43",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.lopes@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.housedems.ct.gov/Lopes",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S06%27&dist_name=Lopes,%20Rick",
                "https://www.senatedems.ct.gov/lopes"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-6-upper-ocd-person-bdc5f5a0-0285-45d2-a6fd-d29d8b31cd43": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/da65a738-37c7-41c1-8cd4-abae65ef0167",
            "firstName": "John Andrew",
            "lastName": "Kissel",
            "fullName": "John Kissel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cga.ct.gov/legpics/S07.png?ver=",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-CT-7-upper-ocd-person-da65a738-37c7-41c1-8cd4-abae65ef0167",
              "builtID": "ct-upper-7",
              "externalID": "ocd-person/da65a738-37c7-41c1-8cd4-abae65ef0167",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.a.kissel@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ctsenaterepublicans.com/home-kissel",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S07%27&dist_name=Kissel,%20John%20A.",
                "https://ctsenaterepublicans.com/home-kissel/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-7-upper-ocd-person-da65a738-37c7-41c1-8cd4-abae65ef0167": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/1e151c18-1372-4233-96a7-3985c310e8dd",
            "firstName": "Lisa Spalvieri",
            "lastName": "Seminara",
            "fullName": "Lisa Seminara",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-CT-8-upper-ocd-person-1e151c18-1372-4233-96a7-3985c310e8dd",
              "builtID": "ct-upper-8",
              "externalID": "ocd-person/1e151c18-1372-4233-96a7-3985c310e8dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisa.seminara@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ctsenaterepublicans.com/home-seminara",
                "https://ctsenaterepublicans.com/home-seminara/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-8-upper-ocd-person-1e151c18-1372-4233-96a7-3985c310e8dd": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/f55ef0f9-f0ed-48ba-80b9-23ac643626cc",
            "firstName": "Matthew L.",
            "lastName": "Lesser",
            "fullName": "Matt Lesser",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/Lesser/images/Lesser1x1.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-CT-9-upper-ocd-person-f55ef0f9-f0ed-48ba-80b9-23ac643626cc",
              "builtID": "ct-upper-9",
              "externalID": "ocd-person/f55ef0f9-f0ed-48ba-80b9-23ac643626cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.lesser@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S09%27&dist_name=Lesser,%20Matthew%20L.",
                "https://www.senatedems.ct.gov/lesser",
                "https://www.senatedems.ct.gov/senator/matt-lesser"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-9-upper-ocd-person-f55ef0f9-f0ed-48ba-80b9-23ac643626cc": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/102845da-9b93-400d-b58a-fa3e7df1806e",
            "firstName": "Gary A.",
            "lastName": "Winfield",
            "fullName": "Gary Winfield",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/winfield/images/winfield-hi.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-CT-10-upper-ocd-person-102845da-9b93-400d-b58a-fa3e7df1806e",
              "builtID": "ct-upper-10",
              "externalID": "ocd-person/102845da-9b93-400d-b58a-fa3e7df1806e",
              "geometry": null
            },
            "contactInfo": {
              "email": "winfield@senatedems.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatedems.ct.gov/Winfield",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S10%27&dist_name=Winfield,%20Gary%20A.",
                "https://www.senatedems.ct.gov/Winfield.php",
                "https://www.senatedems.ct.gov/senator/gary-winfield"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-10-upper-ocd-person-102845da-9b93-400d-b58a-fa3e7df1806e": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/2d21e401-cb76-4001-b836-e57f667f550f",
            "firstName": "Martin M.",
            "lastName": "Looney",
            "fullName": "Martin Looney",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/looney/images/looney-hi.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-CT-11-upper-ocd-person-2d21e401-cb76-4001-b836-e57f667f550f",
              "builtID": "ct-upper-11",
              "externalID": "ocd-person/2d21e401-cb76-4001-b836-e57f667f550f",
              "geometry": null
            },
            "contactInfo": {
              "email": "looney@senatedems.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatedems.ct.gov/Looney",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S11%27&dist_name=Looney,%20Martin%20M.",
                "https://www.senatedems.ct.gov/Looney.php",
                "https://www.senatedems.ct.gov/senator/martin-m-looney"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-11-upper-ocd-person-2d21e401-cb76-4001-b836-e57f667f550f": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/5e2acad1-d976-4a7d-997b-52b5b400a654",
            "firstName": "Christine H.",
            "lastName": "Cohen",
            "fullName": "Christine Cohen",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/Cohen/images/Cohen-hi.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-CT-12-upper-ocd-person-5e2acad1-d976-4a7d-997b-52b5b400a654",
              "builtID": "ct-upper-12",
              "externalID": "ocd-person/5e2acad1-d976-4a7d-997b-52b5b400a654",
              "geometry": null
            },
            "contactInfo": {
              "email": "christine.cohen@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S12%27&dist_name=Cohen,%20Christine",
                "https://www.senatedems.ct.gov/cohen",
                "https://www.senatedems.ct.gov/senator/christine-cohen"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-12-upper-ocd-person-5e2acad1-d976-4a7d-997b-52b5b400a654": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/e823b348-fd0f-410b-b3c1-62fe7a2982f6",
            "firstName": "Jan",
            "lastName": "Hochadel",
            "fullName": "Jan Hochadel",
            "gender": "Female",
            "party": "democratic/working families",
            "image": null,
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-CT-13-upper-ocd-person-e823b348-fd0f-410b-b3c1-62fe7a2982f6",
              "builtID": "ct-upper-13",
              "externalID": "ocd-person/e823b348-fd0f-410b-b3c1-62fe7a2982f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "jan.hochadel@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatedems.ct.gov/hochadel",
                "https://www.senatedems.ct.gov/senator/jan-hochadel"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-13-upper-ocd-person-e823b348-fd0f-410b-b3c1-62fe7a2982f6": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/f37ce303-e106-4972-b453-4287b7915762",
            "firstName": "James J.",
            "lastName": "Maroney",
            "fullName": "James Maroney",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/Maroney/images/Maroney-hi.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-CT-14-upper-ocd-person-f37ce303-e106-4972-b453-4287b7915762",
              "builtID": "ct-upper-14",
              "externalID": "ocd-person/f37ce303-e106-4972-b453-4287b7915762",
              "geometry": null
            },
            "contactInfo": {
              "email": "maroney@senatedems.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S14%27&dist_name=Maroney,%20James%20J.",
                "https://www.senatedems.ct.gov/maroney",
                "https://www.senatedems.ct.gov/senator/james-maroney"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-14-upper-ocd-person-f37ce303-e106-4972-b453-4287b7915762": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/2c22d680-9e92-423b-a943-20571186c91a",
            "firstName": "Joan V.",
            "lastName": "Hartley",
            "fullName": "Joan Hartley",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/hartley/images/hartley-hi.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-CT-15-upper-ocd-person-2c22d680-9e92-423b-a943-20571186c91a",
              "builtID": "ct-upper-15",
              "externalID": "ocd-person/2c22d680-9e92-423b-a943-20571186c91a",
              "geometry": null
            },
            "contactInfo": {
              "email": "hartley@senatedems.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatedems.ct.gov/Hartley",
                "https://www.senatedems.ct.gov/Hartley.php",
                "https://www.senatedems.ct.gov/senator/joan-hartley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-15-upper-ocd-person-2c22d680-9e92-423b-a943-20571186c91a": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/0cdb540d-c360-4981-b7a9-2a67e2f91121",
            "firstName": "Robert Charles",
            "lastName": "Sampson",
            "fullName": "Rob Sampson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cga.ct.gov/legpics/S16.png?ver=",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-CT-16-upper-ocd-person-0cdb540d-c360-4981-b7a9-2a67e2f91121",
              "builtID": "ct-upper-16",
              "externalID": "ocd-person/0cdb540d-c360-4981-b7a9-2a67e2f91121",
              "geometry": null
            },
            "contactInfo": {
              "email": "rob.sampson@housegop.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ctsenaterepublicans.com/home-sampson",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S16%27&dist_name=Sampson,%20Rob",
                "https://ctsenaterepublicans.com/home-sampson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-16-upper-ocd-person-0cdb540d-c360-4981-b7a9-2a67e2f91121": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/bb280880-afa9-43f3-91bf-b05af25b023d",
            "firstName": "Jorge",
            "lastName": "Cabrera",
            "fullName": "Jorge Cabrera",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/cabrera/images/cabrera-hi.png",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-CT-17-upper-ocd-person-bb280880-afa9-43f3-91bf-b05af25b023d",
              "builtID": "ct-upper-17",
              "externalID": "ocd-person/bb280880-afa9-43f3-91bf-b05af25b023d",
              "geometry": null
            },
            "contactInfo": {
              "email": "jorge.cabrera@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S17%27&dist_name=Cabrera,%20Jorge",
                "https://www.senatedems.ct.gov/cabrera",
                "https://www.senatedems.ct.gov/senator/jorge-cabrera"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-17-upper-ocd-person-bb280880-afa9-43f3-91bf-b05af25b023d": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/a648c360-3b9e-408d-ba2a-c171edada365",
            "firstName": "Heather S.",
            "lastName": "Somers",
            "fullName": "Heather Somers",
            "gender": "Female",
            "party": "Republican",
            "image": "https://ctsenaterepublicans.com/wp-content/uploads/2020/12/HeatherHeadshot-e1608139012865.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-CT-18-upper-ocd-person-a648c360-3b9e-408d-ba2a-c171edada365",
              "builtID": "ct-upper-18",
              "externalID": "ocd-person/a648c360-3b9e-408d-ba2a-c171edada365",
              "geometry": null
            },
            "contactInfo": {
              "email": "heather.somers@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ctsenaterepublicans.com/home-Somers",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S18%27&dist_name=Somers,%20Heather%20S.",
                "https://ctsenaterepublicans.com/home-somers/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-18-upper-ocd-person-a648c360-3b9e-408d-ba2a-c171edada365": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/aec1fe1b-02ac-4802-be69-8714ec0b848e",
            "firstName": "Catherine Ann",
            "lastName": "Osten",
            "fullName": "Cathy Osten",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/osten/images/osten1x1.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-CT-19-upper-ocd-person-aec1fe1b-02ac-4802-be69-8714ec0b848e",
              "builtID": "ct-upper-19",
              "externalID": "ocd-person/aec1fe1b-02ac-4802-be69-8714ec0b848e",
              "geometry": null
            },
            "contactInfo": {
              "email": "osten@senatedems.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatedems.ct.gov/Osten",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S19%27&dist_name=Osten,%20Catherine%20A.",
                "https://www.senatedems.ct.gov/Osten.php",
                "https://www.senatedems.ct.gov/senator/cathy-osten"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-19-upper-ocd-person-aec1fe1b-02ac-4802-be69-8714ec0b848e": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/e171a437-11bc-4200-ae58-f6f8c4971f39",
            "firstName": "Martha",
            "lastName": "Marx",
            "fullName": "Martha Marx",
            "gender": "Female",
            "party": "democratic/working families",
            "image": null,
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-CT-20-upper-ocd-person-e171a437-11bc-4200-ae58-f6f8c4971f39",
              "builtID": "ct-upper-20",
              "externalID": "ocd-person/e171a437-11bc-4200-ae58-f6f8c4971f39",
              "geometry": null
            },
            "contactInfo": {
              "email": "martha.marx@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatedems.ct.gov/marx-contact",
                "https://www.senatedems.ct.gov/marx",
                "https://www.senatedems.ct.gov/senator/martha-marx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-20-upper-ocd-person-e171a437-11bc-4200-ae58-f6f8c4971f39": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/5acf728f-8ec5-4db5-b7eb-8dec668d0626",
            "firstName": "Kevin C.",
            "lastName": "Kelly",
            "fullName": "Kevin Kelly",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ctsenaterepublicans.com/wp-content/uploads/2022/02/KellyHeadshotSmall.png",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-CT-21-upper-ocd-person-5acf728f-8ec5-4db5-b7eb-8dec668d0626",
              "builtID": "ct-upper-21",
              "externalID": "ocd-person/5acf728f-8ec5-4db5-b7eb-8dec668d0626",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.kelly@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ctsenaterepublicans.com/home-kelly",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S21%27&dist_name=Kelly,%20Kevin%20C.",
                "https://ctsenaterepublicans.com/home-kelly/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-21-upper-ocd-person-5acf728f-8ec5-4db5-b7eb-8dec668d0626": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/41d83b98-d5ab-48db-b847-3a3e08194b4c",
            "firstName": "Marilyn V.",
            "lastName": "Moore",
            "fullName": "Marilyn Moore",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/moore/images/moore-hi.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-CT-22-upper-ocd-person-41d83b98-d5ab-48db-b847-3a3e08194b4c",
              "builtID": "ct-upper-22",
              "externalID": "ocd-person/41d83b98-d5ab-48db-b847-3a3e08194b4c",
              "geometry": null
            },
            "contactInfo": {
              "email": "marilynvmoore@aol.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatedems.ct.gov/Moore",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S22%27&dist_name=Moore,%20Marilyn%20V.",
                "https://www.senatedems.ct.gov/Moore.php",
                "https://www.senatedems.ct.gov/senator/marilyn-moore"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-22-upper-ocd-person-41d83b98-d5ab-48db-b847-3a3e08194b4c": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/3be9e2dc-7ffa-40fb-90c8-d25fe4970dee",
            "firstName": "Herron Keyon",
            "lastName": "Gaston",
            "fullName": "Herron Gaston",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-CT-23-upper-ocd-person-3be9e2dc-7ffa-40fb-90c8-d25fe4970dee",
              "builtID": "ct-upper-23",
              "externalID": "ocd-person/3be9e2dc-7ffa-40fb-90c8-d25fe4970dee",
              "geometry": null
            },
            "contactInfo": {
              "email": "herron.gaston@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senatedems.ct.gov/gaston",
                "https://www.senatedems.ct.gov/gaston",
                "https://www.senatedems.ct.gov/gaston-230104"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-23-upper-ocd-person-3be9e2dc-7ffa-40fb-90c8-d25fe4970dee": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/34ca88f1-c2bc-430e-9f7c-93e8f3563e33",
            "firstName": "Julie L.",
            "lastName": "Kushner",
            "fullName": "Julie Kushner",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/Kushner/images/Kushner-hi.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-CT-24-upper-ocd-person-34ca88f1-c2bc-430e-9f7c-93e8f3563e33",
              "builtID": "ct-upper-24",
              "externalID": "ocd-person/34ca88f1-c2bc-430e-9f7c-93e8f3563e33",
              "geometry": null
            },
            "contactInfo": {
              "email": "julie.kushner@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S24%27&dist_name=Kushner,%20Julie",
                "https://www.senatedems.ct.gov/kushner",
                "https://www.senatedems.ct.gov/senator/julie-kushner"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-24-upper-ocd-person-34ca88f1-c2bc-430e-9f7c-93e8f3563e33": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/7836916c-08a2-430a-99eb-580f7cf50e81",
            "firstName": "Robert Bruce",
            "lastName": "Duff",
            "fullName": "Bob Duff",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/duff/images/duff-headshot.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-CT-25-upper-ocd-person-7836916c-08a2-430a-99eb-580f7cf50e81",
              "builtID": "ct-upper-25",
              "externalID": "ocd-person/7836916c-08a2-430a-99eb-580f7cf50e81",
              "geometry": null
            },
            "contactInfo": {
              "email": "duff@senatedems.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatedems.ct.gov/Duff",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S25%27&dist_name=Duff,%20Bob",
                "https://www.senatedems.ct.gov/Duff.php",
                "https://www.senatedems.ct.gov/senator/bob-duff"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-25-upper-ocd-person-7836916c-08a2-430a-99eb-580f7cf50e81": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/5310d199-fa92-40aa-8d0b-efad6d3027b2",
            "firstName": "Ceci",
            "lastName": "Maher",
            "fullName": "Ceci Maher",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-CT-26-upper-ocd-person-5310d199-fa92-40aa-8d0b-efad6d3027b2",
              "builtID": "ct-upper-26",
              "externalID": "ocd-person/5310d199-fa92-40aa-8d0b-efad6d3027b2",
              "geometry": null
            },
            "contactInfo": {
              "email": "ceci.maher@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatedems.ct.gov/maher",
                "https://www.senatedems.ct.gov/senator/ceci-maher"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-26-upper-ocd-person-5310d199-fa92-40aa-8d0b-efad6d3027b2": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/fcf63a22-3979-4cbf-a719-0d414d13e1ac",
            "firstName": "Patricia Billie",
            "lastName": "Miller",
            "fullName": "Pat Miller",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/miller/images/miller-hi.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-CT-27-upper-ocd-person-fcf63a22-3979-4cbf-a719-0d414d13e1ac",
              "builtID": "ct-upper-27",
              "externalID": "ocd-person/fcf63a22-3979-4cbf-a719-0d414d13e1ac",
              "geometry": null
            },
            "contactInfo": {
              "email": "patricia.miller@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatedems.ct.gov/miller",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S27%27&dist_name=Miller,%20Patricia%20Billie",
                "https://www.senatedems.ct.gov/Miller",
                "https://www.senatedems.ct.gov/senator/patricia-billie-miller"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-27-upper-ocd-person-fcf63a22-3979-4cbf-a719-0d414d13e1ac": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/b1ab473b-e7ff-4c53-96af-ee796c89f4dd",
            "firstName": "Anthony T.",
            "lastName": "Hwang",
            "fullName": "Tony Hwang",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ctsenaterepublicans.com/wp-content/uploads/2021/08/Hwang_Headshot2019.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-CT-28-upper-ocd-person-b1ab473b-e7ff-4c53-96af-ee796c89f4dd",
              "builtID": "ct-upper-28",
              "externalID": "ocd-person/b1ab473b-e7ff-4c53-96af-ee796c89f4dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "tony.hwang@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ctsenaterepublicans.com/home-Hwang",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S28%27&dist_name=Hwang,%20Tony",
                "https://ctsenaterepublicans.com/home-Hwang/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-28-upper-ocd-person-b1ab473b-e7ff-4c53-96af-ee796c89f4dd": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/77713b68-e8ef-455e-80a6-1614488fb71a",
            "firstName": "Mae M.",
            "lastName": "Flexer",
            "fullName": "Mae Flexer",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/flexer/images/flexer-hi.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-CT-29-upper-ocd-person-77713b68-e8ef-455e-80a6-1614488fb71a",
              "builtID": "ct-upper-29",
              "externalID": "ocd-person/77713b68-e8ef-455e-80a6-1614488fb71a",
              "geometry": null
            },
            "contactInfo": {
              "email": "flexer@senatedems.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatedems.ct.gov/Flexer",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S29%27&dist_name=Flexer,%20Mae",
                "https://www.senatedems.ct.gov/Flexer.php",
                "https://www.senatedems.ct.gov/senator/mae-flexer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-29-upper-ocd-person-77713b68-e8ef-455e-80a6-1614488fb71a": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/f0f89d15-9682-4a2e-8096-57700ac5b8dc",
            "firstName": "Stephen G.",
            "lastName": "Harding",
            "fullName": "Stephen Harding",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.cthousegop.com/harding/wp-content/uploads/sites/35/2016/11/Harding_Bio_Photo_Web.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-CT-30-upper-ocd-person-f0f89d15-9682-4a2e-8096-57700ac5b8dc",
              "builtID": "ct-upper-30",
              "externalID": "ocd-person/f0f89d15-9682-4a2e-8096-57700ac5b8dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephen.harding@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.cthousegop.com/Harding/",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27107%27&dist_name=Harding,%20Stephen%20G.",
                "https://www.ctsenaterepublicans.com/home-Harding",
                "https://ctsenaterepublicans.com/home-harding/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-30-upper-ocd-person-f0f89d15-9682-4a2e-8096-57700ac5b8dc": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/ca8be710-988a-4415-bc37-6d3a92d1afcd",
            "firstName": "Henri",
            "lastName": "Martin",
            "fullName": "Henri Martin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cga.ct.gov/legpics/S31.png?ver=",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-CT-31-upper-ocd-person-ca8be710-988a-4415-bc37-6d3a92d1afcd",
              "builtID": "ct-upper-31",
              "externalID": "ocd-person/ca8be710-988a-4415-bc37-6d3a92d1afcd",
              "geometry": null
            },
            "contactInfo": {
              "email": "henri.martin@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ctsenaterepublicans.com/home-Martin",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S31%27&dist_name=Martin,%20Henri",
                "https://ctsenaterepublicans.com/home-Martin/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-31-upper-ocd-person-ca8be710-988a-4415-bc37-6d3a92d1afcd": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/58a9b2fa-cd93-420c-a9bc-6326a75a218a",
            "firstName": "Eric C.",
            "lastName": "Berthel",
            "fullName": "Eric Berthel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ctsenaterepublicans.com/wp-content/uploads/2019/01/Berthel2019forWeb.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-CT-32-upper-ocd-person-58a9b2fa-cd93-420c-a9bc-6326a75a218a",
              "builtID": "ct-upper-32",
              "externalID": "ocd-person/58a9b2fa-cd93-420c-a9bc-6326a75a218a",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.berthel@cga.ct.gov.",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ctsenaterepublicans.com/home-Berthel",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S32%27&dist_name=Berthel,%20Eric%20C.",
                "https://ctsenaterepublicans.com/home-berthel/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-32-upper-ocd-person-58a9b2fa-cd93-420c-a9bc-6326a75a218a": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/109295ad-521f-463a-b0af-66c9f0598a97",
            "firstName": "Norman",
            "lastName": "Needleman",
            "fullName": "Norm Needleman",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.senatedems.ct.gov/templates/Needleman/images/Needleman-hi.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-CT-33-upper-ocd-person-109295ad-521f-463a-b0af-66c9f0598a97",
              "builtID": "ct-upper-33",
              "externalID": "ocd-person/109295ad-521f-463a-b0af-66c9f0598a97",
              "geometry": null
            },
            "contactInfo": {
              "email": "norm.needleman@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S33%27&dist_name=Needleman,%20Norman",
                "https://www.senatedems.ct.gov/needleman",
                "https://www.senatedems.ct.gov/senator/norm-needleman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-33-upper-ocd-person-109295ad-521f-463a-b0af-66c9f0598a97": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/ca523a61-6db3-47c6-bf1f-8fd7638e999f",
            "firstName": "Paul",
            "lastName": "Cicarella",
            "fullName": "Paul Cicarella",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ctsenaterepublicans.com/wp-content/uploads/2020/11/Paul-Cicarella.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-CT-34-upper-ocd-person-ca523a61-6db3-47c6-bf1f-8fd7638e999f",
              "builtID": "ct-upper-34",
              "externalID": "ocd-person/ca523a61-6db3-47c6-bf1f-8fd7638e999f",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.cicarella@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ctsenaterepublicans.com/home-cicarella",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S34%27&dist_name=Cicarella,%20Paul",
                "https://ctsenaterepublicans.com/home-cicarella/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-34-upper-ocd-person-ca523a61-6db3-47c6-bf1f-8fd7638e999f": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/1a60b520-6887-4830-b33e-78520b7b53aa",
            "firstName": "Jeffrey",
            "lastName": "Gordon",
            "fullName": "Jeff Gordon",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-CT-35-upper-ocd-person-1a60b520-6887-4830-b33e-78520b7b53aa",
              "builtID": "ct-upper-35",
              "externalID": "ocd-person/1a60b520-6887-4830-b33e-78520b7b53aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeffrey.gordon@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ctsenaterepublicans.com/home-gordon",
                "https://ctsenaterepublicans.com/home-gordon/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-35-upper-ocd-person-1a60b520-6887-4830-b33e-78520b7b53aa": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/c5800b0c-c84a-4e63-83fa-8aaad9b6535f",
            "firstName": "Ryan",
            "lastName": "Fazio",
            "fullName": "Ryan Fazio",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ctsenaterepublicans.com/wp-content/uploads/2021/08/225217195_346200893664334_4626743322505851539_n-scaled-e1629858004858.jpg",
            "title": "CT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "CT",
              "stateFull": "Connecticut",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-CT-36-upper-ocd-person-c5800b0c-c84a-4e63-83fa-8aaad9b6535f",
              "builtID": "ct-upper-36",
              "externalID": "ocd-person/c5800b0c-c84a-4e63-83fa-8aaad9b6535f",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryan.fazio@cga.ct.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ctsenaterepublicans.com/home-fazio",
                "https://cga.ct.gov/asp/CGABillStatus/CGAMemberBills.asp?dist_code=%27S36%27&dist_name=Fazio,%20Ryan",
                "https://ctsenaterepublicans.com/home-fazio/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-CT-36-upper-ocd-person-c5800b0c-c84a-4e63-83fa-8aaad9b6535f": 0
        }
      }
    }
  },
  "DC": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "DC",
          "stateFull": "District of Columbia",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "DC",
            "stateFull": "District of Columbia",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-DC---",
            "builtID": "dc--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-DC---": 0 }
    },
    "SENATE": {},
    "HOUSE": {
      "00": {
        "members": [
          {
            "API_ID": "N000147",
            "in_office": true,
            "firstName": "Eleanor",
            "lastName": "Norton",
            "middleName": "Holmes",
            "fullName": "Eleanor Holmes Norton",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/N000147.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "DC",
              "stateFull": "District of Columbia",
              "district": "00",
              "chamber": "",
              "hashID": "HOUSE-DC-00--N000147",
              "builtID": "dc--00",
              "externalID": "N000147",
              "geometry": null,
              "geoid": "1198"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "EleanorNorton",
              "youtube": "EleanorHNorton",
              "instagram": null,
              "facebook": "CongresswomanNorton",
              "urls": ["https://norton.house.gov"],
              "rss_url": "https://norton.house.gov/rss.xml"
            },
            "title": "DC House Member",
            "short_title": "Del.",
            "api_uri": "https://api.propublica.org/congress/v1/members/N000147.json",
            "govtrack_id": "400295",
            "cspan_id": "882",
            "votesmart_id": "775",
            "icpsr_id": null,
            "crp_id": "N00001692",
            "google_entity_id": "/m/01s_vp",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1937-06-13",
            "leadership_role": null,
            "fec_candidate_id": null,
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "34",
            "next_election": "2024",
            "total_votes": 498,
            "missed_votes": 0,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:15 -0400",
            "missed_votes_pct": 0,
            "votes_with_party_pct": 98.19,
            "votes_against_party_pct": 1.81,
            "ocd_id": "ocd-division/country:us/state:dc/cd:79",
            "at_large": true
          }
        ],
        "hash": { "HOUSE-DC-00--N000147": 0 }
      }
    },
    "SLDL": {},
    "SLDU": {
      "At Large": {
        "members": [
          {
            "API_ID": "ocd-person/860fa95b-c737-43bd-948d-1dd6dc1b734e",
            "firstName": "Anita D.",
            "lastName": "Bonds",
            "fullName": "Anita Bonds",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://dccouncil.gov/wp-content/uploads/2017/03/Anita-Bonds-Official-Portrait-Shot-002-75x75-c-default.jpg",
            "title": "DC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DC",
              "stateFull": "District of Columbia",
              "district": "At Large",
              "chamber": "upper",
              "hashID": "SLDU-DC-At Large-upper-ocd-person-860fa95b-c737-43bd-948d-1dd6dc1b734e",
              "builtID": "dc-upper-At-Large",
              "externalID": "ocd-person/860fa95b-c737-43bd-948d-1dd6dc1b734e",
              "geometry": null
            },
            "contactInfo": {
              "email": "abonds@dccouncil.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1350 Pennsylvania Ave. NW Suite 404, Washington, DC 20004",
              "phone1": null,
              "phone2": "202-724-8064",
              "fax1": null,
              "fax2": "202-724-8086",
              "twitter": "AnitaBondsDC",
              "youtube": null,
              "instagram": null,
              "facebook": "anitabondsdc",
              "urls": [
                "https://dccouncil.us/council/anita-bonds/",
                "https://dccouncil.us/council/anita-bonds/",
                "https://dccouncil.gov/council/anita-bonds/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/43dcb6c4-0eb1-48c7-965b-4979eb747b5b",
            "firstName": "Christina",
            "lastName": "Henderson",
            "fullName": "Christina Henderson",
            "gender": "Female",
            "party": "independent",
            "image": "https://dccouncil.gov/wp-content/uploads/2021/01/Headshot-Official-scaled-75x75-c-default.jpg",
            "title": "DC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DC",
              "stateFull": "District of Columbia",
              "district": "At Large",
              "chamber": "upper",
              "hashID": "SLDU-DC-At Large-upper-ocd-person-43dcb6c4-0eb1-48c7-965b-4979eb747b5b",
              "builtID": "dc-upper-At-Large",
              "externalID": "ocd-person/43dcb6c4-0eb1-48c7-965b-4979eb747b5b",
              "geometry": null
            },
            "contactInfo": {
              "email": "chenderson@dccouncil.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1350 Pennsylvania Ave. NW, Suite 408, Washington, DC 20004",
              "phone1": null,
              "phone2": "202-724-8105",
              "fax1": null,
              "fax2": "202-724-8071",
              "twitter": "CMCHenderson",
              "youtube": null,
              "instagram": null,
              "facebook": "christinahendersondc",
              "urls": [
                "https://dccouncil.us/council/At-Large-councilmember-christina-henderson/",
                "https://dccouncil.us/council/At-Large-councilmember-christina-henderson/",
                "https://dccouncil.gov/council/At-Large-councilmember-christina-henderson/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/cc7796a1-6644-4bc1-a536-b9307e84b22f",
            "firstName": "Kenyan R.",
            "lastName": "McDuffie",
            "fullName": "Kenyan McDuffie",
            "gender": "Male",
            "party": "independent",
            "image": "https://dccouncil.gov/wp-content/uploads/2017/03/At-Large-KRM-Headshot-scaled-75x75-c-default.jpg",
            "title": "DC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DC",
              "stateFull": "District of Columbia",
              "district": "At Large",
              "chamber": "upper",
              "hashID": "SLDU-DC-At Large-upper-ocd-person-cc7796a1-6644-4bc1-a536-b9307e84b22f",
              "builtID": "dc-upper-At-Large",
              "externalID": "ocd-person/cc7796a1-6644-4bc1-a536-b9307e84b22f",
              "geometry": null
            },
            "contactInfo": {
              "email": "kmcduffie@dccouncil.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1350 Pennsylvania Ave. NW Suite 506, Washington, DC 20004",
              "phone1": null,
              "phone2": "202-724-8028",
              "fax1": null,
              "fax2": "202-724-8076",
              "twitter": "CM_McDuffie",
              "youtube": null,
              "instagram": null,
              "facebook": "KenyanRMcDuffie",
              "urls": [
                "https://dccouncil.us/council/kenyan-mcduffie/",
                "https://dccouncil.us/council/kenyan-mcduffie/",
                "https://dccouncil.gov/council/kenyan-mcduffie/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/07b9c384-a232-40d5-b445-61db9e4bb65b",
            "firstName": "Robert C.",
            "lastName": "White",
            "fullName": "Robert White",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://dccouncil.gov/wp-content/uploads/2017/06/CM-Robert-White-Headshot_Photo-credit-Sebastian-Vizcarra-scaled-75x75-c-default.jpg",
            "title": "DC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DC",
              "stateFull": "District of Columbia",
              "district": "At Large",
              "chamber": "upper",
              "hashID": "SLDU-DC-At Large-upper-ocd-person-07b9c384-a232-40d5-b445-61db9e4bb65b",
              "builtID": "dc-upper-At-Large",
              "externalID": "ocd-person/07b9c384-a232-40d5-b445-61db9e4bb65b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rwhite@dccouncil.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1350 Pennsylvania Ave. NW Suite 107, Washington, DC 20004",
              "phone1": null,
              "phone2": "202-724-8174",
              "fax1": null,
              "fax2": "202-727-8210",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://dccouncil.us/council/councilmember-robert-c-white-jr/",
                "https://www.robertwhiteatlarge.com/",
                "https://dccouncil.gov/council/councilmember-robert-c-white-jr/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DC-At Large-upper-ocd-person-860fa95b-c737-43bd-948d-1dd6dc1b734e": 0,
          "SLDU-DC-At Large-upper-ocd-person-43dcb6c4-0eb1-48c7-965b-4979eb747b5b": 1,
          "SLDU-DC-At Large-upper-ocd-person-cc7796a1-6644-4bc1-a536-b9307e84b22f": 2,
          "SLDU-DC-At Large-upper-ocd-person-07b9c384-a232-40d5-b445-61db9e4bb65b": 3
        }
      },
      "Ward 1": {
        "members": [
          {
            "API_ID": "ocd-person/4e806a33-bba3-4484-95f8-d723769b99f1",
            "firstName": "Brianne K.",
            "lastName": "Nadeau",
            "fullName": "Brianne Nadeau",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://dccouncil.gov/wp-content/uploads/2017/03/Copy-of-IMG_0900_Fotor-scaled-75x75-c-default.jpg",
            "title": "DC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DC",
              "stateFull": "District of Columbia",
              "district": "Ward 1",
              "chamber": "upper",
              "hashID": "SLDU-DC-Ward 1-upper-ocd-person-4e806a33-bba3-4484-95f8-d723769b99f1",
              "builtID": "dc-upper-ward 1",
              "externalID": "ocd-person/4e806a33-bba3-4484-95f8-d723769b99f1",
              "geometry": null
            },
            "contactInfo": {
              "email": "bnadeau@dccouncil.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1350 Pennsylvania Ave. NW Suite 102, Washington, DC 20004",
              "phone1": null,
              "phone2": "202-724-8181",
              "fax1": null,
              "fax2": "202-724-8109",
              "twitter": "BrianneKNadeau",
              "youtube": null,
              "instagram": null,
              "facebook": "brianneknadeau.",
              "urls": [
                "https://dccouncil.us/council/brianne-nadeau/",
                "https://dccouncil.us/council/brianne-nadeau/",
                "https://www.brianneknadeau.com/",
                "https://brianneknadeau.com/",
                "https://dccouncil.gov/council/brianne-nadeau/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DC-Ward 1-upper-ocd-person-4e806a33-bba3-4484-95f8-d723769b99f1": 0
        }
      },
      "Ward 2": {
        "members": [
          {
            "API_ID": "ocd-person/bf329860-076e-4d14-bed2-969d0d13dfec",
            "firstName": "Brooke",
            "lastName": "Pinto",
            "fullName": "Brooke Pinto",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://dccouncil.gov/wp-content/uploads/2020/06/cmbphs-scaled-75x75-c-default.jpg",
            "title": "DC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DC",
              "stateFull": "District of Columbia",
              "district": "Ward 2",
              "chamber": "upper",
              "hashID": "SLDU-DC-Ward 2-upper-ocd-person-bf329860-076e-4d14-bed2-969d0d13dfec",
              "builtID": "dc-upper-ward 2",
              "externalID": "ocd-person/bf329860-076e-4d14-bed2-969d0d13dfec",
              "geometry": null
            },
            "contactInfo": {
              "email": "bpinto@dccouncil.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1350 Pennsylvania Ave. NW Suite 106, Washington, DC 20004",
              "phone1": null,
              "phone2": "202-724-8058",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://dccouncil.us/council/ward-2-councilmember-brooke-pinto/",
                "https://dccouncil.gov/council/ward-2-councilmember-brooke-pinto/",
                "https://www.brookepintodc.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DC-Ward 2-upper-ocd-person-bf329860-076e-4d14-bed2-969d0d13dfec": 0
        }
      },
      "Ward 6": {
        "members": [
          {
            "API_ID": "ocd-person/ab8e39c8-583e-4e9d-b481-0f43c21318ba",
            "firstName": "Charles",
            "lastName": "Allen",
            "fullName": "Charles Allen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://dccouncil.gov/wp-content/uploads/2017/06/C._Allen_small_1-75x75-c-default.jpg",
            "title": "DC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DC",
              "stateFull": "District of Columbia",
              "district": "Ward 6",
              "chamber": "upper",
              "hashID": "SLDU-DC-Ward 6-upper-ocd-person-ab8e39c8-583e-4e9d-b481-0f43c21318ba",
              "builtID": "dc-upper-ward 6",
              "externalID": "ocd-person/ab8e39c8-583e-4e9d-b481-0f43c21318ba",
              "geometry": null
            },
            "contactInfo": {
              "email": "callen@dccouncil.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1350 Pennsylvania Ave. NW Suite 110, Washington, DC 20004",
              "phone1": null,
              "phone2": "202-724-8072",
              "fax1": null,
              "fax2": "202-724-8054",
              "twitter": "CMCharlesAllen",
              "youtube": null,
              "instagram": null,
              "facebook": "CMcharlesallen",
              "urls": [
                "https://dccouncil.us/council/councilmember-allen/",
                "https://dccouncil.gov/council/councilmember-allen/",
                "https://www.charlesallenward6.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DC-Ward 6-upper-ocd-person-ab8e39c8-583e-4e9d-b481-0f43c21318ba": 0
        }
      },
      "Ward 4": {
        "members": [
          {
            "API_ID": "ocd-person/4fc6d65b-d6d2-46d7-bdea-48b5458576ab",
            "firstName": "Janeese",
            "lastName": "Lewis George",
            "fullName": "Janeese Lewis George",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://dccouncil.gov/wp-content/uploads/2021/01/0EB064A9-D1C7-4387-945B-4DDD13AF8415-75x75-c-default.jpeg",
            "title": "DC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DC",
              "stateFull": "District of Columbia",
              "district": "Ward 4",
              "chamber": "upper",
              "hashID": "SLDU-DC-Ward 4-upper-ocd-person-4fc6d65b-d6d2-46d7-bdea-48b5458576ab",
              "builtID": "dc-upper-ward 4",
              "externalID": "ocd-person/4fc6d65b-d6d2-46d7-bdea-48b5458576ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "jlewisgeorge@dccouncil.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 105, 1350 Pennsylvania Ave. NW, Washington, DC 20004",
              "phone1": null,
              "phone2": "202-724-8052",
              "fax1": null,
              "fax2": "202-741-0908",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://dccouncil.us/council/ward-4-councilmember-janeese-lewis-george/",
                "https://janeeseward4.com/",
                "https://dccouncil.gov/council/ward-4-councilmember-janeese-lewis-george/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DC-Ward 4-upper-ocd-person-4fc6d65b-d6d2-46d7-bdea-48b5458576ab": 0
        }
      },
      "Ward 3": {
        "members": [
          {
            "API_ID": "ocd-person/3451eb73-5042-4269-91b5-0287e527e72e",
            "firstName": "Matthew",
            "lastName": "Frumin",
            "fullName": "Matt Frumin",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://dccouncil.gov/wp-content/uploads/2023/01/Headshot1-scaled-75x75-c-default.jpg",
            "title": "DC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DC",
              "stateFull": "District of Columbia",
              "district": "Ward 3",
              "chamber": "upper",
              "hashID": "SLDU-DC-Ward 3-upper-ocd-person-3451eb73-5042-4269-91b5-0287e527e72e",
              "builtID": "dc-upper-ward 3",
              "externalID": "ocd-person/3451eb73-5042-4269-91b5-0287e527e72e",
              "geometry": null
            },
            "contactInfo": {
              "email": "mfrumin@dccouncil.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1350 Pennsylvania Ave. NW Suite 408, Washington, DC 20004",
              "phone1": null,
              "phone2": "202-724-8062",
              "fax1": null,
              "fax2": "202-724-8118",
              "twitter": "CMFrumin",
              "youtube": null,
              "instagram": null,
              "facebook": "cmfrumin",
              "urls": [
                "https://dccouncil.gov/council/ward-3-councilmember-matthew-frumin/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DC-Ward 3-upper-ocd-person-3451eb73-5042-4269-91b5-0287e527e72e": 0
        }
      },
      "Chairman": {
        "members": [
          {
            "API_ID": "ocd-person/e3b9567d-67ff-4cc1-89eb-1ed1af646393",
            "firstName": "Philip Heath",
            "lastName": "Mendelson",
            "fullName": "Phil Mendelson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://dccouncil.gov/wp-content/uploads/2017/03/Photo-107-002-110x110-c-default.jpg",
            "title": "DC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DC",
              "stateFull": "District of Columbia",
              "district": "Chairman",
              "chamber": "upper",
              "hashID": "SLDU-DC-Chairman-upper-ocd-person-e3b9567d-67ff-4cc1-89eb-1ed1af646393",
              "builtID": "dc-upper-At-Large",
              "externalID": "ocd-person/e3b9567d-67ff-4cc1-89eb-1ed1af646393",
              "geometry": null
            },
            "contactInfo": {
              "email": "pmendelson@dccouncil.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1350 Pennsylvania Ave. NW Suite 504, Washington, DC 20004",
              "phone1": null,
              "phone2": "202-724-8032",
              "fax1": null,
              "fax2": "202-724-8085",
              "twitter": "ChmnMendelson",
              "youtube": null,
              "instagram": null,
              "facebook": "ChairmanPhilMendelson",
              "urls": [
                "https://dccouncil.us/council/phil-mendelson/",
                "https://dccouncil.us/council/phil-mendelson/",
                "https://dccouncil.us/council/phil-mendelson/",
                "https://dccouncil.gov/council/phil-mendelson/",
                "https://chairmanmendelson.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DC-Chairman-upper-ocd-person-e3b9567d-67ff-4cc1-89eb-1ed1af646393": 0
        }
      },
      "Ward 8": {
        "members": [
          {
            "API_ID": "ocd-person/8c48b12b-59a2-41bb-96c5-fc3b367637aa",
            "firstName": "Trayon",
            "lastName": "White",
            "fullName": "Trayon White",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://dccouncil.gov/wp-content/uploads/2017/06/tw-75x75-c-default.jpg",
            "title": "DC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DC",
              "stateFull": "District of Columbia",
              "district": "Ward 8",
              "chamber": "upper",
              "hashID": "SLDU-DC-Ward 8-upper-ocd-person-8c48b12b-59a2-41bb-96c5-fc3b367637aa",
              "builtID": "dc-upper-ward 8",
              "externalID": "ocd-person/8c48b12b-59a2-41bb-96c5-fc3b367637aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "twhite@dccouncil.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1350 Pennsylvania Ave. NW Suite 400, Washington, DC 20004",
              "phone1": null,
              "phone2": "202-724-8045",
              "fax1": null,
              "fax2": "202-724-8055",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://dccouncil.us/council/councilmember-trayon-white-sr/",
                "https://dccouncil.us/council/councilmember-trayon-white-sr/",
                "https://dccouncil.us/council/councilmember-trayon-white-sr/",
                "https://dccouncil.gov/council/councilmember-trayon-white-sr/",
                "https://www.trayonwhite8.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DC-Ward 8-upper-ocd-person-8c48b12b-59a2-41bb-96c5-fc3b367637aa": 0
        }
      },
      "Ward 7": {
        "members": [
          {
            "API_ID": "ocd-person/23ff0b28-93f2-45a2-8afc-ce7f8e5cdf19",
            "firstName": "Vincent Condol",
            "lastName": "Gray",
            "fullName": "Vince Gray",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://dccouncil.gov/wp-content/uploads/2017/03/Headshot_gray_small-75x75-c-default.jpg",
            "title": "DC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DC",
              "stateFull": "District of Columbia",
              "district": "Ward 7",
              "chamber": "upper",
              "hashID": "SLDU-DC-Ward 7-upper-ocd-person-23ff0b28-93f2-45a2-8afc-ce7f8e5cdf19",
              "builtID": "dc-upper-ward 7",
              "externalID": "ocd-person/23ff0b28-93f2-45a2-8afc-ce7f8e5cdf19",
              "geometry": null
            },
            "contactInfo": {
              "email": "vgray@dccouncil.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1350 Pennsylvania Ave. NW Suite 406, Washington, DC 20004",
              "phone1": null,
              "phone2": "202-724-8068",
              "fax1": null,
              "fax2": "202-741-0911",
              "twitter": "VinceGrayWard7",
              "youtube": null,
              "instagram": null,
              "facebook": "VinceGrayWard7",
              "urls": [
                "https://dccouncil.us/council/vincent-gray/",
                "https://dccouncil.us/council/vincent-gray/",
                "https://dccouncil.us/council/vincent-gray/",
                "https://dccouncil.gov/council/vincent-gray/",
                "https://www.vincentcgray.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DC-Ward 7-upper-ocd-person-23ff0b28-93f2-45a2-8afc-ce7f8e5cdf19": 0
        }
      },
      "Ward 5": {
        "members": [
          {
            "API_ID": "ocd-person/30cb555a-f684-418e-8b63-8101e7a34552",
            "firstName": "Zachary",
            "lastName": "Parker",
            "fullName": "Zachary Parker",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://dccouncil.gov/wp-content/uploads/2023/01/Zachary-Parker-Headshot-75x75-c-default.jpg",
            "title": "DC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DC",
              "stateFull": "District of Columbia",
              "district": "Ward 5",
              "chamber": "upper",
              "hashID": "SLDU-DC-Ward 5-upper-ocd-person-30cb555a-f684-418e-8b63-8101e7a34552",
              "builtID": "dc-upper-ward 5",
              "externalID": "ocd-person/30cb555a-f684-418e-8b63-8101e7a34552",
              "geometry": null
            },
            "contactInfo": {
              "email": "zparker@dccouncil.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1350 Pennsylvania Ave. NW Suite 102, Washington, DC 20004",
              "phone1": null,
              "phone2": "202-724-8028",
              "fax1": null,
              "fax2": "202-724-8076",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://dccouncil.gov/council/ward-5-councilmember-zachary-parker/",
                "https://zacharyparkerward5.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DC-Ward 5-upper-ocd-person-30cb555a-f684-418e-8b63-8101e7a34552": 0
        }
      }
    }
  },
  "DE": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "DE",
          "stateFull": "Delaware",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "DE",
            "stateFull": "Delaware",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-DE---",
            "builtID": "de--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-DE---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "C000174",
          "in_office": true,
          "firstName": "Thomas",
          "lastName": "Carper",
          "middleName": "R.",
          "fullName": "Thomas R. Carper",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/C000174.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "DE",
            "stateFull": "Delaware",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-DE---C000174",
            "builtID": "de--",
            "externalID": "C000174",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.carper.senate.gov/contact/",
            "address1": "513 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-2441",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorCarper",
            "youtube": "senatorcarper",
            "instagram": null,
            "facebook": "tomcarper",
            "urls": ["https://www.carper.senate.gov"],
            "rss_url": "https://www.carper.senate.gov/public/?a=RSS.Feed"
          },
          "title": "DE Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/C000174.json",
          "govtrack_id": "300019",
          "cspan_id": "663",
          "votesmart_id": "22421",
          "icpsr_id": "15015",
          "crp_id": "N00012508",
          "google_entity_id": "/m/01xw7t",
          "state_rank": "senior",
          "lis_id": "S277",
          "suffix": null,
          "date_of_birth": "1947-01-23",
          "leadership_role": null,
          "fec_candidate_id": "S8DE00079",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "23",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 3,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 0.54,
          "votes_with_party_pct": 99.45,
          "votes_against_party_pct": 0.55,
          "ocd_id": "ocd-division/country:us/state:de"
        },
        {
          "API_ID": "C001088",
          "in_office": true,
          "firstName": "Christopher",
          "lastName": "Coons",
          "middleName": "A.",
          "fullName": "Christopher A. Coons",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/C001088.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "DE",
            "stateFull": "Delaware",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-DE---C001088",
            "builtID": "de--",
            "externalID": "C001088",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.coons.senate.gov/contact",
            "address1": "218 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-5042",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "ChrisCoons",
            "youtube": "senatorchriscoons",
            "instagram": null,
            "facebook": "senatorchriscoons",
            "urls": ["https://www.coons.senate.gov"],
            "rss_url": null
          },
          "title": "DE Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/C001088.json",
          "govtrack_id": "412390",
          "cspan_id": "9269028",
          "votesmart_id": "122834",
          "icpsr_id": "40916",
          "crp_id": "N00031820",
          "google_entity_id": "/m/082d3d",
          "state_rank": "junior",
          "lis_id": "S337",
          "suffix": null,
          "date_of_birth": "1963-09-09",
          "leadership_role": null,
          "fec_candidate_id": "S0DE00092",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "15",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 22,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 3.97,
          "votes_with_party_pct": 99.06,
          "votes_against_party_pct": 0.94,
          "ocd_id": "ocd-division/country:us/state:de"
        }
      ],
      "hash": { "SENATE-DE---C000174": 0, "SENATE-DE---C001088": 1 }
    },
    "HOUSE": {
      "00": {
        "members": [
          {
            "API_ID": "B001303",
            "in_office": true,
            "firstName": "Lisa",
            "lastName": "Blunt Rochester",
            "middleName": null,
            "fullName": "Lisa Blunt Rochester",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/B001303.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "00",
              "chamber": "",
              "hashID": "HOUSE-DE-00--B001303",
              "builtID": "de--00",
              "externalID": "B001303",
              "geometry": null,
              "geoid": "1000"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1724 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4165",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLBR",
              "youtube": null,
              "instagram": null,
              "facebook": "Rep.BluntRochester",
              "urls": ["https://bluntrochester.house.gov"],
              "rss_url": null
            },
            "title": "DE House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001303.json",
            "govtrack_id": "412689",
            "cspan_id": "104772",
            "votesmart_id": "173249",
            "icpsr_id": "21706",
            "crp_id": "N00038414",
            "google_entity_id": "/g/11c328y56v",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1962-02-10",
            "leadership_role": null,
            "fec_candidate_id": "H6DE00206",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 21,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.99,
            "votes_with_party_pct": 96.88,
            "votes_against_party_pct": 1.17,
            "ocd_id": "ocd-division/country:us/state:de/cd:1",
            "at_large": true
          }
        ],
        "hash": { "HOUSE-DE-00--B001303": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/fdf8cd7b-933f-469b-b1b7-119b39ba5e86",
            "firstName": "Nnamdi O.",
            "lastName": "Chukwuocha",
            "fullName": "Nnamdi Chukwuocha",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Chukwuocha.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-DE-1-lower-ocd-person-fdf8cd7b-933f-469b-b1b7-119b39ba5e86",
              "builtID": "de-lower-1",
              "externalID": "ocd-person/fdf8cd7b-933f-469b-b1b7-119b39ba5e86",
              "geometry": null
            },
            "contactInfo": {
              "email": "nnamdi.chukwuocha@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=13589",
                "https://legis.delaware.gov/LegislatorDetail?personId=13589",
                "https://legis.delaware.gov/AssemblyMember/150/Chukwuocha",
                "https://housedems.delaware.gov/members/house-district-1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-1-lower-ocd-person-fdf8cd7b-933f-469b-b1b7-119b39ba5e86": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/3ad8467d-5ec5-4626-aab5-11e42b62ad60",
            "firstName": "Stephanie T.",
            "lastName": "Bolden",
            "fullName": "Stephanie Bolden",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Bolden.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-DE-2-lower-ocd-person-3ad8467d-5ec5-4626-aab5-11e42b62ad60",
              "builtID": "de-lower-2",
              "externalID": "ocd-person/3ad8467d-5ec5-4626-aab5-11e42b62ad60",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephaniet.bolden@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/legislature.nsf/52087754ec8b9261852569c10055df42/a11deaacd840c137852569de005b764b?OpenDocument&TableRow=1.5.5",
                "https://legis.delaware.gov/LegislatorDetail?personId=332",
                "https://legis.delaware.gov/LegislatorDetail?personId=332",
                "https://legis.delaware.gov/AssemblyMember/150/Bolden",
                "https://housedems.delaware.gov/members/house-district-2/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-2-lower-ocd-person-3ad8467d-5ec5-4626-aab5-11e42b62ad60": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/16dc99d9-d69e-4d73-9ee0-a4ef2543b3df",
            "firstName": "Sherry",
            "lastName": "Dorsey Walker",
            "fullName": "Sherry Dorsey Walker",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Dorsey-Walker.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-DE-3-lower-ocd-person-16dc99d9-d69e-4d73-9ee0-a4ef2543b3df",
              "builtID": "de-lower-3",
              "externalID": "ocd-person/16dc99d9-d69e-4d73-9ee0-a4ef2543b3df",
              "geometry": null
            },
            "contactInfo": {
              "email": "sherry.dorseywalker@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=13590",
                "https://legis.delaware.gov/LegislatorDetail?personId=13590",
                "https://legis.delaware.gov/AssemblyMember/150/DorseyWalker",
                "https://housedems.delaware.gov/members/house-district-3/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-3-lower-ocd-person-16dc99d9-d69e-4d73-9ee0-a4ef2543b3df": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/aa0846e8-7d8d-46ed-b4c9-54c9c2243d16",
            "firstName": "Jeffrey P.",
            "lastName": "Hilovsky",
            "fullName": "Jeff Hilovsky",
            "gender": "Male",
            "party": "Republican",
            "image": "https://housegop.delaware.gov/wp-content/blogs.dir/207/files/sites/207/2022/12/JeffHilovsky2023-square-710x710.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-DE-4-lower-ocd-person-aa0846e8-7d8d-46ed-b4c9-54c9c2243d16",
              "builtID": "de-lower-4",
              "externalID": "ocd-person/aa0846e8-7d8d-46ed-b4c9-54c9c2243d16",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.hilovsky@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=24306",
                "https://legis.delaware.gov/AssemblyMember/152/Hilovsky",
                "https://housegop.delaware.gov/members/house-district-4/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-4-lower-ocd-person-aa0846e8-7d8d-46ed-b4c9-54c9c2243d16": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/b24cb06f-0cb1-4682-8038-d31b8ebc76fb",
            "firstName": "Kendra L.",
            "lastName": "Johnson",
            "fullName": "Kendra Johnson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/K-Johnson.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-DE-5-lower-ocd-person-b24cb06f-0cb1-4682-8038-d31b8ebc76fb",
              "builtID": "de-lower-5",
              "externalID": "ocd-person/b24cb06f-0cb1-4682-8038-d31b8ebc76fb",
              "geometry": null
            },
            "contactInfo": {
              "email": "kendra.johnson@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=13591",
                "https://legis.delaware.gov/LegislatorDetail?personId=13591",
                "https://legis.delaware.gov/AssemblyMember/150/KJohnson",
                "https://housedems.delaware.gov/members/house-district-5/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-5-lower-ocd-person-b24cb06f-0cb1-4682-8038-d31b8ebc76fb": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/7e631da1-ef0f-4c6f-ba17-e5ae2860dad6",
            "firstName": "Debra J.",
            "lastName": "Heffernan",
            "fullName": "Debra Heffernan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Heffernan.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-DE-6-lower-ocd-person-7e631da1-ef0f-4c6f-ba17-e5ae2860dad6",
              "builtID": "de-lower-6",
              "externalID": "ocd-person/7e631da1-ef0f-4c6f-ba17-e5ae2860dad6",
              "geometry": null
            },
            "contactInfo": {
              "email": "debra.heffernan@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=97",
                "https://legis.delaware.gov/LegislatorDetail?personId=97",
                "https://legis.delaware.gov/AssemblyMember/150/Heffernan",
                "https://housedems.delaware.gov/members/house-district-6/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-6-lower-ocd-person-7e631da1-ef0f-4c6f-ba17-e5ae2860dad6": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/1e6f6e97-5c95-4351-afe2-d8843f7897bc",
            "firstName": "Larry D.",
            "lastName": "Lambert",
            "fullName": "Larry Lambert",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Lambert.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-DE-7-lower-ocd-person-1e6f6e97-5c95-4351-afe2-d8843f7897bc",
              "builtID": "de-lower-7",
              "externalID": "ocd-person/1e6f6e97-5c95-4351-afe2-d8843f7897bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "larry.lambert@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=24002",
                "https://legis.delaware.gov/LegislatorDetail?personId=24002",
                "https://legis.delaware.gov/AssemblyMember/151/Lambert",
                "https://housedems.delaware.gov/members/house-district-7/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-7-lower-ocd-person-1e6f6e97-5c95-4351-afe2-d8843f7897bc": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/6a8995a4-deab-416f-89ce-987c8c6557aa",
            "firstName": "Sherae'a Eboni",
            "lastName": "Moore",
            "fullName": "Rae Moore",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2022/09/Moore_Sheraea_Member_Card-min-710x710.png",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-DE-8-lower-ocd-person-6a8995a4-deab-416f-89ce-987c8c6557aa",
              "builtID": "de-lower-8",
              "externalID": "ocd-person/6a8995a4-deab-416f-89ce-987c8c6557aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "rae.moore@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=3143",
                "https://legis.delaware.gov/LegislatorDetail?personId=3143",
                "https://legis.delaware.gov/AssemblyMember/151/SMoore",
                "https://housedems.delaware.gov/members/house-district-8/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-8-lower-ocd-person-6a8995a4-deab-416f-89ce-987c8c6557aa": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/d1020a85-27a1-4458-9ae2-90b09367be2c",
            "firstName": "Kevin S.",
            "lastName": "Hensley",
            "fullName": "Kevin Hensley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://housegop.delaware.gov/wp-content/blogs.dir/207/files/sites/207/2022/08/hensley-headshot-cropped-jan-231.jpeg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-DE-9-lower-ocd-person-d1020a85-27a1-4458-9ae2-90b09367be2c",
              "builtID": "de-lower-9",
              "externalID": "ocd-person/d1020a85-27a1-4458-9ae2-90b09367be2c",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.hensley@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=193",
                "https://legis.delaware.gov/LegislatorDetail?personId=193",
                "https://legis.delaware.gov/AssemblyMember/150/Hensley",
                "https://housegop.delaware.gov/members/house-district-9/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-9-lower-ocd-person-d1020a85-27a1-4458-9ae2-90b09367be2c": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/f8bb88ef-0c5c-45fd-b4e5-397c14899892",
            "firstName": "Sean",
            "lastName": "Matthews",
            "fullName": "Sean Matthews",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Matthews.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-DE-10-lower-ocd-person-f8bb88ef-0c5c-45fd-b4e5-397c14899892",
              "builtID": "de-lower-10",
              "externalID": "ocd-person/f8bb88ef-0c5c-45fd-b4e5-397c14899892",
              "geometry": null
            },
            "contactInfo": {
              "email": "sean.matthews@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=318",
                "https://legis.delaware.gov/LegislatorDetail?personId=318",
                "https://legis.delaware.gov/AssemblyMember/150/Matthews",
                "https://housedems.delaware.gov/members/house-district-10/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-10-lower-ocd-person-f8bb88ef-0c5c-45fd-b4e5-397c14899892": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/ef94fe15-3a43-405c-8ec6-d446784dfb32",
            "firstName": "Jeffrey N.",
            "lastName": "Spiegelman",
            "fullName": "Jeff Spiegelman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://housegop.delaware.gov/wp-content/blogs.dir/207/files/sites/207/2022/08/spiegelman-headshot-compressed-jan-231.jpeg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-DE-11-lower-ocd-person-ef94fe15-3a43-405c-8ec6-d446784dfb32",
              "builtID": "de-lower-11",
              "externalID": "ocd-person/ef94fe15-3a43-405c-8ec6-d446784dfb32",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.spiegelman@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/legislature.nsf/52087754ec8b9261852569c10055df42/ce9f5e594c1b54cc852569d800535df9?OpenDocument&TableRow=1.5.5",
                "https://legis.delaware.gov/LegislatorDetail?personId=169",
                "https://legis.delaware.gov/LegislatorDetail?personId=169",
                "https://legis.delaware.gov/AssemblyMember/150/Spiegelman",
                "https://housegop.delaware.gov/members/house-district-11/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-11-lower-ocd-person-ef94fe15-3a43-405c-8ec6-d446784dfb32": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/0dc99b9c-115c-4da0-81b1-22aa5b6750e0",
            "firstName": "Krista Mollee Zanin",
            "lastName": "Griffith",
            "fullName": "Krista Griffith",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2022/09/Griffith-headshot.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-DE-12-lower-ocd-person-0dc99b9c-115c-4da0-81b1-22aa5b6750e0",
              "builtID": "de-lower-12",
              "externalID": "ocd-person/0dc99b9c-115c-4da0-81b1-22aa5b6750e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "krista.griffith@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=13593",
                "https://legis.delaware.gov/LegislatorDetail?personId=13593",
                "https://legis.delaware.gov/AssemblyMember/150/Griffith",
                "https://housedems.delaware.gov/members/house-district-12/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-12-lower-ocd-person-0dc99b9c-115c-4da0-81b1-22aa5b6750e0": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/5c5f5bf8-d155-4911-8e06-eb6cc664859e",
            "firstName": "DeShanna",
            "lastName": "Neal",
            "fullName": "DeShanna Neal",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Neal.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-DE-13-lower-ocd-person-5c5f5bf8-d155-4911-8e06-eb6cc664859e",
              "builtID": "de-lower-13",
              "externalID": "ocd-person/5c5f5bf8-d155-4911-8e06-eb6cc664859e",
              "geometry": null
            },
            "contactInfo": {
              "email": "deshanna.neal@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=24307",
                "https://legis.delaware.gov/AssemblyMember/152/Neal",
                "https://housedems.delaware.gov/members/house-district-13/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-13-lower-ocd-person-5c5f5bf8-d155-4911-8e06-eb6cc664859e": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/12c9f274-117a-4e85-857e-c379164506ed",
            "firstName": "Peter C.",
            "lastName": "Schwartzkopf",
            "fullName": "Pete Schwartzkopf",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Schwartzkopf.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-DE-14-lower-ocd-person-12c9f274-117a-4e85-857e-c379164506ed",
              "builtID": "de-lower-14",
              "externalID": "ocd-person/12c9f274-117a-4e85-857e-c379164506ed",
              "geometry": null
            },
            "contactInfo": {
              "email": "peter.schwartzkopf@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=260",
                "https://legis.delaware.gov/LegislatorDetail?personId=260",
                "https://legis.delaware.gov/AssemblyMember/150/Schwartzkopf",
                "https://housedems.delaware.gov/members/house-district-14/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-14-lower-ocd-person-12c9f274-117a-4e85-857e-c379164506ed": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/8a7842c7-39a7-40f7-9d05-0a9ee2acd977",
            "firstName": "Valerie Maglio",
            "lastName": "Longhurst",
            "fullName": "Val Longhurst",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Longhurst.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-DE-15-lower-ocd-person-8a7842c7-39a7-40f7-9d05-0a9ee2acd977",
              "builtID": "de-lower-15",
              "externalID": "ocd-person/8a7842c7-39a7-40f7-9d05-0a9ee2acd977",
              "geometry": null
            },
            "contactInfo": {
              "email": "valerie.longhurst@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=359",
                "https://legis.delaware.gov/LegislatorDetail?personId=359",
                "https://legis.delaware.gov/AssemblyMember/150/Longhurst",
                "https://housedems.delaware.gov/members/house-district-15/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-15-lower-ocd-person-8a7842c7-39a7-40f7-9d05-0a9ee2acd977": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/0cac3293-534b-4188-93e3-37ef6cd333ae",
            "firstName": "Franklin D.",
            "lastName": "Cooke",
            "fullName": "Frank Cooke",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Cooke.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-DE-16-lower-ocd-person-0cac3293-534b-4188-93e3-37ef6cd333ae",
              "builtID": "de-lower-16",
              "externalID": "ocd-person/0cac3293-534b-4188-93e3-37ef6cd333ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "franklind.cooke@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=13594",
                "https://legis.delaware.gov/LegislatorDetail?personId=13594",
                "https://legis.delaware.gov/AssemblyMember/150/Cooke",
                "https://housedems.delaware.gov/members/house-district-16/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-16-lower-ocd-person-0cac3293-534b-4188-93e3-37ef6cd333ae": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/2ceeb021-8aee-405f-afd6-f53f0c3d0bb7",
            "firstName": "Melissa C.",
            "lastName": "Minor-Brown",
            "fullName": "Melissa Minor-Brown",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Minor-Brown.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-DE-17-lower-ocd-person-2ceeb021-8aee-405f-afd6-f53f0c3d0bb7",
              "builtID": "de-lower-17",
              "externalID": "ocd-person/2ceeb021-8aee-405f-afd6-f53f0c3d0bb7",
              "geometry": null
            },
            "contactInfo": {
              "email": "melissa.minorbrown@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=13595",
                "https://legis.delaware.gov/LegislatorDetail?personId=13595",
                "https://legis.delaware.gov/AssemblyMember/150/MinorBrown",
                "https://housedems.delaware.gov/members/house-district-17/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-17-lower-ocd-person-2ceeb021-8aee-405f-afd6-f53f0c3d0bb7": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/0a452e37-d7e7-403d-97ff-740c1d257eda",
            "firstName": "Sophie",
            "lastName": "Phillips",
            "fullName": "Sophie Phillips",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Phillips.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-DE-18-lower-ocd-person-0a452e37-d7e7-403d-97ff-740c1d257eda",
              "builtID": "de-lower-18",
              "externalID": "ocd-person/0a452e37-d7e7-403d-97ff-740c1d257eda",
              "geometry": null
            },
            "contactInfo": {
              "email": "sophie.phillips@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=24308",
                "https://legis.delaware.gov/AssemblyMember/152/Phillips",
                "https://housedems.delaware.gov/members/house-district-18/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-18-lower-ocd-person-0a452e37-d7e7-403d-97ff-740c1d257eda": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/80dfa709-d199-4950-a067-2dafc40241b9",
            "firstName": "Kimberly",
            "lastName": "Williams",
            "fullName": "Kim Williams",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Williams.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-DE-19-lower-ocd-person-80dfa709-d199-4950-a067-2dafc40241b9",
              "builtID": "de-lower-19",
              "externalID": "ocd-person/80dfa709-d199-4950-a067-2dafc40241b9",
              "geometry": null
            },
            "contactInfo": {
              "email": "kimberly.williams@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=198",
                "https://legis.delaware.gov/LegislatorDetail?personId=198",
                "https://legis.delaware.gov/AssemblyMember/150/KWilliams",
                "https://housedems.delaware.gov/members/house-district-19/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-19-lower-ocd-person-80dfa709-d199-4950-a067-2dafc40241b9": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/44d88080-6978-4968-9359-38dbd0935e6a",
            "firstName": "Esthelda Ramona Parker",
            "lastName": "Selby",
            "fullName": "Stell Selby",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Parker-Selby.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-DE-20-lower-ocd-person-44d88080-6978-4968-9359-38dbd0935e6a",
              "builtID": "de-lower-20",
              "externalID": "ocd-person/44d88080-6978-4968-9359-38dbd0935e6a",
              "geometry": null
            },
            "contactInfo": {
              "email": "stell.parkerselby@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=24309",
                "https://legis.delaware.gov/AssemblyMember/152/ParkerSelby",
                "https://housedems.delaware.gov/members/house-district-20/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-20-lower-ocd-person-44d88080-6978-4968-9359-38dbd0935e6a": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/73418316-37c9-457a-a800-84815cca2c0e",
            "firstName": "Michael J.",
            "lastName": "Ramone",
            "fullName": "Mike Ramone",
            "gender": "Male",
            "party": "Republican",
            "image": "https://housegop.delaware.gov/wp-content/blogs.dir/207/files/sites/207/2022/08/Ramone_Mike_Member_Card.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-DE-21-lower-ocd-person-73418316-37c9-457a-a800-84815cca2c0e",
              "builtID": "de-lower-21",
              "externalID": "ocd-person/73418316-37c9-457a-a800-84815cca2c0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.ramone@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=243",
                "https://legis.delaware.gov/LegislatorDetail?personId=243",
                "https://legis.delaware.gov/AssemblyMember/150/Ramone",
                "https://housegop.delaware.gov/members/house-district-21/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-21-lower-ocd-person-73418316-37c9-457a-a800-84815cca2c0e": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/c9f1393e-01c8-4597-a6e5-6d43330264dc",
            "firstName": "Michael F.",
            "lastName": "Smith",
            "fullName": "Mike Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://housegop.delaware.gov/wp-content/blogs.dir/207/files/sites/207/2023/03/smith-headshot-compressed-jan-23.png",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-DE-22-lower-ocd-person-c9f1393e-01c8-4597-a6e5-6d43330264dc",
              "builtID": "de-lower-22",
              "externalID": "ocd-person/c9f1393e-01c8-4597-a6e5-6d43330264dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.f.smith@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=13596",
                "https://legis.delaware.gov/LegislatorDetail?personId=13596",
                "https://legis.delaware.gov/AssemblyMember/150/MichaelSmith",
                "https://housegop.delaware.gov/members/house-district-22/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-22-lower-ocd-person-c9f1393e-01c8-4597-a6e5-6d43330264dc": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/d3a970f7-a567-4761-a318-ba3d15cda1cb",
            "firstName": "Paul S.",
            "lastName": "Baumbach",
            "fullName": "Paul Baumbach",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Baumbach.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-DE-23-lower-ocd-person-d3a970f7-a567-4761-a318-ba3d15cda1cb",
              "builtID": "de-lower-23",
              "externalID": "ocd-person/d3a970f7-a567-4761-a318-ba3d15cda1cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.baumbach@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=252",
                "https://legis.delaware.gov/LegislatorDetail?personId=252",
                "https://legis.delaware.gov/AssemblyMember/150/Baumbach",
                "https://housedems.delaware.gov/members/house-district-23/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-23-lower-ocd-person-d3a970f7-a567-4761-a318-ba3d15cda1cb": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/07cb3c97-de4e-4210-8c83-b7276c001024",
            "firstName": "Edward S.",
            "lastName": "Osienski",
            "fullName": "Ed Osienski",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Osienski.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-DE-24-lower-ocd-person-07cb3c97-de4e-4210-8c83-b7276c001024",
              "builtID": "de-lower-24",
              "externalID": "ocd-person/07cb3c97-de4e-4210-8c83-b7276c001024",
              "geometry": null
            },
            "contactInfo": {
              "email": "edward.osienski@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=112",
                "https://legis.delaware.gov/LegislatorDetail?personId=112",
                "https://legis.delaware.gov/AssemblyMember/150/Osienski",
                "https://housedems.delaware.gov/members/house-district-24/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-24-lower-ocd-person-07cb3c97-de4e-4210-8c83-b7276c001024": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/d187436c-b3d3-4512-9172-b7a868942fe4",
            "firstName": "Cyndie Gildea",
            "lastName": "Romer",
            "fullName": "Cyndie Romer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Romer.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-DE-25-lower-ocd-person-d187436c-b3d3-4512-9172-b7a868942fe4",
              "builtID": "de-lower-25",
              "externalID": "ocd-person/d187436c-b3d3-4512-9172-b7a868942fe4",
              "geometry": null
            },
            "contactInfo": {
              "email": "cyndie.romer@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=24310",
                "https://legis.delaware.gov/AssemblyMember/152/Romer",
                "https://housedems.delaware.gov/members/house-district-25/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-25-lower-ocd-person-d187436c-b3d3-4512-9172-b7a868942fe4": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/6c816294-3378-48c1-b2fd-bb5cd57a9deb",
            "firstName": "Madinah",
            "lastName": "Wilson-Anton",
            "fullName": "Madinah Wilson-Anton",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Wilson-Anton.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-DE-26-lower-ocd-person-6c816294-3378-48c1-b2fd-bb5cd57a9deb",
              "builtID": "de-lower-26",
              "externalID": "ocd-person/6c816294-3378-48c1-b2fd-bb5cd57a9deb",
              "geometry": null
            },
            "contactInfo": {
              "email": "madinah.wilson-anton@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=232",
                "https://legis.delaware.gov/LegislatorDetail?personId=232",
                "https://legis.delaware.gov/AssemblyMember/151/WilsonAnton",
                "https://housedems.delaware.gov/members/house-district-26/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-26-lower-ocd-person-6c816294-3378-48c1-b2fd-bb5cd57a9deb": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/bfab3b33-30ff-45fc-a45f-ae796d43de68",
            "firstName": "Eric",
            "lastName": "Morrison",
            "fullName": "Eric Morrison",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Morrison-NEW-1.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-DE-27-lower-ocd-person-bfab3b33-30ff-45fc-a45f-ae796d43de68",
              "builtID": "de-lower-27",
              "externalID": "ocd-person/bfab3b33-30ff-45fc-a45f-ae796d43de68",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.morrison@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=24001",
                "https://legis.delaware.gov/LegislatorDetail?personId=24001",
                "https://legis.delaware.gov/AssemblyMember/151/Morrison",
                "https://housedems.delaware.gov/members/house-district-27/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-27-lower-ocd-person-bfab3b33-30ff-45fc-a45f-ae796d43de68": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/35bf376a-ffc2-4468-b6ab-0ef72cc8d05b",
            "firstName": "William J.",
            "lastName": "Carson",
            "fullName": "Bill Carson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Carson.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-DE-28-lower-ocd-person-35bf376a-ffc2-4468-b6ab-0ef72cc8d05b",
              "builtID": "de-lower-28",
              "externalID": "ocd-person/35bf376a-ffc2-4468-b6ab-0ef72cc8d05b",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.carson@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=370",
                "https://legis.delaware.gov/LegislatorDetail?personId=370",
                "https://legis.delaware.gov/AssemblyMember/150/Carson",
                "https://housedems.delaware.gov/members/house-district-28/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-28-lower-ocd-person-35bf376a-ffc2-4468-b6ab-0ef72cc8d05b": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/fccfb524-6aee-40a9-8469-081e961dd072",
            "firstName": "William G.",
            "lastName": "Bush",
            "fullName": "Bill Bush",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Bush.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-DE-29-lower-ocd-person-fccfb524-6aee-40a9-8469-081e961dd072",
              "builtID": "de-lower-29",
              "externalID": "ocd-person/fccfb524-6aee-40a9-8469-081e961dd072",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.bush@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=369",
                "https://legis.delaware.gov/LegislatorDetail?personId=369",
                "https://legis.delaware.gov/AssemblyMember/150/Bush",
                "https://housedems.delaware.gov/members/house-district-29/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-29-lower-ocd-person-fccfb524-6aee-40a9-8469-081e961dd072": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/1952d6d2-3cd8-4a2c-b663-f16052b948c3",
            "firstName": "William Shannon",
            "lastName": "Morris",
            "fullName": "Shannon Morris",
            "gender": "Male",
            "party": "Republican",
            "image": "https://housegop.delaware.gov/wp-content/blogs.dir/207/files/sites/207/2023/02/morris-headshot-compressed-jan-23.jpeg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-DE-30-lower-ocd-person-1952d6d2-3cd8-4a2c-b663-f16052b948c3",
              "builtID": "de-lower-30",
              "externalID": "ocd-person/1952d6d2-3cd8-4a2c-b663-f16052b948c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "shannon.morris@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=13597",
                "https://legis.delaware.gov/LegislatorDetail?personId=13597",
                "https://legis.delaware.gov/AssemblyMember/150/Morris",
                "https://housegop.delaware.gov/members/house-district-30/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-30-lower-ocd-person-1952d6d2-3cd8-4a2c-b663-f16052b948c3": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/6fd6e720-822c-45ee-8fac-933b4eb9ccb5",
            "firstName": "Sean M.",
            "lastName": "Lynn",
            "fullName": "Sean Lynn",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2022/09/Lynn_Sean_Member_Card-min-710x710.png",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-DE-31-lower-ocd-person-6fd6e720-822c-45ee-8fac-933b4eb9ccb5",
              "builtID": "de-lower-31",
              "externalID": "ocd-person/6fd6e720-822c-45ee-8fac-933b4eb9ccb5",
              "geometry": null
            },
            "contactInfo": {
              "email": "sean.lynn@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=317",
                "https://legis.delaware.gov/LegislatorDetail?personId=317",
                "https://legis.delaware.gov/AssemblyMember/150/Lynn",
                "https://housedems.delaware.gov/members/house-district-31/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-31-lower-ocd-person-6fd6e720-822c-45ee-8fac-933b4eb9ccb5": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/7ae371b6-0fd5-457a-af26-032432dd381b",
            "firstName": "Kerri Evelyn",
            "lastName": "Harris",
            "fullName": "Kerri Harris",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.delaware.gov/wp-content/blogs.dir/210/files/sites/210/2023/02/Harris.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-DE-32-lower-ocd-person-7ae371b6-0fd5-457a-af26-032432dd381b",
              "builtID": "de-lower-32",
              "externalID": "ocd-person/7ae371b6-0fd5-457a-af26-032432dd381b",
              "geometry": null
            },
            "contactInfo": {
              "email": "kerrievelyn.harris@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=24311",
                "https://legis.delaware.gov/AssemblyMember/152/Harris",
                "https://housedems.delaware.gov/members/house-district-32/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-32-lower-ocd-person-7ae371b6-0fd5-457a-af26-032432dd381b": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/4f6f337c-16e7-4d39-a503-6b4b36650d2d",
            "firstName": "Charles S.",
            "lastName": "Postles",
            "fullName": "Charles Postles",
            "gender": "Male",
            "party": "Republican",
            "image": "https://housegop.delaware.gov/wp-content/blogs.dir/207/files/sites/207/2022/08/Charlie_Postles_Member_Card_710x710.png",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-DE-33-lower-ocd-person-4f6f337c-16e7-4d39-a503-6b4b36650d2d",
              "builtID": "de-lower-33",
              "externalID": "ocd-person/4f6f337c-16e7-4d39-a503-6b4b36650d2d",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.postles@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=3133",
                "https://legis.delaware.gov/LegislatorDetail?personId=3133",
                "https://legis.delaware.gov/AssemblyMember/150/Postles",
                "https://housegop.delaware.gov/members/house-district-33/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-33-lower-ocd-person-4f6f337c-16e7-4d39-a503-6b4b36650d2d": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/85f716aa-751a-4258-a68b-f6ed94b2daa0",
            "firstName": "Lyndon Dean",
            "lastName": "Yearick",
            "fullName": "Lyndon Yearick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://housegop.delaware.gov/wp-content/blogs.dir/207/files/sites/207/2022/08/Lyndon_D_Yearick_710x710-710x710.png",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-DE-34-lower-ocd-person-85f716aa-751a-4258-a68b-f6ed94b2daa0",
              "builtID": "de-lower-34",
              "externalID": "ocd-person/85f716aa-751a-4258-a68b-f6ed94b2daa0",
              "geometry": null
            },
            "contactInfo": {
              "email": "lyndon.yearick@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=223",
                "https://legis.delaware.gov/LegislatorDetail?personId=223",
                "https://legis.delaware.gov/AssemblyMember/150/Yearick",
                "https://housegop.delaware.gov/members/house-district-34/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-34-lower-ocd-person-85f716aa-751a-4258-a68b-f6ed94b2daa0": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/4932afab-9381-40d2-837f-f735d1c14b88",
            "firstName": "Jesse R.",
            "lastName": "Vanderwende",
            "fullName": "Jesse Vanderwende",
            "gender": "Male",
            "party": "Republican",
            "image": "https://housegop.delaware.gov/wp-content/blogs.dir/207/files/sites/207/2022/08/Vanderwende_Jesse_Member_Card.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-DE-35-lower-ocd-person-4932afab-9381-40d2-837f-f735d1c14b88",
              "builtID": "de-lower-35",
              "externalID": "ocd-person/4932afab-9381-40d2-837f-f735d1c14b88",
              "geometry": null
            },
            "contactInfo": {
              "email": "jesse.vanderwende@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=13598",
                "https://legis.delaware.gov/LegislatorDetail?personId=13598",
                "https://legis.delaware.gov/AssemblyMember/150/Vanderwende",
                "https://housegop.delaware.gov/members/house-district-35/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-35-lower-ocd-person-4932afab-9381-40d2-837f-f735d1c14b88": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/11732933-9dce-46b4-8683-67e8449b315b",
            "firstName": "Bryan William",
            "lastName": "Shupe",
            "fullName": "Bryan Shupe",
            "gender": "Male",
            "party": "Republican",
            "image": "https://housegop.delaware.gov/wp-content/blogs.dir/207/files/sites/207/2022/08/Shupe_Bryan_Member_Card.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-DE-36-lower-ocd-person-11732933-9dce-46b4-8683-67e8449b315b",
              "builtID": "de-lower-36",
              "externalID": "ocd-person/11732933-9dce-46b4-8683-67e8449b315b",
              "geometry": null
            },
            "contactInfo": {
              "email": "bryan.shupe@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=13599",
                "https://legis.delaware.gov/LegislatorDetail?personId=13599",
                "https://legis.delaware.gov/AssemblyMember/150/Shupe",
                "https://housegop.delaware.gov/members/house-district-36/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-36-lower-ocd-person-11732933-9dce-46b4-8683-67e8449b315b": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/312de86b-37d6-4ffe-a47c-63163e4177c0",
            "firstName": "Valerie Jones",
            "lastName": "Giltner",
            "fullName": "Valerie Giltner",
            "gender": "Female",
            "party": "Republican",
            "image": "https://housegop.delaware.gov/wp-content/blogs.dir/207/files/sites/207/2024/01/headshot-resized-nov-23.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-DE-37-lower-ocd-person-312de86b-37d6-4ffe-a47c-63163e4177c0",
              "builtID": "de-lower-37",
              "externalID": "ocd-person/312de86b-37d6-4ffe-a47c-63163e4177c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "valerie.jonesgiltner@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/AssemblyMember/152/JonesGiltner",
                "https://housegop.delaware.gov/members/house-district-37/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-37-lower-ocd-person-312de86b-37d6-4ffe-a47c-63163e4177c0": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/5cd844e2-9346-40d9-a804-7531aa49ccd4",
            "firstName": "Ronald E.",
            "lastName": "Gray",
            "fullName": "Ron Gray",
            "gender": "Male",
            "party": "Republican",
            "image": "https://housegop.delaware.gov/wp-content/blogs.dir/207/files/sites/207/2022/08/Ron_Gray_Member_Card.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-DE-38-lower-ocd-person-5cd844e2-9346-40d9-a804-7531aa49ccd4",
              "builtID": "de-lower-38",
              "externalID": "ocd-person/5cd844e2-9346-40d9-a804-7531aa49ccd4",
              "geometry": null
            },
            "contactInfo": {
              "email": "ronald.gray@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=290",
                "https://legis.delaware.gov/LegislatorDetail?personId=290",
                "https://legis.delaware.gov/AssemblyMember/150/Gray",
                "https://housegop.delaware.gov/members/house-district-38/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-38-lower-ocd-person-5cd844e2-9346-40d9-a804-7531aa49ccd4": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/947a912f-49e7-4697-896e-fbcd1bcf41d3",
            "firstName": "Daniel B.",
            "lastName": "Short",
            "fullName": "Danny Short",
            "gender": "Male",
            "party": "Republican",
            "image": "https://housegop.delaware.gov/wp-content/blogs.dir/207/files/sites/207/2022/08/Daniel_Short_Member_Card.jpg",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-DE-39-lower-ocd-person-947a912f-49e7-4697-896e-fbcd1bcf41d3",
              "builtID": "de-lower-39",
              "externalID": "ocd-person/947a912f-49e7-4697-896e-fbcd1bcf41d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.short@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=89",
                "https://legis.delaware.gov/LegislatorDetail?personId=89",
                "https://legis.delaware.gov/legislature.nsf/bydistrict/representative39",
                "https://legis.delaware.gov/AssemblyMember/150/DShort",
                "https://housegop.delaware.gov/members/house-district-39/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-39-lower-ocd-person-947a912f-49e7-4697-896e-fbcd1bcf41d3": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/101c752d-fbe4-471c-ae50-f2b1409a328f",
            "firstName": "Timothy D.",
            "lastName": "Dukes",
            "fullName": "Tim Dukes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://housegop.delaware.gov/wp-content/blogs.dir/207/files/sites/207/2023/01/dukes-headshot-compressed2-jan-23.png",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-DE-40-lower-ocd-person-101c752d-fbe4-471c-ae50-f2b1409a328f",
              "builtID": "de-lower-40",
              "externalID": "ocd-person/101c752d-fbe4-471c-ae50-f2b1409a328f",
              "geometry": null
            },
            "contactInfo": {
              "email": "timothy.dukes@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=352",
                "https://legis.delaware.gov/LegislatorDetail?personId=352",
                "https://legis.delaware.gov/AssemblyMember/150/Dukes",
                "https://housegop.delaware.gov/members/house-district-40/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-40-lower-ocd-person-101c752d-fbe4-471c-ae50-f2b1409a328f": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/26490cf5-c29c-485e-bdc5-1e340a4c10f6",
            "firstName": "Richard G.",
            "lastName": "Collins",
            "fullName": "Rich Collins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://housegop.delaware.gov/wp-content/blogs.dir/207/files/sites/207/2022/08/Rich_Collins_Member_Card_710x710.png",
            "title": "DE Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-DE-41-lower-ocd-person-26490cf5-c29c-485e-bdc5-1e340a4c10f6",
              "builtID": "de-lower-41",
              "externalID": "ocd-person/26490cf5-c29c-485e-bdc5-1e340a4c10f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "rich.collins@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=284",
                "https://legis.delaware.gov/LegislatorDetail?personId=284",
                "https://legis.delaware.gov/AssemblyMember/150/Collins",
                "https://housegop.delaware.gov/members/house-district-41/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-DE-41-lower-ocd-person-26490cf5-c29c-485e-bdc5-1e340a4c10f6": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/26a4ec3d-4193-47f0-afe3-207e4a15210d",
            "firstName": "Sarah Elizabeth",
            "lastName": "McBride",
            "fullName": "Sarah McBride",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.delaware.gov/wp-content/blogs.dir/219/files/sites/219/2022/09/McBride_Sarah_Member_Card-min-2.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-DE-1-upper-ocd-person-26a4ec3d-4193-47f0-afe3-207e4a15210d",
              "builtID": "de-upper-1",
              "externalID": "ocd-person/26a4ec3d-4193-47f0-afe3-207e4a15210d",
              "geometry": null
            },
            "contactInfo": {
              "email": "sarah.mcbride@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=23999",
                "https://legis.delaware.gov/LegislatorDetail?personId=23999",
                "https://legis.delaware.gov/AssemblyMember/151/SMcBride",
                "https://senatedems.delaware.gov/members/senate-district-1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-1-upper-ocd-person-26a4ec3d-4193-47f0-afe3-207e4a15210d": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/8c5ff346-9896-489e-be96-db9d46d846da",
            "firstName": "Darius J.",
            "lastName": "Brown",
            "fullName": "Darius Brown",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senatedems.delaware.gov/wp-content/blogs.dir/219/files/sites/219/2022/09/Brown_Darius_J_Member_Card-min-2.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-DE-2-upper-ocd-person-8c5ff346-9896-489e-be96-db9d46d846da",
              "builtID": "de-upper-2",
              "externalID": "ocd-person/8c5ff346-9896-489e-be96-db9d46d846da",
              "geometry": null
            },
            "contactInfo": {
              "email": "darius.brown@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=13600",
                "https://legis.delaware.gov/LegislatorDetail?personId=13600",
                "https://legis.delaware.gov/AssemblyMember/150/Brown",
                "https://senatedems.delaware.gov/members/senate-district-2/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-2-upper-ocd-person-8c5ff346-9896-489e-be96-db9d46d846da": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/ca88dc3b-bbc6-4eb8-af32-9821268ebda5",
            "firstName": "Sarah Elizabeth",
            "lastName": "Lockman",
            "fullName": "Tizzy Lockman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.delaware.gov/wp-content/blogs.dir/219/files/sites/219/2023/03/Senator-Lockman.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-DE-3-upper-ocd-person-ca88dc3b-bbc6-4eb8-af32-9821268ebda5",
              "builtID": "de-upper-3",
              "externalID": "ocd-person/ca88dc3b-bbc6-4eb8-af32-9821268ebda5",
              "geometry": null
            },
            "contactInfo": {
              "email": "elizabeth.lockman@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=13601",
                "https://legis.delaware.gov/LegislatorDetail?personId=13601",
                "https://legis.delaware.gov/AssemblyMember/150/Lockman",
                "https://senatedems.delaware.gov/members/senate-district-3/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-3-upper-ocd-person-ca88dc3b-bbc6-4eb8-af32-9821268ebda5": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/e0bd9610-eafd-4e25-931a-6674762e4c03",
            "firstName": "Laura Viviana",
            "lastName": "Sturgeon",
            "fullName": "Laura Sturgeon",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.delaware.gov/wp-content/blogs.dir/219/files/sites/219/2023/03/Senator-Sturgeon.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-DE-4-upper-ocd-person-e0bd9610-eafd-4e25-931a-6674762e4c03",
              "builtID": "de-upper-4",
              "externalID": "ocd-person/e0bd9610-eafd-4e25-931a-6674762e4c03",
              "geometry": null
            },
            "contactInfo": {
              "email": "laura.sturgeon@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=13602",
                "https://legis.delaware.gov/LegislatorDetail?personId=13602",
                "https://legis.delaware.gov/AssemblyMember/150/Sturgeon",
                "https://senatedems.delaware.gov/members/senate-district-4/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-4-upper-ocd-person-e0bd9610-eafd-4e25-931a-6674762e4c03": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/96f29b7f-bd5a-4a83-8ed0-7a5c6d3c6036",
            "firstName": "Kyle Evans",
            "lastName": "Gay",
            "fullName": "Kyle Gay",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.delaware.gov/wp-content/blogs.dir/219/files/sites/219/2023/03/Senator-Gay.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-DE-5-upper-ocd-person-96f29b7f-bd5a-4a83-8ed0-7a5c6d3c6036",
              "builtID": "de-upper-5",
              "externalID": "ocd-person/96f29b7f-bd5a-4a83-8ed0-7a5c6d3c6036",
              "geometry": null
            },
            "contactInfo": {
              "email": "kyle.gay@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=13474",
                "https://legis.delaware.gov/LegislatorDetail?personId=13474",
                "https://legis.delaware.gov/AssemblyMember/151/Gay",
                "https://senatedems.delaware.gov/members/senate-district-5/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-5-upper-ocd-person-96f29b7f-bd5a-4a83-8ed0-7a5c6d3c6036": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/d96a4018-ca3f-40a6-a12c-cc38d757e9f0",
            "firstName": "Russell",
            "lastName": "Huxtable",
            "fullName": "Russ Huxtable",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senatedems.delaware.gov/wp-content/blogs.dir/219/files/sites/219/2023/03/Senator-Huxtable.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-DE-6-upper-ocd-person-d96a4018-ca3f-40a6-a12c-cc38d757e9f0",
              "builtID": "de-upper-6",
              "externalID": "ocd-person/d96a4018-ca3f-40a6-a12c-cc38d757e9f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "russell.huxtable@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=24303",
                "https://legis.delaware.gov/AssemblyMember/152/Huxtable",
                "https://senatedems.delaware.gov/members/senate-district-6/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-6-upper-ocd-person-d96a4018-ca3f-40a6-a12c-cc38d757e9f0": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/a42132e6-9070-44a4-81be-70797c4ea2bb",
            "firstName": "Spiros",
            "lastName": "Mantzavinos",
            "fullName": "Spiros Mantzavinos",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senatedems.delaware.gov/wp-content/blogs.dir/219/files/sites/219/2023/03/Senator-Mantzavinos.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-DE-7-upper-ocd-person-a42132e6-9070-44a4-81be-70797c4ea2bb",
              "builtID": "de-upper-7",
              "externalID": "ocd-person/a42132e6-9070-44a4-81be-70797c4ea2bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "spiros.mantzavinos@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=1272",
                "https://legis.delaware.gov/LegislatorDetail?personId=1272",
                "https://legis.delaware.gov/AssemblyMember/151/Mantzavinos",
                "https://senatedems.delaware.gov/members/senate-district-7/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-7-upper-ocd-person-a42132e6-9070-44a4-81be-70797c4ea2bb": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/25071aeb-2a5c-4e8b-b166-4a313b3ac22d",
            "firstName": "David P.",
            "lastName": "Sokola",
            "fullName": "Dave Sokola",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senatedems.delaware.gov/wp-content/blogs.dir/219/files/sites/219/2023/03/Senator-Sokola.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-DE-8-upper-ocd-person-25071aeb-2a5c-4e8b-b166-4a313b3ac22d",
              "builtID": "de-upper-8",
              "externalID": "ocd-person/25071aeb-2a5c-4e8b-b166-4a313b3ac22d",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.sokola@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=90",
                "https://legis.delaware.gov/LegislatorDetail?personId=90",
                "https://legis.delaware.gov/AssemblyMember/150/Sokola",
                "https://senatedems.delaware.gov/members/senate-district-8/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-8-upper-ocd-person-25071aeb-2a5c-4e8b-b166-4a313b3ac22d": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/7188a518-8d78-42e8-b618-ee38a10d20a4",
            "firstName": "John",
            "lastName": "Walsh",
            "fullName": "Jack Walsh",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senatedems.delaware.gov/wp-content/blogs.dir/219/files/sites/219/2022/09/John_Walsh_Member_Card-min-2.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-DE-9-upper-ocd-person-7188a518-8d78-42e8-b618-ee38a10d20a4",
              "builtID": "de-upper-9",
              "externalID": "ocd-person/7188a518-8d78-42e8-b618-ee38a10d20a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.walsh@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=3131",
                "https://legis.delaware.gov/LegislatorDetail?personId=3131",
                "https://legis.delaware.gov/AssemblyMember/150/Walsh",
                "https://senatedems.delaware.gov/members/senate-district-9/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-9-upper-ocd-person-7188a518-8d78-42e8-b618-ee38a10d20a4": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/24e001be-be7a-4110-8488-2e385315ff5b",
            "firstName": "Stephanie Leigh",
            "lastName": "Hansen",
            "fullName": "Stephanie Hansen",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.delaware.gov/wp-content/blogs.dir/219/files/sites/219/2023/03/Senator-Hansen.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-DE-10-upper-ocd-person-24e001be-be7a-4110-8488-2e385315ff5b",
              "builtID": "de-upper-10",
              "externalID": "ocd-person/24e001be-be7a-4110-8488-2e385315ff5b",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephanie.hansen@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=3212",
                "https://legis.delaware.gov/LegislatorDetail?personId=3212",
                "https://legis.delaware.gov/AssemblyMember/150/Hansen",
                "https://senatedems.delaware.gov/members/senate-district-10/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-10-upper-ocd-person-24e001be-be7a-4110-8488-2e385315ff5b": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/f35b571a-d734-4086-b358-ff21382d802f",
            "firstName": "Bryan Jeffrey Schurgard",
            "lastName": "Townsend",
            "fullName": "Bryan Townsend",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senatedems.delaware.gov/wp-content/blogs.dir/219/files/sites/219/2022/09/Bryan_Townsend_Member_Card-min-1.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-DE-11-upper-ocd-person-f35b571a-d734-4086-b358-ff21382d802f",
              "builtID": "de-upper-11",
              "externalID": "ocd-person/f35b571a-d734-4086-b358-ff21382d802f",
              "geometry": null
            },
            "contactInfo": {
              "email": "bryan.townsend@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=13",
                "https://legis.delaware.gov/LegislatorDetail?personId=13",
                "https://legis.delaware.gov/AssemblyMember/150/Townsend",
                "https://senatedems.delaware.gov/members/senate-district-11/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-11-upper-ocd-person-f35b571a-d734-4086-b358-ff21382d802f": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/5e54d79a-5ba8-4966-93aa-2e05788a3ddc",
            "firstName": "Nicole Saville",
            "lastName": "Poore",
            "fullName": "Nicole Poore",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.delaware.gov/wp-content/blogs.dir/219/files/sites/219/2023/03/Senator-Poore.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-DE-12-upper-ocd-person-5e54d79a-5ba8-4966-93aa-2e05788a3ddc",
              "builtID": "de-upper-12",
              "externalID": "ocd-person/5e54d79a-5ba8-4966-93aa-2e05788a3ddc",
              "geometry": null
            },
            "contactInfo": {
              "email": "nicole.poore@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=249",
                "https://legis.delaware.gov/LegislatorDetail?personId=249",
                "https://legis.delaware.gov/AssemblyMember/150/Poore",
                "https://senatedems.delaware.gov/members/senate-district-12/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-12-upper-ocd-person-5e54d79a-5ba8-4966-93aa-2e05788a3ddc": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/eee736fd-ee29-403a-9bbc-713961c734b7",
            "firstName": "Marie",
            "lastName": "Pinkney",
            "fullName": "Marie Pinkney",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.delaware.gov/wp-content/blogs.dir/219/files/sites/219/2023/03/Senator-Pinkney.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-DE-13-upper-ocd-person-eee736fd-ee29-403a-9bbc-713961c734b7",
              "builtID": "de-upper-13",
              "externalID": "ocd-person/eee736fd-ee29-403a-9bbc-713961c734b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "marie.pinkney@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=24000",
                "https://legis.delaware.gov/LegislatorDetail?personId=24000",
                "https://legis.delaware.gov/AssemblyMember/151/Pinkney",
                "https://senatedems.delaware.gov/members/senate-district-13/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-13-upper-ocd-person-eee736fd-ee29-403a-9bbc-713961c734b7": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/b5a52e58-da64-49a2-85b2-84f55427fa83",
            "firstName": "Kyra L.",
            "lastName": "Hoffner",
            "fullName": "Kyra Hoffner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.delaware.gov/wp-content/blogs.dir/219/files/sites/219/2022/09/headshot-710x710.jpg",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-DE-14-upper-ocd-person-b5a52e58-da64-49a2-85b2-84f55427fa83",
              "builtID": "de-upper-14",
              "externalID": "ocd-person/b5a52e58-da64-49a2-85b2-84f55427fa83",
              "geometry": null
            },
            "contactInfo": {
              "email": "kyra.hoffner@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=24304",
                "https://legis.delaware.gov/AssemblyMember/152/Hoffner",
                "https://senatedems.delaware.gov/members/senate-district-14/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-14-upper-ocd-person-b5a52e58-da64-49a2-85b2-84f55427fa83": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/44e38555-6698-43cb-aa8b-f47de87ba4e3",
            "firstName": "David G.",
            "lastName": "Lawson",
            "fullName": "Dave Lawson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senategop.delaware.gov/wp-content/blogs.dir/216/files/sites/216/2022/10/Lawson_Dave_Member_Card-min-1.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-DE-15-upper-ocd-person-44e38555-6698-43cb-aa8b-f47de87ba4e3",
              "builtID": "de-upper-15",
              "externalID": "ocd-person/44e38555-6698-43cb-aa8b-f47de87ba4e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "dave.lawson@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=86",
                "https://legis.delaware.gov/LegislatorDetail?personId=86",
                "https://legis.delaware.gov/AssemblyMember/150/Lawson",
                "https://senategop.delaware.gov/members/senate-district-15/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-15-upper-ocd-person-44e38555-6698-43cb-aa8b-f47de87ba4e3": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/fb743246-9c86-45f5-95eb-34bc1e11bef1",
            "firstName": "Eric L.",
            "lastName": "Buckson",
            "fullName": "Eric Buckson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senategop.delaware.gov/wp-content/blogs.dir/216/files/sites/216/2022/11/Buckson.Headshot.2023.Downsized-710x710.jpg",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-DE-16-upper-ocd-person-fb743246-9c86-45f5-95eb-34bc1e11bef1",
              "builtID": "de-upper-16",
              "externalID": "ocd-person/fb743246-9c86-45f5-95eb-34bc1e11bef1",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.buckson@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=24305",
                "https://legis.delaware.gov/AssemblyMember/152/Buckson",
                "https://senategop.delaware.gov/members/senate-district-16/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-16-upper-ocd-person-fb743246-9c86-45f5-95eb-34bc1e11bef1": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/89be0266-5ecf-40ac-9f5c-3dba2ce79a0d",
            "firstName": "William Charles",
            "lastName": "Paradee",
            "fullName": "Trey Paradee",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senatedems.delaware.gov/wp-content/blogs.dir/219/files/sites/219/2023/03/Senator-Paradee.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-DE-17-upper-ocd-person-89be0266-5ecf-40ac-9f5c-3dba2ce79a0d",
              "builtID": "de-upper-17",
              "externalID": "ocd-person/89be0266-5ecf-40ac-9f5c-3dba2ce79a0d",
              "geometry": null
            },
            "contactInfo": {
              "email": "trey.paradee@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=355",
                "https://legis.delaware.gov/LegislatorDetail?personId=355",
                "https://legis.delaware.gov/AssemblyMember/150/Paradee",
                "https://senatedems.delaware.gov/members/senate-district-17/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-17-upper-ocd-person-89be0266-5ecf-40ac-9f5c-3dba2ce79a0d": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/0b53dd28-648e-4fe1-a05a-36a21ec12e63",
            "firstName": "David Lee",
            "lastName": "Wilson",
            "fullName": "Dave Wilson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senategop.delaware.gov/wp-content/blogs.dir/216/files/sites/216/2022/10/Wilson_David_Member_Card-min-1.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-DE-18-upper-ocd-person-0b53dd28-648e-4fe1-a05a-36a21ec12e63",
              "builtID": "de-upper-18",
              "externalID": "ocd-person/0b53dd28-648e-4fe1-a05a-36a21ec12e63",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.l.wilson@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=92",
                "https://legis.delaware.gov/LegislatorDetail?personId=92",
                "https://legis.delaware.gov/AssemblyMember/150/Wilson",
                "https://senategop.delaware.gov/members/senate-district-18/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-18-upper-ocd-person-0b53dd28-648e-4fe1-a05a-36a21ec12e63": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/8b5f6201-6c2f-4847-8d69-737ae776c44b",
            "firstName": "Brian G.",
            "lastName": "Pettyjohn",
            "fullName": "Brian Pettyjohn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senategop.delaware.gov/wp-content/blogs.dir/216/files/sites/216/2022/10/Pettyjohn_Brian_Member_Card-min-1.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-DE-19-upper-ocd-person-8b5f6201-6c2f-4847-8d69-737ae776c44b",
              "builtID": "de-upper-19",
              "externalID": "ocd-person/8b5f6201-6c2f-4847-8d69-737ae776c44b",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.pettyjohn@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=9",
                "https://legis.delaware.gov/LegislatorDetail?personId=9",
                "https://legis.delaware.gov/AssemblyMember/150/Pettyjohn",
                "https://senategop.delaware.gov/members/senate-district-19/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-19-upper-ocd-person-8b5f6201-6c2f-4847-8d69-737ae776c44b": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/7d5fb783-232d-4cc2-b7c7-fdbf4d06eb69",
            "firstName": "Gerald W.",
            "lastName": "Hocker",
            "fullName": "Gerald Hocker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senategop.delaware.gov/wp-content/blogs.dir/216/files/sites/216/2022/10/Hocker_Gerald_Member_Card-min.png",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-DE-20-upper-ocd-person-7d5fb783-232d-4cc2-b7c7-fdbf4d06eb69",
              "builtID": "de-upper-20",
              "externalID": "ocd-person/7d5fb783-232d-4cc2-b7c7-fdbf4d06eb69",
              "geometry": null
            },
            "contactInfo": {
              "email": "gerald.hocker@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=126",
                "https://legis.delaware.gov/LegislatorDetail?personId=126",
                "https://legis.delaware.gov/AssemblyMember/150/Hocker",
                "https://senategop.delaware.gov/members/senate-district-20/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-20-upper-ocd-person-7d5fb783-232d-4cc2-b7c7-fdbf4d06eb69": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/5a29dfb1-14b5-4535-b8ee-da8f432d0727",
            "firstName": "Bryant L.",
            "lastName": "Richardson",
            "fullName": "Bryant Richardson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senategop.delaware.gov/wp-content/blogs.dir/216/files/sites/216/2022/10/Richardson.Headshot.2023.Downsized-710x710.jpg",
            "title": "DE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "DE",
              "stateFull": "Delaware",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-DE-21-upper-ocd-person-5a29dfb1-14b5-4535-b8ee-da8f432d0727",
              "builtID": "de-upper-21",
              "externalID": "ocd-person/5a29dfb1-14b5-4535-b8ee-da8f432d0727",
              "geometry": null
            },
            "contactInfo": {
              "email": "bryant.richardson@delaware.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.delaware.gov/LegislatorDetail?personId=10",
                "https://legis.delaware.gov/LegislatorDetail?personId=10",
                "https://legis.delaware.gov/AssemblyMember/151/Richardson",
                "https://senategop.delaware.gov/members/senat-district-21/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-DE-21-upper-ocd-person-5a29dfb1-14b5-4535-b8ee-da8f432d0727": 0
        }
      }
    }
  },
  "FL": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "FL",
          "stateFull": "Florida",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "FL",
            "stateFull": "Florida",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-FL---",
            "builtID": "fl--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-FL---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "R000595",
          "in_office": true,
          "firstName": "Marco",
          "lastName": "Rubio",
          "middleName": null,
          "fullName": "Marco Rubio",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/R000595.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "FL",
            "stateFull": "Florida",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-FL---R000595",
            "builtID": "fl--",
            "externalID": "R000595",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenRubioPress",
            "youtube": "SenatorMarcoRubio",
            "instagram": null,
            "facebook": "SenatorMarcoRubio",
            "urls": ["https://www.rubio.senate.gov"],
            "rss_url": "https://www.rubio.senate.gov/public/?a=rss.feed"
          },
          "title": "FL Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/R000595.json",
          "govtrack_id": "412491",
          "cspan_id": "87599",
          "votesmart_id": "1601",
          "icpsr_id": "41102",
          "crp_id": "N00030612",
          "google_entity_id": "/m/0dpr5f",
          "state_rank": "senior",
          "lis_id": "S350",
          "suffix": null,
          "date_of_birth": "1971-05-28",
          "leadership_role": null,
          "fec_candidate_id": "S0FL00338",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "13",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 32,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:24 -0400",
          "missed_votes_pct": 5.78,
          "votes_with_party_pct": 92.79,
          "votes_against_party_pct": 7.21,
          "ocd_id": "ocd-division/country:us/state:fl"
        },
        {
          "API_ID": "S001217",
          "in_office": true,
          "firstName": "Rick",
          "lastName": "Scott",
          "middleName": null,
          "fullName": "Rick Scott",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/S001217.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "FL",
            "stateFull": "Florida",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-FL---S001217",
            "builtID": "fl--",
            "externalID": "S001217",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.rickscott.senate.gov/contact/contact",
            "address1": "502 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-5274",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenRickScott",
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.rickscott.senate.gov"],
            "rss_url": null
          },
          "title": "FL Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/S001217.json",
          "govtrack_id": "412838",
          "cspan_id": null,
          "votesmart_id": "124204",
          "icpsr_id": null,
          "crp_id": "N00043290",
          "google_entity_id": "/m/0btx2g",
          "state_rank": "junior",
          "lis_id": "S404",
          "suffix": null,
          "date_of_birth": "1952-12-01",
          "leadership_role": null,
          "fec_candidate_id": "S8FL00273",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 39,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 7.04,
          "votes_with_party_pct": 88.71,
          "votes_against_party_pct": 11.29,
          "ocd_id": "ocd-division/country:us/state:fl"
        }
      ],
      "hash": { "SENATE-FL---R000595": 0, "SENATE-FL---S001217": 1 }
    },
    "HOUSE": {
      "10": {
        "members": [
          {
            "API_ID": "F000476",
            "in_office": true,
            "firstName": "Maxwell",
            "lastName": "Frost",
            "middleName": null,
            "fullName": "Maxwell Frost",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/F000476.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "10",
              "chamber": "",
              "hashID": "HOUSE-FL-10--F000476",
              "builtID": "fl--10",
              "externalID": "F000476",
              "geometry": null,
              "geoid": "1210"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1224 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2176",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMaxwellFrost",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://frost.house.gov/"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000476.json",
            "govtrack_id": "456890",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1997-01-17",
            "leadership_role": null,
            "fec_candidate_id": "H2FL10259",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 5,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.47,
            "votes_with_party_pct": 92.41,
            "votes_against_party_pct": 5.67,
            "ocd_id": "ocd-division/country:us/state:fl/cd:10",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-10--F000476": 0 }
      },
      "11": {
        "members": [
          {
            "API_ID": "W000806",
            "in_office": true,
            "firstName": "Daniel",
            "lastName": "Webster",
            "middleName": null,
            "fullName": "Daniel Webster",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/W000806.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "11",
              "chamber": "",
              "hashID": "HOUSE-FL-11--W000806",
              "builtID": "fl--11",
              "externalID": "W000806",
              "geometry": null,
              "geoid": "1211"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2184 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-1002",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepWebster",
              "youtube": "repdanwebster",
              "instagram": null,
              "facebook": "RepWebster",
              "urls": ["https://webster.house.gov"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000806.json",
            "govtrack_id": "412410",
            "cspan_id": "30504",
            "votesmart_id": "24302",
            "icpsr_id": "21116",
            "crp_id": "N00026335",
            "google_entity_id": "/m/03cg47l",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1949-04-27",
            "leadership_role": null,
            "fec_candidate_id": "H0FL08208",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 35,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 3.31,
            "votes_with_party_pct": 92.51,
            "votes_against_party_pct": 5.49,
            "ocd_id": "ocd-division/country:us/state:fl/cd:11",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-11--W000806": 0 }
      },
      "12": {
        "members": [
          {
            "API_ID": "B001257",
            "in_office": true,
            "firstName": "Gus",
            "lastName": "Bilirakis",
            "middleName": null,
            "fullName": "Gus M. Bilirakis",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001257.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "12",
              "chamber": "",
              "hashID": "HOUSE-FL-12--B001257",
              "builtID": "fl--12",
              "externalID": "B001257",
              "geometry": null,
              "geoid": "1212"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2306 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5755",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGusBilirakis",
              "youtube": "RepGusBilirakis",
              "instagram": null,
              "facebook": "GusBilirakis",
              "urls": ["https://bilirakis.house.gov"],
              "rss_url": "https://bilirakis.house.gov/rss.xml"
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001257.json",
            "govtrack_id": "412250",
            "cspan_id": "1022873",
            "votesmart_id": "17318",
            "icpsr_id": "20758",
            "crp_id": "N00027462",
            "google_entity_id": "/m/0dy3z1",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1963-02-08",
            "leadership_role": null,
            "fec_candidate_id": "H6FL09070",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "20",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 59,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 5.59,
            "votes_with_party_pct": 92.96,
            "votes_against_party_pct": 5.2,
            "ocd_id": "ocd-division/country:us/state:fl/cd:12",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-12--B001257": 0 }
      },
      "13": {
        "members": [
          {
            "API_ID": "L000596",
            "in_office": true,
            "firstName": "Anna Paulina",
            "lastName": "Luna",
            "middleName": null,
            "fullName": "Anna Paulina Luna",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/L000596.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "13",
              "chamber": "",
              "hashID": "HOUSE-FL-13--L000596",
              "builtID": "fl--13",
              "externalID": "L000596",
              "geometry": null,
              "geoid": "1213"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1017 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5961",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLuna",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://luna.house.gov/"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000596.json",
            "govtrack_id": "456891",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1989-05-06",
            "leadership_role": null,
            "fec_candidate_id": "H0FL13158",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 169,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 16,
            "votes_with_party_pct": 85.62,
            "votes_against_party_pct": 12.08,
            "ocd_id": "ocd-division/country:us/state:fl/cd:13",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-13--L000596": 0 }
      },
      "14": {
        "members": [
          {
            "API_ID": "C001066",
            "in_office": true,
            "firstName": "Kathy",
            "lastName": "Castor",
            "middleName": null,
            "fullName": "Kathy Castor",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001066.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "14",
              "chamber": "",
              "hashID": "HOUSE-FL-14--C001066",
              "builtID": "fl--14",
              "externalID": "C001066",
              "geometry": null,
              "geoid": "1214"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2052 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3376",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "USRepKCastor",
              "youtube": "RepKathyCastor",
              "instagram": null,
              "facebook": "USRepKathyCastor",
              "urls": ["https://castor.house.gov"],
              "rss_url": "https://castor.house.gov/news/rss.aspx"
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001066.json",
            "govtrack_id": "412195",
            "cspan_id": "1022874",
            "votesmart_id": "53825",
            "icpsr_id": "20708",
            "crp_id": "N00027514",
            "google_entity_id": "/m/0dq1v6",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1966-08-20",
            "leadership_role": null,
            "fec_candidate_id": "H6FL11126",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "18",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 15,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.42,
            "votes_with_party_pct": 96.8,
            "votes_against_party_pct": 1.36,
            "ocd_id": "ocd-division/country:us/state:fl/cd:14",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-14--C001066": 0 }
      },
      "15": {
        "members": [
          {
            "API_ID": "L000597",
            "in_office": true,
            "firstName": "Laurel",
            "lastName": "Lee",
            "middleName": null,
            "fullName": "Laurel M. Lee",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/L000597.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "15",
              "chamber": "",
              "hashID": "HOUSE-FL-15--L000597",
              "builtID": "fl--15",
              "externalID": "L000597",
              "geometry": null,
              "geoid": "1215"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1118 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5626",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLaurelLee",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://laurellee.house.gov/"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000597.json",
            "govtrack_id": "456892",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1974-03-26",
            "leadership_role": null,
            "fec_candidate_id": "H2FL15241",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 4,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.38,
            "votes_with_party_pct": 89.62,
            "votes_against_party_pct": 8.44,
            "ocd_id": "ocd-division/country:us/state:fl/cd:15",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-15--L000597": 0 }
      },
      "16": {
        "members": [
          {
            "API_ID": "B001260",
            "in_office": true,
            "firstName": "Vern",
            "lastName": "Buchanan",
            "middleName": null,
            "fullName": "Vern Buchanan",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001260.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "16",
              "chamber": "",
              "hashID": "HOUSE-FL-16--B001260",
              "builtID": "fl--16",
              "externalID": "B001260",
              "geometry": null,
              "geoid": "1216"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2110 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5015",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "VernBuchanan",
              "youtube": "vernbuchanan",
              "instagram": null,
              "facebook": "CongressmanBuchanan",
              "urls": ["https://buchanan.house.gov"],
              "rss_url": "https://buchanan.house.gov/rss.xml"
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001260.json",
            "govtrack_id": "412196",
            "cspan_id": "1021626",
            "votesmart_id": "66247",
            "icpsr_id": "20709",
            "crp_id": "N00027626",
            "google_entity_id": "/m/0gtjsp",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1951-05-08",
            "leadership_role": null,
            "fec_candidate_id": "H6FL13148",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "18",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 54,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 5.12,
            "votes_with_party_pct": 91.64,
            "votes_against_party_pct": 6.42,
            "ocd_id": "ocd-division/country:us/state:fl/cd:16",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-16--B001260": 0 }
      },
      "17": {
        "members": [
          {
            "API_ID": "S001214",
            "in_office": true,
            "firstName": "Greg",
            "lastName": "Steube",
            "middleName": null,
            "fullName": "W. Gregory Steube",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S001214.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "17",
              "chamber": "",
              "hashID": "HOUSE-FL-17--S001214",
              "builtID": "fl--17",
              "externalID": "S001214",
              "geometry": null,
              "geoid": "1217"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2457 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5792",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGregSteube",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://steube.house.gov"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001214.json",
            "govtrack_id": "412766",
            "cspan_id": null,
            "votesmart_id": "117248",
            "icpsr_id": null,
            "crp_id": "N00042808",
            "google_entity_id": "/m/0k3lrh0",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1978-05-19",
            "leadership_role": null,
            "fec_candidate_id": "H8FL17053",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 113,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 10.7,
            "votes_with_party_pct": 84.71,
            "votes_against_party_pct": 13.12,
            "ocd_id": "ocd-division/country:us/state:fl/cd:17",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-17--S001214": 0 }
      },
      "18": {
        "members": [
          {
            "API_ID": "F000472",
            "in_office": true,
            "firstName": "Scott",
            "lastName": "Franklin",
            "middleName": null,
            "fullName": "C. Scott Franklin",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/F000472.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "18",
              "chamber": "",
              "hashID": "HOUSE-FL-18--F000472",
              "builtID": "fl--18",
              "externalID": "F000472",
              "geometry": null,
              "geoid": "1218"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "249 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-1252",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepFranklin",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://franklin.house.gov"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000472.json",
            "govtrack_id": "456807",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00046760",
            "google_entity_id": "/g/11ft5hszwx",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1964-08-23",
            "leadership_role": null,
            "fec_candidate_id": "H0FL15104",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.57,
            "votes_with_party_pct": 93.49,
            "votes_against_party_pct": 4.57,
            "ocd_id": "ocd-division/country:us/state:fl/cd:18",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-18--F000472": 0 }
      },
      "19": {
        "members": [
          {
            "API_ID": "D000032",
            "in_office": true,
            "firstName": "Byron",
            "lastName": "Donalds",
            "middleName": null,
            "fullName": "Byron Donalds",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/D000032.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "19",
              "chamber": "",
              "hashID": "HOUSE-FL-19--D000032",
              "builtID": "fl--19",
              "externalID": "D000032",
              "geometry": null,
              "geoid": "1219"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1719 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2536",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepByronDonalds",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://donalds.house.gov"],
              "rss_url": ""
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000032.json",
            "govtrack_id": "456808",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00034016",
            "google_entity_id": "/g/11c1xk7pbn",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1978-10-28",
            "leadership_role": null,
            "fec_candidate_id": "H0FL19205",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 67,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 6.34,
            "votes_with_party_pct": 87.47,
            "votes_against_party_pct": 10.57,
            "ocd_id": "ocd-division/country:us/state:fl/cd:19",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-19--D000032": 0 }
      },
      "20": {
        "members": [
          {
            "API_ID": "C001127",
            "in_office": true,
            "firstName": "Sheila",
            "lastName": "Cherfilus-McCormick",
            "middleName": null,
            "fullName": "Sheila Cherfilus-McCormick",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001127.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "20",
              "chamber": "",
              "hashID": "HOUSE-FL-20--C001127",
              "builtID": "fl--20",
              "externalID": "C001127",
              "geometry": null,
              "geoid": "1220"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "242 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-1313",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "CongresswomanSC",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://cherfilus-mccormick.house.gov"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001127.json",
            "govtrack_id": "456865",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00043504",
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1979-01-25",
            "leadership_role": null,
            "fec_candidate_id": "H8FL20032",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 27,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.56,
            "votes_with_party_pct": 96.27,
            "votes_against_party_pct": 1.76,
            "ocd_id": "ocd-division/country:us/state:fl/cd:20",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-20--C001127": 0 }
      },
      "21": {
        "members": [
          {
            "API_ID": "M001199",
            "in_office": true,
            "firstName": "Brian",
            "lastName": "Mast",
            "middleName": null,
            "fullName": "Brian J. Mast",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001199.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "21",
              "chamber": "",
              "hashID": "HOUSE-FL-21--M001199",
              "builtID": "fl--21",
              "externalID": "M001199",
              "geometry": null,
              "geoid": "1221"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2182 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3026",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repbrianmast",
              "youtube": "repbrianmast",
              "instagram": null,
              "facebook": "repbrianmast",
              "urls": ["https://mast.house.gov"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001199.json",
            "govtrack_id": "412698",
            "cspan_id": "104540",
            "votesmart_id": "166245",
            "icpsr_id": "21735",
            "crp_id": "N00037269",
            "google_entity_id": "/g/11cs5xxx_f",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1980-07-10",
            "leadership_role": null,
            "fec_candidate_id": "H6FL18097",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 19,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.8,
            "votes_with_party_pct": 91.15,
            "votes_against_party_pct": 6.88,
            "ocd_id": "ocd-division/country:us/state:fl/cd:21",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-21--M001199": 0 }
      },
      "22": {
        "members": [
          {
            "API_ID": "F000462",
            "in_office": true,
            "firstName": "Lois",
            "lastName": "Frankel",
            "middleName": null,
            "fullName": "Lois Frankel",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/F000462.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "22",
              "chamber": "",
              "hashID": "HOUSE-FL-22--F000462",
              "builtID": "fl--22",
              "externalID": "F000462",
              "geometry": null,
              "geoid": "1222"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2305 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-9890",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLoisFrankel",
              "youtube": "reploisfrankel",
              "instagram": null,
              "facebook": "RepLoisFrankel",
              "urls": ["https://frankel.house.gov"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000462.json",
            "govtrack_id": "412529",
            "cspan_id": "623714",
            "votesmart_id": "8102",
            "icpsr_id": "21321",
            "crp_id": "N00002893",
            "google_entity_id": "/m/0gjdw8_",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1948-05-16",
            "leadership_role": null,
            "fec_candidate_id": "H2FL14053",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 52,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 4.93,
            "votes_with_party_pct": 95.68,
            "votes_against_party_pct": 2.41,
            "ocd_id": "ocd-division/country:us/state:fl/cd:22",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-22--F000462": 0 }
      },
      "23": {
        "members": [
          {
            "API_ID": "M001217",
            "in_office": true,
            "firstName": "Jared",
            "lastName": "Moskowitz",
            "middleName": null,
            "fullName": "Jared Moskowitz",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M001217.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "23",
              "chamber": "",
              "hashID": "HOUSE-FL-23--M001217",
              "builtID": "fl--23",
              "externalID": "M001217",
              "geometry": null,
              "geoid": "1223"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1130 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3001",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMoskowitz",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://moskowitz.house.gov/"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001217.json",
            "govtrack_id": "456893",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1980-12-18",
            "leadership_role": null,
            "fec_candidate_id": "H2FL22171",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 43,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 4.07,
            "votes_with_party_pct": 90.93,
            "votes_against_party_pct": 7.08,
            "ocd_id": "ocd-division/country:us/state:fl/cd:23",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-23--M001217": 0 }
      },
      "24": {
        "members": [
          {
            "API_ID": "W000808",
            "in_office": true,
            "firstName": "Frederica",
            "lastName": "Wilson",
            "middleName": null,
            "fullName": "Frederica S. Wilson",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/W000808.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "24",
              "chamber": "",
              "hashID": "HOUSE-FL-24--W000808",
              "builtID": "fl--24",
              "externalID": "W000808",
              "geometry": null,
              "geoid": "1224"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2080 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4506",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepWilson",
              "youtube": "repfredericawilson",
              "instagram": null,
              "facebook": "RepWilson",
              "urls": ["https://wilson.house.gov"],
              "rss_url": "https://wilson.house.gov/rss.xml"
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000808.json",
            "govtrack_id": "412412",
            "cspan_id": "87016",
            "votesmart_id": "17319",
            "icpsr_id": "21118",
            "crp_id": "N00030650",
            "google_entity_id": "/m/03cg3tv",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1942-11-05",
            "leadership_role": null,
            "fec_candidate_id": "H0FL17068",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 137,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 12.99,
            "votes_with_party_pct": 96.26,
            "votes_against_party_pct": 1.65,
            "ocd_id": "ocd-division/country:us/state:fl/cd:24",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-24--W000808": 0 }
      },
      "25": {
        "members": [
          {
            "API_ID": "W000797",
            "in_office": true,
            "firstName": "Debbie",
            "lastName": "Wasserman Schultz",
            "middleName": null,
            "fullName": "Debbie Wasserman Schultz",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/W000797.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "25",
              "chamber": "",
              "hashID": "HOUSE-FL-25--W000797",
              "builtID": "fl--25",
              "externalID": "W000797",
              "geometry": null,
              "geoid": "1225"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "270 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-7931",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDWStweets",
              "youtube": "RepWassermanSchultz",
              "instagram": null,
              "facebook": "RepDWS",
              "urls": ["https://wassermanschultz.house.gov"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000797.json",
            "govtrack_id": "400623",
            "cspan_id": "86882",
            "votesmart_id": "24301",
            "icpsr_id": "20504",
            "crp_id": "N00026106",
            "google_entity_id": "/m/04cmyw",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1966-09-27",
            "leadership_role": null,
            "fec_candidate_id": "H4FL20023",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "20",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 7,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.66,
            "votes_with_party_pct": 95.76,
            "votes_against_party_pct": 2.41,
            "ocd_id": "ocd-division/country:us/state:fl/cd:25",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-25--W000797": 0 }
      },
      "26": {
        "members": [
          {
            "API_ID": "D000600",
            "in_office": true,
            "firstName": "Mario",
            "lastName": "Diaz-Balart",
            "middleName": null,
            "fullName": "Mario Diaz-Balart",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/D000600.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "26",
              "chamber": "",
              "hashID": "HOUSE-FL-26--D000600",
              "builtID": "fl--26",
              "externalID": "D000600",
              "geometry": null,
              "geoid": "1226"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "374 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-4211",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "MarioDB",
              "youtube": "MarioDiazBalart",
              "instagram": null,
              "facebook": "mdiazbalart",
              "urls": ["https://mariodiazbalart.house.gov"],
              "rss_url": "https://mariodiazbalart.house.gov/rss.xml"
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000600.json",
            "govtrack_id": "400108",
            "cspan_id": "1003562",
            "votesmart_id": "24312",
            "icpsr_id": "20316",
            "crp_id": "N00025337",
            "google_entity_id": "/m/02536z",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1961-09-25",
            "leadership_role": null,
            "fec_candidate_id": "H2FL25018",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "22",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 65,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 6.16,
            "votes_with_party_pct": 85.79,
            "votes_against_party_pct": 12.26,
            "ocd_id": "ocd-division/country:us/state:fl/cd:26",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-26--D000600": 0 }
      },
      "27": {
        "members": [
          {
            "API_ID": "S000168",
            "in_office": true,
            "firstName": "Maria",
            "lastName": "Salazar",
            "middleName": "Elvira",
            "fullName": "Maria Elvira Salazar",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S000168.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "27",
              "chamber": "",
              "hashID": "HOUSE-FL-27--S000168",
              "builtID": "fl--27",
              "externalID": "S000168",
              "geometry": null,
              "geoid": "1227"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2162 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3931",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMariaSalazar",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://salazar.house.gov"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S000168.json",
            "govtrack_id": "456810",
            "cspan_id": null,
            "votesmart_id": "182300",
            "icpsr_id": null,
            "crp_id": "N00042810",
            "google_entity_id": "/g/1hb_g7r18",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1961-11-01",
            "leadership_role": null,
            "fec_candidate_id": "H8FL27185",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 77,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 7.29,
            "votes_with_party_pct": 84.38,
            "votes_against_party_pct": 13.54,
            "ocd_id": "ocd-division/country:us/state:fl/cd:27",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-27--S000168": 0 }
      },
      "28": {
        "members": [
          {
            "API_ID": "G000593",
            "in_office": true,
            "firstName": "Carlos",
            "lastName": "Giménez",
            "middleName": null,
            "fullName": "Carlos A. Gimenez",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000593.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "28",
              "chamber": "",
              "hashID": "HOUSE-FL-28--G000593",
              "builtID": "fl--28",
              "externalID": "G000593",
              "geometry": null,
              "geoid": "1228"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "448 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2778",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepCarlos",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gimenez.house.gov"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000593.json",
            "govtrack_id": "456809",
            "cspan_id": null,
            "votesmart_id": "81366",
            "icpsr_id": null,
            "crp_id": "N00046394",
            "google_entity_id": "/m/03cc6vt",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1954-01-17",
            "leadership_role": null,
            "fec_candidate_id": "H0FL26036",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 35,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 3.31,
            "votes_with_party_pct": 89.91,
            "votes_against_party_pct": 8.09,
            "ocd_id": "ocd-division/country:us/state:fl/cd:28",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-28--G000593": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "B001314",
            "in_office": true,
            "firstName": "Aaron",
            "lastName": "Bean",
            "middleName": null,
            "fullName": "Aaron Bean",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001314.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-FL-04--B001314",
              "builtID": "fl--04",
              "externalID": "B001314",
              "geometry": null,
              "geoid": "1204"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1239 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-0123",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAaronBean",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://bean.house.gov/"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001314.json",
            "govtrack_id": "456888",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1967-01-25",
            "leadership_role": null,
            "fec_candidate_id": "H2FL04211",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 5,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.47,
            "votes_with_party_pct": 90.49,
            "votes_against_party_pct": 7.57,
            "ocd_id": "ocd-division/country:us/state:fl/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-04--B001314": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "C001039",
            "in_office": true,
            "firstName": "Kat",
            "lastName": "Cammack",
            "middleName": null,
            "fullName": "Kat Cammack",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001039.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-FL-03--C001039",
              "builtID": "fl--03",
              "externalID": "C001039",
              "geometry": null,
              "geoid": "1203"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2421 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5744",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepKatCammack",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://cammack.house.gov"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001039.json",
            "govtrack_id": "456806",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00045978",
            "google_entity_id": "/g/11fsp_lppx",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1988-02-16",
            "leadership_role": null,
            "fec_candidate_id": "H0FL03175",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 45,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 4.26,
            "votes_with_party_pct": 89.81,
            "votes_against_party_pct": 8.17,
            "ocd_id": "ocd-division/country:us/state:fl/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-03--C001039": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "D000628",
            "in_office": true,
            "firstName": "Neal",
            "lastName": "Dunn",
            "middleName": null,
            "fullName": "Neal P. Dunn",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/D000628.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-FL-02--D000628",
              "builtID": "fl--02",
              "externalID": "D000628",
              "geometry": null,
              "geoid": "1202"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "466 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5235",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "drnealdunnfl2",
              "youtube": null,
              "instagram": null,
              "facebook": "drnealdunnfl2",
              "urls": ["https://dunn.house.gov"],
              "rss_url": "https://dunn.house.gov/?a=RSS.Feed"
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000628.json",
            "govtrack_id": "412691",
            "cspan_id": "104529",
            "votesmart_id": "166297",
            "icpsr_id": "21714",
            "crp_id": "N00037442",
            "google_entity_id": "/g/11c2ld4vv7",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1953-02-16",
            "leadership_role": null,
            "fec_candidate_id": "H6FL02208",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 12,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.14,
            "votes_with_party_pct": 92.57,
            "votes_against_party_pct": 5.47,
            "ocd_id": "ocd-division/country:us/state:fl/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-02--D000628": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "G000578",
            "in_office": true,
            "firstName": "Matt",
            "lastName": "Gaetz",
            "middleName": null,
            "fullName": "Matt Gaetz",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000578.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-FL-01--G000578",
              "builtID": "fl--01",
              "externalID": "G000578",
              "geometry": null,
              "geoid": "1201"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2021 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4136",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMattGaetz",
              "youtube": null,
              "instagram": null,
              "facebook": "CongressmanMattGaetz",
              "urls": ["https://gaetz.house.gov"],
              "rss_url": "https://gaetz.house.gov/rss.xml"
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000578.json",
            "govtrack_id": "412690",
            "cspan_id": "104528",
            "votesmart_id": "117101",
            "icpsr_id": "21719",
            "crp_id": "N00039503",
            "google_entity_id": "/m/0s8w2_s",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1982-05-07",
            "leadership_role": null,
            "fec_candidate_id": "H6FL01119",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 81,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 7.67,
            "votes_with_party_pct": 82.5,
            "votes_against_party_pct": 15.73,
            "ocd_id": "ocd-division/country:us/state:fl/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-01--G000578": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "M001216",
            "in_office": true,
            "firstName": "Cory",
            "lastName": "Mills",
            "middleName": null,
            "fullName": "Cory Mills",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001216.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-FL-07--M001216",
              "builtID": "fl--07",
              "externalID": "M001216",
              "geometry": null,
              "geoid": "1207"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1237 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4035",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://mills.house.gov/"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001216.json",
            "govtrack_id": "456889",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1980-07-30",
            "leadership_role": null,
            "fec_candidate_id": "H2FL07156",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 12,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.14,
            "votes_with_party_pct": 89.05,
            "votes_against_party_pct": 8.99,
            "ocd_id": "ocd-division/country:us/state:fl/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-07--M001216": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "P000599",
            "in_office": true,
            "firstName": "Bill",
            "lastName": "Posey",
            "middleName": null,
            "fullName": "Bill Posey",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/P000599.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-FL-08--P000599",
              "builtID": "fl--08",
              "externalID": "P000599",
              "geometry": null,
              "geoid": "1208"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2150 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3671",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "CongBillPosey",
              "youtube": "CongressmanPosey",
              "instagram": null,
              "facebook": "bill.posey15",
              "urls": ["https://posey.house.gov"],
              "rss_url": "https://posey.house.gov/news/rss.aspx?DocumentTypeID=1487"
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000599.json",
            "govtrack_id": "412309",
            "cspan_id": "88959",
            "votesmart_id": "24280",
            "icpsr_id": "20909",
            "crp_id": "N00029662",
            "google_entity_id": "/m/0281vxy",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1947-12-18",
            "leadership_role": null,
            "fec_candidate_id": "H8FL15107",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "16",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 31,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 2.94,
            "votes_with_party_pct": 87.64,
            "votes_against_party_pct": 10.47,
            "ocd_id": "ocd-division/country:us/state:fl/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-08--P000599": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "R000609",
            "in_office": true,
            "firstName": "John",
            "lastName": "Rutherford",
            "middleName": null,
            "fullName": "John H. Rutherford",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/R000609.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-FL-05--R000609",
              "builtID": "fl--05",
              "externalID": "R000609",
              "geometry": null,
              "geoid": "1205"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1711 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2501",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRutherfordFL",
              "youtube": null,
              "instagram": null,
              "facebook": "RepRutherfordFL",
              "urls": ["https://rutherford.house.gov"],
              "rss_url": "https://rutherford.house.gov/rss.xml"
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/R000609.json",
            "govtrack_id": "412692",
            "cspan_id": "104531",
            "votesmart_id": "172542",
            "icpsr_id": "21744",
            "crp_id": "N00039777",
            "google_entity_id": "/m/03zz77",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1952-09-02",
            "leadership_role": null,
            "fec_candidate_id": "H6FL04105",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 7,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.66,
            "votes_with_party_pct": 92.22,
            "votes_against_party_pct": 5.84,
            "ocd_id": "ocd-division/country:us/state:fl/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-05--R000609": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "S001200",
            "in_office": true,
            "firstName": "Darren",
            "lastName": "Soto",
            "middleName": null,
            "fullName": "Darren Soto",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001200.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-FL-09--S001200",
              "builtID": "fl--09",
              "externalID": "S001200",
              "geometry": null,
              "geoid": "1209"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2353 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-9889",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDarrenSoto",
              "youtube": "repdarrensoto",
              "instagram": null,
              "facebook": "CongressmanDarrenSoto",
              "urls": ["https://soto.house.gov"],
              "rss_url": "https://soto.house.gov/rss.xml"
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001200.json",
            "govtrack_id": "412695",
            "cspan_id": "104534",
            "votesmart_id": "67618",
            "icpsr_id": "21746",
            "crp_id": "N00037422",
            "google_entity_id": "/m/05b_1xw",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1978-02-25",
            "leadership_role": null,
            "fec_candidate_id": "H6FL09179",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 7,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.66,
            "votes_with_party_pct": 94.13,
            "votes_against_party_pct": 3.95,
            "ocd_id": "ocd-division/country:us/state:fl/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-09--S001200": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "W000823",
            "in_office": true,
            "firstName": "Michael",
            "lastName": "Waltz",
            "middleName": null,
            "fullName": "Michael Waltz",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/W000823.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-FL-06--W000823",
              "builtID": "fl--06",
              "externalID": "W000823",
              "geometry": null,
              "geoid": "1206"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "244 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2706",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMichaelWaltz",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://waltz.house.gov"],
              "rss_url": null
            },
            "title": "FL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000823.json",
            "govtrack_id": "412764",
            "cspan_id": null,
            "votesmart_id": "182261",
            "icpsr_id": null,
            "crp_id": "N00042403",
            "google_entity_id": "/g/11f50xlkjm",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1974-01-31",
            "leadership_role": null,
            "fec_candidate_id": "H8FL06148",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 68,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 6.44,
            "votes_with_party_pct": 92.48,
            "votes_against_party_pct": 5.46,
            "ocd_id": "ocd-division/country:us/state:fl/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-FL-06--W000823": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/a3c0aa9f-c261-4904-b73b-4dc127778222",
            "firstName": "Michelle",
            "lastName": "Salzman",
            "fullName": "Michelle Salzman",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4763.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-FL-1-lower-ocd-person-a3c0aa9f-c261-4904-b73b-4dc127778222",
              "builtID": "fl-lower-1",
              "externalID": "ocd-person/a3c0aa9f-c261-4904-b73b-4dc127778222",
              "geometry": null
            },
            "contactInfo": {
              "email": "michelle.salzman@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3212 S. Highway 95-A, Cantonment, FL 32533",
              "phone1": null,
              "phone2": "850-471-6024",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4763&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4763&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4763"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-1-lower-ocd-person-a3c0aa9f-c261-4904-b73b-4dc127778222": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/de5ddba1-d7fe-41da-9f36-16a03b0b22fc",
            "firstName": "Robert Alexander",
            "lastName": "Andrade",
            "fullName": "Alex Andrade",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4710.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-FL-2-lower-ocd-person-de5ddba1-d7fe-41da-9f36-16a03b0b22fc",
              "builtID": "fl-lower-2",
              "externalID": "ocd-person/de5ddba1-d7fe-41da-9f36-16a03b0b22fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "alex.andrade@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "226 S. Palafox Place, Pensacola, FL 32502",
              "phone1": null,
              "phone2": "850-595-0467",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4710&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4710&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4710&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4710"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-2-lower-ocd-person-de5ddba1-d7fe-41da-9f36-16a03b0b22fc": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/08182b97-0bb9-4f69-bd18-e77fbf0582f9",
            "firstName": "Joel",
            "lastName": "Rudman",
            "fullName": "Joel Rudman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4861.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-FL-3-lower-ocd-person-08182b97-0bb9-4f69-bd18-e77fbf0582f9",
              "builtID": "fl-lower-3",
              "externalID": "ocd-person/08182b97-0bb9-4f69-bd18-e77fbf0582f9",
              "geometry": null
            },
            "contactInfo": {
              "email": "joel.rudman@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 4013, Milton Campus 5988 Highway 90, Milton, FL 32583",
              "phone1": null,
              "phone2": "850-475-4977",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4861&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4861"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-3-lower-ocd-person-08182b97-0bb9-4f69-bd18-e77fbf0582f9": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/ed56c597-3652-45a5-9c01-de0bf3eb8ab9",
            "firstName": "Thomas Patterson",
            "lastName": "Maney",
            "fullName": "Patt Maney",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4764.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-FL-4-lower-ocd-person-ed56c597-3652-45a5-9c01-de0bf3eb8ab9",
              "builtID": "fl-lower-4",
              "externalID": "ocd-person/ed56c597-3652-45a5-9c01-de0bf3eb8ab9",
              "geometry": null
            },
            "contactInfo": {
              "email": "patt.maney@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "115 Miracle Strip Parkway SE Suite 201, Fort Walton Beach, FL 32548",
              "phone1": null,
              "phone2": "850-226-5029",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4764&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4764&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4764"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-4-lower-ocd-person-ed56c597-3652-45a5-9c01-de0bf3eb8ab9": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/c844e345-f2a8-42fa-9ef9-d278bcb8fa4c",
            "firstName": "Shane G.",
            "lastName": "Abbott",
            "fullName": "Shane Abbott",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4864.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-FL-5-lower-ocd-person-c844e345-f2a8-42fa-9ef9-d278bcb8fa4c",
              "builtID": "fl-lower-5",
              "externalID": "ocd-person/c844e345-f2a8-42fa-9ef9-d278bcb8fa4c",
              "geometry": null
            },
            "contactInfo": {
              "email": "shane.abbott@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 187, Chipola College 3094 Indian Circle, Marianna, FL 32446",
              "phone1": null,
              "phone2": "850-718-0047",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4864&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4864&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-5-lower-ocd-person-c844e345-f2a8-42fa-9ef9-d278bcb8fa4c": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/25748543-5a46-49c2-bc52-f7d39c18a2e6",
            "firstName": "Phillip Wayne",
            "lastName": "Griffitts",
            "fullName": "Griff Griffitts",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4862.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-FL-6-lower-ocd-person-25748543-5a46-49c2-bc52-f7d39c18a2e6",
              "builtID": "fl-lower-6",
              "externalID": "ocd-person/25748543-5a46-49c2-bc52-f7d39c18a2e6",
              "geometry": null
            },
            "contactInfo": {
              "email": "griff.griffitts@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "840 W. 11th St. Suite 3300, Panama City, FL 32401",
              "phone1": null,
              "phone2": "850-914-6300",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4862&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4862"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-6-lower-ocd-person-25748543-5a46-49c2-bc52-f7d39c18a2e6": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/2bae38cc-f703-446b-93ec-576c0e388760",
            "firstName": "Jason S.",
            "lastName": "Shoaf",
            "fullName": "Jason Shoaf",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4756.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-FL-7-lower-ocd-person-2bae38cc-f703-446b-93ec-576c0e388760",
              "builtID": "fl-lower-7",
              "externalID": "ocd-person/2bae38cc-f703-446b-93ec-576c0e388760",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.shoaf@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "103 E. Ellis St., Perry, FL 32347",
              "phone1": null,
              "phone2": "850-295-5680",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4756&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4756&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4756"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-7-lower-ocd-person-2bae38cc-f703-446b-93ec-576c0e388760": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/3c9e6522-75aa-40d0-870c-1792d5178b04",
            "firstName": "Gallop P.",
            "lastName": "Franklin",
            "fullName": "Gallop Franklin",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4863.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-FL-8-lower-ocd-person-3c9e6522-75aa-40d0-870c-1792d5178b04",
              "builtID": "fl-lower-8",
              "externalID": "ocd-person/3c9e6522-75aa-40d0-870c-1792d5178b04",
              "geometry": null
            },
            "contactInfo": {
              "email": "gallop.franklin@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4863&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4863&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-8-lower-ocd-person-3c9e6522-75aa-40d0-870c-1792d5178b04": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/c26599eb-5ef8-44c3-93a6-77cf23a9567e",
            "firstName": "Allison",
            "lastName": "Tant",
            "fullName": "Allison Tant",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4765.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-FL-9-lower-ocd-person-c26599eb-5ef8-44c3-93a6-77cf23a9567e",
              "builtID": "fl-lower-9",
              "externalID": "ocd-person/c26599eb-5ef8-44c3-93a6-77cf23a9567e",
              "geometry": null
            },
            "contactInfo": {
              "email": "allison.tant@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4765&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4765&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4765"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-9-lower-ocd-person-c26599eb-5ef8-44c3-93a6-77cf23a9567e": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/caf95c6f-3143-433e-8654-c17d39b94f88",
            "firstName": "Robert Charles",
            "lastName": "Brannan",
            "fullName": "Chuck Brannan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4708.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-FL-10-lower-ocd-person-caf95c6f-3143-433e-8654-c17d39b94f88",
              "builtID": "fl-lower-10",
              "externalID": "ocd-person/caf95c6f-3143-433e-8654-c17d39b94f88",
              "geometry": null
            },
            "contactInfo": {
              "email": "chuck.brannan@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Baker County Courthouse 339 E. Macclenny Ave., Suite 117, Macclenny, FL 32063",
              "phone1": null,
              "phone2": "904-259-0995",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4708&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4708&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4708&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4708"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-10-lower-ocd-person-caf95c6f-3143-433e-8654-c17d39b94f88": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/965e180a-177f-49ac-baba-0f0c7fe9a836",
            "firstName": "Sam",
            "lastName": "Garrison",
            "fullName": "Sam Garrison",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4767.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-FL-11-lower-ocd-person-965e180a-177f-49ac-baba-0f0c7fe9a836",
              "builtID": "fl-lower-11",
              "externalID": "ocd-person/965e180a-177f-49ac-baba-0f0c7fe9a836",
              "geometry": null
            },
            "contactInfo": {
              "email": "sam.garrison@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1835 EW Parkway Suite 18, Fleming Island, FL 32003",
              "phone1": null,
              "phone2": "904-278-2062",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4767&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4767&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4767"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-11-lower-ocd-person-965e180a-177f-49ac-baba-0f0c7fe9a836": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/17ae3cd7-1aa0-44b4-baab-ed839b6776a6",
            "firstName": "Wyman",
            "lastName": "Duggan",
            "fullName": "Wyman Duggan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4712.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-FL-12-lower-ocd-person-17ae3cd7-1aa0-44b4-baab-ed839b6776a6",
              "builtID": "fl-lower-12",
              "externalID": "ocd-person/17ae3cd7-1aa0-44b4-baab-ed839b6776a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "wyman.duggan@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4070 Herschel St. Unit 6, Jacksonville, FL 32210",
              "phone1": null,
              "phone2": "904-381-6011",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4712&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4712"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-12-lower-ocd-person-17ae3cd7-1aa0-44b4-baab-ed839b6776a6": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/66c3a112-3008-4332-a6b0-8990a7d992d5",
            "firstName": "Angela",
            "lastName": "Nixon",
            "fullName": "Angie Nixon",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4766.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-FL-13-lower-ocd-person-66c3a112-3008-4332-a6b0-8990a7d992d5",
              "builtID": "fl-lower-13",
              "externalID": "ocd-person/66c3a112-3008-4332-a6b0-8990a7d992d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "angela.nixon@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3939 Roosevelt Blvd. Suite G-121, Jacksonville, FL 32205",
              "phone1": null,
              "phone2": "904-381-6013",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4766&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4766&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4766"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-13-lower-ocd-person-66c3a112-3008-4332-a6b0-8990a7d992d5": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/469b8c3d-5133-43c1-8598-b63ea936f135",
            "firstName": "Kimberly Maria",
            "lastName": "Daniels",
            "fullName": "Kim Daniels",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4652.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-FL-14-lower-ocd-person-469b8c3d-5133-43c1-8598-b63ea936f135",
              "builtID": "fl-lower-14",
              "externalID": "ocd-person/469b8c3d-5133-43c1-8598-b63ea936f135",
              "geometry": null
            },
            "contactInfo": {
              "email": "kimberly.daniels@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1225 Beaver St. West Suite 104, Jacksonville, FL 32204",
              "phone1": null,
              "phone2": "904-359-2533",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4652&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4652"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-14-lower-ocd-person-469b8c3d-5133-43c1-8598-b63ea936f135": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/c082de7e-0a73-43a6-94e7-48180a1af3d6",
            "firstName": "Dean Adam",
            "lastName": "Black",
            "fullName": "Dean Black",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4865.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-FL-15-lower-ocd-person-c082de7e-0a73-43a6-94e7-48180a1af3d6",
              "builtID": "fl-lower-15",
              "externalID": "ocd-person/c082de7e-0a73-43a6-94e7-48180a1af3d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "dean.black@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1-071, 76347 Veterans Way, Yulee, FL 32097",
              "phone1": null,
              "phone2": "904-548-4920",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4865&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4865&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-15-lower-ocd-person-c082de7e-0a73-43a6-94e7-48180a1af3d6": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/efa5f0b9-e10e-4ae0-a352-db879b1e0d3b",
            "firstName": "Kiyan",
            "lastName": "Michael",
            "fullName": "Kiyan Michael",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4869.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-FL-16-lower-ocd-person-efa5f0b9-e10e-4ae0-a352-db879b1e0d3b",
              "builtID": "fl-lower-16",
              "externalID": "ocd-person/efa5f0b9-e10e-4ae0-a352-db879b1e0d3b",
              "geometry": null
            },
            "contactInfo": {
              "email": "kiyan.michael@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "421 3rd St. North, Jacksonville Beach, FL 32250",
              "phone1": null,
              "phone2": "904-242-3495",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4869&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4869&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-16-lower-ocd-person-efa5f0b9-e10e-4ae0-a352-db879b1e0d3b": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/8dd703e2-8024-47c5-a22b-87a36db841cb",
            "firstName": "Jessica",
            "lastName": "Baker",
            "fullName": "Jessica Baker",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4866.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-FL-17-lower-ocd-person-8dd703e2-8024-47c5-a22b-87a36db841cb",
              "builtID": "fl-lower-17",
              "externalID": "ocd-person/8dd703e2-8024-47c5-a22b-87a36db841cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "jessica.baker@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "8833 Perimeter Park Blvd. Suite 801, Jacksonville, FL 32216",
              "phone1": null,
              "phone2": "904-928-1217",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4866&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4866&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-17-lower-ocd-person-8dd703e2-8024-47c5-a22b-87a36db841cb": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/cefe376e-b24a-456f-ac02-e95c4b6efcb9",
            "firstName": "Cynthia Ward",
            "lastName": "Stevenson",
            "fullName": "Cyndi Stevenson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4620.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-FL-18-lower-ocd-person-cefe376e-b24a-456f-ac02-e95c4b6efcb9",
              "builtID": "fl-lower-18",
              "externalID": "ocd-person/cefe376e-b24a-456f-ac02-e95c4b6efcb9",
              "geometry": null
            },
            "contactInfo": {
              "email": "cyndi.stevenson@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "309 Kingsley Lake Drive Suite 902, St. Augustine, FL 32092",
              "phone1": null,
              "phone2": "904-823-2300",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4620&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4620&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4620&LegislativeTermId=90",
                "https://www.myfloridahouse.gov//Sections/Representatives/details.aspx?MemberId=4620"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-18-lower-ocd-person-cefe376e-b24a-456f-ac02-e95c4b6efcb9": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/c3506369-635e-45f6-ae79-054d9bcbcd52",
            "firstName": "Paul Marvin",
            "lastName": "Renner",
            "fullName": "Paul Renner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4619.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-FL-19-lower-ocd-person-c3506369-635e-45f6-ae79-054d9bcbcd52",
              "builtID": "fl-lower-19",
              "externalID": "ocd-person/c3506369-635e-45f6-ae79-054d9bcbcd52",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.renner@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4877 Palm Coast Parkway NW Suite 1, Palm Coast, FL 32137",
              "phone1": null,
              "phone2": "386-446-7644",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4619&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4619&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4619&LegislativeTermId=90",
                "https://www.myfloridahouse.gov//Sections/Representatives/details.aspx?MemberId=4619"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-19-lower-ocd-person-c3506369-635e-45f6-ae79-054d9bcbcd52": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/abb34aa1-49fe-4c5c-bd07-7218ad0a619a",
            "firstName": "Bobby",
            "lastName": "Payne",
            "fullName": "Bobby Payne",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4653.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-FL-20-lower-ocd-person-abb34aa1-49fe-4c5c-bd07-7218ad0a619a",
              "builtID": "fl-lower-20",
              "externalID": "ocd-person/abb34aa1-49fe-4c5c-bd07-7218ad0a619a",
              "geometry": null
            },
            "contactInfo": {
              "email": "bobby.payne@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3841 Reid St. Suite 5, Palatka, FL 32177",
              "phone1": null,
              "phone2": "386-312-2272",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4653&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4653&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4653&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4653"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-20-lower-ocd-person-abb34aa1-49fe-4c5c-bd07-7218ad0a619a": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/0f5d2710-6b7c-4444-8a45-ea3e917805ba",
            "firstName": "Yvonne Hayes",
            "lastName": "Hinson",
            "fullName": "Yvonne Hinson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4768.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-FL-21-lower-ocd-person-0f5d2710-6b7c-4444-8a45-ea3e917805ba",
              "builtID": "fl-lower-21",
              "externalID": "ocd-person/0f5d2710-6b7c-4444-8a45-ea3e917805ba",
              "geometry": null
            },
            "contactInfo": {
              "email": "yvonne.hinson@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2815 NW 13th St. Suite 202, Gainesville, FL 32609",
              "phone1": null,
              "phone2": "352-264-4001",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4768&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4768&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4768"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-21-lower-ocd-person-0f5d2710-6b7c-4444-8a45-ea3e917805ba": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/80d93f41-e2ec-401d-8a60-ac98b09e82a0",
            "firstName": "Charles Wesley",
            "lastName": "Clemons",
            "fullName": "Chuck Clemons",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4655.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-FL-22-lower-ocd-person-80d93f41-e2ec-401d-8a60-ac98b09e82a0",
              "builtID": "fl-lower-22",
              "externalID": "ocd-person/80d93f41-e2ec-401d-8a60-ac98b09e82a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "chuck.clemons@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "105 SW 140th Court Suite 1, Newberry, FL 32669",
              "phone1": null,
              "phone2": "352-313-6542",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4655&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4655&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4655&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4655"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-22-lower-ocd-person-80d93f41-e2ec-401d-8a60-ac98b09e82a0": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/6d1623ae-0b24-4007-9c0f-af352ea22180",
            "firstName": "Ralph E.",
            "lastName": "Massullo",
            "fullName": "Ralph Massullo",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4624.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-FL-23-lower-ocd-person-6d1623ae-0b24-4007-9c0f-af352ea22180",
              "builtID": "fl-lower-23",
              "externalID": "ocd-person/6d1623ae-0b24-4007-9c0f-af352ea22180",
              "geometry": null
            },
            "contactInfo": {
              "email": "ralph.massullo@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "106 E. Dampier St., Inverness, FL 34450",
              "phone1": null,
              "phone2": "352-527-4510",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4624&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4624&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4624&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4624",
                "https://ralphmassullo.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-23-lower-ocd-person-6d1623ae-0b24-4007-9c0f-af352ea22180": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/baa03ffb-0948-4975-9e3a-c3fb9c0fcf40",
            "firstName": "Ryan D.",
            "lastName": "Chamberlin",
            "fullName": "Ryan Chamberlin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4901.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-FL-24-lower-ocd-person-baa03ffb-0948-4975-9e3a-c3fb9c0fcf40",
              "builtID": "fl-lower-24",
              "externalID": "ocd-person/baa03ffb-0948-4975-9e3a-c3fb9c0fcf40",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryan.chamberlin@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3001 SW College Road Suite 104, Ocala, FL 34474",
              "phone1": null,
              "phone2": "352-291-4436",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4901&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-24-lower-ocd-person-baa03ffb-0948-4975-9e3a-c3fb9c0fcf40": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/4ba15ff2-a717-4ea5-ad72-34e673ef63d8",
            "firstName": "Taylor Michael",
            "lastName": "Yarkosky",
            "fullName": "Taylor Yarkosky",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4868.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-FL-25-lower-ocd-person-4ba15ff2-a717-4ea5-ad72-34e673ef63d8",
              "builtID": "fl-lower-25",
              "externalID": "ocd-person/4ba15ff2-a717-4ea5-ad72-34e673ef63d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "taylor.yarkosky@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "16207 State Road 50 Suite 402, Clermont, FL 34711",
              "phone1": null,
              "phone2": "352-404-0003",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4868&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4868"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-25-lower-ocd-person-4ba15ff2-a717-4ea5-ad72-34e673ef63d8": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/9c87088c-e5a4-4902-b3cb-4477e062de66",
            "firstName": "Keith L.",
            "lastName": "Truenow",
            "fullName": "Keith Truenow",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4772.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-FL-26-lower-ocd-person-9c87088c-e5a4-4902-b3cb-4477e062de66",
              "builtID": "fl-lower-26",
              "externalID": "ocd-person/9c87088c-e5a4-4902-b3cb-4477e062de66",
              "geometry": null
            },
            "contactInfo": {
              "email": "keith.truenow@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1951 Woodlea Road, Tavares, FL 32778",
              "phone1": null,
              "phone2": "352-742-6275",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4772&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4772&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4772"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-26-lower-ocd-person-9c87088c-e5a4-4902-b3cb-4477e062de66": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/5844fc0a-b2d2-4a09-baa7-6774e684326a",
            "firstName": "Stan",
            "lastName": "McClain",
            "fullName": "Stan McClain",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4658.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-FL-27-lower-ocd-person-5844fc0a-b2d2-4a09-baa7-6774e684326a",
              "builtID": "fl-lower-27",
              "externalID": "ocd-person/5844fc0a-b2d2-4a09-baa7-6774e684326a",
              "geometry": null
            },
            "contactInfo": {
              "email": "stan.mcclain@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "315 SE 25th Ave., Ocala, FL 34471",
              "phone1": null,
              "phone2": "352-732-1313",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4658&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4658&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4658&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4658"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-27-lower-ocd-person-5844fc0a-b2d2-4a09-baa7-6774e684326a": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/04066eef-919c-4dc9-a655-ebc5e185f0c7",
            "firstName": "Thomas J.",
            "lastName": "Leek",
            "fullName": "Tom Leek",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4662.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-FL-28-lower-ocd-person-04066eef-919c-4dc9-a655-ebc5e185f0c7",
              "builtID": "fl-lower-28",
              "externalID": "ocd-person/04066eef-919c-4dc9-a655-ebc5e185f0c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.leek@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "780 W. Granada Blvd., Ormond Beach, FL 32174",
              "phone1": null,
              "phone2": "386-944-1980",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4662&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4662"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-28-lower-ocd-person-04066eef-919c-4dc9-a655-ebc5e185f0c7": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/f2b23850-7feb-484e-8ed2-68e2f6108fbf",
            "firstName": "Webster",
            "lastName": "Barnaby",
            "fullName": "Webster Barnaby",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4770.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-FL-29-lower-ocd-person-f2b23850-7feb-484e-8ed2-68e2f6108fbf",
              "builtID": "fl-lower-29",
              "externalID": "ocd-person/f2b23850-7feb-484e-8ed2-68e2f6108fbf",
              "geometry": null
            },
            "contactInfo": {
              "email": "webster.barnaby@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "120 S. Florida Ave., Deland, FL 32720",
              "phone1": null,
              "phone2": "386-736-5042",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4770&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4770&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4770"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-29-lower-ocd-person-f2b23850-7feb-484e-8ed2-68e2f6108fbf": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/47cd160b-1e9e-469c-a939-002be336302f",
            "firstName": "Chase",
            "lastName": "Tramont",
            "fullName": "Chase Tramont",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4867.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-FL-30-lower-ocd-person-47cd160b-1e9e-469c-a939-002be336302f",
              "builtID": "fl-lower-30",
              "externalID": "ocd-person/47cd160b-1e9e-469c-a939-002be336302f",
              "geometry": null
            },
            "contactInfo": {
              "email": "chase.tramont@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5889 S. Williamson Blvd. Suite 201D, Port Orange, FL 32128",
              "phone1": null,
              "phone2": "386-944-1010",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4867&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4867"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-30-lower-ocd-person-47cd160b-1e9e-469c-a939-002be336302f": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/ac26aaa6-1f59-4893-ae7f-30f5d2e5d8fc",
            "firstName": "Tyler I.",
            "lastName": "Sirois",
            "fullName": "Tyler Sirois",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4748.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-FL-31-lower-ocd-person-ac26aaa6-1f59-4893-ae7f-30f5d2e5d8fc",
              "builtID": "fl-lower-31",
              "externalID": "ocd-person/ac26aaa6-1f59-4893-ae7f-30f5d2e5d8fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "tyler.sirois@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "260 N. Tropical Trail Suite 206, Merritt Island, FL 32953",
              "phone1": null,
              "phone2": "321-449-5111",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4748&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4748&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4748&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4748"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-31-lower-ocd-person-ac26aaa6-1f59-4893-ae7f-30f5d2e5d8fc": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/24c94be4-a2b4-4efc-b3cd-4ef4b8bc4224",
            "firstName": "Thad",
            "lastName": "Altman",
            "fullName": "Thad Altman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4329.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-FL-32-lower-ocd-person-24c94be4-a2b4-4efc-b3cd-4ef4b8bc4224",
              "builtID": "fl-lower-32",
              "externalID": "ocd-person/24c94be4-a2b4-4efc-b3cd-4ef4b8bc4224",
              "geometry": null
            },
            "contactInfo": {
              "email": "thad.altman@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "135A 5th Ave., Indialantic, FL 32903",
              "phone1": null,
              "phone2": "321-499-3100",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4329&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4329"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-32-lower-ocd-person-24c94be4-a2b4-4efc-b3cd-4ef4b8bc4224": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/24414448-9244-4c72-bf3f-1930103b746d",
            "firstName": "Randall Adam",
            "lastName": "Fine",
            "fullName": "Randy Fine",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4646.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-FL-33-lower-ocd-person-24414448-9244-4c72-bf3f-1930103b746d",
              "builtID": "fl-lower-33",
              "externalID": "ocd-person/24414448-9244-4c72-bf3f-1930103b746d",
              "geometry": null
            },
            "contactInfo": {
              "email": "randy.fine@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2539 Palm Bay Road NE Suite 5, Palm Bay, FL 32905",
              "phone1": null,
              "phone2": "321-409-2017",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4646&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4646"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-33-lower-ocd-person-24414448-9244-4c72-bf3f-1930103b746d": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/c63b8e5e-fd63-45a1-b7e9-16595039f34a",
            "firstName": "Robert A.",
            "lastName": "Brackett",
            "fullName": "Robbie Brackett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4870.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-FL-34-lower-ocd-person-c63b8e5e-fd63-45a1-b7e9-16595039f34a",
              "builtID": "fl-lower-34",
              "externalID": "ocd-person/c63b8e5e-fd63-45a1-b7e9-16595039f34a",
              "geometry": null
            },
            "contactInfo": {
              "email": "robert.brackett@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1801 27th St. Suite B2-203, Vero Beach, FL 32960",
              "phone1": null,
              "phone2": "772-778-5005",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4870&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4870&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-34-lower-ocd-person-c63b8e5e-fd63-45a1-b7e9-16595039f34a": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/05a380a1-14c4-4e3b-93bc-cbe93d64c3e3",
            "firstName": "Charles Thomas",
            "lastName": "Keen",
            "fullName": "Tom Keen",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-FL-35-lower-ocd-person-05a380a1-14c4-4e3b-93bc-cbe93d64c3e3",
              "builtID": "fl-lower-35",
              "externalID": "ocd-person/05a380a1-14c4-4e3b-93bc-cbe93d64c3e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.keen@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "13000 Avalon Lake Drive Suite 208, Orlando, FL 32301",
              "phone1": null,
              "phone2": "407-249-4204",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4903&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-35-lower-ocd-person-05a380a1-14c4-4e3b-93bc-cbe93d64c3e3": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/1b25c111-e59b-429e-a6b7-fd22a38c1581",
            "firstName": "Rachel Lora Saunders",
            "lastName": "Plakon",
            "fullName": "Rachel Plakon",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4871.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-FL-36-lower-ocd-person-1b25c111-e59b-429e-a6b7-fd22a38c1581",
              "builtID": "fl-lower-36",
              "externalID": "ocd-person/1b25c111-e59b-429e-a6b7-fd22a38c1581",
              "geometry": null
            },
            "contactInfo": {
              "email": "rachel.plakon@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1855 W. State Road 434 Suite 222, Longwood, FL 32750",
              "phone1": null,
              "phone2": "407-263-3931",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4871&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4871"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-36-lower-ocd-person-1b25c111-e59b-429e-a6b7-fd22a38c1581": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/a5af681e-9679-4fed-83a3-ed16405055cb",
            "firstName": "Susan",
            "lastName": "Plasencia",
            "fullName": "Susan Plasencia",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4872.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-FL-37-lower-ocd-person-a5af681e-9679-4fed-83a3-ed16405055cb",
              "builtID": "fl-lower-37",
              "externalID": "ocd-person/a5af681e-9679-4fed-83a3-ed16405055cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.plasencia@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7457 Aloma Ave. Suite 304, Winter Park, FL 32792",
              "phone1": null,
              "phone2": "407-623-1305",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4872&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4872&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-37-lower-ocd-person-a5af681e-9679-4fed-83a3-ed16405055cb": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/e4bf077a-8468-49a6-a75c-24ee5076352e",
            "firstName": "David Allen",
            "lastName": "Smith",
            "fullName": "Dave Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4718.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-FL-38-lower-ocd-person-e4bf077a-8468-49a6-a75c-24ee5076352e",
              "builtID": "fl-lower-38",
              "externalID": "ocd-person/e4bf077a-8468-49a6-a75c-24ee5076352e",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.smith@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1511 E. State Road 434 #266, Suite 2001, Winter Springs, FL 32708",
              "phone1": null,
              "phone2": "407-971-3570",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4718&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4718&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4718&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4718"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-38-lower-ocd-person-e4bf077a-8468-49a6-a75c-24ee5076352e": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/c585b9bb-83d4-4d01-8a02-778578cfe2d6",
            "firstName": "Douglas Michael",
            "lastName": "Bankson",
            "fullName": "Doug Bankson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4873.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-FL-39-lower-ocd-person-c585b9bb-83d4-4d01-8a02-778578cfe2d6",
              "builtID": "fl-lower-39",
              "externalID": "ocd-person/c585b9bb-83d4-4d01-8a02-778578cfe2d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "doug.bankson@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "10 N. Park Ave., Apopka, FL 32703",
              "phone1": null,
              "phone2": "407-464-3334",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4873&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4873&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-39-lower-ocd-person-c585b9bb-83d4-4d01-8a02-778578cfe2d6": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/2f86f22f-b277-4441-b322-b1fbfb017b74",
            "firstName": "LaVon",
            "lastName": "Bracy Davis",
            "fullName": "LaVon Bracy Davis",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4874.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-FL-40-lower-ocd-person-2f86f22f-b277-4441-b322-b1fbfb017b74",
              "builtID": "fl-lower-40",
              "externalID": "ocd-person/2f86f22f-b277-4441-b322-b1fbfb017b74",
              "geometry": null
            },
            "contactInfo": {
              "email": "lavon.bracydavis@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "The Peachtree Building 425 W. Colonial Drive, Suite 201, Orlando, FL 32804",
              "phone1": null,
              "phone2": "407-245-0010",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4874&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4874&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-40-lower-ocd-person-2f86f22f-b277-4441-b322-b1fbfb017b74": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/617888c1-3369-49cd-a264-18ab663d2e8a",
            "firstName": "Bruce Hadley",
            "lastName": "Antone",
            "fullName": "Bruce Antone",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4269.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-FL-41-lower-ocd-person-617888c1-3369-49cd-a264-18ab663d2e8a",
              "builtID": "fl-lower-41",
              "externalID": "ocd-person/617888c1-3369-49cd-a264-18ab663d2e8a",
              "geometry": null
            },
            "contactInfo": {
              "email": "bruce.antone@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1003 S. Kirkman Road Suite 201, Orlando, FL 32811",
              "phone1": null,
              "phone2": "407-297-2001",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4269&LegislativeTermId=90",
                "https://www.myfloridahouse.gov//Sections/Representatives/details.aspx?MemberId=4269"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-41-lower-ocd-person-617888c1-3369-49cd-a264-18ab663d2e8a": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/1f8e10ad-0047-4854-b696-38e15fda7afa",
            "firstName": "Anna Vishkaee",
            "lastName": "Eskamani",
            "fullName": "Anna Eskamani",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4746.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-FL-42-lower-ocd-person-1f8e10ad-0047-4854-b696-38e15fda7afa",
              "builtID": "fl-lower-42",
              "externalID": "ocd-person/1f8e10ad-0047-4854-b696-38e15fda7afa",
              "geometry": null
            },
            "contactInfo": {
              "email": "anna.eskamani@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1507 E. Concord St., Orlando, FL 32803",
              "phone1": null,
              "phone2": "407-228-1451",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4746&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4746&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4746&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4746"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-42-lower-ocd-person-1f8e10ad-0047-4854-b696-38e15fda7afa": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/fa6bc3c9-a107-4954-adf1-e1461e9f3520",
            "firstName": "Johanna",
            "lastName": "Lopez",
            "fullName": "Joy López",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4875.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-FL-43-lower-ocd-person-fa6bc3c9-a107-4954-adf1-e1461e9f3520",
              "builtID": "fl-lower-43",
              "externalID": "ocd-person/fa6bc3c9-a107-4954-adf1-e1461e9f3520",
              "geometry": null
            },
            "contactInfo": {
              "email": "johanna.lopez@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5575 S. Semoran Blvd. Suite 2, Orlando, FL 32822",
              "phone1": null,
              "phone2": "407-362-1113",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4875&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4875&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-43-lower-ocd-person-fa6bc3c9-a107-4954-adf1-e1461e9f3520": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/ca476441-7c7a-4f21-ba55-207c21a7d51d",
            "firstName": "Jennifer Rita",
            "lastName": "Harris",
            "fullName": "Rita Harris",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4877.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-FL-44-lower-ocd-person-ca476441-7c7a-4f21-ba55-207c21a7d51d",
              "builtID": "fl-lower-44",
              "externalID": "ocd-person/ca476441-7c7a-4f21-ba55-207c21a7d51d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rita.harris@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2151 Consulate Drive Suite 15-3, Orlando, FL 32837",
              "phone1": null,
              "phone2": "407-237-9820",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4877&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4877&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-44-lower-ocd-person-ca476441-7c7a-4f21-ba55-207c21a7d51d": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/236df684-05e5-43f3-ab2f-c6580425b3e0",
            "firstName": "Carolina",
            "lastName": "Amesty",
            "fullName": "Carolina Amesty",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4876.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-FL-45-lower-ocd-person-236df684-05e5-43f3-ab2f-c6580425b3e0",
              "builtID": "fl-lower-45",
              "externalID": "ocd-person/236df684-05e5-43f3-ab2f-c6580425b3e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "carolina.amesty@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6735 Conroy Road Suite 421, Orlando, FL 32835",
              "phone1": null,
              "phone2": "407-271-4011",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4876&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4876&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-45-lower-ocd-person-236df684-05e5-43f3-ab2f-c6580425b3e0": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/e4a84025-e4fd-4d36-8e12-64bd9f61ed6d",
            "firstName": "Kristen Aston",
            "lastName": "Arrington",
            "fullName": "Kristen Arrington",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4774.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-FL-46-lower-ocd-person-e4a84025-e4fd-4d36-8e12-64bd9f61ed6d",
              "builtID": "fl-lower-46",
              "externalID": "ocd-person/e4a84025-e4fd-4d36-8e12-64bd9f61ed6d",
              "geometry": null
            },
            "contactInfo": {
              "email": "kristen.arrington@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 219, 3 Courthouse Square, Kissimmee, FL 34741",
              "phone1": null,
              "phone2": "407-846-5016",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4774&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4774"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-46-lower-ocd-person-e4a84025-e4fd-4d36-8e12-64bd9f61ed6d": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/0a0ec0a3-4f1d-4cd8-b790-d51bff717798",
            "firstName": "Paula A.",
            "lastName": "Stark",
            "fullName": "Paula Stark",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4878.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-FL-47-lower-ocd-person-0a0ec0a3-4f1d-4cd8-b790-d51bff717798",
              "builtID": "fl-lower-47",
              "externalID": "ocd-person/0a0ec0a3-4f1d-4cd8-b790-d51bff717798",
              "geometry": null
            },
            "contactInfo": {
              "email": "paula.stark@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1930 Fortune Road, Kissimmee, FL 34744",
              "phone1": null,
              "phone2": "407-846-5150",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4878&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4878"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-47-lower-ocd-person-0a0ec0a3-4f1d-4cd8-b790-d51bff717798": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/1f7b89f4-f253-4287-9edd-a14dc20f00e3",
            "firstName": "Sam H.",
            "lastName": "Killebrew",
            "fullName": "Sam Killebrew",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4644.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-FL-48-lower-ocd-person-1f7b89f4-f253-4287-9edd-a14dc20f00e3",
              "builtID": "fl-lower-48",
              "externalID": "ocd-person/1f7b89f4-f253-4287-9edd-a14dc20f00e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "sam.killebrew@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "337 Avenue C SW, Winter Haven, FL 33880",
              "phone1": null,
              "phone2": "863-291-5254",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4644&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4644&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4644&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4644"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-48-lower-ocd-person-1f7b89f4-f253-4287-9edd-a14dc20f00e3": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/90758296-368c-4352-ac00-0db0d77fcf75",
            "firstName": "Melony Mincey",
            "lastName": "Bell",
            "fullName": "Melony Bell",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4753.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-FL-49-lower-ocd-person-90758296-368c-4352-ac00-0db0d77fcf75",
              "builtID": "fl-lower-49",
              "externalID": "ocd-person/90758296-368c-4352-ac00-0db0d77fcf75",
              "geometry": null
            },
            "contactInfo": {
              "email": "melony.bell@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "107 W. Broadway St. Suite B, Fort Meade, FL 33841",
              "phone1": null,
              "phone2": "863-285-1101",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4753&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4753"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-49-lower-ocd-person-90758296-368c-4352-ac00-0db0d77fcf75": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/7fb5a540-2c3c-4661-8495-2e0e02ab8644",
            "firstName": "Jennifer H.",
            "lastName": "Canady",
            "fullName": "Jennifer Canady",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4879.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-FL-50-lower-ocd-person-7fb5a540-2c3c-4661-8495-2e0e02ab8644",
              "builtID": "fl-lower-50",
              "externalID": "ocd-person/7fb5a540-2c3c-4661-8495-2e0e02ab8644",
              "geometry": null
            },
            "contactInfo": {
              "email": "jennifer.canady@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2033 E. Edgewood Drive Suite 1, Lakeland, FL 33803",
              "phone1": null,
              "phone2": "863-450-4795",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4879&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4879&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-50-lower-ocd-person-7fb5a540-2c3c-4661-8495-2e0e02ab8644": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/3f87732e-d7d9-4a1a-bee5-9a231758a474",
            "firstName": "Josie",
            "lastName": "Tomkow",
            "fullName": "Josie Tomkow",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4704.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-FL-51-lower-ocd-person-3f87732e-d7d9-4a1a-bee5-9a231758a474",
              "builtID": "fl-lower-51",
              "externalID": "ocd-person/3f87732e-d7d9-4a1a-bee5-9a231758a474",
              "geometry": null
            },
            "contactInfo": {
              "email": "josie.tomkow@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "209 Palmetto St., Auburndale, FL 33823",
              "phone1": null,
              "phone2": "863-292-7003",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4704&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4704&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4704&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4704"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-51-lower-ocd-person-3f87732e-d7d9-4a1a-bee5-9a231758a474": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/4bade878-8949-4f35-98f8-4d205b40dbe4",
            "firstName": "John Paul",
            "lastName": "Temple",
            "fullName": "John Temple",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4880.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-FL-52-lower-ocd-person-4bade878-8949-4f35-98f8-4d205b40dbe4",
              "builtID": "fl-lower-52",
              "externalID": "ocd-person/4bade878-8949-4f35-98f8-4d205b40dbe4",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.temple@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "916 Avenida Central, The Villages, FL 32159",
              "phone1": null,
              "phone2": "352-315-4445",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4880&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4880&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-52-lower-ocd-person-4bade878-8949-4f35-98f8-4d205b40dbe4": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/5bd19ebd-e225-49a5-b0f5-42f6380a9fa8",
            "firstName": "Jeff",
            "lastName": "Holcomb",
            "fullName": "Jeff Holcomb",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4890.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-FL-53-lower-ocd-person-5bd19ebd-e225-49a5-b0f5-42f6380a9fa8",
              "builtID": "fl-lower-53",
              "externalID": "ocd-person/5bd19ebd-e225-49a5-b0f5-42f6380a9fa8",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.holcomb@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2955 Landover Blvd., Spring Hill, FL 34608",
              "phone1": null,
              "phone2": "352-688-5004",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4890&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4890&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-53-lower-ocd-person-5bd19ebd-e225-49a5-b0f5-42f6380a9fa8": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/2a415c08-da66-402b-ada6-93390a5df820",
            "firstName": "Randall Scott",
            "lastName": "Maggard",
            "fullName": "Randy Maggard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4758.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-FL-54-lower-ocd-person-2a415c08-da66-402b-ada6-93390a5df820",
              "builtID": "fl-lower-54",
              "externalID": "ocd-person/2a415c08-da66-402b-ada6-93390a5df820",
              "geometry": null
            },
            "contactInfo": {
              "email": "randy.maggard@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Creekside Center 35358 State Road 54, Zephyrhills, FL 33541",
              "phone1": null,
              "phone2": "813-780-0667",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4758&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4758&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4758"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-54-lower-ocd-person-2a415c08-da66-402b-ada6-93390a5df820": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/7cf27467-9070-4d4a-9a45-24bfe980dfb8",
            "firstName": "Kevin M.",
            "lastName": "Steele",
            "fullName": "Kevin Steele",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4881.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-FL-55-lower-ocd-person-7cf27467-9070-4d4a-9a45-24bfe980dfb8",
              "builtID": "fl-lower-55",
              "externalID": "ocd-person/7cf27467-9070-4d4a-9a45-24bfe980dfb8",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.steele@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "8225 State Road 52, Hudson, FL 34667",
              "phone1": null,
              "phone2": "727-861-1898",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4881&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4881"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-55-lower-ocd-person-7cf27467-9070-4d4a-9a45-24bfe980dfb8": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/8d746fc8-370b-4d12-bcf8-3dd4a4061707",
            "firstName": "Bradford Troy",
            "lastName": "Yeager",
            "fullName": "Brad Yeager",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4882.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-FL-56-lower-ocd-person-8d746fc8-370b-4d12-bcf8-3dd4a4061707",
              "builtID": "fl-lower-56",
              "externalID": "ocd-person/8d746fc8-370b-4d12-bcf8-3dd4a4061707",
              "geometry": null
            },
            "contactInfo": {
              "email": "brad.yeager@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5509 Grand Blvd. Suite 200, New Port Richey, FL 34652",
              "phone1": null,
              "phone2": "727-841-6977",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4882&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4882"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-56-lower-ocd-person-8d746fc8-370b-4d12-bcf8-3dd4a4061707": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/6a3fae94-a613-49f5-acec-355e4a2722d8",
            "firstName": "Adam",
            "lastName": "Anderson",
            "fullName": "Adam Anderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4885.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-FL-57-lower-ocd-person-6a3fae94-a613-49f5-acec-355e4a2722d8",
              "builtID": "fl-lower-57",
              "externalID": "ocd-person/6a3fae94-a613-49f5-acec-355e4a2722d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "adam.anderson@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "682 E. Klosterman Road Suite BB038, Tarpon Springs, FL 34689",
              "phone1": null,
              "phone2": "727-722-7000",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4885&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4885&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-57-lower-ocd-person-6a3fae94-a613-49f5-acec-355e4a2722d8": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/d6c0ddf5-2821-4510-83d1-da9eb422739a",
            "firstName": "Kimberly",
            "lastName": "Berfield",
            "fullName": "Kim Berfield",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4210.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-FL-58-lower-ocd-person-d6c0ddf5-2821-4510-83d1-da9eb422739a",
              "builtID": "fl-lower-58",
              "externalID": "ocd-person/d6c0ddf5-2821-4510-83d1-da9eb422739a",
              "geometry": null
            },
            "contactInfo": {
              "email": "kim.berfield@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2189 Cleveland St. Suite 229, Clearwater, FL 33765",
              "phone1": null,
              "phone2": "727-216-6035",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4210&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4210&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-58-lower-ocd-person-d6c0ddf5-2821-4510-83d1-da9eb422739a": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/8e396afa-17d2-404c-9024-7aa8d7e00e0d",
            "firstName": "Berny",
            "lastName": "Jacques",
            "fullName": "Berny Jacques",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4886.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-FL-59-lower-ocd-person-8e396afa-17d2-404c-9024-7aa8d7e00e0d",
              "builtID": "fl-lower-59",
              "externalID": "ocd-person/8e396afa-17d2-404c-9024-7aa8d7e00e0d",
              "geometry": null
            },
            "contactInfo": {
              "email": "berny.jacques@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4625 E. Bay Drive, Clearwater, FL 33764",
              "phone1": null,
              "phone2": "727-519-0560",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4886&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4886&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-59-lower-ocd-person-8e396afa-17d2-404c-9024-7aa8d7e00e0d": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/8bcd1df3-2697-42a9-842a-f3cced1fcb0f",
            "firstName": "Lindsay Michelle",
            "lastName": "Cross",
            "fullName": "Lindsay Cross",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4887.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-FL-60-lower-ocd-person-8bcd1df3-2697-42a9-842a-f3cced1fcb0f",
              "builtID": "fl-lower-60",
              "externalID": "ocd-person/8bcd1df3-2697-42a9-842a-f3cced1fcb0f",
              "geometry": null
            },
            "contactInfo": {
              "email": "lindsay.cross@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "425 N. 22nd Ave. Suite C, St. Petersburg, FL 33704",
              "phone1": null,
              "phone2": "727-552-2747",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4887&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4887&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-60-lower-ocd-person-8bcd1df3-2697-42a9-842a-f3cced1fcb0f": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/7d59a4fe-d0f1-43a8-93b4-bcd3b7fe5b4e",
            "firstName": "Linda",
            "lastName": "Chaney",
            "fullName": "Linda Chaney",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4778.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-FL-61-lower-ocd-person-7d59a4fe-d0f1-43a8-93b4-bcd3b7fe5b4e",
              "builtID": "fl-lower-61",
              "externalID": "ocd-person/7d59a4fe-d0f1-43a8-93b4-bcd3b7fe5b4e",
              "geometry": null
            },
            "contactInfo": {
              "email": "linda.chaney@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4020 Park St. North Suite 201, St. Petersburg, FL 33709",
              "phone1": null,
              "phone2": "727-471-4672",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4778&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4778"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-61-lower-ocd-person-7d59a4fe-d0f1-43a8-93b4-bcd3b7fe5b4e": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/9b6f17f5-ec19-4302-8a45-2b40175bb39b",
            "firstName": "Michele Kenyette",
            "lastName": "Rayner",
            "fullName": "Michele Rayner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4781.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-FL-62-lower-ocd-person-9b6f17f5-ec19-4302-8a45-2b40175bb39b",
              "builtID": "fl-lower-62",
              "externalID": "ocd-person/9b6f17f5-ec19-4302-8a45-2b40175bb39b",
              "geometry": null
            },
            "contactInfo": {
              "email": "michele.rayner@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3833 Central Ave., St. Petersburg, FL 33713",
              "phone1": null,
              "phone2": "727-471-4706",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4781&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4781"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-62-lower-ocd-person-9b6f17f5-ec19-4302-8a45-2b40175bb39b": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/1be9ca60-ccf5-4beb-835a-3c292e04eaef",
            "firstName": "Dianne Langston",
            "lastName": "Hart",
            "fullName": "Dianne Hart",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4736.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-FL-63-lower-ocd-person-1be9ca60-ccf5-4beb-835a-3c292e04eaef",
              "builtID": "fl-lower-63",
              "externalID": "ocd-person/1be9ca60-ccf5-4beb-835a-3c292e04eaef",
              "geometry": null
            },
            "contactInfo": {
              "email": "dianne.hart@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3911 N. Tampa St., Tampa, FL 33603",
              "phone1": null,
              "phone2": "813-224-1956",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4736&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4736&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4736&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4736"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-63-lower-ocd-person-1be9ca60-ccf5-4beb-835a-3c292e04eaef": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/811dfa67-2615-4d45-9cf9-bd1a587a34fe",
            "firstName": "Susan Lopez",
            "lastName": "Valdes",
            "fullName": "Susan Valdés",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4752.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-FL-64-lower-ocd-person-811dfa67-2615-4d45-9cf9-bd1a587a34fe",
              "builtID": "fl-lower-64",
              "externalID": "ocd-person/811dfa67-2615-4d45-9cf9-bd1a587a34fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.valdes@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2221 N. Himes Ave. Suite B, Tampa, FL 33607",
              "phone1": null,
              "phone2": "813-673-4673",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4752&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4752&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4752&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4752"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-64-lower-ocd-person-811dfa67-2615-4d45-9cf9-bd1a587a34fe": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/d1296445-72e8-45e5-ad0e-4443275176ef",
            "firstName": "Karen",
            "lastName": "Gonzalez Pittman",
            "fullName": "Karen Gonzalez Pittman",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4888.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-FL-65-lower-ocd-person-d1296445-72e8-45e5-ad0e-4443275176ef",
              "builtID": "fl-lower-65",
              "externalID": "ocd-person/d1296445-72e8-45e5-ad0e-4443275176ef",
              "geometry": null
            },
            "contactInfo": {
              "email": "karen.pittman@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1101 Channelside Drive Suite 304, Tampa, FL 33602",
              "phone1": null,
              "phone2": "813-202-3345",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4888&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4888&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-65-lower-ocd-person-d1296445-72e8-45e5-ad0e-4443275176ef": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/217f848d-99c2-43aa-98d2-6fee2812c1e6",
            "firstName": "Traci L.",
            "lastName": "Koster",
            "fullName": "Traci Koster",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4779.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-FL-66-lower-ocd-person-217f848d-99c2-43aa-98d2-6fee2812c1e6",
              "builtID": "fl-lower-66",
              "externalID": "ocd-person/217f848d-99c2-43aa-98d2-6fee2812c1e6",
              "geometry": null
            },
            "contactInfo": {
              "email": "traci.koster@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "14928 Casey Road, Tampa, FL 33624",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4779&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4779&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4779"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-66-lower-ocd-person-217f848d-99c2-43aa-98d2-6fee2812c1e6": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/5a138490-70a4-46fa-967e-8739d1ea7456",
            "firstName": "Fentrice Denell",
            "lastName": "Driskell",
            "fullName": "Fentrice Driskell",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4745.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-FL-67-lower-ocd-person-5a138490-70a4-46fa-967e-8739d1ea7456",
              "builtID": "fl-lower-67",
              "externalID": "ocd-person/5a138490-70a4-46fa-967e-8739d1ea7456",
              "geometry": null
            },
            "contactInfo": {
              "email": "fentrice.driskell@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "13250 N. 56th St. Suite 103, Tampa, FL 33617",
              "phone1": null,
              "phone2": "813-936-0854",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4745&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4745&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4745&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4745"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-67-lower-ocd-person-5a138490-70a4-46fa-967e-8739d1ea7456": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/56263ea0-3c5c-45cb-998d-ac7cf8db5446",
            "firstName": "Lawrence",
            "lastName": "McClure",
            "fullName": "Lawrence McClure",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4686.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-FL-68-lower-ocd-person-56263ea0-3c5c-45cb-998d-ac7cf8db5446",
              "builtID": "fl-lower-68",
              "externalID": "ocd-person/56263ea0-3c5c-45cb-998d-ac7cf8db5446",
              "geometry": null
            },
            "contactInfo": {
              "email": "lawrence.mcclure@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "110 W. Reynolds St. Suite 204, Plant City, FL 33563",
              "phone1": null,
              "phone2": "813-757-9110",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4686&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4686"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-68-lower-ocd-person-56263ea0-3c5c-45cb-998d-ac7cf8db5446": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/5a852452-a855-44a6-aa71-1e07e48c0c89",
            "firstName": "Daniel Antonio",
            "lastName": "Alvarez",
            "fullName": "Danny Alvarez",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4889.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-FL-69-lower-ocd-person-5a852452-a855-44a6-aa71-1e07e48c0c89",
              "builtID": "fl-lower-69",
              "externalID": "ocd-person/5a852452-a855-44a6-aa71-1e07e48c0c89",
              "geometry": null
            },
            "contactInfo": {
              "email": "danny.alvarez@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6152 Delancey Station St. Suite 205 B, Riverview, FL 33578",
              "phone1": null,
              "phone2": "813-657-1118",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4889&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4889&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-69-lower-ocd-person-5a852452-a855-44a6-aa71-1e07e48c0c89": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/4f45bda8-a133-43a3-aa11-6c80833a7521",
            "firstName": "Michael Paul",
            "lastName": "Beltran",
            "fullName": "Mike Beltran",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4747.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-FL-70-lower-ocd-person-4f45bda8-a133-43a3-aa11-6c80833a7521",
              "builtID": "fl-lower-70",
              "externalID": "ocd-person/4f45bda8-a133-43a3-aa11-6c80833a7521",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.beltran@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "235 Apollo Beach Blvd. Suite 234, Apollo Beach, FL 33572",
              "phone1": null,
              "phone2": "656-999-0747",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4747&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4747"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-70-lower-ocd-person-4f45bda8-a133-43a3-aa11-6c80833a7521": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/042b27f1-ec82-497e-958c-ebe5da28b706",
            "firstName": "William Cloud",
            "lastName": "Robinson",
            "fullName": "Will Robinson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4733.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-FL-71-lower-ocd-person-042b27f1-ec82-497e-958c-ebe5da28b706",
              "builtID": "fl-lower-71",
              "externalID": "ocd-person/042b27f1-ec82-497e-958c-ebe5da28b706",
              "geometry": null
            },
            "contactInfo": {
              "email": "will.robinson@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1051 Manatee Ave. West #305, Bradenton, FL 34205",
              "phone1": null,
              "phone2": "941-744-5097",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4733&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4733&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4733&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4733"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-71-lower-ocd-person-042b27f1-ec82-497e-958c-ebe5da28b706": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/ee14a4ef-f1ef-4fe5-a8a8-2158759b3e7c",
            "firstName": "Tommy Eugene",
            "lastName": "Gregory",
            "fullName": "Tommy Gregory",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4732.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-FL-72-lower-ocd-person-ee14a4ef-f1ef-4fe5-a8a8-2158759b3e7c",
              "builtID": "fl-lower-72",
              "externalID": "ocd-person/ee14a4ef-f1ef-4fe5-a8a8-2158759b3e7c",
              "geometry": null
            },
            "contactInfo": {
              "email": "tommy.gregory@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "8470 Enterprise Circle Suite 108A, Lakewood Ranch, FL 34202",
              "phone1": null,
              "phone2": "941-893-5434",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4732&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4732&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4732&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4732"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-72-lower-ocd-person-ee14a4ef-f1ef-4fe5-a8a8-2158759b3e7c": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/741003ec-e46f-4ae8-a3af-ce2a2aba5cd4",
            "firstName": "Fiona Fuller",
            "lastName": "McFarland",
            "fullName": "Fiona McFarland",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4780.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-FL-73-lower-ocd-person-741003ec-e46f-4ae8-a3af-ce2a2aba5cd4",
              "builtID": "fl-lower-73",
              "externalID": "ocd-person/741003ec-e46f-4ae8-a3af-ce2a2aba5cd4",
              "geometry": null
            },
            "contactInfo": {
              "email": "fiona.mcfarland@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3131 S. Tamiami Trail Suite 206, Sarasota, FL 34239",
              "phone1": null,
              "phone2": "941-361-2465",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4780&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4780&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4780"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-73-lower-ocd-person-741003ec-e46f-4ae8-a3af-ce2a2aba5cd4": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/797767ca-fadc-49d9-a7a5-5722baf6557e",
            "firstName": "James Vernon",
            "lastName": "Buchanan",
            "fullName": "James Buchanan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4731.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-FL-74-lower-ocd-person-797767ca-fadc-49d9-a7a5-5722baf6557e",
              "builtID": "fl-lower-74",
              "externalID": "ocd-person/797767ca-fadc-49d9-a7a5-5722baf6557e",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.buchanan@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5900 Pan American Blvd. Building A, Unit 102, North Port, FL 34287",
              "phone1": null,
              "phone2": "941-429-4560",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4731&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4731&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4731&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4731"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-74-lower-ocd-person-797767ca-fadc-49d9-a7a5-5722baf6557e": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/d0741023-b31c-4b82-950b-6efa3b736618",
            "firstName": "Michael J.",
            "lastName": "Grant",
            "fullName": "Mike Grant",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4349.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-FL-75-lower-ocd-person-d0741023-b31c-4b82-950b-6efa3b736618",
              "builtID": "fl-lower-75",
              "externalID": "ocd-person/d0741023-b31c-4b82-950b-6efa3b736618",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.grant@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4355 Pinnacle St. Suite C, Port Charlotte, FL 33980",
              "phone1": null,
              "phone2": "941-613-0914",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4349&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4349&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4349&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4349"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-75-lower-ocd-person-d0741023-b31c-4b82-950b-6efa3b736618": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/402d98f3-c8aa-4d64-9360-cfef6156ce23",
            "firstName": "Lal Spencer",
            "lastName": "Roach",
            "fullName": "Spencer Roach",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4729.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-FL-76-lower-ocd-person-402d98f3-c8aa-4d64-9360-cfef6156ce23",
              "builtID": "fl-lower-76",
              "externalID": "ocd-person/402d98f3-c8aa-4d64-9360-cfef6156ce23",
              "geometry": null
            },
            "contactInfo": {
              "email": "spencer.roach@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3436 Marinatown Lane Suite 6, North Fort Myers, FL 33903",
              "phone1": null,
              "phone2": "239-656-7790",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4729&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4729&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4729&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4729"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-76-lower-ocd-person-402d98f3-c8aa-4d64-9360-cfef6156ce23": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/36219a90-2f5f-4ac6-b6ad-04139103c70c",
            "firstName": "Tiffany",
            "lastName": "Esposito",
            "fullName": "Tiffany Esposito",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4892.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-FL-77-lower-ocd-person-36219a90-2f5f-4ac6-b6ad-04139103c70c",
              "builtID": "fl-lower-77",
              "externalID": "ocd-person/36219a90-2f5f-4ac6-b6ad-04139103c70c",
              "geometry": null
            },
            "contactInfo": {
              "email": "tiffany.esposito@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "16301 Innovation Lane Suite 123, Fort Myers, FL 33913",
              "phone1": null,
              "phone2": "239-445-2357",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4892&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4892&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-77-lower-ocd-person-36219a90-2f5f-4ac6-b6ad-04139103c70c": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/bc54a6ff-f7b3-4bbf-a23a-4acc9caa8064",
            "firstName": "Jenna Dianne",
            "lastName": "Persons-Mulicka",
            "fullName": "Jenna Persons-Mulicka",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4785.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-FL-78-lower-ocd-person-bc54a6ff-f7b3-4bbf-a23a-4acc9caa8064",
              "builtID": "fl-lower-78",
              "externalID": "ocd-person/bc54a6ff-f7b3-4bbf-a23a-4acc9caa8064",
              "geometry": null
            },
            "contactInfo": {
              "email": "jenna.persons@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2120 Main St. Suite 208, Fort Myers, FL 33901",
              "phone1": null,
              "phone2": "239-338-2328",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4785&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4785&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4785"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-78-lower-ocd-person-bc54a6ff-f7b3-4bbf-a23a-4acc9caa8064": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/9a44c892-cd4a-452b-88cb-435e8ece8d82",
            "firstName": "Mike",
            "lastName": "Giallombardo",
            "fullName": "Mike Giallombardo",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4783.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-FL-79-lower-ocd-person-9a44c892-cd4a-452b-88cb-435e8ece8d82",
              "builtID": "fl-lower-79",
              "externalID": "ocd-person/9a44c892-cd4a-452b-88cb-435e8ece8d82",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.giallombardo@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1039 SE 9th Place Suite 310, Cape Coral, FL 33990",
              "phone1": null,
              "phone2": "239-772-1291",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4783&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4783"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-79-lower-ocd-person-9a44c892-cd4a-452b-88cb-435e8ece8d82": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/c94b1151-5034-482b-8d7b-f91f9bb5f951",
            "firstName": "Adam",
            "lastName": "Botana",
            "fullName": "Adam Botana",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4782.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-FL-80-lower-ocd-person-c94b1151-5034-482b-8d7b-f91f9bb5f951",
              "builtID": "fl-lower-80",
              "externalID": "ocd-person/c94b1151-5034-482b-8d7b-f91f9bb5f951",
              "geometry": null
            },
            "contactInfo": {
              "email": "adam.botana@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Sunshine Professional Center 9240 Bonita Beach Road SE, Suite 2215, Bonita Springs, FL 34135",
              "phone1": null,
              "phone2": "239-949-6279",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4782&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4782&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4782"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-80-lower-ocd-person-c94b1151-5034-482b-8d7b-f91f9bb5f951": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/2458a4ab-e345-4453-9395-f424ed712dde",
            "firstName": "Robert",
            "lastName": "Rommel",
            "fullName": "Bob Rommel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4634.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-FL-81-lower-ocd-person-2458a4ab-e345-4453-9395-f424ed712dde",
              "builtID": "fl-lower-81",
              "externalID": "ocd-person/2458a4ab-e345-4453-9395-f424ed712dde",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.rommel@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3299 Tamiami Trail East Suite 304, Naples, FL 34112",
              "phone1": null,
              "phone2": "239-417-6200",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4634&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4634&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4634&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4634"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-81-lower-ocd-person-2458a4ab-e345-4453-9395-f424ed712dde": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/f835385b-0837-4cfc-a81c-54982ba185d7",
            "firstName": "Lauren Uhlich",
            "lastName": "Melo",
            "fullName": "Lauren Melo",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4784.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-FL-82-lower-ocd-person-f835385b-0837-4cfc-a81c-54982ba185d7",
              "builtID": "fl-lower-82",
              "externalID": "ocd-person/f835385b-0837-4cfc-a81c-54982ba185d7",
              "geometry": null
            },
            "contactInfo": {
              "email": "lauren.melo@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Hendry County Courthouse 25 E. Hickpochee Ave., LaBelle, FL 33935",
              "phone1": null,
              "phone2": "863-675-5267",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4784&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4784&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4784"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-82-lower-ocd-person-f835385b-0837-4cfc-a81c-54982ba185d7": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/43356d29-3396-423e-9286-f7bd1e52ebde",
            "firstName": "Kaylee",
            "lastName": "Tuck",
            "fullName": "Kaylee Tuck",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4776.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-FL-83-lower-ocd-person-43356d29-3396-423e-9286-f7bd1e52ebde",
              "builtID": "fl-lower-83",
              "externalID": "ocd-person/43356d29-3396-423e-9286-f7bd1e52ebde",
              "geometry": null
            },
            "contactInfo": {
              "email": "kaylee.tuck@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "55 S. Parrott Ave., Okeechobee, FL 34974",
              "phone1": null,
              "phone2": "863-462-5019",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4776&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4776"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-83-lower-ocd-person-43356d29-3396-423e-9286-f7bd1e52ebde": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/767d2aef-21ee-4b6d-b64b-ed9350abaa60",
            "firstName": "Dana Lee",
            "lastName": "Trabulsy",
            "fullName": "Dana Trabulsy",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4788.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-FL-84-lower-ocd-person-767d2aef-21ee-4b6d-b64b-ed9350abaa60",
              "builtID": "fl-lower-84",
              "externalID": "ocd-person/767d2aef-21ee-4b6d-b64b-ed9350abaa60",
              "geometry": null
            },
            "contactInfo": {
              "email": "dana.trabulsy@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Building A, 3209 Virginia Ave. Suite 134, Fort Pierce, FL 34981",
              "phone1": null,
              "phone2": "772-595-1391",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4788&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4788&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4788"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-84-lower-ocd-person-767d2aef-21ee-4b6d-b64b-ed9350abaa60": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/a44181b6-df0b-466d-9989-d369083201aa",
            "firstName": "Tobin Rogers",
            "lastName": "Overdorf",
            "fullName": "Toby Overdorf",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4728.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-FL-85-lower-ocd-person-a44181b6-df0b-466d-9989-d369083201aa",
              "builtID": "fl-lower-85",
              "externalID": "ocd-person/a44181b6-df0b-466d-9989-d369083201aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "toby.overdorf@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "151 SW Flagler Ave., Stuart, FL 34994",
              "phone1": null,
              "phone2": "772-221-4961",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4728&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4728&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4728&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4728"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-85-lower-ocd-person-a44181b6-df0b-466d-9989-d369083201aa": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/5055fc3a-3cdd-4f4d-addb-23634fd1df92",
            "firstName": "John",
            "lastName": "Snyder",
            "fullName": "John Snyder",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4787.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-FL-86-lower-ocd-person-5055fc3a-3cdd-4f4d-addb-23634fd1df92",
              "builtID": "fl-lower-86",
              "externalID": "ocd-person/5055fc3a-3cdd-4f4d-addb-23634fd1df92",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.snyder@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 110, 200 Military Trail, Jupiter, FL 33458",
              "phone1": null,
              "phone2": "772-403-1064",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4787&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4787&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4787"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-86-lower-ocd-person-5055fc3a-3cdd-4f4d-addb-23634fd1df92": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/513949e3-1913-4880-91ec-8b3de8921863",
            "firstName": "Michael A.",
            "lastName": "Caruso",
            "fullName": "Mike Caruso",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4754.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-FL-87-lower-ocd-person-513949e3-1913-4880-91ec-8b3de8921863",
              "builtID": "fl-lower-87",
              "externalID": "ocd-person/513949e3-1913-4880-91ec-8b3de8921863",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.caruso@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 200, House Office Building 402 S. Monroe St., Tallahassee, FL 32399",
              "phone1": null,
              "phone2": "850-717-5087",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4754&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4754&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4754&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4754"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-87-lower-ocd-person-513949e3-1913-4880-91ec-8b3de8921863": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/bb2ae855-2e7a-4744-9271-a8ae1af89428",
            "firstName": "Jervonte",
            "lastName": "Edmonds",
            "fullName": "Tae Edmonds",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4842.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-FL-88-lower-ocd-person-bb2ae855-2e7a-4744-9271-a8ae1af89428",
              "builtID": "fl-lower-88",
              "externalID": "ocd-person/bb2ae855-2e7a-4744-9271-a8ae1af89428",
              "geometry": null
            },
            "contactInfo": {
              "email": "jervonte.edmonds@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5725 Corporate Way Suite 206, West Palm Beach, FL 33407",
              "phone1": null,
              "phone2": "561-242-5530",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4842&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4842&LegislativeTermId=89"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-88-lower-ocd-person-bb2ae855-2e7a-4744-9271-a8ae1af89428": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/b7377e58-3efa-49b5-a1f0-83da9da3d715",
            "firstName": "David Ryan",
            "lastName": "Silvers",
            "fullName": "David Silvers",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4627.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-FL-89-lower-ocd-person-b7377e58-3efa-49b5-a1f0-83da9da3d715",
              "builtID": "fl-lower-89",
              "externalID": "ocd-person/b7377e58-3efa-49b5-a1f0-83da9da3d715",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.silvers@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1850 Forest Hill Blvd. Suite 200, West Palm Beach, FL 33406",
              "phone1": null,
              "phone2": "561-357-4824",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4627&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4627&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4627&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4627"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-89-lower-ocd-person-b7377e58-3efa-49b5-a1f0-83da9da3d715": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/de6ef298-c7dd-4a60-866e-763e2c5bf607",
            "firstName": "Joseph Anthony",
            "lastName": "Casello",
            "fullName": "Joe Casello",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4750.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-FL-90-lower-ocd-person-de6ef298-c7dd-4a60-866e-763e2c5bf607",
              "builtID": "fl-lower-90",
              "externalID": "ocd-person/de6ef298-c7dd-4a60-866e-763e2c5bf607",
              "geometry": null
            },
            "contactInfo": {
              "email": "casello.joseph@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "9804 S. Military Trail Suite E2-6, Boynton Beach, FL 33436",
              "phone1": null,
              "phone2": "561-292-6015",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4750&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4750&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4750&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4750"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-90-lower-ocd-person-de6ef298-c7dd-4a60-866e-763e2c5bf607": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/ab654947-84ed-4526-9b00-487642499106",
            "firstName": "Margaret",
            "lastName": "Gossett-Seidman",
            "fullName": "Peggy Gossett-Seidman",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4891.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-FL-91-lower-ocd-person-ab654947-84ed-4526-9b00-487642499106",
              "builtID": "fl-lower-91",
              "externalID": "ocd-person/ab654947-84ed-4526-9b00-487642499106",
              "geometry": null
            },
            "contactInfo": {
              "email": "peggy.gossettseidman@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4400 N. Federal Highway Suite 210-52, Boca Raton, FL 33431",
              "phone1": null,
              "phone2": "561-750-2396",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4891&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4891&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-91-lower-ocd-person-ab654947-84ed-4526-9b00-487642499106": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/8eff1a19-557d-4a5d-8a3b-c77015a7babe",
            "firstName": "Kelly A.",
            "lastName": "Skidmore",
            "fullName": "Kelly Skidmore",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4786.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-FL-92-lower-ocd-person-8eff1a19-557d-4a5d-8a3b-c77015a7babe",
              "builtID": "fl-lower-92",
              "externalID": "ocd-person/8eff1a19-557d-4a5d-8a3b-c77015a7babe",
              "geometry": null
            },
            "contactInfo": {
              "email": "kelly.skidmore@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7499 W. Atlantic Ave. Suite 200, Delray Beach, FL 33446",
              "phone1": null,
              "phone2": "561-496-5940",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4786&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4786"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-92-lower-ocd-person-8eff1a19-557d-4a5d-8a3b-c77015a7babe": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/c30912cd-f827-47f3-8ef6-e7db5550512c",
            "firstName": "Katherine M.",
            "lastName": "Waldron",
            "fullName": "Katherine Waldron",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4894.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-FL-93-lower-ocd-person-c30912cd-f827-47f3-8ef6-e7db5550512c",
              "builtID": "fl-lower-93",
              "externalID": "ocd-person/c30912cd-f827-47f3-8ef6-e7db5550512c",
              "geometry": null
            },
            "contactInfo": {
              "email": "katherine.waldron@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3900 Woodlake Blvd. Suite 200-18, Greenacres, FL 33463",
              "phone1": null,
              "phone2": "561-291-6327",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4894&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4894&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-93-lower-ocd-person-c30912cd-f827-47f3-8ef6-e7db5550512c": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/4d91d960-ebfe-4629-86c3-4dc10786ba5d",
            "firstName": "Raymond R.",
            "lastName": "Roth",
            "fullName": "Rick Roth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4648.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-FL-94-lower-ocd-person-4d91d960-ebfe-4629-86c3-4dc10786ba5d",
              "builtID": "fl-lower-94",
              "externalID": "ocd-person/4d91d960-ebfe-4629-86c3-4dc10786ba5d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.roth@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Belle Glade City Hall 110 Dr. Martin Luther King Jr. Blvd. West, Belle Glade, FL 33430",
              "phone1": null,
              "phone2": "561-992-1323",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4648&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4648&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4648&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4648"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-94-lower-ocd-person-4d91d960-ebfe-4629-86c3-4dc10786ba5d": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/89d7d4d0-82e7-4535-bb79-3260a7b432b5",
            "firstName": "Christine",
            "lastName": "Hunschofsky",
            "fullName": "Christine Hunschofsky",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4799.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-FL-95-lower-ocd-person-89d7d4d0-82e7-4535-bb79-3260a7b432b5",
              "builtID": "fl-lower-95",
              "externalID": "ocd-person/89d7d4d0-82e7-4535-bb79-3260a7b432b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "christine.hunschofsky@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4800 W. Copans Road, Coconut Creek, FL 33063",
              "phone1": null,
              "phone2": "954-956-5600",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4799&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4799&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4799"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-95-lower-ocd-person-89d7d4d0-82e7-4535-bb79-3260a7b432b5": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/66308162-9b64-4d34-a8bc-fac71407f7d5",
            "firstName": "Dan",
            "lastName": "Daley",
            "fullName": "Dan Daley",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4757.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-FL-96-lower-ocd-person-66308162-9b64-4d34-a8bc-fac71407f7d5",
              "builtID": "fl-lower-96",
              "externalID": "ocd-person/66308162-9b64-4d34-a8bc-fac71407f7d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.daley@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1401, The Capitol 402 S. Monroe St., Tallahassee, FL 32399",
              "phone1": null,
              "phone2": "850-717-5096",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4757&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4757&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4757"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-96-lower-ocd-person-66308162-9b64-4d34-a8bc-fac71407f7d5": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/314f9b75-2167-4b73-b7b8-9c7dee31aaee",
            "firstName": "Lisa A.",
            "lastName": "Dunkley",
            "fullName": "Lisa Dunkley",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4893.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-FL-97-lower-ocd-person-314f9b75-2167-4b73-b7b8-9c7dee31aaee",
              "builtID": "fl-lower-97",
              "externalID": "ocd-person/314f9b75-2167-4b73-b7b8-9c7dee31aaee",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisa.dunkley@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7491 W. Oakland Park Blvd. Suite 219, Lauderhill, FL 33319",
              "phone1": null,
              "phone2": "954-746-1762",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4893&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4893&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-97-lower-ocd-person-314f9b75-2167-4b73-b7b8-9c7dee31aaee": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/4206b8cb-8af4-4dd9-b2cd-7e39a01d394c",
            "firstName": "Patricia Hawkins",
            "lastName": "Williams",
            "fullName": "Patricia Williams",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4629.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-FL-98-lower-ocd-person-4206b8cb-8af4-4dd9-b2cd-7e39a01d394c",
              "builtID": "fl-lower-98",
              "externalID": "ocd-person/4206b8cb-8af4-4dd9-b2cd-7e39a01d394c",
              "geometry": null
            },
            "contactInfo": {
              "email": "patricia.williams@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 159, 6400 NW 6th Way, Fort Lauderdale, FL 33309",
              "phone1": null,
              "phone2": "954-202-3201",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4629&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4629&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4629&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4629"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-98-lower-ocd-person-4206b8cb-8af4-4dd9-b2cd-7e39a01d394c": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/2c1c37bb-fd17-46d6-9694-8f2757f6da3e",
            "firstName": "Daryl",
            "lastName": "Campbell",
            "fullName": "Daryl Campbell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4843.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-FL-99-lower-ocd-person-2c1c37bb-fd17-46d6-9694-8f2757f6da3e",
              "builtID": "fl-lower-99",
              "externalID": "ocd-person/2c1c37bb-fd17-46d6-9694-8f2757f6da3e",
              "geometry": null
            },
            "contactInfo": {
              "email": "daryl.campbell@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "405 NW 7th St., Fort Lauderdale, FL 33311",
              "phone1": null,
              "phone2": "954-859-5433",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4843&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4843&LegislativeTermId=89"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-99-lower-ocd-person-2c1c37bb-fd17-46d6-9694-8f2757f6da3e": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/54cd0ebc-6f93-489e-a919-3583fa32b26a",
            "firstName": "Chip",
            "lastName": "LaMarca",
            "fullName": "Chip LaMarca",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4722.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-FL-100-lower-ocd-person-54cd0ebc-6f93-489e-a919-3583fa32b26a",
              "builtID": "fl-lower-100",
              "externalID": "ocd-person/54cd0ebc-6f93-489e-a919-3583fa32b26a",
              "geometry": null
            },
            "contactInfo": {
              "email": "chip.lamarca@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1827 NE 24th St., Lighthouse Point, FL 33064",
              "phone1": null,
              "phone2": "954-784-4531",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4722&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4722&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4722&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4722"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-100-lower-ocd-person-54cd0ebc-6f93-489e-a919-3583fa32b26a": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/d38a5a91-afd4-46d2-9bd3-7ccaf7aa0d2b",
            "firstName": "Hillary Brisson",
            "lastName": "Cassel",
            "fullName": "Hillary Cassel",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4895.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-FL-101-lower-ocd-person-d38a5a91-afd4-46d2-9bd3-7ccaf7aa0d2b",
              "builtID": "fl-lower-101",
              "externalID": "ocd-person/d38a5a91-afd4-46d2-9bd3-7ccaf7aa0d2b",
              "geometry": null
            },
            "contactInfo": {
              "email": "hillary.cassel@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3475 Sheridan St. Suite 203, Hollywood, FL 33021",
              "phone1": null,
              "phone2": "954-862-3004",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4895&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4895&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-101-lower-ocd-person-d38a5a91-afd4-46d2-9bd3-7ccaf7aa0d2b": 0
        }
      },
      "102": {
        "members": [
          {
            "API_ID": "ocd-person/1c170657-03fb-4ab7-ae9f-f8e83568471e",
            "firstName": "Michael Alan",
            "lastName": "Gottlieb",
            "fullName": "Mike Gottlieb",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4724.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "102",
              "chamber": "lower",
              "hashID": "SLDL-FL-102-lower-ocd-person-1c170657-03fb-4ab7-ae9f-f8e83568471e",
              "builtID": "fl-lower-102",
              "externalID": "ocd-person/1c170657-03fb-4ab7-ae9f-f8e83568471e",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.gottlieb@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "400 NW 73rd Ave., Plantation, FL 33317",
              "phone1": null,
              "phone2": "954-655-5498",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4724&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4724&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4724&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4724"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-102-lower-ocd-person-1c170657-03fb-4ab7-ae9f-f8e83568471e": 0
        }
      },
      "103": {
        "members": [
          {
            "API_ID": "ocd-person/67c201cf-9aaa-4a06-8dda-28e93ea27f6b",
            "firstName": "Robin",
            "lastName": "Bartleman",
            "fullName": "Robin Bartleman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4793.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "103",
              "chamber": "lower",
              "hashID": "SLDL-FL-103-lower-ocd-person-67c201cf-9aaa-4a06-8dda-28e93ea27f6b",
              "builtID": "fl-lower-103",
              "externalID": "ocd-person/67c201cf-9aaa-4a06-8dda-28e93ea27f6b",
              "geometry": null
            },
            "contactInfo": {
              "email": "robin.bartleman@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1725 Main St. Suite 225, Weston, FL 33326",
              "phone1": null,
              "phone2": "954-424-6828",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4793&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4793&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4793"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-103-lower-ocd-person-67c201cf-9aaa-4a06-8dda-28e93ea27f6b": 0
        }
      },
      "104": {
        "members": [
          {
            "API_ID": "ocd-person/1d219f7c-140b-48fd-ac39-4683c48dbc87",
            "firstName": "Felicia Simone",
            "lastName": "Robinson",
            "fullName": "Felicia Robinson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4791.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "104",
              "chamber": "lower",
              "hashID": "SLDL-FL-104-lower-ocd-person-1d219f7c-140b-48fd-ac39-4683c48dbc87",
              "builtID": "fl-lower-104",
              "externalID": "ocd-person/1d219f7c-140b-48fd-ac39-4683c48dbc87",
              "geometry": null
            },
            "contactInfo": {
              "email": "felicia.robinson@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3964 NW 167th St., Miami Gardens, FL 33054",
              "phone1": null,
              "phone2": "305-620-3711",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4791&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4791&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4791"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-104-lower-ocd-person-1d219f7c-140b-48fd-ac39-4683c48dbc87": 0
        }
      },
      "105": {
        "members": [
          {
            "API_ID": "ocd-person/5eb39c7e-3582-4269-a012-7a88c54623c9",
            "firstName": "Marie Paule",
            "lastName": "Woodson",
            "fullName": "Marie Woodson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4789.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "105",
              "chamber": "lower",
              "hashID": "SLDL-FL-105-lower-ocd-person-5eb39c7e-3582-4269-a012-7a88c54623c9",
              "builtID": "fl-lower-105",
              "externalID": "ocd-person/5eb39c7e-3582-4269-a012-7a88c54623c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "marie.woodson@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7200 Pines Blvd. Building #73, Suite 120, Pembroke Pines, FL 33024",
              "phone1": null,
              "phone2": "954-965-3700",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4789&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4789&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4789"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-105-lower-ocd-person-5eb39c7e-3582-4269-a012-7a88c54623c9": 0
        }
      },
      "106": {
        "members": [
          {
            "API_ID": "ocd-person/38674fed-3bec-4623-a5eb-d31e4851323f",
            "firstName": "Fabian",
            "lastName": "Basabe",
            "fullName": "Fabián Basabe",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4900.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "106",
              "chamber": "lower",
              "hashID": "SLDL-FL-106-lower-ocd-person-38674fed-3bec-4623-a5eb-d31e4851323f",
              "builtID": "fl-lower-106",
              "externalID": "ocd-person/38674fed-3bec-4623-a5eb-d31e4851323f",
              "geometry": null
            },
            "contactInfo": {
              "email": "fabian.basabe@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "327 NW 54th St., Miami, FL 33127",
              "phone1": null,
              "phone2": "305-993-1914",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4900&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4900&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-106-lower-ocd-person-38674fed-3bec-4623-a5eb-d31e4851323f": 0
        }
      },
      "107": {
        "members": [
          {
            "API_ID": "ocd-person/0bf9f192-10f8-4fc8-84ab-7d910363bba9",
            "firstName": "Christopher Emmanuel",
            "lastName": "Benjamin",
            "fullName": "Christopher Benjamin",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4795.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "107",
              "chamber": "lower",
              "hashID": "SLDL-FL-107-lower-ocd-person-0bf9f192-10f8-4fc8-84ab-7d910363bba9",
              "builtID": "fl-lower-107",
              "externalID": "ocd-person/0bf9f192-10f8-4fc8-84ab-7d910363bba9",
              "geometry": null
            },
            "contactInfo": {
              "email": "christopher.benjamin@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "610 NW 183rd St. Suite 204, Miami Gardens, FL 33169",
              "phone1": null,
              "phone2": "305-654-7100",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4795&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4795&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4795"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-107-lower-ocd-person-0bf9f192-10f8-4fc8-84ab-7d910363bba9": 0
        }
      },
      "108": {
        "members": [
          {
            "API_ID": "ocd-person/bcb3b06f-882e-4012-af21-4c9ba68d093d",
            "firstName": "Dotie",
            "lastName": "Joseph",
            "fullName": "Dotie Joseph",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4716.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "108",
              "chamber": "lower",
              "hashID": "SLDL-FL-108-lower-ocd-person-bcb3b06f-882e-4012-af21-4c9ba68d093d",
              "builtID": "fl-lower-108",
              "externalID": "ocd-person/bcb3b06f-882e-4012-af21-4c9ba68d093d",
              "geometry": null
            },
            "contactInfo": {
              "email": "dotie.joseph@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "735 NE 125th St., North Miami, FL 33161",
              "phone1": null,
              "phone2": "305-892-4296",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4716&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4716&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4716&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4716"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-108-lower-ocd-person-bcb3b06f-882e-4012-af21-4c9ba68d093d": 0
        }
      },
      "109": {
        "members": [
          {
            "API_ID": "ocd-person/2c62c9ae-8d1d-4dc3-a86a-fcc5dd7ba286",
            "firstName": "Ashley Viola",
            "lastName": "Gantt",
            "fullName": "Ashley Gantt",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4897.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "109",
              "chamber": "lower",
              "hashID": "SLDL-FL-109-lower-ocd-person-2c62c9ae-8d1d-4dc3-a86a-fcc5dd7ba286",
              "builtID": "fl-lower-109",
              "externalID": "ocd-person/2c62c9ae-8d1d-4dc3-a86a-fcc5dd7ba286",
              "geometry": null
            },
            "contactInfo": {
              "email": "ashley.gantt@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5400 NW 22nd Ave. Suite 101, Building C, Miami, FL 33142",
              "phone1": null,
              "phone2": "786-505-1081",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4897&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4897&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-109-lower-ocd-person-2c62c9ae-8d1d-4dc3-a86a-fcc5dd7ba286": 0
        }
      },
      "110": {
        "members": [
          {
            "API_ID": "ocd-person/35f68144-b99b-4f86-b223-726897926bfa",
            "firstName": "Thomas Paul",
            "lastName": "Fabricio",
            "fullName": "Tom Fabricio",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4792.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "110",
              "chamber": "lower",
              "hashID": "SLDL-FL-110-lower-ocd-person-35f68144-b99b-4f86-b223-726897926bfa",
              "builtID": "fl-lower-110",
              "externalID": "ocd-person/35f68144-b99b-4f86-b223-726897926bfa",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.fabricio@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7480 Fairway Drive Suite 206, Miami Lakes, FL 33014",
              "phone1": null,
              "phone2": "305-503-3737",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4792&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4792&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4792"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-110-lower-ocd-person-35f68144-b99b-4f86-b223-726897926bfa": 0
        }
      },
      "111": {
        "members": [
          {
            "API_ID": "ocd-person/686a3de8-6df1-4144-85bc-edaad7fe1708",
            "firstName": "J. David",
            "lastName": "Borrero",
            "fullName": "David Borrero",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4794.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "111",
              "chamber": "lower",
              "hashID": "SLDL-FL-111-lower-ocd-person-686a3de8-6df1-4144-85bc-edaad7fe1708",
              "builtID": "fl-lower-111",
              "externalID": "ocd-person/686a3de8-6df1-4144-85bc-edaad7fe1708",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.borrero@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 4C, 9600 NW 25th St. Suite 4C, Doral, FL 33172",
              "phone1": null,
              "phone2": "786-336-1022",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4794&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4794&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4794"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-111-lower-ocd-person-686a3de8-6df1-4144-85bc-edaad7fe1708": 0
        }
      },
      "112": {
        "members": [
          {
            "API_ID": "ocd-person/b2fce1a8-4ba6-483b-a534-fa48cc43ee7f",
            "firstName": "Alex",
            "lastName": "Rizo",
            "fullName": "Alex Rizo",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4796.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "112",
              "chamber": "lower",
              "hashID": "SLDL-FL-112-lower-ocd-person-b2fce1a8-4ba6-483b-a534-fa48cc43ee7f",
              "builtID": "fl-lower-112",
              "externalID": "ocd-person/b2fce1a8-4ba6-483b-a534-fa48cc43ee7f",
              "geometry": null
            },
            "contactInfo": {
              "email": "alex.rizo@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3798 W. 12th Ave. Suite A, Hialeah, FL 33012",
              "phone1": null,
              "phone2": "305-364-3114",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4796&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4796"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-112-lower-ocd-person-b2fce1a8-4ba6-483b-a534-fa48cc43ee7f": 0
        }
      },
      "113": {
        "members": [
          {
            "API_ID": "ocd-person/9c3fa1ee-5d40-450b-85b8-e571e0796e26",
            "firstName": "Vicki Lynn",
            "lastName": "Lopez",
            "fullName": "Vicki Lopez",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4896.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "113",
              "chamber": "lower",
              "hashID": "SLDL-FL-113-lower-ocd-person-9c3fa1ee-5d40-450b-85b8-e571e0796e26",
              "builtID": "fl-lower-113",
              "externalID": "ocd-person/9c3fa1ee-5d40-450b-85b8-e571e0796e26",
              "geometry": null
            },
            "contactInfo": {
              "email": "vicki.lopez@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2100 Coral Way Suite 702, Coral Gables, FL 33145",
              "phone1": null,
              "phone2": "305-854-0365",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4896&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4896&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-113-lower-ocd-person-9c3fa1ee-5d40-450b-85b8-e571e0796e26": 0
        }
      },
      "114": {
        "members": [
          {
            "API_ID": "ocd-person/8407b440-b679-4d84-b642-de35869461a4",
            "firstName": "Demi",
            "lastName": "Busatta Cabrera",
            "fullName": "Demi Busatta Cabrera",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4800.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "114",
              "chamber": "lower",
              "hashID": "SLDL-FL-114-lower-ocd-person-8407b440-b679-4d84-b642-de35869461a4",
              "builtID": "fl-lower-114",
              "externalID": "ocd-person/8407b440-b679-4d84-b642-de35869461a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "demi.busattacabrera@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "100 Almeria Ave. Suite 201, Coral Gables, FL 33134",
              "phone1": null,
              "phone2": "305-442-6808",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4800&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4800&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4800"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-114-lower-ocd-person-8407b440-b679-4d84-b642-de35869461a4": 0
        }
      },
      "115": {
        "members": [
          {
            "API_ID": "ocd-person/f0ceb5da-8efe-423d-ad13-f53290802d1b",
            "firstName": "Alina",
            "lastName": "Garcia",
            "fullName": "Alina García",
            "gender": "Female",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4899.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "115",
              "chamber": "lower",
              "hashID": "SLDL-FL-115-lower-ocd-person-f0ceb5da-8efe-423d-ad13-f53290802d1b",
              "builtID": "fl-lower-115",
              "externalID": "ocd-person/f0ceb5da-8efe-423d-ad13-f53290802d1b",
              "geometry": null
            },
            "contactInfo": {
              "email": "alina.garcia@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7380 SW 48th St., Miami, FL 33155",
              "phone1": null,
              "phone2": "305-663-2065",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4899&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4899&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-115-lower-ocd-person-f0ceb5da-8efe-423d-ad13-f53290802d1b": 0
        }
      },
      "116": {
        "members": [
          {
            "API_ID": "ocd-person/ed30b061-bbdc-44f3-8a40-e574646d6cf8",
            "firstName": "Daniel Anthony",
            "lastName": "Perez",
            "fullName": "Daniel Perez",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4690.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "116",
              "chamber": "lower",
              "hashID": "SLDL-FL-116-lower-ocd-person-ed30b061-bbdc-44f3-8a40-e574646d6cf8",
              "builtID": "fl-lower-116",
              "externalID": "ocd-person/ed30b061-bbdc-44f3-8a40-e574646d6cf8",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.perez@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "FIU MARC Building 1550 SW 109th Av., Miami, FL 33199",
              "phone1": null,
              "phone2": "305-348-1414",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4690&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4690&LegislativeTermId=88",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4690&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4690"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-116-lower-ocd-person-ed30b061-bbdc-44f3-8a40-e574646d6cf8": 0
        }
      },
      "117": {
        "members": [
          {
            "API_ID": "ocd-person/313e81ee-19af-4d9d-9186-fbbca7e6d65d",
            "firstName": "Kevin Dykemia",
            "lastName": "Chambliss",
            "fullName": "Kevin Chambliss",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4798.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "117",
              "chamber": "lower",
              "hashID": "SLDL-FL-117-lower-ocd-person-313e81ee-19af-4d9d-9186-fbbca7e6d65d",
              "builtID": "fl-lower-117",
              "externalID": "ocd-person/313e81ee-19af-4d9d-9186-fbbca7e6d65d",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.chambliss@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "17502 SW 104th Ave., Miami, FL 33157",
              "phone1": null,
              "phone2": "305-256-6300",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4798&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4798&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4798"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-117-lower-ocd-person-313e81ee-19af-4d9d-9186-fbbca7e6d65d": 0
        }
      },
      "118": {
        "members": [
          {
            "API_ID": "ocd-person/a4a0a7bf-62c4-412e-9501-007d712009ca",
            "firstName": "Michael David",
            "lastName": "Redondo",
            "fullName": "Mike Redondo",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4902.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "118",
              "chamber": "lower",
              "hashID": "SLDL-FL-118-lower-ocd-person-a4a0a7bf-62c4-412e-9501-007d712009ca",
              "builtID": "fl-lower-118",
              "externalID": "ocd-person/a4a0a7bf-62c4-412e-9501-007d712009ca",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.redondo@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "13701 SW 88th St. Suite 307, Miami, FL 33186",
              "phone1": null,
              "phone2": "305-398-8004",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4902&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4902&LegislativeTermId=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-118-lower-ocd-person-a4a0a7bf-62c4-412e-9501-007d712009ca": 0
        }
      },
      "119": {
        "members": [
          {
            "API_ID": "ocd-person/6c673f21-8f64-44b7-831f-d3d2e9c78eb3",
            "firstName": "Juan Carlos",
            "lastName": "Porras",
            "fullName": "Juan Porras",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4898.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "119",
              "chamber": "lower",
              "hashID": "SLDL-FL-119-lower-ocd-person-6c673f21-8f64-44b7-831f-d3d2e9c78eb3",
              "builtID": "fl-lower-119",
              "externalID": "ocd-person/6c673f21-8f64-44b7-831f-d3d2e9c78eb3",
              "geometry": null
            },
            "contactInfo": {
              "email": "juancarlos.porras@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "13372 SW 128th St., Miami, FL 33186",
              "phone1": null,
              "phone2": "305-252-4307",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4898&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4898"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-119-lower-ocd-person-6c673f21-8f64-44b7-831f-d3d2e9c78eb3": 0
        }
      },
      "120": {
        "members": [
          {
            "API_ID": "ocd-person/d03b8661-852b-44ab-b7c2-aa3bf14acd39",
            "firstName": "James Vernon",
            "lastName": "Mooney",
            "fullName": "Jim Mooney",
            "gender": "Male",
            "party": "Republican",
            "image": "https://myfloridahouse.gov//FileStores/Web/Imaging/Member/4797.jpg",
            "title": "FL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "120",
              "chamber": "lower",
              "hashID": "SLDL-FL-120-lower-ocd-person-d03b8661-852b-44ab-b7c2-aa3bf14acd39",
              "builtID": "fl-lower-120",
              "externalID": "ocd-person/d03b8661-852b-44ab-b7c2-aa3bf14acd39",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.mooney@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Murray Nelson Government Center 102050 Overseas Highway, Key Largo, FL 33037",
              "phone1": null,
              "phone2": "305-453-1202",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4797&LegislativeTermId=89",
                "https://myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4797&LegislativeTermId=90",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4797"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-FL-120-lower-ocd-person-d03b8661-852b-44ab-b7c2-aa3bf14acd39": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/684ec9bf-65e9-4e43-850d-01ea9569d224",
            "firstName": "Douglas Vaughn",
            "lastName": "Broxson",
            "fullName": "Doug Broxson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s01_5194.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-FL-1-upper-ocd-person-684ec9bf-65e9-4e43-850d-01ea9569d224",
              "builtID": "fl-upper-1",
              "externalID": "ocd-person/684ec9bf-65e9-4e43-850d-01ea9569d224",
              "geometry": null
            },
            "contactInfo": {
              "email": "broxson.doug.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 403, 418 W. Garden St., Pensacola, FL 32502",
              "phone1": null,
              "phone2": "850-595-1036",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/2018-2020/S1",
                "https://flsenate.gov/Senators/2022-2024/S1",
                "https://www.flsenate.gov/Senators/s1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-1-upper-ocd-person-684ec9bf-65e9-4e43-850d-01ea9569d224": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/29c38853-2773-45d3-bc17-0979def1ab54",
            "firstName": "Jay Norbert",
            "lastName": "Trumbull",
            "fullName": "Jay Trumbull",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s02_5389.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-FL-2-upper-ocd-person-29c38853-2773-45d3-bc17-0979def1ab54",
              "builtID": "fl-upper-2",
              "externalID": "ocd-person/29c38853-2773-45d3-bc17-0979def1ab54",
              "geometry": null
            },
            "contactInfo": {
              "email": "trumbull.jay.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "840 W. 11th St., Panama City, FL 32401",
              "phone1": null,
              "phone2": "850-747-5454",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://flsenate.gov/Senators/2022-2024/S2",
                "https://www.flsenate.gov/Senators/S2"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-2-upper-ocd-person-29c38853-2773-45d3-bc17-0979def1ab54": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/fca5c9b2-da9e-4836-a3d5-366a25350976",
            "firstName": "Corey",
            "lastName": "Simon",
            "fullName": "Corey Simon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s03_5522.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-FL-3-upper-ocd-person-fca5c9b2-da9e-4836-a3d5-366a25350976",
              "builtID": "fl-upper-3",
              "externalID": "ocd-person/fca5c9b2-da9e-4836-a3d5-366a25350976",
              "geometry": null
            },
            "contactInfo": {
              "email": "simon.corey.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Edward J. Butler Governmental Complex, Quincy, FL",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://flsenate.gov/Senators/2022-2024/S3",
                "https://www.flsenate.gov/Senators/S3"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-3-upper-ocd-person-fca5c9b2-da9e-4836-a3d5-366a25350976": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/f1eda85d-37f2-4475-a318-8278a11c9466",
            "firstName": "Clay",
            "lastName": "Yarborough",
            "fullName": "Clay Yarborough",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s04_5394.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-FL-4-upper-ocd-person-f1eda85d-37f2-4475-a318-8278a11c9466",
              "builtID": "fl-upper-4",
              "externalID": "ocd-person/f1eda85d-37f2-4475-a318-8278a11c9466",
              "geometry": null
            },
            "contactInfo": {
              "email": "yarborough.clay.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Nassau County, Yulee, FL 32097",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4633&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4633&LegislativeTermId=88",
                "https://flsenate.gov/Senators/2022-2024/S4",
                "https://www.flsenate.gov/Senators/S4"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-4-upper-ocd-person-f1eda85d-37f2-4475-a318-8278a11c9466": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/03362949-948c-415d-a5b6-f99e8fbc6495",
            "firstName": "Tracie",
            "lastName": "Davis",
            "fullName": "Tracie Davis",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s05_5385.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-FL-5-upper-ocd-person-03362949-948c-415d-a5b6-f99e8fbc6495",
              "builtID": "fl-upper-5",
              "externalID": "ocd-person/03362949-948c-415d-a5b6-f99e8fbc6495",
              "geometry": null
            },
            "contactInfo": {
              "email": "davis.tracie.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2933 N. Myrtle Ave. Suite 201, Jacksonville, FL 32209",
              "phone1": null,
              "phone2": "904-359-2575",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://flsenate.gov/Senators/2022-2024/S5",
                "https://www.flsenate.gov/Senators/S5"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-5-upper-ocd-person-03362949-948c-415d-a5b6-f99e8fbc6495": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/68e0a17f-338b-497b-ba07-1f74e9623f1d",
            "firstName": "Jennifer",
            "lastName": "Bradley",
            "fullName": "Jennifer Bradley",
            "gender": "Female",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s06_5476.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-FL-6-upper-ocd-person-68e0a17f-338b-497b-ba07-1f74e9623f1d",
              "builtID": "fl-upper-6",
              "externalID": "ocd-person/68e0a17f-338b-497b-ba07-1f74e9623f1d",
              "geometry": null
            },
            "contactInfo": {
              "email": "bradley.jennifer.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1845 EW Parkway Unit #5, Fleming Island, FL 32003",
              "phone1": null,
              "phone2": "904-278-2085",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://flsenate.gov/Senators/2022-2024/S6",
                "https://www.flsenate.gov/Senators/S6"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-6-upper-ocd-person-68e0a17f-338b-497b-ba07-1f74e9623f1d": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/ab530396-3077-4806-acfe-5203a4109a23",
            "firstName": "Travis",
            "lastName": "Hutson",
            "fullName": "Travis Hutson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s07_5186.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-FL-7-upper-ocd-person-ab530396-3077-4806-acfe-5203a4109a23",
              "builtID": "fl-upper-7",
              "externalID": "ocd-person/ab530396-3077-4806-acfe-5203a4109a23",
              "geometry": null
            },
            "contactInfo": {
              "email": "hutson.travis.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4875 Palm Coast Parkway Northwest Suite 5, Palm Coast, FL 32137",
              "phone1": null,
              "phone2": "386-446-7610",
              "fax1": null,
              "fax2": "888-263-3475",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/2018-2020/S7",
                "https://flsenate.gov/Senators/2022-2024/S7",
                "https://www.flsenate.gov/Senators/s7"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-7-upper-ocd-person-ab530396-3077-4806-acfe-5203a4109a23": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/4ae2bacf-d265-42a5-888d-5050015a6602",
            "firstName": "Tom A.",
            "lastName": "Wright",
            "fullName": "Tommy Wright",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s08_5346.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-FL-8-upper-ocd-person-4ae2bacf-d265-42a5-888d-5050015a6602",
              "builtID": "fl-upper-8",
              "externalID": "ocd-person/4ae2bacf-d265-42a5-888d-5050015a6602",
              "geometry": null
            },
            "contactInfo": {
              "email": "wright.tom.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4606 S. Clyde Morris Blvd. Suite 2-J, Port Orange, FL 32129",
              "phone1": null,
              "phone2": "386-304-7630",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/s14",
                "https://www.flsenate.gov/Senators/2018-2020/S14",
                "https://flsenate.gov/Senators/2022-2024/S8",
                "https://www.flsenate.gov/Senators/s8"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-8-upper-ocd-person-4ae2bacf-d265-42a5-888d-5050015a6602": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/5700b358-60e2-4058-91c3-18e14656d40c",
            "firstName": "Warren Keith",
            "lastName": "Perry",
            "fullName": "Keith Perry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s09_5200.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-FL-9-upper-ocd-person-5700b358-60e2-4058-91c3-18e14656d40c",
              "builtID": "fl-upper-9",
              "externalID": "ocd-person/5700b358-60e2-4058-91c3-18e14656d40c",
              "geometry": null
            },
            "contactInfo": {
              "email": "perry.keith.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 106, Levy County Government Center Complex 310 School St., Bronson, FL 32621",
              "phone1": null,
              "phone2": "352-670-4212",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/s8",
                "https://www.flsenate.gov/Senators/2018-2020/S8",
                "https://flsenate.gov/Senators/2022-2024/S9",
                "https://www.flsenate.gov/Senators/s9"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-9-upper-ocd-person-5700b358-60e2-4058-91c3-18e14656d40c": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/b1525f57-c2db-407e-bca9-66675cc4d3ff",
            "firstName": "Jason T.",
            "lastName": "Brodeur",
            "fullName": "Jason Brodeur",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s10_5077.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-FL-10-upper-ocd-person-b1525f57-c2db-407e-bca9-66675cc4d3ff",
              "builtID": "fl-upper-10",
              "externalID": "ocd-person/b1525f57-c2db-407e-bca9-66675cc4d3ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "brodeur.jason.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "110 Timberlachen Circle Suite 1012, Lake Mary, FL 32746",
              "phone1": null,
              "phone2": "407-333-1802",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://flsenate.gov/Senators/2022-2024/S10",
                "https://www.flsenate.gov/Senators/S10"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-10-upper-ocd-person-b1525f57-c2db-407e-bca9-66675cc4d3ff": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/ab1a279b-2b48-4c1b-988e-09f469142391",
            "firstName": "Blaise",
            "lastName": "Ingoglia",
            "fullName": "Blaise Ingoglia",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s11_5410.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-FL-11-upper-ocd-person-ab1a279b-2b48-4c1b-988e-09f469142391",
              "builtID": "fl-upper-11",
              "externalID": "ocd-person/ab1a279b-2b48-4c1b-988e-09f469142391",
              "geometry": null
            },
            "contactInfo": {
              "email": "ingoglia.blaise.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2943 Landover Blvd., Spring Hill, FL 34608",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4603&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4603&LegislativeTermId=88",
                "https://flsenate.gov/Senators/2022-2024/S11",
                "https://www.flsenate.gov/Senators/S11"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-11-upper-ocd-person-ab1a279b-2b48-4c1b-988e-09f469142391": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/50d5881b-ceac-49a2-98db-87ef6e09438c",
            "firstName": "Colleen Griffith",
            "lastName": "Burton",
            "fullName": "Colleen Burton",
            "gender": "Female",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s12_5414.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-FL-12-upper-ocd-person-50d5881b-ceac-49a2-98db-87ef6e09438c",
              "builtID": "fl-upper-12",
              "externalID": "ocd-person/50d5881b-ceac-49a2-98db-87ef6e09438c",
              "geometry": null
            },
            "contactInfo": {
              "email": "burton.colleen.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "100 S. Kentucky Ave. Suite 260, Lakeland, FL 33801",
              "phone1": null,
              "phone2": "863-413-1529",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4616&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4616&LegislativeTermId=88",
                "https://flsenate.gov/Senators/2022-2024/S12",
                "https://www.flsenate.gov/Senators/S12"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-12-upper-ocd-person-50d5881b-ceac-49a2-98db-87ef6e09438c": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/8da77b3a-e4d7-4b04-80bd-403d1e56ff6c",
            "firstName": "Dennis K.",
            "lastName": "Baxley",
            "fullName": "Dennis Baxley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s13_5192.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-FL-13-upper-ocd-person-8da77b3a-e4d7-4b04-80bd-403d1e56ff6c",
              "builtID": "fl-upper-13",
              "externalID": "ocd-person/8da77b3a-e4d7-4b04-80bd-403d1e56ff6c",
              "geometry": null
            },
            "contactInfo": {
              "email": "baxley.dennis.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "206 S. Highway 27/441, Lady Lake, FL 32159",
              "phone1": null,
              "phone2": "352-750-3133",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/s12",
                "https://www.flsenate.gov/Senators/2018-2020/S12",
                "https://flsenate.gov/Senators/2022-2024/S13",
                "https://www.flsenate.gov/Senators/s13"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-13-upper-ocd-person-8da77b3a-e4d7-4b04-80bd-403d1e56ff6c": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/34dac893-eae3-495e-9237-9689a1114895",
            "firstName": "Jarrid",
            "lastName": "Collins",
            "fullName": "Jay Collins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s14_5523.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-FL-14-upper-ocd-person-34dac893-eae3-495e-9237-9689a1114895",
              "builtID": "fl-upper-14",
              "externalID": "ocd-person/34dac893-eae3-495e-9237-9689a1114895",
              "geometry": null
            },
            "contactInfo": {
              "email": "collins.jay.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Veterans Plaza 4902 Eisenhower Blvd., Suite 217, Tampa, FL 33634",
              "phone1": null,
              "phone2": "813-281-2538",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://flsenate.gov/Senators/2022-2024/S14",
                "https://www.flsenate.gov/Senators/S14"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-14-upper-ocd-person-34dac893-eae3-495e-9237-9689a1114895": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/86161c8c-5e83-429e-a451-8dab38b2a979",
            "firstName": "Geraldine Fortenberry",
            "lastName": "Thompson",
            "fullName": "Geri Thompson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s15_5418.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-FL-15-upper-ocd-person-86161c8c-5e83-429e-a451-8dab38b2a979",
              "builtID": "fl-upper-15",
              "externalID": "ocd-person/86161c8c-5e83-429e-a451-8dab38b2a979",
              "geometry": null
            },
            "contactInfo": {
              "email": "geraldine.thompson@myfloridahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "511 W. South St. Suite 205, Orlando, FL 32805",
              "phone1": null,
              "phone2": "407-245-0194",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://flsenate.gov/Senators/2022-2024/S15",
                "https://www.flsenate.gov/Senators/S15"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-15-upper-ocd-person-86161c8c-5e83-429e-a451-8dab38b2a979": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/3de11937-c291-4561-bee5-4f747665cb37",
            "firstName": "Darryl Ervin",
            "lastName": "Rouson",
            "fullName": "Darryl Rouson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s16_5203.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-FL-16-upper-ocd-person-3de11937-c291-4561-bee5-4f747665cb37",
              "builtID": "fl-upper-16",
              "externalID": "ocd-person/3de11937-c291-4561-bee5-4f747665cb37",
              "geometry": null
            },
            "contactInfo": {
              "email": "rouson.darryl.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "The Cuban Club 2010 Avenida Republica de Cuba, Tampa, FL 33605",
              "phone1": null,
              "phone2": "727-822-6828",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/s19",
                "https://www.flsenate.gov/Senators/2018-2020/S19",
                "https://flsenate.gov/Senators/2022-2024/S16",
                "https://www.flsenate.gov/Senators/s16"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-16-upper-ocd-person-3de11937-c291-4561-bee5-4f747665cb37": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/ece5c5bd-6ac1-4800-bd2b-1f1ab4a707e7",
            "firstName": "Linda",
            "lastName": "Stewart",
            "fullName": "Linda Stewart",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s17_5205.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-FL-17-upper-ocd-person-ece5c5bd-6ac1-4800-bd2b-1f1ab4a707e7",
              "builtID": "fl-upper-17",
              "externalID": "ocd-person/ece5c5bd-6ac1-4800-bd2b-1f1ab4a707e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "stewart.linda.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1726 S. Bumby Ave., Orlando, FL 32806",
              "phone1": null,
              "phone2": "407-893-2422",
              "fax1": null,
              "fax2": "888-263-3680",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/s13",
                "https://www.flsenate.gov/Senators/2018-2020/S13",
                "https://flsenate.gov/Senators/2022-2024/S17",
                "https://www.flsenate.gov/Senators/s17"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-17-upper-ocd-person-ece5c5bd-6ac1-4800-bd2b-1f1ab4a707e7": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/618247ea-caa1-462c-a29a-636c1720fd14",
            "firstName": "Nick",
            "lastName": "DiCeglie",
            "fullName": "Nick DiCeglie",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s18_5365.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-FL-18-upper-ocd-person-618247ea-caa1-462c-a29a-636c1720fd14",
              "builtID": "fl-upper-18",
              "externalID": "ocd-person/618247ea-caa1-462c-a29a-636c1720fd14",
              "geometry": null
            },
            "contactInfo": {
              "email": "diceglie.nick.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 200, 9800 4th St. North, St. Petersburg, FL 33702",
              "phone1": null,
              "phone2": "727-563-1910",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4735&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4735&LegislativeTermId=88",
                "https://flsenate.gov/Senators/2022-2024/S18",
                "https://www.flsenate.gov/Senators/S18"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-18-upper-ocd-person-618247ea-caa1-462c-a29a-636c1720fd14": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/a41e2c13-be68-4af3-8229-3c657b8d980d",
            "firstName": "Debbie",
            "lastName": "Mayfield",
            "fullName": "Debbie Mayfield",
            "gender": "Female",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s19_5199.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-FL-19-upper-ocd-person-a41e2c13-be68-4af3-8229-3c657b8d980d",
              "builtID": "fl-upper-19",
              "externalID": "ocd-person/a41e2c13-be68-4af3-8229-3c657b8d980d",
              "geometry": null
            },
            "contactInfo": {
              "email": "mayfield.debbie.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "900 E. Strawbridge Ave., Melbourne, FL 32901",
              "phone1": null,
              "phone2": "321-409-2025",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/s17",
                "https://www.flsenate.gov/Senators/2018-2020/S17",
                "https://flsenate.gov/Senators/2022-2024/S19",
                "https://www.flsenate.gov/Senators/s19"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-19-upper-ocd-person-a41e2c13-be68-4af3-8229-3c657b8d980d": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/c70f231f-92e2-4a55-a59b-fcf113ab51af",
            "firstName": "Jim",
            "lastName": "Boyd",
            "fullName": "Jim Boyd",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s20_5070.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-FL-20-upper-ocd-person-c70f231f-92e2-4a55-a59b-fcf113ab51af",
              "builtID": "fl-upper-20",
              "externalID": "ocd-person/c70f231f-92e2-4a55-a59b-fcf113ab51af",
              "geometry": null
            },
            "contactInfo": {
              "email": "boyd.jim.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "717 Manatee Ave. West Suite 100, Bradenton, FL 34205",
              "phone1": null,
              "phone2": "941-742-6445",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flhouse.gov/Sections/Representatives/details.aspx?MemberId=4511&LegislativeTermId=87",
                "https://www.flsenate.gov/Senators/S21",
                "https://flsenate.gov/Senators/2022-2024/S20",
                "https://www.flsenate.gov/Senators/S20"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-20-upper-ocd-person-c70f231f-92e2-4a55-a59b-fcf113ab51af": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/0e375630-02a4-4e59-ac80-869f9a199b2a",
            "firstName": "Ed",
            "lastName": "Hooper",
            "fullName": "Ed Hooper",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s21_5344.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-FL-21-upper-ocd-person-0e375630-02a4-4e59-ac80-869f9a199b2a",
              "builtID": "fl-upper-21",
              "externalID": "ocd-person/0e375630-02a4-4e59-ac80-869f9a199b2a",
              "geometry": null
            },
            "contactInfo": {
              "email": "hooper.ed.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3450 E. Lake Road South Suite 305, Palm Harbor, FL 34685",
              "phone1": null,
              "phone2": "727-771-2102",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/s16",
                "https://www.flsenate.gov/Senators/2018-2020/S16",
                "https://flsenate.gov/Senators/2022-2024/S21",
                "https://www.flsenate.gov/Senators/s21"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-21-upper-ocd-person-0e375630-02a4-4e59-ac80-869f9a199b2a": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/24b4458a-bc61-42f8-805a-6bc43edf6acb",
            "firstName": "Joseph Ryan",
            "lastName": "Gruters",
            "fullName": "Joe Gruters",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s22_5347.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-FL-22-upper-ocd-person-24b4458a-bc61-42f8-805a-6bc43edf6acb",
              "builtID": "fl-upper-22",
              "externalID": "ocd-person/24b4458a-bc61-42f8-805a-6bc43edf6acb",
              "geometry": null
            },
            "contactInfo": {
              "email": "gruters.joe.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "381 Interstate Blvd., Sarasota, FL 34240",
              "phone1": null,
              "phone2": "941-378-6309",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/s23",
                "https://www.flsenate.gov/Senators/2018-2020/S23",
                "https://flsenate.gov/Senators/2022-2024/S22",
                "https://www.flsenate.gov/Senators/s22"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-22-upper-ocd-person-24b4458a-bc61-42f8-805a-6bc43edf6acb": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/55815523-33b5-4d42-9864-28e00b805e68",
            "firstName": "Daniel Wright",
            "lastName": "Burgess",
            "fullName": "Danny Burgess",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s23_5169.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-FL-23-upper-ocd-person-55815523-33b5-4d42-9864-28e00b805e68",
              "builtID": "fl-upper-23",
              "externalID": "ocd-person/55815523-33b5-4d42-9864-28e00b805e68",
              "geometry": null
            },
            "contactInfo": {
              "email": "burgess.danny.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "38507 5th Ave., Zephyrhills, FL 33542",
              "phone1": null,
              "phone2": "813-779-7059",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/S20",
                "https://flsenate.gov/Senators/2022-2024/S23",
                "https://www.flsenate.gov/Senators/S23"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-23-upper-ocd-person-55815523-33b5-4d42-9864-28e00b805e68": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/1f25e279-3bd4-423b-8d2d-c2d09154540d",
            "firstName": "Bobby",
            "lastName": "Powell",
            "fullName": "Bobby Powell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s24_5201.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-FL-24-upper-ocd-person-1f25e279-3bd4-423b-8d2d-c2d09154540d",
              "builtID": "fl-upper-24",
              "externalID": "ocd-person/1f25e279-3bd4-423b-8d2d-c2d09154540d",
              "geometry": null
            },
            "contactInfo": {
              "email": "powell.bobby.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2715 N. Australian Ave. Suite 105, West Palm Beach, FL 33407",
              "phone1": null,
              "phone2": "561-650-6880",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://flsenate.gov/Senators/2022-2024/S24",
                "https://www.flsenate.gov/Senators/s24"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-24-upper-ocd-person-1f25e279-3bd4-423b-8d2d-c2d09154540d": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/1f0da88a-19c5-4b96-b337-e84cf5d177b8",
            "firstName": "Victor Manuel",
            "lastName": "Torres",
            "fullName": "Vic Torres",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s25_5206.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-FL-25-upper-ocd-person-1f0da88a-19c5-4b96-b337-e84cf5d177b8",
              "builtID": "fl-upper-25",
              "externalID": "ocd-person/1f0da88a-19c5-4b96-b337-e84cf5d177b8",
              "geometry": null
            },
            "contactInfo": {
              "email": "torres.victor.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "101 Church St. Suite 305, Kissimmee, FL 34741",
              "phone1": null,
              "phone2": "407-846-5187",
              "fax1": null,
              "fax2": "850-410-4817",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://flsenate.gov/Senators/2022-2024/S25",
                "https://www.flsenate.gov/Senators/s25"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-25-upper-ocd-person-1f0da88a-19c5-4b96-b337-e84cf5d177b8": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/762d321c-5bbe-4513-b9f0-7e3d8a6972f5",
            "firstName": "Lori",
            "lastName": "Berman",
            "fullName": "Lori Berman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s26_5339.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-FL-26-upper-ocd-person-762d321c-5bbe-4513-b9f0-7e3d8a6972f5",
              "builtID": "fl-upper-26",
              "externalID": "ocd-person/762d321c-5bbe-4513-b9f0-7e3d8a6972f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "berman.lori.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2300 High Ridge Road Suite 161, Boynton Beach, FL 33426",
              "phone1": null,
              "phone2": "561-292-6014",
              "fax1": null,
              "fax2": "888-284-6491",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/s31",
                "https://www.flsenate.gov/Senators/2018-2020/S31",
                "https://flsenate.gov/Senators/2022-2024/S26",
                "https://www.flsenate.gov/Senators/s26"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-26-upper-ocd-person-762d321c-5bbe-4513-b9f0-7e3d8a6972f5": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/5c81dfe7-1cec-45e8-8044-6d9cd324f2e8",
            "firstName": "Ben",
            "lastName": "Albritton",
            "fullName": "Ben Albritton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s27_5342.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-FL-27-upper-ocd-person-5c81dfe7-1cec-45e8-8044-6d9cd324f2e8",
              "builtID": "fl-upper-27",
              "externalID": "ocd-person/5c81dfe7-1cec-45e8-8044-6d9cd324f2e8",
              "geometry": null
            },
            "contactInfo": {
              "email": "albritton.ben.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "410 Taylor St. Suite 106, Punta Gorda, FL 33950",
              "phone1": null,
              "phone2": "941-575-5717",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/s26",
                "https://www.flsenate.gov/Senators/2018-2020/S26",
                "https://flsenate.gov/Senators/2022-2024/S27",
                "https://www.flsenate.gov/Senators/s27"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-27-upper-ocd-person-5c81dfe7-1cec-45e8-8044-6d9cd324f2e8": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/ae123d54-1076-49b2-84f9-2897b7d60ebd",
            "firstName": "Kathleen C.",
            "lastName": "Passidomo",
            "fullName": "Kathleen Passidomo",
            "gender": "Female",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s28_5196.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-FL-28-upper-ocd-person-ae123d54-1076-49b2-84f9-2897b7d60ebd",
              "builtID": "fl-upper-28",
              "externalID": "ocd-person/ae123d54-1076-49b2-84f9-2897b7d60ebd",
              "geometry": null
            },
            "contactInfo": {
              "email": "passidomo.kathleen.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room J-101, 25 E. Hickpoochee Ave., LaBelle, FL 33935",
              "phone1": null,
              "phone2": "863-674-7122",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/2018-2020/S28",
                "https://flsenate.gov/Senators/2022-2024/S28",
                "https://www.flsenate.gov/Senators/s28"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-28-upper-ocd-person-ae123d54-1076-49b2-84f9-2897b7d60ebd": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/9cf042e6-01c0-4f8e-aab5-68508a0fda66",
            "firstName": "Erin K.",
            "lastName": "Grall",
            "fullName": "Erin Grall",
            "gender": "Female",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s29_5426.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-FL-29-upper-ocd-person-9cf042e6-01c0-4f8e-aab5-68508a0fda66",
              "builtID": "fl-upper-29",
              "externalID": "ocd-person/9cf042e6-01c0-4f8e-aab5-68508a0fda66",
              "geometry": null
            },
            "contactInfo": {
              "email": "grall.erin.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3209 Virginia Ave. Suite A149, Fort Pierce, FL 34981",
              "phone1": null,
              "phone2": "772-595-1398",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4637&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4637&LegislativeTermId=88",
                "https://flsenate.gov/Senators/2022-2024/S29",
                "https://www.flsenate.gov/Senators/S29"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-29-upper-ocd-person-9cf042e6-01c0-4f8e-aab5-68508a0fda66": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/8c728fd0-2a3b-48ea-88a9-c7d675eb05d0",
            "firstName": "Tina Scott",
            "lastName": "Polsky",
            "fullName": "Tina Polsky",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s30_5371.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-FL-30-upper-ocd-person-8c728fd0-2a3b-48ea-88a9-c7d675eb05d0",
              "builtID": "fl-upper-30",
              "externalID": "ocd-person/8c728fd0-2a3b-48ea-88a9-c7d675eb05d0",
              "geometry": null
            },
            "contactInfo": {
              "email": "polsky.tina.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5301 N. Federal Highway Suite 135, Boca Raton, FL 33487",
              "phone1": null,
              "phone2": "561-443-8170",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://flsenate.gov/Senators/2022-2024/S30",
                "https://www.flsenate.gov/Senators/S30"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-30-upper-ocd-person-8c728fd0-2a3b-48ea-88a9-c7d675eb05d0": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/cd3ba8e7-7c51-444b-bff3-e0c29de30660",
            "firstName": "Gayle B.",
            "lastName": "Harrell",
            "fullName": "Gayle Harrell",
            "gender": "Female",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s31_5348.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-FL-31-upper-ocd-person-cd3ba8e7-7c51-444b-bff3-e0c29de30660",
              "builtID": "fl-upper-31",
              "externalID": "ocd-person/cd3ba8e7-7c51-444b-bff3-e0c29de30660",
              "geometry": null
            },
            "contactInfo": {
              "email": "harrell.gayle.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Palm Beach County Jupiter Town Hall, Jupiter, FL",
              "phone1": null,
              "phone2": "772-221-4019",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/s25",
                "https://www.flsenate.gov/Senators/2018-2020/S25",
                "https://flsenate.gov/Senators/2022-2024/S31",
                "https://www.flsenate.gov/Senators/s31"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-31-upper-ocd-person-cd3ba8e7-7c51-444b-bff3-e0c29de30660": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/3194701f-dfa8-40e9-9081-56aa20c63f6e",
            "firstName": "Rosalind",
            "lastName": "Osgood",
            "fullName": "Rosalind Osgood",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s32_5520.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-FL-32-upper-ocd-person-3194701f-dfa8-40e9-9081-56aa20c63f6e",
              "builtID": "fl-upper-32",
              "externalID": "ocd-person/3194701f-dfa8-40e9-9081-56aa20c63f6e",
              "geometry": null
            },
            "contactInfo": {
              "email": "osgood.rosalind.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "8491 W. Commercial Blvd., Tamarac, FL 33351",
              "phone1": null,
              "phone2": "954-321-2705",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://flsenate.gov/Senators/2022-2024/S32",
                "https://www.flsenate.gov/Senators/S32",
                "https://www.senatorosgood.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-32-upper-ocd-person-3194701f-dfa8-40e9-9081-56aa20c63f6e": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/e5e1f892-4d9e-464e-b58d-6acf50069b75",
            "firstName": "Jonathan Allen",
            "lastName": "Martin",
            "fullName": "Jonathan Martin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s33_5524.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-FL-33-upper-ocd-person-e5e1f892-4d9e-464e-b58d-6acf50069b75",
              "builtID": "fl-upper-33",
              "externalID": "ocd-person/e5e1f892-4d9e-464e-b58d-6acf50069b75",
              "geometry": null
            },
            "contactInfo": {
              "email": "martin.jonathan.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2000 Main St. Suite 401, Fort Myers, FL 33901",
              "phone1": null,
              "phone2": "239-338-2570",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://flsenate.gov/Senators/2022-2024/S33",
                "https://www.flsenate.gov/Senators/S33"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-33-upper-ocd-person-e5e1f892-4d9e-464e-b58d-6acf50069b75": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/a8a2eaa4-449b-441f-96bb-d8a3e8191479",
            "firstName": "Shevrin D.",
            "lastName": "Jones",
            "fullName": "Shev Jones",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s34_5453.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-FL-34-upper-ocd-person-a8a2eaa4-449b-441f-96bb-d8a3e8191479",
              "builtID": "fl-upper-34",
              "externalID": "ocd-person/a8a2eaa4-449b-441f-96bb-d8a3e8191479",
              "geometry": null
            },
            "contactInfo": {
              "email": "jones.shevrin.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "606 NW 183rd St., Miami Gardens, FL 33169",
              "phone1": null,
              "phone2": "305-493-6002",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4559&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4559&LegislativeTermId=88",
                "https://www.flsenate.gov/Senators/S35",
                "https://flsenate.gov/Senators/2022-2024/S34",
                "https://www.flsenate.gov/Senators/S34"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-34-upper-ocd-person-a8a2eaa4-449b-441f-96bb-d8a3e8191479": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/565546dd-d03e-4062-85a4-ff095ad98c3b",
            "firstName": "Lauren Frances",
            "lastName": "Book",
            "fullName": "Lauren Book",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s35_5189.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-FL-35-upper-ocd-person-565546dd-d03e-4062-85a4-ff095ad98c3b",
              "builtID": "fl-upper-35",
              "externalID": "ocd-person/565546dd-d03e-4062-85a4-ff095ad98c3b",
              "geometry": null
            },
            "contactInfo": {
              "email": "book.lauren.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "12401 Orange Drive Suite 125, Davie, FL 33330",
              "phone1": null,
              "phone2": "954-424-6674",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/s32",
                "https://www.flsenate.gov/Senators/2018-2020/S32",
                "https://flsenate.gov/Senators/2022-2024/S35",
                "https://www.flsenate.gov/Senators/s35"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-35-upper-ocd-person-565546dd-d03e-4062-85a4-ff095ad98c3b": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/3b65ecb0-8a3d-469c-9db4-b70c8724610f",
            "firstName": "Ileana Ydolia",
            "lastName": "Garcia",
            "fullName": "Ileana Garcia",
            "gender": "Female",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s36_5515.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-FL-36-upper-ocd-person-3b65ecb0-8a3d-469c-9db4-b70c8724610f",
              "builtID": "fl-upper-36",
              "externalID": "ocd-person/3b65ecb0-8a3d-469c-9db4-b70c8724610f",
              "geometry": null
            },
            "contactInfo": {
              "email": "garcia.ileana.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2828 Coral Way Suite 208, Miami, FL 33145",
              "phone1": null,
              "phone2": "305-442-6841",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.flsenate.gov/Senators/S37",
                "https://flsenate.gov/Senators/2022-2024/S36",
                "https://www.flsenate.gov/Senators/S36"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-36-upper-ocd-person-3b65ecb0-8a3d-469c-9db4-b70c8724610f": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/42278250-8dc9-4fcc-ab11-964aa8e369e7",
            "firstName": "Jason William Barnet",
            "lastName": "Pizzo",
            "fullName": "Jason Pizzo",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s37_5345.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-FL-37-upper-ocd-person-42278250-8dc9-4fcc-ab11-964aa8e369e7",
              "builtID": "fl-upper-37",
              "externalID": "ocd-person/42278250-8dc9-4fcc-ab11-964aa8e369e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "pizzo.jason.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3475 Sheridan St. Suite 211, Hollywood, FL 33021",
              "phone1": null,
              "phone2": "954-893-5045",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://flsenate.gov/Senators/2022-2024/S37",
                "https://www.flsenate.gov/Senators/s37"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-37-upper-ocd-person-42278250-8dc9-4fcc-ab11-964aa8e369e7": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/7f1e874f-ae8b-42a0-90d9-fe867cf9fb45",
            "firstName": "Alexis Maria",
            "lastName": "Calatayud",
            "fullName": "Alexis Calatayud",
            "gender": "Female",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s38_5525.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-FL-38-upper-ocd-person-7f1e874f-ae8b-42a0-90d9-fe867cf9fb45",
              "builtID": "fl-upper-38",
              "externalID": "ocd-person/7f1e874f-ae8b-42a0-90d9-fe867cf9fb45",
              "geometry": null
            },
            "contactInfo": {
              "email": "calatayud.alexis.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Kendall Campus 11011 SW 104th St., Suite 5101, Miami, FL 33176",
              "phone1": null,
              "phone2": "305-596-3002",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://flsenate.gov/Senators/2022-2024/S38",
                "https://www.flsenate.gov/Senators/S38"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-38-upper-ocd-person-7f1e874f-ae8b-42a0-90d9-fe867cf9fb45": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/a4a5f92e-62cf-401b-9fc6-9d9a63498d5c",
            "firstName": "Bryan",
            "lastName": "Avila",
            "fullName": "Bryan Ávila",
            "gender": "Male",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s39_5459.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-FL-39-upper-ocd-person-a4a5f92e-62cf-401b-9fc6-9d9a63498d5c",
              "builtID": "fl-upper-39",
              "externalID": "ocd-person/a4a5f92e-62cf-401b-9fc6-9d9a63498d5c",
              "geometry": null
            },
            "contactInfo": {
              "email": "avila.bryan.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "10001 NW 87th Ave., Hialeah Gardens, FL 33016",
              "phone1": null,
              "phone2": "305-364-3073",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4615&LegislativeTermId=88",
                "https://www.myfloridahouse.gov/Sections/Representatives/details.aspx?MemberId=4615&LegislativeTermId=88",
                "https://flsenate.gov/Senators/2022-2024/S39",
                "https://www.flsenate.gov/Senators/S39"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-39-upper-ocd-person-a4a5f92e-62cf-401b-9fc6-9d9a63498d5c": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/afd55a58-b8f6-4dd7-9d93-a2207b65e425",
            "firstName": "Ana Maria",
            "lastName": "Rodriguez",
            "fullName": "Ana Maria Rodriguez",
            "gender": "Female",
            "party": "Republican",
            "image": "https://flsenate.gov/PublishedContent/Senators/2022-2024/Photos/s40_5379.jpg",
            "title": "FL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "FL",
              "stateFull": "Florida",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-FL-40-upper-ocd-person-afd55a58-b8f6-4dd7-9d93-a2207b65e425",
              "builtID": "fl-upper-40",
              "externalID": "ocd-person/afd55a58-b8f6-4dd7-9d93-a2207b65e425",
              "geometry": null
            },
            "contactInfo": {
              "email": "rodriguez.anamaria.web@flsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Miami-Dade College, West Campus 3800 NW 115th Ave., Suite 1112, Doral, FL 33178",
              "phone1": null,
              "phone2": "305-470-2552",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://flsenate.gov/Senators/2022-2024/S40",
                "https://www.flsenate.gov/Senators/S40"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-FL-40-upper-ocd-person-afd55a58-b8f6-4dd7-9d93-a2207b65e425": 0
        }
      }
    }
  },
  "GA": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "GA",
          "stateFull": "Georgia",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "GA",
            "stateFull": "Georgia",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-GA---",
            "builtID": "ga--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-GA---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "O000174",
          "in_office": true,
          "firstName": "Jon",
          "lastName": "Ossoff",
          "middleName": null,
          "fullName": "Jon Ossoff",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/O000174.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "GA",
            "stateFull": "Georgia",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-GA---O000174",
            "builtID": "ga--",
            "externalID": "O000174",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.ossoff.senate.gov/contact-us/",
            "address1": "455 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-3521",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenOssoff",
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.ossoff.senate.gov"],
            "rss_url": "https://www.ossoff.senate.gov/media/press-releases/feed/"
          },
          "title": "GA Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/O000174.json",
          "govtrack_id": "456857",
          "cspan_id": null,
          "votesmart_id": "176134",
          "icpsr_id": null,
          "crp_id": "N00040675",
          "google_entity_id": "/g/11g6njlbw2",
          "state_rank": "senior",
          "lis_id": "S414",
          "suffix": null,
          "date_of_birth": "1987-02-16",
          "leadership_role": null,
          "fec_candidate_id": "H8GA06195",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 8,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 1.44,
          "votes_with_party_pct": 99.26,
          "votes_against_party_pct": 0.74,
          "ocd_id": "ocd-division/country:us/state:ga"
        },
        {
          "API_ID": "W000790",
          "in_office": true,
          "firstName": "Raphael",
          "lastName": "Warnock",
          "middleName": null,
          "fullName": "Raphael G. Warnock",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/W000790.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "GA",
            "stateFull": "Georgia",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-GA---W000790",
            "builtID": "ga--",
            "externalID": "W000790",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorWarnock",
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.warnock.senate.gov"],
            "rss_url": "https://www.warnock.senate.gov/media/press-releases/feed/"
          },
          "title": "GA Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/W000790.json",
          "govtrack_id": "456858",
          "cspan_id": null,
          "votesmart_id": "189794",
          "icpsr_id": null,
          "crp_id": "N00046489",
          "google_entity_id": "/g/11b5lw9q73",
          "state_rank": "junior",
          "lis_id": "S415",
          "suffix": null,
          "date_of_birth": "1969-07-23",
          "leadership_role": null,
          "fec_candidate_id": "S0GA00559",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "3",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 23,
          "total_present": 1,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 4.15,
          "votes_with_party_pct": 99.43,
          "votes_against_party_pct": 0.57,
          "ocd_id": "ocd-division/country:us/state:ga"
        }
      ],
      "hash": { "SENATE-GA---O000174": 0, "SENATE-GA---W000790": 1 }
    },
    "HOUSE": {
      "10": {
        "members": [
          {
            "API_ID": "C001129",
            "in_office": true,
            "firstName": "Mike",
            "lastName": "Collins",
            "middleName": null,
            "fullName": "Mike Collins",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001129.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "10",
              "chamber": "",
              "hashID": "HOUSE-GA-10--C001129",
              "builtID": "ga--10",
              "externalID": "C001129",
              "geometry": null,
              "geoid": "1310"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1223 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4101",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMikeCollins",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://collins.house.gov/"],
              "rss_url": null
            },
            "title": "GA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001129.json",
            "govtrack_id": "456895",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1967-07-02",
            "leadership_role": null,
            "fec_candidate_id": "H4GA10071",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.57,
            "votes_with_party_pct": 87.95,
            "votes_against_party_pct": 10.11,
            "ocd_id": "ocd-division/country:us/state:ga/cd:10",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-GA-10--C001129": 0 }
      },
      "11": {
        "members": [
          {
            "API_ID": "L000583",
            "in_office": true,
            "firstName": "Barry",
            "lastName": "Loudermilk",
            "middleName": null,
            "fullName": "Barry Loudermilk",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/L000583.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "11",
              "chamber": "",
              "hashID": "HOUSE-GA-11--L000583",
              "builtID": "ga--11",
              "externalID": "L000583",
              "geometry": null,
              "geoid": "1311"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2133 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2931",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLoudermilk",
              "youtube": null,
              "instagram": null,
              "facebook": "reploudermilk",
              "urls": ["https://loudermilk.house.gov"],
              "rss_url": null
            },
            "title": "GA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000583.json",
            "govtrack_id": "412624",
            "cspan_id": "76165",
            "votesmart_id": "31618",
            "icpsr_id": "21515",
            "crp_id": "N00035347",
            "google_entity_id": "/m/0wqdmcm",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1963-12-22",
            "leadership_role": null,
            "fec_candidate_id": "H4GA11061",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 43,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 4.08,
            "votes_with_party_pct": 92.54,
            "votes_against_party_pct": 5.54,
            "ocd_id": "ocd-division/country:us/state:ga/cd:11",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-GA-11--L000583": 0 }
      },
      "12": {
        "members": [
          {
            "API_ID": "A000372",
            "in_office": true,
            "firstName": "Rick",
            "lastName": "Allen",
            "middleName": null,
            "fullName": "Rick W. Allen",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/A000372.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "12",
              "chamber": "",
              "hashID": "HOUSE-GA-12--A000372",
              "builtID": "ga--12",
              "externalID": "A000372",
              "geometry": null,
              "geoid": "1312"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "462 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2823",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "reprickallen",
              "youtube": null,
              "instagram": null,
              "facebook": "CongressmanRickAllen",
              "urls": ["https://allen.house.gov"],
              "rss_url": null
            },
            "title": "GA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/A000372.json",
            "govtrack_id": "412625",
            "cspan_id": "62545",
            "votesmart_id": "136062",
            "icpsr_id": "21516",
            "crp_id": "N00033720",
            "google_entity_id": "/m/0127y9dk",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1951-11-07",
            "leadership_role": null,
            "fec_candidate_id": "H2GA12121",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 4,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.38,
            "votes_with_party_pct": 94.47,
            "votes_against_party_pct": 3.69,
            "ocd_id": "ocd-division/country:us/state:ga/cd:12",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-GA-12--A000372": 0 }
      },
      "13": {
        "members": [
          {
            "API_ID": "S001157",
            "in_office": true,
            "firstName": "David",
            "lastName": "Scott",
            "middleName": null,
            "fullName": "David Scott",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001157.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "13",
              "chamber": "",
              "hashID": "HOUSE-GA-13--S001157",
              "builtID": "ga--13",
              "externalID": "S001157",
              "geometry": null,
              "geoid": "1313"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "468 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2939",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDavidScott",
              "youtube": "RepDavidScott",
              "instagram": null,
              "facebook": "113303673339",
              "urls": ["https://davidscott.house.gov"],
              "rss_url": "https://davidscott.house.gov/news/rss.aspx"
            },
            "title": "GA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001157.json",
            "govtrack_id": "400363",
            "cspan_id": "1003567",
            "votesmart_id": "7826",
            "icpsr_id": "20321",
            "crp_id": "N00024871",
            "google_entity_id": "/m/0255r4",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1945-06-27",
            "leadership_role": null,
            "fec_candidate_id": "H2GA13012",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "22",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 15,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.42,
            "votes_with_party_pct": 94.37,
            "votes_against_party_pct": 3.78,
            "ocd_id": "ocd-division/country:us/state:ga/cd:13",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-GA-13--S001157": 0 }
      },
      "14": {
        "members": [
          {
            "API_ID": "G000596",
            "in_office": true,
            "firstName": "Marjorie",
            "lastName": "Greene",
            "middleName": "Taylor",
            "fullName": "Marjorie Taylor Greene",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000596.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "14",
              "chamber": "",
              "hashID": "HOUSE-GA-14--G000596",
              "builtID": "ga--14",
              "externalID": "G000596",
              "geometry": null,
              "geoid": "1314"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "403 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5211",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMTG",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://greene.house.gov"],
              "rss_url": null
            },
            "title": "GA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000596.json",
            "govtrack_id": "456814",
            "cspan_id": null,
            "votesmart_id": "189785",
            "icpsr_id": null,
            "crp_id": "N00044701",
            "google_entity_id": "/g/11fscs046n",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1974-05-27",
            "leadership_role": null,
            "fec_candidate_id": "H0GA06192",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 42,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 3.98,
            "votes_with_party_pct": 83.7,
            "votes_against_party_pct": 14.29,
            "ocd_id": "ocd-division/country:us/state:ga/cd:14",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-GA-14--G000596": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "B000490",
            "in_office": true,
            "firstName": "Sanford",
            "lastName": "Bishop",
            "middleName": "D.",
            "fullName": "Sanford D. Bishop, Jr.",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/B000490.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-GA-02--B000490",
              "builtID": "ga--02",
              "externalID": "B000490",
              "geometry": null,
              "geoid": "1302"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2407 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3631",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SanfordBishop",
              "youtube": "RepSanfordBishop",
              "instagram": null,
              "facebook": "sanfordbishop",
              "urls": ["https://bishop.house.gov"],
              "rss_url": "https://bishop.house.gov/rss.xml"
            },
            "title": "GA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B000490.json",
            "govtrack_id": "400030",
            "cspan_id": "26194",
            "votesmart_id": "26817",
            "icpsr_id": "29339",
            "crp_id": "N00002674",
            "google_entity_id": "/m/02554k",
            "state_rank": null,
            "lis_id": null,
            "suffix": "Jr.",
            "date_of_birth": "1947-02-04",
            "leadership_role": null,
            "fec_candidate_id": "H2GA02031",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "32",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 11,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.04,
            "votes_with_party_pct": 95.85,
            "votes_against_party_pct": 2.32,
            "ocd_id": "ocd-division/country:us/state:ga/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-GA-02--B000490": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "C001103",
            "in_office": true,
            "firstName": "Buddy",
            "lastName": "Carter",
            "middleName": null,
            "fullName": "Earl L. \"Buddy\" Carter",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001103.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-GA-01--C001103",
              "builtID": "ga--01",
              "externalID": "C001103",
              "geometry": null,
              "geoid": "1301"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2432 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5831",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBuddyCarter",
              "youtube": null,
              "instagram": null,
              "facebook": "congressmanbuddycarter",
              "urls": ["https://buddycarter.house.gov"],
              "rss_url": null
            },
            "title": "GA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001103.json",
            "govtrack_id": "412622",
            "cspan_id": "76158",
            "votesmart_id": "32085",
            "icpsr_id": "21513",
            "crp_id": "N00035346",
            "google_entity_id": "/m/0w32xj9",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1957-09-06",
            "leadership_role": null,
            "fec_candidate_id": "H4GA01039",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 10,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.95,
            "votes_with_party_pct": 94.24,
            "votes_against_party_pct": 3.9,
            "ocd_id": "ocd-division/country:us/state:ga/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-GA-01--C001103": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "C001116",
            "in_office": true,
            "firstName": "Andrew",
            "lastName": "Clyde",
            "middleName": null,
            "fullName": "Andrew S. Clyde",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001116.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-GA-09--C001116",
              "builtID": "ga--09",
              "externalID": "C001116",
              "geometry": null,
              "geoid": "1309"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "445 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-9893",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://clyde.house.gov"],
              "rss_url": null
            },
            "title": "GA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001116.json",
            "govtrack_id": "456813",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00046654",
            "google_entity_id": "/g/11j32tm4v4",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1963-11-22",
            "leadership_role": null,
            "fec_candidate_id": "H0GA09246",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.57,
            "votes_with_party_pct": 86.59,
            "votes_against_party_pct": 11.47,
            "ocd_id": "ocd-division/country:us/state:ga/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-GA-09--C001116": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "F000465",
            "in_office": true,
            "firstName": "A.",
            "lastName": "Ferguson",
            "middleName": "Drew",
            "fullName": "A. Drew Ferguson IV",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/F000465.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-GA-03--F000465",
              "builtID": "ga--03",
              "externalID": "F000465",
              "geometry": null,
              "geoid": "1303"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2239 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5901",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDrewFerguson",
              "youtube": null,
              "instagram": null,
              "facebook": "RepDrewFerguson",
              "urls": ["https://ferguson.house.gov"],
              "rss_url": null
            },
            "title": "GA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000465.json",
            "govtrack_id": "412700",
            "cspan_id": "104731",
            "votesmart_id": "168132",
            "icpsr_id": "21717",
            "crp_id": "N00039090",
            "google_entity_id": "/g/11c0xj334k",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1966-11-15",
            "leadership_role": null,
            "fec_candidate_id": "H6GA03113",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 28,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.65,
            "votes_with_party_pct": 93.84,
            "votes_against_party_pct": 4.17,
            "ocd_id": "ocd-division/country:us/state:ga/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-GA-03--F000465": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "J000288",
            "in_office": true,
            "firstName": "Hank",
            "lastName": "Johnson",
            "middleName": null,
            "fullName": "Henry C. \"Hank\" Johnson, Jr.",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/J000288.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-GA-04--J000288",
              "builtID": "ga--04",
              "externalID": "J000288",
              "geometry": null,
              "geoid": "1304"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2240 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-1605",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepHankJohnson",
              "youtube": "RepHankJohnson",
              "instagram": null,
              "facebook": "115356957005",
              "urls": ["https://hankjohnson.house.gov"],
              "rss_url": "https://hankjohnson.house.gov/rss.xml"
            },
            "title": "GA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/J000288.json",
            "govtrack_id": "412199",
            "cspan_id": "1020576",
            "votesmart_id": "68070",
            "icpsr_id": "20712",
            "crp_id": "N00027848",
            "google_entity_id": "/m/0flgyy",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1954-10-02",
            "leadership_role": null,
            "fec_candidate_id": "H6GA04129",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "18",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 33,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 3.13,
            "votes_with_party_pct": 93.78,
            "votes_against_party_pct": 4.34,
            "ocd_id": "ocd-division/country:us/state:ga/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-GA-04--J000288": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "M001208",
            "in_office": true,
            "firstName": "Lucy",
            "lastName": "McBath",
            "middleName": null,
            "fullName": "Lucy McBath",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M001208.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-GA-07--M001208",
              "builtID": "ga--07",
              "externalID": "M001208",
              "geometry": null,
              "geoid": "1307"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2246 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4501",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "replucymcbath",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://mcbath.house.gov"],
              "rss_url": null
            },
            "title": "GA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001208.json",
            "govtrack_id": "412769",
            "cspan_id": null,
            "votesmart_id": "178538",
            "icpsr_id": null,
            "crp_id": "N00042813",
            "google_entity_id": "/g/11f647_0gj",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1960-06-01",
            "leadership_role": null,
            "fec_candidate_id": "H8GA06393",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 33,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.13,
            "votes_with_party_pct": 96.25,
            "votes_against_party_pct": 1.78,
            "ocd_id": "ocd-division/country:us/state:ga/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-GA-07--M001208": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "M001218",
            "in_office": true,
            "firstName": "Rich",
            "lastName": "McCormick",
            "middleName": null,
            "fullName": "Richard McCormick",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001218.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-GA-06--M001218",
              "builtID": "ga--06",
              "externalID": "M001218",
              "geometry": null,
              "geoid": "1306"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1213 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4272",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMcCormick",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://mccormick.house.gov/"],
              "rss_url": null
            },
            "title": "GA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001218.json",
            "govtrack_id": "456894",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1968-10-07",
            "leadership_role": null,
            "fec_candidate_id": "H0GA07273",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 15,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.42,
            "votes_with_party_pct": 90.79,
            "votes_against_party_pct": 7.25,
            "ocd_id": "ocd-division/country:us/state:ga/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-GA-06--M001218": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "S001189",
            "in_office": true,
            "firstName": "Austin",
            "lastName": "Scott",
            "middleName": null,
            "fullName": "Austin Scott",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S001189.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-GA-08--S001189",
              "builtID": "ga--08",
              "externalID": "S001189",
              "geometry": null,
              "geoid": "1308"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2185 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6531",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "AustinScottGA08",
              "youtube": "RepAustinScott",
              "instagram": null,
              "facebook": "RepAustinScott",
              "urls": ["https://austinscott.house.gov"],
              "rss_url": "https://austinscott.house.gov/rss.xml"
            },
            "title": "GA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001189.json",
            "govtrack_id": "412417",
            "cspan_id": "623344",
            "votesmart_id": "11812",
            "icpsr_id": "21123",
            "crp_id": "N00032457",
            "google_entity_id": "/m/076zn3y",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1969-12-10",
            "leadership_role": null,
            "fec_candidate_id": "H0GA08099",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 10,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.95,
            "votes_with_party_pct": 90.72,
            "votes_against_party_pct": 7.42,
            "ocd_id": "ocd-division/country:us/state:ga/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-GA-08--S001189": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "W000788",
            "in_office": true,
            "firstName": "Nikema",
            "lastName": "Williams",
            "middleName": null,
            "fullName": "Nikema Williams",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/W000788.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-GA-05--W000788",
              "builtID": "ga--05",
              "externalID": "W000788",
              "geometry": null,
              "geoid": "1305"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1406 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3801",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepNikema",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://nikemawilliams.house.gov"],
              "rss_url": null
            },
            "title": "GA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000788.json",
            "govtrack_id": "456811",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00047361",
            "google_entity_id": "/g/11fctqx0jy",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1978-07-30",
            "leadership_role": null,
            "fec_candidate_id": "H0GA05301",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 8,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.76,
            "votes_with_party_pct": 94.8,
            "votes_against_party_pct": 3.27,
            "ocd_id": "ocd-division/country:us/state:ga/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-GA-05--W000788": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/f4f9be12-5d7c-4fae-8b7b-9e9d19d2282f",
            "firstName": "Michael David",
            "lastName": "Cameron",
            "fullName": "Mike Cameron",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/cameron-mike-4986.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-GA-1-lower-ocd-person-f4f9be12-5d7c-4fae-8b7b-9e9d19d2282f",
              "builtID": "ga-lower-1",
              "externalID": "ocd-person/f4f9be12-5d7c-4fae-8b7b-9e9d19d2282f",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.cameron@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/4986?session=1029",
                "https://www.legis.ga.gov/members/house/4986?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4986"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-1-lower-ocd-person-f4f9be12-5d7c-4fae-8b7b-9e9d19d2282f": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/80f4bbdf-02f3-4233-8456-e550e7e745af",
            "firstName": "Steve",
            "lastName": "Tarvin",
            "fullName": "Steve Tarvin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/tarvin-steve-849.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-GA-2-lower-ocd-person-80f4bbdf-02f3-4233-8456-e550e7e745af",
              "builtID": "ga-lower-2",
              "externalID": "ocd-person/80f4bbdf-02f3-4233-8456-e550e7e745af",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.tarvin@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=849&Session=27",
                "https://www.legis.ga.gov/members/house/849?session=1029",
                "https://www.legis.ga.gov/members/house/849?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/849"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-2-lower-ocd-person-80f4bbdf-02f3-4233-8456-e550e7e745af": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/c4c6c741-0cb4-4179-b46d-9983421b8fdd",
            "firstName": "Mitchell",
            "lastName": "Horner",
            "fullName": "Mitchell Horner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/horner-mitchell-5018.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-GA-3-lower-ocd-person-c4c6c741-0cb4-4179-b46d-9983421b8fdd",
              "builtID": "ga-lower-3",
              "externalID": "ocd-person/c4c6c741-0cb4-4179-b46d-9983421b8fdd",
              "geometry": null
            },
            "contactInfo": {
              "email": "mitchell.horner@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5018?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5018"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-3-lower-ocd-person-c4c6c741-0cb4-4179-b46d-9983421b8fdd": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/97a40ea4-674f-4b84-8fd3-8bc2f3b4a35d",
            "firstName": "Kasey Scott",
            "lastName": "Carpenter",
            "fullName": "Kasey Carpenter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/carpenter-kasey-4911.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-GA-4-lower-ocd-person-97a40ea4-674f-4b84-8fd3-8bc2f3b4a35d",
              "builtID": "ga-lower-4",
              "externalID": "ocd-person/97a40ea4-674f-4b84-8fd3-8bc2f3b4a35d",
              "geometry": null
            },
            "contactInfo": {
              "email": "kasey.carpenter@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4911&Session=27",
                "https://www.legis.ga.gov/members/house/4911?session=1029",
                "https://www.legis.ga.gov/members/house/4911?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4911"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-4-lower-ocd-person-97a40ea4-674f-4b84-8fd3-8bc2f3b4a35d": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/9103b29e-964b-4637-9304-0e73216d7796",
            "firstName": "Madison Fain",
            "lastName": "Barton",
            "fullName": "Matt Barton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/barton-matt-4966.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-GA-5-lower-ocd-person-9103b29e-964b-4637-9304-0e73216d7796",
              "builtID": "ga-lower-5",
              "externalID": "ocd-person/9103b29e-964b-4637-9304-0e73216d7796",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.barton@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "135 Victory Court, Calhoun, GA 30701",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4966&Session=27",
                "https://www.legis.ga.gov/members/house/4966?session=1029",
                "https://www.legis.ga.gov/members/house/4966?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4966"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-5-lower-ocd-person-9103b29e-964b-4637-9304-0e73216d7796": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/594aa971-500b-42a4-9d7a-1ceb4c029220",
            "firstName": "Jason Thomas",
            "lastName": "Ridley",
            "fullName": "Jason Ridley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/ridley-jason-4883.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-GA-6-lower-ocd-person-594aa971-500b-42a4-9d7a-1ceb4c029220",
              "builtID": "ga-lower-6",
              "externalID": "ocd-person/594aa971-500b-42a4-9d7a-1ceb4c029220",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.ridley@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4883&Session=27",
                "https://www.legis.ga.gov/members/house/4883?session=1029",
                "https://www.legis.ga.gov/members/house/4883?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4883"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-6-lower-ocd-person-594aa971-500b-42a4-9d7a-1ceb4c029220": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/8106e2d8-e1e3-42a8-b550-fbd2627fa474",
            "firstName": "John W.",
            "lastName": "Chastain",
            "fullName": "Johnny Chastain",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/chastain-johnny-5057.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-GA-7-lower-ocd-person-8106e2d8-e1e3-42a8-b550-fbd2627fa474",
              "builtID": "ga-lower-7",
              "externalID": "ocd-person/8106e2d8-e1e3-42a8-b550-fbd2627fa474",
              "geometry": null
            },
            "contactInfo": {
              "email": "johnny.chastain@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5057?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5057"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-7-lower-ocd-person-8106e2d8-e1e3-42a8-b550-fbd2627fa474": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/432ef767-d4c8-4736-b2ac-96397205fb3d",
            "firstName": "Norman Stanley",
            "lastName": "Gunter",
            "fullName": "Stan Gunter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/gunter-stan-4987.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-GA-8-lower-ocd-person-432ef767-d4c8-4736-b2ac-96397205fb3d",
              "builtID": "ga-lower-8",
              "externalID": "ocd-person/432ef767-d4c8-4736-b2ac-96397205fb3d",
              "geometry": null
            },
            "contactInfo": {
              "email": "stan.gunter@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 2376, Blairsville, GA 30514",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/4987?session=1029",
                "https://www.legis.ga.gov/members/house/4987?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4987"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-8-lower-ocd-person-432ef767-d4c8-4736-b2ac-96397205fb3d": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/97a002db-6151-4fdf-9bce-013a6344764f",
            "firstName": "William Solomon",
            "lastName": "Wade",
            "fullName": "Will Wade",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/wade-will-4988.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-GA-9-lower-ocd-person-97a002db-6151-4fdf-9bce-013a6344764f",
              "builtID": "ga-lower-9",
              "externalID": "ocd-person/97a002db-6151-4fdf-9bce-013a6344764f",
              "geometry": null
            },
            "contactInfo": {
              "email": "will.wade@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1685, Dawsonville, GA 30534",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/4988?session=1029",
                "https://www.legis.ga.gov/members/house/4988?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4988"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-9-lower-ocd-person-97a002db-6151-4fdf-9bce-013a6344764f": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/546d682f-b256-4060-8fd3-084d7f118d43",
            "firstName": "Victor Edward",
            "lastName": "Anderson",
            "fullName": "Victor Anderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/anderson-victor-4989.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-GA-10-lower-ocd-person-546d682f-b256-4060-8fd3-084d7f118d43",
              "builtID": "ga-lower-10",
              "externalID": "ocd-person/546d682f-b256-4060-8fd3-084d7f118d43",
              "geometry": null
            },
            "contactInfo": {
              "email": "victor.anderson@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1261, Cornelia, GA 30531",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/4989?session=1029",
                "https://www.legis.ga.gov/members/house/4989?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4989"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-10-lower-ocd-person-546d682f-b256-4060-8fd3-084d7f118d43": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/4d1f5379-01e9-4f58-9868-42acc07ec7a8",
            "firstName": "Richard Clark",
            "lastName": "Jasperse",
            "fullName": "Rick Jasperse",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/jasperse-rick-755.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-GA-11-lower-ocd-person-4d1f5379-01e9-4f58-9868-42acc07ec7a8",
              "builtID": "ga-lower-11",
              "externalID": "ocd-person/4d1f5379-01e9-4f58-9868-42acc07ec7a8",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.jasperse@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=755&Session=27",
                "https://www.legis.ga.gov/members/house/755?session=1029",
                "https://www.legis.ga.gov/members/house/755?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/755"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-11-lower-ocd-person-4d1f5379-01e9-4f58-9868-42acc07ec7a8": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/f33d979c-554c-433e-ad0f-837eef1da589",
            "firstName": "James Edward",
            "lastName": "Lumsden",
            "fullName": "Eddie Lumsden",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/lumsden-eddie-826.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-GA-12-lower-ocd-person-f33d979c-554c-433e-ad0f-837eef1da589",
              "builtID": "ga-lower-12",
              "externalID": "ocd-person/f33d979c-554c-433e-ad0f-837eef1da589",
              "geometry": null
            },
            "contactInfo": {
              "email": "eddie.lumsden@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=826&Session=27",
                "https://www.legis.ga.gov/members/house/826?session=1029",
                "https://www.legis.ga.gov/members/house/826?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/826"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-12-lower-ocd-person-f33d979c-554c-433e-ad0f-837eef1da589": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/bf68b3dc-ca81-49f9-8e33-fe27317183d3",
            "firstName": "Katie Montgomery",
            "lastName": "Dempsey",
            "fullName": "Katie Dempsey",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/dempsey-katie-92.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-GA-13-lower-ocd-person-bf68b3dc-ca81-49f9-8e33-fe27317183d3",
              "builtID": "ga-lower-13",
              "externalID": "ocd-person/bf68b3dc-ca81-49f9-8e33-fe27317183d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "katie.dempsey@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=92&Session=27",
                "https://www.legis.ga.gov/members/house/92?session=1029",
                "https://www.legis.ga.gov/members/house/92?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/92"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-13-lower-ocd-person-bf68b3dc-ca81-49f9-8e33-fe27317183d3": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/7ac30a66-1649-4a8c-a72f-a4b9a7ab1534",
            "firstName": "Mitchell",
            "lastName": "Scoggins",
            "fullName": "Mitchell Scoggins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/scoggins-mitchell-4964.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-GA-14-lower-ocd-person-7ac30a66-1649-4a8c-a72f-a4b9a7ab1534",
              "builtID": "ga-lower-14",
              "externalID": "ocd-person/7ac30a66-1649-4a8c-a72f-a4b9a7ab1534",
              "geometry": null
            },
            "contactInfo": {
              "email": "mitchell.scoggins@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4964&Session=27",
                "https://www.legis.ga.gov/members/house/4964?session=1029",
                "https://www.legis.ga.gov/members/house/4964?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4964"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-14-lower-ocd-person-7ac30a66-1649-4a8c-a72f-a4b9a7ab1534": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/909e9026-e549-494b-be99-e8562a389e0c",
            "firstName": "Matthew Harris",
            "lastName": "Gambill",
            "fullName": "Matthew Gambill",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/gambill-matthew-4934.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-GA-15-lower-ocd-person-909e9026-e549-494b-be99-e8562a389e0c",
              "builtID": "ga-lower-15",
              "externalID": "ocd-person/909e9026-e549-494b-be99-e8562a389e0c",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.gambill@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4934&Session=27",
                "https://www.legis.ga.gov/members/house/4934?session=1029",
                "https://www.legis.ga.gov/members/house/4934?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4934"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-15-lower-ocd-person-909e9026-e549-494b-be99-e8562a389e0c": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/6ac8933e-8dde-4902-8eec-61cc5a91c844",
            "firstName": "Trey",
            "lastName": "Kelley",
            "fullName": "Trey Kelley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/kelley-trey-825.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-GA-16-lower-ocd-person-6ac8933e-8dde-4902-8eec-61cc5a91c844",
              "builtID": "ga-lower-16",
              "externalID": "ocd-person/6ac8933e-8dde-4902-8eec-61cc5a91c844",
              "geometry": null
            },
            "contactInfo": {
              "email": "trey.kelley@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=825&Session=27",
                "https://www.legis.ga.gov/members/house/825?session=1029",
                "https://www.legis.ga.gov/members/house/825?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/825"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-16-lower-ocd-person-6ac8933e-8dde-4902-8eec-61cc5a91c844": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/6552dd8d-1020-4bbb-abfc-88975573ebd4",
            "firstName": "Martin",
            "lastName": "Momtahan",
            "fullName": "Martin Momtahan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/momtahan-martin-4935.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-GA-17-lower-ocd-person-6552dd8d-1020-4bbb-abfc-88975573ebd4",
              "builtID": "ga-lower-17",
              "externalID": "ocd-person/6552dd8d-1020-4bbb-abfc-88975573ebd4",
              "geometry": null
            },
            "contactInfo": {
              "email": "martin.momtahan@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4935&Session=27",
                "https://www.legis.ga.gov/members/house/4935?session=1029",
                "https://www.legis.ga.gov/members/house/4935?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4935"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-17-lower-ocd-person-6552dd8d-1020-4bbb-abfc-88975573ebd4": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/4b162447-89a7-4ffc-acbf-617e1ad87e2a",
            "firstName": "Tyler Paul",
            "lastName": "Smith",
            "fullName": "Tyler Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/smith-tyler-paul-4990.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-GA-18-lower-ocd-person-4b162447-89a7-4ffc-acbf-617e1ad87e2a",
              "builtID": "ga-lower-18",
              "externalID": "ocd-person/4b162447-89a7-4ffc-acbf-617e1ad87e2a",
              "geometry": null
            },
            "contactInfo": {
              "email": "tyler.smith@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3002 Cashtown Road, Bremen, GA 30110",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/4990?session=1029",
                "https://www.legis.ga.gov/members/house/4990?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4990"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-18-lower-ocd-person-4b162447-89a7-4ffc-acbf-617e1ad87e2a": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/f36d2507-b19a-4ee5-b4f9-5c06df761edf",
            "firstName": "Joseph",
            "lastName": "Gullett",
            "fullName": "Joseph Gullett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/gullett-joseph-4936.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-GA-19-lower-ocd-person-f36d2507-b19a-4ee5-b4f9-5c06df761edf",
              "builtID": "ga-lower-19",
              "externalID": "ocd-person/f36d2507-b19a-4ee5-b4f9-5c06df761edf",
              "geometry": null
            },
            "contactInfo": {
              "email": "joseph.gullett@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4936&Session=27",
                "https://www.legis.ga.gov/members/house/4936?session=1029",
                "https://www.legis.ga.gov/members/house/4936?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4936"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-19-lower-ocd-person-f36d2507-b19a-4ee5-b4f9-5c06df761edf": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/0d75f0d9-0242-4f72-b4f3-1cd085eb26b6",
            "firstName": "Charlice H.",
            "lastName": "Byrd",
            "fullName": "Charlice Byrd",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/byrd-charlice-75.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-GA-20-lower-ocd-person-0d75f0d9-0242-4f72-b4f3-1cd085eb26b6",
              "builtID": "ga-lower-20",
              "externalID": "ocd-person/0d75f0d9-0242-4f72-b4f3-1cd085eb26b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "charlice.byrd@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www1.legis.ga.gov/legis/2011_12/house/bios/byrdCharlice.htm",
                "https://www.legis.ga.gov/members/house/75?session=1029",
                "https://www.legis.ga.gov/members/house/75?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/75"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-20-lower-ocd-person-0d75f0d9-0242-4f72-b4f3-1cd085eb26b6": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/933a841a-19d6-42c2-a046-991ff9903a23",
            "firstName": "Brad",
            "lastName": "Thomas",
            "fullName": "Brad Thomas",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/thomas-brad-4991.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-GA-21-lower-ocd-person-933a841a-19d6-42c2-a046-991ff9903a23",
              "builtID": "ga-lower-21",
              "externalID": "ocd-person/933a841a-19d6-42c2-a046-991ff9903a23",
              "geometry": null
            },
            "contactInfo": {
              "email": "brad.thomas@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 401-F, Coverdell Legislative Office Building, Atlanta, GA 30334",
              "phone1": null,
              "phone2": "404-656-0152",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/4991?session=1029",
                "https://www.legis.ga.gov/members/house/4991?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4991"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-21-lower-ocd-person-933a841a-19d6-42c2-a046-991ff9903a23": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/56f28f8a-9296-4e69-9bd4-d0101a42b359",
            "firstName": "Jordan",
            "lastName": "Ridley",
            "fullName": "Jordan Ridley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/ridley-jordan-5019.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-GA-22-lower-ocd-person-56f28f8a-9296-4e69-9bd4-d0101a42b359",
              "builtID": "ga-lower-22",
              "externalID": "ocd-person/56f28f8a-9296-4e69-9bd4-d0101a42b359",
              "geometry": null
            },
            "contactInfo": {
              "email": "jordan.ridley@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5019?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5019"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-22-lower-ocd-person-56f28f8a-9296-4e69-9bd4-d0101a42b359": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/d6a8848d-a637-4c2e-ae37-84440c614400",
            "firstName": "Mandi L.",
            "lastName": "Ballinger",
            "fullName": "Mandi Ballinger",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/ballinger-mandi-808.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-GA-23-lower-ocd-person-d6a8848d-a637-4c2e-ae37-84440c614400",
              "builtID": "ga-lower-23",
              "externalID": "ocd-person/d6a8848d-a637-4c2e-ae37-84440c614400",
              "geometry": null
            },
            "contactInfo": {
              "email": "mandi.ballinger@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=808&Session=27",
                "https://www.legis.ga.gov/members/house/808?session=1029",
                "https://www.legis.ga.gov/members/house/808?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/808"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-23-lower-ocd-person-d6a8848d-a637-4c2e-ae37-84440c614400": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/72370a62-92f4-46f1-ab7b-193a643085b8",
            "firstName": "Carter",
            "lastName": "Barrett",
            "fullName": "Carter Barrett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/barrett-carter-5020.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-GA-24-lower-ocd-person-72370a62-92f4-46f1-ab7b-193a643085b8",
              "builtID": "ga-lower-24",
              "externalID": "ocd-person/72370a62-92f4-46f1-ab7b-193a643085b8",
              "geometry": null
            },
            "contactInfo": {
              "email": "carter.barrett@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5020?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5020"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-24-lower-ocd-person-72370a62-92f4-46f1-ab7b-193a643085b8": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/45567c87-3ba6-49ec-9ea2-5ebb880aa94c",
            "firstName": "Todd",
            "lastName": "Jones",
            "fullName": "Todd Jones",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/jones-todd-4885.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-GA-25-lower-ocd-person-45567c87-3ba6-49ec-9ea2-5ebb880aa94c",
              "builtID": "ga-lower-25",
              "externalID": "ocd-person/45567c87-3ba6-49ec-9ea2-5ebb880aa94c",
              "geometry": null
            },
            "contactInfo": {
              "email": "todd.jones@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4885&Session=27",
                "https://www.legis.ga.gov/members/house/4885?session=1029",
                "https://www.legis.ga.gov/members/house/4885?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4885"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-25-lower-ocd-person-45567c87-3ba6-49ec-9ea2-5ebb880aa94c": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/7767ee85-7961-45ba-adc2-c3606b1c39b8",
            "firstName": "Lauren Wylie",
            "lastName": "McDonald",
            "fullName": "Lauren McDonald",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/mcdonald-lauren-4992.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-GA-26-lower-ocd-person-7767ee85-7961-45ba-adc2-c3606b1c39b8",
              "builtID": "ga-lower-26",
              "externalID": "ocd-person/7767ee85-7961-45ba-adc2-c3606b1c39b8",
              "geometry": null
            },
            "contactInfo": {
              "email": "lauren.mcdonald@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4180 Creekwood Drive, Cumming, GA 30041",
              "phone1": null,
              "phone2": "770-886-9899",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/4992?session=1029",
                "https://www.legis.ga.gov/members/house/4992?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4992"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-26-lower-ocd-person-7767ee85-7961-45ba-adc2-c3606b1c39b8": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/69493144-3e3e-49a8-9e1f-f9a948fd0ff3",
            "firstName": "Lee",
            "lastName": "Hawkins",
            "fullName": "Lee Hawkins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/hawkins-lee-19.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-GA-27-lower-ocd-person-69493144-3e3e-49a8-9e1f-f9a948fd0ff3",
              "builtID": "ga-lower-27",
              "externalID": "ocd-person/69493144-3e3e-49a8-9e1f-f9a948fd0ff3",
              "geometry": null
            },
            "contactInfo": {
              "email": "lee.hawkins@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=19&Session=27",
                "https://www.legis.ga.gov/members/house/19?session=1029",
                "https://www.legis.ga.gov/members/house/19?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/19"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-27-lower-ocd-person-69493144-3e3e-49a8-9e1f-f9a948fd0ff3": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/c0b205e2-1542-4802-896d-fa7b2b6fc4d9",
            "firstName": "Brent",
            "lastName": "Cox",
            "fullName": "Brent Cox",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/cox-brent-5021.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-GA-28-lower-ocd-person-c0b205e2-1542-4802-896d-fa7b2b6fc4d9",
              "builtID": "ga-lower-28",
              "externalID": "ocd-person/c0b205e2-1542-4802-896d-fa7b2b6fc4d9",
              "geometry": null
            },
            "contactInfo": {
              "email": "brent.cox@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5021?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5021"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-28-lower-ocd-person-c0b205e2-1542-4802-896d-fa7b2b6fc4d9": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/99f0a2a7-8817-4756-a40e-575e259890e7",
            "firstName": "Matthew",
            "lastName": "Dubnik",
            "fullName": "Matt Dubnik",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/dubnik-matt-4886.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-GA-29-lower-ocd-person-99f0a2a7-8817-4756-a40e-575e259890e7",
              "builtID": "ga-lower-29",
              "externalID": "ocd-person/99f0a2a7-8817-4756-a40e-575e259890e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.dubnik@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4886&Session=27",
                "https://www.legis.ga.gov/members/house/4886?session=1029",
                "https://www.legis.ga.gov/members/house/4886?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4886"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-29-lower-ocd-person-99f0a2a7-8817-4756-a40e-575e259890e7": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/3a323603-753e-4380-993c-9bb2a7fc55f3",
            "firstName": "Derrick",
            "lastName": "McCollum",
            "fullName": "Derrick McCollum",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/mccollum-derrick-5022.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-GA-30-lower-ocd-person-3a323603-753e-4380-993c-9bb2a7fc55f3",
              "builtID": "ga-lower-30",
              "externalID": "ocd-person/3a323603-753e-4380-993c-9bb2a7fc55f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "derrick.mccollum@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5022?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5022"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-30-lower-ocd-person-3a323603-753e-4380-993c-9bb2a7fc55f3": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/3b2b9f31-6859-4582-8d82-581597c0c178",
            "firstName": "Emory West",
            "lastName": "Dunahoo",
            "fullName": "Emory Dunahoo",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/dunahoo-emory-797.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-GA-31-lower-ocd-person-3b2b9f31-6859-4582-8d82-581597c0c178",
              "builtID": "ga-lower-31",
              "externalID": "ocd-person/3b2b9f31-6859-4582-8d82-581597c0c178",
              "geometry": null
            },
            "contactInfo": {
              "email": "emory.dunahoo@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=797&Session=27",
                "https://www.legis.ga.gov/members/house/797?session=1029",
                "https://www.legis.ga.gov/members/house/797?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/797"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-31-lower-ocd-person-3b2b9f31-6859-4582-8d82-581597c0c178": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/d79c3e9b-b73d-416b-9bfd-8a0338d8256c",
            "firstName": "Chris",
            "lastName": "Erwin",
            "fullName": "Chris Erwin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/erwin-chris-4965.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-GA-32-lower-ocd-person-d79c3e9b-b73d-416b-9bfd-8a0338d8256c",
              "builtID": "ga-lower-32",
              "externalID": "ocd-person/d79c3e9b-b73d-416b-9bfd-8a0338d8256c",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.erwin@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 501029, Atlanta, GA 31150",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4965&Session=27",
                "https://www.legis.ga.gov/members/house/4965?session=1029",
                "https://www.legis.ga.gov/members/house/4965?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4965"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-32-lower-ocd-person-d79c3e9b-b73d-416b-9bfd-8a0338d8256c": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/bb48e87c-29db-4a4d-a278-fa299be2020e",
            "firstName": "Alan",
            "lastName": "Powell",
            "fullName": "Alan Powell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/powell-alan-187.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-GA-33-lower-ocd-person-bb48e87c-29db-4a4d-a278-fa299be2020e",
              "builtID": "ga-lower-33",
              "externalID": "ocd-person/bb48e87c-29db-4a4d-a278-fa299be2020e",
              "geometry": null
            },
            "contactInfo": {
              "email": "alan.powell@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=187&Session=27",
                "https://www.legis.ga.gov/members/house/187?session=1029",
                "https://www.legis.ga.gov/members/house/187?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/187"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-33-lower-ocd-person-bb48e87c-29db-4a4d-a278-fa299be2020e": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/8f76af22-98dc-4e1e-ba99-75b87c1129d6",
            "firstName": "Devan",
            "lastName": "Seabaugh",
            "fullName": "Devan Seabaugh",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/seabaugh-devan-5009.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-GA-34-lower-ocd-person-8f76af22-98dc-4e1e-ba99-75b87c1129d6",
              "builtID": "ga-lower-34",
              "externalID": "ocd-person/8f76af22-98dc-4e1e-ba99-75b87c1129d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "devan.seabaugh@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5009?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5009"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-34-lower-ocd-person-8f76af22-98dc-4e1e-ba99-75b87c1129d6": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/3a23acfd-5ce6-4e47-b641-1efdab05bdca",
            "firstName": "Lisa Gayle",
            "lastName": "Campbell",
            "fullName": "Lisa Campbell",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/campbell-lisa-5023.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-GA-35-lower-ocd-person-3a23acfd-5ce6-4e47-b641-1efdab05bdca",
              "builtID": "ga-lower-35",
              "externalID": "ocd-person/3a23acfd-5ce6-4e47-b641-1efdab05bdca",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisa.campbell@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2001 Duncan Drive Unit #2281, Kennesaw, GA 30156",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5023?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5023"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-35-lower-ocd-person-3a23acfd-5ce6-4e47-b641-1efdab05bdca": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/be05ace3-ea73-4f3f-85ae-c5ed313d4cbe",
            "firstName": "Ginny",
            "lastName": "Ehrhart",
            "fullName": "Ginny Ehrhart",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/ehrhart-ginny-4937.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-GA-36-lower-ocd-person-be05ace3-ea73-4f3f-85ae-c5ed313d4cbe",
              "builtID": "ga-lower-36",
              "externalID": "ocd-person/be05ace3-ea73-4f3f-85ae-c5ed313d4cbe",
              "geometry": null
            },
            "contactInfo": {
              "email": "ginny.ehrhart@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4937&Session=27",
                "https://www.legis.ga.gov/members/house/4937?session=1029",
                "https://www.legis.ga.gov/members/house/4937?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4937"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-36-lower-ocd-person-be05ace3-ea73-4f3f-85ae-c5ed313d4cbe": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/4aec761f-5117-4f43-a487-9d77418f0bfa",
            "firstName": "Mary Frances",
            "lastName": "Williams",
            "fullName": "Mary Frances Williams",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/williams-mary-frances-4938.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-GA-37-lower-ocd-person-4aec761f-5117-4f43-a487-9d77418f0bfa",
              "builtID": "ga-lower-37",
              "externalID": "ocd-person/4aec761f-5117-4f43-a487-9d77418f0bfa",
              "geometry": null
            },
            "contactInfo": {
              "email": "maryfrances.williams@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4938&Session=27",
                "https://www.legis.ga.gov/members/house/4938?session=1029",
                "https://www.legis.ga.gov/members/house/4938?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4938"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-37-lower-ocd-person-4aec761f-5117-4f43-a487-9d77418f0bfa": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/83a7bbf2-d69c-4813-bbeb-52820e6b9af4",
            "firstName": "David",
            "lastName": "Wilkerson",
            "fullName": "David Wilkerson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/wilkerson-david-760.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-GA-38-lower-ocd-person-83a7bbf2-d69c-4813-bbeb-52820e6b9af4",
              "builtID": "ga-lower-38",
              "externalID": "ocd-person/83a7bbf2-d69c-4813-bbeb-52820e6b9af4",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.wilkerson@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=760&Session=27",
                "https://www.legis.ga.gov/members/house/760?session=1029",
                "https://www.legis.ga.gov/members/house/760?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/760"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-38-lower-ocd-person-83a7bbf2-d69c-4813-bbeb-52820e6b9af4": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/aa7405f9-5c37-4ce8-a7eb-ac5949a6e776",
            "firstName": "Terry Alexis",
            "lastName": "Cummings",
            "fullName": "Terry Cummings",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/cummings-terry-5024.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-GA-39-lower-ocd-person-aa7405f9-5c37-4ce8-a7eb-ac5949a6e776",
              "builtID": "ga-lower-39",
              "externalID": "ocd-person/aa7405f9-5c37-4ce8-a7eb-ac5949a6e776",
              "geometry": null
            },
            "contactInfo": {
              "email": "terry.cummings@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1400 Veterans Memorial Highway Suite 124-265, Mableton, GA 30126",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5024?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-39-lower-ocd-person-aa7405f9-5c37-4ce8-a7eb-ac5949a6e776": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/52457c0b-9f25-469e-958c-30327af42ee1",
            "firstName": "Doug",
            "lastName": "Stoner",
            "fullName": "Doug Stoner",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/stoner-doug-45.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-GA-40-lower-ocd-person-52457c0b-9f25-469e-958c-30327af42ee1",
              "builtID": "ga-lower-40",
              "externalID": "ocd-person/52457c0b-9f25-469e-958c-30327af42ee1",
              "geometry": null
            },
            "contactInfo": {
              "email": "doug.stoner@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/45?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/45"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-40-lower-ocd-person-52457c0b-9f25-469e-958c-30327af42ee1": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/68185f1d-fa54-4571-b305-fcd6c7dc024e",
            "firstName": "Michael A.",
            "lastName": "Smith",
            "fullName": "Michael Smith",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/smith-michael-832.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-GA-41-lower-ocd-person-68185f1d-fa54-4571-b305-fcd6c7dc024e",
              "builtID": "ga-lower-41",
              "externalID": "ocd-person/68185f1d-fa54-4571-b305-fcd6c7dc024e",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.smith@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=832&Session=27",
                "https://www.legis.ga.gov/members/house/832?session=1029",
                "https://www.legis.ga.gov/members/house/832?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/832"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-41-lower-ocd-person-68185f1d-fa54-4571-b305-fcd6c7dc024e": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/06eaa836-9c03-4dc0-800d-e08f57333d19",
            "firstName": "Teri Lippincott",
            "lastName": "Anulewicz",
            "fullName": "Teri Anulewicz",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/anulewicz-teri-4915.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-GA-42-lower-ocd-person-06eaa836-9c03-4dc0-800d-e08f57333d19",
              "builtID": "ga-lower-42",
              "externalID": "ocd-person/06eaa836-9c03-4dc0-800d-e08f57333d19",
              "geometry": null
            },
            "contactInfo": {
              "email": "teri.anulewicz@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1385, Smyrna, GA 30081",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4915&Session=27",
                "https://www.legis.ga.gov/members/house/4915?session=1029",
                "https://www.legis.ga.gov/members/house/4915?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4915"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-42-lower-ocd-person-06eaa836-9c03-4dc0-800d-e08f57333d19": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/5474fbe2-6cdb-4ad2-9831-cdffaca442af",
            "firstName": "Solomon",
            "lastName": "Adesanya",
            "fullName": "Solomon Adesanya",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/adesanya-solomon-5025.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-GA-43-lower-ocd-person-5474fbe2-6cdb-4ad2-9831-cdffaca442af",
              "builtID": "ga-lower-43",
              "externalID": "ocd-person/5474fbe2-6cdb-4ad2-9831-cdffaca442af",
              "geometry": null
            },
            "contactInfo": {
              "email": "solomon.adesanya@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5025?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5025"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-43-lower-ocd-person-5474fbe2-6cdb-4ad2-9831-cdffaca442af": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/81dde544-49e9-4fb0-9313-32eacaf6194b",
            "firstName": "Don",
            "lastName": "Parsons",
            "fullName": "Don Parsons",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/parsons-don-184.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-GA-44-lower-ocd-person-81dde544-49e9-4fb0-9313-32eacaf6194b",
              "builtID": "ga-lower-44",
              "externalID": "ocd-person/81dde544-49e9-4fb0-9313-32eacaf6194b",
              "geometry": null
            },
            "contactInfo": {
              "email": "don.parsons@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=184&Session=27",
                "https://www.legis.ga.gov/members/house/184?session=1029",
                "https://www.legis.ga.gov/members/house/184?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/184"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-44-lower-ocd-person-81dde544-49e9-4fb0-9313-32eacaf6194b": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/15db25fc-273b-467f-b8f9-897337f40b65",
            "firstName": "Sharon",
            "lastName": "Cooper",
            "fullName": "Sharon Cooper",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/cooper-sharon-86.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-GA-45-lower-ocd-person-15db25fc-273b-467f-b8f9-897337f40b65",
              "builtID": "ga-lower-45",
              "externalID": "ocd-person/15db25fc-273b-467f-b8f9-897337f40b65",
              "geometry": null
            },
            "contactInfo": {
              "email": "sharon.cooper@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=86&Session=27",
                "https://www.legis.ga.gov/members/house/86?session=1029",
                "https://www.legis.ga.gov/members/house/86?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/86"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-45-lower-ocd-person-15db25fc-273b-467f-b8f9-897337f40b65": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/713cac52-0a57-4baa-8273-6eeca5b491df",
            "firstName": "John K.",
            "lastName": "Carson",
            "fullName": "John Carson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/carson-john-795.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-GA-46-lower-ocd-person-713cac52-0a57-4baa-8273-6eeca5b491df",
              "builtID": "ga-lower-46",
              "externalID": "ocd-person/713cac52-0a57-4baa-8273-6eeca5b491df",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.carson@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=795&Session=27",
                "https://www.legis.ga.gov/members/house/795?session=1029",
                "https://www.legis.ga.gov/members/house/795?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/795"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-46-lower-ocd-person-713cac52-0a57-4baa-8273-6eeca5b491df": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/85740034-23b0-4001-9190-8948bd9f3b37",
            "firstName": "Jan Slaughter",
            "lastName": "Jones",
            "fullName": "Jan Jones",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/jones-jan-142.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-GA-47-lower-ocd-person-85740034-23b0-4001-9190-8948bd9f3b37",
              "builtID": "ga-lower-47",
              "externalID": "ocd-person/85740034-23b0-4001-9190-8948bd9f3b37",
              "geometry": null
            },
            "contactInfo": {
              "email": "jan.jones@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=142&Session=27",
                "https://www.legis.ga.gov/members/house/142?session=1029",
                "https://www.legis.ga.gov/members/house/142?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/142"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-47-lower-ocd-person-85740034-23b0-4001-9190-8948bd9f3b37": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/91abe321-5ea4-4f17-b8d8-573d7cb913e6",
            "firstName": "Scott",
            "lastName": "Hilton",
            "fullName": "Scott Hilton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/hilton-scott-4899.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-GA-48-lower-ocd-person-91abe321-5ea4-4f17-b8d8-573d7cb913e6",
              "builtID": "ga-lower-48",
              "externalID": "ocd-person/91abe321-5ea4-4f17-b8d8-573d7cb913e6",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.hilton@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/4899?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4899"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-48-lower-ocd-person-91abe321-5ea4-4f17-b8d8-573d7cb913e6": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/42517dd7-eccb-4d22-a795-cbd010b69ee6",
            "firstName": "Charles E.",
            "lastName": "Martin",
            "fullName": "Chuck Martin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/martin-chuck-164.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-GA-49-lower-ocd-person-42517dd7-eccb-4d22-a795-cbd010b69ee6",
              "builtID": "ga-lower-49",
              "externalID": "ocd-person/42517dd7-eccb-4d22-a795-cbd010b69ee6",
              "geometry": null
            },
            "contactInfo": {
              "email": "chuck.martin@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=164&Session=27",
                "https://www.legis.ga.gov/members/house/164?session=1029",
                "https://www.legis.ga.gov/members/house/164?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/164"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-49-lower-ocd-person-42517dd7-eccb-4d22-a795-cbd010b69ee6": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/48b46caf-d38e-46ab-96ff-c268e23a7d76",
            "firstName": "Michelle Hsiao",
            "lastName": "Au",
            "fullName": "Michelle Au",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/au-michelle-4983.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-GA-50-lower-ocd-person-48b46caf-d38e-46ab-96ff-c268e23a7d76",
              "builtID": "ga-lower-50",
              "externalID": "ocd-person/48b46caf-d38e-46ab-96ff-c268e23a7d76",
              "geometry": null
            },
            "contactInfo": {
              "email": "michelle.au@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/senate/4983?session=1029",
                "https://www.legis.ga.gov/members/house/4983?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4983"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-50-lower-ocd-person-48b46caf-d38e-46ab-96ff-c268e23a7d76": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/bdc621be-7296-4383-8c45-b4533d1c106c",
            "firstName": "Esther Dina Feuer",
            "lastName": "Panitch",
            "fullName": "Esther Panitch",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/panitch-esther-5026.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-GA-51-lower-ocd-person-bdc621be-7296-4383-8c45-b4533d1c106c",
              "builtID": "ga-lower-51",
              "externalID": "ocd-person/bdc621be-7296-4383-8c45-b4533d1c106c",
              "geometry": null
            },
            "contactInfo": {
              "email": "esther.panitch@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5026?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5026"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-51-lower-ocd-person-bdc621be-7296-4383-8c45-b4533d1c106c": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/9007dcd9-3246-4cf3-9185-7478b3a9ad7a",
            "firstName": "Shea",
            "lastName": "Roberts",
            "fullName": "Shea Roberts",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/roberts-shea-4994.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-GA-52-lower-ocd-person-9007dcd9-3246-4cf3-9185-7478b3a9ad7a",
              "builtID": "ga-lower-52",
              "externalID": "ocd-person/9007dcd9-3246-4cf3-9185-7478b3a9ad7a",
              "geometry": null
            },
            "contactInfo": {
              "email": "shea.roberts@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 28571, Atlanta, GA 30358",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/4994?session=1029",
                "https://www.legis.ga.gov/members/house/4994?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4994"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-52-lower-ocd-person-9007dcd9-3246-4cf3-9185-7478b3a9ad7a": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/b824f8fd-7343-449d-8027-fcd0afaf1949",
            "firstName": "Deborah D.",
            "lastName": "Silcox",
            "fullName": "Deborah Silcox",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/silcox-deborah-4887.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-GA-53-lower-ocd-person-b824f8fd-7343-449d-8027-fcd0afaf1949",
              "builtID": "ga-lower-53",
              "externalID": "ocd-person/b824f8fd-7343-449d-8027-fcd0afaf1949",
              "geometry": null
            },
            "contactInfo": {
              "email": "deborah.silcox@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4887&Session=27",
                "https://www.legis.ga.gov/members/house/4887?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4887"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-53-lower-ocd-person-b824f8fd-7343-449d-8027-fcd0afaf1949": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/c2c81ffc-f4b1-40f4-96b0-6309f89902ee",
            "firstName": "Betsy",
            "lastName": "Holland",
            "fullName": "Betsy Holland",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/holland-betsy-4943.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-GA-54-lower-ocd-person-c2c81ffc-f4b1-40f4-96b0-6309f89902ee",
              "builtID": "ga-lower-54",
              "externalID": "ocd-person/c2c81ffc-f4b1-40f4-96b0-6309f89902ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "betsy.holland@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4943&Session=27",
                "https://www.legis.ga.gov/members/house/4943?session=1029",
                "https://www.legis.ga.gov/members/house/4943?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4943"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-54-lower-ocd-person-c2c81ffc-f4b1-40f4-96b0-6309f89902ee": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/f02d369f-2d48-4d8d-853d-77ce5a8810fc",
            "firstName": "Inga S.",
            "lastName": "Willis",
            "fullName": "Inga Willis",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/willis-inga-5027.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-GA-55-lower-ocd-person-f02d369f-2d48-4d8d-853d-77ce5a8810fc",
              "builtID": "ga-lower-55",
              "externalID": "ocd-person/f02d369f-2d48-4d8d-853d-77ce5a8810fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "inga.willis@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5027?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5027"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-55-lower-ocd-person-f02d369f-2d48-4d8d-853d-77ce5a8810fc": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/286a99c2-83e5-4b4c-ab0f-1a73eb47f884",
            "firstName": "Mesha",
            "lastName": "Mainor",
            "fullName": "Mesha Mainor",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/mainor-mesha-4995.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-GA-56-lower-ocd-person-286a99c2-83e5-4b4c-ab0f-1a73eb47f884",
              "builtID": "ga-lower-56",
              "externalID": "ocd-person/286a99c2-83e5-4b4c-ab0f-1a73eb47f884",
              "geometry": null
            },
            "contactInfo": {
              "email": "mesha.mainor@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/4995?session=1029",
                "https://www.legis.ga.gov/members/house/4995?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4995"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-56-lower-ocd-person-286a99c2-83e5-4b4c-ab0f-1a73eb47f884": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/fa5d8d09-a0b9-4ba4-93da-a24902530416",
            "firstName": "Stacey Godfrey",
            "lastName": "Evans",
            "fullName": "Stacey Evans",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/evans-stacey-762.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-GA-57-lower-ocd-person-fa5d8d09-a0b9-4ba4-93da-a24902530416",
              "builtID": "ga-lower-57",
              "externalID": "ocd-person/fa5d8d09-a0b9-4ba4-93da-a24902530416",
              "geometry": null
            },
            "contactInfo": {
              "email": "stacey.evans@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "750 Piedmont Ave. NE, Atlanta, GA 30308",
              "phone1": null,
              "phone2": "770-710-4087",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=762&Session=25",
                "https://www.legis.ga.gov/members/house/762?session=1029",
                "https://www.legis.ga.gov/members/house/762?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/762"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-57-lower-ocd-person-fa5d8d09-a0b9-4ba4-93da-a24902530416": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/5c5ab5cb-54f6-4218-8d1d-a99d36bca436",
            "firstName": "Park Elizabeth",
            "lastName": "Cannon",
            "fullName": "Park Cannon",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/cannon-park-4880.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-GA-58-lower-ocd-person-5c5ab5cb-54f6-4218-8d1d-a99d36bca436",
              "builtID": "ga-lower-58",
              "externalID": "ocd-person/5c5ab5cb-54f6-4218-8d1d-a99d36bca436",
              "geometry": null
            },
            "contactInfo": {
              "email": "park.cannon@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4880&Session=27",
                "https://www.legis.ga.gov/members/house/4880?session=1029",
                "https://www.legis.ga.gov/members/house/4880?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4880"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-58-lower-ocd-person-5c5ab5cb-54f6-4218-8d1d-a99d36bca436": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/80928bd9-c963-4b21-954c-c62ee9373064",
            "firstName": "Phil",
            "lastName": "Olaleye",
            "fullName": "Phil Olaleye",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/olaleye-phil-5028.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-GA-59-lower-ocd-person-80928bd9-c963-4b21-954c-c62ee9373064",
              "builtID": "ga-lower-59",
              "externalID": "ocd-person/80928bd9-c963-4b21-954c-c62ee9373064",
              "geometry": null
            },
            "contactInfo": {
              "email": "phil.olaleye@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5028?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5028"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-59-lower-ocd-person-80928bd9-c963-4b21-954c-c62ee9373064": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/b278b399-5ca3-4cd7-bc19-4053166577a2",
            "firstName": "Sheila",
            "lastName": "Jones",
            "fullName": "Sheila Jones",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/jones-sheila-143.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-GA-60-lower-ocd-person-b278b399-5ca3-4cd7-bc19-4053166577a2",
              "builtID": "ga-lower-60",
              "externalID": "ocd-person/b278b399-5ca3-4cd7-bc19-4053166577a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "sheila.jones@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3246 Amhurst Drive, NW, Atlanta, GA 30318",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=143&Session=27",
                "https://www.legis.ga.gov/members/house/143?session=1029",
                "https://www.legis.ga.gov/members/house/143?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/143"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-60-lower-ocd-person-b278b399-5ca3-4cd7-bc19-4053166577a2": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/d32a6c75-5527-443e-b8a1-2d2931da643a",
            "firstName": "Roger B.",
            "lastName": "Bruce",
            "fullName": "Roger Bruce",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/bruce-roger-69.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-GA-61-lower-ocd-person-d32a6c75-5527-443e-b8a1-2d2931da643a",
              "builtID": "ga-lower-61",
              "externalID": "ocd-person/d32a6c75-5527-443e-b8a1-2d2931da643a",
              "geometry": null
            },
            "contactInfo": {
              "email": "roger.bruce@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=69&Session=27",
                "https://www.legis.ga.gov/members/house/69?session=1029",
                "https://www.legis.ga.gov/members/house/69?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/69"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-61-lower-ocd-person-d32a6c75-5527-443e-b8a1-2d2931da643a": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/6e5432a3-09ed-4813-b11d-e97222bb7e4d",
            "firstName": "Tanya Felecia",
            "lastName": "Miller",
            "fullName": "Tanya Miller",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/miller-tanya-f-5029.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-GA-62-lower-ocd-person-6e5432a3-09ed-4813-b11d-e97222bb7e4d",
              "builtID": "ga-lower-62",
              "externalID": "ocd-person/6e5432a3-09ed-4813-b11d-e97222bb7e4d",
              "geometry": null
            },
            "contactInfo": {
              "email": "tanya.miller@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5029?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-62-lower-ocd-person-6e5432a3-09ed-4813-b11d-e97222bb7e4d": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/eff357d0-3187-4a7b-93fe-2d54d010b093",
            "firstName": "Kim",
            "lastName": "Schofield",
            "fullName": "Kim Schofield",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/schofield-kim-4916.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-GA-63-lower-ocd-person-eff357d0-3187-4a7b-93fe-2d54d010b093",
              "builtID": "ga-lower-63",
              "externalID": "ocd-person/eff357d0-3187-4a7b-93fe-2d54d010b093",
              "geometry": null
            },
            "contactInfo": {
              "email": "kim.schoefield@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4916&Session=27",
                "https://www.legis.ga.gov/members/house/4916?session=1029",
                "https://www.legis.ga.gov/members/house/4916?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4916"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-63-lower-ocd-person-eff357d0-3187-4a7b-93fe-2d54d010b093": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/fc1a5e1f-6ce4-47ab-987b-095afa166359",
            "firstName": "Kimberly R.",
            "lastName": "New",
            "fullName": "Kimberly New",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/new-kimberly-5030.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-GA-64-lower-ocd-person-fc1a5e1f-6ce4-47ab-987b-095afa166359",
              "builtID": "ga-lower-64",
              "externalID": "ocd-person/fc1a5e1f-6ce4-47ab-987b-095afa166359",
              "geometry": null
            },
            "contactInfo": {
              "email": "kimberly.new@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5030?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5030"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-64-lower-ocd-person-fc1a5e1f-6ce4-47ab-987b-095afa166359": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/77cc5831-bac7-499d-a9b6-2e444517021c",
            "firstName": "Mandisha A.",
            "lastName": "Thomas",
            "fullName": "Mandisha Thomas",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/thomas-mandisha-4996.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-GA-65-lower-ocd-person-77cc5831-bac7-499d-a9b6-2e444517021c",
              "builtID": "ga-lower-65",
              "externalID": "ocd-person/77cc5831-bac7-499d-a9b6-2e444517021c",
              "geometry": null
            },
            "contactInfo": {
              "email": "mandisha.thomas@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/4996?session=1029",
                "https://www.legis.ga.gov/members/house/4996?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4996"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-65-lower-ocd-person-77cc5831-bac7-499d-a9b6-2e444517021c": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/275b772e-6407-4aaa-8bd6-ceddad1da7fa",
            "firstName": "Kimberly A.",
            "lastName": "Alexander",
            "fullName": "Kimberly Alexander",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/alexander-kimberly-806.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-GA-66-lower-ocd-person-275b772e-6407-4aaa-8bd6-ceddad1da7fa",
              "builtID": "ga-lower-66",
              "externalID": "ocd-person/275b772e-6407-4aaa-8bd6-ceddad1da7fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "kimberly.alexander@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 393, Hiram, GA 30141",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=806&Session=27",
                "https://www.legis.ga.gov/members/house/806?session=1029",
                "https://www.legis.ga.gov/members/house/806?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/806"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-66-lower-ocd-person-275b772e-6407-4aaa-8bd6-ceddad1da7fa": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/ee575d7a-b5a1-419c-949d-4aa9290a67a1",
            "firstName": "Lydia",
            "lastName": "Glaize",
            "fullName": "Lydia Glaize",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/glaize-lydia-5031.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-GA-67-lower-ocd-person-ee575d7a-b5a1-419c-949d-4aa9290a67a1",
              "builtID": "ga-lower-67",
              "externalID": "ocd-person/ee575d7a-b5a1-419c-949d-4aa9290a67a1",
              "geometry": null
            },
            "contactInfo": {
              "email": "lydia.glaize@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5031?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-67-lower-ocd-person-ee575d7a-b5a1-419c-949d-4aa9290a67a1": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/9046fcb2-f3eb-4233-bd92-32c94e60f9df",
            "firstName": "Derrick",
            "lastName": "Jackson",
            "fullName": "Derrick Jackson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/jackson-derrick-4891.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-GA-68-lower-ocd-person-9046fcb2-f3eb-4233-bd92-32c94e60f9df",
              "builtID": "ga-lower-68",
              "externalID": "ocd-person/9046fcb2-f3eb-4233-bd92-32c94e60f9df",
              "geometry": null
            },
            "contactInfo": {
              "email": "derrick.jackson@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4891?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-68-lower-ocd-person-9046fcb2-f3eb-4233-bd92-32c94e60f9df": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/1bb8d14b-9d5d-4edd-8c93-37e95991a8e4",
            "firstName": "Debra",
            "lastName": "Jones-Bazemore",
            "fullName": "Debra Bazemore",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/bazemore-debra-4890.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-GA-69-lower-ocd-person-1bb8d14b-9d5d-4edd-8c93-37e95991a8e4",
              "builtID": "ga-lower-69",
              "externalID": "ocd-person/1bb8d14b-9d5d-4edd-8c93-37e95991a8e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "debra.bazemore@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4890&Session=27",
                "https://www.legis.ga.gov/members/house/4890?session=1029",
                "https://www.legis.ga.gov/members/house/4890?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4890"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-69-lower-ocd-person-1bb8d14b-9d5d-4edd-8c93-37e95991a8e4": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/945875f7-f3c5-4c75-acd6-a195a0f76b12",
            "firstName": "Lynn Ratigan",
            "lastName": "Smith",
            "fullName": "Lynn Smith",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/smith-lynn-212.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-GA-70-lower-ocd-person-945875f7-f3c5-4c75-acd6-a195a0f76b12",
              "builtID": "ga-lower-70",
              "externalID": "ocd-person/945875f7-f3c5-4c75-acd6-a195a0f76b12",
              "geometry": null
            },
            "contactInfo": {
              "email": "lynn.smith@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=212&Session=27",
                "https://www.legis.ga.gov/members/house/212?session=1029",
                "https://www.legis.ga.gov/members/house/212?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/212"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-70-lower-ocd-person-945875f7-f3c5-4c75-acd6-a195a0f76b12": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/bda95ef9-7054-4100-ab95-498abe8b892f",
            "firstName": "James",
            "lastName": "Collins",
            "fullName": "J. Collins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/collins-j-4892.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-GA-71-lower-ocd-person-bda95ef9-7054-4100-ab95-498abe8b892f",
              "builtID": "ga-lower-71",
              "externalID": "ocd-person/bda95ef9-7054-4100-ab95-498abe8b892f",
              "geometry": null
            },
            "contactInfo": {
              "email": "j.collins@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4892&Session=27",
                "https://www.legis.ga.gov/members/house/4892?session=1029",
                "https://www.legis.ga.gov/members/house/4892?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4892"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-71-lower-ocd-person-bda95ef9-7054-4100-ab95-498abe8b892f": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/9bcfa0eb-ee7d-4762-9555-7bc4a67d6417",
            "firstName": "David",
            "lastName": "Huddleston",
            "fullName": "David Huddleston",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/huddleston-david-5033.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-GA-72-lower-ocd-person-9bcfa0eb-ee7d-4762-9555-7bc4a67d6417",
              "builtID": "ga-lower-72",
              "externalID": "ocd-person/9bcfa0eb-ee7d-4762-9555-7bc4a67d6417",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.huddleston@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5033?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5033"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-72-lower-ocd-person-9bcfa0eb-ee7d-4762-9555-7bc4a67d6417": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/0e45048c-c1a4-415f-9ee4-5d09ea857e2e",
            "firstName": "Josh",
            "lastName": "Bonner",
            "fullName": "Josh Bonner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/bonner-josh-4893.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-GA-73-lower-ocd-person-0e45048c-c1a4-415f-9ee4-5d09ea857e2e",
              "builtID": "ga-lower-73",
              "externalID": "ocd-person/0e45048c-c1a4-415f-9ee4-5d09ea857e2e",
              "geometry": null
            },
            "contactInfo": {
              "email": "josh.bonner@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4893&Session=27",
                "https://www.legis.ga.gov/members/house/4893?session=1029",
                "https://www.legis.ga.gov/members/house/4893?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4893"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-73-lower-ocd-person-0e45048c-c1a4-415f-9ee4-5d09ea857e2e": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/f8db50e0-08ab-406c-ac8b-39c7038ef5ca",
            "firstName": "Karen",
            "lastName": "Mathiak",
            "fullName": "Karen Mathiak",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/mathiak-karen-4894.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-GA-74-lower-ocd-person-f8db50e0-08ab-406c-ac8b-39c7038ef5ca",
              "builtID": "ga-lower-74",
              "externalID": "ocd-person/f8db50e0-08ab-406c-ac8b-39c7038ef5ca",
              "geometry": null
            },
            "contactInfo": {
              "email": "karen.mathiak@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4894&Session=27",
                "https://www.legis.ga.gov/members/house/4894?session=1029",
                "https://www.legis.ga.gov/members/house/4894?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4894"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-74-lower-ocd-person-f8db50e0-08ab-406c-ac8b-39c7038ef5ca": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/fe4b934e-8976-4c62-bcb0-ed8ae9782560",
            "firstName": "Eric W.",
            "lastName": "Bell",
            "fullName": "Eric Bell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/bell-eric-5061.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-GA-75-lower-ocd-person-fe4b934e-8976-4c62-bcb0-ed8ae9782560",
              "builtID": "ga-lower-75",
              "externalID": "ocd-person/fe4b934e-8976-4c62-bcb0-ed8ae9782560",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.bell@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5061?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5061"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-75-lower-ocd-person-fe4b934e-8976-4c62-bcb0-ed8ae9782560": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/21c6cae1-bcd2-4403-ad39-af04b64ce55c",
            "firstName": "Sandra G.",
            "lastName": "Scott",
            "fullName": "Sandra Scott",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/scott-sandra-766.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-GA-76-lower-ocd-person-21c6cae1-bcd2-4403-ad39-af04b64ce55c",
              "builtID": "ga-lower-76",
              "externalID": "ocd-person/21c6cae1-bcd2-4403-ad39-af04b64ce55c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sandra.scott@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=766&Session=27",
                "https://www.legis.ga.gov/members/house/766?session=1029",
                "https://www.legis.ga.gov/members/house/766?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/766"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-76-lower-ocd-person-21c6cae1-bcd2-4403-ad39-af04b64ce55c": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/e04bd985-5b75-4551-84a1-f9797ac495d2",
            "firstName": "Rhonda",
            "lastName": "Burnough",
            "fullName": "Rhonda Burnough",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/burnough-rhonda-4895.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-GA-77-lower-ocd-person-e04bd985-5b75-4551-84a1-f9797ac495d2",
              "builtID": "ga-lower-77",
              "externalID": "ocd-person/e04bd985-5b75-4551-84a1-f9797ac495d2",
              "geometry": null
            },
            "contactInfo": {
              "email": "rhonda.burnough@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4895&Session=27",
                "https://www.legis.ga.gov/members/house/4895?session=1029",
                "https://www.legis.ga.gov/members/house/4895?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4895"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-77-lower-ocd-person-e04bd985-5b75-4551-84a1-f9797ac495d2": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/016fb7f0-2b08-4587-b7f2-8d18c1d9b8f5",
            "firstName": "Demetrius",
            "lastName": "Douglas",
            "fullName": "Demetrius Douglas",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/douglas-demetrius-817.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-GA-78-lower-ocd-person-016fb7f0-2b08-4587-b7f2-8d18c1d9b8f5",
              "builtID": "ga-lower-78",
              "externalID": "ocd-person/016fb7f0-2b08-4587-b7f2-8d18c1d9b8f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "demetrius.douglas@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=817&Session=27",
                "https://www.legis.ga.gov/members/house/817?session=1029",
                "https://www.legis.ga.gov/members/house/817?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/817"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-78-lower-ocd-person-016fb7f0-2b08-4587-b7f2-8d18c1d9b8f5": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/6f986b42-14f6-4147-9466-c49294331db9",
            "firstName": "Yasmin",
            "lastName": "Neal",
            "fullName": "Yasmin Neal",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/neal-yasmin-765.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-GA-79-lower-ocd-person-6f986b42-14f6-4147-9466-c49294331db9",
              "builtID": "ga-lower-79",
              "externalID": "ocd-person/6f986b42-14f6-4147-9466-c49294331db9",
              "geometry": null
            },
            "contactInfo": {
              "email": "yasmin.neal@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www1.legis.ga.gov/legis/2011_12/house/bios/nealYasmin.html",
                "https://www.legis.ga.gov/members/house/765?session=1029",
                "https://www.legis.ga.gov/members/house/765?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/765"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-79-lower-ocd-person-6f986b42-14f6-4147-9466-c49294331db9": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/7475f989-a4f9-4ac8-9188-26903a012d71",
            "firstName": "Long",
            "lastName": "Tran",
            "fullName": "Long Tran",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/tran-long-5034.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-GA-80-lower-ocd-person-7475f989-a4f9-4ac8-9188-26903a012d71",
              "builtID": "ga-lower-80",
              "externalID": "ocd-person/7475f989-a4f9-4ac8-9188-26903a012d71",
              "geometry": null
            },
            "contactInfo": {
              "email": "long.tran@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5034?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5034"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-80-lower-ocd-person-7475f989-a4f9-4ac8-9188-26903a012d71": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/99ef9b20-6b1f-4831-8e58-a523de606cda",
            "firstName": "Michael Scott",
            "lastName": "Holcomb",
            "fullName": "Scott Holcomb",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/holcomb-scott-769.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-GA-81-lower-ocd-person-99ef9b20-6b1f-4831-8e58-a523de606cda",
              "builtID": "ga-lower-81",
              "externalID": "ocd-person/99ef9b20-6b1f-4831-8e58-a523de606cda",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.holcomb@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2306 Briarcliff Commons, Atlanta, GA 30345",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=769&Session=27",
                "https://www.legis.ga.gov/members/house/769?session=1029",
                "https://www.legis.ga.gov/members/house/769?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/769"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-81-lower-ocd-person-99ef9b20-6b1f-4831-8e58-a523de606cda": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/e6ca1b23-0972-4df6-ab04-6186cf9da814",
            "firstName": "Mary Margaret",
            "lastName": "Oliver",
            "fullName": "Mary Margaret Oliver",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/oliver-mary-margaret-181.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-GA-82-lower-ocd-person-e6ca1b23-0972-4df6-ab04-6186cf9da814",
              "builtID": "ga-lower-82",
              "externalID": "ocd-person/e6ca1b23-0972-4df6-ab04-6186cf9da814",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.oliver@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/181?session=1029",
                "https://www.legis.ga.gov/members/house/181?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/181"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-82-lower-ocd-person-e6ca1b23-0972-4df6-ab04-6186cf9da814": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/eb28f991-35c9-4d84-a907-5be56aed1aff",
            "firstName": "Karen",
            "lastName": "Lupton",
            "fullName": "Karen Lupton",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/lupton-karen-5035.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-GA-83-lower-ocd-person-eb28f991-35c9-4d84-a907-5be56aed1aff",
              "builtID": "ga-lower-83",
              "externalID": "ocd-person/eb28f991-35c9-4d84-a907-5be56aed1aff",
              "geometry": null
            },
            "contactInfo": {
              "email": "karen.lupton@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5035?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5035"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-83-lower-ocd-person-eb28f991-35c9-4d84-a907-5be56aed1aff": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/dbb81f07-2181-48dd-b383-19870e08d4fd",
            "firstName": "Omari J.",
            "lastName": "Crawford",
            "fullName": "Omari Crawford",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/crawford-omari-5036.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-GA-84-lower-ocd-person-dbb81f07-2181-48dd-b383-19870e08d4fd",
              "builtID": "ga-lower-84",
              "externalID": "ocd-person/dbb81f07-2181-48dd-b383-19870e08d4fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "omari.crawford@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5036?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5036"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-84-lower-ocd-person-dbb81f07-2181-48dd-b383-19870e08d4fd": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/27365f19-a138-4a9e-9f6e-11352932e5d5",
            "firstName": "Karla Lea",
            "lastName": "Drenner",
            "fullName": "Karla Drenner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/drenner-karla-95.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-GA-85-lower-ocd-person-27365f19-a138-4a9e-9f6e-11352932e5d5",
              "builtID": "ga-lower-85",
              "externalID": "ocd-person/27365f19-a138-4a9e-9f6e-11352932e5d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "karla.drenner@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=95&Session=27",
                "https://www.legis.ga.gov/members/house/95?session=1029",
                "https://www.legis.ga.gov/members/house/95?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/95"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-85-lower-ocd-person-27365f19-a138-4a9e-9f6e-11352932e5d5": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/2a68d594-e7d5-49cd-b615-6efb872430c3",
            "firstName": "Imani",
            "lastName": "Barnes",
            "fullName": "Imani Barnes",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/barnes-imani-5037.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-GA-86-lower-ocd-person-2a68d594-e7d5-49cd-b615-6efb872430c3",
              "builtID": "ga-lower-86",
              "externalID": "ocd-person/2a68d594-e7d5-49cd-b615-6efb872430c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "imani.barnes@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5037?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5037"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-86-lower-ocd-person-2a68d594-e7d5-49cd-b615-6efb872430c3": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/e3b1be9c-b244-4466-9192-adc365a31cfb",
            "firstName": "Viola",
            "lastName": "Davis",
            "fullName": "Viola Davis",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/davis-viola-4947.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-GA-87-lower-ocd-person-e3b1be9c-b244-4466-9192-adc365a31cfb",
              "builtID": "ga-lower-87",
              "externalID": "ocd-person/e3b1be9c-b244-4466-9192-adc365a31cfb",
              "geometry": null
            },
            "contactInfo": {
              "email": "viola.davis@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4947&Session=27",
                "https://www.legis.ga.gov/members/house/4947?session=1029",
                "https://www.legis.ga.gov/members/house/4947?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4947"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-87-lower-ocd-person-e3b1be9c-b244-4466-9192-adc365a31cfb": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/96307d79-07d4-4f59-9dd3-b7f994d566d5",
            "firstName": "Billy",
            "lastName": "Mitchell",
            "fullName": "Billy Mitchell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/mitchell-billy-172.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-GA-88-lower-ocd-person-96307d79-07d4-4f59-9dd3-b7f994d566d5",
              "builtID": "ga-lower-88",
              "externalID": "ocd-person/96307d79-07d4-4f59-9dd3-b7f994d566d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "billy.mitchell@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=172&Session=27",
                "https://www.legis.ga.gov/members/house/172?session=1029",
                "https://www.legis.ga.gov/members/house/172?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/172"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-88-lower-ocd-person-96307d79-07d4-4f59-9dd3-b7f994d566d5": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/f9d51075-c77f-4c05-88b9-ed716fd3b964",
            "firstName": "Becky",
            "lastName": "Evans",
            "fullName": "Becky Evans",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/evans-becky-4946.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-GA-89-lower-ocd-person-f9d51075-c77f-4c05-88b9-ed716fd3b964",
              "builtID": "ga-lower-89",
              "externalID": "ocd-person/f9d51075-c77f-4c05-88b9-ed716fd3b964",
              "geometry": null
            },
            "contactInfo": {
              "email": "becky.evans@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4946&Session=27",
                "https://www.legis.ga.gov/members/house/4946?session=1029",
                "https://www.legis.ga.gov/members/house/4946?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4946"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-89-lower-ocd-person-f9d51075-c77f-4c05-88b9-ed716fd3b964": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/2a66b922-b812-4c45-9786-478187cd5e33",
            "firstName": "Saira Amir",
            "lastName": "Draper",
            "fullName": "Saira Draper",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/draper-saira-5038.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-GA-90-lower-ocd-person-2a66b922-b812-4c45-9786-478187cd5e33",
              "builtID": "ga-lower-90",
              "externalID": "ocd-person/2a66b922-b812-4c45-9786-478187cd5e33",
              "geometry": null
            },
            "contactInfo": {
              "email": "saira.draper@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5038?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5038"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-90-lower-ocd-person-2a66b922-b812-4c45-9786-478187cd5e33": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/b001a55f-61fa-4e62-85fd-ac6c282f2711",
            "firstName": "Angela",
            "lastName": "Moore",
            "fullName": "Angela Moore",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/moore-angela-5008.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-GA-91-lower-ocd-person-b001a55f-61fa-4e62-85fd-ac6c282f2711",
              "builtID": "ga-lower-91",
              "externalID": "ocd-person/b001a55f-61fa-4e62-85fd-ac6c282f2711",
              "geometry": null
            },
            "contactInfo": {
              "email": "angela.moore@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5008?session=1029",
                "https://www.legis.ga.gov/members/house/5008?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5008"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-91-lower-ocd-person-b001a55f-61fa-4e62-85fd-ac6c282f2711": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/bc40f904-9c20-41d0-80d5-e5645d3e6935",
            "firstName": "Rhonda S.",
            "lastName": "Taylor",
            "fullName": "Rhonda Taylor",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/taylor-rhonda-4998.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-GA-92-lower-ocd-person-bc40f904-9c20-41d0-80d5-e5645d3e6935",
              "builtID": "ga-lower-92",
              "externalID": "ocd-person/bc40f904-9c20-41d0-80d5-e5645d3e6935",
              "geometry": null
            },
            "contactInfo": {
              "email": "rhonda.taylor@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 82452, Conyers, GA 30013",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/4998?session=1029",
                "https://www.legis.ga.gov/members/house/4998?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4998"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-92-lower-ocd-person-bc40f904-9c20-41d0-80d5-e5645d3e6935": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/818f1afb-2850-448e-99c1-be63a9acfe95",
            "firstName": "Doreen Roberson",
            "lastName": "Carter",
            "fullName": "Doreen Carter",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/carter-doreen-3877.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-GA-93-lower-ocd-person-818f1afb-2850-448e-99c1-be63a9acfe95",
              "builtID": "ga-lower-93",
              "externalID": "ocd-person/818f1afb-2850-448e-99c1-be63a9acfe95",
              "geometry": null
            },
            "contactInfo": {
              "email": "doreen.carter@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=3877&Session=27",
                "https://www.legis.ga.gov/members/house/3877?session=1029",
                "https://www.legis.ga.gov/members/house/3877?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/3877"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-93-lower-ocd-person-818f1afb-2850-448e-99c1-be63a9acfe95": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/cabee039-e73e-4dc6-95ec-3e22d8e529ff",
            "firstName": "Karen",
            "lastName": "Bennett",
            "fullName": "Karen Bennett",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/bennett-karen-810.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-GA-94-lower-ocd-person-cabee039-e73e-4dc6-95ec-3e22d8e529ff",
              "builtID": "ga-lower-94",
              "externalID": "ocd-person/cabee039-e73e-4dc6-95ec-3e22d8e529ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "karen.bennett@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6909 Springbank Way, Stone Mountain, GA 30087",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=810&Session=27",
                "https://www.legis.ga.gov/members/house/810?session=1029",
                "https://www.legis.ga.gov/members/house/810?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/810"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-94-lower-ocd-person-cabee039-e73e-4dc6-95ec-3e22d8e529ff": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/1f94316e-fb58-401f-bbfc-5ada0a717b6b",
            "firstName": "Dar'shun Nicole",
            "lastName": "Kendrick",
            "fullName": "Dar'shun Kendrick",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/kendrick-dar-shun-770.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-GA-95-lower-ocd-person-1f94316e-fb58-401f-bbfc-5ada0a717b6b",
              "builtID": "ga-lower-95",
              "externalID": "ocd-person/1f94316e-fb58-401f-bbfc-5ada0a717b6b",
              "geometry": null
            },
            "contactInfo": {
              "email": "darshun.kendrick@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=770&Session=27",
                "https://www.legis.ga.gov/members/house/770?session=1029",
                "https://www.legis.ga.gov/members/house/770?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/770"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-95-lower-ocd-person-1f94316e-fb58-401f-bbfc-5ada0a717b6b": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/90ad4502-efa3-4e77-9aed-4ec3667f4352",
            "firstName": "Pedro",
            "lastName": "Marin",
            "fullName": "Pete Marin",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/marin-pedro--pete--163.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-GA-96-lower-ocd-person-90ad4502-efa3-4e77-9aed-4ec3667f4352",
              "builtID": "ga-lower-96",
              "externalID": "ocd-person/90ad4502-efa3-4e77-9aed-4ec3667f4352",
              "geometry": null
            },
            "contactInfo": {
              "email": "marinstatehouse@aol.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=163&Session=27",
                "https://www.legis.ga.gov/members/house/163?session=1029",
                "https://www.legis.ga.gov/members/house/163?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/163"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-96-lower-ocd-person-90ad4502-efa3-4e77-9aed-4ec3667f4352": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/d291834f-70cd-48b5-b7db-b05d23d74a4c",
            "firstName": "Ruwa",
            "lastName": "Romman",
            "fullName": "Ruwa Romman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/romman-ruwa-5039.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-GA-97-lower-ocd-person-d291834f-70cd-48b5-b7db-b05d23d74a4c",
              "builtID": "ga-lower-97",
              "externalID": "ocd-person/d291834f-70cd-48b5-b7db-b05d23d74a4c",
              "geometry": null
            },
            "contactInfo": {
              "email": "ruwa.romman@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5039?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5039"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-97-lower-ocd-person-d291834f-70cd-48b5-b7db-b05d23d74a4c": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/9531d502-440c-48db-83c4-70dfbe9a9ea1",
            "firstName": "Marvin Certeza",
            "lastName": "Lim",
            "fullName": "Marvin Lim",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/lim-marvin-4999.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-GA-98-lower-ocd-person-9531d502-440c-48db-83c4-70dfbe9a9ea1",
              "builtID": "ga-lower-98",
              "externalID": "ocd-person/9531d502-440c-48db-83c4-70dfbe9a9ea1",
              "geometry": null
            },
            "contactInfo": {
              "email": "marvin.lim@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/4999?session=1029",
                "https://www.legis.ga.gov/members/house/4999?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4999"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-98-lower-ocd-person-9531d502-440c-48db-83c4-70dfbe9a9ea1": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/0f4f3c5a-1192-4ec1-9698-4aef005b4875",
            "firstName": "R. Matthew",
            "lastName": "Reeves",
            "fullName": "Matt Reeves",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/reeves-matt-5040.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-GA-99-lower-ocd-person-0f4f3c5a-1192-4ec1-9698-4aef005b4875",
              "builtID": "ga-lower-99",
              "externalID": "ocd-person/0f4f3c5a-1192-4ec1-9698-4aef005b4875",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.reeves@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5040?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5040"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-99-lower-ocd-person-0f4f3c5a-1192-4ec1-9698-4aef005b4875": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/ad18dba4-9247-4542-95b1-2ccef815dbc7",
            "firstName": "David",
            "lastName": "Clark",
            "fullName": "David Clark",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/clark-david-867.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-GA-100-lower-ocd-person-ad18dba4-9247-4542-95b1-2ccef815dbc7",
              "builtID": "ga-lower-100",
              "externalID": "ocd-person/ad18dba4-9247-4542-95b1-2ccef815dbc7",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.clark@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=867&Session=27",
                "https://www.legis.ga.gov/members/house/867?session=1029",
                "https://www.legis.ga.gov/members/house/867?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/867"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-100-lower-ocd-person-ad18dba4-9247-4542-95b1-2ccef815dbc7": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/f936e87a-99f0-45a7-915b-496bc7574728",
            "firstName": "William Gregory",
            "lastName": "Kennard",
            "fullName": "Gregg Kennard",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/kennard-gregg-4950.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-GA-101-lower-ocd-person-f936e87a-99f0-45a7-915b-496bc7574728",
              "builtID": "ga-lower-101",
              "externalID": "ocd-person/f936e87a-99f0-45a7-915b-496bc7574728",
              "geometry": null
            },
            "contactInfo": {
              "email": "gregg.kennard@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4950&Session=27",
                "https://www.legis.ga.gov/members/house/4950?session=1029",
                "https://www.legis.ga.gov/members/house/4950?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4950"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-101-lower-ocd-person-f936e87a-99f0-45a7-915b-496bc7574728": 0
        }
      },
      "102": {
        "members": [
          {
            "API_ID": "ocd-person/dc99efc3-076b-4d00-b4b0-66a0507a4057",
            "firstName": "Gabe",
            "lastName": "Okoye",
            "fullName": "Gabe Okoye",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/okoye-gabe-5041.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "102",
              "chamber": "lower",
              "hashID": "SLDL-GA-102-lower-ocd-person-dc99efc3-076b-4d00-b4b0-66a0507a4057",
              "builtID": "ga-lower-102",
              "externalID": "ocd-person/dc99efc3-076b-4d00-b4b0-66a0507a4057",
              "geometry": null
            },
            "contactInfo": {
              "email": "gabe.okoye@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5041?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5041"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-102-lower-ocd-person-dc99efc3-076b-4d00-b4b0-66a0507a4057": 0
        }
      },
      "103": {
        "members": [
          {
            "API_ID": "ocd-person/64721c9b-451c-4bc9-9040-4ca232ffd746",
            "firstName": "Soo J.",
            "lastName": "Hong",
            "fullName": "Soo Hong",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/hong-soo-5042.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "103",
              "chamber": "lower",
              "hashID": "SLDL-GA-103-lower-ocd-person-64721c9b-451c-4bc9-9040-4ca232ffd746",
              "builtID": "ga-lower-103",
              "externalID": "ocd-person/64721c9b-451c-4bc9-9040-4ca232ffd746",
              "geometry": null
            },
            "contactInfo": {
              "email": "soo.hong@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5042?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5042"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-103-lower-ocd-person-64721c9b-451c-4bc9-9040-4ca232ffd746": 0
        }
      },
      "104": {
        "members": [
          {
            "API_ID": "ocd-person/3ff894cb-f2e4-47d0-9f67-b125245a10c3",
            "firstName": "Charles P.",
            "lastName": "Efstration",
            "fullName": "Chuck Efstration",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/efstration-chuck-846.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "104",
              "chamber": "lower",
              "hashID": "SLDL-GA-104-lower-ocd-person-3ff894cb-f2e4-47d0-9f67-b125245a10c3",
              "builtID": "ga-lower-104",
              "externalID": "ocd-person/3ff894cb-f2e4-47d0-9f67-b125245a10c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "chuck.efstration@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=846&Session=27",
                "https://www.legis.ga.gov/members/house/846?session=1029",
                "https://www.legis.ga.gov/members/house/846?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/846"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-104-lower-ocd-person-3ff894cb-f2e4-47d0-9f67-b125245a10c3": 0
        }
      },
      "105": {
        "members": [
          {
            "API_ID": "ocd-person/a37d3d01-84a2-4c9a-8309-2c4aa03d0928",
            "firstName": "Farooq",
            "lastName": "Mughal",
            "fullName": "Farooq Mughal",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/mughal-farooq-5043.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "105",
              "chamber": "lower",
              "hashID": "SLDL-GA-105-lower-ocd-person-a37d3d01-84a2-4c9a-8309-2c4aa03d0928",
              "builtID": "ga-lower-105",
              "externalID": "ocd-person/a37d3d01-84a2-4c9a-8309-2c4aa03d0928",
              "geometry": null
            },
            "contactInfo": {
              "email": "farooq.mughal@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room #325, 710 Dacula Road, Suite 4A, Dacula, GA 30019",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5043?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5043"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-105-lower-ocd-person-a37d3d01-84a2-4c9a-8309-2c4aa03d0928": 0
        }
      },
      "106": {
        "members": [
          {
            "API_ID": "ocd-person/4161e949-6ea2-4df9-8248-cabcf40286ae",
            "firstName": "Shelly Dinet",
            "lastName": "Hutchinson",
            "fullName": "Shelly Hutchinson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/hutchinson-shelly-4952.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "106",
              "chamber": "lower",
              "hashID": "SLDL-GA-106-lower-ocd-person-4161e949-6ea2-4df9-8248-cabcf40286ae",
              "builtID": "ga-lower-106",
              "externalID": "ocd-person/4161e949-6ea2-4df9-8248-cabcf40286ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "shelly.hutchinson@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4952&Session=27",
                "https://www.legis.ga.gov/members/house/4952?session=1029",
                "https://www.legis.ga.gov/members/house/4952?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4952"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-106-lower-ocd-person-4161e949-6ea2-4df9-8248-cabcf40286ae": 0
        }
      },
      "107": {
        "members": [
          {
            "API_ID": "ocd-person/2ba0916f-40b1-4255-9496-94b12ccabd1e",
            "firstName": "Samuel Lauderdale",
            "lastName": "Park",
            "fullName": "Sam Park",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/park-sam-4901.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "107",
              "chamber": "lower",
              "hashID": "SLDL-GA-107-lower-ocd-person-2ba0916f-40b1-4255-9496-94b12ccabd1e",
              "builtID": "ga-lower-107",
              "externalID": "ocd-person/2ba0916f-40b1-4255-9496-94b12ccabd1e",
              "geometry": null
            },
            "contactInfo": {
              "email": "samuel.park@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4901&Session=27",
                "https://www.legis.ga.gov/members/house/4901?session=1029",
                "https://www.legis.ga.gov/members/house/4901?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4901"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-107-lower-ocd-person-2ba0916f-40b1-4255-9496-94b12ccabd1e": 0
        }
      },
      "108": {
        "members": [
          {
            "API_ID": "ocd-person/0461f02b-df18-4984-8b3b-ada287879d0a",
            "firstName": "Jasmine",
            "lastName": "Clark",
            "fullName": "Jasmine Clark",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/clark-jasmine-4953.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "108",
              "chamber": "lower",
              "hashID": "SLDL-GA-108-lower-ocd-person-0461f02b-df18-4984-8b3b-ada287879d0a",
              "builtID": "ga-lower-108",
              "externalID": "ocd-person/0461f02b-df18-4984-8b3b-ada287879d0a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jasmine.clark@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4953&Session=27",
                "https://www.legis.ga.gov/members/house/4953?session=1029",
                "https://www.legis.ga.gov/members/house/4953?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4953"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-108-lower-ocd-person-0461f02b-df18-4984-8b3b-ada287879d0a": 0
        }
      },
      "109": {
        "members": [
          {
            "API_ID": "ocd-person/8afda706-7afa-462c-89f2-0d6d5da010ff",
            "firstName": "Dewey Loren",
            "lastName": "McClain",
            "fullName": "Dewey McClain",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/mcclain-dewey-845.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "109",
              "chamber": "lower",
              "hashID": "SLDL-GA-109-lower-ocd-person-8afda706-7afa-462c-89f2-0d6d5da010ff",
              "builtID": "ga-lower-109",
              "externalID": "ocd-person/8afda706-7afa-462c-89f2-0d6d5da010ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "dewey.mcclain@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=845&Session=27",
                "https://www.legis.ga.gov/members/house/845?session=1029",
                "https://www.legis.ga.gov/members/house/845?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/845"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-109-lower-ocd-person-8afda706-7afa-462c-89f2-0d6d5da010ff": 0
        }
      },
      "110": {
        "members": [
          {
            "API_ID": "ocd-person/702658f4-8382-479a-9416-79928239d5e7",
            "firstName": "Segun",
            "lastName": "Adeyina",
            "fullName": "Segun Adeyina",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/adeyina-segun-5044.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "110",
              "chamber": "lower",
              "hashID": "SLDL-GA-110-lower-ocd-person-702658f4-8382-479a-9416-79928239d5e7",
              "builtID": "ga-lower-110",
              "externalID": "ocd-person/702658f4-8382-479a-9416-79928239d5e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "segun.adeyina@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1222, Grayson, GA 30017",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5044?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5044"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-110-lower-ocd-person-702658f4-8382-479a-9416-79928239d5e7": 0
        }
      },
      "111": {
        "members": [
          {
            "API_ID": "ocd-person/08ee45fd-d3f4-4129-9e7c-27cf52533ed2",
            "firstName": "Reynaldo",
            "lastName": "Martinez",
            "fullName": "Rey Martinez",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/martinez-reynaldo--rey--5045.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "111",
              "chamber": "lower",
              "hashID": "SLDL-GA-111-lower-ocd-person-08ee45fd-d3f4-4129-9e7c-27cf52533ed2",
              "builtID": "ga-lower-111",
              "externalID": "ocd-person/08ee45fd-d3f4-4129-9e7c-27cf52533ed2",
              "geometry": null
            },
            "contactInfo": {
              "email": "reynaldo.martinez@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5045?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5045"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-111-lower-ocd-person-08ee45fd-d3f4-4129-9e7c-27cf52533ed2": 0
        }
      },
      "112": {
        "members": [
          {
            "API_ID": "ocd-person/13b7af1b-5b56-481b-a81a-2001846188a6",
            "firstName": "Hugh Bruce",
            "lastName": "Williamson",
            "fullName": "Bruce Williamson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/williamson-bruce-778.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "112",
              "chamber": "lower",
              "hashID": "SLDL-GA-112-lower-ocd-person-13b7af1b-5b56-481b-a81a-2001846188a6",
              "builtID": "ga-lower-112",
              "externalID": "ocd-person/13b7af1b-5b56-481b-a81a-2001846188a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "bruce.williamson@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=778&Session=27",
                "https://www.legis.ga.gov/members/house/778?session=1029",
                "https://www.legis.ga.gov/members/house/778?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/778"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-112-lower-ocd-person-13b7af1b-5b56-481b-a81a-2001846188a6": 0
        }
      },
      "113": {
        "members": [
          {
            "API_ID": "ocd-person/e51d36a7-9684-4540-aae7-a5e60e434681",
            "firstName": "Sharon",
            "lastName": "Henderson",
            "fullName": "Sharon Henderson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/henderson-sharon-5003.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "113",
              "chamber": "lower",
              "hashID": "SLDL-GA-113-lower-ocd-person-e51d36a7-9684-4540-aae7-a5e60e434681",
              "builtID": "ga-lower-113",
              "externalID": "ocd-person/e51d36a7-9684-4540-aae7-a5e60e434681",
              "geometry": null
            },
            "contactInfo": {
              "email": "sharon.henderson@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5003?session=1029",
                "https://www.legis.ga.gov/members/house/5003?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5003"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-113-lower-ocd-person-e51d36a7-9684-4540-aae7-a5e60e434681": 0
        }
      },
      "114": {
        "members": [
          {
            "API_ID": "ocd-person/cf955c60-cbda-4414-9266-3d8dca3553fe",
            "firstName": "Tim",
            "lastName": "Fleming",
            "fullName": "Tim Fleming",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/fleming-tim-5046.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "114",
              "chamber": "lower",
              "hashID": "SLDL-GA-114-lower-ocd-person-cf955c60-cbda-4414-9266-3d8dca3553fe",
              "builtID": "ga-lower-114",
              "externalID": "ocd-person/cf955c60-cbda-4414-9266-3d8dca3553fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "tim.fleming@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5046?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5046"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-114-lower-ocd-person-cf955c60-cbda-4414-9266-3d8dca3553fe": 0
        }
      },
      "115": {
        "members": [
          {
            "API_ID": "ocd-person/21651856-bba6-49fd-b3f8-8ed1b723e804",
            "firstName": "Regina",
            "lastName": "Lewis-Ward",
            "fullName": "Regina Lewis-Ward",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/lewis-ward-regina-5001.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "115",
              "chamber": "lower",
              "hashID": "SLDL-GA-115-lower-ocd-person-21651856-bba6-49fd-b3f8-8ed1b723e804",
              "builtID": "ga-lower-115",
              "externalID": "ocd-person/21651856-bba6-49fd-b3f8-8ed1b723e804",
              "geometry": null
            },
            "contactInfo": {
              "email": "regina.lewis-ward@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5001?session=1029",
                "https://www.legis.ga.gov/members/house/5001?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5001"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-115-lower-ocd-person-21651856-bba6-49fd-b3f8-8ed1b723e804": 0
        }
      },
      "116": {
        "members": [
          {
            "API_ID": "ocd-person/4615e308-db74-408f-89df-02be56b5013b",
            "firstName": "El-Mahdi",
            "lastName": "Holly",
            "fullName": "El-Mahdi Holly",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/holly-el-mahdi-4954.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "116",
              "chamber": "lower",
              "hashID": "SLDL-GA-116-lower-ocd-person-4615e308-db74-408f-89df-02be56b5013b",
              "builtID": "ga-lower-116",
              "externalID": "ocd-person/4615e308-db74-408f-89df-02be56b5013b",
              "geometry": null
            },
            "contactInfo": {
              "email": "el-mahdi.holly@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4954&Session=27",
                "https://www.legis.ga.gov/members/house/4954?session=1029",
                "https://www.legis.ga.gov/members/house/4954?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4954"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-116-lower-ocd-person-4615e308-db74-408f-89df-02be56b5013b": 0
        }
      },
      "117": {
        "members": [
          {
            "API_ID": "ocd-person/64ac9af9-ca39-47f2-9ee4-bbeaf0a856ac",
            "firstName": "Lauren",
            "lastName": "Daniel",
            "fullName": "Lauren Daniel",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/daniel-lauren-5047.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "117",
              "chamber": "lower",
              "hashID": "SLDL-GA-117-lower-ocd-person-64ac9af9-ca39-47f2-9ee4-bbeaf0a856ac",
              "builtID": "ga-lower-117",
              "externalID": "ocd-person/64ac9af9-ca39-47f2-9ee4-bbeaf0a856ac",
              "geometry": null
            },
            "contactInfo": {
              "email": "lauren.daniel@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 131, Locust Grove, GA 30248",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5047?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5047"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-117-lower-ocd-person-64ac9af9-ca39-47f2-9ee4-bbeaf0a856ac": 0
        }
      },
      "118": {
        "members": [
          {
            "API_ID": "ocd-person/e1a9102d-f845-47f5-882e-e344eef060ec",
            "firstName": "Clinton",
            "lastName": "Crowe",
            "fullName": "Clint Crowe",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/crowe-clint-5002.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "118",
              "chamber": "lower",
              "hashID": "SLDL-GA-118-lower-ocd-person-e1a9102d-f845-47f5-882e-e344eef060ec",
              "builtID": "ga-lower-118",
              "externalID": "ocd-person/e1a9102d-f845-47f5-882e-e344eef060ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "clint.crowe@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5002?session=1029",
                "https://www.legis.ga.gov/members/house/5002?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5002"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-118-lower-ocd-person-e1a9102d-f845-47f5-882e-e344eef060ec": 0
        }
      },
      "119": {
        "members": [
          {
            "API_ID": "ocd-person/b39e2164-d570-45f2-b271-5e2fbe99c8d6",
            "firstName": "Holt",
            "lastName": "Persinger",
            "fullName": "Holt Persinger",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/persinger-holt-5060.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "119",
              "chamber": "lower",
              "hashID": "SLDL-GA-119-lower-ocd-person-b39e2164-d570-45f2-b271-5e2fbe99c8d6",
              "builtID": "ga-lower-119",
              "externalID": "ocd-person/b39e2164-d570-45f2-b271-5e2fbe99c8d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "holt.persinger@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5060?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5060"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-119-lower-ocd-person-b39e2164-d570-45f2-b271-5e2fbe99c8d6": 0
        }
      },
      "120": {
        "members": [
          {
            "API_ID": "ocd-person/e73cb2a6-bf3e-4211-a386-142c18a4f607",
            "firstName": "Houston",
            "lastName": "Gaines",
            "fullName": "Houston Gaines",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/gaines-houston-4955.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "120",
              "chamber": "lower",
              "hashID": "SLDL-GA-120-lower-ocd-person-e73cb2a6-bf3e-4211-a386-142c18a4f607",
              "builtID": "ga-lower-120",
              "externalID": "ocd-person/e73cb2a6-bf3e-4211-a386-142c18a4f607",
              "geometry": null
            },
            "contactInfo": {
              "email": "houston.gaines@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4955&Session=27",
                "https://www.legis.ga.gov/members/house/4955?session=1029",
                "https://www.legis.ga.gov/members/house/4955?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4955"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-120-lower-ocd-person-e73cb2a6-bf3e-4211-a386-142c18a4f607": 0
        }
      },
      "121": {
        "members": [
          {
            "API_ID": "ocd-person/ff24cea3-8582-4cfe-b015-74ee7c5ebeef",
            "firstName": "Marcus A.",
            "lastName": "Wiedower",
            "fullName": "Marcus Wiedower",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/wiedower-marcus-4956.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "121",
              "chamber": "lower",
              "hashID": "SLDL-GA-121-lower-ocd-person-ff24cea3-8582-4cfe-b015-74ee7c5ebeef",
              "builtID": "ga-lower-121",
              "externalID": "ocd-person/ff24cea3-8582-4cfe-b015-74ee7c5ebeef",
              "geometry": null
            },
            "contactInfo": {
              "email": "marcus.wiedower@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4956&Session=27",
                "https://www.legis.ga.gov/members/house/4956?session=1029",
                "https://www.legis.ga.gov/members/house/4956?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4956"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-121-lower-ocd-person-ff24cea3-8582-4cfe-b015-74ee7c5ebeef": 0
        }
      },
      "122": {
        "members": [
          {
            "API_ID": "ocd-person/e6ae8d30-4b1b-4b06-8918-2be6fa7f8108",
            "firstName": "Spencer",
            "lastName": "Frye",
            "fullName": "Spencer Frye",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/frye-spencer-819.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "122",
              "chamber": "lower",
              "hashID": "SLDL-GA-122-lower-ocd-person-e6ae8d30-4b1b-4b06-8918-2be6fa7f8108",
              "builtID": "ga-lower-122",
              "externalID": "ocd-person/e6ae8d30-4b1b-4b06-8918-2be6fa7f8108",
              "geometry": null
            },
            "contactInfo": {
              "email": "spencer.frye@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=819&Session=27",
                "https://www.legis.ga.gov/members/house/819?session=1029",
                "https://www.legis.ga.gov/members/house/819?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/819"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-122-lower-ocd-person-e6ae8d30-4b1b-4b06-8918-2be6fa7f8108": 0
        }
      },
      "123": {
        "members": [
          {
            "API_ID": "ocd-person/29210f3f-ec97-47c8-9492-b81154275109",
            "firstName": "Robert Freeman",
            "lastName": "Leverett",
            "fullName": "Rob Leverett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/leverett-rob-4993.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "123",
              "chamber": "lower",
              "hashID": "SLDL-GA-123-lower-ocd-person-29210f3f-ec97-47c8-9492-b81154275109",
              "builtID": "ga-lower-123",
              "externalID": "ocd-person/29210f3f-ec97-47c8-9492-b81154275109",
              "geometry": null
            },
            "contactInfo": {
              "email": "rob.leverett@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Drawer 399, Elberton, GA 30635",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/4993?session=1029",
                "https://www.legis.ga.gov/members/house/4993?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4993"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-123-lower-ocd-person-29210f3f-ec97-47c8-9492-b81154275109": 0
        }
      },
      "124": {
        "members": [
          {
            "API_ID": "ocd-person/9dfc8ec3-0935-4d1d-877d-e05cf7a5dca5",
            "firstName": "Trey",
            "lastName": "Rhodes",
            "fullName": "Trey Rhodes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/rhodes-trey-876.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "124",
              "chamber": "lower",
              "hashID": "SLDL-GA-124-lower-ocd-person-9dfc8ec3-0935-4d1d-877d-e05cf7a5dca5",
              "builtID": "ga-lower-124",
              "externalID": "ocd-person/9dfc8ec3-0935-4d1d-877d-e05cf7a5dca5",
              "geometry": null
            },
            "contactInfo": {
              "email": "trey.rhodes@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=876&Session=27",
                "https://www.legis.ga.gov/members/house/876?session=1029",
                "https://www.legis.ga.gov/members/house/876?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/876"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-124-lower-ocd-person-9dfc8ec3-0935-4d1d-877d-e05cf7a5dca5": 0
        }
      },
      "125": {
        "members": [
          {
            "API_ID": "ocd-person/47fd36f3-f5d4-4ef6-a429-1ffc7681e233",
            "firstName": "Gary",
            "lastName": "Richardson",
            "fullName": "Gary Richardson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/GaryRichardson2024.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "125",
              "chamber": "lower",
              "hashID": "SLDL-GA-125-lower-ocd-person-47fd36f3-f5d4-4ef6-a429-1ffc7681e233",
              "builtID": "ga-lower-125",
              "externalID": "ocd-person/47fd36f3-f5d4-4ef6-a429-1ffc7681e233",
              "geometry": null
            },
            "contactInfo": {
              "email": "gary.richardson@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5063?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-125-lower-ocd-person-47fd36f3-f5d4-4ef6-a429-1ffc7681e233": 0
        }
      },
      "126": {
        "members": [
          {
            "API_ID": "ocd-person/8d2154d2-3401-4b8c-9173-9c895d014b79",
            "firstName": "Gloria",
            "lastName": "Frazier",
            "fullName": "Gloria Frazier",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/frazier-gloria-107.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "126",
              "chamber": "lower",
              "hashID": "SLDL-GA-126-lower-ocd-person-8d2154d2-3401-4b8c-9173-9c895d014b79",
              "builtID": "ga-lower-126",
              "externalID": "ocd-person/8d2154d2-3401-4b8c-9173-9c895d014b79",
              "geometry": null
            },
            "contactInfo": {
              "email": "gloria.frazier@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=107&Session=27",
                "https://www.legis.ga.gov/members/house/107?session=1029",
                "https://www.legis.ga.gov/members/house/107?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/107"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-126-lower-ocd-person-8d2154d2-3401-4b8c-9173-9c895d014b79": 0
        }
      },
      "127": {
        "members": [
          {
            "API_ID": "ocd-person/5e12fb82-1f69-46df-9ac5-a09ce51dd95b",
            "firstName": "William Mark",
            "lastName": "Newton",
            "fullName": "Mark Newton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/newton-mark-4902.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "127",
              "chamber": "lower",
              "hashID": "SLDL-GA-127-lower-ocd-person-5e12fb82-1f69-46df-9ac5-a09ce51dd95b",
              "builtID": "ga-lower-127",
              "externalID": "ocd-person/5e12fb82-1f69-46df-9ac5-a09ce51dd95b",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.newton@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4902&Session=27",
                "https://www.legis.ga.gov/members/house/4902?session=1029",
                "https://www.legis.ga.gov/members/house/4902?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4902"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-127-lower-ocd-person-5e12fb82-1f69-46df-9ac5-a09ce51dd95b": 0
        }
      },
      "128": {
        "members": [
          {
            "API_ID": "ocd-person/8e209d95-2333-4aa2-a628-1bdcec0674f5",
            "firstName": "Mack",
            "lastName": "Jackson",
            "fullName": "Mack Jackson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/jackson-mack-738.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "128",
              "chamber": "lower",
              "hashID": "SLDL-GA-128-lower-ocd-person-8e209d95-2333-4aa2-a628-1bdcec0674f5",
              "builtID": "ga-lower-128",
              "externalID": "ocd-person/8e209d95-2333-4aa2-a628-1bdcec0674f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "mack.jackson@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=738&Session=27",
                "https://www.legis.ga.gov/members/house/738?session=1029",
                "https://www.legis.ga.gov/members/house/738?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/738"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-128-lower-ocd-person-8e209d95-2333-4aa2-a628-1bdcec0674f5": 0
        }
      },
      "129": {
        "members": [
          {
            "API_ID": "ocd-person/4b3fdb35-d3fb-40aa-843e-d3900df37ce4",
            "firstName": "Karlton",
            "lastName": "Howard",
            "fullName": "Karlton Howard",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/howard-karlton-5056.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "129",
              "chamber": "lower",
              "hashID": "SLDL-GA-129-lower-ocd-person-4b3fdb35-d3fb-40aa-843e-d3900df37ce4",
              "builtID": "ga-lower-129",
              "externalID": "ocd-person/4b3fdb35-d3fb-40aa-843e-d3900df37ce4",
              "geometry": null
            },
            "contactInfo": {
              "email": "karlton.howard@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5056?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5056"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-129-lower-ocd-person-4b3fdb35-d3fb-40aa-843e-d3900df37ce4": 0
        }
      },
      "130": {
        "members": [
          {
            "API_ID": "ocd-person/031b880f-171c-448f-b1c1-62ac47693909",
            "firstName": "Lynn",
            "lastName": "Gladney",
            "fullName": "Lynn Gladney",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/gladney-lynn-5048.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "130",
              "chamber": "lower",
              "hashID": "SLDL-GA-130-lower-ocd-person-031b880f-171c-448f-b1c1-62ac47693909",
              "builtID": "ga-lower-130",
              "externalID": "ocd-person/031b880f-171c-448f-b1c1-62ac47693909",
              "geometry": null
            },
            "contactInfo": {
              "email": "lynn.gladney@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5048?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5048"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-130-lower-ocd-person-031b880f-171c-448f-b1c1-62ac47693909": 0
        }
      },
      "131": {
        "members": [
          {
            "API_ID": "ocd-person/be736ffd-fffe-4bf0-8bd4-8b2c594f8bf6",
            "firstName": "Jodi",
            "lastName": "Lott",
            "fullName": "Jodi Lott",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/lott-jodi-4879.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "131",
              "chamber": "lower",
              "hashID": "SLDL-GA-131-lower-ocd-person-be736ffd-fffe-4bf0-8bd4-8b2c594f8bf6",
              "builtID": "ga-lower-131",
              "externalID": "ocd-person/be736ffd-fffe-4bf0-8bd4-8b2c594f8bf6",
              "geometry": null
            },
            "contactInfo": {
              "email": "jodi.lott@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4879&Session=27",
                "https://www.legis.ga.gov/members/house/4879?session=1029",
                "https://www.legis.ga.gov/members/house/4879?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4879"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-131-lower-ocd-person-be736ffd-fffe-4bf0-8bd4-8b2c594f8bf6": 0
        }
      },
      "132": {
        "members": [
          {
            "API_ID": "ocd-person/a53e6bbd-a5b8-436b-94ae-bebf957c5b61",
            "firstName": "Brian",
            "lastName": "Prince",
            "fullName": "Brian Prince",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/prince-brian-847.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "132",
              "chamber": "lower",
              "hashID": "SLDL-GA-132-lower-ocd-person-a53e6bbd-a5b8-436b-94ae-bebf957c5b61",
              "builtID": "ga-lower-132",
              "externalID": "ocd-person/a53e6bbd-a5b8-436b-94ae-bebf957c5b61",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.prince@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=847&Session=27",
                "https://www.legis.ga.gov/members/house/847?session=1029",
                "https://www.legis.ga.gov/members/house/847?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/847"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-132-lower-ocd-person-a53e6bbd-a5b8-436b-94ae-bebf957c5b61": 0
        }
      },
      "133": {
        "members": [
          {
            "API_ID": "ocd-person/86ea5bd0-2037-4bbe-a4e4-65bbaa8824ee",
            "firstName": "Kenneth",
            "lastName": "Vance",
            "fullName": "Ken Vance",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/vance-kenneth-5049.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "133",
              "chamber": "lower",
              "hashID": "SLDL-GA-133-lower-ocd-person-86ea5bd0-2037-4bbe-a4e4-65bbaa8824ee",
              "builtID": "ga-lower-133",
              "externalID": "ocd-person/86ea5bd0-2037-4bbe-a4e4-65bbaa8824ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "kenneth.vance@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5049?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5049"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-133-lower-ocd-person-86ea5bd0-2037-4bbe-a4e4-65bbaa8824ee": 0
        }
      },
      "134": {
        "members": [
          {
            "API_ID": "ocd-person/1871260f-8daf-43e1-a9ec-09ebcfdbb6a4",
            "firstName": "David",
            "lastName": "Knight",
            "fullName": "David Knight",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/knight-david-148.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "134",
              "chamber": "lower",
              "hashID": "SLDL-GA-134-lower-ocd-person-1871260f-8daf-43e1-a9ec-09ebcfdbb6a4",
              "builtID": "ga-lower-134",
              "externalID": "ocd-person/1871260f-8daf-43e1-a9ec-09ebcfdbb6a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.knight@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=148&Session=27",
                "https://www.legis.ga.gov/members/house/148?session=1029",
                "https://www.legis.ga.gov/members/house/148?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/148"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-134-lower-ocd-person-1871260f-8daf-43e1-a9ec-09ebcfdbb6a4": 0
        }
      },
      "135": {
        "members": [
          {
            "API_ID": "ocd-person/e4e40f68-0970-4392-b739-3c68f749bcdc",
            "firstName": "Elizabeth Anne Thompson",
            "lastName": "Camp",
            "fullName": "Beth Camp",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/camp-beth-5004.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "135",
              "chamber": "lower",
              "hashID": "SLDL-GA-135-lower-ocd-person-e4e40f68-0970-4392-b739-3c68f749bcdc",
              "builtID": "ga-lower-135",
              "externalID": "ocd-person/e4e40f68-0970-4392-b739-3c68f749bcdc",
              "geometry": null
            },
            "contactInfo": {
              "email": "beth.camp@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5004?session=1029",
                "https://www.legis.ga.gov/members/house/5004?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5004"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-135-lower-ocd-person-e4e40f68-0970-4392-b739-3c68f749bcdc": 0
        }
      },
      "136": {
        "members": [
          {
            "API_ID": "ocd-person/0f288c5b-484a-4b70-8209-0a23f922edd1",
            "firstName": "David Glenn",
            "lastName": "Jenkins",
            "fullName": "David Jenkins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/jenkins-david-5005.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "136",
              "chamber": "lower",
              "hashID": "SLDL-GA-136-lower-ocd-person-0f288c5b-484a-4b70-8209-0a23f922edd1",
              "builtID": "ga-lower-136",
              "externalID": "ocd-person/0f288c5b-484a-4b70-8209-0a23f922edd1",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.jenkins@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "833 John Trammell Road, Grantville, GA 30220",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5005?session=1029",
                "https://www.legis.ga.gov/members/house/5005?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5005"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-136-lower-ocd-person-0f288c5b-484a-4b70-8209-0a23f922edd1": 0
        }
      },
      "137": {
        "members": [
          {
            "API_ID": "ocd-person/5061bc6b-83af-46d0-a78d-30ebe10a63db",
            "firstName": "Debbie G.",
            "lastName": "Buckner",
            "fullName": "Debbie Buckner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/buckner-debbie-71.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "137",
              "chamber": "lower",
              "hashID": "SLDL-GA-137-lower-ocd-person-5061bc6b-83af-46d0-a78d-30ebe10a63db",
              "builtID": "ga-lower-137",
              "externalID": "ocd-person/5061bc6b-83af-46d0-a78d-30ebe10a63db",
              "geometry": null
            },
            "contactInfo": {
              "email": "debbie.buckner@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=71&Session=27",
                "https://www.legis.ga.gov/members/house/71?session=1029",
                "https://www.legis.ga.gov/members/house/71?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/71"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-137-lower-ocd-person-5061bc6b-83af-46d0-a78d-30ebe10a63db": 0
        }
      },
      "138": {
        "members": [
          {
            "API_ID": "ocd-person/9deeb60b-4d1e-42b8-8052-d2808a30ea9a",
            "firstName": "Vance C.",
            "lastName": "Smith",
            "fullName": "Vance Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/smith-vance-215.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "138",
              "chamber": "lower",
              "hashID": "SLDL-GA-138-lower-ocd-person-9deeb60b-4d1e-42b8-8052-d2808a30ea9a",
              "builtID": "ga-lower-138",
              "externalID": "ocd-person/9deeb60b-4d1e-42b8-8052-d2808a30ea9a",
              "geometry": null
            },
            "contactInfo": {
              "email": "vance.smith@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=215&Session=27",
                "https://www.legis.ga.gov/members/house/215?session=1029",
                "https://www.legis.ga.gov/members/house/215?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/215"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-138-lower-ocd-person-9deeb60b-4d1e-42b8-8052-d2808a30ea9a": 0
        }
      },
      "139": {
        "members": [
          {
            "API_ID": "ocd-person/3646e872-85a6-4383-97c5-36ff96ca7113",
            "firstName": "Carmen",
            "lastName": "Rice",
            "fullName": "Carmen Rice",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/CarmenRice2024.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "139",
              "chamber": "lower",
              "hashID": "SLDL-GA-139-lower-ocd-person-3646e872-85a6-4383-97c5-36ff96ca7113",
              "builtID": "ga-lower-139",
              "externalID": "ocd-person/3646e872-85a6-4383-97c5-36ff96ca7113",
              "geometry": null
            },
            "contactInfo": {
              "email": "carmen.rice@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5064?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-139-lower-ocd-person-3646e872-85a6-4383-97c5-36ff96ca7113": 0
        }
      },
      "140": {
        "members": [
          {
            "API_ID": "ocd-person/9fe70ed7-31a0-4732-92b1-54d2a79a4654",
            "firstName": "Tremaine Teddy",
            "lastName": "Reese",
            "fullName": "Teddy Reese",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/reese-tremaine-teddy-5050.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "140",
              "chamber": "lower",
              "hashID": "SLDL-GA-140-lower-ocd-person-9fe70ed7-31a0-4732-92b1-54d2a79a4654",
              "builtID": "ga-lower-140",
              "externalID": "ocd-person/9fe70ed7-31a0-4732-92b1-54d2a79a4654",
              "geometry": null
            },
            "contactInfo": {
              "email": "tremaine.reese@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5050?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5050"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-140-lower-ocd-person-9fe70ed7-31a0-4732-92b1-54d2a79a4654": 0
        }
      },
      "141": {
        "members": [
          {
            "API_ID": "ocd-person/0f5a9273-2fc6-458f-af59-b75070a1dc76",
            "firstName": "Carolyn F.",
            "lastName": "Hugley",
            "fullName": "Carolyn Hugley",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/hugley-carolyn-133.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "141",
              "chamber": "lower",
              "hashID": "SLDL-GA-141-lower-ocd-person-0f5a9273-2fc6-458f-af59-b75070a1dc76",
              "builtID": "ga-lower-141",
              "externalID": "ocd-person/0f5a9273-2fc6-458f-af59-b75070a1dc76",
              "geometry": null
            },
            "contactInfo": {
              "email": "carolyn.hugley@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=133&Session=27",
                "https://www.legis.ga.gov/members/house/133?session=1029",
                "https://www.legis.ga.gov/members/house/133?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/133"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-141-lower-ocd-person-0f5a9273-2fc6-458f-af59-b75070a1dc76": 0
        }
      },
      "142": {
        "members": [
          {
            "API_ID": "ocd-person/bad14891-c7bc-4ac4-b23a-fb5ae3878d67",
            "firstName": "Miriam",
            "lastName": "Paris",
            "fullName": "Miriam Paris",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/paris-miriam-794.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "142",
              "chamber": "lower",
              "hashID": "SLDL-GA-142-lower-ocd-person-bad14891-c7bc-4ac4-b23a-fb5ae3878d67",
              "builtID": "ga-lower-142",
              "externalID": "ocd-person/bad14891-c7bc-4ac4-b23a-fb5ae3878d67",
              "geometry": null
            },
            "contactInfo": {
              "email": "miriam.paris@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=794&Session=27",
                "https://www.legis.ga.gov/members/house/794?session=1029",
                "https://www.legis.ga.gov/members/house/794?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/794"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-142-lower-ocd-person-bad14891-c7bc-4ac4-b23a-fb5ae3878d67": 0
        }
      },
      "143": {
        "members": [
          {
            "API_ID": "ocd-person/5e6ceaa7-8c7a-4e9b-95fa-304041daaab0",
            "firstName": "James Theodore",
            "lastName": "Beverly",
            "fullName": "James Beverly",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/beverly-james-793.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "143",
              "chamber": "lower",
              "hashID": "SLDL-GA-143-lower-ocd-person-5e6ceaa7-8c7a-4e9b-95fa-304041daaab0",
              "builtID": "ga-lower-143",
              "externalID": "ocd-person/5e6ceaa7-8c7a-4e9b-95fa-304041daaab0",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.beverly@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=793&Session=27",
                "https://www.legis.ga.gov/members/house/793?session=1029",
                "https://www.legis.ga.gov/members/house/793?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/793"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-143-lower-ocd-person-5e6ceaa7-8c7a-4e9b-95fa-304041daaab0": 0
        }
      },
      "144": {
        "members": [
          {
            "API_ID": "ocd-person/ec6cf250-ebcc-4dda-89a5-12da9ebbe51a",
            "firstName": "Roy Dale",
            "lastName": "Washburn",
            "fullName": "Dale Washburn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/washburn-dale-4958.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "144",
              "chamber": "lower",
              "hashID": "SLDL-GA-144-lower-ocd-person-ec6cf250-ebcc-4dda-89a5-12da9ebbe51a",
              "builtID": "ga-lower-144",
              "externalID": "ocd-person/ec6cf250-ebcc-4dda-89a5-12da9ebbe51a",
              "geometry": null
            },
            "contactInfo": {
              "email": "dale.washburn@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4958&Session=27",
                "https://www.legis.ga.gov/members/house/4958?session=1029",
                "https://www.legis.ga.gov/members/house/4958?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4958"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-144-lower-ocd-person-ec6cf250-ebcc-4dda-89a5-12da9ebbe51a": 0
        }
      },
      "145": {
        "members": [
          {
            "API_ID": "ocd-person/f863b50d-c865-463a-830a-e5648837d9f7",
            "firstName": "Robert Lee",
            "lastName": "Dickey",
            "fullName": "Robert Dickey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/dickey-robert-791.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "145",
              "chamber": "lower",
              "hashID": "SLDL-GA-145-lower-ocd-person-f863b50d-c865-463a-830a-e5648837d9f7",
              "builtID": "ga-lower-145",
              "externalID": "ocd-person/f863b50d-c865-463a-830a-e5648837d9f7",
              "geometry": null
            },
            "contactInfo": {
              "email": "robert.dickey@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=791&Session=27",
                "https://www.legis.ga.gov/members/house/791?session=1029",
                "https://www.legis.ga.gov/members/house/791?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/791"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-145-lower-ocd-person-f863b50d-c865-463a-830a-e5648837d9f7": 0
        }
      },
      "146": {
        "members": [
          {
            "API_ID": "ocd-person/ee145624-3295-4db4-b3ce-9d1583e4ef6e",
            "firstName": "Shaw",
            "lastName": "Blackmon",
            "fullName": "Shaw Blackmon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/blackmon-shaw-1878.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "146",
              "chamber": "lower",
              "hashID": "SLDL-GA-146-lower-ocd-person-ee145624-3295-4db4-b3ce-9d1583e4ef6e",
              "builtID": "ga-lower-146",
              "externalID": "ocd-person/ee145624-3295-4db4-b3ce-9d1583e4ef6e",
              "geometry": null
            },
            "contactInfo": {
              "email": "shaw.blackmon@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=1878&Session=27",
                "https://www.legis.ga.gov/members/house/1878?session=1029",
                "https://www.legis.ga.gov/members/house/1878?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/1878"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-146-lower-ocd-person-ee145624-3295-4db4-b3ce-9d1583e4ef6e": 0
        }
      },
      "147": {
        "members": [
          {
            "API_ID": "ocd-person/8b4b00cb-6f78-4b69-b788-796b19d94752",
            "firstName": "Bethany",
            "lastName": "Ballard",
            "fullName": "Bethany Ballard",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/ballard-bethany-5051.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "147",
              "chamber": "lower",
              "hashID": "SLDL-GA-147-lower-ocd-person-8b4b00cb-6f78-4b69-b788-796b19d94752",
              "builtID": "ga-lower-147",
              "externalID": "ocd-person/8b4b00cb-6f78-4b69-b788-796b19d94752",
              "geometry": null
            },
            "contactInfo": {
              "email": "bethany.ballard@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5051?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5051"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-147-lower-ocd-person-8b4b00cb-6f78-4b69-b788-796b19d94752": 0
        }
      },
      "148": {
        "members": [
          {
            "API_ID": "ocd-person/3252da04-a17e-4298-b19d-9dd2fbf29ded",
            "firstName": "Noel",
            "lastName": "Williams",
            "fullName": "Noel Williams",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/williams--jr-noel-4960.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "148",
              "chamber": "lower",
              "hashID": "SLDL-GA-148-lower-ocd-person-3252da04-a17e-4298-b19d-9dd2fbf29ded",
              "builtID": "ga-lower-148",
              "externalID": "ocd-person/3252da04-a17e-4298-b19d-9dd2fbf29ded",
              "geometry": null
            },
            "contactInfo": {
              "email": "noel.williams@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4960&Session=27",
                "https://www.legis.ga.gov/members/house/4960?session=1029",
                "https://www.legis.ga.gov/members/house/4960?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4960"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-148-lower-ocd-person-3252da04-a17e-4298-b19d-9dd2fbf29ded": 0
        }
      },
      "149": {
        "members": [
          {
            "API_ID": "ocd-person/1a9598cc-056f-4725-a3fb-64c3f0fd26ed",
            "firstName": "Danny",
            "lastName": "Mathis",
            "fullName": "Danny Mathis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/mathis-danny-4959.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "149",
              "chamber": "lower",
              "hashID": "SLDL-GA-149-lower-ocd-person-1a9598cc-056f-4725-a3fb-64c3f0fd26ed",
              "builtID": "ga-lower-149",
              "externalID": "ocd-person/1a9598cc-056f-4725-a3fb-64c3f0fd26ed",
              "geometry": null
            },
            "contactInfo": {
              "email": "danny.mathis@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4959&Session=27",
                "https://www.legis.ga.gov/members/house/4959?session=1029",
                "https://www.legis.ga.gov/members/house/4959?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4959"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-149-lower-ocd-person-1a9598cc-056f-4725-a3fb-64c3f0fd26ed": 0
        }
      },
      "150": {
        "members": [
          {
            "API_ID": "ocd-person/af51c2db-8871-45a4-803d-314fa2b9d18d",
            "firstName": "Patty Marie",
            "lastName": "Stinson",
            "fullName": "Patty Marie Stinson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/bentley-patty-811.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "150",
              "chamber": "lower",
              "hashID": "SLDL-GA-150-lower-ocd-person-af51c2db-8871-45a4-803d-314fa2b9d18d",
              "builtID": "ga-lower-150",
              "externalID": "ocd-person/af51c2db-8871-45a4-803d-314fa2b9d18d",
              "geometry": null
            },
            "contactInfo": {
              "email": "patty.stinson@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=811&Session=27",
                "https://www.legis.ga.gov/members/house/811?session=1029",
                "https://www.legis.ga.gov/members/house/811?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/811"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-150-lower-ocd-person-af51c2db-8871-45a4-803d-314fa2b9d18d": 0
        }
      },
      "151": {
        "members": [
          {
            "API_ID": "ocd-person/1831cc73-e694-4cea-83a4-b55c3495f76a",
            "firstName": "Mike",
            "lastName": "Cheokas",
            "fullName": "Mike Cheokas",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/cheokas-mike-81.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "151",
              "chamber": "lower",
              "hashID": "SLDL-GA-151-lower-ocd-person-1831cc73-e694-4cea-83a4-b55c3495f76a",
              "builtID": "ga-lower-151",
              "externalID": "ocd-person/1831cc73-e694-4cea-83a4-b55c3495f76a",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.cheokas@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=81&Session=27",
                "https://www.legis.ga.gov/members/house/81?session=1029",
                "https://www.legis.ga.gov/members/house/81?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/81"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-151-lower-ocd-person-1831cc73-e694-4cea-83a4-b55c3495f76a": 0
        }
      },
      "152": {
        "members": [
          {
            "API_ID": "ocd-person/23dfc274-73d1-45df-b381-67e597cf0b84",
            "firstName": "William J.",
            "lastName": "Yearta",
            "fullName": "Bill Yearta",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/yearta-bill-4969.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "152",
              "chamber": "lower",
              "hashID": "SLDL-GA-152-lower-ocd-person-23dfc274-73d1-45df-b381-67e597cf0b84",
              "builtID": "ga-lower-152",
              "externalID": "ocd-person/23dfc274-73d1-45df-b381-67e597cf0b84",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.yearta@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4969&Session=27",
                "https://www.legis.ga.gov/members/house/4969?session=1029",
                "https://www.legis.ga.gov/members/house/4969?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4969"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-152-lower-ocd-person-23dfc274-73d1-45df-b381-67e597cf0b84": 0
        }
      },
      "153": {
        "members": [
          {
            "API_ID": "ocd-person/9a8e7b0c-3c19-473f-b04a-d3a17037af0a",
            "firstName": "David",
            "lastName": "Sampson",
            "fullName": "David Sampson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/sampson-david-5052.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "153",
              "chamber": "lower",
              "hashID": "SLDL-GA-153-lower-ocd-person-9a8e7b0c-3c19-473f-b04a-d3a17037af0a",
              "builtID": "ga-lower-153",
              "externalID": "ocd-person/9a8e7b0c-3c19-473f-b04a-d3a17037af0a",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.sampson@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5052?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5052"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-153-lower-ocd-person-9a8e7b0c-3c19-473f-b04a-d3a17037af0a": 0
        }
      },
      "154": {
        "members": [
          {
            "API_ID": "ocd-person/570a213a-a475-4fd3-ac36-23e1bd1190be",
            "firstName": "Gerald E.",
            "lastName": "Greene",
            "fullName": "Gerald Greene",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/greene-gerald-115.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "154",
              "chamber": "lower",
              "hashID": "SLDL-GA-154-lower-ocd-person-570a213a-a475-4fd3-ac36-23e1bd1190be",
              "builtID": "ga-lower-154",
              "externalID": "ocd-person/570a213a-a475-4fd3-ac36-23e1bd1190be",
              "geometry": null
            },
            "contactInfo": {
              "email": "gerald.greene@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=115&Session=27",
                "https://www.legis.ga.gov/members/house/115?session=1029",
                "https://www.legis.ga.gov/members/house/115?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/115"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-154-lower-ocd-person-570a213a-a475-4fd3-ac36-23e1bd1190be": 0
        }
      },
      "155": {
        "members": [
          {
            "API_ID": "ocd-person/c81779f3-65bf-4714-b295-79cac3b0da28",
            "firstName": "Matt",
            "lastName": "Hatchett",
            "fullName": "Matt Hatchett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/hatchett-matt-781.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "155",
              "chamber": "lower",
              "hashID": "SLDL-GA-155-lower-ocd-person-c81779f3-65bf-4714-b295-79cac3b0da28",
              "builtID": "ga-lower-155",
              "externalID": "ocd-person/c81779f3-65bf-4714-b295-79cac3b0da28",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.hatchett@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=781&Session=27",
                "https://www.legis.ga.gov/members/house/781?session=1029",
                "https://www.legis.ga.gov/members/house/781?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/781"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-155-lower-ocd-person-c81779f3-65bf-4714-b295-79cac3b0da28": 0
        }
      },
      "156": {
        "members": [
          {
            "API_ID": "ocd-person/df874c28-6403-42c1-9cbb-0855508cda85",
            "firstName": "Leesa Wynn",
            "lastName": "Hagan",
            "fullName": "Leesa Hagan",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/hagan-leesa-5010.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "156",
              "chamber": "lower",
              "hashID": "SLDL-GA-156-lower-ocd-person-df874c28-6403-42c1-9cbb-0855508cda85",
              "builtID": "ga-lower-156",
              "externalID": "ocd-person/df874c28-6403-42c1-9cbb-0855508cda85",
              "geometry": null
            },
            "contactInfo": {
              "email": "leesa.hagan@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5010?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5010"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-156-lower-ocd-person-df874c28-6403-42c1-9cbb-0855508cda85": 0
        }
      },
      "157": {
        "members": [
          {
            "API_ID": "ocd-person/adefd0ba-43cd-4aae-9321-12f67e57ac8a",
            "firstName": "William A.",
            "lastName": "Werkheiser",
            "fullName": "Bill Werkheiser",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/werkheiser-bill-863.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "157",
              "chamber": "lower",
              "hashID": "SLDL-GA-157-lower-ocd-person-adefd0ba-43cd-4aae-9321-12f67e57ac8a",
              "builtID": "ga-lower-157",
              "externalID": "ocd-person/adefd0ba-43cd-4aae-9321-12f67e57ac8a",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.werkheiser@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=863&Session=27",
                "https://www.legis.ga.gov/members/house/863?session=1029",
                "https://www.legis.ga.gov/members/house/863?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/863"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-157-lower-ocd-person-adefd0ba-43cd-4aae-9321-12f67e57ac8a": 0
        }
      },
      "158": {
        "members": [
          {
            "API_ID": "ocd-person/842218b5-88ae-4ba8-8395-756bf3340e16",
            "firstName": "Larry J.",
            "lastName": "Parrish",
            "fullName": "Butch Parrish",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/parrish-butch-183.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "158",
              "chamber": "lower",
              "hashID": "SLDL-GA-158-lower-ocd-person-842218b5-88ae-4ba8-8395-756bf3340e16",
              "builtID": "ga-lower-158",
              "externalID": "ocd-person/842218b5-88ae-4ba8-8395-756bf3340e16",
              "geometry": null
            },
            "contactInfo": {
              "email": "butch.parrish@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=183&Session=27",
                "https://www.legis.ga.gov/members/house/183?session=1029",
                "https://www.legis.ga.gov/members/house/183?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/183"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-158-lower-ocd-person-842218b5-88ae-4ba8-8395-756bf3340e16": 0
        }
      },
      "159": {
        "members": [
          {
            "API_ID": "ocd-person/64012657-d026-411c-9525-3232524a5145",
            "firstName": "Jon G.",
            "lastName": "Burns",
            "fullName": "Jon Burns",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/burns-jon-73.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "159",
              "chamber": "lower",
              "hashID": "SLDL-GA-159-lower-ocd-person-64012657-d026-411c-9525-3232524a5145",
              "builtID": "ga-lower-159",
              "externalID": "ocd-person/64012657-d026-411c-9525-3232524a5145",
              "geometry": null
            },
            "contactInfo": {
              "email": "jon.burns@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=73&Session=27",
                "https://www.legis.ga.gov/members/house/73?session=1029",
                "https://www.legis.ga.gov/members/house/73?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/73"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-159-lower-ocd-person-64012657-d026-411c-9525-3232524a5145": 0
        }
      },
      "160": {
        "members": [
          {
            "API_ID": "ocd-person/6971bb34-9ea4-4bdc-971e-c1a2c5de344c",
            "firstName": "Lehman",
            "lastName": "Franklin",
            "fullName": "Lehman Franklin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/franklin-lehman-5053.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "160",
              "chamber": "lower",
              "hashID": "SLDL-GA-160-lower-ocd-person-6971bb34-9ea4-4bdc-971e-c1a2c5de344c",
              "builtID": "ga-lower-160",
              "externalID": "ocd-person/6971bb34-9ea4-4bdc-971e-c1a2c5de344c",
              "geometry": null
            },
            "contactInfo": {
              "email": "lehman.franklin@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5053?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5053"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-160-lower-ocd-person-6971bb34-9ea4-4bdc-971e-c1a2c5de344c": 0
        }
      },
      "161": {
        "members": [
          {
            "API_ID": "ocd-person/4a9ef816-9130-4510-a19e-3124e3f2aeed",
            "firstName": "William W.",
            "lastName": "Hitchens",
            "fullName": "Bill Hitchens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/hitchens-bill-823.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "161",
              "chamber": "lower",
              "hashID": "SLDL-GA-161-lower-ocd-person-4a9ef816-9130-4510-a19e-3124e3f2aeed",
              "builtID": "ga-lower-161",
              "externalID": "ocd-person/4a9ef816-9130-4510-a19e-3124e3f2aeed",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.hitchens@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=823&Session=27",
                "https://www.legis.ga.gov/members/house/823?session=1029",
                "https://www.legis.ga.gov/members/house/823?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/823"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-161-lower-ocd-person-4a9ef816-9130-4510-a19e-3124e3f2aeed": 0
        }
      },
      "162": {
        "members": [
          {
            "API_ID": "ocd-person/562e162f-2a60-441c-956b-11db12eef266",
            "firstName": "Carl Wayne",
            "lastName": "Gilliard",
            "fullName": "Carl Gilliard",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/gilliard-carl-4881.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "162",
              "chamber": "lower",
              "hashID": "SLDL-GA-162-lower-ocd-person-562e162f-2a60-441c-956b-11db12eef266",
              "builtID": "ga-lower-162",
              "externalID": "ocd-person/562e162f-2a60-441c-956b-11db12eef266",
              "geometry": null
            },
            "contactInfo": {
              "email": "carl.gilliard@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4881&Session=27",
                "https://www.legis.ga.gov/members/house/4881?session=1029",
                "https://www.legis.ga.gov/members/house/4881?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4881"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-162-lower-ocd-person-562e162f-2a60-441c-956b-11db12eef266": 0
        }
      },
      "163": {
        "members": [
          {
            "API_ID": "ocd-person/e16d6516-badc-4682-9e3a-84f30a44b060",
            "firstName": "Anne Allen",
            "lastName": "Westbrook",
            "fullName": "Anne Westbrook",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/westbrook-anne-allen-5054.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "163",
              "chamber": "lower",
              "hashID": "SLDL-GA-163-lower-ocd-person-e16d6516-badc-4682-9e3a-84f30a44b060",
              "builtID": "ga-lower-163",
              "externalID": "ocd-person/e16d6516-badc-4682-9e3a-84f30a44b060",
              "geometry": null
            },
            "contactInfo": {
              "email": "anneallen.westbrook@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5054?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5054"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-163-lower-ocd-person-e16d6516-badc-4682-9e3a-84f30a44b060": 0
        }
      },
      "164": {
        "members": [
          {
            "API_ID": "ocd-person/aeaeb854-ff9c-47cb-8069-395b60ba05b2",
            "firstName": "Ron",
            "lastName": "Stephens",
            "fullName": "Ron Stephens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/stephens-ron-219.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "164",
              "chamber": "lower",
              "hashID": "SLDL-GA-164-lower-ocd-person-aeaeb854-ff9c-47cb-8069-395b60ba05b2",
              "builtID": "ga-lower-164",
              "externalID": "ocd-person/aeaeb854-ff9c-47cb-8069-395b60ba05b2",
              "geometry": null
            },
            "contactInfo": {
              "email": "ron.stephens@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=219&Session=27",
                "https://www.legis.ga.gov/members/house/219?session=1029",
                "https://www.legis.ga.gov/members/house/219?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/219"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-164-lower-ocd-person-aeaeb854-ff9c-47cb-8069-395b60ba05b2": 0
        }
      },
      "165": {
        "members": [
          {
            "API_ID": "ocd-person/18427775-e3e7-4730-bb6f-722616e6aebe",
            "firstName": "Edna Branch",
            "lastName": "Jackson",
            "fullName": "Edna Jackson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/jackson-edna-5011.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "165",
              "chamber": "lower",
              "hashID": "SLDL-GA-165-lower-ocd-person-18427775-e3e7-4730-bb6f-722616e6aebe",
              "builtID": "ga-lower-165",
              "externalID": "ocd-person/18427775-e3e7-4730-bb6f-722616e6aebe",
              "geometry": null
            },
            "contactInfo": {
              "email": "edna.jackson@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2227 N. Fernwood Court, Savannah, GA 31404",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5011?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5011"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-165-lower-ocd-person-18427775-e3e7-4730-bb6f-722616e6aebe": 0
        }
      },
      "166": {
        "members": [
          {
            "API_ID": "ocd-person/38c596a4-df69-4a56-9329-be73f26bc7d5",
            "firstName": "Jesse",
            "lastName": "Petrea",
            "fullName": "Jesse Petrea",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/petrea-jesse-862.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "166",
              "chamber": "lower",
              "hashID": "SLDL-GA-166-lower-ocd-person-38c596a4-df69-4a56-9329-be73f26bc7d5",
              "builtID": "ga-lower-166",
              "externalID": "ocd-person/38c596a4-df69-4a56-9329-be73f26bc7d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "jesse.petrea@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=862&Session=27",
                "https://www.legis.ga.gov/members/house/862?session=1029",
                "https://www.legis.ga.gov/members/house/862?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/862"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-166-lower-ocd-person-38c596a4-df69-4a56-9329-be73f26bc7d5": 0
        }
      },
      "167": {
        "members": [
          {
            "API_ID": "ocd-person/d40576ac-fbc0-4b42-98ee-f6362063784d",
            "firstName": "Homer M.",
            "lastName": "DeLoach",
            "fullName": "Buddy DeLoach",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/deloach-buddy-303.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "167",
              "chamber": "lower",
              "hashID": "SLDL-GA-167-lower-ocd-person-d40576ac-fbc0-4b42-98ee-f6362063784d",
              "builtID": "ga-lower-167",
              "externalID": "ocd-person/d40576ac-fbc0-4b42-98ee-f6362063784d",
              "geometry": null
            },
            "contactInfo": {
              "email": "buddy.deloach@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2689 Sutherland Bluff Drive NE, Townsend, GA 31331",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/303?session=1029",
                "https://www.legis.ga.gov/members/house/303?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/303"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-167-lower-ocd-person-d40576ac-fbc0-4b42-98ee-f6362063784d": 0
        }
      },
      "168": {
        "members": [
          {
            "API_ID": "ocd-person/a5a0998b-3837-4c4e-a66b-a567dc2f9d77",
            "firstName": "Al",
            "lastName": "Williams",
            "fullName": "Al Williams",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/williams-al-230.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "168",
              "chamber": "lower",
              "hashID": "SLDL-GA-168-lower-ocd-person-a5a0998b-3837-4c4e-a66b-a567dc2f9d77",
              "builtID": "ga-lower-168",
              "externalID": "ocd-person/a5a0998b-3837-4c4e-a66b-a567dc2f9d77",
              "geometry": null
            },
            "contactInfo": {
              "email": "al.williams@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "9041 E. Oglethorpe Highway, Midway, GA 31320",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=230&Session=27",
                "https://www.legis.ga.gov/members/house/230?session=1029",
                "https://www.legis.ga.gov/members/house/230?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/230"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-168-lower-ocd-person-a5a0998b-3837-4c4e-a66b-a567dc2f9d77": 0
        }
      },
      "169": {
        "members": [
          {
            "API_ID": "ocd-person/cdd385ef-1b37-49e6-a8d7-a2b8087bef4e",
            "firstName": "Clay",
            "lastName": "Pirkle",
            "fullName": "Clay Pirkle",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/pirkle-clay-1877.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "169",
              "chamber": "lower",
              "hashID": "SLDL-GA-169-lower-ocd-person-cdd385ef-1b37-49e6-a8d7-a2b8087bef4e",
              "builtID": "ga-lower-169",
              "externalID": "ocd-person/cdd385ef-1b37-49e6-a8d7-a2b8087bef4e",
              "geometry": null
            },
            "contactInfo": {
              "email": "clay.pirkle@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 607, Ashburn, GA 31714",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=1877&Session=27",
                "https://www.legis.ga.gov/members/house/1877?session=1029",
                "https://www.legis.ga.gov/members/house/1877?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/1877"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-169-lower-ocd-person-cdd385ef-1b37-49e6-a8d7-a2b8087bef4e": 0
        }
      },
      "170": {
        "members": [
          {
            "API_ID": "ocd-person/baf8f67a-639f-4f5e-8f6e-a20c7bfdeac8",
            "firstName": "Penny",
            "lastName": "Houston",
            "fullName": "Penny Houston",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/houston-penny-130.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "170",
              "chamber": "lower",
              "hashID": "SLDL-GA-170-lower-ocd-person-baf8f67a-639f-4f5e-8f6e-a20c7bfdeac8",
              "builtID": "ga-lower-170",
              "externalID": "ocd-person/baf8f67a-639f-4f5e-8f6e-a20c7bfdeac8",
              "geometry": null
            },
            "contactInfo": {
              "email": "penny.houston@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=130&Session=27",
                "https://www.legis.ga.gov/members/house/130?session=1029",
                "https://www.legis.ga.gov/members/house/130?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/130"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-170-lower-ocd-person-baf8f67a-639f-4f5e-8f6e-a20c7bfdeac8": 0
        }
      },
      "171": {
        "members": [
          {
            "API_ID": "ocd-person/c862c17f-ed00-41d7-97e5-1138f451a96c",
            "firstName": "Joe",
            "lastName": "Campbell",
            "fullName": "Joe Campbell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/campbell-joe-4970.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "171",
              "chamber": "lower",
              "hashID": "SLDL-GA-171-lower-ocd-person-c862c17f-ed00-41d7-97e5-1138f451a96c",
              "builtID": "ga-lower-171",
              "externalID": "ocd-person/c862c17f-ed00-41d7-97e5-1138f451a96c",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.campbell@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4970&Session=27",
                "https://www.legis.ga.gov/members/house/4970?session=1029",
                "https://www.legis.ga.gov/members/house/4970?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4970"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-171-lower-ocd-person-c862c17f-ed00-41d7-97e5-1138f451a96c": 0
        }
      },
      "172": {
        "members": [
          {
            "API_ID": "ocd-person/cba2f06c-773c-49d3-949c-b1a2db377c21",
            "firstName": "Charles H.",
            "lastName": "Cannon",
            "fullName": "Chas Cannon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/cannon-charles--chas--5058.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "172",
              "chamber": "lower",
              "hashID": "SLDL-GA-172-lower-ocd-person-cba2f06c-773c-49d3-949c-b1a2db377c21",
              "builtID": "ga-lower-172",
              "externalID": "ocd-person/cba2f06c-773c-49d3-949c-b1a2db377c21",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.cannon@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5058?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5058"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-172-lower-ocd-person-cba2f06c-773c-49d3-949c-b1a2db377c21": 0
        }
      },
      "173": {
        "members": [
          {
            "API_ID": "ocd-person/d00eb950-0fb6-41ab-ae22-8e9ce84d7cf0",
            "firstName": "Darlene K.",
            "lastName": "Taylor",
            "fullName": "Darlene Taylor",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/taylor-darlene-786.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "173",
              "chamber": "lower",
              "hashID": "SLDL-GA-173-lower-ocd-person-d00eb950-0fb6-41ab-ae22-8e9ce84d7cf0",
              "builtID": "ga-lower-173",
              "externalID": "ocd-person/d00eb950-0fb6-41ab-ae22-8e9ce84d7cf0",
              "geometry": null
            },
            "contactInfo": {
              "email": "darlene.taylor@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=786&Session=27",
                "https://www.legis.ga.gov/members/house/786?session=1029",
                "https://www.legis.ga.gov/members/house/786?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/786"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-173-lower-ocd-person-d00eb950-0fb6-41ab-ae22-8e9ce84d7cf0": 0
        }
      },
      "174": {
        "members": [
          {
            "API_ID": "ocd-person/a34c9a5a-f307-4c07-b766-3b3b1148d7b1",
            "firstName": "John L.",
            "lastName": "Corbett",
            "fullName": "John Corbett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/corbett-john-859.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "174",
              "chamber": "lower",
              "hashID": "SLDL-GA-174-lower-ocd-person-a34c9a5a-f307-4c07-b766-3b3b1148d7b1",
              "builtID": "ga-lower-174",
              "externalID": "ocd-person/a34c9a5a-f307-4c07-b766-3b3b1148d7b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.corbett@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=859&Session=27",
                "https://www.legis.ga.gov/members/house/859?session=1029",
                "https://www.legis.ga.gov/members/house/859?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/859"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-174-lower-ocd-person-a34c9a5a-f307-4c07-b766-3b3b1148d7b1": 0
        }
      },
      "175": {
        "members": [
          {
            "API_ID": "ocd-person/9249be26-54d2-467a-88ec-2588b886148d",
            "firstName": "John",
            "lastName": "LaHood",
            "fullName": "John LaHood",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/lahood-john-4923.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "175",
              "chamber": "lower",
              "hashID": "SLDL-GA-175-lower-ocd-person-9249be26-54d2-467a-88ec-2588b886148d",
              "builtID": "ga-lower-175",
              "externalID": "ocd-person/9249be26-54d2-467a-88ec-2588b886148d",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.lahood@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 5500, Valdosta, GA 31603",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4923&Session=27",
                "https://www.legis.ga.gov/members/house/4923?session=1029",
                "https://www.legis.ga.gov/members/house/4923?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4923"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-175-lower-ocd-person-9249be26-54d2-467a-88ec-2588b886148d": 0
        }
      },
      "176": {
        "members": [
          {
            "API_ID": "ocd-person/dbce462d-74e3-42d3-bd6c-3b9f8eeb69fe",
            "firstName": "James",
            "lastName": "Burchett",
            "fullName": "James Burchett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/burchett-james-4967.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "176",
              "chamber": "lower",
              "hashID": "SLDL-GA-176-lower-ocd-person-dbce462d-74e3-42d3-bd6c-3b9f8eeb69fe",
              "builtID": "ga-lower-176",
              "externalID": "ocd-person/dbce462d-74e3-42d3-bd6c-3b9f8eeb69fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.burchett@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "215 Pendleton St., Waycross, GA 31501",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4967&Session=27",
                "https://www.legis.ga.gov/members/house/4967?session=1029",
                "https://www.legis.ga.gov/members/house/4967?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4967"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-176-lower-ocd-person-dbce462d-74e3-42d3-bd6c-3b9f8eeb69fe": 0
        }
      },
      "177": {
        "members": [
          {
            "API_ID": "ocd-person/b0587620-8c43-45b8-8212-285a13a4d47c",
            "firstName": "Dexter",
            "lastName": "Sharper",
            "fullName": "Dexter Sharper",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/sharper-dexter-831.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "177",
              "chamber": "lower",
              "hashID": "SLDL-GA-177-lower-ocd-person-b0587620-8c43-45b8-8212-285a13a4d47c",
              "builtID": "ga-lower-177",
              "externalID": "ocd-person/b0587620-8c43-45b8-8212-285a13a4d47c",
              "geometry": null
            },
            "contactInfo": {
              "email": "dexter.sharper@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=831&Session=27",
                "https://www.legis.ga.gov/members/house/831?session=1029",
                "https://www.legis.ga.gov/members/house/831?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/831"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-177-lower-ocd-person-b0587620-8c43-45b8-8212-285a13a4d47c": 0
        }
      },
      "178": {
        "members": [
          {
            "API_ID": "ocd-person/3194a8e0-aaf8-4686-b2c9-a22521f85fff",
            "firstName": "Steven A.",
            "lastName": "Meeks",
            "fullName": "Steven Meeks",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/meeks-steven-4961.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "178",
              "chamber": "lower",
              "hashID": "SLDL-GA-178-lower-ocd-person-3194a8e0-aaf8-4686-b2c9-a22521f85fff",
              "builtID": "ga-lower-178",
              "externalID": "ocd-person/3194a8e0-aaf8-4686-b2c9-a22521f85fff",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.meeks@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4961&Session=27",
                "https://www.legis.ga.gov/members/house/4961?session=1029",
                "https://www.legis.ga.gov/members/house/4961?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4961"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-178-lower-ocd-person-3194a8e0-aaf8-4686-b2c9-a22521f85fff": 0
        }
      },
      "179": {
        "members": [
          {
            "API_ID": "ocd-person/1da20ad9-1a05-42ab-addb-7fbf295a0e5d",
            "firstName": "Rick",
            "lastName": "Townsend",
            "fullName": "Rick Townsend",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/townsend-rick-5055.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "179",
              "chamber": "lower",
              "hashID": "SLDL-GA-179-lower-ocd-person-1da20ad9-1a05-42ab-addb-7fbf295a0e5d",
              "builtID": "ga-lower-179",
              "externalID": "ocd-person/1da20ad9-1a05-42ab-addb-7fbf295a0e5d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.townsend@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5055?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/5055"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-179-lower-ocd-person-1da20ad9-1a05-42ab-addb-7fbf295a0e5d": 0
        }
      },
      "180": {
        "members": [
          {
            "API_ID": "ocd-person/99082062-acd6-4c6b-bb45-2cfa071f19fc",
            "firstName": "Steven",
            "lastName": "Sainz",
            "fullName": "Steven Sainz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/sainz-steven-4962.jpg",
            "title": "GA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "180",
              "chamber": "lower",
              "hashID": "SLDL-GA-180-lower-ocd-person-99082062-acd6-4c6b-bb45-2cfa071f19fc",
              "builtID": "ga-lower-180",
              "externalID": "ocd-person/99082062-acd6-4c6b-bb45-2cfa071f19fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.sainz@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4962&Session=27",
                "https://www.legis.ga.gov/members/house/4962?session=1029",
                "https://www.legis.ga.gov/members/house/4962?session=1031",
                "https://www.legis.ga.gov/members/house",
                "https://www.legis.ga.gov/members/house/4962"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-GA-180-lower-ocd-person-99082062-acd6-4c6b-bb45-2cfa071f19fc": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/fd2717f1-6294-4601-a027-8fc41195372c",
            "firstName": "Benjamin Luther",
            "lastName": "Watson",
            "fullName": "Ben Watson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/watson-ben-784.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-GA-1-upper-ocd-person-fd2717f1-6294-4601-a027-8fc41195372c",
              "builtID": "ga-upper-1",
              "externalID": "ocd-person/fd2717f1-6294-4601-a027-8fc41195372c",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.watson@house.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=784&Session=27",
                "https://www.legis.ga.gov/members/senate/784?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/784?session=1029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-1-upper-ocd-person-fd2717f1-6294-4601-a027-8fc41195372c": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/73918c45-83c1-4d52-a84d-3ad7ad8de40b",
            "firstName": "Derek J.",
            "lastName": "Mallow",
            "fullName": "Derek Mallow",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/mallow-derek-5007.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-GA-2-upper-ocd-person-73918c45-83c1-4d52-a84d-3ad7ad8de40b",
              "builtID": "ga-upper-2",
              "externalID": "ocd-person/73918c45-83c1-4d52-a84d-3ad7ad8de40b",
              "geometry": null
            },
            "contactInfo": {
              "email": "derek.mallow@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/house/5007?session=1029",
                "https://www.legis.ga.gov/members/senate/5007?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/5007"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-2-upper-ocd-person-73918c45-83c1-4d52-a84d-3ad7ad8de40b": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/011995be-4dd0-43b2-ae75-57ae45f53576",
            "firstName": "Michael D.",
            "lastName": "Hodges",
            "fullName": "Mike Hodges",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/hodges-mike-5013.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-GA-3-upper-ocd-person-011995be-4dd0-43b2-ae75-57ae45f53576",
              "builtID": "ga-upper-3",
              "externalID": "ocd-person/011995be-4dd0-43b2-ae75-57ae45f53576",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.hodges@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/5013?session=1032",
                "https://www.legis.ga.gov/members/senate/5013?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-3-upper-ocd-person-011995be-4dd0-43b2-ae75-57ae45f53576": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/449db918-10b7-4fa7-a047-eb9de416dce3",
            "firstName": "William R.",
            "lastName": "Hickman",
            "fullName": "Billy Hickman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/hickman-billy-4972.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-GA-4-upper-ocd-person-449db918-10b7-4fa7-a047-eb9de416dce3",
              "builtID": "ga-upper-4",
              "externalID": "ocd-person/449db918-10b7-4fa7-a047-eb9de416dce3",
              "geometry": null
            },
            "contactInfo": {
              "email": "billy.hickman@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/senate/4972?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4972?session=1029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-4-upper-ocd-person-449db918-10b7-4fa7-a047-eb9de416dce3": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/5b6e0e37-802b-4103-b401-551532aba2e7",
            "firstName": "Sheikh",
            "lastName": "Rahman",
            "fullName": "Sheikh Rahman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/rahman-sheikh-4924.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-GA-5-upper-ocd-person-5b6e0e37-802b-4103-b401-551532aba2e7",
              "builtID": "ga-upper-5",
              "externalID": "ocd-person/5b6e0e37-802b-4103-b401-551532aba2e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "sheikh.rahman@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=4924&Session=27",
                "https://www.legis.ga.gov/members/senate/4924?session=1029",
                "https://www.legis.ga.gov/members/senate/4924?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4924?"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-5-upper-ocd-person-5b6e0e37-802b-4103-b401-551532aba2e7": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/e6dcaefd-8ecd-4f08-990f-e4443ae53ee8",
            "firstName": "Jason",
            "lastName": "Esteves",
            "fullName": "Jason Esteves",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/esteves-jason-5014.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-GA-6-upper-ocd-person-e6dcaefd-8ecd-4f08-990f-e4443ae53ee8",
              "builtID": "ga-upper-6",
              "externalID": "ocd-person/e6dcaefd-8ecd-4f08-990f-e4443ae53ee8",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.esteves@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/5014?session=1032",
                "https://www.legis.ga.gov/members/senate/5014?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-6-upper-ocd-person-e6dcaefd-8ecd-4f08-990f-e4443ae53ee8": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/23ffff2b-3ce6-46dc-996c-7b4fd71607bc",
            "firstName": "Nabilah Aishah",
            "lastName": "Islam Parkes",
            "fullName": "Nabilah Islam Parkes",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/islam-nabilah-5015.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-GA-7-upper-ocd-person-23ffff2b-3ce6-46dc-996c-7b4fd71607bc",
              "builtID": "ga-upper-7",
              "externalID": "ocd-person/23ffff2b-3ce6-46dc-996c-7b4fd71607bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "nabilah.islam@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/5015?session=1032",
                "https://www.legis.ga.gov/members/senate/5015?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-7-upper-ocd-person-23ffff2b-3ce6-46dc-996c-7b4fd71607bc": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/b90b899d-87f0-43a5-9d15-6e310187ac41",
            "firstName": "Russ",
            "lastName": "Goodman",
            "fullName": "Russ Goodman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/goodman-russ-4977.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-GA-8-upper-ocd-person-b90b899d-87f0-43a5-9d15-6e310187ac41",
              "builtID": "ga-upper-8",
              "externalID": "ocd-person/b90b899d-87f0-43a5-9d15-6e310187ac41",
              "geometry": null
            },
            "contactInfo": {
              "email": "russ.goodman@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/senate/4977?session=1029",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4977?session=1032",
                "https://www.legis.ga.gov/members/senate/4977?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-8-upper-ocd-person-b90b899d-87f0-43a5-9d15-6e310187ac41": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/57ad1f65-9969-4be2-b262-684bf53123fb",
            "firstName": "Nikki",
            "lastName": "Merritt",
            "fullName": "Nikki Merritt",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/merritt-nikki-4978.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-GA-9-upper-ocd-person-57ad1f65-9969-4be2-b262-684bf53123fb",
              "builtID": "ga-upper-9",
              "externalID": "ocd-person/57ad1f65-9969-4be2-b262-684bf53123fb",
              "geometry": null
            },
            "contactInfo": {
              "email": "nikki.merritt@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/senate/4978?session=1029",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4978?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-9-upper-ocd-person-57ad1f65-9969-4be2-b262-684bf53123fb": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/acc7c167-6251-4ce8-8021-511acfa291ef",
            "firstName": "Emanuel Davie",
            "lastName": "Jones",
            "fullName": "Emanuel Jones",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/jones-emanuel-28.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-GA-10-upper-ocd-person-acc7c167-6251-4ce8-8021-511acfa291ef",
              "builtID": "ga-upper-10",
              "externalID": "ocd-person/acc7c167-6251-4ce8-8021-511acfa291ef",
              "geometry": null
            },
            "contactInfo": {
              "email": "emanuel.jones@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=28&Session=27",
                "https://www.legis.ga.gov/members/senate/28?session=1029",
                "https://www.legis.ga.gov/members/senate/28?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/28"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-10-upper-ocd-person-acc7c167-6251-4ce8-8021-511acfa291ef": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/cbcdfca3-e47d-4b32-9164-ec0709a134bf",
            "firstName": "Samuel Lawrence",
            "lastName": "Watson",
            "fullName": "Sam Watson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/watson-sam-837.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-GA-11-upper-ocd-person-cbcdfca3-e47d-4b32-9164-ec0709a134bf",
              "builtID": "ga-upper-11",
              "externalID": "ocd-person/cbcdfca3-e47d-4b32-9164-ec0709a134bf",
              "geometry": null
            },
            "contactInfo": {
              "email": "sam.watson@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=837&Session=27",
                "https://www.legis.ga.gov/members/house/837?session=1029",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/837?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-11-upper-ocd-person-cbcdfca3-e47d-4b32-9164-ec0709a134bf": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/a0bfdc0d-4110-4cd2-b83c-834bba4300f5",
            "firstName": "Freddie Powell",
            "lastName": "Sims",
            "fullName": "Freddie Sims",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/sims-freddie-209.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-GA-12-upper-ocd-person-a0bfdc0d-4110-4cd2-b83c-834bba4300f5",
              "builtID": "ga-upper-12",
              "externalID": "ocd-person/a0bfdc0d-4110-4cd2-b83c-834bba4300f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "freddie.sims@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=209&Session=27",
                "https://www.legis.ga.gov/members/senate/209?session=1029",
                "https://www.legis.ga.gov/members/senate/209?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/209"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-12-upper-ocd-person-a0bfdc0d-4110-4cd2-b83c-834bba4300f5": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/98850c9f-1c37-4177-9ad8-17d2b4d3c8d4",
            "firstName": "Carden H.",
            "lastName": "Summers",
            "fullName": "Carden Summers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/summers-carden-4971.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-GA-13-upper-ocd-person-98850c9f-1c37-4177-9ad8-17d2b4d3c8d4",
              "builtID": "ga-upper-13",
              "externalID": "ocd-person/98850c9f-1c37-4177-9ad8-17d2b4d3c8d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "carden.summers@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=4971&Session=27",
                "https://www.legis.ga.gov/members/senate/4971?session=1029",
                "https://www.legis.ga.gov/members/senate/4971?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4971"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-13-upper-ocd-person-98850c9f-1c37-4177-9ad8-17d2b4d3c8d4": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/b83b0486-773f-4979-a1ce-e2f7f3e6d7e1",
            "firstName": "Joshua Ivan",
            "lastName": "McLaurin",
            "fullName": "Josh McLaurin",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/mclaurin-josh-4942.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-GA-14-upper-ocd-person-b83b0486-773f-4979-a1ce-e2f7f3e6d7e1",
              "builtID": "ga-upper-14",
              "externalID": "ocd-person/b83b0486-773f-4979-a1ce-e2f7f3e6d7e1",
              "geometry": null
            },
            "contactInfo": {
              "email": "josh.mclaurin@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 567297, Atlanta, GA 31156",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4942&Session=27",
                "https://www.legis.ga.gov/members/house/4942?session=1029",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4942?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-14-upper-ocd-person-b83b0486-773f-4979-a1ce-e2f7f3e6d7e1": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/4cf7c975-678b-4e21-9877-6d22d7b37916",
            "firstName": "Ed",
            "lastName": "Harbison",
            "fullName": "Ed Harbison",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/harbison-ed-17.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-GA-15-upper-ocd-person-4cf7c975-678b-4e21-9877-6d22d7b37916",
              "builtID": "ga-upper-15",
              "externalID": "ocd-person/4cf7c975-678b-4e21-9877-6d22d7b37916",
              "geometry": null
            },
            "contactInfo": {
              "email": "ed.harbison@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=17&Session=27",
                "https://www.legis.ga.gov/members/senate/17?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/17?session=1029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-15-upper-ocd-person-4cf7c975-678b-4e21-9877-6d22d7b37916": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/078246e9-90b8-43aa-bb92-d7016f517b8e",
            "firstName": "Maurice H.",
            "lastName": "Harbin",
            "fullName": "Marty Harbin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/harbin-marty-850.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-GA-16-upper-ocd-person-078246e9-90b8-43aa-bb92-d7016f517b8e",
              "builtID": "ga-upper-16",
              "externalID": "ocd-person/078246e9-90b8-43aa-bb92-d7016f517b8e",
              "geometry": null
            },
            "contactInfo": {
              "email": "marty.harbin@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=850&Session=27",
                "https://www.legis.ga.gov/members/senate/850?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/850?session=1029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-16-upper-ocd-person-078246e9-90b8-43aa-bb92-d7016f517b8e": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/3a5721a0-d848-40d0-a48a-66b797e7d7d9",
            "firstName": "Robert Brian",
            "lastName": "Strickland",
            "fullName": "Brian Strickland",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/strickland-brian-835.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-GA-17-upper-ocd-person-3a5721a0-d848-40d0-a48a-66b797e7d7d9",
              "builtID": "ga-upper-17",
              "externalID": "ocd-person/3a5721a0-d848-40d0-a48a-66b797e7d7d9",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.strickland@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=835&Session=27",
                "https://www.legis.ga.gov/members/senate/835?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/835?session=1029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-17-upper-ocd-person-3a5721a0-d848-40d0-a48a-66b797e7d7d9": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/ffa17688-2ca9-40ba-9eb3-3991f930bf53",
            "firstName": "John Flanders",
            "lastName": "Kennedy",
            "fullName": "John Kennedy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/kennedy-john-852.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-GA-18-upper-ocd-person-ffa17688-2ca9-40ba-9eb3-3991f930bf53",
              "builtID": "ga-upper-18",
              "externalID": "ocd-person/ffa17688-2ca9-40ba-9eb3-3991f930bf53",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.kennedy@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=852&Session=27",
                "https://www.legis.ga.gov/members/senate/852?session=1029",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/852?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-18-upper-ocd-person-ffa17688-2ca9-40ba-9eb3-3991f930bf53": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/e9c5a2fc-3354-49e1-ac29-783502155fe7",
            "firstName": "Blake",
            "lastName": "Tillery",
            "fullName": "Blake Tillery",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/tillery-blake-4908.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-GA-19-upper-ocd-person-e9c5a2fc-3354-49e1-ac29-783502155fe7",
              "builtID": "ga-upper-19",
              "externalID": "ocd-person/e9c5a2fc-3354-49e1-ac29-783502155fe7",
              "geometry": null
            },
            "contactInfo": {
              "email": "blake.tillery@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=4908&Session=27",
                "https://www.legis.ga.gov/members/senate/4908?session=1029",
                "https://www.legis.ga.gov/members/senate/4908?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4908"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-19-upper-ocd-person-e9c5a2fc-3354-49e1-ac29-783502155fe7": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/44a51125-9e82-41f6-8d4f-a646895b5994",
            "firstName": "Lawrence Cohen",
            "lastName": "Walker",
            "fullName": "Larry Walker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/walker--iii-larry-4878.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-GA-20-upper-ocd-person-44a51125-9e82-41f6-8d4f-a646895b5994",
              "builtID": "ga-upper-20",
              "externalID": "ocd-person/44a51125-9e82-41f6-8d4f-a646895b5994",
              "geometry": null
            },
            "contactInfo": {
              "email": "larry.walker@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=4878&Session=27",
                "https://www.legis.ga.gov/members/senate/4878?session=1029",
                "https://www.legis.ga.gov/members/senate/4878?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4878"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-20-upper-ocd-person-44a51125-9e82-41f6-8d4f-a646895b5994": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/8d6562cb-cdd0-405f-a165-8463cde26594",
            "firstName": "Brandon",
            "lastName": "Beach",
            "fullName": "Brandon Beach",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/beach-brandon-840.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-GA-21-upper-ocd-person-8d6562cb-cdd0-405f-a165-8463cde26594",
              "builtID": "ga-upper-21",
              "externalID": "ocd-person/8d6562cb-cdd0-405f-a165-8463cde26594",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandon.beach@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3100 Brierfield Road, Alpharetta, GA 30004",
              "phone1": null,
              "phone2": "678-640-1811",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=840&Session=27",
                "https://www.legis.ga.gov/members/senate/840?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/840?session=1029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-21-upper-ocd-person-8d6562cb-cdd0-405f-a165-8463cde26594": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/d7bfd125-d343-4acd-a4dc-480daf241af3",
            "firstName": "Harold V.",
            "lastName": "Jones",
            "fullName": "Harold Jones",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/jones-ii-harold-851.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-GA-22-upper-ocd-person-d7bfd125-d343-4acd-a4dc-480daf241af3",
              "builtID": "ga-upper-22",
              "externalID": "ocd-person/d7bfd125-d343-4acd-a4dc-480daf241af3",
              "geometry": null
            },
            "contactInfo": {
              "email": "harold.jones@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=851&Session=27",
                "https://www.legis.ga.gov/members/senate/851?session=1029",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/851?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-22-upper-ocd-person-d7bfd125-d343-4acd-a4dc-480daf241af3": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/918888ac-edc7-4178-9133-d058f0f21010",
            "firstName": "Othell Maxie",
            "lastName": "Burns",
            "fullName": "Max Burns",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-GA-23-upper-ocd-person-918888ac-edc7-4178-9133-d058f0f21010",
              "builtID": "ga-upper-23",
              "externalID": "ocd-person/918888ac-edc7-4178-9133-d058f0f21010",
              "geometry": null
            },
            "contactInfo": {
              "email": "max.burns@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/senate/4979?session=1029",
                "https://www.legis.ga.gov/members/senate/4979?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4979"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-23-upper-ocd-person-918888ac-edc7-4178-9133-d058f0f21010": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/e11cecf4-f2e5-49d9-ba9e-47681f779438",
            "firstName": "Lee I.",
            "lastName": "Anderson",
            "fullName": "Lee Anderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/anderson-lee-723.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-GA-24-upper-ocd-person-e11cecf4-f2e5-49d9-ba9e-47681f779438",
              "builtID": "ga-upper-24",
              "externalID": "ocd-person/e11cecf4-f2e5-49d9-ba9e-47681f779438",
              "geometry": null
            },
            "contactInfo": {
              "email": "lee.anderson@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "160 Louisville Road, Grovetown, GA 30813",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=723&Session=27",
                "https://www.legis.ga.gov/members/senate/723?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/723?session=1029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-24-upper-ocd-person-e11cecf4-f2e5-49d9-ba9e-47681f779438": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/c7860bae-9d9c-449c-9903-20230eec50ac",
            "firstName": "Ricky",
            "lastName": "Williams",
            "fullName": "Rick Williams",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/williams-rick-4905.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-GA-25-upper-ocd-person-c7860bae-9d9c-449c-9903-20230eec50ac",
              "builtID": "ga-upper-25",
              "externalID": "ocd-person/c7860bae-9d9c-449c-9903-20230eec50ac",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.williams@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4905&Session=27",
                "https://www.legis.ga.gov/members/house/4905?session=1029",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4905?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-25-upper-ocd-person-c7860bae-9d9c-449c-9903-20230eec50ac": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/4b05e770-4423-4b64-8ae1-9600e8c73244",
            "firstName": "David Eugene",
            "lastName": "Lucas",
            "fullName": "David Lucas",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/lucas-david-157.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-GA-26-upper-ocd-person-4b05e770-4423-4b64-8ae1-9600e8c73244",
              "builtID": "ga-upper-26",
              "externalID": "ocd-person/4b05e770-4423-4b64-8ae1-9600e8c73244",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.lucas@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=157&Session=27",
                "https://www.legis.ga.gov/members/senate/157?session=1029",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/157?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-26-upper-ocd-person-4b05e770-4423-4b64-8ae1-9600e8c73244": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/1f7d013a-8861-4e08-b6bf-7c8d36f8107c",
            "firstName": "Gregory Robert",
            "lastName": "Dolezal",
            "fullName": "Greg Dolezal",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/dolezal-greg-4925.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-GA-27-upper-ocd-person-1f7d013a-8861-4e08-b6bf-7c8d36f8107c",
              "builtID": "ga-upper-27",
              "externalID": "ocd-person/1f7d013a-8861-4e08-b6bf-7c8d36f8107c",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.dolezal@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=4925&Session=27",
                "https://www.legis.ga.gov/members/senate/4925?session=1029",
                "https://www.legis.ga.gov/members/senate/4925?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4925"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-27-upper-ocd-person-1f7d013a-8861-4e08-b6bf-7c8d36f8107c": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/81b84318-8c9f-467f-a7c1-776d9c415f51",
            "firstName": "Matthew Freeman",
            "lastName": "Brass",
            "fullName": "Matt Brass",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/brass-matt-4907.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-GA-28-upper-ocd-person-81b84318-8c9f-467f-a7c1-776d9c415f51",
              "builtID": "ga-upper-28",
              "externalID": "ocd-person/81b84318-8c9f-467f-a7c1-776d9c415f51",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.brass@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=4907&Session=27",
                "https://www.legis.ga.gov/members/senate/4907?session=1029",
                "https://www.legis.ga.gov/members/senate/4907?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4907"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-28-upper-ocd-person-81b84318-8c9f-467f-a7c1-776d9c415f51": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/6da7fe11-4436-4854-8e1b-4c45dc4cdaac",
            "firstName": "Wiley Randall",
            "lastName": "Robertson",
            "fullName": "Randy Robertson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/robertson-randy-4926.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-GA-29-upper-ocd-person-6da7fe11-4436-4854-8e1b-4c45dc4cdaac",
              "builtID": "ga-upper-29",
              "externalID": "ocd-person/6da7fe11-4436-4854-8e1b-4c45dc4cdaac",
              "geometry": null
            },
            "contactInfo": {
              "email": "randy.robertson@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=4926&Session=27",
                "https://www.legis.ga.gov/members/senate/4926?session=1029",
                "https://www.legis.ga.gov/members/senate/4926?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4926"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-29-upper-ocd-person-6da7fe11-4436-4854-8e1b-4c45dc4cdaac": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/93f45299-bfa2-4458-844a-5c6b9bdd8c1d",
            "firstName": "Timothy",
            "lastName": "Bearden",
            "fullName": "Tim Bearden",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Timothy_Bearden.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-GA-30-upper-ocd-person-93f45299-bfa2-4458-844a-5c6b9bdd8c1d",
              "builtID": "ga-upper-30",
              "externalID": "ocd-person/93f45299-bfa2-4458-844a-5c6b9bdd8c1d",
              "geometry": null
            },
            "contactInfo": {
              "email": "timothy.bearden@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/senate/62?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-30-upper-ocd-person-93f45299-bfa2-4458-844a-5c6b9bdd8c1d": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/cb7d8c1d-3c26-4f35-8d37-d1cf8a7ce207",
            "firstName": "Jason Raul",
            "lastName": "Anavitarte",
            "fullName": "Jason Anavitarte",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/anavitarte-jason-4980.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-GA-31-upper-ocd-person-cb7d8c1d-3c26-4f35-8d37-d1cf8a7ce207",
              "builtID": "ga-upper-31",
              "externalID": "ocd-person/cb7d8c1d-3c26-4f35-8d37-d1cf8a7ce207",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.anavitarte@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/senate/4980?session=1029",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4980?session=1032",
                "https://www.legis.ga.gov/members/senate/4980?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-31-upper-ocd-person-cb7d8c1d-3c26-4f35-8d37-d1cf8a7ce207": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/fc31ab02-026c-4173-86db-570c0b297c64",
            "firstName": "Kay",
            "lastName": "Kirkpatrick Haltom",
            "fullName": "Kay Kirkpatrick",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/kirkpatrick-kay-4910.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-GA-32-upper-ocd-person-fc31ab02-026c-4173-86db-570c0b297c64",
              "builtID": "ga-upper-32",
              "externalID": "ocd-person/fc31ab02-026c-4173-86db-570c0b297c64",
              "geometry": null
            },
            "contactInfo": {
              "email": "kay.kirkpatrick@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=4910&Session=27",
                "https://www.legis.ga.gov/members/senate/4910?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4910?session=1029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-32-upper-ocd-person-fc31ab02-026c-4173-86db-570c0b297c64": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/efb9168c-b45a-4f43-bba5-d6f05e7d9cbe",
            "firstName": "Michael A.",
            "lastName": "Rhett",
            "fullName": "Doc Rhett",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/rhett-michael--doc--855.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-GA-33-upper-ocd-person-efb9168c-b45a-4f43-bba5-d6f05e7d9cbe",
              "builtID": "ga-upper-33",
              "externalID": "ocd-person/efb9168c-b45a-4f43-bba5-d6f05e7d9cbe",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.rhett@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=855&Session=27",
                "https://www.legis.ga.gov/members/senate/855?session=1029",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/855?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-33-upper-ocd-person-efb9168c-b45a-4f43-bba5-d6f05e7d9cbe": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/110cea65-4417-4b3a-aa39-3e7e6122073d",
            "firstName": "Valencia M.",
            "lastName": "Seay",
            "fullName": "Valencia Seay",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/seay-valencia-41.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-GA-34-upper-ocd-person-110cea65-4417-4b3a-aa39-3e7e6122073d",
              "builtID": "ga-upper-34",
              "externalID": "ocd-person/110cea65-4417-4b3a-aa39-3e7e6122073d",
              "geometry": null
            },
            "contactInfo": {
              "email": "valencia.seay@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=41&Session=27",
                "https://www.legis.ga.gov/members/senate/41?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/41?session=1029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-34-upper-ocd-person-110cea65-4417-4b3a-aa39-3e7e6122073d": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/7f88fadb-2f51-48c7-8ae5-e20cd2f33e4a",
            "firstName": "Donzella Johnson",
            "lastName": "James",
            "fullName": "Donzella James",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/james-donzella-372.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-GA-35-upper-ocd-person-7f88fadb-2f51-48c7-8ae5-e20cd2f33e4a",
              "builtID": "ga-upper-35",
              "externalID": "ocd-person/7f88fadb-2f51-48c7-8ae5-e20cd2f33e4a",
              "geometry": null
            },
            "contactInfo": {
              "email": "donzella.james@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=372&Session=27",
                "https://www.legis.ga.gov/members/senate/372?session=1029",
                "https://www.legis.ga.gov/members/senate/372?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/372"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-35-upper-ocd-person-7f88fadb-2f51-48c7-8ae5-e20cd2f33e4a": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/bf2c1ec5-d995-4605-85ac-139adb037d99",
            "firstName": "Nancy Grogan",
            "lastName": "Orrock",
            "fullName": "Nan Orrock",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/orrock-nan-33.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-GA-36-upper-ocd-person-bf2c1ec5-d995-4605-85ac-139adb037d99",
              "builtID": "ga-upper-36",
              "externalID": "ocd-person/bf2c1ec5-d995-4605-85ac-139adb037d99",
              "geometry": null
            },
            "contactInfo": {
              "email": "nan.orrock@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=33&Session=27",
                "https://www.legis.ga.gov/members/senate/33?session=1029",
                "https://www.legis.ga.gov/members/senate/33?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/33"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-36-upper-ocd-person-bf2c1ec5-d995-4605-85ac-139adb037d99": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/777f58bb-9d6b-4755-ab7b-b94d11629cb5",
            "firstName": "L. Edwin",
            "lastName": "Setzler",
            "fullName": "Ed Setzler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/setzler-ed-203.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-GA-37-upper-ocd-person-777f58bb-9d6b-4755-ab7b-b94d11629cb5",
              "builtID": "ga-upper-37",
              "externalID": "ocd-person/777f58bb-9d6b-4755-ab7b-b94d11629cb5",
              "geometry": null
            },
            "contactInfo": {
              "email": "ed.setzler@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=203&Session=27",
                "https://www.legis.ga.gov/members/house/203?session=1029",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/203?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-37-upper-ocd-person-777f58bb-9d6b-4755-ab7b-b94d11629cb5": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/ab3f6dfa-f18d-4ac8-8dc5-a9ab25a1bfdf",
            "firstName": "Horacena E.",
            "lastName": "Tate",
            "fullName": "Horacena Tate",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/tate-horacena-47.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-GA-38-upper-ocd-person-ab3f6dfa-f18d-4ac8-8dc5-a9ab25a1bfdf",
              "builtID": "ga-upper-38",
              "externalID": "ocd-person/ab3f6dfa-f18d-4ac8-8dc5-a9ab25a1bfdf",
              "geometry": null
            },
            "contactInfo": {
              "email": "horacena.tate@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=47&Session=27",
                "https://www.legis.ga.gov/members/senate/47?session=1029",
                "https://www.legis.ga.gov/members/senate/47?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/47"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-38-upper-ocd-person-ab3f6dfa-f18d-4ac8-8dc5-a9ab25a1bfdf": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/2abbbe23-8cb6-4f33-afaf-38ce88a4e10c",
            "firstName": "Sonya McLaughlin",
            "lastName": "Halpern",
            "fullName": "Sonya Halpern",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/halpern-sonya-4985.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-GA-39-upper-ocd-person-2abbbe23-8cb6-4f33-afaf-38ce88a4e10c",
              "builtID": "ga-upper-39",
              "externalID": "ocd-person/2abbbe23-8cb6-4f33-afaf-38ce88a4e10c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sonya.halpern@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/senate/4985?session=1029",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4985?session=1032",
                "https://www.legis.ga.gov/members/senate/4985?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-39-upper-ocd-person-2abbbe23-8cb6-4f33-afaf-38ce88a4e10c": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/218a4fc4-a568-4b78-b350-0d60c6b12d4c",
            "firstName": "Sally Roettger",
            "lastName": "Harrell",
            "fullName": "Sally Harrell",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/harrell-sally-311.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-GA-40-upper-ocd-person-218a4fc4-a568-4b78-b350-0d60c6b12d4c",
              "builtID": "ga-upper-40",
              "externalID": "ocd-person/218a4fc4-a568-4b78-b350-0d60c6b12d4c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sally.harrell@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=311&Session=27",
                "https://www.legis.ga.gov/members/senate/311?session=1029",
                "https://www.legis.ga.gov/members/senate/311?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/311"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-40-upper-ocd-person-218a4fc4-a568-4b78-b350-0d60c6b12d4c": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/2f8621bc-0548-457c-8b0d-9148e14b77fe",
            "firstName": "Kimberly Sue",
            "lastName": "Jackson",
            "fullName": "Kim Jackson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/jackson-kim-4981.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-GA-41-upper-ocd-person-2f8621bc-0548-457c-8b0d-9148e14b77fe",
              "builtID": "ga-upper-41",
              "externalID": "ocd-person/2f8621bc-0548-457c-8b0d-9148e14b77fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "kim.jackson@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/senate/4981?session=1029",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4981?session=1032",
                "https://www.legis.ga.gov/members/senate/4981?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-41-upper-ocd-person-2f8621bc-0548-457c-8b0d-9148e14b77fe": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/3f15ad5e-877d-44aa-b5b3-b24587c42685",
            "firstName": "Elena",
            "lastName": "Parent",
            "fullName": "Elena Parent",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/parent-elena-768.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-GA-42-upper-ocd-person-3f15ad5e-877d-44aa-b5b3-b24587c42685",
              "builtID": "ga-upper-42",
              "externalID": "ocd-person/3f15ad5e-877d-44aa-b5b3-b24587c42685",
              "geometry": null
            },
            "contactInfo": {
              "email": "elena.parent@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=768&Session=27",
                "https://www.legis.ga.gov/members/senate/768?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/768?session=1029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-42-upper-ocd-person-3f15ad5e-877d-44aa-b5b3-b24587c42685": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/d9f1ea57-7b90-484a-9664-927a36c87e0c",
            "firstName": "Tonya P.",
            "lastName": "Anderson",
            "fullName": "Tonya Anderson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/anderson-tonya-807.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "43",
              "chamber": "upper",
              "hashID": "SLDU-GA-43-upper-ocd-person-d9f1ea57-7b90-484a-9664-927a36c87e0c",
              "builtID": "ga-upper-43",
              "externalID": "ocd-person/d9f1ea57-7b90-484a-9664-927a36c87e0c",
              "geometry": null
            },
            "contactInfo": {
              "email": "tonya.anderson@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1026, Lithonia, GA 30058",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=807&Session=27",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/807?session=1029",
                "https://www.legis.ga.gov/members/senate/807?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-43-upper-ocd-person-d9f1ea57-7b90-484a-9664-927a36c87e0c": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/df023a79-0194-4d1a-8755-5dee4053e1cc",
            "firstName": "Gail",
            "lastName": "Davenport",
            "fullName": "Gail Davenport",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/davenport-gail-10.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "44",
              "chamber": "upper",
              "hashID": "SLDU-GA-44-upper-ocd-person-df023a79-0194-4d1a-8755-5dee4053e1cc",
              "builtID": "ga-upper-44",
              "externalID": "ocd-person/df023a79-0194-4d1a-8755-5dee4053e1cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "gail.davenport@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=10&Session=27",
                "https://www.legis.ga.gov/members/senate/10?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/10?session=1029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-44-upper-ocd-person-df023a79-0194-4d1a-8755-5dee4053e1cc": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/17a7e011-73f2-4ac5-8470-3436b47a6744",
            "firstName": "Clinton Randall",
            "lastName": "Dixon",
            "fullName": "Clint Dixon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/dixon-clint-4982.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "45",
              "chamber": "upper",
              "hashID": "SLDU-GA-45-upper-ocd-person-17a7e011-73f2-4ac5-8470-3436b47a6744",
              "builtID": "ga-upper-45",
              "externalID": "ocd-person/17a7e011-73f2-4ac5-8470-3436b47a6744",
              "geometry": null
            },
            "contactInfo": {
              "email": "clint.dixon@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/senate/4982?session=1029",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4982?session=1032",
                "https://www.legis.ga.gov/members/senate/4982?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-45-upper-ocd-person-17a7e011-73f2-4ac5-8470-3436b47a6744": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/4dc87191-fa52-4c2f-bc9c-8823a8b4c882",
            "firstName": "William Stone",
            "lastName": "Cowsert",
            "fullName": "Bill Cowsert",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/cowsert-bill-9.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "46",
              "chamber": "upper",
              "hashID": "SLDU-GA-46-upper-ocd-person-4dc87191-fa52-4c2f-bc9c-8823a8b4c882",
              "builtID": "ga-upper-46",
              "externalID": "ocd-person/4dc87191-fa52-4c2f-bc9c-8823a8b4c882",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.cowsert@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=9&Session=27",
                "https://www.legis.ga.gov/members/senate/9?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/9?session=1029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-46-upper-ocd-person-4dc87191-fa52-4c2f-bc9c-8823a8b4c882": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/9e417b67-da09-4516-b858-6b2458f53633",
            "firstName": "Franklin Joseph",
            "lastName": "Ginn",
            "fullName": "Frank Ginn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/ginn-frank-751.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "47",
              "chamber": "upper",
              "hashID": "SLDU-GA-47-upper-ocd-person-9e417b67-da09-4516-b858-6b2458f53633",
              "builtID": "ga-upper-47",
              "externalID": "ocd-person/9e417b67-da09-4516-b858-6b2458f53633",
              "geometry": null
            },
            "contactInfo": {
              "email": "frank.ginn@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=751&Session=27",
                "https://www.legis.ga.gov/members/senate/751?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/751?session=1029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-47-upper-ocd-person-9e417b67-da09-4516-b858-6b2458f53633": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/314d7845-ba55-4bd4-809a-c7c7aea26690",
            "firstName": "Shawn",
            "lastName": "Still",
            "fullName": "Shawn Still",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/still-shawn-5016.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "48",
              "chamber": "upper",
              "hashID": "SLDU-GA-48-upper-ocd-person-314d7845-ba55-4bd4-809a-c7c7aea26690",
              "builtID": "ga-upper-48",
              "externalID": "ocd-person/314d7845-ba55-4bd4-809a-c7c7aea26690",
              "geometry": null
            },
            "contactInfo": {
              "email": "shawn.still@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/5016?session=1032",
                "https://www.legis.ga.gov/members/senate/5016?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-48-upper-ocd-person-314d7845-ba55-4bd4-809a-c7c7aea26690": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/307b6d01-e9df-4970-92bf-4da4ead189b5",
            "firstName": "Shelly Cantrell",
            "lastName": "Echols",
            "fullName": "Shelly Echols",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/echols-shelly-5017.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "49",
              "chamber": "upper",
              "hashID": "SLDU-GA-49-upper-ocd-person-307b6d01-e9df-4970-92bf-4da4ead189b5",
              "builtID": "ga-upper-49",
              "externalID": "ocd-person/307b6d01-e9df-4970-92bf-4da4ead189b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "shelly.echols@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/5017?session=1032",
                "https://www.legis.ga.gov/members/senate/5017?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-49-upper-ocd-person-307b6d01-e9df-4970-92bf-4da4ead189b5": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/60bb96c4-f20c-4918-b88b-d7857341e5f4",
            "firstName": "Thomas",
            "lastName": "Hatchett",
            "fullName": "Bo Hatchett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/hatchett-bo-4984.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "50",
              "chamber": "upper",
              "hashID": "SLDU-GA-50-upper-ocd-person-60bb96c4-f20c-4918-b88b-d7857341e5f4",
              "builtID": "ga-upper-50",
              "externalID": "ocd-person/60bb96c4-f20c-4918-b88b-d7857341e5f4",
              "geometry": null
            },
            "contactInfo": {
              "email": "bo.hatchett@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.ga.gov/members/senate/4984?session=1029",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4984?session=1032",
                "https://www.legis.ga.gov/members/senate/4984?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-50-upper-ocd-person-60bb96c4-f20c-4918-b88b-d7857341e5f4": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/e2c141e9-9e04-4e2b-8e5b-57db594c7abc",
            "firstName": "Stephen Wade",
            "lastName": "Gooch",
            "fullName": "Steve Gooch",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/gooch-steve-752.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "51",
              "chamber": "upper",
              "hashID": "SLDU-GA-51-upper-ocd-person-e2c141e9-9e04-4e2b-8e5b-57db594c7abc",
              "builtID": "ga-upper-51",
              "externalID": "ocd-person/e2c141e9-9e04-4e2b-8e5b-57db594c7abc",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.gooch@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=752&Session=27",
                "https://www.legis.ga.gov/members/senate/752?session=1029",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/752?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-51-upper-ocd-person-e2c141e9-9e04-4e2b-8e5b-57db594c7abc": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/b11d0191-cd84-4a93-add6-ee65494caff2",
            "firstName": "Chuck",
            "lastName": "Hufstetler",
            "fullName": "Chuck Hufstetler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/hufstetler-chuck-838.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "52",
              "chamber": "upper",
              "hashID": "SLDU-GA-52-upper-ocd-person-b11d0191-cd84-4a93-add6-ee65494caff2",
              "builtID": "ga-upper-52",
              "externalID": "ocd-person/b11d0191-cd84-4a93-add6-ee65494caff2",
              "geometry": null
            },
            "contactInfo": {
              "email": "chuck.hufstetler@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=838&Session=27",
                "https://www.legis.ga.gov/members/senate/838?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/838?session=1029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-52-upper-ocd-person-b11d0191-cd84-4a93-add6-ee65494caff2": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/6ebda9ee-2203-4b66-9bb3-64d2307157a0",
            "firstName": "Colton Chase",
            "lastName": "Moore",
            "fullName": "Colton Moore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/moore-colton-4933.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "53",
              "chamber": "upper",
              "hashID": "SLDU-GA-53-upper-ocd-person-6ebda9ee-2203-4b66-9bb3-64d2307157a0",
              "builtID": "ga-upper-53",
              "externalID": "ocd-person/6ebda9ee-2203-4b66-9bb3-64d2307157a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "colton.moore@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.ga.gov/Representatives/en-US/member.aspx?Member=4933&Session=27",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4933?session=1032",
                "https://www.legis.ga.gov/members/senate/4933?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-53-upper-ocd-person-6ebda9ee-2203-4b66-9bb3-64d2307157a0": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/1ac93f89-f367-4de3-82ba-79fa78d4d134",
            "firstName": "Charles",
            "lastName": "Payne",
            "fullName": "Chuck Payne",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/payne-chuck--4909.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "54",
              "chamber": "upper",
              "hashID": "SLDU-GA-54-upper-ocd-person-1ac93f89-f367-4de3-82ba-79fa78d4d134",
              "builtID": "ga-upper-54",
              "externalID": "ocd-person/1ac93f89-f367-4de3-82ba-79fa78d4d134",
              "geometry": null
            },
            "contactInfo": {
              "email": "chuck.payne@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=4909&Session=27",
                "https://www.legis.ga.gov/members/senate/4909?session=1029",
                "https://www.legis.ga.gov/members/senate/4909?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/4909"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-54-upper-ocd-person-1ac93f89-f367-4de3-82ba-79fa78d4d134": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/c02e2258-027d-4ef8-bed9-70619b4bc4da",
            "firstName": "Gloria Singleton",
            "lastName": "Butler",
            "fullName": "Gloria Butler",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/butler-gloria-5.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "55",
              "chamber": "upper",
              "hashID": "SLDU-GA-55-upper-ocd-person-c02e2258-027d-4ef8-bed9-70619b4bc4da",
              "builtID": "ga-upper-55",
              "externalID": "ocd-person/c02e2258-027d-4ef8-bed9-70619b4bc4da",
              "geometry": null
            },
            "contactInfo": {
              "email": "gloria.butler@senate.ga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=5&Session=27",
                "https://www.legis.ga.gov/members/senate/5?session=1029",
                "https://www.legis.ga.gov/members/senate/5?session=1031",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/5"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-55-upper-ocd-person-c02e2258-027d-4ef8-bed9-70619b4bc4da": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/ac1cac30-3fec-4c56-b172-b483db465a6b",
            "firstName": "John",
            "lastName": "Albers",
            "fullName": "John Albers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.ga.gov/api/images/default-source/portraits/albers-john-754.jpg",
            "title": "GA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "GA",
              "stateFull": "Georgia",
              "district": "56",
              "chamber": "upper",
              "hashID": "SLDU-GA-56-upper-ocd-person-ac1cac30-3fec-4c56-b172-b483db465a6b",
              "builtID": "ga-upper-56",
              "externalID": "ocd-person/ac1cac30-3fec-4c56-b172-b483db465a6b",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@senatoralbers.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.ga.gov/SENATORS/en-US/member.aspx?Member=754&Session=27",
                "https://www.legis.ga.gov/members/senate",
                "https://www.legis.ga.gov/members/senate/754?session=1029",
                "https://www.legis.ga.gov/members/senate/754?session=1031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-GA-56-upper-ocd-person-ac1cac30-3fec-4c56-b172-b483db465a6b": 0
        }
      }
    }
  },
  "HI": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "HI",
          "stateFull": "Hawaii",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "HI",
            "stateFull": "Hawaii",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-HI---",
            "builtID": "hi--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-HI---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "H001042",
          "in_office": true,
          "firstName": "Mazie",
          "lastName": "Hirono",
          "middleName": "K.",
          "fullName": "Mazie K. Hirono",
          "gender": "F",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/H001042.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "HI",
            "stateFull": "Hawaii",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-HI---H001042",
            "builtID": "hi--",
            "externalID": "H001042",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.hirono.senate.gov/contact",
            "address1": "109 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-6361",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "MazieHirono",
            "youtube": "CongresswomanHirono",
            "instagram": null,
            "facebook": "senatorhirono",
            "urls": ["https://www.hirono.senate.gov"],
            "rss_url": "https://www.hirono.senate.gov/rss/feeds/"
          },
          "title": "HI Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/H001042.json",
          "govtrack_id": "412200",
          "cspan_id": "91216",
          "votesmart_id": "1677",
          "icpsr_id": "20713",
          "crp_id": "N00028139",
          "google_entity_id": "/m/0357cd",
          "state_rank": "junior",
          "lis_id": "S361",
          "suffix": null,
          "date_of_birth": "1947-11-03",
          "leadership_role": null,
          "fec_candidate_id": "H6HI02251",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "11",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 1,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 0.18,
          "votes_with_party_pct": 97.46,
          "votes_against_party_pct": 2.54,
          "ocd_id": "ocd-division/country:us/state:hi"
        },
        {
          "API_ID": "S001194",
          "in_office": true,
          "firstName": "Brian",
          "lastName": "Schatz",
          "middleName": null,
          "fullName": "Brian Schatz",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/S001194.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "HI",
            "stateFull": "Hawaii",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-HI---S001194",
            "builtID": "hi--",
            "externalID": "S001194",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenBrianSchatz",
            "youtube": "senbrianschatz",
            "instagram": null,
            "facebook": "SenBrianSchatz",
            "urls": ["https://www.schatz.senate.gov"],
            "rss_url": null
          },
          "title": "HI Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/S001194.json",
          "govtrack_id": "412507",
          "cspan_id": "87784",
          "votesmart_id": "17852",
          "icpsr_id": "41112",
          "crp_id": "N00028138",
          "google_entity_id": "/m/0dd109",
          "state_rank": "senior",
          "lis_id": "S353",
          "suffix": null,
          "date_of_birth": "1972-10-20",
          "leadership_role": null,
          "fec_candidate_id": "S4HI00136",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "11",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 11,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 1.99,
          "votes_with_party_pct": 99.63,
          "votes_against_party_pct": 0.37,
          "ocd_id": "ocd-division/country:us/state:hi"
        }
      ],
      "hash": { "SENATE-HI---H001042": 0, "SENATE-HI---S001194": 1 }
    },
    "HOUSE": {
      "01": {
        "members": [
          {
            "API_ID": "C001055",
            "in_office": true,
            "firstName": "Ed",
            "lastName": "Case",
            "middleName": null,
            "fullName": "Ed Case",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001055.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-HI-01--C001055",
              "builtID": "hi--01",
              "externalID": "C001055",
              "geometry": null,
              "geoid": "1501"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2210 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2726",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepEdCase",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://case.house.gov"],
              "rss_url": null
            },
            "title": "HI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001055.json",
            "govtrack_id": "400069",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": "20322",
            "crp_id": "N00025882",
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1952-09-27",
            "leadership_role": null,
            "fec_candidate_id": "H2HI02128",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 12,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.14,
            "votes_with_party_pct": 94.29,
            "votes_against_party_pct": 3.77,
            "ocd_id": "ocd-division/country:us/state:hi/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-HI-01--C001055": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "T000487",
            "in_office": true,
            "firstName": "Jill",
            "lastName": "Tokuda",
            "middleName": null,
            "fullName": "Jill N. Tokuda",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/T000487.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-HI-02--T000487",
              "builtID": "hi--02",
              "externalID": "T000487",
              "geometry": null,
              "geoid": "1502"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1005 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-4906",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJillTokuda",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://tokuda.house.gov/"],
              "rss_url": null
            },
            "title": "HI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000487.json",
            "govtrack_id": "456897",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-03-28",
            "leadership_role": null,
            "fec_candidate_id": "H2HI02581",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 10,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.95,
            "votes_with_party_pct": 95.27,
            "votes_against_party_pct": 2.8,
            "ocd_id": "ocd-division/country:us/state:hi/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-HI-02--T000487": 0 }
      }
    },
    "SLDL": {
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/82186787-c5bb-48bd-8920-3b4761a9500b",
            "firstName": "Richard Hiroyuki Keala",
            "lastName": "Onishi",
            "fullName": "Richard Onishi",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Onishi.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-HI-2-lower-ocd-person-82186787-c5bb-48bd-8920-3b4761a9500b",
              "builtID": "hi-lower-2",
              "externalID": "ocd-person/82186787-c5bb-48bd-8920-3b4761a9500b",
              "geometry": null
            },
            "contactInfo": {
              "email": "reponishi@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=onishi&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=onishi&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=onishi&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=106&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=106&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-2-lower-ocd-person-82186787-c5bb-48bd-8920-3b4761a9500b": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/6f62978c-5c1e-4a93-b182-caa7b97d45e0",
            "firstName": "Chris Toshiro",
            "lastName": "Todd",
            "fullName": "Chris Todd",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Todd.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-HI-3-lower-ocd-person-6f62978c-5c1e-4a93-b182-caa7b97d45e0",
              "builtID": "hi-lower-3",
              "externalID": "ocd-person/6f62978c-5c1e-4a93-b182-caa7b97d45e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "reptodd@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "staterepchristodd",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=todd&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=todd&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=todd&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=137&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=137&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-3-lower-ocd-person-6f62978c-5c1e-4a93-b182-caa7b97d45e0": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/d56e9f60-c26e-4437-90d7-4414957e737d",
            "firstName": "Greggor",
            "lastName": "Ilagan",
            "fullName": "Greggor Ilagan",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Ilagan.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-HI-4-lower-ocd-person-d56e9f60-c26e-4437-90d7-4414957e737d",
              "builtID": "hi-lower-4",
              "externalID": "ocd-person/d56e9f60-c26e-4437-90d7-4414957e737d",
              "geometry": null
            },
            "contactInfo": {
              "email": "repilagan@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "repilagan",
              "facebook": "repilagan",
              "urls": [
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=Ilagan&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=Ilagan&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=61&year=2023",
                "https://repilagan.com/",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=61&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-4-lower-ocd-person-d56e9f60-c26e-4437-90d7-4414957e737d": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/47a7e8c6-361d-4854-9253-e311b58d9e1c",
            "firstName": "Jeanne",
            "lastName": "Kapela",
            "fullName": "Jeanné Kapela",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Kapela.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-HI-5-lower-ocd-person-47a7e8c6-361d-4854-9253-e311b58d9e1c",
              "builtID": "hi-lower-5",
              "externalID": "ocd-person/47a7e8c6-361d-4854-9253-e311b58d9e1c",
              "geometry": null
            },
            "contactInfo": {
              "email": "repkapela@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=Kapela&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=Kapela&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=71&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=71&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-5-lower-ocd-person-47a7e8c6-361d-4854-9253-e311b58d9e1c": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/b6d9dda7-d2e1-443e-a19b-ee44ed891cad",
            "firstName": "Kirstin A.K.",
            "lastName": "Kahaloa",
            "fullName": "Kirstin Kahaloa",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Kahaloa.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-HI-6-lower-ocd-person-b6d9dda7-d2e1-443e-a19b-ee44ed891cad",
              "builtID": "hi-lower-6",
              "externalID": "ocd-person/b6d9dda7-d2e1-443e-a19b-ee44ed891cad",
              "geometry": null
            },
            "contactInfo": {
              "email": "repkahaloa@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=264&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=264&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-6-lower-ocd-person-b6d9dda7-d2e1-443e-a19b-ee44ed891cad": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/43795702-8cb7-40a5-945d-80fca537e085",
            "firstName": "Nicole E.",
            "lastName": "Lowen",
            "fullName": "Nicole Lowen",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Lowen.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-HI-7-lower-ocd-person-43795702-8cb7-40a5-945d-80fca537e085",
              "builtID": "hi-lower-7",
              "externalID": "ocd-person/43795702-8cb7-40a5-945d-80fca537e085",
              "geometry": null
            },
            "contactInfo": {
              "email": "replowen@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=lowen&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=lowen&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=lowen&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=83&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=83&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-7-lower-ocd-person-43795702-8cb7-40a5-945d-80fca537e085": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/fca91474-67f3-4b01-a985-6b0ddbfb2227",
            "firstName": "David A.",
            "lastName": "Tarnas",
            "fullName": "David Tarnas",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Tarnas.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-HI-8-lower-ocd-person-fca91474-67f3-4b01-a985-6b0ddbfb2227",
              "builtID": "hi-lower-8",
              "externalID": "ocd-person/fca91474-67f3-4b01-a985-6b0ddbfb2227",
              "geometry": null
            },
            "contactInfo": {
              "email": "reptarnas@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "reptarnas",
              "youtube": null,
              "instagram": "reptarnas",
              "facebook": "reptarnas",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=tarnas&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=tarnas&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=tarnas&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=135&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=135&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-8-lower-ocd-person-fca91474-67f3-4b01-a985-6b0ddbfb2227": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/5d9e6c9d-a94b-4ff6-9828-ed2ce8ceeab0",
            "firstName": "Justin H.",
            "lastName": "Woodson",
            "fullName": "Justin Woodson",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Woodson.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-HI-9-lower-ocd-person-5d9e6c9d-a94b-4ff6-9828-ed2ce8ceeab0",
              "builtID": "hi-lower-9",
              "externalID": "ocd-person/5d9e6c9d-a94b-4ff6-9828-ed2ce8ceeab0",
              "geometry": null
            },
            "contactInfo": {
              "email": "repwoodson@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=woodson&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=woodson&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=woodson&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=146&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=146&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-9-lower-ocd-person-5d9e6c9d-a94b-4ff6-9828-ed2ce8ceeab0": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/6fb71afb-6ce8-4f21-ad16-a7255ca2bfec",
            "firstName": "Tyson K.",
            "lastName": "Miyake",
            "fullName": "Tyson Miyake",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-HI-10-lower-ocd-person-6fb71afb-6ce8-4f21-ad16-a7255ca2bfec",
              "builtID": "hi-lower-10",
              "externalID": "ocd-person/6fb71afb-6ce8-4f21-ad16-a7255ca2bfec",
              "geometry": null
            },
            "contactInfo": {
              "email": "repmiyake@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=294"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-10-lower-ocd-person-6fb71afb-6ce8-4f21-ad16-a7255ca2bfec": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/3c16b940-790f-43f2-946d-998b6177dc5a",
            "firstName": "Terez",
            "lastName": "Amato",
            "fullName": "Terez Amato",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Amato.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-HI-11-lower-ocd-person-3c16b940-790f-43f2-946d-998b6177dc5a",
              "builtID": "hi-lower-11",
              "externalID": "ocd-person/3c16b940-790f-43f2-946d-998b6177dc5a",
              "geometry": null
            },
            "contactInfo": {
              "email": "repamato@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=250&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=250&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-11-lower-ocd-person-3c16b940-790f-43f2-946d-998b6177dc5a": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/51f31e1a-2fe4-4029-b49c-c3affa0b848d",
            "firstName": "Kyle T.",
            "lastName": "Yamashita",
            "fullName": "Kyle Yamashita",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Yamashita.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-HI-12-lower-ocd-person-51f31e1a-2fe4-4029-b49c-c3affa0b848d",
              "builtID": "hi-lower-12",
              "externalID": "ocd-person/51f31e1a-2fe4-4029-b49c-c3affa0b848d",
              "geometry": null
            },
            "contactInfo": {
              "email": "repyamashita@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "kyle.yamashita.77",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=yamashita&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=yamashita&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=yamashita&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=149&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=149&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-12-lower-ocd-person-51f31e1a-2fe4-4029-b49c-c3affa0b848d": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/c6f087db-713b-43c2-b557-7306f62ee15c",
            "firstName": "Mahina M.",
            "lastName": "Poepoe",
            "fullName": "Mahina Poepoe",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Poepoe.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-HI-13-lower-ocd-person-c6f087db-713b-43c2-b557-7306f62ee15c",
              "builtID": "hi-lower-13",
              "externalID": "ocd-person/c6f087db-713b-43c2-b557-7306f62ee15c",
              "geometry": null
            },
            "contactInfo": {
              "email": "reppoepoe@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "reppoepoe",
              "facebook": "reppoepoe",
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=284&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=284&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-13-lower-ocd-person-c6f087db-713b-43c2-b557-7306f62ee15c": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/f7bd1cdd-c131-47eb-ab96-1a7c0599bfa7",
            "firstName": "Elle",
            "lastName": "Cochran",
            "fullName": "Elle Cochran",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Cochran.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-HI-14-lower-ocd-person-f7bd1cdd-c131-47eb-ab96-1a7c0599bfa7",
              "builtID": "hi-lower-14",
              "externalID": "ocd-person/f7bd1cdd-c131-47eb-ab96-1a7c0599bfa7",
              "geometry": null
            },
            "contactInfo": {
              "email": "repcochran@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=253&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=253&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-14-lower-ocd-person-f7bd1cdd-c131-47eb-ab96-1a7c0599bfa7": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/aa2e4b09-2c1e-4fa2-b135-7d2427dca5b4",
            "firstName": "Nadine K.",
            "lastName": "Nakamura",
            "fullName": "Nadine Nakamura",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Nakamura.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-HI-15-lower-ocd-person-aa2e4b09-2c1e-4fa2-b135-7d2427dca5b4",
              "builtID": "hi-lower-15",
              "externalID": "ocd-person/aa2e4b09-2c1e-4fa2-b135-7d2427dca5b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "repnakamura@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "nakamuranadine",
              "facebook": "representativenadinenakamura",
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=100&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=100&year=2024",
                "https://www.repnakamura.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-15-lower-ocd-person-aa2e4b09-2c1e-4fa2-b135-7d2427dca5b4": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/128214ae-53bd-4dfe-88e6-ad3a70c6a83d",
            "firstName": "Luke A.",
            "lastName": "Evslin",
            "fullName": "Luke Evslin",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Evslin.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-HI-16-lower-ocd-person-128214ae-53bd-4dfe-88e6-ad3a70c6a83d",
              "builtID": "hi-lower-16",
              "externalID": "ocd-person/128214ae-53bd-4dfe-88e6-ad3a70c6a83d",
              "geometry": null
            },
            "contactInfo": {
              "email": "repevslin@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=291&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=291&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-16-lower-ocd-person-128214ae-53bd-4dfe-88e6-ad3a70c6a83d": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/587a16dd-bb53-4164-829b-5e1703e06201",
            "firstName": "Daynette",
            "lastName": "Morikawa",
            "fullName": "Dee Morikawa",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Morikawa.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-HI-17-lower-ocd-person-587a16dd-bb53-4164-829b-5e1703e06201",
              "builtID": "hi-lower-17",
              "externalID": "ocd-person/587a16dd-bb53-4164-829b-5e1703e06201",
              "geometry": null
            },
            "contactInfo": {
              "email": "repmorikawa@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=morikawa&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=morikawa&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=morikawa&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=96&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=96&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-17-lower-ocd-person-587a16dd-bb53-4164-829b-5e1703e06201": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/95146956-5701-4883-a355-ca234b817686",
            "firstName": "Gene R.",
            "lastName": "Ward",
            "fullName": "Gene Ward",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Ward.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-HI-18-lower-ocd-person-95146956-5701-4883-a355-ca234b817686",
              "builtID": "hi-lower-18",
              "externalID": "ocd-person/95146956-5701-4883-a355-ca234b817686",
              "geometry": null
            },
            "contactInfo": {
              "email": "repward@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "repward",
              "facebook": "representativegeneward",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=ward&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=ward&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=ward&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=142&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=142&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-18-lower-ocd-person-95146956-5701-4883-a355-ca234b817686": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/f2d1de1a-c206-46f7-8c5f-a6193229642f",
            "firstName": "Mark Jun",
            "lastName": "Hashem",
            "fullName": "Mark Hashem",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Hashem.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-HI-19-lower-ocd-person-f2d1de1a-c206-46f7-8c5f-a6193229642f",
              "builtID": "hi-lower-19",
              "externalID": "ocd-person/f2d1de1a-c206-46f7-8c5f-a6193229642f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rephashem@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "repmarkhashem",
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=hashem&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=hashem&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=hashem&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=55&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=55&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-19-lower-ocd-person-f2d1de1a-c206-46f7-8c5f-a6193229642f": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/05b3f451-01f6-4c0c-a01c-1bb379a80462",
            "firstName": "Bertrand",
            "lastName": "Kobayashi",
            "fullName": "Bert Kobayashi",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Kobayashi.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-HI-20-lower-ocd-person-05b3f451-01f6-4c0c-a01c-1bb379a80462",
              "builtID": "hi-lower-20",
              "externalID": "ocd-person/05b3f451-01f6-4c0c-a01c-1bb379a80462",
              "geometry": null
            },
            "contactInfo": {
              "email": "repkobayashi@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=kobayashi&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=kobayashi&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=kobayashi&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=77&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=77&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-20-lower-ocd-person-05b3f451-01f6-4c0c-a01c-1bb379a80462": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/451d1a20-20d4-4c75-95b9-ca4ba6dd3d1d",
            "firstName": "Jackson D.",
            "lastName": "Sayama",
            "fullName": "Jackson Sayama",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Sayama.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-HI-21-lower-ocd-person-451d1a20-20d4-4c75-95b9-ca4ba6dd3d1d",
              "builtID": "hi-lower-21",
              "externalID": "ocd-person/451d1a20-20d4-4c75-95b9-ca4ba6dd3d1d",
              "geometry": null
            },
            "contactInfo": {
              "email": "repsayama@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "repsayama",
              "facebook": "repsayama",
              "urls": [
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=Sayama&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=Sayama&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=120&year=2023",
                "https://www.repjacksonsayama.com/",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=120"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-21-lower-ocd-person-451d1a20-20d4-4c75-95b9-ca4ba6dd3d1d": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/442e3aac-61ca-4efe-bbb8-716654554597",
            "firstName": "Andrew Takuya",
            "lastName": "Garrett",
            "fullName": "Andrew Garrett",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Garrett.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-HI-22-lower-ocd-person-442e3aac-61ca-4efe-bbb8-716654554597",
              "builtID": "hi-lower-22",
              "externalID": "ocd-person/442e3aac-61ca-4efe-bbb8-716654554597",
              "geometry": null
            },
            "contactInfo": {
              "email": "repgarrett@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "repgarrett",
              "facebook": "profile.php?id=100085131802111",
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=256&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=256&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-22-lower-ocd-person-442e3aac-61ca-4efe-bbb8-716654554597": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/ede18c33-14a4-4481-bd24-78b8ddedcfa4",
            "firstName": "Scott Y.",
            "lastName": "Nishimoto",
            "fullName": "Scott Nishimoto",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Nishimoto.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-HI-23-lower-ocd-person-ede18c33-14a4-4481-bd24-78b8ddedcfa4",
              "builtID": "hi-lower-23",
              "externalID": "ocd-person/ede18c33-14a4-4481-bd24-78b8ddedcfa4",
              "geometry": null
            },
            "contactInfo": {
              "email": "repnishimoto@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=103&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=103&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-23-lower-ocd-person-ede18c33-14a4-4481-bd24-78b8ddedcfa4": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/0ce91900-de38-4ab2-a6fe-089b04b20b74",
            "firstName": "Adrian K.",
            "lastName": "Tam",
            "fullName": "Adrian Tam",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Tam.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-HI-24-lower-ocd-person-0ce91900-de38-4ab2-a6fe-089b04b20b74",
              "builtID": "hi-lower-24",
              "externalID": "ocd-person/0ce91900-de38-4ab2-a6fe-089b04b20b74",
              "geometry": null
            },
            "contactInfo": {
              "email": "reptam@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "repadriantam",
              "facebook": "repadriantam",
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=132&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=132"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-24-lower-ocd-person-0ce91900-de38-4ab2-a6fe-089b04b20b74": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/8a4faf80-4764-45dc-8a41-4d8a5391a792",
            "firstName": "Scott K.",
            "lastName": "Saiki",
            "fullName": "Scott Saiki",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Saiki.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-HI-25-lower-ocd-person-8a4faf80-4764-45dc-8a41-4d8a5391a792",
              "builtID": "hi-lower-25",
              "externalID": "ocd-person/8a4faf80-4764-45dc-8a41-4d8a5391a792",
              "geometry": null
            },
            "contactInfo": {
              "email": "repsaiki@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repscottsaiki?lang=en",
              "youtube": null,
              "instagram": "repscottsaiki",
              "facebook": "repscottsaiki",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=saiki&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=saiki&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=saiki&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=117&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=117&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-25-lower-ocd-person-8a4faf80-4764-45dc-8a41-4d8a5391a792": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/b3a0c91b-d6fd-4a54-9a34-2ba48cc53984",
            "firstName": "Della Au",
            "lastName": "Belatti",
            "fullName": "Della Belatti",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Belatti.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-HI-26-lower-ocd-person-b3a0c91b-d6fd-4a54-9a34-2ba48cc53984",
              "builtID": "hi-lower-26",
              "externalID": "ocd-person/b3a0c91b-d6fd-4a54-9a34-2ba48cc53984",
              "geometry": null
            },
            "contactInfo": {
              "email": "repbelatti@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "repbelatti",
              "facebook": "repbelatti",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=belatti&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=belatti&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=belatti&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=7&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=7&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-26-lower-ocd-person-b3a0c91b-d6fd-4a54-9a34-2ba48cc53984": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/928645f2-46ed-4153-ab60-5b7a8250dab3",
            "firstName": "Jenna",
            "lastName": "Takenouchi",
            "fullName": "Jenna Takenouchi",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Takenouchi.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-HI-27-lower-ocd-person-928645f2-46ed-4153-ab60-5b7a8250dab3",
              "builtID": "hi-lower-27",
              "externalID": "ocd-person/928645f2-46ed-4153-ab60-5b7a8250dab3",
              "geometry": null
            },
            "contactInfo": {
              "email": "reptakenouchi@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "reptakenouchi",
              "facebook": "reptakenouchi",
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=290&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=290&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-27-lower-ocd-person-928645f2-46ed-4153-ab60-5b7a8250dab3": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/0ceb0d53-faa5-4706-a1c5-8ebb45e3b133",
            "firstName": "Daniel",
            "lastName": "Holt",
            "fullName": "Daniel Holt",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Holt.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-HI-28-lower-ocd-person-0ceb0d53-faa5-4706-a1c5-8ebb45e3b133",
              "builtID": "hi-lower-28",
              "externalID": "ocd-person/0ceb0d53-faa5-4706-a1c5-8ebb45e3b133",
              "geometry": null
            },
            "contactInfo": {
              "email": "repholt@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "repdanielholt",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=holt&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=holt&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=holt&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=59&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=59&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-28-lower-ocd-person-0ceb0d53-faa5-4706-a1c5-8ebb45e3b133": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/cf7f012e-34ad-467b-8710-c85705acb67b",
            "firstName": "Joje May Besario",
            "lastName": "Mizuno",
            "fullName": "May Mizuno",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Mizuno.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-HI-29-lower-ocd-person-cf7f012e-34ad-467b-8710-c85705acb67b",
              "builtID": "hi-lower-29",
              "externalID": "ocd-person/cf7f012e-34ad-467b-8710-c85705acb67b",
              "geometry": null
            },
            "contactInfo": {
              "email": "repmmizuno@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=mizuno&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=mizuno&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=mizuno&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=95&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=295&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-29-lower-ocd-person-cf7f012e-34ad-467b-8710-c85705acb67b": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/43f4f67b-6130-4186-878d-6767b612c967",
            "firstName": "Ernesto Montemayor",
            "lastName": "Ganaden",
            "fullName": "Sonny Ganaden",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Ganaden.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-HI-30-lower-ocd-person-43f4f67b-6130-4186-878d-6767b612c967",
              "builtID": "hi-lower-30",
              "externalID": "ocd-person/43f4f67b-6130-4186-878d-6767b612c967",
              "geometry": null
            },
            "contactInfo": {
              "email": "repganaden@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "repganaden",
              "facebook": "repganaden",
              "urls": [
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=Ganaden&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=Ganaden&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=44&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=44&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-30-lower-ocd-person-43f4f67b-6130-4186-878d-6767b612c967": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/546ef7bf-9015-4a09-b8d3-f37fd87ed97f",
            "firstName": "Linda E.",
            "lastName": "Ichiyama",
            "fullName": "Linda Ichiyama",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Ichiyama.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-HI-31-lower-ocd-person-546ef7bf-9015-4a09-b8d3-f37fd87ed97f",
              "builtID": "hi-lower-31",
              "externalID": "ocd-person/546ef7bf-9015-4a09-b8d3-f37fd87ed97f",
              "geometry": null
            },
            "contactInfo": {
              "email": "repichiyama@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=60&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=60&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-31-lower-ocd-person-546ef7bf-9015-4a09-b8d3-f37fd87ed97f": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/9a2b7a6a-2689-42f8-ac5a-9e1a23e5df06",
            "firstName": "Micah Pookela Kim",
            "lastName": "Aiu",
            "fullName": "Micah Aiu",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Aiu.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-HI-32-lower-ocd-person-9a2b7a6a-2689-42f8-ac5a-9e1a23e5df06",
              "builtID": "hi-lower-32",
              "externalID": "ocd-person/9a2b7a6a-2689-42f8-ac5a-9e1a23e5df06",
              "geometry": null
            },
            "contactInfo": {
              "email": "repaiu@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "repmicahaiu",
              "facebook": "peoplestate-representative-micah-pk-aiu100089856578805",
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=248&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=248&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-32-lower-ocd-person-9a2b7a6a-2689-42f8-ac5a-9e1a23e5df06": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/cbf4fe33-42cf-41c2-b689-5e4c0ff150f6",
            "firstName": "Samuel Satoru",
            "lastName": "Kong",
            "fullName": "Sam Kong",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Kong.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-HI-33-lower-ocd-person-cbf4fe33-42cf-41c2-b689-5e4c0ff150f6",
              "builtID": "hi-lower-33",
              "externalID": "ocd-person/cbf4fe33-42cf-41c2-b689-5e4c0ff150f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "repkong@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=kong&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=kong&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=kong&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=78&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=78"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-33-lower-ocd-person-cbf4fe33-42cf-41c2-b689-5e4c0ff150f6": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/33584899-9773-4fa2-96aa-869a6da7cf19",
            "firstName": "Gregg",
            "lastName": "Takayama",
            "fullName": "Gregg Takayama",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Takayama.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-HI-34-lower-ocd-person-33584899-9773-4fa2-96aa-869a6da7cf19",
              "builtID": "hi-lower-34",
              "externalID": "ocd-person/33584899-9773-4fa2-96aa-869a6da7cf19",
              "geometry": null
            },
            "contactInfo": {
              "email": "reptakayama@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": "uc6c_hcnxxhg2fxqmz2gjx8w",
              "instagram": "repgreggtakayama",
              "facebook": "reptakayama",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=takayama&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=takayama&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=takayama&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=130&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=130&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-34-lower-ocd-person-33584899-9773-4fa2-96aa-869a6da7cf19": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/00c3bae0-1087-4a2f-bee8-af0fbd2c6cf6",
            "firstName": "Cory M.",
            "lastName": "Chun",
            "fullName": "Cory Chun",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Chun.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-HI-35-lower-ocd-person-00c3bae0-1087-4a2f-bee8-af0fbd2c6cf6",
              "builtID": "hi-lower-35",
              "externalID": "ocd-person/00c3bae0-1087-4a2f-bee8-af0fbd2c6cf6",
              "geometry": null
            },
            "contactInfo": {
              "email": "repchun@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=252&year=2023"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-35-lower-ocd-person-00c3bae0-1087-4a2f-bee8-af0fbd2c6cf6": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/19b5116e-d2b7-4f4b-8d7d-f4dfcbcbe1d6",
            "firstName": "Rachele Fernandez",
            "lastName": "Lamosao",
            "fullName": "Rachele Lamosao",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Lamosao.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-HI-36-lower-ocd-person-19b5116e-d2b7-4f4b-8d7d-f4dfcbcbe1d6",
              "builtID": "hi-lower-36",
              "externalID": "ocd-person/19b5116e-d2b7-4f4b-8d7d-f4dfcbcbe1d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "replamosao@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "replamosao",
              "facebook": "rachelelamosao?mibextid=lqqt4d",
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=270&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=270&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-36-lower-ocd-person-19b5116e-d2b7-4f4b-8d7d-f4dfcbcbe1d6": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/9957f6f1-9231-45b3-940f-2122c1caf3f7",
            "firstName": "Trish Quema",
            "lastName": "La Chica",
            "fullName": "Trish La Chica",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/LaChica.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-HI-37-lower-ocd-person-9957f6f1-9231-45b3-940f-2122c1caf3f7",
              "builtID": "hi-lower-37",
              "externalID": "ocd-person/9957f6f1-9231-45b3-940f-2122c1caf3f7",
              "geometry": null
            },
            "contactInfo": {
              "email": "replachica@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=292&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=292&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-37-lower-ocd-person-9957f6f1-9231-45b3-940f-2122c1caf3f7": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/8562d0d7-9915-4559-98bc-ac9c456efd02",
            "firstName": "Lauren Kealohilani",
            "lastName": "Matsumoto",
            "fullName": "Lauren Matsumoto",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Matsumoto.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-HI-38-lower-ocd-person-8562d0d7-9915-4559-98bc-ac9c456efd02",
              "builtID": "hi-lower-38",
              "externalID": "ocd-person/8562d0d7-9915-4559-98bc-ac9c456efd02",
              "geometry": null
            },
            "contactInfo": {
              "email": "repmatsumoto@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=matsumoto&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=matsumoto&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=matsumoto&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=90&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=90&year=2024",
                "https://repmatsumoto.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-38-lower-ocd-person-8562d0d7-9915-4559-98bc-ac9c456efd02": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/313f96df-c94e-45d7-8cc6-19465ad40612",
            "firstName": "Elijah",
            "lastName": "Pierick",
            "fullName": "Elijah Pierick",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Pierick.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-HI-39-lower-ocd-person-313f96df-c94e-45d7-8cc6-19465ad40612",
              "builtID": "hi-lower-39",
              "externalID": "ocd-person/313f96df-c94e-45d7-8cc6-19465ad40612",
              "geometry": null
            },
            "contactInfo": {
              "email": "reppierick@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "reppierick",
              "youtube": "repelijahpierick",
              "instagram": "repelijahpierick",
              "facebook": "elijahforhawaii",
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=283&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=283&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-39-lower-ocd-person-313f96df-c94e-45d7-8cc6-19465ad40612": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/9b70d54e-ff6b-488e-8208-23c565d7f972",
            "firstName": "Rose",
            "lastName": "Martinez",
            "fullName": "Rose Martinez",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Martinez.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-HI-40-lower-ocd-person-9b70d54e-ff6b-488e-8208-23c565d7f972",
              "builtID": "hi-lower-40",
              "externalID": "ocd-person/9b70d54e-ff6b-488e-8208-23c565d7f972",
              "geometry": null
            },
            "contactInfo": {
              "email": "repmartinez@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=273&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=273&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-40-lower-ocd-person-9b70d54e-ff6b-488e-8208-23c565d7f972": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/21e33d30-ecf0-431b-ad89-429f026efc24",
            "firstName": "David A.",
            "lastName": "Alcos",
            "fullName": "David Alcos",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Alcos.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-HI-41-lower-ocd-person-21e33d30-ecf0-431b-ad89-429f026efc24",
              "builtID": "hi-lower-41",
              "externalID": "ocd-person/21e33d30-ecf0-431b-ad89-429f026efc24",
              "geometry": null
            },
            "contactInfo": {
              "email": "repalcos@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repalcos",
              "youtube": null,
              "instagram": "repalcos",
              "facebook": "votealcos",
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=249&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=249&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-41-lower-ocd-person-21e33d30-ecf0-431b-ad89-429f026efc24": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/810a94e5-8524-4de9-bdf2-2e0d6c64b7f2",
            "firstName": "Diamond",
            "lastName": "Garcia",
            "fullName": "Diamond Garcia",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Garcia.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-HI-42-lower-ocd-person-810a94e5-8524-4de9-bdf2-2e0d6c64b7f2",
              "builtID": "hi-lower-42",
              "externalID": "ocd-person/810a94e5-8524-4de9-bdf2-2e0d6c64b7f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "repgarcia@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "repdiamondgarcia",
              "facebook": "repdiamondgarcia",
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=255&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=255&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-42-lower-ocd-person-810a94e5-8524-4de9-bdf2-2e0d6c64b7f2": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/207b309f-9e03-4834-85cb-083725f7a7ab",
            "firstName": "K. Kanani",
            "lastName": "Souza",
            "fullName": "Kanani Souza",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Souza.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-HI-43-lower-ocd-person-207b309f-9e03-4834-85cb-083725f7a7ab",
              "builtID": "hi-lower-43",
              "externalID": "ocd-person/207b309f-9e03-4834-85cb-083725f7a7ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "repsouza@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=288&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=288&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-43-lower-ocd-person-207b309f-9e03-4834-85cb-083725f7a7ab": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/76c9a0ff-a9f0-47ac-bf3d-9f8ee134c411",
            "firstName": "Darius K.",
            "lastName": "Kila",
            "fullName": "Darius Kila",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Kila.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-HI-44-lower-ocd-person-76c9a0ff-a9f0-47ac-bf3d-9f8ee134c411",
              "builtID": "hi-lower-44",
              "externalID": "ocd-person/76c9a0ff-a9f0-47ac-bf3d-9f8ee134c411",
              "geometry": null
            },
            "contactInfo": {
              "email": "repkila@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=266&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=266&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-44-lower-ocd-person-76c9a0ff-a9f0-47ac-bf3d-9f8ee134c411": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/12fb4131-d77f-4053-9319-204d396f433b",
            "firstName": "Cedric Solosolo Asuega",
            "lastName": "Gates",
            "fullName": "Cedric Gates",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Gates.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-HI-45-lower-ocd-person-12fb4131-d77f-4053-9319-204d396f433b",
              "builtID": "hi-lower-45",
              "externalID": "ocd-person/12fb4131-d77f-4053-9319-204d396f433b",
              "geometry": null
            },
            "contactInfo": {
              "email": "repgates@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=gates&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=gates&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=gates&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=46&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=46&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-45-lower-ocd-person-12fb4131-d77f-4053-9319-204d396f433b": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/cb860f85-2006-4d5b-b1a8-1c56c1def2d7",
            "firstName": "Amy A.",
            "lastName": "Perruso",
            "fullName": "Amy Perruso",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Perruso.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-HI-46-lower-ocd-person-cb860f85-2006-4d5b-b1a8-1c56c1def2d7",
              "builtID": "hi-lower-46",
              "externalID": "ocd-person/cb860f85-2006-4d5b-b1a8-1c56c1def2d7",
              "geometry": null
            },
            "contactInfo": {
              "email": "repperruso@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "representativeperruso",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=perruso&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=perruso&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=perruso&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=109&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=109&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-46-lower-ocd-person-cb860f85-2006-4d5b-b1a8-1c56c1def2d7": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/078931f6-9caa-4ba0-9f53-2b27e13ed400",
            "firstName": "Sean",
            "lastName": "Quinlan",
            "fullName": "Sean Quinlan",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Quinlan.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-HI-47-lower-ocd-person-078931f6-9caa-4ba0-9f53-2b27e13ed400",
              "builtID": "hi-lower-47",
              "externalID": "ocd-person/078931f6-9caa-4ba0-9f53-2b27e13ed400",
              "geometry": null
            },
            "contactInfo": {
              "email": "repquinlan@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=quinlan&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=quinlan&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=quinlan&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=112&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=112&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-47-lower-ocd-person-078931f6-9caa-4ba0-9f53-2b27e13ed400": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/1d8bd1da-7126-4248-8a1e-dce56626b746",
            "firstName": "Lisa",
            "lastName": "Kitagawa",
            "fullName": "Lisa Kitagawa",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Kitagawa.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-HI-48-lower-ocd-person-1d8bd1da-7126-4248-8a1e-dce56626b746",
              "builtID": "hi-lower-48",
              "externalID": "ocd-person/1d8bd1da-7126-4248-8a1e-dce56626b746",
              "geometry": null
            },
            "contactInfo": {
              "email": "repkitagawa@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "repkitagawa",
              "facebook": "representative-lisa-kitagawa-110741641505108",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=Kitagawa&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=Kitagawa&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=Kitagawa&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=76&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=76&year=2024",
                "https://www.repkitagawa.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-48-lower-ocd-person-1d8bd1da-7126-4248-8a1e-dce56626b746": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/78cc3d2c-31b6-421f-a666-5a96ca3fe65a",
            "firstName": "Scot Z.",
            "lastName": "Matayoshi",
            "fullName": "Scot Matayoshi",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Matayoshi.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-HI-49-lower-ocd-person-78cc3d2c-31b6-421f-a666-5a96ca3fe65a",
              "builtID": "hi-lower-49",
              "externalID": "ocd-person/78cc3d2c-31b6-421f-a666-5a96ca3fe65a",
              "geometry": null
            },
            "contactInfo": {
              "email": "repmatayoshi@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "repscotmatayoshi",
              "facebook": "repscotmatayoshi",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=Matayoshi&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=Matayoshi&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=Matayoshi&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=89&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=89&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-49-lower-ocd-person-78cc3d2c-31b6-421f-a666-5a96ca3fe65a": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/cf7727e8-4d16-410e-beb1-f9d9e2bbe37d",
            "firstName": "Natalia",
            "lastName": "Hussey-Burdick",
            "fullName": "Natalia Hussey-Burdick",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Hussey-Burdick.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-HI-50-lower-ocd-person-cf7727e8-4d16-410e-beb1-f9d9e2bbe37d",
              "builtID": "hi-lower-50",
              "externalID": "ocd-person/cf7727e8-4d16-410e-beb1-f9d9e2bbe37d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rephusseyburdick@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repnhb",
              "youtube": null,
              "instagram": "repnhb",
              "facebook": "repnhb",
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=261&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=261&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-50-lower-ocd-person-cf7727e8-4d16-410e-beb1-f9d9e2bbe37d": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/c4ea2b48-612d-42f4-b396-6f13c86bfe5b",
            "firstName": "Lisa",
            "lastName": "Marten",
            "fullName": "Lisa Marten",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Marten.jpg",
            "title": "HI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-HI-51-lower-ocd-person-c4ea2b48-612d-42f4-b396-6f13c86bfe5b",
              "builtID": "hi-lower-51",
              "externalID": "ocd-person/c4ea2b48-612d-42f4-b396-6f13c86bfe5b",
              "geometry": null
            },
            "contactInfo": {
              "email": "repmarten@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=Marten&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=Marten&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=87&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=87&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-HI-51-lower-ocd-person-c4ea2b48-612d-42f4-b396-6f13c86bfe5b": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/77991a5e-d7df-4282-b1cc-2398c56c18cc",
            "firstName": "Lorraine Rodero",
            "lastName": "Inouye",
            "fullName": "Lorraine Inouye",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/inouye.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-HI-1-upper-ocd-person-77991a5e-d7df-4282-b1cc-2398c56c18cc",
              "builtID": "hi-upper-1",
              "externalID": "ocd-person/77991a5e-d7df-4282-b1cc-2398c56c18cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "seninouye@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "senlorraineinouye",
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=181&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=181&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-1-upper-ocd-person-77991a5e-d7df-4282-b1cc-2398c56c18cc": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/cac74229-bacc-4358-8208-23cdac57d57c",
            "firstName": "Joy A.",
            "lastName": "San Buenaventura",
            "fullName": "Joy San Buenaventura",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/sanbuenaventura.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-HI-2-upper-ocd-person-cac74229-bacc-4358-8208-23cdac57d57c",
              "builtID": "hi-upper-2",
              "externalID": "ocd-person/cac74229-bacc-4358-8208-23cdac57d57c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sensanbuenaventura@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "senjoydistrict2",
              "youtube": null,
              "instagram": "senjoysanbuenaventura",
              "facebook": "senjoydistrict2",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=Sanbuenaventura&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=sanbuenaventura&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=sanbuenaventura&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=206&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=206&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-2-upper-ocd-person-cac74229-bacc-4358-8208-23cdac57d57c": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/e75469cc-851a-4e63-9908-9e63b5215448",
            "firstName": "Dru Mamo",
            "lastName": "Kanuha",
            "fullName": "Dru Kanuha",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/kanuha.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-HI-3-upper-ocd-person-e75469cc-851a-4e63-9908-9e63b5215448",
              "builtID": "hi-upper-3",
              "externalID": "ocd-person/e75469cc-851a-4e63-9908-9e63b5215448",
              "geometry": null
            },
            "contactInfo": {
              "email": "senkanuha@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "senatorkanuha",
              "youtube": null,
              "instagram": "senatorkanuha",
              "facebook": "senatorkanuha",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=kanuha&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=kanuha&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=kanuha&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=184&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=184&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-3-upper-ocd-person-e75469cc-851a-4e63-9908-9e63b5215448": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/ff0ea974-b3aa-4261-aa1b-fa9cf74b3728",
            "firstName": "Herbert M.",
            "lastName": "Richards",
            "fullName": "Tim Richards",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/richards.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-HI-4-upper-ocd-person-ff0ea974-b3aa-4261-aa1b-fa9cf74b3728",
              "builtID": "hi-upper-4",
              "externalID": "ocd-person/ff0ea974-b3aa-4261-aa1b-fa9cf74b3728",
              "geometry": null
            },
            "contactInfo": {
              "email": "senrichards@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=244&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=244&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-4-upper-ocd-person-ff0ea974-b3aa-4261-aa1b-fa9cf74b3728": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/3b99a717-698f-4bcf-a630-183bb63e623e",
            "firstName": "Troy N.",
            "lastName": "Hashimoto",
            "fullName": "Troy Hashimoto",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/Hashimoto.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-HI-5-upper-ocd-person-3b99a717-698f-4bcf-a630-183bb63e623e",
              "builtID": "hi-upper-5",
              "externalID": "ocd-person/3b99a717-698f-4bcf-a630-183bb63e623e",
              "geometry": null
            },
            "contactInfo": {
              "email": "senhashimoto@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "rephashimoto",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=hashimoto&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=hashimoto&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=hashimoto&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=56&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=293&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=293&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-5-upper-ocd-person-3b99a717-698f-4bcf-a630-183bb63e623e": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/d31a4d99-d6f1-42f8-b902-b6bff3c667e8",
            "firstName": "Angus L.K.",
            "lastName": "McKelvey",
            "fullName": "Angus McKelvey",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/mckelvey.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-HI-6-upper-ocd-person-d31a4d99-d6f1-42f8-b902-b6bff3c667e8",
              "builtID": "hi-upper-6",
              "externalID": "ocd-person/d31a4d99-d6f1-42f8-b902-b6bff3c667e8",
              "geometry": null
            },
            "contactInfo": {
              "email": "senmckelvey@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=mckelvey&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=mckelvey&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=mckelvey&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=241&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=241&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-6-upper-ocd-person-d31a4d99-d6f1-42f8-b902-b6bff3c667e8": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/d76e8f2c-2e55-4d2a-94e4-c13cc0a9e5e2",
            "firstName": "Lynn Pualani",
            "lastName": "DeCoite",
            "fullName": "Lynn DeCoite",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/decoite.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-HI-7-upper-ocd-person-d76e8f2c-2e55-4d2a-94e4-c13cc0a9e5e2",
              "builtID": "hi-upper-7",
              "externalID": "ocd-person/d76e8f2c-2e55-4d2a-94e4-c13cc0a9e5e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "sendecoite@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=decoite&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=decoite&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=sendecoite&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=sendecoite&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=161&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=161&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-7-upper-ocd-person-d76e8f2c-2e55-4d2a-94e4-c13cc0a9e5e2": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/3ca1b2e5-0c68-4be1-ba5b-b0d935b85e6f",
            "firstName": "Ronald D.",
            "lastName": "Kouchi",
            "fullName": "Ron Kouchi",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/kouchi.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-HI-8-upper-ocd-person-3ca1b2e5-0c68-4be1-ba5b-b0d935b85e6f",
              "builtID": "hi-upper-8",
              "externalID": "ocd-person/3ca1b2e5-0c68-4be1-ba5b-b0d935b85e6f",
              "geometry": null
            },
            "contactInfo": {
              "email": "senkouchi@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=kouchi&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=kouchi&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=kouchi&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=192&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=192&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-8-upper-ocd-person-3ca1b2e5-0c68-4be1-ba5b-b0d935b85e6f": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/d6359473-e0b5-4040-8d7f-9d95a0518650",
            "firstName": "Stanley",
            "lastName": "Chang",
            "fullName": "Stanley Chang",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/schang.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-HI-9-upper-ocd-person-d6359473-e0b5-4040-8d7f-9d95a0518650",
              "builtID": "hi-upper-9",
              "externalID": "ocd-person/d6359473-e0b5-4040-8d7f-9d95a0518650",
              "geometry": null
            },
            "contactInfo": {
              "email": "senchang@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=chang&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=chang&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=chang&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=157&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=157&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-9-upper-ocd-person-d6359473-e0b5-4040-8d7f-9d95a0518650": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/94e42ecd-2e4d-4502-8b50-c1c6324617f1",
            "firstName": "Les S.",
            "lastName": "Ihara",
            "fullName": "Les Ihara",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/ihara.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-HI-10-upper-ocd-person-94e42ecd-2e4d-4502-8b50-c1c6324617f1",
              "builtID": "hi-upper-10",
              "externalID": "ocd-person/94e42ecd-2e4d-4502-8b50-c1c6324617f1",
              "geometry": null
            },
            "contactInfo": {
              "email": "senihara@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=ihara&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=ihara&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=ihara&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=180&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=180&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-10-upper-ocd-person-94e42ecd-2e4d-4502-8b50-c1c6324617f1": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/ca85a087-4a1f-450b-9386-11c13eb8c280",
            "firstName": "Carol",
            "lastName": "Fukunaga",
            "fullName": "Carol Fukunaga",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/fukunaga.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-HI-11-upper-ocd-person-ca85a087-4a1f-450b-9386-11c13eb8c280",
              "builtID": "hi-upper-11",
              "externalID": "ocd-person/ca85a087-4a1f-450b-9386-11c13eb8c280",
              "geometry": null
            },
            "contactInfo": {
              "email": "senfukunaga@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=168&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=168&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-11-upper-ocd-person-ca85a087-4a1f-450b-9386-11c13eb8c280": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/7e04af29-c684-402f-a7b0-bdde0591197c",
            "firstName": "Sharon Y.",
            "lastName": "Moriwaki",
            "fullName": "Sharon Moriwaki",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/moriwaki.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-HI-12-upper-ocd-person-7e04af29-c684-402f-a7b0-bdde0591197c",
              "builtID": "hi-upper-12",
              "externalID": "ocd-person/7e04af29-c684-402f-a7b0-bdde0591197c",
              "geometry": null
            },
            "contactInfo": {
              "email": "senmoriwaki@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "senatormoriwaki",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=moriwaki&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=moriwaki&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=moriwaki&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=198&year=2023",
                "https://senatormoriwaki.com/",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=198&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-12-upper-ocd-person-7e04af29-c684-402f-a7b0-bdde0591197c": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/099756de-3b84-4531-b5da-6d1c5d4c89ee",
            "firstName": "Karl A.",
            "lastName": "Rhoads",
            "fullName": "Karl Rhoads",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/rhoads.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-HI-13-upper-ocd-person-099756de-3b84-4531-b5da-6d1c5d4c89ee",
              "builtID": "hi-upper-13",
              "externalID": "ocd-person/099756de-3b84-4531-b5da-6d1c5d4c89ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "senrhoads@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=krhoads&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=krhoads&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=krhoads&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=201&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=201&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-13-upper-ocd-person-099756de-3b84-4531-b5da-6d1c5d4c89ee": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/f196e943-c8d6-4a35-96c2-252171c6529a",
            "firstName": "Donna Mercado",
            "lastName": "Kim",
            "fullName": "Donna Kim",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/kim.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-HI-14-upper-ocd-person-f196e943-c8d6-4a35-96c2-252171c6529a",
              "builtID": "hi-upper-14",
              "externalID": "ocd-person/f196e943-c8d6-4a35-96c2-252171c6529a",
              "geometry": null
            },
            "contactInfo": {
              "email": "senkim@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "senatordonnamercadokim",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=kim&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=kim&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=kim&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=189&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=189&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-14-upper-ocd-person-f196e943-c8d6-4a35-96c2-252171c6529a": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/5ef4a0ba-e9b0-4a62-9ac7-42d88308f3df",
            "firstName": "Glenn S.",
            "lastName": "Wakai",
            "fullName": "Glenn Wakai",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/wakai.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-HI-15-upper-ocd-person-5ef4a0ba-e9b0-4a62-9ac7-42d88308f3df",
              "builtID": "hi-upper-15",
              "externalID": "ocd-person/5ef4a0ba-e9b0-4a62-9ac7-42d88308f3df",
              "geometry": null
            },
            "contactInfo": {
              "email": "senwakai@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=wakai&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=wakai&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=wakai&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=217&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=217&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-15-upper-ocd-person-5ef4a0ba-e9b0-4a62-9ac7-42d88308f3df": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/22139704-8a26-4f4a-8a03-989990c21f37",
            "firstName": "Brandon J.C.",
            "lastName": "Elefante",
            "fullName": "Brandon Elefante",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/elefante.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-HI-16-upper-ocd-person-22139704-8a26-4f4a-8a03-989990c21f37",
              "builtID": "hi-upper-16",
              "externalID": "ocd-person/22139704-8a26-4f4a-8a03-989990c21f37",
              "geometry": null
            },
            "contactInfo": {
              "email": "senelefante@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "senelefante",
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=229&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=229&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-16-upper-ocd-person-22139704-8a26-4f4a-8a03-989990c21f37": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/0a9ef1d8-8fec-48f4-963c-50f39332cfa7",
            "firstName": "Donovan M.",
            "lastName": "Dela Cruz",
            "fullName": "Donovan Dela Cruz",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/delacruz.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-HI-17-upper-ocd-person-0a9ef1d8-8fec-48f4-963c-50f39332cfa7",
              "builtID": "hi-upper-17",
              "externalID": "ocd-person/0a9ef1d8-8fec-48f4-963c-50f39332cfa7",
              "geometry": null
            },
            "contactInfo": {
              "email": "sendelacruz@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=delacruz&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=delacruz&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=delacruz&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=162&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=162&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-17-upper-ocd-person-0a9ef1d8-8fec-48f4-963c-50f39332cfa7": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/511d674b-1e98-4a1f-8af2-24f986a165d9",
            "firstName": "Michelle N.",
            "lastName": "Kidani",
            "fullName": "Michelle Kidani",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/kidani.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-HI-18-upper-ocd-person-511d674b-1e98-4a1f-8af2-24f986a165d9",
              "builtID": "hi-upper-18",
              "externalID": "ocd-person/511d674b-1e98-4a1f-8af2-24f986a165d9",
              "geometry": null
            },
            "contactInfo": {
              "email": "senkidani@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=kidani&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=kidani&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=kidani&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=188&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=188&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-18-upper-ocd-person-511d674b-1e98-4a1f-8af2-24f986a165d9": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/b30c0862-ac39-4bcc-83d6-c637ce938a23",
            "firstName": "Henry James C.",
            "lastName": "Aquino",
            "fullName": "Henry Aquino",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/aquino.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-HI-19-upper-ocd-person-b30c0862-ac39-4bcc-83d6-c637ce938a23",
              "builtID": "hi-upper-19",
              "externalID": "ocd-person/b30c0862-ac39-4bcc-83d6-c637ce938a23",
              "geometry": null
            },
            "contactInfo": {
              "email": "senaquino@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=aquino&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=aquino&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=aquino&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=224&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=224&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-19-upper-ocd-person-b30c0862-ac39-4bcc-83d6-c637ce938a23": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/e6bafd21-a2de-41b8-8139-fcbf5ea94cf4",
            "firstName": "Kurt",
            "lastName": "Fevella",
            "fullName": "Kurt Fevella",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/fevella.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-HI-20-upper-ocd-person-e6bafd21-a2de-41b8-8139-fcbf5ea94cf4",
              "builtID": "hi-upper-20",
              "externalID": "ocd-person/e6bafd21-a2de-41b8-8139-fcbf5ea94cf4",
              "geometry": null
            },
            "contactInfo": {
              "email": "senfevella@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=fevella&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=fevella&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=fevella&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=166&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=166&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-20-upper-ocd-person-e6bafd21-a2de-41b8-8139-fcbf5ea94cf4": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/9f87e7d6-a307-45ab-b384-96a8599dab56",
            "firstName": "Gerald Michael",
            "lastName": "Gabbard",
            "fullName": "Mike Gabbard",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/gabbard.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-HI-21-upper-ocd-person-9f87e7d6-a307-45ab-b384-96a8599dab56",
              "builtID": "hi-upper-21",
              "externalID": "ocd-person/9f87e7d6-a307-45ab-b384-96a8599dab56",
              "geometry": null
            },
            "contactInfo": {
              "email": "sengabbard@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "senmikegabbard",
              "youtube": "senmikegabbard",
              "instagram": "gabbardaloha",
              "facebook": "mikegabbardsenatedistrict21",
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=169&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=169&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-21-upper-ocd-person-9f87e7d6-a307-45ab-b384-96a8599dab56": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/3bfffd1c-7acc-436f-9090-8244117b331c",
            "firstName": "Maile S.L.",
            "lastName": "Shimabukuro",
            "fullName": "Maile Shimabukuro",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/shimabukuro.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-HI-22-upper-ocd-person-3bfffd1c-7acc-436f-9090-8244117b331c",
              "builtID": "hi-upper-22",
              "externalID": "ocd-person/3bfffd1c-7acc-436f-9090-8244117b331c",
              "geometry": null
            },
            "contactInfo": {
              "email": "senshimabukuro@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "21maile",
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=shimabukuro&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=shimabukuro&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=shimabukuro&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=207&year=2023"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-22-upper-ocd-person-3bfffd1c-7acc-436f-9090-8244117b331c": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/be1149eb-5b0c-48fb-b9f2-9bee875479cc",
            "firstName": "Brenton",
            "lastName": "Awa",
            "fullName": "Brenton Awa",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/awa.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-HI-23-upper-ocd-person-be1149eb-5b0c-48fb-b9f2-9bee875479cc",
              "builtID": "hi-upper-23",
              "externalID": "ocd-person/be1149eb-5b0c-48fb-b9f2-9bee875479cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "senawa@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=225&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=225",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=225&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-23-upper-ocd-person-be1149eb-5b0c-48fb-b9f2-9bee875479cc": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/07ed1559-820e-4ea1-803e-024ce5359a87",
            "firstName": "Jarrett",
            "lastName": "Keohokalole",
            "fullName": "Jarrett Keohokalole",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/keohokalole.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-HI-24-upper-ocd-person-07ed1559-820e-4ea1-803e-024ce5359a87",
              "builtID": "hi-upper-24",
              "externalID": "ocd-person/07ed1559-820e-4ea1-803e-024ce5359a87",
              "geometry": null
            },
            "contactInfo": {
              "email": "senkeohokalole@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=jkeohokalole&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=jkeohokalole&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=jkeohokalole&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=187&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=187&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-24-upper-ocd-person-07ed1559-820e-4ea1-803e-024ce5359a87": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/3484e323-b1a2-4c41-ad9f-8153445f8cd4",
            "firstName": "Christopher Kalani Cushman",
            "lastName": "Lee",
            "fullName": "Chris Lee",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.hawaii.gov/MemberFiles/RepSenPhotos/clee.jpg",
            "title": "HI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "HI",
              "stateFull": "Hawaii",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-HI-25-upper-ocd-person-3484e323-b1a2-4c41-ad9f-8153445f8cd4",
              "builtID": "hi-upper-25",
              "externalID": "ocd-person/3484e323-b1a2-4c41-ad9f-8153445f8cd4",
              "geometry": null
            },
            "contactInfo": {
              "email": "senlee@capitol.hawaii.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://capitol.hawaii.gov/memberpage.aspx?member=clee&year=2019",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=senclee&year=2021",
                "https://www.capitol.hawaii.gov/memberpage.aspx?member=senclee&year=2022",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=193&year=2023",
                "https://www.capitol.hawaii.gov/legislature/memberpage.aspx?member=193&year=2024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-HI-25-upper-ocd-person-3484e323-b1a2-4c41-ad9f-8153445f8cd4": 0
        }
      }
    }
  },
  "ID": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "ID",
          "stateFull": "Idaho",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "ID",
            "stateFull": "Idaho",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-ID---",
            "builtID": "id--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-ID---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "C000880",
          "in_office": true,
          "firstName": "Michael",
          "lastName": "Crapo",
          "middleName": "D.",
          "fullName": "Mike Crapo",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/C000880.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "ID",
            "stateFull": "Idaho",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-ID---C000880",
            "builtID": "id--",
            "externalID": "C000880",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "MikeCrapo",
            "youtube": "senatorcrapo",
            "instagram": null,
            "facebook": "mikecrapo",
            "urls": ["https://www.crapo.senate.gov"],
            "rss_url": null
          },
          "title": "ID Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/C000880.json",
          "govtrack_id": "300030",
          "cspan_id": "26440",
          "votesmart_id": "26830",
          "icpsr_id": "29345",
          "crp_id": "N00006267",
          "google_entity_id": "/m/021pgp",
          "state_rank": "senior",
          "lis_id": "S266",
          "suffix": null,
          "date_of_birth": "1951-05-20",
          "leadership_role": null,
          "fec_candidate_id": "S8ID00027",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "25",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 33,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 5.96,
          "votes_with_party_pct": 91.87,
          "votes_against_party_pct": 8.13,
          "ocd_id": "ocd-division/country:us/state:id"
        },
        {
          "API_ID": "R000584",
          "in_office": true,
          "firstName": "Jim",
          "lastName": "Risch",
          "middleName": null,
          "fullName": "James E. Risch",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/R000584.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "ID",
            "stateFull": "Idaho",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-ID---R000584",
            "builtID": "id--",
            "externalID": "R000584",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.risch.senate.gov/public/index.cfm?p=Email",
            "address1": "483 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-2752",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorRisch",
            "youtube": "SenatorJamesRisch",
            "instagram": null,
            "facebook": "senatorjimrisch",
            "urls": ["https://www.risch.senate.gov"],
            "rss_url": "https://www.risch.senate.gov/public/?a=rss.feed"
          },
          "title": "ID Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/R000584.json",
          "govtrack_id": "412322",
          "cspan_id": "1020034",
          "votesmart_id": "2919",
          "icpsr_id": "40902",
          "crp_id": "N00029441",
          "google_entity_id": "/m/06y9p0",
          "state_rank": "junior",
          "lis_id": "S323",
          "suffix": null,
          "date_of_birth": "1943-05-03",
          "leadership_role": null,
          "fec_candidate_id": "S8ID00092",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "15",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 59,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 10.65,
          "votes_with_party_pct": 91.99,
          "votes_against_party_pct": 8.01,
          "ocd_id": "ocd-division/country:us/state:id"
        }
      ],
      "hash": { "SENATE-ID---C000880": 0, "SENATE-ID---R000584": 1 }
    },
    "HOUSE": {
      "01": {
        "members": [
          {
            "API_ID": "F000469",
            "in_office": true,
            "firstName": "Russ",
            "lastName": "Fulcher",
            "middleName": null,
            "fullName": "Russ Fulcher",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/F000469.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-ID-01--F000469",
              "builtID": "id--01",
              "externalID": "F000469",
              "geometry": null,
              "geoid": "1601"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1514 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-6611",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRussFulcher",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://fulcher.house.gov"],
              "rss_url": ""
            },
            "title": "ID House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000469.json",
            "govtrack_id": "412773",
            "cspan_id": null,
            "votesmart_id": "33091",
            "icpsr_id": null,
            "crp_id": "N00041335",
            "google_entity_id": "/m/05h4vgh",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1973-07-19",
            "leadership_role": null,
            "fec_candidate_id": "H8ID01124",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 9,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 90.08,
            "votes_against_party_pct": 7.98,
            "ocd_id": "ocd-division/country:us/state:id/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-ID-01--F000469": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "S001148",
            "in_office": true,
            "firstName": "Mike",
            "lastName": "Simpson",
            "middleName": null,
            "fullName": "Michael K. Simpson",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S001148.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-ID-02--S001148",
              "builtID": "id--02",
              "externalID": "S001148",
              "geometry": null,
              "geoid": "1602"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2084 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5531",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "CongMikeSimpson",
              "youtube": "CongMikeSimpson",
              "instagram": null,
              "facebook": "96007744606",
              "urls": ["https://simpson.house.gov"],
              "rss_url": "https://simpson.house.gov/news/rss.aspx"
            },
            "title": "ID House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001148.json",
            "govtrack_id": "400376",
            "cspan_id": "57889",
            "votesmart_id": "2917",
            "icpsr_id": "29910",
            "crp_id": "N00006263",
            "google_entity_id": "/m/0255tn",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1950-09-08",
            "leadership_role": null,
            "fec_candidate_id": "H8ID02064",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "26",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 55,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 5.21,
            "votes_with_party_pct": 83.76,
            "votes_against_party_pct": 14.3,
            "ocd_id": "ocd-division/country:us/state:id/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-ID-02--S001148": 0 }
      }
    },
    "SLDL": {
      "33A": {
        "members": [
          {
            "API_ID": "ocd-person/c8c374b3-22b8-4221-a65a-6a4616cb107d",
            "firstName": "Barbara Dee",
            "lastName": "Ehardt",
            "fullName": "Barbara Ehardt",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2017/directory/Ehardt4945.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "33A",
              "chamber": "lower",
              "hashID": "SLDL-ID-33A-lower-ocd-person-c8c374b3-22b8-4221-a65a-6a4616cb107d",
              "builtID": "id-lower-33a",
              "externalID": "ocd-person/c8c374b3-22b8-4221-a65a-6a4616cb107d",
              "geometry": null
            },
            "contactInfo": {
              "email": "behardt@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "961 J St., Idaho Falls, ID 83402",
              "phone1": null,
              "phone2": "208-332-1189",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=33",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=33",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=33",
                "https://legislature.idaho.gov/legislators/membership/2023/id4945/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-33A-lower-ocd-person-c8c374b3-22b8-4221-a65a-6a4616cb107d": 0
        }
      },
      "6B": {
        "members": [
          {
            "API_ID": "ocd-person/59fc8cd0-bec0-4df6-a3e6-99ea305c7100",
            "firstName": "Brandon",
            "lastName": "Mitchell",
            "fullName": "Brandon Mitchell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2021/directory/Mitchell6704.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "6B",
              "chamber": "lower",
              "hashID": "SLDL-ID-6B-lower-ocd-person-59fc8cd0-bec0-4df6-a3e6-99ea305c7100",
              "builtID": "id-lower-6b",
              "externalID": "ocd-person/59fc8cd0-bec0-4df6-a3e6-99ea305c7100",
              "geometry": null
            },
            "contactInfo": {
              "email": "bmitchell@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 8897, Moscow, ID 83843",
              "phone1": null,
              "phone2": "208-596-5001",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=5",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=5",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=6",
                "https://legislature.idaho.gov/legislators/membership/2023/id6704/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-6B-lower-ocd-person-59fc8cd0-bec0-4df6-a3e6-99ea305c7100": 0
        }
      },
      "13A": {
        "members": [
          {
            "API_ID": "ocd-person/81d4b890-9bf4-44e0-99b2-eda866f02d48",
            "firstName": "Brent J.",
            "lastName": "Crane",
            "fullName": "Brent Crane",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Crane21.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "13A",
              "chamber": "lower",
              "hashID": "SLDL-ID-13A-lower-ocd-person-81d4b890-9bf4-44e0-99b2-eda866f02d48",
              "builtID": "id-lower-13a",
              "externalID": "ocd-person/81d4b890-9bf4-44e0-99b2-eda866f02d48",
              "geometry": null
            },
            "contactInfo": {
              "email": "bcrane@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 86, Nampa, ID 83653",
              "phone1": null,
              "phone2": "208-466-0613",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=13",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=13",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=13",
                "https://legislature.idaho.gov/legislators/membership/2023/id21/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-13A-lower-ocd-person-81d4b890-9bf4-44e0-99b2-eda866f02d48": 0
        }
      },
      "34B": {
        "members": [
          {
            "API_ID": "ocd-person/df313cc3-ceb2-4fb0-bb36-b2f9cb9e959d",
            "firstName": "Britt",
            "lastName": "Raybould",
            "fullName": "Britt Raybould",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Raybould5381.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "34B",
              "chamber": "lower",
              "hashID": "SLDL-ID-34B-lower-ocd-person-df313cc3-ceb2-4fb0-bb36-b2f9cb9e959d",
              "builtID": "id-lower-34b",
              "externalID": "ocd-person/df313cc3-ceb2-4fb0-bb36-b2f9cb9e959d",
              "geometry": null
            },
            "contactInfo": {
              "email": "braybould@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 653, Rexburg, ID 83440",
              "phone1": null,
              "phone2": "208-419-0768",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2019&district=34",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=34",
                "https://legislature.idaho.gov/legislators/membership/2023/id5381/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-34B-lower-ocd-person-df313cc3-ceb2-4fb0-bb36-b2f9cb9e959d": 0
        }
      },
      "18B": {
        "members": [
          {
            "API_ID": "ocd-person/50e557e4-e7b6-4765-a636-1638a675189c",
            "firstName": "Brooke",
            "lastName": "Green",
            "fullName": "Brooke Green",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2019/directory/Green5374.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "18B",
              "chamber": "lower",
              "hashID": "SLDL-ID-18B-lower-ocd-person-50e557e4-e7b6-4765-a636-1638a675189c",
              "builtID": "id-lower-18b",
              "externalID": "ocd-person/50e557e4-e7b6-4765-a636-1638a675189c",
              "geometry": null
            },
            "contactInfo": {
              "email": "bgreen@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4936 E. Woodcutter Drive, Boise, ID 83706",
              "phone1": null,
              "phone2": "208-332-1080",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=18",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=18",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=18",
                "https://legislature.idaho.gov/legislators/membership/2023/id5374/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-18B-lower-ocd-person-50e557e4-e7b6-4765-a636-1638a675189c": 0
        }
      },
      "10B": {
        "members": [
          {
            "API_ID": "ocd-person/e8eea4c8-b669-4609-b991-13ef3a6ff10b",
            "firstName": "Bruce David",
            "lastName": "Skaug",
            "fullName": "Bruce Skaug",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2021/directory/Skaug6708.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "10B",
              "chamber": "lower",
              "hashID": "SLDL-ID-10B-lower-ocd-person-e8eea4c8-b669-4609-b991-13ef3a6ff10b",
              "builtID": "id-lower-10b",
              "externalID": "ocd-person/e8eea4c8-b669-4609-b991-13ef3a6ff10b",
              "geometry": null
            },
            "contactInfo": {
              "email": "bskaug@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1226 E. Karcher Road, Nampa, ID 83687",
              "phone1": null,
              "phone2": "208-466-0030",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=12",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=12",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=10",
                "https://legislature.idaho.gov/legislators/membership/2023/id6708/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-10B-lower-ocd-person-e8eea4c8-b669-4609-b991-13ef3a6ff10b": 0
        }
      },
      "7B": {
        "members": [
          {
            "API_ID": "ocd-person/e3d1a2f1-913b-40ec-8256-36eba043156b",
            "firstName": "Charlie",
            "lastName": "Shepherd",
            "fullName": "Charlie Shepherd",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2021/directory/Shepherd6707.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "7B",
              "chamber": "lower",
              "hashID": "SLDL-ID-7B-lower-ocd-person-e3d1a2f1-913b-40ec-8256-36eba043156b",
              "builtID": "id-lower-7b",
              "externalID": "ocd-person/e3d1a2f1-913b-40ec-8256-36eba043156b",
              "geometry": null
            },
            "contactInfo": {
              "email": "cshepherd@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 293, Pollock, ID 83547",
              "phone1": null,
              "phone2": "208-859-0023",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=7",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=7",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=7",
                "https://legislature.idaho.gov/legislators/membership/2023/id6707/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-7B-lower-ocd-person-e3d1a2f1-913b-40ec-8256-36eba043156b": 0
        }
      },
      "24A": {
        "members": [
          {
            "API_ID": "ocd-person/51555706-cc84-44e1-9709-06a50ce879e5",
            "firstName": "Chenele",
            "lastName": "Dixon",
            "fullName": "Chenele Dixon",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Dixon7808.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "24A",
              "chamber": "lower",
              "hashID": "SLDL-ID-24A-lower-ocd-person-51555706-cc84-44e1-9709-06a50ce879e5",
              "builtID": "id-lower-24a",
              "externalID": "ocd-person/51555706-cc84-44e1-9709-06a50ce879e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "cdixon@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3430 Harvest Moon Drive, Kimberly, ID 83341",
              "phone1": null,
              "phone2": "208-332-1024",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=24",
                "https://legislature.idaho.gov/legislators/membership/2023/id7808/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-24A-lower-ocd-person-51555706-cc84-44e1-9709-06a50ce879e5": 0
        }
      },
      "19B": {
        "members": [
          {
            "API_ID": "ocd-person/7e3f625c-e56e-409a-9989-b07cd77344f6",
            "firstName": "Christopher P.",
            "lastName": "Mathias",
            "fullName": "Chris Mathias",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2021/directory/Mathias6703.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "19B",
              "chamber": "lower",
              "hashID": "SLDL-ID-19B-lower-ocd-person-7e3f625c-e56e-409a-9989-b07cd77344f6",
              "builtID": "id-lower-19b",
              "externalID": "ocd-person/7e3f625c-e56e-409a-9989-b07cd77344f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "cmathias@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 8753, Boise, ID 83707",
              "phone1": null,
              "phone2": "208-332-1076",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=19",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=19",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=19",
                "https://legislature.idaho.gov/legislators/membership/2021/id6703/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-19B-lower-ocd-person-7e3f625c-e56e-409a-9989-b07cd77344f6": 0
        }
      },
      "11B": {
        "members": [
          {
            "API_ID": "ocd-person/1f7ba43d-acfd-432e-8630-77b4f6a9f590",
            "firstName": "Christopher M.",
            "lastName": "Allgood",
            "fullName": "Chris Allgood",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Allgood7858.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "11B",
              "chamber": "lower",
              "hashID": "SLDL-ID-11B-lower-ocd-person-1f7ba43d-acfd-432e-8630-77b4f6a9f590",
              "builtID": "id-lower-11b",
              "externalID": "ocd-person/1f7ba43d-acfd-432e-8630-77b4f6a9f590",
              "geometry": null
            },
            "contactInfo": {
              "email": "callgood@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1322 Wilson St., Caldwell, ID 83605",
              "phone1": null,
              "phone2": "208-249-0876",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=11",
                "https://legislature.idaho.gov/legislators/membership/2023/id7858/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-11B-lower-ocd-person-1f7ba43d-acfd-432e-8630-77b4f6a9f590": 0
        }
      },
      "27B": {
        "members": [
          {
            "API_ID": "ocd-person/1bef63af-a339-47dd-872d-ef1c94a72900",
            "firstName": "Clay",
            "lastName": "Handy",
            "fullName": "Clay Handy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Handy7811.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "27B",
              "chamber": "lower",
              "hashID": "SLDL-ID-27B-lower-ocd-person-1bef63af-a339-47dd-872d-ef1c94a72900",
              "builtID": "id-lower-27b",
              "externalID": "ocd-person/1bef63af-a339-47dd-872d-ef1c94a72900",
              "geometry": null
            },
            "contactInfo": {
              "email": "chandy@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "29 S. 150 East, Burley, ID 83318",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=27",
                "https://legislature.idaho.gov/legislators/membership/2023/id7811/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-27B-lower-ocd-person-1bef63af-a339-47dd-872d-ef1c94a72900": 0
        }
      },
      "2B": {
        "members": [
          {
            "API_ID": "ocd-person/d9426884-2195-45ee-8130-4a8cf0d61adf",
            "firstName": "Dale R.",
            "lastName": "Hawkins",
            "fullName": "Dale Hawkins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Hawkins7855.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "2B",
              "chamber": "lower",
              "hashID": "SLDL-ID-2B-lower-ocd-person-d9426884-2195-45ee-8130-4a8cf0d61adf",
              "builtID": "id-lower-2b",
              "externalID": "ocd-person/d9426884-2195-45ee-8130-4a8cf0d61adf",
              "geometry": null
            },
            "contactInfo": {
              "email": "dhawkins@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 245, Fernwood, ID 83830",
              "phone1": null,
              "phone2": "208-568-1275",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=2",
                "https://legislature.idaho.gov/legislators/membership/2023/id7855/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-2B-lower-ocd-person-d9426884-2195-45ee-8130-4a8cf0d61adf": 0
        }
      },
      "28B": {
        "members": [
          {
            "API_ID": "ocd-person/bae06244-d892-41b2-a4a5-beaaa6cba0da",
            "firstName": "Dan",
            "lastName": "Garner",
            "fullName": "Dan Garner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Garner7813.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "28B",
              "chamber": "lower",
              "hashID": "SLDL-ID-28B-lower-ocd-person-bae06244-d892-41b2-a4a5-beaaa6cba0da",
              "builtID": "id-lower-28b",
              "externalID": "ocd-person/bae06244-d892-41b2-a4a5-beaaa6cba0da",
              "geometry": null
            },
            "contactInfo": {
              "email": "dgarner@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 43, Clifton, ID 83228",
              "phone1": null,
              "phone2": "208-244-0999",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=28",
                "https://legislature.idaho.gov/legislators/membership/2023/id7813/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-28B-lower-ocd-person-bae06244-d892-41b2-a4a5-beaaa6cba0da": 0
        }
      },
      "30A": {
        "members": [
          {
            "API_ID": "ocd-person/20fe3cd5-d9e1-4d03-9247-9a1ccde862d3",
            "firstName": "David Maughan",
            "lastName": "Cannon",
            "fullName": "David Cannon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2021/directory/Cannon6699.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "30A",
              "chamber": "lower",
              "hashID": "SLDL-ID-30A-lower-ocd-person-20fe3cd5-d9e1-4d03-9247-9a1ccde862d3",
              "builtID": "id-lower-30a",
              "externalID": "ocd-person/20fe3cd5-d9e1-4d03-9247-9a1ccde862d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "dcannon@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "75 E. Judicial St., Blackfoot, ID 83221",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=31",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=31",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=30",
                "https://legislature.idaho.gov/legislators/membership/2023/id6699/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-30A-lower-ocd-person-20fe3cd5-d9e1-4d03-9247-9a1ccde862d3": 0
        }
      },
      "15B": {
        "members": [
          {
            "API_ID": "ocd-person/3caa9714-29b4-4851-b530-b696c1a87b41",
            "firstName": "Dori",
            "lastName": "Healey",
            "fullName": "Dori Healey",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Healey7861.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "15B",
              "chamber": "lower",
              "hashID": "SLDL-ID-15B-lower-ocd-person-3caa9714-29b4-4851-b530-b696c1a87b41",
              "builtID": "id-lower-15b",
              "externalID": "ocd-person/3caa9714-29b4-4851-b530-b696c1a87b41",
              "geometry": null
            },
            "contactInfo": {
              "email": "dhealey@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "8059 W. Preece Drive Suite 1106, Boise, ID 83704",
              "phone1": null,
              "phone2": "208-718-0464",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=15",
                "https://legislature.idaho.gov/legislators/membership/2024/id7861/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-15B-lower-ocd-person-3caa9714-29b4-4851-b530-b696c1a87b41": 0
        }
      },
      "27A": {
        "members": [
          {
            "API_ID": "ocd-person/6dbb9465-3ef5-4754-a482-08ae17c4cb9b",
            "firstName": "Douglas T.",
            "lastName": "Pickett",
            "fullName": "Doug Pickett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Pickett7810.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "27A",
              "chamber": "lower",
              "hashID": "SLDL-ID-27A-lower-ocd-person-6dbb9465-3ef5-4754-a482-08ae17c4cb9b",
              "builtID": "id-lower-27a",
              "externalID": "ocd-person/6dbb9465-3ef5-4754-a482-08ae17c4cb9b",
              "geometry": null
            },
            "contactInfo": {
              "email": "dpickett@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "625 W. 1700 South, Oakley, ID 83346",
              "phone1": null,
              "phone2": "208-862-3387",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=27",
                "https://legislature.idaho.gov/legislators/membership/2023/id7810/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-27A-lower-ocd-person-6dbb9465-3ef5-4754-a482-08ae17c4cb9b": 0
        }
      },
      "29A": {
        "members": [
          {
            "API_ID": "ocd-person/f7a93a62-fa0b-4d95-a9ad-10a938fd47a0",
            "firstName": "Dustin Whitney",
            "lastName": "Manwaring",
            "fullName": "Dustin Manwaring",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2021/directory/Manwaring4277.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "29A",
              "chamber": "lower",
              "hashID": "SLDL-ID-29A-lower-ocd-person-f7a93a62-fa0b-4d95-a9ad-10a938fd47a0",
              "builtID": "id-lower-29a",
              "externalID": "ocd-person/f7a93a62-fa0b-4d95-a9ad-10a938fd47a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "dmanwaring@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1469 W. Quinn Road, Pocatello, ID 83202",
              "phone1": null,
              "phone2": "208-252-5295",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=29",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=29",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=29",
                "https://legislature.idaho.gov/legislators/membership/2023/id4277/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-29A-lower-ocd-person-f7a93a62-fa0b-4d95-a9ad-10a938fd47a0": 0
        }
      },
      "14A": {
        "members": [
          {
            "API_ID": "ocd-person/ceec86a5-6519-452a-ba13-9f7ba2da84e5",
            "firstName": "Edward H.",
            "lastName": "Hill",
            "fullName": "Ted Hill",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Hill7859.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "14A",
              "chamber": "lower",
              "hashID": "SLDL-ID-14A-lower-ocd-person-ceec86a5-6519-452a-ba13-9f7ba2da84e5",
              "builtID": "id-lower-14a",
              "externalID": "ocd-person/ceec86a5-6519-452a-ba13-9f7ba2da84e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "thill@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2488 E. Windrose St., Eagle, ID 83616",
              "phone1": null,
              "phone2": "208-332-1124",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=14",
                "https://legislature.idaho.gov/legislators/membership/2023/id7859/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-14A-lower-ocd-person-ceec86a5-6519-452a-ba13-9f7ba2da84e5": 0
        }
      },
      "4B": {
        "members": [
          {
            "API_ID": "ocd-person/f6437e18-b7fb-4e53-8c40-a2423d82853d",
            "firstName": "Elaine",
            "lastName": "Price",
            "fullName": "Elaine Price",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Price7856.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "4B",
              "chamber": "lower",
              "hashID": "SLDL-ID-4B-lower-ocd-person-f6437e18-b7fb-4e53-8c40-a2423d82853d",
              "builtID": "id-lower-4b",
              "externalID": "ocd-person/f6437e18-b7fb-4e53-8c40-a2423d82853d",
              "geometry": null
            },
            "contactInfo": {
              "email": "eprice@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1107 E. Sherman Ave., Coeur d’Alene, ID 83814",
              "phone1": null,
              "phone2": "208-654-3228",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=4",
                "https://legislature.idaho.gov/legislators/membership/2023/id7856/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-4B-lower-ocd-person-f6437e18-b7fb-4e53-8c40-a2423d82853d": 0
        }
      },
      "25B": {
        "members": [
          {
            "API_ID": "ocd-person/6966d5dc-7b0e-4025-add5-ea59f06a45a2",
            "firstName": "Gregory L.",
            "lastName": "Lanting",
            "fullName": "Greg Lanting",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Lanting7809.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "25B",
              "chamber": "lower",
              "hashID": "SLDL-ID-25B-lower-ocd-person-6966d5dc-7b0e-4025-add5-ea59f06a45a2",
              "builtID": "id-lower-25b",
              "externalID": "ocd-person/6966d5dc-7b0e-4025-add5-ea59f06a45a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "glanting@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "355 Elm St. North Apartment 1, Twin Falls, ID 83301",
              "phone1": null,
              "phone2": "208-490-3511",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=25",
                "https://legislature.idaho.gov/legislators/membership/2023/id7809/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-25B-lower-ocd-person-6966d5dc-7b0e-4025-add5-ea59f06a45a2": 0
        }
      },
      "2A": {
        "members": [
          {
            "API_ID": "ocd-person/bd967aaf-a9d5-4bec-bf7c-cf0aa19d72ca",
            "firstName": "Heather",
            "lastName": "Scott",
            "fullName": "Heather Scott",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2019/directory/Scott3137.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "2A",
              "chamber": "lower",
              "hashID": "SLDL-ID-2A-lower-ocd-person-bd967aaf-a9d5-4bec-bf7c-cf0aa19d72ca",
              "builtID": "id-lower-2a",
              "externalID": "ocd-person/bd967aaf-a9d5-4bec-bf7c-cf0aa19d72ca",
              "geometry": null
            },
            "contactInfo": {
              "email": "hscott@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 134, Blanchard, ID 83804",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=1",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=1",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=2",
                "https://legislature.idaho.gov/legislators/membership/2023/id3137/",
                "https://repheatherscott.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-2A-lower-ocd-person-bd967aaf-a9d5-4bec-bf7c-cf0aa19d72ca": 0
        }
      },
      "18A": {
        "members": [
          {
            "API_ID": "ocd-person/8b37fbb7-bc05-4fd5-be33-a8940e489195",
            "firstName": "Ilana S.",
            "lastName": "Rubel",
            "fullName": "Ilana Rubel",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2017/directory/Rubel2888.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "18A",
              "chamber": "lower",
              "hashID": "SLDL-ID-18A-lower-ocd-person-8b37fbb7-bc05-4fd5-be33-a8940e489195",
              "builtID": "id-lower-18a",
              "externalID": "ocd-person/8b37fbb7-bc05-4fd5-be33-a8940e489195",
              "geometry": null
            },
            "contactInfo": {
              "email": "irubel@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2750 Migratory Drive, Boise, ID 83706",
              "phone1": null,
              "phone2": "208-866-4776",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=18",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=18",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=18",
                "https://legislature.idaho.gov/legislators/membership/2023/id2888/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-18A-lower-ocd-person-8b37fbb7-bc05-4fd5-be33-a8940e489195": 0
        }
      },
      "26B": {
        "members": [
          {
            "API_ID": "ocd-person/6e4d92d8-1f64-4284-95cf-d9c50e3d5730",
            "firstName": "Jack",
            "lastName": "Nelsen",
            "fullName": "Jack Nelsen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Nelsen7838.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "26B",
              "chamber": "lower",
              "hashID": "SLDL-ID-26B-lower-ocd-person-6e4d92d8-1f64-4284-95cf-d9c50e3d5730",
              "builtID": "id-lower-26b",
              "externalID": "ocd-person/6e4d92d8-1f64-4284-95cf-d9c50e3d5730",
              "geometry": null
            },
            "contactInfo": {
              "email": "jnelsen@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "253 E. 650 North, Jerome, ID 83338",
              "phone1": null,
              "phone2": "208-320-1921",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=26",
                "https://legislature.idaho.gov/legislators/membership/2023/id7838/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-26B-lower-ocd-person-6e4d92d8-1f64-4284-95cf-d9c50e3d5730": 0
        }
      },
      "9A": {
        "members": [
          {
            "API_ID": "ocd-person/d4aa3680-1836-418c-abe6-abdb07957cdb",
            "firstName": "Jacyn",
            "lastName": "Giesbers-Gallagher",
            "fullName": "Jacyn Gallagher",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Gallagher7801.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "9A",
              "chamber": "lower",
              "hashID": "SLDL-ID-9A-lower-ocd-person-d4aa3680-1836-418c-abe6-abdb07957cdb",
              "builtID": "id-lower-9a",
              "externalID": "ocd-person/d4aa3680-1836-418c-abe6-abdb07957cdb",
              "geometry": null
            },
            "contactInfo": {
              "email": "jgallagher@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "827 Pringle Road, Weiser, ID 83672",
              "phone1": null,
              "phone2": "208-960-0051",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=9",
                "https://legislature.idaho.gov/legislators/membership/2023/id7801/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-9A-lower-ocd-person-d4aa3680-1836-418c-abe6-abdb07957cdb": 0
        }
      },
      "20B": {
        "members": [
          {
            "API_ID": "ocd-person/28827442-9341-4768-86ea-a9186d718be0",
            "firstName": "James",
            "lastName": "Holtzclaw",
            "fullName": "James Holtzclaw",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2017/directory/Holtzclaw2086.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "20B",
              "chamber": "lower",
              "hashID": "SLDL-ID-20B-lower-ocd-person-28827442-9341-4768-86ea-a9186d718be0",
              "builtID": "id-lower-20b",
              "externalID": "ocd-person/28827442-9341-4768-86ea-a9186d718be0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jholtzclaw@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3720 N. Heritage View Ave., Meridian, ID 83646",
              "phone1": null,
              "phone2": "208-284-9542",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=20",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=20",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=20",
                "https://legislature.idaho.gov/legislators/membership/2023/id2086/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-20B-lower-ocd-person-28827442-9341-4768-86ea-a9186d718be0": 0
        }
      },
      "21A": {
        "members": [
          {
            "API_ID": "ocd-person/07e5711c-2e3d-4d26-a82a-6c710ed9bf3b",
            "firstName": "James",
            "lastName": "Petzke",
            "fullName": "James Petzke",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Petzke7863.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "21A",
              "chamber": "lower",
              "hashID": "SLDL-ID-21A-lower-ocd-person-07e5711c-2e3d-4d26-a82a-6c710ed9bf3b",
              "builtID": "id-lower-21a",
              "externalID": "ocd-person/07e5711c-2e3d-4d26-a82a-6c710ed9bf3b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jpetzke@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2628 S. Afleet Ave., Meridian, ID 83642",
              "phone1": null,
              "phone2": "208-450-5615",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=21",
                "https://legislature.idaho.gov/legislators/membership/2023/id7863/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-21A-lower-ocd-person-07e5711c-2e3d-4d26-a82a-6c710ed9bf3b": 0
        }
      },
      "12B": {
        "members": [
          {
            "API_ID": "ocd-person/da5babc3-b974-4f3c-a750-8989ab3a122b",
            "firstName": "Ronald Jaron",
            "lastName": "Crane",
            "fullName": "Jaron Crane",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Crane7803.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "12B",
              "chamber": "lower",
              "hashID": "SLDL-ID-12B-lower-ocd-person-da5babc3-b974-4f3c-a750-8989ab3a122b",
              "builtID": "id-lower-12b",
              "externalID": "ocd-person/da5babc3-b974-4f3c-a750-8989ab3a122b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jcrane@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1804, Nampa, ID 83653",
              "phone1": null,
              "phone2": "208-812-2059",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=12",
                "https://legislature.idaho.gov/legislators/membership/2023/id7803/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-12B-lower-ocd-person-da5babc3-b974-4f3c-a750-8989ab3a122b": 0
        }
      },
      "22B": {
        "members": [
          {
            "API_ID": "ocd-person/9808edb1-15b0-4b9b-91d9-91a97ff7bb4b",
            "firstName": "Jason A.",
            "lastName": "Monks",
            "fullName": "Jason Monks",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2017/directory/Monks2084.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "22B",
              "chamber": "lower",
              "hashID": "SLDL-ID-22B-lower-ocd-person-9808edb1-15b0-4b9b-91d9-91a97ff7bb4b",
              "builtID": "id-lower-22b",
              "externalID": "ocd-person/9808edb1-15b0-4b9b-91d9-91a97ff7bb4b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jmonks@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3865 S. Black Cat Road, Nampa, ID 83687",
              "phone1": null,
              "phone2": "208-884-8684",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=22",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=22",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=22",
                "https://legislature.idaho.gov/legislators/membership/2023/id2084/",
                "https://legislature.idaho.gov/legislators/membership/2024/id2084/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-22B-lower-ocd-person-9808edb1-15b0-4b9b-91d9-91a97ff7bb4b": 0
        }
      },
      "21B": {
        "members": [
          {
            "API_ID": "ocd-person/a0b66388-5039-4a2c-b022-ac2ece0ce865",
            "firstName": "Jeff",
            "lastName": "Ehlers",
            "fullName": "Jeff Ehlers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Ehlers7805.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "21B",
              "chamber": "lower",
              "hashID": "SLDL-ID-21B-lower-ocd-person-a0b66388-5039-4a2c-b022-ac2ece0ce865",
              "builtID": "id-lower-21b",
              "externalID": "ocd-person/a0b66388-5039-4a2c-b022-ac2ece0ce865",
              "geometry": null
            },
            "contactInfo": {
              "email": "jehlers@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3313 W. Cherry Lane #1024, Meridian, ID 83642",
              "phone1": null,
              "phone2": "208-278-2818",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=21",
                "https://legislature.idaho.gov/legislators/membership/2023/id7805/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-21B-lower-ocd-person-a0b66388-5039-4a2c-b022-ac2ece0ce865": 0
        }
      },
      "12A": {
        "members": [
          {
            "API_ID": "ocd-person/42d3b7d7-1a3a-4d58-ae68-a84cbaaf7ad0",
            "firstName": "Jeff J.",
            "lastName": "Cornilles",
            "fullName": "Jeff Cornilles",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Cornilles7802.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "12A",
              "chamber": "lower",
              "hashID": "SLDL-ID-12A-lower-ocd-person-42d3b7d7-1a3a-4d58-ae68-a84cbaaf7ad0",
              "builtID": "id-lower-12a",
              "externalID": "ocd-person/42d3b7d7-1a3a-4d58-ae68-a84cbaaf7ad0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jcornilles@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1313 W. Raelin Drive, Nampa, ID 83686",
              "phone1": null,
              "phone2": "208-880-3562",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=12",
                "https://legislature.idaho.gov/legislators/membership/2023/id7802/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-12A-lower-ocd-person-42d3b7d7-1a3a-4d58-ae68-a84cbaaf7ad0": 0
        }
      },
      "31A": {
        "members": [
          {
            "API_ID": "ocd-person/0147a633-d6d2-4005-9eb9-35dcf205718b",
            "firstName": "Jerald",
            "lastName": "Raymond",
            "fullName": "Jerald Raymond",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Raymond5382.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "31A",
              "chamber": "lower",
              "hashID": "SLDL-ID-31A-lower-ocd-person-0147a633-d6d2-4005-9eb9-35dcf205718b",
              "builtID": "id-lower-31a",
              "externalID": "ocd-person/0147a633-d6d2-4005-9eb9-35dcf205718b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jraymond@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3352 E. 750 North, Menan, ID 83434",
              "phone1": null,
              "phone2": "208-497-2745",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2019&district=35",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=31",
                "https://legislature.idaho.gov/legislators/membership/2023/id5382/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-31A-lower-ocd-person-0147a633-d6d2-4005-9eb9-35dcf205718b": 0
        }
      },
      "4A": {
        "members": [
          {
            "API_ID": "ocd-person/561516c4-4e4f-45bc-b1d0-6ed359006597",
            "firstName": "Joe",
            "lastName": "Alfieri",
            "fullName": "Joe Alfieri",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Alfieri7857.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "4A",
              "chamber": "lower",
              "hashID": "SLDL-ID-4A-lower-ocd-person-561516c4-4e4f-45bc-b1d0-6ed359006597",
              "builtID": "id-lower-4a",
              "externalID": "ocd-person/561516c4-4e4f-45bc-b1d0-6ed359006597",
              "geometry": null
            },
            "contactInfo": {
              "email": "jalfieri@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1200 E. Skyline Drive, Coeur d’Alene, ID 83814",
              "phone1": null,
              "phone2": "208-699-0021",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=4",
                "https://legislature.idaho.gov/legislators/membership/2023/id7857/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-4A-lower-ocd-person-561516c4-4e4f-45bc-b1d0-6ed359006597": 0
        }
      },
      "20A": {
        "members": [
          {
            "API_ID": "ocd-person/ed09bbbc-7b10-4ee8-be6b-a4dfcd7330ec",
            "firstName": "Joe A.",
            "lastName": "Palmer",
            "fullName": "Joe Palmer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Palmer749.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "20A",
              "chamber": "lower",
              "hashID": "SLDL-ID-20A-lower-ocd-person-ed09bbbc-7b10-4ee8-be6b-a4dfcd7330ec",
              "builtID": "id-lower-20a",
              "externalID": "ocd-person/ed09bbbc-7b10-4ee8-be6b-a4dfcd7330ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "jpalmer@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1524 N. Meridian Road, Meridian, ID 83642",
              "phone1": null,
              "phone2": "208-332-1062",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=20",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=20",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=20",
                "https://legislature.idaho.gov/legislators/membership/2023/id749/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-20A-lower-ocd-person-ed09bbbc-7b10-4ee8-be6b-a4dfcd7330ec": 0
        }
      },
      "17A": {
        "members": [
          {
            "API_ID": "ocd-person/048117e1-cf54-4ebf-9afc-f244d023e039",
            "firstName": "John L.",
            "lastName": "Gannon",
            "fullName": "John Gannon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2017/directory/Gannon2144.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "17A",
              "chamber": "lower",
              "hashID": "SLDL-ID-17A-lower-ocd-person-048117e1-cf54-4ebf-9afc-f244d023e039",
              "builtID": "id-lower-17a",
              "externalID": "ocd-person/048117e1-cf54-4ebf-9afc-f244d023e039",
              "geometry": null
            },
            "contactInfo": {
              "email": "jgannon@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1104 S. Johnson St., Boise, ID 83705",
              "phone1": null,
              "phone2": "208-343-1608",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=17",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=17",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=17",
                "https://legislature.idaho.gov/legislators/membership/2023/id2144/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-17A-lower-ocd-person-048117e1-cf54-4ebf-9afc-f244d023e039": 0
        }
      },
      "22A": {
        "members": [
          {
            "API_ID": "ocd-person/ed9290a6-47b1-4b24-8b61-0c0de9b45c73",
            "firstName": "John",
            "lastName": "Vander Woude",
            "fullName": "John Vander Woude",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2017/directory/Vander%20Woude67.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "22A",
              "chamber": "lower",
              "hashID": "SLDL-ID-22A-lower-ocd-person-ed9290a6-47b1-4b24-8b61-0c0de9b45c73",
              "builtID": "id-lower-22a",
              "externalID": "ocd-person/ed9290a6-47b1-4b24-8b61-0c0de9b45c73",
              "geometry": null
            },
            "contactInfo": {
              "email": "jvanderwoude@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5311 Ridgewood Road, Nampa, ID 83687",
              "phone1": null,
              "phone2": "208-888-4210",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=22",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=22",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=22",
                "https://legislature.idaho.gov/legislators/membership/2023/id67/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-22A-lower-ocd-person-ed9290a6-47b1-4b24-8b61-0c0de9b45c73": 0
        }
      },
      "34A": {
        "members": [
          {
            "API_ID": "ocd-person/4501fe8d-11d8-43ee-a9bd-5ba3a82eb5d4",
            "firstName": "Jon O.",
            "lastName": "Weber",
            "fullName": "Jon Weber",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2021/directory/Weber6709.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "34A",
              "chamber": "lower",
              "hashID": "SLDL-ID-34A-lower-ocd-person-4501fe8d-11d8-43ee-a9bd-5ba3a82eb5d4",
              "builtID": "id-lower-34a",
              "externalID": "ocd-person/4501fe8d-11d8-43ee-a9bd-5ba3a82eb5d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "jweber@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "64 E. Main St., Rexburg, ID 83440",
              "phone1": null,
              "phone2": "208-390-6128",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=34",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=34",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=34",
                "https://legislature.idaho.gov/legislators/membership/2023/id6709/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-34A-lower-ocd-person-4501fe8d-11d8-43ee-a9bd-5ba3a82eb5d4": 0
        }
      },
      "3B": {
        "members": [
          {
            "API_ID": "ocd-person/9cbfadc5-8393-444c-bb39-b1c79390d01b",
            "firstName": "Jordan",
            "lastName": "Redman",
            "fullName": "Jordan Redman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Redman7800.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "3B",
              "chamber": "lower",
              "hashID": "SLDL-ID-3B-lower-ocd-person-9cbfadc5-8393-444c-bb39-b1c79390d01b",
              "builtID": "id-lower-3b",
              "externalID": "ocd-person/9cbfadc5-8393-444c-bb39-b1c79390d01b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jredman@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1410 Lincoln Way Suite 100, Coeur d’Alene, ID 83814",
              "phone1": null,
              "phone2": "208-664-5263",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=3",
                "https://legislature.idaho.gov/legislators/membership/2023/id7800/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-3B-lower-ocd-person-9cbfadc5-8393-444c-bb39-b1c79390d01b": 0
        }
      },
      "14B": {
        "members": [
          {
            "API_ID": "ocd-person/188ce2db-4021-4af8-b562-c90bfab83ebf",
            "firstName": "Josh",
            "lastName": "Tanner",
            "fullName": "Josh Tanner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Tanner7860.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "14B",
              "chamber": "lower",
              "hashID": "SLDL-ID-14B-lower-ocd-person-188ce2db-4021-4af8-b562-c90bfab83ebf",
              "builtID": "id-lower-14b",
              "externalID": "ocd-person/188ce2db-4021-4af8-b562-c90bfab83ebf",
              "geometry": null
            },
            "contactInfo": {
              "email": "jtanner@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. BOX 125, Eagle, ID 83616",
              "phone1": null,
              "phone2": "208-781-5211",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=14",
                "https://legislature.idaho.gov/legislators/membership/2023/id7860/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-14B-lower-ocd-person-188ce2db-4021-4af8-b562-c90bfab83ebf": 0
        }
      },
      "35B": {
        "members": [
          {
            "API_ID": "ocd-person/647e5e14-6813-4fc7-a20d-be175157ffb0",
            "firstName": "Joshua",
            "lastName": "Wheeler",
            "fullName": "Josh Wheeler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Wheeler7839.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "35B",
              "chamber": "lower",
              "hashID": "SLDL-ID-35B-lower-ocd-person-647e5e14-6813-4fc7-a20d-be175157ffb0",
              "builtID": "id-lower-35b",
              "externalID": "ocd-person/647e5e14-6813-4fc7-a20d-be175157ffb0",
              "geometry": null
            },
            "contactInfo": {
              "email": "joshwheeler@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1849 S. Foothill Road, Ammon, ID 83401",
              "phone1": null,
              "phone2": "208-360-3926",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=35",
                "https://legislature.idaho.gov/legislators/membership/2023/id7839/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-35B-lower-ocd-person-647e5e14-6813-4fc7-a20d-be175157ffb0": 0
        }
      },
      "9B": {
        "members": [
          {
            "API_ID": "ocd-person/bcaace10-c9c4-4018-a87c-6112eeb33346",
            "firstName": "Judith Lea",
            "lastName": "Boyle",
            "fullName": "Judy Boyle",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2020/directory/Boyle745.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "9B",
              "chamber": "lower",
              "hashID": "SLDL-ID-9B-lower-ocd-person-bcaace10-c9c4-4018-a87c-6112eeb33346",
              "builtID": "id-lower-9b",
              "externalID": "ocd-person/bcaace10-c9c4-4018-a87c-6112eeb33346",
              "geometry": null
            },
            "contactInfo": {
              "email": "jboyle@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 57, Midvale, ID 83645",
              "phone1": null,
              "phone2": "208-355-3225",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=9",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=9",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=9",
                "https://legislature.idaho.gov/legislators/membership/2024/id745/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-9B-lower-ocd-person-bcaace10-c9c4-4018-a87c-6112eeb33346": 0
        }
      },
      "30B": {
        "members": [
          {
            "API_ID": "ocd-person/608abb2e-9393-4bbc-89a7-e3b3985f06a3",
            "firstName": "Julianne",
            "lastName": "Young",
            "fullName": "Julianne Young",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2019/directory/Young5385.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "30B",
              "chamber": "lower",
              "hashID": "SLDL-ID-30B-lower-ocd-person-608abb2e-9393-4bbc-89a7-e3b3985f06a3",
              "builtID": "id-lower-30b",
              "externalID": "ocd-person/608abb2e-9393-4bbc-89a7-e3b3985f06a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "jyoung@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "275 N. 400 West, Blackfoot, ID 83221",
              "phone1": null,
              "phone2": "208-201-1898",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=31",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=31",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=30",
                "https://legislature.idaho.gov/legislators/membership/2023/id5385/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-30B-lower-ocd-person-608abb2e-9393-4bbc-89a7-e3b3985f06a3": 0
        }
      },
      "11A": {
        "members": [
          {
            "API_ID": "ocd-person/fa10dd5d-545e-4a27-bb52-7117406844af",
            "firstName": "Julie",
            "lastName": "Yamamoto",
            "fullName": "Julie Yamamoto",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2022/directory/Yamamoto6710.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "11A",
              "chamber": "lower",
              "hashID": "SLDL-ID-11A-lower-ocd-person-fa10dd5d-545e-4a27-bb52-7117406844af",
              "builtID": "id-lower-11a",
              "externalID": "ocd-person/fa10dd5d-545e-4a27-bb52-7117406844af",
              "geometry": null
            },
            "contactInfo": {
              "email": "jyamamoto@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2619 S. Willow Brook Place, Caldwell, ID 83605",
              "phone1": null,
              "phone2": "208-989-4487",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=10",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=10",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=11",
                "https://legislature.idaho.gov/legislators/membership/2023/id6710/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-11A-lower-ocd-person-fa10dd5d-545e-4a27-bb52-7117406844af": 0
        }
      },
      "13B": {
        "members": [
          {
            "API_ID": "ocd-person/fb12cb10-55f5-46ce-964d-4a96232ab9fd",
            "firstName": "Kenny",
            "lastName": "Wroten",
            "fullName": "Kenny Wroten",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Wroten7804.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "13B",
              "chamber": "lower",
              "hashID": "SLDL-ID-13B-lower-ocd-person-fb12cb10-55f5-46ce-964d-4a96232ab9fd",
              "builtID": "id-lower-13b",
              "externalID": "ocd-person/fb12cb10-55f5-46ce-964d-4a96232ab9fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "kwroten@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 3324, Nampa, ID 83653",
              "phone1": null,
              "phone2": "208-989-8292",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=13",
                "https://legislature.idaho.gov/legislators/membership/2023/id7804/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-13B-lower-ocd-person-fb12cb10-55f5-46ce-964d-4a96232ab9fd": 0
        }
      },
      "35A": {
        "members": [
          {
            "API_ID": "ocd-person/4b8c165b-c49a-413c-846d-8578272d6be6",
            "firstName": "Kevin",
            "lastName": "Andrus",
            "fullName": "Kevin Andrus",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Andrus5367.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "35A",
              "chamber": "lower",
              "hashID": "SLDL-ID-35A-lower-ocd-person-4b8c165b-c49a-413c-846d-8578272d6be6",
              "builtID": "id-lower-35a",
              "externalID": "ocd-person/4b8c165b-c49a-413c-846d-8578272d6be6",
              "geometry": null
            },
            "contactInfo": {
              "email": "kandrus@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6948 E. Old Oregon Trail Road, Lava Hot Springs, ID 83246",
              "phone1": null,
              "phone2": "208-240-0201",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=28",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=28",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=35",
                "https://legislature.idaho.gov/legislators/membership/2023/id5367/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-35A-lower-ocd-person-4b8c165b-c49a-413c-846d-8578272d6be6": 0
        }
      },
      "25A": {
        "members": [
          {
            "API_ID": "ocd-person/2bec666b-1ca3-41dd-af37-9ae8225a83bd",
            "firstName": "Lance W.",
            "lastName": "Clow",
            "fullName": "Lance Clow",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2019/directory/Clow2107.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "25A",
              "chamber": "lower",
              "hashID": "SLDL-ID-25A-lower-ocd-person-2bec666b-1ca3-41dd-af37-9ae8225a83bd",
              "builtID": "id-lower-25a",
              "externalID": "ocd-person/2bec666b-1ca3-41dd-af37-9ae8225a83bd",
              "geometry": null
            },
            "contactInfo": {
              "email": "lclow@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2170 Bitterroot Drive, Twin Falls, ID 83301",
              "phone1": null,
              "phone2": "208-733-5767",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/house/membershipSingle.cfm?ID=2107",
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=24",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=24",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=25",
                "https://legislature.idaho.gov/legislators/membership/2023/id2107/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-25A-lower-ocd-person-2bec666b-1ca3-41dd-af37-9ae8225a83bd": 0
        }
      },
      "19A": {
        "members": [
          {
            "API_ID": "ocd-person/f2af3ae4-9051-488f-be6d-d87bcc832859",
            "firstName": "Lauren",
            "lastName": "Necochea",
            "fullName": "Lauren Necochea",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Necochea6315.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "19A",
              "chamber": "lower",
              "hashID": "SLDL-ID-19A-lower-ocd-person-f2af3ae4-9051-488f-be6d-d87bcc832859",
              "builtID": "id-lower-19a",
              "externalID": "ocd-person/f2af3ae4-9051-488f-be6d-d87bcc832859",
              "geometry": null
            },
            "contactInfo": {
              "email": "lnecochea@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1634, Boise, ID 83701",
              "phone1": null,
              "phone2": "208-332-1078",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=19",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=19",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=19",
                "https://legislature.idaho.gov/legislators/membership/2023/id6315/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-19A-lower-ocd-person-f2af3ae4-9051-488f-be6d-d87bcc832859": 0
        }
      },
      "6A": {
        "members": [
          {
            "API_ID": "ocd-person/5c049d3c-e605-4f3f-9eba-c91949d5f9f4",
            "firstName": "Lori",
            "lastName": "McCann",
            "fullName": "Lori McCann",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2021/directory/McCann6955.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "6A",
              "chamber": "lower",
              "hashID": "SLDL-ID-6A-lower-ocd-person-5c049d3c-e605-4f3f-9eba-c91949d5f9f4",
              "builtID": "id-lower-6a",
              "externalID": "ocd-person/5c049d3c-e605-4f3f-9eba-c91949d5f9f4",
              "geometry": null
            },
            "contactInfo": {
              "email": "lmccann@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1027 Bryden Ave., Lewiston, ID 83501",
              "phone1": null,
              "phone2": "208-743-5517",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=6",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=6",
                "https://legislature.idaho.gov/legislators/membership/2021/id6955/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-6A-lower-ocd-person-5c049d3c-e605-4f3f-9eba-c91949d5f9f4": 0
        }
      },
      "33B": {
        "members": [
          {
            "API_ID": "ocd-person/a9af8eba-9dc3-4961-9305-fa325cb86948",
            "firstName": "Marco Adam",
            "lastName": "Erickson",
            "fullName": "Marco Erickson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Erickson6700.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "33B",
              "chamber": "lower",
              "hashID": "SLDL-ID-33B-lower-ocd-person-a9af8eba-9dc3-4961-9305-fa325cb86948",
              "builtID": "id-lower-33b",
              "externalID": "ocd-person/a9af8eba-9dc3-4961-9305-fa325cb86948",
              "geometry": null
            },
            "contactInfo": {
              "email": "merickson@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "646 Crestview Ave., Idaho Falls, ID 83402",
              "phone1": null,
              "phone2": "208-241-5665",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=33",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=33",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=33",
                "https://legislature.idaho.gov/legislators/membership/2023/id6700/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-33B-lower-ocd-person-a9af8eba-9dc3-4961-9305-fa325cb86948": 0
        }
      },
      "1A": {
        "members": [
          {
            "API_ID": "ocd-person/92364a5c-40fe-4350-9604-5257be00caab",
            "firstName": "Mark",
            "lastName": "Sauter",
            "fullName": "Mark Sauter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Sauter7799.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "1A",
              "chamber": "lower",
              "hashID": "SLDL-ID-1A-lower-ocd-person-92364a5c-40fe-4350-9604-5257be00caab",
              "builtID": "id-lower-1a",
              "externalID": "ocd-person/92364a5c-40fe-4350-9604-5257be00caab",
              "geometry": null
            },
            "contactInfo": {
              "email": "msauter@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1031, Sandpoint, ID 83864",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=1",
                "https://legislature.idaho.gov/legislators/membership/2023/id7799/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-1A-lower-ocd-person-92364a5c-40fe-4350-9604-5257be00caab": 0
        }
      },
      "8A": {
        "members": [
          {
            "API_ID": "ocd-person/49932cc2-cad1-492c-a162-66a5b3aaf627",
            "firstName": "Matthew",
            "lastName": "Bundy",
            "fullName": "Matt Bundy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2021/directory/Bundy6698.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "8A",
              "chamber": "lower",
              "hashID": "SLDL-ID-8A-lower-ocd-person-49932cc2-cad1-492c-a162-66a5b3aaf627",
              "builtID": "id-lower-8a",
              "externalID": "ocd-person/49932cc2-cad1-492c-a162-66a5b3aaf627",
              "geometry": null
            },
            "contactInfo": {
              "email": "mbundy@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1735 Castle Way, Mountain Home, ID 83647",
              "phone1": null,
              "phone2": "208-599-2379",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=23",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=23",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=8",
                "https://legislature.idaho.gov/legislators/membership/2023/id6698/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-8A-lower-ocd-person-49932cc2-cad1-492c-a162-66a5b3aaf627": 0
        }
      },
      "8B": {
        "members": [
          {
            "API_ID": "ocd-person/a4e9fc0b-1f86-47aa-9175-c1c24d898493",
            "firstName": "Megan C.",
            "lastName": "Blanksma",
            "fullName": "Megan Blanksma",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2024/directory/Blanksma4283.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "8B",
              "chamber": "lower",
              "hashID": "SLDL-ID-8B-lower-ocd-person-a4e9fc0b-1f86-47aa-9175-c1c24d898493",
              "builtID": "id-lower-8b",
              "externalID": "ocd-person/a4e9fc0b-1f86-47aa-9175-c1c24d898493",
              "geometry": null
            },
            "contactInfo": {
              "email": "mblanksma@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "595 S. Thacker Road, Hammett, ID 83627",
              "phone1": null,
              "phone2": "208-366-7976",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=23",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=23",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=8",
                "https://legislature.idaho.gov/legislators/membership/2023/id4283/",
                "https://legislature.idaho.gov/legislators/membership/2024/id4283/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-8B-lower-ocd-person-a4e9fc0b-1f86-47aa-9175-c1c24d898493": 0
        }
      },
      "17B": {
        "members": [
          {
            "API_ID": "ocd-person/7abc0666-30bd-4e16-ba86-304b1750ac8e",
            "firstName": "Megan",
            "lastName": "Egbert",
            "fullName": "Megan Egbert",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2024/directory/Egbert9195.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "17B",
              "chamber": "lower",
              "hashID": "SLDL-ID-17B-lower-ocd-person-7abc0666-30bd-4e16-ba86-304b1750ac8e",
              "builtID": "id-lower-17b",
              "externalID": "ocd-person/7abc0666-30bd-4e16-ba86-304b1750ac8e",
              "geometry": null
            },
            "contactInfo": {
              "email": "megbert@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1630 S. Rand St., Boise, ID 83709",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [""],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-17B-lower-ocd-person-7abc0666-30bd-4e16-ba86-304b1750ac8e": 0
        }
      },
      "23A": {
        "members": [
          {
            "API_ID": "ocd-person/9beb01ba-61df-4ce0-9cc8-d3ed41970a3b",
            "firstName": "Melissa",
            "lastName": "Durrant",
            "fullName": "Melissa Durrant",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Durrant7806.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "23A",
              "chamber": "lower",
              "hashID": "SLDL-ID-23A-lower-ocd-person-9beb01ba-61df-4ce0-9cc8-d3ed41970a3b",
              "builtID": "id-lower-23a",
              "externalID": "ocd-person/9beb01ba-61df-4ce0-9cc8-d3ed41970a3b",
              "geometry": null
            },
            "contactInfo": {
              "email": "mdurrant@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4000 W. Hubbard Road, Kuna, ID 83634",
              "phone1": null,
              "phone2": "208-941-1963",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=23",
                "https://legislature.idaho.gov/legislators/membership/2023/id7806/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-23A-lower-ocd-person-9beb01ba-61df-4ce0-9cc8-d3ed41970a3b": 0
        }
      },
      "7A": {
        "members": [
          {
            "API_ID": "ocd-person/7f03914e-cfe2-482c-bbe1-d3d5e45db05d",
            "firstName": "Mike",
            "lastName": "Kingsley",
            "fullName": "Mike Kingsley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2017/directory/Kingsley4273.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "7A",
              "chamber": "lower",
              "hashID": "SLDL-ID-7A-lower-ocd-person-7f03914e-cfe2-482c-bbe1-d3d5e45db05d",
              "builtID": "id-lower-7a",
              "externalID": "ocd-person/7f03914e-cfe2-482c-bbe1-d3d5e45db05d",
              "geometry": null
            },
            "contactInfo": {
              "email": "mkingsley@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3413 Bluebird Circle, Lewiston, ID 83501",
              "phone1": null,
              "phone2": "208-791-8600",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=6",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=6",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=7",
                "https://legislature.idaho.gov/legislators/membership/2023/id4273/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-7A-lower-ocd-person-7f03914e-cfe2-482c-bbe1-d3d5e45db05d": 0
        }
      },
      "10A": {
        "members": [
          {
            "API_ID": "ocd-person/060e3496-76ff-4895-9637-4ff7bbe7a05e",
            "firstName": "Mike",
            "lastName": "Moyle",
            "fullName": "Mike Moyle",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2019/directory/Moyle43.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "10A",
              "chamber": "lower",
              "hashID": "SLDL-ID-10A-lower-ocd-person-060e3496-76ff-4895-9637-4ff7bbe7a05e",
              "builtID": "id-lower-10a",
              "externalID": "ocd-person/060e3496-76ff-4895-9637-4ff7bbe7a05e",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmoyle@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "480 N. Plummer Road, Star, ID 83669",
              "phone1": null,
              "phone2": "208-286-7842",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=14",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=14",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=10",
                "https://legislature.idaho.gov/legislators/membership/2023/id43/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-10A-lower-ocd-person-060e3496-76ff-4895-9637-4ff7bbe7a05e": 0
        }
      },
      "29B": {
        "members": [
          {
            "API_ID": "ocd-person/fcadafa6-b26d-4dac-b04c-1d817397f1c7",
            "firstName": "Nathan",
            "lastName": "Roberts",
            "fullName": "Nate Roberts",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Roberts7864.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "29B",
              "chamber": "lower",
              "hashID": "SLDL-ID-29B-lower-ocd-person-fcadafa6-b26d-4dac-b04c-1d817397f1c7",
              "builtID": "id-lower-29b",
              "externalID": "ocd-person/fcadafa6-b26d-4dac-b04c-1d817397f1c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "nroberts@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 2173, Pocatello, ID 83206",
              "phone1": null,
              "phone2": "208-269-1230",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=29",
                "https://legislature.idaho.gov/legislators/membership/2023/id7864/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-29B-lower-ocd-person-fcadafa6-b26d-4dac-b04c-1d817397f1c7": 0
        }
      },
      "26A": {
        "members": [
          {
            "API_ID": "ocd-person/37acc2f2-ea34-47c2-b354-df928d3cb761",
            "firstName": "Ned",
            "lastName": "Burns",
            "fullName": "Ned Burns",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2022/directory/Burns2824.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "26A",
              "chamber": "lower",
              "hashID": "SLDL-ID-26A-lower-ocd-person-37acc2f2-ea34-47c2-b354-df928d3cb761",
              "builtID": "id-lower-26a",
              "externalID": "ocd-person/37acc2f2-ea34-47c2-b354-df928d3cb761",
              "geometry": null
            },
            "contactInfo": {
              "email": "nburns@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 693, Bellevue, ID 83313",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=26",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=26",
                "https://legislature.idaho.gov/legislators/membership/2023/id2824/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-26A-lower-ocd-person-37acc2f2-ea34-47c2-b354-df928d3cb761": 0
        }
      },
      "28A": {
        "members": [
          {
            "API_ID": "ocd-person/bb03d309-94c3-4d63-bb69-ede616be8c72",
            "firstName": "Richard W.",
            "lastName": "Cheatum",
            "fullName": "Rick Cheatum",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Cheatum7812.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "28A",
              "chamber": "lower",
              "hashID": "SLDL-ID-28A-lower-ocd-person-bb03d309-94c3-4d63-bb69-ede616be8c72",
              "builtID": "id-lower-28a",
              "externalID": "ocd-person/bb03d309-94c3-4d63-bb69-ede616be8c72",
              "geometry": null
            },
            "contactInfo": {
              "email": "rcheatum@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2475 Ada St., Pocatello, ID 83201",
              "phone1": null,
              "phone2": "208-339-2629",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=28",
                "https://legislature.idaho.gov/legislators/membership/2023/id7812/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-28A-lower-ocd-person-bb03d309-94c3-4d63-bb69-ede616be8c72": 0
        }
      },
      "31B": {
        "members": [
          {
            "API_ID": "ocd-person/70eb5f7f-381b-400d-87b2-2bdeb6a1c7e7",
            "firstName": "Rodney G.",
            "lastName": "Furniss",
            "fullName": "Rod Furniss",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2019/directory/Furniss5372.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "31B",
              "chamber": "lower",
              "hashID": "SLDL-ID-31B-lower-ocd-person-70eb5f7f-381b-400d-87b2-2bdeb6a1c7e7",
              "builtID": "id-lower-31b",
              "externalID": "ocd-person/70eb5f7f-381b-400d-87b2-2bdeb6a1c7e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rfurniss@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "346 N. 4456 East, Rigby, ID 83442",
              "phone1": null,
              "phone2": "208-589-1100",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=35",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=35",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=31",
                "https://legislature.idaho.gov/legislators/membership/2023/id5372/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-31B-lower-ocd-person-70eb5f7f-381b-400d-87b2-2bdeb6a1c7e7": 0
        }
      },
      "5A": {
        "members": [
          {
            "API_ID": "ocd-person/71489ac3-457d-41ec-b285-a099648b601b",
            "firstName": "Ron",
            "lastName": "Mendive",
            "fullName": "Ron Mendive",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2019/directory/Mendive2124.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "5A",
              "chamber": "lower",
              "hashID": "SLDL-ID-5A-lower-ocd-person-71489ac3-457d-41ec-b285-a099648b601b",
              "builtID": "id-lower-5a",
              "externalID": "ocd-person/71489ac3-457d-41ec-b285-a099648b601b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rmendive@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3732 S. Dusty Lane, Coeur d'Alene, ID 83814",
              "phone1": null,
              "phone2": "208-667-9330",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=3",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=3",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=5",
                "https://legislature.idaho.gov/legislators/membership/2021/id2124/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-5A-lower-ocd-person-71489ac3-457d-41ec-b285-a099648b601b": 0
        }
      },
      "1B": {
        "members": [
          {
            "API_ID": "ocd-person/e5ee7c55-aa4e-4ce8-b3c8-5ab542f7a836",
            "firstName": "Sage G.",
            "lastName": "Dixon",
            "fullName": "Sage Dixon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2019/directory/Dixon3130.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "1B",
              "chamber": "lower",
              "hashID": "SLDL-ID-1B-lower-ocd-person-e5ee7c55-aa4e-4ce8-b3c8-5ab542f7a836",
              "builtID": "id-lower-1b",
              "externalID": "ocd-person/e5ee7c55-aa4e-4ce8-b3c8-5ab542f7a836",
              "geometry": null
            },
            "contactInfo": {
              "email": "sdixon@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 206, Ponderay, ID 83852",
              "phone1": null,
              "phone2": "208-610-4800",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=1",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=1",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=1",
                "https://legislature.idaho.gov/legislators/membership/2023/id3130/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-1B-lower-ocd-person-e5ee7c55-aa4e-4ce8-b3c8-5ab542f7a836": 0
        }
      },
      "16A": {
        "members": [
          {
            "API_ID": "ocd-person/b5b4cf71-98db-47f1-84ec-087a4b46641a",
            "firstName": "Sonia R.",
            "lastName": "Galaviz",
            "fullName": "Soñia Galaviz",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Galaviz7862.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "16A",
              "chamber": "lower",
              "hashID": "SLDL-ID-16A-lower-ocd-person-b5b4cf71-98db-47f1-84ec-087a4b46641a",
              "builtID": "id-lower-16a",
              "externalID": "ocd-person/b5b4cf71-98db-47f1-84ec-087a4b46641a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sgalaviz@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 7096, Boise, ID 83707",
              "phone1": null,
              "phone2": "208-332-1083",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=16",
                "https://legislature.idaho.gov/legislators/membership/2023/id7862/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-16A-lower-ocd-person-b5b4cf71-98db-47f1-84ec-087a4b46641a": 0
        }
      },
      "32A": {
        "members": [
          {
            "API_ID": "ocd-person/94248975-397a-4e5c-a544-40cb7a3f06d0",
            "firstName": "Stephanie Jo",
            "lastName": "Mickelsen",
            "fullName": "Stephanie Mickelsen",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Mickelsen7814.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "32A",
              "chamber": "lower",
              "hashID": "SLDL-ID-32A-lower-ocd-person-94248975-397a-4e5c-a544-40cb7a3f06d0",
              "builtID": "id-lower-32a",
              "externalID": "ocd-person/94248975-397a-4e5c-a544-40cb7a3f06d0",
              "geometry": null
            },
            "contactInfo": {
              "email": "smickelsen@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "9088 N. River Road, Idaho Falls, ID 83402",
              "phone1": null,
              "phone2": "208-715-7770",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=32",
                "https://legislature.idaho.gov/legislators/membership/2023/id7814/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-32A-lower-ocd-person-94248975-397a-4e5c-a544-40cb7a3f06d0": 0
        }
      },
      "15A": {
        "members": [
          {
            "API_ID": "ocd-person/0c413128-0ec7-4228-aa8b-93572d153c6f",
            "firstName": "Steve",
            "lastName": "Berch",
            "fullName": "Steve Berch",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2019/directory/Berch5368.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "15A",
              "chamber": "lower",
              "hashID": "SLDL-ID-15A-lower-ocd-person-0c413128-0ec7-4228-aa8b-93572d153c6f",
              "builtID": "id-lower-15a",
              "externalID": "ocd-person/0c413128-0ec7-4228-aa8b-93572d153c6f",
              "geometry": null
            },
            "contactInfo": {
              "email": "sberch@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 4903, Boise, ID 83711",
              "phone1": null,
              "phone2": "208-890-9339",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=15",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=15",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=15",
                "https://legislature.idaho.gov/legislators/membership/2023/id5368/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-15A-lower-ocd-person-0c413128-0ec7-4228-aa8b-93572d153c6f": 0
        }
      },
      "24B": {
        "members": [
          {
            "API_ID": "ocd-person/983607b1-0987-4efe-8547-de1c5d262e5f",
            "firstName": "Steven",
            "lastName": "Miller",
            "fullName": "Steve Miller",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Miller2134.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "24B",
              "chamber": "lower",
              "hashID": "SLDL-ID-24B-lower-ocd-person-983607b1-0987-4efe-8547-de1c5d262e5f",
              "builtID": "id-lower-24b",
              "externalID": "ocd-person/983607b1-0987-4efe-8547-de1c5d262e5f",
              "geometry": null
            },
            "contactInfo": {
              "email": "smiller@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1208 E. 200 North, Fairfield, ID 83327",
              "phone1": null,
              "phone2": "208-332-1061",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2018&district=26",
                "https://legislature.idaho.gov/house/membershipSingle.cfm?ID=2134",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=24",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=24",
                "https://legislature.idaho.gov/legislators/membership/2023/id2134/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-24B-lower-ocd-person-983607b1-0987-4efe-8547-de1c5d262e5f": 0
        }
      },
      "23B": {
        "members": [
          {
            "API_ID": "ocd-person/0cbcbc9d-8eed-4213-b2e0-4cdef05968c3",
            "firstName": "Tina Sorenson",
            "lastName": "Lambert",
            "fullName": "Tina Lambert",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Lambert7807.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "23B",
              "chamber": "lower",
              "hashID": "SLDL-ID-23B-lower-ocd-person-0cbcbc9d-8eed-4213-b2e0-4cdef05968c3",
              "builtID": "id-lower-23b",
              "externalID": "ocd-person/0cbcbc9d-8eed-4213-b2e0-4cdef05968c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "tlambert@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1324, Caldwell, ID 83606",
              "phone1": null,
              "phone2": "208-504-8647",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=23",
                "https://legislature.idaho.gov/legislators/membership/2023/id7807/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-23B-lower-ocd-person-0cbcbc9d-8eed-4213-b2e0-4cdef05968c3": 0
        }
      },
      "16B": {
        "members": [
          {
            "API_ID": "ocd-person/a4be8d97-a687-4eb2-b412-c4f5f46553f6",
            "firstName": "Theodore",
            "lastName": "Achilles",
            "fullName": "Todd Achilles",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://npr.brightspotcdn.com/dims4/default/c9004bf/2147483647/strip/true/crop/270x360+16+0/resize/300x400!/format/webp/quality/90/?url=http%3A%2F%2Fnpr-brightspot.s3.amazonaws.com%2Ff0%2F20%2F9032cf984a71a2faeebbd7618fa5%2Ftodd-cab.png",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "16B",
              "chamber": "lower",
              "hashID": "SLDL-ID-16B-lower-ocd-person-a4be8d97-a687-4eb2-b412-c4f5f46553f6",
              "builtID": "id-lower-16b",
              "externalID": "ocd-person/a4be8d97-a687-4eb2-b412-c4f5f46553f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "tachilles@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2311 N. 32nd St., Boise, ID 83703",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/2024/id9096/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-16B-lower-ocd-person-a4be8d97-a687-4eb2-b412-c4f5f46553f6": 0
        }
      },
      "5B": {
        "members": [
          {
            "API_ID": "ocd-person/ee4e19dc-d39c-4a93-b466-346b2e822f86",
            "firstName": "Tony",
            "lastName": "Wisniewski",
            "fullName": "Tony Wisniewski",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2019/directory/Wisniewski5384.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "5B",
              "chamber": "lower",
              "hashID": "SLDL-ID-5B-lower-ocd-person-ee4e19dc-d39c-4a93-b466-346b2e822f86",
              "builtID": "id-lower-5b",
              "externalID": "ocd-person/ee4e19dc-d39c-4a93-b466-346b2e822f86",
              "geometry": null
            },
            "contactInfo": {
              "email": "twisniewski@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 2483, Post Falls, ID 83854",
              "phone1": null,
              "phone2": "208-889-3437",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=3",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=3",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=5",
                "https://legislature.idaho.gov/legislators/membership/2023/id5384/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-5B-lower-ocd-person-ee4e19dc-d39c-4a93-b466-346b2e822f86": 0
        }
      },
      "3A": {
        "members": [
          {
            "API_ID": "ocd-person/1abcdba2-7ed6-4871-889e-9e4c47c4d000",
            "firstName": "James Vito",
            "lastName": "Barbieri",
            "fullName": "Vito Barbieri",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2019/directory/Barbieri1171.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "3A",
              "chamber": "lower",
              "hashID": "SLDL-ID-3A-lower-ocd-person-1abcdba2-7ed6-4871-889e-9e4c47c4d000",
              "builtID": "id-lower-3a",
              "externalID": "ocd-person/1abcdba2-7ed6-4871-889e-9e4c47c4d000",
              "geometry": null
            },
            "contactInfo": {
              "email": "vbar@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "564 E. Prairie Ave., Dalton Gardens, ID 83815",
              "phone1": null,
              "phone2": "208-620-0873",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=2",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=2",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=3",
                "https://legislature.idaho.gov/legislators/membership/2023/id1171/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-3A-lower-ocd-person-1abcdba2-7ed6-4871-889e-9e4c47c4d000": 0
        }
      },
      "32B": {
        "members": [
          {
            "API_ID": "ocd-person/8a220a7f-f629-493c-81e8-5f56d40440a9",
            "firstName": "Wendy Stokes",
            "lastName": "Horman",
            "fullName": "Wendy Horman",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2019/directory/Horman2146.jpg",
            "title": "ID Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "32B",
              "chamber": "lower",
              "hashID": "SLDL-ID-32B-lower-ocd-person-8a220a7f-f629-493c-81e8-5f56d40440a9",
              "builtID": "id-lower-32b",
              "externalID": "ocd-person/8a220a7f-f629-493c-81e8-5f56d40440a9",
              "geometry": null
            },
            "contactInfo": {
              "email": "wendyhorman@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1860 Heather Circle, Idaho Falls, ID 83406",
              "phone1": null,
              "phone2": "208-522-4387",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=30",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=30",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=32",
                "https://legislature.idaho.gov/legislators/membership/2023/id2146/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ID-32B-lower-ocd-person-8a220a7f-f629-493c-81e8-5f56d40440a9": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/64cb5084-5526-4f03-8e7c-526b1589a2c5",
            "firstName": "Scott",
            "lastName": "Herndon",
            "fullName": "Scott Herndon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Herndon7775.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-ID-1-upper-ocd-person-64cb5084-5526-4f03-8e7c-526b1589a2c5",
              "builtID": "id-upper-1",
              "externalID": "ocd-person/64cb5084-5526-4f03-8e7c-526b1589a2c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "sherndon@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "246 Otts Road, Sagle, ID 83860",
              "phone1": null,
              "phone2": "208-610-2680",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=1",
                "https://legislature.idaho.gov/legislators/membership/2023/id7775/",
                "https://legislature.idaho.gov/legislators/membership/2024/id7775/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-1-upper-ocd-person-64cb5084-5526-4f03-8e7c-526b1589a2c5": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/202344ed-fa6e-4d6d-b746-516abb5c962d",
            "firstName": "Phil",
            "lastName": "Hart",
            "fullName": "Phil Hart",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Hart26.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-ID-2-upper-ocd-person-202344ed-fa6e-4d6d-b746-516abb5c962d",
              "builtID": "id-upper-2",
              "externalID": "ocd-person/202344ed-fa6e-4d6d-b746-516abb5c962d",
              "geometry": null
            },
            "contactInfo": {
              "email": "phil.hart@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 540, Kellogg, ID 83837",
              "phone1": null,
              "phone2": "208-772-2522",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=2",
                "https://legislature.idaho.gov/legislators/membership/2023/id26/",
                "https://legislature.idaho.gov/legislators/membership/2024/id26/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-2-upper-ocd-person-202344ed-fa6e-4d6d-b746-516abb5c962d": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/db1a3f87-5a7e-4133-a36d-5b319d28d38c",
            "firstName": "Douglas Martin",
            "lastName": "Okuniewicz",
            "fullName": "Doug Okuniewicz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Okuniewicz6706.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-ID-3-upper-ocd-person-db1a3f87-5a7e-4133-a36d-5b319d28d38c",
              "builtID": "id-upper-3",
              "externalID": "ocd-person/db1a3f87-5a7e-4133-a36d-5b319d28d38c",
              "geometry": null
            },
            "contactInfo": {
              "email": "dougo@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 810, Hayden, ID 83835",
              "phone1": null,
              "phone2": "208-918-1495",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=2",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=2",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=3",
                "https://legislature.idaho.gov/legislators/membership/2023/id6706/",
                "https://legislature.idaho.gov/legislators/membership/2024/id6706/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-3-upper-ocd-person-db1a3f87-5a7e-4133-a36d-5b319d28d38c": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/a0bec144-a980-4ce8-9e02-ada83079a300",
            "firstName": "Ben",
            "lastName": "Toews",
            "fullName": "Ben Toews",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Toews7795.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-ID-4-upper-ocd-person-a0bec144-a980-4ce8-9e02-ada83079a300",
              "builtID": "id-upper-4",
              "externalID": "ocd-person/a0bec144-a980-4ce8-9e02-ada83079a300",
              "geometry": null
            },
            "contactInfo": {
              "email": "btoews@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "911 N. 5th St., Coeur d’Alene, ID 83814",
              "phone1": null,
              "phone2": "208-209-7791",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=4",
                "https://legislature.idaho.gov/legislators/membership/2023/id7795/",
                "https://legislature.idaho.gov/legislators/membership/2024/id7795/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-4-upper-ocd-person-a0bec144-a980-4ce8-9e02-ada83079a300": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/a1af9e8e-8f2c-4b6a-a7ca-708a0d0bf6f2",
            "firstName": "Carl J.",
            "lastName": "Bjerke",
            "fullName": "Carl Bjerke",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Bjerke7796.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-ID-5-upper-ocd-person-a1af9e8e-8f2c-4b6a-a7ca-708a0d0bf6f2",
              "builtID": "id-upper-5",
              "externalID": "ocd-person/a1af9e8e-8f2c-4b6a-a7ca-708a0d0bf6f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "cbjerke@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "212 W. Ironwood Drive Suite D, #242, Coeur d’Alene, ID 83814",
              "phone1": null,
              "phone2": "208-704-0925",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=5",
                "https://legislature.idaho.gov/legislators/membership/2023/id7796/",
                "https://legislature.idaho.gov/legislators/membership/2024/id7796/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-5-upper-ocd-person-a1af9e8e-8f2c-4b6a-a7ca-708a0d0bf6f2": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/04ccb3bd-ae8d-4a64-a6c2-1b63a6f94d15",
            "firstName": "Daniel D.",
            "lastName": "Foreman",
            "fullName": "Dan Foreman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Foreman4269.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-ID-6-upper-ocd-person-04ccb3bd-ae8d-4a64-a6c2-1b63a6f94d15",
              "builtID": "id-upper-6",
              "externalID": "ocd-person/04ccb3bd-ae8d-4a64-a6c2-1b63a6f94d15",
              "geometry": null
            },
            "contactInfo": {
              "email": "dforeman@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 8254, Moscow, ID 83843",
              "phone1": null,
              "phone2": "208-332-1405",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2018&district=5",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=6",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=6",
                "https://legislature.idaho.gov/legislators/membership/2023/id4269/",
                "https://legislature.idaho.gov/legislators/membership/2024/id4269/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-6-upper-ocd-person-04ccb3bd-ae8d-4a64-a6c2-1b63a6f94d15": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/4f02e826-da06-4286-8aaf-b89ef3f659bc",
            "firstName": "Cindy J.",
            "lastName": "Carlson",
            "fullName": "Cindy Carlson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Carlson7776.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-ID-7-upper-ocd-person-4f02e826-da06-4286-8aaf-b89ef3f659bc",
              "builtID": "id-upper-7",
              "externalID": "ocd-person/4f02e826-da06-4286-8aaf-b89ef3f659bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "ccarlson@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1525 Big Salmon Road, Riggins, ID 83549",
              "phone1": null,
              "phone2": "208-494-2633",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=7",
                "https://legislature.idaho.gov/legislators/membership/2023/id7776/",
                "https://legislature.idaho.gov/legislators/membership/2024/id7776/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-7-upper-ocd-person-4f02e826-da06-4286-8aaf-b89ef3f659bc": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/f2ab750a-6588-4339-92b0-ad4281d38c7c",
            "firstName": "Geoffrey",
            "lastName": "Schroeder",
            "fullName": "Geoff Schroeder",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Schroeder7777.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-ID-8-upper-ocd-person-f2ab750a-6588-4339-92b0-ad4281d38c7c",
              "builtID": "id-upper-8",
              "externalID": "ocd-person/f2ab750a-6588-4339-92b0-ad4281d38c7c",
              "geometry": null
            },
            "contactInfo": {
              "email": "gschroeder@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "300 S. 10th East, Mountain Home, ID 83647",
              "phone1": null,
              "phone2": "208-332-1344",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=8",
                "https://legislature.idaho.gov/legislators/membership/2023/id7777/",
                "https://legislature.idaho.gov/legislators/membership/2024/id7777/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-8-upper-ocd-person-f2ab750a-6588-4339-92b0-ad4281d38c7c": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/846c0e7d-ec02-4f49-afb9-3604dd27da98",
            "firstName": "Tammy",
            "lastName": "Nichols",
            "fullName": "Tammy Nichols",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Nichols5380.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-ID-10-upper-ocd-person-846c0e7d-ec02-4f49-afb9-3604dd27da98",
              "builtID": "id-upper-10",
              "externalID": "ocd-person/846c0e7d-ec02-4f49-afb9-3604dd27da98",
              "geometry": null
            },
            "contactInfo": {
              "email": "tnichols@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "10 S. Hawthorne Drive #651, Middleton, ID 83644",
              "phone1": null,
              "phone2": "208-917-2409",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=11",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=11",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=10",
                "https://legislature.idaho.gov/legislators/membership/2023/id5380/",
                "https://legislature.idaho.gov/legislators/membership/2024/id5380/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-10-upper-ocd-person-846c0e7d-ec02-4f49-afb9-3604dd27da98": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/710ae051-7262-42ed-a1b3-2d6269fe3330",
            "firstName": "Christopher T.",
            "lastName": "Trakel",
            "fullName": "Chris Trakel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Trakel7815.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-ID-11-upper-ocd-person-710ae051-7262-42ed-a1b3-2d6269fe3330",
              "builtID": "id-upper-11",
              "externalID": "ocd-person/710ae051-7262-42ed-a1b3-2d6269fe3330",
              "geometry": null
            },
            "contactInfo": {
              "email": "ctrakel@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 315, Caldwell, ID 83606",
              "phone1": null,
              "phone2": "986-210-7653",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=11",
                "https://legislature.idaho.gov/legislators/membership/2023/id7815/",
                "https://legislature.idaho.gov/legislators/membership/2024/id7815/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-11-upper-ocd-person-710ae051-7262-42ed-a1b3-2d6269fe3330": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/02a86541-b10a-42cc-b260-0d0f5e3226c0",
            "firstName": "Benjamin D.",
            "lastName": "Adams",
            "fullName": "Ben Adams",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Adams6685.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-ID-12-upper-ocd-person-02a86541-b10a-42cc-b260-0d0f5e3226c0",
              "builtID": "id-upper-12",
              "externalID": "ocd-person/02a86541-b10a-42cc-b260-0d0f5e3226c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "badams@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1921 Hoover St., Nampa, ID 83686",
              "phone1": null,
              "phone2": "208-546-9393",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=13",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=13",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=12",
                "https://legislature.idaho.gov/legislators/membership/2023/id6685/",
                "https://legislature.idaho.gov/legislators/membership/2024/id6685/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-12-upper-ocd-person-02a86541-b10a-42cc-b260-0d0f5e3226c0": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/e89ea88e-7519-48fe-8cb9-65ea18d0dd7b",
            "firstName": "Brian",
            "lastName": "Lenney",
            "fullName": "Brian Lenney",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Lenney7778.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-ID-13-upper-ocd-person-e89ea88e-7519-48fe-8cb9-65ea18d0dd7b",
              "builtID": "id-upper-13",
              "externalID": "ocd-person/e89ea88e-7519-48fe-8cb9-65ea18d0dd7b",
              "geometry": null
            },
            "contactInfo": {
              "email": "blenney@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 3753, Nampa, ID 83653",
              "phone1": null,
              "phone2": "208-614-1289",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=13",
                "https://legislature.idaho.gov/legislators/membership/2023/id7778/",
                "https://legislature.idaho.gov/legislators/membership/2024/id7778/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-13-upper-ocd-person-e89ea88e-7519-48fe-8cb9-65ea18d0dd7b": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/f67b55ee-1cc0-431b-b950-4b5dfca332ad",
            "firstName": "Cecil Scott",
            "lastName": "Grow",
            "fullName": "Scott Grow",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Grow5126.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-ID-14-upper-ocd-person-f67b55ee-1cc0-431b-b950-4b5dfca332ad",
              "builtID": "id-upper-14",
              "externalID": "ocd-person/f67b55ee-1cc0-431b-b950-4b5dfca332ad",
              "geometry": null
            },
            "contactInfo": {
              "email": "sgrow@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4250 W. Sugarberry Court, Eagle, ID 83616",
              "phone1": null,
              "phone2": "208-870-4000",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=14",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=14",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=14",
                "https://legislature.idaho.gov/legislators/membership/2023/id5126/",
                "https://legislature.idaho.gov/legislators/membership/2024/id5126/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-14-upper-ocd-person-f67b55ee-1cc0-431b-b950-4b5dfca332ad": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/ad6e2f13-a3f5-4477-ada3-3ead88dd84ee",
            "firstName": "James Richard",
            "lastName": "Just",
            "fullName": "Rick Just",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Just7835.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-ID-15-upper-ocd-person-ad6e2f13-a3f5-4477-ada3-3ead88dd84ee",
              "builtID": "id-upper-15",
              "externalID": "ocd-person/ad6e2f13-a3f5-4477-ada3-3ead88dd84ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "rjust@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "11544 W. Jenilyn Court, Boise, ID 83713",
              "phone1": null,
              "phone2": "208-362-9892",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=15",
                "https://legislature.idaho.gov/legislators/membership/2023/id7835/",
                "https://legislature.idaho.gov/legislators/membership/2024/id7835/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-15-upper-ocd-person-ad6e2f13-a3f5-4477-ada3-3ead88dd84ee": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/c77bc461-d0fe-47b4-8999-72e4eda69da1",
            "firstName": "Alison M.",
            "lastName": "Rabe",
            "fullName": "Ali Rabe",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Rabe6687.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-ID-16-upper-ocd-person-c77bc461-d0fe-47b4-8999-72e4eda69da1",
              "builtID": "id-upper-16",
              "externalID": "ocd-person/c77bc461-d0fe-47b4-8999-72e4eda69da1",
              "geometry": null
            },
            "contactInfo": {
              "email": "arabe@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6161 W. Plantation Lane, Boise, ID 83703",
              "phone1": null,
              "phone2": "208-283-4010",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=17",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=16",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=16",
                "https://legislature.idaho.gov/legislators/membership/2023/id6687/",
                "https://legislature.idaho.gov/legislators/membership/2024/id6687/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-16-upper-ocd-person-c77bc461-d0fe-47b4-8999-72e4eda69da1": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/34bec23f-67e3-4940-8f5e-29850f0e9170",
            "firstName": "Carrie",
            "lastName": "Semmelroth",
            "fullName": "Carrie Semmelroth",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2022/directory/Semmelroth7115.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-ID-17-upper-ocd-person-34bec23f-67e3-4940-8f5e-29850f0e9170",
              "builtID": "id-upper-17",
              "externalID": "ocd-person/34bec23f-67e3-4940-8f5e-29850f0e9170",
              "geometry": null
            },
            "contactInfo": {
              "email": "csemmelroth@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4224 W. Fairmont St., Boise, ID 83706",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=17",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=17",
                "https://legislature.idaho.gov/legislators/membership/2023/id7115/",
                "https://legislature.idaho.gov/legislators/membership/2024/id7115/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-17-upper-ocd-person-34bec23f-67e3-4940-8f5e-29850f0e9170": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/c3b6822b-8900-408d-8745-7066b79e1e52",
            "firstName": "Janie",
            "lastName": "Ward-Engelking",
            "fullName": "Janie Ward-Engelking",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Ward-Engelking2091.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-ID-18-upper-ocd-person-c3b6822b-8900-408d-8745-7066b79e1e52",
              "builtID": "id-upper-18",
              "externalID": "ocd-person/c3b6822b-8900-408d-8745-7066b79e1e52",
              "geometry": null
            },
            "contactInfo": {
              "email": "jwardengelking@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3578 S. Crosspoint Ave., Boise, ID 83706",
              "phone1": null,
              "phone2": "208-861-6434",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=18",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=18",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=18",
                "https://legislature.idaho.gov/legislators/membership/2023/id2091/",
                "https://legislature.idaho.gov/legislators/membership/2024/id2091/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-18-upper-ocd-person-c3b6822b-8900-408d-8745-7066b79e1e52": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/c6b06b7d-05f8-45a3-8415-4cd1e76c5642",
            "firstName": "Melissa",
            "lastName": "Wintrow",
            "fullName": "Melissa Wintrow",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2021/directory/Wintrow3139.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-ID-19-upper-ocd-person-c6b06b7d-05f8-45a3-8415-4cd1e76c5642",
              "builtID": "id-upper-19",
              "externalID": "ocd-person/c6b06b7d-05f8-45a3-8415-4cd1e76c5642",
              "geometry": null
            },
            "contactInfo": {
              "email": "mwintrow@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=19",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=19",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=19",
                "https://legislature.idaho.gov/legislators/membership/2023/id3139/",
                "https://legislature.idaho.gov/legislators/membership/2024/id3139/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-19-upper-ocd-person-c6b06b7d-05f8-45a3-8415-4cd1e76c5642": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/9a87e02d-3c35-4d39-99cb-3c4c7d112b22",
            "firstName": "Chuck",
            "lastName": "Winder",
            "fullName": "Chuck Winder",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Winder753.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-ID-20-upper-ocd-person-9a87e02d-3c35-4d39-99cb-3c4c7d112b22",
              "builtID": "id-upper-20",
              "externalID": "ocd-person/9a87e02d-3c35-4d39-99cb-3c4c7d112b22",
              "geometry": null
            },
            "contactInfo": {
              "email": "cwinder@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5528 N. Ebbetts Ave., Boise, ID 83713",
              "phone1": null,
              "phone2": "208-332-1301",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=20",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=20",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=20",
                "https://legislature.idaho.gov/legislators/membership/2023/id753/",
                "https://legislature.idaho.gov/legislators/membership/2024/id753/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-20-upper-ocd-person-9a87e02d-3c35-4d39-99cb-3c4c7d112b22": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/562def54-c2da-4b6a-bdfa-5038a4c04e0c",
            "firstName": "Treg A.",
            "lastName": "Bernt",
            "fullName": "Treg Bernt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Bernt7797.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-ID-21-upper-ocd-person-562def54-c2da-4b6a-bdfa-5038a4c04e0c",
              "builtID": "id-upper-21",
              "externalID": "ocd-person/562def54-c2da-4b6a-bdfa-5038a4c04e0c",
              "geometry": null
            },
            "contactInfo": {
              "email": "tbernt@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "531 S. Tiburon Ave., Meridian, ID 83642",
              "phone1": null,
              "phone2": "208-917-2883",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=21",
                "https://legislature.idaho.gov/legislators/membership/2023/id7797/",
                "https://legislature.idaho.gov/legislators/membership/2024/id7797/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-21-upper-ocd-person-562def54-c2da-4b6a-bdfa-5038a4c04e0c": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/d3ee525f-429f-4ac6-ba52-e947881a3e6c",
            "firstName": "Lori",
            "lastName": "Den Hartog",
            "fullName": "Lori Den Hartog",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2017/directory/Den%20Hartog3124.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-ID-22-upper-ocd-person-d3ee525f-429f-4ac6-ba52-e947881a3e6c",
              "builtID": "id-upper-22",
              "externalID": "ocd-person/d3ee525f-429f-4ac6-ba52-e947881a3e6c",
              "geometry": null
            },
            "contactInfo": {
              "email": "ldenhartog@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 267, Meridian, ID 83680",
              "phone1": null,
              "phone2": "208-779-2022",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=22",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=22",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=22",
                "https://legislature.idaho.gov/legislators/membership/2023/id3124/",
                "https://legislature.idaho.gov/legislators/membership/2024/id3124/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-22-upper-ocd-person-d3ee525f-429f-4ac6-ba52-e947881a3e6c": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/0b894582-b3d6-4600-a401-ed874e8c632d",
            "firstName": "Todd M.",
            "lastName": "Lakey",
            "fullName": "Todd Lakey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Lakey2132.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-ID-23-upper-ocd-person-0b894582-b3d6-4600-a401-ed874e8c632d",
              "builtID": "id-upper-23",
              "externalID": "ocd-person/0b894582-b3d6-4600-a401-ed874e8c632d",
              "geometry": null
            },
            "contactInfo": {
              "email": "tlakey@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "12905 Venezia Court, Nampa, ID 83651",
              "phone1": null,
              "phone2": "208-908-4415",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=12",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=12",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=23",
                "https://legislature.idaho.gov/legislators/membership/2023/id2132/",
                "https://legislature.idaho.gov/legislators/membership/2024/id2132/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-23-upper-ocd-person-0b894582-b3d6-4600-a401-ed874e8c632d": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/7ba08b82-5bfc-4b36-b036-28b04c0d8bd2",
            "firstName": "Glenneda Russell",
            "lastName": "Zuiderveld",
            "fullName": "Glenneda Zuiderveld",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Zuiderveld7798.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-ID-24-upper-ocd-person-7ba08b82-5bfc-4b36-b036-28b04c0d8bd2",
              "builtID": "id-upper-24",
              "externalID": "ocd-person/7ba08b82-5bfc-4b36-b036-28b04c0d8bd2",
              "geometry": null
            },
            "contactInfo": {
              "email": "gzuiderveld@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 3123, Twin Falls, ID 83303",
              "phone1": null,
              "phone2": "208-280-0716",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=24",
                "https://legislature.idaho.gov/legislators/membership/2023/id7798/",
                "https://legislature.idaho.gov/legislators/membership/2024/id7798/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-24-upper-ocd-person-7ba08b82-5bfc-4b36-b036-28b04c0d8bd2": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/d988af9b-9622-41ed-b2d5-e4ad8f782e37",
            "firstName": "Linda Wright",
            "lastName": "Hartgen",
            "fullName": "Linda Hartgen",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Hartgen5376.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-ID-25-upper-ocd-person-d988af9b-9622-41ed-b2d5-e4ad8f782e37",
              "builtID": "id-upper-25",
              "externalID": "ocd-person/d988af9b-9622-41ed-b2d5-e4ad8f782e37",
              "geometry": null
            },
            "contactInfo": {
              "email": "lhartgen@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1681 Wildflower Lane, Twin Falls, ID 83301",
              "phone1": null,
              "phone2": "208-332-1318",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=24",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=24",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=25",
                "https://legislature.idaho.gov/legislators/membership/2023/id5376/",
                "https://legislature.idaho.gov/legislators/membership/2024/id5376/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-25-upper-ocd-person-d988af9b-9622-41ed-b2d5-e4ad8f782e37": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/1024a2b6-d36f-4c1f-86a0-826ec9120fe0",
            "firstName": "Ron C.",
            "lastName": "Taylor",
            "fullName": "Ron Taylor",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Taylor7836.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-ID-26-upper-ocd-person-1024a2b6-d36f-4c1f-86a0-826ec9120fe0",
              "builtID": "id-upper-26",
              "externalID": "ocd-person/1024a2b6-d36f-4c1f-86a0-826ec9120fe0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rtaylor@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "419 S. 2nd Ave., Hailey, ID 83333",
              "phone1": null,
              "phone2": "208-831-1726",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=26",
                "https://legislature.idaho.gov/legislators/membership/2023/id7836/",
                "https://legislature.idaho.gov/legislators/membership/2024/id7836/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-26-upper-ocd-person-1024a2b6-d36f-4c1f-86a0-826ec9120fe0": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/9577c53b-0d84-4d3b-9c5b-7d38227c8e32",
            "firstName": "Kelly Arthur",
            "lastName": "Anthon",
            "fullName": "Kelly Anthon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2017/directory/Anthon3524.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-ID-27-upper-ocd-person-9577c53b-0d84-4d3b-9c5b-7d38227c8e32",
              "builtID": "id-upper-27",
              "externalID": "ocd-person/9577c53b-0d84-4d3b-9c5b-7d38227c8e32",
              "geometry": null
            },
            "contactInfo": {
              "email": "kanthon@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "725 E. 300 South St., Burley, ID 83318",
              "phone1": null,
              "phone2": "208-654-4099",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=27",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=27",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=27",
                "https://legislature.idaho.gov/legislators/membership/2023/id3524/",
                "https://legislature.idaho.gov/legislators/membership/2024/id3524/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-27-upper-ocd-person-9577c53b-0d84-4d3b-9c5b-7d38227c8e32": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/e2a8fbe1-e9dc-4569-865f-450fa5f13665",
            "firstName": "Jim",
            "lastName": "Guthrie",
            "fullName": "Jim Guthrie",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2017/directory/Guthrie1179.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-ID-28-upper-ocd-person-e2a8fbe1-e9dc-4569-865f-450fa5f13665",
              "builtID": "id-upper-28",
              "externalID": "ocd-person/e2a8fbe1-e9dc-4569-865f-450fa5f13665",
              "geometry": null
            },
            "contactInfo": {
              "email": "jguthrie@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "320 S. Marsh Creek Road, McCammon, ID 83250",
              "phone1": null,
              "phone2": "208-251-9303",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=28",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=28",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=28",
                "https://legislature.idaho.gov/legislators/membership/2023/id1179/",
                "https://legislature.idaho.gov/legislators/membership/2024/id1179/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-28-upper-ocd-person-e2a8fbe1-e9dc-4569-865f-450fa5f13665": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/bcb4f50c-1f70-484a-90c2-65652b6a1133",
            "firstName": "James Daw",
            "lastName": "Ruchti",
            "fullName": "James Ruchti",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Ruchti52.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-ID-29-upper-ocd-person-bcb4f50c-1f70-484a-90c2-65652b6a1133",
              "builtID": "id-upper-29",
              "externalID": "ocd-person/bcb4f50c-1f70-484a-90c2-65652b6a1133",
              "geometry": null
            },
            "contactInfo": {
              "email": "jruchti@house.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1490 Windsong Lane, Pocatello, ID 83201",
              "phone1": null,
              "phone2": "208-251-4104",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=29",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=29",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=29",
                "https://legislature.idaho.gov/legislators/membership/2024/id52/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-29-upper-ocd-person-bcb4f50c-1f70-484a-90c2-65652b6a1133": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/8efc94ea-7306-48f5-8d45-5bed5f5e1f85",
            "firstName": "Julie",
            "lastName": "VanOrden",
            "fullName": "Julie VanOrden",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2017/directory/VanOrden2129.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-ID-30-upper-ocd-person-8efc94ea-7306-48f5-8d45-5bed5f5e1f85",
              "builtID": "id-upper-30",
              "externalID": "ocd-person/8efc94ea-7306-48f5-8d45-5bed5f5e1f85",
              "geometry": null
            },
            "contactInfo": {
              "email": "jvanorden@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "425 S. 1100 West, Pingree, ID 83262",
              "phone1": null,
              "phone2": "208-684-4052",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2018&district=31",
                "https://legislature.idaho.gov/house/membershipSingle.cfm?ID=2129",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=30",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=30",
                "https://legislature.idaho.gov/legislators/membership/2023/id2129/",
                "https://legislature.idaho.gov/legislators/membership/2024/id2129/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-30-upper-ocd-person-8efc94ea-7306-48f5-8d45-5bed5f5e1f85": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/2a8943d8-c4fe-4286-8b26-6240d9ff26f8",
            "firstName": "Van T.",
            "lastName": "Burtenshaw",
            "fullName": "Van Burtenshaw",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2023/directory/Burtenshaw3140.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-ID-31-upper-ocd-person-2a8943d8-c4fe-4286-8b26-6240d9ff26f8",
              "builtID": "id-upper-31",
              "externalID": "ocd-person/2a8943d8-c4fe-4286-8b26-6240d9ff26f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "vburtenshaw@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 130, Terreton, ID 83450",
              "phone1": null,
              "phone2": "208-663-4607",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=35",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=35",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=31",
                "https://legislature.idaho.gov/legislators/membership/2023/id3140/",
                "https://legislature.idaho.gov/legislators/membership/2024/id3140/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-31-upper-ocd-person-2a8943d8-c4fe-4286-8b26-6240d9ff26f8": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/9134f853-f2da-47e8-a11f-9fbe77849414",
            "firstName": "Kevin",
            "lastName": "Cook",
            "fullName": "Kevin Cook",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2021/directory/Cook6686.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-ID-32-upper-ocd-person-9134f853-f2da-47e8-a11f-9fbe77849414",
              "builtID": "id-upper-32",
              "externalID": "ocd-person/9134f853-f2da-47e8-a11f-9fbe77849414",
              "geometry": null
            },
            "contactInfo": {
              "email": "kcook@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1184 E. Lazy Lane, Idaho Falls, ID 83404",
              "phone1": null,
              "phone2": "208-521-6776",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=30",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=30",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=32",
                "https://legislature.idaho.gov/legislators/membership/2023/id6686/",
                "https://legislature.idaho.gov/legislators/membership/2024/id6686/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-32-upper-ocd-person-9134f853-f2da-47e8-a11f-9fbe77849414": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/fdcf0c8e-5794-4c3c-a092-a64ee5cab9d3",
            "firstName": "David",
            "lastName": "Lent",
            "fullName": "Dave Lent",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2019/directory/Lent5345.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-ID-33-upper-ocd-person-fdcf0c8e-5794-4c3c-a092-a64ee5cab9d3",
              "builtID": "id-upper-33",
              "externalID": "ocd-person/fdcf0c8e-5794-4c3c-a092-a64ee5cab9d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "dlent@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1186 Caysie Circle, Idaho Falls, ID 83402",
              "phone1": null,
              "phone2": "208-521-0716",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=33",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=33",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=33",
                "https://legislature.idaho.gov/legislators/membership/2023/id5345/",
                "https://legislature.idaho.gov/legislators/membership/2024/id5345/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-33-upper-ocd-person-fdcf0c8e-5794-4c3c-a092-a64ee5cab9d3": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/2960c6c4-abca-4406-9d9d-4734fec3f68d",
            "firstName": "Douglas",
            "lastName": "Ricks",
            "fullName": "Doug Ricks",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2021/directory/Ricks5383.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-ID-34-upper-ocd-person-2960c6c4-abca-4406-9d9d-4734fec3f68d",
              "builtID": "id-upper-34",
              "externalID": "ocd-person/2960c6c4-abca-4406-9d9d-4734fec3f68d",
              "geometry": null
            },
            "contactInfo": {
              "email": "dricks@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "140 S. 3rd East, Rexburg, ID 83440",
              "phone1": null,
              "phone2": "208-557-9665",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=34",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=34",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=34",
                "https://legislature.idaho.gov/legislators/membership/2023/id5383/",
                "https://legislature.idaho.gov/legislators/membership/2024/id5383/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-34-upper-ocd-person-2960c6c4-abca-4406-9d9d-4734fec3f68d": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/84d9ffa4-35f6-4baf-acdd-c3bafee537e7",
            "firstName": "Mark",
            "lastName": "Harris",
            "fullName": "Mark Harris",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2017/directory/Harris3584.jpg",
            "title": "ID Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ID",
              "stateFull": "Idaho",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-ID-35-upper-ocd-person-84d9ffa4-35f6-4baf-acdd-c3bafee537e7",
              "builtID": "id-upper-35",
              "externalID": "ocd-person/84d9ffa4-35f6-4baf-acdd-c3bafee537e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "mharris@senate.idaho.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1619 8-Mile Creek Road, Soda Springs, ID 83276",
              "phone1": null,
              "phone2": "208-547-3360",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.idaho.gov/legislators/membership/?yr=2021&district=32",
                "https://legislature.idaho.gov/legislators/membership/?yr=2022&district=32",
                "https://legislature.idaho.gov/legislators/membership/?yr=2023&district=35",
                "https://legislature.idaho.gov/legislators/membership/2023/id3584/",
                "https://legislature.idaho.gov/legislators/membership/2024/id3584/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ID-35-upper-ocd-person-84d9ffa4-35f6-4baf-acdd-c3bafee537e7": 0
        }
      }
    }
  },
  "IL": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "IL",
          "stateFull": "Illinois",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "IL",
            "stateFull": "Illinois",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-IL---",
            "builtID": "il--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-IL---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "D000622",
          "in_office": true,
          "firstName": "Tammy",
          "lastName": "Duckworth",
          "middleName": null,
          "fullName": "Tammy Duckworth",
          "gender": "F",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/D000622.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "IL",
            "stateFull": "Illinois",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-IL---D000622",
            "builtID": "il--",
            "externalID": "D000622",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenDuckworth",
            "youtube": "repduckworth",
            "instagram": null,
            "facebook": "SenDuckworth",
            "urls": ["https://www.duckworth.senate.gov"],
            "rss_url": null
          },
          "title": "IL Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/D000622.json",
          "govtrack_id": "412533",
          "cspan_id": "94484",
          "votesmart_id": "57442",
          "icpsr_id": "21325",
          "crp_id": "N00027860",
          "google_entity_id": "/m/09vqjr",
          "state_rank": "junior",
          "lis_id": "S386",
          "suffix": null,
          "date_of_birth": "1968-03-12",
          "leadership_role": null,
          "fec_candidate_id": "H6IL06141",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "7",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 8,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 1.44,
          "votes_with_party_pct": 99.63,
          "votes_against_party_pct": 0.37,
          "ocd_id": "ocd-division/country:us/state:il"
        },
        {
          "API_ID": "D000563",
          "in_office": true,
          "firstName": "Richard",
          "lastName": "Durbin",
          "middleName": "J.",
          "fullName": "Richard J. Durbin",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/D000563.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "IL",
            "stateFull": "Illinois",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-IL---D000563",
            "builtID": "il--",
            "externalID": "D000563",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.durbin.senate.gov/contact/",
            "address1": "711 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-2152",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorDurbin",
            "youtube": "SenatorDurbin",
            "instagram": null,
            "facebook": "SenatorDurbin",
            "urls": ["https://www.durbin.senate.gov"],
            "rss_url": null
          },
          "title": "IL Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/D000563.json",
          "govtrack_id": "300038",
          "cspan_id": "6741",
          "votesmart_id": "26847",
          "icpsr_id": "15021",
          "crp_id": "N00004981",
          "google_entity_id": "/m/01xcd1",
          "state_rank": "senior",
          "lis_id": "S253",
          "suffix": null,
          "date_of_birth": "1944-11-21",
          "leadership_role": "Senate Majority Whip",
          "fec_candidate_id": "S6IL00151",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "27",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 49,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 8.84,
          "votes_with_party_pct": 98.01,
          "votes_against_party_pct": 1.99,
          "ocd_id": "ocd-division/country:us/state:il"
        }
      ],
      "hash": { "SENATE-IL---D000622": 0, "SENATE-IL---D000563": 1 }
    },
    "HOUSE": {
      "10": {
        "members": [
          {
            "API_ID": "S001190",
            "in_office": true,
            "firstName": "Brad",
            "lastName": "Schneider",
            "middleName": null,
            "fullName": "Bradley Scott Schneider",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001190.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "10",
              "chamber": "",
              "hashID": "HOUSE-IL-10--S001190",
              "builtID": "il--10",
              "externalID": "S001190",
              "geometry": null,
              "geoid": "1710"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "300 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-4835",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repschneider",
              "youtube": "RepBradSchneider",
              "instagram": null,
              "facebook": "CongressmanBradSchneider",
              "urls": ["https://schneider.house.gov"],
              "rss_url": "https://schneider.house.gov/rss.xml"
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001190.json",
            "govtrack_id": "412534",
            "cspan_id": "63948",
            "votesmart_id": "134948",
            "icpsr_id": "21326",
            "crp_id": "N00033101",
            "google_entity_id": "/m/0j63zyv",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1961-08-20",
            "leadership_role": null,
            "fec_candidate_id": "H2IL10068",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 29,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.75,
            "votes_with_party_pct": 94.89,
            "votes_against_party_pct": 3.15,
            "ocd_id": "ocd-division/country:us/state:il/cd:10",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-10--S001190": 0 }
      },
      "11": {
        "members": [
          {
            "API_ID": "F000454",
            "in_office": true,
            "firstName": "Bill",
            "lastName": "Foster",
            "middleName": null,
            "fullName": "Bill Foster",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/F000454.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "11",
              "chamber": "",
              "hashID": "HOUSE-IL-11--F000454",
              "builtID": "il--11",
              "externalID": "F000454",
              "geometry": null,
              "geoid": "1711"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2366 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3515",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBillFoster",
              "youtube": "RepBillFoster",
              "instagram": null,
              "facebook": "CongressmanBillFoster",
              "urls": ["https://foster.house.gov"],
              "rss_url": "https://foster.house.gov/rss.xml"
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000454.json",
            "govtrack_id": "412257",
            "cspan_id": "1027346",
            "votesmart_id": "101632",
            "icpsr_id": "20749",
            "crp_id": "N00029139",
            "google_entity_id": "/m/02rq8nc",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1955-10-07",
            "leadership_role": null,
            "fec_candidate_id": "H8IL14067",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 9,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 95.95,
            "votes_against_party_pct": 2.22,
            "ocd_id": "ocd-division/country:us/state:il/cd:11",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-11--F000454": 0 }
      },
      "12": {
        "members": [
          {
            "API_ID": "B001295",
            "in_office": true,
            "firstName": "Mike",
            "lastName": "Bost",
            "middleName": null,
            "fullName": "Mike Bost",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001295.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "12",
              "chamber": "",
              "hashID": "HOUSE-IL-12--B001295",
              "builtID": "il--12",
              "externalID": "B001295",
              "geometry": null,
              "geoid": "1712"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "352 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5661",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBost",
              "youtube": null,
              "instagram": null,
              "facebook": "RepBost",
              "urls": ["https://bost.house.gov"],
              "rss_url": null
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001295.json",
            "govtrack_id": "412629",
            "cspan_id": "76176",
            "votesmart_id": "6302",
            "icpsr_id": "21519",
            "crp_id": "N00035420",
            "google_entity_id": "/m/0kry42",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1960-12-30",
            "leadership_role": null,
            "fec_candidate_id": "H4IL12060",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 11,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.04,
            "votes_with_party_pct": 92.96,
            "votes_against_party_pct": 5.18,
            "ocd_id": "ocd-division/country:us/state:il/cd:12",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-12--B001295": 0 }
      },
      "13": {
        "members": [
          {
            "API_ID": "B001315",
            "in_office": true,
            "firstName": "Nikki",
            "lastName": "Budzinski",
            "middleName": null,
            "fullName": "Nikki Budzinski",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/B001315.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "13",
              "chamber": "",
              "hashID": "HOUSE-IL-13--B001315",
              "builtID": "il--13",
              "externalID": "B001315",
              "geometry": null,
              "geoid": "1713"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1009 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2371",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepNikkiB",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://budzinski.house.gov/"],
              "rss_url": null
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001315.json",
            "govtrack_id": "456901",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1977-03-11",
            "leadership_role": null,
            "fec_candidate_id": "H2IL13153",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 5,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.47,
            "votes_with_party_pct": 92.8,
            "votes_against_party_pct": 5.28,
            "ocd_id": "ocd-division/country:us/state:il/cd:13",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-13--B001315": 0 }
      },
      "14": {
        "members": [
          {
            "API_ID": "U000040",
            "in_office": true,
            "firstName": "Lauren",
            "lastName": "Underwood",
            "middleName": null,
            "fullName": "Lauren Underwood",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/U000040.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "14",
              "chamber": "",
              "hashID": "HOUSE-IL-14--U000040",
              "builtID": "il--14",
              "externalID": "U000040",
              "geometry": null,
              "geoid": "1714"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1410 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2976",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepUnderwood",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://underwood.house.gov"],
              "rss_url": null
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/U000040.json",
            "govtrack_id": "412776",
            "cspan_id": null,
            "votesmart_id": "177001",
            "icpsr_id": null,
            "crp_id": "N00041569",
            "google_entity_id": "/g/11gfm73_1z",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1986-10-04",
            "leadership_role": null,
            "fec_candidate_id": "H8IL14174",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 13,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.23,
            "votes_with_party_pct": 96.03,
            "votes_against_party_pct": 2.03,
            "ocd_id": "ocd-division/country:us/state:il/cd:14",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-14--U000040": 0 }
      },
      "15": {
        "members": [
          {
            "API_ID": "M001211",
            "in_office": true,
            "firstName": "Mary",
            "lastName": "Miller",
            "middleName": null,
            "fullName": "Mary E. Miller",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001211.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "15",
              "chamber": "",
              "hashID": "HOUSE-IL-15--M001211",
              "builtID": "il--15",
              "externalID": "M001211",
              "geometry": null,
              "geoid": "1715"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1740 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5271",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMaryMiller",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://marymiller.house.gov"],
              "rss_url": null
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001211.json",
            "govtrack_id": "456820",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00045703",
            "google_entity_id": "/g/11fsd5mh_l",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1959-08-27",
            "leadership_role": null,
            "fec_candidate_id": "H0IL15129",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 13,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.23,
            "votes_with_party_pct": 89.43,
            "votes_against_party_pct": 8.61,
            "ocd_id": "ocd-division/country:us/state:il/cd:15",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-15--M001211": 0 }
      },
      "16": {
        "members": [
          {
            "API_ID": "L000585",
            "in_office": true,
            "firstName": "Darin",
            "lastName": "LaHood",
            "middleName": "M.",
            "fullName": "Darin LaHood",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/L000585.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "16",
              "chamber": "",
              "hashID": "HOUSE-IL-16--L000585",
              "builtID": "il--16",
              "externalID": "L000585",
              "geometry": null,
              "geoid": "1716"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1424 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-6201",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLaHood",
              "youtube": null,
              "instagram": null,
              "facebook": "replahood",
              "urls": ["https://lahood.house.gov"],
              "rss_url": null
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000585.json",
            "govtrack_id": "412674",
            "cspan_id": "70020",
            "votesmart_id": "128760",
            "icpsr_id": "21562",
            "crp_id": "N00037031",
            "google_entity_id": "/m/0gg7rcy",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1968-07-05",
            "leadership_role": null,
            "fec_candidate_id": "H6IL18088",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 33,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 3.13,
            "votes_with_party_pct": 91.52,
            "votes_against_party_pct": 6.49,
            "ocd_id": "ocd-division/country:us/state:il/cd:16",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-16--L000585": 0 }
      },
      "17": {
        "members": [
          {
            "API_ID": "S001225",
            "in_office": true,
            "firstName": "Eric",
            "lastName": "Sorensen",
            "middleName": null,
            "fullName": "Eric Sorensen",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001225.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "17",
              "chamber": "",
              "hashID": "HOUSE-IL-17--S001225",
              "builtID": "il--17",
              "externalID": "S001225",
              "geometry": null,
              "geoid": "1717"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1205 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5905",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepEricSorensen",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://sorensen.house.gov/"],
              "rss_url": null
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001225.json",
            "govtrack_id": "456902",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-03-18",
            "leadership_role": null,
            "fec_candidate_id": "H2IL17147",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 60,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 5.68,
            "votes_with_party_pct": 92.39,
            "votes_against_party_pct": 5.68,
            "ocd_id": "ocd-division/country:us/state:il/cd:17",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-17--S001225": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "C001117",
            "in_office": true,
            "firstName": "Sean",
            "lastName": "Casten",
            "middleName": null,
            "fullName": "Sean Casten",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001117.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-IL-06--C001117",
              "builtID": "il--06",
              "externalID": "C001117",
              "geometry": null,
              "geoid": "1706"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2440 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4561",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepCasten",
              "youtube": null,
              "instagram": null,
              "facebook": "RepSeanCasten",
              "urls": ["https://casten.house.gov"],
              "rss_url": null
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001117.json",
            "govtrack_id": "412775",
            "cspan_id": null,
            "votesmart_id": "176982",
            "icpsr_id": null,
            "crp_id": "N00041338",
            "google_entity_id": "/g/11hc_0775g",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1971-11-23",
            "leadership_role": null,
            "fec_candidate_id": "H8IL06139",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 12,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.14,
            "votes_with_party_pct": 95.94,
            "votes_against_party_pct": 2.13,
            "ocd_id": "ocd-division/country:us/state:il/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-06--C001117": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "D000096",
            "in_office": true,
            "firstName": "Danny",
            "lastName": "Davis",
            "middleName": "K.",
            "fullName": "Danny K. Davis",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/D000096.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-IL-07--D000096",
              "builtID": "il--07",
              "externalID": "D000096",
              "geometry": null,
              "geoid": "1707"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2159 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5006",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDannyDavis",
              "youtube": "dannykdavis07",
              "instagram": null,
              "facebook": "CongressmanDKDavis",
              "urls": ["https://davis.house.gov"],
              "rss_url": null
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000096.json",
            "govtrack_id": "400093",
            "cspan_id": "44325",
            "votesmart_id": "233",
            "icpsr_id": "29717",
            "crp_id": "N00004884",
            "google_entity_id": "/m/0256k7",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1941-09-06",
            "leadership_role": null,
            "fec_candidate_id": "H4IL07037",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "28",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 45,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 4.27,
            "votes_with_party_pct": 95.5,
            "votes_against_party_pct": 2.6,
            "ocd_id": "ocd-division/country:us/state:il/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-07--D000096": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "G000586",
            "in_office": true,
            "firstName": "Jesús",
            "lastName": "García",
            "middleName": null,
            "fullName": "Jesús G. \"Chuy\" García",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/G000586.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-IL-04--G000586",
              "builtID": "il--04",
              "externalID": "G000586",
              "geometry": null,
              "geoid": "1704"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1519 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-8203",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepChuyGarcia",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://chuygarcia.house.gov"],
              "rss_url": null
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000586.json",
            "govtrack_id": "412774",
            "cspan_id": null,
            "votesmart_id": "6261",
            "icpsr_id": null,
            "crp_id": "N00042114",
            "google_entity_id": "/m/012c8r5x",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1956-04-12",
            "leadership_role": null,
            "fec_candidate_id": "H8IL04134",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 108,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 10.23,
            "votes_with_party_pct": 90.51,
            "votes_against_party_pct": 7.36,
            "ocd_id": "ocd-division/country:us/state:il/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-04--G000586": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "J000309",
            "in_office": true,
            "firstName": "Jonathan",
            "lastName": "Jackson",
            "middleName": null,
            "fullName": "Jonathan L. Jackson",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/J000309.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-IL-01--J000309",
              "builtID": "il--01",
              "externalID": "J000309",
              "geometry": null,
              "geoid": "1701"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1641 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4372",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://jonathanjackson.house.gov/"],
              "rss_url": null
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/J000309.json",
            "govtrack_id": "456899",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1966-01-07",
            "leadership_role": null,
            "fec_candidate_id": "H2IL01349",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 22,
            "total_present": 3,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.08,
            "votes_with_party_pct": 93.85,
            "votes_against_party_pct": 4.2,
            "ocd_id": "ocd-division/country:us/state:il/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-01--J000309": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "K000385",
            "in_office": true,
            "firstName": "Robin",
            "lastName": "Kelly",
            "middleName": null,
            "fullName": "Robin L. Kelly",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/K000385.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-IL-02--K000385",
              "builtID": "il--02",
              "externalID": "K000385",
              "geometry": null,
              "geoid": "1702"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2329 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-0773",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRobinKelly",
              "youtube": null,
              "instagram": null,
              "facebook": "reprobinkelly",
              "urls": ["https://robinkelly.house.gov"],
              "rss_url": null
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000385.json",
            "govtrack_id": "412595",
            "cspan_id": "70399",
            "votesmart_id": "33384",
            "icpsr_id": "21371",
            "crp_id": "N00035215",
            "google_entity_id": "/m/0g8l32",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1956-04-30",
            "leadership_role": null,
            "fec_candidate_id": "H2IL02172",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 53,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 5.02,
            "votes_with_party_pct": 96.48,
            "votes_against_party_pct": 1.61,
            "ocd_id": "ocd-division/country:us/state:il/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-02--K000385": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "K000391",
            "in_office": true,
            "firstName": "Raja",
            "lastName": "Krishnamoorthi",
            "middleName": null,
            "fullName": "Raja Krishnamoorthi",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/K000391.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-IL-08--K000391",
              "builtID": "il--08",
              "externalID": "K000391",
              "geometry": null,
              "geoid": "1708"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2367 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3711",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "congressmanraja",
              "youtube": null,
              "instagram": null,
              "facebook": "congressmanraja",
              "urls": ["https://krishnamoorthi.house.gov"],
              "rss_url": "https://krishnamoorthi.house.gov/rss.xml"
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000391.json",
            "govtrack_id": "412701",
            "cspan_id": "103408",
            "votesmart_id": "117519",
            "icpsr_id": "21730",
            "crp_id": "N00033240",
            "google_entity_id": "/g/11c5m7lxtk",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1973-07-19",
            "leadership_role": null,
            "fec_candidate_id": "H6IL08147",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 7,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.66,
            "votes_with_party_pct": 96.54,
            "votes_against_party_pct": 1.54,
            "ocd_id": "ocd-division/country:us/state:il/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-08--K000391": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "Q000023",
            "in_office": true,
            "firstName": "Mike",
            "lastName": "Quigley",
            "middleName": null,
            "fullName": "Mike Quigley",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/Q000023.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-IL-05--Q000023",
              "builtID": "il--05",
              "externalID": "Q000023",
              "geometry": null,
              "geoid": "1705"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2083 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4061",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMikeQuigley",
              "youtube": "RepMikeQuigley",
              "instagram": null,
              "facebook": "repmikequigley",
              "urls": ["https://quigley.house.gov"],
              "rss_url": "https://quigley.house.gov/rss.xml"
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/Q000023.json",
            "govtrack_id": "412331",
            "cspan_id": "9263344",
            "votesmart_id": "83310",
            "icpsr_id": "20954",
            "crp_id": "N00030581",
            "google_entity_id": "/m/0fccwh",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1958-10-17",
            "leadership_role": null,
            "fec_candidate_id": "H0IL05096",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "16",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 33,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.13,
            "votes_with_party_pct": 96.44,
            "votes_against_party_pct": 1.68,
            "ocd_id": "ocd-division/country:us/state:il/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-05--Q000023": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "R000617",
            "in_office": true,
            "firstName": "Delia",
            "lastName": "Ramirez",
            "middleName": null,
            "fullName": "Delia C. Ramirez",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/R000617.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-IL-03--R000617",
              "builtID": "il--03",
              "externalID": "R000617",
              "geometry": null,
              "geoid": "1703"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1523 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5701",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ramirez.house.gov/"],
              "rss_url": null
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/R000617.json",
            "govtrack_id": "456900",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1983-06-02",
            "leadership_role": null,
            "fec_candidate_id": "H2IL03162",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 25,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.37,
            "votes_with_party_pct": 90.4,
            "votes_against_party_pct": 7.64,
            "ocd_id": "ocd-division/country:us/state:il/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-03--R000617": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "S001145",
            "in_office": true,
            "firstName": "Jan",
            "lastName": "Schakowsky",
            "middleName": null,
            "fullName": "Janice D. Schakowsky",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001145.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-IL-09--S001145",
              "builtID": "il--09",
              "externalID": "S001145",
              "geometry": null,
              "geoid": "1709"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2408 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2111",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "JanSchakowsky",
              "youtube": "repschakowsky",
              "instagram": null,
              "facebook": "janschakowsky",
              "urls": ["https://schakowsky.house.gov"],
              "rss_url": null
            },
            "title": "IL House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001145.json",
            "govtrack_id": "400360",
            "cspan_id": "57874",
            "votesmart_id": "6387",
            "icpsr_id": "29911",
            "crp_id": "N00004724",
            "google_entity_id": "/m/0256nw",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1944-05-26",
            "leadership_role": null,
            "fec_candidate_id": "H8IL09067",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "26",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 31,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.94,
            "votes_with_party_pct": 94.08,
            "votes_against_party_pct": 4.04,
            "ocd_id": "ocd-division/country:us/state:il/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IL-09--S001145": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/a76e0c0e-81d3-4f3b-8cc8-b8360d3cecb4",
            "firstName": "Aaron Manuel",
            "lastName": "Ortiz",
            "fullName": "Aarón Ortíz",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{D96B0DF9-0E06-487B-825C-AB0BE8C3CA1E}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-IL-1-lower-ocd-person-a76e0c0e-81d3-4f3b-8cc8-b8360d3cecb4",
              "builtID": "il-lower-1",
              "externalID": "ocd-person/a76e0c0e-81d3-4f3b-8cc8-b8360d3cecb4",
              "geometry": null
            },
            "contactInfo": {
              "email": "district@repaaronortiz.com",
              "contactForm": null,
              "address1": null,
              "address2": "4374 S. Archer Ave., Chicago, IL 60632",
              "phone1": null,
              "phone2": "773-236-0117",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2752",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2953",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2953",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3148",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3148",
                "https://repaaronortiz.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-1-lower-ocd-person-a76e0c0e-81d3-4f3b-8cc8-b8360d3cecb4": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/b6ebf45f-ac97-423b-b5c3-eba0a5766a8d",
            "firstName": "Elizabeth",
            "lastName": "Hernandez",
            "fullName": "Lisa Hernandez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{4E4C2A21-FDC2-48CA-9BA8-462A62E7A33B}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-IL-2-lower-ocd-person-b6ebf45f-ac97-423b-b5c3-eba0a5766a8d",
              "builtID": "il-lower-2",
              "externalID": "ocd-person/b6ebf45f-ac97-423b-b5c3-eba0a5766a8d",
              "geometry": null
            },
            "contactInfo": {
              "email": "office@repehernandez.com",
              "contactForm": null,
              "address1": null,
              "address2": "6117 W. Cermak Road, Cicero, IL 60804",
              "phone1": null,
              "phone2": "708-222-5240",
              "fax1": null,
              "fax2": "708-222-5241",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2568",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2816",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2816",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3037",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3159",
                "https://www.staterepresentativebarbarahernandez.com/",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3037"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-2-lower-ocd-person-b6ebf45f-ac97-423b-b5c3-eba0a5766a8d": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/01201203-526d-4d02-b6fc-cbe5a749b6ab",
            "firstName": "Eva-Dina",
            "lastName": "Delgado",
            "fullName": "Eva-Dina Delgado",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{D02A93E7-7D84-4429-92AB-60B61084EBA1}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-IL-3-lower-ocd-person-01201203-526d-4d02-b6fc-cbe5a749b6ab",
              "builtID": "il-lower-3",
              "externalID": "ocd-person/01201203-526d-4d02-b6fc-cbe5a749b6ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "staterepdelgado@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "6309 W. Belmont Ave., Chicago, IL 60634",
              "phone1": null,
              "phone2": "773-237-4558",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2775",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2974",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2974",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3165",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3165",
                "https://www.staterepdelgado.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-3-lower-ocd-person-01201203-526d-4d02-b6fc-cbe5a749b6ab": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/256a1090-5fef-4f0c-b016-263735573c77",
            "firstName": "Lilian",
            "lastName": "Jimenez",
            "fullName": "Lilian Jiménez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{6C475229-0B41-4F93-A2C7-5B1396A1D1FA}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-IL-4-lower-ocd-person-256a1090-5fef-4f0c-b016-263735573c77",
              "builtID": "il-lower-4",
              "externalID": "ocd-person/256a1090-5fef-4f0c-b016-263735573c77",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@replilianjimenez.com",
              "contactForm": null,
              "address1": null,
              "address2": "2511 W. Division St., Chicago, IL 60622",
              "phone1": null,
              "phone2": "773-337-9995",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3226",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3226"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-4-lower-ocd-person-256a1090-5fef-4f0c-b016-263735573c77": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/3b2af944-b2b7-4b13-87db-1c8cdd45de3c",
            "firstName": "Kimberly Neely",
            "lastName": "Du Buclet",
            "fullName": "Kimberly Du Buclet",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{4858CB21-EEE1-409B-9A4C-FA760BB6905E}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-IL-5-lower-ocd-person-3b2af944-b2b7-4b13-87db-1c8cdd45de3c",
              "builtID": "il-lower-5",
              "externalID": "ocd-person/3b2af944-b2b7-4b13-87db-1c8cdd45de3c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep5information@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "5048 S. Indiana Ave., Chicago, IL 60615",
              "phone1": null,
              "phone2": "773-924-4614",
              "fax1": null,
              "fax2": "773-924-4652",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3252",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3252"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-5-lower-ocd-person-3b2af944-b2b7-4b13-87db-1c8cdd45de3c": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/8a39d16f-edbf-454b-8a77-33030fa9a8c2",
            "firstName": "Sonya Marie",
            "lastName": "Harper",
            "fullName": "Sonya Harper",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{04C99384-69F8-4ACE-9E43-11E21CC5F820}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-IL-6-lower-ocd-person-8a39d16f-edbf-454b-8a77-33030fa9a8c2",
              "builtID": "il-lower-6",
              "externalID": "ocd-person/8a39d16f-edbf-454b-8a77-33030fa9a8c2",
              "geometry": null
            },
            "contactInfo": {
              "email": "repsonyaharper@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "2015 W. 63rd St., Chicago, IL 60636",
              "phone1": null,
              "phone2": "773-925-6580",
              "fax1": null,
              "fax2": "773-925-6584",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2670",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2892",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2892",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3097",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3097",
                "https://www.repsonyaharper.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-6-lower-ocd-person-8a39d16f-edbf-454b-8a77-33030fa9a8c2": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/00cc6415-b2a7-45ab-8f69-be5c3ca6752a",
            "firstName": "Emanuel Christopher",
            "lastName": "Welch",
            "fullName": "Chris Welch",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{5D419B94-66B4-4F3B-86F1-BFF37B3FA55C}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-IL-7-lower-ocd-person-00cc6415-b2a7-45ab-8f69-be5c3ca6752a",
              "builtID": "il-lower-7",
              "externalID": "ocd-person/00cc6415-b2a7-45ab-8f69-be5c3ca6752a",
              "geometry": null
            },
            "contactInfo": {
              "email": "repwelch@emanuelchriswelch.com",
              "contactForm": null,
              "address1": null,
              "address2": "10055 W. Roosevelt Road Suite E, Westchester, IL 60154",
              "phone1": null,
              "phone2": "708-450-1000",
              "fax1": null,
              "fax2": "708-450-1104",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3071",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=2859",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3071"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-7-lower-ocd-person-00cc6415-b2a7-45ab-8f69-be5c3ca6752a": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/91164a3d-fc17-4252-a31f-a8d0d113b74b",
            "firstName": "La Shawn K.",
            "lastName": "Ford",
            "fullName": "La Shawn Ford",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{BCC3C8D6-5728-4A69-8A3B-F203314DD563}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-IL-8-lower-ocd-person-91164a3d-fc17-4252-a31f-a8d0d113b74b",
              "builtID": "il-lower-8",
              "externalID": "ocd-person/91164a3d-fc17-4252-a31f-a8d0d113b74b",
              "geometry": null
            },
            "contactInfo": {
              "email": "repford@lashawnford.com",
              "contactForm": null,
              "address1": null,
              "address2": "5051 W. Chicago Ave., Chicago, IL 60651",
              "phone1": null,
              "phone2": "773-750-0866",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2567",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2815",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2815",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3036",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3036"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-8-lower-ocd-person-91164a3d-fc17-4252-a31f-a8d0d113b74b": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/753dc145-9162-4e02-9b76-836be48498cb",
            "firstName": "Yolonda",
            "lastName": "Morris",
            "fullName": "Yolonda Morris",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-IL-9-lower-ocd-person-753dc145-9162-4e02-9b76-836be48498cb",
              "builtID": "il-lower-9",
              "externalID": "ocd-person/753dc145-9162-4e02-9b76-836be48498cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "9thdistrictinfo@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "4325-27 W. Roosevelt Road, Chicago, IL 60624",
              "phone1": null,
              "phone2": "773-565-4005",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3255",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3255"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-9-lower-ocd-person-753dc145-9162-4e02-9b76-836be48498cb": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/b79ed17d-30a6-4d03-af90-ad3b34610395",
            "firstName": "Jawaharial",
            "lastName": "Williams",
            "fullName": "Omar Williams",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{32750D93-496F-42CA-9CCE-93C3451713A1}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-IL-10-lower-ocd-person-b79ed17d-30a6-4d03-af90-ad3b34610395",
              "builtID": "il-lower-10",
              "externalID": "ocd-person/b79ed17d-30a6-4d03-af90-ad3b34610395",
              "geometry": null
            },
            "contactInfo": {
              "email": "repwilliamsoffice@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "2532 W. Warren Blvd. Suite A, Chicago, IL 60612",
              "phone1": null,
              "phone2": "312-265-1019",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2768",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2969",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2969",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3160",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3160"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-10-lower-ocd-person-b79ed17d-30a6-4d03-af90-ad3b34610395": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/02f2799d-4633-48e9-b5ab-379d48e70b40",
            "firstName": "Ann M.",
            "lastName": "Williams",
            "fullName": "Ann Williams",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{C45B418C-4848-4467-ADC2-739B2D1E4418}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-IL-11-lower-ocd-person-02f2799d-4633-48e9-b5ab-379d48e70b40",
              "builtID": "il-lower-11",
              "externalID": "ocd-person/02f2799d-4633-48e9-b5ab-379d48e70b40",
              "geometry": null
            },
            "contactInfo": {
              "email": "ann@repannwilliams.com",
              "contactForm": null,
              "address1": null,
              "address2": "1726 W. Belmont Ave., Chicago, IL 60657",
              "phone1": null,
              "phone2": "773-880-9082",
              "fax1": null,
              "fax2": "773-880-9083",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2595",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2837",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2837",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3054",
                "https://www.repannwilliams.com/",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3054"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-11-lower-ocd-person-02f2799d-4633-48e9-b5ab-379d48e70b40": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/adf823d2-7e3e-4a4d-898c-2dd404679012",
            "firstName": "Margaret",
            "lastName": "Croke",
            "fullName": "Margaret Croke",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{1719759C-D601-4575-A753-1244D0FE550A}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-IL-12-lower-ocd-person-adf823d2-7e3e-4a4d-898c-2dd404679012",
              "builtID": "il-lower-12",
              "externalID": "ocd-person/adf823d2-7e3e-4a4d-898c-2dd404679012",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@repcroke.com",
              "contactForm": null,
              "address1": null,
              "address2": "3223 N. Sheffield Ave. Suite A, Chicago, IL 60657",
              "phone1": null,
              "phone2": "773-348-8974",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2986",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2986",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3176",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3176",
                "https://www.repcroke.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-12-lower-ocd-person-adf823d2-7e3e-4a4d-898c-2dd404679012": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/c7156dc3-31a6-4582-bf3a-b325c9dc868f",
            "firstName": "Hoan",
            "lastName": "Huynh",
            "fullName": "Hoan Huynh",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{A6CE6A23-9F62-4C37-B297-4E48D79A3CE1}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-IL-13-lower-ocd-person-c7156dc3-31a6-4582-bf3a-b325c9dc868f",
              "builtID": "il-lower-13",
              "externalID": "ocd-person/c7156dc3-31a6-4582-bf3a-b325c9dc868f",
              "geometry": null
            },
            "contactInfo": {
              "email": "hello@rephoanhuynh.com",
              "contactForm": null,
              "address1": null,
              "address2": "1967 W. Montrose Ave., Chicago, IL 60613",
              "phone1": null,
              "phone2": "773-348-3434",
              "fax1": null,
              "fax2": "773-348-3475",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3227",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3227",
                "https://www.rephoanhuynh.com"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-13-lower-ocd-person-c7156dc3-31a6-4582-bf3a-b325c9dc868f": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/e1652f78-fa2e-46de-8630-f3e1e69040a3",
            "firstName": "Kelly M.",
            "lastName": "Cassidy",
            "fullName": "Kelly Cassidy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{3EFBDD50-41FF-4677-AA47-FB35B70C4667}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-IL-14-lower-ocd-person-e1652f78-fa2e-46de-8630-f3e1e69040a3",
              "builtID": "il-lower-14",
              "externalID": "ocd-person/e1652f78-fa2e-46de-8630-f3e1e69040a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@repcassidy.com",
              "contactForm": null,
              "address1": null,
              "address2": "1507 W. Morse Ave., Chicago, IL 60626",
              "phone1": null,
              "phone2": "773-784-2002",
              "fax1": null,
              "fax2": "773-784-2060",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2601",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2840",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2840",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3057",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3057",
                "https://www.repcassidy.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-14-lower-ocd-person-e1652f78-fa2e-46de-8630-f3e1e69040a3": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/c98bae02-b0dd-40ea-96a8-8a5fa994d211",
            "firstName": "Michael J.",
            "lastName": "Kelly",
            "fullName": "Michael Kelly",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{90B28E06-84A0-47EB-A80B-403DF4CDA607}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-IL-15-lower-ocd-person-c98bae02-b0dd-40ea-96a8-8a5fa994d211",
              "builtID": "il-lower-15",
              "externalID": "ocd-person/c98bae02-b0dd-40ea-96a8-8a5fa994d211",
              "geometry": null
            },
            "contactInfo": {
              "email": "district@repkelly.com",
              "contactForm": null,
              "address1": null,
              "address2": "4200 W. Lawrence Ave., Chicago, IL 60630",
              "phone1": null,
              "phone2": "773-736-0218",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=3013",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3201",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3201",
                "https://repkelly.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-15-lower-ocd-person-c98bae02-b0dd-40ea-96a8-8a5fa994d211": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/c2c8c6a1-ef1b-4a38-92fa-bde7563b7f23",
            "firstName": "Kevin John",
            "lastName": "Olickal",
            "fullName": "Kevin Olickal",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{23C3BBA7-3179-41B5-A3EF-A372F88DD9C9}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-IL-16-lower-ocd-person-c2c8c6a1-ef1b-4a38-92fa-bde7563b7f23",
              "builtID": "il-lower-16",
              "externalID": "ocd-person/c2c8c6a1-ef1b-4a38-92fa-bde7563b7f23",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@repkevinolickal.com",
              "contactForm": null,
              "address1": null,
              "address2": "2709 W. Devon Ave., Chicago, IL 60659",
              "phone1": null,
              "phone2": "847-673-1131",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3228",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3228"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-16-lower-ocd-person-c2c8c6a1-ef1b-4a38-92fa-bde7563b7f23": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/cf97f459-e1cd-474b-8306-3c55d7c009b0",
            "firstName": "Jennifer",
            "lastName": "Gong-Gershowitz",
            "fullName": "Jen Gong-Gershowitz",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{395E8140-10ED-4C70-81B9-2673676BF467}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-IL-17-lower-ocd-person-cf97f459-e1cd-474b-8306-3c55d7c009b0",
              "builtID": "il-lower-17",
              "externalID": "ocd-person/cf97f459-e1cd-474b-8306-3c55d7c009b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@gonggershowitz.com",
              "contactForm": null,
              "address1": null,
              "address2": "1812 Waukegan Road Suite B, Glenview, IL 60025",
              "phone1": null,
              "phone2": "847-486-8810",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2744",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2945",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2945",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3141",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3141",
                "https://www.gonggershowitz.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-17-lower-ocd-person-cf97f459-e1cd-474b-8306-3c55d7c009b0": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/7d68f174-3504-4479-80c5-e3fbe69fd354",
            "firstName": "Robyn",
            "lastName": "Gabel",
            "fullName": "Robyn Gabel",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{739CC8F9-E9E4-40A1-AB86-883EF9A5E313}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-IL-18-lower-ocd-person-7d68f174-3504-4479-80c5-e3fbe69fd354",
              "builtID": "il-lower-18",
              "externalID": "ocd-person/7d68f174-3504-4479-80c5-e3fbe69fd354",
              "geometry": null
            },
            "contactInfo": {
              "email": "contact@robyngabel.com",
              "contactForm": null,
              "address1": null,
              "address2": "2100 Ridge Ave. Suite 2600, Evanston, IL 60201",
              "phone1": null,
              "phone2": "847-424-5401",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2583",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2825",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2825",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3045",
                "https://www.ilga.gov/house/Rep.asp?GA=101&MemberID=2583",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3045",
                "https://robyngabel.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-18-lower-ocd-person-7d68f174-3504-4479-80c5-e3fbe69fd354": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/ea4595af-64f9-4d91-9c12-48accdc04ff8",
            "firstName": "Lindsey",
            "lastName": "LaPointe",
            "fullName": "Lindsey LaPointe",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{7447C9D9-48D0-457E-BDC9-847FB8D35DB5}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-IL-19-lower-ocd-person-ea4595af-64f9-4d91-9c12-48accdc04ff8",
              "builtID": "il-lower-19",
              "externalID": "ocd-person/ea4595af-64f9-4d91-9c12-48accdc04ff8",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@replapointe.com",
              "contactForm": null,
              "address1": null,
              "address2": "4349 N. Milwaukee Ave. Suite 2, Chicago, IL 60641",
              "phone1": null,
              "phone2": "773-647-1174",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2771",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2972",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2972",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3163",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3163",
                "https://www.replapointe.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-19-lower-ocd-person-ea4595af-64f9-4d91-9c12-48accdc04ff8": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/49c0fac1-8cf0-48d2-8166-811afe9ac5c5",
            "firstName": "Bradley A.",
            "lastName": "Stephens",
            "fullName": "Brad Stephens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{BD3836AC-CD0C-4E20-AD4C-1F2AEA56A8A2}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-IL-20-lower-ocd-person-49c0fac1-8cf0-48d2-8166-811afe9ac5c5",
              "builtID": "il-lower-20",
              "externalID": "ocd-person/49c0fac1-8cf0-48d2-8166-811afe9ac5c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephens@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "5515 N. East River Road, Chicago, IL 60656",
              "phone1": null,
              "phone2": "773-444-0611",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2770",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2971",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2971",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3162",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3162"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-20-lower-ocd-person-49c0fac1-8cf0-48d2-8166-811afe9ac5c5": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/4101db1f-a913-4d16-88fb-001f120bb092",
            "firstName": "Abdelnasser",
            "lastName": "Rashid",
            "fullName": "Abdelnasser Rashid",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{48E00B1D-C5BE-4210-A9A8-F68739D935D6}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-IL-21-lower-ocd-person-4101db1f-a913-4d16-88fb-001f120bb092",
              "builtID": "il-lower-21",
              "externalID": "ocd-person/4101db1f-a913-4d16-88fb-001f120bb092",
              "geometry": null
            },
            "contactInfo": {
              "email": "office@reprashid.com",
              "contactForm": null,
              "address1": null,
              "address2": "6901 W. Cermak Road, Berwyn, IL 60402",
              "phone1": null,
              "phone2": "708-956-7563",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3229",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3229",
                "https://www.reprashid.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-21-lower-ocd-person-4101db1f-a913-4d16-88fb-001f120bb092": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/8b2bc102-d62b-47b2-91d8-5f9b3a172cea",
            "firstName": "Angelica",
            "lastName": "Guerrero-Cuellar",
            "fullName": "Angelica Guerrero-Cuellar",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{22FBB36E-8A65-4E83-81F1-A20247A6B8F2}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-IL-22-lower-ocd-person-8b2bc102-d62b-47b2-91d8-5f9b3a172cea",
              "builtID": "il-lower-22",
              "externalID": "ocd-person/8b2bc102-d62b-47b2-91d8-5f9b3a172cea",
              "geometry": null
            },
            "contactInfo": {
              "email": "angieguerrerocuellar22@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "6500 S. Pulaski Road, Chicago, IL 60629",
              "phone1": null,
              "phone2": "773-581-8000",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=3011",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3199",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3199"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-22-lower-ocd-person-8b2bc102-d62b-47b2-91d8-5f9b3a172cea": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/e8bba8fb-50a8-4ddd-9bed-785c5f1bb70d",
            "firstName": "Edgar",
            "lastName": "Gonzalez",
            "fullName": "Edgar Gonzalez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{370B1494-7F8F-48BC-BD66-4756E15442E1}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-IL-23-lower-ocd-person-e8bba8fb-50a8-4ddd-9bed-785c5f1bb70d",
              "builtID": "il-lower-23",
              "externalID": "ocd-person/e8bba8fb-50a8-4ddd-9bed-785c5f1bb70d",
              "geometry": null
            },
            "contactInfo": {
              "email": "office@repedgargonzalez.com",
              "contactForm": null,
              "address1": null,
              "address2": "3202 W. 26th St., Chicago, IL 60623",
              "phone1": null,
              "phone2": "708-295-9159",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2776",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2975",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2975",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3166",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3166"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-23-lower-ocd-person-e8bba8fb-50a8-4ddd-9bed-785c5f1bb70d": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/c1ebba3c-4fc2-4b66-9ecc-e20aa4f793a9",
            "firstName": "Theresa",
            "lastName": "Mah",
            "fullName": "Theresa Mah",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{808BAA30-BB82-437A-9A76-386F9A65A073}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-IL-24-lower-ocd-person-c1ebba3c-4fc2-4b66-9ecc-e20aa4f793a9",
              "builtID": "il-lower-24",
              "externalID": "ocd-person/c1ebba3c-4fc2-4b66-9ecc-e20aa4f793a9",
              "geometry": null
            },
            "contactInfo": {
              "email": "office@reptheresamah.com",
              "contactForm": null,
              "address1": null,
              "address2": "2108 W. 35th St., Chicago, IL 60609",
              "phone1": null,
              "phone2": "872-281-5775",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2687",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2904",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2904",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3107",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3107",
                "https://www.reptheresamah.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-24-lower-ocd-person-c1ebba3c-4fc2-4b66-9ecc-e20aa4f793a9": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/7a4c9172-6836-453c-94a8-1254b76fa08f",
            "firstName": "Curtis J.",
            "lastName": "Tarver",
            "fullName": "Curtis Tarver",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{E9404756-F108-41DB-A7A8-87C36BBF763D}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-IL-25-lower-ocd-person-7a4c9172-6836-453c-94a8-1254b76fa08f",
              "builtID": "il-lower-25",
              "externalID": "ocd-person/7a4c9172-6836-453c-94a8-1254b76fa08f",
              "geometry": null
            },
            "contactInfo": {
              "email": "office@repcurtisjtarverii.com",
              "contactForm": null,
              "address1": null,
              "address2": "1303 E. 53rd St., Chicago, IL 60615",
              "phone1": null,
              "phone2": "773-363-8870",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2748",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2949",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2949",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3145",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3145"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-25-lower-ocd-person-7a4c9172-6836-453c-94a8-1254b76fa08f": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/8b7174cd-4081-4717-8ba9-184518f7b31e",
            "firstName": "Kambium Elijah",
            "lastName": "Buckner",
            "fullName": "Kam Buckner",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{37E72FD2-6318-45FD-9FF1-C750EB26137D}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-IL-26-lower-ocd-person-8b7174cd-4081-4717-8ba9-184518f7b31e",
              "builtID": "il-lower-26",
              "externalID": "ocd-person/8b7174cd-4081-4717-8ba9-184518f7b31e",
              "geometry": null
            },
            "contactInfo": {
              "email": "buckner@illinois26.com",
              "contactForm": null,
              "address1": null,
              "address2": "449 E. 35th St., Chicago, IL 60616",
              "phone1": null,
              "phone2": "773-924-1755",
              "fax1": null,
              "fax2": "773-924-1775",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2765",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2966",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2966",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3158",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3158",
                "https://www.illinois26.com"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-26-lower-ocd-person-8b7174cd-4081-4717-8ba9-184518f7b31e": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/c296134a-f9b3-4c65-9ed5-3ecccde59386",
            "firstName": "Justin",
            "lastName": "Slaughter",
            "fullName": "Justin Slaughter",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{170C17F3-38A1-42E5-B222-020B43077D53}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-IL-27-lower-ocd-person-c296134a-f9b3-4c65-9ed5-3ecccde59386",
              "builtID": "il-lower-27",
              "externalID": "ocd-person/c296134a-f9b3-4c65-9ed5-3ecccde59386",
              "geometry": null
            },
            "contactInfo": {
              "email": "justin@repslaughter.com",
              "contactForm": null,
              "address1": null,
              "address2": "1234 W. 95th St., Chicago, IL 60643",
              "phone1": null,
              "phone2": "773-445-9700",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2682",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2900",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2900",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3104",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3104",
                "https://repslaughter27.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-27-lower-ocd-person-c296134a-f9b3-4c65-9ed5-3ecccde59386": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/3ce631a4-7a4f-4905-a959-574f1f0d3d6d",
            "firstName": "Robert",
            "lastName": "Rita",
            "fullName": "Bob Rita",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{AC16F1E8-0988-4A08-A2E5-EF4D06371A24}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-IL-28-lower-ocd-person-3ce631a4-7a4f-4905-a959-574f1f0d3d6d",
              "builtID": "il-lower-28",
              "externalID": "ocd-person/3ce631a4-7a4f-4905-a959-574f1f0d3d6d",
              "geometry": null
            },
            "contactInfo": {
              "email": "robertbobrita@aol.com",
              "contactForm": null,
              "address1": null,
              "address2": "2355 W. York St. Suite 1, Blue Island, IL 60406",
              "phone1": null,
              "phone2": "708-396-2822",
              "fax1": null,
              "fax2": "708-396-2898",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2547",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2802",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2802",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3027",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3027"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-28-lower-ocd-person-3ce631a4-7a4f-4905-a959-574f1f0d3d6d": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/a38c9ab5-48b6-417c-baf1-bae5e8f3b7c0",
            "firstName": "Thaddeus M.",
            "lastName": "Jones",
            "fullName": "Thaddeus Jones",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{6EE82A11-2CC6-48E9-9DE6-61D83EBCAB6B}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-IL-29-lower-ocd-person-a38c9ab5-48b6-417c-baf1-bae5e8f3b7c0",
              "builtID": "il-lower-29",
              "externalID": "ocd-person/a38c9ab5-48b6-417c-baf1-bae5e8f3b7c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "repjones.jones@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "15525 S. Park Ave. Suite 104, South Holland, IL 60473",
              "phone1": null,
              "phone2": "708-825-1174",
              "fax1": null,
              "fax2": "708-596-7062",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2591",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2833",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2833",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3050",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3050",
                "https://www.repthaddeusjones.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-29-lower-ocd-person-a38c9ab5-48b6-417c-baf1-bae5e8f3b7c0": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/fcc072f5-5cbc-4c8e-bc3d-e70b0e102878",
            "firstName": "William Quincy",
            "lastName": "Davis",
            "fullName": "Will Davis",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{6012DCA8-7486-49F9-A387-8703A2CF469C}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-IL-30-lower-ocd-person-fcc072f5-5cbc-4c8e-bc3d-e70b0e102878",
              "builtID": "il-lower-30",
              "externalID": "ocd-person/fcc072f5-5cbc-4c8e-bc3d-e70b0e102878",
              "geometry": null
            },
            "contactInfo": {
              "email": "williamd@ilga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1912 W. 174th St., East Hazel Crest, IL 60429",
              "phone1": null,
              "phone2": "708-799-7300",
              "fax1": null,
              "fax2": "708-799-7377",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2544",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2801",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2801",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3026",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3026"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-30-lower-ocd-person-fcc072f5-5cbc-4c8e-bc3d-e70b0e102878": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/bb07ed41-05af-4e1d-82a3-c9da9f89d71c",
            "firstName": "Mary E.",
            "lastName": "Flowers",
            "fullName": "Mary Flowers",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{3049B08D-BD65-4FCF-B82C-D46B0B4CCF4F}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-IL-31-lower-ocd-person-bb07ed41-05af-4e1d-82a3-c9da9f89d71c",
              "builtID": "il-lower-31",
              "externalID": "ocd-person/bb07ed41-05af-4e1d-82a3-c9da9f89d71c",
              "geometry": null
            },
            "contactInfo": {
              "email": "maryeflowers@ilga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2525 W. 79th St., Chicago, IL 60652",
              "phone1": null,
              "phone2": "773-471-5200",
              "fax1": null,
              "fax2": "773-471-1036",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2526",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2790",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2790",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3020",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3020",
                "https://ilhousedems.com/project/rep-mary-e-flowers/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-31-lower-ocd-person-bb07ed41-05af-4e1d-82a3-c9da9f89d71c": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/5767cad7-5716-4153-bd2c-9b8328b23e77",
            "firstName": "Cyril L.",
            "lastName": "Nichols",
            "fullName": "Cyril Nichols",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{E6B2E40B-0892-434C-854D-629B94E609A4}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-IL-32-lower-ocd-person-5767cad7-5716-4153-bd2c-9b8328b23e77",
              "builtID": "il-lower-32",
              "externalID": "ocd-person/5767cad7-5716-4153-bd2c-9b8328b23e77",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep32nd@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "7601 S. Kostner Ave. Suite 100, Chicago, IL 60652",
              "phone1": null,
              "phone2": "773-912-6107",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=3012",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3200",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3200",
                "https://www.repnichols.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-32-lower-ocd-person-5767cad7-5716-4153-bd2c-9b8328b23e77": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/4e02286b-b560-4837-8b9c-efa2c679e437",
            "firstName": "Marcus C.",
            "lastName": "Evans",
            "fullName": "Marcus Evans",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{BFD8B13A-CD65-452C-8F20-9D32C3A013BF}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-IL-33-lower-ocd-person-4e02286b-b560-4837-8b9c-efa2c679e437",
              "builtID": "il-lower-33",
              "externalID": "ocd-person/4e02286b-b560-4837-8b9c-efa2c679e437",
              "geometry": null
            },
            "contactInfo": {
              "email": "marcus@repevans.com",
              "contactForm": null,
              "address1": null,
              "address2": "8500 S. Stony Island Ave., Chicago, IL 60617",
              "phone1": null,
              "phone2": "773-783-8492",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2604",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2843",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2843",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3058",
                "https://www.ilga.gov/house/Rep.asp?GA=101&MemberID=2604",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3058"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-33-lower-ocd-person-4e02286b-b560-4837-8b9c-efa2c679e437": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/a5e32db2-c1a9-4b4a-b42e-d2d541bd5825",
            "firstName": "Nicholas K.",
            "lastName": "Smith",
            "fullName": "Nick Smith",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{4D7D283C-73C8-40F8-A3F0-7FA9301456F5}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-IL-34-lower-ocd-person-a5e32db2-c1a9-4b4a-b42e-d2d541bd5825",
              "builtID": "il-lower-34",
              "externalID": "ocd-person/a5e32db2-c1a9-4b4a-b42e-d2d541bd5825",
              "geometry": null
            },
            "contactInfo": {
              "email": "repsmith34@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "113 E. 95th St. Suite A, Chicago, IL 60619",
              "phone1": null,
              "phone2": "773-783-8800",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2704",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2919",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2919",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3118",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3118",
                "https://www.repsmith34.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-34-lower-ocd-person-a5e32db2-c1a9-4b4a-b42e-d2d541bd5825": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/f2231f6c-1ed7-4106-8909-1d87557b2f04",
            "firstName": "Mary",
            "lastName": "Gill",
            "fullName": "Mary Gill",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{28C54907-9CC9-428D-BD6B-361B91A10441}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-IL-35-lower-ocd-person-f2231f6c-1ed7-4106-8909-1d87557b2f04",
              "builtID": "il-lower-35",
              "externalID": "ocd-person/f2231f6c-1ed7-4106-8909-1d87557b2f04",
              "geometry": null
            },
            "contactInfo": {
              "email": "repmarygill@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "10400 S. Western Ave., Chicago, IL 60643",
              "phone1": null,
              "phone2": "773-445-8128",
              "fax1": null,
              "fax2": "773-672-5144",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3251",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3251"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-35-lower-ocd-person-f2231f6c-1ed7-4106-8909-1d87557b2f04": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/5e4bc692-b5f5-4fc0-999e-7072f74f5f01",
            "firstName": "Kelly M.",
            "lastName": "Burke",
            "fullName": "Kelly Burke",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{5D9078FA-21F1-411F-A447-B5E7299AC314}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-IL-36-lower-ocd-person-5e4bc692-b5f5-4fc0-999e-7072f74f5f01",
              "builtID": "il-lower-36",
              "externalID": "ocd-person/5e4bc692-b5f5-4fc0-999e-7072f74f5f01",
              "geometry": null
            },
            "contactInfo": {
              "email": "kburke@kellyburkerep36.org",
              "contactForm": null,
              "address1": null,
              "address2": "5144 W. 95th St., Oak Lawn, IL 60453",
              "phone1": null,
              "phone2": "708-425-0571",
              "fax1": null,
              "fax2": "708-425-0642",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2590",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2832",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2832",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3049",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3049"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-36-lower-ocd-person-5e4bc692-b5f5-4fc0-999e-7072f74f5f01": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/075c5ab4-12bf-4573-8091-1539a081256e",
            "firstName": "Patrick",
            "lastName": "Sheehan",
            "fullName": "Patrick Sheehan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Patrick_Sheehan_Illinois.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-IL-37-lower-ocd-person-075c5ab4-12bf-4573-8091-1539a081256e",
              "builtID": "il-lower-37",
              "externalID": "ocd-person/075c5ab4-12bf-4573-8091-1539a081256e",
              "geometry": null
            },
            "contactInfo": {
              "email": "repsheehan@hrs.ilga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "11019 Front St. Suite 1W, Mokena, IL 60448",
              "phone1": null,
              "phone2": "708-694-2032",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ilga.gov/house/Rep.asp?MemberID=3260",
                "https://repsheehan.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-37-lower-ocd-person-075c5ab4-12bf-4573-8091-1539a081256e": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/e1d4b5b4-34b8-4d91-ab9f-08c8a361e12a",
            "firstName": "Debbie",
            "lastName": "Meyers-Martin",
            "fullName": "Debbie Meyers-Martin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{E3E2099C-AB6A-442B-B191-3A83D6D3A1E2}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-IL-38-lower-ocd-person-e1d4b5b4-34b8-4d91-ab9f-08c8a361e12a",
              "builtID": "il-lower-38",
              "externalID": "ocd-person/e1d4b5b4-34b8-4d91-ab9f-08c8a361e12a",
              "geometry": null
            },
            "contactInfo": {
              "email": "staterepdebbiemm@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "813 School Ave., Matteson, IL 60443",
              "phone1": null,
              "phone2": "708-833-7010",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2741",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2942",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2942",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3139",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3139",
                "https://www.repdebbiemeyersmartin.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-38-lower-ocd-person-e1d4b5b4-34b8-4d91-ab9f-08c8a361e12a": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/df11068d-eb77-4eb8-90c8-95056891e633",
            "firstName": "William Spector",
            "lastName": "Guzzardi",
            "fullName": "Will Guzzardi",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{C127AF36-0EAE-48B6-8C31-86AAE3935790}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-IL-39-lower-ocd-person-df11068d-eb77-4eb8-90c8-95056891e633",
              "builtID": "il-lower-39",
              "externalID": "ocd-person/df11068d-eb77-4eb8-90c8-95056891e633",
              "geometry": null
            },
            "contactInfo": {
              "email": "will@repguzzardi.com",
              "contactForm": null,
              "address1": null,
              "address2": "3001 N. Central Park Ave., Chicago, IL 60618",
              "phone1": null,
              "phone2": "773-853-2570",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2662",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2884",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2884",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3091",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3091",
                "https://www.repguzzardi.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-39-lower-ocd-person-df11068d-eb77-4eb8-90c8-95056891e633": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/c0d481c5-9d31-4780-b2b0-bc5327291d6b",
            "firstName": "Jaime M.",
            "lastName": "Andrade",
            "fullName": "Jaime Andrade",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{34A19039-7BF5-44A7-9A06-4EC121E52514}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-IL-40-lower-ocd-person-c0d481c5-9d31-4780-b2b0-bc5327291d6b",
              "builtID": "il-lower-40",
              "externalID": "ocd-person/c0d481c5-9d31-4780-b2b0-bc5327291d6b",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@staterep40.com",
              "contactForm": null,
              "address1": null,
              "address2": "4007 N. Sacramento Ave., Chicago, IL 60618",
              "phone1": null,
              "phone2": "773-267-2880",
              "fax1": null,
              "fax2": "773-267-2840",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2645",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2875",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2875",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3084",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3084",
                "https://www.staterep40.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-40-lower-ocd-person-c0d481c5-9d31-4780-b2b0-bc5327291d6b": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/dc08395b-8a74-4795-b104-d7b11c1e42ca",
            "firstName": "Janet",
            "lastName": "Yang Rohr",
            "fullName": "Janet Yang Rohr",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{4B315A2D-F104-4613-BD17-77ECA3955B30}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-IL-41-lower-ocd-person-dc08395b-8a74-4795-b104-d7b11c1e42ca",
              "builtID": "il-lower-41",
              "externalID": "ocd-person/dc08395b-8a74-4795-b104-d7b11c1e42ca",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@repyangrohr.com",
              "contactForm": null,
              "address1": null,
              "address2": "475 River Bend Road Suite 500, Naperville, IL 60540",
              "phone1": null,
              "phone2": "630-296-4157",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2993",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2993",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3182",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3182",
                "https://www.repyangrohr.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-41-lower-ocd-person-dc08395b-8a74-4795-b104-d7b11c1e42ca": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/cb40e6c0-da2d-49ef-aa76-e6896644dadf",
            "firstName": "Terra",
            "lastName": "Costa Howard",
            "fullName": "Terra Costa Howard",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{7A155EE5-0C58-43BC-9B51-65B6E2908BA1}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-IL-42-lower-ocd-person-cb40e6c0-da2d-49ef-aa76-e6896644dadf",
              "builtID": "il-lower-42",
              "externalID": "ocd-person/cb40e6c0-da2d-49ef-aa76-e6896644dadf",
              "geometry": null
            },
            "contactInfo": {
              "email": "reptch42@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "913 S. Main St., Lombard, IL 60148",
              "phone1": null,
              "phone2": "630-812-9292",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2739",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2940",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2940",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3137",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3137",
                "https://reptch42.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-42-lower-ocd-person-cb40e6c0-da2d-49ef-aa76-e6896644dadf": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/f38a2b8a-b0e4-4e7d-92cf-7855d42e9c2d",
            "firstName": "Anna B.",
            "lastName": "Moeller",
            "fullName": "Anna Moeller",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{DCE9D682-0582-49BD-AD67-98C2502D89C2}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-IL-43-lower-ocd-person-f38a2b8a-b0e4-4e7d-92cf-7855d42e9c2d",
              "builtID": "il-lower-43",
              "externalID": "ocd-person/f38a2b8a-b0e4-4e7d-92cf-7855d42e9c2d",
              "geometry": null
            },
            "contactInfo": {
              "email": "staterepmoeller@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "164 Division St. Suite 103, Elgin, IL 60120",
              "phone1": null,
              "phone2": "847-841-7130",
              "fax1": null,
              "fax2": "847-841-7140",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2647",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2876",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2876",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3085",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3085"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-43-lower-ocd-person-f38a2b8a-b0e4-4e7d-92cf-7855d42e9c2d": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/3806ac4f-5290-4eb7-ba30-aa406036dd7a",
            "firstName": "Fred",
            "lastName": "Crespo",
            "fullName": "Fred Crespo",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{B92F7A2F-6517-4A3F-87B3-56FAA240285F}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-IL-44-lower-ocd-person-3806ac4f-5290-4eb7-ba30-aa406036dd7a",
              "builtID": "il-lower-44",
              "externalID": "ocd-person/3806ac4f-5290-4eb7-ba30-aa406036dd7a",
              "geometry": null
            },
            "contactInfo": {
              "email": "fred@fredcrespo.com",
              "contactForm": null,
              "address1": null,
              "address2": "1014 E. Schaumburg Road, Streamwood, IL 60107",
              "phone1": null,
              "phone2": "630-372-3340",
              "fax1": null,
              "fax2": "630-372-3342",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2569",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2817",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2817",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3038",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3038",
                "https://fredcrespo.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-44-lower-ocd-person-3806ac4f-5290-4eb7-ba30-aa406036dd7a": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/09e0a21a-a775-4732-a163-a06d7f20a781",
            "firstName": "Jennifer Lynn",
            "lastName": "Ladisch-Douglass",
            "fullName": "Jenn Ladisch-Douglass",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{09FFEEE2-1C33-42A0-951E-2875693775FE}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-IL-45-lower-ocd-person-09e0a21a-a775-4732-a163-a06d7f20a781",
              "builtID": "il-lower-45",
              "externalID": "ocd-person/09e0a21a-a775-4732-a163-a06d7f20a781",
              "geometry": null
            },
            "contactInfo": {
              "email": "office.repjld@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "Westmont Center 1 S. Cass Ave., Suite 103-104, Westmont, IL 60559",
              "phone1": null,
              "phone2": "630-413-1080",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3230",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3230"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-45-lower-ocd-person-09e0a21a-a775-4732-a163-a06d7f20a781": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/d5c22b1c-8057-40bf-aea6-0298070ad67b",
            "firstName": "Diane",
            "lastName": "Blair-Sherlock",
            "fullName": "Diane Blair-Sherlock",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{C9D7B27E-2484-4917-A5CA-F85BA458950A}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-IL-46-lower-ocd-person-d5c22b1c-8057-40bf-aea6-0298070ad67b",
              "builtID": "il-lower-46",
              "externalID": "ocd-person/d5c22b1c-8057-40bf-aea6-0298070ad67b",
              "geometry": null
            },
            "contactInfo": {
              "email": "staterep46@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "28 Villa Ave., Villa Park, IL 60148",
              "phone1": null,
              "phone2": "630-415-3520",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3234",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3234"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-46-lower-ocd-person-d5c22b1c-8057-40bf-aea6-0298070ad67b": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/f8dc94db-66cf-4d5e-b1af-0b6307140524",
            "firstName": "Amy Herzing",
            "lastName": "Grant",
            "fullName": "Amy Grant",
            "gender": "Female",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{41DCC207-977E-45DC-BD10-A8AFDCEB673E}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-IL-47-lower-ocd-person-f8dc94db-66cf-4d5e-b1af-0b6307140524",
              "builtID": "il-lower-47",
              "externalID": "ocd-person/f8dc94db-66cf-4d5e-b1af-0b6307140524",
              "geometry": null
            },
            "contactInfo": {
              "email": "grant@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "416 E. Roosevelt Road Suite 104, Wheaton, IL 60187",
              "phone1": null,
              "phone2": "331-218-4182",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2754",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2955",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2955",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3150",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3150",
                "https://repgrant.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-47-lower-ocd-person-f8dc94db-66cf-4d5e-b1af-0b6307140524": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/0069021e-b341-44fd-9d02-d876839c5c23",
            "firstName": "Jennifer",
            "lastName": "Sanalitro",
            "fullName": "Jennifer Sanalitro",
            "gender": "Female",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{07C35999-DEF2-49BA-8A46-7D9D8204BCEA}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-IL-48-lower-ocd-person-0069021e-b341-44fd-9d02-d876839c5c23",
              "builtID": "il-lower-48",
              "externalID": "ocd-person/0069021e-b341-44fd-9d02-d876839c5c23",
              "geometry": null
            },
            "contactInfo": {
              "email": "sanalitro@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "962 W. Army Trail Road, Carol Stream, IL 60188",
              "phone1": null,
              "phone2": "630-349-0645",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3231",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3231",
                "https://repsanalitro.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-48-lower-ocd-person-0069021e-b341-44fd-9d02-d876839c5c23": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/ef519ea9-e143-44e0-95b2-49d37c4ea3c8",
            "firstName": "Maura Welch",
            "lastName": "Hirschauer",
            "fullName": "Maura Hirschauer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{F62525B8-2C23-49B7-AAB8-EFA6AE575F68}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-IL-49-lower-ocd-person-ef519ea9-e143-44e0-95b2-49d37c4ea3c8",
              "builtID": "il-lower-49",
              "externalID": "ocd-person/ef519ea9-e143-44e0-95b2-49d37c4ea3c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "office@repmaura49.com",
              "contactForm": null,
              "address1": null,
              "address2": "946 Neltnor Blvd. #104, West Chicago, IL 60185",
              "phone1": null,
              "phone2": "331-465-9661",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2995",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2995",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3184",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3184",
                "https://www.maurahirschauer.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-49-lower-ocd-person-ef519ea9-e143-44e0-95b2-49d37c4ea3c8": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/fb481641-3999-4fe4-9bac-878becdde97d",
            "firstName": "Barbara",
            "lastName": "Hernandez",
            "fullName": "Barbara Hernandez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{E3518E4F-7AAF-4D93-8985-336197D96094}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-IL-50-lower-ocd-person-fb481641-3999-4fe4-9bac-878becdde97d",
              "builtID": "il-lower-50",
              "externalID": "ocd-person/fb481641-3999-4fe4-9bac-878becdde97d",
              "geometry": null
            },
            "contactInfo": {
              "email": "repbarbarahernandez@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "1 E. Benton St., Suite 101, Aurora, IL 60505",
              "phone1": null,
              "phone2": "630-270-1848",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2766",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2967",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2967",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3159",
                "https://www.ilga.gov/house/Rep.asp?GA=102&MemberID=2967",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3224"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-50-lower-ocd-person-fb481641-3999-4fe4-9bac-878becdde97d": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/3115335b-b84e-49e5-b0ed-8ee2ab139784",
            "firstName": "Nabeela",
            "lastName": "Syed",
            "fullName": "Nabeela Syed",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{1B81D1C2-BEE3-408C-B52D-8B269BC5DA24}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-IL-51-lower-ocd-person-3115335b-b84e-49e5-b0ed-8ee2ab139784",
              "builtID": "il-lower-51",
              "externalID": "ocd-person/3115335b-b84e-49e5-b0ed-8ee2ab139784",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@repsyed.com",
              "contactForm": null,
              "address1": null,
              "address2": "830 W. NW Highway Suite 9, Palatine, IL 60067",
              "phone1": null,
              "phone2": "773-916-6553",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3232",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3232",
                "https://www.repnabeelasyed.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-51-lower-ocd-person-3115335b-b84e-49e5-b0ed-8ee2ab139784": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/eb9a9c6e-1173-4c03-848e-167eb2762673",
            "firstName": "Martin J.",
            "lastName": "McLaughlin",
            "fullName": "Marty McLaughlin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{F90CD638-AB1B-4A73-A003-02D9A911B599}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-IL-52-lower-ocd-person-eb9a9c6e-1173-4c03-848e-167eb2762673",
              "builtID": "il-lower-52",
              "externalID": "ocd-person/eb9a9c6e-1173-4c03-848e-167eb2762673",
              "geometry": null
            },
            "contactInfo": {
              "email": "mclaughlin@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "28662 W. NW Highway Suite A, Lake Barrington, IL 60010",
              "phone1": null,
              "phone2": "224-634-8300",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2997",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2997",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3185",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3185",
                "https://repmclaughlin.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-52-lower-ocd-person-eb9a9c6e-1173-4c03-848e-167eb2762673": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/0394df12-4d09-4844-80aa-269ccc9c1d54",
            "firstName": "Nicolle See",
            "lastName": "Grasse",
            "fullName": "Nicolle Grasse",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/NicoleGrasse2024.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-IL-53-lower-ocd-person-0394df12-4d09-4844-80aa-269ccc9c1d54",
              "builtID": "il-lower-53",
              "externalID": "ocd-person/0394df12-4d09-4844-80aa-269ccc9c1d54",
              "geometry": null
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": "171 W. Wing St. Suite 202, Arlington Heights, IL 60005",
              "phone1": null,
              "phone2": "847-749-1137",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3262"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-53-lower-ocd-person-0394df12-4d09-4844-80aa-269ccc9c1d54": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/48d73b90-0109-4fb2-8689-57e803c8294a",
            "firstName": "Mary Beth",
            "lastName": "Canty",
            "fullName": "Mary Beth Canty",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{1A73F23E-9C8D-4392-B6BD-154AB570C66E}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-IL-54-lower-ocd-person-48d73b90-0109-4fb2-8689-57e803c8294a",
              "builtID": "il-lower-54",
              "externalID": "ocd-person/48d73b90-0109-4fb2-8689-57e803c8294a",
              "geometry": null
            },
            "contactInfo": {
              "email": "info.repmbc@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "12 E. NW Highway, Arlington Heights, IL 60004",
              "phone1": null,
              "phone2": "224-248-8187",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3233",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3233"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-54-lower-ocd-person-48d73b90-0109-4fb2-8689-57e803c8294a": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/5f757cd0-f3a7-413d-9235-ea59f63d23c6",
            "firstName": "Martin J.",
            "lastName": "Moylan",
            "fullName": "Marty Moylan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{3E365092-6B37-486C-996B-C140284F8723}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-IL-55-lower-ocd-person-5f757cd0-f3a7-413d-9235-ea59f63d23c6",
              "builtID": "il-lower-55",
              "externalID": "ocd-person/5f757cd0-f3a7-413d-9235-ea59f63d23c6",
              "geometry": null
            },
            "contactInfo": {
              "email": "staterepmoylan@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "24 S. Des Plaines River Road Suite 400, Des Plaines, IL 60016",
              "phone1": null,
              "phone2": "847-635-6821",
              "fax1": null,
              "fax2": "847-635-8565",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2633",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2865",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2865",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3074",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3074"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-55-lower-ocd-person-5f757cd0-f3a7-413d-9235-ea59f63d23c6": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/394eec6a-ad20-447e-b72c-f09d76e66fa2",
            "firstName": "Michelle",
            "lastName": "Mussman",
            "fullName": "Michelle Mussman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{63BBD5DE-333E-4015-8658-4F7B2D45E1B7}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-IL-56-lower-ocd-person-394eec6a-ad20-447e-b72c-f09d76e66fa2",
              "builtID": "il-lower-56",
              "externalID": "ocd-person/394eec6a-ad20-447e-b72c-f09d76e66fa2",
              "geometry": null
            },
            "contactInfo": {
              "email": "staterepmussman@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "11 N. Roselle Road, Schaumburg, IL 60194",
              "phone1": null,
              "phone2": "847-923-9104",
              "fax1": null,
              "fax2": "847-923-9105",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2593",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2835",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2835",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3052",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3052"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-56-lower-ocd-person-394eec6a-ad20-447e-b72c-f09d76e66fa2": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/4b7dfbae-dc1a-4226-9b42-00c8bbc46b06",
            "firstName": "Tracy",
            "lastName": "Katz Muhl",
            "fullName": "Tracy Katz Muhl",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-IL-57-lower-ocd-person-4b7dfbae-dc1a-4226-9b42-00c8bbc46b06",
              "builtID": "il-lower-57",
              "externalID": "ocd-person/4b7dfbae-dc1a-4226-9b42-00c8bbc46b06",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@staterepkatzmuhl.com",
              "contactForm": null,
              "address1": null,
              "address2": "3153 Dundee Road, Northbrook, IL 60062",
              "phone1": null,
              "phone2": "847-715-9055",
              "fax1": null,
              "fax2": "847-377-9497",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3259"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-57-lower-ocd-person-4b7dfbae-dc1a-4226-9b42-00c8bbc46b06": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/55a824f4-04c7-4834-8a5d-d204ca3bece5",
            "firstName": "Bob",
            "lastName": "Morgan",
            "fullName": "Bob Morgan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{A3460802-617C-4097-B043-6D63A11278AC}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-IL-58-lower-ocd-person-55a824f4-04c7-4834-8a5d-d204ca3bece5",
              "builtID": "il-lower-58",
              "externalID": "ocd-person/55a824f4-04c7-4834-8a5d-d204ca3bece5",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@repbobmorgan.com",
              "contactForm": null,
              "address1": null,
              "address2": "425 Sheridan Road, Highwood, IL 60040",
              "phone1": null,
              "phone2": "847-780-8471",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2745",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2946",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2946",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3142",
                "https://www.repbobmorgan.com/",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3142",
                "https://repbobmorgan.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-58-lower-ocd-person-55a824f4-04c7-4834-8a5d-d204ca3bece5": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/c5786e99-12fd-41eb-b40e-372187dde261",
            "firstName": "Daniel C.",
            "lastName": "Didech",
            "fullName": "Dan Didech",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{1EA869A9-B93D-4E72-824B-E14992452581}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-IL-59-lower-ocd-person-c5786e99-12fd-41eb-b40e-372187dde261",
              "builtID": "il-lower-59",
              "externalID": "ocd-person/c5786e99-12fd-41eb-b40e-372187dde261",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@repdidech.com",
              "contactForm": null,
              "address1": null,
              "address2": "Vernon Township Administrative Building 3050 N. Main St., Buffalo Grove, IL 60089",
              "phone1": null,
              "phone2": "847-478-9909",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2737",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2938",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2938",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3136",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3136",
                "https://www.repdidech.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-59-lower-ocd-person-c5786e99-12fd-41eb-b40e-372187dde261": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/cb9d1283-1029-46cf-8507-1e7ed6937606",
            "firstName": "Rita",
            "lastName": "Mayfield",
            "fullName": "Rita Mayfield",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{3A2DAB8B-B5F8-48EE-85D3-05894ED42A5D}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-IL-60-lower-ocd-person-cb9d1283-1029-46cf-8507-1e7ed6937606",
              "builtID": "il-lower-60",
              "externalID": "ocd-person/cb9d1283-1029-46cf-8507-1e7ed6937606",
              "geometry": null
            },
            "contactInfo": {
              "email": "60thdistrict@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "120 S. Genesee St., Waukegan, IL 60085",
              "phone1": null,
              "phone2": "847-599-2800",
              "fax1": null,
              "fax2": "224-214-3733",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2585",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2827",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2827",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3047",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3047",
                "https://www.ritamayfield60.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-60-lower-ocd-person-cb9d1283-1029-46cf-8507-1e7ed6937606": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/96f00b4c-0a56-4fe0-bd59-7d49eb93531c",
            "firstName": "Joyce",
            "lastName": "Mason",
            "fullName": "Joyce Mason",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{26C52E17-9395-4BF8-B6E6-3E1CE6234032}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-IL-61-lower-ocd-person-96f00b4c-0a56-4fe0-bd59-7d49eb93531c",
              "builtID": "il-lower-61",
              "externalID": "ocd-person/96f00b4c-0a56-4fe0-bd59-7d49eb93531c",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@repjoycemason.com",
              "contactForm": null,
              "address1": null,
              "address2": "34498 Old Walnut Circle Unit C, Gurnee, IL 60031",
              "phone1": null,
              "phone2": "847-485-9986",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2751",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2952",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2952",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3147",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3147",
                "https://www.repjoycemason.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-61-lower-ocd-person-96f00b4c-0a56-4fe0-bd59-7d49eb93531c": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/f0ab36db-6498-4d90-9b11-0191637421d2",
            "firstName": "Laura Faver",
            "lastName": "Dias",
            "fullName": "Laura Dias",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{B0DFD215-F36D-47A7-B7BE-909CDF14DC07}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-IL-62-lower-ocd-person-f0ab36db-6498-4d90-9b11-0191637421d2",
              "builtID": "il-lower-62",
              "externalID": "ocd-person/f0ab36db-6498-4d90-9b11-0191637421d2",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@repfaverdias.com",
              "contactForm": null,
              "address1": null,
              "address2": "10 N. Lake St. Suite 102, Grayslake, IL 60030",
              "phone1": null,
              "phone2": "224-541-4453",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3235",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3235"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-62-lower-ocd-person-f0ab36db-6498-4d90-9b11-0191637421d2": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/56914fff-eda2-403a-869a-895764908cd9",
            "firstName": "Steven",
            "lastName": "Reick",
            "fullName": "Steve Reick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{D4845B6E-9A2E-4F0E-9F70-503B2E5891CA}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-IL-63-lower-ocd-person-56914fff-eda2-403a-869a-895764908cd9",
              "builtID": "il-lower-63",
              "externalID": "ocd-person/56914fff-eda2-403a-869a-895764908cd9",
              "geometry": null
            },
            "contactInfo": {
              "email": "reick@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "1072 Lake Ave., Woodstock, IL 60098",
              "phone1": null,
              "phone2": "815-880-5340",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2690",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2907",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2907",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3109",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3109",
                "https://repstevenreick.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-63-lower-ocd-person-56914fff-eda2-403a-869a-895764908cd9": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/ba3dd226-a1c6-47af-917a-e12696d5aae5",
            "firstName": "Tom",
            "lastName": "Weber",
            "fullName": "Tom Weber",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{4C80DF49-91D2-4C24-981E-BBE946A671D2}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-IL-64-lower-ocd-person-ba3dd226-a1c6-47af-917a-e12696d5aae5",
              "builtID": "il-lower-64",
              "externalID": "ocd-person/ba3dd226-a1c6-47af-917a-e12696d5aae5",
              "geometry": null
            },
            "contactInfo": {
              "email": "weber@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "74 E. Grand Ave. Suite 104, Fox Lake, IL 60020",
              "phone1": null,
              "phone2": "847-629-5439",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2755",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2956",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2956",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3151",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3151",
                "https://repweber.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-64-lower-ocd-person-ba3dd226-a1c6-47af-917a-e12696d5aae5": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/ab6db17a-e7dc-43c8-9770-32c1da1d7f40",
            "firstName": "Dan",
            "lastName": "Ugaste",
            "fullName": "Dan Ugaste",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{2552FBE4-D977-4283-B00C-78FC085BD955}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-IL-65-lower-ocd-person-ab6db17a-e7dc-43c8-9770-32c1da1d7f40",
              "builtID": "il-lower-65",
              "externalID": "ocd-person/ab6db17a-e7dc-43c8-9770-32c1da1d7f40",
              "geometry": null
            },
            "contactInfo": {
              "email": "ugaste@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "406 Surrey Woods Drive Unit A, St. Charles, IL 60174",
              "phone1": null,
              "phone2": "630-797-5530",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2753",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2954",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2954",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3149",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3149",
                "https://repugaste.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-65-lower-ocd-person-ab6db17a-e7dc-43c8-9770-32c1da1d7f40": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/68505842-e93c-42ce-adc8-9354f4a0db7a",
            "firstName": "Suzanne M.",
            "lastName": "Ness",
            "fullName": "Suzanne Ness",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{735B1C5A-1929-4EC8-92B4-72E58D74E743}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-IL-66-lower-ocd-person-68505842-e93c-42ce-adc8-9354f4a0db7a",
              "builtID": "il-lower-66",
              "externalID": "ocd-person/68505842-e93c-42ce-adc8-9354f4a0db7a",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@repsnessil66.com",
              "contactForm": null,
              "address1": null,
              "address2": "20 S. Grove St., Carpentersville, IL 60110",
              "phone1": null,
              "phone2": "224-484-8620",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=3005",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=3005",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3193",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3193",
                "https://repsnessil66.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-66-lower-ocd-person-68505842-e93c-42ce-adc8-9354f4a0db7a": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/77906cdf-58a8-4329-bb24-c65e6e38342f",
            "firstName": "Maurice A.",
            "lastName": "West",
            "fullName": "Maurice West",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{AFB160BE-629B-4C70-8541-877D6FCDE529}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-IL-67-lower-ocd-person-77906cdf-58a8-4329-bb24-c65e6e38342f",
              "builtID": "il-lower-67",
              "externalID": "ocd-person/77906cdf-58a8-4329-bb24-c65e6e38342f",
              "geometry": null
            },
            "contactInfo": {
              "email": "assistance@staterepwest.com",
              "contactForm": null,
              "address1": null,
              "address2": "303 N. Main St. Suite 110, Rockford, IL 61101",
              "phone1": null,
              "phone2": "815-987-7433",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2736",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2937",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2937",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3135",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3135",
                "https://www.staterepwest.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-67-lower-ocd-person-77906cdf-58a8-4329-bb24-c65e6e38342f": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/5da7b2ff-c086-49cd-80bc-9802e426cc00",
            "firstName": "David A.",
            "lastName": "Vella",
            "fullName": "Dave Vella",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{E0F72B4C-2737-483E-84FE-E596DB8F2DCF}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-IL-68-lower-ocd-person-5da7b2ff-c086-49cd-80bc-9802e426cc00",
              "builtID": "il-lower-68",
              "externalID": "ocd-person/5da7b2ff-c086-49cd-80bc-9802e426cc00",
              "geometry": null
            },
            "contactInfo": {
              "email": "repdavevella@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "4007 N. Mulford Road, Loves Park, IL 61111",
              "phone1": null,
              "phone2": "815-329-6741",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2998",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2998",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3186",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3186",
                "https://www.staterepdavevella.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-68-lower-ocd-person-5da7b2ff-c086-49cd-80bc-9802e426cc00": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/6471a51f-0707-4966-b122-9597554d3c19",
            "firstName": "Joe C.",
            "lastName": "Sosnowski",
            "fullName": "Joe Sosnowski",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{7DEBF89C-D2BA-4952-94A2-40F564A00144}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-IL-69-lower-ocd-person-6471a51f-0707-4966-b122-9597554d3c19",
              "builtID": "il-lower-69",
              "externalID": "ocd-person/6471a51f-0707-4966-b122-9597554d3c19",
              "geometry": null
            },
            "contactInfo": {
              "email": "sosnowski@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "1700 Hutchins Road Unit B, Machesney Park, IL 61115",
              "phone1": null,
              "phone2": "815-547-3436",
              "fax1": null,
              "fax2": "815-516-8434",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2594",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2836",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2836",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3053",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3053",
                "https://joesosnowski.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-69-lower-ocd-person-6471a51f-0707-4966-b122-9597554d3c19": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/1055f952-0000-4628-9584-c2a3fc9e991c",
            "firstName": "Jeff",
            "lastName": "Keicher",
            "fullName": "Jeff Keicher",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{FD009EA6-624D-45C7-A093-BC66B8B3C090}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-IL-70-lower-ocd-person-1055f952-0000-4628-9584-c2a3fc9e991c",
              "builtID": "il-lower-70",
              "externalID": "ocd-person/1055f952-0000-4628-9584-c2a3fc9e991c",
              "geometry": null
            },
            "contactInfo": {
              "email": "keicher@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "158 W. State St. Suite C, Sycamore, IL 60178",
              "phone1": null,
              "phone2": "815-748-3494",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2706",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2921",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2921",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3120",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3120",
                "https://repkeicher.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-70-lower-ocd-person-1055f952-0000-4628-9584-c2a3fc9e991c": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/6e9ee3b5-fb44-49f3-81f6-5a470bfaf009",
            "firstName": "Daniel M.",
            "lastName": "Swanson",
            "fullName": "Dan Swanson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{F7512D74-4694-4272-9A1E-6935F4AD4F1D}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-IL-71-lower-ocd-person-6e9ee3b5-fb44-49f3-81f6-5a470bfaf009",
              "builtID": "il-lower-71",
              "externalID": "ocd-person/6e9ee3b5-fb44-49f3-81f6-5a470bfaf009",
              "geometry": null
            },
            "contactInfo": {
              "email": "swanson@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 173 275 N. Division St., Woodhull, IL 61490",
              "phone1": null,
              "phone2": "309-334-7474",
              "fax1": null,
              "fax2": "309-334-8000",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2696",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2912",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2912",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3113",
                "https://repswanson.com/",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3113"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-71-lower-ocd-person-6e9ee3b5-fb44-49f3-81f6-5a470bfaf009": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/26a0642d-d0b6-450f-9438-018a36b04cbf",
            "firstName": "Gregg",
            "lastName": "Johnson",
            "fullName": "Gregg Johnson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{8746A238-F14D-428E-A0AD-E3D3F28F80F5}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-IL-72-lower-ocd-person-26a0642d-d0b6-450f-9438-018a36b04cbf",
              "builtID": "il-lower-72",
              "externalID": "ocd-person/26a0642d-d0b6-450f-9438-018a36b04cbf",
              "geometry": null
            },
            "contactInfo": {
              "email": "support@repgreggjohnson.com",
              "contactForm": null,
              "address1": null,
              "address2": "945 15th Ave., East Moline, IL 61244",
              "phone1": null,
              "phone2": "309-203-1668",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3236",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3236"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-72-lower-ocd-person-26a0642d-d0b6-450f-9438-018a36b04cbf": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/139cce29-08b9-4731-a7e9-bdeec8129eaa",
            "firstName": "Ryan",
            "lastName": "Spain",
            "fullName": "Ryan Spain",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{4C25C70B-0E39-406B-9318-A899C2A6D1B0}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-IL-73-lower-ocd-person-139cce29-08b9-4731-a7e9-bdeec8129eaa",
              "builtID": "il-lower-73",
              "externalID": "ocd-person/139cce29-08b9-4731-a7e9-bdeec8129eaa",
              "geometry": null
            },
            "contactInfo": {
              "email": "repryanspain@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "5407 N. University St., Arbor Hall Suite B, Peoria, IL 61614",
              "phone1": null,
              "phone2": "309-690-7373",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2693",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2910",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2910",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3111",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3111"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-73-lower-ocd-person-139cce29-08b9-4731-a7e9-bdeec8129eaa": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/be767c3e-58d9-41c0-b930-79c0f47b9960",
            "firstName": "Bradley J.",
            "lastName": "Fritts",
            "fullName": "Brad Fritts",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{3A924147-7119-4921-BDF6-14B5D5BA4B8D}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-IL-74-lower-ocd-person-be767c3e-58d9-41c0-b930-79c0f47b9960",
              "builtID": "il-lower-74",
              "externalID": "ocd-person/be767c3e-58d9-41c0-b930-79c0f47b9960",
              "geometry": null
            },
            "contactInfo": {
              "email": "fritts@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "101 W. 1st St., Suite 501, Dixon, IL 61021",
              "phone1": null,
              "phone2": "815-561-3690",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3237",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3237",
                "https://repfritts.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-74-lower-ocd-person-be767c3e-58d9-41c0-b930-79c0f47b9960": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/a9c919a3-8fba-4bad-8589-315491cd9b0e",
            "firstName": "Jed",
            "lastName": "Davis",
            "fullName": "Jed Davis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{AFBDE3F1-DE4D-419A-A7AB-D27678B015BE}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-IL-75-lower-ocd-person-a9c919a3-8fba-4bad-8589-315491cd9b0e",
              "builtID": "il-lower-75",
              "externalID": "ocd-person/a9c919a3-8fba-4bad-8589-315491cd9b0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "davisstaff@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "1925 S. Bridge St., Yorkville, IL 60560",
              "phone1": null,
              "phone2": "331-867-8200",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3223",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3223",
                "https://repjeddavis.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-75-lower-ocd-person-a9c919a3-8fba-4bad-8589-315491cd9b0e": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/652fcf63-73c6-4983-8e61-55227ba5720b",
            "firstName": "Lance",
            "lastName": "Yednock",
            "fullName": "Lance Yednock",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{2F9A6E55-09F7-4C26-AEEC-9A9D32773201}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-IL-76-lower-ocd-person-652fcf63-73c6-4983-8e61-55227ba5720b",
              "builtID": "il-lower-76",
              "externalID": "ocd-person/652fcf63-73c6-4983-8e61-55227ba5720b",
              "geometry": null
            },
            "contactInfo": {
              "email": "staterepyednock@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "628 Columbus St. Suite 408, Ottawa, IL 61350",
              "phone1": null,
              "phone2": "815-324-5055",
              "fax1": null,
              "fax2": "815-324-5040",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2750",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2951",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2951",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3146",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3146"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-76-lower-ocd-person-652fcf63-73c6-4983-8e61-55227ba5720b": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/c0d6eed8-422f-4e17-a4df-5e546b704e91",
            "firstName": "Norma",
            "lastName": "Hernandez",
            "fullName": "Norma Hernandez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{3E520E2E-5D7A-491B-9744-0F0137816162}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-IL-77-lower-ocd-person-c0d6eed8-422f-4e17-a4df-5e546b704e91",
              "builtID": "il-lower-77",
              "externalID": "ocd-person/c0d6eed8-422f-4e17-a4df-5e546b704e91",
              "geometry": null
            },
            "contactInfo": {
              "email": "district@repnormahernandez.com",
              "contactForm": null,
              "address1": null,
              "address2": "125 N. 19th Ave. Suite B, Melrose Park, IL 60160",
              "phone1": null,
              "phone2": "708-365-8498",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3224",
                "https://ilrep24.com/",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3224",
                "https://repnormahernandez.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-77-lower-ocd-person-c0d6eed8-422f-4e17-a4df-5e546b704e91": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/a51455ff-b0ae-47f6-b3ee-2722adbe38c2",
            "firstName": "Camille Y.",
            "lastName": "Lilly",
            "fullName": "Camille Lilly",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{84D8FB64-9F82-405A-9376-A69ADFB46E53}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-IL-78-lower-ocd-person-a51455ff-b0ae-47f6-b3ee-2722adbe38c2",
              "builtID": "il-lower-78",
              "externalID": "ocd-person/a51455ff-b0ae-47f6-b3ee-2722adbe38c2",
              "geometry": null
            },
            "contactInfo": {
              "email": "staterepcamilleylilly@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "6937 W. North Ave., Oak Park, IL 60302",
              "phone1": null,
              "phone2": "773-473-7300",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2584",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2826",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2826",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3046",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3046",
                "https://state-representative-camille-y-lilly.webflow.io/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-78-lower-ocd-person-a51455ff-b0ae-47f6-b3ee-2722adbe38c2": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/5da74880-f9e4-4c20-bc17-74d88526a7a4",
            "firstName": "Jackie LaMotte",
            "lastName": "Haas",
            "fullName": "Jackie Haas",
            "gender": "Female",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{9E211C98-BCB9-43CF-8A78-57D295FB9D78}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-IL-79-lower-ocd-person-5da74880-f9e4-4c20-bc17-74d88526a7a4",
              "builtID": "il-lower-79",
              "externalID": "ocd-person/5da74880-f9e4-4c20-bc17-74d88526a7a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "haas@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "370 E. Court St., Kankakee, IL 60901",
              "phone1": null,
              "phone2": "815-523-7779",
              "fax1": null,
              "fax2": "815-304-5987",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2981",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2981",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3171",
                "https://rephaas.com/",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3171"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-79-lower-ocd-person-5da74880-f9e4-4c20-bc17-74d88526a7a4": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/aecf9fb4-3e54-4963-941f-8dc6b4658584",
            "firstName": "Anthony J.",
            "lastName": "DeLuca",
            "fullName": "Anthony DeLuca",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{64399002-8B14-4D8E-80A4-54A77F25421C}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-IL-80-lower-ocd-person-aecf9fb4-3e54-4963-941f-8dc6b4658584",
              "builtID": "il-lower-80",
              "externalID": "ocd-person/aecf9fb4-3e54-4963-941f-8dc6b4658584",
              "geometry": null
            },
            "contactInfo": {
              "email": "repdeluca@sbcglobal.net",
              "contactForm": null,
              "address1": null,
              "address2": "195 W. Joe Orr Road Suite 201, Chicago Heights, IL 60411",
              "phone1": null,
              "phone2": "708-754-7900",
              "fax1": null,
              "fax2": "708-754-7904",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2581",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2824",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2824",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3044",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3044",
                "https://anthonydeluca.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-80-lower-ocd-person-aecf9fb4-3e54-4963-941f-8dc6b4658584": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/8dfe2e37-8a40-4be0-b44d-0bf65861f4e0",
            "firstName": "Anne M.",
            "lastName": "Stava-Murray",
            "fullName": "Anne Stava-Murray",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{0322F94E-013C-4237-9808-4462138AC595}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-IL-81-lower-ocd-person-8dfe2e37-8a40-4be0-b44d-0bf65861f4e0",
              "builtID": "il-lower-81",
              "externalID": "ocd-person/8dfe2e37-8a40-4be0-b44d-0bf65861f4e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "office@repstavamurray.com",
              "contactForm": null,
              "address1": null,
              "address2": "633 Rogers St. Suite 110, Downers Grove, IL 60515",
              "phone1": null,
              "phone2": "630-605-0595",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2740",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2941",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2941",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3138",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3138"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-81-lower-ocd-person-8dfe2e37-8a40-4be0-b44d-0bf65861f4e0": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/3846d812-409f-4726-8e46-3624bfe92812",
            "firstName": "Nicole",
            "lastName": "La Ha Zwiercan",
            "fullName": "Nicole La Ha",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-IL-82-lower-ocd-person-3846d812-409f-4726-8e46-3624bfe92812",
              "builtID": "il-lower-82",
              "externalID": "ocd-person/3846d812-409f-4726-8e46-3624bfe92812",
              "geometry": null
            },
            "contactInfo": {
              "email": "repnicolelaha@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "106 Stephen St. Suite 102 B, Lemont, IL 60439",
              "phone1": null,
              "phone2": "630-410-8466",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3256",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3256",
                "https://replaha.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-82-lower-ocd-person-3846d812-409f-4726-8e46-3624bfe92812": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/14b940c9-8843-4709-97a9-9faee25bb201",
            "firstName": "Matt",
            "lastName": "Hanson",
            "fullName": "Matt Hanson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{457B8F0B-1DF6-4107-A5FC-9B2BFAAE1008}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-IL-83-lower-ocd-person-14b940c9-8843-4709-97a9-9faee25bb201",
              "builtID": "il-lower-83",
              "externalID": "ocd-person/14b940c9-8843-4709-97a9-9faee25bb201",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@repmatthanson.com",
              "contactForm": null,
              "address1": null,
              "address2": "1605 W. Wilson, Suite 112, Batavia, IL 60510",
              "phone1": null,
              "phone2": "630-425-3965",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3225",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3225"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-83-lower-ocd-person-14b940c9-8843-4709-97a9-9faee25bb201": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/0ecdbb3f-7593-4959-8b27-4a03a925d927",
            "firstName": "Stephanie A.",
            "lastName": "Kifowit",
            "fullName": "Stephanie Kifowit",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{96E18070-8D3D-4EEC-8432-8A30FEC19E5C}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-IL-84-lower-ocd-person-0ecdbb3f-7593-4959-8b27-4a03a925d927",
              "builtID": "il-lower-84",
              "externalID": "ocd-person/0ecdbb3f-7593-4959-8b27-4a03a925d927",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephanie.kifowit@att.net",
              "contactForm": null,
              "address1": null,
              "address2": "1677 Montgomery Road Suite 116, Aurora, IL 60504",
              "phone1": null,
              "phone2": "630-585-1308",
              "fax1": null,
              "fax2": "630-585-1357",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2637",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2868",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2868",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3077",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3077",
                "https://www.ildistrict84.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-84-lower-ocd-person-0ecdbb3f-7593-4959-8b27-4a03a925d927": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/4c05af2c-7756-4002-a45d-cc9f2f5acbfd",
            "firstName": "Dagmara Lopez",
            "lastName": "Avelar",
            "fullName": "Dee Avelar",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{EFDA6EE7-57E6-4ACA-BE60-02A042FF5D5D}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-IL-85-lower-ocd-person-4c05af2c-7756-4002-a45d-cc9f2f5acbfd",
              "builtID": "il-lower-85",
              "externalID": "ocd-person/4c05af2c-7756-4002-a45d-cc9f2f5acbfd",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@repdagmara.org",
              "contactForm": null,
              "address1": null,
              "address2": "1050 W. Romeo Road, Romeoville, IL 60446",
              "phone1": null,
              "phone2": "815-372-0085",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2999",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2999",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3187",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3187",
                "https://www.repdagmara.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-85-lower-ocd-person-4c05af2c-7756-4002-a45d-cc9f2f5acbfd": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/9eda7be9-b2c6-45e6-bea6-098bd7f47595",
            "firstName": "Lawrence M.",
            "lastName": "Walsh",
            "fullName": "Larry Walsh",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{F11DA3BE-D8FF-48F0-8450-43231B4DD28F}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-IL-86-lower-ocd-person-9eda7be9-b2c6-45e6-bea6-098bd7f47595",
              "builtID": "il-lower-86",
              "externalID": "ocd-person/9eda7be9-b2c6-45e6-bea6-098bd7f47595",
              "geometry": null
            },
            "contactInfo": {
              "email": "statereplarrywalshjr@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "3141 W. Jefferson St., Joliet, IL 60435",
              "phone1": null,
              "phone2": "815-730-8600",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2605",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2844",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2844",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3059",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3059"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-86-lower-ocd-person-9eda7be9-b2c6-45e6-bea6-098bd7f47595": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/fb6cc95a-0c98-41e3-a481-8d5dc51d6c54",
            "firstName": "William E.",
            "lastName": "Hauter",
            "fullName": "Bill Hauter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{999C86E0-F35D-4F02-81BE-F4FEBF930114}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-IL-87-lower-ocd-person-fb6cc95a-0c98-41e3-a481-8d5dc51d6c54",
              "builtID": "il-lower-87",
              "externalID": "ocd-person/fb6cc95a-0c98-41e3-a481-8d5dc51d6c54",
              "geometry": null
            },
            "contactInfo": {
              "email": "hauter@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "133 S. Main St. Suite A, Morton, IL 61550",
              "phone1": null,
              "phone2": "309-819-8701",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3238",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3238",
                "https://rephauter.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-87-lower-ocd-person-fb6cc95a-0c98-41e3-a481-8d5dc51d6c54": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/86887625-168f-4937-a32a-836952ae176c",
            "firstName": "Dan",
            "lastName": "Caulkins",
            "fullName": "Dan Caulkins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{73C533DE-29C5-4EF9-A32C-DC5D8EA1DFE8}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-IL-88-lower-ocd-person-86887625-168f-4937-a32a-836952ae176c",
              "builtID": "il-lower-88",
              "externalID": "ocd-person/86887625-168f-4937-a32a-836952ae176c",
              "geometry": null
            },
            "contactInfo": {
              "email": "caulkins@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "715 W. Imboden Drive, Decatur, IL 62521",
              "phone1": null,
              "phone2": "217-876-1968",
              "fax1": null,
              "fax2": "217-876-1973",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2759",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2960",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2960",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3154",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3154",
                "https://repcaulkins.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-88-lower-ocd-person-86887625-168f-4937-a32a-836952ae176c": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/d6d5becd-7733-4849-b2a2-b543bbfea1ec",
            "firstName": "Tony M.",
            "lastName": "McCombie",
            "fullName": "Tony McCombie",
            "gender": "Female",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{0766A6E9-981F-491B-B3A1-12DF91F1DBDA}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-IL-89-lower-ocd-person-d6d5becd-7733-4849-b2a2-b543bbfea1ec",
              "builtID": "il-lower-89",
              "externalID": "ocd-person/d6d5becd-7733-4849-b2a2-b543bbfea1ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "mccombie@ilhousegop.gov",
              "contactForm": null,
              "address1": null,
              "address2": "9317B Illinois Route 84, Savanna, IL 61074",
              "phone1": null,
              "phone2": "815-291-8989",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2688",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2905",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2905",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3108",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3108"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-89-lower-ocd-person-d6d5becd-7733-4849-b2a2-b543bbfea1ec": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/b442db35-fab4-4318-a07b-0e88c314fbaa",
            "firstName": "John M.",
            "lastName": "Cabello",
            "fullName": "John Cabello",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{9E64DAD4-4264-42B1-AFDA-AB8F652472A3}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-IL-90-lower-ocd-person-b442db35-fab4-4318-a07b-0e88c314fbaa",
              "builtID": "il-lower-90",
              "externalID": "ocd-person/b442db35-fab4-4318-a07b-0e88c314fbaa",
              "geometry": null
            },
            "contactInfo": {
              "email": "cabello@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "1702 Hutchins Road, Machesney Park, IL 61115",
              "phone1": null,
              "phone2": "815-974-0090",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2607",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3239",
                "https://www.ilga.gov/house/Rep.asp?GA=101&MemberID=2607",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3239"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-90-lower-ocd-person-b442db35-fab4-4318-a07b-0e88c314fbaa": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/95032a7e-18c4-40be-b89f-1f61b132533a",
            "firstName": "Sharon",
            "lastName": "Chung",
            "fullName": "Sharon Chung",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{0555ECBC-C0A4-4E09-ABA2-F8D6E8E03246}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-IL-91-lower-ocd-person-95032a7e-18c4-40be-b89f-1f61b132533a",
              "builtID": "il-lower-91",
              "externalID": "ocd-person/95032a7e-18c4-40be-b89f-1f61b132533a",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@repchung.com",
              "contactForm": null,
              "address1": null,
              "address2": "216 N. Center St., Bloomington, IL 61701",
              "phone1": null,
              "phone2": "309-808-2351",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3240",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3240",
                "https://www.repchung.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-91-lower-ocd-person-95032a7e-18c4-40be-b89f-1f61b132533a": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/968d7e1e-e0e8-47f0-a258-586eb6cfbac9",
            "firstName": "Jehan A.",
            "lastName": "Gordon-Booth",
            "fullName": "Jehan Gordon-Booth",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{A81D0C01-0A50-446D-B796-3A5A6D046EB1}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-IL-92-lower-ocd-person-968d7e1e-e0e8-47f0-a258-586eb6cfbac9",
              "builtID": "il-lower-92",
              "externalID": "ocd-person/968d7e1e-e0e8-47f0-a258-586eb6cfbac9",
              "geometry": null
            },
            "contactInfo": {
              "email": "repjgordon@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "300 E. War Memorial Drive Suite 303, Peoria, IL 61614",
              "phone1": null,
              "phone2": "309-681-1992",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2576",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2821",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2821",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3042",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3042",
                "https://www.repgordonbooth.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-92-lower-ocd-person-968d7e1e-e0e8-47f0-a258-586eb6cfbac9": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/63883963-04b0-454c-8d18-81dcca230b55",
            "firstName": "Travis Robert",
            "lastName": "Weaver",
            "fullName": "Travis Weaver",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{0A077509-6D57-4C96-8525-0480C8338827}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-IL-93-lower-ocd-person-63883963-04b0-454c-8d18-81dcca230b55",
              "builtID": "il-lower-93",
              "externalID": "ocd-person/63883963-04b0-454c-8d18-81dcca230b55",
              "geometry": null
            },
            "contactInfo": {
              "email": "weaver@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "337 Court St., Pekin, IL 61554",
              "phone1": null,
              "phone2": "309-204-6514",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3244",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3244",
                "https://repweaver.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-93-lower-ocd-person-63883963-04b0-454c-8d18-81dcca230b55": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/cce820c8-7ce2-4b30-8ebd-4734eab16a94",
            "firstName": "Norine K.",
            "lastName": "Hammond",
            "fullName": "Norine Hammond",
            "gender": "Female",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{07D3914D-440A-4891-99AE-256F657BBFFF}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-IL-94-lower-ocd-person-cce820c8-7ce2-4b30-8ebd-4734eab16a94",
              "builtID": "il-lower-94",
              "externalID": "ocd-person/cce820c8-7ce2-4b30-8ebd-4734eab16a94",
              "geometry": null
            },
            "contactInfo": {
              "email": "rephammond@macomb.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 170 331 N. Lafayette St., Macomb, IL 61455",
              "phone1": null,
              "phone2": "309-836-2707",
              "fax1": null,
              "fax2": "309-836-2231",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2597",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2838",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2838",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3055",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3055",
                "https://norinehammond.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-94-lower-ocd-person-cce820c8-7ce2-4b30-8ebd-4734eab16a94": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/868db7f8-5f9e-48a4-83ba-a5404b87276a",
            "firstName": "Michael J.",
            "lastName": "Coffey",
            "fullName": "Mike Coffey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{EA682659-0B02-4A60-8244-1D5D2ABCD620}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-IL-95-lower-ocd-person-868db7f8-5f9e-48a4-83ba-a5404b87276a",
              "builtID": "il-lower-95",
              "externalID": "ocd-person/868db7f8-5f9e-48a4-83ba-a5404b87276a",
              "geometry": null
            },
            "contactInfo": {
              "email": "coffey@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3246",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3246",
                "https://repcoffey.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-95-lower-ocd-person-868db7f8-5f9e-48a4-83ba-a5404b87276a": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/e9bf3918-daaa-4fa4-b58d-538c147335dd",
            "firstName": "Sue",
            "lastName": "Scherer",
            "fullName": "Sue Scherer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{A0B92E66-B4F7-4B85-A7F2-14C0153B426F}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-IL-96-lower-ocd-person-e9bf3918-daaa-4fa4-b58d-538c147335dd",
              "builtID": "il-lower-96",
              "externalID": "ocd-person/e9bf3918-daaa-4fa4-b58d-538c147335dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "staterepsue@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "1353 E. Mound Road Suite 203, Decatur, IL 62526",
              "phone1": null,
              "phone2": "217-877-9636",
              "fax1": null,
              "fax2": "217-877-9659",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2640",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2871",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2871",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3080",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3080"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-96-lower-ocd-person-e9bf3918-daaa-4fa4-b58d-538c147335dd": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/1574a50f-6d50-4d7d-87ee-db0254e5093e",
            "firstName": "Harry",
            "lastName": "Benton",
            "fullName": "Harry Benton",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{591DB385-5E0D-41CC-8B13-2FDA10A73D7F}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-IL-97-lower-ocd-person-1574a50f-6d50-4d7d-87ee-db0254e5093e",
              "builtID": "il-lower-97",
              "externalID": "ocd-person/1574a50f-6d50-4d7d-87ee-db0254e5093e",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@repharrybenton.com",
              "contactForm": null,
              "address1": null,
              "address2": "24047 Lockport St., Plainfield, IL 60544",
              "phone1": null,
              "phone2": "815-254-0000",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3241",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3241"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-97-lower-ocd-person-1574a50f-6d50-4d7d-87ee-db0254e5093e": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/d7b735a6-3b70-4a3d-ae53-948fde86e4e7",
            "firstName": "Natalie A.",
            "lastName": "Manley",
            "fullName": "Natalie Manley",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{F3781CCA-4285-44C3-8E60-811B9229EA1B}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-IL-98-lower-ocd-person-d7b735a6-3b70-4a3d-ae53-948fde86e4e7",
              "builtID": "il-lower-98",
              "externalID": "ocd-person/d7b735a6-3b70-4a3d-ae53-948fde86e4e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "repmanley@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "1050 W. Romeo Road Suite 101, Romeoville, IL 60446",
              "phone1": null,
              "phone2": "815-725-2741",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2639",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2870",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2870",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3079",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3079"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-98-lower-ocd-person-d7b735a6-3b70-4a3d-ae53-948fde86e4e7": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/b0ffa0e6-91b7-435d-8105-643353fef1c2",
            "firstName": "Randy E.",
            "lastName": "Frese",
            "fullName": "Randy Frese",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{BFF3CD04-BFB6-4681-952F-D519B27FE904}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-IL-99-lower-ocd-person-b0ffa0e6-91b7-435d-8105-643353fef1c2",
              "builtID": "il-lower-99",
              "externalID": "ocd-person/b0ffa0e6-91b7-435d-8105-643353fef1c2",
              "geometry": null
            },
            "contactInfo": {
              "email": "repfrese@adams.net",
              "contactForm": null,
              "address1": null,
              "address2": "3601 E. Lake Centre Drive Suite 200, Quincy, IL 62301",
              "phone1": null,
              "phone2": "217-223-0833",
              "fax1": null,
              "fax2": "217-223-1565",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2657",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2881",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2881",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3089",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3089",
                "https://repfrese.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-99-lower-ocd-person-b0ffa0e6-91b7-435d-8105-643353fef1c2": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/384c08f3-49fd-45a7-bb7b-5689bdeae7a7",
            "firstName": "Christopher D.",
            "lastName": "Davidsmeyer",
            "fullName": "C.D. Davidsmeyer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{E54250D5-EB1C-4CB6-9CF9-CA95CAA2F269}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-IL-100-lower-ocd-person-384c08f3-49fd-45a7-bb7b-5689bdeae7a7",
              "builtID": "il-lower-100",
              "externalID": "ocd-person/384c08f3-49fd-45a7-bb7b-5689bdeae7a7",
              "geometry": null
            },
            "contactInfo": {
              "email": "davidsmeyer@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 160 325 W. State St., Suite 102, Jacksonville, IL 62650",
              "phone1": null,
              "phone2": "217-243-6221",
              "fax1": null,
              "fax2": "217-245-2071",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2641",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2872",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2872",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3081",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3081",
                "https://cddavidsmeyer.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-100-lower-ocd-person-384c08f3-49fd-45a7-bb7b-5689bdeae7a7": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/f5a10a95-3160-4cac-9f4e-ba31e5362e7b",
            "firstName": "Chris G.",
            "lastName": "Miller",
            "fullName": "Chris Miller",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{2A519012-9070-494B-9FFC-C2646374202E}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-IL-101-lower-ocd-person-f5a10a95-3160-4cac-9f4e-ba31e5362e7b",
              "builtID": "il-lower-101",
              "externalID": "ocd-person/f5a10a95-3160-4cac-9f4e-ba31e5362e7b",
              "geometry": null
            },
            "contactInfo": {
              "email": "miller@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "1113 Lincoln Ave., Charleston, IL 61920",
              "phone1": null,
              "phone2": "217-508-4108",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2758",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2959",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2959",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3153",
                "https://repcmiller.com/",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3153"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-101-lower-ocd-person-f5a10a95-3160-4cac-9f4e-ba31e5362e7b": 0
        }
      },
      "102": {
        "members": [
          {
            "API_ID": "ocd-person/9729745e-386d-4f4b-8d04-0e762dcd87b2",
            "firstName": "Adam M.",
            "lastName": "Niemerg",
            "fullName": "Adam Niemerg",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{EDDABDD8-51A3-413F-B2A0-FA5953D29598}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "102",
              "chamber": "lower",
              "hashID": "SLDL-IL-102-lower-ocd-person-9729745e-386d-4f4b-8d04-0e762dcd87b2",
              "builtID": "il-lower-102",
              "externalID": "ocd-person/9729745e-386d-4f4b-8d04-0e762dcd87b2",
              "geometry": null
            },
            "contactInfo": {
              "email": "niemerg@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 7 107 E. Main St., Teutopolis, IL 62467",
              "phone1": null,
              "phone2": "217-813-6036",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=3001",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=3001",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3189",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3189",
                "https://repniemerg.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-102-lower-ocd-person-9729745e-386d-4f4b-8d04-0e762dcd87b2": 0
        }
      },
      "103": {
        "members": [
          {
            "API_ID": "ocd-person/bc409660-f360-4624-914f-ba785bbf9b7b",
            "firstName": "Carol",
            "lastName": "Ammons",
            "fullName": "Carol Ammons",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{BD4B7A5C-6D24-4E82-9C18-D5A0F3C42F6C}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "103",
              "chamber": "lower",
              "hashID": "SLDL-IL-103-lower-ocd-person-bc409660-f360-4624-914f-ba785bbf9b7b",
              "builtID": "il-lower-103",
              "externalID": "ocd-person/bc409660-f360-4624-914f-ba785bbf9b7b",
              "geometry": null
            },
            "contactInfo": {
              "email": "assistance@staterepcarolammons.com",
              "contactForm": null,
              "address1": null,
              "address2": "300 S. Broadway Suite154 A, Urbana, IL 61802",
              "phone1": null,
              "phone2": "217-531-1660",
              "fax1": null,
              "fax2": "217-666-7521",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2651",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2877",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2877",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3086",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3086",
                "https://www.staterepcarolammons.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-103-lower-ocd-person-bc409660-f360-4624-914f-ba785bbf9b7b": 0
        }
      },
      "104": {
        "members": [
          {
            "API_ID": "ocd-person/8c6f0ed1-f28f-408a-9b59-156fa7a7f1e0",
            "firstName": "Brandun",
            "lastName": "Schweizer",
            "fullName": "Brandun Schweizer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{7520D666-0725-4963-B643-C1106551E922}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "104",
              "chamber": "lower",
              "hashID": "SLDL-IL-104-lower-ocd-person-8c6f0ed1-f28f-408a-9b59-156fa7a7f1e0",
              "builtID": "il-lower-104",
              "externalID": "ocd-person/8c6f0ed1-f28f-408a-9b59-156fa7a7f1e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "repschweizer@district104.com",
              "contactForm": null,
              "address1": null,
              "address2": "3821 N. Vermilion, Suite 5, Danville, IL 61832",
              "phone1": null,
              "phone2": "217-477-0104",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3257",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3257",
                "https://repschweizer.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-104-lower-ocd-person-8c6f0ed1-f28f-408a-9b59-156fa7a7f1e0": 0
        }
      },
      "105": {
        "members": [
          {
            "API_ID": "ocd-person/13dc4688-95d1-4a5d-abc7-fb9a46a432c8",
            "firstName": "Dennis",
            "lastName": "Tipsword",
            "fullName": "Dennis Tipsword",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{D8E9F62C-6A73-4CCD-8148-332CD9806264}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "105",
              "chamber": "lower",
              "hashID": "SLDL-IL-105-lower-ocd-person-13dc4688-95d1-4a5d-abc7-fb9a46a432c8",
              "builtID": "il-lower-105",
              "externalID": "ocd-person/13dc4688-95d1-4a5d-abc7-fb9a46a432c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "tipsword@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "305 W. Madison St., Pontiac, IL 61764",
              "phone1": null,
              "phone2": "815-844-9344",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3242",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3242",
                "https://reptipsword.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-105-lower-ocd-person-13dc4688-95d1-4a5d-abc7-fb9a46a432c8": 0
        }
      },
      "106": {
        "members": [
          {
            "API_ID": "ocd-person/4a588ccf-b32f-4b66-978a-3415f71d2774",
            "firstName": "Jason",
            "lastName": "Bunting",
            "fullName": "Jason Bunting",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{5F0F6F55-F573-4F05-B495-878685C2C00A}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "106",
              "chamber": "lower",
              "hashID": "SLDL-IL-106-lower-ocd-person-4a588ccf-b32f-4b66-978a-3415f71d2774",
              "builtID": "il-lower-106",
              "externalID": "ocd-person/4a588ccf-b32f-4b66-978a-3415f71d2774",
              "geometry": null
            },
            "contactInfo": {
              "email": "bunting@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "342 W. Walnut, Watseka, IL 60970",
              "phone1": null,
              "phone2": "779-218-3070",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3250",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3250",
                "https://repbunting.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-106-lower-ocd-person-4a588ccf-b32f-4b66-978a-3415f71d2774": 0
        }
      },
      "107": {
        "members": [
          {
            "API_ID": "ocd-person/e05f1a87-b75d-450e-9cd1-3b7a66cb703e",
            "firstName": "Brad E.",
            "lastName": "Halbrook",
            "fullName": "Brad Halbrook",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{3F3C3739-E087-440D-B006-33043A8F5083}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "107",
              "chamber": "lower",
              "hashID": "SLDL-IL-107-lower-ocd-person-e05f1a87-b75d-450e-9cd1-3b7a66cb703e",
              "builtID": "il-lower-107",
              "externalID": "ocd-person/e05f1a87-b75d-450e-9cd1-3b7a66cb703e",
              "geometry": null
            },
            "contactInfo": {
              "email": "halbrook@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "510 S. Staley Suite D, Champaign, IL 61822",
              "phone1": null,
              "phone2": "217-607-1853",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2697",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2913",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2913",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3114",
                "https://rephalbrook.com/",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3114"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-107-lower-ocd-person-e05f1a87-b75d-450e-9cd1-3b7a66cb703e": 0
        }
      },
      "108": {
        "members": [
          {
            "API_ID": "ocd-person/5a23473f-1af0-4f7d-8b08-cb287d93db6f",
            "firstName": "Wayne Arthur",
            "lastName": "Rosenthal",
            "fullName": "Wayne Rosenthal",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{43DB5751-834C-4CA0-B8A4-6D1016E3CB28}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "108",
              "chamber": "lower",
              "hashID": "SLDL-IL-108-lower-ocd-person-5a23473f-1af0-4f7d-8b08-cb287d93db6f",
              "builtID": "il-lower-108",
              "externalID": "ocd-person/5a23473f-1af0-4f7d-8b08-cb287d93db6f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rosenthal@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3243",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3243",
                "https://reprosenthal.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-108-lower-ocd-person-5a23473f-1af0-4f7d-8b08-cb287d93db6f": 0
        }
      },
      "109": {
        "members": [
          {
            "API_ID": "ocd-person/c425f2ef-cad0-4dad-93c4-be11b80af613",
            "firstName": "Charles E.",
            "lastName": "Meier",
            "fullName": "Charlie Meier",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{9D43D019-A58A-47EC-B769-A7050C4B49DB}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "109",
              "chamber": "lower",
              "hashID": "SLDL-IL-109-lower-ocd-person-c425f2ef-cad0-4dad-93c4-be11b80af613",
              "builtID": "il-lower-109",
              "externalID": "ocd-person/c425f2ef-cad0-4dad-93c4-be11b80af613",
              "geometry": null
            },
            "contactInfo": {
              "email": "repcmeier@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "121 Broadway Suite 1, Highland, IL 62249",
              "phone1": null,
              "phone2": "618-651-0405",
              "fax1": null,
              "fax2": "618-651-0413",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2642",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2873",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2873",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3082",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3082",
                "https://charliemeier.net/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-109-lower-ocd-person-c425f2ef-cad0-4dad-93c4-be11b80af613": 0
        }
      },
      "110": {
        "members": [
          {
            "API_ID": "ocd-person/500ffee4-de06-4a0b-b4df-f8e6f52c4e96",
            "firstName": "Blaine",
            "lastName": "Wilhour",
            "fullName": "Blaine Wilhour",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{66C5580C-A414-41BB-B3AC-638925AC34CD}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "110",
              "chamber": "lower",
              "hashID": "SLDL-IL-110-lower-ocd-person-500ffee4-de06-4a0b-b4df-f8e6f52c4e96",
              "builtID": "il-lower-110",
              "externalID": "ocd-person/500ffee4-de06-4a0b-b4df-f8e6f52c4e96",
              "geometry": null
            },
            "contactInfo": {
              "email": "wilhour@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box I 152 S. Church St., Louisville, IL 62858",
              "phone1": null,
              "phone2": "618-665-4109",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2761",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2962",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2962",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3156",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3156",
                "https://repwilhour.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-110-lower-ocd-person-500ffee4-de06-4a0b-b4df-f8e6f52c4e96": 0
        }
      },
      "111": {
        "members": [
          {
            "API_ID": "ocd-person/b13c7ff5-806e-426d-afd5-181f53e9fed1",
            "firstName": "Amy Waters",
            "lastName": "Elik",
            "fullName": "Amy Elik",
            "gender": "Female",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{D070CEE6-55DF-4D51-961B-CCA4A0E49A8C}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "111",
              "chamber": "lower",
              "hashID": "SLDL-IL-111-lower-ocd-person-b13c7ff5-806e-426d-afd5-181f53e9fed1",
              "builtID": "il-lower-111",
              "externalID": "ocd-person/b13c7ff5-806e-426d-afd5-181f53e9fed1",
              "geometry": null
            },
            "contactInfo": {
              "email": "elik@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=3002",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=3002",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3190",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3190",
                "https://repelik.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-111-lower-ocd-person-b13c7ff5-806e-426d-afd5-181f53e9fed1": 0
        }
      },
      "112": {
        "members": [
          {
            "API_ID": "ocd-person/ceb7f558-3a2c-4041-bf3b-f5a9a511e8b3",
            "firstName": "Katie Kobak",
            "lastName": "Stuart",
            "fullName": "Katie Stuart",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{D6DC8152-9760-4BEA-ACF5-C41785E6F6C4}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "112",
              "chamber": "lower",
              "hashID": "SLDL-IL-112-lower-ocd-person-ceb7f558-3a2c-4041-bf3b-f5a9a511e8b3",
              "builtID": "il-lower-112",
              "externalID": "ocd-person/ceb7f558-3a2c-4041-bf3b-f5a9a511e8b3",
              "geometry": null
            },
            "contactInfo": {
              "email": "repkatiestuart@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "2105 Vandalia St. Unit #16, Collinsville, IL 62234",
              "phone1": null,
              "phone2": "618-365-6650",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2695",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2911",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2911",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3112",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3112",
                "https://repstuart.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-112-lower-ocd-person-ceb7f558-3a2c-4041-bf3b-f5a9a511e8b3": 0
        }
      },
      "113": {
        "members": [
          {
            "API_ID": "ocd-person/21e2f7d1-8073-4ee1-a5b5-b32ccdd321d5",
            "firstName": "Jay C.",
            "lastName": "Hoffman",
            "fullName": "Jay Hoffman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{FCD7EF06-36D9-46A7-8085-4B5A5ED81A80}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "113",
              "chamber": "lower",
              "hashID": "SLDL-IL-113-lower-ocd-person-21e2f7d1-8073-4ee1-a5b5-b32ccdd321d5",
              "builtID": "il-lower-113",
              "externalID": "ocd-person/21e2f7d1-8073-4ee1-a5b5-b32ccdd321d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "repjayhoffman@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "312 S. High St., Belleville, IL 62220",
              "phone1": null,
              "phone2": "618-416-7407",
              "fax1": null,
              "fax2": "618-416-7409",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2643",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2874",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2874",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3083",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3083"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-113-lower-ocd-person-21e2f7d1-8073-4ee1-a5b5-b32ccdd321d5": 0
        }
      },
      "114": {
        "members": [
          {
            "API_ID": "ocd-person/266780c2-6b08-469e-9d78-10d618e16f4e",
            "firstName": "Kevin",
            "lastName": "Schmidt",
            "fullName": "Kevin Schmidt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{385A4AC4-BA7A-42A3-987F-85EA620B1F2B}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "114",
              "chamber": "lower",
              "hashID": "SLDL-IL-114-lower-ocd-person-266780c2-6b08-469e-9d78-10d618e16f4e",
              "builtID": "il-lower-114",
              "externalID": "ocd-person/266780c2-6b08-469e-9d78-10d618e16f4e",
              "geometry": null
            },
            "contactInfo": {
              "email": "schmidt@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "4041 Mississippi Ave., Cahokia Heights, IL 62206",
              "phone1": null,
              "phone2": "618-215-1050",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3245",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3245",
                "https://repschmidt.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-114-lower-ocd-person-266780c2-6b08-469e-9d78-10d618e16f4e": 0
        }
      },
      "115": {
        "members": [
          {
            "API_ID": "ocd-person/abda0ae5-9ecb-4363-9a6a-487f1cf8899e",
            "firstName": "David",
            "lastName": "Friess",
            "fullName": "David Friess",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{C1F3982E-6DA7-4AE6-96B9-77E9FBC87A2A}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "115",
              "chamber": "lower",
              "hashID": "SLDL-IL-115-lower-ocd-person-abda0ae5-9ecb-4363-9a6a-487f1cf8899e",
              "builtID": "il-lower-115",
              "externalID": "ocd-person/abda0ae5-9ecb-4363-9a6a-487f1cf8899e",
              "geometry": null
            },
            "contactInfo": {
              "email": "friess@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "143 S. Main St., Red Bud, IL 62278",
              "phone1": null,
              "phone2": "618-282-7284",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=3004",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=3004",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3192",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3192",
                "https://repfriess.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-115-lower-ocd-person-abda0ae5-9ecb-4363-9a6a-487f1cf8899e": 0
        }
      },
      "116": {
        "members": [
          {
            "API_ID": "ocd-person/cbbd1430-7c0b-4be0-9d45-deabdb0c3d0f",
            "firstName": "David",
            "lastName": "Severin",
            "fullName": "Dave Severin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{FC792043-4CE1-4796-BA38-E68B39A9847A}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "116",
              "chamber": "lower",
              "hashID": "SLDL-IL-116-lower-ocd-person-cbbd1430-7c0b-4be0-9d45-deabdb0c3d0f",
              "builtID": "il-lower-116",
              "externalID": "ocd-person/cbbd1430-7c0b-4be0-9d45-deabdb0c3d0f",
              "geometry": null
            },
            "contactInfo": {
              "email": "severin@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "303 N. Main St., Benton, IL 62812",
              "phone1": null,
              "phone2": "618-440-5090",
              "fax1": null,
              "fax2": "618-440-5091",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2691",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2908",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2908",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3110",
                "https://repseverin.com/",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3110"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-116-lower-ocd-person-cbbd1430-7c0b-4be0-9d45-deabdb0c3d0f": 0
        }
      },
      "117": {
        "members": [
          {
            "API_ID": "ocd-person/6cb9bc2c-97b3-4dd1-b5ba-36a644360057",
            "firstName": "Patrick",
            "lastName": "Windhorst",
            "fullName": "Patrick Windhorst",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{547F9C92-7368-4390-B625-5BB795568D50}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "117",
              "chamber": "lower",
              "hashID": "SLDL-IL-117-lower-ocd-person-6cb9bc2c-97b3-4dd1-b5ba-36a644360057",
              "builtID": "il-lower-117",
              "externalID": "ocd-person/6cb9bc2c-97b3-4dd1-b5ba-36a644360057",
              "geometry": null
            },
            "contactInfo": {
              "email": "windhorst@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "2 N. Vine St. Suite 5A, Harrisburg, IL 62946",
              "phone1": null,
              "phone2": "618-294-8703",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2760",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2961",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2961",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3155",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3155",
                "https://repwindhorst.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-117-lower-ocd-person-6cb9bc2c-97b3-4dd1-b5ba-36a644360057": 0
        }
      },
      "118": {
        "members": [
          {
            "API_ID": "ocd-person/2febe0de-8b57-43c9-b8cd-a5363af4eae3",
            "firstName": "Paul",
            "lastName": "Jacobs",
            "fullName": "Paul Jacobs",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{0E151A06-57C8-4261-9577-278F5DD4B717}.jpg",
            "title": "IL Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "118",
              "chamber": "lower",
              "hashID": "SLDL-IL-118-lower-ocd-person-2febe0de-8b57-43c9-b8cd-a5363af4eae3",
              "builtID": "il-lower-118",
              "externalID": "ocd-person/2febe0de-8b57-43c9-b8cd-a5363af4eae3",
              "geometry": null
            },
            "contactInfo": {
              "email": "jacobs@ilhousegop.org",
              "contactForm": null,
              "address1": null,
              "address2": "600 Halfway Road Suite 103, Marion, IL 62959",
              "phone1": null,
              "phone2": "618-559-7018",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=3003",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=3003",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3191",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3191",
                "https://reppauljacobs.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IL-118-lower-ocd-person-2febe0de-8b57-43c9-b8cd-a5363af4eae3": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/caf27c44-2fec-4e26-a102-e6a30ca56be0",
            "firstName": "Javier Loera",
            "lastName": "Cervantes",
            "fullName": "Javier Cervantes",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{C05FD6B2-E0A9-4446-AAE5-D67396FCE7D5}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-IL-1-upper-ocd-person-caf27c44-2fec-4e26-a102-e6a30ca56be0",
              "builtID": "il-upper-1",
              "externalID": "ocd-person/caf27c44-2fec-4e26-a102-e6a30ca56be0",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator.jcervantes@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "1836 W. 35th St. 1st Floor, Chicago, IL 60609",
              "phone1": null,
              "phone2": "773-869-9050",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3206",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3206",
                "https://www.senatorcervantes.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-1-upper-ocd-person-caf27c44-2fec-4e26-a102-e6a30ca56be0": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/436c1e53-1203-4ce3-9931-d22f9e3c406a",
            "firstName": "Omar",
            "lastName": "Aquino",
            "fullName": "Omar Aquino",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{79A5CDE2-DD9F-423E-83F0-4643FDB13ABB}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-IL-2-upper-ocd-person-436c1e53-1203-4ce3-9931-d22f9e3c406a",
              "builtID": "il-upper-2",
              "externalID": "ocd-person/436c1e53-1203-4ce3-9931-d22f9e3c406a",
              "geometry": null
            },
            "contactInfo": {
              "email": "aquino.senate2il@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "4053 W. Armitage Ave., Chicago, IL 60639",
              "phone1": null,
              "phone2": "773-292-0202",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2673",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2894",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2894",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3099",
                "https://www.senatoraquino.com/",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3099"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-2-upper-ocd-person-436c1e53-1203-4ce3-9931-d22f9e3c406a": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/879afbe3-9394-4a84-b57f-af36ca92cb14",
            "firstName": "Mattie",
            "lastName": "Hunter",
            "fullName": "Mattie Hunter",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{F4630DDB-11F0-417E-8C0A-B4ED6426116F}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-IL-3-upper-ocd-person-879afbe3-9394-4a84-b57f-af36ca92cb14",
              "builtID": "il-upper-3",
              "externalID": "ocd-person/879afbe3-9394-4a84-b57f-af36ca92cb14",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator03district@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "2929 S. Wabash Ave. Suite 102, Chicago, IL 60616",
              "phone1": null,
              "phone2": "312-949-1908",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2554",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2808",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2808",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3030",
                "https://www.senatorhunter.com/",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3030"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-3-upper-ocd-person-879afbe3-9394-4a84-b57f-af36ca92cb14": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/595f7bf4-5bd7-4238-94a3-e14d8b6d4545",
            "firstName": "Kimberly A.",
            "lastName": "Lightford",
            "fullName": "Kimberly Lightford",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{7FBA8DB0-281E-406F-BF9F-ABEB75B7B38E}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-IL-4-upper-ocd-person-595f7bf4-5bd7-4238-94a3-e14d8b6d4545",
              "builtID": "il-upper-4",
              "externalID": "ocd-person/595f7bf4-5bd7-4238-94a3-e14d8b6d4545",
              "geometry": null
            },
            "contactInfo": {
              "email": "klightford@senatedem.ilga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4415 W. Harrison St. Suite 550, Hillside, IL 60162",
              "phone1": null,
              "phone2": "708-632-4500",
              "fax1": null,
              "fax2": "708-632-4515",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2535",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2795",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2795",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3023",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3023",
                "https://www.senatorlightford.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-4-upper-ocd-person-595f7bf4-5bd7-4238-94a3-e14d8b6d4545": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/37f0e4e6-fd0c-4bae-8def-f0ab80f722ff",
            "firstName": "Lakesia",
            "lastName": "Collins",
            "fullName": "Lakesia Collins",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{07674912-6AF6-4045-B60C-094B530D9FDB}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-IL-5-upper-ocd-person-37f0e4e6-fd0c-4bae-8def-f0ab80f722ff",
              "builtID": "il-upper-5",
              "externalID": "ocd-person/37f0e4e6-fd0c-4bae-8def-f0ab80f722ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "office@lakesiacollins.com",
              "contactForm": null,
              "address1": null,
              "address2": "2165 S. Millard Ave., Chicago, IL 60623",
              "phone1": null,
              "phone2": "312-298-9181",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2979",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2979",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3169",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3254",
                "https://www.lakesiacollins.com/",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3254"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-5-upper-ocd-person-37f0e4e6-fd0c-4bae-8def-f0ab80f722ff": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/6c0069de-c064-412e-ae45-9e87d6ee3dae",
            "firstName": "Sara",
            "lastName": "Feigenholtz",
            "fullName": "Sara Feigenholtz",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{E77394AE-D896-4FEB-A325-B33A553554EB}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-IL-6-upper-ocd-person-6c0069de-c064-412e-ae45-9e87d6ee3dae",
              "builtID": "il-upper-6",
              "externalID": "ocd-person/6c0069de-c064-412e-ae45-9e87d6ee3dae",
              "geometry": null
            },
            "contactInfo": {
              "email": "sara@senatorsara.com",
              "contactForm": null,
              "address1": null,
              "address2": "3223 N. Sheffield Ave. Suite A, Chicago, IL 60657",
              "phone1": null,
              "phone2": "773-296-4141",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2778",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2977",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2977",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3168",
                "https://staterepsara.com/",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3168"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-6-upper-ocd-person-6c0069de-c064-412e-ae45-9e87d6ee3dae": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/0125b0ac-65ba-443e-b418-53cdf42b9f85",
            "firstName": "Michael J.",
            "lastName": "Simmons-Gessesse",
            "fullName": "Mike Simmons-Gessesse",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{6EDEB1E8-2566-4659-ABF5-A9107C2912F0}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-IL-7-upper-ocd-person-0125b0ac-65ba-443e-b418-53cdf42b9f85",
              "builtID": "il-upper-7",
              "externalID": "ocd-person/0125b0ac-65ba-443e-b418-53cdf42b9f85",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatormikesimmons@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "1040 W. Bryn Mawr Ave., Chicago, IL 60660",
              "phone1": null,
              "phone2": "773-769-1717",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=3008",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=3008",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3196",
                "https://www.senatormikesimmons.com/en/",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3196",
                "https://www.senatormikesimmons.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-7-upper-ocd-person-0125b0ac-65ba-443e-b418-53cdf42b9f85": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/d01a10b0-e391-4c65-ba1f-304ee6130f5c",
            "firstName": "Ramachandra R.",
            "lastName": "Villivalam",
            "fullName": "Ram Villivalam",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{DBF0BBF0-0E16-4C5F-894A-16C4A14CAF51}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-IL-8-upper-ocd-person-d01a10b0-e391-4c65-ba1f-304ee6130f5c",
              "builtID": "il-upper-8",
              "externalID": "ocd-person/d01a10b0-e391-4c65-ba1f-304ee6130f5c",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator@senatorram.com",
              "contactForm": null,
              "address1": null,
              "address2": "3851 W. Devon Ave., Chicago, IL 60659",
              "phone1": null,
              "phone2": "872-208-5188",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2731",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2936",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2936",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3134",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3134",
                "https://www.senatorram.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-8-upper-ocd-person-d01a10b0-e391-4c65-ba1f-304ee6130f5c": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/0ce44c13-37aa-4560-8489-67c309da913a",
            "firstName": "Laura",
            "lastName": "Fine",
            "fullName": "Laura Fine",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{A4F70929-881C-4003-B10A-D1A775D3D94B}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-IL-9-upper-ocd-person-0ce44c13-37aa-4560-8489-67c309da913a",
              "builtID": "il-upper-9",
              "externalID": "ocd-person/0ce44c13-37aa-4560-8489-67c309da913a",
              "geometry": null
            },
            "contactInfo": {
              "email": "laura@senatorfine.com",
              "contactForm": null,
              "address1": null,
              "address2": "1812 Waukegan Road Suite A, Glenview, IL 60026",
              "phone1": null,
              "phone2": "847-998-1717",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2724",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2929",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2929",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3127",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3127",
                "https://www.senatorfine.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-9-upper-ocd-person-0ce44c13-37aa-4560-8489-67c309da913a": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/4a3645fe-2a56-400f-ae2a-18e50f2b0bea",
            "firstName": "Robert F.",
            "lastName": "Martwick",
            "fullName": "Rob Martwick",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{8FA2A22C-6775-4098-905C-0C7D8BBD7AEE}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-IL-10-upper-ocd-person-4a3645fe-2a56-400f-ae2a-18e50f2b0bea",
              "builtID": "il-upper-10",
              "externalID": "ocd-person/4a3645fe-2a56-400f-ae2a-18e50f2b0bea",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@senatormartwick.com",
              "contactForm": null,
              "address1": null,
              "address2": "4349 N. Milwaukee Ave., Chicago, IL 60641",
              "phone1": null,
              "phone2": "773-286-1115",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2769",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2970",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2970",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3161",
                "https://senatormartwick.com/",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3161"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-10-upper-ocd-person-4a3645fe-2a56-400f-ae2a-18e50f2b0bea": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/4666ba31-e78b-48f5-9d88-f4823527df6b",
            "firstName": "Mike",
            "lastName": "Porfirio",
            "fullName": "Mike Porfirio",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{0824C110-5C93-4CBA-92E2-C330BBD6FE02}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-IL-11-upper-ocd-person-4666ba31-e78b-48f5-9d88-f4823527df6b",
              "builtID": "il-upper-11",
              "externalID": "ocd-person/4666ba31-e78b-48f5-9d88-f4823527df6b",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@senatorporfirio.com",
              "contactForm": null,
              "address1": null,
              "address2": "6215 W. 79th St. Suite 1A, Burbank, IL 60459",
              "phone1": null,
              "phone2": "708-430-2510",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3208",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3208",
                "https://www.senatorporfirio.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-11-upper-ocd-person-4666ba31-e78b-48f5-9d88-f4823527df6b": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/013d012f-a50e-43c8-af40-f12aa852a0da",
            "firstName": "Celina",
            "lastName": "Villanueva",
            "fullName": "Celina Villanueva",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{F8A21A81-5453-4E94-AC51-E644E1B0C00B}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-IL-12-upper-ocd-person-013d012f-a50e-43c8-af40-f12aa852a0da",
              "builtID": "il-upper-12",
              "externalID": "ocd-person/013d012f-a50e-43c8-af40-f12aa852a0da",
              "geometry": null
            },
            "contactInfo": {
              "email": "district@senatorvillanueva.com",
              "contactForm": null,
              "address1": null,
              "address2": "2501 S. Central Park Ave. Front Suite, Chicago, IL 60623",
              "phone1": null,
              "phone2": "773-565-4661",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2777",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2976",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2976",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3167",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3167",
                "https://www.senatorvillanueva.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-12-upper-ocd-person-013d012f-a50e-43c8-af40-f12aa852a0da": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/86ee52dd-dc32-4895-bf16-746eb76df012",
            "firstName": "Robert James",
            "lastName": "Peters",
            "fullName": "Robert Peters",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{05AD9CBA-EA20-489D-9C33-3F39DC1F9B0C}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-IL-13-upper-ocd-person-86ee52dd-dc32-4895-bf16-746eb76df012",
              "builtID": "il-upper-13",
              "externalID": "ocd-person/86ee52dd-dc32-4895-bf16-746eb76df012",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@senatorrobertpeters.com",
              "contactForm": null,
              "address1": null,
              "address2": "1304 E. 47th St. Suite 205, Chicago, IL 60615",
              "phone1": null,
              "phone2": "773-363-1996",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2729",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2934",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2934",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3132",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3132",
                "https://www.senatorrobertpeters.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-13-upper-ocd-person-86ee52dd-dc32-4895-bf16-746eb76df012": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/05030c23-9600-4d1c-ab77-46c17aec2059",
            "firstName": "Emil",
            "lastName": "Jones",
            "fullName": "Emil Jones",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{2C0EE719-E9D8-4625-990D-69FCE146BC0F}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-IL-14-upper-ocd-person-05030c23-9600-4d1c-ab77-46c17aec2059",
              "builtID": "il-upper-14",
              "externalID": "ocd-person/05030c23-9600-4d1c-ab77-46c17aec2059",
              "geometry": null
            },
            "contactInfo": {
              "email": "ejones3@senatedem.ilga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "845 W. 115th St., Chicago, IL 60643",
              "phone1": null,
              "phone2": "773-995-7748",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2579",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2823",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2823",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3043",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3043",
                "https://www.senatoremiljones.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-14-upper-ocd-person-05030c23-9600-4d1c-ab77-46c17aec2059": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/f8e78760-4ec7-44f4-a00f-54f42fb834d5",
            "firstName": "Napoleon Bill",
            "lastName": "Harris",
            "fullName": "Napoleon Harris",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{87402198-6E85-4CFE-BDD2-B9DC58948869}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-IL-15-upper-ocd-person-f8e78760-4ec7-44f4-a00f-54f42fb834d5",
              "builtID": "il-upper-15",
              "externalID": "ocd-person/f8e78760-4ec7-44f4-a00f-54f42fb834d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "nharris@senatedem.ilga.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2615",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2851",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2851",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3064",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3064",
                "https://www.senatornapoleonharris.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-15-upper-ocd-person-f8e78760-4ec7-44f4-a00f-54f42fb834d5": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/34274113-7ea1-4e6e-aa7a-da2c9d1037b4",
            "firstName": "Willie",
            "lastName": "Preston",
            "fullName": "Willie Preston",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-IL-16-upper-ocd-person-34274113-7ea1-4e6e-aa7a-da2c9d1037b4",
              "builtID": "il-upper-16",
              "externalID": "ocd-person/34274113-7ea1-4e6e-aa7a-da2c9d1037b4",
              "geometry": null
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": "8500 S. Pulaski Road, Chicago, IL 60652",
              "phone1": null,
              "phone2": "773-912-6255",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3209",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3209",
                "https://senatorpreston.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-16-upper-ocd-person-34274113-7ea1-4e6e-aa7a-da2c9d1037b4": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/50ad4838-140d-4cb7-80cb-46da37a8827f",
            "firstName": "Elgie R.",
            "lastName": "Sims",
            "fullName": "Elgie Sims",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{1EAF7721-A189-4CD1-B31D-CD39F79DBD04}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-IL-17-upper-ocd-person-50ad4838-140d-4cb7-80cb-46da37a8827f",
              "builtID": "il-upper-17",
              "externalID": "ocd-person/50ad4838-140d-4cb7-80cb-46da37a8827f",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@senatorelgiesims.com",
              "contactForm": null,
              "address1": null,
              "address2": "8233 S. Princeton Ave., Chicago, IL 60620",
              "phone1": null,
              "phone2": "773-933-7715",
              "fax1": null,
              "fax2": "773-933-5498",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2703",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2918",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2918",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3117",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3117",
                "https://www.senatorelgiesims.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-17-upper-ocd-person-50ad4838-140d-4cb7-80cb-46da37a8827f": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/5fe6a4a5-da36-4fa0-8c36-739648b9ce0c",
            "firstName": "William",
            "lastName": "Cunningham",
            "fullName": "Bill Cunningham",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{402F0454-4EAF-4721-993D-53C7459BA35B}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-IL-18-upper-ocd-person-5fe6a4a5-da36-4fa0-8c36-739648b9ce0c",
              "builtID": "il-upper-18",
              "externalID": "ocd-person/5fe6a4a5-da36-4fa0-8c36-739648b9ce0c",
              "geometry": null
            },
            "contactInfo": {
              "email": "staterepbillcunningham@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "16033 S. 94th Ave., Orland Hills, IL 60477",
              "phone1": null,
              "phone2": "708-233-9703",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2614",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2850",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2850",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3063",
                "https://www.senatorbillcunningham.com/bio",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3063"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-18-upper-ocd-person-5fe6a4a5-da36-4fa0-8c36-739648b9ce0c": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/fb433923-65ca-475a-b106-7293c3483c57",
            "firstName": "Michael E.",
            "lastName": "Hastings",
            "fullName": "Mike Hastings",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{C6E534EB-7243-4687-8A65-04BD4DA5DF72}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-IL-19-upper-ocd-person-fb433923-65ca-475a-b106-7293c3483c57",
              "builtID": "il-upper-19",
              "externalID": "ocd-person/fb433923-65ca-475a-b106-7293c3483c57",
              "geometry": null
            },
            "contactInfo": {
              "email": "mhastings@senatedem.ilga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "813 School Road, Matteson, IL 60443",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2616",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2852",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2852",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3065",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3065",
                "https://www.senatorhastings.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-19-upper-ocd-person-fb433923-65ca-475a-b106-7293c3483c57": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/f9a03813-26ad-41e6-a0ba-ed86c49dc10c",
            "firstName": "Natalie",
            "lastName": "Toro",
            "fullName": "Natalie Toro",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-IL-20-upper-ocd-person-f9a03813-26ad-41e6-a0ba-ed86c49dc10c",
              "builtID": "il-upper-20",
              "externalID": "ocd-person/f9a03813-26ad-41e6-a0ba-ed86c49dc10c",
              "geometry": null
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": "3140 W. Montrose, Chicago, IL 60618",
              "phone1": null,
              "phone2": "773-278-2020",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3253",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3253",
                "https://www.senatortoro.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-20-upper-ocd-person-f9a03813-26ad-41e6-a0ba-ed86c49dc10c": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/9bc8c9e8-ed36-42a1-ae80-4b2dfe591839",
            "firstName": "Laura",
            "lastName": "Ellman",
            "fullName": "Laura Ellman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{423E6DDE-1AFB-4F10-A767-C869B0E51483}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-IL-21-upper-ocd-person-9bc8c9e8-ed36-42a1-ae80-4b2dfe591839",
              "builtID": "il-upper-21",
              "externalID": "ocd-person/9bc8c9e8-ed36-42a1-ae80-4b2dfe591839",
              "geometry": null
            },
            "contactInfo": {
              "email": "ellman@senatedem.illinois.gov",
              "contactForm": null,
              "address1": null,
              "address2": "24 W. 500 Maple Ave. Suite 215, Naperville, IL 60540",
              "phone1": null,
              "phone2": "630-601-9961",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2725",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2930",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2930",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3128",
                "https://www.senatorlauraellman.com/",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3128"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-21-upper-ocd-person-9bc8c9e8-ed36-42a1-ae80-4b2dfe591839": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/f5add4b7-0c7e-4909-8e31-3042698f5b49",
            "firstName": "Cristina",
            "lastName": "Castro",
            "fullName": "Cristina Castro",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{F09EB558-EB8D-48B1-91F0-EF47A07632F5}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-IL-22-upper-ocd-person-f5add4b7-0c7e-4909-8e31-3042698f5b49",
              "builtID": "il-upper-22",
              "externalID": "ocd-person/f5add4b7-0c7e-4909-8e31-3042698f5b49",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorcastro@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "164 E. Chicago St. Suite 201, Elgin, IL 60120",
              "phone1": null,
              "phone2": "847-214-8864",
              "fax1": null,
              "fax2": "847-214-8867",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2677",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2896",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2896",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3101",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3101",
                "https://www.senatorcristinacastro.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-22-upper-ocd-person-f5add4b7-0c7e-4909-8e31-3042698f5b49": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/16483678-e06e-4551-8cec-266335b56fff",
            "firstName": "Suzanne",
            "lastName": "Glowiak Hilton",
            "fullName": "Suzy Glowiak Hilton",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{8DE2D7E5-9F3D-43FD-B0EC-0010BDA8849A}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-IL-23-upper-ocd-person-16483678-e06e-4551-8cec-266335b56fff",
              "builtID": "il-upper-23",
              "externalID": "ocd-person/16483678-e06e-4551-8cec-266335b56fff",
              "geometry": null
            },
            "contactInfo": {
              "email": "suzy@suzyforsenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "17W715 E. Butterfield Road Suite F, Oakbrook Terrace, IL 60181",
              "phone1": null,
              "phone2": "630-785-3177",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2727",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2932",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2932",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3130",
                "https://www.senatorsuzyglowiak.com/",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3130"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-23-upper-ocd-person-16483678-e06e-4551-8cec-266335b56fff": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/0b9e2838-eeb0-4d10-8292-64a9e9f54dc1",
            "firstName": "Seth P.",
            "lastName": "Lewis",
            "fullName": "Seth Lewis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{E714499E-C9C6-4A01-AE84-6CB703AFB9B7}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-IL-24-upper-ocd-person-0b9e2838-eeb0-4d10-8292-64a9e9f54dc1",
              "builtID": "il-upper-24",
              "externalID": "ocd-person/0b9e2838-eeb0-4d10-8292-64a9e9f54dc1",
              "geometry": null
            },
            "contactInfo": {
              "email": "lewis@ilsenategop.org",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 8547, Bartlett, IL 60103",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2994",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2994",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3210",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3183",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3210",
                "https://senatorlewis.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-24-upper-ocd-person-0b9e2838-eeb0-4d10-8292-64a9e9f54dc1": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/ca80672a-9d02-4287-9cd2-d2a7d08f604a",
            "firstName": "Karina",
            "lastName": "Villa",
            "fullName": "Karina Villa",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{C8126F4D-472E-4738-85B5-87A229F8174A}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-IL-25-upper-ocd-person-ca80672a-9d02-4287-9cd2-d2a7d08f604a",
              "builtID": "il-upper-25",
              "externalID": "ocd-person/ca80672a-9d02-4287-9cd2-d2a7d08f604a",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@senatorvilla.com",
              "contactForm": null,
              "address1": null,
              "address2": "946 N. Neltnor Blvd. Suite 104, West Chicago, IL 60185",
              "phone1": null,
              "phone2": "630-326-9319",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2742",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2987",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2987",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2987",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3177",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3177",
                "https://senatorvilla.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-25-upper-ocd-person-ca80672a-9d02-4287-9cd2-d2a7d08f604a": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/a24d31d0-02cb-49a8-8c25-1be3766dd7c4",
            "firstName": "Daniel",
            "lastName": "McConchie",
            "fullName": "Dan McConchie",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{B3DB61E4-CBA1-4E2C-9DC7-4EEE9F52BCD7}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-IL-26-upper-ocd-person-a24d31d0-02cb-49a8-8c25-1be3766dd7c4",
              "builtID": "il-upper-26",
              "externalID": "ocd-person/a24d31d0-02cb-49a8-8c25-1be3766dd7c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan@senatormcconchie.com",
              "contactForm": null,
              "address1": null,
              "address2": "795 Ela Road Suite 208, Lake Zurich, IL 60047",
              "phone1": null,
              "phone2": "224-662-4544",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2672",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2893",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2893",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3098",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3098",
                "https://senatormcconchie.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-26-upper-ocd-person-a24d31d0-02cb-49a8-8c25-1be3766dd7c4": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/d1c2870a-8fee-47bf-a48e-4320311f06fd",
            "firstName": "Mark L.",
            "lastName": "Walker",
            "fullName": "Mark Walker",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{BC2AF76D-FAE4-44C4-8632-AA4E629A9678}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-IL-27-upper-ocd-person-d1c2870a-8fee-47bf-a48e-4320311f06fd",
              "builtID": "il-upper-27",
              "externalID": "ocd-person/d1c2870a-8fee-47bf-a48e-4320311f06fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "contact@staterepwalker53.com",
              "contactForm": null,
              "address1": null,
              "address2": "171 W. Wing St. Suite 202, Arlington Heights, IL 60005",
              "phone1": null,
              "phone2": "847-749-1880",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2743",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2944",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2944",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3140",
                "https://www.ilga.gov/house/Rep.asp?GA=103&MemberID=3140",
                "https://www.staterepwalker.com/",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3261"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-27-upper-ocd-person-d1c2870a-8fee-47bf-a48e-4320311f06fd": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/55be467f-1dd9-4cb7-96b8-050c113f5975",
            "firstName": "Laura M.",
            "lastName": "Murphy",
            "fullName": "Laura Murphy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{E8C6FBF1-611C-4DD5-A81D-F0FF8917556D}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-IL-28-upper-ocd-person-55be467f-1dd9-4cb7-96b8-050c113f5975",
              "builtID": "il-upper-28",
              "externalID": "ocd-person/55be467f-1dd9-4cb7-96b8-050c113f5975",
              "geometry": null
            },
            "contactInfo": {
              "email": "lmurphy@senatedem.ilga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1645 S. River Road, Suite 7, Des Plaines, IL 60018",
              "phone1": null,
              "phone2": "847-718-1110",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2668",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2890",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2890",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3096",
                "https://www.senatorlauramurphy.com",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3096"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-28-upper-ocd-person-55be467f-1dd9-4cb7-96b8-050c113f5975": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/1e106d7c-5aba-40d1-932b-760b599c70ae",
            "firstName": "Julie A.",
            "lastName": "Morrison",
            "fullName": "Julie Morrison",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{0E6BF8A6-C5C8-4F54-977B-D5FD11707FA5}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-IL-29-upper-ocd-person-1e106d7c-5aba-40d1-932b-760b599c70ae",
              "builtID": "il-upper-29",
              "externalID": "ocd-person/1e106d7c-5aba-40d1-932b-760b599c70ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "morrison@senatedem.illinois.gov",
              "contactForm": null,
              "address1": null,
              "address2": "400 Lake Cook Road Suite 100B, Deerfield, IL 60015",
              "phone1": null,
              "phone2": "847-945-5200",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2619",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2854",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2854",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3067",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3067",
                "https://www.senatorjuliemorrison.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-29-upper-ocd-person-1e106d7c-5aba-40d1-932b-760b599c70ae": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/e4e9607b-dd1b-4c3d-b3a3-f09927800864",
            "firstName": "Adriane",
            "lastName": "Johnson",
            "fullName": "Adriane Johnson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{991EBDF6-BC13-4260-A2B8-E10751629780}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-IL-30-upper-ocd-person-e4e9607b-dd1b-4c3d-b3a3-f09927800864",
              "builtID": "il-upper-30",
              "externalID": "ocd-person/e4e9607b-dd1b-4c3d-b3a3-f09927800864",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@senatoradrianejohnson.com",
              "contactForm": null,
              "address1": null,
              "address2": "149 S. Genesee St., Waukegan, IL 60085",
              "phone1": null,
              "phone2": "847-672-6201",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2980",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2980",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3170",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3170",
                "https://www.senatoradrianejohnson.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-30-upper-ocd-person-e4e9607b-dd1b-4c3d-b3a3-f09927800864": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/1a1cd310-b42c-4da3-b9ac-6278b1e95a02",
            "firstName": "Mary",
            "lastName": "Edly-Allen",
            "fullName": "Mary Edly-Allen",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-IL-31-upper-ocd-person-1a1cd310-b42c-4da3-b9ac-6278b1e95a02",
              "builtID": "il-upper-31",
              "externalID": "ocd-person/1a1cd310-b42c-4da3-b9ac-6278b1e95a02",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@senatoredlyallen.com",
              "contactForm": null,
              "address1": null,
              "address2": "10 N. Lake St. Suite 112, Grayslake, IL 60030",
              "phone1": null,
              "phone2": "847-548-5631",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3212",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3212",
                "https://www.senatoredlyallen.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-31-upper-ocd-person-1a1cd310-b42c-4da3-b9ac-6278b1e95a02": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/07e2527a-ae7d-4c58-b20b-ea2266a03a9e",
            "firstName": "Craig",
            "lastName": "Wilcox",
            "fullName": "Craig Wilcox",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{E397F861-68AA-4CC2-9A23-50B9A8E13031}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-IL-32-upper-ocd-person-07e2527a-ae7d-4c58-b20b-ea2266a03a9e",
              "builtID": "il-upper-32",
              "externalID": "ocd-person/07e2527a-ae7d-4c58-b20b-ea2266a03a9e",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorwilcox@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "209 N. Benton St., Woodstock, IL 60098",
              "phone1": null,
              "phone2": "815-455-6330",
              "fax1": null,
              "fax2": "815-679-6756",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2711",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2925",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2925",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3123",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3123",
                "https://senatorwilcox.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-32-upper-ocd-person-07e2527a-ae7d-4c58-b20b-ea2266a03a9e": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/c4a1e37c-3c81-448c-9349-199a8fa5c2d3",
            "firstName": "Donald P.",
            "lastName": "DeWitte",
            "fullName": "Don DeWitte",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{21A3B029-3423-410E-818A-84512CD0D231}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-IL-33-upper-ocd-person-c4a1e37c-3c81-448c-9349-199a8fa5c2d3",
              "builtID": "il-upper-33",
              "externalID": "ocd-person/c4a1e37c-3c81-448c-9349-199a8fa5c2d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "dewitte@ilsenategop.org",
              "contactForm": null,
              "address1": null,
              "address2": "641 S. 8th St., West Dundee, IL 60118",
              "phone1": null,
              "phone2": "847-214-8245",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2709",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2923",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2923",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3121",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3121",
                "https://senatordewitte.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-33-upper-ocd-person-c4a1e37c-3c81-448c-9349-199a8fa5c2d3": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/ba9c4927-bf9b-4bd5-879f-c515a12a046c",
            "firstName": "Steve",
            "lastName": "Stadelman",
            "fullName": "Steve Stadelman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{47906765-AB0A-48BF-8C14-B5443B8F27C4}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-IL-34-upper-ocd-person-ba9c4927-bf9b-4bd5-879f-c515a12a046c",
              "builtID": "il-upper-34",
              "externalID": "ocd-person/ba9c4927-bf9b-4bd5-879f-c515a12a046c",
              "geometry": null
            },
            "contactInfo": {
              "email": "stadelman@senatedem.ilga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "200 S. Wyman St. Suite 301, Rockford, IL 61101",
              "phone1": null,
              "phone2": "815-987-7557",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2622",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2857",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2857",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3069",
                "https://www.senatorstadelman.com/",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3069"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-34-upper-ocd-person-ba9c4927-bf9b-4bd5-879f-c515a12a046c": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/d02beb7e-1f30-48f2-8418-4c9287452562",
            "firstName": "Dave",
            "lastName": "Syverson",
            "fullName": "Dave Syverson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{6FD168B8-C34B-4399-8E39-7373F7600882}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-IL-35-upper-ocd-person-d02beb7e-1f30-48f2-8418-4c9287452562",
              "builtID": "il-upper-35",
              "externalID": "ocd-person/d02beb7e-1f30-48f2-8418-4c9287452562",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@senatordavesyverson.com",
              "contactForm": null,
              "address1": null,
              "address2": "527 Colman Center Drive, Cherry Valley, IL 61108",
              "phone1": null,
              "phone2": "815-987-7555",
              "fax1": null,
              "fax2": "815-987-7563",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2540",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2798",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2798",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3025",
                "https://senatordavesyverson.com/",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3025"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-35-upper-ocd-person-d02beb7e-1f30-48f2-8418-4c9287452562": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/8021b51f-4acc-459e-a038-786ebaca8666",
            "firstName": "Michael W.",
            "lastName": "Halpin",
            "fullName": "Mike Halpin",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{35F1D456-C798-4A8B-9180-4608CB0FDB78}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-IL-36-upper-ocd-person-8021b51f-4acc-459e-a038-786ebaca8666",
              "builtID": "il-upper-36",
              "externalID": "ocd-person/8021b51f-4acc-459e-a038-786ebaca8666",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatormikehalpin@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "County Office Building 1504 3rd Ave., 2nd Floor, Rock Island, IL 61201",
              "phone1": null,
              "phone2": "309-558-3612",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2685",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2903",
                "https://ilga.gov/house/Rep.asp?GA=102&MemberID=2903",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3106",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3213",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3213"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-36-upper-ocd-person-8021b51f-4acc-459e-a038-786ebaca8666": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/e6dede3b-1db8-42ed-8c71-e19f4e49a8ee",
            "firstName": "Winston",
            "lastName": "Stoller",
            "fullName": "Win Stoller",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{02AA7D94-3FC5-4F6D-A749-6CFFE11755D2}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-IL-37-upper-ocd-person-e6dede3b-1db8-42ed-8c71-e19f4e49a8ee",
              "builtID": "il-upper-37",
              "externalID": "ocd-person/e6dede3b-1db8-42ed-8c71-e19f4e49a8ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorstoller@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "5415 N. University St. Suite 105, Peoria, IL 61614",
              "phone1": null,
              "phone2": "309-693-4921",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2985",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2985",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3175",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3175",
                "https://senatorstoller.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-37-upper-ocd-person-e6dede3b-1db8-42ed-8c71-e19f4e49a8ee": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/a1ac69ba-49db-4529-926f-999e73ef9654",
            "firstName": "Susan Marie Schipper",
            "lastName": "Rezin",
            "fullName": "Sue Rezin",
            "gender": "Female",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{45666230-B77D-4162-A45E-6AC5587C4E56}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-IL-38-upper-ocd-person-a1ac69ba-49db-4529-926f-999e73ef9654",
              "builtID": "il-upper-38",
              "externalID": "ocd-person/a1ac69ba-49db-4529-926f-999e73ef9654",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorrezin@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "850 4th St., DeKalb, IL 60115",
              "phone1": null,
              "phone2": "815-220-8720",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2588",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2830",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2830",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3048",
                "https://senatorrezin.com/",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3048"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-38-upper-ocd-person-a1ac69ba-49db-4529-926f-999e73ef9654": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/15c56180-21e1-492d-874f-25d511f804ee",
            "firstName": "Don",
            "lastName": "Harmon",
            "fullName": "Don Harmon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{CF3F6473-4E6D-4E84-A0A4-4FF7335132E2}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-IL-39-upper-ocd-person-15c56180-21e1-492d-874f-25d511f804ee",
              "builtID": "il-upper-39",
              "externalID": "ocd-person/15c56180-21e1-492d-874f-25d511f804ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "dharmon@senatedem.ilga.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6941-B W. North Ave., Oak Park, IL 60302",
              "phone1": null,
              "phone2": "708-848-2002",
              "fax1": null,
              "fax2": "708-848-2022",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2551",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2806",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2806",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3029",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3029",
                "https://www.donharmon.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-39-upper-ocd-person-15c56180-21e1-492d-874f-25d511f804ee": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/1ae7c123-b1a4-4c6e-b4d3-dc22db461e44",
            "firstName": "Patrick J.",
            "lastName": "Joyce",
            "fullName": "Patrick Joyce",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{39D6F100-DAEC-4C4B-A4F5-F20299B79152}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-IL-40-upper-ocd-person-1ae7c123-b1a4-4c6e-b4d3-dc22db461e44",
              "builtID": "il-upper-40",
              "externalID": "ocd-person/1ae7c123-b1a4-4c6e-b4d3-dc22db461e44",
              "geometry": null
            },
            "contactInfo": {
              "email": "pjoyce2133@yahoo.com",
              "contactForm": null,
              "address1": null,
              "address2": "195 W. Joe Orr Road, Chicago Heights, IL 60411",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2774",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2973",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2973",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3164",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3164",
                "https://www.senatorpatrickjoyce.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-40-upper-ocd-person-1ae7c123-b1a4-4c6e-b4d3-dc22db461e44": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/cb63cb04-f84b-4fc9-91cf-05c4e1f93345",
            "firstName": "John F.",
            "lastName": "Curran",
            "fullName": "John Curran",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{7FC724D0-C297-4D20-AC25-3D879DE286B8}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-IL-41-upper-ocd-person-cb63cb04-f84b-4fc9-91cf-05c4e1f93345",
              "builtID": "il-upper-41",
              "externalID": "ocd-person/cb63cb04-f84b-4fc9-91cf-05c4e1f93345",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorcurran@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "1011 State St. Suite 205, Lemont, IL 60439",
              "phone1": null,
              "phone2": "630-914-5733",
              "fax1": null,
              "fax2": "630-796-2658",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2699",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2915",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2915",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3115",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3115",
                "https://senatorcurran.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-41-upper-ocd-person-cb63cb04-f84b-4fc9-91cf-05c4e1f93345": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/8d0beff8-000c-470a-a6c8-a05e1b6816cb",
            "firstName": "Linda",
            "lastName": "Holmes",
            "fullName": "Linda Holmes",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{9A39C8B9-4B06-4BF7-85CE-4C6F4FD07769}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-IL-42-upper-ocd-person-8d0beff8-000c-470a-a6c8-a05e1b6816cb",
              "builtID": "il-upper-42",
              "externalID": "ocd-person/8d0beff8-000c-470a-a6c8-a05e1b6816cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "holmes42@senatedem.illinois.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4255 Westbrook Drive Suite 216, Aurora, IL 60504",
              "phone1": null,
              "phone2": "630-820-8650",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2564",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2813",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2813",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3034",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3034",
                "https://www.senatorholmes.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-42-upper-ocd-person-8d0beff8-000c-470a-a6c8-a05e1b6816cb": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/ed666567-961a-43f1-8ca3-ddd29e07f8af",
            "firstName": "Rachel F.",
            "lastName": "Ventura",
            "fullName": "Rachel Ventura",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{F4164483-CA9E-423B-ADF4-FA52EE9A3F08}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "43",
              "chamber": "upper",
              "hashID": "SLDU-IL-43-upper-ocd-person-ed666567-961a-43f1-8ca3-ddd29e07f8af",
              "builtID": "il-upper-43",
              "externalID": "ocd-person/ed666567-961a-43f1-8ca3-ddd29e07f8af",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@senatorventura.com",
              "contactForm": null,
              "address1": null,
              "address2": "221 Springfield Ave. Unit 3, Joliet, IL 60435",
              "phone1": null,
              "phone2": "331-290-0443",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3214",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3214",
                "https://www.senatorventura.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-43-upper-ocd-person-ed666567-961a-43f1-8ca3-ddd29e07f8af": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/cb406eb3-673a-4715-8d87-d5963220ad6b",
            "firstName": "Sally Jo",
            "lastName": "Turner",
            "fullName": "Sally Turner",
            "gender": "Female",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{C47C199A-2C77-4A73-BAE5-AB139ED85CAC}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "44",
              "chamber": "upper",
              "hashID": "SLDU-IL-44-upper-ocd-person-cb406eb3-673a-4715-8d87-d5963220ad6b",
              "builtID": "il-upper-44",
              "externalID": "ocd-person/cb406eb3-673a-4715-8d87-d5963220ad6b",
              "geometry": null
            },
            "contactInfo": {
              "email": "turner@ilsenategop.org",
              "contactForm": null,
              "address1": null,
              "address2": "120 S. McLean St. Suite E, Lincoln, IL 62656",
              "phone1": null,
              "phone2": "217-651-8291",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=3007",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=3007",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3195",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3195",
                "https://senatorsallyturner.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-44-upper-ocd-person-cb406eb3-673a-4715-8d87-d5963220ad6b": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/9def23d3-81e9-4baf-b0c8-eeb4e5587778",
            "firstName": "Andrew S.",
            "lastName": "Chesney",
            "fullName": "Andrew Chesney",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{D79AFB6D-F47C-47F7-988D-A3E99A0100E5}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "45",
              "chamber": "upper",
              "hashID": "SLDU-IL-45-upper-ocd-person-9def23d3-81e9-4baf-b0c8-eeb4e5587778",
              "builtID": "il-upper-45",
              "externalID": "ocd-person/9def23d3-81e9-4baf-b0c8-eeb4e5587778",
              "geometry": null
            },
            "contactInfo": {
              "email": "chesney@ilsenategop.org",
              "contactForm": null,
              "address1": null,
              "address2": "Stewart Centre 50 W. Douglas St., Suite 1001, Freeport, IL 61032",
              "phone1": null,
              "phone2": "815-232-0774",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3215",
                "https://ilga.gov/house/Rep.asp?GA=103&MemberID=3157",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3215",
                "https://senatorchesney.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-45-upper-ocd-person-9def23d3-81e9-4baf-b0c8-eeb4e5587778": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/997e8947-dadd-4c8d-9538-0b58c077a812",
            "firstName": "David",
            "lastName": "Koehler",
            "fullName": "Dave Koehler",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{2C57E551-D82A-4C7D-8746-3F83913F0D7D}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "46",
              "chamber": "upper",
              "hashID": "SLDU-IL-46-upper-ocd-person-997e8947-dadd-4c8d-9538-0b58c077a812",
              "builtID": "il-upper-46",
              "externalID": "ocd-person/997e8947-dadd-4c8d-9538-0b58c077a812",
              "geometry": null
            },
            "contactInfo": {
              "email": "46illinois@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "216 N. Center St., Bloomington, IL 61701",
              "phone1": null,
              "phone2": "309-808-2345",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2565",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2814",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2814",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3035",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3035",
                "https://www.senatordavekoehler.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-46-upper-ocd-person-997e8947-dadd-4c8d-9538-0b58c077a812": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/b676c70e-0bc2-45d2-bd4c-9f3fa70bf60e",
            "firstName": "Neil",
            "lastName": "Anderson",
            "fullName": "Neil Anderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{90CDA259-1DEA-4D18-AE97-30051E03D154}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "47",
              "chamber": "upper",
              "hashID": "SLDU-IL-47-upper-ocd-person-b676c70e-0bc2-45d2-bd4c-9f3fa70bf60e",
              "builtID": "il-upper-47",
              "externalID": "ocd-person/b676c70e-0bc2-45d2-bd4c-9f3fa70bf60e",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorneilanderson@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "103 N. College Ave., #201, Aledo, IL 61231",
              "phone1": null,
              "phone2": "309-230-7584",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2664",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2886",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2886",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3092",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3092",
                "https://senatorneilanderson.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-47-upper-ocd-person-b676c70e-0bc2-45d2-bd4c-9f3fa70bf60e": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/5db8d260-f850-406c-8e26-d618762c10cd",
            "firstName": "Doris",
            "lastName": "Turner",
            "fullName": "Doris Turner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{D114C73A-2295-431C-8250-38AC3353A20E}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "48",
              "chamber": "upper",
              "hashID": "SLDU-IL-48-upper-ocd-person-5db8d260-f850-406c-8e26-d618762c10cd",
              "builtID": "il-upper-48",
              "externalID": "ocd-person/5db8d260-f850-406c-8e26-d618762c10cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorturnermac@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "1210 S. Jasper St., Decatur, IL 62321",
              "phone1": null,
              "phone2": "217-706-5311",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=3009",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=3009",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3197",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3197",
                "https://www.senatordoristurner.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-48-upper-ocd-person-5db8d260-f850-406c-8e26-d618762c10cd": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/d218f82f-ce03-4277-9408-119857d1e61f",
            "firstName": "Mary",
            "lastName": "Loughran Cappel",
            "fullName": "Meg Loughran Cappel",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{344F07C5-9853-49B6-902A-89D5F81637BF}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "49",
              "chamber": "upper",
              "hashID": "SLDU-IL-49-upper-ocd-person-d218f82f-ce03-4277-9408-119857d1e61f",
              "builtID": "il-upper-49",
              "externalID": "ocd-person/d218f82f-ce03-4277-9408-119857d1e61f",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@senatorloughrancappel.com",
              "contactForm": null,
              "address1": null,
              "address2": "20660 Caton Farm Road Unit D, Crest Hill, IL 60403",
              "phone1": null,
              "phone2": "815-267-6119",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2982",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2982",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3172",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3172",
                "https://www.senatorloughrancappel.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-49-upper-ocd-person-d218f82f-ce03-4277-9408-119857d1e61f": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/be85f354-7f46-48aa-afc7-850502fda3cb",
            "firstName": "Jil Rene Walker",
            "lastName": "Tracy",
            "fullName": "Jil Tracy",
            "gender": "Female",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{21C0F860-E9DD-4215-A3AA-703E275C8526}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "50",
              "chamber": "upper",
              "hashID": "SLDU-IL-50-upper-ocd-person-be85f354-7f46-48aa-afc7-850502fda3cb",
              "builtID": "il-upper-50",
              "externalID": "ocd-person/be85f354-7f46-48aa-afc7-850502fda3cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "tracy@ilsenategop.org",
              "contactForm": null,
              "address1": null,
              "address2": "3601 E. Lake Centre Drive Suite 200, Quincy, IL 62305",
              "phone1": null,
              "phone2": "217-223-0833",
              "fax1": null,
              "fax2": "217-223-1565",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2680",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2899",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2899",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3103",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3103",
                "https://senatorjiltracy.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-50-upper-ocd-person-be85f354-7f46-48aa-afc7-850502fda3cb": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/b748f1e8-6cfd-47d6-9398-5817aa879f39",
            "firstName": "Chapin",
            "lastName": "Rose",
            "fullName": "Chapin Rose",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{BA95E1D1-0570-4492-B739-D1F593F183FD}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "51",
              "chamber": "upper",
              "hashID": "SLDU-IL-51-upper-ocd-person-b748f1e8-6cfd-47d6-9398-5817aa879f39",
              "builtID": "il-upper-51",
              "externalID": "ocd-person/b748f1e8-6cfd-47d6-9398-5817aa879f39",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorchapinrose@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "88 Broadway Ave. Suite 1, Mattoon, IL 61938",
              "phone1": null,
              "phone2": "217-607-1853",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2621",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2856",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2856",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3068",
                "https://senchapinrose.com/",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3068"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-51-upper-ocd-person-b748f1e8-6cfd-47d6-9398-5817aa879f39": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/8392abef-edd4-4e47-b649-ce4ac82ebb21",
            "firstName": "Paul",
            "lastName": "Faraci",
            "fullName": "Paul Faraci",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "52",
              "chamber": "upper",
              "hashID": "SLDU-IL-52-upper-ocd-person-8392abef-edd4-4e47-b649-ce4ac82ebb21",
              "builtID": "il-upper-52",
              "externalID": "ocd-person/8392abef-edd4-4e47-b649-ce4ac82ebb21",
              "geometry": null
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": "45 E. University Ave. Suite 206, Champaign, IL 61820",
              "phone1": null,
              "phone2": "217-355-5252",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3221",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3221",
                "https://www.senatorfaraci.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-52-upper-ocd-person-8392abef-edd4-4e47-b649-ce4ac82ebb21": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/ada0af2d-86f4-49b1-9e27-faa9fc5b4adb",
            "firstName": "Thomas Michael",
            "lastName": "Bennett",
            "fullName": "Tom Bennett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{10511B6A-CA6D-4073-B65C-FB31C0D0ACE8}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "53",
              "chamber": "upper",
              "hashID": "SLDU-IL-53-upper-ocd-person-ada0af2d-86f4-49b1-9e27-faa9fc5b4adb",
              "builtID": "il-upper-53",
              "externalID": "ocd-person/ada0af2d-86f4-49b1-9e27-faa9fc5b4adb",
              "geometry": null
            },
            "contactInfo": {
              "email": "bennett@ilsenategop.org",
              "contactForm": null,
              "address1": null,
              "address2": "305 W. Madison St., Pontiac, IL 61764",
              "phone1": null,
              "phone2": "815-844-9179",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3222",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3222",
                "https://senatortombennett.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-53-upper-ocd-person-ada0af2d-86f4-49b1-9e27-faa9fc5b4adb": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/d3450848-4552-4b8f-97dc-fb3c26c2566c",
            "firstName": "Steve",
            "lastName": "McClure",
            "fullName": "Steve McClure",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{EB86B3C4-3F25-4D5C-8944-7B4CBCA7CACE}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "54",
              "chamber": "upper",
              "hashID": "SLDU-IL-54-upper-ocd-person-d3450848-4552-4b8f-97dc-fb3c26c2566c",
              "builtID": "il-upper-54",
              "externalID": "ocd-person/d3450848-4552-4b8f-97dc-fb3c26c2566c",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatormcclure@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "410 N. Monroe St. Suite 200, Litchfield, IL 62056",
              "phone1": null,
              "phone2": "217-960-0788",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3131",
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2728",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2933",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2933",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3131",
                "https://senatormcclure.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-54-upper-ocd-person-d3450848-4552-4b8f-97dc-fb3c26c2566c": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/d868bc8f-f51c-4f5b-a9a3-3608b8b44b29",
            "firstName": "Jason",
            "lastName": "Plummer",
            "fullName": "Jason Plummer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{CE75DC7E-2CD4-4D01-B697-C2866F828E25}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "55",
              "chamber": "upper",
              "hashID": "SLDU-IL-55-upper-ocd-person-d868bc8f-f51c-4f5b-a9a3-3608b8b44b29",
              "builtID": "il-upper-55",
              "externalID": "ocd-person/d868bc8f-f51c-4f5b-a9a3-3608b8b44b29",
              "geometry": null
            },
            "contactInfo": {
              "email": "senator@senatorjasonplummer.com",
              "contactForm": null,
              "address1": null,
              "address2": "310 W. Gallatin St., Vandalia, IL 62471",
              "phone1": null,
              "phone2": "618-283-3000",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2730",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2935",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2935",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3133",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3133"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-55-upper-ocd-person-d868bc8f-f51c-4f5b-a9a3-3608b8b44b29": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/4dadb374-d534-4ba0-8323-6e41a19933f5",
            "firstName": "Erica Conway",
            "lastName": "Harriss",
            "fullName": "Erica Harriss",
            "gender": "Female",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{7762DCAD-269B-472B-BDD4-5F5A28D47817}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "56",
              "chamber": "upper",
              "hashID": "SLDU-IL-56-upper-ocd-person-4dadb374-d534-4ba0-8323-6e41a19933f5",
              "builtID": "il-upper-56",
              "externalID": "ocd-person/4dadb374-d534-4ba0-8323-6e41a19933f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "harris@ilsenategop.org",
              "contactForm": null,
              "address1": null,
              "address2": "120 N. Main St. Suite 1B, Edwardsville, IL 62025",
              "phone1": null,
              "phone2": "618-307-5789",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3216",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3216",
                "https://senatorericaharriss.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-56-upper-ocd-person-4dadb374-d534-4ba0-8323-6e41a19933f5": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/ce79408a-9923-4ab4-9c3a-805a2b4162d5",
            "firstName": "Christopher",
            "lastName": "Belt",
            "fullName": "Christopher Belt",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ilga.gov/images/members/{EFE7513C-260C-46DE-BAB3-A12F250B2937}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "57",
              "chamber": "upper",
              "hashID": "SLDU-IL-57-upper-ocd-person-ce79408a-9923-4ab4-9c3a-805a2b4162d5",
              "builtID": "il-upper-57",
              "externalID": "ocd-person/ce79408a-9923-4ab4-9c3a-805a2b4162d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "christophbeltforilsenate@yahoo.com",
              "contactForm": null,
              "address1": null,
              "address2": "Kenneth Hall Regional Office Building Suite 201A, East St. Louis, IL 62201",
              "phone1": null,
              "phone2": "618-875-1212",
              "fax1": null,
              "fax2": "618-274-3010",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2721",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2927",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2927",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3125",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3125",
                "https://www.senatorbelt.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-57-upper-ocd-person-ce79408a-9923-4ab4-9c3a-805a2b4162d5": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/372be8dc-8177-47e7-b128-1e6a81621f3a",
            "firstName": "Terri Courtney",
            "lastName": "Bryant",
            "fullName": "Terri Bryant",
            "gender": "Female",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{58FBFE5F-39EE-4F3C-853A-0DB98E83E893}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "58",
              "chamber": "upper",
              "hashID": "SLDU-IL-58-upper-ocd-person-372be8dc-8177-47e7-b128-1e6a81621f3a",
              "builtID": "il-upper-58",
              "externalID": "ocd-person/372be8dc-8177-47e7-b128-1e6a81621f3a",
              "geometry": null
            },
            "contactInfo": {
              "email": "staterepterribryant@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "2929 Broadway St. Suite 3, Mount Vernon, IL 62864",
              "phone1": null,
              "phone2": "618-472-8158",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/house/Rep.asp?GA=101&MemberID=2656",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2990",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2990",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3180",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3180",
                "https://senatorbryant.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-58-upper-ocd-person-372be8dc-8177-47e7-b128-1e6a81621f3a": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/5a84a944-7d29-454e-aec6-85e6c3238d53",
            "firstName": "Dale",
            "lastName": "Fowler",
            "fullName": "Dale Fowler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ilga.gov/images/members/{1E60CA94-7006-4CAB-841D-C9D99569D9D3}.jpg",
            "title": "IL Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IL",
              "stateFull": "Illinois",
              "district": "59",
              "chamber": "upper",
              "hashID": "SLDU-IL-59-upper-ocd-person-5a84a944-7d29-454e-aec6-85e6c3238d53",
              "builtID": "il-upper-59",
              "externalID": "ocd-person/5a84a944-7d29-454e-aec6-85e6c3238d53",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorfowler59@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "2 N. Vine Suite 600, Harrisburg, IL 62946",
              "phone1": null,
              "phone2": "618-294-8951",
              "fax1": null,
              "fax2": "618-294-8950",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ilga.gov/senate/Senator.asp?GA=101&MemberID=2678",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2897",
                "https://ilga.gov/senate/Senator.asp?GA=102&MemberID=2897",
                "https://ilga.gov/senate/Senator.asp?GA=103&MemberID=3102",
                "https://www.ilga.gov/senate/Senator.asp?GA=101&MemberID=2678",
                "https://www.ilga.gov/senate/Senator.asp?GA=103&MemberID=3102",
                "https://senatorfowler.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IL-59-upper-ocd-person-5a84a944-7d29-454e-aec6-85e6c3238d53": 0
        }
      }
    }
  },
  "IN": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "IN",
          "stateFull": "Indiana",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "IN",
            "stateFull": "Indiana",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-IN---",
            "builtID": "in--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-IN---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "B001310",
          "in_office": true,
          "firstName": "Mike",
          "lastName": "Braun",
          "middleName": null,
          "fullName": "Mike Braun",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/B001310.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "IN",
            "stateFull": "Indiana",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-IN---B001310",
            "builtID": "in--",
            "externalID": "B001310",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.braun.senate.gov/contact-mike",
            "address1": "404 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-4814",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorBraun",
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.braun.senate.gov"],
            "rss_url": null
          },
          "title": "IN Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/B001310.json",
          "govtrack_id": "412839",
          "cspan_id": null,
          "votesmart_id": "148564",
          "icpsr_id": null,
          "crp_id": "N00041731",
          "google_entity_id": "/g/11f2ckdwty",
          "state_rank": "junior",
          "lis_id": "S397",
          "suffix": null,
          "date_of_birth": "1954-03-24",
          "leadership_role": null,
          "fec_candidate_id": "S8IN00171",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 52,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 9.39,
          "votes_with_party_pct": 85.16,
          "votes_against_party_pct": 14.84,
          "ocd_id": "ocd-division/country:us/state:in"
        },
        {
          "API_ID": "Y000064",
          "in_office": true,
          "firstName": "Todd",
          "lastName": "Young",
          "middleName": null,
          "fullName": "Todd Young",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/Y000064.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "IN",
            "stateFull": "Indiana",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-IN---Y000064",
            "builtID": "in--",
            "externalID": "Y000064",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenToddYoung",
            "youtube": "RepToddYoung",
            "instagram": null,
            "facebook": "SenatorToddYoung",
            "urls": ["https://www.young.senate.gov"],
            "rss_url": null
          },
          "title": "IN Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/Y000064.json",
          "govtrack_id": "412428",
          "cspan_id": "1033743",
          "votesmart_id": "120345",
          "icpsr_id": "21133",
          "crp_id": "N00030670",
          "google_entity_id": "/m/0ds030m",
          "state_rank": "senior",
          "lis_id": "S391",
          "suffix": null,
          "date_of_birth": "1972-08-24",
          "leadership_role": null,
          "fec_candidate_id": "H0IN09070",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "7",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 8,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:25 -0400",
          "missed_votes_pct": 1.44,
          "votes_with_party_pct": 84.88,
          "votes_against_party_pct": 15.12,
          "ocd_id": "ocd-division/country:us/state:in"
        }
      ],
      "hash": { "SENATE-IN---B001310": 0, "SENATE-IN---Y000064": 1 }
    },
    "HOUSE": {
      "04": {
        "members": [
          {
            "API_ID": "B001307",
            "in_office": true,
            "firstName": "James",
            "lastName": "Baird",
            "middleName": null,
            "fullName": "James R. Baird",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001307.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-IN-04--B001307",
              "builtID": "in--04",
              "externalID": "B001307",
              "geometry": null,
              "geoid": "1804"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2303 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5037",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJimBaird",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://baird.house.gov"],
              "rss_url": null
            },
            "title": "IN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001307.json",
            "govtrack_id": "412777",
            "cspan_id": null,
            "votesmart_id": "86013",
            "icpsr_id": null,
            "crp_id": "N00041954",
            "google_entity_id": "/g/11gfd5wndr",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1945-06-04",
            "leadership_role": null,
            "fec_candidate_id": "H8IN04199",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 14,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.33,
            "votes_with_party_pct": 87.76,
            "votes_against_party_pct": 10.28,
            "ocd_id": "ocd-division/country:us/state:in/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IN-04--B001307": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "B001299",
            "in_office": true,
            "firstName": "Jim",
            "lastName": "Banks",
            "middleName": null,
            "fullName": "Jim Banks",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001299.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-IN-03--B001299",
              "builtID": "in--03",
              "externalID": "B001299",
              "geometry": null,
              "geoid": "1803"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2418 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4436",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJimBanks",
              "youtube": null,
              "instagram": null,
              "facebook": "RepJimBanks",
              "urls": ["https://banks.house.gov"],
              "rss_url": null
            },
            "title": "IN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001299.json",
            "govtrack_id": "412702",
            "cspan_id": "89806",
            "votesmart_id": "116801",
            "icpsr_id": "21702",
            "crp_id": "N00037185",
            "google_entity_id": "/m/0j621nj",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1979-07-16",
            "leadership_role": null,
            "fec_candidate_id": "H6IN03229",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 41,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 3.88,
            "votes_with_party_pct": 91.05,
            "votes_against_party_pct": 6.94,
            "ocd_id": "ocd-division/country:us/state:in/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IN-03--B001299": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "B001275",
            "in_office": true,
            "firstName": "Larry",
            "lastName": "Bucshon",
            "middleName": null,
            "fullName": "Larry Bucshon",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001275.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-IN-08--B001275",
              "builtID": "in--08",
              "externalID": "B001275",
              "geometry": null,
              "geoid": "1808"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2313 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4636",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLarryBucshon",
              "youtube": "RepLarryBucshon",
              "instagram": null,
              "facebook": "RepLarryBucshon",
              "urls": ["https://bucshon.house.gov"],
              "rss_url": null
            },
            "title": "IN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001275.json",
            "govtrack_id": "412427",
            "cspan_id": "61837",
            "votesmart_id": "120335",
            "icpsr_id": "21132",
            "crp_id": "N00031227",
            "google_entity_id": "/m/0dd9k_y",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1962-05-31",
            "leadership_role": null,
            "fec_candidate_id": "H0IN08114",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 47,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 4.45,
            "votes_with_party_pct": 86.22,
            "votes_against_party_pct": 11.85,
            "ocd_id": "ocd-division/country:us/state:in/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IN-08--B001275": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "C001072",
            "in_office": true,
            "firstName": "André",
            "lastName": "Carson",
            "middleName": null,
            "fullName": "André Carson",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001072.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-IN-07--C001072",
              "builtID": "in--07",
              "externalID": "C001072",
              "geometry": null,
              "geoid": "1807"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2135 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4011",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAndreCarson",
              "youtube": "repandrecarson",
              "instagram": null,
              "facebook": "CongressmanAndreCarson",
              "urls": ["https://carson.house.gov"],
              "rss_url": "https://carson.house.gov/rss.xml"
            },
            "title": "IN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001072.json",
            "govtrack_id": "412258",
            "cspan_id": "1027364",
            "votesmart_id": "84917",
            "icpsr_id": "20757",
            "crp_id": "N00029513",
            "google_entity_id": "/m/03qcvz1",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1974-10-16",
            "leadership_role": null,
            "fec_candidate_id": "H8IN07184",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "18",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 27,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.56,
            "votes_with_party_pct": 94.99,
            "votes_against_party_pct": 3.14,
            "ocd_id": "ocd-division/country:us/state:in/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IN-07--C001072": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "H001093",
            "in_office": true,
            "firstName": "Erin",
            "lastName": "Houchin",
            "middleName": null,
            "fullName": "Erin Houchin",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/H001093.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-IN-09--H001093",
              "builtID": "in--09",
              "externalID": "H001093",
              "geometry": null,
              "geoid": "1809"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1632 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5315",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepHouchin",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://houchin.house.gov/"],
              "rss_url": null
            },
            "title": "IN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001093.json",
            "govtrack_id": "456903",
            "cspan_id": null,
            "votesmart_id": "149614",
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-09-24",
            "leadership_role": null,
            "fec_candidate_id": "H6IN09143",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 2,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.19,
            "votes_with_party_pct": 93.9,
            "votes_against_party_pct": 4.16,
            "ocd_id": "ocd-division/country:us/state:in/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IN-09--H001093": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "M001214",
            "in_office": true,
            "firstName": "Frank",
            "lastName": "Mrvan",
            "middleName": null,
            "fullName": "Frank J. Mrvan",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M001214.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-IN-01--M001214",
              "builtID": "in--01",
              "externalID": "M001214",
              "geometry": null,
              "geoid": "1801"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1607 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2461",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMrvan",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://mrvan.house.gov"],
              "rss_url": null
            },
            "title": "IN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001214.json",
            "govtrack_id": "456821",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00045905",
            "google_entity_id": "/m/03yd13z",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1969-04-16",
            "leadership_role": null,
            "fec_candidate_id": "H0IN01150",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 13,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.23,
            "votes_with_party_pct": 93.61,
            "votes_against_party_pct": 4.45,
            "ocd_id": "ocd-division/country:us/state:in/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IN-01--M001214": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "P000615",
            "in_office": true,
            "firstName": "Greg",
            "lastName": "Pence",
            "middleName": null,
            "fullName": "Greg Pence",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/P000615.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-IN-06--P000615",
              "builtID": "in--06",
              "externalID": "P000615",
              "geometry": null,
              "geoid": "1806"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "404 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3021",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGregPence",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://pence.house.gov"],
              "rss_url": null
            },
            "title": "IN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000615.json",
            "govtrack_id": "412778",
            "cspan_id": null,
            "votesmart_id": "177876",
            "icpsr_id": null,
            "crp_id": "N00041956",
            "google_entity_id": "/g/11gh5qt6_p",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1956-11-14",
            "leadership_role": null,
            "fec_candidate_id": "H8IN06129",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 106,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 10.04,
            "votes_with_party_pct": 91.41,
            "votes_against_party_pct": 6.44,
            "ocd_id": "ocd-division/country:us/state:in/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IN-06--P000615": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "S000929",
            "in_office": true,
            "firstName": "Victoria",
            "lastName": "Spartz",
            "middleName": null,
            "fullName": "Victoria Spartz",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S000929.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-IN-05--S000929",
              "builtID": "in--05",
              "externalID": "S000929",
              "geometry": null,
              "geoid": "1805"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1609 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2276",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepSpartz",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://spartz.house.gov"],
              "rss_url": null
            },
            "title": "IN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S000929.json",
            "govtrack_id": "456822",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00046537",
            "google_entity_id": "/g/11h23hjb94",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1978-10-06",
            "leadership_role": null,
            "fec_candidate_id": "H0IN05326",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 63,
            "total_present": 9,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 5.97,
            "votes_with_party_pct": 87.58,
            "votes_against_party_pct": 11.19,
            "ocd_id": "ocd-division/country:us/state:in/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IN-05--S000929": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "Y000067",
            "in_office": true,
            "firstName": "Rudy",
            "lastName": "Yakym",
            "middleName": null,
            "fullName": "Rudy Yakym III",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/Y000067.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-IN-02--Y000067",
              "builtID": "in--02",
              "externalID": "Y000067",
              "geometry": null,
              "geoid": "1802"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "349 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3915",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRudyYakym",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://yakym.house.gov/"],
              "rss_url": ""
            },
            "title": "IN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/Y000067.json",
            "govtrack_id": "456873",
            "cspan_id": null,
            "votesmart_id": "210545",
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1984-02-24",
            "leadership_role": null,
            "fec_candidate_id": "H2IN02295",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 2,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.19,
            "votes_with_party_pct": 94.77,
            "votes_against_party_pct": 3.29,
            "ocd_id": "ocd-division/country:us/state:in/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IN-02--Y000067": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/16ded3c5-7034-4910-b5d1-76efa7b2f02f",
            "firstName": "Carolyn B.",
            "lastName": "Jackson",
            "fullName": "Carolyn Jackson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/Jackson_Carolyn_web20220118_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-IN-1-lower-ocd-person-16ded3c5-7034-4910-b5d1-76efa7b2f02f",
              "builtID": "in-lower-1",
              "externalID": "ocd-person/16ded3c5-7034-4910-b5d1-76efa7b2f02f",
              "geometry": null
            },
            "contactInfo": {
              "email": "h1@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_carolyn_jackson_1",
                "https://iga.in.gov/legislative/2019/legislators/legislator_carolyn_jackson_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_carolyn_jackson_1/",
                "https://indianahousedemocrats.org/members/carolyn-b.-jackson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-1-lower-ocd-person-16ded3c5-7034-4910-b5d1-76efa7b2f02f": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/e0aaea95-033f-49d8-9be6-3875fc3b8efe",
            "firstName": "Earl L.",
            "lastName": "Harris",
            "fullName": "Earl Harris",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/Harris_Earl_Jr_2021_web_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-IN-2-lower-ocd-person-e0aaea95-033f-49d8-9be6-3875fc3b8efe",
              "builtID": "in-lower-2",
              "externalID": "ocd-person/e0aaea95-033f-49d8-9be6-3875fc3b8efe",
              "geometry": null
            },
            "contactInfo": {
              "email": "h2@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_earl_harris_1",
                "https://indianahousedemocrats.org/members/earl-l-harris-jr",
                "https://iga.in.gov/legislative/2024/legislators/legislator_earl_harris_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-2-lower-ocd-person-e0aaea95-033f-49d8-9be6-3875fc3b8efe": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/4c9685ab-f2a4-46e0-980a-699bb01d26cf",
            "firstName": "Ragen",
            "lastName": "Hatcher",
            "fullName": "Ragen Hatcher",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/Hatcher_Ragen_2020_web_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-IN-3-lower-ocd-person-4c9685ab-f2a4-46e0-980a-699bb01d26cf",
              "builtID": "in-lower-3",
              "externalID": "ocd-person/4c9685ab-f2a4-46e0-980a-699bb01d26cf",
              "geometry": null
            },
            "contactInfo": {
              "email": "h3@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_ragen_hatcher_1",
                "https://iga.in.gov/legislative/2019/legislators/legislator_ragen_hatcher_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_ragen_hatcher_1/",
                "https://indianahousedemocrats.org/members/ragen-hatcher"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-3-lower-ocd-person-4c9685ab-f2a4-46e0-980a-699bb01d26cf": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/3fbcc2a9-c620-456c-b38f-03e0f9063c3f",
            "firstName": "Edmond L.",
            "lastName": "Soliday",
            "fullName": "Ed Soliday",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Soliday_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-IN-4-lower-ocd-person-3fbcc2a9-c620-456c-b38f-03e0f9063c3f",
              "builtID": "in-lower-4",
              "externalID": "ocd-person/3fbcc2a9-c620-456c-b38f-03e0f9063c3f",
              "geometry": null
            },
            "contactInfo": {
              "email": "h4@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_edmond_soliday_864",
                "https://www.indianahouserepublicans.com/members/general/ed-soliday/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_edmond_soliday_864/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_edmond_soliday_864/",
                "https://www.indianahouserepublicans.com/members/general/ed-soliday/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-4-lower-ocd-person-3fbcc2a9-c620-456c-b38f-03e0f9063c3f": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/da8afc87-2b9a-4544-8fb9-2f03f426a451",
            "firstName": "Dale",
            "lastName": "DeVon",
            "fullName": "Dale DeVon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/DeVon_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-IN-5-lower-ocd-person-da8afc87-2b9a-4544-8fb9-2f03f426a451",
              "builtID": "in-lower-5",
              "externalID": "ocd-person/da8afc87-2b9a-4544-8fb9-2f03f426a451",
              "geometry": null
            },
            "contactInfo": {
              "email": "h5@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_dale_devon_1108",
                "https://www.indianahouserepublicans.com/members/general/dale-devon/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_dale_devon_1108/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_dale_devon_1108/",
                "https://www.indianahouserepublicans.com/members/general/dale-devon/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-5-lower-ocd-person-da8afc87-2b9a-4544-8fb9-2f03f426a451": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/7e493895-2f49-4a56-a4a3-f888373769b5",
            "firstName": "Maureen",
            "lastName": "Bauer",
            "fullName": "Maureen Bauer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/Bauer_Maureen_web_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-IN-6-lower-ocd-person-7e493895-2f49-4a56-a4a3-f888373769b5",
              "builtID": "in-lower-6",
              "externalID": "ocd-person/7e493895-2f49-4a56-a4a3-f888373769b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "h06@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_maureen_bauer_1",
                "https://iga.in.gov/legislative/2021/legislators/legislator_maureen_bauer_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_maureen_bauer_1/",
                "https://indianahousedemocrats.org/members/maureen-bauer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-6-lower-ocd-person-7e493895-2f49-4a56-a4a3-f888373769b5": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/d0f113c5-ffad-4c18-867b-417fea0d0a52",
            "firstName": "Jacob",
            "lastName": "Teshka",
            "fullName": "Jake Teshka",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Teshka_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-IN-7-lower-ocd-person-d0f113c5-ffad-4c18-867b-417fea0d0a52",
              "builtID": "in-lower-7",
              "externalID": "ocd-person/d0f113c5-ffad-4c18-867b-417fea0d0a52",
              "geometry": null
            },
            "contactInfo": {
              "email": "h7@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_jake_teshka_1",
                "https://iga.in.gov/legislative/2021/legislators/legislator_jake_teshka_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_jake_teshka_1/",
                "https://www.indianahouserepublicans.com/members/general/jake-teshka/?back=members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-7-lower-ocd-person-d0f113c5-ffad-4c18-867b-417fea0d0a52": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/16b01fca-3a93-41a3-ad6a-f2bf9209ddf7",
            "firstName": "Ryan Michael",
            "lastName": "Dvorak",
            "fullName": "Ryan Dvorak",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/dvorak_resized_800_1000_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-IN-8-lower-ocd-person-16b01fca-3a93-41a3-ad6a-f2bf9209ddf7",
              "builtID": "in-lower-8",
              "externalID": "ocd-person/16b01fca-3a93-41a3-ad6a-f2bf9209ddf7",
              "geometry": null
            },
            "contactInfo": {
              "email": "h8@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_ryan_dvorak_697",
                "https://iga.in.gov/legislative/2021/legislators/legislator_ryan_dvorak_697/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_ryan_dvorak_697/",
                "https://indianahousedemocrats.org/members/ryan-dvorak"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-8-lower-ocd-person-16b01fca-3a93-41a3-ad6a-f2bf9209ddf7": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/8dde5c26-4718-45e7-a021-487d7fa6a924",
            "firstName": "Patricia",
            "lastName": "Boy",
            "fullName": "Patricia Boy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/Boy_Pat_web20220118_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-IN-9-lower-ocd-person-8dde5c26-4718-45e7-a021-487d7fa6a924",
              "builtID": "in-lower-9",
              "externalID": "ocd-person/8dde5c26-4718-45e7-a021-487d7fa6a924",
              "geometry": null
            },
            "contactInfo": {
              "email": "h9@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_patricia_boy_1",
                "https://indianahousedemocrats.org/members/pat-boy",
                "https://iga.in.gov/legislative/2019/legislators/legislator_patricia_boy_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_patricia_boy_1/",
                "https://indianahousedemocrats.org/members/pat-boy/full"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-9-lower-ocd-person-8dde5c26-4718-45e7-a021-487d7fa6a924": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/17fd494d-905d-4c82-a828-6d975cbf02f3",
            "firstName": "Charles A.",
            "lastName": "Moseley",
            "fullName": "Chuck Moseley",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/Moseley_updated_800_1000_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-IN-10-lower-ocd-person-17fd494d-905d-4c82-a828-6d975cbf02f3",
              "builtID": "in-lower-10",
              "externalID": "ocd-person/17fd494d-905d-4c82-a828-6d975cbf02f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "h10@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_charles_moseley_973",
                "https://iga.in.gov/legislative/2021/legislators/legislator_charles_moseley_973/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_charles_moseley_973/",
                "https://indianahousedemocrats.org/members/chuck-moseley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-10-lower-ocd-person-17fd494d-905d-4c82-a828-6d975cbf02f3": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/2ca76c73-5db4-499b-b718-c83294b5f68e",
            "firstName": "Michael Jerome",
            "lastName": "Aylesworth",
            "fullName": "Mike Aylesworth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Aylesworth_Main_List_2022.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-IN-11-lower-ocd-person-2ca76c73-5db4-499b-b718-c83294b5f68e",
              "builtID": "in-lower-11",
              "externalID": "ocd-person/2ca76c73-5db4-499b-b718-c83294b5f68e",
              "geometry": null
            },
            "contactInfo": {
              "email": "h11@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_mike_aylesworth_1",
                "https://www.indianahouserepublicans.com/members/general/michael-aylesworth/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_mike_aylesworth_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_mike_aylesworth_1/",
                "https://www.indianahouserepublicans.com/members/general/michael-aylesworth/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-11-lower-ocd-person-2ca76c73-5db4-499b-b718-c83294b5f68e": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/14aac431-636f-483e-ac39-bf45be53af2f",
            "firstName": "Mike",
            "lastName": "Andrade",
            "fullName": "Mike Andrade",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/Andrade_Mike_web_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-IN-12-lower-ocd-person-14aac431-636f-483e-ac39-bf45be53af2f",
              "builtID": "in-lower-12",
              "externalID": "ocd-person/14aac431-636f-483e-ac39-bf45be53af2f",
              "geometry": null
            },
            "contactInfo": {
              "email": "h12@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_mike_andrade_1",
                "https://iga.in.gov/legislative/2021/legislators/legislator_mike_andrade_1/",
                "https://indianahousedemocrats.org/members/mike-andrade",
                "https://iga.in.gov/legislative/2024/legislators/legislator_mike_andrade_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-12-lower-ocd-person-14aac431-636f-483e-ac39-bf45be53af2f": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/5df40faa-22e6-4300-b749-89abfdc2e554",
            "firstName": "Sharon",
            "lastName": "Negele",
            "fullName": "Sharon Negele",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Negele_Main_List_2022_3.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-IN-13-lower-ocd-person-5df40faa-22e6-4300-b749-89abfdc2e554",
              "builtID": "in-lower-13",
              "externalID": "ocd-person/5df40faa-22e6-4300-b749-89abfdc2e554",
              "geometry": null
            },
            "contactInfo": {
              "email": "h13@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_sharon_negele_1110",
                "https://www.indianahouserepublicans.com/members/leadership/sharon-negele/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_sharon_negele_1110/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_sharon_negele_1110/",
                "https://www.indianahouserepublicans.com/members/leadership/sharon-negele/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-13-lower-ocd-person-5df40faa-22e6-4300-b749-89abfdc2e554": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/86b7914b-08e6-4ac7-bcd6-b4afa4dceb09",
            "firstName": "Vernon G.",
            "lastName": "Smith",
            "fullName": "Vernon Smith",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/Smith_Vernon_web_2020_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-IN-14-lower-ocd-person-86b7914b-08e6-4ac7-bcd6-b4afa4dceb09",
              "builtID": "in-lower-14",
              "externalID": "ocd-person/86b7914b-08e6-4ac7-bcd6-b4afa4dceb09",
              "geometry": null
            },
            "contactInfo": {
              "email": "h14@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_vernon_smith_230",
                "https://iga.in.gov/legislative/2021/legislators/legislator_vernon_smith_230/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_vernon_smith_230/",
                "https://indianahousedemocrats.org/members/vernon-smith"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-14-lower-ocd-person-86b7914b-08e6-4ac7-bcd6-b4afa4dceb09": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/b71e9f3a-2c59-4e18-80e6-da0a7cc51648",
            "firstName": "Harold",
            "lastName": "Slager",
            "fullName": "Hal Slager",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Slager_mainlist.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-IN-15-lower-ocd-person-b71e9f3a-2c59-4e18-80e6-da0a7cc51648",
              "builtID": "in-lower-15",
              "externalID": "ocd-person/b71e9f3a-2c59-4e18-80e6-da0a7cc51648",
              "geometry": null
            },
            "contactInfo": {
              "email": "h15@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_harold_slager_1111",
                "https://iga.in.gov/legislative/2021/legislators/legislator_harold_slager_1111/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_harold_slager_1111/",
                "https://www.indianahouserepublicans.com/members/general/hal-slager/?back=members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-15-lower-ocd-person-b71e9f3a-2c59-4e18-80e6-da0a7cc51648": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/0b35c9d0-f21d-41a3-b7b6-15759df5bc59",
            "firstName": "Kendell",
            "lastName": "Culp",
            "fullName": "Kendell Culp",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Culp_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-IN-16-lower-ocd-person-0b35c9d0-f21d-41a3-b7b6-15759df5bc59",
              "builtID": "in-lower-16",
              "externalID": "ocd-person/0b35c9d0-f21d-41a3-b7b6-15759df5bc59",
              "geometry": null
            },
            "contactInfo": {
              "email": "h16@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.indianahouserepublicans.com/members/general/kendell-culp/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_kendell_culp_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-16-lower-ocd-person-0b35c9d0-f21d-41a3-b7b6-15759df5bc59": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/12a4df88-7cf2-453c-9cb8-dc5d4a2f6b55",
            "firstName": "Jack Edward",
            "lastName": "Jordan",
            "fullName": "Jack Jordan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Jordan_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-IN-17-lower-ocd-person-12a4df88-7cf2-453c-9cb8-dc5d4a2f6b55",
              "builtID": "in-lower-17",
              "externalID": "ocd-person/12a4df88-7cf2-453c-9cb8-dc5d4a2f6b55",
              "geometry": null
            },
            "contactInfo": {
              "email": "h17@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_jack_jordan_1",
                "https://www.indianahouserepublicans.com/members/general/jack-jordan/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_jack_jordan_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_jack_jordan_1/",
                "https://www.indianahouserepublicans.com/members/general/jack-jordan/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-17-lower-ocd-person-12a4df88-7cf2-453c-9cb8-dc5d4a2f6b55": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/a080b584-c899-4694-a797-73cca22610fe",
            "firstName": "David",
            "lastName": "Abbott",
            "fullName": "David Abbott",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Abbott_Main_List_2022.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-IN-18-lower-ocd-person-a080b584-c899-4694-a797-73cca22610fe",
              "builtID": "in-lower-18",
              "externalID": "ocd-person/a080b584-c899-4694-a797-73cca22610fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "h18@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_david_abbott_1",
                "https://www.indianahouserepublicans.com/members/general/david-abbott/?back=members",
                "https://www.indianahouserepublicans.com/members/leadership/david-abbott/?back=members",
                "https://www.indianahouserepublicans.com/members/general/david-abbott/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_david_abbott_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-18-lower-ocd-person-a080b584-c899-4694-a797-73cca22610fe": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/df8a1659-63b2-4e11-8dce-17bce11f8162",
            "firstName": "Julie",
            "lastName": "Olthoff",
            "fullName": "Julie Olthoff",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Olthoff_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-IN-19-lower-ocd-person-df8a1659-63b2-4e11-8dce-17bce11f8162",
              "builtID": "in-lower-19",
              "externalID": "ocd-person/df8a1659-63b2-4e11-8dce-17bce11f8162",
              "geometry": null
            },
            "contactInfo": {
              "email": "h19@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_julie_olthoff_1",
                "https://www.indianahouserepublicans.com/members/leadership/julie-olthoff/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_julie_olthoff_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_julie_olthoff_1/",
                "https://www.indianahouserepublicans.com/members/leadership/julie-olthoff/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-19-lower-ocd-person-df8a1659-63b2-4e11-8dce-17bce11f8162": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/3918dc6e-fbf5-4ea0-b62a-376729b463ec",
            "firstName": "Jim",
            "lastName": "Pressel",
            "fullName": "Jim Pressel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Pressel_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-IN-20-lower-ocd-person-3918dc6e-fbf5-4ea0-b62a-376729b463ec",
              "builtID": "in-lower-20",
              "externalID": "ocd-person/3918dc6e-fbf5-4ea0-b62a-376729b463ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "h20@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_jim_pressel_1",
                "https://www.indianahouserepublicans.com/members/general/jim-pressel/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_jim_pressel_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_jim_pressel_1/",
                "https://www.indianahouserepublicans.com/members/general/jim-pressel/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-20-lower-ocd-person-3918dc6e-fbf5-4ea0-b62a-376729b463ec": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/fbcd4d2c-32c6-4c97-837b-4e4ed80c43df",
            "firstName": "Timothy",
            "lastName": "Wesco",
            "fullName": "Tim Wesco",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Wesco_MainList2023.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-IN-21-lower-ocd-person-fbcd4d2c-32c6-4c97-837b-4e4ed80c43df",
              "builtID": "in-lower-21",
              "externalID": "ocd-person/fbcd4d2c-32c6-4c97-837b-4e4ed80c43df",
              "geometry": null
            },
            "contactInfo": {
              "email": "h21@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_timothy_wesco_1025",
                "https://www.indianahouserepublicans.com/members/general/timothy-wesco/?back=members",
                "https://iga.in.gov/legislative/2023/legislators/legislator_timothy_wesco_1025/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_timothy_wesco_1025/",
                "https://www.indianahouserepublicans.com/members/general/timothy-wesco/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-21-lower-ocd-person-fbcd4d2c-32c6-4c97-837b-4e4ed80c43df": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/d9c1cb89-9943-4afd-9353-8c771dcac356",
            "firstName": "Craig",
            "lastName": "Snow",
            "fullName": "Craig Snow",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Snow_mainlist2023.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-IN-22-lower-ocd-person-d9c1cb89-9943-4afd-9353-8c771dcac356",
              "builtID": "in-lower-22",
              "externalID": "ocd-person/d9c1cb89-9943-4afd-9353-8c771dcac356",
              "geometry": null
            },
            "contactInfo": {
              "email": "h22@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_craig_snow_1",
                "https://www.indianahouserepublicans.com/members/general/craig-snow/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_craig_snow_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-22-lower-ocd-person-d9c1cb89-9943-4afd-9353-8c771dcac356": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/bdae77c7-d89e-426d-b6f6-52e1a48bc87a",
            "firstName": "Ethan E.",
            "lastName": "Manning",
            "fullName": "Ethan Manning",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Manning_MainList2023V2.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-IN-23-lower-ocd-person-bdae77c7-d89e-426d-b6f6-52e1a48bc87a",
              "builtID": "in-lower-23",
              "externalID": "ocd-person/bdae77c7-d89e-426d-b6f6-52e1a48bc87a",
              "geometry": null
            },
            "contactInfo": {
              "email": "h23@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_ethan_manning_1",
                "https://www.indianahouserepublicans.com/members/general/ethan-manning/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_ethan_manning_1/",
                "https://www.indianahouserepublicans.com/members/general/ethan-manning/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_ethan_manning_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-23-lower-ocd-person-bdae77c7-d89e-426d-b6f6-52e1a48bc87a": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/062c9921-6c7e-489b-bcc2-b140231cd2c2",
            "firstName": "Donna",
            "lastName": "Schaibley",
            "fullName": "Donna Schaibley",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Schaibley_lister2021.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-IN-24-lower-ocd-person-062c9921-6c7e-489b-bcc2-b140231cd2c2",
              "builtID": "in-lower-24",
              "externalID": "ocd-person/062c9921-6c7e-489b-bcc2-b140231cd2c2",
              "geometry": null
            },
            "contactInfo": {
              "email": "h24@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_donna_schaibley_1",
                "https://www.indianahouserepublicans.com/members/general/donna-schaibley/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_donna_schaibley_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_donna_schaibley_1/",
                "https://www.indianahouserepublicans.com/members/general/donna-schaibley/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-24-lower-ocd-person-062c9921-6c7e-489b-bcc2-b140231cd2c2": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/172ba652-d80a-45cd-a387-f072925561f7",
            "firstName": "Becky Tucker",
            "lastName": "Cash",
            "fullName": "Becky Cash",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Cash_Main_list.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-IN-25-lower-ocd-person-172ba652-d80a-45cd-a387-f072925561f7",
              "builtID": "in-lower-25",
              "externalID": "ocd-person/172ba652-d80a-45cd-a387-f072925561f7",
              "geometry": null
            },
            "contactInfo": {
              "email": "h25@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.indianahouserepublicans.com/members/general/becky-cash/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_becky_cash_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-25-lower-ocd-person-172ba652-d80a-45cd-a387-f072925561f7": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/a2b2e61b-e27c-4ead-8c81-6703ea817037",
            "firstName": "Chris",
            "lastName": "Campbell",
            "fullName": "Chris Campbell",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/banners/campbell_UPDATED_headshot_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-IN-26-lower-ocd-person-a2b2e61b-e27c-4ead-8c81-6703ea817037",
              "builtID": "in-lower-26",
              "externalID": "ocd-person/a2b2e61b-e27c-4ead-8c81-6703ea817037",
              "geometry": null
            },
            "contactInfo": {
              "email": "h26@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_christine_campbell_1",
                "https://iga.in.gov/legislative/2019/legislators/legislator_christine_campbell_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_christine_campbell_1/",
                "https://indianahousedemocrats.org/members/chris-campbell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-26-lower-ocd-person-a2b2e61b-e27c-4ead-8c81-6703ea817037": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/68f44f31-ba0b-4641-a656-f05e91c02cd8",
            "firstName": "Sheila Ann",
            "lastName": "Klinker",
            "fullName": "Sheila Klinker",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/Klinker_updated_800_1036.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-IN-27-lower-ocd-person-68f44f31-ba0b-4641-a656-f05e91c02cd8",
              "builtID": "in-lower-27",
              "externalID": "ocd-person/68f44f31-ba0b-4641-a656-f05e91c02cd8",
              "geometry": null
            },
            "contactInfo": {
              "email": "h27@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_sheila_klinker_212",
                "https://iga.in.gov/legislative/2021/legislators/legislator_sheila_klinker_212/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_sheila_klinker_212/",
                "https://indianahousedemocrats.org/members/sheila-klinker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-27-lower-ocd-person-68f44f31-ba0b-4641-a656-f05e91c02cd8": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/6835c75a-05e6-44d8-9d8b-7ee4559892b2",
            "firstName": "Jeffrey A.",
            "lastName": "Thompson",
            "fullName": "Jeff Thompson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Thompson_lister2021.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-IN-28-lower-ocd-person-6835c75a-05e6-44d8-9d8b-7ee4559892b2",
              "builtID": "in-lower-28",
              "externalID": "ocd-person/6835c75a-05e6-44d8-9d8b-7ee4559892b2",
              "geometry": null
            },
            "contactInfo": {
              "email": "h28@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_jeffrey_thompson_529",
                "https://www.indianahouserepublicans.com/members/general/jeff-thompson/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_jeffrey_thompson_529/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_jeffrey_thompson_529/",
                "https://www.indianahouserepublicans.com/members/general/jeff-thompson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-28-lower-ocd-person-6835c75a-05e6-44d8-9d8b-7ee4559892b2": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/e9844fde-43a6-4aa8-9248-85ab1ad79c20",
            "firstName": "Charles",
            "lastName": "Goodrich",
            "fullName": "Chuck Goodrich",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Goodrich_mainlist3.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-IN-29-lower-ocd-person-e9844fde-43a6-4aa8-9248-85ab1ad79c20",
              "builtID": "in-lower-29",
              "externalID": "ocd-person/e9844fde-43a6-4aa8-9248-85ab1ad79c20",
              "geometry": null
            },
            "contactInfo": {
              "email": "h29@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_chuck_goodrich_1",
                "https://www.indianahouserepublicans.com/members/general/chuck-goodrich/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_chuck_goodrich_1/",
                "https://www.indianahouserepublicans.com/members/general/chuck-goodrich/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_chuck_goodrich_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-29-lower-ocd-person-e9844fde-43a6-4aa8-9248-85ab1ad79c20": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/344cb784-deed-4671-94f1-0616555296e3",
            "firstName": "Michael",
            "lastName": "Karickhoff",
            "fullName": "Mike Karickhoff",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Karickhoff_Main_List_2.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-IN-30-lower-ocd-person-344cb784-deed-4671-94f1-0616555296e3",
              "builtID": "in-lower-30",
              "externalID": "ocd-person/344cb784-deed-4671-94f1-0616555296e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "h30@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_michael_karickhoff_1027",
                "https://www.indianahouserepublicans.com/members/leadership/mike-karickhoff/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_michael_karickhoff_1027/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_michael_karickhoff_1027/",
                "https://www.indianahouserepublicans.com/members/leadership/mike-karickhoff/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-30-lower-ocd-person-344cb784-deed-4671-94f1-0616555296e3": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/4647c4f2-f1d1-4151-a85a-74db23a7fb5d",
            "firstName": "Lori",
            "lastName": "Goss-Reaves",
            "fullName": "Lori Goss-Reaves",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Goss-Reaves_mainlist.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-IN-31-lower-ocd-person-4647c4f2-f1d1-4151-a85a-74db23a7fb5d",
              "builtID": "in-lower-31",
              "externalID": "ocd-person/4647c4f2-f1d1-4151-a85a-74db23a7fb5d",
              "geometry": null
            },
            "contactInfo": {
              "email": "h31@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.indianahouserepublicans.com/members/general/lori-goss-reaves/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_lori_goss_reaves_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-31-lower-ocd-person-4647c4f2-f1d1-4151-a85a-74db23a7fb5d": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/58922dd9-3295-4d8f-a1ca-60356b1b4ad8",
            "firstName": "Victoria",
            "lastName": "Garcia Wilburn",
            "fullName": "Victoria Garcia Wilburn",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/garciawilburn20221129portrait-resized_800_942_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-IN-32-lower-ocd-person-58922dd9-3295-4d8f-a1ca-60356b1b4ad8",
              "builtID": "in-lower-32",
              "externalID": "ocd-person/58922dd9-3295-4d8f-a1ca-60356b1b4ad8",
              "geometry": null
            },
            "contactInfo": {
              "email": "h32@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://indianahousedemocrats.org/members/victoria-garcia-wilburn",
                "https://iga.in.gov/legislative/2024/legislators/legislator_victoria_garcia_wilburn_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-32-lower-ocd-person-58922dd9-3295-4d8f-a1ca-60356b1b4ad8": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/dcba8890-86e8-4a0c-a4a5-77b2fa98a267",
            "firstName": "John D.",
            "lastName": "Prescott",
            "fullName": "J.D. Prescott",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Prescott-mainlist2.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-IN-33-lower-ocd-person-dcba8890-86e8-4a0c-a4a5-77b2fa98a267",
              "builtID": "in-lower-33",
              "externalID": "ocd-person/dcba8890-86e8-4a0c-a4a5-77b2fa98a267",
              "geometry": null
            },
            "contactInfo": {
              "email": "h33@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_john_prescott_1",
                "https://www.indianahouserepublicans.com/members/general/j.d.-prescott/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_john_prescott_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_john_prescott_1/",
                "https://www.indianahouserepublicans.com/members/general/john-prescott/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-33-lower-ocd-person-dcba8890-86e8-4a0c-a4a5-77b2fa98a267": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/98feacbe-3be5-407e-a437-66d65a836a5e",
            "firstName": "Sue E.",
            "lastName": "Errington",
            "fullName": "Sue Errington",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/banners/errington_UPDATED_headshot_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-IN-34-lower-ocd-person-98feacbe-3be5-407e-a437-66d65a836a5e",
              "builtID": "in-lower-34",
              "externalID": "ocd-person/98feacbe-3be5-407e-a437-66d65a836a5e",
              "geometry": null
            },
            "contactInfo": {
              "email": "h34@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_sue_errington_866",
                "https://iga.in.gov/legislative/2019/legislators/legislator_sue_errington_866/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_sue_errington_866/",
                "https://indianahousedemocrats.org/members/sue-errington"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-34-lower-ocd-person-98feacbe-3be5-407e-a437-66d65a836a5e": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/fafeb171-4592-4254-bb59-788745371b7b",
            "firstName": "Elizabeth Vandelene",
            "lastName": "Rowray",
            "fullName": "Elizabeth Rowray",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Rowray_MainList2023.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-IN-35-lower-ocd-person-fafeb171-4592-4254-bb59-788745371b7b",
              "builtID": "in-lower-35",
              "externalID": "ocd-person/fafeb171-4592-4254-bb59-788745371b7b",
              "geometry": null
            },
            "contactInfo": {
              "email": "h35@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_elizabeth_rowray_1",
                "https://www.indianahouserepublicans.com/members/leadership/elizabeth-rowray/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_elizabeth_rowray_1/",
                "https://www.indianahouserepublicans.com/members/general/elizabeth-rowray/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_elizabeth_rowray_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-35-lower-ocd-person-fafeb171-4592-4254-bb59-788745371b7b": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/b5d0a278-d77b-49fe-88d4-2bd473055144",
            "firstName": "Kyle E.",
            "lastName": "Pierce",
            "fullName": "Kyle Pierce",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Pierce_Main_List_3.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-IN-36-lower-ocd-person-b5d0a278-d77b-49fe-88d4-2bd473055144",
              "builtID": "in-lower-36",
              "externalID": "ocd-person/b5d0a278-d77b-49fe-88d4-2bd473055144",
              "geometry": null
            },
            "contactInfo": {
              "email": "h36@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.indianahouserepublicans.com/members/general/kyle-pierce/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_kyle_pierce_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-36-lower-ocd-person-b5d0a278-d77b-49fe-88d4-2bd473055144": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/d2667b82-c381-495d-b533-c70a9ae92ec0",
            "firstName": "Todd",
            "lastName": "Huston",
            "fullName": "Todd Huston",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Speaker_Huston_Lister.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-IN-37-lower-ocd-person-d2667b82-c381-495d-b533-c70a9ae92ec0",
              "builtID": "in-lower-37",
              "externalID": "ocd-person/d2667b82-c381-495d-b533-c70a9ae92ec0",
              "geometry": null
            },
            "contactInfo": {
              "email": "h37@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_todd_huston_1114",
                "https://www.indianahouserepublicans.com/members/leadership/todd-huston/?back=members",
                "https://www.indianahouserepublicans.com/members/leadership/todd-huston/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_todd_huston_1114"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-37-lower-ocd-person-d2667b82-c381-495d-b533-c70a9ae92ec0": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/113063ff-6e21-4bf7-b9d0-f06aafc349f2",
            "firstName": "Heath",
            "lastName": "VanNatter",
            "fullName": "Heath VanNatter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/VanNatter_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-IN-38-lower-ocd-person-113063ff-6e21-4bf7-b9d0-f06aafc349f2",
              "builtID": "in-lower-38",
              "externalID": "ocd-person/113063ff-6e21-4bf7-b9d0-f06aafc349f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "h38@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_heath_vannatter_956",
                "https://www.indianahouserepublicans.com/members/general/heath-vannatter/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_heath_vannatter_956/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_heath_vannatter_956/",
                "https://www.indianahouserepublicans.com/members/general/heath-vannatter/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-38-lower-ocd-person-113063ff-6e21-4bf7-b9d0-f06aafc349f2": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/585e1d84-c51f-4d50-90d2-e5828a5c2d02",
            "firstName": "Gerald R.",
            "lastName": "Torr",
            "fullName": "Jerry Torr",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Torr_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-IN-39-lower-ocd-person-585e1d84-c51f-4d50-90d2-e5828a5c2d02",
              "builtID": "in-lower-39",
              "externalID": "ocd-person/585e1d84-c51f-4d50-90d2-e5828a5c2d02",
              "geometry": null
            },
            "contactInfo": {
              "email": "h39@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_jerry_torr_117",
                "https://www.indianahouserepublicans.com/members/general/jerry-torr/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_jerry_torr_117/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_jerry_torr_117/",
                "https://www.indianahouserepublicans.com/members/general/jerry-torr/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-39-lower-ocd-person-585e1d84-c51f-4d50-90d2-e5828a5c2d02": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/3ee97114-199a-4084-903f-5eaa231ca656",
            "firstName": "Gregory E.",
            "lastName": "Steuerwald",
            "fullName": "Greg Steuerwald",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Steurerwald_MainList2023.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-IN-40-lower-ocd-person-3ee97114-199a-4084-903f-5eaa231ca656",
              "builtID": "in-lower-40",
              "externalID": "ocd-person/3ee97114-199a-4084-903f-5eaa231ca656",
              "geometry": null
            },
            "contactInfo": {
              "email": "h40@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 503, Danville, IN 46122",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_gregory_steuerwald_927",
                "https://www.indianahouserepublicans.com/members/leadership/greg-steuerwald/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_gregory_steuerwald_927/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_gregory_steuerwald_927/",
                "https://www.indianahouserepublicans.com/members/leadership/greg-steuerwald/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-40-lower-ocd-person-3ee97114-199a-4084-903f-5eaa231ca656": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/38ca634d-bbf0-42f3-bf01-4d6cdcb84bbd",
            "firstName": "Mark Joseph",
            "lastName": "Genda",
            "fullName": "Mark Genda",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Rep._Mark_Genda_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-IN-41-lower-ocd-person-38ca634d-bbf0-42f3-bf01-4d6cdcb84bbd",
              "builtID": "in-lower-41",
              "externalID": "ocd-person/38ca634d-bbf0-42f3-bf01-4d6cdcb84bbd",
              "geometry": null
            },
            "contactInfo": {
              "email": "h41@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.indianahouserepublicans.com/members/general/mark-genda/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_mark_genda_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-41-lower-ocd-person-38ca634d-bbf0-42f3-bf01-4d6cdcb84bbd": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/3e2f70f5-2f3a-425f-99ed-c7277eb2f13c",
            "firstName": "Alan P.",
            "lastName": "Morrison",
            "fullName": "Alan Morrison",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Morrison_Main_list.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-IN-42-lower-ocd-person-3e2f70f5-2f3a-425f-99ed-c7277eb2f13c",
              "builtID": "in-lower-42",
              "externalID": "ocd-person/3e2f70f5-2f3a-425f-99ed-c7277eb2f13c",
              "geometry": null
            },
            "contactInfo": {
              "email": "h42@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_alan_morrison_1115",
                "https://www.indianahouserepublicans.com/members/leadership/alan-morrison/?back=members",
                "https://www.indianahouserepublicans.com/members/general/alan-morrison/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_alan_morrison_1115/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_alan_morrison_1115/",
                "https://www.indianahouserepublicans.com/members/general/alan-morrison/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-42-lower-ocd-person-3e2f70f5-2f3a-425f-99ed-c7277eb2f13c": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/0f97a586-0089-4eb8-86c1-40f42bc1f594",
            "firstName": "Tonya",
            "lastName": "Pfaff",
            "fullName": "Tonya Pfaff",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/Pfaff-headshot-118_web_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-IN-43-lower-ocd-person-0f97a586-0089-4eb8-86c1-40f42bc1f594",
              "builtID": "in-lower-43",
              "externalID": "ocd-person/0f97a586-0089-4eb8-86c1-40f42bc1f594",
              "geometry": null
            },
            "contactInfo": {
              "email": "h43@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_tonya_pfaff_1",
                "https://iga.in.gov/legislative/2019/legislators/legislator_tonya_pfaff_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_tonya_pfaff_1/",
                "https://indianahousedemocrats.org/members/tonya-pfaff"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-43-lower-ocd-person-0f97a586-0089-4eb8-86c1-40f42bc1f594": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/a8ed53f7-00fb-428b-a629-7532efaf902d",
            "firstName": "Beau",
            "lastName": "Baird",
            "fullName": "Beau Baird",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Baird_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-IN-44-lower-ocd-person-a8ed53f7-00fb-428b-a629-7532efaf902d",
              "builtID": "in-lower-44",
              "externalID": "ocd-person/a8ed53f7-00fb-428b-a629-7532efaf902d",
              "geometry": null
            },
            "contactInfo": {
              "email": "h44@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_beau_baird_1",
                "https://www.indianahouserepublicans.com/members/general/beau-baird/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_beau_baird_1/",
                "https://www.indianahouserepublicans.com/members/general/beau-baird/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_beau_baird_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-44-lower-ocd-person-a8ed53f7-00fb-428b-a629-7532efaf902d": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/fe83212e-72d8-4d9c-80cb-338c7f318f09",
            "firstName": "Bruce Alan",
            "lastName": "Borders",
            "fullName": "Bruce Borders",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Borders_Main_List_2022.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-IN-45-lower-ocd-person-fe83212e-72d8-4d9c-80cb-338c7f318f09",
              "builtID": "in-lower-45",
              "externalID": "ocd-person/fe83212e-72d8-4d9c-80cb-338c7f318f09",
              "geometry": null
            },
            "contactInfo": {
              "email": "h45@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_bruce_borders_1",
                "https://www.indianahouserepublicans.com/members/leadership/bruce-borders/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_bruce_borders_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_bruce_borders_1/",
                "https://www.indianahouserepublicans.com/members/leadership/bruce-borders/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-45-lower-ocd-person-fe83212e-72d8-4d9c-80cb-338c7f318f09": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/791928fe-00bb-46bf-9b57-36b7a40e0250",
            "firstName": "Robert",
            "lastName": "Heaton",
            "fullName": "Bob Heaton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Heaton_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-IN-46-lower-ocd-person-791928fe-00bb-46bf-9b57-36b7a40e0250",
              "builtID": "in-lower-46",
              "externalID": "ocd-person/791928fe-00bb-46bf-9b57-36b7a40e0250",
              "geometry": null
            },
            "contactInfo": {
              "email": "h46@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_robert_heaton_1030",
                "https://www.indianahouserepublicans.com/members/leadership/bob-heaton/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_robert_heaton_1030/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_robert_heaton_1030/",
                "https://www.indianahouserepublicans.com/members/leadership/bob-heaton/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-46-lower-ocd-person-791928fe-00bb-46bf-9b57-36b7a40e0250": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/cd2f0bde-b248-4ba3-81ad-042acd7d60c8",
            "firstName": "Robert R.",
            "lastName": "Greene",
            "fullName": "Robb Greene",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Greene_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-IN-47-lower-ocd-person-cd2f0bde-b248-4ba3-81ad-042acd7d60c8",
              "builtID": "in-lower-47",
              "externalID": "ocd-person/cd2f0bde-b248-4ba3-81ad-042acd7d60c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "h47@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.indianahouserepublicans.com/members/general/robb-greene/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_robb_greene_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-47-lower-ocd-person-cd2f0bde-b248-4ba3-81ad-042acd7d60c8": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/5b58dd7d-acb3-4554-9e4e-c1281155f4ab",
            "firstName": "Douglas",
            "lastName": "Miller",
            "fullName": "Doug Miller",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Miller_mainlist2.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-IN-48-lower-ocd-person-5b58dd7d-acb3-4554-9e4e-c1281155f4ab",
              "builtID": "in-lower-48",
              "externalID": "ocd-person/5b58dd7d-acb3-4554-9e4e-c1281155f4ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "h48@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_doug_miller_1",
                "https://iga.in.gov/legislative/2019/legislators/legislator_doug_miller_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_doug_miller_1/",
                "https://www.indianahouserepublicans.com/members/general/doug-miller/?back=members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-48-lower-ocd-person-5b58dd7d-acb3-4554-9e4e-c1281155f4ab": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/e2a84cfb-4ec9-4c0c-879b-27e51dbd6674",
            "firstName": "Joanna",
            "lastName": "King",
            "fullName": "Joanna King",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/King_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-IN-49-lower-ocd-person-e2a84cfb-4ec9-4c0c-879b-27e51dbd6674",
              "builtID": "in-lower-49",
              "externalID": "ocd-person/e2a84cfb-4ec9-4c0c-879b-27e51dbd6674",
              "geometry": null
            },
            "contactInfo": {
              "email": "h49@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_joanna_king_1",
                "https://www.indianahouserepublicans.com/members/leadership/joanna-king/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_joanna_king_1/",
                "https://www.indianahouserepublicans.com/members/general/joanna-king/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_joanna_king_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-49-lower-ocd-person-e2a84cfb-4ec9-4c0c-879b-27e51dbd6674": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/3568c1ff-87a5-4121-a468-ced11a824691",
            "firstName": "Lorissa Hays",
            "lastName": "Sweet",
            "fullName": "Lorissa Sweet",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Sweet_mainlist2023.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-IN-50-lower-ocd-person-3568c1ff-87a5-4121-a468-ced11a824691",
              "builtID": "in-lower-50",
              "externalID": "ocd-person/3568c1ff-87a5-4121-a468-ced11a824691",
              "geometry": null
            },
            "contactInfo": {
              "email": "h50@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.indianahouserepublicans.com/members/general/lorissa-sweet/?back=members",
                "https://www.indianahouserepublicans.com/members/general/lorissa-sweet"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-50-lower-ocd-person-3568c1ff-87a5-4121-a468-ced11a824691": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/d748047f-9a19-4e0b-a3e0-446aa337349c",
            "firstName": "Dennis J.",
            "lastName": "Zent",
            "fullName": "Denny Zent",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Zent_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-IN-51-lower-ocd-person-d748047f-9a19-4e0b-a3e0-446aa337349c",
              "builtID": "in-lower-51",
              "externalID": "ocd-person/d748047f-9a19-4e0b-a3e0-446aa337349c",
              "geometry": null
            },
            "contactInfo": {
              "email": "h51@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_dennis_zent_1117",
                "https://www.indianahouserepublicans.com/members/general/denny-zent/?back=members",
                "https://www.indianahouserepublicans.com/members/leadership/denny-zent/?back=members",
                "https://iga.in.gov/legislative/2022/legislators/legislator_dennis_zent_1117/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_dennis_zent_1117/",
                "https://www.indianahouserepublicans.com/members/leadership/denny-zent/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-51-lower-ocd-person-d748047f-9a19-4e0b-a3e0-446aa337349c": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/ea2c35cb-7071-43de-8dc6-e91756d03f11",
            "firstName": "Benjamin",
            "lastName": "Smaltz",
            "fullName": "Ben Smaltz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Smaltz_main_list2023.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-IN-52-lower-ocd-person-ea2c35cb-7071-43de-8dc6-e91756d03f11",
              "builtID": "in-lower-52",
              "externalID": "ocd-person/ea2c35cb-7071-43de-8dc6-e91756d03f11",
              "geometry": null
            },
            "contactInfo": {
              "email": "h52@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_ben_smaltz_1118",
                "https://www.indianahouserepublicans.com/members/general/ben-smaltz/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_ben_smaltz_1118/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_ben_smaltz_1118/",
                "https://www.indianahouserepublicans.com/members/general/ben-smaltz/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-52-lower-ocd-person-ea2c35cb-7071-43de-8dc6-e91756d03f11": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/c0e3527e-b990-4190-8ffe-43258158ac58",
            "firstName": "Robert W.",
            "lastName": "Cherry",
            "fullName": "Bob Cherry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Cherry_Main_list.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-IN-53-lower-ocd-person-c0e3527e-b990-4190-8ffe-43258158ac58",
              "builtID": "in-lower-53",
              "externalID": "ocd-person/c0e3527e-b990-4190-8ffe-43258158ac58",
              "geometry": null
            },
            "contactInfo": {
              "email": "h53@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3118 E. 100 South, Greenfield, IN 46140",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_robert_cherry_395",
                "https://www.indianahouserepublicans.com/members/general/bob-cherry/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_robert_cherry_395/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_robert_cherry_395/",
                "https://www.indianahouserepublicans.com/members/general/bob-cherry/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-53-lower-ocd-person-c0e3527e-b990-4190-8ffe-43258158ac58": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/e4588f99-2084-4b0f-9260-f73a8f6aeb62",
            "firstName": "Cory",
            "lastName": "Criswell",
            "fullName": "Cory Criswell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Rep._Criswell_2023_mainlist.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-IN-54-lower-ocd-person-e4588f99-2084-4b0f-9260-f73a8f6aeb62",
              "builtID": "in-lower-54",
              "externalID": "ocd-person/e4588f99-2084-4b0f-9260-f73a8f6aeb62",
              "geometry": null
            },
            "contactInfo": {
              "email": "h54@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.indianahouserepublicans.com/members/general/cory-criswell/?back=members",
                "https://www.indianahouserepublicans.com/members/general/cory-criswell/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_cory_criswell_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-54-lower-ocd-person-e4588f99-2084-4b0f-9260-f73a8f6aeb62": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/62c64f60-2fb9-4edc-86ee-6c0d7cccff75",
            "firstName": "Lindsay Avery",
            "lastName": "Patterson",
            "fullName": "Lindsay Patterson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Patterson_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-IN-55-lower-ocd-person-62c64f60-2fb9-4edc-86ee-6c0d7cccff75",
              "builtID": "in-lower-55",
              "externalID": "ocd-person/62c64f60-2fb9-4edc-86ee-6c0d7cccff75",
              "geometry": null
            },
            "contactInfo": {
              "email": "h55@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.indianahouserepublicans.com/members/general/lindsay-patterson/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_lindsay_patterson_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-55-lower-ocd-person-62c64f60-2fb9-4edc-86ee-6c0d7cccff75": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/6629fcea-f482-4e63-872d-d2f8734502ca",
            "firstName": "Bradford J.",
            "lastName": "Barrett",
            "fullName": "Brad Barrett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Barrett_mainlist3.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-IN-56-lower-ocd-person-6629fcea-f482-4e63-872d-d2f8734502ca",
              "builtID": "in-lower-56",
              "externalID": "ocd-person/6629fcea-f482-4e63-872d-d2f8734502ca",
              "geometry": null
            },
            "contactInfo": {
              "email": "h56@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_bradford_barrett_1",
                "https://www.indianahouserepublicans.com/members/general/brad-barrett/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_bradford_barrett_1/",
                "https://www.indianahouserepublicans.com/members/general/bradford-barrett/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_bradford_barrett_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-56-lower-ocd-person-6629fcea-f482-4e63-872d-d2f8734502ca": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/006e6bcf-0a2c-4eef-92e3-44714f31aa4e",
            "firstName": "Craig",
            "lastName": "Haggard",
            "fullName": "Craig Haggard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Haggard_mainlist2023.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-IN-57-lower-ocd-person-006e6bcf-0a2c-4eef-92e3-44714f31aa4e",
              "builtID": "in-lower-57",
              "externalID": "ocd-person/006e6bcf-0a2c-4eef-92e3-44714f31aa4e",
              "geometry": null
            },
            "contactInfo": {
              "email": "h57@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.indianahouserepublicans.com/members/general/craig-haggard/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_craig_haggard_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-57-lower-ocd-person-006e6bcf-0a2c-4eef-92e3-44714f31aa4e": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/7580d002-efd7-461a-80ee-06c82ab0db71",
            "firstName": "Michelle",
            "lastName": "Davis",
            "fullName": "Michelle Davis",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Davis_Main_List_2022.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-IN-58-lower-ocd-person-7580d002-efd7-461a-80ee-06c82ab0db71",
              "builtID": "in-lower-58",
              "externalID": "ocd-person/7580d002-efd7-461a-80ee-06c82ab0db71",
              "geometry": null
            },
            "contactInfo": {
              "email": "h58@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_michelle_davis_1",
                "https://iga.in.gov/legislative/2021/legislators/legislator_michelle_davis_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_michelle_davis_1/",
                "https://www.indianahouserepublicans.com/members/general/michelle-davis/?back=members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-58-lower-ocd-person-7580d002-efd7-461a-80ee-06c82ab0db71": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/d96c807a-9e72-4861-bca4-67d787cbf49f",
            "firstName": "Ryan",
            "lastName": "Lauer",
            "fullName": "Ryan Lauer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Lauer_Mainlist_Image.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-IN-59-lower-ocd-person-d96c807a-9e72-4861-bca4-67d787cbf49f",
              "builtID": "in-lower-59",
              "externalID": "ocd-person/d96c807a-9e72-4861-bca4-67d787cbf49f",
              "geometry": null
            },
            "contactInfo": {
              "email": "h59@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_ryan_lauer_1",
                "https://www.indianahouserepublicans.com/members/general/ryan-lauer/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_ryan_lauer_1/",
                "https://www.indianahouserepublicans.com/members/general/ryan-lauer/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_ryan_lauer_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-59-lower-ocd-person-d96c807a-9e72-4861-bca4-67d787cbf49f": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/0acb3367-1e1e-404e-89c8-82e3c6754235",
            "firstName": "Peggy Mazelin",
            "lastName": "Mayfield",
            "fullName": "Peggy Mayfield",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Mayfield_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-IN-60-lower-ocd-person-0acb3367-1e1e-404e-89c8-82e3c6754235",
              "builtID": "in-lower-60",
              "externalID": "ocd-person/0acb3367-1e1e-404e-89c8-82e3c6754235",
              "geometry": null
            },
            "contactInfo": {
              "email": "h60@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_peggy_mayfield_1121",
                "https://iga.in.gov/legislative/2021/legislators/legislator_peggy_mayfield_1121/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_peggy_mayfield_1121/",
                "https://www.indianahouserepublicans.com/members/general/peggy-mayfield/?back=members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-60-lower-ocd-person-0acb3367-1e1e-404e-89c8-82e3c6754235": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/c66bcc9b-d5e6-478b-b11e-25be7f4e0aaa",
            "firstName": "Matthew S.",
            "lastName": "Pierce",
            "fullName": "Matt Pierce",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/pierce2020portrait_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-IN-61-lower-ocd-person-c66bcc9b-d5e6-478b-b11e-25be7f4e0aaa",
              "builtID": "in-lower-61",
              "externalID": "ocd-person/c66bcc9b-d5e6-478b-b11e-25be7f4e0aaa",
              "geometry": null
            },
            "contactInfo": {
              "email": "h61@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_matthew_pierce_708",
                "https://iga.in.gov/legislative/2021/legislators/legislator_matthew_pierce_708/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_matthew_pierce_708/",
                "https://indianahousedemocrats.org/members/matt-pierce"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-61-lower-ocd-person-c66bcc9b-d5e6-478b-b11e-25be7f4e0aaa": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/e1599f41-bd7d-45c4-8a43-c25d8b519f53",
            "firstName": "David Allen",
            "lastName": "Hall",
            "fullName": "Dave Hall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Hall_mainlist_2023.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-IN-62-lower-ocd-person-e1599f41-bd7d-45c4-8a43-c25d8b519f53",
              "builtID": "in-lower-62",
              "externalID": "ocd-person/e1599f41-bd7d-45c4-8a43-c25d8b519f53",
              "geometry": null
            },
            "contactInfo": {
              "email": "h62@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.indianahouserepublicans.com/members/general/dave-hall/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_david_hall_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-62-lower-ocd-person-e1599f41-bd7d-45c4-8a43-c25d8b519f53": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/2a0b391a-a59d-4b7f-907b-87980c2714d8",
            "firstName": "Shane",
            "lastName": "Lindauer",
            "fullName": "Shane Lindauer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Lindauer_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-IN-63-lower-ocd-person-2a0b391a-a59d-4b7f-907b-87980c2714d8",
              "builtID": "in-lower-63",
              "externalID": "ocd-person/2a0b391a-a59d-4b7f-907b-87980c2714d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "h63@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_shane_lindauer_1",
                "https://www.indianahouserepublicans.com/members/leadership/shane-lindauer/?back=members",
                "https://www.indianahouserepublicans.com/members/general/shane-lindauer/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_shane_lindauer_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_shane_lindauer_1/",
                "https://www.indianahouserepublicans.com/members/general/shane-lindauer/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-63-lower-ocd-person-2a0b391a-a59d-4b7f-907b-87980c2714d8": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/be7ad6b3-da1b-4b8d-b41b-6b6e48a9bdfa",
            "firstName": "Matt",
            "lastName": "Hostettler",
            "fullName": "Matt Hostettler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Hostettler_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-IN-64-lower-ocd-person-be7ad6b3-da1b-4b8d-b41b-6b6e48a9bdfa",
              "builtID": "in-lower-64",
              "externalID": "ocd-person/be7ad6b3-da1b-4b8d-b41b-6b6e48a9bdfa",
              "geometry": null
            },
            "contactInfo": {
              "email": "h64@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_matthew_hostettler_1",
                "https://www.indianahouserepublicans.com/members/general/matt-hostettler/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_matthew_hostettler_1/",
                "https://www.indianahouserepublicans.com/members/general/matt-hostettler/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_matthew_hostettler_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-64-lower-ocd-person-be7ad6b3-da1b-4b8d-b41b-6b6e48a9bdfa": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/f1571a79-9c64-42b2-82d2-ec9f512722eb",
            "firstName": "Christopher",
            "lastName": "May",
            "fullName": "Chris May",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/May_mainlist2.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-IN-65-lower-ocd-person-f1571a79-9c64-42b2-82d2-ec9f512722eb",
              "builtID": "in-lower-65",
              "externalID": "ocd-person/f1571a79-9c64-42b2-82d2-ec9f512722eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "h65@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_chris_may_1",
                "https://www.indianahouserepublicans.com/members/general/chris-may/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_chris_may_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_chris_may_1/",
                "https://www.indianahouserepublicans.com/members/general/chris-may/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-65-lower-ocd-person-f1571a79-9c64-42b2-82d2-ec9f512722eb": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/a74de022-873d-4a31-986c-66e81356a6f2",
            "firstName": "Zachary Charles",
            "lastName": "Payne",
            "fullName": "Zach Payne",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Payne_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-IN-66-lower-ocd-person-a74de022-873d-4a31-986c-66e81356a6f2",
              "builtID": "in-lower-66",
              "externalID": "ocd-person/a74de022-873d-4a31-986c-66e81356a6f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "h66@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_zach_payne_1",
                "https://iga.in.gov/legislative/2021/legislators/legislator_zach_payne_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_zach_payne_1/",
                "https://www.indianahouserepublicans.com/members/general/zach-payne/?back=members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-66-lower-ocd-person-a74de022-873d-4a31-986c-66e81356a6f2": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/7daef9db-7675-4d8e-8e78-b943a24fd5b7",
            "firstName": "James Alexander",
            "lastName": "Zimmerman",
            "fullName": "Alex Zimmerman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Zimmerman_Edited_Headshot.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-IN-67-lower-ocd-person-7daef9db-7675-4d8e-8e78-b943a24fd5b7",
              "builtID": "in-lower-67",
              "externalID": "ocd-person/7daef9db-7675-4d8e-8e78-b943a24fd5b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "h67@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.indianahouserepublicans.com/members/general/alex-zimmerman/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_alex_zimmerman_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-67-lower-ocd-person-7daef9db-7675-4d8e-8e78-b943a24fd5b7": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/96c8ce73-904d-45f9-a2e1-cb9c72596e40",
            "firstName": "Randall J.",
            "lastName": "Lyness",
            "fullName": "Randy Lyness",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Lyness_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-IN-68-lower-ocd-person-96c8ce73-904d-45f9-a2e1-cb9c72596e40",
              "builtID": "in-lower-68",
              "externalID": "ocd-person/96c8ce73-904d-45f9-a2e1-cb9c72596e40",
              "geometry": null
            },
            "contactInfo": {
              "email": "h68@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_randy_lyness_1",
                "https://www.indianahouserepublicans.com/members/general/randy-lyness/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_randy_lyness_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_randy_lyness_1/",
                "https://www.indianahouserepublicans.com/members/general/randy-lyness/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-68-lower-ocd-person-96c8ce73-904d-45f9-a2e1-cb9c72596e40": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/70f005b9-1417-4132-9956-ad93942ca87d",
            "firstName": "Jim",
            "lastName": "Lucas",
            "fullName": "Jim Lucas",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Lucas_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-IN-69-lower-ocd-person-70f005b9-1417-4132-9956-ad93942ca87d",
              "builtID": "in-lower-69",
              "externalID": "ocd-person/70f005b9-1417-4132-9956-ad93942ca87d",
              "geometry": null
            },
            "contactInfo": {
              "email": "h69@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_jim_lucas_1123",
                "https://www.indianahouserepublicans.com/members/general/jim-lucas/?back=members",
                "https://www.indianahouserepublicans.com/members/leadership/jim-lucas/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_jim_lucas_1123"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-69-lower-ocd-person-70f005b9-1417-4132-9956-ad93942ca87d": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/efa78779-f35e-49fb-8b6b-5cccd00c870a",
            "firstName": "Karen",
            "lastName": "Engleman",
            "fullName": "Karen Engleman",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Engleman_mainlist.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-IN-70-lower-ocd-person-efa78779-f35e-49fb-8b6b-5cccd00c870a",
              "builtID": "in-lower-70",
              "externalID": "ocd-person/efa78779-f35e-49fb-8b6b-5cccd00c870a",
              "geometry": null
            },
            "contactInfo": {
              "email": "h70@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_karen_engleman_1",
                "https://www.indianahouserepublicans.com/members/leadership/karen-engleman/?back=members",
                "https://www.indianahouserepublicans.com/members/general/karen-engleman/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_karen_engleman_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_karen_engleman_1/",
                "https://www.indianahouserepublicans.com/members/general/karen-engleman/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-70-lower-ocd-person-efa78779-f35e-49fb-8b6b-5cccd00c870a": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/0761bcc1-6fbf-4a37-8b52-46c2bc6912fa",
            "firstName": "Wendy",
            "lastName": "Dant Chesser",
            "fullName": "Wendy Dant Chesser",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assets.indianahousedemocrats.org/banners/Dant_Chesser_Wendy_media.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-IN-71-lower-ocd-person-0761bcc1-6fbf-4a37-8b52-46c2bc6912fa",
              "builtID": "in-lower-71",
              "externalID": "ocd-person/0761bcc1-6fbf-4a37-8b52-46c2bc6912fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "h71@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://indianahousedemocrats.org/members/wendy-dant-chesser",
                "https://iga.in.gov/legislative/2024/legislators/legislator_wendy_dant_chesser_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-71-lower-ocd-person-0761bcc1-6fbf-4a37-8b52-46c2bc6912fa": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/d01b6bbc-9c5e-4479-afd6-3e7410946367",
            "firstName": "Edward D.",
            "lastName": "Clere",
            "fullName": "Ed Clere",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Clere_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-IN-72-lower-ocd-person-d01b6bbc-9c5e-4479-afd6-3e7410946367",
              "builtID": "in-lower-72",
              "externalID": "ocd-person/d01b6bbc-9c5e-4479-afd6-3e7410946367",
              "geometry": null
            },
            "contactInfo": {
              "email": "h72@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_edward_clere_984",
                "https://www.indianahouserepublicans.com/members/general/ed-clere/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_edward_clere_984/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_edward_clere_984/",
                "https://www.indianahouserepublicans.com/members/general/ed-clere/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-72-lower-ocd-person-d01b6bbc-9c5e-4479-afd6-3e7410946367": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/98849cb4-9559-40c1-bd56-414eaef4e22a",
            "firstName": "Jennifer",
            "lastName": "Meltzer",
            "fullName": "Jenny Meltzer",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Meltzer_Main_List_2.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-IN-73-lower-ocd-person-98849cb4-9559-40c1-bd56-414eaef4e22a",
              "builtID": "in-lower-73",
              "externalID": "ocd-person/98849cb4-9559-40c1-bd56-414eaef4e22a",
              "geometry": null
            },
            "contactInfo": {
              "email": "h73@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.indianahouserepublicans.com/members/general/jennifer-meltzer/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_jennifer_meltzer_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-73-lower-ocd-person-98849cb4-9559-40c1-bd56-414eaef4e22a": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/df749cfb-56e8-482f-bf4b-183a1fb52594",
            "firstName": "Stephen R.",
            "lastName": "Bartels",
            "fullName": "Steve Bartels",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Bartels_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-IN-74-lower-ocd-person-df749cfb-56e8-482f-bf4b-183a1fb52594",
              "builtID": "in-lower-74",
              "externalID": "ocd-person/df749cfb-56e8-482f-bf4b-183a1fb52594",
              "geometry": null
            },
            "contactInfo": {
              "email": "h74@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_stephen_bartels_1",
                "https://www.indianahouserepublicans.com/members/leadership/stephen-bartels/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_stephen_bartels_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_stephen_bartels_1/",
                "https://www.indianahouserepublicans.com/members/general/stephen-bartels"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-74-lower-ocd-person-df749cfb-56e8-482f-bf4b-183a1fb52594": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/e3cfa300-8d75-4217-9cc4-371aca7f77dd",
            "firstName": "Cindy",
            "lastName": "Ledbetter",
            "fullName": "Cindy Ledbetter",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Ledbetter_lister2021.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-IN-75-lower-ocd-person-e3cfa300-8d75-4217-9cc4-371aca7f77dd",
              "builtID": "in-lower-75",
              "externalID": "ocd-person/e3cfa300-8d75-4217-9cc4-371aca7f77dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "h75@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_cindy_ledbetter_1",
                "https://www.indianahouserepublicans.com/members/leadership/cindy-ledbetter/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_cindy_ledbetter_1/",
                "https://www.indianahouserepublicans.com/members/general/cindy-ledbetter/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_cindy_ledbetter_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-75-lower-ocd-person-e3cfa300-8d75-4217-9cc4-371aca7f77dd": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/ac2c89af-df39-4057-bdeb-15ca57b7ef9f",
            "firstName": "Wendy M.",
            "lastName": "McNamara",
            "fullName": "Wendy McNamara",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/McNamara_mainlist2.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-IN-76-lower-ocd-person-ac2c89af-df39-4057-bdeb-15ca57b7ef9f",
              "builtID": "in-lower-76",
              "externalID": "ocd-person/ac2c89af-df39-4057-bdeb-15ca57b7ef9f",
              "geometry": null
            },
            "contactInfo": {
              "email": "h76@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_wendy_mcnamara_1046",
                "https://www.indianahouserepublicans.com/members/general/wendy-mcnamara/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_wendy_mcnamara_1046/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_wendy_mcnamara_1046/",
                "https://www.indianahouserepublicans.com/members/leadership/wendy-mcnamara/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-76-lower-ocd-person-ac2c89af-df39-4057-bdeb-15ca57b7ef9f": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/58bbda21-7c3e-4189-9d02-0ba7f95a6471",
            "firstName": "Ryan D.",
            "lastName": "Hatfield",
            "fullName": "Ryan Hatfield",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/hatfield20220823portrait2_800_929_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-IN-77-lower-ocd-person-58bbda21-7c3e-4189-9d02-0ba7f95a6471",
              "builtID": "in-lower-77",
              "externalID": "ocd-person/58bbda21-7c3e-4189-9d02-0ba7f95a6471",
              "geometry": null
            },
            "contactInfo": {
              "email": "h77@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_ryan_hatfield_1",
                "https://iga.in.gov/legislative/2019/legislators/legislator_ryan_hatfield_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_ryan_hatfield_1/",
                "https://indianahousedemocrats.org/members/ryan-hatfield"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-77-lower-ocd-person-58bbda21-7c3e-4189-9d02-0ba7f95a6471": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/87eb34dc-70d6-4614-a871-568a836ff552",
            "firstName": "Timothy A.",
            "lastName": "O'Brien",
            "fullName": "Tim O'Brien",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/OBrien_Mainlist2.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-IN-78-lower-ocd-person-87eb34dc-70d6-4614-a871-568a836ff552",
              "builtID": "in-lower-78",
              "externalID": "ocd-person/87eb34dc-70d6-4614-a871-568a836ff552",
              "geometry": null
            },
            "contactInfo": {
              "email": "h78@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_timothy_obrien_1/",
                "https://www.indianahouserepublicans.com/members/general/tim-o-brien/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_timothy_obrien_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-78-lower-ocd-person-87eb34dc-70d6-4614-a871-568a836ff552": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/423b3123-c1b6-4e2f-92f4-5610130de844",
            "firstName": "Matthew",
            "lastName": "Lehman",
            "fullName": "Matt Lehman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Lehman_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-IN-79-lower-ocd-person-423b3123-c1b6-4e2f-92f4-5610130de844",
              "builtID": "in-lower-79",
              "externalID": "ocd-person/423b3123-c1b6-4e2f-92f4-5610130de844",
              "geometry": null
            },
            "contactInfo": {
              "email": "h79@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": "663 Lehman St., Berne, IN 46711",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_matthew_lehman_987",
                "https://www.indianahouserepublicans.com/members/leadership/matt-lehman/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_matthew_lehman_987/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_matthew_lehman_987/",
                "https://www.indianahouserepublicans.com/members/leadership/matt-lehman/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-79-lower-ocd-person-423b3123-c1b6-4e2f-92f4-5610130de844": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/d1e89034-ef4a-4a10-b16d-16dc617dcdf1",
            "firstName": "Philip",
            "lastName": "GiaQuinta",
            "fullName": "Phil GiaQuinta",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/giaquinta20180102portrait_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-IN-80-lower-ocd-person-d1e89034-ef4a-4a10-b16d-16dc617dcdf1",
              "builtID": "in-lower-80",
              "externalID": "ocd-person/d1e89034-ef4a-4a10-b16d-16dc617dcdf1",
              "geometry": null
            },
            "contactInfo": {
              "email": "h80@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_philip_giaquinta_879",
                "https://indianahousedemocrats.org/members/phil-giaquinta",
                "https://iga.in.gov/legislative/2024/legislators/legislator_philip_giaquinta_879"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-80-lower-ocd-person-d1e89034-ef4a-4a10-b16d-16dc617dcdf1": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/1b9ddafb-fca9-4924-b565-d651c2ca133b",
            "firstName": "Martin",
            "lastName": "Carbaugh",
            "fullName": "Martin Carbaugh",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Carbaugh_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-IN-81-lower-ocd-person-1b9ddafb-fca9-4924-b565-d651c2ca133b",
              "builtID": "in-lower-81",
              "externalID": "ocd-person/1b9ddafb-fca9-4924-b565-d651c2ca133b",
              "geometry": null
            },
            "contactInfo": {
              "email": "h81@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_martin_carbaugh_1125",
                "https://www.indianahouserepublicans.com/members/general/martin-carbaugh/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_martin_carbaugh_1125/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_martin_carbaugh_1125/",
                "https://www.indianahouserepublicans.com/members/general/martin-carbaugh/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-81-lower-ocd-person-1b9ddafb-fca9-4924-b565-d651c2ca133b": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/a67ee82c-450c-49ed-8862-f58e34a3dfbb",
            "firstName": "Kyle Wilson",
            "lastName": "Miller",
            "fullName": "Kyle Miller",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/banners/Miller_Kyle-web_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-IN-82-lower-ocd-person-a67ee82c-450c-49ed-8862-f58e34a3dfbb",
              "builtID": "in-lower-82",
              "externalID": "ocd-person/a67ee82c-450c-49ed-8862-f58e34a3dfbb",
              "geometry": null
            },
            "contactInfo": {
              "email": "h82@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://indianahousedemocrats.org/members/kyle-miller",
                "https://iga.in.gov/legislative/2024/legislators/legislator_kyle_miller_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-82-lower-ocd-person-a67ee82c-450c-49ed-8862-f58e34a3dfbb": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/35824e09-976c-44b7-9825-0787030f0e4e",
            "firstName": "Christopher",
            "lastName": "Judy",
            "fullName": "Chris Judy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Judy_Main_List_2022.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-IN-83-lower-ocd-person-35824e09-976c-44b7-9825-0787030f0e4e",
              "builtID": "in-lower-83",
              "externalID": "ocd-person/35824e09-976c-44b7-9825-0787030f0e4e",
              "geometry": null
            },
            "contactInfo": {
              "email": "h83@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_christopher_judy_1",
                "https://www.indianahouserepublicans.com/members/general/christopher-judy/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_christopher_judy_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_christopher_judy_1/",
                "https://www.indianahouserepublicans.com/members/general/christopher-judy/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-83-lower-ocd-person-35824e09-976c-44b7-9825-0787030f0e4e": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/20851625-bc7c-41ed-841e-27af062bc03e",
            "firstName": "Robert",
            "lastName": "Morris",
            "fullName": "Bob Morris",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Morris_Main_list.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-IN-84-lower-ocd-person-20851625-bc7c-41ed-841e-27af062bc03e",
              "builtID": "in-lower-84",
              "externalID": "ocd-person/20851625-bc7c-41ed-841e-27af062bc03e",
              "geometry": null
            },
            "contactInfo": {
              "email": "h84@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_robert_morris_1021",
                "https://www.indianahouserepublicans.com/members/general/bob-morris/?back=members",
                "https://iga.in.gov/legislative/2021/legislators/legislator_robert_morris_1021/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_robert_morris_1021/",
                "https://www.indianahouserepublicans.com/members/general/bob-morris/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-84-lower-ocd-person-20851625-bc7c-41ed-841e-27af062bc03e": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/ed771fd3-4bd9-4c99-bb42-4bd9f5d1ff8d",
            "firstName": "Dave",
            "lastName": "Heine",
            "fullName": "Dave Heine",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Heine_mainlist4.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-IN-85-lower-ocd-person-ed771fd3-4bd9-4c99-bb42-4bd9f5d1ff8d",
              "builtID": "in-lower-85",
              "externalID": "ocd-person/ed771fd3-4bd9-4c99-bb42-4bd9f5d1ff8d",
              "geometry": null
            },
            "contactInfo": {
              "email": "h85@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_dave_heine_1",
                "https://www.indianahouserepublicans.com/members/general/dave-heine/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_dave_heine_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_dave_heine_1/",
                "https://www.indianahouserepublicans.com/members/general/dave-heine/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-85-lower-ocd-person-ed771fd3-4bd9-4c99-bb42-4bd9f5d1ff8d": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/45d8ea46-0d3f-4a80-bf4e-ca016bd458a8",
            "firstName": "Edward",
            "lastName": "DeLaney",
            "fullName": "Ed DeLaney",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/Delaney_0318_2200fix_cropped_800_1039_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-IN-86-lower-ocd-person-45d8ea46-0d3f-4a80-bf4e-ca016bd458a8",
              "builtID": "in-lower-86",
              "externalID": "ocd-person/45d8ea46-0d3f-4a80-bf4e-ca016bd458a8",
              "geometry": null
            },
            "contactInfo": {
              "email": "h86@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_edward_delaney_988",
                "https://iga.in.gov/legislative/2021/legislators/legislator_edward_delaney_988/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_edward_delaney_988/",
                "https://indianahousedemocrats.org/members/ed-delaney"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-86-lower-ocd-person-45d8ea46-0d3f-4a80-bf4e-ca016bd458a8": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/ec6a3cce-3eca-4020-8809-a7a1875e1663",
            "firstName": "Carey Jeffries",
            "lastName": "Hamilton",
            "fullName": "Carey Hamilton",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/Hamilton_Carey-21_web_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-IN-87-lower-ocd-person-ec6a3cce-3eca-4020-8809-a7a1875e1663",
              "builtID": "in-lower-87",
              "externalID": "ocd-person/ec6a3cce-3eca-4020-8809-a7a1875e1663",
              "geometry": null
            },
            "contactInfo": {
              "email": "h87@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_carey_hamilton_1",
                "https://iga.in.gov/legislative/2019/legislators/legislator_carey_hamilton_1/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_carey_hamilton_1/",
                "https://indianahousedemocrats.org/members/carey-hamilton"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-87-lower-ocd-person-ec6a3cce-3eca-4020-8809-a7a1875e1663": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/8f56f62b-e700-4fdc-b35a-bba408d3e6cf",
            "firstName": "Christopher P.",
            "lastName": "Jeter",
            "fullName": "Chris Jeter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Jeter_Main_List.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-IN-88-lower-ocd-person-8f56f62b-e700-4fdc-b35a-bba408d3e6cf",
              "builtID": "in-lower-88",
              "externalID": "ocd-person/8f56f62b-e700-4fdc-b35a-bba408d3e6cf",
              "geometry": null
            },
            "contactInfo": {
              "email": "h88@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_chris_jeter_1",
                "https://iga.in.gov/legislative/2020/legislators/legislator_chris_jeter_1/",
                "https://www.indianahouserepublicans.com/members/general/chris-jeter/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_chris_jeter_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-88-lower-ocd-person-8f56f62b-e700-4fdc-b35a-bba408d3e6cf": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/30a1de33-efbb-41e1-bf59-bf8171089a3a",
            "firstName": "Mitch",
            "lastName": "Gore",
            "fullName": "Mitch Gore",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/Gore_Mitch_web_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-IN-89-lower-ocd-person-30a1de33-efbb-41e1-bf59-bf8171089a3a",
              "builtID": "in-lower-89",
              "externalID": "ocd-person/30a1de33-efbb-41e1-bf59-bf8171089a3a",
              "geometry": null
            },
            "contactInfo": {
              "email": "h89@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_mitch_gore_1",
                "https://iga.in.gov/legislative/2021/legislators/legislator_mitch_gore_1/",
                "https://indianahousedemocrats.org/members/mitch-gore",
                "https://iga.in.gov/legislative/2024/legislators/legislator_mitch_gore_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-89-lower-ocd-person-30a1de33-efbb-41e1-bf59-bf8171089a3a": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/ed0facaa-4220-4f7d-899f-2bb0d576e9ef",
            "firstName": "Mike",
            "lastName": "Speedy",
            "fullName": "Mike Speedy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Speedy_lister2021.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-IN-90-lower-ocd-person-ed0facaa-4220-4f7d-899f-2bb0d576e9ef",
              "builtID": "in-lower-90",
              "externalID": "ocd-person/ed0facaa-4220-4f7d-899f-2bb0d576e9ef",
              "geometry": null
            },
            "contactInfo": {
              "email": "h90@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_michael_speedy_1038",
                "https://www.indianahouserepublicans.com/members/general/mike-speedy/?back=members",
                "https://iga.in.gov/legislative/2019/legislators/legislator_michael_speedy_1038/",
                "https://www.indianahouserepublicans.com/members/general/mike-speedy/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_michael_speedy_1038/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-90-lower-ocd-person-ed0facaa-4220-4f7d-899f-2bb0d576e9ef": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/3d215bfb-c08a-47f0-91d3-eb114b33c56d",
            "firstName": "Robert William",
            "lastName": "Behning",
            "fullName": "Bob Behning",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/Behning_lister2021.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-IN-91-lower-ocd-person-3d215bfb-c08a-47f0-91d3-eb114b33c56d",
              "builtID": "in-lower-91",
              "externalID": "ocd-person/3d215bfb-c08a-47f0-91d3-eb114b33c56d",
              "geometry": null
            },
            "contactInfo": {
              "email": "h91@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_robert_behning_187",
                "https://iga.in.gov/legislative/2019/legislators/legislator_robert_behning_187/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_robert_behning_187/",
                "https://www.indianahouserepublicans.com/members/general/bob-behning/?back=members"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-91-lower-ocd-person-3d215bfb-c08a-47f0-91d3-eb114b33c56d": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/8b2a92f1-4b70-4dfc-8f4f-789aca1b5e8b",
            "firstName": "Renee",
            "lastName": "Pack",
            "fullName": "Reneé Pack",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/Pack_Renee_web_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-IN-92-lower-ocd-person-8b2a92f1-4b70-4dfc-8f4f-789aca1b5e8b",
              "builtID": "in-lower-92",
              "externalID": "ocd-person/8b2a92f1-4b70-4dfc-8f4f-789aca1b5e8b",
              "geometry": null
            },
            "contactInfo": {
              "email": "h92@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_renee_pack_1",
                "https://iga.in.gov/legislative/2021/legislators/legislator_renee_pack_1/",
                "https://indianahousedemocrats.org/members/renee-pack",
                "https://iga.in.gov/legislative/2024/legislators/legislator_renee_pack_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-92-lower-ocd-person-8b2a92f1-4b70-4dfc-8f4f-789aca1b5e8b": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/d88b76c4-5b6b-4f93-9347-6ae335208679",
            "firstName": "Julie Schnell",
            "lastName": "McGuire",
            "fullName": "Julie McGuire",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.indianahouserepublicans.com/clientuploads/directory/members/Main_List/McGuire_Main_List_2.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-IN-93-lower-ocd-person-d88b76c4-5b6b-4f93-9347-6ae335208679",
              "builtID": "in-lower-93",
              "externalID": "ocd-person/d88b76c4-5b6b-4f93-9347-6ae335208679",
              "geometry": null
            },
            "contactInfo": {
              "email": "h93@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.indianahouserepublicans.com/members/general/julie-mcguire/?back=members",
                "https://iga.in.gov/legislative/2024/legislators/legislator_julie_mcguire_1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-93-lower-ocd-person-d88b76c4-5b6b-4f93-9347-6ae335208679": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/8db4d04c-3afe-44c1-940b-7761cd035800",
            "firstName": "Cherrish",
            "lastName": "Pryor",
            "fullName": "Cherrish Pryor",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/pryor20220105portrait_800_1000_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-IN-94-lower-ocd-person-8db4d04c-3afe-44c1-940b-7761cd035800",
              "builtID": "in-lower-94",
              "externalID": "ocd-person/8db4d04c-3afe-44c1-940b-7761cd035800",
              "geometry": null
            },
            "contactInfo": {
              "email": "h94@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_cherrish_pryor_990",
                "https://iga.in.gov/legislative/2021/legislators/legislator_cherrish_pryor_990/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_cherrish_pryor_990/",
                "https://indianahousedemocrats.org/members/cherrish-pryor"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-94-lower-ocd-person-8db4d04c-3afe-44c1-940b-7761cd035800": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/5ceabc0e-ec47-41b5-b014-5eddcdc154f3",
            "firstName": "John L.",
            "lastName": "Bartlett",
            "fullName": "John Bartlett",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/Bartlett_John_web_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-IN-95-lower-ocd-person-5ceabc0e-ec47-41b5-b014-5eddcdc154f3",
              "builtID": "in-lower-95",
              "externalID": "ocd-person/5ceabc0e-ec47-41b5-b014-5eddcdc154f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "h95@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_john_bartlett_942",
                "https://iga.in.gov/legislative/2021/legislators/legislator_john_bartlett_942/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_john_bartlett_942/",
                "https://indianahousedemocrats.org/members/john-l-bartlett"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-95-lower-ocd-person-5ceabc0e-ec47-41b5-b014-5eddcdc154f3": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/6bc40502-e00f-4b16-8c1d-9f5a613a4e88",
            "firstName": "Gregory W.",
            "lastName": "Porter",
            "fullName": "Gregory Porter",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/porter20180102portrait_800_1003_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-IN-96-lower-ocd-person-6bc40502-e00f-4b16-8c1d-9f5a613a4e88",
              "builtID": "in-lower-96",
              "externalID": "ocd-person/6bc40502-e00f-4b16-8c1d-9f5a613a4e88",
              "geometry": null
            },
            "contactInfo": {
              "email": "h96@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_gregory_porter_115",
                "https://iga.in.gov/legislative/2021/legislators/legislator_gregory_porter_115/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_gregory_porter_115/",
                "https://indianahousedemocrats.org/members/gregory-w-porter"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-96-lower-ocd-person-6bc40502-e00f-4b16-8c1d-9f5a613a4e88": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/0f97edaa-0f48-4c61-82dd-c70aeb05a5ff",
            "firstName": "Justin",
            "lastName": "Moed",
            "fullName": "Justin Moed",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/Moed_resized_800_1000.png",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-IN-97-lower-ocd-person-0f97edaa-0f48-4c61-82dd-c70aeb05a5ff",
              "builtID": "in-lower-97",
              "externalID": "ocd-person/0f97edaa-0f48-4c61-82dd-c70aeb05a5ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "h97@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_justin_moed_908",
                "https://iga.in.gov/legislative/2021/legislators/legislator_justin_moed_908/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_justin_moed_908/",
                "https://indianahousedemocrats.org/members/justin-moed"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-97-lower-ocd-person-0f97edaa-0f48-4c61-82dd-c70aeb05a5ff": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/d15f8f7e-148b-47fc-858d-84ad23347f64",
            "firstName": "Robin",
            "lastName": "Shackleford",
            "fullName": "Robin Shackleford",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/shackleford20200407portrait_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-IN-98-lower-ocd-person-d15f8f7e-148b-47fc-858d-84ad23347f64",
              "builtID": "in-lower-98",
              "externalID": "ocd-person/d15f8f7e-148b-47fc-858d-84ad23347f64",
              "geometry": null
            },
            "contactInfo": {
              "email": "h98@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_robin_shackleford_1128",
                "https://iga.in.gov/legislative/2021/legislators/legislator_robin_shackleford_1128/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_robin_shackleford_1128/",
                "https://indianahousedemocrats.org/members/robin-shackleford"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-98-lower-ocd-person-d15f8f7e-148b-47fc-858d-84ad23347f64": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/51d00dd2-d18c-4d77-9ad1-710016d30448",
            "firstName": "Vanessa Janice",
            "lastName": "Summers",
            "fullName": "Vanessa Summers",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/banners/summers_updated_headshot_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-IN-99-lower-ocd-person-51d00dd2-d18c-4d77-9ad1-710016d30448",
              "builtID": "in-lower-99",
              "externalID": "ocd-person/51d00dd2-d18c-4d77-9ad1-710016d30448",
              "geometry": null
            },
            "contactInfo": {
              "email": "h99@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_vanessa_summers_231",
                "https://iga.in.gov/legislative/2021/legislators/legislator_vanessa_summers_231/",
                "https://iga.in.gov/legislative/2024/legislators/legislator_vanessa_summers_231/",
                "https://indianahousedemocrats.org/members/vanessa-summers"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-99-lower-ocd-person-51d00dd2-d18c-4d77-9ad1-710016d30448": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/5fd5f8d8-b47c-4b69-ba1a-d89f0fb106a7",
            "firstName": "Robert Blake",
            "lastName": "Johnson",
            "fullName": "Blake Johnson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianahousedemocrats.org/images/made/images/remote/https_assets.indianahousedemocrats.org/members/Headshots/johnson_2020_blake_web_800_999_80.jpg",
            "title": "IN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-IN-100-lower-ocd-person-5fd5f8d8-b47c-4b69-ba1a-d89f0fb106a7",
              "builtID": "in-lower-100",
              "externalID": "ocd-person/5fd5f8d8-b47c-4b69-ba1a-d89f0fb106a7",
              "geometry": null
            },
            "contactInfo": {
              "email": "h100@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_blake_johnson_1",
                "https://iga.in.gov/legislative/2020/legislators/legislator_blake_johnson_1/",
                "https://indianahousedemocrats.org/members/blake-johnson",
                "https://iga.in.gov/legislative/2024/legislators/legislator_blake_johnson_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IN-100-lower-ocd-person-5fd5f8d8-b47c-4b69-ba1a-d89f0fb106a7": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/bb961150-43c0-4535-bc67-a63127c90047",
            "firstName": "Daniel Edward",
            "lastName": "Dernulc",
            "fullName": "Dan Dernulc",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/563c4788-f7fa-4983-83c8-36c8dbf3d3d4/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/dernulc-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-IN-1-upper-ocd-person-bb961150-43c0-4535-bc67-a63127c90047",
              "builtID": "in-upper-1",
              "externalID": "ocd-person/bb961150-43c0-4535-bc67-a63127c90047",
              "geometry": null
            },
            "contactInfo": {
              "email": "s1@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.indianasenaterepublicans.com/dernulc"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-1-upper-ocd-person-bb961150-43c0-4535-bc67-a63127c90047": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/a48f9631-3436-4445-b8fc-87c143f373cd",
            "firstName": "Lonnie Marcus",
            "lastName": "Randolph",
            "fullName": "Lonnie Randolph",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianasenatedemocrats.org/wp-content/uploads/2022/12/Senator-Randolph-Indiana-State-Flag-Headshot.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-IN-2-upper-ocd-person-a48f9631-3436-4445-b8fc-87c143f373cd",
              "builtID": "in-upper-2",
              "externalID": "ocd-person/a48f9631-3436-4445-b8fc-87c143f373cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "s2@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "senatorrandolph",
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorRandolph",
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_lonnie_randolph_160",
                "https://www.indianasenatedemocrats.org/senators/s2/",
                "https://indianasenatedemocrats.orghttps://www.indianasenatedemocrats.org/s2",
                "https://indianasenatedemocrats.org/senators/s2/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-2-upper-ocd-person-a48f9631-3436-4445-b8fc-87c143f373cd": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/3ae897c9-d673-4be8-859d-e2a56a213b79",
            "firstName": "David",
            "lastName": "Vinzant",
            "fullName": "Dave Vinzant",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-IN-3-upper-ocd-person-3ae897c9-d673-4be8-859d-e2a56a213b79",
              "builtID": "in-upper-3",
              "externalID": "ocd-person/3ae897c9-d673-4be8-859d-e2a56a213b79",
              "geometry": null
            },
            "contactInfo": {
              "email": "s3@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://indianasenatedemocrats.org/s3/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-3-upper-ocd-person-3ae897c9-d673-4be8-859d-e2a56a213b79": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/4058ba4c-30a9-46a7-80a1-1f3de1e44a2f",
            "firstName": "Rodney",
            "lastName": "Pol",
            "fullName": "Rodney Pol",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianasenatedemocrats.org/wp-content/uploads/2021/11/Senator-Pol_Hoosierjpg.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-IN-4-upper-ocd-person-4058ba4c-30a9-46a7-80a1-1f3de1e44a2f",
              "builtID": "in-upper-4",
              "externalID": "ocd-person/4058ba4c-30a9-46a7-80a1-1f3de1e44a2f",
              "geometry": null
            },
            "contactInfo": {
              "email": "s4@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SenRodneyPol",
              "youtube": null,
              "instagram": null,
              "facebook": "StateSenatorRodneyPolJr",
              "urls": [
                "https://www.indianasenatedemocrats.org/senators/s4/",
                "https://indianasenatedemocrats.orghttps://www.indianasenatedemocrats.org/s4",
                "https://indianasenatedemocrats.org/senators/s4/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-4-upper-ocd-person-4058ba4c-30a9-46a7-80a1-1f3de1e44a2f": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/ce2c81c3-e2d6-4394-b960-8787d896bfe5",
            "firstName": "Ed",
            "lastName": "Charbonneau",
            "fullName": "Ed Charbonneau",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/64a0ce17-df30-4b22-8f54-69c3062a3461/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/charbonneau-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-IN-5-upper-ocd-person-ce2c81c3-e2d6-4394-b960-8787d896bfe5",
              "builtID": "in-upper-5",
              "externalID": "ocd-person/ce2c81c3-e2d6-4394-b960-8787d896bfe5",
              "geometry": null
            },
            "contactInfo": {
              "email": "s5@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_ed_charbonneau_919",
                "https://iga.in.gov/legislative/2022/legislators/legislator_ed_charbonneau_919/",
                "https://www.indianasenaterepublicans.com/charbonneau"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-5-upper-ocd-person-ce2c81c3-e2d6-4394-b960-8787d896bfe5": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/0b243d61-c607-4711-bc54-aecc21281540",
            "firstName": "Rick",
            "lastName": "Niemeyer",
            "fullName": "Rick Niemeyer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/c19965ba-e156-4348-bf41-366a289532e4/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/niemeyer-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-IN-6-upper-ocd-person-0b243d61-c607-4711-bc54-aecc21281540",
              "builtID": "in-upper-6",
              "externalID": "ocd-person/0b243d61-c607-4711-bc54-aecc21281540",
              "geometry": null
            },
            "contactInfo": {
              "email": "s6@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_rick_niemeyer_1109",
                "https://iga.in.gov/legislative/2022/legislators/legislator_rick_niemeyer_1109/",
                "https://www.indianasenaterepublicans.com/niemeyer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-6-upper-ocd-person-0b243d61-c607-4711-bc54-aecc21281540": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/0482a330-c9fb-4f41-b136-88a4aef2de6a",
            "firstName": "Brian",
            "lastName": "Buchanan",
            "fullName": "Brian Buchanan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/8c4e2d6b-46c5-472e-b027-82db8d3fa31d/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/buchanan-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-IN-7-upper-ocd-person-0482a330-c9fb-4f41-b136-88a4aef2de6a",
              "builtID": "in-upper-7",
              "externalID": "ocd-person/0482a330-c9fb-4f41-b136-88a4aef2de6a",
              "geometry": null
            },
            "contactInfo": {
              "email": "s7@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_brian_buchanan_1",
                "https://www.indianasenaterepublicans.com/buchanan",
                "https://iga.in.gov/legislative/2022/legislators/legislator_brian_buchanan_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-7-upper-ocd-person-0482a330-c9fb-4f41-b136-88a4aef2de6a": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/9569c3da-b86b-4b33-a9ad-8d6174323569",
            "firstName": "Michael",
            "lastName": "Bohacek",
            "fullName": "Mike Bohacek",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/1282c789-f53b-485a-b949-3148759dbbde/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/2021-bohacek-2.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-IN-8-upper-ocd-person-9569c3da-b86b-4b33-a9ad-8d6174323569",
              "builtID": "in-upper-8",
              "externalID": "ocd-person/9569c3da-b86b-4b33-a9ad-8d6174323569",
              "geometry": null
            },
            "contactInfo": {
              "email": "s8@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_mike_bohacek_1",
                "https://www.indianasenaterepublicans.com/bohacek",
                "https://iga.in.gov/legislative/2022/legislators/legislator_mike_bohacek_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-8-upper-ocd-person-9569c3da-b86b-4b33-a9ad-8d6174323569": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/010e5ebe-683c-4fe2-9696-46f7f0b4bea6",
            "firstName": "Ryan",
            "lastName": "Mishler",
            "fullName": "Ryan Mishler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/8b844e0e-016b-4425-bb3b-2134052d1240/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/mishler-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-IN-9-upper-ocd-person-010e5ebe-683c-4fe2-9696-46f7f0b4bea6",
              "builtID": "in-upper-9",
              "externalID": "ocd-person/010e5ebe-683c-4fe2-9696-46f7f0b4bea6",
              "geometry": null
            },
            "contactInfo": {
              "email": "s9@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_ryan_mishler_801",
                "https://iga.in.gov/legislative/2022/legislators/legislator_ryan_mishler_801/",
                "https://www.indianasenaterepublicans.com/mishler"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-9-upper-ocd-person-010e5ebe-683c-4fe2-9696-46f7f0b4bea6": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/1fad6d9f-7d2c-4b2e-a74f-26a2ea44f371",
            "firstName": "David L.",
            "lastName": "Niezgodski",
            "fullName": "David Niezgodski",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianasenatedemocrats.org/wp-content/uploads/2022/12/Senator-niezgodski-Indiana-State-Flag-Headshot.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-IN-10-upper-ocd-person-1fad6d9f-7d2c-4b2e-a74f-26a2ea44f371",
              "builtID": "in-upper-10",
              "externalID": "ocd-person/1fad6d9f-7d2c-4b2e-a74f-26a2ea44f371",
              "geometry": null
            },
            "contactInfo": {
              "email": "s10@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_david_niezgodski_1",
                "https://www.indianasenatedemocrats.org/senators/s10/",
                "https://indianasenatedemocrats.orghttps://www.indianasenatedemocrats.org/s10",
                "https://iga.in.gov/legislative/2021/legislators/legislator_david_niezgodski_1/",
                "https://indianasenatedemocrats.org/senators/s10/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-10-upper-ocd-person-1fad6d9f-7d2c-4b2e-a74f-26a2ea44f371": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/b5f001ff-6e60-49b9-b074-bb83139410b4",
            "firstName": "Linda",
            "lastName": "Rogers",
            "fullName": "Linda Rogers",
            "gender": "Female",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/754a99ca-bab6-488b-88e1-a9e1a9d29d7c/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/rogers-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-IN-11-upper-ocd-person-b5f001ff-6e60-49b9-b074-bb83139410b4",
              "builtID": "in-upper-11",
              "externalID": "ocd-person/b5f001ff-6e60-49b9-b074-bb83139410b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "s11@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_linda_rogers_1",
                "https://iga.in.gov/legislative/2022/legislators/legislator_linda_rogers_1/",
                "https://www.indianasenaterepublicans.com/rogers"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-11-upper-ocd-person-b5f001ff-6e60-49b9-b074-bb83139410b4": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/a1002ff1-ffd8-4d82-8db9-3a8295ca5e06",
            "firstName": "Blake",
            "lastName": "Doriot",
            "fullName": "Blake Doriot",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/6e687e05-6d5e-450b-a8b4-f08c648001c2/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/doriot-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-IN-12-upper-ocd-person-a1002ff1-ffd8-4d82-8db9-3a8295ca5e06",
              "builtID": "in-upper-12",
              "externalID": "ocd-person/a1002ff1-ffd8-4d82-8db9-3a8295ca5e06",
              "geometry": null
            },
            "contactInfo": {
              "email": "s12@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_blake_doriot_1",
                "https://www.indianasenaterepublicans.com/doriot",
                "https://iga.in.gov/legislative/2022/legislators/legislator_blake_doriot_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-12-upper-ocd-person-a1002ff1-ffd8-4d82-8db9-3a8295ca5e06": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/a0b2ce58-2c2d-4b71-a9e2-7b8ae153d53f",
            "firstName": "Susan C.",
            "lastName": "Glick",
            "fullName": "Sue Glick",
            "gender": "Female",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/9104a840-3113-47f6-b8fc-112521fe5577/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/glick-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-IN-13-upper-ocd-person-a0b2ce58-2c2d-4b71-a9e2-7b8ae153d53f",
              "builtID": "in-upper-13",
              "externalID": "ocd-person/a0b2ce58-2c2d-4b71-a9e2-7b8ae153d53f",
              "geometry": null
            },
            "contactInfo": {
              "email": "s13@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_susan_glick_1048",
                "https://iga.in.gov/legislative/2022/legislators/legislator_susan_glick_1048/",
                "https://www.indianasenaterepublicans.com/glick"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-13-upper-ocd-person-a0b2ce58-2c2d-4b71-a9e2-7b8ae153d53f": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/5e19b0fa-7bdc-4732-826c-adf5fbad4e21",
            "firstName": "Tyler",
            "lastName": "Johnson",
            "fullName": "Tyler Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/91785bff-d689-4351-a1fa-2345256bf313/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/johnson-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-IN-14-upper-ocd-person-5e19b0fa-7bdc-4732-826c-adf5fbad4e21",
              "builtID": "in-upper-14",
              "externalID": "ocd-person/5e19b0fa-7bdc-4732-826c-adf5fbad4e21",
              "geometry": null
            },
            "contactInfo": {
              "email": "s14@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.indianasenaterepublicans.com/johnson"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-14-upper-ocd-person-5e19b0fa-7bdc-4732-826c-adf5fbad4e21": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/d9013459-1dea-46ee-bdd5-5b2874b76e0a",
            "firstName": "Elizabeth",
            "lastName": "Brown",
            "fullName": "Liz Brown",
            "gender": "Female",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/e4616efb-2824-423e-ba07-30880be759a9/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/2021-brown-2.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-IN-15-upper-ocd-person-d9013459-1dea-46ee-bdd5-5b2874b76e0a",
              "builtID": "in-upper-15",
              "externalID": "ocd-person/d9013459-1dea-46ee-bdd5-5b2874b76e0a",
              "geometry": null
            },
            "contactInfo": {
              "email": "s15@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_liz_brown_1",
                "https://iga.in.gov/legislative/2023/legislators/legislator_liz_brown_1/",
                "https://www.indianasenaterepublicans.com/brown"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-15-upper-ocd-person-d9013459-1dea-46ee-bdd5-5b2874b76e0a": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/fc270bf0-2220-476a-ad9c-15db82d62192",
            "firstName": "Justin T.",
            "lastName": "Busch",
            "fullName": "Justin Busch",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/9ac33a70-83a0-408c-91d2-3489a04d866d/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/busch-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-IN-16-upper-ocd-person-fc270bf0-2220-476a-ad9c-15db82d62192",
              "builtID": "in-upper-16",
              "externalID": "ocd-person/fc270bf0-2220-476a-ad9c-15db82d62192",
              "geometry": null
            },
            "contactInfo": {
              "email": "s16@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_justin_busch_1",
                "https://iga.in.gov/legislative/2022/legislators/legislator_justin_busch_1/",
                "https://www.indianasenaterepublicans.com/busch"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-16-upper-ocd-person-fc270bf0-2220-476a-ad9c-15db82d62192": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/85c3a6e4-8292-4e3f-a8f4-475aede6b9d6",
            "firstName": "Andrew R.",
            "lastName": "Zay",
            "fullName": "Andy Zay",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/8b7ce1da-cb9b-4548-a41c-0877c65a7bcf/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/2021-zay-2.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-IN-17-upper-ocd-person-85c3a6e4-8292-4e3f-a8f4-475aede6b9d6",
              "builtID": "in-upper-17",
              "externalID": "ocd-person/85c3a6e4-8292-4e3f-a8f4-475aede6b9d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "s17@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_andy_zay_1",
                "https://www.indianasenaterepublicans.com/zay",
                "https://iga.in.gov/legislative/2022/legislators/legislator_andy_zay_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-17-upper-ocd-person-85c3a6e4-8292-4e3f-a8f4-475aede6b9d6": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/b2c735b9-54fb-4e12-9fef-36ffda822b43",
            "firstName": "Stacey Ann",
            "lastName": "Donato",
            "fullName": "Stacey Donato",
            "gender": "Female",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/48630e3d-f299-4f66-a5d9-052719d70bb5/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/donato.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-IN-18-upper-ocd-person-b2c735b9-54fb-4e12-9fef-36ffda822b43",
              "builtID": "in-upper-18",
              "externalID": "ocd-person/b2c735b9-54fb-4e12-9fef-36ffda822b43",
              "geometry": null
            },
            "contactInfo": {
              "email": "s18@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_stacey_donato_1",
                "https://www.indianasenaterepublicans.com/donato",
                "https://iga.in.gov/legislative/2022/legislators/legislator_stacey_donato_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-18-upper-ocd-person-b2c735b9-54fb-4e12-9fef-36ffda822b43": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/aa948d0d-f7d1-4a11-a58b-986b0d61ebf7",
            "firstName": "Travis",
            "lastName": "Holdman",
            "fullName": "Travis Holdman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/83c9f297-e595-4d74-a5d1-05607adcc991/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/holdman.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-IN-19-upper-ocd-person-aa948d0d-f7d1-4a11-a58b-986b0d61ebf7",
              "builtID": "in-upper-19",
              "externalID": "ocd-person/aa948d0d-f7d1-4a11-a58b-986b0d61ebf7",
              "geometry": null
            },
            "contactInfo": {
              "email": "s19@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_travis_holdman_955",
                "https://iga.in.gov/legislative/2022/legislators/legislator_travis_holdman_955/",
                "https://www.indianasenaterepublicans.com/holdman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-19-upper-ocd-person-aa948d0d-f7d1-4a11-a58b-986b0d61ebf7": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/7aea558c-2623-446e-9870-9ee2b8fe5e47",
            "firstName": "Scott A.",
            "lastName": "Baldwin",
            "fullName": "Scott Baldwin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/2a259e46-1091-4af6-8894-cd56343d3aa1/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/baldwin-2.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-IN-20-upper-ocd-person-7aea558c-2623-446e-9870-9ee2b8fe5e47",
              "builtID": "in-upper-20",
              "externalID": "ocd-person/7aea558c-2623-446e-9870-9ee2b8fe5e47",
              "geometry": null
            },
            "contactInfo": {
              "email": "s20@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_scott_baldwin_1",
                "https://www.indianasenaterepublicans.com/baldwin",
                "https://iga.in.gov/legislative/2022/legislators/legislator_scott_baldwin_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-20-upper-ocd-person-7aea558c-2623-446e-9870-9ee2b8fe5e47": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/8c8f7869-329e-4063-ab55-30e4eca00529",
            "firstName": "James R.",
            "lastName": "Buck",
            "fullName": "Jim Buck",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/5d45f119-5f23-4095-bfd8-cd7a96cab132/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/buck-flag-2011-cmyk.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-IN-21-upper-ocd-person-8c8f7869-329e-4063-ab55-30e4eca00529",
              "builtID": "in-upper-21",
              "externalID": "ocd-person/8c8f7869-329e-4063-ab55-30e4eca00529",
              "geometry": null
            },
            "contactInfo": {
              "email": "s21@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_james_buck_124",
                "https://iga.in.gov/legislative/2022/legislators/legislator_james_buck_124/",
                "https://www.indianasenaterepublicans.com/buck"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-21-upper-ocd-person-8c8f7869-329e-4063-ab55-30e4eca00529": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/91572a47-dcdf-4be5-8fec-99630da33a39",
            "firstName": "Ronald J.",
            "lastName": "Alting",
            "fullName": "Ron Alting",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/f9fc698d-3a8c-4782-b57e-476b26b9bc36/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/2021-alting-2.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-IN-22-upper-ocd-person-91572a47-dcdf-4be5-8fec-99630da33a39",
              "builtID": "in-upper-22",
              "externalID": "ocd-person/91572a47-dcdf-4be5-8fec-99630da33a39",
              "geometry": null
            },
            "contactInfo": {
              "email": "s22@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_ron_alting_538",
                "https://iga.in.gov/legislative/2022/legislators/legislator_ron_alting_538/",
                "https://www.indianasenaterepublicans.com/alting"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-22-upper-ocd-person-91572a47-dcdf-4be5-8fec-99630da33a39": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/5f8f629e-daed-4a92-ab9b-98c4127d8a0e",
            "firstName": "Spencer",
            "lastName": "Deery",
            "fullName": "Spencer Deery",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/d1a172a6-548d-4c74-9d9d-ea2f1bab1443/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/derry-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-IN-23-upper-ocd-person-5f8f629e-daed-4a92-ab9b-98c4127d8a0e",
              "builtID": "in-upper-23",
              "externalID": "ocd-person/5f8f629e-daed-4a92-ab9b-98c4127d8a0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "s23@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.indianasenaterepublicans.com/deery"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-23-upper-ocd-person-5f8f629e-daed-4a92-ab9b-98c4127d8a0e": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/ed4d2a15-ec36-4f60-8a07-b79e15d34760",
            "firstName": "John Brockman",
            "lastName": "Crane",
            "fullName": "John Crane",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/1b1ad5c0-95b7-41f0-a2f3-e0f6cd5d0290/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/crane.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-IN-24-upper-ocd-person-ed4d2a15-ec36-4f60-8a07-b79e15d34760",
              "builtID": "in-upper-24",
              "externalID": "ocd-person/ed4d2a15-ec36-4f60-8a07-b79e15d34760",
              "geometry": null
            },
            "contactInfo": {
              "email": "s24@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_john_crane_1",
                "https://www.indianasenaterepublicans.com/crane",
                "https://iga.in.gov/legislative/2022/legislators/legislator_john_crane_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-24-upper-ocd-person-ed4d2a15-ec36-4f60-8a07-b79e15d34760": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/01cb22d6-e0f5-478e-99b2-801ffbf32d2b",
            "firstName": "Mike",
            "lastName": "Gaskill",
            "fullName": "Mike Gaskill",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/3f5f30c4-b021-4232-a66c-526b4778fe5a/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/gaskill.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-IN-25-upper-ocd-person-01cb22d6-e0f5-478e-99b2-801ffbf32d2b",
              "builtID": "in-upper-25",
              "externalID": "ocd-person/01cb22d6-e0f5-478e-99b2-801ffbf32d2b",
              "geometry": null
            },
            "contactInfo": {
              "email": "s25@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_mike_gaskill_1",
                "https://www.indianasenaterepublicans.com/gaskill"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-25-upper-ocd-person-01cb22d6-e0f5-478e-99b2-801ffbf32d2b": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/75d45200-93a5-45b7-90c2-9b3014d7f827",
            "firstName": "Scott",
            "lastName": "Alexander",
            "fullName": "Scott Alexander",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/db6aec75-3f20-4efc-89cb-f7272ded9267/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/alexander-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-IN-26-upper-ocd-person-75d45200-93a5-45b7-90c2-9b3014d7f827",
              "builtID": "in-upper-26",
              "externalID": "ocd-person/75d45200-93a5-45b7-90c2-9b3014d7f827",
              "geometry": null
            },
            "contactInfo": {
              "email": "s26@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.indianasenaterepublicans.com/alexander"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-26-upper-ocd-person-75d45200-93a5-45b7-90c2-9b3014d7f827": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/4267823b-aec1-452a-a040-25b337f04c54",
            "firstName": "Jeffrey Scott",
            "lastName": "Raatz",
            "fullName": "Jeff Raatz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/248dafe4-9869-419e-8b36-190d40ffd50b/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/raatz-2022.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-IN-27-upper-ocd-person-4267823b-aec1-452a-a040-25b337f04c54",
              "builtID": "in-upper-27",
              "externalID": "ocd-person/4267823b-aec1-452a-a040-25b337f04c54",
              "geometry": null
            },
            "contactInfo": {
              "email": "s27@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_jeff_raatz_1",
                "https://iga.in.gov/legislative/2022/legislators/legislator_jeff_raatz_1/",
                "https://www.indianasenaterepublicans.com/raatz"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-27-upper-ocd-person-4267823b-aec1-452a-a040-25b337f04c54": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/6ffd10c6-9fd6-4378-8841-63ce4ca88764",
            "firstName": "Michael R.",
            "lastName": "Crider",
            "fullName": "Michael Crider",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/9bb2551b-a7c9-428f-a184-16ef0d718d68/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/crider-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-IN-28-upper-ocd-person-6ffd10c6-9fd6-4378-8841-63ce4ca88764",
              "builtID": "in-upper-28",
              "externalID": "ocd-person/6ffd10c6-9fd6-4378-8841-63ce4ca88764",
              "geometry": null
            },
            "contactInfo": {
              "email": "s28@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_michael_crider_1130",
                "https://iga.in.gov/legislative/2022/legislators/legislator_michael_crider_1130/",
                "https://www.indianasenaterepublicans.com/crider"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-28-upper-ocd-person-6ffd10c6-9fd6-4378-8841-63ce4ca88764": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/85b8c0cf-3df4-45a1-8c38-d220b7122226",
            "firstName": "J.D.",
            "lastName": "Ford",
            "fullName": "J.D. Ford",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianasenatedemocrats.org/wp-content/uploads/2022/12/Senator-Ford-Indiana-State-Flag-Headshot.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-IN-29-upper-ocd-person-85b8c0cf-3df4-45a1-8c38-d220b7122226",
              "builtID": "in-upper-29",
              "externalID": "ocd-person/85b8c0cf-3df4-45a1-8c38-d220b7122226",
              "geometry": null
            },
            "contactInfo": {
              "email": "s29@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "fordsenator",
              "youtube": null,
              "instagram": null,
              "facebook": "senatorjdford",
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_jd_ford_1",
                "https://www.indianasenatedemocrats.org/senators/s29/",
                "https://indianasenatedemocrats.orghttps://www.indianasenatedemocrats.org/s29",
                "https://iga.in.gov/legislative/2021/legislators/legislator_jd_ford_1/",
                "https://indianasenatedemocrats.org/senators/s29/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-29-upper-ocd-person-85b8c0cf-3df4-45a1-8c38-d220b7122226": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/ba72879f-42bd-4735-a3e0-d5edd0358f98",
            "firstName": "Fady",
            "lastName": "Qaddoura",
            "fullName": "Fady Qaddoura",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianasenatedemocrats.org/wp-content/uploads/2020/12/Senator-Qaddoura.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-IN-30-upper-ocd-person-ba72879f-42bd-4735-a3e0-d5edd0358f98",
              "builtID": "in-upper-30",
              "externalID": "ocd-person/ba72879f-42bd-4735-a3e0-d5edd0358f98",
              "geometry": null
            },
            "contactInfo": {
              "email": "s30@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SenFadyQaddoura",
              "youtube": null,
              "instagram": null,
              "facebook": "SenFadyQaddoura",
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_fady_qaddoura_1",
                "https://www.indianasenatedemocrats.org/senators/s30/",
                "https://indianasenatedemocrats.orghttps://www.indianasenatedemocrats.org/s30",
                "https://iga.in.gov/legislative/2022/legislators/legislator_fady_qaddoura_1/",
                "https://indianasenatedemocrats.org/senators/s30/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-30-upper-ocd-person-ba72879f-42bd-4735-a3e0-d5edd0358f98": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/da010a7d-0344-48cb-b9ba-2eca71007cc6",
            "firstName": "Kyle",
            "lastName": "Walker",
            "fullName": "Kyle Walker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/020c0a65-17de-4aa9-a073-80954fb48637/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/walker-k-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-IN-31-upper-ocd-person-da010a7d-0344-48cb-b9ba-2eca71007cc6",
              "builtID": "in-upper-31",
              "externalID": "ocd-person/da010a7d-0344-48cb-b9ba-2eca71007cc6",
              "geometry": null
            },
            "contactInfo": {
              "email": "s31@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_kyle_walker_1",
                "https://www.indianasenaterepublicans.com/kyle-walker",
                "https://iga.in.gov/legislative/2022/legislators/legislator_kyle_walker_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-31-upper-ocd-person-da010a7d-0344-48cb-b9ba-2eca71007cc6": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/b5e2b0b8-ae2a-4852-9c40-c9bf4a32fe1a",
            "firstName": "Aaron",
            "lastName": "Freeman",
            "fullName": "Aaron Freeman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/a664e2a6-fb83-4186-82cc-d4e33ff0591f/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/2021-freeman-2.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-IN-32-upper-ocd-person-b5e2b0b8-ae2a-4852-9c40-c9bf4a32fe1a",
              "builtID": "in-upper-32",
              "externalID": "ocd-person/b5e2b0b8-ae2a-4852-9c40-c9bf4a32fe1a",
              "geometry": null
            },
            "contactInfo": {
              "email": "s32@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_aaron_freeman_1",
                "https://www.indianasenaterepublicans.com/freeman",
                "https://iga.in.gov/legislative/2022/legislators/legislator_aaron_freeman_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-32-upper-ocd-person-b5e2b0b8-ae2a-4852-9c40-c9bf4a32fe1a": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/39798614-3cb2-40b7-adad-4f5c53ff59e5",
            "firstName": "Gregory G.",
            "lastName": "Taylor",
            "fullName": "Greg Taylor",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indianasenatedemocrats.org/wp-content/uploads/2020/12/Senator-Taylor.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-IN-33-upper-ocd-person-39798614-3cb2-40b7-adad-4f5c53ff59e5",
              "builtID": "in-upper-33",
              "externalID": "ocd-person/39798614-3cb2-40b7-adad-4f5c53ff59e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "s33@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "sen_gregtaylor",
              "youtube": null,
              "instagram": null,
              "facebook": "SenGregTaylor",
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_greg_taylor_976",
                "https://www.indianasenatedemocrats.org/senators/s33/",
                "https://indianasenatedemocrats.orghttps://www.indianasenatedemocrats.org/s33",
                "https://iga.in.gov/legislative/2022/legislators/legislator_greg_taylor_976/",
                "https://indianasenatedemocrats.org/senators/s33/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-33-upper-ocd-person-39798614-3cb2-40b7-adad-4f5c53ff59e5": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/6c8d86c3-4157-4673-a7d9-524c9d49f859",
            "firstName": "La Keisha",
            "lastName": "Jackson",
            "fullName": "La Keisha Jackson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/LaKeishaJackson.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-IN-34-upper-ocd-person-6c8d86c3-4157-4673-a7d9-524c9d49f859",
              "builtID": "in-upper-34",
              "externalID": "ocd-person/6c8d86c3-4157-4673-a7d9-524c9d49f859",
              "geometry": null
            },
            "contactInfo": {
              "email": "s34@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2024/legislators/legislator_la_keisha_jackson_1",
                "https://indianasenatedemocrats.org/senators/s34/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-34-upper-ocd-person-6c8d86c3-4157-4673-a7d9-524c9d49f859": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/01bb6149-aa0f-457f-b759-1338b6c8ae5f",
            "firstName": "R. Michael",
            "lastName": "Young",
            "fullName": "Mike Young",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/3234d40a-6603-4164-b8e7-6529ae02811b/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/young-2012.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-IN-35-upper-ocd-person-01bb6149-aa0f-457f-b759-1338b6c8ae5f",
              "builtID": "in-upper-35",
              "externalID": "ocd-person/01bb6149-aa0f-457f-b759-1338b6c8ae5f",
              "geometry": null
            },
            "contactInfo": {
              "email": "s35@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_r_michael_young_645",
                "https://www.indianasenaterepublicans.com/young"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-35-upper-ocd-person-01bb6149-aa0f-457f-b759-1338b6c8ae5f": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/f15e7d77-05df-4875-88a3-0a1aa40822ec",
            "firstName": "Cynthia V.",
            "lastName": "Carrasco",
            "fullName": "Cyndi Carrasco",
            "gender": "Female",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/1e5c808a-c5f4-4022-a571-1da38a777c1c/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/carrasco-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-IN-36-upper-ocd-person-f15e7d77-05df-4875-88a3-0a1aa40822ec",
              "builtID": "in-upper-36",
              "externalID": "ocd-person/f15e7d77-05df-4875-88a3-0a1aa40822ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "s36@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.indianasenaterepublicans.com/carrasco"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-36-upper-ocd-person-f15e7d77-05df-4875-88a3-0a1aa40822ec": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/766cf9c2-a001-49a1-8fba-075a0510767d",
            "firstName": "Rodric D.",
            "lastName": "Bray",
            "fullName": "Rod Bray",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/300cdb3a-5a3e-4686-9576-da26eb5ed28f/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/bray-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-IN-37-upper-ocd-person-766cf9c2-a001-49a1-8fba-075a0510767d",
              "builtID": "in-upper-37",
              "externalID": "ocd-person/766cf9c2-a001-49a1-8fba-075a0510767d",
              "geometry": null
            },
            "contactInfo": {
              "email": "s37@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_rodric_bray_1106",
                "https://iga.in.gov/legislative/2022/legislators/legislator_rodric_bray_1106/",
                "https://www.indianasenaterepublicans.com/bray"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-37-upper-ocd-person-766cf9c2-a001-49a1-8fba-075a0510767d": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/a5c26055-cf9f-4d9c-979a-f8964c48c4da",
            "firstName": "Gregory Justin",
            "lastName": "Goode",
            "fullName": "Greg Goode",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/4f942262-ed42-47cc-a158-6691b9333043/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/goode-website-2.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-IN-38-upper-ocd-person-a5c26055-cf9f-4d9c-979a-f8964c48c4da",
              "builtID": "in-upper-38",
              "externalID": "ocd-person/a5c26055-cf9f-4d9c-979a-f8964c48c4da",
              "geometry": null
            },
            "contactInfo": {
              "email": "s38@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.indianasenaterepublicans.com/goode"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-38-upper-ocd-person-a5c26055-cf9f-4d9c-979a-f8964c48c4da": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/2d517236-2a88-435f-bc1f-ce09de86c6f9",
            "firstName": "Eric",
            "lastName": "Bassler",
            "fullName": "Eric Bassler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/0b9f559d-7c9e-4d33-b1c8-5d4f3543c195/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/bassler.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-IN-39-upper-ocd-person-2d517236-2a88-435f-bc1f-ce09de86c6f9",
              "builtID": "in-upper-39",
              "externalID": "ocd-person/2d517236-2a88-435f-bc1f-ce09de86c6f9",
              "geometry": null
            },
            "contactInfo": {
              "email": "s39@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_eric_bassler_1",
                "https://iga.in.gov/legislative/2023/legislators/legislator_eric_bassler_1/",
                "https://www.indianasenaterepublicans.com/bassler"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-39-upper-ocd-person-2d517236-2a88-435f-bc1f-ce09de86c6f9": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/872447dd-3b06-421e-af8c-25ea68aa5ae4",
            "firstName": "Shelli Renee",
            "lastName": "Yoder",
            "fullName": "Shelli Yoder",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://indianasenatedemocrats.org/wp-content/uploads/2020/12/Senator-Yoder.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-IN-40-upper-ocd-person-872447dd-3b06-421e-af8c-25ea68aa5ae4",
              "builtID": "in-upper-40",
              "externalID": "ocd-person/872447dd-3b06-421e-af8c-25ea68aa5ae4",
              "geometry": null
            },
            "contactInfo": {
              "email": "s40@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SenatorYoder",
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorShelliYoder",
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_shelli_yoder_1",
                "https://www.indianasenatedemocrats.org/senators/s40/",
                "https://indianasenatedemocrats.orghttps://www.indianasenatedemocrats.org/s40",
                "https://iga.in.gov/legislative/2022/legislators/legislator_shelli_yoder_1/",
                "https://indianasenatedemocrats.org/senators/s40/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-40-upper-ocd-person-872447dd-3b06-421e-af8c-25ea68aa5ae4": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/0bee6f8c-db8b-4e92-8bd4-799c71c55c2c",
            "firstName": "Greggory",
            "lastName": "Walker",
            "fullName": "Greg Walker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/425bd5c0-bbc0-4ca8-b7e1-93e41dfd2d72/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/walker-g-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-IN-41-upper-ocd-person-0bee6f8c-db8b-4e92-8bd4-799c71c55c2c",
              "builtID": "in-upper-41",
              "externalID": "ocd-person/0bee6f8c-db8b-4e92-8bd4-799c71c55c2c",
              "geometry": null
            },
            "contactInfo": {
              "email": "s41@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_greg_walker_867",
                "https://iga.in.gov/legislative/2022/legislators/legislator_greg_walker_867/",
                "https://www.indianasenaterepublicans.com/greg-walker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-41-upper-ocd-person-0bee6f8c-db8b-4e92-8bd4-799c71c55c2c": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/40ea5143-d0ab-4b9a-a0bc-2cc04f984888",
            "firstName": "Jean",
            "lastName": "Leising",
            "fullName": "Jean Leising",
            "gender": "Female",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/214611f0-f359-4510-8ca0-b5d00e139454/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/2021-leising-2.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-IN-42-upper-ocd-person-40ea5143-d0ab-4b9a-a0bc-2cc04f984888",
              "builtID": "in-upper-42",
              "externalID": "ocd-person/40ea5143-d0ab-4b9a-a0bc-2cc04f984888",
              "geometry": null
            },
            "contactInfo": {
              "email": "s42@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_jean_leising_136",
                "https://iga.in.gov/legislative/2022/legislators/legislator_jean_leising_136/",
                "https://www.indianasenaterepublicans.com/leising"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-42-upper-ocd-person-40ea5143-d0ab-4b9a-a0bc-2cc04f984888": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/acd45b79-7930-459f-8118-70b9574ba481",
            "firstName": "Randy",
            "lastName": "Maxwell",
            "fullName": "Randy Maxwell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/78226b9f-d4ad-4d02-8f18-d1bc45ce3c44/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/maxwell-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "43",
              "chamber": "upper",
              "hashID": "SLDU-IN-43-upper-ocd-person-acd45b79-7930-459f-8118-70b9574ba481",
              "builtID": "in-upper-43",
              "externalID": "ocd-person/acd45b79-7930-459f-8118-70b9574ba481",
              "geometry": null
            },
            "contactInfo": {
              "email": "s43@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.indianasenaterepublicans.com/maxwell"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-43-upper-ocd-person-acd45b79-7930-459f-8118-70b9574ba481": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/a7974310-0d01-4bc1-9c14-29561ec68a60",
            "firstName": "Eric Allan",
            "lastName": "Koch",
            "fullName": "Eric Koch",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/efc8ba1f-e642-4264-908c-4cd32e355ad8/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/koch-headshot.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "44",
              "chamber": "upper",
              "hashID": "SLDU-IN-44-upper-ocd-person-a7974310-0d01-4bc1-9c14-29561ec68a60",
              "builtID": "in-upper-44",
              "externalID": "ocd-person/a7974310-0d01-4bc1-9c14-29561ec68a60",
              "geometry": null
            },
            "contactInfo": {
              "email": "s44@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_eric_koch_1",
                "https://www.indianasenaterepublicans.com/koch",
                "https://iga.in.gov/legislative/2022/legislators/legislator_eric_koch_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-44-upper-ocd-person-a7974310-0d01-4bc1-9c14-29561ec68a60": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/dc07e0d2-7372-46c2-967c-878ec522879d",
            "firstName": "Chris",
            "lastName": "Garten",
            "fullName": "Chris Garten",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/a2dbf141-af13-4ad7-9e37-78104d7da727/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/image.jpeg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "45",
              "chamber": "upper",
              "hashID": "SLDU-IN-45-upper-ocd-person-dc07e0d2-7372-46c2-967c-878ec522879d",
              "builtID": "in-upper-45",
              "externalID": "ocd-person/dc07e0d2-7372-46c2-967c-878ec522879d",
              "geometry": null
            },
            "contactInfo": {
              "email": "s45@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_chris_garten_1",
                "https://iga.in.gov/legislative/2022/legislators/legislator_chris_garten_1/",
                "https://www.indianasenaterepublicans.com/garten"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-45-upper-ocd-person-dc07e0d2-7372-46c2-967c-878ec522879d": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/f22b3c5a-950d-4e0c-83c8-bf9a7c005c73",
            "firstName": "Andrea",
            "lastName": "Hunley",
            "fullName": "Andrea Hunley",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://indianasenatedemocrats.org/wp-content/uploads/2022/12/Senator-Hunley_Hoosier-Profile-Pic-600x900-2.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "46",
              "chamber": "upper",
              "hashID": "SLDU-IN-46-upper-ocd-person-f22b3c5a-950d-4e0c-83c8-bf9a7c005c73",
              "builtID": "in-upper-46",
              "externalID": "ocd-person/f22b3c5a-950d-4e0c-83c8-bf9a7c005c73",
              "geometry": null
            },
            "contactInfo": {
              "email": "s46@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://indianasenatedemocrats.orghttps://www.indianasenatedemocrats.org/s46",
                "https://indianasenatedemocrats.org/senators/s46/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-46-upper-ocd-person-f22b3c5a-950d-4e0c-83c8-bf9a7c005c73": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/aa12c3a4-7207-4474-af92-7a1f9dcf62a3",
            "firstName": "Gary",
            "lastName": "Byrne",
            "fullName": "Gary Byrne",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/2277168c-48ae-434a-8380-04e4690e5e9c/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/byrne-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "47",
              "chamber": "upper",
              "hashID": "SLDU-IN-47-upper-ocd-person-aa12c3a4-7207-4474-af92-7a1f9dcf62a3",
              "builtID": "in-upper-47",
              "externalID": "ocd-person/aa12c3a4-7207-4474-af92-7a1f9dcf62a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "s47@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.indianasenaterepublicans.com/byrne",
                "https://iga.in.gov/legislative/2022/legislators/legislator_gary_byrne_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-47-upper-ocd-person-aa12c3a4-7207-4474-af92-7a1f9dcf62a3": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/eb196828-cccf-4897-b3d3-790e53761534",
            "firstName": "Mark B.",
            "lastName": "Messmer",
            "fullName": "Mark Messmer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/ed7ca563-d451-4154-b8ae-2ad3df07f079/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/messmer.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "48",
              "chamber": "upper",
              "hashID": "SLDU-IN-48-upper-ocd-person-eb196828-cccf-4897-b3d3-790e53761534",
              "builtID": "in-upper-48",
              "externalID": "ocd-person/eb196828-cccf-4897-b3d3-790e53761534",
              "geometry": null
            },
            "contactInfo": {
              "email": "s48@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_mark_messmer_983",
                "https://iga.in.gov/legislative/2022/legislators/legislator_mark_messmer_983/",
                "https://www.indianasenaterepublicans.com/messmer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-48-upper-ocd-person-eb196828-cccf-4897-b3d3-790e53761534": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/c2c98fe8-fef2-4d38-93f6-9eff4d591bed",
            "firstName": "James Andrew",
            "lastName": "Tomes",
            "fullName": "Jim Tomes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/a18fe9f0-b8a3-411e-bcc2-6d9fb9423a19/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/2021-tomes-2.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "49",
              "chamber": "upper",
              "hashID": "SLDU-IN-49-upper-ocd-person-c2c98fe8-fef2-4d38-93f6-9eff4d591bed",
              "builtID": "in-upper-49",
              "externalID": "ocd-person/c2c98fe8-fef2-4d38-93f6-9eff4d591bed",
              "geometry": null
            },
            "contactInfo": {
              "email": "s49@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_james_tomes_1041",
                "https://iga.in.gov/legislative/2022/legislators/legislator_james_tomes_1041/",
                "https://iga.in.gov/legislative/2021/legislators/legislator_james_tomes_1041/",
                "https://www.indianasenaterepublicans.com/tomes"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-49-upper-ocd-person-c2c98fe8-fef2-4d38-93f6-9eff4d591bed": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/b6be97c1-d574-443d-b493-224dcb6e2a7f",
            "firstName": "Vaneta",
            "lastName": "Becker",
            "fullName": "Vaneta Becker",
            "gender": "Female",
            "party": "Republican",
            "image": "https://cdn.zephyrcms.com/e904380d-6101-41f4-8699-7849dfa918dc/-/progressive/yes/-/format/jpeg/-/scale_crop/200x300/center/-/stretch/off/becker-website.jpg",
            "title": "IN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IN",
              "stateFull": "Indiana",
              "district": "50",
              "chamber": "upper",
              "hashID": "SLDU-IN-50-upper-ocd-person-b6be97c1-d574-443d-b493-224dcb6e2a7f",
              "builtID": "in-upper-50",
              "externalID": "ocd-person/b6be97c1-d574-443d-b493-224dcb6e2a7f",
              "geometry": null
            },
            "contactInfo": {
              "email": "s50@iga.in.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://iga.in.gov/legislative/2021/legislators/legislator_vaneta_becker_228",
                "https://iga.in.gov/legislative/2022/legislators/legislator_vaneta_becker_228/",
                "https://www.indianasenaterepublicans.com/becker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IN-50-upper-ocd-person-b6be97c1-d574-443d-b493-224dcb6e2a7f": 0
        }
      }
    }
  },
  "IA": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "IA",
          "stateFull": "Iowa",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "IA",
            "stateFull": "Iowa",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-IA---",
            "builtID": "ia--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-IA---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "E000295",
          "in_office": true,
          "firstName": "Joni",
          "lastName": "Ernst",
          "middleName": null,
          "fullName": "Joni Ernst",
          "gender": "F",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/E000295.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "IA",
            "stateFull": "Iowa",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-IA---E000295",
            "builtID": "ia--",
            "externalID": "E000295",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.ernst.senate.gov/public/index.cfm/contact",
            "address1": "730 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-3254",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenJoniErnst",
            "youtube": null,
            "instagram": null,
            "facebook": "senjoniernst",
            "urls": ["https://www.ernst.senate.gov"],
            "rss_url": "https://www.ernst.senate.gov/public/?a=rss.feed"
          },
          "title": "IA Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/E000295.json",
          "govtrack_id": "412667",
          "cspan_id": "75342",
          "votesmart_id": "128583",
          "icpsr_id": "41502",
          "crp_id": "N00035483",
          "google_entity_id": "/m/0gg4wkg",
          "state_rank": "junior",
          "lis_id": "S376",
          "suffix": null,
          "date_of_birth": "1970-07-01",
          "leadership_role": "Senate Republican Conference Vice Chair",
          "fec_candidate_id": "S4IA00129",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "9",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 16,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 2.89,
          "votes_with_party_pct": 93.57,
          "votes_against_party_pct": 6.43,
          "ocd_id": "ocd-division/country:us/state:ia"
        },
        {
          "API_ID": "G000386",
          "in_office": true,
          "firstName": "Charles",
          "lastName": "Grassley",
          "middleName": "E.",
          "fullName": "Chuck Grassley",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/G000386.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "IA",
            "stateFull": "Iowa",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-IA---G000386",
            "builtID": "ia--",
            "externalID": "G000386",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "ChuckGrassley",
            "youtube": "senchuckgrassley",
            "instagram": null,
            "facebook": "grassley",
            "urls": ["https://www.grassley.senate.gov"],
            "rss_url": "https://www.grassley.senate.gov/?q=rss.xml"
          },
          "title": "IA Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/G000386.json",
          "govtrack_id": "300048",
          "cspan_id": "1167",
          "votesmart_id": "53293",
          "icpsr_id": "14226",
          "crp_id": "N00001758",
          "google_entity_id": "/m/020ymy",
          "state_rank": "senior",
          "lis_id": "S153",
          "suffix": null,
          "date_of_birth": "1933-09-17",
          "leadership_role": "President Pro Tempore of the Senate",
          "fec_candidate_id": "S0IA00028",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "43",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 5,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 0.9,
          "votes_with_party_pct": 86.87,
          "votes_against_party_pct": 13.13,
          "ocd_id": "ocd-division/country:us/state:ia"
        }
      ],
      "hash": { "SENATE-IA---E000295": 0, "SENATE-IA---G000386": 1 }
    },
    "HOUSE": {
      "04": {
        "members": [
          {
            "API_ID": "F000446",
            "in_office": true,
            "firstName": "Randy",
            "lastName": "Feenstra",
            "middleName": null,
            "fullName": "Randy Feenstra",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/F000446.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-IA-04--F000446",
              "builtID": "ia--04",
              "externalID": "F000446",
              "geometry": null,
              "geoid": "1904"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1440 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4426",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepFeenstra",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://feenstra.house.gov"],
              "rss_url": null
            },
            "title": "IA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000446.json",
            "govtrack_id": "456818",
            "cspan_id": null,
            "votesmart_id": "103301",
            "icpsr_id": null,
            "crp_id": "N00044011",
            "google_entity_id": "/m/05p06tx",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1969-01-14",
            "leadership_role": null,
            "fec_candidate_id": "H0IA04145",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 4,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.38,
            "votes_with_party_pct": 94.57,
            "votes_against_party_pct": 3.49,
            "ocd_id": "ocd-division/country:us/state:ia/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IA-04--F000446": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "H001091",
            "in_office": true,
            "firstName": "Ashley",
            "lastName": "Hinson",
            "middleName": null,
            "fullName": "Ashley Hinson",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/H001091.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-IA-02--H001091",
              "builtID": "ia--02",
              "externalID": "H001091",
              "geometry": null,
              "geoid": "1902"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1717 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2911",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAshleyHinson",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://hinson.house.gov"],
              "rss_url": null
            },
            "title": "IA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001091.json",
            "govtrack_id": "456816",
            "cspan_id": null,
            "votesmart_id": "168783",
            "icpsr_id": null,
            "crp_id": "N00044521",
            "google_entity_id": "/g/11fjq_fl2t",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1983-06-27",
            "leadership_role": null,
            "fec_candidate_id": "H0IA01174",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 10,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.95,
            "votes_with_party_pct": 88,
            "votes_against_party_pct": 10.05,
            "ocd_id": "ocd-division/country:us/state:ia/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IA-02--H001091": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "M001215",
            "in_office": true,
            "firstName": "Mariannette",
            "lastName": "Miller-Meeks",
            "middleName": null,
            "fullName": "Mariannette Miller-Meeks",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001215.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-IA-01--M001215",
              "builtID": "ia--01",
              "externalID": "M001215",
              "geometry": null,
              "geoid": "1901"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1034 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-6576",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMMM",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://millermeeks.house.gov"],
              "rss_url": null
            },
            "title": "IA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001215.json",
            "govtrack_id": "456817",
            "cspan_id": null,
            "votesmart_id": "103294",
            "icpsr_id": null,
            "crp_id": "N00029495",
            "google_entity_id": "/g/11fhm4wv8g",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1955-09-06",
            "leadership_role": null,
            "fec_candidate_id": "H8IA02043",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 35,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 3.31,
            "votes_with_party_pct": 89.71,
            "votes_against_party_pct": 8.29,
            "ocd_id": "ocd-division/country:us/state:ia/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IA-01--M001215": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "N000193",
            "in_office": true,
            "firstName": "Zach",
            "lastName": "Nunn",
            "middleName": null,
            "fullName": "Zachary Nunn",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/N000193.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-IA-03--N000193",
              "builtID": "ia--03",
              "externalID": "N000193",
              "geometry": null,
              "geoid": "1903"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1232 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5476",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "ZachNunn",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://nunn.house.gov/"],
              "rss_url": null
            },
            "title": "IA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/N000193.json",
            "govtrack_id": "456898",
            "cspan_id": null,
            "votesmart_id": "151170",
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1979-05-04",
            "leadership_role": null,
            "fec_candidate_id": "H2IA03119",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 36,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 3.41,
            "votes_with_party_pct": 85.5,
            "votes_against_party_pct": 12.5,
            "ocd_id": "ocd-division/country:us/state:ia/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-IA-03--N000193": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/4483a8f0-d946-45b2-9a2e-00dbb4816816",
            "firstName": "James D.",
            "lastName": "Scholten",
            "fullName": "J.D. Scholten",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33963",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-IA-1-lower-ocd-person-4483a8f0-d946-45b2-9a2e-00dbb4816816",
              "builtID": "ia-lower-1",
              "externalID": "ocd-person/4483a8f0-d946-45b2-9a2e-00dbb4816816",
              "geometry": null
            },
            "contactInfo": {
              "email": "j.d.scholten@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/ihfkwH",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33963"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-1-lower-ocd-person-4483a8f0-d946-45b2-9a2e-00dbb4816816": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/078e02d7-0d26-49f2-9176-8044701e540f",
            "firstName": "Robert James",
            "lastName": "Henderson",
            "fullName": "Bob Henderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33964",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-IA-2-lower-ocd-person-078e02d7-0d26-49f2-9176-8044701e540f",
              "builtID": "ia-lower-2",
              "externalID": "ocd-person/078e02d7-0d26-49f2-9176-8044701e540f",
              "geometry": null
            },
            "contactInfo": {
              "email": "robert.henderson@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33964&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33964"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-2-lower-ocd-person-078e02d7-0d26-49f2-9176-8044701e540f": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/49fc2a54-b290-4f92-9fda-64893f09d29c",
            "firstName": "Thomas Michael",
            "lastName": "Jeneary",
            "fullName": "Tom Jeneary",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27018",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-IA-3-lower-ocd-person-49fc2a54-b290-4f92-9fda-64893f09d29c",
              "builtID": "ia-lower-3",
              "externalID": "ocd-person/49fc2a54-b290-4f92-9fda-64893f09d29c",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.jeneary@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=27018",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=27018",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=27018",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=27018&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=27018"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-3-lower-ocd-person-49fc2a54-b290-4f92-9fda-64893f09d29c": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/9d60676d-f50e-4117-8d22-681927e43a01",
            "firstName": "Skyler",
            "lastName": "Wheeler",
            "fullName": "Skyler Wheeler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=18040",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-IA-4-lower-ocd-person-9d60676d-f50e-4117-8d22-681927e43a01",
              "builtID": "ia-lower-4",
              "externalID": "ocd-person/9d60676d-f50e-4117-8d22-681927e43a01",
              "geometry": null
            },
            "contactInfo": {
              "email": "skyler.wheeler@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=18040",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=18040",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=18040&ga=89",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=18040&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=18040"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-4-lower-ocd-person-9d60676d-f50e-4117-8d22-681927e43a01": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/ef26d757-2882-4047-a82e-0682cc638d05",
            "firstName": "Zachary",
            "lastName": "Dieken",
            "fullName": "Zach Dieken",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33965",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-IA-5-lower-ocd-person-ef26d757-2882-4047-a82e-0682cc638d05",
              "builtID": "ia-lower-5",
              "externalID": "ocd-person/ef26d757-2882-4047-a82e-0682cc638d05",
              "geometry": null
            },
            "contactInfo": {
              "email": "zachary.dieken@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33965&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33965"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-5-lower-ocd-person-ef26d757-2882-4047-a82e-0682cc638d05": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/65c3efb9-452e-4aae-a390-15db3f3ba707",
            "firstName": "Megan Lee Hess",
            "lastName": "Jones",
            "fullName": "Megan Jones",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=10747",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-IA-6-lower-ocd-person-65c3efb9-452e-4aae-a390-15db3f3ba707",
              "builtID": "ia-lower-6",
              "externalID": "ocd-person/65c3efb9-452e-4aae-a390-15db3f3ba707",
              "geometry": null
            },
            "contactInfo": {
              "email": "megan.jones@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=10747",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=10747",
                "https://www.jonesforiowa.com",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=10747&ga=89",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=10747&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=10747"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-6-lower-ocd-person-65c3efb9-452e-4aae-a390-15db3f3ba707": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/7abba275-ec8f-4525-a0b4-8355016db588",
            "firstName": "Michael",
            "lastName": "Sexton",
            "fullName": "Mike Sexton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=1153",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-IA-7-lower-ocd-person-7abba275-ec8f-4525-a0b4-8355016db588",
              "builtID": "ia-lower-7",
              "externalID": "ocd-person/7abba275-ec8f-4525-a0b4-8355016db588",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.sexton@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=1153",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=1153",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=1153",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=1153&ga=89",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=1153&ga=90",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://lp.constantcontactpages.com/su/99oa5DS",
                "https://www.legis.iowa.gov/legislators/legislator?personID=1153"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-7-lower-ocd-person-7abba275-ec8f-4525-a0b4-8355016db588": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/f8bf5332-b8b7-4d55-8652-e9b883cae35e",
            "firstName": "Ann",
            "lastName": "Meyer",
            "fullName": "Ann Meyer",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27019",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-IA-8-lower-ocd-person-f8bf5332-b8b7-4d55-8652-e9b883cae35e",
              "builtID": "ia-lower-8",
              "externalID": "ocd-person/f8bf5332-b8b7-4d55-8652-e9b883cae35e",
              "geometry": null
            },
            "contactInfo": {
              "email": "ann.meyer@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=27019",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=27019",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=27019&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=27019"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-8-lower-ocd-person-f8bf5332-b8b7-4d55-8652-e9b883cae35e": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/21405c5b-96dc-4c28-9b69-59c2cf00e9a5",
            "firstName": "Henry",
            "lastName": "Stone",
            "fullName": "Henry Stone",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30645",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-IA-9-lower-ocd-person-21405c5b-96dc-4c28-9b69-59c2cf00e9a5",
              "builtID": "ia-lower-9",
              "externalID": "ocd-person/21405c5b-96dc-4c28-9b69-59c2cf00e9a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "henry.stone@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=30645",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://iowa.us7.list-manage.com/subscribe?u=b7d249185b0959748eda3cd67&id=04fa01942f",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30645"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-9-lower-ocd-person-21405c5b-96dc-4c28-9b69-59c2cf00e9a5": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/955a4d7c-a378-4bd2-8a74-dac94f74ba52",
            "firstName": "John H.",
            "lastName": "Wills",
            "fullName": "John Wills",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=13794",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-IA-10-lower-ocd-person-955a4d7c-a378-4bd2-8a74-dac94f74ba52",
              "builtID": "ia-lower-10",
              "externalID": "ocd-person/955a4d7c-a378-4bd2-8a74-dac94f74ba52",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.wills@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=13794",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=13794",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=13794&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=13794"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-10-lower-ocd-person-955a4d7c-a378-4bd2-8a74-dac94f74ba52": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/4dfe4566-e567-4f5f-bc1d-f6dd3febe2ea",
            "firstName": "Brian",
            "lastName": "Best",
            "fullName": "Brian Best",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=14800",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-IA-11-lower-ocd-person-4dfe4566-e567-4f5f-bc1d-f6dd3febe2ea",
              "builtID": "ia-lower-11",
              "externalID": "ocd-person/4dfe4566-e567-4f5f-bc1d-f6dd3febe2ea",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.best@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=14800",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=14800",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=14800",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://facebook.us17.list-manage.com/subscribe?u=c0e1fdb03b769b2872ca4d743&id=4aad588edf",
                "https://www.legis.iowa.gov/legislators/legislator?personID=14800"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-11-lower-ocd-person-4dfe4566-e567-4f5f-bc1d-f6dd3febe2ea": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/427b416b-eca9-4863-a8c2-c54ad2769bc1",
            "firstName": "Steven",
            "lastName": "Holt",
            "fullName": "Steven Holt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=14802",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-IA-12-lower-ocd-person-427b416b-eca9-4863-a8c2-c54ad2769bc1",
              "builtID": "ia-lower-12",
              "externalID": "ocd-person/427b416b-eca9-4863-a8c2-c54ad2769bc1",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.holt@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=14802",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=14802",
                "https://https://us12.list-manage.com/subscribe?u=f955f4c16837dca985de73bb8&id=02599e57e6",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://www.legis.iowa.gov/legislators/legislator?personID=14802"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-12-lower-ocd-person-427b416b-eca9-4863-a8c2-c54ad2769bc1": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/10d43b96-0df8-483e-90ae-984e208481bb",
            "firstName": "Ken",
            "lastName": "Carlson",
            "fullName": "Ken Carlson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33967",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-IA-13-lower-ocd-person-10d43b96-0df8-483e-90ae-984e208481bb",
              "builtID": "ia-lower-13",
              "externalID": "ocd-person/10d43b96-0df8-483e-90ae-984e208481bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.carlson@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33967&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33967"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-13-lower-ocd-person-10d43b96-0df8-483e-90ae-984e208481bb": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/9c6428d6-af30-4a4f-bb9c-666d3d6d84c5",
            "firstName": "Jacob I.",
            "lastName": "Bossman",
            "fullName": "Jacob Bossman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=25497",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-IA-14-lower-ocd-person-9c6428d6-af30-4a4f-bb9c-666d3d6d84c5",
              "builtID": "ia-lower-14",
              "externalID": "ocd-person/9c6428d6-af30-4a4f-bb9c-666d3d6d84c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "jacob.bossman@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=25497",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=25497",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=25497",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=25497&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=25497"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-14-lower-ocd-person-9c6428d6-af30-4a4f-bb9c-666d3d6d84c5": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/be1898a3-30da-45c6-810f-4751158daefc",
            "firstName": "Matt W.",
            "lastName": "Windschitl",
            "fullName": "Matt Windschitl",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=6282",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-IA-15-lower-ocd-person-be1898a3-30da-45c6-810f-4751158daefc",
              "builtID": "ia-lower-15",
              "externalID": "ocd-person/be1898a3-30da-45c6-810f-4751158daefc",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.windschitl@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=6282",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=6282",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=6282",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://iowahouserepublicans.nationbuilder.com/admin/sites/2/pages/30/preview?browser_id=default&logged_in=0&theme_id=5cf7e407c294800185b6c2c2",
                "https://www.legis.iowa.gov/legislators/legislator?personID=6282"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-15-lower-ocd-person-be1898a3-30da-45c6-810f-4751158daefc": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/7e4913e9-9219-461f-9842-2a96813e009e",
            "firstName": "David",
            "lastName": "Sieck",
            "fullName": "David Sieck",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=15920",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-IA-16-lower-ocd-person-7e4913e9-9219-461f-9842-2a96813e009e",
              "builtID": "ia-lower-16",
              "externalID": "ocd-person/7e4913e9-9219-461f-9842-2a96813e009e",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.sieck@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=15920",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=15920",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=15920&ga=89",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=15920&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=15920"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-16-lower-ocd-person-7e4913e9-9219-461f-9842-2a96813e009e": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/60f3c355-fa56-48db-8379-14234d427148",
            "firstName": "Devon G.",
            "lastName": "Wood",
            "fullName": "Devon Wood",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33968",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-IA-17-lower-ocd-person-60f3c355-fa56-48db-8379-14234d427148",
              "builtID": "ia-lower-17",
              "externalID": "ocd-person/60f3c355-fa56-48db-8379-14234d427148",
              "geometry": null
            },
            "contactInfo": {
              "email": "devon.wood@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33968&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33968"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-17-lower-ocd-person-60f3c355-fa56-48db-8379-14234d427148": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/137108a7-0316-40f8-9624-08b2094fb723",
            "firstName": "Thomas Jay",
            "lastName": "Moore",
            "fullName": "Tom Moore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=17112",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-IA-18-lower-ocd-person-137108a7-0316-40f8-9624-08b2094fb723",
              "builtID": "ia-lower-18",
              "externalID": "ocd-person/137108a7-0316-40f8-9624-08b2094fb723",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.moore@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=17112",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=17112",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=17112",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://lp.constantcontactpages.com/su/uOj4xDA",
                "https://www.legis.iowa.gov/legislators/legislator?personID=17112"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-18-lower-ocd-person-137108a7-0316-40f8-9624-08b2094fb723": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/29efffba-9a8b-473f-a66e-8be5441cbb83",
            "firstName": "J. Brent",
            "lastName": "Siegrist",
            "fullName": "Brent Siegrist",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30646",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-IA-19-lower-ocd-person-29efffba-9a8b-473f-a66e-8be5441cbb83",
              "builtID": "ia-lower-19",
              "externalID": "ocd-person/29efffba-9a8b-473f-a66e-8be5441cbb83",
              "geometry": null
            },
            "contactInfo": {
              "email": "brent.siegrist@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=30646",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=30646&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30646"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-19-lower-ocd-person-29efffba-9a8b-473f-a66e-8be5441cbb83": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/c9679bfc-5a21-405a-bae1-e2de9a85baf8",
            "firstName": "Joshua",
            "lastName": "Turek",
            "fullName": "Josh Turek",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33969",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-IA-20-lower-ocd-person-c9679bfc-5a21-405a-bae1-e2de9a85baf8",
              "builtID": "ia-lower-20",
              "externalID": "ocd-person/c9679bfc-5a21-405a-bae1-e2de9a85baf8",
              "geometry": null
            },
            "contactInfo": {
              "email": "josh.turek@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/ihe76n",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33969"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-20-lower-ocd-person-c9679bfc-5a21-405a-bae1-e2de9a85baf8": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/942429d9-5b4a-4ad7-8da7-d43eb7828dff",
            "firstName": "Brooke",
            "lastName": "Boden",
            "fullName": "Brooke Boden",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30648",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-IA-21-lower-ocd-person-942429d9-5b4a-4ad7-8da7-d43eb7828dff",
              "builtID": "ia-lower-21",
              "externalID": "ocd-person/942429d9-5b4a-4ad7-8da7-d43eb7828dff",
              "geometry": null
            },
            "contactInfo": {
              "email": "brooke.boden@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=30648",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://brookeforiowa.us7.list-manage.com/subscribe?u=2729c18ae239c6270f597da67&id=dbd3dab3ff",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30648"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-21-lower-ocd-person-942429d9-5b4a-4ad7-8da7-d43eb7828dff": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/843dcb1c-23fe-4148-9bc6-77552c21668e",
            "firstName": "Stan",
            "lastName": "Gustafson",
            "fullName": "Stan Gustafson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=12712",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-IA-22-lower-ocd-person-843dcb1c-23fe-4148-9bc6-77552c21668e",
              "builtID": "ia-lower-22",
              "externalID": "ocd-person/843dcb1c-23fe-4148-9bc6-77552c21668e",
              "geometry": null
            },
            "contactInfo": {
              "email": "stan.gustafson@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=12712",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=12712",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=12712",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=12712&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=12712"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-22-lower-ocd-person-843dcb1c-23fe-4148-9bc6-77552c21668e": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/97a4ef03-4b50-4fcd-9ebc-3d76482f2150",
            "firstName": "Ray Bubba",
            "lastName": "Sorensen",
            "fullName": "Ray Sorensen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27020",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-IA-23-lower-ocd-person-97a4ef03-4b50-4fcd-9ebc-3d76482f2150",
              "builtID": "ia-lower-23",
              "externalID": "ocd-person/97a4ef03-4b50-4fcd-9ebc-3d76482f2150",
              "geometry": null
            },
            "contactInfo": {
              "email": "ray.sorensen@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://iowa.us20.list-manage.com/subscribe?u=bf8ea8a70bdafa528d6feb893&id=cf761d2cb3",
                "https://www.legis.iowa.gov/legislators/legislator?personID=27020"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-23-lower-ocd-person-97a4ef03-4b50-4fcd-9ebc-3d76482f2150": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/81d2bcf2-ab2f-43f3-9260-b527ae546092",
            "firstName": "Nelson Joel",
            "lastName": "Fry",
            "fullName": "Joel Fry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=9409",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-IA-24-lower-ocd-person-81d2bcf2-ab2f-43f3-9260-b527ae546092",
              "builtID": "ia-lower-24",
              "externalID": "ocd-person/81d2bcf2-ab2f-43f3-9260-b527ae546092",
              "geometry": null
            },
            "contactInfo": {
              "email": "joel.fry@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=9409",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=9409",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=9409",
                "https://iowahouserepublicans.com/members/joel-fry",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=9409&ga=89",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=9409&ga=90",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://www.legis.iowa.gov/legislators/legislator?personID=9409"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-24-lower-ocd-person-81d2bcf2-ab2f-43f3-9260-b527ae546092": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/831144f3-e67d-4706-a018-abec3c492269",
            "firstName": "Hans Christian",
            "lastName": "Wilz",
            "fullName": "Hans Wilz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33970",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-IA-25-lower-ocd-person-831144f3-e67d-4706-a018-abec3c492269",
              "builtID": "ia-lower-25",
              "externalID": "ocd-person/831144f3-e67d-4706-a018-abec3c492269",
              "geometry": null
            },
            "contactInfo": {
              "email": "hans.wilz@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33970&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33970"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-25-lower-ocd-person-831144f3-e67d-4706-a018-abec3c492269": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/3dea807e-b6b5-4531-a5d6-d51ae8811aca",
            "firstName": "Austin Rex",
            "lastName": "Harris",
            "fullName": "Austin Harris",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33971",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-IA-26-lower-ocd-person-3dea807e-b6b5-4531-a5d6-d51ae8811aca",
              "builtID": "ia-lower-26",
              "externalID": "ocd-person/3dea807e-b6b5-4531-a5d6-d51ae8811aca",
              "geometry": null
            },
            "contactInfo": {
              "email": "austin.harris@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33971&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33971"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-26-lower-ocd-person-3dea807e-b6b5-4531-a5d6-d51ae8811aca": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/1b7d56b0-f635-423c-9c09-5617322b1518",
            "firstName": "Kenan",
            "lastName": "Judge",
            "fullName": "Kenan Judge",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27027",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-IA-27-lower-ocd-person-1b7d56b0-f635-423c-9c09-5617322b1518",
              "builtID": "ia-lower-27",
              "externalID": "ocd-person/1b7d56b0-f635-423c-9c09-5617322b1518",
              "geometry": null
            },
            "contactInfo": {
              "email": "kenan.judge@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=27027",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=27027",
                "https://eepurl.com/gXSavj",
                "https://https://eepurl.com/gXSavj",
                "https://www.legis.iowa.gov/legislators/legislator?personID=27027"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-27-lower-ocd-person-1b7d56b0-f635-423c-9c09-5617322b1518": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/e0df02a4-7910-4538-8c3b-8152fb8f657c",
            "firstName": "David Edmund",
            "lastName": "Young",
            "fullName": "David Young",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-IA-28-lower-ocd-person-e0df02a4-7910-4538-8c3b-8152fb8f657c",
              "builtID": "ia-lower-28",
              "externalID": "ocd-person/e0df02a4-7910-4538-8c3b-8152fb8f657c",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.young@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33972&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33972"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-28-lower-ocd-person-e0df02a4-7910-4538-8c3b-8152fb8f657c": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/141dec61-26f0-4155-a86b-9b2179c9546c",
            "firstName": "Brian",
            "lastName": "Meyer",
            "fullName": "Brian Meyer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=6377",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-IA-29-lower-ocd-person-141dec61-26f0-4155-a86b-9b2179c9546c",
              "builtID": "ia-lower-29",
              "externalID": "ocd-person/141dec61-26f0-4155-a86b-9b2179c9546c",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.meyer@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=6377",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=6377",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=6377",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=6377&ga=89",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=6377&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=6377"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-29-lower-ocd-person-141dec61-26f0-4155-a86b-9b2179c9546c": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/84f3edbd-f0e1-4220-a670-f7d39d57bda5",
            "firstName": "Megan L.",
            "lastName": "Srinivas",
            "fullName": "Megan Srinivas",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33973",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-IA-30-lower-ocd-person-84f3edbd-f0e1-4220-a670-f7d39d57bda5",
              "builtID": "ia-lower-30",
              "externalID": "ocd-person/84f3edbd-f0e1-4220-a670-f7d39d57bda5",
              "geometry": null
            },
            "contactInfo": {
              "email": "megan.srinivas@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/ihe76f",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33973"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-30-lower-ocd-person-84f3edbd-f0e1-4220-a670-f7d39d57bda5": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/996db9b7-c251-478f-8852-0c5477013469",
            "firstName": "Mary L.",
            "lastName": "Madison",
            "fullName": "Mary Madison",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33974",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-IA-31-lower-ocd-person-996db9b7-c251-478f-8852-0c5477013469",
              "builtID": "ia-lower-31",
              "externalID": "ocd-person/996db9b7-c251-478f-8852-0c5477013469",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.madison@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33974&ga=90",
                "https://https://eepurl.com/ihfkwv",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33974"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-31-lower-ocd-person-996db9b7-c251-478f-8852-0c5477013469": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/522b14b8-b82f-4336-a7fb-a0f782d5509a",
            "firstName": "Jennifer",
            "lastName": "Konfrst",
            "fullName": "Jennifer Konfrst",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27026",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-IA-32-lower-ocd-person-522b14b8-b82f-4336-a7fb-a0f782d5509a",
              "builtID": "ia-lower-32",
              "externalID": "ocd-person/522b14b8-b82f-4336-a7fb-a0f782d5509a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jennifer.konfrst@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/gXUjrH",
                "https://www.legis.iowa.gov/legislators/legislator?personID=27026"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-32-lower-ocd-person-522b14b8-b82f-4336-a7fb-a0f782d5509a": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/dad6bcf1-d56a-4a10-a969-2980dd3582d8",
            "firstName": "Ruth Ann",
            "lastName": "Gaines",
            "fullName": "Ruth Ann Gaines",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=9413",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-IA-33-lower-ocd-person-dad6bcf1-d56a-4a10-a969-2980dd3582d8",
              "builtID": "ia-lower-33",
              "externalID": "ocd-person/dad6bcf1-d56a-4a10-a969-2980dd3582d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "ruthann.gaines@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=9413",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=9413",
                "https://eepurl.com/gXMYEr",
                "https://https://eepurl.com/gXMYEr",
                "https://www.legis.iowa.gov/legislators/legislator?personID=9413"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-33-lower-ocd-person-dad6bcf1-d56a-4a10-a969-2980dd3582d8": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/8db31cf2-7d0f-43dd-9245-2a298a5c9b37",
            "firstName": "Ako",
            "lastName": "Abdul-Samad",
            "fullName": "Ako Abdul-Samad",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=6284",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-IA-34-lower-ocd-person-8db31cf2-7d0f-43dd-9245-2a298a5c9b37",
              "builtID": "ia-lower-34",
              "externalID": "ocd-person/8db31cf2-7d0f-43dd-9245-2a298a5c9b37",
              "geometry": null
            },
            "contactInfo": {
              "email": "ako.abdul-samad@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=6284",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=6284",
                "https://eepurl.com/gXDK4D",
                "https://https://citizensforako.com",
                "https://https://eepurl.com/gXDK4D",
                "https://www.legis.iowa.gov/legislators/legislator?personID=6284"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-34-lower-ocd-person-8db31cf2-7d0f-43dd-9245-2a298a5c9b37": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/8ee06272-1977-4810-8c40-5d007fb31ac8",
            "firstName": "Sean",
            "lastName": "Bagniewski",
            "fullName": "Sean Bagniewski",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33975",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-IA-35-lower-ocd-person-8ee06272-1977-4810-8c40-5d007fb31ac8",
              "builtID": "ia-lower-35",
              "externalID": "ocd-person/8ee06272-1977-4810-8c40-5d007fb31ac8",
              "geometry": null
            },
            "contactInfo": {
              "email": "sean.bagniewski@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/ihfkvX",
                "https://https://eepurl.com/ihS4LT",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33975"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-35-lower-ocd-person-8ee06272-1977-4810-8c40-5d007fb31ac8": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/9aa42801-ba2e-4f2e-9448-cf5e6114fba7",
            "firstName": "Austin",
            "lastName": "Baeth",
            "fullName": "Austin Baeth",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33976",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-IA-36-lower-ocd-person-9aa42801-ba2e-4f2e-9448-cf5e6114fba7",
              "builtID": "ia-lower-36",
              "externalID": "ocd-person/9aa42801-ba2e-4f2e-9448-cf5e6114fba7",
              "geometry": null
            },
            "contactInfo": {
              "email": "austin.baeth@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/ihfkvT",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33976"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-36-lower-ocd-person-9aa42801-ba2e-4f2e-9448-cf5e6114fba7": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/5aa1870f-0eb7-4a82-9788-7b159d28a287",
            "firstName": "Barbara J.",
            "lastName": "Kniff McCulla",
            "fullName": "Barb Kniff McCulla",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33977",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-IA-37-lower-ocd-person-5aa1870f-0eb7-4a82-9788-7b159d28a287",
              "builtID": "ia-lower-37",
              "externalID": "ocd-person/5aa1870f-0eb7-4a82-9788-7b159d28a287",
              "geometry": null
            },
            "contactInfo": {
              "email": "barb.kniffmcculla@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33977&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33977"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-37-lower-ocd-person-5aa1870f-0eb7-4a82-9788-7b159d28a287": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/7a97018b-eb0e-46cf-8bd8-26520ff0d1e0",
            "firstName": "Jonathon",
            "lastName": "Dunwell",
            "fullName": "Jon Dunwell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=32165",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-IA-38-lower-ocd-person-7a97018b-eb0e-46cf-8bd8-26520ff0d1e0",
              "builtID": "ia-lower-38",
              "externalID": "ocd-person/7a97018b-eb0e-46cf-8bd8-26520ff0d1e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jon.dunwell@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://mailchi.mp/6f5fef3ed848/untitled-page",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=32165"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-38-lower-ocd-person-7a97018b-eb0e-46cf-8bd8-26520ff0d1e0": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/8ef407a5-2d80-4190-a656-a23d13fb5cb4",
            "firstName": "Rick L.",
            "lastName": "Olson",
            "fullName": "Rick Olson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=776",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-IA-39-lower-ocd-person-8ef407a5-2d80-4190-a656-a23d13fb5cb4",
              "builtID": "ia-lower-39",
              "externalID": "ocd-person/8ef407a5-2d80-4190-a656-a23d13fb5cb4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.olson@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=776",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=776",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=776",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=776&ga=89",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=776&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=776"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-39-lower-ocd-person-8ef407a5-2d80-4190-a656-a23d13fb5cb4": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/7ebd4346-a560-458d-b010-3ddd82d1cf38",
            "firstName": "Bill",
            "lastName": "Gustoff",
            "fullName": "Bill Gustoff",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33978",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-IA-40-lower-ocd-person-7ebd4346-a560-458d-b010-3ddd82d1cf38",
              "builtID": "ia-lower-40",
              "externalID": "ocd-person/7ebd4346-a560-458d-b010-3ddd82d1cf38",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.gustoff@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33978&ga=90",
                "https://https://eepurl.com/h6jOxr",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33978"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-40-lower-ocd-person-7ebd4346-a560-458d-b010-3ddd82d1cf38": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/1ca8e33b-4983-4e18-ab06-414f8b119916",
            "firstName": "Molly",
            "lastName": "Buck",
            "fullName": "Molly Buck",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33979",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-IA-41-lower-ocd-person-1ca8e33b-4983-4e18-ab06-414f8b119916",
              "builtID": "ia-lower-41",
              "externalID": "ocd-person/1ca8e33b-4983-4e18-ab06-414f8b119916",
              "geometry": null
            },
            "contactInfo": {
              "email": "molly.buck@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https:// https://eepurl.com/ihfkv1",
                "https://https://eepurl.com/ihfkv1",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33979"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-41-lower-ocd-person-1ca8e33b-4983-4e18-ab06-414f8b119916": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/ba713234-dc5e-4646-9f74-c25d84f648cc",
            "firstName": "Heather Ann",
            "lastName": "Matson",
            "fullName": "Heather Matson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27023",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-IA-42-lower-ocd-person-ba713234-dc5e-4646-9f74-c25d84f648cc",
              "builtID": "ia-lower-42",
              "externalID": "ocd-person/ba713234-dc5e-4646-9f74-c25d84f648cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "heather.matson@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/ihfkwz",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=27023"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-42-lower-ocd-person-ba713234-dc5e-4646-9f74-c25d84f648cc": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/258e0f31-d71a-42b5-98ff-3f2263f95f65",
            "firstName": "Eddie",
            "lastName": "Andrews",
            "fullName": "Eddie Andrews",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30650",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-IA-43-lower-ocd-person-258e0f31-d71a-42b5-98ff-3f2263f95f65",
              "builtID": "ia-lower-43",
              "externalID": "ocd-person/258e0f31-d71a-42b5-98ff-3f2263f95f65",
              "geometry": null
            },
            "contactInfo": {
              "email": "eddie.andrews@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=30650&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30650"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-43-lower-ocd-person-258e0f31-d71a-42b5-98ff-3f2263f95f65": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/0695ec27-a347-429b-8f47-ed8fcc2dfcf9",
            "firstName": "John",
            "lastName": "Forbes",
            "fullName": "John Forbes",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=10739",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-IA-44-lower-ocd-person-0695ec27-a347-429b-8f47-ed8fcc2dfcf9",
              "builtID": "ia-lower-44",
              "externalID": "ocd-person/0695ec27-a347-429b-8f47-ed8fcc2dfcf9",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.forbes@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/gZJhOn",
                "https://www.legis.iowa.gov/legislators/legislator?personID=10739"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-44-lower-ocd-person-0695ec27-a347-429b-8f47-ed8fcc2dfcf9": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/e0d66082-fd49-47ff-acd7-2f8106bc9837",
            "firstName": "Brian K.",
            "lastName": "Lohse",
            "fullName": "Brian Lohse",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27022",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-IA-45-lower-ocd-person-e0d66082-fd49-47ff-acd7-2f8106bc9837",
              "builtID": "ia-lower-45",
              "externalID": "ocd-person/e0d66082-fd49-47ff-acd7-2f8106bc9837",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.lohse@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://gop.us20.list-manage.com/subscribe?u=871f3d222fc2ae8e3dd8b683a&id=5f02846a39",
                "https://www.legis.iowa.gov/legislators/legislator?personID=27022"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-45-lower-ocd-person-e0d66082-fd49-47ff-acd7-2f8106bc9837": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/d9b64ebb-2332-4ab8-b21f-e697f08759d3",
            "firstName": "Dan",
            "lastName": "Gehlbach",
            "fullName": "Dan Gehlbach",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33980",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-IA-46-lower-ocd-person-d9b64ebb-2332-4ab8-b21f-e697f08759d3",
              "builtID": "ia-lower-46",
              "externalID": "ocd-person/d9b64ebb-2332-4ab8-b21f-e697f08759d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.gehlbach@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33980&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33980"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-46-lower-ocd-person-d9b64ebb-2332-4ab8-b21f-e697f08759d3": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/e7b268b8-c922-4e66-8327-cc4c49854d5e",
            "firstName": "Carter F.",
            "lastName": "Nordman",
            "fullName": "Carter Nordman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30647",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-IA-47-lower-ocd-person-e7b268b8-c922-4e66-8327-cc4c49854d5e",
              "builtID": "ia-lower-47",
              "externalID": "ocd-person/e7b268b8-c922-4e66-8327-cc4c49854d5e",
              "geometry": null
            },
            "contactInfo": {
              "email": "carter.nordman@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=30647",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=30647",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=30647&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30647"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-47-lower-ocd-person-e7b268b8-c922-4e66-8327-cc4c49854d5e": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/9eddb157-fee7-4b49-a7da-0f791731afcc",
            "firstName": "Phil",
            "lastName": "Thompson",
            "fullName": "Phil Thompson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27028",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-IA-48-lower-ocd-person-9eddb157-fee7-4b49-a7da-0f791731afcc",
              "builtID": "ia-lower-48",
              "externalID": "ocd-person/9eddb157-fee7-4b49-a7da-0f791731afcc",
              "geometry": null
            },
            "contactInfo": {
              "email": "phil.thompson@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=27028",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=27028",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=27028&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=27028"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-48-lower-ocd-person-9eddb157-fee7-4b49-a7da-0f791731afcc": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/5c955d7c-30ce-4930-9d96-588735b89dc2",
            "firstName": "Beth",
            "lastName": "Wessel-Kroeschell",
            "fullName": "Beth Wessel-Kroeschell",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=774",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-IA-49-lower-ocd-person-5c955d7c-30ce-4930-9d96-588735b89dc2",
              "builtID": "ia-lower-49",
              "externalID": "ocd-person/5c955d7c-30ce-4930-9d96-588735b89dc2",
              "geometry": null
            },
            "contactInfo": {
              "email": "beth.wessel-kroeschell@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=774",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=774",
                "https://eepurl.com/gXNo2T",
                "https://https://eepurl.com/gXNo2T",
                "https://www.legis.iowa.gov/legislators/legislator?personID=774"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-49-lower-ocd-person-5c955d7c-30ce-4930-9d96-588735b89dc2": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/068a7290-ab67-4ff5-abe5-8e23a7715097",
            "firstName": "Ross",
            "lastName": "Wilburn",
            "fullName": "Ross Wilburn",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=28701",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-IA-50-lower-ocd-person-068a7290-ab67-4ff5-abe5-8e23a7715097",
              "builtID": "ia-lower-50",
              "externalID": "ocd-person/068a7290-ab67-4ff5-abe5-8e23a7715097",
              "geometry": null
            },
            "contactInfo": {
              "email": "ross.wilburn@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/gXMQCb",
                "https://www.legis.iowa.gov/legislators/legislator?personID=28701"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-50-lower-ocd-person-068a7290-ab67-4ff5-abe5-8e23a7715097": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/e45ce672-d53b-443e-b434-b2e8b8d520b9",
            "firstName": "Dave",
            "lastName": "Deyoe",
            "fullName": "Dave Deyoe",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=6289",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-IA-51-lower-ocd-person-e45ce672-d53b-443e-b434-b2e8b8d520b9",
              "builtID": "ia-lower-51",
              "externalID": "ocd-person/e45ce672-d53b-443e-b434-b2e8b8d520b9",
              "geometry": null
            },
            "contactInfo": {
              "email": "dave.deyoe@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=6289",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=6289",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=6289",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=6289&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=6289"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-51-lower-ocd-person-e45ce672-d53b-443e-b434-b2e8b8d520b9": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/35c4a2a8-5d8c-4df4-9ca8-c223f5c41a47",
            "firstName": "Susan Koenig",
            "lastName": "Cahill",
            "fullName": "Sue Cahill",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30660",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-IA-52-lower-ocd-person-35c4a2a8-5d8c-4df4-9ca8-c223f5c41a47",
              "builtID": "ia-lower-52",
              "externalID": "ocd-person/35c4a2a8-5d8c-4df4-9ca8-c223f5c41a47",
              "geometry": null
            },
            "contactInfo": {
              "email": "sue.cahill@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=30660",
                "https://eepurl.com/gXMTdD",
                "https://https://eepurl.com/gXMTdD",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30660"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-52-lower-ocd-person-35c4a2a8-5d8c-4df4-9ca8-c223f5c41a47": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/a4832927-060d-4e15-82d7-795f6f9cc78f",
            "firstName": "Dean C.",
            "lastName": "Fisher",
            "fullName": "Dean Fisher",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=10755",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-IA-53-lower-ocd-person-a4832927-060d-4e15-82d7-795f6f9cc78f",
              "builtID": "ia-lower-53",
              "externalID": "ocd-person/a4832927-060d-4e15-82d7-795f6f9cc78f",
              "geometry": null
            },
            "contactInfo": {
              "email": "dean.fisher@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=10755",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=10755",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=10755&ga=89",
                "https://www.deanfisher.com",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=10755&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=10755"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-53-lower-ocd-person-a4832927-060d-4e15-82d7-795f6f9cc78f": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/b28ad297-8f6d-4f36-807e-0acfa35c7d4c",
            "firstName": "Joshua",
            "lastName": "Meggers",
            "fullName": "Josh Meggers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33981",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-IA-54-lower-ocd-person-b28ad297-8f6d-4f36-807e-0acfa35c7d4c",
              "builtID": "ia-lower-54",
              "externalID": "ocd-person/b28ad297-8f6d-4f36-807e-0acfa35c7d4c",
              "geometry": null
            },
            "contactInfo": {
              "email": "joshua.meggers@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33981&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33981"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-54-lower-ocd-person-b28ad297-8f6d-4f36-807e-0acfa35c7d4c": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/90ea16da-2e10-470e-a004-a5e2688e6331",
            "firstName": "Shannon Fesenmeyer",
            "lastName": "Latham",
            "fullName": "Shannon Latham",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30651",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-IA-55-lower-ocd-person-90ea16da-2e10-470e-a004-a5e2688e6331",
              "builtID": "ia-lower-55",
              "externalID": "ocd-person/90ea16da-2e10-470e-a004-a5e2688e6331",
              "geometry": null
            },
            "contactInfo": {
              "email": "shannon.latham@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://lathamforiowa.us7.list-manage.com/subscribe?u=6721a2b3f29f131aa167a1a92&id=80ffe32ef8",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30651"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-55-lower-ocd-person-90ea16da-2e10-470e-a004-a5e2688e6331": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/967868e2-3ec5-425f-bc96-f41208809217",
            "firstName": "Mark I.",
            "lastName": "Thompson",
            "fullName": "Mark Thompson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33982",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-IA-56-lower-ocd-person-967868e2-3ec5-425f-bc96-f41208809217",
              "builtID": "ia-lower-56",
              "externalID": "ocd-person/967868e2-3ec5-425f-bc96-f41208809217",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.thompson@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33982&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33982"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-56-lower-ocd-person-967868e2-3ec5-425f-bc96-f41208809217": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/43530aa9-bb74-4e1b-845c-e9491c9a410c",
            "firstName": "Patrick",
            "lastName": "Grassley",
            "fullName": "Pat Grassley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=6277",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-IA-57-lower-ocd-person-43530aa9-bb74-4e1b-845c-e9491c9a410c",
              "builtID": "ia-lower-57",
              "externalID": "ocd-person/43530aa9-bb74-4e1b-845c-e9491c9a410c",
              "geometry": null
            },
            "contactInfo": {
              "email": "pat.grassley@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=6277",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=6277",
                "https://iowahouserepublicans.com/members/pat-grassley",
                "https://https://mailchi.mp/78e98d00d91a/sign-up-for-my-newsletter",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://twitter.com/PatGrassley",
                "https://www.legis.iowa.gov/legislators/legislator?personID=6277",
                "https://iowahouserepublicans.nationbuilder.com/Grassley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-57-lower-ocd-person-43530aa9-bb74-4e1b-845c-e9491c9a410c": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/1a5372b1-e23d-4877-a614-a32f552c58b4",
            "firstName": "Charles M.",
            "lastName": "Thomson",
            "fullName": "Charley Thomson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33983",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-IA-58-lower-ocd-person-1a5372b1-e23d-4877-a614-a32f552c58b4",
              "builtID": "ia-lower-58",
              "externalID": "ocd-person/1a5372b1-e23d-4877-a614-a32f552c58b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "charley.thomson@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33983&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33983"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-58-lower-ocd-person-1a5372b1-e23d-4877-a614-a32f552c58b4": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/727a56e6-e37a-424a-bb2f-bebec45f183e",
            "firstName": "Sharon Sue",
            "lastName": "Steckman",
            "fullName": "Sharon Steckman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=6580",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-IA-59-lower-ocd-person-727a56e6-e37a-424a-bb2f-bebec45f183e",
              "builtID": "ia-lower-59",
              "externalID": "ocd-person/727a56e6-e37a-424a-bb2f-bebec45f183e",
              "geometry": null
            },
            "contactInfo": {
              "email": "sharon.steckman@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=6580",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=6580",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=6580",
                "https://eepurl.com/gXMNlX",
                "https://https://eepurl.com/gXMNlX",
                "https://www.legis.iowa.gov/legislators/legislator?personID=6580"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-59-lower-ocd-person-727a56e6-e37a-424a-bb2f-bebec45f183e": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/37952941-d329-4c96-8458-654fa495ec76",
            "firstName": "Jane",
            "lastName": "Bloomingdale",
            "fullName": "Jane Bloomingdale",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=18043",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-IA-60-lower-ocd-person-37952941-d329-4c96-8458-654fa495ec76",
              "builtID": "ia-lower-60",
              "externalID": "ocd-person/37952941-d329-4c96-8458-654fa495ec76",
              "geometry": null
            },
            "contactInfo": {
              "email": "jane.bloomingdale@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=18043",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=18043",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=18043&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=18043"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-60-lower-ocd-person-37952941-d329-4c96-8458-654fa495ec76": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/7467e822-4c62-4cd2-b8e1-6f72a9204fce",
            "firstName": "Timi",
            "lastName": "Brown-Powers",
            "fullName": "Timi Brown-Powers",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=14805",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-IA-61-lower-ocd-person-7467e822-4c62-4cd2-b8e1-6f72a9204fce",
              "builtID": "ia-lower-61",
              "externalID": "ocd-person/7467e822-4c62-4cd2-b8e1-6f72a9204fce",
              "geometry": null
            },
            "contactInfo": {
              "email": "timi.brown-powers@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=14805",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=14805",
                "https://www.timiforiowa.com/",
                "https://eepurl.com/gXSzO1",
                "https://https://www.timiforiowa.com/",
                "https://https://eepurl.com/gXSzO1",
                "https://www.legis.iowa.gov/legislators/legislator?personID=14805"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-61-lower-ocd-person-7467e822-4c62-4cd2-b8e1-6f72a9204fce": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/d7083428-89b1-4a56-a4c8-4a40e80e30e8",
            "firstName": "Jerome",
            "lastName": "Amos",
            "fullName": "Jerome Amos",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=6174",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-IA-62-lower-ocd-person-d7083428-89b1-4a56-a4c8-4a40e80e30e8",
              "builtID": "ia-lower-62",
              "externalID": "ocd-person/d7083428-89b1-4a56-a4c8-4a40e80e30e8",
              "geometry": null
            },
            "contactInfo": {
              "email": "jerome.amosjr@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/ihfkvP",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=6174"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-62-lower-ocd-person-d7083428-89b1-4a56-a4c8-4a40e80e30e8": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/bf9a43c8-3e2a-489f-94f6-b26035f7f9b9",
            "firstName": "Michael R.",
            "lastName": "Bergan",
            "fullName": "Michael Bergan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=18044",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-IA-63-lower-ocd-person-bf9a43c8-3e2a-489f-94f6-b26035f7f9b9",
              "builtID": "ia-lower-63",
              "externalID": "ocd-person/bf9a43c8-3e2a-489f-94f6-b26035f7f9b9",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.bergan@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=18044",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=18044",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://iowa.us4.list-manage.com/subscribe?u=cda8d4cec739450efbb093a0a&id=09e9bff453",
                "https://www.legis.iowa.gov/legislators/legislator?personID=18044"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-63-lower-ocd-person-bf9a43c8-3e2a-489f-94f6-b26035f7f9b9": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/5afd80c1-3f96-4ccc-92b1-19bc26d72f4e",
            "firstName": "Anne",
            "lastName": "Osmundson",
            "fullName": "Anne Osmundson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27029",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-IA-64-lower-ocd-person-5afd80c1-3f96-4ccc-92b1-19bc26d72f4e",
              "builtID": "ia-lower-64",
              "externalID": "ocd-person/5afd80c1-3f96-4ccc-92b1-19bc26d72f4e",
              "geometry": null
            },
            "contactInfo": {
              "email": "anne.osmundson@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://iowa.us20.list-manage.com/subscribe?u=82adcd7732186b6e52ba50850&id=49cf8b4b46",
                "https://www.legis.iowa.gov/legislators/legislator?personID=27029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-64-lower-ocd-person-5afd80c1-3f96-4ccc-92b1-19bc26d72f4e": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/82dcdad5-7974-4b38-a44c-7007e8111a5c",
            "firstName": "Shannon Fluhr",
            "lastName": "Lundgren",
            "fullName": "Shannon Lundgren",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=18046",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-IA-65-lower-ocd-person-82dcdad5-7974-4b38-a44c-7007e8111a5c",
              "builtID": "ia-lower-65",
              "externalID": "ocd-person/82dcdad5-7974-4b38-a44c-7007e8111a5c",
              "geometry": null
            },
            "contactInfo": {
              "email": "shannon.lundgren@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://facebook.us14.list-manage.com/subscribe?u=2aece3f2f1c3b4cc01a0cc692&id=8fc149e435",
                "https://www.legis.iowa.gov/legislators/legislator?personID=18046"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-65-lower-ocd-person-82dcdad5-7974-4b38-a44c-7007e8111a5c": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/fca5c5d4-a5b0-40eb-ace9-24dfd50bc4a6",
            "firstName": "Steven P.",
            "lastName": "Bradley",
            "fullName": "Steve Bradley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30652",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-IA-66-lower-ocd-person-fca5c5d4-a5b0-40eb-ace9-24dfd50bc4a6",
              "builtID": "ia-lower-66",
              "externalID": "ocd-person/fca5c5d4-a5b0-40eb-ace9-24dfd50bc4a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.bradley@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=30652",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=30652",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=30652&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30652"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-66-lower-ocd-person-fca5c5d4-a5b0-40eb-ace9-24dfd50bc4a6": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/63ec798f-401b-4512-9f56-b162eb96bfe9",
            "firstName": "Craig Paul",
            "lastName": "Johnson",
            "fullName": "Craig Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=18075",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-IA-67-lower-ocd-person-63ec798f-401b-4512-9f56-b162eb96bfe9",
              "builtID": "ia-lower-67",
              "externalID": "ocd-person/63ec798f-401b-4512-9f56-b162eb96bfe9",
              "geometry": null
            },
            "contactInfo": {
              "email": "craig.johnson@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=18075",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=18075",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=18075",
                "https://www.iowasenaterepublicans.com/senators/craig-johnson/",
                "https://www.iowasenaterepublicans.com/senators/craig-johnson/",
                "https://https://iowahouserepublicans.nationbuilder.com/Johnson",
                "https://https://eepurl.com/gSVn0f",
                "https://www.legis.iowa.gov/legislators/legislator?personID=18075"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-67-lower-ocd-person-63ec798f-401b-4512-9f56-b162eb96bfe9": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/623b4ac1-3411-4109-b0eb-31bec2780313",
            "firstName": "Chad",
            "lastName": "Ingels",
            "fullName": "Chad Ingels",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30653",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-IA-68-lower-ocd-person-623b4ac1-3411-4109-b0eb-31bec2780313",
              "builtID": "ia-lower-68",
              "externalID": "ocd-person/623b4ac1-3411-4109-b0eb-31bec2780313",
              "geometry": null
            },
            "contactInfo": {
              "email": "chad.ingels@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=30653",
                "https://https://iowa.us7.list-manage.com/subscribe?u=2ab7b475cd3c7661510125056&id=4b2e51f33d",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30653"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-68-lower-ocd-person-623b4ac1-3411-4109-b0eb-31bec2780313": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/264c5cb9-f69f-4d07-ac58-231c98915233",
            "firstName": "Tom",
            "lastName": "Determann",
            "fullName": "Tom Determann",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33984",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-IA-69-lower-ocd-person-264c5cb9-f69f-4d07-ac58-231c98915233",
              "builtID": "ia-lower-69",
              "externalID": "ocd-person/264c5cb9-f69f-4d07-ac58-231c98915233",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.determann@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33984&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33984"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-69-lower-ocd-person-264c5cb9-f69f-4d07-ac58-231c98915233": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/e8a08a29-2cc5-4b0b-a461-61983aa0771c",
            "firstName": "Norlin G.",
            "lastName": "Mommsen",
            "fullName": "Norlin Mommsen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=14810",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-IA-70-lower-ocd-person-e8a08a29-2cc5-4b0b-a461-61983aa0771c",
              "builtID": "ia-lower-70",
              "externalID": "ocd-person/e8a08a29-2cc5-4b0b-a461-61983aa0771c",
              "geometry": null
            },
            "contactInfo": {
              "email": "norlin.mommsen@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=14810",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=14810&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=14810"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-70-lower-ocd-person-e8a08a29-2cc5-4b0b-a461-61983aa0771c": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/2f43be90-4912-41fa-acf3-735e3a9ef557",
            "firstName": "Lindsay",
            "lastName": "James",
            "fullName": "Lindsay James",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27039",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-IA-71-lower-ocd-person-2f43be90-4912-41fa-acf3-735e3a9ef557",
              "builtID": "ia-lower-71",
              "externalID": "ocd-person/2f43be90-4912-41fa-acf3-735e3a9ef557",
              "geometry": null
            },
            "contactInfo": {
              "email": "lindsay.james@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/gXSDkT",
                "https://www.legis.iowa.gov/legislators/legislator?personID=27039"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-71-lower-ocd-person-2f43be90-4912-41fa-acf3-735e3a9ef557": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/b7f99d63-12f8-46a7-a96b-a3d91e05592e",
            "firstName": "Charles William",
            "lastName": "Isenhart",
            "fullName": "Charles Isenhart",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=6575",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-IA-72-lower-ocd-person-b7f99d63-12f8-46a7-a96b-a3d91e05592e",
              "builtID": "ia-lower-72",
              "externalID": "ocd-person/b7f99d63-12f8-46a7-a96b-a3d91e05592e",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.isenhart@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=6575",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=6575",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=6575&ga=89",
                "https://https://www.commongoodcampaign.com",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=6575&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=6575"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-72-lower-ocd-person-b7f99d63-12f8-46a7-a96b-a3d91e05592e": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/75ee3e5c-bf95-4f36-8366-264872631c3c",
            "firstName": "Elizabeth",
            "lastName": "Wilson",
            "fullName": "Elizabeth Wilson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33985",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-IA-73-lower-ocd-person-75ee3e5c-bf95-4f36-8366-264872631c3c",
              "builtID": "ia-lower-73",
              "externalID": "ocd-person/75ee3e5c-bf95-4f36-8366-264872631c3c",
              "geometry": null
            },
            "contactInfo": {
              "email": "elizabeth.wilson@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/ihOgIP",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33985"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-73-lower-ocd-person-75ee3e5c-bf95-4f36-8366-264872631c3c": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/8e424b53-9531-4b76-bd44-a8008f34b539",
            "firstName": "Eric",
            "lastName": "Gjerde",
            "fullName": "Eric Gjerde",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30659",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-IA-74-lower-ocd-person-8e424b53-9531-4b76-bd44-a8008f34b539",
              "builtID": "ia-lower-74",
              "externalID": "ocd-person/8e424b53-9531-4b76-bd44-a8008f34b539",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.gjerde@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/hn8I8P",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30659"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-74-lower-ocd-person-8e424b53-9531-4b76-bd44-a8008f34b539": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/bdd055a7-677f-4df3-ba42-72c09d75a2ad",
            "firstName": "Bob M.",
            "lastName": "Kressig",
            "fullName": "Bob Kressig",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=781",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-IA-75-lower-ocd-person-bdd055a7-677f-4df3-ba42-72c09d75a2ad",
              "builtID": "ia-lower-75",
              "externalID": "ocd-person/bdd055a7-677f-4df3-ba42-72c09d75a2ad",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.kressig@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://iowa.us19.list-manage.com/subscribe?u=9f68e4c04f9646e4baeec6d78&id=77ff2ca7e5",
                "https://www.legis.iowa.gov/legislators/legislator?personID=781"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-75-lower-ocd-person-bdd055a7-677f-4df3-ba42-72c09d75a2ad": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/5dbb36a6-f191-4201-9cbe-20d7aada4d4c",
            "firstName": "Derek",
            "lastName": "Wulf",
            "fullName": "Derek Wulf",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33986",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-IA-76-lower-ocd-person-5dbb36a6-f191-4201-9cbe-20d7aada4d4c",
              "builtID": "ia-lower-76",
              "externalID": "ocd-person/5dbb36a6-f191-4201-9cbe-20d7aada4d4c",
              "geometry": null
            },
            "contactInfo": {
              "email": "derek.wulf@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33986&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33986"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-76-lower-ocd-person-5dbb36a6-f191-4201-9cbe-20d7aada4d4c": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/c8eeda58-6c95-4ac9-988f-1c9363b2e17c",
            "firstName": "Jeff",
            "lastName": "Cooling",
            "fullName": "Jeff Cooling",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33987",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-IA-77-lower-ocd-person-c8eeda58-6c95-4ac9-988f-1c9363b2e17c",
              "builtID": "ia-lower-77",
              "externalID": "ocd-person/c8eeda58-6c95-4ac9-988f-1c9363b2e17c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.cooling@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/ihfkv5",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33987"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-77-lower-ocd-person-c8eeda58-6c95-4ac9-988f-1c9363b2e17c": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/2dc8d5c9-4ded-4e1a-9e0c-58b4de26f4f9",
            "firstName": "Sami",
            "lastName": "Scheetz",
            "fullName": "Sami Scheetz",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33988",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-IA-78-lower-ocd-person-2dc8d5c9-4ded-4e1a-9e0c-58b4de26f4f9",
              "builtID": "ia-lower-78",
              "externalID": "ocd-person/2dc8d5c9-4ded-4e1a-9e0c-58b4de26f4f9",
              "geometry": null
            },
            "contactInfo": {
              "email": "sami.scheetz@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/ihfkwD",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33988"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-78-lower-ocd-person-2dc8d5c9-4ded-4e1a-9e0c-58b4de26f4f9": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/0a7675f8-869f-4d49-9a14-c44201e8a8dc",
            "firstName": "Tracy",
            "lastName": "Ehlert",
            "fullName": "Tracy Ehlert",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27032",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-IA-79-lower-ocd-person-0a7675f8-869f-4d49-9a14-c44201e8a8dc",
              "builtID": "ia-lower-79",
              "externalID": "ocd-person/0a7675f8-869f-4d49-9a14-c44201e8a8dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "tracy.ehlert@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=27032",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=27032",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=27032",
                "https://eepurl.com/gXOgt9",
                "https://https://eepurl.com/gXOgt9",
                "https://www.legis.iowa.gov/legislators/legislator?personID=27032"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-79-lower-ocd-person-0a7675f8-869f-4d49-9a14-c44201e8a8dc": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/57925a0b-3010-440d-a012-c8465535c13e",
            "firstName": "Art",
            "lastName": "Staed",
            "fullName": "Art Staed",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=10743",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-IA-80-lower-ocd-person-57925a0b-3010-440d-a012-c8465535c13e",
              "builtID": "ia-lower-80",
              "externalID": "ocd-person/57925a0b-3010-440d-a012-c8465535c13e",
              "geometry": null
            },
            "contactInfo": {
              "email": "art.staed@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=10743",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=10743",
                "https://eepurl.com/gXSMIH",
                "https://https://eepurl.com/gXSMIH",
                "https://www.legis.iowa.gov/legislators/legislator?personID=10743"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-80-lower-ocd-person-57925a0b-3010-440d-a012-c8465535c13e": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/20eca0ac-c86e-4162-95d4-c4d8809cf9aa",
            "firstName": "Luana",
            "lastName": "Stoltenberg",
            "fullName": "Luana Stoltenberg",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33989",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-IA-81-lower-ocd-person-20eca0ac-c86e-4162-95d4-c4d8809cf9aa",
              "builtID": "ia-lower-81",
              "externalID": "ocd-person/20eca0ac-c86e-4162-95d4-c4d8809cf9aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "luana.stoltenberg@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33989&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33989"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-81-lower-ocd-person-20eca0ac-c86e-4162-95d4-c4d8809cf9aa": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/657c7d2d-2444-4809-93fb-d1701d77f528",
            "firstName": "Bobby",
            "lastName": "Kaufmann",
            "fullName": "Bobby Kaufmann",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=10756",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-IA-82-lower-ocd-person-657c7d2d-2444-4809-93fb-d1701d77f528",
              "builtID": "ia-lower-82",
              "externalID": "ocd-person/657c7d2d-2444-4809-93fb-d1701d77f528",
              "geometry": null
            },
            "contactInfo": {
              "email": "bobby.kaufmann@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=10756&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=10756"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-82-lower-ocd-person-657c7d2d-2444-4809-93fb-d1701d77f528": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/6729badd-57a5-484c-bb4c-23afa6e96cdf",
            "firstName": "Cindy",
            "lastName": "Golding",
            "fullName": "Cindy Golding",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33990",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-IA-83-lower-ocd-person-6729badd-57a5-484c-bb4c-23afa6e96cdf",
              "builtID": "ia-lower-83",
              "externalID": "ocd-person/6729badd-57a5-484c-bb4c-23afa6e96cdf",
              "geometry": null
            },
            "contactInfo": {
              "email": "cindy.golding@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33990&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33990"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-83-lower-ocd-person-6729badd-57a5-484c-bb4c-23afa6e96cdf": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/ed333b61-6734-49a2-b37d-a167f330bf09",
            "firstName": "Thomas D.",
            "lastName": "Gerhold",
            "fullName": "Tom Gerhold",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27033",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-IA-84-lower-ocd-person-ed333b61-6734-49a2-b37d-a167f330bf09",
              "builtID": "ia-lower-84",
              "externalID": "ocd-person/ed333b61-6734-49a2-b37d-a167f330bf09",
              "geometry": null
            },
            "contactInfo": {
              "email": "thomas.gerhold@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=27033",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=27033",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=27033&ga=89",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=27033&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=27033"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-84-lower-ocd-person-ed333b61-6734-49a2-b37d-a167f330bf09": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/0ed1d7f1-1471-4055-b674-582bf19c28b3",
            "firstName": "Amy",
            "lastName": "Nielsen",
            "fullName": "Amy Nielsen",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=18050",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-IA-85-lower-ocd-person-0ed1d7f1-1471-4055-b674-582bf19c28b3",
              "builtID": "ia-lower-85",
              "externalID": "ocd-person/0ed1d7f1-1471-4055-b674-582bf19c28b3",
              "geometry": null
            },
            "contactInfo": {
              "email": "amy.nielsen@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/gXNTL1",
                "https://www.legis.iowa.gov/legislators/legislator?personID=18050"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-85-lower-ocd-person-0ed1d7f1-1471-4055-b674-582bf19c28b3": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/55c46119-e6fa-4b22-ade6-18990aeacf0c",
            "firstName": "David",
            "lastName": "Jacoby",
            "fullName": "Dave Jacoby",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=320",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-IA-86-lower-ocd-person-55c46119-e6fa-4b22-ade6-18990aeacf0c",
              "builtID": "ia-lower-86",
              "externalID": "ocd-person/55c46119-e6fa-4b22-ade6-18990aeacf0c",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.jacoby@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/gXMR_X",
                "https://www.legis.iowa.gov/legislators/legislator?personID=320"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-86-lower-ocd-person-55c46119-e6fa-4b22-ade6-18990aeacf0c": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/11ceb3de-a680-4dc0-b140-edd37f04e75b",
            "firstName": "Jeff",
            "lastName": "Shipley",
            "fullName": "Jeff Shipley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27036",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-IA-87-lower-ocd-person-11ceb3de-a680-4dc0-b140-edd37f04e75b",
              "builtID": "ia-lower-87",
              "externalID": "ocd-person/11ceb3de-a680-4dc0-b140-edd37f04e75b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.shipley@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=27036",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=27036",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=27036&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?personID=27036"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-87-lower-ocd-person-11ceb3de-a680-4dc0-b140-edd37f04e75b": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/64d7c18c-3a40-4a0c-a5b9-51ab85b37c81",
            "firstName": "Helena",
            "lastName": "Hayes",
            "fullName": "Helena Hayes",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33991",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-IA-88-lower-ocd-person-64d7c18c-3a40-4a0c-a5b9-51ab85b37c81",
              "builtID": "ia-lower-88",
              "externalID": "ocd-person/64d7c18c-3a40-4a0c-a5b9-51ab85b37c81",
              "geometry": null
            },
            "contactInfo": {
              "email": "helena.hayes@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33991&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33991"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-88-lower-ocd-person-64d7c18c-3a40-4a0c-a5b9-51ab85b37c81": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/6ce3e40a-3eee-4a3f-acf3-ddee38e37e6f",
            "firstName": "Elinor A.",
            "lastName": "Levin",
            "fullName": "Elinor Levin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33992",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-IA-89-lower-ocd-person-6ce3e40a-3eee-4a3f-acf3-ddee38e37e6f",
              "builtID": "ia-lower-89",
              "externalID": "ocd-person/6ce3e40a-3eee-4a3f-acf3-ddee38e37e6f",
              "geometry": null
            },
            "contactInfo": {
              "email": "elinor.levin@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/ihfkwn",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33992"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-89-lower-ocd-person-6ce3e40a-3eee-4a3f-acf3-ddee38e37e6f": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/c29c02a4-8429-4846-bc19-817f545f3935",
            "firstName": "Adam",
            "lastName": "Zabner",
            "fullName": "Adam Zabner",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33993",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-IA-90-lower-ocd-person-c29c02a4-8429-4846-bc19-817f545f3935",
              "builtID": "ia-lower-90",
              "externalID": "ocd-person/c29c02a4-8429-4846-bc19-817f545f3935",
              "geometry": null
            },
            "contactInfo": {
              "email": "adam.zabner@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/ihe76H",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33993"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-90-lower-ocd-person-c29c02a4-8429-4846-bc19-817f545f3935": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/af3a9f61-f7aa-4b0a-a6d1-fe72417c90db",
            "firstName": "Brad",
            "lastName": "Sherman",
            "fullName": "Brad Sherman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33994",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-IA-91-lower-ocd-person-af3a9f61-f7aa-4b0a-a6d1-fe72417c90db",
              "builtID": "ia-lower-91",
              "externalID": "ocd-person/af3a9f61-f7aa-4b0a-a6d1-fe72417c90db",
              "geometry": null
            },
            "contactInfo": {
              "email": "brad.sherman@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33994&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33994"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-91-lower-ocd-person-af3a9f61-f7aa-4b0a-a6d1-fe72417c90db": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/e927adb7-9b5c-41a8-8daf-c9a8620741f5",
            "firstName": "Heather",
            "lastName": "Hora",
            "fullName": "Heather Hora",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33995",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-IA-92-lower-ocd-person-e927adb7-9b5c-41a8-8daf-c9a8620741f5",
              "builtID": "ia-lower-92",
              "externalID": "ocd-person/e927adb7-9b5c-41a8-8daf-c9a8620741f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "heather.hora@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33995&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33995"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-92-lower-ocd-person-e927adb7-9b5c-41a8-8daf-c9a8620741f5": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/98672aab-5ba5-4994-964a-f53d8bcdd108",
            "firstName": "Gary",
            "lastName": "Mohr",
            "fullName": "Gary Mohr",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=18038",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-IA-93-lower-ocd-person-98672aab-5ba5-4994-964a-f53d8bcdd108",
              "builtID": "ia-lower-93",
              "externalID": "ocd-person/98672aab-5ba5-4994-964a-f53d8bcdd108",
              "geometry": null
            },
            "contactInfo": {
              "email": "gary.mohr@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=18038",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=18038",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://facebook.us4.list-manage.com/subscribe?u=4f656da8e0485e1a4a8032e0f&id=f0dd08c637",
                "https://www.legis.iowa.gov/legislators/legislator?personID=18038"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-93-lower-ocd-person-98672aab-5ba5-4994-964a-f53d8bcdd108": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/4416e207-bc39-4dee-b6fc-7e70e351aa54",
            "firstName": "Mike",
            "lastName": "Vondran",
            "fullName": "Mike Vondran",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33996",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-IA-94-lower-ocd-person-4416e207-bc39-4dee-b6fc-7e70e351aa54",
              "builtID": "ia-lower-94",
              "externalID": "ocd-person/4416e207-bc39-4dee-b6fc-7e70e351aa54",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.vondran@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33996&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33996"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-94-lower-ocd-person-4416e207-bc39-4dee-b6fc-7e70e351aa54": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/fb3a8905-a9a9-4016-97b3-94ce90308240",
            "firstName": "Taylor R.",
            "lastName": "Collins",
            "fullName": "Taylor Collins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27618",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-IA-95-lower-ocd-person-fb3a8905-a9a9-4016-97b3-94ce90308240",
              "builtID": "ia-lower-95",
              "externalID": "ocd-person/fb3a8905-a9a9-4016-97b3-94ce90308240",
              "geometry": null
            },
            "contactInfo": {
              "email": "taylor.collins@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=27618&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=27618"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-95-lower-ocd-person-fb3a8905-a9a9-4016-97b3-94ce90308240": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/277c38cf-3dc5-419b-b072-df34adc214e6",
            "firstName": "Mark A.",
            "lastName": "Cisneros",
            "fullName": "Mark Cisneros",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30656",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-IA-96-lower-ocd-person-277c38cf-3dc5-419b-b072-df34adc214e6",
              "builtID": "ia-lower-96",
              "externalID": "ocd-person/277c38cf-3dc5-419b-b072-df34adc214e6",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.cisneros@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=30656",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://gmail.us7.list-manage.com/subscribe?u=85fcfa7dcbbb7c52a233de778&id=e13efc9ee4",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30656"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-96-lower-ocd-person-277c38cf-3dc5-419b-b072-df34adc214e6": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/3dd459aa-4a08-4a98-bce4-37536a9414fa",
            "firstName": "Ken",
            "lastName": "Croken",
            "fullName": "Ken Croken",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33997",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-IA-97-lower-ocd-person-3dd459aa-4a08-4a98-bce4-37536a9414fa",
              "builtID": "ia-lower-97",
              "externalID": "ocd-person/3dd459aa-4a08-4a98-bce4-37536a9414fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.croken@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://eepurl.com/ihfkwb",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33997"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-97-lower-ocd-person-3dd459aa-4a08-4a98-bce4-37536a9414fa": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/c746f92f-f2d1-4dd9-aa76-f056fe026c32",
            "firstName": "Monica Hosch",
            "lastName": "Kurth",
            "fullName": "Monica Kurth",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=18614",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-IA-98-lower-ocd-person-c746f92f-f2d1-4dd9-aa76-f056fe026c32",
              "builtID": "ia-lower-98",
              "externalID": "ocd-person/c746f92f-f2d1-4dd9-aa76-f056fe026c32",
              "geometry": null
            },
            "contactInfo": {
              "email": "monica.kurth@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=18614",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=18614",
                "https://eepurl.com/gXNhsX",
                "https://https://eepurl.com/gXNhsX",
                "https://www.legis.iowa.gov/legislators/legislator?personID=18614"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-98-lower-ocd-person-c746f92f-f2d1-4dd9-aa76-f056fe026c32": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/aec12ca2-6ad3-4026-9f01-7a691566c6fc",
            "firstName": "Matthew B.",
            "lastName": "Rinker",
            "fullName": "Matt Rinker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33998",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-IA-99-lower-ocd-person-aec12ca2-6ad3-4026-9f01-7a691566c6fc",
              "builtID": "ia-lower-99",
              "externalID": "ocd-person/aec12ca2-6ad3-4026-9f01-7a691566c6fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.rinker@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=33998&ga=90",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33998"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-99-lower-ocd-person-aec12ca2-6ad3-4026-9f01-7a691566c6fc": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/d7d6f3fb-432f-4ade-8810-46c56c6da333",
            "firstName": "Martin",
            "lastName": "Graber",
            "fullName": "Martin Graber",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30655",
            "title": "IA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-IA-100-lower-ocd-person-d7d6f3fb-432f-4ade-8810-46c56c6da333",
              "builtID": "ia-lower-100",
              "externalID": "ocd-person/d7d6f3fb-432f-4ade-8810-46c56c6da333",
              "geometry": null
            },
            "contactInfo": {
              "email": "martin.graber@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=30655",
                "https://https://iowahouserepublicans.nationbuilder.com/reps",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=30655&ga=90",
                "https://https://iowahouserepublicans.nationbuilder.com/Graber",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30655"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-IA-100-lower-ocd-person-d7d6f3fb-432f-4ade-8810-46c56c6da333": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/015c7221-f9fd-4d8b-9a93-c460855365d5",
            "firstName": "Rocky",
            "lastName": "De Witt",
            "fullName": "Rocky De Witt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=34001",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-IA-1-upper-ocd-person-015c7221-f9fd-4d8b-9a93-c460855365d5",
              "builtID": "ia-upper-1",
              "externalID": "ocd-person/015c7221-f9fd-4d8b-9a93-c460855365d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rocky.dewitt@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.iowasenaterepublicans.com/senators/rocky-de-witt/",
                "https://https://www.iowasenaterepublicans.com/senators/rocky-de-witt/",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=34001",
                "https://iowasenaterepublicans.com/senators/rocky-de-witt/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-1-upper-ocd-person-015c7221-f9fd-4d8b-9a93-c460855365d5": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/cc1cff7e-82d6-4947-a906-f467b4017285",
            "firstName": "Jeffrey",
            "lastName": "Taylor",
            "fullName": "Jeff Taylor",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30544",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-IA-2-upper-ocd-person-cc1cff7e-82d6-4947-a906-f467b4017285",
              "builtID": "ia-upper-2",
              "externalID": "ocd-person/cc1cff7e-82d6-4947-a906-f467b4017285",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.taylor@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=30544",
                "https://www.iowasenaterepublicans.com/senators/jeff-taylor/",
                "https://www.iowasenaterepublicans.com/senators/jeff-taylor/",
                "https://https://www.iowasenaterepublicans.com/senators/jeff-taylor/",
                "https://https://www.iowasenaterepublicans.com/senators/jeff-taylor/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30544",
                "https://iowasenaterepublicans.com/senators/jeff-taylor/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-2-upper-ocd-person-cc1cff7e-82d6-4947-a906-f467b4017285": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/0f7d8e8f-ab3e-4a0f-bde0-2ef776cdb27d",
            "firstName": "K. Lynn",
            "lastName": "Evans",
            "fullName": "Lynn Evans",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=34008",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-IA-3-upper-ocd-person-0f7d8e8f-ab3e-4a0f-bde0-2ef776cdb27d",
              "builtID": "ia-upper-3",
              "externalID": "ocd-person/0f7d8e8f-ab3e-4a0f-bde0-2ef776cdb27d",
              "geometry": null
            },
            "contactInfo": {
              "email": "lynn.evans@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.iowasenaterepublicans.com/senators/lynn-evans/",
                "https://https://www.iowasenaterepublicans.com/senators/lynn-evans/",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=34008",
                "https://iowasenaterepublicans.com/senators/lynn-evans/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-3-upper-ocd-person-0f7d8e8f-ab3e-4a0f-bde0-2ef776cdb27d": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/293487d6-a28b-48a2-8cfc-c3daabb8abbe",
            "firstName": "Timothy",
            "lastName": "Kraayenbrink",
            "fullName": "Tim Kraayenbrink",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=14812",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-IA-4-upper-ocd-person-293487d6-a28b-48a2-8cfc-c3daabb8abbe",
              "builtID": "ia-upper-4",
              "externalID": "ocd-person/293487d6-a28b-48a2-8cfc-c3daabb8abbe",
              "geometry": null
            },
            "contactInfo": {
              "email": "tim.kraayenbrink@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=14812",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=14812",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=14812",
                "https://www.iowasenaterepublicans.com/senators/tim-kraayenbrink/",
                "https://www.iowasenaterepublicans.com/senators/tim-kraayenbrink/",
                "https://https://www.iowasenaterepublicans.com/senators/tim-kraayenbrink/",
                "https://https://www.iowasenaterepublicans.com/senators/tim-kraayenbrink/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=14812"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-4-upper-ocd-person-293487d6-a28b-48a2-8cfc-c3daabb8abbe": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/93e9ba73-a053-4661-8b8b-e8e2f25d0596",
            "firstName": "David D.",
            "lastName": "Rowley",
            "fullName": "Dave Rowley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=32458",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-IA-5-upper-ocd-person-93e9ba73-a053-4661-8b8b-e8e2f25d0596",
              "builtID": "ia-upper-5",
              "externalID": "ocd-person/93e9ba73-a053-4661-8b8b-e8e2f25d0596",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.rowley@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.iowasenaterepublicans.com/senators/dave-rowley/",
                "https://https://www.iowasenaterepublicans.com/senators/dave-rowley/",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=32458",
                "https://iowasenaterepublicans.com/senators/dave-rowley/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-5-upper-ocd-person-93e9ba73-a053-4661-8b8b-e8e2f25d0596": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/32126238-17ce-4338-923a-3867b6517039",
            "firstName": "Jason M.",
            "lastName": "Schultz",
            "fullName": "Jason Schultz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=6588",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-IA-6-upper-ocd-person-32126238-17ce-4338-923a-3867b6517039",
              "builtID": "ia-upper-6",
              "externalID": "ocd-person/32126238-17ce-4338-923a-3867b6517039",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.schultz@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=6588",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=6588",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=6588",
                "https://www.iowasenaterepublicans.com/senators/jason-schultz/",
                "https://www.iowasenaterepublicans.com/senators/jason-schultz/",
                "https://https://www.iowasenaterepublicans.com/senators/jason-schultz/",
                "https://https://www.iowasenaterepublicans.com/senators/jason-schultz/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=6588"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-6-upper-ocd-person-32126238-17ce-4338-923a-3867b6517039": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/abad9c61-6eb9-484a-bf07-94cb9040a639",
            "firstName": "Kevin",
            "lastName": "Alons",
            "fullName": "Kevin Alons",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=33999",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-IA-7-upper-ocd-person-abad9c61-6eb9-484a-bf07-94cb9040a639",
              "builtID": "ia-upper-7",
              "externalID": "ocd-person/abad9c61-6eb9-484a-bf07-94cb9040a639",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.alons@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.iowasenaterepublicans.com/senators/kevin-alons/",
                "https://https://www.iowasenaterepublicans.com/senators/kevin-alons/",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=33999",
                "https://iowasenaterepublicans.com/senators/kevin-alons/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-7-upper-ocd-person-abad9c61-6eb9-484a-bf07-94cb9040a639": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/37b1879c-5435-4c28-bb62-fa71fbabbbdb",
            "firstName": "Mark",
            "lastName": "Costello",
            "fullName": "Mark Costello",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=10749",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-IA-8-upper-ocd-person-37b1879c-5435-4c28-bb62-fa71fbabbbdb",
              "builtID": "ia-upper-8",
              "externalID": "ocd-person/37b1879c-5435-4c28-bb62-fa71fbabbbdb",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.costello@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=10749",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=10749",
                "https://www.iowasenaterepublicans.com/senators/mark-costello/",
                "https://www.iowasenaterepublicans.com/senators/mark-costello/",
                "https://https://www.iowasenaterepublicans.com/senators/mark-costello/",
                "https://https://www.iowasenaterepublicans.com/senators/mark-costello/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=10749"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-8-upper-ocd-person-37b1879c-5435-4c28-bb62-fa71fbabbbdb": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/d1167ce2-5a24-4eb8-bce8-3800ea24a4d0",
            "firstName": "Tom",
            "lastName": "Shipley",
            "fullName": "Tom Shipley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=14814",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-IA-9-upper-ocd-person-d1167ce2-5a24-4eb8-bce8-3800ea24a4d0",
              "builtID": "ia-upper-9",
              "externalID": "ocd-person/d1167ce2-5a24-4eb8-bce8-3800ea24a4d0",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.shipley@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=14814",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=14814",
                "https://www.iowasenaterepublicans.com/senators/tom-shipley/",
                "https://www.iowasenaterepublicans.com/senators/tom-shipley/",
                "https://https://www.iowasenaterepublicans.com/senators/tom-shipley/",
                "https://https://www.iowasenaterepublicans.com/senators/tom-shipley/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=14814"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-9-upper-ocd-person-d1167ce2-5a24-4eb8-bce8-3800ea24a4d0": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/79384252-69ca-43c7-bda1-46b6abe5873e",
            "firstName": "Dan",
            "lastName": "Dawson",
            "fullName": "Dan Dawson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=18072",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-IA-10-upper-ocd-person-79384252-69ca-43c7-bda1-46b6abe5873e",
              "builtID": "ia-upper-10",
              "externalID": "ocd-person/79384252-69ca-43c7-bda1-46b6abe5873e",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.dawson@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=18072",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=18072",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=18072",
                "https://www.iowasenaterepublicans.com/senators/dan-dawson/",
                "https://www.iowasenaterepublicans.com/senators/dan-dawson/",
                "https://https://www.iowasenaterepublicans.com/senators/dan-dawson/",
                "https://https://www.iowasenaterepublicans.com/senators/dan-dawson/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=18072"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-10-upper-ocd-person-79384252-69ca-43c7-bda1-46b6abe5873e": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/67f25420-8ea1-49d1-aa86-488136b5f9f5",
            "firstName": "Julian B.",
            "lastName": "Garrett",
            "fullName": "Julian Garrett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=9404",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-IA-11-upper-ocd-person-67f25420-8ea1-49d1-aa86-488136b5f9f5",
              "builtID": "ia-upper-11",
              "externalID": "ocd-person/67f25420-8ea1-49d1-aa86-488136b5f9f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "julian.garrett@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=9404",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=9404",
                "https://www.iowasenaterepublicans.com/senators/julian.garrett/",
                "https://www.iowasenaterepublicans.com/senators/julian-garrett/",
                "https://https://www.iowasenaterepublicans.com/senators/julian.garrett/",
                "https://https://www.iowasenaterepublicans.com/senators/julian-garrett/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=9404"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-11-upper-ocd-person-67f25420-8ea1-49d1-aa86-488136b5f9f5": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/8e969687-4903-4f3c-a76f-2e35a2232d09",
            "firstName": "Amy Jellison",
            "lastName": "Sinclair",
            "fullName": "Amy Sinclair",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=10729",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-IA-12-upper-ocd-person-8e969687-4903-4f3c-a76f-2e35a2232d09",
              "builtID": "ia-upper-12",
              "externalID": "ocd-person/8e969687-4903-4f3c-a76f-2e35a2232d09",
              "geometry": null
            },
            "contactInfo": {
              "email": "amy.sinclair@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=10729",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=10729",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=10729",
                "https://www.iowasenaterepublicans.com/senators/amy-sinclair/",
                "https://www.iowasenaterepublicans.com/senators/amy-sinclair/",
                "https://https://www.iowasenaterepublicans.com/senators/amy-sinclair/",
                "https://https://www.iowasenaterepublicans.com/senators/amy-sinclair/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=10729"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-12-upper-ocd-person-8e969687-4903-4f3c-a76f-2e35a2232d09": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/f4803b48-80d4-43aa-86cd-fe78d268e77a",
            "firstName": "Cherielynn Marie",
            "lastName": "Westrich",
            "fullName": "Cherielynn Westrich",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30654",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-IA-13-upper-ocd-person-f4803b48-80d4-43aa-86cd-fe78d268e77a",
              "builtID": "ia-upper-13",
              "externalID": "ocd-person/f4803b48-80d4-43aa-86cd-fe78d268e77a",
              "geometry": null
            },
            "contactInfo": {
              "email": "cherielynn.westrich@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.iowasenaterepublicans.com/senators/cherielynn-westrich/",
                "https://https://www.iowasenaterepublicans.com/senators/cherielynn-westrich/",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=30654",
                "https://iowasenaterepublicans.com/senators/cherielynn-westrich/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-13-upper-ocd-person-f4803b48-80d4-43aa-86cd-fe78d268e77a": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/ce66871e-57e7-471d-b231-d772dd41eb63",
            "firstName": "Sarah Trone",
            "lastName": "Garriott",
            "fullName": "Sarah Garriott",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30551",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-IA-14-upper-ocd-person-ce66871e-57e7-471d-b231-d772dd41eb63",
              "builtID": "ia-upper-14",
              "externalID": "ocd-person/ce66871e-57e7-471d-b231-d772dd41eb63",
              "geometry": null
            },
            "contactInfo": {
              "email": "sarah.trone.garriott@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=30551",
                "https://www.senate.iowa.gov/senator/trone-garriott",
                "https://https://mailchi.mp/legis/trone-garriott",
                "https://sarah@sarahforiowa.com",
                "https://https://www.senate.iowa.gov/senator/trone-garriott",
                "https://https://www.senate.iowa.gov/democrats/subscribe/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30551",
                "https://www.senate.iowa.gov/senator/trone-garriott/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-14-upper-ocd-person-ce66871e-57e7-471d-b231-d772dd41eb63": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/be302cfd-09b1-4a29-974d-479aa24469eb",
            "firstName": "Tony",
            "lastName": "Bisignano",
            "fullName": "Tony Bisignano",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=906",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-IA-15-upper-ocd-person-be302cfd-09b1-4a29-974d-479aa24469eb",
              "builtID": "ia-upper-15",
              "externalID": "ocd-person/be302cfd-09b1-4a29-974d-479aa24469eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "tony.bisignano@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=906",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=906",
                "https://www.senate.iowa.gov/senator/bisignano/",
                "https://https://mailchi.mp/legis/bisignano",
                "https://https://www.senate.iowa.gov/senator/bisignano/",
                "https://https://www.senate.iowa.gov/democrats/subscribe/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=906"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-15-upper-ocd-person-be302cfd-09b1-4a29-974d-479aa24469eb": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/c8fe9d62-630f-427b-8f17-3bd2ff82a33a",
            "firstName": "Claire A.",
            "lastName": "Celsi",
            "fullName": "Claire Celsi",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27000",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-IA-16-upper-ocd-person-c8fe9d62-630f-427b-8f17-3bd2ff82a33a",
              "builtID": "ia-upper-16",
              "externalID": "ocd-person/c8fe9d62-630f-427b-8f17-3bd2ff82a33a",
              "geometry": null
            },
            "contactInfo": {
              "email": "claire.celsi@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=27000",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=27000",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=27000",
                "https://www.senate.iowa.gov/senator/celsi/",
                "https://https://www.legis.iowa.gov/legislators/legislator/newsletter?id=27000&ga=89",
                "https://www.claire4iowa.com",
                "https://https://www.senate.iowa.gov/senator/celsi/",
                "https://https://www.senate.iowa.gov/democrats/subscribe/",
                "https://https://claire4iowa.us20.list-manage.com/subscribe?u=a96020dc7a81b1ff711f8a989&id=1683d94873",
                "https://www.legis.iowa.gov/legislators/legislator?personID=27000"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-16-upper-ocd-person-c8fe9d62-630f-427b-8f17-3bd2ff82a33a": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/0af01ef4-f982-4239-9ffc-56d33cee3b7b",
            "firstName": "Izaah J.B.",
            "lastName": "Knox",
            "fullName": "Izaah Knox",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=34010",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-IA-17-upper-ocd-person-0af01ef4-f982-4239-9ffc-56d33cee3b7b",
              "builtID": "ia-upper-17",
              "externalID": "ocd-person/0af01ef4-f982-4239-9ffc-56d33cee3b7b",
              "geometry": null
            },
            "contactInfo": {
              "email": "izaah.knox@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.senate.iowa.gov/senator/knox/",
                "https://https://www.senate.iowa.gov/democrats/subscribe/",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=34010"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-17-upper-ocd-person-0af01ef4-f982-4239-9ffc-56d33cee3b7b": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/3089e8af-0071-4298-822c-2d16ac01fe9f",
            "firstName": "Janet",
            "lastName": "Petersen",
            "fullName": "Janet Petersen",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=72",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-IA-18-upper-ocd-person-3089e8af-0071-4298-822c-2d16ac01fe9f",
              "builtID": "ia-upper-18",
              "externalID": "ocd-person/3089e8af-0071-4298-822c-2d16ac01fe9f",
              "geometry": null
            },
            "contactInfo": {
              "email": "janet.petersen@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=72",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=72",
                "https://www.senate.iowa.gov/senator/petersen/",
                "https://https://mailchi.mp/legis/petersen",
                "https://www.janet4iowa.com",
                "https://https://www.senate.iowa.gov/senator/petersen/",
                "https://https://www.senate.iowa.gov/democrats/subscribe/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=72"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-18-upper-ocd-person-3089e8af-0071-4298-822c-2d16ac01fe9f": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/1cfc824d-740a-4e35-a4e2-3728e2d43056",
            "firstName": "Ken",
            "lastName": "Rozenboom",
            "fullName": "Ken Rozenboom",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=10731",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-IA-19-upper-ocd-person-1cfc824d-740a-4e35-a4e2-3728e2d43056",
              "builtID": "ia-upper-19",
              "externalID": "ocd-person/1cfc824d-740a-4e35-a4e2-3728e2d43056",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.rozenboom@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=10731",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=10731",
                "https://www.iowasenaterepublicans.com/senators/ken-rozenboom/",
                "https://www.iowasenaterepublicans.com/senators/ken-rozenboom/",
                "https://https://www.iowasenaterepublicans.com/senators/ken-rozenboom/",
                "https://https://www.iowasenaterepublicans.com/senators/ken-rozenboom/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=10731"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-19-upper-ocd-person-1cfc824d-740a-4e35-a4e2-3728e2d43056": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/8902ec21-c923-4923-853e-2eeecbc51793",
            "firstName": "Nathaniel R.",
            "lastName": "Boulton",
            "fullName": "Nate Boulton",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=18073",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-IA-20-upper-ocd-person-8902ec21-c923-4923-853e-2eeecbc51793",
              "builtID": "ia-upper-20",
              "externalID": "ocd-person/8902ec21-c923-4923-853e-2eeecbc51793",
              "geometry": null
            },
            "contactInfo": {
              "email": "nate.boulton@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=18073",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=18073",
                "https://www.senate.iowa.gov/senator/boulton",
                "https://https://mailchi.mp/legis/boulton",
                "https://www.nateboulton.com/",
                "https://https://www.senate.iowa.gov/senator/boulton",
                "https://https://www.senate.iowa.gov/democrats/subscribe/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=18073",
                "https://www.senate.iowa.gov/senator/boulton/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-20-upper-ocd-person-8902ec21-c923-4923-853e-2eeecbc51793": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/37784f85-492c-422c-93a3-9910867f68f9",
            "firstName": "Michael",
            "lastName": "Bousselot",
            "fullName": "Mike Bousselot",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=32076",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-IA-21-upper-ocd-person-37784f85-492c-422c-93a3-9910867f68f9",
              "builtID": "ia-upper-21",
              "externalID": "ocd-person/37784f85-492c-422c-93a3-9910867f68f9",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.bousselot@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.iowasenaterepublicans.com/senators/mike-bousselot/",
                "https://https://www.iowasenaterepublicans.com/senators/mike-bousselot/",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=32076"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-21-upper-ocd-person-37784f85-492c-422c-93a3-9910867f68f9": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/13357403-7d6d-49f5-9745-f56737e98c95",
            "firstName": "Bradley",
            "lastName": "Zaun",
            "fullName": "Brad Zaun",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=788",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-IA-22-upper-ocd-person-13357403-7d6d-49f5-9745-f56737e98c95",
              "builtID": "ia-upper-22",
              "externalID": "ocd-person/13357403-7d6d-49f5-9745-f56737e98c95",
              "geometry": null
            },
            "contactInfo": {
              "email": "brad.zaun@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=788",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=788",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=788",
                "https://www.iowasenaterepublicans.com/senators/brad-zaun/",
                "https://www.iowasenaterepublicans.com/senators/brad-zaun/",
                "https://www.bzaun.com",
                "https://https://www.iowasenaterepublicans.com/senators/brad-zaun/",
                "https://https://www.iowasenaterepublicans.com/senators/brad-zaun/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=788"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-22-upper-ocd-person-13357403-7d6d-49f5-9745-f56737e98c95": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/9da58039-06dd-451e-835c-c406a493a21c",
            "firstName": "Jack Andrew",
            "lastName": "Whitver",
            "fullName": "Jack Whitver",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=9768",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-IA-23-upper-ocd-person-9da58039-06dd-451e-835c-c406a493a21c",
              "builtID": "ia-upper-23",
              "externalID": "ocd-person/9da58039-06dd-451e-835c-c406a493a21c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jack.whitver@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=9768",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=9768",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=9768",
                "https://www.iowasenaterepublicans.com/senators/jack-whitver/",
                "https://www.iowasenaterepublicans.com/senators/jack-whitver/",
                "https://www.whitverforiowa.com",
                "https://https://www.iowasenaterepublicans.com/senators/jack-whitver/",
                "https://https://www.iowasenaterepublicans.com/senators/jack-whitver/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=9768"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-23-upper-ocd-person-9da58039-06dd-451e-835c-c406a493a21c": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/c6cbc677-2d42-4497-ae57-989a30aaddae",
            "firstName": "Jesse",
            "lastName": "Green",
            "fullName": "Jesse Green",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30546",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-IA-24-upper-ocd-person-c6cbc677-2d42-4497-ae57-989a30aaddae",
              "builtID": "ia-upper-24",
              "externalID": "ocd-person/c6cbc677-2d42-4497-ae57-989a30aaddae",
              "geometry": null
            },
            "contactInfo": {
              "email": "jesse.green@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=30546",
                "https://www.iowasenaterepublicans.com/senators/jesse-green/",
                "https://www.iowasenaterepublicans.com/senators/jesse-green/",
                "https://https://www.iowasenaterepublicans.com/senators/jesse-green/",
                "https://https://www.iowasenaterepublicans.com/senators/jesse-green/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30546",
                "https://iowasenaterepublicans.com/senators/jesse-green/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-24-upper-ocd-person-c6cbc677-2d42-4497-ae57-989a30aaddae": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/d3b3bc8e-ab0c-4ee2-ba3d-de78040274af",
            "firstName": "Herman Charles",
            "lastName": "Quirmbach",
            "fullName": "Herman Quirmbach",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=161",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-IA-25-upper-ocd-person-d3b3bc8e-ab0c-4ee2-ba3d-de78040274af",
              "builtID": "ia-upper-25",
              "externalID": "ocd-person/d3b3bc8e-ab0c-4ee2-ba3d-de78040274af",
              "geometry": null
            },
            "contactInfo": {
              "email": "herman.quirmbach@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.senate.iowa.gov/senator/quirmbach",
                "https://https://www.senate.iowa.gov/democrats/subscribe/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=161"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-25-upper-ocd-person-d3b3bc8e-ab0c-4ee2-ba3d-de78040274af": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/f8bb19e7-5336-4860-b2ea-82c943ab163d",
            "firstName": "Jeff",
            "lastName": "Edler",
            "fullName": "Jeff Edler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=18076",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-IA-26-upper-ocd-person-f8bb19e7-5336-4860-b2ea-82c943ab163d",
              "builtID": "ia-upper-26",
              "externalID": "ocd-person/f8bb19e7-5336-4860-b2ea-82c943ab163d",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.edler@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=18076",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=18076",
                "https://www.iowasenaterepublicans.com/senators/jeff-edler/",
                "https://www.iowasenaterepublicans.com/senators/jeff-edler/",
                "https://https://www.iowasenaterepublicans.com/senators/jeff-edler/",
                "https://https://www.iowasenaterepublicans.com/senators/jeff-edler/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=18076"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-26-upper-ocd-person-f8bb19e7-5336-4860-b2ea-82c943ab163d": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/79eacd35-8caf-466e-b75f-0d878dcf5221",
            "firstName": "Annette",
            "lastName": "Sweeney",
            "fullName": "Annette Sweeney",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=6585",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-IA-27-upper-ocd-person-79eacd35-8caf-466e-b75f-0d878dcf5221",
              "builtID": "ia-upper-27",
              "externalID": "ocd-person/79eacd35-8caf-466e-b75f-0d878dcf5221",
              "geometry": null
            },
            "contactInfo": {
              "email": "annette.sweeney@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=6585",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=6585",
                "https://www.iowasenaterepublicans.com/senators/annette-sweeney/",
                "https://www.iowasenaterepublicans.com/senators/annette-sweeney/",
                "https://https://www.iowasenaterepublicans.com/senators/annette-sweeney/",
                "https://https://www.iowasenaterepublicans.com/senators/annette-sweeney/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=6585"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-27-upper-ocd-person-79eacd35-8caf-466e-b75f-0d878dcf5221": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/1e11235a-f4c8-4124-9f3a-3bb7a219636d",
            "firstName": "Dennis",
            "lastName": "Guth",
            "fullName": "Dennis Guth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=10726",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-IA-28-upper-ocd-person-1e11235a-f4c8-4124-9f3a-3bb7a219636d",
              "builtID": "ia-upper-28",
              "externalID": "ocd-person/1e11235a-f4c8-4124-9f3a-3bb7a219636d",
              "geometry": null
            },
            "contactInfo": {
              "email": "dennis.guth@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.iowasenaterepublicans.com/senators/dennis-guth/",
                "https://https://www.iowasenaterepublicans.com/senators/dennis-guth/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=10726"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-28-upper-ocd-person-1e11235a-f4c8-4124-9f3a-3bb7a219636d": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/b51c0843-c860-4568-a3a7-3d004a377fd4",
            "firstName": "Sandy",
            "lastName": "Salmon",
            "fullName": "Sandy Salmon",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=10754",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-IA-29-upper-ocd-person-b51c0843-c860-4568-a3a7-3d004a377fd4",
              "builtID": "ia-upper-29",
              "externalID": "ocd-person/b51c0843-c860-4568-a3a7-3d004a377fd4",
              "geometry": null
            },
            "contactInfo": {
              "email": "sandy.salmon@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=10754",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=10754",
                "https://www.sandysalmon.org",
                "https://https://www.sandysalmon.org/newsletters",
                "https://https://www.iowasenaterepublicans.com/senators/sandy-salmon/",
                "https://https://www.iowasenaterepublicans.com/senators/sandy-salmon/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=10754"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-29-upper-ocd-person-b51c0843-c860-4568-a3a7-3d004a377fd4": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/c1174a56-68c9-4103-aaee-fa3f1a2b25ca",
            "firstName": "William Anthony",
            "lastName": "Dotzler",
            "fullName": "Bill Dotzler",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=157",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-IA-31-upper-ocd-person-c1174a56-68c9-4103-aaee-fa3f1a2b25ca",
              "builtID": "ia-upper-31",
              "externalID": "ocd-person/c1174a56-68c9-4103-aaee-fa3f1a2b25ca",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.dotzler@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=157",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=157",
                "https://www.senate.iowa.gov/senator/dotzler",
                "https://https://mailchi.mp/legis/dotzler",
                "https://https://www.senate.iowa.gov/senator/dotzler",
                "https://https://www.senate.iowa.gov/democrats/subscribe/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=157"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-31-upper-ocd-person-c1174a56-68c9-4103-aaee-fa3f1a2b25ca": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/cad5e110-d7b3-45b3-b7c3-9e8232e2dbc7",
            "firstName": "Michael T.",
            "lastName": "Klimesh",
            "fullName": "Mike Klimesh",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30547",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-IA-32-upper-ocd-person-cad5e110-d7b3-45b3-b7c3-9e8232e2dbc7",
              "builtID": "ia-upper-32",
              "externalID": "ocd-person/cad5e110-d7b3-45b3-b7c3-9e8232e2dbc7",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.klimesh@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=30547",
                "https://www.iowasenaterepublicans.com/senators/mike-klimesh/",
                "https://www.iowasenaterepublicans.com/senators/mike-klimesh/",
                "https://https://www.iowasenaterepublicans.com/senators/mike-klimesh/",
                "https://https://www.iowasenaterepublicans.com/senators/mike-klimesh/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30547",
                "https://iowasenaterepublicans.com/senators/mike-klimesh/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-32-upper-ocd-person-cad5e110-d7b3-45b3-b7c3-9e8232e2dbc7": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/9b68d2a2-ead2-401e-a225-1edae68f6099",
            "firstName": "Carrie Larson",
            "lastName": "Koelker",
            "fullName": "Carrie Koelker",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27001",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-IA-33-upper-ocd-person-9b68d2a2-ead2-401e-a225-1edae68f6099",
              "builtID": "ia-upper-33",
              "externalID": "ocd-person/9b68d2a2-ead2-401e-a225-1edae68f6099",
              "geometry": null
            },
            "contactInfo": {
              "email": "carrie.koelker@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=27001",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=27001",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=27001",
                "https://www.iowasenaterepublicans.com/senators/carrie-koelker/",
                "https://www.iowasenaterepublicans.com/senators/carrie-koelker/",
                "https://https://www.iowasenaterepublicans.com/senators/carrie-koelker/",
                "https://https://www.iowasenaterepublicans.com/senators/carrie-koelker/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=27001"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-33-upper-ocd-person-9b68d2a2-ead2-401e-a225-1edae68f6099": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/3fa8bceb-666f-4480-a957-1928e2b5fec6",
            "firstName": "Dan",
            "lastName": "Zumbach",
            "fullName": "Dan Zumbach",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=10733",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-IA-34-upper-ocd-person-3fa8bceb-666f-4480-a957-1928e2b5fec6",
              "builtID": "ia-upper-34",
              "externalID": "ocd-person/3fa8bceb-666f-4480-a957-1928e2b5fec6",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.zumbach@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=10733",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=10733",
                "https://www.iowasenaterepublicans.com/senators/dan-zumbach/",
                "https://www.iowasenaterepublicans.com/senators/dan-zumbach/",
                "https://https://www.iowasenaterepublicans.com/senators/dan-zumbach/",
                "https://https://www.iowasenaterepublicans.com/senators/dan-zumbach/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=10733"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-34-upper-ocd-person-3fa8bceb-666f-4480-a957-1928e2b5fec6": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/a32d2cef-0ce9-4a2d-bbf6-8c8a53eaad4f",
            "firstName": "Chris",
            "lastName": "Cournoyer",
            "fullName": "Chris Cournoyer",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27004",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-IA-35-upper-ocd-person-a32d2cef-0ce9-4a2d-bbf6-8c8a53eaad4f",
              "builtID": "ia-upper-35",
              "externalID": "ocd-person/a32d2cef-0ce9-4a2d-bbf6-8c8a53eaad4f",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.cournoyer@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=27004",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=27004",
                "https://www.iowasenaterepublicans.com/senators/chris-cournoyer/",
                "https://www.iowasenaterepublicans.com/senators/chris-cournoyer/",
                "https://www.cournoyerforsenate.com",
                "https://https://www.iowasenaterepublicans.com/senators/chris-cournoyer/",
                "https://https://www.iowasenaterepublicans.com/senators/chris-cournoyer/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=27004"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-35-upper-ocd-person-a32d2cef-0ce9-4a2d-bbf6-8c8a53eaad4f": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/36b3c342-b276-4a10-92e9-9f3662349546",
            "firstName": "Pam",
            "lastName": "Jochum",
            "fullName": "Pam Jochum",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=35",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-IA-36-upper-ocd-person-36b3c342-b276-4a10-92e9-9f3662349546",
              "builtID": "ia-upper-36",
              "externalID": "ocd-person/36b3c342-b276-4a10-92e9-9f3662349546",
              "geometry": null
            },
            "contactInfo": {
              "email": "pam.jochum@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=35",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=35",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=35",
                "https://www.senate.iowa.gov/senator/jochum",
                "https://https://mailchi.mp/legis/jochum",
                "https://https://www.senate.iowa.gov/senator/jochum",
                "https://https://www.senate.iowa.gov/democrats/subscribe/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=35"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-36-upper-ocd-person-36b3c342-b276-4a10-92e9-9f3662349546": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/d675a03b-f474-4e1c-b48a-b78b399488c4",
            "firstName": "Molly Erin",
            "lastName": "Donahue",
            "fullName": "Molly Donahue",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27031",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-IA-37-upper-ocd-person-d675a03b-f474-4e1c-b48a-b78b399488c4",
              "builtID": "ia-upper-37",
              "externalID": "ocd-person/d675a03b-f474-4e1c-b48a-b78b399488c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "molly.donahue@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=27031",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=27031",
                "https://eepurl.com/gXN_ff",
                "https://https://www.senate.iowa.gov/senator/donahue/",
                "https://https://www.senate.iowa.gov/democrats/subscribe/",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=27031",
                "https://www.senate.iowa.gov/senator/donahue/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-37-upper-ocd-person-d675a03b-f474-4e1c-b48a-b78b399488c4": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/83a23151-4c51-4466-954c-c27005186f54",
            "firstName": "Eric",
            "lastName": "Giddens",
            "fullName": "Eric Giddens",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=28254",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-IA-38-upper-ocd-person-83a23151-4c51-4466-954c-c27005186f54",
              "builtID": "ia-upper-38",
              "externalID": "ocd-person/83a23151-4c51-4466-954c-c27005186f54",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.giddens@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.senate.iowa.gov/senator/giddens/",
                "https://https://www.senate.iowa.gov/democrats/subscribe/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=28254",
                "https://www.senate.iowa.gov/senator/giddens/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-38-upper-ocd-person-83a23151-4c51-4466-954c-c27005186f54": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/a5bbbe7f-202c-4427-89e1-da8d2786d1dc",
            "firstName": "Liz",
            "lastName": "Bennett",
            "fullName": "Liz Bennett",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=34000",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-IA-39-upper-ocd-person-a5bbbe7f-202c-4427-89e1-da8d2786d1dc",
              "builtID": "ia-upper-39",
              "externalID": "ocd-person/a5bbbe7f-202c-4427-89e1-da8d2786d1dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "liz.bennett@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=14806",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=14806",
                "https://eepurl.com/gXSfzr",
                "https://https://www.senate.iowa.gov/senator/bennett/",
                "https://https://www.senate.iowa.gov/democrats/subscribe/",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=34000"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-39-upper-ocd-person-a5bbbe7f-202c-4427-89e1-da8d2786d1dc": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/ecc56806-698d-486b-bf02-38aa436474a9",
            "firstName": "Todd E.",
            "lastName": "Taylor",
            "fullName": "Todd Taylor",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=54",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-IA-40-upper-ocd-person-ecc56806-698d-486b-bf02-38aa436474a9",
              "builtID": "ia-upper-40",
              "externalID": "ocd-person/ecc56806-698d-486b-bf02-38aa436474a9",
              "geometry": null
            },
            "contactInfo": {
              "email": "todd.taylor@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=54",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=54",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=54",
                "https://www.senate.iowa.gov/senator/todd-taylor/",
                "https://https://mailchi.mp/legis/ttaylor",
                "https://https://www.senate.iowa.gov/senator/todd-taylor/",
                "https://https://www.senate.iowa.gov/democrats/subscribe/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=54"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-40-upper-ocd-person-ecc56806-698d-486b-bf02-38aa436474a9": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/2ff1622d-f1f4-4344-8821-57bcf8d99b83",
            "firstName": "Kerry",
            "lastName": "Gruenhagen",
            "fullName": "Kerry Gruenhagen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=34009",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-IA-41-upper-ocd-person-2ff1622d-f1f4-4344-8821-57bcf8d99b83",
              "builtID": "ia-upper-41",
              "externalID": "ocd-person/2ff1622d-f1f4-4344-8821-57bcf8d99b83",
              "geometry": null
            },
            "contactInfo": {
              "email": "kerry.gruenhagen@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.iowasenaterepublicans.com/senators/kerry-gruenhagen/",
                "https://https://www.iowasenaterepublicans.com/senators/kerry-gruenhagen/",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=34009"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-41-upper-ocd-person-2ff1622d-f1f4-4344-8821-57bcf8d99b83": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/006bcadf-1173-4661-a28e-25fbf1a5c423",
            "firstName": "Charlie",
            "lastName": "McClintock",
            "fullName": "Charlie McClintock",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30657",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-IA-42-upper-ocd-person-006bcadf-1173-4661-a28e-25fbf1a5c423",
              "builtID": "ia-upper-42",
              "externalID": "ocd-person/006bcadf-1173-4661-a28e-25fbf1a5c423",
              "geometry": null
            },
            "contactInfo": {
              "email": "charlie.mcclintock@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.iowasenaterepublicans.com/senators/charlie-mcclintock/",
                "https://https://www.iowasenaterepublicans.com/senators/charlie-mcclintock/",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=30657",
                "https://iowasenaterepublicans.com/senators/charlie-mcclintock/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-42-upper-ocd-person-006bcadf-1173-4661-a28e-25fbf1a5c423": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/ab4528a8-eeca-4562-8475-967ce868eb68",
            "firstName": "Zacharia P.",
            "lastName": "Wahls",
            "fullName": "Zach Wahls",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=27002",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "43",
              "chamber": "upper",
              "hashID": "SLDU-IA-43-upper-ocd-person-ab4528a8-eeca-4562-8475-967ce868eb68",
              "builtID": "ia-upper-43",
              "externalID": "ocd-person/ab4528a8-eeca-4562-8475-967ce868eb68",
              "geometry": null
            },
            "contactInfo": {
              "email": "zach.wahls@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=27002",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=27002",
                "https://www.senate.iowa.gov/senator/wahls/",
                "https://https://mailchi.mp/legis/wahls",
                "https://www.zachwahls.com",
                "https://https://www.senate.iowa.gov/senator/wahls/",
                "https://https://www.senate.iowa.gov/democrats/subscribe/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=27002"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-43-upper-ocd-person-ab4528a8-eeca-4562-8475-967ce868eb68": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/9788064e-fad9-4130-9084-69e829884d9c",
            "firstName": "Adrian Jeremy",
            "lastName": "Dickey",
            "fullName": "Adrian Dickey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=31094",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "44",
              "chamber": "upper",
              "hashID": "SLDU-IA-44-upper-ocd-person-9788064e-fad9-4130-9084-69e829884d9c",
              "builtID": "ia-upper-44",
              "externalID": "ocd-person/9788064e-fad9-4130-9084-69e829884d9c",
              "geometry": null
            },
            "contactInfo": {
              "email": "adrian.dickey@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.iowasenaterepublicans.com/senators/adrian-dickey/",
                "https://https://www.iowasenaterepublicans.com/senators/adrian-dickey/",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=31094",
                "https://iowasenaterepublicans.com/senators/adrian-dickey/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-44-upper-ocd-person-9788064e-fad9-4130-9084-69e829884d9c": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/1c841d58-bdbe-4021-9126-055184cecdd5",
            "firstName": "Janice G.",
            "lastName": "Weiner",
            "fullName": "Janice Weiner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=34011",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "45",
              "chamber": "upper",
              "hashID": "SLDU-IA-45-upper-ocd-person-1c841d58-bdbe-4021-9126-055184cecdd5",
              "builtID": "ia-upper-45",
              "externalID": "ocd-person/1c841d58-bdbe-4021-9126-055184cecdd5",
              "geometry": null
            },
            "contactInfo": {
              "email": "janice.weiner@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.senate.iowa.gov/senator/weiner/",
                "https://https://www.senate.iowa.gov/democrats/subscribe/",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=34011",
                "https://www.senate.iowa.gov/senator/weiner/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-45-upper-ocd-person-1c841d58-bdbe-4021-9126-055184cecdd5": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/fe90f2b2-0892-4abd-9d6d-4b2ac1dae632",
            "firstName": "Dawn",
            "lastName": "Driscoll",
            "fullName": "Dawn Driscoll",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30548",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "46",
              "chamber": "upper",
              "hashID": "SLDU-IA-46-upper-ocd-person-fe90f2b2-0892-4abd-9d6d-4b2ac1dae632",
              "builtID": "ia-upper-46",
              "externalID": "ocd-person/fe90f2b2-0892-4abd-9d6d-4b2ac1dae632",
              "geometry": null
            },
            "contactInfo": {
              "email": "dawn.driscoll@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.iowasenaterepublicans.com/senators/dawn-driscoll/",
                "https://https://www.iowasenaterepublicans.com/senators/dawn-driscoll/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30548",
                "https://iowasenaterepublicans.com/senators/dawn-driscoll/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-46-upper-ocd-person-fe90f2b2-0892-4abd-9d6d-4b2ac1dae632": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/5d6f21b7-11a6-4f5e-8661-abc9345f3623",
            "firstName": "Scott",
            "lastName": "Webster",
            "fullName": "Scott Webster",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=13574",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "47",
              "chamber": "upper",
              "hashID": "SLDU-IA-47-upper-ocd-person-5d6f21b7-11a6-4f5e-8661-abc9345f3623",
              "builtID": "ia-upper-47",
              "externalID": "ocd-person/5d6f21b7-11a6-4f5e-8661-abc9345f3623",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.webster@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.iowasenaterepublicans.com/senators/scott-webster/",
                "https://https://www.iowasenaterepublicans.com/senators/scott-webster/",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=13574",
                "https://iowasenaterepublicans.com/senators/scott-webster/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-47-upper-ocd-person-5d6f21b7-11a6-4f5e-8661-abc9345f3623": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/b1110000-b6c3-4348-8655-8a7b51c8a9db",
            "firstName": "Mark S.",
            "lastName": "Lofgren",
            "fullName": "Mark Lofgren",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=9406",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "48",
              "chamber": "upper",
              "hashID": "SLDU-IA-48-upper-ocd-person-b1110000-b6c3-4348-8655-8a7b51c8a9db",
              "builtID": "ia-upper-48",
              "externalID": "ocd-person/b1110000-b6c3-4348-8655-8a7b51c8a9db",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.lofgren@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=88&personID=9406",
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=9406",
                "https://www.iowasenaterepublicans.com/senators/mark-lofgren/",
                "https://www.iowasenaterepublicans.com/senators/mark-lofgren/",
                "https://https://www.iowasenaterepublicans.com/senators/mark-lofgren/",
                "https://https://www.iowasenaterepublicans.com/senators/mark-lofgren/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=9406"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-48-upper-ocd-person-b1110000-b6c3-4348-8655-8a7b51c8a9db": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/84279bc7-04df-475e-8d6f-9f9703c73101",
            "firstName": "Cindy Lou",
            "lastName": "Winckler",
            "fullName": "Cindy Winckler",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=44",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "49",
              "chamber": "upper",
              "hashID": "SLDU-IA-49-upper-ocd-person-84279bc7-04df-475e-8d6f-9f9703c73101",
              "builtID": "ia-upper-49",
              "externalID": "ocd-person/84279bc7-04df-475e-8d6f-9f9703c73101",
              "geometry": null
            },
            "contactInfo": {
              "email": "cindy.winckler@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://https://www.senate.iowa.gov/democrats/subscribe/",
                "https://www.legis.iowa.gov/legislators/legislator?ga=90&personID=44"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-49-upper-ocd-person-84279bc7-04df-475e-8d6f-9f9703c73101": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/54f49ddd-7a13-4639-87de-7db8760ed5c5",
            "firstName": "Jeffrey",
            "lastName": "Reichman",
            "fullName": "Jeff Reichman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.iowa.gov/photo?action=getPhoto&ga=90&pid=30549",
            "title": "IA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "IA",
              "stateFull": "Iowa",
              "district": "50",
              "chamber": "upper",
              "hashID": "SLDU-IA-50-upper-ocd-person-54f49ddd-7a13-4639-87de-7db8760ed5c5",
              "builtID": "ia-upper-50",
              "externalID": "ocd-person/54f49ddd-7a13-4639-87de-7db8760ed5c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.reichman@legis.iowa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.iowa.gov/legislators/legislator?ga=89&personID=30549",
                "https://www.iowasenaterepublicans.com/senators/jeff-reichman/",
                "https://www.iowasenaterepublicans.com/senators/jeff-reichman/",
                "https://https://www.iowasenaterepublicans.com/senators/jeff-reichman/",
                "https://https://www.iowasenaterepublicans.com/senators/jeff-reichman/",
                "https://www.legis.iowa.gov/legislators/legislator?personID=30549",
                "https://iowasenaterepublicans.com/senators/jeff-reichman/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-IA-50-upper-ocd-person-54f49ddd-7a13-4639-87de-7db8760ed5c5": 0
        }
      }
    }
  },
  "KS": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "KS",
          "stateFull": "Kansas",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "KS",
            "stateFull": "Kansas",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-KS---",
            "builtID": "ks--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-KS---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "M001198",
          "in_office": true,
          "firstName": "Roger",
          "lastName": "Marshall",
          "middleName": null,
          "fullName": "Roger Marshall",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/M001198.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "KS",
            "stateFull": "Kansas",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-KS---M001198",
            "builtID": "ks--",
            "externalID": "M001198",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.marshall.senate.gov/contact/",
            "address1": "479a Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-4774",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorMarshall",
            "youtube": null,
            "instagram": null,
            "facebook": "rogermarshallmd",
            "urls": ["https://www.marshall.senate.gov"],
            "rss_url": "https://www.marshall.senate.gov/media/press-releases/feed/"
          },
          "title": "KS Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/M001198.json",
          "govtrack_id": "412704",
          "cspan_id": "103425",
          "votesmart_id": "172080",
          "icpsr_id": "21734",
          "crp_id": "N00037034",
          "google_entity_id": "/g/11c4btdtm1",
          "state_rank": "junior",
          "lis_id": "S411",
          "suffix": null,
          "date_of_birth": "1960-08-09",
          "leadership_role": null,
          "fec_candidate_id": "H6KS01179",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "3",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 25,
          "total_present": 0,
          "last_updated": "2024-06-20 15:30:29 -0400",
          "missed_votes_pct": 4.51,
          "votes_with_party_pct": 84.2,
          "votes_against_party_pct": 15.8,
          "ocd_id": "ocd-division/country:us/state:ks"
        },
        {
          "API_ID": "M000934",
          "in_office": true,
          "firstName": "Jerry",
          "lastName": "Moran",
          "middleName": null,
          "fullName": "Jerry Moran",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/M000934.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "KS",
            "stateFull": "Kansas",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-KS---M000934",
            "builtID": "ks--",
            "externalID": "M000934",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "JerryMoran",
            "youtube": "senatorjerrymoran",
            "instagram": null,
            "facebook": "jerrymoran",
            "urls": ["https://www.moran.senate.gov"],
            "rss_url": "https://www.moran.senate.gov/public/?a=rss.feed"
          },
          "title": "KS Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/M000934.json",
          "govtrack_id": "400284",
          "cspan_id": "45469",
          "votesmart_id": "542",
          "icpsr_id": "29722",
          "crp_id": "N00005282",
          "google_entity_id": "/m/024s8t",
          "state_rank": "senior",
          "lis_id": "S347",
          "suffix": null,
          "date_of_birth": "1954-05-29",
          "leadership_role": null,
          "fec_candidate_id": "H6KS01096",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "13",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 49,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 8.84,
          "votes_with_party_pct": 86.69,
          "votes_against_party_pct": 13.31,
          "ocd_id": "ocd-division/country:us/state:ks"
        }
      ],
      "hash": { "SENATE-KS---M001198": 0, "SENATE-KS---M000934": 1 }
    },
    "HOUSE": {
      "03": {
        "members": [
          {
            "API_ID": "D000629",
            "in_office": true,
            "firstName": "Sharice",
            "lastName": "Davids",
            "middleName": null,
            "fullName": "Sharice Davids",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/D000629.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-KS-03--D000629",
              "builtID": "ks--03",
              "externalID": "D000629",
              "geometry": null,
              "geoid": "2003"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2435 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2865",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDavids",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://davids.house.gov"],
              "rss_url": null
            },
            "title": "KS House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000629.json",
            "govtrack_id": "412780",
            "cspan_id": null,
            "votesmart_id": "181201",
            "icpsr_id": null,
            "crp_id": "N00042626",
            "google_entity_id": "/g/11fgm1fq16",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1980-05-22",
            "leadership_role": null,
            "fec_candidate_id": "H8KS03155",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 3,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.28,
            "votes_with_party_pct": 93.77,
            "votes_against_party_pct": 4.31,
            "ocd_id": "ocd-division/country:us/state:ks/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-KS-03--D000629": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "E000298",
            "in_office": true,
            "firstName": "Ron",
            "lastName": "Estes",
            "middleName": null,
            "fullName": "Ron Estes",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/E000298.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-KS-04--E000298",
              "builtID": "ks--04",
              "externalID": "E000298",
              "geometry": null,
              "geoid": "2004"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2234 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6216",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRonEstes",
              "youtube": null,
              "instagram": null,
              "facebook": "RepRonEstes",
              "urls": ["https://estes.house.gov"],
              "rss_url": null
            },
            "title": "KS House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/E000298.json",
            "govtrack_id": "412735",
            "cspan_id": "107963",
            "votesmart_id": "125031",
            "icpsr_id": "21750",
            "crp_id": "N00040712",
            "google_entity_id": "/m/0gfgsrm",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1956-07-19",
            "leadership_role": null,
            "fec_candidate_id": "H8KS04112",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 16,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.52,
            "votes_with_party_pct": 93.92,
            "votes_against_party_pct": 4.12,
            "ocd_id": "ocd-division/country:us/state:ks/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-KS-04--E000298": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "L000266",
            "in_office": true,
            "firstName": "Jake",
            "lastName": "LaTurner",
            "middleName": null,
            "fullName": "Jake LaTurner",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/L000266.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-KS-02--L000266",
              "builtID": "ks--02",
              "externalID": "L000266",
              "geometry": null,
              "geoid": "2002"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2441 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6601",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLaTurner",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://laturner.house.gov"],
              "rss_url": null
            },
            "title": "KS House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000266.json",
            "govtrack_id": "456824",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00044232",
            "google_entity_id": "/m/0w32xrn",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1988-02-17",
            "leadership_role": null,
            "fec_candidate_id": "H0KS02188",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 9,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 90.94,
            "votes_against_party_pct": 7.12,
            "ocd_id": "ocd-division/country:us/state:ks/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-KS-02--L000266": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "M000871",
            "in_office": true,
            "firstName": "Tracey",
            "lastName": "Mann",
            "middleName": null,
            "fullName": "Tracey Mann",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M000871.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-KS-01--M000871",
              "builtID": "ks--01",
              "externalID": "M000871",
              "geometry": null,
              "geoid": "2001"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "344 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2715",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMann",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://mann.house.gov"],
              "rss_url": null
            },
            "title": "KS House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M000871.json",
            "govtrack_id": "456823",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00030743",
            "google_entity_id": "/g/11hcbb5b38",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-12-17",
            "leadership_role": null,
            "fec_candidate_id": "H0KS01123",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 3,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.28,
            "votes_with_party_pct": 92.73,
            "votes_against_party_pct": 5.33,
            "ocd_id": "ocd-division/country:us/state:ks/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-KS-01--M000871": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/b08a0272-189a-4034-9f35-494ffd6cf142",
            "firstName": "Michael",
            "lastName": "Houser",
            "fullName": "Mike Houser",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/m/images/pics/rep_houser_michael_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-KS-1-lower-ocd-person-b08a0272-189a-4034-9f35-494ffd6cf142",
              "builtID": "ks-lower-1",
              "externalID": "ocd-person/b08a0272-189a-4034-9f35-494ffd6cf142",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.houser@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_houser_michael_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_houser_michael_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_houser_michael_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-1-lower-ocd-person-b08a0272-189a-4034-9f35-494ffd6cf142": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/9d1f2540-8f56-49c1-be08-2ee7b7faed8e",
            "firstName": "Kenneth",
            "lastName": "Collins",
            "fullName": "Ken Collins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://lirp.cdn-website.com/eb5f99f9/dms3rep/multi/opt/KEN-COLLINS-1920w.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-KS-2-lower-ocd-person-9d1f2540-8f56-49c1-be08-2ee7b7faed8e",
              "builtID": "ks-lower-2",
              "externalID": "ocd-person/9d1f2540-8f56-49c1-be08-2ee7b7faed8e",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.collins@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_collins_kenneth_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_collins_kenneth_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_collins_kenneth_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-2-lower-ocd-person-9d1f2540-8f56-49c1-be08-2ee7b7faed8e": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/8b617466-af12-4848-8cca-ed088fb196da",
            "firstName": "Charles",
            "lastName": "Smith",
            "fullName": "Chuck Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_smith_charles_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-KS-3-lower-ocd-person-8b617466-af12-4848-8cca-ed088fb196da",
              "builtID": "ks-lower-3",
              "externalID": "ocd-person/8b617466-af12-4848-8cca-ed088fb196da",
              "geometry": null
            },
            "contactInfo": {
              "email": "chuck.smith@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_smith_charles_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_smith_charles_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-3-lower-ocd-person-8b617466-af12-4848-8cca-ed088fb196da": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/803e1253-a98c-4c6f-95fb-36b3034983eb",
            "firstName": "Trevor",
            "lastName": "Jacobs",
            "fullName": "Trevor Jacobs",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_jacobs_trevor_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-KS-4-lower-ocd-person-803e1253-a98c-4c6f-95fb-36b3034983eb",
              "builtID": "ks-lower-4",
              "externalID": "ocd-person/803e1253-a98c-4c6f-95fb-36b3034983eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "trevor.jacobs@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_jacobs_trevor_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_jacobs_trevor_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_jacobs_trevor_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-4-lower-ocd-person-803e1253-a98c-4c6f-95fb-36b3034983eb": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/986eb0cc-71bf-4790-8618-5046163cdd9f",
            "firstName": "Carrie Wiseman",
            "lastName": "Barth",
            "fullName": "Carrie Barth",
            "gender": "Female",
            "party": "Republican",
            "image": "https://static.wixstatic.com/media/26da8c_00eb5164120246329992495e88a15fb8~mv2.jpg/v1/fill/w_188,h_284,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/carrie003_edited.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-KS-5-lower-ocd-person-986eb0cc-71bf-4790-8618-5046163cdd9f",
              "builtID": "ks-lower-5",
              "externalID": "ocd-person/986eb0cc-71bf-4790-8618-5046163cdd9f",
              "geometry": null
            },
            "contactInfo": {
              "email": "carrie.barth@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_barth_carrie_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-5-lower-ocd-person-986eb0cc-71bf-4790-8618-5046163cdd9f": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/31351496-5fc5-44fc-911f-0e7547a50a72",
            "firstName": "Samantha M.",
            "lastName": "Poetter Parshall",
            "fullName": "Samantha Poetter Parshall",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_poetter_samantha_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-KS-6-lower-ocd-person-31351496-5fc5-44fc-911f-0e7547a50a72",
              "builtID": "ks-lower-6",
              "externalID": "ocd-person/31351496-5fc5-44fc-911f-0e7547a50a72",
              "geometry": null
            },
            "contactInfo": {
              "email": "samantha.poetter@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_poetter_samantha_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_poetter_samantha_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-6-lower-ocd-person-31351496-5fc5-44fc-911f-0e7547a50a72": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/2d7cf122-fd6c-406c-a158-de6c61ff0afa",
            "firstName": "Dan",
            "lastName": "Goddard",
            "fullName": "Dan Goddard",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/s/images/pics/rep_goddard_dan_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-KS-7-lower-ocd-person-2d7cf122-fd6c-406c-a158-de6c61ff0afa",
              "builtID": "ks-lower-7",
              "externalID": "ocd-person/2d7cf122-fd6c-406c-a158-de6c61ff0afa",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.goddard@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_goddard_dan_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-7-lower-ocd-person-2d7cf122-fd6c-406c-a158-de6c61ff0afa": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/e0cd5e74-7229-4c04-be9e-aa688868e703",
            "firstName": "Christopher D.",
            "lastName": "Croft",
            "fullName": "Chris Croft",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_croft_chris_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-KS-8-lower-ocd-person-e0cd5e74-7229-4c04-be9e-aa688868e703",
              "builtID": "ks-lower-8",
              "externalID": "ocd-person/e0cd5e74-7229-4c04-be9e-aa688868e703",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.croft@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_croft_chris_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_croft_chris_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_croft_chris_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-8-lower-ocd-person-e0cd5e74-7229-4c04-be9e-aa688868e703": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/3ebdf5bf-c602-4181-914d-fd63b564ea2b",
            "firstName": "Fred",
            "lastName": "Gardner",
            "fullName": "Fred Gardner",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/s/images/pics/rep_gardner_fred_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-KS-9-lower-ocd-person-3ebdf5bf-c602-4181-914d-fd63b564ea2b",
              "builtID": "ks-lower-9",
              "externalID": "ocd-person/3ebdf5bf-c602-4181-914d-fd63b564ea2b",
              "geometry": null
            },
            "contactInfo": {
              "email": "fred.gardner@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_gardner_fred_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-9-lower-ocd-person-3ebdf5bf-c602-4181-914d-fd63b564ea2b": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/dd195ea4-3f71-41f2-af19-95d60b252348",
            "firstName": "Christina",
            "lastName": "Haswood",
            "fullName": "Christina Haswood",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ogden_images.s3.amazonaws.com/www.ljworld.com/images/2020/05/26135513/FullSizeRender.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-KS-10-lower-ocd-person-dd195ea4-3f71-41f2-af19-95d60b252348",
              "builtID": "ks-lower-10",
              "externalID": "ocd-person/dd195ea4-3f71-41f2-af19-95d60b252348",
              "geometry": null
            },
            "contactInfo": {
              "email": "christina.haswood@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_haswood_christina_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_haswood_christina_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-10-lower-ocd-person-dd195ea4-3f71-41f2-af19-95d60b252348": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/61d402db-ed53-4310-9c6b-c392755834a6",
            "firstName": "Ron",
            "lastName": "Bryce",
            "fullName": "Ron Bryce",
            "gender": "Male",
            "party": "Republican",
            "image": "https://images.squarespace-cdn.com/content/v1/6274282b82ca3f5d6291f52c/f64184b5-0bca-4335-a38c-82d9c0699191/0L4B7244.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-KS-11-lower-ocd-person-61d402db-ed53-4310-9c6b-c392755834a6",
              "builtID": "ks-lower-11",
              "externalID": "ocd-person/61d402db-ed53-4310-9c6b-c392755834a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "ron.bryce@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_bryce_ron_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-11-lower-ocd-person-61d402db-ed53-4310-9c6b-c392755834a6": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/050b34c6-14a3-4998-a67a-13c02217a666",
            "firstName": "Charles Douglas",
            "lastName": "Blex",
            "fullName": "Doug Blex",
            "gender": "Male",
            "party": "Republican",
            "image": "https://kggfradio.com/assets/images/blogs/2020/Doug_Blex.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-KS-12-lower-ocd-person-050b34c6-14a3-4998-a67a-13c02217a666",
              "builtID": "ks-lower-12",
              "externalID": "ocd-person/050b34c6-14a3-4998-a67a-13c02217a666",
              "geometry": null
            },
            "contactInfo": {
              "email": "doug.blex@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_blex_doug_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_blex_doug_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_blex_doug_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-12-lower-ocd-person-050b34c6-14a3-4998-a67a-13c02217a666": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/75db09fb-b9dd-4c6f-ae73-bfde55d7dd62",
            "firstName": "Duane",
            "lastName": "Droge",
            "fullName": "Duane Droge",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.newyork1.vip.townnews.com/emporiagazette.com/content/tncms/assets/v3/editorial/2/a8/2a8fea5a-8f9b-11ed-a14b-337129534442/63bb357bdc02b.image.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-KS-13-lower-ocd-person-75db09fb-b9dd-4c6f-ae73-bfde55d7dd62",
              "builtID": "ks-lower-13",
              "externalID": "ocd-person/75db09fb-b9dd-4c6f-ae73-bfde55d7dd62",
              "geometry": null
            },
            "contactInfo": {
              "email": "duane.droge@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_droge_duane_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-13-lower-ocd-person-75db09fb-b9dd-4c6f-ae73-bfde55d7dd62": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/c958af46-da23-478b-bef4-bcbf2e51f57a",
            "firstName": "Dennis",
            "lastName": "Miller",
            "fullName": "Dennis Miller",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.kslegislature.org/li/s/images/pics/rep_miller_dennis_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-KS-14-lower-ocd-person-c958af46-da23-478b-bef4-bcbf2e51f57a",
              "builtID": "ks-lower-14",
              "externalID": "ocd-person/c958af46-da23-478b-bef4-bcbf2e51f57a",
              "geometry": null
            },
            "contactInfo": {
              "email": "dennis.miller@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_miller_dennis_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-14-lower-ocd-person-c958af46-da23-478b-bef4-bcbf2e51f57a": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/4fe036b2-a1f7-4c21-be47-a8f5f27260d8",
            "firstName": "Allison",
            "lastName": "Hougland",
            "fullName": "Allison Hougland",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://us1-photo.nextdoor.com/pages_avatar_photos/20/30/2030af523c3fc8dfca70f487cc54bb74.jpeg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-KS-15-lower-ocd-person-4fe036b2-a1f7-4c21-be47-a8f5f27260d8",
              "builtID": "ks-lower-15",
              "externalID": "ocd-person/4fe036b2-a1f7-4c21-be47-a8f5f27260d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "allison.hougland@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_hougland_allison_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-15-lower-ocd-person-4fe036b2-a1f7-4c21-be47-a8f5f27260d8": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/dd5d95d7-16a3-4094-8c3f-d1319fb5b61a",
            "firstName": "Linda",
            "lastName": "Featherston",
            "fullName": "Linda Featherston",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Linda-Featherston.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-KS-16-lower-ocd-person-dd5d95d7-16a3-4094-8c3f-d1319fb5b61a",
              "builtID": "ks-lower-16",
              "externalID": "ocd-person/dd5d95d7-16a3-4094-8c3f-d1319fb5b61a",
              "geometry": null
            },
            "contactInfo": {
              "email": "linda.featherston@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_featherston_linda_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_featherston_linda_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-16-lower-ocd-person-dd5d95d7-16a3-4094-8c3f-d1319fb5b61a": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/f66696d0-6a3c-4b91-9f41-ed016e74d4ef",
            "firstName": "Jo Ella",
            "lastName": "Hoye",
            "fullName": "Jo Ella Hoye",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Jo-Ella-Hoye.PNG",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-KS-17-lower-ocd-person-f66696d0-6a3c-4b91-9f41-ed016e74d4ef",
              "builtID": "ks-lower-17",
              "externalID": "ocd-person/f66696d0-6a3c-4b91-9f41-ed016e74d4ef",
              "geometry": null
            },
            "contactInfo": {
              "email": "joella.hoye@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_hoye_jo_ella_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_hoye_jo_ella_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-17-lower-ocd-person-f66696d0-6a3c-4b91-9f41-ed016e74d4ef": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/eafd24b5-d096-4fa0-92c4-3b4ced58c40f",
            "firstName": "Cindy",
            "lastName": "Neighbor",
            "fullName": "Cindy Neighbor",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_neighbor_cindy_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-KS-18-lower-ocd-person-eafd24b5-d096-4fa0-92c4-3b4ced58c40f",
              "builtID": "ks-lower-18",
              "externalID": "ocd-person/eafd24b5-d096-4fa0-92c4-3b4ced58c40f",
              "geometry": null
            },
            "contactInfo": {
              "email": "cindy.neighbor@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_neighbor_cindy_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_neighbor_cindy_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_neighbor_cindy_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-18-lower-ocd-person-eafd24b5-d096-4fa0-92c4-3b4ced58c40f": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/6c410bc7-76fd-4488-aca4-5158a5a55d70",
            "firstName": "Stephanie",
            "lastName": "Sawyer-Clayton",
            "fullName": "Stephanie Sawyer-Clayton",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_clayton_stephanie_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-KS-19-lower-ocd-person-6c410bc7-76fd-4488-aca4-5158a5a55d70",
              "builtID": "ks-lower-19",
              "externalID": "ocd-person/6c410bc7-76fd-4488-aca4-5158a5a55d70",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephanie.clayton@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_clayton_stephanie_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_clayton_stephanie_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_clayton_stephanie_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-19-lower-ocd-person-6c410bc7-76fd-4488-aca4-5158a5a55d70": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/46d9caea-ab2c-4a6e-a08c-ebc927403dc8",
            "firstName": "Mari-Lynn",
            "lastName": "Poskin",
            "fullName": "Mari-Lynn Poskin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_poskin_mari_lynn_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-KS-20-lower-ocd-person-46d9caea-ab2c-4a6e-a08c-ebc927403dc8",
              "builtID": "ks-lower-20",
              "externalID": "ocd-person/46d9caea-ab2c-4a6e-a08c-ebc927403dc8",
              "geometry": null
            },
            "contactInfo": {
              "email": "mari-lynn.poskin@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_poskin_mari_lynn_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_poskin_mari_lynn_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-20-lower-ocd-person-46d9caea-ab2c-4a6e-a08c-ebc927403dc8": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/4a358caf-0fad-4d45-8fa7-3c5ef062a544",
            "firstName": "Jerry",
            "lastName": "Stogsdill",
            "fullName": "Jerry Stogsdill",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.kslegislature.org/li/m/images/pics/rep_stogsdill_jerry_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-KS-21-lower-ocd-person-4a358caf-0fad-4d45-8fa7-3c5ef062a544",
              "builtID": "ks-lower-21",
              "externalID": "ocd-person/4a358caf-0fad-4d45-8fa7-3c5ef062a544",
              "geometry": null
            },
            "contactInfo": {
              "email": "jerry.stogsdill@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_stogsdill_jerry_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_stogsdill_jerry_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_stogsdill_jerry_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-21-lower-ocd-person-4a358caf-0fad-4d45-8fa7-3c5ef062a544": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/4a16a87e-2567-4361-9874-aaee47278840",
            "firstName": "Lindsay",
            "lastName": "Vaughn",
            "fullName": "Lindsay Vaughn",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_vaughn_lindsay_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-KS-22-lower-ocd-person-4a16a87e-2567-4361-9874-aaee47278840",
              "builtID": "ks-lower-22",
              "externalID": "ocd-person/4a16a87e-2567-4361-9874-aaee47278840",
              "geometry": null
            },
            "contactInfo": {
              "email": "lindsay.vaughn@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_vaughn_lindsay_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_vaughn_lindsay_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-22-lower-ocd-person-4a16a87e-2567-4361-9874-aaee47278840": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/c06bc779-d072-495b-95f8-e5f4e67bdd4c",
            "firstName": "Susan",
            "lastName": "Ruiz",
            "fullName": "Susan Ruiz",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.kslegislature.org/li/s/images/pics/rep_ruiz_susan_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-KS-23-lower-ocd-person-c06bc779-d072-495b-95f8-e5f4e67bdd4c",
              "builtID": "ks-lower-23",
              "externalID": "ocd-person/c06bc779-d072-495b-95f8-e5f4e67bdd4c",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.ruiz@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_ruiz_susan_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_ruiz_susan_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_ruiz_susan_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-23-lower-ocd-person-c06bc779-d072-495b-95f8-e5f4e67bdd4c": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/65fa3818-4803-4821-8533-51a7802694ec",
            "firstName": "Jarrod",
            "lastName": "Ousley",
            "fullName": "Jarrod Ousley",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.kslegislature.org/li/m/images/pics/rep_ousley_jarrod_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-KS-24-lower-ocd-person-65fa3818-4803-4821-8533-51a7802694ec",
              "builtID": "ks-lower-24",
              "externalID": "ocd-person/65fa3818-4803-4821-8533-51a7802694ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "jarrod.ousley@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_ousley_jarrod_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_ousley_jarrod_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_ousley_jarrod_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-24-lower-ocd-person-65fa3818-4803-4821-8533-51a7802694ec": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/c4495c7d-3d3a-4651-9c8b-e3c975c0b84c",
            "firstName": "Rui",
            "lastName": "Xu",
            "fullName": "Rui Xu",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/rui_xu.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-KS-25-lower-ocd-person-c4495c7d-3d3a-4651-9c8b-e3c975c0b84c",
              "builtID": "ks-lower-25",
              "externalID": "ocd-person/c4495c7d-3d3a-4651-9c8b-e3c975c0b84c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rui.xu@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_xu_rui_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_xu_rui_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_xu_rui_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-25-lower-ocd-person-c4495c7d-3d3a-4651-9c8b-e3c975c0b84c": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/2c46aedb-f7a4-4f9a-bade-5ae192acc2a3",
            "firstName": "Adam",
            "lastName": "Thomas",
            "fullName": "Adam Thomas",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_thomas_adam_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-KS-26-lower-ocd-person-2c46aedb-f7a4-4f9a-bade-5ae192acc2a3",
              "builtID": "ks-lower-26",
              "externalID": "ocd-person/2c46aedb-f7a4-4f9a-bade-5ae192acc2a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "adam.thomas@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_thomas_adam_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_thomas_adam_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_thomas_adam_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-26-lower-ocd-person-2c46aedb-f7a4-4f9a-bade-5ae192acc2a3": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/a65df523-1cb7-4ee3-ac85-1eec82b3fe2c",
            "firstName": "Sean E.",
            "lastName": "Tarwater",
            "fullName": "Sean Tarwater",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_tarwater_sean_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-KS-27-lower-ocd-person-a65df523-1cb7-4ee3-ac85-1eec82b3fe2c",
              "builtID": "ks-lower-27",
              "externalID": "ocd-person/a65df523-1cb7-4ee3-ac85-1eec82b3fe2c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sean.tarwater@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_tarwater_sean_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_tarwater_sean_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_tarwater_sean_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-27-lower-ocd-person-a65df523-1cb7-4ee3-ac85-1eec82b3fe2c": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/71b474cb-1732-41fc-b525-4d53911cbbbe",
            "firstName": "Carl",
            "lastName": "Turner",
            "fullName": "Carl Turner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_turner_carl_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-KS-28-lower-ocd-person-71b474cb-1732-41fc-b525-4d53911cbbbe",
              "builtID": "ks-lower-28",
              "externalID": "ocd-person/71b474cb-1732-41fc-b525-4d53911cbbbe",
              "geometry": null
            },
            "contactInfo": {
              "email": "carl.turner@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_turner_carl_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_turner_carl_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-28-lower-ocd-person-71b474cb-1732-41fc-b525-4d53911cbbbe": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/eaf4ca2d-ba48-4c99-88cc-2845e7237143",
            "firstName": "Heather McCormick",
            "lastName": "Meyer",
            "fullName": "Heather Meyer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_meyer_heather_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-KS-29-lower-ocd-person-eaf4ca2d-ba48-4c99-88cc-2845e7237143",
              "builtID": "ks-lower-29",
              "externalID": "ocd-person/eaf4ca2d-ba48-4c99-88cc-2845e7237143",
              "geometry": null
            },
            "contactInfo": {
              "email": "heather.meyer@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_meyer_heather_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_meyer_heather_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-29-lower-ocd-person-eaf4ca2d-ba48-4c99-88cc-2845e7237143": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/0ebc0742-f24c-4ab3-9155-7a4c37e2fa87",
            "firstName": "Laura",
            "lastName": "Williams",
            "fullName": "Laura Williams",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/s/images/pics/rep_williams_laura_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-KS-30-lower-ocd-person-0ebc0742-f24c-4ab3-9155-7a4c37e2fa87",
              "builtID": "ks-lower-30",
              "externalID": "ocd-person/0ebc0742-f24c-4ab3-9155-7a4c37e2fa87",
              "geometry": null
            },
            "contactInfo": {
              "email": "laura.williams@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_williams_laura_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-30-lower-ocd-person-0ebc0742-f24c-4ab3-9155-7a4c37e2fa87": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/6a7f0eed-6a34-4bd2-842c-2978ca9c6008",
            "firstName": "Louis",
            "lastName": "Ruiz",
            "fullName": "Louis Ruiz",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_ruiz_louis_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-KS-31-lower-ocd-person-6a7f0eed-6a34-4bd2-842c-2978ca9c6008",
              "builtID": "ks-lower-31",
              "externalID": "ocd-person/6a7f0eed-6a34-4bd2-842c-2978ca9c6008",
              "geometry": null
            },
            "contactInfo": {
              "email": "louis.ruiz@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_ruiz_louis_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_ruiz_louis_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_ruiz_louis_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-31-lower-ocd-person-6a7f0eed-6a34-4bd2-842c-2978ca9c6008": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/57e6524f-40c5-4708-89ad-c80570bf31b1",
            "firstName": "Pam",
            "lastName": "Curtis",
            "fullName": "Pam Curtis",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.kcchamber.com/sites/default/files/2018-11/img-staff-pam-curtis.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-KS-32-lower-ocd-person-57e6524f-40c5-4708-89ad-c80570bf31b1",
              "builtID": "ks-lower-32",
              "externalID": "ocd-person/57e6524f-40c5-4708-89ad-c80570bf31b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "pam.curtis@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_curtis_pam_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_curtis_pam_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_curtis_pam_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-32-lower-ocd-person-57e6524f-40c5-4708-89ad-c80570bf31b1": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/3a3759b4-7380-4ea8-acdf-bec3f61929c5",
            "firstName": "Mike",
            "lastName": "Thompson",
            "fullName": "Mike Thompson",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/s/images/pics/rep_thompson_mike_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-KS-33-lower-ocd-person-3a3759b4-7380-4ea8-acdf-bec3f61929c5",
              "builtID": "ks-lower-33",
              "externalID": "ocd-person/3a3759b4-7380-4ea8-acdf-bec3f61929c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.thompson@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_thompson_mike_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-33-lower-ocd-person-3a3759b4-7380-4ea8-acdf-bec3f61929c5": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/8d608ede-12f3-4ec4-8206-2c3dde0db6b7",
            "firstName": "Valdenia Camille",
            "lastName": "Winn",
            "fullName": "Valdenia Winn",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_winn_valdenia_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-KS-34-lower-ocd-person-8d608ede-12f3-4ec4-8206-2c3dde0db6b7",
              "builtID": "ks-lower-34",
              "externalID": "ocd-person/8d608ede-12f3-4ec4-8206-2c3dde0db6b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "valdenia.winn@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_winn_valdenia_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_winn_valdenia_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_winn_valdenia_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-34-lower-ocd-person-8d608ede-12f3-4ec4-8206-2c3dde0db6b7": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/1ec315c1-3c78-4239-9162-9094c55a75ff",
            "firstName": "Marvin S.",
            "lastName": "Robinson",
            "fullName": "Marvin Robinson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://static.wixstatic.com/media/b01d55_df9e283e534144ddb1d46875c32aaef5~mv2.png/v1/crop/x_0,y_23,w_283,h_283/fill/w_225,h_225,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Marvin%20Robinson%20II.png",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-KS-35-lower-ocd-person-1ec315c1-3c78-4239-9162-9094c55a75ff",
              "builtID": "ks-lower-35",
              "externalID": "ocd-person/1ec315c1-3c78-4239-9162-9094c55a75ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "marvin.robinson@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_robinson_marvin_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-35-lower-ocd-person-1ec315c1-3c78-4239-9162-9094c55a75ff": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/c1529b6d-c377-4491-9397-d61c1301243a",
            "firstName": "Lynn",
            "lastName": "Melton",
            "fullName": "Lynn Melton",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/LynnMelton.jpeg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-KS-36-lower-ocd-person-c1529b6d-c377-4491-9397-d61c1301243a",
              "builtID": "ks-lower-36",
              "externalID": "ocd-person/c1529b6d-c377-4491-9397-d61c1301243a",
              "geometry": null
            },
            "contactInfo": {
              "email": "lynn.melton@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_melton_lynn_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-36-lower-ocd-person-c1529b6d-c377-4491-9397-d61c1301243a": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/faf6de90-d016-4f46-8b12-0be42d361030",
            "firstName": "Melissa",
            "lastName": "Oropeza",
            "fullName": "Melissa Oropeza",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://static.wixstatic.com/media/b85886_bf3234c73f3d4fc9860eabd10f95d123~mv2.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-KS-37-lower-ocd-person-faf6de90-d016-4f46-8b12-0be42d361030",
              "builtID": "ks-lower-37",
              "externalID": "ocd-person/faf6de90-d016-4f46-8b12-0be42d361030",
              "geometry": null
            },
            "contactInfo": {
              "email": "melissa.oropeza@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_oropeza_melissa_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-37-lower-ocd-person-faf6de90-d016-4f46-8b12-0be42d361030": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/ecff64bf-dfd7-4df4-a1c2-681348e68fa8",
            "firstName": "Timothy H.",
            "lastName": "Johnson",
            "fullName": "Tim Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Timothy-Johnson.PNG",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-KS-38-lower-ocd-person-ecff64bf-dfd7-4df4-a1c2-681348e68fa8",
              "builtID": "ks-lower-38",
              "externalID": "ocd-person/ecff64bf-dfd7-4df4-a1c2-681348e68fa8",
              "geometry": null
            },
            "contactInfo": {
              "email": "timothy.johnson@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_johnson_timothy_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_johnson_timothy_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-38-lower-ocd-person-ecff64bf-dfd7-4df4-a1c2-681348e68fa8": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/e0cddd71-639c-4b74-8dea-bc189418034d",
            "firstName": "Angela",
            "lastName": "Stiens",
            "fullName": "Angela Stiens",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_stiens_angela_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-KS-39-lower-ocd-person-e0cddd71-639c-4b74-8dea-bc189418034d",
              "builtID": "ks-lower-39",
              "externalID": "ocd-person/e0cddd71-639c-4b74-8dea-bc189418034d",
              "geometry": null
            },
            "contactInfo": {
              "email": "angela.stiens@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_stiens_angela_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-39-lower-ocd-person-e0cddd71-639c-4b74-8dea-bc189418034d": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/0a2abc46-725c-4637-bf38-dc83cb7f90fd",
            "firstName": "David",
            "lastName": "Buehler",
            "fullName": "Dave Buehler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://images.squarespace-cdn.com/content/v1/62829028cd859a1538cdefe4/1653320055752-3YTFP1H6E7GEFQ80IWLE/NavyHeadshot.jpg?format=750w",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-KS-40-lower-ocd-person-0a2abc46-725c-4637-bf38-dc83cb7f90fd",
              "builtID": "ks-lower-40",
              "externalID": "ocd-person/0a2abc46-725c-4637-bf38-dc83cb7f90fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.buehler@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_buehler_david_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-40-lower-ocd-person-0a2abc46-725c-4637-bf38-dc83cb7f90fd": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/fcd23935-0e7e-4896-9381-45ca745e8903",
            "firstName": "Pat",
            "lastName": "Proctor",
            "fullName": "Pat Proctor",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_proctor_pat_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-KS-41-lower-ocd-person-fcd23935-0e7e-4896-9381-45ca745e8903",
              "builtID": "ks-lower-41",
              "externalID": "ocd-person/fcd23935-0e7e-4896-9381-45ca745e8903",
              "geometry": null
            },
            "contactInfo": {
              "email": "pat.proctor@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_proctor_pat_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_proctor_pat_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-41-lower-ocd-person-fcd23935-0e7e-4896-9381-45ca745e8903": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/a1546322-9d4b-467c-a65a-57dddcc9c4cf",
            "firstName": "Lance W.",
            "lastName": "Neelly",
            "fullName": "Lance Neelly",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_neelly_lance_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-KS-42-lower-ocd-person-a1546322-9d4b-467c-a65a-57dddcc9c4cf",
              "builtID": "ks-lower-42",
              "externalID": "ocd-person/a1546322-9d4b-467c-a65a-57dddcc9c4cf",
              "geometry": null
            },
            "contactInfo": {
              "email": "lance.neelly@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_neelly_lance_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_neelly_lance_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-42-lower-ocd-person-a1546322-9d4b-467c-a65a-57dddcc9c4cf": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/dc23fc2c-0f64-4fab-96ec-951f0efbe7bb",
            "firstName": "William",
            "lastName": "Sutton",
            "fullName": "Bill Sutton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_sutton_william_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-KS-43-lower-ocd-person-dc23fc2c-0f64-4fab-96ec-951f0efbe7bb",
              "builtID": "ks-lower-43",
              "externalID": "ocd-person/dc23fc2c-0f64-4fab-96ec-951f0efbe7bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.sutton@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_sutton_william_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_sutton_william_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_sutton_william_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-43-lower-ocd-person-dc23fc2c-0f64-4fab-96ec-951f0efbe7bb": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/3bf41768-e1b5-4017-8ab5-8d1fb1d6521c",
            "firstName": "Barbara W.",
            "lastName": "Ballard",
            "fullName": "Barbara Ballard",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://ogden_images.s3.amazonaws.com/www.ljworld.com/images/2016/07/20043350/candidate_Barbara_Ballard003-714x1024.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-KS-44-lower-ocd-person-3bf41768-e1b5-4017-8ab5-8d1fb1d6521c",
              "builtID": "ks-lower-44",
              "externalID": "ocd-person/3bf41768-e1b5-4017-8ab5-8d1fb1d6521c",
              "geometry": null
            },
            "contactInfo": {
              "email": "barbara.ballard@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_ballard_barbara_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_ballard_barbara_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_ballard_barbara_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-44-lower-ocd-person-3bf41768-e1b5-4017-8ab5-8d1fb1d6521c": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/05204bb3-1917-4f12-848e-b83f68f5fddf",
            "firstName": "Mike",
            "lastName": "Amyx",
            "fullName": "Mike Amyx",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assets.lawrenceks.org/images/commission/mike-amyx.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-KS-45-lower-ocd-person-05204bb3-1917-4f12-848e-b83f68f5fddf",
              "builtID": "ks-lower-45",
              "externalID": "ocd-person/05204bb3-1917-4f12-848e-b83f68f5fddf",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.amyx@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_amyx_mike_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_amyx_mike_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_amyx_mike_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-45-lower-ocd-person-05204bb3-1917-4f12-848e-b83f68f5fddf": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/2b1eb7a3-d80a-44bf-b3b1-ca2c6d037d93",
            "firstName": "Dennis",
            "lastName": "Highberger",
            "fullName": "Boog Highberger",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_highberger_dennis_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-KS-46-lower-ocd-person-2b1eb7a3-d80a-44bf-b3b1-ca2c6d037d93",
              "builtID": "ks-lower-46",
              "externalID": "ocd-person/2b1eb7a3-d80a-44bf-b3b1-ca2c6d037d93",
              "geometry": null
            },
            "contactInfo": {
              "email": "dennis.boog.highberger@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_highberger_dennis_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_highberger_dennis_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_highberger_dennis_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-46-lower-ocd-person-2b1eb7a3-d80a-44bf-b3b1-ca2c6d037d93": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/6daed22d-40c3-492c-948a-d3de94499e53",
            "firstName": "Ronald B.",
            "lastName": "Ellis",
            "fullName": "Ronald Ellis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gannett-cdn.com/authoring/2018/10/13/NTCJ/ghows-KS-781e2876-8aa2-2177-e053-0100007f5e86-b578f832.jpeg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-KS-47-lower-ocd-person-6daed22d-40c3-492c-948a-d3de94499e53",
              "builtID": "ks-lower-47",
              "externalID": "ocd-person/6daed22d-40c3-492c-948a-d3de94499e53",
              "geometry": null
            },
            "contactInfo": {
              "email": "ronald.ellis@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_ellis_ronald_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_ellis_ronald_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_ellis_ronald_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-47-lower-ocd-person-6daed22d-40c3-492c-948a-d3de94499e53": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/d9b94e4b-d2b0-4139-8135-cba9ff03e8b7",
            "firstName": "Dan",
            "lastName": "Osman",
            "fullName": "Dan Osman",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.kslegislature.org/li/s/images/pics/rep_osman_dan_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-KS-48-lower-ocd-person-d9b94e4b-d2b0-4139-8135-cba9ff03e8b7",
              "builtID": "ks-lower-48",
              "externalID": "ocd-person/d9b94e4b-d2b0-4139-8135-cba9ff03e8b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.osman@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_osman_dan_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_osman_dan_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-48-lower-ocd-person-d9b94e4b-d2b0-4139-8135-cba9ff03e8b7": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/7bb01d00-be23-4fb4-ab33-074c2826b69e",
            "firstName": "Nikki Johnston",
            "lastName": "McDonald",
            "fullName": "Nikki McDonald",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_mcdonald_nikki_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-KS-49-lower-ocd-person-7bb01d00-be23-4fb4-ab33-074c2826b69e",
              "builtID": "ks-lower-49",
              "externalID": "ocd-person/7bb01d00-be23-4fb4-ab33-074c2826b69e",
              "geometry": null
            },
            "contactInfo": {
              "email": "nikki.mcdonald@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_mcdonald_nikki_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-49-lower-ocd-person-7bb01d00-be23-4fb4-ab33-074c2826b69e": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/2abcd1bf-54b0-4abe-a716-065895d88d6b",
            "firstName": "Kyle",
            "lastName": "McNorton",
            "fullName": "Kyle McNorton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_mcnorton_kyle_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-KS-50-lower-ocd-person-2abcd1bf-54b0-4abe-a716-065895d88d6b",
              "builtID": "ks-lower-50",
              "externalID": "ocd-person/2abcd1bf-54b0-4abe-a716-065895d88d6b",
              "geometry": null
            },
            "contactInfo": {
              "email": "kyle.mcnorton@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_mcnorton_kyle_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-50-lower-ocd-person-2abcd1bf-54b0-4abe-a716-065895d88d6b": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/8a353296-bd2d-4fd6-b1b9-d8b3c8b3f771",
            "firstName": "Kenny",
            "lastName": "Titus",
            "fullName": "Kenny Titus",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_titus_kenny_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-KS-51-lower-ocd-person-8a353296-bd2d-4fd6-b1b9-d8b3c8b3f771",
              "builtID": "ks-lower-51",
              "externalID": "ocd-person/8a353296-bd2d-4fd6-b1b9-d8b3c8b3f771",
              "geometry": null
            },
            "contactInfo": {
              "email": "kenny.titus@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_titus_kenny_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-51-lower-ocd-person-8a353296-bd2d-4fd6-b1b9-d8b3c8b3f771": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/0c2ba27f-4208-475d-a625-a12c5f0581b5",
            "firstName": "Jesse",
            "lastName": "Borjon",
            "fullName": "Jesse Borjon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Jesse-Borjon.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-KS-52-lower-ocd-person-0c2ba27f-4208-475d-a625-a12c5f0581b5",
              "builtID": "ks-lower-52",
              "externalID": "ocd-person/0c2ba27f-4208-475d-a625-a12c5f0581b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "jesse.borjon@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_borjon_jesse_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_borjon_jesse_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-52-lower-ocd-person-0c2ba27f-4208-475d-a625-a12c5f0581b5": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/a81f29c0-88a5-4984-a36e-214eddb912fb",
            "firstName": "Kirk",
            "lastName": "Haskins",
            "fullName": "Kirk Haskins",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://static.wixstatic.com/media/ebdb2c_75a5a6468d2d4f6e8c98ea016b1ec6b6~mv2.jpg/v1/fill/w_425,h_639,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/headshot_touched_up_final.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-KS-53-lower-ocd-person-a81f29c0-88a5-4984-a36e-214eddb912fb",
              "builtID": "ks-lower-53",
              "externalID": "ocd-person/a81f29c0-88a5-4984-a36e-214eddb912fb",
              "geometry": null
            },
            "contactInfo": {
              "email": "kirk.haskins@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_haskins_kirk_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-53-lower-ocd-person-a81f29c0-88a5-4984-a36e-214eddb912fb": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/afee9b4c-c30b-4cba-b5a2-e20a186c75b1",
            "firstName": "Ken",
            "lastName": "Corbet",
            "fullName": "Ken Corbet",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ogden_images.s3.amazonaws.com/www.ljworld.com/images/2012/10/21162428/corbet_ken-682x1024.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-KS-54-lower-ocd-person-afee9b4c-c30b-4cba-b5a2-e20a186c75b1",
              "builtID": "ks-lower-54",
              "externalID": "ocd-person/afee9b4c-c30b-4cba-b5a2-e20a186c75b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.corbet@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_corbet_ken_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_corbet_ken_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_corbet_ken_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-54-lower-ocd-person-afee9b4c-c30b-4cba-b5a2-e20a186c75b1": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/9e2cc4ba-4ac7-4554-ac51-b41f2c3667d1",
            "firstName": "Tobias J.H.",
            "lastName": "Schlingensiepen",
            "fullName": "Tobias Schlingensiepen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://images.squarespace-cdn.com/content/v1/62a93b88228a337fdf4f80e2/1655346125169-V2O5XAAA56EL7GFUJ9L6/Tobias+4KH+0422.jpg?format=500w",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-KS-55-lower-ocd-person-9e2cc4ba-4ac7-4554-ac51-b41f2c3667d1",
              "builtID": "ks-lower-55",
              "externalID": "ocd-person/9e2cc4ba-4ac7-4554-ac51-b41f2c3667d1",
              "geometry": null
            },
            "contactInfo": {
              "email": "tobias.schlingensiepen@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_schlingensiepen_tobias_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-55-lower-ocd-person-9e2cc4ba-4ac7-4554-ac51-b41f2c3667d1": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/acb8f24a-aecd-43a5-887c-be8bbf963e3d",
            "firstName": "Virgil",
            "lastName": "Weigel",
            "fullName": "Virgil Weigel",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_weigel_virgil_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-KS-56-lower-ocd-person-acb8f24a-aecd-43a5-887c-be8bbf963e3d",
              "builtID": "ks-lower-56",
              "externalID": "ocd-person/acb8f24a-aecd-43a5-887c-be8bbf963e3d",
              "geometry": null
            },
            "contactInfo": {
              "email": "virgil.weigel@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_weigel_virgil_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_weigel_virgil_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_weigel_virgil_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-56-lower-ocd-person-acb8f24a-aecd-43a5-887c-be8bbf963e3d": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/f29363f0-8ac1-4c25-a8a7-74db5cefd9a4",
            "firstName": "John",
            "lastName": "Alcala",
            "fullName": "John Alcala",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_alcala_john_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-KS-57-lower-ocd-person-f29363f0-8ac1-4c25-a8a7-74db5cefd9a4",
              "builtID": "ks-lower-57",
              "externalID": "ocd-person/f29363f0-8ac1-4c25-a8a7-74db5cefd9a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.alcala@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_alcala_john_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_alcala_john_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_alcala_john_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-57-lower-ocd-person-f29363f0-8ac1-4c25-a8a7-74db5cefd9a4": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/68dbfc96-2c1d-49e9-af6e-3d4f33b4bce8",
            "firstName": "Victor W.",
            "lastName": "Miller",
            "fullName": "Vic Miller",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/sen_miller_vic_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-KS-58-lower-ocd-person-68dbfc96-2c1d-49e9-af6e-3d4f33b4bce8",
              "builtID": "ks-lower-58",
              "externalID": "ocd-person/68dbfc96-2c1d-49e9-af6e-3d4f33b4bce8",
              "geometry": null
            },
            "contactInfo": {
              "email": "vic.miller@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_miller_vic_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_miller_vic_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_miller_vic_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-58-lower-ocd-person-68dbfc96-2c1d-49e9-af6e-3d4f33b4bce8": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/23c34f8e-b06b-4de9-91c3-44892dbb2b7d",
            "firstName": "Rebecca",
            "lastName": "Schmoe",
            "fullName": "Rebecca Schmoe",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/s/images/pics/rep_schmoe_rebecca_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-KS-59-lower-ocd-person-23c34f8e-b06b-4de9-91c3-44892dbb2b7d",
              "builtID": "ks-lower-59",
              "externalID": "ocd-person/23c34f8e-b06b-4de9-91c3-44892dbb2b7d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rebecca.schmoe@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_schmoe_rebecca_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-59-lower-ocd-person-23c34f8e-b06b-4de9-91c3-44892dbb2b7d": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/1748d756-be07-4eaf-be90-82992092e990",
            "firstName": "Mark",
            "lastName": "Schreiber",
            "fullName": "Mark Schreiber",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.schreiberforkansas.com/wordpress/wp-content/uploads/2021/01/schreiber_header-2021.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-KS-60-lower-ocd-person-1748d756-be07-4eaf-be90-82992092e990",
              "builtID": "ks-lower-60",
              "externalID": "ocd-person/1748d756-be07-4eaf-be90-82992092e990",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.schreiber@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_schreiber_mark_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_schreiber_mark_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_schreiber_mark_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-60-lower-ocd-person-1748d756-be07-4eaf-be90-82992092e990": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/aa7fa5a0-596b-4f52-9af5-732b76f875e9",
            "firstName": "Francis",
            "lastName": "Awerkamp",
            "fullName": "Francis Awerkamp",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_awerkamp_francis_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-KS-61-lower-ocd-person-aa7fa5a0-596b-4f52-9af5-732b76f875e9",
              "builtID": "ks-lower-61",
              "externalID": "ocd-person/aa7fa5a0-596b-4f52-9af5-732b76f875e9",
              "geometry": null
            },
            "contactInfo": {
              "email": "francis.awerkamp@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_awerkamp_francis_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_awerkamp_francis_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_awerkamp_francis_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-61-lower-ocd-person-aa7fa5a0-596b-4f52-9af5-732b76f875e9": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/0c722aa0-4865-4d7b-8ed7-c6537d0a1b71",
            "firstName": "Randy",
            "lastName": "Garber",
            "fullName": "Randy Garber",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.holtonrecorder.net/sites/default/files/styles/galleria_zoom/public/field/image/RandyGarber.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-KS-62-lower-ocd-person-0c722aa0-4865-4d7b-8ed7-c6537d0a1b71",
              "builtID": "ks-lower-62",
              "externalID": "ocd-person/0c722aa0-4865-4d7b-8ed7-c6537d0a1b71",
              "geometry": null
            },
            "contactInfo": {
              "email": "randy.garber@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_garber_randy_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_garber_randy_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_garber_randy_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-62-lower-ocd-person-0c722aa0-4865-4d7b-8ed7-c6537d0a1b71": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/a5e62cf7-f799-4086-9350-516d61316518",
            "firstName": "John R.",
            "lastName": "Eplee",
            "fullName": "John Eplee",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/rep_eplee_john_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-KS-63-lower-ocd-person-a5e62cf7-f799-4086-9350-516d61316518",
              "builtID": "ks-lower-63",
              "externalID": "ocd-person/a5e62cf7-f799-4086-9350-516d61316518",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.eplee@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_eplee_john_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_eplee_john_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_eplee_john_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-63-lower-ocd-person-a5e62cf7-f799-4086-9350-516d61316518": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/e6316819-6e7e-4560-9acf-a7c28e5cfbe7",
            "firstName": "Lewis",
            "lastName": "Bloom",
            "fullName": "Bill Bloom",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Lewis_Bloom.png",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-KS-64-lower-ocd-person-e6316819-6e7e-4560-9acf-a7c28e5cfbe7",
              "builtID": "ks-lower-64",
              "externalID": "ocd-person/e6316819-6e7e-4560-9acf-a7c28e5cfbe7",
              "geometry": null
            },
            "contactInfo": {
              "email": "lewis.bloom@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_bloom_lewis_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-64-lower-ocd-person-e6316819-6e7e-4560-9acf-a7c28e5cfbe7": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/c4117d3c-1538-47f2-8343-3af6900fa4f4",
            "firstName": "Jeff",
            "lastName": "Underhill",
            "fullName": "Jeff Underhill",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/s/images/pics/rep_underhill_jeff_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-KS-65-lower-ocd-person-c4117d3c-1538-47f2-8343-3af6900fa4f4",
              "builtID": "ks-lower-65",
              "externalID": "ocd-person/c4117d3c-1538-47f2-8343-3af6900fa4f4",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.underhill@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_underhill_jeff_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-65-lower-ocd-person-c4117d3c-1538-47f2-8343-3af6900fa4f4": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/61dc8b8f-5016-4114-b08a-b2dd0cb16993",
            "firstName": "Sydney Lynn",
            "lastName": "Carlin",
            "fullName": "Sydney Carlin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assets.nationbuilder.com/carlinforkansas/pages/22/attachments/original/1473126263/IMG_0437.JPG?1473126263",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-KS-66-lower-ocd-person-61dc8b8f-5016-4114-b08a-b2dd0cb16993",
              "builtID": "ks-lower-66",
              "externalID": "ocd-person/61dc8b8f-5016-4114-b08a-b2dd0cb16993",
              "geometry": null
            },
            "contactInfo": {
              "email": "sydney.carlin@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_carlin_sydney_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_carlin_sydney_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_carlin_sydney_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-66-lower-ocd-person-61dc8b8f-5016-4114-b08a-b2dd0cb16993": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/75d4404f-3551-462a-bfce-840afa89f75f",
            "firstName": "Michael L.",
            "lastName": "Dodson",
            "fullName": "Mike Dodson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://mikedodson.org/wp-content/uploads/2015/02/image01.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-KS-67-lower-ocd-person-75d4404f-3551-462a-bfce-840afa89f75f",
              "builtID": "ks-lower-67",
              "externalID": "ocd-person/75d4404f-3551-462a-bfce-840afa89f75f",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.dodson@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_dodson_michael_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_dodson_michael_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-67-lower-ocd-person-75d4404f-3551-462a-bfce-840afa89f75f": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/529d7e5b-3acd-467b-b6b2-bf4314fcf633",
            "firstName": "Nathan",
            "lastName": "Butler",
            "fullName": "Nate Butler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ksn.com/wp-content/uploads/sites/13/2022/07/Nathan-Butler-courtesy-Nathan-Butler-maybe.jpeg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-KS-68-lower-ocd-person-529d7e5b-3acd-467b-b6b2-bf4314fcf633",
              "builtID": "ks-lower-68",
              "externalID": "ocd-person/529d7e5b-3acd-467b-b6b2-bf4314fcf633",
              "geometry": null
            },
            "contactInfo": {
              "email": "nat.butler@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_butler_nathan_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-68-lower-ocd-person-529d7e5b-3acd-467b-b6b2-bf4314fcf633": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/3df522a5-5542-4ad4-b1bf-54736e60c601",
            "firstName": "Clarke J.",
            "lastName": "Sanders",
            "fullName": "Clarke Sanders",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_sanders_clarke_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-KS-69-lower-ocd-person-3df522a5-5542-4ad4-b1bf-54736e60c601",
              "builtID": "ks-lower-69",
              "externalID": "ocd-person/3df522a5-5542-4ad4-b1bf-54736e60c601",
              "geometry": null
            },
            "contactInfo": {
              "email": "clarke.sanders@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_sanders_clarke_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_sanders_clarke_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-69-lower-ocd-person-3df522a5-5542-4ad4-b1bf-54736e60c601": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/64c3ae14-1ec8-46ed-b317-bcd9722319f5",
            "firstName": "Scott",
            "lastName": "Hill",
            "fullName": "Scott Hill",
            "gender": "Male",
            "party": "Republican",
            "image": "https://images.squarespace-cdn.com/content/v1/6222d8028ede8d5851c17efa/7ed784e6-46b5-4b91-b476-b3ed493e8362/press+photo+1.jpg?format=1000w",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-KS-70-lower-ocd-person-64c3ae14-1ec8-46ed-b317-bcd9722319f5",
              "builtID": "ks-lower-70",
              "externalID": "ocd-person/64c3ae14-1ec8-46ed-b317-bcd9722319f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.hill@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_hill_scott_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-70-lower-ocd-person-64c3ae14-1ec8-46ed-b317-bcd9722319f5": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/e5b4d1e8-40a3-4f75-af3a-36fc7e84a228",
            "firstName": "Steven Kirk",
            "lastName": "Howe",
            "fullName": "Steve Howe",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_howe_steven_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-KS-71-lower-ocd-person-e5b4d1e8-40a3-4f75-af3a-36fc7e84a228",
              "builtID": "ks-lower-71",
              "externalID": "ocd-person/e5b4d1e8-40a3-4f75-af3a-36fc7e84a228",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.howe@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_howe_steven_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_howe_steven_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-71-lower-ocd-person-e5b4d1e8-40a3-4f75-af3a-36fc7e84a228": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/4544fc4e-fff8-4bbe-bc29-4e28c6be3179",
            "firstName": "Avery Clay",
            "lastName": "Anderson",
            "fullName": "Avery Anderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gannett-cdn.com/-mm-/b7f21864ea2f692daa800e1f0bbe0a1fdbbdf4cf/c=0-274-5260-3233/local/-/media/2020/11/20/Topeka/ghows-KS-200929929-8133c8fa.jpg?width=660&height=372&fit=crop&format=pjpg&auto=webp",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-KS-72-lower-ocd-person-4544fc4e-fff8-4bbe-bc29-4e28c6be3179",
              "builtID": "ks-lower-72",
              "externalID": "ocd-person/4544fc4e-fff8-4bbe-bc29-4e28c6be3179",
              "geometry": null
            },
            "contactInfo": {
              "email": "avery.anderson@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_anderson_avery_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_anderson_avery_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-72-lower-ocd-person-4544fc4e-fff8-4bbe-bc29-4e28c6be3179": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/bfbcc7a5-6b3c-4b40-9d9a-96db98570167",
            "firstName": "Lori",
            "lastName": "Shultz",
            "fullName": "Lori Shultz",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/LoriShultz2024.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-KS-73-lower-ocd-person-bfbcc7a5-6b3c-4b40-9d9a-96db98570167",
              "builtID": "ks-lower-73",
              "externalID": "ocd-person/bfbcc7a5-6b3c-4b40-9d9a-96db98570167",
              "geometry": null
            },
            "contactInfo": {
              "email": "lori.shultz@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_shultz_lori_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-73-lower-ocd-person-bfbcc7a5-6b3c-4b40-9d9a-96db98570167": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/413cc3ff-a3b9-465b-827d-4aeb4b0b42c1",
            "firstName": "Stephen",
            "lastName": "Owens",
            "fullName": "Stephen Owens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.rethinkkansas.com/images/65480/custom_logo_b94946bbf1294c9e9d596bcf4b66b090.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-KS-74-lower-ocd-person-413cc3ff-a3b9-465b-827d-4aeb4b0b42c1",
              "builtID": "ks-lower-74",
              "externalID": "ocd-person/413cc3ff-a3b9-465b-827d-4aeb4b0b42c1",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephen.owens@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_owens_stephen_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_owens_stephen_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_owens_stephen_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-74-lower-ocd-person-413cc3ff-a3b9-465b-827d-4aeb4b0b42c1": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/695c8e66-9ee6-4431-8bd4-b7435b04e2c7",
            "firstName": "Will",
            "lastName": "Carpenter",
            "fullName": "Will Carpenter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Will_Carpenter.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-KS-75-lower-ocd-person-695c8e66-9ee6-4431-8bd4-b7435b04e2c7",
              "builtID": "ks-lower-75",
              "externalID": "ocd-person/695c8e66-9ee6-4431-8bd4-b7435b04e2c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "will.carpenter@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_carpenter_will_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_carpenter_will_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_carpenter_will_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-75-lower-ocd-person-695c8e66-9ee6-4431-8bd4-b7435b04e2c7": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/0f0f626f-b39a-4a94-9d33-0d8166473d26",
            "firstName": "Eric L.",
            "lastName": "Smith",
            "fullName": "Eric Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_smith_eric_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-KS-76-lower-ocd-person-0f0f626f-b39a-4a94-9d33-0d8166473d26",
              "builtID": "ks-lower-76",
              "externalID": "ocd-person/0f0f626f-b39a-4a94-9d33-0d8166473d26",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.smith@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_smith_eric_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_smith_eric_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_smith_eric_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-76-lower-ocd-person-0f0f626f-b39a-4a94-9d33-0d8166473d26": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/81803637-3bf0-4bbe-9ae4-7724488955b7",
            "firstName": "Kristey",
            "lastName": "Williams",
            "fullName": "Kristey Williams",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_williams_kristey_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-KS-77-lower-ocd-person-81803637-3bf0-4bbe-9ae4-7724488955b7",
              "builtID": "ks-lower-77",
              "externalID": "ocd-person/81803637-3bf0-4bbe-9ae4-7724488955b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "kristey.williams@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_williams_kristey_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_williams_kristey_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_williams_kristey_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-77-lower-ocd-person-81803637-3bf0-4bbe-9ae4-7724488955b7": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/d034154f-ba03-4c97-ac96-c4023968f9b3",
            "firstName": "Robyn R.",
            "lastName": "Essex",
            "fullName": "Robyn Essex",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Robyn_Essex.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-KS-78-lower-ocd-person-d034154f-ba03-4c97-ac96-c4023968f9b3",
              "builtID": "ks-lower-78",
              "externalID": "ocd-person/d034154f-ba03-4c97-ac96-c4023968f9b3",
              "geometry": null
            },
            "contactInfo": {
              "email": "robyn.essex@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_essex_robyn_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-78-lower-ocd-person-d034154f-ba03-4c97-ac96-c4023968f9b3": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/41909a90-1eff-473a-8cb7-3c8770f667f4",
            "firstName": "Webster T.",
            "lastName": "Roth",
            "fullName": "Web Roth",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/s/images/pics/rep_roth_webster_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-KS-79-lower-ocd-person-41909a90-1eff-473a-8cb7-3c8770f667f4",
              "builtID": "ks-lower-79",
              "externalID": "ocd-person/41909a90-1eff-473a-8cb7-3c8770f667f4",
              "geometry": null
            },
            "contactInfo": {
              "email": "webster.roth@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_roth_webster_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-79-lower-ocd-person-41909a90-1eff-473a-8cb7-3c8770f667f4": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/d01abc3e-cce3-450e-8d2c-41491b5bbde6",
            "firstName": "Bill",
            "lastName": "Rhiley",
            "fullName": "Bill Rhiley",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/m/images/pics/rep_rhiley_bill_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-KS-80-lower-ocd-person-d01abc3e-cce3-450e-8d2c-41491b5bbde6",
              "builtID": "ks-lower-80",
              "externalID": "ocd-person/d01abc3e-cce3-450e-8d2c-41491b5bbde6",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.rhiley@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_rhiley_bill_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_rhiley_bill_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_rhiley_bill_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-80-lower-ocd-person-d01abc3e-cce3-450e-8d2c-41491b5bbde6": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/38998536-e18f-44bb-8e80-01bfee04e991",
            "firstName": "Blake",
            "lastName": "Carpenter",
            "fullName": "Blake Carpenter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.carpenterforkansas.com/wp-content/uploads/2022/07/Carpenter-Blues-Photo.png",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-KS-81-lower-ocd-person-38998536-e18f-44bb-8e80-01bfee04e991",
              "builtID": "ks-lower-81",
              "externalID": "ocd-person/38998536-e18f-44bb-8e80-01bfee04e991",
              "geometry": null
            },
            "contactInfo": {
              "email": "blake.carpenter@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_carpenter_blake_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_carpenter_blake_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_carpenter_blake_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-81-lower-ocd-person-38998536-e18f-44bb-8e80-01bfee04e991": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/567cbef1-19d5-4e90-9b32-d7f2d45428d6",
            "firstName": "Leah",
            "lastName": "Howell",
            "fullName": "Leah Howell",
            "gender": "Female",
            "party": "Republican",
            "image": "https://img1.wsimg.com/isteam/ip/534758f8-3fcd-4777-8cd5-06b294ad98e6/Snapseed.JPG/:/cr=t:0%25,l:0%25,w:100%25,h:100%25",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-KS-82-lower-ocd-person-567cbef1-19d5-4e90-9b32-d7f2d45428d6",
              "builtID": "ks-lower-82",
              "externalID": "ocd-person/567cbef1-19d5-4e90-9b32-d7f2d45428d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "leah.howell@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_howell_leah_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-82-lower-ocd-person-567cbef1-19d5-4e90-9b32-d7f2d45428d6": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/d951217c-00c1-4957-842b-02ecf79ee70d",
            "firstName": "Henry M.",
            "lastName": "Helgerson",
            "fullName": "Henry Helgerson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/rep_helgerson_henry_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-KS-83-lower-ocd-person-d951217c-00c1-4957-842b-02ecf79ee70d",
              "builtID": "ks-lower-83",
              "externalID": "ocd-person/d951217c-00c1-4957-842b-02ecf79ee70d",
              "geometry": null
            },
            "contactInfo": {
              "email": "henry.helgerson@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_helgerson_henry_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_helgerson_henry_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_helgerson_henry_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-83-lower-ocd-person-d951217c-00c1-4957-842b-02ecf79ee70d": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/96643a66-5a00-41ba-9aa6-fc126a92849c",
            "firstName": "Ford",
            "lastName": "Carr",
            "fullName": "Ford Carr",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://kansasreflector.com/wp-content/uploads/2022/06/FordCarr-300x202.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-KS-84-lower-ocd-person-96643a66-5a00-41ba-9aa6-fc126a92849c",
              "builtID": "ks-lower-84",
              "externalID": "ocd-person/96643a66-5a00-41ba-9aa6-fc126a92849c",
              "geometry": null
            },
            "contactInfo": {
              "email": "ford.carr@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_carr_ford_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-84-lower-ocd-person-96643a66-5a00-41ba-9aa6-fc126a92849c": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/d7d7bc12-659e-454e-8c26-0f02ba0d5f84",
            "firstName": "Patrick",
            "lastName": "Penn",
            "fullName": "Patrick Penn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_penn_patrick_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-KS-85-lower-ocd-person-d7d7bc12-659e-454e-8c26-0f02ba0d5f84",
              "builtID": "ks-lower-85",
              "externalID": "ocd-person/d7d7bc12-659e-454e-8c26-0f02ba0d5f84",
              "geometry": null
            },
            "contactInfo": {
              "email": "patrick.penn@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_penn_patrick_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_penn_patrick_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-85-lower-ocd-person-d7d7bc12-659e-454e-8c26-0f02ba0d5f84": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/38e5e1b5-72d2-4968-be98-032e8320d475",
            "firstName": "Silas",
            "lastName": "Miller",
            "fullName": "Silas Miller",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://media.sharethetrail.net/prod_d_1/images/silas-miller_5_sq.height-800.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-KS-86-lower-ocd-person-38e5e1b5-72d2-4968-be98-032e8320d475",
              "builtID": "ks-lower-86",
              "externalID": "ocd-person/38e5e1b5-72d2-4968-be98-032e8320d475",
              "geometry": null
            },
            "contactInfo": {
              "email": "silas.miller@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_miller_silas_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-86-lower-ocd-person-38e5e1b5-72d2-4968-be98-032e8320d475": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/075f264c-efcf-408f-baf8-b3f9306bae36",
            "firstName": "Susan Oliver",
            "lastName": "Estes",
            "fullName": "Susan Estes",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Susan-Estes.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-KS-87-lower-ocd-person-075f264c-efcf-408f-baf8-b3f9306bae36",
              "builtID": "ks-lower-87",
              "externalID": "ocd-person/075f264c-efcf-408f-baf8-b3f9306bae36",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.estes@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_estes_susan_2/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_estes_susan_2/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-87-lower-ocd-person-075f264c-efcf-408f-baf8-b3f9306bae36": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/8e21a303-1c30-4c02-a8f8-8eacccb6873c",
            "firstName": "Sandy",
            "lastName": "Pickert",
            "fullName": "Sandy Pickert",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/s/images/pics/rep_pickert_sandy_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-KS-88-lower-ocd-person-8e21a303-1c30-4c02-a8f8-8eacccb6873c",
              "builtID": "ks-lower-88",
              "externalID": "ocd-person/8e21a303-1c30-4c02-a8f8-8eacccb6873c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sandy.pickert@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_pickert_sandy_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-88-lower-ocd-person-8e21a303-1c30-4c02-a8f8-8eacccb6873c": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/16ed9e2b-5a1b-48b1-99c9-651a55061060",
            "firstName": "Kelechi",
            "lastName": "Ohaebosim",
            "fullName": "K.C. Ohaebosim",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_ohaebosim_kc_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-KS-89-lower-ocd-person-16ed9e2b-5a1b-48b1-99c9-651a55061060",
              "builtID": "ks-lower-89",
              "externalID": "ocd-person/16ed9e2b-5a1b-48b1-99c9-651a55061060",
              "geometry": null
            },
            "contactInfo": {
              "email": "kc.ohaebosim@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_ohaebosim_kc_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_ohaebosim_kc_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_ohaebosim_kc_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-89-lower-ocd-person-16ed9e2b-5a1b-48b1-99c9-651a55061060": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/432673fc-d06a-4943-81ac-6beb141a8b15",
            "firstName": "Carl F.A.",
            "lastName": "Maughan",
            "fullName": "Carl Maughan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kansas.com/news/special-reports/judging-the-judges/az69hp/picture90641187/alternates/FREE_1140/Maughan_Carl",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-KS-90-lower-ocd-person-432673fc-d06a-4943-81ac-6beb141a8b15",
              "builtID": "ks-lower-90",
              "externalID": "ocd-person/432673fc-d06a-4943-81ac-6beb141a8b15",
              "geometry": null
            },
            "contactInfo": {
              "email": "carl.maughan@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_maughan_carl_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-90-lower-ocd-person-432673fc-d06a-4943-81ac-6beb141a8b15": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/06e5710d-2c6a-4baa-829f-803417539415",
            "firstName": "Emil",
            "lastName": "Bergquist",
            "fullName": "Emil Bergquist",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/rep_bergquist_emil_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-KS-91-lower-ocd-person-06e5710d-2c6a-4baa-829f-803417539415",
              "builtID": "ks-lower-91",
              "externalID": "ocd-person/06e5710d-2c6a-4baa-829f-803417539415",
              "geometry": null
            },
            "contactInfo": {
              "email": "emil.bergquist@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_bergquist_emil_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_bergquist_emil_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_bergquist_emil_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-91-lower-ocd-person-06e5710d-2c6a-4baa-829f-803417539415": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/0ce6641b-4793-4194-8a6b-de220fcb20d5",
            "firstName": "John",
            "lastName": "Carmichael",
            "fullName": "John Carmichael",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/John_Carmichael.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-KS-92-lower-ocd-person-0ce6641b-4793-4194-8a6b-de220fcb20d5",
              "builtID": "ks-lower-92",
              "externalID": "ocd-person/0ce6641b-4793-4194-8a6b-de220fcb20d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.carmichael@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_carmichael_john_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_carmichael_john_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_carmichael_john_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-92-lower-ocd-person-0ce6641b-4793-4194-8a6b-de220fcb20d5": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/ee28d684-dc5b-4923-a56f-7fef74c9ef67",
            "firstName": "Brian",
            "lastName": "Bergkamp",
            "fullName": "Brian Bergkamp",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Brian-Bergkamp.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-KS-93-lower-ocd-person-ee28d684-dc5b-4923-a56f-7fef74c9ef67",
              "builtID": "ks-lower-93",
              "externalID": "ocd-person/ee28d684-dc5b-4923-a56f-7fef74c9ef67",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.bergkamp@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_bergkamp_brian_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_bergkamp_brian_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-93-lower-ocd-person-ee28d684-dc5b-4923-a56f-7fef74c9ef67": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/0f185c85-3f64-4f47-9a64-048b1e009181",
            "firstName": "Leo",
            "lastName": "Delperdang",
            "fullName": "Leo Delperdang",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/rep_delperdang_leo_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-KS-94-lower-ocd-person-0f185c85-3f64-4f47-9a64-048b1e009181",
              "builtID": "ks-lower-94",
              "externalID": "ocd-person/0f185c85-3f64-4f47-9a64-048b1e009181",
              "geometry": null
            },
            "contactInfo": {
              "email": "leo.delperdang@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_delperdang_leo_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_delperdang_leo_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_delperdang_leo_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-94-lower-ocd-person-0f185c85-3f64-4f47-9a64-048b1e009181": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/e6ca4faa-17ec-4256-9265-fbba8afcbb0e",
            "firstName": "Tom",
            "lastName": "Sawyer",
            "fullName": "Tom Sawyer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_sawyer_tom_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-KS-95-lower-ocd-person-e6ca4faa-17ec-4256-9265-fbba8afcbb0e",
              "builtID": "ks-lower-95",
              "externalID": "ocd-person/e6ca4faa-17ec-4256-9265-fbba8afcbb0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.sawyer@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_sawyer_tom_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_sawyer_tom_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_sawyer_tom_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-95-lower-ocd-person-e6ca4faa-17ec-4256-9265-fbba8afcbb0e": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/9cef565d-6e73-4090-80bc-a752310efc4c",
            "firstName": "Tom",
            "lastName": "Kessler",
            "fullName": "Tom Kessler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://files.voterly.com/psn/49f078a5d1ee950ecb71bf44f386d15b/200x250/tom-kessler.jpeg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-KS-96-lower-ocd-person-9cef565d-6e73-4090-80bc-a752310efc4c",
              "builtID": "ks-lower-96",
              "externalID": "ocd-person/9cef565d-6e73-4090-80bc-a752310efc4c",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.kessler@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_kessler_tom_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_kessler_tom_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-96-lower-ocd-person-9cef565d-6e73-4090-80bc-a752310efc4c": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/df19b642-2877-4b6d-ab29-bbdd26deaa26",
            "firstName": "Nick",
            "lastName": "Hoheisel",
            "fullName": "Nick Hoheisel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_hoheisel_nick_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-KS-97-lower-ocd-person-df19b642-2877-4b6d-ab29-bbdd26deaa26",
              "builtID": "ks-lower-97",
              "externalID": "ocd-person/df19b642-2877-4b6d-ab29-bbdd26deaa26",
              "geometry": null
            },
            "contactInfo": {
              "email": "nick.hoheisel@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_hoheisel_nick_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_hoheisel_nick_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_hoheisel_nick_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-97-lower-ocd-person-df19b642-2877-4b6d-ab29-bbdd26deaa26": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/c333ddca-e5a2-4638-910c-a816bcddd666",
            "firstName": "Cyndi",
            "lastName": "Howerton",
            "fullName": "Cyndi Howerton",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.kansascity.com/latest-news/gv6n0n/picture253314683/alternates/LANDSCAPE_1140/Howerton-outside-blue-scale_fitted.jpeg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-KS-98-lower-ocd-person-c333ddca-e5a2-4638-910c-a816bcddd666",
              "builtID": "ks-lower-98",
              "externalID": "ocd-person/c333ddca-e5a2-4638-910c-a816bcddd666",
              "geometry": null
            },
            "contactInfo": {
              "email": "cyndi.howerton@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_howerton_cyndi_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_howerton_cyndi_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-98-lower-ocd-person-c333ddca-e5a2-4638-910c-a816bcddd666": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/4ab616b7-79d2-4d89-ae25-cd4ea4e8c4c8",
            "firstName": "Susan",
            "lastName": "Humphries",
            "fullName": "Susan Humphries",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/SusanHumphries.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-KS-99-lower-ocd-person-4ab616b7-79d2-4d89-ae25-cd4ea4e8c4c8",
              "builtID": "ks-lower-99",
              "externalID": "ocd-person/4ab616b7-79d2-4d89-ae25-cd4ea4e8c4c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.humphries@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_humphries_susan_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_humphries_susan_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_humphries_susan_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-99-lower-ocd-person-4ab616b7-79d2-4d89-ae25-cd4ea4e8c4c8": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/26eb8f32-d499-4b8b-82c6-8fef014633a3",
            "firstName": "Daniel R.",
            "lastName": "Hawkins",
            "fullName": "Dan Hawkins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://i0.wp.com/danhawkinskansas.com/wp-content/uploads/2020/06/Rep.-Hawkins.jpg?resize=1000%2C1000&ssl=1",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-KS-100-lower-ocd-person-26eb8f32-d499-4b8b-82c6-8fef014633a3",
              "builtID": "ks-lower-100",
              "externalID": "ocd-person/26eb8f32-d499-4b8b-82c6-8fef014633a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.hawkins@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_hawkins_daniel_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_hawkins_daniel_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_hawkins_daniel_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-100-lower-ocd-person-26eb8f32-d499-4b8b-82c6-8fef014633a3": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/29dad718-27f5-4cbb-9a2c-56fad33e4569",
            "firstName": "Joe",
            "lastName": "Seiwert",
            "fullName": "Joe Seiwert",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/m/images/pics/rep_seiwert_joe_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-KS-101-lower-ocd-person-29dad718-27f5-4cbb-9a2c-56fad33e4569",
              "builtID": "ks-lower-101",
              "externalID": "ocd-person/29dad718-27f5-4cbb-9a2c-56fad33e4569",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.seiwert@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_seiwert_joe_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_seiwert_joe_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_seiwert_joe_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-101-lower-ocd-person-29dad718-27f5-4cbb-9a2c-56fad33e4569": 0
        }
      },
      "102": {
        "members": [
          {
            "API_ID": "ocd-person/11a23aec-0417-42f0-8301-1fb911c10596",
            "firstName": "Jason",
            "lastName": "Probst",
            "fullName": "Jason Probst",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.kslegislature.org/li/s/images/pics/rep_probst_jason_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "102",
              "chamber": "lower",
              "hashID": "SLDL-KS-102-lower-ocd-person-11a23aec-0417-42f0-8301-1fb911c10596",
              "builtID": "ks-lower-102",
              "externalID": "ocd-person/11a23aec-0417-42f0-8301-1fb911c10596",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.probst@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_probst_jason_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_probst_jason_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_probst_jason_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-102-lower-ocd-person-11a23aec-0417-42f0-8301-1fb911c10596": 0
        }
      },
      "103": {
        "members": [
          {
            "API_ID": "ocd-person/a587476a-1201-4d9f-b8b7-ac6f74b3f6ce",
            "firstName": "Angela",
            "lastName": "Martinez",
            "fullName": "Angela Martinez",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.kslegislature.org/li/s/images/pics/rep_martinez_angela_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "103",
              "chamber": "lower",
              "hashID": "SLDL-KS-103-lower-ocd-person-a587476a-1201-4d9f-b8b7-ac6f74b3f6ce",
              "builtID": "ks-lower-103",
              "externalID": "ocd-person/a587476a-1201-4d9f-b8b7-ac6f74b3f6ce",
              "geometry": null
            },
            "contactInfo": {
              "email": "angela.martinez@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_martinez_angela_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-103-lower-ocd-person-a587476a-1201-4d9f-b8b7-ac6f74b3f6ce": 0
        }
      },
      "104": {
        "members": [
          {
            "API_ID": "ocd-person/8dc23ca9-1ad8-466d-83d4-453f1bf324b0",
            "firstName": "Paul",
            "lastName": "Waggoner",
            "fullName": "Paul Waggoner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_waggoner_paul_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "104",
              "chamber": "lower",
              "hashID": "SLDL-KS-104-lower-ocd-person-8dc23ca9-1ad8-466d-83d4-453f1bf324b0",
              "builtID": "ks-lower-104",
              "externalID": "ocd-person/8dc23ca9-1ad8-466d-83d4-453f1bf324b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.waggoner@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_waggoner_paul_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_waggoner_paul_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_waggoner_paul_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-104-lower-ocd-person-8dc23ca9-1ad8-466d-83d4-453f1bf324b0": 0
        }
      },
      "105": {
        "members": [
          {
            "API_ID": "ocd-person/4f9fb266-2de1-4d94-948e-9e0255666c52",
            "firstName": "Brenda",
            "lastName": "Landwehr",
            "fullName": "Brenda Landwehr",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_landwehr_brenda_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "105",
              "chamber": "lower",
              "hashID": "SLDL-KS-105-lower-ocd-person-4f9fb266-2de1-4d94-948e-9e0255666c52",
              "builtID": "ks-lower-105",
              "externalID": "ocd-person/4f9fb266-2de1-4d94-948e-9e0255666c52",
              "geometry": null
            },
            "contactInfo": {
              "email": "brenda.landwehr@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_landwehr_brenda_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_landwehr_brenda_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_landwehr_brenda_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-105-lower-ocd-person-4f9fb266-2de1-4d94-948e-9e0255666c52": 0
        }
      },
      "106": {
        "members": [
          {
            "API_ID": "ocd-person/019607dd-e8e2-4c2e-b4cc-e95ccddb54a2",
            "firstName": "Lisa",
            "lastName": "Moser",
            "fullName": "Lisa Moser",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Lisa-Moser.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "106",
              "chamber": "lower",
              "hashID": "SLDL-KS-106-lower-ocd-person-019607dd-e8e2-4c2e-b4cc-e95ccddb54a2",
              "builtID": "ks-lower-106",
              "externalID": "ocd-person/019607dd-e8e2-4c2e-b4cc-e95ccddb54a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisa.moser@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_moser_lisa_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_moser_lisa_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-106-lower-ocd-person-019607dd-e8e2-4c2e-b4cc-e95ccddb54a2": 0
        }
      },
      "107": {
        "members": [
          {
            "API_ID": "ocd-person/43d0b5fe-e853-4200-a66a-d4590eec1ad1",
            "firstName": "Susan Wilson",
            "lastName": "Concannon",
            "fullName": "Susan Concannon",
            "gender": "Female",
            "party": "Republican",
            "image": "https://static.votesmart.org/static/canphoto/140849.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "107",
              "chamber": "lower",
              "hashID": "SLDL-KS-107-lower-ocd-person-43d0b5fe-e853-4200-a66a-d4590eec1ad1",
              "builtID": "ks-lower-107",
              "externalID": "ocd-person/43d0b5fe-e853-4200-a66a-d4590eec1ad1",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.concannon@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_concannon_susan_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_concannon_susan_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_concannon_susan_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-107-lower-ocd-person-43d0b5fe-e853-4200-a66a-d4590eec1ad1": 0
        }
      },
      "108": {
        "members": [
          {
            "API_ID": "ocd-person/c56cf970-39c2-4821-8723-a85dfe09cd6b",
            "firstName": "Brandon T.",
            "lastName": "Woodard",
            "fullName": "Brandon Woodard",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_woodard_brandon_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "108",
              "chamber": "lower",
              "hashID": "SLDL-KS-108-lower-ocd-person-c56cf970-39c2-4821-8723-a85dfe09cd6b",
              "builtID": "ks-lower-108",
              "externalID": "ocd-person/c56cf970-39c2-4821-8723-a85dfe09cd6b",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandon.woodard@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_woodard_brandon_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-108-lower-ocd-person-c56cf970-39c2-4821-8723-a85dfe09cd6b": 0
        }
      },
      "109": {
        "members": [
          {
            "API_ID": "ocd-person/655437d6-f80f-4e14-b061-c28a8977cf0f",
            "firstName": "Troy L.",
            "lastName": "Waymaster",
            "fullName": "Troy Waymaster",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_waymaster_troy_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "109",
              "chamber": "lower",
              "hashID": "SLDL-KS-109-lower-ocd-person-655437d6-f80f-4e14-b061-c28a8977cf0f",
              "builtID": "ks-lower-109",
              "externalID": "ocd-person/655437d6-f80f-4e14-b061-c28a8977cf0f",
              "geometry": null
            },
            "contactInfo": {
              "email": "troy.waymaster@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_waymaster_troy_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_waymaster_troy_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_waymaster_troy_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-109-lower-ocd-person-655437d6-f80f-4e14-b061-c28a8977cf0f": 0
        }
      },
      "110": {
        "members": [
          {
            "API_ID": "ocd-person/782cdea1-59a1-4930-8126-ffd1264420f9",
            "firstName": "Kenneth J.",
            "lastName": "Rahjes",
            "fullName": "Ken Rahjes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_rahjes_ken_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "110",
              "chamber": "lower",
              "hashID": "SLDL-KS-110-lower-ocd-person-782cdea1-59a1-4930-8126-ffd1264420f9",
              "builtID": "ks-lower-110",
              "externalID": "ocd-person/782cdea1-59a1-4930-8126-ffd1264420f9",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.rahjes@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_rahjes_ken_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_rahjes_ken_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_rahjes_ken_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-110-lower-ocd-person-782cdea1-59a1-4930-8126-ffd1264420f9": 0
        }
      },
      "111": {
        "members": [
          {
            "API_ID": "ocd-person/7aac5ab6-1590-4cfe-8c02-d61f292497c8",
            "firstName": "Barbara King",
            "lastName": "Wasinger",
            "fullName": "Barb Wasinger",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_wasinger_barbara_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "111",
              "chamber": "lower",
              "hashID": "SLDL-KS-111-lower-ocd-person-7aac5ab6-1590-4cfe-8c02-d61f292497c8",
              "builtID": "ks-lower-111",
              "externalID": "ocd-person/7aac5ab6-1590-4cfe-8c02-d61f292497c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "barb.wasinger@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_wasinger_barbara_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_wasinger_barbara_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_wasinger_barbara_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-111-lower-ocd-person-7aac5ab6-1590-4cfe-8c02-d61f292497c8": 0
        }
      },
      "112": {
        "members": [
          {
            "API_ID": "ocd-person/80f9f23c-fa36-43ef-b97e-a9563bd4237b",
            "firstName": "Tory Marie",
            "lastName": "Blew",
            "fullName": "Tory Marie Blew",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/blewmarie.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "112",
              "chamber": "lower",
              "hashID": "SLDL-KS-112-lower-ocd-person-80f9f23c-fa36-43ef-b97e-a9563bd4237b",
              "builtID": "ks-lower-112",
              "externalID": "ocd-person/80f9f23c-fa36-43ef-b97e-a9563bd4237b",
              "geometry": null
            },
            "contactInfo": {
              "email": "torymarie.blew@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_arnberger_tory_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_arnberger_tory_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_arnberger_tory_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-112-lower-ocd-person-80f9f23c-fa36-43ef-b97e-a9563bd4237b": 0
        }
      },
      "113": {
        "members": [
          {
            "API_ID": "ocd-person/ca3f1bf6-44e0-4d67-b242-3cd29905723c",
            "firstName": "Brett",
            "lastName": "Fairchild",
            "fullName": "Brett Fairchild",
            "gender": "Male",
            "party": "Republican",
            "image": "https://brettfairchild.com/wp-content/uploads/bb-plugin/cache/Brett-Fairchild-of-KS-circle.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "113",
              "chamber": "lower",
              "hashID": "SLDL-KS-113-lower-ocd-person-ca3f1bf6-44e0-4d67-b242-3cd29905723c",
              "builtID": "ks-lower-113",
              "externalID": "ocd-person/ca3f1bf6-44e0-4d67-b242-3cd29905723c",
              "geometry": null
            },
            "contactInfo": {
              "email": "brett.fairchild@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_fairchild_brett_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_fairchild_brett_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-113-lower-ocd-person-ca3f1bf6-44e0-4d67-b242-3cd29905723c": 0
        }
      },
      "114": {
        "members": [
          {
            "API_ID": "ocd-person/8b446a75-944d-4292-8af0-f5114be39021",
            "firstName": "Michael",
            "lastName": "Murphy",
            "fullName": "Mike Murphy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvKwXLW2-GGx639WKAs10NgKJgmOprF8JoSnBrc3tSNe41yMEh6bGuVEiIvAulBU795Tk&usqp=CAU",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "114",
              "chamber": "lower",
              "hashID": "SLDL-KS-114-lower-ocd-person-8b446a75-944d-4292-8af0-f5114be39021",
              "builtID": "ks-lower-114",
              "externalID": "ocd-person/8b446a75-944d-4292-8af0-f5114be39021",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.murphy@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_murphy_michael_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_murphy_michael_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-114-lower-ocd-person-8b446a75-944d-4292-8af0-f5114be39021": 0
        }
      },
      "115": {
        "members": [
          {
            "API_ID": "ocd-person/b5ed1fef-035c-4454-90ae-6434d0540f43",
            "firstName": "Gary",
            "lastName": "White",
            "fullName": "Gary White",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Gary_White.jpeg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "115",
              "chamber": "lower",
              "hashID": "SLDL-KS-115-lower-ocd-person-b5ed1fef-035c-4454-90ae-6434d0540f43",
              "builtID": "ks-lower-115",
              "externalID": "ocd-person/b5ed1fef-035c-4454-90ae-6434d0540f43",
              "geometry": null
            },
            "contactInfo": {
              "email": "gary.white@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_white_gary_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-115-lower-ocd-person-b5ed1fef-035c-4454-90ae-6434d0540f43": 0
        }
      },
      "116": {
        "members": [
          {
            "API_ID": "ocd-person/c51517ec-db1e-433d-a1d0-683547ae34a2",
            "firstName": "Kyle Dee",
            "lastName": "Hoffman",
            "fullName": "Kyle Hoffman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Kyle_Hoffman.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "116",
              "chamber": "lower",
              "hashID": "SLDL-KS-116-lower-ocd-person-c51517ec-db1e-433d-a1d0-683547ae34a2",
              "builtID": "ks-lower-116",
              "externalID": "ocd-person/c51517ec-db1e-433d-a1d0-683547ae34a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "kyle.hoffman@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_hoffman_kyle_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_hoffman_kyle_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_hoffman_kyle_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-116-lower-ocd-person-c51517ec-db1e-433d-a1d0-683547ae34a2": 0
        }
      },
      "117": {
        "members": [
          {
            "API_ID": "ocd-person/d7dd1de1-73e2-438a-bf25-c70e12ede30d",
            "firstName": "Adam",
            "lastName": "Turk",
            "fullName": "Adam Turk",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/s/images/pics/rep_turk_adam_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "117",
              "chamber": "lower",
              "hashID": "SLDL-KS-117-lower-ocd-person-d7dd1de1-73e2-438a-bf25-c70e12ede30d",
              "builtID": "ks-lower-117",
              "externalID": "ocd-person/d7dd1de1-73e2-438a-bf25-c70e12ede30d",
              "geometry": null
            },
            "contactInfo": {
              "email": "adam.turk@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_turk_adam_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-117-lower-ocd-person-d7dd1de1-73e2-438a-bf25-c70e12ede30d": 0
        }
      },
      "118": {
        "members": [
          {
            "API_ID": "ocd-person/ca29b8ff-9845-40ba-a196-458cef943041",
            "firstName": "James",
            "lastName": "Minnix",
            "fullName": "Jim Minnix",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_minnix_jim_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "118",
              "chamber": "lower",
              "hashID": "SLDL-KS-118-lower-ocd-person-ca29b8ff-9845-40ba-a196-458cef943041",
              "builtID": "ks-lower-118",
              "externalID": "ocd-person/ca29b8ff-9845-40ba-a196-458cef943041",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.minnix@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_minnix_jim_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_minnix_jim_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-118-lower-ocd-person-ca29b8ff-9845-40ba-a196-458cef943041": 0
        }
      },
      "119": {
        "members": [
          {
            "API_ID": "ocd-person/55f1cbff-5420-44f1-8d6e-1970317cd150",
            "firstName": "Jason",
            "lastName": "Goetz",
            "fullName": "Jason Goetz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ksn.com/wp-content/uploads/sites/13/2022/07/Jason-Goetz-courtesy-Vibewest-Photography-by-Taylor.jpg?resize=288,360",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "119",
              "chamber": "lower",
              "hashID": "SLDL-KS-119-lower-ocd-person-55f1cbff-5420-44f1-8d6e-1970317cd150",
              "builtID": "ks-lower-119",
              "externalID": "ocd-person/55f1cbff-5420-44f1-8d6e-1970317cd150",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.goetz@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_goetz_jason_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-119-lower-ocd-person-55f1cbff-5420-44f1-8d6e-1970317cd150": 0
        }
      },
      "120": {
        "members": [
          {
            "API_ID": "ocd-person/c1802270-0126-4be5-b7e7-1a9a472f5fd7",
            "firstName": "Adam C.",
            "lastName": "Smith",
            "fullName": "Adam Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/m/images/pics/rep_smith_adam_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "120",
              "chamber": "lower",
              "hashID": "SLDL-KS-120-lower-ocd-person-c1802270-0126-4be5-b7e7-1a9a472f5fd7",
              "builtID": "ks-lower-120",
              "externalID": "ocd-person/c1802270-0126-4be5-b7e7-1a9a472f5fd7",
              "geometry": null
            },
            "contactInfo": {
              "email": "adam.smith@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_smith_adam_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_smith_adam_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_smith_adam_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-120-lower-ocd-person-c1802270-0126-4be5-b7e7-1a9a472f5fd7": 0
        }
      },
      "121": {
        "members": [
          {
            "API_ID": "ocd-person/df7cab28-f697-448a-9d60-64163b67843d",
            "firstName": "John",
            "lastName": "Resman",
            "fullName": "John Resman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/rep_resman_john_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "121",
              "chamber": "lower",
              "hashID": "SLDL-KS-121-lower-ocd-person-df7cab28-f697-448a-9d60-64163b67843d",
              "builtID": "ks-lower-121",
              "externalID": "ocd-person/df7cab28-f697-448a-9d60-64163b67843d",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.resman@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_resman_john_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_resman_john_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_resman_john_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-121-lower-ocd-person-df7cab28-f697-448a-9d60-64163b67843d": 0
        }
      },
      "122": {
        "members": [
          {
            "API_ID": "ocd-person/463dcd61-1bf6-4182-ae6f-a840e43ea7fe",
            "firstName": "William",
            "lastName": "Clifford",
            "fullName": "Bill Clifford",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.fryeye.com/wp-content/uploads/2022/11/DR-1.png",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "122",
              "chamber": "lower",
              "hashID": "SLDL-KS-122-lower-ocd-person-463dcd61-1bf6-4182-ae6f-a840e43ea7fe",
              "builtID": "ks-lower-122",
              "externalID": "ocd-person/463dcd61-1bf6-4182-ae6f-a840e43ea7fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.clifford@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/rep_clifford_bill_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_clifford_bill_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-122-lower-ocd-person-463dcd61-1bf6-4182-ae6f-a840e43ea7fe": 0
        }
      },
      "123": {
        "members": [
          {
            "API_ID": "ocd-person/05ca3b77-c9d8-4d8e-92bf-603b2f71eda6",
            "firstName": "Robert Y.",
            "lastName": "Lewis",
            "fullName": "Bob Lewis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.jamesmarshlaw.com/wp-content/uploads/2022/11/RobertYLewis.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "123",
              "chamber": "lower",
              "hashID": "SLDL-KS-123-lower-ocd-person-05ca3b77-c9d8-4d8e-92bf-603b2f71eda6",
              "builtID": "ks-lower-123",
              "externalID": "ocd-person/05ca3b77-c9d8-4d8e-92bf-603b2f71eda6",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.lewis@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_lewis_bob_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-123-lower-ocd-person-05ca3b77-c9d8-4d8e-92bf-603b2f71eda6": 0
        }
      },
      "124": {
        "members": [
          {
            "API_ID": "ocd-person/a62fdd9b-c63b-4ed4-842e-9e5acfd3760a",
            "firstName": "David",
            "lastName": "Younger",
            "fullName": "Dave Younger",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/s/images/pics/rep_younger_david_1.jpg",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "124",
              "chamber": "lower",
              "hashID": "SLDL-KS-124-lower-ocd-person-a62fdd9b-c63b-4ed4-842e-9e5acfd3760a",
              "builtID": "ks-lower-124",
              "externalID": "ocd-person/a62fdd9b-c63b-4ed4-842e-9e5acfd3760a",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.younger@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/rep_younger_david_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-124-lower-ocd-person-a62fdd9b-c63b-4ed4-842e-9e5acfd3760a": 0
        }
      },
      "125": {
        "members": [
          {
            "API_ID": "ocd-person/cc0df3af-9c8b-4834-a629-c16e4c8debcb",
            "firstName": "Shannon",
            "lastName": "Francis",
            "fullName": "Shannon Francis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Shannon_Francis.JPG",
            "title": "KS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "125",
              "chamber": "lower",
              "hashID": "SLDL-KS-125-lower-ocd-person-cc0df3af-9c8b-4834-a629-c16e4c8debcb",
              "builtID": "ks-lower-125",
              "externalID": "ocd-person/cc0df3af-9c8b-4834-a629-c16e4c8debcb",
              "geometry": null
            },
            "contactInfo": {
              "email": "shannon.francis@house.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_francis_shannon_1/",
                "https://www.kslegislature.org/li/b2021_22/members/rep_francis_shannon_1/",
                "https://www.kslegislature.org/li/b2023_24/members/rep_francis_shannon_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KS-125-lower-ocd-person-cc0df3af-9c8b-4834-a629-c16e4c8debcb": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/ba58a83a-f61d-401a-96f9-51a827e0bc58",
            "firstName": "Dennis D.",
            "lastName": "Pyle",
            "fullName": "Dennis Pyle",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/sen_pyle_dennis_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-KS-1-upper-ocd-person-ba58a83a-f61d-401a-96f9-51a827e0bc58",
              "builtID": "ks-upper-1",
              "externalID": "ocd-person/ba58a83a-f61d-401a-96f9-51a827e0bc58",
              "geometry": null
            },
            "contactInfo": {
              "email": "dennis.pyle@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_pyle_dennis_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_pyle_dennis_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_pyle_dennis_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-1-upper-ocd-person-ba58a83a-f61d-401a-96f9-51a827e0bc58": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/4fca7c3e-1f7c-455e-b555-a293ff578e60",
            "firstName": "Marci",
            "lastName": "Francisco",
            "fullName": "Marci Francisco",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://images.squarespace-cdn.com/content/v1/606b1f70a46bfa6ea792c03e/1618941809929-DZYRQJ9WE7KMEJZU14MJ/Francisco.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-KS-2-upper-ocd-person-4fca7c3e-1f7c-455e-b555-a293ff578e60",
              "builtID": "ks-upper-2",
              "externalID": "ocd-person/4fca7c3e-1f7c-455e-b555-a293ff578e60",
              "geometry": null
            },
            "contactInfo": {
              "email": "marci.francisco@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_francisco_marci_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_francisco_marci_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_francisco_marci_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-2-upper-ocd-person-4fca7c3e-1f7c-455e-b555-a293ff578e60": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/49315929-7de4-4861-8b73-421be32e2dd0",
            "firstName": "George Thomas",
            "lastName": "Holland",
            "fullName": "Tom Holland",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.tomhollandforkansas.org/wp-content/uploads/2020/08/2010.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-KS-3-upper-ocd-person-49315929-7de4-4861-8b73-421be32e2dd0",
              "builtID": "ks-upper-3",
              "externalID": "ocd-person/49315929-7de4-4861-8b73-421be32e2dd0",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.holland@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_holland_tom_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_holland_tom_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_holland_tom_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-3-upper-ocd-person-49315929-7de4-4861-8b73-421be32e2dd0": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/0a700e33-0ae7-4627-936c-982317c26b9d",
            "firstName": "David",
            "lastName": "Haley",
            "fullName": "David Haley",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://images.squarespace-cdn.com/content/v1/606b1f70a46bfa6ea792c03e/1618942086989-LIN2C4WCFMR47RKYDJ5V/Haley.jpg?format=1000w",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-KS-4-upper-ocd-person-0a700e33-0ae7-4627-936c-982317c26b9d",
              "builtID": "ks-upper-4",
              "externalID": "ocd-person/0a700e33-0ae7-4627-936c-982317c26b9d",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.haley@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_haley_david_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_haley_david_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_haley_david_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-4-upper-ocd-person-0a700e33-0ae7-4627-936c-982317c26b9d": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/8a4754a1-0c05-43f9-93a8-30afd24676b7",
            "firstName": "Jeffrey Thomas",
            "lastName": "Pittman",
            "fullName": "Jeff Pittman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/sen_pittman_jeff_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-KS-5-upper-ocd-person-8a4754a1-0c05-43f9-93a8-30afd24676b7",
              "builtID": "ks-upper-5",
              "externalID": "ocd-person/8a4754a1-0c05-43f9-93a8-30afd24676b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.pittman@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_pittman_jeff_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_pittman_jeff_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_pittman_jeff_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-5-upper-ocd-person-8a4754a1-0c05-43f9-93a8-30afd24676b7": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/148ed29a-4c96-4e69-9791-29b2bd3f09b6",
            "firstName": "Patricia D.",
            "lastName": "Pettey",
            "fullName": "Pat Pettey",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.kslegislature.org/li/m/images/pics/sen_pettey_pat_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-KS-6-upper-ocd-person-148ed29a-4c96-4e69-9791-29b2bd3f09b6",
              "builtID": "ks-upper-6",
              "externalID": "ocd-person/148ed29a-4c96-4e69-9791-29b2bd3f09b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "pat.pettey@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_pettey_pat_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_pettey_pat_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_pettey_pat_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-6-upper-ocd-person-148ed29a-4c96-4e69-9791-29b2bd3f09b6": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/76d729c5-1b4d-4966-8c99-5ac8b74d8719",
            "firstName": "Ethan Jared",
            "lastName": "Corson",
            "fullName": "Ethan Corson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://images.squarespace-cdn.com/content/v1/606b1f70a46bfa6ea792c03e/1618940932636-25QRSEVW2G7U5XH6UMHJ/50131538178_3e2bac9588_o.jpg?format=1000w",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-KS-7-upper-ocd-person-76d729c5-1b4d-4966-8c99-5ac8b74d8719",
              "builtID": "ks-upper-7",
              "externalID": "ocd-person/76d729c5-1b4d-4966-8c99-5ac8b74d8719",
              "geometry": null
            },
            "contactInfo": {
              "email": "ethan.corson@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/sen_corson_ethan_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_corson_ethan_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-7-upper-ocd-person-76d729c5-1b4d-4966-8c99-5ac8b74d8719": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/9c36b31f-b9e7-43cd-8aab-6148c8f6cac8",
            "firstName": "Cindy",
            "lastName": "Holscher",
            "fullName": "Cindy Holscher",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Cindy-Holscher.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-KS-8-upper-ocd-person-9c36b31f-b9e7-43cd-8aab-6148c8f6cac8",
              "builtID": "ks-upper-8",
              "externalID": "ocd-person/9c36b31f-b9e7-43cd-8aab-6148c8f6cac8",
              "geometry": null
            },
            "contactInfo": {
              "email": "cindy.holscher@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_holscher_cindy_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_holscher_cindy_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_holscher_cindy_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-8-upper-ocd-person-9c36b31f-b9e7-43cd-8aab-6148c8f6cac8": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/35630402-1f6a-4a3e-b6cf-48c1b93bd6ae",
            "firstName": "Beverly Kastl",
            "lastName": "Gossage",
            "fullName": "Beverly Gossage",
            "gender": "Female",
            "party": "Republican",
            "image": "https://i0.wp.com/klcjournal.com/wp-content/uploads/2020/10/gossage-campaign-pic-edit.jpg?resize=283%2C300&ssl=1",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-KS-9-upper-ocd-person-35630402-1f6a-4a3e-b6cf-48c1b93bd6ae",
              "builtID": "ks-upper-9",
              "externalID": "ocd-person/35630402-1f6a-4a3e-b6cf-48c1b93bd6ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "beverly.gossage@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/sen_gossage_beverly_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_gossage_beverly_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-9-upper-ocd-person-35630402-1f6a-4a3e-b6cf-48c1b93bd6ae": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/97943c08-7d1f-400e-b71d-7a20e939b1d9",
            "firstName": "Michael Lowry",
            "lastName": "Thompson",
            "fullName": "Mike Thompson",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/s/images/pics/sen_thompson_mike_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-KS-10-upper-ocd-person-97943c08-7d1f-400e-b71d-7a20e939b1d9",
              "builtID": "ks-upper-10",
              "externalID": "ocd-person/97943c08-7d1f-400e-b71d-7a20e939b1d9",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.thompson@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_thompson_mike_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_thompson_mike_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_thompson_mike_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-10-upper-ocd-person-97943c08-7d1f-400e-b71d-7a20e939b1d9": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/85a66c0d-6f9e-4a55-947e-4d3dc28af0d8",
            "firstName": "Kellie",
            "lastName": "Warren",
            "fullName": "Kellie Warren",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/sen_warren_kellie_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-KS-11-upper-ocd-person-85a66c0d-6f9e-4a55-947e-4d3dc28af0d8",
              "builtID": "ks-upper-11",
              "externalID": "ocd-person/85a66c0d-6f9e-4a55-947e-4d3dc28af0d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "kellie.warren@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_warren_kellie_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_warren_kellie_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_warren_kellie_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-11-upper-ocd-person-85a66c0d-6f9e-4a55-947e-4d3dc28af0d8": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/cc84252c-b7c6-484d-a036-dd38a90b1b04",
            "firstName": "Caryn",
            "lastName": "Tyson",
            "fullName": "Caryn Tyson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/sen_tyson_caryn_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-KS-12-upper-ocd-person-cc84252c-b7c6-484d-a036-dd38a90b1b04",
              "builtID": "ks-upper-12",
              "externalID": "ocd-person/cc84252c-b7c6-484d-a036-dd38a90b1b04",
              "geometry": null
            },
            "contactInfo": {
              "email": "caryn.tyson@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_tyson_caryn_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_tyson_caryn_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_tyson_caryn_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-12-upper-ocd-person-cc84252c-b7c6-484d-a036-dd38a90b1b04": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/c091ed05-ad26-42f2-b7e8-a830404cb152",
            "firstName": "Tim",
            "lastName": "Shallenburger",
            "fullName": "Tim Shallenburger",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/s/images/pics/sen_shallenburger_tim_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-KS-13-upper-ocd-person-c091ed05-ad26-42f2-b7e8-a830404cb152",
              "builtID": "ks-upper-13",
              "externalID": "ocd-person/c091ed05-ad26-42f2-b7e8-a830404cb152",
              "geometry": null
            },
            "contactInfo": {
              "email": "tim.shallenburger@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/sen_shallenburger_tim_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-13-upper-ocd-person-c091ed05-ad26-42f2-b7e8-a830404cb152": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/a0787678-986f-459f-bba3-38d46ef55d8b",
            "firstName": "Michael A.",
            "lastName": "Fagg",
            "fullName": "Mike Fagg",
            "gender": "Male",
            "party": "Republican",
            "image": "https://kake.images.worldnow.com/images/19978473_G.jpeg?lastEditedDate=1603122239000",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-KS-14-upper-ocd-person-a0787678-986f-459f-bba3-38d46ef55d8b",
              "builtID": "ks-upper-14",
              "externalID": "ocd-person/a0787678-986f-459f-bba3-38d46ef55d8b",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.fagg@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/sen_fagg_michael_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_fagg_michael_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-14-upper-ocd-person-a0787678-986f-459f-bba3-38d46ef55d8b": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/90f4cf61-cce8-4f87-8495-c0dc298c8d27",
            "firstName": "Virgil",
            "lastName": "Peck",
            "fullName": "Virgil Peck",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/m/images/pics/sen_peck_virgil_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-KS-15-upper-ocd-person-90f4cf61-cce8-4f87-8495-c0dc298c8d27",
              "builtID": "ks-upper-15",
              "externalID": "ocd-person/90f4cf61-cce8-4f87-8495-c0dc298c8d27",
              "geometry": null
            },
            "contactInfo": {
              "email": "virgil.peck@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2015_16/members/rep_peck_virgil_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_peck_virgil_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_peck_virgil_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-15-upper-ocd-person-90f4cf61-cce8-4f87-8495-c0dc298c8d27": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/b4774ff4-de16-4716-8956-65ecc740eb8c",
            "firstName": "Ty",
            "lastName": "Masterson",
            "fullName": "Ty Masterson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/sen_masterson_ty_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-KS-16-upper-ocd-person-b4774ff4-de16-4716-8956-65ecc740eb8c",
              "builtID": "ks-upper-16",
              "externalID": "ocd-person/b4774ff4-de16-4716-8956-65ecc740eb8c",
              "geometry": null
            },
            "contactInfo": {
              "email": "ty.masterson@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_masterson_ty_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_masterson_ty_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_masterson_ty_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-16-upper-ocd-person-b4774ff4-de16-4716-8956-65ecc740eb8c": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/5a19ae7d-e339-4b1e-b73b-95c9cdd520a4",
            "firstName": "Jeffrey S.",
            "lastName": "Longbine",
            "fullName": "Jeff Longbine",
            "gender": "Male",
            "party": "Republican",
            "image": "https://images.squarespace-cdn.com/content/v1/5f7cfe88158d2a7fdaca10e2/1602035383004-KRE0A8X5IYP0BT5AZIXY/LongbineJeff_Print.jpg?format=1000w",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-KS-17-upper-ocd-person-5a19ae7d-e339-4b1e-b73b-95c9cdd520a4",
              "builtID": "ks-upper-17",
              "externalID": "ocd-person/5a19ae7d-e339-4b1e-b73b-95c9cdd520a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.longbine@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_longbine_jeff_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_longbine_jeff_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_longbine_jeff_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-17-upper-ocd-person-5a19ae7d-e339-4b1e-b73b-95c9cdd520a4": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/e31b1197-a570-4004-bda7-932b05758a36",
            "firstName": "Kristen Brunkow",
            "lastName": "O'Shea",
            "fullName": "Kristen O'Shea",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/sen_oshea_kristen_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-KS-18-upper-ocd-person-e31b1197-a570-4004-bda7-932b05758a36",
              "builtID": "ks-upper-18",
              "externalID": "ocd-person/e31b1197-a570-4004-bda7-932b05758a36",
              "geometry": null
            },
            "contactInfo": {
              "email": "kristen.oshea@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/sen_oshea_kristen_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_oshea_kristen_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-18-upper-ocd-person-e31b1197-a570-4004-bda7-932b05758a36": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/5281ab3e-91a8-43be-bcc7-5d9d4c2a2545",
            "firstName": "Richard Gene",
            "lastName": "Kloos",
            "fullName": "Rick Kloos",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ksn.com/wp-content/uploads/sites/13/2018/10/20180607_Rick20Kloos20-20Govenor20Candidate_3420-20Alexandra20Martinez_1539633409099.jpg_59052445_ver1.0.jpg?w=2560&h=1440&crop=1",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-KS-19-upper-ocd-person-5281ab3e-91a8-43be-bcc7-5d9d4c2a2545",
              "builtID": "ks-upper-19",
              "externalID": "ocd-person/5281ab3e-91a8-43be-bcc7-5d9d4c2a2545",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.kloos@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/sen_kloos_rick_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_kloos_rick_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-19-upper-ocd-person-5281ab3e-91a8-43be-bcc7-5d9d4c2a2545": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/57c530cc-4575-431e-9118-11f64fa3a573",
            "firstName": "Brenda S.",
            "lastName": "Dietrich",
            "fullName": "Brenda Dietrich",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/rep_dietrich_brenda_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-KS-20-upper-ocd-person-57c530cc-4575-431e-9118-11f64fa3a573",
              "builtID": "ks-upper-20",
              "externalID": "ocd-person/57c530cc-4575-431e-9118-11f64fa3a573",
              "geometry": null
            },
            "contactInfo": {
              "email": "brenda.dietrich@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_dietrich_brenda_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_dietrich_brenda_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_dietrich_brenda_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-20-upper-ocd-person-57c530cc-4575-431e-9118-11f64fa3a573": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/549c712e-1cc1-4ca7-9ef7-b09bb36d7229",
            "firstName": "Dinah",
            "lastName": "Sykes",
            "fullName": "Dinah Sykes",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/sen_sykes_dinah_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-KS-21-upper-ocd-person-549c712e-1cc1-4ca7-9ef7-b09bb36d7229",
              "builtID": "ks-upper-21",
              "externalID": "ocd-person/549c712e-1cc1-4ca7-9ef7-b09bb36d7229",
              "geometry": null
            },
            "contactInfo": {
              "email": "dinah.sykes@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_sykes_dinah_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_sykes_dinah_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_sykes_dinah_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-21-upper-ocd-person-549c712e-1cc1-4ca7-9ef7-b09bb36d7229": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/5f39e157-4b1e-4a21-bc24-ec7c142db356",
            "firstName": "Usha",
            "lastName": "Reddi",
            "fullName": "Usha Reddi",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.kslegislature.org/li/s/images/pics/sen_reddi_usha_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-KS-22-upper-ocd-person-5f39e157-4b1e-4a21-bc24-ec7c142db356",
              "builtID": "ks-upper-22",
              "externalID": "ocd-person/5f39e157-4b1e-4a21-bc24-ec7c142db356",
              "geometry": null
            },
            "contactInfo": {
              "email": "usha.reddi@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/sen_reddi_usha_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-22-upper-ocd-person-5f39e157-4b1e-4a21-bc24-ec7c142db356": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/8053260c-bf25-480f-8f53-86243ea2c83b",
            "firstName": "Robert S.",
            "lastName": "Olson",
            "fullName": "Rob Olson",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/s/images/pics/sen_olson_robert_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-KS-23-upper-ocd-person-8053260c-bf25-480f-8f53-86243ea2c83b",
              "builtID": "ks-upper-23",
              "externalID": "ocd-person/8053260c-bf25-480f-8f53-86243ea2c83b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rob.olson@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_olson_robert_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_olson_robert_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_olson_robert_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-23-upper-ocd-person-8053260c-bf25-480f-8f53-86243ea2c83b": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/2bbf4d79-672b-46f7-8745-ee91db3169e3",
            "firstName": "Jeremy Ryan",
            "lastName": "Claeys",
            "fullName": "J.R. Claeys",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/JR_Claeys.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-KS-24-upper-ocd-person-2bbf4d79-672b-46f7-8745-ee91db3169e3",
              "builtID": "ks-upper-24",
              "externalID": "ocd-person/2bbf4d79-672b-46f7-8745-ee91db3169e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "jrclaeys@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/sen_claeys_jr_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_claeys_jr_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-24-upper-ocd-person-2bbf4d79-672b-46f7-8745-ee91db3169e3": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/d10b672d-f934-4a41-b283-2c16a42e05c4",
            "firstName": "Mary Anita",
            "lastName": "Ware",
            "fullName": "Mary Ware",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.kslegislature.org/li/s/images/pics/sen_ware_mary_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-KS-25-upper-ocd-person-d10b672d-f934-4a41-b283-2c16a42e05c4",
              "builtID": "ks-upper-25",
              "externalID": "ocd-person/d10b672d-f934-4a41-b283-2c16a42e05c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.ware@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_ware_mary_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_ware_mary_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_ware_mary_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-25-upper-ocd-person-d10b672d-f934-4a41-b283-2c16a42e05c4": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/3f6a4937-225b-4300-bb1e-487d72d8102d",
            "firstName": "Daniel J.",
            "lastName": "Kerschen",
            "fullName": "Dan Kerschen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.newyork1.vip.townnews.com/derbyinformer.com/content/tncms/assets/v3/editorial/3/28/328c97f4-194f-11eb-a6b4-9337cb331129/5f99beb695e53.image.jpg?resize=200%2C293",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-KS-26-upper-ocd-person-3f6a4937-225b-4300-bb1e-487d72d8102d",
              "builtID": "ks-upper-26",
              "externalID": "ocd-person/3f6a4937-225b-4300-bb1e-487d72d8102d",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.kerschen@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_kerschen_dan_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_kerschen_dan_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_kerschen_dan_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-26-upper-ocd-person-3f6a4937-225b-4300-bb1e-487d72d8102d": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/c0c78c6c-d725-4f3b-8ae5-11446753d9a7",
            "firstName": "Chase Gregory",
            "lastName": "Blasi",
            "fullName": "Chase Blasi",
            "gender": "Male",
            "party": "Republican",
            "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSa11ox2Co3jBjukYKYwV43uBXISvy1ckbu-CWVXpKoN3EOYnsVnjF9KSF9-4sMJzT84WE&usqp=CAU",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-KS-27-upper-ocd-person-c0c78c6c-d725-4f3b-8ae5-11446753d9a7",
              "builtID": "ks-upper-27",
              "externalID": "ocd-person/c0c78c6c-d725-4f3b-8ae5-11446753d9a7",
              "geometry": null
            },
            "contactInfo": {
              "email": "chase.blasi@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2023_24/members/sen_blasi_chase_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-27-upper-ocd-person-c0c78c6c-d725-4f3b-8ae5-11446753d9a7": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/4d244ceb-6d50-4670-93db-22ad153b3961",
            "firstName": "Mike",
            "lastName": "Petersen",
            "fullName": "Mike Petersen",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.kslegislature.org/li/m/images/pics/sen_petersen_mike_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-KS-28-upper-ocd-person-4d244ceb-6d50-4670-93db-22ad153b3961",
              "builtID": "ks-upper-28",
              "externalID": "ocd-person/4d244ceb-6d50-4670-93db-22ad153b3961",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.petersen@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_petersen_mike_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_petersen_mike_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_petersen_mike_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-28-upper-ocd-person-4d244ceb-6d50-4670-93db-22ad153b3961": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/9f48288b-b1a9-43a1-884b-5cc2abfa4e4f",
            "firstName": "Oletha",
            "lastName": "Faust-Goudeau",
            "fullName": "Oletha Faust-Goudeau",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Oletha_Faust-Goudeau.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-KS-29-upper-ocd-person-9f48288b-b1a9-43a1-884b-5cc2abfa4e4f",
              "builtID": "ks-upper-29",
              "externalID": "ocd-person/9f48288b-b1a9-43a1-884b-5cc2abfa4e4f",
              "geometry": null
            },
            "contactInfo": {
              "email": "oletha.faust-goudeau@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_faust_goudeau_oletha_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_faust_goudeau_oletha_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_faust_goudeau_oletha_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-29-upper-ocd-person-9f48288b-b1a9-43a1-884b-5cc2abfa4e4f": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/3093633a-62dc-4e93-a305-258a20bfd0f2",
            "firstName": "Renee Kendra",
            "lastName": "Erickson",
            "fullName": "Renee Erickson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Renee_Erickson.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-KS-30-upper-ocd-person-3093633a-62dc-4e93-a305-258a20bfd0f2",
              "builtID": "ks-upper-30",
              "externalID": "ocd-person/3093633a-62dc-4e93-a305-258a20bfd0f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "renee.erickson@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_erickson_renee_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_erickson_renee_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_erickson_renee_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-30-upper-ocd-person-3093633a-62dc-4e93-a305-258a20bfd0f2": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/76bac087-6698-4ad1-a81b-c66667850009",
            "firstName": "Carolyn",
            "lastName": "McGinn",
            "fullName": "Carolyn McGinn",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/sen_mcginn_carolyn_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-KS-31-upper-ocd-person-76bac087-6698-4ad1-a81b-c66667850009",
              "builtID": "ks-upper-31",
              "externalID": "ocd-person/76bac087-6698-4ad1-a81b-c66667850009",
              "geometry": null
            },
            "contactInfo": {
              "email": "carolyn.mcginn@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_mcginn_carolyn_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_mcginn_carolyn_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_mcginn_carolyn_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-31-upper-ocd-person-76bac087-6698-4ad1-a81b-c66667850009": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/4c340817-c498-4df6-afe4-d3251ce6594b",
            "firstName": "Larry",
            "lastName": "Alley",
            "fullName": "Larry Alley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Larry_Alley.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-KS-32-upper-ocd-person-4c340817-c498-4df6-afe4-d3251ce6594b",
              "builtID": "ks-upper-32",
              "externalID": "ocd-person/4c340817-c498-4df6-afe4-d3251ce6594b",
              "geometry": null
            },
            "contactInfo": {
              "email": "larry.alley@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_alley_larry_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_alley_larry_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_alley_larry_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-32-upper-ocd-person-4c340817-c498-4df6-afe4-d3251ce6594b": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/1b272330-b7d2-4d33-8828-2e45e68c7afa",
            "firstName": "Alicia Dawn",
            "lastName": "Straub",
            "fullName": "Alicia Straub",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/sen_straub_alicia_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-KS-33-upper-ocd-person-1b272330-b7d2-4d33-8828-2e45e68c7afa",
              "builtID": "ks-upper-33",
              "externalID": "ocd-person/1b272330-b7d2-4d33-8828-2e45e68c7afa",
              "geometry": null
            },
            "contactInfo": {
              "email": "alicia.straub@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/rep_straub_alicia_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_straub_alicia_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_straub_alicia_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-33-upper-ocd-person-1b272330-b7d2-4d33-8828-2e45e68c7afa": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/3cfa0ac0-ff53-4f3b-9389-6962e409988a",
            "firstName": "Mark B.",
            "lastName": "Steffen",
            "fullName": "Mark Steffen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/sen_steffen_mark_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-KS-34-upper-ocd-person-3cfa0ac0-ff53-4f3b-9389-6962e409988a",
              "builtID": "ks-upper-34",
              "externalID": "ocd-person/3cfa0ac0-ff53-4f3b-9389-6962e409988a",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.steffen@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/sen_steffen_mark_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_steffen_mark_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-34-upper-ocd-person-3cfa0ac0-ff53-4f3b-9389-6962e409988a": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/d9e298ca-0584-41ff-bb18-082072dfe73f",
            "firstName": "Richard E.",
            "lastName": "Wilborn",
            "fullName": "Rick Wilborn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/sen_wilborn_rick_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-KS-35-upper-ocd-person-d9e298ca-0584-41ff-bb18-082072dfe73f",
              "builtID": "ks-upper-35",
              "externalID": "ocd-person/d9e298ca-0584-41ff-bb18-082072dfe73f",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.wilborn@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_wilborn_rick_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_wilborn_rick_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_wilborn_rick_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-35-upper-ocd-person-d9e298ca-0584-41ff-bb18-082072dfe73f": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/416dbc91-431f-4dc6-8c8a-ca2db2cd3300",
            "firstName": "Elaine",
            "lastName": "Bowers",
            "fullName": "Elaine Bowers",
            "gender": "Female",
            "party": "Republican",
            "image": "https://img1.wsimg.com/isteam/ip/80c40439-022d-4960-b9db-9edee1fb7239/116288352_1853414708116161_8330522447916288057.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=h:500,cg:true",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-KS-36-upper-ocd-person-416dbc91-431f-4dc6-8c8a-ca2db2cd3300",
              "builtID": "ks-upper-36",
              "externalID": "ocd-person/416dbc91-431f-4dc6-8c8a-ca2db2cd3300",
              "geometry": null
            },
            "contactInfo": {
              "email": "elaine.bowers@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_bowers_elaine_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_bowers_elaine_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_bowers_elaine_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-36-upper-ocd-person-416dbc91-431f-4dc6-8c8a-ca2db2cd3300": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/3238a53f-36f0-4dc7-8aa7-6cb521182851",
            "firstName": "Molly",
            "lastName": "Baumgardner",
            "fullName": "Molly Baumgardner",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.kansascity.com/news/local/community/johnson-county/olathe/ntsiie/picture718755/alternates/LANDSCAPE_1140/Baumgardner.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-KS-37-upper-ocd-person-3238a53f-36f0-4dc7-8aa7-6cb521182851",
              "builtID": "ks-upper-37",
              "externalID": "ocd-person/3238a53f-36f0-4dc7-8aa7-6cb521182851",
              "geometry": null
            },
            "contactInfo": {
              "email": "molly.baumgardner@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_baumgardner_molly_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_baumgardner_molly_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_baumgardner_molly_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-37-upper-ocd-person-3238a53f-36f0-4dc7-8aa7-6cb521182851": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/43caafd7-9177-40f4-b515-0681ef5930f3",
            "firstName": "Ronald W.",
            "lastName": "Ryckman",
            "fullName": "Ron Ryckman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.kslegislature.org/li/s/images/pics/sen_ryckman_ronald_1.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-KS-38-upper-ocd-person-43caafd7-9177-40f4-b515-0681ef5930f3",
              "builtID": "ks-upper-38",
              "externalID": "ocd-person/43caafd7-9177-40f4-b515-0681ef5930f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "ron.ryckman@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2021_22/members/sen_ryckman_ronald_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_ryckman_ronald_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-38-upper-ocd-person-43caafd7-9177-40f4-b515-0681ef5930f3": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/b2e4bec5-55fb-4bd2-a0f1-728997f9e780",
            "firstName": "John",
            "lastName": "Doll",
            "fullName": "John Doll",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/John_Doll.jpg",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-KS-39-upper-ocd-person-b2e4bec5-55fb-4bd2-a0f1-728997f9e780",
              "builtID": "ks-upper-39",
              "externalID": "ocd-person/b2e4bec5-55fb-4bd2-a0f1-728997f9e780",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.doll@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_doll_john_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_doll_john_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_doll_john_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-39-upper-ocd-person-b2e4bec5-55fb-4bd2-a0f1-728997f9e780": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/e5d951f2-88c7-47d4-ac28-08c175ba440d",
            "firstName": "Richard L.",
            "lastName": "Billinger",
            "fullName": "Rick Billinger",
            "gender": "Male",
            "party": "Republican",
            "image": "https://images.squarespace-cdn.com/content/v1/539a608ce4b077b40b218d99/1402633131997-X766NGTZ4126X7GA7H5P/image-asset.jpeg?format=1000w",
            "title": "KS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KS",
              "stateFull": "Kansas",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-KS-40-upper-ocd-person-e5d951f2-88c7-47d4-ac28-08c175ba440d",
              "builtID": "ks-upper-40",
              "externalID": "ocd-person/e5d951f2-88c7-47d4-ac28-08c175ba440d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.billinger@senate.ks.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.kslegislature.org/li/b2019_20/members/sen_billinger_rick_1/",
                "https://www.kslegislature.org/li/b2021_22/members/sen_billinger_rick_1/",
                "https://www.kslegislature.org/li/b2023_24/members/sen_billinger_rick_1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KS-40-upper-ocd-person-e5d951f2-88c7-47d4-ac28-08c175ba440d": 0
        }
      }
    }
  },
  "KY": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "KY",
          "stateFull": "Kentucky",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "KY",
            "stateFull": "Kentucky",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-KY---",
            "builtID": "ky--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-KY---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "M000355",
          "in_office": true,
          "firstName": "Mitch",
          "lastName": "McConnell",
          "middleName": null,
          "fullName": "Mitch McConnell",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/M000355.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "KY",
            "stateFull": "Kentucky",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-KY---M000355",
            "builtID": "ky--",
            "externalID": "M000355",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.mcconnell.senate.gov/public/index.cfm?p=contact",
            "address1": "317 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-2541",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "McConnellPress",
            "youtube": null,
            "instagram": null,
            "facebook": "mitchmcconnell",
            "urls": ["https://www.mcconnell.senate.gov"],
            "rss_url": "https://www.mcconnell.senate.gov/public/?a=RSS.Feed"
          },
          "title": "KY Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/M000355.json",
          "govtrack_id": "300072",
          "cspan_id": "2351",
          "votesmart_id": "53298",
          "icpsr_id": "14921",
          "crp_id": "N00003389",
          "google_entity_id": "/m/01z6ls",
          "state_rank": "senior",
          "lis_id": "S174",
          "suffix": null,
          "date_of_birth": "1942-02-20",
          "leadership_role": "Senate Minority Leader",
          "fec_candidate_id": "S2KY00012",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "39",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 35,
          "total_present": 0,
          "last_updated": "2024-06-20 15:30:29 -0400",
          "missed_votes_pct": 6.32,
          "votes_with_party_pct": 87.67,
          "votes_against_party_pct": 12.33,
          "ocd_id": "ocd-division/country:us/state:ky"
        },
        {
          "API_ID": "P000603",
          "in_office": true,
          "firstName": "Rand",
          "lastName": "Paul",
          "middleName": null,
          "fullName": "Rand Paul",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/P000603.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "KY",
            "stateFull": "Kentucky",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-KY---P000603",
            "builtID": "ky--",
            "externalID": "P000603",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "RandPaul",
            "youtube": "SenatorRandPaul",
            "instagram": null,
            "facebook": "SenatorRandPaul",
            "urls": ["https://www.paul.senate.gov"],
            "rss_url": null
          },
          "title": "KY Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/P000603.json",
          "govtrack_id": "412492",
          "cspan_id": "9265241",
          "votesmart_id": "117285",
          "icpsr_id": "41104",
          "crp_id": "N00030836",
          "google_entity_id": "/m/05pdb7q",
          "state_rank": "junior",
          "lis_id": "S348",
          "suffix": null,
          "date_of_birth": "1963-01-07",
          "leadership_role": null,
          "fec_candidate_id": "S0KY00156",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "13",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 27,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 4.87,
          "votes_with_party_pct": 81.4,
          "votes_against_party_pct": 18.6,
          "ocd_id": "ocd-division/country:us/state:ky"
        }
      ],
      "hash": { "SENATE-KY---M000355": 0, "SENATE-KY---P000603": 1 }
    },
    "HOUSE": {
      "06": {
        "members": [
          {
            "API_ID": "B001282",
            "in_office": true,
            "firstName": "Andy",
            "lastName": "Barr",
            "middleName": null,
            "fullName": "Andy Barr",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001282.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-KY-06--B001282",
              "builtID": "ky--06",
              "externalID": "B001282",
              "geometry": null,
              "geoid": "2106"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2430 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4706",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAndyBarr",
              "youtube": "RepAndyBarr",
              "instagram": null,
              "facebook": "RepAndyBarr",
              "urls": ["https://barr.house.gov"],
              "rss_url": null
            },
            "title": "KY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001282.json",
            "govtrack_id": "412541",
            "cspan_id": "61848",
            "votesmart_id": "117290",
            "icpsr_id": "21333",
            "crp_id": "N00031233",
            "google_entity_id": "/m/0ddc6r6",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1973-07-24",
            "leadership_role": null,
            "fec_candidate_id": "H0KY06104",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 58,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 5.5,
            "votes_with_party_pct": 93.46,
            "votes_against_party_pct": 4.6,
            "ocd_id": "ocd-division/country:us/state:ky/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-KY-06--B001282": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "C001108",
            "in_office": true,
            "firstName": "James",
            "lastName": "Comer",
            "middleName": null,
            "fullName": "James Comer",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001108.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-KY-01--C001108",
              "builtID": "ky--01",
              "externalID": "C001108",
              "geometry": null,
              "geoid": "2101"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2410 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3115",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJamesComer",
              "youtube": null,
              "instagram": null,
              "facebook": "congressmancomer",
              "urls": ["https://comer.house.gov"],
              "rss_url": null
            },
            "title": "KY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001108.json",
            "govtrack_id": "412676",
            "cspan_id": "76619",
            "votesmart_id": "35169",
            "icpsr_id": "21565",
            "crp_id": "N00038260",
            "google_entity_id": "/m/0j65cf0",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1972-08-19",
            "leadership_role": null,
            "fec_candidate_id": "H6KY01110",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 12,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.14,
            "votes_with_party_pct": 91.1,
            "votes_against_party_pct": 6.94,
            "ocd_id": "ocd-division/country:us/state:ky/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-KY-01--C001108": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "G000558",
            "in_office": true,
            "firstName": "Brett",
            "lastName": "Guthrie",
            "middleName": null,
            "fullName": "Brett Guthrie",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000558.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-KY-02--G000558",
              "builtID": "ky--02",
              "externalID": "G000558",
              "geometry": null,
              "geoid": "2102"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2434 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3501",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGuthrie",
              "youtube": "BrettGuthrie",
              "instagram": null,
              "facebook": "CongressmanGuthrie",
              "urls": ["https://guthrie.house.gov"],
              "rss_url": null
            },
            "title": "KY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000558.json",
            "govtrack_id": "412278",
            "cspan_id": "1031343",
            "votesmart_id": "18829",
            "icpsr_id": "20916",
            "crp_id": "N00029675",
            "google_entity_id": "/m/0411qqt",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1964-02-18",
            "leadership_role": null,
            "fec_candidate_id": "H8KY02031",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "16",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 1,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.09,
            "votes_with_party_pct": 93.32,
            "votes_against_party_pct": 4.84,
            "ocd_id": "ocd-division/country:us/state:ky/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-KY-02--G000558": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "M001184",
            "in_office": true,
            "firstName": "Thomas",
            "lastName": "Massie",
            "middleName": null,
            "fullName": "Thomas Massie",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001184.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-KY-04--M001184",
              "builtID": "ky--04",
              "externalID": "M001184",
              "geometry": null,
              "geoid": "2104"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2453 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3465",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepThomasMassie",
              "youtube": "repthomasmassie",
              "instagram": null,
              "facebook": "RepThomasMassie",
              "urls": ["https://massie.house.gov"],
              "rss_url": null
            },
            "title": "KY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001184.json",
            "govtrack_id": "412503",
            "cspan_id": "79951",
            "votesmart_id": "132068",
            "icpsr_id": "31102",
            "crp_id": "N00034041",
            "google_entity_id": "/m/0h_cb6v",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1971-01-13",
            "leadership_role": null,
            "fec_candidate_id": "H2KY04121",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 66,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 6.26,
            "votes_with_party_pct": 75.44,
            "votes_against_party_pct": 22.61,
            "ocd_id": "ocd-division/country:us/state:ky/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-KY-04--M001184": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "M001220",
            "in_office": true,
            "firstName": "Morgan",
            "lastName": "McGarvey",
            "middleName": null,
            "fullName": "Morgan McGarvey",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M001220.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-KY-03--M001220",
              "builtID": "ky--03",
              "externalID": "M001220",
              "geometry": null,
              "geoid": "2103"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1527 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5401",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMcGarvey",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://mcgarvey.house.gov/"],
              "rss_url": null
            },
            "title": "KY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001220.json",
            "govtrack_id": "456904",
            "cspan_id": null,
            "votesmart_id": "139826",
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1979-12-23",
            "leadership_role": null,
            "fec_candidate_id": "H2KY03206",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 2,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.19,
            "votes_with_party_pct": 96.65,
            "votes_against_party_pct": 1.44,
            "ocd_id": "ocd-division/country:us/state:ky/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-KY-03--M001220": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "R000395",
            "in_office": true,
            "firstName": "Harold",
            "lastName": "Rogers",
            "middleName": null,
            "fullName": "Harold Rogers",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/R000395.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-KY-05--R000395",
              "builtID": "ky--05",
              "externalID": "R000395",
              "geometry": null,
              "geoid": "2105"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2406 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4601",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepHalRogers",
              "youtube": "RepHalRogers",
              "instagram": null,
              "facebook": "CongressmanHalRogers",
              "urls": ["https://halrogers.house.gov"],
              "rss_url": null
            },
            "title": "KY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/R000395.json",
            "govtrack_id": "400340",
            "cspan_id": "6739",
            "votesmart_id": "26875",
            "icpsr_id": "14854",
            "crp_id": "N00003473",
            "google_entity_id": "/m/025jnx",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1937-12-31",
            "leadership_role": null,
            "fec_candidate_id": "H0KY05015",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "44",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 50,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 4.74,
            "votes_with_party_pct": 85.99,
            "votes_against_party_pct": 12.08,
            "ocd_id": "ocd-division/country:us/state:ky/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-KY-05--R000395": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/f73e54d0-9547-4688-b97d-4818b104d160",
            "firstName": "Steven Jack",
            "lastName": "Rudy",
            "fullName": "Steven Rudy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house1.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-KY-1-lower-ocd-person-f73e54d0-9547-4688-b97d-4818b104d160",
              "builtID": "ky-lower-1",
              "externalID": "ocd-person/f73e54d0-9547-4688-b97d-4818b104d160",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.rudy@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "350 Peppers Mill Drive, Paducah, KY 42001",
              "phone1": null,
              "phone2": "270-462-3156",
              "fax1": null,
              "fax2": null,
              "twitter": "kyrudy",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-1-lower-ocd-person-f73e54d0-9547-4688-b97d-4818b104d160": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/9e788295-a4ea-4572-aa06-d96aa9b0dc95",
            "firstName": "Richard",
            "lastName": "Heath",
            "fullName": "Richard Heath",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house2.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-KY-2-lower-ocd-person-9e788295-a4ea-4572-aa06-d96aa9b0dc95",
              "builtID": "ky-lower-2",
              "externalID": "ocd-person/9e788295-a4ea-4572-aa06-d96aa9b0dc95",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.heath@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=2"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-2-lower-ocd-person-9e788295-a4ea-4572-aa06-d96aa9b0dc95": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/c033dd77-f8d7-4558-8582-9e0088ae5406",
            "firstName": "Randy",
            "lastName": "Bridges",
            "fullName": "Randy Bridges",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house3.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-KY-3-lower-ocd-person-c033dd77-f8d7-4558-8582-9e0088ae5406",
              "builtID": "ky-lower-3",
              "externalID": "ocd-person/c033dd77-f8d7-4558-8582-9e0088ae5406",
              "geometry": null
            },
            "contactInfo": {
              "email": "randy.bridges@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=3"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-3-lower-ocd-person-c033dd77-f8d7-4558-8582-9e0088ae5406": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/66619aa2-a521-4318-b5d7-b4c36954b43d",
            "firstName": "D. Wade",
            "lastName": "Williams",
            "fullName": "Wade Williams",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house4.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-KY-4-lower-ocd-person-66619aa2-a521-4318-b5d7-b4c36954b43d",
              "builtID": "ky-lower-4",
              "externalID": "ocd-person/66619aa2-a521-4318-b5d7-b4c36954b43d",
              "geometry": null
            },
            "contactInfo": {
              "email": "wade.williams@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=4"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-4-lower-ocd-person-66619aa2-a521-4318-b5d7-b4c36954b43d": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/ad063b4f-a860-48c3-abfb-18e977a21caf",
            "firstName": "Mary Beth",
            "lastName": "Imes",
            "fullName": "Mary Beth Imes",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house5.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-KY-5-lower-ocd-person-ad063b4f-a860-48c3-abfb-18e977a21caf",
              "builtID": "ky-lower-5",
              "externalID": "ocd-person/ad063b4f-a860-48c3-abfb-18e977a21caf",
              "geometry": null
            },
            "contactInfo": {
              "email": "marybeth.imes@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=5"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-5-lower-ocd-person-ad063b4f-a860-48c3-abfb-18e977a21caf": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/db36edd8-6c2f-4441-aa30-5b4dc48b3357",
            "firstName": "Chris",
            "lastName": "Freeland",
            "fullName": "Chris Freeland",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house6.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-KY-6-lower-ocd-person-db36edd8-6c2f-4441-aa30-5b4dc48b3357",
              "builtID": "ky-lower-6",
              "externalID": "ocd-person/db36edd8-6c2f-4441-aa30-5b4dc48b3357",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.freeland@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=6"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-6-lower-ocd-person-db36edd8-6c2f-4441-aa30-5b4dc48b3357": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/92576283-508e-4613-b1a0-3d227edf4068",
            "firstName": "Vera Suzanne",
            "lastName": "Miles",
            "fullName": "Suzanne Miles",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house7.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-KY-7-lower-ocd-person-92576283-508e-4613-b1a0-3d227edf4068",
              "builtID": "ky-lower-7",
              "externalID": "ocd-person/92576283-508e-4613-b1a0-3d227edf4068",
              "geometry": null
            },
            "contactInfo": {
              "email": "suzanne.miles@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 21592, Owensboro, KY 42304",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "MilesForKY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=7"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-7-lower-ocd-person-92576283-508e-4613-b1a0-3d227edf4068": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/ec846db3-c75b-4460-ba72-3e2b3fd9e04d",
            "firstName": "Walker",
            "lastName": "Thomas",
            "fullName": "Walker Thomas",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house8.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-KY-8-lower-ocd-person-ec846db3-c75b-4460-ba72-3e2b3fd9e04d",
              "builtID": "ky-lower-8",
              "externalID": "ocd-person/ec846db3-c75b-4460-ba72-3e2b3fd9e04d",
              "geometry": null
            },
            "contactInfo": {
              "email": "walker.thomas@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2620 Cox Mill Road, Hopkinsville, KY 42240",
              "phone1": null,
              "phone2": "270-889-8091",
              "fax1": null,
              "fax2": "270-885-5335",
              "twitter": "walkerthomas_ky",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=8"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-8-lower-ocd-person-ec846db3-c75b-4460-ba72-3e2b3fd9e04d": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/c6904810-37a8-428d-8342-7831d7dc100c",
            "firstName": "Myron",
            "lastName": "Dossett",
            "fullName": "Myron Dossett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house9.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-KY-9-lower-ocd-person-c6904810-37a8-428d-8342-7831d7dc100c",
              "builtID": "ky-lower-9",
              "externalID": "ocd-person/c6904810-37a8-428d-8342-7831d7dc100c",
              "geometry": null
            },
            "contactInfo": {
              "email": "myron.dossett@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "491 E. Nashville St., Pembroke, KY 42266",
              "phone1": null,
              "phone2": "270-475-9503",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=9"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-9-lower-ocd-person-c6904810-37a8-428d-8342-7831d7dc100c": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/f218fb5a-2336-4f82-b192-acc3893dabdf",
            "firstName": "Josh",
            "lastName": "Calloway",
            "fullName": "Josh Calloway",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house10.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-KY-10-lower-ocd-person-f218fb5a-2336-4f82-b192-acc3893dabdf",
              "builtID": "ky-lower-10",
              "externalID": "ocd-person/f218fb5a-2336-4f82-b192-acc3893dabdf",
              "geometry": null
            },
            "contactInfo": {
              "email": "josh.calloway@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "117 Dents Bridge Road, Irvington, KY 40146",
              "phone1": null,
              "phone2": "270-863-1081",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=10"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-10-lower-ocd-person-f218fb5a-2336-4f82-b192-acc3893dabdf": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/88d36395-f0ac-4da3-837b-39f5a797e83b",
            "firstName": "Jonathan",
            "lastName": "Dixon",
            "fullName": "Jonathan Dixon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house11.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-KY-11-lower-ocd-person-88d36395-f0ac-4da3-837b-39f5a797e83b",
              "builtID": "ky-lower-11",
              "externalID": "ocd-person/88d36395-f0ac-4da3-837b-39f5a797e83b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jonathan.dixon@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=11"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-11-lower-ocd-person-88d36395-f0ac-4da3-837b-39f5a797e83b": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/f40ed494-ce37-43f4-b323-1bc2b6197831",
            "firstName": "Jim",
            "lastName": "Gooch",
            "fullName": "Jim Gooch",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house12.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-KY-12-lower-ocd-person-f40ed494-ce37-43f4-b323-1bc2b6197831",
              "builtID": "ky-lower-12",
              "externalID": "ocd-person/f40ed494-ce37-43f4-b323-1bc2b6197831",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.gooch@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "806 Princeton St., Providence, KY 42450",
              "phone1": null,
              "phone2": "270-667-7327",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=12"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-12-lower-ocd-person-f40ed494-ce37-43f4-b323-1bc2b6197831": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/ed9307f1-cd95-4004-9e72-46970ea64adf",
            "firstName": "D.J.",
            "lastName": "Johnson",
            "fullName": "D.J. Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house13.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-KY-13-lower-ocd-person-ed9307f1-cd95-4004-9e72-46970ea64adf",
              "builtID": "ky-lower-13",
              "externalID": "ocd-person/ed9307f1-cd95-4004-9e72-46970ea64adf",
              "geometry": null
            },
            "contactInfo": {
              "email": "dj.johnson@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1122, Owensboro, KY 42302",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.lrc.ky.gov/legislator/H013.htm",
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=13"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-13-lower-ocd-person-ed9307f1-cd95-4004-9e72-46970ea64adf": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/07773b3f-387f-4e4e-ba79-571940c186f0",
            "firstName": "Scott",
            "lastName": "Lewis",
            "fullName": "Scott Lewis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house14.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-KY-14-lower-ocd-person-07773b3f-387f-4e4e-ba79-571940c186f0",
              "builtID": "ky-lower-14",
              "externalID": "ocd-person/07773b3f-387f-4e4e-ba79-571940c186f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.lewis@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 454, Hartford, KY 42347",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=14"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-14-lower-ocd-person-07773b3f-387f-4e4e-ba79-571940c186f0": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/be2fd4e3-6dfe-4d54-8c45-631eac63bc27",
            "firstName": "Rebecca",
            "lastName": "Raymer",
            "fullName": "Rebecca Raymer",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house15.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-KY-15-lower-ocd-person-be2fd4e3-6dfe-4d54-8c45-631eac63bc27",
              "builtID": "ky-lower-15",
              "externalID": "ocd-person/be2fd4e3-6dfe-4d54-8c45-631eac63bc27",
              "geometry": null
            },
            "contactInfo": {
              "email": "rebecca.raymer@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "raymerforky",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=15"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-15-lower-ocd-person-be2fd4e3-6dfe-4d54-8c45-631eac63bc27": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/3fac3a9c-78ad-4b24-8483-f1786fac9c9e",
            "firstName": "Jason",
            "lastName": "Petrie",
            "fullName": "Jason Petrie",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house16.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-KY-16-lower-ocd-person-3fac3a9c-78ad-4b24-8483-f1786fac9c9e",
              "builtID": "ky-lower-16",
              "externalID": "ocd-person/3fac3a9c-78ad-4b24-8483-f1786fac9c9e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.petrie@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=16"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-16-lower-ocd-person-3fac3a9c-78ad-4b24-8483-f1786fac9c9e": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/ad2d1d2a-b838-4727-ac0f-a6fb64298b14",
            "firstName": "Robert B.",
            "lastName": "Duvall",
            "fullName": "Robert Duvall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house17.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-KY-17-lower-ocd-person-ad2d1d2a-b838-4727-ac0f-a6fb64298b14",
              "builtID": "ky-lower-17",
              "externalID": "ocd-person/ad2d1d2a-b838-4727-ac0f-a6fb64298b14",
              "geometry": null
            },
            "contactInfo": {
              "email": "robert.duvall@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1297, Bowling Green, KY 42102",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=17"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-17-lower-ocd-person-ad2d1d2a-b838-4727-ac0f-a6fb64298b14": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/d3176059-683c-48be-9c43-a57da08a2f49",
            "firstName": "Samara Rae",
            "lastName": "Heavrin",
            "fullName": "Samara Heavrin",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house18.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-KY-18-lower-ocd-person-d3176059-683c-48be-9c43-a57da08a2f49",
              "builtID": "ky-lower-18",
              "externalID": "ocd-person/d3176059-683c-48be-9c43-a57da08a2f49",
              "geometry": null
            },
            "contactInfo": {
              "email": "samara.heavrin@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "474 Mulberry St. Apartment B, Leitchfield, KY 42754",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=18"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-18-lower-ocd-person-d3176059-683c-48be-9c43-a57da08a2f49": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/f013115a-6bbc-48ac-aab4-7949dd902701",
            "firstName": "Michael Lee",
            "lastName": "Meredith",
            "fullName": "Michael Meredith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house19.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-KY-19-lower-ocd-person-f013115a-6bbc-48ac-aab4-7949dd902701",
              "builtID": "ky-lower-19",
              "externalID": "ocd-person/f013115a-6bbc-48ac-aab4-7949dd902701",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.meredith@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 292, Brownsville, KY 42210",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=19"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-19-lower-ocd-person-f013115a-6bbc-48ac-aab4-7949dd902701": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/0e905c68-fbcc-4392-865e-a4dbc5d2ea42",
            "firstName": "Kevin",
            "lastName": "Jackson",
            "fullName": "Kevin Jackson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house20.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-KY-20-lower-ocd-person-0e905c68-fbcc-4392-865e-a4dbc5d2ea42",
              "builtID": "ky-lower-20",
              "externalID": "ocd-person/0e905c68-fbcc-4392-865e-a4dbc5d2ea42",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.jackson@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=20"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-20-lower-ocd-person-0e905c68-fbcc-4392-865e-a4dbc5d2ea42": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/f7bb262c-026f-4af2-9e17-939692c96e89",
            "firstName": "Amy",
            "lastName": "Neighbors",
            "fullName": "Amy Neighbors",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house21.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-KY-21-lower-ocd-person-f7bb262c-026f-4af2-9e17-939692c96e89",
              "builtID": "ky-lower-21",
              "externalID": "ocd-person/f7bb262c-026f-4af2-9e17-939692c96e89",
              "geometry": null
            },
            "contactInfo": {
              "email": "amy.neighbors@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "aneigh",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=21"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-21-lower-ocd-person-f7bb262c-026f-4af2-9e17-939692c96e89": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/5a327e3b-ae91-4279-ae17-9214aad32fa9",
            "firstName": "Shawn",
            "lastName": "McPherson",
            "fullName": "Shawn McPherson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house22.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-KY-22-lower-ocd-person-5a327e3b-ae91-4279-ae17-9214aad32fa9",
              "builtID": "ky-lower-22",
              "externalID": "ocd-person/5a327e3b-ae91-4279-ae17-9214aad32fa9",
              "geometry": null
            },
            "contactInfo": {
              "email": "shawn.mcpherson@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "801 E. Main St., Scottsville, KY 42164",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=22"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-22-lower-ocd-person-5a327e3b-ae91-4279-ae17-9214aad32fa9": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/817df40b-8e76-460d-81cf-63277866268a",
            "firstName": "Steve",
            "lastName": "Riley",
            "fullName": "Steve Riley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house23.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-KY-23-lower-ocd-person-817df40b-8e76-460d-81cf-63277866268a",
              "builtID": "ky-lower-23",
              "externalID": "ocd-person/817df40b-8e76-460d-81cf-63277866268a",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.riley@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "189 Blue Sky Drive, Glasgow, KY 42141",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=23"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-23-lower-ocd-person-817df40b-8e76-460d-81cf-63277866268a": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/a7d1434c-dea5-4b88-81fb-7579ef543095",
            "firstName": "Courtney",
            "lastName": "Gilbert",
            "fullName": "Courtney Gilbert",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Courtney_Gilbert.jpeg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-KY-24-lower-ocd-person-a7d1434c-dea5-4b88-81fb-7579ef543095",
              "builtID": "ky-lower-24",
              "externalID": "ocd-person/a7d1434c-dea5-4b88-81fb-7579ef543095",
              "geometry": null
            },
            "contactInfo": {
              "email": "courtney.gilbert@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=24"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-24-lower-ocd-person-a7d1434c-dea5-4b88-81fb-7579ef543095": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/e0860aa8-43ba-4bf2-94d7-3632c7375ab6",
            "firstName": "Steve",
            "lastName": "Bratcher",
            "fullName": "Steve Bratcher",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house25.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-KY-25-lower-ocd-person-e0860aa8-43ba-4bf2-94d7-3632c7375ab6",
              "builtID": "ky-lower-25",
              "externalID": "ocd-person/e0860aa8-43ba-4bf2-94d7-3632c7375ab6",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.bratcher@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=25"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-25-lower-ocd-person-e0860aa8-43ba-4bf2-94d7-3632c7375ab6": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/eddce954-34a0-4b9a-aaca-6114e6bf418f",
            "firstName": "Peyton Holmes Porter",
            "lastName": "Griffee",
            "fullName": "Peyton Griffee",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/PeytonGriffee2024.png",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-KY-26-lower-ocd-person-eddce954-34a0-4b9a-aaca-6114e6bf418f",
              "builtID": "ky-lower-26",
              "externalID": "ocd-person/eddce954-34a0-4b9a-aaca-6114e6bf418f",
              "geometry": null
            },
            "contactInfo": {
              "email": "peyton.griffee@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=26"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-26-lower-ocd-person-eddce954-34a0-4b9a-aaca-6114e6bf418f": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/911af3e2-7435-4f82-a477-99cb078ea99b",
            "firstName": "Nancy",
            "lastName": "Tate",
            "fullName": "Nancy Tate",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house27.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-KY-27-lower-ocd-person-911af3e2-7435-4f82-a477-99cb078ea99b",
              "builtID": "ky-lower-27",
              "externalID": "ocd-person/911af3e2-7435-4f82-a477-99cb078ea99b",
              "geometry": null
            },
            "contactInfo": {
              "email": "nancy.tate@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=27"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-27-lower-ocd-person-911af3e2-7435-4f82-a477-99cb078ea99b": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/b86f4912-39b1-44a0-90a8-0b233921a094",
            "firstName": "Jared",
            "lastName": "Bauman",
            "fullName": "Jared Bauman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house28.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-KY-28-lower-ocd-person-b86f4912-39b1-44a0-90a8-0b233921a094",
              "builtID": "ky-lower-28",
              "externalID": "ocd-person/b86f4912-39b1-44a0-90a8-0b233921a094",
              "geometry": null
            },
            "contactInfo": {
              "email": "jared.bauman@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "KY",
              "phone1": null,
              "phone2": "502-544-1846",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=28"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-28-lower-ocd-person-b86f4912-39b1-44a0-90a8-0b233921a094": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/035b1122-ff5d-4e14-ac67-60871c5e7fea",
            "firstName": "Kevin D.",
            "lastName": "Bratcher",
            "fullName": "Kevin Bratcher",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house29.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-KY-29-lower-ocd-person-035b1122-ff5d-4e14-ac67-60871c5e7fea",
              "builtID": "ky-lower-29",
              "externalID": "ocd-person/035b1122-ff5d-4e14-ac67-60871c5e7fea",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.bratcher@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBratcherKY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=29"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-29-lower-ocd-person-035b1122-ff5d-4e14-ac67-60871c5e7fea": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/d4dd968d-1508-476b-bde1-7504a47288f0",
            "firstName": "Daniel",
            "lastName": "Grossberg",
            "fullName": "Daniel Grossberg",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house30.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-KY-30-lower-ocd-person-d4dd968d-1508-476b-bde1-7504a47288f0",
              "builtID": "ky-lower-30",
              "externalID": "ocd-person/d4dd968d-1508-476b-bde1-7504a47288f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.grossberg@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Grossberg4KY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=30"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-30-lower-ocd-person-d4dd968d-1508-476b-bde1-7504a47288f0": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/c85ace8f-67e3-438d-afeb-e6442aec43cd",
            "firstName": "Susan",
            "lastName": "Tyler Witten",
            "fullName": "Susan Tyler Witten",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house31.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-KY-31-lower-ocd-person-c85ace8f-67e3-438d-afeb-e6442aec43cd",
              "builtID": "ky-lower-31",
              "externalID": "ocd-person/c85ace8f-67e3-438d-afeb-e6442aec43cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.witten@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 43861, Louisville, KY 40253",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "catfaninthevill",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=31"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-31-lower-ocd-person-c85ace8f-67e3-438d-afeb-e6442aec43cd": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/77db7bcf-dfa8-4365-bb3a-4ca0351a8368",
            "firstName": "Tina",
            "lastName": "Bojanowski",
            "fullName": "Tina Bojanowski",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house32.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-KY-32-lower-ocd-person-77db7bcf-dfa8-4365-bb3a-4ca0351a8368",
              "builtID": "ky-lower-32",
              "externalID": "ocd-person/77db7bcf-dfa8-4365-bb3a-4ca0351a8368",
              "geometry": null
            },
            "contactInfo": {
              "email": "tina.bojanowski@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "tinaforkentucky",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=32"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-32-lower-ocd-person-77db7bcf-dfa8-4365-bb3a-4ca0351a8368": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/c56fc398-975e-47b9-919a-fbdd0c9f721d",
            "firstName": "Jason M.",
            "lastName": "Nemes",
            "fullName": "Jason Nemes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house33.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-KY-33-lower-ocd-person-c56fc398-975e-47b9-919a-fbdd0c9f721d",
              "builtID": "ky-lower-33",
              "externalID": "ocd-person/c56fc398-975e-47b9-919a-fbdd0c9f721d",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.nemes@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "jmnemes",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=33"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-33-lower-ocd-person-c56fc398-975e-47b9-919a-fbdd0c9f721d": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/bb3f614a-7fa0-4e55-a76b-dedfea0fdaa9",
            "firstName": "Sarah",
            "lastName": "Stalker",
            "fullName": "Sarah Stalker",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house34.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-KY-34-lower-ocd-person-bb3f614a-7fa0-4e55-a76b-dedfea0fdaa9",
              "builtID": "ky-lower-34",
              "externalID": "ocd-person/bb3f614a-7fa0-4e55-a76b-dedfea0fdaa9",
              "geometry": null
            },
            "contactInfo": {
              "email": "sarah.stalker@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "sarahforky",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=34"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-34-lower-ocd-person-bb3f614a-7fa0-4e55-a76b-dedfea0fdaa9": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/6dd815a4-fc3c-4e79-8cd5-a574d8cf0af8",
            "firstName": "Lisa",
            "lastName": "Willner",
            "fullName": "Lisa Willner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house35.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-KY-35-lower-ocd-person-6dd815a4-fc3c-4e79-8cd5-a574d8cf0af8",
              "builtID": "ky-lower-35",
              "externalID": "ocd-person/6dd815a4-fc3c-4e79-8cd5-a574d8cf0af8",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisa.willner@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "lgwillner",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=35"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-35-lower-ocd-person-6dd815a4-fc3c-4e79-8cd5-a574d8cf0af8": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/2f3255d6-7801-4089-a275-f01e00444ee0",
            "firstName": "John",
            "lastName": "Hodgson",
            "fullName": "John Hodgson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house36.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-KY-36-lower-ocd-person-2f3255d6-7801-4089-a275-f01e00444ee0",
              "builtID": "ky-lower-36",
              "externalID": "ocd-person/2f3255d6-7801-4089-a275-f01e00444ee0",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.hodgson@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 74, Fischerville, KY 40023",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=36"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-36-lower-ocd-person-2f3255d6-7801-4089-a275-f01e00444ee0": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/67b233b8-474d-49ce-81a8-7f48eec3c79a",
            "firstName": "Emily",
            "lastName": "Callaway",
            "fullName": "Emily Callaway",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house37.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-KY-37-lower-ocd-person-67b233b8-474d-49ce-81a8-7f48eec3c79a",
              "builtID": "ky-lower-37",
              "externalID": "ocd-person/67b233b8-474d-49ce-81a8-7f48eec3c79a",
              "geometry": null
            },
            "contactInfo": {
              "email": "emily.callaway@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4621 Outer Loop #234, Louisville, KY 40219",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Miz_Emc",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=37"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-37-lower-ocd-person-67b233b8-474d-49ce-81a8-7f48eec3c79a": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/1217ac1a-4ca4-4fe4-953b-572beb008bed",
            "firstName": "Rachel",
            "lastName": "Roarx",
            "fullName": "Rachel Roarx",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house38.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-KY-38-lower-ocd-person-1217ac1a-4ca4-4fe4-953b-572beb008bed",
              "builtID": "ky-lower-38",
              "externalID": "ocd-person/1217ac1a-4ca4-4fe4-953b-572beb008bed",
              "geometry": null
            },
            "contactInfo": {
              "email": "rachel.roarx@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2306 Pikes Peak Blvd., Louisville, KY 40214",
              "phone1": null,
              "phone2": "502-724-7529",
              "fax1": null,
              "fax2": null,
              "twitter": "rachelroarx",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=38"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-38-lower-ocd-person-1217ac1a-4ca4-4fe4-953b-572beb008bed": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/9a6d1b5a-6cb4-421e-b7c2-2cb27cfa4cc9",
            "firstName": "Edward Matthew",
            "lastName": "Lockett",
            "fullName": "Matt Lockett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house39.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-KY-39-lower-ocd-person-9a6d1b5a-6cb4-421e-b7c2-2cb27cfa4cc9",
              "builtID": "ky-lower-39",
              "externalID": "ocd-person/9a6d1b5a-6cb4-421e-b7c2-2cb27cfa4cc9",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.lockett@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=39"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-39-lower-ocd-person-9a6d1b5a-6cb4-421e-b7c2-2cb27cfa4cc9": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/bee3f0dd-450a-41a2-b9b9-e056d0a0f14d",
            "firstName": "Nima",
            "lastName": "Kulkarni",
            "fullName": "Nima Kulkarni",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house40.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-KY-40-lower-ocd-person-bee3f0dd-450a-41a2-b9b9-e056d0a0f14d",
              "builtID": "ky-lower-40",
              "externalID": "ocd-person/bee3f0dd-450a-41a2-b9b9-e056d0a0f14d",
              "geometry": null
            },
            "contactInfo": {
              "email": "nima.kulkarni@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=40"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-40-lower-ocd-person-bee3f0dd-450a-41a2-b9b9-e056d0a0f14d": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/2973d2c4-1ca8-4842-a3d9-d5444b212c00",
            "firstName": "Josie",
            "lastName": "Raymond",
            "fullName": "Josie Raymond",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house41.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-KY-41-lower-ocd-person-2973d2c4-1ca8-4842-a3d9-d5444b212c00",
              "builtID": "ky-lower-41",
              "externalID": "ocd-person/2973d2c4-1ca8-4842-a3d9-d5444b212c00",
              "geometry": null
            },
            "contactInfo": {
              "email": "josie.raymond@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repjosieraymond",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=31",
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=31",
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=41"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-41-lower-ocd-person-2973d2c4-1ca8-4842-a3d9-d5444b212c00": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/15b970f1-bd55-4876-80d5-13570b8f1a1a",
            "firstName": "Keturah",
            "lastName": "Herron",
            "fullName": "Keturah Herron",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house42.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-KY-42-lower-ocd-person-15b970f1-bd55-4876-80d5-13570b8f1a1a",
              "builtID": "ky-lower-42",
              "externalID": "ocd-person/15b970f1-bd55-4876-80d5-13570b8f1a1a",
              "geometry": null
            },
            "contactInfo": {
              "email": "keturah.herron@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=42"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-42-lower-ocd-person-15b970f1-bd55-4876-80d5-13570b8f1a1a": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/897f811e-273d-4fc1-9268-0087e57444cf",
            "firstName": "Pamela D.",
            "lastName": "Stevenson",
            "fullName": "Pamela Stevenson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house43.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-KY-43-lower-ocd-person-897f811e-273d-4fc1-9268-0087e57444cf",
              "builtID": "ky-lower-43",
              "externalID": "ocd-person/897f811e-273d-4fc1-9268-0087e57444cf",
              "geometry": null
            },
            "contactInfo": {
              "email": "pamela.stevenson@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=43"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-43-lower-ocd-person-897f811e-273d-4fc1-9268-0087e57444cf": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/c8a9f2cf-9a79-45f6-94df-346b546e1d9b",
            "firstName": "Beverly",
            "lastName": "Chester-Burton",
            "fullName": "Beverly Chester-Burton",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house44.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-KY-44-lower-ocd-person-c8a9f2cf-9a79-45f6-94df-346b546e1d9b",
              "builtID": "ky-lower-44",
              "externalID": "ocd-person/c8a9f2cf-9a79-45f6-94df-346b546e1d9b",
              "geometry": null
            },
            "contactInfo": {
              "email": "beverly.chesterburton@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=44"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-44-lower-ocd-person-c8a9f2cf-9a79-45f6-94df-346b546e1d9b": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/60d081e8-3608-4946-85ae-b4940fc5dd05",
            "firstName": "Killian",
            "lastName": "Timoney",
            "fullName": "Killian Timoney",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house45.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-KY-45-lower-ocd-person-60d081e8-3608-4946-85ae-b4940fc5dd05",
              "builtID": "ky-lower-45",
              "externalID": "ocd-person/60d081e8-3608-4946-85ae-b4940fc5dd05",
              "geometry": null
            },
            "contactInfo": {
              "email": "killian.timoney@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "kykillian",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=45"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-45-lower-ocd-person-60d081e8-3608-4946-85ae-b4940fc5dd05": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/dfaa3769-decc-4c1c-9c0b-7195153ab6c8",
            "firstName": "Alan",
            "lastName": "Gentry",
            "fullName": "Al Gentry",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house46.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-KY-46-lower-ocd-person-dfaa3769-decc-4c1c-9c0b-7195153ab6c8",
              "builtID": "ky-lower-46",
              "externalID": "ocd-person/dfaa3769-decc-4c1c-9c0b-7195153ab6c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "al.gentry@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "8406 Cloverport Drive, Louisville, KY 40228",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "kyalgentry",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=46"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-46-lower-ocd-person-dfaa3769-decc-4c1c-9c0b-7195153ab6c8": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/4342be62-2791-4d48-b601-43949239cd94",
            "firstName": "Felicia Marie Smith",
            "lastName": "Rabourn",
            "fullName": "Felicia Rabourn",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house47.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-KY-47-lower-ocd-person-4342be62-2791-4d48-b601-43949239cd94",
              "builtID": "ky-lower-47",
              "externalID": "ocd-person/4342be62-2791-4d48-b601-43949239cd94",
              "geometry": null
            },
            "contactInfo": {
              "email": "felicia.rabourn@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. BOX 47, Campbellsburg, KY 40011",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=47"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-47-lower-ocd-person-4342be62-2791-4d48-b601-43949239cd94": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/d7b86c25-2f90-4d8b-a92e-4b4ef5dd0512",
            "firstName": "Ken",
            "lastName": "Fleming",
            "fullName": "Ken Fleming",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house48.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-KY-48-lower-ocd-person-d7b86c25-2f90-4d8b-a92e-4b4ef5dd0512",
              "builtID": "ky-lower-48",
              "externalID": "ocd-person/d7b86c25-2f90-4d8b-a92e-4b4ef5dd0512",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.fleming@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.lrc.ky.gov/legislator/H048.htm",
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=48"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-48-lower-ocd-person-d7b86c25-2f90-4d8b-a92e-4b4ef5dd0512": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/7d2b01df-9356-4eaa-923f-144e3f229860",
            "firstName": "Thomas",
            "lastName": "Huff",
            "fullName": "Thomas Huff",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house49.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-KY-49-lower-ocd-person-7d2b01df-9356-4eaa-923f-144e3f229860",
              "builtID": "ky-lower-49",
              "externalID": "ocd-person/7d2b01df-9356-4eaa-923f-144e3f229860",
              "geometry": null
            },
            "contactInfo": {
              "email": "thomas.huff@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1331, Shepherdsville, KY 40165",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=49"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-49-lower-ocd-person-7d2b01df-9356-4eaa-923f-144e3f229860": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/70d58905-dd36-4e9a-b281-639600e6b945",
            "firstName": "Candy Desire",
            "lastName": "Massaroni",
            "fullName": "Candy Massaroni",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house50.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-KY-50-lower-ocd-person-70d58905-dd36-4e9a-b281-639600e6b945",
              "builtID": "ky-lower-50",
              "externalID": "ocd-person/70d58905-dd36-4e9a-b281-639600e6b945",
              "geometry": null
            },
            "contactInfo": {
              "email": "candy.massaroni@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=50"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-50-lower-ocd-person-70d58905-dd36-4e9a-b281-639600e6b945": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/3e417407-423f-45d3-b18a-c2845cd1ec57",
            "firstName": "Michael",
            "lastName": "Pollock",
            "fullName": "Sarge Pollock",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house51.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-KY-51-lower-ocd-person-3e417407-423f-45d3-b18a-c2845cd1ec57",
              "builtID": "ky-lower-51",
              "externalID": "ocd-person/3e417407-423f-45d3-b18a-c2845cd1ec57",
              "geometry": null
            },
            "contactInfo": {
              "email": "sarge.pollock@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 164, Campbellsville, KY 42719",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=51"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-51-lower-ocd-person-3e417407-423f-45d3-b18a-c2845cd1ec57": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/7aa13bf4-126a-4901-bfeb-2244bd2d3cc3",
            "firstName": "Kenneth H.",
            "lastName": "Upchurch",
            "fullName": "Ken Upchurch",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house52.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-KY-52-lower-ocd-person-7aa13bf4-126a-4901-bfeb-2244bd2d3cc3",
              "builtID": "ky-lower-52",
              "externalID": "ocd-person/7aa13bf4-126a-4901-bfeb-2244bd2d3cc3",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.upchurch@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 969, Monticello, KY 42633",
              "phone1": null,
              "phone2": "606-340-8490",
              "fax1": null,
              "fax2": null,
              "twitter": "KenUpchurch",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=52"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-52-lower-ocd-person-7aa13bf4-126a-4901-bfeb-2244bd2d3cc3": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/d6c9aa9b-3c6b-411a-bff1-fc1c38d4bce0",
            "firstName": "James Allen",
            "lastName": "Tipton",
            "fullName": "James Tipton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house53.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-KY-53-lower-ocd-person-d6c9aa9b-3c6b-411a-bff1-fc1c38d4bce0",
              "builtID": "ky-lower-53",
              "externalID": "ocd-person/d6c9aa9b-3c6b-411a-bff1-fc1c38d4bce0",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.tipton@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "8151 Little Mount Road, Taylorsville, KY 40071",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "JamesATipton",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=53"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-53-lower-ocd-person-d6c9aa9b-3c6b-411a-bff1-fc1c38d4bce0": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/d93e2493-49a8-4395-b7e0-5842893344e1",
            "firstName": "Daniel B.",
            "lastName": "Elliott",
            "fullName": "Daniel Elliott",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house54.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-KY-54-lower-ocd-person-d93e2493-49a8-4395-b7e0-5842893344e1",
              "builtID": "ky-lower-54",
              "externalID": "ocd-person/d93e2493-49a8-4395-b7e0-5842893344e1",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.elliott@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 2082, Danville, KY 40423",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=54"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-54-lower-ocd-person-d93e2493-49a8-4395-b7e0-5842893344e1": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/cd95ada0-14f0-4b63-aeea-f4a92525eeaf",
            "firstName": "Kimberly Paige",
            "lastName": "King",
            "fullName": "Kim King",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house55.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-KY-55-lower-ocd-person-cd95ada0-14f0-4b63-aeea-f4a92525eeaf",
              "builtID": "ky-lower-55",
              "externalID": "ocd-person/cd95ada0-14f0-4b63-aeea-f4a92525eeaf",
              "geometry": null
            },
            "contactInfo": {
              "email": "kim.king@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "250 Bright Leaf Drive, Harrodsburg, KY 40330",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "KimberlyKingGOP",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=55"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-55-lower-ocd-person-cd95ada0-14f0-4b63-aeea-f4a92525eeaf": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/c88c6162-de24-4d8b-98a9-f1788390d043",
            "firstName": "Daniel",
            "lastName": "Fister",
            "fullName": "Dan Fister",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house56.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-KY-56-lower-ocd-person-c88c6162-de24-4d8b-98a9-f1788390d043",
              "builtID": "ky-lower-56",
              "externalID": "ocd-person/c88c6162-de24-4d8b-98a9-f1788390d043",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.fister@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=56"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-56-lower-ocd-person-c88c6162-de24-4d8b-98a9-f1788390d043": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/27334d5d-f890-4082-bfbb-3d11af8e3756",
            "firstName": "Derrick",
            "lastName": "Graham",
            "fullName": "Derrick Graham",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house57.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-KY-57-lower-ocd-person-27334d5d-f890-4082-bfbb-3d11af8e3756",
              "builtID": "ky-lower-57",
              "externalID": "ocd-person/27334d5d-f890-4082-bfbb-3d11af8e3756",
              "geometry": null
            },
            "contactInfo": {
              "email": "derrick.graham@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "157 Bellemeade Drive, Frankfort, KY 40601",
              "phone1": null,
              "phone2": "502-223-1769",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=57"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-57-lower-ocd-person-27334d5d-f890-4082-bfbb-3d11af8e3756": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/2c5c20cc-807b-44c8-a1a9-582fe69ca87c",
            "firstName": "Jennifer Suzanne Henson",
            "lastName": "Decker",
            "fullName": "Jennifer Decker",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house58.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-KY-58-lower-ocd-person-2c5c20cc-807b-44c8-a1a9-582fe69ca87c",
              "builtID": "ky-lower-58",
              "externalID": "ocd-person/2c5c20cc-807b-44c8-a1a9-582fe69ca87c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jennifer.decker@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=58"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-58-lower-ocd-person-2c5c20cc-807b-44c8-a1a9-582fe69ca87c": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/bf8bfdc3-66f2-417c-8178-9ed16bf8843f",
            "firstName": "David W.",
            "lastName": "Osborne",
            "fullName": "David Osborne",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house59.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-KY-59-lower-ocd-person-bf8bfdc3-66f2-417c-8178-9ed16bf8843f",
              "builtID": "ky-lower-59",
              "externalID": "ocd-person/bf8bfdc3-66f2-417c-8178-9ed16bf8843f",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.osborne@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 8, Prospect, KY 40059",
              "phone1": null,
              "phone2": "502-645-2186",
              "fax1": null,
              "fax2": null,
              "twitter": "reposborne",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=59"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-59-lower-ocd-person-bf8bfdc3-66f2-417c-8178-9ed16bf8843f": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/1ad4657b-84d4-4ce1-864e-2f1f75686cc7",
            "firstName": "Marianne Grooms",
            "lastName": "Proctor",
            "fullName": "Marianne Proctor",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house60.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-KY-60-lower-ocd-person-1ad4657b-84d4-4ce1-864e-2f1f75686cc7",
              "builtID": "ky-lower-60",
              "externalID": "ocd-person/1ad4657b-84d4-4ce1-864e-2f1f75686cc7",
              "geometry": null
            },
            "contactInfo": {
              "email": "marianne.proctor@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=60"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-60-lower-ocd-person-1ad4657b-84d4-4ce1-864e-2f1f75686cc7": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/a8988de2-d65f-461c-96d1-4f1cddc6a969",
            "firstName": "Savannah",
            "lastName": "Maddox",
            "fullName": "Savannah Maddox",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house61.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-KY-61-lower-ocd-person-a8988de2-d65f-461c-96d1-4f1cddc6a969",
              "builtID": "ky-lower-61",
              "externalID": "ocd-person/a8988de2-d65f-461c-96d1-4f1cddc6a969",
              "geometry": null
            },
            "contactInfo": {
              "email": "savannah.maddox@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SavannahLMaddox",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=61"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-61-lower-ocd-person-a8988de2-d65f-461c-96d1-4f1cddc6a969": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/91e57008-84f5-42ad-9f31-a2869bced4bb",
            "firstName": "Phillip",
            "lastName": "Pratt",
            "fullName": "Phillip Pratt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house62.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-KY-62-lower-ocd-person-91e57008-84f5-42ad-9f31-a2869bced4bb",
              "builtID": "ky-lower-62",
              "externalID": "ocd-person/91e57008-84f5-42ad-9f31-a2869bced4bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "phillip.pratt@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=62"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-62-lower-ocd-person-91e57008-84f5-42ad-9f31-a2869bced4bb": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/27b951aa-c951-4160-9373-721ca3b6a9db",
            "firstName": "Kimberly",
            "lastName": "Banta",
            "fullName": "Kim Banta",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house63.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-KY-63-lower-ocd-person-27b951aa-c951-4160-9373-721ca3b6a9db",
              "builtID": "ky-lower-63",
              "externalID": "ocd-person/27b951aa-c951-4160-9373-721ca3b6a9db",
              "geometry": null
            },
            "contactInfo": {
              "email": "kim.banta@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=63"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-63-lower-ocd-person-27b951aa-c951-4160-9373-721ca3b6a9db": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/92511255-ca06-4241-bd91-0cafd92e3d5c",
            "firstName": "Kimberly Poore",
            "lastName": "Moser",
            "fullName": "Kim Moser",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house64.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-KY-64-lower-ocd-person-92511255-ca06-4241-bd91-0cafd92e3d5c",
              "builtID": "ky-lower-64",
              "externalID": "ocd-person/92511255-ca06-4241-bd91-0cafd92e3d5c",
              "geometry": null
            },
            "contactInfo": {
              "email": "kimberly.moser@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 143, Independence, KY 41051",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=64"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-64-lower-ocd-person-92511255-ca06-4241-bd91-0cafd92e3d5c": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/737ae037-a61c-4649-9c08-bf5fe052ff36",
            "firstName": "Stephanie Ann",
            "lastName": "Dietz",
            "fullName": "Stephanie Dietz",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house65.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-KY-65-lower-ocd-person-737ae037-a61c-4649-9c08-bf5fe052ff36",
              "builtID": "ky-lower-65",
              "externalID": "ocd-person/737ae037-a61c-4649-9c08-bf5fe052ff36",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephanie.dietz@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "sdietz2",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=65"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-65-lower-ocd-person-737ae037-a61c-4649-9c08-bf5fe052ff36": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/b702771e-135c-4444-b148-a321ee8088b2",
            "firstName": "John Steven",
            "lastName": "Rawlings",
            "fullName": "Steve Rawlings",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house66.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-KY-66-lower-ocd-person-b702771e-135c-4444-b148-a321ee8088b2",
              "builtID": "ky-lower-66",
              "externalID": "ocd-person/b702771e-135c-4444-b148-a321ee8088b2",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.rawlings@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3350 Easton Lane, Burlington, KY 41005",
              "phone1": null,
              "phone2": "859-757-7037",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=66"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-66-lower-ocd-person-b702771e-135c-4444-b148-a321ee8088b2": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/412e31e1-fc10-4abd-90de-3af87a5b7f4d",
            "firstName": "Rachel Catherine",
            "lastName": "Roberts",
            "fullName": "Rachel Roberts",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house67.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-KY-67-lower-ocd-person-412e31e1-fc10-4abd-90de-3af87a5b7f4d",
              "builtID": "ky-lower-67",
              "externalID": "ocd-person/412e31e1-fc10-4abd-90de-3af87a5b7f4d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rachel.roberts@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Rachel4KY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=67"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-67-lower-ocd-person-412e31e1-fc10-4abd-90de-3af87a5b7f4d": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/34a39b2d-1ff6-4ced-adf2-32f92fa781b1",
            "firstName": "Mike",
            "lastName": "Clines",
            "fullName": "Mike Clines",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house68.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-KY-68-lower-ocd-person-34a39b2d-1ff6-4ced-adf2-32f92fa781b1",
              "builtID": "ky-lower-68",
              "externalID": "ocd-person/34a39b2d-1ff6-4ced-adf2-32f92fa781b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.clines@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=68"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-68-lower-ocd-person-34a39b2d-1ff6-4ced-adf2-32f92fa781b1": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/a41c47ce-cbfa-49e3-8a21-5666f24ef96a",
            "firstName": "Steven",
            "lastName": "Doan",
            "fullName": "Steven Doan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house69.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-KY-69-lower-ocd-person-a41c47ce-cbfa-49e3-8a21-5666f24ef96a",
              "builtID": "ky-lower-69",
              "externalID": "ocd-person/a41c47ce-cbfa-49e3-8a21-5666f24ef96a",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.doan@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "stevedoanlaw",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=69"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-69-lower-ocd-person-a41c47ce-cbfa-49e3-8a21-5666f24ef96a": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/3d3b33bc-8852-48fe-90cf-42ce9551424c",
            "firstName": "William Lee",
            "lastName": "Lawrence",
            "fullName": "William Lawrence",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house70.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-KY-70-lower-ocd-person-3d3b33bc-8852-48fe-90cf-42ce9551424c",
              "builtID": "ky-lower-70",
              "externalID": "ocd-person/3d3b33bc-8852-48fe-90cf-42ce9551424c",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.lawrence@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=70"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-70-lower-ocd-person-3d3b33bc-8852-48fe-90cf-42ce9551424c": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/a110d6ba-f17f-4847-b0e9-327c82cd7122",
            "firstName": "Josh",
            "lastName": "Bray",
            "fullName": "Josh Bray",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house71.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-KY-71-lower-ocd-person-a110d6ba-f17f-4847-b0e9-327c82cd7122",
              "builtID": "ky-lower-71",
              "externalID": "ocd-person/a110d6ba-f17f-4847-b0e9-327c82cd7122",
              "geometry": null
            },
            "contactInfo": {
              "email": "josh.bray@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "BrayforKy",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=71"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-71-lower-ocd-person-a110d6ba-f17f-4847-b0e9-327c82cd7122": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/1b8bc567-b895-4fae-8b3f-7a7160ca5138",
            "firstName": "Matthew",
            "lastName": "Koch",
            "fullName": "Matt Koch",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house72.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-KY-72-lower-ocd-person-1b8bc567-b895-4fae-8b3f-7a7160ca5138",
              "builtID": "ky-lower-72",
              "externalID": "ocd-person/1b8bc567-b895-4fae-8b3f-7a7160ca5138",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.koch@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=72"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-72-lower-ocd-person-1b8bc567-b895-4fae-8b3f-7a7160ca5138": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/718ec3d2-325d-4ddf-b72b-728cea857222",
            "firstName": "Ryan",
            "lastName": "Dotson",
            "fullName": "Ryan Dotson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house73.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-KY-73-lower-ocd-person-718ec3d2-325d-4ddf-b72b-728cea857222",
              "builtID": "ky-lower-73",
              "externalID": "ocd-person/718ec3d2-325d-4ddf-b72b-728cea857222",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryan.dotson@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=73"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-73-lower-ocd-person-718ec3d2-325d-4ddf-b72b-728cea857222": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/ebd5f90d-e3cc-45ef-97ff-d51ff1685bcc",
            "firstName": "David",
            "lastName": "Hale",
            "fullName": "David Hale",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house74.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-KY-74-lower-ocd-person-ebd5f90d-e3cc-45ef-97ff-d51ff1685bcc",
              "builtID": "ky-lower-74",
              "externalID": "ocd-person/ebd5f90d-e3cc-45ef-97ff-d51ff1685bcc",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.hale@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "11 Hales Lane, Wellington, KY 40387",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=74"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-74-lower-ocd-person-ebd5f90d-e3cc-45ef-97ff-d51ff1685bcc": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/8048f367-9d46-4be5-9cb8-7a249d06cb13",
            "firstName": "Lindsey",
            "lastName": "Burke",
            "fullName": "Lindsey Burke",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house75.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-KY-75-lower-ocd-person-8048f367-9d46-4be5-9cb8-7a249d06cb13",
              "builtID": "ky-lower-75",
              "externalID": "ocd-person/8048f367-9d46-4be5-9cb8-7a249d06cb13",
              "geometry": null
            },
            "contactInfo": {
              "email": "lindsey.burke@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=75"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-75-lower-ocd-person-8048f367-9d46-4be5-9cb8-7a249d06cb13": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/d9335cfd-1606-4b1a-acd8-422f6d7c9e27",
            "firstName": "Ruth Ann",
            "lastName": "Palumbo",
            "fullName": "Ruth Ann Palumbo",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house76.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-KY-76-lower-ocd-person-d9335cfd-1606-4b1a-acd8-422f6d7c9e27",
              "builtID": "ky-lower-76",
              "externalID": "ocd-person/d9335cfd-1606-4b1a-acd8-422f6d7c9e27",
              "geometry": null
            },
            "contactInfo": {
              "email": "ruthann.palumbo@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "10 Deepwood Drive, Lexington, KY 40505",
              "phone1": null,
              "phone2": "859-299-2597",
              "fax1": null,
              "fax2": null,
              "twitter": "ruthannpalumbo",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=76"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-76-lower-ocd-person-d9335cfd-1606-4b1a-acd8-422f6d7c9e27": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/bb3e5fee-3bda-4a5d-95ee-dc31ff9defed",
            "firstName": "George",
            "lastName": "Brown",
            "fullName": "George Brown",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house77.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-KY-77-lower-ocd-person-bb3e5fee-3bda-4a5d-95ee-dc31ff9defed",
              "builtID": "ky-lower-77",
              "externalID": "ocd-person/bb3e5fee-3bda-4a5d-95ee-dc31ff9defed",
              "geometry": null
            },
            "contactInfo": {
              "email": "george.brown@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "424 E. 4th St., Lexington, KY 40508",
              "phone1": null,
              "phone2": "859-312-7513",
              "fax1": null,
              "fax2": null,
              "twitter": "GeorgeBrownky77",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=77"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-77-lower-ocd-person-bb3e5fee-3bda-4a5d-95ee-dc31ff9defed": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/b1a31b02-5d89-42be-97d4-7d08d99161d8",
            "firstName": "Mark",
            "lastName": "Hart",
            "fullName": "Mark Hart",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house78.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-KY-78-lower-ocd-person-b1a31b02-5d89-42be-97d4-7d08d99161d8",
              "builtID": "ky-lower-78",
              "externalID": "ocd-person/b1a31b02-5d89-42be-97d4-7d08d99161d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.hart@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "202 W. 4th St., Falmouth, KY 41040",
              "phone1": null,
              "phone2": "859-654-4278",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=78"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-78-lower-ocd-person-b1a31b02-5d89-42be-97d4-7d08d99161d8": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/51dc7033-7377-44d4-a5d8-7bd36b371896",
            "firstName": "Chad R.",
            "lastName": "Aull",
            "fullName": "Chad Aull",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house79.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-KY-79-lower-ocd-person-51dc7033-7377-44d4-a5d8-7bd36b371896",
              "builtID": "ky-lower-79",
              "externalID": "ocd-person/51dc7033-7377-44d4-a5d8-7bd36b371896",
              "geometry": null
            },
            "contactInfo": {
              "email": "chad.aull@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "chadaull",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=79"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-79-lower-ocd-person-51dc7033-7377-44d4-a5d8-7bd36b371896": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/3643503c-1da8-4a57-90f1-54f063b1d25e",
            "firstName": "David",
            "lastName": "Meade",
            "fullName": "David Meade",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house80.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-KY-80-lower-ocd-person-3643503c-1da8-4a57-90f1-54f063b1d25e",
              "builtID": "ky-lower-80",
              "externalID": "ocd-person/3643503c-1da8-4a57-90f1-54f063b1d25e",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.meade@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 121, Stanford, KY 40484",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "DavidMeadeKY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=80"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-80-lower-ocd-person-3643503c-1da8-4a57-90f1-54f063b1d25e": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/96ab59e2-44c2-4c3d-ac8a-1ee24acc5b55",
            "firstName": "Deanna",
            "lastName": "Frazier Gordon",
            "fullName": "Deanna Frazier Gordon",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house81.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-KY-81-lower-ocd-person-96ab59e2-44c2-4c3d-ac8a-1ee24acc5b55",
              "builtID": "ky-lower-81",
              "externalID": "ocd-person/96ab59e2-44c2-4c3d-ac8a-1ee24acc5b55",
              "geometry": null
            },
            "contactInfo": {
              "email": "deanna.gordon@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "deanna_ky",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=81"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-81-lower-ocd-person-96ab59e2-44c2-4c3d-ac8a-1ee24acc5b55": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/716d7b2c-0576-4bf8-b5a9-b6fa2c1f8024",
            "firstName": "Nick",
            "lastName": "Wilson",
            "fullName": "Nick Wilson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house82.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-KY-82-lower-ocd-person-716d7b2c-0576-4bf8-b5a9-b6fa2c1f8024",
              "builtID": "ky-lower-82",
              "externalID": "ocd-person/716d7b2c-0576-4bf8-b5a9-b6fa2c1f8024",
              "geometry": null
            },
            "contactInfo": {
              "email": "nick.wilson@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 573, Williamsburg, KY 40769",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=82"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-82-lower-ocd-person-716d7b2c-0576-4bf8-b5a9-b6fa2c1f8024": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/ff91cf3b-77a1-47b0-81b3-1c18f006349a",
            "firstName": "Joshua",
            "lastName": "Branscum",
            "fullName": "Josh Branscum",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house83.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-KY-83-lower-ocd-person-ff91cf3b-77a1-47b0-81b3-1c18f006349a",
              "builtID": "ky-lower-83",
              "externalID": "ocd-person/ff91cf3b-77a1-47b0-81b3-1c18f006349a",
              "geometry": null
            },
            "contactInfo": {
              "email": "josh.branscum@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "BranscumforKY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=83"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-83-lower-ocd-person-ff91cf3b-77a1-47b0-81b3-1c18f006349a": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/6843e90b-a30a-4dbf-885c-7321ccc8f73f",
            "firstName": "Chris",
            "lastName": "Fugate",
            "fullName": "Chris Fugate",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house84.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-KY-84-lower-ocd-person-6843e90b-a30a-4dbf-885c-7321ccc8f73f",
              "builtID": "ky-lower-84",
              "externalID": "ocd-person/6843e90b-a30a-4dbf-885c-7321ccc8f73f",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.fugate@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 202, Chavies, KY 41727",
              "phone1": null,
              "phone2": "606-233-5660",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=84"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-84-lower-ocd-person-6843e90b-a30a-4dbf-885c-7321ccc8f73f": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/2eab3a78-154d-43cd-8278-73ad2e166b08",
            "firstName": "Shane",
            "lastName": "Baker",
            "fullName": "Shane Baker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house85.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-KY-85-lower-ocd-person-2eab3a78-154d-43cd-8278-73ad2e166b08",
              "builtID": "ky-lower-85",
              "externalID": "ocd-person/2eab3a78-154d-43cd-8278-73ad2e166b08",
              "geometry": null
            },
            "contactInfo": {
              "email": "shane.baker@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=85"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-85-lower-ocd-person-2eab3a78-154d-43cd-8278-73ad2e166b08": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/5764151d-bf80-422b-bc46-93ac2be910cc",
            "firstName": "Tom O'Dell",
            "lastName": "Smith",
            "fullName": "Tom Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house86.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-KY-86-lower-ocd-person-5764151d-bf80-422b-bc46-93ac2be910cc",
              "builtID": "ky-lower-86",
              "externalID": "ocd-person/5764151d-bf80-422b-bc46-93ac2be910cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.odellsmith@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=86"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-86-lower-ocd-person-5764151d-bf80-422b-bc46-93ac2be910cc": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/e7f7a316-4eb0-4be9-adf9-e7067b20dcfc",
            "firstName": "Adam",
            "lastName": "Bowling",
            "fullName": "Adam Bowling",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house87.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-KY-87-lower-ocd-person-e7f7a316-4eb0-4be9-adf9-e7067b20dcfc",
              "builtID": "ky-lower-87",
              "externalID": "ocd-person/e7f7a316-4eb0-4be9-adf9-e7067b20dcfc",
              "geometry": null
            },
            "contactInfo": {
              "email": "adam.bowling@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 2928, Middlesboro, KY 40965",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "ABowlingKY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=87"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-87-lower-ocd-person-e7f7a316-4eb0-4be9-adf9-e7067b20dcfc": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/21df94f4-75d3-45c3-a9bf-224a8278675b",
            "firstName": "Cherlynn",
            "lastName": "Stevenson",
            "fullName": "Cherlynn Stevenson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house88.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-KY-88-lower-ocd-person-21df94f4-75d3-45c3-a9bf-224a8278675b",
              "builtID": "ky-lower-88",
              "externalID": "ocd-person/21df94f4-75d3-45c3-a9bf-224a8278675b",
              "geometry": null
            },
            "contactInfo": {
              "email": "cherlynn.stevenson@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1890 Star Shoot Parkway Suite 170, #319, Lexington, KY 40509",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "CherlynnForKy",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=88"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-88-lower-ocd-person-21df94f4-75d3-45c3-a9bf-224a8278675b": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/b2834b48-3186-4fbd-aa3e-daa1d38fa308",
            "firstName": "Timothy",
            "lastName": "Truett",
            "fullName": "Timmy Truett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house89.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-KY-89-lower-ocd-person-b2834b48-3186-4fbd-aa3e-daa1d38fa308",
              "builtID": "ky-lower-89",
              "externalID": "ocd-person/b2834b48-3186-4fbd-aa3e-daa1d38fa308",
              "geometry": null
            },
            "contactInfo": {
              "email": "timmy.truett@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4172 Highway 2003, McKee, KY 40447",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=89"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-89-lower-ocd-person-b2834b48-3186-4fbd-aa3e-daa1d38fa308": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/60ef609e-8aed-4698-a626-664f536a6122",
            "firstName": "Derek",
            "lastName": "Lewis",
            "fullName": "Derek Lewis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house90.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-KY-90-lower-ocd-person-60ef609e-8aed-4698-a626-664f536a6122",
              "builtID": "ky-lower-90",
              "externalID": "ocd-person/60ef609e-8aed-4698-a626-664f536a6122",
              "geometry": null
            },
            "contactInfo": {
              "email": "derek.lewis@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-90-lower-ocd-person-60ef609e-8aed-4698-a626-664f536a6122": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/91eec267-436a-49a5-9d41-b6b2632d0a51",
            "firstName": "Billy",
            "lastName": "Wesley",
            "fullName": "Bill Wesley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house91.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-KY-91-lower-ocd-person-91eec267-436a-49a5-9d41-b6b2632d0a51",
              "builtID": "ky-lower-91",
              "externalID": "ocd-person/91eec267-436a-49a5-9d41-b6b2632d0a51",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.welsey@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=91"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-91-lower-ocd-person-91eec267-436a-49a5-9d41-b6b2632d0a51": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/7cda4251-f26b-4bab-bf88-ed61b947018e",
            "firstName": "John",
            "lastName": "Blanton",
            "fullName": "John Blanton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house92.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-KY-92-lower-ocd-person-7cda4251-f26b-4bab-bf88-ed61b947018e",
              "builtID": "ky-lower-92",
              "externalID": "ocd-person/7cda4251-f26b-4bab-bf88-ed61b947018e",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.blanton@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repblanton92",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=92"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-92-lower-ocd-person-7cda4251-f26b-4bab-bf88-ed61b947018e": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/943724d5-5e02-4d0f-bd79-a74e30f18d1a",
            "firstName": "Adrielle",
            "lastName": "Camuel",
            "fullName": "Adrielle Camuel",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house93.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-KY-93-lower-ocd-person-943724d5-5e02-4d0f-bd79-a74e30f18d1a",
              "builtID": "ky-lower-93",
              "externalID": "ocd-person/943724d5-5e02-4d0f-bd79-a74e30f18d1a",
              "geometry": null
            },
            "contactInfo": {
              "email": "adrielle.camuel@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "camuelforky",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=93"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-93-lower-ocd-person-943724d5-5e02-4d0f-bd79-a74e30f18d1a": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/728efe7a-788c-49bc-9859-469585e33c32",
            "firstName": "Jacob D.",
            "lastName": "Justice",
            "fullName": "Jacob Justice",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house94.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-KY-94-lower-ocd-person-728efe7a-788c-49bc-9859-469585e33c32",
              "builtID": "ky-lower-94",
              "externalID": "ocd-person/728efe7a-788c-49bc-9859-469585e33c32",
              "geometry": null
            },
            "contactInfo": {
              "email": "jacob.justice@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=94"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-94-lower-ocd-person-728efe7a-788c-49bc-9859-469585e33c32": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/6bc2a8c3-3370-4586-b0b7-a5028f336d12",
            "firstName": "Ashley Tackett",
            "lastName": "Laferty",
            "fullName": "Ashley Laferty",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house95.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-KY-95-lower-ocd-person-6bc2a8c3-3370-4586-b0b7-a5028f336d12",
              "builtID": "ky-lower-95",
              "externalID": "ocd-person/6bc2a8c3-3370-4586-b0b7-a5028f336d12",
              "geometry": null
            },
            "contactInfo": {
              "email": "ashley.tackettlaferty@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=95"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-95-lower-ocd-person-6bc2a8c3-3370-4586-b0b7-a5028f336d12": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/8ae56ea2-3f37-490b-a3dc-0dec9fe85c7c",
            "firstName": "Patrick C.",
            "lastName": "Flannery",
            "fullName": "Patrick Flannery",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house96.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-KY-96-lower-ocd-person-8ae56ea2-3f37-490b-a3dc-0dec9fe85c7c",
              "builtID": "ky-lower-96",
              "externalID": "ocd-person/8ae56ea2-3f37-490b-a3dc-0dec9fe85c7c",
              "geometry": null
            },
            "contactInfo": {
              "email": "patrick.flannery@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=96"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-96-lower-ocd-person-8ae56ea2-3f37-490b-a3dc-0dec9fe85c7c": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/22e056d2-728a-425a-a0a1-19e0e5cc59cb",
            "firstName": "Bobby W.",
            "lastName": "McCool",
            "fullName": "Bobby McCool",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house97.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-KY-97-lower-ocd-person-22e056d2-728a-425a-a0a1-19e0e5cc59cb",
              "builtID": "ky-lower-97",
              "externalID": "ocd-person/22e056d2-728a-425a-a0a1-19e0e5cc59cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "bobby.mccool@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=97"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-97-lower-ocd-person-22e056d2-728a-425a-a0a1-19e0e5cc59cb": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/f56d6e0d-20b2-4cf4-aa7a-fd1d5c591d60",
            "firstName": "Danny",
            "lastName": "Bentley",
            "fullName": "Danny Bentley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house98.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-KY-98-lower-ocd-person-f56d6e0d-20b2-4cf4-aa7a-fd1d5c591d60",
              "builtID": "ky-lower-98",
              "externalID": "ocd-person/f56d6e0d-20b2-4cf4-aa7a-fd1d5c591d60",
              "geometry": null
            },
            "contactInfo": {
              "email": "danny.bentley@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "drdanbentley",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=98"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-98-lower-ocd-person-f56d6e0d-20b2-4cf4-aa7a-fd1d5c591d60": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/29b248e1-4033-41f6-ba30-136f2aa55045",
            "firstName": "Richard",
            "lastName": "White",
            "fullName": "Richard White",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house99.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-KY-99-lower-ocd-person-29b248e1-4033-41f6-ba30-136f2aa55045",
              "builtID": "ky-lower-99",
              "externalID": "ocd-person/29b248e1-4033-41f6-ba30-136f2aa55045",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.white@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RichardWhiteKY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=99"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-99-lower-ocd-person-29b248e1-4033-41f6-ba30-136f2aa55045": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/65469c71-edc2-4c9f-87da-bcf4d3277591",
            "firstName": "Scott L.",
            "lastName": "Sharp",
            "fullName": "Scott Sharp",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/house100.jpg",
            "title": "KY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-KY-100-lower-ocd-person-65469c71-edc2-4c9f-87da-bcf4d3277591",
              "builtID": "ky-lower-100",
              "externalID": "ocd-person/65469c71-edc2-4c9f-87da-bcf4d3277591",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.sharp@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=100"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-KY-100-lower-ocd-person-65469c71-edc2-4c9f-87da-bcf4d3277591": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/a796965c-0c0f-43b2-975b-d9726b34acd8",
            "firstName": "Jason Glenn",
            "lastName": "Howell",
            "fullName": "Jason Howell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate101.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-KY-1-upper-ocd-person-a796965c-0c0f-43b2-975b-d9726b34acd8",
              "builtID": "ky-upper-1",
              "externalID": "ocd-person/a796965c-0c0f-43b2-975b-d9726b34acd8",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.howell@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "jasonghowell",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=101"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-1-upper-ocd-person-a796965c-0c0f-43b2-975b-d9726b34acd8": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/0ec7d376-4235-44e7-a6d7-d63962dff167",
            "firstName": "Danny",
            "lastName": "Carroll",
            "fullName": "Danny Carroll",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate102.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-KY-2-upper-ocd-person-0ec7d376-4235-44e7-a6d7-d63962dff167",
              "builtID": "ky-upper-2",
              "externalID": "ocd-person/0ec7d376-4235-44e7-a6d7-d63962dff167",
              "geometry": null
            },
            "contactInfo": {
              "email": "danny.carroll@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "dannycarrollky",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=102"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-2-upper-ocd-person-0ec7d376-4235-44e7-a6d7-d63962dff167": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/f81a2d15-a4d9-4a66-97bb-31304fcb7eab",
            "firstName": "Whitney H.",
            "lastName": "Westerfield",
            "fullName": "Whitney Westerfield",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate103.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-KY-3-upper-ocd-person-f81a2d15-a4d9-4a66-97bb-31304fcb7eab",
              "builtID": "ky-upper-3",
              "externalID": "ocd-person/f81a2d15-a4d9-4a66-97bb-31304fcb7eab",
              "geometry": null
            },
            "contactInfo": {
              "email": "whitney.westerfield@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "KyWhitney",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=103"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-3-upper-ocd-person-f81a2d15-a4d9-4a66-97bb-31304fcb7eab": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/366daab1-a877-49b9-86b8-e3c497143bf0",
            "firstName": "Robert",
            "lastName": "Mills",
            "fullName": "Robby Mills",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate104.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-KY-4-upper-ocd-person-366daab1-a877-49b9-86b8-e3c497143bf0",
              "builtID": "ky-upper-4",
              "externalID": "ocd-person/366daab1-a877-49b9-86b8-e3c497143bf0",
              "geometry": null
            },
            "contactInfo": {
              "email": "robby.mills@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "robbymillsforky",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=104"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-4-upper-ocd-person-366daab1-a877-49b9-86b8-e3c497143bf0": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/8915acb8-6089-451f-be08-c79b0be59c40",
            "firstName": "Stephen",
            "lastName": "Meredith",
            "fullName": "Stephen Meredith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate105.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-KY-5-upper-ocd-person-8915acb8-6089-451f-be08-c79b0be59c40",
              "builtID": "ky-upper-5",
              "externalID": "ocd-person/8915acb8-6089-451f-be08-c79b0be59c40",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephen.meredith@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1424 Byrtle Grove Road, Leitchfield, KY 42754",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=105"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-5-upper-ocd-person-8915acb8-6089-451f-be08-c79b0be59c40": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/db2324a8-1ae2-4e3a-81e5-ac83cb7bbb05",
            "firstName": "Lindsey Sublett",
            "lastName": "Tichenor",
            "fullName": "Lindsey Tichenor",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate106.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-KY-6-upper-ocd-person-db2324a8-1ae2-4e3a-81e5-ac83cb7bbb05",
              "builtID": "ky-upper-6",
              "externalID": "ocd-person/db2324a8-1ae2-4e3a-81e5-ac83cb7bbb05",
              "geometry": null
            },
            "contactInfo": {
              "email": "lindsey.tichenor@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 651, La Grange, KY 40031",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "tichenor4ky",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=106"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-6-upper-ocd-person-db2324a8-1ae2-4e3a-81e5-ac83cb7bbb05": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/dc7699cf-a38a-4669-8d33-915fc706b055",
            "firstName": "Adrienne",
            "lastName": "Southworth",
            "fullName": "Adrienne Southworth",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate107.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-KY-7-upper-ocd-person-dc7699cf-a38a-4669-8d33-915fc706b055",
              "builtID": "ky-upper-7",
              "externalID": "ocd-person/dc7699cf-a38a-4669-8d33-915fc706b055",
              "geometry": null
            },
            "contactInfo": {
              "email": "adrienne.southworth@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "senate7ky",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=107"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-7-upper-ocd-person-dc7699cf-a38a-4669-8d33-915fc706b055": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/2d6a7358-f685-4476-aa31-b92cf72b11ec",
            "firstName": "Gary M.",
            "lastName": "Boswell",
            "fullName": "Gary Boswell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate108.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-KY-8-upper-ocd-person-2d6a7358-f685-4476-aa31-b92cf72b11ec",
              "builtID": "ky-upper-8",
              "externalID": "ocd-person/2d6a7358-f685-4476-aa31-b92cf72b11ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "gary.boswell@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3130 Reid Road, Owensboro, KY 42303",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=108"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-8-upper-ocd-person-2d6a7358-f685-4476-aa31-b92cf72b11ec": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/5594a97f-7358-427e-af84-43150a21c97e",
            "firstName": "David P.",
            "lastName": "Givens",
            "fullName": "David Givens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate109.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-KY-9-upper-ocd-person-5594a97f-7358-427e-af84-43150a21c97e",
              "builtID": "ky-upper-9",
              "externalID": "ocd-person/5594a97f-7358-427e-af84-43150a21c97e",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.givens@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 12, Greensburg, KY 42743",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "kydavidgivens",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=109"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-9-upper-ocd-person-5594a97f-7358-427e-af84-43150a21c97e": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/b76a3218-b1bd-475c-a775-7a6cbefbc1f3",
            "firstName": "Matthew Darin",
            "lastName": "Deneen",
            "fullName": "Matt Deneen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate110.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-KY-10-upper-ocd-person-b76a3218-b1bd-475c-a775-7a6cbefbc1f3",
              "builtID": "ky-upper-10",
              "externalID": "ocd-person/b76a3218-b1bd-475c-a775-7a6cbefbc1f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.deneen@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "MatthewDeneen1",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=110"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-10-upper-ocd-person-b76a3218-b1bd-475c-a775-7a6cbefbc1f3": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/1bca305e-0f8d-4ce6-849a-322f7dbecb4b",
            "firstName": "John",
            "lastName": "Schickel",
            "fullName": "John Schickel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate111.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-KY-11-upper-ocd-person-1bca305e-0f8d-4ce6-849a-322f7dbecb4b",
              "builtID": "ky-upper-11",
              "externalID": "ocd-person/1bca305e-0f8d-4ce6-849a-322f7dbecb4b",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.schickel@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SenatorSchickel",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=111"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-11-upper-ocd-person-1bca305e-0f8d-4ce6-849a-322f7dbecb4b": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/7c03bd44-8541-49b0-9794-a9e606c292ce",
            "firstName": "Amanda Mays",
            "lastName": "Bledsoe",
            "fullName": "Amanda Bledsoe",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate112.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-KY-12-upper-ocd-person-7c03bd44-8541-49b0-9794-a9e606c292ce",
              "builtID": "ky-upper-12",
              "externalID": "ocd-person/7c03bd44-8541-49b0-9794-a9e606c292ce",
              "geometry": null
            },
            "contactInfo": {
              "email": "amanda.maysbledsoe@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 23112, Lexington, KY 40523",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "amandambledsoe",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=112"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-12-upper-ocd-person-7c03bd44-8541-49b0-9794-a9e606c292ce": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/42b259db-9ddb-4588-a463-b1a38c944c66",
            "firstName": "Reginald Leonard",
            "lastName": "Thomas",
            "fullName": "Reggie Thomas",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate113.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-KY-13-upper-ocd-person-42b259db-9ddb-4588-a463-b1a38c944c66",
              "builtID": "ky-upper-13",
              "externalID": "ocd-person/42b259db-9ddb-4588-a463-b1a38c944c66",
              "geometry": null
            },
            "contactInfo": {
              "email": "reginald.thomas@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "ReggieThomasKY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=113"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-13-upper-ocd-person-42b259db-9ddb-4588-a463-b1a38c944c66": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/4042b29b-c6bb-407e-856d-9197a03d55ff",
            "firstName": "Jimmy",
            "lastName": "Higdon",
            "fullName": "Jimmy Higdon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate114.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-KY-14-upper-ocd-person-4042b29b-c6bb-407e-856d-9197a03d55ff",
              "builtID": "ky-upper-14",
              "externalID": "ocd-person/4042b29b-c6bb-407e-856d-9197a03d55ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "jimmy.higdon@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "344 N. Spalding, Lebanon, KY 40033",
              "phone1": null,
              "phone2": "270-692-6945",
              "fax1": null,
              "fax2": null,
              "twitter": "SenatorJimmy",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=114"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-14-upper-ocd-person-4042b29b-c6bb-407e-856d-9197a03d55ff": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/34ed9881-46b8-41ba-b625-3d350140d2b9",
            "firstName": "Rick",
            "lastName": "Girdler",
            "fullName": "Rick Girdler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate115.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-KY-15-upper-ocd-person-34ed9881-46b8-41ba-b625-3d350140d2b9",
              "builtID": "ky-upper-15",
              "externalID": "ocd-person/34ed9881-46b8-41ba-b625-3d350140d2b9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.girdler@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=115"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-15-upper-ocd-person-34ed9881-46b8-41ba-b625-3d350140d2b9": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/32b5035e-6d1a-44b8-9f7a-4173a1cdb940",
            "firstName": "George Maxwell",
            "lastName": "Wise",
            "fullName": "Max Wise",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate116.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-KY-16-upper-ocd-person-32b5035e-6d1a-44b8-9f7a-4173a1cdb940",
              "builtID": "ky-upper-16",
              "externalID": "ocd-person/32b5035e-6d1a-44b8-9f7a-4173a1cdb940",
              "geometry": null
            },
            "contactInfo": {
              "email": "max.wise@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "maxwellwise",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=116"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-16-upper-ocd-person-32b5035e-6d1a-44b8-9f7a-4173a1cdb940": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/bb250cb3-f9ac-4d33-bdde-36c4e3bca9d4",
            "firstName": "Damon",
            "lastName": "Thayer",
            "fullName": "Damon Thayer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate117.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-KY-17-upper-ocd-person-bb250cb3-f9ac-4d33-bdde-36c4e3bca9d4",
              "builtID": "ky-upper-17",
              "externalID": "ocd-person/bb250cb3-f9ac-4d33-bdde-36c4e3bca9d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "damon.thayer@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "damon_thayer",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=117"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-17-upper-ocd-person-bb250cb3-f9ac-4d33-bdde-36c4e3bca9d4": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/42990da5-7020-4327-bbd8-496955b00040",
            "firstName": "Robin L.",
            "lastName": "Webb",
            "fullName": "Robin Webb",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate118.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-KY-18-upper-ocd-person-42990da5-7020-4327-bbd8-496955b00040",
              "builtID": "ky-upper-18",
              "externalID": "ocd-person/42990da5-7020-4327-bbd8-496955b00040",
              "geometry": null
            },
            "contactInfo": {
              "email": "robin.webb@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "102 S. Hord St., Grayson, KY 41143",
              "phone1": null,
              "phone2": "606-474-5380",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=118"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-18-upper-ocd-person-42990da5-7020-4327-bbd8-496955b00040": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/8dbecabb-ef15-4925-8e50-26331e738a05",
            "firstName": "Cassie Chambers",
            "lastName": "Armstrong",
            "fullName": "Cassie Armstrong",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate119.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-KY-19-upper-ocd-person-8dbecabb-ef15-4925-8e50-26331e738a05",
              "builtID": "ky-upper-19",
              "externalID": "ocd-person/8dbecabb-ef15-4925-8e50-26331e738a05",
              "geometry": null
            },
            "contactInfo": {
              "email": "cassie.armstrong@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "cassieforky",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=119"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-19-upper-ocd-person-8dbecabb-ef15-4925-8e50-26331e738a05": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/6aa07729-a16f-4465-a605-7afbbe982882",
            "firstName": "Edwin Gex",
            "lastName": "Williams",
            "fullName": "Gex Williams",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate120.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-KY-20-upper-ocd-person-6aa07729-a16f-4465-a605-7afbbe982882",
              "builtID": "ky-upper-20",
              "externalID": "ocd-person/6aa07729-a16f-4465-a605-7afbbe982882",
              "geometry": null
            },
            "contactInfo": {
              "email": "gex.williams@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=120"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-20-upper-ocd-person-6aa07729-a16f-4465-a605-7afbbe982882": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/257271f3-68b1-4481-94e5-f98300493413",
            "firstName": "Brandon J.",
            "lastName": "Storm",
            "fullName": "Brandon Storm",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate121.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-KY-21-upper-ocd-person-257271f3-68b1-4481-94e5-f98300493413",
              "builtID": "ky-upper-21",
              "externalID": "ocd-person/257271f3-68b1-4481-94e5-f98300493413",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandon.storm@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=121"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-21-upper-ocd-person-257271f3-68b1-4481-94e5-f98300493413": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/a7cd8577-c315-4bf0-b95f-3259ab7dfce7",
            "firstName": "Donald",
            "lastName": "Douglas",
            "fullName": "Don Douglas",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate122.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-KY-22-upper-ocd-person-a7cd8577-c315-4bf0-b95f-3259ab7dfce7",
              "builtID": "ky-upper-22",
              "externalID": "ocd-person/a7cd8577-c315-4bf0-b95f-3259ab7dfce7",
              "geometry": null
            },
            "contactInfo": {
              "email": "donald.douglas@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=122"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-22-upper-ocd-person-a7cd8577-c315-4bf0-b95f-3259ab7dfce7": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/b32a95c9-42ba-449b-8581-97b9cef73c6a",
            "firstName": "Christian",
            "lastName": "McDaniel",
            "fullName": "Chris McDaniel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate123.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-KY-23-upper-ocd-person-b32a95c9-42ba-449b-8581-97b9cef73c6a",
              "builtID": "ky-upper-23",
              "externalID": "ocd-person/b32a95c9-42ba-449b-8581-97b9cef73c6a",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.mcdaniel@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 15231, Latonia, KY 41015",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "kychrismcdaniel",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=123"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-23-upper-ocd-person-b32a95c9-42ba-449b-8581-97b9cef73c6a": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/b7112818-3357-413d-acae-a46bf3442b1d",
            "firstName": "Shelley Funke",
            "lastName": "Frommeyer",
            "fullName": "Shelley Frommeyer",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate124.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-KY-24-upper-ocd-person-b7112818-3357-413d-acae-a46bf3442b1d",
              "builtID": "ky-upper-24",
              "externalID": "ocd-person/b7112818-3357-413d-acae-a46bf3442b1d",
              "geometry": null
            },
            "contactInfo": {
              "email": "shelley.funkefrommeyer@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 511, Alexandria, KY 41001",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "FunkeShelley",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=124"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-24-upper-ocd-person-b7112818-3357-413d-acae-a46bf3442b1d": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/8659c7e3-ec01-4c40-b7ee-d21f37facff5",
            "firstName": "Bertram Robert",
            "lastName": "Stivers",
            "fullName": "Robert Stivers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate125.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-KY-25-upper-ocd-person-8659c7e3-ec01-4c40-b7ee-d21f37facff5",
              "builtID": "ky-upper-25",
              "externalID": "ocd-person/8659c7e3-ec01-4c40-b7ee-d21f37facff5",
              "geometry": null
            },
            "contactInfo": {
              "email": "robert.stivers@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "207 Main St., Manchester, KY 40962",
              "phone1": null,
              "phone2": "606-598-8575",
              "fax1": null,
              "fax2": "606-598-2357",
              "twitter": "kysenatepres",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=125"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-25-upper-ocd-person-8659c7e3-ec01-4c40-b7ee-d21f37facff5": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/b45a3156-f662-4884-9c13-b33a4c2d9105",
            "firstName": "Karen",
            "lastName": "Berg",
            "fullName": "Karen Berg",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate126.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-KY-26-upper-ocd-person-b45a3156-f662-4884-9c13-b33a4c2d9105",
              "builtID": "ky-upper-26",
              "externalID": "ocd-person/b45a3156-f662-4884-9c13-b33a4c2d9105",
              "geometry": null
            },
            "contactInfo": {
              "email": "karen.berg@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=126"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-26-upper-ocd-person-b45a3156-f662-4884-9c13-b33a4c2d9105": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/1f3bbab4-2be0-496c-9c3b-b8f340f6b404",
            "firstName": "Stephen",
            "lastName": "West",
            "fullName": "Steve West",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate127.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-KY-27-upper-ocd-person-1f3bbab4-2be0-496c-9c3b-b8f340f6b404",
              "builtID": "ky-upper-27",
              "externalID": "ocd-person/1f3bbab4-2be0-496c-9c3b-b8f340f6b404",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.west@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SteveWestKY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=127"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-27-upper-ocd-person-1f3bbab4-2be0-496c-9c3b-b8f340f6b404": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/139d004c-7a19-4153-a58b-919a1b70180e",
            "firstName": "Greg",
            "lastName": "Elkins",
            "fullName": "Greg Elkins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate128.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-KY-28-upper-ocd-person-139d004c-7a19-4153-a58b-919a1b70180e",
              "builtID": "ky-upper-28",
              "externalID": "ocd-person/139d004c-7a19-4153-a58b-919a1b70180e",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.elkins@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=128"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-28-upper-ocd-person-139d004c-7a19-4153-a58b-919a1b70180e": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/70b716e7-d7d4-4a24-93eb-4b06378bb7fb",
            "firstName": "Johnnie L.",
            "lastName": "Turner",
            "fullName": "Johnnie Turner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate129.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-KY-29-upper-ocd-person-70b716e7-d7d4-4a24-93eb-4b06378bb7fb",
              "builtID": "ky-upper-29",
              "externalID": "ocd-person/70b716e7-d7d4-4a24-93eb-4b06378bb7fb",
              "geometry": null
            },
            "contactInfo": {
              "email": "johnnie.turner@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 351, Harlan, KY 40831",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=129"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-29-upper-ocd-person-70b716e7-d7d4-4a24-93eb-4b06378bb7fb": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/209d5334-d4a2-45f3-b910-0cd52f96dd4e",
            "firstName": "Brandon D.",
            "lastName": "Smith",
            "fullName": "Brandon Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate130.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-KY-30-upper-ocd-person-209d5334-d4a2-45f3-b910-0cd52f96dd4e",
              "builtID": "ky-upper-30",
              "externalID": "ocd-person/209d5334-d4a2-45f3-b910-0cd52f96dd4e",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandon.smith@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 846, Hazard, KY 41702",
              "phone1": null,
              "phone2": "606-436-4526",
              "fax1": null,
              "fax2": "606-436-4526",
              "twitter": "Smithkysenate",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=130"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-30-upper-ocd-person-209d5334-d4a2-45f3-b910-0cd52f96dd4e": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/18063ded-67cb-46a9-b558-14d5da92b860",
            "firstName": "Charles Phillip",
            "lastName": "Wheeler",
            "fullName": "Phillip Wheeler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate131.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-KY-31-upper-ocd-person-18063ded-67cb-46a9-b558-14d5da92b860",
              "builtID": "ky-upper-31",
              "externalID": "ocd-person/18063ded-67cb-46a9-b558-14d5da92b860",
              "geometry": null
            },
            "contactInfo": {
              "email": "phillip.wheeler@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=131"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-31-upper-ocd-person-18063ded-67cb-46a9-b558-14d5da92b860": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/64af8584-1675-47fd-acfb-1cec59dfdda5",
            "firstName": "Mike",
            "lastName": "Wilson",
            "fullName": "Mike Wilson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate132.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-KY-32-upper-ocd-person-64af8584-1675-47fd-acfb-1cec59dfdda5",
              "builtID": "ky-upper-32",
              "externalID": "ocd-person/64af8584-1675-47fd-acfb-1cec59dfdda5",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.wilson@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "mikewilson2018",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=132"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-32-upper-ocd-person-64af8584-1675-47fd-acfb-1cec59dfdda5": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/0d92a09c-ce23-46cd-9582-b7d1b08d76dd",
            "firstName": "Gerald A.",
            "lastName": "Neal",
            "fullName": "Gerald Neal",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate133.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-KY-33-upper-ocd-person-0d92a09c-ce23-46cd-9582-b7d1b08d76dd",
              "builtID": "ky-upper-33",
              "externalID": "ocd-person/0d92a09c-ce23-46cd-9582-b7d1b08d76dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "gerald.neal@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "462 S. 4th St. Meidinger Tower, Suite 1250, Louisville, KY 40202",
              "phone1": null,
              "phone2": "502-776-1222",
              "fax1": null,
              "fax2": "502-584-1119",
              "twitter": "GeraldNeal33",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=133"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-33-upper-ocd-person-0d92a09c-ce23-46cd-9582-b7d1b08d76dd": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/b2299f51-c122-46ac-9937-3a6a7b682bb8",
            "firstName": "Jared",
            "lastName": "Carpenter",
            "fullName": "Jared Carpenter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate134.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-KY-34-upper-ocd-person-b2299f51-c122-46ac-9937-3a6a7b682bb8",
              "builtID": "ky-upper-34",
              "externalID": "ocd-person/b2299f51-c122-46ac-9937-3a6a7b682bb8",
              "geometry": null
            },
            "contactInfo": {
              "email": "jared.carpenter@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 100, Berea, KY 40403",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "JCarpenterKY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=134"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-34-upper-ocd-person-b2299f51-c122-46ac-9937-3a6a7b682bb8": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/3b8491a7-53b7-4b69-aac5-110a09c930ad",
            "firstName": "Denise",
            "lastName": "Harper Angel",
            "fullName": "Denise Harper Angel",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate135.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-KY-35-upper-ocd-person-3b8491a7-53b7-4b69-aac5-110a09c930ad",
              "builtID": "ky-upper-35",
              "externalID": "ocd-person/3b8491a7-53b7-4b69-aac5-110a09c930ad",
              "geometry": null
            },
            "contactInfo": {
              "email": "denise.harperangel@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2521 Ransdell Ave., Louisville, KY 40204",
              "phone1": null,
              "phone2": "502-452-9130",
              "fax1": null,
              "fax2": null,
              "twitter": "KYHarperAngel",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=135"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-35-upper-ocd-person-3b8491a7-53b7-4b69-aac5-110a09c930ad": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/38ae421f-f4fa-40f6-b025-a75bc6b42f35",
            "firstName": "Julie Raque",
            "lastName": "Adams",
            "fullName": "Julie Adams",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate136.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-KY-36-upper-ocd-person-38ae421f-f4fa-40f6-b025-a75bc6b42f35",
              "builtID": "ky-upper-36",
              "externalID": "ocd-person/38ae421f-f4fa-40f6-b025-a75bc6b42f35",
              "geometry": null
            },
            "contactInfo": {
              "email": "julie.adams@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "jrajra",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=136"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-36-upper-ocd-person-38ae421f-f4fa-40f6-b025-a75bc6b42f35": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/77ce8a4c-90d4-4e54-b1da-ef6796aa71b5",
            "firstName": "David",
            "lastName": "Yates",
            "fullName": "David Yates",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate137.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-KY-37-upper-ocd-person-77ce8a4c-90d4-4e54-b1da-ef6796aa71b5",
              "builtID": "ky-upper-37",
              "externalID": "ocd-person/77ce8a4c-90d4-4e54-b1da-ef6796aa71b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.yates@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "DavidYates37",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=137"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-37-upper-ocd-person-77ce8a4c-90d4-4e54-b1da-ef6796aa71b5": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/230c230d-7313-4a94-8eb3-60f57a86543c",
            "firstName": "Michael J.",
            "lastName": "Nemes",
            "fullName": "Mike Nemes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate138.jpg",
            "title": "KY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "KY",
              "stateFull": "Kentucky",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-KY-38-upper-ocd-person-230c230d-7313-4a94-8eb3-60f57a86543c",
              "builtID": "ky-upper-38",
              "externalID": "ocd-person/230c230d-7313-4a94-8eb3-60f57a86543c",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.nemes@lrc.ky.gov",
              "contactForm": null,
              "address1": null,
              "address2": "209 Sandy Drive, Shepherdsville, KY 40165",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.lrc.ky.gov/legislator/H038.htm",
                "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=138"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-KY-38-upper-ocd-person-230c230d-7313-4a94-8eb3-60f57a86543c": 0
        }
      }
    }
  },
  "LA": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "LA",
          "stateFull": "Louisiana",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "LA",
            "stateFull": "Louisiana",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-LA---",
            "builtID": "la--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-LA---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "C001075",
          "in_office": true,
          "firstName": "Bill",
          "lastName": "Cassidy",
          "middleName": null,
          "fullName": "Bill Cassidy",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/C001075.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "LA",
            "stateFull": "Louisiana",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-LA---C001075",
            "builtID": "la--",
            "externalID": "C001075",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.cassidy.senate.gov/contact",
            "address1": "520 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-5824",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": null,
            "youtube": "SenatorBillCassidy",
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.cassidy.senate.gov"],
            "rss_url": null
          },
          "title": "LA Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/C001075.json",
          "govtrack_id": "412269",
          "cspan_id": "1030546",
          "votesmart_id": "69494",
          "icpsr_id": "20919",
          "crp_id": "N00030245",
          "google_entity_id": "/m/0286t7r",
          "state_rank": "senior",
          "lis_id": "S373",
          "suffix": null,
          "date_of_birth": "1957-09-28",
          "leadership_role": null,
          "fec_candidate_id": "H8LA00017",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "9",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 25,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 4.51,
          "votes_with_party_pct": 87.64,
          "votes_against_party_pct": 12.36,
          "ocd_id": "ocd-division/country:us/state:la"
        },
        {
          "API_ID": "K000393",
          "in_office": true,
          "firstName": "John",
          "lastName": "Kennedy",
          "middleName": null,
          "fullName": "John Kennedy",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/K000393.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "LA",
            "stateFull": "Louisiana",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-LA---K000393",
            "builtID": "la--",
            "externalID": "K000393",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenJohnKennedy",
            "youtube": null,
            "instagram": null,
            "facebook": "JohnKennedyLouisiana",
            "urls": ["https://www.kennedy.senate.gov/public"],
            "rss_url": "https://www.kennedy.senate.gov/public/?a=RSS.Feed"
          },
          "title": "LA Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/K000393.json",
          "govtrack_id": "412679",
          "cspan_id": "1011723",
          "votesmart_id": "35496",
          "icpsr_id": "41703",
          "crp_id": "N00026823",
          "google_entity_id": "/m/0fl2sv",
          "state_rank": "junior",
          "lis_id": "S389",
          "suffix": null,
          "date_of_birth": "1951-11-21",
          "leadership_role": null,
          "fec_candidate_id": "S4LA00065",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "3",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 18,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:25 -0400",
          "missed_votes_pct": 3.25,
          "votes_with_party_pct": 85.25,
          "votes_against_party_pct": 14.75,
          "ocd_id": "ocd-division/country:us/state:la"
        }
      ],
      "hash": { "SENATE-LA---C001075": 0, "SENATE-LA---K000393": 1 }
    },
    "HOUSE": {
      "02": {
        "members": [
          {
            "API_ID": "C001125",
            "in_office": true,
            "firstName": "Troy",
            "lastName": "Carter",
            "middleName": null,
            "fullName": "Troy A. Carter",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001125.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-LA-02--C001125",
              "builtID": "la--02",
              "externalID": "C001125",
              "geometry": null,
              "geoid": "2202"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "442 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-6636",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepTroyCarter",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://troycarter.house.gov"],
              "rss_url": null
            },
            "title": "LA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001125.json",
            "govtrack_id": "456860",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00025766",
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1963-10-26",
            "leadership_role": null,
            "fec_candidate_id": "H2LA02149",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 23,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.18,
            "votes_with_party_pct": 96.68,
            "votes_against_party_pct": 1.37,
            "ocd_id": "ocd-division/country:us/state:la/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-LA-02--C001125": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "G000577",
            "in_office": true,
            "firstName": "Garret",
            "lastName": "Graves",
            "middleName": null,
            "fullName": "Garret Graves",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000577.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-LA-06--G000577",
              "builtID": "la--06",
              "externalID": "G000577",
              "geometry": null,
              "geoid": "2206"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2402 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3901",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGarretGraves",
              "youtube": null,
              "instagram": null,
              "facebook": "CongressmanGarretGraves",
              "urls": ["https://garretgraves.house.gov"],
              "rss_url": "https://garretgraves.house.gov/rss.xml"
            },
            "title": "LA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000577.json",
            "govtrack_id": "412631",
            "cspan_id": "9274609",
            "votesmart_id": "155424",
            "icpsr_id": "21523",
            "crp_id": "N00036135",
            "google_entity_id": "/m/012bxlbr",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1972-01-31",
            "leadership_role": null,
            "fec_candidate_id": "H4LA06153",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 15,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.42,
            "votes_with_party_pct": 91.36,
            "votes_against_party_pct": 6.77,
            "ocd_id": "ocd-division/country:us/state:la/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-LA-06--G000577": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "H001077",
            "in_office": true,
            "firstName": "Clay",
            "lastName": "Higgins",
            "middleName": null,
            "fullName": "Clay Higgins",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/H001077.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-LA-03--H001077",
              "builtID": "la--03",
              "externalID": "H001077",
              "geometry": null,
              "geoid": "2203"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "572 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2031",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepClayHiggins",
              "youtube": null,
              "instagram": null,
              "facebook": "CongressmanClayHiggins",
              "urls": ["https://clayhiggins.house.gov"],
              "rss_url": "https://clayhiggins.house.gov/rss.xml"
            },
            "title": "LA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001077.json",
            "govtrack_id": "412705",
            "cspan_id": "106094",
            "votesmart_id": "174484",
            "icpsr_id": "21724",
            "crp_id": "N00039953",
            "google_entity_id": "/g/11c1s3lp76",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1961-08-24",
            "leadership_role": null,
            "fec_candidate_id": "H6LA03148",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 19,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.8,
            "votes_with_party_pct": 88.02,
            "votes_against_party_pct": 10.02,
            "ocd_id": "ocd-division/country:us/state:la/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-LA-03--H001077": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "J000299",
            "in_office": true,
            "firstName": "Mike",
            "lastName": "Johnson",
            "middleName": null,
            "fullName": "Mike Johnson",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/J000299.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-LA-04--J000299",
              "builtID": "la--04",
              "externalID": "J000299",
              "geometry": null,
              "geoid": "2204"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "568 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2777",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMikeJohnson",
              "youtube": null,
              "instagram": null,
              "facebook": "RepMikeJohnson",
              "urls": ["https://mikejohnson.house.gov"],
              "rss_url": null
            },
            "title": "LA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/J000299.json",
            "govtrack_id": "412706",
            "cspan_id": "106095",
            "votesmart_id": "156097",
            "icpsr_id": "21727",
            "crp_id": "N00039106",
            "google_entity_id": "/m/0131jn2m",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1972-01-30",
            "leadership_role": "House Republican Conference Vice Chair",
            "fec_candidate_id": "H6LA04138",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 962,
            "missed_votes": 194,
            "total_present": 0,
            "last_updated": "2024-06-28 17:03:36 -0400",
            "missed_votes_pct": 20.17,
            "votes_with_party_pct": 92.88,
            "votes_against_party_pct": 4.49,
            "ocd_id": "ocd-division/country:us/state:la/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-LA-04--J000299": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "L000595",
            "in_office": true,
            "firstName": "Julia",
            "lastName": "Letlow",
            "middleName": null,
            "fullName": "Julia Letlow",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/L000595.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-LA-05--L000595",
              "builtID": "la--05",
              "externalID": "L000595",
              "geometry": null,
              "geoid": "2205"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "142 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-8490",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repjulialetlow",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://letlow.house.gov"],
              "rss_url": null
            },
            "title": "LA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000595.json",
            "govtrack_id": "456859",
            "cspan_id": null,
            "votesmart_id": "195745",
            "icpsr_id": null,
            "crp_id": "N00047972",
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1981-03-16",
            "leadership_role": null,
            "fec_candidate_id": "H2LA05126",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 11,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.04,
            "votes_with_party_pct": 88.77,
            "votes_against_party_pct": 9.28,
            "ocd_id": "ocd-division/country:us/state:la/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-LA-05--L000595": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "S001176",
            "in_office": true,
            "firstName": "Steve",
            "lastName": "Scalise",
            "middleName": null,
            "fullName": "Steve Scalise",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S001176.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-LA-01--S001176",
              "builtID": "la--01",
              "externalID": "S001176",
              "geometry": null,
              "geoid": "2201"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2049 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3015",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SteveScalise",
              "youtube": "RepSteveScalise",
              "instagram": null,
              "facebook": "RepSteveScalise",
              "urls": ["https://scalise.house.gov"],
              "rss_url": "https://scalise.house.gov/rss.xml"
            },
            "title": "LA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001176.json",
            "govtrack_id": "412261",
            "cspan_id": "1015311",
            "votesmart_id": "9026",
            "icpsr_id": "20759",
            "crp_id": "N00009660",
            "google_entity_id": "/m/0br34c",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1965-10-06",
            "leadership_role": "House Minority Whip",
            "fec_candidate_id": "H0LA01087",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "18",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 116,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 11,
            "votes_with_party_pct": 94.57,
            "votes_against_party_pct": 3.37,
            "ocd_id": "ocd-division/country:us/state:la/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-LA-01--S001176": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/dc59ede6-164c-4fd4-adc1-7e080309776b",
            "firstName": "Danny",
            "lastName": "McCormick",
            "fullName": "Danny McCormick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep1.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-LA-1-lower-ocd-person-dc59ede6-164c-4fd4-adc1-7e080309776b",
              "builtID": "la-lower-1",
              "externalID": "ocd-person/dc59ede6-164c-4fd4-adc1-7e080309776b",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse001@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 160, Oil City, LA 71061",
              "phone1": null,
              "phone2": "318-995-8040",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=1",
                "https://house.louisiana.gov/H_Reps/members?ID=1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-1-lower-ocd-person-dc59ede6-164c-4fd4-adc1-7e080309776b": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/9c50a5a9-a217-4644-b29d-68d498d9e16d",
            "firstName": "Steven P.",
            "lastName": "Jackson",
            "fullName": "Steven Jackson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep2.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-LA-2-lower-ocd-person-9c50a5a9-a217-4644-b29d-68d498d9e16d",
              "builtID": "la-lower-2",
              "externalID": "ocd-person/9c50a5a9-a217-4644-b29d-68d498d9e16d",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse002@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "101 Milam St. Suite 100, Shreveport, LA 71101",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=2"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-2-lower-ocd-person-9c50a5a9-a217-4644-b29d-68d498d9e16d": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/a838e3b6-52ea-4ad0-a308-2337762a492b",
            "firstName": "Tammy T.",
            "lastName": "Phelps",
            "fullName": "Tammy Phelps",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep3.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-LA-3-lower-ocd-person-a838e3b6-52ea-4ad0-a308-2337762a492b",
              "builtID": "la-lower-3",
              "externalID": "ocd-person/a838e3b6-52ea-4ad0-a308-2337762a492b",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse003@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "810 E. 70th St. Suite A, Shreveport, LA 71106",
              "phone1": null,
              "phone2": "318-862-3080",
              "fax1": null,
              "fax2": "318-862-3088",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=3",
                "https://house.louisiana.gov/H_Reps/members?ID=3"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-3-lower-ocd-person-a838e3b6-52ea-4ad0-a308-2337762a492b": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/378995b0-0c3e-49a4-aa93-4633f06d5509",
            "firstName": "Daryl Joy",
            "lastName": "Walters",
            "fullName": "Joy Walters",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep4.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-LA-4-lower-ocd-person-378995b0-0c3e-49a4-aa93-4633f06d5509",
              "builtID": "la-lower-4",
              "externalID": "ocd-person/378995b0-0c3e-49a4-aa93-4633f06d5509",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse004@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7505 Pines Road Suite 1160, Shreveport, LA 71129",
              "phone1": null,
              "phone2": "318-560-4059",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=4"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-4-lower-ocd-person-378995b0-0c3e-49a4-aa93-4633f06d5509": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/a900feca-75c7-434e-8d00-dd67170f2b01",
            "firstName": "Dennis",
            "lastName": "Bamburg",
            "fullName": "Dennis Bamburg",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep5.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-LA-5-lower-ocd-person-a900feca-75c7-434e-8d00-dd67170f2b01",
              "builtID": "la-lower-5",
              "externalID": "ocd-person/a900feca-75c7-434e-8d00-dd67170f2b01",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse005@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1619 Jimmie Davis Highway, Bossier City, LA 71112",
              "phone1": null,
              "phone2": "318-588-2014",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=5"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-5-lower-ocd-person-a900feca-75c7-434e-8d00-dd67170f2b01": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/b38eb9d5-c27d-443e-995d-f9681e3a992e",
            "firstName": "Michael Christopher",
            "lastName": "Melerine",
            "fullName": "Michael Melerine",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep6.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-LA-6-lower-ocd-person-b38eb9d5-c27d-443e-995d-f9681e3a992e",
              "builtID": "la-lower-6",
              "externalID": "ocd-person/b38eb9d5-c27d-443e-995d-f9681e3a992e",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse006@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "401 Market St. Suite 1130, Shreveport, LA 71101",
              "phone1": null,
              "phone2": "318-553-2259",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=6"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-6-lower-ocd-person-b38eb9d5-c27d-443e-995d-f9681e3a992e": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/d6e47850-c123-4060-8309-74192011981b",
            "firstName": "Lawrence A.",
            "lastName": "Bagley",
            "fullName": "Larry Bagley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep7.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-LA-7-lower-ocd-person-d6e47850-c123-4060-8309-74192011981b",
              "builtID": "la-lower-7",
              "externalID": "ocd-person/d6e47850-c123-4060-8309-74192011981b",
              "geometry": null
            },
            "contactInfo": {
              "email": "bagleyl@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "671 Highway 171 Suite E, Stonewall, LA 71078",
              "phone1": null,
              "phone2": "318-925-9588",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=7",
                "https://house.louisiana.gov/H_Reps/members?ID=7"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-7-lower-ocd-person-d6e47850-c123-4060-8309-74192011981b": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/126879ce-50c0-4741-a7e7-fb23fecb9d29",
            "firstName": "Raymond J.",
            "lastName": "Crews",
            "fullName": "Raymond Crews",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep8.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-LA-8-lower-ocd-person-126879ce-50c0-4741-a7e7-fb23fecb9d29",
              "builtID": "la-lower-8",
              "externalID": "ocd-person/126879ce-50c0-4741-a7e7-fb23fecb9d29",
              "geometry": null
            },
            "contactInfo": {
              "email": "crewsr@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4921 Shed Road Suite 200, Bossier City, LA 71111",
              "phone1": null,
              "phone2": "318-716-7532",
              "fax1": null,
              "fax2": "318-239-1677",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=8",
                "https://house.louisiana.gov/H_Reps/members?ID=8"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-8-lower-ocd-person-126879ce-50c0-4741-a7e7-fb23fecb9d29": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/4661fe94-874c-4884-99a2-c60516c811ff",
            "firstName": "Sylvia Delores Miller",
            "lastName": "Horton",
            "fullName": "Dodie Horton",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep9.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-LA-9-lower-ocd-person-4661fe94-874c-4884-99a2-c60516c811ff",
              "builtID": "la-lower-9",
              "externalID": "ocd-person/4661fe94-874c-4884-99a2-c60516c811ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "hortond@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "954 Highway 80 Suite 400, Haughton, LA 71037",
              "phone1": null,
              "phone2": "318-949-2463",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=9",
                "https://house.louisiana.gov/H_Reps/members?ID=9"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-9-lower-ocd-person-4661fe94-874c-4884-99a2-c60516c811ff": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/c3e1299d-034f-4e70-9c86-c003c6e35964",
            "firstName": "Wayne",
            "lastName": "McMahen",
            "fullName": "Wayne McMahen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep10.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-LA-10-lower-ocd-person-c3e1299d-034f-4e70-9c86-c003c6e35964",
              "builtID": "la-lower-10",
              "externalID": "ocd-person/c3e1299d-034f-4e70-9c86-c003c6e35964",
              "geometry": null
            },
            "contactInfo": {
              "email": "mcmahenw@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "736 Main St., Minden, LA 71055",
              "phone1": null,
              "phone2": "318-371-3092",
              "fax1": null,
              "fax2": "318-371-3093",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=10",
                "https://house.louisiana.gov/H_Reps/members?ID=10"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-10-lower-ocd-person-c3e1299d-034f-4e70-9c86-c003c6e35964": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/17cf7c94-788b-49f9-bca2-2f413974a927",
            "firstName": "Rashid Armand",
            "lastName": "Young",
            "fullName": "Rashid Young",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep11.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-LA-11-lower-ocd-person-17cf7c94-788b-49f9-bca2-2f413974a927",
              "builtID": "la-lower-11",
              "externalID": "ocd-person/17cf7c94-788b-49f9-bca2-2f413974a927",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse011@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=11"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-11-lower-ocd-person-17cf7c94-788b-49f9-bca2-2f413974a927": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/a07b757e-a57b-4dda-aa6a-7bf5e6b3561d",
            "firstName": "Christopher",
            "lastName": "Turner",
            "fullName": "Chris Turner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep12.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-LA-12-lower-ocd-person-a07b757e-a57b-4dda-aa6a-7bf5e6b3561d",
              "builtID": "la-lower-12",
              "externalID": "ocd-person/a07b757e-a57b-4dda-aa6a-7bf5e6b3561d",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse012@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "111 N. Trenton St. Suite A, Ruston, LA 71270",
              "phone1": null,
              "phone2": "318-251-5038",
              "fax1": null,
              "fax2": "318-251-5091",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=12",
                "https://house.louisiana.gov/H_Reps/members?ID=12"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-12-lower-ocd-person-a07b757e-a57b-4dda-aa6a-7bf5e6b3561d": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/2ed9a2e2-0f61-4de1-af10-e9e0dbf02492",
            "firstName": "Jack Gideon",
            "lastName": "McFarland",
            "fullName": "Jack McFarland",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep13.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-LA-13-lower-ocd-person-2ed9a2e2-0f61-4de1-af10-e9e0dbf02492",
              "builtID": "la-lower-13",
              "externalID": "ocd-person/2ed9a2e2-0f61-4de1-af10-e9e0dbf02492",
              "geometry": null
            },
            "contactInfo": {
              "email": "mcfarlandj@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 143, Jonesboro, LA 71251",
              "phone1": null,
              "phone2": "318-259-4275",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=13",
                "https://house.louisiana.gov/H_Reps/members?ID=13"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-13-lower-ocd-person-2ed9a2e2-0f61-4de1-af10-e9e0dbf02492": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/22590bd7-29fe-454d-b61d-2c54c4562274",
            "firstName": "Michael Charles",
            "lastName": "Echols",
            "fullName": "Michael Echols",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep14.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-LA-14-lower-ocd-person-22590bd7-29fe-454d-b61d-2c54c4562274",
              "builtID": "la-lower-14",
              "externalID": "ocd-person/22590bd7-29fe-454d-b61d-2c54c4562274",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse014@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "300 Washington St. Suite 203, Monroe, LA 71201",
              "phone1": null,
              "phone2": "318-598-4010",
              "fax1": null,
              "fax2": "318-598-4019",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=14",
                "https://house.louisiana.gov/H_Reps/members?ID=14"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-14-lower-ocd-person-22590bd7-29fe-454d-b61d-2c54c4562274": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/040e17ea-a4d0-47ce-8f45-527a6bdc83a7",
            "firstName": "Foy Bryan",
            "lastName": "Gadberry",
            "fullName": "Foy Gadberry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep15.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-LA-15-lower-ocd-person-040e17ea-a4d0-47ce-8f45-527a6bdc83a7",
              "builtID": "la-lower-15",
              "externalID": "ocd-person/040e17ea-a4d0-47ce-8f45-527a6bdc83a7",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse015@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1137 Ole Highway 15, West Monroe, LA 71291",
              "phone1": null,
              "phone2": "318-396-1031",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=15",
                "https://house.louisiana.gov/H_Reps/members?ID=15"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-15-lower-ocd-person-040e17ea-a4d0-47ce-8f45-527a6bdc83a7": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/5d1fade2-4678-406c-b4ed-657baed1db2b",
            "firstName": "Adrian J.",
            "lastName": "Fisher",
            "fullName": "Adrian Fisher",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep16.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-LA-16-lower-ocd-person-5d1fade2-4678-406c-b4ed-657baed1db2b",
              "builtID": "la-lower-16",
              "externalID": "ocd-person/5d1fade2-4678-406c-b4ed-657baed1db2b",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse016@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 7563, Monroe, LA 71211",
              "phone1": null,
              "phone2": "318-556-7001",
              "fax1": null,
              "fax2": "318-556-7006",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=16",
                "https://house.louisiana.gov/H_Reps/members?ID=16"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-16-lower-ocd-person-5d1fade2-4678-406c-b4ed-657baed1db2b": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/88548c7a-b637-4821-b336-c99cfa475e22",
            "firstName": "Patricia",
            "lastName": "Moore",
            "fullName": "Pat Moore",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep17.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-LA-17-lower-ocd-person-88548c7a-b637-4821-b336-c99cfa475e22",
              "builtID": "la-lower-17",
              "externalID": "ocd-person/88548c7a-b637-4821-b336-c99cfa475e22",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse017@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "300 Washington St. Suite 308, Monroe, LA 71201",
              "phone1": null,
              "phone2": "318-362-3014",
              "fax1": null,
              "fax2": "318-362-3019",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=17",
                "https://house.louisiana.gov/H_Reps/members?ID=17"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-17-lower-ocd-person-88548c7a-b637-4821-b336-c99cfa475e22": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/9ae6b490-4fe0-435c-93fd-ad316e474204",
            "firstName": "Jeremy S.",
            "lastName": "LaCombe",
            "fullName": "Jeremy LaCombe",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep18.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-LA-18-lower-ocd-person-9ae6b490-4fe0-435c-93fd-ad316e474204",
              "builtID": "la-lower-18",
              "externalID": "ocd-person/9ae6b490-4fe0-435c-93fd-ad316e474204",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse018@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 370, Livonia, LA 70755",
              "phone1": null,
              "phone2": "225-637-3803",
              "fax1": null,
              "fax2": "225-637-3805",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=18",
                "https://house.louisiana.gov/H_Reps/members?ID=18"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-18-lower-ocd-person-9ae6b490-4fe0-435c-93fd-ad316e474204": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/7796b73e-f06a-4d0b-8a85-cfde955a4cd1",
            "firstName": "Francis Coleman",
            "lastName": "Thompson",
            "fullName": "Francis Thompson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep19.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-LA-19-lower-ocd-person-7796b73e-f06a-4d0b-8a85-cfde955a4cd1",
              "builtID": "la-lower-19",
              "externalID": "ocd-person/7796b73e-f06a-4d0b-8a85-cfde955a4cd1",
              "geometry": null
            },
            "contactInfo": {
              "email": "thompsof@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 68, Delhi, LA 71232",
              "phone1": null,
              "phone2": "318-878-9408",
              "fax1": null,
              "fax2": "318-878-5650",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=19",
                "https://house.louisiana.gov/H_Reps/members?ID=19"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-19-lower-ocd-person-7796b73e-f06a-4d0b-8a85-cfde955a4cd1": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/8ce9b8d1-a92d-4351-a098-617599dde680",
            "firstName": "Hartwell Neil",
            "lastName": "Riser",
            "fullName": "Neil Riser",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep20.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-LA-20-lower-ocd-person-8ce9b8d1-a92d-4351-a098-617599dde680",
              "builtID": "la-lower-20",
              "externalID": "ocd-person/8ce9b8d1-a92d-4351-a098-617599dde680",
              "geometry": null
            },
            "contactInfo": {
              "email": "risern@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 117, Columbia, LA 71418",
              "phone1": null,
              "phone2": "318-649-0977",
              "fax1": null,
              "fax2": "318-649-0979",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/senators/senpage.asp?SenID=32",
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=20",
                "https://house.louisiana.gov/H_Reps/members?ID=20"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-20-lower-ocd-person-8ce9b8d1-a92d-4351-a098-617599dde680": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/871d5c01-9710-46f4-aeba-b321bdf11e30",
            "firstName": "Caxerrick Travis",
            "lastName": "Johnson",
            "fullName": "Travis Johnson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep21.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-LA-21-lower-ocd-person-871d5c01-9710-46f4-aeba-b321bdf11e30",
              "builtID": "la-lower-21",
              "externalID": "ocd-person/871d5c01-9710-46f4-aeba-b321bdf11e30",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse021@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "200 Advocate Row Suite D, Vidalia, LA 71373",
              "phone1": null,
              "phone2": "225-308-4269",
              "fax1": null,
              "fax2": "318-336-9268",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=21",
                "https://house.louisiana.gov/H_Reps/members?ID=21"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-21-lower-ocd-person-871d5c01-9710-46f4-aeba-b321bdf11e30": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/50b42a20-9d29-4388-b06f-b4511eda2131",
            "firstName": "Michael",
            "lastName": "Firment",
            "fullName": "Gabe Firment",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep22.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-LA-22-lower-ocd-person-50b42a20-9d29-4388-b06f-b4511eda2131",
              "builtID": "la-lower-22",
              "externalID": "ocd-person/50b42a20-9d29-4388-b06f-b4511eda2131",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse022@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 660 181 Barron Road, Pollock, LA 71467",
              "phone1": null,
              "phone2": "318-765-9606",
              "fax1": null,
              "fax2": "318-765-9607",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=22",
                "https://house.louisiana.gov/H_Reps/members?ID=22"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-22-lower-ocd-person-50b42a20-9d29-4388-b06f-b4511eda2131": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/6fdcb100-c1e5-450e-a3dd-cf42c06cff1e",
            "firstName": "Shaun Raphael",
            "lastName": "Mena",
            "fullName": "Shaun Mena",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep23.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-LA-23-lower-ocd-person-6fdcb100-c1e5-450e-a3dd-cf42c06cff1e",
              "builtID": "la-lower-23",
              "externalID": "ocd-person/6fdcb100-c1e5-450e-a3dd-cf42c06cff1e",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse023@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4603 S. Carrolton Ave. Suite E, New Orleans, LA 70119",
              "phone1": null,
              "phone2": "504-396-4105",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=23"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-23-lower-ocd-person-6fdcb100-c1e5-450e-a3dd-cf42c06cff1e": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/01cb17b2-a215-4cd5-916a-43c3e91723c7",
            "firstName": "Rodney Wayne",
            "lastName": "Schamerhorn",
            "fullName": "Rodney Schamerhorn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep24.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-LA-24-lower-ocd-person-01cb17b2-a215-4cd5-916a-43c3e91723c7",
              "builtID": "la-lower-24",
              "externalID": "ocd-person/01cb17b2-a215-4cd5-916a-43c3e91723c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse024@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 83, Hornbeck, LA 71439",
              "phone1": null,
              "phone2": "318-565-4959",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=24",
                "https://house.louisiana.gov/H_Reps/members?ID=24"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-24-lower-ocd-person-01cb17b2-a215-4cd5-916a-43c3e91723c7": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/daa3d2db-d395-4c08-9020-b90d6bc2c498",
            "firstName": "Jason Brian",
            "lastName": "DeWitt",
            "fullName": "Jason DeWitt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep25.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-LA-25-lower-ocd-person-daa3d2db-d395-4c08-9020-b90d6bc2c498",
              "builtID": "la-lower-25",
              "externalID": "ocd-person/daa3d2db-d395-4c08-9020-b90d6bc2c498",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse025@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7050 Louisiana Highway 1, Boyce, LA 70409",
              "phone1": null,
              "phone2": "318-277-7475",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=25"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-25-lower-ocd-person-daa3d2db-d395-4c08-9020-b90d6bc2c498": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/9a91a848-9d01-46b6-84f1-cc868681e912",
            "firstName": "Ed",
            "lastName": "Larvadain",
            "fullName": "Ed Larvadain",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep26.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-LA-26-lower-ocd-person-9a91a848-9d01-46b6-84f1-cc868681e912",
              "builtID": "la-lower-26",
              "externalID": "ocd-person/9a91a848-9d01-46b6-84f1-cc868681e912",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse026@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1811 MacArthur Drive, Alexandria, LA 71301",
              "phone1": null,
              "phone2": "318-487-5441",
              "fax1": null,
              "fax2": "318-487-5455",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=26",
                "https://house.louisiana.gov/H_Reps/members?ID=26"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-26-lower-ocd-person-9a91a848-9d01-46b6-84f1-cc868681e912": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/0e901294-3718-49df-9922-0ac4503acee2",
            "firstName": "Michael T.",
            "lastName": "Johnson",
            "fullName": "Mike Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep27.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-LA-27-lower-ocd-person-0e901294-3718-49df-9922-0ac4503acee2",
              "builtID": "la-lower-27",
              "externalID": "ocd-person/0e901294-3718-49df-9922-0ac4503acee2",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse027@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2757 Highway 28 East, Pineville, LA 71360",
              "phone1": null,
              "phone2": "318-487-5377",
              "fax1": null,
              "fax2": "318-487-5420",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=27",
                "https://house.louisiana.gov/H_Reps/members?ID=27"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-27-lower-ocd-person-0e901294-3718-49df-9922-0ac4503acee2": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/2ace9f60-8f90-43ab-a45d-d1c64bdb7462",
            "firstName": "Daryl Andrew",
            "lastName": "Deshotel",
            "fullName": "Daryl Deshotel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep28.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-LA-28-lower-ocd-person-2ace9f60-8f90-43ab-a45d-d1c64bdb7462",
              "builtID": "la-lower-28",
              "externalID": "ocd-person/2ace9f60-8f90-43ab-a45d-d1c64bdb7462",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse028@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "312 N. Main St., Marksville, LA 71351",
              "phone1": null,
              "phone2": "318-253-8891",
              "fax1": null,
              "fax2": "318-253-6377",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=28",
                "https://house.louisiana.gov/H_Reps/members?ID=28"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-28-lower-ocd-person-2ace9f60-8f90-43ab-a45d-d1c64bdb7462": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/efdf7ee4-4d37-4e57-8f0c-e0db52ee7f70",
            "firstName": "Edmond Dwayne",
            "lastName": "Jordan",
            "fullName": "Edmond Jordan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep29.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-LA-29-lower-ocd-person-efdf7ee4-4d37-4e57-8f0c-e0db52ee7f70",
              "builtID": "la-lower-29",
              "externalID": "ocd-person/efdf7ee4-4d37-4e57-8f0c-e0db52ee7f70",
              "geometry": null
            },
            "contactInfo": {
              "email": "jordane@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2370 Towne Center Blvd., Baton Rouge, LA 70806",
              "phone1": null,
              "phone2": "225-925-3700",
              "fax1": null,
              "fax2": "225-925-3701",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=29",
                "https://house.louisiana.gov/H_Reps/members?ID=29"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-29-lower-ocd-person-efdf7ee4-4d37-4e57-8f0c-e0db52ee7f70": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/2a4d4c31-ee06-4f32-a67b-c55db60502e4",
            "firstName": "Charles Anthony",
            "lastName": "Owen",
            "fullName": "Chuck Owen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep30.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-LA-30-lower-ocd-person-2a4d4c31-ee06-4f32-a67b-c55db60502e4",
              "builtID": "la-lower-30",
              "externalID": "ocd-person/2a4d4c31-ee06-4f32-a67b-c55db60502e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse030@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 55 6673 Evans St., Rosepine, LA 70659",
              "phone1": null,
              "phone2": "337-460-8726",
              "fax1": null,
              "fax2": "337-460-8727",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=30",
                "https://house.louisiana.gov/H_Reps/members?ID=30"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-30-lower-ocd-person-2a4d4c31-ee06-4f32-a67b-c55db60502e4": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/ba2902d5-5a0e-4e71-b396-6e85358511de",
            "firstName": "Troy Jude",
            "lastName": "Hebert",
            "fullName": "Troy Hebert",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep31.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-LA-31-lower-ocd-person-ba2902d5-5a0e-4e71-b396-6e85358511de",
              "builtID": "la-lower-31",
              "externalID": "ocd-person/ba2902d5-5a0e-4e71-b396-6e85358511de",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse031@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3639 Ambassador Caffery Parkway Suite 214, Lafayette, LA 70503",
              "phone1": null,
              "phone2": "337-347-7077",
              "fax1": null,
              "fax2": "337-347-7073",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=31"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-31-lower-ocd-person-ba2902d5-5a0e-4e71-b396-6e85358511de": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/2bf8549d-d379-4c42-af8c-b5b9d270f17d",
            "firstName": "R. Dewith",
            "lastName": "Carrier",
            "fullName": "Dewith Carrier",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep32.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-LA-32-lower-ocd-person-2bf8549d-d379-4c42-af8c-b5b9d270f17d",
              "builtID": "la-lower-32",
              "externalID": "ocd-person/2bf8549d-d379-4c42-af8c-b5b9d270f17d",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse032@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 593, Oakdale, LA 71463",
              "phone1": null,
              "phone2": "318-335-0206",
              "fax1": null,
              "fax2": "318-335-0208",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=32",
                "https://house.louisiana.gov/H_Reps/members?ID=32"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-32-lower-ocd-person-2bf8549d-d379-4c42-af8c-b5b9d270f17d": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/81379952-373b-4e6e-a29f-b9bb60e7593b",
            "firstName": "Leslie",
            "lastName": "Farnum",
            "fullName": "Les Farnum",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep33.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-LA-33-lower-ocd-person-81379952-373b-4e6e-a29f-b9bb60e7593b",
              "builtID": "la-lower-33",
              "externalID": "ocd-person/81379952-373b-4e6e-a29f-b9bb60e7593b",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse033@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 558, Sulphur, LA 70664",
              "phone1": null,
              "phone2": "337-527-2377",
              "fax1": null,
              "fax2": "337-527-2378",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=33",
                "https://house.louisiana.gov/H_Reps/members?ID=33"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-33-lower-ocd-person-81379952-373b-4e6e-a29f-b9bb60e7593b": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/3ced6b49-7f72-41d0-a84c-0e7cff17e88b",
            "firstName": "Wilford Dan",
            "lastName": "Carter",
            "fullName": "Wilford Carter",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep34.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-LA-34-lower-ocd-person-3ced6b49-7f72-41d0-a84c-0e7cff17e88b",
              "builtID": "la-lower-34",
              "externalID": "ocd-person/3ced6b49-7f72-41d0-a84c-0e7cff17e88b",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse034@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1025 Mill St., Lake Charles, LA 70601",
              "phone1": null,
              "phone2": "337-491-2320",
              "fax1": null,
              "fax2": "337-491-2020",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=34",
                "https://house.louisiana.gov/H_Reps/members?ID=34"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-34-lower-ocd-person-3ced6b49-7f72-41d0-a84c-0e7cff17e88b": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/9ffd05e6-a127-4896-b60e-fc2722fa8779",
            "firstName": "Brett Frank",
            "lastName": "Geymann",
            "fullName": "Brett Geymann",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep35.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-LA-35-lower-ocd-person-9ffd05e6-a127-4896-b60e-fc2722fa8779",
              "builtID": "la-lower-35",
              "externalID": "ocd-person/9ffd05e6-a127-4896-b60e-fc2722fa8779",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse035@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 12703, Lake Charles, LA 70612",
              "phone1": null,
              "phone2": "337-855-8133",
              "fax1": null,
              "fax2": "337-855-8285",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=35",
                "https://house.louisiana.gov/H_Reps/members?ID=35"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-35-lower-ocd-person-9ffd05e6-a127-4896-b60e-fc2722fa8779": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/04a524b7-1f7a-4d01-8491-c52df47e20cc",
            "firstName": "Phillip Eric",
            "lastName": "Tarver",
            "fullName": "Phil Tarver",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep36.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-LA-36-lower-ocd-person-04a524b7-1f7a-4d01-8491-c52df47e20cc",
              "builtID": "la-lower-36",
              "externalID": "ocd-person/04a524b7-1f7a-4d01-8491-c52df47e20cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse036@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "132 Jamestown, Lake Charles, LA 70605",
              "phone1": null,
              "phone2": "337-475-8186",
              "fax1": null,
              "fax2": "337-475-8188",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=36",
                "https://house.louisiana.gov/H_Reps/members?ID=36"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-36-lower-ocd-person-04a524b7-1f7a-4d01-8491-c52df47e20cc": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/87828aa1-a979-419e-aac6-c808c00896e7",
            "firstName": "Troy D.",
            "lastName": "Romero",
            "fullName": "Troy Romero",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep37.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-LA-37-lower-ocd-person-87828aa1-a979-419e-aac6-c808c00896e7",
              "builtID": "la-lower-37",
              "externalID": "ocd-person/87828aa1-a979-419e-aac6-c808c00896e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse037@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 287, Jennings, LA 70546",
              "phone1": null,
              "phone2": "337-824-0376",
              "fax1": null,
              "fax2": "337-824-4780",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=37",
                "https://house.louisiana.gov/H_Reps/members?ID=37"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-37-lower-ocd-person-87828aa1-a979-419e-aac6-c808c00896e7": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/6c232d36-fe39-4006-8397-de9cb1e5454c",
            "firstName": "Rhonda Gaye",
            "lastName": "Butler",
            "fullName": "Rhonda Butler",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep38.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-LA-38-lower-ocd-person-6c232d36-fe39-4006-8397-de9cb1e5454c",
              "builtID": "la-lower-38",
              "externalID": "ocd-person/6c232d36-fe39-4006-8397-de9cb1e5454c",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse038@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5592 Vidrine Road, Ville Platte, LA 70586",
              "phone1": null,
              "phone2": "337-363-3900",
              "fax1": null,
              "fax2": "337-363-3903",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=38",
                "https://house.louisiana.gov/H_Reps/members?ID=38"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-38-lower-ocd-person-6c232d36-fe39-4006-8397-de9cb1e5454c": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/0e515629-c907-4618-9ae1-4e4457ea25ea",
            "firstName": "Julie Cathryn",
            "lastName": "Emerson",
            "fullName": "Julie Emerson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep39.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-LA-39-lower-ocd-person-0e515629-c907-4618-9ae1-4e4457ea25ea",
              "builtID": "la-lower-39",
              "externalID": "ocd-person/0e515629-c907-4618-9ae1-4e4457ea25ea",
              "geometry": null
            },
            "contactInfo": {
              "email": "emersonj@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "306 N. Church St., Carencro, LA 70520",
              "phone1": null,
              "phone2": "337-886-4687",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=39",
                "https://house.louisiana.gov/H_Reps/members?ID=39"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-39-lower-ocd-person-0e515629-c907-4618-9ae1-4e4457ea25ea": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/dcf2c7e1-e124-424c-ac0c-8d1e6600e970",
            "firstName": "Dustin",
            "lastName": "Miller",
            "fullName": "Dustin Miller",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep40.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-LA-40-lower-ocd-person-dcf2c7e1-e124-424c-ac0c-8d1e6600e970",
              "builtID": "la-lower-40",
              "externalID": "ocd-person/dcf2c7e1-e124-424c-ac0c-8d1e6600e970",
              "geometry": null
            },
            "contactInfo": {
              "email": "millerd@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1310 S. Union St. Suite 2, Opelousas, LA 70570",
              "phone1": null,
              "phone2": "337-943-2900",
              "fax1": null,
              "fax2": "337-943-2902",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=40",
                "https://house.louisiana.gov/H_Reps/members?ID=40"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-40-lower-ocd-person-dcf2c7e1-e124-424c-ac0c-8d1e6600e970": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/ee23b4bf-f5f7-498c-9fcc-f3b2d6f8fb1d",
            "firstName": "Phillip Ryan",
            "lastName": "DeVillier",
            "fullName": "Phillip DeVillier",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep41.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-LA-41-lower-ocd-person-ee23b4bf-f5f7-498c-9fcc-f3b2d6f8fb1d",
              "builtID": "la-lower-41",
              "externalID": "ocd-person/ee23b4bf-f5f7-498c-9fcc-f3b2d6f8fb1d",
              "geometry": null
            },
            "contactInfo": {
              "email": "devillierp@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 986, Eunice, LA 70535",
              "phone1": null,
              "phone2": "337-457-0194",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=41",
                "https://house.louisiana.gov/H_Reps/members?ID=41"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-41-lower-ocd-person-ee23b4bf-f5f7-498c-9fcc-f3b2d6f8fb1d": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/75101b55-81b9-465a-8ec6-297fa75cdd07",
            "firstName": "Chance Keith",
            "lastName": "Henry",
            "fullName": "Chance Henry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep42.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-LA-42-lower-ocd-person-75101b55-81b9-465a-8ec6-297fa75cdd07",
              "builtID": "la-lower-42",
              "externalID": "ocd-person/75101b55-81b9-465a-8ec6-297fa75cdd07",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse042@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "509 N. Parkerson Ave., Crowley, LA 70526",
              "phone1": null,
              "phone2": "337-384-8999",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=42"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-42-lower-ocd-person-75101b55-81b9-465a-8ec6-297fa75cdd07": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/85df7a11-9edb-4e27-b7ce-60ffe7b35d39",
            "firstName": "Joshua Paul",
            "lastName": "Carlson",
            "fullName": "Josh Carlson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep43.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-LA-43-lower-ocd-person-85df7a11-9edb-4e27-b7ce-60ffe7b35d39",
              "builtID": "la-lower-43",
              "externalID": "ocd-person/85df7a11-9edb-4e27-b7ce-60ffe7b35d39",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse043@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1602 W. Pinhook Road Suite 202, Lafayette, LA 70508",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=43"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-43-lower-ocd-person-85df7a11-9edb-4e27-b7ce-60ffe7b35d39": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/8d0bbdce-eaab-4b3a-8385-bf8c6a2e0b87",
            "firstName": "Tehmi Jahi",
            "lastName": "Chassion",
            "fullName": "Tehmi Chassion",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep44.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-LA-44-lower-ocd-person-8d0bbdce-eaab-4b3a-8385-bf8c6a2e0b87",
              "builtID": "la-lower-44",
              "externalID": "ocd-person/8d0bbdce-eaab-4b3a-8385-bf8c6a2e0b87",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse044@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=44"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-44-lower-ocd-person-8d0bbdce-eaab-4b3a-8385-bf8c6a2e0b87": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/92439b80-4646-445b-939f-5414e309f183",
            "firstName": "Brach Jerad",
            "lastName": "Myers",
            "fullName": "Brach Myers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep45.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-LA-45-lower-ocd-person-92439b80-4646-445b-939f-5414e309f183",
              "builtID": "la-lower-45",
              "externalID": "ocd-person/92439b80-4646-445b-939f-5414e309f183",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse045@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 51499, Lafayette, LA 70505",
              "phone1": null,
              "phone2": "337-295-1765",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=45"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-45-lower-ocd-person-92439b80-4646-445b-939f-5414e309f183": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/a31429da-4cde-4dbe-9510-af2dfa8ab2ed",
            "firstName": "Chad Michael",
            "lastName": "Boyer",
            "fullName": "Chad Boyer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep46.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-LA-46-lower-ocd-person-a31429da-4cde-4dbe-9510-af2dfa8ab2ed",
              "builtID": "la-lower-46",
              "externalID": "ocd-person/a31429da-4cde-4dbe-9510-af2dfa8ab2ed",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse046@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "110 N. Main St., Breaux Bridge, LA 70517",
              "phone1": null,
              "phone2": "337-332-3331",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=46"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-46-lower-ocd-person-a31429da-4cde-4dbe-9510-af2dfa8ab2ed": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/85ef927c-d038-4534-a8ff-b99ad6656bda",
            "firstName": "Ryan Joseph",
            "lastName": "Bourriaque",
            "fullName": "Ryan Bourriaque",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep47.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-LA-47-lower-ocd-person-85ef927c-d038-4534-a8ff-b99ad6656bda",
              "builtID": "la-lower-47",
              "externalID": "ocd-person/85ef927c-d038-4534-a8ff-b99ad6656bda",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse047@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "122 N. State St. Suite 100, Abbeville, LA 70510",
              "phone1": null,
              "phone2": "337-893-5035",
              "fax1": null,
              "fax2": "337-740-5035",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=47",
                "https://house.louisiana.gov/H_Reps/members?ID=47"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-47-lower-ocd-person-85ef927c-d038-4534-a8ff-b99ad6656bda": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/c5ea395a-7594-4585-b193-000e05f08005",
            "firstName": "Gerald Alphonse",
            "lastName": "Beaullieu",
            "fullName": "Beau Beaullieu",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep48.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-LA-48-lower-ocd-person-c5ea395a-7594-4585-b193-000e05f08005",
              "builtID": "la-lower-48",
              "externalID": "ocd-person/c5ea395a-7594-4585-b193-000e05f08005",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse048@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "209 W. Main St. 4th Floor, Suite 403, New Iberia, LA 70560",
              "phone1": null,
              "phone2": "337-373-4051",
              "fax1": null,
              "fax2": "337-373-4053",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=48",
                "https://house.louisiana.gov/H_Reps/members?ID=48"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-48-lower-ocd-person-c5ea395a-7594-4585-b193-000e05f08005": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/80ea23c0-16f8-4a57-aa48-2c5cf478a3e1",
            "firstName": "Jacob Jules Gabriel",
            "lastName": "Landry",
            "fullName": "Jacob Landry",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep49.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-LA-49-lower-ocd-person-80ea23c0-16f8-4a57-aa48-2c5cf478a3e1",
              "builtID": "la-lower-49",
              "externalID": "ocd-person/80ea23c0-16f8-4a57-aa48-2c5cf478a3e1",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse049@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "106 E. Edwards St., Erath, LA 70533",
              "phone1": null,
              "phone2": "337-361-0631",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=49"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-49-lower-ocd-person-80ea23c0-16f8-4a57-aa48-2c5cf478a3e1": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/c75a9e00-6017-4e74-95fd-70fbfa803c58",
            "firstName": "Vincent J.",
            "lastName": "St. Blanc",
            "fullName": "Vinney St. Blanc",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep50.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-LA-50-lower-ocd-person-c75a9e00-6017-4e74-95fd-70fbfa803c58",
              "builtID": "la-lower-50",
              "externalID": "ocd-person/c75a9e00-6017-4e74-95fd-70fbfa803c58",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse050@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "733 Main St., Franklin, LA 70538",
              "phone1": null,
              "phone2": "337-828-7778",
              "fax1": null,
              "fax2": "337-828-4511",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=50",
                "https://house.louisiana.gov/H_Reps/members?ID=50"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-50-lower-ocd-person-c75a9e00-6017-4e74-95fd-70fbfa803c58": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/cbe53992-3b3c-4ec0-a94a-58ddf96e6edd",
            "firstName": "Beryl Adams",
            "lastName": "Amedee",
            "fullName": "Beryl Amedée",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep51.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-LA-51-lower-ocd-person-cbe53992-3b3c-4ec0-a94a-58ddf96e6edd",
              "builtID": "la-lower-51",
              "externalID": "ocd-person/cbe53992-3b3c-4ec0-a94a-58ddf96e6edd",
              "geometry": null
            },
            "contactInfo": {
              "email": "amedeeb@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4266 W. Main St. Suite 300, Gray, LA 70359",
              "phone1": null,
              "phone2": "985-858-2967",
              "fax1": null,
              "fax2": "985-858-2968",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=51",
                "https://house.louisiana.gov/H_Reps/members?ID=51"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-51-lower-ocd-person-cbe53992-3b3c-4ec0-a94a-58ddf96e6edd": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/2dafe5b8-cb5a-411a-b270-322dcaf18293",
            "firstName": "Jerome Peter",
            "lastName": "Zeringue",
            "fullName": "Jerome Zeringue",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep52.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-LA-52-lower-ocd-person-2dafe5b8-cb5a-411a-b270-322dcaf18293",
              "builtID": "la-lower-52",
              "externalID": "ocd-person/2dafe5b8-cb5a-411a-b270-322dcaf18293",
              "geometry": null
            },
            "contactInfo": {
              "email": "zeringuej@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "423 Goode St., Houma, LA 70360",
              "phone1": null,
              "phone2": "985-876-8823",
              "fax1": null,
              "fax2": "985-876-0213",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=52",
                "https://house.louisiana.gov/H_Reps/members?ID=52"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-52-lower-ocd-person-2dafe5b8-cb5a-411a-b270-322dcaf18293": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/95e28ef0-84f6-45ec-97fe-a58f360a2ad9",
            "firstName": "Jessica",
            "lastName": "Domangue",
            "fullName": "Jessica Domangue",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep53.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-LA-53-lower-ocd-person-95e28ef0-84f6-45ec-97fe-a58f360a2ad9",
              "builtID": "la-lower-53",
              "externalID": "ocd-person/95e28ef0-84f6-45ec-97fe-a58f360a2ad9",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse053@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7833 W. Main St., Houma, LA 70360",
              "phone1": null,
              "phone2": "985-858-2970",
              "fax1": null,
              "fax2": "985-858-2972",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=53"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-53-lower-ocd-person-95e28ef0-84f6-45ec-97fe-a58f360a2ad9": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/9002c3a7-c0d9-4112-ad34-e0c6fa909ce1",
            "firstName": "Joseph A.",
            "lastName": "Orgeron",
            "fullName": "Joe Orgeron",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep54.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-LA-54-lower-ocd-person-9002c3a7-c0d9-4112-ad34-e0c6fa909ce1",
              "builtID": "la-lower-54",
              "externalID": "ocd-person/9002c3a7-c0d9-4112-ad34-e0c6fa909ce1",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse054@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "15063 E. Main St., Cut Off, LA 70345",
              "phone1": null,
              "phone2": "985-693-9000",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=54",
                "https://house.louisiana.gov/H_Reps/members?ID=54"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-54-lower-ocd-person-9002c3a7-c0d9-4112-ad34-e0c6fa909ce1": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/90b6f6c3-9620-4fbb-8c23-f8ab7b163de7",
            "firstName": "Bryan",
            "lastName": "Fontenot",
            "fullName": "Bryan Fontenot",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep55.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-LA-55-lower-ocd-person-90b6f6c3-9620-4fbb-8c23-f8ab7b163de7",
              "builtID": "la-lower-55",
              "externalID": "ocd-person/90b6f6c3-9620-4fbb-8c23-f8ab7b163de7",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse055@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "406 W. 3rd St. Suite 107, Thibodaux, LA 70301",
              "phone1": null,
              "phone2": "985-447-0999",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=55",
                "https://house.louisiana.gov/H_Reps/members?ID=55"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-55-lower-ocd-person-90b6f6c3-9620-4fbb-8c23-f8ab7b163de7": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/5d15439d-4139-448a-8f61-25ab54e68b19",
            "firstName": "Beth Anne",
            "lastName": "Billings",
            "fullName": "Beth Billings",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep56.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-LA-56-lower-ocd-person-5d15439d-4139-448a-8f61-25ab54e68b19",
              "builtID": "la-lower-56",
              "externalID": "ocd-person/5d15439d-4139-448a-8f61-25ab54e68b19",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse056@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "104 Campus Drive East Suite 201, Destrehan, LA 70047",
              "phone1": null,
              "phone2": "985-764-0203",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=56"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-56-lower-ocd-person-5d15439d-4139-448a-8f61-25ab54e68b19": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/586f002d-71da-4836-a306-6e8a3d485263",
            "firstName": "Sylvia Elaine",
            "lastName": "Taylor",
            "fullName": "Sylvia Taylor",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep57.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-LA-57-lower-ocd-person-586f002d-71da-4836-a306-6e8a3d485263",
              "builtID": "la-lower-57",
              "externalID": "ocd-person/586f002d-71da-4836-a306-6e8a3d485263",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse057@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2013 W. Airline Highway, Laplace, LA 70068",
              "phone1": null,
              "phone2": "985-618-0166",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=57"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-57-lower-ocd-person-586f002d-71da-4836-a306-6e8a3d485263": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/43a80737-e614-475d-aace-2d1324cb516a",
            "firstName": "Kendricks",
            "lastName": "Brass",
            "fullName": "Ken Brass",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep58.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-LA-58-lower-ocd-person-43a80737-e614-475d-aace-2d1324cb516a",
              "builtID": "la-lower-58",
              "externalID": "ocd-person/43a80737-e614-475d-aace-2d1324cb516a",
              "geometry": null
            },
            "contactInfo": {
              "email": "brassk@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "22140 Highway 20 Suite C, Vacherie, LA 70090",
              "phone1": null,
              "phone2": "225-265-9005",
              "fax1": null,
              "fax2": "225-265-9006",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=58",
                "https://house.louisiana.gov/H_Reps/members?ID=58"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-58-lower-ocd-person-43a80737-e614-475d-aace-2d1324cb516a": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/9fde5725-d1b2-4afc-9897-2706dda502c8",
            "firstName": "Anthony Joseph",
            "lastName": "Bacala",
            "fullName": "Tony Bacala",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep59.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-LA-59-lower-ocd-person-9fde5725-d1b2-4afc-9897-2706dda502c8",
              "builtID": "la-lower-59",
              "externalID": "ocd-person/9fde5725-d1b2-4afc-9897-2706dda502c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "bacalat@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "15482 Airline Highway, Prairieville, LA 70769",
              "phone1": null,
              "phone2": "225-677-8023",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=59",
                "https://house.louisiana.gov/H_Reps/members?ID=59"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-59-lower-ocd-person-9fde5725-d1b2-4afc-9897-2706dda502c8": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/6c1520bb-eebb-41ec-8d67-4f6e936f60b9",
            "firstName": "Chad Michael",
            "lastName": "Brown",
            "fullName": "Chad Brown",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep60.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-LA-60-lower-ocd-person-6c1520bb-eebb-41ec-8d67-4f6e936f60b9",
              "builtID": "la-lower-60",
              "externalID": "ocd-person/6c1520bb-eebb-41ec-8d67-4f6e936f60b9",
              "geometry": null
            },
            "contactInfo": {
              "email": "brownc@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "57835 Plaquemine St., Plaquemine, LA 70764",
              "phone1": null,
              "phone2": "225-687-2410",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=60",
                "https://house.louisiana.gov/H_Reps/members?ID=60"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-60-lower-ocd-person-6c1520bb-eebb-41ec-8d67-4f6e936f60b9": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/20def39d-ba5c-45d7-ba6c-5cf43590d4b0",
            "firstName": "C. Denise",
            "lastName": "Marcelle",
            "fullName": "Denise Marcelle",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep61.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-LA-61-lower-ocd-person-20def39d-ba5c-45d7-ba6c-5cf43590d4b0",
              "builtID": "la-lower-61",
              "externalID": "ocd-person/20def39d-ba5c-45d7-ba6c-5cf43590d4b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "marcelled@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1824 N. Acadian Thruway West, Baton Rouge, LA 70802",
              "phone1": null,
              "phone2": "225-359-9362",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=61",
                "https://house.louisiana.gov/H_Reps/members?ID=61"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-61-lower-ocd-person-20def39d-ba5c-45d7-ba6c-5cf43590d4b0": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/0353532c-93a0-41cb-9621-278c8ef19178",
            "firstName": "Roy Daryl",
            "lastName": "Adams",
            "fullName": "Roy Adams",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep62.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-LA-62-lower-ocd-person-0353532c-93a0-41cb-9621-278c8ef19178",
              "builtID": "la-lower-62",
              "externalID": "ocd-person/0353532c-93a0-41cb-9621-278c8ef19178",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse062@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 217, Jackson, LA 70748",
              "phone1": null,
              "phone2": "225-634-7470",
              "fax1": null,
              "fax2": "225-634-7477",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=62",
                "https://house.louisiana.gov/H_Reps/members?ID=62"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-62-lower-ocd-person-0353532c-93a0-41cb-9621-278c8ef19178": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/ad514eed-2832-4ae3-a0c5-62d8f2721297",
            "firstName": "Barbara West",
            "lastName": "Carpenter",
            "fullName": "Barbara Carpenter",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep63.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-LA-63-lower-ocd-person-ad514eed-2832-4ae3-a0c5-62d8f2721297",
              "builtID": "la-lower-63",
              "externalID": "ocd-person/ad514eed-2832-4ae3-a0c5-62d8f2721297",
              "geometry": null
            },
            "contactInfo": {
              "email": "carpenterb@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1975 Harding Blvd., Baton Rouge, LA 70807",
              "phone1": null,
              "phone2": "225-771-5674",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=63",
                "https://house.louisiana.gov/H_Reps/members?ID=63"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-63-lower-ocd-person-ad514eed-2832-4ae3-a0c5-62d8f2721297": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/c64e6628-efa4-4ffe-8b2f-d9df80f4f313",
            "firstName": "Kellee",
            "lastName": "Hennessy Dickerson",
            "fullName": "Kellee Hennessy",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep64.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-LA-64-lower-ocd-person-c64e6628-efa4-4ffe-8b2f-d9df80f4f313",
              "builtID": "la-lower-64",
              "externalID": "ocd-person/c64e6628-efa4-4ffe-8b2f-d9df80f4f313",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse064@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "32350 Louisiana Highway 16 Building F, Suite 2, Denham Springs, LA 70706",
              "phone1": null,
              "phone2": "225-380-4232",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=64"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-64-lower-ocd-person-c64e6628-efa4-4ffe-8b2f-d9df80f4f313": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/d2ecd14d-09ce-4e45-8969-149531759584",
            "firstName": "Lauren Elizabeth",
            "lastName": "Ventrella",
            "fullName": "Lauren Ventrella",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep65.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-LA-65-lower-ocd-person-d2ecd14d-09ce-4e45-8969-149531759584",
              "builtID": "la-lower-65",
              "externalID": "ocd-person/d2ecd14d-09ce-4e45-8969-149531759584",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse065@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "22801 Greenwell Springs Road Suite 3, Greenwell Spring, LA 70739",
              "phone1": null,
              "phone2": "225-361-4318",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=65"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-65-lower-ocd-person-d2ecd14d-09ce-4e45-8969-149531759584": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/9661594c-df1e-402a-b2c5-fe2c88366506",
            "firstName": "Emily Morrow",
            "lastName": "Chenevert",
            "fullName": "Emily Chenevert",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep66.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-LA-66-lower-ocd-person-9661594c-df1e-402a-b2c5-fe2c88366506",
              "builtID": "la-lower-66",
              "externalID": "ocd-person/9661594c-df1e-402a-b2c5-fe2c88366506",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse066@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "111 Founders Drive Suite 100, Baton Rouge, LA 70810",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=66"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-66-lower-ocd-person-9661594c-df1e-402a-b2c5-fe2c88366506": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/52035a94-6995-4ea4-b463-1109d683048b",
            "firstName": "Larry",
            "lastName": "Selders",
            "fullName": "Larry Selders",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep67.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-LA-67-lower-ocd-person-52035a94-6995-4ea4-b463-1109d683048b",
              "builtID": "la-lower-67",
              "externalID": "ocd-person/52035a94-6995-4ea4-b463-1109d683048b",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse067@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "251 Florida St. Suite 300, Baton Rouge, LA 70801",
              "phone1": null,
              "phone2": "225-342-7106",
              "fax1": null,
              "fax2": "225-342-7117",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=67",
                "https://house.louisiana.gov/H_Reps/members?ID=67"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-67-lower-ocd-person-52035a94-6995-4ea4-b463-1109d683048b": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/d3cae3e7-f6eb-4a9c-8c63-8719902f771d",
            "firstName": "Dixon Wallace",
            "lastName": "McMakin",
            "fullName": "Dixon McMakin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep68.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-LA-68-lower-ocd-person-d3cae3e7-f6eb-4a9c-8c63-8719902f771d",
              "builtID": "la-lower-68",
              "externalID": "ocd-person/d3cae3e7-f6eb-4a9c-8c63-8719902f771d",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse068@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3613 Perkins Road Unit F, Baton Rouge, LA 70808",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=68"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-68-lower-ocd-person-d3cae3e7-f6eb-4a9c-8c63-8719902f771d": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/386a6e17-a7e7-4715-8420-5e10bf52dcc1",
            "firstName": "Paula Pellerin",
            "lastName": "Davis",
            "fullName": "Paula Davis",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep69.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-LA-69-lower-ocd-person-386a6e17-a7e7-4715-8420-5e10bf52dcc1",
              "builtID": "la-lower-69",
              "externalID": "ocd-person/386a6e17-a7e7-4715-8420-5e10bf52dcc1",
              "geometry": null
            },
            "contactInfo": {
              "email": "davisp@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7902 Wrenwood Blvd. Suite D, Baton Rouge, LA 70809",
              "phone1": null,
              "phone2": "225-362-5301",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=69",
                "https://house.louisiana.gov/H_Reps/members?ID=69"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-69-lower-ocd-person-386a6e17-a7e7-4715-8420-5e10bf52dcc1": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/bd179bf7-f90d-4e3a-baea-a590c3002586",
            "firstName": "Barbara Reich",
            "lastName": "Freiberg",
            "fullName": "Barbara Freiberg",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep70.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-LA-70-lower-ocd-person-bd179bf7-f90d-4e3a-baea-a590c3002586",
              "builtID": "la-lower-70",
              "externalID": "ocd-person/bd179bf7-f90d-4e3a-baea-a590c3002586",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse070@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5800 One Perkins Place Suite 7A, Baton Rouge, LA 70808",
              "phone1": null,
              "phone2": "225-763-3500",
              "fax1": null,
              "fax2": "225-763-3506",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=70",
                "https://house.louisiana.gov/H_Reps/members?ID=70"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-70-lower-ocd-person-bd179bf7-f90d-4e3a-baea-a590c3002586": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/c6f00cae-9826-4be6-934e-1a9516b20c4d",
            "firstName": "Roger William",
            "lastName": "Wilder",
            "fullName": "Roger Wilder",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep71.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-LA-71-lower-ocd-person-c6f00cae-9826-4be6-934e-1a9516b20c4d",
              "builtID": "la-lower-71",
              "externalID": "ocd-person/c6f00cae-9826-4be6-934e-1a9516b20c4d",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse071@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1863, Denham Springs, LA 70727",
              "phone1": null,
              "phone2": "225-380-7160",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=71"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-71-lower-ocd-person-c6f00cae-9826-4be6-934e-1a9516b20c4d": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/a24e242f-3fd9-479c-8423-cf0c6e2c6273",
            "firstName": "Robert J.",
            "lastName": "Carter",
            "fullName": "Robby Carter",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep72.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-LA-72-lower-ocd-person-a24e242f-3fd9-479c-8423-cf0c6e2c6273",
              "builtID": "la-lower-72",
              "externalID": "ocd-person/a24e242f-3fd9-479c-8423-cf0c6e2c6273",
              "geometry": null
            },
            "contactInfo": {
              "email": "carterr@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "225 Central Ave. NW, Amite, LA 70422",
              "phone1": null,
              "phone2": "985-748-2245",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=72",
                "https://house.louisiana.gov/H_Reps/members?ID=72"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-72-lower-ocd-person-a24e242f-3fd9-479c-8423-cf0c6e2c6273": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/d6dba94d-0167-4391-992d-af96c6525253",
            "firstName": "Kimberly Landry",
            "lastName": "Coates",
            "fullName": "Kim Coates",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep73.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-LA-73-lower-ocd-person-d6dba94d-0167-4391-992d-af96c6525253",
              "builtID": "la-lower-73",
              "externalID": "ocd-person/d6dba94d-0167-4391-992d-af96c6525253",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse073@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P. O. Box 2203, Ponchatoula, LA 70454",
              "phone1": null,
              "phone2": "985-551-1424",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=73"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-73-lower-ocd-person-d6dba94d-0167-4391-992d-af96c6525253": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/52df3631-4ef7-4b52-ab11-8a8eeb7bf499",
            "firstName": "Peter F.",
            "lastName": "Egan",
            "fullName": "Peter Egan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep74.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-LA-74-lower-ocd-person-52df3631-4ef7-4b52-ab11-8a8eeb7bf499",
              "builtID": "la-lower-74",
              "externalID": "ocd-person/52df3631-4ef7-4b52-ab11-8a8eeb7bf499",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse074@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "78461 Highway 437, Covington, LA 70435",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=74"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-74-lower-ocd-person-52df3631-4ef7-4b52-ab11-8a8eeb7bf499": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/8bc5e1d1-fe97-4466-a11d-415f56c5151a",
            "firstName": "John E.",
            "lastName": "Wyble",
            "fullName": "John Wyble",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep75.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-LA-75-lower-ocd-person-8bc5e1d1-fe97-4466-a11d-415f56c5151a",
              "builtID": "la-lower-75",
              "externalID": "ocd-person/8bc5e1d1-fe97-4466-a11d-415f56c5151a",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse075@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "943 Washington St., Franklinton, LA 70438",
              "phone1": null,
              "phone2": "985-515-1020",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=75"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-75-lower-ocd-person-8bc5e1d1-fe97-4466-a11d-415f56c5151a": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/f05b1c80-c85c-42f5-8336-84bcd7b0c847",
            "firstName": "Stephanie Hunter",
            "lastName": "Berault",
            "fullName": "Stephanie Berault",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep76.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-LA-76-lower-ocd-person-f05b1c80-c85c-42f5-8336-84bcd7b0c847",
              "builtID": "la-lower-76",
              "externalID": "ocd-person/f05b1c80-c85c-42f5-8336-84bcd7b0c847",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse076@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1808 Front St. Suite 204, Slidell, LA 70461",
              "phone1": null,
              "phone2": "985-640-9795",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=76"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-76-lower-ocd-person-f05b1c80-c85c-42f5-8336-84bcd7b0c847": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/d3ecec03-0408-46f0-8c82-b775f787e6e1",
            "firstName": "Mark A.",
            "lastName": "Wright",
            "fullName": "Mark Wright",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep77.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-LA-77-lower-ocd-person-d3ecec03-0408-46f0-8c82-b775f787e6e1",
              "builtID": "la-lower-77",
              "externalID": "ocd-person/d3ecec03-0408-46f0-8c82-b775f787e6e1",
              "geometry": null
            },
            "contactInfo": {
              "email": "wrightm@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "312 S. Jefferson St. Suite A and B, Covington, LA 70433",
              "phone1": null,
              "phone2": "985-893-6262",
              "fax1": null,
              "fax2": "985-893-6261",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=77",
                "https://house.louisiana.gov/H_Reps/members?ID=77"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-77-lower-ocd-person-d3ecec03-0408-46f0-8c82-b775f787e6e1": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/3c0eeaa2-cf62-4b17-9b62-2531c078dd42",
            "firstName": "John R.",
            "lastName": "Illg",
            "fullName": "John Illg",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep78.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-LA-78-lower-ocd-person-3c0eeaa2-cf62-4b17-9b62-2531c078dd42",
              "builtID": "la-lower-78",
              "externalID": "ocd-person/3c0eeaa2-cf62-4b17-9b62-2531c078dd42",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse078@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6387 Jefferson Highway, Harahan, LA 70123",
              "phone1": null,
              "phone2": "504-737-0315",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=78",
                "https://house.louisiana.gov/H_Reps/members?ID=78"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-78-lower-ocd-person-3c0eeaa2-cf62-4b17-9b62-2531c078dd42": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/aefc2237-5913-4b96-9354-18fb7e58884c",
            "firstName": "Deborah Ann",
            "lastName": "Villio",
            "fullName": "Debbie Villio",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep79.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-LA-79-lower-ocd-person-aefc2237-5913-4b96-9354-18fb7e58884c",
              "builtID": "la-lower-79",
              "externalID": "ocd-person/aefc2237-5913-4b96-9354-18fb7e58884c",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse079@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4203 Williams Blvd. Suite 200, Kenner, LA 70065",
              "phone1": null,
              "phone2": "504-468-8603",
              "fax1": null,
              "fax2": "504-468-8605",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=79",
                "https://house.louisiana.gov/H_Reps/members?ID=79"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-79-lower-ocd-person-aefc2237-5913-4b96-9354-18fb7e58884c": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/d95410b8-a66c-4662-bcc6-1884252e061b",
            "firstName": "Polly Jung",
            "lastName": "Thomas",
            "fullName": "Polly Thomas",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep80.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-LA-80-lower-ocd-person-d95410b8-a66c-4662-bcc6-1884252e061b",
              "builtID": "la-lower-80",
              "externalID": "ocd-person/d95410b8-a66c-4662-bcc6-1884252e061b",
              "geometry": null
            },
            "contactInfo": {
              "email": "thomaspj@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3216 N. Turnbull Drive Suite B, Metairie, LA 70002",
              "phone1": null,
              "phone2": "504-837-6559",
              "fax1": null,
              "fax2": "504-324-9926",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=80",
                "https://house.louisiana.gov/H_Reps/members?ID=80"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-80-lower-ocd-person-d95410b8-a66c-4662-bcc6-1884252e061b": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/b96a872c-91bd-47ea-a50f-f82294b3d91b",
            "firstName": "Jeffrey Fons",
            "lastName": "Wiley",
            "fullName": "Jeff Wiley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep81.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-LA-81-lower-ocd-person-b96a872c-91bd-47ea-a50f-f82294b3d91b",
              "builtID": "la-lower-81",
              "externalID": "ocd-person/b96a872c-91bd-47ea-a50f-f82294b3d91b",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse081@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "12481 Homeport Drive Suite J, Maurepas, LA 70449",
              "phone1": null,
              "phone2": "225-698-5400",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=81"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-81-lower-ocd-person-b96a872c-91bd-47ea-a50f-f82294b3d91b": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/1dbd875c-89b6-4ea1-a73b-7c8efdb203a3",
            "firstName": "Laurie",
            "lastName": "Schlegel",
            "fullName": "Laurie Schlegel",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep82.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-LA-82-lower-ocd-person-1dbd875c-89b6-4ea1-a73b-7c8efdb203a3",
              "builtID": "la-lower-82",
              "externalID": "ocd-person/1dbd875c-89b6-4ea1-a73b-7c8efdb203a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse082@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3238 Metairie Road Suite B, Metairie, LA 70001",
              "phone1": null,
              "phone2": "504-655-6887",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=82",
                "https://house.louisiana.gov/H_Reps/members?ID=82"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-82-lower-ocd-person-1dbd875c-89b6-4ea1-a73b-7c8efdb203a3": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/8ffaa2bd-ff67-464a-b639-19a6069e28a2",
            "firstName": "Kyle M.",
            "lastName": "Green",
            "fullName": "Kyle Green",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep83.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-LA-83-lower-ocd-person-8ffaa2bd-ff67-464a-b639-19a6069e28a2",
              "builtID": "la-lower-83",
              "externalID": "ocd-person/8ffaa2bd-ff67-464a-b639-19a6069e28a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse083@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6641 Westbank Expressway Suite D, Marrero, LA 70072",
              "phone1": null,
              "phone2": "504-349-8788",
              "fax1": null,
              "fax2": "504-349-8790",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=83",
                "https://house.louisiana.gov/H_Reps/members?ID=83"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-83-lower-ocd-person-8ffaa2bd-ff67-464a-b639-19a6069e28a2": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/1ebb9d9b-329d-4a43-a33c-e3608ef09cc8",
            "firstName": "Timothy P.",
            "lastName": "Kerner",
            "fullName": "Tim Kerner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep84.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-LA-84-lower-ocd-person-1ebb9d9b-329d-4a43-a33c-e3608ef09cc8",
              "builtID": "la-lower-84",
              "externalID": "ocd-person/1ebb9d9b-329d-4a43-a33c-e3608ef09cc8",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse084@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "799 Jean Lafitte Blvd., Lafitte, LA 70067",
              "phone1": null,
              "phone2": "504-689-7725",
              "fax1": null,
              "fax2": "504-689-7727",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=84",
                "https://house.louisiana.gov/H_Reps/members?ID=84"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-84-lower-ocd-person-1ebb9d9b-329d-4a43-a33c-e3608ef09cc8": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/fe1e7481-9bbb-4e58-8d19-9be18558f12b",
            "firstName": "Vincent E.",
            "lastName": "Cox",
            "fullName": "Vincent Cox",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep85.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-LA-85-lower-ocd-person-fe1e7481-9bbb-4e58-8d19-9be18558f12b",
              "builtID": "la-lower-85",
              "externalID": "ocd-person/fe1e7481-9bbb-4e58-8d19-9be18558f12b",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse085@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "General Government Building 200 Derbigny St., Suite 4300, Gretna, LA 70053",
              "phone1": null,
              "phone2": "504-361-6013",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=85"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-85-lower-ocd-person-fe1e7481-9bbb-4e58-8d19-9be18558f12b": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/bc967860-a12b-4285-bd97-36379803f119",
            "firstName": "Nicholas",
            "lastName": "Muscarello",
            "fullName": "Nicholas Muscarello",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep86.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-LA-86-lower-ocd-person-bc967860-a12b-4285-bd97-36379803f119",
              "builtID": "la-lower-86",
              "externalID": "ocd-person/bc967860-a12b-4285-bd97-36379803f119",
              "geometry": null
            },
            "contactInfo": {
              "email": "muscarellon@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1523, Hammond, LA 70404",
              "phone1": null,
              "phone2": "985-974-0009",
              "fax1": null,
              "fax2": "985-345-4447",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=86",
                "https://house.louisiana.gov/H_Reps/members?ID=86"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-86-lower-ocd-person-bc967860-a12b-4285-bd97-36379803f119": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/71696836-b261-4646-8127-407c40e2c35b",
            "firstName": "Rodney S.",
            "lastName": "Lyons",
            "fullName": "Rodney Lyons",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep87.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-LA-87-lower-ocd-person-71696836-b261-4646-8127-407c40e2c35b",
              "builtID": "la-lower-87",
              "externalID": "ocd-person/71696836-b261-4646-8127-407c40e2c35b",
              "geometry": null
            },
            "contactInfo": {
              "email": "lyonsr@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5201 Westbank Expressway Suite 114, Marrero, LA 70072",
              "phone1": null,
              "phone2": "504-510-5417",
              "fax1": null,
              "fax2": "504-349-8704",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=87",
                "https://house.louisiana.gov/H_Reps/members?ID=87"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-87-lower-ocd-person-71696836-b261-4646-8127-407c40e2c35b": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/92f7834e-8ce3-437f-bdbf-8dad3528ae3b",
            "firstName": "Donna Katherine",
            "lastName": "Edmonston",
            "fullName": "Kathy Edmonston",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep88.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-LA-88-lower-ocd-person-92f7834e-8ce3-437f-bdbf-8dad3528ae3b",
              "builtID": "la-lower-88",
              "externalID": "ocd-person/92f7834e-8ce3-437f-bdbf-8dad3528ae3b",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse088@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2115 S. Burnside Suite C, Gonzales, LA 70737",
              "phone1": null,
              "phone2": "225-647-5646",
              "fax1": null,
              "fax2": "225-644-7207",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=88",
                "https://house.louisiana.gov/H_Reps/members?ID=88"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-88-lower-ocd-person-92f7834e-8ce3-437f-bdbf-8dad3528ae3b": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/bd6d41fc-8ad8-468f-8259-223d38d42bd8",
            "firstName": "Christopher Kim",
            "lastName": "Carver",
            "fullName": "Kim Carver",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep89.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-LA-89-lower-ocd-person-bd6d41fc-8ad8-468f-8259-223d38d42bd8",
              "builtID": "la-lower-89",
              "externalID": "ocd-person/bd6d41fc-8ad8-468f-8259-223d38d42bd8",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse089@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1200 W. Causeway Approach Suite 20, Mandeville, LA 70471",
              "phone1": null,
              "phone2": "985-629-1412",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=89"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-89-lower-ocd-person-bd6d41fc-8ad8-468f-8259-223d38d42bd8": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/96385d4e-bfae-4929-9845-502fd2ed2cc7",
            "firstName": "Brian Leonard",
            "lastName": "Glorioso",
            "fullName": "Brian Glorioso",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep90.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-LA-90-lower-ocd-person-96385d4e-bfae-4929-9845-502fd2ed2cc7",
              "builtID": "la-lower-90",
              "externalID": "ocd-person/96385d4e-bfae-4929-9845-502fd2ed2cc7",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse090@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1301 Brownswitch Road Suite C, Slidell, LA 70461",
              "phone1": null,
              "phone2": "985-960-4251",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=90"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-90-lower-ocd-person-96385d4e-bfae-4929-9845-502fd2ed2cc7": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/6e106343-c9e7-4993-aca8-201210f61d64",
            "firstName": "Mandie E.",
            "lastName": "Landry",
            "fullName": "Mandie Landry",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep91.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-LA-91-lower-ocd-person-6e106343-c9e7-4993-aca8-201210f61d64",
              "builtID": "la-lower-91",
              "externalID": "ocd-person/6e106343-c9e7-4993-aca8-201210f61d64",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse091@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4035 Washington Ave., New Orleans, LA 70125",
              "phone1": null,
              "phone2": "504-383-3836",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=91",
                "https://house.louisiana.gov/H_Reps/members?ID=91"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-91-lower-ocd-person-6e106343-c9e7-4993-aca8-201210f61d64": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/958015ce-4a91-4830-a11f-e73997823d4b",
            "firstName": "Joseph A.",
            "lastName": "Stagni",
            "fullName": "Joe Stagni",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep92.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-LA-92-lower-ocd-person-958015ce-4a91-4830-a11f-e73997823d4b",
              "builtID": "la-lower-92",
              "externalID": "ocd-person/958015ce-4a91-4830-a11f-e73997823d4b",
              "geometry": null
            },
            "contactInfo": {
              "email": "stagnij@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1201 Williams Blvd., Kenner, LA 70062",
              "phone1": null,
              "phone2": "504-465-3479",
              "fax1": null,
              "fax2": "504-465-3481",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=92",
                "https://house.louisiana.gov/H_Reps/members?ID=92"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-92-lower-ocd-person-958015ce-4a91-4830-a11f-e73997823d4b": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/967fa557-fcdc-4a75-a13e-dabf05b85905",
            "firstName": "Alonzo L.",
            "lastName": "Knox",
            "fullName": "Alonzo Knox",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep93.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-LA-93-lower-ocd-person-967fa557-fcdc-4a75-a13e-dabf05b85905",
              "builtID": "la-lower-93",
              "externalID": "ocd-person/967fa557-fcdc-4a75-a13e-dabf05b85905",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse093@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1239 Baronne St., New Orleans, LA 70113",
              "phone1": null,
              "phone2": "504-568-3101",
              "fax1": null,
              "fax2": "504-568-3104",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=93",
                "https://house.louisiana.gov/H_Reps/members?ID=93"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-93-lower-ocd-person-967fa557-fcdc-4a75-a13e-dabf05b85905": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/de7591e7-6ae0-47c3-b1ea-12073106498c",
            "firstName": "Stephanie Anne",
            "lastName": "Hilferty",
            "fullName": "Stephanie Hilferty",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep94.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-LA-94-lower-ocd-person-de7591e7-6ae0-47c3-b1ea-12073106498c",
              "builtID": "la-lower-94",
              "externalID": "ocd-person/de7591e7-6ae0-47c3-b1ea-12073106498c",
              "geometry": null
            },
            "contactInfo": {
              "email": "hilfertys@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3331 Severn Ave. Suite 206, Metairie, LA 70002",
              "phone1": null,
              "phone2": "504-885-4154",
              "fax1": null,
              "fax2": "504-885-4156",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=94",
                "https://house.louisiana.gov/H_Reps/members?ID=94"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-94-lower-ocd-person-de7591e7-6ae0-47c3-b1ea-12073106498c": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/46b660af-1c4c-46a1-905e-ffd89a9cc32f",
            "firstName": "Shane",
            "lastName": "Mack",
            "fullName": "Shane Mack",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep95.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-LA-95-lower-ocd-person-46b660af-1c4c-46a1-905e-ffd89a9cc32f",
              "builtID": "la-lower-95",
              "externalID": "ocd-person/46b660af-1c4c-46a1-905e-ffd89a9cc32f",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse095@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1354, Livingston, LA 70754",
              "phone1": null,
              "phone2": "225-686-7500",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=95",
                "https://house.louisiana.gov/H_Reps/members?ID=95"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-95-lower-ocd-person-46b660af-1c4c-46a1-905e-ffd89a9cc32f": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/4d1c2a6e-0339-4b7a-ac52-dfd9064bfbbf",
            "firstName": "Marcus Anthony",
            "lastName": "Bryant",
            "fullName": "Marcus Bryant",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep96.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-LA-96-lower-ocd-person-4d1c2a6e-0339-4b7a-ac52-dfd9064bfbbf",
              "builtID": "la-lower-96",
              "externalID": "ocd-person/4d1c2a6e-0339-4b7a-ac52-dfd9064bfbbf",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse096@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "209 W. Main St., New Iberia, LA 70560",
              "phone1": null,
              "phone2": "337-373-9380",
              "fax1": null,
              "fax2": "337-373-9382",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=96",
                "https://house.louisiana.gov/H_Reps/members?ID=96"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-96-lower-ocd-person-4d1c2a6e-0339-4b7a-ac52-dfd9064bfbbf": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/834e887f-c79d-44ed-be47-74571a792249",
            "firstName": "Matthew",
            "lastName": "Willard",
            "fullName": "Matt Willard",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep97.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-LA-97-lower-ocd-person-834e887f-c79d-44ed-be47-74571a792249",
              "builtID": "la-lower-97",
              "externalID": "ocd-person/834e887f-c79d-44ed-be47-74571a792249",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse097@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2021 Lakeshore Drive Suite 140, New Orleans, LA 70122",
              "phone1": null,
              "phone2": "504-283-4261",
              "fax1": null,
              "fax2": "504-283-4263",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=97",
                "https://house.louisiana.gov/H_Reps/members?ID=97"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-97-lower-ocd-person-834e887f-c79d-44ed-be47-74571a792249": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/3937cef1-8c62-428c-8837-aa6a6a8b60e7",
            "firstName": "Aimee Adatto",
            "lastName": "Freeman",
            "fullName": "Aimee Freeman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep98.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-LA-98-lower-ocd-person-3937cef1-8c62-428c-8837-aa6a6a8b60e7",
              "builtID": "la-lower-98",
              "externalID": "ocd-person/3937cef1-8c62-428c-8837-aa6a6a8b60e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse098@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "200 Broadway St. Suite 212, New Orleans, LA 70118",
              "phone1": null,
              "phone2": "504-861-1614",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=98",
                "https://house.louisiana.gov/H_Reps/members?ID=98"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-98-lower-ocd-person-3937cef1-8c62-428c-8837-aa6a6a8b60e7": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/85a96a1b-a8a0-4ddd-acd7-9452b48f5ace",
            "firstName": "Candace N.",
            "lastName": "Newell",
            "fullName": "Candace Newell",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep99.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-LA-99-lower-ocd-person-85a96a1b-a8a0-4ddd-acd7-9452b48f5ace",
              "builtID": "la-lower-99",
              "externalID": "ocd-person/85a96a1b-a8a0-4ddd-acd7-9452b48f5ace",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse099@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "New Orleans Lake Front Airport Terminal Building 6001 Stars and Stripes, Suite 149, New Orleans, LA 70126",
              "phone1": null,
              "phone2": "504-240-3435",
              "fax1": null,
              "fax2": "504-240-3437",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=99",
                "https://house.louisiana.gov/H_Reps/members?ID=99"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-99-lower-ocd-person-85a96a1b-a8a0-4ddd-acd7-9452b48f5ace": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/9c15d56b-627b-4fbe-8e23-2ac8385c2224",
            "firstName": "Jason Wynne",
            "lastName": "Hughes",
            "fullName": "Jason Hughes",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep100.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-LA-100-lower-ocd-person-9c15d56b-627b-4fbe-8e23-2ac8385c2224",
              "builtID": "la-lower-100",
              "externalID": "ocd-person/9c15d56b-627b-4fbe-8e23-2ac8385c2224",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse100@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 872461, New Orleans, LA 70187",
              "phone1": null,
              "phone2": "504-246-9707",
              "fax1": null,
              "fax2": "504-246-9709",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=100",
                "https://house.louisiana.gov/H_Reps/members?ID=100"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-100-lower-ocd-person-9c15d56b-627b-4fbe-8e23-2ac8385c2224": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/9510158f-4e63-4b68-ad44-daef2987b780",
            "firstName": "Vanessa Caston",
            "lastName": "LaFleur",
            "fullName": "Vanessa LaFleur",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep101.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-LA-101-lower-ocd-person-9510158f-4e63-4b68-ad44-daef2987b780",
              "builtID": "la-lower-101",
              "externalID": "ocd-person/9510158f-4e63-4b68-ad44-daef2987b780",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse101@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2370 Towne Center Blvd. 1st Floor, Baton Rouge, LA 70806",
              "phone1": null,
              "phone2": "225-925-6880",
              "fax1": null,
              "fax2": "225-925-6881",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=101",
                "https://house.louisiana.gov/H_Reps/members?ID=101"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-101-lower-ocd-person-9510158f-4e63-4b68-ad44-daef2987b780": 0
        }
      },
      "102": {
        "members": [
          {
            "API_ID": "ocd-person/d107ae2b-6cb6-4545-a185-d63408f46033",
            "firstName": "Delisha Young",
            "lastName": "Boyd",
            "fullName": "Delisha Boyd",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics20/rep102.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "102",
              "chamber": "lower",
              "hashID": "SLDL-LA-102-lower-ocd-person-d107ae2b-6cb6-4545-a185-d63408f46033",
              "builtID": "la-lower-102",
              "externalID": "ocd-person/d107ae2b-6cb6-4545-a185-d63408f46033",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse102@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3401 General De Gaulle Drive Suite 5076, New Orleans, LA 70114",
              "phone1": null,
              "phone2": "504-605-5622",
              "fax1": null,
              "fax2": "504-814-7921",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=102",
                "https://house.louisiana.gov/H_Reps/members?ID=102"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-102-lower-ocd-person-d107ae2b-6cb6-4545-a185-d63408f46033": 0
        }
      },
      "103": {
        "members": [
          {
            "API_ID": "ocd-person/e06c4ec7-9051-4489-b572-5b55e5fd4a68",
            "firstName": "Michael Robert",
            "lastName": "Bayham",
            "fullName": "Mike Bayham",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep103.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "103",
              "chamber": "lower",
              "hashID": "SLDL-LA-103-lower-ocd-person-e06c4ec7-9051-4489-b572-5b55e5fd4a68",
              "builtID": "la-lower-103",
              "externalID": "ocd-person/e06c4ec7-9051-4489-b572-5b55e5fd4a68",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse103@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "100 Port Blvd. Suite 40, Chalmette, LA 70043",
              "phone1": null,
              "phone2": "504-512-3628",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=103"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-103-lower-ocd-person-e06c4ec7-9051-4489-b572-5b55e5fd4a68": 0
        }
      },
      "104": {
        "members": [
          {
            "API_ID": "ocd-person/ef1c54d6-d162-48d3-b3b5-b02da086c1ee",
            "firstName": "Jack William",
            "lastName": "Galle",
            "fullName": "Jay Gallé",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep104.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "104",
              "chamber": "lower",
              "hashID": "SLDL-LA-104-lower-ocd-person-ef1c54d6-d162-48d3-b3b5-b02da086c1ee",
              "builtID": "la-lower-104",
              "externalID": "ocd-person/ef1c54d6-d162-48d3-b3b5-b02da086c1ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse104@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "790 Florida St. (U.S. 190) Suite 3B, Mandeville, LA 70448",
              "phone1": null,
              "phone2": "985-624-4603",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=104"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-104-lower-ocd-person-ef1c54d6-d162-48d3-b3b5-b02da086c1ee": 0
        }
      },
      "105": {
        "members": [
          {
            "API_ID": "ocd-person/bfe2d39a-39a1-4992-86cd-6ab25767de92",
            "firstName": "Stephen Jacob",
            "lastName": "Braud",
            "fullName": "Jacob Braud",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.louisiana.gov/H_Reps/RepPics/rep105.jpg",
            "title": "LA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "105",
              "chamber": "lower",
              "hashID": "SLDL-LA-105-lower-ocd-person-bfe2d39a-39a1-4992-86cd-6ab25767de92",
              "builtID": "la-lower-105",
              "externalID": "ocd-person/bfe2d39a-39a1-4992-86cd-6ab25767de92",
              "geometry": null
            },
            "contactInfo": {
              "email": "hse105@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "8114 Highway 23 Suite 103, Belle Chasse, LA 70037",
              "phone1": null,
              "phone2": "504-230-2360",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.louisiana.gov/H_Reps/members?ID=105"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-LA-105-lower-ocd-person-bfe2d39a-39a1-4992-86cd-6ab25767de92": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/cfe19d33-7048-4fb7-9d56-0fa0f5f985f5",
            "firstName": "Robert Stanford",
            "lastName": "Owen",
            "fullName": "Bob Owen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenPics/Sen1.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-LA-1-upper-ocd-person-cfe19d33-7048-4fb7-9d56-0fa0f5f985f5",
              "builtID": "la-upper-1",
              "externalID": "ocd-person/cfe19d33-7048-4fb7-9d56-0fa0f5f985f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen01@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "100 Port Blvd. Suite 20, Chalmette, LA 70043",
              "phone1": null,
              "phone2": "985-639-0400",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.la.gov/smembers?ID=1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-1-upper-ocd-person-cfe19d33-7048-4fb7-9d56-0fa0f5f985f5": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/1899dc2e-bac6-4951-b3fc-87ea5e2d4969",
            "firstName": "Edward Joseph",
            "lastName": "Price",
            "fullName": "Ed Price",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senate.la.gov/SenatorPics/Sen2.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-LA-2-upper-ocd-person-1899dc2e-bac6-4951-b3fc-87ea5e2d4969",
              "builtID": "la-upper-2",
              "externalID": "ocd-person/1899dc2e-bac6-4951-b3fc-87ea5e2d4969",
              "geometry": null
            },
            "contactInfo": {
              "email": "pricee@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2115 S. Burnside Ave. Suite B, Gonzales, LA 70737",
              "phone1": null,
              "phone2": "225-644-6738",
              "fax1": null,
              "fax2": "225-644-6750",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=2",
                "https://senate.la.gov/smembers?ID=2"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-2-upper-ocd-person-1899dc2e-bac6-4951-b3fc-87ea5e2d4969": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/26ccd002-0b4d-4845-8a86-cae06a62be70",
            "firstName": "Joseph J.",
            "lastName": "Bouie",
            "fullName": "Joe Bouie",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senate.la.gov/SenatorPics/Sen3.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-LA-3-upper-ocd-person-26ccd002-0b4d-4845-8a86-cae06a62be70",
              "builtID": "la-upper-3",
              "externalID": "ocd-person/26ccd002-0b4d-4845-8a86-cae06a62be70",
              "geometry": null
            },
            "contactInfo": {
              "email": "bouiej@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=97",
                "https://senate.la.gov/smembers.aspx?ID=3",
                "https://senate.la.gov/smembers?ID=3"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-3-upper-ocd-person-26ccd002-0b4d-4845-8a86-cae06a62be70": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/ce11601e-9a67-48a1-9408-b80943312303",
            "firstName": "James C.",
            "lastName": "Harris",
            "fullName": "Jimmy Harris",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senate.la.gov/SenatorPics/Sen4.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-LA-4-upper-ocd-person-ce11601e-9a67-48a1-9408-b80943312303",
              "builtID": "la-upper-4",
              "externalID": "ocd-person/ce11601e-9a67-48a1-9408-b80943312303",
              "geometry": null
            },
            "contactInfo": {
              "email": "harrisj@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2021 Lakeshore Drive, Suite 204, New Orleans, LA 70122",
              "phone1": null,
              "phone2": "504-286-1960",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=99",
                "https://senate.la.gov/smembers.aspx?ID=4",
                "https://senate.la.gov/smembers?ID=4"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-4-upper-ocd-person-ce11601e-9a67-48a1-9408-b80943312303": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/7d852e7a-ae99-4f89-b4c0-4d7cb7e816db",
            "firstName": "Royce",
            "lastName": "Duplessis",
            "fullName": "Royce Duplessis",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senate.la.gov/SenatorPics/Sen5.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-LA-5-upper-ocd-person-7d852e7a-ae99-4f89-b4c0-4d7cb7e816db",
              "builtID": "la-upper-5",
              "externalID": "ocd-person/7d852e7a-ae99-4f89-b4c0-4d7cb7e816db",
              "geometry": null
            },
            "contactInfo": {
              "email": "duplessisr@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "643 Magazine St. Suite 302, New Orleans, LA 70130",
              "phone1": null,
              "phone2": "504-568-2740",
              "fax1": null,
              "fax2": "504-568-2744",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=93",
                "https://senate.la.gov/smembers.aspx?ID=5",
                "https://senate.la.gov/smembers?ID=5"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-5-upper-ocd-person-7d852e7a-ae99-4f89-b4c0-4d7cb7e816db": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/e49647e3-b5c3-4329-9472-27048115441c",
            "firstName": "Richard Phillip",
            "lastName": "Edmonds",
            "fullName": "Rick Edmonds",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenPics/Sen6.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-LA-6-upper-ocd-person-e49647e3-b5c3-4329-9472-27048115441c",
              "builtID": "la-upper-6",
              "externalID": "ocd-person/e49647e3-b5c3-4329-9472-27048115441c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen06@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3931 S. Sherwood Forest Blvd. Suite 200, Baton Rouge, LA 70816",
              "phone1": null,
              "phone2": "225-295-9240",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=66",
                "https://senate.la.gov/smembers?ID=6"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-6-upper-ocd-person-e49647e3-b5c3-4329-9472-27048115441c": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/60ffe8d0-0469-4eca-a69a-deec93c203b3",
            "firstName": "Gary Michael",
            "lastName": "Carter",
            "fullName": "Gary Carter",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senate.la.gov/SenatorPics/Sen7.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-LA-7-upper-ocd-person-60ffe8d0-0469-4eca-a69a-deec93c203b3",
              "builtID": "la-upper-7",
              "externalID": "ocd-person/60ffe8d0-0469-4eca-a69a-deec93c203b3",
              "geometry": null
            },
            "contactInfo": {
              "email": "carterg@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2401 Westbend Parkway Suite 3071, New Orleans, LA 70114",
              "phone1": null,
              "phone2": "504-361-6600",
              "fax1": null,
              "fax2": "504-361-6603",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=102",
                "https://senate.la.gov/smembers.aspx?ID=7",
                "https://senate.la.gov/smembers?ID=7"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-7-upper-ocd-person-60ffe8d0-0469-4eca-a69a-deec93c203b3": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/a5240587-95aa-4135-befc-de2ea2aa1d51",
            "firstName": "John Patrick",
            "lastName": "Connick",
            "fullName": "Patrick Connick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen8.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-LA-8-upper-ocd-person-a5240587-95aa-4135-befc-de2ea2aa1d51",
              "builtID": "la-upper-8",
              "externalID": "ocd-person/a5240587-95aa-4135-befc-de2ea2aa1d51",
              "geometry": null
            },
            "contactInfo": {
              "email": "connickp@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5201 Westbank Expressway Suite 315, Marrero, LA 70072",
              "phone1": null,
              "phone2": "504-371-0240",
              "fax1": null,
              "fax2": "504-347-4526",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=84",
                "https://senate.la.gov/smembers.aspx?ID=8",
                "https://senate.la.gov/smembers?ID=8"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-8-upper-ocd-person-a5240587-95aa-4135-befc-de2ea2aa1d51": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/684d1cc7-cce3-4545-a6ab-d689c7c56105",
            "firstName": "John Cameron",
            "lastName": "Henry",
            "fullName": "Cameron Henry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen9.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-LA-9-upper-ocd-person-684d1cc7-cce3-4545-a6ab-d689c7c56105",
              "builtID": "la-upper-9",
              "externalID": "ocd-person/684d1cc7-cce3-4545-a6ab-d689c7c56105",
              "geometry": null
            },
            "contactInfo": {
              "email": "henryc@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2573 Metairie Road Suite C, Metairie, LA 70001",
              "phone1": null,
              "phone2": "504-838-5433",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=9",
                "https://senate.la.gov/smembers?ID=9"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-9-upper-ocd-person-684d1cc7-cce3-4545-a6ab-d689c7c56105": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/f6f401f7-7fb4-40df-84a0-b5a3f0fe9ec2",
            "firstName": "Michael Kirk",
            "lastName": "Talbot",
            "fullName": "Kirk Talbot",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen10.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-LA-10-upper-ocd-person-f6f401f7-7fb4-40df-84a0-b5a3f0fe9ec2",
              "builtID": "la-upper-10",
              "externalID": "ocd-person/f6f401f7-7fb4-40df-84a0-b5a3f0fe9ec2",
              "geometry": null
            },
            "contactInfo": {
              "email": "talbotk@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "9523 Jefferson Highway Suite B, River Ridge, LA 70123",
              "phone1": null,
              "phone2": "504-736-7299",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=78",
                "https://senate.la.gov/smembers.aspx?ID=10",
                "https://senate.la.gov/smembers?ID=10"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-10-upper-ocd-person-f6f401f7-7fb4-40df-84a0-b5a3f0fe9ec2": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/d4a6db65-2f6f-464f-ae26-58176f2b68e6",
            "firstName": "Patrick",
            "lastName": "McMath",
            "fullName": "Patrick McMath",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen11.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-LA-11-upper-ocd-person-d4a6db65-2f6f-464f-ae26-58176f2b68e6",
              "builtID": "la-upper-11",
              "externalID": "ocd-person/d4a6db65-2f6f-464f-ae26-58176f2b68e6",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen11@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "409 E. Boston St. Suite 200, Covington, LA 70433",
              "phone1": null,
              "phone2": "985-900-6278",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=11",
                "https://senate.la.gov/smembers?ID=11"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-11-upper-ocd-person-d4a6db65-2f6f-464f-ae26-58176f2b68e6": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/b6e8a5cf-ec5c-494b-b9ff-efa0fb92e1c1",
            "firstName": "Mary Beth Sherman",
            "lastName": "Mizell",
            "fullName": "Beth Mizell",
            "gender": "Female",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen12.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-LA-12-upper-ocd-person-b6e8a5cf-ec5c-494b-b9ff-efa0fb92e1c1",
              "builtID": "la-upper-12",
              "externalID": "ocd-person/b6e8a5cf-ec5c-494b-b9ff-efa0fb92e1c1",
              "geometry": null
            },
            "contactInfo": {
              "email": "mizellb@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1051 Main St., Franklinton, LA 70438",
              "phone1": null,
              "phone2": "985-839-3936",
              "fax1": null,
              "fax2": "985-839-7714",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=12",
                "https://senate.la.gov/smembers?ID=12"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-12-upper-ocd-person-b6e8a5cf-ec5c-494b-b9ff-efa0fb92e1c1": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/33e76c3a-a834-444c-9872-fc2af5c83837",
            "firstName": "Valarie Dawn Hope",
            "lastName": "Hodges",
            "fullName": "Valarie Hodges",
            "gender": "Female",
            "party": "Republican",
            "image": "https://senate.la.gov/SenPics/Sen13.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-LA-13-upper-ocd-person-33e76c3a-a834-444c-9872-fc2af5c83837",
              "builtID": "la-upper-13",
              "externalID": "ocd-person/33e76c3a-a834-444c-9872-fc2af5c83837",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen13@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "35055 Louisiana Highway 16 Suite 2A, Denham Springs, LA 70706",
              "phone1": null,
              "phone2": "225-791-2199",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=64",
                "https://senate.la.gov/smembers?ID=13"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-13-upper-ocd-person-33e76c3a-a834-444c-9872-fc2af5c83837": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/b2eca8f2-6db8-4263-92a4-050e3819de53",
            "firstName": "Cleo C.",
            "lastName": "Fields",
            "fullName": "Cleo Fields",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senate.la.gov/SenPics/Sen14.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-LA-14-upper-ocd-person-b2eca8f2-6db8-4263-92a4-050e3819de53",
              "builtID": "la-upper-14",
              "externalID": "ocd-person/b2eca8f2-6db8-4263-92a4-050e3819de53",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen14@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2147 Government St., Baton Rouge, LA 70806",
              "phone1": null,
              "phone2": "225-342-9514",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=14",
                "https://senate.la.gov/smembers?ID=14"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-14-upper-ocd-person-b2eca8f2-6db8-4263-92a4-050e3819de53": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/8710f3da-9aea-41a9-acd2-181377cc6ae7",
            "firstName": "Regina Ashford",
            "lastName": "Barrow",
            "fullName": "Regina Barrow",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senate.la.gov/SenatorPics/Sen15.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-LA-15-upper-ocd-person-8710f3da-9aea-41a9-acd2-181377cc6ae7",
              "builtID": "la-upper-15",
              "externalID": "ocd-person/8710f3da-9aea-41a9-acd2-181377cc6ae7",
              "geometry": null
            },
            "contactInfo": {
              "email": "barrowr@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4811 Harding Blvd., Baton Rouge, LA 70811",
              "phone1": null,
              "phone2": "225-359-9400",
              "fax1": null,
              "fax2": "225-359-9402",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=15",
                "https://senate.la.gov/smembers?ID=15"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-15-upper-ocd-person-8710f3da-9aea-41a9-acd2-181377cc6ae7": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/a8344aaa-aff7-42b3-b1a2-59539191fbb2",
            "firstName": "Franklin Johnson",
            "lastName": "Foil",
            "fullName": "Franklin Foil",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen16.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-LA-16-upper-ocd-person-a8344aaa-aff7-42b3-b1a2-59539191fbb2",
              "builtID": "la-upper-16",
              "externalID": "ocd-person/a8344aaa-aff7-42b3-b1a2-59539191fbb2",
              "geometry": null
            },
            "contactInfo": {
              "email": "foilf@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "412 N. 4th St. Suite 230, Baton Rouge, LA 70802",
              "phone1": null,
              "phone2": "225-342-6777",
              "fax1": null,
              "fax2": "225-342-6785",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=70",
                "https://senate.la.gov/smembers.aspx?ID=16",
                "https://senate.la.gov/smembers?ID=16"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-16-upper-ocd-person-a8344aaa-aff7-42b3-b1a2-59539191fbb2": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/75f165af-66d1-464e-99b0-4176b8636501",
            "firstName": "Caleb Seth",
            "lastName": "Kleinpeter",
            "fullName": "Caleb Kleinpeter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen17.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-LA-17-upper-ocd-person-75f165af-66d1-464e-99b0-4176b8636501",
              "builtID": "la-upper-17",
              "externalID": "ocd-person/75f165af-66d1-464e-99b0-4176b8636501",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen17@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3741 Highway 1, Port Allen, LA 70767",
              "phone1": null,
              "phone2": "225-246-8838",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=17",
                "https://senate.la.gov/smembers?ID=17"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-17-upper-ocd-person-75f165af-66d1-464e-99b0-4176b8636501": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/c89a156e-8426-4001-8364-94a179482d76",
            "firstName": "Eddie Joseph",
            "lastName": "Lambert",
            "fullName": "Eddie Lambert",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen18.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-LA-18-upper-ocd-person-c89a156e-8426-4001-8364-94a179482d76",
              "builtID": "la-upper-18",
              "externalID": "ocd-person/c89a156e-8426-4001-8364-94a179482d76",
              "geometry": null
            },
            "contactInfo": {
              "email": "lamberte@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 241, Gonzales, LA 70707",
              "phone1": null,
              "phone2": "225-673-5048",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=18",
                "https://senate.la.gov/smembers?ID=18"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-18-upper-ocd-person-c89a156e-8426-4001-8364-94a179482d76": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/77166b5f-e299-431c-869d-ab2fff79cd33",
            "firstName": "Gregory Allen",
            "lastName": "Miller",
            "fullName": "Greg Miller",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenPics/Sen19.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-LA-19-upper-ocd-person-77166b5f-e299-431c-869d-ab2fff79cd33",
              "builtID": "la-upper-19",
              "externalID": "ocd-person/77166b5f-e299-431c-869d-ab2fff79cd33",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen19@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 190, Norco, LA 70079",
              "phone1": null,
              "phone2": "985-764-9991",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.la.gov/smembers?ID=19"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-19-upper-ocd-person-77166b5f-e299-431c-869d-ab2fff79cd33": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/750a2868-c9b0-4124-95c7-3516f93ff087",
            "firstName": "Michael",
            "lastName": "Fesi",
            "fullName": "Mike Fési",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen20.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-LA-20-upper-ocd-person-750a2868-c9b0-4124-95c7-3516f93ff087",
              "builtID": "la-upper-20",
              "externalID": "ocd-person/750a2868-c9b0-4124-95c7-3516f93ff087",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen20@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7910 W. Main St. Suite 430, Houma, LA 70360",
              "phone1": null,
              "phone2": "985-858-2979",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=20",
                "https://senate.la.gov/smembers?ID=20"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-20-upper-ocd-person-750a2868-c9b0-4124-95c7-3516f93ff087": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/655ddc35-ecd0-4db2-a3fe-cd86f1cb7d61",
            "firstName": "Robert",
            "lastName": "Allain",
            "fullName": "Robert Allain",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen21.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-LA-21-upper-ocd-person-655ddc35-ecd0-4db2-a3fe-cd86f1cb7d61",
              "builtID": "la-upper-21",
              "externalID": "ocd-person/655ddc35-ecd0-4db2-a3fe-cd86f1cb7d61",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen21@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "600 Main St., Franklin, LA 70538",
              "phone1": null,
              "phone2": "337-828-9107",
              "fax1": null,
              "fax2": "337-828-9108",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=21",
                "https://senate.la.gov/smembers?ID=21"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-21-upper-ocd-person-655ddc35-ecd0-4db2-a3fe-cd86f1cb7d61": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/8574f94d-93ed-49b0-a5f6-aa1832b5542a",
            "firstName": "Blake John",
            "lastName": "Miguez",
            "fullName": "Blake Miguez",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenPics/Sen22.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-LA-22-upper-ocd-person-8574f94d-93ed-49b0-a5f6-aa1832b5542a",
              "builtID": "la-upper-22",
              "externalID": "ocd-person/8574f94d-93ed-49b0-a5f6-aa1832b5542a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen22@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1101 E. Admiral Doyle Drive Suite 301-8, New Iberia, LA 70560",
              "phone1": null,
              "phone2": "337-373-9400",
              "fax1": null,
              "fax2": "337-373-9402",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=49",
                "https://senate.la.gov/smembers?ID=22"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-22-upper-ocd-person-8574f94d-93ed-49b0-a5f6-aa1832b5542a": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/302255c1-3261-4cc5-bdcd-4cea1aaa8f1a",
            "firstName": "Jean-Paul Philip",
            "lastName": "Coussan",
            "fullName": "J.P. Coussan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenPics/Sen23.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-LA-23-upper-ocd-person-302255c1-3261-4cc5-bdcd-4cea1aaa8f1a",
              "builtID": "la-upper-23",
              "externalID": "ocd-person/302255c1-3261-4cc5-bdcd-4cea1aaa8f1a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen23@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "101 W. Farrel Road Building 5, Suite 100, Lafayette, LA 70508",
              "phone1": null,
              "phone2": "337-315-9528",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.la.gov/smembers?ID=23"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-23-upper-ocd-person-302255c1-3261-4cc5-bdcd-4cea1aaa8f1a": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/379e9992-463c-4188-9702-668457a75c3d",
            "firstName": "Gerald",
            "lastName": "Boudreaux",
            "fullName": "Gerald Boudreaux",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senate.la.gov/SenatorPics/Sen24.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-LA-24-upper-ocd-person-379e9992-463c-4188-9702-668457a75c3d",
              "builtID": "la-upper-24",
              "externalID": "ocd-person/379e9992-463c-4188-9702-668457a75c3d",
              "geometry": null
            },
            "contactInfo": {
              "email": "boudreauxg@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 91245, Lafayette, LA 70509",
              "phone1": null,
              "phone2": "337-267-7520",
              "fax1": null,
              "fax2": "337-267-7522",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=24",
                "https://senate.la.gov/smembers?ID=24"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-24-upper-ocd-person-379e9992-463c-4188-9702-668457a75c3d": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/addae848-cfaf-4637-a23e-595e45c6e14f",
            "firstName": "Mark Thorpe",
            "lastName": "Abraham",
            "fullName": "Mark Abraham",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen25.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-LA-25-upper-ocd-person-addae848-cfaf-4637-a23e-595e45c6e14f",
              "builtID": "la-upper-25",
              "externalID": "ocd-person/addae848-cfaf-4637-a23e-595e45c6e14f",
              "geometry": null
            },
            "contactInfo": {
              "email": "abrahamm@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "130 Jamestown Road, Lake Charles, LA 70605",
              "phone1": null,
              "phone2": "337-475-3016",
              "fax1": null,
              "fax2": "337-475-3018",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=36",
                "https://senate.la.gov/smembers.aspx?ID=25",
                "https://senate.la.gov/smembers?ID=25"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-25-upper-ocd-person-addae848-cfaf-4637-a23e-595e45c6e14f": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/7dac3861-2ffb-45ba-b690-a8e199eb2acd",
            "firstName": "Craig Robert",
            "lastName": "Hensgens",
            "fullName": "Bob Hensgens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen26.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-LA-26-upper-ocd-person-7dac3861-2ffb-45ba-b690-a8e199eb2acd",
              "builtID": "la-upper-26",
              "externalID": "ocd-person/7dac3861-2ffb-45ba-b690-a8e199eb2acd",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen26@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "122 N. State St. Suite 100, Abbeville, LA 70510",
              "phone1": null,
              "phone2": "337-740-6425",
              "fax1": null,
              "fax2": "337-898-1160",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=26",
                "https://senate.la.gov/smembers?ID=26"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-26-upper-ocd-person-7dac3861-2ffb-45ba-b690-a8e199eb2acd": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/e8c847e2-5ee6-4f9c-a4e4-d7d8daa8464c",
            "firstName": "Jeremy P.",
            "lastName": "Stine",
            "fullName": "Jeremy Stine",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen27.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-LA-27-upper-ocd-person-e8c847e2-5ee6-4f9c-a4e4-d7d8daa8464c",
              "builtID": "la-upper-27",
              "externalID": "ocd-person/e8c847e2-5ee6-4f9c-a4e4-d7d8daa8464c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen27@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1011 Lakeshore Drive Suite 515, Lake Charles, LA 70601",
              "phone1": null,
              "phone2": "337-491-2016",
              "fax1": null,
              "fax2": "337-491-2984",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=27",
                "https://senate.la.gov/smembers?ID=27"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-27-upper-ocd-person-e8c847e2-5ee6-4f9c-a4e4-d7d8daa8464c": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/aa2da413-7e13-4c2b-93e8-784edc0a495e",
            "firstName": "Heather Miley",
            "lastName": "Cloud",
            "fullName": "Heather Cloud",
            "gender": "Female",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen28.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-LA-28-upper-ocd-person-aa2da413-7e13-4c2b-93e8-784edc0a495e",
              "builtID": "la-upper-28",
              "externalID": "ocd-person/aa2da413-7e13-4c2b-93e8-784edc0a495e",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen28@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 269, Turkey Creek, LA 70585",
              "phone1": null,
              "phone2": "337-461-2595",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=28",
                "https://senate.la.gov/smembers?ID=28"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-28-upper-ocd-person-aa2da413-7e13-4c2b-93e8-784edc0a495e": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/b4a4657a-e588-4036-9a0f-48847e22a140",
            "firstName": "Wendell Jay",
            "lastName": "Luneau",
            "fullName": "Jay Luneau",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senate.la.gov/SenatorPics/Sen29.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-LA-29-upper-ocd-person-b4a4657a-e588-4036-9a0f-48847e22a140",
              "builtID": "la-upper-29",
              "externalID": "ocd-person/b4a4657a-e588-4036-9a0f-48847e22a140",
              "geometry": null
            },
            "contactInfo": {
              "email": "luneauj@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1010 Main St., Pineville, LA 71360",
              "phone1": null,
              "phone2": "318-484-2288",
              "fax1": null,
              "fax2": "318-484-2287",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=29",
                "https://senate.la.gov/smembers?ID=29"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-29-upper-ocd-person-b4a4657a-e588-4036-9a0f-48847e22a140": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/723f3444-f927-44b0-8659-7ac00370aa92",
            "firstName": "Mike",
            "lastName": "Reese",
            "fullName": "Mike Reese",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen30.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-LA-30-upper-ocd-person-723f3444-f927-44b0-8659-7ac00370aa92",
              "builtID": "la-upper-30",
              "externalID": "ocd-person/723f3444-f927-44b0-8659-7ac00370aa92",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen30@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "401 W. 1st St., DeRidder, LA 70634",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=30",
                "https://senate.la.gov/smembers?ID=30"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-30-upper-ocd-person-723f3444-f927-44b0-8659-7ac00370aa92": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/5032448a-7275-4a02-8dc1-d251cdde8ad9",
            "firstName": "Alan Thomas",
            "lastName": "Seabaugh",
            "fullName": "Alan Seabaugh",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenPics/Sen31.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-LA-31-upper-ocd-person-5032448a-7275-4a02-8dc1-d251cdde8ad9",
              "builtID": "la-upper-31",
              "externalID": "ocd-person/5032448a-7275-4a02-8dc1-d251cdde8ad9",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen31@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "401 Market St. Suite 1150, Shreveport, LA 71101",
              "phone1": null,
              "phone2": "318-676-7990",
              "fax1": null,
              "fax2": "318-676-7992",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=5",
                "https://senate.la.gov/smembers?ID=31"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-31-upper-ocd-person-5032448a-7275-4a02-8dc1-d251cdde8ad9": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/25923053-3679-443e-bbb1-e2f63a50585c",
            "firstName": "Glen D.",
            "lastName": "Womack",
            "fullName": "Glen Womack",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen32.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-LA-32-upper-ocd-person-25923053-3679-443e-bbb1-e2f63a50585c",
              "builtID": "la-upper-32",
              "externalID": "ocd-person/25923053-3679-443e-bbb1-e2f63a50585c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen32@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 660 119 Pine St., Suite B, Harrisonburg, LA 71340",
              "phone1": null,
              "phone2": "318-744-0005",
              "fax1": null,
              "fax2": "318-744-0007",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=32",
                "https://senate.la.gov/smembers?ID=32"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-32-upper-ocd-person-25923053-3679-443e-bbb1-e2f63a50585c": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/dd47283c-c202-461f-b56a-8cc2c0b14f15",
            "firstName": "Stewart",
            "lastName": "Cathey",
            "fullName": "Stewart Cathey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen33.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-LA-33-upper-ocd-person-dd47283c-c202-461f-b56a-8cc2c0b14f15",
              "builtID": "la-upper-33",
              "externalID": "ocd-person/dd47283c-c202-461f-b56a-8cc2c0b14f15",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen33@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "209 N. Park Drive, Monroe, LA 71203",
              "phone1": null,
              "phone2": "318-582-5524",
              "fax1": null,
              "fax2": "318-582-5526",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=33",
                "https://senate.la.gov/smembers?ID=33"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-33-upper-ocd-person-dd47283c-c202-461f-b56a-8cc2c0b14f15": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/acdaa5e6-3b22-44b9-8b47-480b801483dd",
            "firstName": "Katrina Renee",
            "lastName": "Jackson-Andrews",
            "fullName": "Katrina Jackson-Andrews",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senate.la.gov/SenatorPics/Sen34.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-LA-34-upper-ocd-person-acdaa5e6-3b22-44b9-8b47-480b801483dd",
              "builtID": "la-upper-34",
              "externalID": "ocd-person/acdaa5e6-3b22-44b9-8b47-480b801483dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "jacksonk@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4106 DeSiard St., Monroe, LA 71203",
              "phone1": null,
              "phone2": "318-343-2877",
              "fax1": null,
              "fax2": "318-343-2879",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.la.gov/smembers.aspx?ID=34",
                "https://senate.la.gov/smembers?ID=34"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-34-upper-ocd-person-acdaa5e6-3b22-44b9-8b47-480b801483dd": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/9d6aa1d9-76b2-41ad-a3e0-4fa86da08645",
            "firstName": "John Clyde",
            "lastName": "Morris",
            "fullName": "Jay Morris",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenatorPics/Sen35.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-LA-35-upper-ocd-person-9d6aa1d9-76b2-41ad-a3e0-4fa86da08645",
              "builtID": "la-upper-35",
              "externalID": "ocd-person/9d6aa1d9-76b2-41ad-a3e0-4fa86da08645",
              "geometry": null
            },
            "contactInfo": {
              "email": "morrisjc@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1408 N. 7th St., West Monroe, LA 71291",
              "phone1": null,
              "phone2": "318-362-4270",
              "fax1": null,
              "fax2": "318-362-4277",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.louisiana.gov/H_Reps/members.aspx?ID=14",
                "https://senate.la.gov/smembers.aspx?ID=35",
                "https://senate.la.gov/smembers?ID=35"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-35-upper-ocd-person-9d6aa1d9-76b2-41ad-a3e0-4fa86da08645": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/0d1a7b74-9ed8-4d8c-a1fc-f2caafc3d364",
            "firstName": "Jesse Adam",
            "lastName": "Bass",
            "fullName": "Adam Bass",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenPics/Sen36.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-LA-36-upper-ocd-person-0d1a7b74-9ed8-4d8c-a1fc-f2caafc3d364",
              "builtID": "la-upper-36",
              "externalID": "ocd-person/0d1a7b74-9ed8-4d8c-a1fc-f2caafc3d364",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen36@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2123 Shed Road, Suite 5, Bossier City, LA 71111",
              "phone1": null,
              "phone2": "318-741-2812",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.la.gov/smembers?ID=36"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-36-upper-ocd-person-0d1a7b74-9ed8-4d8c-a1fc-f2caafc3d364": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/20547e38-711f-4012-9217-b739f4450b91",
            "firstName": "William",
            "lastName": "Wheat",
            "fullName": "Bill Wheat",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenPics/Sen37.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-LA-37-upper-ocd-person-20547e38-711f-4012-9217-b739f4450b91",
              "builtID": "la-upper-37",
              "externalID": "ocd-person/20547e38-711f-4012-9217-b739f4450b91",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen37@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 2473, Ponchatoula, LA 70454",
              "phone1": null,
              "phone2": "985-386-7844",
              "fax1": null,
              "fax2": "985-386-5669",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.la.gov/smembers?ID=37"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-37-upper-ocd-person-20547e38-711f-4012-9217-b739f4450b91": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/577fe2a3-275b-4b82-b508-c0ce29deb2fa",
            "firstName": "Thomas Alexander",
            "lastName": "Pressly",
            "fullName": "Thomas Pressly",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.la.gov/SenPics/Sen38.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-LA-38-upper-ocd-person-577fe2a3-275b-4b82-b508-c0ce29deb2fa",
              "builtID": "la-upper-38",
              "externalID": "ocd-person/577fe2a3-275b-4b82-b508-c0ce29deb2fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen38@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "900 Pierremont Road Suite 119, Shreveport, LA 71106",
              "phone1": null,
              "phone2": "318-862-9920",
              "fax1": null,
              "fax2": "318-862-9922",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.la.gov/smembers?ID=38"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-38-upper-ocd-person-577fe2a3-275b-4b82-b508-c0ce29deb2fa": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/fc43b9e1-5e0d-4767-a12e-b2e801444d22",
            "firstName": "Samuel Lee",
            "lastName": "Jenkins",
            "fullName": "Sam Jenkins",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senate.la.gov/SenPics/Sen39.jpg",
            "title": "LA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "LA",
              "stateFull": "Louisiana",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-LA-39-upper-ocd-person-fc43b9e1-5e0d-4767-a12e-b2e801444d22",
              "builtID": "la-upper-39",
              "externalID": "ocd-person/fc43b9e1-5e0d-4767-a12e-b2e801444d22",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen39@legis.la.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2419 Kings Highway, Shreveport, LA 71103",
              "phone1": null,
              "phone2": "318-632-5970",
              "fax1": null,
              "fax2": "318-632-5972",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.la.gov/smembers?ID=39"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-LA-39-upper-ocd-person-fc43b9e1-5e0d-4767-a12e-b2e801444d22": 0
        }
      }
    }
  },
  "ME": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "ME",
          "stateFull": "Maine",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "ME",
            "stateFull": "Maine",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-ME---",
            "builtID": "me--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-ME---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "C001035",
          "in_office": true,
          "firstName": "Susan",
          "lastName": "Collins",
          "middleName": null,
          "fullName": "Susan M. Collins",
          "gender": "F",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/C001035.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "ME",
            "stateFull": "Maine",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-ME---C001035",
            "builtID": "me--",
            "externalID": "C001035",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.collins.senate.gov/contact",
            "address1": "413 Dirksen Senate Office Building",
            "address2": null,
            "phone1": "202-224-2523",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorCollins",
            "youtube": "SenatorSusanCollins",
            "instagram": null,
            "facebook": "susancollins",
            "urls": ["https://www.collins.senate.gov"],
            "rss_url": "https://www.collins.senate.gov/?q=rss.xml"
          },
          "title": "ME Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/C001035.json",
          "govtrack_id": "300025",
          "cspan_id": "45738",
          "votesmart_id": "379",
          "icpsr_id": "49703",
          "crp_id": "N00000491",
          "google_entity_id": "/m/020y8m",
          "state_rank": "senior",
          "lis_id": "S252",
          "suffix": null,
          "date_of_birth": "1952-12-07",
          "leadership_role": null,
          "fec_candidate_id": "S6ME00159",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "27",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 0,
          "total_present": 0,
          "last_updated": "2024-06-20 15:30:29 -0400",
          "missed_votes_pct": 0,
          "votes_with_party_pct": 51.58,
          "votes_against_party_pct": 48.42,
          "ocd_id": "ocd-division/country:us/state:me"
        },
        {
          "API_ID": "K000383",
          "in_office": true,
          "firstName": "Angus",
          "lastName": "King",
          "middleName": null,
          "fullName": "Angus S. King Jr.",
          "gender": "M",
          "party": "Independent",
          "image": "https://theunitedstates.io/images/congress/225x275/K000383.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "ME",
            "stateFull": "Maine",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-ME---K000383",
            "builtID": "me--",
            "externalID": "K000383",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.king.senate.gov/contact",
            "address1": "133 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-5344",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenAngusKing",
            "youtube": "SenatorAngusKing",
            "instagram": null,
            "facebook": "SenatorAngusSKingJr",
            "urls": ["https://www.king.senate.gov"],
            "rss_url": null
          },
          "title": "ME Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/K000383.json",
          "govtrack_id": "412545",
          "cspan_id": "37413",
          "votesmart_id": "22381",
          "icpsr_id": "41300",
          "crp_id": "N00034580",
          "google_entity_id": "/m/02hfx0",
          "state_rank": "junior",
          "lis_id": "S363",
          "suffix": null,
          "date_of_birth": "1944-03-31",
          "leadership_role": null,
          "fec_candidate_id": "S2ME00109",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "11",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 13,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 2.35,
          "votes_with_party_pct": 98.14,
          "votes_against_party_pct": 1.86,
          "ocd_id": "ocd-division/country:us/state:me"
        }
      ],
      "hash": { "SENATE-ME---C001035": 0, "SENATE-ME---K000383": 1 }
    },
    "HOUSE": {
      "02": {
        "members": [
          {
            "API_ID": "G000592",
            "in_office": true,
            "firstName": "Jared",
            "lastName": "Golden",
            "middleName": null,
            "fullName": "Jared F. Golden",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/G000592.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-ME-02--G000592",
              "builtID": "me--02",
              "externalID": "G000592",
              "geometry": null,
              "geoid": "2302"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1710 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-6306",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repgolden",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://golden.house.gov"],
              "rss_url": null
            },
            "title": "ME House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000592.json",
            "govtrack_id": "412842",
            "cspan_id": null,
            "votesmart_id": "151420",
            "icpsr_id": null,
            "crp_id": "N00041668",
            "google_entity_id": "/g/11f1224576",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1982-07-25",
            "leadership_role": null,
            "fec_candidate_id": "H8ME02185",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 55,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 5.21,
            "votes_with_party_pct": 79.03,
            "votes_against_party_pct": 18.95,
            "ocd_id": "ocd-division/country:us/state:me/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-ME-02--G000592": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "P000597",
            "in_office": true,
            "firstName": "Chellie",
            "lastName": "Pingree",
            "middleName": null,
            "fullName": "Chellie Pingree",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/P000597.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-ME-01--P000597",
              "builtID": "me--01",
              "externalID": "P000597",
              "geometry": null,
              "geoid": "2301"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2354 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6116",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "ChelliePingree",
              "youtube": "congresswomanpingree",
              "instagram": null,
              "facebook": "ChelliePingree",
              "urls": ["https://pingree.house.gov"],
              "rss_url": null
            },
            "title": "ME House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000597.json",
            "govtrack_id": "412307",
            "cspan_id": "1002167",
            "votesmart_id": "6586",
            "icpsr_id": "20920",
            "crp_id": "N00013817",
            "google_entity_id": "/m/05gflq",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1955-04-02",
            "leadership_role": null,
            "fec_candidate_id": "H8ME01120",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "16",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 57,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 5.4,
            "votes_with_party_pct": 95.25,
            "votes_against_party_pct": 2.83,
            "ocd_id": "ocd-division/country:us/state:me/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-ME-01--P000597": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/7d7f8943-3838-4a5e-87f9-cb5cfc894c10",
            "firstName": "Austin Leo",
            "lastName": "Theriault",
            "fullName": "Austin Theriault",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/d5c9b049-28da-4f9a-8248-aa4d7e022268_THERIAULT.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-ME-1-lower-ocd-person-7d7f8943-3838-4a5e-87f9-cb5cfc894c10",
              "builtID": "me-lower-1",
              "externalID": "ocd-person/7d7f8943-3838-4a5e-87f9-cb5cfc894c10",
              "geometry": null
            },
            "contactInfo": {
              "email": "austin.theriault@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1453",
                "https://mainehousegop.org/members/theriault-austin/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-1-lower-ocd-person-7d7f8943-3838-4a5e-87f9-cb5cfc894c10": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/c76a67f9-4d87-4a73-8d89-eea7cd8a3a98",
            "firstName": "Roger Clarence",
            "lastName": "Albert",
            "fullName": "Roger Albert",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/ce15102a-72b1-4d16-adcc-ec90fdd077e0_ALBERT.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-ME-2-lower-ocd-person-c76a67f9-4d87-4a73-8d89-eea7cd8a3a98",
              "builtID": "me-lower-2",
              "externalID": "ocd-person/c76a67f9-4d87-4a73-8d89-eea7cd8a3a98",
              "geometry": null
            },
            "contactInfo": {
              "email": "roger.albert@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1454",
                "https://mainehousegop.org/members/albert-roger/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-2-lower-ocd-person-c76a67f9-4d87-4a73-8d89-eea7cd8a3a98": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/a3a29593-781a-462d-93cc-685be03b3b56",
            "firstName": "Mark Michael",
            "lastName": "Babin",
            "fullName": "Mark Babin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/05fa532f-1a27-4521-af69-ceecf5c3311e_BABIN.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-ME-3-lower-ocd-person-a3a29593-781a-462d-93cc-685be03b3b56",
              "builtID": "me-lower-3",
              "externalID": "ocd-person/a3a29593-781a-462d-93cc-685be03b3b56",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.babin@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1455",
                "https://mainehousegop.org/members/babin-mark/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-3-lower-ocd-person-a3a29593-781a-462d-93cc-685be03b3b56": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/2e5b6467-1014-4771-9aae-2c401029ed01",
            "firstName": "Timothy C.",
            "lastName": "Guerrette",
            "fullName": "Timmy Guerrette",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/140ecd56-dda0-495a-8f55-a372f209dcea_TGUERRETTE.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-ME-4-lower-ocd-person-2e5b6467-1014-4771-9aae-2c401029ed01",
              "builtID": "me-lower-4",
              "externalID": "ocd-person/2e5b6467-1014-4771-9aae-2c401029ed01",
              "geometry": null
            },
            "contactInfo": {
              "email": "timothy.guerrette@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1456",
                "https://mainehousegop.org/members/guerrette-timothy/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-4-lower-ocd-person-2e5b6467-1014-4771-9aae-2c401029ed01": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/b6102f7e-216a-4632-901c-25b2b446f080",
            "firstName": "Joseph Frederick",
            "lastName": "Underwood",
            "fullName": "Joseph Underwood",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/5f2f5dbd-c70a-49a9-87bd-de2f2a42cfc5_UnderwoodNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-ME-5-lower-ocd-person-b6102f7e-216a-4632-901c-25b2b446f080",
              "builtID": "me-lower-5",
              "externalID": "ocd-person/b6102f7e-216a-4632-901c-25b2b446f080",
              "geometry": null
            },
            "contactInfo": {
              "email": "joseph.underwood@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1444",
                "https://mainehousegop.org/members/underwood-joseph/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-5-lower-ocd-person-b6102f7e-216a-4632-901c-25b2b446f080": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/1b986e08-1270-49ba-b6d0-941a950d3008",
            "firstName": "Donald J.",
            "lastName": "Ardell",
            "fullName": "Donald Ardell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/3bf42df4-0f16-418b-ad61-621fd8c4c1e0_ARDELL.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-ME-6-lower-ocd-person-1b986e08-1270-49ba-b6d0-941a950d3008",
              "builtID": "me-lower-6",
              "externalID": "ocd-person/1b986e08-1270-49ba-b6d0-941a950d3008",
              "geometry": null
            },
            "contactInfo": {
              "email": "donald.ardell@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1457",
                "https://mainehousegop.org/members/ardell-donald/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-6-lower-ocd-person-1b986e08-1270-49ba-b6d0-941a950d3008": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/90989c27-4cd8-409e-8325-9bd50dac9cf4",
            "firstName": "Gregory Lewis",
            "lastName": "Swallow",
            "fullName": "Gregory Swallow",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/e42361cf-f827-42a9-85de-64b34fc21ab3_swallow.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-ME-7-lower-ocd-person-90989c27-4cd8-409e-8325-9bd50dac9cf4",
              "builtID": "me-lower-7",
              "externalID": "ocd-person/90989c27-4cd8-409e-8325-9bd50dac9cf4",
              "geometry": null
            },
            "contactInfo": {
              "email": "gregory.swallow@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1381",
                "https://mainehousegop.org/members/swallow-gregory/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-7-lower-ocd-person-90989c27-4cd8-409e-8325-9bd50dac9cf4": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/3853d2fe-2580-4041-abb1-b4136172e9c0",
            "firstName": "Tracy L.",
            "lastName": "Quint",
            "fullName": "Tracy Quint",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/a2caf281-9523-418e-b5a2-1a0635438985_QuintNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-ME-8-lower-ocd-person-3853d2fe-2580-4041-abb1-b4136172e9c0",
              "builtID": "me-lower-8",
              "externalID": "ocd-person/3853d2fe-2580-4041-abb1-b4136172e9c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "tracy.quint@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1443",
                "https://mainehousegop.org/members/quint-tracy/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-8-lower-ocd-person-3853d2fe-2580-4041-abb1-b4136172e9c0": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/b77ec17e-16c2-4958-bbaf-150ea6fb5467",
            "firstName": "Anne Churchill",
            "lastName": "Perry",
            "fullName": "Anne Perry",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/907f0f6c-f076-444f-9678-f869ece743c7_perrac[1].jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-ME-9-lower-ocd-person-b77ec17e-16c2-4958-bbaf-150ea6fb5467",
              "builtID": "me-lower-9",
              "externalID": "ocd-person/b77ec17e-16c2-4958-bbaf-150ea6fb5467",
              "geometry": null
            },
            "contactInfo": {
              "email": "anne.perry@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/housedems/perrya/index.html",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/126"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-9-lower-ocd-person-b77ec17e-16c2-4958-bbaf-150ea6fb5467": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/e9dda9c3-a2a1-46e4-bd3f-0268f36b6557",
            "firstName": "Kenneth Ralph",
            "lastName": "Davis",
            "fullName": "Ken Davis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/f779a917-9e9e-4c7b-86a6-c75fca9036c8_DAVIS.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-ME-10-lower-ocd-person-e9dda9c3-a2a1-46e4-bd3f-0268f36b6557",
              "builtID": "me-lower-10",
              "externalID": "ocd-person/e9dda9c3-a2a1-46e4-bd3f-0268f36b6557",
              "geometry": null
            },
            "contactInfo": {
              "email": "kenneth.davis@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1458",
                "https://mainehousegop.org/members/davis-kenneth/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-10-lower-ocd-person-e9dda9c3-a2a1-46e4-bd3f-0268f36b6557": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/2ffd13d7-b22a-4bea-8bc0-eaa6c0a53c17",
            "firstName": "Tiffany Pinkham",
            "lastName": "Strout",
            "fullName": "Tiffany Strout",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/0cea9b69-973d-411d-bbd7-b5c6e1e6d559_TSTROUT.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-ME-11-lower-ocd-person-2ffd13d7-b22a-4bea-8bc0-eaa6c0a53c17",
              "builtID": "me-lower-11",
              "externalID": "ocd-person/2ffd13d7-b22a-4bea-8bc0-eaa6c0a53c17",
              "geometry": null
            },
            "contactInfo": {
              "email": "tiffany.strout@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1459",
                "https://mainehousegop.org/members/strout-tiffany/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-11-lower-ocd-person-2ffd13d7-b22a-4bea-8bc0-eaa6c0a53c17": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/43c2650b-6248-46f9-a553-8c2bd0bab294",
            "firstName": "William R.",
            "lastName": "Faulkingham",
            "fullName": "Billy Bob Faulkingham",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/b1dc14b4-920f-4f14-99c9-bce559bceb76_FaulkinghamNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-ME-12-lower-ocd-person-43c2650b-6248-46f9-a553-8c2bd0bab294",
              "builtID": "me-lower-12",
              "externalID": "ocd-person/43c2650b-6248-46f9-a553-8c2bd0bab294",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.faulkingham@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1379",
                "https://mainehousegop.org/members/faulkingham-billy-bob/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-12-lower-ocd-person-43c2650b-6248-46f9-a553-8c2bd0bab294": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/ba1384b3-1c88-4b66-8c97-f94d038f372c",
            "firstName": "James Mark",
            "lastName": "Worth",
            "fullName": "James Worth",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/6b46df5b-47f1-4a54-80d9-ac5c6289711d_WORTH.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-ME-13-lower-ocd-person-ba1384b3-1c88-4b66-8c97-f94d038f372c",
              "builtID": "me-lower-13",
              "externalID": "ocd-person/ba1384b3-1c88-4b66-8c97-f94d038f372c",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.worth@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1460",
                "https://legislature.maine.gov/housedems/worth/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-13-lower-ocd-person-ba1384b3-1c88-4b66-8c97-f94d038f372c": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/cd08563e-987c-4c06-9487-4a22015e18a9",
            "firstName": "Holly Rae",
            "lastName": "Eaton",
            "fullName": "Holly Eaton",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/56d51271-b0dc-43ea-8f70-423d544537d5_EATON.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-ME-15-lower-ocd-person-cd08563e-987c-4c06-9487-4a22015e18a9",
              "builtID": "me-lower-15",
              "externalID": "ocd-person/cd08563e-987c-4c06-9487-4a22015e18a9",
              "geometry": null
            },
            "contactInfo": {
              "email": "holly.eaton@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1461",
                "https://legislature.maine.gov/housedems/eaton/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-15-lower-ocd-person-cd08563e-987c-4c06-9487-4a22015e18a9": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/a1ab39cd-e95b-4f16-9fdc-9f7d2685fc45",
            "firstName": "Nina Azella",
            "lastName": "Milliken",
            "fullName": "Nina Milliken",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/d61581cd-0cf9-4ffa-a29e-f767f25dec5f_NMILLIKEN.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-ME-16-lower-ocd-person-a1ab39cd-e95b-4f16-9fdc-9f7d2685fc45",
              "builtID": "me-lower-16",
              "externalID": "ocd-person/a1ab39cd-e95b-4f16-9fdc-9f7d2685fc45",
              "geometry": null
            },
            "contactInfo": {
              "email": "nina.milliken@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1462",
                "https://legislature.maine.gov/housedems/milliken/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-16-lower-ocd-person-a1ab39cd-e95b-4f16-9fdc-9f7d2685fc45": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/e50b3c92-f48c-41f9-a719-bb82edf5d91a",
            "firstName": "Ronald B.",
            "lastName": "Russell",
            "fullName": "Ron Russell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/14029c94-7025-48fd-8a1b-878b3b7475e9_RUSSELL.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-ME-17-lower-ocd-person-e50b3c92-f48c-41f9-a719-bb82edf5d91a",
              "builtID": "me-lower-17",
              "externalID": "ocd-person/e50b3c92-f48c-41f9-a719-bb82edf5d91a",
              "geometry": null
            },
            "contactInfo": {
              "email": "ronald.russell@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1463",
                "https://legislature.maine.gov/housedems/russell/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-17-lower-ocd-person-e50b3c92-f48c-41f9-a719-bb82edf5d91a": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/af7c00a7-4add-4db0-9bf9-5a1c7f5bf49d",
            "firstName": "Meldon H.",
            "lastName": "Carmichael",
            "fullName": "Micky Carmichael",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/6590363e-edfd-4acf-b8ed-67792095f7e8_CarmichaelNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-ME-18-lower-ocd-person-af7c00a7-4add-4db0-9bf9-5a1c7f5bf49d",
              "builtID": "me-lower-18",
              "externalID": "ocd-person/af7c00a7-4add-4db0-9bf9-5a1c7f5bf49d",
              "geometry": null
            },
            "contactInfo": {
              "email": "meldon.carmichael@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1441",
                "https://mainehousegop.org/members/carmichael-micky/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-18-lower-ocd-person-af7c00a7-4add-4db0-9bf9-5a1c7f5bf49d": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/0f9ba178-89fb-439e-afa2-c327414d966d",
            "firstName": "Richard H.",
            "lastName": "Campbell",
            "fullName": "Dick Campbell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/8be4f86f-6d8b-4193-a213-a97eb6b5ac5b_camprh.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-ME-19-lower-ocd-person-0f9ba178-89fb-439e-afa2-c327414d966d",
              "builtID": "me-lower-19",
              "externalID": "ocd-person/0f9ba178-89fb-439e-afa2-c327414d966d",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.campbell@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/21",
                "https://mainehousegop.org/members/campbell-richard/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-19-lower-ocd-person-0f9ba178-89fb-439e-afa2-c327414d966d": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/f5d4f6e4-29e2-42a8-b0b0-b2bf3ff47afd",
            "firstName": "Kevin J.M.",
            "lastName": "O'Connell",
            "fullName": "Kevin O'Connell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/7f84a161-28af-47bc-8b38-dc4fb205b35f_KOConnell2020.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-ME-20-lower-ocd-person-f5d4f6e4-29e2-42a8-b0b0-b2bf3ff47afd",
              "builtID": "me-lower-20",
              "externalID": "ocd-person/f5d4f6e4-29e2-42a8-b0b0-b2bf3ff47afd",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.oconnell@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1405"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-20-lower-ocd-person-f5d4f6e4-29e2-42a8-b0b0-b2bf3ff47afd": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/8f61f208-cf99-489a-aa36-847438b0ed60",
            "firstName": "Ambureen",
            "lastName": "Rana",
            "fullName": "Ambureen Rana",
            "gender": "X",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/1f89f046-4256-4341-9236-68c67e6efd77_ARANA.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-ME-21-lower-ocd-person-8f61f208-cf99-489a-aa36-847438b0ed60",
              "builtID": "me-lower-21",
              "externalID": "ocd-person/8f61f208-cf99-489a-aa36-847438b0ed60",
              "geometry": null
            },
            "contactInfo": {
              "email": "ambureen.rana@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1464"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-21-lower-ocd-person-8f61f208-cf99-489a-aa36-847438b0ed60": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/a65cc42d-ae91-4f47-8269-4673f106682f",
            "firstName": "Laura D.",
            "lastName": "Supica",
            "fullName": "Laura Supica",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/1a8dbf21-c24a-4e1c-89e3-53030c1ecfe5_Supica.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-ME-22-lower-ocd-person-a65cc42d-ae91-4f47-8269-4673f106682f",
              "builtID": "me-lower-22",
              "externalID": "ocd-person/a65cc42d-ae91-4f47-8269-4673f106682f",
              "geometry": null
            },
            "contactInfo": {
              "email": "laura.supica@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1438",
                "https://legislature.maine.gov/housedems/supical/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-22-lower-ocd-person-a65cc42d-ae91-4f47-8269-4673f106682f": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/42c79801-802e-4f3d-8917-f3f956766e50",
            "firstName": "Amy J.",
            "lastName": "Roeder",
            "fullName": "Amy Roeder",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/3265b5dc-e744-46e5-8ff5-317b5e4842ae_RoederNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-ME-23-lower-ocd-person-42c79801-802e-4f3d-8917-f3f956766e50",
              "builtID": "me-lower-23",
              "externalID": "ocd-person/42c79801-802e-4f3d-8917-f3f956766e50",
              "geometry": null
            },
            "contactInfo": {
              "email": "amy.roeder@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1437",
                "https://legislature.maine.gov/housedems/roedera/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-23-lower-ocd-person-42c79801-802e-4f3d-8917-f3f956766e50": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/2ae3e4e2-70c7-4855-bcd3-fab9b0da5c09",
            "firstName": "Joseph C.",
            "lastName": "Perry",
            "fullName": "Joe Perry",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/37c627f4-f72a-4db9-990f-0236f34ef452_perryjc-2019.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-ME-24-lower-ocd-person-2ae3e4e2-70c7-4855-bcd3-fab9b0da5c09",
              "builtID": "me-lower-24",
              "externalID": "ocd-person/2ae3e4e2-70c7-4855-bcd3-fab9b0da5c09",
              "geometry": null
            },
            "contactInfo": {
              "email": "joseph.perry@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/559"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-24-lower-ocd-person-2ae3e4e2-70c7-4855-bcd3-fab9b0da5c09": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/051d0089-f1cc-4523-a48d-ae98700165f8",
            "firstName": "Laurie",
            "lastName": "Osher",
            "fullName": "Laurie Osher",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/62af6ace-cea8-45d3-b863-ada1f65c3a25_Osher.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-ME-25-lower-ocd-person-051d0089-f1cc-4523-a48d-ae98700165f8",
              "builtID": "me-lower-25",
              "externalID": "ocd-person/051d0089-f1cc-4523-a48d-ae98700165f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "laurie.osher@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1436",
                "https://legislature.maine.gov/housedems/osherl/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-25-lower-ocd-person-051d0089-f1cc-4523-a48d-ae98700165f8": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/a3281b8a-e758-4804-a964-e8db96d043a8",
            "firstName": "James F.",
            "lastName": "Dill",
            "fullName": "Jim Dill",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/dill-5x7.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-ME-26-lower-ocd-person-a3281b8a-e758-4804-a964-e8db96d043a8",
              "builtID": "me-lower-26",
              "externalID": "ocd-person/a3281b8a-e758-4804-a964-e8db96d043a8",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.dill@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-5",
                "https://legislature.maine.gov/District-5",
                "https://www.mainesenate.org/senator/senator/jim-dill/",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/253"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-26-lower-ocd-person-a3281b8a-e758-4804-a964-e8db96d043a8": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/7893adc7-d4fb-42e4-bae0-1258ce673096",
            "firstName": "Gary",
            "lastName": "Drinkwater",
            "fullName": "Gary Drinkwater",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/3b421f7d-d986-4022-886f-90d3f5e19f79_drinkwater.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-ME-27-lower-ocd-person-7893adc7-d4fb-42e4-bae0-1258ce673096",
              "builtID": "me-lower-27",
              "externalID": "ocd-person/7893adc7-d4fb-42e4-bae0-1258ce673096",
              "geometry": null
            },
            "contactInfo": {
              "email": "gary.drinkwater@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1376",
                "https://mainehousegop.org/members/drinkwater-gary/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-27-lower-ocd-person-7893adc7-d4fb-42e4-bae0-1258ce673096": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/e603c478-27c7-4f68-ba31-4ef3732ab916",
            "firstName": "Irene A.",
            "lastName": "Gifford",
            "fullName": "Irene Gifford",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/af5019e3-ac57-47c4-bc0e-b9eb39973a6f_GIFFORD.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-ME-28-lower-ocd-person-e603c478-27c7-4f68-ba31-4ef3732ab916",
              "builtID": "me-lower-28",
              "externalID": "ocd-person/e603c478-27c7-4f68-ba31-4ef3732ab916",
              "geometry": null
            },
            "contactInfo": {
              "email": "irene.gifford@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1465",
                "https://mainehousegop.org/members/gifford-irene/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-28-lower-ocd-person-e603c478-27c7-4f68-ba31-4ef3732ab916": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/8eebfb56-a801-4ce3-81e3-c1c039cd1381",
            "firstName": "Kathy Irene",
            "lastName": "Javner",
            "fullName": "Kathy Javner",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/2aa9f321-d6bb-4ed8-9baa-56c3094d0b53_Javner.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-ME-29-lower-ocd-person-8eebfb56-a801-4ce3-81e3-c1c039cd1381",
              "builtID": "me-lower-29",
              "externalID": "ocd-person/8eebfb56-a801-4ce3-81e3-c1c039cd1381",
              "geometry": null
            },
            "contactInfo": {
              "email": "kathy.javner@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1380",
                "https://mainehousegop.org/members/javner-kathy/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-29-lower-ocd-person-8eebfb56-a801-4ce3-81e3-c1c039cd1381": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/54f9af68-0a59-4cd7-a448-40725e8781ab",
            "firstName": "James Lee",
            "lastName": "White",
            "fullName": "Jim White",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/4f4a6b7f-2c72-4910-848e-2f14ebf20832_WHITEJ.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-ME-30-lower-ocd-person-54f9af68-0a59-4cd7-a448-40725e8781ab",
              "builtID": "me-lower-30",
              "externalID": "ocd-person/54f9af68-0a59-4cd7-a448-40725e8781ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.white@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1466",
                "https://mainehousegop.org/members/white-james/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-30-lower-ocd-person-54f9af68-0a59-4cd7-a448-40725e8781ab": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/1793f579-da58-422f-bc22-36da20ce246c",
            "firstName": "Chad Richard",
            "lastName": "Perkins",
            "fullName": "Chad Perkins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/a6f40ce5-a695-480d-92cf-cfe044f027ac_CPERKINS.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-ME-31-lower-ocd-person-1793f579-da58-422f-bc22-36da20ce246c",
              "builtID": "me-lower-31",
              "externalID": "ocd-person/1793f579-da58-422f-bc22-36da20ce246c",
              "geometry": null
            },
            "contactInfo": {
              "email": "chad.perkins@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1467",
                "https://mainehousegop.org/members/perkins-chad/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-31-lower-ocd-person-1793f579-da58-422f-bc22-36da20ce246c": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/7d5a163c-a37a-48be-9a3f-2e4b54e3fdd6",
            "firstName": "Steven D.",
            "lastName": "Foster",
            "fullName": "Steven Foster",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/dc1e3209-322d-403d-b1b6-6290ee06199b_Foster.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-ME-32-lower-ocd-person-7d5a163c-a37a-48be-9a3f-2e4b54e3fdd6",
              "builtID": "me-lower-32",
              "externalID": "ocd-person/7d5a163c-a37a-48be-9a3f-2e4b54e3fdd6",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.foster@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1370",
                "https://mainehousegop.org/members/foster-steven/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-32-lower-ocd-person-7d5a163c-a37a-48be-9a3f-2e4b54e3fdd6": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/a93d36ff-25db-4f7f-b28c-7b3c00867c3c",
            "firstName": "Danny Edward",
            "lastName": "Costain",
            "fullName": "Dan Costain",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/32aa1842-cc09-4028-80c3-99652ef25ad3_CostainNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-ME-33-lower-ocd-person-a93d36ff-25db-4f7f-b28c-7b3c00867c3c",
              "builtID": "me-lower-33",
              "externalID": "ocd-person/a93d36ff-25db-4f7f-b28c-7b3c00867c3c",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.costain@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1368",
                "https://mainehousegop.org/members/danny-costain/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-33-lower-ocd-person-a93d36ff-25db-4f7f-b28c-7b3c00867c3c": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/513edd6e-0d85-4ad6-8852-577cd5c0ee02",
            "firstName": "Abigail W.",
            "lastName": "Griffin",
            "fullName": "Abigail Griffin",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/fa129957-8083-482b-93f5-318d0cfc0718_griffin.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-ME-34-lower-ocd-person-513edd6e-0d85-4ad6-8852-577cd5c0ee02",
              "builtID": "me-lower-34",
              "externalID": "ocd-person/513edd6e-0d85-4ad6-8852-577cd5c0ee02",
              "geometry": null
            },
            "contactInfo": {
              "email": "abigail.griffin@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1369",
                "https://mainehousegop.org/members/griffin-abigail/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-34-lower-ocd-person-513edd6e-0d85-4ad6-8852-577cd5c0ee02": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/e4f0e3da-2e65-4986-96ad-1ef21f9da73d",
            "firstName": "James E.",
            "lastName": "Thorne",
            "fullName": "Jim Thorne",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/92dc9462-f2b9-4767-8dec-39b147b90d53_ThorneNEWNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-ME-35-lower-ocd-person-e4f0e3da-2e65-4986-96ad-1ef21f9da73d",
              "builtID": "me-lower-35",
              "externalID": "ocd-person/e4f0e3da-2e65-4986-96ad-1ef21f9da73d",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.thorne@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1431",
                "https://mainehousegop.org/members/thorne-james/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-35-lower-ocd-person-e4f0e3da-2e65-4986-96ad-1ef21f9da73d": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/b3f3ab98-9169-42f8-b7d7-c04b3bdfb545",
            "firstName": "David G.",
            "lastName": "Haggan",
            "fullName": "David Haggan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/ef1b37a7-5997-414d-9ae4-6f38e8f619d8_haggdg.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-ME-36-lower-ocd-person-b3f3ab98-9169-42f8-b7d7-c04b3bdfb545",
              "builtID": "me-lower-36",
              "externalID": "ocd-person/b3f3ab98-9169-42f8-b7d7-c04b3bdfb545",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.haggan@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mainehousegop.org/members/haggan-david/",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/77"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-36-lower-ocd-person-b3f3ab98-9169-42f8-b7d7-c04b3bdfb545": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/76ab2f9a-14e8-4eb5-ae0e-1a26e4a3a04c",
            "firstName": "Reagan L.",
            "lastName": "Paul",
            "fullName": "Reagan Paul",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/7b5328a9-249c-464f-b943-d0fa3fe16d1c_RPAUL.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-ME-37-lower-ocd-person-76ab2f9a-14e8-4eb5-ae0e-1a26e4a3a04c",
              "builtID": "me-lower-37",
              "externalID": "ocd-person/76ab2f9a-14e8-4eb5-ae0e-1a26e4a3a04c",
              "geometry": null
            },
            "contactInfo": {
              "email": "reagan.paul@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1496",
                "https://mainehousegop.org/members/paul-reagan/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-37-lower-ocd-person-76ab2f9a-14e8-4eb5-ae0e-1a26e4a3a04c": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/f52b3788-349b-4b6c-84a3-72d02a953616",
            "firstName": "Benjamin C.",
            "lastName": "Hymes",
            "fullName": "Ben Hymes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/04372b02-d30f-4b91-a71e-0d7e188b385f_HYMES.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-ME-38-lower-ocd-person-f52b3788-349b-4b6c-84a3-72d02a953616",
              "builtID": "me-lower-38",
              "externalID": "ocd-person/f52b3788-349b-4b6c-84a3-72d02a953616",
              "geometry": null
            },
            "contactInfo": {
              "email": "benjamin.hymes@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1497",
                "https://mainehousegop.org/members/hymes-benjamin/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-38-lower-ocd-person-f52b3788-349b-4b6c-84a3-72d02a953616": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/b7b97724-7abc-44fb-bace-d3606d3fadb2",
            "firstName": "Janice",
            "lastName": "Dodge",
            "fullName": "Janice Dodge",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/3966d66f-a9d5-4de6-963e-cbe39eacecb3_JDODGE.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-ME-39-lower-ocd-person-b7b97724-7abc-44fb-bace-d3606d3fadb2",
              "builtID": "me-lower-39",
              "externalID": "ocd-person/b7b97724-7abc-44fb-bace-d3606d3fadb2",
              "geometry": null
            },
            "contactInfo": {
              "email": "jan.dodge@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1366"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-39-lower-ocd-person-b7b97724-7abc-44fb-bace-d3606d3fadb2": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/73b870a7-1c98-45c4-a94c-f09b657550f2",
            "firstName": "Stanley Paige",
            "lastName": "Zeigler",
            "fullName": "Paige Zeigler",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/b2638497-ab0a-4d4d-8766-cb715ae9c795_zeigsp[1].jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-ME-40-lower-ocd-person-73b870a7-1c98-45c4-a94c-f09b657550f2",
              "builtID": "me-lower-40",
              "externalID": "ocd-person/73b870a7-1c98-45c4-a94c-f09b657550f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "stanleypaige.zeigler@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/housedems/zeiglers/index.html",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/39"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-40-lower-ocd-person-73b870a7-1c98-45c4-a94c-f09b657550f2": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/6ec04f29-e02d-4c39-96e8-e9ed823437e7",
            "firstName": "Victoria Wenzel",
            "lastName": "Doudera",
            "fullName": "Vicki Doudera",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/b23e405e-96d3-42e0-8cea-8c5bb8949003_Doudera.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-ME-41-lower-ocd-person-6ec04f29-e02d-4c39-96e8-e9ed823437e7",
              "builtID": "me-lower-41",
              "externalID": "ocd-person/6ec04f29-e02d-4c39-96e8-e9ed823437e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "vicki.doudera@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1365"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-41-lower-ocd-person-6ec04f29-e02d-4c39-96e8-e9ed823437e7": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/0842c8ba-66a3-4896-aede-def84a65605b",
            "firstName": "Valli D.",
            "lastName": "Geiger",
            "fullName": "Valli Geiger",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/b7429307-4ee4-4919-b291-30007ac4f3d6_GeigerNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-ME-42-lower-ocd-person-0842c8ba-66a3-4896-aede-def84a65605b",
              "builtID": "me-lower-42",
              "externalID": "ocd-person/0842c8ba-66a3-4896-aede-def84a65605b",
              "geometry": null
            },
            "contactInfo": {
              "email": "valli.geiger@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1430",
                "https://legislature.maine.gov/housedems/geigerv/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-42-lower-ocd-person-0842c8ba-66a3-4896-aede-def84a65605b": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/187c138c-d84d-4813-884e-553b8a9354f1",
            "firstName": "Ann Higgins",
            "lastName": "Matlack",
            "fullName": "Ann Matlack",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/e8bdda16-c44f-4ced-9b15-4a11e3496fa9_Matlack.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-ME-43-lower-ocd-person-187c138c-d84d-4813-884e-553b8a9354f1",
              "builtID": "me-lower-43",
              "externalID": "ocd-person/187c138c-d84d-4813-884e-553b8a9354f1",
              "geometry": null
            },
            "contactInfo": {
              "email": "ann.matlack@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1364"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-43-lower-ocd-person-187c138c-d84d-4813-884e-553b8a9354f1": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/30ae681d-e01b-4305-9c6e-60df2e62b49b",
            "firstName": "William D.",
            "lastName": "Pluecker",
            "fullName": "Bill Pluecker",
            "gender": "Male",
            "party": "independent",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/592ffe4b-8d79-4017-bf5c-d39d0730d8aa_pluecker.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-ME-44-lower-ocd-person-30ae681d-e01b-4305-9c6e-60df2e62b49b",
              "builtID": "me-lower-44",
              "externalID": "ocd-person/30ae681d-e01b-4305-9c6e-60df2e62b49b",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.pluecker@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1383",
                "https://legislature.maine.gov/representative-bill-pluecker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-44-lower-ocd-person-30ae681d-e01b-4305-9c6e-60df2e62b49b": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/b6cdaab7-6aec-4c65-a239-e18c6e839fbc",
            "firstName": "Abden S.",
            "lastName": "Simmons",
            "fullName": "Abden Simmons",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/75e9b804-f745-4f44-9817-146eb1a8c378_simmas[1].jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-ME-45-lower-ocd-person-b6cdaab7-6aec-4c65-a239-e18c6e839fbc",
              "builtID": "me-lower-45",
              "externalID": "ocd-person/b6cdaab7-6aec-4c65-a239-e18c6e839fbc",
              "geometry": null
            },
            "contactInfo": {
              "email": "abden.simmons@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.maine.gov/legis/house/hsebios/simmas.htm",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/144",
                "https://mainehousegop.org/members/simmons-abden/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-45-lower-ocd-person-b6cdaab7-6aec-4c65-a239-e18c6e839fbc": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/b795e4ca-6b0d-4101-902d-8db5c1b54485",
            "firstName": "Lydia V.",
            "lastName": "Crafts",
            "fullName": "Lydia Crafts",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/aee526c2-f213-42e1-b859-11325b8c555a_Crafts.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-ME-46-lower-ocd-person-b795e4ca-6b0d-4101-902d-8db5c1b54485",
              "builtID": "me-lower-46",
              "externalID": "ocd-person/b795e4ca-6b0d-4101-902d-8db5c1b54485",
              "geometry": null
            },
            "contactInfo": {
              "email": "lydia.crafts@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1429",
                "https://legislature.maine.gov/housedems/craftsl/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-46-lower-ocd-person-b795e4ca-6b0d-4101-902d-8db5c1b54485": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/88103cc1-4a12-4091-9989-b062f9ed0eaf",
            "firstName": "Edward J.",
            "lastName": "Polewarczyk",
            "fullName": "Ed Polewarczyk",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/064582e2-b422-47fe-8f88-7c7c6bdfcec6_EPOLEWARCZYK.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-ME-47-lower-ocd-person-88103cc1-4a12-4091-9989-b062f9ed0eaf",
              "builtID": "me-lower-47",
              "externalID": "ocd-person/88103cc1-4a12-4091-9989-b062f9ed0eaf",
              "geometry": null
            },
            "contactInfo": {
              "email": "edward.polewarczyk@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1499",
                "https://mainehousegop.org/members/polewarczyk-edward/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-47-lower-ocd-person-88103cc1-4a12-4091-9989-b062f9ed0eaf": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/468ba602-86c5-4293-85b2-45e87313c1c3",
            "firstName": "Holly",
            "lastName": "Stover",
            "fullName": "Holly Stover",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/fc29e232-5948-4268-8919-22a92f6ab7ab_stover.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-ME-48-lower-ocd-person-468ba602-86c5-4293-85b2-45e87313c1c3",
              "builtID": "me-lower-48",
              "externalID": "ocd-person/468ba602-86c5-4293-85b2-45e87313c1c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "holly.stover@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1363"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-48-lower-ocd-person-468ba602-86c5-4293-85b2-45e87313c1c3": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/82b51bea-5f96-46d6-bbcf-4d973f701b27",
            "firstName": "Allison",
            "lastName": "Hepler",
            "fullName": "Allison Hepler",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/a75da791-060e-45cd-b1bd-95bc7c00c6e8_hepler.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-ME-49-lower-ocd-person-82b51bea-5f96-46d6-bbcf-4d973f701b27",
              "builtID": "me-lower-49",
              "externalID": "ocd-person/82b51bea-5f96-46d6-bbcf-4d973f701b27",
              "geometry": null
            },
            "contactInfo": {
              "email": "allison.hepler@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1353"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-49-lower-ocd-person-82b51bea-5f96-46d6-bbcf-4d973f701b27": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/8dd63807-ac15-408c-97de-c8e9195a6a42",
            "firstName": "David A.",
            "lastName": "Sinclair",
            "fullName": "David Sinclair",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/10a6470d-cab7-4912-8ce5-b2ed172f4f28_SinclairCropped3.JPG",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-ME-50-lower-ocd-person-8dd63807-ac15-408c-97de-c8e9195a6a42",
              "builtID": "me-lower-50",
              "externalID": "ocd-person/8dd63807-ac15-408c-97de-c8e9195a6a42",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.sinclair@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1508"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-50-lower-ocd-person-8dd63807-ac15-408c-97de-c8e9195a6a42": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/d91a8965-c40d-481f-af61-5acb9b953fd3",
            "firstName": "Sally Jeane",
            "lastName": "Cluchey",
            "fullName": "Sally Cluchey",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/c6886b51-5eda-49d0-b7ad-a5315555764c_SCLUCHEY.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-ME-52-lower-ocd-person-d91a8965-c40d-481f-af61-5acb9b953fd3",
              "builtID": "me-lower-52",
              "externalID": "ocd-person/d91a8965-c40d-481f-af61-5acb9b953fd3",
              "geometry": null
            },
            "contactInfo": {
              "email": "sally.cluchey@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1501",
                "https://legislature.maine.gov/housedems/clucheys/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-52-lower-ocd-person-d91a8965-c40d-481f-af61-5acb9b953fd3": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/feb330d7-8122-4547-b38a-38279732a9ce",
            "firstName": "Michael",
            "lastName": "Lemelin",
            "fullName": "Michael Lemelin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/2f05ff55-e46c-41ce-b4b9-5d144bc7b7ee_LemelinNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-ME-53-lower-ocd-person-feb330d7-8122-4547-b38a-38279732a9ce",
              "builtID": "me-lower-53",
              "externalID": "ocd-person/feb330d7-8122-4547-b38a-38279732a9ce",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.lemelin@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1428",
                "https://mainehousegop.org/members/lemelin-michael/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-53-lower-ocd-person-feb330d7-8122-4547-b38a-38279732a9ce": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/80900bc0-7e26-4195-a0af-ec56b9f34fe0",
            "firstName": "Karen L.",
            "lastName": "Montell",
            "fullName": "Karen Montell",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/509661e8-e573-4bef-b8ed-47c5c0a586a4_MONTELL.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-ME-54-lower-ocd-person-80900bc0-7e26-4195-a0af-ec56b9f34fe0",
              "builtID": "me-lower-54",
              "externalID": "ocd-person/80900bc0-7e26-4195-a0af-ec56b9f34fe0",
              "geometry": null
            },
            "contactInfo": {
              "email": "karen.montell@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1502",
                "https://legislature.maine.gov/housedems/montell/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-54-lower-ocd-person-80900bc0-7e26-4195-a0af-ec56b9f34fe0": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/43e6cd5d-1e16-4099-b46b-1b6883e679b6",
            "firstName": "Daniel Joseph",
            "lastName": "Shagoury",
            "fullName": "Dan Shagoury",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/b6eec5f0-d0b2-41c8-9295-fe2051a0e061_SHAGOURY.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-ME-55-lower-ocd-person-43e6cd5d-1e16-4099-b46b-1b6883e679b6",
              "builtID": "me-lower-55",
              "externalID": "ocd-person/43e6cd5d-1e16-4099-b46b-1b6883e679b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.shagoury@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1503",
                "https://legislature.maine.gov/housedems/shagoury/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-55-lower-ocd-person-43e6cd5d-1e16-4099-b46b-1b6883e679b6": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/abfa3321-4f0f-42a1-81ef-25b5d7bbfd3e",
            "firstName": "Randall Adam",
            "lastName": "Greenwood",
            "fullName": "Randall Greenwood",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/46650f82-b988-4d47-8cb9-0a5abc26a0a2_Greenwood.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-ME-56-lower-ocd-person-abfa3321-4f0f-42a1-81ef-25b5d7bbfd3e",
              "builtID": "me-lower-56",
              "externalID": "ocd-person/abfa3321-4f0f-42a1-81ef-25b5d7bbfd3e",
              "geometry": null
            },
            "contactInfo": {
              "email": "randall.greenwood@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/208",
                "https://mainehousegop.org/members/greenwood-randall/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-56-lower-ocd-person-abfa3321-4f0f-42a1-81ef-25b5d7bbfd3e": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/194ff967-ad0b-4f2d-8cb7-ef1e062b235c",
            "firstName": "Tavis Rock",
            "lastName": "Hasenfus",
            "fullName": "Tavis Hasenfus",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/7caf9ecd-04e1-4e00-b772-b38616cac2ab_HasenfusNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-ME-57-lower-ocd-person-194ff967-ad0b-4f2d-8cb7-ef1e062b235c",
              "builtID": "me-lower-57",
              "externalID": "ocd-person/194ff967-ad0b-4f2d-8cb7-ef1e062b235c",
              "geometry": null
            },
            "contactInfo": {
              "email": "tavis.hasenfus@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1427",
                "https://legislature.maine.gov/housedems/hasenfust/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-57-lower-ocd-person-194ff967-ad0b-4f2d-8cb7-ef1e062b235c": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/7a45ac53-b1a7-48bf-a6e1-eae270f577ab",
            "firstName": "Daniel J.",
            "lastName": "Newman",
            "fullName": "Dan Newman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/248e235f-70f2-490b-b249-fae254d2105c_NewmanNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-ME-58-lower-ocd-person-7a45ac53-b1a7-48bf-a6e1-eae270f577ab",
              "builtID": "me-lower-58",
              "externalID": "ocd-person/7a45ac53-b1a7-48bf-a6e1-eae270f577ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.newman@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1425",
                "https://mainehousegop.org/members/newman-daniel/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-58-lower-ocd-person-7a45ac53-b1a7-48bf-a6e1-eae270f577ab": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/70439288-52d2-4c36-9de4-0ce8a4a9a0ed",
            "firstName": "Raegan French",
            "lastName": "LaRochelle",
            "fullName": "Raegan LaRochelle",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/18728af1-3ec2-4459-8372-0c92809f9b27_LaRochelle.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-ME-59-lower-ocd-person-70439288-52d2-4c36-9de4-0ce8a4a9a0ed",
              "builtID": "me-lower-59",
              "externalID": "ocd-person/70439288-52d2-4c36-9de4-0ce8a4a9a0ed",
              "geometry": null
            },
            "contactInfo": {
              "email": "raegan.larochelle@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1450",
                "https://legislature.maine.gov/housedems/larocheller/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-59-lower-ocd-person-70439288-52d2-4c36-9de4-0ce8a4a9a0ed": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/44cd2c44-aa58-4129-bc5f-eefc64bf836f",
            "firstName": "William R.",
            "lastName": "Bridgeo",
            "fullName": "Bill Bridgeo",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/3f618227-f773-43c5-9a0e-d081871faa0e_WBRIDGEO.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-ME-60-lower-ocd-person-44cd2c44-aa58-4129-bc5f-eefc64bf836f",
              "builtID": "me-lower-60",
              "externalID": "ocd-person/44cd2c44-aa58-4129-bc5f-eefc64bf836f",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.bridgeo@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1504",
                "https://legislature.maine.gov/housedems/bridgeo/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-60-lower-ocd-person-44cd2c44-aa58-4129-bc5f-eefc64bf836f": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/ec504278-9872-4842-a99c-80b31e563707",
            "firstName": "Richard T.",
            "lastName": "Bradstreet",
            "fullName": "Dick Bradstreet",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/25f23c49-eaab-420a-8fdd-b4d2afb931bc_bradrt.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-ME-61-lower-ocd-person-ec504278-9872-4842-a99c-80b31e563707",
              "builtID": "me-lower-61",
              "externalID": "ocd-person/ec504278-9872-4842-a99c-80b31e563707",
              "geometry": null
            },
            "contactInfo": {
              "email": "dick.bradstreet@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/18"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-61-lower-ocd-person-ec504278-9872-4842-a99c-80b31e563707": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/b07e421b-8c1f-473d-aa7a-10d71e8a443b",
            "firstName": "Katrina J.",
            "lastName": "Smith",
            "fullName": "Katrina Smith",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/5d2a8b6b-bea9-4f98-ad05-6d20c8f83aa6_KSMITH.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-ME-62-lower-ocd-person-b07e421b-8c1f-473d-aa7a-10d71e8a443b",
              "builtID": "me-lower-62",
              "externalID": "ocd-person/b07e421b-8c1f-473d-aa7a-10d71e8a443b",
              "geometry": null
            },
            "contactInfo": {
              "email": "katrina.smith@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1505",
                "https://mainehousegop.org/members/smith-katrina/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-62-lower-ocd-person-b07e421b-8c1f-473d-aa7a-10d71e8a443b": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/73dfc0b1-9ce4-4d8e-8119-1dc7396fbb2c",
            "firstName": "Scott Wynn",
            "lastName": "Cyrway",
            "fullName": "Scott Cyrway",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/uploads/visual_edit/cyrway-5x7-1.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-ME-63-lower-ocd-person-73dfc0b1-9ce4-4d8e-8119-1dc7396fbb2c",
              "builtID": "me-lower-63",
              "externalID": "ocd-person/73dfc0b1-9ce4-4d8e-8119-1dc7396fbb2c",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.cyrway@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-16",
                "https://legislature.maine.gov/District-16",
                "https://mesenategop.com/your-senators/senator-scott-cyrway/",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1506",
                "https://mainehousegop.org/members/cyrway-scott/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-63-lower-ocd-person-73dfc0b1-9ce4-4d8e-8119-1dc7396fbb2c": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/2d4009da-89a2-41dd-aed4-10abc5bb6e66",
            "firstName": "Colleen M.",
            "lastName": "Madigan",
            "fullName": "Colleen Madigan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/6f161024-d866-4264-b957-ce30cacd4db2_madicm.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-ME-64-lower-ocd-person-2d4009da-89a2-41dd-aed4-10abc5bb6e66",
              "builtID": "me-lower-64",
              "externalID": "ocd-person/2d4009da-89a2-41dd-aed4-10abc5bb6e66",
              "geometry": null
            },
            "contactInfo": {
              "email": "colleen.madigan@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/housedems/madiganc/index.html",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/104"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-64-lower-ocd-person-2d4009da-89a2-41dd-aed4-10abc5bb6e66": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/eae853be-ccf6-4a64-964e-a524cf3143b7",
            "firstName": "Bruce A.",
            "lastName": "White",
            "fullName": "Bruce White",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/80b99c67-2fca-410c-95ec-0966049b81e8_BWhiteNew.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-ME-65-lower-ocd-person-eae853be-ccf6-4a64-964e-a524cf3143b7",
              "builtID": "me-lower-65",
              "externalID": "ocd-person/eae853be-ccf6-4a64-964e-a524cf3143b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "bruce.white@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1372"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-65-lower-ocd-person-eae853be-ccf6-4a64-964e-a524cf3143b7": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/32f4600c-1bc1-4758-8d94-69878c863e00",
            "firstName": "Robert W.",
            "lastName": "Nutting",
            "fullName": "Bob Nutting",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/10857e45-bb21-4dfb-92af-ab7059898dbe_nuttrw.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-ME-66-lower-ocd-person-32f4600c-1bc1-4758-8d94-69878c863e00",
              "builtID": "me-lower-66",
              "externalID": "ocd-person/32f4600c-1bc1-4758-8d94-69878c863e00",
              "geometry": null
            },
            "contactInfo": {
              "email": "robert.nutting@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.maine.gov/legis/house/hsebios/nuttrw.htm",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/218",
                "https://mainehousegop.org/members/nutting-robert/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-66-lower-ocd-person-32f4600c-1bc1-4758-8d94-69878c863e00": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/2c48b67e-10dd-4162-af2b-add94f3bc728",
            "firstName": "Shelley",
            "lastName": "Rudnicki",
            "fullName": "Shelley Rudnicki",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/f5ef18ca-04bc-4226-a57d-0857e0087153_rudnicki.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-ME-67-lower-ocd-person-2c48b67e-10dd-4162-af2b-add94f3bc728",
              "builtID": "me-lower-67",
              "externalID": "ocd-person/2c48b67e-10dd-4162-af2b-add94f3bc728",
              "geometry": null
            },
            "contactInfo": {
              "email": "shelley.rudnicki@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1371",
                "https://mainehousegop.org/members/rudnicki-shelley/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-67-lower-ocd-person-2c48b67e-10dd-4162-af2b-add94f3bc728": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/1e7a6a8f-7160-4bee-80d1-b1dc47b3db4f",
            "firstName": "Amanda N.",
            "lastName": "Collamore",
            "fullName": "Amanda Collamore",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/24f65326-81e9-48f3-a7cc-a5dc8e7d74e6_Collamore.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-ME-68-lower-ocd-person-1e7a6a8f-7160-4bee-80d1-b1dc47b3db4f",
              "builtID": "me-lower-68",
              "externalID": "ocd-person/1e7a6a8f-7160-4bee-80d1-b1dc47b3db4f",
              "geometry": null
            },
            "contactInfo": {
              "email": "amanda.collamore@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1432",
                "https://mainehousegop.org/members/collamore-amanda/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-68-lower-ocd-person-1e7a6a8f-7160-4bee-80d1-b1dc47b3db4f": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/8bc14822-ff62-4ffe-bf42-4114bd5bd99b",
            "firstName": "Dean A.",
            "lastName": "Cray",
            "fullName": "Dean Cray",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/898a0677-23f7-4936-a1a4-c913ae94227c_CRAY.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-ME-69-lower-ocd-person-8bc14822-ff62-4ffe-bf42-4114bd5bd99b",
              "builtID": "me-lower-69",
              "externalID": "ocd-person/8bc14822-ff62-4ffe-bf42-4114bd5bd99b",
              "geometry": null
            },
            "contactInfo": {
              "email": "dean.cray@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.maine.gov/legis/house/hsebios/crayda.htm",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/249",
                "https://mainehousegop.org/members/cray-dean/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-69-lower-ocd-person-8bc14822-ff62-4ffe-bf42-4114bd5bd99b": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/b873e7d2-9103-47e9-8c25-df94056f728d",
            "firstName": "Jennifer Lynn",
            "lastName": "Poirier",
            "fullName": "Jennifer Poirier",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/8e43d53c-3390-4bb4-9adb-b5a728cb3263_Poirier.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-ME-70-lower-ocd-person-b873e7d2-9103-47e9-8c25-df94056f728d",
              "builtID": "me-lower-70",
              "externalID": "ocd-person/b873e7d2-9103-47e9-8c25-df94056f728d",
              "geometry": null
            },
            "contactInfo": {
              "email": "jennifer.poirier@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1433",
                "https://mainehousegop.org/members/poirier-jennifer/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-70-lower-ocd-person-b873e7d2-9103-47e9-8c25-df94056f728d": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/d72eadfd-23ee-4ecb-9853-e139ea82aec3",
            "firstName": "John E.",
            "lastName": "Ducharme",
            "fullName": "Jack Ducharme",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/c1e35f04-f37a-4b52-b892-dc74d53b510c_DucharmeNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-ME-71-lower-ocd-person-d72eadfd-23ee-4ecb-9853-e139ea82aec3",
              "builtID": "me-lower-71",
              "externalID": "ocd-person/d72eadfd-23ee-4ecb-9853-e139ea82aec3",
              "geometry": null
            },
            "contactInfo": {
              "email": "jack.ducharme@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1434",
                "https://mainehousegop.org/members/ducharme-jack/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-71-lower-ocd-person-d72eadfd-23ee-4ecb-9853-e139ea82aec3": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/62fbaabc-63da-4026-849c-3d2e444ea444",
            "firstName": "Larry C.",
            "lastName": "Dunphy",
            "fullName": "Larry Dunphy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/7c48264b-bc70-4bc7-a67d-505c55c0bcc8_dunplc.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-ME-72-lower-ocd-person-62fbaabc-63da-4026-849c-3d2e444ea444",
              "builtID": "me-lower-72",
              "externalID": "ocd-person/62fbaabc-63da-4026-849c-3d2e444ea444",
              "geometry": null
            },
            "contactInfo": {
              "email": "larry.dunphy@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/201",
                "https://mainehousegop.org/members/dunphy-larry/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-72-lower-ocd-person-62fbaabc-63da-4026-849c-3d2e444ea444": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/fc107a79-150e-414f-b796-960c96d75eb6",
            "firstName": "Michael",
            "lastName": "Soboleski",
            "fullName": "Mike Soboleski",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/cb4e1505-8bc4-478c-a23c-d677f2d58e50_MSOBOLESKI.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-ME-73-lower-ocd-person-fc107a79-150e-414f-b796-960c96d75eb6",
              "builtID": "me-lower-73",
              "externalID": "ocd-person/fc107a79-150e-414f-b796-960c96d75eb6",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.soboleski@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1482",
                "https://mainehousegop.org/members/soboleski-michael/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-73-lower-ocd-person-fc107a79-150e-414f-b796-960c96d75eb6": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/8a7d14ca-669e-4984-8a28-0086e2b9b2ae",
            "firstName": "Randall C.",
            "lastName": "Hall",
            "fullName": "Randy Hall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/34a33c5a-2116-406e-8a63-e77dbb049077_hall.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-ME-74-lower-ocd-person-8a7d14ca-669e-4984-8a28-0086e2b9b2ae",
              "builtID": "me-lower-74",
              "externalID": "ocd-person/8a7d14ca-669e-4984-8a28-0086e2b9b2ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "randall.hall@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1375",
                "https://mainehousegop.org/members/hall-randall/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-74-lower-ocd-person-8a7d14ca-669e-4984-8a28-0086e2b9b2ae": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/5e8c1129-4ab0-403d-9343-51b2529545df",
            "firstName": "H. Scott",
            "lastName": "Landry",
            "fullName": "Scott Landry",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/ad2ddc98-f4b5-4f4f-9594-bb0a5c89fabc_landry.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-ME-75-lower-ocd-person-5e8c1129-4ab0-403d-9343-51b2529545df",
              "builtID": "me-lower-75",
              "externalID": "ocd-person/5e8c1129-4ab0-403d-9343-51b2529545df",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.landry@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1374"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-75-lower-ocd-person-5e8c1129-4ab0-403d-9343-51b2529545df": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/7498179f-e3de-47b7-9aee-9abee716522a",
            "firstName": "Sheila A.",
            "lastName": "Lyman",
            "fullName": "Sheila Lyman",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/07bd7878-b32c-4a23-b44a-9f56e3353fdf_LymanNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-ME-76-lower-ocd-person-7498179f-e3de-47b7-9aee-9abee716522a",
              "builtID": "me-lower-76",
              "externalID": "ocd-person/7498179f-e3de-47b7-9aee-9abee716522a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sheila.lyman@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1424",
                "https://mainehousegop.org/members/lyman-sheila/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-76-lower-ocd-person-7498179f-e3de-47b7-9aee-9abee716522a": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/444d3040-a7ee-4559-8d5f-bfc515617605",
            "firstName": "Tammy L.",
            "lastName": "Schmersal-Burgess",
            "fullName": "Tammy Schmersal-Burgess",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/c0bba107-42c6-442e-aea6-3cdc8e53c9eb_TBURGESS.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-ME-77-lower-ocd-person-444d3040-a7ee-4559-8d5f-bfc515617605",
              "builtID": "me-lower-77",
              "externalID": "ocd-person/444d3040-a7ee-4559-8d5f-bfc515617605",
              "geometry": null
            },
            "contactInfo": {
              "email": "tammy.schmersal-burgess@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1483",
                "https://mainehousegop.org/members/schmersal-burgess-tammy/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-77-lower-ocd-person-444d3040-a7ee-4559-8d5f-bfc515617605": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/b61923e6-c463-4df4-95b2-117d74c030bd",
            "firstName": "Rachel Ann",
            "lastName": "Henderson",
            "fullName": "Rachel Henderson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/7c8fd3af-5878-4aea-91fe-0faa167ad005_HENDERSON.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-ME-78-lower-ocd-person-b61923e6-c463-4df4-95b2-117d74c030bd",
              "builtID": "me-lower-78",
              "externalID": "ocd-person/b61923e6-c463-4df4-95b2-117d74c030bd",
              "geometry": null
            },
            "contactInfo": {
              "email": "rachel.henderson@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1484",
                "https://mainehousegop.org/members/henderson-rachel/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-78-lower-ocd-person-b61923e6-c463-4df4-95b2-117d74c030bd": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/cf1f0a32-7107-44ea-96f2-1f3c604d87cc",
            "firstName": "Caldwell",
            "lastName": "Jackson",
            "fullName": "Caldwell Jackson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/4a816ecf-f3c1-49e1-88e9-f7a4d18cb5de_JACKSON.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-ME-80-lower-ocd-person-cf1f0a32-7107-44ea-96f2-1f3c604d87cc",
              "builtID": "me-lower-80",
              "externalID": "ocd-person/cf1f0a32-7107-44ea-96f2-1f3c604d87cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "caldwell.jackson@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1485",
                "https://mainehousegop.org/members/jackson-caldwell/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-80-lower-ocd-person-cf1f0a32-7107-44ea-96f2-1f3c604d87cc": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/4dd7da95-3d79-429a-9ab9-37b734209d58",
            "firstName": "Howard Sawin",
            "lastName": "Millett",
            "fullName": "Sawin Millett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/e1ac929e-f627-42f7-92ce-d880fe6c5d81_millett.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-ME-81-lower-ocd-person-4dd7da95-3d79-429a-9ab9-37b734209d58",
              "builtID": "me-lower-81",
              "externalID": "ocd-person/4dd7da95-3d79-429a-9ab9-37b734209d58",
              "geometry": null
            },
            "contactInfo": {
              "email": "sawin.millett@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1357",
                "https://mainehousegop.org/members/millett-sawin/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-81-lower-ocd-person-4dd7da95-3d79-429a-9ab9-37b734209d58": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/2fa1f078-a449-4e70-aac1-7663473a2fb5",
            "firstName": "Caleb Joshua",
            "lastName": "Ness",
            "fullName": "Caleb Ness",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/99218279-23eb-4d23-9694-ecd4cb650a1a_CNESS.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-ME-82-lower-ocd-person-2fa1f078-a449-4e70-aac1-7663473a2fb5",
              "builtID": "me-lower-82",
              "externalID": "ocd-person/2fa1f078-a449-4e70-aac1-7663473a2fb5",
              "geometry": null
            },
            "contactInfo": {
              "email": "caleb.ness@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1486",
                "https://mainehousegop.org/members/ness-caleb/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-82-lower-ocd-person-2fa1f078-a449-4e70-aac1-7663473a2fb5": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/761b038e-9367-467c-bdc6-21188aba08ec",
            "firstName": "Walter N.",
            "lastName": "Riseman",
            "fullName": "Walter Riseman",
            "gender": "Male",
            "party": "independent",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/e90e3af5-f8a6-4c6f-a468-cf16142aa733_riseman.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-ME-83-lower-ocd-person-761b038e-9367-467c-bdc6-21188aba08ec",
              "builtID": "me-lower-83",
              "externalID": "ocd-person/761b038e-9367-467c-bdc6-21188aba08ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "walter.riseman@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1382"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-83-lower-ocd-person-761b038e-9367-467c-bdc6-21188aba08ec": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/d16d8ff0-3e6f-4970-9baf-767c9eb09fab",
            "firstName": "Mark",
            "lastName": "Walker",
            "fullName": "Mark Walker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/006156d6-d589-47bc-a865-871a85279134_MWALKER.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-ME-84-lower-ocd-person-d16d8ff0-3e6f-4970-9baf-767c9eb09fab",
              "builtID": "me-lower-84",
              "externalID": "ocd-person/d16d8ff0-3e6f-4970-9baf-767c9eb09fab",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.walker@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1487",
                "https://mainehousegop.org/members/walker-mark/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-84-lower-ocd-person-d16d8ff0-3e6f-4970-9baf-767c9eb09fab": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/410a9d92-cdb2-4927-b589-72e32d525883",
            "firstName": "Kimberly J.",
            "lastName": "Pomerleau",
            "fullName": "Kim Pomerleau",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/be0d0db4-52a9-40c7-8300-6cbb2e7e652d_POMERLEAU.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-ME-85-lower-ocd-person-410a9d92-cdb2-4927-b589-72e32d525883",
              "builtID": "me-lower-85",
              "externalID": "ocd-person/410a9d92-cdb2-4927-b589-72e32d525883",
              "geometry": null
            },
            "contactInfo": {
              "email": "kimberly.pomerleau@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1488",
                "https://mainehousegop.org/members/pomerleau-kimberly/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-85-lower-ocd-person-410a9d92-cdb2-4927-b589-72e32d525883": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/396c42a5-aa11-4278-bb9f-f6b3c5b2f0f5",
            "firstName": "Jessica L.",
            "lastName": "Fay",
            "fullName": "Jess Fay",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/709042d1-fb4a-4254-b88e-53b178c8ad27_fay_jl.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-ME-86-lower-ocd-person-396c42a5-aa11-4278-bb9f-f6b3c5b2f0f5",
              "builtID": "me-lower-86",
              "externalID": "ocd-person/396c42a5-aa11-4278-bb9f-f6b3c5b2f0f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "jessica.fay@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/housedems/fayj/index.html",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/62"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-86-lower-ocd-person-396c42a5-aa11-4278-bb9f-f6b3c5b2f0f5": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/f338ea4f-b539-450c-bca4-161c427bb81a",
            "firstName": "David",
            "lastName": "Boyer",
            "fullName": "David Boyer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/c6b23ace-404f-4ba8-9f4d-c551acec8ca5_DBOYER.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-ME-87-lower-ocd-person-f338ea4f-b539-450c-bca4-161c427bb81a",
              "builtID": "me-lower-87",
              "externalID": "ocd-person/f338ea4f-b539-450c-bca4-161c427bb81a",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.boyer@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1489",
                "https://mainehousegop.org/members/boyer-david/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-87-lower-ocd-person-f338ea4f-b539-450c-bca4-161c427bb81a": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/a4b85af2-1c8b-4af6-8814-1539c9252aef",
            "firstName": "Kathleen A.",
            "lastName": "Shaw",
            "fullName": "Kathy Shaw",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/98417f8d-1ad8-4c09-ab4e-ab12626a5dbd_KSHAW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-ME-88-lower-ocd-person-a4b85af2-1c8b-4af6-8814-1539c9252aef",
              "builtID": "me-lower-88",
              "externalID": "ocd-person/a4b85af2-1c8b-4af6-8814-1539c9252aef",
              "geometry": null
            },
            "contactInfo": {
              "email": "kathy.shaw@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1490",
                "https://legislature.maine.gov/housedems/shaw/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-88-lower-ocd-person-a4b85af2-1c8b-4af6-8814-1539c9252aef": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/dff0e200-fd8c-4ada-ac3d-e014a529dd59",
            "firstName": "Adam R.",
            "lastName": "Lee",
            "fullName": "Adam Lee",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/cc770c67-4f1f-4e24-a168-4a359d33b108_ALEE.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-ME-89-lower-ocd-person-dff0e200-fd8c-4ada-ac3d-e014a529dd59",
              "builtID": "me-lower-89",
              "externalID": "ocd-person/dff0e200-fd8c-4ada-ac3d-e014a529dd59",
              "geometry": null
            },
            "contactInfo": {
              "email": "adam.lee@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1491",
                "https://legislature.maine.gov/housedems/lee/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-89-lower-ocd-person-dff0e200-fd8c-4ada-ac3d-e014a529dd59": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/5df4dc20-8fb9-432c-8844-c72a66a325d6",
            "firstName": "Laurel Dawson Munsell",
            "lastName": "Libby",
            "fullName": "Laurel Libby",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/12533ded-aef6-4f67-a45a-d9c25b51d38a_Libby.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-ME-90-lower-ocd-person-5df4dc20-8fb9-432c-8844-c72a66a325d6",
              "builtID": "me-lower-90",
              "externalID": "ocd-person/5df4dc20-8fb9-432c-8844-c72a66a325d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "laurel.libby@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1423",
                "https://mainehousegop.org/members/libby-laurel/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-90-lower-ocd-person-5df4dc20-8fb9-432c-8844-c72a66a325d6": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/64a07a85-a469-4c9d-9b7c-bf4de46ade74",
            "firstName": "Joshua",
            "lastName": "Morris",
            "fullName": "Josh Morris",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/78e82de0-6a49-4118-9325-db07c8e934df_morris.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-ME-91-lower-ocd-person-64a07a85-a469-4c9d-9b7c-bf4de46ade74",
              "builtID": "me-lower-91",
              "externalID": "ocd-person/64a07a85-a469-4c9d-9b7c-bf4de46ade74",
              "geometry": null
            },
            "contactInfo": {
              "email": "joshua.morris@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1359",
                "https://mainehousegop.org/members/morris-joshua/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-91-lower-ocd-person-64a07a85-a469-4c9d-9b7c-bf4de46ade74": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/94645c8f-f06c-485e-b5fa-797e3590ea30",
            "firstName": "Stephen J.",
            "lastName": "Wood",
            "fullName": "Steve Wood",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/1e90c36e-1b64-464a-8371-c4bb4bad5925_woodsj[1].jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-ME-92-lower-ocd-person-94645c8f-f06c-485e-b5fa-797e3590ea30",
              "builtID": "me-lower-92",
              "externalID": "ocd-person/94645c8f-f06c-485e-b5fa-797e3590ea30",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephen.wood@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/40",
                "https://mainehousegop.org/members/wood-stephen/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-92-lower-ocd-person-94645c8f-f06c-485e-b5fa-797e3590ea30": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/0b6d7994-8ba7-48cc-aaaf-b1210c3d3f2e",
            "firstName": "Margaret M.",
            "lastName": "Craven",
            "fullName": "Margaret Craven",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/0fd5a57f-3cb7-4a27-8dbc-e68659f69502_craven.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-ME-93-lower-ocd-person-0b6d7994-8ba7-48cc-aaaf-b1210c3d3f2e",
              "builtID": "me-lower-93",
              "externalID": "ocd-person/0b6d7994-8ba7-48cc-aaaf-b1210c3d3f2e",
              "geometry": null
            },
            "contactInfo": {
              "email": "margaret.craven@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/424"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-93-lower-ocd-person-0b6d7994-8ba7-48cc-aaaf-b1210c3d3f2e": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/5fdde975-1201-4265-aed0-1223f97f93f6",
            "firstName": "Kristen",
            "lastName": "Cloutier",
            "fullName": "Kristen Cloutier",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/61a2f8e0-e229-4163-b1a0-1efd9689fc5b_cloutier.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-ME-94-lower-ocd-person-5fdde975-1201-4265-aed0-1223f97f93f6",
              "builtID": "me-lower-94",
              "externalID": "ocd-person/5fdde975-1201-4265-aed0-1223f97f93f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "kristen.cloutier@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1355"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-94-lower-ocd-person-5fdde975-1201-4265-aed0-1223f97f93f6": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/0bb988f9-6dca-4fd8-ac06-3603af31094f",
            "firstName": "Mana Hared",
            "lastName": "Abdi",
            "fullName": "Mana Abdi",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/0b0aefe9-d3ab-435e-bf39-4028eaabde3a_MABDI.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-ME-95-lower-ocd-person-0bb988f9-6dca-4fd8-ac06-3603af31094f",
              "builtID": "me-lower-95",
              "externalID": "ocd-person/0bb988f9-6dca-4fd8-ac06-3603af31094f",
              "geometry": null
            },
            "contactInfo": {
              "email": "mana.abdi@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1492",
                "https://legislature.maine.gov/housedems/abdi/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-95-lower-ocd-person-0bb988f9-6dca-4fd8-ac06-3603af31094f": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/c3185181-4ae9-42ce-8bf5-9a2f04c2422b",
            "firstName": "Michel A.",
            "lastName": "Lajoie",
            "fullName": "Mike Lajoie",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/5dfb974c-7ace-4bd3-86bf-d975d1ac8aa1_lajoma.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-ME-96-lower-ocd-person-c3185181-4ae9-42ce-8bf5-9a2f04c2422b",
              "builtID": "me-lower-96",
              "externalID": "ocd-person/c3185181-4ae9-42ce-8bf5-9a2f04c2422b",
              "geometry": null
            },
            "contactInfo": {
              "email": "michel.lajoie@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/212"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-96-lower-ocd-person-c3185181-4ae9-42ce-8bf5-9a2f04c2422b": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/6c313875-2dc9-4ef5-b869-c3cc0b7d5c97",
            "firstName": "Richard G.",
            "lastName": "Mason",
            "fullName": "Rick Mason",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/00d3edf9-afa9-42a9-894e-6f123441a00b_MasonNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-ME-97-lower-ocd-person-6c313875-2dc9-4ef5-b869-c3cc0b7d5c97",
              "builtID": "me-lower-97",
              "externalID": "ocd-person/6c313875-2dc9-4ef5-b869-c3cc0b7d5c97",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.mason@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/158",
                "https://mainehousegop.org/members/mason-richard/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-97-lower-ocd-person-6c313875-2dc9-4ef5-b869-c3cc0b7d5c97": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/fdc5aba6-4bf2-47ba-8239-ec30358a4c7c",
            "firstName": "Joseph C.",
            "lastName": "Galletta",
            "fullName": "Joseph Galletta",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/13ab95b6-1d41-4522-84e1-1639bd198b24_GALLETTA.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-ME-98-lower-ocd-person-fdc5aba6-4bf2-47ba-8239-ec30358a4c7c",
              "builtID": "me-lower-98",
              "externalID": "ocd-person/fdc5aba6-4bf2-47ba-8239-ec30358a4c7c",
              "geometry": null
            },
            "contactInfo": {
              "email": "joseph.galletta@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1493",
                "https://mainehousegop.org/members/galletta-joseph/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-98-lower-ocd-person-fdc5aba6-4bf2-47ba-8239-ec30358a4c7c": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/cef7caa9-828c-4272-a694-2aa16a5397ea",
            "firstName": "Cheryl A.",
            "lastName": "Golek",
            "fullName": "Cheryl Golek",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/bedcf53e-b367-42a7-9db6-a1c317f925af_GOLEK.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-ME-99-lower-ocd-person-cef7caa9-828c-4272-a694-2aa16a5397ea",
              "builtID": "me-lower-99",
              "externalID": "ocd-person/cef7caa9-828c-4272-a694-2aa16a5397ea",
              "geometry": null
            },
            "contactInfo": {
              "email": "cheryl.golek@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1494",
                "https://legislature.maine.gov/housedems/golek/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-99-lower-ocd-person-cef7caa9-828c-4272-a694-2aa16a5397ea": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/eb5efd36-8020-46f1-a6ef-6dac6873f692",
            "firstName": "Daniel J.",
            "lastName": "Ankeles",
            "fullName": "Dan Ankeles",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/4ce09159-182e-48aa-93cb-0c127b0abe7c_ANKELES.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-ME-100-lower-ocd-person-eb5efd36-8020-46f1-a6ef-6dac6873f692",
              "builtID": "me-lower-100",
              "externalID": "ocd-person/eb5efd36-8020-46f1-a6ef-6dac6873f692",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.ankeles@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1495",
                "https://legislature.maine.gov/housedems/ankeles/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-100-lower-ocd-person-eb5efd36-8020-46f1-a6ef-6dac6873f692": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/522f7557-1c06-451b-9c81-78080fc5c21c",
            "firstName": "Poppy Thacher",
            "lastName": "Arford",
            "fullName": "Poppy Arford",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/e67bda1d-1bd9-46a4-8dca-4c3616a20dfa_ArfordNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-ME-101-lower-ocd-person-522f7557-1c06-451b-9c81-78080fc5c21c",
              "builtID": "me-lower-101",
              "externalID": "ocd-person/522f7557-1c06-451b-9c81-78080fc5c21c",
              "geometry": null
            },
            "contactInfo": {
              "email": "poppy.arford@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1421",
                "https://legislature.maine.gov/housedems/arfordp/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-101-lower-ocd-person-522f7557-1c06-451b-9c81-78080fc5c21c": 0
        }
      },
      "102": {
        "members": [
          {
            "API_ID": "ocd-person/efda26eb-c8f7-443c-a963-95419878a856",
            "firstName": "Melanie",
            "lastName": "Sachs",
            "fullName": "Melanie Sachs",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/5e4515a3-52f4-43e9-beaa-2fe8ac45c90d_Sachs.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "102",
              "chamber": "lower",
              "hashID": "SLDL-ME-102-lower-ocd-person-efda26eb-c8f7-443c-a963-95419878a856",
              "builtID": "me-lower-102",
              "externalID": "ocd-person/efda26eb-c8f7-443c-a963-95419878a856",
              "geometry": null
            },
            "contactInfo": {
              "email": "melanie.sachs@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1420",
                "https://legislature.maine.gov/housedems/sachsm/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-102-lower-ocd-person-efda26eb-c8f7-443c-a963-95419878a856": 0
        }
      },
      "103": {
        "members": [
          {
            "API_ID": "ocd-person/7777fc5c-d508-4332-aac4-ec4799cf1721",
            "firstName": "Arthur L.",
            "lastName": "Bell",
            "fullName": "Art Bell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/e376b676-cec4-4774-bb44-7cc0d25425d2_BellNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "103",
              "chamber": "lower",
              "hashID": "SLDL-ME-103-lower-ocd-person-7777fc5c-d508-4332-aac4-ec4799cf1721",
              "builtID": "me-lower-103",
              "externalID": "ocd-person/7777fc5c-d508-4332-aac4-ec4799cf1721",
              "geometry": null
            },
            "contactInfo": {
              "email": "arthur.bell@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1419",
                "https://legislature.maine.gov/housedems/bella/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-103-lower-ocd-person-7777fc5c-d508-4332-aac4-ec4799cf1721": 0
        }
      },
      "104": {
        "members": [
          {
            "API_ID": "ocd-person/20082672-8aa7-4d49-bad7-fe5a92d1720d",
            "firstName": "Amy Bradstreet",
            "lastName": "Arata",
            "fullName": "Amy Arata",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/73657768-20f5-4692-8934-16f6b9a3cacd_arata.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "104",
              "chamber": "lower",
              "hashID": "SLDL-ME-104-lower-ocd-person-20082672-8aa7-4d49-bad7-fe5a92d1720d",
              "builtID": "me-lower-104",
              "externalID": "ocd-person/20082672-8aa7-4d49-bad7-fe5a92d1720d",
              "geometry": null
            },
            "contactInfo": {
              "email": "amy.arata@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1356",
                "https://mainehousegop.org/members/arata-amy/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-104-lower-ocd-person-20082672-8aa7-4d49-bad7-fe5a92d1720d": 0
        }
      },
      "105": {
        "members": [
          {
            "API_ID": "ocd-person/2f7e7649-5c68-4f8f-8c9a-7993b66d383a",
            "firstName": "Anne P.",
            "lastName": "Graham",
            "fullName": "Annie Graham",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/3c31dd8d-1de9-4bf3-b506-b7c45790974f_AGRAHAM.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "105",
              "chamber": "lower",
              "hashID": "SLDL-ME-105-lower-ocd-person-2f7e7649-5c68-4f8f-8c9a-7993b66d383a",
              "builtID": "me-lower-105",
              "externalID": "ocd-person/2f7e7649-5c68-4f8f-8c9a-7993b66d383a",
              "geometry": null
            },
            "contactInfo": {
              "email": "anne.graham@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/259"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-105-lower-ocd-person-2f7e7649-5c68-4f8f-8c9a-7993b66d383a": 0
        }
      },
      "106": {
        "members": [
          {
            "API_ID": "ocd-person/72cc3b25-8e1e-4095-93be-216ee191572c",
            "firstName": "Barbara A.",
            "lastName": "Bagshaw",
            "fullName": "Barbara Bagshaw",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/d2a8373a-d1e8-4e25-936a-4fbcd3ac4087_BBAGSHAW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "106",
              "chamber": "lower",
              "hashID": "SLDL-ME-106-lower-ocd-person-72cc3b25-8e1e-4095-93be-216ee191572c",
              "builtID": "me-lower-106",
              "externalID": "ocd-person/72cc3b25-8e1e-4095-93be-216ee191572c",
              "geometry": null
            },
            "contactInfo": {
              "email": "barbara.bagshaw@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1473",
                "https://mainehousegop.org/members/bagshaw-barbara/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-106-lower-ocd-person-72cc3b25-8e1e-4095-93be-216ee191572c": 0
        }
      },
      "107": {
        "members": [
          {
            "API_ID": "ocd-person/dee8fe26-42af-4b65-b477-3d8f6a938435",
            "firstName": "Jane P.",
            "lastName": "Pringle",
            "fullName": "Jane Pringle",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/8b09b373-e03d-47e1-8972-a5af150db655_JPRINGLE.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "107",
              "chamber": "lower",
              "hashID": "SLDL-ME-107-lower-ocd-person-dee8fe26-42af-4b65-b477-3d8f6a938435",
              "builtID": "me-lower-107",
              "externalID": "ocd-person/dee8fe26-42af-4b65-b477-3d8f6a938435",
              "geometry": null
            },
            "contactInfo": {
              "email": "jane.pringle@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/287",
                "https://legislature.maine.gov/housedems/pringle/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-107-lower-ocd-person-dee8fe26-42af-4b65-b477-3d8f6a938435": 0
        }
      },
      "108": {
        "members": [
          {
            "API_ID": "ocd-person/fd39ad81-2d2b-42d7-ac28-644998318bd3",
            "firstName": "Maureen Fitzgerald",
            "lastName": "Terry",
            "fullName": "Maureen Terry",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/ba4c08ce-72a6-43b5-899a-7bb292b72bbb_terrmf[1].jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "108",
              "chamber": "lower",
              "hashID": "SLDL-ME-108-lower-ocd-person-fd39ad81-2d2b-42d7-ac28-644998318bd3",
              "builtID": "me-lower-108",
              "externalID": "ocd-person/fd39ad81-2d2b-42d7-ac28-644998318bd3",
              "geometry": null
            },
            "contactInfo": {
              "email": "maureen.terry@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/housedems/terrym/index.html",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/54"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-108-lower-ocd-person-fd39ad81-2d2b-42d7-ac28-644998318bd3": 0
        }
      },
      "109": {
        "members": [
          {
            "API_ID": "ocd-person/ad627e35-da3a-4877-a414-591479f567ae",
            "firstName": "James Allen",
            "lastName": "Boyle",
            "fullName": "James Boyle",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/03290f7f-d2dc-40e2-865d-4538208ba81e_Boyle.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "109",
              "chamber": "lower",
              "hashID": "SLDL-ME-109-lower-ocd-person-ad627e35-da3a-4877-a414-591479f567ae",
              "builtID": "me-lower-109",
              "externalID": "ocd-person/ad627e35-da3a-4877-a414-591479f567ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.boyle@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.maine.gov/legis/senate/bio06s.htm",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1452",
                "https://legislature.maine.gov/housedems/boylej/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-109-lower-ocd-person-ad627e35-da3a-4877-a414-591479f567ae": 0
        }
      },
      "110": {
        "members": [
          {
            "API_ID": "ocd-person/56b4d10f-91d4-4e1e-83fb-b3104aa47aa6",
            "firstName": "Stephen W.",
            "lastName": "Moriarty",
            "fullName": "Steve Moriarty",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/031b9de5-e685-4455-bae3-90b14f90bfa3_MoriartyNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "110",
              "chamber": "lower",
              "hashID": "SLDL-ME-110-lower-ocd-person-56b4d10f-91d4-4e1e-83fb-b3104aa47aa6",
              "builtID": "me-lower-110",
              "externalID": "ocd-person/56b4d10f-91d4-4e1e-83fb-b3104aa47aa6",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.moriarty@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/279"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-110-lower-ocd-person-56b4d10f-91d4-4e1e-83fb-b3104aa47aa6": 0
        }
      },
      "111": {
        "members": [
          {
            "API_ID": "ocd-person/d9825f86-280b-4b70-9a6e-2bb7f02e239a",
            "firstName": "Amy Donovan",
            "lastName": "Kuhn",
            "fullName": "Amy Kuhn",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/a99f9866-50ae-4e3a-8eee-10a3e0d00dce_AKUHN.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "111",
              "chamber": "lower",
              "hashID": "SLDL-ME-111-lower-ocd-person-d9825f86-280b-4b70-9a6e-2bb7f02e239a",
              "builtID": "me-lower-111",
              "externalID": "ocd-person/d9825f86-280b-4b70-9a6e-2bb7f02e239a",
              "geometry": null
            },
            "contactInfo": {
              "email": "amy.kuhn@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1474",
                "https://legislature.maine.gov/housedems/kuhn/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-111-lower-ocd-person-d9825f86-280b-4b70-9a6e-2bb7f02e239a": 0
        }
      },
      "112": {
        "members": [
          {
            "API_ID": "ocd-person/3fbfa65e-661c-4b82-a754-9ae0c6956797",
            "firstName": "W. Edward",
            "lastName": "Crockett",
            "fullName": "Ed Crockett",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/ff383c77-b5c1-424d-9de3-1e8a5603b1c9_crockett.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "112",
              "chamber": "lower",
              "hashID": "SLDL-ME-112-lower-ocd-person-3fbfa65e-661c-4b82-a754-9ae0c6956797",
              "builtID": "me-lower-112",
              "externalID": "ocd-person/3fbfa65e-661c-4b82-a754-9ae0c6956797",
              "geometry": null
            },
            "contactInfo": {
              "email": "ed.crockett@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1351"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-112-lower-ocd-person-3fbfa65e-661c-4b82-a754-9ae0c6956797": 0
        }
      },
      "113": {
        "members": [
          {
            "API_ID": "ocd-person/f6eea224-7845-41b1-8e05-030ef3d8d886",
            "firstName": "Grayson",
            "lastName": "Lookner",
            "fullName": "Grayson Lookner",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/bce0954f-21de-45f6-a3a2-2b5485271493_LooknerNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "113",
              "chamber": "lower",
              "hashID": "SLDL-ME-113-lower-ocd-person-f6eea224-7845-41b1-8e05-030ef3d8d886",
              "builtID": "me-lower-113",
              "externalID": "ocd-person/f6eea224-7845-41b1-8e05-030ef3d8d886",
              "geometry": null
            },
            "contactInfo": {
              "email": "grayson.lookner@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1416",
                "https://legislature.maine.gov/housedems/looknerg/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-113-lower-ocd-person-f6eea224-7845-41b1-8e05-030ef3d8d886": 0
        }
      },
      "114": {
        "members": [
          {
            "API_ID": "ocd-person/3b7b05d8-c603-4c16-be4c-65b632703ccd",
            "firstName": "Benjamin T.",
            "lastName": "Collings",
            "fullName": "Benjamin Collings",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/3f39cbd6-16a6-480c-bf80-9bd5767ad9d7_collings-2019.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "114",
              "chamber": "lower",
              "hashID": "SLDL-ME-114-lower-ocd-person-3b7b05d8-c603-4c16-be4c-65b632703ccd",
              "builtID": "me-lower-114",
              "externalID": "ocd-person/3b7b05d8-c603-4c16-be4c-65b632703ccd",
              "geometry": null
            },
            "contactInfo": {
              "email": "benjamin.collings@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/housedems/collingsb/index.html",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/28"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-114-lower-ocd-person-3b7b05d8-c603-4c16-be4c-65b632703ccd": 0
        }
      },
      "115": {
        "members": [
          {
            "API_ID": "ocd-person/b0c25078-ee4b-4638-9523-17997681a6e7",
            "firstName": "Michael F.",
            "lastName": "Brennan",
            "fullName": "Michael Brennan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/3626b818-d26c-4777-99c6-0a04ef459fc3_brennan.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "115",
              "chamber": "lower",
              "hashID": "SLDL-ME-115-lower-ocd-person-b0c25078-ee4b-4638-9523-17997681a6e7",
              "builtID": "me-lower-115",
              "externalID": "ocd-person/b0c25078-ee4b-4638-9523-17997681a6e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.brennan@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1350"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-115-lower-ocd-person-b0c25078-ee4b-4638-9523-17997681a6e7": 0
        }
      },
      "116": {
        "members": [
          {
            "API_ID": "ocd-person/59c5505d-902d-4b6a-b3d5-8e036e640348",
            "firstName": "Samuel Lewis",
            "lastName": "Zager",
            "fullName": "Sam Zager",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/b6cd2a0d-2b96-4103-a43b-bea5aef4a435_Zager.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "116",
              "chamber": "lower",
              "hashID": "SLDL-ME-116-lower-ocd-person-59c5505d-902d-4b6a-b3d5-8e036e640348",
              "builtID": "me-lower-116",
              "externalID": "ocd-person/59c5505d-902d-4b6a-b3d5-8e036e640348",
              "geometry": null
            },
            "contactInfo": {
              "email": "sam.zager@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1418",
                "https://legislature.maine.gov/housedems/zagers/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-116-lower-ocd-person-59c5505d-902d-4b6a-b3d5-8e036e640348": 0
        }
      },
      "117": {
        "members": [
          {
            "API_ID": "ocd-person/7f03a30f-5a77-49b2-8c92-57f7a4238052",
            "firstName": "Matthew W.",
            "lastName": "Moonen",
            "fullName": "Matt Moonen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/5ce2da1b-2227-407f-8f25-9cc5647cf4c5_moonen.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "117",
              "chamber": "lower",
              "hashID": "SLDL-ME-117-lower-ocd-person-7f03a30f-5a77-49b2-8c92-57f7a4238052",
              "builtID": "me-lower-117",
              "externalID": "ocd-person/7f03a30f-5a77-49b2-8c92-57f7a4238052",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.moonen@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/118"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-117-lower-ocd-person-7f03a30f-5a77-49b2-8c92-57f7a4238052": 0
        }
      },
      "118": {
        "members": [
          {
            "API_ID": "ocd-person/a3fd29b8-0e0e-4559-a71f-6f7cf20be60f",
            "firstName": "Rachel",
            "lastName": "Talbot Ross",
            "fullName": "Rachel Talbot Ross",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/5a851e9e-6f42-4467-adb4-dbfc44dee0ad_talbr_[1].jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "118",
              "chamber": "lower",
              "hashID": "SLDL-ME-118-lower-ocd-person-a3fd29b8-0e0e-4559-a71f-6f7cf20be60f",
              "builtID": "me-lower-118",
              "externalID": "ocd-person/a3fd29b8-0e0e-4559-a71f-6f7cf20be60f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rachel.talbotross@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/housedems/talbotrossr/index.html",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/56"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-118-lower-ocd-person-a3fd29b8-0e0e-4559-a71f-6f7cf20be60f": 0
        }
      },
      "119": {
        "members": [
          {
            "API_ID": "ocd-person/d87034c7-fd93-4ba1-a61b-f4ea810506c9",
            "firstName": "Charles A.",
            "lastName": "Skold",
            "fullName": "Charles Skold",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/f62e4eee-633b-458e-9410-219176bec3a2_CSKOLD.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "119",
              "chamber": "lower",
              "hashID": "SLDL-ME-119-lower-ocd-person-d87034c7-fd93-4ba1-a61b-f4ea810506c9",
              "builtID": "me-lower-119",
              "externalID": "ocd-person/d87034c7-fd93-4ba1-a61b-f4ea810506c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.skold@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1475",
                "https://legislature.maine.gov/housedems/skold/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-119-lower-ocd-person-d87034c7-fd93-4ba1-a61b-f4ea810506c9": 0
        }
      },
      "120": {
        "members": [
          {
            "API_ID": "ocd-person/3080d13f-59de-437a-b405-a2313be6ee89",
            "firstName": "Deqa",
            "lastName": "Dhalac",
            "fullName": "Deqa Dhalac",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/57526b13-6bb7-4ccf-b3e4-136ae6208e84_DHALAC.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "120",
              "chamber": "lower",
              "hashID": "SLDL-ME-120-lower-ocd-person-3080d13f-59de-437a-b405-a2313be6ee89",
              "builtID": "me-lower-120",
              "externalID": "ocd-person/3080d13f-59de-437a-b405-a2313be6ee89",
              "geometry": null
            },
            "contactInfo": {
              "email": "deqa.dhalac@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1476",
                "https://legislature.maine.gov/housedems/dhalac/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-120-lower-ocd-person-3080d13f-59de-437a-b405-a2313be6ee89": 0
        }
      },
      "121": {
        "members": [
          {
            "API_ID": "ocd-person/14000c75-b0e9-451c-a081-9121970897de",
            "firstName": "Christopher J.",
            "lastName": "Kessler",
            "fullName": "Christopher Kessler",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/7b6f510d-1915-4b36-846c-7be39a62c2f6_kessler.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "121",
              "chamber": "lower",
              "hashID": "SLDL-ME-121-lower-ocd-person-14000c75-b0e9-451c-a081-9121970897de",
              "builtID": "me-lower-121",
              "externalID": "ocd-person/14000c75-b0e9-451c-a081-9121970897de",
              "geometry": null
            },
            "contactInfo": {
              "email": "christopher.kessler@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1384"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-121-lower-ocd-person-14000c75-b0e9-451c-a081-9121970897de": 0
        }
      },
      "122": {
        "members": [
          {
            "API_ID": "ocd-person/dec943c1-07d5-479a-972f-a3c1cc6a2714",
            "firstName": "Matthew D.",
            "lastName": "Beck",
            "fullName": "Matt Beck",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/10b00e2f-ff9f-4f15-b72a-1b8ed89dc9a8_BECK.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "122",
              "chamber": "lower",
              "hashID": "SLDL-ME-122-lower-ocd-person-dec943c1-07d5-479a-972f-a3c1cc6a2714",
              "builtID": "me-lower-122",
              "externalID": "ocd-person/dec943c1-07d5-479a-972f-a3c1cc6a2714",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.beck@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1509"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-122-lower-ocd-person-dec943c1-07d5-479a-972f-a3c1cc6a2714": 0
        }
      },
      "123": {
        "members": [
          {
            "API_ID": "ocd-person/1c0f311a-f4ab-4091-aaa0-9b0de3612e9e",
            "firstName": "Rebecca J.",
            "lastName": "Millett",
            "fullName": "Reb Millett",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/0ca262c0-9f4e-44f4-92d1-e8fd98aff5ec_Millett.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "123",
              "chamber": "lower",
              "hashID": "SLDL-ME-123-lower-ocd-person-1c0f311a-f4ab-4091-aaa0-9b0de3612e9e",
              "builtID": "me-lower-123",
              "externalID": "ocd-person/1c0f311a-f4ab-4091-aaa0-9b0de3612e9e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rebecca.millett@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1399"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-123-lower-ocd-person-1c0f311a-f4ab-4091-aaa0-9b0de3612e9e": 0
        }
      },
      "124": {
        "members": [
          {
            "API_ID": "ocd-person/140459ab-ba63-410a-819c-aec28c2f2f66",
            "firstName": "Sophia B.",
            "lastName": "Warren",
            "fullName": "Sophie Warren",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/cf752b53-787f-40ea-980b-165536a3a6c7_Warren.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "124",
              "chamber": "lower",
              "hashID": "SLDL-ME-124-lower-ocd-person-140459ab-ba63-410a-819c-aec28c2f2f66",
              "builtID": "me-lower-124",
              "externalID": "ocd-person/140459ab-ba63-410a-819c-aec28c2f2f66",
              "geometry": null
            },
            "contactInfo": {
              "email": "sophia.warren@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1413"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-124-lower-ocd-person-140459ab-ba63-410a-819c-aec28c2f2f66": 0
        }
      },
      "125": {
        "members": [
          {
            "API_ID": "ocd-person/8abb4c0b-ed96-442e-8483-93a05845dcc5",
            "firstName": "Kelly Noonan",
            "lastName": "Murphy",
            "fullName": "Kelly Murphy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/17a7b5d3-e413-418b-bad4-831e543f1099_MURPHY.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "125",
              "chamber": "lower",
              "hashID": "SLDL-ME-125-lower-ocd-person-8abb4c0b-ed96-442e-8483-93a05845dcc5",
              "builtID": "me-lower-125",
              "externalID": "ocd-person/8abb4c0b-ed96-442e-8483-93a05845dcc5",
              "geometry": null
            },
            "contactInfo": {
              "email": "kelly.murphy@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1477",
                "https://legislature.maine.gov/housedems/murphy/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-125-lower-ocd-person-8abb4c0b-ed96-442e-8483-93a05845dcc5": 0
        }
      },
      "126": {
        "members": [
          {
            "API_ID": "ocd-person/81123167-ba38-419a-92e5-fe0102d6a553",
            "firstName": "Andrew M.",
            "lastName": "Gattine",
            "fullName": "Drew Gattine",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/a5278ffe-f50e-4998-83fb-cad702fc9659_gattam.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "126",
              "chamber": "lower",
              "hashID": "SLDL-ME-126-lower-ocd-person-81123167-ba38-419a-92e5-fe0102d6a553",
              "builtID": "me-lower-126",
              "externalID": "ocd-person/81123167-ba38-419a-92e5-fe0102d6a553",
              "geometry": null
            },
            "contactInfo": {
              "email": "andrew.gattine@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.maine.gov/legis/house/hsebios/gattam.htm",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/68"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-126-lower-ocd-person-81123167-ba38-419a-92e5-fe0102d6a553": 0
        }
      },
      "127": {
        "members": [
          {
            "API_ID": "ocd-person/35363ed2-34d2-4f93-bbce-2b0fe26d962e",
            "firstName": "Morgan J.",
            "lastName": "Rielly",
            "fullName": "Morgan Rielly",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/81d0ef8c-588c-417c-84c1-77b41cad5120_RiellyNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "127",
              "chamber": "lower",
              "hashID": "SLDL-ME-127-lower-ocd-person-35363ed2-34d2-4f93-bbce-2b0fe26d962e",
              "builtID": "me-lower-127",
              "externalID": "ocd-person/35363ed2-34d2-4f93-bbce-2b0fe26d962e",
              "geometry": null
            },
            "contactInfo": {
              "email": "morgan.rielly@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1414",
                "https://legislature.maine.gov/housedems/riellym/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-127-lower-ocd-person-35363ed2-34d2-4f93-bbce-2b0fe26d962e": 0
        }
      },
      "128": {
        "members": [
          {
            "API_ID": "ocd-person/d43df0c7-0dfd-4640-9881-f37611c15830",
            "firstName": "Suzanne M.",
            "lastName": "Salisbury",
            "fullName": "Sue Salisbury",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/51e7366f-0985-40da-ad18-0933d15e2d99_SalisburyNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "128",
              "chamber": "lower",
              "hashID": "SLDL-ME-128-lower-ocd-person-d43df0c7-0dfd-4640-9881-f37611c15830",
              "builtID": "me-lower-128",
              "externalID": "ocd-person/d43df0c7-0dfd-4640-9881-f37611c15830",
              "geometry": null
            },
            "contactInfo": {
              "email": "suzanne.salisbury@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1415",
                "https://legislature.maine.gov/housedems/salisburys/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-128-lower-ocd-person-d43df0c7-0dfd-4640-9881-f37611c15830": 0
        }
      },
      "129": {
        "members": [
          {
            "API_ID": "ocd-person/2c5fae3a-eb7c-457c-a75b-73bf55183588",
            "firstName": "Margaret M.",
            "lastName": "O'Neil",
            "fullName": "Maggie O'Neil",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/9c5871d0-8183-4802-b27e-af73f7111666_oneimm[1].jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "129",
              "chamber": "lower",
              "hashID": "SLDL-ME-129-lower-ocd-person-2c5fae3a-eb7c-457c-a75b-73bf55183588",
              "builtID": "me-lower-129",
              "externalID": "ocd-person/2c5fae3a-eb7c-457c-a75b-73bf55183588",
              "geometry": null
            },
            "contactInfo": {
              "email": "margaret.o'neil@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/housedems/oneilm/index.html",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/121"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-129-lower-ocd-person-2c5fae3a-eb7c-457c-a75b-73bf55183588": 0
        }
      },
      "130": {
        "members": [
          {
            "API_ID": "ocd-person/61ef08d1-e345-42af-a56e-4fcd5076f0f5",
            "firstName": "Lynn H.",
            "lastName": "Copeland",
            "fullName": "Lynn Copeland",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/df46c4c5-9e8b-4b1d-85e2-a4f88aec8b09_Copeland.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "130",
              "chamber": "lower",
              "hashID": "SLDL-ME-130-lower-ocd-person-61ef08d1-e345-42af-a56e-4fcd5076f0f5",
              "builtID": "me-lower-130",
              "externalID": "ocd-person/61ef08d1-e345-42af-a56e-4fcd5076f0f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "lynn.copeland@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1410",
                "https://legislature.maine.gov/housedems/copelandl/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-130-lower-ocd-person-61ef08d1-e345-42af-a56e-4fcd5076f0f5": 0
        }
      },
      "131": {
        "members": [
          {
            "API_ID": "ocd-person/3cdb36ad-77a1-401d-8479-630ae970300b",
            "firstName": "Lori",
            "lastName": "Gramlich",
            "fullName": "Lori Gramlich",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/d346883e-e53b-4578-bd40-44eb245624e9_gramlich.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "131",
              "chamber": "lower",
              "hashID": "SLDL-ME-131-lower-ocd-person-3cdb36ad-77a1-401d-8479-630ae970300b",
              "builtID": "me-lower-131",
              "externalID": "ocd-person/3cdb36ad-77a1-401d-8479-630ae970300b",
              "geometry": null
            },
            "contactInfo": {
              "email": "lori.gramlich@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1342"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-131-lower-ocd-person-3cdb36ad-77a1-401d-8479-630ae970300b": 0
        }
      },
      "132": {
        "members": [
          {
            "API_ID": "ocd-person/d61e781e-a755-40eb-988b-919bc1a9783c",
            "firstName": "Erin R.",
            "lastName": "Sheehan",
            "fullName": "Erin Sheehan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/2b79178a-61a4-4761-a4bc-d73b17b1d298_SheehanNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "132",
              "chamber": "lower",
              "hashID": "SLDL-ME-132-lower-ocd-person-d61e781e-a755-40eb-988b-919bc1a9783c",
              "builtID": "me-lower-132",
              "externalID": "ocd-person/d61e781e-a755-40eb-988b-919bc1a9783c",
              "geometry": null
            },
            "contactInfo": {
              "email": "erin.sheehan@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1409",
                "https://legislature.maine.gov/housedems/sheehane/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-132-lower-ocd-person-d61e781e-a755-40eb-988b-919bc1a9783c": 0
        }
      },
      "133": {
        "members": [
          {
            "API_ID": "ocd-person/48305a9e-8f2a-4cea-8689-b93491c82ad5",
            "firstName": "Marc G.",
            "lastName": "Malon",
            "fullName": "Marc Malon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/24740258-3b61-4d37-9179-239dbb5b64ee_MMALON.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "133",
              "chamber": "lower",
              "hashID": "SLDL-ME-133-lower-ocd-person-48305a9e-8f2a-4cea-8689-b93491c82ad5",
              "builtID": "me-lower-133",
              "externalID": "ocd-person/48305a9e-8f2a-4cea-8689-b93491c82ad5",
              "geometry": null
            },
            "contactInfo": {
              "email": "marc.malon@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1478",
                "https://legislature.maine.gov/housedems/malon/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-133-lower-ocd-person-48305a9e-8f2a-4cea-8689-b93491c82ad5": 0
        }
      },
      "134": {
        "members": [
          {
            "API_ID": "ocd-person/10c768fb-c5ef-448e-9759-d76f6c313909",
            "firstName": "Traci",
            "lastName": "Gere",
            "fullName": "Traci Gere",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/593607d8-2dcf-4adc-8351-ebf61eeda842_Gere2021.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "134",
              "chamber": "lower",
              "hashID": "SLDL-ME-134-lower-ocd-person-10c768fb-c5ef-448e-9759-d76f6c313909",
              "builtID": "me-lower-134",
              "externalID": "ocd-person/10c768fb-c5ef-448e-9759-d76f6c313909",
              "geometry": null
            },
            "contactInfo": {
              "email": "traci.gere@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1408",
                "https://legislature.maine.gov/housedems/geret/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-134-lower-ocd-person-10c768fb-c5ef-448e-9759-d76f6c313909": 0
        }
      },
      "135": {
        "members": [
          {
            "API_ID": "ocd-person/f9010476-1b1e-4407-b672-b81cd0ee8ae6",
            "firstName": "Daniel",
            "lastName": "Sayre",
            "fullName": "Dan Sayre",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/5eaede44-a899-42af-a114-5ea2bc5af4db_DSAYRE.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "135",
              "chamber": "lower",
              "hashID": "SLDL-ME-135-lower-ocd-person-f9010476-1b1e-4407-b672-b81cd0ee8ae6",
              "builtID": "me-lower-135",
              "externalID": "ocd-person/f9010476-1b1e-4407-b672-b81cd0ee8ae6",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.sayre@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1479",
                "https://legislature.maine.gov/housedems/sayre/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-135-lower-ocd-person-f9010476-1b1e-4407-b672-b81cd0ee8ae6": 0
        }
      },
      "136": {
        "members": [
          {
            "API_ID": "ocd-person/26bbe967-9b3e-4822-8217-5035b08b253d",
            "firstName": "Heidi H.",
            "lastName": "Sampson",
            "fullName": "Heidi Sampson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/f1758d00-0a1b-4cb2-92ba-41a0958293ab_samphh[1].jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "136",
              "chamber": "lower",
              "hashID": "SLDL-ME-136-lower-ocd-person-26bbe967-9b3e-4822-8217-5035b08b253d",
              "builtID": "me-lower-136",
              "externalID": "ocd-person/26bbe967-9b3e-4822-8217-5035b08b253d",
              "geometry": null
            },
            "contactInfo": {
              "email": "heidi.sampson@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mainehousegop.org/members/sampson-heidi/",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/137"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-136-lower-ocd-person-26bbe967-9b3e-4822-8217-5035b08b253d": 0
        }
      },
      "137": {
        "members": [
          {
            "API_ID": "ocd-person/69e87146-73a0-434e-9cbb-8d7c80e7ce30",
            "firstName": "Nathan Michael",
            "lastName": "Carlow",
            "fullName": "Nathan Carlow",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/a06a6e19-aeb1-4d9f-b827-7ff997065c8d_CarlowNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "137",
              "chamber": "lower",
              "hashID": "SLDL-ME-137-lower-ocd-person-69e87146-73a0-434e-9cbb-8d7c80e7ce30",
              "builtID": "me-lower-137",
              "externalID": "ocd-person/69e87146-73a0-434e-9cbb-8d7c80e7ce30",
              "geometry": null
            },
            "contactInfo": {
              "email": "nathan.carlow@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1411",
                "https://mainehousegop.org/members/carlow-nathan/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-137-lower-ocd-person-69e87146-73a0-434e-9cbb-8d7c80e7ce30": 0
        }
      },
      "138": {
        "members": [
          {
            "API_ID": "ocd-person/134a1697-216a-4761-827f-840f72c640a0",
            "firstName": "Mark John",
            "lastName": "Blier",
            "fullName": "Mark Blier",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/0b41e77d-6373-4758-bc30-be7babfa921e_BlierNEW.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "138",
              "chamber": "lower",
              "hashID": "SLDL-ME-138-lower-ocd-person-134a1697-216a-4761-827f-840f72c640a0",
              "builtID": "me-lower-138",
              "externalID": "ocd-person/134a1697-216a-4761-827f-840f72c640a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.blier@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1344",
                "https://mainehousegop.org/members/blier-mark/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-138-lower-ocd-person-134a1697-216a-4761-827f-840f72c640a0": 0
        }
      },
      "139": {
        "members": [
          {
            "API_ID": "ocd-person/30414bf4-13ad-4bd5-a1d9-3cf11be6464d",
            "firstName": "David",
            "lastName": "Woodsome",
            "fullName": "Woody Woodsome",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/uploads/visual_edit/woodsome-5x7-2.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "139",
              "chamber": "lower",
              "hashID": "SLDL-ME-139-lower-ocd-person-30414bf4-13ad-4bd5-a1d9-3cf11be6464d",
              "builtID": "me-lower-139",
              "externalID": "ocd-person/30414bf4-13ad-4bd5-a1d9-3cf11be6464d",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.woodsome@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-33",
                "https://legislature.maine.gov/District-33",
                "https://mesenategop.com/your-senators/senator-david-woodsome/",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1480",
                "https://mainehousegop.org/members/woodsome-david/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-139-lower-ocd-person-30414bf4-13ad-4bd5-a1d9-3cf11be6464d": 0
        }
      },
      "140": {
        "members": [
          {
            "API_ID": "ocd-person/8a75e2da-2389-4052-9592-1e607ba2dfc1",
            "firstName": "Wayne R.",
            "lastName": "Parry",
            "fullName": "Wayne Parry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/64b98aaf-c97d-41c4-88d7-3139d1d72339_Parry.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "140",
              "chamber": "lower",
              "hashID": "SLDL-ME-140-lower-ocd-person-8a75e2da-2389-4052-9592-1e607ba2dfc1",
              "builtID": "me-lower-140",
              "externalID": "ocd-person/8a75e2da-2389-4052-9592-1e607ba2dfc1",
              "geometry": null
            },
            "contactInfo": {
              "email": "wayne.parry@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/124",
                "https://mainehousegop.org/members/parry-wayne/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-140-lower-ocd-person-8a75e2da-2389-4052-9592-1e607ba2dfc1": 0
        }
      },
      "141": {
        "members": [
          {
            "API_ID": "ocd-person/85c0e0b8-2654-4da5-96e6-8745ec68d0af",
            "firstName": "Lucas John",
            "lastName": "Lanigan",
            "fullName": "Lucas Lanigan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/beba31fd-c03d-41e8-91d9-97bf04b13254_LANIGAN.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "141",
              "chamber": "lower",
              "hashID": "SLDL-ME-141-lower-ocd-person-85c0e0b8-2654-4da5-96e6-8745ec68d0af",
              "builtID": "me-lower-141",
              "externalID": "ocd-person/85c0e0b8-2654-4da5-96e6-8745ec68d0af",
              "geometry": null
            },
            "contactInfo": {
              "email": "lucas.lanigan@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1481",
                "https://mainehousegop.org/members/lanigan-lucas/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-141-lower-ocd-person-85c0e0b8-2654-4da5-96e6-8745ec68d0af": 0
        }
      },
      "142": {
        "members": [
          {
            "API_ID": "ocd-person/56960ccd-b154-42ae-9fb3-c323e07c23b1",
            "firstName": "Anne-Marie",
            "lastName": "Mastraccio",
            "fullName": "Anne-Marie Mastraccio",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/989eebc7-717b-474a-9a55-203b709d019b_AMASTRACCIO.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "142",
              "chamber": "lower",
              "hashID": "SLDL-ME-142-lower-ocd-person-56960ccd-b154-42ae-9fb3-c323e07c23b1",
              "builtID": "me-lower-142",
              "externalID": "ocd-person/56960ccd-b154-42ae-9fb3-c323e07c23b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "anne-marie.mastraccio@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/111"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-142-lower-ocd-person-56960ccd-b154-42ae-9fb3-c323e07c23b1": 0
        }
      },
      "143": {
        "members": [
          {
            "API_ID": "ocd-person/8646c09c-1d84-4065-bcf2-8be6a8baf494",
            "firstName": "Ann Marie",
            "lastName": "Fredericks",
            "fullName": "Ann Marie Fredericks",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/d04ae08f-1582-4de6-a34e-bdd72386622e_FREDRICKS.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "143",
              "chamber": "lower",
              "hashID": "SLDL-ME-143-lower-ocd-person-8646c09c-1d84-4065-bcf2-8be6a8baf494",
              "builtID": "me-lower-143",
              "externalID": "ocd-person/8646c09c-1d84-4065-bcf2-8be6a8baf494",
              "geometry": null
            },
            "contactInfo": {
              "email": "ann.fredericks@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1468",
                "https://mainehousegop.org/members/fredericks-ann/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-143-lower-ocd-person-8646c09c-1d84-4065-bcf2-8be6a8baf494": 0
        }
      },
      "144": {
        "members": [
          {
            "API_ID": "ocd-person/2288cf22-64f4-4f1f-b5e3-b1ada5c72914",
            "firstName": "Jeffrey Sean",
            "lastName": "Adams",
            "fullName": "Jeff Adams",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/19be31f4-e0c7-4466-bcc7-ba2191307f59_ADAMS.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "144",
              "chamber": "lower",
              "hashID": "SLDL-ME-144-lower-ocd-person-2288cf22-64f4-4f1f-b5e3-b1ada5c72914",
              "builtID": "me-lower-144",
              "externalID": "ocd-person/2288cf22-64f4-4f1f-b5e3-b1ada5c72914",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeffrey.adams@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1469",
                "https://mainehousegop.org/members/adams-jeffrey/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-144-lower-ocd-person-2288cf22-64f4-4f1f-b5e3-b1ada5c72914": 0
        }
      },
      "145": {
        "members": [
          {
            "API_ID": "ocd-person/fe64cd57-46c8-4d6a-bef2-2e205e04e311",
            "firstName": "Daniel J.",
            "lastName": "Hobbs",
            "fullName": "Daniel Hobbs",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/9c0ec8c3-1be8-473c-a835-3be9fa538e58_hobbs.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "145",
              "chamber": "lower",
              "hashID": "SLDL-ME-145-lower-ocd-person-fe64cd57-46c8-4d6a-bef2-2e205e04e311",
              "builtID": "me-lower-145",
              "externalID": "ocd-person/fe64cd57-46c8-4d6a-bef2-2e205e04e311",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.hobbs@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1338"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-145-lower-ocd-person-fe64cd57-46c8-4d6a-bef2-2e205e04e311": 0
        }
      },
      "146": {
        "members": [
          {
            "API_ID": "ocd-person/7dea9101-48ec-4f1a-871e-a4f1c98b8914",
            "firstName": "Walter Gerard",
            "lastName": "Runte",
            "fullName": "Gerry Runte",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/a2caaeac-ae5a-4ffd-b7a6-45e1840bba4b_RUNTE.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "146",
              "chamber": "lower",
              "hashID": "SLDL-ME-146-lower-ocd-person-7dea9101-48ec-4f1a-871e-a4f1c98b8914",
              "builtID": "me-lower-146",
              "externalID": "ocd-person/7dea9101-48ec-4f1a-871e-a4f1c98b8914",
              "geometry": null
            },
            "contactInfo": {
              "email": "gerry.runte@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1470",
                "https://legislature.maine.gov/housedems/runte/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-146-lower-ocd-person-7dea9101-48ec-4f1a-871e-a4f1c98b8914": 0
        }
      },
      "147": {
        "members": [
          {
            "API_ID": "ocd-person/82f5c446-4f81-4c54-98ae-7b302003d39d",
            "firstName": "Holly T.",
            "lastName": "Sargent",
            "fullName": "Holly Sargent",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/191052f1-463d-4a44-bfdc-ecde7bb45ec0_SARGENT.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "147",
              "chamber": "lower",
              "hashID": "SLDL-ME-147-lower-ocd-person-82f5c446-4f81-4c54-98ae-7b302003d39d",
              "builtID": "me-lower-147",
              "externalID": "ocd-person/82f5c446-4f81-4c54-98ae-7b302003d39d",
              "geometry": null
            },
            "contactInfo": {
              "email": "holly.sargent@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1471",
                "https://legislature.maine.gov/housedems/sargent/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-147-lower-ocd-person-82f5c446-4f81-4c54-98ae-7b302003d39d": 0
        }
      },
      "148": {
        "members": [
          {
            "API_ID": "ocd-person/be3df38c-150a-4e3c-8c16-6e97bdbeadc4",
            "firstName": "Thomas A.",
            "lastName": "Lavigne",
            "fullName": "Thomas Lavigne",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/fc7494df-6e51-45a2-9716-43f8d0f0b162_LAVIGNE.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "148",
              "chamber": "lower",
              "hashID": "SLDL-ME-148-lower-ocd-person-be3df38c-150a-4e3c-8c16-6e97bdbeadc4",
              "builtID": "me-lower-148",
              "externalID": "ocd-person/be3df38c-150a-4e3c-8c16-6e97bdbeadc4",
              "geometry": null
            },
            "contactInfo": {
              "email": "thomas.lavigne@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1472",
                "https://mainehousegop.org/members/lavigne-thomas/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-148-lower-ocd-person-be3df38c-150a-4e3c-8c16-6e97bdbeadc4": 0
        }
      },
      "149": {
        "members": [
          {
            "API_ID": "ocd-person/524f6cce-8333-442a-aff3-2e92bd9df894",
            "firstName": "Tiffany D.",
            "lastName": "Roberts",
            "fullName": "Tiffany Roberts",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/524ac892-9d68-4268-8883-b94a2e993161_Roberts.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "149",
              "chamber": "lower",
              "hashID": "SLDL-ME-149-lower-ocd-person-524f6cce-8333-442a-aff3-2e92bd9df894",
              "builtID": "me-lower-149",
              "externalID": "ocd-person/524f6cce-8333-442a-aff3-2e92bd9df894",
              "geometry": null
            },
            "contactInfo": {
              "email": "tiffany.roberts@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1385"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-149-lower-ocd-person-524f6cce-8333-442a-aff3-2e92bd9df894": 0
        }
      },
      "150": {
        "members": [
          {
            "API_ID": "ocd-person/e26fdc8a-3099-4ea4-ad2d-1ccdc45ce1e6",
            "firstName": "Michele",
            "lastName": "Meyer",
            "fullName": "Michele Meyer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/b1004d88-844a-4d6d-9e4a-e6a329b430c9_meyer.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "150",
              "chamber": "lower",
              "hashID": "SLDL-ME-150-lower-ocd-person-e26fdc8a-3099-4ea4-ad2d-1ccdc45ce1e6",
              "builtID": "me-lower-150",
              "externalID": "ocd-person/e26fdc8a-3099-4ea4-ad2d-1ccdc45ce1e6",
              "geometry": null
            },
            "contactInfo": {
              "email": "michele.meyer@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1337"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-150-lower-ocd-person-e26fdc8a-3099-4ea4-ad2d-1ccdc45ce1e6": 0
        }
      },
      "151": {
        "members": [
          {
            "API_ID": "ocd-person/375e5fda-9367-4ce3-a209-1c772c32219f",
            "firstName": "Kristi Michele",
            "lastName": "Mathieson",
            "fullName": "Kristi Mathieson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/61a4ca7c-418f-4f26-a7eb-e355ea89333e_Mathieson.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "151",
              "chamber": "lower",
              "hashID": "SLDL-ME-151-lower-ocd-person-375e5fda-9367-4ce3-a209-1c772c32219f",
              "builtID": "me-lower-151",
              "externalID": "ocd-person/375e5fda-9367-4ce3-a209-1c772c32219f",
              "geometry": null
            },
            "contactInfo": {
              "email": "kristi.mathieson@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1406",
                "https://legislature.maine.gov/housedems/mathiesonk/index.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-151-lower-ocd-person-375e5fda-9367-4ce3-a209-1c772c32219f": 0
        }
      },
      "Passamaquoddy Tribe": {
        "members": [
          {
            "API_ID": "ocd-person/906cd606-c6ff-41e5-87a6-e038b711fa61",
            "firstName": "Aaron M.",
            "lastName": "Dana",
            "fullName": "Aaron Dana",
            "gender": "Male",
            "party": "independent",
            "image": "https://legislature.maine.gov/house/house/Repository/MemberProfiles/27852f04-e612-46a5-9727-1bbd1d5e48e0_DANA.jpg",
            "title": "ME Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "Passamaquoddy Tribe",
              "chamber": "lower",
              "hashID": "SLDL-ME-Passamaquoddy Tribe-lower-ocd-person-906cd606-c6ff-41e5-87a6-e038b711fa61",
              "builtID": "me-lower-passamaquoddy tribe",
              "externalID": "ocd-person/906cd606-c6ff-41e5-87a6-e038b711fa61",
              "geometry": null
            },
            "contactInfo": {
              "email": "aaron.dana@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1507"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ME-Passamaquoddy Tribe-lower-ocd-person-906cd606-c6ff-41e5-87a6-e038b711fa61": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/78ccbb5e-2908-43c7-b609-5ed18f8ff685",
            "firstName": "Troy Dale",
            "lastName": "Jackson",
            "fullName": "Troy Jackson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/troy-jackson-2.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-ME-1-upper-ocd-person-78ccbb5e-2908-43c7-b609-5ed18f8ff685",
              "builtID": "me-upper-1",
              "externalID": "ocd-person/78ccbb5e-2908-43c7-b609-5ed18f8ff685",
              "geometry": null
            },
            "contactInfo": {
              "email": "troy.jackson@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-1",
                "https://legislature.maine.gov/District-1",
                "https://www.troyjackson.org",
                "https://legislature.maine.gov/District1",
                "https://legislature.maine.gov/district1",
                "https://troyjackson.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-1-upper-ocd-person-78ccbb5e-2908-43c7-b609-5ed18f8ff685": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/bd417e77-81d9-4e60-8b2a-b940da10bc37",
            "firstName": "Harold L.",
            "lastName": "Stewart",
            "fullName": "Trey Stewart",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/uploads/visual_edit/stewart-harold-trey-1.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-ME-2-upper-ocd-person-bd417e77-81d9-4e60-8b2a-b940da10bc37",
              "builtID": "me-upper-2",
              "externalID": "ocd-person/bd417e77-81d9-4e60-8b2a-b940da10bc37",
              "geometry": null
            },
            "contactInfo": {
              "email": "trey.stewart@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-2",
                "https://legislature.maine.gov/District-2",
                "https://mesenategop.com/senator-trey-stewart/",
                "https://legislature.maine.gov/District2",
                "https://mesenategop.com/your-senators/senator-trey-stewart/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-2-upper-ocd-person-bd417e77-81d9-4e60-8b2a-b940da10bc37": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/72193c8f-2b94-4e56-bff4-482bde6037eb",
            "firstName": "Bradlee Thomas",
            "lastName": "Farrin",
            "fullName": "Brad Farrin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/uploads/visual_edit/farrin-bradlee-1.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-ME-3-upper-ocd-person-72193c8f-2b94-4e56-bff4-482bde6037eb",
              "builtID": "me-upper-3",
              "externalID": "ocd-person/72193c8f-2b94-4e56-bff4-482bde6037eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "brad.farrin@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-3",
                "https://legislature.maine.gov/District-3",
                "https://mesenategop.com/your-senators/senator-brad-farrin/",
                "https://legislature.maine.gov/District3",
                "https://legislature.maine.gov/district3"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-3-upper-ocd-person-72193c8f-2b94-4e56-bff4-482bde6037eb": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/3439fe0c-fb43-4977-ac8c-1dd1f8f10e96",
            "firstName": "Stacey K.",
            "lastName": "Guerin",
            "fullName": "Stacey Guerin",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/uploads/visual_edit/guerin-headshot-outdoors-rgb.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-ME-4-upper-ocd-person-3439fe0c-fb43-4977-ac8c-1dd1f8f10e96",
              "builtID": "me-upper-4",
              "externalID": "ocd-person/3439fe0c-fb43-4977-ac8c-1dd1f8f10e96",
              "geometry": null
            },
            "contactInfo": {
              "email": "stacey.guerin@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-10",
                "https://legislature.maine.gov/District-10",
                "https://legislature.maine.gov/District4",
                "https://legislature.maine.gov/district4",
                "https://mesenategop.com/your-senators/senator-stacey-guerin/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-4-upper-ocd-person-3439fe0c-fb43-4977-ac8c-1dd1f8f10e96": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/fa3e8ae3-757e-44fb-9451-6bf381d748ce",
            "firstName": "Russell J.",
            "lastName": "Black",
            "fullName": "Russell Black",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/uploads/visual_edit/black-5x7.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-ME-5-upper-ocd-person-fa3e8ae3-757e-44fb-9451-6bf381d748ce",
              "builtID": "me-upper-5",
              "externalID": "ocd-person/fa3e8ae3-757e-44fb-9451-6bf381d748ce",
              "geometry": null
            },
            "contactInfo": {
              "email": "russell.black@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-17",
                "https://legislature.maine.gov/District-17",
                "https://mesenategop.com/your-senators/senator-russell-black/",
                "https://legislature.maine.gov/District5"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-5-upper-ocd-person-fa3e8ae3-757e-44fb-9451-6bf381d748ce": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/24bd9e6e-f10d-4e0d-a4b1-72bbe45f4e0d",
            "firstName": "Marianne",
            "lastName": "Moore",
            "fullName": "Marianne Moore",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/uploads/visual_edit/moore-5x7.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-ME-6-upper-ocd-person-24bd9e6e-f10d-4e0d-a4b1-72bbe45f4e0d",
              "builtID": "me-upper-6",
              "externalID": "ocd-person/24bd9e6e-f10d-4e0d-a4b1-72bbe45f4e0d",
              "geometry": null
            },
            "contactInfo": {
              "email": "marianne.moore@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-6",
                "https://legislature.maine.gov/District-6",
                "https://www.mesenategop.com/blog/home/your-senators/senator-marianne-moore/",
                "https://mesenategop.com/your-senators/senator-marianne-moore/",
                "https://legislature.maine.gov/District6"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-6-upper-ocd-person-24bd9e6e-f10d-4e0d-a4b1-72bbe45f4e0d": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/e53a09e6-a38e-4622-97e4-c40bf2d93faa",
            "firstName": "Nicole",
            "lastName": "Grohoski",
            "fullName": "Nicole Grohoski",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/senator-grohoski-photo-web.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-ME-7-upper-ocd-person-e53a09e6-a38e-4622-97e4-c40bf2d93faa",
              "builtID": "me-upper-7",
              "externalID": "ocd-person/e53a09e6-a38e-4622-97e4-c40bf2d93faa",
              "geometry": null
            },
            "contactInfo": {
              "email": "nicole.grohoski@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/1387",
                "https://legislature.maine.gov/district-7",
                "https://legislature.maine.gov/District7",
                "https://www.mainesenate.org/senator/senator/nicole-grohoski/",
                "https://legislature.maine.gov/district7"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-7-upper-ocd-person-e53a09e6-a38e-4622-97e4-c40bf2d93faa": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/a18c5356-5572-4a99-8a7c-e86105afb821",
            "firstName": "Mike",
            "lastName": "Tipping",
            "fullName": "Mike Tipping",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/tipping-1.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-ME-8-upper-ocd-person-a18c5356-5572-4a99-8a7c-e86105afb821",
              "builtID": "me-upper-8",
              "externalID": "ocd-person/a18c5356-5572-4a99-8a7c-e86105afb821",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.tipping@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District8",
                "https://www.mainesenate.org/senator/senator/senator-mike-tipping/",
                "https://legislature.maine.gov/district8"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-8-upper-ocd-person-a18c5356-5572-4a99-8a7c-e86105afb821": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/34231d57-22e2-4f2f-be41-d2734fa6211f",
            "firstName": "Joseph M.",
            "lastName": "Baldacci",
            "fullName": "Joe Baldacci",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/baldacci-1024x683.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-ME-9-upper-ocd-person-34231d57-22e2-4f2f-be41-d2734fa6211f",
              "builtID": "me-upper-9",
              "externalID": "ocd-person/34231d57-22e2-4f2f-be41-d2734fa6211f",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.baldacci@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-9",
                "https://legislature.maine.gov/District-9",
                "https://legislature.maine.gov/District9",
                "https://www.mainesenate.org/senator/senator/joe-baldacci/",
                "https://legislature.maine.gov/district9"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-9-upper-ocd-person-34231d57-22e2-4f2f-be41-d2734fa6211f": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/7704cf0d-3b4e-4253-ac4c-394c9e64f543",
            "firstName": "Peter A.",
            "lastName": "Lyford",
            "fullName": "Peter Lyford",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/uploads/visual_edit/lyford-peter.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-ME-10-upper-ocd-person-7704cf0d-3b4e-4253-ac4c-394c9e64f543",
              "builtID": "me-upper-10",
              "externalID": "ocd-person/7704cf0d-3b4e-4253-ac4c-394c9e64f543",
              "geometry": null
            },
            "contactInfo": {
              "email": "peter.lyford@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/103",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/103",
                "https://legislature.maine.gov/District10",
                "https://mesenategop.com/your-senators/senator-peter-lyford/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-10-upper-ocd-person-7704cf0d-3b4e-4253-ac4c-394c9e64f543": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/6d397c09-c2d3-41ff-b33a-c42efae4f187",
            "firstName": "Glenn",
            "lastName": "Curry",
            "fullName": "Chip Curry",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/chipcurry-web-1024x683.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-ME-11-upper-ocd-person-6d397c09-c2d3-41ff-b33a-c42efae4f187",
              "builtID": "me-upper-11",
              "externalID": "ocd-person/6d397c09-c2d3-41ff-b33a-c42efae4f187",
              "geometry": null
            },
            "contactInfo": {
              "email": "chip.curry@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-11",
                "https://legislature.maine.gov/District-11",
                "https://www.mainesenate.org/senator/senator/erin-herbig/",
                "https://legislature.maine.gov/District11",
                "https://www.mainesenate.org/senator/senator/chip-curry/",
                "https://legislature.maine.gov/district11"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-11-upper-ocd-person-6d397c09-c2d3-41ff-b33a-c42efae4f187": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/db11e611-94a4-4451-8486-f941a532789d",
            "firstName": "Anne",
            "lastName": "Beebe-Center",
            "fullName": "Pinny Beebe-Center",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/beebe-center-1024x1024.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-ME-12-upper-ocd-person-db11e611-94a4-4451-8486-f941a532789d",
              "builtID": "me-upper-12",
              "externalID": "ocd-person/db11e611-94a4-4451-8486-f941a532789d",
              "geometry": null
            },
            "contactInfo": {
              "email": "anne.beebe-center@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District12",
                "https://legislature.maine.gov/district12",
                "https://www.mainesenate.org/senator/senator/pinny-beebe-center/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-12-upper-ocd-person-db11e611-94a4-4451-8486-f941a532789d": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/38a3d5d7-cc62-4479-bfba-03d40099e7cd",
            "firstName": "Cameron",
            "lastName": "Reny",
            "fullName": "Cameron Reny",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/reny-2.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-ME-13-upper-ocd-person-38a3d5d7-cc62-4479-bfba-03d40099e7cd",
              "builtID": "me-upper-13",
              "externalID": "ocd-person/38a3d5d7-cc62-4479-bfba-03d40099e7cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "cameron.reny@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District13",
                "https://legislature.maine.gov/district13",
                "https://www.mainesenate.org/senator/senator/senator-cameron-reny/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-13-upper-ocd-person-38a3d5d7-cc62-4479-bfba-03d40099e7cd": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/5a475e6f-5277-425e-bb09-1c55facbc736",
            "firstName": "Craig V.",
            "lastName": "Hickman",
            "fullName": "Craig Hickman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/hickman-1024x1024.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-ME-14-upper-ocd-person-5a475e6f-5277-425e-bb09-1c55facbc736",
              "builtID": "me-upper-14",
              "externalID": "ocd-person/5a475e6f-5277-425e-bb09-1c55facbc736",
              "geometry": null
            },
            "contactInfo": {
              "email": "craig.hickman@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-14",
                "https://legislature.maine.gov/District-14",
                "https://www.mainesenate.org",
                "https://www.mainesenate.org/senator/senator/craig-hickman/",
                "https://legislature.maine.gov/District14"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-14-upper-ocd-person-5a475e6f-5277-425e-bb09-1c55facbc736": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/51a68891-f5de-4f19-b373-9c39183924a4",
            "firstName": "Matthew G.",
            "lastName": "Pouliot",
            "fullName": "Matt Pouliot",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/uploads/visual_edit/pouliot-5x7.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-ME-15-upper-ocd-person-51a68891-f5de-4f19-b373-9c39183924a4",
              "builtID": "me-upper-15",
              "externalID": "ocd-person/51a68891-f5de-4f19-b373-9c39183924a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.pouliot@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-15",
                "https://legislature.maine.gov/District-15",
                "https://mesenategop.com/your-senators/senator-matthew-pouliot/",
                "https://legislature.maine.gov/District15",
                "https://legislature.maine.gov/district15"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-15-upper-ocd-person-51a68891-f5de-4f19-b373-9c39183924a4": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/c722e491-8448-4049-9596-4744f4b9cd53",
            "firstName": "David",
            "lastName": "LaFountain",
            "fullName": "Dave LaFountain",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/lafountain.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-ME-16-upper-ocd-person-c722e491-8448-4049-9596-4744f4b9cd53",
              "builtID": "me-upper-16",
              "externalID": "ocd-person/c722e491-8448-4049-9596-4744f4b9cd53",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.lafountain@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District16",
                "https://legislature.maine.gov/district16",
                "https://www.mainesenate.org/senator/senator/dave-lafountain/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-16-upper-ocd-person-c722e491-8448-4049-9596-4744f4b9cd53": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/3ef077c6-482d-4dc2-92ef-69ac2a09bf06",
            "firstName": "Jeffrey L.",
            "lastName": "Timberlake",
            "fullName": "Jeff Timberlake",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/uploads/visual_edit/timberlake-jeff-3.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-ME-17-upper-ocd-person-3ef077c6-482d-4dc2-92ef-69ac2a09bf06",
              "builtID": "me-upper-17",
              "externalID": "ocd-person/3ef077c6-482d-4dc2-92ef-69ac2a09bf06",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeffrey.timberlake@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-22",
                "https://legislature.maine.gov/District-22",
                "https://legislature.maine.gov/District17",
                "https://mesenategop.com/your-senators/senator-jeff-timberlake/",
                "https://legislature.maine.gov/district17"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-17-upper-ocd-person-3ef077c6-482d-4dc2-92ef-69ac2a09bf06": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/4276be86-7dfa-4817-9792-52622b93ff40",
            "firstName": "Richard A.",
            "lastName": "Bennett",
            "fullName": "Rick Bennett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/uploads/visual_edit/bennett-5x7.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-ME-18-upper-ocd-person-4276be86-7dfa-4817-9792-52622b93ff40",
              "builtID": "me-upper-18",
              "externalID": "ocd-person/4276be86-7dfa-4817-9792-52622b93ff40",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.bennett@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-19",
                "https://legislature.maine.gov/District-19",
                "https://mesenategop.com/senator-rick-bennett/",
                "https://mesenategop.com/your-senators/senator-rick-bennett/",
                "https://legislature.maine.gov/District18"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-18-upper-ocd-person-4276be86-7dfa-4817-9792-52622b93ff40": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/9f41dbe3-e183-47f5-b041-b485b457036f",
            "firstName": "Lisa",
            "lastName": "Keim",
            "fullName": "Lisa Keim",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.maine.gov/uploads/visual_edit/lisa-keim-headshot.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-ME-19-upper-ocd-person-9f41dbe3-e183-47f5-b041-b485b457036f",
              "builtID": "me-upper-19",
              "externalID": "ocd-person/9f41dbe3-e183-47f5-b041-b485b457036f",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisa.keim@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-18",
                "https://legislature.maine.gov/District-18",
                "https://mesenategop.com/your-senators/senator-lisa-keim/",
                "https://legislature.maine.gov/District19"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-19-upper-ocd-person-9f41dbe3-e183-47f5-b041-b485b457036f": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/d24ac656-4b2e-416f-83ce-b5f32e3d5825",
            "firstName": "Eric L.",
            "lastName": "Brakey",
            "fullName": "Eric Brakey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/uploads/visual_edit/brakey.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-ME-20-upper-ocd-person-d24ac656-4b2e-416f-83ce-b5f32e3d5825",
              "builtID": "me-upper-20",
              "externalID": "ocd-person/d24ac656-4b2e-416f-83ce-b5f32e3d5825",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.brakey@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District20",
                "https://mesenategop.com/your-senators/senator-eric-brakey/",
                "https://legislature.maine.gov/district20"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-20-upper-ocd-person-d24ac656-4b2e-416f-83ce-b5f32e3d5825": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/062977a2-a482-4c4f-b053-75cae4bdce31",
            "firstName": "Margaret R.",
            "lastName": "Rotundo",
            "fullName": "Peggy Rotundo",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/rotundo.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-ME-21-upper-ocd-person-062977a2-a482-4c4f-b053-75cae4bdce31",
              "builtID": "me-upper-21",
              "externalID": "ocd-person/062977a2-a482-4c4f-b053-75cae4bdce31",
              "geometry": null
            },
            "contactInfo": {
              "email": "margaret.rotundo@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District21",
                "https://legislature.maine.gov/district21",
                "https://www.mainesenate.org/senator/senator/senator-margaret-rotundo/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-21-upper-ocd-person-062977a2-a482-4c4f-b053-75cae4bdce31": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/32c9dbd7-beb7-481e-b0f4-22f3eaa00e50",
            "firstName": "James",
            "lastName": "Libby",
            "fullName": "Jim Libby",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/uploads/visual_edit/libby-1.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-ME-22-upper-ocd-person-32c9dbd7-beb7-481e-b0f4-22f3eaa00e50",
              "builtID": "me-upper-22",
              "externalID": "ocd-person/32c9dbd7-beb7-481e-b0f4-22f3eaa00e50",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.libby@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District22",
                "https://legislature.maine.gov/district22",
                "https://mesenategop.com/your-senators/senator-james-libby/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-22-upper-ocd-person-32c9dbd7-beb7-481e-b0f4-22f3eaa00e50": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/863a9ef0-5916-40d6-9929-7a7b498af0ec",
            "firstName": "Matthea Elisabeth Larsen",
            "lastName": "Daughtry",
            "fullName": "Mattie Daughtry",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/daughtry-matthea-2.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-ME-23-upper-ocd-person-863a9ef0-5916-40d6-9929-7a7b498af0ec",
              "builtID": "me-upper-23",
              "externalID": "ocd-person/863a9ef0-5916-40d6-9929-7a7b498af0ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "mattie.daughtry@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-24",
                "https://legislature.maine.gov/District-24",
                "https://legislature.maine.gov/District23",
                "https://www.mainesenate.org/senator/senator/mattie-daughtry/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-23-upper-ocd-person-863a9ef0-5916-40d6-9929-7a7b498af0ec": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/7506df16-2523-4f35-85a2-d8fdf5c59b38",
            "firstName": "Eloise A.",
            "lastName": "Vitelli",
            "fullName": "Eloise Vitelli",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/vitelliweb2020-683x1024.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-ME-24-upper-ocd-person-7506df16-2523-4f35-85a2-d8fdf5c59b38",
              "builtID": "me-upper-24",
              "externalID": "ocd-person/7506df16-2523-4f35-85a2-d8fdf5c59b38",
              "geometry": null
            },
            "contactInfo": {
              "email": "eloise.vitelli@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-23",
                "https://legislature.maine.gov/District-23",
                "https://legislature.maine.gov/District24",
                "https://www.mainesenate.org/senator/senator/eloise-vitelli/",
                "https://legislature.maine.gov/district24"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-24-upper-ocd-person-7506df16-2523-4f35-85a2-d8fdf5c59b38": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/0d692e7b-535a-498b-8072-a258806fecb4",
            "firstName": "Teresa S.",
            "lastName": "Pierce",
            "fullName": "Teresa Pierce",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/pierce-web-748x1024.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-ME-25-upper-ocd-person-0d692e7b-535a-498b-8072-a258806fecb4",
              "builtID": "me-upper-25",
              "externalID": "ocd-person/0d692e7b-535a-498b-8072-a258806fecb4",
              "geometry": null
            },
            "contactInfo": {
              "email": "teresa.pierce@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/130",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/130",
                "https://legislature.maine.gov/District25",
                "https://legislature.maine.gov/district25",
                "https://www.mainesenate.org/senator/senator/senator-teresa-pierce/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-25-upper-ocd-person-0d692e7b-535a-498b-8072-a258806fecb4": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/2715d740-16c6-49ec-9224-d3d9a24e47ee",
            "firstName": "Timothy",
            "lastName": "Nangle",
            "fullName": "Tim Nangle",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/nangle.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-ME-26-upper-ocd-person-2715d740-16c6-49ec-9224-d3d9a24e47ee",
              "builtID": "me-upper-26",
              "externalID": "ocd-person/2715d740-16c6-49ec-9224-d3d9a24e47ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "timothy.nangle@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District26",
                "https://legislature.maine.gov/district26",
                "https://www.mainesenate.org/senator/senator/tim-nangle/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-26-upper-ocd-person-2715d740-16c6-49ec-9224-d3d9a24e47ee": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/c2268745-e66c-42d5-93bf-3b52819006e9",
            "firstName": "Jill",
            "lastName": "Duson",
            "fullName": "Jill Duson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/duson-3.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-ME-27-upper-ocd-person-c2268745-e66c-42d5-93bf-3b52819006e9",
              "builtID": "me-upper-27",
              "externalID": "ocd-person/c2268745-e66c-42d5-93bf-3b52819006e9",
              "geometry": null
            },
            "contactInfo": {
              "email": "jill.duson@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District27",
                "https://legislature.maine.gov/district27",
                "https://www.mainesenate.org/senator/senator/senator-jill-duson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-27-upper-ocd-person-c2268745-e66c-42d5-93bf-3b52819006e9": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/7dc90231-0b7d-40d5-93e2-e32335922a48",
            "firstName": "Benjamin M.",
            "lastName": "Chipman",
            "fullName": "Ben Chipman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/chipman-5x7.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-ME-28-upper-ocd-person-7dc90231-0b7d-40d5-93e2-e32335922a48",
              "builtID": "me-upper-28",
              "externalID": "ocd-person/7dc90231-0b7d-40d5-93e2-e32335922a48",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.chipman@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-27",
                "https://legislature.maine.gov/District-27",
                "https://legislature.maine.gov/District28",
                "https://www.mainesenate.org/senator/senator/ben-chipman/",
                "https://legislature.maine.gov/district28"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-28-upper-ocd-person-7dc90231-0b7d-40d5-93e2-e32335922a48": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/50f75f0e-328c-413c-8733-de2872c5b7b0",
            "firstName": "Anne",
            "lastName": "Carney",
            "fullName": "Anne Carney",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/carney-web-1-1024x854.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-ME-29-upper-ocd-person-50f75f0e-328c-413c-8733-de2872c5b7b0",
              "builtID": "me-upper-29",
              "externalID": "ocd-person/50f75f0e-328c-413c-8733-de2872c5b7b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "anne.carney@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-29",
                "https://legislature.maine.gov/District-29",
                "https://legislature.maine.gov/District29",
                "https://www.mainesenate.org/senator/senator/anne-carney/",
                "https://legislature.maine.gov/district29"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-29-upper-ocd-person-50f75f0e-328c-413c-8733-de2872c5b7b0": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/b060c143-2734-491d-b28f-f6e4e2c77cb8",
            "firstName": "Stacy Fielding",
            "lastName": "Brenner",
            "fullName": "Stacy Brenner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/brenner-1022x1024.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-ME-30-upper-ocd-person-b060c143-2734-491d-b28f-f6e4e2c77cb8",
              "builtID": "me-upper-30",
              "externalID": "ocd-person/b060c143-2734-491d-b28f-f6e4e2c77cb8",
              "geometry": null
            },
            "contactInfo": {
              "email": "stacy.brenner@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-30",
                "https://legislature.maine.gov/District-30",
                "https://legislature.maine.gov/District30",
                "https://www.mainesenate.org/senator/senator/stacy-brenner/",
                "https://legislature.maine.gov/district30"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-30-upper-ocd-person-b060c143-2734-491d-b28f-f6e4e2c77cb8": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/d071215f-4839-4c4c-ab4d-f3c7179004a6",
            "firstName": "Donna",
            "lastName": "Bailey",
            "fullName": "Donna Bailey",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/bailey-donna-6.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-ME-31-upper-ocd-person-d071215f-4839-4c4c-ab4d-f3c7179004a6",
              "builtID": "me-upper-31",
              "externalID": "ocd-person/d071215f-4839-4c4c-ab4d-f3c7179004a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "donna.bailey@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-31",
                "https://legislature.maine.gov/District-31",
                "https://www.mainesenate.org/senator/senator/donna-bailey/",
                "https://legislature.maine.gov/District31",
                "https://legislature.maine.gov/district31"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-31-upper-ocd-person-d071215f-4839-4c4c-ab4d-f3c7179004a6": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/050d357e-3b22-4647-942f-56c9d8755194",
            "firstName": "Henry",
            "lastName": "Ingwersen",
            "fullName": "Henry Ingwersen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/ingwersen-1024x1024.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-ME-32-upper-ocd-person-050d357e-3b22-4647-942f-56c9d8755194",
              "builtID": "me-upper-32",
              "externalID": "ocd-person/050d357e-3b22-4647-942f-56c9d8755194",
              "geometry": null
            },
            "contactInfo": {
              "email": "henry.ingwersen@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District32",
                "https://legislature.maine.gov/district32",
                "https://www.mainesenate.org/senator/senator/henry-ingwersen/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-32-upper-ocd-person-050d357e-3b22-4647-942f-56c9d8755194": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/f4b39a4f-3a31-4e15-9b0a-459cb260f535",
            "firstName": "Matthew A.",
            "lastName": "Harrington",
            "fullName": "Matt Harrington",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.maine.gov/uploads/visual_edit/harrington-matt.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-ME-33-upper-ocd-person-f4b39a4f-3a31-4e15-9b0a-459cb260f535",
              "builtID": "me-upper-33",
              "externalID": "ocd-person/f4b39a4f-3a31-4e15-9b0a-459cb260f535",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.harrington@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/83",
                "https://legislature.maine.gov/house/house/MemberProfiles/Details/83",
                "https://legislature.maine.gov/District33",
                "https://legislature.maine.gov/district33",
                "https://mesenategop.com/your-senators/senator-matt-harrington/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-33-upper-ocd-person-f4b39a4f-3a31-4e15-9b0a-459cb260f535": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/8c2c2996-5a72-4c9a-a0d9-958099f3a304",
            "firstName": "Joseph",
            "lastName": "Rafferty",
            "fullName": "Joe Rafferty",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/rafferty-1.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-ME-34-upper-ocd-person-8c2c2996-5a72-4c9a-a0d9-958099f3a304",
              "builtID": "me-upper-34",
              "externalID": "ocd-person/8c2c2996-5a72-4c9a-a0d9-958099f3a304",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.rafferty@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-34",
                "https://legislature.maine.gov/District-34",
                "https://www.mainesenate.org/senator/senator/joe-rafferty/",
                "https://legislature.maine.gov/District34",
                "https://legislature.maine.gov/district34"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-34-upper-ocd-person-8c2c2996-5a72-4c9a-a0d9-958099f3a304": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/ce4a4a85-e41d-410f-bfba-07f7f5b9dee0",
            "firstName": "Mark W.",
            "lastName": "Lawrence",
            "fullName": "Mark Lawrence",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.maine.gov/uploads/visual_edit/lawrence-5x7-1.jpg",
            "title": "ME Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ME",
              "stateFull": "Maine",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-ME-35-upper-ocd-person-ce4a4a85-e41d-410f-bfba-07f7f5b9dee0",
              "builtID": "me-upper-35",
              "externalID": "ocd-person/ce4a4a85-e41d-410f-bfba-07f7f5b9dee0",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.lawrence@legislature.maine.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.maine.gov/District-35",
                "https://legislature.maine.gov/District-35",
                "https://legislature.maine.gov/District35",
                "https://www.mainesenate.org/senator/senator/mark-lawrence/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ME-35-upper-ocd-person-ce4a4a85-e41d-410f-bfba-07f7f5b9dee0": 0
        }
      }
    }
  },
  "MD": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "MD",
          "stateFull": "Maryland",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "MD",
            "stateFull": "Maryland",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-MD---",
            "builtID": "md--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-MD---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "C000141",
          "in_office": true,
          "firstName": "Benjamin",
          "lastName": "Cardin",
          "middleName": "L.",
          "fullName": "Benjamin L. Cardin",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/C000141.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "MD",
            "stateFull": "Maryland",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-MD---C000141",
            "builtID": "md--",
            "externalID": "C000141",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.cardin.senate.gov/contact/",
            "address1": "509 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-4524",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorCardin",
            "youtube": "senatorcardin",
            "instagram": null,
            "facebook": "senatorbencardin",
            "urls": ["https://www.cardin.senate.gov"],
            "rss_url": null
          },
          "title": "MD Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/C000141.json",
          "govtrack_id": "400064",
          "cspan_id": "4004",
          "votesmart_id": "26888",
          "icpsr_id": "15408",
          "crp_id": "N00001955",
          "google_entity_id": "/m/025k3k",
          "state_rank": "senior",
          "lis_id": "S308",
          "suffix": null,
          "date_of_birth": "1943-10-05",
          "leadership_role": null,
          "fec_candidate_id": "H6MD03177",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "17",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 10,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 1.81,
          "votes_with_party_pct": 98.16,
          "votes_against_party_pct": 1.85,
          "ocd_id": "ocd-division/country:us/state:md"
        },
        {
          "API_ID": "V000128",
          "in_office": true,
          "firstName": "Chris",
          "lastName": "Van Hollen",
          "middleName": null,
          "fullName": "Chris Van Hollen",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/V000128.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "MD",
            "stateFull": "Maryland",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-MD---V000128",
            "builtID": "md--",
            "externalID": "V000128",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "ChrisVanHollen",
            "youtube": "RepChrisVanHollen",
            "instagram": null,
            "facebook": "chrisvanhollen",
            "urls": ["https://www.vanhollen.senate.gov"],
            "rss_url": null
          },
          "title": "MD Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/V000128.json",
          "govtrack_id": "400415",
          "cspan_id": "20756",
          "votesmart_id": "6098",
          "icpsr_id": "20330",
          "crp_id": "N00013820",
          "google_entity_id": "/m/025kb5",
          "state_rank": "junior",
          "lis_id": "S390",
          "suffix": null,
          "date_of_birth": "1959-01-10",
          "leadership_role": null,
          "fec_candidate_id": "H2MD08126",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "7",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 2,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 0.36,
          "votes_with_party_pct": 96.91,
          "votes_against_party_pct": 3.09,
          "ocd_id": "ocd-division/country:us/state:md"
        }
      ],
      "hash": { "SENATE-MD---C000141": 0, "SENATE-MD---V000128": 1 }
    },
    "HOUSE": {
      "01": {
        "members": [
          {
            "API_ID": "H001052",
            "in_office": true,
            "firstName": "Andy",
            "lastName": "Harris",
            "middleName": null,
            "fullName": "Andy Harris",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/H001052.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-MD-01--H001052",
              "builtID": "md--01",
              "externalID": "H001052",
              "geometry": null,
              "geoid": "2401"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1536 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5311",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAndyHarrisMD",
              "youtube": "RepAndyHarris",
              "instagram": null,
              "facebook": "AndyHarrisMD",
              "urls": ["https://harris.house.gov"],
              "rss_url": "https://harris.house.gov/rss.xml"
            },
            "title": "MD House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001052.json",
            "govtrack_id": "412434",
            "cspan_id": "1033464",
            "votesmart_id": "19157",
            "icpsr_id": "21139",
            "crp_id": "N00029147",
            "google_entity_id": "/m/02852wc",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1957-01-25",
            "leadership_role": null,
            "fec_candidate_id": "H8MD01094",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 18,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.71,
            "votes_with_party_pct": 87.43,
            "votes_against_party_pct": 10.71,
            "ocd_id": "ocd-division/country:us/state:md/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MD-01--H001052": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "H000874",
            "in_office": true,
            "firstName": "Steny",
            "lastName": "Hoyer",
            "middleName": "H.",
            "fullName": "Steny H. Hoyer",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/H000874.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-MD-05--H000874",
              "builtID": "md--05",
              "externalID": "H000874",
              "geometry": null,
              "geoid": "2405"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1705 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4131",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "LeaderHoyer",
              "youtube": "LeaderHoyer",
              "instagram": null,
              "facebook": "WhipHoyer",
              "urls": ["https://hoyer.house.gov"],
              "rss_url": null
            },
            "title": "MD House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H000874.json",
            "govtrack_id": "400189",
            "cspan_id": "1919",
            "votesmart_id": "26890",
            "icpsr_id": "14873",
            "crp_id": "N00001821",
            "google_entity_id": "/m/025k5p",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1939-06-14",
            "leadership_role": "House Majority Leader",
            "fec_candidate_id": "H2MD05155",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "44",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 65,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 6.16,
            "votes_with_party_pct": 96.43,
            "votes_against_party_pct": 1.63,
            "ocd_id": "ocd-division/country:us/state:md/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MD-05--H000874": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "I000058",
            "in_office": true,
            "firstName": "Glenn",
            "lastName": "Ivey",
            "middleName": null,
            "fullName": "Glenn Ivey",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/I000058.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-MD-04--I000058",
              "builtID": "md--04",
              "externalID": "I000058",
              "geometry": null,
              "geoid": "2404"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1529 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-8699",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGlennIvey",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ivey.house.gov/"],
              "rss_url": null
            },
            "title": "MD House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/I000058.json",
            "govtrack_id": "456905",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1961-02-27",
            "leadership_role": null,
            "fec_candidate_id": "H2MD04315",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 12,
            "total_present": 4,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.14,
            "votes_with_party_pct": 96.23,
            "votes_against_party_pct": 1.84,
            "ocd_id": "ocd-division/country:us/state:md/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MD-04--I000058": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "M000687",
            "in_office": true,
            "firstName": "Kweisi",
            "lastName": "Mfume",
            "middleName": null,
            "fullName": "Kweisi Mfume",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M000687.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-MD-07--M000687",
              "builtID": "md--07",
              "externalID": "M000687",
              "geometry": null,
              "geoid": "2407"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2263 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4741",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepKweisiMfume",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://mfume.house.gov"],
              "rss_url": null
            },
            "title": "MD House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M000687.json",
            "govtrack_id": "407672",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": "15432",
            "crp_id": "N00001799",
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1948-10-24",
            "leadership_role": null,
            "fec_candidate_id": "S6MD03185",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 36,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.41,
            "votes_with_party_pct": 96.34,
            "votes_against_party_pct": 1.68,
            "ocd_id": "ocd-division/country:us/state:md/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MD-07--M000687": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "R000606",
            "in_office": true,
            "firstName": "Jamie",
            "lastName": "Raskin",
            "middleName": null,
            "fullName": "Jamie Raskin",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/R000606.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-MD-08--R000606",
              "builtID": "md--08",
              "externalID": "R000606",
              "geometry": null,
              "geoid": "2408"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2242 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5341",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repraskin",
              "youtube": null,
              "instagram": null,
              "facebook": "repraskin",
              "urls": ["https://raskin.house.gov"],
              "rss_url": "https://raskin.house.gov/rss.xml"
            },
            "title": "MD House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/R000606.json",
            "govtrack_id": "412708",
            "cspan_id": "12924",
            "votesmart_id": "65904",
            "icpsr_id": "21741",
            "crp_id": "N00037036",
            "google_entity_id": "/m/0c13wm",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1962-12-13",
            "leadership_role": null,
            "fec_candidate_id": "H6MD08457",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 12,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.14,
            "votes_with_party_pct": 96.03,
            "votes_against_party_pct": 2.03,
            "ocd_id": "ocd-division/country:us/state:md/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MD-08--R000606": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "R000576",
            "in_office": true,
            "firstName": "C.A. Dutch",
            "lastName": "Ruppersberger",
            "middleName": null,
            "fullName": "C. A. Dutch Ruppersberger",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/R000576.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-MD-02--R000576",
              "builtID": "md--02",
              "externalID": "R000576",
              "geometry": null,
              "geoid": "2402"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2206 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3061",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Call_Me_Dutch",
              "youtube": "ruppersberger",
              "instagram": null,
              "facebook": "DutchRupp",
              "urls": ["https://ruppersberger.house.gov"],
              "rss_url": "https://ruppersberger.house.gov/rss.xml"
            },
            "title": "MD House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/R000576.json",
            "govtrack_id": "400349",
            "cspan_id": "49155",
            "votesmart_id": "36130",
            "icpsr_id": "20329",
            "crp_id": "N00025482",
            "google_entity_id": "/m/025k36",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1946-01-31",
            "leadership_role": null,
            "fec_candidate_id": "H2MD02160",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "22",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 24,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.27,
            "votes_with_party_pct": 96.28,
            "votes_against_party_pct": 1.86,
            "ocd_id": "ocd-division/country:us/state:md/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MD-02--R000576": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "S001168",
            "in_office": true,
            "firstName": "John",
            "lastName": "Sarbanes",
            "middleName": null,
            "fullName": "John P. Sarbanes",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001168.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-MD-03--S001168",
              "builtID": "md--03",
              "externalID": "S001168",
              "geometry": null,
              "geoid": "2403"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2370 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4016",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepSarbanes",
              "youtube": "RepJohnSarbanes",
              "instagram": null,
              "facebook": "RepSarbanes",
              "urls": ["https://sarbanes.house.gov"],
              "rss_url": "https://sarbanes.house.gov/rss.xml"
            },
            "title": "MD House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001168.json",
            "govtrack_id": "412212",
            "cspan_id": "1022884",
            "votesmart_id": "66575",
            "icpsr_id": "20724",
            "crp_id": "N00027751",
            "google_entity_id": "/m/08_r8n",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1962-05-22",
            "leadership_role": null,
            "fec_candidate_id": "H6MD03292",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "18",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 27,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.56,
            "votes_with_party_pct": 96.37,
            "votes_against_party_pct": 1.77,
            "ocd_id": "ocd-division/country:us/state:md/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MD-03--S001168": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "T000483",
            "in_office": true,
            "firstName": "David",
            "lastName": "Trone",
            "middleName": null,
            "fullName": "David J. Trone",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/T000483.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-MD-06--T000483",
              "builtID": "md--06",
              "externalID": "T000483",
              "geometry": null,
              "geoid": "2406"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2404 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2721",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDavidTrone",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://trone.house.gov"],
              "rss_url": null
            },
            "title": "MD House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000483.json",
            "govtrack_id": "412783",
            "cspan_id": null,
            "votesmart_id": "167336",
            "icpsr_id": null,
            "crp_id": "N00039122",
            "google_entity_id": "/g/11dxnzbmvl",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1955-09-21",
            "leadership_role": null,
            "fec_candidate_id": "H6MD08549",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 127,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 12.03,
            "votes_with_party_pct": 95.43,
            "votes_against_party_pct": 2.5,
            "ocd_id": "ocd-division/country:us/state:md/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MD-06--T000483": 0 }
      }
    },
    "SLDL": {
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/fd61aa90-0a23-4cc2-8c6f-9fae2aa49a81",
            "firstName": "Karen",
            "lastName": "Simpson",
            "fullName": "Karen Simpson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/simpson01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-MD-3-lower-ocd-person-fd61aa90-0a23-4cc2-8c6f-9fae2aa49a81",
              "builtID": "md-lower-3",
              "externalID": "ocd-person/fd61aa90-0a23-4cc2-8c6f-9fae2aa49a81",
              "geometry": null
            },
            "contactInfo": {
              "email": "karen.simpson@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/simpson01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa18380.html"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/38379d8c-a63d-4e2b-88ee-ee6dcd646c95",
            "firstName": "Kenneth P.",
            "lastName": "Kerr",
            "fullName": "Ken Kerr",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/kerr01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-MD-3-lower-ocd-person-38379d8c-a63d-4e2b-88ee-ee6dcd646c95",
              "builtID": "md-lower-3",
              "externalID": "ocd-person/38379d8c-a63d-4e2b-88ee-ee6dcd646c95",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.kerr@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "4539 Metropolitan Court Suite 257, Frederick, MD 21704",
              "phone1": null,
              "phone2": "301-360-3505",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=kerr01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/kerr01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/cda5b902-c106-4458-a951-c74230e76e6a",
            "firstName": "Kristopher Goddard",
            "lastName": "Fair",
            "fullName": "Kris Fair",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/KrisFair.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-MD-3-lower-ocd-person-cda5b902-c106-4458-a951-c74230e76e6a",
              "builtID": "md-lower-3",
              "externalID": "ocd-person/cda5b902-c106-4458-a951-c74230e76e6a",
              "geometry": null
            },
            "contactInfo": {
              "email": "kris.fair@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "253 E. Church St. Suite 100, Frederick, MD 21701",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/fair01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-3-lower-ocd-person-fd61aa90-0a23-4cc2-8c6f-9fae2aa49a81": 0,
          "SLDL-MD-3-lower-ocd-person-38379d8c-a63d-4e2b-88ee-ee6dcd646c95": 1,
          "SLDL-MD-3-lower-ocd-person-cda5b902-c106-4458-a951-c74230e76e6a": 2
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/ce14f02f-4b1e-407e-b8e5-57eb78c8d557",
            "firstName": "April Fleming",
            "lastName": "Miller",
            "fullName": "April Miller",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/April_Fleming_Miller.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-MD-4-lower-ocd-person-ce14f02f-4b1e-407e-b8e5-57eb78c8d557",
              "builtID": "md-lower-4",
              "externalID": "ocd-person/ce14f02f-4b1e-407e-b8e5-57eb78c8d557",
              "geometry": null
            },
            "contactInfo": {
              "email": "april.miller@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/miller03"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/515dbb17-6f5d-482a-8799-a2490be688fb",
            "firstName": "Barrie S.",
            "lastName": "Ciliberti",
            "fullName": "Barrie Ciliberti",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/ciliberti01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-MD-4-lower-ocd-person-515dbb17-6f5d-482a-8799-a2490be688fb",
              "builtID": "md-lower-4",
              "externalID": "ocd-person/515dbb17-6f5d-482a-8799-a2490be688fb",
              "geometry": null
            },
            "contactInfo": {
              "email": "barrie.ciliberti@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=ciliberti01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa12199.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/ciliberti01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/208e3148-8653-4162-8c92-6ba02e15e59b",
            "firstName": "Jesse T.",
            "lastName": "Pippy",
            "fullName": "Jesse Pippy",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2019RS/images/pippy01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-MD-4-lower-ocd-person-208e3148-8653-4162-8c92-6ba02e15e59b",
              "builtID": "md-lower-4",
              "externalID": "ocd-person/208e3148-8653-4162-8c92-6ba02e15e59b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jesse.pippy@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=pippy01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/pippy01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-4-lower-ocd-person-ce14f02f-4b1e-407e-b8e5-57eb78c8d557": 0,
          "SLDL-MD-4-lower-ocd-person-515dbb17-6f5d-482a-8799-a2490be688fb": 1,
          "SLDL-MD-4-lower-ocd-person-208e3148-8653-4162-8c92-6ba02e15e59b": 2
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/76812cdb-3d6d-458a-b864-feb72f363a8e",
            "firstName": "April R.",
            "lastName": "Rose",
            "fullName": "April Rose",
            "gender": "Female",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/rose01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-MD-5-lower-ocd-person-76812cdb-3d6d-458a-b864-feb72f363a8e",
              "builtID": "md-lower-5",
              "externalID": "ocd-person/76812cdb-3d6d-458a-b864-feb72f363a8e",
              "geometry": null
            },
            "contactInfo": {
              "email": "april.rose@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=rose01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17126.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/rose01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b722dd05-587d-4b70-90b2-37f1e75be01f",
            "firstName": "Christopher",
            "lastName": "Tomlinson",
            "fullName": "Chris Tomlinson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://mgaleg.maryland.gov/2023RS/images/tomlinson01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-MD-5-lower-ocd-person-b722dd05-587d-4b70-90b2-37f1e75be01f",
              "builtID": "md-lower-5",
              "externalID": "ocd-person/b722dd05-587d-4b70-90b2-37f1e75be01f",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.tomlinson@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/tomlinson01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0ec36459-c974-4c63-8c06-5f268766dbc6",
            "firstName": "Christopher Eric",
            "lastName": "Bouchat",
            "fullName": "Christopher Bouchat",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.newyork1.vip.townnews.com/fredericknewspost.com/content/tncms/assets/v3/editorial/d/6b/d6bdcb52-3e26-50ac-b6ff-b5d5b9f47df5/63166ecee3bb0.image.jpg?resize=400%2C267",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-MD-5-lower-ocd-person-0ec36459-c974-4c63-8c06-5f268766dbc6",
              "builtID": "md-lower-5",
              "externalID": "ocd-person/0ec36459-c974-4c63-8c06-5f268766dbc6",
              "geometry": null
            },
            "contactInfo": {
              "email": "christopher.bouchat@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/bouchat01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-5-lower-ocd-person-76812cdb-3d6d-458a-b864-feb72f363a8e": 0,
          "SLDL-MD-5-lower-ocd-person-b722dd05-587d-4b70-90b2-37f1e75be01f": 1,
          "SLDL-MD-5-lower-ocd-person-0ec36459-c974-4c63-8c06-5f268766dbc6": 2
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/84c7f5dc-7dfc-4a3b-9a85-7c7732515f0a",
            "firstName": "Robin L.",
            "lastName": "Grammer",
            "fullName": "Robin Grammer",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2020RS/images/grammer01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-MD-6-lower-ocd-person-84c7f5dc-7dfc-4a3b-9a85-7c7732515f0a",
              "builtID": "md-lower-6",
              "externalID": "ocd-person/84c7f5dc-7dfc-4a3b-9a85-7c7732515f0a",
              "geometry": null
            },
            "contactInfo": {
              "email": "robin.grammer@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=grammer01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17048.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/grammer01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/253942ff-e83a-46ca-a05a-2e8e8644b4c2",
            "firstName": "Richard W.",
            "lastName": "Metzgar",
            "fullName": "Ric Metzgar",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/metzgar01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-MD-6-lower-ocd-person-253942ff-e83a-46ca-a05a-2e8e8644b4c2",
              "builtID": "md-lower-6",
              "externalID": "ocd-person/253942ff-e83a-46ca-a05a-2e8e8644b4c2",
              "geometry": null
            },
            "contactInfo": {
              "email": "ric.metzgar@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=metzgar01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17050.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/metzgar01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f84bc5c0-1a2f-4eac-8a7a-63c879ad56e5",
            "firstName": "Robert B.",
            "lastName": "Long",
            "fullName": "Bob Long",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/long01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-MD-6-lower-ocd-person-f84bc5c0-1a2f-4eac-8a7a-63c879ad56e5",
              "builtID": "md-lower-6",
              "externalID": "ocd-person/f84bc5c0-1a2f-4eac-8a7a-63c879ad56e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.long@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=long01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17049.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/long01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-6-lower-ocd-person-84c7f5dc-7dfc-4a3b-9a85-7c7732515f0a": 0,
          "SLDL-MD-6-lower-ocd-person-253942ff-e83a-46ca-a05a-2e8e8644b4c2": 1,
          "SLDL-MD-6-lower-ocd-person-f84bc5c0-1a2f-4eac-8a7a-63c879ad56e5": 2
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/4eb57c5a-70c0-4b16-a247-53cf291bc38a",
            "firstName": "Carl W.",
            "lastName": "Jackson",
            "fullName": "Carl Jackson",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/jackson02.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-MD-8-lower-ocd-person-4eb57c5a-70c0-4b16-a247-53cf291bc38a",
              "builtID": "md-lower-8",
              "externalID": "ocd-person/4eb57c5a-70c0-4b16-a247-53cf291bc38a",
              "geometry": null
            },
            "contactInfo": {
              "email": "carl.jackson@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa18235.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/jackson02"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/14830b25-7188-44be-836f-2d3d0324dec1",
            "firstName": "Hir Bahadur",
            "lastName": "Bhandari",
            "fullName": "Harry Bhandari",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/bhandari01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-MD-8-lower-ocd-person-14830b25-7188-44be-836f-2d3d0324dec1",
              "builtID": "md-lower-8",
              "externalID": "ocd-person/14830b25-7188-44be-836f-2d3d0324dec1",
              "geometry": null
            },
            "contactInfo": {
              "email": "harry.bhandari@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=bhandari01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/bhandari01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e8548408-5e6b-4fda-bd93-99fa1c84c1bf",
            "firstName": "Nicholas J.",
            "lastName": "Allen",
            "fullName": "Nick Allen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/allen01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-MD-8-lower-ocd-person-e8548408-5e6b-4fda-bd93-99fa1c84c1bf",
              "builtID": "md-lower-8",
              "externalID": "ocd-person/e8548408-5e6b-4fda-bd93-99fa1c84c1bf",
              "geometry": null
            },
            "contactInfo": {
              "email": "nick.allen@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/allen01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-8-lower-ocd-person-4eb57c5a-70c0-4b16-a247-53cf291bc38a": 0,
          "SLDL-MD-8-lower-ocd-person-14830b25-7188-44be-836f-2d3d0324dec1": 1,
          "SLDL-MD-8-lower-ocd-person-e8548408-5e6b-4fda-bd93-99fa1c84c1bf": 2
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/4bf0680f-fd5c-48a1-b6d3-73672f71048a",
            "firstName": "Adrienne A.",
            "lastName": "Jones",
            "fullName": "Adrienne Jones",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/jones.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-MD-10-lower-ocd-person-4bf0680f-fd5c-48a1-b6d3-73672f71048a",
              "builtID": "md-lower-10",
              "externalID": "ocd-person/4bf0680f-fd5c-48a1-b6d3-73672f71048a",
              "geometry": null
            },
            "contactInfo": {
              "email": "adrienne.jones@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "2 W. Rolling Crossroads Suite 112, Catonsville, MD 21228",
              "phone1": null,
              "phone2": "410-455-5330",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=jones&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa02630.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/jones",
                "https://www.mdspeakerjones.com/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e9d8fb0f-3272-47c8-8926-28c7bdfc19ca",
            "firstName": "Jennifer Arice",
            "lastName": "White Holland",
            "fullName": "Jennifer White Holland",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2024RS/images/white01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-MD-10-lower-ocd-person-e9d8fb0f-3272-47c8-8926-28c7bdfc19ca",
              "builtID": "md-lower-10",
              "externalID": "ocd-person/e9d8fb0f-3272-47c8-8926-28c7bdfc19ca",
              "geometry": null
            },
            "contactInfo": {
              "email": "jennifer.white@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/white01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/bae5d58b-dc40-476e-8845-063ed7fd81bd",
            "firstName": "Norman Scott",
            "lastName": "Phillips",
            "fullName": "Scott Phillips",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/phillips02.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-MD-10-lower-ocd-person-bae5d58b-dc40-476e-8845-063ed7fd81bd",
              "builtID": "md-lower-10",
              "externalID": "ocd-person/bae5d58b-dc40-476e-8845-063ed7fd81bd",
              "geometry": null
            },
            "contactInfo": {
              "email": "n.scott.phillips@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/phillips02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-10-lower-ocd-person-4bf0680f-fd5c-48a1-b6d3-73672f71048a": 0,
          "SLDL-MD-10-lower-ocd-person-e9d8fb0f-3272-47c8-8926-28c7bdfc19ca": 1,
          "SLDL-MD-10-lower-ocd-person-bae5d58b-dc40-476e-8845-063ed7fd81bd": 2
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/51573cf9-a7d7-40d5-bd9a-90e3042c4cab",
            "firstName": "Jennifer R.",
            "lastName": "Terrasa",
            "fullName": "Jen Terrasa",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/terrasa01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-MD-13-lower-ocd-person-51573cf9-a7d7-40d5-bd9a-90e3042c4cab",
              "builtID": "md-lower-13",
              "externalID": "ocd-person/51573cf9-a7d7-40d5-bd9a-90e3042c4cab",
              "geometry": null
            },
            "contactInfo": {
              "email": "jen.terrasa@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=terrasa01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/terrasa01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ba217836-74e4-49ec-8eac-58d21e792e63",
            "firstName": "Pamela Lanman",
            "lastName": "Guzzone",
            "fullName": "Pam Guzzone",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/guzzone01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-MD-13-lower-ocd-person-ba217836-74e4-49ec-8eac-58d21e792e63",
              "builtID": "md-lower-13",
              "externalID": "ocd-person/ba217836-74e4-49ec-8eac-58d21e792e63",
              "geometry": null
            },
            "contactInfo": {
              "email": "pam.guzzone@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/guzzone01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4d13bf0a-908c-4292-b5c9-411026ba2c17",
            "firstName": "Vanessa E.",
            "lastName": "Atterbeary",
            "fullName": "Vanessa Atterbeary",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/atterbeary01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-MD-13-lower-ocd-person-4d13bf0a-908c-4292-b5c9-411026ba2c17",
              "builtID": "md-lower-13",
              "externalID": "ocd-person/4d13bf0a-908c-4292-b5c9-411026ba2c17",
              "geometry": null
            },
            "contactInfo": {
              "email": "vanessa.atterbeary@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=atterbeary01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17059.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/atterbeary01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-13-lower-ocd-person-51573cf9-a7d7-40d5-bd9a-90e3042c4cab": 0,
          "SLDL-MD-13-lower-ocd-person-ba217836-74e4-49ec-8eac-58d21e792e63": 1,
          "SLDL-MD-13-lower-ocd-person-4d13bf0a-908c-4292-b5c9-411026ba2c17": 2
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/88aa2d42-9bca-4df7-b961-1516d5c1f73a",
            "firstName": "Anne R.",
            "lastName": "Kaiser",
            "fullName": "Anne Kaiser",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/kaiser.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-MD-14-lower-ocd-person-88aa2d42-9bca-4df7-b961-1516d5c1f73a",
              "builtID": "md-lower-14",
              "externalID": "ocd-person/88aa2d42-9bca-4df7-b961-1516d5c1f73a",
              "geometry": null
            },
            "contactInfo": {
              "email": "anne.kaiser@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=kaiser&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa13987.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/kaiser"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c5a06a0d-f611-4526-8674-23e035caab74",
            "firstName": "Bernice Dansoah",
            "lastName": "Mireku-North",
            "fullName": "Bernice Mireku-North",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/mireku01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-MD-14-lower-ocd-person-c5a06a0d-f611-4526-8674-23e035caab74",
              "builtID": "md-lower-14",
              "externalID": "ocd-person/c5a06a0d-f611-4526-8674-23e035caab74",
              "geometry": null
            },
            "contactInfo": {
              "email": "bernice.mireku-north@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/mireku01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/aa70aec7-1d13-4ac0-912d-408ae8ee0763",
            "firstName": "Pamela E.",
            "lastName": "Queen",
            "fullName": "Pam Queen",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/queen01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-MD-14-lower-ocd-person-aa70aec7-1d13-4ac0-912d-408ae8ee0763",
              "builtID": "md-lower-14",
              "externalID": "ocd-person/aa70aec7-1d13-4ac0-912d-408ae8ee0763",
              "geometry": null
            },
            "contactInfo": {
              "email": "pam.queen@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=queen01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17181.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/queen01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-14-lower-ocd-person-88aa2d42-9bca-4df7-b961-1516d5c1f73a": 0,
          "SLDL-MD-14-lower-ocd-person-c5a06a0d-f611-4526-8674-23e035caab74": 1,
          "SLDL-MD-14-lower-ocd-person-aa70aec7-1d13-4ac0-912d-408ae8ee0763": 2
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/ccc2ad8a-fa2a-432c-8e95-d301636547fc",
            "firstName": "David V.",
            "lastName": "Fraser-Hidalgo",
            "fullName": "David Fraser-Hidalgo",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/fraser01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-MD-15-lower-ocd-person-ccc2ad8a-fa2a-432c-8e95-d301636547fc",
              "builtID": "md-lower-15",
              "externalID": "ocd-person/ccc2ad8a-fa2a-432c-8e95-d301636547fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.fraser.hidalgo@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=fraser01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa16856.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/fraser01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/832c64da-9793-49a2-8231-c62d0cd7078a",
            "firstName": "Lily",
            "lastName": "Qi",
            "fullName": "Lily Qi",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/qi01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-MD-15-lower-ocd-person-832c64da-9793-49a2-8231-c62d0cd7078a",
              "builtID": "md-lower-15",
              "externalID": "ocd-person/832c64da-9793-49a2-8231-c62d0cd7078a",
              "geometry": null
            },
            "contactInfo": {
              "email": "lily.qi@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=qi01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/qi01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/9858db06-ec0e-40fe-b000-0630238a6ad1",
            "firstName": "Linda K.",
            "lastName": "Foley",
            "fullName": "Linda Foley",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2022RS/images/foley01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-MD-15-lower-ocd-person-9858db06-ec0e-40fe-b000-0630238a6ad1",
              "builtID": "md-lower-15",
              "externalID": "ocd-person/9858db06-ec0e-40fe-b000-0630238a6ad1",
              "geometry": null
            },
            "contactInfo": {
              "email": "linda.foley@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa18326.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/foley01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-15-lower-ocd-person-ccc2ad8a-fa2a-432c-8e95-d301636547fc": 0,
          "SLDL-MD-15-lower-ocd-person-832c64da-9793-49a2-8231-c62d0cd7078a": 1,
          "SLDL-MD-15-lower-ocd-person-9858db06-ec0e-40fe-b000-0630238a6ad1": 2
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/b86e252c-2904-4d28-9d2f-b13d854efa66",
            "firstName": "Marc Alan",
            "lastName": "Korman",
            "fullName": "Marc Korman",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/korman01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-MD-16-lower-ocd-person-b86e252c-2904-4d28-9d2f-b13d854efa66",
              "builtID": "md-lower-16",
              "externalID": "ocd-person/b86e252c-2904-4d28-9d2f-b13d854efa66",
              "geometry": null
            },
            "contactInfo": {
              "email": "marc.korman@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=korman01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/korman01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/80095967-58e7-4c8b-a675-eb1c461104fc",
            "firstName": "Sarah Siddiqui",
            "lastName": "Wolek",
            "fullName": "Sarah Wolek",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/wolek01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-MD-16-lower-ocd-person-80095967-58e7-4c8b-a675-eb1c461104fc",
              "builtID": "md-lower-16",
              "externalID": "ocd-person/80095967-58e7-4c8b-a675-eb1c461104fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "sarah.wolek@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/wolek01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-16-lower-ocd-person-b86e252c-2904-4d28-9d2f-b13d854efa66": 0,
          "SLDL-MD-16-lower-ocd-person-80095967-58e7-4c8b-a675-eb1c461104fc": 1
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/08182b0f-8c65-4ff2-994e-a0e04063cd4d",
            "firstName": "Joseph",
            "lastName": "Vogel",
            "fullName": "Joe Vogel",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://msa.maryland.gov/msa/mdmanual/06hse/images/1198-1-10791b.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-MD-17-lower-ocd-person-08182b0f-8c65-4ff2-994e-a0e04063cd4d",
              "builtID": "md-lower-17",
              "externalID": "ocd-person/08182b0f-8c65-4ff2-994e-a0e04063cd4d",
              "geometry": null
            },
            "contactInfo": {
              "email": "joseph.vogel@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/vogel01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa18391.html"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0d479110-74e8-45dd-bc01-330a169918b1",
            "firstName": "Julie",
            "lastName": "Palakovich Carr",
            "fullName": "Julie Palakovich Carr",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/palakovich01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-MD-17-lower-ocd-person-0d479110-74e8-45dd-bc01-330a169918b1",
              "builtID": "md-lower-17",
              "externalID": "ocd-person/0d479110-74e8-45dd-bc01-330a169918b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "julie.palakovichcarr@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=palakovich01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/palakovich01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8d581a25-a2ea-46ee-be26-3c6698559b78",
            "firstName": "Ryan Scott",
            "lastName": "Spiegel",
            "fullName": "Ryan Spiegel",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/spiegel01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-MD-17-lower-ocd-person-8d581a25-a2ea-46ee-be26-3c6698559b78",
              "builtID": "md-lower-17",
              "externalID": "ocd-person/8d581a25-a2ea-46ee-be26-3c6698559b78",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryan.spiegel@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/spiegel01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-17-lower-ocd-person-08182b0f-8c65-4ff2-994e-a0e04063cd4d": 0,
          "SLDL-MD-17-lower-ocd-person-0d479110-74e8-45dd-bc01-330a169918b1": 1,
          "SLDL-MD-17-lower-ocd-person-8d581a25-a2ea-46ee-be26-3c6698559b78": 2
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/5037901c-64bd-472c-a440-a440841cbb13",
            "firstName": "Aaron M.",
            "lastName": "Kaufman",
            "fullName": "Aaron Kaufman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/kaufman01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-MD-18-lower-ocd-person-5037901c-64bd-472c-a440-a440841cbb13",
              "builtID": "md-lower-18",
              "externalID": "ocd-person/5037901c-64bd-472c-a440-a440841cbb13",
              "geometry": null
            },
            "contactInfo": {
              "email": "aaron.kaufman@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/kaufman01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ea24b6b8-ecff-4a51-badf-001c8889c5df",
            "firstName": "Emily E.",
            "lastName": "Shetty",
            "fullName": "Emily Shetty",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2024RS/images/shetty01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-MD-18-lower-ocd-person-ea24b6b8-ecff-4a51-badf-001c8889c5df",
              "builtID": "md-lower-18",
              "externalID": "ocd-person/ea24b6b8-ecff-4a51-badf-001c8889c5df",
              "geometry": null
            },
            "contactInfo": {
              "email": "emily.shetty@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=shetty01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/shetty01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4edadbc3-1635-4ce9-9b8c-1220f07e7b23",
            "firstName": "Jared S.",
            "lastName": "Solomon",
            "fullName": "Jared Solomon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://bosepublicaffairs.com/wp-content/uploads/2020/12/solomon-768x959.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-MD-18-lower-ocd-person-4edadbc3-1635-4ce9-9b8c-1220f07e7b23",
              "builtID": "md-lower-18",
              "externalID": "ocd-person/4edadbc3-1635-4ce9-9b8c-1220f07e7b23",
              "geometry": null
            },
            "contactInfo": {
              "email": "jared.solomon@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=solomon01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/solomon01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-18-lower-ocd-person-5037901c-64bd-472c-a440-a440841cbb13": 0,
          "SLDL-MD-18-lower-ocd-person-ea24b6b8-ecff-4a51-badf-001c8889c5df": 1,
          "SLDL-MD-18-lower-ocd-person-4edadbc3-1635-4ce9-9b8c-1220f07e7b23": 2
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/de3cab57-1771-4805-b984-618ad887e38e",
            "firstName": "Bonnie Lynne",
            "lastName": "Cullison",
            "fullName": "Bonnie Cullison",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/cullison.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-MD-19-lower-ocd-person-de3cab57-1771-4805-b984-618ad887e38e",
              "builtID": "md-lower-19",
              "externalID": "ocd-person/de3cab57-1771-4805-b984-618ad887e38e",
              "geometry": null
            },
            "contactInfo": {
              "email": "bonnie.cullison@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "Annapolis, MD",
              "phone1": null,
              "phone2": "410-841-3883",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=cullison&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa15452.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/cullison"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0f51a760-22e6-4a05-8be0-983dffe30e42",
            "firstName": "Charlotte A.",
            "lastName": "Crutchfield",
            "fullName": "Charlotte Crutchfield",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/crutchfield01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-MD-19-lower-ocd-person-0f51a760-22e6-4a05-8be0-983dffe30e42",
              "builtID": "md-lower-19",
              "externalID": "ocd-person/0f51a760-22e6-4a05-8be0-983dffe30e42",
              "geometry": null
            },
            "contactInfo": {
              "email": "charlotte.crutchfield@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=crutchfield01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/crutchfield01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/5d11064b-6757-42f7-86fa-3023fefb6c10",
            "firstName": "Vaughn",
            "lastName": "Stewart",
            "fullName": "Vaughn Stewart",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/stewart01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-MD-19-lower-ocd-person-5d11064b-6757-42f7-86fa-3023fefb6c10",
              "builtID": "md-lower-19",
              "externalID": "ocd-person/5d11064b-6757-42f7-86fa-3023fefb6c10",
              "geometry": null
            },
            "contactInfo": {
              "email": "vaughn.stewart@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=stewart01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/stewart01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-19-lower-ocd-person-de3cab57-1771-4805-b984-618ad887e38e": 0,
          "SLDL-MD-19-lower-ocd-person-0f51a760-22e6-4a05-8be0-983dffe30e42": 1,
          "SLDL-MD-19-lower-ocd-person-5d11064b-6757-42f7-86fa-3023fefb6c10": 2
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/0692b705-a577-4edb-9da0-8c828e2473ab",
            "firstName": "David Hyon",
            "lastName": "Moon",
            "fullName": "David Moon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/moon01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-MD-20-lower-ocd-person-0692b705-a577-4edb-9da0-8c828e2473ab",
              "builtID": "md-lower-20",
              "externalID": "ocd-person/0692b705-a577-4edb-9da0-8c828e2473ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.moon@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=moon01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17063.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/moon01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/03da7272-eadc-4ad3-ba4b-f06f60acbed0",
            "firstName": "Jheanelle K.",
            "lastName": "Wilkins",
            "fullName": "Jheanelle Wilkins",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2017RS/images/wilkins01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-MD-20-lower-ocd-person-03da7272-eadc-4ad3-ba4b-f06f60acbed0",
              "builtID": "md-lower-20",
              "externalID": "ocd-person/03da7272-eadc-4ad3-ba4b-f06f60acbed0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jheanelle.wilkins@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=wilkins01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/wilkins01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d4354cae-a994-4b2d-863f-238322fc6135",
            "firstName": "Lorig",
            "lastName": "Charkoudian",
            "fullName": "Lorig Charkoudian",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/charkoudian01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-MD-20-lower-ocd-person-d4354cae-a994-4b2d-863f-238322fc6135",
              "builtID": "md-lower-20",
              "externalID": "ocd-person/d4354cae-a994-4b2d-863f-238322fc6135",
              "geometry": null
            },
            "contactInfo": {
              "email": "lorig.charkoudian@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=charkoudian01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/charkoudian01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-20-lower-ocd-person-0692b705-a577-4edb-9da0-8c828e2473ab": 0,
          "SLDL-MD-20-lower-ocd-person-03da7272-eadc-4ad3-ba4b-f06f60acbed0": 1,
          "SLDL-MD-20-lower-ocd-person-d4354cae-a994-4b2d-863f-238322fc6135": 2
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/0e4cb257-11b9-4772-b323-624ef28fb8b6",
            "firstName": "Benjamin Scott",
            "lastName": "Barnes",
            "fullName": "Ben Barnes",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/barnes.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-MD-21-lower-ocd-person-0e4cb257-11b9-4772-b323-624ef28fb8b6",
              "builtID": "md-lower-21",
              "externalID": "ocd-person/0e4cb257-11b9-4772-b323-624ef28fb8b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.barnes@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=barnes&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa14613.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/barnes"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/366c0122-84a8-460e-bc98-93d4ca5f0176",
            "firstName": "Joseline A.",
            "lastName": "Pena-Melnyk",
            "fullName": "Joseline Peña-Melnyk",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2018RS/images/pena.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-MD-21-lower-ocd-person-366c0122-84a8-460e-bc98-93d4ca5f0176",
              "builtID": "md-lower-21",
              "externalID": "ocd-person/366c0122-84a8-460e-bc98-93d4ca5f0176",
              "geometry": null
            },
            "contactInfo": {
              "email": "joseline.pena.melnyk@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1251, College Park, MD 20741",
              "phone1": null,
              "phone2": "410-841-3502",
              "fax1": null,
              "fax2": "410-841-3342",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=pena&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa14629.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/pena"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/07185e4b-2314-476e-9c3b-48959096f2a5",
            "firstName": "Mary Angela",
            "lastName": "Lehman",
            "fullName": "Mary Lehman",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/lehman01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-MD-21-lower-ocd-person-07185e4b-2314-476e-9c3b-48959096f2a5",
              "builtID": "md-lower-21",
              "externalID": "ocd-person/07185e4b-2314-476e-9c3b-48959096f2a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.lehman@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=lehman01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/lehman01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-21-lower-ocd-person-0e4cb257-11b9-4772-b323-624ef28fb8b6": 0,
          "SLDL-MD-21-lower-ocd-person-366c0122-84a8-460e-bc98-93d4ca5f0176": 1,
          "SLDL-MD-21-lower-ocd-person-07185e4b-2314-476e-9c3b-48959096f2a5": 2
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/7bcb723d-68c2-4826-acab-0437aa27471f",
            "firstName": "Anne Marie",
            "lastName": "Healey",
            "fullName": "Anne Healey",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/healey.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-MD-22-lower-ocd-person-7bcb723d-68c2-4826-acab-0437aa27471f",
              "builtID": "md-lower-22",
              "externalID": "ocd-person/7bcb723d-68c2-4826-acab-0437aa27471f",
              "geometry": null
            },
            "contactInfo": {
              "email": "anne.healey@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=healey&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa12238.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/healey"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/61ddb821-9c47-454b-8185-b68c47dcb144",
            "firstName": "Ashanti Fernando",
            "lastName": "Martinez",
            "fullName": "Ashanti Martínez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/martinez01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-MD-22-lower-ocd-person-61ddb821-9c47-454b-8185-b68c47dcb144",
              "builtID": "md-lower-22",
              "externalID": "ocd-person/61ddb821-9c47-454b-8185-b68c47dcb144",
              "geometry": null
            },
            "contactInfo": {
              "email": "ashanti.martinez@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/martinez01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0e07b201-0a1a-4a45-af43-f4d9d779b409",
            "firstName": "Nicole A.",
            "lastName": "Williams",
            "fullName": "Nicole Williams",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2020RS/images/williams01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-MD-22-lower-ocd-person-0e07b201-0a1a-4a45-af43-f4d9d779b409",
              "builtID": "md-lower-22",
              "externalID": "ocd-person/0e07b201-0a1a-4a45-af43-f4d9d779b409",
              "geometry": null
            },
            "contactInfo": {
              "email": "nicole.williams@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/williams01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa18240.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-22-lower-ocd-person-7bcb723d-68c2-4826-acab-0437aa27471f": 0,
          "SLDL-MD-22-lower-ocd-person-61ddb821-9c47-454b-8185-b68c47dcb144": 1,
          "SLDL-MD-22-lower-ocd-person-0e07b201-0a1a-4a45-af43-f4d9d779b409": 2
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/a1a2b050-266e-4d44-9463-be3a02e16e41",
            "firstName": "Adrian A.",
            "lastName": "Boafo",
            "fullName": "Adrian Boafo",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://msa.maryland.gov/msa/mdmanual/06hse/images/1198-1-10818b.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-MD-23-lower-ocd-person-a1a2b050-266e-4d44-9463-be3a02e16e41",
              "builtID": "md-lower-23",
              "externalID": "ocd-person/a1a2b050-266e-4d44-9463-be3a02e16e41",
              "geometry": null
            },
            "contactInfo": {
              "email": "adrian.boafo@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa18393.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/boafo01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/5fff41d7-cea5-4e01-a4da-e4474d267691",
            "firstName": "Marvin E.",
            "lastName": "Holmes",
            "fullName": "Marvin Holmes",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/holmes.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-MD-23-lower-ocd-person-5fff41d7-cea5-4e01-a4da-e4474d267691",
              "builtID": "md-lower-23",
              "externalID": "ocd-person/5fff41d7-cea5-4e01-a4da-e4474d267691",
              "geometry": null
            },
            "contactInfo": {
              "email": "rvin.holmes@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=holmes&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa13964.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/holmes"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f14ad8e7-adc9-44f2-95eb-8b29c34aa4dc",
            "firstName": "Kym",
            "lastName": "Taylor",
            "fullName": "Kym Taylor",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Kym_Taylor.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-MD-23-lower-ocd-person-f14ad8e7-adc9-44f2-95eb-8b29c34aa4dc",
              "builtID": "md-lower-23",
              "externalID": "ocd-person/f14ad8e7-adc9-44f2-95eb-8b29c34aa4dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "kym.taylor@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/taylor03"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-23-lower-ocd-person-a1a2b050-266e-4d44-9463-be3a02e16e41": 0,
          "SLDL-MD-23-lower-ocd-person-5fff41d7-cea5-4e01-a4da-e4474d267691": 1,
          "SLDL-MD-23-lower-ocd-person-f14ad8e7-adc9-44f2-95eb-8b29c34aa4dc": 2
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/7636786f-1db3-4094-8527-e339576314d6",
            "firstName": "Andrea Fletcher",
            "lastName": "Harrison",
            "fullName": "Andrea Harrison",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/harrison01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-MD-24-lower-ocd-person-7636786f-1db3-4094-8527-e339576314d6",
              "builtID": "md-lower-24",
              "externalID": "ocd-person/7636786f-1db3-4094-8527-e339576314d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "andreafletcher.harrison@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=harrison01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/harrison01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/eb368768-5e97-43c1-9b0e-72062436519a",
            "firstName": "Jazz M.",
            "lastName": "Lewis",
            "fullName": "Jazz Lewis",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2017RS/images/lewis02.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-MD-24-lower-ocd-person-eb368768-5e97-43c1-9b0e-72062436519a",
              "builtID": "md-lower-24",
              "externalID": "ocd-person/eb368768-5e97-43c1-9b0e-72062436519a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jazz.lewis@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "1801 McCormick Drive Suite 400, Largo, MD 20774",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=lewis02&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/lewis02"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/bede8bef-3c7b-469d-809d-98ee3be92630",
            "firstName": "Tiffany T.",
            "lastName": "Alston",
            "fullName": "Tiffany Alston",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/alston01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-MD-24-lower-ocd-person-bede8bef-3c7b-469d-809d-98ee3be92630",
              "builtID": "md-lower-24",
              "externalID": "ocd-person/bede8bef-3c7b-469d-809d-98ee3be92630",
              "geometry": null
            },
            "contactInfo": {
              "email": "tiffany.alston@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.msa.md.gov/msa/mdmanual/06hse/html/msa15348.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/alston01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-24-lower-ocd-person-7636786f-1db3-4094-8527-e339576314d6": 0,
          "SLDL-MD-24-lower-ocd-person-eb368768-5e97-43c1-9b0e-72062436519a": 1,
          "SLDL-MD-24-lower-ocd-person-bede8bef-3c7b-469d-809d-98ee3be92630": 2
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/59787563-473b-4975-a473-4a1e4d505905",
            "firstName": "Denise Geneva",
            "lastName": "Roberts",
            "fullName": "Denise Roberts",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2024RS/images/roberts01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-MD-25-lower-ocd-person-59787563-473b-4975-a473-4a1e4d505905",
              "builtID": "md-lower-25",
              "externalID": "ocd-person/59787563-473b-4975-a473-4a1e4d505905",
              "geometry": null
            },
            "contactInfo": {
              "email": "denise.roberts@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/roberts01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ba6cafdb-f65b-4397-bc36-3d4c0a248cca",
            "firstName": "Karen R.",
            "lastName": "Toles",
            "fullName": "Karen Toles",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://msa.maryland.gov/msa/mdmanual/36loc/pg/leg/images/1198-1-5308b.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-MD-25-lower-ocd-person-ba6cafdb-f65b-4397-bc36-3d4c0a248cca",
              "builtID": "md-lower-25",
              "externalID": "ocd-person/ba6cafdb-f65b-4397-bc36-3d4c0a248cca",
              "geometry": null
            },
            "contactInfo": {
              "email": "karen.toles@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa15309.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/toles01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a6771d68-fa3d-4ced-a613-3543f54fe153",
            "firstName": "Kent A.",
            "lastName": "Roberson",
            "fullName": "Kent Roberson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2024RS/images/roberson01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-MD-25-lower-ocd-person-a6771d68-fa3d-4ced-a613-3543f54fe153",
              "builtID": "md-lower-25",
              "externalID": "ocd-person/a6771d68-fa3d-4ced-a613-3543f54fe153",
              "geometry": null
            },
            "contactInfo": {
              "email": "kent.roberson@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/roberson01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-25-lower-ocd-person-59787563-473b-4975-a473-4a1e4d505905": 0,
          "SLDL-MD-25-lower-ocd-person-ba6cafdb-f65b-4397-bc36-3d4c0a248cca": 1,
          "SLDL-MD-25-lower-ocd-person-a6771d68-fa3d-4ced-a613-3543f54fe153": 2
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/108e1cf8-7e40-49bd-a7c8-1a7978e55b32",
            "firstName": "Jamila Jaye",
            "lastName": "Woods",
            "fullName": "Jamila Woods",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Jamila_01-2018.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-MD-26-lower-ocd-person-108e1cf8-7e40-49bd-a7c8-1a7978e55b32",
              "builtID": "md-lower-26",
              "externalID": "ocd-person/108e1cf8-7e40-49bd-a7c8-1a7978e55b32",
              "geometry": null
            },
            "contactInfo": {
              "email": "jamila.woods@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/woods01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/dcd83c80-dab5-4c22-be2f-0b4081af2f77",
            "firstName": "Kriselda",
            "lastName": "Valderrama-Lobo",
            "fullName": "Kris Valderrama",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/valderrama.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-MD-26-lower-ocd-person-dcd83c80-dab5-4c22-be2f-0b4081af2f77",
              "builtID": "md-lower-26",
              "externalID": "ocd-person/dcd83c80-dab5-4c22-be2f-0b4081af2f77",
              "geometry": null
            },
            "contactInfo": {
              "email": "kris.valderrama@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=valderrama&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa14636.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/valderrama"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b982cab0-0d2e-46ae-8e22-51f68e7aac82",
            "firstName": "Veronica L.",
            "lastName": "Turner",
            "fullName": "Veronica Turner",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/turner01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-MD-26-lower-ocd-person-b982cab0-0d2e-46ae-8e22-51f68e7aac82",
              "builtID": "md-lower-26",
              "externalID": "ocd-person/b982cab0-0d2e-46ae-8e22-51f68e7aac82",
              "geometry": null
            },
            "contactInfo": {
              "email": "veronica.turner@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=turner01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/turner01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-26-lower-ocd-person-108e1cf8-7e40-49bd-a7c8-1a7978e55b32": 0,
          "SLDL-MD-26-lower-ocd-person-dcd83c80-dab5-4c22-be2f-0b4081af2f77": 1,
          "SLDL-MD-26-lower-ocd-person-b982cab0-0d2e-46ae-8e22-51f68e7aac82": 2
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/ab6c2e37-3818-467c-ad60-997274e897d0",
            "firstName": "C.T.",
            "lastName": "Wilson",
            "fullName": "C.T. Wilson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2024RS/images/wilson.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-MD-28-lower-ocd-person-ab6c2e37-3818-467c-ad60-997274e897d0",
              "builtID": "md-lower-28",
              "externalID": "ocd-person/ab6c2e37-3818-467c-ad60-997274e897d0",
              "geometry": null
            },
            "contactInfo": {
              "email": "ct.wilson@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=wilson&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa15455.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/wilson"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/64b23008-9889-4fd1-9353-42888d9bc4bb",
            "firstName": "Debra M.",
            "lastName": "Davis",
            "fullName": "Debra Davis",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/davis02.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-MD-28-lower-ocd-person-64b23008-9889-4fd1-9353-42888d9bc4bb",
              "builtID": "md-lower-28",
              "externalID": "ocd-person/64b23008-9889-4fd1-9353-42888d9bc4bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "debra.davis@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=davis02&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/davis02"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c8851cc0-d707-49c5-939a-485fc7e14075",
            "firstName": "Edith Jerry",
            "lastName": "Patterson",
            "fullName": "Edith Patterson",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2018RS/images/patterson02.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-MD-28-lower-ocd-person-c8851cc0-d707-49c5-939a-485fc7e14075",
              "builtID": "md-lower-28",
              "externalID": "ocd-person/c8851cc0-d707-49c5-939a-485fc7e14075",
              "geometry": null
            },
            "contactInfo": {
              "email": "edith.patterson@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=patterson02&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa14559.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/patterson02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-28-lower-ocd-person-ab6c2e37-3818-467c-ad60-997274e897d0": 0,
          "SLDL-MD-28-lower-ocd-person-64b23008-9889-4fd1-9353-42888d9bc4bb": 1,
          "SLDL-MD-28-lower-ocd-person-c8851cc0-d707-49c5-939a-485fc7e14075": 2
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/395603e6-0fdf-4cf6-bacb-18d98c848990",
            "firstName": "Brian A.",
            "lastName": "Chisholm",
            "fullName": "Brian Chisholm",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2019RS/images/chisholm01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-MD-31-lower-ocd-person-395603e6-0fdf-4cf6-bacb-18d98c848990",
              "builtID": "md-lower-31",
              "externalID": "ocd-person/395603e6-0fdf-4cf6-bacb-18d98c848990",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.chisholm@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=chisholm01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/chisholm01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/36e6313f-b85b-4c14-9ff4-e23fc015d904",
            "firstName": "Nicholaus R.",
            "lastName": "Kipke",
            "fullName": "Nic Kipke",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/kipke.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-MD-31-lower-ocd-person-36e6313f-b85b-4c14-9ff4-e23fc015d904",
              "builtID": "md-lower-31",
              "externalID": "ocd-person/36e6313f-b85b-4c14-9ff4-e23fc015d904",
              "geometry": null
            },
            "contactInfo": {
              "email": "nicholaus.kipke@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=kipke&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa14623.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/kipke"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/06ca80bc-c262-4eb9-9648-f8b8ac992ec5",
            "firstName": "Rachel Parker",
            "lastName": "Munoz",
            "fullName": "Rachel Muñoz",
            "gender": "Female",
            "party": "Republican",
            "image": "https://mgaleg.maryland.gov/2023RS/images/munoz01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-MD-31-lower-ocd-person-06ca80bc-c262-4eb9-9648-f8b8ac992ec5",
              "builtID": "md-lower-31",
              "externalID": "ocd-person/06ca80bc-c262-4eb9-9648-f8b8ac992ec5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rachel.munoz@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/munoz01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa18323.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-31-lower-ocd-person-395603e6-0fdf-4cf6-bacb-18d98c848990": 0,
          "SLDL-MD-31-lower-ocd-person-36e6313f-b85b-4c14-9ff4-e23fc015d904": 1,
          "SLDL-MD-31-lower-ocd-person-06ca80bc-c262-4eb9-9648-f8b8ac992ec5": 2
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/9fbc0383-4845-42ae-a2db-06b16c6f6728",
            "firstName": "Juanita Sandra",
            "lastName": "Bartlett",
            "fullName": "Sandy Bartlett",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/bartlett02.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-MD-32-lower-ocd-person-9fbc0383-4845-42ae-a2db-06b16c6f6728",
              "builtID": "md-lower-32",
              "externalID": "ocd-person/9fbc0383-4845-42ae-a2db-06b16c6f6728",
              "geometry": null
            },
            "contactInfo": {
              "email": "sandy.bartlett@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=bartlett02&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/bartlett02"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/5393ab25-fbd9-455b-8da2-76c53e955823",
            "firstName": "Mark Soo",
            "lastName": "Chang",
            "fullName": "Mark Chang",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/chang01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-MD-32-lower-ocd-person-5393ab25-fbd9-455b-8da2-76c53e955823",
              "builtID": "md-lower-32",
              "externalID": "ocd-person/5393ab25-fbd9-455b-8da2-76c53e955823",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.chang@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=chang01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17071.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/chang01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/eb13f926-c0f1-426b-b0a7-63c324f9a558",
            "firstName": "Michael J.",
            "lastName": "Rogers",
            "fullName": "Mike Rogers",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/rogers01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-MD-32-lower-ocd-person-eb13f926-c0f1-426b-b0a7-63c324f9a558",
              "builtID": "md-lower-32",
              "externalID": "ocd-person/eb13f926-c0f1-426b-b0a7-63c324f9a558",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.rogers@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=rogers01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/rogers01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-32-lower-ocd-person-9fbc0383-4845-42ae-a2db-06b16c6f6728": 0,
          "SLDL-MD-32-lower-ocd-person-5393ab25-fbd9-455b-8da2-76c53e955823": 1,
          "SLDL-MD-32-lower-ocd-person-eb13f926-c0f1-426b-b0a7-63c324f9a558": 2
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/4f0a99bb-9045-471a-88d2-1fea0ad8ccab",
            "firstName": "Jay A.",
            "lastName": "Jacobs",
            "fullName": "Jay Jacobs",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/jacobs%20j.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-MD-36-lower-ocd-person-4f0a99bb-9045-471a-88d2-1fea0ad8ccab",
              "builtID": "md-lower-36",
              "externalID": "ocd-person/4f0a99bb-9045-471a-88d2-1fea0ad8ccab",
              "geometry": null
            },
            "contactInfo": {
              "email": "jay.jacobs@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=jacobs%20j&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa15458.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/jacobs%20j"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/297d462e-6cee-4faf-ab3e-4ded2ea6f916",
            "firstName": "Jefferson L.",
            "lastName": "Ghrist",
            "fullName": "Jeff Ghrist",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/ghrist01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-MD-36-lower-ocd-person-297d462e-6cee-4faf-ab3e-4ded2ea6f916",
              "builtID": "md-lower-36",
              "externalID": "ocd-person/297d462e-6cee-4faf-ab3e-4ded2ea6f916",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.ghrist@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=ghrist01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa14691.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/ghrist01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/15b13c83-9ee7-43b6-a32a-f26d76573d54",
            "firstName": "Steven J.",
            "lastName": "Arentz",
            "fullName": "Steve Arentz",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/arentz01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-MD-36-lower-ocd-person-15b13c83-9ee7-43b6-a32a-f26d76573d54",
              "builtID": "md-lower-36",
              "externalID": "ocd-person/15b13c83-9ee7-43b6-a32a-f26d76573d54",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.arentz@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=arentz01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa15407.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/arentz01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-36-lower-ocd-person-4f0a99bb-9045-471a-88d2-1fea0ad8ccab": 0,
          "SLDL-MD-36-lower-ocd-person-297d462e-6cee-4faf-ab3e-4ded2ea6f916": 1,
          "SLDL-MD-36-lower-ocd-person-15b13c83-9ee7-43b6-a32a-f26d76573d54": 2
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/2f6dc3da-2750-4c4a-b4cf-034c40f3cb31",
            "firstName": "Gabriel",
            "lastName": "Acevero",
            "fullName": "Gabriel Acevero",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/acevero01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-MD-39-lower-ocd-person-2f6dc3da-2750-4c4a-b4cf-034c40f3cb31",
              "builtID": "md-lower-39",
              "externalID": "ocd-person/2f6dc3da-2750-4c4a-b4cf-034c40f3cb31",
              "geometry": null
            },
            "contactInfo": {
              "email": "gabriel.acevero@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=acevero01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/acevero01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/330c3ef8-92a2-41fc-9ef8-877d389aecdf",
            "firstName": "William Gregory",
            "lastName": "Wims",
            "fullName": "Greg Wims",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/wims01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-MD-39-lower-ocd-person-330c3ef8-92a2-41fc-9ef8-877d389aecdf",
              "builtID": "md-lower-39",
              "externalID": "ocd-person/330c3ef8-92a2-41fc-9ef8-877d389aecdf",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.wims@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/wims01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/3972ae32-865b-4f83-961f-d8a7ca703ad5",
            "firstName": "Lesley J.",
            "lastName": "Lopez",
            "fullName": "Lesley Lopez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://static.votesmart.org/static/canphoto/181654.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-MD-39-lower-ocd-person-3972ae32-865b-4f83-961f-d8a7ca703ad5",
              "builtID": "md-lower-39",
              "externalID": "ocd-person/3972ae32-865b-4f83-961f-d8a7ca703ad5",
              "geometry": null
            },
            "contactInfo": {
              "email": "lesley.lopez@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=lopez01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/lopez01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-39-lower-ocd-person-2f6dc3da-2750-4c4a-b4cf-034c40f3cb31": 0,
          "SLDL-MD-39-lower-ocd-person-330c3ef8-92a2-41fc-9ef8-877d389aecdf": 1,
          "SLDL-MD-39-lower-ocd-person-3972ae32-865b-4f83-961f-d8a7ca703ad5": 2
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/a07dd6a0-012b-41a7-901d-754d1cd194bb",
            "firstName": "Frank M.",
            "lastName": "Conaway",
            "fullName": "Frank Conaway",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/conaway.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-MD-40-lower-ocd-person-a07dd6a0-012b-41a7-901d-754d1cd194bb",
              "builtID": "md-lower-40",
              "externalID": "ocd-person/a07dd6a0-012b-41a7-901d-754d1cd194bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "frank.conaway@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=conaway&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa14616.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/conaway"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b6c25db2-72d7-47d0-a70d-f598d135180a",
            "firstName": "Marlon D.",
            "lastName": "Amprey",
            "fullName": "Marlon Amprey",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2021RS/images/amprey01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-MD-40-lower-ocd-person-b6c25db2-72d7-47d0-a70d-f598d135180a",
              "builtID": "md-lower-40",
              "externalID": "ocd-person/b6c25db2-72d7-47d0-a70d-f598d135180a",
              "geometry": null
            },
            "contactInfo": {
              "email": "marlon.amprey@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa18286.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/amprey01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/55cb04cb-cd28-44ed-a4a4-dcb2b064ede9",
            "firstName": "Melissa R.",
            "lastName": "Wells",
            "fullName": "Melissa Wells",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/wells02.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-MD-40-lower-ocd-person-55cb04cb-cd28-44ed-a4a4-dcb2b064ede9",
              "builtID": "md-lower-40",
              "externalID": "ocd-person/55cb04cb-cd28-44ed-a4a4-dcb2b064ede9",
              "geometry": null
            },
            "contactInfo": {
              "email": "melissa.wells@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=wells01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/wells02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-40-lower-ocd-person-a07dd6a0-012b-41a7-901d-754d1cd194bb": 0,
          "SLDL-MD-40-lower-ocd-person-b6c25db2-72d7-47d0-a70d-f598d135180a": 1,
          "SLDL-MD-40-lower-ocd-person-55cb04cb-cd28-44ed-a4a4-dcb2b064ede9": 2
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/d848993b-65e9-4ea3-ab2a-dfaf796142cc",
            "firstName": "Dalya",
            "lastName": "Attar-Mehrzadi",
            "fullName": "Dalya Attar",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/attar01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-MD-41-lower-ocd-person-d848993b-65e9-4ea3-ab2a-dfaf796142cc",
              "builtID": "md-lower-41",
              "externalID": "ocd-person/d848993b-65e9-4ea3-ab2a-dfaf796142cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "dalya.attar@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=attar01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/attar01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8ae09510-12a7-41f7-ae83-82b48e3b9af4",
            "firstName": "Malcolm Peter",
            "lastName": "Ruff",
            "fullName": "Malcolm Ruff",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/ruff01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-MD-41-lower-ocd-person-8ae09510-12a7-41f7-ae83-82b48e3b9af4",
              "builtID": "md-lower-41",
              "externalID": "ocd-person/8ae09510-12a7-41f7-ae83-82b48e3b9af4",
              "geometry": null
            },
            "contactInfo": {
              "email": "malcolm.ruff@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/ruff01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/9ec0fe7d-36ae-437b-9bcf-754da8e4e247",
            "firstName": "Samuel I.",
            "lastName": "Rosenberg",
            "fullName": "Sandy Rosenberg",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2022RS/images/rosenberg.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-MD-41-lower-ocd-person-9ec0fe7d-36ae-437b-9bcf-754da8e4e247",
              "builtID": "md-lower-41",
              "externalID": "ocd-person/9ec0fe7d-36ae-437b-9bcf-754da8e4e247",
              "geometry": null
            },
            "contactInfo": {
              "email": "samuel.rosenberg@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "4811 Liberty Heights Ave., Baltimore, MD 21207",
              "phone1": null,
              "phone2": "410-664-2646",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=rosenberg&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/rosenberg",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa12301.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-41-lower-ocd-person-d848993b-65e9-4ea3-ab2a-dfaf796142cc": 0,
          "SLDL-MD-41-lower-ocd-person-8ae09510-12a7-41f7-ae83-82b48e3b9af4": 1,
          "SLDL-MD-41-lower-ocd-person-9ec0fe7d-36ae-437b-9bcf-754da8e4e247": 2
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/d0ca1dc9-5ac3-42ea-8ec5-c86a229ccba5",
            "firstName": "Caylin",
            "lastName": "Young",
            "fullName": "Caylin Young",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/young05.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-MD-45-lower-ocd-person-d0ca1dc9-5ac3-42ea-8ec5-c86a229ccba5",
              "builtID": "md-lower-45",
              "externalID": "ocd-person/d0ca1dc9-5ac3-42ea-8ec5-c86a229ccba5",
              "geometry": null
            },
            "contactInfo": {
              "email": "caylin.young@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/young05"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/76c59be2-cf5e-49d2-8dd6-7d2506a7bcef",
            "firstName": "Jacqueline T.",
            "lastName": "Addison",
            "fullName": "Jackie Addison",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/addison01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-MD-45-lower-ocd-person-76c59be2-cf5e-49d2-8dd6-7d2506a7bcef",
              "builtID": "md-lower-45",
              "externalID": "ocd-person/76c59be2-cf5e-49d2-8dd6-7d2506a7bcef",
              "geometry": null
            },
            "contactInfo": {
              "email": "jackie.addison@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/addison01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0f8f065b-2d2e-4da3-91d4-988f16c72ef1",
            "firstName": "Stephanie Maddin",
            "lastName": "Smith",
            "fullName": "Stephanie Smith",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/smith03.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-MD-45-lower-ocd-person-0f8f065b-2d2e-4da3-91d4-988f16c72ef1",
              "builtID": "md-lower-45",
              "externalID": "ocd-person/0f8f065b-2d2e-4da3-91d4-988f16c72ef1",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephanie.smith@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=smith03&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/smith03"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-45-lower-ocd-person-d0ca1dc9-5ac3-42ea-8ec5-c86a229ccba5": 0,
          "SLDL-MD-45-lower-ocd-person-76c59be2-cf5e-49d2-8dd6-7d2506a7bcef": 1,
          "SLDL-MD-45-lower-ocd-person-0f8f065b-2d2e-4da3-91d4-988f16c72ef1": 2
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/1403e204-8965-4cb4-adcb-6754808e119a",
            "firstName": "Luke H.",
            "lastName": "Clippinger",
            "fullName": "Luke Clippinger",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2020RS/images/clippinger.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-MD-46-lower-ocd-person-1403e204-8965-4cb4-adcb-6754808e119a",
              "builtID": "md-lower-46",
              "externalID": "ocd-person/1403e204-8965-4cb4-adcb-6754808e119a",
              "geometry": null
            },
            "contactInfo": {
              "email": "uke.clippinger@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "3323 Eastern Ave. Suite 200, Baltimore, MD 21224",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=clippinger&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa15461.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/clippinger"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d09ea440-65a0-4e4f-81dd-201202f1ff3c",
            "firstName": "Mark",
            "lastName": "Edelson",
            "fullName": "Mark Edelson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/edelson01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-MD-46-lower-ocd-person-d09ea440-65a0-4e4f-81dd-201202f1ff3c",
              "builtID": "md-lower-46",
              "externalID": "ocd-person/d09ea440-65a0-4e4f-81dd-201202f1ff3c",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.edelson@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/edelson01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d0e4f14b-c4a3-485e-88f7-ea7a86f67ca1",
            "firstName": "Robbyn Terresa",
            "lastName": "Lewis",
            "fullName": "Robbyn Lewis",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://msa.maryland.gov/msa/mdmanual/06hse/images/1198-1-07944b.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-MD-46-lower-ocd-person-d0e4f14b-c4a3-485e-88f7-ea7a86f67ca1",
              "builtID": "md-lower-46",
              "externalID": "ocd-person/d0e4f14b-c4a3-485e-88f7-ea7a86f67ca1",
              "geometry": null
            },
            "contactInfo": {
              "email": "robbyn.lewis@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=lewis01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/lewis01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-46-lower-ocd-person-1403e204-8965-4cb4-adcb-6754808e119a": 0,
          "SLDL-MD-46-lower-ocd-person-d09ea440-65a0-4e4f-81dd-201202f1ff3c": 1,
          "SLDL-MD-46-lower-ocd-person-d0e4f14b-c4a3-485e-88f7-ea7a86f67ca1": 2
        }
      },
      "44B": {
        "members": [
          {
            "API_ID": "ocd-person/311ecab9-74b4-4c0f-849b-c077dfbb291f",
            "firstName": "Aletheia R.",
            "lastName": "McCaskill",
            "fullName": "Aletheia McCaskill",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/mccaskill01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "44B",
              "chamber": "lower",
              "hashID": "SLDL-MD-44B-lower-ocd-person-311ecab9-74b4-4c0f-849b-c077dfbb291f",
              "builtID": "md-lower-44b",
              "externalID": "ocd-person/311ecab9-74b4-4c0f-849b-c077dfbb291f",
              "geometry": null
            },
            "contactInfo": {
              "email": "aletheia.mccaskill@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/mccaskill01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4357a17f-f87b-4ae0-afe6-5d8ef9b3d7ed",
            "firstName": "Sheila S.",
            "lastName": "Ruth",
            "fullName": "Sheila Ruth",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2020RS/images/ruth01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "44B",
              "chamber": "lower",
              "hashID": "SLDL-MD-44B-lower-ocd-person-4357a17f-f87b-4ae0-afe6-5d8ef9b3d7ed",
              "builtID": "md-lower-44b",
              "externalID": "ocd-person/4357a17f-f87b-4ae0-afe6-5d8ef9b3d7ed",
              "geometry": null
            },
            "contactInfo": {
              "email": "sheila.ruth@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa18255.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/ruth01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-44B-lower-ocd-person-311ecab9-74b4-4c0f-849b-c077dfbb291f": 0,
          "SLDL-MD-44B-lower-ocd-person-4357a17f-f87b-4ae0-afe6-5d8ef9b3d7ed": 1
        }
      },
      "34A": {
        "members": [
          {
            "API_ID": "ocd-person/ecb19095-2750-4bef-bb87-1f646818a9b1",
            "firstName": "Andre V.",
            "lastName": "Johnson",
            "fullName": "Andre Johnson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://msa.maryland.gov/msa/mdmanual/36loc/ha/leg/images/1198-1-09406b.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "34A",
              "chamber": "lower",
              "hashID": "SLDL-MD-34A-lower-ocd-person-ecb19095-2750-4bef-bb87-1f646818a9b1",
              "builtID": "md-lower-34a",
              "externalID": "ocd-person/ecb19095-2750-4bef-bb87-1f646818a9b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "andre.johnson@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17954.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/johnson02"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8733ef57-5d09-47f2-9a16-d96cdc43485b",
            "firstName": "Steven C.",
            "lastName": "Johnson",
            "fullName": "Steve Johnson",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/johnson01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "34A",
              "chamber": "lower",
              "hashID": "SLDL-MD-34A-lower-ocd-person-8733ef57-5d09-47f2-9a16-d96cdc43485b",
              "builtID": "md-lower-34a",
              "externalID": "ocd-person/8733ef57-5d09-47f2-9a16-d96cdc43485b",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.johnson@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=johnson01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/johnson01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-34A-lower-ocd-person-ecb19095-2750-4bef-bb87-1f646818a9b1": 0,
          "SLDL-MD-34A-lower-ocd-person-8733ef57-5d09-47f2-9a16-d96cdc43485b": 1
        }
      },
      "33A": {
        "members": [
          {
            "API_ID": "ocd-person/7c57193f-f7af-4f7b-9b5f-cf17a7f51f25",
            "firstName": "Andrew Christopher",
            "lastName": "Pruski",
            "fullName": "Andrew Pruski",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/pruski01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "33A",
              "chamber": "lower",
              "hashID": "SLDL-MD-33A-lower-ocd-person-7c57193f-f7af-4f7b-9b5f-cf17a7f51f25",
              "builtID": "md-lower-33a",
              "externalID": "ocd-person/7c57193f-f7af-4f7b-9b5f-cf17a7f51f25",
              "geometry": null
            },
            "contactInfo": {
              "email": "andrew.pruski@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/pruski01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-33A-lower-ocd-person-7c57193f-f7af-4f7b-9b5f-cf17a7f51f25": 0
        }
      },
      "29B": {
        "members": [
          {
            "API_ID": "ocd-person/d9828ed6-bdb0-414e-ba12-486b78362842",
            "firstName": "Brian Michael",
            "lastName": "Crosby",
            "fullName": "Brian Crosby",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/crosby01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "29B",
              "chamber": "lower",
              "hashID": "SLDL-MD-29B-lower-ocd-person-d9828ed6-bdb0-414e-ba12-486b78362842",
              "builtID": "md-lower-29b",
              "externalID": "ocd-person/d9828ed6-bdb0-414e-ba12-486b78362842",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.crosby@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=crosby01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/crosby01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-29B-lower-ocd-person-d9828ed6-bdb0-414e-ba12-486b78362842": 0
        }
      },
      "2B": {
        "members": [
          {
            "API_ID": "ocd-person/6602a940-58df-434f-b9f1-d17e548a9b15",
            "firstName": "Brooke",
            "lastName": "Grossman",
            "fullName": "Brooke Grossman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gannett-cdn.com/presto/2022/07/03/NTHM/4200f0df-3071-4b2c-a3f9-efb0d3430e95-Brooke_Campaign.jpg?crop=719,405,x0,y96&width=719&height=405&format=pjpg&auto=webp",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "2B",
              "chamber": "lower",
              "hashID": "SLDL-MD-2B-lower-ocd-person-6602a940-58df-434f-b9f1-d17e548a9b15",
              "builtID": "md-lower-2b",
              "externalID": "ocd-person/6602a940-58df-434f-b9f1-d17e548a9b15",
              "geometry": null
            },
            "contactInfo": {
              "email": "brooke.grossman@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/grossman01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa18378.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-2B-lower-ocd-person-6602a940-58df-434f-b9f1-d17e548a9b15": 0
        }
      },
      "43B": {
        "members": [
          {
            "API_ID": "ocd-person/3c9356a4-fd55-43ff-809f-65568d0a8e53",
            "firstName": "Catherine M.",
            "lastName": "Forbes",
            "fullName": "Cathi Forbes",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/forbes01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "43B",
              "chamber": "lower",
              "hashID": "SLDL-MD-43B-lower-ocd-person-3c9356a4-fd55-43ff-809f-65568d0a8e53",
              "builtID": "md-lower-43b",
              "externalID": "ocd-person/3c9356a4-fd55-43ff-809f-65568d0a8e53",
              "geometry": null
            },
            "contactInfo": {
              "email": "catherine.forbes@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/forbes01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa18236.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-43B-lower-ocd-person-3c9356a4-fd55-43ff-809f-65568d0a8e53": 0
        }
      },
      "9A": {
        "members": [
          {
            "API_ID": "ocd-person/6bfe5c90-c168-41e5-a36d-6dcab4c0f5be",
            "firstName": "Chao",
            "lastName": "Wu",
            "fullName": "Chao Wu",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/ChaoWu.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "9A",
              "chamber": "lower",
              "hashID": "SLDL-MD-9A-lower-ocd-person-6bfe5c90-c168-41e5-a36d-6dcab4c0f5be",
              "builtID": "md-lower-9a",
              "externalID": "ocd-person/6bfe5c90-c168-41e5-a36d-6dcab4c0f5be",
              "geometry": null
            },
            "contactInfo": {
              "email": "chao.wu@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/wu01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/9496938b-7a65-4f74-9ecf-5b3e1ef94287",
            "firstName": "Natalie C.",
            "lastName": "Ziegler",
            "fullName": "Natalie Ziegler",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/ziegler01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "9A",
              "chamber": "lower",
              "hashID": "SLDL-MD-9A-lower-ocd-person-9496938b-7a65-4f74-9ecf-5b3e1ef94287",
              "builtID": "md-lower-9a",
              "externalID": "ocd-person/9496938b-7a65-4f74-9ecf-5b3e1ef94287",
              "geometry": null
            },
            "contactInfo": {
              "email": "natalie.ziegler@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/ziegler01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-9A-lower-ocd-person-6bfe5c90-c168-41e5-a36d-6dcab4c0f5be": 0,
          "SLDL-MD-9A-lower-ocd-person-9496938b-7a65-4f74-9ecf-5b3e1ef94287": 1
        }
      },
      "38A": {
        "members": [
          {
            "API_ID": "ocd-person/1a43a7cb-acbf-4974-8fe8-315cb674b27d",
            "firstName": "Charles James",
            "lastName": "Otto",
            "fullName": "Charles Otto",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/otto.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "38A",
              "chamber": "lower",
              "hashID": "SLDL-MD-38A-lower-ocd-person-1a43a7cb-acbf-4974-8fe8-315cb674b27d",
              "builtID": "md-lower-38a",
              "externalID": "ocd-person/1a43a7cb-acbf-4974-8fe8-315cb674b27d",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.otto@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 38, Princess Anne, MD 21853",
              "phone1": null,
              "phone2": "410-621-0200",
              "fax1": null,
              "fax2": "410-621-0300",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=otto&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa15521.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/otto"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-38A-lower-ocd-person-1a43a7cb-acbf-4974-8fe8-315cb674b27d": 0
        }
      },
      "11A": {
        "members": [
          {
            "API_ID": "ocd-person/f6826933-02c6-40ff-9df9-b9e82c54febc",
            "firstName": "Cheryl",
            "lastName": "Pasteur",
            "fullName": "Cheryl Pasteur",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://elections2018.news.baltimoresun.com/images/candidates/cheryl-e-pasteur-500.a5da2e57eaba873842ee212a1a709bb74cb44f96bf3b5d8158034d0d4174834f.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "11A",
              "chamber": "lower",
              "hashID": "SLDL-MD-11A-lower-ocd-person-f6826933-02c6-40ff-9df9-b9e82c54febc",
              "builtID": "md-lower-11a",
              "externalID": "ocd-person/f6826933-02c6-40ff-9df9-b9e82c54febc",
              "geometry": null
            },
            "contactInfo": {
              "email": "cheryl.pasteur@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/pasteur01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa18388.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-11A-lower-ocd-person-f6826933-02c6-40ff-9df9-b9e82c54febc": 0
        }
      },
      "37B": {
        "members": [
          {
            "API_ID": "ocd-person/b7e16db2-0bde-4bed-8eb1-09f30ae66d4e",
            "firstName": "Christopher T.",
            "lastName": "Adams",
            "fullName": "Chris Adams",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/adams01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "37B",
              "chamber": "lower",
              "hashID": "SLDL-MD-37B-lower-ocd-person-b7e16db2-0bde-4bed-8eb1-09f30ae66d4e",
              "builtID": "md-lower-37b",
              "externalID": "ocd-person/b7e16db2-0bde-4bed-8eb1-09f30ae66d4e",
              "geometry": null
            },
            "contactInfo": {
              "email": "christopher.adams@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=adams01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17076.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/adams01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d8ab28c1-cdd0-4bef-80a6-9712887594b0",
            "firstName": "Thomas S.",
            "lastName": "Hutchinson",
            "fullName": "Tom Hutchinson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://mgaleg.maryland.gov/2023RS/images/hutchinson01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "37B",
              "chamber": "lower",
              "hashID": "SLDL-MD-37B-lower-ocd-person-d8ab28c1-cdd0-4bef-80a6-9712887594b0",
              "builtID": "md-lower-37b",
              "externalID": "ocd-person/d8ab28c1-cdd0-4bef-80a6-9712887594b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.hutchinson@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/hutchinson01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-37B-lower-ocd-person-b7e16db2-0bde-4bed-8eb1-09f30ae66d4e": 0,
          "SLDL-MD-37B-lower-ocd-person-d8ab28c1-cdd0-4bef-80a6-9712887594b0": 1
        }
      },
      "9B": {
        "members": [
          {
            "API_ID": "ocd-person/ef1a4d48-39d0-4b6c-9ffc-a6d30b28476d",
            "firstName": "Mary Courtney",
            "lastName": "Watson",
            "fullName": "Courtney Watson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/watson02.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "9B",
              "chamber": "lower",
              "hashID": "SLDL-MD-9B-lower-ocd-person-ef1a4d48-39d0-4b6c-9ffc-a6d30b28476d",
              "builtID": "md-lower-9b",
              "externalID": "ocd-person/ef1a4d48-39d0-4b6c-9ffc-a6d30b28476d",
              "geometry": null
            },
            "contactInfo": {
              "email": "courtney.watson@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=watson02&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/watson02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-9B-lower-ocd-person-ef1a4d48-39d0-4b6c-9ffc-a6d30b28476d": 0
        }
      },
      "30A": {
        "members": [
          {
            "API_ID": "ocd-person/73faf103-715f-425d-8642-0ecd0cf1d4c9",
            "firstName": "Dana Celeste",
            "lastName": "Jones",
            "fullName": "Dana Jones",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/jones01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "30A",
              "chamber": "lower",
              "hashID": "SLDL-MD-30A-lower-ocd-person-73faf103-715f-425d-8642-0ecd0cf1d4c9",
              "builtID": "md-lower-30a",
              "externalID": "ocd-person/73faf103-715f-425d-8642-0ecd0cf1d4c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "dana.jones@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa18264.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/jones01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/be45d2a1-057a-49ea-a810-5317478c26b1",
            "firstName": "Shaneka T.",
            "lastName": "Henson",
            "fullName": "Shaneka Henson",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/henson01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "30A",
              "chamber": "lower",
              "hashID": "SLDL-MD-30A-lower-ocd-person-be45d2a1-057a-49ea-a810-5317478c26b1",
              "builtID": "md-lower-30a",
              "externalID": "ocd-person/be45d2a1-057a-49ea-a810-5317478c26b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "shaneka.henson@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/henson01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa18109.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-30A-lower-ocd-person-73faf103-715f-425d-8642-0ecd0cf1d4c9": 0,
          "SLDL-MD-30A-lower-ocd-person-be45d2a1-057a-49ea-a810-5317478c26b1": 1
        }
      },
      "11B": {
        "members": [
          {
            "API_ID": "ocd-person/0418164e-2e86-4186-9f2d-327cb86b7eda",
            "firstName": "Dana Max",
            "lastName": "Stein",
            "fullName": "Dana Stein",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2020RS/images/stein.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "11B",
              "chamber": "lower",
              "hashID": "SLDL-MD-11B-lower-ocd-person-0418164e-2e86-4186-9f2d-327cb86b7eda",
              "builtID": "md-lower-11b",
              "externalID": "ocd-person/0418164e-2e86-4186-9f2d-327cb86b7eda",
              "geometry": null
            },
            "contactInfo": {
              "email": "dana.stein@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=stein&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/stein"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/13771198-ddf4-4dfd-80f1-b4069ec55914",
            "firstName": "Jon S.",
            "lastName": "Cardin",
            "fullName": "Jon Cardin",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/cardin01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "11B",
              "chamber": "lower",
              "hashID": "SLDL-MD-11B-lower-ocd-person-13771198-ddf4-4dfd-80f1-b4069ec55914",
              "builtID": "md-lower-11b",
              "externalID": "ocd-person/13771198-ddf4-4dfd-80f1-b4069ec55914",
              "geometry": null
            },
            "contactInfo": {
              "email": "jon.cardin@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=cardin01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/cardin01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-11B-lower-ocd-person-0418164e-2e86-4186-9f2d-327cb86b7eda": 0,
          "SLDL-MD-11B-lower-ocd-person-13771198-ddf4-4dfd-80f1-b4069ec55914": 1
        }
      },
      "47B": {
        "members": [
          {
            "API_ID": "ocd-person/1ef3da63-78d6-4d56-a5a0-b4c4106eda34",
            "firstName": "Deni",
            "lastName": "Taveras",
            "fullName": "Deni Taveras",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://global-uploads.webflow.com/5e1b86c619c05c91b9967ad6/620e4aa91638bf13a472cf3e_DtOqMwcsRnKd3LnHjPV2jw_thumb_d6b.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "47B",
              "chamber": "lower",
              "hashID": "SLDL-MD-47B-lower-ocd-person-1ef3da63-78d6-4d56-a5a0-b4c4106eda34",
              "builtID": "md-lower-47b",
              "externalID": "ocd-person/1ef3da63-78d6-4d56-a5a0-b4c4106eda34",
              "geometry": null
            },
            "contactInfo": {
              "email": "deni.taveras@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/taveras01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-47B-lower-ocd-person-1ef3da63-78d6-4d56-a5a0-b4c4106eda34": 0
        }
      },
      "47A": {
        "members": [
          {
            "API_ID": "ocd-person/3235313d-d279-4aaf-97fd-476d4c79c11a",
            "firstName": "Diana M.",
            "lastName": "Fennell",
            "fullName": "Diana Fennell",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/fennell01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "47A",
              "chamber": "lower",
              "hashID": "SLDL-MD-47A-lower-ocd-person-3235313d-d279-4aaf-97fd-476d4c79c11a",
              "builtID": "md-lower-47a",
              "externalID": "ocd-person/3235313d-d279-4aaf-97fd-476d4c79c11a",
              "geometry": null
            },
            "contactInfo": {
              "email": "diana.fennell@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=fennell01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17085.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/fennell01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e57fb9b8-7870-4bd2-8f46-df05edf038ac",
            "firstName": "Robert Julian",
            "lastName": "Ivey",
            "fullName": "Julian Ivey",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/ivey01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "47A",
              "chamber": "lower",
              "hashID": "SLDL-MD-47A-lower-ocd-person-e57fb9b8-7870-4bd2-8f46-df05edf038ac",
              "builtID": "md-lower-47a",
              "externalID": "ocd-person/e57fb9b8-7870-4bd2-8f46-df05edf038ac",
              "geometry": null
            },
            "contactInfo": {
              "email": "julian.ivey@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=ivey01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/ivey01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-47A-lower-ocd-person-3235313d-d279-4aaf-97fd-476d4c79c11a": 0,
          "SLDL-MD-47A-lower-ocd-person-e57fb9b8-7870-4bd2-8f46-df05edf038ac": 1
        }
      },
      "43A": {
        "members": [
          {
            "API_ID": "ocd-person/cded392e-47da-4c74-998d-7276520db9ad",
            "firstName": "Elizabeth M.",
            "lastName": "Embry",
            "fullName": "Elizabeth Embry",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/embry01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "43A",
              "chamber": "lower",
              "hashID": "SLDL-MD-43A-lower-ocd-person-cded392e-47da-4c74-998d-7276520db9ad",
              "builtID": "md-lower-43a",
              "externalID": "ocd-person/cded392e-47da-4c74-998d-7276520db9ad",
              "geometry": null
            },
            "contactInfo": {
              "email": "elizabeth.embry@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/embry01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f25013b8-d93b-46bf-a1ef-8b368731040d",
            "firstName": "Regina T.",
            "lastName": "Boyce",
            "fullName": "Regina Boyce",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/boyce01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "43A",
              "chamber": "lower",
              "hashID": "SLDL-MD-43A-lower-ocd-person-f25013b8-d93b-46bf-a1ef-8b368731040d",
              "builtID": "md-lower-43a",
              "externalID": "ocd-person/f25013b8-d93b-46bf-a1ef-8b368731040d",
              "geometry": null
            },
            "contactInfo": {
              "email": "regina.boyce@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=boyce01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/boyce01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-43A-lower-ocd-person-cded392e-47da-4c74-998d-7276520db9ad": 0,
          "SLDL-MD-43A-lower-ocd-person-f25013b8-d93b-46bf-a1ef-8b368731040d": 1
        }
      },
      "44A": {
        "members": [
          {
            "API_ID": "ocd-person/cbf6aefa-a6aa-4de5-9237-d1c34e5c4930",
            "firstName": "Eric D.",
            "lastName": "Ebersole",
            "fullName": "Eric Ebersole",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/ebersole01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "44A",
              "chamber": "lower",
              "hashID": "SLDL-MD-44A-lower-ocd-person-cbf6aefa-a6aa-4de5-9237-d1c34e5c4930",
              "builtID": "md-lower-44a",
              "externalID": "ocd-person/cbf6aefa-a6aa-4de5-9237-d1c34e5c4930",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.ebersole@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=ebersole01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17056.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/ebersole01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-44A-lower-ocd-person-cbf6aefa-a6aa-4de5-9237-d1c34e5c4930": 0
        }
      },
      "12B": {
        "members": [
          {
            "API_ID": "ocd-person/e420ea16-5cb8-49b4-ba37-e5b5876a02fc",
            "firstName": "Gary",
            "lastName": "Simmons",
            "fullName": "Gary Simmons",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/simmons01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "12B",
              "chamber": "lower",
              "hashID": "SLDL-MD-12B-lower-ocd-person-e420ea16-5cb8-49b4-ba37-e5b5876a02fc",
              "builtID": "md-lower-12b",
              "externalID": "ocd-person/e420ea16-5cb8-49b4-ba37-e5b5876a02fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "gary.simmons@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/simmons01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-12B-lower-ocd-person-e420ea16-5cb8-49b4-ba37-e5b5876a02fc": 0
        }
      },
      "33C": {
        "members": [
          {
            "API_ID": "ocd-person/396e14a6-1903-4a12-ad7b-aefae5263b0c",
            "firstName": "Heather Alice",
            "lastName": "Bagnall Tudball",
            "fullName": "Heather Bagnall Tudball",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/bagnall01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "33C",
              "chamber": "lower",
              "hashID": "SLDL-MD-33C-lower-ocd-person-396e14a6-1903-4a12-ad7b-aefae5263b0c",
              "builtID": "md-lower-33c",
              "externalID": "ocd-person/396e14a6-1903-4a12-ad7b-aefae5263b0c",
              "geometry": null
            },
            "contactInfo": {
              "email": "heather.bagnall@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=bagnall01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/bagnall01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-33C-lower-ocd-person-396e14a6-1903-4a12-ad7b-aefae5263b0c": 0
        }
      },
      "1B": {
        "members": [
          {
            "API_ID": "ocd-person/0218a7f4-9904-426e-b676-be22eb714185",
            "firstName": "Jason C.",
            "lastName": "Buckel",
            "fullName": "Jason Buckel",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/buckel01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "1B",
              "chamber": "lower",
              "hashID": "SLDL-MD-1B-lower-ocd-person-0218a7f4-9904-426e-b676-be22eb714185",
              "builtID": "md-lower-1b",
              "externalID": "ocd-person/0218a7f4-9904-426e-b676-be22eb714185",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.buckel@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=buckel01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17042.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/buckel01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-1B-lower-ocd-person-0218a7f4-9904-426e-b676-be22eb714185": 0
        }
      },
      "27B": {
        "members": [
          {
            "API_ID": "ocd-person/20f85b73-be94-4030-bac8-4a6e515f9285",
            "firstName": "Jeffrie Eugene",
            "lastName": "Long",
            "fullName": "Jeff Long",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/long02.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "27B",
              "chamber": "lower",
              "hashID": "SLDL-MD-27B-lower-ocd-person-20f85b73-be94-4030-bac8-4a6e515f9285",
              "builtID": "md-lower-27b",
              "externalID": "ocd-person/20f85b73-be94-4030-bac8-4a6e515f9285",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeffrie.long@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/long02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-27B-lower-ocd-person-20f85b73-be94-4030-bac8-4a6e515f9285": 0
        }
      },
      "12A": {
        "members": [
          {
            "API_ID": "ocd-person/5e096a5d-1598-49c1-ba34-758759953f2f",
            "firstName": "Jessica Marie Page",
            "lastName": "Feldmark",
            "fullName": "Jessica Feldmark",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/feldmark01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "12A",
              "chamber": "lower",
              "hashID": "SLDL-MD-12A-lower-ocd-person-5e096a5d-1598-49c1-ba34-758759953f2f",
              "builtID": "md-lower-12a",
              "externalID": "ocd-person/5e096a5d-1598-49c1-ba34-758759953f2f",
              "geometry": null
            },
            "contactInfo": {
              "email": "jessica.feldmark@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=feldmark01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/feldmark01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/9b63f31e-5bc0-47bf-a901-0a8dcc2a67b7",
            "firstName": "Terri Lynn",
            "lastName": "Hill",
            "fullName": "Terri Hill",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/hill02.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "12A",
              "chamber": "lower",
              "hashID": "SLDL-MD-12A-lower-ocd-person-9b63f31e-5bc0-47bf-a901-0a8dcc2a67b7",
              "builtID": "md-lower-12a",
              "externalID": "ocd-person/9b63f31e-5bc0-47bf-a901-0a8dcc2a67b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "terri.hill@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=hill02&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17057.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/hill02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-12A-lower-ocd-person-5e096a5d-1598-49c1-ba34-758759953f2f": 0,
          "SLDL-MD-12A-lower-ocd-person-9b63f31e-5bc0-47bf-a901-0a8dcc2a67b7": 1
        }
      },
      "1A": {
        "members": [
          {
            "API_ID": "ocd-person/bb4f12c9-db2f-449d-a3a3-a7f77ad5d1f9",
            "firstName": "James Carlton",
            "lastName": "Hinebaugh",
            "fullName": "Jim Hinebaugh",
            "gender": "Male",
            "party": "Republican",
            "image": "https://mgaleg.maryland.gov/2023RS/images/hinebaugh01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "1A",
              "chamber": "lower",
              "hashID": "SLDL-MD-1A-lower-ocd-person-bb4f12c9-db2f-449d-a3a3-a7f77ad5d1f9",
              "builtID": "md-lower-1a",
              "externalID": "ocd-person/bb4f12c9-db2f-449d-a3a3-a7f77ad5d1f9",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.hinebaugh@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/hinebaugh01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-1A-lower-ocd-person-bb4f12c9-db2f-449d-a3a3-a7f77ad5d1f9": 0
        }
      },
      "42C": {
        "members": [
          {
            "API_ID": "ocd-person/9026e10a-e925-4bcb-ba28-85852dc4b2a4",
            "firstName": "Joshua J.",
            "lastName": "Stonko",
            "fullName": "Josh Stonko",
            "gender": "Male",
            "party": "Republican",
            "image": "https://mgaleg.maryland.gov/2023RS/images/stonko01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "42C",
              "chamber": "lower",
              "hashID": "SLDL-MD-42C-lower-ocd-person-9026e10a-e925-4bcb-ba28-85852dc4b2a4",
              "builtID": "md-lower-42c",
              "externalID": "ocd-person/9026e10a-e925-4bcb-ba28-85852dc4b2a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "joshua.stonko@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/stonko01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-42C-lower-ocd-person-9026e10a-e925-4bcb-ba28-85852dc4b2a4": 0
        }
      },
      "38B": {
        "members": [
          {
            "API_ID": "ocd-person/882548a3-099b-4763-9ec2-751ea362637b",
            "firstName": "Carl L.",
            "lastName": "Anderton",
            "fullName": "Carl Anderton",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/anderton01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "38B",
              "chamber": "lower",
              "hashID": "SLDL-MD-38B-lower-ocd-person-882548a3-099b-4763-9ec2-751ea362637b",
              "builtID": "md-lower-38b",
              "externalID": "ocd-person/882548a3-099b-4763-9ec2-751ea362637b",
              "geometry": null
            },
            "contactInfo": {
              "email": "carl.anderton@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=anderton01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17078.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/anderton01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-38B-lower-ocd-person-882548a3-099b-4763-9ec2-751ea362637b": 0
        }
      },
      "7A": {
        "members": [
          {
            "API_ID": "ocd-person/943d1601-904b-40dc-8b98-84bbb02851ab",
            "firstName": "Kathryn Y.",
            "lastName": "Szeliga",
            "fullName": "Kathy Szeliga",
            "gender": "Female",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/szeliga.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "7A",
              "chamber": "lower",
              "hashID": "SLDL-MD-7A-lower-ocd-person-943d1601-904b-40dc-8b98-84bbb02851ab",
              "builtID": "md-lower-7a",
              "externalID": "ocd-person/943d1601-904b-40dc-8b98-84bbb02851ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "kathy.szeliga@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=szeliga&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa15446.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/szeliga"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/bf30221f-f777-4158-bf3b-2ca40dad8144",
            "firstName": "Ryan M.",
            "lastName": "Nawrocki",
            "fullName": "Ryan Nawrocki",
            "gender": "Male",
            "party": "Republican",
            "image": "https://mgaleg.maryland.gov/2023RS/images/nawrocki01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "7A",
              "chamber": "lower",
              "hashID": "SLDL-MD-7A-lower-ocd-person-bf30221f-f777-4158-bf3b-2ca40dad8144",
              "builtID": "md-lower-7a",
              "externalID": "ocd-person/bf30221f-f777-4158-bf3b-2ca40dad8144",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryan.nawrocki@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/nawrocki01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-7A-lower-ocd-person-943d1601-904b-40dc-8b98-84bbb02851ab": 0,
          "SLDL-MD-7A-lower-ocd-person-bf30221f-f777-4158-bf3b-2ca40dad8144": 1
        }
      },
      "35B": {
        "members": [
          {
            "API_ID": "ocd-person/fe412f7a-cff0-4153-bfea-a0578f2e1aaa",
            "firstName": "Kevin B.",
            "lastName": "Hornberger",
            "fullName": "Kevin Hornberger",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/hornberger01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "35B",
              "chamber": "lower",
              "hashID": "SLDL-MD-35B-lower-ocd-person-fe412f7a-cff0-4153-bfea-a0578f2e1aaa",
              "builtID": "md-lower-35b",
              "externalID": "ocd-person/fe412f7a-cff0-4153-bfea-a0578f2e1aaa",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.hornberger@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=hornberger01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17073.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/hornberger01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-35B-lower-ocd-person-fe412f7a-cff0-4153-bfea-a0578f2e1aaa": 0
        }
      },
      "27A": {
        "members": [
          {
            "API_ID": "ocd-person/0b93e4f5-9d81-4c35-a9d3-04ad1375bae9",
            "firstName": "Kevin M.",
            "lastName": "Harris",
            "fullName": "Kevin Harris",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/harris02.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "27A",
              "chamber": "lower",
              "hashID": "SLDL-MD-27A-lower-ocd-person-0b93e4f5-9d81-4c35-a9d3-04ad1375bae9",
              "builtID": "md-lower-27a",
              "externalID": "ocd-person/0b93e4f5-9d81-4c35-a9d3-04ad1375bae9",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.harris@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/harris02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-27A-lower-ocd-person-0b93e4f5-9d81-4c35-a9d3-04ad1375bae9": 0
        }
      },
      "7B": {
        "members": [
          {
            "API_ID": "ocd-person/6d88ea30-7b4e-4dcb-8cc7-ed4bb6edc317",
            "firstName": "Lauren Rothleitner",
            "lastName": "Arikan",
            "fullName": "Lauren Arikan",
            "gender": "Female",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2019RS/images/arikan01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "7B",
              "chamber": "lower",
              "hashID": "SLDL-MD-7B-lower-ocd-person-6d88ea30-7b4e-4dcb-8cc7-ed4bb6edc317",
              "builtID": "md-lower-7b",
              "externalID": "ocd-person/6d88ea30-7b4e-4dcb-8cc7-ed4bb6edc317",
              "geometry": null
            },
            "contactInfo": {
              "email": "lauren.arikan@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=arikan01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/arikan01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-7B-lower-ocd-person-6d88ea30-7b4e-4dcb-8cc7-ed4bb6edc317": 0
        }
      },
      "27C": {
        "members": [
          {
            "API_ID": "ocd-person/77d4130a-be8c-46a7-adc4-08ecd11ea6ee",
            "firstName": "Mark Nicholas",
            "lastName": "Fisher",
            "fullName": "Mark Fisher",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/fisher.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "27C",
              "chamber": "lower",
              "hashID": "SLDL-MD-27C-lower-ocd-person-77d4130a-be8c-46a7-adc4-08ecd11ea6ee",
              "builtID": "md-lower-27c",
              "externalID": "ocd-person/77d4130a-be8c-46a7-adc4-08ecd11ea6ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "rk.fisher@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=fisher&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa15454.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/fisher"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-27C-lower-ocd-person-77d4130a-be8c-46a7-adc4-08ecd11ea6ee": 0
        }
      },
      "29A": {
        "members": [
          {
            "API_ID": "ocd-person/d7346fb4-948a-4e86-8b06-5e43345e4b4a",
            "firstName": "James Matthew",
            "lastName": "Morgan",
            "fullName": "Matt Morgan",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/morgan02.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "29A",
              "chamber": "lower",
              "hashID": "SLDL-MD-29A-lower-ocd-person-d7346fb4-948a-4e86-8b06-5e43345e4b4a",
              "builtID": "md-lower-29a",
              "externalID": "ocd-person/d7346fb4-948a-4e86-8b06-5e43345e4b4a",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.morgan@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=morgan02&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17068.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/morgan02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-29A-lower-ocd-person-d7346fb4-948a-4e86-8b06-5e43345e4b4a": 0
        }
      },
      "42B": {
        "members": [
          {
            "API_ID": "ocd-person/b120ed9c-3669-44e7-a5ef-710b716071e7",
            "firstName": "Michele Jenkins",
            "lastName": "Guyton",
            "fullName": "Michele Guyton",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/guyton01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "42B",
              "chamber": "lower",
              "hashID": "SLDL-MD-42B-lower-ocd-person-b120ed9c-3669-44e7-a5ef-710b716071e7",
              "builtID": "md-lower-42b",
              "externalID": "ocd-person/b120ed9c-3669-44e7-a5ef-710b716071e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "michele.guyton@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=guyton01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/guyton01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-42B-lower-ocd-person-b120ed9c-3669-44e7-a5ef-710b716071e7": 0
        }
      },
      "35A": {
        "members": [
          {
            "API_ID": "ocd-person/79a4ffd2-8ca9-4fb2-beb2-bcbfd1d49897",
            "firstName": "Michael J.",
            "lastName": "Griffith",
            "fullName": "Mike Griffith",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2020RS/images/griffith02.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "35A",
              "chamber": "lower",
              "hashID": "SLDL-MD-35A-lower-ocd-person-79a4ffd2-8ca9-4fb2-beb2-bcbfd1d49897",
              "builtID": "md-lower-35a",
              "externalID": "ocd-person/79a4ffd2-8ca9-4fb2-beb2-bcbfd1d49897",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.griffith@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/griffith02",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa18252.html"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ad9925ab-ab4a-46e9-a316-4d3a964736a3",
            "firstName": "Teresa E.",
            "lastName": "Reilly",
            "fullName": "Teresa Reilly",
            "gender": "Female",
            "party": "Republican",
            "image": "https://mgaleg.maryland.gov/2023RS/images/reilly01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "35A",
              "chamber": "lower",
              "hashID": "SLDL-MD-35A-lower-ocd-person-ad9925ab-ab4a-46e9-a316-4d3a964736a3",
              "builtID": "md-lower-35a",
              "externalID": "ocd-person/ad9925ab-ab4a-46e9-a316-4d3a964736a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "teresa.reilly@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=reilly01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17075.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/reilly01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-35A-lower-ocd-person-79a4ffd2-8ca9-4fb2-beb2-bcbfd1d49897": 0,
          "SLDL-MD-35A-lower-ocd-person-ad9925ab-ab4a-46e9-a316-4d3a964736a3": 1
        }
      },
      "42A": {
        "members": [
          {
            "API_ID": "ocd-person/7817a7bd-51a6-456a-a2df-4b9e3523d181",
            "firstName": "Antonino D.",
            "lastName": "Mangione",
            "fullName": "Nino Mangione",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2019RS/images/mangione01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "42A",
              "chamber": "lower",
              "hashID": "SLDL-MD-42A-lower-ocd-person-7817a7bd-51a6-456a-a2df-4b9e3523d181",
              "builtID": "md-lower-42a",
              "externalID": "ocd-person/7817a7bd-51a6-456a-a2df-4b9e3523d181",
              "geometry": null
            },
            "contactInfo": {
              "email": "nino.mangione@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=mangione01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/mangione01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-42A-lower-ocd-person-7817a7bd-51a6-456a-a2df-4b9e3523d181": 0
        }
      },
      "30B": {
        "members": [
          {
            "API_ID": "ocd-person/32a0f09e-394f-446c-93c7-9cff2ea28b83",
            "firstName": "Seth A.",
            "lastName": "Howard",
            "fullName": "Seth Howard",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/howard01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "30B",
              "chamber": "lower",
              "hashID": "SLDL-MD-30B-lower-ocd-person-32a0f09e-394f-446c-93c7-9cff2ea28b83",
              "builtID": "md-lower-30b",
              "externalID": "ocd-person/32a0f09e-394f-446c-93c7-9cff2ea28b83",
              "geometry": null
            },
            "contactInfo": {
              "email": "seth.howard@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=howard01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa17069.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/howard01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-30B-lower-ocd-person-32a0f09e-394f-446c-93c7-9cff2ea28b83": 0
        }
      },
      "37A": {
        "members": [
          {
            "API_ID": "ocd-person/1bad5583-acbc-451b-9425-0a8936f1e7b5",
            "firstName": "Sheree",
            "lastName": "Sample-Hughes",
            "fullName": "Sheree Sample-Hughes",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/sample01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "37A",
              "chamber": "lower",
              "hashID": "SLDL-MD-37A-lower-ocd-person-1bad5583-acbc-451b-9425-0a8936f1e7b5",
              "builtID": "md-lower-37a",
              "externalID": "ocd-person/1bad5583-acbc-451b-9425-0a8936f1e7b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "sheree.sample.hughes@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=sample01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa14911.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/sample01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-37A-lower-ocd-person-1bad5583-acbc-451b-9425-0a8936f1e7b5": 0
        }
      },
      "33B": {
        "members": [
          {
            "API_ID": "ocd-person/f2511fcc-12a0-46e4-b831-803485efb5ea",
            "firstName": "Stuart",
            "lastName": "Schmidt",
            "fullName": "Stuart Schmidt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://mgaleg.maryland.gov/2023RS/images/schmidt01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "33B",
              "chamber": "lower",
              "hashID": "SLDL-MD-33B-lower-ocd-person-f2511fcc-12a0-46e4-b831-803485efb5ea",
              "builtID": "md-lower-33b",
              "externalID": "ocd-person/f2511fcc-12a0-46e4-b831-803485efb5ea",
              "geometry": null
            },
            "contactInfo": {
              "email": "stuart.schmidt@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/schmidt01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-33B-lower-ocd-person-f2511fcc-12a0-46e4-b831-803485efb5ea": 0
        }
      },
      "34B": {
        "members": [
          {
            "API_ID": "ocd-person/1cfdc0e8-7eed-411a-b931-cffa6479b51f",
            "firstName": "Susan K.",
            "lastName": "McComas",
            "fullName": "Susan McComas",
            "gender": "Female",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/mccomas.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "34B",
              "chamber": "lower",
              "hashID": "SLDL-MD-34B-lower-ocd-person-1cfdc0e8-7eed-411a-b931-cffa6479b51f",
              "builtID": "md-lower-34b",
              "externalID": "ocd-person/1cfdc0e8-7eed-411a-b931-cffa6479b51f",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.mccomas@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1204, Bel Air, MD 21014",
              "phone1": null,
              "phone2": "410-836-9449",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=mccomas&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa14001.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/mccomas"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-34B-lower-ocd-person-1cfdc0e8-7eed-411a-b931-cffa6479b51f": 0
        }
      },
      "1C": {
        "members": [
          {
            "API_ID": "ocd-person/e5f4fb79-fd8e-437d-9488-68fd47d430c6",
            "firstName": "Terry Lee",
            "lastName": "Baker",
            "fullName": "Terry Baker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://msa.maryland.gov/msa/mdmanual/36loc/wa/leg/images/1198-1-09528b.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "1C",
              "chamber": "lower",
              "hashID": "SLDL-MD-1C-lower-ocd-person-e5f4fb79-fd8e-437d-9488-68fd47d430c6",
              "builtID": "md-lower-1c",
              "externalID": "ocd-person/e5f4fb79-fd8e-437d-9488-68fd47d430c6",
              "geometry": null
            },
            "contactInfo": {
              "email": "terry.baker@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/baker04"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-1C-lower-ocd-person-e5f4fb79-fd8e-437d-9488-68fd47d430c6": 0
        }
      },
      "29C": {
        "members": [
          {
            "API_ID": "ocd-person/c08afca2-f312-4cb0-a9c5-58be80d81df1",
            "firstName": "Todd B.",
            "lastName": "Morgan",
            "fullName": "Todd Morgan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://mgaleg.maryland.gov/2023RS/images/morgan03.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "29C",
              "chamber": "lower",
              "hashID": "SLDL-MD-29C-lower-ocd-person-c08afca2-f312-4cb0-a9c5-58be80d81df1",
              "builtID": "md-lower-29c",
              "externalID": "ocd-person/c08afca2-f312-4cb0-a9c5-58be80d81df1",
              "geometry": null
            },
            "contactInfo": {
              "email": "todd.morgan@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/morgan03"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-29C-lower-ocd-person-c08afca2-f312-4cb0-a9c5-58be80d81df1": 0
        }
      },
      "38C": {
        "members": [
          {
            "API_ID": "ocd-person/ed6c6e8e-a50c-4fce-86a8-0d9d7a75aa1f",
            "firstName": "Wayne A.",
            "lastName": "Hartman",
            "fullName": "Wayne Hartman",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2019RS/images/hartman01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "38C",
              "chamber": "lower",
              "hashID": "SLDL-MD-38C-lower-ocd-person-ed6c6e8e-a50c-4fce-86a8-0d9d7a75aa1f",
              "builtID": "md-lower-38c",
              "externalID": "ocd-person/ed6c6e8e-a50c-4fce-86a8-0d9d7a75aa1f",
              "geometry": null
            },
            "contactInfo": {
              "email": "wayne.hartman@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=hartman01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/hartman01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-38C-lower-ocd-person-ed6c6e8e-a50c-4fce-86a8-0d9d7a75aa1f": 0
        }
      },
      "2A": {
        "members": [
          {
            "API_ID": "ocd-person/7616b0d4-487f-4667-b92c-b09f628235d1",
            "firstName": "William J.",
            "lastName": "Wivell",
            "fullName": "William Wivell",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/wivell01.jpg",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "2A",
              "chamber": "lower",
              "hashID": "SLDL-MD-2A-lower-ocd-person-7616b0d4-487f-4667-b92c-b09f628235d1",
              "builtID": "md-lower-2a",
              "externalID": "ocd-person/7616b0d4-487f-4667-b92c-b09f628235d1",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.wivell@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 273, Smithsburg, MD 21783",
              "phone1": null,
              "phone2": "410-841-3447",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=wivell01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa14893.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/wivell01"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/943e92d3-98ad-490f-a93a-16182c624a1f",
            "firstName": "William M.",
            "lastName": "Valentine",
            "fullName": "William Valentine",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.newyork1.vip.townnews.com/fredericknewspost.com/content/tncms/assets/v3/editorial/a/7a/a7adf4f6-64f2-5123-82bc-5d65e10bda6e/62b95a9a20374.image.jpg?crop=815%2C1208%2C356%2C15&resize=200%2C296&order=crop%2Cresize",
            "title": "MD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "2A",
              "chamber": "lower",
              "hashID": "SLDL-MD-2A-lower-ocd-person-943e92d3-98ad-490f-a93a-16182c624a1f",
              "builtID": "md-lower-2a",
              "externalID": "ocd-person/943e92d3-98ad-490f-a93a-16182c624a1f",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.valentine@house.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/valentine01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MD-2A-lower-ocd-person-7616b0d4-487f-4667-b92c-b09f628235d1": 0,
          "SLDL-MD-2A-lower-ocd-person-943e92d3-98ad-490f-a93a-16182c624a1f": 1
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/5bdf592a-c502-4b60-8799-8ed681950475",
            "firstName": "Michael W.",
            "lastName": "McKay",
            "fullName": "Mike McKay",
            "gender": "Male",
            "party": "Republican",
            "image": "https://mgaleg.maryland.gov/2023RS/images/mckay02.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-MD-1-upper-ocd-person-5bdf592a-c502-4b60-8799-8ed681950475",
              "builtID": "md-upper-1",
              "externalID": "ocd-person/5bdf592a-c502-4b60-8799-8ed681950475",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.mckay@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=mckay01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/mckay01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/mckay02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-1-upper-ocd-person-5bdf592a-c502-4b60-8799-8ed681950475": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/6605f7aa-80cb-4ee0-8d0d-18cff943f158",
            "firstName": "Paul D.",
            "lastName": "Corderman",
            "fullName": "Paul Corderman",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2018RS/images/corderman01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-MD-2-upper-ocd-person-6605f7aa-80cb-4ee0-8d0d-18cff943f158",
              "builtID": "md-upper-2",
              "externalID": "ocd-person/6605f7aa-80cb-4ee0-8d0d-18cff943f158",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.corderman@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 3716, Hagerstown, MD 21742",
              "phone1": null,
              "phone2": "240-313-3929",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=corderman01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/corderman01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa17582.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/corderman02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-2-upper-ocd-person-6605f7aa-80cb-4ee0-8d0d-18cff943f158": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/eebac21f-2ba7-4368-a8e9-fcc20672ad1e",
            "firstName": "Karen Lewis",
            "lastName": "Young",
            "fullName": "Karen Young",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/young04.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-MD-3-upper-ocd-person-eebac21f-2ba7-4368-a8e9-fcc20672ad1e",
              "builtID": "md-upper-3",
              "externalID": "ocd-person/eebac21f-2ba7-4368-a8e9-fcc20672ad1e",
              "geometry": null
            },
            "contactInfo": {
              "email": "karen.young@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=young02&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/young02",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/young04"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-3-upper-ocd-person-eebac21f-2ba7-4368-a8e9-fcc20672ad1e": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/d8938e62-87cd-420e-a009-f78a418ed138",
            "firstName": "William G.",
            "lastName": "Folden",
            "fullName": "William Folden",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/folden01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-MD-4-upper-ocd-person-d8938e62-87cd-420e-a009-f78a418ed138",
              "builtID": "md-upper-4",
              "externalID": "ocd-person/d8938e62-87cd-420e-a009-f78a418ed138",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.folden@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=folden01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/folden02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-4-upper-ocd-person-d8938e62-87cd-420e-a009-f78a418ed138": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/eea44ceb-5bec-458e-b22e-16351c5f98f9",
            "firstName": "Justin D.",
            "lastName": "Ready",
            "fullName": "Justin Ready",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/ready01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-MD-5-upper-ocd-person-eea44ceb-5bec-458e-b22e-16351c5f98f9",
              "builtID": "md-upper-5",
              "externalID": "ocd-person/eea44ceb-5bec-458e-b22e-16351c5f98f9",
              "geometry": null
            },
            "contactInfo": {
              "email": "justin.ready@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=ready01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/ready01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-5-upper-ocd-person-eea44ceb-5bec-458e-b22e-16351c5f98f9": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/6a5dec66-3631-424f-aceb-6aa9f107cea1",
            "firstName": "Johnny Ray",
            "lastName": "Salling",
            "fullName": "Johnny Salling",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2020RS/images/salling01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-MD-6-upper-ocd-person-6a5dec66-3631-424f-aceb-6aa9f107cea1",
              "builtID": "md-upper-6",
              "externalID": "ocd-person/6a5dec66-3631-424f-aceb-6aa9f107cea1",
              "geometry": null
            },
            "contactInfo": {
              "email": "johnnyray.salling@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=salling01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa17039.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/salling01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-6-upper-ocd-person-6a5dec66-3631-424f-aceb-6aa9f107cea1": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/72201809-94c8-4c48-a0be-18555a2d55fa",
            "firstName": "Jonathan Bartlett",
            "lastName": "Jennings",
            "fullName": "J.B. Jennings",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2020RS/images/jennings.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-MD-7-upper-ocd-person-72201809-94c8-4c48-a0be-18555a2d55fa",
              "builtID": "md-upper-7",
              "externalID": "ocd-person/72201809-94c8-4c48-a0be-18555a2d55fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "jb.jennings@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "141 N. Main St. Suite K, Bel Air, MD 21014",
              "phone1": null,
              "phone2": "443-371-2772",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=jennings&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa13980.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/jennings"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-7-upper-ocd-person-72201809-94c8-4c48-a0be-18555a2d55fa": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/d9846189-aaed-4ca9-a59f-4a3146d72719",
            "firstName": "Katherine A.",
            "lastName": "Klausmeier",
            "fullName": "Kathy Klausmeier",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/klausmeier.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-MD-8-upper-ocd-person-d9846189-aaed-4ca9-a59f-4a3146d72719",
              "builtID": "md-upper-8",
              "externalID": "ocd-person/d9846189-aaed-4ca9-a59f-4a3146d72719",
              "geometry": null
            },
            "contactInfo": {
              "email": "katherine.klausmeier@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "4100 Walter Ave., Baltimore, MD 21236",
              "phone1": null,
              "phone2": "410-256-1353",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=klausmeier&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa12255.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/klausmeier"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-8-upper-ocd-person-d9846189-aaed-4ca9-a59f-4a3146d72719": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/232b5c5d-87e8-4ce9-921c-8c05af96afd4",
            "firstName": "Kathryn Fry",
            "lastName": "Hester",
            "fullName": "Katie Hester",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/hester01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-MD-9-upper-ocd-person-232b5c5d-87e8-4ce9-921c-8c05af96afd4",
              "builtID": "md-upper-9",
              "externalID": "ocd-person/232b5c5d-87e8-4ce9-921c-8c05af96afd4",
              "geometry": null
            },
            "contactInfo": {
              "email": "katiefry.hester@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=hester01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa18044.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/hester01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-9-upper-ocd-person-232b5c5d-87e8-4ce9-921c-8c05af96afd4": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/4e1fee1d-1f1c-416b-9615-4171d4074db8",
            "firstName": "Benjamin",
            "lastName": "Brooks",
            "fullName": "Ben Brooks",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/brooks02.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-MD-10-upper-ocd-person-4e1fee1d-1f1c-416b-9615-4171d4074db8",
              "builtID": "md-upper-10",
              "externalID": "ocd-person/4e1fee1d-1f1c-416b-9615-4171d4074db8",
              "geometry": null
            },
            "contactInfo": {
              "email": "benjamin.brooks@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=brooks01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/brooks01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/brooks02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-10-upper-ocd-person-4e1fee1d-1f1c-416b-9615-4171d4074db8": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/77cad368-7e81-4ff2-91d8-62ecacd38fe7",
            "firstName": "Michelle Laskin",
            "lastName": "Hettleman",
            "fullName": "Shelly Hettleman",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/hettleman01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-MD-11-upper-ocd-person-77cad368-7e81-4ff2-91d8-62ecacd38fe7",
              "builtID": "md-upper-11",
              "externalID": "ocd-person/77cad368-7e81-4ff2-91d8-62ecacd38fe7",
              "geometry": null
            },
            "contactInfo": {
              "email": "shelly.hettleman@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=hettleman01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/hettleman02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-11-upper-ocd-person-77cad368-7e81-4ff2-91d8-62ecacd38fe7": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/b002bdd1-0622-4462-8623-e8bb52fde504",
            "firstName": "Clarence",
            "lastName": "Lam",
            "fullName": "Clarence Lam",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/lam01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-MD-12-upper-ocd-person-b002bdd1-0622-4462-8623-e8bb52fde504",
              "builtID": "md-upper-12",
              "externalID": "ocd-person/b002bdd1-0622-4462-8623-e8bb52fde504",
              "geometry": null
            },
            "contactInfo": {
              "email": "clarence.lam@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=lam02&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa17058.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/lam02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-12-upper-ocd-person-b002bdd1-0622-4462-8623-e8bb52fde504": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/04eec23c-719e-46b1-ae56-f34de9207e8c",
            "firstName": "Guy Joseph",
            "lastName": "Guzzone",
            "fullName": "Guy Guzzone",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2020RS/images/guzzone.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-MD-13-upper-ocd-person-04eec23c-719e-46b1-ae56-f34de9207e8c",
              "builtID": "md-upper-13",
              "externalID": "ocd-person/04eec23c-719e-46b1-ae56-f34de9207e8c",
              "geometry": null
            },
            "contactInfo": {
              "email": "guy.guzzone@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=guzzone&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa14619.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/guzzone"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-13-upper-ocd-person-04eec23c-719e-46b1-ae56-f34de9207e8c": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/00b4a416-e9ca-44ad-b808-58c19d6fb54a",
            "firstName": "Craig J.",
            "lastName": "Zucker",
            "fullName": "Craig Zucker",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/zucker01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-MD-14-upper-ocd-person-00b4a416-e9ca-44ad-b808-58c19d6fb54a",
              "builtID": "md-upper-14",
              "externalID": "ocd-person/00b4a416-e9ca-44ad-b808-58c19d6fb54a",
              "geometry": null
            },
            "contactInfo": {
              "email": "craig.zucker@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=zucker01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa15448.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/zucker01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-14-upper-ocd-person-00b4a416-e9ca-44ad-b808-58c19d6fb54a": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/c3ce882c-89d3-453c-a70f-b0ef9046fcf3",
            "firstName": "Brian Jeffrey",
            "lastName": "Feldman",
            "fullName": "Brian Feldman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/feldman.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-MD-15-upper-ocd-person-c3ce882c-89d3-453c-a70f-b0ef9046fcf3",
              "builtID": "md-upper-15",
              "externalID": "ocd-person/c3ce882c-89d3-453c-a70f-b0ef9046fcf3",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.feldman@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=feldman&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa13991.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/feldman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-15-upper-ocd-person-c3ce882c-89d3-453c-a70f-b0ef9046fcf3": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/b9f21b96-4bb5-43e8-a5d8-19cec0332315",
            "firstName": "Sara N.",
            "lastName": "Love",
            "fullName": "Sara Love",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2024RS/images/love02.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-MD-16-upper-ocd-person-b9f21b96-4bb5-43e8-a5d8-19cec0332315",
              "builtID": "md-upper-16",
              "externalID": "ocd-person/b9f21b96-4bb5-43e8-a5d8-19cec0332315",
              "geometry": null
            },
            "contactInfo": {
              "email": "sara.love@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=love01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/love01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/love02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-16-upper-ocd-person-b9f21b96-4bb5-43e8-a5d8-19cec0332315": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/3b21ad36-c0bc-4341-9f55-c4529affc7f5",
            "firstName": "Cheryl C.",
            "lastName": "Kagan",
            "fullName": "Cheryl Kagan",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/kagan01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-MD-17-upper-ocd-person-3b21ad36-c0bc-4341-9f55-c4529affc7f5",
              "builtID": "md-upper-17",
              "externalID": "ocd-person/3b21ad36-c0bc-4341-9f55-c4529affc7f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "cheryl.kagan@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=kagan01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa12251.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/kagan01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-17-upper-ocd-person-3b21ad36-c0bc-4341-9f55-c4529affc7f5": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/43ee08fa-dce5-4526-8242-17e3e70baad7",
            "firstName": "Jeffrey D.",
            "lastName": "Waldstreicher",
            "fullName": "Jeff Waldstreicher",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/waldstreicher1.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-MD-18-upper-ocd-person-43ee08fa-dce5-4526-8242-17e3e70baad7",
              "builtID": "md-upper-18",
              "externalID": "ocd-person/43ee08fa-dce5-4526-8242-17e3e70baad7",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.waldstreicher@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=waldstreicher1&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa14637.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/waldstreicher1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-18-upper-ocd-person-43ee08fa-dce5-4526-8242-17e3e70baad7": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/f43bc1a5-01e8-416f-86ca-bd857bf6ed80",
            "firstName": "Benjamin F.",
            "lastName": "Kramer",
            "fullName": "Ben Kramer",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/kramer%20b.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-MD-19-upper-ocd-person-f43bc1a5-01e8-416f-86ca-bd857bf6ed80",
              "builtID": "md-upper-19",
              "externalID": "ocd-person/f43bc1a5-01e8-416f-86ca-bd857bf6ed80",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.kramer@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=kramer02&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa14624.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/kramer02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-19-upper-ocd-person-f43bc1a5-01e8-416f-86ca-bd857bf6ed80": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/6e1c09ac-57e7-4a54-bd83-74905670ad7f",
            "firstName": "William C.",
            "lastName": "Smith",
            "fullName": "Will Smith",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2017RS/images/smith02.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-MD-20-upper-ocd-person-6e1c09ac-57e7-4a54-bd83-74905670ad7f",
              "builtID": "md-upper-20",
              "externalID": "ocd-person/6e1c09ac-57e7-4a54-bd83-74905670ad7f",
              "geometry": null
            },
            "contactInfo": {
              "email": "will.smith@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=smith02&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/smith02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-20-upper-ocd-person-6e1c09ac-57e7-4a54-bd83-74905670ad7f": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/0867a6dd-db6b-4c2f-8748-f9b4782d9a19",
            "firstName": "James Carew",
            "lastName": "Rosapepe",
            "fullName": "Jim Rosapepe",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/rosapepe.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-MD-21-upper-ocd-person-0867a6dd-db6b-4c2f-8748-f9b4782d9a19",
              "builtID": "md-upper-21",
              "externalID": "ocd-person/0867a6dd-db6b-4c2f-8748-f9b4782d9a19",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.rosapepe@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=rosapepe&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/rosapepe"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-21-upper-ocd-person-0867a6dd-db6b-4c2f-8748-f9b4782d9a19": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/0ddc9257-e4a4-4414-b21d-3467c8285806",
            "firstName": "Alonzo Todd",
            "lastName": "Washington",
            "fullName": "Alonzo Washington",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/washington02.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-MD-22-upper-ocd-person-0ddc9257-e4a4-4414-b21d-3467c8285806",
              "builtID": "md-upper-22",
              "externalID": "ocd-person/0ddc9257-e4a4-4414-b21d-3467c8285806",
              "geometry": null
            },
            "contactInfo": {
              "email": "alonzo.washington@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=washington%20a&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/washington%20a",
                "https://msa.maryland.gov/msa/mdmanual/06hse/html/msa16423.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/washington02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-22-upper-ocd-person-0ddc9257-e4a4-4414-b21d-3467c8285806": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/177ee919-8310-4485-9b07-041cd83b2947",
            "firstName": "Ronald Lorenzo",
            "lastName": "Watson",
            "fullName": "Ron Watson",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/watson03.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-MD-23-upper-ocd-person-177ee919-8310-4485-9b07-041cd83b2947",
              "builtID": "md-upper-23",
              "externalID": "ocd-person/177ee919-8310-4485-9b07-041cd83b2947",
              "geometry": null
            },
            "contactInfo": {
              "email": "ron.watson@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=watson03&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/watson03",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/watson04",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa18025.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-23-upper-ocd-person-177ee919-8310-4485-9b07-041cd83b2947": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/a5a952f8-f001-4619-9056-103a1f4e4d8e",
            "firstName": "Joanne Claybon",
            "lastName": "Benson",
            "fullName": "Joanne Benson",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/benson.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-MD-24-upper-ocd-person-a5a952f8-f001-4619-9056-103a1f4e4d8e",
              "builtID": "md-upper-24",
              "externalID": "ocd-person/a5a952f8-f001-4619-9056-103a1f4e4d8e",
              "geometry": null
            },
            "contactInfo": {
              "email": "joanne.benson@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=benson&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa12185.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/benson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-24-upper-ocd-person-a5a952f8-f001-4619-9056-103a1f4e4d8e": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/23c9c495-1fe1-4258-bb15-7f1183ffc4fe",
            "firstName": "Nicholas Patrick",
            "lastName": "Charles",
            "fullName": "Nick Charles",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2024RS/images/charles02.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-MD-25-upper-ocd-person-23c9c495-1fe1-4258-bb15-7f1183ffc4fe",
              "builtID": "md-upper-25",
              "externalID": "ocd-person/23c9c495-1fe1-4258-bb15-7f1183ffc4fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "nick.charles@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=charles01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/charles02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-25-upper-ocd-person-23c9c495-1fe1-4258-bb15-7f1183ffc4fe": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/bbf4a659-f7a7-4db7-8cb1-4dc654db53e3",
            "firstName": "Charles Anthony",
            "lastName": "Muse",
            "fullName": "Anthony Muse",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/muse.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-MD-26-upper-ocd-person-bbf4a659-f7a7-4db7-8cb1-4dc654db53e3",
              "builtID": "md-upper-26",
              "externalID": "ocd-person/bbf4a659-f7a7-4db7-8cb1-4dc654db53e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "anthony.muse@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa12282.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/muse01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-26-upper-ocd-person-bbf4a659-f7a7-4db7-8cb1-4dc654db53e3": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/66fe4760-a59b-4503-98d4-875408b7352f",
            "firstName": "Michael A.",
            "lastName": "Jackson",
            "fullName": "Michael Jackson",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/jackson01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-MD-27-upper-ocd-person-66fe4760-a59b-4503-98d4-875408b7352f",
              "builtID": "md-upper-27",
              "externalID": "ocd-person/66fe4760-a59b-4503-98d4-875408b7352f",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.jackson@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=jackson01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/jackson01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/jackson03",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa14132.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-27-upper-ocd-person-66fe4760-a59b-4503-98d4-875408b7352f": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/ad59d051-2efb-495f-b032-524d21a95e50",
            "firstName": "Arthur Carr",
            "lastName": "Ellis",
            "fullName": "Arthur Ellis",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/ellis01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-MD-28-upper-ocd-person-ad59d051-2efb-495f-b032-524d21a95e50",
              "builtID": "md-upper-28",
              "externalID": "ocd-person/ad59d051-2efb-495f-b032-524d21a95e50",
              "geometry": null
            },
            "contactInfo": {
              "email": "arthur.ellis@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=ellis01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/ellis01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa18045.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-28-upper-ocd-person-ad59d051-2efb-495f-b032-524d21a95e50": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/d6ad9dff-6f4f-48c9-8806-d651a0d86f58",
            "firstName": "John Daniel",
            "lastName": "Bailey",
            "fullName": "Jack Bailey",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2019RS/images/bailey01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-MD-29-upper-ocd-person-d6ad9dff-6f4f-48c9-8806-d651a0d86f58",
              "builtID": "md-upper-29",
              "externalID": "ocd-person/d6ad9dff-6f4f-48c9-8806-d651a0d86f58",
              "geometry": null
            },
            "contactInfo": {
              "email": "jack.bailey@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "Dorsey Professional Park 23680 Three Notch Road, Unit 101, Hollywood, MD 20636",
              "phone1": null,
              "phone2": "240-309-4238",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=bailey01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa18046.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/bailey01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-29-upper-ocd-person-d6ad9dff-6f4f-48c9-8806-d651a0d86f58": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/a649f616-ab89-4e4d-bfbe-4335d1d67d1a",
            "firstName": "Sarah Kelly",
            "lastName": "Elfreth",
            "fullName": "Sarah Elfreth",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2024RS/images/elfreth01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-MD-30-upper-ocd-person-a649f616-ab89-4e4d-bfbe-4335d1d67d1a",
              "builtID": "md-upper-30",
              "externalID": "ocd-person/a649f616-ab89-4e4d-bfbe-4335d1d67d1a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sarah.elfreth@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=elfreth01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/elfreth01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-30-upper-ocd-person-a649f616-ab89-4e4d-bfbe-4335d1d67d1a": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/b0d22b6f-68c0-4dd0-892c-7844e6830693",
            "firstName": "Bryan W.",
            "lastName": "Simonaire",
            "fullName": "Bryan Simonaire",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/simonaire.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-MD-31-upper-ocd-person-b0d22b6f-68c0-4dd0-892c-7844e6830693",
              "builtID": "md-upper-31",
              "externalID": "ocd-person/b0d22b6f-68c0-4dd0-892c-7844e6830693",
              "geometry": null
            },
            "contactInfo": {
              "email": "bryan.simonaire@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=simonaire&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa14640.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/simonaire"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-31-upper-ocd-person-b0d22b6f-68c0-4dd0-892c-7844e6830693": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/15921e4d-a07c-4ad7-863c-1d8dfd2d6725",
            "firstName": "Pamela Graboski",
            "lastName": "Beidle",
            "fullName": "Pamela Beidle",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/beidle01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-MD-32-upper-ocd-person-15921e4d-a07c-4ad7-863c-1d8dfd2d6725",
              "builtID": "md-upper-32",
              "externalID": "ocd-person/15921e4d-a07c-4ad7-863c-1d8dfd2d6725",
              "geometry": null
            },
            "contactInfo": {
              "email": "pamela.beidle@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=beidle01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa14495.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/beidle01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-32-upper-ocd-person-15921e4d-a07c-4ad7-863c-1d8dfd2d6725": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/4f379062-a8ba-49af-850a-a1a358797d48",
            "firstName": "Dawn D.",
            "lastName": "Gile",
            "fullName": "Dawn Gile",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/gile01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-MD-33-upper-ocd-person-4f379062-a8ba-49af-850a-a1a358797d48",
              "builtID": "md-upper-33",
              "externalID": "ocd-person/4f379062-a8ba-49af-850a-a1a358797d48",
              "geometry": null
            },
            "contactInfo": {
              "email": "dawn.gile@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/gile01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa18497.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-33-upper-ocd-person-4f379062-a8ba-49af-850a-a1a358797d48": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/72a5c405-0523-4ea4-9029-024c49d76cb3",
            "firstName": "Mary-Dulany",
            "lastName": "James",
            "fullName": "Mary-Dulany James",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/james01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-MD-34-upper-ocd-person-72a5c405-0523-4ea4-9029-024c49d76cb3",
              "builtID": "md-upper-34",
              "externalID": "ocd-person/72a5c405-0523-4ea4-9029-024c49d76cb3",
              "geometry": null
            },
            "contactInfo": {
              "email": "marydulany.james@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/james01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa02778.html"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-34-upper-ocd-person-72a5c405-0523-4ea4-9029-024c49d76cb3": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/7c2a7a4b-f847-4efa-baea-2e5633acdcd5",
            "firstName": "Jason C.",
            "lastName": "Gallion",
            "fullName": "Jason Gallion",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2020RS/images/gallion01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-MD-35-upper-ocd-person-7c2a7a4b-f847-4efa-baea-2e5633acdcd5",
              "builtID": "md-upper-35",
              "externalID": "ocd-person/7c2a7a4b-f847-4efa-baea-2e5633acdcd5",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.gallion@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=gallion01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/gallion01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-35-upper-ocd-person-7c2a7a4b-f847-4efa-baea-2e5633acdcd5": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/2271e390-772d-440c-9601-f72ed997092b",
            "firstName": "Stephen S.",
            "lastName": "Hershey",
            "fullName": "Steve Hershey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://mgaleg.maryland.gov/2023RS/images/hershey.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-MD-36-upper-ocd-person-2271e390-772d-440c-9601-f72ed997092b",
              "builtID": "md-upper-36",
              "externalID": "ocd-person/2271e390-772d-440c-9601-f72ed997092b",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.hershey@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=hershey&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/hershey"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-36-upper-ocd-person-2271e390-772d-440c-9601-f72ed997092b": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/84e203b4-277c-4e10-aeb3-a80158bdf525",
            "firstName": "John Frederick",
            "lastName": "Mautz",
            "fullName": "Johnny Mautz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://mgaleg.maryland.gov/2023RS/images/mautz02.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-MD-37-upper-ocd-person-84e203b4-277c-4e10-aeb3-a80158bdf525",
              "builtID": "md-upper-37",
              "externalID": "ocd-person/84e203b4-277c-4e10-aeb3-a80158bdf525",
              "geometry": null
            },
            "contactInfo": {
              "email": "johnny.mautz@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "8695 Commerce Drive, Easton, MD 21601",
              "phone1": null,
              "phone2": "410-829-2524",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=mautz01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/mautz01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/mautz02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-37-upper-ocd-person-84e203b4-277c-4e10-aeb3-a80158bdf525": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/6e819331-2ab7-4a58-be19-1585dc2872ff",
            "firstName": "Mary Elizabeth Rubin",
            "lastName": "Carozza",
            "fullName": "Mary Beth Carozza",
            "gender": "Female",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2016RS/images/carozza01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-MD-38-upper-ocd-person-6e819331-2ab7-4a58-be19-1585dc2872ff",
              "builtID": "md-upper-38",
              "externalID": "ocd-person/6e819331-2ab7-4a58-be19-1585dc2872ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "marybeth.carozza@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=carozza02&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa14149.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/carozza02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-38-upper-ocd-person-6e819331-2ab7-4a58-be19-1585dc2872ff": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/92f41e40-4782-4c52-81a1-de8f78e05ab1",
            "firstName": "Nancy J.",
            "lastName": "King",
            "fullName": "Nancy King",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/king.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-MD-39-upper-ocd-person-92f41e40-4782-4c52-81a1-de8f78e05ab1",
              "builtID": "md-upper-39",
              "externalID": "ocd-person/92f41e40-4782-4c52-81a1-de8f78e05ab1",
              "geometry": null
            },
            "contactInfo": {
              "email": "nancy.king@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=king&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa14005.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/king"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-39-upper-ocd-person-92f41e40-4782-4c52-81a1-de8f78e05ab1": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/2b26dc52-cb0c-4750-af6b-9c00e12eef29",
            "firstName": "Antonio Lamar",
            "lastName": "Hayes",
            "fullName": "Antonio Hayes",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://mgaleg.maryland.gov/2023RS/images/hayes02.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-MD-40-upper-ocd-person-2b26dc52-cb0c-4750-af6b-9c00e12eef29",
              "builtID": "md-upper-40",
              "externalID": "ocd-person/2b26dc52-cb0c-4750-af6b-9c00e12eef29",
              "geometry": null
            },
            "contactInfo": {
              "email": "antonio.hayes@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=hayes02&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa17079.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/hayes02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-40-upper-ocd-person-2b26dc52-cb0c-4750-af6b-9c00e12eef29": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/d2a4d2c5-192b-4545-bd00-58024b65e7b4",
            "firstName": "Jill Priscilla",
            "lastName": "Carter",
            "fullName": "Jill Carter",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2018RS/images/carter01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-MD-41-upper-ocd-person-d2a4d2c5-192b-4545-bd00-58024b65e7b4",
              "builtID": "md-upper-41",
              "externalID": "ocd-person/d2a4d2c5-192b-4545-bd00-58024b65e7b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "jill.carter@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=carter01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/carter01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-41-upper-ocd-person-d2a4d2c5-192b-4545-bd00-58024b65e7b4": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/99ff7e2c-87d6-49d5-b112-3807852511bc",
            "firstName": "Christopher R.",
            "lastName": "West",
            "fullName": "Chris West",
            "gender": "Male",
            "party": "Republican",
            "image": "http://mgaleg.maryland.gov/2019RS/images/west02.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-MD-42-upper-ocd-person-99ff7e2c-87d6-49d5-b112-3807852511bc",
              "builtID": "md-upper-42",
              "externalID": "ocd-person/99ff7e2c-87d6-49d5-b112-3807852511bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.west@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": "1134 York Road Suite 200, Lutherville, MD 21093",
              "phone1": null,
              "phone2": "410-823-7087",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=west02&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa17080.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/west02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-42-upper-ocd-person-99ff7e2c-87d6-49d5-b112-3807852511bc": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/0bdef1bb-ea31-4a27-937c-eb680ae67343",
            "firstName": "Mary Lynn",
            "lastName": "Washington",
            "fullName": "Mary Washington",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/washington.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "43",
              "chamber": "upper",
              "hashID": "SLDU-MD-43-upper-ocd-person-0bdef1bb-ea31-4a27-937c-eb680ae67343",
              "builtID": "md-upper-43",
              "externalID": "ocd-person/0bdef1bb-ea31-4a27-937c-eb680ae67343",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.washington@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=washington01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa15349.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/washington01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-43-upper-ocd-person-0bdef1bb-ea31-4a27-937c-eb680ae67343": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/a111b899-ab75-4934-a6e9-a9c8be9bc70d",
            "firstName": "Charles E.",
            "lastName": "Sydnor",
            "fullName": "Charles Sydnor",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2021RS/images/sydnor02.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "44",
              "chamber": "upper",
              "hashID": "SLDU-MD-44-upper-ocd-person-a111b899-ab75-4934-a6e9-a9c8be9bc70d",
              "builtID": "md-upper-44",
              "externalID": "ocd-person/a111b899-ab75-4934-a6e9-a9c8be9bc70d",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.sydnor@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=sydnor01&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa17081.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/sydnor02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-44-upper-ocd-person-a111b899-ab75-4934-a6e9-a9c8be9bc70d": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/be00c352-4825-4b80-97cd-954097e3a482",
            "firstName": "Cory V.",
            "lastName": "McCray",
            "fullName": "Cory McCray",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2016RS/images/mccray01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "45",
              "chamber": "upper",
              "hashID": "SLDU-MD-45-upper-ocd-person-be00c352-4825-4b80-97cd-954097e3a482",
              "builtID": "md-upper-45",
              "externalID": "ocd-person/be00c352-4825-4b80-97cd-954097e3a482",
              "geometry": null
            },
            "contactInfo": {
              "email": "cory.mccray@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=mccray02&stab=01",
                "https://msa.maryland.gov/msa/mdmanual/05sen/html/msa17083.html",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/mccray02"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-45-upper-ocd-person-be00c352-4825-4b80-97cd-954097e3a482": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/86f0d40f-05f4-46a2-9c24-21ff52fdc6fe",
            "firstName": "William C.",
            "lastName": "Ferguson",
            "fullName": "Bill Ferguson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://msa.maryland.gov/msa/mdmanual/05sen/images/1198-1-4729b.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "46",
              "chamber": "upper",
              "hashID": "SLDU-MD-46-upper-ocd-person-86f0d40f-05f4-46a2-9c24-21ff52fdc6fe",
              "builtID": "md-upper-46",
              "externalID": "ocd-person/86f0d40f-05f4-46a2-9c24-21ff52fdc6fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.ferguson@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=ferguson&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/ferguson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-46-upper-ocd-person-86f0d40f-05f4-46a2-9c24-21ff52fdc6fe": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/6c4c1b4d-7c32-4d2b-83ab-d207f92e1818",
            "firstName": "Malcolm L.",
            "lastName": "Augustine",
            "fullName": "Malcolm Augustine",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://mgaleg.maryland.gov/2019RS/images/augustine01.jpg",
            "title": "MD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MD",
              "stateFull": "Maryland",
              "district": "47",
              "chamber": "upper",
              "hashID": "SLDU-MD-47-upper-ocd-person-6c4c1b4d-7c32-4d2b-83ab-d207f92e1818",
              "builtID": "md-upper-47",
              "externalID": "ocd-person/6c4c1b4d-7c32-4d2b-83ab-d207f92e1818",
              "geometry": null
            },
            "contactInfo": {
              "email": "malcolm.augustine@senate.state.md.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mgaleg.maryland.gov/webmga/frmMain.aspx?pid=sponpage&tab=subject6&id=augustine01&stab=01",
                "https://mgaleg.maryland.gov/mgawebsite/Members/Details/augustine01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MD-47-upper-ocd-person-6c4c1b4d-7c32-4d2b-83ab-d207f92e1818": 0
        }
      }
    }
  },
  "MA": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "MA",
          "stateFull": "Massachusetts",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "MA",
            "stateFull": "Massachusetts",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-MA---",
            "builtID": "ma--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-MA---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "M000133",
          "in_office": true,
          "firstName": "Edward",
          "lastName": "Markey",
          "middleName": "J.",
          "fullName": "Edward J. Markey",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/M000133.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "MA",
            "stateFull": "Massachusetts",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-MA---M000133",
            "builtID": "ma--",
            "externalID": "M000133",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.markey.senate.gov/contact",
            "address1": "255 Dirksen Senate Office Building",
            "address2": null,
            "phone1": "202-224-2742",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenMarkey",
            "youtube": "RepMarkey",
            "instagram": null,
            "facebook": "EdJMarkey",
            "urls": ["https://www.markey.senate.gov"],
            "rss_url": null
          },
          "title": "MA Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/M000133.json",
          "govtrack_id": "400253",
          "cspan_id": "260",
          "votesmart_id": "26900",
          "icpsr_id": "14435",
          "crp_id": "N00000270",
          "google_entity_id": "/m/028vr4",
          "state_rank": "junior",
          "lis_id": "S369",
          "suffix": null,
          "date_of_birth": "1946-07-11",
          "leadership_role": null,
          "fec_candidate_id": "H6MA07101",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "11",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 15,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 2.71,
          "votes_with_party_pct": 93.48,
          "votes_against_party_pct": 6.52,
          "ocd_id": "ocd-division/country:us/state:ma"
        },
        {
          "API_ID": "W000817",
          "in_office": true,
          "firstName": "Elizabeth",
          "lastName": "Warren",
          "middleName": null,
          "fullName": "Elizabeth Warren",
          "gender": "F",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/W000817.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "MA",
            "stateFull": "Massachusetts",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-MA---W000817",
            "builtID": "ma--",
            "externalID": "W000817",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.warren.senate.gov/?p=email_senator",
            "address1": "309 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-4543",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenWarren",
            "youtube": "senelizabethwarren",
            "instagram": null,
            "facebook": "senatorelizabethwarren",
            "urls": ["https://www.warren.senate.gov"],
            "rss_url": "https://www.warren.senate.gov/rss/"
          },
          "title": "MA Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/W000817.json",
          "govtrack_id": "412542",
          "cspan_id": "1023023",
          "votesmart_id": "141272",
          "icpsr_id": "41301",
          "crp_id": "N00033492",
          "google_entity_id": "/m/01qh39",
          "state_rank": "senior",
          "lis_id": "S366",
          "suffix": null,
          "date_of_birth": "1949-06-22",
          "leadership_role": null,
          "fec_candidate_id": "S2MA00170",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "11",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 5,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 0.9,
          "votes_with_party_pct": 94.88,
          "votes_against_party_pct": 5.12,
          "ocd_id": "ocd-division/country:us/state:ma"
        }
      ],
      "hash": { "SENATE-MA---M000133": 0, "SENATE-MA---W000817": 1 }
    },
    "HOUSE": {
      "04": {
        "members": [
          {
            "API_ID": "A000148",
            "in_office": true,
            "firstName": "Jake",
            "lastName": "Auchincloss",
            "middleName": null,
            "fullName": "Jake Auchincloss",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/A000148.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-MA-04--A000148",
              "builtID": "ma--04",
              "externalID": "A000148",
              "geometry": null,
              "geoid": "2504"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1524 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5931",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAuchincloss",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://auchincloss.house.gov"],
              "rss_url": null
            },
            "title": "MA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/A000148.json",
            "govtrack_id": "456825",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00045506",
            "google_entity_id": "/g/11f0xw2_p4",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1988-01-29",
            "leadership_role": null,
            "fec_candidate_id": "H0MA04192",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 27,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.56,
            "votes_with_party_pct": 95.29,
            "votes_against_party_pct": 2.75,
            "ocd_id": "ocd-division/country:us/state:ma/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MA-04--A000148": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "C001101",
            "in_office": true,
            "firstName": "Katherine",
            "lastName": "Clark",
            "middleName": null,
            "fullName": "Katherine M. Clark",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001101.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-MA-05--C001101",
              "builtID": "ma--05",
              "externalID": "C001101",
              "geometry": null,
              "geoid": "2505"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2368 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2836",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepKClark",
              "youtube": null,
              "instagram": null,
              "facebook": "CongresswomanClark",
              "urls": ["https://katherineclark.house.gov"],
              "rss_url": null
            },
            "title": "MA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001101.json",
            "govtrack_id": "412600",
            "cspan_id": "73178",
            "votesmart_id": "35858",
            "icpsr_id": "21375",
            "crp_id": "N00035278",
            "google_entity_id": "/m/0fpjc8b",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1963-07-17",
            "leadership_role": "Assistant Speaker of the House",
            "fec_candidate_id": "H4MA05084",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 0,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0,
            "votes_with_party_pct": 96.84,
            "votes_against_party_pct": 1.34,
            "ocd_id": "ocd-division/country:us/state:ma/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MA-05--C001101": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "K000375",
            "in_office": true,
            "firstName": "Bill",
            "lastName": "Keating",
            "middleName": null,
            "fullName": "William R. Keating",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/K000375.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-MA-09--K000375",
              "builtID": "ma--09",
              "externalID": "K000375",
              "geometry": null,
              "geoid": "2509"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2351 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3111",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "USRepKeating",
              "youtube": "RepBillKeating",
              "instagram": null,
              "facebook": "Congressman.Keating",
              "urls": ["https://keating.house.gov"],
              "rss_url": "https://keating.house.gov/rss.xml"
            },
            "title": "MA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000375.json",
            "govtrack_id": "412435",
            "cspan_id": "61856",
            "votesmart_id": "4743",
            "icpsr_id": "21140",
            "crp_id": "N00031933",
            "google_entity_id": "/m/09v6g7c",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1952-09-06",
            "leadership_role": null,
            "fec_candidate_id": "H0MA10082",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 22,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.09,
            "votes_with_party_pct": 96.19,
            "votes_against_party_pct": 1.95,
            "ocd_id": "ocd-division/country:us/state:ma/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MA-09--K000375": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "L000562",
            "in_office": true,
            "firstName": "Stephen",
            "lastName": "Lynch",
            "middleName": "F.",
            "fullName": "Stephen F. Lynch",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/L000562.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-MA-08--L000562",
              "builtID": "ma--08",
              "externalID": "L000562",
              "geometry": null,
              "geoid": "2508"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2109 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-8273",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepStephenLynch",
              "youtube": "RepLynch",
              "instagram": null,
              "facebook": "repstephenlynch",
              "urls": ["https://lynch.house.gov"],
              "rss_url": null
            },
            "title": "MA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000562.json",
            "govtrack_id": "400249",
            "cspan_id": "1000222",
            "votesmart_id": "4844",
            "icpsr_id": "20119",
            "crp_id": "N00013855",
            "google_entity_id": "/m/028vsz",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1955-03-31",
            "leadership_role": null,
            "fec_candidate_id": "H2MA09072",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "24",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 19,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.8,
            "votes_with_party_pct": 95.13,
            "votes_against_party_pct": 3.02,
            "ocd_id": "ocd-division/country:us/state:ma/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MA-08--L000562": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "M000312",
            "in_office": true,
            "firstName": "Jim",
            "lastName": "McGovern",
            "middleName": null,
            "fullName": "James P. McGovern",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M000312.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-MA-02--M000312",
              "builtID": "ma--02",
              "externalID": "M000312",
              "geometry": null,
              "geoid": "2502"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "370 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-6101",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMcGovern",
              "youtube": "repjimmcgovern",
              "instagram": null,
              "facebook": "RepJimMcGovern",
              "urls": ["https://mcgovern.house.gov"],
              "rss_url": null
            },
            "title": "MA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M000312.json",
            "govtrack_id": "400263",
            "cspan_id": "45976",
            "votesmart_id": "552",
            "icpsr_id": "29729",
            "crp_id": "N00000179",
            "google_entity_id": "/m/028vn7",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1959-11-20",
            "leadership_role": null,
            "fec_candidate_id": "H4MA03022",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "28",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 8,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.76,
            "votes_with_party_pct": 94.12,
            "votes_against_party_pct": 4.05,
            "ocd_id": "ocd-division/country:us/state:ma/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MA-02--M000312": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "M001196",
            "in_office": true,
            "firstName": "Seth",
            "lastName": "Moulton",
            "middleName": null,
            "fullName": "Seth Moulton",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M001196.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-MA-06--M001196",
              "builtID": "ma--06",
              "externalID": "M001196",
              "geometry": null,
              "geoid": "2506"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1126 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-8020",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "teammoulton",
              "youtube": null,
              "instagram": null,
              "facebook": "CongressmanSethMoulton",
              "urls": ["https://moulton.house.gov"],
              "rss_url": null
            },
            "title": "MA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001196.json",
            "govtrack_id": "412632",
            "cspan_id": "78453",
            "votesmart_id": "146299",
            "icpsr_id": "21525",
            "crp_id": "N00035431",
            "google_entity_id": "/m/0sgh587",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1978-10-24",
            "leadership_role": null,
            "fec_candidate_id": "H4MA06090",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 18,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.71,
            "votes_with_party_pct": 95.14,
            "votes_against_party_pct": 3.02,
            "ocd_id": "ocd-division/country:us/state:ma/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MA-06--M001196": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "N000015",
            "in_office": true,
            "firstName": "Richard",
            "lastName": "Neal",
            "middleName": "E.",
            "fullName": "Richard E. Neal",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/N000015.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-MA-01--N000015",
              "builtID": "ma--01",
              "externalID": "N000015",
              "geometry": null,
              "geoid": "2501"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "372 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5601",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRichardNeal",
              "youtube": "RepRichardENeal",
              "instagram": null,
              "facebook": "reprichardneal",
              "urls": ["https://neal.house.gov"],
              "rss_url": "https://neal.house.gov/rss.xml"
            },
            "title": "MA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/N000015.json",
            "govtrack_id": "400291",
            "cspan_id": "6103",
            "votesmart_id": "26895",
            "icpsr_id": "15616",
            "crp_id": "N00000153",
            "google_entity_id": "/m/028vm4",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1949-02-14",
            "leadership_role": null,
            "fec_candidate_id": "H8MA02041",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "36",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 23,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.18,
            "votes_with_party_pct": 96.09,
            "votes_against_party_pct": 2.05,
            "ocd_id": "ocd-division/country:us/state:ma/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MA-01--N000015": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "P000617",
            "in_office": true,
            "firstName": "Ayanna",
            "lastName": "Pressley",
            "middleName": null,
            "fullName": "Ayanna Pressley",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/P000617.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-MA-07--P000617",
              "builtID": "ma--07",
              "externalID": "P000617",
              "geometry": null,
              "geoid": "2507"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "402 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5111",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepPressley",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://pressley.house.gov"],
              "rss_url": "https://pressley.house.gov/category/press-releases/feed/"
            },
            "title": "MA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000617.json",
            "govtrack_id": "412782",
            "cspan_id": null,
            "votesmart_id": "122700",
            "icpsr_id": null,
            "crp_id": "N00042581",
            "google_entity_id": "/m/0bmh9vc",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1974-02-03",
            "leadership_role": null,
            "fec_candidate_id": "H8MA07032",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 53,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 5.02,
            "votes_with_party_pct": 90.03,
            "votes_against_party_pct": 7.96,
            "ocd_id": "ocd-division/country:us/state:ma/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MA-07--P000617": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "T000482",
            "in_office": true,
            "firstName": "Lori",
            "lastName": "Trahan",
            "middleName": null,
            "fullName": "Lori Trahan",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/T000482.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-MA-03--T000482",
              "builtID": "ma--03",
              "externalID": "T000482",
              "geometry": null,
              "geoid": "2503"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2439 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3411",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLoriTrahan",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://trahan.house.gov"],
              "rss_url": null
            },
            "title": "MA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000482.json",
            "govtrack_id": "412781",
            "cspan_id": null,
            "votesmart_id": "182310",
            "icpsr_id": null,
            "crp_id": "N00041808",
            "google_entity_id": "/g/11gtcg496z",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1973-10-27",
            "leadership_role": null,
            "fec_candidate_id": "H8MA03106",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 9,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 96.43,
            "votes_against_party_pct": 1.64,
            "ocd_id": "ocd-division/country:us/state:ma/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MA-03--T000482": 0 }
      }
    },
    "SLDL": {
      "7th Hampden": {
        "members": [
          {
            "API_ID": "ocd-person/c3d23eba-caa6-4d2a-bbd2-5b8b2ca184ae",
            "firstName": "Aaron L.",
            "lastName": "Saunders",
            "fullName": "Aaron Saunders",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/ALS1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "7th Hampden",
              "chamber": "lower",
              "hashID": "SLDL-MA-7th Hampden-lower-ocd-person-c3d23eba-caa6-4d2a-bbd2-5b8b2ca184ae",
              "builtID": "ma-lower-7th hampden",
              "externalID": "ocd-person/c3d23eba-caa6-4d2a-bbd2-5b8b2ca184ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "aaron.saunders@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/ALS1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-7th Hampden-lower-ocd-person-c3d23eba-caa6-4d2a-bbd2-5b8b2ca184ae": 0
        }
      },
      "3rd Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/4183f327-e2a6-4b1a-9d23-161da1b5e261",
            "firstName": "Aaron M.",
            "lastName": "Michlewitz",
            "fullName": "Aaron Michlewitz",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/AMM1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "3rd Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-3rd Suffolk-lower-ocd-person-4183f327-e2a6-4b1a-9d23-161da1b5e261",
              "builtID": "ma-lower-3rd suffolk",
              "externalID": "ocd-person/4183f327-e2a6-4b1a-9d23-161da1b5e261",
              "geometry": null
            },
            "contactInfo": {
              "email": "aaron.m.michlewitz@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/AMM1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-3rd Suffolk-lower-ocd-person-4183f327-e2a6-4b1a-9d23-161da1b5e261": 0
        }
      },
      "14th Bristol": {
        "members": [
          {
            "API_ID": "ocd-person/804c5518-7fdf-43c9-ba24-f2c24eb3b0ab",
            "firstName": "Adam J.",
            "lastName": "Scanlon",
            "fullName": "Adam Scanlon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/AJS1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "14th Bristol",
              "chamber": "lower",
              "hashID": "SLDL-MA-14th Bristol-lower-ocd-person-804c5518-7fdf-43c9-ba24-f2c24eb3b0ab",
              "builtID": "ma-lower-14th bristol",
              "externalID": "ocd-person/804c5518-7fdf-43c9-ba24-f2c24eb3b0ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "adam.scanlon@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/AJS1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-14th Bristol-lower-ocd-person-804c5518-7fdf-43c9-ba24-f2c24eb3b0ab": 0
        }
      },
      "1st Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/f8772f1e-29f7-42e2-8a8c-ab4005153b05",
            "firstName": "Adrian C.",
            "lastName": "Madaro",
            "fullName": "Adrian Madaro",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/ACM1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "1st Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-1st Suffolk-lower-ocd-person-f8772f1e-29f7-42e2-8a8c-ab4005153b05",
              "builtID": "ma-lower-1st suffolk",
              "externalID": "ocd-person/f8772f1e-29f7-42e2-8a8c-ab4005153b05",
              "geometry": null
            },
            "contactInfo": {
              "email": "adrian.madaro@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/ACM1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-1st Suffolk-lower-ocd-person-f8772f1e-29f7-42e2-8a8c-ab4005153b05": 0
        }
      },
      "14th Essex": {
        "members": [
          {
            "API_ID": "ocd-person/300d40b3-9479-43c0-99d3-18f94e1110cc",
            "firstName": "Adrianne Pusateri",
            "lastName": "Ramos",
            "fullName": "Adrianne Ramos",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/APR1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "14th Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-14th Essex-lower-ocd-person-300d40b3-9479-43c0-99d3-18f94e1110cc",
              "builtID": "ma-lower-14th essex",
              "externalID": "ocd-person/300d40b3-9479-43c0-99d3-18f94e1110cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "adrianne.ramos@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/APR1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-14th Essex-lower-ocd-person-300d40b3-9479-43c0-99d3-18f94e1110cc": 0
        }
      },
      "7th Bristol": {
        "members": [
          {
            "API_ID": "ocd-person/6e81dcb7-4a04-4a88-b5f2-728a3336cf9d",
            "firstName": "Alan",
            "lastName": "Silvia",
            "fullName": "Alan Silvia",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/A_S1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "7th Bristol",
              "chamber": "lower",
              "hashID": "SLDL-MA-7th Bristol-lower-ocd-person-6e81dcb7-4a04-4a88-b5f2-728a3336cf9d",
              "builtID": "ma-lower-7th bristol",
              "externalID": "ocd-person/6e81dcb7-4a04-4a88-b5f2-728a3336cf9d",
              "geometry": null
            },
            "contactInfo": {
              "email": "alan.silvia@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Fall River, MA",
              "phone1": null,
              "phone2": "774-526-1122",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/A_S1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-7th Bristol-lower-ocd-person-6e81dcb7-4a04-4a88-b5f2-728a3336cf9d": 0
        }
      },
      "14th Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/2a9569a0-feaf-431d-8a8d-012d8ebccbf8",
            "firstName": "Alice Hanlon",
            "lastName": "Peisch",
            "fullName": "Alice Peisch",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/AHP1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "14th Norfolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-14th Norfolk-lower-ocd-person-2a9569a0-feaf-431d-8a8d-012d8ebccbf8",
              "builtID": "ma-lower-14th norfolk",
              "externalID": "ocd-person/2a9569a0-feaf-431d-8a8d-012d8ebccbf8",
              "geometry": null
            },
            "contactInfo": {
              "email": "alice.peisch@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/AHP1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-14th Norfolk-lower-ocd-person-2a9569a0-feaf-431d-8a8d-012d8ebccbf8": 0
        }
      },
      "7th Plymouth": {
        "members": [
          {
            "API_ID": "ocd-person/bed31f9a-e759-41a5-80b6-8b6bb9a64762",
            "firstName": "Alyson M.",
            "lastName": "Sullivan-Almeida",
            "fullName": "Alyson Sullivan-Almeida",
            "gender": "Female",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/AMS2.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "7th Plymouth",
              "chamber": "lower",
              "hashID": "SLDL-MA-7th Plymouth-lower-ocd-person-bed31f9a-e759-41a5-80b6-8b6bb9a64762",
              "builtID": "ma-lower-7th plymouth",
              "externalID": "ocd-person/bed31f9a-e759-41a5-80b6-8b6bb9a64762",
              "geometry": null
            },
            "contactInfo": {
              "email": "alyson.sullivan@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/AMS2"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-7th Plymouth-lower-ocd-person-bed31f9a-e759-41a5-80b6-8b6bb9a64762": 0
        }
      },
      "3rd Essex": {
        "members": [
          {
            "API_ID": "ocd-person/c1f81bec-d032-4f41-940c-ca32f2b5af12",
            "firstName": "Andres X.",
            "lastName": "Vargas",
            "fullName": "Andy Vargas",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/AXV1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "3rd Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-3rd Essex-lower-ocd-person-c1f81bec-d032-4f41-940c-ca32f2b5af12",
              "builtID": "ma-lower-3rd essex",
              "externalID": "ocd-person/c1f81bec-d032-4f41-940c-ca32f2b5af12",
              "geometry": null
            },
            "contactInfo": {
              "email": "andy.vargas@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/AXV1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-3rd Essex-lower-ocd-person-c1f81bec-d032-4f41-940c-ca32f2b5af12": 0
        }
      },
      "12th Hampden": {
        "members": [
          {
            "API_ID": "ocd-person/8302648f-4ed2-454e-ad1e-d7389e25b8dc",
            "firstName": "Angelo J.",
            "lastName": "Puppolo",
            "fullName": "Angelo Puppolo",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/AJP1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "12th Hampden",
              "chamber": "lower",
              "hashID": "SLDL-MA-12th Hampden-lower-ocd-person-8302648f-4ed2-454e-ad1e-d7389e25b8dc",
              "builtID": "ma-lower-12th hampden",
              "externalID": "ocd-person/8302648f-4ed2-454e-ad1e-d7389e25b8dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "angelo.puppolo@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Wilbraham, MA",
              "phone1": null,
              "phone2": "413-596-4333",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/AJP1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-12th Hampden-lower-ocd-person-8302648f-4ed2-454e-ad1e-d7389e25b8dc": 0
        }
      },
      "8th Plymouth": {
        "members": [
          {
            "API_ID": "ocd-person/650d9459-5ecd-4e1a-8a96-f061a15e0ebc",
            "firstName": "Angelo",
            "lastName": "D'Emilia",
            "fullName": "Angelo D'Emilia",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Angelo-DEmilia.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "8th Plymouth",
              "chamber": "lower",
              "hashID": "SLDL-MA-8th Plymouth-lower-ocd-person-650d9459-5ecd-4e1a-8a96-f061a15e0ebc",
              "builtID": "ma-lower-8th plymouth",
              "externalID": "ocd-person/650d9459-5ecd-4e1a-8a96-f061a15e0ebc",
              "geometry": null
            },
            "contactInfo": {
              "email": "angelo.d'emilia@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Bridgewater, MA",
              "phone1": null,
              "phone2": "508-697-2700",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/ALD1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-8th Plymouth-lower-ocd-person-650d9459-5ecd-4e1a-8a96-f061a15e0ebc": 0
        }
      },
      "5th Essex": {
        "members": [
          {
            "API_ID": "ocd-person/3b6297d5-2c8d-42a8-ba9b-bb4e8f50c0e2",
            "firstName": "Ann-Margaret",
            "lastName": "Ferrante",
            "fullName": "Ann-Margaret Ferrante",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Ann-Margaret-Ferrante.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "5th Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-5th Essex-lower-ocd-person-3b6297d5-2c8d-42a8-ba9b-bb4e8f50c0e2",
              "builtID": "ma-lower-5th essex",
              "externalID": "ocd-person/3b6297d5-2c8d-42a8-ba9b-bb4e8f50c0e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "ann-margaret.ferrante@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/AMF1",
                "https://annmargaretferrante.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-5th Essex-lower-ocd-person-3b6297d5-2c8d-42a8-ba9b-bb4e8f50c0e2": 0
        }
      },
      "13th Bristol": {
        "members": [
          {
            "API_ID": "ocd-person/59aed143-c5c1-4760-ac55-eab18600c451",
            "firstName": "Antonio F.D.",
            "lastName": "Cabral",
            "fullName": "Tony Cabral",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/AFC1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "13th Bristol",
              "chamber": "lower",
              "hashID": "SLDL-MA-13th Bristol-lower-ocd-person-59aed143-c5c1-4760-ac55-eab18600c451",
              "builtID": "ma-lower-13th bristol",
              "externalID": "ocd-person/59aed143-c5c1-4760-ac55-eab18600c451",
              "geometry": null
            },
            "contactInfo": {
              "email": "antonio.cabral@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/AFC1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-13th Bristol-lower-ocd-person-59aed143-c5c1-4760-ac55-eab18600c451": 0
        }
      },
      "20th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/368c3f95-1124-4f0a-9ab5-7361cb2805d3",
            "firstName": "Bradley H.",
            "lastName": "Jones",
            "fullName": "Brad Jones",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/BHJ1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "20th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-20th Middlesex-lower-ocd-person-368c3f95-1124-4f0a-9ab5-7361cb2805d3",
              "builtID": "ma-lower-20th middlesex",
              "externalID": "ocd-person/368c3f95-1124-4f0a-9ab5-7361cb2805d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "bradley.jones@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "North Reading, MA",
              "phone1": null,
              "phone2": "978-664-5936",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/BHJ1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-20th Middlesex-lower-ocd-person-368c3f95-1124-4f0a-9ab5-7361cb2805d3": 0
        }
      },
      "12th Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/99c9c9e7-f0e3-480b-ad38-de297e6c635a",
            "firstName": "Brandy",
            "lastName": "Fluker Oakley",
            "fullName": "Brandy Fluker Oakley",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/BFO1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "12th Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-12th Suffolk-lower-ocd-person-99c9c9e7-f0e3-480b-ad38-de297e6c635a",
              "builtID": "ma-lower-12th suffolk",
              "externalID": "ocd-person/99c9c9e7-f0e3-480b-ad38-de297e6c635a",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandy.flukeroakley@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/BFO1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-12th Suffolk-lower-ocd-person-99c9c9e7-f0e3-480b-ad38-de297e6c635a": 0
        }
      },
      "2nd Hampden": {
        "members": [
          {
            "API_ID": "ocd-person/503a0888-f086-4b52-b03c-c5293378bc51",
            "firstName": "Brian M.",
            "lastName": "Ashe",
            "fullName": "Brian Ashe",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/BMA1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "2nd Hampden",
              "chamber": "lower",
              "hashID": "SLDL-MA-2nd Hampden-lower-ocd-person-503a0888-f086-4b52-b03c-c5293378bc51",
              "builtID": "ma-lower-2nd hampden",
              "externalID": "ocd-person/503a0888-f086-4b52-b03c-c5293378bc51",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.ashe@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Longmeadow, MA",
              "phone1": null,
              "phone2": "413-754-4184",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/BMA1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-2nd Hampden-lower-ocd-person-503a0888-f086-4b52-b03c-c5293378bc51": 0
        }
      },
      "10th Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/66e7c819-cd52-4034-bace-bfec476c106d",
            "firstName": "Brian W.",
            "lastName": "Murray",
            "fullName": "Brian Murray",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/BWM1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "10th Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-10th Worcester-lower-ocd-person-66e7c819-cd52-4034-bace-bfec476c106d",
              "builtID": "ma-lower-10th worcester",
              "externalID": "ocd-person/66e7c819-cd52-4034-bace-bfec476c106d",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.murray@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "MA",
              "phone1": null,
              "phone2": "508-473-1740",
              "fax1": null,
              "fax2": "508-634-3347",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/BWM1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-10th Worcester-lower-ocd-person-66e7c819-cd52-4034-bace-bfec476c106d": 0
        }
      },
      "1st Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/f81b07bd-cc43-47be-95ea-dca26f19a647",
            "firstName": "Bruce J.",
            "lastName": "Ayers",
            "fullName": "Bruce Ayers",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/BJA1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "1st Norfolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-1st Norfolk-lower-ocd-person-f81b07bd-cc43-47be-95ea-dca26f19a647",
              "builtID": "ma-lower-1st norfolk",
              "externalID": "ocd-person/f81b07bd-cc43-47be-95ea-dca26f19a647",
              "geometry": null
            },
            "contactInfo": {
              "email": "bruce.ayers@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/BJA1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-1st Norfolk-lower-ocd-person-f81b07bd-cc43-47be-95ea-dca26f19a647": 0
        }
      },
      "11th Hampden": {
        "members": [
          {
            "API_ID": "ocd-person/b23dd64e-a05c-4fe1-8562-c29436ca79bb",
            "firstName": "Bud L.",
            "lastName": "Williams",
            "fullName": "Bud Williams",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/BLW1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "11th Hampden",
              "chamber": "lower",
              "hashID": "SLDL-MA-11th Hampden-lower-ocd-person-b23dd64e-a05c-4fe1-8562-c29436ca79bb",
              "builtID": "ma-lower-11th hampden",
              "externalID": "ocd-person/b23dd64e-a05c-4fe1-8562-c29436ca79bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "bud.williams@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "815 State St., Springfield, MA 01109",
              "phone1": null,
              "phone2": "413-316-4743",
              "fax1": null,
              "fax2": "413-739-8572",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/BLW1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-11th Hampden-lower-ocd-person-b23dd64e-a05c-4fe1-8562-c29436ca79bb": 0
        }
      },
      "10th Hampden": {
        "members": [
          {
            "API_ID": "ocd-person/c4d03c0e-713d-4195-ba23-1394f225494b",
            "firstName": "Carlos",
            "lastName": "Gonzalez",
            "fullName": "Carlos González",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/C_G1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "10th Hampden",
              "chamber": "lower",
              "hashID": "SLDL-MA-10th Hampden-lower-ocd-person-c4d03c0e-713d-4195-ba23-1394f225494b",
              "builtID": "ma-lower-10th hampden",
              "externalID": "ocd-person/c4d03c0e-713d-4195-ba23-1394f225494b",
              "geometry": null
            },
            "contactInfo": {
              "email": "carlos.gonzalez@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/C_G1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-10th Hampden-lower-ocd-person-c4d03c0e-713d-4195-ba23-1394f225494b": 0
        }
      },
      "13th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/b9a91cec-bad7-43a7-8fd2-60cd704bd7ab",
            "firstName": "Carmine Lawrence",
            "lastName": "Gentile",
            "fullName": "Carmine Gentile",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/CLG1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "13th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-13th Middlesex-lower-ocd-person-b9a91cec-bad7-43a7-8fd2-60cd704bd7ab",
              "builtID": "ma-lower-13th middlesex",
              "externalID": "ocd-person/b9a91cec-bad7-43a7-8fd2-60cd704bd7ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "carmine.gentile@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/CLG1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-13th Middlesex-lower-ocd-person-b9a91cec-bad7-43a7-8fd2-60cd704bd7ab": 0
        }
      },
      "3rd Bristol": {
        "members": [
          {
            "API_ID": "ocd-person/55824639-e627-48ad-9a3d-01b53caee00c",
            "firstName": "Carol A.",
            "lastName": "Doherty",
            "fullName": "Carol Doherty",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/CAD1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "3rd Bristol",
              "chamber": "lower",
              "hashID": "SLDL-MA-3rd Bristol-lower-ocd-person-55824639-e627-48ad-9a3d-01b53caee00c",
              "builtID": "ma-lower-3rd bristol",
              "externalID": "ocd-person/55824639-e627-48ad-9a3d-01b53caee00c",
              "geometry": null
            },
            "contactInfo": {
              "email": "carol.doherty@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/CAD1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-3rd Bristol-lower-ocd-person-55824639-e627-48ad-9a3d-01b53caee00c": 0
        }
      },
      "6th Bristol": {
        "members": [
          {
            "API_ID": "ocd-person/af41924a-7c46-4b6b-b71b-bba683c62c5f",
            "firstName": "Carole A.",
            "lastName": "Fiola",
            "fullName": "Carole Fiola",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/CAF1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "6th Bristol",
              "chamber": "lower",
              "hashID": "SLDL-MA-6th Bristol-lower-ocd-person-af41924a-7c46-4b6b-b71b-bba683c62c5f",
              "builtID": "ma-lower-6th bristol",
              "externalID": "ocd-person/af41924a-7c46-4b6b-b71b-bba683c62c5f",
              "geometry": null
            },
            "contactInfo": {
              "email": "carole.fiola@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Fall River, MA",
              "phone1": null,
              "phone2": "774-322-1313",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/CAF1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-6th Bristol-lower-ocd-person-af41924a-7c46-4b6b-b71b-bba683c62c5f": 0
        }
      },
      "34th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/35396516-6096-4336-ab74-80d6d8e53bcd",
            "firstName": "Christine P.",
            "lastName": "Barber",
            "fullName": "Christine Barber",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/CPB2.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "34th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-34th Middlesex-lower-ocd-person-35396516-6096-4336-ab74-80d6d8e53bcd",
              "builtID": "ma-lower-34th middlesex",
              "externalID": "ocd-person/35396516-6096-4336-ab74-80d6d8e53bcd",
              "geometry": null
            },
            "contactInfo": {
              "email": "christine.barber@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/CPB2"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-34th Middlesex-lower-ocd-person-35396516-6096-4336-ab74-80d6d8e53bcd": 0
        }
      },
      "11th Bristol": {
        "members": [
          {
            "API_ID": "ocd-person/9750bdb4-4dce-4003-85ec-672185a3a5a4",
            "firstName": "Christopher M.",
            "lastName": "Hendricks",
            "fullName": "Chris Hendricks",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/C_H1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "11th Bristol",
              "chamber": "lower",
              "hashID": "SLDL-MA-11th Bristol-lower-ocd-person-9750bdb4-4dce-4003-85ec-672185a3a5a4",
              "builtID": "ma-lower-11th bristol",
              "externalID": "ocd-person/9750bdb4-4dce-4003-85ec-672185a3a5a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.hendricks@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/C_H1",
                "https://www.rephendricks.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-11th Bristol-lower-ocd-person-9750bdb4-4dce-4003-85ec-672185a3a5a4": 0
        }
      },
      "5th Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/59539448-5c3b-4d4d-8e96-48fabb01b10f",
            "firstName": "Christopher J.",
            "lastName": "Worrell",
            "fullName": "Chris Worrell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/CJW1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "5th Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-5th Suffolk-lower-ocd-person-59539448-5c3b-4d4d-8e96-48fabb01b10f",
              "builtID": "ma-lower-5th suffolk",
              "externalID": "ocd-person/59539448-5c3b-4d4d-8e96-48fabb01b10f",
              "geometry": null
            },
            "contactInfo": {
              "email": "christopher.worrell@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/CJW1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-5th Suffolk-lower-ocd-person-59539448-5c3b-4d4d-8e96-48fabb01b10f": 0
        }
      },
      "9th Bristol": {
        "members": [
          {
            "API_ID": "ocd-person/560c5ca3-d9b4-4c47-998c-a9baa16a8309",
            "firstName": "Christopher M.",
            "lastName": "Markey",
            "fullName": "Christopher Markey",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/CMM1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "9th Bristol",
              "chamber": "lower",
              "hashID": "SLDL-MA-9th Bristol-lower-ocd-person-560c5ca3-d9b4-4c47-998c-a9baa16a8309",
              "builtID": "ma-lower-9th bristol",
              "externalID": "ocd-person/560c5ca3-d9b4-4c47-998c-a9baa16a8309",
              "geometry": null
            },
            "contactInfo": {
              "email": "christopher.markey@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/CMM1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-9th Bristol-lower-ocd-person-560c5ca3-d9b4-4c47-998c-a9baa16a8309": 0
        }
      },
      "1st Barnstable": {
        "members": [
          {
            "API_ID": "ocd-person/c30f76b4-73d6-4795-be75-c46973d17f59",
            "firstName": "Christopher Richard",
            "lastName": "Flanagan",
            "fullName": "Chris Flanagan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/CRF1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "1st Barnstable",
              "chamber": "lower",
              "hashID": "SLDL-MA-1st Barnstable-lower-ocd-person-c30f76b4-73d6-4795-be75-c46973d17f59",
              "builtID": "ma-lower-1st barnstable",
              "externalID": "ocd-person/c30f76b4-73d6-4795-be75-c46973d17f59",
              "geometry": null
            },
            "contactInfo": {
              "email": "christopher.flanagan@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/CRF1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-1st Barnstable-lower-ocd-person-c30f76b4-73d6-4795-be75-c46973d17f59": 0
        }
      },
      "7th Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/3b92fb1f-d268-40d8-8a4e-851f11fe81ff",
            "firstName": "Chynah",
            "lastName": "Tyler",
            "fullName": "Chynah Tyler",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/C_T1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "7th Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-7th Suffolk-lower-ocd-person-3b92fb1f-d268-40d8-8a4e-851f11fe81ff",
              "builtID": "ma-lower-7th suffolk",
              "externalID": "ocd-person/3b92fb1f-d268-40d8-8a4e-851f11fe81ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "chynah.tyler@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/C_T1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-7th Suffolk-lower-ocd-person-3b92fb1f-d268-40d8-8a4e-851f11fe81ff": 0
        }
      },
      "36th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/0e38852a-52b0-4aac-8a65-fa3fc964c88e",
            "firstName": "Colleen M.",
            "lastName": "Garry",
            "fullName": "Colleen Garry",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/CMG1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "36th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-36th Middlesex-lower-ocd-person-0e38852a-52b0-4aac-8a65-fa3fc964c88e",
              "builtID": "ma-lower-36th middlesex",
              "externalID": "ocd-person/0e38852a-52b0-4aac-8a65-fa3fc964c88e",
              "geometry": null
            },
            "contactInfo": {
              "email": "colleen.garry@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/CMG1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-36th Middlesex-lower-ocd-person-0e38852a-52b0-4aac-8a65-fa3fc964c88e": 0
        }
      },
      "10th Essex": {
        "members": [
          {
            "API_ID": "ocd-person/5f227f77-f58b-4ac6-b193-9f406bde4055",
            "firstName": "Daniel F.",
            "lastName": "Cahill",
            "fullName": "Dan Cahill",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/DFC1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "10th Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-10th Essex-lower-ocd-person-5f227f77-f58b-4ac6-b193-9f406bde4055",
              "builtID": "ma-lower-10th essex",
              "externalID": "ocd-person/5f227f77-f58b-4ac6-b193-9f406bde4055",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.cahill@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/DFC1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-10th Essex-lower-ocd-person-5f227f77-f58b-4ac6-b193-9f406bde4055": 0
        }
      },
      "13th Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/2990f236-cb76-4d93-af19-7792fa77290b",
            "firstName": "Daniel J.",
            "lastName": "Hunt",
            "fullName": "Dan Hunt",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/djh1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "13th Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-13th Suffolk-lower-ocd-person-2990f236-cb76-4d93-af19-7792fa77290b",
              "builtID": "ma-lower-13th suffolk",
              "externalID": "ocd-person/2990f236-cb76-4d93-af19-7792fa77290b",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.hunt@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/djh1",
                "https://malegislature.gov/Legislators/Profile/DJH1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-13th Suffolk-lower-ocd-person-2990f236-cb76-4d93-af19-7792fa77290b": 0
        }
      },
      "2nd Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/c0519476-08fc-44af-9114-c77d197f883d",
            "firstName": "Daniel Joseph",
            "lastName": "Ryan",
            "fullName": "Danny Ryan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/djr1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "2nd Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-2nd Suffolk-lower-ocd-person-c0519476-08fc-44af-9114-c77d197f883d",
              "builtID": "ma-lower-2nd suffolk",
              "externalID": "ocd-person/c0519476-08fc-44af-9114-c77d197f883d",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.ryan@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/djr1",
                "https://malegislature.gov/Legislators/Profile/DJR1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-2nd Suffolk-lower-ocd-person-c0519476-08fc-44af-9114-c77d197f883d": 0
        }
      },
      "16th Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/d70e97f1-b8ec-489d-ab5a-2b7f3775ae8a",
            "firstName": "Daniel M.",
            "lastName": "Donahue",
            "fullName": "Dan Donahue",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/DMD1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "16th Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-16th Worcester-lower-ocd-person-d70e97f1-b8ec-489d-ab5a-2b7f3775ae8a",
              "builtID": "ma-lower-16th worcester",
              "externalID": "ocd-person/d70e97f1-b8ec-489d-ab5a-2b7f3775ae8a",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.donahue@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/DMD1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-16th Worcester-lower-ocd-person-d70e97f1-b8ec-489d-ab5a-2b7f3775ae8a": 0
        }
      },
      "2nd Hampshire": {
        "members": [
          {
            "API_ID": "ocd-person/62ebe10b-ef71-4579-a55a-3c2045916d70",
            "firstName": "Daniel R.",
            "lastName": "Carey",
            "fullName": "Dan Carey",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/DRC1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "2nd Hampshire",
              "chamber": "lower",
              "hashID": "SLDL-MA-2nd Hampshire-lower-ocd-person-62ebe10b-ef71-4579-a55a-3c2045916d70",
              "builtID": "ma-lower-2nd hampshire",
              "externalID": "ocd-person/62ebe10b-ef71-4579-a55a-3c2045916d70",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.carey@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1309, Easthampton, MA 01027",
              "phone1": null,
              "phone2": "413-529-4286",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/DRC1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-2nd Hampshire-lower-ocd-person-62ebe10b-ef71-4579-a55a-3c2045916d70": 0
        }
      },
      "4th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/5696763c-5fd6-44dd-9dce-7be56632cedd",
            "firstName": "Danielle W.",
            "lastName": "Gregoire",
            "fullName": "Danielle Gregoire",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/DWG1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "4th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-4th Middlesex-lower-ocd-person-5696763c-5fd6-44dd-9dce-7be56632cedd",
              "builtID": "ma-lower-4th middlesex",
              "externalID": "ocd-person/5696763c-5fd6-44dd-9dce-7be56632cedd",
              "geometry": null
            },
            "contactInfo": {
              "email": "danielle.gregoire@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/DWG1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-4th Middlesex-lower-ocd-person-5696763c-5fd6-44dd-9dce-7be56632cedd": 0
        }
      },
      "37th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/8f480d9c-0741-43bb-83a4-179745e1f3b2",
            "firstName": "Danillo A.",
            "lastName": "Sena",
            "fullName": "Dan Sena",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://malegislature.gov/Legislators/Profile/170/DAS1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "37th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-37th Middlesex-lower-ocd-person-8f480d9c-0741-43bb-83a4-179745e1f3b2",
              "builtID": "ma-lower-37th middlesex",
              "externalID": "ocd-person/8f480d9c-0741-43bb-83a4-179745e1f3b2",
              "geometry": null
            },
            "contactInfo": {
              "email": "danillo.sena@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/DAS1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-37th Middlesex-lower-ocd-person-8f480d9c-0741-43bb-83a4-179745e1f3b2": 0
        }
      },
      "19th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/f3dd7fd8-5d7f-4fa3-b2fe-910e375f2edd",
            "firstName": "David Allen",
            "lastName": "Robertson",
            "fullName": "Dave Robertson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/D_R1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "19th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-19th Middlesex-lower-ocd-person-f3dd7fd8-5d7f-4fa3-b2fe-910e375f2edd",
              "builtID": "ma-lower-19th middlesex",
              "externalID": "ocd-person/f3dd7fd8-5d7f-4fa3-b2fe-910e375f2edd",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.robertson@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/D_R1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-19th Middlesex-lower-ocd-person-f3dd7fd8-5d7f-4fa3-b2fe-910e375f2edd": 0
        }
      },
      "4th Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/5fde161f-7456-4006-bffd-eaf6fedc5aa3",
            "firstName": "David",
            "lastName": "Biele",
            "fullName": "David Biele",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/D_B1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "4th Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-4th Suffolk-lower-ocd-person-5fde161f-7456-4006-bffd-eaf6fedc5aa3",
              "builtID": "ma-lower-4th suffolk",
              "externalID": "ocd-person/5fde161f-7456-4006-bffd-eaf6fedc5aa3",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.biele@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/D_B1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-4th Suffolk-lower-ocd-person-5fde161f-7456-4006-bffd-eaf6fedc5aa3": 0
        }
      },
      "5th Plymouth": {
        "members": [
          {
            "API_ID": "ocd-person/cf295962-4f8d-427a-84e9-c7615ff94c8c",
            "firstName": "David F.",
            "lastName": "DeCoste",
            "fullName": "David DeCoste",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/DFD1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "5th Plymouth",
              "chamber": "lower",
              "hashID": "SLDL-MA-5th Plymouth-lower-ocd-person-cf295962-4f8d-427a-84e9-c7615ff94c8c",
              "builtID": "ma-lower-5th plymouth",
              "externalID": "ocd-person/cf295962-4f8d-427a-84e9-c7615ff94c8c",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.decoste@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/DFD1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-5th Plymouth-lower-ocd-person-cf295962-4f8d-427a-84e9-c7615ff94c8c": 0
        }
      },
      "17th Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/ca659c30-e49e-420b-8213-797cfeb3c378",
            "firstName": "David Henry Argosky",
            "lastName": "LeBoeuf",
            "fullName": "David LeBoeuf",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/DAL1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "17th Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-17th Worcester-lower-ocd-person-ca659c30-e49e-420b-8213-797cfeb3c378",
              "builtID": "ma-lower-17th worcester",
              "externalID": "ocd-person/ca659c30-e49e-420b-8213-797cfeb3c378",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.leboeuf@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/DAL1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-17th Worcester-lower-ocd-person-ca659c30-e49e-420b-8213-797cfeb3c378": 0
        }
      },
      "9th Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/b601dee7-1ad1-4f36-b585-b0fb5077fd89",
            "firstName": "David K.",
            "lastName": "Muradian",
            "fullName": "Dave Muradian",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/DKM1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "9th Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-9th Worcester-lower-ocd-person-b601dee7-1ad1-4f36-b585-b0fb5077fd89",
              "builtID": "ma-lower-9th worcester",
              "externalID": "ocd-person/b601dee7-1ad1-4f36-b585-b0fb5077fd89",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.muradian@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/DKM1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-9th Worcester-lower-ocd-person-b601dee7-1ad1-4f36-b585-b0fb5077fd89": 0
        }
      },
      "24th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/51adc4d9-23d8-442f-b692-c4e4204f552e",
            "firstName": "David M.",
            "lastName": "Rogers",
            "fullName": "Dave Rogers",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/DMR1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "24th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-24th Middlesex-lower-ocd-person-51adc4d9-23d8-442f-b692-c4e4204f552e",
              "builtID": "ma-lower-24th middlesex",
              "externalID": "ocd-person/51adc4d9-23d8-442f-b692-c4e4204f552e",
              "geometry": null
            },
            "contactInfo": {
              "email": "dave.rogers@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/DMR1",
                "https://www.repdaverogers.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-24th Middlesex-lower-ocd-person-51adc4d9-23d8-442f-b692-c4e4204f552e": 0
        }
      },
      "5th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/06867e5f-9de5-4cb2-a3ae-a039e890718c",
            "firstName": "David Paul",
            "lastName": "Linsky",
            "fullName": "David Linsky",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/DPL1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "5th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-5th Middlesex-lower-ocd-person-06867e5f-9de5-4cb2-a3ae-a039e890718c",
              "builtID": "ma-lower-5th middlesex",
              "externalID": "ocd-person/06867e5f-9de5-4cb2-a3ae-a039e890718c",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.linsky@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/DPL1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-5th Middlesex-lower-ocd-person-06867e5f-9de5-4cb2-a3ae-a039e890718c": 0
        }
      },
      "3rd Barnstable": {
        "members": [
          {
            "API_ID": "ocd-person/5c798735-2f95-40a6-9ef7-4cd25eb638f6",
            "firstName": "David T.",
            "lastName": "Vieira",
            "fullName": "Dave Vieira",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/DTV1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "3rd Barnstable",
              "chamber": "lower",
              "hashID": "SLDL-MA-3rd Barnstable-lower-ocd-person-5c798735-2f95-40a6-9ef7-4cd25eb638f6",
              "builtID": "ma-lower-3rd barnstable",
              "externalID": "ocd-person/5c798735-2f95-40a6-9ef7-4cd25eb638f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.vieira@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "346 Gifford St. Unit 3, Falmouth, MA 02540",
              "phone1": null,
              "phone2": "508-548-8683",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/DTV1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-3rd Barnstable-lower-ocd-person-5c798735-2f95-40a6-9ef7-4cd25eb638f6": 0
        }
      },
      "1st Essex": {
        "members": [
          {
            "API_ID": "ocd-person/6c49bb2c-7c53-4f22-bfb9-04af813b95b0",
            "firstName": "Dawne",
            "lastName": "Shand",
            "fullName": "Dawne Shand",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/D_S1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "1st Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-1st Essex-lower-ocd-person-6c49bb2c-7c53-4f22-bfb9-04af813b95b0",
              "builtID": "ma-lower-1st essex",
              "externalID": "ocd-person/6c49bb2c-7c53-4f22-bfb9-04af813b95b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "dawne.shand@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/D_S1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-1st Essex-lower-ocd-person-6c49bb2c-7c53-4f22-bfb9-04af813b95b0": 0
        }
      },
      "13th Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/7be05b7f-d3dd-4a0f-b7d0-f29144d47f92",
            "firstName": "Denise C.",
            "lastName": "Garlick",
            "fullName": "Denise Garlick",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/DCG1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "13th Norfolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-13th Norfolk-lower-ocd-person-7be05b7f-d3dd-4a0f-b7d0-f29144d47f92",
              "builtID": "ma-lower-13th norfolk",
              "externalID": "ocd-person/7be05b7f-d3dd-4a0f-b7d0-f29144d47f92",
              "geometry": null
            },
            "contactInfo": {
              "email": "denise.garlick@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/DCG1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-13th Norfolk-lower-ocd-person-7be05b7f-d3dd-4a0f-b7d0-f29144d47f92": 0
        }
      },
      "9th Essex": {
        "members": [
          {
            "API_ID": "ocd-person/e8071049-2a8d-4633-b6cb-c1f6ae83bb7a",
            "firstName": "Donald H.",
            "lastName": "Wong",
            "fullName": "Donald Wong",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/DHW1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "9th Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-9th Essex-lower-ocd-person-e8071049-2a8d-4633-b6cb-c1f6ae83bb7a",
              "builtID": "ma-lower-9th essex",
              "externalID": "ocd-person/e8071049-2a8d-4633-b6cb-c1f6ae83bb7a",
              "geometry": null
            },
            "contactInfo": {
              "email": "donald.wong@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/DHW1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-9th Essex-lower-ocd-person-e8071049-2a8d-4633-b6cb-c1f6ae83bb7a": 0
        }
      },
      "5th Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/5a276629-f50a-4d9a-a54e-2c0e07f84968",
            "firstName": "Donald R.",
            "lastName": "Berthiaume",
            "fullName": "Donnie Berthiaume",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/DRB1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "5th Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-5th Worcester-lower-ocd-person-5a276629-f50a-4d9a-a54e-2c0e07f84968",
              "builtID": "ma-lower-5th worcester",
              "externalID": "ocd-person/5a276629-f50a-4d9a-a54e-2c0e07f84968",
              "geometry": null
            },
            "contactInfo": {
              "email": "donald.berthiaume@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/DRB1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-5th Worcester-lower-ocd-person-5a276629-f50a-4d9a-a54e-2c0e07f84968": 0
        }
      },
      "Barnstable, Dukes and Nantucket": {
        "members": [
          {
            "API_ID": "ocd-person/cd05a88c-19f0-4fc5-bc3b-d339572842b7",
            "firstName": "Dylan Andreo",
            "lastName": "Fernandes",
            "fullName": "Dylan Fernandes",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/DAF1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Barnstable, Dukes and Nantucket",
              "chamber": "lower",
              "hashID": "SLDL-MA-Barnstable, Dukes and Nantucket-lower-ocd-person-cd05a88c-19f0-4fc5-bc3b-d339572842b7",
              "builtID": "ma-lower-barnstable, dukes and nantucket",
              "externalID": "ocd-person/cd05a88c-19f0-4fc5-bc3b-d339572842b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "dylan.fernandes@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/DAF1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-Barnstable, Dukes and Nantucket-lower-ocd-person-cd05a88c-19f0-4fc5-bc3b-d339572842b7": 0
        }
      },
      "8th Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/73394d70-485b-4fbf-a512-bf2da4e4cb3b",
            "firstName": "Edward R.",
            "lastName": "Philips",
            "fullName": "Ted Philips",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/ERP1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "8th Norfolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-8th Norfolk-lower-ocd-person-73394d70-485b-4fbf-a512-bf2da4e4cb3b",
              "builtID": "ma-lower-8th norfolk",
              "externalID": "ocd-person/73394d70-485b-4fbf-a512-bf2da4e4cb3b",
              "geometry": null
            },
            "contactInfo": {
              "email": "edward.philips@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/ERP1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-8th Norfolk-lower-ocd-person-73394d70-485b-4fbf-a512-bf2da4e4cb3b": 0
        }
      },
      "27th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/a3f4c05a-b51a-4b8f-bc5d-d4cb82d74164",
            "firstName": "Erika",
            "lastName": "Uyterhoeven",
            "fullName": "Erika Uyterhoeven",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/E_U1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "27th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-27th Middlesex-lower-ocd-person-a3f4c05a-b51a-4b8f-bc5d-d4cb82d74164",
              "builtID": "ma-lower-27th middlesex",
              "externalID": "ocd-person/a3f4c05a-b51a-4b8f-bc5d-d4cb82d74164",
              "geometry": null
            },
            "contactInfo": {
              "email": "erika.uyterhoeven@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/E_U1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-27th Middlesex-lower-ocd-person-a3f4c05a-b51a-4b8f-bc5d-d4cb82d74164": 0
        }
      },
      "4th Essex": {
        "members": [
          {
            "API_ID": "ocd-person/824532f7-8dc9-4a1a-a7b3-16b2d0e78941",
            "firstName": "Estela A.",
            "lastName": "Reyes",
            "fullName": "Estela Reyes",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/EAR1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "4th Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-4th Essex-lower-ocd-person-824532f7-8dc9-4a1a-a7b3-16b2d0e78941",
              "builtID": "ma-lower-4th essex",
              "externalID": "ocd-person/824532f7-8dc9-4a1a-a7b3-16b2d0e78941",
              "geometry": null
            },
            "contactInfo": {
              "email": "estela.reyes@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/EAR1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-4th Essex-lower-ocd-person-824532f7-8dc9-4a1a-a7b3-16b2d0e78941": 0
        }
      },
      "1st Bristol": {
        "members": [
          {
            "API_ID": "ocd-person/ee42e89c-24dd-47b1-aafa-e16472680e07",
            "firstName": "Fred Jay",
            "lastName": "Barrows",
            "fullName": "Jay Barrows",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/FJB1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "1st Bristol",
              "chamber": "lower",
              "hashID": "SLDL-MA-1st Bristol-lower-ocd-person-ee42e89c-24dd-47b1-aafa-e16472680e07",
              "builtID": "ma-lower-1st bristol",
              "externalID": "ocd-person/ee42e89c-24dd-47b1-aafa-e16472680e07",
              "geometry": null
            },
            "contactInfo": {
              "email": "fred.barrows@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/FJB1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-1st Bristol-lower-ocd-person-ee42e89c-24dd-47b1-aafa-e16472680e07": 0
        }
      },
      "16th Essex": {
        "members": [
          {
            "API_ID": "ocd-person/99109961-27e8-4e58-8269-3a1deb29f228",
            "firstName": "Francisco E.",
            "lastName": "Paulino",
            "fullName": "Francisco Paulino",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/FEP1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "16th Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-16th Essex-lower-ocd-person-99109961-27e8-4e58-8269-3a1deb29f228",
              "builtID": "ma-lower-16th essex",
              "externalID": "ocd-person/99109961-27e8-4e58-8269-3a1deb29f228",
              "geometry": null
            },
            "contactInfo": {
              "email": "francisco.paulino@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/FEP1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-16th Essex-lower-ocd-person-99109961-27e8-4e58-8269-3a1deb29f228": 0
        }
      },
      "17th Essex": {
        "members": [
          {
            "API_ID": "ocd-person/8c42603e-4483-44f1-995f-5956fa09c73c",
            "firstName": "Frank A.",
            "lastName": "Moran",
            "fullName": "Frank Moran",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/FAM1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "17th Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-17th Essex-lower-ocd-person-8c42603e-4483-44f1-995f-5956fa09c73c",
              "builtID": "ma-lower-17th essex",
              "externalID": "ocd-person/8c42603e-4483-44f1-995f-5956fa09c73c",
              "geometry": null
            },
            "contactInfo": {
              "email": "frank.moran@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/FAM1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-17th Essex-lower-ocd-person-8c42603e-4483-44f1-995f-5956fa09c73c": 0
        }
      },
      "9th Plymouth": {
        "members": [
          {
            "API_ID": "ocd-person/3a1863b5-ca54-44d8-9afb-55f48623fd47",
            "firstName": "Gerard J.",
            "lastName": "Cassidy",
            "fullName": "Gerry Cassidy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/G_C2.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "9th Plymouth",
              "chamber": "lower",
              "hashID": "SLDL-MA-9th Plymouth-lower-ocd-person-3a1863b5-ca54-44d8-9afb-55f48623fd47",
              "builtID": "ma-lower-9th plymouth",
              "externalID": "ocd-person/3a1863b5-ca54-44d8-9afb-55f48623fd47",
              "geometry": null
            },
            "contactInfo": {
              "email": "gerard.cassidy@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/G_C2"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-9th Plymouth-lower-ocd-person-3a1863b5-ca54-44d8-9afb-55f48623fd47": 0
        }
      },
      "11th Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/3dd1e2a8-3fdd-4d3a-9504-88a04f3fc973",
            "firstName": "Hannah",
            "lastName": "Kane",
            "fullName": "Hannah Kane",
            "gender": "Female",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/HEK1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "11th Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-11th Worcester-lower-ocd-person-3dd1e2a8-3fdd-4d3a-9504-88a04f3fc973",
              "builtID": "ma-lower-11th worcester",
              "externalID": "ocd-person/3dd1e2a8-3fdd-4d3a-9504-88a04f3fc973",
              "geometry": null
            },
            "contactInfo": {
              "email": "hannah.kane@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/HEK1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-11th Worcester-lower-ocd-person-3dd1e2a8-3fdd-4d3a-9504-88a04f3fc973": 0
        }
      },
      "7th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/e3dc06b1-8523-4a33-959f-21ed0d5da851",
            "firstName": "Jack Patrick",
            "lastName": "Lewis",
            "fullName": "Jack Lewis",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JPL1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "7th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-7th Middlesex-lower-ocd-person-e3dc06b1-8523-4a33-959f-21ed0d5da851",
              "builtID": "ma-lower-7th middlesex",
              "externalID": "ocd-person/e3dc06b1-8523-4a33-959f-21ed0d5da851",
              "geometry": null
            },
            "contactInfo": {
              "email": "jack.lewis@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JPL1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-7th Middlesex-lower-ocd-person-e3dc06b1-8523-4a33-959f-21ed0d5da851": 0
        }
      },
      "2nd Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/70e42c78-89e1-43cb-bc65-7d0da132daa2",
            "firstName": "James",
            "lastName": "Arciero",
            "fullName": "Jim Arciero",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/J_A1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "2nd Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-2nd Middlesex-lower-ocd-person-70e42c78-89e1-43cb-bc65-7d0da132daa2",
              "builtID": "ma-lower-2nd middlesex",
              "externalID": "ocd-person/70e42c78-89e1-43cb-bc65-7d0da132daa2",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.arciero@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/J_A1",
                "https://repjamesarciero.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-2nd Middlesex-lower-ocd-person-70e42c78-89e1-43cb-bc65-7d0da132daa2": 0
        }
      },
      "8th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/47ffc61b-0cae-4386-ae68-12b20eadd83f",
            "firstName": "James C.",
            "lastName": "Arena-DeRosa",
            "fullName": "James Arena-DeRosa",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JCD1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "8th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-8th Middlesex-lower-ocd-person-47ffc61b-0cae-4386-ae68-12b20eadd83f",
              "builtID": "ma-lower-8th middlesex",
              "externalID": "ocd-person/47ffc61b-0cae-4386-ae68-12b20eadd83f",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.arena-derosa@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JCD1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-8th Middlesex-lower-ocd-person-47ffc61b-0cae-4386-ae68-12b20eadd83f": 0
        }
      },
      "14th Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/67f64532-7f9a-4cd3-8c89-dc9b5df06517",
            "firstName": "James J.",
            "lastName": "O'Day",
            "fullName": "Jim O'Day",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JJO1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "14th Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-14th Worcester-lower-ocd-person-67f64532-7f9a-4cd3-8c89-dc9b5df06517",
              "builtID": "ma-lower-14th worcester",
              "externalID": "ocd-person/67f64532-7f9a-4cd3-8c89-dc9b5df06517",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.o'day@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JJO1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-14th Worcester-lower-ocd-person-67f64532-7f9a-4cd3-8c89-dc9b5df06517": 0
        }
      },
      "2nd Bristol": {
        "members": [
          {
            "API_ID": "ocd-person/c8936e7c-f0f5-4dc4-b9e9-774e38638787",
            "firstName": "James K.",
            "lastName": "Hawkins",
            "fullName": "Jim Hawkins",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JKH1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "2nd Bristol",
              "chamber": "lower",
              "hashID": "SLDL-MA-2nd Bristol-lower-ocd-person-c8936e7c-f0f5-4dc4-b9e9-774e38638787",
              "builtID": "ma-lower-2nd bristol",
              "externalID": "ocd-person/c8936e7c-f0f5-4dc4-b9e9-774e38638787",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.hawkins@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JKH1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-2nd Bristol-lower-ocd-person-c8936e7c-f0f5-4dc4-b9e9-774e38638787": 0
        }
      },
      "4th Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/ef175304-020e-4f79-b71f-dc961b738254",
            "firstName": "James M.",
            "lastName": "Murphy",
            "fullName": "Jamie Murphy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JMM1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "4th Norfolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-4th Norfolk-lower-ocd-person-ef175304-020e-4f79-b71f-dc961b738254",
              "builtID": "ma-lower-4th norfolk",
              "externalID": "ocd-person/ef175304-020e-4f79-b71f-dc961b738254",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.murphy@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JMM1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-4th Norfolk-lower-ocd-person-ef175304-020e-4f79-b71f-dc961b738254": 0
        }
      },
      "8th Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/033bf9eb-2706-4c5f-9f93-8e8d222bf2c0",
            "firstName": "Jay D.",
            "lastName": "Livingstone",
            "fullName": "Jay Livingstone",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/J_L1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "8th Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-8th Suffolk-lower-ocd-person-033bf9eb-2706-4c5f-9f93-8e8d222bf2c0",
              "builtID": "ma-lower-8th suffolk",
              "externalID": "ocd-person/033bf9eb-2706-4c5f-9f93-8e8d222bf2c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jay.livingstone@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/J_L1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-8th Suffolk-lower-ocd-person-033bf9eb-2706-4c5f-9f93-8e8d222bf2c0": 0
        }
      },
      "10th Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/b23d4ed5-1994-4355-a102-cdf15ae57230",
            "firstName": "Jeffrey N.",
            "lastName": "Roy",
            "fullName": "Jeff Roy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JNR1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "10th Norfolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-10th Norfolk-lower-ocd-person-b23d4ed5-1994-4355-a102-cdf15ae57230",
              "builtID": "ma-lower-10th norfolk",
              "externalID": "ocd-person/b23d4ed5-1994-4355-a102-cdf15ae57230",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeffrey.roy@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "124 Grove St. Suite 220, Franklin, MA 02038",
              "phone1": null,
              "phone2": "508-520-3100",
              "fax1": null,
              "fax2": "508-618-7126",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JNR1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-10th Norfolk-lower-ocd-person-b23d4ed5-1994-4355-a102-cdf15ae57230": 0
        }
      },
      "19th Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/cd89185f-f928-4b26-bb47-60f1bccad0dd",
            "firstName": "Jeffrey Rosario",
            "lastName": "Turco",
            "fullName": "Jeff Turco",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JRT1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "19th Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-19th Suffolk-lower-ocd-person-cd89185f-f928-4b26-bb47-60f1bccad0dd",
              "builtID": "ma-lower-19th suffolk",
              "externalID": "ocd-person/cd89185f-f928-4b26-bb47-60f1bccad0dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeffrey.turco@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JRT1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-19th Suffolk-lower-ocd-person-cd89185f-f928-4b26-bb47-60f1bccad0dd": 0
        }
      },
      "8th Essex": {
        "members": [
          {
            "API_ID": "ocd-person/eab9b69f-0434-4307-972c-6acce9deb370",
            "firstName": "Jennifer Balinsky",
            "lastName": "Armini",
            "fullName": "Jenny Armini",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JBA1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "8th Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-8th Essex-lower-ocd-person-eab9b69f-0434-4307-972c-6acce9deb370",
              "builtID": "ma-lower-8th essex",
              "externalID": "ocd-person/eab9b69f-0434-4307-972c-6acce9deb370",
              "geometry": null
            },
            "contactInfo": {
              "email": "jennifer.armini@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JBA1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-8th Essex-lower-ocd-person-eab9b69f-0434-4307-972c-6acce9deb370": 0
        }
      },
      "6th Essex": {
        "members": [
          {
            "API_ID": "ocd-person/67833a9e-e086-4494-a364-fecdcd6aa609",
            "firstName": "Jerald A.",
            "lastName": "Parisella",
            "fullName": "Jerry Parisella",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JAP1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "6th Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-6th Essex-lower-ocd-person-67833a9e-e086-4494-a364-fecdcd6aa609",
              "builtID": "ma-lower-6th essex",
              "externalID": "ocd-person/67833a9e-e086-4494-a364-fecdcd6aa609",
              "geometry": null
            },
            "contactInfo": {
              "email": "jerald.parisella@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JAP1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-6th Essex-lower-ocd-person-67833a9e-e086-4494-a364-fecdcd6aa609": 0
        }
      },
      "16th Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/0ed98993-456c-4630-bd52-894bdde0c0b0",
            "firstName": "Jessica Ann",
            "lastName": "Giannino",
            "fullName": "Jessica Giannino",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JAG1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "16th Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-16th Suffolk-lower-ocd-person-0ed98993-456c-4630-bd52-894bdde0c0b0",
              "builtID": "ma-lower-16th suffolk",
              "externalID": "ocd-person/0ed98993-456c-4630-bd52-894bdde0c0b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jessica.giannino@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JAG1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-16th Suffolk-lower-ocd-person-0ed98993-456c-4630-bd52-894bdde0c0b0": 0
        }
      },
      "3rd Plymouth": {
        "members": [
          {
            "API_ID": "ocd-person/03d1f31d-1431-41d2-88b7-67b57d50f323",
            "firstName": "Joan",
            "lastName": "Meschino",
            "fullName": "Joan Meschino",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/J_M1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "3rd Plymouth",
              "chamber": "lower",
              "hashID": "SLDL-MA-3rd Plymouth-lower-ocd-person-03d1f31d-1431-41d2-88b7-67b57d50f323",
              "builtID": "ma-lower-3rd plymouth",
              "externalID": "ocd-person/03d1f31d-1431-41d2-88b7-67b57d50f323",
              "geometry": null
            },
            "contactInfo": {
              "email": "joan.meschino@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/J_M1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-3rd Plymouth-lower-ocd-person-03d1f31d-1431-41d2-88b7-67b57d50f323": 0
        }
      },
      "1st Berkshire": {
        "members": [
          {
            "API_ID": "ocd-person/da8051b7-de05-4c30-9d37-5d7223906356",
            "firstName": "John",
            "lastName": "Barrett",
            "fullName": "John Barrett",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/J_B1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "1st Berkshire",
              "chamber": "lower",
              "hashID": "SLDL-MA-1st Berkshire-lower-ocd-person-da8051b7-de05-4c30-9d37-5d7223906356",
              "builtID": "ma-lower-1st berkshire",
              "externalID": "ocd-person/da8051b7-de05-4c30-9d37-5d7223906356",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.barrett@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Adams, MA",
              "phone1": null,
              "phone2": "413-743-8300",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/J_B1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-1st Berkshire-lower-ocd-person-da8051b7-de05-4c30-9d37-5d7223906356": 0
        }
      },
      "9th Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/15ff9d00-e05e-470a-b89c-36038b1dd5bb",
            "firstName": "John Francis",
            "lastName": "Moran",
            "fullName": "John Moran",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JFM1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "9th Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-9th Suffolk-lower-ocd-person-15ff9d00-e05e-470a-b89c-36038b1dd5bb",
              "builtID": "ma-lower-9th suffolk",
              "externalID": "ocd-person/15ff9d00-e05e-470a-b89c-36038b1dd5bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.moran@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JFM1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-9th Suffolk-lower-ocd-person-15ff9d00-e05e-470a-b89c-36038b1dd5bb": 0
        }
      },
      "12th Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/50942563-77bb-49ec-a54c-6e26048a31a3",
            "firstName": "John H.",
            "lastName": "Rogers",
            "fullName": "John Rogers",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JHR1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "12th Norfolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-12th Norfolk-lower-ocd-person-50942563-77bb-49ec-a54c-6e26048a31a3",
              "builtID": "ma-lower-12th norfolk",
              "externalID": "ocd-person/50942563-77bb-49ec-a54c-6e26048a31a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.rogers@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JHR1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-12th Norfolk-lower-ocd-person-50942563-77bb-49ec-a54c-6e26048a31a3": 0
        }
      },
      "10th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/1ea6937d-a39e-41e2-92fb-47c2966cd52e",
            "firstName": "John J.",
            "lastName": "Lawn",
            "fullName": "John Lawn",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JJL2.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "10th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-10th Middlesex-lower-ocd-person-1ea6937d-a39e-41e2-92fb-47c2966cd52e",
              "builtID": "ma-lower-10th middlesex",
              "externalID": "ocd-person/1ea6937d-a39e-41e2-92fb-47c2966cd52e",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.lawn@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JJL2"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-10th Middlesex-lower-ocd-person-1ea6937d-a39e-41e2-92fb-47c2966cd52e": 0
        }
      },
      "13th Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/2abbc0cb-6339-42d9-9947-69412a24301b",
            "firstName": "John J.",
            "lastName": "Mahoney",
            "fullName": "John Mahoney",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JJM2.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "13th Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-13th Worcester-lower-ocd-person-2abbc0cb-6339-42d9-9947-69412a24301b",
              "builtID": "ma-lower-13th worcester",
              "externalID": "ocd-person/2abbc0cb-6339-42d9-9947-69412a24301b",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.mahoney@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JJM2"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-13th Worcester-lower-ocd-person-2abbc0cb-6339-42d9-9947-69412a24301b": 0
        }
      },
      "6th Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/0e8c6c71-bca0-46fd-86d1-223d41ed5517",
            "firstName": "John J.",
            "lastName": "Marsi",
            "fullName": "John Marsi",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/JJM1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "6th Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-6th Worcester-lower-ocd-person-0e8c6c71-bca0-46fd-86d1-223d41ed5517",
              "builtID": "ma-lower-6th worcester",
              "externalID": "ocd-person/0e8c6c71-bca0-46fd-86d1-223d41ed5517",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.marsi@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JJM1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-6th Worcester-lower-ocd-person-0e8c6c71-bca0-46fd-86d1-223d41ed5517": 0
        }
      },
      "2nd Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/49c4bb4e-fadd-4136-953f-6bd64a970acb",
            "firstName": "Jonathan D.",
            "lastName": "Zlotnik",
            "fullName": "Jon Zlotnik",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JDZ1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "2nd Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-2nd Worcester-lower-ocd-person-49c4bb4e-fadd-4136-953f-6bd64a970acb",
              "builtID": "ma-lower-2nd worcester",
              "externalID": "ocd-person/49c4bb4e-fadd-4136-953f-6bd64a970acb",
              "geometry": null
            },
            "contactInfo": {
              "email": "jon.zlotnik@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "MA",
              "phone1": null,
              "phone2": "978-410-9559",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JDZ1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-2nd Worcester-lower-ocd-person-49c4bb4e-fadd-4136-953f-6bd64a970acb": 0
        }
      },
      "18th Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/0e6c20bd-bfcb-445c-a1ce-3be99e26398e",
            "firstName": "Joseph D.",
            "lastName": "McKenna",
            "fullName": "Joe McKenna",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/JDM1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "18th Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-18th Worcester-lower-ocd-person-0e6c20bd-bfcb-445c-a1ce-3be99e26398e",
              "builtID": "ma-lower-18th worcester",
              "externalID": "ocd-person/0e6c20bd-bfcb-445c-a1ce-3be99e26398e",
              "geometry": null
            },
            "contactInfo": {
              "email": "joseph.mckenna@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JDM1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-18th Worcester-lower-ocd-person-0e6c20bd-bfcb-445c-a1ce-3be99e26398e": 0
        }
      },
      "28th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/6c3b63a2-ef13-43e5-b6de-d5e0901aa65a",
            "firstName": "Joseph William",
            "lastName": "McGonagle",
            "fullName": "Joe McGonagle",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/jwm1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "28th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-28th Middlesex-lower-ocd-person-6c3b63a2-ef13-43e5-b6de-d5e0901aa65a",
              "builtID": "ma-lower-28th middlesex",
              "externalID": "ocd-person/6c3b63a2-ef13-43e5-b6de-d5e0901aa65a",
              "geometry": null
            },
            "contactInfo": {
              "email": "joseph.mcgonagle@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/jwm1",
                "https://malegislature.gov/Legislators/Profile/JWM1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-28th Middlesex-lower-ocd-person-6c3b63a2-ef13-43e5-b6de-d5e0901aa65a": 0
        }
      },
      "11th Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/6f15f647-07f5-4739-a340-497798a474e7",
            "firstName": "Judith A.",
            "lastName": "Garcia",
            "fullName": "Judith García",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JAG2.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "11th Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-11th Suffolk-lower-ocd-person-6f15f647-07f5-4739-a340-497798a474e7",
              "builtID": "ma-lower-11th suffolk",
              "externalID": "ocd-person/6f15f647-07f5-4739-a340-497798a474e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "judith.garcia@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JAG2"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-11th Suffolk-lower-ocd-person-6f15f647-07f5-4739-a340-497798a474e7": 0
        }
      },
      "19th Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/e2aa2c0f-d6e6-49d7-8483-d595a3a1eb3a",
            "firstName": "Kate",
            "lastName": "Donaghue",
            "fullName": "Kate Donaghue",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/K_D1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "19th Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-19th Worcester-lower-ocd-person-e2aa2c0f-d6e6-49d7-8483-d595a3a1eb3a",
              "builtID": "ma-lower-19th worcester",
              "externalID": "ocd-person/e2aa2c0f-d6e6-49d7-8483-d595a3a1eb3a",
              "geometry": null
            },
            "contactInfo": {
              "email": "kate.donaghue@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/K_D1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-19th Worcester-lower-ocd-person-e2aa2c0f-d6e6-49d7-8483-d595a3a1eb3a": 0
        }
      },
      "3rd Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/3d709a64-6cd2-49c6-ad0d-b007e834bfec",
            "firstName": "Kate",
            "lastName": "Hogan",
            "fullName": "Kate Hogan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/K_H1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "3rd Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-3rd Middlesex-lower-ocd-person-3d709a64-6cd2-49c6-ad0d-b007e834bfec",
              "builtID": "ma-lower-3rd middlesex",
              "externalID": "ocd-person/3d709a64-6cd2-49c6-ad0d-b007e834bfec",
              "geometry": null
            },
            "contactInfo": {
              "email": "kate.hogan@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/K_H1",
                "https://malegislature.gov/Legislators/Profile/K_H1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-3rd Middlesex-lower-ocd-person-3d709a64-6cd2-49c6-ad0d-b007e834bfec": 0
        }
      },
      "32nd Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/7d66e1ca-1fb3-4194-bb18-f9924c528df2",
            "firstName": "Katherine",
            "lastName": "Lipper-Garabedian",
            "fullName": "Kate Lipper-Garabedian",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/KLG1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "32nd Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-32nd Middlesex-lower-ocd-person-7d66e1ca-1fb3-4194-bb18-f9924c528df2",
              "builtID": "ma-lower-32nd middlesex",
              "externalID": "ocd-person/7d66e1ca-1fb3-4194-bb18-f9924c528df2",
              "geometry": null
            },
            "contactInfo": {
              "email": "kate.lipper-garabedian@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/KLG1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-32nd Middlesex-lower-ocd-person-7d66e1ca-1fb3-4194-bb18-f9924c528df2": 0
        }
      },
      "12th Plymouth": {
        "members": [
          {
            "API_ID": "ocd-person/fbbda637-7a45-43f2-a13a-388dc50b02ee",
            "firstName": "Kathleen Smith",
            "lastName": "LaNatra",
            "fullName": "Kathy LaNatra",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/KPL1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "12th Plymouth",
              "chamber": "lower",
              "hashID": "SLDL-MA-12th Plymouth-lower-ocd-person-fbbda637-7a45-43f2-a13a-388dc50b02ee",
              "builtID": "ma-lower-12th plymouth",
              "externalID": "ocd-person/fbbda637-7a45-43f2-a13a-388dc50b02ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "kathleen.lanatra@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/KPL1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-12th Plymouth-lower-ocd-person-fbbda637-7a45-43f2-a13a-388dc50b02ee": 0
        }
      },
      "11th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/61a670a7-37a6-42f0-8c8f-4cfc1d93693a",
            "firstName": "Kay",
            "lastName": "Khan",
            "fullName": "Kay Khan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/K_K1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "11th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-11th Middlesex-lower-ocd-person-61a670a7-37a6-42f0-8c8f-4cfc1d93693a",
              "builtID": "ma-lower-11th middlesex",
              "externalID": "ocd-person/61a670a7-37a6-42f0-8c8f-4cfc1d93693a",
              "geometry": null
            },
            "contactInfo": {
              "email": "kay.khan@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/K_K1",
                "https://www.kaykhan.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-11th Middlesex-lower-ocd-person-61a670a7-37a6-42f0-8c8f-4cfc1d93693a": 0
        }
      },
      "4th Hampden": {
        "members": [
          {
            "API_ID": "ocd-person/72eea330-24b4-4207-a016-0b1e568e0b6e",
            "firstName": "Kelly W.",
            "lastName": "Pease",
            "fullName": "Kelly Pease",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/KWP1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "4th Hampden",
              "chamber": "lower",
              "hashID": "SLDL-MA-4th Hampden-lower-ocd-person-72eea330-24b4-4207-a016-0b1e568e0b6e",
              "builtID": "ma-lower-4th hampden",
              "externalID": "ocd-person/72eea330-24b4-4207-a016-0b1e568e0b6e",
              "geometry": null
            },
            "contactInfo": {
              "email": "kelly.pease@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/KWP1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-4th Hampden-lower-ocd-person-72eea330-24b4-4207-a016-0b1e568e0b6e": 0
        }
      },
      "21st Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/47c42957-b618-41b7-a557-5d24c33568cf",
            "firstName": "Kenneth I.",
            "lastName": "Gordon",
            "fullName": "Ken Gordon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/KIG1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "21st Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-21st Middlesex-lower-ocd-person-47c42957-b618-41b7-a557-5d24c33568cf",
              "builtID": "ma-lower-21st middlesex",
              "externalID": "ocd-person/47c42957-b618-41b7-a557-5d24c33568cf",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.gordon@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/KIG1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-21st Middlesex-lower-ocd-person-47c42957-b618-41b7-a557-5d24c33568cf": 0
        }
      },
      "17th Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/9e4b8b32-233e-443f-a147-46fbaa778a58",
            "firstName": "Kevin G.",
            "lastName": "Honan",
            "fullName": "Kevin Honan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/KGH1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "17th Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-17th Suffolk-lower-ocd-person-9e4b8b32-233e-443f-a147-46fbaa778a58",
              "builtID": "ma-lower-17th suffolk",
              "externalID": "ocd-person/9e4b8b32-233e-443f-a147-46fbaa778a58",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.honan@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "192 Faneuil St., Brighton, MA 02135",
              "phone1": null,
              "phone2": "617-254-6457",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/KGH1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-17th Suffolk-lower-ocd-person-9e4b8b32-233e-443f-a147-46fbaa778a58": 0
        }
      },
      "1st Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/888457ba-c348-4826-b2c6-450dba5e38bd",
            "firstName": "Kimberly N.",
            "lastName": "Ferguson",
            "fullName": "Kim Ferguson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Kimberly-Ferguson.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "1st Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-1st Worcester-lower-ocd-person-888457ba-c348-4826-b2c6-450dba5e38bd",
              "builtID": "ma-lower-1st worcester",
              "externalID": "ocd-person/888457ba-c348-4826-b2c6-450dba5e38bd",
              "geometry": null
            },
            "contactInfo": {
              "email": "kimberly.ferguson@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/KNF1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-1st Worcester-lower-ocd-person-888457ba-c348-4826-b2c6-450dba5e38bd": 0
        }
      },
      "2nd Barnstable": {
        "members": [
          {
            "API_ID": "ocd-person/df1a3cfc-1555-4a05-8a5c-03a7e53932aa",
            "firstName": "Kip Andre",
            "lastName": "Diggs",
            "fullName": "Kip Diggs",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.capecod.com/wp-content/uploads/NWS_Kip-Diggs_JS081720.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "2nd Barnstable",
              "chamber": "lower",
              "hashID": "SLDL-MA-2nd Barnstable-lower-ocd-person-df1a3cfc-1555-4a05-8a5c-03a7e53932aa",
              "builtID": "ma-lower-2nd barnstable",
              "externalID": "ocd-person/df1a3cfc-1555-4a05-8a5c-03a7e53932aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "kip.diggs@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/KAD1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-2nd Barnstable-lower-ocd-person-df1a3cfc-1555-4a05-8a5c-03a7e53932aa": 0
        }
      },
      "2nd Essex": {
        "members": [
          {
            "API_ID": "ocd-person/1129a0d9-c280-4bf0-af34-20879293a2b5",
            "firstName": "Kristin Hoffman",
            "lastName": "Kassner",
            "fullName": "Kristin Kassner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/K_K2.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "2nd Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-2nd Essex-lower-ocd-person-1129a0d9-c280-4bf0-af34-20879293a2b5",
              "builtID": "ma-lower-2nd essex",
              "externalID": "ocd-person/1129a0d9-c280-4bf0-af34-20879293a2b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "kristin.kassner@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/K_K2"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-2nd Essex-lower-ocd-person-1129a0d9-c280-4bf0-af34-20879293a2b5": 0
        }
      },
      "1st Hampshire": {
        "members": [
          {
            "API_ID": "ocd-person/05f9b238-283f-4529-8989-ff7d1bda60a8",
            "firstName": "Lindsay N.",
            "lastName": "Sabadosa",
            "fullName": "Lindsay Sabadosa",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/L_S1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "1st Hampshire",
              "chamber": "lower",
              "hashID": "SLDL-MA-1st Hampshire-lower-ocd-person-05f9b238-283f-4529-8989-ff7d1bda60a8",
              "builtID": "ma-lower-1st hampshire",
              "externalID": "ocd-person/05f9b238-283f-4529-8989-ff7d1bda60a8",
              "geometry": null
            },
            "contactInfo": {
              "email": "lindsay.sabadosa@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "76 Gothic St., Northampton, MA 01060",
              "phone1": null,
              "phone2": "413-270-1166",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/L_S1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-1st Hampshire-lower-ocd-person-05f9b238-283f-4529-8989-ff7d1bda60a8": 0
        }
      },
      "7th Essex": {
        "members": [
          {
            "API_ID": "ocd-person/5ae88c49-8938-4472-8de2-1c0f0bc0e2f0",
            "firstName": "Manny",
            "lastName": "Cruz",
            "fullName": "Manny Cruz",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/M_C3.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "7th Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-7th Essex-lower-ocd-person-5ae88c49-8938-4472-8de2-1c0f0bc0e2f0",
              "builtID": "ma-lower-7th essex",
              "externalID": "ocd-person/5ae88c49-8938-4472-8de2-1c0f0bc0e2f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "manny.cruz@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/M_C3"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-7th Essex-lower-ocd-person-5ae88c49-8938-4472-8de2-1c0f0bc0e2f0": 0
        }
      },
      "22nd Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/398f9a47-bc65-4c2a-b12e-347f375d8fac",
            "firstName": "Marc T.",
            "lastName": "Lombardo",
            "fullName": "Marc Lombardo",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/MTL1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "22nd Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-22nd Middlesex-lower-ocd-person-398f9a47-bc65-4c2a-b12e-347f375d8fac",
              "builtID": "ma-lower-22nd middlesex",
              "externalID": "ocd-person/398f9a47-bc65-4c2a-b12e-347f375d8fac",
              "geometry": null
            },
            "contactInfo": {
              "email": "marc.lombardo@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MTL1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-22nd Middlesex-lower-ocd-person-398f9a47-bc65-4c2a-b12e-347f375d8fac": 0
        }
      },
      "9th Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/2dd65a68-0ea5-4c15-b5fe-bcde086eb357",
            "firstName": "Marcus S.",
            "lastName": "Vaughn",
            "fullName": "Marcus Vaughn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/MSV1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "9th Norfolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-9th Norfolk-lower-ocd-person-2dd65a68-0ea5-4c15-b5fe-bcde086eb357",
              "builtID": "ma-lower-9th norfolk",
              "externalID": "ocd-person/2dd65a68-0ea5-4c15-b5fe-bcde086eb357",
              "geometry": null
            },
            "contactInfo": {
              "email": "marcus.vaughn@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MSV1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-9th Norfolk-lower-ocd-person-2dd65a68-0ea5-4c15-b5fe-bcde086eb357": 0
        }
      },
      "1st Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/a25fd374-e502-43bb-91fe-99c4b6c493a5",
            "firstName": "Margaret R.",
            "lastName": "Scarsdale",
            "fullName": "Margaret Scarsdale",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/MRS1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "1st Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-1st Middlesex-lower-ocd-person-a25fd374-e502-43bb-91fe-99c4b6c493a5",
              "builtID": "ma-lower-1st middlesex",
              "externalID": "ocd-person/a25fd374-e502-43bb-91fe-99c4b6c493a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "margaret.scarsdale@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MRS1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-1st Middlesex-lower-ocd-person-a25fd374-e502-43bb-91fe-99c4b6c493a5": 0
        }
      },
      "25th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/b9019024-899b-41b3-a283-69c50866fabd",
            "firstName": "Marjorie C.",
            "lastName": "Decker",
            "fullName": "Marjorie Decker",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/MCD1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "25th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-25th Middlesex-lower-ocd-person-b9019024-899b-41b3-a283-69c50866fabd",
              "builtID": "ma-lower-25th middlesex",
              "externalID": "ocd-person/b9019024-899b-41b3-a283-69c50866fabd",
              "geometry": null
            },
            "contactInfo": {
              "email": "marjorie.decker@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MCD1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-25th Middlesex-lower-ocd-person-b9019024-899b-41b3-a283-69c50866fabd": 0
        }
      },
      "5th Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/b51c5c27-7da0-42f5-b67b-22085173af7a",
            "firstName": "Mark James",
            "lastName": "Cusack",
            "fullName": "Mark Cusack",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/MJC1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "5th Norfolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-5th Norfolk-lower-ocd-person-b51c5c27-7da0-42f5-b67b-22085173af7a",
              "builtID": "ma-lower-5th norfolk",
              "externalID": "ocd-person/b51c5c27-7da0-42f5-b67b-22085173af7a",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.cusack@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MJC1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-5th Norfolk-lower-ocd-person-b51c5c27-7da0-42f5-b67b-22085173af7a": 0
        }
      },
      "15th Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/4d0b5dda-d9d1-4c25-9c94-ce8fbd8ff398",
            "firstName": "Mary S.",
            "lastName": "Keefe",
            "fullName": "Mary Keefe",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/MSK1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "15th Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-15th Worcester-lower-ocd-person-4d0b5dda-d9d1-4c25-9c94-ce8fbd8ff398",
              "builtID": "ma-lower-15th worcester",
              "externalID": "ocd-person/4d0b5dda-d9d1-4c25-9c94-ce8fbd8ff398",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.keefe@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MSK1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-15th Worcester-lower-ocd-person-4d0b5dda-d9d1-4c25-9c94-ce8fbd8ff398": 0
        }
      },
      "1st Plymouth": {
        "members": [
          {
            "API_ID": "ocd-person/207ff26d-21a1-448a-bd09-c1b08c2a6f62",
            "firstName": "Mathew J.",
            "lastName": "Muratore",
            "fullName": "Matt Muratore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/MJM2.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "1st Plymouth",
              "chamber": "lower",
              "hashID": "SLDL-MA-1st Plymouth-lower-ocd-person-207ff26d-21a1-448a-bd09-c1b08c2a6f62",
              "builtID": "ma-lower-1st plymouth",
              "externalID": "ocd-person/207ff26d-21a1-448a-bd09-c1b08c2a6f62",
              "geometry": null
            },
            "contactInfo": {
              "email": "mathew.muratore@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MJM2"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-1st Plymouth-lower-ocd-person-207ff26d-21a1-448a-bd09-c1b08c2a6f62": 0
        }
      },
      "12th Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/b61c923f-3598-465b-8051-6507b6f6c6c2",
            "firstName": "Meghan",
            "lastName": "Kilcoyne",
            "fullName": "Meg Kilcoyne",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/M_K1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "12th Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-12th Worcester-lower-ocd-person-b61c923f-3598-465b-8051-6507b6f6c6c2",
              "builtID": "ma-lower-12th worcester",
              "externalID": "ocd-person/b61c923f-3598-465b-8051-6507b6f6c6c2",
              "geometry": null
            },
            "contactInfo": {
              "email": "meghan.kilcoyne@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/M_K1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-12th Worcester-lower-ocd-person-b61c923f-3598-465b-8051-6507b6f6c6c2": 0
        }
      },
      "6th Hampden": {
        "members": [
          {
            "API_ID": "ocd-person/b61922e8-c775-4c74-8ecb-557d79162195",
            "firstName": "Michael J.",
            "lastName": "Finn",
            "fullName": "Mike Finn",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Michael_Finn.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "6th Hampden",
              "chamber": "lower",
              "hashID": "SLDL-MA-6th Hampden-lower-ocd-person-b61922e8-c775-4c74-8ecb-557d79162195",
              "builtID": "ma-lower-6th hampden",
              "externalID": "ocd-person/b61922e8-c775-4c74-8ecb-557d79162195",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.finn@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "71 Park Ave., West Springfield, MA 01089",
              "phone1": null,
              "phone2": "413-363-1965",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MJF1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-6th Hampden-lower-ocd-person-b61922e8-c775-4c74-8ecb-557d79162195": 0
        }
      },
      "18th Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/2fd8669d-a5e2-40fd-bb2f-595210f1bf49",
            "firstName": "Michael J.",
            "lastName": "Moran",
            "fullName": "Mike Moran",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/MJM1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "18th Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-18th Suffolk-lower-ocd-person-2fd8669d-a5e2-40fd-bb2f-595210f1bf49",
              "builtID": "ma-lower-18th suffolk",
              "externalID": "ocd-person/2fd8669d-a5e2-40fd-bb2f-595210f1bf49",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.moran@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MJM1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-18th Suffolk-lower-ocd-person-2fd8669d-a5e2-40fd-bb2f-595210f1bf49": 0
        }
      },
      "8th Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/4cec2aa4-c17b-4749-b0e9-58a25695bf2f",
            "firstName": "Michael J.",
            "lastName": "Soter",
            "fullName": "Mike Soter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/MJS3.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "8th Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-8th Worcester-lower-ocd-person-4cec2aa4-c17b-4749-b0e9-58a25695bf2f",
              "builtID": "ma-lower-8th worcester",
              "externalID": "ocd-person/4cec2aa4-c17b-4749-b0e9-58a25695bf2f",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.soter@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MJS3"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-8th Worcester-lower-ocd-person-4cec2aa4-c17b-4749-b0e9-58a25695bf2f": 0
        }
      },
      "3rd Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/591dc6dd-40b8-4713-83b8-f236ff6250bf",
            "firstName": "Michael Paul",
            "lastName": "Kushmerek",
            "fullName": "Mike Kushmerek",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/MPK1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "3rd Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-3rd Worcester-lower-ocd-person-591dc6dd-40b8-4713-83b8-f236ff6250bf",
              "builtID": "ma-lower-3rd worcester",
              "externalID": "ocd-person/591dc6dd-40b8-4713-83b8-f236ff6250bf",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.kushmerek@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "718 Main St., Fitchburg, MA 01420",
              "phone1": null,
              "phone2": "978-829-1954",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MPK1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-3rd Worcester-lower-ocd-person-591dc6dd-40b8-4713-83b8-f236ff6250bf": 0
        }
      },
      "31st Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/2e198563-4aeb-499b-8333-b4a46b605491",
            "firstName": "Michael Seamus",
            "lastName": "Day",
            "fullName": "Mike Day",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/MSD1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "31st Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-31st Middlesex-lower-ocd-person-2e198563-4aeb-499b-8333-b4a46b605491",
              "builtID": "ma-lower-31st middlesex",
              "externalID": "ocd-person/2e198563-4aeb-499b-8333-b4a46b605491",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.day@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MSD1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-31st Middlesex-lower-ocd-person-2e198563-4aeb-499b-8333-b4a46b605491": 0
        }
      },
      "15th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/ea385faa-049c-4743-ad41-3bbe58ee40e2",
            "firstName": "Michelle L.",
            "lastName": "Ciccolo",
            "fullName": "Michelle Ciccolo",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/M_C2.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "15th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-15th Middlesex-lower-ocd-person-ea385faa-049c-4743-ad41-3bbe58ee40e2",
              "builtID": "ma-lower-15th middlesex",
              "externalID": "ocd-person/ea385faa-049c-4743-ad41-3bbe58ee40e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "michelle.ciccolo@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/M_C2"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-15th Middlesex-lower-ocd-person-ea385faa-049c-4743-ad41-3bbe58ee40e2": 0
        }
      },
      "10th Plymouth": {
        "members": [
          {
            "API_ID": "ocd-person/f41834f0-0d73-4e8e-8758-66184772c081",
            "firstName": "Michelle M.",
            "lastName": "DuBois",
            "fullName": "Michelle DuBois",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/MMD1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "10th Plymouth",
              "chamber": "lower",
              "hashID": "SLDL-MA-10th Plymouth-lower-ocd-person-f41834f0-0d73-4e8e-8758-66184772c081",
              "builtID": "ma-lower-10th plymouth",
              "externalID": "ocd-person/f41834f0-0d73-4e8e-8758-66184772c081",
              "geometry": null
            },
            "contactInfo": {
              "email": "michelle.dubois@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MMD1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-10th Plymouth-lower-ocd-person-f41834f0-0d73-4e8e-8758-66184772c081": 0
        }
      },
      "26th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/a8225754-d88c-4343-8829-aa168b564cbd",
            "firstName": "Michael L.",
            "lastName": "Connolly",
            "fullName": "Mike Connolly",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/M_C1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "26th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-26th Middlesex-lower-ocd-person-a8225754-d88c-4343-8829-aa168b564cbd",
              "builtID": "ma-lower-26th middlesex",
              "externalID": "ocd-person/a8225754-d88c-4343-8829-aa168b564cbd",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.connolly@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/M_C1",
                "https://www.repmikeconnolly.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-26th Middlesex-lower-ocd-person-a8225754-d88c-4343-8829-aa168b564cbd": 0
        }
      },
      "3rd Hampshire": {
        "members": [
          {
            "API_ID": "ocd-person/65617e1a-1834-4ec8-9f19-4ac801ce9764",
            "firstName": "Mindy",
            "lastName": "Domb",
            "fullName": "Mindy Domb",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/M_D2.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "3rd Hampshire",
              "chamber": "lower",
              "hashID": "SLDL-MA-3rd Hampshire-lower-ocd-person-65617e1a-1834-4ec8-9f19-4ac801ce9764",
              "builtID": "ma-lower-3rd hampshire",
              "externalID": "ocd-person/65617e1a-1834-4ec8-9f19-4ac801ce9764",
              "geometry": null
            },
            "contactInfo": {
              "email": "mindy.domb@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "MA",
              "phone1": null,
              "phone2": "413-335-1362",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/M_D2"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-3rd Hampshire-lower-ocd-person-65617e1a-1834-4ec8-9f19-4ac801ce9764": 0
        }
      },
      "4th Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/17c3ac90-1218-4d61-ae47-bc5ef6ee4a74",
            "firstName": "Natalie M.",
            "lastName": "Higgins",
            "fullName": "Natalie Higgins",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/N_H1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "4th Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-4th Worcester-lower-ocd-person-17c3ac90-1218-4d61-ae47-bc5ef6ee4a74",
              "builtID": "ma-lower-4th worcester",
              "externalID": "ocd-person/17c3ac90-1218-4d61-ae47-bc5ef6ee4a74",
              "geometry": null
            },
            "contactInfo": {
              "email": "natalie.higgins@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/N_H1",
                "https://www.repnataliehiggins.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-4th Worcester-lower-ocd-person-17c3ac90-1218-4d61-ae47-bc5ef6ee4a74": 0
        }
      },
      "1st Franklin": {
        "members": [
          {
            "API_ID": "ocd-person/173c7e47-c770-4c5a-9794-4db19f9f5dce",
            "firstName": "Natalie M.",
            "lastName": "Blais",
            "fullName": "Natalie Blais",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/NMB1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "1st Franklin",
              "chamber": "lower",
              "hashID": "SLDL-MA-1st Franklin-lower-ocd-person-173c7e47-c770-4c5a-9794-4db19f9f5dce",
              "builtID": "ma-lower-1st franklin",
              "externalID": "ocd-person/173c7e47-c770-4c5a-9794-4db19f9f5dce",
              "geometry": null
            },
            "contactInfo": {
              "email": "natalie.blais@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 450, Sunderland, MA 01375",
              "phone1": null,
              "phone2": "413-362-9453",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/NMB1",
                "https://www.repblais.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-1st Franklin-lower-ocd-person-173c7e47-c770-4c5a-9794-4db19f9f5dce": 0
        }
      },
      "3rd Hampden": {
        "members": [
          {
            "API_ID": "ocd-person/0e72fc19-89be-49b9-8baf-5f35f938ec87",
            "firstName": "Nicholas A.",
            "lastName": "Boldyga",
            "fullName": "Nick Boldyga",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/NAG1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "3rd Hampden",
              "chamber": "lower",
              "hashID": "SLDL-MA-3rd Hampden-lower-ocd-person-0e72fc19-89be-49b9-8baf-5f35f938ec87",
              "builtID": "ma-lower-3rd hampden",
              "externalID": "ocd-person/0e72fc19-89be-49b9-8baf-5f35f938ec87",
              "geometry": null
            },
            "contactInfo": {
              "email": "nicholas.boldyga@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Town Hall 454 College Highway, Southwick, MA 01077",
              "phone1": null,
              "phone2": "413-569-3137 ext. 103",
              "fax1": null,
              "fax2": "413-455-3180",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/NAG1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-3rd Hampden-lower-ocd-person-0e72fc19-89be-49b9-8baf-5f35f938ec87": 0
        }
      },
      "12th Bristol": {
        "members": [
          {
            "API_ID": "ocd-person/6d186dd3-a4a0-4d65-aaf1-2f0a63cd42ec",
            "firstName": "Norman J.",
            "lastName": "Orrall",
            "fullName": "Norm Orrall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/NJO1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "12th Bristol",
              "chamber": "lower",
              "hashID": "SLDL-MA-12th Bristol-lower-ocd-person-6d186dd3-a4a0-4d65-aaf1-2f0a63cd42ec",
              "builtID": "ma-lower-12th bristol",
              "externalID": "ocd-person/6d186dd3-a4a0-4d65-aaf1-2f0a63cd42ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "norman.orrall@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/NJO1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-12th Bristol-lower-ocd-person-6d186dd3-a4a0-4d65-aaf1-2f0a63cd42ec": 0
        }
      },
      "9th Hampden": {
        "members": [
          {
            "API_ID": "ocd-person/a1a94ad4-7b75-46be-896e-548c86b23d0c",
            "firstName": "Orlando",
            "lastName": "Ramos",
            "fullName": "Orlando Ramos",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/O_R1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "9th Hampden",
              "chamber": "lower",
              "hashID": "SLDL-MA-9th Hampden-lower-ocd-person-a1a94ad4-7b75-46be-896e-548c86b23d0c",
              "builtID": "ma-lower-9th hampden",
              "externalID": "ocd-person/a1a94ad4-7b75-46be-896e-548c86b23d0c",
              "geometry": null
            },
            "contactInfo": {
              "email": "orlando.ramos@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Springfield, MA 01104",
              "phone1": null,
              "phone2": "413-271-7955",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/O_R1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-9th Hampden-lower-ocd-person-a1a94ad4-7b75-46be-896e-548c86b23d0c": 0
        }
      },
      "5th Hampden": {
        "members": [
          {
            "API_ID": "ocd-person/e50e8121-bc6f-4aaa-8cf2-f1cbd53c9172",
            "firstName": "Patricia A.",
            "lastName": "Duffy",
            "fullName": "Pat Duffy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/PAD1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "5th Hampden",
              "chamber": "lower",
              "hashID": "SLDL-MA-5th Hampden-lower-ocd-person-e50e8121-bc6f-4aaa-8cf2-f1cbd53c9172",
              "builtID": "ma-lower-5th hampden",
              "externalID": "ocd-person/e50e8121-bc6f-4aaa-8cf2-f1cbd53c9172",
              "geometry": null
            },
            "contactInfo": {
              "email": "patricia.duffy@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/PAD1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-5th Hampden-lower-ocd-person-e50e8121-bc6f-4aaa-8cf2-f1cbd53c9172": 0
        }
      },
      "5th Bristol": {
        "members": [
          {
            "API_ID": "ocd-person/d2f16a1f-415d-4e60-b905-2f4c71959e0f",
            "firstName": "Patricia A.",
            "lastName": "Haddad",
            "fullName": "Pat Haddad",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/PAH1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "5th Bristol",
              "chamber": "lower",
              "hashID": "SLDL-MA-5th Bristol-lower-ocd-person-d2f16a1f-415d-4e60-b905-2f4c71959e0f",
              "builtID": "ma-lower-5th bristol",
              "externalID": "ocd-person/d2f16a1f-415d-4e60-b905-2f4c71959e0f",
              "geometry": null
            },
            "contactInfo": {
              "email": "patricia.haddad@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Somerset, MA",
              "phone1": null,
              "phone2": "508-646-2821",
              "fax1": null,
              "fax2": "508-324-0993",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/PAH1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-5th Bristol-lower-ocd-person-d2f16a1f-415d-4e60-b905-2f4c71959e0f": 0
        }
      },
      "4th Plymouth": {
        "members": [
          {
            "API_ID": "ocd-person/0a288f3d-397f-4e7b-8bfd-b5032ae1421f",
            "firstName": "Patrick Joseph",
            "lastName": "Kearney",
            "fullName": "Patrick Kearney",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/PJK1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "4th Plymouth",
              "chamber": "lower",
              "hashID": "SLDL-MA-4th Plymouth-lower-ocd-person-0a288f3d-397f-4e7b-8bfd-b5032ae1421f",
              "builtID": "ma-lower-4th plymouth",
              "externalID": "ocd-person/0a288f3d-397f-4e7b-8bfd-b5032ae1421f",
              "geometry": null
            },
            "contactInfo": {
              "email": "patrick.kearney@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/PJK1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-4th Plymouth-lower-ocd-person-0a288f3d-397f-4e7b-8bfd-b5032ae1421f": 0
        }
      },
      "8th Bristol": {
        "members": [
          {
            "API_ID": "ocd-person/51c2533b-95e0-45c5-9100-0c6d68efb192",
            "firstName": "Paul A.",
            "lastName": "Schmid",
            "fullName": "Paul Schmid",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/PAS1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "8th Bristol",
              "chamber": "lower",
              "hashID": "SLDL-MA-8th Bristol-lower-ocd-person-51c2533b-95e0-45c5-9100-0c6d68efb192",
              "builtID": "ma-lower-8th bristol",
              "externalID": "ocd-person/51c2533b-95e0-45c5-9100-0c6d68efb192",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.schmid@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Fall River, MA",
              "phone1": null,
              "phone2": "508-567-5675",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/PAS1",
                "https://www.reppaulschmid.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-8th Bristol-lower-ocd-person-51c2533b-95e0-45c5-9100-0c6d68efb192": 0
        }
      },
      "35th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/18bf433d-24e5-46cb-9d83-c2f9373204bd",
            "firstName": "Paul J.",
            "lastName": "Donato",
            "fullName": "Paul Donato",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/PJD1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "35th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-35th Middlesex-lower-ocd-person-18bf433d-24e5-46cb-9d83-c2f9373204bd",
              "builtID": "ma-lower-35th middlesex",
              "externalID": "ocd-person/18bf433d-24e5-46cb-9d83-c2f9373204bd",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.donato@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Medford, MA",
              "phone1": null,
              "phone2": "781-395-1683",
              "fax1": null,
              "fax2": "781-395-2871",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/PJD1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-35th Middlesex-lower-ocd-person-18bf433d-24e5-46cb-9d83-c2f9373204bd": 0
        }
      },
      "7th Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/fdc5586f-0db3-4614-af1f-72a42cf5e6f0",
            "firstName": "Paul K.",
            "lastName": "Frost",
            "fullName": "Paul Frost",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Paul-Frost.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "7th Worcester",
              "chamber": "lower",
              "hashID": "SLDL-MA-7th Worcester-lower-ocd-person-fdc5586f-0db3-4614-af1f-72a42cf5e6f0",
              "builtID": "ma-lower-7th worcester",
              "externalID": "ocd-person/fdc5586f-0db3-4614-af1f-72a42cf5e6f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.frost@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/PKF1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-7th Worcester-lower-ocd-person-fdc5586f-0db3-4614-af1f-72a42cf5e6f0": 0
        }
      },
      "11th Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/d0e66289-022e-4a99-ac8c-f20fb7557e7f",
            "firstName": "Paul",
            "lastName": "McMurtry",
            "fullName": "Paul McMurtry",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/P_M1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "11th Norfolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-11th Norfolk-lower-ocd-person-d0e66289-022e-4a99-ac8c-f20fb7557e7f",
              "builtID": "ma-lower-11th norfolk",
              "externalID": "ocd-person/d0e66289-022e-4a99-ac8c-f20fb7557e7f",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.mcmurtry@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/P_M1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-11th Norfolk-lower-ocd-person-d0e66289-022e-4a99-ac8c-f20fb7557e7f": 0
        }
      },
      "11th Essex": {
        "members": [
          {
            "API_ID": "ocd-person/c017af03-ae33-4c72-aee4-6ce58722b3b8",
            "firstName": "Peter L.",
            "lastName": "Capano",
            "fullName": "Pete Capano",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/PLC1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "11th Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-11th Essex-lower-ocd-person-c017af03-ae33-4c72-aee4-6ce58722b3b8",
              "builtID": "ma-lower-11th essex",
              "externalID": "ocd-person/c017af03-ae33-4c72-aee4-6ce58722b3b8",
              "geometry": null
            },
            "contactInfo": {
              "email": "peter.capano@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/PLC1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-11th Essex-lower-ocd-person-c017af03-ae33-4c72-aee4-6ce58722b3b8": 0
        }
      },
      "6th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/a148ff77-2e73-4e42-a525-20e8d1ed569a",
            "firstName": "Priscila S.",
            "lastName": "Sousa",
            "fullName": "Priscila Sousa",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/PSS1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "6th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-6th Middlesex-lower-ocd-person-a148ff77-2e73-4e42-a525-20e8d1ed569a",
              "builtID": "ma-lower-6th middlesex",
              "externalID": "ocd-person/a148ff77-2e73-4e42-a525-20e8d1ed569a",
              "geometry": null
            },
            "contactInfo": {
              "email": "priscila.sousa@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/PSS1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-6th Middlesex-lower-ocd-person-a148ff77-2e73-4e42-a525-20e8d1ed569a": 0
        }
      },
      "18th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/7b9e688f-a9aa-425b-8d3d-833993d6d58a",
            "firstName": "Rady",
            "lastName": "Mom",
            "fullName": "Rady Mom",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/R_M2.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "18th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-18th Middlesex-lower-ocd-person-7b9e688f-a9aa-425b-8d3d-833993d6d58a",
              "builtID": "ma-lower-18th middlesex",
              "externalID": "ocd-person/7b9e688f-a9aa-425b-8d3d-833993d6d58a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rady.mom@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/R_M2"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-18th Middlesex-lower-ocd-person-7b9e688f-a9aa-425b-8d3d-833993d6d58a": 0
        }
      },
      "30th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/521620ea-1e5b-4a0c-94f8-abe888370e05",
            "firstName": "Richard M.",
            "lastName": "Haggerty",
            "fullName": "Rich Haggerty",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/RMH1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "30th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-30th Middlesex-lower-ocd-person-521620ea-1e5b-4a0c-94f8-abe888370e05",
              "builtID": "ma-lower-30th middlesex",
              "externalID": "ocd-person/521620ea-1e5b-4a0c-94f8-abe888370e05",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.haggerty@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/RMH1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-30th Middlesex-lower-ocd-person-521620ea-1e5b-4a0c-94f8-abe888370e05": 0
        }
      },
      "11th Plymouth": {
        "members": [
          {
            "API_ID": "ocd-person/87e3afd8-390c-45a3-97c6-2bb164ec1e90",
            "firstName": "Rita A.",
            "lastName": "Mendes",
            "fullName": "Rita Mendes",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/RAM1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "11th Plymouth",
              "chamber": "lower",
              "hashID": "SLDL-MA-11th Plymouth-lower-ocd-person-87e3afd8-390c-45a3-97c6-2bb164ec1e90",
              "builtID": "ma-lower-11th plymouth",
              "externalID": "ocd-person/87e3afd8-390c-45a3-97c6-2bb164ec1e90",
              "geometry": null
            },
            "contactInfo": {
              "email": "rita.mendes@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/RAM1",
                "https://www.staterepmendes.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-11th Plymouth-lower-ocd-person-87e3afd8-390c-45a3-97c6-2bb164ec1e90": 0
        }
      },
      "14th Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/edcd5892-2488-4945-a3da-86f40012d396",
            "firstName": "Robert",
            "lastName": "Consalvo",
            "fullName": "Rob Consalvo",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/R_C1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "14th Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-14th Suffolk-lower-ocd-person-edcd5892-2488-4945-a3da-86f40012d396",
              "builtID": "ma-lower-14th suffolk",
              "externalID": "ocd-person/edcd5892-2488-4945-a3da-86f40012d396",
              "geometry": null
            },
            "contactInfo": {
              "email": "rob.consalvo@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/R_C1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-14th Suffolk-lower-ocd-person-edcd5892-2488-4945-a3da-86f40012d396": 0
        }
      },
      "16th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/49816a0c-b8e5-4586-859b-8a8f1ae9b6ab",
            "firstName": "Rodney M.",
            "lastName": "Elliott",
            "fullName": "Rodney Elliott",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/RME1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "16th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-16th Middlesex-lower-ocd-person-49816a0c-b8e5-4586-859b-8a8f1ae9b6ab",
              "builtID": "ma-lower-16th middlesex",
              "externalID": "ocd-person/49816a0c-b8e5-4586-859b-8a8f1ae9b6ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "rodney.elliott@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/RME1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-16th Middlesex-lower-ocd-person-49816a0c-b8e5-4586-859b-8a8f1ae9b6ab": 0
        }
      },
      "3rd Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/e142d28d-e57a-4ff9-afa4-c3cd149f2114",
            "firstName": "Ronald Joseph",
            "lastName": "Mariano",
            "fullName": "Ron Mariano",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/R_M1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "3rd Norfolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-3rd Norfolk-lower-ocd-person-e142d28d-e57a-4ff9-afa4-c3cd149f2114",
              "builtID": "ma-lower-3rd norfolk",
              "externalID": "ocd-person/e142d28d-e57a-4ff9-afa4-c3cd149f2114",
              "geometry": null
            },
            "contactInfo": {
              "email": "ronald.mariano@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/R_M1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-3rd Norfolk-lower-ocd-person-e142d28d-e57a-4ff9-afa4-c3cd149f2114": 0
        }
      },
      "6th Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/078692f2-2afe-4aa1-a320-d4747edacbbb",
            "firstName": "Russell Earl",
            "lastName": "Holmes",
            "fullName": "Russell Holmes",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/REH1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "6th Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-6th Suffolk-lower-ocd-person-078692f2-2afe-4aa1-a320-d4747edacbbb",
              "builtID": "ma-lower-6th suffolk",
              "externalID": "ocd-person/078692f2-2afe-4aa1-a320-d4747edacbbb",
              "geometry": null
            },
            "contactInfo": {
              "email": "russell.holmes@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/REH1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-6th Suffolk-lower-ocd-person-078692f2-2afe-4aa1-a320-d4747edacbbb": 0
        }
      },
      "12th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/0df5de43-f3a3-4988-82fb-7f951fa02753",
            "firstName": "Ruth B.",
            "lastName": "Balser",
            "fullName": "Ruth Balser",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/RBB1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "12th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-12th Middlesex-lower-ocd-person-0df5de43-f3a3-4988-82fb-7f951fa02753",
              "builtID": "ma-lower-12th middlesex",
              "externalID": "ocd-person/0df5de43-f3a3-4988-82fb-7f951fa02753",
              "geometry": null
            },
            "contactInfo": {
              "email": "ruth.balser@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/RBB1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-12th Middlesex-lower-ocd-person-0df5de43-f3a3-4988-82fb-7f951fa02753": 0
        }
      },
      "15th Essex": {
        "members": [
          {
            "API_ID": "ocd-person/799d49f3-28e5-46a1-bcc8-6370e9fbf44a",
            "firstName": "Ryan M.",
            "lastName": "Hamilton",
            "fullName": "Ryan Hamilton",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/RMH2.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "15th Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-15th Essex-lower-ocd-person-799d49f3-28e5-46a1-bcc8-6370e9fbf44a",
              "builtID": "ma-lower-15th essex",
              "externalID": "ocd-person/799d49f3-28e5-46a1-bcc8-6370e9fbf44a",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryan.hamilton@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/RMH2"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-15th Essex-lower-ocd-person-799d49f3-28e5-46a1-bcc8-6370e9fbf44a": 0
        }
      },
      "13th Essex": {
        "members": [
          {
            "API_ID": "ocd-person/308b15a0-dc30-41be-be53-9e5827522cac",
            "firstName": "Sally",
            "lastName": "Kerans",
            "fullName": "Sally Kerans",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/SPK1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "13th Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-13th Essex-lower-ocd-person-308b15a0-dc30-41be-be53-9e5827522cac",
              "builtID": "ma-lower-13th essex",
              "externalID": "ocd-person/308b15a0-dc30-41be-be53-9e5827522cac",
              "geometry": null
            },
            "contactInfo": {
              "email": "sally.kerans@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/SPK1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-13th Essex-lower-ocd-person-308b15a0-dc30-41be-be53-9e5827522cac": 0
        }
      },
      "15th Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/668db267-f9e1-4839-918d-c092741f96f9",
            "firstName": "Samantha",
            "lastName": "Montano",
            "fullName": "Sam Montaño",
            "gender": "X",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/S_M1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "15th Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-15th Suffolk-lower-ocd-person-668db267-f9e1-4839-918d-c092741f96f9",
              "builtID": "ma-lower-15th suffolk",
              "externalID": "ocd-person/668db267-f9e1-4839-918d-c092741f96f9",
              "geometry": null
            },
            "contactInfo": {
              "email": "samantha.montano@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/S_M1",
                "https://malegislature.gov/Legislators/Profile/S_M1/District",
                "https://malegislature.gov/Legislators/Profile/S_M1/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-15th Suffolk-lower-ocd-person-668db267-f9e1-4839-918d-c092741f96f9": 0
        }
      },
      "4th Barnstable": {
        "members": [
          {
            "API_ID": "ocd-person/6b1bbe32-1b86-48a4-909e-ee1ba6c5c746",
            "firstName": "Sarah K.",
            "lastName": "Peake",
            "fullName": "Sarah Peake",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/SKP1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "4th Barnstable",
              "chamber": "lower",
              "hashID": "SLDL-MA-4th Barnstable-lower-ocd-person-6b1bbe32-1b86-48a4-909e-ee1ba6c5c746",
              "builtID": "ma-lower-4th barnstable",
              "externalID": "ocd-person/6b1bbe32-1b86-48a4-909e-ee1ba6c5c746",
              "geometry": null
            },
            "contactInfo": {
              "email": "sarah.peake@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/SKP1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-4th Barnstable-lower-ocd-person-6b1bbe32-1b86-48a4-909e-ee1ba6c5c746": 0
        }
      },
      "23rd Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/1e9d742c-c20b-45cf-9e22-e7752189fa7a",
            "firstName": "Sean",
            "lastName": "Garballey",
            "fullName": "Sean Garballey",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/S_G1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "23rd Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-23rd Middlesex-lower-ocd-person-1e9d742c-c20b-45cf-9e22-e7752189fa7a",
              "builtID": "ma-lower-23rd middlesex",
              "externalID": "ocd-person/1e9d742c-c20b-45cf-9e22-e7752189fa7a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sean.garballey@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/S_G1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-23rd Middlesex-lower-ocd-person-1e9d742c-c20b-45cf-9e22-e7752189fa7a": 0
        }
      },
      "8th Hampden": {
        "members": [
          {
            "API_ID": "ocd-person/ff433cf6-2895-4e01-b143-26747500f8a4",
            "firstName": "Shirley B.",
            "lastName": "Arriaga",
            "fullName": "Shirley Arriaga",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/SBA1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "8th Hampden",
              "chamber": "lower",
              "hashID": "SLDL-MA-8th Hampden-lower-ocd-person-ff433cf6-2895-4e01-b143-26747500f8a4",
              "builtID": "ma-lower-8th hampden",
              "externalID": "ocd-person/ff433cf6-2895-4e01-b143-26747500f8a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "shirley.arriaga@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/SBA1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-8th Hampden-lower-ocd-person-ff433cf6-2895-4e01-b143-26747500f8a4": 0
        }
      },
      "14th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/f43366ee-ff27-43ac-aa55-629be715b4a9",
            "firstName": "Simon Joseph",
            "lastName": "Cataldo",
            "fullName": "Simon Cataldo",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/S_C1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "14th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-14th Middlesex-lower-ocd-person-f43366ee-ff27-43ac-aa55-629be715b4a9",
              "builtID": "ma-lower-14th middlesex",
              "externalID": "ocd-person/f43366ee-ff27-43ac-aa55-629be715b4a9",
              "geometry": null
            },
            "contactInfo": {
              "email": "simon.cataldo@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/S_C1",
                "https://www.repcataldo.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-14th Middlesex-lower-ocd-person-f43366ee-ff27-43ac-aa55-629be715b4a9": 0
        }
      },
      "3rd Berkshire": {
        "members": [
          {
            "API_ID": "ocd-person/88e0ae45-75e7-48b6-880a-1a4c039d4373",
            "firstName": "William Smitty",
            "lastName": "Pignatelli",
            "fullName": "Smitty Pignatelli",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/William-Pignatelli.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "3rd Berkshire",
              "chamber": "lower",
              "hashID": "SLDL-MA-3rd Berkshire-lower-ocd-person-88e0ae45-75e7-48b6-880a-1a4c039d4373",
              "builtID": "ma-lower-3rd berkshire",
              "externalID": "ocd-person/88e0ae45-75e7-48b6-880a-1a4c039d4373",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.smitty@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Lenox, MA",
              "phone1": null,
              "phone2": "413-637-0631",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/WSP1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-3rd Berkshire-lower-ocd-person-88e0ae45-75e7-48b6-880a-1a4c039d4373": 0
        }
      },
      "29th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/f20dd448-92ef-458c-bf92-90e7e766072a",
            "firstName": "Steven C.",
            "lastName": "Owens",
            "fullName": "Steve Owens",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/SCO1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "29th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-29th Middlesex-lower-ocd-person-f20dd448-92ef-458c-bf92-90e7e766072a",
              "builtID": "ma-lower-29th middlesex",
              "externalID": "ocd-person/f20dd448-92ef-458c-bf92-90e7e766072a",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.owens@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/SCO1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-29th Middlesex-lower-ocd-person-f20dd448-92ef-458c-bf92-90e7e766072a": 0
        }
      },
      "5th Barnstable": {
        "members": [
          {
            "API_ID": "ocd-person/485e001e-95e9-4b79-9226-cf028a307138",
            "firstName": "Steven George",
            "lastName": "Xiarhos",
            "fullName": "Steve Xiarhos",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/SGX1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "5th Barnstable",
              "chamber": "lower",
              "hashID": "SLDL-MA-5th Barnstable-lower-ocd-person-485e001e-95e9-4b79-9226-cf028a307138",
              "builtID": "ma-lower-5th barnstable",
              "externalID": "ocd-person/485e001e-95e9-4b79-9226-cf028a307138",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.xiarhos@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "MA",
              "phone1": null,
              "phone2": "508-681-9673",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/SGX1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-5th Barnstable-lower-ocd-person-485e001e-95e9-4b79-9226-cf028a307138": 0
        }
      },
      "4th Bristol": {
        "members": [
          {
            "API_ID": "ocd-person/54487f49-5377-4c41-bd97-60b748cc240a",
            "firstName": "Steven S.",
            "lastName": "Howitt",
            "fullName": "Steven Howitt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/SSH1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "4th Bristol",
              "chamber": "lower",
              "hashID": "SLDL-MA-4th Bristol-lower-ocd-person-54487f49-5377-4c41-bd97-60b748cc240a",
              "builtID": "ma-lower-4th bristol",
              "externalID": "ocd-person/54487f49-5377-4c41-bd97-60b748cc240a",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.howitt@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/SSH1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-4th Bristol-lower-ocd-person-54487f49-5377-4c41-bd97-60b748cc240a": 0
        }
      },
      "33rd Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/209427dd-91f0-45d6-ae8c-05a51872c10c",
            "firstName": "Steven",
            "lastName": "Ultrino",
            "fullName": "Steve Ultrino",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/S_G2.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "33rd Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-33rd Middlesex-lower-ocd-person-209427dd-91f0-45d6-ae8c-05a51872c10c",
              "builtID": "ma-lower-33rd middlesex",
              "externalID": "ocd-person/209427dd-91f0-45d6-ae8c-05a51872c10c",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.ultrino@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/S_G2"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-33rd Middlesex-lower-ocd-person-209427dd-91f0-45d6-ae8c-05a51872c10c": 0
        }
      },
      "2nd Plymouth": {
        "members": [
          {
            "API_ID": "ocd-person/adb172f4-64c0-4c96-8097-71bd125fc2e0",
            "firstName": "Susan Williams",
            "lastName": "Gifford",
            "fullName": "Susan Gifford",
            "gender": "Female",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/SWG1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "2nd Plymouth",
              "chamber": "lower",
              "hashID": "SLDL-MA-2nd Plymouth-lower-ocd-person-adb172f4-64c0-4c96-8097-71bd125fc2e0",
              "builtID": "ma-lower-2nd plymouth",
              "externalID": "ocd-person/adb172f4-64c0-4c96-8097-71bd125fc2e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.gifford@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Wareham, MA",
              "phone1": null,
              "phone2": "508-295-5999",
              "fax1": null,
              "fax2": "508-295-5993",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/SWG1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-2nd Plymouth-lower-ocd-person-adb172f4-64c0-4c96-8097-71bd125fc2e0": 0
        }
      },
      "2nd Franklin": {
        "members": [
          {
            "API_ID": "ocd-person/76b07f35-1876-4259-9c0a-bdf595541c39",
            "firstName": "Susannah M.",
            "lastName": "Whipps",
            "fullName": "Susannah Whipps",
            "gender": "Female",
            "party": "independent",
            "image": "https://malegislature.gov/Legislators/Profile/170/SLG1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "2nd Franklin",
              "chamber": "lower",
              "hashID": "SLDL-MA-2nd Franklin-lower-ocd-person-76b07f35-1876-4259-9c0a-bdf595541c39",
              "builtID": "ma-lower-2nd franklin",
              "externalID": "ocd-person/76b07f35-1876-4259-9c0a-bdf595541c39",
              "geometry": null
            },
            "contactInfo": {
              "email": "susannah.whipps@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "352 Main St., Athol, MA 01331",
              "phone1": null,
              "phone2": "978-895-9606",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/SLG1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-2nd Franklin-lower-ocd-person-76b07f35-1876-4259-9c0a-bdf595541c39": 0
        }
      },
      "2nd Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/6b420d2a-f12d-40ca-a13f-04cf9061a66b",
            "firstName": "Tackey",
            "lastName": "Chan",
            "fullName": "Tackey Chan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/T_C1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "2nd Norfolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-2nd Norfolk-lower-ocd-person-6b420d2a-f12d-40ca-a13f-04cf9061a66b",
              "builtID": "ma-lower-2nd norfolk",
              "externalID": "ocd-person/6b420d2a-f12d-40ca-a13f-04cf9061a66b",
              "geometry": null
            },
            "contactInfo": {
              "email": "tackey.chan@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/T_C1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-2nd Norfolk-lower-ocd-person-6b420d2a-f12d-40ca-a13f-04cf9061a66b": 0
        }
      },
      "9th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/2becbe4e-5b5e-4b42-be87-b4abf57550c7",
            "firstName": "Thomas M.",
            "lastName": "Stanley",
            "fullName": "Tom Stanley",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/TMS1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "9th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-9th Middlesex-lower-ocd-person-2becbe4e-5b5e-4b42-be87-b4abf57550c7",
              "builtID": "ma-lower-9th middlesex",
              "externalID": "ocd-person/2becbe4e-5b5e-4b42-be87-b4abf57550c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "thomas.stanley@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/TMS1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-9th Middlesex-lower-ocd-person-2becbe4e-5b5e-4b42-be87-b4abf57550c7": 0
        }
      },
      "12th Essex": {
        "members": [
          {
            "API_ID": "ocd-person/5f796cb3-08ae-40c4-8c11-a2758f317cc2",
            "firstName": "Thomas P.",
            "lastName": "Walsh",
            "fullName": "Tom Walsh",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/TJW1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "12th Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-12th Essex-lower-ocd-person-5f796cb3-08ae-40c4-8c11-a2758f317cc2",
              "builtID": "ma-lower-12th essex",
              "externalID": "ocd-person/5f796cb3-08ae-40c4-8c11-a2758f317cc2",
              "geometry": null
            },
            "contactInfo": {
              "email": "thomas.walsh@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/TJW1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-12th Essex-lower-ocd-person-5f796cb3-08ae-40c4-8c11-a2758f317cc2": 0
        }
      },
      "1st Hampden": {
        "members": [
          {
            "API_ID": "ocd-person/d8895862-a95b-4982-bb2e-31c8ffa4bf57",
            "firstName": "Todd M.",
            "lastName": "Smola",
            "fullName": "Todd Smola",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/TMS2.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "1st Hampden",
              "chamber": "lower",
              "hashID": "SLDL-MA-1st Hampden-lower-ocd-person-d8895862-a95b-4982-bb2e-31c8ffa4bf57",
              "builtID": "ma-lower-1st hampden",
              "externalID": "ocd-person/d8895862-a95b-4982-bb2e-31c8ffa4bf57",
              "geometry": null
            },
            "contactInfo": {
              "email": "todd.smola@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/TMS2"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-1st Hampden-lower-ocd-person-d8895862-a95b-4982-bb2e-31c8ffa4bf57": 0
        }
      },
      "15th Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/7a234dbf-2f9f-466f-8db1-44ac0f3975a8",
            "firstName": "Thomas",
            "lastName": "Vitolo",
            "fullName": "Tommy Vitolo",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/T_V1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "15th Norfolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-15th Norfolk-lower-ocd-person-7a234dbf-2f9f-466f-8db1-44ac0f3975a8",
              "builtID": "ma-lower-15th norfolk",
              "externalID": "ocd-person/7a234dbf-2f9f-466f-8db1-44ac0f3975a8",
              "geometry": null
            },
            "contactInfo": {
              "email": "tommy.vitolo@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/T_V1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-15th Norfolk-lower-ocd-person-7a234dbf-2f9f-466f-8db1-44ac0f3975a8": 0
        }
      },
      "18th Essex": {
        "members": [
          {
            "API_ID": "ocd-person/1e7b8d51-56fa-4faf-93e6-9b485812ddf9",
            "firstName": "Tram T.",
            "lastName": "Nguyen",
            "fullName": "Tram Nguyen",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/TTN1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "18th Essex",
              "chamber": "lower",
              "hashID": "SLDL-MA-18th Essex-lower-ocd-person-1e7b8d51-56fa-4faf-93e6-9b485812ddf9",
              "builtID": "ma-lower-18th essex",
              "externalID": "ocd-person/1e7b8d51-56fa-4faf-93e6-9b485812ddf9",
              "geometry": null
            },
            "contactInfo": {
              "email": "tram.nguyen@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/TTN1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-18th Essex-lower-ocd-person-1e7b8d51-56fa-4faf-93e6-9b485812ddf9": 0
        }
      },
      "2nd Berkshire": {
        "members": [
          {
            "API_ID": "ocd-person/1f444dfe-d3b3-4852-ab10-0682206d773d",
            "firstName": "Patricia",
            "lastName": "Farley-Bouvier",
            "fullName": "Tricia Farley-Bouvier",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/TFB1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "2nd Berkshire",
              "chamber": "lower",
              "hashID": "SLDL-MA-2nd Berkshire-lower-ocd-person-1f444dfe-d3b3-4852-ab10-0682206d773d",
              "builtID": "ma-lower-2nd berkshire",
              "externalID": "ocd-person/1f444dfe-d3b3-4852-ab10-0682206d773d",
              "geometry": null
            },
            "contactInfo": {
              "email": "tricia.farley-bouvier@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Pittsfield, MA",
              "phone1": null,
              "phone2": "413-442-4300",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/TFB1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-2nd Berkshire-lower-ocd-person-1f444dfe-d3b3-4852-ab10-0682206d773d": 0
        }
      },
      "17th Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/053aae1e-ae76-41b0-912c-39f27f3f6d6f",
            "firstName": "Vanna",
            "lastName": "Howard",
            "fullName": "Vanna Howard",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/V_H1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "17th Middlesex",
              "chamber": "lower",
              "hashID": "SLDL-MA-17th Middlesex-lower-ocd-person-053aae1e-ae76-41b0-912c-39f27f3f6d6f",
              "builtID": "ma-lower-17th middlesex",
              "externalID": "ocd-person/053aae1e-ae76-41b0-912c-39f27f3f6d6f",
              "geometry": null
            },
            "contactInfo": {
              "email": "vanna.howard@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/V_H1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-17th Middlesex-lower-ocd-person-053aae1e-ae76-41b0-912c-39f27f3f6d6f": 0
        }
      },
      "6th Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/04997a33-67a9-4535-95d2-c9a4c260519d",
            "firstName": "William C.",
            "lastName": "Galvin",
            "fullName": "Bill Galvin",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/WCG1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "6th Norfolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-6th Norfolk-lower-ocd-person-04997a33-67a9-4535-95d2-c9a4c260519d",
              "builtID": "ma-lower-6th norfolk",
              "externalID": "ocd-person/04997a33-67a9-4535-95d2-c9a4c260519d",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.galvin@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/WCG1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-6th Norfolk-lower-ocd-person-04997a33-67a9-4535-95d2-c9a4c260519d": 0
        }
      },
      "7th Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/1b7d1710-da71-46cd-9ee3-fc891556f3a9",
            "firstName": "William J.",
            "lastName": "Driscoll",
            "fullName": "Bill Driscoll",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/WJD1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "7th Norfolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-7th Norfolk-lower-ocd-person-1b7d1710-da71-46cd-9ee3-fc891556f3a9",
              "builtID": "ma-lower-7th norfolk",
              "externalID": "ocd-person/1b7d1710-da71-46cd-9ee3-fc891556f3a9",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.driscoll@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/WJD1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-7th Norfolk-lower-ocd-person-1b7d1710-da71-46cd-9ee3-fc891556f3a9": 0
        }
      },
      "10th Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/9f0c5984-accf-45a3-8922-07aa7ade0030",
            "firstName": "William F.",
            "lastName": "MacGregor",
            "fullName": "Billy MacGregor",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://images.squarespace-cdn.com/content/v1/64014cb72a76a83f08ddf74b/beb20812-27a1-4410-bc5a-e476990155c6/BZ3A6742.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "10th Suffolk",
              "chamber": "lower",
              "hashID": "SLDL-MA-10th Suffolk-lower-ocd-person-9f0c5984-accf-45a3-8922-07aa7ade0030",
              "builtID": "ma-lower-10th suffolk",
              "externalID": "ocd-person/9f0c5984-accf-45a3-8922-07aa7ade0030",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.macgregor@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/WFM1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-10th Suffolk-lower-ocd-person-9f0c5984-accf-45a3-8922-07aa7ade0030": 0
        }
      },
      "10th Bristol": {
        "members": [
          {
            "API_ID": "ocd-person/95fef9c7-c5c1-415c-95ef-94e4d87e3a72",
            "firstName": "William M.",
            "lastName": "Straus",
            "fullName": "Bill Straus",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/WMS1.jpg",
            "title": "MA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "10th Bristol",
              "chamber": "lower",
              "hashID": "SLDL-MA-10th Bristol-lower-ocd-person-95fef9c7-c5c1-415c-95ef-94e4d87e3a72",
              "builtID": "ma-lower-10th bristol",
              "externalID": "ocd-person/95fef9c7-c5c1-415c-95ef-94e4d87e3a72",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.straus@mahouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/WMS1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MA-10th Bristol-lower-ocd-person-95fef9c7-c5c1-415c-95ef-94e4d87e3a72": 0
        }
      }
    },
    "SLDU": {
      "Hampden": {
        "members": [
          {
            "API_ID": "ocd-person/d2a82a0d-fbd1-4448-be2d-b786a7e11168",
            "firstName": "Adam",
            "lastName": "Gomez",
            "fullName": "Adam Gómez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/A_G0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Hampden",
              "chamber": "upper",
              "hashID": "SLDU-MA-Hampden-upper-ocd-person-d2a82a0d-fbd1-4448-be2d-b786a7e11168",
              "builtID": "ma-upper-hampden",
              "externalID": "ocd-person/d2a82a0d-fbd1-4448-be2d-b786a7e11168",
              "geometry": null
            },
            "contactInfo": {
              "email": "adam.gomez@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/A_G0"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Hampden-upper-ocd-person-d2a82a0d-fbd1-4448-be2d-b786a7e11168": 0
        }
      },
      "Second Essex and Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/49397268-3fc9-4c83-a385-f55be18597f4",
            "firstName": "Barry R.",
            "lastName": "Finegold",
            "fullName": "Barry Finegold",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/BRF0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Second Essex and Middlesex",
              "chamber": "upper",
              "hashID": "SLDU-MA-Second Essex and Middlesex-upper-ocd-person-49397268-3fc9-4c83-a385-f55be18597f4",
              "builtID": "ma-upper-second essex and middlesex",
              "externalID": "ocd-person/49397268-3fc9-4c83-a385-f55be18597f4",
              "geometry": null
            },
            "contactInfo": {
              "email": "barry.finegold@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/BRF0"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Second Essex and Middlesex-upper-ocd-person-49397268-3fc9-4c83-a385-f55be18597f4": 0
        }
      },
      "Third Essex": {
        "members": [
          {
            "API_ID": "ocd-person/77994b2c-f88c-4a5f-bf9d-7abd1875d8dc",
            "firstName": "Brendan P.",
            "lastName": "Crighton",
            "fullName": "Brendan Crighton",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/BPC0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Third Essex",
              "chamber": "upper",
              "hashID": "SLDU-MA-Third Essex-upper-ocd-person-77994b2c-f88c-4a5f-bf9d-7abd1875d8dc",
              "builtID": "ma-upper-third essex",
              "externalID": "ocd-person/77994b2c-f88c-4a5f-bf9d-7abd1875d8dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "brendan.crighton@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/BPC0"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Third Essex-upper-ocd-person-77994b2c-f88c-4a5f-bf9d-7abd1875d8dc": 0
        }
      },
      "First Essex and Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/429f87cf-f7a1-4201-ade4-9efeb9149d03",
            "firstName": "Bruce E.",
            "lastName": "Tarr",
            "fullName": "Bruce Tarr",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/BET0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "First Essex and Middlesex",
              "chamber": "upper",
              "hashID": "SLDU-MA-First Essex and Middlesex-upper-ocd-person-429f87cf-f7a1-4201-ade4-9efeb9149d03",
              "builtID": "ma-upper-first essex and middlesex",
              "externalID": "ocd-person/429f87cf-f7a1-4201-ade4-9efeb9149d03",
              "geometry": null
            },
            "contactInfo": {
              "email": "bruce.tarr@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/BET0",
                "https://www.tarrtalk.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-First Essex and Middlesex-upper-ocd-person-429f87cf-f7a1-4201-ade4-9efeb9149d03": 0
        }
      },
      "Fourth Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/456b0061-febd-4ff7-8547-42be0685526f",
            "firstName": "Cindy F.",
            "lastName": "Friedman",
            "fullName": "Cindy Friedman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/CFF0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Fourth Middlesex",
              "chamber": "upper",
              "hashID": "SLDU-MA-Fourth Middlesex-upper-ocd-person-456b0061-febd-4ff7-8547-42be0685526f",
              "builtID": "ma-upper-fourth middlesex",
              "externalID": "ocd-person/456b0061-febd-4ff7-8547-42be0685526f",
              "geometry": null
            },
            "contactInfo": {
              "email": "cindy.friedman@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/CFF0",
                "https://cindyfriedman.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Fourth Middlesex-upper-ocd-person-456b0061-febd-4ff7-8547-42be0685526f": 0
        }
      },
      "Norfolk and Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/ece82669-b6f6-4f0f-ae5d-7b238b1cd1dd",
            "firstName": "Cynthia Stone",
            "lastName": "Creem",
            "fullName": "Cynthia Creem",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/CSC0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Norfolk and Middlesex",
              "chamber": "upper",
              "hashID": "SLDU-MA-Norfolk and Middlesex-upper-ocd-person-ece82669-b6f6-4f0f-ae5d-7b238b1cd1dd",
              "builtID": "ma-upper-norfolk and middlesex",
              "externalID": "ocd-person/ece82669-b6f6-4f0f-ae5d-7b238b1cd1dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "cynthia.creem@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/CSC0",
                "https://www.senatorcindycreem.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Norfolk and Middlesex-upper-ocd-person-ece82669-b6f6-4f0f-ae5d-7b238b1cd1dd": 0
        }
      },
      "First Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/6ad38b3e-46dc-4367-b85a-26793ea9ff65",
            "firstName": "Edward J.",
            "lastName": "Kennedy",
            "fullName": "Ed Kennedy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/EDJ0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "First Middlesex",
              "chamber": "upper",
              "hashID": "SLDU-MA-First Middlesex-upper-ocd-person-6ad38b3e-46dc-4367-b85a-26793ea9ff65",
              "builtID": "ma-upper-first middlesex",
              "externalID": "ocd-person/6ad38b3e-46dc-4367-b85a-26793ea9ff65",
              "geometry": null
            },
            "contactInfo": {
              "email": "edward.kennedy@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/EDJ0",
                "https://senatoredkennedy.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-First Middlesex-upper-ocd-person-6ad38b3e-46dc-4367-b85a-26793ea9ff65": 0
        }
      },
      "Hampden, Hampshire and Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/b857e2f7-b65a-4d34-a680-50dfff745a16",
            "firstName": "Jacob R.",
            "lastName": "Oliveira",
            "fullName": "Jake Oliveira",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JRO0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Hampden, Hampshire and Worcester",
              "chamber": "upper",
              "hashID": "SLDU-MA-Hampden, Hampshire and Worcester-upper-ocd-person-b857e2f7-b65a-4d34-a680-50dfff745a16",
              "builtID": "ma-upper-hampden, hampshire and worcester",
              "externalID": "ocd-person/b857e2f7-b65a-4d34-a680-50dfff745a16",
              "geometry": null
            },
            "contactInfo": {
              "email": "jacob.oliveira@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "MA",
              "phone1": null,
              "phone2": "413-206-6524",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/JRO1",
                "https://malegislature.gov/Legislators/Profile/JRO0"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Hampden, Hampshire and Worcester-upper-ocd-person-b857e2f7-b65a-4d34-a680-50dfff745a16": 0
        }
      },
      "Middlesex and Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/23704722-a50c-4148-8f14-98c7d78324b9",
            "firstName": "James Bradley",
            "lastName": "Eldridge",
            "fullName": "Jamie Eldridge",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JBE0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Middlesex and Worcester",
              "chamber": "upper",
              "hashID": "SLDU-MA-Middlesex and Worcester-upper-ocd-person-23704722-a50c-4148-8f14-98c7d78324b9",
              "builtID": "ma-upper-middlesex and worcester",
              "externalID": "ocd-person/23704722-a50c-4148-8f14-98c7d78324b9",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.eldridge@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 106, 255 Main St., Marlborough, MA 01752",
              "phone1": null,
              "phone2": "978-460-8564",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/JBE0",
                "https://www.senatoreldridge.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Middlesex and Worcester-upper-ocd-person-23704722-a50c-4148-8f14-98c7d78324b9": 0
        }
      },
      "Fifth Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/92a770ae-a348-4f86-92ad-c4a8ebc568f6",
            "firstName": "Jason M.",
            "lastName": "Lewis",
            "fullName": "Jason Lewis",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/jml0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Fifth Middlesex",
              "chamber": "upper",
              "hashID": "SLDU-MA-Fifth Middlesex-upper-ocd-person-92a770ae-a348-4f86-92ad-c4a8ebc568f6",
              "builtID": "ma-upper-fifth middlesex",
              "externalID": "ocd-person/92a770ae-a348-4f86-92ad-c4a8ebc568f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.lewis@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/jml0",
                "https://malegislature.gov/Legislators/Profile/JML0",
                "https://senatorjasonlewis.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Fifth Middlesex-upper-ocd-person-92a770ae-a348-4f86-92ad-c4a8ebc568f6": 0
        }
      },
      "Second Essex": {
        "members": [
          {
            "API_ID": "ocd-person/d0956cb2-93df-4096-82ce-d39fbf0b9e36",
            "firstName": "Joan B.",
            "lastName": "Lovely",
            "fullName": "Joan Lovely",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JBL0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Second Essex",
              "chamber": "upper",
              "hashID": "SLDU-MA-Second Essex-upper-ocd-person-d0956cb2-93df-4096-82ce-d39fbf0b9e36",
              "builtID": "ma-upper-second essex",
              "externalID": "ocd-person/d0956cb2-93df-4096-82ce-d39fbf0b9e36",
              "geometry": null
            },
            "contactInfo": {
              "email": "joan.lovely@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/JBL0",
                "https://www.senatorjoanlovely.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Second Essex-upper-ocd-person-d0956cb2-93df-4096-82ce-d39fbf0b9e36": 0
        }
      },
      "Hampshire, Franklin and Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/2998b05b-b83e-4a9b-9659-a609ddc63a34",
            "firstName": "Joanne M.",
            "lastName": "Comerford",
            "fullName": "Jo Comerford",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JMC0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Hampshire, Franklin and Worcester",
              "chamber": "upper",
              "hashID": "SLDU-MA-Hampshire, Franklin and Worcester-upper-ocd-person-2998b05b-b83e-4a9b-9659-a609ddc63a34",
              "builtID": "ma-upper-hampshire, franklin and worcester",
              "externalID": "ocd-person/2998b05b-b83e-4a9b-9659-a609ddc63a34",
              "geometry": null
            },
            "contactInfo": {
              "email": "jo.comerford@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 116B and 116C, UMass Amherst Isenberg School of Management 121 Presidents Drive, Amherst, MA 01003",
              "phone1": null,
              "phone2": "413-367-4656",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/JMC0",
                "https://senatorjocomerford.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Hampshire, Franklin and Worcester-upper-ocd-person-2998b05b-b83e-4a9b-9659-a609ddc63a34": 0
        }
      },
      "Hampden and Hampshire": {
        "members": [
          {
            "API_ID": "ocd-person/a37a3f66-83a2-4a7e-90e3-8de41be7262d",
            "firstName": "John Christopher",
            "lastName": "Velis",
            "fullName": "John Velis",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/jcv1.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Hampden and Hampshire",
              "chamber": "upper",
              "hashID": "SLDU-MA-Hampden and Hampshire-upper-ocd-person-a37a3f66-83a2-4a7e-90e3-8de41be7262d",
              "builtID": "ma-upper-hampden and hampshire",
              "externalID": "ocd-person/a37a3f66-83a2-4a7e-90e3-8de41be7262d",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.velis@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/jcv1",
                "https://malegislature.gov/Legislators/Profile/JCV0"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Hampden and Hampshire-upper-ocd-person-a37a3f66-83a2-4a7e-90e3-8de41be7262d": 0
        }
      },
      "Worcester and Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/5a333327-70bb-4b95-9cfd-fadd701e9348",
            "firstName": "John Joseph",
            "lastName": "Cronin",
            "fullName": "John Cronin",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JJC0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Worcester and Middlesex",
              "chamber": "upper",
              "hashID": "SLDU-MA-Worcester and Middlesex-upper-ocd-person-5a333327-70bb-4b95-9cfd-fadd701e9348",
              "builtID": "ma-upper-worcester and middlesex",
              "externalID": "ocd-person/5a333327-70bb-4b95-9cfd-fadd701e9348",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.cronin@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/JJC0"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Worcester and Middlesex-upper-ocd-person-5a333327-70bb-4b95-9cfd-fadd701e9348": 0
        }
      },
      "Norfolk and Plymouth": {
        "members": [
          {
            "API_ID": "ocd-person/7be04e69-85c6-44e4-a933-88c59c0675a5",
            "firstName": "John F.",
            "lastName": "Keenan",
            "fullName": "John Keenan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JFK0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Norfolk and Plymouth",
              "chamber": "upper",
              "hashID": "SLDU-MA-Norfolk and Plymouth-upper-ocd-person-7be04e69-85c6-44e4-a933-88c59c0675a5",
              "builtID": "ma-upper-norfolk and plymouth",
              "externalID": "ocd-person/7be04e69-85c6-44e4-a933-88c59c0675a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.keenan@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/JFK0",
                "https://senatorjohnkeenan.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Norfolk and Plymouth-upper-ocd-person-7be04e69-85c6-44e4-a933-88c59c0675a5": 0
        }
      },
      "Cape and Islands": {
        "members": [
          {
            "API_ID": "ocd-person/5a0688ef-c0cd-469d-bd3c-fe9848724387",
            "firstName": "Julian Andre",
            "lastName": "Cyr",
            "fullName": "Julian Cyr",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/JAC0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Cape and Islands",
              "chamber": "upper",
              "hashID": "SLDU-MA-Cape and Islands-upper-ocd-person-5a0688ef-c0cd-469d-bd3c-fe9848724387",
              "builtID": "ma-upper-cape and islands",
              "externalID": "ocd-person/5a0688ef-c0cd-469d-bd3c-fe9848724387",
              "geometry": null
            },
            "contactInfo": {
              "email": "julian.cyr@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/JAC0",
                "https://www.senatorcyr.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Cape and Islands-upper-ocd-person-5a0688ef-c0cd-469d-bd3c-fe9848724387": 0
        }
      },
      "Middlesex and Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/e48879ef-ed74-4f93-8e5b-c0f3752d4fd7",
            "firstName": "Karen Eileen",
            "lastName": "Spilka",
            "fullName": "Karen Spilka",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/KES0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Middlesex and Norfolk",
              "chamber": "upper",
              "hashID": "SLDU-MA-Middlesex and Norfolk-upper-ocd-person-e48879ef-ed74-4f93-8e5b-c0f3752d4fd7",
              "builtID": "ma-upper-middlesex and norfolk",
              "externalID": "ocd-person/e48879ef-ed74-4f93-8e5b-c0f3752d4fd7",
              "geometry": null
            },
            "contactInfo": {
              "email": "karen.spilka@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/KES0",
                "https://karenspilka.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Middlesex and Norfolk-upper-ocd-person-e48879ef-ed74-4f93-8e5b-c0f3752d4fd7": 0
        }
      },
      "Second Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/703d6d4e-3a1d-4a2e-82e3-3c9693b75953",
            "firstName": "Elizabeth",
            "lastName": "Miranda",
            "fullName": "Liz Miranda",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/L%20M0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Second Suffolk",
              "chamber": "upper",
              "hashID": "SLDU-MA-Second Suffolk-upper-ocd-person-703d6d4e-3a1d-4a2e-82e3-3c9693b75953",
              "builtID": "ma-upper-second suffolk",
              "externalID": "ocd-person/703d6d4e-3a1d-4a2e-82e3-3c9693b75953",
              "geometry": null
            },
            "contactInfo": {
              "email": "liz.miranda@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/L_M2",
                "https://malegislature.gov/Legislators/Profile/L M0",
                "https://malegislature.gov/Legislators/Profile/L%20M0"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Second Suffolk-upper-ocd-person-703d6d4e-3a1d-4a2e-82e3-3c9693b75953": 0
        }
      },
      "Third Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/5278711f-55ec-4a67-acc9-edd1db1c4e8c",
            "firstName": "Lydia Marie",
            "lastName": "Edwards",
            "fullName": "Lydia Edwards",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/LME0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Third Suffolk",
              "chamber": "upper",
              "hashID": "SLDU-MA-Third Suffolk-upper-ocd-person-5278711f-55ec-4a67-acc9-edd1db1c4e8c",
              "builtID": "ma-upper-third suffolk",
              "externalID": "ocd-person/5278711f-55ec-4a67-acc9-edd1db1c4e8c",
              "geometry": null
            },
            "contactInfo": {
              "email": "lydia.edwards@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 413-C, 24 Beacon St., Boston, MA 02133",
              "phone1": null,
              "phone2": "617-722-1634",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/LME0"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Third Suffolk-upper-ocd-person-5278711f-55ec-4a67-acc9-edd1db1c4e8c": 0
        }
      },
      "Third Bristol and Plymouth": {
        "members": [
          {
            "API_ID": "ocd-person/9e8c3405-d8ed-4838-a376-d19c357f4cb4",
            "firstName": "Marc R.",
            "lastName": "Pacheco",
            "fullName": "Marc Pacheco",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/MRP0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Third Bristol and Plymouth",
              "chamber": "upper",
              "hashID": "SLDU-MA-Third Bristol and Plymouth-upper-ocd-person-9e8c3405-d8ed-4838-a376-d19c357f4cb4",
              "builtID": "ma-upper-third bristol and plymouth",
              "externalID": "ocd-person/9e8c3405-d8ed-4838-a376-d19c357f4cb4",
              "geometry": null
            },
            "contactInfo": {
              "email": "marc.pacheco@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Taunton, MA",
              "phone1": null,
              "phone2": "508-822-3000",
              "fax1": null,
              "fax2": "508-880-6084",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MRP0"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Third Bristol and Plymouth-upper-ocd-person-9e8c3405-d8ed-4838-a376-d19c357f4cb4": 0
        }
      },
      "Second Bristol and Plymouth": {
        "members": [
          {
            "API_ID": "ocd-person/31600b44-797b-4baf-80aa-0762b4f0aff5",
            "firstName": "Mark C.",
            "lastName": "Montigny",
            "fullName": "Mark Montigny",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/MCM0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Second Bristol and Plymouth",
              "chamber": "upper",
              "hashID": "SLDU-MA-Second Bristol and Plymouth-upper-ocd-person-31600b44-797b-4baf-80aa-0762b4f0aff5",
              "builtID": "ma-upper-second bristol and plymouth",
              "externalID": "ocd-person/31600b44-797b-4baf-80aa-0762b4f0aff5",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.montigny@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 305, 888 Purchase St., New Bedford, MA 02740",
              "phone1": null,
              "phone2": "508-984-1474",
              "fax1": null,
              "fax2": "508-984-1590",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MCM0"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Second Bristol and Plymouth-upper-ocd-person-31600b44-797b-4baf-80aa-0762b4f0aff5": 0
        }
      },
      "Second Plymouth and Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/c3cdd26f-1314-4366-89f6-17c4c540bce9",
            "firstName": "Michael Daniels",
            "lastName": "Brady",
            "fullName": "Mike Brady",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/MDB0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Second Plymouth and Norfolk",
              "chamber": "upper",
              "hashID": "SLDU-MA-Second Plymouth and Norfolk-upper-ocd-person-c3cdd26f-1314-4366-89f6-17c4c540bce9",
              "builtID": "ma-upper-second plymouth and norfolk",
              "externalID": "ocd-person/c3cdd26f-1314-4366-89f6-17c4c540bce9",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.brady@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MDB0"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Second Plymouth and Norfolk-upper-ocd-person-c3cdd26f-1314-4366-89f6-17c4c540bce9": 0
        }
      },
      "Norfolk and Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/6a82d675-3496-48af-88cf-23c26355b01f",
            "firstName": "Michael F.",
            "lastName": "Rush",
            "fullName": "Mike Rush",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/MFR0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Norfolk and Suffolk",
              "chamber": "upper",
              "hashID": "SLDU-MA-Norfolk and Suffolk-upper-ocd-person-6a82d675-3496-48af-88cf-23c26355b01f",
              "builtID": "ma-upper-norfolk and suffolk",
              "externalID": "ocd-person/6a82d675-3496-48af-88cf-23c26355b01f",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.rush@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/MFR0"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Norfolk and Suffolk-upper-ocd-person-6a82d675-3496-48af-88cf-23c26355b01f": 0
        }
      },
      "Third Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/2cf31d35-c3e8-4627-a534-08dce38078db",
            "firstName": "Michael John",
            "lastName": "Barrett",
            "fullName": "Mike Barrett",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/MJB0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Third Middlesex",
              "chamber": "upper",
              "hashID": "SLDU-MA-Third Middlesex-upper-ocd-person-2cf31d35-c3e8-4627-a534-08dce38078db",
              "builtID": "ma-upper-third middlesex",
              "externalID": "ocd-person/2cf31d35-c3e8-4627-a534-08dce38078db",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.barrett@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/MJB0",
                "https://senatormikebarrett.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Third Middlesex-upper-ocd-person-2cf31d35-c3e8-4627-a534-08dce38078db": 0
        }
      },
      "First Bristol and Plymouth": {
        "members": [
          {
            "API_ID": "ocd-person/af0d6647-3a7b-48d0-971c-bde55a75e78c",
            "firstName": "Michael J.",
            "lastName": "Rodrigues",
            "fullName": "Michael Rodrigues",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/MJR0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "First Bristol and Plymouth",
              "chamber": "upper",
              "hashID": "SLDU-MA-First Bristol and Plymouth-upper-ocd-person-af0d6647-3a7b-48d0-971c-bde55a75e78c",
              "builtID": "ma-upper-first bristol and plymouth",
              "externalID": "ocd-person/af0d6647-3a7b-48d0-971c-bde55a75e78c",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.rodrigues@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 235, 1 Government Center, Fall River, MA 02722",
              "phone1": null,
              "phone2": "508-646-0650",
              "fax1": null,
              "fax2": "508-646-0656",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/MJR0",
                "https://www.senatorrodrigues.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-First Bristol and Plymouth-upper-ocd-person-af0d6647-3a7b-48d0-971c-bde55a75e78c": 0
        }
      },
      "Second Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/7b48bd48-8d27-4dd8-958d-703571efe150",
            "firstName": "Michael O.",
            "lastName": "Moore",
            "fullName": "Mike Moore",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/MOM0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Second Worcester",
              "chamber": "upper",
              "hashID": "SLDU-MA-Second Worcester-upper-ocd-person-7b48bd48-8d27-4dd8-958d-703571efe150",
              "builtID": "ma-upper-second worcester",
              "externalID": "ocd-person/7b48bd48-8d27-4dd8-958d-703571efe150",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.moore@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "36 N. Quinsigamond Ave., Shrewsbury, MA 01545",
              "phone1": null,
              "phone2": "508-757-0323 ext. 13",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/MOM0",
                "https://www.senatormikemoore.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Second Worcester-upper-ocd-person-7b48bd48-8d27-4dd8-958d-703571efe150": 0
        }
      },
      "First Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/8ca6ea6c-1121-40b3-a5cf-9b55dfe6a2b0",
            "firstName": "Nicholas",
            "lastName": "Collins",
            "fullName": "Nick Collins",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/N_C0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "First Suffolk",
              "chamber": "upper",
              "hashID": "SLDU-MA-First Suffolk-upper-ocd-person-8ca6ea6c-1121-40b3-a5cf-9b55dfe6a2b0",
              "builtID": "ma-upper-first suffolk",
              "externalID": "ocd-person/8ca6ea6c-1121-40b3-a5cf-9b55dfe6a2b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "nick.collins@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/N_C0"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-First Suffolk-upper-ocd-person-8ca6ea6c-1121-40b3-a5cf-9b55dfe6a2b0": 0
        }
      },
      "Second Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/6de50ed2-4fe8-4b98-894d-0bc391c876b5",
            "firstName": "Patricia Deats",
            "lastName": "Jehlen",
            "fullName": "Pat Jehlen",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/PDJ0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Second Middlesex",
              "chamber": "upper",
              "hashID": "SLDU-MA-Second Middlesex-upper-ocd-person-6de50ed2-4fe8-4b98-894d-0bc391c876b5",
              "builtID": "ma-upper-second middlesex",
              "externalID": "ocd-person/6de50ed2-4fe8-4b98-894d-0bc391c876b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "patricia.jehlen@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/PDJ0",
                "https://www.patjehlen.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Second Middlesex-upper-ocd-person-6de50ed2-4fe8-4b98-894d-0bc391c876b5": 0
        }
      },
      "First Plymouth and Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/493988da-4de8-49a0-8d6c-090097e89920",
            "firstName": "Patrick M.",
            "lastName": "O'Connor",
            "fullName": "Patrick O'Connor",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/PMO.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "First Plymouth and Norfolk",
              "chamber": "upper",
              "hashID": "SLDU-MA-First Plymouth and Norfolk-upper-ocd-person-493988da-4de8-49a0-8d6c-090097e89920",
              "builtID": "ma-upper-first plymouth and norfolk",
              "externalID": "ocd-person/493988da-4de8-49a0-8d6c-090097e89920",
              "geometry": null
            },
            "contactInfo": {
              "email": "patrick.oconnor@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/PMO"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-First Plymouth and Norfolk-upper-ocd-person-493988da-4de8-49a0-8d6c-090097e89920": 0
        }
      },
      "Bristol and Norfolk": {
        "members": [
          {
            "API_ID": "ocd-person/b390af07-1fa2-4516-b8f0-b6bd1b62121a",
            "firstName": "Paul R.",
            "lastName": "Feeney",
            "fullName": "Paul Feeney",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/PRF0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Bristol and Norfolk",
              "chamber": "upper",
              "hashID": "SLDU-MA-Bristol and Norfolk-upper-ocd-person-b390af07-1fa2-4516-b8f0-b6bd1b62121a",
              "builtID": "ma-upper-bristol and norfolk",
              "externalID": "ocd-person/b390af07-1fa2-4516-b8f0-b6bd1b62121a",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.feeney@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/PRF0"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Bristol and Norfolk-upper-ocd-person-b390af07-1fa2-4516-b8f0-b6bd1b62121a": 0
        }
      },
      "Berkshire, Hampden, Franklin and Hampshire": {
        "members": [
          {
            "API_ID": "ocd-person/fd5aaac8-48f5-42c2-82a8-abaf6c5de650",
            "firstName": "Paul W.",
            "lastName": "Mark",
            "fullName": "Paul Mark",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/PWM0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Berkshire, Hampden, Franklin and Hampshire",
              "chamber": "upper",
              "hashID": "SLDU-MA-Berkshire, Hampden, Franklin and Hampshire-upper-ocd-person-fd5aaac8-48f5-42c2-82a8-abaf6c5de650",
              "builtID": "ma-upper-berkshire, hampden, franklin and hampshire",
              "externalID": "ocd-person/fd5aaac8-48f5-42c2-82a8-abaf6c5de650",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.mark@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "773 Tyler St., Pittsfield, MA 01201",
              "phone1": null,
              "phone2": "413-464-5635",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/PWM1",
                "https://malegislature.gov/Legislators/Profile/PWM0"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Berkshire, Hampden, Franklin and Hampshire-upper-ocd-person-fd5aaac8-48f5-42c2-82a8-abaf6c5de650": 0
        }
      },
      "First Essex": {
        "members": [
          {
            "API_ID": "ocd-person/1d33d1e7-dae9-4e98-aa9e-626b0d711d9a",
            "firstName": "Pavel M.",
            "lastName": "Payano",
            "fullName": "Pavel Payano",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/PMP0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "First Essex",
              "chamber": "upper",
              "hashID": "SLDU-MA-First Essex-upper-ocd-person-1d33d1e7-dae9-4e98-aa9e-626b0d711d9a",
              "builtID": "ma-upper-first essex",
              "externalID": "ocd-person/1d33d1e7-dae9-4e98-aa9e-626b0d711d9a",
              "geometry": null
            },
            "contactInfo": {
              "email": "pavel.payano@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/PMP0"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-First Essex-upper-ocd-person-1d33d1e7-dae9-4e98-aa9e-626b0d711d9a": 0
        }
      },
      "Worcester and Hampshire": {
        "members": [
          {
            "API_ID": "ocd-person/c674060b-4568-43fa-b72d-0921f4876713",
            "firstName": "Peter J.",
            "lastName": "Durant",
            "fullName": "Peter Durant",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/PJD0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Worcester and Hampshire",
              "chamber": "upper",
              "hashID": "SLDU-MA-Worcester and Hampshire-upper-ocd-person-c674060b-4568-43fa-b72d-0921f4876713",
              "builtID": "ma-upper-worcester and hampshire",
              "externalID": "ocd-person/c674060b-4568-43fa-b72d-0921f4876713",
              "geometry": null
            },
            "contactInfo": {
              "email": "peter.durant@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/PJD0"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Worcester and Hampshire-upper-ocd-person-c674060b-4568-43fa-b72d-0921f4876713": 0
        }
      },
      "Norfolk, Worcester and Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/fb97b139-c0dd-41b5-ae52-159528f47b11",
            "firstName": "Rebecca Lynne",
            "lastName": "Rausch",
            "fullName": "Becca Rausch",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/RLR0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Norfolk, Worcester and Middlesex",
              "chamber": "upper",
              "hashID": "SLDU-MA-Norfolk, Worcester and Middlesex-upper-ocd-person-fb97b139-c0dd-41b5-ae52-159528f47b11",
              "builtID": "ma-upper-norfolk, worcester and middlesex",
              "externalID": "ocd-person/fb97b139-c0dd-41b5-ae52-159528f47b11",
              "geometry": null
            },
            "contactInfo": {
              "email": "becca.rausch@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/RLR0",
                "https://www.beccarauschma.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Norfolk, Worcester and Middlesex-upper-ocd-person-fb97b139-c0dd-41b5-ae52-159528f47b11": 0
        }
      },
      "First Worcester": {
        "members": [
          {
            "API_ID": "ocd-person/2fb1c0e0-edaa-4b55-9bbb-b6fe6b3e5dcc",
            "firstName": "Robyn K.",
            "lastName": "Kennedy",
            "fullName": "Robyn Kennedy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/RKK0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "First Worcester",
              "chamber": "upper",
              "hashID": "SLDU-MA-First Worcester-upper-ocd-person-2fb1c0e0-edaa-4b55-9bbb-b6fe6b3e5dcc",
              "builtID": "ma-upper-first worcester",
              "externalID": "ocd-person/2fb1c0e0-edaa-4b55-9bbb-b6fe6b3e5dcc",
              "geometry": null
            },
            "contactInfo": {
              "email": "robyn.kennedy@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/RKK0"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-First Worcester-upper-ocd-person-2fb1c0e0-edaa-4b55-9bbb-b6fe6b3e5dcc": 0
        }
      },
      "Worcester and Hampden": {
        "members": [
          {
            "API_ID": "ocd-person/edebef62-78d9-4f8b-a94d-65a29dbc09f6",
            "firstName": "Ryan C.",
            "lastName": "Fattman",
            "fullName": "Ryan Fattman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://malegislature.gov/Legislators/Profile/170/RCF0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Worcester and Hampden",
              "chamber": "upper",
              "hashID": "SLDU-MA-Worcester and Hampden-upper-ocd-person-edebef62-78d9-4f8b-a94d-65a29dbc09f6",
              "builtID": "ma-upper-worcester and hampden",
              "externalID": "ocd-person/edebef62-78d9-4f8b-a94d-65a29dbc09f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryan.fattman@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/RCF0"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Worcester and Hampden-upper-ocd-person-edebef62-78d9-4f8b-a94d-65a29dbc09f6": 0
        }
      },
      "Middlesex and Suffolk": {
        "members": [
          {
            "API_ID": "ocd-person/d691b834-a68d-44da-8140-9582ee37d8a6",
            "firstName": "Sal N.",
            "lastName": "DiDomenico",
            "fullName": "Sal DiDomenico",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/SND0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Middlesex and Suffolk",
              "chamber": "upper",
              "hashID": "SLDU-MA-Middlesex and Suffolk-upper-ocd-person-d691b834-a68d-44da-8140-9582ee37d8a6",
              "builtID": "ma-upper-middlesex and suffolk",
              "externalID": "ocd-person/d691b834-a68d-44da-8140-9582ee37d8a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "sal.didomenico@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/SND0",
                "https://www.senatordidomenico.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Middlesex and Suffolk-upper-ocd-person-d691b834-a68d-44da-8140-9582ee37d8a6": 0
        }
      },
      "Plymouth and Barnstable": {
        "members": [
          {
            "API_ID": "ocd-person/ade9765f-c80e-4127-adc4-cde294a455c6",
            "firstName": "Susan Lynn",
            "lastName": "Moran",
            "fullName": "Susan Moran",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/SLM0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Plymouth and Barnstable",
              "chamber": "upper",
              "hashID": "SLDU-MA-Plymouth and Barnstable-upper-ocd-person-ade9765f-c80e-4127-adc4-cde294a455c6",
              "builtID": "ma-upper-plymouth and barnstable",
              "externalID": "ocd-person/ade9765f-c80e-4127-adc4-cde294a455c6",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.moran@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/SLM0",
                "https://www.senatorsusanmoran.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Plymouth and Barnstable-upper-ocd-person-ade9765f-c80e-4127-adc4-cde294a455c6": 0
        }
      },
      "Norfolk, Plymouth and Bristol": {
        "members": [
          {
            "API_ID": "ocd-person/0db5ab85-b9c0-4b48-a0b4-d5944bab0a2d",
            "firstName": "Walter F.",
            "lastName": "Timilty",
            "fullName": "Walter Timilty",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/WFT0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Norfolk, Plymouth and Bristol",
              "chamber": "upper",
              "hashID": "SLDU-MA-Norfolk, Plymouth and Bristol-upper-ocd-person-0db5ab85-b9c0-4b48-a0b4-d5944bab0a2d",
              "builtID": "ma-upper-norfolk, plymouth and bristol",
              "externalID": "ocd-person/0db5ab85-b9c0-4b48-a0b4-d5944bab0a2d",
              "geometry": null
            },
            "contactInfo": {
              "email": "walter.timilty@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malegislature.gov/Legislators/Profile/WFT0"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Norfolk, Plymouth and Bristol-upper-ocd-person-0db5ab85-b9c0-4b48-a0b4-d5944bab0a2d": 0
        }
      },
      "Suffolk and Middlesex": {
        "members": [
          {
            "API_ID": "ocd-person/94929da6-2219-4a44-99c3-d6561eead9f5",
            "firstName": "William N.",
            "lastName": "Brownsberger",
            "fullName": "Will Brownsberger",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://malegislature.gov/Legislators/Profile/170/WNB0.jpg",
            "title": "MA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MA",
              "stateFull": "Massachusetts",
              "district": "Suffolk and Middlesex",
              "chamber": "upper",
              "hashID": "SLDU-MA-Suffolk and Middlesex-upper-ocd-person-94929da6-2219-4a44-99c3-d6561eead9f5",
              "builtID": "ma-upper-suffolk and middlesex",
              "externalID": "ocd-person/94929da6-2219-4a44-99c3-d6561eead9f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.brownsberger@masenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://malegislature.gov/Legislators/Profile/WNB0",
                "https://willbrownsberger.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MA-Suffolk and Middlesex-upper-ocd-person-94929da6-2219-4a44-99c3-d6561eead9f5": 0
        }
      }
    }
  },
  "MI": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "MI",
          "stateFull": "Michigan",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "MI",
            "stateFull": "Michigan",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-MI---",
            "builtID": "mi--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-MI---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "P000595",
          "in_office": true,
          "firstName": "Gary",
          "lastName": "Peters",
          "middleName": null,
          "fullName": "Gary C. Peters",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/P000595.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "MI",
            "stateFull": "Michigan",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-MI---P000595",
            "builtID": "mi--",
            "externalID": "P000595",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.peters.senate.gov/contact/email-gary",
            "address1": "724 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-6221",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenGaryPeters",
            "youtube": "RepGaryPeters",
            "instagram": null,
            "facebook": "SenGaryPeters",
            "urls": ["https://www.peters.senate.gov"],
            "rss_url": null
          },
          "title": "MI Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/P000595.json",
          "govtrack_id": "412305",
          "cspan_id": "50199",
          "votesmart_id": "8749",
          "icpsr_id": "20923",
          "crp_id": "N00029277",
          "google_entity_id": "/m/02x0lnt",
          "state_rank": "junior",
          "lis_id": "S380",
          "suffix": null,
          "date_of_birth": "1958-12-01",
          "leadership_role": null,
          "fec_candidate_id": "H8MI09068",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "9",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 4,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 0.72,
          "votes_with_party_pct": 98.72,
          "votes_against_party_pct": 1.28,
          "ocd_id": "ocd-division/country:us/state:mi"
        },
        {
          "API_ID": "S000770",
          "in_office": true,
          "firstName": "Debbie",
          "lastName": "Stabenow",
          "middleName": null,
          "fullName": "Debbie Stabenow",
          "gender": "F",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/S000770.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "MI",
            "stateFull": "Michigan",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-MI---S000770",
            "builtID": "mi--",
            "externalID": "S000770",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.stabenow.senate.gov/contact",
            "address1": "731 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-4822",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenStabenow",
            "youtube": "senatorstabenow",
            "instagram": null,
            "facebook": "SenatorStabenow",
            "urls": ["https://www.stabenow.senate.gov"],
            "rss_url": null
          },
          "title": "MI Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/S000770.json",
          "govtrack_id": "300093",
          "cspan_id": "45451",
          "votesmart_id": "515",
          "icpsr_id": "29732",
          "crp_id": "N00004118",
          "google_entity_id": "/m/01xh64",
          "state_rank": "senior",
          "lis_id": "S284",
          "suffix": null,
          "date_of_birth": "1950-04-29",
          "leadership_role": null,
          "fec_candidate_id": "S8MI00281",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "23",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 17,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 3.07,
          "votes_with_party_pct": 99.25,
          "votes_against_party_pct": 0.75,
          "ocd_id": "ocd-division/country:us/state:mi"
        }
      ],
      "hash": { "SENATE-MI---P000595": 0, "SENATE-MI---S000770": 1 }
    },
    "HOUSE": {
      "10": {
        "members": [
          {
            "API_ID": "J000307",
            "in_office": true,
            "firstName": "John",
            "lastName": "James",
            "middleName": null,
            "fullName": "John James",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/J000307.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "10",
              "chamber": "",
              "hashID": "HOUSE-MI-10--J000307",
              "builtID": "mi--10",
              "externalID": "J000307",
              "geometry": null,
              "geoid": "2610"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1319 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4961",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://james.house.gov/"],
              "rss_url": null
            },
            "title": "MI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/J000307.json",
            "govtrack_id": "456907",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1981-06-08",
            "leadership_role": null,
            "fec_candidate_id": "H2MI10150",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 21,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.99,
            "votes_with_party_pct": 85.42,
            "votes_against_party_pct": 12.61,
            "ocd_id": "ocd-division/country:us/state:mi/cd:10",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MI-10--J000307": 0 }
      },
      "11": {
        "members": [
          {
            "API_ID": "S001215",
            "in_office": true,
            "firstName": "Haley",
            "lastName": "Stevens",
            "middleName": null,
            "fullName": "Haley M. Stevens",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001215.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "11",
              "chamber": "",
              "hashID": "HOUSE-MI-11--S001215",
              "builtID": "mi--11",
              "externalID": "S001215",
              "geometry": null,
              "geoid": "2611"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2411 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-8171",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepHaleyStevens",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://stevens.house.gov"],
              "rss_url": null
            },
            "title": "MI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001215.json",
            "govtrack_id": "412786",
            "cspan_id": null,
            "votesmart_id": "181092",
            "icpsr_id": null,
            "crp_id": "N00040915",
            "google_entity_id": "/g/11gzqmm20k",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1983-06-24",
            "leadership_role": null,
            "fec_candidate_id": "H8MI11254",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 1,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.09,
            "votes_with_party_pct": 96.08,
            "votes_against_party_pct": 2.01,
            "ocd_id": "ocd-division/country:us/state:mi/cd:11",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MI-11--S001215": 0 }
      },
      "12": {
        "members": [
          {
            "API_ID": "T000481",
            "in_office": true,
            "firstName": "Rashida",
            "lastName": "Tlaib",
            "middleName": null,
            "fullName": "Rashida Tlaib",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/T000481.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "12",
              "chamber": "",
              "hashID": "HOUSE-MI-12--T000481",
              "builtID": "mi--12",
              "externalID": "T000481",
              "geometry": null,
              "geoid": "2612"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2438 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5126",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRashida",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://tlaib.house.gov"],
              "rss_url": null
            },
            "title": "MI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000481.json",
            "govtrack_id": "412787",
            "cspan_id": null,
            "votesmart_id": "105368",
            "icpsr_id": null,
            "crp_id": "N00042649",
            "google_entity_id": "/m/059_x6c",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-07-24",
            "leadership_role": null,
            "fec_candidate_id": "H8MI13250",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 24,
            "total_present": 4,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 2.27,
            "votes_with_party_pct": 88.56,
            "votes_against_party_pct": 9.48,
            "ocd_id": "ocd-division/country:us/state:mi/cd:12",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MI-12--T000481": 0 }
      },
      "13": {
        "members": [
          {
            "API_ID": "T000488",
            "in_office": true,
            "firstName": "Shri",
            "lastName": "Thanedar",
            "middleName": null,
            "fullName": "Shri Thanedar",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/T000488.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "13",
              "chamber": "",
              "hashID": "HOUSE-MI-13--T000488",
              "builtID": "mi--13",
              "externalID": "T000488",
              "geometry": null,
              "geoid": "2613"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1039 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5802",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepShriThanedar",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://thanedar.house.gov/"],
              "rss_url": null
            },
            "title": "MI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000488.json",
            "govtrack_id": "456908",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1955-02-22",
            "leadership_role": null,
            "fec_candidate_id": "H2MI13204",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 1,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.09,
            "votes_with_party_pct": 95.79,
            "votes_against_party_pct": 2.3,
            "ocd_id": "ocd-division/country:us/state:mi/cd:13",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MI-13--T000488": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "B001301",
            "in_office": true,
            "firstName": "Jack",
            "lastName": "Bergman",
            "middleName": null,
            "fullName": "Jack Bergman",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001301.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-MI-01--B001301",
              "builtID": "mi--01",
              "externalID": "B001301",
              "geometry": null,
              "geoid": "2601"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "566 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-4735",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJackBergman",
              "youtube": "RepJackBergman",
              "instagram": null,
              "facebook": "RepJackBergman",
              "urls": ["https://bergman.house.gov"],
              "rss_url": null
            },
            "title": "MI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001301.json",
            "govtrack_id": "412709",
            "cspan_id": "1020529",
            "votesmart_id": "170172",
            "icpsr_id": "21704",
            "crp_id": "N00039533",
            "google_entity_id": "/m/05b2l3v",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1947-02-02",
            "leadership_role": null,
            "fec_candidate_id": "H6MI01226",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 13,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.23,
            "votes_with_party_pct": 92.86,
            "votes_against_party_pct": 5.19,
            "ocd_id": "ocd-division/country:us/state:mi/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MI-01--B001301": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "D000624",
            "in_office": true,
            "firstName": "Debbie",
            "lastName": "Dingell",
            "middleName": null,
            "fullName": "Debbie Dingell",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/D000624.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-MI-06--D000624",
              "builtID": "mi--06",
              "externalID": "D000624",
              "geometry": null,
              "geoid": "2606"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "102 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-4071",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDebDingell",
              "youtube": null,
              "instagram": null,
              "facebook": "RepDebbieDingell",
              "urls": ["https://debbiedingell.house.gov"],
              "rss_url": null
            },
            "title": "MI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000624.json",
            "govtrack_id": "412637",
            "cspan_id": "20818",
            "votesmart_id": "152482",
            "icpsr_id": "21529",
            "crp_id": "N00036149",
            "google_entity_id": "/m/03m4188",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1953-11-23",
            "leadership_role": null,
            "fec_candidate_id": "H4MI12079",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 46,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 4.36,
            "votes_with_party_pct": 95.8,
            "votes_against_party_pct": 2.2,
            "ocd_id": "ocd-division/country:us/state:mi/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MI-06--D000624": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "H001058",
            "in_office": true,
            "firstName": "Bill",
            "lastName": "Huizenga",
            "middleName": null,
            "fullName": "Bill Huizenga",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/H001058.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-MI-04--H001058",
              "builtID": "mi--04",
              "externalID": "H001058",
              "geometry": null,
              "geoid": "2604"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2232 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4401",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepHuizenga",
              "youtube": "RepHuizenga",
              "instagram": null,
              "facebook": "RepHuizenga",
              "urls": ["https://huizenga.house.gov"],
              "rss_url": "https://huizenga.house.gov/news/rss.aspx"
            },
            "title": "MI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001058.json",
            "govtrack_id": "412437",
            "cspan_id": "1033765",
            "votesmart_id": "38351",
            "icpsr_id": "21142",
            "crp_id": "N00030673",
            "google_entity_id": "/m/05b0j1w",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1969-01-31",
            "leadership_role": null,
            "fec_candidate_id": "H0MI02094",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 12,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.14,
            "votes_with_party_pct": 93.64,
            "votes_against_party_pct": 4.5,
            "ocd_id": "ocd-division/country:us/state:mi/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MI-04--H001058": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "K000380",
            "in_office": true,
            "firstName": "Dan",
            "lastName": "Kildee",
            "middleName": null,
            "fullName": "Daniel T. Kildee",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/K000380.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-MI-08--K000380",
              "builtID": "mi--08",
              "externalID": "K000380",
              "geometry": null,
              "geoid": "2608"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "200 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3611",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDanKildee",
              "youtube": "RepDanKildee",
              "instagram": null,
              "facebook": "RepDanKildee",
              "urls": ["https://dankildee.house.gov"],
              "rss_url": "https://dankildee.house.gov/rss.xml"
            },
            "title": "MI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000380.json",
            "govtrack_id": "412546",
            "cspan_id": "623723",
            "votesmart_id": "136102",
            "icpsr_id": "21372",
            "crp_id": "N00033395",
            "google_entity_id": "/m/0n4c3yt",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1958-08-11",
            "leadership_role": null,
            "fec_candidate_id": "H2MI05119",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 42,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 3.98,
            "votes_with_party_pct": 93.82,
            "votes_against_party_pct": 4.29,
            "ocd_id": "ocd-division/country:us/state:mi/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MI-08--K000380": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "M001136",
            "in_office": true,
            "firstName": "Lisa",
            "lastName": "McClain",
            "middleName": null,
            "fullName": "Lisa C. McClain",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001136.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-MI-09--M001136",
              "builtID": "mi--09",
              "externalID": "M001136",
              "geometry": null,
              "geoid": "2609"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "444 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2106",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLisaMcClain",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://mcclain.house.gov"],
              "rss_url": null
            },
            "title": "MI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001136.json",
            "govtrack_id": "456827",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00045730",
            "google_entity_id": "/g/11j1xf64qr",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1966-04-07",
            "leadership_role": null,
            "fec_candidate_id": "H0MI10287",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 36,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 3.41,
            "votes_with_party_pct": 93.99,
            "votes_against_party_pct": 4,
            "ocd_id": "ocd-division/country:us/state:mi/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MI-09--M001136": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "M001194",
            "in_office": true,
            "firstName": "John",
            "lastName": "Moolenaar",
            "middleName": null,
            "fullName": "John R. Moolenaar",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001194.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-MI-02--M001194",
              "builtID": "mi--02",
              "externalID": "M001194",
              "geometry": null,
              "geoid": "2602"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "246 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3561",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMoolenaar",
              "youtube": null,
              "instagram": null,
              "facebook": "RepMoolenaar",
              "urls": ["https://moolenaar.house.gov"],
              "rss_url": "https://moolenaar.house.gov/rss.xml"
            },
            "title": "MI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001194.json",
            "govtrack_id": "412634",
            "cspan_id": "76660",
            "votesmart_id": "37676",
            "icpsr_id": "21526",
            "crp_id": "N00036275",
            "google_entity_id": "/m/0z6rww4",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1961-05-08",
            "leadership_role": null,
            "fec_candidate_id": "H4MI04126",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 7,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.66,
            "votes_with_party_pct": 92.7,
            "votes_against_party_pct": 5.45,
            "ocd_id": "ocd-division/country:us/state:mi/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MI-02--M001194": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "S001221",
            "in_office": true,
            "firstName": "Hillary",
            "lastName": "Scholten",
            "middleName": null,
            "fullName": "Hillary J. Scholten",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001221.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-MI-03--S001221",
              "builtID": "mi--03",
              "externalID": "S001221",
              "geometry": null,
              "geoid": "2603"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1317 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3831",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://scholten.house.gov/"],
              "rss_url": null
            },
            "title": "MI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001221.json",
            "govtrack_id": "456906",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1982-02-22",
            "leadership_role": null,
            "fec_candidate_id": "H0MI03316",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 8,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.76,
            "votes_with_party_pct": 92.87,
            "votes_against_party_pct": 5.2,
            "ocd_id": "ocd-division/country:us/state:mi/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MI-03--S001221": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "S001208",
            "in_office": true,
            "firstName": "Elissa",
            "lastName": "Slotkin",
            "middleName": null,
            "fullName": "Elissa Slotkin",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001208.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-MI-07--S001208",
              "builtID": "mi--07",
              "externalID": "S001208",
              "geometry": null,
              "geoid": "2607"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2245 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4872",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepSlotkin",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://slotkin.house.gov"],
              "rss_url": null
            },
            "title": "MI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001208.json",
            "govtrack_id": "412784",
            "cspan_id": null,
            "votesmart_id": "181080",
            "icpsr_id": null,
            "crp_id": "N00041357",
            "google_entity_id": "/g/11dfr3z7r1",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-07-10",
            "leadership_role": null,
            "fec_candidate_id": "H8MI08102",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 3,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.28,
            "votes_with_party_pct": 93,
            "votes_against_party_pct": 5.08,
            "ocd_id": "ocd-division/country:us/state:mi/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MI-07--S001208": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "W000798",
            "in_office": true,
            "firstName": "Tim",
            "lastName": "Walberg",
            "middleName": null,
            "fullName": "Tim Walberg",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/W000798.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-MI-05--W000798",
              "builtID": "mi--05",
              "externalID": "W000798",
              "geometry": null,
              "geoid": "2605"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2266 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6276",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepWalberg",
              "youtube": "RepWalberg",
              "instagram": null,
              "facebook": "RepWalberg",
              "urls": ["https://walberg.house.gov"],
              "rss_url": "https://walberg.house.gov/rss.xml"
            },
            "title": "MI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000798.json",
            "govtrack_id": "412213",
            "cspan_id": "1022844",
            "votesmart_id": "8618",
            "icpsr_id": "20725",
            "crp_id": "N00026368",
            "google_entity_id": "/m/0d7n3m",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1951-04-12",
            "leadership_role": null,
            "fec_candidate_id": "H4MI07103",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.57,
            "votes_with_party_pct": 93.29,
            "votes_against_party_pct": 4.86,
            "ocd_id": "ocd-division/country:us/state:mi/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MI-05--W000798": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/38d2f8c4-f40c-44b7-a2dd-57a4d5375572",
            "firstName": "Tyrone",
            "lastName": "Carter",
            "fullName": "Tyrone Carter",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://housedems.com/sites/default/files/Images/Representative_Images/D006_Tyrone_Carter/006_Carter.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-MI-1-lower-ocd-person-38d2f8c4-f40c-44b7-a2dd-57a4d5375572",
              "builtID": "mi-lower-1",
              "externalID": "ocd-person/38d2f8c4-f40c-44b7-a2dd-57a4d5375572",
              "geometry": null
            },
            "contactInfo": {
              "email": "tyronecarter@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.com/tyrone-carter",
                "https://housedems.com/tyrone-carter/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-1-lower-ocd-person-38d2f8c4-f40c-44b7-a2dd-57a4d5375572": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/4d548157-c00e-4131-bf2b-5838ffc5281a",
            "firstName": "Tullio",
            "lastName": "Liberati",
            "fullName": "Tullio Liberati",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-MI-2-lower-ocd-person-4d548157-c00e-4131-bf2b-5838ffc5281a",
              "builtID": "mi-lower-2",
              "externalID": "ocd-person/4d548157-c00e-4131-bf2b-5838ffc5281a",
              "geometry": null
            },
            "contactInfo": {
              "email": "tullioliberati@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/tullio-liberati/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-2-lower-ocd-person-4d548157-c00e-4131-bf2b-5838ffc5281a": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/788d408d-cb80-43fd-802a-4778a930894d",
            "firstName": "Alabas",
            "lastName": "Farhat",
            "fullName": "Alabas Farhat",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-MI-3-lower-ocd-person-788d408d-cb80-43fd-802a-4778a930894d",
              "builtID": "mi-lower-3",
              "externalID": "ocd-person/788d408d-cb80-43fd-802a-4778a930894d",
              "geometry": null
            },
            "contactInfo": {
              "email": "alabasfarhat@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Alabas-Farhat/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-3-lower-ocd-person-788d408d-cb80-43fd-802a-4778a930894d": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/1b71cbce-bf12-4009-b1cd-79da2f22b9aa",
            "firstName": "Karen",
            "lastName": "Whitsett",
            "fullName": "Karen Whitsett",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.com/sites/default/files/Images/Representative_Images/D009_Karen_Whitsett/009_Whitsett.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-MI-4-lower-ocd-person-1b71cbce-bf12-4009-b1cd-79da2f22b9aa",
              "builtID": "mi-lower-4",
              "externalID": "ocd-person/1b71cbce-bf12-4009-b1cd-79da2f22b9aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "karenwhitsett@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.com/whitsett",
                "https://housedems.com/karen-whitsett/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-4-lower-ocd-person-1b71cbce-bf12-4009-b1cd-79da2f22b9aa": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/2c6f93a7-f08a-4ad4-9424-cdd857401e6e",
            "firstName": "Natalie",
            "lastName": "Price",
            "fullName": "Natalie Price",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-MI-5-lower-ocd-person-2c6f93a7-f08a-4ad4-9424-cdd857401e6e",
              "builtID": "mi-lower-5",
              "externalID": "ocd-person/2c6f93a7-f08a-4ad4-9424-cdd857401e6e",
              "geometry": null
            },
            "contactInfo": {
              "email": "natalieprice@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Natalie-Price/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-5-lower-ocd-person-2c6f93a7-f08a-4ad4-9424-cdd857401e6e": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/ae5a3a48-9948-4c6c-95c2-c2d0915fd866",
            "firstName": "Regina",
            "lastName": "Weiss",
            "fullName": "Regina Weiss",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-MI-6-lower-ocd-person-ae5a3a48-9948-4c6c-95c2-c2d0915fd866",
              "builtID": "mi-lower-6",
              "externalID": "ocd-person/ae5a3a48-9948-4c6c-95c2-c2d0915fd866",
              "geometry": null
            },
            "contactInfo": {
              "email": "reginaweiss@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/regina-weiss/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-6-lower-ocd-person-ae5a3a48-9948-4c6c-95c2-c2d0915fd866": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/6751fa70-04e4-45e1-b3c5-6e0a5a397f8f",
            "firstName": "Helena",
            "lastName": "Scott",
            "fullName": "Helena Scott",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-MI-7-lower-ocd-person-6751fa70-04e4-45e1-b3c5-6e0a5a397f8f",
              "builtID": "mi-lower-7",
              "externalID": "ocd-person/6751fa70-04e4-45e1-b3c5-6e0a5a397f8f",
              "geometry": null
            },
            "contactInfo": {
              "email": "helenascott@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/helena-scott/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-7-lower-ocd-person-6751fa70-04e4-45e1-b3c5-6e0a5a397f8f": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/b34263be-1f2e-49db-a261-b30e76ba790a",
            "firstName": "Mike",
            "lastName": "McFall",
            "fullName": "Mike McFall",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-MI-8-lower-ocd-person-b34263be-1f2e-49db-a261-b30e76ba790a",
              "builtID": "mi-lower-8",
              "externalID": "ocd-person/b34263be-1f2e-49db-a261-b30e76ba790a",
              "geometry": null
            },
            "contactInfo": {
              "email": "mikemcfall@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Mike-McFall/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-8-lower-ocd-person-b34263be-1f2e-49db-a261-b30e76ba790a": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/5a87d637-4763-44a1-b763-a40c9a30b67e",
            "firstName": "Abraham",
            "lastName": "Aiyash",
            "fullName": "Abraham Aiyash",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-MI-9-lower-ocd-person-5a87d637-4763-44a1-b763-a40c9a30b67e",
              "builtID": "mi-lower-9",
              "externalID": "ocd-person/5a87d637-4763-44a1-b763-a40c9a30b67e",
              "geometry": null
            },
            "contactInfo": {
              "email": "abrahamaiyash@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/abraham-aiyash/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-9-lower-ocd-person-5a87d637-4763-44a1-b763-a40c9a30b67e": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/19b8cf37-0b98-4194-9d2c-db6da3fae35c",
            "firstName": "Joseph",
            "lastName": "Tate",
            "fullName": "Joe Tate",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://housedems.com/sites/default/files/Images/Representative_Images/D002_Joe_Tate/002_Tate.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-MI-10-lower-ocd-person-19b8cf37-0b98-4194-9d2c-db6da3fae35c",
              "builtID": "mi-lower-10",
              "externalID": "ocd-person/19b8cf37-0b98-4194-9d2c-db6da3fae35c",
              "geometry": null
            },
            "contactInfo": {
              "email": "joetate@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.com/tate",
                "https://housedems.com/joe-tate/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-10-lower-ocd-person-19b8cf37-0b98-4194-9d2c-db6da3fae35c": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/8c335851-e7f6-40d6-b83c-fe413f21934e",
            "firstName": "Veronica",
            "lastName": "Paiz",
            "fullName": "Veronica Paiz",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-MI-11-lower-ocd-person-8c335851-e7f6-40d6-b83c-fe413f21934e",
              "builtID": "mi-lower-11",
              "externalID": "ocd-person/8c335851-e7f6-40d6-b83c-fe413f21934e",
              "geometry": null
            },
            "contactInfo": {
              "email": "veronicapaiz@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Veronica-Paiz/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-11-lower-ocd-person-8c335851-e7f6-40d6-b83c-fe413f21934e": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/e1d87954-4ee4-4668-9075-c6240dbe8c9b",
            "firstName": "Kimberly L.",
            "lastName": "Edwards",
            "fullName": "Kimberly Edwards",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-MI-12-lower-ocd-person-e1d87954-4ee4-4668-9075-c6240dbe8c9b",
              "builtID": "mi-lower-12",
              "externalID": "ocd-person/e1d87954-4ee4-4668-9075-c6240dbe8c9b",
              "geometry": null
            },
            "contactInfo": {
              "email": "kimberlyedwards@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Kimberly-Edwards/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-12-lower-ocd-person-e1d87954-4ee4-4668-9075-c6240dbe8c9b": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/cd44a574-079b-4fd8-82cd-0daea8d7ff96",
            "firstName": "Mai",
            "lastName": "Xiong",
            "fullName": "Mai Xiong",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/MaiXiong.jpeg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-MI-13-lower-ocd-person-cd44a574-079b-4fd8-82cd-0daea8d7ff96",
              "builtID": "mi-lower-13",
              "externalID": "ocd-person/cd44a574-079b-4fd8-82cd-0daea8d7ff96",
              "geometry": null
            },
            "contactInfo": {
              "email": "maixiong@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/mai-xiong/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-13-lower-ocd-person-cd44a574-079b-4fd8-82cd-0daea8d7ff96": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/36e87b55-c5db-4331-8088-8fe1dbc544f9",
            "firstName": "Donavan",
            "lastName": "McKinney",
            "fullName": "Donavan McKinney",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-MI-14-lower-ocd-person-36e87b55-c5db-4331-8088-8fe1dbc544f9",
              "builtID": "mi-lower-14",
              "externalID": "ocd-person/36e87b55-c5db-4331-8088-8fe1dbc544f9",
              "geometry": null
            },
            "contactInfo": {
              "email": "donavanmckinney@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Donavan-McKinney/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-14-lower-ocd-person-36e87b55-c5db-4331-8088-8fe1dbc544f9": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/3d6727ea-1a91-414d-aac4-ba312cbfaabc",
            "firstName": "Erin",
            "lastName": "Byrnes",
            "fullName": "Erin Byrnes",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-MI-15-lower-ocd-person-3d6727ea-1a91-414d-aac4-ba312cbfaabc",
              "builtID": "mi-lower-15",
              "externalID": "ocd-person/3d6727ea-1a91-414d-aac4-ba312cbfaabc",
              "geometry": null
            },
            "contactInfo": {
              "email": "erinbyrnes@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Erin-Byrnes/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-15-lower-ocd-person-3d6727ea-1a91-414d-aac4-ba312cbfaabc": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/ea254169-e403-4e29-ab06-8f40fb869ac2",
            "firstName": "Stephanie A.",
            "lastName": "Young",
            "fullName": "Stephanie Young",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-MI-16-lower-ocd-person-ea254169-e403-4e29-ab06-8f40fb869ac2",
              "builtID": "mi-lower-16",
              "externalID": "ocd-person/ea254169-e403-4e29-ab06-8f40fb869ac2",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephanieyoung@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/stephanie-young/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-16-lower-ocd-person-ea254169-e403-4e29-ab06-8f40fb869ac2": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/64f12acb-0356-4726-ab8e-0c9c1945d5b0",
            "firstName": "Laurie",
            "lastName": "Pohutsky",
            "fullName": "Laurie Pohutsky",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.com/sites/default/files/Images/Representative_Images/D019_Laurie_Pohutsky/019_Pohutsky.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-MI-17-lower-ocd-person-64f12acb-0356-4726-ab8e-0c9c1945d5b0",
              "builtID": "mi-lower-17",
              "externalID": "ocd-person/64f12acb-0356-4726-ab8e-0c9c1945d5b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "lauriepohutsky@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.com/pohutsky",
                "https://housedems.com/laurie-pohutsky/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-17-lower-ocd-person-64f12acb-0356-4726-ab8e-0c9c1945d5b0": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/d6da4c9b-26ef-4fa9-a1c6-a79d843abe09",
            "firstName": "Jason",
            "lastName": "Hoskins",
            "fullName": "Jason Hoskins",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-MI-18-lower-ocd-person-d6da4c9b-26ef-4fa9-a1c6-a79d843abe09",
              "builtID": "mi-lower-18",
              "externalID": "ocd-person/d6da4c9b-26ef-4fa9-a1c6-a79d843abe09",
              "geometry": null
            },
            "contactInfo": {
              "email": "jasonhoskins@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Jason-Hoskins/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-18-lower-ocd-person-d6da4c9b-26ef-4fa9-a1c6-a79d843abe09": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/5a99ab1c-62e3-4424-9bb1-310ffcecc3e3",
            "firstName": "Samantha",
            "lastName": "Steckloff",
            "fullName": "Samantha Steckloff",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-MI-19-lower-ocd-person-5a99ab1c-62e3-4424-9bb1-310ffcecc3e3",
              "builtID": "mi-lower-19",
              "externalID": "ocd-person/5a99ab1c-62e3-4424-9bb1-310ffcecc3e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "samanthasteckloff@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/samantha-steckloff/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-19-lower-ocd-person-5a99ab1c-62e3-4424-9bb1-310ffcecc3e3": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/3b59f672-58f4-4d87-8b6e-6f9cc63a4d4c",
            "firstName": "Noah",
            "lastName": "Arbit",
            "fullName": "Noah Arbit",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-MI-20-lower-ocd-person-3b59f672-58f4-4d87-8b6e-6f9cc63a4d4c",
              "builtID": "mi-lower-20",
              "externalID": "ocd-person/3b59f672-58f4-4d87-8b6e-6f9cc63a4d4c",
              "geometry": null
            },
            "contactInfo": {
              "email": "noaharbit@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Noah-Arbit/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-20-lower-ocd-person-3b59f672-58f4-4d87-8b6e-6f9cc63a4d4c": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/6f366c7a-8dab-4d39-90b0-3a25760d2234",
            "firstName": "Kelly",
            "lastName": "Breen",
            "fullName": "Kelly Breen",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-MI-21-lower-ocd-person-6f366c7a-8dab-4d39-90b0-3a25760d2234",
              "builtID": "mi-lower-21",
              "externalID": "ocd-person/6f366c7a-8dab-4d39-90b0-3a25760d2234",
              "geometry": null
            },
            "contactInfo": {
              "email": "kellybreen@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/kelly-breen/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-21-lower-ocd-person-6f366c7a-8dab-4d39-90b0-3a25760d2234": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/42a456fa-8979-468f-b2a6-30f81ff20299",
            "firstName": "Matt",
            "lastName": "Koleszar",
            "fullName": "Matt Koleszar",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://housedems.com/sites/default/files/Images/Representative_Images/D020_Matt_Koleszar/020_Koleszar.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-MI-22-lower-ocd-person-42a456fa-8979-468f-b2a6-30f81ff20299",
              "builtID": "mi-lower-22",
              "externalID": "ocd-person/42a456fa-8979-468f-b2a6-30f81ff20299",
              "geometry": null
            },
            "contactInfo": {
              "email": "mattkoleszar@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.com/koleszar",
                "https://housedems.com/matt-koleszar/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-22-lower-ocd-person-42a456fa-8979-468f-b2a6-30f81ff20299": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/9b0967d9-1238-4f90-8e03-233a17fc03e0",
            "firstName": "Jason T.",
            "lastName": "Morgan",
            "fullName": "Jason Morgan",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-MI-23-lower-ocd-person-9b0967d9-1238-4f90-8e03-233a17fc03e0",
              "builtID": "mi-lower-23",
              "externalID": "ocd-person/9b0967d9-1238-4f90-8e03-233a17fc03e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jasonmorgan@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Jason-Morgan/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-23-lower-ocd-person-9b0967d9-1238-4f90-8e03-233a17fc03e0": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/1a16bef8-b655-46da-a5c5-07a8c5569eed",
            "firstName": "Ranjeev",
            "lastName": "Puri",
            "fullName": "Ranjeev Puri",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-MI-24-lower-ocd-person-1a16bef8-b655-46da-a5c5-07a8c5569eed",
              "builtID": "mi-lower-24",
              "externalID": "ocd-person/1a16bef8-b655-46da-a5c5-07a8c5569eed",
              "geometry": null
            },
            "contactInfo": {
              "email": "ranjeevpuri@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/ranjeev-puri/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-24-lower-ocd-person-1a16bef8-b655-46da-a5c5-07a8c5569eed": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/8e6c8809-d207-477d-8bdc-6646878249c5",
            "firstName": "Peter Alexander",
            "lastName": "Herzberg",
            "fullName": "Peter Herzberg",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/PeterHerzberg.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-MI-25-lower-ocd-person-8e6c8809-d207-477d-8bdc-6646878249c5",
              "builtID": "mi-lower-25",
              "externalID": "ocd-person/8e6c8809-d207-477d-8bdc-6646878249c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "peterherzberg@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/peter-herzberg/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-25-lower-ocd-person-8e6c8809-d207-477d-8bdc-6646878249c5": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/07ff51f1-6cd0-4004-b618-bc70e5a2e551",
            "firstName": "Dylan",
            "lastName": "Wegela",
            "fullName": "Dylan Wegela",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-MI-26-lower-ocd-person-07ff51f1-6cd0-4004-b618-bc70e5a2e551",
              "builtID": "mi-lower-26",
              "externalID": "ocd-person/07ff51f1-6cd0-4004-b618-bc70e5a2e551",
              "geometry": null
            },
            "contactInfo": {
              "email": "dylanwegela@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Dylan-Wegela/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-26-lower-ocd-person-07ff51f1-6cd0-4004-b618-bc70e5a2e551": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/8a444713-86ab-4a98-b26f-3f19302f0348",
            "firstName": "Jaime",
            "lastName": "Churches",
            "fullName": "Jaime Churches",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-MI-27-lower-ocd-person-8a444713-86ab-4a98-b26f-3f19302f0348",
              "builtID": "mi-lower-27",
              "externalID": "ocd-person/8a444713-86ab-4a98-b26f-3f19302f0348",
              "geometry": null
            },
            "contactInfo": {
              "email": "jaimechurches@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Jaime-Churches/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-27-lower-ocd-person-8a444713-86ab-4a98-b26f-3f19302f0348": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/ea1e2bf8-dd08-4785-a7f7-48e7c9e8a53c",
            "firstName": "Jamie",
            "lastName": "Thompson",
            "fullName": "Jamie Thompson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Thompson-028.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-MI-28-lower-ocd-person-ea1e2bf8-dd08-4785-a7f7-48e7c9e8a53c",
              "builtID": "mi-lower-28",
              "externalID": "ocd-person/ea1e2bf8-dd08-4785-a7f7-48e7c9e8a53c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jamiethompson@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepJamieThompson/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-28-lower-ocd-person-ea1e2bf8-dd08-4785-a7f7-48e7c9e8a53c": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/af4a0996-bcf0-4e8f-9165-4290343e905f",
            "firstName": "James",
            "lastName": "DeSana",
            "fullName": "Jim DeSana",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/DeSana-029.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-MI-29-lower-ocd-person-af4a0996-bcf0-4e8f-9165-4290343e905f",
              "builtID": "mi-lower-29",
              "externalID": "ocd-person/af4a0996-bcf0-4e8f-9165-4290343e905f",
              "geometry": null
            },
            "contactInfo": {
              "email": "jamesdesana@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepJamesDeSana/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-29-lower-ocd-person-af4a0996-bcf0-4e8f-9165-4290343e905f": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/a9f36e83-23ad-4912-a183-8b1e93ef3e81",
            "firstName": "William T.",
            "lastName": "Bruck",
            "fullName": "Will Bruck",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Bruck-030.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-MI-30-lower-ocd-person-a9f36e83-23ad-4912-a183-8b1e93ef3e81",
              "builtID": "mi-lower-30",
              "externalID": "ocd-person/a9f36e83-23ad-4912-a183-8b1e93ef3e81",
              "geometry": null
            },
            "contactInfo": {
              "email": "williambruck@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepWilliamBruck/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-30-lower-ocd-person-a9f36e83-23ad-4912-a183-8b1e93ef3e81": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/50d75ced-0a70-4f88-aada-57015d799fd0",
            "firstName": "Regina",
            "lastName": "Miller",
            "fullName": "Reggie Miller",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-MI-31-lower-ocd-person-50d75ced-0a70-4f88-aada-57015d799fd0",
              "builtID": "mi-lower-31",
              "externalID": "ocd-person/50d75ced-0a70-4f88-aada-57015d799fd0",
              "geometry": null
            },
            "contactInfo": {
              "email": "reggiemiller@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Reggie-Miller/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-31-lower-ocd-person-50d75ced-0a70-4f88-aada-57015d799fd0": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/8560a009-9142-4824-bec4-67559d8d0add",
            "firstName": "Jimmie",
            "lastName": "Wilson",
            "fullName": "Jimmie Wilson",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-MI-32-lower-ocd-person-8560a009-9142-4824-bec4-67559d8d0add",
              "builtID": "mi-lower-32",
              "externalID": "ocd-person/8560a009-9142-4824-bec4-67559d8d0add",
              "geometry": null
            },
            "contactInfo": {
              "email": "jimmiewilson@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Jimmie-Wilson-Jr/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-32-lower-ocd-person-8560a009-9142-4824-bec4-67559d8d0add": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/ccfacab2-ec54-40f8-add5-2dfe02908055",
            "firstName": "Felicia",
            "lastName": "Brabec",
            "fullName": "Felicia Brabec",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-MI-33-lower-ocd-person-ccfacab2-ec54-40f8-add5-2dfe02908055",
              "builtID": "mi-lower-33",
              "externalID": "ocd-person/ccfacab2-ec54-40f8-add5-2dfe02908055",
              "geometry": null
            },
            "contactInfo": {
              "email": "feliciabrabec@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/felicia-brabec/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-33-lower-ocd-person-ccfacab2-ec54-40f8-add5-2dfe02908055": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/f87c906f-bf6e-41db-8ab6-cf736ba589d4",
            "firstName": "Dale W.",
            "lastName": "Zorn",
            "fullName": "Dale Zorn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Zorn-034.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-MI-34-lower-ocd-person-f87c906f-bf6e-41db-8ab6-cf736ba589d4",
              "builtID": "mi-lower-34",
              "externalID": "ocd-person/f87c906f-bf6e-41db-8ab6-cf736ba589d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "dalezorn@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.michigan.gov/zorn",
                "https://senate.michigan.gov/zorn",
                "https://www.SenatorDaleZorn.com/contact/",
                "https://gophouse.org/member/RepDaleZorn/posts"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-34-lower-ocd-person-f87c906f-bf6e-41db-8ab6-cf736ba589d4": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/154e0e09-4da4-449d-9ebe-58c0b720e513",
            "firstName": "Andrew F.",
            "lastName": "Fink",
            "fullName": "Andrew Fink",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2021/01/HS_FINK_0121_NSC_7667-scaled.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-MI-35-lower-ocd-person-154e0e09-4da4-449d-9ebe-58c0b720e513",
              "builtID": "mi-lower-35",
              "externalID": "ocd-person/154e0e09-4da4-449d-9ebe-58c0b720e513",
              "geometry": null
            },
            "contactInfo": {
              "email": "andrewfink@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://repfink.com",
                "https://gophouse.org/member/repandrewfink/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-35-lower-ocd-person-154e0e09-4da4-449d-9ebe-58c0b720e513": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/4dee484b-79f4-4ca2-92dc-2ed7e0623d6b",
            "firstName": "Stephen",
            "lastName": "Carra",
            "fullName": "Steve Carra",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2021/05/HS_CARRA_0221_NSC_2360-scaled.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-MI-36-lower-ocd-person-4dee484b-79f4-4ca2-92dc-2ed7e0623d6b",
              "builtID": "mi-lower-36",
              "externalID": "ocd-person/4dee484b-79f4-4ca2-92dc-2ed7e0623d6b",
              "geometry": null
            },
            "contactInfo": {
              "email": "stevecarra@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://repcarra.com",
                "https://gophouse.org/member/repstevecarra/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-36-lower-ocd-person-4dee484b-79f4-4ca2-92dc-2ed7e0623d6b": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/04a88313-6387-44ab-972c-b000b3c316f4",
            "firstName": "Brad",
            "lastName": "Paquette",
            "fullName": "Brad Paquette",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2020/07/HS_PAQUETTE_0119.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-MI-37-lower-ocd-person-04a88313-6387-44ab-972c-b000b3c316f4",
              "builtID": "mi-lower-37",
              "externalID": "ocd-person/04a88313-6387-44ab-972c-b000b3c316f4",
              "geometry": null
            },
            "contactInfo": {
              "email": "bradpaquette@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gophouse.org/representatives/southwest/paquette/",
                "https://gophouse.org/member/repbradpaquette/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-37-lower-ocd-person-04a88313-6387-44ab-972c-b000b3c316f4": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/fd51ab83-f1b7-4044-b794-871ba167b504",
            "firstName": "Joey",
            "lastName": "Andrews",
            "fullName": "Joey Andrews",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-MI-38-lower-ocd-person-fd51ab83-f1b7-4044-b794-871ba167b504",
              "builtID": "mi-lower-38",
              "externalID": "ocd-person/fd51ab83-f1b7-4044-b794-871ba167b504",
              "geometry": null
            },
            "contactInfo": {
              "email": "joeyandrews@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/joey-andrews/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-38-lower-ocd-person-fd51ab83-f1b7-4044-b794-871ba167b504": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/f749bf7e-46bc-482d-ac6e-60288f3b722e",
            "firstName": "Pauline",
            "lastName": "Wendzel",
            "fullName": "Pauline Wendzel",
            "gender": "Female",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2020/07/HS_WENDZEL_1218_DSC_9364.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-MI-39-lower-ocd-person-f749bf7e-46bc-482d-ac6e-60288f3b722e",
              "builtID": "mi-lower-39",
              "externalID": "ocd-person/f749bf7e-46bc-482d-ac6e-60288f3b722e",
              "geometry": null
            },
            "contactInfo": {
              "email": "paulinewendzel@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/reppaulinewendzel/about"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-39-lower-ocd-person-f749bf7e-46bc-482d-ac6e-60288f3b722e": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/4c46fce6-3e67-43a0-b396-5130a004c2a4",
            "firstName": "Christine",
            "lastName": "Morse",
            "fullName": "Christine Morse",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-MI-40-lower-ocd-person-4c46fce6-3e67-43a0-b396-5130a004c2a4",
              "builtID": "mi-lower-40",
              "externalID": "ocd-person/4c46fce6-3e67-43a0-b396-5130a004c2a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "christinemorse@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/christine-morse/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-40-lower-ocd-person-4c46fce6-3e67-43a0-b396-5130a004c2a4": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/5154033e-e367-449c-a719-9437a309e269",
            "firstName": "Julie M.",
            "lastName": "Rogers",
            "fullName": "Julie Rogers",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-MI-41-lower-ocd-person-5154033e-e367-449c-a719-9437a309e269",
              "builtID": "mi-lower-41",
              "externalID": "ocd-person/5154033e-e367-449c-a719-9437a309e269",
              "geometry": null
            },
            "contactInfo": {
              "email": "julierogers@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/julie-rogers/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-41-lower-ocd-person-5154033e-e367-449c-a719-9437a309e269": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/dc7344e8-b8bf-4f0d-924d-4f4b5ea40554",
            "firstName": "Matt",
            "lastName": "Hall",
            "fullName": "Matt Hall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2023/01/Leader-Matt-Hall-headshot-scaled.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-MI-42-lower-ocd-person-dc7344e8-b8bf-4f0d-924d-4f4b5ea40554",
              "builtID": "mi-lower-42",
              "externalID": "ocd-person/dc7344e8-b8bf-4f0d-924d-4f4b5ea40554",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthall@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gophouse.org/representatives/southwest/hall/",
                "https://gophouse.org/member/repmatthall/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-42-lower-ocd-person-dc7344e8-b8bf-4f0d-924d-4f4b5ea40554": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/6c955ba2-9448-43ae-8d7f-c3d76329ad60",
            "firstName": "Rachelle M.",
            "lastName": "Smit",
            "fullName": "Rachelle Smit",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Smit-043.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-MI-43-lower-ocd-person-6c955ba2-9448-43ae-8d7f-c3d76329ad60",
              "builtID": "mi-lower-43",
              "externalID": "ocd-person/6c955ba2-9448-43ae-8d7f-c3d76329ad60",
              "geometry": null
            },
            "contactInfo": {
              "email": "rachellesmit@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepRachelleSmit/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-43-lower-ocd-person-6c955ba2-9448-43ae-8d7f-c3d76329ad60": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/7ac8386b-fa1e-4f5d-aaeb-e5614e771141",
            "firstName": "James T.",
            "lastName": "Haadsma",
            "fullName": "Jim Haadsma",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://housedems.com/sites/default/files/Images/Representative_Images/D062_Jim_Haadsma/062_Haadsma.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-MI-44-lower-ocd-person-7ac8386b-fa1e-4f5d-aaeb-e5614e771141",
              "builtID": "mi-lower-44",
              "externalID": "ocd-person/7ac8386b-fa1e-4f5d-aaeb-e5614e771141",
              "geometry": null
            },
            "contactInfo": {
              "email": "jimhaadsma@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.com/haadsma",
                "https://housedems.com/jim-haadsma/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-44-lower-ocd-person-7ac8386b-fa1e-4f5d-aaeb-e5614e771141": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/ef5b60a6-d301-4433-8e85-6c14ba254011",
            "firstName": "Sarah Latoski",
            "lastName": "Lightner",
            "fullName": "Sarah Lightner",
            "gender": "Female",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2021/04/HS_Lightner_0421_NSC_0141-scaled.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-MI-45-lower-ocd-person-ef5b60a6-d301-4433-8e85-6c14ba254011",
              "builtID": "mi-lower-45",
              "externalID": "ocd-person/ef5b60a6-d301-4433-8e85-6c14ba254011",
              "geometry": null
            },
            "contactInfo": {
              "email": "sarahlightner@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gophouse.org/representatives/central/lightner/",
                "https://gophouse.org/member/repsarahlightner/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-45-lower-ocd-person-ef5b60a6-d301-4433-8e85-6c14ba254011": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/e21ee6f1-0fdc-4ee6-8432-6149de16046d",
            "firstName": "Kathy",
            "lastName": "Schmaltz",
            "fullName": "Kathy Schmaltz",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Schmaltz-046.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-MI-46-lower-ocd-person-e21ee6f1-0fdc-4ee6-8432-6149de16046d",
              "builtID": "mi-lower-46",
              "externalID": "ocd-person/e21ee6f1-0fdc-4ee6-8432-6149de16046d",
              "geometry": null
            },
            "contactInfo": {
              "email": "kathyschmaltz@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepKathySchmaltz/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-46-lower-ocd-person-e21ee6f1-0fdc-4ee6-8432-6149de16046d": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/2f75473d-b2e6-49fd-914e-1e552ebc38a6",
            "firstName": "Carrie Amber",
            "lastName": "Rheingans",
            "fullName": "Carrie Rheingans",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-MI-47-lower-ocd-person-2f75473d-b2e6-49fd-914e-1e552ebc38a6",
              "builtID": "mi-lower-47",
              "externalID": "ocd-person/2f75473d-b2e6-49fd-914e-1e552ebc38a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "carrierheingans@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Carrie-Rheingans/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-47-lower-ocd-person-2f75473d-b2e6-49fd-914e-1e552ebc38a6": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/99ac6698-e0f9-4ddf-9df6-2d82aa70468f",
            "firstName": "Jennifer Anne",
            "lastName": "Conlin",
            "fullName": "Jennifer Conlin",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-MI-48-lower-ocd-person-99ac6698-e0f9-4ddf-9df6-2d82aa70468f",
              "builtID": "mi-lower-48",
              "externalID": "ocd-person/99ac6698-e0f9-4ddf-9df6-2d82aa70468f",
              "geometry": null
            },
            "contactInfo": {
              "email": "jenniferconlin@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Jennifer-Conlin/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-48-lower-ocd-person-99ac6698-e0f9-4ddf-9df6-2d82aa70468f": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/3a9d71dc-da5b-4bd3-8062-c8387292dc52",
            "firstName": "Ann",
            "lastName": "Bollin",
            "fullName": "Ann Bollin",
            "gender": "Female",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2020/07/HS_AnnBollin_0119-scaled.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-MI-49-lower-ocd-person-3a9d71dc-da5b-4bd3-8062-c8387292dc52",
              "builtID": "mi-lower-49",
              "externalID": "ocd-person/3a9d71dc-da5b-4bd3-8062-c8387292dc52",
              "geometry": null
            },
            "contactInfo": {
              "email": "annbollin@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gophouse.org/representatives/southeast/bollin/",
                "https://gophouse.org/member/repannbollin/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-49-lower-ocd-person-3a9d71dc-da5b-4bd3-8062-c8387292dc52": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/f5b2c77c-88b8-4ea7-854a-789b660e5a91",
            "firstName": "Robert",
            "lastName": "Bezotte",
            "fullName": "Bob Bezotte",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-MI-50-lower-ocd-person-f5b2c77c-88b8-4ea7-854a-789b660e5a91",
              "builtID": "mi-lower-50",
              "externalID": "ocd-person/f5b2c77c-88b8-4ea7-854a-789b660e5a91",
              "geometry": null
            },
            "contactInfo": {
              "email": "robertbezotte@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://repbezotte.com",
                "https://gophouse.org/member/repbobbezotte/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-50-lower-ocd-person-f5b2c77c-88b8-4ea7-854a-789b660e5a91": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/241c969f-c72f-4acb-9a37-529e6ec0d955",
            "firstName": "Matthew",
            "lastName": "Maddock",
            "fullName": "Matt Maddock",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2023/01/Maddock-051.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-MI-51-lower-ocd-person-241c969f-c72f-4acb-9a37-529e6ec0d955",
              "builtID": "mi-lower-51",
              "externalID": "ocd-person/241c969f-c72f-4acb-9a37-529e6ec0d955",
              "geometry": null
            },
            "contactInfo": {
              "email": "mattmaddock@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gophouse.org/representatives/southeast/maddock/",
                "",
                "https://gophouse.org/member/repmattmaddock/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-51-lower-ocd-person-241c969f-c72f-4acb-9a37-529e6ec0d955": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/a93eaebe-bdac-4292-a28f-6a3b04efd191",
            "firstName": "Michael R.",
            "lastName": "Harris",
            "fullName": "Mike Harris",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2022/05/HS_HARRIS_0522_web-scaled.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-MI-52-lower-ocd-person-a93eaebe-bdac-4292-a28f-6a3b04efd191",
              "builtID": "mi-lower-52",
              "externalID": "ocd-person/a93eaebe-bdac-4292-a28f-6a3b04efd191",
              "geometry": null
            },
            "contactInfo": {
              "email": "mikeharris@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/repmikeharris/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-52-lower-ocd-person-a93eaebe-bdac-4292-a28f-6a3b04efd191": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/0019e62f-6671-4085-b2d3-55d3c4a23717",
            "firstName": "Brenda Joyce",
            "lastName": "Carter",
            "fullName": "Brenda Carter",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.com/sites/default/files/Images/Representative_Images/D029_Brenda_Carter/029_Carter.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-MI-53-lower-ocd-person-0019e62f-6671-4085-b2d3-55d3c4a23717",
              "builtID": "mi-lower-53",
              "externalID": "ocd-person/0019e62f-6671-4085-b2d3-55d3c4a23717",
              "geometry": null
            },
            "contactInfo": {
              "email": "brendacarter@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.com/brenda-carter",
                "https://housedems.com/brenda-carter/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-53-lower-ocd-person-0019e62f-6671-4085-b2d3-55d3c4a23717": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/4a5a14f9-56b4-4f7a-ad0c-bfe3750912a3",
            "firstName": "Donni",
            "lastName": "Steele",
            "fullName": "Donni Steele",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Steele-054.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-MI-54-lower-ocd-person-4a5a14f9-56b4-4f7a-ad0c-bfe3750912a3",
              "builtID": "mi-lower-54",
              "externalID": "ocd-person/4a5a14f9-56b4-4f7a-ad0c-bfe3750912a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "donnisteele@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepDonniSteele/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-54-lower-ocd-person-4a5a14f9-56b4-4f7a-ad0c-bfe3750912a3": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/a5bb89cb-ac42-47c0-bcc8-96bf18e4d865",
            "firstName": "Mark",
            "lastName": "Tisdel",
            "fullName": "Mark Tisdel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2021/01/HS_TISDEL_0121_NSC_7619-scaled.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-MI-55-lower-ocd-person-a5bb89cb-ac42-47c0-bcc8-96bf18e4d865",
              "builtID": "mi-lower-55",
              "externalID": "ocd-person/a5bb89cb-ac42-47c0-bcc8-96bf18e4d865",
              "geometry": null
            },
            "contactInfo": {
              "email": "marktisdel@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://reptisdel.com",
                "https://gophouse.org/member/repmarktisdel/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-55-lower-ocd-person-a5bb89cb-ac42-47c0-bcc8-96bf18e4d865": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/55a6fa48-006b-48ee-bffd-655ed7e56fce",
            "firstName": "Sharon",
            "lastName": "MacDonell",
            "fullName": "Sharon MacDonell",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-MI-56-lower-ocd-person-55a6fa48-006b-48ee-bffd-655ed7e56fce",
              "builtID": "mi-lower-56",
              "externalID": "ocd-person/55a6fa48-006b-48ee-bffd-655ed7e56fce",
              "geometry": null
            },
            "contactInfo": {
              "email": "sharonmacdonell@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Sharon-MacDonell/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-56-lower-ocd-person-55a6fa48-006b-48ee-bffd-655ed7e56fce": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/58e6e432-e6a2-44f4-8dda-cc3d8e01ca7e",
            "firstName": "Thomas",
            "lastName": "Kuhn",
            "fullName": "Tom Kuhn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Kuhn-057.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-MI-57-lower-ocd-person-58e6e432-e6a2-44f4-8dda-cc3d8e01ca7e",
              "builtID": "mi-lower-57",
              "externalID": "ocd-person/58e6e432-e6a2-44f4-8dda-cc3d8e01ca7e",
              "geometry": null
            },
            "contactInfo": {
              "email": "tomkuhn@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepTomKuhn/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-57-lower-ocd-person-58e6e432-e6a2-44f4-8dda-cc3d8e01ca7e": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/af52f259-fe60-4903-98b8-739a114a8deb",
            "firstName": "Nate",
            "lastName": "Shannon",
            "fullName": "Nate Shannon",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-MI-58-lower-ocd-person-af52f259-fe60-4903-98b8-739a114a8deb",
              "builtID": "mi-lower-58",
              "externalID": "ocd-person/af52f259-fe60-4903-98b8-739a114a8deb",
              "geometry": null
            },
            "contactInfo": {
              "email": "nateshannon@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/nate-shannon/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-58-lower-ocd-person-af52f259-fe60-4903-98b8-739a114a8deb": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/2e512bae-42f3-4ce8-b565-df48469da08a",
            "firstName": "Douglas Chester",
            "lastName": "Wozniak",
            "fullName": "Doug Wozniak",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.michigan.gov/_images/8th.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-MI-59-lower-ocd-person-2e512bae-42f3-4ce8-b565-df48469da08a",
              "builtID": "mi-lower-59",
              "externalID": "ocd-person/2e512bae-42f3-4ce8-b565-df48469da08a",
              "geometry": null
            },
            "contactInfo": {
              "email": "douglaswozniak@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatordougwozniak.com/",
                "https://www.senatordougwozniak.com/contact",
                "https://gophouse.org/member/repdougwozniak/posts"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-59-lower-ocd-person-2e512bae-42f3-4ce8-b565-df48469da08a": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/f66dcbe7-c834-4c23-83ca-38eb5d1a1ba9",
            "firstName": "Joseph",
            "lastName": "Aragona",
            "fullName": "Joe Aragona",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Aragona-060.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-MI-60-lower-ocd-person-f66dcbe7-c834-4c23-83ca-38eb5d1a1ba9",
              "builtID": "mi-lower-60",
              "externalID": "ocd-person/f66dcbe7-c834-4c23-83ca-38eb5d1a1ba9",
              "geometry": null
            },
            "contactInfo": {
              "email": "josepharagona@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepJosephAragona/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-60-lower-ocd-person-f66dcbe7-c834-4c23-83ca-38eb5d1a1ba9": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/7e4be084-8c26-421f-ab31-81893f7ac525",
            "firstName": "Denise",
            "lastName": "Mentzer",
            "fullName": "Denise Mentzer",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-MI-61-lower-ocd-person-7e4be084-8c26-421f-ab31-81893f7ac525",
              "builtID": "mi-lower-61",
              "externalID": "ocd-person/7e4be084-8c26-421f-ab31-81893f7ac525",
              "geometry": null
            },
            "contactInfo": {
              "email": "denisementzer@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Denise-Mentzer/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-61-lower-ocd-person-7e4be084-8c26-421f-ab31-81893f7ac525": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/81da0894-0569-4c8d-9c4e-befd39650fee",
            "firstName": "Alicia",
            "lastName": "St. Germaine",
            "fullName": "Alicia St. Germaine",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/St. Germaine-062.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-MI-62-lower-ocd-person-81da0894-0569-4c8d-9c4e-befd39650fee",
              "builtID": "mi-lower-62",
              "externalID": "ocd-person/81da0894-0569-4c8d-9c4e-befd39650fee",
              "geometry": null
            },
            "contactInfo": {
              "email": "aliciastgermaine@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepAliciaStGermaine/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-62-lower-ocd-person-81da0894-0569-4c8d-9c4e-befd39650fee": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/569bf706-64ce-4dd7-8639-886beb018d8c",
            "firstName": "Jay",
            "lastName": "DeBoyer",
            "fullName": "Jay DeBoyer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/DeBoyer-063.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-MI-63-lower-ocd-person-569bf706-64ce-4dd7-8639-886beb018d8c",
              "builtID": "mi-lower-63",
              "externalID": "ocd-person/569bf706-64ce-4dd7-8639-886beb018d8c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jaydeboyerr@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepJayDeBoyer/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-63-lower-ocd-person-569bf706-64ce-4dd7-8639-886beb018d8c": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/77456985-a7be-4384-9dfe-70373b8031f6",
            "firstName": "Andrew",
            "lastName": "Beeler",
            "fullName": "Andrew Beeler",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-MI-64-lower-ocd-person-77456985-a7be-4384-9dfe-70373b8031f6",
              "builtID": "mi-lower-64",
              "externalID": "ocd-person/77456985-a7be-4384-9dfe-70373b8031f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "andrewbeeler@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://repbeeler.com",
                "https://gophouse.org/member/repandrewbeeler/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-64-lower-ocd-person-77456985-a7be-4384-9dfe-70373b8031f6": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/fe33333e-a9ff-4279-ae47-1cc27703ee5c",
            "firstName": "Jaime",
            "lastName": "Greene",
            "fullName": "Jaime Greene",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Greene-065.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-MI-65-lower-ocd-person-fe33333e-a9ff-4279-ae47-1cc27703ee5c",
              "builtID": "mi-lower-65",
              "externalID": "ocd-person/fe33333e-a9ff-4279-ae47-1cc27703ee5c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jaimegreene@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepJaimeGreene/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-65-lower-ocd-person-fe33333e-a9ff-4279-ae47-1cc27703ee5c": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/f6df9fad-c6ec-4abf-b175-ac1e9d672759",
            "firstName": "Joshua",
            "lastName": "Schriver",
            "fullName": "Josh Schriver",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Schriver-066.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-MI-66-lower-ocd-person-f6df9fad-c6ec-4abf-b175-ac1e9d672759",
              "builtID": "mi-lower-66",
              "externalID": "ocd-person/f6df9fad-c6ec-4abf-b175-ac1e9d672759",
              "geometry": null
            },
            "contactInfo": {
              "email": "joshschriver@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepJoshSchriver/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-66-lower-ocd-person-f6df9fad-c6ec-4abf-b175-ac1e9d672759": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/67081474-baf6-465a-bcd2-b2b84ab5c36b",
            "firstName": "Philip A.",
            "lastName": "Green",
            "fullName": "Phil Green",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2022/06/HS_GREEN_0522_NSC5945-scaled.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-MI-67-lower-ocd-person-67081474-baf6-465a-bcd2-b2b84ab5c36b",
              "builtID": "mi-lower-67",
              "externalID": "ocd-person/67081474-baf6-465a-bcd2-b2b84ab5c36b",
              "geometry": null
            },
            "contactInfo": {
              "email": "repphilgreen@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gophouse.org/representatives/thumb/green/",
                "https://gophouse.org/member/repphilgreen/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-67-lower-ocd-person-67081474-baf6-465a-bcd2-b2b84ab5c36b": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/95434509-a8fd-4e25-9f04-a952416d6da3",
            "firstName": "David",
            "lastName": "Martin",
            "fullName": "David Martin",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-MI-68-lower-ocd-person-95434509-a8fd-4e25-9f04-a952416d6da3",
              "builtID": "mi-lower-68",
              "externalID": "ocd-person/95434509-a8fd-4e25-9f04-a952416d6da3",
              "geometry": null
            },
            "contactInfo": {
              "email": "davidmartin@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://repmartin.com",
                "https://gophouse.org/member/repdavidmartin/posts"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-68-lower-ocd-person-95434509-a8fd-4e25-9f04-a952416d6da3": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/124196c5-fa79-4958-97c1-364026ae5f36",
            "firstName": "Jasper R.",
            "lastName": "Martus",
            "fullName": "Jaz Martus",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-MI-69-lower-ocd-person-124196c5-fa79-4958-97c1-364026ae5f36",
              "builtID": "mi-lower-69",
              "externalID": "ocd-person/124196c5-fa79-4958-97c1-364026ae5f36",
              "geometry": null
            },
            "contactInfo": {
              "email": "jaspermartus@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Jasper-Martus/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-69-lower-ocd-person-124196c5-fa79-4958-97c1-364026ae5f36": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/7e3c6932-35bd-43f6-b1ca-d8fd6d5c0040",
            "firstName": "Cynthia Renay",
            "lastName": "Neeley",
            "fullName": "Cynthia Neeley",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-MI-70-lower-ocd-person-7e3c6932-35bd-43f6-b1ca-d8fd6d5c0040",
              "builtID": "mi-lower-70",
              "externalID": "ocd-person/7e3c6932-35bd-43f6-b1ca-d8fd6d5c0040",
              "geometry": null
            },
            "contactInfo": {
              "email": "cynthianeeley@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/cynthia-neeley/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-70-lower-ocd-person-7e3c6932-35bd-43f6-b1ca-d8fd6d5c0040": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/172b6e7c-3765-4978-bc03-3d19a92d5164",
            "firstName": "Brian",
            "lastName": "BeGole",
            "fullName": "Brian BeGole",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/BeGole-071.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-MI-71-lower-ocd-person-172b6e7c-3765-4978-bc03-3d19a92d5164",
              "builtID": "mi-lower-71",
              "externalID": "ocd-person/172b6e7c-3765-4978-bc03-3d19a92d5164",
              "geometry": null
            },
            "contactInfo": {
              "email": "brianbegole@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepBrianBeGole/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-71-lower-ocd-person-172b6e7c-3765-4978-bc03-3d19a92d5164": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/bdbada04-5616-4e9c-91db-f709e24d43f3",
            "firstName": "Michael",
            "lastName": "Mueller",
            "fullName": "Mike Mueller",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2020/07/HS_MULLER_1218_DSC_9358.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-MI-72-lower-ocd-person-bdbada04-5616-4e9c-91db-f709e24d43f3",
              "builtID": "mi-lower-72",
              "externalID": "ocd-person/bdbada04-5616-4e9c-91db-f709e24d43f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "mikemueller@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/repmikemueller/about"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-72-lower-ocd-person-bdbada04-5616-4e9c-91db-f709e24d43f3": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/192ea166-adcc-4d74-b497-2fd4db882a00",
            "firstName": "Julie",
            "lastName": "Brixie",
            "fullName": "Julie Brixie",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.com/sites/default/files/Images/Representative_Images/D069_Julie_Brixie/069_Brixie.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-MI-73-lower-ocd-person-192ea166-adcc-4d74-b497-2fd4db882a00",
              "builtID": "mi-lower-73",
              "externalID": "ocd-person/192ea166-adcc-4d74-b497-2fd4db882a00",
              "geometry": null
            },
            "contactInfo": {
              "email": "juliebrixie@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.com/brixie",
                "https://housedems.com/julie-brixie/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-73-lower-ocd-person-192ea166-adcc-4d74-b497-2fd4db882a00": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/f5c9b37d-39a5-4c63-a526-6f35f45cf5cf",
            "firstName": "Kara Henigan",
            "lastName": "Hope",
            "fullName": "Kara Hope",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.com/sites/default/files/Images/Representative_Images/D067_Kara_Hope/067_Hope.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-MI-74-lower-ocd-person-f5c9b37d-39a5-4c63-a526-6f35f45cf5cf",
              "builtID": "mi-lower-74",
              "externalID": "ocd-person/f5c9b37d-39a5-4c63-a526-6f35f45cf5cf",
              "geometry": null
            },
            "contactInfo": {
              "email": "karahope@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.com/hope",
                "https://housedems.com/kara-hope/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-74-lower-ocd-person-f5c9b37d-39a5-4c63-a526-6f35f45cf5cf": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/026c8d4d-bdd5-4b1f-838e-12c191323540",
            "firstName": "Penelope",
            "lastName": "Tsernoglou",
            "fullName": "Penelope Tsernoglou",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-MI-75-lower-ocd-person-026c8d4d-bdd5-4b1f-838e-12c191323540",
              "builtID": "mi-lower-75",
              "externalID": "ocd-person/026c8d4d-bdd5-4b1f-838e-12c191323540",
              "geometry": null
            },
            "contactInfo": {
              "email": "penelopetsernoglou@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Penelope-Tsernoglou/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-75-lower-ocd-person-026c8d4d-bdd5-4b1f-838e-12c191323540": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/0db39d07-a543-4e52-82b0-457f406855e8",
            "firstName": "Angela Brownell",
            "lastName": "Witwer",
            "fullName": "Angela Witwer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.com/sites/default/files/Images/Representative_Images/D071_Angela_Witwer/071_Witwer.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-MI-76-lower-ocd-person-0db39d07-a543-4e52-82b0-457f406855e8",
              "builtID": "mi-lower-76",
              "externalID": "ocd-person/0db39d07-a543-4e52-82b0-457f406855e8",
              "geometry": null
            },
            "contactInfo": {
              "email": "angelawitwer@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.com/witwer",
                "https://housedems.com/angela-witwer/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-76-lower-ocd-person-0db39d07-a543-4e52-82b0-457f406855e8": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/10e1b0b6-8c1d-4f2b-b29a-08770991b1d4",
            "firstName": "Emily",
            "lastName": "Dievendorf",
            "fullName": "Emily Dievendorf",
            "gender": "X",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-MI-77-lower-ocd-person-10e1b0b6-8c1d-4f2b-b29a-08770991b1d4",
              "builtID": "mi-lower-77",
              "externalID": "ocd-person/10e1b0b6-8c1d-4f2b-b29a-08770991b1d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "emilydievendorf@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Emily-Dievendorf/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-77-lower-ocd-person-10e1b0b6-8c1d-4f2b-b29a-08770991b1d4": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/37cbe31c-4d9d-4f77-847f-ce463e1bec58",
            "firstName": "Gina",
            "lastName": "Johnsen",
            "fullName": "Gina Johnsen",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Johnsen-078.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-MI-78-lower-ocd-person-37cbe31c-4d9d-4f77-847f-ce463e1bec58",
              "builtID": "mi-lower-78",
              "externalID": "ocd-person/37cbe31c-4d9d-4f77-847f-ce463e1bec58",
              "geometry": null
            },
            "contactInfo": {
              "email": "ginajohnsen@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepGinaJohnsen/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-78-lower-ocd-person-37cbe31c-4d9d-4f77-847f-ce463e1bec58": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/527d59db-a346-4a91-8f98-e1b57947b58c",
            "firstName": "Angela",
            "lastName": "Rigas",
            "fullName": "Angela Rigas",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Rigas-079.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-MI-79-lower-ocd-person-527d59db-a346-4a91-8f98-e1b57947b58c",
              "builtID": "mi-lower-79",
              "externalID": "ocd-person/527d59db-a346-4a91-8f98-e1b57947b58c",
              "geometry": null
            },
            "contactInfo": {
              "email": "angelarigas@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepAngelaRigas/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-79-lower-ocd-person-527d59db-a346-4a91-8f98-e1b57947b58c": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/a61c6338-85df-446c-854a-17991386d252",
            "firstName": "Philip",
            "lastName": "Skaggs",
            "fullName": "Phil Skaggs",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-MI-80-lower-ocd-person-a61c6338-85df-446c-854a-17991386d252",
              "builtID": "mi-lower-80",
              "externalID": "ocd-person/a61c6338-85df-446c-854a-17991386d252",
              "geometry": null
            },
            "contactInfo": {
              "email": "philskaggs@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Phil-Skaggs/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-80-lower-ocd-person-a61c6338-85df-446c-854a-17991386d252": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/85cde8af-86e7-4d74-9bf7-2cc79ccfab39",
            "firstName": "Rachel",
            "lastName": "Hood",
            "fullName": "Rachel Hood",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://housedems.com/sites/default/files/Images/Representative_Images/D076_Rachel_Hood/076_Hood.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-MI-81-lower-ocd-person-85cde8af-86e7-4d74-9bf7-2cc79ccfab39",
              "builtID": "mi-lower-81",
              "externalID": "ocd-person/85cde8af-86e7-4d74-9bf7-2cc79ccfab39",
              "geometry": null
            },
            "contactInfo": {
              "email": "rachelhood@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.com/hood",
                "https://housedems.com/rachel-hood/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-81-lower-ocd-person-85cde8af-86e7-4d74-9bf7-2cc79ccfab39": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/642baa61-66d3-44c1-997f-58b2c5740b4f",
            "firstName": "Kristian",
            "lastName": "Grant",
            "fullName": "Kristian Grant",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-MI-82-lower-ocd-person-642baa61-66d3-44c1-997f-58b2c5740b4f",
              "builtID": "mi-lower-82",
              "externalID": "ocd-person/642baa61-66d3-44c1-997f-58b2c5740b4f",
              "geometry": null
            },
            "contactInfo": {
              "email": "kristiangrant@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Kristian-Grant/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-82-lower-ocd-person-642baa61-66d3-44c1-997f-58b2c5740b4f": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/9f4f8b82-e4ee-4e50-a2ea-d46bbf264d34",
            "firstName": "John W.",
            "lastName": "Fitzgerald",
            "fullName": "John Fitzgerald",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-MI-83-lower-ocd-person-9f4f8b82-e4ee-4e50-a2ea-d46bbf264d34",
              "builtID": "mi-lower-83",
              "externalID": "ocd-person/9f4f8b82-e4ee-4e50-a2ea-d46bbf264d34",
              "geometry": null
            },
            "contactInfo": {
              "email": "johnfitzgerald@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/John-Fitzgerald/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-83-lower-ocd-person-9f4f8b82-e4ee-4e50-a2ea-d46bbf264d34": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/86812673-e63a-4f36-aa3e-26de84380657",
            "firstName": "Carol",
            "lastName": "Glanville",
            "fullName": "Carol Glanville",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-MI-84-lower-ocd-person-86812673-e63a-4f36-aa3e-26de84380657",
              "builtID": "mi-lower-84",
              "externalID": "ocd-person/86812673-e63a-4f36-aa3e-26de84380657",
              "geometry": null
            },
            "contactInfo": {
              "email": "caglanville@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/carol-glanville/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-84-lower-ocd-person-86812673-e63a-4f36-aa3e-26de84380657": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/38c62407-9471-40de-8a1c-44142573c79a",
            "firstName": "Bradley",
            "lastName": "Slagh",
            "fullName": "Brad Slagh",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2021/02/HS_SLAGH_0221_NSC_1065-scaled.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-MI-85-lower-ocd-person-38c62407-9471-40de-8a1c-44142573c79a",
              "builtID": "mi-lower-85",
              "externalID": "ocd-person/38c62407-9471-40de-8a1c-44142573c79a",
              "geometry": null
            },
            "contactInfo": {
              "email": "bradleyslagh@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gophouse.org/representatives/westmi/slagh/",
                "https://gophouse.org/member/repbradleyslagh/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-85-lower-ocd-person-38c62407-9471-40de-8a1c-44142573c79a": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/ffae0dee-fb80-4434-bc8c-8e510d3bc495",
            "firstName": "Nancy",
            "lastName": "DeBoer",
            "fullName": "Nancy DeBoer",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/De Boer-086.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-MI-86-lower-ocd-person-ffae0dee-fb80-4434-bc8c-8e510d3bc495",
              "builtID": "mi-lower-86",
              "externalID": "ocd-person/ffae0dee-fb80-4434-bc8c-8e510d3bc495",
              "geometry": null
            },
            "contactInfo": {
              "email": "nancydeboer@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mi.gov/repdetail/repNancyDeBoer",
                "https://gophouse.org/member/RepNancyDeBoer/posts"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-86-lower-ocd-person-ffae0dee-fb80-4434-bc8c-8e510d3bc495": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/6f36a657-51ea-4c74-b1fd-7bc69ad222bd",
            "firstName": "William",
            "lastName": "Snyder",
            "fullName": "Will Snyder",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-MI-87-lower-ocd-person-6f36a657-51ea-4c74-b1fd-7bc69ad222bd",
              "builtID": "mi-lower-87",
              "externalID": "ocd-person/6f36a657-51ea-4c74-b1fd-7bc69ad222bd",
              "geometry": null
            },
            "contactInfo": {
              "email": "willsnyder@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Will-Snyder/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-87-lower-ocd-person-6f36a657-51ea-4c74-b1fd-7bc69ad222bd": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/960d6487-b202-46fa-8905-e001b6954d5a",
            "firstName": "Gregory",
            "lastName": "VanWoerkom",
            "fullName": "Greg VanWoerkom",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2020/07/HS_VAN-WOERKOM_0119.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-MI-88-lower-ocd-person-960d6487-b202-46fa-8905-e001b6954d5a",
              "builtID": "mi-lower-88",
              "externalID": "ocd-person/960d6487-b202-46fa-8905-e001b6954d5a",
              "geometry": null
            },
            "contactInfo": {
              "email": "gregvanwoerkom@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gophouse.org/representatives/westmi/vanwoerkom/",
                "https://gophouse.org/member/repgregvanwoerkom/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-88-lower-ocd-person-960d6487-b202-46fa-8905-e001b6954d5a": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/187a5c10-ccc0-4c94-a4c9-4c208ace4776",
            "firstName": "Luke",
            "lastName": "Meerman",
            "fullName": "Luke Meerman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2020/07/HS_MEERMAN_0119.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-MI-89-lower-ocd-person-187a5c10-ccc0-4c94-a4c9-4c208ace4776",
              "builtID": "mi-lower-89",
              "externalID": "ocd-person/187a5c10-ccc0-4c94-a4c9-4c208ace4776",
              "geometry": null
            },
            "contactInfo": {
              "email": "lukemeerman@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gophouse.org/representatives/southwest/meerman/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-89-lower-ocd-person-187a5c10-ccc0-4c94-a4c9-4c208ace4776": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/c925c469-552f-448d-a89f-ef456e15da21",
            "firstName": "Bryan R.",
            "lastName": "Posthumus",
            "fullName": "Bryan Posthumus",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2021/01/Bryan-Posthumus_HS.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-MI-90-lower-ocd-person-c925c469-552f-448d-a89f-ef456e15da21",
              "builtID": "mi-lower-90",
              "externalID": "ocd-person/c925c469-552f-448d-a89f-ef456e15da21",
              "geometry": null
            },
            "contactInfo": {
              "email": "bryanposthumus@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://repposthumus.com",
                "https://gophouse.org/member/repbryanposthumus/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-90-lower-ocd-person-c925c469-552f-448d-a89f-ef456e15da21": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/1a809c60-e0a4-4b43-bdba-43f097021b1f",
            "firstName": "Patrick",
            "lastName": "Outman",
            "fullName": "Pat Outman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2021/01/HS_OUTMAN_0221_NSC_7638-scaled.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-MI-91-lower-ocd-person-1a809c60-e0a4-4b43-bdba-43f097021b1f",
              "builtID": "mi-lower-91",
              "externalID": "ocd-person/1a809c60-e0a4-4b43-bdba-43f097021b1f",
              "geometry": null
            },
            "contactInfo": {
              "email": "patoutman@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://repoutman.com",
                "https://gophouse.org/member/reppatoutman/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-91-lower-ocd-person-1a809c60-e0a4-4b43-bdba-43f097021b1f": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/549e485e-c1ea-4582-8db3-91d1e15fe27a",
            "firstName": "Jerry",
            "lastName": "Neyer",
            "fullName": "Jerry Neyer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Neyer-092.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-MI-92-lower-ocd-person-549e485e-c1ea-4582-8db3-91d1e15fe27a",
              "builtID": "mi-lower-92",
              "externalID": "ocd-person/549e485e-c1ea-4582-8db3-91d1e15fe27a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jerryneyerr@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepJerryNeyer/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-92-lower-ocd-person-549e485e-c1ea-4582-8db3-91d1e15fe27a": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/4eab6d53-e023-4314-934a-faffd35d78a2",
            "firstName": "Graham",
            "lastName": "Filler",
            "fullName": "Graham Filler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2020/07/HS_FILLER_0119_DSC_1197-1.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-MI-93-lower-ocd-person-4eab6d53-e023-4314-934a-faffd35d78a2",
              "builtID": "mi-lower-93",
              "externalID": "ocd-person/4eab6d53-e023-4314-934a-faffd35d78a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "grahamfiller@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gophouse.org/representatives/central/filler/",
                "https://gophouse.org/member/repgrahamfiller/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-93-lower-ocd-person-4eab6d53-e023-4314-934a-faffd35d78a2": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/5f160dff-1ce1-4606-a469-fdd08125beb4",
            "firstName": "Amos",
            "lastName": "O'Neal",
            "fullName": "Amos O'Neal",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-MI-94-lower-ocd-person-5f160dff-1ce1-4606-a469-fdd08125beb4",
              "builtID": "mi-lower-94",
              "externalID": "ocd-person/5f160dff-1ce1-4606-a469-fdd08125beb4",
              "geometry": null
            },
            "contactInfo": {
              "email": "amosoneal@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/amos-oneal/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-94-lower-ocd-person-5f160dff-1ce1-4606-a469-fdd08125beb4": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/5355f558-55bb-4f29-81eb-89179f55d69a",
            "firstName": "Bill G.",
            "lastName": "Schuette",
            "fullName": "Bill Schuette",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Schuette-095.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-MI-95-lower-ocd-person-5355f558-55bb-4f29-81eb-89179f55d69a",
              "builtID": "mi-lower-95",
              "externalID": "ocd-person/5355f558-55bb-4f29-81eb-89179f55d69a",
              "geometry": null
            },
            "contactInfo": {
              "email": "billschuette@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepBillSchuette/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-95-lower-ocd-person-5355f558-55bb-4f29-81eb-89179f55d69a": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/6b7aadaf-f690-487f-bec8-95f095f6b565",
            "firstName": "Timothy",
            "lastName": "Beson",
            "fullName": "Timmy Beson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2021/01/HS_BESON_1220_NSC_6238-scaled.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-MI-96-lower-ocd-person-6b7aadaf-f690-487f-bec8-95f095f6b565",
              "builtID": "mi-lower-96",
              "externalID": "ocd-person/6b7aadaf-f690-487f-bec8-95f095f6b565",
              "geometry": null
            },
            "contactInfo": {
              "email": "timothybeson@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://repbeson.com",
                "https://gophouse.org/member/reptimothybeson/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-96-lower-ocd-person-6b7aadaf-f690-487f-bec8-95f095f6b565": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/33fdbffe-7e3d-4146-bd91-bddc098a7bf3",
            "firstName": "Matthew",
            "lastName": "Bierlein",
            "fullName": "Matt Bierlein",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Bierlein-097.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-MI-97-lower-ocd-person-33fdbffe-7e3d-4146-bd91-bddc098a7bf3",
              "builtID": "mi-lower-97",
              "externalID": "ocd-person/33fdbffe-7e3d-4146-bd91-bddc098a7bf3",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthewbierlein@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepMatthewBierlein/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-97-lower-ocd-person-33fdbffe-7e3d-4146-bd91-bddc098a7bf3": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/fc7627d7-6c08-49e7-9edc-6f419443ffaa",
            "firstName": "Gregory",
            "lastName": "Alexander",
            "fullName": "Greg Alexander",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Alexander-098.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-MI-98-lower-ocd-person-fc7627d7-6c08-49e7-9edc-6f419443ffaa",
              "builtID": "mi-lower-98",
              "externalID": "ocd-person/fc7627d7-6c08-49e7-9edc-6f419443ffaa",
              "geometry": null
            },
            "contactInfo": {
              "email": "gregalexander@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/repgregalexander/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-98-lower-ocd-person-fc7627d7-6c08-49e7-9edc-6f419443ffaa": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/0991c39a-0216-491e-a2c1-e7b61b11275d",
            "firstName": "Michael",
            "lastName": "Hoadley",
            "fullName": "Mike Hoadley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Hoadley-099.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-MI-99-lower-ocd-person-0991c39a-0216-491e-a2c1-e7b61b11275d",
              "builtID": "mi-lower-99",
              "externalID": "ocd-person/0991c39a-0216-491e-a2c1-e7b61b11275d",
              "geometry": null
            },
            "contactInfo": {
              "email": "mikehoadley@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepMikeHoadley/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-99-lower-ocd-person-0991c39a-0216-491e-a2c1-e7b61b11275d": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/462dc80a-8642-40d7-9c22-f6234da5451e",
            "firstName": "Tom",
            "lastName": "Kunse",
            "fullName": "Tom Kunse",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Kunse-100.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-MI-100-lower-ocd-person-462dc80a-8642-40d7-9c22-f6234da5451e",
              "builtID": "mi-lower-100",
              "externalID": "ocd-person/462dc80a-8642-40d7-9c22-f6234da5451e",
              "geometry": null
            },
            "contactInfo": {
              "email": "tomkunse@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepTomKunse/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-100-lower-ocd-person-462dc80a-8642-40d7-9c22-f6234da5451e": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/ba49b4ef-08fb-48a0-b8a9-495a3605ef0a",
            "firstName": "Joseph",
            "lastName": "Fox",
            "fullName": "Joseph Fox",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Fox-101.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-MI-101-lower-ocd-person-ba49b4ef-08fb-48a0-b8a9-495a3605ef0a",
              "builtID": "mi-lower-101",
              "externalID": "ocd-person/ba49b4ef-08fb-48a0-b8a9-495a3605ef0a",
              "geometry": null
            },
            "contactInfo": {
              "email": "josephfox@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepJosephFox/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-101-lower-ocd-person-ba49b4ef-08fb-48a0-b8a9-495a3605ef0a": 0
        }
      },
      "102": {
        "members": [
          {
            "API_ID": "ocd-person/b0804883-3f8c-4341-bbc1-26a0346b37c4",
            "firstName": "Curtis",
            "lastName": "VanderWall",
            "fullName": "Curt VanderWall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.michigan.gov/_images/35th.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "102",
              "chamber": "lower",
              "hashID": "SLDL-MI-102-lower-ocd-person-b0804883-3f8c-4341-bbc1-26a0346b37c4",
              "builtID": "mi-lower-102",
              "externalID": "ocd-person/b0804883-3f8c-4341-bbc1-26a0346b37c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "curtisvanderwall@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatorcurtvanderwall.com/",
                "https://www.senatorcurtvanderwall.com/contact",
                "https://gophouse.org/member/RepCurtisVanderWall/posts"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-102-lower-ocd-person-b0804883-3f8c-4341-bbc1-26a0346b37c4": 0
        }
      },
      "103": {
        "members": [
          {
            "API_ID": "ocd-person/80ccf9a5-7303-4218-be3e-0ab6f8e90eee",
            "firstName": "Betsy Lynn",
            "lastName": "Coffia",
            "fullName": "Betsy Coffia",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "103",
              "chamber": "lower",
              "hashID": "SLDL-MI-103-lower-ocd-person-80ccf9a5-7303-4218-be3e-0ab6f8e90eee",
              "builtID": "mi-lower-103",
              "externalID": "ocd-person/80ccf9a5-7303-4218-be3e-0ab6f8e90eee",
              "geometry": null
            },
            "contactInfo": {
              "email": "betsycoffia@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Betsy-Coffia/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-103-lower-ocd-person-80ccf9a5-7303-4218-be3e-0ab6f8e90eee": 0
        }
      },
      "104": {
        "members": [
          {
            "API_ID": "ocd-person/e1147656-e038-47fa-ad70-4d206233db72",
            "firstName": "John R.",
            "lastName": "Roth",
            "fullName": "J.R. Roth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2021/01/HS_ROTH_1220_NSC_7566-scaled.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "104",
              "chamber": "lower",
              "hashID": "SLDL-MI-104-lower-ocd-person-e1147656-e038-47fa-ad70-4d206233db72",
              "builtID": "mi-lower-104",
              "externalID": "ocd-person/e1147656-e038-47fa-ad70-4d206233db72",
              "geometry": null
            },
            "contactInfo": {
              "email": "johnroth@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://reproth.com",
                "https://gophouse.org/member/repjohnroth/posts"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-104-lower-ocd-person-e1147656-e038-47fa-ad70-4d206233db72": 0
        }
      },
      "105": {
        "members": [
          {
            "API_ID": "ocd-person/f38453af-388f-48fc-a18e-f709dba5153c",
            "firstName": "Kenneth C.",
            "lastName": "Borton",
            "fullName": "Ken Borton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2021/01/HS_BORTON_0121_NSC_7600-scaled.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "105",
              "chamber": "lower",
              "hashID": "SLDL-MI-105-lower-ocd-person-f38453af-388f-48fc-a18e-f709dba5153c",
              "builtID": "mi-lower-105",
              "externalID": "ocd-person/f38453af-388f-48fc-a18e-f709dba5153c",
              "geometry": null
            },
            "contactInfo": {
              "email": "kenborton@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://repborton.com",
                "https://gophouse.org/member/repkenborton/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-105-lower-ocd-person-f38453af-388f-48fc-a18e-f709dba5153c": 0
        }
      },
      "106": {
        "members": [
          {
            "API_ID": "ocd-person/60ae519b-a739-4dc5-80bb-c0161269607a",
            "firstName": "Cameron",
            "lastName": "Cavitt",
            "fullName": "Cam Cavitt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Cavitt-106.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "106",
              "chamber": "lower",
              "hashID": "SLDL-MI-106-lower-ocd-person-60ae519b-a739-4dc5-80bb-c0161269607a",
              "builtID": "mi-lower-106",
              "externalID": "ocd-person/60ae519b-a739-4dc5-80bb-c0161269607a",
              "geometry": null
            },
            "contactInfo": {
              "email": "camcavitt@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepCamCavitt/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-106-lower-ocd-person-60ae519b-a739-4dc5-80bb-c0161269607a": 0
        }
      },
      "107": {
        "members": [
          {
            "API_ID": "ocd-person/79ed6347-2370-40bc-9570-996894d0b600",
            "firstName": "Neil",
            "lastName": "Friske",
            "fullName": "Neil Friske",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Friske-107.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "107",
              "chamber": "lower",
              "hashID": "SLDL-MI-107-lower-ocd-person-79ed6347-2370-40bc-9570-996894d0b600",
              "builtID": "mi-lower-107",
              "externalID": "ocd-person/79ed6347-2370-40bc-9570-996894d0b600",
              "geometry": null
            },
            "contactInfo": {
              "email": "neilfriske@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepNeilFriske/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-107-lower-ocd-person-79ed6347-2370-40bc-9570-996894d0b600": 0
        }
      },
      "108": {
        "members": [
          {
            "API_ID": "ocd-person/addfe9a4-4b73-4dec-95a9-4bb7cd0d6bd2",
            "firstName": "David",
            "lastName": "Prestin",
            "fullName": "Dave Prestin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mi.gov/media/Representatives/Prestin-108.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "108",
              "chamber": "lower",
              "hashID": "SLDL-MI-108-lower-ocd-person-addfe9a4-4b73-4dec-95a9-4bb7cd0d6bd2",
              "builtID": "mi-lower-108",
              "externalID": "ocd-person/addfe9a4-4b73-4dec-95a9-4bb7cd0d6bd2",
              "geometry": null
            },
            "contactInfo": {
              "email": "daveprestin@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gophouse.org/member/RepDavePrestin/posts"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-108-lower-ocd-person-addfe9a4-4b73-4dec-95a9-4bb7cd0d6bd2": 0
        }
      },
      "109": {
        "members": [
          {
            "API_ID": "ocd-person/4f4e9d3d-0e2f-4c10-abcb-8308f0cb33e2",
            "firstName": "Jennifer M.",
            "lastName": "Hill",
            "fullName": "Jenn Hill",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "109",
              "chamber": "lower",
              "hashID": "SLDL-MI-109-lower-ocd-person-4f4e9d3d-0e2f-4c10-abcb-8308f0cb33e2",
              "builtID": "mi-lower-109",
              "externalID": "ocd-person/4f4e9d3d-0e2f-4c10-abcb-8308f0cb33e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "jennhill@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://housedems.com/Jenn-Hill/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-109-lower-ocd-person-4f4e9d3d-0e2f-4c10-abcb-8308f0cb33e2": 0
        }
      },
      "110": {
        "members": [
          {
            "API_ID": "ocd-person/a3b57cdd-ae52-49af-9456-bc1f212fe970",
            "firstName": "Gregory",
            "lastName": "Markkanen",
            "fullName": "Greg Markkanen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://dtj5wlj7ond0z.cloudfront.net/uploads/2020/07/HS_MARKKAMEN_0119.jpg",
            "title": "MI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "110",
              "chamber": "lower",
              "hashID": "SLDL-MI-110-lower-ocd-person-a3b57cdd-ae52-49af-9456-bc1f212fe970",
              "builtID": "mi-lower-110",
              "externalID": "ocd-person/a3b57cdd-ae52-49af-9456-bc1f212fe970",
              "geometry": null
            },
            "contactInfo": {
              "email": "gregmarkkanen@house.mi.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gophouse.org/representatives/up/markkanen/",
                "https://gophouse.org/member/repgregmarkkanen/about"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MI-110-lower-ocd-person-a3b57cdd-ae52-49af-9456-bc1f212fe970": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/ebc9981a-cbe1-4d37-99b0-89d3c350fd3f",
            "firstName": "Erika-Marie S.",
            "lastName": "Geiss",
            "fullName": "Erika Geiss",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.com/geiss/wp-content/uploads/sites/7/2022/12/MDS_Geiss_DSC5413.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-MI-1-upper-ocd-person-ebc9981a-cbe1-4d37-99b0-89d3c350fd3f",
              "builtID": "mi-upper-1",
              "externalID": "ocd-person/ebc9981a-cbe1-4d37-99b0-89d3c350fd3f",
              "geometry": null
            },
            "contactInfo": {
              "email": "senegeiss@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senatedems.com/geiss/contact",
                "https://senatedems.com/geiss",
                "https://senatedems.com/geiss/contact",
                "https://senatedems.com/geiss/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-1-upper-ocd-person-ebc9981a-cbe1-4d37-99b0-89d3c350fd3f": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/8e302b97-4868-4575-a56f-3881e91ae0f8",
            "firstName": "Sylvia",
            "lastName": "Santana",
            "fullName": "Sylvia Santana",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.com/santana/wp-content/uploads/sites/8/2022/12/Santana-Official-Portrait.jpeg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-MI-2-upper-ocd-person-8e302b97-4868-4575-a56f-3881e91ae0f8",
              "builtID": "mi-upper-2",
              "externalID": "ocd-person/8e302b97-4868-4575-a56f-3881e91ae0f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "senssantana@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senatedems.com/santana/contact",
                "https://senatedems.com/santana",
                "https://senatedems.com/santana/contact",
                "https://senatedems.com/santana/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-2-upper-ocd-person-8e302b97-4868-4575-a56f-3881e91ae0f8": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/1d297b7e-2e51-4e1a-a208-2f5505e9f1f7",
            "firstName": "Stephanie Gray",
            "lastName": "Chang",
            "fullName": "Stephanie Chang",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.com/chang/wp-content/uploads/sites/9/2022/12/MDS_Chang_DSC5412.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-MI-3-upper-ocd-person-1d297b7e-2e51-4e1a-a208-2f5505e9f1f7",
              "builtID": "mi-upper-3",
              "externalID": "ocd-person/1d297b7e-2e51-4e1a-a208-2f5505e9f1f7",
              "geometry": null
            },
            "contactInfo": {
              "email": "senschang@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Samaritan Center 5555 Conner, Suite 1400D, Detroit, MI 48213",
              "phone1": null,
              "phone2": "313-922-6949",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senatedems.com/chang/contact",
                "https://senatedems.com/chang",
                "https://senatedems.com/chang/contact",
                "https://senatedems.com/chang/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-3-upper-ocd-person-1d297b7e-2e51-4e1a-a208-2f5505e9f1f7": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/5d2fbcb4-0e27-4cb5-99bf-f94693b3aa12",
            "firstName": "Darrin Quiroz",
            "lastName": "Camilleri",
            "fullName": "Darrin Camilleri",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senatedems.com/camilleri/wp-content/uploads/sites/10/2022/12/Portrait_Camilleri_Web.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-MI-4-upper-ocd-person-5d2fbcb4-0e27-4cb5-99bf-f94693b3aa12",
              "builtID": "mi-upper-4",
              "externalID": "ocd-person/5d2fbcb4-0e27-4cb5-99bf-f94693b3aa12",
              "geometry": null
            },
            "contactInfo": {
              "email": "sendcamilleri@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.com/camilleri",
                "https://senatedems.com/camilleri",
                "https://senatedems.com/camilleri/contact",
                "https://senatedems.com/camilleri/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-4-upper-ocd-person-5d2fbcb4-0e27-4cb5-99bf-f94693b3aa12": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/f266a938-3705-4c7c-994f-859b2b6024dc",
            "firstName": "Dayna",
            "lastName": "Polehanki",
            "fullName": "Dayna Polehanki",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.com/polehanki/wp-content/uploads/sites/11/2022/12/Polehanki-Official-Portrait.jpeg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-MI-5-upper-ocd-person-f266a938-3705-4c7c-994f-859b2b6024dc",
              "builtID": "mi-upper-5",
              "externalID": "ocd-person/f266a938-3705-4c7c-994f-859b2b6024dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "sendpolehanki@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senatedems.com/polehanki/contact",
                "https://senatedems.com/polehanki",
                "https://senatedems.com/polehanki/contact",
                "https://senatedems.com/polehanki/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-5-upper-ocd-person-f266a938-3705-4c7c-994f-859b2b6024dc": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/a9e6b2e4-016d-481e-9f34-1f1b33d857ca",
            "firstName": "Mary",
            "lastName": "Cavanagh",
            "fullName": "Mary Cavanagh",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.com/cavanagh/wp-content/uploads/sites/12/2022/12/Cavanaugh_Web.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-MI-6-upper-ocd-person-a9e6b2e4-016d-481e-9f34-1f1b33d857ca",
              "builtID": "mi-upper-6",
              "externalID": "ocd-person/a9e6b2e4-016d-481e-9f34-1f1b33d857ca",
              "geometry": null
            },
            "contactInfo": {
              "email": "senmcavanagh@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.com/mary-cavanagh/",
                "https://senatedems.com/cavanagh",
                "https://senatedems.com/cavanagh/contact",
                "https://senatedems.com/cavanagh/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-6-upper-ocd-person-a9e6b2e4-016d-481e-9f34-1f1b33d857ca": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/d1c4631b-145d-4418-8a29-24683b83ae44",
            "firstName": "Jeremy Allen",
            "lastName": "Moss",
            "fullName": "Jeremy Moss",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senatedems.com/moss/wp-content/uploads/sites/13/2023/01/MDS_Moss_W3A6000.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-MI-7-upper-ocd-person-d1c4631b-145d-4418-8a29-24683b83ae44",
              "builtID": "mi-upper-7",
              "externalID": "ocd-person/d1c4631b-145d-4418-8a29-24683b83ae44",
              "geometry": null
            },
            "contactInfo": {
              "email": "senjmoss@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senatedems.com/moss/contact",
                "https://senatedems.com/moss",
                "https://senatedems.com/moss/contact",
                "https://senatedems.com/moss/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-7-upper-ocd-person-d1c4631b-145d-4418-8a29-24683b83ae44": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/dc6ff9c0-f2b1-433d-a96b-292cf05bcb50",
            "firstName": "Mallory",
            "lastName": "McMorrow",
            "fullName": "Mallory McMorrow",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.com/mcmorrow/wp-content/uploads/sites/14/2023/02/MDS_McMorrow_DSC5342_dec20.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-MI-8-upper-ocd-person-dc6ff9c0-f2b1-433d-a96b-292cf05bcb50",
              "builtID": "mi-upper-8",
              "externalID": "ocd-person/dc6ff9c0-f2b1-433d-a96b-292cf05bcb50",
              "geometry": null
            },
            "contactInfo": {
              "email": "senmmcmorrow@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senatedems.com/mcmorrow/contact",
                "https://senatedems.com/mcmorrow",
                "https://senatedems.com/mcmorrow/contact",
                "https://senatedems.com/mcmorrow/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-8-upper-ocd-person-dc6ff9c0-f2b1-433d-a96b-292cf05bcb50": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/be2c96ad-1304-4c25-8cab-2c8ec5d6475f",
            "firstName": "Michael J.",
            "lastName": "Webber",
            "fullName": "Michael Webber",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2023/01/Webber-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-MI-9-upper-ocd-person-be2c96ad-1304-4c25-8cab-2c8ec5d6475f",
              "builtID": "mi-upper-9",
              "externalID": "ocd-person/be2c96ad-1304-4c25-8cab-2c8ec5d6475f",
              "geometry": null
            },
            "contactInfo": {
              "email": "senmwebber@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatormichaelwebber.com",
                "https://www.senatormichaelwebber.com/contact",
                "https://www.senatormichaelwebber.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-9-upper-ocd-person-be2c96ad-1304-4c25-8cab-2c8ec5d6475f": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/1d71200f-5f95-46e8-ac9b-a35c6c0178d8",
            "firstName": "Paul J.",
            "lastName": "Wojno",
            "fullName": "Paul Wojno",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senatedems.com/wojno/wp-content/uploads/sites/3/2023/01/MDS_Wojno_OfficialPortrait.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-MI-10-upper-ocd-person-1d71200f-5f95-46e8-ac9b-a35c6c0178d8",
              "builtID": "mi-upper-10",
              "externalID": "ocd-person/1d71200f-5f95-46e8-ac9b-a35c6c0178d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "senpwojno@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senatedems.com/wojno/contact",
                "https://senatedems.com/wojno",
                "https://senatedems.com/wojno/contact",
                "https://senatedems.com/wojno/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-10-upper-ocd-person-1d71200f-5f95-46e8-ac9b-a35c6c0178d8": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/3cef42f4-ef0b-43c2-b0b8-01f7f399af2f",
            "firstName": "Veronica",
            "lastName": "Klinefelt",
            "fullName": "Veronica Klinefelt",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.com/klinefelt/wp-content/uploads/sites/15/2022/12/Portrait_Klinefelt_Web.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-MI-11-upper-ocd-person-3cef42f4-ef0b-43c2-b0b8-01f7f399af2f",
              "builtID": "mi-upper-11",
              "externalID": "ocd-person/3cef42f4-ef0b-43c2-b0b8-01f7f399af2f",
              "geometry": null
            },
            "contactInfo": {
              "email": "senvklinefelt@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senatedems.com/klinefelt",
                "https://senatedems.com/klinefelt/contact",
                "https://senatedems.com/klinefelt/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-11-upper-ocd-person-3cef42f4-ef0b-43c2-b0b8-01f7f399af2f": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/cbfd5eae-d312-4498-a988-bcb52883f637",
            "firstName": "Kevin Michael",
            "lastName": "Hertel",
            "fullName": "Kevin Hertel",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senatedems.com/hertel/wp-content/uploads/sites/16/2022/12/Hertel_Portrait.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-MI-12-upper-ocd-person-cbfd5eae-d312-4498-a988-bcb52883f637",
              "builtID": "mi-upper-12",
              "externalID": "ocd-person/cbfd5eae-d312-4498-a988-bcb52883f637",
              "geometry": null
            },
            "contactInfo": {
              "email": "senkhertel@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.com/hertel",
                "https://senatedems.com/hertel",
                "https://senatedems.com/hertel/contact",
                "https://senatedems.com/hertel/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-12-upper-ocd-person-cbfd5eae-d312-4498-a988-bcb52883f637": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/3e24c95f-2ed8-442a-a41f-1a3a9a0b95ed",
            "firstName": "Rosemary",
            "lastName": "Bayer",
            "fullName": "Rosemary Bayer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.com/bayer/wp-content/uploads/sites/17/2022/12/Bayer-Official-Portrait.jpeg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-MI-13-upper-ocd-person-3e24c95f-2ed8-442a-a41f-1a3a9a0b95ed",
              "builtID": "mi-upper-13",
              "externalID": "ocd-person/3e24c95f-2ed8-442a-a41f-1a3a9a0b95ed",
              "geometry": null
            },
            "contactInfo": {
              "email": "senrbayer@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senatedems.com/bayer/contact",
                "https://senatedems.com/bayer",
                "https://senatedems.com/bayer/contact",
                "https://senatedems.com/bayer/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-13-upper-ocd-person-3e24c95f-2ed8-442a-a41f-1a3a9a0b95ed": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/38c58e5f-715a-4943-a8d9-2b557db3db02",
            "firstName": "Susan E.",
            "lastName": "Shink",
            "fullName": "Sue Shink",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.com/shink/wp-content/uploads/sites/18/2022/12/Portrait_Shink_Web.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-MI-14-upper-ocd-person-38c58e5f-715a-4943-a8d9-2b557db3db02",
              "builtID": "mi-upper-14",
              "externalID": "ocd-person/38c58e5f-715a-4943-a8d9-2b557db3db02",
              "geometry": null
            },
            "contactInfo": {
              "email": "sensshink@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senatedems.com/shink",
                "https://senatedems.com/shink/contact",
                "https://senatedems.com/shink/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-14-upper-ocd-person-38c58e5f-715a-4943-a8d9-2b557db3db02": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/a1c1163d-af8d-4b88-9365-07717d4a45c3",
            "firstName": "Jeff",
            "lastName": "Irwin",
            "fullName": "Jeff Irwin",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senatedems.com/irwin/wp-content/uploads/sites/19/2022/12/MDS_Irwin_DSC5423.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-MI-15-upper-ocd-person-a1c1163d-af8d-4b88-9365-07717d4a45c3",
              "builtID": "mi-upper-15",
              "externalID": "ocd-person/a1c1163d-af8d-4b88-9365-07717d4a45c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "senjirwin@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senatedems.com/irwin/contact/",
                "https://senatedems.com/irwin",
                "https://senatedems.com/irwin/contact",
                "https://senatedems.com/irwin/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-15-upper-ocd-person-a1c1163d-af8d-4b88-9365-07717d4a45c3": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/e394293d-abd7-46a9-a60f-6c75e369468e",
            "firstName": "Joseph N.",
            "lastName": "Bellino",
            "fullName": "Joe Bellino",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2023/01/Bellino-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-MI-16-upper-ocd-person-e394293d-abd7-46a9-a60f-6c75e369468e",
              "builtID": "mi-upper-16",
              "externalID": "ocd-person/e394293d-abd7-46a9-a60f-6c75e369468e",
              "geometry": null
            },
            "contactInfo": {
              "email": "senjbellino@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gophouse.org/representatives/southeast/bellino/",
                "https://www.senatorjosephbellino.com/contact",
                "https://www.senatorjosephbellino.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-16-upper-ocd-person-e394293d-abd7-46a9-a60f-6c75e369468e": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/a16e3693-0703-40fb-b508-3b2812079548",
            "firstName": "Jonathan",
            "lastName": "Lindsey",
            "fullName": "Jonathan Lindsey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2023/01/Lindsey-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-MI-17-upper-ocd-person-a16e3693-0703-40fb-b508-3b2812079548",
              "builtID": "mi-upper-17",
              "externalID": "ocd-person/a16e3693-0703-40fb-b508-3b2812079548",
              "geometry": null
            },
            "contactInfo": {
              "email": "senjlindsey@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatorjonathanlindsey.com",
                "https://www.senatorjonathanlindsey.com/contact",
                "https://www.senatorjonathanlindsey.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-17-upper-ocd-person-a16e3693-0703-40fb-b508-3b2812079548": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/c96aa62f-6a2c-4d37-9074-2c25875c639b",
            "firstName": "Thomas A.",
            "lastName": "Albert",
            "fullName": "Thomas Albert",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2023/01/Albert-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-MI-18-upper-ocd-person-c96aa62f-6a2c-4d37-9074-2c25875c639b",
              "builtID": "mi-upper-18",
              "externalID": "ocd-person/c96aa62f-6a2c-4d37-9074-2c25875c639b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sentalbert@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gophouse.org/representatives/westmi/albert/",
                "https://www.senatorthomasalbert.com",
                "https://www.senatorthomasalbert.com/contact",
                "https://www.senatorthomasalbert.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-18-upper-ocd-person-c96aa62f-6a2c-4d37-9074-2c25875c639b": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/c15f272e-73f2-410c-8afe-1cc91ad3603a",
            "firstName": "Sean A.",
            "lastName": "McCann",
            "fullName": "Sean McCann",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senatedems.com/mccann/wp-content/uploads/sites/20/2022/12/MDS_McCann_DSC5430.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-MI-19-upper-ocd-person-c15f272e-73f2-410c-8afe-1cc91ad3603a",
              "builtID": "mi-upper-19",
              "externalID": "ocd-person/c15f272e-73f2-410c-8afe-1cc91ad3603a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sensmccann@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": "315 N. Burdick St. Suite 200, Kalamazoo, MI 49007",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senatedems.com/mccann/contact",
                "https://senatedems.com/mccann",
                "https://senatedems.com/mccann/contact",
                "https://senatedems.com/mccann/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-19-upper-ocd-person-c15f272e-73f2-410c-8afe-1cc91ad3603a": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/6d11944d-f19f-4697-811c-0c9dca750c57",
            "firstName": "Aric Y.",
            "lastName": "Nesbitt",
            "fullName": "Aric Nesbitt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2019/01/Nesbitt-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-MI-20-upper-ocd-person-6d11944d-f19f-4697-811c-0c9dca750c57",
              "builtID": "mi-upper-20",
              "externalID": "ocd-person/6d11944d-f19f-4697-811c-0c9dca750c57",
              "geometry": null
            },
            "contactInfo": {
              "email": "senanesbitt@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatoraricnesbitt.com/contact",
                "https://www.senatoraricnesbitt.com",
                "https://www.senatoraricnesbitt.com/contact",
                "https://www.senatoraricnesbitt.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-20-upper-ocd-person-6d11944d-f19f-4697-811c-0c9dca750c57": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/08eac592-a25a-444f-aea7-db0cdfa79e7b",
            "firstName": "Sarah",
            "lastName": "Anthony",
            "fullName": "Sarah Anthony",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.com/anthony/wp-content/uploads/sites/21/2022/12/MDS_Anthony_DSC5449.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-MI-21-upper-ocd-person-08eac592-a25a-444f-aea7-db0cdfa79e7b",
              "builtID": "mi-upper-21",
              "externalID": "ocd-person/08eac592-a25a-444f-aea7-db0cdfa79e7b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sensanthony@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.com/anthony",
                "https://senatedems.com/anthony",
                "https://senatedems.com/anthony/contact",
                "https://senatedems.com/anthony/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-21-upper-ocd-person-08eac592-a25a-444f-aea7-db0cdfa79e7b": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/4fa99fea-71fd-4f21-9f75-157e6a946c80",
            "firstName": "Lana",
            "lastName": "Theis",
            "fullName": "Lana Theis",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2019/01/Theis-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-MI-22-upper-ocd-person-4fa99fea-71fd-4f21-9f75-157e6a946c80",
              "builtID": "mi-upper-22",
              "externalID": "ocd-person/4fa99fea-71fd-4f21-9f75-157e6a946c80",
              "geometry": null
            },
            "contactInfo": {
              "email": "senltheis@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatorlanatheis.com/contact",
                "https://www.senatorlanatheis.com/contact",
                "https://www.senatorlanatheis.com",
                "https://www.senatorlanatheis.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-22-upper-ocd-person-4fa99fea-71fd-4f21-9f75-157e6a946c80": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/e0a8928d-8ccc-4e1e-9ca5-d7f50642ff91",
            "firstName": "Jim",
            "lastName": "Runestad",
            "fullName": "Jim Runestad",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2019/01/Runestad-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-MI-23-upper-ocd-person-e0a8928d-8ccc-4e1e-9ca5-d7f50642ff91",
              "builtID": "mi-upper-23",
              "externalID": "ocd-person/e0a8928d-8ccc-4e1e-9ca5-d7f50642ff91",
              "geometry": null
            },
            "contactInfo": {
              "email": "senjrunestad@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatorjimrunestad.com",
                "https://www.senatorjimrunestad.com/contact",
                "https://www.senatorjimrunestad.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-23-upper-ocd-person-e0a8928d-8ccc-4e1e-9ca5-d7f50642ff91": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/8d0d58dd-a718-4f8f-bb7f-c530e039c0c5",
            "firstName": "Ruth",
            "lastName": "Johnson",
            "fullName": "Ruth Johnson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2023/01/Johnson-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-MI-24-upper-ocd-person-8d0d58dd-a718-4f8f-bb7f-c530e039c0c5",
              "builtID": "mi-upper-24",
              "externalID": "ocd-person/8d0d58dd-a718-4f8f-bb7f-c530e039c0c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "senrjohnson@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatorruthjohnson.com/contact/",
                "https://www.senatorruthjohnson.com",
                "https://www.senatorruthjohnson.com/contact",
                "https://www.senatorruthjohnson.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-24-upper-ocd-person-8d0d58dd-a718-4f8f-bb7f-c530e039c0c5": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/30afc35e-fa35-448c-b303-e0f6cc456c77",
            "firstName": "Daniel Victor",
            "lastName": "Lauwers",
            "fullName": "Dan Lauwers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2023/01/Lauwers-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-MI-25-upper-ocd-person-30afc35e-fa35-448c-b303-e0f6cc456c77",
              "builtID": "mi-upper-25",
              "externalID": "ocd-person/30afc35e-fa35-448c-b303-e0f6cc456c77",
              "geometry": null
            },
            "contactInfo": {
              "email": "sendlauwers@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatordanlauwers.com/contact",
                "https://www.senatordanlauwers.com/contact",
                "https://www.senatordanlauwers.com",
                "https://www.senatordanlauwers.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-25-upper-ocd-person-30afc35e-fa35-448c-b303-e0f6cc456c77": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/cf7ed8de-2df4-41ff-9dcb-489980b031a0",
            "firstName": "Kevin",
            "lastName": "Daley",
            "fullName": "Kevin Daley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2023/01/Daley-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-MI-26-upper-ocd-person-cf7ed8de-2df4-41ff-9dcb-489980b031a0",
              "builtID": "mi-upper-26",
              "externalID": "ocd-person/cf7ed8de-2df4-41ff-9dcb-489980b031a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "senkdaley@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatorkevindaley.com/",
                "https://www.senatorkevindaley.com/contact/",
                "https://www.senatorkevindaley.com",
                "https://www.senatorkevindaley.com/contact",
                "https://senatorkevindaley.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-26-upper-ocd-person-cf7ed8de-2df4-41ff-9dcb-489980b031a0": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/de1a4de4-aa34-4487-98f8-27b140fed784",
            "firstName": "John",
            "lastName": "Cherry",
            "fullName": "John Cherry",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senatedems.com/cherry/wp-content/uploads/sites/22/2022/12/Portrait_Cherry_Web.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-MI-27-upper-ocd-person-de1a4de4-aa34-4487-98f8-27b140fed784",
              "builtID": "mi-upper-27",
              "externalID": "ocd-person/de1a4de4-aa34-4487-98f8-27b140fed784",
              "geometry": null
            },
            "contactInfo": {
              "email": "senjcherry@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedems.com/cherry",
                "https://senatedems.com/cherry",
                "https://senatedems.com/cherry/contact",
                "https://senatedems.com/cherry/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-27-upper-ocd-person-de1a4de4-aa34-4487-98f8-27b140fed784": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/9271c2c7-01d8-44f1-95a4-1496b8716268",
            "firstName": "Sam",
            "lastName": "Singh",
            "fullName": "Sam Singh",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senatedems.com/singh/wp-content/uploads/sites/23/2023/01/Singh-portrait.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-MI-28-upper-ocd-person-9271c2c7-01d8-44f1-95a4-1496b8716268",
              "builtID": "mi-upper-28",
              "externalID": "ocd-person/9271c2c7-01d8-44f1-95a4-1496b8716268",
              "geometry": null
            },
            "contactInfo": {
              "email": "senssingh@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senatedems.com/singh",
                "https://senatedems.com/singh/contact",
                "https://senatedems.com/singh/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-28-upper-ocd-person-9271c2c7-01d8-44f1-95a4-1496b8716268": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/de38477b-6f3e-44cd-96d0-bd81ff831948",
            "firstName": "Winnie",
            "lastName": "Brinks",
            "fullName": "Winnie Brinks",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.com/brinks/wp-content/uploads/sites/6/2022/12/MDS_Brinks_DSC5456_536.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-MI-29-upper-ocd-person-de38477b-6f3e-44cd-96d0-bd81ff831948",
              "builtID": "mi-upper-29",
              "externalID": "ocd-person/de38477b-6f3e-44cd-96d0-bd81ff831948",
              "geometry": null
            },
            "contactInfo": {
              "email": "senwbrinks@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senatedems.com/brinks/contact",
                "https://senatedems.com/brinks",
                "https://senatedems.com/brinks/contact",
                "https://senatedems.com/brinks/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-29-upper-ocd-person-de38477b-6f3e-44cd-96d0-bd81ff831948": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/8564d477-bb39-47a4-87e1-3ceacd7d3abe",
            "firstName": "Mark E.",
            "lastName": "Huizenga",
            "fullName": "Mark Huizenga",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2021/11/Huizenga-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-MI-30-upper-ocd-person-8564d477-bb39-47a4-87e1-3ceacd7d3abe",
              "builtID": "mi-upper-30",
              "externalID": "ocd-person/8564d477-bb39-47a4-87e1-3ceacd7d3abe",
              "geometry": null
            },
            "contactInfo": {
              "email": "senmhuizenga@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://SenatorMarkHuizenga.com",
                "https://SenatorMarkHuizenga.com/contact",
                "https://SenatorMarkHuizenga.com",
                "https://www.senatormarkhuizenga.com/contact",
                "https://www.senatormarkhuizenga.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-30-upper-ocd-person-8564d477-bb39-47a4-87e1-3ceacd7d3abe": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/cd36ab04-a62b-41fa-8b91-d6c792f6719e",
            "firstName": "Roger",
            "lastName": "Victory",
            "fullName": "Roger Victory",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2019/01/Victory-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-MI-31-upper-ocd-person-cd36ab04-a62b-41fa-8b91-d6c792f6719e",
              "builtID": "mi-upper-31",
              "externalID": "ocd-person/cd36ab04-a62b-41fa-8b91-d6c792f6719e",
              "geometry": null
            },
            "contactInfo": {
              "email": "senrvictory@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatorrogervictory.com/contact",
                "https://www.senatorrogervictory.com/contact",
                "https://www.senatorrogervictory.com",
                "https://www.senatorrogervictory.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-31-upper-ocd-person-cd36ab04-a62b-41fa-8b91-d6c792f6719e": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/5a58fa81-639b-4304-87ca-7471ba9ade96",
            "firstName": "Jon C.",
            "lastName": "Bumstead",
            "fullName": "Jon Bumstead",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2023/01/Bumstead-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-MI-32-upper-ocd-person-5a58fa81-639b-4304-87ca-7471ba9ade96",
              "builtID": "mi-upper-32",
              "externalID": "ocd-person/5a58fa81-639b-4304-87ca-7471ba9ade96",
              "geometry": null
            },
            "contactInfo": {
              "email": "senjbumstead@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatorjonbumstead.com/contact/",
                "https://www.senatorjonbumstead.com",
                "https://www.senatorjonbumstead.com/contact",
                "https://www.senatorjonbumstead.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-32-upper-ocd-person-5a58fa81-639b-4304-87ca-7471ba9ade96": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/9c525554-fb85-45f3-87fa-cf6a3208ce32",
            "firstName": "Rick",
            "lastName": "Outman",
            "fullName": "Rick Outman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2019/01/Outman-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-MI-33-upper-ocd-person-9c525554-fb85-45f3-87fa-cf6a3208ce32",
              "builtID": "mi-upper-33",
              "externalID": "ocd-person/9c525554-fb85-45f3-87fa-cf6a3208ce32",
              "geometry": null
            },
            "contactInfo": {
              "email": "senroutman@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatorrickoutman.com/contact",
                "https://www.senatorrickoutman.com",
                "https://www.senatorrickoutman.com/contact",
                "https://www.senatorrickoutman.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-33-upper-ocd-person-9c525554-fb85-45f3-87fa-cf6a3208ce32": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/b6d8b86d-2975-4d07-83fe-a1841f39806b",
            "firstName": "Roger",
            "lastName": "Hauck",
            "fullName": "Roger Hauck",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2023/01/Hauck-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-MI-34-upper-ocd-person-b6d8b86d-2975-4d07-83fe-a1841f39806b",
              "builtID": "mi-upper-34",
              "externalID": "ocd-person/b6d8b86d-2975-4d07-83fe-a1841f39806b",
              "geometry": null
            },
            "contactInfo": {
              "email": "senrhauck@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gophouse.org/representatives/central/hauck/",
                "https://www.senatorrogerhauck.com",
                "https://www.senatorrogerhauck.com/contact",
                "https://www.senatorrogerhauck.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-34-upper-ocd-person-b6d8b86d-2975-4d07-83fe-a1841f39806b": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/9ac144c6-4f2f-4544-9558-3046f9f215e3",
            "firstName": "Kristen McDonald",
            "lastName": "Rivet",
            "fullName": "Kristen Rivet",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senatedems.com/mcdonaldrivet/wp-content/uploads/sites/24/2022/12/Portrait_McDonald-Rivet_Web.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-MI-35-upper-ocd-person-9ac144c6-4f2f-4544-9558-3046f9f215e3",
              "builtID": "mi-upper-35",
              "externalID": "ocd-person/9ac144c6-4f2f-4544-9558-3046f9f215e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "senkmcdonaldrivet@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senatedems.com/mcdonaldrivet",
                "https://senatedems.com/mcdonaldrivet/contact",
                "https://senatedems.com/mcdonaldrivet/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-35-upper-ocd-person-9ac144c6-4f2f-4544-9558-3046f9f215e3": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/0c9a2c44-5a9a-44cd-8470-5dad5c7b2c53",
            "firstName": "Michele",
            "lastName": "Hoitenga",
            "fullName": "Michele Hoitenga",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2023/01/Hoitenga-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-MI-36-upper-ocd-person-0c9a2c44-5a9a-44cd-8470-5dad5c7b2c53",
              "builtID": "mi-upper-36",
              "externalID": "ocd-person/0c9a2c44-5a9a-44cd-8470-5dad5c7b2c53",
              "geometry": null
            },
            "contactInfo": {
              "email": "senmhoitenga@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatormichelehoitenga.com",
                "https://www.senatormichelehoitenga.com/contact",
                "https://www.senatormichelehoitenga.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-36-upper-ocd-person-0c9a2c44-5a9a-44cd-8470-5dad5c7b2c53": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/071e391f-da3d-4e66-ae78-61a63bc23729",
            "firstName": "John N.",
            "lastName": "Damoose",
            "fullName": "John Damoose",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2023/01/Damoose-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-MI-37-upper-ocd-person-071e391f-da3d-4e66-ae78-61a63bc23729",
              "builtID": "mi-upper-37",
              "externalID": "ocd-person/071e391f-da3d-4e66-ae78-61a63bc23729",
              "geometry": null
            },
            "contactInfo": {
              "email": "senjdamoose@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://repdamoose.com",
                "https://www.senatorjohndamoose.com/contact",
                "https://www.senatorjohndamoose.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-37-upper-ocd-person-071e391f-da3d-4e66-ae78-61a63bc23729": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/4fc7e235-2446-405d-9a56-5e010017f617",
            "firstName": "Edward W.",
            "lastName": "McBroom",
            "fullName": "Ed McBroom",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.misenategop.com/wp-content/uploads/2019/01/McBroom-580x770.jpg",
            "title": "MI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MI",
              "stateFull": "Michigan",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-MI-38-upper-ocd-person-4fc7e235-2446-405d-9a56-5e010017f617",
              "builtID": "mi-upper-38",
              "externalID": "ocd-person/4fc7e235-2446-405d-9a56-5e010017f617",
              "geometry": null
            },
            "contactInfo": {
              "email": "senemcbroom@senate.michigan.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senatoredmcbroom.com/contact",
                "https://www.senatoredmcbroom.com",
                "https://www.senatoredmcbroom.com/contact",
                "https://www.senatoredmcbroom.com/",
                "https://senatoredmcbroom.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MI-38-upper-ocd-person-4fc7e235-2446-405d-9a56-5e010017f617": 0
        }
      }
    }
  },
  "MN": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "MN",
          "stateFull": "Minnesota",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "MN",
            "stateFull": "Minnesota",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-MN---",
            "builtID": "mn--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-MN---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "K000367",
          "in_office": true,
          "firstName": "Amy",
          "lastName": "Klobuchar",
          "middleName": null,
          "fullName": "Amy Klobuchar",
          "gender": "F",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/K000367.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "MN",
            "stateFull": "Minnesota",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-MN---K000367",
            "builtID": "mn--",
            "externalID": "K000367",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.klobuchar.senate.gov/public/index.cfm/contact",
            "address1": "425 Dirksen Senate Office Building",
            "address2": null,
            "phone1": "202-224-3244",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenAmyKlobuchar",
            "youtube": "senatorklobuchar",
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.klobuchar.senate.gov"],
            "rss_url": null
          },
          "title": "MN Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/K000367.json",
          "govtrack_id": "412242",
          "cspan_id": "83701",
          "votesmart_id": "65092",
          "icpsr_id": "40700",
          "crp_id": "N00027500",
          "google_entity_id": "/m/05fbpt",
          "state_rank": "senior",
          "lis_id": "S311",
          "suffix": null,
          "date_of_birth": "1960-05-25",
          "leadership_role": "Senate Democratic Steering Committee Chair",
          "fec_candidate_id": "S6MN00267",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "17",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 11,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 1.99,
          "votes_with_party_pct": 99.26,
          "votes_against_party_pct": 0.74,
          "ocd_id": "ocd-division/country:us/state:mn"
        },
        {
          "API_ID": "S001203",
          "in_office": true,
          "firstName": "Tina",
          "lastName": "Smith",
          "middleName": null,
          "fullName": "Tina Smith",
          "gender": "F",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/S001203.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "MN",
            "stateFull": "Minnesota",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-MN---S001203",
            "builtID": "mn--",
            "externalID": "S001203",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.smith.senate.gov/share-your-opinion/",
            "address1": "720 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-5641",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenTinaSmith",
            "youtube": null,
            "instagram": null,
            "facebook": "USSenTinaSmith",
            "urls": ["https://www.smith.senate.gov"],
            "rss_url": null
          },
          "title": "MN Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/S001203.json",
          "govtrack_id": "412742",
          "cspan_id": "111313",
          "votesmart_id": "152968",
          "icpsr_id": "41706",
          "crp_id": "N00042353",
          "google_entity_id": "/m/0127xvy3",
          "state_rank": "junior",
          "lis_id": "S394",
          "suffix": null,
          "date_of_birth": "1958-03-04",
          "leadership_role": null,
          "fec_candidate_id": "S8MN00578",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "6",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 15,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 2.71,
          "votes_with_party_pct": 99.81,
          "votes_against_party_pct": 0.19,
          "ocd_id": "ocd-division/country:us/state:mn"
        }
      ],
      "hash": { "SENATE-MN---K000367": 0, "SENATE-MN---S001203": 1 }
    },
    "HOUSE": {
      "02": {
        "members": [
          {
            "API_ID": "C001119",
            "in_office": true,
            "firstName": "Angie",
            "lastName": "Craig",
            "middleName": null,
            "fullName": "Angie Craig",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001119.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-MN-02--C001119",
              "builtID": "mn--02",
              "externalID": "C001119",
              "geometry": null,
              "geoid": "2702"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2442 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2271",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAngieCraig",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://craig.house.gov"],
              "rss_url": null
            },
            "title": "MN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001119.json",
            "govtrack_id": "412789",
            "cspan_id": null,
            "votesmart_id": "166261",
            "icpsr_id": null,
            "crp_id": "N00037039",
            "google_entity_id": "/g/11gtcwmfp3",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1972-02-14",
            "leadership_role": null,
            "fec_candidate_id": "H6MN02131",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 29,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 2.75,
            "votes_with_party_pct": 87.81,
            "votes_against_party_pct": 10.23,
            "ocd_id": "ocd-division/country:us/state:mn/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MN-02--C001119": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "E000294",
            "in_office": true,
            "firstName": "Tom",
            "lastName": "Emmer",
            "middleName": null,
            "fullName": "Tom Emmer",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/E000294.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-MN-06--E000294",
              "builtID": "mn--06",
              "externalID": "E000294",
              "geometry": null,
              "geoid": "2706"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "464 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2331",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepTomEmmer",
              "youtube": "RepTomEmmer",
              "instagram": null,
              "facebook": "reptomemmer",
              "urls": ["https://emmer.house.gov"],
              "rss_url": null
            },
            "title": "MN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/E000294.json",
            "govtrack_id": "412639",
            "cspan_id": "75567",
            "votesmart_id": "38894",
            "icpsr_id": "21531",
            "crp_id": "N00035440",
            "google_entity_id": "/m/06w2w3l",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1961-03-03",
            "leadership_role": null,
            "fec_candidate_id": "H4MN06087",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.57,
            "votes_with_party_pct": 93.68,
            "votes_against_party_pct": 4.47,
            "ocd_id": "ocd-division/country:us/state:mn/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MN-06--E000294": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "F000475",
            "in_office": true,
            "firstName": "Brad",
            "lastName": "Finstad",
            "middleName": null,
            "fullName": "Brad Finstad",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/F000475.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-MN-01--F000475",
              "builtID": "mn--01",
              "externalID": "F000475",
              "geometry": null,
              "geoid": "2701"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1605 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2472",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepFinstad",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://finstad.house.gov/"],
              "rss_url": null
            },
            "title": "MN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000475.json",
            "govtrack_id": "456869",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00050649",
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-05-30",
            "leadership_role": null,
            "fec_candidate_id": "H2MN01223",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 9,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 93.37,
            "votes_against_party_pct": 4.68,
            "ocd_id": "ocd-division/country:us/state:mn/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MN-01--F000475": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "F000470",
            "in_office": true,
            "firstName": "Michelle",
            "lastName": "Fischbach",
            "middleName": null,
            "fullName": "Michelle Fischbach",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/F000470.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-MN-07--F000470",
              "builtID": "mn--07",
              "externalID": "F000470",
              "geometry": null,
              "geoid": "2707"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1004 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2165",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepFischbach",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://fischbach.house.gov"],
              "rss_url": null
            },
            "title": "MN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000470.json",
            "govtrack_id": "456828",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00045251",
            "google_entity_id": "/m/063_kp4",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1965-11-03",
            "leadership_role": null,
            "fec_candidate_id": "H0MN07091",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 5,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.47,
            "votes_with_party_pct": 92.62,
            "votes_against_party_pct": 5.44,
            "ocd_id": "ocd-division/country:us/state:mn/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MN-07--F000470": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "M001143",
            "in_office": true,
            "firstName": "Betty",
            "lastName": "McCollum",
            "middleName": null,
            "fullName": "Betty McCollum",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M001143.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-MN-04--M001143",
              "builtID": "mn--04",
              "externalID": "M001143",
              "geometry": null,
              "geoid": "2704"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2426 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6631",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "BettyMcCollum04",
              "youtube": "BMcCollum04",
              "instagram": null,
              "facebook": "repbettymccollum",
              "urls": ["https://mccollum.house.gov"],
              "rss_url": "https://mccollum.house.gov/rss.xml"
            },
            "title": "MN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001143.json",
            "govtrack_id": "400259",
            "cspan_id": "86670",
            "votesmart_id": "3812",
            "icpsr_id": "20122",
            "crp_id": "N00012942",
            "google_entity_id": "/m/0249hy",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1954-07-12",
            "leadership_role": null,
            "fec_candidate_id": "H0MN04049",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "24",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 7,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.66,
            "votes_with_party_pct": 95.76,
            "votes_against_party_pct": 2.41,
            "ocd_id": "ocd-division/country:us/state:mn/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MN-04--M001143": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "O000173",
            "in_office": true,
            "firstName": "Ilhan",
            "lastName": "Omar",
            "middleName": null,
            "fullName": "Ilhan Omar",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/O000173.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-MN-05--O000173",
              "builtID": "mn--05",
              "externalID": "O000173",
              "geometry": null,
              "geoid": "2705"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1730 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4755",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Ilhan",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://omar.house.gov"],
              "rss_url": null
            },
            "title": "MN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/O000173.json",
            "govtrack_id": "412791",
            "cspan_id": null,
            "votesmart_id": "171628",
            "icpsr_id": null,
            "crp_id": "N00043581",
            "google_entity_id": "/g/11c4wx5dm0",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1981-10-04",
            "leadership_role": null,
            "fec_candidate_id": "H8MN05239",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 17,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.61,
            "votes_with_party_pct": 88.63,
            "votes_against_party_pct": 9.43,
            "ocd_id": "ocd-division/country:us/state:mn/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MN-05--O000173": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "P000616",
            "in_office": true,
            "firstName": "Dean",
            "lastName": "Phillips",
            "middleName": null,
            "fullName": "Dean Phillips",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/P000616.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-MN-03--P000616",
              "builtID": "mn--03",
              "externalID": "P000616",
              "geometry": null,
              "geoid": "2703"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2452 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2871",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDeanPhillips",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://phillips.house.gov"],
              "rss_url": null
            },
            "title": "MN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000616.json",
            "govtrack_id": "412790",
            "cspan_id": null,
            "votesmart_id": "181357",
            "icpsr_id": null,
            "crp_id": "N00041134",
            "google_entity_id": "/g/11fg48_c9t",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1969-01-20",
            "leadership_role": null,
            "fec_candidate_id": "H8MN03143",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 323,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 30.59,
            "votes_with_party_pct": 93.11,
            "votes_against_party_pct": 4.27,
            "ocd_id": "ocd-division/country:us/state:mn/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MN-03--P000616": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "S001212",
            "in_office": true,
            "firstName": "Pete",
            "lastName": "Stauber",
            "middleName": null,
            "fullName": "Pete Stauber",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S001212.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-MN-08--S001212",
              "builtID": "mn--08",
              "externalID": "S001212",
              "geometry": null,
              "geoid": "2708"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "145 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-6211",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepPeteStauber",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://stauber.house.gov"],
              "rss_url": null
            },
            "title": "MN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001212.json",
            "govtrack_id": "412792",
            "cspan_id": null,
            "votesmart_id": "159954",
            "icpsr_id": null,
            "crp_id": "N00041511",
            "google_entity_id": "/g/11gjy6v3nv",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1966-05-10",
            "leadership_role": null,
            "fec_candidate_id": "H8MN08043",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 74,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 7.01,
            "votes_with_party_pct": 92.55,
            "votes_against_party_pct": 5.49,
            "ocd_id": "ocd-division/country:us/state:mn/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MN-08--S001212": 0 }
      }
    },
    "SLDL": {
      "62A": {
        "members": [
          {
            "API_ID": "ocd-person/09493dc0-a224-4cf6-a859-1384ffeda8a0",
            "firstName": "Aisha",
            "lastName": "Gomez",
            "fullName": "Aisha Gomez",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/62A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "62A",
              "chamber": "lower",
              "hashID": "SLDL-MN-62A-lower-ocd-person-09493dc0-a224-4cf6-a859-1384ffeda8a0",
              "builtID": "mn-lower-62a",
              "externalID": "ocd-person/09493dc0-a224-4cf6-a859-1384ffeda8a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.aisha.gomez@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15531"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-62A-lower-ocd-person-09493dc0-a224-4cf6-a859-1384ffeda8a0": 0
        }
      },
      "8B": {
        "members": [
          {
            "API_ID": "ocd-person/5604b347-d9b5-40b6-844d-8bc5246283d3",
            "firstName": "Alicia",
            "lastName": "Kozlowski",
            "fullName": "Liish Kozlowski",
            "gender": "X",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/08B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "8B",
              "chamber": "lower",
              "hashID": "SLDL-MN-8B-lower-ocd-person-5604b347-d9b5-40b6-844d-8bc5246283d3",
              "builtID": "mn-lower-8b",
              "externalID": "ocd-person/5604b347-d9b5-40b6-844d-8bc5246283d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.alicia.kozlowski@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15595"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-8B-lower-ocd-person-5604b347-d9b5-40b6-844d-8bc5246283d3": 0
        }
      },
      "47A": {
        "members": [
          {
            "API_ID": "ocd-person/8300aa36-f818-4ae8-ad31-f9373e396e08",
            "firstName": "Amanda",
            "lastName": "Hemmingsen-Jaeger",
            "fullName": "Amanda Hemmingsen-Jaeger",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/47A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "47A",
              "chamber": "lower",
              "hashID": "SLDL-MN-47A-lower-ocd-person-8300aa36-f818-4ae8-ad31-f9373e396e08",
              "builtID": "mn-lower-47a",
              "externalID": "ocd-person/8300aa36-f818-4ae8-ad31-f9373e396e08",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.amanda.hemmingsen-jaeger@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15620"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-47A-lower-ocd-person-8300aa36-f818-4ae8-ad31-f9373e396e08": 0
        }
      },
      "45A": {
        "members": [
          {
            "API_ID": "ocd-person/bba68b5b-90ac-4465-bdd2-fdf23c33b87a",
            "firstName": "Andrew",
            "lastName": "Myers",
            "fullName": "Andrew Myers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/45A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "45A",
              "chamber": "lower",
              "hashID": "SLDL-MN-45A-lower-ocd-person-bba68b5b-90ac-4465-bdd2-fdf23c33b87a",
              "builtID": "mn-lower-45a",
              "externalID": "ocd-person/bba68b5b-90ac-4465-bdd2-fdf23c33b87a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.andrew.myers@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15618"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-45A-lower-ocd-person-bba68b5b-90ac-4465-bdd2-fdf23c33b87a": 0
        }
      },
      "25B": {
        "members": [
          {
            "API_ID": "ocd-person/41cf59c9-a2c3-442d-85f1-7d6ec8de1a97",
            "firstName": "Andrew",
            "lastName": "Smith",
            "fullName": "Andy Smith",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/25B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "25B",
              "chamber": "lower",
              "hashID": "SLDL-MN-25B-lower-ocd-person-41cf59c9-a2c3-442d-85f1-7d6ec8de1a97",
              "builtID": "mn-lower-25b",
              "externalID": "ocd-person/41cf59c9-a2c3-442d-85f1-7d6ec8de1a97",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.andy.smith@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15606"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-25B-lower-ocd-person-41cf59c9-a2c3-442d-85f1-7d6ec8de1a97": 0
        }
      },
      "28B": {
        "members": [
          {
            "API_ID": "ocd-person/a93779a2-69bc-4d3c-a8c6-530f3eccc341",
            "firstName": "Anne",
            "lastName": "Neu Brindley",
            "fullName": "Anne Neu Brindley",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/28B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "28B",
              "chamber": "lower",
              "hashID": "SLDL-MN-28B-lower-ocd-person-a93779a2-69bc-4d3c-a8c6-530f3eccc341",
              "builtID": "mn-lower-28b",
              "externalID": "ocd-person/a93779a2-69bc-4d3c-a8c6-530f3eccc341",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.anne.neu@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "North Branch, MN",
              "phone1": null,
              "phone2": "651-243-0427",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15494"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-28B-lower-ocd-person-a93779a2-69bc-4d3c-a8c6-530f3eccc341": 0
        }
      },
      "66B": {
        "members": [
          {
            "API_ID": "ocd-person/9f56bc31-f522-4e29-af62-cdc616babc88",
            "firstName": "Athena",
            "lastName": "Hollins",
            "fullName": "Athena Hollins",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/66B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "66B",
              "chamber": "lower",
              "hashID": "SLDL-MN-66B-lower-ocd-person-9f56bc31-f522-4e29-af62-cdc616babc88",
              "builtID": "mn-lower-66b",
              "externalID": "ocd-person/9f56bc31-f522-4e29-af62-cdc616babc88",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.athena.hollins@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "672 Sherwood Ave., St. Paul, MN 55106",
              "phone1": null,
              "phone2": "651-352-7605",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.leg.state.mn.us/members/profile/15553",
                "https://www.house.mn.gov/members/profile/15553"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-66B-lower-ocd-person-9f56bc31-f522-4e29-af62-cdc616babc88": 0
        }
      },
      "54B": {
        "members": [
          {
            "API_ID": "ocd-person/f8d52f8b-1b8c-454d-87be-42edd3100511",
            "firstName": "Ben",
            "lastName": "Bakeberg",
            "fullName": "Ben Bakeberg",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/54B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "54B",
              "chamber": "lower",
              "hashID": "SLDL-MN-54B-lower-ocd-person-f8d52f8b-1b8c-454d-87be-42edd3100511",
              "builtID": "mn-lower-54b",
              "externalID": "ocd-person/f8d52f8b-1b8c-454d-87be-42edd3100511",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ben.bakeberg@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Jordan, MN",
              "phone1": null,
              "phone2": "612-433-3696",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15569"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-54B-lower-ocd-person-f8d52f8b-1b8c-454d-87be-42edd3100511": 0
        }
      },
      "6A": {
        "members": [
          {
            "API_ID": "ocd-person/f5597924-50c0-4bfc-bd63-3d3b814274f8",
            "firstName": "Ben",
            "lastName": "Davis",
            "fullName": "Ben Davis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/06A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "6A",
              "chamber": "lower",
              "hashID": "SLDL-MN-6A-lower-ocd-person-f5597924-50c0-4bfc-bd63-3d3b814274f8",
              "builtID": "mn-lower-6a",
              "externalID": "ocd-person/f5597924-50c0-4bfc-bd63-3d3b814274f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ben.davis@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15594"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-6A-lower-ocd-person-f5597924-50c0-4bfc-bd63-3d3b814274f8": 0
        }
      },
      "14A": {
        "members": [
          {
            "API_ID": "ocd-person/354e366b-b9b5-46b4-b9ce-f3d96f508ceb",
            "firstName": "Bernadette",
            "lastName": "Perryman",
            "fullName": "Bernie Perryman",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/14A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "14A",
              "chamber": "lower",
              "hashID": "SLDL-MN-14A-lower-ocd-person-354e366b-b9b5-46b4-b9ce-f3d96f508ceb",
              "builtID": "mn-lower-14a",
              "externalID": "ocd-person/354e366b-b9b5-46b4-b9ce-f3d96f508ceb",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.bernie.perryman@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "St. Augusta, MN",
              "phone1": null,
              "phone2": "320-292-5960",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15599"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-14A-lower-ocd-person-354e366b-b9b5-46b4-b9ce-f3d96f508ceb": 0
        }
      },
      "52B": {
        "members": [
          {
            "API_ID": "ocd-person/971431ad-1fdb-49e5-9cf0-57cfff1645a8",
            "firstName": "Bianca Ward",
            "lastName": "Virnig",
            "fullName": "Bianca Virnig",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": null,
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "52B",
              "chamber": "lower",
              "hashID": "SLDL-MN-52B-lower-ocd-person-971431ad-1fdb-49e5-9cf0-57cfff1645a8",
              "builtID": "mn-lower-52b",
              "externalID": "ocd-person/971431ad-1fdb-49e5-9cf0-57cfff1645a8",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.bianca.virnig@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1449 Rocky Lane, Eagan, MN 55122",
              "phone1": null,
              "phone2": "651-278-8605",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15625"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-52B-lower-ocd-person-971431ad-1fdb-49e5-9cf0-57cfff1645a8": 0
        }
      },
      "22A": {
        "members": [
          {
            "API_ID": "ocd-person/ad3245d1-b310-4d50-8290-e642d078f661",
            "firstName": "Bjorn",
            "lastName": "Olson",
            "fullName": "Bjorn Olson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/22A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "22A",
              "chamber": "lower",
              "hashID": "SLDL-MN-22A-lower-ocd-person-ad3245d1-b310-4d50-8290-e642d078f661",
              "builtID": "mn-lower-22a",
              "externalID": "ocd-person/ad3245d1-b310-4d50-8290-e642d078f661",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.bjorn.olson@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "714 S. Prairie Ave., Fairmont, MN 56031",
              "phone1": null,
              "phone2": "507-525-3597",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15544"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-22A-lower-ocd-person-ad3245d1-b310-4d50-8290-e642d078f661": 0
        }
      },
      "17B": {
        "members": [
          {
            "API_ID": "ocd-person/fe6a11bc-199d-467e-9447-3999c8250490",
            "firstName": "Bobbie",
            "lastName": "Harder",
            "fullName": "Bobbie Harder",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/17B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "17B",
              "chamber": "lower",
              "hashID": "SLDL-MN-17B-lower-ocd-person-fe6a11bc-199d-467e-9447-3999c8250490",
              "builtID": "mn-lower-17b",
              "externalID": "ocd-person/fe6a11bc-199d-467e-9447-3999c8250490",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.bobbie.harder@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 303, Henderson, MN 56044",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15601"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-17B-lower-ocd-person-fe6a11bc-199d-467e-9447-3999c8250490": 0
        }
      },
      "54A": {
        "members": [
          {
            "API_ID": "ocd-person/05f634e6-f8d3-4983-9604-dfc8dddc574e",
            "firstName": "Brad",
            "lastName": "Tabke",
            "fullName": "Brad Tabke",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/54A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "54A",
              "chamber": "lower",
              "hashID": "SLDL-MN-54A-lower-ocd-person-05f634e6-f8d3-4983-9604-dfc8dddc574e",
              "builtID": "mn-lower-54a",
              "externalID": "ocd-person/05f634e6-f8d3-4983-9604-dfc8dddc574e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.brad.tabke@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15523"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-54A-lower-ocd-person-05f634e6-f8d3-4983-9604-dfc8dddc574e": 0
        }
      },
      "19A": {
        "members": [
          {
            "API_ID": "ocd-person/a29e9cdc-04fa-42c7-b17c-92b30324c72c",
            "firstName": "Brian",
            "lastName": "Daniels",
            "fullName": "Brian Daniels",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/19A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "19A",
              "chamber": "lower",
              "hashID": "SLDL-MN-19A-lower-ocd-person-a29e9cdc-04fa-42c7-b17c-92b30324c72c",
              "builtID": "mn-lower-19a",
              "externalID": "ocd-person/a29e9cdc-04fa-42c7-b17c-92b30324c72c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.brian.daniels@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1415 Brentwood Circle, Faribault, MN 55021",
              "phone1": null,
              "phone2": "507-330-2758",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15433"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-19A-lower-ocd-person-a29e9cdc-04fa-42c7-b17c-92b30324c72c": 0
        }
      },
      "28A": {
        "members": [
          {
            "API_ID": "ocd-person/da4c1675-372e-4c9d-9599-ce45502c93fc",
            "firstName": "Brian",
            "lastName": "Johnson",
            "fullName": "Brian Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/28A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "28A",
              "chamber": "lower",
              "hashID": "SLDL-MN-28A-lower-ocd-person-da4c1675-372e-4c9d-9599-ce45502c93fc",
              "builtID": "mn-lower-28a",
              "externalID": "ocd-person/da4c1675-372e-4c9d-9599-ce45502c93fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.brian.johnson@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Cambridge, MN",
              "phone1": null,
              "phone2": "763-202-4731",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15403"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-28A-lower-ocd-person-da4c1675-372e-4c9d-9599-ce45502c93fc": 0
        }
      },
      "22B": {
        "members": [
          {
            "API_ID": "ocd-person/81cc1363-8faf-454c-8fe7-c8e991936bb5",
            "firstName": "Brian H.",
            "lastName": "Pfarr",
            "fullName": "Brian Pfarr",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/22B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "22B",
              "chamber": "lower",
              "hashID": "SLDL-MN-22B-lower-ocd-person-81cc1363-8faf-454c-8fe7-c8e991936bb5",
              "builtID": "mn-lower-22b",
              "externalID": "ocd-person/81cc1363-8faf-454c-8fe7-c8e991936bb5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.brian.pfarr@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://house.mn.gov/members/profile/15543"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-22B-lower-ocd-person-81cc1363-8faf-454c-8fe7-c8e991936bb5": 0
        }
      },
      "36B": {
        "members": [
          {
            "API_ID": "ocd-person/372ff500-488c-4bf6-9557-6b5824c6e193",
            "firstName": "Brion Marie",
            "lastName": "Curran",
            "fullName": "Brion Curran",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/36B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "36B",
              "chamber": "lower",
              "hashID": "SLDL-MN-36B-lower-ocd-person-372ff500-488c-4bf6-9557-6b5824c6e193",
              "builtID": "mn-lower-36b",
              "externalID": "ocd-person/372ff500-488c-4bf6-9557-6b5824c6e193",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.brion.curran@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15614"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-36B-lower-ocd-person-372ff500-488c-4bf6-9557-6b5824c6e193": 0
        }
      },
      "27B": {
        "members": [
          {
            "API_ID": "ocd-person/b135d3fd-c3cd-4bb5-a0b8-eefef669aada",
            "firstName": "Bryan",
            "lastName": "Lawrence",
            "fullName": "Bryan Lawrence",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/27B.jpg?v=010120",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "27B",
              "chamber": "lower",
              "hashID": "SLDL-MN-27B-lower-ocd-person-b135d3fd-c3cd-4bb5-a0b8-eefef669aada",
              "builtID": "mn-lower-27b",
              "externalID": "ocd-person/b135d3fd-c3cd-4bb5-a0b8-eefef669aada",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.bryan.lawrence@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "12147 - 293rd Ave. NW, Princeton, MN 55371",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15626"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-27B-lower-ocd-person-b135d3fd-c3cd-4bb5-a0b8-eefef669aada": 0
        }
      },
      "49B": {
        "members": [
          {
            "API_ID": "ocd-person/bfa93ce5-99ed-4840-9d49-c3531a6e7742",
            "firstName": "Carlie",
            "lastName": "Kotyza-Witthuhn",
            "fullName": "Carlie Kotyza-Witthuhn",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/49B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "49B",
              "chamber": "lower",
              "hashID": "SLDL-MN-49B-lower-ocd-person-bfa93ce5-99ed-4840-9d49-c3531a6e7742",
              "builtID": "mn-lower-49b",
              "externalID": "ocd-person/bfa93ce5-99ed-4840-9d49-c3531a6e7742",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.carlie.kotyza-witthuhn@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 44272, Eden Prairie, MN 55344",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15515"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-49B-lower-ocd-person-bfa93ce5-99ed-4840-9d49-c3531a6e7742": 0
        }
      },
      "43A": {
        "members": [
          {
            "API_ID": "ocd-person/31bf14e3-fcde-4b2f-b2c4-1dd47b9a260b",
            "firstName": "Cedrick Rommel",
            "lastName": "Frazier",
            "fullName": "Cedrick Frazier",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/43A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "43A",
              "chamber": "lower",
              "hashID": "SLDL-MN-43A-lower-ocd-person-31bf14e3-fcde-4b2f-b2c4-1dd47b9a260b",
              "builtID": "mn-lower-43a",
              "externalID": "ocd-person/31bf14e3-fcde-4b2f-b2c4-1dd47b9a260b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.cedrick.frazier@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15548"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-43A-lower-ocd-person-31bf14e3-fcde-4b2f-b2c4-1dd47b9a260b": 0
        }
      },
      "46B": {
        "members": [
          {
            "API_ID": "ocd-person/ec1171fd-3b90-4137-b01d-041f905c642d",
            "firstName": "Cheryl",
            "lastName": "Youakim",
            "fullName": "Cheryl Youakim",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/46B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "46B",
              "chamber": "lower",
              "hashID": "SLDL-MN-46B-lower-ocd-person-ec1171fd-3b90-4137-b01d-041f905c642d",
              "builtID": "mn-lower-46b",
              "externalID": "ocd-person/ec1171fd-3b90-4137-b01d-041f905c642d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.cheryl.youakim@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Hopkins, MN",
              "phone1": null,
              "phone2": "952-240-2278",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15450"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-46B-lower-ocd-person-ec1171fd-3b90-4137-b01d-041f905c642d": 0
        }
      },
      "15A": {
        "members": [
          {
            "API_ID": "ocd-person/d9abe93e-6eba-4658-a0cd-68610585606b",
            "firstName": "Christopher Thomas",
            "lastName": "Swedzinski",
            "fullName": "Chris Swedzinski",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/15A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "15A",
              "chamber": "lower",
              "hashID": "SLDL-MN-15A-lower-ocd-person-d9abe93e-6eba-4658-a0cd-68610585606b",
              "builtID": "mn-lower-15a",
              "externalID": "ocd-person/d9abe93e-6eba-4658-a0cd-68610585606b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.chris.swedzinski@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Ghent, MN",
              "phone1": null,
              "phone2": "507-829-7754",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15369"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-15A-lower-ocd-person-d9abe93e-6eba-4658-a0cd-68610585606b": 0
        }
      },
      "14B": {
        "members": [
          {
            "API_ID": "ocd-person/57755948-5912-49c5-b8b7-65c110ae44d9",
            "firstName": "Dan",
            "lastName": "Wolgamott",
            "fullName": "Dan Wolgamott",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/14B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "14B",
              "chamber": "lower",
              "hashID": "SLDL-MN-14B-lower-ocd-person-57755948-5912-49c5-b8b7-65c110ae44d9",
              "builtID": "mn-lower-14b",
              "externalID": "ocd-person/57755948-5912-49c5-b8b7-65c110ae44d9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.dan.wolgamott@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "717 Riverside Drive SE, St. Cloud, MN 56304",
              "phone1": null,
              "phone2": "320-828-2632",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15499"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-14B-lower-ocd-person-57755948-5912-49c5-b8b7-65c110ae44d9": 0
        }
      },
      "34A": {
        "members": [
          {
            "API_ID": "ocd-person/04242ba4-8fb4-4306-98d2-cae358d8e532",
            "firstName": "Danny",
            "lastName": "Nadeau",
            "fullName": "Danny Nadeau",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/34A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "34A",
              "chamber": "lower",
              "hashID": "SLDL-MN-34A-lower-ocd-person-04242ba4-8fb4-4306-98d2-cae358d8e532",
              "builtID": "mn-lower-34a",
              "externalID": "ocd-person/04242ba4-8fb4-4306-98d2-cae358d8e532",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.danny.nadeau@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 752, Rogers, MN 55374",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15612"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-34A-lower-ocd-person-04242ba4-8fb4-4306-98d2-cae358d8e532": 0
        }
      },
      "16B": {
        "members": [
          {
            "API_ID": "ocd-person/c0275aeb-e2ff-44c7-b4ab-cae4bf495d84",
            "firstName": "Dave",
            "lastName": "Baker",
            "fullName": "Dave Baker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/16B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "16B",
              "chamber": "lower",
              "hashID": "SLDL-MN-16B-lower-ocd-person-c0275aeb-e2ff-44c7-b4ab-cae4bf495d84",
              "builtID": "mn-lower-16b",
              "externalID": "ocd-person/c0275aeb-e2ff-44c7-b4ab-cae4bf495d84",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.dave.baker@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15429"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-16B-lower-ocd-person-c0275aeb-e2ff-44c7-b4ab-cae4bf495d84": 0
        }
      },
      "7B": {
        "members": [
          {
            "API_ID": "ocd-person/46e7057a-c627-43c9-802f-65b3475f8ebe",
            "firstName": "Dave",
            "lastName": "Lislegard",
            "fullName": "Dave Lislegard",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/07B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "7B",
              "chamber": "lower",
              "hashID": "SLDL-MN-7B-lower-ocd-person-46e7057a-c627-43c9-802f-65b3475f8ebe",
              "builtID": "mn-lower-7b",
              "externalID": "ocd-person/46e7057a-c627-43c9-802f-65b3475f8ebe",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.dave.lislegard@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "720 Arrowhead St., Aurora, MN 55705",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15497"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-7B-lower-ocd-person-46e7057a-c627-43c9-802f-65b3475f8ebe": 0
        }
      },
      "64B": {
        "members": [
          {
            "API_ID": "ocd-person/a8c6c773-371b-4df9-a57a-5d2565c7b876",
            "firstName": "Dave",
            "lastName": "Pinto",
            "fullName": "Dave Pinto",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/64B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "64B",
              "chamber": "lower",
              "hashID": "SLDL-MN-64B-lower-ocd-person-a8c6c773-371b-4df9-a57a-5d2565c7b876",
              "builtID": "mn-lower-64b",
              "externalID": "ocd-person/a8c6c773-371b-4df9-a57a-5d2565c7b876",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.dave.pinto@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15444"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-64B-lower-ocd-person-a8c6c773-371b-4df9-a57a-5d2565c7b876": 0
        }
      },
      "17A": {
        "members": [
          {
            "API_ID": "ocd-person/f4047f6d-971d-41be-bbce-e15dac5829ae",
            "firstName": "Dawn",
            "lastName": "Gillman",
            "fullName": "Dawn Gillman",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/17A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "17A",
              "chamber": "lower",
              "hashID": "SLDL-MN-17A-lower-ocd-person-f4047f6d-971d-41be-bbce-e15dac5829ae",
              "builtID": "mn-lower-17a",
              "externalID": "ocd-person/f4047f6d-971d-41be-bbce-e15dac5829ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.dawn.gillman@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 218, Dassel, MN 55325",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15600"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-17A-lower-ocd-person-f4047f6d-971d-41be-bbce-e15dac5829ae": 0
        }
      },
      "16A": {
        "members": [
          {
            "API_ID": "ocd-person/e4b1c526-c1b8-49b3-89f9-63c28fdcd563",
            "firstName": "Dean L.",
            "lastName": "Urdahl",
            "fullName": "Dean Urdahl",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/16A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "16A",
              "chamber": "lower",
              "hashID": "SLDL-MN-16A-lower-ocd-person-e4b1c526-c1b8-49b3-89f9-63c28fdcd563",
              "builtID": "mn-lower-16a",
              "externalID": "ocd-person/e4b1c526-c1b8-49b3-89f9-63c28fdcd563",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.dean.urdahl@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "54880 253rd St., Grove City, MN 56243",
              "phone1": null,
              "phone2": "320-857-2600",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/10792"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-16A-lower-ocd-person-e4b1c526-c1b8-49b3-89f9-63c28fdcd563": 0
        }
      },
      "1B": {
        "members": [
          {
            "API_ID": "ocd-person/c6a62c9e-b4e0-4408-98d9-4645d9924656",
            "firstName": "Debra L.",
            "lastName": "Kiel",
            "fullName": "Deb Kiel",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/01B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "1B",
              "chamber": "lower",
              "hashID": "SLDL-MN-1B-lower-ocd-person-c6a62c9e-b4e0-4408-98d9-4645d9924656",
              "builtID": "mn-lower-1b",
              "externalID": "ocd-person/c6a62c9e-b4e0-4408-98d9-4645d9924656",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.deb.kiel@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15353"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-1B-lower-ocd-person-c6a62c9e-b4e0-4408-98d9-4645d9924656": 0
        }
      },
      "24A": {
        "members": [
          {
            "API_ID": "ocd-person/6652aa40-32c5-4430-bbf6-707a0b8a1f56",
            "firstName": "Duane Robert",
            "lastName": "Quam",
            "fullName": "Duane Quam",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/24A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "24A",
              "chamber": "lower",
              "hashID": "SLDL-MN-24A-lower-ocd-person-6652aa40-32c5-4430-bbf6-707a0b8a1f56",
              "builtID": "mn-lower-24a",
              "externalID": "ocd-person/6652aa40-32c5-4430-bbf6-707a0b8a1f56",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.duane.quam@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "622 Meadowlark Court NW, Byron, MN 55920",
              "phone1": null,
              "phone2": "507-775-6043",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15366"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-24A-lower-ocd-person-6652aa40-32c5-4430-bbf6-707a0b8a1f56": 0
        }
      },
      "36A": {
        "members": [
          {
            "API_ID": "ocd-person/f27e2742-5c0f-4dba-a37f-76592f91f6f0",
            "firstName": "Elliott",
            "lastName": "Engen",
            "fullName": "Elliott Engen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/36A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "36A",
              "chamber": "lower",
              "hashID": "SLDL-MN-36A-lower-ocd-person-f27e2742-5c0f-4dba-a37f-76592f91f6f0",
              "builtID": "mn-lower-36a",
              "externalID": "ocd-person/f27e2742-5c0f-4dba-a37f-76592f91f6f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.elliott.engen@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15613"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-36A-lower-ocd-person-f27e2742-5c0f-4dba-a37f-76592f91f6f0": 0
        }
      },
      "63B": {
        "members": [
          {
            "API_ID": "ocd-person/f38e8115-610c-4e22-b9cb-80bd96311410",
            "firstName": "Emma G.",
            "lastName": "Greenman",
            "fullName": "Emma Greenman",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/63B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "63B",
              "chamber": "lower",
              "hashID": "SLDL-MN-63B-lower-ocd-person-f38e8115-610c-4e22-b9cb-80bd96311410",
              "builtID": "mn-lower-63b",
              "externalID": "ocd-person/f38e8115-610c-4e22-b9cb-80bd96311410",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.emma.greenman@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15552"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-63B-lower-ocd-person-f38e8115-610c-4e22-b9cb-80bd96311410": 0
        }
      },
      "39A": {
        "members": [
          {
            "API_ID": "ocd-person/0d6f7edf-eae0-441d-8089-84472ce1f36f",
            "firstName": "Erin Anderson",
            "lastName": "Koegel",
            "fullName": "Erin Koegel",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/39A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "39A",
              "chamber": "lower",
              "hashID": "SLDL-MN-39A-lower-ocd-person-0d6f7edf-eae0-441d-8089-84472ce1f36f",
              "builtID": "mn-lower-39a",
              "externalID": "ocd-person/0d6f7edf-eae0-441d-8089-84472ce1f36f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.erin.koegel@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15465"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-39A-lower-ocd-person-0d6f7edf-eae0-441d-8089-84472ce1f36f": 0
        }
      },
      "59B": {
        "members": [
          {
            "API_ID": "ocd-person/b140f0d2-f803-4118-9c6c-72aa322ec3bb",
            "firstName": "Esther",
            "lastName": "Agbaje",
            "fullName": "Esther Agbaje",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/59B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "59B",
              "chamber": "lower",
              "hashID": "SLDL-MN-59B-lower-ocd-person-b140f0d2-f803-4118-9c6c-72aa322ec3bb",
              "builtID": "mn-lower-59b",
              "externalID": "ocd-person/b140f0d2-f803-4118-9c6c-72aa322ec3bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.esther.agbaje@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15551"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-59B-lower-ocd-person-b140f0d2-f803-4118-9c6c-72aa322ec3bb": 0
        }
      },
      "47B": {
        "members": [
          {
            "API_ID": "ocd-person/d0e94ca0-b600-4567-88ee-34a861ca38aa",
            "firstName": "Ethan",
            "lastName": "Cha",
            "fullName": "Ethan Cha",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/47B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "47B",
              "chamber": "lower",
              "hashID": "SLDL-MN-47B-lower-ocd-person-d0e94ca0-b600-4567-88ee-34a861ca38aa",
              "builtID": "mn-lower-47b",
              "externalID": "ocd-person/d0e94ca0-b600-4567-88ee-34a861ca38aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ethan.cha@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15621"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-47B-lower-ocd-person-d0e94ca0-b600-4567-88ee-34a861ca38aa": 0
        }
      },
      "61A": {
        "members": [
          {
            "API_ID": "ocd-person/dbfdaa3b-e33c-4ea9-aa3c-6eee04a084e9",
            "firstName": "Frank",
            "lastName": "Hornstein",
            "fullName": "Frank Hornstein",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/61A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "61A",
              "chamber": "lower",
              "hashID": "SLDL-MN-61A-lower-ocd-person-dbfdaa3b-e33c-4ea9-aa3c-6eee04a084e9",
              "builtID": "mn-lower-61a",
              "externalID": "ocd-person/dbfdaa3b-e33c-4ea9-aa3c-6eee04a084e9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.frank.hornstein@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/10767"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-61A-lower-ocd-person-dbfdaa3b-e33c-4ea9-aa3c-6eee04a084e9": 0
        }
      },
      "59A": {
        "members": [
          {
            "API_ID": "ocd-person/d8da71a7-c99b-4510-8abc-2d8e78db3fff",
            "firstName": "Fue",
            "lastName": "Lee",
            "fullName": "Fue Lee",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/59A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "59A",
              "chamber": "lower",
              "hashID": "SLDL-MN-59A-lower-ocd-person-d8da71a7-c99b-4510-8abc-2d8e78db3fff",
              "builtID": "mn-lower-59a",
              "externalID": "ocd-person/d8da71a7-c99b-4510-8abc-2d8e78db3fff",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.fue.lee@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15468"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-59A-lower-ocd-person-d8da71a7-c99b-4510-8abc-2d8e78db3fff": 0
        }
      },
      "26A": {
        "members": [
          {
            "API_ID": "ocd-person/1091c938-5418-45bf-8f4a-d27e226338d4",
            "firstName": "Gene P.",
            "lastName": "Pelowski",
            "fullName": "Gene Pelowski",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/26A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "26A",
              "chamber": "lower",
              "hashID": "SLDL-MN-26A-lower-ocd-person-1091c938-5418-45bf-8f4a-d27e226338d4",
              "builtID": "mn-lower-26a",
              "externalID": "ocd-person/1091c938-5418-45bf-8f4a-d27e226338d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.gene.pelowski@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "257 Wilson St., Winona, MN 55987",
              "phone1": null,
              "phone2": "507-454-3282",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/10516"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-26A-lower-ocd-person-1091c938-5418-45bf-8f4a-d27e226338d4": 0
        }
      },
      "42B": {
        "members": [
          {
            "API_ID": "ocd-person/2cb0f6d7-006d-4f7a-a1a3-218eff6016aa",
            "firstName": "Ginny",
            "lastName": "Klevorn",
            "fullName": "Ginny Klevorn",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/42B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "42B",
              "chamber": "lower",
              "hashID": "SLDL-MN-42B-lower-ocd-person-2cb0f6d7-006d-4f7a-a1a3-218eff6016aa",
              "builtID": "mn-lower-42b",
              "externalID": "ocd-person/2cb0f6d7-006d-4f7a-a1a3-218eff6016aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ginny.klevorn@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4755 Kingsview Lane North, Plymouth, MN 55446",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15512"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-42B-lower-ocd-person-2cb0f6d7-006d-4f7a-a1a3-218eff6016aa": 0
        }
      },
      "26B": {
        "members": [
          {
            "API_ID": "ocd-person/5bf21441-27fe-4f78-8fc1-83ca3ee5700d",
            "firstName": "Gregory Michael",
            "lastName": "Davids",
            "fullName": "Greg Davids",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/26B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "26B",
              "chamber": "lower",
              "hashID": "SLDL-MN-26B-lower-ocd-person-5bf21441-27fe-4f78-8fc1-83ca3ee5700d",
              "builtID": "mn-lower-26b",
              "externalID": "ocd-person/5bf21441-27fe-4f78-8fc1-83ca3ee5700d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.greg.davids@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 32, Preston, MN 55965",
              "phone1": null,
              "phone2": "507-951-3893",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/10123"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-26B-lower-ocd-person-5bf21441-27fe-4f78-8fc1-83ca3ee5700d": 0
        }
      },
      "31A": {
        "members": [
          {
            "API_ID": "ocd-person/371f41ef-a432-4880-950b-f3ac4b17d02f",
            "firstName": "Harry",
            "lastName": "Niska",
            "fullName": "Harry Niska",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/31A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "31A",
              "chamber": "lower",
              "hashID": "SLDL-MN-31A-lower-ocd-person-371f41ef-a432-4880-950b-f3ac4b17d02f",
              "builtID": "mn-lower-31a",
              "externalID": "ocd-person/371f41ef-a432-4880-950b-f3ac4b17d02f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.harry.niska@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15608"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-31A-lower-ocd-person-371f41ef-a432-4880-950b-f3ac4b17d02f": 0
        }
      },
      "4A": {
        "members": [
          {
            "API_ID": "ocd-person/3ace0851-830e-42a1-8390-27c1142f2367",
            "firstName": "Heather",
            "lastName": "Keeler",
            "fullName": "Heather Keeler",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/04A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "4A",
              "chamber": "lower",
              "hashID": "SLDL-MN-4A-lower-ocd-person-3ace0851-830e-42a1-8390-27c1142f2367",
              "builtID": "mn-lower-4a",
              "externalID": "ocd-person/3ace0851-830e-42a1-8390-27c1142f2367",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.heather.keeler@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15539"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-4A-lower-ocd-person-3ace0851-830e-42a1-8390-27c1142f2367": 0
        }
      },
      "62B": {
        "members": [
          {
            "API_ID": "ocd-person/a4725c69-1edc-4eb2-b032-83fcf8d5456e",
            "firstName": "Hodan",
            "lastName": "Hassan",
            "fullName": "Hodan Hassan",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/62B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "62B",
              "chamber": "lower",
              "hashID": "SLDL-MN-62B-lower-ocd-person-a4725c69-1edc-4eb2-b032-83fcf8d5456e",
              "builtID": "mn-lower-62b",
              "externalID": "ocd-person/a4725c69-1edc-4eb2-b032-83fcf8d5456e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.hodan.hassan@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15530"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-62B-lower-ocd-person-a4725c69-1edc-4eb2-b032-83fcf8d5456e": 0
        }
      },
      "10B": {
        "members": [
          {
            "API_ID": "ocd-person/a916d408-21ae-4eee-a2d7-c8019478f521",
            "firstName": "Isaac M.",
            "lastName": "Schultz",
            "fullName": "Isaac Schultz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/10B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "10B",
              "chamber": "lower",
              "hashID": "SLDL-MN-10B-lower-ocd-person-a916d408-21ae-4eee-a2d7-c8019478f521",
              "builtID": "mn-lower-10b",
              "externalID": "ocd-person/a916d408-21ae-4eee-a2d7-c8019478f521",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.isaac.schultz@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15597"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-10B-lower-ocd-person-a916d408-21ae-4eee-a2d7-c8019478f521": 0
        }
      },
      "40B": {
        "members": [
          {
            "API_ID": "ocd-person/41099526-9f37-4d91-be38-e16637a815c9",
            "firstName": "Jamie",
            "lastName": "Becker-Finn",
            "fullName": "Jamie Becker-Finn",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/40B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "40B",
              "chamber": "lower",
              "hashID": "SLDL-MN-40B-lower-ocd-person-41099526-9f37-4d91-be38-e16637a815c9",
              "builtID": "mn-lower-40b",
              "externalID": "ocd-person/41099526-9f37-4d91-be38-e16637a815c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jamie.becker-finn@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1769 Lexington Ave. North Box 151, Roseville, MN 55113",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15457"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-40B-lower-ocd-person-41099526-9f37-4d91-be38-e16637a815c9": 0
        }
      },
      "61B": {
        "members": [
          {
            "API_ID": "ocd-person/b6ea5e13-3aad-438c-bed0-749a9bff80c3",
            "firstName": "James M.",
            "lastName": "Long",
            "fullName": "Jamie Long",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/61B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "61B",
              "chamber": "lower",
              "hashID": "SLDL-MN-61B-lower-ocd-person-b6ea5e13-3aad-438c-bed0-749a9bff80c3",
              "builtID": "mn-lower-61b",
              "externalID": "ocd-person/b6ea5e13-3aad-438c-bed0-749a9bff80c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jamie.long@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15529"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-61B-lower-ocd-person-b6ea5e13-3aad-438c-bed0-749a9bff80c3": 0
        }
      },
      "67B": {
        "members": [
          {
            "API_ID": "ocd-person/8892bfba-9b17-4378-8c85-f2d73d2b88ac",
            "firstName": "Jay",
            "lastName": "Xiong",
            "fullName": "Jay Xiong",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/67B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "67B",
              "chamber": "lower",
              "hashID": "SLDL-MN-67B-lower-ocd-person-8892bfba-9b17-4378-8c85-f2d73d2b88ac",
              "builtID": "mn-lower-67b",
              "externalID": "ocd-person/8892bfba-9b17-4378-8c85-f2d73d2b88ac",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jay.xiong@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15533"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-67B-lower-ocd-person-8892bfba-9b17-4378-8c85-f2d73d2b88ac": 0
        }
      },
      "9A": {
        "members": [
          {
            "API_ID": "ocd-person/edcf7ea6-b735-46b3-9d50-fa189cda6e8d",
            "firstName": "Jeff",
            "lastName": "Backer",
            "fullName": "Jeff Backer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/09A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "9A",
              "chamber": "lower",
              "hashID": "SLDL-MN-9A-lower-ocd-person-edcf7ea6-b735-46b3-9d50-fa189cda6e8d",
              "builtID": "mn-lower-9a",
              "externalID": "ocd-person/edcf7ea6-b735-46b3-9d50-fa189cda6e8d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jeff.backer@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15428"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-9A-lower-ocd-person-edcf7ea6-b735-46b3-9d50-fa189cda6e8d": 0
        }
      },
      "18A": {
        "members": [
          {
            "API_ID": "ocd-person/6864cbde-4569-4710-90ef-a492a1d560db",
            "firstName": "Jeff",
            "lastName": "Brand",
            "fullName": "Jeff Brand",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/18A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "18A",
              "chamber": "lower",
              "hashID": "SLDL-MN-18A-lower-ocd-person-6864cbde-4569-4710-90ef-a492a1d560db",
              "builtID": "mn-lower-18a",
              "externalID": "ocd-person/6864cbde-4569-4710-90ef-a492a1d560db",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jeff.brand@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "St. Peter, MN",
              "phone1": null,
              "phone2": "507-380-0510",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15501"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-18A-lower-ocd-person-6864cbde-4569-4710-90ef-a492a1d560db": 0
        }
      },
      "11A": {
        "members": [
          {
            "API_ID": "ocd-person/f6aa30f5-2e66-44ae-8de0-352c64ddafe8",
            "firstName": "Jeff A.",
            "lastName": "Dotseth",
            "fullName": "Jeff Dotseth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/11A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "11A",
              "chamber": "lower",
              "hashID": "SLDL-MN-11A-lower-ocd-person-f6aa30f5-2e66-44ae-8de0-352c64ddafe8",
              "builtID": "mn-lower-11a",
              "externalID": "ocd-person/f6aa30f5-2e66-44ae-8de0-352c64ddafe8",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jeff.dotseth@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 2, Kettle River, MN 55757",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15598"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-11A-lower-ocd-person-f6aa30f5-2e66-44ae-8de0-352c64ddafe8": 0
        }
      },
      "57B": {
        "members": [
          {
            "API_ID": "ocd-person/72c6929c-a329-4613-99a0-5e339f5a045f",
            "firstName": "Jeff",
            "lastName": "Witte",
            "fullName": "Jeff Witte",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/57B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "57B",
              "chamber": "lower",
              "hashID": "SLDL-MN-57B-lower-ocd-person-72c6929c-a329-4613-99a0-5e339f5a045f",
              "builtID": "mn-lower-57b",
              "externalID": "ocd-person/72c6929c-a329-4613-99a0-5e339f5a045f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jeff.witte@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Lakeville, MN",
              "phone1": null,
              "phone2": "612-845-2378",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15570"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-57B-lower-ocd-person-72c6929c-a329-4613-99a0-5e339f5a045f": 0
        }
      },
      "35B": {
        "members": [
          {
            "API_ID": "ocd-person/9ec4bda3-571f-4d6d-85f1-39a2d54d106a",
            "firstName": "Gerald F.",
            "lastName": "Newton",
            "fullName": "Jerry Newton",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/35B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "35B",
              "chamber": "lower",
              "hashID": "SLDL-MN-35B-lower-ocd-person-9ec4bda3-571f-4d6d-85f1-39a2d54d106a",
              "builtID": "mn-lower-35b",
              "externalID": "ocd-person/9ec4bda3-571f-4d6d-85f1-39a2d54d106a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.jerry.newton@senate.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "12095 Dogwood St. NW, Coon Rapids, MN 55448",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.leg.state.mn.us/members/profile/15315",
                "https://www.house.mn.gov/members/profile/15315"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-35B-lower-ocd-person-9ec4bda3-571f-4d6d-85f1-39a2d54d106a": 0
        }
      },
      "55A": {
        "members": [
          {
            "API_ID": "ocd-person/5099a61c-2d2a-41d0-a03d-ccbbb0edbf15",
            "firstName": "Jessica",
            "lastName": "Hanson",
            "fullName": "Jess Hanson",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/55A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "55A",
              "chamber": "lower",
              "hashID": "SLDL-MN-55A-lower-ocd-person-5099a61c-2d2a-41d0-a03d-ccbbb0edbf15",
              "builtID": "mn-lower-55a",
              "externalID": "ocd-person/5099a61c-2d2a-41d0-a03d-ccbbb0edbf15",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jessica.hanson@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Burnsville Office, MN",
              "phone1": null,
              "phone2": "651-271-9344",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15563"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-55A-lower-ocd-person-5099a61c-2d2a-41d0-a03d-ccbbb0edbf15": 0
        }
      },
      "4B": {
        "members": [
          {
            "API_ID": "ocd-person/0897d40e-d8b4-4b1e-b0cf-e0ae4e9685f2",
            "firstName": "James",
            "lastName": "Joy",
            "fullName": "Jim Joy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/04B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "4B",
              "chamber": "lower",
              "hashID": "SLDL-MN-4B-lower-ocd-person-0897d40e-d8b4-4b1e-b0cf-e0ae4e9685f2",
              "builtID": "mn-lower-4b",
              "externalID": "ocd-person/0897d40e-d8b4-4b1e-b0cf-e0ae4e9685f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jim.joy@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.leg.state.mn.us/members/profile/15591",
                "https://www.house.mn.gov/members/profile/15591"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-4B-lower-ocd-person-0897d40e-d8b4-4b1e-b0cf-e0ae4e9685f2": 0
        }
      },
      "48A": {
        "members": [
          {
            "API_ID": "ocd-person/d7012c12-e223-4409-823b-ba3e55be6677",
            "firstName": "James Alexander",
            "lastName": "Nash",
            "fullName": "Jim Nash",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/48A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "48A",
              "chamber": "lower",
              "hashID": "SLDL-MN-48A-lower-ocd-person-d7012c12-e223-4409-823b-ba3e55be6677",
              "builtID": "mn-lower-48a",
              "externalID": "ocd-person/d7012c12-e223-4409-823b-ba3e55be6677",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jim.nash@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1318 Dunsmore Drive, Waconia, MN 55387",
              "phone1": null,
              "phone2": "952-807-6416",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15441"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-48A-lower-ocd-person-d7012c12-e223-4409-823b-ba3e55be6677": 0
        }
      },
      "29A": {
        "members": [
          {
            "API_ID": "ocd-person/6292245e-1db4-4a8e-ba2a-a036e7b68044",
            "firstName": "Joseph P.",
            "lastName": "McDonald",
            "fullName": "Joe McDonald",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/29A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "29A",
              "chamber": "lower",
              "hashID": "SLDL-MN-29A-lower-ocd-person-6292245e-1db4-4a8e-ba2a-a036e7b68044",
              "builtID": "mn-lower-29a",
              "externalID": "ocd-person/6292245e-1db4-4a8e-ba2a-a036e7b68044",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.joe.mcdonald@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 51, Delano, MN 55328",
              "phone1": null,
              "phone2": "612-910-0310",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15360"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-29A-lower-ocd-person-6292245e-1db4-4a8e-ba2a-a036e7b68044": 0
        }
      },
      "21A": {
        "members": [
          {
            "API_ID": "ocd-person/f08531c2-0974-4606-ab08-f5453ff76edd",
            "firstName": "Joseph Roy",
            "lastName": "Schomacker",
            "fullName": "Joe Schomacker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/21A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "21A",
              "chamber": "lower",
              "hashID": "SLDL-MN-21A-lower-ocd-person-f08531c2-0974-4606-ab08-f5453ff76edd",
              "builtID": "mn-lower-21a",
              "externalID": "ocd-person/f08531c2-0974-4606-ab08-f5453ff76edd",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.joe.schomacker@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "105 S. Cedar St., Luverne, MN 56156",
              "phone1": null,
              "phone2": "507-935-0308",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15367"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-21A-lower-ocd-person-f08531c2-0974-4606-ab08-f5453ff76edd": 0
        }
      },
      "1A": {
        "members": [
          {
            "API_ID": "ocd-person/c86f501e-0b47-4e57-8f5a-d7d0be2392af",
            "firstName": "John",
            "lastName": "Burkel",
            "fullName": "John Burkel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/01A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "1A",
              "chamber": "lower",
              "hashID": "SLDL-MN-1A-lower-ocd-person-c86f501e-0b47-4e57-8f5a-d7d0be2392af",
              "builtID": "mn-lower-1a",
              "externalID": "ocd-person/c86f501e-0b47-4e57-8f5a-d7d0be2392af",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.john.burkel@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "24932 - 270th Ave., Badger, MN 56714",
              "phone1": null,
              "phone2": "218-528-3391",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15538"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-1A-lower-ocd-person-c86f501e-0b47-4e57-8f5a-d7d0be2392af": 0
        }
      },
      "56B": {
        "members": [
          {
            "API_ID": "ocd-person/5d8ac115-4a2b-4f0f-b70a-c1df26184891",
            "firstName": "John D.",
            "lastName": "Huot",
            "fullName": "John Huot",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/56B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "56B",
              "chamber": "lower",
              "hashID": "SLDL-MN-56B-lower-ocd-person-5d8ac115-4a2b-4f0f-b70a-c1df26184891",
              "builtID": "mn-lower-56b",
              "externalID": "ocd-person/5d8ac115-4a2b-4f0f-b70a-c1df26184891",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.john.huot@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15527"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-56B-lower-ocd-person-5d8ac115-4a2b-4f0f-b70a-c1df26184891": 0
        }
      },
      "19B": {
        "members": [
          {
            "API_ID": "ocd-person/78230568-c8eb-464c-aff8-5de9042b29ef",
            "firstName": "John",
            "lastName": "Petersburg",
            "fullName": "John Petersburg",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/19B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "19B",
              "chamber": "lower",
              "hashID": "SLDL-MN-19B-lower-ocd-person-78230568-c8eb-464c-aff8-5de9042b29ef",
              "builtID": "mn-lower-19b",
              "externalID": "ocd-person/78230568-c8eb-464c-aff8-5de9042b29ef",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.john.petersburg@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "700 19th Ave. NE, Waseca, MN 56093",
              "phone1": null,
              "phone2": "507-461-4377",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15410"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-19B-lower-ocd-person-78230568-c8eb-464c-aff8-5de9042b29ef": 0
        }
      },
      "57A": {
        "members": [
          {
            "API_ID": "ocd-person/8c4ed90f-f243-43f1-b205-0edd374777d8",
            "firstName": "Jon",
            "lastName": "Koznick",
            "fullName": "Jon Koznick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/57A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "57A",
              "chamber": "lower",
              "hashID": "SLDL-MN-57A-lower-ocd-person-8c4ed90f-f243-43f1-b205-0edd374777d8",
              "builtID": "mn-lower-57a",
              "externalID": "ocd-person/8c4ed90f-f243-43f1-b205-0edd374777d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jon.koznick@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15436"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-57A-lower-ocd-person-8c4ed90f-f243-43f1-b205-0edd374777d8": 0
        }
      },
      "6B": {
        "members": [
          {
            "API_ID": "ocd-person/18549c8f-0d80-4c2f-bdcc-f3762c3acb19",
            "firstName": "Joshua",
            "lastName": "Heintzeman",
            "fullName": "Josh Heintzeman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/06B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "6B",
              "chamber": "lower",
              "hashID": "SLDL-MN-6B-lower-ocd-person-18549c8f-0d80-4c2f-bdcc-f3762c3acb19",
              "builtID": "mn-lower-6b",
              "externalID": "ocd-person/18549c8f-0d80-4c2f-bdcc-f3762c3acb19",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.josh.heintzeman@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15435"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-6B-lower-ocd-person-18549c8f-0d80-4c2f-bdcc-f3762c3acb19": 0
        }
      },
      "33B": {
        "members": [
          {
            "API_ID": "ocd-person/4558fb30-435a-4fde-acdd-bdcc82c7da45",
            "firstName": "Josiah",
            "lastName": "Hill",
            "fullName": "Josiah Hill",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/33B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "33B",
              "chamber": "lower",
              "hashID": "SLDL-MN-33B-lower-ocd-person-4558fb30-435a-4fde-acdd-bdcc82c7da45",
              "builtID": "mn-lower-33b",
              "externalID": "ocd-person/4558fb30-435a-4fde-acdd-bdcc82c7da45",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.josiah.hill@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15611"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-33B-lower-ocd-person-4558fb30-435a-4fde-acdd-bdcc82c7da45": 0
        }
      },
      "55B": {
        "members": [
          {
            "API_ID": "ocd-person/1912cdc1-267c-4d36-acec-08896502658e",
            "firstName": "Kaela",
            "lastName": "Berg",
            "fullName": "Kaela Berg",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/55B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "55B",
              "chamber": "lower",
              "hashID": "SLDL-MN-55B-lower-ocd-person-1912cdc1-267c-4d36-acec-08896502658e",
              "builtID": "mn-lower-55b",
              "externalID": "ocd-person/1912cdc1-267c-4d36-acec-08896502658e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kaela.berg@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15550"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-55B-lower-ocd-person-1912cdc1-267c-4d36-acec-08896502658e": 0
        }
      },
      "64A": {
        "members": [
          {
            "API_ID": "ocd-person/92da7ead-83b1-4d16-9acd-c403152fa0b0",
            "firstName": "Kaohly Vang",
            "lastName": "Her",
            "fullName": "Kaohly Her",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/64A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "64A",
              "chamber": "lower",
              "hashID": "SLDL-MN-64A-lower-ocd-person-92da7ead-83b1-4d16-9acd-c403152fa0b0",
              "builtID": "mn-lower-64a",
              "externalID": "ocd-person/92da7ead-83b1-4d16-9acd-c403152fa0b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kaohly.her@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15532"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-64A-lower-ocd-person-92da7ead-83b1-4d16-9acd-c403152fa0b0": 0
        }
      },
      "40A": {
        "members": [
          {
            "API_ID": "ocd-person/3eb5eb9d-cd17-4a48-ac4c-4f7a7b8e133e",
            "firstName": "Kelly",
            "lastName": "Moller",
            "fullName": "Kelly Moller",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/40A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "40A",
              "chamber": "lower",
              "hashID": "SLDL-MN-40A-lower-ocd-person-3eb5eb9d-cd17-4a48-ac4c-4f7a7b8e133e",
              "builtID": "mn-lower-40a",
              "externalID": "ocd-person/3eb5eb9d-cd17-4a48-ac4c-4f7a7b8e133e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kelly.moller@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15511"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-40A-lower-ocd-person-3eb5eb9d-cd17-4a48-ac4c-4f7a7b8e133e": 0
        }
      },
      "25A": {
        "members": [
          {
            "API_ID": "ocd-person/6902f219-7571-4bf1-a0af-ffa9eae2c23f",
            "firstName": "Kimberly Kearnes",
            "lastName": "Hicks",
            "fullName": "Kim Hicks",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/25A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "25A",
              "chamber": "lower",
              "hashID": "SLDL-MN-25A-lower-ocd-person-6902f219-7571-4bf1-a0af-ffa9eae2c23f",
              "builtID": "mn-lower-25a",
              "externalID": "ocd-person/6902f219-7571-4bf1-a0af-ffa9eae2c23f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kim.hicks@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15605"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-25A-lower-ocd-person-6902f219-7571-4bf1-a0af-ffa9eae2c23f": 0
        }
      },
      "5A": {
        "members": [
          {
            "API_ID": "ocd-person/d9b75b0d-6065-4efc-81e2-03084dee070a",
            "firstName": "Krista",
            "lastName": "Knudsen",
            "fullName": "Krista Knudsen",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/05A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "5A",
              "chamber": "lower",
              "hashID": "SLDL-MN-5A-lower-ocd-person-d9b75b0d-6065-4efc-81e2-03084dee070a",
              "builtID": "mn-lower-5a",
              "externalID": "ocd-person/d9b75b0d-6065-4efc-81e2-03084dee070a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.krista.knudsen@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15592"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-5A-lower-ocd-person-d9b75b0d-6065-4efc-81e2-03084dee070a": 0
        }
      },
      "58A": {
        "members": [
          {
            "API_ID": "ocd-person/ed73b309-bb2f-4805-a33b-a3fb760e6664",
            "firstName": "Kristi Achor",
            "lastName": "Pursell",
            "fullName": "Kristi Pursell",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/58A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "58A",
              "chamber": "lower",
              "hashID": "SLDL-MN-58A-lower-ocd-person-ed73b309-bb2f-4805-a33b-a3fb760e6664",
              "builtID": "mn-lower-58a",
              "externalID": "ocd-person/ed73b309-bb2f-4805-a33b-a3fb760e6664",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kristi.pursell@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15571"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-58A-lower-ocd-person-ed73b309-bb2f-4805-a33b-a3fb760e6664": 0
        }
      },
      "37B": {
        "members": [
          {
            "API_ID": "ocd-person/ad111cf3-f5f9-4e94-a087-dc39ce1fb136",
            "firstName": "Kristin",
            "lastName": "Bahner",
            "fullName": "Kristin Bahner",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/37B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "37B",
              "chamber": "lower",
              "hashID": "SLDL-MN-37B-lower-ocd-person-ad111cf3-f5f9-4e94-a087-dc39ce1fb136",
              "builtID": "mn-lower-37b",
              "externalID": "ocd-person/ad111cf3-f5f9-4e94-a087-dc39ce1fb136",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kristin.bahner@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15505"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-37B-lower-ocd-person-ad111cf3-f5f9-4e94-a087-dc39ce1fb136": 0
        }
      },
      "37A": {
        "members": [
          {
            "API_ID": "ocd-person/d32d0b31-20af-4068-a5bd-42fd0a072ded",
            "firstName": "Kristin",
            "lastName": "Robbins",
            "fullName": "Kristin Robbins",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/37A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "37A",
              "chamber": "lower",
              "hashID": "SLDL-MN-37A-lower-ocd-person-d32d0b31-20af-4068-a5bd-42fd0a072ded",
              "builtID": "mn-lower-37a",
              "externalID": "ocd-person/d32d0b31-20af-4068-a5bd-42fd0a072ded",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kristin.robbins@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15504"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-37A-lower-ocd-person-d32d0b31-20af-4068-a5bd-42fd0a072ded": 0
        }
      },
      "46A": {
        "members": [
          {
            "API_ID": "ocd-person/a4451923-cc1b-4d5d-9c14-5c50062335a2",
            "firstName": "Larry",
            "lastName": "Kraft",
            "fullName": "Larry Kraft",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/46A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "46A",
              "chamber": "lower",
              "hashID": "SLDL-MN-46A-lower-ocd-person-a4451923-cc1b-4d5d-9c14-5c50062335a2",
              "builtID": "mn-lower-46a",
              "externalID": "ocd-person/a4451923-cc1b-4d5d-9c14-5c50062335a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.larry.kraft@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "St. Louis Park, MN",
              "phone1": null,
              "phone2": "952-715-7535",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15619"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-46A-lower-ocd-person-a4451923-cc1b-4d5d-9c14-5c50062335a2": 0
        }
      },
      "49A": {
        "members": [
          {
            "API_ID": "ocd-person/d954ee99-d88d-4514-bab9-1daf4a2e05db",
            "firstName": "Laurie",
            "lastName": "Pryor",
            "fullName": "Laurie Pryor",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/49A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "49A",
              "chamber": "lower",
              "hashID": "SLDL-MN-49A-lower-ocd-person-d954ee99-d88d-4514-bab9-1daf4a2e05db",
              "builtID": "mn-lower-49a",
              "externalID": "ocd-person/d954ee99-d88d-4514-bab9-1daf4a2e05db",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.laurie.pryor@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15472"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-49A-lower-ocd-person-d954ee99-d88d-4514-bab9-1daf4a2e05db": 0
        }
      },
      "66A": {
        "members": [
          {
            "API_ID": "ocd-person/afff2a0a-1b2d-480e-a90d-8609842f0927",
            "firstName": "Leigh",
            "lastName": "Finke",
            "fullName": "Leigh Finke",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/66A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "66A",
              "chamber": "lower",
              "hashID": "SLDL-MN-66A-lower-ocd-person-afff2a0a-1b2d-480e-a90d-8609842f0927",
              "builtID": "mn-lower-66a",
              "externalID": "ocd-person/afff2a0a-1b2d-480e-a90d-8609842f0927",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.leigh.finke@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 40206, St. Paul, MN 55104",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15575"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-66A-lower-ocd-person-afff2a0a-1b2d-480e-a90d-8609842f0927": 0
        }
      },
      "44B": {
        "members": [
          {
            "API_ID": "ocd-person/00240593-7a31-4725-8c31-c723115732bb",
            "firstName": "Leon Michael",
            "lastName": "Lillie",
            "fullName": "Leon Lillie",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/44B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "44B",
              "chamber": "lower",
              "hashID": "SLDL-MN-44B-lower-ocd-person-00240593-7a31-4725-8c31-c723115732bb",
              "builtID": "mn-lower-44b",
              "externalID": "ocd-person/00240593-7a31-4725-8c31-c723115732bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.leon.lillie@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2500 7th Ave. East, North St. Paul, MN 55109",
              "phone1": null,
              "phone2": "651-770-9260",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/12269"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-44B-lower-ocd-person-00240593-7a31-4725-8c31-c723115732bb": 0
        }
      },
      "13A": {
        "members": [
          {
            "API_ID": "ocd-person/7b7b8d46-05d4-4285-b728-abcad1a430d5",
            "firstName": "Lisa",
            "lastName": "Demuth",
            "fullName": "Lisa Demuth",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/13A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "13A",
              "chamber": "lower",
              "hashID": "SLDL-MN-13A-lower-ocd-person-7b7b8d46-05d4-4285-b728-abcad1a430d5",
              "builtID": "mn-lower-13a",
              "externalID": "ocd-person/7b7b8d46-05d4-4285-b728-abcad1a430d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.lisa.demuth@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 516, Cold Spring, MN 56320",
              "phone1": null,
              "phone2": "320-685-8930",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15498"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-13A-lower-ocd-person-7b7b8d46-05d4-4285-b728-abcad1a430d5": 0
        }
      },
      "67A": {
        "members": [
          {
            "API_ID": "ocd-person/f9dbe591-5c2d-4a25-836a-ae05863b40ff",
            "firstName": "Kaozouapa Elizabeth",
            "lastName": "Lee",
            "fullName": "Liz Lee",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/67A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "67A",
              "chamber": "lower",
              "hashID": "SLDL-MN-67A-lower-ocd-person-f9dbe591-5c2d-4a25-836a-ae05863b40ff",
              "builtID": "mn-lower-67a",
              "externalID": "ocd-person/f9dbe591-5c2d-4a25-836a-ae05863b40ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.liz.lee@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15576"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-67A-lower-ocd-person-f9dbe591-5c2d-4a25-836a-ae05863b40ff": 0
        }
      },
      "52A": {
        "members": [
          {
            "API_ID": "ocd-person/e57307af-8e06-4c44-997d-a2cad2cdb231",
            "firstName": "Liz",
            "lastName": "Reyer",
            "fullName": "Liz Reyer",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/52A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "52A",
              "chamber": "lower",
              "hashID": "SLDL-MN-52A-lower-ocd-person-e57307af-8e06-4c44-997d-a2cad2cdb231",
              "builtID": "mn-lower-52a",
              "externalID": "ocd-person/e57307af-8e06-4c44-997d-a2cad2cdb231",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.liz.reyer@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.leg.state.mn.us/members/profile/15549",
                "https://www.house.mn.gov/members/profile/15549"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-52A-lower-ocd-person-e57307af-8e06-4c44-997d-a2cad2cdb231": 0
        }
      },
      "48B": {
        "members": [
          {
            "API_ID": "ocd-person/75e19dbb-1f4b-4ace-b87e-201e445d25b4",
            "firstName": "Lucille",
            "lastName": "Rehm",
            "fullName": "Lucy Rehm",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/48B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "48B",
              "chamber": "lower",
              "hashID": "SLDL-MN-48B-lower-ocd-person-75e19dbb-1f4b-4ace-b87e-201e445d25b4",
              "builtID": "mn-lower-48b",
              "externalID": "ocd-person/75e19dbb-1f4b-4ace-b87e-201e445d25b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.lucy.rehm@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15622"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-48B-lower-ocd-person-75e19dbb-1f4b-4ace-b87e-201e445d25b4": 0
        }
      },
      "18B": {
        "members": [
          {
            "API_ID": "ocd-person/b0d93fc5-2efc-4908-aceb-7b2fc208b34d",
            "firstName": "Luke",
            "lastName": "Frederick",
            "fullName": "Luke Frederick",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/18B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "18B",
              "chamber": "lower",
              "hashID": "SLDL-MN-18B-lower-ocd-person-b0d93fc5-2efc-4908-aceb-7b2fc208b34d",
              "builtID": "mn-lower-18b",
              "externalID": "ocd-person/b0d93fc5-2efc-4908-aceb-7b2fc208b34d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.luke.frederick@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15542"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-18B-lower-ocd-person-b0d93fc5-2efc-4908-aceb-7b2fc208b34d": 0
        }
      },
      "65B": {
        "members": [
          {
            "API_ID": "ocd-person/d1c094c0-6184-49e4-a18a-d5b900caca1b",
            "firstName": "Maria Isa",
            "lastName": "Perez-Vega",
            "fullName": "María Isa Pérez-Vega",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/65B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "65B",
              "chamber": "lower",
              "hashID": "SLDL-MN-65B-lower-ocd-person-d1c094c0-6184-49e4-a18a-d5b900caca1b",
              "builtID": "mn-lower-65b",
              "externalID": "ocd-person/d1c094c0-6184-49e4-a18a-d5b900caca1b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.maria.isa.perez-vega@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15574"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-65B-lower-ocd-person-d1c094c0-6184-49e4-a18a-d5b900caca1b": 0
        }
      },
      "29B": {
        "members": [
          {
            "API_ID": "ocd-person/0682bc6b-e4f2-427a-917b-96fbc49bb741",
            "firstName": "Marion Daniels",
            "lastName": "Rarick",
            "fullName": "Marion Rarick",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/29B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "29B",
              "chamber": "lower",
              "hashID": "SLDL-MN-29B-lower-ocd-person-0682bc6b-e4f2-427a-917b-96fbc49bb741",
              "builtID": "mn-lower-29b",
              "externalID": "ocd-person/0682bc6b-e4f2-427a-917b-96fbc49bb741",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.marion.rarick@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15409"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-29B-lower-ocd-person-0682bc6b-e4f2-427a-917b-96fbc49bb741": 0
        }
      },
      "21B": {
        "members": [
          {
            "API_ID": "ocd-person/9837607c-b08b-4742-ad5f-3e8f72f8b7b4",
            "firstName": "Marjorie Judith",
            "lastName": "Fogelman",
            "fullName": "Marj Fogelman",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/21B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "21B",
              "chamber": "lower",
              "hashID": "SLDL-MN-21B-lower-ocd-person-9837607c-b08b-4742-ad5f-3e8f72f8b7b4",
              "builtID": "mn-lower-21b",
              "externalID": "ocd-person/9837607c-b08b-4742-ad5f-3e8f72f8b7b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.marj.fogelman@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 141, Fulda, MN 56131",
              "phone1": null,
              "phone2": "507-370-1112",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15604"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-21B-lower-ocd-person-9837607c-b08b-4742-ad5f-3e8f72f8b7b4": 0
        }
      },
      "41A": {
        "members": [
          {
            "API_ID": "ocd-person/f9d8c8e5-df64-42c2-befa-2dcd788b160f",
            "firstName": "Mark",
            "lastName": "Wiens",
            "fullName": "Mark Wiens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/41A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "41A",
              "chamber": "lower",
              "hashID": "SLDL-MN-41A-lower-ocd-person-f9d8c8e5-df64-42c2-befa-2dcd788b160f",
              "builtID": "mn-lower-41a",
              "externalID": "ocd-person/f9d8c8e5-df64-42c2-befa-2dcd788b160f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.mark.wiens@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15615"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-41A-lower-ocd-person-f9d8c8e5-df64-42c2-befa-2dcd788b160f": 0
        }
      },
      "53A": {
        "members": [
          {
            "API_ID": "ocd-person/1acddc90-d32a-4495-a769-27f8b57193b7",
            "firstName": "Mary Frances",
            "lastName": "Clardy",
            "fullName": "Mary Clardy",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/53A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "53A",
              "chamber": "lower",
              "hashID": "SLDL-MN-53A-lower-ocd-person-1acddc90-d32a-4495-a769-27f8b57193b7",
              "builtID": "mn-lower-53a",
              "externalID": "ocd-person/1acddc90-d32a-4495-a769-27f8b57193b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.mary.frances.clardy@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15568"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-53A-lower-ocd-person-1acddc90-d32a-4495-a769-27f8b57193b7": 0
        }
      },
      "12B": {
        "members": [
          {
            "API_ID": "ocd-person/1b284763-8577-49ab-b641-f4f079196646",
            "firstName": "Mary Bensoni",
            "lastName": "Franson",
            "fullName": "Mary Franson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/12B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "12B",
              "chamber": "lower",
              "hashID": "SLDL-MN-12B-lower-ocd-person-1b284763-8577-49ab-b641-f4f079196646",
              "builtID": "mn-lower-12b",
              "externalID": "ocd-person/1b284763-8577-49ab-b641-f4f079196646",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.mary.franson@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15347"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-12B-lower-ocd-person-1b284763-8577-49ab-b641-f4f079196646": 0
        }
      },
      "2B": {
        "members": [
          {
            "API_ID": "ocd-person/cbab855f-4a47-4afd-b74a-468a4545b82b",
            "firstName": "Matt",
            "lastName": "Bliss",
            "fullName": "Matt Bliss",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/02B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "2B",
              "chamber": "lower",
              "hashID": "SLDL-MN-2B-lower-ocd-person-cbab855f-4a47-4afd-b74a-468a4545b82b",
              "builtID": "mn-lower-2b",
              "externalID": "ocd-person/cbab855f-4a47-4afd-b74a-468a4545b82b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.matt.bliss@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15458"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-2B-lower-ocd-person-cbab855f-4a47-4afd-b74a-468a4545b82b": 0
        }
      },
      "2A": {
        "members": [
          {
            "API_ID": "ocd-person/a6b8f2bf-ad39-4884-9274-11caba55a064",
            "firstName": "Matthew",
            "lastName": "Grossell",
            "fullName": "Matt Grossell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/02A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "2A",
              "chamber": "lower",
              "hashID": "SLDL-MN-2A-lower-ocd-person-a6b8f2bf-ad39-4884-9274-11caba55a064",
              "builtID": "mn-lower-2a",
              "externalID": "ocd-person/a6b8f2bf-ad39-4884-9274-11caba55a064",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.matt.grossell@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "227 Main St. North, Clearbrook, MN 56634",
              "phone1": null,
              "phone2": "218-209-7661",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15461"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-2A-lower-ocd-person-a6b8f2bf-ad39-4884-9274-11caba55a064": 0
        }
      },
      "32B": {
        "members": [
          {
            "API_ID": "ocd-person/662f07d4-7dd5-4549-bded-c8b5a200e054",
            "firstName": "Matt",
            "lastName": "Norris",
            "fullName": "Matt Norris",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/32B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "32B",
              "chamber": "lower",
              "hashID": "SLDL-MN-32B-lower-ocd-person-662f07d4-7dd5-4549-bded-c8b5a200e054",
              "builtID": "mn-lower-32b",
              "externalID": "ocd-person/662f07d4-7dd5-4549-bded-c8b5a200e054",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.matt.norris@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "11775 Vermillion St. NE Unit A, Blaine, MN 55449",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15609"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-32B-lower-ocd-person-662f07d4-7dd5-4549-bded-c8b5a200e054": 0
        }
      },
      "34B": {
        "members": [
          {
            "API_ID": "ocd-person/b7309389-b5e6-4557-9b87-6ffba0768db4",
            "firstName": "Melissa",
            "lastName": "Hortman",
            "fullName": "Melissa Hortman",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/34B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "34B",
              "chamber": "lower",
              "hashID": "SLDL-MN-34B-lower-ocd-person-b7309389-b5e6-4557-9b87-6ffba0768db4",
              "builtID": "mn-lower-34b",
              "externalID": "ocd-person/b7309389-b5e6-4557-9b87-6ffba0768db4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.melissa.hortman@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/12266"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-34B-lower-ocd-person-b7309389-b5e6-4557-9b87-6ffba0768db4": 0
        }
      },
      "51A": {
        "members": [
          {
            "API_ID": "ocd-person/8910b3db-698e-4019-b976-0ee19ecb8ca0",
            "firstName": "Michael",
            "lastName": "Howard",
            "fullName": "Michael Howard",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/51A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "51A",
              "chamber": "lower",
              "hashID": "SLDL-MN-51A-lower-ocd-person-8910b3db-698e-4019-b976-0ee19ecb8ca0",
              "builtID": "mn-lower-51a",
              "externalID": "ocd-person/8910b3db-698e-4019-b976-0ee19ecb8ca0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.michael.howard@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15518"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-51A-lower-ocd-person-8910b3db-698e-4019-b976-0ee19ecb8ca0": 0
        }
      },
      "38A": {
        "members": [
          {
            "API_ID": "ocd-person/ba4ad211-a91c-4cd4-b7aa-0224f4547b13",
            "firstName": "Michael V.",
            "lastName": "Nelson",
            "fullName": "Mike Nelson",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/38A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "38A",
              "chamber": "lower",
              "hashID": "SLDL-MN-38A-lower-ocd-person-ba4ad211-a91c-4cd4-b7aa-0224f4547b13",
              "builtID": "mn-lower-38a",
              "externalID": "ocd-person/ba4ad211-a91c-4cd4-b7aa-0224f4547b13",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.michael.nelson@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7441 Hampshire Ave. North, Brooklyn Park, MN 55428",
              "phone1": null,
              "phone2": "763-561-2795",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/10779"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-38A-lower-ocd-person-ba4ad211-a91c-4cd4-b7aa-0224f4547b13": 0
        }
      },
      "43B": {
        "members": [
          {
            "API_ID": "ocd-person/4815983e-d0b9-4ebc-855f-bb9eac8aa5ae",
            "firstName": "Michael J.A.",
            "lastName": "Freiberg",
            "fullName": "Mike Freiberg",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/43B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "43B",
              "chamber": "lower",
              "hashID": "SLDL-MN-43B-lower-ocd-person-4815983e-d0b9-4ebc-855f-bb9eac8aa5ae",
              "builtID": "mn-lower-43b",
              "externalID": "ocd-person/4815983e-d0b9-4ebc-855f-bb9eac8aa5ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.mike.freiberg@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15398"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-43B-lower-ocd-person-4815983e-d0b9-4ebc-855f-bb9eac8aa5ae": 0
        }
      },
      "5B": {
        "members": [
          {
            "API_ID": "ocd-person/4c34f3d9-8890-4145-8a96-92ab23f046c0",
            "firstName": "Mike",
            "lastName": "Wiener",
            "fullName": "Mike Wiener",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/05B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "5B",
              "chamber": "lower",
              "hashID": "SLDL-MN-5B-lower-ocd-person-4c34f3d9-8890-4145-8a96-92ab23f046c0",
              "builtID": "mn-lower-5b",
              "externalID": "ocd-person/4c34f3d9-8890-4145-8a96-92ab23f046c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.mike.wiener@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "17576 231st Ave., Long Prairie, MN 56347",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15593"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-5B-lower-ocd-person-4c34f3d9-8890-4145-8a96-92ab23f046c0": 0
        }
      },
      "60B": {
        "members": [
          {
            "API_ID": "ocd-person/591ea10b-1362-434e-90be-2db0877a0e9c",
            "firstName": "Mohamud",
            "lastName": "Noor",
            "fullName": "Mohamud Noor",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/60B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "60B",
              "chamber": "lower",
              "hashID": "SLDL-MN-60B-lower-ocd-person-591ea10b-1362-434e-90be-2db0877a0e9c",
              "builtID": "mn-lower-60b",
              "externalID": "ocd-person/591ea10b-1362-434e-90be-2db0877a0e9c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.mohamud.noor@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 581492, Minneapolis, MN 55458",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15528"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-60B-lower-ocd-person-591ea10b-1362-434e-90be-2db0877a0e9c": 0
        }
      },
      "3B": {
        "members": [
          {
            "API_ID": "ocd-person/1b1055a8-959c-452c-a2a8-106a4ccef20d",
            "firstName": "Natalie",
            "lastName": "Zeleznikar",
            "fullName": "Natalie Zeleznikar",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/03B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "3B",
              "chamber": "lower",
              "hashID": "SLDL-MN-3B-lower-ocd-person-1b1055a8-959c-452c-a2a8-106a4ccef20d",
              "builtID": "mn-lower-3b",
              "externalID": "ocd-person/1b1055a8-959c-452c-a2a8-106a4ccef20d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.natalie.zeleznikar@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15624"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-3B-lower-ocd-person-1b1055a8-959c-452c-a2a8-106a4ccef20d": 0
        }
      },
      "51B": {
        "members": [
          {
            "API_ID": "ocd-person/63b26a4d-ae7c-4267-89ab-dfa4a1f341ad",
            "firstName": "Nathan",
            "lastName": "Coulter",
            "fullName": "Nathan Coulter",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/51B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "51B",
              "chamber": "lower",
              "hashID": "SLDL-MN-51B-lower-ocd-person-63b26a4d-ae7c-4267-89ab-dfa4a1f341ad",
              "builtID": "mn-lower-51b",
              "externalID": "ocd-person/63b26a4d-ae7c-4267-89ab-dfa4a1f341ad",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.nathan.coulter@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "10000 Emerson Ave. South, Bloomington, MN 55431",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15567"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-51B-lower-ocd-person-63b26a4d-ae7c-4267-89ab-dfa4a1f341ad": 0
        }
      },
      "11B": {
        "members": [
          {
            "API_ID": "ocd-person/172cb289-beec-4bc1-8627-f027f2cd3769",
            "firstName": "Nathan",
            "lastName": "Nelson",
            "fullName": "Nathan Nelson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/11B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "11B",
              "chamber": "lower",
              "hashID": "SLDL-MN-11B-lower-ocd-person-172cb289-beec-4bc1-8627-f027f2cd3769",
              "builtID": "mn-lower-11b",
              "externalID": "ocd-person/172cb289-beec-4bc1-8627-f027f2cd3769",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.nathan.nelson@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Hinckley, MN",
              "phone1": null,
              "phone2": "320-333-0823",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15534"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-11B-lower-ocd-person-172cb289-beec-4bc1-8627-f027f2cd3769": 0
        }
      },
      "42A": {
        "members": [
          {
            "API_ID": "ocd-person/764db471-e56d-4ef0-9df8-4e7a164c3b7e",
            "firstName": "Ned",
            "lastName": "Carroll",
            "fullName": "Ned Carroll",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/42A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "42A",
              "chamber": "lower",
              "hashID": "SLDL-MN-42A-lower-ocd-person-764db471-e56d-4ef0-9df8-4e7a164c3b7e",
              "builtID": "mn-lower-42a",
              "externalID": "ocd-person/764db471-e56d-4ef0-9df8-4e7a164c3b7e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ned.carroll@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15617"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-42A-lower-ocd-person-764db471-e56d-4ef0-9df8-4e7a164c3b7e": 0
        }
      },
      "32A": {
        "members": [
          {
            "API_ID": "ocd-person/171224fc-8a8d-4516-aa87-a2f5bcf1ea46",
            "firstName": "Nolan",
            "lastName": "West",
            "fullName": "Nolan West",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/32A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "32A",
              "chamber": "lower",
              "hashID": "SLDL-MN-32A-lower-ocd-person-171224fc-8a8d-4516-aa87-a2f5bcf1ea46",
              "builtID": "mn-lower-32a",
              "externalID": "ocd-person/171224fc-8a8d-4516-aa87-a2f5bcf1ea46",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.nolan.west@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15476"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-32A-lower-ocd-person-171224fc-8a8d-4516-aa87-a2f5bcf1ea46": 0
        }
      },
      "20A": {
        "members": [
          {
            "API_ID": "ocd-person/26c19220-1368-4453-81c9-3b0c395e3949",
            "firstName": "Pam",
            "lastName": "Altendorf",
            "fullName": "Pam Altendorf",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/20A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "20A",
              "chamber": "lower",
              "hashID": "SLDL-MN-20A-lower-ocd-person-26c19220-1368-4453-81c9-3b0c395e3949",
              "builtID": "mn-lower-20a",
              "externalID": "ocd-person/26c19220-1368-4453-81c9-3b0c395e3949",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.pam.altendorf@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15602"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-20A-lower-ocd-person-26c19220-1368-4453-81c9-3b0c395e3949": 0
        }
      },
      "58B": {
        "members": [
          {
            "API_ID": "ocd-person/71d15539-3706-417e-9e36-3d66189beb83",
            "firstName": "Patrick Lee",
            "lastName": "Garofalo",
            "fullName": "Pat Garofalo",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/58B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "58B",
              "chamber": "lower",
              "hashID": "SLDL-MN-58B-lower-ocd-person-71d15539-3706-417e-9e36-3d66189beb83",
              "builtID": "mn-lower-58b",
              "externalID": "ocd-person/71d15539-3706-417e-9e36-3d66189beb83",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.pat.garofalo@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/12262"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-58B-lower-ocd-person-71d15539-3706-417e-9e36-3d66189beb83": 0
        }
      },
      "23B": {
        "members": [
          {
            "API_ID": "ocd-person/71df2690-6a54-4452-960a-54a5b6540bc7",
            "firstName": "Patricia",
            "lastName": "Mueller",
            "fullName": "Patricia Mueller",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/23B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "23B",
              "chamber": "lower",
              "hashID": "SLDL-MN-23B-lower-ocd-person-71df2690-6a54-4452-960a-54a5b6540bc7",
              "builtID": "mn-lower-23b",
              "externalID": "ocd-person/71df2690-6a54-4452-960a-54a5b6540bc7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.patricia.mueller@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1304 19th Ave. SW, Austin, MN 55912",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15561"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-23B-lower-ocd-person-71df2690-6a54-4452-960a-54a5b6540bc7": 0
        }
      },
      "33A": {
        "members": [
          {
            "API_ID": "ocd-person/4bab18ef-55dc-44bf-adfe-8dbdf9488a09",
            "firstName": "Patricia",
            "lastName": "Anderson",
            "fullName": "Patti Anderson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/33A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "33A",
              "chamber": "lower",
              "hashID": "SLDL-MN-33A-lower-ocd-person-4bab18ef-55dc-44bf-adfe-8dbdf9488a09",
              "builtID": "mn-lower-33a",
              "externalID": "ocd-person/4bab18ef-55dc-44bf-adfe-8dbdf9488a09",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.patti.anderson@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15610"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-33A-lower-ocd-person-4bab18ef-55dc-44bf-adfe-8dbdf9488a09": 0
        }
      },
      "45B": {
        "members": [
          {
            "API_ID": "ocd-person/b393cb61-cb71-4cd0-8132-6e21c2a7202d",
            "firstName": "Patty",
            "lastName": "Acomb",
            "fullName": "Patty Acomb",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/45B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "45B",
              "chamber": "lower",
              "hashID": "SLDL-MN-45B-lower-ocd-person-b393cb61-cb71-4cd0-8132-6e21c2a7202d",
              "builtID": "mn-lower-45b",
              "externalID": "ocd-person/b393cb61-cb71-4cd0-8132-6e21c2a7202d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.patty.acomb@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15513"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-45B-lower-ocd-person-b393cb61-cb71-4cd0-8132-6e21c2a7202d": 0
        }
      },
      "12A": {
        "members": [
          {
            "API_ID": "ocd-person/ab35dde5-226a-4427-9344-c74d8291a75f",
            "firstName": "Paul H.",
            "lastName": "Anderson",
            "fullName": "Paul Anderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/12A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "12A",
              "chamber": "lower",
              "hashID": "SLDL-MN-12A-lower-ocd-person-ab35dde5-226a-4427-9344-c74d8291a75f",
              "builtID": "mn-lower-12a",
              "externalID": "ocd-person/ab35dde5-226a-4427-9344-c74d8291a75f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.paul.anderson@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Starbuck, MN",
              "phone1": null,
              "phone2": "320-239-2726",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15301"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-12A-lower-ocd-person-ab35dde5-226a-4427-9344-c74d8291a75f": 0
        }
      },
      "30B": {
        "members": [
          {
            "API_ID": "ocd-person/3e0eb8aa-b66c-4b30-9a0e-f1326c6f0589",
            "firstName": "Paul",
            "lastName": "Novotny",
            "fullName": "Paul Novotny",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/30B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "30B",
              "chamber": "lower",
              "hashID": "SLDL-MN-30B-lower-ocd-person-3e0eb8aa-b66c-4b30-9a0e-f1326c6f0589",
              "builtID": "mn-lower-30b",
              "externalID": "ocd-person/3e0eb8aa-b66c-4b30-9a0e-f1326c6f0589",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.paul.novotny@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15536"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-30B-lower-ocd-person-3e0eb8aa-b66c-4b30-9a0e-f1326c6f0589": 0
        }
      },
      "15B": {
        "members": [
          {
            "API_ID": "ocd-person/380875c6-dc4c-4062-8c2d-f5b80dd64c4c",
            "firstName": "Paul M.",
            "lastName": "Torkelson",
            "fullName": "Paul Torkelson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/15B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "15B",
              "chamber": "lower",
              "hashID": "SLDL-MN-15B-lower-ocd-person-380875c6-dc4c-4062-8c2d-f5b80dd64c4c",
              "builtID": "mn-lower-15b",
              "externalID": "ocd-person/380875c6-dc4c-4062-8c2d-f5b80dd64c4c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.paul.torkelson@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "19572 Oak Shores Road, Hanska, MN 56041",
              "phone1": null,
              "phone2": "507-375-7315",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15304"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-15B-lower-ocd-person-380875c6-dc4c-4062-8c2d-f5b80dd64c4c": 0
        }
      },
      "23A": {
        "members": [
          {
            "API_ID": "ocd-person/16c8390c-93c6-403e-83d4-5ff1a81fe4aa",
            "firstName": "Peggy",
            "lastName": "Bennett",
            "fullName": "Peggy Bennett",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/23A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "23A",
              "chamber": "lower",
              "hashID": "SLDL-MN-23A-lower-ocd-person-16c8390c-93c6-403e-83d4-5ff1a81fe4aa",
              "builtID": "mn-lower-23a",
              "externalID": "ocd-person/16c8390c-93c6-403e-83d4-5ff1a81fe4aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.peggy.bennett@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15430"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-23A-lower-ocd-person-16c8390c-93c6-403e-83d4-5ff1a81fe4aa": 0
        }
      },
      "31B": {
        "members": [
          {
            "API_ID": "ocd-person/75f1b339-bd9f-4f7c-820b-75812553bde0",
            "firstName": "Peggy S.",
            "lastName": "Scott",
            "fullName": "Peggy Scott",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/31B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "31B",
              "chamber": "lower",
              "hashID": "SLDL-MN-31B-lower-ocd-person-75f1b339-bd9f-4f7c-820b-75812553bde0",
              "builtID": "mn-lower-31b",
              "externalID": "ocd-person/75f1b339-bd9f-4f7c-820b-75812553bde0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.peggy.scott@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15314"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-31B-lower-ocd-person-75f1b339-bd9f-4f7c-820b-75812553bde0": 0
        }
      },
      "44A": {
        "members": [
          {
            "API_ID": "ocd-person/929dc6f7-63f4-4a9d-ba24-6ab8c5964acf",
            "firstName": "Peter",
            "lastName": "Fischer",
            "fullName": "Peter Fischer",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/44A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "44A",
              "chamber": "lower",
              "hashID": "SLDL-MN-44A-lower-ocd-person-929dc6f7-63f4-4a9d-ba24-6ab8c5964acf",
              "builtID": "mn-lower-44a",
              "externalID": "ocd-person/929dc6f7-63f4-4a9d-ba24-6ab8c5964acf",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.peter.fischer@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15396"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-44A-lower-ocd-person-929dc6f7-63f4-4a9d-ba24-6ab8c5964acf": 0
        }
      },
      "53B": {
        "members": [
          {
            "API_ID": "ocd-person/c41944c1-d89b-4c3f-a76f-d5c58d0a507f",
            "firstName": "Rick",
            "lastName": "Hansen",
            "fullName": "Rick Hansen",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/53B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "53B",
              "chamber": "lower",
              "hashID": "SLDL-MN-53B-lower-ocd-person-c41944c1-d89b-4c3f-a76f-d5c58d0a507f",
              "builtID": "mn-lower-53b",
              "externalID": "ocd-person/c41944c1-d89b-4c3f-a76f-d5c58d0a507f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.rick.hansen@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1007 15th Ave. North, South St. Paul, MN 55075",
              "phone1": null,
              "phone2": "651-451-1189",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/12282"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-53B-lower-ocd-person-c41944c1-d89b-4c3f-a76f-d5c58d0a507f": 0
        }
      },
      "56A": {
        "members": [
          {
            "API_ID": "ocd-person/f2b7f80c-abb4-4204-839f-eb9a4de6dbab",
            "firstName": "Robert",
            "lastName": "Bierman",
            "fullName": "Robert Bierman",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/56A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "56A",
              "chamber": "lower",
              "hashID": "SLDL-MN-56A-lower-ocd-person-f2b7f80c-abb4-4204-839f-eb9a4de6dbab",
              "builtID": "mn-lower-56a",
              "externalID": "ocd-person/f2b7f80c-abb4-4204-839f-eb9a4de6dbab",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.robert.bierman@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15526"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-56A-lower-ocd-person-f2b7f80c-abb4-4204-839f-eb9a4de6dbab": 0
        }
      },
      "3A": {
        "members": [
          {
            "API_ID": "ocd-person/e7b3aed7-aa50-4c4b-bd53-eacc60e53ba8",
            "firstName": "Roger J.",
            "lastName": "Skraba",
            "fullName": "Roger Skraba",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/03A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "3A",
              "chamber": "lower",
              "hashID": "SLDL-MN-3A-lower-ocd-person-e7b3aed7-aa50-4c4b-bd53-eacc60e53ba8",
              "builtID": "mn-lower-3a",
              "externalID": "ocd-person/e7b3aed7-aa50-4c4b-bd53-eacc60e53ba8",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.roger.skraba@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1039 E. Harvey St., Ely, MN 55731",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15623"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-3A-lower-ocd-person-e7b3aed7-aa50-4c4b-bd53-eacc60e53ba8": 0
        }
      },
      "10A": {
        "members": [
          {
            "API_ID": "ocd-person/3059daef-810c-4917-b6fa-c059312f97bf",
            "firstName": "Ron",
            "lastName": "Kresha",
            "fullName": "Ron Kresha",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/10A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "10A",
              "chamber": "lower",
              "hashID": "SLDL-MN-10A-lower-ocd-person-3059daef-810c-4917-b6fa-c059312f97bf",
              "builtID": "mn-lower-10a",
              "externalID": "ocd-person/3059daef-810c-4917-b6fa-c059312f97bf",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ron.kresha@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15404"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-10A-lower-ocd-person-3059daef-810c-4917-b6fa-c059312f97bf": 0
        }
      },
      "65A": {
        "members": [
          {
            "API_ID": "ocd-person/1ff5f689-18c9-4d74-970f-c289f915dc6a",
            "firstName": "Samakab",
            "lastName": "Hussein",
            "fullName": "Samakab Hussein",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/65A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "65A",
              "chamber": "lower",
              "hashID": "SLDL-MN-65A-lower-ocd-person-1ff5f689-18c9-4d74-970f-c289f915dc6a",
              "builtID": "mn-lower-65a",
              "externalID": "ocd-person/1ff5f689-18c9-4d74-970f-c289f915dc6a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.samakab.hussein@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15573"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-65A-lower-ocd-person-1ff5f689-18c9-4d74-970f-c289f915dc6a": 0
        }
      },
      "63A": {
        "members": [
          {
            "API_ID": "ocd-person/74dec7df-a9b9-48aa-9fae-bbe45990f4f5",
            "firstName": "Samantha",
            "lastName": "Sencer-Mura",
            "fullName": "Samantha Sencer-Mura",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/63A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "63A",
              "chamber": "lower",
              "hashID": "SLDL-MN-63A-lower-ocd-person-74dec7df-a9b9-48aa-9fae-bbe45990f4f5",
              "builtID": "mn-lower-63a",
              "externalID": "ocd-person/74dec7df-a9b9-48aa-9fae-bbe45990f4f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.samantha.sencer-mura@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15572"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-63A-lower-ocd-person-74dec7df-a9b9-48aa-9fae-bbe45990f4f5": 0
        }
      },
      "38B": {
        "members": [
          {
            "API_ID": "ocd-person/3f73ba2f-f038-47ff-b721-27118592b691",
            "firstName": "Samantha",
            "lastName": "Vang",
            "fullName": "Samantha Vang",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/38B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "38B",
              "chamber": "lower",
              "hashID": "SLDL-MN-38B-lower-ocd-person-3f73ba2f-f038-47ff-b721-27118592b691",
              "builtID": "mn-lower-38b",
              "externalID": "ocd-person/3f73ba2f-f038-47ff-b721-27118592b691",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.samantha.vang@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 29674 6848 Lee Ave. North, Brooklyn Center, MN 55429",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15510"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-38B-lower-ocd-person-3f73ba2f-f038-47ff-b721-27118592b691": 0
        }
      },
      "39B": {
        "members": [
          {
            "API_ID": "ocd-person/94c52434-9616-43bd-863b-9481943dedb1",
            "firstName": "Sandra",
            "lastName": "Feist",
            "fullName": "Sandra Feist",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/39B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "39B",
              "chamber": "lower",
              "hashID": "SLDL-MN-39B-lower-ocd-person-94c52434-9616-43bd-863b-9481943dedb1",
              "builtID": "mn-lower-39b",
              "externalID": "ocd-person/94c52434-9616-43bd-863b-9481943dedb1",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.sandra.feist@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15547"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-39B-lower-ocd-person-94c52434-9616-43bd-863b-9481943dedb1": 0
        }
      },
      "41B": {
        "members": [
          {
            "API_ID": "ocd-person/572b7b6f-269f-49c0-a4d7-ce53f76c33e5",
            "firstName": "Shane Richard",
            "lastName": "Hudella",
            "fullName": "Shane Hudella",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/41B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "41B",
              "chamber": "lower",
              "hashID": "SLDL-MN-41B-lower-ocd-person-572b7b6f-269f-49c0-a4d7-ce53f76c33e5",
              "builtID": "mn-lower-41b",
              "externalID": "ocd-person/572b7b6f-269f-49c0-a4d7-ce53f76c33e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.shane.hudella@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "15213 Ravenna Trail, Hastings, MN 55033",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15616"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-41B-lower-ocd-person-572b7b6f-269f-49c0-a4d7-ce53f76c33e5": 0
        }
      },
      "27A": {
        "members": [
          {
            "API_ID": "ocd-person/204ac87d-7f04-4b49-adc4-658faec7d413",
            "firstName": "Shane",
            "lastName": "Mekeland",
            "fullName": "Shane Mekeland",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/27A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "27A",
              "chamber": "lower",
              "hashID": "SLDL-MN-27A-lower-ocd-person-204ac87d-7f04-4b49-adc4-658faec7d413",
              "builtID": "mn-lower-27a",
              "externalID": "ocd-person/204ac87d-7f04-4b49-adc4-658faec7d413",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.shane.mekeland@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15500"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-27A-lower-ocd-person-204ac87d-7f04-4b49-adc4-658faec7d413": 0
        }
      },
      "7A": {
        "members": [
          {
            "API_ID": "ocd-person/2e342c55-f56d-4895-9dd2-ef1271f784c0",
            "firstName": "Spencer R.",
            "lastName": "Igo",
            "fullName": "Spencer Igo",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/07A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "7A",
              "chamber": "lower",
              "hashID": "SLDL-MN-7A-lower-ocd-person-2e342c55-f56d-4895-9dd2-ef1271f784c0",
              "builtID": "mn-lower-7a",
              "externalID": "ocd-person/2e342c55-f56d-4895-9dd2-ef1271f784c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.spencer.igo@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Wabana Township, MN",
              "phone1": null,
              "phone2": "218-244-1295",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15540"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-7A-lower-ocd-person-2e342c55-f56d-4895-9dd2-ef1271f784c0": 0
        }
      },
      "50B": {
        "members": [
          {
            "API_ID": "ocd-person/b867618c-7a49-4cb3-94ac-b5836ae8dedf",
            "firstName": "Steve",
            "lastName": "Elkins",
            "fullName": "Steve Elkins",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/50B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "50B",
              "chamber": "lower",
              "hashID": "SLDL-MN-50B-lower-ocd-person-b867618c-7a49-4cb3-94ac-b5836ae8dedf",
              "builtID": "mn-lower-50b",
              "externalID": "ocd-person/b867618c-7a49-4cb3-94ac-b5836ae8dedf",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.steve.elkins@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "8709 Sandro Road, Bloomington, MN 55438",
              "phone1": null,
              "phone2": "952-835-2118",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15517"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-50B-lower-ocd-person-b867618c-7a49-4cb3-94ac-b5836ae8dedf": 0
        }
      },
      "20B": {
        "members": [
          {
            "API_ID": "ocd-person/3c90edec-1bce-4197-8e1c-cc840102c7a9",
            "firstName": "Steven",
            "lastName": "Jacob",
            "fullName": "Steven Jacob",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/20B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "20B",
              "chamber": "lower",
              "hashID": "SLDL-MN-20B-lower-ocd-person-3c90edec-1bce-4197-8e1c-cc840102c7a9",
              "builtID": "mn-lower-20b",
              "externalID": "ocd-person/3c90edec-1bce-4197-8e1c-cc840102c7a9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.steven.jacob@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "13377 Jacob Lane, Altura, MN 55910",
              "phone1": null,
              "phone2": "507-534-2554",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15603"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-20B-lower-ocd-person-3c90edec-1bce-4197-8e1c-cc840102c7a9": 0
        }
      },
      "60A": {
        "members": [
          {
            "API_ID": "ocd-person/e60bb3ff-5164-4d6d-9029-d2e3d2ac69a0",
            "firstName": "Sydney",
            "lastName": "Jordan",
            "fullName": "Sydney Jordan",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/60A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "60A",
              "chamber": "lower",
              "hashID": "SLDL-MN-60A-lower-ocd-person-e60bb3ff-5164-4d6d-9029-d2e3d2ac69a0",
              "builtID": "mn-lower-60a",
              "externalID": "ocd-person/e60bb3ff-5164-4d6d-9029-d2e3d2ac69a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.sydney.jordan@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15535"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-60A-lower-ocd-person-e60bb3ff-5164-4d6d-9029-d2e3d2ac69a0": 0
        }
      },
      "13B": {
        "members": [
          {
            "API_ID": "ocd-person/015d0cf4-9742-4050-8084-a62be4107f2e",
            "firstName": "Timothy J.",
            "lastName": "O'Driscoll",
            "fullName": "Tim O'Driscoll",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/13B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "13B",
              "chamber": "lower",
              "hashID": "SLDL-MN-13B-lower-ocd-person-015d0cf4-9742-4050-8084-a62be4107f2e",
              "builtID": "mn-lower-13b",
              "externalID": "ocd-person/015d0cf4-9742-4050-8084-a62be4107f2e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.tim.odriscoll@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 225, Sartell, MN 56377",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15364"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-13B-lower-ocd-person-015d0cf4-9742-4050-8084-a62be4107f2e": 0
        }
      },
      "24B": {
        "members": [
          {
            "API_ID": "ocd-person/ed6e8851-f7a8-41af-b2b6-570bd2004587",
            "firstName": "Tina",
            "lastName": "Liebling",
            "fullName": "Tina Liebling",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/24B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "24B",
              "chamber": "lower",
              "hashID": "SLDL-MN-24B-lower-ocd-person-ed6e8851-f7a8-41af-b2b6-570bd2004587",
              "builtID": "mn-lower-24b",
              "externalID": "ocd-person/ed6e8851-f7a8-41af-b2b6-570bd2004587",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.tina.liebling@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/12268"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-24B-lower-ocd-person-ed6e8851-f7a8-41af-b2b6-570bd2004587": 0
        }
      },
      "9B": {
        "members": [
          {
            "API_ID": "ocd-person/a798a6be-e28f-447c-b9ce-ffd7bf9a0ed4",
            "firstName": "Tom",
            "lastName": "Murphy",
            "fullName": "Tom Murphy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/09B.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "9B",
              "chamber": "lower",
              "hashID": "SLDL-MN-9B-lower-ocd-person-a798a6be-e28f-447c-b9ce-ffd7bf9a0ed4",
              "builtID": "mn-lower-9b",
              "externalID": "ocd-person/a798a6be-e28f-447c-b9ce-ffd7bf9a0ed4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.tom.murphy@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Underwood, MN",
              "phone1": null,
              "phone2": "218-640-1707",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15596"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-9B-lower-ocd-person-a798a6be-e28f-447c-b9ce-ffd7bf9a0ed4": 0
        }
      },
      "30A": {
        "members": [
          {
            "API_ID": "ocd-person/0b721953-c642-4b33-b203-5eb3f3da210b",
            "firstName": "Walter",
            "lastName": "Hudson",
            "fullName": "Walter Hudson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/30A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "30A",
              "chamber": "lower",
              "hashID": "SLDL-MN-30A-lower-ocd-person-0b721953-c642-4b33-b203-5eb3f3da210b",
              "builtID": "mn-lower-30a",
              "externalID": "ocd-person/0b721953-c642-4b33-b203-5eb3f3da210b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.walter.hudson@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 307, Albertville, MN 55301",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15607"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-30A-lower-ocd-person-0b721953-c642-4b33-b203-5eb3f3da210b": 0
        }
      },
      "35A": {
        "members": [
          {
            "API_ID": "ocd-person/ed5f1cc0-ff12-461e-9016-0ef27f180430",
            "firstName": "Zack",
            "lastName": "Stephenson",
            "fullName": "Zack Stephenson",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.house.mn.gov/hinfo/memberimgls93/35A.gif?v=032323",
            "title": "MN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "35A",
              "chamber": "lower",
              "hashID": "SLDL-MN-35A-lower-ocd-person-ed5f1cc0-ff12-461e-9016-0ef27f180430",
              "builtID": "mn-lower-35a",
              "externalID": "ocd-person/ed5f1cc0-ff12-461e-9016-0ef27f180430",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.zack.stephenson@house.mn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 222, Champlin, MN 55316",
              "phone1": null,
              "phone2": "763-370-9000",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.house.mn.gov/members/profile/15507"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MN-35A-lower-ocd-person-ed5f1cc0-ff12-461e-9016-0ef27f180430": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/de9b28f6-a2a8-4edd-98cb-2b3ff2e6382c",
            "firstName": "Mark T.",
            "lastName": "Johnson",
            "fullName": "Mark Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/01Johnson.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-MN-1-upper-ocd-person-de9b28f6-a2a8-4edd-98cb-2b3ff2e6382c",
              "builtID": "mn-upper-1",
              "externalID": "ocd-person/de9b28f6-a2a8-4edd-98cb-2b3ff2e6382c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.mark.johnson@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1217"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-1-upper-ocd-person-de9b28f6-a2a8-4edd-98cb-2b3ff2e6382c": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/9d8b9b67-e74b-4e7f-92d5-52e3c0907f1d",
            "firstName": "Steve",
            "lastName": "Green",
            "fullName": "Steve Green",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/02Green.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-MN-2-upper-ocd-person-9d8b9b67-e74b-4e7f-92d5-52e3c0907f1d",
              "builtID": "mn-upper-2",
              "externalID": "ocd-person/9d8b9b67-e74b-4e7f-92d5-52e3c0907f1d",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.steve.green@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1251"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-2-upper-ocd-person-9d8b9b67-e74b-4e7f-92d5-52e3c0907f1d": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/6a13cdd4-3646-4fe9-a2a3-5f1399c2c146",
            "firstName": "Grant",
            "lastName": "Hauschild",
            "fullName": "Grant Hauschild",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/03Hauschild.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-MN-3-upper-ocd-person-6a13cdd4-3646-4fe9-a2a3-5f1399c2c146",
              "builtID": "mn-upper-3",
              "externalID": "ocd-person/6a13cdd4-3646-4fe9-a2a3-5f1399c2c146",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.grant.hauschild@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1252"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-3-upper-ocd-person-6a13cdd4-3646-4fe9-a2a3-5f1399c2c146": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/b8c303a5-0a73-4a26-8071-be5a9dcf113a",
            "firstName": "Robert J.",
            "lastName": "Kupec",
            "fullName": "Rob Kupec",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/04Kupec.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-MN-4-upper-ocd-person-b8c303a5-0a73-4a26-8071-be5a9dcf113a",
              "builtID": "mn-upper-4",
              "externalID": "ocd-person/b8c303a5-0a73-4a26-8071-be5a9dcf113a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.robert.kupec@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1253"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-4-upper-ocd-person-b8c303a5-0a73-4a26-8071-be5a9dcf113a": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/fde08265-b73e-4a4b-ba87-d90d71319640",
            "firstName": "Paul J.",
            "lastName": "Utke",
            "fullName": "Paul Utke",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/02Utke.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-MN-5-upper-ocd-person-fde08265-b73e-4a4b-ba87-d90d71319640",
              "builtID": "mn-upper-5",
              "externalID": "ocd-person/fde08265-b73e-4a4b-ba87-d90d71319640",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.paul.utke@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1218"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-5-upper-ocd-person-fde08265-b73e-4a4b-ba87-d90d71319640": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/022f293c-ec0c-45c9-a304-c4efad5908b0",
            "firstName": "Justin D.",
            "lastName": "Eichorn",
            "fullName": "Justin Eichorn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/06Eichorn.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-MN-6-upper-ocd-person-022f293c-ec0c-45c9-a304-c4efad5908b0",
              "builtID": "mn-upper-6",
              "externalID": "ocd-person/022f293c-ec0c-45c9-a304-c4efad5908b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.justin.eichorn@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1219"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-6-upper-ocd-person-022f293c-ec0c-45c9-a304-c4efad5908b0": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/9cdb9977-9923-4124-ae93-c45e28f1f51c",
            "firstName": "Robert D.",
            "lastName": "Farnsworth",
            "fullName": "Rob Farnsworth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/07Farnsworth.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-MN-7-upper-ocd-person-9cdb9977-9923-4124-ae93-c45e28f1f51c",
              "builtID": "mn-upper-7",
              "externalID": "ocd-person/9cdb9977-9923-4124-ae93-c45e28f1f51c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.robert.farnsworth@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1254"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-7-upper-ocd-person-9cdb9977-9923-4124-ae93-c45e28f1f51c": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/8d0a69ec-1301-4df5-9676-86720555ea7c",
            "firstName": "Jennifer A.",
            "lastName": "McEwen",
            "fullName": "Jen McEwen",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/07McEwenJen.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-MN-8-upper-ocd-person-8d0a69ec-1301-4df5-9676-86720555ea7c",
              "builtID": "mn-upper-8",
              "externalID": "ocd-person/8d0a69ec-1301-4df5-9676-86720555ea7c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.jennifer.mcewen@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1241"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-8-upper-ocd-person-8d0a69ec-1301-4df5-9676-86720555ea7c": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/4c3116e2-98db-4367-b543-a12220c9b12f",
            "firstName": "Jordan C.",
            "lastName": "Rasmusson",
            "fullName": "Jordan Rasmusson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/09Rasmusson.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-MN-9-upper-ocd-person-4c3116e2-98db-4367-b543-a12220c9b12f",
              "builtID": "mn-upper-9",
              "externalID": "ocd-person/4c3116e2-98db-4367-b543-a12220c9b12f",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.jordan.rasmusson@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1255"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-9-upper-ocd-person-4c3116e2-98db-4367-b543-a12220c9b12f": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/7eaf40ec-f926-4992-8962-65b90846ef80",
            "firstName": "Nathan",
            "lastName": "Wesenberg",
            "fullName": "Nathan Wesenberg",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/10Wesenberg.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-MN-10-upper-ocd-person-7eaf40ec-f926-4992-8962-65b90846ef80",
              "builtID": "mn-upper-10",
              "externalID": "ocd-person/7eaf40ec-f926-4992-8962-65b90846ef80",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.nathan.wesenberg@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1256"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-10-upper-ocd-person-7eaf40ec-f926-4992-8962-65b90846ef80": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/f15c0d42-4380-4f9b-b66d-14ffe61e1a40",
            "firstName": "Jason",
            "lastName": "Rarick",
            "fullName": "Jason Rarick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/11Rarick.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-MN-11-upper-ocd-person-f15c0d42-4380-4f9b-b66d-14ffe61e1a40",
              "builtID": "mn-upper-11",
              "externalID": "ocd-person/f15c0d42-4380-4f9b-b66d-14ffe61e1a40",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.jason.rarick@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.mnsenaterepublicans.com/jason-rarick/",
                "https://www.senate.mn/members/member_bio.html?mem_id=1240"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-11-upper-ocd-person-f15c0d42-4380-4f9b-b66d-14ffe61e1a40": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/1c19414e-994b-46d4-8971-432ed482ca39",
            "firstName": "Torrey N.",
            "lastName": "Westrom",
            "fullName": "Torrey Westrom",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/12Westrom.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-MN-12-upper-ocd-person-1c19414e-994b-46d4-8971-432ed482ca39",
              "builtID": "mn-upper-12",
              "externalID": "ocd-person/1c19414e-994b-46d4-8971-432ed482ca39",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.torrey.westrom@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1197"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-12-upper-ocd-person-1c19414e-994b-46d4-8971-432ed482ca39": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/971dbd16-363f-4151-a75c-306746aec18e",
            "firstName": "Jeff R.",
            "lastName": "Howe",
            "fullName": "Jeff Howe",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/13Howe.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-MN-13-upper-ocd-person-971dbd16-363f-4151-a75c-306746aec18e",
              "builtID": "mn-upper-13",
              "externalID": "ocd-person/971dbd16-363f-4151-a75c-306746aec18e",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.jeff.howe@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1239"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-13-upper-ocd-person-971dbd16-363f-4151-a75c-306746aec18e": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/20c89480-8cde-4106-8b3d-54183371960a",
            "firstName": "Aric",
            "lastName": "Putnam",
            "fullName": "Aric Putnam",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/14PutnamAric.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-MN-14-upper-ocd-person-20c89480-8cde-4106-8b3d-54183371960a",
              "builtID": "mn-upper-14",
              "externalID": "ocd-person/20c89480-8cde-4106-8b3d-54183371960a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.aric.putnam@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1249"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-14-upper-ocd-person-20c89480-8cde-4106-8b3d-54183371960a": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/d1b377a1-8caa-4e37-999b-83ef8a1bf92c",
            "firstName": "Gary H.",
            "lastName": "Dahms",
            "fullName": "Gary Dahms",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/16Dahms.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-MN-15-upper-ocd-person-d1b377a1-8caa-4e37-999b-83ef8a1bf92c",
              "builtID": "mn-upper-15",
              "externalID": "ocd-person/d1b377a1-8caa-4e37-999b-83ef8a1bf92c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.gary.dahms@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1174"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-15-upper-ocd-person-d1b377a1-8caa-4e37-999b-83ef8a1bf92c": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/3e1cd4f3-2c37-4531-b2cb-ca875c82d773",
            "firstName": "Andrew R.",
            "lastName": "Lang",
            "fullName": "Andrew Lang",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/17Lang.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-MN-16-upper-ocd-person-3e1cd4f3-2c37-4531-b2cb-ca875c82d773",
              "builtID": "mn-upper-16",
              "externalID": "ocd-person/3e1cd4f3-2c37-4531-b2cb-ca875c82d773",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.andrew.lang@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1223"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-16-upper-ocd-person-3e1cd4f3-2c37-4531-b2cb-ca875c82d773": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/7a52595e-fa21-4e21-88d6-b03e4d9f1f77",
            "firstName": "Glenn",
            "lastName": "Gruenhagen",
            "fullName": "Glenn Gruenhagen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/17Gruenhagen.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-MN-17-upper-ocd-person-7a52595e-fa21-4e21-88d6-b03e4d9f1f77",
              "builtID": "mn-upper-17",
              "externalID": "ocd-person/7a52595e-fa21-4e21-88d6-b03e4d9f1f77",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.glenn.gruenhagen@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1257"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-17-upper-ocd-person-7a52595e-fa21-4e21-88d6-b03e4d9f1f77": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/55a02119-b43d-4b39-a19b-07411c5ec7c9",
            "firstName": "Nick A.",
            "lastName": "Frentz",
            "fullName": "Nick Frentz",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/19Frentz.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-MN-18-upper-ocd-person-55a02119-b43d-4b39-a19b-07411c5ec7c9",
              "builtID": "mn-upper-18",
              "externalID": "ocd-person/55a02119-b43d-4b39-a19b-07411c5ec7c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.nick.frentz@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1224"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-18-upper-ocd-person-55a02119-b43d-4b39-a19b-07411c5ec7c9": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/f6b649e0-5359-4d22-8ff0-6ca017bc5e23",
            "firstName": "John R.",
            "lastName": "Jasinski",
            "fullName": "John Jasinski",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/24Jasinski.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-MN-19-upper-ocd-person-f6b649e0-5359-4d22-8ff0-6ca017bc5e23",
              "builtID": "mn-upper-19",
              "externalID": "ocd-person/f6b649e0-5359-4d22-8ff0-6ca017bc5e23",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.john.jasinski@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1227"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-19-upper-ocd-person-f6b649e0-5359-4d22-8ff0-6ca017bc5e23": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/a947d529-ab68-4ae8-b1e8-7ff012dde193",
            "firstName": "Steve",
            "lastName": "Drazkowski",
            "fullName": "Steve Drazkowski",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/20Drazkowski.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-MN-20-upper-ocd-person-a947d529-ab68-4ae8-b1e8-7ff012dde193",
              "builtID": "mn-upper-20",
              "externalID": "ocd-person/a947d529-ab68-4ae8-b1e8-7ff012dde193",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.steve.drazkowski@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1258"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-20-upper-ocd-person-a947d529-ab68-4ae8-b1e8-7ff012dde193": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/f839da78-1e9c-4e4b-b1d7-d203970ec6b2",
            "firstName": "William",
            "lastName": "Weber",
            "fullName": "Bill Weber",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/22Weber.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-MN-21-upper-ocd-person-f839da78-1e9c-4e4b-b1d7-d203970ec6b2",
              "builtID": "mn-upper-21",
              "externalID": "ocd-person/f839da78-1e9c-4e4b-b1d7-d203970ec6b2",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.bill.weber@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1199"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-21-upper-ocd-person-f839da78-1e9c-4e4b-b1d7-d203970ec6b2": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/f9fb7c65-e0ab-484c-8517-b20267cc3f07",
            "firstName": "Rich",
            "lastName": "Draheim",
            "fullName": "Rich Draheim",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/20Draheim.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-MN-22-upper-ocd-person-f9fb7c65-e0ab-484c-8517-b20267cc3f07",
              "builtID": "mn-upper-22",
              "externalID": "ocd-person/f9fb7c65-e0ab-484c-8517-b20267cc3f07",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.rich.draheim@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1225"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-22-upper-ocd-person-f9fb7c65-e0ab-484c-8517-b20267cc3f07": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/d3857eb4-0ec5-4406-840a-e338aad6a238",
            "firstName": "Gene Allen",
            "lastName": "Dornink",
            "fullName": "Gene Dornink",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/27DorninkGene.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-MN-23-upper-ocd-person-d3857eb4-0ec5-4406-840a-e338aad6a238",
              "builtID": "mn-upper-23",
              "externalID": "ocd-person/d3857eb4-0ec5-4406-840a-e338aad6a238",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.gene.dornink@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1250"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-23-upper-ocd-person-d3857eb4-0ec5-4406-840a-e338aad6a238": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/69ac712d-4de2-448a-8b84-ef07b4c4e423",
            "firstName": "Carla Jean",
            "lastName": "Nelson",
            "fullName": "Carla Nelson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/26Nelson.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-MN-24-upper-ocd-person-69ac712d-4de2-448a-8b84-ef07b4c4e423",
              "builtID": "mn-upper-24",
              "externalID": "ocd-person/69ac712d-4de2-448a-8b84-ef07b4c4e423",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.carla.nelson@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1178"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-24-upper-ocd-person-69ac712d-4de2-448a-8b84-ef07b4c4e423": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/26ed75ae-3230-4e92-82a3-486bd76e1893",
            "firstName": "Liz",
            "lastName": "Boldon",
            "fullName": "Liz Boldon",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/25Boldon.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-MN-25-upper-ocd-person-26ed75ae-3230-4e92-82a3-486bd76e1893",
              "builtID": "mn-upper-25",
              "externalID": "ocd-person/26ed75ae-3230-4e92-82a3-486bd76e1893",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.liz.boldon@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1259"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-25-upper-ocd-person-26ed75ae-3230-4e92-82a3-486bd76e1893": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/f0824aef-4692-46c8-bafa-f19a01a9d716",
            "firstName": "Jeremy R.",
            "lastName": "Miller",
            "fullName": "Jeremy Miller",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/26Miller.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-MN-26-upper-ocd-person-f0824aef-4692-46c8-bafa-f19a01a9d716",
              "builtID": "mn-upper-26",
              "externalID": "ocd-person/f0824aef-4692-46c8-bafa-f19a01a9d716",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.jeremy.miller@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1179"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-26-upper-ocd-person-f0824aef-4692-46c8-bafa-f19a01a9d716": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/aabdca28-85ec-41e4-be90-726acaaa0cff",
            "firstName": "Andrew M.",
            "lastName": "Mathews",
            "fullName": "Andrew Mathews",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/15Mathews.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-MN-27-upper-ocd-person-aabdca28-85ec-41e4-be90-726acaaa0cff",
              "builtID": "mn-upper-27",
              "externalID": "ocd-person/aabdca28-85ec-41e4-be90-726acaaa0cff",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.andrew.mathews@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1222"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-27-upper-ocd-person-aabdca28-85ec-41e4-be90-726acaaa0cff": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/f20a4255-6c65-4091-a0f5-aea70f133cdb",
            "firstName": "Mark W.",
            "lastName": "Koran",
            "fullName": "Mark Koran",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/32Koran.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-MN-28-upper-ocd-person-f20a4255-6c65-4091-a0f5-aea70f133cdb",
              "builtID": "mn-upper-28",
              "externalID": "ocd-person/f20a4255-6c65-4091-a0f5-aea70f133cdb",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.mark.koran@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1228"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-28-upper-ocd-person-f20a4255-6c65-4091-a0f5-aea70f133cdb": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/63ed97bf-edfa-44d2-9259-9ac1799dfea6",
            "firstName": "Bruce Douglas",
            "lastName": "Anderson",
            "fullName": "Bruce Anderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/29Anderson.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-MN-29-upper-ocd-person-63ed97bf-edfa-44d2-9259-9ac1799dfea6",
              "builtID": "mn-upper-29",
              "externalID": "ocd-person/63ed97bf-edfa-44d2-9259-9ac1799dfea6",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.bruce.anderson@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1201"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-29-upper-ocd-person-63ed97bf-edfa-44d2-9259-9ac1799dfea6": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/a9040c8f-56d4-4ad9-83f5-e6f1216e7a5e",
            "firstName": "Eric",
            "lastName": "Lucero",
            "fullName": "Eric Lucero",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/30Lucero.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-MN-30-upper-ocd-person-a9040c8f-56d4-4ad9-83f5-e6f1216e7a5e",
              "builtID": "mn-upper-30",
              "externalID": "ocd-person/a9040c8f-56d4-4ad9-83f5-e6f1216e7a5e",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.eric.lucero@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1260"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-30-upper-ocd-person-a9040c8f-56d4-4ad9-83f5-e6f1216e7a5e": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/57b9021d-f5b7-4f80-a82d-a93ca9a123d0",
            "firstName": "Calvin",
            "lastName": "Bahr",
            "fullName": "Cal Bahr",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/31Bahr.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-MN-31-upper-ocd-person-57b9021d-f5b7-4f80-a82d-a93ca9a123d0",
              "builtID": "mn-upper-31",
              "externalID": "ocd-person/57b9021d-f5b7-4f80-a82d-a93ca9a123d0",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.cal.bahr@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1261"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-31-upper-ocd-person-57b9021d-f5b7-4f80-a82d-a93ca9a123d0": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/3a76623e-d295-4c58-be60-fd37a285a39b",
            "firstName": "Michael Eugene",
            "lastName": "Kreun",
            "fullName": "Michael Kreun",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/32Kreun.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-MN-32-upper-ocd-person-3a76623e-d295-4c58-be60-fd37a285a39b",
              "builtID": "mn-upper-32",
              "externalID": "ocd-person/3a76623e-d295-4c58-be60-fd37a285a39b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.michael.kreun@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1262"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-32-upper-ocd-person-3a76623e-d295-4c58-be60-fd37a285a39b": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/dde8cf7d-52c2-48e5-bf65-f0fe2477713c",
            "firstName": "Karin",
            "lastName": "Housley",
            "fullName": "Karin Housley",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/39Housley.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-MN-33-upper-ocd-person-dde8cf7d-52c2-48e5-bf65-f0fe2477713c",
              "builtID": "mn-upper-33",
              "externalID": "ocd-person/dde8cf7d-52c2-48e5-bf65-f0fe2477713c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.karin.housley@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1214"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-33-upper-ocd-person-dde8cf7d-52c2-48e5-bf65-f0fe2477713c": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/b31cb55a-94ea-4f44-a4b9-8003b32c749a",
            "firstName": "John A.",
            "lastName": "Hoffman",
            "fullName": "John Hoffman",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/36Hoffman.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-MN-34-upper-ocd-person-b31cb55a-94ea-4f44-a4b9-8003b32c749a",
              "builtID": "mn-upper-34",
              "externalID": "ocd-person/b31cb55a-94ea-4f44-a4b9-8003b32c749a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.john.hoffman@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1205"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-34-upper-ocd-person-b31cb55a-94ea-4f44-a4b9-8003b32c749a": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/fc189dd9-b597-4744-86d3-ac315b93a3f3",
            "firstName": "James Joseph",
            "lastName": "Abeler",
            "fullName": "Jim Abeler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/35Abeler.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-MN-35-upper-ocd-person-fc189dd9-b597-4744-86d3-ac315b93a3f3",
              "builtID": "mn-upper-35",
              "externalID": "ocd-person/fc189dd9-b597-4744-86d3-ac315b93a3f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.jim.abeler@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1216"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-35-upper-ocd-person-fc189dd9-b597-4744-86d3-ac315b93a3f3": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/9411a121-c47d-431a-b407-88ff5c891c5f",
            "firstName": "Heather",
            "lastName": "Gustafson",
            "fullName": "Heather Gustafson",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/36Gustafson.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-MN-36-upper-ocd-person-9411a121-c47d-431a-b407-88ff5c891c5f",
              "builtID": "mn-upper-36",
              "externalID": "ocd-person/9411a121-c47d-431a-b407-88ff5c891c5f",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.heather.gustafson@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1263"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-36-upper-ocd-person-9411a121-c47d-431a-b407-88ff5c891c5f": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/dc676c57-0779-4d0e-9ba4-a07d7034dfa1",
            "firstName": "Warren E.",
            "lastName": "Limmer",
            "fullName": "Warren Limmer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/37Limmer.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-MN-37-upper-ocd-person-dc676c57-0779-4d0e-9ba4-a07d7034dfa1",
              "builtID": "mn-upper-37",
              "externalID": "ocd-person/dc676c57-0779-4d0e-9ba4-a07d7034dfa1",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.warren.limmer@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1032"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-37-upper-ocd-person-dc676c57-0779-4d0e-9ba4-a07d7034dfa1": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/bd086574-4116-4b0a-ad6b-7c0352293f06",
            "firstName": "Susan Kaying",
            "lastName": "Pha",
            "fullName": "Susan Pha",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/38Pha.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-MN-38-upper-ocd-person-bd086574-4116-4b0a-ad6b-7c0352293f06",
              "builtID": "mn-upper-38",
              "externalID": "ocd-person/bd086574-4116-4b0a-ad6b-7c0352293f06",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.susan.pha@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1264"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-38-upper-ocd-person-bd086574-4116-4b0a-ad6b-7c0352293f06": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/2795e0ed-3a02-4afc-8836-f5c3c067139c",
            "firstName": "Mary Kelly",
            "lastName": "Kunesh-Podein",
            "fullName": "Mary Kunesh-Podein",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/41KuneshPodeinMary.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-MN-39-upper-ocd-person-2795e0ed-3a02-4afc-8836-f5c3c067139c",
              "builtID": "mn-upper-39",
              "externalID": "ocd-person/2795e0ed-3a02-4afc-8836-f5c3c067139c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.mary.kunesh@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1242"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-39-upper-ocd-person-2795e0ed-3a02-4afc-8836-f5c3c067139c": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/4acf27d9-edd4-4f04-97c7-164390a5664b",
            "firstName": "John J.",
            "lastName": "Marty",
            "fullName": "John Marty",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/40Marty.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-MN-40-upper-ocd-person-4acf27d9-edd4-4f04-97c7-164390a5664b",
              "builtID": "mn-upper-40",
              "externalID": "ocd-person/4acf27d9-edd4-4f04-97c7-164390a5664b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.john.mcguire@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1035"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-40-upper-ocd-person-4acf27d9-edd4-4f04-97c7-164390a5664b": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/4ca681ca-58ab-4d75-b7ef-8d255f858d62",
            "firstName": "Judy",
            "lastName": "Seeberger",
            "fullName": "Judy Seeberger",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/41Seeberger.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-MN-41-upper-ocd-person-4ca681ca-58ab-4d75-b7ef-8d255f858d62",
              "builtID": "mn-upper-41",
              "externalID": "ocd-person/4ca681ca-58ab-4d75-b7ef-8d255f858d62",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.judy.seeberger@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1265"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-41-upper-ocd-person-4ca681ca-58ab-4d75-b7ef-8d255f858d62": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/7536b66f-54fe-436e-ba03-83291c3c8d12",
            "firstName": "Bonnie Sue",
            "lastName": "Westlin",
            "fullName": "Bonnie Westlin",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/42Westlin.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-MN-42-upper-ocd-person-7536b66f-54fe-436e-ba03-83291c3c8d12",
              "builtID": "mn-upper-42",
              "externalID": "ocd-person/7536b66f-54fe-436e-ba03-83291c3c8d12",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.bwestlin@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1266"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-42-upper-ocd-person-7536b66f-54fe-436e-ba03-83291c3c8d12": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/ec588921-873b-4335-8fa1-2e3485008d3e",
            "firstName": "Ann H.",
            "lastName": "Rest",
            "fullName": "Ann Rest",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/43Rest.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "43",
              "chamber": "upper",
              "hashID": "SLDU-MN-43-upper-ocd-person-ec588921-873b-4335-8fa1-2e3485008d3e",
              "builtID": "mn-upper-43",
              "externalID": "ocd-person/ec588921-873b-4335-8fa1-2e3485008d3e",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.ann.rest@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1051"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-43-upper-ocd-person-ec588921-873b-4335-8fa1-2e3485008d3e": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/5d9e1da5-ecf9-45d9-b104-7d1238032aff",
            "firstName": "Tou",
            "lastName": "Xiong",
            "fullName": "Tou Xiong",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/44Xiong.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "44",
              "chamber": "upper",
              "hashID": "SLDU-MN-44-upper-ocd-person-5d9e1da5-ecf9-45d9-b104-7d1238032aff",
              "builtID": "mn-upper-44",
              "externalID": "ocd-person/5d9e1da5-ecf9-45d9-b104-7d1238032aff",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.tou.xiong@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1267"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-44-upper-ocd-person-5d9e1da5-ecf9-45d9-b104-7d1238032aff": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/834a2c10-beb0-454e-8e08-619309d8a08a",
            "firstName": "Ronald Steven",
            "lastName": "Latz",
            "fullName": "Ron Latz",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/46Latz.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "46",
              "chamber": "upper",
              "hashID": "SLDU-MN-46-upper-ocd-person-834a2c10-beb0-454e-8e08-619309d8a08a",
              "builtID": "mn-upper-46",
              "externalID": "ocd-person/834a2c10-beb0-454e-8e08-619309d8a08a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.ron.latz@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1102"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-46-upper-ocd-person-834a2c10-beb0-454e-8e08-619309d8a08a": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/9de6da8d-780a-4e78-8a4c-7ab99fcf3623",
            "firstName": "Nicole Lynn",
            "lastName": "Mitchell",
            "fullName": "Nicole Mitchell",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/47Mitchell.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "47",
              "chamber": "upper",
              "hashID": "SLDU-MN-47-upper-ocd-person-9de6da8d-780a-4e78-8a4c-7ab99fcf3623",
              "builtID": "mn-upper-47",
              "externalID": "ocd-person/9de6da8d-780a-4e78-8a4c-7ab99fcf3623",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.nicole.mitchell@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1269"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-47-upper-ocd-person-9de6da8d-780a-4e78-8a4c-7ab99fcf3623": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/62a79a93-6117-4f36-87ef-f4058c34142a",
            "firstName": "Julia E.",
            "lastName": "Coleman",
            "fullName": "Julia Coleman",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/47ColemanJulia.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "48",
              "chamber": "upper",
              "hashID": "SLDU-MN-48-upper-ocd-person-62a79a93-6117-4f36-87ef-f4058c34142a",
              "builtID": "mn-upper-48",
              "externalID": "ocd-person/62a79a93-6117-4f36-87ef-f4058c34142a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.julia.coleman@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1244"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-48-upper-ocd-person-62a79a93-6117-4f36-87ef-f4058c34142a": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/e67843ce-752d-4cc0-abb9-4a45b8b8fdca",
            "firstName": "Steve A.",
            "lastName": "Cwodzinski",
            "fullName": "Steve Cwodzinski",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/48Cwodzinski.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "49",
              "chamber": "upper",
              "hashID": "SLDU-MN-49-upper-ocd-person-e67843ce-752d-4cc0-abb9-4a45b8b8fdca",
              "builtID": "mn-upper-49",
              "externalID": "ocd-person/e67843ce-752d-4cc0-abb9-4a45b8b8fdca",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.steve.cwodzinski@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1234"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-49-upper-ocd-person-e67843ce-752d-4cc0-abb9-4a45b8b8fdca": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/c5f2a744-0a3e-481f-9224-e4d5a58b9c9d",
            "firstName": "Alice",
            "lastName": "Mann",
            "fullName": "Alice Mann",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/50Mann.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "50",
              "chamber": "upper",
              "hashID": "SLDU-MN-50-upper-ocd-person-c5f2a744-0a3e-481f-9224-e4d5a58b9c9d",
              "builtID": "mn-upper-50",
              "externalID": "ocd-person/c5f2a744-0a3e-481f-9224-e4d5a58b9c9d",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.alice.mann@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1270"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-50-upper-ocd-person-c5f2a744-0a3e-481f-9224-e4d5a58b9c9d": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/d2fb6801-379e-481c-9313-2ac099d3a775",
            "firstName": "Melissa Halvorson",
            "lastName": "Wiklund",
            "fullName": "Melissa Wiklund",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/50Wiklund.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "51",
              "chamber": "upper",
              "hashID": "SLDU-MN-51-upper-ocd-person-d2fb6801-379e-481c-9313-2ac099d3a775",
              "builtID": "mn-upper-51",
              "externalID": "ocd-person/d2fb6801-379e-481c-9313-2ac099d3a775",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.melissa.wiklund@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1209"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-51-upper-ocd-person-d2fb6801-379e-481c-9313-2ac099d3a775": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/ac087885-bab6-4fbf-b88a-daf71262c8b6",
            "firstName": "James",
            "lastName": "Carlson",
            "fullName": "Jim Carlson",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/51Carlson.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "52",
              "chamber": "upper",
              "hashID": "SLDU-MN-52-upper-ocd-person-ac087885-bab6-4fbf-b88a-daf71262c8b6",
              "builtID": "mn-upper-52",
              "externalID": "ocd-person/ac087885-bab6-4fbf-b88a-daf71262c8b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.jim.carlson@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1140"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-52-upper-ocd-person-ac087885-bab6-4fbf-b88a-daf71262c8b6": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/a4b21c2d-d3fe-41dc-9614-0ff80878850c",
            "firstName": "Matthew David",
            "lastName": "Klein",
            "fullName": "Matt Klein",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/52Klein.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "53",
              "chamber": "upper",
              "hashID": "SLDU-MN-53-upper-ocd-person-a4b21c2d-d3fe-41dc-9614-0ff80878850c",
              "builtID": "mn-upper-53",
              "externalID": "ocd-person/a4b21c2d-d3fe-41dc-9614-0ff80878850c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.matt.klein@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1235"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-53-upper-ocd-person-a4b21c2d-d3fe-41dc-9614-0ff80878850c": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/f520232e-1086-4353-b904-9ff7a89ba5e8",
            "firstName": "Eric R.",
            "lastName": "Pratt",
            "fullName": "Eric Pratt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/55Pratt.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "54",
              "chamber": "upper",
              "hashID": "SLDU-MN-54-upper-ocd-person-f520232e-1086-4353-b904-9ff7a89ba5e8",
              "builtID": "mn-upper-54",
              "externalID": "ocd-person/f520232e-1086-4353-b904-9ff7a89ba5e8",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.eric.pratt@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1210"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-54-upper-ocd-person-f520232e-1086-4353-b904-9ff7a89ba5e8": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/39814623-d268-47a1-8dcd-78e3ae1a33cf",
            "firstName": "Lindsey",
            "lastName": "Port",
            "fullName": "Lindsey Port",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/56PortLindsey.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "55",
              "chamber": "upper",
              "hashID": "SLDU-MN-55-upper-ocd-person-39814623-d268-47a1-8dcd-78e3ae1a33cf",
              "builtID": "mn-upper-55",
              "externalID": "ocd-person/39814623-d268-47a1-8dcd-78e3ae1a33cf",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.lindsey.port@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1245"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-55-upper-ocd-person-39814623-d268-47a1-8dcd-78e3ae1a33cf": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/5233b98b-0b6d-47c9-9fc0-9218a592f8d8",
            "firstName": "Erin K.",
            "lastName": "Maye Quade",
            "fullName": "Erin Maye Quade",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/56MayeQuade.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "56",
              "chamber": "upper",
              "hashID": "SLDU-MN-56-upper-ocd-person-5233b98b-0b6d-47c9-9fc0-9218a592f8d8",
              "builtID": "mn-upper-56",
              "externalID": "ocd-person/5233b98b-0b6d-47c9-9fc0-9218a592f8d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.erin.mayequade@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1271"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-56-upper-ocd-person-5233b98b-0b6d-47c9-9fc0-9218a592f8d8": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/acacede0-a09a-45b9-8990-646dfa5bd59a",
            "firstName": "Zach",
            "lastName": "Duckworth",
            "fullName": "Zach Duckworth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/58DuckworthZach.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "57",
              "chamber": "upper",
              "hashID": "SLDU-MN-57-upper-ocd-person-acacede0-a09a-45b9-8990-646dfa5bd59a",
              "builtID": "mn-upper-57",
              "externalID": "ocd-person/acacede0-a09a-45b9-8990-646dfa5bd59a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.zach.duckworth@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1246"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-57-upper-ocd-person-acacede0-a09a-45b9-8990-646dfa5bd59a": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/05fb3c26-0704-49c8-8eb6-8c624b752434",
            "firstName": "Bill",
            "lastName": "Lieske",
            "fullName": "Bill Lieske",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mn/img/member_thumbnails/58Lieske.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "58",
              "chamber": "upper",
              "hashID": "SLDU-MN-58-upper-ocd-person-05fb3c26-0704-49c8-8eb6-8c624b752434",
              "builtID": "mn-upper-58",
              "externalID": "ocd-person/05fb3c26-0704-49c8-8eb6-8c624b752434",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.bill.lieske@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1272"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-58-upper-ocd-person-05fb3c26-0704-49c8-8eb6-8c624b752434": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/c7436be4-472a-4d5e-82f5-f46359395d3a",
            "firstName": "Bobby Joe",
            "lastName": "Champion",
            "fullName": "Bobby Joe Champion",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/59Champion.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "59",
              "chamber": "upper",
              "hashID": "SLDU-MN-59-upper-ocd-person-c7436be4-472a-4d5e-82f5-f46359395d3a",
              "builtID": "mn-upper-59",
              "externalID": "ocd-person/c7436be4-472a-4d5e-82f5-f46359395d3a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.bobby.champion@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1212"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-59-upper-ocd-person-c7436be4-472a-4d5e-82f5-f46359395d3a": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/20d236e4-9e82-4b9b-a68f-6aa4ff2cf474",
            "firstName": "Karen",
            "lastName": "Dziedzic",
            "fullName": "Kari Dziedzic",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/60Dziedzic.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "60",
              "chamber": "upper",
              "hashID": "SLDU-MN-60-upper-ocd-person-20d236e4-9e82-4b9b-a68f-6aa4ff2cf474",
              "builtID": "mn-upper-60",
              "externalID": "ocd-person/20d236e4-9e82-4b9b-a68f-6aa4ff2cf474",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.kari.dziedzic@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1193"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-60-upper-ocd-person-20d236e4-9e82-4b9b-a68f-6aa4ff2cf474": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/4dc8ff31-159a-457a-9551-a897d222f87e",
            "firstName": "David Scott",
            "lastName": "Dibble",
            "fullName": "Scott Dibble",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/61Dibble.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "61",
              "chamber": "upper",
              "hashID": "SLDU-MN-61-upper-ocd-person-4dc8ff31-159a-457a-9551-a897d222f87e",
              "builtID": "mn-upper-61",
              "externalID": "ocd-person/4dc8ff31-159a-457a-9551-a897d222f87e",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.scott.dibble@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1010"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-61-upper-ocd-person-4dc8ff31-159a-457a-9551-a897d222f87e": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/04eeaa61-ee3c-4e36-88f2-b415660175eb",
            "firstName": "Omar",
            "lastName": "Fateh",
            "fullName": "Omar Fateh",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/62FatehOmar.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "62",
              "chamber": "upper",
              "hashID": "SLDU-MN-62-upper-ocd-person-04eeaa61-ee3c-4e36-88f2-b415660175eb",
              "builtID": "mn-upper-62",
              "externalID": "ocd-person/04eeaa61-ee3c-4e36-88f2-b415660175eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.omar.fateh@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1247"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-62-upper-ocd-person-04eeaa61-ee3c-4e36-88f2-b415660175eb": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/332e30b5-dc8d-4074-9c16-6e2a6255d135",
            "firstName": "Zaynab",
            "lastName": "Mohamed",
            "fullName": "Zaynab Mohamed",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/63Mohamed.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "63",
              "chamber": "upper",
              "hashID": "SLDU-MN-63-upper-ocd-person-332e30b5-dc8d-4074-9c16-6e2a6255d135",
              "builtID": "mn-upper-63",
              "externalID": "ocd-person/332e30b5-dc8d-4074-9c16-6e2a6255d135",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.zaynab.mohamed@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1273"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-63-upper-ocd-person-332e30b5-dc8d-4074-9c16-6e2a6255d135": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/81828030-d1b8-4641-8692-8148fca58b28",
            "firstName": "Erin P.",
            "lastName": "Murphy",
            "fullName": "Erin Murphy",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/64MurphyErin.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "64",
              "chamber": "upper",
              "hashID": "SLDU-MN-64-upper-ocd-person-81828030-d1b8-4641-8692-8148fca58b28",
              "builtID": "mn-upper-64",
              "externalID": "ocd-person/81828030-d1b8-4641-8692-8148fca58b28",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.erin.murphy@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1248"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-64-upper-ocd-person-81828030-d1b8-4641-8692-8148fca58b28": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/0b83a916-567b-4d36-843f-17d8f5e13b8d",
            "firstName": "Sandra L.",
            "lastName": "Pappas",
            "fullName": "Sandy Pappas",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/65Pappas.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "65",
              "chamber": "upper",
              "hashID": "SLDU-MN-65-upper-ocd-person-0b83a916-567b-4d36-843f-17d8f5e13b8d",
              "builtID": "mn-upper-65",
              "externalID": "ocd-person/0b83a916-567b-4d36-843f-17d8f5e13b8d",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.sandra.parry@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1046"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-65-upper-ocd-person-0b83a916-567b-4d36-843f-17d8f5e13b8d": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/9afffc3e-2fd8-404c-beeb-5bf3e17397ce",
            "firstName": "Clare",
            "lastName": "Oumou Verbeten",
            "fullName": "Clare Oumou Verbeten",
            "gender": "Female",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/66OumouVerbeten.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "66",
              "chamber": "upper",
              "hashID": "SLDU-MN-66-upper-ocd-person-9afffc3e-2fd8-404c-beeb-5bf3e17397ce",
              "builtID": "mn-upper-66",
              "externalID": "ocd-person/9afffc3e-2fd8-404c-beeb-5bf3e17397ce",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.clare.oumou.verbeten@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1274"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-66-upper-ocd-person-9afffc3e-2fd8-404c-beeb-5bf3e17397ce": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/5644f3f8-6394-4ab6-9619-7ab6702b37ea",
            "firstName": "Foung",
            "lastName": "Hawj",
            "fullName": "Foung Hawj",
            "gender": "Male",
            "party": "democratic-farmer-labor",
            "image": "https://www.senate.mn/img/member_thumbnails/67Hawj.jpg",
            "title": "MN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MN",
              "stateFull": "Minnesota",
              "district": "67",
              "chamber": "upper",
              "hashID": "SLDU-MN-67-upper-ocd-person-5644f3f8-6394-4ab6-9619-7ab6702b37ea",
              "builtID": "mn-upper-67",
              "externalID": "ocd-person/5644f3f8-6394-4ab6-9619-7ab6702b37ea",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.foung.hawj@senate.mn",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mn/members/member_bio.html?mem_id=1213"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MN-67-upper-ocd-person-5644f3f8-6394-4ab6-9619-7ab6702b37ea": 0
        }
      }
    }
  },
  "MS": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "MS",
          "stateFull": "Mississippi",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "MS",
            "stateFull": "Mississippi",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-MS---",
            "builtID": "ms--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-MS---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "H001079",
          "in_office": true,
          "firstName": "Cindy",
          "lastName": "Hyde-Smith",
          "middleName": null,
          "fullName": "Cindy Hyde-Smith",
          "gender": "F",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/H001079.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "MS",
            "stateFull": "Mississippi",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-MS---H001079",
            "builtID": "ms--",
            "externalID": "H001079",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.hydesmith.senate.gov/content/contact-senator",
            "address1": "702 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-5054",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenHydeSmith",
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.hydesmith.senate.gov"],
            "rss_url": null
          },
          "title": "MS Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/H001079.json",
          "govtrack_id": "412743",
          "cspan_id": "113208",
          "votesmart_id": "20784",
          "icpsr_id": "41707",
          "crp_id": "N00043298",
          "google_entity_id": "/m/03wc2px",
          "state_rank": "junior",
          "lis_id": "S395",
          "suffix": null,
          "date_of_birth": "1959-05-10",
          "leadership_role": null,
          "fec_candidate_id": "S8MS00261",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "6",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 21,
          "total_present": 0,
          "last_updated": "2024-06-20 15:30:30 -0400",
          "missed_votes_pct": 3.79,
          "votes_with_party_pct": 95.25,
          "votes_against_party_pct": 4.75,
          "ocd_id": "ocd-division/country:us/state:ms"
        },
        {
          "API_ID": "W000437",
          "in_office": true,
          "firstName": "Roger",
          "lastName": "Wicker",
          "middleName": null,
          "fullName": "Roger F. Wicker",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/W000437.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "MS",
            "stateFull": "Mississippi",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-MS---W000437",
            "builtID": "ms--",
            "externalID": "W000437",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.wicker.senate.gov/public/index.cfm/contact",
            "address1": "555 Dirksen Senate Office Building",
            "address2": null,
            "phone1": "202-224-6253",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorWicker",
            "youtube": "SenatorWicker",
            "instagram": null,
            "facebook": "SenatorWicker",
            "urls": ["https://www.wicker.senate.gov"],
            "rss_url": "https://www.wicker.senate.gov/public/?a=rss.feed"
          },
          "title": "MS Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/W000437.json",
          "govtrack_id": "400432",
          "cspan_id": "18203",
          "votesmart_id": "21926",
          "icpsr_id": "29534",
          "crp_id": "N00003280",
          "google_entity_id": "/m/025xxb",
          "state_rank": "senior",
          "lis_id": "S318",
          "suffix": null,
          "date_of_birth": "1951-07-05",
          "leadership_role": null,
          "fec_candidate_id": "H4MS01078",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "17",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 10,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 1.81,
          "votes_with_party_pct": 92.44,
          "votes_against_party_pct": 7.56,
          "ocd_id": "ocd-division/country:us/state:ms"
        }
      ],
      "hash": { "SENATE-MS---H001079": 0, "SENATE-MS---W000437": 1 }
    },
    "HOUSE": {
      "04": {
        "members": [
          {
            "API_ID": "E000235",
            "in_office": true,
            "firstName": "Mike",
            "lastName": "Ezell",
            "middleName": null,
            "fullName": "Mike Ezell",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/E000235.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-MS-04--E000235",
              "builtID": "ms--04",
              "externalID": "E000235",
              "geometry": null,
              "geoid": "2804"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "443 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5772",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepEzell",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ezell.house.gov/"],
              "rss_url": null
            },
            "title": "MS House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/E000235.json",
            "govtrack_id": "456911",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1959-04-06",
            "leadership_role": null,
            "fec_candidate_id": "H2MS04258",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 37,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 3.5,
            "votes_with_party_pct": 93.59,
            "votes_against_party_pct": 4.4,
            "ocd_id": "ocd-division/country:us/state:ms/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MS-04--E000235": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "G000591",
            "in_office": true,
            "firstName": "Michael",
            "lastName": "Guest",
            "middleName": null,
            "fullName": "Michael Guest",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000591.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-MS-03--G000591",
              "builtID": "ms--03",
              "externalID": "G000591",
              "geometry": null,
              "geoid": "2803"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "450 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5031",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMichaelGuest",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://guest.house.gov"],
              "rss_url": null
            },
            "title": "MS House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000591.json",
            "govtrack_id": "412793",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00042458",
            "google_entity_id": "/g/11gtc4dxpz",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1970-02-04",
            "leadership_role": null,
            "fec_candidate_id": "H8MS03125",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 10,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.95,
            "votes_with_party_pct": 93.18,
            "votes_against_party_pct": 4.87,
            "ocd_id": "ocd-division/country:us/state:ms/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MS-03--G000591": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "K000388",
            "in_office": true,
            "firstName": "Trent",
            "lastName": "Kelly",
            "middleName": null,
            "fullName": "Trent Kelly",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/K000388.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-MS-01--K000388",
              "builtID": "ms--01",
              "externalID": "K000388",
              "geometry": null,
              "geoid": "2801"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2243 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4306",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepTrentKelly",
              "youtube": null,
              "instagram": null,
              "facebook": "reptrentkelly",
              "urls": ["https://trentkelly.house.gov"],
              "rss_url": null
            },
            "title": "MS House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000388.json",
            "govtrack_id": "412673",
            "cspan_id": "97322",
            "votesmart_id": "156389",
            "icpsr_id": "21561",
            "crp_id": "N00037003",
            "google_entity_id": "/m/013b9qgh",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1966-03-01",
            "leadership_role": null,
            "fec_candidate_id": "H6MS01131",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 3,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.28,
            "votes_with_party_pct": 93.9,
            "votes_against_party_pct": 4.17,
            "ocd_id": "ocd-division/country:us/state:ms/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MS-01--K000388": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "T000193",
            "in_office": true,
            "firstName": "Bennie",
            "lastName": "Thompson",
            "middleName": null,
            "fullName": "Bennie G. Thompson",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/T000193.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-MS-02--T000193",
              "builtID": "ms--02",
              "externalID": "T000193",
              "geometry": null,
              "geoid": "2802"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2466 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5876",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "BennieGThompson",
              "youtube": "RepBennieThompson",
              "instagram": null,
              "facebook": "CongressmanBennieGThompson",
              "urls": ["https://benniethompson.house.gov"],
              "rss_url": "https://benniethompson.house.gov/rss.xml"
            },
            "title": "MS House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000193.json",
            "govtrack_id": "400402",
            "cspan_id": "7304",
            "votesmart_id": "26929",
            "icpsr_id": "29368",
            "crp_id": "N00003288",
            "google_entity_id": "/m/033tc5",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1948-01-28",
            "leadership_role": null,
            "fec_candidate_id": "H4MS02068",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "32",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 33,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.13,
            "votes_with_party_pct": 96.85,
            "votes_against_party_pct": 1.28,
            "ocd_id": "ocd-division/country:us/state:ms/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MS-02--T000193": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/dcc9906b-9a0a-4503-9128-006317d5ff3f",
            "firstName": "Lester E.",
            "lastName": "Carpenter",
            "fullName": "Bubba Carpenter",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/carpenter.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-MS-1-lower-ocd-person-dcc9906b-9a0a-4503-9128-006317d5ff3f",
              "builtID": "ms-lower-1",
              "externalID": "ocd-person/dcc9906b-9a0a-4503-9128-006317d5ff3f",
              "geometry": null
            },
            "contactInfo": {
              "email": "lcarpenter@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/carpenter.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-1-lower-ocd-person-dcc9906b-9a0a-4503-9128-006317d5ff3f": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/487214e9-b0e2-4741-8bd4-a043a1dd19a5",
            "firstName": "Bradford",
            "lastName": "Mattox",
            "fullName": "Brad Mattox",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.supertalk.fm/wp-content/uploads/2023/08/Brad-Mattox-960x540.png.webp",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-MS-2-lower-ocd-person-487214e9-b0e2-4741-8bd4-a043a1dd19a5",
              "builtID": "ms-lower-2",
              "externalID": "ocd-person/487214e9-b0e2-4741-8bd4-a043a1dd19a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "mattox@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/mattox.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-2-lower-ocd-person-487214e9-b0e2-4741-8bd4-a043a1dd19a5": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/2601640f-867f-480f-83a1-c59c332c0b82",
            "firstName": "William Tracy",
            "lastName": "Arnold",
            "fullName": "Tracy Arnold",
            "gender": "Male",
            "party": "Republican",
            "image": "http://da.mdah.ms.gov/series-files/legislature/composites/2016/500/house-of-representatives/2016_2020HousePhotos/Tracy.Arnold.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-MS-3-lower-ocd-person-2601640f-867f-480f-83a1-c59c332c0b82",
              "builtID": "ms-lower-3",
              "externalID": "ocd-person/2601640f-867f-480f-83a1-c59c332c0b82",
              "geometry": null
            },
            "contactInfo": {
              "email": "warnold@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/arnold.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-3-lower-ocd-person-2601640f-867f-480f-83a1-c59c332c0b82": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/f4bd4ab9-cb95-4b3e-95cb-68312eda5b16",
            "firstName": "Joseph F.",
            "lastName": "Steverson",
            "fullName": "Jody Steverson",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/steverson.jpg?rand=41826",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-MS-4-lower-ocd-person-f4bd4ab9-cb95-4b3e-95cb-68312eda5b16",
              "builtID": "ms-lower-4",
              "externalID": "ocd-person/f4bd4ab9-cb95-4b3e-95cb-68312eda5b16",
              "geometry": null
            },
            "contactInfo": {
              "email": "jsteverson@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/steverson.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-4-lower-ocd-person-f4bd4ab9-cb95-4b3e-95cb-68312eda5b16": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/26c38427-62d3-440b-9253-c3e485fbd8ea",
            "firstName": "John G.",
            "lastName": "Faulkner",
            "fullName": "John Faulkner",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/faulkner.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-MS-5-lower-ocd-person-26c38427-62d3-440b-9253-c3e485fbd8ea",
              "builtID": "ms-lower-5",
              "externalID": "ocd-person/26c38427-62d3-440b-9253-c3e485fbd8ea",
              "geometry": null
            },
            "contactInfo": {
              "email": "jfaulkner@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/faulkner.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-5-lower-ocd-person-26c38427-62d3-440b-9253-c3e485fbd8ea": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/9eae167e-9e7e-4334-a67e-5b686617c71c",
            "firstName": "Justin",
            "lastName": "Keen",
            "fullName": "Justin Keen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://desotocountynews.com/wp-content/uploads/2023/01/Justin-Keen-800x445.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-MS-6-lower-ocd-person-9eae167e-9e7e-4334-a67e-5b686617c71c",
              "builtID": "ms-lower-6",
              "externalID": "ocd-person/9eae167e-9e7e-4334-a67e-5b686617c71c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jkeen@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/keen.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-6-lower-ocd-person-9eae167e-9e7e-4334-a67e-5b686617c71c": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/58bd4b77-f7d6-45bd-bb3e-0386cf36bbf3",
            "firstName": "Kimberly",
            "lastName": "Remak",
            "fullName": "Kimberly Remak",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/KimberlyRemak.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-MS-7-lower-ocd-person-58bd4b77-f7d6-45bd-bb3e-0386cf36bbf3",
              "builtID": "ms-lower-7",
              "externalID": "ocd-person/58bd4b77-f7d6-45bd-bb3e-0386cf36bbf3",
              "geometry": null
            },
            "contactInfo": {
              "email": "remak@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/remak.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-7-lower-ocd-person-58bd4b77-f7d6-45bd-bb3e-0386cf36bbf3": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/c655558e-dfac-468a-a633-85f380c538b6",
            "firstName": "John Thomas",
            "lastName": "Lamar",
            "fullName": "Trey Lamar",
            "gender": "Male",
            "party": "Republican",
            "image": "https://images.squarespace-cdn.com/content/v1/5660bc77e4b02bc8dd3c055f/1463509030796-7AM0VGR04ZZUTAOF9TQW/image-asset.jpeg?format=500w",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-MS-8-lower-ocd-person-c655558e-dfac-468a-a633-85f380c538b6",
              "builtID": "ms-lower-8",
              "externalID": "ocd-person/c655558e-dfac-468a-a633-85f380c538b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "jlamar@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/lamar.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-8-lower-ocd-person-c655558e-dfac-468a-a633-85f380c538b6": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/379b9fd9-2da7-4e20-b0ed-4319ead3a1db",
            "firstName": "Cedric",
            "lastName": "Burnett",
            "fullName": "Cedric Burnett",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/burnett.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-MS-9-lower-ocd-person-379b9fd9-2da7-4e20-b0ed-4319ead3a1db",
              "builtID": "ms-lower-9",
              "externalID": "ocd-person/379b9fd9-2da7-4e20-b0ed-4319ead3a1db",
              "geometry": null
            },
            "contactInfo": {
              "email": "cburnett@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/burnett.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-9-lower-ocd-person-379b9fd9-2da7-4e20-b0ed-4319ead3a1db": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/5ad5b396-01a0-40eb-8d4b-0f76f2727406",
            "firstName": "Josh",
            "lastName": "Hawkins",
            "fullName": "Josh Hawkins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/80182230_83452a1c-4a99-4e85-9c86-d12418dab31b.jpeg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-MS-10-lower-ocd-person-5ad5b396-01a0-40eb-8d4b-0f76f2727406",
              "builtID": "ms-lower-10",
              "externalID": "ocd-person/5ad5b396-01a0-40eb-8d4b-0f76f2727406",
              "geometry": null
            },
            "contactInfo": {
              "email": "jhawkins@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/hawkins.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-10-lower-ocd-person-5ad5b396-01a0-40eb-8d4b-0f76f2727406": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/843ed177-0a3d-4f96-9df2-28ad7333c5a6",
            "firstName": "Lataisha",
            "lastName": "Jackson",
            "fullName": "Lataisha Jackson",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/jackson.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-MS-11-lower-ocd-person-843ed177-0a3d-4f96-9df2-28ad7333c5a6",
              "builtID": "ms-lower-11",
              "externalID": "ocd-person/843ed177-0a3d-4f96-9df2-28ad7333c5a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "ljackson@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/jackson.xml",
                "https://billstatus.ls.state.ms.us/2023/pdf/house_authors/jackson.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-11-lower-ocd-person-843ed177-0a3d-4f96-9df2-28ad7333c5a6": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/0c9a7d35-4b91-42c7-8af2-39b6514c23d8",
            "firstName": "Clay",
            "lastName": "Deweese",
            "fullName": "Clay Deweese",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/deweese.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-MS-12-lower-ocd-person-0c9a7d35-4b91-42c7-8af2-39b6514c23d8",
              "builtID": "ms-lower-12",
              "externalID": "ocd-person/0c9a7d35-4b91-42c7-8af2-39b6514c23d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "cdeweese@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/deweese.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-12-lower-ocd-person-0c9a7d35-4b91-42c7-8af2-39b6514c23d8": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/abfa015f-301e-4e17-9917-535bb9079768",
            "firstName": "Steve E.",
            "lastName": "Massengill",
            "fullName": "Steve Massengill",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/massengill.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-MS-13-lower-ocd-person-abfa015f-301e-4e17-9917-535bb9079768",
              "builtID": "ms-lower-13",
              "externalID": "ocd-person/abfa015f-301e-4e17-9917-535bb9079768",
              "geometry": null
            },
            "contactInfo": {
              "email": "smassengill@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/massengill.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-13-lower-ocd-person-abfa015f-301e-4e17-9917-535bb9079768": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/d79acfe3-5991-41ae-b245-f7b7f877febb",
            "firstName": "Samuel J.",
            "lastName": "Creekmore",
            "fullName": "Sam Creekmore",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/creekmore_iv.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-MS-14-lower-ocd-person-d79acfe3-5991-41ae-b245-f7b7f877febb",
              "builtID": "ms-lower-14",
              "externalID": "ocd-person/d79acfe3-5991-41ae-b245-f7b7f877febb",
              "geometry": null
            },
            "contactInfo": {
              "email": "screekmore@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/creekmore_iv.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-14-lower-ocd-person-d79acfe3-5991-41ae-b245-f7b7f877febb": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/27f187c6-8755-4a18-a32a-dd5b5d7831c8",
            "firstName": "Beth Luther",
            "lastName": "Waldo",
            "fullName": "Beth Waldo",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Beth_Luther_Waldo.jpeg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-MS-15-lower-ocd-person-27f187c6-8755-4a18-a32a-dd5b5d7831c8",
              "builtID": "ms-lower-15",
              "externalID": "ocd-person/27f187c6-8755-4a18-a32a-dd5b5d7831c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "bwaldo@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/waldo.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-15-lower-ocd-person-27f187c6-8755-4a18-a32a-dd5b5d7831c8": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/f8b65fd8-7b11-4954-93fd-47a5cbbd53c8",
            "firstName": "Rickey",
            "lastName": "Thompson",
            "fullName": "Rickey Thompson",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/thompson.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-MS-16-lower-ocd-person-f8b65fd8-7b11-4954-93fd-47a5cbbd53c8",
              "builtID": "ms-lower-16",
              "externalID": "ocd-person/f8b65fd8-7b11-4954-93fd-47a5cbbd53c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "rthompson@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/thompson.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-16-lower-ocd-person-f8b65fd8-7b11-4954-93fd-47a5cbbd53c8": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/4a73b9e6-c66b-4022-b380-6caea6b48265",
            "firstName": "Shane",
            "lastName": "Aguirre",
            "fullName": "Shane Aguirre",
            "gender": "Male",
            "party": "Republican",
            "image": "https://billstatus.ls.state.ms.us/members/house/aguirre.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-MS-17-lower-ocd-person-4a73b9e6-c66b-4022-b380-6caea6b48265",
              "builtID": "ms-lower-17",
              "externalID": "ocd-person/4a73b9e6-c66b-4022-b380-6caea6b48265",
              "geometry": null
            },
            "contactInfo": {
              "email": "saguirre@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/aguirre.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-17-lower-ocd-person-4a73b9e6-c66b-4022-b380-6caea6b48265": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/52d3da6f-c397-4795-9bd8-5a3f17657129",
            "firstName": "Jerry R.",
            "lastName": "Turner",
            "fullName": "Jerry Turner",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/turner.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-MS-18-lower-ocd-person-52d3da6f-c397-4795-9bd8-5a3f17657129",
              "builtID": "ms-lower-18",
              "externalID": "ocd-person/52d3da6f-c397-4795-9bd8-5a3f17657129",
              "geometry": null
            },
            "contactInfo": {
              "email": "jturner@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/turner.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-18-lower-ocd-person-52d3da6f-c397-4795-9bd8-5a3f17657129": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/defa75f7-917e-42da-9d2c-403ad80d1995",
            "firstName": "Randy Phillip",
            "lastName": "Boyd",
            "fullName": "Randy Boyd",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/boyd_(19th).jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-MS-19-lower-ocd-person-defa75f7-917e-42da-9d2c-403ad80d1995",
              "builtID": "ms-lower-19",
              "externalID": "ocd-person/defa75f7-917e-42da-9d2c-403ad80d1995",
              "geometry": null
            },
            "contactInfo": {
              "email": "rboyd@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/boyd.xml",
                "https://billstatus.ls.state.ms.us/members/house/boyd_(19).xml",
                "https://billstatus.ls.state.ms.us/members/house/boyd_(19th).xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-19-lower-ocd-person-defa75f7-917e-42da-9d2c-403ad80d1995": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/6d293363-c72f-479b-81fc-225071ac4913",
            "firstName": "Rodney Lawell",
            "lastName": "Hall",
            "fullName": "Rodney Hall",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-MS-20-lower-ocd-person-6d293363-c72f-479b-81fc-225071ac4913",
              "builtID": "ms-lower-20",
              "externalID": "ocd-person/6d293363-c72f-479b-81fc-225071ac4913",
              "geometry": null
            },
            "contactInfo": {
              "email": "rhall@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/hall.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-20-lower-ocd-person-6d293363-c72f-479b-81fc-225071ac4913": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/fd1a4542-8537-4af3-90c8-4052be561c66",
            "firstName": "Donnie",
            "lastName": "Bell",
            "fullName": "Donnie Bell",
            "gender": "Male",
            "party": "Republican",
            "image": "http://ecm.coop/images/photos_house/BELL-APP-2021.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-MS-21-lower-ocd-person-fd1a4542-8537-4af3-90c8-4052be561c66",
              "builtID": "ms-lower-21",
              "externalID": "ocd-person/fd1a4542-8537-4af3-90c8-4052be561c66",
              "geometry": null
            },
            "contactInfo": {
              "email": "dbell@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/bell_(21st).xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-21-lower-ocd-person-fd1a4542-8537-4af3-90c8-4052be561c66": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/d0a3f800-01b9-4442-94e2-676c0a364450",
            "firstName": "Jonathan Ray",
            "lastName": "Lancaster",
            "fullName": "Jon Lancaster",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/lancaster.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-MS-22-lower-ocd-person-d0a3f800-01b9-4442-94e2-676c0a364450",
              "builtID": "ms-lower-22",
              "externalID": "ocd-person/d0a3f800-01b9-4442-94e2-676c0a364450",
              "geometry": null
            },
            "contactInfo": {
              "email": "jlancaster@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/lancaster.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-22-lower-ocd-person-d0a3f800-01b9-4442-94e2-676c0a364450": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/db170373-6140-4fbc-ab64-452d2abcd987",
            "firstName": "Andrew",
            "lastName": "Stepp",
            "fullName": "Andy Stepp",
            "gender": "Male",
            "party": "Republican",
            "image": "https://img1.wsimg.com/isteam/ip/50966f63-7277-4e1c-a1a2-926d291c012d/Stepp%20Website%20Header.jpg/:/cr=t:13.57%25,l:7.42%25,w:70.42%25,h:70.42%25/rs=w:1280,h:963",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-MS-23-lower-ocd-person-db170373-6140-4fbc-ab64-452d2abcd987",
              "builtID": "ms-lower-23",
              "externalID": "ocd-person/db170373-6140-4fbc-ab64-452d2abcd987",
              "geometry": null
            },
            "contactInfo": {
              "email": "stepp@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/stepp.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-23-lower-ocd-person-db170373-6140-4fbc-ab64-452d2abcd987": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/ffba30ff-86d3-42db-ab94-0028df181da1",
            "firstName": "Jeff",
            "lastName": "Hale",
            "fullName": "Jeff Hale",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/hale.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-MS-24-lower-ocd-person-ffba30ff-86d3-42db-ab94-0028df181da1",
              "builtID": "ms-lower-24",
              "externalID": "ocd-person/ffba30ff-86d3-42db-ab94-0028df181da1",
              "geometry": null
            },
            "contactInfo": {
              "email": "jhale@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/hale.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-24-lower-ocd-person-ffba30ff-86d3-42db-ab94-0028df181da1": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/48567f29-ec70-4ff5-bda5-873f2e42933b",
            "firstName": "Dan",
            "lastName": "Eubanks",
            "fullName": "Dan Eubanks",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/eubanks.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-MS-25-lower-ocd-person-48567f29-ec70-4ff5-bda5-873f2e42933b",
              "builtID": "ms-lower-25",
              "externalID": "ocd-person/48567f29-ec70-4ff5-bda5-873f2e42933b",
              "geometry": null
            },
            "contactInfo": {
              "email": "deubanks@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/eubanks.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-25-lower-ocd-person-48567f29-ec70-4ff5-bda5-873f2e42933b": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/b6553553-bd9a-4bd4-92da-27018b5914fe",
            "firstName": "Orlando W.",
            "lastName": "Paden",
            "fullName": "Orlando Paden",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/paden.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-MS-26-lower-ocd-person-b6553553-bd9a-4bd4-92da-27018b5914fe",
              "builtID": "ms-lower-26",
              "externalID": "ocd-person/b6553553-bd9a-4bd4-92da-27018b5914fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "opaden@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/paden.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-26-lower-ocd-person-b6553553-bd9a-4bd4-92da-27018b5914fe": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/f0fd6950-d890-445b-834e-a635715b75d5",
            "firstName": "Kenji",
            "lastName": "Holloway",
            "fullName": "Kenji Holloway",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/KenjiHolloway.jpeg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-MS-27-lower-ocd-person-f0fd6950-d890-445b-834e-a635715b75d5",
              "builtID": "ms-lower-27",
              "externalID": "ocd-person/f0fd6950-d890-445b-834e-a635715b75d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "holloway@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/holloway_(27th).xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-27-lower-ocd-person-f0fd6950-d890-445b-834e-a635715b75d5": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/7051aea6-71cf-4c6e-a3f7-5eebe826aa44",
            "firstName": "W.I.",
            "lastName": "Harris",
            "fullName": "Doc Harris",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/harrisdoc.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-MS-28-lower-ocd-person-7051aea6-71cf-4c6e-a3f7-5eebe826aa44",
              "builtID": "ms-lower-28",
              "externalID": "ocd-person/7051aea6-71cf-4c6e-a3f7-5eebe826aa44",
              "geometry": null
            },
            "contactInfo": {
              "email": "wharris@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/harris.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-28-lower-ocd-person-7051aea6-71cf-4c6e-a3f7-5eebe826aa44": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/e7684f57-ee0c-48ea-8157-8f04c2b8e685",
            "firstName": "Robert L.",
            "lastName": "Sanders",
            "fullName": "Robert Sanders",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/sanders.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-MS-29-lower-ocd-person-e7684f57-ee0c-48ea-8157-8f04c2b8e685",
              "builtID": "ms-lower-29",
              "externalID": "ocd-person/e7684f57-ee0c-48ea-8157-8f04c2b8e685",
              "geometry": null
            },
            "contactInfo": {
              "email": "rsanders@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/sanders.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-29-lower-ocd-person-e7684f57-ee0c-48ea-8157-8f04c2b8e685": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/b37913d2-9f6e-4f1c-b6c8-7a19863be463",
            "firstName": "Tracey Torrance",
            "lastName": "Rosebud",
            "fullName": "Tracey Rosebud",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/rosebud.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-MS-30-lower-ocd-person-b37913d2-9f6e-4f1c-b6c8-7a19863be463",
              "builtID": "ms-lower-30",
              "externalID": "ocd-person/b37913d2-9f6e-4f1c-b6c8-7a19863be463",
              "geometry": null
            },
            "contactInfo": {
              "email": "trosebud@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/rosebud.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-30-lower-ocd-person-b37913d2-9f6e-4f1c-b6c8-7a19863be463": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/d4007757-e112-43b0-8261-1dd806f658c8",
            "firstName": "Otis L.",
            "lastName": "Anthony",
            "fullName": "Otis Anthony",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/anthony.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-MS-31-lower-ocd-person-d4007757-e112-43b0-8261-1dd806f658c8",
              "builtID": "ms-lower-31",
              "externalID": "ocd-person/d4007757-e112-43b0-8261-1dd806f658c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "oanthony@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/anthony.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-31-lower-ocd-person-d4007757-e112-43b0-8261-1dd806f658c8": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/23d69b3d-2f17-4fa9-a23c-c9ad8db4f7a9",
            "firstName": "Solomon Curtis",
            "lastName": "Osborne",
            "fullName": "Solomon Osborne",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/osborne.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-MS-32-lower-ocd-person-23d69b3d-2f17-4fa9-a23c-c9ad8db4f7a9",
              "builtID": "ms-lower-32",
              "externalID": "ocd-person/23d69b3d-2f17-4fa9-a23c-c9ad8db4f7a9",
              "geometry": null
            },
            "contactInfo": {
              "email": "sosborne@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/osborne.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-32-lower-ocd-person-23d69b3d-2f17-4fa9-a23c-c9ad8db4f7a9": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/822c7153-6b04-4db2-bec4-b6f9d074fb75",
            "firstName": "Jim",
            "lastName": "Estrada",
            "fullName": "Jim Estrada",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/JimEstrada2023.jpeg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-MS-33-lower-ocd-person-822c7153-6b04-4db2-bec4-b6f9d074fb75",
              "builtID": "ms-lower-33",
              "externalID": "ocd-person/822c7153-6b04-4db2-bec4-b6f9d074fb75",
              "geometry": null
            },
            "contactInfo": {
              "email": "jestrada@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/estrada.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-33-lower-ocd-person-822c7153-6b04-4db2-bec4-b6f9d074fb75": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/b339d980-af38-4e76-b53c-215c26191cc1",
            "firstName": "Mark Kevin",
            "lastName": "Horan",
            "fullName": "Kevin Horan",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/horan.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-MS-34-lower-ocd-person-b339d980-af38-4e76-b53c-215c26191cc1",
              "builtID": "ms-lower-34",
              "externalID": "ocd-person/b339d980-af38-4e76-b53c-215c26191cc1",
              "geometry": null
            },
            "contactInfo": {
              "email": "khoran@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/horan.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-34-lower-ocd-person-b339d980-af38-4e76-b53c-215c26191cc1": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/622761e7-fba1-4a35-879b-45a6dd021ab0",
            "firstName": "Joey",
            "lastName": "Hood",
            "fullName": "Joey Hood",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/hood.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-MS-35-lower-ocd-person-622761e7-fba1-4a35-879b-45a6dd021ab0",
              "builtID": "ms-lower-35",
              "externalID": "ocd-person/622761e7-fba1-4a35-879b-45a6dd021ab0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jhood@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/hood.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-35-lower-ocd-person-622761e7-fba1-4a35-879b-45a6dd021ab0": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/38d590c5-20ff-42d5-b9dd-af77664213cf",
            "firstName": "Karl Malinsky",
            "lastName": "Gibbs",
            "fullName": "Karl Gibbs",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/gibbs.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-MS-36-lower-ocd-person-38d590c5-20ff-42d5-b9dd-af77664213cf",
              "builtID": "ms-lower-36",
              "externalID": "ocd-person/38d590c5-20ff-42d5-b9dd-af77664213cf",
              "geometry": null
            },
            "contactInfo": {
              "email": "kgibbs@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/gibbs.xml",
                "https://billstatus.ls.state.ms.us/members/house/gibbs_(36th).xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-36-lower-ocd-person-38d590c5-20ff-42d5-b9dd-af77664213cf": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/95d25b69-8eb7-44cd-a789-54351e17970c",
            "firstName": "George Andrew",
            "lastName": "Boyd",
            "fullName": "Andy Boyd",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/boyd_(37).jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-MS-37-lower-ocd-person-95d25b69-8eb7-44cd-a789-54351e17970c",
              "builtID": "ms-lower-37",
              "externalID": "ocd-person/95d25b69-8eb7-44cd-a789-54351e17970c",
              "geometry": null
            },
            "contactInfo": {
              "email": "aboyd@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/boyd_(37).xml",
                "https://billstatus.ls.state.ms.us/members/house/boyd_(37th).xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-37-lower-ocd-person-95d25b69-8eb7-44cd-a789-54351e17970c": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/74ef0a3e-35c9-4729-b6fd-2c07c165864d",
            "firstName": "Cheikh",
            "lastName": "Taylor",
            "fullName": "Cheikh Taylor",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.starkvilledailynews.com/sites/default/files/styles/galleria_zoom/public/field/image/TAYLOR%20MUG_0.png?itok=jRnUfeN0",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-MS-38-lower-ocd-person-74ef0a3e-35c9-4729-b6fd-2c07c165864d",
              "builtID": "ms-lower-38",
              "externalID": "ocd-person/74ef0a3e-35c9-4729-b6fd-2c07c165864d",
              "geometry": null
            },
            "contactInfo": {
              "email": "ctaylor@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/taylor.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-38-lower-ocd-person-74ef0a3e-35c9-4729-b6fd-2c07c165864d": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/22afa0b0-e76a-4ac0-af9a-fa158c55daea",
            "firstName": "Dana Underwood",
            "lastName": "McLean",
            "fullName": "Dana McLean",
            "gender": "Female",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/mclean.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-MS-39-lower-ocd-person-22afa0b0-e76a-4ac0-af9a-fa158c55daea",
              "builtID": "ms-lower-39",
              "externalID": "ocd-person/22afa0b0-e76a-4ac0-af9a-fa158c55daea",
              "geometry": null
            },
            "contactInfo": {
              "email": "dmclean@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/mclean.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-39-lower-ocd-person-22afa0b0-e76a-4ac0-af9a-fa158c55daea": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/3a2e8f7d-e657-45e2-93d7-2f5e8499ccfd",
            "firstName": "Hester",
            "lastName": "Jackson-McCray",
            "fullName": "Hester Jackson-McCray",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/mccray.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-MS-40-lower-ocd-person-3a2e8f7d-e657-45e2-93d7-2f5e8499ccfd",
              "builtID": "ms-lower-40",
              "externalID": "ocd-person/3a2e8f7d-e657-45e2-93d7-2f5e8499ccfd",
              "geometry": null
            },
            "contactInfo": {
              "email": "hmccray@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/mccray.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-40-lower-ocd-person-3a2e8f7d-e657-45e2-93d7-2f5e8499ccfd": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/a1fef221-e3ca-4598-a7f9-cf6862b3007a",
            "firstName": "Kabir",
            "lastName": "Karriem",
            "fullName": "Kabir Karriem",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/karriem.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-MS-41-lower-ocd-person-a1fef221-e3ca-4598-a7f9-cf6862b3007a",
              "builtID": "ms-lower-41",
              "externalID": "ocd-person/a1fef221-e3ca-4598-a7f9-cf6862b3007a",
              "geometry": null
            },
            "contactInfo": {
              "email": "kkarriem@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/karriem.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-41-lower-ocd-person-a1fef221-e3ca-4598-a7f9-cf6862b3007a": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/6e50b530-8c0e-485a-a568-6d40ba83127d",
            "firstName": "Carl L.",
            "lastName": "Mickens",
            "fullName": "Carl Mickens",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/mickens.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-MS-42-lower-ocd-person-6e50b530-8c0e-485a-a568-6d40ba83127d",
              "builtID": "ms-lower-42",
              "externalID": "ocd-person/6e50b530-8c0e-485a-a568-6d40ba83127d",
              "geometry": null
            },
            "contactInfo": {
              "email": "cmickens@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/mickens.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-42-lower-ocd-person-6e50b530-8c0e-485a-a568-6d40ba83127d": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/e36cdae5-3437-48fc-93b4-280b09af661b",
            "firstName": "Loyd B.",
            "lastName": "Roberson",
            "fullName": "Rob Roberson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://robrobersonattorney.com/wp-content/uploads/2019/07/Rob-Roberson.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-MS-43-lower-ocd-person-e36cdae5-3437-48fc-93b4-280b09af661b",
              "builtID": "ms-lower-43",
              "externalID": "ocd-person/e36cdae5-3437-48fc-93b4-280b09af661b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rroberson@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/roberson.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-43-lower-ocd-person-e36cdae5-3437-48fc-93b4-280b09af661b": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/796d8875-e1a2-4274-bdba-2e689d99929c",
            "firstName": "Craig Scott",
            "lastName": "Bounds",
            "fullName": "C. Scott Bounds",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/bounds.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-MS-44-lower-ocd-person-796d8875-e1a2-4274-bdba-2e689d99929c",
              "builtID": "ms-lower-44",
              "externalID": "ocd-person/796d8875-e1a2-4274-bdba-2e689d99929c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sbounds@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/bounds.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-44-lower-ocd-person-796d8875-e1a2-4274-bdba-2e689d99929c": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/0f07445d-ab7a-4c6f-9b3c-a402436afbed",
            "firstName": "Keith K.",
            "lastName": "Jackson",
            "fullName": "Keith Jackson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.theclintoncourier.net/wp-content/uploads/2024/03/Statement-by-Attorney-Christopher-A.-Collins-on-Behalf-of-Keith-Jackson.png",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-MS-45-lower-ocd-person-0f07445d-ab7a-4c6f-9b3c-a402436afbed",
              "builtID": "ms-lower-45",
              "externalID": "ocd-person/0f07445d-ab7a-4c6f-9b3c-a402436afbed",
              "geometry": null
            },
            "contactInfo": {
              "email": "jackson@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/jackson_(45th).xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-45-lower-ocd-person-0f07445d-ab7a-4c6f-9b3c-a402436afbed": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/761527db-bbe0-43e5-90e0-2a06528ebfa4",
            "firstName": "Karl",
            "lastName": "Oliver",
            "fullName": "Karl Oliver",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/oliver.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-MS-46-lower-ocd-person-761527db-bbe0-43e5-90e0-2a06528ebfa4",
              "builtID": "ms-lower-46",
              "externalID": "ocd-person/761527db-bbe0-43e5-90e0-2a06528ebfa4",
              "geometry": null
            },
            "contactInfo": {
              "email": "koliver@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/oliver.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-46-lower-ocd-person-761527db-bbe0-43e5-90e0-2a06528ebfa4": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/0d191683-0d9c-408f-812b-5d986a3030a6",
            "firstName": "Bryant W.",
            "lastName": "Clark",
            "fullName": "Bryant Clark",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/clark.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-MS-47-lower-ocd-person-0d191683-0d9c-408f-812b-5d986a3030a6",
              "builtID": "ms-lower-47",
              "externalID": "ocd-person/0d191683-0d9c-408f-812b-5d986a3030a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "bclark@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/clark.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-47-lower-ocd-person-0d191683-0d9c-408f-812b-5d986a3030a6": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/fa29dc4f-e4cb-45dd-a450-a1911d529801",
            "firstName": "Jason M.",
            "lastName": "White",
            "fullName": "Jason White",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/white.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-MS-48-lower-ocd-person-fa29dc4f-e4cb-45dd-a450-a1911d529801",
              "builtID": "ms-lower-48",
              "externalID": "ocd-person/fa29dc4f-e4cb-45dd-a450-a1911d529801",
              "geometry": null
            },
            "contactInfo": {
              "email": "jwhite@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/White.xml",
                "https://billstatus.ls.state.ms.us/members/house/white.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-48-lower-ocd-person-fa29dc4f-e4cb-45dd-a450-a1911d529801": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/bec0f8f6-7f4c-442b-be25-14e2a2c1f5cb",
            "firstName": "Willie",
            "lastName": "Bailey",
            "fullName": "Willie Bailey",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/bailey.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-MS-49-lower-ocd-person-bec0f8f6-7f4c-442b-be25-14e2a2c1f5cb",
              "builtID": "ms-lower-49",
              "externalID": "ocd-person/bec0f8f6-7f4c-442b-be25-14e2a2c1f5cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "wbailey@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/bailey.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-49-lower-ocd-person-bec0f8f6-7f4c-442b-be25-14e2a2c1f5cb": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/a432eb59-5656-4869-aedc-832b2206b4a5",
            "firstName": "John W.",
            "lastName": "Hines",
            "fullName": "John Hines",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/hines.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-MS-50-lower-ocd-person-a432eb59-5656-4869-aedc-832b2206b4a5",
              "builtID": "ms-lower-50",
              "externalID": "ocd-person/a432eb59-5656-4869-aedc-832b2206b4a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "jhines@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/hines.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-50-lower-ocd-person-a432eb59-5656-4869-aedc-832b2206b4a5": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/3312edf4-798e-4333-b2c8-4d73f246de01",
            "firstName": "Timaka",
            "lastName": "James-Jones",
            "fullName": "Timaka James-Jones",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/TimakaJames-Jones2023.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-MS-51-lower-ocd-person-3312edf4-798e-4333-b2c8-4d73f246de01",
              "builtID": "ms-lower-51",
              "externalID": "ocd-person/3312edf4-798e-4333-b2c8-4d73f246de01",
              "geometry": null
            },
            "contactInfo": {
              "email": "james-jones@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/james-jones.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-51-lower-ocd-person-3312edf4-798e-4333-b2c8-4d73f246de01": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/af66e7a0-2b1a-4fa0-a66c-e6e7a2541cb1",
            "firstName": "William Franklin",
            "lastName": "Kinkade",
            "fullName": "Bill Kinkade",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/kinkade.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-MS-52-lower-ocd-person-af66e7a0-2b1a-4fa0-a66c-e6e7a2541cb1",
              "builtID": "ms-lower-52",
              "externalID": "ocd-person/af66e7a0-2b1a-4fa0-a66c-e6e7a2541cb1",
              "geometry": null
            },
            "contactInfo": {
              "email": "bkinkade@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/kinkade.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-52-lower-ocd-person-af66e7a0-2b1a-4fa0-a66c-e6e7a2541cb1": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/c0d8a1af-3036-4aee-89e7-f98cc40203cd",
            "firstName": "Vince",
            "lastName": "Mangold",
            "fullName": "Vince Mangold",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/mangold.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-MS-53-lower-ocd-person-c0d8a1af-3036-4aee-89e7-f98cc40203cd",
              "builtID": "ms-lower-53",
              "externalID": "ocd-person/c0d8a1af-3036-4aee-89e7-f98cc40203cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "vmangold@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/mangold.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-53-lower-ocd-person-c0d8a1af-3036-4aee-89e7-f98cc40203cd": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/7a9174a7-1301-407a-8c77-5f70e70fde92",
            "firstName": "Kevin",
            "lastName": "Ford",
            "fullName": "Kevin Ford",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/ford.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-MS-54-lower-ocd-person-7a9174a7-1301-407a-8c77-5f70e70fde92",
              "builtID": "ms-lower-54",
              "externalID": "ocd-person/7a9174a7-1301-407a-8c77-5f70e70fde92",
              "geometry": null
            },
            "contactInfo": {
              "email": "kford@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/ford.xml",
                "https://billstatus.ls.state.ms.us/members/house/ford_(54th).xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-54-lower-ocd-person-7a9174a7-1301-407a-8c77-5f70e70fde92": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/5fb7e1d6-24ab-41e1-b0b2-d1445bf6abd3",
            "firstName": "Oscar",
            "lastName": "Denton",
            "fullName": "Oscar Denton",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/denton.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-MS-55-lower-ocd-person-5fb7e1d6-24ab-41e1-b0b2-d1445bf6abd3",
              "builtID": "ms-lower-55",
              "externalID": "ocd-person/5fb7e1d6-24ab-41e1-b0b2-d1445bf6abd3",
              "geometry": null
            },
            "contactInfo": {
              "email": "odenton@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/denton.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-55-lower-ocd-person-5fb7e1d6-24ab-41e1-b0b2-d1445bf6abd3": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/bb9ba950-b671-4c41-b851-4a2c86d2059d",
            "firstName": "Clay",
            "lastName": "Mansell",
            "fullName": "Clay Mansell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Clay_Mansell.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-MS-56-lower-ocd-person-bb9ba950-b671-4c41-b851-4a2c86d2059d",
              "builtID": "ms-lower-56",
              "externalID": "ocd-person/bb9ba950-b671-4c41-b851-4a2c86d2059d",
              "geometry": null
            },
            "contactInfo": {
              "email": "cmansell@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/mansell.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-56-lower-ocd-person-bb9ba950-b671-4c41-b851-4a2c86d2059d": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/c38da14f-37fd-48fd-9903-e63ce18c1215",
            "firstName": "Lawrence Stephen",
            "lastName": "Blackmon",
            "fullName": "Lawrence Blackmon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://i0.wp.com/www.theindustrycosign.com/wp-content/uploads/2021/11/Lawrence-Blackmon-the-industry-cosign-big-ced.jpg?w=1170&ssl=1",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-MS-57-lower-ocd-person-c38da14f-37fd-48fd-9903-e63ce18c1215",
              "builtID": "ms-lower-57",
              "externalID": "ocd-person/c38da14f-37fd-48fd-9903-e63ce18c1215",
              "geometry": null
            },
            "contactInfo": {
              "email": "lblackmon@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/blackmon.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-57-lower-ocd-person-c38da14f-37fd-48fd-9903-e63ce18c1215": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/392af292-07d5-4bfd-9636-fe4257df61b3",
            "firstName": "Jonathan",
            "lastName": "McMillan",
            "fullName": "Jonathan McMillan",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-MS-58-lower-ocd-person-392af292-07d5-4bfd-9636-fe4257df61b3",
              "builtID": "ms-lower-58",
              "externalID": "ocd-person/392af292-07d5-4bfd-9636-fe4257df61b3",
              "geometry": null
            },
            "contactInfo": {
              "email": "mcmillan@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/mcmillan.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-58-lower-ocd-person-392af292-07d5-4bfd-9636-fe4257df61b3": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/222077fd-0e92-4437-a156-59521d7faffa",
            "firstName": "Brent",
            "lastName": "Powell",
            "fullName": "Brent Powell",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/powell.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-MS-59-lower-ocd-person-222077fd-0e92-4437-a156-59521d7faffa",
              "builtID": "ms-lower-59",
              "externalID": "ocd-person/222077fd-0e92-4437-a156-59521d7faffa",
              "geometry": null
            },
            "contactInfo": {
              "email": "bpowell@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/powell.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-59-lower-ocd-person-222077fd-0e92-4437-a156-59521d7faffa": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/002bdecd-2374-44d8-8280-e0636c5f4897",
            "firstName": "Fred",
            "lastName": "Shanks",
            "fullName": "Fred Shanks",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/shanks.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-MS-60-lower-ocd-person-002bdecd-2374-44d8-8280-e0636c5f4897",
              "builtID": "ms-lower-60",
              "externalID": "ocd-person/002bdecd-2374-44d8-8280-e0636c5f4897",
              "geometry": null
            },
            "contactInfo": {
              "email": "fshanks@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/shanks.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-60-lower-ocd-person-002bdecd-2374-44d8-8280-e0636c5f4897": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/2fe9c09d-6213-4576-b0e4-6e89330f41bc",
            "firstName": "Gene",
            "lastName": "Newman",
            "fullName": "Gene Newman",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/newman.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-MS-61-lower-ocd-person-2fe9c09d-6213-4576-b0e4-6e89330f41bc",
              "builtID": "ms-lower-61",
              "externalID": "ocd-person/2fe9c09d-6213-4576-b0e4-6e89330f41bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "gnewman@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/newman.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-61-lower-ocd-person-2fe9c09d-6213-4576-b0e4-6e89330f41bc": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/f9eedb57-45af-4743-b869-ece1ccb643e2",
            "firstName": "Lance",
            "lastName": "Varner",
            "fullName": "Lance Varner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://mcgeerealtyservices.com/wp-content/uploads/2020/09/Lances-Headshot-300x400.gif",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-MS-62-lower-ocd-person-f9eedb57-45af-4743-b869-ece1ccb643e2",
              "builtID": "ms-lower-62",
              "externalID": "ocd-person/f9eedb57-45af-4743-b869-ece1ccb643e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "varner@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/varner.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-62-lower-ocd-person-f9eedb57-45af-4743-b869-ece1ccb643e2": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/1253bd3e-36dd-4f23-a671-ab24dc4aed12",
            "firstName": "Stephanie McKenzie",
            "lastName": "Foster",
            "fullName": "Stephanie Foster",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/foster.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-MS-63-lower-ocd-person-1253bd3e-36dd-4f23-a671-ab24dc4aed12",
              "builtID": "ms-lower-63",
              "externalID": "ocd-person/1253bd3e-36dd-4f23-a671-ab24dc4aed12",
              "geometry": null
            },
            "contactInfo": {
              "email": "sfoster@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/foster.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-63-lower-ocd-person-1253bd3e-36dd-4f23-a671-ab24dc4aed12": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/de5a9a25-1b08-45ba-9aff-e635905498ba",
            "firstName": "Shanda M.",
            "lastName": "Yates",
            "fullName": "Shanda Yates",
            "gender": "Female",
            "party": "independent",
            "image": "http://billstatus.ls.state.ms.us/members/house/yates.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-MS-64-lower-ocd-person-de5a9a25-1b08-45ba-9aff-e635905498ba",
              "builtID": "ms-lower-64",
              "externalID": "ocd-person/de5a9a25-1b08-45ba-9aff-e635905498ba",
              "geometry": null
            },
            "contactInfo": {
              "email": "syates@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/yates.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-64-lower-ocd-person-de5a9a25-1b08-45ba-9aff-e635905498ba": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/5aa9d603-5bb2-4c1d-9a0b-1baaf91c425d",
            "firstName": "Christopher M.",
            "lastName": "Bell",
            "fullName": "Christopher Bell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://static.votesmart.org/static/canphoto/40229.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-MS-65-lower-ocd-person-5aa9d603-5bb2-4c1d-9a0b-1baaf91c425d",
              "builtID": "ms-lower-65",
              "externalID": "ocd-person/5aa9d603-5bb2-4c1d-9a0b-1baaf91c425d",
              "geometry": null
            },
            "contactInfo": {
              "email": "cbell@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/bell_(65th).xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-65-lower-ocd-person-5aa9d603-5bb2-4c1d-9a0b-1baaf91c425d": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/706088db-555c-4dac-b9c4-ca6574241ca4",
            "firstName": "Fabian",
            "lastName": "Nelson",
            "fullName": "Fabian Nelson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/fnelson.JPG",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-MS-66-lower-ocd-person-706088db-555c-4dac-b9c4-ca6574241ca4",
              "builtID": "ms-lower-66",
              "externalID": "ocd-person/706088db-555c-4dac-b9c4-ca6574241ca4",
              "geometry": null
            },
            "contactInfo": {
              "email": "nelson@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/nelson.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-66-lower-ocd-person-706088db-555c-4dac-b9c4-ca6574241ca4": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/4fa41145-35bf-442b-bdfe-cf54ea53da69",
            "firstName": "Earle S.",
            "lastName": "Banks",
            "fullName": "Earle Banks",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/banks.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-MS-67-lower-ocd-person-4fa41145-35bf-442b-bdfe-cf54ea53da69",
              "builtID": "ms-lower-67",
              "externalID": "ocd-person/4fa41145-35bf-442b-bdfe-cf54ea53da69",
              "geometry": null
            },
            "contactInfo": {
              "email": "ebankslaw@aol.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/banks.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-67-lower-ocd-person-4fa41145-35bf-442b-bdfe-cf54ea53da69": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/56158045-4973-4fd2-9589-01ad212f2975",
            "firstName": "Zakiya",
            "lastName": "Summers",
            "fullName": "Zakiya Summers",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://stateinnovation.org/wp-content/uploads/2021/04/Zakiya-Summers.png",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-MS-68-lower-ocd-person-56158045-4973-4fd2-9589-01ad212f2975",
              "builtID": "ms-lower-68",
              "externalID": "ocd-person/56158045-4973-4fd2-9589-01ad212f2975",
              "geometry": null
            },
            "contactInfo": {
              "email": "zsummers@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/summers.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-68-lower-ocd-person-56158045-4973-4fd2-9589-01ad212f2975": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/c135a4b0-9620-4eef-b561-2b08beda7289",
            "firstName": "Tamarra Grace",
            "lastName": "Butler-Washington",
            "fullName": "Grace Butler-Washington",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/TamarraGraceButlerWashington.png",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-MS-69-lower-ocd-person-c135a4b0-9620-4eef-b561-2b08beda7289",
              "builtID": "ms-lower-69",
              "externalID": "ocd-person/c135a4b0-9620-4eef-b561-2b08beda7289",
              "geometry": null
            },
            "contactInfo": {
              "email": "gbutlerwashington@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/butler-washinton.xml",
                "https://billstatus.ls.state.ms.us/members/house/butler-washington.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-69-lower-ocd-person-c135a4b0-9620-4eef-b561-2b08beda7289": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/b8541474-47fd-4dd2-8576-53b7fe911039",
            "firstName": "William R.",
            "lastName": "Brown",
            "fullName": "Bo Brown",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/brown_(70th).jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-MS-70-lower-ocd-person-b8541474-47fd-4dd2-8576-53b7fe911039",
              "builtID": "ms-lower-70",
              "externalID": "ocd-person/b8541474-47fd-4dd2-8576-53b7fe911039",
              "geometry": null
            },
            "contactInfo": {
              "email": "bbrown@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/brown_(70th).xml",
                "https://billstatus.ls.state.ms.us/members/house/brown.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-70-lower-ocd-person-b8541474-47fd-4dd2-8576-53b7fe911039": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/fee2ce67-1403-487e-b891-587e625ce15c",
            "firstName": "Ronnie C.",
            "lastName": "Crudup",
            "fullName": "Ronnie Crudup",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/crudup.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-MS-71-lower-ocd-person-fee2ce67-1403-487e-b891-587e625ce15c",
              "builtID": "ms-lower-71",
              "externalID": "ocd-person/fee2ce67-1403-487e-b891-587e625ce15c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rcrudup@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/crudup.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-71-lower-ocd-person-fee2ce67-1403-487e-b891-587e625ce15c": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/48af55d6-95ef-4e57-9ec3-a566af0ce517",
            "firstName": "Justis Robert",
            "lastName": "Gibbs",
            "fullName": "Justis Gibbs",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/JustisGibbs.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-MS-72-lower-ocd-person-48af55d6-95ef-4e57-9ec3-a566af0ce517",
              "builtID": "ms-lower-72",
              "externalID": "ocd-person/48af55d6-95ef-4e57-9ec3-a566af0ce517",
              "geometry": null
            },
            "contactInfo": {
              "email": "gibbs@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/gibbs_(72nd).xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-72-lower-ocd-person-48af55d6-95ef-4e57-9ec3-a566af0ce517": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/922a1ebe-1f17-497b-af99-5f14b6b0f66a",
            "firstName": "Jill",
            "lastName": "Ford",
            "fullName": "Jill Ford",
            "gender": "Female",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/ford_(73rd).jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-MS-73-lower-ocd-person-922a1ebe-1f17-497b-af99-5f14b6b0f66a",
              "builtID": "ms-lower-73",
              "externalID": "ocd-person/922a1ebe-1f17-497b-af99-5f14b6b0f66a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jford@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/ford_(73rd).xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-73-lower-ocd-person-922a1ebe-1f17-497b-af99-5f14b6b0f66a": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/fe627b98-efb2-43b0-a6e5-7ad967c1a2fc",
            "firstName": "Jeremy Lewayne",
            "lastName": "Yancey",
            "fullName": "Lee Yancey",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/yancey.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-MS-74-lower-ocd-person-fe627b98-efb2-43b0-a6e5-7ad967c1a2fc",
              "builtID": "ms-lower-74",
              "externalID": "ocd-person/fe627b98-efb2-43b0-a6e5-7ad967c1a2fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "lyancey@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/yancey.xml",
                "https://billstatus.ls.state.ms.us/members/house/yancey.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-74-lower-ocd-person-fe627b98-efb2-43b0-a6e5-7ad967c1a2fc": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/c854a4e7-61d0-4c5e-8623-694a38f0ad20",
            "firstName": "Celeste",
            "lastName": "Hurst",
            "fullName": "Celeste Hurst",
            "gender": "Female",
            "party": "Republican",
            "image": "https://static.wixstatic.com/media/4b62c6_190e934501654befbc52179bbb3d45b0~mv2.jpeg/v1/fill/w_605,h_713,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/4b62c6_190e934501654befbc52179bbb3d45b0~mv2.jpeg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-MS-75-lower-ocd-person-c854a4e7-61d0-4c5e-8623-694a38f0ad20",
              "builtID": "ms-lower-75",
              "externalID": "ocd-person/c854a4e7-61d0-4c5e-8623-694a38f0ad20",
              "geometry": null
            },
            "contactInfo": {
              "email": "churst@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/hurst.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-75-lower-ocd-person-c854a4e7-61d0-4c5e-8623-694a38f0ad20": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/745b9724-e66d-46ec-874a-d658f01b78a6",
            "firstName": "Gregory",
            "lastName": "Holloway",
            "fullName": "Gregory Holloway",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/holloway.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-MS-76-lower-ocd-person-745b9724-e66d-46ec-874a-d658f01b78a6",
              "builtID": "ms-lower-76",
              "externalID": "ocd-person/745b9724-e66d-46ec-874a-d658f01b78a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "gholloway@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/holloway.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-76-lower-ocd-person-745b9724-e66d-46ec-874a-d658f01b78a6": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/35bf01bd-be16-4ce6-b07c-bfff3d25e7e3",
            "firstName": "Price",
            "lastName": "Wallace",
            "fullName": "Price Wallace",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/wallace.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-MS-77-lower-ocd-person-35bf01bd-be16-4ce6-b07c-bfff3d25e7e3",
              "builtID": "ms-lower-77",
              "externalID": "ocd-person/35bf01bd-be16-4ce6-b07c-bfff3d25e7e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "pwallace@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/wallace.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-77-lower-ocd-person-35bf01bd-be16-4ce6-b07c-bfff3d25e7e3": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/af2a8aef-177c-49d2-9b77-f1f19f7b8eaa",
            "firstName": "Randal Kevin",
            "lastName": "Rushing",
            "fullName": "Randy Rushing",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/rushing.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-MS-78-lower-ocd-person-af2a8aef-177c-49d2-9b77-f1f19f7b8eaa",
              "builtID": "ms-lower-78",
              "externalID": "ocd-person/af2a8aef-177c-49d2-9b77-f1f19f7b8eaa",
              "geometry": null
            },
            "contactInfo": {
              "email": "rrushing@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/rushing.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-78-lower-ocd-person-af2a8aef-177c-49d2-9b77-f1f19f7b8eaa": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/3439bb59-6632-4653-83e1-031422ad832f",
            "firstName": "Mark K.",
            "lastName": "Tullos",
            "fullName": "Mark Tullos",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/tullos.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-MS-79-lower-ocd-person-3439bb59-6632-4653-83e1-031422ad832f",
              "builtID": "ms-lower-79",
              "externalID": "ocd-person/3439bb59-6632-4653-83e1-031422ad832f",
              "geometry": null
            },
            "contactInfo": {
              "email": "mtullos@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/tullos.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-79-lower-ocd-person-3439bb59-6632-4653-83e1-031422ad832f": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/ee6c2cb7-6d0f-4b45-9e06-2ba1dfae8ecb",
            "firstName": "Omeria McDonald",
            "lastName": "Scott",
            "fullName": "Omeria Scott",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/scott.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-MS-80-lower-ocd-person-ee6c2cb7-6d0f-4b45-9e06-2ba1dfae8ecb",
              "builtID": "ms-lower-80",
              "externalID": "ocd-person/ee6c2cb7-6d0f-4b45-9e06-2ba1dfae8ecb",
              "geometry": null
            },
            "contactInfo": {
              "email": "oscott@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/scott.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-80-lower-ocd-person-ee6c2cb7-6d0f-4b45-9e06-2ba1dfae8ecb": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/f1e7e5ba-29b7-42e2-bb70-5ab4c7923c77",
            "firstName": "Stephen A.",
            "lastName": "Horne",
            "fullName": "Steve Horne",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/horne.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-MS-81-lower-ocd-person-f1e7e5ba-29b7-42e2-bb70-5ab4c7923c77",
              "builtID": "ms-lower-81",
              "externalID": "ocd-person/f1e7e5ba-29b7-42e2-bb70-5ab4c7923c77",
              "geometry": null
            },
            "contactInfo": {
              "email": "shorne@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/horne.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-81-lower-ocd-person-f1e7e5ba-29b7-42e2-bb70-5ab4c7923c77": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/310c4392-eb6a-4cb0-9baa-72cc03153f22",
            "firstName": "Charles Lemuel",
            "lastName": "Young",
            "fullName": "Charles Young",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/young.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-MS-82-lower-ocd-person-310c4392-eb6a-4cb0-9baa-72cc03153f22",
              "builtID": "ms-lower-82",
              "externalID": "ocd-person/310c4392-eb6a-4cb0-9baa-72cc03153f22",
              "geometry": null
            },
            "contactInfo": {
              "email": "cyoung@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/young.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-82-lower-ocd-person-310c4392-eb6a-4cb0-9baa-72cc03153f22": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/15f21051-d36c-4a2a-aa96-100bb8cb693b",
            "firstName": "Billy Adam",
            "lastName": "Calvert",
            "fullName": "Billy Adam Calvert",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/calvert.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-MS-83-lower-ocd-person-15f21051-d36c-4a2a-aa96-100bb8cb693b",
              "builtID": "ms-lower-83",
              "externalID": "ocd-person/15f21051-d36c-4a2a-aa96-100bb8cb693b",
              "geometry": null
            },
            "contactInfo": {
              "email": "bcalvert@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/calvert.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-83-lower-ocd-person-15f21051-d36c-4a2a-aa96-100bb8cb693b": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/5ff548a6-827f-4ab7-a4a5-5b510712f6a2",
            "firstName": "Troy Moss",
            "lastName": "Smith",
            "fullName": "Troy Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/smith.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-MS-84-lower-ocd-person-5ff548a6-827f-4ab7-a4a5-5b510712f6a2",
              "builtID": "ms-lower-84",
              "externalID": "ocd-person/5ff548a6-827f-4ab7-a4a5-5b510712f6a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "tsmith@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/smith.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-84-lower-ocd-person-5ff548a6-827f-4ab7-a4a5-5b510712f6a2": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/73fee1dd-f8c0-40a7-a4f9-e7576b4ecc3f",
            "firstName": "Jeffery",
            "lastName": "Harness",
            "fullName": "Jeffery Harness",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/harness.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-MS-85-lower-ocd-person-73fee1dd-f8c0-40a7-a4f9-e7576b4ecc3f",
              "builtID": "ms-lower-85",
              "externalID": "ocd-person/73fee1dd-f8c0-40a7-a4f9-e7576b4ecc3f",
              "geometry": null
            },
            "contactInfo": {
              "email": "jharness@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/harness.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-85-lower-ocd-person-73fee1dd-f8c0-40a7-a4f9-e7576b4ecc3f": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/9b8df60b-26ba-4cdc-b862-87b15f81fdc1",
            "firstName": "Shane",
            "lastName": "Barnett",
            "fullName": "Shane Barnett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://images.squarespace-cdn.com/content/v1/5472abbae4b0859145039552/1519227639852-W83BICPBB7LGL856WGBQ/Shane.jpg?format=500w",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-MS-86-lower-ocd-person-9b8df60b-26ba-4cdc-b862-87b15f81fdc1",
              "builtID": "ms-lower-86",
              "externalID": "ocd-person/9b8df60b-26ba-4cdc-b862-87b15f81fdc1",
              "geometry": null
            },
            "contactInfo": {
              "email": "sbarnett@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/barnett.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-86-lower-ocd-person-9b8df60b-26ba-4cdc-b862-87b15f81fdc1": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/820dcd6c-fdeb-4244-8ceb-7f5ce3048674",
            "firstName": "Joseph",
            "lastName": "Tubb",
            "fullName": "Joseph Tubb",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/tubb.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-MS-87-lower-ocd-person-820dcd6c-fdeb-4244-8ceb-7f5ce3048674",
              "builtID": "ms-lower-87",
              "externalID": "ocd-person/820dcd6c-fdeb-4244-8ceb-7f5ce3048674",
              "geometry": null
            },
            "contactInfo": {
              "email": "jtubb@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/tubb.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-87-lower-ocd-person-820dcd6c-fdeb-4244-8ceb-7f5ce3048674": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/4a046f0b-1286-4bb3-aea0-7284adab76fd",
            "firstName": "Charles Gray",
            "lastName": "Blackwell",
            "fullName": "Chuck Blackwell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.newyork1.vip.townnews.com/leader-call.com/content/tncms/assets/v3/editorial/1/87/187e6b2a-3e05-11ee-bb42-933e2e528fdf/64dfd3b16ca2f.image.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-MS-88-lower-ocd-person-4a046f0b-1286-4bb3-aea0-7284adab76fd",
              "builtID": "ms-lower-88",
              "externalID": "ocd-person/4a046f0b-1286-4bb3-aea0-7284adab76fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "cblackwell@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/blackwell.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-88-lower-ocd-person-4a046f0b-1286-4bb3-aea0-7284adab76fd": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/2118023c-ed1d-455f-b3a6-faeefd0e87b5",
            "firstName": "Donnie",
            "lastName": "Scoggin",
            "fullName": "Donnie Scoggin",
            "gender": "Male",
            "party": "Republican",
            "image": "http://files.ctctcdn.com/ee25b30f001/08bd0580-a7b2-481a-a9d2-198a61aec56d.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-MS-89-lower-ocd-person-2118023c-ed1d-455f-b3a6-faeefd0e87b5",
              "builtID": "ms-lower-89",
              "externalID": "ocd-person/2118023c-ed1d-455f-b3a6-faeefd0e87b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "dscoggin@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/scoggin.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-89-lower-ocd-person-2118023c-ed1d-455f-b3a6-faeefd0e87b5": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/86dbc7dc-6cd5-4005-b076-9b1bb674e331",
            "firstName": "Noah",
            "lastName": "Sanford",
            "fullName": "Noah Sanford",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/sanford.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-MS-90-lower-ocd-person-86dbc7dc-6cd5-4005-b076-9b1bb674e331",
              "builtID": "ms-lower-90",
              "externalID": "ocd-person/86dbc7dc-6cd5-4005-b076-9b1bb674e331",
              "geometry": null
            },
            "contactInfo": {
              "email": "nsanford@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/sanford.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-90-lower-ocd-person-86dbc7dc-6cd5-4005-b076-9b1bb674e331": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/7fe0478b-3777-490c-93cb-20f83baf3d3f",
            "firstName": "Robert E.",
            "lastName": "Evans",
            "fullName": "Bob Evans",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/evans_(91st).jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-MS-91-lower-ocd-person-7fe0478b-3777-490c-93cb-20f83baf3d3f",
              "builtID": "ms-lower-91",
              "externalID": "ocd-person/7fe0478b-3777-490c-93cb-20f83baf3d3f",
              "geometry": null
            },
            "contactInfo": {
              "email": "bevans@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/evans_(91st).xml",
                "https://billstatus.ls.state.ms.us/members/house/evans_(91st).xml",
                "https://billstatus.ls.state.ms.us/members/house/evans_%2891st%29.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-91-lower-ocd-person-7fe0478b-3777-490c-93cb-20f83baf3d3f": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/4b865b47-4811-4437-be08-0f3bf10d461c",
            "firstName": "Becky",
            "lastName": "Currie",
            "fullName": "Becky Currie",
            "gender": "Female",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/currie.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-MS-92-lower-ocd-person-4b865b47-4811-4437-be08-0f3bf10d461c",
              "builtID": "ms-lower-92",
              "externalID": "ocd-person/4b865b47-4811-4437-be08-0f3bf10d461c",
              "geometry": null
            },
            "contactInfo": {
              "email": "bcurrie@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/currie.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-92-lower-ocd-person-4b865b47-4811-4437-be08-0f3bf10d461c": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/53869ff4-415d-466a-ad71-c13485b83318",
            "firstName": "Timmy",
            "lastName": "Ladner",
            "fullName": "Timmy Ladner",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/ladner.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-MS-93-lower-ocd-person-53869ff4-415d-466a-ad71-c13485b83318",
              "builtID": "ms-lower-93",
              "externalID": "ocd-person/53869ff4-415d-466a-ad71-c13485b83318",
              "geometry": null
            },
            "contactInfo": {
              "email": "tladner@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/ladner.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-93-lower-ocd-person-53869ff4-415d-466a-ad71-c13485b83318": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/0de22359-9403-4bec-917a-985b26fd45ff",
            "firstName": "Robert Lee",
            "lastName": "Johnson",
            "fullName": "Robert Johnson",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/johnson.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-MS-94-lower-ocd-person-0de22359-9403-4bec-917a-985b26fd45ff",
              "builtID": "ms-lower-94",
              "externalID": "ocd-person/0de22359-9403-4bec-917a-985b26fd45ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "rjohnson@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/johnson_(94th).xml",
                "https://billstatus.ls.state.ms.us/members/house/johnson.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-94-lower-ocd-person-0de22359-9403-4bec-917a-985b26fd45ff": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/7674b0fc-99c4-44a7-85ec-7edd5e6e85a0",
            "firstName": "Jay",
            "lastName": "McKnight",
            "fullName": "Jay McKnight",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/mcknight.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-MS-95-lower-ocd-person-7674b0fc-99c4-44a7-85ec-7edd5e6e85a0",
              "builtID": "ms-lower-95",
              "externalID": "ocd-person/7674b0fc-99c4-44a7-85ec-7edd5e6e85a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jmcknight@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/mcknight.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-95-lower-ocd-person-7674b0fc-99c4-44a7-85ec-7edd5e6e85a0": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/83f9f72e-ec2d-4351-a227-d8a6aec18eed",
            "firstName": "Angela",
            "lastName": "Cockerham",
            "fullName": "Angela Cockerham",
            "gender": "Female",
            "party": "independent",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Angela_Cockerham.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-MS-96-lower-ocd-person-83f9f72e-ec2d-4351-a227-d8a6aec18eed",
              "builtID": "ms-lower-96",
              "externalID": "ocd-person/83f9f72e-ec2d-4351-a227-d8a6aec18eed",
              "geometry": null
            },
            "contactInfo": {
              "email": "acockerham@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/cockerham.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-96-lower-ocd-person-83f9f72e-ec2d-4351-a227-d8a6aec18eed": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/0bf76f2b-9380-45c1-8809-315a682322a3",
            "firstName": "Samuel Cochran",
            "lastName": "Mims",
            "fullName": "Sam Mims",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/mims.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-MS-97-lower-ocd-person-0bf76f2b-9380-45c1-8809-315a682322a3",
              "builtID": "ms-lower-97",
              "externalID": "ocd-person/0bf76f2b-9380-45c1-8809-315a682322a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "smims@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/mims.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-97-lower-ocd-person-0bf76f2b-9380-45c1-8809-315a682322a3": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/da9d953d-a95f-4166-9b94-e6c5e5b40c86",
            "firstName": "Daryl L.",
            "lastName": "Porter",
            "fullName": "Daryl Porter",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.pepperodom.com/wp-content/uploads/2019/05/daryl-porter-683x1024.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-MS-98-lower-ocd-person-da9d953d-a95f-4166-9b94-e6c5e5b40c86",
              "builtID": "ms-lower-98",
              "externalID": "ocd-person/da9d953d-a95f-4166-9b94-e6c5e5b40c86",
              "geometry": null
            },
            "contactInfo": {
              "email": "dporter@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/porter.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-98-lower-ocd-person-da9d953d-a95f-4166-9b94-e6c5e5b40c86": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/2fed984f-0f51-40d2-9881-ec16131a6a2f",
            "firstName": "Bill",
            "lastName": "Pigott",
            "fullName": "Bill Pigott",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/pigott.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-MS-99-lower-ocd-person-2fed984f-0f51-40d2-9881-ec16131a6a2f",
              "builtID": "ms-lower-99",
              "externalID": "ocd-person/2fed984f-0f51-40d2-9881-ec16131a6a2f",
              "geometry": null
            },
            "contactInfo": {
              "email": "bpigott@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/pigott.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-99-lower-ocd-person-2fed984f-0f51-40d2-9881-ec16131a6a2f": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/da8a0173-20dc-4979-949d-57d902e744e0",
            "firstName": "Ken",
            "lastName": "Morgan",
            "fullName": "Ken Morgan",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/morgan.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-MS-100-lower-ocd-person-da8a0173-20dc-4979-949d-57d902e744e0",
              "builtID": "ms-lower-100",
              "externalID": "ocd-person/da8a0173-20dc-4979-949d-57d902e744e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "kmorgan@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/morgan.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-100-lower-ocd-person-da8a0173-20dc-4979-949d-57d902e744e0": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/63655fb4-af7a-4b96-95f0-5aa1bd93da62",
            "firstName": "Kent",
            "lastName": "McCarty",
            "fullName": "Kent McCarty",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/mccarty.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-MS-101-lower-ocd-person-63655fb4-af7a-4b96-95f0-5aa1bd93da62",
              "builtID": "ms-lower-101",
              "externalID": "ocd-person/63655fb4-af7a-4b96-95f0-5aa1bd93da62",
              "geometry": null
            },
            "contactInfo": {
              "email": "kmccarty@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/mccarty.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-101-lower-ocd-person-63655fb4-af7a-4b96-95f0-5aa1bd93da62": 0
        }
      },
      "102": {
        "members": [
          {
            "API_ID": "ocd-person/e645db95-2862-40dd-b548-b84b6f0b5f3f",
            "firstName": "Missy Warren",
            "lastName": "McGee",
            "fullName": "Missy McGee",
            "gender": "Female",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/mcgee.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "102",
              "chamber": "lower",
              "hashID": "SLDL-MS-102-lower-ocd-person-e645db95-2862-40dd-b548-b84b6f0b5f3f",
              "builtID": "ms-lower-102",
              "externalID": "ocd-person/e645db95-2862-40dd-b548-b84b6f0b5f3f",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmcgee@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/mcgee.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-102-lower-ocd-person-e645db95-2862-40dd-b548-b84b6f0b5f3f": 0
        }
      },
      "103": {
        "members": [
          {
            "API_ID": "ocd-person/7dcb0b5b-3eb3-4db2-8666-0eb83589289f",
            "firstName": "Percy Willis",
            "lastName": "Watson",
            "fullName": "Percy Watson",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/watson.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "103",
              "chamber": "lower",
              "hashID": "SLDL-MS-103-lower-ocd-person-7dcb0b5b-3eb3-4db2-8666-0eb83589289f",
              "builtID": "ms-lower-103",
              "externalID": "ocd-person/7dcb0b5b-3eb3-4db2-8666-0eb83589289f",
              "geometry": null
            },
            "contactInfo": {
              "email": "pwatson@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/watson.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-103-lower-ocd-person-7dcb0b5b-3eb3-4db2-8666-0eb83589289f": 0
        }
      },
      "104": {
        "members": [
          {
            "API_ID": "ocd-person/57419404-003c-4a1d-93c0-dce987b11c00",
            "firstName": "Larry",
            "lastName": "Byrd",
            "fullName": "Larry Byrd",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/byrd.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "104",
              "chamber": "lower",
              "hashID": "SLDL-MS-104-lower-ocd-person-57419404-003c-4a1d-93c0-dce987b11c00",
              "builtID": "ms-lower-104",
              "externalID": "ocd-person/57419404-003c-4a1d-93c0-dce987b11c00",
              "geometry": null
            },
            "contactInfo": {
              "email": "lbyrd@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/byrd.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-104-lower-ocd-person-57419404-003c-4a1d-93c0-dce987b11c00": 0
        }
      },
      "105": {
        "members": [
          {
            "API_ID": "ocd-person/bb2a876e-ca55-46dd-842d-9b89a910848d",
            "firstName": "Elliot",
            "lastName": "Burch",
            "fullName": "Elliot Burch",
            "gender": "Male",
            "party": "Republican",
            "image": "https://static.wixstatic.com/media/220c5e_621b140aa59647afa9d2a4370f2a9253~mv2_d_5184_3456_s_4_2.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "105",
              "chamber": "lower",
              "hashID": "SLDL-MS-105-lower-ocd-person-bb2a876e-ca55-46dd-842d-9b89a910848d",
              "builtID": "ms-lower-105",
              "externalID": "ocd-person/bb2a876e-ca55-46dd-842d-9b89a910848d",
              "geometry": null
            },
            "contactInfo": {
              "email": "burch@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/burch.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-105-lower-ocd-person-bb2a876e-ca55-46dd-842d-9b89a910848d": 0
        }
      },
      "106": {
        "members": [
          {
            "API_ID": "ocd-person/8a829f4e-388e-41c5-bbc6-9e4292ff9539",
            "firstName": "Jansen T.",
            "lastName": "Owen",
            "fullName": "Jansen Owen",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/owen.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "106",
              "chamber": "lower",
              "hashID": "SLDL-MS-106-lower-ocd-person-8a829f4e-388e-41c5-bbc6-9e4292ff9539",
              "builtID": "ms-lower-106",
              "externalID": "ocd-person/8a829f4e-388e-41c5-bbc6-9e4292ff9539",
              "geometry": null
            },
            "contactInfo": {
              "email": "jowen@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/owen.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-106-lower-ocd-person-8a829f4e-388e-41c5-bbc6-9e4292ff9539": 0
        }
      },
      "107": {
        "members": [
          {
            "API_ID": "ocd-person/df77d55d-10c7-4ecb-9ed9-b8f989da339e",
            "firstName": "Steve",
            "lastName": "Lott",
            "fullName": "Steve Lott",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.chicago2.vip.townnews.com/stonecountyenterprise.com/content/tncms/assets/v3/editorial/2/fa/2faf492e-910b-11ed-8a24-b33c741f2b91/63bd9eedb8c9d.image.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "107",
              "chamber": "lower",
              "hashID": "SLDL-MS-107-lower-ocd-person-df77d55d-10c7-4ecb-9ed9-b8f989da339e",
              "builtID": "ms-lower-107",
              "externalID": "ocd-person/df77d55d-10c7-4ecb-9ed9-b8f989da339e",
              "geometry": null
            },
            "contactInfo": {
              "email": "lott@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/lott.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-107-lower-ocd-person-df77d55d-10c7-4ecb-9ed9-b8f989da339e": 0
        }
      },
      "108": {
        "members": [
          {
            "API_ID": "ocd-person/fa44d12a-cb19-426b-94d3-6fb68b4095c8",
            "firstName": "Stacey Hobgood",
            "lastName": "Wilkes",
            "fullName": "Stacey Wilkes",
            "gender": "Female",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/hobgood-wilkes.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "108",
              "chamber": "lower",
              "hashID": "SLDL-MS-108-lower-ocd-person-fa44d12a-cb19-426b-94d3-6fb68b4095c8",
              "builtID": "ms-lower-108",
              "externalID": "ocd-person/fa44d12a-cb19-426b-94d3-6fb68b4095c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "swilkes@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/wilkes.xml",
                "https://billstatus.ls.state.ms.us/members/house/hobgood-wilkes.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-108-lower-ocd-person-fa44d12a-cb19-426b-94d3-6fb68b4095c8": 0
        }
      },
      "109": {
        "members": [
          {
            "API_ID": "ocd-person/c3ea3d93-cd2d-48f4-babd-a47bbd951108",
            "firstName": "Manly George",
            "lastName": "Barton",
            "fullName": "Manly Barton",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/barton.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "109",
              "chamber": "lower",
              "hashID": "SLDL-MS-109-lower-ocd-person-c3ea3d93-cd2d-48f4-babd-a47bbd951108",
              "builtID": "ms-lower-109",
              "externalID": "ocd-person/c3ea3d93-cd2d-48f4-babd-a47bbd951108",
              "geometry": null
            },
            "contactInfo": {
              "email": "mbarton@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/barton.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-109-lower-ocd-person-c3ea3d93-cd2d-48f4-babd-a47bbd951108": 0
        }
      },
      "110": {
        "members": [
          {
            "API_ID": "ocd-person/29c08356-5e03-4aed-8518-28da6090a14b",
            "firstName": "Jeramey Dewayne",
            "lastName": "Anderson",
            "fullName": "Jeramey Anderson",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/anderson_(110th).jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "110",
              "chamber": "lower",
              "hashID": "SLDL-MS-110-lower-ocd-person-29c08356-5e03-4aed-8518-28da6090a14b",
              "builtID": "ms-lower-110",
              "externalID": "ocd-person/29c08356-5e03-4aed-8518-28da6090a14b",
              "geometry": null
            },
            "contactInfo": {
              "email": "janderson@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/anderson.xml",
                "https://billstatus.ls.state.ms.us/members/house/anderson_(110th).xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-110-lower-ocd-person-29c08356-5e03-4aed-8518-28da6090a14b": 0
        }
      },
      "111": {
        "members": [
          {
            "API_ID": "ocd-person/af90acc6-0f51-4638-9b7a-51afc5acb0a3",
            "firstName": "James Clayton",
            "lastName": "Fondren",
            "fullName": "Jimmy Fondren",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Jimmy_Fondren.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "111",
              "chamber": "lower",
              "hashID": "SLDL-MS-111-lower-ocd-person-af90acc6-0f51-4638-9b7a-51afc5acb0a3",
              "builtID": "ms-lower-111",
              "externalID": "ocd-person/af90acc6-0f51-4638-9b7a-51afc5acb0a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "fondren@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/fondren.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-111-lower-ocd-person-af90acc6-0f51-4638-9b7a-51afc5acb0a3": 0
        }
      },
      "112": {
        "members": [
          {
            "API_ID": "ocd-person/6c7089b2-5e2d-4049-9aee-5fd76004af16",
            "firstName": "John",
            "lastName": "Read",
            "fullName": "John Read",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/read.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "112",
              "chamber": "lower",
              "hashID": "SLDL-MS-112-lower-ocd-person-6c7089b2-5e2d-4049-9aee-5fd76004af16",
              "builtID": "ms-lower-112",
              "externalID": "ocd-person/6c7089b2-5e2d-4049-9aee-5fd76004af16",
              "geometry": null
            },
            "contactInfo": {
              "email": "jread@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/read.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-112-lower-ocd-person-6c7089b2-5e2d-4049-9aee-5fd76004af16": 0
        }
      },
      "113": {
        "members": [
          {
            "API_ID": "ocd-person/4ead62fd-15e5-4662-8f9b-21a8b0fa8ebe",
            "firstName": "Henry B.",
            "lastName": "Zuber",
            "fullName": "Hank Zuber",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/zuber.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "113",
              "chamber": "lower",
              "hashID": "SLDL-MS-113-lower-ocd-person-4ead62fd-15e5-4662-8f9b-21a8b0fa8ebe",
              "builtID": "ms-lower-113",
              "externalID": "ocd-person/4ead62fd-15e5-4662-8f9b-21a8b0fa8ebe",
              "geometry": null
            },
            "contactInfo": {
              "email": "hzuber@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/zuber.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-113-lower-ocd-person-4ead62fd-15e5-4662-8f9b-21a8b0fa8ebe": 0
        }
      },
      "114": {
        "members": [
          {
            "API_ID": "ocd-person/c5ec6885-a7dc-48f4-a2e4-7aa85397f337",
            "firstName": "Jeffrey S.",
            "lastName": "Guice",
            "fullName": "Jeffrey Guice",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/guice.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "114",
              "chamber": "lower",
              "hashID": "SLDL-MS-114-lower-ocd-person-c5ec6885-a7dc-48f4-a2e4-7aa85397f337",
              "builtID": "ms-lower-114",
              "externalID": "ocd-person/c5ec6885-a7dc-48f4-a2e4-7aa85397f337",
              "geometry": null
            },
            "contactInfo": {
              "email": "jguice@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/guice.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-114-lower-ocd-person-c5ec6885-a7dc-48f4-a2e4-7aa85397f337": 0
        }
      },
      "115": {
        "members": [
          {
            "API_ID": "ocd-person/b62cfb62-68b6-4804-a1ff-f0259e7513b4",
            "firstName": "Zachary",
            "lastName": "Grady",
            "fullName": "Zack Grady",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/ZacharyGrady.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "115",
              "chamber": "lower",
              "hashID": "SLDL-MS-115-lower-ocd-person-b62cfb62-68b6-4804-a1ff-f0259e7513b4",
              "builtID": "ms-lower-115",
              "externalID": "ocd-person/b62cfb62-68b6-4804-a1ff-f0259e7513b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "zgrady@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/grady.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-115-lower-ocd-person-b62cfb62-68b6-4804-a1ff-f0259e7513b4": 0
        }
      },
      "116": {
        "members": [
          {
            "API_ID": "ocd-person/7f1357e0-62b5-412c-b121-6cb5a5aced0e",
            "firstName": "Casey",
            "lastName": "Eure",
            "fullName": "Casey Eure",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/eure.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "116",
              "chamber": "lower",
              "hashID": "SLDL-MS-116-lower-ocd-person-7f1357e0-62b5-412c-b121-6cb5a5aced0e",
              "builtID": "ms-lower-116",
              "externalID": "ocd-person/7f1357e0-62b5-412c-b121-6cb5a5aced0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "ceure@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/eure.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-116-lower-ocd-person-7f1357e0-62b5-412c-b121-6cb5a5aced0e": 0
        }
      },
      "117": {
        "members": [
          {
            "API_ID": "ocd-person/9967ba62-2eb6-452e-8445-d3422c62a813",
            "firstName": "Kevin W.",
            "lastName": "Felsher",
            "fullName": "Kevin Felsher",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/felsher.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "117",
              "chamber": "lower",
              "hashID": "SLDL-MS-117-lower-ocd-person-9967ba62-2eb6-452e-8445-d3422c62a813",
              "builtID": "ms-lower-117",
              "externalID": "ocd-person/9967ba62-2eb6-452e-8445-d3422c62a813",
              "geometry": null
            },
            "contactInfo": {
              "email": "kfelsher@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/felsher.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-117-lower-ocd-person-9967ba62-2eb6-452e-8445-d3422c62a813": 0
        }
      },
      "118": {
        "members": [
          {
            "API_ID": "ocd-person/c38f3e52-9594-4580-a280-bdaa11a33b66",
            "firstName": "Greg",
            "lastName": "Haney",
            "fullName": "Greg Haney",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/haney.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "118",
              "chamber": "lower",
              "hashID": "SLDL-MS-118-lower-ocd-person-c38f3e52-9594-4580-a280-bdaa11a33b66",
              "builtID": "ms-lower-118",
              "externalID": "ocd-person/c38f3e52-9594-4580-a280-bdaa11a33b66",
              "geometry": null
            },
            "contactInfo": {
              "email": "ghaney@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/haney.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-118-lower-ocd-person-c38f3e52-9594-4580-a280-bdaa11a33b66": 0
        }
      },
      "119": {
        "members": [
          {
            "API_ID": "ocd-person/249c4603-1fce-4c28-84be-5fa6fc5420b4",
            "firstName": "Jeffrey",
            "lastName": "Hulum",
            "fullName": "Jeffrey Hulum",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/house/hulum.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "119",
              "chamber": "lower",
              "hashID": "SLDL-MS-119-lower-ocd-person-249c4603-1fce-4c28-84be-5fa6fc5420b4",
              "builtID": "ms-lower-119",
              "externalID": "ocd-person/249c4603-1fce-4c28-84be-5fa6fc5420b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "jhulum@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/hulum.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-119-lower-ocd-person-249c4603-1fce-4c28-84be-5fa6fc5420b4": 0
        }
      },
      "120": {
        "members": [
          {
            "API_ID": "ocd-person/4b4c6bbd-fcbb-4c7e-9746-83acffe80898",
            "firstName": "Richard B.",
            "lastName": "Bennett",
            "fullName": "Richard Bennett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://files.voterly.com/psn/6e52d29ffa0b5418442774f18dcfec57/200x250/richard-bennett.jpeg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "120",
              "chamber": "lower",
              "hashID": "SLDL-MS-120-lower-ocd-person-4b4c6bbd-fcbb-4c7e-9746-83acffe80898",
              "builtID": "ms-lower-120",
              "externalID": "ocd-person/4b4c6bbd-fcbb-4c7e-9746-83acffe80898",
              "geometry": null
            },
            "contactInfo": {
              "email": "rbennett@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/bennett.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-120-lower-ocd-person-4b4c6bbd-fcbb-4c7e-9746-83acffe80898": 0
        }
      },
      "121": {
        "members": [
          {
            "API_ID": "ocd-person/7e340127-bea2-48af-95a8-368b99160880",
            "firstName": "Carolyn",
            "lastName": "Crawford",
            "fullName": "Carolyn Crawford",
            "gender": "Female",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/crawford.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "121",
              "chamber": "lower",
              "hashID": "SLDL-MS-121-lower-ocd-person-7e340127-bea2-48af-95a8-368b99160880",
              "builtID": "ms-lower-121",
              "externalID": "ocd-person/7e340127-bea2-48af-95a8-368b99160880",
              "geometry": null
            },
            "contactInfo": {
              "email": "ccrawford@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/crawford.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-121-lower-ocd-person-7e340127-bea2-48af-95a8-368b99160880": 0
        }
      },
      "122": {
        "members": [
          {
            "API_ID": "ocd-person/307ab2ac-eafb-4d68-8e02-1fff040f50e4",
            "firstName": "Brent",
            "lastName": "Anderson",
            "fullName": "Brent Anderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api/storage/uploads/thumbs/200/300/crop/best/80182230_img_6905.jpg",
            "title": "MS Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "122",
              "chamber": "lower",
              "hashID": "SLDL-MS-122-lower-ocd-person-307ab2ac-eafb-4d68-8e02-1fff040f50e4",
              "builtID": "ms-lower-122",
              "externalID": "ocd-person/307ab2ac-eafb-4d68-8e02-1fff040f50e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "banderson@house.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/anderson_(122nd).xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MS-122-lower-ocd-person-307ab2ac-eafb-4d68-8e02-1fff040f50e4": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/017dec88-1105-454d-b55b-eeb3a3c85b55",
            "firstName": "Michael W.",
            "lastName": "McLendon",
            "fullName": "Michael McLendon",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/mclendon.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-MS-1-upper-ocd-person-017dec88-1105-454d-b55b-eeb3a3c85b55",
              "builtID": "ms-upper-1",
              "externalID": "ocd-person/017dec88-1105-454d-b55b-eeb3a3c85b55",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmclendon@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/mclendon.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-1-upper-ocd-person-017dec88-1105-454d-b55b-eeb3a3c85b55": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/18630d08-6afc-4892-ac2f-e13f0d9932f6",
            "firstName": "David L.",
            "lastName": "Parker",
            "fullName": "David Parker",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/parker.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-MS-2-upper-ocd-person-18630d08-6afc-4892-ac2f-e13f0d9932f6",
              "builtID": "ms-upper-2",
              "externalID": "ocd-person/18630d08-6afc-4892-ac2f-e13f0d9932f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "dparker@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/parker.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-2-upper-ocd-person-18630d08-6afc-4892-ac2f-e13f0d9932f6": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/cc7b9351-ea3c-4436-8cc7-9ec0f34b3e0a",
            "firstName": "Kathy Leath",
            "lastName": "Chism",
            "fullName": "Kathy Chism",
            "gender": "Female",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/chism.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-MS-3-upper-ocd-person-cc7b9351-ea3c-4436-8cc7-9ec0f34b3e0a",
              "builtID": "ms-upper-3",
              "externalID": "ocd-person/cc7b9351-ea3c-4436-8cc7-9ec0f34b3e0a",
              "geometry": null
            },
            "contactInfo": {
              "email": "kchism@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/chism.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-3-upper-ocd-person-cc7b9351-ea3c-4436-8cc7-9ec0f34b3e0a": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/d618a68e-ed3a-47a5-be24-852af75d9ab3",
            "firstName": "Rita Potts",
            "lastName": "Parks",
            "fullName": "Rita Parks",
            "gender": "Female",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/parks.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-MS-4-upper-ocd-person-d618a68e-ed3a-47a5-be24-852af75d9ab3",
              "builtID": "ms-upper-4",
              "externalID": "ocd-person/d618a68e-ed3a-47a5-be24-852af75d9ab3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rparks@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/parks.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-4-upper-ocd-person-d618a68e-ed3a-47a5-be24-852af75d9ab3": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/32153f0b-aa40-4989-9787-e2608c55a87e",
            "firstName": "Daniel H.",
            "lastName": "Sparks",
            "fullName": "Daniel Sparks",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/sparks.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-MS-5-upper-ocd-person-32153f0b-aa40-4989-9787-e2608c55a87e",
              "builtID": "ms-upper-5",
              "externalID": "ocd-person/32153f0b-aa40-4989-9787-e2608c55a87e",
              "geometry": null
            },
            "contactInfo": {
              "email": "dsparks@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/sparks.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-5-upper-ocd-person-32153f0b-aa40-4989-9787-e2608c55a87e": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/17a5d5bb-d52b-4d53-a6a0-bcaa7c5538d7",
            "firstName": "Chad",
            "lastName": "McMahan",
            "fullName": "Chad McMahan",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/mcmahan.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-MS-6-upper-ocd-person-17a5d5bb-d52b-4d53-a6a0-bcaa7c5538d7",
              "builtID": "ms-upper-6",
              "externalID": "ocd-person/17a5d5bb-d52b-4d53-a6a0-bcaa7c5538d7",
              "geometry": null
            },
            "contactInfo": {
              "email": "cmcmahan@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/mcmahan.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-6-upper-ocd-person-17a5d5bb-d52b-4d53-a6a0-bcaa7c5538d7": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/717de4df-c8b0-4387-a767-4d37b577f370",
            "firstName": "Wendell Hob",
            "lastName": "Bryan",
            "fullName": "Hob Bryan",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/senate/bryan.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-MS-7-upper-ocd-person-717de4df-c8b0-4387-a767-4d37b577f370",
              "builtID": "ms-upper-7",
              "externalID": "ocd-person/717de4df-c8b0-4387-a767-4d37b577f370",
              "geometry": null
            },
            "contactInfo": {
              "email": "hbryan@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/bryan.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-7-upper-ocd-person-717de4df-c8b0-4387-a767-4d37b577f370": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/ba13036f-790b-4745-b008-eeb74f1c86a1",
            "firstName": "Benjamin Allen",
            "lastName": "Suber",
            "fullName": "Ben Suber",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/suber.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-MS-8-upper-ocd-person-ba13036f-790b-4745-b008-eeb74f1c86a1",
              "builtID": "ms-upper-8",
              "externalID": "ocd-person/ba13036f-790b-4745-b008-eeb74f1c86a1",
              "geometry": null
            },
            "contactInfo": {
              "email": "bsuber@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/suber.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-8-upper-ocd-person-ba13036f-790b-4745-b008-eeb74f1c86a1": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/befe53fc-f95d-49df-93f4-9985e00151e6",
            "firstName": "Nicole Akins",
            "lastName": "Boyd",
            "fullName": "Nicole Boyd",
            "gender": "Female",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/boyd.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-MS-9-upper-ocd-person-befe53fc-f95d-49df-93f4-9985e00151e6",
              "builtID": "ms-upper-9",
              "externalID": "ocd-person/befe53fc-f95d-49df-93f4-9985e00151e6",
              "geometry": null
            },
            "contactInfo": {
              "email": "nboyd@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/boyd.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-9-upper-ocd-person-befe53fc-f95d-49df-93f4-9985e00151e6": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/60cdec6e-abad-4d5c-97ca-83da67ed7a67",
            "firstName": "Neil S.",
            "lastName": "Whaley",
            "fullName": "Neil Whaley",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/whaley.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-MS-10-upper-ocd-person-60cdec6e-abad-4d5c-97ca-83da67ed7a67",
              "builtID": "ms-upper-10",
              "externalID": "ocd-person/60cdec6e-abad-4d5c-97ca-83da67ed7a67",
              "geometry": null
            },
            "contactInfo": {
              "email": "nwhaley@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/whaley.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-10-upper-ocd-person-60cdec6e-abad-4d5c-97ca-83da67ed7a67": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/0fde8bcf-e43a-4b39-a9a5-b7869424e2d9",
            "firstName": "Reginald D.",
            "lastName": "Jackson",
            "fullName": "Reginald Jackson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://billstatus.ls.state.ms.us/members/senate/jackson.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-MS-11-upper-ocd-person-0fde8bcf-e43a-4b39-a9a5-b7869424e2d9",
              "builtID": "ms-upper-11",
              "externalID": "ocd-person/0fde8bcf-e43a-4b39-a9a5-b7869424e2d9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rjackson@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/jackson_(11th).xml",
                "https://billstatus.ls.state.ms.us/members/senate/jackson_(11th).xml",
                "https://billstatus.ls.state.ms.us/members/senate/jackson.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-11-upper-ocd-person-0fde8bcf-e43a-4b39-a9a5-b7869424e2d9": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/3ea1bab4-64f2-4ff3-b0a9-e04a8caa3e9f",
            "firstName": "Derrick Terrell",
            "lastName": "Simmons",
            "fullName": "Derrick Simmons",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/senate/simmons_(12th).jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-MS-12-upper-ocd-person-3ea1bab4-64f2-4ff3-b0a9-e04a8caa3e9f",
              "builtID": "ms-upper-12",
              "externalID": "ocd-person/3ea1bab4-64f2-4ff3-b0a9-e04a8caa3e9f",
              "geometry": null
            },
            "contactInfo": {
              "email": "dsimmons@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/simmons_(12th).xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-12-upper-ocd-person-3ea1bab4-64f2-4ff3-b0a9-e04a8caa3e9f": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/f1012c43-9559-4f7a-8b70-8dd9cf88bab1",
            "firstName": "Sarita",
            "lastName": "Simmons",
            "fullName": "Sarita Simmons",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/senate/simmons_(13th).jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-MS-13-upper-ocd-person-f1012c43-9559-4f7a-8b70-8dd9cf88bab1",
              "builtID": "ms-upper-13",
              "externalID": "ocd-person/f1012c43-9559-4f7a-8b70-8dd9cf88bab1",
              "geometry": null
            },
            "contactInfo": {
              "email": "ssimmons@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/simmons_(13th).xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-13-upper-ocd-person-f1012c43-9559-4f7a-8b70-8dd9cf88bab1": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/094c0731-4932-4400-ad96-84c83ba1dd59",
            "firstName": "Lydia Graves",
            "lastName": "Chassaniol",
            "fullName": "Lydia Chassaniol",
            "gender": "Female",
            "party": "Republican",
            "image": "https://billstatus.ls.state.ms.us/members/senate/chassaniol.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-MS-14-upper-ocd-person-094c0731-4932-4400-ad96-84c83ba1dd59",
              "builtID": "ms-upper-14",
              "externalID": "ocd-person/094c0731-4932-4400-ad96-84c83ba1dd59",
              "geometry": null
            },
            "contactInfo": {
              "email": "lchassaniol@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/chassaniol.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-14-upper-ocd-person-094c0731-4932-4400-ad96-84c83ba1dd59": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/5f65f3a3-db2a-4573-a5c8-59b6cc0dbf40",
            "firstName": "Thomas Barton",
            "lastName": "Williams",
            "fullName": "Bart Williams",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/williams.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-MS-15-upper-ocd-person-5f65f3a3-db2a-4573-a5c8-59b6cc0dbf40",
              "builtID": "ms-upper-15",
              "externalID": "ocd-person/5f65f3a3-db2a-4573-a5c8-59b6cc0dbf40",
              "geometry": null
            },
            "contactInfo": {
              "email": "bwilliams@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": "508 New Hope Church Road, Starkville, MS 39759",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/williams.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-15-upper-ocd-person-5f65f3a3-db2a-4573-a5c8-59b6cc0dbf40": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/74d0c9b2-58a2-41a7-b2a0-582924a45adf",
            "firstName": "Angela",
            "lastName": "Turner-Ford",
            "fullName": "Angela Turner-Ford",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/senate/turner-ford.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-MS-16-upper-ocd-person-74d0c9b2-58a2-41a7-b2a0-582924a45adf",
              "builtID": "ms-upper-16",
              "externalID": "ocd-person/74d0c9b2-58a2-41a7-b2a0-582924a45adf",
              "geometry": null
            },
            "contactInfo": {
              "email": "aturner@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/turner-ford.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-16-upper-ocd-person-74d0c9b2-58a2-41a7-b2a0-582924a45adf": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/c22d208a-59c7-4e90-bbe0-37b953a00b41",
            "firstName": "Charles",
            "lastName": "Younger",
            "fullName": "Chuck Younger",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/younger.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-MS-17-upper-ocd-person-c22d208a-59c7-4e90-bbe0-37b953a00b41",
              "builtID": "ms-upper-17",
              "externalID": "ocd-person/c22d208a-59c7-4e90-bbe0-37b953a00b41",
              "geometry": null
            },
            "contactInfo": {
              "email": "cyounger@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/younger.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-17-upper-ocd-person-c22d208a-59c7-4e90-bbe0-37b953a00b41": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/629badc7-5b63-4aae-b19f-d754dbe12e49",
            "firstName": "Jenifer Burrage",
            "lastName": "Branning",
            "fullName": "Jenifer Branning",
            "gender": "Female",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/branning.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-MS-18-upper-ocd-person-629badc7-5b63-4aae-b19f-d754dbe12e49",
              "builtID": "ms-upper-18",
              "externalID": "ocd-person/629badc7-5b63-4aae-b19f-d754dbe12e49",
              "geometry": null
            },
            "contactInfo": {
              "email": "branning@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/branning.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-18-upper-ocd-person-629badc7-5b63-4aae-b19f-d754dbe12e49": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/b58c7115-6f1d-4de3-9327-eef3b6f0c83c",
            "firstName": "Kevin Edward",
            "lastName": "Blackwell",
            "fullName": "Kevin Blackwell",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/blackwell.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-MS-19-upper-ocd-person-b58c7115-6f1d-4de3-9327-eef3b6f0c83c",
              "builtID": "ms-upper-19",
              "externalID": "ocd-person/b58c7115-6f1d-4de3-9327-eef3b6f0c83c",
              "geometry": null
            },
            "contactInfo": {
              "email": "kblackwell@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/blackwell.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-19-upper-ocd-person-b58c7115-6f1d-4de3-9327-eef3b6f0c83c": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/13163ae5-a55d-4eff-8b23-b9f7df9e354a",
            "firstName": "Josh",
            "lastName": "Harkins",
            "fullName": "Josh Harkins",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/harkins.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-MS-20-upper-ocd-person-13163ae5-a55d-4eff-8b23-b9f7df9e354a",
              "builtID": "ms-upper-20",
              "externalID": "ocd-person/13163ae5-a55d-4eff-8b23-b9f7df9e354a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jharkins@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/harkins.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-20-upper-ocd-person-13163ae5-a55d-4eff-8b23-b9f7df9e354a": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/3b2b3cb2-3ef8-400d-b395-6b0895ea7631",
            "firstName": "Bradford Jerome",
            "lastName": "Blackmon",
            "fullName": "Bradford Blackmon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://billstatus.ls.state.ms.us/members/senate/blackmon.jpeg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-MS-21-upper-ocd-person-3b2b3cb2-3ef8-400d-b395-6b0895ea7631",
              "builtID": "ms-upper-21",
              "externalID": "ocd-person/3b2b3cb2-3ef8-400d-b395-6b0895ea7631",
              "geometry": null
            },
            "contactInfo": {
              "email": "bblackmon@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/blackmon.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-21-upper-ocd-person-3b2b3cb2-3ef8-400d-b395-6b0895ea7631": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/13131d55-19a4-49cd-aba9-e8eef7aedb06",
            "firstName": "Joseph C.",
            "lastName": "Thomas",
            "fullName": "Joseph Thomas",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/senate/thomas.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-MS-22-upper-ocd-person-13131d55-19a4-49cd-aba9-e8eef7aedb06",
              "builtID": "ms-upper-22",
              "externalID": "ocd-person/13131d55-19a4-49cd-aba9-e8eef7aedb06",
              "geometry": null
            },
            "contactInfo": {
              "email": "jthomas@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/thomas.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-22-upper-ocd-person-13131d55-19a4-49cd-aba9-e8eef7aedb06": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/f275881b-1096-4a6a-a607-b417eae41f2e",
            "firstName": "W. Briggs",
            "lastName": "Hopson",
            "fullName": "Briggs Hopson",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/hopson.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-MS-23-upper-ocd-person-f275881b-1096-4a6a-a607-b417eae41f2e",
              "builtID": "ms-upper-23",
              "externalID": "ocd-person/f275881b-1096-4a6a-a607-b417eae41f2e",
              "geometry": null
            },
            "contactInfo": {
              "email": "bhopson@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/hopson.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-23-upper-ocd-person-f275881b-1096-4a6a-a607-b417eae41f2e": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/f2f61e1d-8d34-40c7-95ff-fb08cd2a4f75",
            "firstName": "David Lee",
            "lastName": "Jordan",
            "fullName": "David Jordan",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/senate/jordan.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-MS-24-upper-ocd-person-f2f61e1d-8d34-40c7-95ff-fb08cd2a4f75",
              "builtID": "ms-upper-24",
              "externalID": "ocd-person/f2f61e1d-8d34-40c7-95ff-fb08cd2a4f75",
              "geometry": null
            },
            "contactInfo": {
              "email": "djordan@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/jordan.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-24-upper-ocd-person-f2f61e1d-8d34-40c7-95ff-fb08cd2a4f75": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/61c65e25-07c9-445c-8cef-1e6d53cbbfb3",
            "firstName": "J. Walter",
            "lastName": "Michel",
            "fullName": "Walter Michel",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/michel.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-MS-25-upper-ocd-person-61c65e25-07c9-445c-8cef-1e6d53cbbfb3",
              "builtID": "ms-upper-25",
              "externalID": "ocd-person/61c65e25-07c9-445c-8cef-1e6d53cbbfb3",
              "geometry": null
            },
            "contactInfo": {
              "email": "wmichel@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/michel.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-25-upper-ocd-person-61c65e25-07c9-445c-8cef-1e6d53cbbfb3": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/b90d629d-9f8b-421a-88e3-9f72580cdd37",
            "firstName": "John A.",
            "lastName": "Horhn",
            "fullName": "John Horhn",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/senate/horhn.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-MS-26-upper-ocd-person-b90d629d-9f8b-421a-88e3-9f72580cdd37",
              "builtID": "ms-upper-26",
              "externalID": "ocd-person/b90d629d-9f8b-421a-88e3-9f72580cdd37",
              "geometry": null
            },
            "contactInfo": {
              "email": "jhorhn@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/horhn.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-26-upper-ocd-person-b90d629d-9f8b-421a-88e3-9f72580cdd37": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/92659a52-eda2-41a1-9300-40ca7fc3a46b",
            "firstName": "Hillman Terome",
            "lastName": "Frazier",
            "fullName": "Hillman Frazier",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/senate/frazier.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-MS-27-upper-ocd-person-92659a52-eda2-41a1-9300-40ca7fc3a46b",
              "builtID": "ms-upper-27",
              "externalID": "ocd-person/92659a52-eda2-41a1-9300-40ca7fc3a46b",
              "geometry": null
            },
            "contactInfo": {
              "email": "hfrazier@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/frazier.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-27-upper-ocd-person-92659a52-eda2-41a1-9300-40ca7fc3a46b": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/4b5fb3d4-b284-4886-bb0b-4c00febb1724",
            "firstName": "Sollie B.",
            "lastName": "Norwood",
            "fullName": "Sollie Norwood",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/senate/norwood.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-MS-28-upper-ocd-person-4b5fb3d4-b284-4886-bb0b-4c00febb1724",
              "builtID": "ms-upper-28",
              "externalID": "ocd-person/4b5fb3d4-b284-4886-bb0b-4c00febb1724",
              "geometry": null
            },
            "contactInfo": {
              "email": "snorwood@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/norwood.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-28-upper-ocd-person-4b5fb3d4-b284-4886-bb0b-4c00febb1724": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/c8e4b4c1-6c8f-4b95-9b48-c73997b3f518",
            "firstName": "David",
            "lastName": "Blount",
            "fullName": "David Blount",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/senate/blount.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-MS-29-upper-ocd-person-c8e4b4c1-6c8f-4b95-9b48-c73997b3f518",
              "builtID": "ms-upper-29",
              "externalID": "ocd-person/c8e4b4c1-6c8f-4b95-9b48-c73997b3f518",
              "geometry": null
            },
            "contactInfo": {
              "email": "dblount@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/blount.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-29-upper-ocd-person-c8e4b4c1-6c8f-4b95-9b48-c73997b3f518": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/d1572354-a456-47fa-aa21-58be9884a3bb",
            "firstName": "Dean",
            "lastName": "Kirby",
            "fullName": "Dean Kirby",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/kirby.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-MS-30-upper-ocd-person-d1572354-a456-47fa-aa21-58be9884a3bb",
              "builtID": "ms-upper-30",
              "externalID": "ocd-person/d1572354-a456-47fa-aa21-58be9884a3bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "dkirby@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/Kirby.xml",
                "https://billstatus.ls.state.ms.us/members/senate/kirby.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-30-upper-ocd-person-d1572354-a456-47fa-aa21-58be9884a3bb": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/bd335f00-4e41-4972-b8f4-f2249b410614",
            "firstName": "Tyler",
            "lastName": "McCaughn",
            "fullName": "Tyler McCaughn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://billstatus.ls.state.ms.us/members/senate/mccaughn.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-MS-31-upper-ocd-person-bd335f00-4e41-4972-b8f4-f2249b410614",
              "builtID": "ms-upper-31",
              "externalID": "ocd-person/bd335f00-4e41-4972-b8f4-f2249b410614",
              "geometry": null
            },
            "contactInfo": {
              "email": "tmccaughn@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/mccaughn.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-31-upper-ocd-person-bd335f00-4e41-4972-b8f4-f2249b410614": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/eb5bde8b-1497-4a27-9806-128cf68cb00d",
            "firstName": "Rodgrick Glenard",
            "lastName": "Hickman",
            "fullName": "Rod Hickman",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://billstatus.ls.state.ms.us/members/senate/hickman.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-MS-32-upper-ocd-person-eb5bde8b-1497-4a27-9806-128cf68cb00d",
              "builtID": "ms-upper-32",
              "externalID": "ocd-person/eb5bde8b-1497-4a27-9806-128cf68cb00d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rhickman@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/hickman.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-32-upper-ocd-person-eb5bde8b-1497-4a27-9806-128cf68cb00d": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/9839c663-7ae3-4437-82d8-c985975309d8",
            "firstName": "Jeff",
            "lastName": "Tate",
            "fullName": "Jeff Tate",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/tate.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-MS-33-upper-ocd-person-9839c663-7ae3-4437-82d8-c985975309d8",
              "builtID": "ms-upper-33",
              "externalID": "ocd-person/9839c663-7ae3-4437-82d8-c985975309d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "jtate@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/tate.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-33-upper-ocd-person-9839c663-7ae3-4437-82d8-c985975309d8": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/fcb35ef2-7463-4f8a-9a8a-3bc45b235ecb",
            "firstName": "Juan",
            "lastName": "Barnett",
            "fullName": "Juan Barnett",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://static.votesmart.org/static/canphoto/156222.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-MS-34-upper-ocd-person-fcb35ef2-7463-4f8a-9a8a-3bc45b235ecb",
              "builtID": "ms-upper-34",
              "externalID": "ocd-person/fcb35ef2-7463-4f8a-9a8a-3bc45b235ecb",
              "geometry": null
            },
            "contactInfo": {
              "email": "jbarnett@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/barnett.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-34-upper-ocd-person-fcb35ef2-7463-4f8a-9a8a-3bc45b235ecb": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/80fca1ba-017c-4941-990c-a5e2ff34aea0",
            "firstName": "Andy",
            "lastName": "Berry",
            "fullName": "Andy Berry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://billstatus.ls.state.ms.us/members/senate/Berry.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-MS-35-upper-ocd-person-80fca1ba-017c-4941-990c-a5e2ff34aea0",
              "builtID": "ms-upper-35",
              "externalID": "ocd-person/80fca1ba-017c-4941-990c-a5e2ff34aea0",
              "geometry": null
            },
            "contactInfo": {
              "email": "aberry@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/berry.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-35-upper-ocd-person-80fca1ba-017c-4941-990c-a5e2ff34aea0": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/77852654-cea4-4555-899c-641494605220",
            "firstName": "Brian",
            "lastName": "Rhodes",
            "fullName": "Brian Rhodes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://billstatus.ls.state.ms.us/members/senate/rhodes.jpeg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-MS-36-upper-ocd-person-77852654-cea4-4555-899c-641494605220",
              "builtID": "ms-upper-36",
              "externalID": "ocd-person/77852654-cea4-4555-899c-641494605220",
              "geometry": null
            },
            "contactInfo": {
              "email": "brhodes@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/rhodes.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-36-upper-ocd-person-77852654-cea4-4555-899c-641494605220": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/c012fed5-c753-4a01-9c3c-9924d178c24d",
            "firstName": "Albert",
            "lastName": "Butler",
            "fullName": "Albert Butler",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://billstatus.ls.state.ms.us/members/senate/butler.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-MS-37-upper-ocd-person-c012fed5-c753-4a01-9c3c-9924d178c24d",
              "builtID": "ms-upper-37",
              "externalID": "ocd-person/c012fed5-c753-4a01-9c3c-9924d178c24d",
              "geometry": null
            },
            "contactInfo": {
              "email": "abutler@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/butler_(36th).xml",
                "https://billstatus.ls.state.ms.us/members/senate/butler.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-37-upper-ocd-person-c012fed5-c753-4a01-9c3c-9924d178c24d": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/dcae79b5-ddc5-48e8-86c2-54d5c47f0656",
            "firstName": "Gary L.",
            "lastName": "Brumfield",
            "fullName": "Gary Brumfield",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://billstatus.ls.state.ms.us/members/senate/brumfield.png",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-MS-38-upper-ocd-person-dcae79b5-ddc5-48e8-86c2-54d5c47f0656",
              "builtID": "ms-upper-38",
              "externalID": "ocd-person/dcae79b5-ddc5-48e8-86c2-54d5c47f0656",
              "geometry": null
            },
            "contactInfo": {
              "email": "gbrumfield@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/brumfield.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-38-upper-ocd-person-dcae79b5-ddc5-48e8-86c2-54d5c47f0656": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/c664f98f-0d57-4d32-abf1-a95dcec7fa31",
            "firstName": "Jason Todd",
            "lastName": "Barrett",
            "fullName": "Jason Barrett",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/barrett.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-MS-39-upper-ocd-person-c664f98f-0d57-4d32-abf1-a95dcec7fa31",
              "builtID": "ms-upper-39",
              "externalID": "ocd-person/c664f98f-0d57-4d32-abf1-a95dcec7fa31",
              "geometry": null
            },
            "contactInfo": {
              "email": "jbarrett@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/barrett.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-39-upper-ocd-person-c664f98f-0d57-4d32-abf1-a95dcec7fa31": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/4004cf9a-6d23-4323-b158-00f22078d2b4",
            "firstName": "Angela Burks",
            "lastName": "Hill",
            "fullName": "Angela Hill",
            "gender": "Female",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/hill.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-MS-40-upper-ocd-person-4004cf9a-6d23-4323-b158-00f22078d2b4",
              "builtID": "ms-upper-40",
              "externalID": "ocd-person/4004cf9a-6d23-4323-b158-00f22078d2b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "ahill@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/hill.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-40-upper-ocd-person-4004cf9a-6d23-4323-b158-00f22078d2b4": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/bc0bd61c-da9a-40db-bf66-a0108b01275e",
            "firstName": "Joseph Edgar",
            "lastName": "Fillingane",
            "fullName": "Joey Fillingane",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/fillingane.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-MS-41-upper-ocd-person-bc0bd61c-da9a-40db-bf66-a0108b01275e",
              "builtID": "ms-upper-41",
              "externalID": "ocd-person/bc0bd61c-da9a-40db-bf66-a0108b01275e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jfillingane@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/fillingane.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-41-upper-ocd-person-bc0bd61c-da9a-40db-bf66-a0108b01275e": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/33bd801c-d704-46a8-b60f-5929bc412479",
            "firstName": "Robin J.",
            "lastName": "Robinson",
            "fullName": "Robin Robinson",
            "gender": "Female",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/house/robinson.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-MS-42-upper-ocd-person-33bd801c-d704-46a8-b60f-5929bc412479",
              "builtID": "ms-upper-42",
              "externalID": "ocd-person/33bd801c-d704-46a8-b60f-5929bc412479",
              "geometry": null
            },
            "contactInfo": {
              "email": "rrobinson@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/robinson.xml",
                "https://billstatus.ls.state.ms.us/members/house/robinson.xml",
                "https://billstatus.ls.state.ms.us/members/senate/robinson.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-42-upper-ocd-person-33bd801c-d704-46a8-b60f-5929bc412479": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/8c139a04-43ed-48c5-98cc-e2d308efa65b",
            "firstName": "Dennis",
            "lastName": "DeBar",
            "fullName": "Dennis DeBar",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/debar.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "43",
              "chamber": "upper",
              "hashID": "SLDU-MS-43-upper-ocd-person-8c139a04-43ed-48c5-98cc-e2d308efa65b",
              "builtID": "ms-upper-43",
              "externalID": "ocd-person/8c139a04-43ed-48c5-98cc-e2d308efa65b",
              "geometry": null
            },
            "contactInfo": {
              "email": "ddebar@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/debar.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-43-upper-ocd-person-8c139a04-43ed-48c5-98cc-e2d308efa65b": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/3594f477-2f29-4875-8352-eb8d01775a88",
            "firstName": "John Allen",
            "lastName": "Polk",
            "fullName": "John Polk",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/polk.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "44",
              "chamber": "upper",
              "hashID": "SLDU-MS-44-upper-ocd-person-3594f477-2f29-4875-8352-eb8d01775a88",
              "builtID": "ms-upper-44",
              "externalID": "ocd-person/3594f477-2f29-4875-8352-eb8d01775a88",
              "geometry": null
            },
            "contactInfo": {
              "email": "jpolk@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/polk.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-44-upper-ocd-person-3594f477-2f29-4875-8352-eb8d01775a88": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/20510bb3-edcd-4cb8-a41a-e0114e6215c9",
            "firstName": "Chris",
            "lastName": "Johnson",
            "fullName": "Chris Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/johnson.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "45",
              "chamber": "upper",
              "hashID": "SLDU-MS-45-upper-ocd-person-20510bb3-edcd-4cb8-a41a-e0114e6215c9",
              "builtID": "ms-upper-45",
              "externalID": "ocd-person/20510bb3-edcd-4cb8-a41a-e0114e6215c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "chjohnson@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/johnson_(87th).xml",
                "https://billstatus.ls.state.ms.us/members/senate/johnson.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-45-upper-ocd-person-20510bb3-edcd-4cb8-a41a-e0114e6215c9": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/7aef9236-de61-47cf-8243-b5a21fcac9dc",
            "firstName": "Philman A.",
            "lastName": "Ladner",
            "fullName": "Philman Ladner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://billstatus.ls.state.ms.us/members/senate/Ladner.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "46",
              "chamber": "upper",
              "hashID": "SLDU-MS-46-upper-ocd-person-7aef9236-de61-47cf-8243-b5a21fcac9dc",
              "builtID": "ms-upper-46",
              "externalID": "ocd-person/7aef9236-de61-47cf-8243-b5a21fcac9dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "pladner@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/ladner.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-46-upper-ocd-person-7aef9236-de61-47cf-8243-b5a21fcac9dc": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/70b45762-64ee-4a16-b15a-194e4e3c9bf6",
            "firstName": "Joseph Michael",
            "lastName": "Seymour",
            "fullName": "Mike Seymour",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/seymour.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "47",
              "chamber": "upper",
              "hashID": "SLDU-MS-47-upper-ocd-person-70b45762-64ee-4a16-b15a-194e4e3c9bf6",
              "builtID": "ms-upper-47",
              "externalID": "ocd-person/70b45762-64ee-4a16-b15a-194e4e3c9bf6",
              "geometry": null
            },
            "contactInfo": {
              "email": "jseymour@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/seymour.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-47-upper-ocd-person-70b45762-64ee-4a16-b15a-194e4e3c9bf6": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/eaa39996-c8a7-4568-b01e-d7685667227b",
            "firstName": "Mike",
            "lastName": "Thompson",
            "fullName": "Mike Thompson",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/thompson.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "48",
              "chamber": "upper",
              "hashID": "SLDU-MS-48-upper-ocd-person-eaa39996-c8a7-4568-b01e-d7685667227b",
              "builtID": "ms-upper-48",
              "externalID": "ocd-person/eaa39996-c8a7-4568-b01e-d7685667227b",
              "geometry": null
            },
            "contactInfo": {
              "email": "mthompson@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/thompson.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-48-upper-ocd-person-eaa39996-c8a7-4568-b01e-d7685667227b": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/cc55837a-0a3e-4bcf-8255-b8786fc2b43f",
            "firstName": "Joel R.",
            "lastName": "Carter",
            "fullName": "Joel Carter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://billstatus.ls.state.ms.us/members/senate/carter.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "49",
              "chamber": "upper",
              "hashID": "SLDU-MS-49-upper-ocd-person-cc55837a-0a3e-4bcf-8255-b8786fc2b43f",
              "builtID": "ms-upper-49",
              "externalID": "ocd-person/cc55837a-0a3e-4bcf-8255-b8786fc2b43f",
              "geometry": null
            },
            "contactInfo": {
              "email": "jcarter@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/carter.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-49-upper-ocd-person-cc55837a-0a3e-4bcf-8255-b8786fc2b43f": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/8467b994-f651-446b-b77f-0632ff790ff8",
            "firstName": "Scott",
            "lastName": "DeLano",
            "fullName": "Scott DeLano",
            "gender": "Male",
            "party": "Republican",
            "image": "https://billstatus.ls.state.ms.us/members/senate/delano.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "50",
              "chamber": "upper",
              "hashID": "SLDU-MS-50-upper-ocd-person-8467b994-f651-446b-b77f-0632ff790ff8",
              "builtID": "ms-upper-50",
              "externalID": "ocd-person/8467b994-f651-446b-b77f-0632ff790ff8",
              "geometry": null
            },
            "contactInfo": {
              "email": "sdelano@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/house/delano.xml",
                "https://billstatus.ls.state.ms.us/members/senate/delano.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-50-upper-ocd-person-8467b994-f651-446b-b77f-0632ff790ff8": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/33ffa85f-af2b-41bc-89ce-3c59341d17ab",
            "firstName": "Jeremy Thomas",
            "lastName": "England",
            "fullName": "Jeremy England",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/england.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "51",
              "chamber": "upper",
              "hashID": "SLDU-MS-51-upper-ocd-person-33ffa85f-af2b-41bc-89ce-3c59341d17ab",
              "builtID": "ms-upper-51",
              "externalID": "ocd-person/33ffa85f-af2b-41bc-89ce-3c59341d17ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "jengland@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/england.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-51-upper-ocd-person-33ffa85f-af2b-41bc-89ce-3c59341d17ab": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/7e27e9e0-fe62-4fc0-b8b0-c2ac9c9ce28d",
            "firstName": "Christopher Brice",
            "lastName": "Wiggins",
            "fullName": "Brice Wiggins",
            "gender": "Male",
            "party": "Republican",
            "image": "http://billstatus.ls.state.ms.us/members/senate/wiggins.jpg",
            "title": "MS Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MS",
              "stateFull": "Mississippi",
              "district": "52",
              "chamber": "upper",
              "hashID": "SLDU-MS-52-upper-ocd-person-7e27e9e0-fe62-4fc0-b8b0-c2ac9c9ce28d",
              "builtID": "ms-upper-52",
              "externalID": "ocd-person/7e27e9e0-fe62-4fc0-b8b0-c2ac9c9ce28d",
              "geometry": null
            },
            "contactInfo": {
              "email": "bwiggins@senate.ms.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://billstatus.ls.state.ms.us/members/senate/wiggins.xml"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MS-52-upper-ocd-person-7e27e9e0-fe62-4fc0-b8b0-c2ac9c9ce28d": 0
        }
      }
    }
  },
  "MO": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "MO",
          "stateFull": "Missouri",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "MO",
            "stateFull": "Missouri",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-MO---",
            "builtID": "mo--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-MO---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "H001089",
          "in_office": true,
          "firstName": "Joshua",
          "lastName": "Hawley",
          "middleName": null,
          "fullName": "Josh Hawley",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/H001089.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "MO",
            "stateFull": "Missouri",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-MO---H001089",
            "builtID": "mo--",
            "externalID": "H001089",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.hawley.senate.gov/contact-senator-hawley",
            "address1": "115 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-6154",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenHawleyPress",
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.hawley.senate.gov"],
            "rss_url": null
          },
          "title": "MO Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/H001089.json",
          "govtrack_id": "412840",
          "cspan_id": null,
          "votesmart_id": "169716",
          "icpsr_id": null,
          "crp_id": "N00041620",
          "google_entity_id": "/g/11bwy_95zy",
          "state_rank": "senior",
          "lis_id": "S399",
          "suffix": null,
          "date_of_birth": "1979-12-31",
          "leadership_role": null,
          "fec_candidate_id": "S8MO00160",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 41,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:27 -0400",
          "missed_votes_pct": 7.4,
          "votes_with_party_pct": 82.74,
          "votes_against_party_pct": 17.26,
          "ocd_id": "ocd-division/country:us/state:mo"
        },
        {
          "API_ID": "S001227",
          "in_office": true,
          "firstName": "Eric",
          "lastName": "Schmitt",
          "middleName": null,
          "fullName": "Eric Schmitt",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/S001227.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "MO",
            "stateFull": "Missouri",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-MO---S001227",
            "builtID": "mo--",
            "externalID": "S001227",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": null,
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.schmitt.senate.gov/"],
            "rss_url": null
          },
          "title": "MO Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/S001227.json",
          "govtrack_id": "456875",
          "cspan_id": null,
          "votesmart_id": null,
          "icpsr_id": null,
          "crp_id": null,
          "google_entity_id": null,
          "state_rank": "junior",
          "lis_id": "S420",
          "suffix": null,
          "date_of_birth": "1975-06-20",
          "leadership_role": null,
          "fec_candidate_id": "S2MO00544",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 28,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:28 -0400",
          "missed_votes_pct": 5.05,
          "votes_with_party_pct": 85.88,
          "votes_against_party_pct": 14.12,
          "ocd_id": "ocd-division/country:us/state:mo"
        }
      ],
      "hash": { "SENATE-MO---H001089": 0, "SENATE-MO---S001227": 1 }
    },
    "HOUSE": {
      "04": {
        "members": [
          {
            "API_ID": "A000379",
            "in_office": true,
            "firstName": "Mark",
            "lastName": "Alford",
            "middleName": null,
            "fullName": "Mark Alford",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/A000379.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-MO-04--A000379",
              "builtID": "mo--04",
              "externalID": "A000379",
              "geometry": null,
              "geoid": "2904"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1516 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2876",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://alford.house.gov/"],
              "rss_url": null
            },
            "title": "MO House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/A000379.json",
            "govtrack_id": "456909",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1963-10-04",
            "leadership_role": null,
            "fec_candidate_id": "H2MO04207",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.57,
            "votes_with_party_pct": 93.5,
            "votes_against_party_pct": 4.56,
            "ocd_id": "ocd-division/country:us/state:mo/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MO-04--A000379": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "B001316",
            "in_office": true,
            "firstName": "Eric",
            "lastName": "Burlison",
            "middleName": null,
            "fullName": "Eric Burlison",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001316.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-MO-07--B001316",
              "builtID": "mo--07",
              "externalID": "B001316",
              "geometry": null,
              "geoid": "2907"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1108 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-6536",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepEricBurlison",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://burlison.house.gov/"],
              "rss_url": null
            },
            "title": "MO House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001316.json",
            "govtrack_id": "456910",
            "cspan_id": null,
            "votesmart_id": "104635",
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-10-02",
            "leadership_role": null,
            "fec_candidate_id": "H2MO07143",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 28,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 2.65,
            "votes_with_party_pct": 84.33,
            "votes_against_party_pct": 13.69,
            "ocd_id": "ocd-division/country:us/state:mo/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MO-07--B001316": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "B001224",
            "in_office": true,
            "firstName": "Cori",
            "lastName": "Bush",
            "middleName": null,
            "fullName": "Cori Bush",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/B001224.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-MO-01--B001224",
              "builtID": "mo--01",
              "externalID": "B001224",
              "geometry": null,
              "geoid": "2901"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2463 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2406",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepCori",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://bush.house.gov"],
              "rss_url": null
            },
            "title": "MO House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001224.json",
            "govtrack_id": "456829",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00039373",
            "google_entity_id": "/g/11cntkcbkt",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-07-21",
            "leadership_role": null,
            "fec_candidate_id": "H8MO01143",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 188,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 17.8,
            "votes_with_party_pct": 88.11,
            "votes_against_party_pct": 9.67,
            "ocd_id": "ocd-division/country:us/state:mo/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MO-01--B001224": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "C001061",
            "in_office": true,
            "firstName": "Emanuel",
            "lastName": "Cleaver",
            "middleName": null,
            "fullName": "Emanuel Cleaver",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001061.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-MO-05--C001061",
              "builtID": "mo--05",
              "externalID": "C001061",
              "geometry": null,
              "geoid": "2905"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2217 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4535",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepCleaver",
              "youtube": "repcleaver",
              "instagram": null,
              "facebook": "emanuelcleaverii",
              "urls": ["https://cleaver.house.gov"],
              "rss_url": "https://cleaver.house.gov/rss.xml"
            },
            "title": "MO House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001061.json",
            "govtrack_id": "400639",
            "cspan_id": "10933",
            "votesmart_id": "39507",
            "icpsr_id": "20517",
            "crp_id": "N00026790",
            "google_entity_id": "/m/04cbbm",
            "state_rank": null,
            "lis_id": null,
            "suffix": "II",
            "date_of_birth": "1944-10-26",
            "leadership_role": null,
            "fec_candidate_id": "H4MO05234",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "20",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 179,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 16.97,
            "votes_with_party_pct": 95.85,
            "votes_against_party_pct": 1.96,
            "ocd_id": "ocd-division/country:us/state:mo/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MO-05--C001061": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "G000546",
            "in_office": true,
            "firstName": "Sam",
            "lastName": "Graves",
            "middleName": null,
            "fullName": "Sam Graves",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000546.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-MO-06--G000546",
              "builtID": "mo--06",
              "externalID": "G000546",
              "geometry": null,
              "geoid": "2906"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1135 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-7041",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepSamGraves",
              "youtube": null,
              "instagram": null,
              "facebook": "118514606128",
              "urls": ["https://graves.house.gov"],
              "rss_url": "https://graves.house.gov/rss.xml"
            },
            "title": "MO House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000546.json",
            "govtrack_id": "400158",
            "cspan_id": "89873",
            "votesmart_id": "9425",
            "icpsr_id": "20124",
            "crp_id": "N00013323",
            "google_entity_id": "/m/03tnfy",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1963-11-07",
            "leadership_role": null,
            "fec_candidate_id": "H0MO06073",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "24",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 14,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.33,
            "votes_with_party_pct": 93.24,
            "votes_against_party_pct": 4.9,
            "ocd_id": "ocd-division/country:us/state:mo/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MO-06--G000546": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "L000569",
            "in_office": true,
            "firstName": "Blaine",
            "lastName": "Luetkemeyer",
            "middleName": null,
            "fullName": "Blaine Luetkemeyer",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/L000569.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-MO-03--L000569",
              "builtID": "mo--03",
              "externalID": "L000569",
              "geometry": null,
              "geoid": "2903"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2230 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2956",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBlaine",
              "youtube": "BLuetkemeyer",
              "instagram": null,
              "facebook": "BlaineLuetkemeyer",
              "urls": ["https://luetkemeyer.house.gov"],
              "rss_url": "https://luetkemeyer.house.gov/news/rss.aspx"
            },
            "title": "MO House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000569.json",
            "govtrack_id": "412292",
            "cspan_id": "1031348",
            "votesmart_id": "20400",
            "icpsr_id": "20926",
            "crp_id": "N00030026",
            "google_entity_id": "/m/04ycpq1",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1952-05-07",
            "leadership_role": null,
            "fec_candidate_id": "H8MO09153",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "16",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 66,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 6.26,
            "votes_with_party_pct": 94.54,
            "votes_against_party_pct": 3.5,
            "ocd_id": "ocd-division/country:us/state:mo/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MO-03--L000569": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "S001195",
            "in_office": true,
            "firstName": "Jason",
            "lastName": "Smith",
            "middleName": null,
            "fullName": "Jason Smith",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S001195.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-MO-08--S001195",
              "builtID": "mo--08",
              "externalID": "S001195",
              "geometry": null,
              "geoid": "2908"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1011 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4404",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJasonSmith",
              "youtube": "RepJasonSmith",
              "instagram": null,
              "facebook": "repjasonsmith",
              "urls": ["https://jasonsmith.house.gov"],
              "rss_url": null
            },
            "title": "MO House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001195.json",
            "govtrack_id": "412596",
            "cspan_id": "71083",
            "votesmart_id": "59318",
            "icpsr_id": "21373",
            "crp_id": "N00035282",
            "google_entity_id": "/m/0g9yj_2",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1980-06-16",
            "leadership_role": null,
            "fec_candidate_id": "H4MO08162",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 34,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 3.22,
            "votes_with_party_pct": 93.61,
            "votes_against_party_pct": 4.4,
            "ocd_id": "ocd-division/country:us/state:mo/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MO-08--S001195": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "W000812",
            "in_office": true,
            "firstName": "Ann",
            "lastName": "Wagner",
            "middleName": null,
            "fullName": "Ann Wagner",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/W000812.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-MO-02--W000812",
              "builtID": "mo--02",
              "externalID": "W000812",
              "geometry": null,
              "geoid": "2902"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2350 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-1621",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAnnWagner",
              "youtube": null,
              "instagram": null,
              "facebook": "RepAnnWagner",
              "urls": ["https://wagner.house.gov"],
              "rss_url": "https://wagner.house.gov/rss.xml"
            },
            "title": "MO House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000812.json",
            "govtrack_id": "412548",
            "cspan_id": "82702",
            "votesmart_id": "136083",
            "icpsr_id": "21337",
            "crp_id": "N00033106",
            "google_entity_id": "/m/07q9w8",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1962-09-13",
            "leadership_role": null,
            "fec_candidate_id": "H2MO02102",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 25,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 2.37,
            "votes_with_party_pct": 87.41,
            "votes_against_party_pct": 10.7,
            "ocd_id": "ocd-division/country:us/state:mo/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MO-02--W000812": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/633fd335-98a2-42a7-acac-cb78eeca9a12",
            "firstName": "Jeff",
            "lastName": "Farnan",
            "fullName": "Jeff Farnan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2354",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-MO-1-lower-ocd-person-633fd335-98a2-42a7-acac-cb78eeca9a12",
              "builtID": "mo-lower-1",
              "externalID": "ocd-person/633fd335-98a2-42a7-acac-cb78eeca9a12",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.farnan@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=001",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-1-lower-ocd-person-633fd335-98a2-42a7-acac-cb78eeca9a12": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/22e282f9-afbf-46b8-ac81-0780f5372d0d",
            "firstName": "Mazzie M.",
            "lastName": "Christensen",
            "fullName": "Mazzie Christensen",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2381",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-MO-2-lower-ocd-person-22e282f9-afbf-46b8-ac81-0780f5372d0d",
              "builtID": "mo-lower-2",
              "externalID": "ocd-person/22e282f9-afbf-46b8-ac81-0780f5372d0d",
              "geometry": null
            },
            "contactInfo": {
              "email": "mazzie.christensen@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=002",
                "https://house.mo.gov/MemberDetails.aspx?district=002"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-2-lower-ocd-person-22e282f9-afbf-46b8-ac81-0780f5372d0d": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/ace530aa-6fbb-40a0-b91e-94820a287b2e",
            "firstName": "Danny",
            "lastName": "Busick",
            "fullName": "Danny Busick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2069",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-MO-3-lower-ocd-person-ace530aa-6fbb-40a0-b91e-94820a287b2e",
              "builtID": "mo-lower-3",
              "externalID": "ocd-person/ace530aa-6fbb-40a0-b91e-94820a287b2e",
              "geometry": null
            },
            "contactInfo": {
              "email": "danny.busick@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=3",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=003",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=003",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=3",
                "https://house.mo.gov/MemberDetails.aspx?year=2024&code=R&district=003"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-3-lower-ocd-person-ace530aa-6fbb-40a0-b91e-94820a287b2e": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/d6be1f75-f237-41ad-af36-a7d1c6e3a9c6",
            "firstName": "Greg",
            "lastName": "Sharpe",
            "fullName": "Greg Sharpe",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2086",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-MO-4-lower-ocd-person-d6be1f75-f237-41ad-af36-a7d1c6e3a9c6",
              "builtID": "mo-lower-4",
              "externalID": "ocd-person/d6be1f75-f237-41ad-af36-a7d1c6e3a9c6",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.sharpe@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=4",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=004",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=004",
                "https://house.mo.gov/MemberDetails.aspx?district=004"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-4-lower-ocd-person-d6be1f75-f237-41ad-af36-a7d1c6e3a9c6": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/9a78c55e-0f06-4dd9-b86e-287db7332d98",
            "firstName": "Louis",
            "lastName": "Riggs",
            "fullName": "Louis Riggs",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2094",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-MO-5-lower-ocd-person-9a78c55e-0f06-4dd9-b86e-287db7332d98",
              "builtID": "mo-lower-5",
              "externalID": "ocd-person/9a78c55e-0f06-4dd9-b86e-287db7332d98",
              "geometry": null
            },
            "contactInfo": {
              "email": "louis.riggs@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=5",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=005",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=005",
                "https://house.mo.gov/MemberDetails.aspx?district=005",
                "https://house.mo.gov/MemberDetails.aspx?year=2024&code=R&district=005"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-5-lower-ocd-person-9a78c55e-0f06-4dd9-b86e-287db7332d98": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/c53c50aa-49b2-415a-9a50-eb947daf3d7f",
            "firstName": "Edwin",
            "lastName": "Lewis",
            "fullName": "Ed Lewis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2244",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-MO-6-lower-ocd-person-c53c50aa-49b2-415a-9a50-eb947daf3d7f",
              "builtID": "mo-lower-6",
              "externalID": "ocd-person/c53c50aa-49b2-415a-9a50-eb947daf3d7f",
              "geometry": null
            },
            "contactInfo": {
              "email": "ed.lewis@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=006",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=006",
                "https://house.mo.gov/MemberDetails.aspx?district=006"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-6-lower-ocd-person-c53c50aa-49b2-415a-9a50-eb947daf3d7f": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/920c5b79-73a0-477b-a90d-1a290e48e014",
            "firstName": "Peggy",
            "lastName": "McGaugh",
            "fullName": "Peggy McGaugh",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2019",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-MO-7-lower-ocd-person-920c5b79-73a0-477b-a90d-1a290e48e014",
              "builtID": "mo-lower-7",
              "externalID": "ocd-person/920c5b79-73a0-477b-a90d-1a290e48e014",
              "geometry": null
            },
            "contactInfo": {
              "email": "peggy.mcgaugh@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=39",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=039",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=007",
                "https://house.mo.gov/MemberDetails.aspx?district=007"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-7-lower-ocd-person-920c5b79-73a0-477b-a90d-1a290e48e014": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/1da3a25b-64fa-48d4-8441-e1dca4526f7f",
            "firstName": "Joshua E.",
            "lastName": "Hurlbert",
            "fullName": "Josh Hurlbert",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2242",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-MO-8-lower-ocd-person-1da3a25b-64fa-48d4-8441-e1dca4526f7f",
              "builtID": "mo-lower-8",
              "externalID": "ocd-person/1da3a25b-64fa-48d4-8441-e1dca4526f7f",
              "geometry": null
            },
            "contactInfo": {
              "email": "josh.hurlbert@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=008",
                "https://house.mo.gov/MemberDetails.aspx?district=008"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-8-lower-ocd-person-1da3a25b-64fa-48d4-8441-e1dca4526f7f": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/f3250139-5c42-40d9-b535-2e7137f83f1e",
            "firstName": "Dean",
            "lastName": "VanSchoiack",
            "fullName": "Dean VanSchoiack",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2243",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-MO-9-lower-ocd-person-f3250139-5c42-40d9-b535-2e7137f83f1e",
              "builtID": "mo-lower-9",
              "externalID": "ocd-person/f3250139-5c42-40d9-b535-2e7137f83f1e",
              "geometry": null
            },
            "contactInfo": {
              "email": "dean.vanschoiack@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=009",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=009",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=9"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-9-lower-ocd-person-f3250139-5c42-40d9-b535-2e7137f83f1e": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/28245e55-fddc-46db-85a7-ce9ca55da6d7",
            "firstName": "Bill",
            "lastName": "Falkner",
            "fullName": "Bill Falkner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2062",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-MO-10-lower-ocd-person-28245e55-fddc-46db-85a7-ce9ca55da6d7",
              "builtID": "mo-lower-10",
              "externalID": "ocd-person/28245e55-fddc-46db-85a7-ce9ca55da6d7",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.falkner@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=010",
                "https://www.house.mo.gov/MemberDetails.aspx?district=10",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=010",
                "https://house.mo.gov/memberdetails.aspx?district=010"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-10-lower-ocd-person-28245e55-fddc-46db-85a7-ce9ca55da6d7": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/b7d28fcb-9563-420c-91bb-01d3202124fd",
            "firstName": "Brenda",
            "lastName": "Shields",
            "fullName": "Brenda Shields",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2093",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-MO-11-lower-ocd-person-b7d28fcb-9563-420c-91bb-01d3202124fd",
              "builtID": "mo-lower-11",
              "externalID": "ocd-person/b7d28fcb-9563-420c-91bb-01d3202124fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "brenda.shields@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=11",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=011",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=011",
                "https://house.mo.gov/MemberDetails.aspx?district=011"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-11-lower-ocd-person-b7d28fcb-9563-420c-91bb-01d3202124fd": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/a6f69fd7-b80a-4f5c-a4ae-ad963c80d566",
            "firstName": "Jamie",
            "lastName": "Johnson",
            "fullName": "Jamie Johnson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2387",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-MO-12-lower-ocd-person-a6f69fd7-b80a-4f5c-a4ae-ad963c80d566",
              "builtID": "mo-lower-12",
              "externalID": "ocd-person/a6f69fd7-b80a-4f5c-a4ae-ad963c80d566",
              "geometry": null
            },
            "contactInfo": {
              "email": "jamie.johnson@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=012",
                "https://house.mo.gov/MemberDetails.aspx?district=012"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-12-lower-ocd-person-a6f69fd7-b80a-4f5c-a4ae-ad963c80d566": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/e1a53185-3c84-4f82-87f0-09bd607cf2e7",
            "firstName": "Sean Scobee",
            "lastName": "Pouche",
            "fullName": "Sean Pouche",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2209",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-MO-13-lower-ocd-person-e1a53185-3c84-4f82-87f0-09bd607cf2e7",
              "builtID": "mo-lower-13",
              "externalID": "ocd-person/e1a53185-3c84-4f82-87f0-09bd607cf2e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "sean.pouche@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=013",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=013",
                "https://house.mo.gov/MemberDetails.aspx?district=013"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-13-lower-ocd-person-e1a53185-3c84-4f82-87f0-09bd607cf2e7": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/1ff1dec0-c449-4ff8-aa22-0830817c2070",
            "firstName": "Ashley",
            "lastName": "Aune",
            "fullName": "Ashley Aune",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2210",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-MO-14-lower-ocd-person-1ff1dec0-c449-4ff8-aa22-0830817c2070",
              "builtID": "mo-lower-14",
              "externalID": "ocd-person/1ff1dec0-c449-4ff8-aa22-0830817c2070",
              "geometry": null
            },
            "contactInfo": {
              "email": "ashley.aune@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=014",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=014",
                "https://house.mo.gov/MemberDetails.aspx?district=014"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-14-lower-ocd-person-1ff1dec0-c449-4ff8-aa22-0830817c2070": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/d8594030-4826-48c5-af43-674470f1dd44",
            "firstName": "Maggie",
            "lastName": "Nurrenbern",
            "fullName": "Maggie Nurrenbern",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2211",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-MO-15-lower-ocd-person-d8594030-4826-48c5-af43-674470f1dd44",
              "builtID": "mo-lower-15",
              "externalID": "ocd-person/d8594030-4826-48c5-af43-674470f1dd44",
              "geometry": null
            },
            "contactInfo": {
              "email": "maggie.nurrenbern@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=015",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=015",
                "https://house.mo.gov/MemberDetails.aspx?district=015"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-15-lower-ocd-person-d8594030-4826-48c5-af43-674470f1dd44": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/43f13a74-64cd-41b6-b03a-51af80dbfc10",
            "firstName": "Chris",
            "lastName": "Brown",
            "fullName": "Chris Brown",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2212",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-MO-16-lower-ocd-person-43f13a74-64cd-41b6-b03a-51af80dbfc10",
              "builtID": "mo-lower-16",
              "externalID": "ocd-person/43f13a74-64cd-41b6-b03a-51af80dbfc10",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.brown@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=016",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=016",
                "https://house.mo.gov/MemberDetails.aspx?district=016"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-16-lower-ocd-person-43f13a74-64cd-41b6-b03a-51af80dbfc10": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/7472aac6-4730-456b-8e2f-3b79e45da74c",
            "firstName": "Bill",
            "lastName": "Allen",
            "fullName": "Bill Allen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2377",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-MO-17-lower-ocd-person-7472aac6-4730-456b-8e2f-3b79e45da74c",
              "builtID": "mo-lower-17",
              "externalID": "ocd-person/7472aac6-4730-456b-8e2f-3b79e45da74c",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.allen@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=017",
                "https://house.mo.gov/memberdetails.aspx?district=017"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-17-lower-ocd-person-7472aac6-4730-456b-8e2f-3b79e45da74c": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/76bc7488-cc9e-4b47-91da-4557fb577ff5",
            "firstName": "Eric",
            "lastName": "Woods",
            "fullName": "Eric Woods",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2367",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-MO-18-lower-ocd-person-76bc7488-cc9e-4b47-91da-4557fb577ff5",
              "builtID": "mo-lower-18",
              "externalID": "ocd-person/76bc7488-cc9e-4b47-91da-4557fb577ff5",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.woods@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=018",
                "https://house.mo.gov/MemberDetails.aspx?district=018"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-18-lower-ocd-person-76bc7488-cc9e-4b47-91da-4557fb577ff5": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/b97c98fc-fdc1-4d70-8729-d1f9c951a3d8",
            "firstName": "Ingrid",
            "lastName": "Burnett",
            "fullName": "Ingrid Burnett",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1916",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-MO-19-lower-ocd-person-b97c98fc-fdc1-4d70-8729-d1f9c951a3d8",
              "builtID": "mo-lower-19",
              "externalID": "ocd-person/b97c98fc-fdc1-4d70-8729-d1f9c951a3d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "ingrid.burnett@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=19",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=019",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=019",
                "https://house.mo.gov/MemberDetails.aspx?district=019"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-19-lower-ocd-person-b97c98fc-fdc1-4d70-8729-d1f9c951a3d8": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/4773dfea-a880-4ca1-bdf9-d2c3d487864d",
            "firstName": "Aaron",
            "lastName": "McMullen",
            "fullName": "Aaron McMullen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2391",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-MO-20-lower-ocd-person-4773dfea-a880-4ca1-bdf9-d2c3d487864d",
              "builtID": "mo-lower-20",
              "externalID": "ocd-person/4773dfea-a880-4ca1-bdf9-d2c3d487864d",
              "geometry": null
            },
            "contactInfo": {
              "email": "aaron.mcmullen@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=020",
                "https://house.mo.gov/MemberDetails.aspx?district=020"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-20-lower-ocd-person-4773dfea-a880-4ca1-bdf9-d2c3d487864d": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/daa04c1e-185e-4125-84e5-dd7e3d1c6766",
            "firstName": "Robert Edward",
            "lastName": "Sauls",
            "fullName": "Robert Sauls",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2130",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-MO-21-lower-ocd-person-daa04c1e-185e-4125-84e5-dd7e3d1c6766",
              "builtID": "mo-lower-21",
              "externalID": "ocd-person/daa04c1e-185e-4125-84e5-dd7e3d1c6766",
              "geometry": null
            },
            "contactInfo": {
              "email": "robert.sauls@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=21",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=021",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=021",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=21"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-21-lower-ocd-person-daa04c1e-185e-4125-84e5-dd7e3d1c6766": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/e2fb607f-9af9-4d80-b473-8ed33e3d942d",
            "firstName": "Yolanda",
            "lastName": "Young",
            "fullName": "Yolanda Young",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2168",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-MO-22-lower-ocd-person-e2fb607f-9af9-4d80-b473-8ed33e3d942d",
              "builtID": "mo-lower-22",
              "externalID": "ocd-person/e2fb607f-9af9-4d80-b473-8ed33e3d942d",
              "geometry": null
            },
            "contactInfo": {
              "email": "yolanda.young@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=22",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=022",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=022",
                "https://house.mo.gov/MemberDetails.aspx?district=022"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-22-lower-ocd-person-e2fb607f-9af9-4d80-b473-8ed33e3d942d": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/8014964b-117f-40a4-a466-ac597dd70983",
            "firstName": "Michael",
            "lastName": "Johnson",
            "fullName": "Michael Johnson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2255",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-MO-23-lower-ocd-person-8014964b-117f-40a4-a466-ac597dd70983",
              "builtID": "mo-lower-23",
              "externalID": "ocd-person/8014964b-117f-40a4-a466-ac597dd70983",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.johnson@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=023",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=023",
                "https://house.mo.gov/MemberDetails.aspx?district=023"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-23-lower-ocd-person-8014964b-117f-40a4-a466-ac597dd70983": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/f7c7d32d-5d88-489a-9adf-76c9837b3272",
            "firstName": "Emily",
            "lastName": "Weber",
            "fullName": "Emily Weber",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2246",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-MO-24-lower-ocd-person-f7c7d32d-5d88-489a-9adf-76c9837b3272",
              "builtID": "mo-lower-24",
              "externalID": "ocd-person/f7c7d32d-5d88-489a-9adf-76c9837b3272",
              "geometry": null
            },
            "contactInfo": {
              "email": "emily.weber@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=024",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=024",
                "https://house.mo.gov/MemberDetails.aspx?district=024"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-24-lower-ocd-person-f7c7d32d-5d88-489a-9adf-76c9837b3272": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/618e91b0-663d-4095-9a38-eefab6304e5f",
            "firstName": "Patty",
            "lastName": "Lewis",
            "fullName": "Patty Lewis",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2213",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-MO-25-lower-ocd-person-618e91b0-663d-4095-9a38-eefab6304e5f",
              "builtID": "mo-lower-25",
              "externalID": "ocd-person/618e91b0-663d-4095-9a38-eefab6304e5f",
              "geometry": null
            },
            "contactInfo": {
              "email": "patty.lewis@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=025",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=025",
                "https://house.mo.gov/MemberDetails.aspx?district=025"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-25-lower-ocd-person-618e91b0-663d-4095-9a38-eefab6304e5f": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/7c346fe4-6aaa-404a-9ecf-98df40edf450",
            "firstName": "Ashley Jade Bland",
            "lastName": "Manlove",
            "fullName": "Ashley Bland Manlove",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2056",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-MO-26-lower-ocd-person-7c346fe4-6aaa-404a-9ecf-98df40edf450",
              "builtID": "mo-lower-26",
              "externalID": "ocd-person/7c346fe4-6aaa-404a-9ecf-98df40edf450",
              "geometry": null
            },
            "contactInfo": {
              "email": "ashley.blandmanlove@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=26",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=026",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=026",
                "https://house.mo.gov/MemberDetails.aspx?district=026"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-26-lower-ocd-person-7c346fe4-6aaa-404a-9ecf-98df40edf450": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/5d6d2e6d-5e93-4323-adc1-32635f0077e3",
            "firstName": "Richard",
            "lastName": "Brown",
            "fullName": "Richard Brown",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1917",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-MO-27-lower-ocd-person-5d6d2e6d-5e93-4323-adc1-32635f0077e3",
              "builtID": "mo-lower-27",
              "externalID": "ocd-person/5d6d2e6d-5e93-4323-adc1-32635f0077e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.brown@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=27",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=027",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=027",
                "https://house.mo.gov/MemberDetails.aspx?district=027"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-27-lower-ocd-person-5d6d2e6d-5e93-4323-adc1-32635f0077e3": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/92c3e6b9-77e4-404e-988b-810026971bec",
            "firstName": "Jerome",
            "lastName": "Barnes",
            "fullName": "Jerome Barnes",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1931",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-MO-28-lower-ocd-person-92c3e6b9-77e4-404e-988b-810026971bec",
              "builtID": "mo-lower-28",
              "externalID": "ocd-person/92c3e6b9-77e4-404e-988b-810026971bec",
              "geometry": null
            },
            "contactInfo": {
              "email": "jerome.barnes@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=28",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=028",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=028",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=28"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-28-lower-ocd-person-92c3e6b9-77e4-404e-988b-810026971bec": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/ba6eb394-0402-49ba-9917-c5618d8a1589",
            "firstName": "Aaron",
            "lastName": "Crossley",
            "fullName": "Aaron Crossley",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2366",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-MO-29-lower-ocd-person-ba6eb394-0402-49ba-9917-c5618d8a1589",
              "builtID": "mo-lower-29",
              "externalID": "ocd-person/ba6eb394-0402-49ba-9917-c5618d8a1589",
              "geometry": null
            },
            "contactInfo": {
              "email": "aaron.crossley@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=029",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=29"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-29-lower-ocd-person-ba6eb394-0402-49ba-9917-c5618d8a1589": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/419c30c0-b3f2-4d4b-a2f1-ee08050e845b",
            "firstName": "Jonathan",
            "lastName": "Patterson",
            "fullName": "Jon Patterson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2075",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-MO-30-lower-ocd-person-419c30c0-b3f2-4d4b-a2f1-ee08050e845b",
              "builtID": "mo-lower-30",
              "externalID": "ocd-person/419c30c0-b3f2-4d4b-a2f1-ee08050e845b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jonathan.patterson@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=30",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=030",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=030",
                "https://house.mo.gov/MemberDetails.aspx?district=030"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-30-lower-ocd-person-419c30c0-b3f2-4d4b-a2f1-ee08050e845b": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/807fb33c-89e6-4d2e-ac97-2860c363a0a3",
            "firstName": "Dan",
            "lastName": "Stacy",
            "fullName": "Dan Stacy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1899",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-MO-31-lower-ocd-person-807fb33c-89e6-4d2e-ac97-2860c363a0a3",
              "builtID": "mo-lower-31",
              "externalID": "ocd-person/807fb33c-89e6-4d2e-ac97-2860c363a0a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.stacy@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=31",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=031",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=031",
                "https://house.mo.gov/MemberDetails.aspx?district=031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-31-lower-ocd-person-807fb33c-89e6-4d2e-ac97-2860c363a0a3": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/100ec26e-d90f-4707-822c-db61c8f1b370",
            "firstName": "Jeffrey L.",
            "lastName": "Coleman",
            "fullName": "Jeff Coleman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2071",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-MO-32-lower-ocd-person-100ec26e-d90f-4707-822c-db61c8f1b370",
              "builtID": "mo-lower-32",
              "externalID": "ocd-person/100ec26e-d90f-4707-822c-db61c8f1b370",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.coleman@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=32",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=032",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=032",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=32"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-32-lower-ocd-person-100ec26e-d90f-4707-822c-db61c8f1b370": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/962b4926-1444-414e-af1d-93ba1365f157",
            "firstName": "Chris",
            "lastName": "Sander",
            "fullName": "Chris Sander",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2214",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-MO-33-lower-ocd-person-962b4926-1444-414e-af1d-93ba1365f157",
              "builtID": "mo-lower-33",
              "externalID": "ocd-person/962b4926-1444-414e-af1d-93ba1365f157",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.sander@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=033",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=033",
                "https://house.mo.gov/MemberDetails.aspx?district=033"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-33-lower-ocd-person-962b4926-1444-414e-af1d-93ba1365f157": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/c140221e-dfad-45d7-9371-faf3cc12ff17",
            "firstName": "Kemp",
            "lastName": "Strickler",
            "fullName": "Kemp Strickler",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2358",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-MO-34-lower-ocd-person-c140221e-dfad-45d7-9371-faf3cc12ff17",
              "builtID": "mo-lower-34",
              "externalID": "ocd-person/c140221e-dfad-45d7-9371-faf3cc12ff17",
              "geometry": null
            },
            "contactInfo": {
              "email": "kemp.strickler@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=034",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=34"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-34-lower-ocd-person-c140221e-dfad-45d7-9371-faf3cc12ff17": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/7f91f845-d612-45d2-bc08-421c4e91357a",
            "firstName": "Keri",
            "lastName": "Ingle",
            "fullName": "Keri Ingle",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2134",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-MO-35-lower-ocd-person-7f91f845-d612-45d2-bc08-421c4e91357a",
              "builtID": "mo-lower-35",
              "externalID": "ocd-person/7f91f845-d612-45d2-bc08-421c4e91357a",
              "geometry": null
            },
            "contactInfo": {
              "email": "keri.ingle@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=35",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=035",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=035",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=35"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-35-lower-ocd-person-7f91f845-d612-45d2-bc08-421c4e91357a": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/a5cc5083-e44a-4379-b4db-74c4a930821b",
            "firstName": "Anthony T.",
            "lastName": "Ealy",
            "fullName": "Anthony Ealy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2384",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-MO-36-lower-ocd-person-a5cc5083-e44a-4379-b4db-74c4a930821b",
              "builtID": "mo-lower-36",
              "externalID": "ocd-person/a5cc5083-e44a-4379-b4db-74c4a930821b",
              "geometry": null
            },
            "contactInfo": {
              "email": "anthony.ealy@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=036",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=36"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-36-lower-ocd-person-a5cc5083-e44a-4379-b4db-74c4a930821b": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/0fddc5af-b1f0-49ca-84d9-fc6458edf362",
            "firstName": "Mark",
            "lastName": "Sharp",
            "fullName": "Mark Sharp",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2169",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-MO-37-lower-ocd-person-0fddc5af-b1f0-49ca-84d9-fc6458edf362",
              "builtID": "mo-lower-37",
              "externalID": "ocd-person/0fddc5af-b1f0-49ca-84d9-fc6458edf362",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.sharp@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=36",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=036",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=037",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=37"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-37-lower-ocd-person-0fddc5af-b1f0-49ca-84d9-fc6458edf362": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/1fb98f39-af96-4545-a978-17a379dedbf6",
            "firstName": "Chris",
            "lastName": "Lonsdale",
            "fullName": "Chris Lonsdale",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2390",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-MO-38-lower-ocd-person-1fb98f39-af96-4545-a978-17a379dedbf6",
              "builtID": "mo-lower-38",
              "externalID": "ocd-person/1fb98f39-af96-4545-a978-17a379dedbf6",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.lonsdale@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=038",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=38"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-38-lower-ocd-person-1fb98f39-af96-4545-a978-17a379dedbf6": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/31c11703-e7ba-44fb-b5ac-0fbba3f772f9",
            "firstName": "Doug",
            "lastName": "Richey",
            "fullName": "Doug Richey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2088",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-MO-39-lower-ocd-person-31c11703-e7ba-44fb-b5ac-0fbba3f772f9",
              "builtID": "mo-lower-39",
              "externalID": "ocd-person/31c11703-e7ba-44fb-b5ac-0fbba3f772f9",
              "geometry": null
            },
            "contactInfo": {
              "email": "doug.richey@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=38",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=038",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=039",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=39"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-39-lower-ocd-person-31c11703-e7ba-44fb-b5ac-0fbba3f772f9": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/b1fb2c50-2091-4264-abed-e89079479bc0",
            "firstName": "Chad",
            "lastName": "Perkins",
            "fullName": "Chad Perkins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2207",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-MO-40-lower-ocd-person-b1fb2c50-2091-4264-abed-e89079479bc0",
              "builtID": "mo-lower-40",
              "externalID": "ocd-person/b1fb2c50-2091-4264-abed-e89079479bc0",
              "geometry": null
            },
            "contactInfo": {
              "email": "chad.perkins@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=040",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=040",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=40"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-40-lower-ocd-person-b1fb2c50-2091-4264-abed-e89079479bc0": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/f3eb420f-df97-4edf-b8c9-e0fa231c6860",
            "firstName": "Doyle",
            "lastName": "Justus",
            "fullName": "Doyle Justus",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2364",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-MO-41-lower-ocd-person-f3eb420f-df97-4edf-b8c9-e0fa231c6860",
              "builtID": "mo-lower-41",
              "externalID": "ocd-person/f3eb420f-df97-4edf-b8c9-e0fa231c6860",
              "geometry": null
            },
            "contactInfo": {
              "email": "doyle.justus@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=041",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=41"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-41-lower-ocd-person-f3eb420f-df97-4edf-b8c9-e0fa231c6860": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/acd99a81-de49-4ab0-9bb5-bb4fa72c02c5",
            "firstName": "Jeff",
            "lastName": "Myers",
            "fullName": "Jeff Myers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2392",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-MO-42-lower-ocd-person-acd99a81-de49-4ab0-9bb5-bb4fa72c02c5",
              "builtID": "mo-lower-42",
              "externalID": "ocd-person/acd99a81-de49-4ab0-9bb5-bb4fa72c02c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.myers@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=042",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=42"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-42-lower-ocd-person-acd99a81-de49-4ab0-9bb5-bb4fa72c02c5": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/96d0d8da-dcff-4f59-b051-e0da15584b7b",
            "firstName": "Kent",
            "lastName": "Haden",
            "fullName": "Kent Haden",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2076",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-MO-43-lower-ocd-person-96d0d8da-dcff-4f59-b051-e0da15584b7b",
              "builtID": "mo-lower-43",
              "externalID": "ocd-person/96d0d8da-dcff-4f59-b051-e0da15584b7b",
              "geometry": null
            },
            "contactInfo": {
              "email": "kent.haden@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=43",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=043",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=043",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=43"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-43-lower-ocd-person-96d0d8da-dcff-4f59-b051-e0da15584b7b": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/11b71e58-eaff-4889-a77b-2d5c9e53b3e9",
            "firstName": "Cheri",
            "lastName": "Toalson Reisch",
            "fullName": "Cheri Toalson Reisch",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1946",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-MO-44-lower-ocd-person-11b71e58-eaff-4889-a77b-2d5c9e53b3e9",
              "builtID": "mo-lower-44",
              "externalID": "ocd-person/11b71e58-eaff-4889-a77b-2d5c9e53b3e9",
              "geometry": null
            },
            "contactInfo": {
              "email": "cheri.toalson-reisch@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=44",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=044",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=044",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=44"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-44-lower-ocd-person-11b71e58-eaff-4889-a77b-2d5c9e53b3e9": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/6ee52a1a-7c1b-4ebb-ac4f-8602d03b2034",
            "firstName": "Mary Kathy",
            "lastName": "Steinhoff",
            "fullName": "Kathy Steinhoff",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2361",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-MO-45-lower-ocd-person-6ee52a1a-7c1b-4ebb-ac4f-8602d03b2034",
              "builtID": "mo-lower-45",
              "externalID": "ocd-person/6ee52a1a-7c1b-4ebb-ac4f-8602d03b2034",
              "geometry": null
            },
            "contactInfo": {
              "email": "kathy.steinhoff@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=045",
                "https://house.mo.gov/MemberDetails.aspx?district=045"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-45-lower-ocd-person-6ee52a1a-7c1b-4ebb-ac4f-8602d03b2034": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/c509449e-070c-462c-810e-f14315c9df42",
            "firstName": "David Tyson",
            "lastName": "Smith",
            "fullName": "David Smith",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2290",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-MO-46-lower-ocd-person-c509449e-070c-462c-810e-f14315c9df42",
              "builtID": "mo-lower-46",
              "externalID": "ocd-person/c509449e-070c-462c-810e-f14315c9df42",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.smith@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=046",
                "https://house.mo.gov/MemberDetails.aspx?district=046"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-46-lower-ocd-person-c509449e-070c-462c-810e-f14315c9df42": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/f2e657d5-999a-4d77-98e3-b06a7c3771f4",
            "firstName": "Adrian S.",
            "lastName": "Plank",
            "fullName": "Adrian Plank",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2395",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-MO-47-lower-ocd-person-f2e657d5-999a-4d77-98e3-b06a7c3771f4",
              "builtID": "mo-lower-47",
              "externalID": "ocd-person/f2e657d5-999a-4d77-98e3-b06a7c3771f4",
              "geometry": null
            },
            "contactInfo": {
              "email": "adrian.plank@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=047",
                "https://house.mo.gov/MemberDetails.aspx?district=047"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-47-lower-ocd-person-f2e657d5-999a-4d77-98e3-b06a7c3771f4": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/14c919a7-4443-4bc7-8221-34a59cc3bfef",
            "firstName": "Timothy",
            "lastName": "Taylor",
            "fullName": "Tim Taylor",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2217",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-MO-48-lower-ocd-person-14c919a7-4443-4bc7-8221-34a59cc3bfef",
              "builtID": "mo-lower-48",
              "externalID": "ocd-person/14c919a7-4443-4bc7-8221-34a59cc3bfef",
              "geometry": null
            },
            "contactInfo": {
              "email": "tim.taylor@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=048",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=048",
                "https://house.mo.gov/MemberDetails.aspx?district=048"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-48-lower-ocd-person-14c919a7-4443-4bc7-8221-34a59cc3bfef": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/9814247b-44fc-473f-b770-a44c77292d77",
            "firstName": "Jim",
            "lastName": "Schulte",
            "fullName": "Jim Schulte",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2397",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-MO-49-lower-ocd-person-9814247b-44fc-473f-b770-a44c77292d77",
              "builtID": "mo-lower-49",
              "externalID": "ocd-person/9814247b-44fc-473f-b770-a44c77292d77",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.schulte@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=049",
                "https://house.mo.gov/MemberDetails.aspx?district=049"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-49-lower-ocd-person-9814247b-44fc-473f-b770-a44c77292d77": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/ff57107c-b23f-4956-81ae-de882134c539",
            "firstName": "Douglas",
            "lastName": "Mann",
            "fullName": "Doug Mann",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2356",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-MO-50-lower-ocd-person-ff57107c-b23f-4956-81ae-de882134c539",
              "builtID": "mo-lower-50",
              "externalID": "ocd-person/ff57107c-b23f-4956-81ae-de882134c539",
              "geometry": null
            },
            "contactInfo": {
              "email": "doug%20mann@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=050",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=50"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-50-lower-ocd-person-ff57107c-b23f-4956-81ae-de882134c539": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/1ab01adf-962d-47ac-977c-2cf25712f583",
            "firstName": "Kurtis",
            "lastName": "Gregory",
            "fullName": "Kurtis Gregory",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2218",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-MO-51-lower-ocd-person-1ab01adf-962d-47ac-977c-2cf25712f583",
              "builtID": "mo-lower-51",
              "externalID": "ocd-person/1ab01adf-962d-47ac-977c-2cf25712f583",
              "geometry": null
            },
            "contactInfo": {
              "email": "kurtis.gregory@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=051",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=051",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=51"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-51-lower-ocd-person-1ab01adf-962d-47ac-977c-2cf25712f583": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/4cfed488-abe6-46e5-a3aa-55a73e2984ca",
            "firstName": "Bradley",
            "lastName": "Pollitt",
            "fullName": "Brad Pollitt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2073",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-MO-52-lower-ocd-person-4cfed488-abe6-46e5-a3aa-55a73e2984ca",
              "builtID": "mo-lower-52",
              "externalID": "ocd-person/4cfed488-abe6-46e5-a3aa-55a73e2984ca",
              "geometry": null
            },
            "contactInfo": {
              "email": "bradley.pollitt@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=52",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=052",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=052",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=52"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-52-lower-ocd-person-4cfed488-abe6-46e5-a3aa-55a73e2984ca": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/da48983a-852b-4d3b-b635-285f37d8d012",
            "firstName": "Terry",
            "lastName": "Thompson",
            "fullName": "Terry Thompson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2219",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-MO-53-lower-ocd-person-da48983a-852b-4d3b-b635-285f37d8d012",
              "builtID": "mo-lower-53",
              "externalID": "ocd-person/da48983a-852b-4d3b-b635-285f37d8d012",
              "geometry": null
            },
            "contactInfo": {
              "email": "terry.thompson@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=053",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=053",
                "https://house.mo.gov/MemberDetails.aspx?district=053"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-53-lower-ocd-person-da48983a-852b-4d3b-b635-285f37d8d012": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/aa6fd7b5-39d2-41f0-8435-7190259e9b86",
            "firstName": "Dan",
            "lastName": "Houx",
            "fullName": "Dan Houx",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1904",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-MO-54-lower-ocd-person-aa6fd7b5-39d2-41f0-8435-7190259e9b86",
              "builtID": "mo-lower-54",
              "externalID": "ocd-person/aa6fd7b5-39d2-41f0-8435-7190259e9b86",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.houx@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=54",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=054",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=054",
                "https://house.mo.gov/MemberDetails.aspx?district=054"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-54-lower-ocd-person-aa6fd7b5-39d2-41f0-8435-7190259e9b86": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/a1f0048f-8cd3-4536-89eb-562d51ac6864",
            "firstName": "Mike",
            "lastName": "Haffner",
            "fullName": "Mike Haffner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2111",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-MO-55-lower-ocd-person-a1f0048f-8cd3-4536-89eb-562d51ac6864",
              "builtID": "mo-lower-55",
              "externalID": "ocd-person/a1f0048f-8cd3-4536-89eb-562d51ac6864",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.haffner@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=55",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=055",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=055",
                "https://house.mo.gov/MemberDetails.aspx?district=055"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-55-lower-ocd-person-a1f0048f-8cd3-4536-89eb-562d51ac6864": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/dc271660-9483-4b46-8c96-4794078b130f",
            "firstName": "Michael",
            "lastName": "Davis",
            "fullName": "Michael Davis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2220",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-MO-56-lower-ocd-person-dc271660-9483-4b46-8c96-4794078b130f",
              "builtID": "mo-lower-56",
              "externalID": "ocd-person/dc271660-9483-4b46-8c96-4794078b130f",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.davis@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=056",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=056",
                "https://house.mo.gov/MemberDetails.aspx?district=056"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-56-lower-ocd-person-dc271660-9483-4b46-8c96-4794078b130f": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/bd63f6e7-ed53-4a4c-98b6-925588710b0b",
            "firstName": "Rodger L.",
            "lastName": "Reedy",
            "fullName": "Rodger Reedy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2096",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-MO-57-lower-ocd-person-bd63f6e7-ed53-4a4c-98b6-925588710b0b",
              "builtID": "mo-lower-57",
              "externalID": "ocd-person/bd63f6e7-ed53-4a4c-98b6-925588710b0b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rodger.reedy@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=57",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=057",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=057",
                "https://house.mo.gov/MemberDetails.aspx?district=057"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-57-lower-ocd-person-bd63f6e7-ed53-4a4c-98b6-925588710b0b": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/05c84580-b849-4320-9aa4-e34d8b977aca",
            "firstName": "Willard",
            "lastName": "Haley",
            "fullName": "Willard Haley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2221",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-MO-58-lower-ocd-person-05c84580-b849-4320-9aa4-e34d8b977aca",
              "builtID": "mo-lower-58",
              "externalID": "ocd-person/05c84580-b849-4320-9aa4-e34d8b977aca",
              "geometry": null
            },
            "contactInfo": {
              "email": "willard.haley@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=058",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=058",
                "https://house.mo.gov/MemberDetails.aspx?district=058"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-58-lower-ocd-person-05c84580-b849-4320-9aa4-e34d8b977aca": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/fe941ee4-f34a-4685-873c-29adbb732b2c",
            "firstName": "Rudy L.",
            "lastName": "Veit",
            "fullName": "Rudy Veit",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2078",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-MO-59-lower-ocd-person-fe941ee4-f34a-4685-873c-29adbb732b2c",
              "builtID": "mo-lower-59",
              "externalID": "ocd-person/fe941ee4-f34a-4685-873c-29adbb732b2c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rudy.veit@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=59",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=059",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=059",
                "https://house.mo.gov/MemberDetails.aspx?district=059"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-59-lower-ocd-person-fe941ee4-f34a-4685-873c-29adbb732b2c": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/841b8b5c-a701-43c0-bb81-8e9515e61ea0",
            "firstName": "David",
            "lastName": "Griffith",
            "fullName": "Dave Griffith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2052",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-MO-60-lower-ocd-person-841b8b5c-a701-43c0-bb81-8e9515e61ea0",
              "builtID": "mo-lower-60",
              "externalID": "ocd-person/841b8b5c-a701-43c0-bb81-8e9515e61ea0",
              "geometry": null
            },
            "contactInfo": {
              "email": "dave.griffith@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=60",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=060",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=060",
                "https://house.mo.gov/MemberDetails.aspx?district=060"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-60-lower-ocd-person-841b8b5c-a701-43c0-bb81-8e9515e61ea0": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/d01a9b1a-a262-4d27-9de9-b7ad0ff8c7f0",
            "firstName": "Bruce",
            "lastName": "Sassmann",
            "fullName": "Bruce Sassmann",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2222",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-MO-61-lower-ocd-person-d01a9b1a-a262-4d27-9de9-b7ad0ff8c7f0",
              "builtID": "mo-lower-61",
              "externalID": "ocd-person/d01a9b1a-a262-4d27-9de9-b7ad0ff8c7f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "bruce.sassmann@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=062",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=061",
                "https://house.mo.gov/MemberDetails.aspx?district=061"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-61-lower-ocd-person-d01a9b1a-a262-4d27-9de9-b7ad0ff8c7f0": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/272db469-eb67-4752-8307-c46a95eaace0",
            "firstName": "Sherri",
            "lastName": "Gallick",
            "fullName": "Sherri Gallick",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2359",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-MO-62-lower-ocd-person-272db469-eb67-4752-8307-c46a95eaace0",
              "builtID": "mo-lower-62",
              "externalID": "ocd-person/272db469-eb67-4752-8307-c46a95eaace0",
              "geometry": null
            },
            "contactInfo": {
              "email": "sherri.gallick@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=062",
                "https://house.mo.gov/MemberDetails.aspx?district=062"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-62-lower-ocd-person-272db469-eb67-4752-8307-c46a95eaace0": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/4f9135f0-db97-4860-af00-182d0026feae",
            "firstName": "Tricia K.",
            "lastName": "Byrnes",
            "fullName": "Tricia Byrnes",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2382",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-MO-63-lower-ocd-person-4f9135f0-db97-4860-af00-182d0026feae",
              "builtID": "mo-lower-63",
              "externalID": "ocd-person/4f9135f0-db97-4860-af00-182d0026feae",
              "geometry": null
            },
            "contactInfo": {
              "email": "tricia.byrnes@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=063",
                "https://house.mo.gov/MemberDetails.aspx?district=063"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-63-lower-ocd-person-4f9135f0-db97-4860-af00-182d0026feae": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/bbbb08a2-3f5c-4f55-ae94-605465b61130",
            "firstName": "Tony",
            "lastName": "Lovasco",
            "fullName": "Tony Lovasco",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2077",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-MO-64-lower-ocd-person-bbbb08a2-3f5c-4f55-ae94-605465b61130",
              "builtID": "mo-lower-64",
              "externalID": "ocd-person/bbbb08a2-3f5c-4f55-ae94-605465b61130",
              "geometry": null
            },
            "contactInfo": {
              "email": "tony.lovasco@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=64",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=064",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=064",
                "https://house.mo.gov/MemberDetails.aspx?district=64"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-64-lower-ocd-person-bbbb08a2-3f5c-4f55-ae94-605465b61130": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/b6ab609a-1abc-40ee-9ac1-889ccb08a9f0",
            "firstName": "Wendy",
            "lastName": "Hausman",
            "fullName": "Wendy Hausman",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2360",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-MO-65-lower-ocd-person-b6ab609a-1abc-40ee-9ac1-889ccb08a9f0",
              "builtID": "mo-lower-65",
              "externalID": "ocd-person/b6ab609a-1abc-40ee-9ac1-889ccb08a9f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "wendy.hausman@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=065",
                "https://house.mo.gov/MemberDetails.aspx?district=065"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-65-lower-ocd-person-b6ab609a-1abc-40ee-9ac1-889ccb08a9f0": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/47f4d77d-149c-410a-a324-067172efda3c",
            "firstName": "Marlene",
            "lastName": "Terry",
            "fullName": "Marlene Terry",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2247",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-MO-66-lower-ocd-person-47f4d77d-149c-410a-a324-067172efda3c",
              "builtID": "mo-lower-66",
              "externalID": "ocd-person/47f4d77d-149c-410a-a324-067172efda3c",
              "geometry": null
            },
            "contactInfo": {
              "email": "marlene.terry@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=066",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=066",
                "https://house.mo.gov/MemberDetails.aspx?district=066"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-66-lower-ocd-person-47f4d77d-149c-410a-a324-067172efda3c": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/6a91fe48-fe6d-4816-9358-939de946e7b4",
            "firstName": "Chantelle",
            "lastName": "Nickson-Clark",
            "fullName": "Chantelle Nickson-Clark",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2375",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-MO-67-lower-ocd-person-6a91fe48-fe6d-4816-9358-939de946e7b4",
              "builtID": "mo-lower-67",
              "externalID": "ocd-person/6a91fe48-fe6d-4816-9358-939de946e7b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "chantelle.nicksoncla@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=067",
                "https://house.mo.gov/MemberDetails.aspx?district=067"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-67-lower-ocd-person-6a91fe48-fe6d-4816-9358-939de946e7b4": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/3c4ba4e7-c000-4c5b-b6ab-266396bea4e9",
            "firstName": "Jermond",
            "lastName": "Mosley",
            "fullName": "Jay Mosley",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1938",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-MO-68-lower-ocd-person-3c4ba4e7-c000-4c5b-b6ab-266396bea4e9",
              "builtID": "mo-lower-68",
              "externalID": "ocd-person/3c4ba4e7-c000-4c5b-b6ab-266396bea4e9",
              "geometry": null
            },
            "contactInfo": {
              "email": "jay.mosley@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=68",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=068",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=068",
                "https://house.mo.gov/MemberDetails.aspx?district=068"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-68-lower-ocd-person-3c4ba4e7-c000-4c5b-b6ab-266396bea4e9": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/f436aa10-c006-4128-9748-050bf50c4e21",
            "firstName": "Adam",
            "lastName": "Schnelting",
            "fullName": "Adam Schnelting",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2087",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-MO-69-lower-ocd-person-f436aa10-c006-4128-9748-050bf50c4e21",
              "builtID": "mo-lower-69",
              "externalID": "ocd-person/f436aa10-c006-4128-9748-050bf50c4e21",
              "geometry": null
            },
            "contactInfo": {
              "email": "adam.schnelting@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=104",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=104",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=069",
                "https://house.mo.gov/MemberDetails.aspx?district=069"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-69-lower-ocd-person-f436aa10-c006-4128-9748-050bf50c4e21": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/26d2c9d5-8aa2-43ab-9aed-e6b3f42e3cbf",
            "firstName": "Gretchen",
            "lastName": "Bangert",
            "fullName": "Gretchen Bangert",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1908",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-MO-70-lower-ocd-person-26d2c9d5-8aa2-43ab-9aed-e6b3f42e3cbf",
              "builtID": "mo-lower-70",
              "externalID": "ocd-person/26d2c9d5-8aa2-43ab-9aed-e6b3f42e3cbf",
              "geometry": null
            },
            "contactInfo": {
              "email": "gretchen.bangert@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=69",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=069",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=070",
                "https://house.mo.gov/MemberDetails.aspx?district=070"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-70-lower-ocd-person-26d2c9d5-8aa2-43ab-9aed-e6b3f42e3cbf": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/6c3609dd-62d2-4030-9b24-7b5d49a16677",
            "firstName": "LaDonna",
            "lastName": "Appelbaum",
            "fullName": "LaDonna Appelbaum",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2057",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-MO-71-lower-ocd-person-6c3609dd-62d2-4030-9b24-7b5d49a16677",
              "builtID": "mo-lower-71",
              "externalID": "ocd-person/6c3609dd-62d2-4030-9b24-7b5d49a16677",
              "geometry": null
            },
            "contactInfo": {
              "email": "ladonna.appelbaum@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=71",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=071",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=071",
                "https://house.mo.gov/MemberDetails.aspx?district=071"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-71-lower-ocd-person-6c3609dd-62d2-4030-9b24-7b5d49a16677": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/c2ccbec5-9ad2-4210-9029-3c9968b72d2f",
            "firstName": "Douglas Thomas",
            "lastName": "Clemens",
            "fullName": "Doug Clemens",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2060",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-MO-72-lower-ocd-person-c2ccbec5-9ad2-4210-9029-3c9968b72d2f",
              "builtID": "mo-lower-72",
              "externalID": "ocd-person/c2ccbec5-9ad2-4210-9029-3c9968b72d2f",
              "geometry": null
            },
            "contactInfo": {
              "email": "doug.clemens@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=72",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=072",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=072",
                "https://house.mo.gov/MemberDetails.aspx?district=072"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-72-lower-ocd-person-c2ccbec5-9ad2-4210-9029-3c9968b72d2f": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/e0d1f714-8603-40c6-8c3e-04934648caa4",
            "firstName": "Raychel Crystal",
            "lastName": "Proudie",
            "fullName": "Raychel Proudie",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2099",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-MO-73-lower-ocd-person-e0d1f714-8603-40c6-8c3e-04934648caa4",
              "builtID": "mo-lower-73",
              "externalID": "ocd-person/e0d1f714-8603-40c6-8c3e-04934648caa4",
              "geometry": null
            },
            "contactInfo": {
              "email": "raychel.proudie@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=73",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=073",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=073",
                "https://house.mo.gov/MemberDetails.aspx?district=073"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-73-lower-ocd-person-e0d1f714-8603-40c6-8c3e-04934648caa4": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/da41c492-2d37-45c4-9d90-a48b56568f2e",
            "firstName": "Kevin Lamar",
            "lastName": "Windham",
            "fullName": "Kevin Windham",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2091",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-MO-74-lower-ocd-person-da41c492-2d37-45c4-9d90-a48b56568f2e",
              "builtID": "mo-lower-74",
              "externalID": "ocd-person/da41c492-2d37-45c4-9d90-a48b56568f2e",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.windhamjr@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=85",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=085",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=074",
                "https://house.mo.gov/MemberDetails.aspx?district=074"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-74-lower-ocd-person-da41c492-2d37-45c4-9d90-a48b56568f2e": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/de7efb99-6dba-4e44-96f5-02753676e61c",
            "firstName": "Alan Jerome",
            "lastName": "Gray",
            "fullName": "Alan Gray",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1934",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-MO-75-lower-ocd-person-de7efb99-6dba-4e44-96f5-02753676e61c",
              "builtID": "mo-lower-75",
              "externalID": "ocd-person/de7efb99-6dba-4e44-96f5-02753676e61c",
              "geometry": null
            },
            "contactInfo": {
              "email": "alan.gray@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=75",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=075",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=075",
                "https://house.mo.gov/MemberDetails.aspx?district=075"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-75-lower-ocd-person-de7efb99-6dba-4e44-96f5-02753676e61c": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/500e226f-ea79-4915-ab13-a11ab4d9e48e",
            "firstName": "Marlon Shepard",
            "lastName": "Anderson",
            "fullName": "Marlon Anderson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2276",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-MO-76-lower-ocd-person-500e226f-ea79-4915-ab13-a11ab4d9e48e",
              "builtID": "mo-lower-76",
              "externalID": "ocd-person/500e226f-ea79-4915-ab13-a11ab4d9e48e",
              "geometry": null
            },
            "contactInfo": {
              "email": "marlon.anderson@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=076",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=076",
                "https://house.mo.gov/MemberDetails.aspx?district=076"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-76-lower-ocd-person-500e226f-ea79-4915-ab13-a11ab4d9e48e": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/6b417baa-36e5-4928-bb3d-b6b8f2cc95dd",
            "firstName": "Kimberly-Ann",
            "lastName": "Collins",
            "fullName": "Kimberly-Ann Collins",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2277",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-MO-77-lower-ocd-person-6b417baa-36e5-4928-bb3d-b6b8f2cc95dd",
              "builtID": "mo-lower-77",
              "externalID": "ocd-person/6b417baa-36e5-4928-bb3d-b6b8f2cc95dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "kimberlyann.collins@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=077",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=077",
                "https://house.mo.gov/MemberDetails.aspx?district=077"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-77-lower-ocd-person-6b417baa-36e5-4928-bb3d-b6b8f2cc95dd": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/24c9a1f1-0023-4733-aa18-80f252645723",
            "firstName": "LaKeySha Amr",
            "lastName": "Frazier-Bosley",
            "fullName": "LaKeySha Bosley",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2133",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-MO-79-lower-ocd-person-24c9a1f1-0023-4733-aa18-80f252645723",
              "builtID": "mo-lower-79",
              "externalID": "ocd-person/24c9a1f1-0023-4733-aa18-80f252645723",
              "geometry": null
            },
            "contactInfo": {
              "email": "lakeysha.bosley@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=79",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=079",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=079",
                "https://house.mo.gov/MemberDetails.aspx?district=079"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-79-lower-ocd-person-24c9a1f1-0023-4733-aa18-80f252645723": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/4d3cccd3-57b9-439e-a759-a5d47f1f8861",
            "firstName": "Peter",
            "lastName": "Merideth",
            "fullName": "Peter Merideth",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1920",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-MO-80-lower-ocd-person-4d3cccd3-57b9-439e-a759-a5d47f1f8861",
              "builtID": "mo-lower-80",
              "externalID": "ocd-person/4d3cccd3-57b9-439e-a759-a5d47f1f8861",
              "geometry": null
            },
            "contactInfo": {
              "email": "peter.merideth@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=80",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=080",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=080",
                "https://house.mo.gov/MemberDetails.aspx?district=080"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-80-lower-ocd-person-4d3cccd3-57b9-439e-a759-a5d47f1f8861": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/6ad9db86-5e30-4693-be71-41bacad5b843",
            "firstName": "Steve",
            "lastName": "Butz",
            "fullName": "Steve Butz",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2061",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-MO-81-lower-ocd-person-6ad9db86-5e30-4693-be71-41bacad5b843",
              "builtID": "mo-lower-81",
              "externalID": "ocd-person/6ad9db86-5e30-4693-be71-41bacad5b843",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.butz@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=81",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=081",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=081",
                "https://house.mo.gov/MemberDetails.aspx?district=081"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-81-lower-ocd-person-6ad9db86-5e30-4693-be71-41bacad5b843": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/36ae788a-47cc-46bc-b0f3-e4225020d0d5",
            "firstName": "Donna Cunningham",
            "lastName": "Baringer",
            "fullName": "Donna Baringer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1918",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-MO-82-lower-ocd-person-36ae788a-47cc-46bc-b0f3-e4225020d0d5",
              "builtID": "mo-lower-82",
              "externalID": "ocd-person/36ae788a-47cc-46bc-b0f3-e4225020d0d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "donna.baringer@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=82",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=082",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=082",
                "https://house.mo.gov/MemberDetails.aspx?district=082"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-82-lower-ocd-person-36ae788a-47cc-46bc-b0f3-e4225020d0d5": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/30082bd2-dc22-482c-9874-dadd73b6c1cd",
            "firstName": "Sarah",
            "lastName": "Unsicker",
            "fullName": "Sarah Unsicker",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1923",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-MO-83-lower-ocd-person-30082bd2-dc22-482c-9874-dadd73b6c1cd",
              "builtID": "mo-lower-83",
              "externalID": "ocd-person/30082bd2-dc22-482c-9874-dadd73b6c1cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "sarah.unsicker@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=91",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=091",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=083",
                "https://house.mo.gov/MemberDetails.aspx?district=083"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-83-lower-ocd-person-30082bd2-dc22-482c-9874-dadd73b6c1cd": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/4a6e4da5-0cbd-4033-89a3-5bd749b37108",
            "firstName": "Delbret",
            "lastName": "Taylor",
            "fullName": "Del Taylor",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2400",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-MO-84-lower-ocd-person-4a6e4da5-0cbd-4033-89a3-5bd749b37108",
              "builtID": "mo-lower-84",
              "externalID": "ocd-person/4a6e4da5-0cbd-4033-89a3-5bd749b37108",
              "geometry": null
            },
            "contactInfo": {
              "email": "del.taylor@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=084",
                "https://house.mo.gov/MemberDetails.aspx?district=084"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-84-lower-ocd-person-4a6e4da5-0cbd-4033-89a3-5bd749b37108": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/6f65a218-7bef-4fa3-b86c-907ed92296d8",
            "firstName": "Yolonda",
            "lastName": "Fountain-Henderson",
            "fullName": "Yolonda Fountain-Henderson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2385",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-MO-85-lower-ocd-person-6f65a218-7bef-4fa3-b86c-907ed92296d8",
              "builtID": "mo-lower-85",
              "externalID": "ocd-person/6f65a218-7bef-4fa3-b86c-907ed92296d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "yolonda.fountainhend@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=085",
                "https://house.mo.gov/MemberDetails.aspx?district=085"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-85-lower-ocd-person-6f65a218-7bef-4fa3-b86c-907ed92296d8": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/16ede933-ba44-4da8-9371-e72ee885758b",
            "firstName": "Joseph",
            "lastName": "Adams",
            "fullName": "Joe Adams",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1780",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-MO-86-lower-ocd-person-16ede933-ba44-4da8-9371-e72ee885758b",
              "builtID": "mo-lower-86",
              "externalID": "ocd-person/16ede933-ba44-4da8-9371-e72ee885758b",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.adams@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=86",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=086",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=086",
                "https://house.mo.gov/MemberDetails.aspx?district=086"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-86-lower-ocd-person-16ede933-ba44-4da8-9371-e72ee885758b": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/53433938-7922-4e1c-b1bf-5943b8186751",
            "firstName": "Paula",
            "lastName": "Brown",
            "fullName": "Paula Brown",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2053",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-MO-87-lower-ocd-person-53433938-7922-4e1c-b1bf-5943b8186751",
              "builtID": "mo-lower-87",
              "externalID": "ocd-person/53433938-7922-4e1c-b1bf-5943b8186751",
              "geometry": null
            },
            "contactInfo": {
              "email": "paula.brown@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=70",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=070",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=087",
                "https://house.mo.gov/MemberDetails.aspx?district=087"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-87-lower-ocd-person-53433938-7922-4e1c-b1bf-5943b8186751": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/c47d84db-02de-4687-bd5b-c6dbc71cc8c4",
            "firstName": "Holly M.",
            "lastName": "Jones",
            "fullName": "Holly Jones",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2388",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-MO-88-lower-ocd-person-c47d84db-02de-4687-bd5b-c6dbc71cc8c4",
              "builtID": "mo-lower-88",
              "externalID": "ocd-person/c47d84db-02de-4687-bd5b-c6dbc71cc8c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "holly.jones@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=088",
                "https://house.mo.gov/MemberDetails.aspx?district=088"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-88-lower-ocd-person-c47d84db-02de-4687-bd5b-c6dbc71cc8c4": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/66b082b9-57c4-43a3-b25b-d59ac8648601",
            "firstName": "Dean",
            "lastName": "Plocher",
            "fullName": "Dean Plocher",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1873",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-MO-89-lower-ocd-person-66b082b9-57c4-43a3-b25b-d59ac8648601",
              "builtID": "mo-lower-89",
              "externalID": "ocd-person/66b082b9-57c4-43a3-b25b-d59ac8648601",
              "geometry": null
            },
            "contactInfo": {
              "email": "dean.plocher@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=89",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=089",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=089",
                "https://house.mo.gov/MemberDetails.aspx?district=089"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-89-lower-ocd-person-66b082b9-57c4-43a3-b25b-d59ac8648601": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/1e3ee7f1-e867-4ca3-b49f-19b6fd7442e6",
            "firstName": "Barbara",
            "lastName": "Phifer",
            "fullName": "Barbara Phifer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2270",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-MO-90-lower-ocd-person-1e3ee7f1-e867-4ca3-b49f-19b6fd7442e6",
              "builtID": "mo-lower-90",
              "externalID": "ocd-person/1e3ee7f1-e867-4ca3-b49f-19b6fd7442e6",
              "geometry": null
            },
            "contactInfo": {
              "email": "barbara.phifer@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=090",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=090",
                "https://house.mo.gov/MemberDetails.aspx?district=090"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-90-lower-ocd-person-1e3ee7f1-e867-4ca3-b49f-19b6fd7442e6": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/0f113187-3958-449e-9c4e-3e81390c1dd4",
            "firstName": "Jo",
            "lastName": "Doll",
            "fullName": "Jo Doll",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2224",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-MO-91-lower-ocd-person-0f113187-3958-449e-9c4e-3e81390c1dd4",
              "builtID": "mo-lower-91",
              "externalID": "ocd-person/0f113187-3958-449e-9c4e-3e81390c1dd4",
              "geometry": null
            },
            "contactInfo": {
              "email": "jo.doll@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=083",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=091",
                "https://house.mo.gov/MemberDetails.aspx?district=091"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-91-lower-ocd-person-0f113187-3958-449e-9c4e-3e81390c1dd4": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/24dc0503-f4cf-48b1-afdf-e57157bafec3",
            "firstName": "Michael",
            "lastName": "Burton",
            "fullName": "Michael Burton",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2225",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-MO-92-lower-ocd-person-24dc0503-f4cf-48b1-afdf-e57157bafec3",
              "builtID": "mo-lower-92",
              "externalID": "ocd-person/24dc0503-f4cf-48b1-afdf-e57157bafec3",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.burton@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=092",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=092",
                "https://house.mo.gov/MemberDetails.aspx?district=092"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-92-lower-ocd-person-24dc0503-f4cf-48b1-afdf-e57157bafec3": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/d29468e7-eb61-45e7-89ad-4dfb93a0baf2",
            "firstName": "Bridget Walsh",
            "lastName": "Moore",
            "fullName": "Bridget Moore",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2248",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-MO-93-lower-ocd-person-d29468e7-eb61-45e7-89ad-4dfb93a0baf2",
              "builtID": "mo-lower-93",
              "externalID": "ocd-person/d29468e7-eb61-45e7-89ad-4dfb93a0baf2",
              "geometry": null
            },
            "contactInfo": {
              "email": "bridget.walshmoore@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=093",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=093",
                "https://house.mo.gov/MemberDetails.aspx?district=093"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-93-lower-ocd-person-d29468e7-eb61-45e7-89ad-4dfb93a0baf2": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/06e17e6f-a17c-4654-9ead-d0aa98a95110",
            "firstName": "Jim",
            "lastName": "Murphy",
            "fullName": "Jim Murphy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2063",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-MO-94-lower-ocd-person-06e17e6f-a17c-4654-9ead-d0aa98a95110",
              "builtID": "mo-lower-94",
              "externalID": "ocd-person/06e17e6f-a17c-4654-9ead-d0aa98a95110",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.murphy@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=94",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=094",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=094",
                "https://house.mo.gov/MemberDetails.aspx?district=094"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-94-lower-ocd-person-06e17e6f-a17c-4654-9ead-d0aa98a95110": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/538f6002-cdea-4972-9f8e-50effc9fb84f",
            "firstName": "Michael",
            "lastName": "O'Donnell",
            "fullName": "Michael O'Donnell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2083",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-MO-95-lower-ocd-person-538f6002-cdea-4972-9f8e-50effc9fb84f",
              "builtID": "mo-lower-95",
              "externalID": "ocd-person/538f6002-cdea-4972-9f8e-50effc9fb84f",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.odonnell@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=95",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=095",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=095",
                "https://house.mo.gov/MemberDetails.aspx?district=095"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-95-lower-ocd-person-538f6002-cdea-4972-9f8e-50effc9fb84f": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/8f5928b4-5528-4506-8075-4e69c5864b42",
            "firstName": "Brad",
            "lastName": "Christ",
            "fullName": "Brad Christ",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2374",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-MO-96-lower-ocd-person-8f5928b4-5528-4506-8075-4e69c5864b42",
              "builtID": "mo-lower-96",
              "externalID": "ocd-person/8f5928b4-5528-4506-8075-4e69c5864b42",
              "geometry": null
            },
            "contactInfo": {
              "email": "brad.christ@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=096",
                "https://house.mo.gov/MemberDetails.aspx?district=096"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-96-lower-ocd-person-8f5928b4-5528-4506-8075-4e69c5864b42": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/6db49039-f2b0-42ea-990d-92ffef2494c3",
            "firstName": "David",
            "lastName": "Casteel",
            "fullName": "David Casteel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2383",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-MO-97-lower-ocd-person-6db49039-f2b0-42ea-990d-92ffef2494c3",
              "builtID": "mo-lower-97",
              "externalID": "ocd-person/6db49039-f2b0-42ea-990d-92ffef2494c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.casteel@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=097",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=97"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-97-lower-ocd-person-6db49039-f2b0-42ea-990d-92ffef2494c3": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/3135abbd-6e12-4847-9b19-e2a57337724a",
            "firstName": "Deb",
            "lastName": "Lavender",
            "fullName": "Deb Lavender",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1786",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-MO-98-lower-ocd-person-3135abbd-6e12-4847-9b19-e2a57337724a",
              "builtID": "mo-lower-98",
              "externalID": "ocd-person/3135abbd-6e12-4847-9b19-e2a57337724a",
              "geometry": null
            },
            "contactInfo": {
              "email": "deb.lavender@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=098",
                "https://house.mo.gov/MemberDetails.aspx?district=098"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-98-lower-ocd-person-3135abbd-6e12-4847-9b19-e2a57337724a": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/b93bdb73-001b-43ed-a436-99ad2efe4937",
            "firstName": "Ian",
            "lastName": "Mackey",
            "fullName": "Ian Mackey",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2080",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-MO-99-lower-ocd-person-b93bdb73-001b-43ed-a436-99ad2efe4937",
              "builtID": "mo-lower-99",
              "externalID": "ocd-person/b93bdb73-001b-43ed-a436-99ad2efe4937",
              "geometry": null
            },
            "contactInfo": {
              "email": "ian.mackey@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=87",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=087",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=099",
                "https://house.mo.gov/MemberDetails.aspx?district=099"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-99-lower-ocd-person-b93bdb73-001b-43ed-a436-99ad2efe4937": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/4d403f59-65f6-484f-9aeb-fe05380d4bcf",
            "firstName": "Philip A.",
            "lastName": "Oehlerking",
            "fullName": "Philip Oehlerking",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2371",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-MO-100-lower-ocd-person-4d403f59-65f6-484f-9aeb-fe05380d4bcf",
              "builtID": "mo-lower-100",
              "externalID": "ocd-person/4d403f59-65f6-484f-9aeb-fe05380d4bcf",
              "geometry": null
            },
            "contactInfo": {
              "email": "philip.oehlerking@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=100"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-100-lower-ocd-person-4d403f59-65f6-484f-9aeb-fe05380d4bcf": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/1f082953-c9f5-4a72-a281-cdb9fe4bda5e",
            "firstName": "Ben Kumar",
            "lastName": "Keathley",
            "fullName": "Ben Keathley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2389",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-MO-101-lower-ocd-person-1f082953-c9f5-4a72-a281-cdb9fe4bda5e",
              "builtID": "mo-lower-101",
              "externalID": "ocd-person/1f082953-c9f5-4a72-a281-cdb9fe4bda5e",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.keathley@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=101",
                "https://house.mo.gov/MemberDetails.aspx?year=2024&code=R&district=101"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-101-lower-ocd-person-1f082953-c9f5-4a72-a281-cdb9fe4bda5e": 0
        }
      },
      "102": {
        "members": [
          {
            "API_ID": "ocd-person/4209f53e-18f3-4c9e-8183-caa385c319ec",
            "firstName": "Richard",
            "lastName": "West",
            "fullName": "Richard West",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2245",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "102",
              "chamber": "lower",
              "hashID": "SLDL-MO-102-lower-ocd-person-4209f53e-18f3-4c9e-8183-caa385c319ec",
              "builtID": "mo-lower-102",
              "externalID": "ocd-person/4209f53e-18f3-4c9e-8183-caa385c319ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.west@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=063",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=102",
                "https://house.mo.gov/MemberDetails.aspx?district=102"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-102-lower-ocd-person-4209f53e-18f3-4c9e-8183-caa385c319ec": 0
        }
      },
      "103": {
        "members": [
          {
            "API_ID": "ocd-person/49609474-e343-4c1d-8e6b-677f31c228b7",
            "firstName": "Dave",
            "lastName": "Hinman",
            "fullName": "Dave Hinman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2357",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "103",
              "chamber": "lower",
              "hashID": "SLDL-MO-103-lower-ocd-person-49609474-e343-4c1d-8e6b-677f31c228b7",
              "builtID": "mo-lower-103",
              "externalID": "ocd-person/49609474-e343-4c1d-8e6b-677f31c228b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "dave.hinman@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=103"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-103-lower-ocd-person-49609474-e343-4c1d-8e6b-677f31c228b7": 0
        }
      },
      "104": {
        "members": [
          {
            "API_ID": "ocd-person/0ec7ac7b-bbef-4349-a840-55682097e171",
            "firstName": "Philip J.",
            "lastName": "Christofanelli",
            "fullName": "Phil Christofanelli",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1914",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "104",
              "chamber": "lower",
              "hashID": "SLDL-MO-104-lower-ocd-person-0ec7ac7b-bbef-4349-a840-55682097e171",
              "builtID": "mo-lower-104",
              "externalID": "ocd-person/0ec7ac7b-bbef-4349-a840-55682097e171",
              "geometry": null
            },
            "contactInfo": {
              "email": "phil.christofanelli@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=105",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=105",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=104",
                "https://house.mo.gov/MemberDetails.aspx?district=104"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-104-lower-ocd-person-0ec7ac7b-bbef-4349-a840-55682097e171": 0
        }
      },
      "105": {
        "members": [
          {
            "API_ID": "ocd-person/0035304c-e314-4d57-8fc8-addf19b16111",
            "firstName": "Adam",
            "lastName": "Schwadron",
            "fullName": "Adam Schwadron",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2226",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "105",
              "chamber": "lower",
              "hashID": "SLDL-MO-105-lower-ocd-person-0035304c-e314-4d57-8fc8-addf19b16111",
              "builtID": "mo-lower-105",
              "externalID": "ocd-person/0035304c-e314-4d57-8fc8-addf19b16111",
              "geometry": null
            },
            "contactInfo": {
              "email": "adam.schwadron@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=105",
                "https://house.mo.gov/MemberDetails.aspx?district=105"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-105-lower-ocd-person-0035304c-e314-4d57-8fc8-addf19b16111": 0
        }
      },
      "106": {
        "members": [
          {
            "API_ID": "ocd-person/350514c2-7875-44c4-8ef3-20da25540793",
            "firstName": "Travis",
            "lastName": "Wilson",
            "fullName": "Travis Wilson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2373",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "106",
              "chamber": "lower",
              "hashID": "SLDL-MO-106-lower-ocd-person-350514c2-7875-44c4-8ef3-20da25540793",
              "builtID": "mo-lower-106",
              "externalID": "ocd-person/350514c2-7875-44c4-8ef3-20da25540793",
              "geometry": null
            },
            "contactInfo": {
              "email": "travis.wilson@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=106",
                "https://house.mo.gov/MemberDetails.aspx?district=106"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-106-lower-ocd-person-350514c2-7875-44c4-8ef3-20da25540793": 0
        }
      },
      "107": {
        "members": [
          {
            "API_ID": "ocd-person/661f49f4-e075-491e-aad8-4dde65ecb6df",
            "firstName": "Mark",
            "lastName": "Matthiesen",
            "fullName": "Mark Matthiesen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1937",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "107",
              "chamber": "lower",
              "hashID": "SLDL-MO-107-lower-ocd-person-661f49f4-e075-491e-aad8-4dde65ecb6df",
              "builtID": "mo-lower-107",
              "externalID": "ocd-person/661f49f4-e075-491e-aad8-4dde65ecb6df",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.matthiesen@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=107",
                "https://house.mo.gov/MemberDetails.aspx?district=107"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-107-lower-ocd-person-661f49f4-e075-491e-aad8-4dde65ecb6df": 0
        }
      },
      "108": {
        "members": [
          {
            "API_ID": "ocd-person/7a1baa83-5ea6-4b5c-b116-041b5a451048",
            "firstName": "Justin",
            "lastName": "Hicks",
            "fullName": "Justin Hicks",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2372",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "108",
              "chamber": "lower",
              "hashID": "SLDL-MO-108-lower-ocd-person-7a1baa83-5ea6-4b5c-b116-041b5a451048",
              "builtID": "mo-lower-108",
              "externalID": "ocd-person/7a1baa83-5ea6-4b5c-b116-041b5a451048",
              "geometry": null
            },
            "contactInfo": {
              "email": "justin.hicks@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=108",
                "https://house.mo.gov/MemberDetails.aspx?district=108"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-108-lower-ocd-person-7a1baa83-5ea6-4b5c-b116-041b5a451048": 0
        }
      },
      "109": {
        "members": [
          {
            "API_ID": "ocd-person/523124a7-8c9d-4da6-bec1-5845414ac6e7",
            "firstName": "Kyle",
            "lastName": "Marquart",
            "fullName": "Kyle Marquart",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2368",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "109",
              "chamber": "lower",
              "hashID": "SLDL-MO-109-lower-ocd-person-523124a7-8c9d-4da6-bec1-5845414ac6e7",
              "builtID": "mo-lower-109",
              "externalID": "ocd-person/523124a7-8c9d-4da6-bec1-5845414ac6e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "kyle.marquart@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=109",
                "https://house.mo.gov/MemberDetails.aspx?district=109"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-109-lower-ocd-person-523124a7-8c9d-4da6-bec1-5845414ac6e7": 0
        }
      },
      "110": {
        "members": [
          {
            "API_ID": "ocd-person/3d236739-37ea-4cdb-8f3b-513b12a47d16",
            "firstName": "Justin",
            "lastName": "Sparks",
            "fullName": "Justin Sparks",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2398",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "110",
              "chamber": "lower",
              "hashID": "SLDL-MO-110-lower-ocd-person-3d236739-37ea-4cdb-8f3b-513b12a47d16",
              "builtID": "mo-lower-110",
              "externalID": "ocd-person/3d236739-37ea-4cdb-8f3b-513b12a47d16",
              "geometry": null
            },
            "contactInfo": {
              "email": "justin.sparks@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=110",
                "https://house.mo.gov/MemberDetails.aspx?district=110"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-110-lower-ocd-person-3d236739-37ea-4cdb-8f3b-513b12a47d16": 0
        }
      },
      "111": {
        "members": [
          {
            "API_ID": "ocd-person/7e2f9d42-a658-4eb7-a770-98a328f95e70",
            "firstName": "Gary",
            "lastName": "Bonacker",
            "fullName": "Gary Bonacker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2380",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "111",
              "chamber": "lower",
              "hashID": "SLDL-MO-111-lower-ocd-person-7e2f9d42-a658-4eb7-a770-98a328f95e70",
              "builtID": "mo-lower-111",
              "externalID": "ocd-person/7e2f9d42-a658-4eb7-a770-98a328f95e70",
              "geometry": null
            },
            "contactInfo": {
              "email": "gary.bonacker@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=111",
                "https://house.mo.gov/MemberDetails.aspx?district=111"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-111-lower-ocd-person-7e2f9d42-a658-4eb7-a770-98a328f95e70": 0
        }
      },
      "112": {
        "members": [
          {
            "API_ID": "ocd-person/64a3bd7d-7dc8-4ce6-bd30-6101b2b7926e",
            "firstName": "Renee",
            "lastName": "Reuter",
            "fullName": "Renee Reuter",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2396",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "112",
              "chamber": "lower",
              "hashID": "SLDL-MO-112-lower-ocd-person-64a3bd7d-7dc8-4ce6-bd30-6101b2b7926e",
              "builtID": "mo-lower-112",
              "externalID": "ocd-person/64a3bd7d-7dc8-4ce6-bd30-6101b2b7926e",
              "geometry": null
            },
            "contactInfo": {
              "email": "renee.reuter@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=112",
                "https://house.mo.gov/MemberDetails.aspx?district=112"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-112-lower-ocd-person-64a3bd7d-7dc8-4ce6-bd30-6101b2b7926e": 0
        }
      },
      "113": {
        "members": [
          {
            "API_ID": "ocd-person/1d96c354-2527-4449-9b40-36bb78fe2cb7",
            "firstName": "Phil",
            "lastName": "Amato",
            "fullName": "Phil Amato",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2378",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "113",
              "chamber": "lower",
              "hashID": "SLDL-MO-113-lower-ocd-person-1d96c354-2527-4449-9b40-36bb78fe2cb7",
              "builtID": "mo-lower-113",
              "externalID": "ocd-person/1d96c354-2527-4449-9b40-36bb78fe2cb7",
              "geometry": null
            },
            "contactInfo": {
              "email": "phil.amato@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=113",
                "https://house.mo.gov/MemberDetails.aspx?district=113"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-113-lower-ocd-person-1d96c354-2527-4449-9b40-36bb78fe2cb7": 0
        }
      },
      "114": {
        "members": [
          {
            "API_ID": "ocd-person/f7a04349-8ead-478a-880e-8d87aa5e3192",
            "firstName": "Ken",
            "lastName": "Waller",
            "fullName": "Ken Waller",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2401",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "114",
              "chamber": "lower",
              "hashID": "SLDL-MO-114-lower-ocd-person-f7a04349-8ead-478a-880e-8d87aa5e3192",
              "builtID": "mo-lower-114",
              "externalID": "ocd-person/f7a04349-8ead-478a-880e-8d87aa5e3192",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.waller@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=114",
                "https://house.mo.gov/MemberDetails.aspx?district=114"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-114-lower-ocd-person-f7a04349-8ead-478a-880e-8d87aa5e3192": 0
        }
      },
      "115": {
        "members": [
          {
            "API_ID": "ocd-person/1803795a-4847-429f-afd8-c478f9f751d2",
            "firstName": "Cyndi",
            "lastName": "Buchheit-Courtway",
            "fullName": "Cyndi Buchheit-Courtway",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2227",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "115",
              "chamber": "lower",
              "hashID": "SLDL-MO-115-lower-ocd-person-1803795a-4847-429f-afd8-c478f9f751d2",
              "builtID": "mo-lower-115",
              "externalID": "ocd-person/1803795a-4847-429f-afd8-c478f9f751d2",
              "geometry": null
            },
            "contactInfo": {
              "email": "cyndi.buchheitcourtway@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=115",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=115",
                "https://house.mo.gov/MemberDetails.aspx?district=115"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-115-lower-ocd-person-1803795a-4847-429f-afd8-c478f9f751d2": 0
        }
      },
      "116": {
        "members": [
          {
            "API_ID": "ocd-person/616dd247-4438-44de-97b4-cd3a5a3e554d",
            "firstName": "Dale L.",
            "lastName": "Wright",
            "fullName": "Dale Wright",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2095",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "116",
              "chamber": "lower",
              "hashID": "SLDL-MO-116-lower-ocd-person-616dd247-4438-44de-97b4-cd3a5a3e554d",
              "builtID": "mo-lower-116",
              "externalID": "ocd-person/616dd247-4438-44de-97b4-cd3a5a3e554d",
              "geometry": null
            },
            "contactInfo": {
              "email": "dale.wright@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=116",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=116",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=116",
                "https://house.mo.gov/MemberDetails.aspx?district=116"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-116-lower-ocd-person-616dd247-4438-44de-97b4-cd3a5a3e554d": 0
        }
      },
      "117": {
        "members": [
          {
            "API_ID": "ocd-person/bfad4ef7-fad2-46e6-8f12-f8ea9feaa0cb",
            "firstName": "Mike",
            "lastName": "Henderson",
            "fullName": "Mike Henderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1944",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "117",
              "chamber": "lower",
              "hashID": "SLDL-MO-117-lower-ocd-person-bfad4ef7-fad2-46e6-8f12-f8ea9feaa0cb",
              "builtID": "mo-lower-117",
              "externalID": "ocd-person/bfad4ef7-fad2-46e6-8f12-f8ea9feaa0cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.henderson@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=117",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=117",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=117",
                "https://house.mo.gov/MemberDetails.aspx?district=117"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-117-lower-ocd-person-bfad4ef7-fad2-46e6-8f12-f8ea9feaa0cb": 0
        }
      },
      "118": {
        "members": [
          {
            "API_ID": "ocd-person/1a644e87-8691-4e86-af73-6a2af0f56c82",
            "firstName": "Michael",
            "lastName": "McGirl",
            "fullName": "Mike McGirl",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2065",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "118",
              "chamber": "lower",
              "hashID": "SLDL-MO-118-lower-ocd-person-1a644e87-8691-4e86-af73-6a2af0f56c82",
              "builtID": "mo-lower-118",
              "externalID": "ocd-person/1a644e87-8691-4e86-af73-6a2af0f56c82",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.mcgirl@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=118",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=118",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=118",
                "https://house.mo.gov/MemberDetails.aspx?district=118"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-118-lower-ocd-person-1a644e87-8691-4e86-af73-6a2af0f56c82": 0
        }
      },
      "119": {
        "members": [
          {
            "API_ID": "ocd-person/e0e9f47c-af7c-45f3-9d7e-1a158a19b362",
            "firstName": "Brad",
            "lastName": "Banderman",
            "fullName": "Brad Banderman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2379",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "119",
              "chamber": "lower",
              "hashID": "SLDL-MO-119-lower-ocd-person-e0e9f47c-af7c-45f3-9d7e-1a158a19b362",
              "builtID": "mo-lower-119",
              "externalID": "ocd-person/e0e9f47c-af7c-45f3-9d7e-1a158a19b362",
              "geometry": null
            },
            "contactInfo": {
              "email": "brad.banderman@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=119",
                "https://house.mo.gov/MemberDetails.aspx?district=119"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-119-lower-ocd-person-e0e9f47c-af7c-45f3-9d7e-1a158a19b362": 0
        }
      },
      "120": {
        "members": [
          {
            "API_ID": "ocd-person/1b74840b-dd15-4dad-8c4d-60257b6edbe3",
            "firstName": "Ron",
            "lastName": "Copeland",
            "fullName": "Ron Copeland",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2236",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "120",
              "chamber": "lower",
              "hashID": "SLDL-MO-120-lower-ocd-person-1b74840b-dd15-4dad-8c4d-60257b6edbe3",
              "builtID": "mo-lower-120",
              "externalID": "ocd-person/1b74840b-dd15-4dad-8c4d-60257b6edbe3",
              "geometry": null
            },
            "contactInfo": {
              "email": "ron.copeland@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=143",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=120",
                "https://house.mo.gov/MemberDetails.aspx?district=120"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-120-lower-ocd-person-1b74840b-dd15-4dad-8c4d-60257b6edbe3": 0
        }
      },
      "121": {
        "members": [
          {
            "API_ID": "ocd-person/6b7c5c9c-05ba-4a2e-8f23-117edbf2969b",
            "firstName": "Bill",
            "lastName": "Hardwick",
            "fullName": "Bill Hardwick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2253",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "121",
              "chamber": "lower",
              "hashID": "SLDL-MO-121-lower-ocd-person-6b7c5c9c-05ba-4a2e-8f23-117edbf2969b",
              "builtID": "mo-lower-121",
              "externalID": "ocd-person/6b7c5c9c-05ba-4a2e-8f23-117edbf2969b",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.hardwick@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=121",
                "https://house.mo.gov/MemberDetails.aspx?district=121"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-121-lower-ocd-person-6b7c5c9c-05ba-4a2e-8f23-117edbf2969b": 0
        }
      },
      "122": {
        "members": [
          {
            "API_ID": "ocd-person/ae8a2d25-fbb2-4254-976a-f9dcf0c92c6f",
            "firstName": "Tara",
            "lastName": "Peters",
            "fullName": "Tara Peters",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2394",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "122",
              "chamber": "lower",
              "hashID": "SLDL-MO-122-lower-ocd-person-ae8a2d25-fbb2-4254-976a-f9dcf0c92c6f",
              "builtID": "mo-lower-122",
              "externalID": "ocd-person/ae8a2d25-fbb2-4254-976a-f9dcf0c92c6f",
              "geometry": null
            },
            "contactInfo": {
              "email": "tara.peters@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=122",
                "https://house.mo.gov/MemberDetails.aspx?district=122"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-122-lower-ocd-person-ae8a2d25-fbb2-4254-976a-f9dcf0c92c6f": 0
        }
      },
      "123": {
        "members": [
          {
            "API_ID": "ocd-person/c2153ae3-e9c7-4236-8b49-6985ae164bae",
            "firstName": "Lisa",
            "lastName": "Thomas",
            "fullName": "Lisa Thomas",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2228",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "123",
              "chamber": "lower",
              "hashID": "SLDL-MO-123-lower-ocd-person-c2153ae3-e9c7-4236-8b49-6985ae164bae",
              "builtID": "mo-lower-123",
              "externalID": "ocd-person/c2153ae3-e9c7-4236-8b49-6985ae164bae",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisa.thomas@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=124",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=123",
                "https://house.mo.gov/MemberDetails.aspx?district=123",
                "https://house.mo.gov/MemberDetails.aspx?year=2024&code=R&district=123"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-123-lower-ocd-person-c2153ae3-e9c7-4236-8b49-6985ae164bae": 0
        }
      },
      "124": {
        "members": [
          {
            "API_ID": "ocd-person/af64f0a2-f3ff-4c6d-b24f-62c3941d1da4",
            "firstName": "Donald",
            "lastName": "Mayhew",
            "fullName": "Don Mayhew",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2082",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "124",
              "chamber": "lower",
              "hashID": "SLDL-MO-124-lower-ocd-person-af64f0a2-f3ff-4c6d-b24f-62c3941d1da4",
              "builtID": "mo-lower-124",
              "externalID": "ocd-person/af64f0a2-f3ff-4c6d-b24f-62c3941d1da4",
              "geometry": null
            },
            "contactInfo": {
              "email": "don.mayhew@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=121",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=121",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=124",
                "https://house.mo.gov/MemberDetails.aspx?district=124"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-124-lower-ocd-person-af64f0a2-f3ff-4c6d-b24f-62c3941d1da4": 0
        }
      },
      "125": {
        "members": [
          {
            "API_ID": "ocd-person/0f4f5a2e-bc86-4c2a-9ab1-041d1c844283",
            "firstName": "Dane",
            "lastName": "Diehl",
            "fullName": "Dane Diehl",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2370",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "125",
              "chamber": "lower",
              "hashID": "SLDL-MO-125-lower-ocd-person-0f4f5a2e-bc86-4c2a-9ab1-041d1c844283",
              "builtID": "mo-lower-125",
              "externalID": "ocd-person/0f4f5a2e-bc86-4c2a-9ab1-041d1c844283",
              "geometry": null
            },
            "contactInfo": {
              "email": "dane.diehl@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=125",
                "https://house.mo.gov/MemberDetails.aspx?district=125"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-125-lower-ocd-person-0f4f5a2e-bc86-4c2a-9ab1-041d1c844283": 0
        }
      },
      "126": {
        "members": [
          {
            "API_ID": "ocd-person/49c68d0e-cf5d-4b46-9890-c887a428929e",
            "firstName": "Jim",
            "lastName": "Kalberloh",
            "fullName": "Jim Kalberloh",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2229",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "126",
              "chamber": "lower",
              "hashID": "SLDL-MO-126-lower-ocd-person-49c68d0e-cf5d-4b46-9890-c887a428929e",
              "builtID": "mo-lower-126",
              "externalID": "ocd-person/49c68d0e-cf5d-4b46-9890-c887a428929e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.kalberloh@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=125",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=126",
                "https://house.mo.gov/MemberDetails.aspx?district=126"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-126-lower-ocd-person-49c68d0e-cf5d-4b46-9890-c887a428929e": 0
        }
      },
      "127": {
        "members": [
          {
            "API_ID": "ocd-person/fff43ce1-d209-4556-ba74-32f9ba46d3c0",
            "firstName": "Ann",
            "lastName": "Kelley",
            "fullName": "Ann Kelley",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2051",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "127",
              "chamber": "lower",
              "hashID": "SLDL-MO-127-lower-ocd-person-fff43ce1-d209-4556-ba74-32f9ba46d3c0",
              "builtID": "mo-lower-127",
              "externalID": "ocd-person/fff43ce1-d209-4556-ba74-32f9ba46d3c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "ann.kelley@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=127",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=127",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=127",
                "https://house.mo.gov/memberdetails.aspx?district=127"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-127-lower-ocd-person-fff43ce1-d209-4556-ba74-32f9ba46d3c0": 0
        }
      },
      "128": {
        "members": [
          {
            "API_ID": "ocd-person/82b7dedc-0491-48fe-988e-dd2eaea1037b",
            "firstName": "Mike",
            "lastName": "Stephens",
            "fullName": "Mike Stephens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1903",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "128",
              "chamber": "lower",
              "hashID": "SLDL-MO-128-lower-ocd-person-82b7dedc-0491-48fe-988e-dd2eaea1037b",
              "builtID": "mo-lower-128",
              "externalID": "ocd-person/82b7dedc-0491-48fe-988e-dd2eaea1037b",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.stephens@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=128",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=128",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=128",
                "https://house.mo.gov/MemberDetails.aspx?district=128"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-128-lower-ocd-person-82b7dedc-0491-48fe-988e-dd2eaea1037b": 0
        }
      },
      "129": {
        "members": [
          {
            "API_ID": "ocd-person/1250ea3d-fe5c-46ea-bead-f7249d234e68",
            "firstName": "John",
            "lastName": "Black",
            "fullName": "John Black",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2114",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "129",
              "chamber": "lower",
              "hashID": "SLDL-MO-129-lower-ocd-person-1250ea3d-fe5c-46ea-bead-f7249d234e68",
              "builtID": "mo-lower-129",
              "externalID": "ocd-person/1250ea3d-fe5c-46ea-bead-f7249d234e68",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.black@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=137",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=137",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=129"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-129-lower-ocd-person-1250ea3d-fe5c-46ea-bead-f7249d234e68": 0
        }
      },
      "130": {
        "members": [
          {
            "API_ID": "ocd-person/163f458d-ad80-4bad-9223-69e1f6fec4d2",
            "firstName": "Bishop",
            "lastName": "Davidson",
            "fullName": "Bishop Davidson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2230",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "130",
              "chamber": "lower",
              "hashID": "SLDL-MO-130-lower-ocd-person-163f458d-ad80-4bad-9223-69e1f6fec4d2",
              "builtID": "mo-lower-130",
              "externalID": "ocd-person/163f458d-ad80-4bad-9223-69e1f6fec4d2",
              "geometry": null
            },
            "contactInfo": {
              "email": "bishop.davidson@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=130",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=130",
                "https://house.mo.gov/MemberDetails.aspx?district=130"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-130-lower-ocd-person-163f458d-ad80-4bad-9223-69e1f6fec4d2": 0
        }
      },
      "131": {
        "members": [
          {
            "API_ID": "ocd-person/d9dd5492-e605-4fc6-a18a-8772a7682a08",
            "firstName": "Bill",
            "lastName": "Owen",
            "fullName": "Bill Owen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2231",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "131",
              "chamber": "lower",
              "hashID": "SLDL-MO-131-lower-ocd-person-d9dd5492-e605-4fc6-a18a-8772a7682a08",
              "builtID": "mo-lower-131",
              "externalID": "ocd-person/d9dd5492-e605-4fc6-a18a-8772a7682a08",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.owen@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=131",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=131",
                "https://house.mo.gov/MemberDetails.aspx?district=131"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-131-lower-ocd-person-d9dd5492-e605-4fc6-a18a-8772a7682a08": 0
        }
      },
      "132": {
        "members": [
          {
            "API_ID": "ocd-person/cb8e2f4e-d808-4e2d-9b1c-71a2fa3f2740",
            "firstName": "Crystal M.",
            "lastName": "Quade",
            "fullName": "Crystal Quade",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1919",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "132",
              "chamber": "lower",
              "hashID": "SLDL-MO-132-lower-ocd-person-cb8e2f4e-d808-4e2d-9b1c-71a2fa3f2740",
              "builtID": "mo-lower-132",
              "externalID": "ocd-person/cb8e2f4e-d808-4e2d-9b1c-71a2fa3f2740",
              "geometry": null
            },
            "contactInfo": {
              "email": "crystal.quade@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=132",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=132",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=132",
                "https://house.mo.gov/MemberDetails.aspx?district=132"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-132-lower-ocd-person-cb8e2f4e-d808-4e2d-9b1c-71a2fa3f2740": 0
        }
      },
      "133": {
        "members": [
          {
            "API_ID": "ocd-person/1df6dc2e-0d12-40d6-b368-4d0b3a19ef95",
            "firstName": "Melanie",
            "lastName": "Stinnett",
            "fullName": "Melanie Stinnett",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2399",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "133",
              "chamber": "lower",
              "hashID": "SLDL-MO-133-lower-ocd-person-1df6dc2e-0d12-40d6-b368-4d0b3a19ef95",
              "builtID": "mo-lower-133",
              "externalID": "ocd-person/1df6dc2e-0d12-40d6-b368-4d0b3a19ef95",
              "geometry": null
            },
            "contactInfo": {
              "email": "melanie.stinnett@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=133",
                "https://house.mo.gov/MemberDetails.aspx?district=133"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-133-lower-ocd-person-1df6dc2e-0d12-40d6-b368-4d0b3a19ef95": 0
        }
      },
      "134": {
        "members": [
          {
            "API_ID": "ocd-person/b11b5a8d-08e8-4cf3-802c-42a842102cfa",
            "firstName": "Alex",
            "lastName": "Riley",
            "fullName": "Alex Riley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2232",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "134",
              "chamber": "lower",
              "hashID": "SLDL-MO-134-lower-ocd-person-b11b5a8d-08e8-4cf3-802c-42a842102cfa",
              "builtID": "mo-lower-134",
              "externalID": "ocd-person/b11b5a8d-08e8-4cf3-802c-42a842102cfa",
              "geometry": null
            },
            "contactInfo": {
              "email": "alex.riley@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=134",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=134",
                "https://house.mo.gov/MemberDetails.aspx?district=134"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-134-lower-ocd-person-b11b5a8d-08e8-4cf3-802c-42a842102cfa": 0
        }
      },
      "135": {
        "members": [
          {
            "API_ID": "ocd-person/55b3b80d-f69c-4f9f-aaed-4d1e5cd2634d",
            "firstName": "Betsy",
            "lastName": "Fogle",
            "fullName": "Betsy Fogle",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2233",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "135",
              "chamber": "lower",
              "hashID": "SLDL-MO-135-lower-ocd-person-55b3b80d-f69c-4f9f-aaed-4d1e5cd2634d",
              "builtID": "mo-lower-135",
              "externalID": "ocd-person/55b3b80d-f69c-4f9f-aaed-4d1e5cd2634d",
              "geometry": null
            },
            "contactInfo": {
              "email": "betsy.fogle@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=135",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=135",
                "https://house.mo.gov/memberdetails.aspx?district=135"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-135-lower-ocd-person-55b3b80d-f69c-4f9f-aaed-4d1e5cd2634d": 0
        }
      },
      "136": {
        "members": [
          {
            "API_ID": "ocd-person/9adf60fd-19a0-429c-ab41-5cbc8e6391fc",
            "firstName": "Stephanie",
            "lastName": "Hein",
            "fullName": "Stephanie Hein",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2363",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "136",
              "chamber": "lower",
              "hashID": "SLDL-MO-136-lower-ocd-person-9adf60fd-19a0-429c-ab41-5cbc8e6391fc",
              "builtID": "mo-lower-136",
              "externalID": "ocd-person/9adf60fd-19a0-429c-ab41-5cbc8e6391fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephanie.hein@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=136",
                "https://house.mo.gov/MemberDetails.aspx?district=136"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-136-lower-ocd-person-9adf60fd-19a0-429c-ab41-5cbc8e6391fc": 0
        }
      },
      "137": {
        "members": [
          {
            "API_ID": "ocd-person/1cd15a85-b074-4d67-8fcd-1cbccfc924f4",
            "firstName": "Darin",
            "lastName": "Chappell",
            "fullName": "Darin Chappell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2362",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "137",
              "chamber": "lower",
              "hashID": "SLDL-MO-137-lower-ocd-person-1cd15a85-b074-4d67-8fcd-1cbccfc924f4",
              "builtID": "mo-lower-137",
              "externalID": "ocd-person/1cd15a85-b074-4d67-8fcd-1cbccfc924f4",
              "geometry": null
            },
            "contactInfo": {
              "email": "darin.chappell@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=137",
                "https://house.mo.gov/MemberDetails.aspx?district=137"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-137-lower-ocd-person-1cd15a85-b074-4d67-8fcd-1cbccfc924f4": 0
        }
      },
      "138": {
        "members": [
          {
            "API_ID": "ocd-person/9f6b3ff8-f439-44d0-bdf7-315451e52cb7",
            "firstName": "Brad",
            "lastName": "Hudson",
            "fullName": "Brad Hudson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2081",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "138",
              "chamber": "lower",
              "hashID": "SLDL-MO-138-lower-ocd-person-9f6b3ff8-f439-44d0-bdf7-315451e52cb7",
              "builtID": "mo-lower-138",
              "externalID": "ocd-person/9f6b3ff8-f439-44d0-bdf7-315451e52cb7",
              "geometry": null
            },
            "contactInfo": {
              "email": "brad.hudson@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=138",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=138",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=138",
                "https://house.mo.gov/memberdetails.aspx?district=138"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-138-lower-ocd-person-9f6b3ff8-f439-44d0-bdf7-315451e52cb7": 0
        }
      },
      "139": {
        "members": [
          {
            "API_ID": "ocd-person/b2f744fa-f71d-4865-a704-afcd3d030ff7",
            "firstName": "Bob",
            "lastName": "Titus",
            "fullName": "Bob Titus",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2369",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "139",
              "chamber": "lower",
              "hashID": "SLDL-MO-139-lower-ocd-person-b2f744fa-f71d-4865-a704-afcd3d030ff7",
              "builtID": "mo-lower-139",
              "externalID": "ocd-person/b2f744fa-f71d-4865-a704-afcd3d030ff7",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.titus@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=139",
                "https://house.mo.gov/MemberDetails.aspx?district=139"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-139-lower-ocd-person-b2f744fa-f71d-4865-a704-afcd3d030ff7": 0
        }
      },
      "140": {
        "members": [
          {
            "API_ID": "ocd-person/1235305e-980d-4eb8-8743-8d0d8a455c4e",
            "firstName": "Jamie Ray",
            "lastName": "Gragg",
            "fullName": "Jamie Ray Gragg",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2386",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "140",
              "chamber": "lower",
              "hashID": "SLDL-MO-140-lower-ocd-person-1235305e-980d-4eb8-8743-8d0d8a455c4e",
              "builtID": "mo-lower-140",
              "externalID": "ocd-person/1235305e-980d-4eb8-8743-8d0d8a455c4e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jamieray.gragg@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=140",
                "https://house.mo.gov/MemberDetails.aspx?district=140"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-140-lower-ocd-person-1235305e-980d-4eb8-8743-8d0d8a455c4e": 0
        }
      },
      "141": {
        "members": [
          {
            "API_ID": "ocd-person/69d02db8-824d-40b9-b1ba-6556d6e2c719",
            "firstName": "Hannah",
            "lastName": "Kelly",
            "fullName": "Hannah Kelly",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1927",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "141",
              "chamber": "lower",
              "hashID": "SLDL-MO-141-lower-ocd-person-69d02db8-824d-40b9-b1ba-6556d6e2c719",
              "builtID": "mo-lower-141",
              "externalID": "ocd-person/69d02db8-824d-40b9-b1ba-6556d6e2c719",
              "geometry": null
            },
            "contactInfo": {
              "email": "hannah.kelly@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=141",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=141",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=141",
                "https://house.mo.gov/MemberDetails.aspx?district=141"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-141-lower-ocd-person-69d02db8-824d-40b9-b1ba-6556d6e2c719": 0
        }
      },
      "142": {
        "members": [
          {
            "API_ID": "ocd-person/fa6c1b05-02e8-4c6f-9a84-d1f58885ea04",
            "firstName": "Jeff",
            "lastName": "Knight",
            "fullName": "Jeff Knight",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2020",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "142",
              "chamber": "lower",
              "hashID": "SLDL-MO-142-lower-ocd-person-fa6c1b05-02e8-4c6f-9a84-d1f58885ea04",
              "builtID": "mo-lower-142",
              "externalID": "ocd-person/fa6c1b05-02e8-4c6f-9a84-d1f58885ea04",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.knight@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=129",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=129",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=142",
                "https://house.mo.gov/MemberDetails.aspx?district=142"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-142-lower-ocd-person-fa6c1b05-02e8-4c6f-9a84-d1f58885ea04": 0
        }
      },
      "143": {
        "members": [
          {
            "API_ID": "ocd-person/9c485ac1-4ad5-4466-9392-a9014827ef56",
            "firstName": "Bennie Lee",
            "lastName": "Cook",
            "fullName": "Bennie Cook",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2235",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "143",
              "chamber": "lower",
              "hashID": "SLDL-MO-143-lower-ocd-person-9c485ac1-4ad5-4466-9392-a9014827ef56",
              "builtID": "mo-lower-143",
              "externalID": "ocd-person/9c485ac1-4ad5-4466-9392-a9014827ef56",
              "geometry": null
            },
            "contactInfo": {
              "email": "bennie.cook@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=143",
                "https://house.mo.gov/MemberDetails.aspx?district=143"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-143-lower-ocd-person-9c485ac1-4ad5-4466-9392-a9014827ef56": 0
        }
      },
      "144": {
        "members": [
          {
            "API_ID": "ocd-person/34829e4c-4db2-48e5-bf60-c8011ba802ba",
            "firstName": "Christina Dodson",
            "lastName": "Dinkins",
            "fullName": "Chris Dinkins",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2022",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "144",
              "chamber": "lower",
              "hashID": "SLDL-MO-144-lower-ocd-person-34829e4c-4db2-48e5-bf60-c8011ba802ba",
              "builtID": "mo-lower-144",
              "externalID": "ocd-person/34829e4c-4db2-48e5-bf60-c8011ba802ba",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.dinkins@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=144",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=144",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=144",
                "https://house.mo.gov/MemberDetails.aspx?district=144"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-144-lower-ocd-person-34829e4c-4db2-48e5-bf60-c8011ba802ba": 0
        }
      },
      "145": {
        "members": [
          {
            "API_ID": "ocd-person/6032efc2-0728-421e-b74f-3e72928de7e6",
            "firstName": "Rick",
            "lastName": "Francis",
            "fullName": "Rick Francis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1901",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "145",
              "chamber": "lower",
              "hashID": "SLDL-MO-145-lower-ocd-person-6032efc2-0728-421e-b74f-3e72928de7e6",
              "builtID": "mo-lower-145",
              "externalID": "ocd-person/6032efc2-0728-421e-b74f-3e72928de7e6",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.francis@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=145",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=145",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=145",
                "https://house.mo.gov/MemberDetails.aspx?year=2024&code=R&district=145"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-145-lower-ocd-person-6032efc2-0728-421e-b74f-3e72928de7e6": 0
        }
      },
      "146": {
        "members": [
          {
            "API_ID": "ocd-person/54f9145f-2529-448e-8238-b00d049a782c",
            "firstName": "Barry",
            "lastName": "Hovis",
            "fullName": "Barry Hovis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2129",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "146",
              "chamber": "lower",
              "hashID": "SLDL-MO-146-lower-ocd-person-54f9145f-2529-448e-8238-b00d049a782c",
              "builtID": "mo-lower-146",
              "externalID": "ocd-person/54f9145f-2529-448e-8238-b00d049a782c",
              "geometry": null
            },
            "contactInfo": {
              "email": "barry.hovis@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=146",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=146",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=146",
                "https://house.mo.gov/MemberDetails.aspx?district=146"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-146-lower-ocd-person-54f9145f-2529-448e-8238-b00d049a782c": 0
        }
      },
      "147": {
        "members": [
          {
            "API_ID": "ocd-person/709dba3b-3a48-4429-94e7-fada7faa0d90",
            "firstName": "John",
            "lastName": "Voss",
            "fullName": "John Voss",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2355",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "147",
              "chamber": "lower",
              "hashID": "SLDL-MO-147-lower-ocd-person-709dba3b-3a48-4429-94e7-fada7faa0d90",
              "builtID": "mo-lower-147",
              "externalID": "ocd-person/709dba3b-3a48-4429-94e7-fada7faa0d90",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.voss@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=147",
                "https://house.mo.gov/MemberDetails.aspx?district=147"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-147-lower-ocd-person-709dba3b-3a48-4429-94e7-fada7faa0d90": 0
        }
      },
      "148": {
        "members": [
          {
            "API_ID": "ocd-person/87967de8-0f0d-45d5-9248-2767f9157d7b",
            "firstName": "Jamie",
            "lastName": "Burger",
            "fullName": "Jamie Burger",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2237",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "148",
              "chamber": "lower",
              "hashID": "SLDL-MO-148-lower-ocd-person-87967de8-0f0d-45d5-9248-2767f9157d7b",
              "builtID": "mo-lower-148",
              "externalID": "ocd-person/87967de8-0f0d-45d5-9248-2767f9157d7b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jamie.burger@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=148",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=148",
                "https://house.mo.gov/MemberDetails.aspx?district=148"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-148-lower-ocd-person-87967de8-0f0d-45d5-9248-2767f9157d7b": 0
        }
      },
      "149": {
        "members": [
          {
            "API_ID": "ocd-person/0cb45c52-5b3d-4bfb-a65c-41fd9462e548",
            "firstName": "Donnie",
            "lastName": "Brown",
            "fullName": "Donnie Brown",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2365",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "149",
              "chamber": "lower",
              "hashID": "SLDL-MO-149-lower-ocd-person-0cb45c52-5b3d-4bfb-a65c-41fd9462e548",
              "builtID": "mo-lower-149",
              "externalID": "ocd-person/0cb45c52-5b3d-4bfb-a65c-41fd9462e548",
              "geometry": null
            },
            "contactInfo": {
              "email": "donnie.brown@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=149",
                "https://house.mo.gov/MemberDetails.aspx?district=149"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-149-lower-ocd-person-0cb45c52-5b3d-4bfb-a65c-41fd9462e548": 0
        }
      },
      "150": {
        "members": [
          {
            "API_ID": "ocd-person/08f76b3a-4317-4d18-a44b-f2d6a673f1e3",
            "firstName": "Cameron Bunting",
            "lastName": "Parker",
            "fullName": "Cameron Parker",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2393",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "150",
              "chamber": "lower",
              "hashID": "SLDL-MO-150-lower-ocd-person-08f76b3a-4317-4d18-a44b-f2d6a673f1e3",
              "builtID": "mo-lower-150",
              "externalID": "ocd-person/08f76b3a-4317-4d18-a44b-f2d6a673f1e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "cameron.buntingparke@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=150",
                "https://house.mo.gov/MemberDetails.aspx?district=150"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-150-lower-ocd-person-08f76b3a-4317-4d18-a44b-f2d6a673f1e3": 0
        }
      },
      "151": {
        "members": [
          {
            "API_ID": "ocd-person/8145894d-564b-4438-a1a7-bf1535aba01a",
            "firstName": "Herman",
            "lastName": "Morse",
            "fullName": "Herman Morse",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2008",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "151",
              "chamber": "lower",
              "hashID": "SLDL-MO-151-lower-ocd-person-8145894d-564b-4438-a1a7-bf1535aba01a",
              "builtID": "mo-lower-151",
              "externalID": "ocd-person/8145894d-564b-4438-a1a7-bf1535aba01a",
              "geometry": null
            },
            "contactInfo": {
              "email": "herman.morse@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=151",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=151",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=151",
                "https://house.mo.gov/MemberDetails.aspx?district=151"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-151-lower-ocd-person-8145894d-564b-4438-a1a7-bf1535aba01a": 0
        }
      },
      "152": {
        "members": [
          {
            "API_ID": "ocd-person/a9a55831-48ad-4f2e-b548-0b7652b2b681",
            "firstName": "Hardy",
            "lastName": "Billington",
            "fullName": "Hardy Billington",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2058",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "152",
              "chamber": "lower",
              "hashID": "SLDL-MO-152-lower-ocd-person-a9a55831-48ad-4f2e-b548-0b7652b2b681",
              "builtID": "mo-lower-152",
              "externalID": "ocd-person/a9a55831-48ad-4f2e-b548-0b7652b2b681",
              "geometry": null
            },
            "contactInfo": {
              "email": "hardy.billington@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=152",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=152",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=152"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-152-lower-ocd-person-a9a55831-48ad-4f2e-b548-0b7652b2b681": 0
        }
      },
      "153": {
        "members": [
          {
            "API_ID": "ocd-person/96eee47e-29d5-40e5-9882-79788f2df608",
            "firstName": "Darrell",
            "lastName": "Atchison",
            "fullName": "Darrell Atchison",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2238",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "153",
              "chamber": "lower",
              "hashID": "SLDL-MO-153-lower-ocd-person-96eee47e-29d5-40e5-9882-79788f2df608",
              "builtID": "mo-lower-153",
              "externalID": "ocd-person/96eee47e-29d5-40e5-9882-79788f2df608",
              "geometry": null
            },
            "contactInfo": {
              "email": "darrell.atchison@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=153",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=153",
                "https://house.mo.gov/MemberDetails.aspx?district=153"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-153-lower-ocd-person-96eee47e-29d5-40e5-9882-79788f2df608": 0
        }
      },
      "154": {
        "members": [
          {
            "API_ID": "ocd-person/970bb139-3a35-4e82-bdab-8f5353ddb416",
            "firstName": "David Paul",
            "lastName": "Evans",
            "fullName": "David Evans",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2055",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "154",
              "chamber": "lower",
              "hashID": "SLDL-MO-154-lower-ocd-person-970bb139-3a35-4e82-bdab-8f5353ddb416",
              "builtID": "mo-lower-154",
              "externalID": "ocd-person/970bb139-3a35-4e82-bdab-8f5353ddb416",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.evans@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=154",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=154",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=154",
                "https://house.mo.gov/MemberDetails.aspx?district=154"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-154-lower-ocd-person-970bb139-3a35-4e82-bdab-8f5353ddb416": 0
        }
      },
      "155": {
        "members": [
          {
            "API_ID": "ocd-person/74efb08b-f153-4d63-b6d6-c685d6a04790",
            "firstName": "Travis",
            "lastName": "Smith",
            "fullName": "Travis Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2239",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "155",
              "chamber": "lower",
              "hashID": "SLDL-MO-155-lower-ocd-person-74efb08b-f153-4d63-b6d6-c685d6a04790",
              "builtID": "mo-lower-155",
              "externalID": "ocd-person/74efb08b-f153-4d63-b6d6-c685d6a04790",
              "geometry": null
            },
            "contactInfo": {
              "email": "travis.smith@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=155",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=155",
                "https://house.mo.gov/MemberDetails.aspx?district=155"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-155-lower-ocd-person-74efb08b-f153-4d63-b6d6-c685d6a04790": 0
        }
      },
      "156": {
        "members": [
          {
            "API_ID": "ocd-person/83283f83-e8d3-4e03-949c-1bdfae4bfd7f",
            "firstName": "Brian",
            "lastName": "Seitz",
            "fullName": "Brian Seitz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2240",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "156",
              "chamber": "lower",
              "hashID": "SLDL-MO-156-lower-ocd-person-83283f83-e8d3-4e03-949c-1bdfae4bfd7f",
              "builtID": "mo-lower-156",
              "externalID": "ocd-person/83283f83-e8d3-4e03-949c-1bdfae4bfd7f",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.seitz@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=156",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=156",
                "https://house.mo.gov/MemberDetails.aspx?district=156"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-156-lower-ocd-person-83283f83-e8d3-4e03-949c-1bdfae4bfd7f": 0
        }
      },
      "157": {
        "members": [
          {
            "API_ID": "ocd-person/c5b24334-9dfa-4356-8aa2-8f91c2a3d692",
            "firstName": "Mitch",
            "lastName": "Boggs",
            "fullName": "Mitch Boggs",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2241",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "157",
              "chamber": "lower",
              "hashID": "SLDL-MO-157-lower-ocd-person-c5b24334-9dfa-4356-8aa2-8f91c2a3d692",
              "builtID": "mo-lower-157",
              "externalID": "ocd-person/c5b24334-9dfa-4356-8aa2-8f91c2a3d692",
              "geometry": null
            },
            "contactInfo": {
              "email": "mitch.boggs@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=157",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=157",
                "https://house.mo.gov/MemberDetails.aspx?district=157"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-157-lower-ocd-person-c5b24334-9dfa-4356-8aa2-8f91c2a3d692": 0
        }
      },
      "158": {
        "members": [
          {
            "API_ID": "ocd-person/515b97f2-7c88-40e5-b127-04146435e50e",
            "firstName": "Scott",
            "lastName": "Cupps",
            "fullName": "Scott Cupps",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2172",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "158",
              "chamber": "lower",
              "hashID": "SLDL-MO-158-lower-ocd-person-515b97f2-7c88-40e5-b127-04146435e50e",
              "builtID": "mo-lower-158",
              "externalID": "ocd-person/515b97f2-7c88-40e5-b127-04146435e50e",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.cupps@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=158",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=158",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=158",
                "https://house.mo.gov/MemberDetails.aspx?district=158"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-158-lower-ocd-person-515b97f2-7c88-40e5-b127-04146435e50e": 0
        }
      },
      "159": {
        "members": [
          {
            "API_ID": "ocd-person/914b9a95-6dd2-4d1d-8dc4-eed597b02e53",
            "firstName": "Dirk",
            "lastName": "Deaton",
            "fullName": "Dirk Deaton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2090",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "159",
              "chamber": "lower",
              "hashID": "SLDL-MO-159-lower-ocd-person-914b9a95-6dd2-4d1d-8dc4-eed597b02e53",
              "builtID": "mo-lower-159",
              "externalID": "ocd-person/914b9a95-6dd2-4d1d-8dc4-eed597b02e53",
              "geometry": null
            },
            "contactInfo": {
              "email": "dirk.deaton@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=159",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=159",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=159",
                "https://house.mo.gov/MemberDetails.aspx?district=159"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-159-lower-ocd-person-914b9a95-6dd2-4d1d-8dc4-eed597b02e53": 0
        }
      },
      "160": {
        "members": [
          {
            "API_ID": "ocd-person/77aeecd6-a8b9-446f-b361-88b07026555c",
            "firstName": "Ben",
            "lastName": "Baker",
            "fullName": "Ben Baker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2066",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "160",
              "chamber": "lower",
              "hashID": "SLDL-MO-160-lower-ocd-person-77aeecd6-a8b9-446f-b361-88b07026555c",
              "builtID": "mo-lower-160",
              "externalID": "ocd-person/77aeecd6-a8b9-446f-b361-88b07026555c",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.baker@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=160",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=160",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=160"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-160-lower-ocd-person-77aeecd6-a8b9-446f-b361-88b07026555c": 0
        }
      },
      "161": {
        "members": [
          {
            "API_ID": "ocd-person/56f5920f-46d9-4991-b029-5984b80cd7ff",
            "firstName": "Lane",
            "lastName": "Roberts",
            "fullName": "Lane Roberts",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2092",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "161",
              "chamber": "lower",
              "hashID": "SLDL-MO-161-lower-ocd-person-56f5920f-46d9-4991-b029-5984b80cd7ff",
              "builtID": "mo-lower-161",
              "externalID": "ocd-person/56f5920f-46d9-4991-b029-5984b80cd7ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "lane.roberts@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=161",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=161",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=161",
                "https://house.mo.gov/MemberDetails.aspx?district=161"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-161-lower-ocd-person-56f5920f-46d9-4991-b029-5984b80cd7ff": 0
        }
      },
      "162": {
        "members": [
          {
            "API_ID": "ocd-person/4d763e2a-ab08-46ea-8561-9b3b74b34971",
            "firstName": "Robert",
            "lastName": "Bromley",
            "fullName": "Bob Bromley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=2074",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "162",
              "chamber": "lower",
              "hashID": "SLDL-MO-162-lower-ocd-person-4d763e2a-ab08-46ea-8561-9b3b74b34971",
              "builtID": "mo-lower-162",
              "externalID": "ocd-person/4d763e2a-ab08-46ea-8561-9b3b74b34971",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.bromley@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=162",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=162",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=162",
                "https://house.mo.gov/MemberDetails.aspx?district=162"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-162-lower-ocd-person-4d763e2a-ab08-46ea-8561-9b3b74b34971": 0
        }
      },
      "163": {
        "members": [
          {
            "API_ID": "ocd-person/7529d2d2-0cc1-4d30-9e8f-12d3773dff8d",
            "firstName": "Cody",
            "lastName": "Smith",
            "fullName": "Cody Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.mo.gov/MemberPhoto.aspx?id=1945",
            "title": "MO Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "163",
              "chamber": "lower",
              "hashID": "SLDL-MO-163-lower-ocd-person-7529d2d2-0cc1-4d30-9e8f-12d3773dff8d",
              "builtID": "mo-lower-163",
              "externalID": "ocd-person/7529d2d2-0cc1-4d30-9e8f-12d3773dff8d",
              "geometry": null
            },
            "contactInfo": {
              "email": "cody.smith@house.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=163",
                "https://house.mo.gov/MemberDetails.aspx?year=2021&code=R&district=163",
                "https://house.mo.gov/MemberDetails.aspx?year=2023&code=R&district=163",
                "https://house.mo.gov/MemberDetails.aspx?district=163"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MO-163-lower-ocd-person-7529d2d2-0cc1-4d30-9e8f-12d3773dff8d": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/f6f3c354-c65e-4d9a-a713-5fce1ad2c403",
            "firstName": "Doug",
            "lastName": "Beck",
            "fullName": "Doug Beck",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.mo.gov/21web/wp-content/uploads/2020/12/Beck2021-683x1024.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-MO-1-upper-ocd-person-f6f3c354-c65e-4d9a-a713-5fce1ad2c403",
              "builtID": "mo-upper-1",
              "externalID": "ocd-person/f6f3c354-c65e-4d9a-a713-5fce1ad2c403",
              "geometry": null
            },
            "contactInfo": {
              "email": "doug.beck@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=92",
                "https://www.senate.mo.gov/mem01/",
                "https://www.senate.mo.gov/Senators/Member/01"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-1-upper-ocd-person-f6f3c354-c65e-4d9a-a713-5fce1ad2c403": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/ecbee637-2b07-4028-acb3-b82df2edb42a",
            "firstName": "Nicholas",
            "lastName": "Schroer",
            "fullName": "Nick Schroer",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-MO-2-upper-ocd-person-ecbee637-2b07-4028-acb3-b82df2edb42a",
              "builtID": "mo-upper-2",
              "externalID": "ocd-person/ecbee637-2b07-4028-acb3-b82df2edb42a",
              "geometry": null
            },
            "contactInfo": {
              "email": "nick.schroer@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.senate.mo.gov/Senators/Member/02"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-2-upper-ocd-person-ecbee637-2b07-4028-acb3-b82df2edb42a": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/709ce3cd-4a4e-4bff-93e5-c89a3c46ee06",
            "firstName": "Elaine",
            "lastName": "Gannon",
            "fullName": "Elaine Gannon",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.senate.mo.gov/21web/wp-content/uploads/2020/12/Gannon-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-MO-3-upper-ocd-person-709ce3cd-4a4e-4bff-93e5-c89a3c46ee06",
              "builtID": "mo-upper-3",
              "externalID": "ocd-person/709ce3cd-4a4e-4bff-93e5-c89a3c46ee06",
              "geometry": null
            },
            "contactInfo": {
              "email": "elaine.gannon@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=115",
                "https://www.senate.mo.gov/mem03",
                "https://www.senate.mo.gov/Senators/Member/03"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-3-upper-ocd-person-709ce3cd-4a4e-4bff-93e5-c89a3c46ee06": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/fbeafead-44b9-4f08-9854-ad516fb6c838",
            "firstName": "Karla",
            "lastName": "May",
            "fullName": "Karla May",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.mo.gov/19web/wp-content/uploads/2019/01/KarlaMay-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-MO-4-upper-ocd-person-fbeafead-44b9-4f08-9854-ad516fb6c838",
              "builtID": "mo-upper-4",
              "externalID": "ocd-person/fbeafead-44b9-4f08-9854-ad516fb6c838",
              "geometry": null
            },
            "contactInfo": {
              "email": "karla.may@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem04",
                "https://www.senate.mo.gov/mem04",
                "https://www.senate.mo.gov/Senators/Member/04"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-4-upper-ocd-person-fbeafead-44b9-4f08-9854-ad516fb6c838": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/20ad6a34-2965-4068-b819-49c5e0d27831",
            "firstName": "Steven",
            "lastName": "Roberts",
            "fullName": "Steve Roberts",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.mo.gov/21web/wp-content/uploads/2020/12/Roberts-2021-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-MO-5-upper-ocd-person-20ad6a34-2965-4068-b819-49c5e0d27831",
              "builtID": "mo-upper-5",
              "externalID": "ocd-person/20ad6a34-2965-4068-b819-49c5e0d27831",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.roberts@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem05",
                "https://www.senate.mo.gov/Senators/Member/05"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-5-upper-ocd-person-20ad6a34-2965-4068-b819-49c5e0d27831": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/8df40920-38e1-4892-aa6b-764467bf79b7",
            "firstName": "Mike",
            "lastName": "Bernskoetter",
            "fullName": "Mike Bernskoetter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mo.gov/19web/wp-content/uploads/2019/01/Bernskoetter-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-MO-6-upper-ocd-person-8df40920-38e1-4892-aa6b-764467bf79b7",
              "builtID": "mo-upper-6",
              "externalID": "ocd-person/8df40920-38e1-4892-aa6b-764467bf79b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.bernskoetter@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem06",
                "https://www.senate.mo.gov/mem06",
                "https://www.senate.mo.gov/Senators/Member/06"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-6-upper-ocd-person-8df40920-38e1-4892-aa6b-764467bf79b7": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/70c04c22-b1a9-4c3c-96f9-36761e63f3f8",
            "firstName": "Mike",
            "lastName": "Cierpiot",
            "fullName": "Mike Cierpiot",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mo.gov/20web/wp-content/uploads/2020/01/Cierpiot2020-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-MO-8-upper-ocd-person-70c04c22-b1a9-4c3c-96f9-36761e63f3f8",
              "builtID": "mo-upper-8",
              "externalID": "ocd-person/70c04c22-b1a9-4c3c-96f9-36761e63f3f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.cierpiot@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem08",
                "https://www.senate.mo.gov/mem08",
                "https://www.senate.mo.gov/Senators/Member/08"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-8-upper-ocd-person-70c04c22-b1a9-4c3c-96f9-36761e63f3f8": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/de8806cc-fa03-4745-ae1e-198c56492f9a",
            "firstName": "Barbara Anne",
            "lastName": "Washington",
            "fullName": "Barbara Washington",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-MO-9-upper-ocd-person-de8806cc-fa03-4745-ae1e-198c56492f9a",
              "builtID": "mo-upper-9",
              "externalID": "ocd-person/de8806cc-fa03-4745-ae1e-198c56492f9a",
              "geometry": null
            },
            "contactInfo": {
              "email": "barbara.washington@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=23",
                "https://www.senate.mo.gov/mem09",
                "https://www.senate.mo.gov/Senators/Member/09"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-9-upper-ocd-person-de8806cc-fa03-4745-ae1e-198c56492f9a": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/c9758b3c-066c-40b4-9d40-a93880fda67a",
            "firstName": "Travis",
            "lastName": "Fitzwater",
            "fullName": "Travis Fitzwater",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-MO-10-upper-ocd-person-c9758b3c-066c-40b4-9d40-a93880fda67a",
              "builtID": "mo-upper-10",
              "externalID": "ocd-person/c9758b3c-066c-40b4-9d40-a93880fda67a",
              "geometry": null
            },
            "contactInfo": {
              "email": "travis.fitzwater@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.senate.mo.gov/Senators/Member/10"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-10-upper-ocd-person-c9758b3c-066c-40b4-9d40-a93880fda67a": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/bb98b6a0-ba5f-45f8-ad14-b798515fa8a0",
            "firstName": "Rusty",
            "lastName": "Black",
            "fullName": "Rusty Black",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-MO-12-upper-ocd-person-bb98b6a0-ba5f-45f8-ad14-b798515fa8a0",
              "builtID": "mo-upper-12",
              "externalID": "ocd-person/bb98b6a0-ba5f-45f8-ad14-b798515fa8a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rusty.black@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.senate.mo.gov/Senators/Member/12"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-12-upper-ocd-person-bb98b6a0-ba5f-45f8-ad14-b798515fa8a0": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/048bc29f-0bab-4fa1-8a1e-647b0eba4dcd",
            "firstName": "Angela Walton",
            "lastName": "Mosley",
            "fullName": "Angela Mosley",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.mo.gov/21web/wp-content/uploads/2020/12/Mosley2021-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-MO-13-upper-ocd-person-048bc29f-0bab-4fa1-8a1e-647b0eba4dcd",
              "builtID": "mo-upper-13",
              "externalID": "ocd-person/048bc29f-0bab-4fa1-8a1e-647b0eba4dcd",
              "geometry": null
            },
            "contactInfo": {
              "email": "angela.mosley@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem13",
                "https://www.senate.mo.gov/Senators/Member/13"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-13-upper-ocd-person-048bc29f-0bab-4fa1-8a1e-647b0eba4dcd": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/4f5340d3-7637-4eb2-be68-890073af50af",
            "firstName": "Brian Christopher",
            "lastName": "Williams",
            "fullName": "Brian Williams",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.senate.mo.gov/19web/wp-content/uploads/2019/05/Williams2019-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-MO-14-upper-ocd-person-4f5340d3-7637-4eb2-be68-890073af50af",
              "builtID": "mo-upper-14",
              "externalID": "ocd-person/4f5340d3-7637-4eb2-be68-890073af50af",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.williams@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem14",
                "https://www.senate.mo.gov/mem14",
                "https://www.senate.mo.gov/Senators/Member/14"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-14-upper-ocd-person-4f5340d3-7637-4eb2-be68-890073af50af": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/2effb0e6-3648-4cc7-8738-8f2cb8373b30",
            "firstName": "Andrew P.",
            "lastName": "Koenig",
            "fullName": "Andrew Koenig",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mo.gov/18web/wp-content/uploads/2017/01/Koenig-Andrew-2017w-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-MO-15-upper-ocd-person-2effb0e6-3648-4cc7-8738-8f2cb8373b30",
              "builtID": "mo-upper-15",
              "externalID": "ocd-person/2effb0e6-3648-4cc7-8738-8f2cb8373b30",
              "geometry": null
            },
            "contactInfo": {
              "email": "andrew.koenig@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem15",
                "https://www.senate.mo.gov/mem15",
                "https://www.senate.mo.gov/Senators/Member/15"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-15-upper-ocd-person-2effb0e6-3648-4cc7-8738-8f2cb8373b30": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/867469f6-f988-49ec-be8c-4a065db3f223",
            "firstName": "Justin",
            "lastName": "Brown",
            "fullName": "Justin Brown",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mo.gov/19web/wp-content/uploads/2019/09/Brown2019-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-MO-16-upper-ocd-person-867469f6-f988-49ec-be8c-4a065db3f223",
              "builtID": "mo-upper-16",
              "externalID": "ocd-person/867469f6-f988-49ec-be8c-4a065db3f223",
              "geometry": null
            },
            "contactInfo": {
              "email": "justin.brown@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem16",
                "https://www.senate.mo.gov/mem16",
                "https://www.senate.mo.gov/Senators/Member/16"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-16-upper-ocd-person-867469f6-f988-49ec-be8c-4a065db3f223": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/d7beefb2-2b4f-490a-b1f3-b83840c663ff",
            "firstName": "Lauren",
            "lastName": "Arthur",
            "fullName": "Lauren Arthur",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senate.mo.gov/19web/wp-content/uploads/2019/02/Arthur2019-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-MO-17-upper-ocd-person-d7beefb2-2b4f-490a-b1f3-b83840c663ff",
              "builtID": "mo-upper-17",
              "externalID": "ocd-person/d7beefb2-2b4f-490a-b1f3-b83840c663ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "lauren.arthur@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem17",
                "https://www.senate.mo.gov/mem17",
                "https://www.senate.mo.gov/Senators/Member/17"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-17-upper-ocd-person-d7beefb2-2b4f-490a-b1f3-b83840c663ff": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/77b71863-3523-489a-a6a8-953ffa0d8955",
            "firstName": "Cindy",
            "lastName": "O'Laughlin",
            "fullName": "Cindy O'Laughlin",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.senate.mo.gov/19web/wp-content/uploads/2019/09/OLaughlin2019-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-MO-18-upper-ocd-person-77b71863-3523-489a-a6a8-953ffa0d8955",
              "builtID": "mo-upper-18",
              "externalID": "ocd-person/77b71863-3523-489a-a6a8-953ffa0d8955",
              "geometry": null
            },
            "contactInfo": {
              "email": "cindy.olaughlin@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem18",
                "https://www.senate.mo.gov/mem18",
                "https://www.senate.mo.gov/Senators/Member/18"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-18-upper-ocd-person-77b71863-3523-489a-a6a8-953ffa0d8955": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/b65e8aa2-23c4-4ad4-8550-510d8ba82cfc",
            "firstName": "Caleb",
            "lastName": "Rowden",
            "fullName": "Caleb Rowden",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mo.gov/18Web/wp-content/uploads/photo-gallery/Senator Portraits/thumb/Rowden1a-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-MO-19-upper-ocd-person-b65e8aa2-23c4-4ad4-8550-510d8ba82cfc",
              "builtID": "mo-upper-19",
              "externalID": "ocd-person/b65e8aa2-23c4-4ad4-8550-510d8ba82cfc",
              "geometry": null
            },
            "contactInfo": {
              "email": "caleb.rowden@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem19",
                "https://www.senate.mo.gov/mem19",
                "https://www.senate.mo.gov/Senators/Member/19"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-19-upper-ocd-person-b65e8aa2-23c4-4ad4-8550-510d8ba82cfc": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/c5e38dee-7f82-4795-a134-b984b6878458",
            "firstName": "Curtis D.",
            "lastName": "Trent",
            "fullName": "Curtis Trent",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-MO-20-upper-ocd-person-c5e38dee-7f82-4795-a134-b984b6878458",
              "builtID": "mo-upper-20",
              "externalID": "ocd-person/c5e38dee-7f82-4795-a134-b984b6878458",
              "geometry": null
            },
            "contactInfo": {
              "email": "curtis.trent@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.senate.mo.gov/Senators/Member/20"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-20-upper-ocd-person-c5e38dee-7f82-4795-a134-b984b6878458": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/9f04b7ea-bd98-4f8e-ad76-8a5bf7b5917a",
            "firstName": "Denny L.",
            "lastName": "Hoskins",
            "fullName": "Denny Hoskins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mo.gov/19web/wp-content/uploads/2019/09/HoskinsW-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-MO-21-upper-ocd-person-9f04b7ea-bd98-4f8e-ad76-8a5bf7b5917a",
              "builtID": "mo-upper-21",
              "externalID": "ocd-person/9f04b7ea-bd98-4f8e-ad76-8a5bf7b5917a",
              "geometry": null
            },
            "contactInfo": {
              "email": "denny.hoskins@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem21",
                "https://www.senate.mo.gov/mem21",
                "https://www.senate.mo.gov/Senators/Member/21"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-21-upper-ocd-person-9f04b7ea-bd98-4f8e-ad76-8a5bf7b5917a": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/f1d83642-4aa6-4fea-80f1-4c3f422c63ed",
            "firstName": "Mary Elizabeth",
            "lastName": "Coleman",
            "fullName": "Mary Elizabeth Coleman",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-MO-22-upper-ocd-person-f1d83642-4aa6-4fea-80f1-4c3f422c63ed",
              "builtID": "mo-upper-22",
              "externalID": "ocd-person/f1d83642-4aa6-4fea-80f1-4c3f422c63ed",
              "geometry": null
            },
            "contactInfo": {
              "email": "maryelizabeth.coleman@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.senate.mo.gov/Senators/Member/22"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-22-upper-ocd-person-f1d83642-4aa6-4fea-80f1-4c3f422c63ed": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/52c87342-c9d6-4006-9e44-812722b40ed3",
            "firstName": "William",
            "lastName": "Eigel",
            "fullName": "Bill Eigel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mo.gov/19web/wp-content/uploads/2019/01/Eigel-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-MO-23-upper-ocd-person-52c87342-c9d6-4006-9e44-812722b40ed3",
              "builtID": "mo-upper-23",
              "externalID": "ocd-person/52c87342-c9d6-4006-9e44-812722b40ed3",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.eigel@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem23",
                "https://www.senate.mo.gov/mem23",
                "https://www.senate.mo.gov/Senators/Member/23"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-23-upper-ocd-person-52c87342-c9d6-4006-9e44-812722b40ed3": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/802c155f-8979-418f-b13b-b75de93caf31",
            "firstName": "Tracy",
            "lastName": "McCreery",
            "fullName": "Tracy McCreery",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-MO-24-upper-ocd-person-802c155f-8979-418f-b13b-b75de93caf31",
              "builtID": "mo-upper-24",
              "externalID": "ocd-person/802c155f-8979-418f-b13b-b75de93caf31",
              "geometry": null
            },
            "contactInfo": {
              "email": "tracy.mccreery@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.senate.mo.gov/Senators/Member/24"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-24-upper-ocd-person-802c155f-8979-418f-b13b-b75de93caf31": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/87d06121-1f5c-49ed-88dc-f5c70e88533e",
            "firstName": "Jason",
            "lastName": "Bean",
            "fullName": "Jason Bean",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mo.gov/21web/wp-content/uploads/2020/12/Bean-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-MO-25-upper-ocd-person-87d06121-1f5c-49ed-88dc-f5c70e88533e",
              "builtID": "mo-upper-25",
              "externalID": "ocd-person/87d06121-1f5c-49ed-88dc-f5c70e88533e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.bean@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem25",
                "https://www.senate.mo.gov/Senators/Member/25"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-25-upper-ocd-person-87d06121-1f5c-49ed-88dc-f5c70e88533e": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/c44ce433-7957-40b8-98dd-221b2f7d1436",
            "firstName": "Benjamin",
            "lastName": "Brown",
            "fullName": "Ben Brown",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mo.gov/WebPhotos/SenatorPortraits/Brown26.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-MO-26-upper-ocd-person-c44ce433-7957-40b8-98dd-221b2f7d1436",
              "builtID": "mo-upper-26",
              "externalID": "ocd-person/c44ce433-7957-40b8-98dd-221b2f7d1436",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.brown@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.senate.mo.gov/Senators/Member/26"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-26-upper-ocd-person-c44ce433-7957-40b8-98dd-221b2f7d1436": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/a5a31674-2ef7-48e1-a1e0-133e1ae84278",
            "firstName": "Holly Renee Thompson",
            "lastName": "Rehder",
            "fullName": "Holly Rehder",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.senate.mo.gov/21web/wp-content/uploads/2021/01/Rehder-2-683x1024.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-MO-27-upper-ocd-person-a5a31674-2ef7-48e1-a1e0-133e1ae84278",
              "builtID": "mo-upper-27",
              "externalID": "ocd-person/a5a31674-2ef7-48e1-a1e0-133e1ae84278",
              "geometry": null
            },
            "contactInfo": {
              "email": "holly.rehder@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=148",
                "https://www.senate.mo.gov/mem27/",
                "https://www.senate.mo.gov/Senators/Member/27"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-27-upper-ocd-person-a5a31674-2ef7-48e1-a1e0-133e1ae84278": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/4f194218-3d95-4f08-8963-7e3440fdb671",
            "firstName": "Sandy Franklin",
            "lastName": "Crawford",
            "fullName": "Sandy Crawford",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.senate.mo.gov/19web/wp-content/uploads/2019/09/Crawford2019-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-MO-28-upper-ocd-person-4f194218-3d95-4f08-8963-7e3440fdb671",
              "builtID": "mo-upper-28",
              "externalID": "ocd-person/4f194218-3d95-4f08-8963-7e3440fdb671",
              "geometry": null
            },
            "contactInfo": {
              "email": "sandy.crawford@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem28",
                "https://www.senate.mo.gov/mem28",
                "https://www.senate.mo.gov/Senators/Member/28"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-28-upper-ocd-person-4f194218-3d95-4f08-8963-7e3440fdb671": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/43563b48-1d26-4a85-bad2-615971201d41",
            "firstName": "Mike",
            "lastName": "Moon",
            "fullName": "Mike Moon",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-MO-29-upper-ocd-person-43563b48-1d26-4a85-bad2-615971201d41",
              "builtID": "mo-upper-29",
              "externalID": "ocd-person/43563b48-1d26-4a85-bad2-615971201d41",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.moon@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=157",
                "https://www.senate.mo.gov/mem29",
                "https://www.senate.mo.gov/Senators/Member/29"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-29-upper-ocd-person-43563b48-1d26-4a85-bad2-615971201d41": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/934fdfca-5cdf-4c67-a3b0-b23e433f87f6",
            "firstName": "Lincoln",
            "lastName": "Hough",
            "fullName": "Lincoln Hough",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mo.gov/19web/wp-content/uploads/2019/10/Hough-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-MO-30-upper-ocd-person-934fdfca-5cdf-4c67-a3b0-b23e433f87f6",
              "builtID": "mo-upper-30",
              "externalID": "ocd-person/934fdfca-5cdf-4c67-a3b0-b23e433f87f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "lincoln.hough@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem30",
                "https://www.senate.mo.gov/mem30",
                "https://www.senate.mo.gov/Senators/Member/30"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-30-upper-ocd-person-934fdfca-5cdf-4c67-a3b0-b23e433f87f6": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/e4811e7a-7df7-4275-917d-8bdcc2778fb1",
            "firstName": "Richard Ray",
            "lastName": "Brattin",
            "fullName": "Rick Brattin",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-MO-31-upper-ocd-person-e4811e7a-7df7-4275-917d-8bdcc2778fb1",
              "builtID": "mo-upper-31",
              "externalID": "ocd-person/e4811e7a-7df7-4275-917d-8bdcc2778fb1",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.brattin@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=55",
                "https://www.senate.mo.gov/mem31",
                "https://www.senate.mo.gov/Senators/Member/31"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-31-upper-ocd-person-e4811e7a-7df7-4275-917d-8bdcc2778fb1": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/29fe2ddb-6025-4e60-942d-fd0880ebad5b",
            "firstName": "Jill",
            "lastName": "Carter",
            "fullName": "Jill Carter",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-MO-32-upper-ocd-person-29fe2ddb-6025-4e60-942d-fd0880ebad5b",
              "builtID": "mo-upper-32",
              "externalID": "ocd-person/29fe2ddb-6025-4e60-942d-fd0880ebad5b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jill.carter@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.senate.mo.gov/Senators/Member/32/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-32-upper-ocd-person-29fe2ddb-6025-4e60-942d-fd0880ebad5b": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/c494eff5-d2d8-4bf0-92e0-0d6d4cd788df",
            "firstName": "Karla",
            "lastName": "Eslinger",
            "fullName": "Karla Eslinger",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.senate.mo.gov/21web/wp-content/uploads/2021/01/Eslinger-683x1024.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-MO-33-upper-ocd-person-c494eff5-d2d8-4bf0-92e0-0d6d4cd788df",
              "builtID": "mo-upper-33",
              "externalID": "ocd-person/c494eff5-d2d8-4bf0-92e0-0d6d4cd788df",
              "geometry": null
            },
            "contactInfo": {
              "email": "karla.eslinger@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.house.mo.gov/MemberDetails.aspx?district=155",
                "https://www.senate.mo.gov/mem33/",
                "https://www.senate.mo.gov/Senators/Member/33"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-33-upper-ocd-person-c494eff5-d2d8-4bf0-92e0-0d6d4cd788df": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/98b8120c-7a9c-4415-9e7b-8bfcdc8fa8a5",
            "firstName": "Tony",
            "lastName": "Luetkemeyer",
            "fullName": "Tony Luetkemeyer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.senate.mo.gov/20web/wp-content/uploads/2020/01/010720-002-200x300.jpg",
            "title": "MO Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MO",
              "stateFull": "Missouri",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-MO-34-upper-ocd-person-98b8120c-7a9c-4415-9e7b-8bfcdc8fa8a5",
              "builtID": "mo-upper-34",
              "externalID": "ocd-person/98b8120c-7a9c-4415-9e7b-8bfcdc8fa8a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "tony.luetkemeyer@senate.mo.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.senate.mo.gov/mem34",
                "https://www.senate.mo.gov/mem34",
                "https://www.senate.mo.gov/Senators/Member/34"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MO-34-upper-ocd-person-98b8120c-7a9c-4415-9e7b-8bfcdc8fa8a5": 0
        }
      }
    }
  },
  "MT": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "MT",
          "stateFull": "Montana",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "MT",
            "stateFull": "Montana",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-MT---",
            "builtID": "mt--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-MT---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "D000618",
          "in_office": true,
          "firstName": "Steve",
          "lastName": "Daines",
          "middleName": null,
          "fullName": "Steve Daines",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/D000618.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "MT",
            "stateFull": "Montana",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-MT---D000618",
            "builtID": "mt--",
            "externalID": "D000618",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.daines.senate.gov/connect/email-steve",
            "address1": "320 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-2651",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SteveDaines",
            "youtube": "SteveDainesMT",
            "instagram": null,
            "facebook": "SteveDainesMT",
            "urls": ["https://www.daines.senate.gov"],
            "rss_url": null
          },
          "title": "MT Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/D000618.json",
          "govtrack_id": "412549",
          "cspan_id": "1034037",
          "votesmart_id": "135720",
          "icpsr_id": "21338",
          "crp_id": "N00033054",
          "google_entity_id": "/m/03qlc5t",
          "state_rank": "junior",
          "lis_id": "S375",
          "suffix": null,
          "date_of_birth": "1962-08-20",
          "leadership_role": null,
          "fec_candidate_id": "H2MT01060",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "9",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 24,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 4.33,
          "votes_with_party_pct": 90.63,
          "votes_against_party_pct": 9.37,
          "ocd_id": "ocd-division/country:us/state:mt"
        },
        {
          "API_ID": "T000464",
          "in_office": true,
          "firstName": "Jon",
          "lastName": "Tester",
          "middleName": null,
          "fullName": "Jon Tester",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/T000464.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "MT",
            "stateFull": "Montana",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-MT---T000464",
            "builtID": "mt--",
            "externalID": "T000464",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.tester.senate.gov/?p=email_senator",
            "address1": "311 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-2644",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorTester",
            "youtube": "senatorjontester",
            "instagram": null,
            "facebook": "senatortester",
            "urls": ["https://www.tester.senate.gov"],
            "rss_url": "https://www.tester.senate.gov/rss/"
          },
          "title": "MT Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/T000464.json",
          "govtrack_id": "412244",
          "cspan_id": "1020176",
          "votesmart_id": "20928",
          "icpsr_id": "40702",
          "crp_id": "N00027605",
          "google_entity_id": "/m/066cdp",
          "state_rank": "senior",
          "lis_id": "S314",
          "suffix": null,
          "date_of_birth": "1956-08-21",
          "leadership_role": null,
          "fec_candidate_id": "S6MT00162",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "17",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 7,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 1.26,
          "votes_with_party_pct": 93.58,
          "votes_against_party_pct": 6.42,
          "ocd_id": "ocd-division/country:us/state:mt"
        }
      ],
      "hash": { "SENATE-MT---D000618": 0, "SENATE-MT---T000464": 1 }
    },
    "HOUSE": {
      "02": {
        "members": [
          {
            "API_ID": "R000103",
            "in_office": true,
            "firstName": "Matt",
            "lastName": "Rosendale",
            "middleName": null,
            "fullName": "Matthew M. Rosendale, Sr.",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/R000103.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-MT-02--R000103",
              "builtID": "mt--02",
              "externalID": "R000103",
              "geometry": null,
              "geoid": "3002"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1023 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3211",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRosendale",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://rosendale.house.gov"],
              "rss_url": null
            },
            "title": "MT House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/R000103.json",
            "govtrack_id": "456830",
            "cspan_id": null,
            "votesmart_id": "120815",
            "icpsr_id": null,
            "crp_id": "N00035517",
            "google_entity_id": "/m/0j260rd",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1960-07-07",
            "leadership_role": null,
            "fec_candidate_id": "H4MT00050",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 1,
            "total_present": 5,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.09,
            "votes_with_party_pct": 79.5,
            "votes_against_party_pct": 18.67,
            "ocd_id": "ocd-division/country:us/state:mt/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MT-02--R000103": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "Z000018",
            "in_office": true,
            "firstName": "Ryan",
            "lastName": "Zinke",
            "middleName": null,
            "fullName": "Ryan K. Zinke",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/Z000018.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-MT-01--Z000018",
              "builtID": "mt--01",
              "externalID": "Z000018",
              "geometry": null,
              "geoid": "3001"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "512 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5628",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRyanZinke",
              "youtube": null,
              "instagram": null,
              "facebook": "CongressmanRyanZinke",
              "urls": ["https://zinke.house.gov"],
              "rss_url": "https://zinke.house.gov/rss.xml"
            },
            "title": "MT House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/Z000018.json",
            "govtrack_id": "412640",
            "cspan_id": null,
            "votesmart_id": "104073",
            "icpsr_id": "21532",
            "crp_id": "N00035616",
            "google_entity_id": "/m/0gkz1jg",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1961-11-01",
            "leadership_role": null,
            "fec_candidate_id": "H4MT01041",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 18,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.7,
            "votes_with_party_pct": 90.47,
            "votes_against_party_pct": 7.56,
            "ocd_id": "ocd-division/country:us/state:mt/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-MT-01--Z000018": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/ce731f49-51e1-46f1-8d9b-83ada28520cc",
            "firstName": "Steve",
            "lastName": "Gunderson",
            "fullName": "Steve Gunderson",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-MT-1-lower-ocd-person-ce731f49-51e1-46f1-8d9b-83ada28520cc",
              "builtID": "mt-lower-1",
              "externalID": "ocd-person/ce731f49-51e1-46f1-8d9b-83ada28520cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.gunderson@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4744",
                "https://leg.mt.gov/legislator-information/roster/individual/5059",
                "https://leg.mt.gov/legislator-information/roster/individual/6888",
                "https://leg.mt.gov/legislator-information/roster/individual/7522"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-1-lower-ocd-person-ce731f49-51e1-46f1-8d9b-83ada28520cc": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/51f462fa-2f25-40cf-a73f-3d9d60ea8936",
            "firstName": "Neil A.",
            "lastName": "Duram",
            "fullName": "Neil Duram",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-MT-2-lower-ocd-person-51f462fa-2f25-40cf-a73f-3d9d60ea8936",
              "builtID": "mt-lower-2",
              "externalID": "ocd-person/51f462fa-2f25-40cf-a73f-3d9d60ea8936",
              "geometry": null
            },
            "contactInfo": {
              "email": "neil.duram@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5060",
                "https://leg.mt.gov/legislator-information/roster/individual/6889",
                "https://leg.mt.gov/legislator-information/roster/individual/7494"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-2-lower-ocd-person-51f462fa-2f25-40cf-a73f-3d9d60ea8936": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/8664c246-dc7e-48aa-9db1-9e33055dcb67",
            "firstName": "Braxton",
            "lastName": "Mitchell",
            "fullName": "Braxton Mitchell",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-MT-3-lower-ocd-person-8664c246-dc7e-48aa-9db1-9e33055dcb67",
              "builtID": "mt-lower-3",
              "externalID": "ocd-person/8664c246-dc7e-48aa-9db1-9e33055dcb67",
              "geometry": null
            },
            "contactInfo": {
              "email": "braxton.mitchell@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6890",
                "https://leg.mt.gov/legislator-information/roster/individual/7563"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-3-lower-ocd-person-8664c246-dc7e-48aa-9db1-9e33055dcb67": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/109f7bbf-905d-418c-b8e2-11705af0a5fb",
            "firstName": "Matt",
            "lastName": "Regier",
            "fullName": "Matt Regier",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-MT-4-lower-ocd-person-109f7bbf-905d-418c-b8e2-11705af0a5fb",
              "builtID": "mt-lower-4",
              "externalID": "ocd-person/109f7bbf-905d-418c-b8e2-11705af0a5fb",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.regier@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4747",
                "https://leg.mt.gov/legislator-information/roster/individual/5062",
                "https://leg.mt.gov/legislator-information/roster/individual/6832",
                "https://leg.mt.gov/legislator-information/roster/individual/7580"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-4-lower-ocd-person-109f7bbf-905d-418c-b8e2-11705af0a5fb": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/9c542cad-1c8f-4dfd-b41f-12468fd2d806",
            "firstName": "Dave B.",
            "lastName": "Fern",
            "fullName": "Dave Fern",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-MT-5-lower-ocd-person-9c542cad-1c8f-4dfd-b41f-12468fd2d806",
              "builtID": "mt-lower-5",
              "externalID": "ocd-person/9c542cad-1c8f-4dfd-b41f-12468fd2d806",
              "geometry": null
            },
            "contactInfo": {
              "email": "dave.fern@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4748",
                "https://leg.mt.gov/legislator-information/roster/individual/5063",
                "https://leg.mt.gov/legislator-information/roster/individual/6856",
                "https://leg.mt.gov/legislator-information/roster/individual/7502"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-5-lower-ocd-person-9c542cad-1c8f-4dfd-b41f-12468fd2d806": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/817877aa-a3ac-40a7-9f32-f40204840abf",
            "firstName": "Amy",
            "lastName": "Regier",
            "fullName": "Amy Regier",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-MT-6-lower-ocd-person-817877aa-a3ac-40a7-9f32-f40204840abf",
              "builtID": "mt-lower-6",
              "externalID": "ocd-person/817877aa-a3ac-40a7-9f32-f40204840abf",
              "geometry": null
            },
            "contactInfo": {
              "email": "amy.regier@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6891",
                "https://leg.mt.gov/legislator-information/roster/individual/7578"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-6-lower-ocd-person-817877aa-a3ac-40a7-9f32-f40204840abf": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/db529569-d550-4ed9-b371-b5a1cae9e007",
            "firstName": "Courtenay",
            "lastName": "Sprunger",
            "fullName": "Courtenay Sprunger",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-MT-7-lower-ocd-person-db529569-d550-4ed9-b371-b5a1cae9e007",
              "builtID": "mt-lower-7",
              "externalID": "ocd-person/db529569-d550-4ed9-b371-b5a1cae9e007",
              "geometry": null
            },
            "contactInfo": {
              "email": "courtenay.sprunger@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7646"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-7-lower-ocd-person-db529569-d550-4ed9-b371-b5a1cae9e007": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/be2c0041-9ca1-48d1-8244-023a31458b5e",
            "firstName": "Terry",
            "lastName": "Falk",
            "fullName": "Terry Falk",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-MT-8-lower-ocd-person-be2c0041-9ca1-48d1-8244-023a31458b5e",
              "builtID": "mt-lower-8",
              "externalID": "ocd-person/be2c0041-9ca1-48d1-8244-023a31458b5e",
              "geometry": null
            },
            "contactInfo": {
              "email": "terry.falk@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7626"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-8-lower-ocd-person-be2c0041-9ca1-48d1-8244-023a31458b5e": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/9685efd1-9b29-4c6a-b3c3-574b74c934a4",
            "firstName": "Tony",
            "lastName": "Brockman",
            "fullName": "Tony Brockman",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-MT-9-lower-ocd-person-9685efd1-9b29-4c6a-b3c3-574b74c934a4",
              "builtID": "mt-lower-9",
              "externalID": "ocd-person/9685efd1-9b29-4c6a-b3c3-574b74c934a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "tony.brockman@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7621"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-9-lower-ocd-person-9685efd1-9b29-4c6a-b3c3-574b74c934a4": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/ab9d7c2f-41db-4137-b068-f8ce4725c497",
            "firstName": "Bob",
            "lastName": "Keenan",
            "fullName": "Bob Keenan",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-MT-10-lower-ocd-person-ab9d7c2f-41db-4137-b068-f8ce4725c497",
              "builtID": "mt-lower-10",
              "externalID": "ocd-person/ab9d7c2f-41db-4137-b068-f8ce4725c497",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.keenan@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4711",
                "https://leg.mt.gov/legislator-information/roster/individual/5036",
                "https://leg.mt.gov/legislator-information/roster/individual/6805",
                "https://leg.mt.gov/legislator-information/roster/individual/7537"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-10-lower-ocd-person-ab9d7c2f-41db-4137-b068-f8ce4725c497": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/37855b69-b31b-4b88-b1b8-ae00ceba596c",
            "firstName": "Tanner J.",
            "lastName": "Smith",
            "fullName": "Tanner Smith",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-MT-11-lower-ocd-person-37855b69-b31b-4b88-b1b8-ae00ceba596c",
              "builtID": "mt-lower-11",
              "externalID": "ocd-person/37855b69-b31b-4b88-b1b8-ae00ceba596c",
              "geometry": null
            },
            "contactInfo": {
              "email": "tanner.smith@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7645"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-11-lower-ocd-person-37855b69-b31b-4b88-b1b8-ae00ceba596c": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/7e2b7b5b-0e72-4dac-ab5d-cc44450aeb95",
            "firstName": "Linda",
            "lastName": "Reksten",
            "fullName": "Linda Reksten",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-MT-12-lower-ocd-person-7e2b7b5b-0e72-4dac-ab5d-cc44450aeb95",
              "builtID": "mt-lower-12",
              "externalID": "ocd-person/7e2b7b5b-0e72-4dac-ab5d-cc44450aeb95",
              "geometry": null
            },
            "contactInfo": {
              "email": "linda.reksten@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6894",
                "https://leg.mt.gov/legislator-information/roster/individual/7581"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-12-lower-ocd-person-7e2b7b5b-0e72-4dac-ab5d-cc44450aeb95": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/a379258c-d227-4861-998b-15bb543886e2",
            "firstName": "Paul C.",
            "lastName": "Fielder",
            "fullName": "Paul Fielder",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-MT-13-lower-ocd-person-a379258c-d227-4861-998b-15bb543886e2",
              "builtID": "mt-lower-13",
              "externalID": "ocd-person/a379258c-d227-4861-998b-15bb543886e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.fielder@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6895",
                "https://leg.mt.gov/legislator-information/roster/individual/7503"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-13-lower-ocd-person-a379258c-d227-4861-998b-15bb543886e2": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/e5cf63ad-879c-435f-819e-a3326862b1b8",
            "firstName": "Denley M.",
            "lastName": "Loge",
            "fullName": "Denley Loge",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-MT-14-lower-ocd-person-e5cf63ad-879c-435f-819e-a3326862b1b8",
              "builtID": "mt-lower-14",
              "externalID": "ocd-person/e5cf63ad-879c-435f-819e-a3326862b1b8",
              "geometry": null
            },
            "contactInfo": {
              "email": "denley.loge@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4757",
                "https://leg.mt.gov/legislator-information/roster/individual/5072",
                "https://leg.mt.gov/legislator-information/roster/individual/6836",
                "https://leg.mt.gov/legislator-information/roster/individual/7549"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-14-lower-ocd-person-e5cf63ad-879c-435f-819e-a3326862b1b8": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/a38068ae-e340-48df-aae0-f3d15f3735af",
            "firstName": "Marvin R.",
            "lastName": "Weatherwax",
            "fullName": "Marvin Weatherwax",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-MT-15-lower-ocd-person-a38068ae-e340-48df-aae0-f3d15f3735af",
              "builtID": "mt-lower-15",
              "externalID": "ocd-person/a38068ae-e340-48df-aae0-f3d15f3735af",
              "geometry": null
            },
            "contactInfo": {
              "email": "marvin.weatherwax@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5073",
                "https://leg.mt.gov/legislator-information/roster/individual/6825",
                "https://leg.mt.gov/legislator-information/roster/individual/7608"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-15-lower-ocd-person-a38068ae-e340-48df-aae0-f3d15f3735af": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/637f0db7-9c93-478e-904b-a36d0a2a67b1",
            "firstName": "Tyson T.",
            "lastName": "Running Wolf",
            "fullName": "Tyson Running Wolf",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-MT-16-lower-ocd-person-637f0db7-9c93-478e-904b-a36d0a2a67b1",
              "builtID": "mt-lower-16",
              "externalID": "ocd-person/637f0db7-9c93-478e-904b-a36d0a2a67b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "tyson.runningwolf@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5074",
                "https://leg.mt.gov/legislator-information/roster/individual/6826",
                "https://leg.mt.gov/legislator-information/roster/individual/7583"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-16-lower-ocd-person-637f0db7-9c93-478e-904b-a36d0a2a67b1": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/f5dbcae0-234e-489a-a323-ab11d7c869b3",
            "firstName": "Ross H.",
            "lastName": "Fitzgerald",
            "fullName": "Ross Fitzgerald",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-MT-17-lower-ocd-person-f5dbcae0-234e-489a-a323-ab11d7c869b3",
              "builtID": "mt-lower-17",
              "externalID": "ocd-person/f5dbcae0-234e-489a-a323-ab11d7c869b3",
              "geometry": null
            },
            "contactInfo": {
              "email": "ross.fitzgerald@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4760",
                "https://leg.mt.gov/legislator-information/roster/individual/5075",
                "https://leg.mt.gov/legislator-information/roster/individual/6896",
                "https://leg.mt.gov/legislator-information/roster/individual/7504"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-17-lower-ocd-person-f5dbcae0-234e-489a-a323-ab11d7c869b3": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/ed3e498d-5160-4f6a-8580-ee2a4d877b5d",
            "firstName": "Llewelyn E.",
            "lastName": "Jones",
            "fullName": "Llew Jones",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-MT-18-lower-ocd-person-ed3e498d-5160-4f6a-8580-ee2a4d877b5d",
              "builtID": "mt-lower-18",
              "externalID": "ocd-person/ed3e498d-5160-4f6a-8580-ee2a4d877b5d",
              "geometry": null
            },
            "contactInfo": {
              "email": "llew.jones@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5076",
                "https://leg.mt.gov/legislator-information/roster/individual/6837",
                "https://leg.mt.gov/legislator-information/roster/individual/7534"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-18-lower-ocd-person-ed3e498d-5160-4f6a-8580-ee2a4d877b5d": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/2c714bfa-23c8-496d-a0ad-718b806db836",
            "firstName": "Russel W.",
            "lastName": "Miner",
            "fullName": "Russ Miner",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-MT-19-lower-ocd-person-2c714bfa-23c8-496d-a0ad-718b806db836",
              "builtID": "mt-lower-19",
              "externalID": "ocd-person/2c714bfa-23c8-496d-a0ad-718b806db836",
              "geometry": null
            },
            "contactInfo": {
              "email": "russel.miner@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7637"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-19-lower-ocd-person-2c714bfa-23c8-496d-a0ad-718b806db836": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/1e64880a-672d-4b71-a2e6-f95c41193953",
            "firstName": "Fred",
            "lastName": "Anderson",
            "fullName": "Fred Anderson",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-MT-20-lower-ocd-person-1e64880a-672d-4b71-a2e6-f95c41193953",
              "builtID": "mt-lower-20",
              "externalID": "ocd-person/1e64880a-672d-4b71-a2e6-f95c41193953",
              "geometry": null
            },
            "contactInfo": {
              "email": "fred.anderson@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4763",
                "https://leg.mt.gov/legislator-information/roster/individual/5078",
                "https://leg.mt.gov/legislator-information/roster/individual/6898",
                "https://leg.mt.gov/legislator-information/roster/individual/7617",
                "https://leg.mt.gov/legislator-information/roster/individual/7808"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-20-lower-ocd-person-1e64880a-672d-4b71-a2e6-f95c41193953": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/29b81d32-dc72-49d8-8bef-ca4b60fe9c87",
            "firstName": "Francis Edward",
            "lastName": "Buttrey",
            "fullName": "Ed Buttrey",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-MT-21-lower-ocd-person-29b81d32-dc72-49d8-8bef-ca4b60fe9c87",
              "builtID": "mt-lower-21",
              "externalID": "ocd-person/29b81d32-dc72-49d8-8bef-ca4b60fe9c87",
              "geometry": null
            },
            "contactInfo": {
              "email": "ed.buttrey@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5079",
                "https://leg.mt.gov/legislator-information/roster/individual/6899",
                "https://leg.mt.gov/legislator-information/roster/individual/7484"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-21-lower-ocd-person-29b81d32-dc72-49d8-8bef-ca4b60fe9c87": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/a0d721fa-c3c6-400f-926d-a7294792ea38",
            "firstName": "Lola",
            "lastName": "Sheldon-Galloway",
            "fullName": "Lola Sheldon-Galloway",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-MT-22-lower-ocd-person-a0d721fa-c3c6-400f-926d-a7294792ea38",
              "builtID": "mt-lower-22",
              "externalID": "ocd-person/a0d721fa-c3c6-400f-926d-a7294792ea38",
              "geometry": null
            },
            "contactInfo": {
              "email": "lola.sheldongalloway@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4765",
                "https://leg.mt.gov/legislator-information/roster/individual/5081",
                "https://leg.mt.gov/legislator-information/roster/individual/6900",
                "https://leg.mt.gov/legislator-information/roster/individual/7589"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-22-lower-ocd-person-a0d721fa-c3c6-400f-926d-a7294792ea38": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/7ff6cc57-cdfb-4817-b033-0e3698cb11f7",
            "firstName": "Douglas Scot",
            "lastName": "Kerns",
            "fullName": "Scot Kerns",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-MT-23-lower-ocd-person-7ff6cc57-cdfb-4817-b033-0e3698cb11f7",
              "builtID": "mt-lower-23",
              "externalID": "ocd-person/7ff6cc57-cdfb-4817-b033-0e3698cb11f7",
              "geometry": null
            },
            "contactInfo": {
              "email": "scot.kerns@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6901",
                "https://leg.mt.gov/legislator-information/roster/individual/7632"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-23-lower-ocd-person-7ff6cc57-cdfb-4817-b033-0e3698cb11f7": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/01d8976a-5dc4-4e73-b9db-770ff58a89fc",
            "firstName": "Steven Edwin",
            "lastName": "Galloway",
            "fullName": "Steven Galloway",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-MT-24-lower-ocd-person-01d8976a-5dc4-4e73-b9db-770ff58a89fc",
              "builtID": "mt-lower-24",
              "externalID": "ocd-person/01d8976a-5dc4-4e73-b9db-770ff58a89fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.galloway@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6902",
                "https://leg.mt.gov/legislator-information/roster/individual/7514"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-24-lower-ocd-person-01d8976a-5dc4-4e73-b9db-770ff58a89fc": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/61c5bfdf-8d89-4ca3-b235-c55eb13c485e",
            "firstName": "Steve",
            "lastName": "Gist",
            "fullName": "Steve Gist",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-MT-25-lower-ocd-person-61c5bfdf-8d89-4ca3-b235-c55eb13c485e",
              "builtID": "mt-lower-25",
              "externalID": "ocd-person/61c5bfdf-8d89-4ca3-b235-c55eb13c485e",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.gist@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6903",
                "https://leg.mt.gov/legislator-information/roster/individual/7518"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-25-lower-ocd-person-61c5bfdf-8d89-4ca3-b235-c55eb13c485e": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/0602a63c-7e36-4bb5-9a8a-21b7f684ff75",
            "firstName": "George Peter",
            "lastName": "Nikolakakos",
            "fullName": "George Nikolakakos",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-MT-26-lower-ocd-person-0602a63c-7e36-4bb5-9a8a-21b7f684ff75",
              "builtID": "mt-lower-26",
              "externalID": "ocd-person/0602a63c-7e36-4bb5-9a8a-21b7f684ff75",
              "geometry": null
            },
            "contactInfo": {
              "email": "george.nikolakakos@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7639"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-26-lower-ocd-person-0602a63c-7e36-4bb5-9a8a-21b7f684ff75": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/92191849-ad4d-479b-9168-582773a9f6bb",
            "firstName": "Joshua J.",
            "lastName": "Kassmier",
            "fullName": "Josh Kassmier",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-MT-27-lower-ocd-person-92191849-ad4d-479b-9168-582773a9f6bb",
              "builtID": "mt-lower-27",
              "externalID": "ocd-person/92191849-ad4d-479b-9168-582773a9f6bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "josh.kassmier@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5086",
                "https://leg.mt.gov/legislator-information/roster/individual/6838",
                "https://leg.mt.gov/legislator-information/roster/individual/7536"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-27-lower-ocd-person-92191849-ad4d-479b-9168-582773a9f6bb": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/4d9cab5d-56c1-4262-9063-052b13b7d0c2",
            "firstName": "Paul",
            "lastName": "Tuss",
            "fullName": "Paul Tuss",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-MT-28-lower-ocd-person-4d9cab5d-56c1-4262-9063-052b13b7d0c2",
              "builtID": "mt-lower-28",
              "externalID": "ocd-person/4d9cab5d-56c1-4262-9063-052b13b7d0c2",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.tuss@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7647"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-28-lower-ocd-person-4d9cab5d-56c1-4262-9063-052b13b7d0c2": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/a5539d52-ce2e-4170-9035-c8c33a2d4dc6",
            "firstName": "Edward",
            "lastName": "Butcher",
            "fullName": "Ed Butcher",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-MT-29-lower-ocd-person-a5539d52-ce2e-4170-9035-c8c33a2d4dc6",
              "builtID": "mt-lower-29",
              "externalID": "ocd-person/a5539d52-ce2e-4170-9035-c8c33a2d4dc6",
              "geometry": null
            },
            "contactInfo": {
              "email": "ed.butcher@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7656"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-29-lower-ocd-person-a5539d52-ce2e-4170-9035-c8c33a2d4dc6": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/a1d83e65-aea1-4e5e-9854-f6474d52a9e3",
            "firstName": "James H.",
            "lastName": "Bergstrom",
            "fullName": "James Bergstrom",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-MT-30-lower-ocd-person-a1d83e65-aea1-4e5e-9854-f6474d52a9e3",
              "builtID": "mt-lower-30",
              "externalID": "ocd-person/a1d83e65-aea1-4e5e-9854-f6474d52a9e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.bergstrom@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7620"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-30-lower-ocd-person-a1d83e65-aea1-4e5e-9854-f6474d52a9e3": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/e9b6d4cf-9585-4cc4-8802-598065431e0e",
            "firstName": "Frank J.",
            "lastName": "Smith",
            "fullName": "Frank Smith",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-MT-31-lower-ocd-person-e9b6d4cf-9585-4cc4-8802-598065431e0e",
              "builtID": "mt-lower-31",
              "externalID": "ocd-person/e9b6d4cf-9585-4cc4-8802-598065431e0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "frank.smith@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/css/Sessions/62nd/leg_info.asp?HouseID=0&SessionID=105&LAWSID=7879",
                "https://leg.mt.gov/legislator-information/roster/individual/4725",
                "https://leg.mt.gov/legislator-information/roster/individual/5011",
                "https://leg.mt.gov/legislator-information/roster/individual/6827",
                "https://leg.mt.gov/legislator-information/roster/individual/7591"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-31-lower-ocd-person-e9b6d4cf-9585-4cc4-8802-598065431e0e": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/aad657b3-2d8f-48cf-99b3-650ccddf258b",
            "firstName": "Jonathan",
            "lastName": "Windy Boy",
            "fullName": "Jonathan Windy Boy",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-MT-32-lower-ocd-person-aad657b3-2d8f-48cf-99b3-650ccddf258b",
              "builtID": "mt-lower-32",
              "externalID": "ocd-person/aad657b3-2d8f-48cf-99b3-650ccddf258b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jonathan.windyboy@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4774",
                "https://leg.mt.gov/legislator-information/roster/individual/5091",
                "https://leg.mt.gov/legislator-information/roster/individual/6857",
                "https://leg.mt.gov/legislator-information/roster/individual/7612"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-32-lower-ocd-person-aad657b3-2d8f-48cf-99b3-650ccddf258b": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/dfb34737-af97-4a3b-b4cc-6db721e2cb8a",
            "firstName": "Casey James",
            "lastName": "Knudsen",
            "fullName": "Casey Knudsen",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-MT-33-lower-ocd-person-dfb34737-af97-4a3b-b4cc-6db721e2cb8a",
              "builtID": "mt-lower-33",
              "externalID": "ocd-person/dfb34737-af97-4a3b-b4cc-6db721e2cb8a",
              "geometry": null
            },
            "contactInfo": {
              "email": "casey.knudsen@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4775",
                "https://leg.mt.gov/legislator-information/roster/individual/5092",
                "https://leg.mt.gov/legislator-information/roster/individual/6840",
                "https://leg.mt.gov/legislator-information/roster/individual/7543"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-33-lower-ocd-person-dfb34737-af97-4a3b-b4cc-6db721e2cb8a": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/c352f893-5a02-464f-9a21-37177ca55726",
            "firstName": "Rhonda",
            "lastName": "Knudsen",
            "fullName": "Rhonda Knudsen",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-MT-34-lower-ocd-person-c352f893-5a02-464f-9a21-37177ca55726",
              "builtID": "mt-lower-34",
              "externalID": "ocd-person/c352f893-5a02-464f-9a21-37177ca55726",
              "geometry": null
            },
            "contactInfo": {
              "email": "rhonda.knudsen@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5093",
                "https://leg.mt.gov/legislator-information/roster/individual/6841",
                "https://leg.mt.gov/legislator-information/roster/individual/7544"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-34-lower-ocd-person-c352f893-5a02-464f-9a21-37177ca55726": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/578dada9-9f18-470a-ae8f-e8516a5e9635",
            "firstName": "Brandon",
            "lastName": "Ler",
            "fullName": "Brandon Ler",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-MT-35-lower-ocd-person-578dada9-9f18-470a-ae8f-e8516a5e9635",
              "builtID": "mt-lower-35",
              "externalID": "ocd-person/578dada9-9f18-470a-ae8f-e8516a5e9635",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandon.ler@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6842",
                "https://leg.mt.gov/legislator-information/roster/individual/7548"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-35-lower-ocd-person-578dada9-9f18-470a-ae8f-e8516a5e9635": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/cf7f704c-87ac-4bcd-821a-9377bbbee43f",
            "firstName": "Bob",
            "lastName": "Phalen",
            "fullName": "Bob Phalen",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-MT-36-lower-ocd-person-cf7f704c-87ac-4bcd-821a-9377bbbee43f",
              "builtID": "mt-lower-36",
              "externalID": "ocd-person/cf7f704c-87ac-4bcd-821a-9377bbbee43f",
              "geometry": null
            },
            "contactInfo": {
              "email": "bobby.phalen@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6843",
                "https://leg.mt.gov/legislator-information/roster/individual/7575"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-36-lower-ocd-person-cf7f704c-87ac-4bcd-821a-9377bbbee43f": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/d6bca004-d3ef-4015-9c88-b4d536110436",
            "firstName": "Jerry",
            "lastName": "Schillinger",
            "fullName": "Jerry Schillinger",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-MT-37-lower-ocd-person-d6bca004-d3ef-4015-9c88-b4d536110436",
              "builtID": "mt-lower-37",
              "externalID": "ocd-person/d6bca004-d3ef-4015-9c88-b4d536110436",
              "geometry": null
            },
            "contactInfo": {
              "email": "jerry.schillinger@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6907",
                "https://leg.mt.gov/legislator-information/roster/individual/7587"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-37-lower-ocd-person-d6bca004-d3ef-4015-9c88-b4d536110436": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/d5507f4a-a8ab-4249-a9ae-3ea728a6728b",
            "firstName": "Greg",
            "lastName": "Kmetz",
            "fullName": "Greg Kmetz",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-MT-38-lower-ocd-person-d5507f4a-a8ab-4249-a9ae-3ea728a6728b",
              "builtID": "mt-lower-38",
              "externalID": "ocd-person/d5507f4a-a8ab-4249-a9ae-3ea728a6728b",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.kmetz@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7633"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-38-lower-ocd-person-d5507f4a-a8ab-4249-a9ae-3ea728a6728b": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/5492c1bb-9eb1-4cf9-a689-0de78812382f",
            "firstName": "Gary",
            "lastName": "Parry",
            "fullName": "Gary Parry",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-MT-39-lower-ocd-person-5492c1bb-9eb1-4cf9-a689-0de78812382f",
              "builtID": "mt-lower-39",
              "externalID": "ocd-person/5492c1bb-9eb1-4cf9-a689-0de78812382f",
              "geometry": null
            },
            "contactInfo": {
              "email": "gary.parry@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7641"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-39-lower-ocd-person-5492c1bb-9eb1-4cf9-a689-0de78812382f": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/8641b3dd-3687-4665-a96c-2e406740affc",
            "firstName": "Gregory",
            "lastName": "Oblander",
            "fullName": "Greg Oblander",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-MT-40-lower-ocd-person-8641b3dd-3687-4665-a96c-2e406740affc",
              "builtID": "mt-lower-40",
              "externalID": "ocd-person/8641b3dd-3687-4665-a96c-2e406740affc",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.oblander@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7640"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-40-lower-ocd-person-8641b3dd-3687-4665-a96c-2e406740affc": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/29925f97-152a-473e-951e-27d55dba532e",
            "firstName": "Gayle",
            "lastName": "Lammers",
            "fullName": "Gayle Lammers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://static.wixstatic.com/media/bf4594_51bb3c90d2b140bc9f3286324b5fb6f6~mv2.jpg/v1/crop/x_0,y_0,w_420,h_496,q_80,enc_auto/bf4594_51bb3c90d2b140bc9f3286324b5fb6f6~mv2.jpg",
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-MT-41-lower-ocd-person-29925f97-152a-473e-951e-27d55dba532e",
              "builtID": "mt-lower-41",
              "externalID": "ocd-person/29925f97-152a-473e-951e-27d55dba532e",
              "geometry": null
            },
            "contactInfo": {
              "email": "gayle.lammers@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": "137 S. Heimat Road, Hardin, MT 59034",
              "phone1": null,
              "phone2": "406-679-0020",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7810"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-41-lower-ocd-person-29925f97-152a-473e-951e-27d55dba532e": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/f635de95-f2af-4d28-842d-9b88246f4840",
            "firstName": "Sharon",
            "lastName": "Stewart Peregoy",
            "fullName": "Sharon Stewart Peregoy",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-MT-42-lower-ocd-person-f635de95-f2af-4d28-842d-9b88246f4840",
              "builtID": "mt-lower-42",
              "externalID": "ocd-person/f635de95-f2af-4d28-842d-9b88246f4840",
              "geometry": null
            },
            "contactInfo": {
              "email": "sharonstewartperegoy@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5101",
                "https://leg.mt.gov/legislator-information/roster/individual/6829",
                "https://leg.mt.gov/legislator-information/roster/individual/7596"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-42-lower-ocd-person-f635de95-f2af-4d28-842d-9b88246f4840": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/3f7c50cf-76f5-4948-9777-c4801fd63fdf",
            "firstName": "Kerri",
            "lastName": "Seekins-Crowe",
            "fullName": "Kerri Seekins-Crowe",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-MT-43-lower-ocd-person-3f7c50cf-76f5-4948-9777-c4801fd63fdf",
              "builtID": "mt-lower-43",
              "externalID": "ocd-person/3f7c50cf-76f5-4948-9777-c4801fd63fdf",
              "geometry": null
            },
            "contactInfo": {
              "email": "kerri.seekins-crowe@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6909",
                "https://leg.mt.gov/legislator-information/roster/individual/7588"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-43-lower-ocd-person-3f7c50cf-76f5-4948-9777-c4801fd63fdf": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/18659b1f-64e3-4f2d-88d8-972821b26500",
            "firstName": "Larry",
            "lastName": "Brewster",
            "fullName": "Larry Brewster",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-MT-44-lower-ocd-person-18659b1f-64e3-4f2d-88d8-972821b26500",
              "builtID": "mt-lower-44",
              "externalID": "ocd-person/18659b1f-64e3-4f2d-88d8-972821b26500",
              "geometry": null
            },
            "contactInfo": {
              "email": "larry.brewster@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6336",
                "https://leg.mt.gov/legislator-information/roster/individual/6910",
                "https://leg.mt.gov/legislator-information/roster/individual/7480"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-44-lower-ocd-person-18659b1f-64e3-4f2d-88d8-972821b26500": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/1e48939a-8455-4a22-b545-7a908f2b9462",
            "firstName": "Katie",
            "lastName": "Zolnikov",
            "fullName": "Katie Zolnikov",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-MT-45-lower-ocd-person-1e48939a-8455-4a22-b545-7a908f2b9462",
              "builtID": "mt-lower-45",
              "externalID": "ocd-person/1e48939a-8455-4a22-b545-7a908f2b9462",
              "geometry": null
            },
            "contactInfo": {
              "email": "katie.zolnikov@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6337",
                "https://leg.mt.gov/legislator-information/roster/individual/6846",
                "https://leg.mt.gov/legislator-information/roster/individual/7616"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-45-lower-ocd-person-1e48939a-8455-4a22-b545-7a908f2b9462": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/e0b3aa43-c4ac-42b9-934a-281d064e590f",
            "firstName": "William Walter",
            "lastName": "Mercer",
            "fullName": "Bill Mercer",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-MT-46-lower-ocd-person-e0b3aa43-c4ac-42b9-934a-281d064e590f",
              "builtID": "mt-lower-46",
              "externalID": "ocd-person/e0b3aa43-c4ac-42b9-934a-281d064e590f",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.mercer@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5105",
                "https://leg.mt.gov/legislator-information/roster/individual/6911",
                "https://leg.mt.gov/legislator-information/roster/individual/7561"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-46-lower-ocd-person-e0b3aa43-c4ac-42b9-934a-281d064e590f": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/9b4883f0-d68f-48df-b4f1-de6d401e420e",
            "firstName": "Denise",
            "lastName": "Baum",
            "fullName": "Denise Baum",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-MT-47-lower-ocd-person-9b4883f0-d68f-48df-b4f1-de6d401e420e",
              "builtID": "mt-lower-47",
              "externalID": "ocd-person/9b4883f0-d68f-48df-b4f1-de6d401e420e",
              "geometry": null
            },
            "contactInfo": {
              "email": "denise.baum@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7619"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-47-lower-ocd-person-9b4883f0-d68f-48df-b4f1-de6d401e420e": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/b0d78104-aa08-4d01-9b7e-25d9aa422806",
            "firstName": "Jodee",
            "lastName": "Etchart",
            "fullName": "Jodee Etchart",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-MT-48-lower-ocd-person-b0d78104-aa08-4d01-9b7e-25d9aa422806",
              "builtID": "mt-lower-48",
              "externalID": "ocd-person/b0d78104-aa08-4d01-9b7e-25d9aa422806",
              "geometry": null
            },
            "contactInfo": {
              "email": "jodee.etchart@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7625"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-48-lower-ocd-person-b0d78104-aa08-4d01-9b7e-25d9aa422806": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/86031b2b-034f-4791-8c0e-b0dd4d776eb6",
            "firstName": "Emma",
            "lastName": "Kerr-Carpenter",
            "fullName": "Emma Kerr-Carpenter",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-MT-49-lower-ocd-person-86031b2b-034f-4791-8c0e-b0dd4d776eb6",
              "builtID": "mt-lower-49",
              "externalID": "ocd-person/86031b2b-034f-4791-8c0e-b0dd4d776eb6",
              "geometry": null
            },
            "contactInfo": {
              "email": "emma.kc@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5007",
                "https://leg.mt.gov/legislator-information/roster/individual/5108",
                "https://leg.mt.gov/legislator-information/roster/individual/6860",
                "https://leg.mt.gov/legislator-information/roster/individual/7541"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-49-lower-ocd-person-86031b2b-034f-4791-8c0e-b0dd4d776eb6": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/d4c52ded-4a5c-4451-92b7-1f50d6dcbc7d",
            "firstName": "Naarah Nethanieh",
            "lastName": "Hastings",
            "fullName": "Naarah Hastings",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-MT-50-lower-ocd-person-d4c52ded-4a5c-4451-92b7-1f50d6dcbc7d",
              "builtID": "mt-lower-50",
              "externalID": "ocd-person/d4c52ded-4a5c-4451-92b7-1f50d6dcbc7d",
              "geometry": null
            },
            "contactInfo": {
              "email": "naarah.hastings@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7657"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-50-lower-ocd-person-d4c52ded-4a5c-4451-92b7-1f50d6dcbc7d": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/3b95ca27-a496-4781-8fda-7d98db846f50",
            "firstName": "Michael",
            "lastName": "Yakawich",
            "fullName": "Mike Yakawich",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-MT-51-lower-ocd-person-3b95ca27-a496-4781-8fda-7d98db846f50",
              "builtID": "mt-lower-51",
              "externalID": "ocd-person/3b95ca27-a496-4781-8fda-7d98db846f50",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.yakawich@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7650"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-51-lower-ocd-person-3b95ca27-a496-4781-8fda-7d98db846f50": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/a010acb1-3638-4369-ac44-23ce1661b36f",
            "firstName": "Sherry",
            "lastName": "Essmann",
            "fullName": "Sherry Essmann",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-MT-52-lower-ocd-person-a010acb1-3638-4369-ac44-23ce1661b36f",
              "builtID": "mt-lower-52",
              "externalID": "ocd-person/a010acb1-3638-4369-ac44-23ce1661b36f",
              "geometry": null
            },
            "contactInfo": {
              "email": "sherry.essmann@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7499"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-52-lower-ocd-person-a010acb1-3638-4369-ac44-23ce1661b36f": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/e3347d94-a591-4c55-98fe-910536a94b2c",
            "firstName": "Nelly",
            "lastName": "Nicol",
            "fullName": "Nelly Nicol",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-MT-53-lower-ocd-person-e3347d94-a591-4c55-98fe-910536a94b2c",
              "builtID": "mt-lower-53",
              "externalID": "ocd-person/e3347d94-a591-4c55-98fe-910536a94b2c",
              "geometry": null
            },
            "contactInfo": {
              "email": "nelly.nicol@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7638"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-53-lower-ocd-person-e3347d94-a591-4c55-98fe-910536a94b2c": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/a1b59e61-3b93-4e73-b9ee-03d6dd2c464a",
            "firstName": "Terrill",
            "lastName": "Moore",
            "fullName": "Terry Moore",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-MT-54-lower-ocd-person-a1b59e61-3b93-4e73-b9ee-03d6dd2c464a",
              "builtID": "mt-lower-54",
              "externalID": "ocd-person/a1b59e61-3b93-4e73-b9ee-03d6dd2c464a",
              "geometry": null
            },
            "contactInfo": {
              "email": "terry.moore@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5113",
                "https://leg.mt.gov/legislator-information/roster/individual/6915",
                "https://leg.mt.gov/legislator-information/roster/individual/7565"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-54-lower-ocd-person-a1b59e61-3b93-4e73-b9ee-03d6dd2c464a": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/06d3f385-e129-46a1-b8a3-88d1b5efa2c8",
            "firstName": "Lee",
            "lastName": "Deming",
            "fullName": "Lee Deming",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-MT-55-lower-ocd-person-06d3f385-e129-46a1-b8a3-88d1b5efa2c8",
              "builtID": "mt-lower-55",
              "externalID": "ocd-person/06d3f385-e129-46a1-b8a3-88d1b5efa2c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "lee.deming@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7623"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-55-lower-ocd-person-06d3f385-e129-46a1-b8a3-88d1b5efa2c8": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/b4f79bac-c985-4bdc-9505-145cdd861198",
            "firstName": "Sue",
            "lastName": "Vinton",
            "fullName": "Sue Vinton",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-MT-56-lower-ocd-person-b4f79bac-c985-4bdc-9505-145cdd861198",
              "builtID": "mt-lower-56",
              "externalID": "ocd-person/b4f79bac-c985-4bdc-9505-145cdd861198",
              "geometry": null
            },
            "contactInfo": {
              "email": "sue.vinton@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4798",
                "https://leg.mt.gov/legislator-information/roster/individual/5115",
                "https://leg.mt.gov/legislator-information/roster/individual/6849",
                "https://leg.mt.gov/legislator-information/roster/individual/7606"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-56-lower-ocd-person-b4f79bac-c985-4bdc-9505-145cdd861198": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/e47950ef-92f1-41d1-8f1c-5b579fb80e57",
            "firstName": "Fiona B.",
            "lastName": "Nave",
            "fullName": "Fiona Nave",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-MT-57-lower-ocd-person-e47950ef-92f1-41d1-8f1c-5b579fb80e57",
              "builtID": "mt-lower-57",
              "externalID": "ocd-person/e47950ef-92f1-41d1-8f1c-5b579fb80e57",
              "geometry": null
            },
            "contactInfo": {
              "email": "fiona.nave@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6850",
                "https://leg.mt.gov/legislator-information/roster/individual/7567"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-57-lower-ocd-person-e47950ef-92f1-41d1-8f1c-5b579fb80e57": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/e46e64b0-2c6e-4581-b281-bc4c0993fa5b",
            "firstName": "Brad",
            "lastName": "Barker",
            "fullName": "Brad Barker",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-MT-58-lower-ocd-person-e46e64b0-2c6e-4581-b281-bc4c0993fa5b",
              "builtID": "mt-lower-58",
              "externalID": "ocd-person/e46e64b0-2c6e-4581-b281-bc4c0993fa5b",
              "geometry": null
            },
            "contactInfo": {
              "email": "brad.barker@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mtgop.org/leaders/brad-barker/",
                "https://leg.mt.gov/legislator-information/roster/individual/7618"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-58-lower-ocd-person-e46e64b0-2c6e-4581-b281-bc4c0993fa5b": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/1cd0aa0a-36c3-414d-9cde-8dd2bb3b080a",
            "firstName": "Martin Charles",
            "lastName": "Malone",
            "fullName": "Marty Malone",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-MT-59-lower-ocd-person-1cd0aa0a-36c3-414d-9cde-8dd2bb3b080a",
              "builtID": "mt-lower-59",
              "externalID": "ocd-person/1cd0aa0a-36c3-414d-9cde-8dd2bb3b080a",
              "geometry": null
            },
            "contactInfo": {
              "email": "marty.malone@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6917",
                "https://leg.mt.gov/legislator-information/roster/individual/7552"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-59-lower-ocd-person-1cd0aa0a-36c3-414d-9cde-8dd2bb3b080a": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/f48baca5-ca09-4494-a6d2-a6866f06469f",
            "firstName": "Laurie Friedman",
            "lastName": "Bishop",
            "fullName": "Laurie Bishop",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-MT-60-lower-ocd-person-f48baca5-ca09-4494-a6d2-a6866f06469f",
              "builtID": "mt-lower-60",
              "externalID": "ocd-person/f48baca5-ca09-4494-a6d2-a6866f06469f",
              "geometry": null
            },
            "contactInfo": {
              "email": "laurie.bishop@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4802",
                "https://leg.mt.gov/legislator-information/roster/individual/5119",
                "https://leg.mt.gov/legislator-information/roster/individual/6861",
                "https://leg.mt.gov/legislator-information/roster/individual/7477"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-60-lower-ocd-person-f48baca5-ca09-4494-a6d2-a6866f06469f": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/df262d31-e1ec-4411-b316-4176a5f494d2",
            "firstName": "Jim",
            "lastName": "Hamilton",
            "fullName": "Jim Hamilton",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-MT-61-lower-ocd-person-df262d31-e1ec-4411-b316-4176a5f494d2",
              "builtID": "mt-lower-61",
              "externalID": "ocd-person/df262d31-e1ec-4411-b316-4176a5f494d2",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.hamilton@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4803",
                "https://leg.mt.gov/legislator-information/roster/individual/5120",
                "https://leg.mt.gov/legislator-information/roster/individual/6830",
                "https://leg.mt.gov/legislator-information/roster/individual/7523"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-61-lower-ocd-person-df262d31-e1ec-4411-b316-4176a5f494d2": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/8ed38f70-33c3-4e70-a7da-cbb7eaffec5b",
            "firstName": "Edward",
            "lastName": "Stafman",
            "fullName": "Ed Stafman",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-MT-62-lower-ocd-person-8ed38f70-33c3-4e70-a7da-cbb7eaffec5b",
              "builtID": "mt-lower-62",
              "externalID": "ocd-person/8ed38f70-33c3-4e70-a7da-cbb7eaffec5b",
              "geometry": null
            },
            "contactInfo": {
              "email": "ed.stafman@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6862",
                "https://leg.mt.gov/legislator-information/roster/individual/7595"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-62-lower-ocd-person-8ed38f70-33c3-4e70-a7da-cbb7eaffec5b": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/8df546a1-e199-4f1c-824b-1ec579b2e5d2",
            "firstName": "Alice",
            "lastName": "Buckley",
            "fullName": "Alice Buckley",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-MT-63-lower-ocd-person-8df546a1-e199-4f1c-824b-1ec579b2e5d2",
              "builtID": "mt-lower-63",
              "externalID": "ocd-person/8df546a1-e199-4f1c-824b-1ec579b2e5d2",
              "geometry": null
            },
            "contactInfo": {
              "email": "alice.buckley@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6863",
                "https://leg.mt.gov/legislator-information/roster/individual/7483"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-63-lower-ocd-person-8df546a1-e199-4f1c-824b-1ec579b2e5d2": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/60009e1c-4fef-4511-b59b-02b39b406a1d",
            "firstName": "Jane",
            "lastName": "Gillette",
            "fullName": "Jane Gillette",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-MT-64-lower-ocd-person-60009e1c-4fef-4511-b59b-02b39b406a1d",
              "builtID": "mt-lower-64",
              "externalID": "ocd-person/60009e1c-4fef-4511-b59b-02b39b406a1d",
              "geometry": null
            },
            "contactInfo": {
              "email": "jane.gillette@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6918",
                "https://leg.mt.gov/legislator-information/roster/individual/7517"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-64-lower-ocd-person-60009e1c-4fef-4511-b59b-02b39b406a1d": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/ef4c6827-f405-491c-a349-d1ede87549c1",
            "firstName": "Kelly",
            "lastName": "Kortum",
            "fullName": "Kelly Kortum",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-MT-65-lower-ocd-person-ef4c6827-f405-491c-a349-d1ede87549c1",
              "builtID": "mt-lower-65",
              "externalID": "ocd-person/ef4c6827-f405-491c-a349-d1ede87549c1",
              "geometry": null
            },
            "contactInfo": {
              "email": "kelly.kortum@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6864",
                "https://leg.mt.gov/legislator-information/roster/individual/7545"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-65-lower-ocd-person-ef4c6827-f405-491c-a349-d1ede87549c1": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/1cc82ae1-087a-497e-9f01-6a96201815e3",
            "firstName": "Eric",
            "lastName": "Matthews",
            "fullName": "Eric Matthews",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-MT-66-lower-ocd-person-1cc82ae1-087a-497e-9f01-6a96201815e3",
              "builtID": "mt-lower-66",
              "externalID": "ocd-person/1cc82ae1-087a-497e-9f01-6a96201815e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.matthews@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7636"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-66-lower-ocd-person-1cc82ae1-087a-497e-9f01-6a96201815e3": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/8274985f-2a84-4d59-a4e4-d1ba9555e64b",
            "firstName": "Jedediah Lee",
            "lastName": "Hinkle",
            "fullName": "Jedediah Hinkle",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-MT-67-lower-ocd-person-8274985f-2a84-4d59-a4e4-d1ba9555e64b",
              "builtID": "mt-lower-67",
              "externalID": "ocd-person/8274985f-2a84-4d59-a4e4-d1ba9555e64b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jedediah.hinkle@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4706",
                "https://leg.mt.gov/legislator-information/roster/individual/6919",
                "https://leg.mt.gov/legislator-information/roster/individual/7531"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-67-lower-ocd-person-8274985f-2a84-4d59-a4e4-d1ba9555e64b": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/b6bacabc-6ff6-4bc6-9631-fbbf067f9f0e",
            "firstName": "Caleb L.",
            "lastName": "Hinkle",
            "fullName": "Caleb Hinkle",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-MT-68-lower-ocd-person-b6bacabc-6ff6-4bc6-9631-fbbf067f9f0e",
              "builtID": "mt-lower-68",
              "externalID": "ocd-person/b6bacabc-6ff6-4bc6-9631-fbbf067f9f0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "caleb.hinkle@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6920",
                "https://leg.mt.gov/legislator-information/roster/individual/7530"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-68-lower-ocd-person-b6bacabc-6ff6-4bc6-9631-fbbf067f9f0e": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/50855ba0-9cce-4f96-868e-0c361d604c11",
            "firstName": "Jennifer",
            "lastName": "Carlson",
            "fullName": "Jennifer Carlson",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-MT-69-lower-ocd-person-50855ba0-9cce-4f96-868e-0c361d604c11",
              "builtID": "mt-lower-69",
              "externalID": "ocd-person/50855ba0-9cce-4f96-868e-0c361d604c11",
              "geometry": null
            },
            "contactInfo": {
              "email": "jennifer.carlson@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6851",
                "https://leg.mt.gov/legislator-information/roster/individual/7486"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-69-lower-ocd-person-50855ba0-9cce-4f96-868e-0c361d604c11": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/7f228ad4-2fec-4ec3-8ae9-18cef8e8969a",
            "firstName": "Julie Darling",
            "lastName": "Dooling",
            "fullName": "Julie Dooling",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-MT-70-lower-ocd-person-7f228ad4-2fec-4ec3-8ae9-18cef8e8969a",
              "builtID": "mt-lower-70",
              "externalID": "ocd-person/7f228ad4-2fec-4ec3-8ae9-18cef8e8969a",
              "geometry": null
            },
            "contactInfo": {
              "email": "julie.dooling@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5129",
                "https://leg.mt.gov/legislator-information/roster/individual/6852",
                "https://leg.mt.gov/legislator-information/roster/individual/7492"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-70-lower-ocd-person-7f228ad4-2fec-4ec3-8ae9-18cef8e8969a": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/89cfc24f-851d-46c8-ad39-9cc888a437a5",
            "firstName": "Kenneth M.",
            "lastName": "Walsh",
            "fullName": "Ken Walsh",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-MT-71-lower-ocd-person-89cfc24f-851d-46c8-ad39-9cc888a437a5",
              "builtID": "mt-lower-71",
              "externalID": "ocd-person/89cfc24f-851d-46c8-ad39-9cc888a437a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "kenneth.walsh@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6921",
                "https://leg.mt.gov/legislator-information/roster/individual/7607"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-71-lower-ocd-person-89cfc24f-851d-46c8-ad39-9cc888a437a5": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/7b74ff6f-933d-4d43-80ec-34ae6267f546",
            "firstName": "Tom",
            "lastName": "Welch",
            "fullName": "Tom Welch",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-MT-72-lower-ocd-person-7b74ff6f-933d-4d43-80ec-34ae6267f546",
              "builtID": "mt-lower-72",
              "externalID": "ocd-person/7b74ff6f-933d-4d43-80ec-34ae6267f546",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.welch@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4814",
                "https://leg.mt.gov/legislator-information/roster/individual/5131",
                "https://leg.mt.gov/legislator-information/roster/individual/6853",
                "https://leg.mt.gov/legislator-information/roster/individual/7611"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-72-lower-ocd-person-7b74ff6f-933d-4d43-80ec-34ae6267f546": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/9da66594-712c-4dee-9ed9-7cff10abc43a",
            "firstName": "Jennifer",
            "lastName": "Lynch",
            "fullName": "Jennifer Lynch",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-MT-73-lower-ocd-person-9da66594-712c-4dee-9ed9-7cff10abc43a",
              "builtID": "mt-lower-73",
              "externalID": "ocd-person/9da66594-712c-4dee-9ed9-7cff10abc43a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jennifer.lynch@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7634"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-73-lower-ocd-person-9da66594-712c-4dee-9ed9-7cff10abc43a": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/ec4cdcd7-d8b8-4c37-84ce-a99749b22ca3",
            "firstName": "Derek J.",
            "lastName": "Harvey",
            "fullName": "Derek Harvey",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-MT-74-lower-ocd-person-ec4cdcd7-d8b8-4c37-84ce-a99749b22ca3",
              "builtID": "mt-lower-74",
              "externalID": "ocd-person/ec4cdcd7-d8b8-4c37-84ce-a99749b22ca3",
              "geometry": null
            },
            "contactInfo": {
              "email": "derek.harvey@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5133",
                "https://leg.mt.gov/legislator-information/roster/individual/6866",
                "https://leg.mt.gov/legislator-information/roster/individual/7524"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-74-lower-ocd-person-ec4cdcd7-d8b8-4c37-84ce-a99749b22ca3": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/cbd45abb-98a1-4826-9ff7-64d24c8029f3",
            "firstName": "Marta",
            "lastName": "Bertoglio",
            "fullName": "Marta Bertoglio",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-MT-75-lower-ocd-person-cbd45abb-98a1-4826-9ff7-64d24c8029f3",
              "builtID": "mt-lower-75",
              "externalID": "ocd-person/cbd45abb-98a1-4826-9ff7-64d24c8029f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "marta.bertoglio@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6922",
                "https://leg.mt.gov/legislator-information/roster/individual/7475"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-75-lower-ocd-person-cbd45abb-98a1-4826-9ff7-64d24c8029f3": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/228fbd51-8789-433e-a790-52e395da0dd0",
            "firstName": "Donavon",
            "lastName": "Hawk",
            "fullName": "Donavon Hawk",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-MT-76-lower-ocd-person-228fbd51-8789-433e-a790-52e395da0dd0",
              "builtID": "mt-lower-76",
              "externalID": "ocd-person/228fbd51-8789-433e-a790-52e395da0dd0",
              "geometry": null
            },
            "contactInfo": {
              "email": "donavon.hawk@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6867",
                "https://leg.mt.gov/legislator-information/roster/individual/7525"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-76-lower-ocd-person-228fbd51-8789-433e-a790-52e395da0dd0": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/bcfec03b-c046-4af3-9653-a8e5e4fa97b6",
            "firstName": "John",
            "lastName": "Fitzpatrick",
            "fullName": "John Fitzpatrick",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-MT-77-lower-ocd-person-bcfec03b-c046-4af3-9653-a8e5e4fa97b6",
              "builtID": "mt-lower-77",
              "externalID": "ocd-person/bcfec03b-c046-4af3-9653-a8e5e4fa97b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.fitzpatrick@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7627"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-77-lower-ocd-person-bcfec03b-c046-4af3-9653-a8e5e4fa97b6": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/ad705006-a048-4d65-86aa-6c6c9d05643b",
            "firstName": "Gregory L.",
            "lastName": "Frazer",
            "fullName": "Greg Frazer",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-MT-78-lower-ocd-person-ad705006-a048-4d65-86aa-6c6c9d05643b",
              "builtID": "mt-lower-78",
              "externalID": "ocd-person/ad705006-a048-4d65-86aa-6c6c9d05643b",
              "geometry": null
            },
            "contactInfo": {
              "email": "gregory.frazer@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6923",
                "https://leg.mt.gov/legislator-information/roster/individual/7511"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-78-lower-ocd-person-ad705006-a048-4d65-86aa-6c6c9d05643b": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/51492780-1440-4625-b487-63640ee2b11d",
            "firstName": "Laura",
            "lastName": "Smith",
            "fullName": "Laura Smith",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-MT-79-lower-ocd-person-51492780-1440-4625-b487-63640ee2b11d",
              "builtID": "mt-lower-79",
              "externalID": "ocd-person/51492780-1440-4625-b487-63640ee2b11d",
              "geometry": null
            },
            "contactInfo": {
              "email": "laura.smith@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7644"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-79-lower-ocd-person-51492780-1440-4625-b487-63640ee2b11d": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/01b5e40e-16c0-48df-8e7b-28877a05fe28",
            "firstName": "Zachary",
            "lastName": "Wirth",
            "fullName": "Zack Wirth",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-MT-80-lower-ocd-person-01b5e40e-16c0-48df-8e7b-28877a05fe28",
              "builtID": "mt-lower-80",
              "externalID": "ocd-person/01b5e40e-16c0-48df-8e7b-28877a05fe28",
              "geometry": null
            },
            "contactInfo": {
              "email": "zack.wirth@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7654"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-80-lower-ocd-person-01b5e40e-16c0-48df-8e7b-28877a05fe28": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/81f1ba67-a22c-4bdb-89b2-3351d7e1263d",
            "firstName": "Melissa",
            "lastName": "Romano",
            "fullName": "Melissa Romano",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-MT-81-lower-ocd-person-81f1ba67-a22c-4bdb-89b2-3351d7e1263d",
              "builtID": "mt-lower-81",
              "externalID": "ocd-person/81f1ba67-a22c-4bdb-89b2-3351d7e1263d",
              "geometry": null
            },
            "contactInfo": {
              "email": "melissa.romano@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7642"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-81-lower-ocd-person-81f1ba67-a22c-4bdb-89b2-3351d7e1263d": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/c2eda1fb-6dc5-4326-a4b5-a295f26ee399",
            "firstName": "Mary M.",
            "lastName": "Caferro",
            "fullName": "Mary Caferro",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-MT-82-lower-ocd-person-c2eda1fb-6dc5-4326-a4b5-a295f26ee399",
              "builtID": "mt-lower-82",
              "externalID": "ocd-person/c2eda1fb-6dc5-4326-a4b5-a295f26ee399",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.caferro@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5140",
                "https://leg.mt.gov/legislator-information/roster/individual/6870",
                "https://leg.mt.gov/legislator-information/roster/individual/7485"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-82-lower-ocd-person-c2eda1fb-6dc5-4326-a4b5-a295f26ee399": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/9ace0ebd-f11a-4c74-9234-782fe7996efd",
            "firstName": "Kim",
            "lastName": "Abbott",
            "fullName": "Kim Abbott",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-MT-83-lower-ocd-person-9ace0ebd-f11a-4c74-9234-782fe7996efd",
              "builtID": "mt-lower-83",
              "externalID": "ocd-person/9ace0ebd-f11a-4c74-9234-782fe7996efd",
              "geometry": null
            },
            "contactInfo": {
              "email": "kim.abbott@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4825",
                "https://leg.mt.gov/legislator-information/roster/individual/5142",
                "https://leg.mt.gov/legislator-information/roster/individual/6872",
                "https://leg.mt.gov/legislator-information/roster/individual/7467"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-83-lower-ocd-person-9ace0ebd-f11a-4c74-9234-782fe7996efd": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/51c3b0b8-ee51-48bb-8b83-b50ea6f670ec",
            "firstName": "Jill",
            "lastName": "Cohenour",
            "fullName": "Jill Cohenour",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-MT-84-lower-ocd-person-51c3b0b8-ee51-48bb-8b83-b50ea6f670ec",
              "builtID": "mt-lower-84",
              "externalID": "ocd-person/51c3b0b8-ee51-48bb-8b83-b50ea6f670ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "jill.cohenour@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4703",
                "https://leg.mt.gov/legislator-information/roster/individual/5054",
                "https://leg.mt.gov/legislator-information/roster/individual/6800",
                "https://leg.mt.gov/legislator-information/roster/individual/7488"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-84-lower-ocd-person-51c3b0b8-ee51-48bb-8b83-b50ea6f670ec": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/27529c82-5ccb-4fa8-b1a7-3fdee6b44575",
            "firstName": "Michele R.",
            "lastName": "Binkley",
            "fullName": "Michele Binkley",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-MT-85-lower-ocd-person-27529c82-5ccb-4fa8-b1a7-3fdee6b44575",
              "builtID": "mt-lower-85",
              "externalID": "ocd-person/27529c82-5ccb-4fa8-b1a7-3fdee6b44575",
              "geometry": null
            },
            "contactInfo": {
              "email": "michele.binkley@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6925",
                "https://leg.mt.gov/legislator-information/roster/individual/7476"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-85-lower-ocd-person-27529c82-5ccb-4fa8-b1a7-3fdee6b44575": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/8583c5f0-fc55-4f5b-9664-19d44f7f25b3",
            "firstName": "David",
            "lastName": "Bedey",
            "fullName": "Dave Bedey",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-MT-86-lower-ocd-person-8583c5f0-fc55-4f5b-9664-19d44f7f25b3",
              "builtID": "mt-lower-86",
              "externalID": "ocd-person/8583c5f0-fc55-4f5b-9664-19d44f7f25b3",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.bedey@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5145",
                "https://leg.mt.gov/legislator-information/roster/individual/6854",
                "https://leg.mt.gov/legislator-information/roster/individual/7473"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-86-lower-ocd-person-8583c5f0-fc55-4f5b-9664-19d44f7f25b3": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/46695811-d427-4817-81a4-b983846796b5",
            "firstName": "Ron L.",
            "lastName": "Marshall",
            "fullName": "Ron Marshall",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-MT-87-lower-ocd-person-46695811-d427-4817-81a4-b983846796b5",
              "builtID": "mt-lower-87",
              "externalID": "ocd-person/46695811-d427-4817-81a4-b983846796b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "ron.marshall@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6926",
                "https://leg.mt.gov/legislator-information/roster/individual/7556"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-87-lower-ocd-person-46695811-d427-4817-81a4-b983846796b5": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/aa752348-5ef1-4ff2-adef-779e750129bb",
            "firstName": "Wayne",
            "lastName": "Rusk",
            "fullName": "Wayne Rusk",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-MT-88-lower-ocd-person-aa752348-5ef1-4ff2-adef-779e750129bb",
              "builtID": "mt-lower-88",
              "externalID": "ocd-person/aa752348-5ef1-4ff2-adef-779e750129bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "wayne.rusk@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7643"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-88-lower-ocd-person-aa752348-5ef1-4ff2-adef-779e750129bb": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/c76a4d75-0213-4a09-8426-315007fe7290",
            "firstName": "Katie",
            "lastName": "Sullivan",
            "fullName": "Katie Sullivan",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-MT-89-lower-ocd-person-c76a4d75-0213-4a09-8426-315007fe7290",
              "builtID": "mt-lower-89",
              "externalID": "ocd-person/c76a4d75-0213-4a09-8426-315007fe7290",
              "geometry": null
            },
            "contactInfo": {
              "email": "katie.sullivan@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5148",
                "https://leg.mt.gov/legislator-information/roster/individual/6874",
                "https://leg.mt.gov/legislator-information/roster/individual/7598"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-89-lower-ocd-person-c76a4d75-0213-4a09-8426-315007fe7290": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/e223fb67-4ad0-4f12-aa95-f0b8567d84c1",
            "firstName": "Marilyn",
            "lastName": "Marler",
            "fullName": "Marilyn Marler",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-MT-90-lower-ocd-person-e223fb67-4ad0-4f12-aa95-f0b8567d84c1",
              "builtID": "mt-lower-90",
              "externalID": "ocd-person/e223fb67-4ad0-4f12-aa95-f0b8567d84c1",
              "geometry": null
            },
            "contactInfo": {
              "email": "marilyn.marler@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5149",
                "https://leg.mt.gov/legislator-information/roster/individual/6875",
                "https://leg.mt.gov/legislator-information/roster/individual/7555"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-90-lower-ocd-person-e223fb67-4ad0-4f12-aa95-f0b8567d84c1": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/d640b7b9-98c0-48f4-820d-24440c180667",
            "firstName": "Connie",
            "lastName": "Keogh",
            "fullName": "Connie Keogh",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-MT-91-lower-ocd-person-d640b7b9-98c0-48f4-820d-24440c180667",
              "builtID": "mt-lower-91",
              "externalID": "ocd-person/d640b7b9-98c0-48f4-820d-24440c180667",
              "geometry": null
            },
            "contactInfo": {
              "email": "connie.keogh@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5150",
                "https://leg.mt.gov/legislator-information/roster/individual/6876",
                "https://leg.mt.gov/legislator-information/roster/individual/7539"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-91-lower-ocd-person-d640b7b9-98c0-48f4-820d-24440c180667": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/1fa9e318-076d-4702-a3df-b7931b617926",
            "firstName": "Mike",
            "lastName": "Hopkins",
            "fullName": "Mike Hopkins",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-MT-92-lower-ocd-person-1fa9e318-076d-4702-a3df-b7931b617926",
              "builtID": "mt-lower-92",
              "externalID": "ocd-person/1fa9e318-076d-4702-a3df-b7931b617926",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.hopkins@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4834",
                "https://leg.mt.gov/legislator-information/roster/individual/5151",
                "https://leg.mt.gov/legislator-information/roster/individual/6927",
                "https://leg.mt.gov/legislator-information/roster/individual/7532"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-92-lower-ocd-person-1fa9e318-076d-4702-a3df-b7931b617926": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/21e604a6-9cd0-49e7-b2b6-9dffd4419bee",
            "firstName": "Joe",
            "lastName": "Read",
            "fullName": "Joe Read",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-MT-93-lower-ocd-person-21e604a6-9cd0-49e7-b2b6-9dffd4419bee",
              "builtID": "mt-lower-93",
              "externalID": "ocd-person/21e604a6-9cd0-49e7-b2b6-9dffd4419bee",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.read@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5152",
                "https://leg.mt.gov/legislator-information/roster/individual/6928",
                "https://leg.mt.gov/legislator-information/roster/individual/7577"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-93-lower-ocd-person-21e604a6-9cd0-49e7-b2b6-9dffd4419bee": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/d7ca6eb8-615f-4b3c-8651-3a5f86577f0a",
            "firstName": "Thomas",
            "lastName": "France",
            "fullName": "Tom France",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-MT-94-lower-ocd-person-d7ca6eb8-615f-4b3c-8651-3a5f86577f0a",
              "builtID": "mt-lower-94",
              "externalID": "ocd-person/d7ca6eb8-615f-4b3c-8651-3a5f86577f0a",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.france@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6877",
                "https://leg.mt.gov/legislator-information/roster/individual/7510"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-94-lower-ocd-person-d7ca6eb8-615f-4b3c-8651-3a5f86577f0a": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/9f2826d8-7d78-45bd-b5a8-2bbc9f7eff2d",
            "firstName": "Sarah J.",
            "lastName": "Howell",
            "fullName": "S.J. Howell",
            "gender": "X",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-MT-95-lower-ocd-person-9f2826d8-7d78-45bd-b5a8-2bbc9f7eff2d",
              "builtID": "mt-lower-95",
              "externalID": "ocd-person/9f2826d8-7d78-45bd-b5a8-2bbc9f7eff2d",
              "geometry": null
            },
            "contactInfo": {
              "email": "sj.howell@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7630"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-95-lower-ocd-person-9f2826d8-7d78-45bd-b5a8-2bbc9f7eff2d": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/5692ad8b-7258-409d-bbf3-63d6748e8b68",
            "firstName": "Jonathan G.",
            "lastName": "Karlen",
            "fullName": "Jonathan Karlen",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-MT-96-lower-ocd-person-5692ad8b-7258-409d-bbf3-63d6748e8b68",
              "builtID": "mt-lower-96",
              "externalID": "ocd-person/5692ad8b-7258-409d-bbf3-63d6748e8b68",
              "geometry": null
            },
            "contactInfo": {
              "email": "jonathan.karlen@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7631"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-96-lower-ocd-person-5692ad8b-7258-409d-bbf3-63d6748e8b68": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/6410d3f3-a1a0-4cca-a8d7-6cc5a6c42804",
            "firstName": "Darrellyn",
            "lastName": "Hellegaard",
            "fullName": "Lyn Hellegaard",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-MT-97-lower-ocd-person-6410d3f3-a1a0-4cca-a8d7-6cc5a6c42804",
              "builtID": "mt-lower-97",
              "externalID": "ocd-person/6410d3f3-a1a0-4cca-a8d7-6cc5a6c42804",
              "geometry": null
            },
            "contactInfo": {
              "email": "lyn.hellegaard@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7629"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-97-lower-ocd-person-6410d3f3-a1a0-4cca-a8d7-6cc5a6c42804": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/ad9a1852-425f-4f4b-9b6e-270f2c282024",
            "firstName": "Bob",
            "lastName": "Carter",
            "fullName": "Bob Carter",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-MT-98-lower-ocd-person-ad9a1852-425f-4f4b-9b6e-270f2c282024",
              "builtID": "mt-lower-98",
              "externalID": "ocd-person/ad9a1852-425f-4f4b-9b6e-270f2c282024",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.carter@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7622"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-98-lower-ocd-person-ad9a1852-425f-4f4b-9b6e-270f2c282024": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/64bf2cec-e248-4708-9462-1c3a971ad653",
            "firstName": "Mark",
            "lastName": "Thane",
            "fullName": "Mark Thane",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-MT-99-lower-ocd-person-64bf2cec-e248-4708-9462-1c3a971ad653",
              "builtID": "mt-lower-99",
              "externalID": "ocd-person/64bf2cec-e248-4708-9462-1c3a971ad653",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.thane@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6880",
                "https://leg.mt.gov/legislator-information/roster/individual/7600"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-99-lower-ocd-person-64bf2cec-e248-4708-9462-1c3a971ad653": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/ba9f3268-99c4-4f05-ac5b-c552e8c3aedc",
            "firstName": "Zooey",
            "lastName": "Zephyr",
            "fullName": "Zooey Zephyr",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-MT-100-lower-ocd-person-ba9f3268-99c4-4f05-ac5b-c552e8c3aedc",
              "builtID": "mt-lower-100",
              "externalID": "ocd-person/ba9f3268-99c4-4f05-ac5b-c552e8c3aedc",
              "geometry": null
            },
            "contactInfo": {
              "email": "zooey.zephyr@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7651"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-MT-100-lower-ocd-person-ba9f3268-99c4-4f05-ac5b-c552e8c3aedc": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/54c93c1b-968f-4b39-a17f-26ebcb266f69",
            "firstName": "Mike",
            "lastName": "Cuffe",
            "fullName": "Mike Cuffe",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-MT-1-upper-ocd-person-54c93c1b-968f-4b39-a17f-26ebcb266f69",
              "builtID": "mt-upper-1",
              "externalID": "ocd-person/54c93c1b-968f-4b39-a17f-26ebcb266f69",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.cuffe@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5034",
                "https://leg.mt.gov/legislator-information/roster/individual/6803",
                "https://leg.mt.gov/legislator-information/roster/individual/7489"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-1-upper-ocd-person-54c93c1b-968f-4b39-a17f-26ebcb266f69": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/8cf123ff-85f6-4652-922a-87c0cb474573",
            "firstName": "Carl",
            "lastName": "Glimm",
            "fullName": "Carl Glimm",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-MT-2-upper-ocd-person-8cf123ff-85f6-4652-922a-87c0cb474573",
              "builtID": "mt-upper-2",
              "externalID": "ocd-person/8cf123ff-85f6-4652-922a-87c0cb474573",
              "geometry": null
            },
            "contactInfo": {
              "email": "carl.glimm@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4749",
                "https://leg.mt.gov/legislator-information/roster/individual/5064",
                "https://leg.mt.gov/legislator-information/roster/individual/6931",
                "https://leg.mt.gov/legislator-information/roster/individual/7519"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-2-upper-ocd-person-8cf123ff-85f6-4652-922a-87c0cb474573": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/d21c0b57-3b30-4cfc-895d-461eebd97461",
            "firstName": "Keith",
            "lastName": "Regier",
            "fullName": "Keith Regier",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-MT-3-upper-ocd-person-d21c0b57-3b30-4cfc-895d-461eebd97461",
              "builtID": "mt-upper-3",
              "externalID": "ocd-person/d21c0b57-3b30-4cfc-895d-461eebd97461",
              "geometry": null
            },
            "contactInfo": {
              "email": "keith.regier@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4844",
                "https://leg.mt.gov/legislator-information/roster/individual/5031",
                "https://leg.mt.gov/legislator-information/roster/individual/6932",
                "https://leg.mt.gov/legislator-information/roster/individual/7579"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-3-upper-ocd-person-d21c0b57-3b30-4cfc-895d-461eebd97461": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/54fe073e-e061-43aa-9299-42fe869102d0",
            "firstName": "John D.",
            "lastName": "Fuller",
            "fullName": "John Fuller",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-MT-4-upper-ocd-person-54fe073e-e061-43aa-9299-42fe869102d0",
              "builtID": "mt-upper-4",
              "externalID": "ocd-person/54fe073e-e061-43aa-9299-42fe869102d0",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.fuller@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5066",
                "https://leg.mt.gov/legislator-information/roster/individual/6833",
                "https://leg.mt.gov/legislator-information/roster/individual/7513"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-4-upper-ocd-person-54fe073e-e061-43aa-9299-42fe869102d0": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/9fe046dc-4a09-4f3a-8054-97718b4dc017",
            "firstName": "Mark R.",
            "lastName": "Noland",
            "fullName": "Mark Noland",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-MT-5-upper-ocd-person-9fe046dc-4a09-4f3a-8054-97718b4dc017",
              "builtID": "mt-upper-5",
              "externalID": "ocd-person/9fe046dc-4a09-4f3a-8054-97718b4dc017",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.noland@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4753",
                "https://leg.mt.gov/legislator-information/roster/individual/5068",
                "https://leg.mt.gov/legislator-information/roster/individual/5068",
                "https://leg.mt.gov/legislator-information/roster/individual/6893",
                "https://leg.mt.gov/legislator-information/roster/individual/7570"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-5-upper-ocd-person-9fe046dc-4a09-4f3a-8054-97718b4dc017": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/e085c6ea-bb53-4daf-8a02-5268818158bd",
            "firstName": "Gregory J.",
            "lastName": "Hertz",
            "fullName": "Greg Hertz",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-MT-6-upper-ocd-person-e085c6ea-bb53-4daf-8a02-5268818158bd",
              "builtID": "mt-upper-6",
              "externalID": "ocd-person/e085c6ea-bb53-4daf-8a02-5268818158bd",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.hertz@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4755",
                "https://leg.mt.gov/legislator-information/roster/individual/5070",
                "https://leg.mt.gov/legislator-information/roster/individual/6818",
                "https://leg.mt.gov/legislator-information/roster/individual/7528"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-6-upper-ocd-person-e085c6ea-bb53-4daf-8a02-5268818158bd": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/15efb0ab-2c00-48b2-9e3a-35c7d7a43bb6",
            "firstName": "Greg W.",
            "lastName": "Hinkle",
            "fullName": "Greg Hinkle",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-MT-7-upper-ocd-person-15efb0ab-2c00-48b2-9e3a-35c7d7a43bb6",
              "builtID": "mt-upper-7",
              "externalID": "ocd-person/15efb0ab-2c00-48b2-9e3a-35c7d7a43bb6",
              "geometry": null
            },
            "contactInfo": {
              "email": "ccwhi@blackfoot.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/css/Sessions/62nd/leg_info.asp?HouseID=0&SessionID=105&LAWSID=6467",
                "https://leg.mt.gov/legislator-information/roster/individual/3575"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-7-upper-ocd-person-15efb0ab-2c00-48b2-9e3a-35c7d7a43bb6": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/8a773a18-0be3-4292-9543-e6e5051e59f4",
            "firstName": "Susan",
            "lastName": "Webber",
            "fullName": "Susan Webber",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-MT-8-upper-ocd-person-8a773a18-0be3-4292-9543-e6e5051e59f4",
              "builtID": "mt-upper-8",
              "externalID": "ocd-person/8a773a18-0be3-4292-9543-e6e5051e59f4",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.webber@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5037",
                "https://leg.mt.gov/legislator-information/roster/individual/6793",
                "https://leg.mt.gov/legislator-information/roster/individual/7609"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-8-upper-ocd-person-8a773a18-0be3-4292-9543-e6e5051e59f4": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/0bb87197-276c-4ea7-9e93-cf782879e010",
            "firstName": "Bruce",
            "lastName": "Gillespie",
            "fullName": "Butch Gillespie",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-MT-9-upper-ocd-person-0bb87197-276c-4ea7-9e93-cf782879e010",
              "builtID": "mt-upper-9",
              "externalID": "ocd-person/0bb87197-276c-4ea7-9e93-cf782879e010",
              "geometry": null
            },
            "contactInfo": {
              "email": "bruce.gillespie@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5038",
                "https://leg.mt.gov/legislator-information/roster/individual/6806",
                "https://leg.mt.gov/legislator-information/roster/individual/7516"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-9-upper-ocd-person-0bb87197-276c-4ea7-9e93-cf782879e010": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/afcd51eb-e3cf-4bc4-8093-80f884de54e2",
            "firstName": "Steven J.",
            "lastName": "Fitzpatrick",
            "fullName": "Steve Fitzpatrick",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-MT-10-upper-ocd-person-afcd51eb-e3cf-4bc4-8093-80f884de54e2",
              "builtID": "mt-upper-10",
              "externalID": "ocd-person/afcd51eb-e3cf-4bc4-8093-80f884de54e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.fitzpatrick@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4723",
                "https://leg.mt.gov/legislator-information/roster/individual/5009",
                "https://leg.mt.gov/legislator-information/roster/individual/6819",
                "https://leg.mt.gov/legislator-information/roster/individual/7506"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-10-upper-ocd-person-afcd51eb-e3cf-4bc4-8093-80f884de54e2": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/ab3accc3-6794-45c1-9f78-27d524aecc00",
            "firstName": "Daniel",
            "lastName": "Emrich",
            "fullName": "Daniel Emrich",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-MT-11-upper-ocd-person-ab3accc3-6794-45c1-9f78-27d524aecc00",
              "builtID": "mt-upper-11",
              "externalID": "ocd-person/ab3accc3-6794-45c1-9f78-27d524aecc00",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.emrich@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7624"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-11-upper-ocd-person-ab3accc3-6794-45c1-9f78-27d524aecc00": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/8f827a3b-16fe-4e09-bad0-c3e29c73d9c8",
            "firstName": "Wendy",
            "lastName": "McKamey",
            "fullName": "Wendy McKamey",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-MT-12-upper-ocd-person-8f827a3b-16fe-4e09-bad0-c3e29c73d9c8",
              "builtID": "mt-upper-12",
              "externalID": "ocd-person/8f827a3b-16fe-4e09-bad0-c3e29c73d9c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "wendy.mckamey@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4762",
                "https://leg.mt.gov/legislator-information/roster/individual/5077",
                "https://leg.mt.gov/legislator-information/roster/individual/6897",
                "https://leg.mt.gov/legislator-information/roster/individual/7560"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-12-upper-ocd-person-8f827a3b-16fe-4e09-bad0-c3e29c73d9c8": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/b51a470c-840d-404c-aebe-a7cb47c3edff",
            "firstName": "Jeremy",
            "lastName": "Trebas",
            "fullName": "Jeremy Trebas",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-MT-13-upper-ocd-person-b51a470c-840d-404c-aebe-a7cb47c3edff",
              "builtID": "mt-upper-13",
              "externalID": "ocd-person/b51a470c-840d-404c-aebe-a7cb47c3edff",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeremy.trebas@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4847",
                "https://leg.mt.gov/legislator-information/roster/individual/6904",
                "https://leg.mt.gov/legislator-information/roster/individual/7601"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-13-upper-ocd-person-b51a470c-840d-404c-aebe-a7cb47c3edff": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/8a8a229e-b35e-4fea-a4a9-114cc9f7488e",
            "firstName": "Russel",
            "lastName": "Tempel",
            "fullName": "Russel Tempel",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-MT-14-upper-ocd-person-8a8a229e-b35e-4fea-a4a9-114cc9f7488e",
              "builtID": "mt-upper-14",
              "externalID": "ocd-person/8a8a229e-b35e-4fea-a4a9-114cc9f7488e",
              "geometry": null
            },
            "contactInfo": {
              "email": "russ.tempel@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4848",
                "https://leg.mt.gov/legislator-information/roster/individual/5042",
                "https://leg.mt.gov/legislator-information/roster/individual/6808",
                "https://leg.mt.gov/legislator-information/roster/individual/7599"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-14-upper-ocd-person-8a8a229e-b35e-4fea-a4a9-114cc9f7488e": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/5d98dd7f-fa7f-4f57-a5f3-d65181feee04",
            "firstName": "Dan",
            "lastName": "Bartel",
            "fullName": "Dan Bartel",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-MT-15-upper-ocd-person-5d98dd7f-fa7f-4f57-a5f3-d65181feee04",
              "builtID": "mt-upper-15",
              "externalID": "ocd-person/5d98dd7f-fa7f-4f57-a5f3-d65181feee04",
              "geometry": null
            },
            "contactInfo": {
              "email": "danbartel2@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4771",
                "https://leg.mt.gov/legislator-information/roster/individual/5088",
                "https://leg.mt.gov/legislator-information/roster/individual/6839",
                "https://leg.mt.gov/legislator-information/roster/individual/7470"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-15-upper-ocd-person-5d98dd7f-fa7f-4f57-a5f3-d65181feee04": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/ef77e3ee-eec4-4274-ae22-7b1074227740",
            "firstName": "Michael",
            "lastName": "Fox",
            "fullName": "Mike Fox",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-MT-16-upper-ocd-person-ef77e3ee-eec4-4274-ae22-7b1074227740",
              "builtID": "mt-upper-16",
              "externalID": "ocd-person/ef77e3ee-eec4-4274-ae22-7b1074227740",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.fox@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6817",
                "https://leg.mt.gov/legislator-information/roster/individual/7509"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-16-upper-ocd-person-ef77e3ee-eec4-4274-ae22-7b1074227740": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/191d61f0-fa51-4588-8cb3-5d05d227d776",
            "firstName": "Mike",
            "lastName": "Lang",
            "fullName": "Mike Lang",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-MT-17-upper-ocd-person-191d61f0-fa51-4588-8cb3-5d05d227d776",
              "builtID": "mt-upper-17",
              "externalID": "ocd-person/191d61f0-fa51-4588-8cb3-5d05d227d776",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.lang@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4726",
                "https://leg.mt.gov/legislator-information/roster/individual/5012",
                "https://leg.mt.gov/legislator-information/roster/individual/6821",
                "https://leg.mt.gov/legislator-information/roster/individual/7546"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-17-upper-ocd-person-191d61f0-fa51-4588-8cb3-5d05d227d776": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/c08be554-02c3-48d6-a52e-3bff24acc036",
            "firstName": "Steve",
            "lastName": "Hinebauch",
            "fullName": "Steve Hinebauch",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-MT-18-upper-ocd-person-c08be554-02c3-48d6-a52e-3bff24acc036",
              "builtID": "mt-upper-18",
              "externalID": "ocd-person/c08be554-02c3-48d6-a52e-3bff24acc036",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.hinebauch@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4727",
                "https://leg.mt.gov/legislator-information/roster/individual/5013",
                "https://leg.mt.gov/legislator-information/roster/individual/6934",
                "https://leg.mt.gov/legislator-information/roster/individual/7529"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-18-upper-ocd-person-c08be554-02c3-48d6-a52e-3bff24acc036": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/ea9c7cc2-da2d-430e-a997-824818295244",
            "firstName": "Kenneth John",
            "lastName": "Bogner",
            "fullName": "Ken Bogner",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-MT-19-upper-ocd-person-ea9c7cc2-da2d-430e-a997-824818295244",
              "builtID": "mt-upper-19",
              "externalID": "ocd-person/ea9c7cc2-da2d-430e-a997-824818295244",
              "geometry": null
            },
            "contactInfo": {
              "email": "kenneth.bogner@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5043",
                "https://leg.mt.gov/legislator-information/roster/individual/6809",
                "https://leg.mt.gov/legislator-information/roster/individual/7478"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-19-upper-ocd-person-ea9c7cc2-da2d-430e-a997-824818295244": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/69cfa3aa-7091-4752-a99c-b6a42c70b17b",
            "firstName": "Barry M.",
            "lastName": "Usher",
            "fullName": "Barry Usher",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-MT-20-upper-ocd-person-69cfa3aa-7091-4752-a99c-b6a42c70b17b",
              "builtID": "mt-upper-20",
              "externalID": "ocd-person/69cfa3aa-7091-4752-a99c-b6a42c70b17b",
              "geometry": null
            },
            "contactInfo": {
              "email": "barry.usher@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4782",
                "https://leg.mt.gov/legislator-information/roster/individual/5099",
                "https://leg.mt.gov/legislator-information/roster/individual/6908",
                "https://leg.mt.gov/legislator-information/roster/individual/7603"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-20-upper-ocd-person-69cfa3aa-7091-4752-a99c-b6a42c70b17b": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/2be19b40-cdd4-4c6e-8eaf-1e0f589ef7cc",
            "firstName": "Jason Dean",
            "lastName": "Small",
            "fullName": "Jason Small",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-MT-21-upper-ocd-person-2be19b40-cdd4-4c6e-8eaf-1e0f589ef7cc",
              "builtID": "mt-upper-21",
              "externalID": "ocd-person/2be19b40-cdd4-4c6e-8eaf-1e0f589ef7cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.small@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4728",
                "https://leg.mt.gov/legislator-information/roster/individual/5014",
                "https://leg.mt.gov/legislator-information/roster/individual/6935",
                "https://leg.mt.gov/legislator-information/roster/individual/7590"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-21-upper-ocd-person-2be19b40-cdd4-4c6e-8eaf-1e0f589ef7cc": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/afffd4a2-fd6b-4cc5-8eff-8aa1f696db3a",
            "firstName": "Daniel",
            "lastName": "Zolnikov",
            "fullName": "Daniel Zolnikov",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-MT-22-upper-ocd-person-afffd4a2-fd6b-4cc5-8eff-8aa1f696db3a",
              "builtID": "mt-upper-22",
              "externalID": "ocd-person/afffd4a2-fd6b-4cc5-8eff-8aa1f696db3a",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.zolnikov@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7652"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-22-upper-ocd-person-afffd4a2-fd6b-4cc5-8eff-8aa1f696db3a": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/3a84733c-95e7-4927-af5f-63f82cb8a884",
            "firstName": "Thomas Van",
            "lastName": "McGillvray",
            "fullName": "Tom McGillvray",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-MT-23-upper-ocd-person-3a84733c-95e7-4927-af5f-63f82cb8a884",
              "builtID": "mt-upper-23",
              "externalID": "ocd-person/3a84733c-95e7-4927-af5f-63f82cb8a884",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.mcgillvray@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/css/Sessions/62nd/leg_info.asp?HouseID=0&SessionID=105&LAWSID=3851",
                "https://leg.mt.gov/legislator-information/roster/individual/6822",
                "https://leg.mt.gov/legislator-information/roster/individual/7559"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-23-upper-ocd-person-3a84733c-95e7-4927-af5f-63f82cb8a884": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/2b3be8a4-c4bb-423d-8a66-c9b1669215ff",
            "firstName": "Katharin Alcorn",
            "lastName": "Kelker",
            "fullName": "Kathy Kelker",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-MT-24-upper-ocd-person-2b3be8a4-c4bb-423d-8a66-c9b1669215ff",
              "builtID": "mt-upper-24",
              "externalID": "ocd-person/2b3be8a4-c4bb-423d-8a66-c9b1669215ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "kathy.kelker@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4789",
                "https://leg.mt.gov/legislator-information/roster/individual/5106",
                "https://leg.mt.gov/legislator-information/roster/individual/6858",
                "https://leg.mt.gov/legislator-information/roster/individual/7538"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-24-upper-ocd-person-2b3be8a4-c4bb-423d-8a66-c9b1669215ff": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/ab9c7685-d9ed-4cc7-be7b-c2f0fc50fd9c",
            "firstName": "Jen",
            "lastName": "Gross",
            "fullName": "Jen Gross",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-MT-25-upper-ocd-person-ab9c7685-d9ed-4cc7-be7b-c2f0fc50fd9c",
              "builtID": "mt-upper-25",
              "externalID": "ocd-person/ab9c7685-d9ed-4cc7-be7b-c2f0fc50fd9c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jen.gross@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4730",
                "https://leg.mt.gov/legislator-information/roster/individual/5016",
                "https://leg.mt.gov/legislator-information/roster/individual/6881",
                "https://leg.mt.gov/legislator-information/roster/individual/7521"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-25-upper-ocd-person-ab9c7685-d9ed-4cc7-be7b-c2f0fc50fd9c": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/c633fcd0-9d67-4004-9c5b-358888c997f6",
            "firstName": "Christopher",
            "lastName": "Friedel",
            "fullName": "Chris Friedel",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-MT-26-upper-ocd-person-c633fcd0-9d67-4004-9c5b-358888c997f6",
              "builtID": "mt-upper-26",
              "externalID": "ocd-person/c633fcd0-9d67-4004-9c5b-358888c997f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.friedel@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6936",
                "https://leg.mt.gov/legislator-information/roster/individual/7512"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-26-upper-ocd-person-c633fcd0-9d67-4004-9c5b-358888c997f6": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/0fd1ad64-349c-42d9-ae46-4ff7d3eb9495",
            "firstName": "Dennis Robert",
            "lastName": "Lenz",
            "fullName": "Dennis Lenz",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-MT-27-upper-ocd-person-0fd1ad64-349c-42d9-ae46-4ff7d3eb9495",
              "builtID": "mt-upper-27",
              "externalID": "ocd-person/0fd1ad64-349c-42d9-ae46-4ff7d3eb9495",
              "geometry": null
            },
            "contactInfo": {
              "email": "dennis.lenz@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4795",
                "https://leg.mt.gov/legislator-information/roster/individual/5112",
                "https://leg.mt.gov/legislator-information/roster/individual/6847",
                "https://leg.mt.gov/legislator-information/roster/individual/7547"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-27-upper-ocd-person-0fd1ad64-349c-42d9-ae46-4ff7d3eb9495": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/43225045-f2eb-4da5-b7c9-71dd853a06b3",
            "firstName": "Brad",
            "lastName": "Molnar",
            "fullName": "Brad Molnar",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-MT-28-upper-ocd-person-43225045-f2eb-4da5-b7c9-71dd853a06b3",
              "builtID": "mt-upper-28",
              "externalID": "ocd-person/43225045-f2eb-4da5-b7c9-71dd853a06b3",
              "geometry": null
            },
            "contactInfo": {
              "email": "brad.molnar@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6823",
                "https://leg.mt.gov/legislator-information/roster/individual/7564"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-28-upper-ocd-person-43225045-f2eb-4da5-b7c9-71dd853a06b3": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/40c484e9-cba9-4fd5-8f99-7bcaf25fc4ac",
            "firstName": "Forrest J.",
            "lastName": "Mandeville",
            "fullName": "Forrest Mandeville",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-MT-29-upper-ocd-person-40c484e9-cba9-4fd5-8f99-7bcaf25fc4ac",
              "builtID": "mt-upper-29",
              "externalID": "ocd-person/40c484e9-cba9-4fd5-8f99-7bcaf25fc4ac",
              "geometry": null
            },
            "contactInfo": {
              "email": "forrest.mandeville@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7635"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-29-upper-ocd-person-40c484e9-cba9-4fd5-8f99-7bcaf25fc4ac": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/a936c9c8-0a8d-4bcf-8da0-1a9b9ac80ab2",
            "firstName": "John",
            "lastName": "Esp",
            "fullName": "John Esp",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-MT-30-upper-ocd-person-a936c9c8-0a8d-4bcf-8da0-1a9b9ac80ab2",
              "builtID": "mt-upper-30",
              "externalID": "ocd-person/a936c9c8-0a8d-4bcf-8da0-1a9b9ac80ab2",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.esp@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5049",
                "https://leg.mt.gov/legislator-information/roster/individual/6814",
                "https://leg.mt.gov/legislator-information/roster/individual/7498"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-30-upper-ocd-person-a936c9c8-0a8d-4bcf-8da0-1a9b9ac80ab2": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/ab04d84a-c320-4d82-b211-d36206213195",
            "firstName": "Christopher S.",
            "lastName": "Pope",
            "fullName": "Chris Pope",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-MT-31-upper-ocd-person-ab04d84a-c320-4d82-b211-d36206213195",
              "builtID": "mt-upper-31",
              "externalID": "ocd-person/ab04d84a-c320-4d82-b211-d36206213195",
              "geometry": null
            },
            "contactInfo": {
              "email": "christopher.pope@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/css/Sessions/64th/leg_info.asp?HouseID=0&SessionID=109&LAWSID=16520",
                "https://leg.mt.gov/legislator-information/roster/individual/5124",
                "https://leg.mt.gov/legislator-information/roster/individual/6882",
                "https://leg.mt.gov/legislator-information/roster/individual/7576"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-31-upper-ocd-person-ab04d84a-c320-4d82-b211-d36206213195": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/4877fdac-4a93-4654-ad03-1b3038145c69",
            "firstName": "Pat",
            "lastName": "Flowers",
            "fullName": "Pat Flowers",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-MT-32-upper-ocd-person-4877fdac-4a93-4654-ad03-1b3038145c69",
              "builtID": "mt-upper-32",
              "externalID": "ocd-person/4877fdac-4a93-4654-ad03-1b3038145c69",
              "geometry": null
            },
            "contactInfo": {
              "email": "pat.flowers@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5050",
                "https://leg.mt.gov/legislator-information/roster/individual/6797",
                "https://leg.mt.gov/legislator-information/roster/individual/7508"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-32-upper-ocd-person-4877fdac-4a93-4654-ad03-1b3038145c69": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/482e35af-a664-4010-a5db-33f21d902045",
            "firstName": "Denise",
            "lastName": "Hayman",
            "fullName": "Denise Hayman",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-MT-33-upper-ocd-person-482e35af-a664-4010-a5db-33f21d902045",
              "builtID": "mt-upper-33",
              "externalID": "ocd-person/482e35af-a664-4010-a5db-33f21d902045",
              "geometry": null
            },
            "contactInfo": {
              "email": "denise.hayman@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4808",
                "https://leg.mt.gov/legislator-information/roster/individual/5125",
                "https://leg.mt.gov/legislator-information/roster/individual/6831",
                "https://leg.mt.gov/legislator-information/roster/individual/7526"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-33-upper-ocd-person-482e35af-a664-4010-a5db-33f21d902045": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/307202d9-6626-46a3-9697-0cc5f80356e4",
            "firstName": "Shelley",
            "lastName": "Vance",
            "fullName": "Shelley Vance",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-MT-34-upper-ocd-person-307202d9-6626-46a3-9697-0cc5f80356e4",
              "builtID": "mt-upper-34",
              "externalID": "ocd-person/307202d9-6626-46a3-9697-0cc5f80356e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "shelley.vance@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7648"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-34-upper-ocd-person-307202d9-6626-46a3-9697-0cc5f80356e4": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/6506172b-6734-4d40-843e-e69b516cf405",
            "firstName": "Walt",
            "lastName": "Sales",
            "fullName": "Walt Sales",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-MT-35-upper-ocd-person-6506172b-6734-4d40-843e-e69b516cf405",
              "builtID": "mt-upper-35",
              "externalID": "ocd-person/6506172b-6734-4d40-843e-e69b516cf405",
              "geometry": null
            },
            "contactInfo": {
              "email": "walt.sales@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4811",
                "https://leg.mt.gov/legislator-information/roster/individual/5128",
                "https://leg.mt.gov/legislator-information/roster/individual/6824",
                "https://leg.mt.gov/legislator-information/roster/individual/7585"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-35-upper-ocd-person-6506172b-6734-4d40-843e-e69b516cf405": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/1ceb0d0c-ddd6-43b9-9e74-86f75519030d",
            "firstName": "Jeffrey",
            "lastName": "Welborn",
            "fullName": "Jeff Welborn",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-MT-36-upper-ocd-person-1ceb0d0c-ddd6-43b9-9e74-86f75519030d",
              "builtID": "mt-upper-36",
              "externalID": "ocd-person/1ceb0d0c-ddd6-43b9-9e74-86f75519030d",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.welborn@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4735",
                "https://leg.mt.gov/legislator-information/roster/individual/5021",
                "https://leg.mt.gov/legislator-information/roster/individual/6937",
                "https://leg.mt.gov/legislator-information/roster/individual/7610"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-36-upper-ocd-person-1ceb0d0c-ddd6-43b9-9e74-86f75519030d": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/cb8e7696-86d6-4abb-a303-5e15c8c72b59",
            "firstName": "Ryan",
            "lastName": "Lynch",
            "fullName": "Ryan Lynch",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-MT-37-upper-ocd-person-cb8e7696-86d6-4abb-a303-5e15c8c72b59",
              "builtID": "mt-upper-37",
              "externalID": "ocd-person/cb8e7696-86d6-4abb-a303-5e15c8c72b59",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryan.lynch@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4818",
                "https://leg.mt.gov/legislator-information/roster/individual/5135",
                "https://leg.mt.gov/legislator-information/roster/individual/6883",
                "https://leg.mt.gov/legislator-information/roster/individual/7551"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-37-upper-ocd-person-cb8e7696-86d6-4abb-a303-5e15c8c72b59": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/3a40e354-8436-4755-8668-30c6d3f14c1b",
            "firstName": "Edith L.",
            "lastName": "McClafferty",
            "fullName": "Edie McClafferty",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-MT-38-upper-ocd-person-3a40e354-8436-4755-8668-30c6d3f14c1b",
              "builtID": "mt-upper-38",
              "externalID": "ocd-person/3a40e354-8436-4755-8668-30c6d3f14c1b",
              "geometry": null
            },
            "contactInfo": {
              "email": "edie.mcclafferty@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4737",
                "https://leg.mt.gov/legislator-information/roster/individual/5023",
                "https://leg.mt.gov/legislator-information/roster/individual/6884",
                "https://leg.mt.gov/legislator-information/roster/individual/7558"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-38-upper-ocd-person-3a40e354-8436-4755-8668-30c6d3f14c1b": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/611b2f4e-e05c-4e84-9abf-09991e3666be",
            "firstName": "Terry",
            "lastName": "Vermeire",
            "fullName": "Terry Vermeire",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-MT-39-upper-ocd-person-611b2f4e-e05c-4e84-9abf-09991e3666be",
              "builtID": "mt-upper-39",
              "externalID": "ocd-person/611b2f4e-e05c-4e84-9abf-09991e3666be",
              "geometry": null
            },
            "contactInfo": {
              "email": "terry.vermeire@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://mtgop.org/leaders/terry-vermeire/",
                "https://leg.mt.gov/legislator-information/roster/individual/7649"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-39-upper-ocd-person-611b2f4e-e05c-4e84-9abf-09991e3666be": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/f87d7b76-3065-48f4-bc3b-50bf00926180",
            "firstName": "Becky M.",
            "lastName": "Beard",
            "fullName": "Becky Beard",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-MT-40-upper-ocd-person-f87d7b76-3065-48f4-bc3b-50bf00926180",
              "builtID": "mt-upper-40",
              "externalID": "ocd-person/f87d7b76-3065-48f4-bc3b-50bf00926180",
              "geometry": null
            },
            "contactInfo": {
              "email": "becky.beard@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/7472"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-40-upper-ocd-person-f87d7b76-3065-48f4-bc3b-50bf00926180": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/2062cc82-e160-435d-ab16-c57305a13db4",
            "firstName": "Janet Heath",
            "lastName": "Ellis",
            "fullName": "Janet Ellis",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-MT-41-upper-ocd-person-2062cc82-e160-435d-ab16-c57305a13db4",
              "builtID": "mt-upper-41",
              "externalID": "ocd-person/2062cc82-e160-435d-ab16-c57305a13db4",
              "geometry": null
            },
            "contactInfo": {
              "email": "janet.ellis@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5053",
                "https://leg.mt.gov/legislator-information/roster/individual/6799",
                "https://leg.mt.gov/legislator-information/roster/individual/7495"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-41-upper-ocd-person-2062cc82-e160-435d-ab16-c57305a13db4": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/3e82bb94-a815-40d2-b125-b9884517b1c3",
            "firstName": "Mary Ann",
            "lastName": "Dunwell",
            "fullName": "Mary Ann Dunwell",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-MT-42-upper-ocd-person-3e82bb94-a815-40d2-b125-b9884517b1c3",
              "builtID": "mt-upper-42",
              "externalID": "ocd-person/3e82bb94-a815-40d2-b125-b9884517b1c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "maryann.dunwell@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4826",
                "https://leg.mt.gov/legislator-information/roster/individual/5143",
                "https://leg.mt.gov/legislator-information/roster/individual/6873",
                "https://leg.mt.gov/legislator-information/roster/individual/7493"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-42-upper-ocd-person-3e82bb94-a815-40d2-b125-b9884517b1c3": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/f68bf624-cad5-4db2-9c74-a49a33348018",
            "firstName": "Jason W.",
            "lastName": "Ellsworth",
            "fullName": "Jason Ellsworth",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "43",
              "chamber": "upper",
              "hashID": "SLDU-MT-43-upper-ocd-person-f68bf624-cad5-4db2-9c74-a49a33348018",
              "builtID": "mt-upper-43",
              "externalID": "ocd-person/f68bf624-cad5-4db2-9c74-a49a33348018",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.ellsworth@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/5055",
                "https://leg.mt.gov/legislator-information/roster/individual/6816",
                "https://leg.mt.gov/legislator-information/roster/individual/7496"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-43-upper-ocd-person-f68bf624-cad5-4db2-9c74-a49a33348018": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/97c7ced5-ba80-4a9a-80ca-349fd2040a64",
            "firstName": "Theresa",
            "lastName": "Manzella",
            "fullName": "Theresa Manzella",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "44",
              "chamber": "upper",
              "hashID": "SLDU-MT-44-upper-ocd-person-97c7ced5-ba80-4a9a-80ca-349fd2040a64",
              "builtID": "mt-upper-44",
              "externalID": "ocd-person/97c7ced5-ba80-4a9a-80ca-349fd2040a64",
              "geometry": null
            },
            "contactInfo": {
              "email": "theresa.manzella@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4827",
                "https://leg.mt.gov/legislator-information/roster/individual/5144",
                "https://leg.mt.gov/legislator-information/roster/individual/6939",
                "https://leg.mt.gov/legislator-information/roster/individual/7554"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-44-upper-ocd-person-97c7ced5-ba80-4a9a-80ca-349fd2040a64": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/4e6ad74d-1e72-4d2b-ac0d-557f429b3e9a",
            "firstName": "Ellen Marie",
            "lastName": "Boldman",
            "fullName": "Ellie Boldman",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "45",
              "chamber": "upper",
              "hashID": "SLDU-MT-45-upper-ocd-person-4e6ad74d-1e72-4d2b-ac0d-557f429b3e9a",
              "builtID": "mt-upper-45",
              "externalID": "ocd-person/4e6ad74d-1e72-4d2b-ac0d-557f429b3e9a",
              "geometry": null
            },
            "contactInfo": {
              "email": "ellie.boldman@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6886",
                "https://leg.mt.gov/legislator-information/roster/individual/7479"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-45-upper-ocd-person-4e6ad74d-1e72-4d2b-ac0d-557f429b3e9a": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/6141fdd6-7114-4662-ac8b-a12782d813d1",
            "firstName": "Shannon",
            "lastName": "O'Brien",
            "fullName": "Shannon O'Brien",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "46",
              "chamber": "upper",
              "hashID": "SLDU-MT-46-upper-ocd-person-6141fdd6-7114-4662-ac8b-a12782d813d1",
              "builtID": "mt-upper-46",
              "externalID": "ocd-person/6141fdd6-7114-4662-ac8b-a12782d813d1",
              "geometry": null
            },
            "contactInfo": {
              "email": "shannon.obrien@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/6887",
                "https://leg.mt.gov/legislator-information/roster/individual/7572"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-46-upper-ocd-person-6141fdd6-7114-4662-ac8b-a12782d813d1": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/c6641ba3-7be7-4286-9ade-3445b4d6a08f",
            "firstName": "Daniel",
            "lastName": "Salomon",
            "fullName": "Dan Salomon",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "47",
              "chamber": "upper",
              "hashID": "SLDU-MT-47-upper-ocd-person-c6641ba3-7be7-4286-9ade-3445b4d6a08f",
              "builtID": "mt-upper-47",
              "externalID": "ocd-person/c6641ba3-7be7-4286-9ade-3445b4d6a08f",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.salomon@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4743",
                "https://leg.mt.gov/legislator-information/roster/individual/5029",
                "https://leg.mt.gov/legislator-information/roster/individual/6940",
                "https://leg.mt.gov/legislator-information/roster/individual/7586"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-47-upper-ocd-person-c6641ba3-7be7-4286-9ade-3445b4d6a08f": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/83f50436-e02e-41b0-b6bb-dd44973665c8",
            "firstName": "Shane Antoine",
            "lastName": "Morigeau",
            "fullName": "Shane Morigeau",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "48",
              "chamber": "upper",
              "hashID": "SLDU-MT-48-upper-ocd-person-83f50436-e02e-41b0-b6bb-dd44973665c8",
              "builtID": "mt-upper-48",
              "externalID": "ocd-person/83f50436-e02e-41b0-b6bb-dd44973665c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "shane.morigeau@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4837",
                "https://leg.mt.gov/legislator-information/roster/individual/5154",
                "https://leg.mt.gov/legislator-information/roster/individual/6792",
                "https://leg.mt.gov/legislator-information/roster/individual/6942",
                "https://leg.mt.gov/legislator-information/roster/individual/7566"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-48-upper-ocd-person-83f50436-e02e-41b0-b6bb-dd44973665c8": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/d6a3a052-3fb3-4dce-9ce0-074852fb2612",
            "firstName": "Willis",
            "lastName": "Curdy",
            "fullName": "Willis Curdy",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "49",
              "chamber": "upper",
              "hashID": "SLDU-MT-49-upper-ocd-person-d6a3a052-3fb3-4dce-9ce0-074852fb2612",
              "builtID": "mt-upper-49",
              "externalID": "ocd-person/d6a3a052-3fb3-4dce-9ce0-074852fb2612",
              "geometry": null
            },
            "contactInfo": {
              "email": "willis.curdy@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4840",
                "https://leg.mt.gov/legislator-information/roster/individual/5157",
                "https://leg.mt.gov/legislator-information/roster/individual/6879",
                "https://leg.mt.gov/legislator-information/roster/individual/7490"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-49-upper-ocd-person-d6a3a052-3fb3-4dce-9ce0-074852fb2612": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/2dcaf4be-0227-4ced-b01c-aacd8376c45a",
            "firstName": "Andrea",
            "lastName": "Olsen",
            "fullName": "Andrea Olsen",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "MT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "MT",
              "stateFull": "Montana",
              "district": "50",
              "chamber": "upper",
              "hashID": "SLDU-MT-50-upper-ocd-person-2dcaf4be-0227-4ced-b01c-aacd8376c45a",
              "builtID": "mt-upper-50",
              "externalID": "ocd-person/2dcaf4be-0227-4ced-b01c-aacd8376c45a",
              "geometry": null
            },
            "contactInfo": {
              "email": "andrea.olsen@legmt.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.mt.gov/legislator-information/roster/individual/4842",
                "https://leg.mt.gov/legislator-information/roster/individual/5159",
                "https://leg.mt.gov/legislator-information/roster/individual/6941",
                "https://leg.mt.gov/legislator-information/roster/individual/7573"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-MT-50-upper-ocd-person-2dcaf4be-0227-4ced-b01c-aacd8376c45a": 0
        }
      }
    }
  },
  "NE": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "NE",
          "stateFull": "Nebraska",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "NE",
            "stateFull": "Nebraska",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-NE---",
            "builtID": "ne--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-NE---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "F000463",
          "in_office": true,
          "firstName": "Deb",
          "lastName": "Fischer",
          "middleName": null,
          "fullName": "Deb Fischer",
          "gender": "F",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/F000463.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "NE",
            "stateFull": "Nebraska",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-NE---F000463",
            "builtID": "ne--",
            "externalID": "F000463",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.fischer.senate.gov/public/index.cfm/contact",
            "address1": "454 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-6551",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorFischer",
            "youtube": "senatordebfischer",
            "instagram": null,
            "facebook": "senatordebfischer",
            "urls": ["https://www.fischer.senate.gov"],
            "rss_url": null
          },
          "title": "NE Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/F000463.json",
          "govtrack_id": "412556",
          "cspan_id": "1034067",
          "votesmart_id": "41963",
          "icpsr_id": "41302",
          "crp_id": "N00033443",
          "google_entity_id": "/m/0c4cp0",
          "state_rank": "senior",
          "lis_id": "S357",
          "suffix": null,
          "date_of_birth": "1951-03-01",
          "leadership_role": null,
          "fec_candidate_id": "S2NE00094",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "11",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 3,
          "total_present": 0,
          "last_updated": "2024-06-20 15:30:30 -0400",
          "missed_votes_pct": 0.54,
          "votes_with_party_pct": 97.58,
          "votes_against_party_pct": 2.42,
          "ocd_id": "ocd-division/country:us/state:ne"
        },
        {
          "API_ID": "R000618",
          "in_office": true,
          "firstName": "Pete",
          "lastName": "Ricketts",
          "middleName": null,
          "fullName": "Pete Ricketts",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/R000618.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "NE",
            "stateFull": "Nebraska",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-NE---R000618",
            "builtID": "ne--",
            "externalID": "R000618",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": null,
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.ricketts.senate.gov/"],
            "rss_url": ""
          },
          "title": "NE Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/R000618.json",
          "govtrack_id": "456952",
          "cspan_id": null,
          "votesmart_id": null,
          "icpsr_id": null,
          "crp_id": null,
          "google_entity_id": null,
          "state_rank": null,
          "lis_id": "S423",
          "suffix": null,
          "date_of_birth": "1964-08-19",
          "leadership_role": null,
          "fec_candidate_id": null,
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": null,
          "total_votes": 554,
          "missed_votes": 21,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 3.79,
          "votes_with_party_pct": 94.57,
          "votes_against_party_pct": 5.43,
          "ocd_id": "ocd-division/country:us/state:ne"
        }
      ],
      "hash": { "SENATE-NE---F000463": 0, "SENATE-NE---R000618": 1 }
    },
    "HOUSE": {
      "02": {
        "members": [
          {
            "API_ID": "B001298",
            "in_office": true,
            "firstName": "Don",
            "lastName": "Bacon",
            "middleName": null,
            "fullName": "Don Bacon",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001298.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-NE-02--B001298",
              "builtID": "ne--02",
              "externalID": "B001298",
              "geometry": null,
              "geoid": "3102"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2104 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4155",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repdonbacon",
              "youtube": null,
              "instagram": null,
              "facebook": "RepDonBacon",
              "urls": ["https://bacon.house.gov"],
              "rss_url": null
            },
            "title": "NE House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001298.json",
            "govtrack_id": "412713",
            "cspan_id": "103442",
            "votesmart_id": "166299",
            "icpsr_id": "21701",
            "crp_id": "N00037049",
            "google_entity_id": "/m/01311t71",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1963-08-16",
            "leadership_role": null,
            "fec_candidate_id": "H6NE02125",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 14,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.33,
            "votes_with_party_pct": 82.58,
            "votes_against_party_pct": 15.46,
            "ocd_id": "ocd-division/country:us/state:ne/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NE-02--B001298": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "F000474",
            "in_office": true,
            "firstName": "Mike",
            "lastName": "Flood",
            "middleName": null,
            "fullName": "Mike Flood",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/F000474.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-NE-01--F000474",
              "builtID": "ne--01",
              "externalID": "F000474",
              "geometry": null,
              "geoid": "3101"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "343 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-4806",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "USRepMikeFlood",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://flood.house.gov/"],
              "rss_url": null
            },
            "title": "NE House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000474.json",
            "govtrack_id": "456868",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00050145",
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1975-02-23",
            "leadership_role": null,
            "fec_candidate_id": "H2NE01118",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.57,
            "votes_with_party_pct": 89.13,
            "votes_against_party_pct": 8.93,
            "ocd_id": "ocd-division/country:us/state:ne/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NE-01--F000474": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "S001172",
            "in_office": true,
            "firstName": "Adrian",
            "lastName": "Smith",
            "middleName": null,
            "fullName": "Adrian Smith",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S001172.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-NE-03--S001172",
              "builtID": "ne--03",
              "externalID": "S001172",
              "geometry": null,
              "geoid": "3103"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "502 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-6435",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAdrianSmith",
              "youtube": "RepAdrianSmith",
              "instagram": null,
              "facebook": null,
              "urls": ["https://adriansmith.house.gov"],
              "rss_url": "https://adriansmith.house.gov/rss.xml"
            },
            "title": "NE House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001172.json",
            "govtrack_id": "412217",
            "cspan_id": "1022845",
            "votesmart_id": "21284",
            "icpsr_id": "20729",
            "crp_id": "N00027623",
            "google_entity_id": "/m/0c505n",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1970-12-19",
            "leadership_role": null,
            "fec_candidate_id": "H6NE03115",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "18",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 34,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 3.22,
            "votes_with_party_pct": 94.5,
            "votes_against_party_pct": 3.6,
            "ocd_id": "ocd-division/country:us/state:ne/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NE-03--S001172": 0 }
      }
    },
    "SLDL": {},
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/695496e5-c9c9-4337-9891-ae31f21fdd06",
            "firstName": "Julie E.",
            "lastName": "Slama",
            "fullName": "Julie Slama",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist01/highres/dist01.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-NE-1-upper-ocd-person-695496e5-c9c9-4337-9891-ae31f21fdd06",
              "builtID": "ne-upper-1",
              "externalID": "ocd-person/695496e5-c9c9-4337-9891-ae31f21fdd06",
              "geometry": null
            },
            "contactInfo": {
              "email": "jslama@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist01",
                "https://news.legislature.ne.gov/dist01/",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-1-upper-ocd-person-695496e5-c9c9-4337-9891-ae31f21fdd06": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/809c0698-2669-426a-819a-275a0773ff84",
            "firstName": "Robert",
            "lastName": "Clements",
            "fullName": "Rob Clements",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist02/highres/dist02.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-NE-2-upper-ocd-person-809c0698-2669-426a-819a-275a0773ff84",
              "builtID": "ne-upper-2",
              "externalID": "ocd-person/809c0698-2669-426a-819a-275a0773ff84",
              "geometry": null
            },
            "contactInfo": {
              "email": "rclements@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist02",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=2",
                "https://news.legislature.ne.gov/dist02/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-2-upper-ocd-person-809c0698-2669-426a-819a-275a0773ff84": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/adde7784-4e40-4bd5-892d-f45189ad8962",
            "firstName": "Carol",
            "lastName": "Blood",
            "fullName": "Carol Blood",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist03/highres/dist03.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-NE-3-upper-ocd-person-adde7784-4e40-4bd5-892d-f45189ad8962",
              "builtID": "ne-upper-3",
              "externalID": "ocd-person/adde7784-4e40-4bd5-892d-f45189ad8962",
              "geometry": null
            },
            "contactInfo": {
              "email": "cblood@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist03",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=3",
                "https://news.legislature.ne.gov/dist03/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-3-upper-ocd-person-adde7784-4e40-4bd5-892d-f45189ad8962": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/63d2956a-a0be-40db-a0e3-6c32743951ab",
            "firstName": "R. Brad",
            "lastName": "von Gillern",
            "fullName": "Brad von Gillern",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist04/highres/dist04.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-NE-4-upper-ocd-person-63d2956a-a0be-40db-a0e3-6c32743951ab",
              "builtID": "ne-upper-4",
              "externalID": "ocd-person/63d2956a-a0be-40db-a0e3-6c32743951ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "bvongillern@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=4"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-4-upper-ocd-person-63d2956a-a0be-40db-a0e3-6c32743951ab": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/8560f373-d2af-4c14-887d-6ec11534dba0",
            "firstName": "Michael F.",
            "lastName": "McDonnell",
            "fullName": "Mike McDonnell",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist05/highres/dist05.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-NE-5-upper-ocd-person-8560f373-d2af-4c14-887d-6ec11534dba0",
              "builtID": "ne-upper-5",
              "externalID": "ocd-person/8560f373-d2af-4c14-887d-6ec11534dba0",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmcdonnell@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist05",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=5",
                "https://news.legislature.ne.gov/dist05/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-5-upper-ocd-person-8560f373-d2af-4c14-887d-6ec11534dba0": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/fd6e1947-c274-4ff5-b2ee-5e5a79d6a729",
            "firstName": "Machaela M.",
            "lastName": "Cavanaugh",
            "fullName": "Machaela Cavanaugh",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist06/highres/dist06.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-NE-6-upper-ocd-person-fd6e1947-c274-4ff5-b2ee-5e5a79d6a729",
              "builtID": "ne-upper-6",
              "externalID": "ocd-person/fd6e1947-c274-4ff5-b2ee-5e5a79d6a729",
              "geometry": null
            },
            "contactInfo": {
              "email": "mcavanaugh@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist06",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=6",
                "https://news.legislature.ne.gov/dist06/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-6-upper-ocd-person-fd6e1947-c274-4ff5-b2ee-5e5a79d6a729": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/f004a111-7ea8-4a83-8f45-3e350e699a39",
            "firstName": "Anthony",
            "lastName": "Vargas",
            "fullName": "Tony Vargas",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist07/highres/dist07.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-NE-7-upper-ocd-person-f004a111-7ea8-4a83-8f45-3e350e699a39",
              "builtID": "ne-upper-7",
              "externalID": "ocd-person/f004a111-7ea8-4a83-8f45-3e350e699a39",
              "geometry": null
            },
            "contactInfo": {
              "email": "tvargas@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist07",
                "https://news.legislature.ne.gov/dist07/",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=7"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-7-upper-ocd-person-f004a111-7ea8-4a83-8f45-3e350e699a39": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/1b988941-ec1a-4f87-b17a-51fb0309dea0",
            "firstName": "Megan",
            "lastName": "Hunt",
            "fullName": "Megan Hunt",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist08/highres/dist08.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-NE-8-upper-ocd-person-1b988941-ec1a-4f87-b17a-51fb0309dea0",
              "builtID": "ne-upper-8",
              "externalID": "ocd-person/1b988941-ec1a-4f87-b17a-51fb0309dea0",
              "geometry": null
            },
            "contactInfo": {
              "email": "mhunt@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist08",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=8",
                "https://news.legislature.ne.gov/dist08/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-8-upper-ocd-person-1b988941-ec1a-4f87-b17a-51fb0309dea0": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/0436186a-3b62-46f1-8f36-55e09fca3e77",
            "firstName": "John J.",
            "lastName": "Cavanaugh",
            "fullName": "John Cavanaugh",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist09/highres/dist09.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-NE-9-upper-ocd-person-0436186a-3b62-46f1-8f36-55e09fca3e77",
              "builtID": "ne-upper-9",
              "externalID": "ocd-person/0436186a-3b62-46f1-8f36-55e09fca3e77",
              "geometry": null
            },
            "contactInfo": {
              "email": "jcavanaugh@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist09/",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=9"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-9-upper-ocd-person-0436186a-3b62-46f1-8f36-55e09fca3e77": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/bc906a77-5932-4042-9ede-a13b24924236",
            "firstName": "Wendy",
            "lastName": "DeBoer",
            "fullName": "Wendy DeBoer",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist10/highres/dist10.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-NE-10-upper-ocd-person-bc906a77-5932-4042-9ede-a13b24924236",
              "builtID": "ne-upper-10",
              "externalID": "ocd-person/bc906a77-5932-4042-9ede-a13b24924236",
              "geometry": null
            },
            "contactInfo": {
              "email": "wdeboer@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist10",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=10",
                "https://news.legislature.ne.gov/dist10/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-10-upper-ocd-person-bc906a77-5932-4042-9ede-a13b24924236": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/27784792-d54f-4a64-8408-a4a1ce0c26b1",
            "firstName": "Terrell",
            "lastName": "McKinney",
            "fullName": "Terrell McKinney",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist11/highres/dist11.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-NE-11-upper-ocd-person-27784792-d54f-4a64-8408-a4a1ce0c26b1",
              "builtID": "ne-upper-11",
              "externalID": "ocd-person/27784792-d54f-4a64-8408-a4a1ce0c26b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "tmckinney@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=11",
                "https://news.legislature.ne.gov/dist11/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-11-upper-ocd-person-27784792-d54f-4a64-8408-a4a1ce0c26b1": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/3c015cf9-e3de-42cf-a364-64607ecc70ce",
            "firstName": "Mervin Merle",
            "lastName": "Riepe",
            "fullName": "Merv Riepe",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist12/highres/dist12.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-NE-12-upper-ocd-person-3c015cf9-e3de-42cf-a364-64607ecc70ce",
              "builtID": "ne-upper-12",
              "externalID": "ocd-person/3c015cf9-e3de-42cf-a364-64607ecc70ce",
              "geometry": null
            },
            "contactInfo": {
              "email": "mriepe@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=12"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-12-upper-ocd-person-3c015cf9-e3de-42cf-a364-64607ecc70ce": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/59757a4e-8be9-4282-a753-91fe7ab665bd",
            "firstName": "Justin T.",
            "lastName": "Wayne",
            "fullName": "Justin Wayne",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist13/highres/dist13.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-NE-13-upper-ocd-person-59757a4e-8be9-4282-a753-91fe7ab665bd",
              "builtID": "ne-upper-13",
              "externalID": "ocd-person/59757a4e-8be9-4282-a753-91fe7ab665bd",
              "geometry": null
            },
            "contactInfo": {
              "email": "jwayne@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist13",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=13",
                "https://news.legislature.ne.gov/dist13/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-13-upper-ocd-person-59757a4e-8be9-4282-a753-91fe7ab665bd": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/f5d27385-af4c-4c08-8654-3e27ef120d94",
            "firstName": "John K.",
            "lastName": "Arch",
            "fullName": "John Arch",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist14/highres/dist14.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-NE-14-upper-ocd-person-f5d27385-af4c-4c08-8654-3e27ef120d94",
              "builtID": "ne-upper-14",
              "externalID": "ocd-person/f5d27385-af4c-4c08-8654-3e27ef120d94",
              "geometry": null
            },
            "contactInfo": {
              "email": "jarch@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist14",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=14",
                "https://news.legislature.ne.gov/dist14/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-14-upper-ocd-person-f5d27385-af4c-4c08-8654-3e27ef120d94": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/c5b78494-5d8f-4ef6-b83e-cf00d2604b3e",
            "firstName": "Lynne",
            "lastName": "Walz",
            "fullName": "Lynne Walz",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist15/highres/dist15.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-NE-15-upper-ocd-person-c5b78494-5d8f-4ef6-b83e-cf00d2604b3e",
              "builtID": "ne-upper-15",
              "externalID": "ocd-person/c5b78494-5d8f-4ef6-b83e-cf00d2604b3e",
              "geometry": null
            },
            "contactInfo": {
              "email": "lwalz@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist15",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=15",
                "https://news.legislature.ne.gov/dist15/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-15-upper-ocd-person-c5b78494-5d8f-4ef6-b83e-cf00d2604b3e": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/bfc9dd37-6a7b-4ea2-acf5-c8e161a27764",
            "firstName": "Benjamin",
            "lastName": "Hansen",
            "fullName": "Ben Hansen",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist16/highres/dist16.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-NE-16-upper-ocd-person-bfc9dd37-6a7b-4ea2-acf5-c8e161a27764",
              "builtID": "ne-upper-16",
              "externalID": "ocd-person/bfc9dd37-6a7b-4ea2-acf5-c8e161a27764",
              "geometry": null
            },
            "contactInfo": {
              "email": "bhansen@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist16",
                "https://news.legislature.ne.gov/dist16/",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=16"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-16-upper-ocd-person-bfc9dd37-6a7b-4ea2-acf5-c8e161a27764": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/06ad0015-2e29-435a-b13c-170181bab0d8",
            "firstName": "Joanne Jones",
            "lastName": "Albrecht",
            "fullName": "Joni Albrecht",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist17/highres/dist17.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-NE-17-upper-ocd-person-06ad0015-2e29-435a-b13c-170181bab0d8",
              "builtID": "ne-upper-17",
              "externalID": "ocd-person/06ad0015-2e29-435a-b13c-170181bab0d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "jalbrecht@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist17",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=17",
                "https://news.legislature.ne.gov/dist17/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-17-upper-ocd-person-06ad0015-2e29-435a-b13c-170181bab0d8": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/fdac2d3a-3497-4e34-8247-af670c901dd8",
            "firstName": "Christy",
            "lastName": "Armendariz",
            "fullName": "Christy Armendariz",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist18/highres/dist18.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-NE-18-upper-ocd-person-fdac2d3a-3497-4e34-8247-af670c901dd8",
              "builtID": "ne-upper-18",
              "externalID": "ocd-person/fdac2d3a-3497-4e34-8247-af670c901dd8",
              "geometry": null
            },
            "contactInfo": {
              "email": "carmendariz@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=18"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-18-upper-ocd-person-fdac2d3a-3497-4e34-8247-af670c901dd8": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/a9cc86ff-602c-453f-9d6c-fd1567c7fbd7",
            "firstName": "Robert",
            "lastName": "Dover",
            "fullName": "Robert Dover",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist19/highres/dist19.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-NE-19-upper-ocd-person-a9cc86ff-602c-453f-9d6c-fd1567c7fbd7",
              "builtID": "ne-upper-19",
              "externalID": "ocd-person/a9cc86ff-602c-453f-9d6c-fd1567c7fbd7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rdover@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=19"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-19-upper-ocd-person-a9cc86ff-602c-453f-9d6c-fd1567c7fbd7": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/30b4a401-6f6b-4367-80c2-b9d60d7c527f",
            "firstName": "John A.",
            "lastName": "Fredrickson",
            "fullName": "John Fredrickson",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist20/highres/dist20.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-NE-20-upper-ocd-person-30b4a401-6f6b-4367-80c2-b9d60d7c527f",
              "builtID": "ne-upper-20",
              "externalID": "ocd-person/30b4a401-6f6b-4367-80c2-b9d60d7c527f",
              "geometry": null
            },
            "contactInfo": {
              "email": "jfredrickson@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=20"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-20-upper-ocd-person-30b4a401-6f6b-4367-80c2-b9d60d7c527f": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/3da787e9-f6ed-4069-bd74-58ec7c015efb",
            "firstName": "Beau J.",
            "lastName": "Ballard",
            "fullName": "Beau Ballard",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist21/highres/dist21.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-NE-21-upper-ocd-person-3da787e9-f6ed-4069-bd74-58ec7c015efb",
              "builtID": "ne-upper-21",
              "externalID": "ocd-person/3da787e9-f6ed-4069-bd74-58ec7c015efb",
              "geometry": null
            },
            "contactInfo": {
              "email": "bballard@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=21"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-21-upper-ocd-person-3da787e9-f6ed-4069-bd74-58ec7c015efb": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/471fb0d6-b798-4c0c-bac1-021db8b68801",
            "firstName": "Mike",
            "lastName": "Moser",
            "fullName": "Mike Moser",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist22/highres/dist22.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-NE-22-upper-ocd-person-471fb0d6-b798-4c0c-bac1-021db8b68801",
              "builtID": "ne-upper-22",
              "externalID": "ocd-person/471fb0d6-b798-4c0c-bac1-021db8b68801",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmoser@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist22",
                "https://news.legislature.ne.gov/dist22/",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=22"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-22-upper-ocd-person-471fb0d6-b798-4c0c-bac1-021db8b68801": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/53e9667b-b61b-4ca5-a3e0-a30282382851",
            "firstName": "Bruce L.",
            "lastName": "Bostelman",
            "fullName": "Bruce Bostelman",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist23/highres/dist23.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-NE-23-upper-ocd-person-53e9667b-b61b-4ca5-a3e0-a30282382851",
              "builtID": "ne-upper-23",
              "externalID": "ocd-person/53e9667b-b61b-4ca5-a3e0-a30282382851",
              "geometry": null
            },
            "contactInfo": {
              "email": "bbostelman@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist23",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=23",
                "https://news.legislature.ne.gov/dist23/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-23-upper-ocd-person-53e9667b-b61b-4ca5-a3e0-a30282382851": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/967bf436-18bf-444e-bd2f-d83e5ec78995",
            "firstName": "Jana",
            "lastName": "Hughes",
            "fullName": "Jana Hughes",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist24/highres/dist24.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-NE-24-upper-ocd-person-967bf436-18bf-444e-bd2f-d83e5ec78995",
              "builtID": "ne-upper-24",
              "externalID": "ocd-person/967bf436-18bf-444e-bd2f-d83e5ec78995",
              "geometry": null
            },
            "contactInfo": {
              "email": "jhughes@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=24"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-24-upper-ocd-person-967bf436-18bf-444e-bd2f-d83e5ec78995": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/ecf3913e-8e18-4aec-8991-12549df820cd",
            "firstName": "Carolyn Heide",
            "lastName": "Bosn",
            "fullName": "Carolyn Bosn",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist25/highres/dist25.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-NE-25-upper-ocd-person-ecf3913e-8e18-4aec-8991-12549df820cd",
              "builtID": "ne-upper-25",
              "externalID": "ocd-person/ecf3913e-8e18-4aec-8991-12549df820cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "cbosn@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=25"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-25-upper-ocd-person-ecf3913e-8e18-4aec-8991-12549df820cd": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/14507b1b-007f-4f87-adbb-a5e4669efba8",
            "firstName": "George Clinton",
            "lastName": "Dungan",
            "fullName": "George Dungan",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist26/highres/dist26.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-NE-26-upper-ocd-person-14507b1b-007f-4f87-adbb-a5e4669efba8",
              "builtID": "ne-upper-26",
              "externalID": "ocd-person/14507b1b-007f-4f87-adbb-a5e4669efba8",
              "geometry": null
            },
            "contactInfo": {
              "email": "gdungan@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=26"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-26-upper-ocd-person-14507b1b-007f-4f87-adbb-a5e4669efba8": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/3acb7032-1f3a-4087-a2cd-0f3786b9f9c9",
            "firstName": "Anna",
            "lastName": "Wishart",
            "fullName": "Anna Wishart",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist27/highres/dist27.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-NE-27-upper-ocd-person-3acb7032-1f3a-4087-a2cd-0f3786b9f9c9",
              "builtID": "ne-upper-27",
              "externalID": "ocd-person/3acb7032-1f3a-4087-a2cd-0f3786b9f9c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "awishart@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist27",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=27",
                "https://news.legislature.ne.gov/dist27/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-27-upper-ocd-person-3acb7032-1f3a-4087-a2cd-0f3786b9f9c9": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/0f176ff3-ed75-4605-9e8b-5bb5a3d3c4cf",
            "firstName": "Jane Michele",
            "lastName": "Raybould",
            "fullName": "Jane Raybould",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist28/highres/dist28.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-NE-28-upper-ocd-person-0f176ff3-ed75-4605-9e8b-5bb5a3d3c4cf",
              "builtID": "ne-upper-28",
              "externalID": "ocd-person/0f176ff3-ed75-4605-9e8b-5bb5a3d3c4cf",
              "geometry": null
            },
            "contactInfo": {
              "email": "jraybould@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=28"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-28-upper-ocd-person-0f176ff3-ed75-4605-9e8b-5bb5a3d3c4cf": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/5e6ca662-d4e3-405b-a060-d0150546eede",
            "firstName": "Eliot",
            "lastName": "Bostar",
            "fullName": "Eliot Bostar",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist29/highres/dist29.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-NE-29-upper-ocd-person-5e6ca662-d4e3-405b-a060-d0150546eede",
              "builtID": "ne-upper-29",
              "externalID": "ocd-person/5e6ca662-d4e3-405b-a060-d0150546eede",
              "geometry": null
            },
            "contactInfo": {
              "email": "ebostar@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=29",
                "https://news.legislature.ne.gov/dist29/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-29-upper-ocd-person-5e6ca662-d4e3-405b-a060-d0150546eede": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/4a8d555a-4bba-4f76-a3e1-88eee6030413",
            "firstName": "Myron",
            "lastName": "Dorn",
            "fullName": "Myron Dorn",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist30/highres/dist30.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-NE-30-upper-ocd-person-4a8d555a-4bba-4f76-a3e1-88eee6030413",
              "builtID": "ne-upper-30",
              "externalID": "ocd-person/4a8d555a-4bba-4f76-a3e1-88eee6030413",
              "geometry": null
            },
            "contactInfo": {
              "email": "mdorn@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist30",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=30",
                "https://news.legislature.ne.gov/dist30/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-30-upper-ocd-person-4a8d555a-4bba-4f76-a3e1-88eee6030413": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/9d6fd408-5497-4038-9716-c1f487e348d2",
            "firstName": "Kathleen",
            "lastName": "Kauth",
            "fullName": "Kathleen Kauth",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist31/highres/dist31.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-NE-31-upper-ocd-person-9d6fd408-5497-4038-9716-c1f487e348d2",
              "builtID": "ne-upper-31",
              "externalID": "ocd-person/9d6fd408-5497-4038-9716-c1f487e348d2",
              "geometry": null
            },
            "contactInfo": {
              "email": "kkauth@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist31/",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=31",
                "https://www.nebraskalegislature.gov/senators/landing-pages/index.php?District=31"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-31-upper-ocd-person-9d6fd408-5497-4038-9716-c1f487e348d2": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/37ed5b35-3aea-40a2-95ed-ecc5120fbe82",
            "firstName": "Tom",
            "lastName": "Brandt",
            "fullName": "Tom Brandt",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist32/highres/dist32.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-NE-32-upper-ocd-person-37ed5b35-3aea-40a2-95ed-ecc5120fbe82",
              "builtID": "ne-upper-32",
              "externalID": "ocd-person/37ed5b35-3aea-40a2-95ed-ecc5120fbe82",
              "geometry": null
            },
            "contactInfo": {
              "email": "tbrandt@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist32",
                "https://news.legislature.ne.gov/dist32/",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=32"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-32-upper-ocd-person-37ed5b35-3aea-40a2-95ed-ecc5120fbe82": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/161479ed-b2dd-46e6-b43d-d82d2cce898e",
            "firstName": "Steve",
            "lastName": "Halloran",
            "fullName": "Steve Halloran",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist33/highres/dist33.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-NE-33-upper-ocd-person-161479ed-b2dd-46e6-b43d-d82d2cce898e",
              "builtID": "ne-upper-33",
              "externalID": "ocd-person/161479ed-b2dd-46e6-b43d-d82d2cce898e",
              "geometry": null
            },
            "contactInfo": {
              "email": "shalloran@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist33",
                "https://news.legislature.ne.gov/dist33/",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=33"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-33-upper-ocd-person-161479ed-b2dd-46e6-b43d-d82d2cce898e": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/2ad42fad-ae37-44a6-8933-0a942f7a85f7",
            "firstName": "Loren",
            "lastName": "Lippincott",
            "fullName": "Loren Lippincott",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist34/highres/dist34.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-NE-34-upper-ocd-person-2ad42fad-ae37-44a6-8933-0a942f7a85f7",
              "builtID": "ne-upper-34",
              "externalID": "ocd-person/2ad42fad-ae37-44a6-8933-0a942f7a85f7",
              "geometry": null
            },
            "contactInfo": {
              "email": "llippincott@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=34"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-34-upper-ocd-person-2ad42fad-ae37-44a6-8933-0a942f7a85f7": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/c52425e7-796a-427b-bf66-3bad19439493",
            "firstName": "Raymond",
            "lastName": "Aguilar",
            "fullName": "Ray Aguilar",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist35/highres/dist35.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-NE-35-upper-ocd-person-c52425e7-796a-427b-bf66-3bad19439493",
              "builtID": "ne-upper-35",
              "externalID": "ocd-person/c52425e7-796a-427b-bf66-3bad19439493",
              "geometry": null
            },
            "contactInfo": {
              "email": "raguilar@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist35/",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=35"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-35-upper-ocd-person-c52425e7-796a-427b-bf66-3bad19439493": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/62ccba1d-4200-41c7-a051-21fa421a206a",
            "firstName": "Richard",
            "lastName": "Holdcroft",
            "fullName": "Rick Holdcroft",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist36/highres/dist36.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-NE-36-upper-ocd-person-62ccba1d-4200-41c7-a051-21fa421a206a",
              "builtID": "ne-upper-36",
              "externalID": "ocd-person/62ccba1d-4200-41c7-a051-21fa421a206a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rholdcroft@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=36"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-36-upper-ocd-person-62ccba1d-4200-41c7-a051-21fa421a206a": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/b68e46b2-ab04-4e52-8269-be6f9b5cf134",
            "firstName": "John S.",
            "lastName": "Lowe",
            "fullName": "John Lowe",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist37/highres/dist37.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-NE-37-upper-ocd-person-b68e46b2-ab04-4e52-8269-be6f9b5cf134",
              "builtID": "ne-upper-37",
              "externalID": "ocd-person/b68e46b2-ab04-4e52-8269-be6f9b5cf134",
              "geometry": null
            },
            "contactInfo": {
              "email": "jlowe@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist37",
                "https://news.legislature.ne.gov/dist37/",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=37"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-37-upper-ocd-person-b68e46b2-ab04-4e52-8269-be6f9b5cf134": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/25c29fce-5de4-46cd-97f0-887033d446ce",
            "firstName": "Dave",
            "lastName": "Murman",
            "fullName": "Dave Murman",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist38/highres/dist38.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-NE-38-upper-ocd-person-25c29fce-5de4-46cd-97f0-887033d446ce",
              "builtID": "ne-upper-38",
              "externalID": "ocd-person/25c29fce-5de4-46cd-97f0-887033d446ce",
              "geometry": null
            },
            "contactInfo": {
              "email": "dmurman@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist38",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=38",
                "https://news.legislature.ne.gov/dist38/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-38-upper-ocd-person-25c29fce-5de4-46cd-97f0-887033d446ce": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/c2eb46a6-7524-41e6-a5c3-31e364a23183",
            "firstName": "Lou Ann",
            "lastName": "Linehan",
            "fullName": "Lou Ann Linehan",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist39/highres/dist39.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-NE-39-upper-ocd-person-c2eb46a6-7524-41e6-a5c3-31e364a23183",
              "builtID": "ne-upper-39",
              "externalID": "ocd-person/c2eb46a6-7524-41e6-a5c3-31e364a23183",
              "geometry": null
            },
            "contactInfo": {
              "email": "llinehan@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist39",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=39",
                "https://news.legislature.ne.gov/dist39/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-39-upper-ocd-person-c2eb46a6-7524-41e6-a5c3-31e364a23183": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/01d5ca17-53a1-463a-b2f6-74fe24833c0f",
            "firstName": "Barry D.",
            "lastName": "DeKay",
            "fullName": "Barry DeKay",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist40/highres/dist40.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-NE-40-upper-ocd-person-01d5ca17-53a1-463a-b2f6-74fe24833c0f",
              "builtID": "ne-upper-40",
              "externalID": "ocd-person/01d5ca17-53a1-463a-b2f6-74fe24833c0f",
              "geometry": null
            },
            "contactInfo": {
              "email": "bdekay@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=40"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-40-upper-ocd-person-01d5ca17-53a1-463a-b2f6-74fe24833c0f": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/5868ed3b-2931-4080-a7c2-9b4eb0e3d487",
            "firstName": "Fred",
            "lastName": "Meyer",
            "fullName": "Fred Meyer",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist41/highres/dist41.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-NE-41-upper-ocd-person-5868ed3b-2931-4080-a7c2-9b4eb0e3d487",
              "builtID": "ne-upper-41",
              "externalID": "ocd-person/5868ed3b-2931-4080-a7c2-9b4eb0e3d487",
              "geometry": null
            },
            "contactInfo": {
              "email": "fmeyer@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=41"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-41-upper-ocd-person-5868ed3b-2931-4080-a7c2-9b4eb0e3d487": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/70b3f826-87f3-43c6-9762-27a178156a55",
            "firstName": "Michael B.",
            "lastName": "Jacobson",
            "fullName": "Mike Jacobson",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist42/highres/dist42.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-NE-42-upper-ocd-person-70b3f826-87f3-43c6-9762-27a178156a55",
              "builtID": "ne-upper-42",
              "externalID": "ocd-person/70b3f826-87f3-43c6-9762-27a178156a55",
              "geometry": null
            },
            "contactInfo": {
              "email": "mjacobson@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=42",
                "https://news.legislature.ne.gov/dist42/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-42-upper-ocd-person-70b3f826-87f3-43c6-9762-27a178156a55": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/5ffe6af7-c819-416a-ae92-f575baae1d75",
            "firstName": "Thomas Hill",
            "lastName": "Brewer",
            "fullName": "Tom Brewer",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist43/highres/dist43.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "43",
              "chamber": "upper",
              "hashID": "SLDU-NE-43-upper-ocd-person-5ffe6af7-c819-416a-ae92-f575baae1d75",
              "builtID": "ne-upper-43",
              "externalID": "ocd-person/5ffe6af7-c819-416a-ae92-f575baae1d75",
              "geometry": null
            },
            "contactInfo": {
              "email": "tbrewer@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist43",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=43",
                "https://news.legislature.ne.gov/dist43/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-43-upper-ocd-person-5ffe6af7-c819-416a-ae92-f575baae1d75": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/374b6dc4-5b90-4028-814c-84895a293c84",
            "firstName": "Teresa J.",
            "lastName": "Ibach",
            "fullName": "Teresa Ibach",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist44/highres/dist44.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "44",
              "chamber": "upper",
              "hashID": "SLDU-NE-44-upper-ocd-person-374b6dc4-5b90-4028-814c-84895a293c84",
              "builtID": "ne-upper-44",
              "externalID": "ocd-person/374b6dc4-5b90-4028-814c-84895a293c84",
              "geometry": null
            },
            "contactInfo": {
              "email": "tibach@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=44"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-44-upper-ocd-person-374b6dc4-5b90-4028-814c-84895a293c84": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/de8d4e0a-36dd-4a84-96ee-e4b7bc4cf69d",
            "firstName": "Rita Gomez",
            "lastName": "Sanders",
            "fullName": "Rita Sanders",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist45/highres/dist45.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "45",
              "chamber": "upper",
              "hashID": "SLDU-NE-45-upper-ocd-person-de8d4e0a-36dd-4a84-96ee-e4b7bc4cf69d",
              "builtID": "ne-upper-45",
              "externalID": "ocd-person/de8d4e0a-36dd-4a84-96ee-e4b7bc4cf69d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rsanders@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=45",
                "https://news.legislature.ne.gov/dist45/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-45-upper-ocd-person-de8d4e0a-36dd-4a84-96ee-e4b7bc4cf69d": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/bf280e36-3547-4d6a-b5aa-d6c32bb5547a",
            "firstName": "Danielle",
            "lastName": "Conrad",
            "fullName": "Danielle Conrad",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist46/highres/dist46.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "46",
              "chamber": "upper",
              "hashID": "SLDU-NE-46-upper-ocd-person-bf280e36-3547-4d6a-b5aa-d6c32bb5547a",
              "builtID": "ne-upper-46",
              "externalID": "ocd-person/bf280e36-3547-4d6a-b5aa-d6c32bb5547a",
              "geometry": null
            },
            "contactInfo": {
              "email": "dconrad@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=46"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-46-upper-ocd-person-bf280e36-3547-4d6a-b5aa-d6c32bb5547a": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/9b133798-e217-40a2-b6ce-77b12e58f428",
            "firstName": "Steve",
            "lastName": "Erdman",
            "fullName": "Steve Erdman",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist47/highres/dist47.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "47",
              "chamber": "upper",
              "hashID": "SLDU-NE-47-upper-ocd-person-9b133798-e217-40a2-b6ce-77b12e58f428",
              "builtID": "ne-upper-47",
              "externalID": "ocd-person/9b133798-e217-40a2-b6ce-77b12e58f428",
              "geometry": null
            },
            "contactInfo": {
              "email": "serdman@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://news.legislature.ne.gov/dist47",
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=47",
                "https://news.legislature.ne.gov/dist47/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-47-upper-ocd-person-9b133798-e217-40a2-b6ce-77b12e58f428": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/a98b3cf6-9bf8-40a3-94f3-1c196e233ebd",
            "firstName": "Brian",
            "lastName": "Hardin",
            "fullName": "Brian Hardin",
            "gender": "Male",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist48/highres/dist48.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "48",
              "chamber": "upper",
              "hashID": "SLDU-NE-48-upper-ocd-person-a98b3cf6-9bf8-40a3-94f3-1c196e233ebd",
              "builtID": "ne-upper-48",
              "externalID": "ocd-person/a98b3cf6-9bf8-40a3-94f3-1c196e233ebd",
              "geometry": null
            },
            "contactInfo": {
              "email": "bhardin@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=48"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-48-upper-ocd-person-a98b3cf6-9bf8-40a3-94f3-1c196e233ebd": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/f1aff840-9cc2-44a1-a117-c96d5f0837b9",
            "firstName": "Jen",
            "lastName": "Day",
            "fullName": "Jen Day",
            "gender": "Female",
            "party": "nonpartisan",
            "image": "https://www.nebraskalegislature.gov/media/images/senators/dist49/highres/dist49.jpg",
            "title": "NE Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NE",
              "stateFull": "Nebraska",
              "district": "49",
              "chamber": "upper",
              "hashID": "SLDU-NE-49-upper-ocd-person-f1aff840-9cc2-44a1-a117-c96d5f0837b9",
              "builtID": "ne-upper-49",
              "externalID": "ocd-person/f1aff840-9cc2-44a1-a117-c96d5f0837b9",
              "geometry": null
            },
            "contactInfo": {
              "email": "jday@leg.ne.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://nebraskalegislature.gov/senators/landing-pages/index.php?District=49",
                "https://news.legislature.ne.gov/dist49/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NE-49-upper-ocd-person-f1aff840-9cc2-44a1-a117-c96d5f0837b9": 0
        }
      }
    }
  },
  "NV": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "NV",
          "stateFull": "Nevada",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "NV",
            "stateFull": "Nevada",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-NV---",
            "builtID": "nv--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-NV---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "C001113",
          "in_office": true,
          "firstName": "Catherine",
          "lastName": "Cortez Masto",
          "middleName": null,
          "fullName": "Catherine Cortez Masto",
          "gender": "F",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/C001113.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "NV",
            "stateFull": "Nevada",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-NV---C001113",
            "builtID": "nv--",
            "externalID": "C001113",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "sencortezmasto",
            "youtube": null,
            "instagram": null,
            "facebook": "SenatorCortezMasto",
            "urls": ["https://www.cortezmasto.senate.gov"],
            "rss_url": null
          },
          "title": "NV Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/C001113.json",
          "govtrack_id": "412681",
          "cspan_id": "105698",
          "votesmart_id": "69579",
          "icpsr_id": "41700",
          "crp_id": "N00037161",
          "google_entity_id": "/m/02rtp9t",
          "state_rank": "senior",
          "lis_id": "S385",
          "suffix": null,
          "date_of_birth": "1964-03-29",
          "leadership_role": null,
          "fec_candidate_id": "S6NV00200",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "3",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 9,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 1.62,
          "votes_with_party_pct": 99.26,
          "votes_against_party_pct": 0.74,
          "ocd_id": "ocd-division/country:us/state:nv"
        },
        {
          "API_ID": "R000608",
          "in_office": true,
          "firstName": "Jacky",
          "lastName": "Rosen",
          "middleName": null,
          "fullName": "Jacky Rosen",
          "gender": "F",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/R000608.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "NV",
            "stateFull": "Nevada",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-NV---R000608",
            "builtID": "nv--",
            "externalID": "R000608",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.rosen.senate.gov/contact_jacky",
            "address1": "713 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-6244",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenJackyRosen",
            "youtube": null,
            "instagram": null,
            "facebook": "RepJackyRosen",
            "urls": ["https://www.rosen.senate.gov"],
            "rss_url": null
          },
          "title": "NV Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/R000608.json",
          "govtrack_id": "412715",
          "cspan_id": "104738",
          "votesmart_id": "169471",
          "icpsr_id": "21743",
          "crp_id": "N00038734",
          "google_entity_id": "/g/11cntnvwkg",
          "state_rank": "junior",
          "lis_id": "S402",
          "suffix": null,
          "date_of_birth": "1957-08-02",
          "leadership_role": null,
          "fec_candidate_id": "H6NV03139",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "5",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 0,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 0,
          "votes_with_party_pct": 97.1,
          "votes_against_party_pct": 2.9,
          "ocd_id": "ocd-division/country:us/state:nv"
        }
      ],
      "hash": { "SENATE-NV---C001113": 0, "SENATE-NV---R000608": 1 }
    },
    "HOUSE": {
      "02": {
        "members": [
          {
            "API_ID": "A000369",
            "in_office": true,
            "firstName": "Mark",
            "lastName": "Amodei",
            "middleName": null,
            "fullName": "Mark E. Amodei",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/A000369.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-NV-02--A000369",
              "builtID": "nv--02",
              "externalID": "A000369",
              "geometry": null,
              "geoid": "3202"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "104 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-6155",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "MarkAmodeiNV2",
              "youtube": "markamodeinv2",
              "instagram": null,
              "facebook": "MarkAmodeiNV2",
              "urls": ["https://amodei.house.gov"],
              "rss_url": null
            },
            "title": "NV House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/A000369.json",
            "govtrack_id": "412500",
            "cspan_id": "62817",
            "votesmart_id": "12537",
            "icpsr_id": "21196",
            "crp_id": "N00031177",
            "google_entity_id": "/m/03bzdkn",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1958-06-12",
            "leadership_role": null,
            "fec_candidate_id": "H2NV02395",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 10,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.95,
            "votes_with_party_pct": 91.8,
            "votes_against_party_pct": 6.35,
            "ocd_id": "ocd-division/country:us/state:nv/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NV-02--A000369": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "H001066",
            "in_office": true,
            "firstName": "Steven",
            "lastName": "Horsford",
            "middleName": null,
            "fullName": "Steven Horsford",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/H001066.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-NV-04--H001066",
              "builtID": "nv--04",
              "externalID": "H001066",
              "geometry": null,
              "geoid": "3204"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "406 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-9894",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepHorsford",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://horsford.house.gov"],
              "rss_url": "https://horsford.house.gov/rss.xml"
            },
            "title": "NV House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001066.json",
            "govtrack_id": "412559",
            "cspan_id": "79614",
            "votesmart_id": "44064",
            "icpsr_id": "21339",
            "crp_id": "N00033638",
            "google_entity_id": "/m/03c01w9",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1973-04-29",
            "leadership_role": null,
            "fec_candidate_id": "H2NV04011",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 32,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 3.03,
            "votes_with_party_pct": 93.5,
            "votes_against_party_pct": 4.53,
            "ocd_id": "ocd-division/country:us/state:nv/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NV-04--H001066": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "L000590",
            "in_office": true,
            "firstName": "Susie",
            "lastName": "Lee",
            "middleName": null,
            "fullName": "Susie Lee",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/L000590.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-NV-03--L000590",
              "builtID": "nv--03",
              "externalID": "L000590",
              "geometry": null,
              "geoid": "3203"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "365 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3252",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepSusieLee",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://susielee.house.gov"],
              "rss_url": null
            },
            "title": "NV House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000590.json",
            "govtrack_id": "412802",
            "cspan_id": null,
            "votesmart_id": "169344",
            "icpsr_id": null,
            "crp_id": "N00037247",
            "google_entity_id": "/g/11gtdntn1_",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1966-11-07",
            "leadership_role": null,
            "fec_candidate_id": "H6NV04020",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 48,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 4.55,
            "votes_with_party_pct": 90.49,
            "votes_against_party_pct": 7.51,
            "ocd_id": "ocd-division/country:us/state:nv/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NV-03--L000590": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "T000468",
            "in_office": true,
            "firstName": "Dina",
            "lastName": "Titus",
            "middleName": null,
            "fullName": "Dina Titus",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/T000468.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-NV-01--T000468",
              "builtID": "nv--01",
              "externalID": "T000468",
              "geometry": null,
              "geoid": "3201"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2464 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5965",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDinaTitus",
              "youtube": "CongresswomanTitus",
              "instagram": null,
              "facebook": "CongresswomanTitus",
              "urls": ["https://titus.house.gov"],
              "rss_url": null
            },
            "title": "NV House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000468.json",
            "govtrack_id": "412318",
            "cspan_id": "1021622",
            "votesmart_id": "2629",
            "icpsr_id": "20927",
            "crp_id": "N00030191",
            "google_entity_id": "/m/07hwl2",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1950-05-23",
            "leadership_role": null,
            "fec_candidate_id": "H8NV03036",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 42,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.98,
            "votes_with_party_pct": 95.02,
            "votes_against_party_pct": 3.09,
            "ocd_id": "ocd-division/country:us/state:nv/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NV-01--T000468": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/b266a1bc-d8f5-472c-ba8d-e98e0d904ee3",
            "firstName": "Daniele",
            "lastName": "Monroe-Moreno",
            "fullName": "Daniele Monroe-Moreno",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/MonroeMoreno.Daniele.307.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-NV-1-lower-ocd-person-b266a1bc-d8f5-472c-ba8d-e98e0d904ee3",
              "builtID": "nv-lower-1",
              "externalID": "ocd-person/b266a1bc-d8f5-472c-ba8d-e98e0d904ee3",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniele.monroemoreno@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=307",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-1-lower-ocd-person-b266a1bc-d8f5-472c-ba8d-e98e0d904ee3": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/61389b89-1169-49b3-a17b-c904f2111b4e",
            "firstName": "Heidi W.",
            "lastName": "Kasama",
            "fullName": "Heidi Kasama",
            "gender": "Female",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/Kasama.Heidi.379.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-NV-2-lower-ocd-person-61389b89-1169-49b3-a17b-c904f2111b4e",
              "builtID": "nv-lower-2",
              "externalID": "ocd-person/61389b89-1169-49b3-a17b-c904f2111b4e",
              "geometry": null
            },
            "contactInfo": {
              "email": "heidi.kasama@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=379",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/2"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-2-lower-ocd-person-61389b89-1169-49b3-a17b-c904f2111b4e": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/59a3b5f2-a135-48e5-9baa-98585f8ff35e",
            "firstName": "Selena",
            "lastName": "Torres",
            "fullName": "Selena Torres",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/80th2019/legislators/Assembly/Images/Torres.Selena.359.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-NV-3-lower-ocd-person-59a3b5f2-a135-48e5-9baa-98585f8ff35e",
              "builtID": "nv-lower-3",
              "externalID": "ocd-person/59a3b5f2-a135-48e5-9baa-98585f8ff35e",
              "geometry": null
            },
            "contactInfo": {
              "email": "selena.torres@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=359",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/3"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-3-lower-ocd-person-59a3b5f2-a135-48e5-9baa-98585f8ff35e": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/767ad2fb-a79a-4cbc-807a-572df02da823",
            "firstName": "Richard",
            "lastName": "McArthur",
            "fullName": "Richard McArthur",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/79th2017/legislators/Assembly/Images/McArthur.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-NV-4-lower-ocd-person-767ad2fb-a79a-4cbc-807a-572df02da823",
              "builtID": "nv-lower-4",
              "externalID": "ocd-person/767ad2fb-a79a-4cbc-807a-572df02da823",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.mcarthur@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=301",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/4"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-4-lower-ocd-person-767ad2fb-a79a-4cbc-807a-572df02da823": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/287d0078-78f3-4717-8493-9f77d0eb57d6",
            "firstName": "Brittney Marie",
            "lastName": "Miller",
            "fullName": "Brittney Miller",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/80th2019/legislators/Assembly/Images/Miller.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-NV-5-lower-ocd-person-287d0078-78f3-4717-8493-9f77d0eb57d6",
              "builtID": "nv-lower-5",
              "externalID": "ocd-person/287d0078-78f3-4717-8493-9f77d0eb57d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "brittney.miller@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=304",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/5"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-5-lower-ocd-person-287d0078-78f3-4717-8493-9f77d0eb57d6": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/fd083d2a-961b-4ad3-9862-bc37b2fb272f",
            "firstName": "Shondra",
            "lastName": "Summers-Armstrong",
            "fullName": "Shondra Summers-Armstrong",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/SummersArmstrong.Shondra.386.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-NV-6-lower-ocd-person-fd083d2a-961b-4ad3-9862-bc37b2fb272f",
              "builtID": "nv-lower-6",
              "externalID": "ocd-person/fd083d2a-961b-4ad3-9862-bc37b2fb272f",
              "geometry": null
            },
            "contactInfo": {
              "email": "shondra.armstrong@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=386",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/6"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-6-lower-ocd-person-fd083d2a-961b-4ad3-9862-bc37b2fb272f": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/fb919fa4-e37e-4268-9f5d-e4c3e9490475",
            "firstName": "Duy",
            "lastName": "Nguyen",
            "fullName": "Duy Nguyen",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/82nd2023/legislators/Assembly/Images/Nguyen.Duy.392.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-NV-8-lower-ocd-person-fb919fa4-e37e-4268-9f5d-e4c3e9490475",
              "builtID": "nv-lower-8",
              "externalID": "ocd-person/fb919fa4-e37e-4268-9f5d-e4c3e9490475",
              "geometry": null
            },
            "contactInfo": {
              "email": "duy.nguyen@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/8"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-8-lower-ocd-person-fb919fa4-e37e-4268-9f5d-e4c3e9490475": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/4caae060-1c91-4d9d-a8f9-83d74c7d0f58",
            "firstName": "Steven James",
            "lastName": "Yeager",
            "fullName": "Steve Yeager",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/80th2019/legislators/Assembly/Images/Yeager.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-NV-9-lower-ocd-person-4caae060-1c91-4d9d-a8f9-83d74c7d0f58",
              "builtID": "nv-lower-9",
              "externalID": "ocd-person/4caae060-1c91-4d9d-a8f9-83d74c7d0f58",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.yeager@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=310",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/9"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-9-lower-ocd-person-4caae060-1c91-4d9d-a8f9-83d74c7d0f58": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/ba795c1a-235c-49d1-af4d-9d23a2395ccb",
            "firstName": "Beatrice Angela",
            "lastName": "Duran",
            "fullName": "Bea Duran",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/Duran.Bea.373.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-NV-11-lower-ocd-person-ba795c1a-235c-49d1-af4d-9d23a2395ccb",
              "builtID": "nv-lower-11",
              "externalID": "ocd-person/ba795c1a-235c-49d1-af4d-9d23a2395ccb",
              "geometry": null
            },
            "contactInfo": {
              "email": "bea.duran@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=373",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/11"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-11-lower-ocd-person-ba795c1a-235c-49d1-af4d-9d23a2395ccb": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/bd27bab3-8a47-439f-8ae5-8ce3ba534f90",
            "firstName": "Max",
            "lastName": "Carter",
            "fullName": "Max Carter",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/82nd2023/legislators/Assembly/Images/Carter.Max.393.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-NV-12-lower-ocd-person-bd27bab3-8a47-439f-8ae5-8ce3ba534f90",
              "builtID": "nv-lower-12",
              "externalID": "ocd-person/bd27bab3-8a47-439f-8ae5-8ce3ba534f90",
              "geometry": null
            },
            "contactInfo": {
              "email": "max.carter@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": "181 Clayton St., Las Vegas, NV 89110",
              "phone1": null,
              "phone2": "702-386-8603",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/12"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-12-lower-ocd-person-bd27bab3-8a47-439f-8ae5-8ce3ba534f90": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/20c9828b-8026-440a-9328-0c2cbcbad016",
            "firstName": "Brian",
            "lastName": "Hibbetts",
            "fullName": "Brian Hibbetts",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/82nd2023/legislators/Assembly/Images/Hibbetts.Brian.394.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-NV-13-lower-ocd-person-20c9828b-8026-440a-9328-0c2cbcbad016",
              "builtID": "nv-lower-13",
              "externalID": "ocd-person/20c9828b-8026-440a-9328-0c2cbcbad016",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.hibbetts@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": "8461 W. Farm Road, Number 120-287, Las Vegas, NV 89131",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/13"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-13-lower-ocd-person-20c9828b-8026-440a-9328-0c2cbcbad016": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/39e0ca55-992e-4558-aec5-279adb467de7",
            "firstName": "Erica V.",
            "lastName": "Mosca",
            "fullName": "Erica Mosca",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/82nd2023/legislators/Assembly/Images/Mosca.Erica.395.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-NV-14-lower-ocd-person-39e0ca55-992e-4558-aec5-279adb467de7",
              "builtID": "nv-lower-14",
              "externalID": "ocd-person/39e0ca55-992e-4558-aec5-279adb467de7",
              "geometry": null
            },
            "contactInfo": {
              "email": "erica.mosca@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": "2126 Citroen St., Las Vegas, NV 89142",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/14"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-14-lower-ocd-person-39e0ca55-992e-4558-aec5-279adb467de7": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/b88fd854-c97b-4908-a554-583b93bca5c4",
            "firstName": "Howard",
            "lastName": "Watts",
            "fullName": "Howard Watts",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/33rd2021Special/legislators/Assembly/Images/Watts.Howard.363.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-NV-15-lower-ocd-person-b88fd854-c97b-4908-a554-583b93bca5c4",
              "builtID": "nv-lower-15",
              "externalID": "ocd-person/b88fd854-c97b-4908-a554-583b93bca5c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "howard.watts@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=363",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/15"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-15-lower-ocd-person-b88fd854-c97b-4908-a554-583b93bca5c4": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/67f22a3d-05c3-4256-8260-dac682607fc7",
            "firstName": "Cecelia",
            "lastName": "Gonzalez",
            "fullName": "Cecelia González",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/Gonzlez.Cecelia.378.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-NV-16-lower-ocd-person-67f22a3d-05c3-4256-8260-dac682607fc7",
              "builtID": "nv-lower-16",
              "externalID": "ocd-person/67f22a3d-05c3-4256-8260-dac682607fc7",
              "geometry": null
            },
            "contactInfo": {
              "email": "cecelia.gonzalez@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=378",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/16"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-16-lower-ocd-person-67f22a3d-05c3-4256-8260-dac682607fc7": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/cea67d43-e30a-4ec3-af31-4cf26bd6c780",
            "firstName": "Clara",
            "lastName": "Thomas",
            "fullName": "Claire Thomas",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/Thomas.Clara.381.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-NV-17-lower-ocd-person-cea67d43-e30a-4ec3-af31-4cf26bd6c780",
              "builtID": "nv-lower-17",
              "externalID": "ocd-person/cea67d43-e30a-4ec3-af31-4cf26bd6c780",
              "geometry": null
            },
            "contactInfo": {
              "email": "clara.thomas@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=381",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/17"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-17-lower-ocd-person-cea67d43-e30a-4ec3-af31-4cf26bd6c780": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/00175071-948a-4814-b66e-b07b2ec7785d",
            "firstName": "Venicia",
            "lastName": "Considine",
            "fullName": "Venicia Considine",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/Considine.Venicia.380.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-NV-18-lower-ocd-person-00175071-948a-4814-b66e-b07b2ec7785d",
              "builtID": "nv-lower-18",
              "externalID": "ocd-person/00175071-948a-4814-b66e-b07b2ec7785d",
              "geometry": null
            },
            "contactInfo": {
              "email": "venicia.considine@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=380",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/18"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-18-lower-ocd-person-00175071-948a-4814-b66e-b07b2ec7785d": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/7b7e4737-3bd7-462f-894f-1397a87f04ab",
            "firstName": "Thaddeus J.",
            "lastName": "Yurek",
            "fullName": "Toby Yurek",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/82nd2023/legislators/Assembly/Images/Yurek.Toby.396.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-NV-19-lower-ocd-person-7b7e4737-3bd7-462f-894f-1397a87f04ab",
              "builtID": "nv-lower-19",
              "externalID": "ocd-person/7b7e4737-3bd7-462f-894f-1397a87f04ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "toby.yurek@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/19"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-19-lower-ocd-person-7b7e4737-3bd7-462f-894f-1397a87f04ab": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/7d16a086-5877-48e9-9e76-13c5159f89ce",
            "firstName": "David",
            "lastName": "Orentlicher",
            "fullName": "David Orentlicher",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/Orentlicher.David.377.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-NV-20-lower-ocd-person-7d16a086-5877-48e9-9e76-13c5159f89ce",
              "builtID": "nv-lower-20",
              "externalID": "ocd-person/7d16a086-5877-48e9-9e76-13c5159f89ce",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.orentlicher@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=377",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/20"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-20-lower-ocd-person-7d16a086-5877-48e9-9e76-13c5159f89ce": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/a76836f8-24c9-4f90-8e13-29f417a7ad9f",
            "firstName": "Elaine H.",
            "lastName": "Marzola",
            "fullName": "Elaine Marzola",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/Marzola.Elaine.376.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-NV-21-lower-ocd-person-a76836f8-24c9-4f90-8e13-29f417a7ad9f",
              "builtID": "nv-lower-21",
              "externalID": "ocd-person/a76836f8-24c9-4f90-8e13-29f417a7ad9f",
              "geometry": null
            },
            "contactInfo": {
              "email": "elaine.marzola@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=376",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/21"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-21-lower-ocd-person-a76836f8-24c9-4f90-8e13-29f417a7ad9f": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/5cafbfaf-3066-4080-9754-b53f337ba4ba",
            "firstName": "Melissa",
            "lastName": "Hardy",
            "fullName": "Melissa Hardy",
            "gender": "Female",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/80th2019/legislators/Assembly/Images/Hardy.Melissa.364.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-NV-22-lower-ocd-person-5cafbfaf-3066-4080-9754-b53f337ba4ba",
              "builtID": "nv-lower-22",
              "externalID": "ocd-person/5cafbfaf-3066-4080-9754-b53f337ba4ba",
              "geometry": null
            },
            "contactInfo": {
              "email": "melissa.hardy@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=364",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/22"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-22-lower-ocd-person-5cafbfaf-3066-4080-9754-b53f337ba4ba": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/bf8b61a8-585a-4357-82f8-bd8de898262b",
            "firstName": "Danielle",
            "lastName": "Gallant",
            "fullName": "Danielle Gallant",
            "gender": "Female",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/82nd2023/legislators/Assembly/Images/Gallant.Danielle.397.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-NV-23-lower-ocd-person-bf8b61a8-585a-4357-82f8-bd8de898262b",
              "builtID": "nv-lower-23",
              "externalID": "ocd-person/bf8b61a8-585a-4357-82f8-bd8de898262b",
              "geometry": null
            },
            "contactInfo": {
              "email": "danielle.gallant@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": "7380 S. Eastern Ave., Suite 124, Las Vegas, NV 89123",
              "phone1": null,
              "phone2": "725-272-7798",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/23"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-23-lower-ocd-person-bf8b61a8-585a-4357-82f8-bd8de898262b": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/be47c5c9-c307-4324-9e83-c46e594b5f06",
            "firstName": "Sarah",
            "lastName": "Peters",
            "fullName": "Sarah Peters",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/Peters.Sarah.356.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-NV-24-lower-ocd-person-be47c5c9-c307-4324-9e83-c46e594b5f06",
              "builtID": "nv-lower-24",
              "externalID": "ocd-person/be47c5c9-c307-4324-9e83-c46e594b5f06",
              "geometry": null
            },
            "contactInfo": {
              "email": "sarah.peters@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=356",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/24"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-24-lower-ocd-person-be47c5c9-c307-4324-9e83-c46e594b5f06": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/99f4f903-1c4f-49f0-835f-c0b9b1aa0317",
            "firstName": "Selena",
            "lastName": "La Rue Hatch",
            "fullName": "Selena La Rue Hatch",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/82nd2023/legislators/Assembly/Images/LaRueHatch.Selena.398.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-NV-25-lower-ocd-person-99f4f903-1c4f-49f0-835f-c0b9b1aa0317",
              "builtID": "nv-lower-25",
              "externalID": "ocd-person/99f4f903-1c4f-49f0-835f-c0b9b1aa0317",
              "geometry": null
            },
            "contactInfo": {
              "email": "selena.la.rue.hatch@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/25"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-25-lower-ocd-person-99f4f903-1c4f-49f0-835f-c0b9b1aa0317": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/204b492e-4b29-4e8e-9c3e-d2d0f0a8ceea",
            "firstName": "Richard",
            "lastName": "DeLong",
            "fullName": "Rich DeLong",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/82nd2023/legislators/Assembly/Images/DeLong.Rich.399.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-NV-26-lower-ocd-person-204b492e-4b29-4e8e-9c3e-d2d0f0a8ceea",
              "builtID": "nv-lower-26",
              "externalID": "ocd-person/204b492e-4b29-4e8e-9c3e-d2d0f0a8ceea",
              "geometry": null
            },
            "contactInfo": {
              "email": "rich.delong@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": "59 Damonte Ranch Parkway Suite 543, Reno, NV 89521",
              "phone1": null,
              "phone2": "775-476-2321",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/26"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-26-lower-ocd-person-204b492e-4b29-4e8e-9c3e-d2d0f0a8ceea": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/d95b3a70-9bb4-43b8-a376-9d3151f9c44d",
            "firstName": "Angie",
            "lastName": "Taylor",
            "fullName": "Angie Taylor",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/AngieTaylor22.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-NV-27-lower-ocd-person-d95b3a70-9bb4-43b8-a376-9d3151f9c44d",
              "builtID": "nv-lower-27",
              "externalID": "ocd-person/d95b3a70-9bb4-43b8-a376-9d3151f9c44d",
              "geometry": null
            },
            "contactInfo": {
              "email": "angie.taylor@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": "10580 North McCarran Blvd., No. 115-345, Reno, NV 89503",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/27"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-27-lower-ocd-person-d95b3a70-9bb4-43b8-a376-9d3151f9c44d": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/eae59702-f6de-431e-98f0-9c4bc5b6e059",
            "firstName": "Reuben",
            "lastName": "D'Silva",
            "fullName": "Reuben D'Silva",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/82nd2023/legislators/Assembly/Images/DSilva.Reuben.401.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-NV-28-lower-ocd-person-eae59702-f6de-431e-98f0-9c4bc5b6e059",
              "builtID": "nv-lower-28",
              "externalID": "ocd-person/eae59702-f6de-431e-98f0-9c4bc5b6e059",
              "geometry": null
            },
            "contactInfo": {
              "email": "reuben.dsilva@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 363106, North Las Vegas, NV 89030",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/28"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-28-lower-ocd-person-eae59702-f6de-431e-98f0-9c4bc5b6e059": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/0f7983ab-0a1f-435a-b8ae-f5f4a73e26c5",
            "firstName": "Lesley Elizabeth",
            "lastName": "Cohen",
            "fullName": "Lesley Cohen",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/80th2019/legislators/Assembly/Images/Cohen.Lesley.268.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-NV-29-lower-ocd-person-0f7983ab-0a1f-435a-b8ae-f5f4a73e26c5",
              "builtID": "nv-lower-29",
              "externalID": "ocd-person/0f7983ab-0a1f-435a-b8ae-f5f4a73e26c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "lesley.cohen@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=268",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/29"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-29-lower-ocd-person-0f7983ab-0a1f-435a-b8ae-f5f4a73e26c5": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/d4a1125a-69d2-4de1-a4b4-af2aae20de09",
            "firstName": "Natha C.",
            "lastName": "Anderson",
            "fullName": "Natha Anderson",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/Anderson.Natha.375.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-NV-30-lower-ocd-person-d4a1125a-69d2-4de1-a4b4-af2aae20de09",
              "builtID": "nv-lower-30",
              "externalID": "ocd-person/d4a1125a-69d2-4de1-a4b4-af2aae20de09",
              "geometry": null
            },
            "contactInfo": {
              "email": "natha.anderson@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=375",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/30"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-30-lower-ocd-person-d4a1125a-69d2-4de1-a4b4-af2aae20de09": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/70e15282-2812-4486-9d7e-06862aa45cc3",
            "firstName": "Jill",
            "lastName": "Dickman",
            "fullName": "Jill Dickman",
            "gender": "Female",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/Dickman.Jill.277.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-NV-31-lower-ocd-person-70e15282-2812-4486-9d7e-06862aa45cc3",
              "builtID": "nv-lower-31",
              "externalID": "ocd-person/70e15282-2812-4486-9d7e-06862aa45cc3",
              "geometry": null
            },
            "contactInfo": {
              "email": "jill.dickman@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=277",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/31"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-31-lower-ocd-person-70e15282-2812-4486-9d7e-06862aa45cc3": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/6fee4c5a-f125-46bc-b11a-d254e40428e4",
            "firstName": "Alexis Lloyd",
            "lastName": "Hansen",
            "fullName": "Alexis Hansen",
            "gender": "Female",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/80th2019/legislators/Assembly/Images/Hansen.Alexis.366.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-NV-32-lower-ocd-person-6fee4c5a-f125-46bc-b11a-d254e40428e4",
              "builtID": "nv-lower-32",
              "externalID": "ocd-person/6fee4c5a-f125-46bc-b11a-d254e40428e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "alexis.hansen@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=366",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/32"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-32-lower-ocd-person-6fee4c5a-f125-46bc-b11a-d254e40428e4": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/11f44d71-88c1-4022-a2f5-f8ca9b8795f2",
            "firstName": "Bert",
            "lastName": "Gurr",
            "fullName": "Bert Gurr",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/82nd2023/legislators/Assembly/Images/Gurr.Bert.402.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-NV-33-lower-ocd-person-11f44d71-88c1-4022-a2f5-f8ca9b8795f2",
              "builtID": "nv-lower-33",
              "externalID": "ocd-person/11f44d71-88c1-4022-a2f5-f8ca9b8795f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "bert.gurr@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1436, Elko, NV 89803",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/33"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-33-lower-ocd-person-11f44d71-88c1-4022-a2f5-f8ca9b8795f2": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/c9d78312-791c-488f-9a62-6aca63db21f0",
            "firstName": "Shannon Mary",
            "lastName": "Bilbray-Axelrod",
            "fullName": "Shannon Bilbray-Axelrod",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/80th2019/legislators/Assembly/Images/BilbrayAxelrod.Shannon.332.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-NV-34-lower-ocd-person-c9d78312-791c-488f-9a62-6aca63db21f0",
              "builtID": "nv-lower-34",
              "externalID": "ocd-person/c9d78312-791c-488f-9a62-6aca63db21f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "shannon.bilbrayaxelrod@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=332",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/34"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-34-lower-ocd-person-c9d78312-791c-488f-9a62-6aca63db21f0": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/3435f7f4-4515-40da-a6e8-d03f1e01e9d3",
            "firstName": "Michelle",
            "lastName": "Gorelow",
            "fullName": "Michelle Gorelow",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/80th2019/legislators/Assembly/Images/Gorelow.Michelle.367.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-NV-35-lower-ocd-person-3435f7f4-4515-40da-a6e8-d03f1e01e9d3",
              "builtID": "nv-lower-35",
              "externalID": "ocd-person/3435f7f4-4515-40da-a6e8-d03f1e01e9d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "michelle.gorelow@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=367",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/35"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-35-lower-ocd-person-3435f7f4-4515-40da-a6e8-d03f1e01e9d3": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/33330e1e-b22d-49d7-bce8-6964b289683a",
            "firstName": "Gregory T.",
            "lastName": "Hafen",
            "fullName": "Greg Hafen",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/80th2019/legislators/Assembly/Images/HafenII.Gregory.371.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-NV-36-lower-ocd-person-33330e1e-b22d-49d7-bce8-6964b289683a",
              "builtID": "nv-lower-36",
              "externalID": "ocd-person/33330e1e-b22d-49d7-bce8-6964b289683a",
              "geometry": null
            },
            "contactInfo": {
              "email": "gregory.hafen@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=371",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/36"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-36-lower-ocd-person-33330e1e-b22d-49d7-bce8-6964b289683a": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/1fa69460-c910-4cf1-acfb-1d44c342e658",
            "firstName": "Shea",
            "lastName": "Backus",
            "fullName": "Shea Backus",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/80th2019/legislators/Assembly/Images/Backus.Shea.368.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-NV-37-lower-ocd-person-1fa69460-c910-4cf1-acfb-1d44c342e658",
              "builtID": "nv-lower-37",
              "externalID": "ocd-person/1fa69460-c910-4cf1-acfb-1d44c342e658",
              "geometry": null
            },
            "contactInfo": {
              "email": "shea.backus@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/37"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-37-lower-ocd-person-1fa69460-c910-4cf1-acfb-1d44c342e658": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/1c4f97a9-c965-4d0d-b5b6-39b91aee4f49",
            "firstName": "Gregory Scott",
            "lastName": "Koenig",
            "fullName": "Gregory Koenig",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/82nd2023/legislators/Assembly/Images/Koenig.Gregory.403.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-NV-38-lower-ocd-person-1c4f97a9-c965-4d0d-b5b6-39b91aee4f49",
              "builtID": "nv-lower-38",
              "externalID": "ocd-person/1c4f97a9-c965-4d0d-b5b6-39b91aee4f49",
              "geometry": null
            },
            "contactInfo": {
              "email": "gregory.koenig@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": "2330 Thurman Lane, Fallon, NV 89406",
              "phone1": null,
              "phone2": "775-527-6294",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/38"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-38-lower-ocd-person-1c4f97a9-c965-4d0d-b5b6-39b91aee4f49": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/7ea18ec5-ed07-4de0-8b70-8034022ff32a",
            "firstName": "Kenneth D.",
            "lastName": "Gray",
            "fullName": "Ken Gray",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/82nd2023/legislators/Assembly/Images/Gray.Ken.404.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-NV-39-lower-ocd-person-7ea18ec5-ed07-4de0-8b70-8034022ff32a",
              "builtID": "nv-lower-39",
              "externalID": "ocd-person/7ea18ec5-ed07-4de0-8b70-8034022ff32a",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.gray@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": "1128 Cheatgrass Drive, Dayton, NV 89403",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/39"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-39-lower-ocd-person-7ea18ec5-ed07-4de0-8b70-8034022ff32a": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/a964be66-bd32-48ab-b6c4-9ede22177328",
            "firstName": "Philip",
            "lastName": "O'Neill",
            "fullName": "P.K. O'Neill",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/ONeill.PhilipPK.285.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-NV-40-lower-ocd-person-a964be66-bd32-48ab-b6c4-9ede22177328",
              "builtID": "nv-lower-40",
              "externalID": "ocd-person/a964be66-bd32-48ab-b6c4-9ede22177328",
              "geometry": null
            },
            "contactInfo": {
              "email": "pk.oneill@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=285",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/40"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-40-lower-ocd-person-a964be66-bd32-48ab-b6c4-9ede22177328": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/25e09131-39e3-4c58-96b0-6c1ac9e74b19",
            "firstName": "Sandra",
            "lastName": "Jauregui Fleming",
            "fullName": "Sandra Jauregui",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/Jauregui.Sandra.344.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-NV-41-lower-ocd-person-25e09131-39e3-4c58-96b0-6c1ac9e74b19",
              "builtID": "nv-lower-41",
              "externalID": "ocd-person/25e09131-39e3-4c58-96b0-6c1ac9e74b19",
              "geometry": null
            },
            "contactInfo": {
              "email": "sandra.jauregui@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=344",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/41"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-41-lower-ocd-person-25e09131-39e3-4c58-96b0-6c1ac9e74b19": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/fc07db44-bc3c-412b-a15e-dd013220411a",
            "firstName": "Tracy",
            "lastName": "Brown-May",
            "fullName": "Tracy Brown-May",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/BrownMay.Tracy.353.jpg",
            "title": "NV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-NV-42-lower-ocd-person-fc07db44-bc3c-412b-a15e-dd013220411a",
              "builtID": "nv-lower-42",
              "externalID": "ocd-person/fc07db44-bc3c-412b-a15e-dd013220411a",
              "geometry": null
            },
            "contactInfo": {
              "email": "tracy.brownmay@asm.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/42"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NV-42-lower-ocd-person-fc07db44-bc3c-412b-a15e-dd013220411a": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/313e26dd-bd3c-42fb-8694-b004661b068c",
            "firstName": "Patricia Ann",
            "lastName": "Spearman",
            "fullName": "Pat Spearman",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/79th2017/legislators/Senators/Images/Spearman,P.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-NV-1-upper-ocd-person-313e26dd-bd3c-42fb-8694-b004661b068c",
              "builtID": "nv-upper-1",
              "externalID": "ocd-person/313e26dd-bd3c-42fb-8694-b004661b068c",
              "geometry": null
            },
            "contactInfo": {
              "email": "pat.spearman@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=204",
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-1-upper-ocd-person-313e26dd-bd3c-42fb-8694-b004661b068c": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/e5b06346-f5ee-4e1d-baed-342cc4a8ff36",
            "firstName": "Edgar R.",
            "lastName": "Flores",
            "fullName": "Edgar Flores",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/Flores.Edgar.273.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-NV-2-upper-ocd-person-e5b06346-f5ee-4e1d-baed-342cc4a8ff36",
              "builtID": "nv-upper-2",
              "externalID": "ocd-person/e5b06346-f5ee-4e1d-baed-342cc4a8ff36",
              "geometry": null
            },
            "contactInfo": {
              "email": "edgar.flores@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 42302, Las Vegas, NV 89116",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=273",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/28",
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/2"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-2-upper-ocd-person-e5b06346-f5ee-4e1d-baed-342cc4a8ff36": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/d84ad99c-617a-4d33-a351-8bde19868746",
            "firstName": "Rochelle Thuy",
            "lastName": "Nguyen",
            "fullName": "Rochelle Nguyen",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/82nd2023/legislators/Senators/Images/Nguyen.Rochelle.372.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-NV-3-upper-ocd-person-d84ad99c-617a-4d33-a351-8bde19868746",
              "builtID": "nv-upper-3",
              "externalID": "ocd-person/d84ad99c-617a-4d33-a351-8bde19868746",
              "geometry": null
            },
            "contactInfo": {
              "email": "rochelle.nguyen@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=372",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/10",
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/3"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-3-upper-ocd-person-d84ad99c-617a-4d33-a351-8bde19868746": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/6da4be55-c493-4b72-b436-5257259247d2",
            "firstName": "Dina",
            "lastName": "Neal",
            "fullName": "Dina Neal",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Senators/Images/Neal.Dina.129.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-NV-4-upper-ocd-person-6da4be55-c493-4b72-b436-5257259247d2",
              "builtID": "nv-upper-4",
              "externalID": "ocd-person/6da4be55-c493-4b72-b436-5257259247d2",
              "geometry": null
            },
            "contactInfo": {
              "email": "dina.neal@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=129",
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/4"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-4-upper-ocd-person-6da4be55-c493-4b72-b436-5257259247d2": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/189d270a-96e5-45af-a2b8-bbcc2ad225c1",
            "firstName": "Carrie Ann",
            "lastName": "Buck",
            "fullName": "Carrie Buck",
            "gender": "Female",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Senators/Images/Buck.Carrie.387.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-NV-5-upper-ocd-person-189d270a-96e5-45af-a2b8-bbcc2ad225c1",
              "builtID": "nv-upper-5",
              "externalID": "ocd-person/189d270a-96e5-45af-a2b8-bbcc2ad225c1",
              "geometry": null
            },
            "contactInfo": {
              "email": "carrie.buck@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=387",
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/5"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-5-upper-ocd-person-189d270a-96e5-45af-a2b8-bbcc2ad225c1": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/d2ca0a59-3bf1-4e1c-b4d7-9da33a4c5152",
            "firstName": "Nicole Jeanette",
            "lastName": "Cannizzaro",
            "fullName": "Nicole Cannizzaro",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/32nd2020Special/legislators/Senators/Images/Cannizzaro.Nicole.347.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-NV-6-upper-ocd-person-d2ca0a59-3bf1-4e1c-b4d7-9da33a4c5152",
              "builtID": "nv-upper-6",
              "externalID": "ocd-person/d2ca0a59-3bf1-4e1c-b4d7-9da33a4c5152",
              "geometry": null
            },
            "contactInfo": {
              "email": "nicole.cannizzaro@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=347",
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/6"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-6-upper-ocd-person-d2ca0a59-3bf1-4e1c-b4d7-9da33a4c5152": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/05dee0b4-bfea-4473-b253-739895818b0d",
            "firstName": "Roberta Ann",
            "lastName": "Lange",
            "fullName": "Roberta Lange",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Senators/Images/Lange.Roberta.383.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-NV-7-upper-ocd-person-05dee0b4-bfea-4473-b253-739895818b0d",
              "builtID": "nv-upper-7",
              "externalID": "ocd-person/05dee0b4-bfea-4473-b253-739895818b0d",
              "geometry": null
            },
            "contactInfo": {
              "email": "roberta.lange@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=383",
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/7"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-7-upper-ocd-person-05dee0b4-bfea-4473-b253-739895818b0d": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/0435a1f4-d8e4-43d9-9ac1-788a0aed92e4",
            "firstName": "Marilyn",
            "lastName": "Dondero Loop",
            "fullName": "Marilyn Dondero Loop",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/80th2019/legislators/Senators/Images/DonderoLoop.Marilyn.92.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-NV-8-upper-ocd-person-0435a1f4-d8e4-43d9-9ac1-788a0aed92e4",
              "builtID": "nv-upper-8",
              "externalID": "ocd-person/0435a1f4-d8e4-43d9-9ac1-788a0aed92e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "marilyn.donderoloop@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=92",
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/8"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-8-upper-ocd-person-0435a1f4-d8e4-43d9-9ac1-788a0aed92e4": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/860029b3-070b-4c23-a826-51948c5d995c",
            "firstName": "Melanie",
            "lastName": "Scheible",
            "fullName": "Melanie Scheible",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/80th2019/legislators/Senators/Images/Scheible.Melanie.358.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-NV-9-upper-ocd-person-860029b3-070b-4c23-a826-51948c5d995c",
              "builtID": "nv-upper-9",
              "externalID": "ocd-person/860029b3-070b-4c23-a826-51948c5d995c",
              "geometry": null
            },
            "contactInfo": {
              "email": "melanie.scheible@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=358",
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/9"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-9-upper-ocd-person-860029b3-070b-4c23-a826-51948c5d995c": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/bcad4dc1-c812-409b-9cc9-e1d8c91fdafa",
            "firstName": "Fabian",
            "lastName": "Donate",
            "fullName": "Fabian Doñate",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/82nd2023/legislators/Senators/Images/Doate.Fabian.350.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-NV-10-upper-ocd-person-bcad4dc1-c812-409b-9cc9-e1d8c91fdafa",
              "builtID": "nv-upper-10",
              "externalID": "ocd-person/bcad4dc1-c812-409b-9cc9-e1d8c91fdafa",
              "geometry": null
            },
            "contactInfo": {
              "email": "fabian.donate@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/10"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-10-upper-ocd-person-bcad4dc1-c812-409b-9cc9-e1d8c91fdafa": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/ab30aa3f-5248-44f6-9e23-00f85f8e4d2e",
            "firstName": "Dallas",
            "lastName": "Harris",
            "fullName": "Dallas Harris",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/80th2019/legislators/Senators/Images/Harris.Dallas.370.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-NV-11-upper-ocd-person-ab30aa3f-5248-44f6-9e23-00f85f8e4d2e",
              "builtID": "nv-upper-11",
              "externalID": "ocd-person/ab30aa3f-5248-44f6-9e23-00f85f8e4d2e",
              "geometry": null
            },
            "contactInfo": {
              "email": "dallas.harris@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=370",
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/11"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-11-upper-ocd-person-ab30aa3f-5248-44f6-9e23-00f85f8e4d2e": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/a12a3103-37b0-4ac4-81f0-100aaad494e3",
            "firstName": "Julie Holzer",
            "lastName": "Pazina",
            "fullName": "Julie Pazina",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/82nd2023/legislators/Senators/Images/Pazina.Julie.391.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-NV-12-upper-ocd-person-a12a3103-37b0-4ac4-81f0-100aaad494e3",
              "builtID": "nv-upper-12",
              "externalID": "ocd-person/a12a3103-37b0-4ac4-81f0-100aaad494e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "julie.pazina@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 231866, Las Vegas, NV 89105",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/12"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-12-upper-ocd-person-a12a3103-37b0-4ac4-81f0-100aaad494e3": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/a0553b6f-7142-4321-9e3a-c089447d3a78",
            "firstName": "Richard",
            "lastName": "Daly",
            "fullName": "Skip Daly",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/82nd2023/legislators/Senators/Images/Daly.Skip.155.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-NV-13-upper-ocd-person-a0553b6f-7142-4321-9e3a-c089447d3a78",
              "builtID": "nv-upper-13",
              "externalID": "ocd-person/a0553b6f-7142-4321-9e3a-c089447d3a78",
              "geometry": null
            },
            "contactInfo": {
              "email": "skip.daly@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/13"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-13-upper-ocd-person-a0553b6f-7142-4321-9e3a-c089447d3a78": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/94c7e249-88bb-4f83-b225-6b34f9361d0f",
            "firstName": "Ira",
            "lastName": "Hansen",
            "fullName": "Ira Hansen",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/80th2019/legislators/Senators/Images/Hansen.Ira.157.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-NV-14-upper-ocd-person-94c7e249-88bb-4f83-b225-6b34f9361d0f",
              "builtID": "nv-upper-14",
              "externalID": "ocd-person/94c7e249-88bb-4f83-b225-6b34f9361d0f",
              "geometry": null
            },
            "contactInfo": {
              "email": "ira.hansen@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=157",
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/14"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-14-upper-ocd-person-94c7e249-88bb-4f83-b225-6b34f9361d0f": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/a9c5b02b-6b3c-42ab-9085-104a6f673f00",
            "firstName": "Heidi Seevers",
            "lastName": "Gansert",
            "fullName": "Heidi Gansert",
            "gender": "Female",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/79th2017/legislators/Senators/Images/Gansert.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-NV-15-upper-ocd-person-a9c5b02b-6b3c-42ab-9085-104a6f673f00",
              "builtID": "nv-upper-15",
              "externalID": "ocd-person/a9c5b02b-6b3c-42ab-9085-104a6f673f00",
              "geometry": null
            },
            "contactInfo": {
              "email": "heidi.gansert@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=12",
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/15"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-15-upper-ocd-person-a9c5b02b-6b3c-42ab-9085-104a6f673f00": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/b82b9b71-1d83-4f91-9a7f-8adec73b023f",
            "firstName": "Lisa",
            "lastName": "Krasner",
            "fullName": "Lisa Krasner",
            "gender": "Female",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/81st2021/legislators/Assembly/Images/Krasner.Lisa.327.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-NV-16-upper-ocd-person-b82b9b71-1d83-4f91-9a7f-8adec73b023f",
              "builtID": "nv-upper-16",
              "externalID": "ocd-person/b82b9b71-1d83-4f91-9a7f-8adec73b023f",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisa.krasner@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=327",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/26",
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/16"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-16-upper-ocd-person-b82b9b71-1d83-4f91-9a7f-8adec73b023f": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/6aa57859-c195-4a44-9404-b6e2bbe4ac04",
            "firstName": "Robin L.",
            "lastName": "Titus",
            "fullName": "Robin Titus",
            "gender": "Female",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/79th2017/legislators/Assembly/Images/Titus.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-NV-17-upper-ocd-person-6aa57859-c195-4a44-9404-b6e2bbe4ac04",
              "builtID": "nv-upper-17",
              "externalID": "ocd-person/6aa57859-c195-4a44-9404-b6e2bbe4ac04",
              "geometry": null
            },
            "contactInfo": {
              "email": "robin.titus@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=281",
                "https://www.leg.state.nv.us/App/Legislator/A/Assembly/Current/38",
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/17"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-17-upper-ocd-person-6aa57859-c195-4a44-9404-b6e2bbe4ac04": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/10a65aab-d47e-46c5-84d6-b35bafce0d35",
            "firstName": "Peter J.",
            "lastName": "Goicoechea",
            "fullName": "Pete Goicoechea",
            "gender": "Male",
            "party": "Republican",
            "image": "http://leg.state.nv.us/Session/79th2017/legislators/Senators/Images/Goicoechea.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-NV-19-upper-ocd-person-10a65aab-d47e-46c5-84d6-b35bafce0d35",
              "builtID": "nv-upper-19",
              "externalID": "ocd-person/10a65aab-d47e-46c5-84d6-b35bafce0d35",
              "geometry": null
            },
            "contactInfo": {
              "email": "pete.goicoechea@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=15",
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/19"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-19-upper-ocd-person-10a65aab-d47e-46c5-84d6-b35bafce0d35": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/00ad1837-b472-4ce8-872d-e1eac1c1c6db",
            "firstName": "Jeff",
            "lastName": "Stone",
            "fullName": "Jeff Stone",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nvgopsenate.com/wp-content/uploads/2022/12/jeff-stone-1.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-NV-20-upper-ocd-person-00ad1837-b472-4ce8-872d-e1eac1c1c6db",
              "builtID": "nv-upper-20",
              "externalID": "ocd-person/00ad1837-b472-4ce8-872d-e1eac1c1c6db",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.stone@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/20"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-20-upper-ocd-person-00ad1837-b472-4ce8-872d-e1eac1c1c6db": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/75d6c9b4-d0ee-49bb-aa17-ec20bb33e9eb",
            "firstName": "James",
            "lastName": "Ohrenschall",
            "fullName": "James Ohrenschall",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://leg.state.nv.us/Session/80th2019/legislators/Senators/Images/Ohrenschall.James.67.jpg",
            "title": "NV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NV",
              "stateFull": "Nevada",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-NV-21-upper-ocd-person-75d6c9b4-d0ee-49bb-aa17-ec20bb33e9eb",
              "builtID": "nv-upper-21",
              "externalID": "ocd-person/75d6c9b4-d0ee-49bb-aa17-ec20bb33e9eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.ohrenschall@sen.state.nv.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.state.nv.us/App/Legislator/A/api/81st2021/Legislator?id=67",
                "https://www.leg.state.nv.us/App/Legislator/A/Senate/Current/21"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NV-21-upper-ocd-person-75d6c9b4-d0ee-49bb-aa17-ec20bb33e9eb": 0
        }
      }
    }
  },
  "NH": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "NH",
          "stateFull": "New Hampshire",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "NH",
            "stateFull": "New Hampshire",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-NH---",
            "builtID": "nh--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-NH---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "H001076",
          "in_office": true,
          "firstName": "Margaret",
          "lastName": "Hassan",
          "middleName": null,
          "fullName": "Margaret Wood Hassan",
          "gender": "F",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/H001076.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "NH",
            "stateFull": "New Hampshire",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-NH---H001076",
            "builtID": "nh--",
            "externalID": "H001076",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "Senatorhassan",
            "youtube": null,
            "instagram": null,
            "facebook": "SenatorHassan",
            "urls": ["https://www.hassan.senate.gov"],
            "rss_url": null
          },
          "title": "NH Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/H001076.json",
          "govtrack_id": "412680",
          "cspan_id": "67481",
          "votesmart_id": "42552",
          "icpsr_id": "41702",
          "crp_id": "N00038397",
          "google_entity_id": "/m/03c3zch",
          "state_rank": "junior",
          "lis_id": "S388",
          "suffix": null,
          "date_of_birth": "1958-02-27",
          "leadership_role": null,
          "fec_candidate_id": "S6NH00091",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "3",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 6,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 1.08,
          "votes_with_party_pct": 99.08,
          "votes_against_party_pct": 0.92,
          "ocd_id": "ocd-division/country:us/state:nh"
        },
        {
          "API_ID": "S001181",
          "in_office": true,
          "firstName": "Jeanne",
          "lastName": "Shaheen",
          "middleName": null,
          "fullName": "Jeanne Shaheen",
          "gender": "F",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/S001181.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "NH",
            "stateFull": "New Hampshire",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-NH---S001181",
            "builtID": "nh--",
            "externalID": "S001181",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.shaheen.senate.gov/contact/contact-jeanne",
            "address1": "506 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-2841",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorShaheen",
            "youtube": "senatorshaheen",
            "instagram": null,
            "facebook": "SenatorShaheen",
            "urls": ["https://www.shaheen.senate.gov"],
            "rss_url": null
          },
          "title": "NH Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/S001181.json",
          "govtrack_id": "412323",
          "cspan_id": "22850",
          "votesmart_id": "1663",
          "icpsr_id": "40906",
          "crp_id": "N00024790",
          "google_entity_id": "/m/01rrm2",
          "state_rank": "senior",
          "lis_id": "S324",
          "suffix": null,
          "date_of_birth": "1947-01-28",
          "leadership_role": null,
          "fec_candidate_id": "S0NH00219",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "15",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 26,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 4.69,
          "votes_with_party_pct": 99.05,
          "votes_against_party_pct": 0.95,
          "ocd_id": "ocd-division/country:us/state:nh"
        }
      ],
      "hash": { "SENATE-NH---H001076": 0, "SENATE-NH---S001181": 1 }
    },
    "HOUSE": {
      "02": {
        "members": [
          {
            "API_ID": "K000382",
            "in_office": true,
            "firstName": "Ann",
            "lastName": "Kuster",
            "middleName": "McLane",
            "fullName": "Ann M. Kuster",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/K000382.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-NH-02--K000382",
              "builtID": "nh--02",
              "externalID": "K000382",
              "geometry": null,
              "geoid": "3302"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2201 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5206",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAnnieKuster",
              "youtube": "RepKuster",
              "instagram": null,
              "facebook": "CongresswomanAnnieKuster",
              "urls": ["https://kuster.house.gov"],
              "rss_url": null
            },
            "title": "NH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000382.json",
            "govtrack_id": "412557",
            "cspan_id": "62650",
            "votesmart_id": "122256",
            "icpsr_id": "21340",
            "crp_id": "N00030875",
            "google_entity_id": "/m/064p47_",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1956-09-05",
            "leadership_role": null,
            "fec_candidate_id": "H0NH02181",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 59,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 5.59,
            "votes_with_party_pct": 95.94,
            "votes_against_party_pct": 2.13,
            "ocd_id": "ocd-division/country:us/state:nh/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NH-02--K000382": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "P000614",
            "in_office": true,
            "firstName": "Chris",
            "lastName": "Pappas",
            "middleName": null,
            "fullName": "Chris Pappas",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/P000614.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-NH-01--P000614",
              "builtID": "nh--01",
              "externalID": "P000614",
              "geometry": null,
              "geoid": "3301"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "452 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5456",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepChrisPappas",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://pappas.house.gov"],
              "rss_url": null
            },
            "title": "NH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000614.json",
            "govtrack_id": "412795",
            "cspan_id": null,
            "votesmart_id": "42635",
            "icpsr_id": null,
            "crp_id": "N00042161",
            "google_entity_id": "/m/0nd3t8z",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1980-06-04",
            "leadership_role": null,
            "fec_candidate_id": "H8NH01210",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 10,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.95,
            "votes_with_party_pct": 90.07,
            "votes_against_party_pct": 8,
            "ocd_id": "ocd-division/country:us/state:nh/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NH-01--P000614": 0 }
      }
    },
    "SLDL": {
      "Rockingham 30": {
        "members": [
          {
            "API_ID": "ocd-person/e60aed5e-cbb4-4b27-ab1b-2ce1dd787dac",
            "firstName": "Aboul Bashar",
            "lastName": "Khan",
            "fullName": "Aboul Khan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://files.voterly.com/psn/9de321cebe6696758a23d6f267550e1e/200x250/aboul-b-khan.jpeg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 30",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 30-lower-ocd-person-e60aed5e-cbb4-4b27-ab1b-2ce1dd787dac",
              "builtID": "nh-lower-rockingham 30",
              "externalID": "ocd-person/e60aed5e-cbb4-4b27-ab1b-2ce1dd787dac",
              "geometry": null
            },
            "contactInfo": {
              "email": "aboul.khan@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=736",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=736"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 30-lower-ocd-person-e60aed5e-cbb4-4b27-ab1b-2ce1dd787dac": 0
        }
      },
      "Strafford 7": {
        "members": [
          {
            "API_ID": "ocd-person/540cbe60-cc65-4ff6-8c78-5d54488e5ed8",
            "firstName": "Aidan K.",
            "lastName": "Ankarberg",
            "fullName": "Aidan Ankarberg",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2020-07/AidanAnkarberg.png?itok=Uh6Qy1u1",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 7",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 7-lower-ocd-person-540cbe60-cc65-4ff6-8c78-5d54488e5ed8",
              "builtID": "nh-lower-strafford 7",
              "externalID": "ocd-person/540cbe60-cc65-4ff6-8c78-5d54488e5ed8",
              "geometry": null
            },
            "contactInfo": {
              "email": "aidan.ankarberg@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9947",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9947"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 7-lower-ocd-person-540cbe60-cc65-4ff6-8c78-5d54488e5ed8": 0
        }
      },
      "Merrimack 11": {
        "members": [
          {
            "API_ID": "ocd-person/dfa84366-3323-4ae8-9a1a-cc10f852a347",
            "firstName": "Alisson",
            "lastName": "Turcotte",
            "fullName": "Alisson Turcotte",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://gencourt.state.nh.us/house/images/memberpics/409198.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 11",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 11-lower-ocd-person-dfa84366-3323-4ae8-9a1a-cc10f852a347",
              "builtID": "nh-lower-merrimack 11",
              "externalID": "ocd-person/dfa84366-3323-4ae8-9a1a-cc10f852a347",
              "geometry": null
            },
            "contactInfo": {
              "email": "alisson.turcotte@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=787",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=11083",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=11083"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 11-lower-ocd-person-dfa84366-3323-4ae8-9a1a-cc10f852a347": 0
        }
      },
      "Rockingham 33": {
        "members": [
          {
            "API_ID": "ocd-person/3ab49eaa-77a6-4e6e-85a5-24812e78ae7a",
            "firstName": "Alexis Kemmler",
            "lastName": "Simpson",
            "fullName": "Alexis Simpson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377314.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 33",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 33-lower-ocd-person-3ab49eaa-77a6-4e6e-85a5-24812e78ae7a",
              "builtID": "nh-lower-rockingham 33",
              "externalID": "ocd-person/3ab49eaa-77a6-4e6e-85a5-24812e78ae7a",
              "geometry": null
            },
            "contactInfo": {
              "email": "ahksimpson@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377314",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=904",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=904"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 33-lower-ocd-person-3ab49eaa-77a6-4e6e-85a5-24812e78ae7a": 0
        }
      },
      "Hillsborough 7": {
        "members": [
          {
            "API_ID": "ocd-person/36eee284-6fc9-4b03-9b5c-012b5fdd6995",
            "firstName": "Alicia",
            "lastName": "Gregg",
            "fullName": "Alicia Gregg",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-07/Alicia-Gregg.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 7",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 7-lower-ocd-person-36eee284-6fc9-4b03-9b5c-012b5fdd6995",
              "builtID": "nh-lower-hillsborough 7",
              "externalID": "ocd-person/36eee284-6fc9-4b03-9b5c-012b5fdd6995",
              "geometry": null
            },
            "contactInfo": {
              "email": "alicia.gregg@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10781"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/36fb226e-9ed4-483f-a254-141baf18d295",
            "firstName": "Catherine",
            "lastName": "Sofikitis",
            "fullName": "Catherine Sofikitis",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408546.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 7",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 7-lower-ocd-person-36fb226e-9ed4-483f-a254-141baf18d295",
              "builtID": "nh-lower-hillsborough 7",
              "externalID": "ocd-person/36fb226e-9ed4-483f-a254-141baf18d295",
              "geometry": null
            },
            "contactInfo": {
              "email": "catherine4ward7@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8546",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=408546"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/9e0a9394-7bcc-451a-9ae1-439248598eae",
            "firstName": "Louis C.",
            "lastName": "Juris",
            "fullName": "Lou Juris",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409114.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 7",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 7-lower-ocd-person-9e0a9394-7bcc-451a-9ae1-439248598eae",
              "builtID": "nh-lower-hillsborough 7",
              "externalID": "ocd-person/9e0a9394-7bcc-451a-9ae1-439248598eae",
              "geometry": null
            },
            "contactInfo": {
              "email": "louis.juris@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10782"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 7-lower-ocd-person-36eee284-6fc9-4b03-9b5c-012b5fdd6995": 0,
          "SLDL-NH-Hillsborough 7-lower-ocd-person-36fb226e-9ed4-483f-a254-141baf18d295": 1,
          "SLDL-NH-Hillsborough 7-lower-ocd-person-9e0a9394-7bcc-451a-9ae1-439248598eae": 2
        }
      },
      "Hillsborough 38": {
        "members": [
          {
            "API_ID": "ocd-person/50bcd6fc-7d60-4595-811c-213e18faf326",
            "firstName": "Alicia",
            "lastName": "Lekas",
            "fullName": "Alicia Lekas",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408880.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 38",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 38-lower-ocd-person-50bcd6fc-7d60-4595-811c-213e18faf326",
              "builtID": "nh-lower-hillsborough 38",
              "externalID": "ocd-person/50bcd6fc-7d60-4595-811c-213e18faf326",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.alicia.lekas@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9236",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9236"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/97ccf8c1-af1c-4a3c-b267-683d0446b790",
            "firstName": "Tony",
            "lastName": "Lekas",
            "fullName": "Tony Lekas",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408881.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 38",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 38-lower-ocd-person-97ccf8c1-af1c-4a3c-b267-683d0446b790",
              "builtID": "nh-lower-hillsborough 38",
              "externalID": "ocd-person/97ccf8c1-af1c-4a3c-b267-683d0446b790",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.tony.lekas@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9239",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9239"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 38-lower-ocd-person-50bcd6fc-7d60-4595-811c-213e18faf326": 0,
          "SLDL-NH-Hillsborough 38-lower-ocd-person-97ccf8c1-af1c-4a3c-b267-683d0446b790": 1
        }
      },
      "Hillsborough 20": {
        "members": [
          {
            "API_ID": "ocd-person/f809a410-5a03-4ebc-a0ab-32507705c479",
            "firstName": "Alissandra Rodriguez",
            "lastName": "Murray",
            "fullName": "Alissandra Murray",
            "gender": "X",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-08/alissandra-murray.jpg?itok=4aFp3Ai9",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 20",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 20-lower-ocd-person-f809a410-5a03-4ebc-a0ab-32507705c479",
              "builtID": "nh-lower-hillsborough 20",
              "externalID": "ocd-person/f809a410-5a03-4ebc-a0ab-32507705c479",
              "geometry": null
            },
            "contactInfo": {
              "email": "alissandra.murray@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10799"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b60bf9cd-2098-46af-b990-bd45ff384e54",
            "firstName": "Candace",
            "lastName": "Gibbons",
            "fullName": "Candace Gibbons",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/CandaceMoulton2.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 20",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 20-lower-ocd-person-b60bf9cd-2098-46af-b990-bd45ff384e54",
              "builtID": "nh-lower-hillsborough 20",
              "externalID": "ocd-person/b60bf9cd-2098-46af-b990-bd45ff384e54",
              "geometry": null
            },
            "contactInfo": {
              "email": "candace.gibbons@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9135"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 20-lower-ocd-person-f809a410-5a03-4ebc-a0ab-32507705c479": 0,
          "SLDL-NH-Hillsborough 20-lower-ocd-person-b60bf9cd-2098-46af-b990-bd45ff384e54": 1
        }
      },
      "Strafford 20": {
        "members": [
          {
            "API_ID": "ocd-person/69b94b6a-d5d5-430b-a4b9-c62c8414cb75",
            "firstName": "Allan",
            "lastName": "Howland",
            "fullName": "Allan Howland",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ci.durham.nh.us/sites/default/files/styles/full_node_primary/public/imageattachments/administration/page/19301/allan_howland_2_-_town_council.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 20",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 20-lower-ocd-person-69b94b6a-d5d5-430b-a4b9-c62c8414cb75",
              "builtID": "nh-lower-strafford 20",
              "externalID": "ocd-person/69b94b6a-d5d5-430b-a4b9-c62c8414cb75",
              "geometry": null
            },
            "contactInfo": {
              "email": "allan.howland@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10903"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 20-lower-ocd-person-69b94b6a-d5d5-430b-a4b9-c62c8414cb75": 0
        }
      },
      "Rockingham 12": {
        "members": [
          {
            "API_ID": "ocd-person/0bc83772-75ee-453c-868c-8d2a034788b1",
            "firstName": "Allison",
            "lastName": "Knab",
            "fullName": "Allison Knab",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409118.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 12-lower-ocd-person-0bc83772-75ee-453c-868c-8d2a034788b1",
              "builtID": "nh-lower-rockingham 12",
              "externalID": "ocd-person/0bc83772-75ee-453c-868c-8d2a034788b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "allison.knab@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10856"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/82019391-800c-469e-b17b-fbbf700b6555",
            "firstName": "Zoe",
            "lastName": "Manos",
            "fullName": "Zoe Manos",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gannett-cdn.com/presto/2022/10/20/NPOH/d99fd355-15a2-43ba-b2ac-6f0353ba64e3-Manos.jpeg?width=300&height=317&fit=crop&format=pjpg&auto=webp",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 12-lower-ocd-person-82019391-800c-469e-b17b-fbbf700b6555",
              "builtID": "nh-lower-rockingham 12",
              "externalID": "ocd-person/82019391-800c-469e-b17b-fbbf700b6555",
              "geometry": null
            },
            "contactInfo": {
              "email": "zoe.manos@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10857"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 12-lower-ocd-person-0bc83772-75ee-453c-868c-8d2a034788b1": 0,
          "SLDL-NH-Rockingham 12-lower-ocd-person-82019391-800c-469e-b17b-fbbf700b6555": 1
        }
      },
      "Hillsborough 9": {
        "members": [
          {
            "API_ID": "ocd-person/4c979ca8-85c0-4124-ac2d-c44f3bcb95ff",
            "firstName": "Allison",
            "lastName": "Nutting-Wong",
            "fullName": "Allison Nutting-Wong",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/406681.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 9",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 9-lower-ocd-person-4c979ca8-85c0-4124-ac2d-c44f3bcb95ff",
              "builtID": "nh-lower-hillsborough 9",
              "externalID": "ocd-person/4c979ca8-85c0-4124-ac2d-c44f3bcb95ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "allison.nuttingwong@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=6681",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=406681"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/09941292-6228-45f4-b75f-f8a47a381659",
            "firstName": "Michael P.",
            "lastName": "Pedersen",
            "fullName": "Michael Pedersen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://patch.com/img/cdn/users/701506/2012/10/T800x600/4860fa91b492827afa1a9a1d36512ef2.jpg?width=700",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 9",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 9-lower-ocd-person-09941292-6228-45f4-b75f-f8a47a381659",
              "builtID": "nh-lower-hillsborough 9",
              "externalID": "ocd-person/09941292-6228-45f4-b75f-f8a47a381659",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.pedersen@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=7315",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=7315"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/967b5d85-d526-49ad-941a-1401fa855998",
            "firstName": "William",
            "lastName": "Dolan",
            "fullName": "Bill Dolan",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://dolanforrep.com/wp-content/uploads/2022/10/Professional-Photo-SMALL.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 9",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 9-lower-ocd-person-967b5d85-d526-49ad-941a-1401fa855998",
              "builtID": "nh-lower-hillsborough 9",
              "externalID": "ocd-person/967b5d85-d526-49ad-941a-1401fa855998",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.dolan@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10784"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 9-lower-ocd-person-4c979ca8-85c0-4124-ac2d-c44f3bcb95ff": 0,
          "SLDL-NH-Hillsborough 9-lower-ocd-person-09941292-6228-45f4-b75f-f8a47a381659": 1,
          "SLDL-NH-Hillsborough 9-lower-ocd-person-967b5d85-d526-49ad-941a-1401fa855998": 2
        }
      },
      "Merrimack 26": {
        "members": [
          {
            "API_ID": "ocd-person/e3a1815a-a84c-4bd1-9e20-b73b07d2576f",
            "firstName": "Alvin",
            "lastName": "See",
            "fullName": "Alvin See",
            "gender": "Male",
            "party": "Republican",
            "image": "https://andoverbeacon.com/wp-content/uploads/2022/10/010-Town-News-News-Candidate-State-Rep-Merr-26-Rep-See-Photo-1-e1666301578915-577x1024.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 26",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 26-lower-ocd-person-e3a1815a-a84c-4bd1-9e20-b73b07d2576f",
              "builtID": "nh-lower-merrimack 26",
              "externalID": "ocd-person/e3a1815a-a84c-4bd1-9e20-b73b07d2576f",
              "geometry": null
            },
            "contactInfo": {
              "email": "alvin.see@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10682"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 26-lower-ocd-person-e3a1815a-a84c-4bd1-9e20-b73b07d2576f": 0
        }
      },
      "Hillsborough 25": {
        "members": [
          {
            "API_ID": "ocd-person/7ad034dd-b6f2-4314-94d9-cffce7144e5d",
            "firstName": "Amanda",
            "lastName": "Bouldin",
            "fullName": "Amanda Bouldin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377283.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 25",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 25-lower-ocd-person-7ad034dd-b6f2-4314-94d9-cffce7144e5d",
              "builtID": "nh-lower-hillsborough 25",
              "externalID": "ocd-person/7ad034dd-b6f2-4314-94d9-cffce7144e5d",
              "geometry": null
            },
            "contactInfo": {
              "email": "amanda.bouldin@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=838",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377283"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4f2dee8b-bbd5-41d9-8bd4-bf2f60556ed7",
            "firstName": "Kathy Schofield",
            "lastName": "Staub",
            "fullName": "Kathy Staub",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/KathyStaub.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 25",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 25-lower-ocd-person-4f2dee8b-bbd5-41d9-8bd4-bf2f60556ed7",
              "builtID": "nh-lower-hillsborough 25",
              "externalID": "ocd-person/4f2dee8b-bbd5-41d9-8bd4-bf2f60556ed7",
              "geometry": null
            },
            "contactInfo": {
              "email": "kathy.staub@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10801"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 25-lower-ocd-person-7ad034dd-b6f2-4314-94d9-cffce7144e5d": 0,
          "SLDL-NH-Hillsborough 25-lower-ocd-person-4f2dee8b-bbd5-41d9-8bd4-bf2f60556ed7": 1
        }
      },
      "Cheshire 15": {
        "members": [
          {
            "API_ID": "ocd-person/79216b9e-557f-42be-bd35-02f8b82465e3",
            "firstName": "Amanda Elizabeth",
            "lastName": "Toll",
            "fullName": "Amanda Toll",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://bloximages.chicago2.vip.townnews.com/sentinelsource.com/content/tncms/assets/v3/editorial/3/1e/31ebe9c5-1aba-543d-ae1e-0a2763cbdd59/5f89c562cf206.image.jpg?resize=267%2C348",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 15",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 15-lower-ocd-person-79216b9e-557f-42be-bd35-02f8b82465e3",
              "builtID": "nh-lower-cheshire 15",
              "externalID": "ocd-person/79216b9e-557f-42be-bd35-02f8b82465e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "electamandanh@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9879",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9879"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/42f1befd-176c-45d5-8ea8-1efe37124fdd",
            "firstName": "Renee",
            "lastName": "Monteil",
            "fullName": "Renée Monteil",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-08/renee-monteil.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 15",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 15-lower-ocd-person-42f1befd-176c-45d5-8ea8-1efe37124fdd",
              "builtID": "nh-lower-cheshire 15",
              "externalID": "ocd-person/42f1befd-176c-45d5-8ea8-1efe37124fdd",
              "geometry": null
            },
            "contactInfo": {
              "email": "renee.monteil@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10754"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 15-lower-ocd-person-79216b9e-557f-42be-bd35-02f8b82465e3": 0,
          "SLDL-NH-Cheshire 15-lower-ocd-person-42f1befd-176c-45d5-8ea8-1efe37124fdd": 1
        }
      },
      "Hillsborough 41": {
        "members": [
          {
            "API_ID": "ocd-person/5b7f0213-5ecb-4d91-84c8-29c0abedfe7f",
            "firstName": "Amy L.",
            "lastName": "Bradley",
            "fullName": "Amy Bradley",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Amy_Bradley.PNG",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 41",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 41-lower-ocd-person-5b7f0213-5ecb-4d91-84c8-29c0abedfe7f",
              "builtID": "nh-lower-hillsborough 41",
              "externalID": "ocd-person/5b7f0213-5ecb-4d91-84c8-29c0abedfe7f",
              "geometry": null
            },
            "contactInfo": {
              "email": "amy.bradley@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9918",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9918"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8e044671-4143-41a6-a341-6c8514bb2d66",
            "firstName": "Jacqueline",
            "lastName": "Chretien",
            "fullName": "Jackie Chretien",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Jacqueline_Chretien_official_square.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 41",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 41-lower-ocd-person-8e044671-4143-41a6-a341-6c8514bb2d66",
              "builtID": "nh-lower-hillsborough 41",
              "externalID": "ocd-person/8e044671-4143-41a6-a341-6c8514bb2d66",
              "geometry": null
            },
            "contactInfo": {
              "email": "jackie.chretien@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9133",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9133"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/03bba97f-9b7c-4c33-adb7-e5202a6bd040",
            "firstName": "Mary",
            "lastName": "Heath",
            "fullName": "Mary Heath",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377199.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 41",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 41-lower-ocd-person-03bba97f-9b7c-4c33-adb7-e5202a6bd040",
              "builtID": "nh-lower-hillsborough 41",
              "externalID": "ocd-person/03bba97f-9b7c-4c33-adb7-e5202a6bd040",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.heath@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=803",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377199"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 41-lower-ocd-person-5b7f0213-5ecb-4d91-84c8-29c0abedfe7f": 0,
          "SLDL-NH-Hillsborough 41-lower-ocd-person-8e044671-4143-41a6-a341-6c8514bb2d66": 1,
          "SLDL-NH-Hillsborough 41-lower-ocd-person-03bba97f-9b7c-4c33-adb7-e5202a6bd040": 2
        }
      },
      "Hillsborough 13": {
        "members": [
          {
            "API_ID": "ocd-person/da5fb901-d16d-430f-b5cb-7ab556f8d4ee",
            "firstName": "Andrew J.",
            "lastName": "Prout",
            "fullName": "Andrew Prout",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408359.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 13-lower-ocd-person-da5fb901-d16d-430f-b5cb-7ab556f8d4ee",
              "builtID": "nh-lower-hillsborough 13",
              "externalID": "ocd-person/da5fb901-d16d-430f-b5cb-7ab556f8d4ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "andrew.prout@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8359",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=408359"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b8aa741e-30b1-4d45-8f6a-7241f0d0eed2",
            "firstName": "Andrew",
            "lastName": "Renzullo",
            "fullName": "Andy Renzullo",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376618.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 13-lower-ocd-person-b8aa741e-30b1-4d45-8f6a-7241f0d0eed2",
              "builtID": "nh-lower-hillsborough 13",
              "externalID": "ocd-person/b8aa741e-30b1-4d45-8f6a-7241f0d0eed2",
              "geometry": null
            },
            "contactInfo": {
              "email": "andrew.renzullo@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=513",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376618"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/db105051-1a17-4bff-a3a3-46332533ac8a",
            "firstName": "Cathy",
            "lastName": "Kenny",
            "fullName": "Cathy Kenny",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409116.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 13-lower-ocd-person-db105051-1a17-4bff-a3a3-46332533ac8a",
              "builtID": "nh-lower-hillsborough 13",
              "externalID": "ocd-person/db105051-1a17-4bff-a3a3-46332533ac8a",
              "geometry": null
            },
            "contactInfo": {
              "email": "cathy.kenny@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10640"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/31f696d4-f806-4eb3-a8ce-5b1d2a2eba93",
            "firstName": "Jordan G.",
            "lastName": "Ulery",
            "fullName": "Jordan Ulery",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376635.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 13-lower-ocd-person-31f696d4-f806-4eb3-a8ce-5b1d2a2eba93",
              "builtID": "nh-lower-hillsborough 13",
              "externalID": "ocd-person/31f696d4-f806-4eb3-a8ce-5b1d2a2eba93",
              "geometry": null
            },
            "contactInfo": {
              "email": "repulery@comcast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=520",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376635"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e7f37a7a-0f05-4fd3-944d-be2c41cc200b",
            "firstName": "Robert",
            "lastName": "Wherry",
            "fullName": "Robert Wherry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409187.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 13-lower-ocd-person-e7f37a7a-0f05-4fd3-944d-be2c41cc200b",
              "builtID": "nh-lower-hillsborough 13",
              "externalID": "ocd-person/e7f37a7a-0f05-4fd3-944d-be2c41cc200b",
              "geometry": null
            },
            "contactInfo": {
              "email": "robert.wherry@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10641"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/31386c5e-8c14-4d35-8d99-11adab1b353e",
            "firstName": "Stephen J.",
            "lastName": "Kennedy",
            "fullName": "Stephen Kennedy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/StephenKennedy.jfif",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 13-lower-ocd-person-31386c5e-8c14-4d35-8d99-11adab1b353e",
              "builtID": "nh-lower-hillsborough 13",
              "externalID": "ocd-person/31386c5e-8c14-4d35-8d99-11adab1b353e",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephen.kennedy@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10639"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 13-lower-ocd-person-da5fb901-d16d-430f-b5cb-7ab556f8d4ee": 0,
          "SLDL-NH-Hillsborough 13-lower-ocd-person-b8aa741e-30b1-4d45-8f6a-7241f0d0eed2": 1,
          "SLDL-NH-Hillsborough 13-lower-ocd-person-db105051-1a17-4bff-a3a3-46332533ac8a": 2,
          "SLDL-NH-Hillsborough 13-lower-ocd-person-31f696d4-f806-4eb3-a8ce-5b1d2a2eba93": 3,
          "SLDL-NH-Hillsborough 13-lower-ocd-person-e7f37a7a-0f05-4fd3-944d-be2c41cc200b": 4,
          "SLDL-NH-Hillsborough 13-lower-ocd-person-31386c5e-8c14-4d35-8d99-11adab1b353e": 5
        }
      },
      "Merrimack 9": {
        "members": [
          {
            "API_ID": "ocd-person/9d75b7df-2487-4a44-a774-738f89f1dc5d",
            "firstName": "Angela",
            "lastName": "Brennan",
            "fullName": "Angela Brennan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://abrennanforbow.files.wordpress.com/2022/01/img_0438_facetune_16-01-2022-15-17-48.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 9",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 9-lower-ocd-person-9d75b7df-2487-4a44-a774-738f89f1dc5d",
              "builtID": "nh-lower-merrimack 9",
              "externalID": "ocd-person/9d75b7df-2487-4a44-a774-738f89f1dc5d",
              "geometry": null
            },
            "contactInfo": {
              "email": "angela.brennan@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10835"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c3c9bb99-9512-4bec-be04-d8b29ea96f3e",
            "firstName": "David",
            "lastName": "Luneau",
            "fullName": "David Luneau",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377307.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 9",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 9-lower-ocd-person-c3c9bb99-9512-4bec-be04-d8b29ea96f3e",
              "builtID": "nh-lower-merrimack 9",
              "externalID": "ocd-person/c3c9bb99-9512-4bec-be04-d8b29ea96f3e",
              "geometry": null
            },
            "contactInfo": {
              "email": "dluneaunh@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=880",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377307"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8c5f7a02-e92b-4f64-b698-c178b8fd23bb",
            "firstName": "Mel",
            "lastName": "Myler",
            "fullName": "Mel Myler",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377153.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 9",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 9-lower-ocd-person-8c5f7a02-e92b-4f64-b698-c178b8fd23bb",
              "builtID": "nh-lower-merrimack 9",
              "externalID": "ocd-person/8c5f7a02-e92b-4f64-b698-c178b8fd23bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "mel.myler@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=758",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377153"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c85e3c8a-f2b9-40d5-a830-7c59a1328bf4",
            "firstName": "Muriel",
            "lastName": "Hall",
            "fullName": "Muriel Hall",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409056.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 9",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 9-lower-ocd-person-c85e3c8a-f2b9-40d5-a830-7c59a1328bf4",
              "builtID": "nh-lower-merrimack 9",
              "externalID": "ocd-person/c85e3c8a-f2b9-40d5-a830-7c59a1328bf4",
              "geometry": null
            },
            "contactInfo": {
              "email": "muriel.hall@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10352",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10352"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 9-lower-ocd-person-9d75b7df-2487-4a44-a774-738f89f1dc5d": 0,
          "SLDL-NH-Merrimack 9-lower-ocd-person-c3c9bb99-9512-4bec-be04-d8b29ea96f3e": 1,
          "SLDL-NH-Merrimack 9-lower-ocd-person-8c5f7a02-e92b-4f64-b698-c178b8fd23bb": 2,
          "SLDL-NH-Merrimack 9-lower-ocd-person-c85e3c8a-f2b9-40d5-a830-7c59a1328bf4": 3
        }
      },
      "Carroll 2": {
        "members": [
          {
            "API_ID": "ocd-person/a028512b-5d61-4d23-9e28-dec7038577d6",
            "firstName": "Anita Lang",
            "lastName": "Burroughs",
            "fullName": "Anita Burroughs",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408822.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Carroll 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Carroll 2-lower-ocd-person-a028512b-5d61-4d23-9e28-dec7038577d6",
              "builtID": "nh-lower-carroll 2",
              "externalID": "ocd-person/a028512b-5d61-4d23-9e28-dec7038577d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "anitadburr@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9044",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408822"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/28ef2003-b65a-4c46-92f1-b777498c5a00",
            "firstName": "Chris",
            "lastName": "McAleer",
            "fullName": "Chris McAleer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408973.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Carroll 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Carroll 2-lower-ocd-person-28ef2003-b65a-4c46-92f1-b777498c5a00",
              "builtID": "nh-lower-carroll 2",
              "externalID": "ocd-person/28ef2003-b65a-4c46-92f1-b777498c5a00",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.mcaleer@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9871",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9871"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Carroll 2-lower-ocd-person-a028512b-5d61-4d23-9e28-dec7038577d6": 0,
          "SLDL-NH-Carroll 2-lower-ocd-person-28ef2003-b65a-4c46-92f1-b777498c5a00": 1
        }
      },
      "Rockingham 18": {
        "members": [
          {
            "API_ID": "ocd-person/a0e1e398-f339-4c5b-9337-7a99f9162327",
            "firstName": "Arlene",
            "lastName": "Quaratiello",
            "fullName": "Arlene Quaratiello",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409157.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 18",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 18-lower-ocd-person-a0e1e398-f339-4c5b-9337-7a99f9162327",
              "builtID": "nh-lower-rockingham 18",
              "externalID": "ocd-person/a0e1e398-f339-4c5b-9337-7a99f9162327",
              "geometry": null
            },
            "contactInfo": {
              "email": "arlene.quaratiello@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10699"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/7ef7472f-0bda-4708-9239-76adeca1e87e",
            "firstName": "Debra L.",
            "lastName": "DeSimone",
            "fullName": "Debra DeSimone",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376807.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 18",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 18-lower-ocd-person-7ef7472f-0bda-4708-9239-76adeca1e87e",
              "builtID": "nh-lower-rockingham 18",
              "externalID": "ocd-person/7ef7472f-0bda-4708-9239-76adeca1e87e",
              "geometry": null
            },
            "contactInfo": {
              "email": "debra.desimone@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=570",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376807"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 18-lower-ocd-person-a0e1e398-f339-4c5b-9337-7a99f9162327": 0,
          "SLDL-NH-Rockingham 18-lower-ocd-person-7ef7472f-0bda-4708-9239-76adeca1e87e": 1
        }
      },
      "Coos 2": {
        "members": [
          {
            "API_ID": "ocd-person/4f07f5aa-c410-481b-b570-36ae37d64e24",
            "firstName": "Arnold G.",
            "lastName": "Davis",
            "fullName": "Arnie Davis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408983.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Coos 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Coos 2-lower-ocd-person-4f07f5aa-c410-481b-b570-36ae37d64e24",
              "builtID": "nh-lower-coos 2",
              "externalID": "ocd-person/4f07f5aa-c410-481b-b570-36ae37d64e24",
              "geometry": null
            },
            "contactInfo": {
              "email": "arnidavis@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9882",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9882"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Coos 2-lower-ocd-person-4f07f5aa-c410-481b-b570-36ae37d64e24": 0
        }
      },
      "Belknap 7": {
        "members": [
          {
            "API_ID": "ocd-person/3e0ee111-2332-4742-b073-a23cc37a8b51",
            "firstName": "Barbara",
            "lastName": "Comtois",
            "fullName": "Barbara Comtois",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408274.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 7",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 7-lower-ocd-person-3e0ee111-2332-4742-b073-a23cc37a8b51",
              "builtID": "nh-lower-belknap 7",
              "externalID": "ocd-person/3e0ee111-2332-4742-b073-a23cc37a8b51",
              "geometry": null
            },
            "contactInfo": {
              "email": "barbara.comtois@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8274",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408274"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8488ba3a-e608-4f0d-9d7b-7e6e13244917",
            "firstName": "Paul",
            "lastName": "Terry",
            "fullName": "Paul Terry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://paulterryfornh.com/wp-content/uploads/2020/06/Paul8.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 7",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 7-lower-ocd-person-8488ba3a-e608-4f0d-9d7b-7e6e13244917",
              "builtID": "nh-lower-belknap 7",
              "externalID": "ocd-person/8488ba3a-e608-4f0d-9d7b-7e6e13244917",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.terry@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9866",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9866"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b4735fd9-309f-40b0-a554-c646dde34fc1",
            "firstName": "Peter",
            "lastName": "Varney",
            "fullName": "Peter Varney",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377271.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 7",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 7-lower-ocd-person-b4735fd9-309f-40b0-a554-c646dde34fc1",
              "builtID": "nh-lower-belknap 7",
              "externalID": "ocd-person/b4735fd9-309f-40b0-a554-c646dde34fc1",
              "geometry": null
            },
            "contactInfo": {
              "email": "pvarney@atsnh.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=814",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377271"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Belknap 7-lower-ocd-person-3e0ee111-2332-4742-b073-a23cc37a8b51": 0,
          "SLDL-NH-Belknap 7-lower-ocd-person-8488ba3a-e608-4f0d-9d7b-7e6e13244917": 1,
          "SLDL-NH-Belknap 7-lower-ocd-person-b4735fd9-309f-40b0-a554-c646dde34fc1": 2
        }
      },
      "Cheshire 10": {
        "members": [
          {
            "API_ID": "ocd-person/a8bcce41-eef2-4b18-b99f-8bb34c7f94ea",
            "firstName": "Francis Barrett",
            "lastName": "Faulkner",
            "fullName": "Barry Faulkner",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/407628.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 10-lower-ocd-person-a8bcce41-eef2-4b18-b99f-8bb34c7f94ea",
              "builtID": "nh-lower-cheshire 10",
              "externalID": "ocd-person/a8bcce41-eef2-4b18-b99f-8bb34c7f94ea",
              "geometry": null
            },
            "contactInfo": {
              "email": "fbfaulkner@outlook.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=7628",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=407628"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/159d4b0d-ce1f-4605-bed7-9d9f98d95336",
            "firstName": "Bruce L.",
            "lastName": "Tatro",
            "fullName": "Bruce Tatro",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377039.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 10-lower-ocd-person-159d4b0d-ce1f-4605-bed7-9d9f98d95336",
              "builtID": "nh-lower-cheshire 10",
              "externalID": "ocd-person/159d4b0d-ce1f-4605-bed7-9d9f98d95336",
              "geometry": null
            },
            "contactInfo": {
              "email": "btatro1@yahoo.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=661"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 10-lower-ocd-person-a8bcce41-eef2-4b18-b99f-8bb34c7f94ea": 0,
          "SLDL-NH-Cheshire 10-lower-ocd-person-159d4b0d-ce1f-4605-bed7-9d9f98d95336": 1
        }
      },
      "Hillsborough 35": {
        "members": [
          {
            "API_ID": "ocd-person/e7e53690-6b15-465f-b969-f85c57005854",
            "firstName": "Benjamin",
            "lastName": "Ming",
            "fullName": "Ben Ming",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://ben-ming.com/images/BioPhoto.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 35",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 35-lower-ocd-person-e7e53690-6b15-465f-b969-f85c57005854",
              "builtID": "nh-lower-hillsborough 35",
              "externalID": "ocd-person/e7e53690-6b15-465f-b969-f85c57005854",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.ming@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10288"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4c8bec8a-e86d-4316-8b2e-2f8c4157cae5",
            "firstName": "Kathy",
            "lastName": "McGhee",
            "fullName": "Kat McGhee",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408947.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 35",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 35-lower-ocd-person-4c8bec8a-e86d-4316-8b2e-2f8c4157cae5",
              "builtID": "nh-lower-hillsborough 35",
              "externalID": "ocd-person/4c8bec8a-e86d-4316-8b2e-2f8c4157cae5",
              "geometry": null
            },
            "contactInfo": {
              "email": "kat.mcghee@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=6966",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=6966"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 35-lower-ocd-person-e7e53690-6b15-465f-b969-f85c57005854": 0,
          "SLDL-NH-Hillsborough 35-lower-ocd-person-4c8bec8a-e86d-4316-8b2e-2f8c4157cae5": 1
        }
      },
      "Hillsborough 39": {
        "members": [
          {
            "API_ID": "ocd-person/018987e5-d731-41ed-8cce-b3266df24b64",
            "firstName": "Benjamin C.",
            "lastName": "Baroody",
            "fullName": "Benjamin Baroody",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/374801.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 39",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 39-lower-ocd-person-018987e5-d731-41ed-8cce-b3266df24b64",
              "builtID": "nh-lower-hillsborough 39",
              "externalID": "ocd-person/018987e5-d731-41ed-8cce-b3266df24b64",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.baroody@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=977",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=374801"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 39-lower-ocd-person-018987e5-d731-41ed-8cce-b3266df24b64": 0
        }
      },
      "Merrimack 17": {
        "members": [
          {
            "API_ID": "ocd-person/49453d65-a175-4c22-bc1f-878d02ea3d45",
            "firstName": "Beth",
            "lastName": "Richards",
            "fullName": "Beth Richards",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408564.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 17",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 17-lower-ocd-person-49453d65-a175-4c22-bc1f-878d02ea3d45",
              "builtID": "nh-lower-merrimack 17",
              "externalID": "ocd-person/49453d65-a175-4c22-bc1f-878d02ea3d45",
              "geometry": null
            },
            "contactInfo": {
              "email": "bethrichardsforward3@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8564",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=408564"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 17-lower-ocd-person-49453d65-a175-4c22-bc1f-878d02ea3d45": 0
        }
      },
      "Hillsborough 12": {
        "members": [
          {
            "API_ID": "ocd-person/6f592f37-cac8-4bf0-b12c-3a86f9b444dc",
            "firstName": "Bill",
            "lastName": "Boyd",
            "fullName": "Bill Boyd",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409055.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 12-lower-ocd-person-6f592f37-cac8-4bf0-b12c-3a86f9b444dc",
              "builtID": "nh-lower-hillsborough 12",
              "externalID": "ocd-person/6f592f37-cac8-4bf0-b12c-3a86f9b444dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.boyd@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10347",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10347"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/84f79859-fef0-441d-80bb-5edeafc70e78",
            "firstName": "Jeanine M.",
            "lastName": "Notter",
            "fullName": "Jeanine Notter",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376991.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 12-lower-ocd-person-84f79859-fef0-441d-80bb-5edeafc70e78",
              "builtID": "nh-lower-hillsborough 12",
              "externalID": "ocd-person/84f79859-fef0-441d-80bb-5edeafc70e78",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeanine.notter@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=639",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376991"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4a80db99-34b2-4d33-995b-41d889592bf3",
            "firstName": "Maureen",
            "lastName": "Mooney",
            "fullName": "Maureen Mooney",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376501.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 12-lower-ocd-person-4a80db99-34b2-4d33-995b-41d889592bf3",
              "builtID": "nh-lower-hillsborough 12",
              "externalID": "ocd-person/4a80db99-34b2-4d33-995b-41d889592bf3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.maureen.mooney@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=5772",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=5772"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0ac05fa7-143f-4ae9-8ac8-99de42d21779",
            "firstName": "Nancy Olson",
            "lastName": "Murphy",
            "fullName": "Nancy Murphy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408861.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 12-lower-ocd-person-0ac05fa7-143f-4ae9-8ac8-99de42d21779",
              "builtID": "nh-lower-hillsborough 12",
              "externalID": "ocd-person/0ac05fa7-143f-4ae9-8ac8-99de42d21779",
              "geometry": null
            },
            "contactInfo": {
              "email": "nancy.murphy@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9089",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9089"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c7c69eff-d232-40f0-b41b-ef9edd276edf",
            "firstName": "Robert",
            "lastName": "Healey",
            "fullName": "Bob Healey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2020-08/BobHealey.jpg?itok=q-046TNm",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 12-lower-ocd-person-c7c69eff-d232-40f0-b41b-ef9edd276edf",
              "builtID": "nh-lower-hillsborough 12",
              "externalID": "ocd-person/c7c69eff-d232-40f0-b41b-ef9edd276edf",
              "geometry": null
            },
            "contactInfo": {
              "email": "rvhealey@aol.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9901",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9901"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1a5923c6-3e1f-4ebe-8739-17c9e9642403",
            "firstName": "Rosemarie",
            "lastName": "Rung",
            "fullName": "Rosemarie Rung",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.insidesources.com/wp-content/uploads/2020/06/rung-300x300.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 12-lower-ocd-person-1a5923c6-3e1f-4ebe-8739-17c9e9642403",
              "builtID": "nh-lower-hillsborough 12",
              "externalID": "ocd-person/1a5923c6-3e1f-4ebe-8739-17c9e9642403",
              "geometry": null
            },
            "contactInfo": {
              "email": "rosemarie.rung@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9090",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9090"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/18d80f49-9f78-441e-bf28-1ed0929b5fd3",
            "firstName": "Tim",
            "lastName": "McGough",
            "fullName": "Tim McGough",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376150.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 12-lower-ocd-person-18d80f49-9f78-441e-bf28-1ed0929b5fd3",
              "builtID": "nh-lower-hillsborough 12",
              "externalID": "ocd-person/18d80f49-9f78-441e-bf28-1ed0929b5fd3",
              "geometry": null
            },
            "contactInfo": {
              "email": "tim.mcgough@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=5686"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/2375b05c-cf9a-4f1b-a4b0-194fac6d86bf",
            "firstName": "Wendy",
            "lastName": "Thomas",
            "fullName": "Wendy Thomas",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://nhwn.files.wordpress.com/2010/05/wendy-shot.jpg?w=676",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 12-lower-ocd-person-2375b05c-cf9a-4f1b-a4b0-194fac6d86bf",
              "builtID": "nh-lower-hillsborough 12",
              "externalID": "ocd-person/2375b05c-cf9a-4f1b-a4b0-194fac6d86bf",
              "geometry": null
            },
            "contactInfo": {
              "email": "wendy.thomas@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9093",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9093"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 12-lower-ocd-person-6f592f37-cac8-4bf0-b12c-3a86f9b444dc": 0,
          "SLDL-NH-Hillsborough 12-lower-ocd-person-84f79859-fef0-441d-80bb-5edeafc70e78": 1,
          "SLDL-NH-Hillsborough 12-lower-ocd-person-4a80db99-34b2-4d33-995b-41d889592bf3": 2,
          "SLDL-NH-Hillsborough 12-lower-ocd-person-0ac05fa7-143f-4ae9-8ac8-99de42d21779": 3,
          "SLDL-NH-Hillsborough 12-lower-ocd-person-c7c69eff-d232-40f0-b41b-ef9edd276edf": 4,
          "SLDL-NH-Hillsborough 12-lower-ocd-person-1a5923c6-3e1f-4ebe-8739-17c9e9642403": 5,
          "SLDL-NH-Hillsborough 12-lower-ocd-person-18d80f49-9f78-441e-bf28-1ed0929b5fd3": 6,
          "SLDL-NH-Hillsborough 12-lower-ocd-person-2375b05c-cf9a-4f1b-a4b0-194fac6d86bf": 7
        }
      },
      "Strafford 15": {
        "members": [
          {
            "API_ID": "ocd-person/f9eb1de2-7711-4e9d-bace-142e613274a8",
            "firstName": "Bill",
            "lastName": "Conlin",
            "fullName": "Bill Conlin",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 15",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 15-lower-ocd-person-f9eb1de2-7711-4e9d-bace-142e613274a8",
              "builtID": "nh-lower-strafford 15",
              "externalID": "ocd-person/f9eb1de2-7711-4e9d-bace-142e613274a8",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.conlin@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10898"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 15-lower-ocd-person-f9eb1de2-7711-4e9d-bace-142e613274a8": 0
        }
      },
      "Hillsborough 43": {
        "members": [
          {
            "API_ID": "ocd-person/8fb8ae5a-0b74-4be8-89be-36a0966cd8b5",
            "firstName": "Bill",
            "lastName": "King",
            "fullName": "Bill King",
            "gender": "Male",
            "party": "Republican",
            "image": "https://mcusercontent.com/81c9a8f6d68205a1de8957c61/images/16419446-67d0-42c5-84e5-3ad28b305c24.png",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 43",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 43-lower-ocd-person-8fb8ae5a-0b74-4be8-89be-36a0966cd8b5",
              "builtID": "nh-lower-hillsborough 43",
              "externalID": "ocd-person/8fb8ae5a-0b74-4be8-89be-36a0966cd8b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "king4nh@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9906",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9906"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/74b2c980-7fce-46b9-98dc-356240e18c2e",
            "firstName": "Maria Elizabeth",
            "lastName": "Perez",
            "fullName": "Maria Perez",
            "gender": "Female",
            "party": "independent",
            "image": "https://patch.com/img/cdn20/users/546351/20200814/014359/styles/patch_image/public/mara-prez-state-rep___14134203792.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 43",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 43-lower-ocd-person-74b2c980-7fce-46b9-98dc-356240e18c2e",
              "builtID": "nh-lower-hillsborough 43",
              "externalID": "ocd-person/74b2c980-7fce-46b9-98dc-356240e18c2e",
              "geometry": null
            },
            "contactInfo": {
              "email": "maria.perez@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9908",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9908"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1bf3d6c4-3325-4571-b2f7-6a28422dada6",
            "firstName": "Peter",
            "lastName": "Petrigno",
            "fullName": "Peter Petrigno",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408958.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 43",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 43-lower-ocd-person-1bf3d6c4-3325-4571-b2f7-6a28422dada6",
              "builtID": "nh-lower-hillsborough 43",
              "externalID": "ocd-person/1bf3d6c4-3325-4571-b2f7-6a28422dada6",
              "geometry": null
            },
            "contactInfo": {
              "email": "peter.petrigno@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9099",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9099"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/5c985922-36b3-4d80-986d-1f9c363920f1",
            "firstName": "Vanessa L.",
            "lastName": "Sheehan",
            "fullName": "Vanessa Sheehan",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409007.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 43",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 43-lower-ocd-person-5c985922-36b3-4d80-986d-1f9c363920f1",
              "builtID": "nh-lower-hillsborough 43",
              "externalID": "ocd-person/5c985922-36b3-4d80-986d-1f9c363920f1",
              "geometry": null
            },
            "contactInfo": {
              "email": "vanessa@vanessa4nh.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9907",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9907"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 43-lower-ocd-person-8fb8ae5a-0b74-4be8-89be-36a0966cd8b5": 0,
          "SLDL-NH-Hillsborough 43-lower-ocd-person-74b2c980-7fce-46b9-98dc-356240e18c2e": 1,
          "SLDL-NH-Hillsborough 43-lower-ocd-person-1bf3d6c4-3325-4571-b2f7-6a28422dada6": 2,
          "SLDL-NH-Hillsborough 43-lower-ocd-person-5c985922-36b3-4d80-986d-1f9c363920f1": 3
        }
      },
      "Grafton 8": {
        "members": [
          {
            "API_ID": "ocd-person/6d4386a3-50ff-437f-bf9e-50faea1fd774",
            "firstName": "William R.",
            "lastName": "Bolton",
            "fullName": "Bill Bolton",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409070.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 8-lower-ocd-person-6d4386a3-50ff-437f-bf9e-50faea1fd774",
              "builtID": "nh-lower-grafton 8",
              "externalID": "ocd-person/6d4386a3-50ff-437f-bf9e-50faea1fd774",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.bolton@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10765"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/af8ff7f6-ee61-492f-87cc-8fc69bee7886",
            "firstName": "Peter A.",
            "lastName": "Lovett",
            "fullName": "Peter Lovett",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/PeterLovett.png",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 8-lower-ocd-person-af8ff7f6-ee61-492f-87cc-8fc69bee7886",
              "builtID": "nh-lower-grafton 8",
              "externalID": "ocd-person/af8ff7f6-ee61-492f-87cc-8fc69bee7886",
              "geometry": null
            },
            "contactInfo": {
              "email": "peter.lovett@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10766"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/2260bb0a-065f-4d26-a814-f70aa2c16705",
            "firstName": "Sallie D.",
            "lastName": "Fellows",
            "fullName": "Sallie Fellows",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408836.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 8-lower-ocd-person-2260bb0a-065f-4d26-a814-f70aa2c16705",
              "builtID": "nh-lower-grafton 8",
              "externalID": "ocd-person/2260bb0a-065f-4d26-a814-f70aa2c16705",
              "geometry": null
            },
            "contactInfo": {
              "email": "sallie.fellows@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9059",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9059"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 8-lower-ocd-person-6d4386a3-50ff-437f-bf9e-50faea1fd774": 0,
          "SLDL-NH-Grafton 8-lower-ocd-person-af8ff7f6-ee61-492f-87cc-8fc69bee7886": 1,
          "SLDL-NH-Grafton 8-lower-ocd-person-2260bb0a-065f-4d26-a814-f70aa2c16705": 2
        }
      },
      "Rockingham 17": {
        "members": [
          {
            "API_ID": "ocd-person/ce46c52e-8598-4888-8a1f-d16b283a7238",
            "firstName": "Robert J.",
            "lastName": "Lynn",
            "fullName": "Bob Lynn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://upload.wikimedia.org/wikipedia/commons/4/42/Robert_J._Lynn.png",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 17",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 17-lower-ocd-person-ce46c52e-8598-4888-8a1f-d16b283a7238",
              "builtID": "nh-lower-rockingham 17",
              "externalID": "ocd-person/ce46c52e-8598-4888-8a1f-d16b283a7238",
              "geometry": null
            },
            "contactInfo": {
              "email": "rjlynn4@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9937",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9937"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/28d59199-5bae-46fe-99f3-ec78e330cdeb",
            "firstName": "Charles E.",
            "lastName": "McMahon",
            "fullName": "Charles McMahon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376498.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 17",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 17-lower-ocd-person-28d59199-5bae-46fe-99f3-ec78e330cdeb",
              "builtID": "nh-lower-rockingham 17",
              "externalID": "ocd-person/28d59199-5bae-46fe-99f3-ec78e330cdeb",
              "geometry": null
            },
            "contactInfo": {
              "email": "cmcmahon55@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=489",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376498"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/36ded3c5-84fd-49cc-82cb-d03fe682ad5f",
            "firstName": "Daniel",
            "lastName": "Popovici-Muller",
            "fullName": "Daniel Popovici-Muller",
            "gender": "Male",
            "party": "Republican",
            "image": "https://patch.com/img/cdn20/users/546351/20200306/091006/styles/patch_image/public/daniel-popovici-muller-windham___06090937367.jpg?width=1200",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 17",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 17-lower-ocd-person-36ded3c5-84fd-49cc-82cb-d03fe682ad5f",
              "builtID": "nh-lower-rockingham 17",
              "externalID": "ocd-person/36ded3c5-84fd-49cc-82cb-d03fe682ad5f",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.popovicimuller@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10697"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b7d43cab-3399-4022-a2a4-9415937b3daa",
            "firstName": "Katelyn T.",
            "lastName": "Kuttab",
            "fullName": "Katelyn Kuttab",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409119.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 17",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 17-lower-ocd-person-b7d43cab-3399-4022-a2a4-9415937b3daa",
              "builtID": "nh-lower-rockingham 17",
              "externalID": "ocd-person/b7d43cab-3399-4022-a2a4-9415937b3daa",
              "geometry": null
            },
            "contactInfo": {
              "email": "katelyn.kuttab@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10695"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 17-lower-ocd-person-ce46c52e-8598-4888-8a1f-d16b283a7238": 0,
          "SLDL-NH-Rockingham 17-lower-ocd-person-28d59199-5bae-46fe-99f3-ec78e330cdeb": 1,
          "SLDL-NH-Rockingham 17-lower-ocd-person-36ded3c5-84fd-49cc-82cb-d03fe682ad5f": 2,
          "SLDL-NH-Rockingham 17-lower-ocd-person-b7d43cab-3399-4022-a2a4-9415937b3daa": 3
        }
      },
      "Strafford 9": {
        "members": [
          {
            "API_ID": "ocd-person/f51bcd29-63c8-4ef6-96d9-4bdd7b7617ee",
            "firstName": "Brandon",
            "lastName": "Phinney",
            "fullName": "Brandon Phinney",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408447.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 9",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 9-lower-ocd-person-f51bcd29-63c8-4ef6-96d9-4bdd7b7617ee",
              "builtID": "nh-lower-strafford 9",
              "externalID": "ocd-person/f51bcd29-63c8-4ef6-96d9-4bdd7b7617ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandon.phinney@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8447",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=408447"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 9-lower-ocd-person-f51bcd29-63c8-4ef6-96d9-4bdd7b7617ee": 0
        }
      },
      "Hillsborough 26": {
        "members": [
          {
            "API_ID": "ocd-person/e0dbb769-2677-4939-adc5-e1d2b253150f",
            "firstName": "Brian D.",
            "lastName": "Cole",
            "fullName": "Brian Cole",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409192.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 26",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 26-lower-ocd-person-e0dbb769-2677-4939-adc5-e1d2b253150f",
              "builtID": "nh-lower-hillsborough 26",
              "externalID": "ocd-person/e0dbb769-2677-4939-adc5-e1d2b253150f",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.cole@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=7267"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/6723b20c-eb92-4b4b-8db7-5cf0e06d0c5f",
            "firstName": "Mary",
            "lastName": "Freitas",
            "fullName": "Mary Freitas",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377296.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 26",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 26-lower-ocd-person-6723b20c-eb92-4b4b-8db7-5cf0e06d0c5f",
              "builtID": "nh-lower-hillsborough 26",
              "externalID": "ocd-person/6723b20c-eb92-4b4b-8db7-5cf0e06d0c5f",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.freitas@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=847",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377296"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 26-lower-ocd-person-e0dbb769-2677-4939-adc5-e1d2b253150f": 0,
          "SLDL-NH-Hillsborough 26-lower-ocd-person-6723b20c-eb92-4b4b-8db7-5cf0e06d0c5f": 1
        }
      },
      "Sullivan 1": {
        "members": [
          {
            "API_ID": "ocd-person/4c78e7fc-43a2-467b-abe3-6ba04ad8853e",
            "firstName": "Brian",
            "lastName": "Sullivan",
            "fullName": "Brian Sullivan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408813.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Sullivan 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Sullivan 1-lower-ocd-person-4c78e7fc-43a2-467b-abe3-6ba04ad8853e",
              "builtID": "nh-lower-sullivan 1",
              "externalID": "ocd-person/4c78e7fc-43a2-467b-abe3-6ba04ad8853e",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.sullivan@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8986",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408813"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Sullivan 1-lower-ocd-person-4c78e7fc-43a2-467b-abe3-6ba04ad8853e": 0
        }
      },
      "Merrimack 12": {
        "members": [
          {
            "API_ID": "ocd-person/b12abcbf-fbbe-4464-854e-6960b024e62a",
            "firstName": "G. Brian",
            "lastName": "Seaworth",
            "fullName": "Brian Seaworth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377023.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 12-lower-ocd-person-b12abcbf-fbbe-4464-854e-6960b024e62a",
              "builtID": "nh-lower-merrimack 12",
              "externalID": "ocd-person/b12abcbf-fbbe-4464-854e-6960b024e62a",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.seaworth@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=944",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377023"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/62ff0476-f4e6-411f-a58e-2e7963cde2ab",
            "firstName": "Dianne",
            "lastName": "Schuett",
            "fullName": "Dianne Schuett",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376862.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 12-lower-ocd-person-62ff0476-f4e6-411f-a58e-2e7963cde2ab",
              "builtID": "nh-lower-merrimack 12",
              "externalID": "ocd-person/62ff0476-f4e6-411f-a58e-2e7963cde2ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "dianne.schuett@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=595",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376862"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 12-lower-ocd-person-b12abcbf-fbbe-4464-854e-6960b024e62a": 0,
          "SLDL-NH-Merrimack 12-lower-ocd-person-62ff0476-f4e6-411f-a58e-2e7963cde2ab": 1
        }
      },
      "Strafford 10": {
        "members": [
          {
            "API_ID": "ocd-person/9f35ea7b-b937-4b06-8be5-5cfbc83381e1",
            "firstName": "Cam",
            "lastName": "Kenney",
            "fullName": "Cam Kenney",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/CamKenney.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 10-lower-ocd-person-9f35ea7b-b937-4b06-8be5-5cfbc83381e1",
              "builtID": "nh-lower-strafford 10",
              "externalID": "ocd-person/9f35ea7b-b937-4b06-8be5-5cfbc83381e1",
              "geometry": null
            },
            "contactInfo": {
              "email": "cam.kenney@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9231",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9231"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a35a4483-879d-4dd2-889e-57a576e56acc",
            "firstName": "Loren",
            "lastName": "Selig",
            "fullName": "Loren Selig",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Loren_Selig.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 10-lower-ocd-person-a35a4483-879d-4dd2-889e-57a576e56acc",
              "builtID": "nh-lower-strafford 10",
              "externalID": "ocd-person/a35a4483-879d-4dd2-889e-57a576e56acc",
              "geometry": null
            },
            "contactInfo": {
              "email": "loren.selig@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10896"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/86b31d04-3a4b-4199-b10e-27bd337f0a34",
            "firstName": "Marjorie",
            "lastName": "Smith",
            "fullName": "Marjorie Smith",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376166.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 10-lower-ocd-person-86b31d04-3a4b-4199-b10e-27bd337f0a34",
              "builtID": "nh-lower-strafford 10",
              "externalID": "ocd-person/86b31d04-3a4b-4199-b10e-27bd337f0a34",
              "geometry": null
            },
            "contactInfo": {
              "email": "msmithpen@aol.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=452",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376166"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/2a11a559-c16e-4211-82ea-215951d74c1f",
            "firstName": "Timothy O.",
            "lastName": "Horrigan",
            "fullName": "Timothy Horrigan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376826.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 10-lower-ocd-person-2a11a559-c16e-4211-82ea-215951d74c1f",
              "builtID": "nh-lower-strafford 10",
              "externalID": "ocd-person/2a11a559-c16e-4211-82ea-215951d74c1f",
              "geometry": null
            },
            "contactInfo": {
              "email": "timothy.horrigan@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=579",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376826"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 10-lower-ocd-person-9f35ea7b-b937-4b06-8be5-5cfbc83381e1": 0,
          "SLDL-NH-Strafford 10-lower-ocd-person-a35a4483-879d-4dd2-889e-57a576e56acc": 1,
          "SLDL-NH-Strafford 10-lower-ocd-person-86b31d04-3a4b-4199-b10e-27bd337f0a34": 2,
          "SLDL-NH-Strafford 10-lower-ocd-person-2a11a559-c16e-4211-82ea-215951d74c1f": 3
        }
      },
      "Rockingham 29": {
        "members": [
          {
            "API_ID": "ocd-person/7a7a1931-0eaf-4b95-8fea-ad20f590e4af",
            "firstName": "Candice M.",
            "lastName": "O'Neil",
            "fullName": "Candice O'Neil",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.attorneycandiceoneil.com/hubfs/Hudkins_April_2021/Images/Candice%20O%E2%80%99Neil.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 29",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 29-lower-ocd-person-7a7a1931-0eaf-4b95-8fea-ad20f590e4af",
              "builtID": "nh-lower-rockingham 29",
              "externalID": "ocd-person/7a7a1931-0eaf-4b95-8fea-ad20f590e4af",
              "geometry": null
            },
            "contactInfo": {
              "email": "candice.oneil@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10878",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10878"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0dbeb05f-3510-48d3-9918-a929a6069ac2",
            "firstName": "Chris",
            "lastName": "Muns",
            "fullName": "Chris Muns",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377150.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 29",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 29-lower-ocd-person-0dbeb05f-3510-48d3-9918-a929a6069ac2",
              "builtID": "nh-lower-rockingham 29",
              "externalID": "ocd-person/0dbeb05f-3510-48d3-9918-a929a6069ac2",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.muns@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377150",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=755",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=755"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0f78413a-b328-4856-ab48-21a5d4d614c0",
            "firstName": "Michael",
            "lastName": "Edgar",
            "fullName": "Michael Edgar",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377778.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 29",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 29-lower-ocd-person-0f78413a-b328-4856-ab48-21a5d4d614c0",
              "builtID": "nh-lower-rockingham 29",
              "externalID": "ocd-person/0f78413a-b328-4856-ab48-21a5d4d614c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.edgar@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=1056",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=377778"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/fb61041a-a20a-409c-988b-cdbcf276b0f2",
            "firstName": "J. Tracy",
            "lastName": "Emerick",
            "fullName": "Tracy Emerick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377094.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 29",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 29-lower-ocd-person-fb61041a-a20a-409c-988b-cdbcf276b0f2",
              "builtID": "nh-lower-rockingham 29",
              "externalID": "ocd-person/fb61041a-a20a-409c-988b-cdbcf276b0f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "tracy.emerick@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=701",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=701"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 29-lower-ocd-person-7a7a1931-0eaf-4b95-8fea-ad20f590e4af": 0,
          "SLDL-NH-Rockingham 29-lower-ocd-person-0dbeb05f-3510-48d3-9918-a929a6069ac2": 1,
          "SLDL-NH-Rockingham 29-lower-ocd-person-0f78413a-b328-4856-ab48-21a5d4d614c0": 2,
          "SLDL-NH-Rockingham 29-lower-ocd-person-fb61041a-a20a-409c-988b-cdbcf276b0f2": 3
        }
      },
      "Merrimack 27": {
        "members": [
          {
            "API_ID": "ocd-person/ae3d6133-98b5-444e-b95e-2edc2f5302dc",
            "firstName": "Carol M.",
            "lastName": "McGuire",
            "fullName": "Carol McGuire",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376841.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 27",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 27-lower-ocd-person-ae3d6133-98b5-444e-b95e-2edc2f5302dc",
              "builtID": "nh-lower-merrimack 27",
              "externalID": "ocd-person/ae3d6133-98b5-444e-b95e-2edc2f5302dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "carol@mcguire4house.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=584",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376841"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/01cfe997-f42c-4ba3-979b-fb7f70acda3e",
            "firstName": "J.R.",
            "lastName": "Hoell",
            "fullName": "J.R. Hoell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376949.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 27",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 27-lower-ocd-person-01cfe997-f42c-4ba3-979b-fb7f70acda3e",
              "builtID": "nh-lower-merrimack 27",
              "externalID": "ocd-person/01cfe997-f42c-4ba3-979b-fb7f70acda3e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jr.hoell@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=627",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376949"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 27-lower-ocd-person-ae3d6133-98b5-444e-b95e-2edc2f5302dc": 0,
          "SLDL-NH-Merrimack 27-lower-ocd-person-01cfe997-f42c-4ba3-979b-fb7f70acda3e": 1
        }
      },
      "Grafton 10": {
        "members": [
          {
            "API_ID": "ocd-person/fd8eb4da-691b-42e4-87cd-7d54c50f7913",
            "firstName": "Carroll M.",
            "lastName": "Brown",
            "fullName": "Carroll Brown",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409074.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 10-lower-ocd-person-fd8eb4da-691b-42e4-87cd-7d54c50f7913",
              "builtID": "nh-lower-grafton 10",
              "externalID": "ocd-person/fd8eb4da-691b-42e4-87cd-7d54c50f7913",
              "geometry": null
            },
            "contactInfo": {
              "email": "carroll.brown@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10619"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 10-lower-ocd-person-fd8eb4da-691b-42e4-87cd-7d54c50f7913": 0
        }
      },
      "Hillsborough 6": {
        "members": [
          {
            "API_ID": "ocd-person/74d3b542-b2d0-426f-9835-0de12d5e4895",
            "firstName": "Carry",
            "lastName": "Spier",
            "fullName": "Carry Spier",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409174.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 6-lower-ocd-person-74d3b542-b2d0-426f-9835-0de12d5e4895",
              "builtID": "nh-lower-hillsborough 6",
              "externalID": "ocd-person/74d3b542-b2d0-426f-9835-0de12d5e4895",
              "geometry": null
            },
            "contactInfo": {
              "email": "carry.spier@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10780"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/43eb9cdc-392c-4ca5-b5da-ad542ea0bcc0",
            "firstName": "Sherry",
            "lastName": "Dutzy",
            "fullName": "Sherry Dutzy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408872.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 6-lower-ocd-person-43eb9cdc-392c-4ca5-b5da-ad542ea0bcc0",
              "builtID": "nh-lower-hillsborough 6",
              "externalID": "ocd-person/43eb9cdc-392c-4ca5-b5da-ad542ea0bcc0",
              "geometry": null
            },
            "contactInfo": {
              "email": "sherry.dutzy@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9421",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9421"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ce985799-268d-4190-b078-955fc63a8c3c",
            "firstName": "Suzanne Mercier",
            "lastName": "Vail",
            "fullName": "Sue Vail",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377185.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 6-lower-ocd-person-ce985799-268d-4190-b078-955fc63a8c3c",
              "builtID": "nh-lower-hillsborough 6",
              "externalID": "ocd-person/ce985799-268d-4190-b078-955fc63a8c3c",
              "geometry": null
            },
            "contactInfo": {
              "email": "suzanne.vail@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=789",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=789"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 6-lower-ocd-person-74d3b542-b2d0-426f-9835-0de12d5e4895": 0,
          "SLDL-NH-Hillsborough 6-lower-ocd-person-43eb9cdc-392c-4ca5-b5da-ad542ea0bcc0": 1,
          "SLDL-NH-Hillsborough 6-lower-ocd-person-ce985799-268d-4190-b078-955fc63a8c3c": 2
        }
      },
      "Strafford 4": {
        "members": [
          {
            "API_ID": "ocd-person/711423e3-ff28-4150-8a89-ec568a4f2c5d",
            "firstName": "Cassandra N.",
            "lastName": "Levesque",
            "fullName": "Cassandra Levesque",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408930.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 4-lower-ocd-person-711423e3-ff28-4150-8a89-ec568a4f2c5d",
              "builtID": "nh-lower-strafford 4",
              "externalID": "ocd-person/711423e3-ff28-4150-8a89-ec568a4f2c5d",
              "geometry": null
            },
            "contactInfo": {
              "email": "cassandra.levesque@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9425",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9425"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ff64ec8f-c8be-4e9c-8dce-3397cdc2401e",
            "firstName": "Heath",
            "lastName": "Howard",
            "fullName": "Heath Howard",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409110.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 4-lower-ocd-person-ff64ec8f-c8be-4e9c-8dce-3397cdc2401e",
              "builtID": "nh-lower-strafford 4",
              "externalID": "ocd-person/ff64ec8f-c8be-4e9c-8dce-3397cdc2401e",
              "geometry": null
            },
            "contactInfo": {
              "email": "heath.howard@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10004"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b5f897f3-cce8-43b0-a130-f98d0d88da8e",
            "firstName": "Leonard",
            "lastName": "Turcotte",
            "fullName": "Len Turcotte",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377269.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 4-lower-ocd-person-b5f897f3-cce8-43b0-a130-f98d0d88da8e",
              "builtID": "nh-lower-strafford 4",
              "externalID": "ocd-person/b5f897f3-cce8-43b0-a130-f98d0d88da8e",
              "geometry": null
            },
            "contactInfo": {
              "email": "lenturcotte@metrocast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=920"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 4-lower-ocd-person-711423e3-ff28-4150-8a89-ec568a4f2c5d": 0,
          "SLDL-NH-Strafford 4-lower-ocd-person-ff64ec8f-c8be-4e9c-8dce-3397cdc2401e": 1,
          "SLDL-NH-Strafford 4-lower-ocd-person-b5f897f3-cce8-43b0-a130-f98d0d88da8e": 2
        }
      },
      "Hillsborough 2": {
        "members": [
          {
            "API_ID": "ocd-person/5223be3f-79ec-4442-8164-588d2ccc77e0",
            "firstName": "Catherine A.",
            "lastName": "Rombeau",
            "fullName": "Catherine Rombeau",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.littler.com/files/lawyer-photo/png/Rombeau_Catherine_06113.png",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 2-lower-ocd-person-5223be3f-79ec-4442-8164-588d2ccc77e0",
              "builtID": "nh-lower-hillsborough 2",
              "externalID": "ocd-person/5223be3f-79ec-4442-8164-588d2ccc77e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "catherine.rombeau@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10407",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10407"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4246d501-b872-4cf9-bfd8-7ef21015da7a",
            "firstName": "Kristin",
            "lastName": "Noble",
            "fullName": "Kristin Noble",
            "gender": "Female",
            "party": "Republican",
            "image": "https://kristinnoble.com/wp-content/uploads/2022/06/Kristin-Noble1.png",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 2-lower-ocd-person-4246d501-b872-4cf9-bfd8-7ef21015da7a",
              "builtID": "nh-lower-hillsborough 2",
              "externalID": "ocd-person/4246d501-b872-4cf9-bfd8-7ef21015da7a",
              "geometry": null
            },
            "contactInfo": {
              "email": "kristin.noble@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10626",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10626"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d0d7f906-c48f-4a61-943c-1faf0162b329",
            "firstName": "Laurie",
            "lastName": "Sanborn",
            "fullName": "Laurie Sanborn",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377016.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 2-lower-ocd-person-d0d7f906-c48f-4a61-943c-1faf0162b329",
              "builtID": "nh-lower-hillsborough 2",
              "externalID": "ocd-person/d0d7f906-c48f-4a61-943c-1faf0162b329",
              "geometry": null
            },
            "contactInfo": {
              "email": "repsanborn@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=651",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377016"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/40ae4adb-2124-47ca-82b5-9eee794af4a2",
            "firstName": "Linda",
            "lastName": "Gould",
            "fullName": "Linda Gould",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377299.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 2-lower-ocd-person-40ae4adb-2124-47ca-82b5-9eee794af4a2",
              "builtID": "nh-lower-hillsborough 2",
              "externalID": "ocd-person/40ae4adb-2124-47ca-82b5-9eee794af4a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "linda.gould@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=849",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377299"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/260ddf78-7100-4fee-a353-0ebf1a0615ec",
            "firstName": "Loren",
            "lastName": "Foxx",
            "fullName": "Loren Foxx",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://tcbagency.com/wp-content/uploads/2021/10/10_52_50-400x547.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 2-lower-ocd-person-260ddf78-7100-4fee-a353-0ebf1a0615ec",
              "builtID": "nh-lower-hillsborough 2",
              "externalID": "ocd-person/260ddf78-7100-4fee-a353-0ebf1a0615ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "loren.foxx@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10773"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c40abbd9-220c-4081-9ad8-9559c10f9aae",
            "firstName": "Theodore",
            "lastName": "Gorski",
            "fullName": "Ted Gorski",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.tedgorski.com/wp-content/uploads/sites/23/2020/08/ted-gorski-featured.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 2-lower-ocd-person-c40abbd9-220c-4081-9ad8-9559c10f9aae",
              "builtID": "nh-lower-hillsborough 2",
              "externalID": "ocd-person/c40abbd9-220c-4081-9ad8-9559c10f9aae",
              "geometry": null
            },
            "contactInfo": {
              "email": "ted.gorski@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9898",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9898"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 2-lower-ocd-person-5223be3f-79ec-4442-8164-588d2ccc77e0": 0,
          "SLDL-NH-Hillsborough 2-lower-ocd-person-4246d501-b872-4cf9-bfd8-7ef21015da7a": 1,
          "SLDL-NH-Hillsborough 2-lower-ocd-person-d0d7f906-c48f-4a61-943c-1faf0162b329": 2,
          "SLDL-NH-Hillsborough 2-lower-ocd-person-40ae4adb-2124-47ca-82b5-9eee794af4a2": 3,
          "SLDL-NH-Hillsborough 2-lower-ocd-person-260ddf78-7100-4fee-a353-0ebf1a0615ec": 4,
          "SLDL-NH-Hillsborough 2-lower-ocd-person-c40abbd9-220c-4081-9ad8-9559c10f9aae": 5
        }
      },
      "Cheshire 6": {
        "members": [
          {
            "API_ID": "ocd-person/c15709b9-8514-4e8f-8a52-6b464eb8484d",
            "firstName": "Cathryn A.",
            "lastName": "Harvey",
            "fullName": "Cathryn Harvey",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408479.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 6-lower-ocd-person-c15709b9-8514-4e8f-8a52-6b464eb8484d",
              "builtID": "nh-lower-cheshire 6",
              "externalID": "ocd-person/c15709b9-8514-4e8f-8a52-6b464eb8484d",
              "geometry": null
            },
            "contactInfo": {
              "email": "cathryn.harvey@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8479",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408479"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/5aae4f7b-a7b5-467d-8b16-c566da38f624",
            "firstName": "Michael Dennis",
            "lastName": "Abbott",
            "fullName": "Mike Abbott",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377279.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 6-lower-ocd-person-5aae4f7b-a7b5-467d-8b16-c566da38f624",
              "builtID": "nh-lower-cheshire 6",
              "externalID": "ocd-person/5aae4f7b-a7b5-467d-8b16-c566da38f624",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.abbott@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=818",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377279"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 6-lower-ocd-person-c15709b9-8514-4e8f-8a52-6b464eb8484d": 0,
          "SLDL-NH-Cheshire 6-lower-ocd-person-5aae4f7b-a7b5-467d-8b16-c566da38f624": 1
        }
      },
      "Strafford 12": {
        "members": [
          {
            "API_ID": "ocd-person/74d2aaac-97e3-41bc-af8d-3b9bcbbdc317",
            "firstName": "Cecilia",
            "lastName": "Rich",
            "fullName": "Cecilia Rich",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/candidate-images/CeciliaRich.jpg?itok=G8lJ_wVD",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 12-lower-ocd-person-74d2aaac-97e3-41bc-af8d-3b9bcbbdc317",
              "builtID": "nh-lower-strafford 12",
              "externalID": "ocd-person/74d2aaac-97e3-41bc-af8d-3b9bcbbdc317",
              "geometry": null
            },
            "contactInfo": {
              "email": "cecilia.rich@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9238",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9238"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/cd5b7056-d9eb-4fb1-93e3-6ba43408afa5",
            "firstName": "Gerri",
            "lastName": "Cannon",
            "fullName": "Gerri Cannon",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408935.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 12-lower-ocd-person-cd5b7056-d9eb-4fb1-93e3-6ba43408afa5",
              "builtID": "nh-lower-strafford 12",
              "externalID": "ocd-person/cd5b7056-d9eb-4fb1-93e3-6ba43408afa5",
              "geometry": null
            },
            "contactInfo": {
              "email": "gerri.cannon@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9235",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9235"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a7506d46-b322-4d55-9e12-37e2def9a3ed",
            "firstName": "Jeffrey",
            "lastName": "Rich",
            "fullName": "Jeffrey Rich",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 12-lower-ocd-person-a7506d46-b322-4d55-9e12-37e2def9a3ed",
              "builtID": "nh-lower-strafford 12",
              "externalID": "ocd-person/a7506d46-b322-4d55-9e12-37e2def9a3ed",
              "geometry": null
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10897"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/84b0eb59-b35d-4f30-aa55-b5cd6e7f495b",
            "firstName": "Kenneth",
            "lastName": "Vincent",
            "fullName": "Kenneth Vincent",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408632.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 12-lower-ocd-person-84b0eb59-b35d-4f30-aa55-b5cd6e7f495b",
              "builtID": "nh-lower-strafford 12",
              "externalID": "ocd-person/84b0eb59-b35d-4f30-aa55-b5cd6e7f495b",
              "geometry": null
            },
            "contactInfo": {
              "email": "kvincentnhrep@comcast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8632",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=408632"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 12-lower-ocd-person-74d2aaac-97e3-41bc-af8d-3b9bcbbdc317": 0,
          "SLDL-NH-Strafford 12-lower-ocd-person-cd5b7056-d9eb-4fb1-93e3-6ba43408afa5": 1,
          "SLDL-NH-Strafford 12-lower-ocd-person-a7506d46-b322-4d55-9e12-37e2def9a3ed": 2,
          "SLDL-NH-Strafford 12-lower-ocd-person-84b0eb59-b35d-4f30-aa55-b5cd6e7f495b": 3
        }
      },
      "Rockingham 13": {
        "members": [
          {
            "API_ID": "ocd-person/2cc808df-0763-4807-9a67-b53a1764f84c",
            "firstName": "Charles",
            "lastName": "Foote",
            "fullName": "Charlie Foote",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-08/charles-foote.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 13-lower-ocd-person-2cc808df-0763-4807-9a67-b53a1764f84c",
              "builtID": "nh-lower-rockingham 13",
              "externalID": "ocd-person/2cc808df-0763-4807-9a67-b53a1764f84c",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.foote@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10691"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1c956ef6-b705-4e9d-af0c-f66d6f44591e",
            "firstName": "David C.",
            "lastName": "Love",
            "fullName": "David Love",
            "gender": "Male",
            "party": "Republican",
            "image": "https://vote-usa.org/Image.aspx?Id=NHLoveDavidC&Col=Profile300&Def=Profile300",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 13-lower-ocd-person-1c956ef6-b705-4e9d-af0c-f66d6f44591e",
              "builtID": "nh-lower-rockingham 13",
              "externalID": "ocd-person/1c956ef6-b705-4e9d-af0c-f66d6f44591e",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.love@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8407",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=8407"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ef41a7dd-a84f-47db-b794-2a6c6be48771",
            "firstName": "David",
            "lastName": "Milz",
            "fullName": "David Milz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377147.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 13-lower-ocd-person-ef41a7dd-a84f-47db-b794-2a6c6be48771",
              "builtID": "nh-lower-rockingham 13",
              "externalID": "ocd-person/ef41a7dd-a84f-47db-b794-2a6c6be48771",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.milz@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=752",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377147"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/94df7a3f-b27d-42c1-9ecc-1ebdc707c9ae",
            "firstName": "Erica",
            "lastName": "Layon",
            "fullName": "Erica Layon",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409035.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 13-lower-ocd-person-94df7a3f-b27d-42c1-9ecc-1ebdc707c9ae",
              "builtID": "nh-lower-rockingham 13",
              "externalID": "ocd-person/94df7a3f-b27d-42c1-9ecc-1ebdc707c9ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "erica.layon@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9935",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9935"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1f649bd8-815d-47b9-aca3-6e11ef2da422",
            "firstName": "Jodi",
            "lastName": "Nelson",
            "fullName": "Jodi Nelson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://nhjournal.com/wp-content/uploads/2021/12/Nelson-300x300.jpeg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 13-lower-ocd-person-1f649bd8-815d-47b9-aca3-6e11ef2da422",
              "builtID": "nh-lower-rockingham 13",
              "externalID": "ocd-person/1f649bd8-815d-47b9-aca3-6e11ef2da422",
              "geometry": null
            },
            "contactInfo": {
              "email": "jodi.nelson@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10440",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10440"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/433c67d6-bfdc-410e-87e2-463c93d55a17",
            "firstName": "John M.",
            "lastName": "Potucek",
            "fullName": "John Potucek",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377257.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 13-lower-ocd-person-433c67d6-bfdc-410e-87e2-463c93d55a17",
              "builtID": "nh-lower-rockingham 13",
              "externalID": "ocd-person/433c67d6-bfdc-410e-87e2-463c93d55a17",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.potucek@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=902",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377257"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a0a8c9d8-1f27-4d28-a249-8a5856ea5c05",
            "firstName": "Katherine",
            "lastName": "Prudhomme-O'Brien",
            "fullName": "Katherine Prudhomme-O'Brien",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377258.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 13-lower-ocd-person-a0a8c9d8-1f27-4d28-a249-8a5856ea5c05",
              "builtID": "nh-lower-rockingham 13",
              "externalID": "ocd-person/a0a8c9d8-1f27-4d28-a249-8a5856ea5c05",
              "geometry": null
            },
            "contactInfo": {
              "email": "kpo@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377258"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/19404ada-b556-4609-9814-f19db9c0a0ad",
            "firstName": "Phyllis M.",
            "lastName": "Katsakiores",
            "fullName": "Phyllis Katsakiores",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/332247.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 13-lower-ocd-person-19404ada-b556-4609-9814-f19db9c0a0ad",
              "builtID": "nh-lower-rockingham 13",
              "externalID": "ocd-person/19404ada-b556-4609-9814-f19db9c0a0ad",
              "geometry": null
            },
            "contactInfo": {
              "email": "pkatsakiores@comcast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=960",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=332247"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c715d154-3822-42b6-ab75-73181ac7be0a",
            "firstName": "Richard",
            "lastName": "Tripp",
            "fullName": "Richard Tripp",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408408.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 13-lower-ocd-person-c715d154-3822-42b6-ab75-73181ac7be0a",
              "builtID": "nh-lower-rockingham 13",
              "externalID": "ocd-person/c715d154-3822-42b6-ab75-73181ac7be0a",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.tripp@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8408",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=8408"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/2a085551-6f62-4320-a1eb-bc9a177b9a6d",
            "firstName": "Stephen",
            "lastName": "Pearson",
            "fullName": "Steve Pearson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/candidate-images/StephenPearson.jpg?itok=bRU8nrZr",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 13-lower-ocd-person-2a085551-6f62-4320-a1eb-bc9a177b9a6d",
              "builtID": "nh-lower-rockingham 13",
              "externalID": "ocd-person/2a085551-6f62-4320-a1eb-bc9a177b9a6d",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.pearson@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9269",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9269"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 13-lower-ocd-person-2cc808df-0763-4807-9a67-b53a1764f84c": 0,
          "SLDL-NH-Rockingham 13-lower-ocd-person-1c956ef6-b705-4e9d-af0c-f66d6f44591e": 1,
          "SLDL-NH-Rockingham 13-lower-ocd-person-ef41a7dd-a84f-47db-b794-2a6c6be48771": 2,
          "SLDL-NH-Rockingham 13-lower-ocd-person-94df7a3f-b27d-42c1-9ecc-1ebdc707c9ae": 3,
          "SLDL-NH-Rockingham 13-lower-ocd-person-1f649bd8-815d-47b9-aca3-6e11ef2da422": 4,
          "SLDL-NH-Rockingham 13-lower-ocd-person-433c67d6-bfdc-410e-87e2-463c93d55a17": 5,
          "SLDL-NH-Rockingham 13-lower-ocd-person-a0a8c9d8-1f27-4d28-a249-8a5856ea5c05": 6,
          "SLDL-NH-Rockingham 13-lower-ocd-person-19404ada-b556-4609-9814-f19db9c0a0ad": 7,
          "SLDL-NH-Rockingham 13-lower-ocd-person-c715d154-3822-42b6-ab75-73181ac7be0a": 8,
          "SLDL-NH-Rockingham 13-lower-ocd-person-2a085551-6f62-4320-a1eb-bc9a177b9a6d": 9
        }
      },
      "Rockingham 20": {
        "members": [
          {
            "API_ID": "ocd-person/66ba1045-a0e7-4602-b228-e68dea8be29e",
            "firstName": "Charles",
            "lastName": "Melvin",
            "fullName": "Charlie Melvin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://img.youtube.com/vi/47TkmRoel30/hqdefault.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 20",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 20-lower-ocd-person-66ba1045-a0e7-4602-b228-e68dea8be29e",
              "builtID": "nh-lower-rockingham 20",
              "externalID": "ocd-person/66ba1045-a0e7-4602-b228-e68dea8be29e",
              "geometry": null
            },
            "contactInfo": {
              "email": "charlie.melvin@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8419",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=8419"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/14936ed1-5203-4f3e-be18-4243f1fff521",
            "firstName": "James D.",
            "lastName": "Summers",
            "fullName": "Jim Summers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/candidate-images/jimsummers.jpg?itok=7IqtSp2Z",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 20",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 20-lower-ocd-person-14936ed1-5203-4f3e-be18-4243f1fff521",
              "builtID": "nh-lower-rockingham 20",
              "externalID": "ocd-person/14936ed1-5203-4f3e-be18-4243f1fff521",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.summers@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9294"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c62fca24-e8c3-4f87-95dd-cb52a3d58c05",
            "firstName": "Robert D.",
            "lastName": "Harb",
            "fullName": "Bob Harb",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408914.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 20",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 20-lower-ocd-person-c62fca24-e8c3-4f87-95dd-cb52a3d58c05",
              "builtID": "nh-lower-rockingham 20",
              "externalID": "ocd-person/c62fca24-e8c3-4f87-95dd-cb52a3d58c05",
              "geometry": null
            },
            "contactInfo": {
              "email": "robert.harb@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9293",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9293"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 20-lower-ocd-person-66ba1045-a0e7-4602-b228-e68dea8be29e": 0,
          "SLDL-NH-Rockingham 20-lower-ocd-person-14936ed1-5203-4f3e-be18-4243f1fff521": 1,
          "SLDL-NH-Rockingham 20-lower-ocd-person-c62fca24-e8c3-4f87-95dd-cb52a3d58c05": 2
        }
      },
      "Belknap 5": {
        "members": [
          {
            "API_ID": "ocd-person/643751f4-6db0-4021-bf76-b8f2483d640d",
            "firstName": "Charlie",
            "lastName": "St. Clair",
            "fullName": "Charlie St. Clair",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://images.dailykos.com/images/448207/story_image/Charlie_St._Clair.jpg?1505263188",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 5-lower-ocd-person-643751f4-6db0-4021-bf76-b8f2483d640d",
              "builtID": "nh-lower-belknap 5",
              "externalID": "ocd-person/643751f4-6db0-4021-bf76-b8f2483d640d",
              "geometry": null
            },
            "contactInfo": {
              "email": "charlie.stclair@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8956",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=8956"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1ca469eb-22a1-4c1c-af43-a0a5456f3a4f",
            "firstName": "David O.",
            "lastName": "Huot",
            "fullName": "David Huot",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377125.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 5-lower-ocd-person-1ca469eb-22a1-4c1c-af43-a0a5456f3a4f",
              "builtID": "nh-lower-belknap 5",
              "externalID": "ocd-person/1ca469eb-22a1-4c1c-af43-a0a5456f3a4f",
              "geometry": null
            },
            "contactInfo": {
              "email": "dhuot03246@yahoo.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=732",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=732"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c924901e-1cc8-4574-ae7c-19f3e52d2031",
            "firstName": "Michael D.",
            "lastName": "Bordes",
            "fullName": "Mike Bordes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2020-07/mike-bordes.png?itok=kgP2odgc",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 5-lower-ocd-person-c924901e-1cc8-4574-ae7c-19f3e52d2031",
              "builtID": "nh-lower-belknap 5",
              "externalID": "ocd-person/c924901e-1cc8-4574-ae7c-19f3e52d2031",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.bordes@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9861",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9861"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a82b5491-46a0-4062-aec5-362724f8f729",
            "firstName": "Steven T.",
            "lastName": "Bogert",
            "fullName": "Steve Bogert",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409069.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 5-lower-ocd-person-a82b5491-46a0-4062-aec5-362724f8f729",
              "builtID": "nh-lower-belknap 5",
              "externalID": "ocd-person/a82b5491-46a0-4062-aec5-362724f8f729",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.bogert@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10573"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Belknap 5-lower-ocd-person-643751f4-6db0-4021-bf76-b8f2483d640d": 0,
          "SLDL-NH-Belknap 5-lower-ocd-person-1ca469eb-22a1-4c1c-af43-a0a5456f3a4f": 1,
          "SLDL-NH-Belknap 5-lower-ocd-person-c924901e-1cc8-4574-ae7c-19f3e52d2031": 2,
          "SLDL-NH-Belknap 5-lower-ocd-person-a82b5491-46a0-4062-aec5-362724f8f729": 3
        }
      },
      "Rockingham 10": {
        "members": [
          {
            "API_ID": "ocd-person/75a682ff-407d-4192-858d-9ce6a979c400",
            "firstName": "Charlotte",
            "lastName": "DiLorenzo",
            "fullName": "Charlotte DiLorenzo",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408612.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 10-lower-ocd-person-75a682ff-407d-4192-858d-9ce6a979c400",
              "builtID": "nh-lower-rockingham 10",
              "externalID": "ocd-person/75a682ff-407d-4192-858d-9ce6a979c400",
              "geometry": null
            },
            "contactInfo": {
              "email": "charlotte.dilorenzo@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8612",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408612"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/77304532-17fb-4b2c-aed6-213a435e4a46",
            "firstName": "Ellen",
            "lastName": "Read",
            "fullName": "Ellen Read",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408613.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 10-lower-ocd-person-77304532-17fb-4b2c-aed6-213a435e4a46",
              "builtID": "nh-lower-rockingham 10",
              "externalID": "ocd-person/77304532-17fb-4b2c-aed6-213a435e4a46",
              "geometry": null
            },
            "contactInfo": {
              "email": "ellen4nh@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8613",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408613"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/facb5409-244f-4381-8452-e6a1078bb1c5",
            "firstName": "Michael",
            "lastName": "Cahill",
            "fullName": "Michael Cahill",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377079.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 10-lower-ocd-person-facb5409-244f-4381-8452-e6a1078bb1c5",
              "builtID": "nh-lower-rockingham 10",
              "externalID": "ocd-person/facb5409-244f-4381-8452-e6a1078bb1c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.cahill@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=687",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377079"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 10-lower-ocd-person-75a682ff-407d-4192-858d-9ce6a979c400": 0,
          "SLDL-NH-Rockingham 10-lower-ocd-person-77304532-17fb-4b2c-aed6-213a435e4a46": 1,
          "SLDL-NH-Rockingham 10-lower-ocd-person-facb5409-244f-4381-8452-e6a1078bb1c5": 2
        }
      },
      "Rockingham 9": {
        "members": [
          {
            "API_ID": "ocd-person/9debae73-c678-420c-a515-c7381e304d1c",
            "firstName": "Chris",
            "lastName": "True",
            "fullName": "Chris True",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377268.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 9",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 9-lower-ocd-person-9debae73-c678-420c-a515-c7381e304d1c",
              "builtID": "nh-lower-rockingham 9",
              "externalID": "ocd-person/9debae73-c678-420c-a515-c7381e304d1c",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.true@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=908",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377268"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/45e7b6d4-c265-40a9-ba77-47a7a454ed71",
            "firstName": "Tony",
            "lastName": "Piemonte",
            "fullName": "Tony Piemonte",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408902.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 9",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 9-lower-ocd-person-45e7b6d4-c265-40a9-ba77-47a7a454ed71",
              "builtID": "nh-lower-rockingham 9",
              "externalID": "ocd-person/45e7b6d4-c265-40a9-ba77-47a7a454ed71",
              "geometry": null
            },
            "contactInfo": {
              "email": "tony.piemonte@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9266",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9266"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 9-lower-ocd-person-9debae73-c678-420c-a515-c7381e304d1c": 0,
          "SLDL-NH-Rockingham 9-lower-ocd-person-45e7b6d4-c265-40a9-ba77-47a7a454ed71": 1
        }
      },
      "Hillsborough 8": {
        "members": [
          {
            "API_ID": "ocd-person/84fef36b-d3a0-4bb1-9458-7e7b165a51ff",
            "firstName": "Christal",
            "lastName": "Lloyd",
            "fullName": "Christal Lloyd",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://cdn.runforsomething.net/candidates/2022/09/campaign/3087.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 8-lower-ocd-person-84fef36b-d3a0-4bb1-9458-7e7b165a51ff",
              "builtID": "nh-lower-hillsborough 8",
              "externalID": "ocd-person/84fef36b-d3a0-4bb1-9458-7e7b165a51ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "christal.lloyd@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10783"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/3ffd7496-524f-40b6-92d8-0e932f002a90",
            "firstName": "Efstathia C.",
            "lastName": "Booras",
            "fullName": "Efstathia Booras",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377071.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 8-lower-ocd-person-3ffd7496-524f-40b6-92d8-0e932f002a90",
              "builtID": "nh-lower-hillsborough 8",
              "externalID": "ocd-person/3ffd7496-524f-40b6-92d8-0e932f002a90",
              "geometry": null
            },
            "contactInfo": {
              "email": "efstathia.booras@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377071",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=679",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=679"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/9c5c8165-5f7d-4348-9466-331f9b250536",
            "firstName": "Frances",
            "lastName": "Nutter-Upham",
            "fullName": "Fran Nutter-Upham",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://static1.squarespace.com/static/5b8f135350a54f0c91c966bf/t/5b8f1872898583a9bc7d84e3/1536195871798/idphoto2.jpeg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 8-lower-ocd-person-9c5c8165-5f7d-4348-9466-331f9b250536",
              "builtID": "nh-lower-hillsborough 8",
              "externalID": "ocd-person/9c5c8165-5f7d-4348-9466-331f9b250536",
              "geometry": null
            },
            "contactInfo": {
              "email": "fran.nutterupham@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9112",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9112"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 8-lower-ocd-person-84fef36b-d3a0-4bb1-9458-7e7b165a51ff": 0,
          "SLDL-NH-Hillsborough 8-lower-ocd-person-3ffd7496-524f-40b6-92d8-0e932f002a90": 1,
          "SLDL-NH-Hillsborough 8-lower-ocd-person-9c5c8165-5f7d-4348-9466-331f9b250536": 2
        }
      },
      "Hillsborough 21": {
        "members": [
          {
            "API_ID": "ocd-person/a05c5eab-d5d5-475d-a66a-39b8b514b8e6",
            "firstName": "Christine",
            "lastName": "Seibert",
            "fullName": "Christine Seibert",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://christine4nh.com/christine_seibert_profile_icon_256.png",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 21",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 21-lower-ocd-person-a05c5eab-d5d5-475d-a66a-39b8b514b8e6",
              "builtID": "nh-lower-hillsborough 21",
              "externalID": "ocd-person/a05c5eab-d5d5-475d-a66a-39b8b514b8e6",
              "geometry": null
            },
            "contactInfo": {
              "email": "christine.seibert@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9077"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/903e02f2-e17d-41f4-bd94-f91557c0e1fd",
            "firstName": "Jeffrey P.",
            "lastName": "Goley",
            "fullName": "Jeffrey Goley",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376227.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 21",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 21-lower-ocd-person-903e02f2-e17d-41f4-bd94-f91557c0e1fd",
              "builtID": "nh-lower-hillsborough 21",
              "externalID": "ocd-person/903e02f2-e17d-41f4-bd94-f91557c0e1fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "jgoley03104@yahoo.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=460",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376227"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 21-lower-ocd-person-a05c5eab-d5d5-475d-a66a-39b8b514b8e6": 0,
          "SLDL-NH-Hillsborough 21-lower-ocd-person-903e02f2-e17d-41f4-bd94-f91557c0e1fd": 1
        }
      },
      "Hillsborough 24": {
        "members": [
          {
            "API_ID": "ocd-person/7eb393f4-db93-4fbd-95bc-3491d9a6ca93",
            "firstName": "Christopher",
            "lastName": "Herbert",
            "fullName": "Chris Herbert",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377303.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 24",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 24-lower-ocd-person-7eb393f4-db93-4fbd-95bc-3491d9a6ca93",
              "builtID": "nh-lower-hillsborough 24",
              "externalID": "ocd-person/7eb393f4-db93-4fbd-95bc-3491d9a6ca93",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.herbert@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=856"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/74ae3ef0-c1f3-48ce-a5a6-c9bc4661bf08",
            "firstName": "Donald J.",
            "lastName": "Bouchard",
            "fullName": "Don Bouchard",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408854.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 24",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 24-lower-ocd-person-74ae3ef0-c1f3-48ce-a5a6-c9bc4661bf08",
              "builtID": "nh-lower-hillsborough 24",
              "externalID": "ocd-person/74ae3ef0-c1f3-48ce-a5a6-c9bc4661bf08",
              "geometry": null
            },
            "contactInfo": {
              "email": "donald.bouchard@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9079",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9079"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 24-lower-ocd-person-7eb393f4-db93-4fbd-95bc-3491d9a6ca93": 0,
          "SLDL-NH-Hillsborough 24-lower-ocd-person-74ae3ef0-c1f3-48ce-a5a6-c9bc4661bf08": 1
        }
      },
      "Strafford 8": {
        "members": [
          {
            "API_ID": "ocd-person/152fbcf7-564b-483f-b6b3-aa3f0cae47ee",
            "firstName": "Chuck",
            "lastName": "Grassie",
            "fullName": "Chuck Grassie",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408725.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 8-lower-ocd-person-152fbcf7-564b-483f-b6b3-aa3f0cae47ee",
              "builtID": "nh-lower-strafford 8",
              "externalID": "ocd-person/152fbcf7-564b-483f-b6b3-aa3f0cae47ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "chuck.grassie@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8725",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=8725"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 8-lower-ocd-person-152fbcf7-564b-483f-b6b3-aa3f0cae47ee": 0
        }
      },
      "Strafford 2": {
        "members": [
          {
            "API_ID": "ocd-person/bfecb4c6-509b-4c23-9c69-0e21483c44d3",
            "firstName": "Claudine",
            "lastName": "Burnham",
            "fullName": "Claudine Burnham",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/ClaudineBurnham.jfif",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 2-lower-ocd-person-bfecb4c6-509b-4c23-9c69-0e21483c44d3",
              "builtID": "nh-lower-strafford 2",
              "externalID": "ocd-person/bfecb4c6-509b-4c23-9c69-0e21483c44d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "claudine.burnham@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10713"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/56f58b74-b3b6-4f73-ad18-15af5119c4bc",
            "firstName": "Glenn",
            "lastName": "Bailey",
            "fullName": "Glenn Bailey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://img1.wsimg.com/isteam/ip/c57a3ba3-f1b7-4cb6-8681-75784530d1f4/glennbailey1.jpeg/:/",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 2-lower-ocd-person-56f58b74-b3b6-4f73-ad18-15af5119c4bc",
              "builtID": "nh-lower-strafford 2",
              "externalID": "ocd-person/56f58b74-b3b6-4f73-ad18-15af5119c4bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "grbailey@metrocast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9946",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9946"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/6d72216e-2f3d-4c60-9ce0-dc2c365d2033",
            "firstName": "Michael",
            "lastName": "Granger",
            "fullName": "Michael Granger",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-09/michael-granger.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 2-lower-ocd-person-6d72216e-2f3d-4c60-9ce0-dc2c365d2033",
              "builtID": "nh-lower-strafford 2",
              "externalID": "ocd-person/6d72216e-2f3d-4c60-9ce0-dc2c365d2033",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.granger@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10714"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 2-lower-ocd-person-bfecb4c6-509b-4c23-9c69-0e21483c44d3": 0,
          "SLDL-NH-Strafford 2-lower-ocd-person-56f58b74-b3b6-4f73-ad18-15af5119c4bc": 1,
          "SLDL-NH-Strafford 2-lower-ocd-person-6d72216e-2f3d-4c60-9ce0-dc2c365d2033": 2
        }
      },
      "Merrimack 13": {
        "members": [
          {
            "API_ID": "ocd-person/2bf4a05c-14f0-4916-964b-48bdb20f4fdd",
            "firstName": "Clayton",
            "lastName": "Wood",
            "fullName": "Clayton Wood",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409188.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 13-lower-ocd-person-2bf4a05c-14f0-4916-964b-48bdb20f4fdd",
              "builtID": "nh-lower-merrimack 13",
              "externalID": "ocd-person/2bf4a05c-14f0-4916-964b-48bdb20f4fdd",
              "geometry": null
            },
            "contactInfo": {
              "email": "clayton.wood@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10678"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e4ac4259-d405-4839-af31-6c409719065c",
            "firstName": "Cyril Nicholas",
            "lastName": "Aures",
            "fullName": "Cy Aures",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409061.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 13-lower-ocd-person-e4ac4259-d405-4839-af31-6c409719065c",
              "builtID": "nh-lower-merrimack 13",
              "externalID": "ocd-person/e4ac4259-d405-4839-af31-6c409719065c",
              "geometry": null
            },
            "contactInfo": {
              "email": "cyril.aures@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10677"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 13-lower-ocd-person-2bf4a05c-14f0-4916-964b-48bdb20f4fdd": 0,
          "SLDL-NH-Merrimack 13-lower-ocd-person-e4ac4259-d405-4839-af31-6c409719065c": 1
        }
      },
      "Strafford 6": {
        "members": [
          {
            "API_ID": "ocd-person/29d53319-cb0c-4b0c-bfac-14a694226bb8",
            "firstName": "Clifford A.",
            "lastName": "Newton",
            "fullName": "Cliff Newton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gannett-cdn.com/presto/2020/10/22/NFDD/4cd362d9-a3b7-4fdd-af54-40497b98fa28-NHDOV-102320-Cliff-Newton.jpg?width=300&height=355&fit=crop&format=pjpg&auto=webp",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 6-lower-ocd-person-29d53319-cb0c-4b0c-bfac-14a694226bb8",
              "builtID": "nh-lower-strafford 6",
              "externalID": "ocd-person/29d53319-cb0c-4b0c-bfac-14a694226bb8",
              "geometry": null
            },
            "contactInfo": {
              "email": "clifford.newton@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=5830",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=5830"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 6-lower-ocd-person-29d53319-cb0c-4b0c-bfac-14a694226bb8": 0
        }
      },
      "Merrimack 16": {
        "members": [
          {
            "API_ID": "ocd-person/8171a035-7cc1-4a7f-b086-9bdb800782da",
            "firstName": "Connie Boyles",
            "lastName": "Lane",
            "fullName": "Connie Lane",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://i1.wp.com/orr-reno.com/wp-content/uploads/2016/09/Connie-Boyles-Lane.png?w=262&ssl=1",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 16",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 16-lower-ocd-person-8171a035-7cc1-4a7f-b086-9bdb800782da",
              "builtID": "nh-lower-merrimack 16",
              "externalID": "ocd-person/8171a035-7cc1-4a7f-b086-9bdb800782da",
              "geometry": null
            },
            "contactInfo": {
              "email": "connie.lane@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9156",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9156"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 16-lower-ocd-person-8171a035-7cc1-4a7f-b086-9bdb800782da": 0
        }
      },
      "Coos 5": {
        "members": [
          {
            "API_ID": "ocd-person/ab2a31f1-bacd-4d84-9d33-0af4fd11c99c",
            "firstName": "Corinne E.",
            "lastName": "Cascadden",
            "fullName": "Corinne Cascadden",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409078.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Coos 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Coos 5-lower-ocd-person-ab2a31f1-bacd-4d84-9d33-0af4fd11c99c",
              "builtID": "nh-lower-coos 5",
              "externalID": "ocd-person/ab2a31f1-bacd-4d84-9d33-0af4fd11c99c",
              "geometry": null
            },
            "contactInfo": {
              "email": "corinne.cascadden@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9754"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8dda050b-ba91-4808-bedf-e441c7e05663",
            "firstName": "Henry",
            "lastName": "Noel",
            "fullName": "Henry Noël",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://bloximages.newyork1.vip.townnews.com/conwaydailysun.com/content/tncms/assets/v3/editorial/b/98/b983cd4a-d63a-11e8-94f8-4fc158f5b24c/5bce36074557c.image.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Coos 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Coos 5-lower-ocd-person-8dda050b-ba91-4808-bedf-e441c7e05663",
              "builtID": "nh-lower-coos 5",
              "externalID": "ocd-person/8dda050b-ba91-4808-bedf-e441c7e05663",
              "geometry": null
            },
            "contactInfo": {
              "email": "henry.noel@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9413",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9413"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Coos 5-lower-ocd-person-ab2a31f1-bacd-4d84-9d33-0af4fd11c99c": 0,
          "SLDL-NH-Coos 5-lower-ocd-person-8dda050b-ba91-4808-bedf-e441c7e05663": 1
        }
      },
      "Grafton 9": {
        "members": [
          {
            "API_ID": "ocd-person/850748c6-b79d-46a1-ada9-5d3bcb8e6a72",
            "firstName": "Corinne",
            "lastName": "Morse",
            "fullName": "Corinne Morse",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://static.wixstatic.com/media/fa8a50_fd856055d542413395443b866f5166aa~mv2.jpg/v1/fill/w_200,h_200,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Corinne-005_edited.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 9",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 9-lower-ocd-person-850748c6-b79d-46a1-ada9-5d3bcb8e6a72",
              "builtID": "nh-lower-grafton 9",
              "externalID": "ocd-person/850748c6-b79d-46a1-ada9-5d3bcb8e6a72",
              "geometry": null
            },
            "contactInfo": {
              "email": "corinne.morse@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10767"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 9-lower-ocd-person-850748c6-b79d-46a1-ada9-5d3bcb8e6a72": 0
        }
      },
      "Hillsborough 40": {
        "members": [
          {
            "API_ID": "ocd-person/2bfb554a-fb3f-48c0-b1fb-613edf2b7529",
            "firstName": "Damond T.",
            "lastName": "Ford",
            "fullName": "Damond Ford",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://d1fdloi71mui9q.cloudfront.net/hiWRzNmhRdGMaDlt15wj_ViRI1qgAEyOWHlK2",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 40",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 40-lower-ocd-person-2bfb554a-fb3f-48c0-b1fb-613edf2b7529",
              "builtID": "nh-lower-hillsborough 40",
              "externalID": "ocd-person/2bfb554a-fb3f-48c0-b1fb-613edf2b7529",
              "geometry": null
            },
            "contactInfo": {
              "email": "damond.ford@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10819"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/69275b32-0ad6-48a4-9787-bf2869573772",
            "firstName": "Mark S.",
            "lastName": "MacKenzie",
            "fullName": "Mark MacKenzie",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408516.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 40",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 40-lower-ocd-person-69275b32-0ad6-48a4-9787-bf2869573772",
              "builtID": "nh-lower-hillsborough 40",
              "externalID": "ocd-person/69275b32-0ad6-48a4-9787-bf2869573772",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.mackenzie@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8516",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408516"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/61d2e8f3-f2b9-4357-81af-fe982ee1821f",
            "firstName": "Matthew B.",
            "lastName": "Wilhelm",
            "fullName": "Matt Wilhelm",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408887.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 40",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 40-lower-ocd-person-61d2e8f3-f2b9-4357-81af-fe982ee1821f",
              "builtID": "nh-lower-hillsborough 40",
              "externalID": "ocd-person/61d2e8f3-f2b9-4357-81af-fe982ee1821f",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.wilhelm@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9134",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9134"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/6164e59b-1d52-4ee4-aa53-28ea86884757",
            "firstName": "Trinidad",
            "lastName": "Tellez",
            "fullName": "Trinidad Tellez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://i0.wp.com/healthequitystrategiesllc.com/wp-content/uploads/2021/06/trini-headshot-pink.jpeg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 40",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 40-lower-ocd-person-6164e59b-1d52-4ee4-aa53-28ea86884757",
              "builtID": "nh-lower-hillsborough 40",
              "externalID": "ocd-person/6164e59b-1d52-4ee4-aa53-28ea86884757",
              "geometry": null
            },
            "contactInfo": {
              "email": "trinidad.tellez@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10820"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 40-lower-ocd-person-2bfb554a-fb3f-48c0-b1fb-613edf2b7529": 0,
          "SLDL-NH-Hillsborough 40-lower-ocd-person-69275b32-0ad6-48a4-9787-bf2869573772": 1,
          "SLDL-NH-Hillsborough 40-lower-ocd-person-61d2e8f3-f2b9-4357-81af-fe982ee1821f": 2,
          "SLDL-NH-Hillsborough 40-lower-ocd-person-6164e59b-1d52-4ee4-aa53-28ea86884757": 3
        }
      },
      "Merrimack 14": {
        "members": [
          {
            "API_ID": "ocd-person/f67d5fcc-0dac-4bf0-b1ab-b63f73162d0b",
            "firstName": "Daniel",
            "lastName": "McGuire",
            "fullName": "Dan McGuire",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376984.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 14",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 14-lower-ocd-person-f67d5fcc-0dac-4bf0-b1ab-b63f73162d0b",
              "builtID": "nh-lower-merrimack 14",
              "externalID": "ocd-person/f67d5fcc-0dac-4bf0-b1ab-b63f73162d0b",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.mcguire@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=637",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376984"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 14-lower-ocd-person-f67d5fcc-0dac-4bf0-b1ab-b63f73162d0b": 0
        }
      },
      "Merrimack 7": {
        "members": [
          {
            "API_ID": "ocd-person/d24c83af-3362-4925-9c05-a6b776d3314c",
            "firstName": "Dan",
            "lastName": "Wolf",
            "fullName": "Dan Wolf",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/802694.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 7",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 7-lower-ocd-person-d24c83af-3362-4925-9c05-a6b776d3314c",
              "builtID": "nh-lower-merrimack 7",
              "externalID": "ocd-person/d24c83af-3362-4925-9c05-a6b776d3314c",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.wolf@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=3714",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=802694"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/947e2e2b-04eb-4f92-9f83-aef8760517e0",
            "firstName": "Karen",
            "lastName": "Ebel",
            "fullName": "Karen Ebel",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377093.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 7",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 7-lower-ocd-person-947e2e2b-04eb-4f92-9f83-aef8760517e0",
              "builtID": "nh-lower-merrimack 7",
              "externalID": "ocd-person/947e2e2b-04eb-4f92-9f83-aef8760517e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "karen.ebel@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=700",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377093"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 7-lower-ocd-person-d24c83af-3362-4925-9c05-a6b776d3314c": 0,
          "SLDL-NH-Merrimack 7-lower-ocd-person-947e2e2b-04eb-4f92-9f83-aef8760517e0": 1
        }
      },
      "Cheshire 9": {
        "members": [
          {
            "API_ID": "ocd-person/566f423e-75f7-474c-b57f-7e53bffcd411",
            "firstName": "Daniel Adams",
            "lastName": "Eaton",
            "fullName": "Daniel Eaton",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/364729.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 9",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 9-lower-ocd-person-566f423e-75f7-474c-b57f-7e53bffcd411",
              "builtID": "nh-lower-cheshire 9",
              "externalID": "ocd-person/566f423e-75f7-474c-b57f-7e53bffcd411",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.eaton@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=409",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=364729"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 9-lower-ocd-person-566f423e-75f7-474c-b57f-7e53bffcd411": 0
        }
      },
      "Hillsborough 34": {
        "members": [
          {
            "API_ID": "ocd-person/ce0bbcca-54b7-40f4-817f-4f17e7a212a1",
            "firstName": "Daniel",
            "lastName": "LeClerc",
            "fullName": "Dan LeClerc",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Daniel-LeClerc.PNG",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 34",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 34-lower-ocd-person-ce0bbcca-54b7-40f4-817f-4f17e7a212a1",
              "builtID": "nh-lower-hillsborough 34",
              "externalID": "ocd-person/ce0bbcca-54b7-40f4-817f-4f17e7a212a1",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.leclerc@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10814"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/88428a5a-4af6-40b8-a4ff-c2ea283b758f",
            "firstName": "Daniel T.",
            "lastName": "Veilleux",
            "fullName": "Dan Veilleux",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409005.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 34",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 34-lower-ocd-person-88428a5a-4af6-40b8-a4ff-c2ea283b758f",
              "builtID": "nh-lower-hillsborough 34",
              "externalID": "ocd-person/88428a5a-4af6-40b8-a4ff-c2ea283b758f",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.veilleux@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9905",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9905"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/20439d4f-c097-48b8-82c0-256b411b3b10",
            "firstName": "Jennifer",
            "lastName": "Morton",
            "fullName": "Jenn Morton",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-09/jennifer-morton.jpg?itok=o6zSPDsk",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 34",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 34-lower-ocd-person-20439d4f-c097-48b8-82c0-256b411b3b10",
              "builtID": "nh-lower-hillsborough 34",
              "externalID": "ocd-person/20439d4f-c097-48b8-82c0-256b411b3b10",
              "geometry": null
            },
            "contactInfo": {
              "email": "jennifer.morton@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10815"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 34-lower-ocd-person-ce0bbcca-54b7-40f4-817f-4f17e7a212a1": 0,
          "SLDL-NH-Hillsborough 34-lower-ocd-person-88428a5a-4af6-40b8-a4ff-c2ea283b758f": 1,
          "SLDL-NH-Hillsborough 34-lower-ocd-person-20439d4f-c097-48b8-82c0-256b411b3b10": 2
        }
      },
      "Strafford 19": {
        "members": [
          {
            "API_ID": "ocd-person/fecba997-12f3-40fa-83e1-7add4778a042",
            "firstName": "Daniel W.",
            "lastName": "Fitzpatrick",
            "fullName": "Dan Fitzpatrick",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://news.rochesternh.gov/wp-content/uploads/2021/08/external-content.duckduckgo-768x571.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 19",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 19-lower-ocd-person-fecba997-12f3-40fa-83e1-7add4778a042",
              "builtID": "nh-lower-strafford 19",
              "externalID": "ocd-person/fecba997-12f3-40fa-83e1-7add4778a042",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.fitzpatrick@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10901"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ed400d18-bf08-4b30-90e3-b7f9b44b5ce3",
            "firstName": "James",
            "lastName": "Connor",
            "fullName": "Jim Connor",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-08/jim-connor.jpg?itok=LT81Eepw",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 19",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 19-lower-ocd-person-ed400d18-bf08-4b30-90e3-b7f9b44b5ce3",
              "builtID": "nh-lower-strafford 19",
              "externalID": "ocd-person/ed400d18-bf08-4b30-90e3-b7f9b44b5ce3",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.connor@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10725"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b9b6f587-2395-497d-8f04-dfaae1b3d7f4",
            "firstName": "Kelley",
            "lastName": "Potenza",
            "fullName": "Kelley Potenza",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409156.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 19",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 19-lower-ocd-person-b9b6f587-2395-497d-8f04-dfaae1b3d7f4",
              "builtID": "nh-lower-strafford 19",
              "externalID": "ocd-person/b9b6f587-2395-497d-8f04-dfaae1b3d7f4",
              "geometry": null
            },
            "contactInfo": {
              "email": "kelley.potenza@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10726"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 19-lower-ocd-person-fecba997-12f3-40fa-83e1-7add4778a042": 0,
          "SLDL-NH-Strafford 19-lower-ocd-person-ed400d18-bf08-4b30-90e3-b7f9b44b5ce3": 1,
          "SLDL-NH-Strafford 19-lower-ocd-person-b9b6f587-2395-497d-8f04-dfaae1b3d7f4": 2
        }
      },
      "Merrimack 3": {
        "members": [
          {
            "API_ID": "ocd-person/a9da1843-ceb9-40c6-9846-a3c3d5319ccd",
            "firstName": "Dave",
            "lastName": "Testerman",
            "fullName": "Dave Testerman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408369.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 3",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 3-lower-ocd-person-a9da1843-ceb9-40c6-9846-a3c3d5319ccd",
              "builtID": "nh-lower-merrimack 3",
              "externalID": "ocd-person/a9da1843-ceb9-40c6-9846-a3c3d5319ccd",
              "geometry": null
            },
            "contactInfo": {
              "email": "dave@sanbornhall.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8369",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=408369"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/7d004604-9d6b-4b7f-a288-f29566d9b9fe",
            "firstName": "James",
            "lastName": "Mason",
            "fullName": "Jim Mason",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/candidate-images/jmasonmh2.jpg?itok=TiDjfgTu",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 3",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 3-lower-ocd-person-7d004604-9d6b-4b7f-a288-f29566d9b9fe",
              "builtID": "nh-lower-merrimack 3",
              "externalID": "ocd-person/7d004604-9d6b-4b7f-a288-f29566d9b9fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.mason@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9921",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9921"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 3-lower-ocd-person-a9da1843-ceb9-40c6-9846-a3c3d5319ccd": 0,
          "SLDL-NH-Merrimack 3-lower-ocd-person-7d004604-9d6b-4b7f-a288-f29566d9b9fe": 1
        }
      },
      "Strafford 3": {
        "members": [
          {
            "API_ID": "ocd-person/e31ccc42-68c0-4f1c-b78d-c857f0060df0",
            "firstName": "David A.",
            "lastName": "Bickford",
            "fullName": "Dave Bickford",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376083.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 3",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 3-lower-ocd-person-e31ccc42-68c0-4f1c-b78d-c857f0060df0",
              "builtID": "nh-lower-strafford 3",
              "externalID": "ocd-person/e31ccc42-68c0-4f1c-b78d-c857f0060df0",
              "geometry": null
            },
            "contactInfo": {
              "email": "davidabickford51@yahoo.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=442"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 3-lower-ocd-person-e31ccc42-68c0-4f1c-b78d-c857f0060df0": 0
        }
      },
      "Rockingham 16": {
        "members": [
          {
            "API_ID": "ocd-person/4e9bb139-f952-4fda-bce1-f1bb16c585e3",
            "firstName": "David C.",
            "lastName": "Lundgren",
            "fullName": "David Lundgren",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376972.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 16",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 16-lower-ocd-person-4e9bb139-f952-4fda-bce1-f1bb16c585e3",
              "builtID": "nh-lower-rockingham 16",
              "externalID": "ocd-person/4e9bb139-f952-4fda-bce1-f1bb16c585e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "qtipnh@aol.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=635",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376972"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/5db11498-80a8-4136-9c50-2c7f1a2f6826",
            "firstName": "Douglas",
            "lastName": "Thomas",
            "fullName": "Doug Thomas",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377267.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 16",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 16-lower-ocd-person-5db11498-80a8-4136-9c50-2c7f1a2f6826",
              "builtID": "nh-lower-rockingham 16",
              "externalID": "ocd-person/5db11498-80a8-4136-9c50-2c7f1a2f6826",
              "geometry": null
            },
            "contactInfo": {
              "email": "doug.thomasnh@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=907",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377267"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ea8d6a3b-8312-4ffe-a85a-e8a9776ec560",
            "firstName": "Kristine",
            "lastName": "Perez",
            "fullName": "Kristine Perez",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409150.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 16",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 16-lower-ocd-person-ea8d6a3b-8312-4ffe-a85a-e8a9776ec560",
              "builtID": "nh-lower-rockingham 16",
              "externalID": "ocd-person/ea8d6a3b-8312-4ffe-a85a-e8a9776ec560",
              "geometry": null
            },
            "contactInfo": {
              "email": "kristine.perez@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10694"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e0b29cba-9dd9-4e90-889c-e5eaea7d174c",
            "firstName": "Ron",
            "lastName": "Dunn",
            "fullName": "Ron Dunn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.voterondunn.com/images/109810/Ron_Dunn.png",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 16",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 16-lower-ocd-person-e0b29cba-9dd9-4e90-889c-e5eaea7d174c",
              "builtID": "nh-lower-rockingham 16",
              "externalID": "ocd-person/e0b29cba-9dd9-4e90-889c-e5eaea7d174c",
              "geometry": null
            },
            "contactInfo": {
              "email": "ron.dunn@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10693"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e94c1d98-5454-40a2-8293-ee0998fee8ca",
            "firstName": "Sherman Adams",
            "lastName": "Packard",
            "fullName": "Sherm Packard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/375453.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 16",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 16-lower-ocd-person-e94c1d98-5454-40a2-8293-ee0998fee8ca",
              "builtID": "nh-lower-rockingham 16",
              "externalID": "ocd-person/e94c1d98-5454-40a2-8293-ee0998fee8ca",
              "geometry": null
            },
            "contactInfo": {
              "email": "sherman.packard@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=425",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=375453"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/330b6b4b-dfa6-4379-ae45-bedd9b276fcd",
            "firstName": "Tom",
            "lastName": "Dolan",
            "fullName": "Tom Dolan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/candidate-images/TomDolan.jpg?itok=7APGZQJ2",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 16",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 16-lower-ocd-person-330b6b4b-dfa6-4379-ae45-bedd9b276fcd",
              "builtID": "nh-lower-rockingham 16",
              "externalID": "ocd-person/330b6b4b-dfa6-4379-ae45-bedd9b276fcd",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.dolan@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9268",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9268"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8588302c-ac8c-4b58-9444-0c9f29448aa0",
            "firstName": "Wayne D.",
            "lastName": "MacDonald",
            "fullName": "Wayne MacDonald",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409051.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 16",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 16-lower-ocd-person-8588302c-ac8c-4b58-9444-0c9f29448aa0",
              "builtID": "nh-lower-rockingham 16",
              "externalID": "ocd-person/8588302c-ac8c-4b58-9444-0c9f29448aa0",
              "geometry": null
            },
            "contactInfo": {
              "email": "elephantsmarching@msn.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9951",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9951"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 16-lower-ocd-person-4e9bb139-f952-4fda-bce1-f1bb16c585e3": 0,
          "SLDL-NH-Rockingham 16-lower-ocd-person-5db11498-80a8-4136-9c50-2c7f1a2f6826": 1,
          "SLDL-NH-Rockingham 16-lower-ocd-person-ea8d6a3b-8312-4ffe-a85a-e8a9776ec560": 2,
          "SLDL-NH-Rockingham 16-lower-ocd-person-e0b29cba-9dd9-4e90-889c-e5eaea7d174c": 3,
          "SLDL-NH-Rockingham 16-lower-ocd-person-e94c1d98-5454-40a2-8293-ee0998fee8ca": 4,
          "SLDL-NH-Rockingham 16-lower-ocd-person-330b6b4b-dfa6-4379-ae45-bedd9b276fcd": 5,
          "SLDL-NH-Rockingham 16-lower-ocd-person-8588302c-ac8c-4b58-9444-0c9f29448aa0": 6
        }
      },
      "Grafton 16": {
        "members": [
          {
            "API_ID": "ocd-person/01e401fa-3dca-460b-8eac-080f6e7fce90",
            "firstName": "David",
            "lastName": "Fracht",
            "fullName": "David Fracht",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://indepthnh.org/wp-content/uploads/2023/08/F4LEEdwW0AEfuby.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 16",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 16-lower-ocd-person-01e401fa-3dca-460b-8eac-080f6e7fce90",
              "builtID": "nh-lower-grafton 16",
              "externalID": "ocd-person/01e401fa-3dca-460b-8eac-080f6e7fce90",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.fracht@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=11041",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=11041"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 16-lower-ocd-person-01e401fa-3dca-460b-8eac-080f6e7fce90": 0
        }
      },
      "Belknap 6": {
        "members": [
          {
            "API_ID": "ocd-person/c614a6b4-5a2b-46ca-89c0-1ff666aef92a",
            "firstName": "David",
            "lastName": "Nagel",
            "fullName": "Dave Nagel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-09/david-nagel.jpg?itok=_dKC7l45",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 6-lower-ocd-person-c614a6b4-5a2b-46ca-89c0-1ff666aef92a",
              "builtID": "nh-lower-belknap 6",
              "externalID": "ocd-person/c614a6b4-5a2b-46ca-89c0-1ff666aef92a",
              "geometry": null
            },
            "contactInfo": {
              "email": "davidnagelmd@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10574",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10574"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/5a633278-e4a7-4a58-9c9d-2d917deefd40",
            "firstName": "Harry H.",
            "lastName": "Bean",
            "fullName": "Harry Bean",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408817.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 6-lower-ocd-person-5a633278-e4a7-4a58-9c9d-2d917deefd40",
              "builtID": "nh-lower-belknap 6",
              "externalID": "ocd-person/5a633278-e4a7-4a58-9c9d-2d917deefd40",
              "geometry": null
            },
            "contactInfo": {
              "email": "harry.bean@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9409",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9409"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/31db4686-36fa-4933-b965-3c62c87651b5",
            "firstName": "Richard",
            "lastName": "Beaudoin",
            "fullName": "Richard Beaudoin",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 6-lower-ocd-person-31db4686-36fa-4933-b965-3c62c87651b5",
              "builtID": "nh-lower-belknap 6",
              "externalID": "ocd-person/31db4686-36fa-4933-b965-3c62c87651b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rbbmere25@aol.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9431"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/9aae937c-2a6d-4654-a18e-d76e1b379e51",
            "firstName": "Russell",
            "lastName": "Dumais",
            "fullName": "Russ Dumais",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.newyork1.vip.townnews.com/laconiadailysun.com/content/tncms/assets/v3/editorial/3/33/3330e0b6-5949-11ed-9877-0b2d40bf1fa8/636012e676cdb.image.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 6-lower-ocd-person-9aae937c-2a6d-4654-a18e-d76e1b379e51",
              "builtID": "nh-lower-belknap 6",
              "externalID": "ocd-person/9aae937c-2a6d-4654-a18e-d76e1b379e51",
              "geometry": null
            },
            "contactInfo": {
              "email": "russell.dumais@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=807",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377221"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Belknap 6-lower-ocd-person-c614a6b4-5a2b-46ca-89c0-1ff666aef92a": 0,
          "SLDL-NH-Belknap 6-lower-ocd-person-5a633278-e4a7-4a58-9c9d-2d917deefd40": 1,
          "SLDL-NH-Belknap 6-lower-ocd-person-31db4686-36fa-4933-b965-3c62c87651b5": 2,
          "SLDL-NH-Belknap 6-lower-ocd-person-9aae937c-2a6d-4654-a18e-d76e1b379e51": 3
        }
      },
      "Hillsborough 17": {
        "members": [
          {
            "API_ID": "ocd-person/d347f91a-d60c-48a2-b78b-8e387e7b38a1",
            "firstName": "David",
            "lastName": "Preece",
            "fullName": "David Preece",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/801430.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 17",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 17-lower-ocd-person-d347f91a-d60c-48a2-b78b-8e387e7b38a1",
              "builtID": "nh-lower-hillsborough 17",
              "externalID": "ocd-person/d347f91a-d60c-48a2-b78b-8e387e7b38a1",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.preece@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=2458"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a9f75dde-f348-4c7a-90c9-a785ee2de208",
            "firstName": "Linda",
            "lastName": "DiSilvestro",
            "fullName": "Linda DiSilvestro",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377091.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 17",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 17-lower-ocd-person-a9f75dde-f348-4c7a-90c9-a785ee2de208",
              "builtID": "nh-lower-hillsborough 17",
              "externalID": "ocd-person/a9f75dde-f348-4c7a-90c9-a785ee2de208",
              "geometry": null
            },
            "contactInfo": {
              "email": "linda.disilvestro@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=698",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377091"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 17-lower-ocd-person-d347f91a-d60c-48a2-b78b-8e387e7b38a1": 0,
          "SLDL-NH-Hillsborough 17-lower-ocd-person-a9f75dde-f348-4c7a-90c9-a785ee2de208": 1
        }
      },
      "Rockingham 37": {
        "members": [
          {
            "API_ID": "ocd-person/4b449f0f-2024-4e18-bbbd-7f0bbcfa8cac",
            "firstName": "David",
            "lastName": "Meuse",
            "fullName": "David Meuse",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/candidate-images/davidmeuse.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 37",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 37-lower-ocd-person-4b449f0f-2024-4e18-bbbd-7f0bbcfa8cac",
              "builtID": "nh-lower-rockingham 37",
              "externalID": "ocd-person/4b449f0f-2024-4e18-bbbd-7f0bbcfa8cac",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.meuse@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9226",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9226"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 37-lower-ocd-person-4b449f0f-2024-4e18-bbbd-7f0bbcfa8cac": 0
        }
      },
      "Carroll 1": {
        "members": [
          {
            "API_ID": "ocd-person/09308be5-c800-42a8-b0bf-afbc877ab976",
            "firstName": "David",
            "lastName": "Paige",
            "fullName": "David Paige",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.davidfornh.com/wp-content/uploads/sites/40/2022/06/david-paige3-683x1024.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Carroll 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Carroll 1-lower-ocd-person-09308be5-c800-42a8-b0bf-afbc877ab976",
              "builtID": "nh-lower-carroll 1",
              "externalID": "ocd-person/09308be5-c800-42a8-b0bf-afbc877ab976",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.paige@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10741",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10741"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/bdde38b3-3210-4b2d-890b-bbf2d2bf314a",
            "firstName": "Stephen",
            "lastName": "Woodcock",
            "fullName": "Steve Woodcock",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://bloximages.newyork1.vip.townnews.com/conwaydailysun.com/content/tncms/assets/v3/editorial/8/cc/8cca2b78-6d85-11e8-9ca7-6bd6b0015d75/5b1e8a49f1b8e.image.jpg?resize=1200%2C1500",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Carroll 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Carroll 1-lower-ocd-person-bdde38b3-3210-4b2d-890b-bbf2d2bf314a",
              "builtID": "nh-lower-carroll 1",
              "externalID": "ocd-person/bdde38b3-3210-4b2d-890b-bbf2d2bf314a",
              "geometry": null
            },
            "contactInfo": {
              "email": "stevewoodcock.rep@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9046",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9046"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/22809c1b-0676-4ad9-8d0e-8a0a9d19063a",
            "firstName": "Thomas",
            "lastName": "Buco",
            "fullName": "Tom Buco",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376645.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Carroll 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Carroll 1-lower-ocd-person-22809c1b-0676-4ad9-8d0e-8a0a9d19063a",
              "builtID": "nh-lower-carroll 1",
              "externalID": "ocd-person/22809c1b-0676-4ad9-8d0e-8a0a9d19063a",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.buco@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=523",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376645"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Carroll 1-lower-ocd-person-09308be5-c800-42a8-b0bf-afbc877ab976": 0,
          "SLDL-NH-Carroll 1-lower-ocd-person-bdde38b3-3210-4b2d-890b-bbf2d2bf314a": 1,
          "SLDL-NH-Carroll 1-lower-ocd-person-22809c1b-0676-4ad9-8d0e-8a0a9d19063a": 2
        }
      },
      "Grafton 1": {
        "members": [
          {
            "API_ID": "ocd-person/e849b9fb-0e1d-4ed5-b684-2a6b3520be6a",
            "firstName": "David",
            "lastName": "Rochefort",
            "fullName": "David Rochefort",
            "gender": "Male",
            "party": "Republican",
            "image": "https://a4pc.org/files/Rochefort_July-2022-450x600.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 1-lower-ocd-person-e849b9fb-0e1d-4ed5-b684-2a6b3520be6a",
              "builtID": "nh-lower-grafton 1",
              "externalID": "ocd-person/e849b9fb-0e1d-4ed5-b684-2a6b3520be6a",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.rochefort@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10614"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c018f667-a248-4b79-b8be-63740191e1ff",
            "firstName": "Linda",
            "lastName": "Massimilla",
            "fullName": "Linda Massimilla",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377141.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 1-lower-ocd-person-c018f667-a248-4b79-b8be-63740191e1ff",
              "builtID": "nh-lower-grafton 1",
              "externalID": "ocd-person/c018f667-a248-4b79-b8be-63740191e1ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "linda.massimilla@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=746"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a7e2763e-dc4f-44de-9c6e-5bd2c3f525d0",
            "firstName": "Matthew",
            "lastName": "Simon",
            "fullName": "Matt Simon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.newyork1.vip.townnews.com/unionleader.com/content/tncms/assets/v3/editorial/4/78/47878caa-0002-5e3d-9b48-9254fa2b231d/63501002089de.image.jpg?resize=300%2C465",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 1-lower-ocd-person-a7e2763e-dc4f-44de-9c6e-5bd2c3f525d0",
              "builtID": "nh-lower-grafton 1",
              "externalID": "ocd-person/a7e2763e-dc4f-44de-9c6e-5bd2c3f525d0",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.simon@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9892",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9892"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 1-lower-ocd-person-e849b9fb-0e1d-4ed5-b684-2a6b3520be6a": 0,
          "SLDL-NH-Grafton 1-lower-ocd-person-c018f667-a248-4b79-b8be-63740191e1ff": 1,
          "SLDL-NH-Grafton 1-lower-ocd-person-a7e2763e-dc4f-44de-9c6e-5bd2c3f525d0": 2
        }
      },
      "Merrimack 5": {
        "members": [
          {
            "API_ID": "ocd-person/8015d8e4-78cd-41a5-93f9-f6a704910c2f",
            "firstName": "Deborah",
            "lastName": "Aylward",
            "fullName": "Deborah Aylward",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409062.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 5-lower-ocd-person-8015d8e4-78cd-41a5-93f9-f6a704910c2f",
              "builtID": "nh-lower-merrimack 5",
              "externalID": "ocd-person/8015d8e4-78cd-41a5-93f9-f6a704910c2f",
              "geometry": null
            },
            "contactInfo": {
              "email": "deborah.aylward@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10670"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4f84e968-9226-470f-bde4-4163efc19593",
            "firstName": "Louise",
            "lastName": "Andrus",
            "fullName": "Louise Andrus",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409020.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 5-lower-ocd-person-4f84e968-9226-470f-bde4-4163efc19593",
              "builtID": "nh-lower-merrimack 5",
              "externalID": "ocd-person/4f84e968-9226-470f-bde4-4163efc19593",
              "geometry": null
            },
            "contactInfo": {
              "email": "louise.andrus@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9920",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9920"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 5-lower-ocd-person-8015d8e4-78cd-41a5-93f9-f6a704910c2f": 0,
          "SLDL-NH-Merrimack 5-lower-ocd-person-4f84e968-9226-470f-bde4-4163efc19593": 1
        }
      },
      "Rockingham 14": {
        "members": [
          {
            "API_ID": "ocd-person/6e4aa2d5-d1c7-4009-950f-e5ebedea7189",
            "firstName": "Deborah L.",
            "lastName": "Hobson",
            "fullName": "Deb Hobson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://deb4nh.files.wordpress.com/2020/03/screenshot-2020-03-19-at-11.03.17-pm.png?w=232&h=300",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 14",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 14-lower-ocd-person-6e4aa2d5-d1c7-4009-950f-e5ebedea7189",
              "builtID": "nh-lower-rockingham 14",
              "externalID": "ocd-person/6e4aa2d5-d1c7-4009-950f-e5ebedea7189",
              "geometry": null
            },
            "contactInfo": {
              "email": "deborah.hobson@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9423",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9423"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e2f9dd7e-b221-4cf0-b7de-5daa70658669",
            "firstName": "Kenneth L.",
            "lastName": "Weyler",
            "fullName": "Ken Weyler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/374470.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 14",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 14-lower-ocd-person-e2f9dd7e-b221-4cf0-b7de-5daa70658669",
              "builtID": "nh-lower-rockingham 14",
              "externalID": "ocd-person/e2f9dd7e-b221-4cf0-b7de-5daa70658669",
              "geometry": null
            },
            "contactInfo": {
              "email": "kweyler@aol.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=422",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=374470"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 14-lower-ocd-person-6e4aa2d5-d1c7-4009-950f-e5ebedea7189": 0,
          "SLDL-NH-Rockingham 14-lower-ocd-person-e2f9dd7e-b221-4cf0-b7de-5daa70658669": 1
        }
      },
      "Rockingham 24": {
        "members": [
          {
            "API_ID": "ocd-person/d6200a81-3d99-4ff7-93e0-e2f3b49cfd65",
            "firstName": "Dennis J.",
            "lastName": "Malloy",
            "fullName": "Dennis Malloy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377137.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 24",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 24-lower-ocd-person-d6200a81-3d99-4ff7-93e0-e2f3b49cfd65",
              "builtID": "nh-lower-rockingham 24",
              "externalID": "ocd-person/d6200a81-3d99-4ff7-93e0-e2f3b49cfd65",
              "geometry": null
            },
            "contactInfo": {
              "email": "dennis.malloy@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=742",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377137"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/26f01212-5287-4735-aa1f-77e4f0d2acb6",
            "firstName": "Jacinthe",
            "lastName": "Grote",
            "fullName": "Jaci Grote",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408923.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 24",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 24-lower-ocd-person-26f01212-5287-4735-aa1f-77e4f0d2acb6",
              "builtID": "nh-lower-rockingham 24",
              "externalID": "ocd-person/26f01212-5287-4735-aa1f-77e4f0d2acb6",
              "geometry": null
            },
            "contactInfo": {
              "email": "jaci.grote@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9225",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408923"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 24-lower-ocd-person-d6200a81-3d99-4ff7-93e0-e2f3b49cfd65": 0,
          "SLDL-NH-Rockingham 24-lower-ocd-person-26f01212-5287-4735-aa1f-77e4f0d2acb6": 1
        }
      },
      "Rockingham 25": {
        "members": [
          {
            "API_ID": "ocd-person/4568bf8a-b56b-43ec-a00f-718341eb6cb9",
            "firstName": "Dennis",
            "lastName": "Mannion",
            "fullName": "Dennis Mannion",
            "gender": "Male",
            "party": "Republican",
            "image": "https://img1.wsimg.com/isteam/ip/ebfaec8d-b793-4eb6-ac39-a9bdf899e928/57475A20-CA58-4725-80DE-C7E21225874B.jpeg/:/cr=t:22.2%25,l:22.2%25,w:60.24%25,h:60.24%25/rs=w:1280,h:1707",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 25",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 25-lower-ocd-person-4568bf8a-b56b-43ec-a00f-718341eb6cb9",
              "builtID": "nh-lower-rockingham 25",
              "externalID": "ocd-person/4568bf8a-b56b-43ec-a00f-718341eb6cb9",
              "geometry": null
            },
            "contactInfo": {
              "email": "dennis.mannion@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10705"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/db4ec0a8-f70c-4510-ae01-c9050646a503",
            "firstName": "Fred",
            "lastName": "Doucette",
            "fullName": "Fred Doucette",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377220.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 25",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 25-lower-ocd-person-db4ec0a8-f70c-4510-ae01-c9050646a503",
              "builtID": "nh-lower-rockingham 25",
              "externalID": "ocd-person/db4ec0a8-f70c-4510-ae01-c9050646a503",
              "geometry": null
            },
            "contactInfo": {
              "email": "fred.doucette@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=893",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377220"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/568a287b-89c6-44b7-a8e6-a5188ef7f9bc",
            "firstName": "Joseph",
            "lastName": "Sweeney",
            "fullName": "Joe Sweeney",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377174.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 25",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 25-lower-ocd-person-568a287b-89c6-44b7-a8e6-a5188ef7f9bc",
              "builtID": "nh-lower-rockingham 25",
              "externalID": "ocd-person/568a287b-89c6-44b7-a8e6-a5188ef7f9bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.sweeney@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377174",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=779",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=779"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4c4f4974-0202-43a0-9aba-f41d0f99603a",
            "firstName": "John",
            "lastName": "Janigian",
            "fullName": "John Janigian",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408412.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 25",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 25-lower-ocd-person-4c4f4974-0202-43a0-9aba-f41d0f99603a",
              "builtID": "nh-lower-rockingham 25",
              "externalID": "ocd-person/4c4f4974-0202-43a0-9aba-f41d0f99603a",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.janigian@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8412",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408412"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/81a14b0b-f5dd-4e7a-b500-c9dc0a56c2be",
            "firstName": "John",
            "lastName": "Sytek",
            "fullName": "John Sytek",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/375692.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 25",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 25-lower-ocd-person-81a14b0b-f5dd-4e7a-b500-c9dc0a56c2be",
              "builtID": "nh-lower-rockingham 25",
              "externalID": "ocd-person/81a14b0b-f5dd-4e7a-b500-c9dc0a56c2be",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.sytek@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=426",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=375692"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b89dda18-b7c5-4184-b6d9-0bff9bed045e",
            "firstName": "Lorie",
            "lastName": "Ball",
            "fullName": "Lorie Ball",
            "gender": "Female",
            "party": "Republican",
            "image": "https://lorieball.com/assets/lorie-340x285.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 25",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 25-lower-ocd-person-b89dda18-b7c5-4184-b6d9-0bff9bed045e",
              "builtID": "nh-lower-rockingham 25",
              "externalID": "ocd-person/b89dda18-b7c5-4184-b6d9-0bff9bed045e",
              "geometry": null
            },
            "contactInfo": {
              "email": "lorie.ball@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10702"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b3316ac6-b023-4dec-9e07-33600f4027bb",
            "firstName": "Susan J.",
            "lastName": "Vandecasteele",
            "fullName": "Sue Vandecasteele",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2020-07/susan-j-vandecasteele.png?itok=r8hKgMhD",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 25",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 25-lower-ocd-person-b3316ac6-b023-4dec-9e07-33600f4027bb",
              "builtID": "nh-lower-rockingham 25",
              "externalID": "ocd-person/b3316ac6-b023-4dec-9e07-33600f4027bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "susanjonvan@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9938",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9938"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/93179238-e90a-433f-bafb-72333aa7c9ef",
            "firstName": "Tanya",
            "lastName": "Donnelly",
            "fullName": "Tanya Donnelly",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409089.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 25",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 25-lower-ocd-person-93179238-e90a-433f-bafb-72333aa7c9ef",
              "builtID": "nh-lower-rockingham 25",
              "externalID": "ocd-person/93179238-e90a-433f-bafb-72333aa7c9ef",
              "geometry": null
            },
            "contactInfo": {
              "email": "tanya.donnelly@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10704"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8c0d6811-045b-46ca-91c6-86e360249246",
            "firstName": "Valerie",
            "lastName": "McDonnell",
            "fullName": "Valerie McDonnell",
            "gender": "Female",
            "party": "Republican",
            "image": "https://mcdonnellforsalem.files.wordpress.com/2022/05/img-8527.jpg?w=1024",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 25",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 25-lower-ocd-person-8c0d6811-045b-46ca-91c6-86e360249246",
              "builtID": "nh-lower-rockingham 25",
              "externalID": "ocd-person/8c0d6811-045b-46ca-91c6-86e360249246",
              "geometry": null
            },
            "contactInfo": {
              "email": "valerie.mcdonnell@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10706"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 25-lower-ocd-person-4568bf8a-b56b-43ec-a00f-718341eb6cb9": 0,
          "SLDL-NH-Rockingham 25-lower-ocd-person-db4ec0a8-f70c-4510-ae01-c9050646a503": 1,
          "SLDL-NH-Rockingham 25-lower-ocd-person-568a287b-89c6-44b7-a8e6-a5188ef7f9bc": 2,
          "SLDL-NH-Rockingham 25-lower-ocd-person-4c4f4974-0202-43a0-9aba-f41d0f99603a": 3,
          "SLDL-NH-Rockingham 25-lower-ocd-person-81a14b0b-f5dd-4e7a-b500-c9dc0a56c2be": 4,
          "SLDL-NH-Rockingham 25-lower-ocd-person-b89dda18-b7c5-4184-b6d9-0bff9bed045e": 5,
          "SLDL-NH-Rockingham 25-lower-ocd-person-b3316ac6-b023-4dec-9e07-33600f4027bb": 6,
          "SLDL-NH-Rockingham 25-lower-ocd-person-93179238-e90a-433f-bafb-72333aa7c9ef": 7,
          "SLDL-NH-Rockingham 25-lower-ocd-person-8c0d6811-045b-46ca-91c6-86e360249246": 8
        }
      },
      "Hillsborough 32": {
        "members": [
          {
            "API_ID": "ocd-person/592ab77a-caf9-4132-ba45-effb3ecb012e",
            "firstName": "Diane",
            "lastName": "Kelley",
            "fullName": "Diane Kelley",
            "gender": "Female",
            "party": "Republican",
            "image": "https://static.votesmart.org/static/canphoto/195062.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 32",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 32-lower-ocd-person-592ab77a-caf9-4132-ba45-effb3ecb012e",
              "builtID": "nh-lower-hillsborough 32",
              "externalID": "ocd-person/592ab77a-caf9-4132-ba45-effb3ecb012e",
              "geometry": null
            },
            "contactInfo": {
              "email": "diane.kelley@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9909",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9909"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/fa8b39ea-006f-420a-9a3f-fe36d0db8776",
            "firstName": "Jim",
            "lastName": "Kofalt",
            "fullName": "Jim Kofalt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://static.votesmart.org/static/canphoto/154516.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 32",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 32-lower-ocd-person-fa8b39ea-006f-420a-9a3f-fe36d0db8776",
              "builtID": "nh-lower-hillsborough 32",
              "externalID": "ocd-person/fa8b39ea-006f-420a-9a3f-fe36d0db8776",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.kofalt@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9895",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9895"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a56b0587-b57f-4dbb-a977-ba82c714a379",
            "firstName": "Shane",
            "lastName": "Sirois",
            "fullName": "Shane Sirois",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409170.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 32",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 32-lower-ocd-person-a56b0587-b57f-4dbb-a977-ba82c714a379",
              "builtID": "nh-lower-hillsborough 32",
              "externalID": "ocd-person/a56b0587-b57f-4dbb-a977-ba82c714a379",
              "geometry": null
            },
            "contactInfo": {
              "email": "shane.sirois@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10653"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 32-lower-ocd-person-592ab77a-caf9-4132-ba45-effb3ecb012e": 0,
          "SLDL-NH-Hillsborough 32-lower-ocd-person-fa8b39ea-006f-420a-9a3f-fe36d0db8776": 1,
          "SLDL-NH-Hillsborough 32-lower-ocd-person-a56b0587-b57f-4dbb-a977-ba82c714a379": 2
        }
      },
      "Hillsborough 36": {
        "members": [
          {
            "API_ID": "ocd-person/7cc1d333-efac-47fb-a109-98113c762790",
            "firstName": "Diane",
            "lastName": "Pauer",
            "fullName": "Diane Pauer",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409010.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 36",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 36-lower-ocd-person-7cc1d333-efac-47fb-a109-98113c762790",
              "builtID": "nh-lower-hillsborough 36",
              "externalID": "ocd-person/7cc1d333-efac-47fb-a109-98113c762790",
              "geometry": null
            },
            "contactInfo": {
              "email": "diane.pauer@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9910",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9910"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/32401243-00a9-4a26-a2cb-0ebf497dbabc",
            "firstName": "John",
            "lastName": "Lewicke",
            "fullName": "John Lewicke",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408346.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 36",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 36-lower-ocd-person-32401243-00a9-4a26-a2cb-0ebf497dbabc",
              "builtID": "nh-lower-hillsborough 36",
              "externalID": "ocd-person/32401243-00a9-4a26-a2cb-0ebf497dbabc",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.lewicke@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8346",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=8346"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 36-lower-ocd-person-7cc1d333-efac-47fb-a109-98113c762790": 0,
          "SLDL-NH-Hillsborough 36-lower-ocd-person-32401243-00a9-4a26-a2cb-0ebf497dbabc": 1
        }
      },
      "Cheshire 12": {
        "members": [
          {
            "API_ID": "ocd-person/5e1bcd9f-9dd0-46b1-8c7f-b9e84bbba8e7",
            "firstName": "Dick",
            "lastName": "Thackston",
            "fullName": "Dick Thackston",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409190.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 12-lower-ocd-person-5e1bcd9f-9dd0-46b1-8c7f-b9e84bbba8e7",
              "builtID": "nh-lower-cheshire 12",
              "externalID": "ocd-person/5e1bcd9f-9dd0-46b1-8c7f-b9e84bbba8e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "thackston3@aol.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=6938"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 12-lower-ocd-person-5e1bcd9f-9dd0-46b1-8c7f-b9e84bbba8e7": 0
        }
      },
      "Belknap 8": {
        "members": [
          {
            "API_ID": "ocd-person/464c15d2-0b67-47e6-8c0a-fc556b336d4a",
            "firstName": "Douglas R.",
            "lastName": "Trottier",
            "fullName": "Douglas Trottier",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2020-07/douglas-r-trottier.png?itok=8gaHTq0I",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 8-lower-ocd-person-464c15d2-0b67-47e6-8c0a-fc556b336d4a",
              "builtID": "nh-lower-belknap 8",
              "externalID": "ocd-person/464c15d2-0b67-47e6-8c0a-fc556b336d4a",
              "geometry": null
            },
            "contactInfo": {
              "email": "douglastrottier1@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9867",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9867"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a5d1eff0-7a7b-4726-ad0a-5ba931098d08",
            "firstName": "Nikki",
            "lastName": "McCarter",
            "fullName": "Nikki McCarter",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-07/Nikki-McCarter.jpg?itok=iFBSCxy5",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 8-lower-ocd-person-a5d1eff0-7a7b-4726-ad0a-5ba931098d08",
              "builtID": "nh-lower-belknap 8",
              "externalID": "ocd-person/a5d1eff0-7a7b-4726-ad0a-5ba931098d08",
              "geometry": null
            },
            "contactInfo": {
              "email": "nikki.mccarter@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10575"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Belknap 8-lower-ocd-person-464c15d2-0b67-47e6-8c0a-fc556b336d4a": 0,
          "SLDL-NH-Belknap 8-lower-ocd-person-a5d1eff0-7a7b-4726-ad0a-5ba931098d08": 1
        }
      },
      "Cheshire 2": {
        "members": [
          {
            "API_ID": "ocd-person/31e633fb-0fd5-43e8-8a18-2bd0c1d6bca9",
            "firstName": "Dru",
            "lastName": "Fox",
            "fullName": "Dru Fox",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408975.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 2-lower-ocd-person-31e633fb-0fd5-43e8-8a18-2bd0c1d6bca9",
              "builtID": "nh-lower-cheshire 2",
              "externalID": "ocd-person/31e633fb-0fd5-43e8-8a18-2bd0c1d6bca9",
              "geometry": null
            },
            "contactInfo": {
              "email": "dru.fox@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9873",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9873"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 2-lower-ocd-person-31e633fb-0fd5-43e8-8a18-2bd0c1d6bca9": 0
        }
      },
      "Coos 7": {
        "members": [
          {
            "API_ID": "ocd-person/49188b50-c42c-4892-8417-41b9a3fec2f7",
            "firstName": "Eamon",
            "lastName": "Kelley",
            "fullName": "Eamon Kelley",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://bloximages.newyork1.vip.townnews.com/conwaydailysun.com/content/tncms/assets/v3/editorial/3/1d/31d15e62-1792-11eb-b543-5fcfb756108c/5f96d478a6315.image.jpg?resize=750%2C944",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Coos 7",
              "chamber": "lower",
              "hashID": "SLDL-NH-Coos 7-lower-ocd-person-49188b50-c42c-4892-8417-41b9a3fec2f7",
              "builtID": "nh-lower-coos 7",
              "externalID": "ocd-person/49188b50-c42c-4892-8417-41b9a3fec2f7",
              "geometry": null
            },
            "contactInfo": {
              "email": "eamon@kelleyforberlin.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9883",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9883"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Coos 7-lower-ocd-person-49188b50-c42c-4892-8417-41b9a3fec2f7": 0
        }
      },
      "Rockingham 7": {
        "members": [
          {
            "API_ID": "ocd-person/9b5063bc-ae10-4901-8622-71516cc3b9c9",
            "firstName": "Emily",
            "lastName": "Phillips",
            "fullName": "Emily Phillips",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/EmilyPhillips.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 7",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 7-lower-ocd-person-9b5063bc-ae10-4901-8622-71516cc3b9c9",
              "builtID": "nh-lower-rockingham 7",
              "externalID": "ocd-person/9b5063bc-ae10-4901-8622-71516cc3b9c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "emily.phillips@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10687"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 7-lower-ocd-person-9b5063bc-ae10-4901-8622-71516cc3b9c9": 0
        }
      },
      "Merrimack 20": {
        "members": [
          {
            "API_ID": "ocd-person/a8e2dc1d-b828-475a-92d4-373129360dcb",
            "firstName": "Eric",
            "lastName": "Gallager",
            "fullName": "Eric Gallager",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409026.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 20",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 20-lower-ocd-person-a8e2dc1d-b828-475a-92d4-373129360dcb",
              "builtID": "nh-lower-merrimack 20",
              "externalID": "ocd-person/a8e2dc1d-b828-475a-92d4-373129360dcb",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.gallager@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9926",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9926"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 20-lower-ocd-person-a8e2dc1d-b828-475a-92d4-373129360dcb": 0
        }
      },
      "Rockingham 6": {
        "members": [
          {
            "API_ID": "ocd-person/fa004123-5b0b-4019-8f74-8c43570c388c",
            "firstName": "Eric",
            "lastName": "Turer",
            "fullName": "Eric Turer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://photos.jsi.com/Photos/US/35335.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 6-lower-ocd-person-fa004123-5b0b-4019-8f74-8c43570c388c",
              "builtID": "nh-lower-rockingham 6",
              "externalID": "ocd-person/fa004123-5b0b-4019-8f74-8c43570c388c",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.turer@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9999"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 6-lower-ocd-person-fa004123-5b0b-4019-8f74-8c43570c388c": 0
        }
      },
      "Strafford 11": {
        "members": [
          {
            "API_ID": "ocd-person/5a468f0d-8baf-47e9-92da-2db62373030f",
            "firstName": "Erik R.",
            "lastName": "Johnson",
            "fullName": "Erik Johnson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.fosters.com/gcdn/authoring/authoring-images/2024/02/28/NPOH/72776423007-72712182007-erikjohnson.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 11",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 11-lower-ocd-person-5a468f0d-8baf-47e9-92da-2db62373030f",
              "builtID": "nh-lower-strafford 11",
              "externalID": "ocd-person/5a468f0d-8baf-47e9-92da-2db62373030f",
              "geometry": null
            },
            "contactInfo": {
              "email": "erik.johnson@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=11134"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/2830876f-1a4d-4332-ae2a-a97607c4e1b9",
            "firstName": "Janet G.",
            "lastName": "Wall",
            "fullName": "Janet Wall",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/372557.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 11",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 11-lower-ocd-person-2830876f-1a4d-4332-ae2a-a97607c4e1b9",
              "builtID": "nh-lower-strafford 11",
              "externalID": "ocd-person/2830876f-1a4d-4332-ae2a-a97607c4e1b9",
              "geometry": null
            },
            "contactInfo": {
              "email": "janet.wall@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=415",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=372557"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4febed48-b7fd-4b44-98e8-fb20de52968e",
            "firstName": "Thomas",
            "lastName": "Southworth",
            "fullName": "Tom Southworth",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377316.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 11",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 11-lower-ocd-person-4febed48-b7fd-4b44-98e8-fb20de52968e",
              "builtID": "nh-lower-strafford 11",
              "externalID": "ocd-person/4febed48-b7fd-4b44-98e8-fb20de52968e",
              "geometry": null
            },
            "contactInfo": {
              "email": "thomas.southworth@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=918",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377316"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 11-lower-ocd-person-5a468f0d-8baf-47e9-92da-2db62373030f": 0,
          "SLDL-NH-Strafford 11-lower-ocd-person-2830876f-1a4d-4332-ae2a-a97607c4e1b9": 1,
          "SLDL-NH-Strafford 11-lower-ocd-person-4febed48-b7fd-4b44-98e8-fb20de52968e": 2
        }
      },
      "Hillsborough 3": {
        "members": [
          {
            "API_ID": "ocd-person/0119879d-c522-4d71-992d-936a57389093",
            "firstName": "Fred E.",
            "lastName": "Davis",
            "fullName": "Fred Davis",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Fred_Davis.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 3",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 3-lower-ocd-person-0119879d-c522-4d71-992d-936a57389093",
              "builtID": "nh-lower-hillsborough 3",
              "externalID": "ocd-person/0119879d-c522-4d71-992d-936a57389093",
              "geometry": null
            },
            "contactInfo": {
              "email": "fred.davis@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9109"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a5b1edc7-c0a1-49fe-824c-a9177a8e1218",
            "firstName": "Marc W.",
            "lastName": "Plamondon",
            "fullName": "Marc Plamondon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://d1fdloi71mui9q.cloudfront.net/wxHeDOlTmrng9JXrmHMA_PzA4v4H07EpaEbgi",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 3",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 3-lower-ocd-person-a5b1edc7-c0a1-49fe-824c-a9177a8e1218",
              "builtID": "nh-lower-hillsborough 3",
              "externalID": "ocd-person/a5b1edc7-c0a1-49fe-824c-a9177a8e1218",
              "geometry": null
            },
            "contactInfo": {
              "email": "marc.plamondon@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10998",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10998"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/2fe799f4-a099-4667-ac0d-1bce40a08e3d",
            "firstName": "Paige Marie",
            "lastName": "Beauchemin",
            "fullName": "Paige Beauchemin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://paigefornh.campaign.win/uploads/1475cb773b95c1f47f13e6f157afb5bf",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 3",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 3-lower-ocd-person-2fe799f4-a099-4667-ac0d-1bce40a08e3d",
              "builtID": "nh-lower-hillsborough 3",
              "externalID": "ocd-person/2fe799f4-a099-4667-ac0d-1bce40a08e3d",
              "geometry": null
            },
            "contactInfo": {
              "email": "paige.beauchemin@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=11100",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=11100"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 3-lower-ocd-person-0119879d-c522-4d71-992d-936a57389093": 0,
          "SLDL-NH-Hillsborough 3-lower-ocd-person-a5b1edc7-c0a1-49fe-824c-a9177a8e1218": 1,
          "SLDL-NH-Hillsborough 3-lower-ocd-person-2fe799f4-a099-4667-ac0d-1bce40a08e3d": 2
        }
      },
      "Hillsborough 29": {
        "members": [
          {
            "API_ID": "ocd-person/573f6daa-f310-46da-9f87-bf7497dd084a",
            "firstName": "Frederick Randall",
            "lastName": "Plett",
            "fullName": "Fred Plett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408952.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 29",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 29-lower-ocd-person-573f6daa-f310-46da-9f87-bf7497dd084a",
              "builtID": "nh-lower-hillsborough 29",
              "externalID": "ocd-person/573f6daa-f310-46da-9f87-bf7497dd084a",
              "geometry": null
            },
            "contactInfo": {
              "email": "fred.plett@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9184",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9184"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e7800d58-1019-4aff-8d1a-22faba9d3e23",
            "firstName": "Joseph",
            "lastName": "Alexander",
            "fullName": "Joe Alexander",
            "gender": "Male",
            "party": "Republican",
            "image": "https://kubrick.htvapps.com/htv-prod-media.s3.amazonaws.com/images/joealexanderphoto-png-1571786224.png",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 29",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 29-lower-ocd-person-e7800d58-1019-4aff-8d1a-22faba9d3e23",
              "builtID": "nh-lower-hillsborough 29",
              "externalID": "ocd-person/e7800d58-1019-4aff-8d1a-22faba9d3e23",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.alexander@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9165",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9165"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/81c869fa-8ef4-416f-96a9-d24a7cce621d",
            "firstName": "Judi Greene",
            "lastName": "Lanza",
            "fullName": "Judi Lanza",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://goffstowndems.com/wp-content/uploads/2022/08/Lanza1crop-1237x1536.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 29",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 29-lower-ocd-person-81c869fa-8ef4-416f-96a9-d24a7cce621d",
              "builtID": "nh-lower-hillsborough 29",
              "externalID": "ocd-person/81c869fa-8ef4-416f-96a9-d24a7cce621d",
              "geometry": null
            },
            "contactInfo": {
              "email": "judi.lanza@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8505"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8ae0c62a-4992-4df5-ae59-56c3364df8fc",
            "firstName": "Sheila",
            "lastName": "Seidel",
            "fullName": "Sheila Seidel",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-09/sheila-seidel.png?itok=ElFYaFFT",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 29",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 29-lower-ocd-person-8ae0c62a-4992-4df5-ae59-56c3364df8fc",
              "builtID": "nh-lower-hillsborough 29",
              "externalID": "ocd-person/8ae0c62a-4992-4df5-ae59-56c3364df8fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "sheila.seidel@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10651"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 29-lower-ocd-person-573f6daa-f310-46da-9f87-bf7497dd084a": 0,
          "SLDL-NH-Hillsborough 29-lower-ocd-person-e7800d58-1019-4aff-8d1a-22faba9d3e23": 1,
          "SLDL-NH-Hillsborough 29-lower-ocd-person-81c869fa-8ef4-416f-96a9-d24a7cce621d": 2,
          "SLDL-NH-Hillsborough 29-lower-ocd-person-8ae0c62a-4992-4df5-ae59-56c3364df8fc": 3
        }
      },
      "Rockingham 11": {
        "members": [
          {
            "API_ID": "ocd-person/01d85172-f83f-48ee-8c35-8979a95c9190",
            "firstName": "Gabrielle Smith",
            "lastName": "Grossman",
            "fullName": "Gaby Grossman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408918.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 11",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 11-lower-ocd-person-01d85172-f83f-48ee-8c35-8979a95c9190",
              "builtID": "nh-lower-rockingham 11",
              "externalID": "ocd-person/01d85172-f83f-48ee-8c35-8979a95c9190",
              "geometry": null
            },
            "contactInfo": {
              "email": "gaby.grossman@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9218",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9218"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/77ca15e6-ebe1-4ef5-8bc9-ed3dff861f38",
            "firstName": "Julie Dupre",
            "lastName": "Gilman",
            "fullName": "Julie Gilman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408615.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 11",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 11-lower-ocd-person-77ca15e6-ebe1-4ef5-8bc9-ed3dff861f38",
              "builtID": "nh-lower-rockingham 11",
              "externalID": "ocd-person/77ca15e6-ebe1-4ef5-8bc9-ed3dff861f38",
              "geometry": null
            },
            "contactInfo": {
              "email": "gilmanjd@comcast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8615",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408615"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ad3c3841-fa56-4055-bd86-8a3c504d36a9",
            "firstName": "Linda J.",
            "lastName": "Haskins",
            "fullName": "Linda Haskins",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409107.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 11",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 11-lower-ocd-person-ad3c3841-fa56-4055-bd86-8a3c504d36a9",
              "builtID": "nh-lower-rockingham 11",
              "externalID": "ocd-person/ad3c3841-fa56-4055-bd86-8a3c504d36a9",
              "geometry": null
            },
            "contactInfo": {
              "email": "linda.haskins@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10855"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8c6eeb07-d048-47fd-9f0e-718460e2edc9",
            "firstName": "Mark A.",
            "lastName": "Paige",
            "fullName": "Mark Paige",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Mark-Paige.PNG",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 11",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 11-lower-ocd-person-8c6eeb07-d048-47fd-9f0e-718460e2edc9",
              "builtID": "nh-lower-rockingham 11",
              "externalID": "ocd-person/8c6eeb07-d048-47fd-9f0e-718460e2edc9",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.paige@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9942",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9942"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 11-lower-ocd-person-01d85172-f83f-48ee-8c35-8979a95c9190": 0,
          "SLDL-NH-Rockingham 11-lower-ocd-person-77ca15e6-ebe1-4ef5-8bc9-ed3dff861f38": 1,
          "SLDL-NH-Rockingham 11-lower-ocd-person-ad3c3841-fa56-4055-bd86-8a3c504d36a9": 2,
          "SLDL-NH-Rockingham 11-lower-ocd-person-8c6eeb07-d048-47fd-9f0e-718460e2edc9": 3
        }
      },
      "Strafford 16": {
        "members": [
          {
            "API_ID": "ocd-person/dfdcff96-acc9-4d8f-8036-6e273bb3f536",
            "firstName": "Gail L.",
            "lastName": "Pare",
            "fullName": "Gail Pare",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gannett-cdn.com/presto/2022/10/09/NFDD/31df2ffa-f56b-47fd-bf8f-67281a7c1be0-Gail_Pare.JPG",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 16",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 16-lower-ocd-person-dfdcff96-acc9-4d8f-8036-6e273bb3f536",
              "builtID": "nh-lower-strafford 16",
              "externalID": "ocd-person/dfdcff96-acc9-4d8f-8036-6e273bb3f536",
              "geometry": null
            },
            "contactInfo": {
              "email": "gail.pare@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10899",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10899"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 16-lower-ocd-person-dfdcff96-acc9-4d8f-8036-6e273bb3f536": 0
        }
      },
      "Sullivan 6": {
        "members": [
          {
            "API_ID": "ocd-person/78078880-ab6f-4f87-b095-8b85c31f1780",
            "firstName": "Gary",
            "lastName": "Merchant",
            "fullName": "Gary Merchant",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Gary_Merchant_with_beard.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Sullivan 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Sullivan 6-lower-ocd-person-78078880-ab6f-4f87-b095-8b85c31f1780",
              "builtID": "nh-lower-sullivan 6",
              "externalID": "ocd-person/78078880-ab6f-4f87-b095-8b85c31f1780",
              "geometry": null
            },
            "contactInfo": {
              "email": "merchant4nhhouse@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9240",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9240"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d3dab509-1268-4b4f-b363-98932002bf2e",
            "firstName": "John R.",
            "lastName": "Cloutier",
            "fullName": "John Cloutier",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/375829.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Sullivan 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Sullivan 6-lower-ocd-person-d3dab509-1268-4b4f-b363-98932002bf2e",
              "builtID": "nh-lower-sullivan 6",
              "externalID": "ocd-person/d3dab509-1268-4b4f-b363-98932002bf2e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jocloutier@comcast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=375829",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=427"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/74efcb71-c62c-4388-b9ad-69f779b0c33e",
            "firstName": "Walter A.",
            "lastName": "Stapleton",
            "fullName": "Walt Stapleton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408955.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Sullivan 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Sullivan 6-lower-ocd-person-74efcb71-c62c-4388-b9ad-69f779b0c33e",
              "builtID": "nh-lower-sullivan 6",
              "externalID": "ocd-person/74efcb71-c62c-4388-b9ad-69f779b0c33e",
              "geometry": null
            },
            "contactInfo": {
              "email": "walt.stapleton@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8454",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=8454"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Sullivan 6-lower-ocd-person-78078880-ab6f-4f87-b095-8b85c31f1780": 0,
          "SLDL-NH-Sullivan 6-lower-ocd-person-d3dab509-1268-4b4f-b363-98932002bf2e": 1,
          "SLDL-NH-Sullivan 6-lower-ocd-person-74efcb71-c62c-4388-b9ad-69f779b0c33e": 2
        }
      },
      "Strafford 21": {
        "members": [
          {
            "API_ID": "ocd-person/1d25683b-5c85-41cb-8585-6e18ddd0f648",
            "firstName": "Geoffrey",
            "lastName": "Smith",
            "fullName": "Geoff Smith",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gannett-cdn.com/presto/2022/09/08/NFDD/05d04a1f-87b2-4f2f-be88-18c7eefc1bc2-Gsmith_Headshot.jpg?width=300&height=355&fit=crop&format=pjpg&auto=webp",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 21",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 21-lower-ocd-person-1d25683b-5c85-41cb-8585-6e18ddd0f648",
              "builtID": "nh-lower-strafford 21",
              "externalID": "ocd-person/1d25683b-5c85-41cb-8585-6e18ddd0f648",
              "geometry": null
            },
            "contactInfo": {
              "email": "geoff.smith@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10905"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b2bd1fe6-401e-41ac-88cc-a96d8a12d737",
            "firstName": "Luz",
            "lastName": "Bay",
            "fullName": "Luz Bay",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/LuzBay.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 21",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 21-lower-ocd-person-b2bd1fe6-401e-41ac-88cc-a96d8a12d737",
              "builtID": "nh-lower-strafford 21",
              "externalID": "ocd-person/b2bd1fe6-401e-41ac-88cc-a96d8a12d737",
              "geometry": null
            },
            "contactInfo": {
              "email": "luz.bay@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10904"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0e26c62b-320e-4b22-a5cf-d8960ef2e3fc",
            "firstName": "Susan",
            "lastName": "Treleaven",
            "fullName": "Susan Treleaven",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377318.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 21",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 21-lower-ocd-person-0e26c62b-320e-4b22-a5cf-d8960ef2e3fc",
              "builtID": "nh-lower-strafford 21",
              "externalID": "ocd-person/0e26c62b-320e-4b22-a5cf-d8960ef2e3fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "streleaven@comcast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=919",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377318"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 21-lower-ocd-person-1d25683b-5c85-41cb-8585-6e18ddd0f648": 0,
          "SLDL-NH-Strafford 21-lower-ocd-person-b2bd1fe6-401e-41ac-88cc-a96d8a12d737": 1,
          "SLDL-NH-Strafford 21-lower-ocd-person-0e26c62b-320e-4b22-a5cf-d8960ef2e3fc": 2
        }
      },
      "Grafton 14": {
        "members": [
          {
            "API_ID": "ocd-person/1d64c012-42cd-433e-9e51-d449279fe57e",
            "firstName": "George",
            "lastName": "Sykes",
            "fullName": "George Sykes",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377176.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 14",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 14-lower-ocd-person-1d64c012-42cd-433e-9e51-d449279fe57e",
              "builtID": "nh-lower-grafton 14",
              "externalID": "ocd-person/1d64c012-42cd-433e-9e51-d449279fe57e",
              "geometry": null
            },
            "contactInfo": {
              "email": "george.sykes@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=781",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377176"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 14-lower-ocd-person-1d64c012-42cd-433e-9e51-d449279fe57e": 0
        }
      },
      "Hillsborough 42": {
        "members": [
          {
            "API_ID": "ocd-person/915a097c-1280-478b-b200-0903e323e9e5",
            "firstName": "Gerald",
            "lastName": "Griffin",
            "fullName": "Gerry Griffin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408314.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 42",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 42-lower-ocd-person-915a097c-1280-478b-b200-0903e323e9e5",
              "builtID": "nh-lower-hillsborough 42",
              "externalID": "ocd-person/915a097c-1280-478b-b200-0903e323e9e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "gerald.griffin@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8314",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=8314"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c975be5b-c686-4489-92d2-5b8ba36f9974",
            "firstName": "Keith",
            "lastName": "Ammon",
            "fullName": "Keith Ammon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377204.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 42",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 42-lower-ocd-person-c975be5b-c686-4489-92d2-5b8ba36f9974",
              "builtID": "nh-lower-hillsborough 42",
              "externalID": "ocd-person/c975be5b-c686-4489-92d2-5b8ba36f9974",
              "geometry": null
            },
            "contactInfo": {
              "email": "keith.ammon@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377204",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=836"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/72ef654a-277a-4309-b0ae-08fee715af2c",
            "firstName": "Lisa C.M.",
            "lastName": "Post",
            "fullName": "Lisa Post",
            "gender": "Female",
            "party": "Republican",
            "image": "https://img1.wsimg.com/isteam/ip/6c315054-4fed-4fed-ad1c-411ab3cf43e8/lisa603Apic2.1%20(2).jpg/:/rs=h:600/qt=q:95",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 42",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 42-lower-ocd-person-72ef654a-277a-4309-b0ae-08fee715af2c",
              "builtID": "nh-lower-hillsborough 42",
              "externalID": "ocd-person/72ef654a-277a-4309-b0ae-08fee715af2c",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisacmpost@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9896",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9896"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 42-lower-ocd-person-915a097c-1280-478b-b200-0903e323e9e5": 0,
          "SLDL-NH-Hillsborough 42-lower-ocd-person-c975be5b-c686-4489-92d2-5b8ba36f9974": 1,
          "SLDL-NH-Hillsborough 42-lower-ocd-person-72ef654a-277a-4309-b0ae-08fee715af2c": 2
        }
      },
      "Rockingham 27": {
        "members": [
          {
            "API_ID": "ocd-person/03774445-16e9-4857-8015-317a3d8705a3",
            "firstName": "Gerald W.R.",
            "lastName": "Ward",
            "fullName": "Gerry Ward",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377188.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 27",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 27-lower-ocd-person-03774445-16e9-4857-8015-317a3d8705a3",
              "builtID": "nh-lower-rockingham 27",
              "externalID": "ocd-person/03774445-16e9-4857-8015-317a3d8705a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "ward4staterep@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=792",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377188"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 27-lower-ocd-person-03774445-16e9-4857-8015-317a3d8705a3": 0
        }
      },
      "Carroll 7": {
        "members": [
          {
            "API_ID": "ocd-person/c8aa2951-dea0-4999-9b6a-6323d9917e0b",
            "firstName": "Glenn",
            "lastName": "Cordelli",
            "fullName": "Glen Cordelli",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377085.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Carroll 7",
              "chamber": "lower",
              "hashID": "SLDL-NH-Carroll 7-lower-ocd-person-c8aa2951-dea0-4999-9b6a-6323d9917e0b",
              "builtID": "nh-lower-carroll 7",
              "externalID": "ocd-person/c8aa2951-dea0-4999-9b6a-6323d9917e0b",
              "geometry": null
            },
            "contactInfo": {
              "email": "glenn.cordelli@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=692",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377085"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Carroll 7-lower-ocd-person-c8aa2951-dea0-4999-9b6a-6323d9917e0b": 0
        }
      },
      "Merrimack 2": {
        "members": [
          {
            "API_ID": "ocd-person/852df922-484b-412f-a604-c2d67efee5ec",
            "firstName": "Gregory",
            "lastName": "Hill",
            "fullName": "Greg Hill",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/candidate-images/ghillmh3.jpg?itok=5R4Y1pzI",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 2-lower-ocd-person-852df922-484b-412f-a604-c2d67efee5ec",
              "builtID": "nh-lower-merrimack 2",
              "externalID": "ocd-person/852df922-484b-412f-a604-c2d67efee5ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "greghillnh@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=943",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=943"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 2-lower-ocd-person-852df922-484b-412f-a604-c2d67efee5ec": 0
        }
      },
      "Rockingham 1": {
        "members": [
          {
            "API_ID": "ocd-person/8a0b843a-c82b-473f-9bb9-d864e8d90414",
            "firstName": "Hal",
            "lastName": "Rafter",
            "fullName": "Hal Rafter",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409197.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 1-lower-ocd-person-8a0b843a-c82b-473f-9bb9-d864e8d90414",
              "builtID": "nh-lower-rockingham 1",
              "externalID": "ocd-person/8a0b843a-c82b-473f-9bb9-d864e8d90414",
              "geometry": null
            },
            "contactInfo": {
              "email": "hal.rafter@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=7705",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=7705"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/955860db-4243-4bc4-bd69-3f6f15c75cba",
            "firstName": "Jacob",
            "lastName": "Brouillard",
            "fullName": "Jake Brouillard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://media.licdn.com/dms/image/C4D03AQGlyI8u7B5Ang/profile-displayphoto-shrink_200_200/0/1516964459350?e=1724284800&v=beta&t=rKd1QDskaYFzh_Vd6VUQMYFE6QzWQJSeZEji8NMBBcU",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 1-lower-ocd-person-955860db-4243-4bc4-bd69-3f6f15c75cba",
              "builtID": "nh-lower-rockingham 1",
              "externalID": "ocd-person/955860db-4243-4bc4-bd69-3f6f15c75cba",
              "geometry": null
            },
            "contactInfo": {
              "email": "jacob.brouillard@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10684"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/9a1db7df-83d0-4f2f-8c3c-7610f8012ce6",
            "firstName": "Paul",
            "lastName": "Tudor",
            "fullName": "Paul Tudor",
            "gender": "Male",
            "party": "Republican",
            "image": "https://static.votesmart.org/static/canphoto/195052.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 1-lower-ocd-person-9a1db7df-83d0-4f2f-8c3c-7610f8012ce6",
              "builtID": "nh-lower-rockingham 1",
              "externalID": "ocd-person/9a1db7df-83d0-4f2f-8c3c-7610f8012ce6",
              "geometry": null
            },
            "contactInfo": {
              "email": "paultudor.1strockingham@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9930",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9930"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 1-lower-ocd-person-8a0b843a-c82b-473f-9bb9-d864e8d90414": 0,
          "SLDL-NH-Rockingham 1-lower-ocd-person-955860db-4243-4bc4-bd69-3f6f15c75cba": 1,
          "SLDL-NH-Rockingham 1-lower-ocd-person-9a1db7df-83d0-4f2f-8c3c-7610f8012ce6": 2
        }
      },
      "Grafton 4": {
        "members": [
          {
            "API_ID": "ocd-person/b14568dd-d9f5-4557-8ccd-0d000c231650",
            "firstName": "Heather P.",
            "lastName": "Baldwin",
            "fullName": "Heather Baldwin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Heather_Baldwin.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 4-lower-ocd-person-b14568dd-d9f5-4557-8ccd-0d000c231650",
              "builtID": "nh-lower-grafton 4",
              "externalID": "ocd-person/b14568dd-d9f5-4557-8ccd-0d000c231650",
              "geometry": null
            },
            "contactInfo": {
              "email": "heather.baldwin@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10762"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 4-lower-ocd-person-b14568dd-d9f5-4557-8ccd-0d000c231650": 0
        }
      },
      "Hillsborough 5": {
        "members": [
          {
            "API_ID": "ocd-person/e1e26c8f-3b7f-42bf-89e2-33331ff83bb3",
            "firstName": "Heather Shewan",
            "lastName": "Raymond",
            "fullName": "Heather Raymond",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://nhhousedems.org/wp-content/uploads/2022/09/632c91a44ffa8-768x1152.jpeg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 5-lower-ocd-person-e1e26c8f-3b7f-42bf-89e2-33331ff83bb3",
              "builtID": "nh-lower-hillsborough 5",
              "externalID": "ocd-person/e1e26c8f-3b7f-42bf-89e2-33331ff83bb3",
              "geometry": null
            },
            "contactInfo": {
              "email": "heather.raymond@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10779",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10779"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e9cfb6b3-63bb-4ff2-acf7-292631049f67",
            "firstName": "Shelley",
            "lastName": "Devine",
            "fullName": "Shelley Devine",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-08/shelley-devine.jpg?itok=0awOs8Sr",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 5-lower-ocd-person-e9cfb6b3-63bb-4ff2-acf7-292631049f67",
              "builtID": "nh-lower-hillsborough 5",
              "externalID": "ocd-person/e9cfb6b3-63bb-4ff2-acf7-292631049f67",
              "geometry": null
            },
            "contactInfo": {
              "email": "shelley.devine@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10777"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c9b60101-8e1e-4ca2-b5c1-3ffa019fcb3e",
            "firstName": "Susan A.",
            "lastName": "Elberger",
            "fullName": "Susan Elberger",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409093.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 5-lower-ocd-person-c9b60101-8e1e-4ca2-b5c1-3ffa019fcb3e",
              "builtID": "nh-lower-hillsborough 5",
              "externalID": "ocd-person/c9b60101-8e1e-4ca2-b5c1-3ffa019fcb3e",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.elberger@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10778"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 5-lower-ocd-person-e1e26c8f-3b7f-42bf-89e2-33331ff83bb3": 0,
          "SLDL-NH-Hillsborough 5-lower-ocd-person-e9cfb6b3-63bb-4ff2-acf7-292631049f67": 1,
          "SLDL-NH-Hillsborough 5-lower-ocd-person-c9b60101-8e1e-4ca2-b5c1-3ffa019fcb3e": 2
        }
      },
      "Hillsborough 19": {
        "members": [
          {
            "API_ID": "ocd-person/b64a1dfb-4b79-4424-96d3-972f6ef2b6eb",
            "firstName": "Heidi",
            "lastName": "Hamer",
            "fullName": "Heidi Hamer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408859.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 19",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 19-lower-ocd-person-b64a1dfb-4b79-4424-96d3-972f6ef2b6eb",
              "builtID": "nh-lower-hillsborough 19",
              "externalID": "ocd-person/b64a1dfb-4b79-4424-96d3-972f6ef2b6eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "heidi.hamer@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9087",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9087"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/fb843a60-91e0-4639-a5a4-1a734586c9c9",
            "firstName": "Jane Ellen",
            "lastName": "Beaulieu",
            "fullName": "Jane Beaulieu",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376550.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 19",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 19-lower-ocd-person-fb843a60-91e0-4639-a5a4-1a734586c9c9",
              "builtID": "nh-lower-hillsborough 19",
              "externalID": "ocd-person/fb843a60-91e0-4639-a5a4-1a734586c9c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "jane.beaulieu@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=501",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376550"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 19-lower-ocd-person-b64a1dfb-4b79-4424-96d3-972f6ef2b6eb": 0,
          "SLDL-NH-Hillsborough 19-lower-ocd-person-fb843a60-91e0-4639-a5a4-1a734586c9c9": 1
        }
      },
      "Sullivan 8": {
        "members": [
          {
            "API_ID": "ocd-person/a1852031-32a0-4f94-95b8-57ddcc5ab576",
            "firstName": "Hope",
            "lastName": "Damon",
            "fullName": "Hope Damon",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409085.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Sullivan 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Sullivan 8-lower-ocd-person-a1852031-32a0-4f94-95b8-57ddcc5ab576",
              "builtID": "nh-lower-sullivan 8",
              "externalID": "ocd-person/a1852031-32a0-4f94-95b8-57ddcc5ab576",
              "geometry": null
            },
            "contactInfo": {
              "email": "hope.damon@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10910"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/83491899-2a30-4a74-8f9d-6f1ff131b526",
            "firstName": "Jonathan F.",
            "lastName": "Stone",
            "fullName": "Jon Stone",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2020-07/jonathan-f-stone.png?itok=LFxDzX1r",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Sullivan 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Sullivan 8-lower-ocd-person-83491899-2a30-4a74-8f9d-6f1ff131b526",
              "builtID": "nh-lower-sullivan 8",
              "externalID": "ocd-person/83491899-2a30-4a74-8f9d-6f1ff131b526",
              "geometry": null
            },
            "contactInfo": {
              "email": "jonathan.stone@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10163"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Sullivan 8-lower-ocd-person-a1852031-32a0-4f94-95b8-57ddcc5ab576": 0,
          "SLDL-NH-Sullivan 8-lower-ocd-person-83491899-2a30-4a74-8f9d-6f1ff131b526": 1
        }
      },
      "Rockingham 36": {
        "members": [
          {
            "API_ID": "ocd-person/9bae2e1c-4557-48a0-8adb-531347472614",
            "firstName": "J. David",
            "lastName": "Bernardy",
            "fullName": "J.D. Bernardy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2020-08/JDBernardy.png?itok=IhGAlNMd",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 36",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 36-lower-ocd-person-9bae2e1c-4557-48a0-8adb-531347472614",
              "builtID": "nh-lower-rockingham 36",
              "externalID": "ocd-person/9bae2e1c-4557-48a0-8adb-531347472614",
              "geometry": null
            },
            "contactInfo": {
              "email": "jd.bernardy@comcast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9941",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9941"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 36-lower-ocd-person-9bae2e1c-4557-48a0-8adb-531347472614": 0
        }
      },
      "Rockingham 2": {
        "members": [
          {
            "API_ID": "ocd-person/2520dc46-648c-44d0-ba1f-fe09b17a9d8b",
            "firstName": "James",
            "lastName": "Spillane",
            "fullName": "Jim Spillane",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377264.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 2-lower-ocd-person-2520dc46-648c-44d0-ba1f-fe09b17a9d8b",
              "builtID": "nh-lower-rockingham 2",
              "externalID": "ocd-person/2520dc46-648c-44d0-ba1f-fe09b17a9d8b",
              "geometry": null
            },
            "contactInfo": {
              "email": "james@jamesspillane.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=905",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377264"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/6e2bc564-5cd1-4f96-a265-4753b7f12ee1",
            "firstName": "Jason",
            "lastName": "Osborne",
            "fullName": "Jason Osborne",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377253.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 2-lower-ocd-person-6e2bc564-5cd1-4f96-a265-4753b7f12ee1",
              "builtID": "nh-lower-rockingham 2",
              "externalID": "ocd-person/6e2bc564-5cd1-4f96-a265-4753b7f12ee1",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason@osborne4nh.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=901"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/9d10ccec-b571-4eea-b025-9ed7634b6516",
            "firstName": "Kevin",
            "lastName": "Verville",
            "fullName": "Kevin Verville",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408388.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 2-lower-ocd-person-9d10ccec-b571-4eea-b025-9ed7634b6516",
              "builtID": "nh-lower-rockingham 2",
              "externalID": "ocd-person/9d10ccec-b571-4eea-b025-9ed7634b6516",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.verville@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8388",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408388"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 2-lower-ocd-person-2520dc46-648c-44d0-ba1f-fe09b17a9d8b": 0,
          "SLDL-NH-Rockingham 2-lower-ocd-person-6e2bc564-5cd1-4f96-a265-4753b7f12ee1": 1,
          "SLDL-NH-Rockingham 2-lower-ocd-person-9d10ccec-b571-4eea-b025-9ed7634b6516": 2
        }
      },
      "Strafford 1": {
        "members": [
          {
            "API_ID": "ocd-person/934b4d28-78b5-40ef-a194-d53d99cc430c",
            "firstName": "James F.",
            "lastName": "Horgan",
            "fullName": "James Horgan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408434.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 1-lower-ocd-person-934b4d28-78b5-40ef-a194-d53d99cc430c",
              "builtID": "nh-lower-strafford 1",
              "externalID": "ocd-person/934b4d28-78b5-40ef-a194-d53d99cc430c",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.horgan@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8434",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=408434"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/5ce55936-fd73-4b3c-ba16-70e13734a3c8",
            "firstName": "Joseph A.",
            "lastName": "Pitre",
            "fullName": "Joseph Pitre",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377005.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 1-lower-ocd-person-5ce55936-fd73-4b3c-ba16-70e13734a3c8",
              "builtID": "nh-lower-strafford 1",
              "externalID": "ocd-person/5ce55936-fd73-4b3c-ba16-70e13734a3c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "house@joepitre.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=647",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377005"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 1-lower-ocd-person-934b4d28-78b5-40ef-a194-d53d99cc430c": 0,
          "SLDL-NH-Strafford 1-lower-ocd-person-5ce55936-fd73-4b3c-ba16-70e13734a3c8": 1
        }
      },
      "Merrimack 22": {
        "members": [
          {
            "API_ID": "ocd-person/cfbe3994-9a8b-4edf-80e7-d087700ca75a",
            "firstName": "James Leon",
            "lastName": "Roesener",
            "fullName": "James Roesener",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://victoryfund.org/wp-content/uploads/2017/01/James-Roesener-1024x576.png",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 22",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 22-lower-ocd-person-cfbe3994-9a8b-4edf-80e7-d087700ca75a",
              "builtID": "nh-lower-merrimack 22",
              "externalID": "ocd-person/cfbe3994-9a8b-4edf-80e7-d087700ca75a",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.roesener@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10840",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10840"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 22-lower-ocd-person-cfbe3994-9a8b-4edf-80e7-d087700ca75a": 0
        }
      },
      "Grafton 12": {
        "members": [
          {
            "API_ID": "ocd-person/c88c54ce-42e9-4111-8e1a-164ee43eaa89",
            "firstName": "James Michael",
            "lastName": "Murphy",
            "fullName": "James Murphy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/JamesMurphy.png",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 12-lower-ocd-person-c88c54ce-42e9-4111-8e1a-164ee43eaa89",
              "builtID": "nh-lower-grafton 12",
              "externalID": "ocd-person/c88c54ce-42e9-4111-8e1a-164ee43eaa89",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.murphy@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9891",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9891"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0929165d-f73c-4820-8820-209de58c1e78",
            "firstName": "Mary A.",
            "lastName": "Hakken-Phillips",
            "fullName": "Mary Hakken-Phillips",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408990.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 12-lower-ocd-person-0929165d-f73c-4820-8820-209de58c1e78",
              "builtID": "nh-lower-grafton 12",
              "externalID": "ocd-person/0929165d-f73c-4820-8820-209de58c1e78",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.hakkenphillips@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9889",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9889"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/33c30ffc-2203-48fc-9161-e14946abce23",
            "firstName": "James Russell",
            "lastName": "Muirhead",
            "fullName": "Russ Muirhead",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://snworksceo.imgix.net/drt/1ca40038-9f0d-422c-b469-afddf7607f69.sized-1000x1000.jpg?dpr=2",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 12",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 12-lower-ocd-person-33c30ffc-2203-48fc-9161-e14946abce23",
              "builtID": "nh-lower-grafton 12",
              "externalID": "ocd-person/33c30ffc-2203-48fc-9161-e14946abce23",
              "geometry": null
            },
            "contactInfo": {
              "email": "russell.muirhead@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9890",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9890"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 12-lower-ocd-person-c88c54ce-42e9-4111-8e1a-164ee43eaa89": 0,
          "SLDL-NH-Grafton 12-lower-ocd-person-0929165d-f73c-4820-8820-209de58c1e78": 1,
          "SLDL-NH-Grafton 12-lower-ocd-person-33c30ffc-2203-48fc-9161-e14946abce23": 2
        }
      },
      "Merrimack 18": {
        "members": [
          {
            "API_ID": "ocd-person/c027d650-709d-440f-8740-84b3e11406ba",
            "firstName": "James R.",
            "lastName": "MacKay",
            "fullName": "Jim MacKay",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376028.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 18",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 18-lower-ocd-person-c027d650-709d-440f-8740-84b3e11406ba",
              "builtID": "nh-lower-merrimack 18",
              "externalID": "ocd-person/c027d650-709d-440f-8740-84b3e11406ba",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.mackay@mygait.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=440",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376028"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 18-lower-ocd-person-c027d650-709d-440f-8740-84b3e11406ba": 0
        }
      },
      "Cheshire 18": {
        "members": [
          {
            "API_ID": "ocd-person/8ee6ee68-5ccb-4871-b815-6ed27c68c3ae",
            "firstName": "James",
            "lastName": "Qualey",
            "fullName": "Jim Qualey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Jim-Qualey.PNG",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 18",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 18-lower-ocd-person-8ee6ee68-5ccb-4871-b815-6ed27c68c3ae",
              "builtID": "nh-lower-cheshire 18",
              "externalID": "ocd-person/8ee6ee68-5ccb-4871-b815-6ed27c68c3ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.qualey@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9875",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9875"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/04fa0b25-21fd-44d0-a34f-3b251539c023",
            "firstName": "Matthew J.",
            "lastName": "Santonastaso",
            "fullName": "Matthew Santonastaso",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Matthew-Santonastaso.PNG",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 18",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 18-lower-ocd-person-04fa0b25-21fd-44d0-a34f-3b251539c023",
              "builtID": "nh-lower-cheshire 18",
              "externalID": "ocd-person/04fa0b25-21fd-44d0-a34f-3b251539c023",
              "geometry": null
            },
            "contactInfo": {
              "email": "santonastaso@cheshireliberty.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9877",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9877"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 18-lower-ocd-person-8ee6ee68-5ccb-4871-b815-6ed27c68c3ae": 0,
          "SLDL-NH-Cheshire 18-lower-ocd-person-04fa0b25-21fd-44d0-a34f-3b251539c023": 1
        }
      },
      "Coos 1": {
        "members": [
          {
            "API_ID": "ocd-person/0723bc3a-b040-40e5-9eaa-60c12f148828",
            "firstName": "James W.",
            "lastName": "Tierney",
            "fullName": "James Tierney",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Coos 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Coos 1-lower-ocd-person-0723bc3a-b040-40e5-9eaa-60c12f148828",
              "builtID": "nh-lower-coos 1",
              "externalID": "ocd-person/0723bc3a-b040-40e5-9eaa-60c12f148828",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.tierney@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8023"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8d99d9dc-999d-438c-84c8-01d80f1ef5cd",
            "firstName": "Sean",
            "lastName": "Durkin",
            "fullName": "Sean Durkin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://nhjournal.com/wp-content/uploads/2024/01/sean-durkin-300x300.jpeg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Coos 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Coos 1-lower-ocd-person-8d99d9dc-999d-438c-84c8-01d80f1ef5cd",
              "builtID": "nh-lower-coos 1",
              "externalID": "ocd-person/8d99d9dc-999d-438c-84c8-01d80f1ef5cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "seandurkin4nh@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=11118"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Coos 1-lower-ocd-person-0723bc3a-b040-40e5-9eaa-60c12f148828": 0,
          "SLDL-NH-Coos 1-lower-ocd-person-8d99d9dc-999d-438c-84c8-01d80f1ef5cd": 1
        }
      },
      "Grafton 2": {
        "members": [
          {
            "API_ID": "ocd-person/80eb1932-bf31-4008-b48b-640178bfa0d3",
            "firstName": "Jared",
            "lastName": "Sullivan",
            "fullName": "Jared Sullivan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-10/jared-sullivan.jpg?itok=-ZrM4eNJ",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 2-lower-ocd-person-80eb1932-bf31-4008-b48b-640178bfa0d3",
              "builtID": "nh-lower-grafton 2",
              "externalID": "ocd-person/80eb1932-bf31-4008-b48b-640178bfa0d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "jared.sullivan@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10761"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 2-lower-ocd-person-80eb1932-bf31-4008-b48b-640178bfa0d3": 0
        }
      },
      "Rockingham 40": {
        "members": [
          {
            "API_ID": "ocd-person/5411c21d-e8ee-4300-a79b-1a688e01c481",
            "firstName": "Jason",
            "lastName": "Janvrin",
            "fullName": "Jason Janvrin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408422.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 40",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 40-lower-ocd-person-5411c21d-e8ee-4300-a79b-1a688e01c481",
              "builtID": "nh-lower-rockingham 40",
              "externalID": "ocd-person/5411c21d-e8ee-4300-a79b-1a688e01c481",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.janvrin@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8422",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=408422"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 40-lower-ocd-person-5411c21d-e8ee-4300-a79b-1a688e01c481": 0
        }
      },
      "Merrimack 25": {
        "members": [
          {
            "API_ID": "ocd-person/9726db41-c8d6-4eb7-b860-c1953880b9ff",
            "firstName": "Jason",
            "lastName": "Gerhard",
            "fullName": "Jason Gerhard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409099.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 25",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 25-lower-ocd-person-9726db41-c8d6-4eb7-b860-c1953880b9ff",
              "builtID": "nh-lower-merrimack 25",
              "externalID": "ocd-person/9726db41-c8d6-4eb7-b860-c1953880b9ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.gerhard@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10681"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 25-lower-ocd-person-9726db41-c8d6-4eb7-b860-c1953880b9ff": 0
        }
      },
      "Hillsborough 23": {
        "members": [
          {
            "API_ID": "ocd-person/f47878eb-66fe-4d01-92ea-4d3190c6183c",
            "firstName": "Jean Leniol",
            "lastName": "Jeudy",
            "fullName": "Jean Jeudy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376657.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 23",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 23-lower-ocd-person-f47878eb-66fe-4d01-92ea-4d3190c6183c",
              "builtID": "nh-lower-hillsborough 23",
              "externalID": "ocd-person/f47878eb-66fe-4d01-92ea-4d3190c6183c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jean.jeudy@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=527",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376657"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/14d0ff58-3340-4344-bb79-c6acd5f8ba6c",
            "firstName": "Patrick T.",
            "lastName": "Long",
            "fullName": "Patrick Long",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://swww.gencourt.state.nh.us/house/images/memberpics/376696.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 23",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 23-lower-ocd-person-14d0ff58-3340-4344-bb79-c6acd5f8ba6c",
              "builtID": "nh-lower-hillsborough 23",
              "externalID": "ocd-person/14d0ff58-3340-4344-bb79-c6acd5f8ba6c",
              "geometry": null
            },
            "contactInfo": {
              "email": "long55@comcast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=540",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376696"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 23-lower-ocd-person-f47878eb-66fe-4d01-92ea-4d3190c6183c": 0,
          "SLDL-NH-Hillsborough 23-lower-ocd-person-14d0ff58-3340-4344-bb79-c6acd5f8ba6c": 1
        }
      },
      "Grafton 6": {
        "members": [
          {
            "API_ID": "ocd-person/9a227955-60e0-45e3-bea8-03f44f4cd08e",
            "firstName": "Jeffrey",
            "lastName": "Greeson",
            "fullName": "Jeffrey Greeson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2020-08/JeffreyGreeson.png?itok=qmn6EV6_",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 6-lower-ocd-person-9a227955-60e0-45e3-bea8-03f44f4cd08e",
              "builtID": "nh-lower-grafton 6",
              "externalID": "ocd-person/9a227955-60e0-45e3-bea8-03f44f4cd08e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeffrey.greeson@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9893",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9893"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 6-lower-ocd-person-9a227955-60e0-45e3-bea8-03f44f4cd08e": 0
        }
      },
      "Hillsborough 1": {
        "members": [
          {
            "API_ID": "ocd-person/ff500f6d-8768-4947-af54-b2881b1134a4",
            "firstName": "Jeffrey",
            "lastName": "Tenczar",
            "fullName": "Jeff Tenczar",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-07/tenczar-jeff.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 1-lower-ocd-person-ff500f6d-8768-4947-af54-b2881b1134a4",
              "builtID": "nh-lower-hillsborough 1",
              "externalID": "ocd-person/ff500f6d-8768-4947-af54-b2881b1134a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeffrey.tenczar@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10624"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/25d631e5-9851-47a8-a4f5-7d7d412c7dd3",
            "firstName": "Kimberly Lyon",
            "lastName": "Abare",
            "fullName": "Kim Abare",
            "gender": "Female",
            "party": "Republican",
            "image": "http://t2.gstatic.com/licensed-image?q=tbn:ANd9GcT1IpRKaFLCxC8ycAY2FL4msgYAN09PHp1SmwQZXbx1V48DLmWyACXL9nyUol5cUFI7",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 1-lower-ocd-person-25d631e5-9851-47a8-a4f5-7d7d412c7dd3",
              "builtID": "nh-lower-hillsborough 1",
              "externalID": "ocd-person/25d631e5-9851-47a8-a4f5-7d7d412c7dd3",
              "geometry": null
            },
            "contactInfo": {
              "email": "kimberly.abare@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10621"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ca35671b-d04a-498c-b041-46441c079247",
            "firstName": "Sandra Arsenault",
            "lastName": "Panek",
            "fullName": "Sandra Panek",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-08/sandra-panek.jpg?itok=zZoNLM_c",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 1-lower-ocd-person-ca35671b-d04a-498c-b041-46441c079247",
              "builtID": "nh-lower-hillsborough 1",
              "externalID": "ocd-person/ca35671b-d04a-498c-b041-46441c079247",
              "geometry": null
            },
            "contactInfo": {
              "email": "sandra.panek@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10623",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10623"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/6bf9fba1-96e4-46af-843d-5e006d357acd",
            "firstName": "Tom",
            "lastName": "Mannion",
            "fullName": "Tom Mannion",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Tom_Mannion.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 1-lower-ocd-person-6bf9fba1-96e4-46af-843d-5e006d357acd",
              "builtID": "nh-lower-hillsborough 1",
              "externalID": "ocd-person/6bf9fba1-96e4-46af-843d-5e006d357acd",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.mannion@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10622"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 1-lower-ocd-person-ff500f6d-8768-4947-af54-b2881b1134a4": 0,
          "SLDL-NH-Hillsborough 1-lower-ocd-person-25d631e5-9851-47a8-a4f5-7d7d412c7dd3": 1,
          "SLDL-NH-Hillsborough 1-lower-ocd-person-ca35671b-d04a-498c-b041-46441c079247": 2,
          "SLDL-NH-Hillsborough 1-lower-ocd-person-6bf9fba1-96e4-46af-843d-5e006d357acd": 3
        }
      },
      "Cheshire 17": {
        "members": [
          {
            "API_ID": "ocd-person/50eaea14-c7e4-4aac-890e-bda69eb86978",
            "firstName": "Jennifer",
            "lastName": "Rhodes",
            "fullName": "Jennifer Rhodes",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408979.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 17",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 17-lower-ocd-person-50eaea14-c7e4-4aac-890e-bda69eb86978",
              "builtID": "nh-lower-cheshire 17",
              "externalID": "ocd-person/50eaea14-c7e4-4aac-890e-bda69eb86978",
              "geometry": null
            },
            "contactInfo": {
              "email": "jennifer.rhodes@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9878",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9878"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 17-lower-ocd-person-50eaea14-c7e4-4aac-890e-bda69eb86978": 0
        }
      },
      "Rockingham 21": {
        "members": [
          {
            "API_ID": "ocd-person/64122b0a-5dcf-4f7e-aa74-cff67dcb67b3",
            "firstName": "Jennifer",
            "lastName": "Mandelbaum",
            "fullName": "Jennifer Mandelbaum",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/JenniferMandelbaum2024.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 21",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 21-lower-ocd-person-64122b0a-5dcf-4f7e-aa74-cff67dcb67b3",
              "builtID": "nh-lower-rockingham 21",
              "externalID": "ocd-person/64122b0a-5dcf-4f7e-aa74-cff67dcb67b3",
              "geometry": null
            },
            "contactInfo": {
              "email": "jenfornh@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=11135"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 21-lower-ocd-person-64122b0a-5dcf-4f7e-aa74-cff67dcb67b3": 0
        }
      },
      "Grafton 3": {
        "members": [
          {
            "API_ID": "ocd-person/80181303-730c-46b5-88e5-ccfbf32b132f",
            "firstName": "Jerry M.",
            "lastName": "Stringham",
            "fullName": "Jerry Stringham",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://jerrymstringham.com/wp-content/uploads/2018/08/profile2-600x679.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 3",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 3-lower-ocd-person-80181303-730c-46b5-88e5-ccfbf32b132f",
              "builtID": "nh-lower-grafton 3",
              "externalID": "ocd-person/80181303-730c-46b5-88e5-ccfbf32b132f",
              "geometry": null
            },
            "contactInfo": {
              "email": "jerry.stringham@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9058",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9058"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 3-lower-ocd-person-80181303-730c-46b5-88e5-ccfbf32b132f": 0
        }
      },
      "Rockingham 31": {
        "members": [
          {
            "API_ID": "ocd-person/3787c84d-f615-4e9d-bac8-42f86ea308db",
            "firstName": "Jess",
            "lastName": "Edwards",
            "fullName": "Jess Edwards",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408392.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 31",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 31-lower-ocd-person-3787c84d-f615-4e9d-bac8-42f86ea308db",
              "builtID": "nh-lower-rockingham 31",
              "externalID": "ocd-person/3787c84d-f615-4e9d-bac8-42f86ea308db",
              "geometry": null
            },
            "contactInfo": {
              "email": "jess.edwards@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8392",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408392"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/6d43b165-fda9-4918-a2b7-ad66940062a0",
            "firstName": "Terry",
            "lastName": "Roy",
            "fullName": "Terry Roy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408925.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 31",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 31-lower-ocd-person-6d43b165-fda9-4918-a2b7-ad66940062a0",
              "builtID": "nh-lower-rockingham 31",
              "externalID": "ocd-person/6d43b165-fda9-4918-a2b7-ad66940062a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "terry.roy@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9272",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408925"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 31-lower-ocd-person-3787c84d-f615-4e9d-bac8-42f86ea308db": 0,
          "SLDL-NH-Rockingham 31-lower-ocd-person-6d43b165-fda9-4918-a2b7-ad66940062a0": 1
        }
      },
      "Strafford 17": {
        "members": [
          {
            "API_ID": "ocd-person/47233e65-87c6-4a8b-ba37-271c2413615d",
            "firstName": "Jessica F.",
            "lastName": "LaMontagne",
            "fullName": "Jessica LaMontagne",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409120.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 17",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 17-lower-ocd-person-47233e65-87c6-4a8b-ba37-271c2413615d",
              "builtID": "nh-lower-strafford 17",
              "externalID": "ocd-person/47233e65-87c6-4a8b-ba37-271c2413615d",
              "geometry": null
            },
            "contactInfo": {
              "email": "jessica.lamontagne@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10900"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 17-lower-ocd-person-47233e65-87c6-4a8b-ba37-271c2413615d": 0
        }
      },
      "Hillsborough 18": {
        "members": [
          {
            "API_ID": "ocd-person/d607d081-ecb8-4d5d-bc2f-d3ee78a627c7",
            "firstName": "Jessica Michelle",
            "lastName": "Grill",
            "fullName": "Jessica Grill",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Jessica_Grill.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 18",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 18-lower-ocd-person-d607d081-ecb8-4d5d-bc2f-d3ee78a627c7",
              "builtID": "nh-lower-hillsborough 18",
              "externalID": "ocd-person/d607d081-ecb8-4d5d-bc2f-d3ee78a627c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "jessica.grill@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10797"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b0fe396b-3c66-4220-a83b-3cc844c6b32f",
            "firstName": "Juliet L.",
            "lastName": "Smith",
            "fullName": "Juliet Smith",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://vote.manchesterinklink.com/electionsnh/wp-content/uploads/julie-smith.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 18",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 18-lower-ocd-person-b0fe396b-3c66-4220-a83b-3cc844c6b32f",
              "builtID": "nh-lower-hillsborough 18",
              "externalID": "ocd-person/b0fe396b-3c66-4220-a83b-3cc844c6b32f",
              "geometry": null
            },
            "contactInfo": {
              "email": "juliet.smith@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10798"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 18-lower-ocd-person-d607d081-ecb8-4d5d-bc2f-d3ee78a627c7": 0,
          "SLDL-NH-Hillsborough 18-lower-ocd-person-b0fe396b-3c66-4220-a83b-3cc844c6b32f": 1
        }
      },
      "Hillsborough 30": {
        "members": [
          {
            "API_ID": "ocd-person/de8891e6-5b3f-4fb5-8c5d-63ab84c70e59",
            "firstName": "Jim",
            "lastName": "Fedolfi",
            "fullName": "Jim Fedolfi",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408299.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 30",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 30-lower-ocd-person-de8891e6-5b3f-4fb5-8c5d-63ab84c70e59",
              "builtID": "nh-lower-hillsborough 30",
              "externalID": "ocd-person/de8891e6-5b3f-4fb5-8c5d-63ab84c70e59",
              "geometry": null
            },
            "contactInfo": {
              "email": "jimfornh@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8299",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408299"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e6b59654-1e8f-4f6b-b01f-7d69bb76ffec",
            "firstName": "James",
            "lastName": "Creighton",
            "fullName": "Jim Creighton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409017.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 30",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 30-lower-ocd-person-e6b59654-1e8f-4f6b-b01f-7d69bb76ffec",
              "builtID": "nh-lower-hillsborough 30",
              "externalID": "ocd-person/e6b59654-1e8f-4f6b-b01f-7d69bb76ffec",
              "geometry": null
            },
            "contactInfo": {
              "email": "creighton4nh@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9917",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9917"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f34606de-25d3-4e18-991e-b21727563eb6",
            "firstName": "Riche",
            "lastName": "Colcombe",
            "fullName": "Riché Colcombe",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/richecolcombe2.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 30",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 30-lower-ocd-person-f34606de-25d3-4e18-991e-b21727563eb6",
              "builtID": "nh-lower-hillsborough 30",
              "externalID": "ocd-person/f34606de-25d3-4e18-991e-b21727563eb6",
              "geometry": null
            },
            "contactInfo": {
              "email": "riche.colcombe@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10099"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 30-lower-ocd-person-de8891e6-5b3f-4fb5-8c5d-63ab84c70e59": 0,
          "SLDL-NH-Hillsborough 30-lower-ocd-person-e6b59654-1e8f-4f6b-b01f-7d69bb76ffec": 1,
          "SLDL-NH-Hillsborough 30-lower-ocd-person-f34606de-25d3-4e18-991e-b21727563eb6": 2
        }
      },
      "Rockingham 23": {
        "members": [
          {
            "API_ID": "ocd-person/deb760cf-91f7-415c-949a-bab0c5311d6f",
            "firstName": "Jim",
            "lastName": "Maggiore",
            "fullName": "Jim Maggiore",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/candidate-images/jimmaggiore.jpg?itok=mrb5_XQt",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 23",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 23-lower-ocd-person-deb760cf-91f7-415c-949a-bab0c5311d6f",
              "builtID": "nh-lower-rockingham 23",
              "externalID": "ocd-person/deb760cf-91f7-415c-949a-bab0c5311d6f",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.maggiore@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9224",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9224"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 23-lower-ocd-person-deb760cf-91f7-415c-949a-bab0c5311d6f": 0
        }
      },
      "Rockingham 26": {
        "members": [
          {
            "API_ID": "ocd-person/c0b6bd27-86aa-48a8-8c15-9724581b1145",
            "firstName": "Joan L.",
            "lastName": "Hamblet",
            "fullName": "Joan Hamblet",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409045.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 26",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 26-lower-ocd-person-c0b6bd27-86aa-48a8-8c15-9724581b1145",
              "builtID": "nh-lower-rockingham 26",
              "externalID": "ocd-person/c0b6bd27-86aa-48a8-8c15-9724581b1145",
              "geometry": null
            },
            "contactInfo": {
              "email": "joan.hamblet@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9945",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9945"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 26-lower-ocd-person-c0b6bd27-86aa-48a8-8c15-9724581b1145": 0
        }
      },
      "Cheshire 4": {
        "members": [
          {
            "API_ID": "ocd-person/97c57bb8-58d9-43b2-9ccb-5c926022f89f",
            "firstName": "Jodi K.",
            "lastName": "Newell",
            "fullName": "Jodi Newell",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-09/Jodi-Newell.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 4-lower-ocd-person-97c57bb8-58d9-43b2-9ccb-5c926022f89f",
              "builtID": "nh-lower-cheshire 4",
              "externalID": "ocd-person/97c57bb8-58d9-43b2-9ccb-5c926022f89f",
              "geometry": null
            },
            "contactInfo": {
              "email": "jodi.newell@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10750",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10750"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 4-lower-ocd-person-97c57bb8-58d9-43b2-9ccb-5c926022f89f": 0
        }
      },
      "Cheshire 16": {
        "members": [
          {
            "API_ID": "ocd-person/44fbe7a3-bc2c-4ab0-b669-35521e926782",
            "firstName": "Joe",
            "lastName": "Schapiro",
            "fullName": "Joe Schapiro",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408830.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 16",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 16-lower-ocd-person-44fbe7a3-bc2c-4ab0-b669-35521e926782",
              "builtID": "nh-lower-cheshire 16",
              "externalID": "ocd-person/44fbe7a3-bc2c-4ab0-b669-35521e926782",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.schapiro@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9057",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9057"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 16-lower-ocd-person-44fbe7a3-bc2c-4ab0-b669-35521e926782": 0
        }
      },
      "Merrimack 10": {
        "members": [
          {
            "API_ID": "ocd-person/c4ba3022-267a-4ca0-8aad-2a2daa3af0db",
            "firstName": "John Anthony",
            "lastName": "Leavitt",
            "fullName": "John Leavitt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408381.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 10-lower-ocd-person-c4ba3022-267a-4ca0-8aad-2a2daa3af0db",
              "builtID": "nh-lower-merrimack 10",
              "externalID": "ocd-person/c4ba3022-267a-4ca0-8aad-2a2daa3af0db",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.leavitt@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8381",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=8381"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b46ed135-4c70-40b9-81ab-94660b76be3e",
            "firstName": "Stephen",
            "lastName": "Boyd",
            "fullName": "Stephen Boyd",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/StephenBoyd.jfif",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 10-lower-ocd-person-b46ed135-4c70-40b9-81ab-94660b76be3e",
              "builtID": "nh-lower-merrimack 10",
              "externalID": "ocd-person/b46ed135-4c70-40b9-81ab-94660b76be3e",
              "geometry": null
            },
            "contactInfo": {
              "email": "seboyd2020@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9929",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9929"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e325954b-c90e-4b1e-aadb-3f5cc7ec1671",
            "firstName": "Thomas C.",
            "lastName": "Walsh",
            "fullName": "Thomas Walsh",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377187.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 10-lower-ocd-person-e325954b-c90e-4b1e-aadb-3f5cc7ec1671",
              "builtID": "nh-lower-merrimack 10",
              "externalID": "ocd-person/e325954b-c90e-4b1e-aadb-3f5cc7ec1671",
              "geometry": null
            },
            "contactInfo": {
              "email": "thomas.walsh@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=791",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377187"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/3bc62111-d292-4ae2-a648-43b858308394",
            "firstName": "Yury",
            "lastName": "Polozov",
            "fullName": "Yury Polozov",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-09/yuri-polozov.jpg?itok=ES4GDjOh",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 10-lower-ocd-person-3bc62111-d292-4ae2-a648-43b858308394",
              "builtID": "nh-lower-merrimack 10",
              "externalID": "ocd-person/3bc62111-d292-4ae2-a648-43b858308394",
              "geometry": null
            },
            "contactInfo": {
              "email": "yury.polozov@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10676"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 10-lower-ocd-person-c4ba3022-267a-4ca0-8aad-2a2daa3af0db": 0,
          "SLDL-NH-Merrimack 10-lower-ocd-person-b46ed135-4c70-40b9-81ab-94660b76be3e": 1,
          "SLDL-NH-Merrimack 10-lower-ocd-person-e325954b-c90e-4b1e-aadb-3f5cc7ec1671": 2,
          "SLDL-NH-Merrimack 10-lower-ocd-person-3bc62111-d292-4ae2-a648-43b858308394": 3
        }
      },
      "Cheshire 14": {
        "members": [
          {
            "API_ID": "ocd-person/35c51630-9b9c-411a-9b98-0842cb6093df",
            "firstName": "John B.",
            "lastName": "Hunt",
            "fullName": "John Hunt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/372375.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 14",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 14-lower-ocd-person-35c51630-9b9c-411a-9b98-0842cb6093df",
              "builtID": "nh-lower-cheshire 14",
              "externalID": "ocd-person/35c51630-9b9c-411a-9b98-0842cb6093df",
              "geometry": null
            },
            "contactInfo": {
              "email": "jbhunt@prodigy.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=414",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=372375"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 14-lower-ocd-person-35c51630-9b9c-411a-9b98-0842cb6093df": 0
        }
      },
      "Grafton 18": {
        "members": [
          {
            "API_ID": "ocd-person/a365b40c-5682-4627-ae84-764e7da7219e",
            "firstName": "John",
            "lastName": "Sellers",
            "fullName": "John Sellers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409169.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 18",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 18-lower-ocd-person-a365b40c-5682-4627-ae84-764e7da7219e",
              "builtID": "nh-lower-grafton 18",
              "externalID": "ocd-person/a365b40c-5682-4627-ae84-764e7da7219e",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.sellers@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10620"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 18-lower-ocd-person-a365b40c-5682-4627-ae84-764e7da7219e": 0
        }
      },
      "Carroll 6": {
        "members": [
          {
            "API_ID": "ocd-person/a22a36bb-3514-4d76-a39e-791902f41d6c",
            "firstName": "John T.",
            "lastName": "MacDonald",
            "fullName": "John MacDonald",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/candidate-images/JohnTMacdonald.jpg?itok=E0Xsy4nv",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Carroll 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Carroll 6-lower-ocd-person-a22a36bb-3514-4d76-a39e-791902f41d6c",
              "builtID": "nh-lower-carroll 6",
              "externalID": "ocd-person/a22a36bb-3514-4d76-a39e-791902f41d6c",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.macdonald@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9411",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9411"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/437d084e-35d3-4a37-9ccb-61d6fcc43b1c",
            "firstName": "Katy",
            "lastName": "Peternel",
            "fullName": "Katy Peternel",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-07/Katy-Peternel.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Carroll 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Carroll 6-lower-ocd-person-437d084e-35d3-4a37-9ccb-61d6fcc43b1c",
              "builtID": "nh-lower-carroll 6",
              "externalID": "ocd-person/437d084e-35d3-4a37-9ccb-61d6fcc43b1c",
              "geometry": null
            },
            "contactInfo": {
              "email": "katy.peternel@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10579"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Carroll 6-lower-ocd-person-a22a36bb-3514-4d76-a39e-791902f41d6c": 0,
          "SLDL-NH-Carroll 6-lower-ocd-person-437d084e-35d3-4a37-9ccb-61d6fcc43b1c": 1
        }
      },
      "Hillsborough 33": {
        "members": [
          {
            "API_ID": "ocd-person/87b1ae9f-d7ad-4fc2-b96c-a03c1926c4b1",
            "firstName": "Jonah",
            "lastName": "Wheeler",
            "fullName": "Jonah Wheeler",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://static.wixstatic.com/media/4655f1_d2a8a2149cf54e91832ddb103872eee0~mv2.jpg/v1/fill/w_400,h_400,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/i-BFnhF57-X4_edited.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 33",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 33-lower-ocd-person-87b1ae9f-d7ad-4fc2-b96c-a03c1926c4b1",
              "builtID": "nh-lower-hillsborough 33",
              "externalID": "ocd-person/87b1ae9f-d7ad-4fc2-b96c-a03c1926c4b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "jonah.wheeler@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10813"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/86708f42-531f-4e57-a6b1-f5b11ff9389c",
            "firstName": "Peter R.",
            "lastName": "Leishman",
            "fullName": "Peter Leishman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376139.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 33",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 33-lower-ocd-person-86708f42-531f-4e57-a6b1-f5b11ff9389c",
              "builtID": "nh-lower-hillsborough 33",
              "externalID": "ocd-person/86708f42-531f-4e57-a6b1-f5b11ff9389c",
              "geometry": null
            },
            "contactInfo": {
              "email": "prleishman@aol.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=450",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376139"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 33-lower-ocd-person-87b1ae9f-d7ad-4fc2-b96c-a03c1926c4b1": 0,
          "SLDL-NH-Hillsborough 33-lower-ocd-person-86708f42-531f-4e57-a6b1-f5b11ff9389c": 1
        }
      },
      "Carroll 5": {
        "members": [
          {
            "API_ID": "ocd-person/214b10b8-1ed6-4964-8aab-32c6609965f6",
            "firstName": "Jonathan H.",
            "lastName": "Smith",
            "fullName": "Jonathan Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.newyork1.vip.townnews.com/conwaydailysun.com/content/tncms/assets/v3/editorial/d/32/d32c40ee-0fb6-11eb-8b8e-a7afe05f98e4/5f8a1bf422620.image.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Carroll 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Carroll 5-lower-ocd-person-214b10b8-1ed6-4964-8aab-32c6609965f6",
              "builtID": "nh-lower-carroll 5",
              "externalID": "ocd-person/214b10b8-1ed6-4964-8aab-32c6609965f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "jonathan.smith@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9869",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9869"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Carroll 5-lower-ocd-person-214b10b8-1ed6-4964-8aab-32c6609965f6": 0
        }
      },
      "Merrimack 4": {
        "members": [
          {
            "API_ID": "ocd-person/3d1f7d21-9a20-4245-b51f-03ca0d2b14eb",
            "firstName": "Jose",
            "lastName": "Cambrils",
            "fullName": "Jose Cambrils",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409025.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 4-lower-ocd-person-3d1f7d21-9a20-4245-b51f-03ca0d2b14eb",
              "builtID": "nh-lower-merrimack 4",
              "externalID": "ocd-person/3d1f7d21-9a20-4245-b51f-03ca0d2b14eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "jose4nh@comcast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9925",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9925"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/acb20216-f193-42e1-9304-2b1d3572e61d",
            "firstName": "Michael",
            "lastName": "Moffett",
            "fullName": "Mike Moffett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/407144.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 4-lower-ocd-person-acb20216-f193-42e1-9304-2b1d3572e61d",
              "builtID": "nh-lower-merrimack 4",
              "externalID": "ocd-person/acb20216-f193-42e1-9304-2b1d3572e61d",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.moffett@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=7144"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 4-lower-ocd-person-3d1f7d21-9a20-4245-b51f-03ca0d2b14eb": 0,
          "SLDL-NH-Merrimack 4-lower-ocd-person-acb20216-f193-42e1-9304-2b1d3572e61d": 1
        }
      },
      "Rockingham 15": {
        "members": [
          {
            "API_ID": "ocd-person/772f5157-b959-4aed-beaa-6bd67ab8fefe",
            "firstName": "Joseph",
            "lastName": "Guthrie",
            "fullName": "Joe Guthrie",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376127.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 15",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 15-lower-ocd-person-772f5157-b959-4aed-beaa-6bd67ab8fefe",
              "builtID": "nh-lower-rockingham 15",
              "externalID": "ocd-person/772f5157-b959-4aed-beaa-6bd67ab8fefe",
              "geometry": null
            },
            "contactInfo": {
              "email": "joseph.guthrie@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=936",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376127"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/554fe6ba-061a-413f-b2c7-c0e10c47ad01",
            "firstName": "Lilli",
            "lastName": "Walsh",
            "fullName": "Lilli Walsh",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409185.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 15",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 15-lower-ocd-person-554fe6ba-061a-413f-b2c7-c0e10c47ad01",
              "builtID": "nh-lower-rockingham 15",
              "externalID": "ocd-person/554fe6ba-061a-413f-b2c7-c0e10c47ad01",
              "geometry": null
            },
            "contactInfo": {
              "email": "lilli.walsh@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10692"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 15-lower-ocd-person-772f5157-b959-4aed-beaa-6bd67ab8fefe": 0,
          "SLDL-NH-Rockingham 15-lower-ocd-person-554fe6ba-061a-413f-b2c7-c0e10c47ad01": 1
        }
      },
      "Rockingham 32": {
        "members": [
          {
            "API_ID": "ocd-person/c96f9d7a-b859-4b37-8fdd-4097b21d3f2c",
            "firstName": "Josh",
            "lastName": "Yokela",
            "fullName": "Josh Yokela",
            "gender": "Male",
            "party": "Republican",
            "image": "https://http://www.gencourt.state.nh.us/house/images/memberpics/408926.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 32",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 32-lower-ocd-person-c96f9d7a-b859-4b37-8fdd-4097b21d3f2c",
              "builtID": "nh-lower-rockingham 32",
              "externalID": "ocd-person/c96f9d7a-b859-4b37-8fdd-4097b21d3f2c",
              "geometry": null
            },
            "contactInfo": {
              "email": "josh.yokela@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9273",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9273"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 32-lower-ocd-person-c96f9d7a-b859-4b37-8fdd-4097b21d3f2c": 0
        }
      },
      "Sullivan 4": {
        "members": [
          {
            "API_ID": "ocd-person/533fb85c-c4f0-41f3-9bc6-8b1c422a9693",
            "firstName": "Judy",
            "lastName": "Aron",
            "fullName": "Judy Aron",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408942.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Sullivan 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Sullivan 4-lower-ocd-person-533fb85c-c4f0-41f3-9bc6-8b1c422a9693",
              "builtID": "nh-lower-sullivan 4",
              "externalID": "ocd-person/533fb85c-c4f0-41f3-9bc6-8b1c422a9693",
              "geometry": null
            },
            "contactInfo": {
              "email": "judy.aron@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9429",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9429"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Sullivan 4-lower-ocd-person-533fb85c-c4f0-41f3-9bc6-8b1c422a9693": 0
        }
      },
      "Belknap 3": {
        "members": [
          {
            "API_ID": "ocd-person/5d7f39ae-2cc4-4b36-bd76-828a24bc454d",
            "firstName": "Juliet",
            "lastName": "Harvey-Bolia",
            "fullName": "Juliet Harvey-Bolia",
            "gender": "Female",
            "party": "Republican",
            "image": "https://static.votesmart.org/static/canphoto/194686.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 3",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 3-lower-ocd-person-5d7f39ae-2cc4-4b36-bd76-828a24bc454d",
              "builtID": "nh-lower-belknap 3",
              "externalID": "ocd-person/5d7f39ae-2cc4-4b36-bd76-828a24bc454d",
              "geometry": null
            },
            "contactInfo": {
              "email": "juliet.harveybolia@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9865",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9865"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Belknap 3-lower-ocd-person-5d7f39ae-2cc4-4b36-bd76-828a24bc454d": 0
        }
      },
      "Rockingham 35": {
        "members": [
          {
            "API_ID": "ocd-person/0d4bc96e-c56f-42a3-b602-54222dfdd883",
            "firstName": "Julius Fredi",
            "lastName": "Soti",
            "fullName": "Julius Soti",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409036.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 35",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 35-lower-ocd-person-0d4bc96e-c56f-42a3-b602-54222dfdd883",
              "builtID": "nh-lower-rockingham 35",
              "externalID": "ocd-person/0d4bc96e-c56f-42a3-b602-54222dfdd883",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jsoti@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9936",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9936"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 35-lower-ocd-person-0d4bc96e-c56f-42a3-b602-54222dfdd883": 0
        }
      },
      "Carroll 3": {
        "members": [
          {
            "API_ID": "ocd-person/a0936f2f-8170-47f9-8b05-6c6697a95968",
            "firstName": "Karel",
            "lastName": "Crawford",
            "fullName": "Karel Crawford",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377087.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Carroll 3",
              "chamber": "lower",
              "hashID": "SLDL-NH-Carroll 3-lower-ocd-person-a0936f2f-8170-47f9-8b05-6c6697a95968",
              "builtID": "nh-lower-carroll 3",
              "externalID": "ocd-person/a0936f2f-8170-47f9-8b05-6c6697a95968",
              "geometry": null
            },
            "contactInfo": {
              "email": "karel.crawford@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=694",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377087"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d08d07c4-6aa0-4ce4-a940-924ab72e216f",
            "firstName": "Richard R.",
            "lastName": "Brown",
            "fullName": "Richard Brown",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-08/richard-brown.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Carroll 3",
              "chamber": "lower",
              "hashID": "SLDL-NH-Carroll 3-lower-ocd-person-d08d07c4-6aa0-4ce4-a940-924ab72e216f",
              "builtID": "nh-lower-carroll 3",
              "externalID": "ocd-person/d08d07c4-6aa0-4ce4-a940-924ab72e216f",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.brown@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10577"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Carroll 3-lower-ocd-person-a0936f2f-8170-47f9-8b05-6c6697a95968": 0,
          "SLDL-NH-Carroll 3-lower-ocd-person-d08d07c4-6aa0-4ce4-a940-924ab72e216f": 1
        }
      },
      "Hillsborough 27": {
        "members": [
          {
            "API_ID": "ocd-person/2137275c-48da-4baa-bc6f-ae2c3cc2fb7e",
            "firstName": "Karen",
            "lastName": "Reid",
            "fullName": "Karen Reid",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409160.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 27",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 27-lower-ocd-person-2137275c-48da-4baa-bc6f-ae2c3cc2fb7e",
              "builtID": "nh-lower-hillsborough 27",
              "externalID": "ocd-person/2137275c-48da-4baa-bc6f-ae2c3cc2fb7e",
              "geometry": null
            },
            "contactInfo": {
              "email": "karen.reid@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10649"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 27-lower-ocd-person-2137275c-48da-4baa-bc6f-ae2c3cc2fb7e": 0
        }
      },
      "Hillsborough 45": {
        "members": [
          {
            "API_ID": "ocd-person/fa3a20db-280c-4bda-8dd5-308a59ab0efa",
            "firstName": "Karen Elizabeth",
            "lastName": "Calabro",
            "fullName": "Karen Calabro",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Karen_Calabro.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 45",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 45-lower-ocd-person-fa3a20db-280c-4bda-8dd5-308a59ab0efa",
              "builtID": "nh-lower-hillsborough 45",
              "externalID": "ocd-person/fa3a20db-280c-4bda-8dd5-308a59ab0efa",
              "geometry": null
            },
            "contactInfo": {
              "email": "karen.calabro@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10827"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 45-lower-ocd-person-fa3a20db-280c-4bda-8dd5-308a59ab0efa": 0
        }
      },
      "Rockingham 22": {
        "members": [
          {
            "API_ID": "ocd-person/79bb2147-ea4b-4706-8b38-ee2309613ea0",
            "firstName": "Kate Rushford",
            "lastName": "Murray",
            "fullName": "Kate Murray",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408619.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 22",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 22-lower-ocd-person-79bb2147-ea4b-4706-8b38-ee2309613ea0",
              "builtID": "nh-lower-rockingham 22",
              "externalID": "ocd-person/79bb2147-ea4b-4706-8b38-ee2309613ea0",
              "geometry": null
            },
            "contactInfo": {
              "email": "kate.murray@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8619",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=408619"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 22-lower-ocd-person-79bb2147-ea4b-4706-8b38-ee2309613ea0": 0
        }
      },
      "Hillsborough 28": {
        "members": [
          {
            "API_ID": "ocd-person/0e6b2514-25d6-4e16-bcbd-5029e90df22d",
            "firstName": "Keith",
            "lastName": "Erf",
            "fullName": "Keith Erf",
            "gender": "Male",
            "party": "Republican",
            "image": "https://static.votesmart.org/static/canphoto/185241.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 28",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 28-lower-ocd-person-0e6b2514-25d6-4e16-bcbd-5029e90df22d",
              "builtID": "nh-lower-hillsborough 28",
              "externalID": "ocd-person/0e6b2514-25d6-4e16-bcbd-5029e90df22d",
              "geometry": null
            },
            "contactInfo": {
              "email": "keith.erf@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9416",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9416"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b9ca7e25-b8c9-47c8-aac3-4b688cbcea42",
            "firstName": "Leah",
            "lastName": "Cushman",
            "fullName": "Leah Cushman",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.leahcushman.com/wp-content/uploads/sites/17/2020/07/leah-cushman-headshot.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 28",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 28-lower-ocd-person-b9ca7e25-b8c9-47c8-aac3-4b688cbcea42",
              "builtID": "nh-lower-hillsborough 28",
              "externalID": "ocd-person/b9ca7e25-b8c9-47c8-aac3-4b688cbcea42",
              "geometry": null
            },
            "contactInfo": {
              "email": "leah.cushman@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9894",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9894"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 28-lower-ocd-person-0e6b2514-25d6-4e16-bcbd-5029e90df22d": 0,
          "SLDL-NH-Hillsborough 28-lower-ocd-person-b9ca7e25-b8c9-47c8-aac3-4b688cbcea42": 1
        }
      },
      "Rockingham 4": {
        "members": [
          {
            "API_ID": "ocd-person/dc2cc65b-e3ca-4804-8c7e-9321674d7563",
            "firstName": "Kevin",
            "lastName": "Pratt",
            "fullName": "Kevin Pratt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://static.votesmart.org/static/canphoto/185301.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 4-lower-ocd-person-dc2cc65b-e3ca-4804-8c7e-9321674d7563",
              "builtID": "nh-lower-rockingham 4",
              "externalID": "ocd-person/dc2cc65b-e3ca-4804-8c7e-9321674d7563",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.pratt@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9264",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9264"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/07c09a56-23ba-4c8b-90bd-5668f22c5103",
            "firstName": "Mike",
            "lastName": "Drago",
            "fullName": "Mike Drago",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-08/mike-drago.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 4-lower-ocd-person-07c09a56-23ba-4c8b-90bd-5668f22c5103",
              "builtID": "nh-lower-rockingham 4",
              "externalID": "ocd-person/07c09a56-23ba-4c8b-90bd-5668f22c5103",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.drago@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10686"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/2efa037f-aac0-4b7a-92df-8b28382e1a43",
            "firstName": "Tim",
            "lastName": "Cahill",
            "fullName": "Tim Cahill",
            "gender": "Male",
            "party": "Republican",
            "image": "https://manchesterinklink.com/wp-content/uploads/2023/01/DSC_5462-scaled.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 4-lower-ocd-person-2efa037f-aac0-4b7a-92df-8b28382e1a43",
              "builtID": "nh-lower-rockingham 4",
              "externalID": "ocd-person/2efa037f-aac0-4b7a-92df-8b28382e1a43",
              "geometry": null
            },
            "contactInfo": {
              "email": "tim.cahill@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10685"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 4-lower-ocd-person-dc2cc65b-e3ca-4804-8c7e-9321674d7563": 0,
          "SLDL-NH-Rockingham 4-lower-ocd-person-07c09a56-23ba-4c8b-90bd-5668f22c5103": 1,
          "SLDL-NH-Rockingham 4-lower-ocd-person-2efa037f-aac0-4b7a-92df-8b28382e1a43": 2
        }
      },
      "Merrimack 29": {
        "members": [
          {
            "API_ID": "ocd-person/8698b473-ea4e-4126-8766-a2b5b5878702",
            "firstName": "Kristina",
            "lastName": "Schultz",
            "fullName": "Kris Schultz",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408808.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 29",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 29-lower-ocd-person-8698b473-ea4e-4126-8766-a2b5b5878702",
              "builtID": "nh-lower-merrimack 29",
              "externalID": "ocd-person/8698b473-ea4e-4126-8766-a2b5b5878702",
              "geometry": null
            },
            "contactInfo": {
              "email": "kris.schultz@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8858",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408808"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 29-lower-ocd-person-8698b473-ea4e-4126-8766-a2b5b5878702": 0
        }
      },
      "Hillsborough 16": {
        "members": [
          {
            "API_ID": "ocd-person/26d443c0-53fe-480b-bf4c-7b7a28a109e9",
            "firstName": "Larry G.",
            "lastName": "Gagne",
            "fullName": "Larry Gagne",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376814.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 16",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 16-lower-ocd-person-26d443c0-53fe-480b-bf4c-7b7a28a109e9",
              "builtID": "nh-lower-hillsborough 16",
              "externalID": "ocd-person/26d443c0-53fe-480b-bf4c-7b7a28a109e9",
              "geometry": null
            },
            "contactInfo": {
              "email": "lgagne6166@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376814",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=573"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/93966021-59f3-4dbf-9cae-3cb69f19585d",
            "firstName": "William J.",
            "lastName": "Infantine",
            "fullName": "Will Infantine",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376479.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 16",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 16-lower-ocd-person-93966021-59f3-4dbf-9cae-3cb69f19585d",
              "builtID": "nh-lower-hillsborough 16",
              "externalID": "ocd-person/93966021-59f3-4dbf-9cae-3cb69f19585d",
              "geometry": null
            },
            "contactInfo": {
              "email": "repinfantine@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=486",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=486"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 16-lower-ocd-person-26d443c0-53fe-480b-bf4c-7b7a28a109e9": 0,
          "SLDL-NH-Hillsborough 16-lower-ocd-person-93966021-59f3-4dbf-9cae-3cb69f19585d": 1
        }
      },
      "Hillsborough 11": {
        "members": [
          {
            "API_ID": "ocd-person/4f71f9e1-ce78-46d3-9665-00dd404a0f1b",
            "firstName": "Latha",
            "lastName": "Mangipudi",
            "fullName": "Latha Mangipudi",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377200.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 11",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 11-lower-ocd-person-4f71f9e1-ce78-46d3-9665-00dd404a0f1b",
              "builtID": "nh-lower-hillsborough 11",
              "externalID": "ocd-person/4f71f9e1-ce78-46d3-9665-00dd404a0f1b",
              "geometry": null
            },
            "contactInfo": {
              "email": "latha.mangipudi@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=804",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377200"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ac414b7c-45dc-48b6-9530-eaa8e80b124f",
            "firstName": "Laura Damphousse",
            "lastName": "Telerski",
            "fullName": "Laura Telerski",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408878.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 11",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 11-lower-ocd-person-ac414b7c-45dc-48b6-9530-eaa8e80b124f",
              "builtID": "nh-lower-hillsborough 11",
              "externalID": "ocd-person/ac414b7c-45dc-48b6-9530-eaa8e80b124f",
              "geometry": null
            },
            "contactInfo": {
              "email": "laura.telerski@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9117",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9117"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4c6a4d79-307b-4e5f-9d63-ba4f60a6892c",
            "firstName": "William",
            "lastName": "Darby",
            "fullName": "Will Darby",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409086.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 11",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 11-lower-ocd-person-4c6a4d79-307b-4e5f-9d63-ba4f60a6892c",
              "builtID": "nh-lower-hillsborough 11",
              "externalID": "ocd-person/4c6a4d79-307b-4e5f-9d63-ba4f60a6892c",
              "geometry": null
            },
            "contactInfo": {
              "email": "will.darby@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10785"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 11-lower-ocd-person-4f71f9e1-ce78-46d3-9665-00dd404a0f1b": 0,
          "SLDL-NH-Hillsborough 11-lower-ocd-person-ac414b7c-45dc-48b6-9530-eaa8e80b124f": 1,
          "SLDL-NH-Hillsborough 11-lower-ocd-person-4c6a4d79-307b-4e5f-9d63-ba4f60a6892c": 2
        }
      },
      "Grafton 13": {
        "members": [
          {
            "API_ID": "ocd-person/90ca1969-94b3-4c8d-b712-e00e48a7a671",
            "firstName": "Laurel",
            "lastName": "Stavis",
            "fullName": "Laurel Stavis",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.vnews.com/getattachment/85edef6d-5262-47a3-9223-0c528a13588b/AndyWhite-tc-vn-051718-ph2",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 13-lower-ocd-person-90ca1969-94b3-4c8d-b712-e00e48a7a671",
              "builtID": "nh-lower-grafton 13",
              "externalID": "ocd-person/90ca1969-94b3-4c8d-b712-e00e48a7a671",
              "geometry": null
            },
            "contactInfo": {
              "email": "laurel.stavis@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9063",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9063"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 13-lower-ocd-person-90ca1969-94b3-4c8d-b712-e00e48a7a671": 0
        }
      },
      "Grafton 11": {
        "members": [
          {
            "API_ID": "ocd-person/a70935b5-517e-4b70-accb-26e55c34fb42",
            "firstName": "Lex",
            "lastName": "Berezhny",
            "fullName": "Lex Berezhny",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Lex-Berezhny.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 11",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 11-lower-ocd-person-a70935b5-517e-4b70-accb-26e55c34fb42",
              "builtID": "nh-lower-grafton 11",
              "externalID": "ocd-person/a70935b5-517e-4b70-accb-26e55c34fb42",
              "geometry": null
            },
            "contactInfo": {
              "email": "lex@berezhny.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9887",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9887"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 11-lower-ocd-person-a70935b5-517e-4b70-accb-26e55c34fb42": 0
        }
      },
      "Hillsborough 10": {
        "members": [
          {
            "API_ID": "ocd-person/1e2b57f6-dbb9-4069-a71b-d40828c80cfa",
            "firstName": "Linda",
            "lastName": "Harriott-Gathright",
            "fullName": "Linda Harriott-Gathright",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377113.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 10-lower-ocd-person-1e2b57f6-dbb9-4069-a71b-d40828c80cfa",
              "builtID": "nh-lower-hillsborough 10",
              "externalID": "ocd-person/1e2b57f6-dbb9-4069-a71b-d40828c80cfa",
              "geometry": null
            },
            "contactInfo": {
              "email": "linda.harriottgathright@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=720",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=720"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/27e55643-df5b-49aa-8529-082cd786c0f8",
            "firstName": "Martin L.",
            "lastName": "Jack",
            "fullName": "Martin Jack",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377126.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 10-lower-ocd-person-27e55643-df5b-49aa-8529-082cd786c0f8",
              "builtID": "nh-lower-hillsborough 10",
              "externalID": "ocd-person/27e55643-df5b-49aa-8529-082cd786c0f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "martin.jack@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=733",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377126"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e15e323b-2d85-4272-9f1e-13d12b8c82f4",
            "firstName": "Michael B.",
            "lastName": "O'Brien",
            "fullName": "Michael O'Brien",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376720.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 10",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 10-lower-ocd-person-e15e323b-2d85-4272-9f1e-13d12b8c82f4",
              "builtID": "nh-lower-hillsborough 10",
              "externalID": "ocd-person/e15e323b-2d85-4272-9f1e-13d12b8c82f4",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.o'brien@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=547",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376720"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 10-lower-ocd-person-1e2b57f6-dbb9-4069-a71b-d40828c80cfa": 0,
          "SLDL-NH-Hillsborough 10-lower-ocd-person-27e55643-df5b-49aa-8529-082cd786c0f8": 1,
          "SLDL-NH-Hillsborough 10-lower-ocd-person-e15e323b-2d85-4272-9f1e-13d12b8c82f4": 2
        }
      },
      "Sullivan 5": {
        "members": [
          {
            "API_ID": "ocd-person/950e6cfe-ff6c-49ed-a4c2-1e66e60c6315",
            "firstName": "Linda L.",
            "lastName": "Tanner",
            "fullName": "Linda Tanner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377179.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Sullivan 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Sullivan 5-lower-ocd-person-950e6cfe-ff6c-49ed-a4c2-1e66e60c6315",
              "builtID": "nh-lower-sullivan 5",
              "externalID": "ocd-person/950e6cfe-ff6c-49ed-a4c2-1e66e60c6315",
              "geometry": null
            },
            "contactInfo": {
              "email": "linda.tanner@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=784",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377179"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Sullivan 5-lower-ocd-person-950e6cfe-ff6c-49ed-a4c2-1e66e60c6315": 0
        }
      },
      "Hillsborough 4": {
        "members": [
          {
            "API_ID": "ocd-person/d0a3a869-3612-4910-b31b-32a88667cb84",
            "firstName": "Linda V.",
            "lastName": "Ryan",
            "fullName": "Linda Ryan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-09/linda-v-ryan.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 4-lower-ocd-person-d0a3a869-3612-4910-b31b-32a88667cb84",
              "builtID": "nh-lower-hillsborough 4",
              "externalID": "ocd-person/d0a3a869-3612-4910-b31b-32a88667cb84",
              "geometry": null
            },
            "contactInfo": {
              "email": "linda.ryan@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10776"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f68b34d8-19af-449e-a4cc-3d2729bb9e23",
            "firstName": "Ray E.",
            "lastName": "Newman",
            "fullName": "Ray Newman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/styles/candidate_detail_page_-_thumb/public/candidate-images/raynewman.jpg?itok=fz_-ogHQ",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 4-lower-ocd-person-f68b34d8-19af-449e-a4cc-3d2729bb9e23",
              "builtID": "nh-lower-hillsborough 4",
              "externalID": "ocd-person/f68b34d8-19af-449e-a4cc-3d2729bb9e23",
              "geometry": null
            },
            "contactInfo": {
              "email": "ray.newman@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9107",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9107"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/27254294-07b6-4c08-90be-466800c312bc",
            "firstName": "Sue",
            "lastName": "Newman",
            "fullName": "Sue Newman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408537.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 4-lower-ocd-person-27254294-07b6-4c08-90be-466800c312bc",
              "builtID": "nh-lower-hillsborough 4",
              "externalID": "ocd-person/27254294-07b6-4c08-90be-466800c312bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "sue.newman@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8537",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=408537"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 4-lower-ocd-person-d0a3a869-3612-4910-b31b-32a88667cb84": 0,
          "SLDL-NH-Hillsborough 4-lower-ocd-person-f68b34d8-19af-449e-a4cc-3d2729bb9e23": 1,
          "SLDL-NH-Hillsborough 4-lower-ocd-person-27254294-07b6-4c08-90be-466800c312bc": 2
        }
      },
      "Carroll 4": {
        "members": [
          {
            "API_ID": "ocd-person/4d68b7bf-e7fb-4c6d-ac12-526aba53e65e",
            "firstName": "Lino",
            "lastName": "Avellani",
            "fullName": "Lino Avellani",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377205.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Carroll 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Carroll 4-lower-ocd-person-4d68b7bf-e7fb-4c6d-ac12-526aba53e65e",
              "builtID": "nh-lower-carroll 4",
              "externalID": "ocd-person/4d68b7bf-e7fb-4c6d-ac12-526aba53e65e",
              "geometry": null
            },
            "contactInfo": {
              "email": "lino.avellani@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=815",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377205"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/75ad73cd-6591-4a64-9f6b-7558bbaf3689",
            "firstName": "Michael",
            "lastName": "Belcher",
            "fullName": "Mike Belcher",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Michael_Belcher.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Carroll 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Carroll 4-lower-ocd-person-75ad73cd-6591-4a64-9f6b-7558bbaf3689",
              "builtID": "nh-lower-carroll 4",
              "externalID": "ocd-person/75ad73cd-6591-4a64-9f6b-7558bbaf3689",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.belcher@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10578"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Carroll 4-lower-ocd-person-4d68b7bf-e7fb-4c6d-ac12-526aba53e65e": 0,
          "SLDL-NH-Carroll 4-lower-ocd-person-75ad73cd-6591-4a64-9f6b-7558bbaf3689": 1
        }
      },
      "Belknap 2": {
        "members": [
          {
            "API_ID": "ocd-person/a5d72b13-8ab8-4c21-8dea-0619f3e8072b",
            "firstName": "Lisa",
            "lastName": "Smart",
            "fullName": "Lisa Smart",
            "gender": "Female",
            "party": "Republican",
            "image": "https://bloximages.newyork1.vip.townnews.com/laconiadailysun.com/content/tncms/assets/v3/editorial/f/88/f88b69a6-5fef-11ed-bc7b-d7845a404302/636b3c5c3863b.image.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 2-lower-ocd-person-a5d72b13-8ab8-4c21-8dea-0619f3e8072b",
              "builtID": "nh-lower-belknap 2",
              "externalID": "ocd-person/a5d72b13-8ab8-4c21-8dea-0619f3e8072b",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisa.smart@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10572"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/3b11dc06-256a-430c-9f75-7ebe69267e4d",
            "firstName": "Matthew",
            "lastName": "Coker",
            "fullName": "Matt Coker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409079.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 2-lower-ocd-person-3b11dc06-256a-430c-9f75-7ebe69267e4d",
              "builtID": "nh-lower-belknap 2",
              "externalID": "ocd-person/3b11dc06-256a-430c-9f75-7ebe69267e4d",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.coker@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10733"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Belknap 2-lower-ocd-person-a5d72b13-8ab8-4c21-8dea-0619f3e8072b": 0,
          "SLDL-NH-Belknap 2-lower-ocd-person-3b11dc06-256a-430c-9f75-7ebe69267e4d": 1
        }
      },
      "Hillsborough 44": {
        "members": [
          {
            "API_ID": "ocd-person/e44fbca0-a34c-4b73-aaa6-872b471b9031",
            "firstName": "Lisa",
            "lastName": "Mazur",
            "fullName": "Lisa Mazur",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409129.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 44",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 44-lower-ocd-person-e44fbca0-a34c-4b73-aaa6-872b471b9031",
              "builtID": "nh-lower-hillsborough 44",
              "externalID": "ocd-person/e44fbca0-a34c-4b73-aaa6-872b471b9031",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisa.mazur@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10667"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f5b14d40-aa28-485b-ab61-dc07c647384a",
            "firstName": "Travis James",
            "lastName": "Corcoran",
            "fullName": "Travis Corcoran",
            "gender": "Male",
            "party": "Republican",
            "image": "https://travis4nh.com/wp-content/uploads/2022/07/tjic_clark_hat-250x300.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 44",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 44-lower-ocd-person-f5b14d40-aa28-485b-ab61-dc07c647384a",
              "builtID": "nh-lower-hillsborough 44",
              "externalID": "ocd-person/f5b14d40-aa28-485b-ab61-dc07c647384a",
              "geometry": null
            },
            "contactInfo": {
              "email": "tjic2020@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10666"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 44-lower-ocd-person-e44fbca0-a34c-4b73-aaa6-872b471b9031": 0,
          "SLDL-NH-Hillsborough 44-lower-ocd-person-f5b14d40-aa28-485b-ab61-dc07c647384a": 1
        }
      },
      "Merrimack 1": {
        "members": [
          {
            "API_ID": "ocd-person/c1f83f4d-e410-4642-b973-0ec4cd924ab4",
            "firstName": "Lorrie J.",
            "lastName": "Carey",
            "fullName": "Lorrie Carey",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377080.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 1-lower-ocd-person-c1f83f4d-e410-4642-b973-0ec4cd924ab4",
              "builtID": "nh-lower-merrimack 1",
              "externalID": "ocd-person/c1f83f4d-e410-4642-b973-0ec4cd924ab4",
              "geometry": null
            },
            "contactInfo": {
              "email": "lorrie.carey@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=688"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 1-lower-ocd-person-c1f83f4d-e410-4642-b973-0ec4cd924ab4": 0
        }
      },
      "Cheshire 8": {
        "members": [
          {
            "API_ID": "ocd-person/4854b313-26e5-49a3-8095-76298f96ced3",
            "firstName": "Lucius",
            "lastName": "Parshall",
            "fullName": "Lucius Parshall",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408976.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 8-lower-ocd-person-4854b313-26e5-49a3-8095-76298f96ced3",
              "builtID": "nh-lower-cheshire 8",
              "externalID": "ocd-person/4854b313-26e5-49a3-8095-76298f96ced3",
              "geometry": null
            },
            "contactInfo": {
              "email": "lucius.parshall@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9874",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9874"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 8-lower-ocd-person-4854b313-26e5-49a3-8095-76298f96ced3": 0
        }
      },
      "Cheshire 5": {
        "members": [
          {
            "API_ID": "ocd-person/a6790cd1-6b7a-48f9-b48c-b298929578a4",
            "firstName": "Lucy McVitty",
            "lastName": "Weber",
            "fullName": "Lucy Weber",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376668.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 5-lower-ocd-person-a6790cd1-6b7a-48f9-b48c-b298929578a4",
              "builtID": "nh-lower-cheshire 5",
              "externalID": "ocd-person/a6790cd1-6b7a-48f9-b48c-b298929578a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "lwmcv@comcast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376668",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=531"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 5-lower-ocd-person-a6790cd1-6b7a-48f9-b48c-b298929578a4": 0
        }
      },
      "Sullivan 7": {
        "members": [
          {
            "API_ID": "ocd-person/17abebe7-d58e-456b-b716-5cdadf60cbc7",
            "firstName": "Margaret M.",
            "lastName": "Drye",
            "fullName": "Margaret Drye",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409091.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Sullivan 7",
              "chamber": "lower",
              "hashID": "SLDL-NH-Sullivan 7-lower-ocd-person-17abebe7-d58e-456b-b716-5cdadf60cbc7",
              "builtID": "nh-lower-sullivan 7",
              "externalID": "ocd-person/17abebe7-d58e-456b-b716-5cdadf60cbc7",
              "geometry": null
            },
            "contactInfo": {
              "email": "margaret.drye@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9284"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Sullivan 7-lower-ocd-person-17abebe7-d58e-456b-b716-5cdadf60cbc7": 0
        }
      },
      "Rockingham 5": {
        "members": [
          {
            "API_ID": "ocd-person/248ddc70-c3e0-4ec2-94ff-1d1de0d0e9fc",
            "firstName": "Mark",
            "lastName": "Vallone",
            "fullName": "Mark Vallone",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gannett-cdn.com/authoring/2018/11/08/NPOH/ghows-SO-7a29a32c-45e6-3fe0-e053-0100007f452c-3c584aa9.jpeg?width=1200&disable=upscale&format=pjpg&auto=webp",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 5-lower-ocd-person-248ddc70-c3e0-4ec2-94ff-1d1de0d0e9fc",
              "builtID": "nh-lower-rockingham 5",
              "externalID": "ocd-person/248ddc70-c3e0-4ec2-94ff-1d1de0d0e9fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.vallone@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9210",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9210"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1aa8819a-e1bd-4569-93ec-305ad61e8ea4",
            "firstName": "G. Michael",
            "lastName": "Vose",
            "fullName": "Michael Vose",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377272.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 5-lower-ocd-person-1aa8819a-e1bd-4569-93ec-305ad61e8ea4",
              "builtID": "nh-lower-rockingham 5",
              "externalID": "ocd-person/1aa8819a-e1bd-4569-93ec-305ad61e8ea4",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.vose@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=909"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 5-lower-ocd-person-248ddc70-c3e0-4ec2-94ff-1d1de0d0e9fc": 0,
          "SLDL-NH-Rockingham 5-lower-ocd-person-1aa8819a-e1bd-4569-93ec-305ad61e8ea4": 1
        }
      },
      "Carroll 8": {
        "members": [
          {
            "API_ID": "ocd-person/c344ed7a-6fea-4f17-9c48-4e27d67bebc8",
            "firstName": "Mark E.",
            "lastName": "McConkey",
            "fullName": "Mark McConkey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376495.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Carroll 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Carroll 8-lower-ocd-person-c344ed7a-6fea-4f17-9c48-4e27d67bebc8",
              "builtID": "nh-lower-carroll 8",
              "externalID": "ocd-person/c344ed7a-6fea-4f17-9c48-4e27d67bebc8",
              "geometry": null
            },
            "contactInfo": {
              "email": "mrkmcconkey@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=488",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=488"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/22961bf0-ff8c-4770-b786-bebda1ca8781",
            "firstName": "Michael",
            "lastName": "Costable",
            "fullName": "Michael Costable",
            "gender": "Male",
            "party": "Republican",
            "image": "https://http://www.gencourt.state.nh.us/house/images/memberpics/408389.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Carroll 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Carroll 8-lower-ocd-person-22961bf0-ff8c-4770-b786-bebda1ca8781",
              "builtID": "nh-lower-carroll 8",
              "externalID": "ocd-person/22961bf0-ff8c-4770-b786-bebda1ca8781",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.costable@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8389",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408389"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Carroll 8-lower-ocd-person-c344ed7a-6fea-4f17-9c48-4e27d67bebc8": 0,
          "SLDL-NH-Carroll 8-lower-ocd-person-22961bf0-ff8c-4770-b786-bebda1ca8781": 1
        }
      },
      "Hillsborough 15": {
        "members": [
          {
            "API_ID": "ocd-person/b50abef6-0b19-4d5c-97c9-f757f3b571ad",
            "firstName": "Mark L.",
            "lastName": "Proulx",
            "fullName": "Mark Proulx",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377007.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 15",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 15-lower-ocd-person-b50abef6-0b19-4d5c-97c9-f757f3b571ad",
              "builtID": "nh-lower-hillsborough 15",
              "externalID": "ocd-person/b50abef6-0b19-4d5c-97c9-f757f3b571ad",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.proulx@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=932",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377007"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b089786f-4435-4822-a7d6-3725cca787bf",
            "firstName": "Mark",
            "lastName": "McLean",
            "fullName": "Mark McLean",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377251.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 15",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 15-lower-ocd-person-b089786f-4435-4822-a7d6-3725cca787bf",
              "builtID": "nh-lower-hillsborough 15",
              "externalID": "ocd-person/b089786f-4435-4822-a7d6-3725cca787bf",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.mclean@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=859",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=377251"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 15-lower-ocd-person-b50abef6-0b19-4d5c-97c9-f757f3b571ad": 0,
          "SLDL-NH-Hillsborough 15-lower-ocd-person-b089786f-4435-4822-a7d6-3725cca787bf": 1
        }
      },
      "Rockingham 34": {
        "members": [
          {
            "API_ID": "ocd-person/6c90e0a1-d9d5-488c-a9a3-898ca5b0f9a4",
            "firstName": "Mark A.",
            "lastName": "Pearson",
            "fullName": "Mark Pearson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408749.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 34",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 34-lower-ocd-person-6c90e0a1-d9d5-488c-a9a3-898ca5b0f9a4",
              "builtID": "nh-lower-rockingham 34",
              "externalID": "ocd-person/6c90e0a1-d9d5-488c-a9a3-898ca5b0f9a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.pearson@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8749",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=408749"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 34-lower-ocd-person-6c90e0a1-d9d5-488c-a9a3-898ca5b0f9a4": 0
        }
      },
      "Merrimack 19": {
        "members": [
          {
            "API_ID": "ocd-person/0e6f806c-ba57-4dc6-b6b4-f2a926288ca3",
            "firstName": "Mary Jane",
            "lastName": "Wallner",
            "fullName": "Mary Jane Wallner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/368423.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 19",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 19-lower-ocd-person-0e6f806c-ba57-4dc6-b6b4-f2a926288ca3",
              "builtID": "nh-lower-merrimack 19",
              "externalID": "ocd-person/0e6f806c-ba57-4dc6-b6b4-f2a926288ca3",
              "geometry": null
            },
            "contactInfo": {
              "email": "maryjane.wallner@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=411",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=368423"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 19-lower-ocd-person-0e6f806c-ba57-4dc6-b6b4-f2a926288ca3": 0
        }
      },
      "Grafton 5": {
        "members": [
          {
            "API_ID": "ocd-person/91ed10f2-681e-4c3d-987d-4670bcbd8836",
            "firstName": "Matthew",
            "lastName": "Coulon",
            "fullName": "Matt Coulon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlgpFoSnKhgU22xww-x-ObgqEr0gnNt3h8LKNzqJallR_3zxpblNlI-OeEYpSdZgaVpYQ&usqp=CAU",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 5-lower-ocd-person-91ed10f2-681e-4c3d-987d-4670bcbd8836",
              "builtID": "nh-lower-grafton 5",
              "externalID": "ocd-person/91ed10f2-681e-4c3d-987d-4670bcbd8836",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.coulon@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10617"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1bd6c569-f2dd-4670-bf82-ef3ea4b15960",
            "firstName": "Roderick M.",
            "lastName": "Ladd",
            "fullName": "Rick Ladd",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376832.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 5-lower-ocd-person-1bd6c569-f2dd-4670-bf82-ef3ea4b15960",
              "builtID": "nh-lower-grafton 5",
              "externalID": "ocd-person/1bd6c569-f2dd-4670-bf82-ef3ea4b15960",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.ladd@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=582",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=582"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 5-lower-ocd-person-91ed10f2-681e-4c3d-987d-4670bcbd8836": 0,
          "SLDL-NH-Grafton 5-lower-ocd-person-1bd6c569-f2dd-4670-bf82-ef3ea4b15960": 1
        }
      },
      "Merrimack 24": {
        "members": [
          {
            "API_ID": "ocd-person/3e70b6de-08a4-4ee5-a1c5-482f9394c475",
            "firstName": "Matthew",
            "lastName": "Hicks",
            "fullName": "Matthew Hicks",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.concordmonitor.com/getattachment/a2e12972-c7b8-4e6a-81b8-1bcd6ceb3afc/housedistrict24-cm-10312022-ph1",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 24",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 24-lower-ocd-person-3e70b6de-08a4-4ee5-a1c5-482f9394c475",
              "builtID": "nh-lower-merrimack 24",
              "externalID": "ocd-person/3e70b6de-08a4-4ee5-a1c5-482f9394c475",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.hicks@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10844"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 24-lower-ocd-person-3e70b6de-08a4-4ee5-a1c5-482f9394c475": 0
        }
      },
      "Hillsborough 37": {
        "members": [
          {
            "API_ID": "ocd-person/f1902aac-3930-478d-9e89-60cef8bcae0b",
            "firstName": "Megan A.",
            "lastName": "Murray",
            "fullName": "Megan Murray",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/candidate-images/meganmurray.jpg?itok=wkaoaE_6",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 37",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 37-lower-ocd-person-f1902aac-3930-478d-9e89-60cef8bcae0b",
              "builtID": "nh-lower-hillsborough 37",
              "externalID": "ocd-person/f1902aac-3930-478d-9e89-60cef8bcae0b",
              "geometry": null
            },
            "contactInfo": {
              "email": "megan.murray@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9095",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9095"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 37-lower-ocd-person-f1902aac-3930-478d-9e89-60cef8bcae0b": 0
        }
      },
      "Merrimack 23": {
        "members": [
          {
            "API_ID": "ocd-person/db534ca2-9d91-4578-b093-9b3ed61695eb",
            "firstName": "Merryl",
            "lastName": "Gibbs",
            "fullName": "Merryl Gibbs",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-10/merryl-gibbs.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 23",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 23-lower-ocd-person-db534ca2-9d91-4578-b093-9b3ed61695eb",
              "builtID": "nh-lower-merrimack 23",
              "externalID": "ocd-person/db534ca2-9d91-4578-b093-9b3ed61695eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "merryl.gibbs@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10843"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 23-lower-ocd-person-db534ca2-9d91-4578-b093-9b3ed61695eb": 0
        }
      },
      "Strafford 18": {
        "members": [
          {
            "API_ID": "ocd-person/21e95dbc-c226-4f4a-9fa2-8f5b374d0a98",
            "firstName": "Michael",
            "lastName": "Harrington",
            "fullName": "Mike Harrington",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376347.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 18",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 18-lower-ocd-person-21e95dbc-c226-4f4a-9fa2-8f5b374d0a98",
              "builtID": "nh-lower-strafford 18",
              "externalID": "ocd-person/21e95dbc-c226-4f4a-9fa2-8f5b374d0a98",
              "geometry": null
            },
            "contactInfo": {
              "email": "harringt@metrocast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=5122",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=5122"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 18-lower-ocd-person-21e95dbc-c226-4f4a-9fa2-8f5b374d0a98": 0
        }
      },
      "Coos 6": {
        "members": [
          {
            "API_ID": "ocd-person/546b9d6e-0e31-4a6c-9d8f-63a0c9bb2b1f",
            "firstName": "Michael P.",
            "lastName": "Murphy",
            "fullName": "Mike Murphy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://nhjournal.com/wp-content/uploads/2024/01/mike-murphy-233x300.jpeg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Coos 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Coos 6-lower-ocd-person-546b9d6e-0e31-4a6c-9d8f-63a0c9bb2b1f",
              "builtID": "nh-lower-coos 6",
              "externalID": "ocd-person/546b9d6e-0e31-4a6c-9d8f-63a0c9bb2b1f",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.murphy@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=11119"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Coos 6-lower-ocd-person-546b9d6e-0e31-4a6c-9d8f-63a0c9bb2b1f": 0
        }
      },
      "Coos 3": {
        "members": [
          {
            "API_ID": "ocd-person/7c8a79bb-76d5-488c-988b-084fa40b34c7",
            "firstName": "Mike",
            "lastName": "Ouellet",
            "fullName": "Mike Ouellet",
            "gender": "Male",
            "party": "Republican",
            "image": "https://media.licdn.com/dms/image/D4E03AQH_-vRixMIyxA/profile-displayphoto-shrink_200_200/0/1678487746618?e=1724284800&v=beta&t=jdlnFAXSxQGHKa6Vdi9tW77p378izU1QhMmBq2htsWg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Coos 3",
              "chamber": "lower",
              "hashID": "SLDL-NH-Coos 3-lower-ocd-person-7c8a79bb-76d5-488c-988b-084fa40b34c7",
              "builtID": "nh-lower-coos 3",
              "externalID": "ocd-person/7c8a79bb-76d5-488c-988b-084fa40b34c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.ouellet@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10609",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10609"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Coos 3-lower-ocd-person-7c8a79bb-76d5-488c-988b-084fa40b34c7": 0
        }
      },
      "Hillsborough 31": {
        "members": [
          {
            "API_ID": "ocd-person/4825494b-dbbb-44f4-a4a7-dee1df0a87ae",
            "firstName": "Molly",
            "lastName": "Howard",
            "fullName": "Molly Howard",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409109.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 31",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 31-lower-ocd-person-4825494b-dbbb-44f4-a4a7-dee1df0a87ae",
              "builtID": "nh-lower-hillsborough 31",
              "externalID": "ocd-person/4825494b-dbbb-44f4-a4a7-dee1df0a87ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "molly.howard@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10810"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 31-lower-ocd-person-4825494b-dbbb-44f4-a4a7-dee1df0a87ae": 0
        }
      },
      "Rockingham 39": {
        "members": [
          {
            "API_ID": "ocd-person/f20b1b61-dfc7-4ea0-b0cf-3705e7c7283e",
            "firstName": "Ned",
            "lastName": "Raynolds",
            "fullName": "Ned Raynolds",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gannett-cdn.com/authoring/2019/10/15/NPOH/ghows-SO-94f3f1c5-649c-7f85-e053-0100007fcf09-d1bff693.jpeg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 39",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 39-lower-ocd-person-f20b1b61-dfc7-4ea0-b0cf-3705e7c7283e",
              "builtID": "nh-lower-rockingham 39",
              "externalID": "ocd-person/f20b1b61-dfc7-4ea0-b0cf-3705e7c7283e",
              "geometry": null
            },
            "contactInfo": {
              "email": "ned.raynolds@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10886"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 39-lower-ocd-person-f20b1b61-dfc7-4ea0-b0cf-3705e7c7283e": 0
        }
      },
      "Cheshire 1": {
        "members": [
          {
            "API_ID": "ocd-person/170e4582-46d4-480d-9bca-b49b2ba8baf3",
            "firstName": "Nicholas",
            "lastName": "Germana",
            "fullName": "Nick Germana",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-09/nicholas-germana.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 1-lower-ocd-person-170e4582-46d4-480d-9bca-b49b2ba8baf3",
              "builtID": "nh-lower-cheshire 1",
              "externalID": "ocd-person/170e4582-46d4-480d-9bca-b49b2ba8baf3",
              "geometry": null
            },
            "contactInfo": {
              "email": "nicholas.germana@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10748"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 1-lower-ocd-person-170e4582-46d4-480d-9bca-b49b2ba8baf3": 0
        }
      },
      "Hillsborough 22": {
        "members": [
          {
            "API_ID": "ocd-person/854e522b-afa5-4359-b826-71aaa8b89f39",
            "firstName": "Nicole",
            "lastName": "Leapley",
            "fullName": "Nicole Leapley",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409122.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 22",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 22-lower-ocd-person-854e522b-afa5-4359-b826-71aaa8b89f39",
              "builtID": "nh-lower-hillsborough 22",
              "externalID": "ocd-person/854e522b-afa5-4359-b826-71aaa8b89f39",
              "geometry": null
            },
            "contactInfo": {
              "email": "nicole.leapley@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10800"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e657ef2c-bfc3-4a08-99fe-4456be3bfe2a",
            "firstName": "Patricia",
            "lastName": "Cornell",
            "fullName": "Patricia Cornell",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377289.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 22",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 22-lower-ocd-person-e657ef2c-bfc3-4a08-99fe-4456be3bfe2a",
              "builtID": "nh-lower-hillsborough 22",
              "externalID": "ocd-person/e657ef2c-bfc3-4a08-99fe-4456be3bfe2a",
              "geometry": null
            },
            "contactInfo": {
              "email": "patricia.cornell@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=841",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377289"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 22-lower-ocd-person-854e522b-afa5-4359-b826-71aaa8b89f39": 0,
          "SLDL-NH-Hillsborough 22-lower-ocd-person-e657ef2c-bfc3-4a08-99fe-4456be3bfe2a": 1
        }
      },
      "Rockingham 3": {
        "members": [
          {
            "API_ID": "ocd-person/a2ff7d0d-db50-41fe-97ab-2a2007c99625",
            "firstName": "Oliver",
            "lastName": "Ford",
            "fullName": "Oliver Ford",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.newyork1.vip.townnews.com/unionleader.com/content/tncms/assets/v3/editorial/2/64/264ac390-3ff2-5b76-8425-a243a99628e5/5efa1566e5f8e.image.jpg?resize=300%2C395",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 3",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 3-lower-ocd-person-a2ff7d0d-db50-41fe-97ab-2a2007c99625",
              "builtID": "nh-lower-rockingham 3",
              "externalID": "ocd-person/a2ff7d0d-db50-41fe-97ab-2a2007c99625",
              "geometry": null
            },
            "contactInfo": {
              "email": "lynchford@comcast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9933",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9933"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 3-lower-ocd-person-a2ff7d0d-db50-41fe-97ab-2a2007c99625": 0
        }
      },
      "Rockingham 38": {
        "members": [
          {
            "API_ID": "ocd-person/3d7dffbf-59f3-4a2f-a29a-bcece98c17fb",
            "firstName": "Peggy",
            "lastName": "Balboni",
            "fullName": "Peggy Balboni",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409063.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 38",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 38-lower-ocd-person-3d7dffbf-59f3-4a2f-a29a-bcece98c17fb",
              "builtID": "nh-lower-rockingham 38",
              "externalID": "ocd-person/3d7dffbf-59f3-4a2f-a29a-bcece98c17fb",
              "geometry": null
            },
            "contactInfo": {
              "email": "peggy.balboni@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10885"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 38-lower-ocd-person-3d7dffbf-59f3-4a2f-a29a-bcece98c17fb": 0
        }
      },
      "Strafford 14": {
        "members": [
          {
            "API_ID": "ocd-person/16654889-3b48-44d6-a6d3-8da5d7a434f1",
            "firstName": "Peter B.",
            "lastName": "Schmidt",
            "fullName": "Peter Schmidt",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376521.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 14",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 14-lower-ocd-person-16654889-3b48-44d6-a6d3-8da5d7a434f1",
              "builtID": "nh-lower-strafford 14",
              "externalID": "ocd-person/16654889-3b48-44d6-a6d3-8da5d7a434f1",
              "geometry": null
            },
            "contactInfo": {
              "email": "peterbarrettschmidt@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=496",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376521"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 14-lower-ocd-person-16654889-3b48-44d6-a6d3-8da5d7a434f1": 0
        }
      },
      "Strafford 13": {
        "members": [
          {
            "API_ID": "ocd-person/2c42e95c-0192-4910-9fc6-8a43b58c6467",
            "firstName": "Peter",
            "lastName": "Bixby",
            "fullName": "Peter Bixby",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377070.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 13-lower-ocd-person-2c42e95c-0192-4910-9fc6-8a43b58c6467",
              "builtID": "nh-lower-strafford 13",
              "externalID": "ocd-person/2c42e95c-0192-4910-9fc6-8a43b58c6467",
              "geometry": null
            },
            "contactInfo": {
              "email": "peter.bixby@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=678",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377070"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 13-lower-ocd-person-2c42e95c-0192-4910-9fc6-8a43b58c6467": 0
        }
      },
      "Cheshire 3": {
        "members": [
          {
            "API_ID": "ocd-person/09cfaba9-f3ee-41d6-8cf7-0c93613109cd",
            "firstName": "Philip",
            "lastName": "Jones",
            "fullName": "Philip Jones",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://bloximages.chicago2.vip.townnews.com/sentinelsource.com/content/tncms/assets/v3/editorial/c/45/c457ee2b-e565-5b59-b3ae-0c2e9b900599/6352dd6e9dda8.image.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 3",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 3-lower-ocd-person-09cfaba9-f3ee-41d6-8cf7-0c93613109cd",
              "builtID": "nh-lower-cheshire 3",
              "externalID": "ocd-person/09cfaba9-f3ee-41d6-8cf7-0c93613109cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "philip.jones@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10749"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 3-lower-ocd-person-09cfaba9-f3ee-41d6-8cf7-0c93613109cd": 0
        }
      },
      "Hillsborough 14": {
        "members": [
          {
            "API_ID": "ocd-person/ac6ce16b-a462-4727-8e4b-724c7c19e58c",
            "firstName": "Ralph G.",
            "lastName": "Boehm",
            "fullName": "Ralph Boehm",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376553.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 14",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 14-lower-ocd-person-ac6ce16b-a462-4727-8e4b-724c7c19e58c",
              "builtID": "nh-lower-hillsborough 14",
              "externalID": "ocd-person/ac6ce16b-a462-4727-8e4b-724c7c19e58c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rgboehm@comcast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=502",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376553"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c11f9fad-128e-455e-896e-2e880b6cc29e",
            "firstName": "Richard W.",
            "lastName": "Lascelles",
            "fullName": "Rich Lascelles",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408331.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Hillsborough 14",
              "chamber": "lower",
              "hashID": "SLDL-NH-Hillsborough 14-lower-ocd-person-c11f9fad-128e-455e-896e-2e880b6cc29e",
              "builtID": "nh-lower-hillsborough 14",
              "externalID": "ocd-person/c11f9fad-128e-455e-896e-2e880b6cc29e",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.lascelles@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8331",
                "https://gencourt.state.nh.us/house/members/member.aspx?member=408331"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Hillsborough 14-lower-ocd-person-ac6ce16b-a462-4727-8e4b-724c7c19e58c": 0,
          "SLDL-NH-Hillsborough 14-lower-ocd-person-c11f9fad-128e-455e-896e-2e880b6cc29e": 1
        }
      },
      "Merrimack 30": {
        "members": [
          {
            "API_ID": "ocd-person/39eb4d14-9ef3-4b1b-bcb6-a66f1aa97491",
            "firstName": "Rebecca",
            "lastName": "McWilliams",
            "fullName": "Rebecca McWilliams",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408898.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 30",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 30-lower-ocd-person-39eb4d14-9ef3-4b1b-bcb6-a66f1aa97491",
              "builtID": "nh-lower-merrimack 30",
              "externalID": "ocd-person/39eb4d14-9ef3-4b1b-bcb6-a66f1aa97491",
              "geometry": null
            },
            "contactInfo": {
              "email": "rebecca.mcwilliams@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9170",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9170"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 30-lower-ocd-person-39eb4d14-9ef3-4b1b-bcb6-a66f1aa97491": 0
        }
      },
      "Rockingham 28": {
        "members": [
          {
            "API_ID": "ocd-person/e38b81bb-9bf6-4ace-bc4e-898732105d5d",
            "firstName": "Rebecca Susan",
            "lastName": "McBeath",
            "fullName": "Becky McBeath",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377309.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 28",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 28-lower-ocd-person-e38b81bb-9bf6-4ace-bc4e-898732105d5d",
              "builtID": "nh-lower-rockingham 28",
              "externalID": "ocd-person/e38b81bb-9bf6-4ace-bc4e-898732105d5d",
              "geometry": null
            },
            "contactInfo": {
              "email": "nhstatehouse@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=900"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 28-lower-ocd-person-e38b81bb-9bf6-4ace-bc4e-898732105d5d": 0
        }
      },
      "Cheshire 13": {
        "members": [
          {
            "API_ID": "ocd-person/47c165c7-ecd9-4b88-b9f7-2e58e8e290d4",
            "firstName": "Richard",
            "lastName": "Ames",
            "fullName": "Richard Ames",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377061.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 13",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 13-lower-ocd-person-47c165c7-ecd9-4b88-b9f7-2e58e8e290d4",
              "builtID": "nh-lower-cheshire 13",
              "externalID": "ocd-person/47c165c7-ecd9-4b88-b9f7-2e58e8e290d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.ames@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=669",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377061"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 13-lower-ocd-person-47c165c7-ecd9-4b88-b9f7-2e58e8e290d4": 0
        }
      },
      "Rockingham 8": {
        "members": [
          {
            "API_ID": "ocd-person/2baea6f1-a511-4f17-bfba-1ada8543c112",
            "firstName": "Scott",
            "lastName": "Wallace",
            "fullName": "Scott Wallace",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408431.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 8-lower-ocd-person-2baea6f1-a511-4f17-bfba-1ada8543c112",
              "builtID": "nh-lower-rockingham 8",
              "externalID": "ocd-person/2baea6f1-a511-4f17-bfba-1ada8543c112",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.wallace@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=8431",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=408431"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 8-lower-ocd-person-2baea6f1-a511-4f17-bfba-1ada8543c112": 0
        }
      },
      "Coos 4": {
        "members": [
          {
            "API_ID": "ocd-person/15d93e43-ec08-4958-8f3b-1d56d95eb574",
            "firstName": "Seth",
            "lastName": "King",
            "fullName": "Seth King",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-06/Seth-King.jpg?itok=bvhWHfUY",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Coos 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Coos 4-lower-ocd-person-15d93e43-ec08-4958-8f3b-1d56d95eb574",
              "builtID": "nh-lower-coos 4",
              "externalID": "ocd-person/15d93e43-ec08-4958-8f3b-1d56d95eb574",
              "geometry": null
            },
            "contactInfo": {
              "email": "seth.king@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10610"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Coos 4-lower-ocd-person-15d93e43-ec08-4958-8f3b-1d56d95eb574": 0
        }
      },
      "Cheshire 7": {
        "members": [
          {
            "API_ID": "ocd-person/ad56e51d-eab7-4a7b-9e0c-13a05356f916",
            "firstName": "Shaun M.",
            "lastName": "Filiault",
            "fullName": "Shaun Filiault",
            "gender": "Male",
            "party": "independent",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/ShaunFiliault.png",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 7",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 7-lower-ocd-person-ad56e51d-eab7-4a7b-9e0c-13a05356f916",
              "builtID": "nh-lower-cheshire 7",
              "externalID": "ocd-person/ad56e51d-eab7-4a7b-9e0c-13a05356f916",
              "geometry": null
            },
            "contactInfo": {
              "email": "shaun.filiault@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10751"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 7-lower-ocd-person-ad56e51d-eab7-4a7b-9e0c-13a05356f916": 0
        }
      },
      "Merrimack 8": {
        "members": [
          {
            "API_ID": "ocd-person/8cd051cc-e5af-412f-8ea7-350a7f4c83e2",
            "firstName": "Sherry L.",
            "lastName": "Gould",
            "fullName": "Sherry Gould",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409102.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 8-lower-ocd-person-8cd051cc-e5af-412f-8ea7-350a7f4c83e2",
              "builtID": "nh-lower-merrimack 8",
              "externalID": "ocd-person/8cd051cc-e5af-412f-8ea7-350a7f4c83e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "sherrygould@tds.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10833"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8184319b-a3eb-4538-a78a-5e5cd439e2a3",
            "firstName": "Stephanie R.",
            "lastName": "Payeur",
            "fullName": "Stephanie Payeur",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/2022-10/stephanie-payeur.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 8-lower-ocd-person-8184319b-a3eb-4538-a78a-5e5cd439e2a3",
              "builtID": "nh-lower-merrimack 8",
              "externalID": "ocd-person/8184319b-a3eb-4538-a78a-5e5cd439e2a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephanie.payeur@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10834",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10834"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/960dc748-e7c7-4859-ab1d-b07f9bb7b269",
            "firstName": "Anthony",
            "lastName": "Caplan",
            "fullName": "Tony Caplan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409023.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 8",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 8-lower-ocd-person-960dc748-e7c7-4859-ab1d-b07f9bb7b269",
              "builtID": "nh-lower-merrimack 8",
              "externalID": "ocd-person/960dc748-e7c7-4859-ab1d-b07f9bb7b269",
              "geometry": null
            },
            "contactInfo": {
              "email": "tony.caplan@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9923",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9923"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 8-lower-ocd-person-8cd051cc-e5af-412f-8ea7-350a7f4c83e2": 0,
          "SLDL-NH-Merrimack 8-lower-ocd-person-8184319b-a3eb-4538-a78a-5e5cd439e2a3": 1,
          "SLDL-NH-Merrimack 8-lower-ocd-person-960dc748-e7c7-4859-ab1d-b07f9bb7b269": 2
        }
      },
      "Sullivan 3": {
        "members": [
          {
            "API_ID": "ocd-person/31d4d765-be08-4393-8e9a-21444cf8b411",
            "firstName": "Skip",
            "lastName": "Rollins",
            "fullName": "Skip Rollins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377163.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Sullivan 3",
              "chamber": "lower",
              "hashID": "SLDL-NH-Sullivan 3-lower-ocd-person-31d4d765-be08-4393-8e9a-21444cf8b411",
              "builtID": "nh-lower-sullivan 3",
              "externalID": "ocd-person/31d4d765-be08-4393-8e9a-21444cf8b411",
              "geometry": null
            },
            "contactInfo": {
              "email": "skip@lavalleys.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=768",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377163"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/129a2970-07b9-4136-aae1-15c689edca40",
            "firstName": "Steven D.",
            "lastName": "Smith",
            "fullName": "Steven Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/377031.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Sullivan 3",
              "chamber": "lower",
              "hashID": "SLDL-NH-Sullivan 3-lower-ocd-person-129a2970-07b9-4136-aae1-15c689edca40",
              "builtID": "nh-lower-sullivan 3",
              "externalID": "ocd-person/129a2970-07b9-4136-aae1-15c689edca40",
              "geometry": null
            },
            "contactInfo": {
              "email": "nhfirst@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=656",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=377031"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/236f5df6-87b6-488d-86cd-a1ed3a5adb81",
            "firstName": "Walter Gybbon",
            "lastName": "Spilsbury",
            "fullName": "Terry Spilsbury",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409050.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Sullivan 3",
              "chamber": "lower",
              "hashID": "SLDL-NH-Sullivan 3-lower-ocd-person-236f5df6-87b6-488d-86cd-a1ed3a5adb81",
              "builtID": "nh-lower-sullivan 3",
              "externalID": "ocd-person/236f5df6-87b6-488d-86cd-a1ed3a5adb81",
              "geometry": null
            },
            "contactInfo": {
              "email": "walter.spilsbury@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9950",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9950"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Sullivan 3-lower-ocd-person-31d4d765-be08-4393-8e9a-21444cf8b411": 0,
          "SLDL-NH-Sullivan 3-lower-ocd-person-129a2970-07b9-4136-aae1-15c689edca40": 1,
          "SLDL-NH-Sullivan 3-lower-ocd-person-236f5df6-87b6-488d-86cd-a1ed3a5adb81": 2
        }
      },
      "Merrimack 15": {
        "members": [
          {
            "API_ID": "ocd-person/8572ae91-584f-45b3-8882-b33fa9588de2",
            "firstName": "Stephen James",
            "lastName": "Shurtleff",
            "fullName": "Steve Shurtleff",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376628.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 15",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 15-lower-ocd-person-8572ae91-584f-45b3-8882-b33fa9588de2",
              "builtID": "nh-lower-merrimack 15",
              "externalID": "ocd-person/8572ae91-584f-45b3-8882-b33fa9588de2",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.shurtleff@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=517",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376628"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 15-lower-ocd-person-8572ae91-584f-45b3-8882-b33fa9588de2": 0
        }
      },
      "Rockingham 19": {
        "members": [
          {
            "API_ID": "ocd-person/64a835c6-0e89-411f-bc32-331e246b10f0",
            "firstName": "Susan M.",
            "lastName": "Porcelli",
            "fullName": "Susan Porcelli",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409155.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Rockingham 19",
              "chamber": "lower",
              "hashID": "SLDL-NH-Rockingham 19-lower-ocd-person-64a835c6-0e89-411f-bc32-331e246b10f0",
              "builtID": "nh-lower-rockingham 19",
              "externalID": "ocd-person/64a835c6-0e89-411f-bc32-331e246b10f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.porcelli@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10700"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Rockingham 19-lower-ocd-person-64a835c6-0e89-411f-bc32-331e246b10f0": 0
        }
      },
      "Grafton 17": {
        "members": [
          {
            "API_ID": "ocd-person/e13283b4-defe-4f4e-8e67-4d89b8e3790c",
            "firstName": "Susan W.",
            "lastName": "Almy",
            "fullName": "Susan Almy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376095.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 17",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 17-lower-ocd-person-e13283b4-defe-4f4e-8e67-4d89b8e3790c",
              "builtID": "nh-lower-grafton 17",
              "externalID": "ocd-person/e13283b4-defe-4f4e-8e67-4d89b8e3790c",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.almy@comcast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=445",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376095"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 17-lower-ocd-person-e13283b4-defe-4f4e-8e67-4d89b8e3790c": 0
        }
      },
      "Merrimack 6": {
        "members": [
          {
            "API_ID": "ocd-person/29eeecd3-79b7-489c-b1a5-ace913e619f2",
            "firstName": "Thomas C.",
            "lastName": "Schamberg",
            "fullName": "Tom Schamberg",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/candidate-images/schamberg.jpg?itok=xCPIm8JQ",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 6",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 6-lower-ocd-person-29eeecd3-79b7-489c-b1a5-ace913e619f2",
              "builtID": "nh-lower-merrimack 6",
              "externalID": "ocd-person/29eeecd3-79b7-489c-b1a5-ace913e619f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.schamberg@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=771",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=771"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 6-lower-ocd-person-29eeecd3-79b7-489c-b1a5-ace913e619f2": 0
        }
      },
      "Grafton 15": {
        "members": [
          {
            "API_ID": "ocd-person/25d71e57-9def-4149-8257-2bfb3c5e5415",
            "firstName": "Thomas H.",
            "lastName": "Cormen",
            "fullName": "Tom Cormen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/409084.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 15",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 15-lower-ocd-person-25d71e57-9def-4149-8257-2bfb3c5e5415",
              "builtID": "nh-lower-grafton 15",
              "externalID": "ocd-person/25d71e57-9def-4149-8257-2bfb3c5e5415",
              "geometry": null
            },
            "contactInfo": {
              "email": "thomas.cormen@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10769"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 15-lower-ocd-person-25d71e57-9def-4149-8257-2bfb3c5e5415": 0
        }
      },
      "Strafford 5": {
        "members": [
          {
            "API_ID": "ocd-person/21a4a059-a8f3-4ec9-ac42-84983f16fb8d",
            "firstName": "Thomas L.",
            "lastName": "Kaczynski",
            "fullName": "Thomas Kaczynski",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.citizenscount.org/sites/default/files/styles/profile_140x176/public/candidate-images/tkaczynskisth22.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Strafford 5",
              "chamber": "lower",
              "hashID": "SLDL-NH-Strafford 5-lower-ocd-person-21a4a059-a8f3-4ec9-ac42-84983f16fb8d",
              "builtID": "nh-lower-strafford 5",
              "externalID": "ocd-person/21a4a059-a8f3-4ec9-ac42-84983f16fb8d",
              "geometry": null
            },
            "contactInfo": {
              "email": "thomas.kaczynski@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=915",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=915"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Strafford 5-lower-ocd-person-21a4a059-a8f3-4ec9-ac42-84983f16fb8d": 0
        }
      },
      "Merrimack 21": {
        "members": [
          {
            "API_ID": "ocd-person/edcefea3-7cd4-4128-92ef-b22bc4a7c0b4",
            "firstName": "Timothy A.",
            "lastName": "Soucy",
            "fullName": "Timothy Soucy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/376869.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Merrimack 21",
              "chamber": "lower",
              "hashID": "SLDL-NH-Merrimack 21-lower-ocd-person-edcefea3-7cd4-4128-92ef-b22bc4a7c0b4",
              "builtID": "nh-lower-merrimack 21",
              "externalID": "ocd-person/edcefea3-7cd4-4128-92ef-b22bc4a7c0b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "timothy.soucy@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=599",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?member=376869"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Merrimack 21-lower-ocd-person-edcefea3-7cd4-4128-92ef-b22bc4a7c0b4": 0
        }
      },
      "Belknap 1": {
        "members": [
          {
            "API_ID": "ocd-person/8f685697-5fb0-4966-8cd0-ad3059650f8d",
            "firstName": "Tom",
            "lastName": "Ploszaj",
            "fullName": "Tom Ploszaj",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/house/images/memberpics/408962.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 1",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 1-lower-ocd-person-8f685697-5fb0-4966-8cd0-ad3059650f8d",
              "builtID": "nh-lower-belknap 1",
              "externalID": "ocd-person/8f685697-5fb0-4966-8cd0-ad3059650f8d",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.ploszaj@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9860",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9860"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Belknap 1-lower-ocd-person-8f685697-5fb0-4966-8cd0-ad3059650f8d": 0
        }
      },
      "Grafton 7": {
        "members": [
          {
            "API_ID": "ocd-person/8a58e10f-d293-4d74-ba51-bc62945251d4",
            "firstName": "Tom",
            "lastName": "Hoyt",
            "fullName": "Tommy Hoyt",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://plymouthareademocrats.org/wp-content/uploads/2022/08/Tommy-Hoyt.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Grafton 7",
              "chamber": "lower",
              "hashID": "SLDL-NH-Grafton 7-lower-ocd-person-8a58e10f-d293-4d74-ba51-bc62945251d4",
              "builtID": "nh-lower-grafton 7",
              "externalID": "ocd-person/8a58e10f-d293-4d74-ba51-bc62945251d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "hoytman56@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10764"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Grafton 7-lower-ocd-person-8a58e10f-d293-4d74-ba51-bc62945251d4": 0
        }
      },
      "Belknap 4": {
        "members": [
          {
            "API_ID": "ocd-person/fbfbfb0a-2c7d-457c-8322-12221db1662b",
            "firstName": "Travis J.",
            "lastName": "O'Hara",
            "fullName": "Travis O'Hara",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Travis-OHara.PNG",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Belknap 4",
              "chamber": "lower",
              "hashID": "SLDL-NH-Belknap 4-lower-ocd-person-fbfbfb0a-2c7d-457c-8322-12221db1662b",
              "builtID": "nh-lower-belknap 4",
              "externalID": "ocd-person/fbfbfb0a-2c7d-457c-8322-12221db1662b",
              "geometry": null
            },
            "contactInfo": {
              "email": "travis@ohara4nh.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=9868",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=9868"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Belknap 4-lower-ocd-person-fbfbfb0a-2c7d-457c-8322-12221db1662b": 0
        }
      },
      "Sullivan 2": {
        "members": [
          {
            "API_ID": "ocd-person/0e14232e-aea6-4da6-9380-c59375f58e7c",
            "firstName": "William S.",
            "lastName": "Palmer",
            "fullName": "Bill Palmer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://img1.wsimg.com/isteam/ip/738dae47-37b5-49b6-abe3-e67d73f8b065/profile%20picture%2024x27_image_palmernh_220703.png/:/rs=w:1160,h:1305",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Sullivan 2",
              "chamber": "lower",
              "hashID": "SLDL-NH-Sullivan 2-lower-ocd-person-0e14232e-aea6-4da6-9380-c59375f58e7c",
              "builtID": "nh-lower-sullivan 2",
              "externalID": "ocd-person/0e14232e-aea6-4da6-9380-c59375f58e7c",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.palmer@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10906",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10906"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Sullivan 2-lower-ocd-person-0e14232e-aea6-4da6-9380-c59375f58e7c": 0
        }
      },
      "Cheshire 11": {
        "members": [
          {
            "API_ID": "ocd-person/5a20b4ff-5cd1-4684-919a-6ddaf3585438",
            "firstName": "Zachary",
            "lastName": "Nutting",
            "fullName": "Zachary Nutting",
            "gender": "Male",
            "party": "Republican",
            "image": "https://co.cheshire.nh.us/wp-content/uploads/0230-nutting-zachary-1-300x286.jpg",
            "title": "NH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "Cheshire 11",
              "chamber": "lower",
              "hashID": "SLDL-NH-Cheshire 11-lower-ocd-person-5a20b4ff-5cd1-4684-919a-6ddaf3585438",
              "builtID": "nh-lower-cheshire 11",
              "externalID": "ocd-person/5a20b4ff-5cd1-4684-919a-6ddaf3585438",
              "geometry": null
            },
            "contactInfo": {
              "email": "zachary.nutting@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/house/members/member.aspx?pid=10587",
                "https://gencourt.state.nh.us/house/members/member.aspx?pid=10587"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NH-Cheshire 11-lower-ocd-person-5a20b4ff-5cd1-4684-919a-6ddaf3585438": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/33ce9791-5bd7-4f8a-a0a3-5aaf1c3efe22",
            "firstName": "Carrie Winn",
            "lastName": "Gendreau",
            "fullName": "Carrie Gendreau",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/01.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-NH-1-upper-ocd-person-33ce9791-5bd7-4f8a-a0a3-5aaf1c3efe22",
              "builtID": "nh-upper-1",
              "externalID": "ocd-person/33ce9791-5bd7-4f8a-a0a3-5aaf1c3efe22",
              "geometry": null
            },
            "contactInfo": {
              "email": "carrie.gendreau@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district01.aspx",
                "https://gencourt.state.nh.us/senate/members/webpages/district01.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-1-upper-ocd-person-33ce9791-5bd7-4f8a-a0a3-5aaf1c3efe22": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/5330ad05-ce69-47c0-9093-6286233c7a7d",
            "firstName": "Timothy",
            "lastName": "Lang",
            "fullName": "Tim Lang",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/02.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-NH-2-upper-ocd-person-5330ad05-ce69-47c0-9093-6286233c7a7d",
              "builtID": "nh-upper-2",
              "externalID": "ocd-person/5330ad05-ce69-47c0-9093-6286233c7a7d",
              "geometry": null
            },
            "contactInfo": {
              "email": "timothy.lang@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district02.aspx",
                "https://gencourt.state.nh.us/senate/members/webpages/district02.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-2-upper-ocd-person-5330ad05-ce69-47c0-9093-6286233c7a7d": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/3f5455e8-256e-4442-9c13-bc14f62ce76f",
            "firstName": "Joseph E.",
            "lastName": "Bradley",
            "fullName": "Jeb Bradley",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-NH-3-upper-ocd-person-3f5455e8-256e-4442-9c13-bc14f62ce76f",
              "builtID": "nh-upper-3",
              "externalID": "ocd-person/3f5455e8-256e-4442-9c13-bc14f62ce76f",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeb.bradley@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district03.aspx",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district03.aspx",
                "https://gencourt.state.nh.us/senate/members/webpages/district03.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-3-upper-ocd-person-3f5455e8-256e-4442-9c13-bc14f62ce76f": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/cf24defe-6df3-49f3-88ca-c6774b144f19",
            "firstName": "David H.",
            "lastName": "Watters",
            "fullName": "David Watters",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/04.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-NH-4-upper-ocd-person-cf24defe-6df3-49f3-88ca-c6774b144f19",
              "builtID": "nh-upper-4",
              "externalID": "ocd-person/cf24defe-6df3-49f3-88ca-c6774b144f19",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.watters@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district04.aspx",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district04.aspx",
                "https://gencourt.state.nh.us/Senate/members/webpages/district04.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-4-upper-ocd-person-cf24defe-6df3-49f3-88ca-c6774b144f19": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/de206392-deba-4ecf-96a3-33494c415417",
            "firstName": "Suzanne M.",
            "lastName": "Prentiss",
            "fullName": "Sue Prentiss",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/05.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-NH-5-upper-ocd-person-de206392-deba-4ecf-96a3-33494c415417",
              "builtID": "nh-upper-5",
              "externalID": "ocd-person/de206392-deba-4ecf-96a3-33494c415417",
              "geometry": null
            },
            "contactInfo": {
              "email": "suzanne.prentiss@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district05.aspx",
                "https://gencourt.state.nh.us/Senate/members/webpages/district05.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-5-upper-ocd-person-de206392-deba-4ecf-96a3-33494c415417": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/e6443187-eb51-42eb-8bd7-349ed0ba9641",
            "firstName": "James P.",
            "lastName": "Gray",
            "fullName": "James Gray",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/06.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-NH-6-upper-ocd-person-e6443187-eb51-42eb-8bd7-349ed0ba9641",
              "builtID": "nh-upper-6",
              "externalID": "ocd-person/e6443187-eb51-42eb-8bd7-349ed0ba9641",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.gray@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district06.aspx",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district06.aspx",
                "https://gencourt.state.nh.us/Senate/members/webpages/district06.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-6-upper-ocd-person-e6443187-eb51-42eb-8bd7-349ed0ba9641": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/6328e7f7-d952-4d28-98b8-75a9a6f50c8d",
            "firstName": "Daniel E.",
            "lastName": "Innis",
            "fullName": "Dan Innis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/07.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-NH-7-upper-ocd-person-6328e7f7-d952-4d28-98b8-75a9a6f50c8d",
              "builtID": "nh-upper-7",
              "externalID": "ocd-person/6328e7f7-d952-4d28-98b8-75a9a6f50c8d",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.innis@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district07.aspx",
                "https://gencourt.state.nh.us/senate/members/webpages/district07.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-7-upper-ocd-person-6328e7f7-d952-4d28-98b8-75a9a6f50c8d": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/9089a509-1f26-4770-adc6-199ed716e692",
            "firstName": "Ruth",
            "lastName": "Ward",
            "fullName": "Ruth Ward",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/08.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-NH-8-upper-ocd-person-9089a509-1f26-4770-adc6-199ed716e692",
              "builtID": "nh-upper-8",
              "externalID": "ocd-person/9089a509-1f26-4770-adc6-199ed716e692",
              "geometry": null
            },
            "contactInfo": {
              "email": "ruth.ward@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district08.aspx",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district08.aspx",
                "https://gencourt.state.nh.us/Senate/members/webpages/district08.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-8-upper-ocd-person-9089a509-1f26-4770-adc6-199ed716e692": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/8dc59c94-caba-41b2-a6ba-6bca0f595118",
            "firstName": "Denise Crusade",
            "lastName": "Ricciardi",
            "fullName": "Denise Ricciardi",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/09.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-NH-9-upper-ocd-person-8dc59c94-caba-41b2-a6ba-6bca0f595118",
              "builtID": "nh-upper-9",
              "externalID": "ocd-person/8dc59c94-caba-41b2-a6ba-6bca0f595118",
              "geometry": null
            },
            "contactInfo": {
              "email": "denise.ricciardi@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district09.aspx",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district09.aspx",
                "https://gencourt.state.nh.us/Senate/members/webpages/district09.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-9-upper-ocd-person-8dc59c94-caba-41b2-a6ba-6bca0f595118": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/8e013e93-3dd2-4530-a42f-d9ba62ea5f04",
            "firstName": "Donovan",
            "lastName": "Fenton",
            "fullName": "Donovan Fenton",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/10.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-NH-10-upper-ocd-person-8e013e93-3dd2-4530-a42f-d9ba62ea5f04",
              "builtID": "nh-upper-10",
              "externalID": "ocd-person/8e013e93-3dd2-4530-a42f-d9ba62ea5f04",
              "geometry": null
            },
            "contactInfo": {
              "email": "donovan.fenton@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district10.aspx",
                "https://gencourt.state.nh.us/senate/members/webpages/district10.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-10-upper-ocd-person-8e013e93-3dd2-4530-a42f-d9ba62ea5f04": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/92b62a3f-15ef-4cd6-9073-14d32a3eb74b",
            "firstName": "Shannon E.",
            "lastName": "Chandley",
            "fullName": "Shannon Chandley",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/11.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-NH-11-upper-ocd-person-92b62a3f-15ef-4cd6-9073-14d32a3eb74b",
              "builtID": "nh-upper-11",
              "externalID": "ocd-person/92b62a3f-15ef-4cd6-9073-14d32a3eb74b",
              "geometry": null
            },
            "contactInfo": {
              "email": "shannon.chandley@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district11.aspx",
                "https://gencourt.state.nh.us/senate/members/webpages/district11.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-11-upper-ocd-person-92b62a3f-15ef-4cd6-9073-14d32a3eb74b": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/b99b0bbb-4710-43c7-a6b5-0e04524eedf0",
            "firstName": "Kevin A.",
            "lastName": "Avard",
            "fullName": "Kevin Avard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/12.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-NH-12-upper-ocd-person-b99b0bbb-4710-43c7-a6b5-0e04524eedf0",
              "builtID": "nh-upper-12",
              "externalID": "ocd-person/b99b0bbb-4710-43c7-a6b5-0e04524eedf0",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.avard@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district12.aspx",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district12.aspx",
                "https://gencourt.state.nh.us/Senate/members/webpages/district12.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-12-upper-ocd-person-b99b0bbb-4710-43c7-a6b5-0e04524eedf0": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/f3e949ce-6d98-4826-bbca-08039cea4ea4",
            "firstName": "Lucinda",
            "lastName": "Rosenwald",
            "fullName": "Cindy Rosenwald",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/13.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-NH-13-upper-ocd-person-f3e949ce-6d98-4826-bbca-08039cea4ea4",
              "builtID": "nh-upper-13",
              "externalID": "ocd-person/f3e949ce-6d98-4826-bbca-08039cea4ea4",
              "geometry": null
            },
            "contactInfo": {
              "email": "cindy.rosenwald@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district13.aspx",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district13.aspx",
                "https://gencourt.state.nh.us/senate/members/webpages/district13.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-13-upper-ocd-person-f3e949ce-6d98-4826-bbca-08039cea4ea4": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/00964fc0-dd57-44c3-87bf-b6d5bbc34560",
            "firstName": "Sharon M.",
            "lastName": "Carson",
            "fullName": "Sharon Carson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/14.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-NH-14-upper-ocd-person-00964fc0-dd57-44c3-87bf-b6d5bbc34560",
              "builtID": "nh-upper-14",
              "externalID": "ocd-person/00964fc0-dd57-44c3-87bf-b6d5bbc34560",
              "geometry": null
            },
            "contactInfo": {
              "email": "sharon.carson@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district14.aspx",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district14.aspx",
                "https://gencourt.state.nh.us/Senate/members/webpages/district14.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-14-upper-ocd-person-00964fc0-dd57-44c3-87bf-b6d5bbc34560": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/92267d4c-e6f1-4f45-87be-83f229c2cd36",
            "firstName": "Rebecca",
            "lastName": "Whitley",
            "fullName": "Becky Whitley",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/15.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-NH-15-upper-ocd-person-92267d4c-e6f1-4f45-87be-83f229c2cd36",
              "builtID": "nh-upper-15",
              "externalID": "ocd-person/92267d4c-e6f1-4f45-87be-83f229c2cd36",
              "geometry": null
            },
            "contactInfo": {
              "email": "becky.whitley@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district15.aspx",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district15.aspx",
                "https://gencourt.state.nh.us/Senate/members/webpages/district15.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-15-upper-ocd-person-92267d4c-e6f1-4f45-87be-83f229c2cd36": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/7ec573c3-d961-4d08-a71c-d239490b5c1a",
            "firstName": "Keith",
            "lastName": "Murphy",
            "fullName": "Keith Murphy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/16.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-NH-16-upper-ocd-person-7ec573c3-d961-4d08-a71c-d239490b5c1a",
              "builtID": "nh-upper-16",
              "externalID": "ocd-person/7ec573c3-d961-4d08-a71c-d239490b5c1a",
              "geometry": null
            },
            "contactInfo": {
              "email": "keith.murphy@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district16.aspx",
                "https://gencourt.state.nh.us/senate/members/webpages/district16.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-16-upper-ocd-person-7ec573c3-d961-4d08-a71c-d239490b5c1a": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/c4122c6a-ed23-492e-b813-59a0fed1ec86",
            "firstName": "Howard",
            "lastName": "Pearl",
            "fullName": "Howard Pearl",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/17.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-NH-17-upper-ocd-person-c4122c6a-ed23-492e-b813-59a0fed1ec86",
              "builtID": "nh-upper-17",
              "externalID": "ocd-person/c4122c6a-ed23-492e-b813-59a0fed1ec86",
              "geometry": null
            },
            "contactInfo": {
              "email": "howard.pearl@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district17.aspx",
                "https://gencourt.state.nh.us/senate/members/webpages/district17.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-17-upper-ocd-person-c4122c6a-ed23-492e-b813-59a0fed1ec86": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/bfbbacfd-e12a-4e07-970e-c2c2c006f4a2",
            "firstName": "Donna M.",
            "lastName": "Soucy",
            "fullName": "Donna Soucy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/18.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-NH-18-upper-ocd-person-bfbbacfd-e12a-4e07-970e-c2c2c006f4a2",
              "builtID": "nh-upper-18",
              "externalID": "ocd-person/bfbbacfd-e12a-4e07-970e-c2c2c006f4a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "donna.soucy@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district18.aspx",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district18.aspx",
                "https://gencourt.state.nh.us/senate/members/webpages/district18.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-18-upper-ocd-person-bfbbacfd-e12a-4e07-970e-c2c2c006f4a2": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/461c9024-c8c3-44a5-91d6-015a6587db5f",
            "firstName": "Regina M.",
            "lastName": "Birdsell",
            "fullName": "Regina Birdsell",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/19.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-NH-19-upper-ocd-person-461c9024-c8c3-44a5-91d6-015a6587db5f",
              "builtID": "nh-upper-19",
              "externalID": "ocd-person/461c9024-c8c3-44a5-91d6-015a6587db5f",
              "geometry": null
            },
            "contactInfo": {
              "email": "regina.birdsell@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district19.aspx",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district19.aspx",
                "https://gencourt.state.nh.us/senate/members/webpages/district19.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-19-upper-ocd-person-461c9024-c8c3-44a5-91d6-015a6587db5f": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/87c48788-817d-408b-a1e1-ebe1c9fd893d",
            "firstName": "Lou",
            "lastName": "D'Allesandro",
            "fullName": "Lou D'Allesandro",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/20.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-NH-20-upper-ocd-person-87c48788-817d-408b-a1e1-ebe1c9fd893d",
              "builtID": "nh-upper-20",
              "externalID": "ocd-person/87c48788-817d-408b-a1e1-ebe1c9fd893d",
              "geometry": null
            },
            "contactInfo": {
              "email": "lou.dallesandro@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district20.aspx",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district20.aspx",
                "https://gencourt.state.nh.us/Senate/members/webpages/district20.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-20-upper-ocd-person-87c48788-817d-408b-a1e1-ebe1c9fd893d": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/c20b7693-7f2c-4646-af7f-d57518edd1a4",
            "firstName": "Rebecca",
            "lastName": "Perkins Kwoka",
            "fullName": "Rebecca Perkins Kwoka",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/21.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-NH-21-upper-ocd-person-c20b7693-7f2c-4646-af7f-d57518edd1a4",
              "builtID": "nh-upper-21",
              "externalID": "ocd-person/c20b7693-7f2c-4646-af7f-d57518edd1a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rebecca.perkinskwoka@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district21.aspx",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district21.aspx",
                "https://gencourt.state.nh.us/Senate/members/webpages/district21.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-21-upper-ocd-person-c20b7693-7f2c-4646-af7f-d57518edd1a4": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/6dfd683a-1f79-49db-95a9-07f4f9898a22",
            "firstName": "Daryl",
            "lastName": "Abbas",
            "fullName": "Daryl Abbas",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Daryl-Abbas.PNG",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-NH-22-upper-ocd-person-6dfd683a-1f79-49db-95a9-07f4f9898a22",
              "builtID": "nh-upper-22",
              "externalID": "ocd-person/6dfd683a-1f79-49db-95a9-07f4f9898a22",
              "geometry": null
            },
            "contactInfo": {
              "email": "daryl.abbas@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/senate/members/webpages/district22.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-22-upper-ocd-person-6dfd683a-1f79-49db-95a9-07f4f9898a22": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/d3c437bd-532d-458b-961f-4f6dccec1e4b",
            "firstName": "William",
            "lastName": "Gannon",
            "fullName": "Bill Gannon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/23.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-NH-23-upper-ocd-person-d3c437bd-532d-458b-961f-4f6dccec1e4b",
              "builtID": "nh-upper-23",
              "externalID": "ocd-person/d3c437bd-532d-458b-961f-4f6dccec1e4b",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.gannon@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district23.aspx",
                "https://gencourt.state.nh.us/Senate/members/webpages/district23.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-23-upper-ocd-person-d3c437bd-532d-458b-961f-4f6dccec1e4b": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/925608b2-8d23-4186-85f6-4c50f334374d",
            "firstName": "Debra O'Connell",
            "lastName": "Altschiller",
            "fullName": "Debra Altschiller",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.gencourt.state.nh.us/Senate/images/senators/24.jpg",
            "title": "NH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NH",
              "stateFull": "New Hampshire",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-NH-24-upper-ocd-person-925608b2-8d23-4186-85f6-4c50f334374d",
              "builtID": "nh-upper-24",
              "externalID": "ocd-person/925608b2-8d23-4186-85f6-4c50f334374d",
              "geometry": null
            },
            "contactInfo": {
              "email": "debra.altschiller@leg.state.nh.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.gencourt.state.nh.us/downloads/Members.txt",
                "https://gencourt.state.nh.us/downloads/Members.txt",
                "https://www.gencourt.state.nh.us/Senate/members/webpages/district24.aspx",
                "https://gencourt.state.nh.us/senate/members/webpages/district24.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NH-24-upper-ocd-person-925608b2-8d23-4186-85f6-4c50f334374d": 0
        }
      }
    }
  },
  "NJ": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "NJ",
          "stateFull": "New Jersey",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "NJ",
            "stateFull": "New Jersey",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-NJ---",
            "builtID": "nj--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-NJ---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "B001288",
          "in_office": true,
          "firstName": "Cory",
          "lastName": "Booker",
          "middleName": null,
          "fullName": "Cory A. Booker",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/B001288.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "NJ",
            "stateFull": "New Jersey",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-NJ---B001288",
            "builtID": "nj--",
            "externalID": "B001288",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.booker.senate.gov/?p=contact",
            "address1": "717 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-3224",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenBooker",
            "youtube": "SenCoryBooker",
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.booker.senate.gov"],
            "rss_url": null
          },
          "title": "NJ Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/B001288.json",
          "govtrack_id": "412598",
          "cspan_id": "84679",
          "votesmart_id": "76151",
          "icpsr_id": "41308",
          "crp_id": "N00035267",
          "google_entity_id": "/m/06p430",
          "state_rank": "junior",
          "lis_id": "S370",
          "suffix": null,
          "date_of_birth": "1969-04-27",
          "leadership_role": null,
          "fec_candidate_id": "S4NJ00185",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "11",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 28,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 5.05,
          "votes_with_party_pct": 97.14,
          "votes_against_party_pct": 2.86,
          "ocd_id": "ocd-division/country:us/state:nj"
        },
        {
          "API_ID": "M000639",
          "in_office": true,
          "firstName": "Robert",
          "lastName": "Menendez",
          "middleName": null,
          "fullName": "Robert Menendez",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/M000639.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "NJ",
            "stateFull": "New Jersey",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-NJ---M000639",
            "builtID": "nj--",
            "externalID": "M000639",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.menendez.senate.gov/contact",
            "address1": "528 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-4744",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorMenendez",
            "youtube": "SenatorMenendezNJ",
            "instagram": null,
            "facebook": "senatormenendez",
            "urls": ["https://www.menendez.senate.gov"],
            "rss_url": null
          },
          "title": "NJ Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/M000639.json",
          "govtrack_id": "400272",
          "cspan_id": "29608",
          "votesmart_id": "26961",
          "icpsr_id": "29373",
          "crp_id": "N00000699",
          "google_entity_id": "/m/033d3p",
          "state_rank": "senior",
          "lis_id": "S306",
          "suffix": null,
          "date_of_birth": "1954-01-01",
          "leadership_role": null,
          "fec_candidate_id": "H2NJ13075",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "18",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 53,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 9.57,
          "votes_with_party_pct": 96.59,
          "votes_against_party_pct": 3.41,
          "ocd_id": "ocd-division/country:us/state:nj"
        }
      ],
      "hash": { "SENATE-NJ---B001288": 0, "SENATE-NJ---M000639": 1 }
    },
    "HOUSE": {
      "10": {
        "members": [
          {
            "API_ID": "P000604",
            "in_office": true,
            "firstName": "Donald",
            "lastName": "Payne",
            "middleName": "M.",
            "fullName": "Donald M. Payne, Jr.",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/P000604.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "10",
              "chamber": "",
              "hashID": "HOUSE-NJ-10--P000604",
              "builtID": "nj--10",
              "externalID": "P000604",
              "geometry": null,
              "geoid": "3410"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "106 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3436",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDonaldPayne",
              "youtube": null,
              "instagram": null,
              "facebook": "DonaldPayneJr",
              "urls": ["https://payne.house.gov"],
              "rss_url": "https://payne.house.gov/rss.xml"
            },
            "title": "NJ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000604.json",
            "govtrack_id": "412506",
            "cspan_id": "65639",
            "votesmart_id": "90668",
            "icpsr_id": "31103",
            "crp_id": "N00034639",
            "google_entity_id": "/m/0jwr2v8",
            "state_rank": null,
            "lis_id": null,
            "suffix": "Jr.",
            "date_of_birth": "1958-12-17",
            "leadership_role": null,
            "fec_candidate_id": "H2NJ10154",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 872,
            "missed_votes": 99,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 11.35,
            "votes_with_party_pct": 96.61,
            "votes_against_party_pct": 1.04,
            "ocd_id": "ocd-division/country:us/state:nj/cd:10",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NJ-10--P000604": 0 }
      },
      "11": {
        "members": [
          {
            "API_ID": "S001207",
            "in_office": true,
            "firstName": "Mikie",
            "lastName": "Sherrill",
            "middleName": null,
            "fullName": "Mikie Sherrill",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001207.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "11",
              "chamber": "",
              "hashID": "HOUSE-NJ-11--S001207",
              "builtID": "nj--11",
              "externalID": "S001207",
              "geometry": null,
              "geoid": "3411"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1427 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5034",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepSherrill",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://sherrill.house.gov"],
              "rss_url": null
            },
            "title": "NJ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001207.json",
            "govtrack_id": "412799",
            "cspan_id": null,
            "votesmart_id": "179651",
            "icpsr_id": null,
            "crp_id": "N00041154",
            "google_entity_id": "/g/11f3r4x6x5",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1972-01-19",
            "leadership_role": null,
            "fec_candidate_id": "H8NJ11142",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 23,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.18,
            "votes_with_party_pct": 93.84,
            "votes_against_party_pct": 4.2,
            "ocd_id": "ocd-division/country:us/state:nj/cd:11",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NJ-11--S001207": 0 }
      },
      "12": {
        "members": [
          {
            "API_ID": "W000822",
            "in_office": true,
            "firstName": "Bonnie",
            "lastName": "Watson Coleman",
            "middleName": null,
            "fullName": "Bonnie Watson Coleman",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/W000822.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "12",
              "chamber": "",
              "hashID": "HOUSE-NJ-12--W000822",
              "builtID": "nj--12",
              "externalID": "W000822",
              "geometry": null,
              "geoid": "3412"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "168 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5801",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBonnie",
              "youtube": null,
              "instagram": null,
              "facebook": "RepBonnie",
              "urls": ["https://watsoncoleman.house.gov"],
              "rss_url": null
            },
            "title": "NJ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000822.json",
            "govtrack_id": "412644",
            "cspan_id": "79091",
            "votesmart_id": "24799",
            "icpsr_id": "21538",
            "crp_id": "N00036158",
            "google_entity_id": "/m/08kj70",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1945-02-06",
            "leadership_role": null,
            "fec_candidate_id": "H4NJ12149",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 119,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 11.27,
            "votes_with_party_pct": 94.61,
            "votes_against_party_pct": 3.34,
            "ocd_id": "ocd-division/country:us/state:nj/cd:12",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NJ-12--W000822": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "G000583",
            "in_office": true,
            "firstName": "Josh",
            "lastName": "Gottheimer",
            "middleName": null,
            "fullName": "Josh Gottheimer",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/G000583.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-NJ-05--G000583",
              "builtID": "nj--05",
              "externalID": "G000583",
              "geometry": null,
              "geoid": "3405"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "203 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-4465",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJoshG",
              "youtube": null,
              "instagram": null,
              "facebook": "RepJoshG",
              "urls": ["https://gottheimer.house.gov"],
              "rss_url": null
            },
            "title": "NJ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000583.json",
            "govtrack_id": "412714",
            "cspan_id": "9275683",
            "votesmart_id": "169202",
            "icpsr_id": "21723",
            "crp_id": "N00036944",
            "google_entity_id": "/g/120x5twv",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1975-03-08",
            "leadership_role": null,
            "fec_candidate_id": "H6NJ05171",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 54,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 5.11,
            "votes_with_party_pct": 90.42,
            "votes_against_party_pct": 7.56,
            "ocd_id": "ocd-division/country:us/state:nj/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NJ-05--G000583": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "K000398",
            "in_office": true,
            "firstName": "Thomas",
            "lastName": "Kean",
            "middleName": null,
            "fullName": "Thomas H. Kean, Jr.",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/K000398.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-NJ-07--K000398",
              "builtID": "nj--07",
              "externalID": "K000398",
              "geometry": null,
              "geoid": "3407"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "251 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5361",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "CongressmanKean",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://kean.house.gov/"],
              "rss_url": null
            },
            "title": "NJ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000398.json",
            "govtrack_id": "456917",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1968-09-05",
            "leadership_role": null,
            "fec_candidate_id": "H0NJ07261",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.57,
            "votes_with_party_pct": 80.08,
            "votes_against_party_pct": 17.98,
            "ocd_id": "ocd-division/country:us/state:nj/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NJ-07--K000398": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "K000394",
            "in_office": true,
            "firstName": "Andy",
            "lastName": "Kim",
            "middleName": null,
            "fullName": "Andy Kim",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/K000394.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-NJ-03--K000394",
              "builtID": "nj--03",
              "externalID": "K000394",
              "geometry": null,
              "geoid": "3403"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2444 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4765",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAndyKimNJ",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://kim.house.gov"],
              "rss_url": null
            },
            "title": "NJ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000394.json",
            "govtrack_id": "412797",
            "cspan_id": null,
            "votesmart_id": "179640",
            "icpsr_id": null,
            "crp_id": "N00041370",
            "google_entity_id": "/g/11f730yxds",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1982-07-12",
            "leadership_role": null,
            "fec_candidate_id": "H8NJ03206",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 105,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 9.94,
            "votes_with_party_pct": 96.08,
            "votes_against_party_pct": 1.8,
            "ocd_id": "ocd-division/country:us/state:nj/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NJ-03--K000394": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "M001226",
            "in_office": true,
            "firstName": "Robert",
            "lastName": "Menendez",
            "middleName": null,
            "fullName": "Robert Menendez",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M001226.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-NJ-08--M001226",
              "builtID": "nj--08",
              "externalID": "M001226",
              "geometry": null,
              "geoid": "3408"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1007 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-7919",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMenendez",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://menendez.house.gov/"],
              "rss_url": null
            },
            "title": "NJ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001226.json",
            "govtrack_id": "456918",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1985-07-12",
            "leadership_role": null,
            "fec_candidate_id": "H2NJ08232",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 15,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.42,
            "votes_with_party_pct": 96.22,
            "votes_against_party_pct": 1.84,
            "ocd_id": "ocd-division/country:us/state:nj/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NJ-08--M001226": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "N000188",
            "in_office": true,
            "firstName": "Donald",
            "lastName": "Norcross",
            "middleName": "W.",
            "fullName": "Donald Norcross",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/N000188.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-NJ-01--N000188",
              "builtID": "nj--01",
              "externalID": "N000188",
              "geometry": null,
              "geoid": "3401"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2427 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6501",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "DonaldNorcross",
              "youtube": null,
              "instagram": null,
              "facebook": "DonaldNorcrossNJ",
              "urls": ["https://norcross.house.gov"],
              "rss_url": "https://norcross.house.gov/?a=RSS.Feed"
            },
            "title": "NJ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/N000188.json",
            "govtrack_id": "412606",
            "cspan_id": "76311",
            "votesmart_id": "116277",
            "icpsr_id": "21536",
            "crp_id": "N00036154",
            "google_entity_id": "/m/09g8b1_",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1958-12-13",
            "leadership_role": null,
            "fec_candidate_id": "H4NJ01084",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 59,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 5.59,
            "votes_with_party_pct": 95.04,
            "votes_against_party_pct": 2.94,
            "ocd_id": "ocd-division/country:us/state:nj/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NJ-01--N000188": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "P000034",
            "in_office": true,
            "firstName": "Frank",
            "lastName": "Pallone",
            "middleName": null,
            "fullName": "Frank Pallone, Jr.",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/P000034.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-NJ-06--P000034",
              "builtID": "nj--06",
              "externalID": "P000034",
              "geometry": null,
              "geoid": "3406"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2107 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4671",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "FrankPallone",
              "youtube": "repfrankpallone",
              "instagram": null,
              "facebook": "repfrankpallone",
              "urls": ["https://pallone.house.gov"],
              "rss_url": "https://pallone.house.gov/rss.xml"
            },
            "title": "NJ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000034.json",
            "govtrack_id": "400308",
            "cspan_id": "6107",
            "votesmart_id": "26951",
            "icpsr_id": "15454",
            "crp_id": "N00000781",
            "google_entity_id": "/m/0g6t5w",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1951-10-30",
            "leadership_role": null,
            "fec_candidate_id": "H8NJ03073",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "38",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 2,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.19,
            "votes_with_party_pct": 94.92,
            "votes_against_party_pct": 3.26,
            "ocd_id": "ocd-division/country:us/state:nj/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NJ-06--P000034": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "P000096",
            "in_office": true,
            "firstName": "Bill",
            "lastName": "Pascrell",
            "middleName": null,
            "fullName": "Bill Pascrell, Jr.",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/P000096.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-NJ-09--P000096",
              "builtID": "nj--09",
              "externalID": "P000096",
              "geometry": null,
              "geoid": "3409"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2409 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5751",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "BillPascrell",
              "youtube": "RepPascrell",
              "instagram": null,
              "facebook": "pascrell",
              "urls": ["https://pascrell.house.gov"],
              "rss_url": null
            },
            "title": "NJ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000096.json",
            "govtrack_id": "400309",
            "cspan_id": "45543",
            "votesmart_id": "478",
            "icpsr_id": "29741",
            "crp_id": "N00000751",
            "google_entity_id": "/m/03txs_",
            "state_rank": null,
            "lis_id": null,
            "suffix": "Jr.",
            "date_of_birth": "1937-01-25",
            "leadership_role": null,
            "fec_candidate_id": "H6NJ08118",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "28",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 31,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.94,
            "votes_with_party_pct": 96.65,
            "votes_against_party_pct": 1.48,
            "ocd_id": "ocd-division/country:us/state:nj/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NJ-09--P000096": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "S000522",
            "in_office": true,
            "firstName": "Christopher",
            "lastName": "Smith",
            "middleName": "H.",
            "fullName": "Christopher H. Smith",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S000522.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-NJ-04--S000522",
              "builtID": "nj--04",
              "externalID": "S000522",
              "geometry": null,
              "geoid": "3404"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2373 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3765",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepChrisSmith",
              "youtube": "USRepChrisSmith",
              "instagram": null,
              "facebook": "RepChrisSmith",
              "urls": ["https://chrissmith.house.gov"],
              "rss_url": "https://chrissmith.house.gov/news/rss.aspx"
            },
            "title": "NJ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S000522.json",
            "govtrack_id": "400380",
            "cspan_id": "6411",
            "votesmart_id": "26952",
            "icpsr_id": "14863",
            "crp_id": "N00009816",
            "google_entity_id": "/m/033f4d",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1953-03-04",
            "leadership_role": null,
            "fec_candidate_id": "H8NJ04014",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "44",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 24,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 2.27,
            "votes_with_party_pct": 87.64,
            "votes_against_party_pct": 10.48,
            "ocd_id": "ocd-division/country:us/state:nj/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NJ-04--S000522": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "V000133",
            "in_office": true,
            "firstName": "Jefferson",
            "lastName": "Van Drew",
            "middleName": null,
            "fullName": "Jefferson Van Drew",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/V000133.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-NJ-02--V000133",
              "builtID": "nj--02",
              "externalID": "V000133",
              "geometry": null,
              "geoid": "3402"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2447 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6572",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "CongressmanJVD",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://vandrew.house.gov"],
              "rss_url": null
            },
            "title": "NJ House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/V000133.json",
            "govtrack_id": "412796",
            "cspan_id": null,
            "votesmart_id": "24685",
            "icpsr_id": null,
            "crp_id": "N00042164",
            "google_entity_id": "/m/08h3sl",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1953-02-23",
            "leadership_role": null,
            "fec_candidate_id": "H8NJ02166",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 9,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 90.64,
            "votes_against_party_pct": 7.41,
            "ocd_id": "ocd-division/country:us/state:nj/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NJ-02--V000133": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/4ebe8d6e-9814-4f68-aa9f-148c0942eaab",
            "firstName": "Antwan L.",
            "lastName": "McClellan",
            "fullName": "Antwan McClellan",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.njleg.state.nj.us/members/memberphotos/mcclellan_antwan_2020.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-NJ-1-lower-ocd-person-4ebe8d6e-9814-4f68-aa9f-148c0942eaab",
              "builtID": "nj-lower-1",
              "externalID": "ocd-person/4ebe8d6e-9814-4f68-aa9f-148c0942eaab",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmmcclellan@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "School House Office Park 211 S. Main St., Suite 104, Cape May Court House, NJ 08210",
              "phone1": null,
              "phone2": "609-778-2012",
              "fax1": null,
              "fax2": "609-778-2033",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njassemblygop.com/mcclellan/",
                "https://www.njleg.state.nj.us/legislative-roster/425/assemblyman-mcclellan"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/7f9ebe1c-d4fa-4dd0-8948-7cf6248ffb6d",
            "firstName": "Erik Kurt",
            "lastName": "Simonsen",
            "fullName": "Erik Simonsen",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.njleg.state.nj.us/members/memberphotos/simonsen_erik_2020.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-NJ-1-lower-ocd-person-7f9ebe1c-d4fa-4dd0-8948-7cf6248ffb6d",
              "builtID": "nj-lower-1",
              "externalID": "ocd-person/7f9ebe1c-d4fa-4dd0-8948-7cf6248ffb6d",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmsimonsen@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "School House Office Park 211 S. Main St., Suite 104, Cape May Court House, NJ 08210",
              "phone1": null,
              "phone2": "609-778-2012",
              "fax1": null,
              "fax2": "609-778-2033",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njassemblygop.com/simonsen/",
                "https://www.njleg.state.nj.us/legislative-roster/426/assemblyman-simonsen"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-1-lower-ocd-person-4ebe8d6e-9814-4f68-aa9f-148c0942eaab": 0,
          "SLDL-NJ-1-lower-ocd-person-7f9ebe1c-d4fa-4dd0-8948-7cf6248ffb6d": 1
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/3525b57b-adb4-4cae-b3a3-6bf2b22a5419",
            "firstName": "Claire S.",
            "lastName": "Swift",
            "fullName": "Claire Swift",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-NJ-2-lower-ocd-person-3525b57b-adb4-4cae-b3a3-6bf2b22a5419",
              "builtID": "nj-lower-2",
              "externalID": "ocd-person/3525b57b-adb4-4cae-b3a3-6bf2b22a5419",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswswift@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "3123 Atlantic Ave. Suite 100, Atlantic City, NJ 08401",
              "phone1": null,
              "phone2": "609-677-8266",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njassemblygop.com/swift/",
                "https://www.njleg.state.nj.us/legislative-roster/438/assemblywoman-swift"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/df880b40-1e96-49d0-92bc-70732a1cce32",
            "firstName": "Donald A.",
            "lastName": "Guardian",
            "fullName": "Don Guardian",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-NJ-2-lower-ocd-person-df880b40-1e96-49d0-92bc-70732a1cce32",
              "builtID": "nj-lower-2",
              "externalID": "ocd-person/df880b40-1e96-49d0-92bc-70732a1cce32",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmguardian@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "3123 Atlantic Ave. Suite 100, Atlantic City, NJ 08401",
              "phone1": null,
              "phone2": "609-677-8266",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njassemblygop.com/guardian/",
                "https://www.njleg.state.nj.us/legislative-roster/437/assemblyman-guardian"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-2-lower-ocd-person-3525b57b-adb4-4cae-b3a3-6bf2b22a5419": 0,
          "SLDL-NJ-2-lower-ocd-person-df880b40-1e96-49d0-92bc-70732a1cce32": 1
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/64a8c09d-24a2-4d4c-a071-d09f0a30c79f",
            "firstName": "David",
            "lastName": "Bailey",
            "fullName": "Dave Bailey",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-NJ-3-lower-ocd-person-64a8c09d-24a2-4d4c-a071-d09f0a30c79f",
              "builtID": "nj-lower-3",
              "externalID": "ocd-person/64a8c09d-24a2-4d4c-a071-d09f0a30c79f",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmbailey@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "711 N. Main St., Glassboro, NJ 08028",
              "phone1": null,
              "phone2": "856-226-3530",
              "fax1": null,
              "fax2": "856-243-2830",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/dave-bailey/",
                "https://www.njleg.state.nj.us/legislative-roster/459/assemblyman-bailey"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/5d619c98-5127-433c-99c6-adb888ab407b",
            "firstName": "Heather",
            "lastName": "Simmons",
            "fullName": "Heather Simmons",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-NJ-3-lower-ocd-person-5d619c98-5127-433c-99c6-adb888ab407b",
              "builtID": "nj-lower-3",
              "externalID": "ocd-person/5d619c98-5127-433c-99c6-adb888ab407b",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswsimmons@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "711 N. Main St., Glassboro, NJ 08028",
              "phone1": null,
              "phone2": "856-226-3530",
              "fax1": null,
              "fax2": "856-243-2830",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/heather-simmons/",
                "https://www.njleg.state.nj.us/legislative-roster/460/assemblywoman-simmons"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-3-lower-ocd-person-64a8c09d-24a2-4d4c-a071-d09f0a30c79f": 0,
          "SLDL-NJ-3-lower-ocd-person-5d619c98-5127-433c-99c6-adb888ab407b": 1
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/fab6547d-4f37-4c02-a738-dc4fb0b707e7",
            "firstName": "Cody D.",
            "lastName": "Miller",
            "fullName": "Cody Miller",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-NJ-4-lower-ocd-person-fab6547d-4f37-4c02-a738-dc4fb0b707e7",
              "builtID": "nj-lower-4",
              "externalID": "ocd-person/fab6547d-4f37-4c02-a738-dc4fb0b707e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmmiller@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "129 Johnson Road Suite 1, Turnersville, NJ 08012",
              "phone1": null,
              "phone2": "856-232-6700",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/cody-miller/",
                "https://www.njleg.state.nj.us/legislative-roster/463/assemblyman-miller"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/fa6aa5c6-0479-4d12-88c0-75480bfbe3a5",
            "firstName": "Daniel",
            "lastName": "Hutchison",
            "fullName": "Dan Hutchison",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-NJ-4-lower-ocd-person-fa6aa5c6-0479-4d12-88c0-75480bfbe3a5",
              "builtID": "nj-lower-4",
              "externalID": "ocd-person/fa6aa5c6-0479-4d12-88c0-75480bfbe3a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmhutchison@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1379 Chews Landing Road, Laurel Springs, NJ 08021",
              "phone1": null,
              "phone2": "856-401-3073",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/dan-hutchison/",
                "https://www.njleg.state.nj.us/legislative-roster/462/assemblyman-hutchison"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-4-lower-ocd-person-fab6547d-4f37-4c02-a738-dc4fb0b707e7": 0,
          "SLDL-NJ-4-lower-ocd-person-fa6aa5c6-0479-4d12-88c0-75480bfbe3a5": 1
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/1e9cd368-349e-4ddc-a34d-8301d0ac4841",
            "firstName": "William F.",
            "lastName": "Moen",
            "fullName": "Bill Moen",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-NJ-5-lower-ocd-person-1e9cd368-349e-4ddc-a34d-8301d0ac4841",
              "builtID": "nj-lower-5",
              "externalID": "ocd-person/1e9cd368-349e-4ddc-a34d-8301d0ac4841",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmmoen@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "608 N. Broad St. Suite 200, Woodbury, NJ 08096",
              "phone1": null,
              "phone2": "856-853-2960",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/William-F-Moen-Jr/",
                "https://www.njleg.state.nj.us/legislative-roster/420/assemblyman-moen",
                "https://www.assemblydems.com/163/William-F-Moen-Jr---District-5"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/3d5d3c07-70ed-481a-91ba-a19fce581561",
            "firstName": "William W.",
            "lastName": "Spearman",
            "fullName": "Bill Spearman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/spearman_william_2018a.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-NJ-5-lower-ocd-person-3d5d3c07-70ed-481a-91ba-a19fce581561",
              "builtID": "nj-lower-5",
              "externalID": "ocd-person/3d5d3c07-70ed-481a-91ba-a19fce581561",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmspearman@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "608 N. Broad St. Suite 200, Woodbury, NJ 08096",
              "phone1": null,
              "phone2": "856-853-2960",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=415",
                "https://www.assemblydems.com/william-spearman/",
                "https://www.njleg.state.nj.us/legislative-roster/415/assemblyman-spearman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-5-lower-ocd-person-1e9cd368-349e-4ddc-a34d-8301d0ac4841": 0,
          "SLDL-NJ-5-lower-ocd-person-3d5d3c07-70ed-481a-91ba-a19fce581561": 1
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/a27823aa-f5a9-4d6c-8089-1d8ceee6f8e0",
            "firstName": "Louis D.",
            "lastName": "Greenwald",
            "fullName": "Lou Greenwald",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/greenwald_color.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-NJ-6-lower-ocd-person-a27823aa-f5a9-4d6c-8089-1d8ceee6f8e0",
              "builtID": "nj-lower-6",
              "externalID": "ocd-person/a27823aa-f5a9-4d6c-8089-1d8ceee6f8e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmgreenwald@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1101 Laurel Oak Road Suite 150, Voorhees, NJ 08043",
              "phone1": null,
              "phone2": "856-435-1247",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=155",
                "https://www.assemblydems.com/louis-greenwald/",
                "https://www.njleg.state.nj.us/legislative-roster/155/assemblyman-greenwald"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/279cf9a8-0a51-45cd-b79f-b174f6f1cbc0",
            "firstName": "Pamela Rosen",
            "lastName": "Lampitt",
            "fullName": "Pam Lampitt",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/lampitt_pamela_2017.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-NJ-6-lower-ocd-person-279cf9a8-0a51-45cd-b79f-b174f6f1cbc0",
              "builtID": "nj-lower-6",
              "externalID": "ocd-person/279cf9a8-0a51-45cd-b79f-b174f6f1cbc0",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswlampitt@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1101 Laurel Oak Road Suite 150, Voorhees, NJ 08043",
              "phone1": null,
              "phone2": "856-435-1247",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=251",
                "https://www.assemblydems.com/pamela-lampitt/",
                "https://www.njleg.state.nj.us/legislative-roster/251/assemblywoman-lampitt"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-6-lower-ocd-person-a27823aa-f5a9-4d6c-8089-1d8ceee6f8e0": 0,
          "SLDL-NJ-6-lower-ocd-person-279cf9a8-0a51-45cd-b79f-b174f6f1cbc0": 1
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/c33b5f2b-0494-414f-aa84-d2286ae46095",
            "firstName": "Carol A.",
            "lastName": "Murphy",
            "fullName": "Carol Murphy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/murphy_carol_2021.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-NJ-7-lower-ocd-person-c33b5f2b-0494-414f-aa84-d2286ae46095",
              "builtID": "nj-lower-7",
              "externalID": "ocd-person/c33b5f2b-0494-414f-aa84-d2286ae46095",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswmurphy@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "750 Centerton Road, Mount Laurel, NJ 08054",
              "phone1": null,
              "phone2": "856-242-2018",
              "fax1": null,
              "fax2": "856-722-6749",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=397",
                "https://www.assemblydems.com/carol-murphy/",
                "https://www.njleg.state.nj.us/legislative-roster/397/assemblywoman-murphy"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/6b67938b-adbf-488a-a3c1-3de2c3bc3b8f",
            "firstName": "Herbert C.",
            "lastName": "Conaway",
            "fullName": "Herb Conaway",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-NJ-7-lower-ocd-person-6b67938b-adbf-488a-a3c1-3de2c3bc3b8f",
              "builtID": "nj-lower-7",
              "externalID": "ocd-person/6b67938b-adbf-488a-a3c1-3de2c3bc3b8f",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmconaway@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "Delran Professional Center, 8008 Route 130 North Building C, Suite 450, Delran, NJ 08075",
              "phone1": null,
              "phone2": "856-461-3997",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/herb-conaway/",
                "https://www.njleg.state.nj.us/legislative-roster/186/assemblyman-conaway"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-7-lower-ocd-person-c33b5f2b-0494-414f-aa84-d2286ae46095": 0,
          "SLDL-NJ-7-lower-ocd-person-6b67938b-adbf-488a-a3c1-3de2c3bc3b8f": 1
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/acf3b54e-9725-4867-aaa5-9275ada21b37",
            "firstName": "Andrea",
            "lastName": "Katz",
            "fullName": "Andrea Katz",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-NJ-8-lower-ocd-person-acf3b54e-9725-4867-aaa5-9275ada21b37",
              "builtID": "nj-lower-8",
              "externalID": "ocd-person/acf3b54e-9725-4867-aaa5-9275ada21b37",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswkatz@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "100 High St. Suite 101, Mt. Holly, NJ 08060",
              "phone1": null,
              "phone2": "609-850-2397",
              "fax1": null,
              "fax2": "609-784-8776",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/andrea-katz-2/",
                "https://www.njleg.state.nj.us/legislative-roster/496/assemblywoman-katz"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/71bb6c1c-60ba-4dc8-8534-b07524695d6e",
            "firstName": "Michael",
            "lastName": "Torrissi",
            "fullName": "Mike Torrissi",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-NJ-8-lower-ocd-person-71bb6c1c-60ba-4dc8-8534-b07524695d6e",
              "builtID": "nj-lower-8",
              "externalID": "ocd-person/71bb6c1c-60ba-4dc8-8534-b07524695d6e",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmtorrissi@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "Elmwood Business Park Suite B-101, Evesham, NJ 08053",
              "phone1": null,
              "phone2": "856-817-2143",
              "fax1": null,
              "fax2": "856-817-2065",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njassemblygop.com/torrissi/",
                "https://www.njleg.state.nj.us/legislative-roster/442/assemblyman-torrissi"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-8-lower-ocd-person-acf3b54e-9725-4867-aaa5-9275ada21b37": 0,
          "SLDL-NJ-8-lower-ocd-person-71bb6c1c-60ba-4dc8-8534-b07524695d6e": 1
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/d6731a7f-a22e-48dd-8bba-fd2c155c16c3",
            "firstName": "Brian E.",
            "lastName": "Rumpf",
            "fullName": "Brian Rumpf",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/rumpf_brian_2020.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-NJ-9-lower-ocd-person-d6731a7f-a22e-48dd-8bba-fd2c155c16c3",
              "builtID": "nj-lower-9",
              "externalID": "ocd-person/d6731a7f-a22e-48dd-8bba-fd2c155c16c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmrumpf@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "620 W. Lacey Road, Forked River, NJ 08731",
              "phone1": null,
              "phone2": "609-693-6700",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=228",
                "https://www.njassemblygop.com/rumpf/",
                "https://www.njleg.state.nj.us/legislative-roster/228/assemblyman-rumpf"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/85863833-b5e6-40f2-aafc-5a0fc5f790d0",
            "firstName": "Gregory E.",
            "lastName": "Myhre",
            "fullName": "Greg Myhre",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-NJ-9-lower-ocd-person-85863833-b5e6-40f2-aafc-5a0fc5f790d0",
              "builtID": "nj-lower-9",
              "externalID": "ocd-person/85863833-b5e6-40f2-aafc-5a0fc5f790d0",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmmyhre@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "620 W. Lacey Road, Forked River, NJ 08731",
              "phone1": null,
              "phone2": "609-693-6700",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njassemblygop.com/asm-myhre/",
                "https://www.njleg.state.nj.us/legislative-roster/466/assemblyman-myhre"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-9-lower-ocd-person-d6731a7f-a22e-48dd-8bba-fd2c155c16c3": 0,
          "SLDL-NJ-9-lower-ocd-person-85863833-b5e6-40f2-aafc-5a0fc5f790d0": 1
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/2ac520a3-ae36-4936-a9c3-6842401b6c83",
            "firstName": "Gregory P.",
            "lastName": "McGuckin",
            "fullName": "Greg McGuckin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/mcguckin_gregory_2020.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-NJ-10-lower-ocd-person-2ac520a3-ae36-4936-a9c3-6842401b6c83",
              "builtID": "nj-lower-10",
              "externalID": "ocd-person/2ac520a3-ae36-4936-a9c3-6842401b6c83",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmmcguckin@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "852 Highway 70, Brick, NJ 08724",
              "phone1": null,
              "phone2": "732-840-9028",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=341",
                "https://www.njassemblygop.com/McGuckin/",
                "https://www.njleg.state.nj.us/legislative-roster/341/assemblyman-mcguckin"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e4c2301b-6812-423e-b27a-c40919f4bd5d",
            "firstName": "Paul Michael",
            "lastName": "Kanitra",
            "fullName": "Paul Kanitra",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-NJ-10-lower-ocd-person-e4c2301b-6812-423e-b27a-c40919f4bd5d",
              "builtID": "nj-lower-10",
              "externalID": "ocd-person/e4c2301b-6812-423e-b27a-c40919f4bd5d",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmkanitra@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "8523 Highway 70, Brick, NJ 08724",
              "phone1": null,
              "phone2": "732-840-9028",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njassemblygop.com/kanitra/",
                "https://www.njleg.state.nj.us/legislative-roster/467/assemblyman-kanitra",
                "https://www.njassemblygop.com/226/Paul-Kanitra"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-10-lower-ocd-person-2ac520a3-ae36-4936-a9c3-6842401b6c83": 0,
          "SLDL-NJ-10-lower-ocd-person-e4c2301b-6812-423e-b27a-c40919f4bd5d": 1
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/589a73bd-a164-472a-8b50-4b4d0846cd24",
            "firstName": "Luanne M.",
            "lastName": "Peterpaul",
            "fullName": "Luanne Peterpaul",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-NJ-11-lower-ocd-person-589a73bd-a164-472a-8b50-4b4d0846cd24",
              "builtID": "nj-lower-11",
              "externalID": "ocd-person/589a73bd-a164-472a-8b50-4b4d0846cd24",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswpeterpaul@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "802 W. Park Ave. Suite 302, Ocean Township, NJ 07712",
              "phone1": null,
              "phone2": "732-695-3371",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/luanne-peterpaul/",
                "https://www.njleg.state.nj.us/legislative-roster/469/assemblywoman-peterpaul"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/78ee83b9-ead4-4a03-89e7-f976d0454408",
            "firstName": "Margaret M.",
            "lastName": "Donlon",
            "fullName": "Margie Donlon",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-NJ-11-lower-ocd-person-78ee83b9-ead4-4a03-89e7-f976d0454408",
              "builtID": "nj-lower-11",
              "externalID": "ocd-person/78ee83b9-ead4-4a03-89e7-f976d0454408",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswdonlon@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "802 W. Park Ave., Suite 302, Ocean Township, NJ 07712",
              "phone1": null,
              "phone2": "732-695-3371",
              "fax1": null,
              "fax2": "732-695-3374",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/margie-donlon/",
                "https://www.njleg.state.nj.us/legislative-roster/468/assemblywoman-donlon"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-11-lower-ocd-person-589a73bd-a164-472a-8b50-4b4d0846cd24": 0,
          "SLDL-NJ-11-lower-ocd-person-78ee83b9-ead4-4a03-89e7-f976d0454408": 1
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/fc986c0e-c1cd-422f-80c1-4d3691f34f28",
            "firstName": "Alexander",
            "lastName": "Sauickie",
            "fullName": "Alex Sauickie",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-NJ-12-lower-ocd-person-fc986c0e-c1cd-422f-80c1-4d3691f34f28",
              "builtID": "nj-lower-12",
              "externalID": "ocd-person/fc986c0e-c1cd-422f-80c1-4d3691f34f28",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmsauickie@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "405 Route 539, Cream Ridge, NJ 08514",
              "phone1": null,
              "phone2": "609-758-0205",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/legislative-roster/454/assemblyman-sauickie",
                "https://www.njassemblygop.com/sauickie/",
                "https://njleg.state.nj.us/legislative-roster/454/assemblyman-sauickie"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b94db48b-9083-48c9-8b27-3603db14fa3e",
            "firstName": "Robert D.",
            "lastName": "Clifton",
            "fullName": "Rob Clifton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/clifton_robert_2020.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-NJ-12-lower-ocd-person-b94db48b-9083-48c9-8b27-3603db14fa3e",
              "builtID": "nj-lower-12",
              "externalID": "ocd-person/b94db48b-9083-48c9-8b27-3603db14fa3e",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmclifton@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "935 Highway 34 Suite 3B, Matawan, NJ 07747",
              "phone1": null,
              "phone2": "732-970-6386",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=342",
                "https://www.njassemblygop.com/clifton/",
                "https://www.njleg.state.nj.us/legislative-roster/342/assemblyman-clifton"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-12-lower-ocd-person-fc986c0e-c1cd-422f-80c1-4d3691f34f28": 0,
          "SLDL-NJ-12-lower-ocd-person-b94db48b-9083-48c9-8b27-3603db14fa3e": 1
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/4323bf03-63e6-4f8b-9b2e-3554df7723bf",
            "firstName": "Gerard P.",
            "lastName": "Scharfenberger",
            "fullName": "Gerry Scharfenberger",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.njleg.state.nj.us/members/memberphotos/scharfenberger_gerard_2020a.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-NJ-13-lower-ocd-person-4323bf03-63e6-4f8b-9b2e-3554df7723bf",
              "builtID": "nj-lower-13",
              "externalID": "ocd-person/4323bf03-63e6-4f8b-9b2e-3554df7723bf",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmscharfenberger@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1715 Highway 35 North Suite 306, Middletown, NJ 07748",
              "phone1": null,
              "phone2": "732-856-9294",
              "fax1": null,
              "fax2": "732-856-9243",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=422",
                "https://www.njassemblygop.com/scharfenberger/",
                "https://www.njleg.state.nj.us/legislative-roster/422/assemblyman-scharfenberger",
                "https://www.njassemblygop.com/234/Gerry-Scharfenberger"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/379945ef-55af-4b76-96ce-fcddbcb0c1c6",
            "firstName": "Victoria Anne",
            "lastName": "Flynn",
            "fullName": "Vicky Flynn",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-NJ-13-lower-ocd-person-379945ef-55af-4b76-96ce-fcddbcb0c1c6",
              "builtID": "nj-lower-13",
              "externalID": "ocd-person/379945ef-55af-4b76-96ce-fcddbcb0c1c6",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswflynn@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1715 Highway 35 North Suite 307, Middletown, NJ 07748",
              "phone1": null,
              "phone2": "732-856-9833",
              "fax1": null,
              "fax2": "732-856-9691",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njassemblygop.com/flynn/",
                "https://www.njleg.state.nj.us/legislative-roster/446/assemblywoman-flynn"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-13-lower-ocd-person-4323bf03-63e6-4f8b-9b2e-3554df7723bf": 0,
          "SLDL-NJ-13-lower-ocd-person-379945ef-55af-4b76-96ce-fcddbcb0c1c6": 1
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/c05a0080-95df-4576-a81c-d509fb4a5063",
            "firstName": "Tennille R.",
            "lastName": "McCoy",
            "fullName": "Tennille McCoy",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-NJ-14-lower-ocd-person-c05a0080-95df-4576-a81c-d509fb4a5063",
              "builtID": "nj-lower-14",
              "externalID": "ocd-person/c05a0080-95df-4576-a81c-d509fb4a5063",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswmccoy@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "3691A Nottingham Way, Hamilton Square, NJ 08690",
              "phone1": null,
              "phone2": "609-631-0198",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/tennille-mccoy/",
                "https://www.njleg.state.nj.us/legislative-roster/471/assemblywoman-mccoy"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/08016f74-f5d6-4554-91b4-385fdbd558a7",
            "firstName": "Wayne P.",
            "lastName": "DeAngelo",
            "fullName": "Wayne DeAngelo",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/deangelo_color.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-NJ-14-lower-ocd-person-08016f74-f5d6-4554-91b4-385fdbd558a7",
              "builtID": "nj-lower-14",
              "externalID": "ocd-person/08016f74-f5d6-4554-91b4-385fdbd558a7",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmdeangelo@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "4621A Nottingham Way, Hamilton, NJ 08690",
              "phone1": null,
              "phone2": "609-631-7501",
              "fax1": null,
              "fax2": "609-631-7531",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=279",
                "https://www.assemblydems.com/wayne-deangelo/",
                "https://www.njleg.state.nj.us/legislative-roster/279/assemblyman-deangelo"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-14-lower-ocd-person-c05a0080-95df-4576-a81c-d509fb4a5063": 0,
          "SLDL-NJ-14-lower-ocd-person-08016f74-f5d6-4554-91b4-385fdbd558a7": 1
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/c6d61a12-eeb5-4400-943d-68a81b0728f0",
            "firstName": "Anthony S.",
            "lastName": "Verrelli",
            "fullName": "Anthony Verrelli",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/verrelli_anthony_2020.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-NJ-15-lower-ocd-person-c6d61a12-eeb5-4400-943d-68a81b0728f0",
              "builtID": "nj-lower-15",
              "externalID": "ocd-person/c6d61a12-eeb5-4400-943d-68a81b0728f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmverrelli@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "144 W. State St., Trenton, NJ 08608",
              "phone1": null,
              "phone2": "609-292-0500",
              "fax1": null,
              "fax2": "609-571-9645",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=416",
                "https://www.assemblydems.com/anthony-verrelli/",
                "https://www.njleg.state.nj.us/legislative-roster/416/assemblyman-verrelli"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b432ec8c-f914-47dc-addd-740dfc95daaf",
            "firstName": "Verlina",
            "lastName": "Reynolds-Jackson",
            "fullName": "Verlina Reynolds-Jackson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/reynolds-jackson_verlina_2018.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-NJ-15-lower-ocd-person-b432ec8c-f914-47dc-addd-740dfc95daaf",
              "builtID": "nj-lower-15",
              "externalID": "ocd-person/b432ec8c-f914-47dc-addd-740dfc95daaf",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswreynoldsjackson@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "144 W. State St., Trenton, NJ 08608",
              "phone1": null,
              "phone2": "609-571-9638",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=409",
                "https://www.assemblydems.com/verlina-reynolds-jackson/",
                "https://www.njleg.state.nj.us/legislative-roster/409/assemblywoman-reynolds-jackson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-15-lower-ocd-person-c6d61a12-eeb5-4400-943d-68a81b0728f0": 0,
          "SLDL-NJ-15-lower-ocd-person-b432ec8c-f914-47dc-addd-740dfc95daaf": 1
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/97251dd4-e275-4347-a757-a05366fa5013",
            "firstName": "Mitchelle",
            "lastName": "Drulis",
            "fullName": "Mitchelle Drulis",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-NJ-16-lower-ocd-person-97251dd4-e275-4347-a757-a05366fa5013",
              "builtID": "nj-lower-16",
              "externalID": "ocd-person/97251dd4-e275-4347-a757-a05366fa5013",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswdrulis@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "390 Amwell Road Suite 301, Hillsborough, NJ 08844",
              "phone1": null,
              "phone2": "908-829-4191",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/mitchelle-drulis/",
                "https://www.njleg.state.nj.us/legislative-roster/472/assemblywoman-drulis",
                "https://www.assemblydems.com/178/Mitchelle-Drulis---District-16"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/77b80e3f-bfdd-4c81-b92e-866c17abd398",
            "firstName": "Roy",
            "lastName": "Freiman",
            "fullName": "Roy Freiman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/freiman_roy_2020.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-NJ-16-lower-ocd-person-77b80e3f-bfdd-4c81-b92e-866c17abd398",
              "builtID": "nj-lower-16",
              "externalID": "ocd-person/77b80e3f-bfdd-4c81-b92e-866c17abd398",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmfreiman@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "390 Amwell Road Suite 301, Hillsborough, NJ 08844",
              "phone1": null,
              "phone2": "908-829-4191",
              "fax1": null,
              "fax2": "908-829-4193",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=400",
                "https://www.assemblydems.com/roy-freiman/",
                "https://www.njleg.state.nj.us/legislative-roster/400/assemblyman-freiman",
                "https://www.assemblydems.com/180/Roy-Freiman---District-16"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-16-lower-ocd-person-97251dd4-e275-4347-a757-a05366fa5013": 0,
          "SLDL-NJ-16-lower-ocd-person-77b80e3f-bfdd-4c81-b92e-866c17abd398": 1
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/03acbfd3-a81b-4461-aff4-db7ecf5e8e48",
            "firstName": "Joseph",
            "lastName": "Danielsen",
            "fullName": "Joe Danielsen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/danielsen_joe_2021.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-NJ-17-lower-ocd-person-03acbfd3-a81b-4461-aff4-db7ecf5e8e48",
              "builtID": "nj-lower-17",
              "externalID": "ocd-person/03acbfd3-a81b-4461-aff4-db7ecf5e8e48",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmdanielsen@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "334 Elizabeth Ave., Somerset, NJ 08873",
              "phone1": null,
              "phone2": "732-247-3999",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=370",
                "https://www.assemblydems.com/joseph-danielsen/",
                "https://www.njleg.state.nj.us/legislative-roster/370/assemblyman-danielsen"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/59c25089-5752-4eb9-a96b-a005c1c46743",
            "firstName": "Kevin P.",
            "lastName": "Egan",
            "fullName": "Kevin Egan",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-NJ-17-lower-ocd-person-59c25089-5752-4eb9-a96b-a005c1c46743",
              "builtID": "nj-lower-17",
              "externalID": "ocd-person/59c25089-5752-4eb9-a96b-a005c1c46743",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmegan@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "100 Bayard St., New Brunswick, NJ 08901",
              "phone1": null,
              "phone2": "732-249-4550",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/kevin-egan/",
                "https://www.njleg.state.nj.us/legislative-roster/495/assemblyman-egan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-17-lower-ocd-person-03acbfd3-a81b-4461-aff4-db7ecf5e8e48": 0,
          "SLDL-NJ-17-lower-ocd-person-59c25089-5752-4eb9-a96b-a005c1c46743": 1
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/d6732f77-1c72-4ee6-8d52-5c9b79987976",
            "firstName": "Robert J.",
            "lastName": "Karabinchak",
            "fullName": "Robert Karabinchak",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/karabinchak_color.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-NJ-18-lower-ocd-person-d6732f77-1c72-4ee6-8d52-5c9b79987976",
              "builtID": "nj-lower-18",
              "externalID": "ocd-person/d6732f77-1c72-4ee6-8d52-5c9b79987976",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmkarabinchak@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "3 Stephenville Parkway Suite 2D, Edison, NJ 08820",
              "phone1": null,
              "phone2": "732-548-1406",
              "fax1": null,
              "fax2": "732-548-1623",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=388",
                "https://www.assemblydems.com/robert-karabinchak/",
                "https://www.njleg.state.nj.us/legislative-roster/388/assemblyman-karabinchak"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/901f85af-77c2-4cff-8626-ac510fdc425f",
            "firstName": "Sterley S.",
            "lastName": "Stanley",
            "fullName": "Sterley Stanley",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/stanley_sterley_2021.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-NJ-18-lower-ocd-person-901f85af-77c2-4cff-8626-ac510fdc425f",
              "builtID": "nj-lower-18",
              "externalID": "ocd-person/901f85af-77c2-4cff-8626-ac510fdc425f",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmstanley@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "197 State Highway 18 Suite 102 North, East Brunswick, NJ 08816",
              "phone1": null,
              "phone2": "732-875-3833",
              "fax1": null,
              "fax2": "732-253-5007",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/sterleystanley/",
                "https://www.njleg.state.nj.us/legislative-roster/429/assemblyman-stanley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-18-lower-ocd-person-d6732f77-1c72-4ee6-8d52-5c9b79987976": 0,
          "SLDL-NJ-18-lower-ocd-person-901f85af-77c2-4cff-8626-ac510fdc425f": 1
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/9d1ae08a-66b3-4d68-bafe-956d4eaeb57f",
            "firstName": "Craig J.",
            "lastName": "Coughlin",
            "fullName": "Craig Coughlin",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/coughlin_craig_2017.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-NJ-19-lower-ocd-person-9d1ae08a-66b3-4d68-bafe-956d4eaeb57f",
              "builtID": "nj-lower-19",
              "externalID": "ocd-person/9d1ae08a-66b3-4d68-bafe-956d4eaeb57f",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmcoughlin@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "569 Rahway Ave., Woodbridge, NJ 07095",
              "phone1": null,
              "phone2": "732-855-7441",
              "fax1": null,
              "fax2": "732-855-7558",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=319",
                "https://www.assemblydems.com/craig-coughlin/",
                "https://www.njleg.state.nj.us/legislative-roster/319/assemblyman-coughlin"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a0d9e9ac-6ede-4e36-8c9e-f7bd83cc704a",
            "firstName": "Yvonne M.",
            "lastName": "Lopez",
            "fullName": "Yvonne Lopez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/lopez_yvonne_2017.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-NJ-19-lower-ocd-person-a0d9e9ac-6ede-4e36-8c9e-f7bd83cc704a",
              "builtID": "nj-lower-19",
              "externalID": "ocd-person/a0d9e9ac-6ede-4e36-8c9e-f7bd83cc704a",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswlopez@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "211 Front St., Perth Amboy, NJ 08861",
              "phone1": null,
              "phone2": "732-638-5057",
              "fax1": null,
              "fax2": "848-203-3508",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=401",
                "https://www.assemblydems.com/yvonne-lopez/",
                "https://www.njleg.state.nj.us/legislative-roster/401/assemblywoman-lopez"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-19-lower-ocd-person-9d1ae08a-66b3-4d68-bafe-956d4eaeb57f": 0,
          "SLDL-NJ-19-lower-ocd-person-a0d9e9ac-6ede-4e36-8c9e-f7bd83cc704a": 1
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/ec370db8-c51e-4022-9868-5cad9c38e97d",
            "firstName": "Annette M.",
            "lastName": "Quijano",
            "fullName": "Annette Quijano",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/quijano_annette_2020.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-NJ-20-lower-ocd-person-ec370db8-c51e-4022-9868-5cad9c38e97d",
              "builtID": "nj-lower-20",
              "externalID": "ocd-person/ec370db8-c51e-4022-9868-5cad9c38e97d",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswquijano@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "985 Stuyvesant Ave., Union, NJ 07083",
              "phone1": null,
              "phone2": "908-624-0880",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=309",
                "https://www.assemblydems.com/annette-quijano/",
                "https://www.njleg.state.nj.us/legislative-roster/309/assemblywoman-quijano"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1093b785-2805-428f-8e50-cf38620ec5d6",
            "firstName": "Reginald W.",
            "lastName": "Atkins",
            "fullName": "Reginald Atkins",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-NJ-20-lower-ocd-person-1093b785-2805-428f-8e50-cf38620ec5d6",
              "builtID": "nj-lower-20",
              "externalID": "ocd-person/1093b785-2805-428f-8e50-cf38620ec5d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmatkins@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "129 E. 1st Ave., Roselle, NJ 07203",
              "phone1": null,
              "phone2": "908-445-7154",
              "fax1": null,
              "fax2": "908-445-7157",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/reginald-atkins/",
                "https://www.njleg.state.nj.us/legislative-roster/448/assemblyman-atkins"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-20-lower-ocd-person-ec370db8-c51e-4022-9868-5cad9c38e97d": 0,
          "SLDL-NJ-20-lower-ocd-person-1093b785-2805-428f-8e50-cf38620ec5d6": 1
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/c3f2aa37-3c3c-4786-8ece-54acab035f35",
            "firstName": "Michele",
            "lastName": "Matsikoudis",
            "fullName": "Michele Matsikoudis",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-NJ-21-lower-ocd-person-c3f2aa37-3c3c-4786-8ece-54acab035f35",
              "builtID": "nj-lower-21",
              "externalID": "ocd-person/c3f2aa37-3c3c-4786-8ece-54acab035f35",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswmatsikoudis@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1967 Highway 34, Building C Suite 202, Wall Township, NJ 07719",
              "phone1": null,
              "phone2": "908-679-8889",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njassemblygop.com/matsikoudis/",
                "https://www.njleg.state.nj.us/legislative-roster/449/assemblywoman-matsikoudis"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f553c0d7-7c53-4197-9a83-b0a1d2b56dd3",
            "firstName": "Nancy Ann Foster",
            "lastName": "Munoz",
            "fullName": "Nancy Muñoz",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/munoz_nancy_2020.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-NJ-21-lower-ocd-person-f553c0d7-7c53-4197-9a83-b0a1d2b56dd3",
              "builtID": "nj-lower-21",
              "externalID": "ocd-person/f553c0d7-7c53-4197-9a83-b0a1d2b56dd3",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswmunoz@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "57 Union Place Suite 310, Summit, NJ 07901",
              "phone1": null,
              "phone2": "908-918-0414",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=314",
                "https://www.njassemblygop.com/munoz/",
                "https://www.njleg.state.nj.us/legislative-roster/314/assemblywoman-munoz"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-21-lower-ocd-person-c3f2aa37-3c3c-4786-8ece-54acab035f35": 0,
          "SLDL-NJ-21-lower-ocd-person-f553c0d7-7c53-4197-9a83-b0a1d2b56dd3": 1
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/d80f0df2-57cd-436b-a250-7413ec11fe27",
            "firstName": "James J.",
            "lastName": "Kennedy",
            "fullName": "Jim Kennedy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/kennedy_color.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-NJ-22-lower-ocd-person-d80f0df2-57cd-436b-a250-7413ec11fe27",
              "builtID": "nj-lower-22",
              "externalID": "ocd-person/d80f0df2-57cd-436b-a250-7413ec11fe27",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmkennedy@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1445 Main St., Rahway, NJ 07065",
              "phone1": null,
              "phone2": "732-943-2660",
              "fax1": null,
              "fax2": "732-827-5916",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=381",
                "https://www.assemblydems.com/james-kennedy/",
                "https://www.njleg.state.nj.us/legislative-roster/381/assemblyman-kennedy"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/199dd5c8-3ef1-4a2c-9585-9de5def27e98",
            "firstName": "Linda S.",
            "lastName": "Carter",
            "fullName": "Linda Carter",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/carter_linda_2018a.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-NJ-22-lower-ocd-person-199dd5c8-3ef1-4a2c-9585-9de5def27e98",
              "builtID": "nj-lower-22",
              "externalID": "ocd-person/199dd5c8-3ef1-4a2c-9585-9de5def27e98",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswcarter@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "200 W. 2nd St. Suite 102, Plainfield, NJ 07060",
              "phone1": null,
              "phone2": "908-561-5757",
              "fax1": null,
              "fax2": "908-561-5547",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=414",
                "https://www.assemblydems.com/linda-carter/",
                "https://www.njleg.state.nj.us/legislative-roster/414/assemblywoman-carter"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-22-lower-ocd-person-d80f0df2-57cd-436b-a250-7413ec11fe27": 0,
          "SLDL-NJ-22-lower-ocd-person-199dd5c8-3ef1-4a2c-9585-9de5def27e98": 1
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/df6d2d1f-3fb0-4e93-93cf-26665cae9a23",
            "firstName": "Erik",
            "lastName": "Peterson",
            "fullName": "Erik Peterson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/peterson_erik_2020.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-NJ-23-lower-ocd-person-df6d2d1f-3fb0-4e93-93cf-26665cae9a23",
              "builtID": "nj-lower-23",
              "externalID": "ocd-person/df6d2d1f-3fb0-4e93-93cf-26665cae9a23",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmpeterson@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "28 Center St., Clinton, NJ 08809",
              "phone1": null,
              "phone2": "908-238-0251",
              "fax1": null,
              "fax2": "908-238-0256",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=320",
                "https://www.njassemblygop.com/peterson/",
                "https://www.njleg.state.nj.us/legislative-roster/320/assemblyman-peterson"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/dce6ec3a-7d62-4b33-8693-259364c85285",
            "firstName": "John",
            "lastName": "DiMaio",
            "fullName": "John DiMaio",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/dimaio_john_2020.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-NJ-23-lower-ocd-person-dce6ec3a-7d62-4b33-8693-259364c85285",
              "builtID": "nj-lower-23",
              "externalID": "ocd-person/dce6ec3a-7d62-4b33-8693-259364c85285",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmdimaio@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "208 Mountain Ave. Suite 3, Hackettstown, NJ 07840",
              "phone1": null,
              "phone2": "908-684-9550",
              "fax1": null,
              "fax2": "908-722-1542",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=313",
                "https://www.njassemblygop.com/dimaio/",
                "https://www.njleg.state.nj.us/legislative-roster/313/assemblyman-dimaio"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-23-lower-ocd-person-df6d2d1f-3fb0-4e93-93cf-26665cae9a23": 0,
          "SLDL-NJ-23-lower-ocd-person-dce6ec3a-7d62-4b33-8693-259364c85285": 1
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/790ac0bd-71fa-4446-b1fa-b013e4b4f33e",
            "firstName": "Dawn",
            "lastName": "Fantasia",
            "fullName": "Dawn Fantasia",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-NJ-24-lower-ocd-person-790ac0bd-71fa-4446-b1fa-b013e4b4f33e",
              "builtID": "nj-lower-24",
              "externalID": "ocd-person/790ac0bd-71fa-4446-b1fa-b013e4b4f33e",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswfantasia@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1 Wilson Drive Suite 2B, Sparta, NJ 07871",
              "phone1": null,
              "phone2": "973-300-0200",
              "fax1": null,
              "fax2": "973-300-1744",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njassemblygop.com/fantasia/",
                "https://www.njleg.state.nj.us/legislative-roster/474/assemblywoman-fantasia"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/9228b053-be5e-4490-aadc-ece59b7421fd",
            "firstName": "Michael J.",
            "lastName": "Inganamort",
            "fullName": "Mike Inganamort",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-NJ-24-lower-ocd-person-9228b053-be5e-4490-aadc-ece59b7421fd",
              "builtID": "nj-lower-24",
              "externalID": "ocd-person/9228b053-be5e-4490-aadc-ece59b7421fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "asminganamort@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1 Wilson Drive Suite 2B, Sparta, NJ 07871",
              "phone1": null,
              "phone2": "973-300-0200",
              "fax1": null,
              "fax2": "973-300-1744",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njassemblygop.com/asm-inganamort/",
                "https://www.njleg.state.nj.us/legislative-roster/475/assemblyman-inganamort"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-24-lower-ocd-person-790ac0bd-71fa-4446-b1fa-b013e4b4f33e": 0,
          "SLDL-NJ-24-lower-ocd-person-9228b053-be5e-4490-aadc-ece59b7421fd": 1
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/03aa8bec-9d7e-41e7-ad20-b5ba1991cab6",
            "firstName": "Aura Kenny",
            "lastName": "Dunn",
            "fullName": "Aura Dunn",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/dunn_aura_2020.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-NJ-25-lower-ocd-person-03aa8bec-9d7e-41e7-ad20-b5ba1991cab6",
              "builtID": "nj-lower-25",
              "externalID": "ocd-person/03aa8bec-9d7e-41e7-ad20-b5ba1991cab6",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswdunn@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "191 Main St. Suite D, Chester, NJ 07930",
              "phone1": null,
              "phone2": "908-955-7228",
              "fax1": null,
              "fax2": "908-955-7044",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njassemblygop.com/dunn/",
                "https://www.njleg.state.nj.us/legislative-roster/428/assemblywoman-dunn"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/09c6e805-6356-4bcb-a7d3-6be479d41e9a",
            "firstName": "Christian E.",
            "lastName": "Barranco",
            "fullName": "Chris Barranco",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-NJ-25-lower-ocd-person-09c6e805-6356-4bcb-a7d3-6be479d41e9a",
              "builtID": "nj-lower-25",
              "externalID": "ocd-person/09c6e805-6356-4bcb-a7d3-6be479d41e9a",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmbarranco@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "762 Route 15 South Suite 1A, Lake Hopatcong, NJ 07849",
              "phone1": null,
              "phone2": "973-810-2695",
              "fax1": null,
              "fax2": "973-810-2698",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njassemblygop.com/barranco/",
                "https://www.njleg.state.nj.us/legislative-roster/450/assemblyman-barranco"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-25-lower-ocd-person-03aa8bec-9d7e-41e7-ad20-b5ba1991cab6": 0,
          "SLDL-NJ-25-lower-ocd-person-09c6e805-6356-4bcb-a7d3-6be479d41e9a": 1
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/006c1bd9-54e9-49be-9d02-12dda1119149",
            "firstName": "Brian",
            "lastName": "Bergen",
            "fullName": "Brian Bergen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/bergen_brian_2020.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-NJ-26-lower-ocd-person-006c1bd9-54e9-49be-9d02-12dda1119149",
              "builtID": "nj-lower-26",
              "externalID": "ocd-person/006c1bd9-54e9-49be-9d02-12dda1119149",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmbergen@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "5 E. Main St. Suite 22B, Denville, NJ 07834",
              "phone1": null,
              "phone2": "973-539-8113",
              "fax1": null,
              "fax2": "973-539-8752",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=423",
                "https://www.njassemblygop.com/bergen/",
                "https://www.njleg.state.nj.us/legislative-roster/423/assemblyman-bergen"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/dbe67894-e4cd-46ea-bb59-877a8b4f26d0",
            "firstName": "James K.",
            "lastName": "Webber",
            "fullName": "Jay Webber",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/webber_jay_2019.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-NJ-26-lower-ocd-person-dbe67894-e4cd-46ea-bb59-877a8b4f26d0",
              "builtID": "nj-lower-26",
              "externalID": "ocd-person/dbe67894-e4cd-46ea-bb59-877a8b4f26d0",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmwebber@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "160 Littleton Road Suite 204, Parsippany, NJ 07054",
              "phone1": null,
              "phone2": "973-917-3420",
              "fax1": null,
              "fax2": "973-917-3423",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=283",
                "https://www.njassemblygop.com/webber/",
                "https://www.njleg.state.nj.us/legislative-roster/283/assemblyman-webber"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-26-lower-ocd-person-006c1bd9-54e9-49be-9d02-12dda1119149": 0,
          "SLDL-NJ-26-lower-ocd-person-dbe67894-e4cd-46ea-bb59-877a8b4f26d0": 1
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/b5f9d8c9-0f98-4d89-8a5f-82152f41687d",
            "firstName": "Alixon A.",
            "lastName": "Collazos-Gill",
            "fullName": "Alixon Collazos-Gill",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-NJ-27-lower-ocd-person-b5f9d8c9-0f98-4d89-8a5f-82152f41687d",
              "builtID": "nj-lower-27",
              "externalID": "ocd-person/b5f9d8c9-0f98-4d89-8a5f-82152f41687d",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswcollazosgill@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "39 S. Fullerton Ave. Second Floor, Suite 7, Montclair, NJ 07042",
              "phone1": null,
              "phone2": "973-509-0388",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/alixon-collazos-gill/",
                "https://www.njleg.state.nj.us/legislative-roster/480/assemblywoman-collazos-gill"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f152f2d6-e4ff-4958-996d-532c310ddef1",
            "firstName": "Rosaura",
            "lastName": "Bagolie",
            "fullName": "Rosy Bagolie",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-NJ-27-lower-ocd-person-f152f2d6-e4ff-4958-996d-532c310ddef1",
              "builtID": "nj-lower-27",
              "externalID": "ocd-person/f152f2d6-e4ff-4958-996d-532c310ddef1",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswbagolie@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "651 Old Mount Pleasant Ave., Livingston, NJ 07039",
              "phone1": null,
              "phone2": "973-535-5017",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/rosy-bagolie/",
                "https://www.njleg.state.nj.us/legislative-roster/479/assemblywoman-bagolie",
                "https://www.assemblydems.com/203/Rosaura-Rosy-Bagolie---District-27"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-27-lower-ocd-person-b5f9d8c9-0f98-4d89-8a5f-82152f41687d": 0,
          "SLDL-NJ-27-lower-ocd-person-f152f2d6-e4ff-4958-996d-532c310ddef1": 1
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/c5469bd6-29bd-4054-9ac0-3577d17422d4",
            "firstName": "Cleopatra Gibson",
            "lastName": "Tucker",
            "fullName": "Cleopatra Tucker",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/tucker_cleopatra_2017.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-NJ-28-lower-ocd-person-c5469bd6-29bd-4054-9ac0-3577d17422d4",
              "builtID": "nj-lower-28",
              "externalID": "ocd-person/c5469bd6-29bd-4054-9ac0-3577d17422d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswtucker@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1079 Bergen St., Newark, NJ 07112",
              "phone1": null,
              "phone2": "973-926-4320",
              "fax1": null,
              "fax2": "973-926-5736",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=285",
                "https://www.assemblydems.com/cleopatra-tucker/",
                "https://www.njleg.state.nj.us/legislative-roster/285/assemblywoman-tucker"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/01f02f19-297f-45a0-a127-7ddf3faa3ab1",
            "firstName": "Garnet R.",
            "lastName": "Hall",
            "fullName": "Garnet Hall",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-NJ-28-lower-ocd-person-01f02f19-297f-45a0-a127-7ddf3faa3ab1",
              "builtID": "nj-lower-28",
              "externalID": "ocd-person/01f02f19-297f-45a0-a127-7ddf3faa3ab1",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswhall@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "511 Valley St., Maplewood, NJ 07040",
              "phone1": null,
              "phone2": "973-762-1886",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/garnet-hall/",
                "https://www.njleg.state.nj.us/legislative-roster/481/assemblywoman-hall"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-28-lower-ocd-person-c5469bd6-29bd-4054-9ac0-3577d17422d4": 0,
          "SLDL-NJ-28-lower-ocd-person-01f02f19-297f-45a0-a127-7ddf3faa3ab1": 1
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/6056438b-65d3-4db4-b0ad-c367074bd47e",
            "firstName": "Eliana",
            "lastName": "Pintor Marin",
            "fullName": "Eliana Pintor Marin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/pintormarin_eliana_2017.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-NJ-29-lower-ocd-person-6056438b-65d3-4db4-b0ad-c367074bd47e",
              "builtID": "nj-lower-29",
              "externalID": "ocd-person/6056438b-65d3-4db4-b0ad-c367074bd47e",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswpintormarin@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "263 Lafayette St. First Floor, Newark, NJ 07105",
              "phone1": null,
              "phone2": "973-589-0713",
              "fax1": null,
              "fax2": "973-589-0716",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=362",
                "https://www.assemblydems.com/eliana-pintor-marin/",
                "https://www.njleg.state.nj.us/legislative-roster/362/assemblywoman-pintor-marin"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/304c7552-b833-4b9c-b125-39030b8376ff",
            "firstName": "Shanique Davis",
            "lastName": "Speight",
            "fullName": "Shanique Speight",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/speight_shanique_2017.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-NJ-29-lower-ocd-person-304c7552-b833-4b9c-b125-39030b8376ff",
              "builtID": "nj-lower-29",
              "externalID": "ocd-person/304c7552-b833-4b9c-b125-39030b8376ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswspeight@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=403",
                "https://www.assemblydems.com/shanique-speight/",
                "https://www.njleg.state.nj.us/legislative-roster/403/assemblywoman-speight",
                "https://www.assemblydems.com/200/Shanique-Speight---District-29"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-29-lower-ocd-person-6056438b-65d3-4db4-b0ad-c367074bd47e": 0,
          "SLDL-NJ-29-lower-ocd-person-304c7552-b833-4b9c-b125-39030b8376ff": 1
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/4dab8e3a-93be-4ad7-8cb1-5009e4c7d232",
            "firstName": "Alexander",
            "lastName": "Schnall",
            "fullName": "Avi Schnall",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-NJ-30-lower-ocd-person-4dab8e3a-93be-4ad7-8cb1-5009e4c7d232",
              "builtID": "nj-lower-30",
              "externalID": "ocd-person/4dab8e3a-93be-4ad7-8cb1-5009e4c7d232",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmschnall@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "500 River Ave. Suite 220, Lakewood, NJ 08701",
              "phone1": null,
              "phone2": "848-245-9486",
              "fax1": null,
              "fax2": "848-245-9489",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/avi-schnall/",
                "https://www.njleg.state.nj.us/legislative-roster/482/assemblyman-schnall"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ac95c4e3-057b-43bc-8bee-752137bb2d12",
            "firstName": "Sean Thomas",
            "lastName": "Kean",
            "fullName": "Sean Kean",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/kean_sean_2020.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-NJ-30-lower-ocd-person-ac95c4e3-057b-43bc-8bee-752137bb2d12",
              "builtID": "nj-lower-30",
              "externalID": "ocd-person/ac95c4e3-057b-43bc-8bee-752137bb2d12",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmskean@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1967 Highway 34, Building C Suite 202, Wall Township, NJ 07719",
              "phone1": null,
              "phone2": "732-974-0400",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=333",
                "https://www.njassemblygop.com/kean/",
                "https://www.njleg.state.nj.us/legislative-roster/333/assemblyman-kean"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-30-lower-ocd-person-4dab8e3a-93be-4ad7-8cb1-5009e4c7d232": 0,
          "SLDL-NJ-30-lower-ocd-person-ac95c4e3-057b-43bc-8bee-752137bb2d12": 1
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/cc432a41-2aa4-4763-89d5-cd823c485d4e",
            "firstName": "Barbara",
            "lastName": "McCann Stamato",
            "fullName": "Barbara McCann Stamato",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-NJ-31-lower-ocd-person-cc432a41-2aa4-4763-89d5-cd823c485d4e",
              "builtID": "nj-lower-31",
              "externalID": "ocd-person/cc432a41-2aa4-4763-89d5-cd823c485d4e",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswmccannstamato@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1738 Kennedy Blvd., Jersey City, NJ 07305",
              "phone1": null,
              "phone2": "201-451-5100",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/barbara-mccann-stamato/",
                "https://www.njleg.state.nj.us/legislative-roster/484/assemblywoman-mccann-stamato"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d624fdfb-366e-41f1-83a7-2a8421350d3c",
            "firstName": "William B.",
            "lastName": "Sampson",
            "fullName": "Will Sampson",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-NJ-31-lower-ocd-person-d624fdfb-366e-41f1-83a7-2a8421350d3c",
              "builtID": "nj-lower-31",
              "externalID": "ocd-person/d624fdfb-366e-41f1-83a7-2a8421350d3c",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmsampson@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "25 W. 8th St., Bayonne, NJ 07002",
              "phone1": null,
              "phone2": "201-455-2966",
              "fax1": null,
              "fax2": "201-455-2969",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/william-sampson/",
                "https://www.njleg.state.nj.us/legislative-roster/451/assemblyman-sampson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-31-lower-ocd-person-cc432a41-2aa4-4763-89d5-cd823c485d4e": 0,
          "SLDL-NJ-31-lower-ocd-person-d624fdfb-366e-41f1-83a7-2a8421350d3c": 1
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/865cd920-2556-4906-ae1a-01ddf9067ef7",
            "firstName": "Jessica",
            "lastName": "Ramirez",
            "fullName": "Jessica Ramirez",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-NJ-32-lower-ocd-person-865cd920-2556-4906-ae1a-01ddf9067ef7",
              "builtID": "nj-lower-32",
              "externalID": "ocd-person/865cd920-2556-4906-ae1a-01ddf9067ef7",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswramirez@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "433 Palisade Ave., Jersey City, NJ 07307",
              "phone1": null,
              "phone2": "201-626-4000",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/Jessica-Ramirez/",
                "https://www.njleg.state.nj.us/legislative-roster/487/assemblywoman-ramirez",
                "https://www.assemblydems.com/220/Jessica-Ramirez---District-32"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b53a329c-5936-465c-8056-a42d0d2cc63f",
            "firstName": "John P.",
            "lastName": "Allen",
            "fullName": "John Allen",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-NJ-32-lower-ocd-person-b53a329c-5936-465c-8056-a42d0d2cc63f",
              "builtID": "nj-lower-32",
              "externalID": "ocd-person/b53a329c-5936-465c-8056-a42d0d2cc63f",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmallen@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "80 River St., Second Floor, Hoboken, NJ 07030",
              "phone1": null,
              "phone2": "201-683-7917",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/john-allen/",
                "https://www.njleg.state.nj.us/legislative-roster/486/assemblyman-allen"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-32-lower-ocd-person-865cd920-2556-4906-ae1a-01ddf9067ef7": 0,
          "SLDL-NJ-32-lower-ocd-person-b53a329c-5936-465c-8056-a42d0d2cc63f": 1
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/2078f4b2-3e69-4e01-aada-3ccf21ba6173",
            "firstName": "Gabriel",
            "lastName": "Rodriguez",
            "fullName": "Gabe Rodriguez",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-NJ-33-lower-ocd-person-2078f4b2-3e69-4e01-aada-3ccf21ba6173",
              "builtID": "nj-lower-33",
              "externalID": "ocd-person/2078f4b2-3e69-4e01-aada-3ccf21ba6173",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmrodriguez@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "5600 Kennedy Blvd. Suite 104, West New York, NJ 07093",
              "phone1": null,
              "phone2": "201-223-4247",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/gabriel-rodriguez/",
                "https://www.njleg.state.nj.us/legislative-roster/489/assemblyman-rodriguez"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4454126e-9060-42fc-a81b-91c985d242c1",
            "firstName": "Julio",
            "lastName": "Marenco",
            "fullName": "Julio Marenco",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-NJ-33-lower-ocd-person-4454126e-9060-42fc-a81b-91c985d242c1",
              "builtID": "nj-lower-33",
              "externalID": "ocd-person/4454126e-9060-42fc-a81b-91c985d242c1",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@votejulio.com",
              "contactForm": null,
              "address1": null,
              "address2": "9060 Palisade Ave., North Bergen, NJ 07047",
              "phone1": null,
              "phone2": "201-295-0200",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/julio-marenco/",
                "https://www.njleg.state.nj.us/legislative-roster/488/assemblyman-marenco"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-33-lower-ocd-person-2078f4b2-3e69-4e01-aada-3ccf21ba6173": 0,
          "SLDL-NJ-33-lower-ocd-person-4454126e-9060-42fc-a81b-91c985d242c1": 1
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/38f2061a-a83f-45bc-92a2-817f44d6b377",
            "firstName": "Carmen Theresa",
            "lastName": "Morales",
            "fullName": "Carmen Morales",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-NJ-34-lower-ocd-person-38f2061a-a83f-45bc-92a2-817f44d6b377",
              "builtID": "nj-lower-34",
              "externalID": "ocd-person/38f2061a-a83f-45bc-92a2-817f44d6b377",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswmorales@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "148-152 Franklin St., Belleville, NJ 07109",
              "phone1": null,
              "phone2": "973-450-0484",
              "fax1": null,
              "fax2": "973-450-0487",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/carmen-morales/",
                "https://www.njleg.state.nj.us/legislative-roster/491/assemblywoman-morales"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/96eb992c-0f0c-4db5-b146-76349604f4d3",
            "firstName": "Michael J.",
            "lastName": "Venezia",
            "fullName": "Mike Venezia",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-NJ-34-lower-ocd-person-96eb992c-0f0c-4db5-b146-76349604f4d3",
              "builtID": "nj-lower-34",
              "externalID": "ocd-person/96eb992c-0f0c-4db5-b146-76349604f4d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmvenezia@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "148-152 Franklin St., Belleville, NJ 07109",
              "phone1": null,
              "phone2": "973-450-0484",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/legislative-roster/492/assemblyman-venezia"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-34-lower-ocd-person-38f2061a-a83f-45bc-92a2-817f44d6b377": 0,
          "SLDL-NJ-34-lower-ocd-person-96eb992c-0f0c-4db5-b146-76349604f4d3": 1
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/18fa38fc-954e-43d9-a294-a2068828216e",
            "firstName": "Benjie E.",
            "lastName": "Wimberly",
            "fullName": "Benjie Wimberly",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/wimberly_color.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-NJ-35-lower-ocd-person-18fa38fc-954e-43d9-a294-a2068828216e",
              "builtID": "nj-lower-35",
              "externalID": "ocd-person/18fa38fc-954e-43d9-a294-a2068828216e",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmwimberly@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "301 Main St. Suite 360, Paterson, NJ 07505",
              "phone1": null,
              "phone2": "973-925-7061",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=347",
                "https://www.assemblydems.com/benjie-wimberly/",
                "https://www.njleg.state.nj.us/legislative-roster/347/assemblyman-wimberly"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/6cab3e19-7a7c-4488-877f-a6a167306ba5",
            "firstName": "Shavonda E.",
            "lastName": "Sumter",
            "fullName": "Shavonda Sumter",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/sumter_color.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-NJ-35-lower-ocd-person-6cab3e19-7a7c-4488-877f-a6a167306ba5",
              "builtID": "nj-lower-35",
              "externalID": "ocd-person/6cab3e19-7a7c-4488-877f-a6a167306ba5",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswsumter@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "21 Mill St. Suite 5, Paterson, NJ 07501",
              "phone1": null,
              "phone2": "973-925-7063",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=346",
                "https://www.assemblydems.com/shavonda-sumter/",
                "https://www.njleg.state.nj.us/legislative-roster/346/assemblywoman-sumter"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-35-lower-ocd-person-18fa38fc-954e-43d9-a294-a2068828216e": 0,
          "SLDL-NJ-35-lower-ocd-person-6cab3e19-7a7c-4488-877f-a6a167306ba5": 1
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/5475b510-25ec-4354-abb5-f1f03c3ea2c3",
            "firstName": "Clinton",
            "lastName": "Calabrese",
            "fullName": "Clinton Calabrese",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/calabrese_clinton_2018.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-NJ-36-lower-ocd-person-5475b510-25ec-4354-abb5-f1f03c3ea2c3",
              "builtID": "nj-lower-36",
              "externalID": "ocd-person/5475b510-25ec-4354-abb5-f1f03c3ea2c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmcalabrese@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "613 Bergen Blvd., Ridgefield, NJ 07657",
              "phone1": null,
              "phone2": "201-943-0615",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=408",
                "https://www.assemblydems.com/clinton-calabrese/",
                "https://www.njleg.state.nj.us/legislative-roster/408/assemblyman-calabrese"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/93a1a3ce-eb52-4e71-b01c-346f41b6bde4",
            "firstName": "Gary Steven",
            "lastName": "Schaer",
            "fullName": "Gary Schaer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/schaer_color.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-NJ-36-lower-ocd-person-93a1a3ce-eb52-4e71-b01c-346f41b6bde4",
              "builtID": "nj-lower-36",
              "externalID": "ocd-person/93a1a3ce-eb52-4e71-b01c-346f41b6bde4",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmschaer@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1 Howe Ave. Suite 401, Passaic, NJ 07055",
              "phone1": null,
              "phone2": "973-249-3665",
              "fax1": null,
              "fax2": "973-249-6281",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=258",
                "https://www.assemblydems.com/gary-schaer/",
                "https://www.njleg.state.nj.us/legislative-roster/258/assemblyman-schaer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-36-lower-ocd-person-5475b510-25ec-4354-abb5-f1f03c3ea2c3": 0,
          "SLDL-NJ-36-lower-ocd-person-93a1a3ce-eb52-4e71-b01c-346f41b6bde4": 1
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/e5da04d8-e25f-4ab3-a453-84e692fe89c4",
            "firstName": "Ellen J.",
            "lastName": "Park",
            "fullName": "Ellen Park",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-NJ-37-lower-ocd-person-e5da04d8-e25f-4ab3-a453-84e692fe89c4",
              "builtID": "nj-lower-37",
              "externalID": "ocd-person/e5da04d8-e25f-4ab3-a453-84e692fe89c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswpark@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "96 Engle St., Englewood, NJ 07631",
              "phone1": null,
              "phone2": "201-928-0100",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/ellen-j-park/",
                "https://www.njleg.state.nj.us/legislative-roster/453/assemblywoman-park"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/49be7512-9343-4189-8769-4497ed129a92",
            "firstName": "Shama",
            "lastName": "Haider",
            "fullName": "Shama Haider",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-NJ-37-lower-ocd-person-49be7512-9343-4189-8769-4497ed129a92",
              "builtID": "nj-lower-37",
              "externalID": "ocd-person/49be7512-9343-4189-8769-4497ed129a92",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswhaider@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "96 Engle St., Englewood, NJ 07631",
              "phone1": null,
              "phone2": "201-308-7061",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.assemblydems.com/shama-haider/",
                "https://www.njleg.state.nj.us/legislative-roster/452/assemblywoman-haider"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-37-lower-ocd-person-e5da04d8-e25f-4ab3-a453-84e692fe89c4": 0,
          "SLDL-NJ-37-lower-ocd-person-49be7512-9343-4189-8769-4497ed129a92": 1
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/28b4c7d2-d1c3-4735-b321-0f348ec27299",
            "firstName": "Lisa",
            "lastName": "Swain",
            "fullName": "Lisa Swain",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/swain_lisa_2018a.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-NJ-38-lower-ocd-person-28b4c7d2-d1c3-4735-b321-0f348ec27299",
              "builtID": "nj-lower-38",
              "externalID": "ocd-person/28b4c7d2-d1c3-4735-b321-0f348ec27299",
              "geometry": null
            },
            "contactInfo": {
              "email": "aswswain@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "205 Robin Road Suite 122, Paramus, NJ 07652",
              "phone1": null,
              "phone2": "201-576-9199",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=413",
                "https://www.assemblydems.com/lisa-swain/",
                "https://www.njleg.state.nj.us/legislative-roster/413/assemblywoman-swain"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/934c3022-709b-4768-b2df-635d25579128",
            "firstName": "Peter Christopher",
            "lastName": "Tully",
            "fullName": "Chris Tully",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/tully_christopher_2018b.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-NJ-38-lower-ocd-person-934c3022-709b-4768-b2df-635d25579128",
              "builtID": "nj-lower-38",
              "externalID": "ocd-person/934c3022-709b-4768-b2df-635d25579128",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmtully@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "205 Robin Road Suite 122, Paramus, NJ 07652",
              "phone1": null,
              "phone2": "201-576-9199",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=412",
                "https://www.assemblydems.com/p-christopher-tully/",
                "https://www.njleg.state.nj.us/legislative-roster/412/assemblyman-tully"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-38-lower-ocd-person-28b4c7d2-d1c3-4735-b321-0f348ec27299": 0,
          "SLDL-NJ-38-lower-ocd-person-934c3022-709b-4768-b2df-635d25579128": 1
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/86641d6d-4538-453d-974f-aec40c42a33d",
            "firstName": "John V.",
            "lastName": "Azzariti",
            "fullName": "John Azzariti",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-NJ-39-lower-ocd-person-86641d6d-4538-453d-974f-aec40c42a33d",
              "builtID": "nj-lower-39",
              "externalID": "ocd-person/86641d6d-4538-453d-974f-aec40c42a33d",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmazzariti@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "287 Kinderkamack Road, Westwood, NJ 07675",
              "phone1": null,
              "phone2": "201-666-0881",
              "fax1": null,
              "fax2": "201-666-5255",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njassemblygop.com/asm-azzariti/",
                "https://www.njleg.state.nj.us/legislative-roster/493/assemblyman-azzariti"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/acff039b-0a83-4184-8b6b-7b051cfcdc77",
            "firstName": "Robert Joseph",
            "lastName": "Auth",
            "fullName": "Bob Auth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/auth_robert_2017.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-NJ-39-lower-ocd-person-acff039b-0a83-4184-8b6b-7b051cfcdc77",
              "builtID": "nj-lower-39",
              "externalID": "ocd-person/acff039b-0a83-4184-8b6b-7b051cfcdc77",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmauth@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1069 Ringwood Ave. Suite 312, Haskell, NJ 07420",
              "phone1": null,
              "phone2": "862-248-0491",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=366",
                "https://www.njassemblygop.com/auth/",
                "https://www.njleg.state.nj.us/legislative-roster/366/assemblyman-auth"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-39-lower-ocd-person-86641d6d-4538-453d-974f-aec40c42a33d": 0,
          "SLDL-NJ-39-lower-ocd-person-acff039b-0a83-4184-8b6b-7b051cfcdc77": 1
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/525b3307-b007-4cfa-8166-efb0810fcda7",
            "firstName": "Al",
            "lastName": "Barlas",
            "fullName": "Al Barlas",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-NJ-40-lower-ocd-person-525b3307-b007-4cfa-8166-efb0810fcda7",
              "builtID": "nj-lower-40",
              "externalID": "ocd-person/525b3307-b007-4cfa-8166-efb0810fcda7",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmbarlas@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1211 Hamburg Turnpike Suite 301, Wayne, NJ 07470",
              "phone1": null,
              "phone2": "973-237-1362",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njassemblygop.com/asm-barlas/",
                "https://www.njleg.state.nj.us/legislative-roster/494/assemblyman-barlas"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/33050562-f29d-4c4f-9e21-022822792a5c",
            "firstName": "Christopher P.",
            "lastName": "DePhillips",
            "fullName": "Chris DePhillips",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/dephillips_christopher_2020.jpg",
            "title": "NJ Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-NJ-40-lower-ocd-person-33050562-f29d-4c4f-9e21-022822792a5c",
              "builtID": "nj-lower-40",
              "externalID": "ocd-person/33050562-f29d-4c4f-9e21-022822792a5c",
              "geometry": null
            },
            "contactInfo": {
              "email": "asmdephillips@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=404",
                "https://www.njassemblyrepublicans.com/dephillips/",
                "https://www.njleg.state.nj.us/legislative-roster/404/assemblyman-dephillips"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NJ-40-lower-ocd-person-525b3307-b007-4cfa-8166-efb0810fcda7": 0,
          "SLDL-NJ-40-lower-ocd-person-33050562-f29d-4c4f-9e21-022822792a5c": 1
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/4cb8db73-8982-4b4f-9c90-f86b20b217bc",
            "firstName": "Michael L.",
            "lastName": "Testa",
            "fullName": "Mike Testa",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/testa_michael_2019.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-NJ-1-upper-ocd-person-4cb8db73-8982-4b4f-9c90-f86b20b217bc",
              "builtID": "nj-upper-1",
              "externalID": "ocd-person/4cb8db73-8982-4b4f-9c90-f86b20b217bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "sentesta@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "School House Office Park 211 S. Main St., Suite 104, Cape May Court House, NJ 08210",
              "phone1": null,
              "phone2": "609-778-2012",
              "fax1": null,
              "fax2": "609-778-2033",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=424",
                "https://www.njleg.state.nj.us/legislative-roster/424/senator-testa",
                "https://testa.senatenj.com/testa.php"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-1-upper-ocd-person-4cb8db73-8982-4b4f-9c90-f86b20b217bc": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/5a6bf64a-7731-4e80-8208-e1a18f09762b",
            "firstName": "Vincent Joseph",
            "lastName": "Polistina",
            "fullName": "Vince Polistina",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/polistina_vincent_2021.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-NJ-2-upper-ocd-person-5a6bf64a-7731-4e80-8208-e1a18f09762b",
              "builtID": "nj-upper-2",
              "externalID": "ocd-person/5a6bf64a-7731-4e80-8208-e1a18f09762b",
              "geometry": null
            },
            "contactInfo": {
              "email": "senpolistina@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "3123 Atlantic Ave. Suite 100, Atlantic City, NJ 08401",
              "phone1": null,
              "phone2": "609-677-8266",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://polistina.senatenj.com/",
                "https://www.njleg.state.nj.us/legislative-roster/432/senator-polistina",
                "https://www.senatenj.com/166/Vince-Polistina---District-2"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-2-upper-ocd-person-5a6bf64a-7731-4e80-8208-e1a18f09762b": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/1b726d76-851a-487b-a6e6-42ee4fa5c050",
            "firstName": "John J.",
            "lastName": "Burzichelli",
            "fullName": "John Burzichelli",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/burzichelli_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-NJ-3-upper-ocd-person-1b726d76-851a-487b-a6e6-42ee4fa5c050",
              "builtID": "nj-upper-3",
              "externalID": "ocd-person/1b726d76-851a-487b-a6e6-42ee4fa5c050",
              "geometry": null
            },
            "contactInfo": {
              "email": "senburzichelli@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "711 N. Main St., Glassboro, NJ 08028",
              "phone1": null,
              "phone2": "856-226-3530",
              "fax1": null,
              "fax2": "856-243-2830",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=200",
                "https://www.njleg.state.nj.us/legislative-roster/458/senator-burzichelli"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-3-upper-ocd-person-1b726d76-851a-487b-a6e6-42ee4fa5c050": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/f15f8b7e-96ee-41e8-b59c-b277927985c0",
            "firstName": "Paul D.",
            "lastName": "Moriarty",
            "fullName": "Paul Moriarty",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/moriarty_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-NJ-4-upper-ocd-person-f15f8b7e-96ee-41e8-b59c-b277927985c0",
              "builtID": "nj-upper-4",
              "externalID": "ocd-person/f15f8b7e-96ee-41e8-b59c-b277927985c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "senmoriarty@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1379 Chews Landing Rd., Laurel Springs, NJ 08021",
              "phone1": null,
              "phone2": "856-401-3073",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=250",
                "https://www.njleg.state.nj.us/legislative-roster/250/assemblyman-moriarty",
                "https://www.assemblydems.com/paul-moriarty/",
                "https://www.njleg.state.nj.us/legislative-roster/461/senator-moriarty"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-4-upper-ocd-person-f15f8b7e-96ee-41e8-b59c-b277927985c0": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/b656905a-de58-4d36-b433-276788233b1f",
            "firstName": "Nilsa I.",
            "lastName": "Cruz-Perez",
            "fullName": "Nilsa Cruz-Perez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/cruzperez_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-NJ-5-upper-ocd-person-b656905a-de58-4d36-b433-276788233b1f",
              "builtID": "nj-upper-5",
              "externalID": "ocd-person/b656905a-de58-4d36-b433-276788233b1f",
              "geometry": null
            },
            "contactInfo": {
              "email": "sencruzperez@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "608 N. Broad St. Suite 200, Woodbury, NJ 08096",
              "phone1": null,
              "phone2": "856-853-2960",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=371",
                "https://www.njleg.state.nj.us/legislative-roster/371/senator-cruz-perez",
                "https://www.njsendems.org/senators/nilsa-cruz-perez/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-5-upper-ocd-person-b656905a-de58-4d36-b433-276788233b1f": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/87714b2b-1868-4d3f-aa3f-a3d25db0fbc6",
            "firstName": "James",
            "lastName": "Beach",
            "fullName": "Jim Beach",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/beach_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-NJ-6-upper-ocd-person-87714b2b-1868-4d3f-aa3f-a3d25db0fbc6",
              "builtID": "nj-upper-6",
              "externalID": "ocd-person/87714b2b-1868-4d3f-aa3f-a3d25db0fbc6",
              "geometry": null
            },
            "contactInfo": {
              "email": "senbeach@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1309 Route 70 West Suite A, Cherry Hill, NJ 08002",
              "phone1": null,
              "phone2": "856-429-1572",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=310",
                "https://www.njleg.state.nj.us/legislative-roster/310/senator-beach",
                "https://www.njsendems.org/senators/james-beach/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-6-upper-ocd-person-87714b2b-1868-4d3f-aa3f-a3d25db0fbc6": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/a1c9ec59-4127-4973-b100-f35c30bb817c",
            "firstName": "Troy",
            "lastName": "Singleton",
            "fullName": "Troy Singleton",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/singleton_troy_2019.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-NJ-7-upper-ocd-person-a1c9ec59-4127-4973-b100-f35c30bb817c",
              "builtID": "nj-upper-7",
              "externalID": "ocd-person/a1c9ec59-4127-4973-b100-f35c30bb817c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sensingleton@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "400 N. Church St. Suite 260, Moorestown, NJ 08057",
              "phone1": null,
              "phone2": "856-234-2790",
              "fax1": null,
              "fax2": "856-234-2957",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=395",
                "https://www.njleg.state.nj.us/legislative-roster/395/senator-singleton"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-7-upper-ocd-person-a1c9ec59-4127-4973-b100-f35c30bb817c": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/0e989cd5-91f8-4595-8346-bbac4f3d2c9e",
            "firstName": "Latham",
            "lastName": "Tiver",
            "fullName": "Latham Tiver",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-NJ-8-upper-ocd-person-0e989cd5-91f8-4595-8346-bbac4f3d2c9e",
              "builtID": "nj-upper-8",
              "externalID": "ocd-person/0e989cd5-91f8-4595-8346-bbac4f3d2c9e",
              "geometry": null
            },
            "contactInfo": {
              "email": "sentiver@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "Elmwood Business Park Suite B-101, Evesham, NJ 08053",
              "phone1": null,
              "phone2": "856-817-2143",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/legislative-roster/464/senator-tiver",
                "https://www.senatenj.com/201/Latham-Tiver---District-8"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-8-upper-ocd-person-0e989cd5-91f8-4595-8346-bbac4f3d2c9e": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/0f0e1edc-6ab5-4fca-8999-2db3229c5d19",
            "firstName": "Carmen F.",
            "lastName": "Amato",
            "fullName": "Carmen Amato",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-NJ-9-upper-ocd-person-0f0e1edc-6ab5-4fca-8999-2db3229c5d19",
              "builtID": "nj-upper-9",
              "externalID": "ocd-person/0f0e1edc-6ab5-4fca-8999-2db3229c5d19",
              "geometry": null
            },
            "contactInfo": {
              "email": "senamato@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "620 W. Lacey Road, Forked River, NJ 08731",
              "phone1": null,
              "phone2": "609-693-6700",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/legislative-roster/465/senator-amato",
                "https://www.senatenj.com/206/Carmen-Amato---District-9"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-9-upper-ocd-person-0f0e1edc-6ab5-4fca-8999-2db3229c5d19": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/32e625c0-5e22-40b4-a6a3-4040d10e6ff0",
            "firstName": "James William",
            "lastName": "Holzapfel",
            "fullName": "Jim Holzapfel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/holzapfel_james_2020.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-NJ-10-upper-ocd-person-32e625c0-5e22-40b4-a6a3-4040d10e6ff0",
              "builtID": "nj-upper-10",
              "externalID": "ocd-person/32e625c0-5e22-40b4-a6a3-4040d10e6ff0",
              "geometry": null
            },
            "contactInfo": {
              "email": "senholzapfel@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "852 Highway 70, Brick, NJ 08724",
              "phone1": null,
              "phone2": "732-840-9028",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=334",
                "https://holzapfel.senatenj.com/",
                "https://www.njleg.state.nj.us/legislative-roster/334/senator-holzapfel",
                "https://www.senatenj.com/163/Jim-Holzapfel---District-10"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-10-upper-ocd-person-32e625c0-5e22-40b4-a6a3-4040d10e6ff0": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/76afbcca-bed8-4e2e-bc3a-0fd6d53158a5",
            "firstName": "Vin",
            "lastName": "Gopal",
            "fullName": "Vin Gopal",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/gopal_vin_2021.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-NJ-11-upper-ocd-person-76afbcca-bed8-4e2e-bc3a-0fd6d53158a5",
              "builtID": "nj-upper-11",
              "externalID": "ocd-person/76afbcca-bed8-4e2e-bc3a-0fd6d53158a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "sengopal@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "802 W. Park Ave. Suite 302, Ocean Township, NJ 07712",
              "phone1": null,
              "phone2": "732-695-3371",
              "fax1": null,
              "fax2": "732-695-3374",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=405",
                "https://www.njleg.state.nj.us/legislative-roster/405/senator-gopal",
                "https://www.njsendems.org/senators/vin-gopal/",
                "https://www.senatorgopal.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-11-upper-ocd-person-76afbcca-bed8-4e2e-bc3a-0fd6d53158a5": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/fee23b32-9f92-4951-889e-6d09749a78b9",
            "firstName": "Owen E.",
            "lastName": "Henry",
            "fullName": "Owen Henry",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-NJ-12-upper-ocd-person-fee23b32-9f92-4951-889e-6d09749a78b9",
              "builtID": "nj-upper-12",
              "externalID": "ocd-person/fee23b32-9f92-4951-889e-6d09749a78b9",
              "geometry": null
            },
            "contactInfo": {
              "email": "senhenry@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "2501 Highway 516, Old Bridge, NJ 08857",
              "phone1": null,
              "phone2": "732-607-7580",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/legislative-roster/470/senator-henry",
                "https://www.senatenj.com/177/Owen-Henry---District-12"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-12-upper-ocd-person-fee23b32-9f92-4951-889e-6d09749a78b9": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/ac044b70-7745-435a-9c38-c4af013d0b0e",
            "firstName": "Declan Joseph",
            "lastName": "O'Scanlon",
            "fullName": "Declan O'Scanlon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/oscanlon_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-NJ-13-upper-ocd-person-ac044b70-7745-435a-9c38-c4af013d0b0e",
              "builtID": "nj-upper-13",
              "externalID": "ocd-person/ac044b70-7745-435a-9c38-c4af013d0b0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "senoscanlon@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "101 Crawfords Corner Road Suite 4332, Holmdel, NJ 07733",
              "phone1": null,
              "phone2": "732-444-1838",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=394",
                "https://oscanlon.senatenj.com/",
                "https://www.njleg.state.nj.us/legislative-roster/394/senator-o'scanlon",
                "https://www.senatenj.com/164/Declan-OScanlon---District-13"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-13-upper-ocd-person-ac044b70-7745-435a-9c38-c4af013d0b0e": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/27646653-40e2-4c79-b608-0f2edcaa0322",
            "firstName": "Linda R.",
            "lastName": "Greenstein",
            "fullName": "Linda Greenstein",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/greenstein_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-NJ-14-upper-ocd-person-27646653-40e2-4c79-b608-0f2edcaa0322",
              "builtID": "nj-upper-14",
              "externalID": "ocd-person/27646653-40e2-4c79-b608-0f2edcaa0322",
              "geometry": null
            },
            "contactInfo": {
              "email": "sengreenstein@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1249 S. River Road Suite 105, Cranbury, NJ 08512",
              "phone1": null,
              "phone2": "609-395-9911",
              "fax1": null,
              "fax2": "609-395-9032",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=329",
                "https://www.njleg.state.nj.us/legislative-roster/329/senator-greenstein",
                "https://www.njsendems.org/senators/linda-greenstein/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-14-upper-ocd-person-27646653-40e2-4c79-b608-0f2edcaa0322": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/26eb8b3c-59a5-49ca-841c-13a569444994",
            "firstName": "Shirley Kersey",
            "lastName": "Turner",
            "fullName": "Shirley Turner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/turner_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-NJ-15-upper-ocd-person-26eb8b3c-59a5-49ca-841c-13a569444994",
              "builtID": "nj-upper-15",
              "externalID": "ocd-person/26eb8b3c-59a5-49ca-841c-13a569444994",
              "geometry": null
            },
            "contactInfo": {
              "email": "senturner@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1230 Parkway Ave. Suite 103, Ewing Twp., NJ 08628",
              "phone1": null,
              "phone2": "609-323-7239",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=47",
                "https://www.njleg.state.nj.us/legislative-roster/47/senator-turner",
                "https://www.njsendems.org/senators/shirley-k-turner/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-15-upper-ocd-person-26eb8b3c-59a5-49ca-841c-13a569444994": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/7782ffcd-73df-41b5-9857-fd7b10953bf3",
            "firstName": "Andrew P.",
            "lastName": "Zwicker",
            "fullName": "Andrew Zwicker",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/zwicker_andrew_2019.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-NJ-16-upper-ocd-person-7782ffcd-73df-41b5-9857-fd7b10953bf3",
              "builtID": "nj-upper-16",
              "externalID": "ocd-person/7782ffcd-73df-41b5-9857-fd7b10953bf3",
              "geometry": null
            },
            "contactInfo": {
              "email": "senzwicker@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1 Deer Park Drive Suite D-1, Monmouth Junction, NJ 08852",
              "phone1": null,
              "phone2": "732-823-1684",
              "fax1": null,
              "fax2": "732-823-1658",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=386",
                "https://www.njleg.state.nj.us/legislative-roster/436/senator-zwicker",
                "https://www.njsendems.org/senators/andrew-zwicker/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-16-upper-ocd-person-7782ffcd-73df-41b5-9857-fd7b10953bf3": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/177d44ba-0c93-4e26-b245-84aa2239f7b4",
            "firstName": "Robert G.",
            "lastName": "Smith",
            "fullName": "Bob Smith",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/smith_bob_2021.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-NJ-17-upper-ocd-person-177d44ba-0c93-4e26-b245-84aa2239f7b4",
              "builtID": "nj-upper-17",
              "externalID": "ocd-person/177d44ba-0c93-4e26-b245-84aa2239f7b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "senbsmith@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "216 Stelton Road Suite E-5, Piscataway, NJ 08854",
              "phone1": null,
              "phone2": "732-752-0770",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=53",
                "https://www.njleg.state.nj.us/legislative-roster/53/senator-smith",
                "https://www.njsendems.org/senators/bob-smith/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-17-upper-ocd-person-177d44ba-0c93-4e26-b245-84aa2239f7b4": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/16048732-130c-4102-b6e7-2d8faa0c3f52",
            "firstName": "Patrick J.",
            "lastName": "Diegnan",
            "fullName": "Patrick Diegnan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/diegnan_2017.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-NJ-18-upper-ocd-person-16048732-130c-4102-b6e7-2d8faa0c3f52",
              "builtID": "nj-upper-18",
              "externalID": "ocd-person/16048732-130c-4102-b6e7-2d8faa0c3f52",
              "geometry": null
            },
            "contactInfo": {
              "email": "sendiegnan@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "908 Oak Tree Ave. Unit P, South Plainfield, NJ 07080",
              "phone1": null,
              "phone2": "908-757-1677",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=387",
                "https://www.njleg.state.nj.us/legislative-roster/387/senator-diegnan",
                "https://www.njsendems.org/senators/patrick-j-diegnanjr/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-18-upper-ocd-person-16048732-130c-4102-b6e7-2d8faa0c3f52": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/97fba3ff-adc8-4323-bf77-37acfe18b735",
            "firstName": "Joseph F.",
            "lastName": "Vitale",
            "fullName": "Joe Vitale",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/vitale_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-NJ-19-upper-ocd-person-97fba3ff-adc8-4323-bf77-37acfe18b735",
              "builtID": "nj-upper-19",
              "externalID": "ocd-person/97fba3ff-adc8-4323-bf77-37acfe18b735",
              "geometry": null
            },
            "contactInfo": {
              "email": "senvitale@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "569 Rahway Ave., Woodbridge, NJ 07095",
              "phone1": null,
              "phone2": "732-855-7441",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=175",
                "https://www.njleg.state.nj.us/legislative-roster/175/senator-vitale",
                "https://www.njsendems.org/category/senator/joseph-vitale/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-19-upper-ocd-person-97fba3ff-adc8-4323-bf77-37acfe18b735": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/5dcef6ac-7dbe-4c65-a53b-670c89a615e7",
            "firstName": "Joseph P.",
            "lastName": "Cryan",
            "fullName": "Joe Cryan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/cryan_joseph_2017.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-NJ-20-upper-ocd-person-5dcef6ac-7dbe-4c65-a53b-670c89a615e7",
              "builtID": "nj-upper-20",
              "externalID": "ocd-person/5dcef6ac-7dbe-4c65-a53b-670c89a615e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "sencryan@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "985 Stuyvesant Ave., Union, NJ 07083",
              "phone1": null,
              "phone2": "908-624-0880",
              "fax1": null,
              "fax2": "908-624-0587",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=406",
                "https://www.njleg.state.nj.us/legislative-roster/406/senator-cryan",
                "https://www.njsendems.org/senators/joseph-cryan/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-20-upper-ocd-person-5dcef6ac-7dbe-4c65-a53b-670c89a615e7": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/08a758f9-92fb-4e59-ac8f-edc75aaf3390",
            "firstName": "Jon M.",
            "lastName": "Bramnick",
            "fullName": "Jon Bramnick",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-NJ-21-upper-ocd-person-08a758f9-92fb-4e59-ac8f-edc75aaf3390",
              "builtID": "nj-upper-21",
              "externalID": "ocd-person/08a758f9-92fb-4e59-ac8f-edc75aaf3390",
              "geometry": null
            },
            "contactInfo": {
              "email": "senbramnick@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "251 North Ave. West 2nd Floor, Westfield, NJ 07090",
              "phone1": null,
              "phone2": "908-232-2073",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://bramnick.senatenj.com/",
                "https://www.njleg.state.nj.us/legislative-roster/433/senator-bramnick",
                "https://www.senatenj.com/160/Jon-Bramnick--District-21"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-21-upper-ocd-person-08a758f9-92fb-4e59-ac8f-edc75aaf3390": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/4d47b297-b5f4-4d83-94e6-bb95eb91ecd7",
            "firstName": "Nicholas Paul",
            "lastName": "Scutari",
            "fullName": "Nick Scutari",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/scutari_nicholas_2020.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-NJ-22-upper-ocd-person-4d47b297-b5f4-4d83-94e6-bb95eb91ecd7",
              "builtID": "nj-upper-22",
              "externalID": "ocd-person/4d47b297-b5f4-4d83-94e6-bb95eb91ecd7",
              "geometry": null
            },
            "contactInfo": {
              "email": "senscutari@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "67 Walnut Ave., Clark, NJ 07066",
              "phone1": null,
              "phone2": "732-827-7480",
              "fax1": null,
              "fax2": "732-215-4785",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=237",
                "https://www.njleg.state.nj.us/legislative-roster/237/senator-scutari",
                "https://www.njsendems.org/senators/nicholas-p-scutari/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-22-upper-ocd-person-4d47b297-b5f4-4d83-94e6-bb95eb91ecd7": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/2798197f-1516-44ef-9933-99e5b23f19c8",
            "firstName": "Douglas Joseph",
            "lastName": "Steinhardt",
            "fullName": "Doug Steinhardt",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-NJ-23-upper-ocd-person-2798197f-1516-44ef-9933-99e5b23f19c8",
              "builtID": "nj-upper-23",
              "externalID": "ocd-person/2798197f-1516-44ef-9933-99e5b23f19c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "sensteinhardt@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "127 Belvidere Ave., 2nd Floor, Washington, NJ 07882",
              "phone1": null,
              "phone2": "908-835-0552",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://steinhardt.senatenj.com/",
                "https://www.njleg.state.nj.us/legislative-roster/456/senator-steinhardt",
                "https://www.senatenj.com/169/Doug-Steinhardt---District-23"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-23-upper-ocd-person-2798197f-1516-44ef-9933-99e5b23f19c8": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/1dde507a-70ed-4f89-95f8-cd11748a753c",
            "firstName": "Parker",
            "lastName": "Space",
            "fullName": "Parker Space",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/space_parker_2020.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-NJ-24-upper-ocd-person-1dde507a-70ed-4f89-95f8-cd11748a753c",
              "builtID": "nj-upper-24",
              "externalID": "ocd-person/1dde507a-70ed-4f89-95f8-cd11748a753c",
              "geometry": null
            },
            "contactInfo": {
              "email": "senspace@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 184 1001 Route 517, Allamuchy, NJ 07820",
              "phone1": null,
              "phone2": "908-441-6343",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=358",
                "https://www.njleg.state.nj.us/legislative-roster/358/assemblyman-space",
                "https://www.njassemblygop.com/space/",
                "https://www.njleg.state.nj.us/legislative-roster/473/senator-space"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-24-upper-ocd-person-1dde507a-70ed-4f89-95f8-cd11748a753c": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/f321f20f-b8af-4d0b-85ba-e7b8dcf9ae03",
            "firstName": "Anthony Mark",
            "lastName": "Bucco",
            "fullName": "Tony Bucco",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/bucco_a_m_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-NJ-25-upper-ocd-person-f321f20f-b8af-4d0b-85ba-e7b8dcf9ae03",
              "builtID": "nj-upper-25",
              "externalID": "ocd-person/f321f20f-b8af-4d0b-85ba-e7b8dcf9ae03",
              "geometry": null
            },
            "contactInfo": {
              "email": "senbucco@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "75 Bloomfield Ave. Suite 302, 3rd Floor, Denville, NJ 07834",
              "phone1": null,
              "phone2": "973-627-9700",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=419",
                "https://bucco.senatenj.com/",
                "https://www.njleg.state.nj.us/legislative-roster/419/senator-bucco",
                "https://www.senatenj.com/159/Anthony-M-Bucco---District-25---Republic"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-25-upper-ocd-person-f321f20f-b8af-4d0b-85ba-e7b8dcf9ae03": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/4bf4c8e4-5a97-4100-b34d-cb01da6ab266",
            "firstName": "Joseph",
            "lastName": "Pennacchio",
            "fullName": "Joe Pennacchio",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/pennacchio_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-NJ-26-upper-ocd-person-4bf4c8e4-5a97-4100-b34d-cb01da6ab266",
              "builtID": "nj-upper-26",
              "externalID": "ocd-person/4bf4c8e4-5a97-4100-b34d-cb01da6ab266",
              "geometry": null
            },
            "contactInfo": {
              "email": "senpennacchio@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "170 Changebridge Road Unit A1, Montville, NJ 07045",
              "phone1": null,
              "phone2": "973-227-4012",
              "fax1": null,
              "fax2": "973-227-4945",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=305",
                "https://pennacchio.senatenj.com/",
                "https://www.njleg.state.nj.us/legislative-roster/305/senator-pennacchio",
                "https://www.senatenj.com/165/Joe-Pennacchio---District-26"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-26-upper-ocd-person-4bf4c8e4-5a97-4100-b34d-cb01da6ab266": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/1fec06e0-00df-4d84-8c9a-18e80a6f29e2",
            "firstName": "John F.",
            "lastName": "McKeon",
            "fullName": "John McKeon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/mckeon_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-NJ-27-upper-ocd-person-1fec06e0-00df-4d84-8c9a-18e80a6f29e2",
              "builtID": "nj-upper-27",
              "externalID": "ocd-person/1fec06e0-00df-4d84-8c9a-18e80a6f29e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "senmckeon@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "250 Main St., Madison, NJ 07940",
              "phone1": null,
              "phone2": "973-377-1606",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=207",
                "https://www.njleg.state.nj.us/legislative-roster/207/assemblyman-mckeon",
                "https://www.assemblydems.com/john-mckeon/",
                "https://www.njleg.state.nj.us/legislative-roster/478/senator-mckeon"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-27-upper-ocd-person-1fec06e0-00df-4d84-8c9a-18e80a6f29e2": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/b8495630-20f7-4f9e-bf84-2a13a6da3794",
            "firstName": "Renee C.",
            "lastName": "Burgess",
            "fullName": "Renee Burgess",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-NJ-28-upper-ocd-person-b8495630-20f7-4f9e-bf84-2a13a6da3794",
              "builtID": "nj-upper-28",
              "externalID": "ocd-person/b8495630-20f7-4f9e-bf84-2a13a6da3794",
              "geometry": null
            },
            "contactInfo": {
              "email": "senburgess@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "660 Stuyvesant Ave., Irvington, NJ 07111",
              "phone1": null,
              "phone2": "862-231-6577",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/legislative-roster/455/senator-burgess",
                "https://www.njsendems.org/senators/renee-c-burgess/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-28-upper-ocd-person-b8495630-20f7-4f9e-bf84-2a13a6da3794": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/907aae0b-1026-405a-9429-cadd01efd79d",
            "firstName": "Maria Teresa",
            "lastName": "Ruiz",
            "fullName": "Teresa Ruiz",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/ruiz_teresa_2021.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-NJ-29-upper-ocd-person-907aae0b-1026-405a-9429-cadd01efd79d",
              "builtID": "nj-upper-29",
              "externalID": "ocd-person/907aae0b-1026-405a-9429-cadd01efd79d",
              "geometry": null
            },
            "contactInfo": {
              "email": "senruiz@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "166 Bloomfield Ave., Newark, NJ 07104",
              "phone1": null,
              "phone2": "973-484-1000",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=287",
                "https://www.njleg.state.nj.us/legislative-roster/287/senator-ruiz",
                "https://www.njsendems.org/senators/m-teresa-ruiz/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-29-upper-ocd-person-907aae0b-1026-405a-9429-cadd01efd79d": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/45219a29-080f-4452-9b05-92488e96f2f1",
            "firstName": "Robert W.",
            "lastName": "Singer",
            "fullName": "Bob Singer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/singer_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-NJ-30-upper-ocd-person-45219a29-080f-4452-9b05-92488e96f2f1",
              "builtID": "nj-upper-30",
              "externalID": "ocd-person/45219a29-080f-4452-9b05-92488e96f2f1",
              "geometry": null
            },
            "contactInfo": {
              "email": "sensinger@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "1771 Madison Ave. Suite 202, Lakewood, NJ 08701",
              "phone1": null,
              "phone2": "732-987-5669",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=93",
                "https://singer.senatenj.com/",
                "https://www.njleg.state.nj.us/legislative-roster/93/senator-singer",
                "https://www.senatenj.com/168/Robert-Singer---District-30"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-30-upper-ocd-person-45219a29-080f-4452-9b05-92488e96f2f1": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/d8c826c4-95c7-465b-8448-1f05495bfc45",
            "firstName": "Angela V.",
            "lastName": "McKnight",
            "fullName": "Angela McKnight",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/mcknight_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-NJ-31-upper-ocd-person-d8c826c4-95c7-465b-8448-1f05495bfc45",
              "builtID": "nj-upper-31",
              "externalID": "ocd-person/d8c826c4-95c7-465b-8448-1f05495bfc45",
              "geometry": null
            },
            "contactInfo": {
              "email": "senmcknight@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "2324 John F. Kennedy Blvd., Jersey City, NJ 07304",
              "phone1": null,
              "phone2": "201-360-2502",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=383",
                "https://www.njleg.state.nj.us/legislative-roster/383/assemblywoman-mcknight",
                "https://www.njleg.state.nj.us/legislative-roster/483/senator-mcknight"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-31-upper-ocd-person-d8c826c4-95c7-465b-8448-1f05495bfc45": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/fe8bf5e4-85ed-423b-b2d8-301f29d3faea",
            "firstName": "Raj",
            "lastName": "Mukherji",
            "fullName": "Raj Mukherji",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/mukherji_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-NJ-32-upper-ocd-person-fe8bf5e4-85ed-423b-b2d8-301f29d3faea",
              "builtID": "nj-upper-32",
              "externalID": "ocd-person/fe8bf5e4-85ed-423b-b2d8-301f29d3faea",
              "geometry": null
            },
            "contactInfo": {
              "email": "senmukherji@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "433 Palisade Ave., Jersey City, NJ 07030",
              "phone1": null,
              "phone2": "201-626-4000",
              "fax1": null,
              "fax2": "201-626-4001",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=364",
                "https://www.njleg.state.nj.us/legislative-roster/364/assemblyman-mukherji",
                "https://www.assemblydems.com/raj-mukherji/",
                "https://www.njleg.state.nj.us/legislative-roster/485/senator-mukherji"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-32-upper-ocd-person-fe8bf5e4-85ed-423b-b2d8-301f29d3faea": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/6f616307-dafe-44ca-a6b3-5f9516d21f89",
            "firstName": "Brian P.",
            "lastName": "Stack",
            "fullName": "Brian Stack",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/stack_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-NJ-33-upper-ocd-person-6f616307-dafe-44ca-a6b3-5f9516d21f89",
              "builtID": "nj-upper-33",
              "externalID": "ocd-person/6f616307-dafe-44ca-a6b3-5f9516d21f89",
              "geometry": null
            },
            "contactInfo": {
              "email": "senstack@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "411 Palisade Ave., Jersey City, NJ 07307",
              "phone1": null,
              "phone2": "201-721-5263",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=306",
                "https://www.njleg.state.nj.us/legislative-roster/306/senator-stack",
                "https://www.njsendems.org/senators/brian-p-stack/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-33-upper-ocd-person-6f616307-dafe-44ca-a6b3-5f9516d21f89": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/707e3027-d06f-4346-8c11-3ff1becbe725",
            "firstName": "Britnee N.",
            "lastName": "Timberlake",
            "fullName": "Britnee Timberlake",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/timberlake_britnee_2018.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-NJ-34-upper-ocd-person-707e3027-d06f-4346-8c11-3ff1becbe725",
              "builtID": "nj-upper-34",
              "externalID": "ocd-person/707e3027-d06f-4346-8c11-3ff1becbe725",
              "geometry": null
            },
            "contactInfo": {
              "email": "sentimberlake@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "520 Main St. Suite 1, East Orange, NJ 07018",
              "phone1": null,
              "phone2": "973-395-1166",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=407",
                "https://www.njleg.state.nj.us/legislative-roster/407/assemblywoman-timberlake",
                "https://www.assemblydems.com/britnee-timberlake/",
                "https://www.njleg.state.nj.us/legislative-roster/490/senator-timberlake"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-34-upper-ocd-person-707e3027-d06f-4346-8c11-3ff1becbe725": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/a1deda3b-81ab-4453-8fa5-1d5ab8c0631d",
            "firstName": "Nelida",
            "lastName": "Pou",
            "fullName": "Nellie Pou",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/pou_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-NJ-35-upper-ocd-person-a1deda3b-81ab-4453-8fa5-1d5ab8c0631d",
              "builtID": "nj-upper-35",
              "externalID": "ocd-person/a1deda3b-81ab-4453-8fa5-1d5ab8c0631d",
              "geometry": null
            },
            "contactInfo": {
              "email": "senpou@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "100 Hamilton Plaza Suite 1405, Paterson, NJ 07505",
              "phone1": null,
              "phone2": "973-247-1555",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=335",
                "https://www.njleg.state.nj.us/legislative-roster/335/senator-pou",
                "https://www.njsendems.org/senators/nellie-pou/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-35-upper-ocd-person-a1deda3b-81ab-4453-8fa5-1d5ab8c0631d": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/1d8eb6cf-cff2-4948-ba03-7f31fcadd4ec",
            "firstName": "Paul Anthony",
            "lastName": "Sarlo",
            "fullName": "Paul Sarlo",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/sarlo_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-NJ-36-upper-ocd-person-1d8eb6cf-cff2-4948-ba03-7f31fcadd4ec",
              "builtID": "nj-upper-36",
              "externalID": "ocd-person/1d8eb6cf-cff2-4948-ba03-7f31fcadd4ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "sensarlo@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "496 Columbia Blvd. 1st Floor, Wood-Ridge, NJ 07075",
              "phone1": null,
              "phone2": "201-804-8118",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=223",
                "https://www.njleg.state.nj.us/legislative-roster/223/senator-sarlo",
                "https://www.njsendems.org/senators/paul-a-sarlo/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-36-upper-ocd-person-1d8eb6cf-cff2-4948-ba03-7f31fcadd4ec": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/53fe6716-2bdb-4926-993f-4a2cf002ad51",
            "firstName": "Gordon M.",
            "lastName": "Johnson",
            "fullName": "Gordon Johnson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/johnson_color.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-NJ-37-upper-ocd-person-53fe6716-2bdb-4926-993f-4a2cf002ad51",
              "builtID": "nj-upper-37",
              "externalID": "ocd-person/53fe6716-2bdb-4926-993f-4a2cf002ad51",
              "geometry": null
            },
            "contactInfo": {
              "email": "senjohnson@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "545 Cedar Lane, Teaneck, NJ 07666",
              "phone1": null,
              "phone2": "201-530-0469",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/legislative-roster/434/senator-johnson",
                "https://www.njsendems.org/senators/gordon-johnson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-37-upper-ocd-person-53fe6716-2bdb-4926-993f-4a2cf002ad51": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/90a0a109-2ce3-4a55-8761-1e07ff875703",
            "firstName": "Joseph A.",
            "lastName": "Lagana",
            "fullName": "Joe Lagana",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/lagana_joseph_2020.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-NJ-38-upper-ocd-person-90a0a109-2ce3-4a55-8761-1e07ff875703",
              "builtID": "nj-upper-38",
              "externalID": "ocd-person/90a0a109-2ce3-4a55-8761-1e07ff875703",
              "geometry": null
            },
            "contactInfo": {
              "email": "senlagana@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "205 Robin Road Suite 122, Paramus, NJ 07652",
              "phone1": null,
              "phone2": "201-576-9199",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=411",
                "https://www.njleg.state.nj.us/legislative-roster/411/senator-lagana",
                "https://www.njsendems.org/senators/joseph-lagana/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-38-upper-ocd-person-90a0a109-2ce3-4a55-8761-1e07ff875703": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/57fe7d05-af12-4f44-b143-e19814f2cfa2",
            "firstName": "Holly T.",
            "lastName": "Schepisi",
            "fullName": "Holly Schepisi",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/schepisi_holly_2021.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-NJ-39-upper-ocd-person-57fe7d05-af12-4f44-b143-e19814f2cfa2",
              "builtID": "nj-upper-39",
              "externalID": "ocd-person/57fe7d05-af12-4f44-b143-e19814f2cfa2",
              "geometry": null
            },
            "contactInfo": {
              "email": "senschepisi@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "287 Kinderkamack Road, Westwood, NJ 07675",
              "phone1": null,
              "phone2": "201-666-0881",
              "fax1": null,
              "fax2": "201-666-5255",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=350",
                "https://schepisi.senatenj.com/",
                "https://www.njleg.state.nj.us/legislative-roster/430/senator-schepisi",
                "https://www.senatenj.com/167/Holly-Schepisi---District-39"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-39-upper-ocd-person-57fe7d05-af12-4f44-b143-e19814f2cfa2": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/0880a4b0-8e91-484b-aa12-6e0444838c44",
            "firstName": "Kristin M.",
            "lastName": "Corrado",
            "fullName": "Kristin Corrado",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.njleg.state.nj.us/members/memberphotos/corrado_kristin_2017.jpg",
            "title": "NJ Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NJ",
              "stateFull": "New Jersey",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-NJ-40-upper-ocd-person-0880a4b0-8e91-484b-aa12-6e0444838c44",
              "builtID": "nj-upper-40",
              "externalID": "ocd-person/0880a4b0-8e91-484b-aa12-6e0444838c44",
              "geometry": null
            },
            "contactInfo": {
              "email": "sencorrado@njleg.org",
              "contactForm": null,
              "address1": null,
              "address2": "999 Riverview Drive Suite 350, Totowa, NJ 07512",
              "phone1": null,
              "phone2": "973-237-1360",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.njleg.state.nj.us/members/bio.asp?Leg=392",
                "https://corrado.senatenj.com/",
                "https://www.njleg.state.nj.us/legislative-roster/392/senator-corrado",
                "https://www.senatenj.com/161/Kristin-Corrado---District-40"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NJ-40-upper-ocd-person-0880a4b0-8e91-484b-aa12-6e0444838c44": 0
        }
      }
    }
  },
  "NM": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "NM",
          "stateFull": "New Mexico",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "NM",
            "stateFull": "New Mexico",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-NM---",
            "builtID": "nm--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-NM---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "H001046",
          "in_office": true,
          "firstName": "Martin",
          "lastName": "Heinrich",
          "middleName": null,
          "fullName": "Martin Heinrich",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/H001046.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "NM",
            "stateFull": "New Mexico",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-NM---H001046",
            "builtID": "nm--",
            "externalID": "H001046",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.heinrich.senate.gov/contact",
            "address1": "303 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-5521",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "MartinHeinrich",
            "youtube": "SenMartinHeinrich",
            "instagram": null,
            "facebook": "MartinHeinrich",
            "urls": ["https://www.heinrich.senate.gov"],
            "rss_url": null
          },
          "title": "NM Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/H001046.json",
          "govtrack_id": "412281",
          "cspan_id": "1030686",
          "votesmart_id": "74517",
          "icpsr_id": "20930",
          "crp_id": "N00029835",
          "google_entity_id": "/m/02qkv0r",
          "state_rank": "senior",
          "lis_id": "S359",
          "suffix": null,
          "date_of_birth": "1971-10-17",
          "leadership_role": null,
          "fec_candidate_id": "H8NM01224",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "11",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 6,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 1.08,
          "votes_with_party_pct": 98.9,
          "votes_against_party_pct": 1.1,
          "ocd_id": "ocd-division/country:us/state:nm"
        },
        {
          "API_ID": "L000570",
          "in_office": true,
          "firstName": "Ben",
          "lastName": "Luján",
          "middleName": "Ray",
          "fullName": "Ben Ray Lujan",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/L000570.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "NM",
            "stateFull": "New Mexico",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-NM---L000570",
            "builtID": "nm--",
            "externalID": "L000570",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.lujan.senate.gov/contact/",
            "address1": "498 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-6621",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorLujan",
            "youtube": "Repbenraylujan",
            "instagram": null,
            "facebook": "RepBenRayLujan",
            "urls": ["https://www.lujan.senate.gov"],
            "rss_url": null
          },
          "title": "NM Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/L000570.json",
          "govtrack_id": "412293",
          "cspan_id": "1031351",
          "votesmart_id": "102842",
          "icpsr_id": "20932",
          "crp_id": "N00029562",
          "google_entity_id": "/m/0412qzq",
          "state_rank": "junior",
          "lis_id": "S409",
          "suffix": null,
          "date_of_birth": "1972-06-07",
          "leadership_role": "Assistant Speaker of the House",
          "fec_candidate_id": "H8NM03196",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "3",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 5,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 0.9,
          "votes_with_party_pct": 98.54,
          "votes_against_party_pct": 1.46,
          "ocd_id": "ocd-division/country:us/state:nm"
        }
      ],
      "hash": { "SENATE-NM---H001046": 0, "SENATE-NM---L000570": 1 }
    },
    "HOUSE": {
      "1": {
        "members": [
          {
            "API_ID": "S001218",
            "in_office": true,
            "firstName": "Melanie",
            "lastName": "Stansbury",
            "middleName": null,
            "fullName": "Melanie A. Stansbury",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001218.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": 1,
              "chamber": "",
              "hashID": "HOUSE-NM-1--S001218",
              "builtID": "nm--1",
              "externalID": "S001218",
              "geometry": null,
              "geoid": "3501"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1421 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-6316",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Rep_Stansbury",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://stansbury.house.gov"],
              "rss_url": null
            },
            "title": "NM House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001218.json",
            "govtrack_id": "456861",
            "cspan_id": null,
            "votesmart_id": "180789",
            "icpsr_id": null,
            "crp_id": "N00047871",
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1979-01-31",
            "leadership_role": null,
            "fec_candidate_id": "H2NM01144",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 23,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.18,
            "votes_with_party_pct": 96.09,
            "votes_against_party_pct": 1.96,
            "ocd_id": "ocd-division/country:us/state:nm/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NM-1--S001218": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "L000273",
            "in_office": true,
            "firstName": "Teresa",
            "lastName": "Fernandez",
            "middleName": "Leger",
            "fullName": "Teresa Leger Fernandez",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/L000273.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-NM-03--L000273",
              "builtID": "nm--03",
              "externalID": "L000273",
              "geometry": null,
              "geoid": "3503"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1510 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-6190",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepTeresaLF",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://fernandez.house.gov"],
              "rss_url": null
            },
            "title": "NM House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000273.json",
            "govtrack_id": "456835",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00044559",
            "google_entity_id": "/g/11h_x7n8f5",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1959-07-01",
            "leadership_role": null,
            "fec_candidate_id": "H0NM03102",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 35,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.31,
            "votes_with_party_pct": 95.55,
            "votes_against_party_pct": 2.47,
            "ocd_id": "ocd-division/country:us/state:nm/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NM-03--L000273": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "V000136",
            "in_office": true,
            "firstName": "Gabe",
            "lastName": "Vasquez",
            "middleName": null,
            "fullName": "Gabe Vasquez",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/V000136.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-NM-02--V000136",
              "builtID": "nm--02",
              "externalID": "V000136",
              "geometry": null,
              "geoid": "3502"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1517 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2365",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://vasquez.house.gov/"],
              "rss_url": null
            },
            "title": "NM House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/V000136.json",
            "govtrack_id": "456919",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1984-08-03",
            "leadership_role": null,
            "fec_candidate_id": "H2NM02191",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 2,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.19,
            "votes_with_party_pct": 91.76,
            "votes_against_party_pct": 6.32,
            "ocd_id": "ocd-division/country:us/state:nm/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NM-02--V000136": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/948ba2f7-fd71-42ed-a35a-e692b76d2ca2",
            "firstName": "Rodney",
            "lastName": "Montoya",
            "fullName": "Rod Montoya",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HMONR.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-NM-1-lower-ocd-person-948ba2f7-fd71-42ed-a35a-e692b76d2ca2",
              "builtID": "nm-lower-1",
              "externalID": "ocd-person/948ba2f7-fd71-42ed-a35a-e692b76d2ca2",
              "geometry": null
            },
            "contactInfo": {
              "email": "roddmontoya@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "rod.montoya.186",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HMONR"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-1-lower-ocd-person-948ba2f7-fd71-42ed-a35a-e692b76d2ca2": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/aa8f331c-d541-4acd-b025-e218e01764e2",
            "firstName": "P. Mark",
            "lastName": "Duncan",
            "fullName": "Mark Duncan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HDUNC.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-NM-2-lower-ocd-person-aa8f331c-d541-4acd-b025-e218e01764e2",
              "builtID": "nm-lower-2",
              "externalID": "ocd-person/aa8f331c-d541-4acd-b025-e218e01764e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.duncan@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HDUNC"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-2-lower-ocd-person-aa8f331c-d541-4acd-b025-e218e01764e2": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/e0e4fcc9-1e0c-49f8-894d-223c863d61f8",
            "firstName": "William A.",
            "lastName": "Hall",
            "fullName": "Bill Hall",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-NM-3-lower-ocd-person-e0e4fcc9-1e0c-49f8-894d-223c863d61f8",
              "builtID": "nm-lower-3",
              "externalID": "ocd-person/e0e4fcc9-1e0c-49f8-894d-223c863d61f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.hall@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HHABI"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-3-lower-ocd-person-e0e4fcc9-1e0c-49f8-894d-223c863d61f8": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/c95a430c-716c-4612-a6be-946521bbb322",
            "firstName": "Anthony",
            "lastName": "Allison",
            "fullName": "Tony Allison",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HALLI.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-NM-4-lower-ocd-person-c95a430c-716c-4612-a6be-946521bbb322",
              "builtID": "nm-lower-4",
              "externalID": "ocd-person/c95a430c-716c-4612-a6be-946521bbb322",
              "geometry": null
            },
            "contactInfo": {
              "email": "anthony.allison@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": "35 Road 6785, Fruitland, NM 87416",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "Anthony4NM",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HALLI"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-4-lower-ocd-person-c95a430c-716c-4612-a6be-946521bbb322": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/69fcccf7-9cfb-4c8f-978a-c85fbd55922d",
            "firstName": "Doreen Wonda",
            "lastName": "Johnson",
            "fullName": "Wonda Johnson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HJOHD.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-NM-5-lower-ocd-person-69fcccf7-9cfb-4c8f-978a-c85fbd55922d",
              "builtID": "nm-lower-5",
              "externalID": "ocd-person/69fcccf7-9cfb-4c8f-978a-c85fbd55922d",
              "geometry": null
            },
            "contactInfo": {
              "email": "dwonda.johnson@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "DoreenWondaJohnson",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HJOHD"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-5-lower-ocd-person-69fcccf7-9cfb-4c8f-978a-c85fbd55922d": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/f4e26ecc-9ede-40ac-9b72-51cc2ac62f85",
            "firstName": "Eliseo Lee",
            "lastName": "Alcon",
            "fullName": "Eliseo Alcon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HALCO.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-NM-6-lower-ocd-person-f4e26ecc-9ede-40ac-9b72-51cc2ac62f85",
              "builtID": "nm-lower-6",
              "externalID": "ocd-person/f4e26ecc-9ede-40ac-9b72-51cc2ac62f85",
              "geometry": null
            },
            "contactInfo": {
              "email": "eliseo.alcon@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "eliseoalcon",
              "youtube": null,
              "instagram": null,
              "facebook": "eliseolee.alcon",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HALCO"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-6-lower-ocd-person-f4e26ecc-9ede-40ac-9b72-51cc2ac62f85": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/f2394325-2797-4403-8f77-7f4dd3db0bb9",
            "firstName": "Tanya Mirabal",
            "lastName": "Moya",
            "fullName": "Tanya Moya",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HMIRA.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-NM-7-lower-ocd-person-f2394325-2797-4403-8f77-7f4dd3db0bb9",
              "builtID": "nm-lower-7",
              "externalID": "ocd-person/f2394325-2797-4403-8f77-7f4dd3db0bb9",
              "geometry": null
            },
            "contactInfo": {
              "email": "tanya.mirabalmoya@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HMIRA"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-7-lower-ocd-person-f2394325-2797-4403-8f77-7f4dd3db0bb9": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/05f3609c-c2a6-42fc-bf6a-5141f8050ff8",
            "firstName": "Brian G.",
            "lastName": "Baca",
            "fullName": "Brian Baca",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HBACB.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-NM-8-lower-ocd-person-05f3609c-c2a6-42fc-bf6a-5141f8050ff8",
              "builtID": "nm-lower-8",
              "externalID": "ocd-person/05f3609c-c2a6-42fc-bf6a-5141f8050ff8",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.baca@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HBACB"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-8-lower-ocd-person-05f3609c-c2a6-42fc-bf6a-5141f8050ff8": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/94eed261-74bb-4f8b-a0ab-62b2a7ff8e98",
            "firstName": "Patricia A.",
            "lastName": "Lundstrom",
            "fullName": "Patty Lundstrom",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HLUND.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-NM-9-lower-ocd-person-94eed261-74bb-4f8b-a0ab-62b2a7ff8e98",
              "builtID": "nm-lower-9",
              "externalID": "ocd-person/94eed261-74bb-4f8b-a0ab-62b2a7ff8e98",
              "geometry": null
            },
            "contactInfo": {
              "email": "patricia.lundstrom@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "patricia.lundstrom.1",
              "urls": [
                "https://www.linkedin.com/in/patty-lundstrom-a47ba450/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HLUND"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-9-lower-ocd-person-94eed261-74bb-4f8b-a0ab-62b2a7ff8e98": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/5cf6976d-e616-4579-a194-ec3c37260ebd",
            "firstName": "Gregorio Andres",
            "lastName": "Romero",
            "fullName": "Andrés Romero",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HROMA.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-NM-10-lower-ocd-person-5cf6976d-e616-4579-a194-ec3c37260ebd",
              "builtID": "nm-lower-10",
              "externalID": "ocd-person/5cf6976d-e616-4579-a194-ec3c37260ebd",
              "geometry": null
            },
            "contactInfo": {
              "email": "andres.romero@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "andresromeronm",
              "youtube": null,
              "instagram": null,
              "facebook": "RomeroForDistrict10",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HROMA"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-10-lower-ocd-person-5cf6976d-e616-4579-a194-ec3c37260ebd": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/221a42af-f54f-4e66-b648-9b0028165595",
            "firstName": "Javier I.",
            "lastName": "Martinez",
            "fullName": "Javier Martínez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HMARJ.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-NM-11-lower-ocd-person-221a42af-f54f-4e66-b648-9b0028165595",
              "builtID": "nm-lower-11",
              "externalID": "ocd-person/221a42af-f54f-4e66-b648-9b0028165595",
              "geometry": null
            },
            "contactInfo": {
              "email": "javier.martinez@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "javierfornm",
              "youtube": null,
              "instagram": "javierfornm",
              "facebook": "javiermartinezfornewmexico",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HMARJ"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-11-lower-ocd-person-221a42af-f54f-4e66-b648-9b0028165595": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/057c7d79-a168-4b7b-ae96-66cf41e4f115",
            "firstName": "Art",
            "lastName": "De La Cruz",
            "fullName": "Art De La Cruz",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HDELA.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-NM-12-lower-ocd-person-057c7d79-a168-4b7b-ae96-66cf41e4f115",
              "builtID": "nm-lower-12",
              "externalID": "ocd-person/057c7d79-a168-4b7b-ae96-66cf41e4f115",
              "geometry": null
            },
            "contactInfo": {
              "email": "art.delacruz@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HDELA"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-12-lower-ocd-person-057c7d79-a168-4b7b-ae96-66cf41e4f115": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/20dc90cf-7805-4609-8631-45db1973cd5c",
            "firstName": "Patricia A.",
            "lastName": "Roybal Caballero",
            "fullName": "Pat Roybal Caballero",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HROYB.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-NM-13-lower-ocd-person-20dc90cf-7805-4609-8631-45db1973cd5c",
              "builtID": "nm-lower-13",
              "externalID": "ocd-person/20dc90cf-7805-4609-8631-45db1973cd5c",
              "geometry": null
            },
            "contactInfo": {
              "email": "pat.roybalcaballero@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "proybalcaballer",
              "youtube": null,
              "instagram": "state_rep_district_13",
              "facebook": "patricia4nm",
              "urls": [
                "https://www.linkedin.com/in/patricia-roybal-caballero-2007b135/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HROYB"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-13-lower-ocd-person-20dc90cf-7805-4609-8631-45db1973cd5c": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/3363aa83-2490-4631-b04b-9a52fdbad14b",
            "firstName": "Miguel P.",
            "lastName": "Garcia",
            "fullName": "Miguel García",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HGAMP.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-NM-14-lower-ocd-person-3363aa83-2490-4631-b04b-9a52fdbad14b",
              "builtID": "nm-lower-14",
              "externalID": "ocd-person/3363aa83-2490-4631-b04b-9a52fdbad14b",
              "geometry": null
            },
            "contactInfo": {
              "email": "miguel.garcia@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HGAMP"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-14-lower-ocd-person-3363aa83-2490-4631-b04b-9a52fdbad14b": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/124f1c82-12f1-45ab-b21e-67cc4a529d18",
            "firstName": "Dayan M.",
            "lastName": "Hochman-Vigil",
            "fullName": "Day Hochman-Vigil",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HHOCH.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-NM-15-lower-ocd-person-124f1c82-12f1-45ab-b21e-67cc4a529d18",
              "builtID": "nm-lower-15",
              "externalID": "ocd-person/124f1c82-12f1-45ab-b21e-67cc4a529d18",
              "geometry": null
            },
            "contactInfo": {
              "email": "dayan.hochman-vigil@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "dayhochmanvigil",
              "youtube": null,
              "instagram": "dayhochmanvigil",
              "facebook": "DayHochmanVigil",
              "urls": [
                "https://www.linkedin.com/in/day-hochman-vigil-3536a036/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HHOCH"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-15-lower-ocd-person-124f1c82-12f1-45ab-b21e-67cc4a529d18": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/bc947ac1-c584-4e5f-84a9-ea9f6ac959c6",
            "firstName": "Flor Yanira",
            "lastName": "Gurrola Valenzuela",
            "fullName": "Yanira Gurrola",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HGURR.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-NM-16-lower-ocd-person-bc947ac1-c584-4e5f-84a9-ea9f6ac959c6",
              "builtID": "nm-lower-16",
              "externalID": "ocd-person/bc947ac1-c584-4e5f-84a9-ea9f6ac959c6",
              "geometry": null
            },
            "contactInfo": {
              "email": "yanira.gurrola@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HGURR",
                "https://www.nmlegis.gov/members/Legislator?SponCode=HGURR"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-16-lower-ocd-person-bc947ac1-c584-4e5f-84a9-ea9f6ac959c6": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/2e2ecadf-7236-48c5-8145-e7fea6fcd393",
            "firstName": "Cynthia D.",
            "lastName": "Borrego",
            "fullName": "Cynthia Borrego",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HBORR.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-NM-17-lower-ocd-person-2e2ecadf-7236-48c5-8145-e7fea6fcd393",
              "builtID": "nm-lower-17",
              "externalID": "ocd-person/2e2ecadf-7236-48c5-8145-e7fea6fcd393",
              "geometry": null
            },
            "contactInfo": {
              "email": "cynthia.borrego@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HBORR"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-17-lower-ocd-person-2e2ecadf-7236-48c5-8145-e7fea6fcd393": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/addccb03-54fe-4f51-8720-43be8fc3b668",
            "firstName": "Gail",
            "lastName": "Chasey",
            "fullName": "Gail Chasey",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HCHAS.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-NM-18-lower-ocd-person-addccb03-54fe-4f51-8720-43be8fc3b668",
              "builtID": "nm-lower-18",
              "externalID": "ocd-person/addccb03-54fe-4f51-8720-43be8fc3b668",
              "geometry": null
            },
            "contactInfo": {
              "email": "gail@gailchasey.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repgailchasey",
              "youtube": null,
              "instagram": null,
              "facebook": "repchasey",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HCHAS"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-18-lower-ocd-person-addccb03-54fe-4f51-8720-43be8fc3b668": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/bc8985ad-c6e6-42cd-b2d5-30688e4616a1",
            "firstName": "Janelle",
            "lastName": "Anyanonu",
            "fullName": "Janelle Anyanonu",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HANYA.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-NM-19-lower-ocd-person-bc8985ad-c6e6-42cd-b2d5-30688e4616a1",
              "builtID": "nm-lower-19",
              "externalID": "ocd-person/bc8985ad-c6e6-42cd-b2d5-30688e4616a1",
              "geometry": null
            },
            "contactInfo": {
              "email": "janelle.anyanonu@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HANYA"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-19-lower-ocd-person-bc8985ad-c6e6-42cd-b2d5-30688e4616a1": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/ac49b3cf-9fb8-4fa1-af55-4b35a6570c87",
            "firstName": "Meredith A.",
            "lastName": "Dixon",
            "fullName": "Meredith Dixon",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HDIXO.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-NM-20-lower-ocd-person-ac49b3cf-9fb8-4fa1-af55-4b35a6570c87",
              "builtID": "nm-lower-20",
              "externalID": "ocd-person/ac49b3cf-9fb8-4fa1-af55-4b35a6570c87",
              "geometry": null
            },
            "contactInfo": {
              "email": "meredith.dixon@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HDIXO"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-20-lower-ocd-person-ac49b3cf-9fb8-4fa1-af55-4b35a6570c87": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/8cb27ec7-dc78-49b1-bc3f-18ad2beb76b6",
            "firstName": "Debra Marie",
            "lastName": "Sarinana",
            "fullName": "Debbie Sariñana",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HSARI.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-NM-21-lower-ocd-person-8cb27ec7-dc78-49b1-bc3f-18ad2beb76b6",
              "builtID": "nm-lower-21",
              "externalID": "ocd-person/8cb27ec7-dc78-49b1-bc3f-18ad2beb76b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "debbie.sarinana@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SarinanaDebbie",
              "youtube": null,
              "instagram": null,
              "facebook": "debsarinana",
              "urls": [
                "https://www.linkedin.com/in/debra-sarinana-05a362141/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HSARI"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-21-lower-ocd-person-8cb27ec7-dc78-49b1-bc3f-18ad2beb76b6": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/eef5df5d-b9da-4ac3-9a3e-7f6ba775e6d7",
            "firstName": "Stefani",
            "lastName": "Lord",
            "fullName": "Stefani Lord",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HLORD.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-NM-22-lower-ocd-person-eef5df5d-b9da-4ac3-9a3e-7f6ba775e6d7",
              "builtID": "nm-lower-22",
              "externalID": "ocd-person/eef5df5d-b9da-4ac3-9a3e-7f6ba775e6d7",
              "geometry": null
            },
            "contactInfo": {
              "email": "info@lord2020.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HLORD"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-22-lower-ocd-person-eef5df5d-b9da-4ac3-9a3e-7f6ba775e6d7": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/be61f240-c0ad-4a98-8ad5-d30f4c5f4a06",
            "firstName": "Alan T.",
            "lastName": "Martinez",
            "fullName": "Alan Martinez",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HMAAL.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-NM-23-lower-ocd-person-be61f240-c0ad-4a98-8ad5-d30f4c5f4a06",
              "builtID": "nm-lower-23",
              "externalID": "ocd-person/be61f240-c0ad-4a98-8ad5-d30f4c5f4a06",
              "geometry": null
            },
            "contactInfo": {
              "email": "alan.martinez@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HMAAL"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-23-lower-ocd-person-be61f240-c0ad-4a98-8ad5-d30f4c5f4a06": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/13ad8eb1-a64f-4a08-8c68-a1488466128f",
            "firstName": "Elizabeth L.",
            "lastName": "Thomson",
            "fullName": "Liz Thomson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HTHOE.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-NM-24-lower-ocd-person-13ad8eb1-a64f-4a08-8c68-a1488466128f",
              "builtID": "nm-lower-24",
              "externalID": "ocd-person/13ad8eb1-a64f-4a08-8c68-a1488466128f",
              "geometry": null
            },
            "contactInfo": {
              "email": "liz.thomson@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "lizthomsonnm",
              "youtube": null,
              "instagram": null,
              "facebook": "lizthomsonnm",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HTHOE"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-24-lower-ocd-person-13ad8eb1-a64f-4a08-8c68-a1488466128f": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/8819e763-061e-4f30-9828-af635428b41a",
            "firstName": "Cristina",
            "lastName": "Parajon",
            "fullName": "Cristina Parajón",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HPARA.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-NM-25-lower-ocd-person-8819e763-061e-4f30-9828-af635428b41a",
              "builtID": "nm-lower-25",
              "externalID": "ocd-person/8819e763-061e-4f30-9828-af635428b41a",
              "geometry": null
            },
            "contactInfo": {
              "email": "cristina.parajon@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HPARA"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-25-lower-ocd-person-8819e763-061e-4f30-9828-af635428b41a": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/19ad7971-bfe4-49e2-bc0f-c5416df1f241",
            "firstName": "Eleanor",
            "lastName": "Chavez",
            "fullName": "Eleanor Chávez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HCHAL.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-NM-26-lower-ocd-person-19ad7971-bfe4-49e2-bc0f-c5416df1f241",
              "builtID": "nm-lower-26",
              "externalID": "ocd-person/19ad7971-bfe4-49e2-bc0f-c5416df1f241",
              "geometry": null
            },
            "contactInfo": {
              "email": "eleanor.chavez@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HCHAL",
                "https://www.nmlegis.gov/members/Legislator?SponCode=HCHAL"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-26-lower-ocd-person-19ad7971-bfe4-49e2-bc0f-c5416df1f241": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/c2fb0283-189c-4b0c-9d99-7b70d2f62c40",
            "firstName": "Marian",
            "lastName": "Matthews",
            "fullName": "Marian Matthews",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HMATT.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-NM-27-lower-ocd-person-c2fb0283-189c-4b0c-9d99-7b70d2f62c40",
              "builtID": "nm-lower-27",
              "externalID": "ocd-person/c2fb0283-189c-4b0c-9d99-7b70d2f62c40",
              "geometry": null
            },
            "contactInfo": {
              "email": "marian.matthews@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Hd27Marian",
              "youtube": null,
              "instagram": null,
              "facebook": "MarianMatthewsHD27",
              "urls": [
                "https://www.linkedin.com/in/marianmatthews/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HMATT"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-27-lower-ocd-person-c2fb0283-189c-4b0c-9d99-7b70d2f62c40": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/800d2a2e-1622-4ab2-b290-3c537b38bdf8",
            "firstName": "Pamelya",
            "lastName": "Herndon",
            "fullName": "Pamelya Herndon",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HHEPA.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-NM-28-lower-ocd-person-800d2a2e-1622-4ab2-b290-3c537b38bdf8",
              "builtID": "nm-lower-28",
              "externalID": "ocd-person/800d2a2e-1622-4ab2-b290-3c537b38bdf8",
              "geometry": null
            },
            "contactInfo": {
              "email": "pamelya.herndon@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HHEPA"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-28-lower-ocd-person-800d2a2e-1622-4ab2-b290-3c537b38bdf8": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/930a9ffb-d292-49d5-8c43-c42bdab290ff",
            "firstName": "Joy I.",
            "lastName": "Garratt",
            "fullName": "Joy Garratt",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HGARR.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-NM-29-lower-ocd-person-930a9ffb-d292-49d5-8c43-c42bdab290ff",
              "builtID": "nm-lower-29",
              "externalID": "ocd-person/930a9ffb-d292-49d5-8c43-c42bdab290ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "joy.garratt@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "joyousgarratt",
              "youtube": null,
              "instagram": "jigarratt",
              "facebook": "Joy4NewMexico",
              "urls": [
                "https://www.linkedin.com/in/joygarratt/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HGARR"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-29-lower-ocd-person-930a9ffb-d292-49d5-8c43-c42bdab290ff": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/48969449-b004-4ca3-a72b-8508e95e0051",
            "firstName": "Natalie R.",
            "lastName": "Figueroa",
            "fullName": "Natalie Figueroa",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HFIGU.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-NM-30-lower-ocd-person-48969449-b004-4ca3-a72b-8508e95e0051",
              "builtID": "nm-lower-30",
              "externalID": "ocd-person/48969449-b004-4ca3-a72b-8508e95e0051",
              "geometry": null
            },
            "contactInfo": {
              "email": "natalie.figueroa@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "Natalie4NM",
              "urls": [
                "https://www.linkedin.com/in/natalie-figueroa-150a0670/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HFIGU"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-30-lower-ocd-person-48969449-b004-4ca3-a72b-8508e95e0051": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/3c48451c-736b-4e84-b0aa-dedebebebd57",
            "firstName": "William R.",
            "lastName": "Rehm",
            "fullName": "Bill Rehm",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HREHM.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-NM-31-lower-ocd-person-3c48451c-736b-4e84-b0aa-dedebebebd57",
              "builtID": "nm-lower-31",
              "externalID": "ocd-person/3c48451c-736b-4e84-b0aa-dedebebebd57",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.rehm@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "BillRehmNM",
              "youtube": null,
              "instagram": null,
              "facebook": "BillRehmNM",
              "urls": [
                "https://www.linkedin.com/in/bill-rehm-a69b5b80/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HREHM"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-31-lower-ocd-person-3c48451c-736b-4e84-b0aa-dedebebebd57": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/6968fa09-cbf8-42a5-a5a6-132b0b663103",
            "firstName": "Jenifer Marie",
            "lastName": "Jones",
            "fullName": "Jenifer Jones",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HJONJ.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-NM-32-lower-ocd-person-6968fa09-cbf8-42a5-a5a6-132b0b663103",
              "builtID": "nm-lower-32",
              "externalID": "ocd-person/6968fa09-cbf8-42a5-a5a6-132b0b663103",
              "geometry": null
            },
            "contactInfo": {
              "email": "jenifer.jones@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HJONJ"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-32-lower-ocd-person-6968fa09-cbf8-42a5-a5a6-132b0b663103": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/5b9af862-0f9f-42b2-a694-f80055129d6c",
            "firstName": "Micaela Lara",
            "lastName": "Cadena",
            "fullName": "Micaela Cadena",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HCADA.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-NM-33-lower-ocd-person-5b9af862-0f9f-42b2-a694-f80055129d6c",
              "builtID": "nm-lower-33",
              "externalID": "ocd-person/5b9af862-0f9f-42b2-a694-f80055129d6c",
              "geometry": null
            },
            "contactInfo": {
              "email": "micaela.cadena@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "laracadena4nm",
              "urls": [
                "https://www.linkedin.com/in/micaela-cadena-95541a183/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HCADA"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-33-lower-ocd-person-5b9af862-0f9f-42b2-a694-f80055129d6c": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/e8d69d4c-dfd4-4e22-939a-cc356ba7ac05",
            "firstName": "Raymundo",
            "lastName": "Lara",
            "fullName": "Ray Lara",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HLARA.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-NM-34-lower-ocd-person-e8d69d4c-dfd4-4e22-939a-cc356ba7ac05",
              "builtID": "nm-lower-34",
              "externalID": "ocd-person/e8d69d4c-dfd4-4e22-939a-cc356ba7ac05",
              "geometry": null
            },
            "contactInfo": {
              "email": "raymundo.lara@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RayLaraforStateRepresentative",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HLARA"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-34-lower-ocd-person-e8d69d4c-dfd4-4e22-939a-cc356ba7ac05": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/ea2a9403-248c-4474-aea0-47b45265e0be",
            "firstName": "Angelica M.",
            "lastName": "Rubio",
            "fullName": "Angelica Rubio",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HRUBI.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-NM-35-lower-ocd-person-ea2a9403-248c-4474-aea0-47b45265e0be",
              "builtID": "nm-lower-35",
              "externalID": "ocd-person/ea2a9403-248c-4474-aea0-47b45265e0be",
              "geometry": null
            },
            "contactInfo": {
              "email": "angelica.rubio@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "anrubio",
              "youtube": null,
              "instagram": "roguerubio",
              "facebook": "RubioNM35",
              "urls": [
                "https://www.linkedin.com/in/angelicarubio/",
                "https://www.youtube.com/channel/UC2VFQSuJ2XNJylakavA0EMw",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HRUBI"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-35-lower-ocd-person-ea2a9403-248c-4474-aea0-47b45265e0be": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/57440e55-06ad-4f1f-8aa5-3bdd31a805dd",
            "firstName": "Nathan P.",
            "lastName": "Small",
            "fullName": "Nathan Small",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HSMAL.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-NM-36-lower-ocd-person-57440e55-06ad-4f1f-8aa5-3bdd31a805dd",
              "builtID": "nm-lower-36",
              "externalID": "ocd-person/57440e55-06ad-4f1f-8aa5-3bdd31a805dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "nathan.small@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "nathanlcnm",
              "youtube": null,
              "instagram": null,
              "facebook": "NathanForNM",
              "urls": [
                "https://www.linkedin.com/in/nathan-small-20685816/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HSMAL"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-36-lower-ocd-person-57440e55-06ad-4f1f-8aa5-3bdd31a805dd": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/05309266-c7a0-455b-94f2-ebf1d436daff",
            "firstName": "Joanne J.",
            "lastName": "Ferrary",
            "fullName": "Joanne Ferrary",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HFERJ.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-NM-37-lower-ocd-person-05309266-c7a0-455b-94f2-ebf1d436daff",
              "builtID": "nm-lower-37",
              "externalID": "ocd-person/05309266-c7a0-455b-94f2-ebf1d436daff",
              "geometry": null
            },
            "contactInfo": {
              "email": "joanne.ferrary@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "rjferrary53",
              "youtube": null,
              "instagram": null,
              "facebook": "Ferrary4NM",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HFERJ"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-37-lower-ocd-person-05309266-c7a0-455b-94f2-ebf1d436daff": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/ce30fc0e-3447-4efe-a743-dec08834bd9b",
            "firstName": "Tara",
            "lastName": "Jaramillo",
            "fullName": "Tara Jaramillo",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HJARA.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-NM-38-lower-ocd-person-ce30fc0e-3447-4efe-a743-dec08834bd9b",
              "builtID": "nm-lower-38",
              "externalID": "ocd-person/ce30fc0e-3447-4efe-a743-dec08834bd9b",
              "geometry": null
            },
            "contactInfo": {
              "email": "tara.jaramillo@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HJARA"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-38-lower-ocd-person-ce30fc0e-3447-4efe-a743-dec08834bd9b": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/6f02eec7-0afe-4f15-ad56-653148da00c0",
            "firstName": "Luis M.",
            "lastName": "Terrazas",
            "fullName": "Luis Terrazas",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HTERR.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-NM-39-lower-ocd-person-6f02eec7-0afe-4f15-ad56-653148da00c0",
              "builtID": "nm-lower-39",
              "externalID": "ocd-person/6f02eec7-0afe-4f15-ad56-653148da00c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "luis.terrazas@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HTERR"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-39-lower-ocd-person-6f02eec7-0afe-4f15-ad56-653148da00c0": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/0ed175a3-3c71-4b42-bf53-9435d0f93f52",
            "firstName": "Joseph Louis",
            "lastName": "Sanchez",
            "fullName": "Joseph Sanchez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HSAJO.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-NM-40-lower-ocd-person-0ed175a3-3c71-4b42-bf53-9435d0f93f52",
              "builtID": "nm-lower-40",
              "externalID": "ocd-person/0ed175a3-3c71-4b42-bf53-9435d0f93f52",
              "geometry": null
            },
            "contactInfo": {
              "email": "joseph.sanchez@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "josephsancheznm",
              "youtube": null,
              "instagram": null,
              "facebook": "josephsanchezfornm",
              "urls": [
                "https://www.youtube.com/channel/UCxxw8xKzoQkJ-VwbfgeXz5g",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HSAJO"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-40-lower-ocd-person-0ed175a3-3c71-4b42-bf53-9435d0f93f52": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/d5d0e9d9-bd6f-4f28-9a3f-13a56cadcc0f",
            "firstName": "Susan K.",
            "lastName": "Herrera",
            "fullName": "Susan Herrera",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HHERS.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-NM-41-lower-ocd-person-d5d0e9d9-bd6f-4f28-9a3f-13a56cadcc0f",
              "builtID": "nm-lower-41",
              "externalID": "ocd-person/d5d0e9d9-bd6f-4f28-9a3f-13a56cadcc0f",
              "geometry": null
            },
            "contactInfo": {
              "email": "susan.herrera@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "NMDistrict41",
              "urls": [
                "https://www.linkedin.com/in/susan-herrera-62757815/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HHERS"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-41-lower-ocd-person-d5d0e9d9-bd6f-4f28-9a3f-13a56cadcc0f": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/0dd8a8ff-99c5-4d1a-90fb-3b74398a4141",
            "firstName": "Kristina",
            "lastName": "Ortez",
            "fullName": "Kristina Ortez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HORTE.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-NM-42-lower-ocd-person-0dd8a8ff-99c5-4d1a-90fb-3b74398a4141",
              "builtID": "nm-lower-42",
              "externalID": "ocd-person/0dd8a8ff-99c5-4d1a-90fb-3b74398a4141",
              "geometry": null
            },
            "contactInfo": {
              "email": "kristina.ortez@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HORTE"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-42-lower-ocd-person-0dd8a8ff-99c5-4d1a-90fb-3b74398a4141": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/6ecab682-9ccf-4777-940d-afa418b601b8",
            "firstName": "Christine F.",
            "lastName": "Chandler",
            "fullName": "Chris Chandler",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HCHAN.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-NM-43-lower-ocd-person-6ecab682-9ccf-4777-940d-afa418b601b8",
              "builtID": "nm-lower-43",
              "externalID": "ocd-person/6ecab682-9ccf-4777-940d-afa418b601b8",
              "geometry": null
            },
            "contactInfo": {
              "email": "christine.chandler@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Christine4nm",
              "youtube": null,
              "instagram": "christine4nm",
              "facebook": "Christine4NM",
              "urls": [
                "https://www.linkedin.com/in/christine-chandler-b567865a/",
                "https://www.youtube.com/channel/UCfJsAcQbA02PTqGqjUjzbBA",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HCHAN"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-43-lower-ocd-person-6ecab682-9ccf-4777-940d-afa418b601b8": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/73fc9839-619a-4ad5-bbbd-dfaf7f308e09",
            "firstName": "Kathleen",
            "lastName": "Cates",
            "fullName": "Kathleen Cates",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HCATE.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-NM-44-lower-ocd-person-73fc9839-619a-4ad5-bbbd-dfaf7f308e09",
              "builtID": "nm-lower-44",
              "externalID": "ocd-person/73fc9839-619a-4ad5-bbbd-dfaf7f308e09",
              "geometry": null
            },
            "contactInfo": {
              "email": "kathleen.cates@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HCATE"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-44-lower-ocd-person-73fc9839-619a-4ad5-bbbd-dfaf7f308e09": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/570bb94a-0500-44de-8983-ca259e0d4a60",
            "firstName": "Linda Michelle",
            "lastName": "Serrato",
            "fullName": "Linda Serrato",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HSERR.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-NM-45-lower-ocd-person-570bb94a-0500-44de-8983-ca259e0d4a60",
              "builtID": "nm-lower-45",
              "externalID": "ocd-person/570bb94a-0500-44de-8983-ca259e0d4a60",
              "geometry": null
            },
            "contactInfo": {
              "email": "linda.serrato@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HSERR"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-45-lower-ocd-person-570bb94a-0500-44de-8983-ca259e0d4a60": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/ed0ba54a-4fc3-4c50-aa0d-f74b1f0e86f6",
            "firstName": "Andrea D.",
            "lastName": "Romero",
            "fullName": "Andrea Romero",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HROAN.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-NM-46-lower-ocd-person-ed0ba54a-4fc3-4c50-aa0d-f74b1f0e86f6",
              "builtID": "nm-lower-46",
              "externalID": "ocd-person/ed0ba54a-4fc3-4c50-aa0d-f74b1f0e86f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "andrea@andrearomero.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "aromero_nm",
              "youtube": null,
              "instagram": "andrearomero_nm46",
              "facebook": "andrearomeronm46",
              "urls": [
                "https://www.linkedin.com/in/andreadromero/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HROAN"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-46-lower-ocd-person-ed0ba54a-4fc3-4c50-aa0d-f74b1f0e86f6": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/0b7d9451-0462-4064-b28e-6d44123edd1e",
            "firstName": "Reena",
            "lastName": "Szczepanski",
            "fullName": "Reena Szczepanski",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HSZCZ.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-NM-47-lower-ocd-person-0b7d9451-0462-4064-b28e-6d44123edd1e",
              "builtID": "nm-lower-47",
              "externalID": "ocd-person/0b7d9451-0462-4064-b28e-6d44123edd1e",
              "geometry": null
            },
            "contactInfo": {
              "email": "reena.szczepanski@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HSCZE",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HSZCZ"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-47-lower-ocd-person-0b7d9451-0462-4064-b28e-6d44123edd1e": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/a564eb5c-5c29-43ef-a40f-241efb70ef8d",
            "firstName": "Tara L.",
            "lastName": "Lujan",
            "fullName": "Tara Luján",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HLUTA.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-NM-48-lower-ocd-person-a564eb5c-5c29-43ef-a40f-241efb70ef8d",
              "builtID": "nm-lower-48",
              "externalID": "ocd-person/a564eb5c-5c29-43ef-a40f-241efb70ef8d",
              "geometry": null
            },
            "contactInfo": {
              "email": "tara.lujan@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HLUTA"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-48-lower-ocd-person-a564eb5c-5c29-43ef-a40f-241efb70ef8d": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/9564298b-dc55-4037-8205-5eca1c2063dc",
            "firstName": "Gail",
            "lastName": "Armstrong",
            "fullName": "Gail Armstrong",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HARMG.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-NM-49-lower-ocd-person-9564298b-dc55-4037-8205-5eca1c2063dc",
              "builtID": "nm-lower-49",
              "externalID": "ocd-person/9564298b-dc55-4037-8205-5eca1c2063dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "gail@gailfornewmexico.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "gail4nm",
              "youtube": null,
              "instagram": "gail4nm",
              "facebook": "Gail4NM",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HARMG"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-49-lower-ocd-person-9564298b-dc55-4037-8205-5eca1c2063dc": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/e3affc46-1790-48ba-ae36-5c00473d6c35",
            "firstName": "Matthew",
            "lastName": "McQueen",
            "fullName": "Matthew McQueen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HMCQU.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-NM-50-lower-ocd-person-e3affc46-1790-48ba-ae36-5c00473d6c35",
              "builtID": "nm-lower-50",
              "externalID": "ocd-person/e3affc46-1790-48ba-ae36-5c00473d6c35",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.mcqueen@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "mcqueenfornm",
              "youtube": null,
              "instagram": null,
              "facebook": "mcqueenfornm",
              "urls": [
                "https://www.linkedin.com/in/matthew-mcqueen-86a8322/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HMCQU"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-50-lower-ocd-person-e3affc46-1790-48ba-ae36-5c00473d6c35": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/fd2e3bdc-3ca7-495c-b853-8a4db020e4d0",
            "firstName": "John Peter",
            "lastName": "Block",
            "fullName": "John Block",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HBLOC.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-NM-51-lower-ocd-person-fd2e3bdc-3ca7-495c-b853-8a4db020e4d0",
              "builtID": "nm-lower-51",
              "externalID": "ocd-person/fd2e3bdc-3ca7-495c-b853-8a4db020e4d0",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.block@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HBLOC"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-51-lower-ocd-person-fd2e3bdc-3ca7-495c-b853-8a4db020e4d0": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/6563f869-64f5-42fb-9707-263fee6f59dc",
            "firstName": "Doreen Ybarra",
            "lastName": "Gallegos",
            "fullName": "Doreen Gallegos",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HGADO.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-NM-52-lower-ocd-person-6563f869-64f5-42fb-9707-263fee6f59dc",
              "builtID": "nm-lower-52",
              "externalID": "ocd-person/6563f869-64f5-42fb-9707-263fee6f59dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "doreen.gallegos@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "doreen4staterep",
              "youtube": null,
              "instagram": null,
              "facebook": "doreen.gallegos.9",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HGADO"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-52-lower-ocd-person-6563f869-64f5-42fb-9707-263fee6f59dc": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/8e244278-1a1d-4525-a41e-52da81f9d3ff",
            "firstName": "Willie D.",
            "lastName": "Madrid",
            "fullName": "Willie Madrid",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HMADR.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-NM-53-lower-ocd-person-8e244278-1a1d-4525-a41e-52da81f9d3ff",
              "builtID": "nm-lower-53",
              "externalID": "ocd-person/8e244278-1a1d-4525-a41e-52da81f9d3ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "willie.madrid@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "WillieMadrid3",
              "youtube": null,
              "instagram": null,
              "facebook": "williemadrid53",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HMADR"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-53-lower-ocd-person-8e244278-1a1d-4525-a41e-52da81f9d3ff": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/667a8a3c-dbe9-4aba-b768-e90be503df88",
            "firstName": "James G.",
            "lastName": "Townsend",
            "fullName": "Jim Townsend",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HTOWJ.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-NM-54-lower-ocd-person-667a8a3c-dbe9-4aba-b768-e90be503df88",
              "builtID": "nm-lower-54",
              "externalID": "ocd-person/667a8a3c-dbe9-4aba-b768-e90be503df88",
              "geometry": null
            },
            "contactInfo": {
              "email": "townsend@pvtn.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "LeaderTownsend",
              "youtube": null,
              "instagram": null,
              "facebook": "jimandpaula.townsend",
              "urls": [
                "https://www.linkedin.com/in/jim-townsend-5897b633/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HTOWJ"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-54-lower-ocd-person-667a8a3c-dbe9-4aba-b768-e90be503df88": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/8b51e39c-5f4c-48dd-affd-b97c6062d87e",
            "firstName": "Cathrynn Novich",
            "lastName": "Brown",
            "fullName": "Cathrynn Brown",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HBROW.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-NM-55-lower-ocd-person-8b51e39c-5f4c-48dd-affd-b97c6062d87e",
              "builtID": "nm-lower-55",
              "externalID": "ocd-person/8b51e39c-5f4c-48dd-affd-b97c6062d87e",
              "geometry": null
            },
            "contactInfo": {
              "email": "c.brown.nm55@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "cathrynnbrown",
              "youtube": null,
              "instagram": null,
              "facebook": "RepCathrynnBrown",
              "urls": [
                "https://www.linkedin.com/in/cathrynnbrown/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HBROW"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-55-lower-ocd-person-8b51e39c-5f4c-48dd-affd-b97c6062d87e": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/e7e8e973-411b-45fd-8fd7-7e4ea7d9f1be",
            "firstName": "Harlan H.",
            "lastName": "Vincent",
            "fullName": "Harlan Vincent",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HVINC.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-NM-56-lower-ocd-person-e7e8e973-411b-45fd-8fd7-7e4ea7d9f1be",
              "builtID": "nm-lower-56",
              "externalID": "ocd-person/e7e8e973-411b-45fd-8fd7-7e4ea7d9f1be",
              "geometry": null
            },
            "contactInfo": {
              "email": "harlan.vincent@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HVINC"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-56-lower-ocd-person-e7e8e973-411b-45fd-8fd7-7e4ea7d9f1be": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/3b21d42c-0ba5-4c02-b4bc-e1490c4ce453",
            "firstName": "Jason Carl",
            "lastName": "Harper",
            "fullName": "Jason Harper",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HHARP.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-NM-57-lower-ocd-person-3b21d42c-0ba5-4c02-b4bc-e1490c4ce453",
              "builtID": "nm-lower-57",
              "externalID": "ocd-person/3b21d42c-0ba5-4c02-b4bc-e1490c4ce453",
              "geometry": null
            },
            "contactInfo": {
              "email": "jasonharpernm@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "jasonharpernm",
              "youtube": null,
              "instagram": null,
              "facebook": "JasonHarperNM",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HHARP"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-57-lower-ocd-person-3b21d42c-0ba5-4c02-b4bc-e1490c4ce453": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/46464f19-1bda-489f-93cf-3772d0445407",
            "firstName": "Candy Spence",
            "lastName": "Ezzell",
            "fullName": "Candy Ezzell",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HEZZE.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-NM-58-lower-ocd-person-46464f19-1bda-489f-93cf-3772d0445407",
              "builtID": "nm-lower-58",
              "externalID": "ocd-person/46464f19-1bda-489f-93cf-3772d0445407",
              "geometry": null
            },
            "contactInfo": {
              "email": "csecows@aol.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "candyezzell",
              "youtube": null,
              "instagram": null,
              "facebook": "candy.ezzell",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HEZZE"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-58-lower-ocd-person-46464f19-1bda-489f-93cf-3772d0445407": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/26073faf-c6db-4e86-9ab8-0137d1a872c6",
            "firstName": "Jared A.",
            "lastName": "Hembree",
            "fullName": "Jared Hembree",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-NM-59-lower-ocd-person-26073faf-c6db-4e86-9ab8-0137d1a872c6",
              "builtID": "nm-lower-59",
              "externalID": "ocd-person/26073faf-c6db-4e86-9ab8-0137d1a872c6",
              "geometry": null
            },
            "contactInfo": {
              "email": "jared.hembree@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HHEMB"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-59-lower-ocd-person-26073faf-c6db-4e86-9ab8-0137d1a872c6": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/71b8feb5-0bbd-45bd-9b25-c6dc7642962b",
            "firstName": "Joshua N.",
            "lastName": "Hernandez",
            "fullName": "Josh Hernandez",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HHERN.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-NM-60-lower-ocd-person-71b8feb5-0bbd-45bd-9b25-c6dc7642962b",
              "builtID": "nm-lower-60",
              "externalID": "ocd-person/71b8feb5-0bbd-45bd-9b25-c6dc7642962b",
              "geometry": null
            },
            "contactInfo": {
              "email": "joshua.hernandez@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HHERN"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-60-lower-ocd-person-71b8feb5-0bbd-45bd-9b25-c6dc7642962b": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/a36bdc41-5666-4208-b8f0-8d9012d31f22",
            "firstName": "Randall T.",
            "lastName": "Pettigrew",
            "fullName": "Randy Pettigrew",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HPETT.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-NM-61-lower-ocd-person-a36bdc41-5666-4208-b8f0-8d9012d31f22",
              "builtID": "nm-lower-61",
              "externalID": "ocd-person/a36bdc41-5666-4208-b8f0-8d9012d31f22",
              "geometry": null
            },
            "contactInfo": {
              "email": "randall.pettigrew@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HPETT"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-61-lower-ocd-person-a36bdc41-5666-4208-b8f0-8d9012d31f22": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/febbd8f4-a85e-476c-b96f-52f4f759f8c7",
            "firstName": "Larry Ray",
            "lastName": "Scott",
            "fullName": "Larry Scott",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HSCOT.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-NM-62-lower-ocd-person-febbd8f4-a85e-476c-b96f-52f4f759f8c7",
              "builtID": "nm-lower-62",
              "externalID": "ocd-person/febbd8f4-a85e-476c-b96f-52f4f759f8c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "larry.scott@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HSCOT"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-62-lower-ocd-person-febbd8f4-a85e-476c-b96f-52f4f759f8c7": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/dc968f82-503e-4246-9124-b880fd6ae3ae",
            "firstName": "Martin Ruben",
            "lastName": "Zamora",
            "fullName": "Martin Zamora",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HZAMO.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-NM-63-lower-ocd-person-dc968f82-503e-4246-9124-b880fd6ae3ae",
              "builtID": "nm-lower-63",
              "externalID": "ocd-person/dc968f82-503e-4246-9124-b880fd6ae3ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "martin.zamora@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repmartinzamora",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HZAMO"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-63-lower-ocd-person-dc968f82-503e-4246-9124-b880fd6ae3ae": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/c4caca3c-faa3-445f-91e0-cce9edd53a5a",
            "firstName": "Andrea Rowley",
            "lastName": "Reeb",
            "fullName": "Andi Reeb",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HREEB.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-NM-64-lower-ocd-person-c4caca3c-faa3-445f-91e0-cce9edd53a5a",
              "builtID": "nm-lower-64",
              "externalID": "ocd-person/c4caca3c-faa3-445f-91e0-cce9edd53a5a",
              "geometry": null
            },
            "contactInfo": {
              "email": "andrea.reeb@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HREEB"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-64-lower-ocd-person-c4caca3c-faa3-445f-91e0-cce9edd53a5a": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/0421351b-6a8c-4731-bf93-5029d6b4feab",
            "firstName": "Derrick J.",
            "lastName": "Lente",
            "fullName": "Derrick Lente",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HLENT.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-NM-65-lower-ocd-person-0421351b-6a8c-4731-bf93-5029d6b4feab",
              "builtID": "nm-lower-65",
              "externalID": "ocd-person/0421351b-6a8c-4731-bf93-5029d6b4feab",
              "geometry": null
            },
            "contactInfo": {
              "email": "derrick.lente@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": "djlente",
              "facebook": "derrickjlentefornmhousedistrict65",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HLENT"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-65-lower-ocd-person-0421351b-6a8c-4731-bf93-5029d6b4feab": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/d92a32b1-e3ef-43d7-8e63-c24e59c52b3d",
            "firstName": "Jimmy G.",
            "lastName": "Mason",
            "fullName": "Jimmy Mason",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HMASO.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-NM-66-lower-ocd-person-d92a32b1-e3ef-43d7-8e63-c24e59c52b3d",
              "builtID": "nm-lower-66",
              "externalID": "ocd-person/d92a32b1-e3ef-43d7-8e63-c24e59c52b3d",
              "geometry": null
            },
            "contactInfo": {
              "email": "jimmy.mason@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HMASO"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-66-lower-ocd-person-d92a32b1-e3ef-43d7-8e63-c24e59c52b3d": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/c41b373a-7376-4084-b0de-3648cfce9b05",
            "firstName": "Jackey",
            "lastName": "Chatfield",
            "fullName": "Jack Chatfield",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HCHAT.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-NM-67-lower-ocd-person-c41b373a-7376-4084-b0de-3648cfce9b05",
              "builtID": "nm-lower-67",
              "externalID": "ocd-person/c41b373a-7376-4084-b0de-3648cfce9b05",
              "geometry": null
            },
            "contactInfo": {
              "email": "jack.chatfield@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "jackchatfieldhd67",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HCHAT"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-67-lower-ocd-person-c41b373a-7376-4084-b0de-3648cfce9b05": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/052e06cd-7f58-4c3c-8640-be1d8842a19b",
            "firstName": "Charlotte",
            "lastName": "Little",
            "fullName": "Charlotte Little",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HLITC.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-NM-68-lower-ocd-person-052e06cd-7f58-4c3c-8640-be1d8842a19b",
              "builtID": "nm-lower-68",
              "externalID": "ocd-person/052e06cd-7f58-4c3c-8640-be1d8842a19b",
              "geometry": null
            },
            "contactInfo": {
              "email": "charlotte.little@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HLITC"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-68-lower-ocd-person-052e06cd-7f58-4c3c-8640-be1d8842a19b": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/784596bc-1498-4999-a367-5a705d286085",
            "firstName": "Harry",
            "lastName": "Garcia",
            "fullName": "Harry Garcia",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HGAHA.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-NM-69-lower-ocd-person-784596bc-1498-4999-a367-5a705d286085",
              "builtID": "nm-lower-69",
              "externalID": "ocd-person/784596bc-1498-4999-a367-5a705d286085",
              "geometry": null
            },
            "contactInfo": {
              "email": "hgarciad69@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "harrygarciafornm",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HGAHA"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-69-lower-ocd-person-784596bc-1498-4999-a367-5a705d286085": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/d9b83f67-094b-44a1-bff0-231114d27d08",
            "firstName": "Ambrose M.",
            "lastName": "Castellano",
            "fullName": "Ambrose Castellano",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/House/HCAST.jpg",
            "title": "NM Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-NM-70-lower-ocd-person-d9b83f67-094b-44a1-bff0-231114d27d08",
              "builtID": "nm-lower-70",
              "externalID": "ocd-person/d9b83f67-094b-44a1-bff0-231114d27d08",
              "geometry": null
            },
            "contactInfo": {
              "email": "ambrose.castellano@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HCAST"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NM-70-lower-ocd-person-d9b83f67-094b-44a1-bff0-231114d27d08": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/7a7be318-78b1-49c6-bd98-f2b9b7476372",
            "firstName": "William E.",
            "lastName": "Sharer",
            "fullName": "Bill Sharer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SSHAR.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-NM-1-upper-ocd-person-7a7be318-78b1-49c6-bd98-f2b9b7476372",
              "builtID": "nm-upper-1",
              "externalID": "ocd-person/7a7be318-78b1-49c6-bd98-f2b9b7476372",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill@williamsharer.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "billsharer",
              "youtube": null,
              "instagram": null,
              "facebook": "bill.sharer.9",
              "urls": [
                "https://www.linkedin.com/in/william-sharer-71248033/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SSHAR"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-1-upper-ocd-person-7a7be318-78b1-49c6-bd98-f2b9b7476372": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/b5e67085-d4de-4de1-bf25-00c53ee3ec39",
            "firstName": "Steven P.",
            "lastName": "Neville",
            "fullName": "Steve Neville",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SNEVI.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-NM-2-upper-ocd-person-b5e67085-d4de-4de1-bf25-00c53ee3ec39",
              "builtID": "nm-upper-2",
              "externalID": "ocd-person/b5e67085-d4de-4de1-bf25-00c53ee3ec39",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.neville@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SteveNevilleNM",
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorSteveNeville",
              "urls": [
                "https://www.linkedin.com/in/stevenevillenewmexico/",
                "https://www.youtube.com/user/SteveNevilleNM",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SNEVI"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-2-upper-ocd-person-b5e67085-d4de-4de1-bf25-00c53ee3ec39": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/fa98430a-5031-4c64-bd6d-8a6f0cc4043e",
            "firstName": "Shannon D.",
            "lastName": "Pinto",
            "fullName": "Shannon Pinto",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SPINS.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-NM-3-upper-ocd-person-fa98430a-5031-4c64-bd6d-8a6f0cc4043e",
              "builtID": "nm-upper-3",
              "externalID": "ocd-person/fa98430a-5031-4c64-bd6d-8a6f0cc4043e",
              "geometry": null
            },
            "contactInfo": {
              "email": "shannon.pinto@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "Shannon4NM",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SPINS"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-3-upper-ocd-person-fa98430a-5031-4c64-bd6d-8a6f0cc4043e": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/b4c20aea-b56c-4a19-a89a-788c53d995d7",
            "firstName": "George K.",
            "lastName": "Munoz",
            "fullName": "George Muñoz",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SMUNO.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-NM-4-upper-ocd-person-b4c20aea-b56c-4a19-a89a-788c53d995d7",
              "builtID": "nm-upper-4",
              "externalID": "ocd-person/b4c20aea-b56c-4a19-a89a-788c53d995d7",
              "geometry": null
            },
            "contactInfo": {
              "email": "george.munoz@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "munozfornm",
              "youtube": null,
              "instagram": null,
              "facebook": "nmsenatormunoz",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SMUNO"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-4-upper-ocd-person-b4c20aea-b56c-4a19-a89a-788c53d995d7": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/9b0d6912-d333-4ae7-b626-ce643099a43d",
            "firstName": "Leo V.",
            "lastName": "Jaramillo",
            "fullName": "Leo Jaramillo",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SJARA.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-NM-5-upper-ocd-person-9b0d6912-d333-4ae7-b626-ce643099a43d",
              "builtID": "nm-upper-5",
              "externalID": "ocd-person/9b0d6912-d333-4ae7-b626-ce643099a43d",
              "geometry": null
            },
            "contactInfo": {
              "email": "leo.jaramillo@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SJARA"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-5-upper-ocd-person-9b0d6912-d333-4ae7-b626-ce643099a43d": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/5e9a828c-3dfb-4f88-8dfe-f8fccf11ef78",
            "firstName": "Roberto Jesse",
            "lastName": "Gonzales",
            "fullName": "Bobby Gonzales",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SGONZ.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-NM-6-upper-ocd-person-5e9a828c-3dfb-4f88-8dfe-f8fccf11ef78",
              "builtID": "nm-upper-6",
              "externalID": "ocd-person/5e9a828c-3dfb-4f88-8dfe-f8fccf11ef78",
              "geometry": null
            },
            "contactInfo": {
              "email": "roberto.gonzales@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SGONZ"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-6-upper-ocd-person-5e9a828c-3dfb-4f88-8dfe-f8fccf11ef78": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/6a28626b-9919-4d77-9b62-44ad1d45215d",
            "firstName": "John Patrick",
            "lastName": "Woods",
            "fullName": "Pat Woods",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SWOOD.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-NM-7-upper-ocd-person-6a28626b-9919-4d77-9b62-44ad1d45215d",
              "builtID": "nm-upper-7",
              "externalID": "ocd-person/6a28626b-9919-4d77-9b62-44ad1d45215d",
              "geometry": null
            },
            "contactInfo": {
              "email": "pat.woods@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.linkedin.com/in/pat-woods-abb44b46/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SWOOD"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-7-upper-ocd-person-6a28626b-9919-4d77-9b62-44ad1d45215d": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/e2f7dd70-36fc-4b65-8dfd-19fcc1d63e38",
            "firstName": "Pete",
            "lastName": "Campos",
            "fullName": "Pete Campos",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SCAMP.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-NM-8-upper-ocd-person-e2f7dd70-36fc-4b65-8dfd-19fcc1d63e38",
              "builtID": "nm-upper-8",
              "externalID": "ocd-person/e2f7dd70-36fc-4b65-8dfd-19fcc1d63e38",
              "geometry": null
            },
            "contactInfo": {
              "email": "pete.campos@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "senpetecampos",
              "youtube": null,
              "instagram": null,
              "facebook": "pete.campos.1426",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SCAMP",
                "https://petecampos.com"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-8-upper-ocd-person-e2f7dd70-36fc-4b65-8dfd-19fcc1d63e38": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/a30f836f-c05d-47d6-9566-29830f7cb4cc",
            "firstName": "Brenda Grace Agoyothe",
            "lastName": "McKenna",
            "fullName": "Brenda McKenna",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SMCKE.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-NM-9-upper-ocd-person-a30f836f-c05d-47d6-9566-29830f7cb4cc",
              "builtID": "nm-upper-9",
              "externalID": "ocd-person/a30f836f-c05d-47d6-9566-29830f7cb4cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "brenda.mckenna@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SMCKE"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-9-upper-ocd-person-a30f836f-c05d-47d6-9566-29830f7cb4cc": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/cce76180-bf09-413a-9d9d-1cc8ae7cf2db",
            "firstName": "Katy M.",
            "lastName": "Duhigg",
            "fullName": "Katy Duhigg",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SDUHI.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-NM-10-upper-ocd-person-cce76180-bf09-413a-9d9d-1cc8ae7cf2db",
              "builtID": "nm-upper-10",
              "externalID": "ocd-person/cce76180-bf09-413a-9d9d-1cc8ae7cf2db",
              "geometry": null
            },
            "contactInfo": {
              "email": "katy.duhigg@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SDUHI"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-10-upper-ocd-person-cce76180-bf09-413a-9d9d-1cc8ae7cf2db": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/ecc60fc8-8598-4650-8a55-6e91d24e991e",
            "firstName": "Linda M.",
            "lastName": "Lopez",
            "fullName": "Linda López",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SLOPE.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-NM-11-upper-ocd-person-ecc60fc8-8598-4650-8a55-6e91d24e991e",
              "builtID": "nm-upper-11",
              "externalID": "ocd-person/ecc60fc8-8598-4650-8a55-6e91d24e991e",
              "geometry": null
            },
            "contactInfo": {
              "email": "linda.lopez@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "lopez4gov",
              "youtube": null,
              "instagram": null,
              "facebook": "linda.m.lopez.39",
              "urls": [
                "https://www.linkedin.com/in/linda-lopez-018b9111/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SLOPE"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-11-upper-ocd-person-ecc60fc8-8598-4650-8a55-6e91d24e991e": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/f2ef9d8b-fed9-43d3-a1e3-ac5a20d59265",
            "firstName": "Gerald P.",
            "lastName": "Ortiz y Pino",
            "fullName": "Jerry Ortiz y Pino",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SORTI.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-NM-12-upper-ocd-person-f2ef9d8b-fed9-43d3-a1e3-ac5a20d59265",
              "builtID": "nm-upper-12",
              "externalID": "ocd-person/f2ef9d8b-fed9-43d3-a1e3-ac5a20d59265",
              "geometry": null
            },
            "contactInfo": {
              "email": "jortizyp@msn.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "jerryfornm",
              "youtube": null,
              "instagram": null,
              "facebook": "jerry.o.pino",
              "urls": [
                "https://www.linkedin.com/in/jerry-pino-2126b014/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SORTI"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-12-upper-ocd-person-f2ef9d8b-fed9-43d3-a1e3-ac5a20d59265": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/f6772233-67ef-4094-9713-bdf4ddd96a5f",
            "firstName": "William Baldwin",
            "lastName": "O'Neill",
            "fullName": "Bill O'Neill",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SONEI.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-NM-13-upper-ocd-person-f6772233-67ef-4094-9713-bdf4ddd96a5f",
              "builtID": "nm-upper-13",
              "externalID": "ocd-person/f6772233-67ef-4094-9713-bdf4ddd96a5f",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill@billoneill4nm.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "billoneillfornm",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SONEI"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-13-upper-ocd-person-f6772233-67ef-4094-9713-bdf4ddd96a5f": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/ce888eff-62f5-41c0-9114-651c43f77aba",
            "firstName": "Michael",
            "lastName": "Padilla",
            "fullName": "Michael Padilla",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SPADI.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-NM-14-upper-ocd-person-ce888eff-62f5-41c0-9114-651c43f77aba",
              "builtID": "nm-upper-14",
              "externalID": "ocd-person/ce888eff-62f5-41c0-9114-651c43f77aba",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.padilla@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SenPadilla",
              "youtube": null,
              "instagram": "michael.padilla.31",
              "facebook": "michael.padilla.31",
              "urls": [
                "https://www.linkedin.com/in/michaelpadilla1/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SPADI"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-14-upper-ocd-person-ce888eff-62f5-41c0-9114-651c43f77aba": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/4fcfad95-7efe-4bea-93d0-026d28382c3d",
            "firstName": "Daniel A.",
            "lastName": "Ivey-Soto",
            "fullName": "Daniel Ivey-Soto",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SIVEY.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-NM-15-upper-ocd-person-4fcfad95-7efe-4bea-93d0-026d28382c3d",
              "builtID": "nm-upper-15",
              "externalID": "ocd-person/4fcfad95-7efe-4bea-93d0-026d28382c3d",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.ivey-soto@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "IveySoto",
              "youtube": null,
              "instagram": null,
              "facebook": "seniveysoto",
              "urls": [
                "https://www.linkedin.com/in/iveysoto/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SIVEY"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-15-upper-ocd-person-4fcfad95-7efe-4bea-93d0-026d28382c3d": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/3dcac810-1d9b-4a4d-9e82-8b6e49eba735",
            "firstName": "Antoinette",
            "lastName": "Sedillo Lopez",
            "fullName": "Antoinette Sedillo Lopez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SSEDI.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-NM-16-upper-ocd-person-3dcac810-1d9b-4a4d-9e82-8b6e49eba735",
              "builtID": "nm-upper-16",
              "externalID": "ocd-person/3dcac810-1d9b-4a4d-9e82-8b6e49eba735",
              "geometry": null
            },
            "contactInfo": {
              "email": "a.sedillolopez@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "asl4justice4all",
              "youtube": null,
              "instagram": "antoinetteforjustice",
              "facebook": "NMStateSenatorAntoinetteSedilloLopezNMSD16",
              "urls": [
                "https://www.linkedin.com/in/antoinette-sedillo-lopez-ab3a5014/",
                "https://www.youtube.com/channel/UCbdMb0StJwEM_P5WXsx0w7Q",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SSEDI"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-16-upper-ocd-person-3dcac810-1d9b-4a4d-9e82-8b6e49eba735": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/f1144347-0a16-43e4-97b9-01308a890631",
            "firstName": "Mimi",
            "lastName": "Stewart",
            "fullName": "Mimi Stewart",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SSTEW.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-NM-17-upper-ocd-person-f1144347-0a16-43e4-97b9-01308a890631",
              "builtID": "nm-upper-17",
              "externalID": "ocd-person/f1144347-0a16-43e4-97b9-01308a890631",
              "geometry": null
            },
            "contactInfo": {
              "email": "mimi.stewart@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Sen_MimiStewart",
              "youtube": null,
              "instagram": "mimistewart9",
              "facebook": "peopleformimistewart",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SSTEW"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-17-upper-ocd-person-f1144347-0a16-43e4-97b9-01308a890631": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/37cc0bab-0119-4bd6-9794-3d3324cc34de",
            "firstName": "William G.",
            "lastName": "Tallman",
            "fullName": "Bill Tallman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/STALL.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-NM-18-upper-ocd-person-37cc0bab-0119-4bd6-9794-3d3324cc34de",
              "builtID": "nm-upper-18",
              "externalID": "ocd-person/37cc0bab-0119-4bd6-9794-3d3324cc34de",
              "geometry": null
            },
            "contactInfo": {
              "email": "w.tallman5909@comcast.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "tallman5909",
              "youtube": null,
              "instagram": null,
              "facebook": "Tallman4NM",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=STALL"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-18-upper-ocd-person-37cc0bab-0119-4bd6-9794-3d3324cc34de": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/de331065-d830-4877-a31d-25c6c20784cd",
            "firstName": "Gregg",
            "lastName": "Schmedes",
            "fullName": "Gregg Schmedes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SSCHM.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-NM-19-upper-ocd-person-de331065-d830-4877-a31d-25c6c20784cd",
              "builtID": "nm-upper-19",
              "externalID": "ocd-person/de331065-d830-4877-a31d-25c6c20784cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "gregg.schmedes@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "gwschmedes",
              "youtube": null,
              "instagram": "greggschmedes",
              "facebook": "gregg4nm",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HSCHM",
                "https://www.linkedin.com/in/gregg-schmedes-93025311b/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SSCHM"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-19-upper-ocd-person-de331065-d830-4877-a31d-25c6c20784cd": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/88fd357f-3fb1-4c2b-b49b-555231011c46",
            "firstName": "Martin",
            "lastName": "Hickey",
            "fullName": "Martin Hickey",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SHICK.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-NM-20-upper-ocd-person-88fd357f-3fb1-4c2b-b49b-555231011c46",
              "builtID": "nm-upper-20",
              "externalID": "ocd-person/88fd357f-3fb1-4c2b-b49b-555231011c46",
              "geometry": null
            },
            "contactInfo": {
              "email": "martin.hickey@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SHICK"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-20-upper-ocd-person-88fd357f-3fb1-4c2b-b49b-555231011c46": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/0024fecf-b002-4f60-b3ab-57ebb1944de0",
            "firstName": "Mark David",
            "lastName": "Moores",
            "fullName": "Mark Moores",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SMOOR.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-NM-21-upper-ocd-person-0024fecf-b002-4f60-b3ab-57ebb1944de0",
              "builtID": "nm-upper-21",
              "externalID": "ocd-person/0024fecf-b002-4f60-b3ab-57ebb1944de0",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.moores@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "markdmoores",
              "urls": [
                "https://www.linkedin.com/in/mark-m-bbb06a5/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SMOOR"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-21-upper-ocd-person-0024fecf-b002-4f60-b3ab-57ebb1944de0": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/145aba4a-89ba-4c17-b1ec-2f4b568f5048",
            "firstName": "Benny J.",
            "lastName": "Shendo",
            "fullName": "Benny Shendo",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SSHEN.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-NM-22-upper-ocd-person-145aba4a-89ba-4c17-b1ec-2f4b568f5048",
              "builtID": "nm-upper-22",
              "externalID": "ocd-person/145aba4a-89ba-4c17-b1ec-2f4b568f5048",
              "geometry": null
            },
            "contactInfo": {
              "email": "benny.shendo@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "BennyShendoforNMSenate",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SSHEN"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-22-upper-ocd-person-145aba4a-89ba-4c17-b1ec-2f4b568f5048": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/713e2881-4ecb-4d66-9bd3-6422c898df91",
            "firstName": "Harold",
            "lastName": "Pope",
            "fullName": "Harold Pope",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SPOPE.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-NM-23-upper-ocd-person-713e2881-4ecb-4d66-9bd3-6422c898df91",
              "builtID": "nm-upper-23",
              "externalID": "ocd-person/713e2881-4ecb-4d66-9bd3-6422c898df91",
              "geometry": null
            },
            "contactInfo": {
              "email": "harold.popejr@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SPOPE"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-23-upper-ocd-person-713e2881-4ecb-4d66-9bd3-6422c898df91": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/24a1452c-8cf1-4d5e-a49e-fb9c716381f5",
            "firstName": "Nancy E.",
            "lastName": "Rodriguez",
            "fullName": "Nancy Rodriguez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SRODR.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-NM-24-upper-ocd-person-24a1452c-8cf1-4d5e-a49e-fb9c716381f5",
              "builtID": "nm-upper-24",
              "externalID": "ocd-person/24a1452c-8cf1-4d5e-a49e-fb9c716381f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "nancy.rodriguez@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SRODR"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-24-upper-ocd-person-24a1452c-8cf1-4d5e-a49e-fb9c716381f5": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/7595b557-0c70-44e3-a26b-af533d1ca94d",
            "firstName": "Peter",
            "lastName": "Wirth",
            "fullName": "Peter Wirth",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SWIRT.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-NM-25-upper-ocd-person-7595b557-0c70-44e3-a26b-af533d1ca94d",
              "builtID": "nm-upper-25",
              "externalID": "ocd-person/7595b557-0c70-44e3-a26b-af533d1ca94d",
              "geometry": null
            },
            "contactInfo": {
              "email": "peter.wirth@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "senatorwirth",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SWIRT"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-25-upper-ocd-person-7595b557-0c70-44e3-a26b-af533d1ca94d": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/2913173a-2bfe-4474-8bdc-d145f868c4de",
            "firstName": "Antonio",
            "lastName": "Maestas",
            "fullName": "Moe Maestas",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SMAEM.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-NM-26-upper-ocd-person-2913173a-2bfe-4474-8bdc-d145f868c4de",
              "builtID": "nm-upper-26",
              "externalID": "ocd-person/2913173a-2bfe-4474-8bdc-d145f868c4de",
              "geometry": null
            },
            "contactInfo": {
              "email": "antonio.maestas@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMoe",
              "youtube": null,
              "instagram": null,
              "facebook": "repmoe",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HMAES",
                "https://www.linkedin.com/in/antonio-moe%E2%80%8B-maestas-1262466/",
                "https://www.youtube.com/channel/UC7G5hJOY0O8otO1RpJtszSQ",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HMAES",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SMAEM",
                "https://nmlegis.gov/Members/Legislator?SponCode=smaem"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-26-upper-ocd-person-2913173a-2bfe-4474-8bdc-d145f868c4de": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/79e71401-1fce-49bc-84cf-4e4449a9a696",
            "firstName": "Gregory J.",
            "lastName": "Nibert",
            "fullName": "Greg Nibert",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-NM-27-upper-ocd-person-79e71401-1fce-49bc-84cf-4e4449a9a696",
              "builtID": "nm-upper-27",
              "externalID": "ocd-person/79e71401-1fce-49bc-84cf-4e4449a9a696",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.nibert@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.linkedin.com/in/greg-nibert-78120326/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HNIBE",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SNIBE"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-27-upper-ocd-person-79e71401-1fce-49bc-84cf-4e4449a9a696": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/23ae490e-665a-4d01-b000-ad84ebd25b09",
            "firstName": "Siah",
            "lastName": "Correa Hemphill",
            "fullName": "Siah Correa Hemphill",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SHEMP.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-NM-28-upper-ocd-person-23ae490e-665a-4d01-b000-ad84ebd25b09",
              "builtID": "nm-upper-28",
              "externalID": "ocd-person/23ae490e-665a-4d01-b000-ad84ebd25b09",
              "geometry": null
            },
            "contactInfo": {
              "email": "siah.hemphill@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SHEMP"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-28-upper-ocd-person-23ae490e-665a-4d01-b000-ad84ebd25b09": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/33fb337d-0a2f-4a2c-9018-dcb6760917aa",
            "firstName": "Gregory A.",
            "lastName": "Baca",
            "fullName": "Greg Baca",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SBACA.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-NM-29-upper-ocd-person-33fb337d-0a2f-4a2c-9018-dcb6760917aa",
              "builtID": "nm-upper-29",
              "externalID": "ocd-person/33fb337d-0a2f-4a2c-9018-dcb6760917aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.baca@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "bacaforsenatenm",
              "youtube": null,
              "instagram": null,
              "facebook": "gregory.baca.75",
              "urls": [
                "https://www.linkedin.com/in/gregory-a-baca-377b2238/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SBACA"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-29-upper-ocd-person-33fb337d-0a2f-4a2c-9018-dcb6760917aa": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/82528266-9d6a-4fb3-854e-be9ab6a549b7",
            "firstName": "Joshua Adolph",
            "lastName": "Sanchez",
            "fullName": "Joshua Sanchez",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SSANJ.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-NM-30-upper-ocd-person-82528266-9d6a-4fb3-854e-be9ab6a549b7",
              "builtID": "nm-upper-30",
              "externalID": "ocd-person/82528266-9d6a-4fb3-854e-be9ab6a549b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "jas4nm@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SSANJ"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-30-upper-ocd-person-82528266-9d6a-4fb3-854e-be9ab6a549b7": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/14f0186e-bf9b-46aa-aa00-1ccc8f58490b",
            "firstName": "Joseph",
            "lastName": "Cervantes",
            "fullName": "Joe Cervantes",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SCERV.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-NM-31-upper-ocd-person-14f0186e-bf9b-46aa-aa00-1ccc8f58490b",
              "builtID": "nm-upper-31",
              "externalID": "ocd-person/14f0186e-bf9b-46aa-aa00-1ccc8f58490b",
              "geometry": null
            },
            "contactInfo": {
              "email": "joseph.cervantes@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "senjoecervantes",
              "youtube": null,
              "instagram": null,
              "facebook": "joe4nm",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SCERV"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-31-upper-ocd-person-14f0186e-bf9b-46aa-aa00-1ccc8f58490b": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/4be1cfe1-f23c-41c3-a0f1-0e366d32cc1e",
            "firstName": "Cliff R.",
            "lastName": "Pirtle",
            "fullName": "Cliff Pirtle",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SPIRT.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-NM-32-upper-ocd-person-4be1cfe1-f23c-41c3-a0f1-0e366d32cc1e",
              "builtID": "nm-upper-32",
              "externalID": "ocd-person/4be1cfe1-f23c-41c3-a0f1-0e366d32cc1e",
              "geometry": null
            },
            "contactInfo": {
              "email": "cliff.pirtle@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "CliffPirtle",
              "youtube": null,
              "instagram": null,
              "facebook": "cliff.pirtle",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SPIRT"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-32-upper-ocd-person-4be1cfe1-f23c-41c3-a0f1-0e366d32cc1e": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/0ee726a5-7015-46c1-a09d-401c19f7a7cf",
            "firstName": "William F.",
            "lastName": "Burt",
            "fullName": "Bill Burt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SBURT.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-NM-33-upper-ocd-person-0ee726a5-7015-46c1-a09d-401c19f7a7cf",
              "builtID": "nm-upper-33",
              "externalID": "ocd-person/0ee726a5-7015-46c1-a09d-401c19f7a7cf",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.burt@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "burtfornmsenate",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.linkedin.com/in/william-f-bill-burt-21467b58/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SBURT"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-33-upper-ocd-person-0ee726a5-7015-46c1-a09d-401c19f7a7cf": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/87448df9-e44d-421f-ac90-d7cb7f00df79",
            "firstName": "Ron",
            "lastName": "Griggs",
            "fullName": "Ron Griggs",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SGRIG.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-NM-34-upper-ocd-person-87448df9-e44d-421f-ac90-d7cb7f00df79",
              "builtID": "nm-upper-34",
              "externalID": "ocd-person/87448df9-e44d-421f-ac90-d7cb7f00df79",
              "geometry": null
            },
            "contactInfo": {
              "email": "ron.griggs@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "ron.griggs.31",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SGRIG"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-34-upper-ocd-person-87448df9-e44d-421f-ac90-d7cb7f00df79": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/1ed6a003-54c1-4da3-b297-0cec8ddf4882",
            "firstName": "Crystal Diamond",
            "lastName": "Brantley",
            "fullName": "Crystal Brantley",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SDIAM.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-NM-35-upper-ocd-person-1ed6a003-54c1-4da3-b297-0cec8ddf4882",
              "builtID": "nm-upper-35",
              "externalID": "ocd-person/1ed6a003-54c1-4da3-b297-0cec8ddf4882",
              "geometry": null
            },
            "contactInfo": {
              "email": "crystal.diamond@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SDIAM"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-35-upper-ocd-person-1ed6a003-54c1-4da3-b297-0cec8ddf4882": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/601202fc-f916-4be1-ae20-8c437293817e",
            "firstName": "Jeffrey",
            "lastName": "Steinborn",
            "fullName": "Jeff Steinborn",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SSTEI.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-NM-36-upper-ocd-person-601202fc-f916-4be1-ae20-8c437293817e",
              "builtID": "nm-upper-36",
              "externalID": "ocd-person/601202fc-f916-4be1-ae20-8c437293817e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.steinborn@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "jeff4nm",
              "youtube": null,
              "instagram": null,
              "facebook": "jeff.steinborn.9",
              "urls": [
                "https://www.linkedin.com/in/jeff-steinborn-57879346/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SSTEI"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-36-upper-ocd-person-601202fc-f916-4be1-ae20-8c437293817e": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/33aa9a6b-b106-4216-8ea5-82d85cd2250a",
            "firstName": "William P.",
            "lastName": "Soules",
            "fullName": "Bill Soules",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SSOUL.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-NM-37-upper-ocd-person-33aa9a6b-b106-4216-8ea5-82d85cd2250a",
              "builtID": "nm-upper-37",
              "externalID": "ocd-person/33aa9a6b-b106-4216-8ea5-82d85cd2250a",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.soules@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "senatorsoules",
              "youtube": null,
              "instagram": "soules_bill",
              "facebook": "Soules4Senate37",
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SSOUL"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-37-upper-ocd-person-33aa9a6b-b106-4216-8ea5-82d85cd2250a": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/83df260f-2110-4d39-8e19-6a2edc33491d",
            "firstName": "Carrie",
            "lastName": "Hamblen",
            "fullName": "Carrie Hamblen",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SHAMB.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-NM-38-upper-ocd-person-83df260f-2110-4d39-8e19-6a2edc33491d",
              "builtID": "nm-upper-38",
              "externalID": "ocd-person/83df260f-2110-4d39-8e19-6a2edc33491d",
              "geometry": null
            },
            "contactInfo": {
              "email": "carrie.hamblen@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SHAMB"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-38-upper-ocd-person-83df260f-2110-4d39-8e19-6a2edc33491d": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/1ed4e059-94c6-4404-b28e-0dc572112d03",
            "firstName": "Elizabeth T.",
            "lastName": "Stefanics",
            "fullName": "Liz Stefanics",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SSTEF.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-NM-39-upper-ocd-person-1ed4e059-94c6-4404-b28e-0dc572112d03",
              "builtID": "nm-upper-39",
              "externalID": "ocd-person/1ed4e059-94c6-4404-b28e-0dc572112d03",
              "geometry": null
            },
            "contactInfo": {
              "email": "lstefanics@msn.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorLizStefanics",
              "urls": [
                "https://www.linkedin.com/in/liz-stefanics-140488151/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SSTEF"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-39-upper-ocd-person-1ed4e059-94c6-4404-b28e-0dc572112d03": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/365e015d-5011-4126-8b07-0b0f90a949c7",
            "firstName": "Craig W.",
            "lastName": "Brandt",
            "fullName": "Craig Brandt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SBRAN.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-NM-40-upper-ocd-person-365e015d-5011-4126-8b07-0b0f90a949c7",
              "builtID": "nm-upper-40",
              "externalID": "ocd-person/365e015d-5011-4126-8b07-0b0f90a949c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "craig.brandt@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Craig4NM",
              "youtube": null,
              "instagram": null,
              "facebook": "craig.brandt.31",
              "urls": [
                "https://www.linkedin.com/in/craigbrandtnm/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SBRAN"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-40-upper-ocd-person-365e015d-5011-4126-8b07-0b0f90a949c7": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/9d5296b7-b8b1-40c8-a8ce-246f719b8ff0",
            "firstName": "David M.",
            "lastName": "Gallegos",
            "fullName": "David Gallegos",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SGADM.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-NM-41-upper-ocd-person-9d5296b7-b8b1-40c8-a8ce-246f719b8ff0",
              "builtID": "nm-upper-41",
              "externalID": "ocd-person/9d5296b7-b8b1-40c8-a8ce-246f719b8ff0",
              "geometry": null
            },
            "contactInfo": {
              "email": "david@ramirezandsonsinc.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "gallegos4nm",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=HGADV",
                "https://www.linkedin.com/in/david-gallegos-02427756/",
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SGADM"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-41-upper-ocd-person-9d5296b7-b8b1-40c8-a8ce-246f719b8ff0": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/180655a2-a251-4b16-8ae9-74f634e650e4",
            "firstName": "Steven",
            "lastName": "McCutcheon",
            "fullName": "Steve McCutcheon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nmlegis.gov/Images/Legislators/Senate/SMCCU.jpg",
            "title": "NM Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NM",
              "stateFull": "New Mexico",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-NM-42-upper-ocd-person-180655a2-a251-4b16-8ae9-74f634e650e4",
              "builtID": "nm-upper-42",
              "externalID": "ocd-person/180655a2-a251-4b16-8ae9-74f634e650e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "steven.mccutcheon@nmlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nmlegis.gov/Members/Legislator?SponCode=SMCCU"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NM-42-upper-ocd-person-180655a2-a251-4b16-8ae9-74f634e650e4": 0
        }
      }
    }
  },
  "NY": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "NY",
          "stateFull": "New York",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "NY",
            "stateFull": "New York",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-NY---",
            "builtID": "ny--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-NY---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "G000555",
          "in_office": true,
          "firstName": "Kirsten",
          "lastName": "Gillibrand",
          "middleName": "E.",
          "fullName": "Kirsten E. Gillibrand",
          "gender": "F",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/G000555.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "NY",
            "stateFull": "New York",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-NY---G000555",
            "builtID": "ny--",
            "externalID": "G000555",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.gillibrand.senate.gov/contact/email-me",
            "address1": "478 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-4451",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "GillibrandNY",
            "youtube": "KirstenEGillibrand",
            "instagram": null,
            "facebook": "SenKirstenGillibrand",
            "urls": ["https://www.gillibrand.senate.gov"],
            "rss_url": null
          },
          "title": "NY Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/G000555.json",
          "govtrack_id": "412223",
          "cspan_id": "1022862",
          "votesmart_id": "65147",
          "icpsr_id": "20735",
          "crp_id": "N00027658",
          "google_entity_id": "/m/0gnfc4",
          "state_rank": "junior",
          "lis_id": "S331",
          "suffix": null,
          "date_of_birth": "1966-12-09",
          "leadership_role": null,
          "fec_candidate_id": "H6NY20167",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "15",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 8,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 1.44,
          "votes_with_party_pct": 99.63,
          "votes_against_party_pct": 0.37,
          "ocd_id": "ocd-division/country:us/state:ny"
        },
        {
          "API_ID": "S000148",
          "in_office": true,
          "firstName": "Charles",
          "lastName": "Schumer",
          "middleName": "E.",
          "fullName": "Charles E. Schumer",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/S000148.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "NY",
            "stateFull": "New York",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-NY---S000148",
            "builtID": "ny--",
            "externalID": "S000148",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenSchumer",
            "youtube": "SenatorSchumer",
            "instagram": null,
            "facebook": "senschumer",
            "urls": ["https://www.schumer.senate.gov"],
            "rss_url": null
          },
          "title": "NY Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/S000148.json",
          "govtrack_id": "300087",
          "cspan_id": "5929",
          "votesmart_id": "26976",
          "icpsr_id": "14858",
          "crp_id": "N00001093",
          "google_entity_id": "/m/01w74d",
          "state_rank": "senior",
          "lis_id": "S270",
          "suffix": null,
          "date_of_birth": "1950-11-23",
          "leadership_role": "Senate Majority Leader",
          "fec_candidate_id": "S8NY00082",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "25",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 3,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 0.54,
          "votes_with_party_pct": 98.18,
          "votes_against_party_pct": 1.82,
          "ocd_id": "ocd-division/country:us/state:ny"
        }
      ],
      "hash": { "SENATE-NY---G000555": 0, "SENATE-NY---S000148": 1 }
    },
    "HOUSE": {
      "10": {
        "members": [
          {
            "API_ID": "G000599",
            "in_office": true,
            "firstName": "Dan",
            "lastName": "Goldman",
            "middleName": null,
            "fullName": "Daniel S. Goldman",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/G000599.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "10",
              "chamber": "",
              "hashID": "HOUSE-NY-10--G000599",
              "builtID": "ny--10",
              "externalID": "G000599",
              "geometry": null,
              "geoid": "3610"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "245 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-7944",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDanGoldman",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://goldman.house.gov/"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000599.json",
            "govtrack_id": "456923",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-02-26",
            "leadership_role": null,
            "fec_candidate_id": "H2NY10308",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 69,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 6.53,
            "votes_with_party_pct": 95.91,
            "votes_against_party_pct": 2.15,
            "ocd_id": "ocd-division/country:us/state:ny/cd:10",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-10--G000599": 0 }
      },
      "11": {
        "members": [
          {
            "API_ID": "M000317",
            "in_office": true,
            "firstName": "Nicole",
            "lastName": "Malliotakis",
            "middleName": null,
            "fullName": "Nicole Malliotakis",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M000317.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "11",
              "chamber": "",
              "hashID": "HOUSE-NY-11--M000317",
              "builtID": "ny--11",
              "externalID": "M000317",
              "geometry": null,
              "geoid": "3611"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "351 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3371",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMalliotakis",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://malliotakis.house.gov"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M000317.json",
            "govtrack_id": "456837",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00044040",
            "google_entity_id": "/m/0g5817m",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1980-11-11",
            "leadership_role": null,
            "fec_candidate_id": "H0NY11078",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 21,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.99,
            "votes_with_party_pct": 91.03,
            "votes_against_party_pct": 7,
            "ocd_id": "ocd-division/country:us/state:ny/cd:11",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-11--M000317": 0 }
      },
      "12": {
        "members": [
          {
            "API_ID": "N000002",
            "in_office": true,
            "firstName": "Jerrold",
            "lastName": "Nadler",
            "middleName": null,
            "fullName": "Jerrold Nadler",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/N000002.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "12",
              "chamber": "",
              "hashID": "HOUSE-NY-12--N000002",
              "builtID": "ny--12",
              "externalID": "N000002",
              "geometry": null,
              "geoid": "3612"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2132 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5635",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJerryNadler",
              "youtube": "congressmannadler",
              "instagram": null,
              "facebook": "CongressmanNadler",
              "urls": ["https://nadler.house.gov"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/N000002.json",
            "govtrack_id": "400289",
            "cspan_id": "26159",
            "votesmart_id": "26980",
            "icpsr_id": "29377",
            "crp_id": "N00000939",
            "google_entity_id": "/m/03tk11",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1947-06-13",
            "leadership_role": null,
            "fec_candidate_id": "H2NY17071",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "34",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 34,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.22,
            "votes_with_party_pct": 95.35,
            "votes_against_party_pct": 2.77,
            "ocd_id": "ocd-division/country:us/state:ny/cd:12",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-12--N000002": 0 }
      },
      "13": {
        "members": [
          {
            "API_ID": "E000297",
            "in_office": true,
            "firstName": "Adriano",
            "lastName": "Espaillat",
            "middleName": null,
            "fullName": "Adriano Espaillat",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/E000297.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "13",
              "chamber": "",
              "hashID": "HOUSE-NY-13--E000297",
              "builtID": "ny--13",
              "externalID": "E000297",
              "geometry": null,
              "geoid": "3613"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2332 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4365",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepEspaillat",
              "youtube": null,
              "instagram": null,
              "facebook": "RepEspaillat",
              "urls": ["https://espaillat.house.gov"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/E000297.json",
            "govtrack_id": "412718",
            "cspan_id": "68413",
            "votesmart_id": "14379",
            "icpsr_id": "21715",
            "crp_id": "N00034549",
            "google_entity_id": "/m/06nfs2",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1954-09-27",
            "leadership_role": null,
            "fec_candidate_id": "H2NY13096",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 14,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.33,
            "votes_with_party_pct": 95.25,
            "votes_against_party_pct": 2.81,
            "ocd_id": "ocd-division/country:us/state:ny/cd:13",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-13--E000297": 0 }
      },
      "14": {
        "members": [
          {
            "API_ID": "O000172",
            "in_office": true,
            "firstName": "Alexandria",
            "lastName": "Ocasio-Cortez",
            "middleName": null,
            "fullName": "Alexandria Ocasio-Cortez",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/O000172.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "14",
              "chamber": "",
              "hashID": "HOUSE-NY-14--O000172",
              "builtID": "ny--14",
              "externalID": "O000172",
              "geometry": null,
              "geoid": "3614"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "250 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3965",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAOC",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ocasio-cortez.house.gov"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/O000172.json",
            "govtrack_id": "412804",
            "cspan_id": null,
            "votesmart_id": "180416",
            "icpsr_id": null,
            "crp_id": "N00041162",
            "google_entity_id": "/g/11f6y3nqg6",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1989-10-13",
            "leadership_role": null,
            "fec_candidate_id": "H8NY15148",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 33,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 3.13,
            "votes_with_party_pct": 90.03,
            "votes_against_party_pct": 8,
            "ocd_id": "ocd-division/country:us/state:ny/cd:14",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-14--O000172": 0 }
      },
      "15": {
        "members": [
          {
            "API_ID": "T000486",
            "in_office": true,
            "firstName": "Ritchie",
            "lastName": "Torres",
            "middleName": null,
            "fullName": "Ritchie Torres",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/T000486.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "15",
              "chamber": "",
              "hashID": "HOUSE-NY-15--T000486",
              "builtID": "ny--15",
              "externalID": "T000486",
              "geometry": null,
              "geoid": "3615"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1414 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4361",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRitchie",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ritchietorres.house.gov"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000486.json",
            "govtrack_id": "456838",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00044346",
            "google_entity_id": "/m/0x11h9s",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1988-03-12",
            "leadership_role": null,
            "fec_candidate_id": "H0NY15160",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 37,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.5,
            "votes_with_party_pct": 94.25,
            "votes_against_party_pct": 3.77,
            "ocd_id": "ocd-division/country:us/state:ny/cd:15",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-15--T000486": 0 }
      },
      "16": {
        "members": [
          {
            "API_ID": "B001223",
            "in_office": true,
            "firstName": "Jamaal",
            "lastName": "Bowman",
            "middleName": null,
            "fullName": "Jamaal Bowman",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/B001223.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "16",
              "chamber": "",
              "hashID": "HOUSE-NY-16--B001223",
              "builtID": "ny--16",
              "externalID": "B001223",
              "geometry": null,
              "geoid": "3616"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "345 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2464",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBowman",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://bowman.house.gov"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001223.json",
            "govtrack_id": "456839",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00044790",
            "google_entity_id": "/g/11fsgvc0xx",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-04-01",
            "leadership_role": null,
            "fec_candidate_id": "H0NY16143",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 109,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 10.32,
            "votes_with_party_pct": 89.22,
            "votes_against_party_pct": 8.75,
            "ocd_id": "ocd-division/country:us/state:ny/cd:16",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-16--B001223": 0 }
      },
      "17": {
        "members": [
          {
            "API_ID": "L000599",
            "in_office": true,
            "firstName": "Michael",
            "lastName": "Lawler",
            "middleName": null,
            "fullName": "Michael Lawler",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/L000599.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "17",
              "chamber": "",
              "hashID": "HOUSE-NY-17--L000599",
              "builtID": "ny--17",
              "externalID": "L000599",
              "geometry": null,
              "geoid": "3617"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1013 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-6506",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMikeLawler",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://lawler.house.gov/"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000599.json",
            "govtrack_id": "456924",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1986-09-09",
            "leadership_role": null,
            "fec_candidate_id": "H2NY17162",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 5,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.47,
            "votes_with_party_pct": 79.42,
            "votes_against_party_pct": 18.64,
            "ocd_id": "ocd-division/country:us/state:ny/cd:17",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-17--L000599": 0 }
      },
      "18": {
        "members": [
          {
            "API_ID": "R000579",
            "in_office": true,
            "firstName": "Patrick",
            "lastName": "Ryan",
            "middleName": null,
            "fullName": "Patrick Ryan",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/R000579.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "18",
              "chamber": "",
              "hashID": "HOUSE-NY-18--R000579",
              "builtID": "ny--18",
              "externalID": "R000579",
              "geometry": null,
              "geoid": "3618"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1030 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5614",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepPatRyanNY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://patryan.house.gov/"],
              "rss_url": ""
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/R000579.json",
            "govtrack_id": "456871",
            "cspan_id": null,
            "votesmart_id": "180314",
            "icpsr_id": null,
            "crp_id": "N00041165",
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1982-03-28",
            "leadership_role": null,
            "fec_candidate_id": "H8NY19223",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 3,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.28,
            "votes_with_party_pct": 91.66,
            "votes_against_party_pct": 6.42,
            "ocd_id": "ocd-division/country:us/state:ny/cd:18",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-18--R000579": 0 }
      },
      "19": {
        "members": [
          {
            "API_ID": "M001221",
            "in_office": true,
            "firstName": "Marcus",
            "lastName": "Molinaro",
            "middleName": null,
            "fullName": "Marcus J. Molinaro",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001221.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "19",
              "chamber": "",
              "hashID": "HOUSE-NY-19--M001221",
              "builtID": "ny--19",
              "externalID": "M001221",
              "geometry": null,
              "geoid": "3619"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1207 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5441",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMolinaroNY19",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://molinaro.house.gov/"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001221.json",
            "govtrack_id": "456925",
            "cspan_id": null,
            "votesmart_id": "69202",
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1975-10-08",
            "leadership_role": null,
            "fec_candidate_id": "H2NY19127",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 32,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 3.03,
            "votes_with_party_pct": 83.65,
            "votes_against_party_pct": 14.36,
            "ocd_id": "ocd-division/country:us/state:ny/cd:19",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-19--M001221": 0 }
      },
      "20": {
        "members": [
          {
            "API_ID": "T000469",
            "in_office": true,
            "firstName": "Paul",
            "lastName": "Tonko",
            "middleName": null,
            "fullName": "Paul Tonko",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/T000469.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "20",
              "chamber": "",
              "hashID": "HOUSE-NY-20--T000469",
              "builtID": "ny--20",
              "externalID": "T000469",
              "geometry": null,
              "geoid": "3620"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2369 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5076",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepPaulTonko",
              "youtube": "reppaultonko",
              "instagram": null,
              "facebook": "reppaultonko",
              "urls": ["https://tonko.house.gov"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000469.json",
            "govtrack_id": "412319",
            "cspan_id": "1031353",
            "votesmart_id": "4403",
            "icpsr_id": "20934",
            "crp_id": "N00030196",
            "google_entity_id": "/m/0gzy4c",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1949-06-18",
            "leadership_role": null,
            "fec_candidate_id": "H8NY21203",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "16",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 5,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.47,
            "votes_with_party_pct": 95.87,
            "votes_against_party_pct": 2.31,
            "ocd_id": "ocd-division/country:us/state:ny/cd:20",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-20--T000469": 0 }
      },
      "21": {
        "members": [
          {
            "API_ID": "S001196",
            "in_office": true,
            "firstName": "Elise",
            "lastName": "Stefanik",
            "middleName": null,
            "fullName": "Elise M. Stefanik",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S001196.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "21",
              "chamber": "",
              "hashID": "HOUSE-NY-21--S001196",
              "builtID": "ny--21",
              "externalID": "S001196",
              "geometry": null,
              "geoid": "3621"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2211 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4611",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepStefanik",
              "youtube": null,
              "instagram": null,
              "facebook": "RepEliseStefanik",
              "urls": ["https://stefanik.house.gov"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001196.json",
            "govtrack_id": "412648",
            "cspan_id": "76364",
            "votesmart_id": "152539",
            "icpsr_id": "21541",
            "crp_id": "N00035523",
            "google_entity_id": "/m/0110fjsf",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1984-07-02",
            "leadership_role": "House Republican Conference Chair",
            "fec_candidate_id": "H4NY21079",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 9,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 94.05,
            "votes_against_party_pct": 4.09,
            "ocd_id": "ocd-division/country:us/state:ny/cd:21",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-21--S001196": 0 }
      },
      "22": {
        "members": [
          {
            "API_ID": "W000828",
            "in_office": true,
            "firstName": "Brandon",
            "lastName": "Williams",
            "middleName": null,
            "fullName": "Brandon Williams",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/W000828.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "22",
              "chamber": "",
              "hashID": "HOUSE-NY-22--W000828",
              "builtID": "ny--22",
              "externalID": "W000828",
              "geometry": null,
              "geoid": "3622"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1022 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3701",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://brandonwilliams.house.gov/"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000828.json",
            "govtrack_id": "456926",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1967-05-22",
            "leadership_role": null,
            "fec_candidate_id": "H2NY22212",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 71,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 6.72,
            "votes_with_party_pct": 88.7,
            "votes_against_party_pct": 9.22,
            "ocd_id": "ocd-division/country:us/state:ny/cd:22",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-22--W000828": 0 }
      },
      "23": {
        "members": [
          {
            "API_ID": "L000600",
            "in_office": true,
            "firstName": "Nick",
            "lastName": "Langworthy",
            "middleName": null,
            "fullName": "Nicholas A. Langworthy",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/L000600.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "23",
              "chamber": "",
              "hashID": "HOUSE-NY-23--L000600",
              "builtID": "ny--23",
              "externalID": "L000600",
              "geometry": null,
              "geoid": "3623"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1630 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3161",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://langworthy.house.gov/"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000600.json",
            "govtrack_id": "456927",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1981-02-27",
            "leadership_role": null,
            "fec_candidate_id": "H2NY23228",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 55,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 5.21,
            "votes_with_party_pct": 91.94,
            "votes_against_party_pct": 6.02,
            "ocd_id": "ocd-division/country:us/state:ny/cd:23",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-23--L000600": 0 }
      },
      "24": {
        "members": [
          {
            "API_ID": "T000478",
            "in_office": true,
            "firstName": "Claudia",
            "lastName": "Tenney",
            "middleName": null,
            "fullName": "Claudia Tenney",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/T000478.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "24",
              "chamber": "",
              "hashID": "HOUSE-NY-24--T000478",
              "builtID": "ny--24",
              "externalID": "T000478",
              "geometry": null,
              "geoid": "3624"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2349 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3665",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepTenney",
              "youtube": null,
              "instagram": null,
              "facebook": "RepClaudiaTenney",
              "urls": ["https://tenney.house.gov"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000478.json",
            "govtrack_id": "412720",
            "cspan_id": "103481",
            "votesmart_id": "127668",
            "icpsr_id": "21749",
            "crp_id": "N00036351",
            "google_entity_id": "/m/0g5s72n",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1961-02-04",
            "leadership_role": null,
            "fec_candidate_id": "H4NY22051",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 9,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 92.79,
            "votes_against_party_pct": 5.26,
            "ocd_id": "ocd-division/country:us/state:ny/cd:24",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-24--T000478": 0 }
      },
      "25": {
        "members": [
          {
            "API_ID": "M001206",
            "in_office": true,
            "firstName": "Joe",
            "lastName": "Morelle",
            "middleName": null,
            "fullName": "Joseph D. Morelle",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M001206.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "25",
              "chamber": "",
              "hashID": "HOUSE-NY-25--M001206",
              "builtID": "ny--25",
              "externalID": "M001206",
              "geometry": null,
              "geoid": "3625"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "570 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3615",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repjoemorelle",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://morelle.house.gov"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001206.json",
            "govtrack_id": "412749",
            "cspan_id": null,
            "votesmart_id": "4362",
            "icpsr_id": null,
            "crp_id": "N00043207",
            "google_entity_id": "/m/03d820m",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1957-04-29",
            "leadership_role": null,
            "fec_candidate_id": "H8NY25105",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 31,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.94,
            "votes_with_party_pct": 94.98,
            "votes_against_party_pct": 3.05,
            "ocd_id": "ocd-division/country:us/state:ny/cd:25",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-25--M001206": 0 }
      },
      "26": {
        "members": [
          {
            "API_ID": "K000402",
            "in_office": true,
            "firstName": "Timothy",
            "lastName": "Kennedy",
            "middleName": null,
            "fullName": "Timothy Kennedy",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/K000402.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "26",
              "chamber": "",
              "hashID": "HOUSE-NY-26--K000402",
              "builtID": "ny--26",
              "externalID": "K000402",
              "geometry": null,
              "geoid": "3626"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://kennedy.house.gov/"],
              "rss_url": ""
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000402.json",
            "govtrack_id": null,
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-10-20",
            "leadership_role": null,
            "fec_candidate_id": null,
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": null,
            "total_votes": 159,
            "missed_votes": 0,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0,
            "votes_with_party_pct": 96.23,
            "votes_against_party_pct": 3.14,
            "ocd_id": "ocd-division/country:us/state:ny/cd:26",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-26--K000402": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "C001067",
            "in_office": true,
            "firstName": "Yvette",
            "lastName": "Clarke",
            "middleName": "D.",
            "fullName": "Yvette D. Clarke",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001067.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-NY-09--C001067",
              "builtID": "ny--09",
              "externalID": "C001067",
              "geometry": null,
              "geoid": "3609"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2058 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6231",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepYvetteClarke",
              "youtube": "repyvetteclarke",
              "instagram": null,
              "facebook": "repyvettedclarke",
              "urls": ["https://clarke.house.gov"],
              "rss_url": "https://clarke.house.gov/category/press-releases/feed/"
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001067.json",
            "govtrack_id": "412221",
            "cspan_id": "1022875",
            "votesmart_id": "44741",
            "icpsr_id": "20733",
            "crp_id": "N00026961",
            "google_entity_id": "/m/06h90t",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1964-11-21",
            "leadership_role": null,
            "fec_candidate_id": "H4NY11138",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "18",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 8,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.76,
            "votes_with_party_pct": 94.5,
            "votes_against_party_pct": 3.66,
            "ocd_id": "ocd-division/country:us/state:ny/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-09--C001067": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "D000632",
            "in_office": true,
            "firstName": "Anthony",
            "lastName": "D'Esposito",
            "middleName": null,
            "fullName": "Anthony D'Esposito",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/D000632.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-NY-04--D000632",
              "builtID": "ny--04",
              "externalID": "D000632",
              "geometry": null,
              "geoid": "3604"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1508 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5516",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDesposito",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://desposito.house.gov/"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000632.json",
            "govtrack_id": "456922",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1982-02-22",
            "leadership_role": null,
            "fec_candidate_id": "H2NY04277",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 41,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 3.88,
            "votes_with_party_pct": 83.4,
            "votes_against_party_pct": 14.59,
            "ocd_id": "ocd-division/country:us/state:ny/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-04--D000632": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "G000597",
            "in_office": true,
            "firstName": "Andrew",
            "lastName": "Garbarino",
            "middleName": null,
            "fullName": "Andrew R. Garbarino",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000597.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-NY-02--G000597",
              "builtID": "ny--02",
              "externalID": "G000597",
              "geometry": null,
              "geoid": "3602"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2344 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-7896",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGarbarino",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://garbarino.house.gov"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000597.json",
            "govtrack_id": "456836",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00046030",
            "google_entity_id": "/g/11bw8f4j8q",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1984-09-27",
            "leadership_role": null,
            "fec_candidate_id": "H0NY02234",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 64,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 6.06,
            "votes_with_party_pct": 82.6,
            "votes_against_party_pct": 15.35,
            "ocd_id": "ocd-division/country:us/state:ny/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-02--G000597": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "J000294",
            "in_office": true,
            "firstName": "Hakeem",
            "lastName": "Jeffries",
            "middleName": null,
            "fullName": "Hakeem S. Jeffries",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/J000294.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-NY-08--J000294",
              "builtID": "ny--08",
              "externalID": "J000294",
              "geometry": null,
              "geoid": "3608"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2433 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5936",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJeffries",
              "youtube": null,
              "instagram": null,
              "facebook": "RepHakeemJeffries",
              "urls": ["https://jeffries.house.gov"],
              "rss_url": "https://jeffries.house.gov/feed/"
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/J000294.json",
            "govtrack_id": "412561",
            "cspan_id": "79612",
            "votesmart_id": "55285",
            "icpsr_id": "21343",
            "crp_id": "N00033640",
            "google_entity_id": "/m/025_74m",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1970-08-04",
            "leadership_role": "House Democratic Caucus Chair",
            "fec_candidate_id": "H2NY10092",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 8,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.76,
            "votes_with_party_pct": 97.01,
            "votes_against_party_pct": 1.16,
            "ocd_id": "ocd-division/country:us/state:ny/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-08--J000294": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "L000598",
            "in_office": true,
            "firstName": "Nick",
            "lastName": "LaLota",
            "middleName": null,
            "fullName": "Nick LaLota",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/L000598.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-NY-01--L000598",
              "builtID": "ny--01",
              "externalID": "L000598",
              "geometry": null,
              "geoid": "3601"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1530 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3826",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLaLota",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://lalota.house.gov/"],
              "rss_url": null
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000598.json",
            "govtrack_id": "456920",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1978-06-23",
            "leadership_role": null,
            "fec_candidate_id": "H2NY01190",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 8,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.76,
            "votes_with_party_pct": 83.35,
            "votes_against_party_pct": 14.8,
            "ocd_id": "ocd-division/country:us/state:ny/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-01--L000598": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "M001137",
            "in_office": true,
            "firstName": "Gregory",
            "lastName": "Meeks",
            "middleName": "W.",
            "fullName": "Gregory W. Meeks",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M001137.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-NY-05--M001137",
              "builtID": "ny--05",
              "externalID": "M001137",
              "geometry": null,
              "geoid": "3605"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2310 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3461",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGregoryMeeks",
              "youtube": "gwmeeks",
              "instagram": null,
              "facebook": "gregorymeeksny05",
              "urls": ["https://meeks.house.gov"],
              "rss_url": "https://meeks.house.gov/rss.xml"
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001137.json",
            "govtrack_id": "400271",
            "cspan_id": "53469",
            "votesmart_id": "4360",
            "icpsr_id": "29776",
            "crp_id": "N00001171",
            "google_entity_id": "/m/024_3b",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1953-09-25",
            "leadership_role": null,
            "fec_candidate_id": "H8NY06048",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "28",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 24,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.27,
            "votes_with_party_pct": 96.58,
            "votes_against_party_pct": 1.57,
            "ocd_id": "ocd-division/country:us/state:ny/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-05--M001137": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "M001188",
            "in_office": true,
            "firstName": "Grace",
            "lastName": "Meng",
            "middleName": null,
            "fullName": "Grace Meng",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M001188.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-NY-06--M001188",
              "builtID": "ny--06",
              "externalID": "M001188",
              "geometry": null,
              "geoid": "3606"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2209 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2601",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGraceMeng",
              "youtube": null,
              "instagram": null,
              "facebook": "repgracemeng",
              "urls": ["https://meng.house.gov"],
              "rss_url": "https://meng.house.gov/rss.xml"
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001188.json",
            "govtrack_id": "412560",
            "cspan_id": "68411",
            "votesmart_id": "69157",
            "icpsr_id": "21342",
            "crp_id": "N00034547",
            "google_entity_id": "/m/04ld76x",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1975-10-01",
            "leadership_role": null,
            "fec_candidate_id": "H2NY06116",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 57,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 5.4,
            "votes_with_party_pct": 95.55,
            "votes_against_party_pct": 2.53,
            "ocd_id": "ocd-division/country:us/state:ny/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-06--M001188": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "S001201",
            "in_office": true,
            "firstName": "Thomas",
            "lastName": "Suozzi",
            "middleName": null,
            "fullName": "Thomas Suozzi",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001201.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-NY-03--S001201",
              "builtID": "ny--03",
              "externalID": "S001201",
              "geometry": null,
              "geoid": "3603"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepTomSuozzi",
              "youtube": null,
              "instagram": null,
              "facebook": "RepTomSuozzi",
              "urls": ["https://suozzi.house.gov"],
              "rss_url": "https://suozzi.house.gov/rss.xml"
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001201.json",
            "govtrack_id": "412717",
            "cspan_id": "104747",
            "votesmart_id": "92111",
            "icpsr_id": "21747",
            "crp_id": "N00038742",
            "google_entity_id": "/m/060n4d",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1962-08-31",
            "leadership_role": null,
            "fec_candidate_id": null,
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": null,
            "total_votes": 281,
            "missed_votes": 2,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.71,
            "votes_with_party_pct": 91.67,
            "votes_against_party_pct": 7.97,
            "ocd_id": "ocd-division/country:us/state:ny/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-03--S001201": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "V000081",
            "in_office": true,
            "firstName": "Nydia",
            "lastName": "Velázquez",
            "middleName": "M.",
            "fullName": "Nydia M. Velázquez",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/V000081.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-NY-07--V000081",
              "builtID": "ny--07",
              "externalID": "V000081",
              "geometry": null,
              "geoid": "3607"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2302 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2361",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "NydiaVelazquez",
              "youtube": "nydiavelazquez",
              "instagram": null,
              "facebook": "8037068318",
              "urls": ["https://velazquez.house.gov"],
              "rss_url": "https://velazquez.house.gov/rss.xml"
            },
            "title": "NY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/V000081.json",
            "govtrack_id": "400416",
            "cspan_id": "26160",
            "votesmart_id": "26975",
            "icpsr_id": "29378",
            "crp_id": "N00001102",
            "google_entity_id": "/m/03sjbt",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1953-03-28",
            "leadership_role": null,
            "fec_candidate_id": "H2NY00010",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "32",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 32,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 3.03,
            "votes_with_party_pct": 93.68,
            "votes_against_party_pct": 4.44,
            "ocd_id": "ocd-division/country:us/state:ny/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NY-07--V000081": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/835c10ff-752e-49b0-b7a7-390edc412bc3",
            "firstName": "Frederick W.",
            "lastName": "Thiele",
            "fullName": "Fred Thiele",
            "gender": "Male",
            "party": "democratic/independence/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/001/headshot/001.jpg?hst=1660072514",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-NY-1-lower-ocd-person-835c10ff-752e-49b0-b7a7-390edc412bc3",
              "builtID": "ny-lower-1",
              "externalID": "ocd-person/835c10ff-752e-49b0-b7a7-390edc412bc3",
              "geometry": null
            },
            "contactInfo": {
              "email": "thielef@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3350 Noyac Road, Building B Suite 1, Sag Harbor, NY 11963",
              "phone1": null,
              "phone2": "631-537-2583",
              "fax1": null,
              "fax2": "631-725-2372",
              "twitter": "fred_thiele",
              "youtube": null,
              "instagram": null,
              "facebook": "assemblymanfredthiele",
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Fred-W-Thiele-Jr",
                "https://nyassembly.gov/mem/fred-w-thiele-jr"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-1-lower-ocd-person-835c10ff-752e-49b0-b7a7-390edc412bc3": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/983d7603-0ccc-4995-8afe-2ece30a487c7",
            "firstName": "Jodi Bennett",
            "lastName": "Giglio",
            "fullName": "Jodi Giglio",
            "gender": "Female",
            "party": "republican/conservative/independence",
            "image": "https://assembly.state.ny.us/write/upload/member_files/002/headshot/002.jpg?hst=1668622850",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-NY-2-lower-ocd-person-983d7603-0ccc-4995-8afe-2ece30a487c7",
              "builtID": "ny-lower-2",
              "externalID": "ocd-person/983d7603-0ccc-4995-8afe-2ece30a487c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "giglioj2@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "30 W. Main St. Suite 103, Riverhead, NY 11901",
              "phone1": null,
              "phone2": "631-727-0204",
              "fax1": null,
              "fax2": "631-727-0426",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Jodi-Giglio",
                "https://nyassembly.gov/mem/Jodi-Giglio"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-2-lower-ocd-person-983d7603-0ccc-4995-8afe-2ece30a487c7": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/691f0b4b-a2b3-47d6-b5ba-6bf503340e10",
            "firstName": "Joseph P.",
            "lastName": "DeStefano",
            "fullName": "Joe DeStefano",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/003/headshot/003.jpg?hst=1660072569",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-NY-3-lower-ocd-person-691f0b4b-a2b3-47d6-b5ba-6bf503340e10",
              "builtID": "ny-lower-3",
              "externalID": "ocd-person/691f0b4b-a2b3-47d6-b5ba-6bf503340e10",
              "geometry": null
            },
            "contactInfo": {
              "email": "destefanoj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3245 Route 112 Building 2, Suite 6, Medford, NY 11763",
              "phone1": null,
              "phone2": "631-207-0073",
              "fax1": null,
              "fax2": "631-732-1798",
              "twitter": "dacomish",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Joe-DeStefano",
                "https://nyassembly.gov/mem/Joe-DeStefano"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-3-lower-ocd-person-691f0b4b-a2b3-47d6-b5ba-6bf503340e10": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/fe77d15b-2a70-41b0-9b1f-88ab55d5234f",
            "firstName": "Edward Albert",
            "lastName": "Flood",
            "fullName": "Ed Flood",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://assembly.state.ny.us/write/upload/member_files/004/headshot/004.jpg?hst=1672560813",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-NY-4-lower-ocd-person-fe77d15b-2a70-41b0-9b1f-88ab55d5234f",
              "builtID": "ny-lower-4",
              "externalID": "ocd-person/fe77d15b-2a70-41b0-9b1f-88ab55d5234f",
              "geometry": null
            },
            "contactInfo": {
              "email": "floode@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "149 Main St., East Setauket, NY 11733",
              "phone1": null,
              "phone2": "631-751-3094",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Ed-Flood",
                "https://nyassembly.gov/mem/Ed-Flood"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-4-lower-ocd-person-fe77d15b-2a70-41b0-9b1f-88ab55d5234f": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/70d249e7-22a2-4536-892f-a61aa22723e4",
            "firstName": "Douglas M.",
            "lastName": "Smith",
            "fullName": "Doug Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/005/headshot/005.jpg?hst=1660072659",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-NY-5-lower-ocd-person-70d249e7-22a2-4536-892f-a61aa22723e4",
              "builtID": "ny-lower-5",
              "externalID": "ocd-person/70d249e7-22a2-4536-892f-a61aa22723e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "smithd@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4155 Veterans Memorial Highway Suite 9, Ronkonkoma, NY 11779",
              "phone1": null,
              "phone2": "631-585-0230",
              "fax1": null,
              "fax2": "631-585-0310",
              "twitter": "DougSmithNY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Doug-Smith",
                "https://nyassembly.gov/mem/Doug-Smith"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-5-lower-ocd-person-70d249e7-22a2-4536-892f-a61aa22723e4": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/efcd9b6f-4657-41aa-94a1-9b048c7043cf",
            "firstName": "Philip",
            "lastName": "Ramos",
            "fullName": "Phil Ramos",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/006/headshot/006.jpg?hst=1660072681",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-NY-6-lower-ocd-person-efcd9b6f-4657-41aa-94a1-9b048c7043cf",
              "builtID": "ny-lower-6",
              "externalID": "ocd-person/efcd9b6f-4657-41aa-94a1-9b048c7043cf",
              "geometry": null
            },
            "contactInfo": {
              "email": "ramosp@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1010 Suffolk Ave., Brentwood, NY 11717",
              "phone1": null,
              "phone2": "631-435-3214",
              "fax1": null,
              "fax2": null,
              "twitter": "philramos6AD",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Phil-Ramos",
                "https://nyassembly.gov/mem/phil-ramos"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-6-lower-ocd-person-efcd9b6f-4657-41aa-94a1-9b048c7043cf": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/603a62e0-5ca7-4259-b071-db52bc9f2569",
            "firstName": "Jarett C.",
            "lastName": "Gandolfo",
            "fullName": "Jarett Gandolfo",
            "gender": "Male",
            "party": "republican/conservative/independence",
            "image": "https://assembly.state.ny.us/write/upload/member_files/007/headshot/007.jpg?hst=1660072714",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-NY-7-lower-ocd-person-603a62e0-5ca7-4259-b071-db52bc9f2569",
              "builtID": "ny-lower-7",
              "externalID": "ocd-person/603a62e0-5ca7-4259-b071-db52bc9f2569",
              "geometry": null
            },
            "contactInfo": {
              "email": "gandolfoj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "859 Montauk Highway Suite 1, Bayport, NY 11705",
              "phone1": null,
              "phone2": "631-589-0348",
              "fax1": null,
              "fax2": "631-589-0487",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Jarett-Gandolfo",
                "https://nyassembly.gov/mem/Jarett-Gandolfo"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-7-lower-ocd-person-603a62e0-5ca7-4259-b071-db52bc9f2569": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/7d944986-094e-4116-871c-d902074d70a5",
            "firstName": "Michael J.",
            "lastName": "Fitzpatrick",
            "fullName": "Mike Fitzpatrick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/008/headshot/008.jpg?hst=1660072739",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-NY-8-lower-ocd-person-7d944986-094e-4116-871c-d902074d70a5",
              "builtID": "ny-lower-8",
              "externalID": "ocd-person/7d944986-094e-4116-871c-d902074d70a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "fitzpatrickm@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "285 Middle Country Road Suite 202, Smithtown, NY 11787",
              "phone1": null,
              "phone2": "631-724-2929",
              "fax1": null,
              "fax2": "631-724-3024",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Michael-J-Fitzpatrick",
                "https://nyassembly.gov/mem/michael-j-fitzpatrick"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-8-lower-ocd-person-7d944986-094e-4116-871c-d902074d70a5": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/d72babb1-84cc-49d4-8b91-7945b7b9abf5",
            "firstName": "Michael A.",
            "lastName": "Durso",
            "fullName": "Michael Durso",
            "gender": "Male",
            "party": "republican/conservative/independence/libertarian",
            "image": "https://assembly.state.ny.us/write/upload/member_files/009/headshot/009.jpg?hst=1660072763",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-NY-9-lower-ocd-person-d72babb1-84cc-49d4-8b91-7945b7b9abf5",
              "builtID": "ny-lower-9",
              "externalID": "ocd-person/d72babb1-84cc-49d4-8b91-7945b7b9abf5",
              "geometry": null
            },
            "contactInfo": {
              "email": "dursom@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "512 Park Blvd., Massapequa Park, NY 11762",
              "phone1": null,
              "phone2": "516-541-4598",
              "fax1": null,
              "fax2": "516-541-4625",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Michael-Durso",
                "https://nyassembly.gov/mem/Michael-Durso"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-9-lower-ocd-person-d72babb1-84cc-49d4-8b91-7945b7b9abf5": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/a6956643-33f7-4b99-bcfb-fd44f9d92481",
            "firstName": "Steven H.",
            "lastName": "Stern",
            "fullName": "Steve Stern",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/010/headshot/010.jpg?hst=1660072786",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-NY-10-lower-ocd-person-a6956643-33f7-4b99-bcfb-fd44f9d92481",
              "builtID": "ny-lower-10",
              "externalID": "ocd-person/a6956643-33f7-4b99-bcfb-fd44f9d92481",
              "geometry": null
            },
            "contactInfo": {
              "email": "sterns@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "95 Broad Hollow Road Suite 100, Melville, NY 11747",
              "phone1": null,
              "phone2": "631-271-8025",
              "fax1": null,
              "fax2": null,
              "twitter": "SteveSternNY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Steve-Stern",
                "https://nyassembly.gov/mem/Steve-Stern"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-10-lower-ocd-person-a6956643-33f7-4b99-bcfb-fd44f9d92481": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/3fb3ae76-a801-45b5-bbdd-7a6a3b86cb90",
            "firstName": "Kimberly",
            "lastName": "Jean-Pierre",
            "fullName": "Kimberly Jean-Pierre",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/011/headshot/011.jpg?hst=1675708782",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-NY-11-lower-ocd-person-3fb3ae76-a801-45b5-bbdd-7a6a3b86cb90",
              "builtID": "ny-lower-11",
              "externalID": "ocd-person/3fb3ae76-a801-45b5-bbdd-7a6a3b86cb90",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeanpierrek@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "640 W. Montauk Highway, Lindenhurst, NY 11757",
              "phone1": null,
              "phone2": "631-957-2087",
              "fax1": null,
              "fax2": "631-957-2998",
              "twitter": "Kimjeanpierre",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Kimberly-Jean-Pierre",
                "https://nyassembly.gov/mem/Kimberly-Jean-Pierre"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-11-lower-ocd-person-3fb3ae76-a801-45b5-bbdd-7a6a3b86cb90": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/c54d76b7-c535-44f9-b534-d20d689f594e",
            "firstName": "Keith P.",
            "lastName": "Brown",
            "fullName": "Keith Brown",
            "gender": "Male",
            "party": "republican/conservative/independence",
            "image": "https://assembly.state.ny.us/write/upload/member_files/012/headshot/012.jpg?hst=1660072856",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-NY-12-lower-ocd-person-c54d76b7-c535-44f9-b534-d20d689f594e",
              "builtID": "ny-lower-12",
              "externalID": "ocd-person/c54d76b7-c535-44f9-b534-d20d689f594e",
              "geometry": null
            },
            "contactInfo": {
              "email": "brownk@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6080 Jericho Turnpike Suite 310, Commack, NY 11725",
              "phone1": null,
              "phone2": "631-261-4151",
              "fax1": null,
              "fax2": "631-261-2992",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Keith-P-Brown",
                "https://nyassembly.gov/mem/Keith-P-Brown"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-12-lower-ocd-person-c54d76b7-c535-44f9-b534-d20d689f594e": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/3bb6eebd-d57b-4592-bc52-9ce338df9ccf",
            "firstName": "Charles David",
            "lastName": "Lavine",
            "fullName": "Charles Lavine",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/013/headshot/013.jpg?hst=1660072879",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-NY-13-lower-ocd-person-3bb6eebd-d57b-4592-bc52-9ce338df9ccf",
              "builtID": "ny-lower-13",
              "externalID": "ocd-person/3bb6eebd-d57b-4592-bc52-9ce338df9ccf",
              "geometry": null
            },
            "contactInfo": {
              "email": "lavinec@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1 School St. Suite 303-B, Glen Cove, NY 11542",
              "phone1": null,
              "phone2": "516-676-0050",
              "fax1": null,
              "fax2": "516-676-0071",
              "twitter": "CharlesLavineNY",
              "youtube": null,
              "instagram": null,
              "facebook": "57599145372",
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Charles-D-Lavine",
                "https://nyassembly.gov/mem/charles-d-lavine"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-13-lower-ocd-person-3bb6eebd-d57b-4592-bc52-9ce338df9ccf": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/9f3ae1c5-651b-4cc8-a1ce-9e94c414a220",
            "firstName": "David G.",
            "lastName": "McDonough",
            "fullName": "Dave McDonough",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/014/headshot/014.jpg?hst=1660072904",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-NY-14-lower-ocd-person-9f3ae1c5-651b-4cc8-a1ce-9e94c414a220",
              "builtID": "ny-lower-14",
              "externalID": "ocd-person/9f3ae1c5-651b-4cc8-a1ce-9e94c414a220",
              "geometry": null
            },
            "contactInfo": {
              "email": "mcdonoughd@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "404 Bedford Ave., Bellmore, NY 11710",
              "phone1": null,
              "phone2": "516-409-2070",
              "fax1": null,
              "fax2": "516-409-2073",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "dave.mcdonough.710?fref=ts&ref=br_tf",
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/David-G-McDonough",
                "https://nyassembly.gov/mem/david-g-mcdonough"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-14-lower-ocd-person-9f3ae1c5-651b-4cc8-a1ce-9e94c414a220": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/aa1af232-61bb-427c-b30e-81169c18d15d",
            "firstName": "Jake",
            "lastName": "Blumencranz",
            "fullName": "Jake Blumencranz",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://assembly.state.ny.us/write/upload/member_files/015/headshot/015.jpg?hst=1671723761",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-NY-15-lower-ocd-person-aa1af232-61bb-427c-b30e-81169c18d15d",
              "builtID": "ny-lower-15",
              "externalID": "ocd-person/aa1af232-61bb-427c-b30e-81169c18d15d",
              "geometry": null
            },
            "contactInfo": {
              "email": "blumencranzj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "111 W. Old Country Road Suite 202, Hicksville, NY 11801",
              "phone1": null,
              "phone2": "516-937-3571",
              "fax1": null,
              "fax2": "516-937-3632",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Jake-Blumencranz",
                "https://nyassembly.gov/mem/Jake-Blumencranz"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-15-lower-ocd-person-aa1af232-61bb-427c-b30e-81169c18d15d": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/c291848d-0879-4c0a-ae66-bc4e22a03f39",
            "firstName": "Gina L.",
            "lastName": "Sillitti",
            "fullName": "Gina Sillitti",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/016/headshot/016.jpg?hst=1660072953",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-NY-16-lower-ocd-person-c291848d-0879-4c0a-ae66-bc4e22a03f39",
              "builtID": "ny-lower-16",
              "externalID": "ocd-person/c291848d-0879-4c0a-ae66-bc4e22a03f39",
              "geometry": null
            },
            "contactInfo": {
              "email": "sillittig@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "44 S. Bayles Ave. Suite 200, Port Washington, NY 11050",
              "phone1": null,
              "phone2": "516-482-6966",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Gina-Sillitti",
                "https://assembly.state.ny.us/mem/Gina-L-Sillitti",
                "https://nyassembly.gov/mem/Gina-L-Sillitti/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-16-lower-ocd-person-c291848d-0879-4c0a-ae66-bc4e22a03f39": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/d0ecfe0a-9f41-4deb-9829-7c1c16a7c498",
            "firstName": "John K.",
            "lastName": "Mikulin",
            "fullName": "John Mikulin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/017/headshot/017.jpg?hst=1678734317",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-NY-17-lower-ocd-person-d0ecfe0a-9f41-4deb-9829-7c1c16a7c498",
              "builtID": "ny-lower-17",
              "externalID": "ocd-person/d0ecfe0a-9f41-4deb-9829-7c1c16a7c498",
              "geometry": null
            },
            "contactInfo": {
              "email": "mikulinj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1975 Hempstead Turnpike Suite 202, East Meadow, NY 11554",
              "phone1": null,
              "phone2": "516-228-4960",
              "fax1": null,
              "fax2": "516-228-4963",
              "twitter": "JohnMikulin",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/John-K-Mikulin",
                "https://nyassembly.gov/mem/John-K-Mikulin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-17-lower-ocd-person-d0ecfe0a-9f41-4deb-9829-7c1c16a7c498": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/4a1f37a7-5196-4193-abe6-c26869f47831",
            "firstName": "Taylor",
            "lastName": "Darling",
            "fullName": "Taylor Darling",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/018/headshot/018.jpg?hst=1660072988",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-NY-18-lower-ocd-person-4a1f37a7-5196-4193-abe6-c26869f47831",
              "builtID": "ny-lower-18",
              "externalID": "ocd-person/4a1f37a7-5196-4193-abe6-c26869f47831",
              "geometry": null
            },
            "contactInfo": {
              "email": "darlingt@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "33 Front St. Suite 302 & 303, Hempstead, NY 11550",
              "phone1": null,
              "phone2": "516-489-6610",
              "fax1": null,
              "fax2": null,
              "twitter": "IamTDarling",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Taylor-Darling",
                "https://nyassembly.gov/mem/Taylor-Darling/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-18-lower-ocd-person-4a1f37a7-5196-4193-abe6-c26869f47831": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/42d68829-d969-44d1-9e22-042a6e787dc5",
            "firstName": "Edward P.",
            "lastName": "Ra",
            "fullName": "Ed Ra",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/019/headshot/019.jpg?hst=1660073018",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-NY-19-lower-ocd-person-42d68829-d969-44d1-9e22-042a6e787dc5",
              "builtID": "ny-lower-19",
              "externalID": "ocd-person/42d68829-d969-44d1-9e22-042a6e787dc5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rae@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "825 E. Gate Blvd. Suite 207, Garden City, NY 11530",
              "phone1": null,
              "phone2": "516-535-4095",
              "fax1": null,
              "fax2": "516-535-4097",
              "twitter": "EdwardRa19",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Edward-P-Ra",
                "https://nyassembly.gov/mem/edward-p-ra"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-19-lower-ocd-person-42d68829-d969-44d1-9e22-042a6e787dc5": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/38ed0365-1654-4a2d-bb23-4428736df57f",
            "firstName": "Eric",
            "lastName": "Brown",
            "fullName": "Ari Brown",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/020/headshot/020.jpg?hst=1660073031",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-NY-20-lower-ocd-person-38ed0365-1654-4a2d-bb23-4428736df57f",
              "builtID": "ny-lower-20",
              "externalID": "ocd-person/38ed0365-1654-4a2d-bb23-4428736df57f",
              "geometry": null
            },
            "contactInfo": {
              "email": "browna@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "525 Chestnut St. Suite 103, Cedarhurst, NY 11516",
              "phone1": null,
              "phone2": "516-431-0500",
              "fax1": null,
              "fax2": "516-295-2498",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Ari-Brown",
                "https://nyassembly.gov/mem/Ari-Brown"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-20-lower-ocd-person-38ed0365-1654-4a2d-bb23-4428736df57f": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/5d0016e7-6394-4abc-81b9-226723acd5c7",
            "firstName": "Brian F.",
            "lastName": "Curran",
            "fullName": "Brian Curran",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/021/headshot/021.jpg?hst=1672571212",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-NY-21-lower-ocd-person-5d0016e7-6394-4abc-81b9-226723acd5c7",
              "builtID": "ny-lower-21",
              "externalID": "ocd-person/5d0016e7-6394-4abc-81b9-226723acd5c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "curranb@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "74 N. Village Ave., Rockville Centre, NY 11570",
              "phone1": null,
              "phone2": "516-561-8216",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Brian-Curran",
                "https://assembly.state.ny.us/mem/brian-curran"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-21-lower-ocd-person-5d0016e7-6394-4abc-81b9-226723acd5c7": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/2cb280b3-0aa9-4a76-abfd-3118e0d96a08",
            "firstName": "Michaelle C.",
            "lastName": "Solages",
            "fullName": "Michaelle Solages",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/022/headshot/022.jpg?hst=1660073080",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-NY-22-lower-ocd-person-2cb280b3-0aa9-4a76-abfd-3118e0d96a08",
              "builtID": "ny-lower-22",
              "externalID": "ocd-person/2cb280b3-0aa9-4a76-abfd-3118e0d96a08",
              "geometry": null
            },
            "contactInfo": {
              "email": "solagesm@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "33 N. Central Ave., Valley Stream, NY 11580",
              "phone1": null,
              "phone2": "516-599-2972",
              "fax1": null,
              "fax2": "516-599-3768",
              "twitter": "solagesNY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Michaelle-C-Solages",
                "https://nyassembly.gov/mem/Michaelle-C-Solages"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-22-lower-ocd-person-2cb280b3-0aa9-4a76-abfd-3118e0d96a08": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/b4246863-b244-4d37-acae-0600d4b429b4",
            "firstName": "Stacey G.",
            "lastName": "Pheffer Amato",
            "fullName": "Stacey Pheffer Amato",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/023/headshot/023.jpg?hst=1660073108",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-NY-23-lower-ocd-person-b4246863-b244-4d37-acae-0600d4b429b4",
              "builtID": "ny-lower-23",
              "externalID": "ocd-person/b4246863-b244-4d37-acae-0600d4b429b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "amatos@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "9516 Rockaway Beach Blvd., Rockaway Beach, NY 11693",
              "phone1": null,
              "phone2": "718-945-9550",
              "fax1": null,
              "fax2": "718-945-9549",
              "twitter": "Stacey23AD",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Stacey-Pheffer-Amato",
                "https://nyassembly.gov/mem/Stacey-Pheffer-Amato"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-23-lower-ocd-person-b4246863-b244-4d37-acae-0600d4b429b4": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/df0c2fdc-3cf4-4c89-849a-b62ac2d7d2d4",
            "firstName": "David Ira",
            "lastName": "Weprin",
            "fullName": "David Weprin",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/024/headshot/024.jpg?hst=1660073135",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-NY-24-lower-ocd-person-df0c2fdc-3cf4-4c89-849a-b62ac2d7d2d4",
              "builtID": "ny-lower-24",
              "externalID": "ocd-person/df0c2fdc-3cf4-4c89-849a-b62ac2d7d2d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "weprind@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 5, 111-12 Atlantic Ave., Richmond Hill, NY 11419",
              "phone1": null,
              "phone2": "718-805-2381",
              "fax1": null,
              "fax2": "718-805-2384",
              "twitter": "DavidWeprin",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/David-I-Weprin",
                "https://nyassembly.gov/mem/david-i-weprin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-24-lower-ocd-person-df0c2fdc-3cf4-4c89-849a-b62ac2d7d2d4": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/2dfa3301-d40b-4d1a-89c7-62a2b965bace",
            "firstName": "Nily",
            "lastName": "Rozic",
            "fullName": "Nily Rozic",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/025/headshot/025.jpg?hst=1660073163",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-NY-25-lower-ocd-person-2dfa3301-d40b-4d1a-89c7-62a2b965bace",
              "builtID": "ny-lower-25",
              "externalID": "ocd-person/2dfa3301-d40b-4d1a-89c7-62a2b965bace",
              "geometry": null
            },
            "contactInfo": {
              "email": "rozicn@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "159-16 Union Turnpike, Flushing, NY 11366",
              "phone1": null,
              "phone2": "718-820-0241",
              "fax1": null,
              "fax2": null,
              "twitter": "nily",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Nily-Rozic",
                "https://nyassembly.gov/mem/Nily-Rozic"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-25-lower-ocd-person-2dfa3301-d40b-4d1a-89c7-62a2b965bace": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/b7e93c8c-9a4b-4678-8d6a-effce9a76938",
            "firstName": "Edward Charles",
            "lastName": "Braunstein",
            "fullName": "Ed Braunstein",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/026/headshot/026.jpg?hst=1660073175",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-NY-26-lower-ocd-person-b7e93c8c-9a4b-4678-8d6a-effce9a76938",
              "builtID": "ny-lower-26",
              "externalID": "ocd-person/b7e93c8c-9a4b-4678-8d6a-effce9a76938",
              "geometry": null
            },
            "contactInfo": {
              "email": "braunsteine@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "213-33 39th Ave., Suite 238, Bayside, NY 11361",
              "phone1": null,
              "phone2": "718-357-3588",
              "fax1": null,
              "fax2": "718-357-5947",
              "twitter": "edbraunstein",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Edward-C-Braunstein",
                "https://nyassembly.gov/mem/edward-c-braunstein"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-26-lower-ocd-person-b7e93c8c-9a4b-4678-8d6a-effce9a76938": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/4d3e092b-0cef-41ca-89a0-e73e1f9fda01",
            "firstName": "Samuel T.",
            "lastName": "Berger",
            "fullName": "Sam Berger",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/027/headshot/027.jpg?hst=1695676852",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-NY-27-lower-ocd-person-4d3e092b-0cef-41ca-89a0-e73e1f9fda01",
              "builtID": "ny-lower-27",
              "externalID": "ocd-person/4d3e092b-0cef-41ca-89a0-e73e1f9fda01",
              "geometry": null
            },
            "contactInfo": {
              "email": "bergers@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "159-06 71st Ave., Flushing, NY 11365",
              "phone1": null,
              "phone2": "718-969-1508",
              "fax1": null,
              "fax2": "718-969-8326",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Sam-Berger",
                "https://nyassembly.gov/mem/Sam-Berger"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-27-lower-ocd-person-4d3e092b-0cef-41ca-89a0-e73e1f9fda01": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/2a9aad09-337f-4fd0-8e87-70afd78ac8cc",
            "firstName": "Andrew D.",
            "lastName": "Hevesi",
            "fullName": "Andrew Hevesi",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/028/headshot/028.jpg?hst=1660073205",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-NY-28-lower-ocd-person-2a9aad09-337f-4fd0-8e87-70afd78ac8cc",
              "builtID": "ny-lower-28",
              "externalID": "ocd-person/2a9aad09-337f-4fd0-8e87-70afd78ac8cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "hevesia@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "70-50 Austin St. Suite 118, Forest Hills, NY 11375",
              "phone1": null,
              "phone2": "718-263-5595",
              "fax1": null,
              "fax2": null,
              "twitter": "AM_AndrewHevesi",
              "youtube": null,
              "instagram": null,
              "facebook": "AssemblymanAndrewHevesi",
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Andrew-Hevesi",
                "https://nyassembly.gov/mem/andrew-hevesi"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-28-lower-ocd-person-2a9aad09-337f-4fd0-8e87-70afd78ac8cc": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/2196fbda-e731-4e6b-a221-7bf5e25b8b95",
            "firstName": "Alicia L.",
            "lastName": "Hyndman",
            "fullName": "Alicia Hyndman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/029/headshot/029.jpg?hst=1660073230",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-NY-29-lower-ocd-person-2196fbda-e731-4e6b-a221-7bf5e25b8b95",
              "builtID": "ny-lower-29",
              "externalID": "ocd-person/2196fbda-e731-4e6b-a221-7bf5e25b8b95",
              "geometry": null
            },
            "contactInfo": {
              "email": "hyndmana@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "232-06A Merrick Blvd., Springfield Gardens, NY 11413",
              "phone1": null,
              "phone2": "718-723-5412",
              "fax1": null,
              "fax2": null,
              "twitter": "AliciaHyndman",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Alicia-Hyndman",
                "https://nyassembly.gov/mem/Alicia-Hyndman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-29-lower-ocd-person-2196fbda-e731-4e6b-a221-7bf5e25b8b95": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/b3a5dc39-1c55-48b7-986c-d36a3db0917e",
            "firstName": "Steven B.",
            "lastName": "Raga",
            "fullName": "Steven Raga",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/030/headshot/030.jpg?hst=1672571626",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-NY-30-lower-ocd-person-b3a5dc39-1c55-48b7-986c-d36a3db0917e",
              "builtID": "ny-lower-30",
              "externalID": "ocd-person/b3a5dc39-1c55-48b7-986c-d36a3db0917e",
              "geometry": null
            },
            "contactInfo": {
              "email": "ragas@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "55-19 69th St., Maspeth, NY 11378",
              "phone1": null,
              "phone2": "718-651-3185",
              "fax1": null,
              "fax2": "718-651-3027",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Steven-Raga",
                "https://nyassembly.gov/mem/Steven-Raga"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-30-lower-ocd-person-b3a5dc39-1c55-48b7-986c-d36a3db0917e": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/cf330444-ddcb-44aa-882d-f1488c42533e",
            "firstName": "Khaleel M.",
            "lastName": "Anderson",
            "fullName": "Khaleel Anderson",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/031/headshot/031.jpg?hst=1668023367",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-NY-31-lower-ocd-person-cf330444-ddcb-44aa-882d-f1488c42533e",
              "builtID": "ny-lower-31",
              "externalID": "ocd-person/cf330444-ddcb-44aa-882d-f1488c42533e",
              "geometry": null
            },
            "contactInfo": {
              "email": "andersonk@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "19-31 Mott Ave. Suite 301, Far Rockaway, NY 11691",
              "phone1": null,
              "phone2": "718-327-1845",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Khaleel-M-Anderson",
                "https://nyassembly.gov/mem/Khaleel-M-Anderson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-31-lower-ocd-person-cf330444-ddcb-44aa-882d-f1488c42533e": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/96b77d8c-c15b-4881-b9c0-3f48cfd41572",
            "firstName": "Vivian E.",
            "lastName": "Cook",
            "fullName": "Vivian Cook",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/032/headshot/032.jpg?hst=1660073288",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-NY-32-lower-ocd-person-96b77d8c-c15b-4881-b9c0-3f48cfd41572",
              "builtID": "ny-lower-32",
              "externalID": "ocd-person/96b77d8c-c15b-4881-b9c0-3f48cfd41572",
              "geometry": null
            },
            "contactInfo": {
              "email": "cookv@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "142-15 Rockaway Blvd, Jamaica, NY 11436",
              "phone1": null,
              "phone2": "718-322-3975",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Vivian-E-Cook",
                "https://nyassembly.gov/mem/vivian-e-cook"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-32-lower-ocd-person-96b77d8c-c15b-4881-b9c0-3f48cfd41572": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/b8a0fb1e-422b-44b7-846e-82d2998a0bf5",
            "firstName": "Clyde",
            "lastName": "Vanel",
            "fullName": "Clyde Vanel",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/033/headshot/033.jpg?hst=1660073302",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-NY-33-lower-ocd-person-b8a0fb1e-422b-44b7-846e-82d2998a0bf5",
              "builtID": "ny-lower-33",
              "externalID": "ocd-person/b8a0fb1e-422b-44b7-846e-82d2998a0bf5",
              "geometry": null
            },
            "contactInfo": {
              "email": "vanelc@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "97-01 Springfield Blvd., Queens Village, NY 11429",
              "phone1": null,
              "phone2": "718-479-2333",
              "fax1": null,
              "fax2": "718-464-7128",
              "twitter": "clydevanel",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Clyde-Vanel",
                "https://nyassembly.gov/mem/Clyde-Vanel"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-33-lower-ocd-person-b8a0fb1e-422b-44b7-846e-82d2998a0bf5": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/5a115673-aa3c-4518-94bc-b2a5dadeb8af",
            "firstName": "Jessica",
            "lastName": "Gonzalez-Rojas",
            "fullName": "Jessica González-Rojas",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/034/headshot/034.jpg?hst=1660073319",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-NY-34-lower-ocd-person-5a115673-aa3c-4518-94bc-b2a5dadeb8af",
              "builtID": "ny-lower-34",
              "externalID": "ocd-person/5a115673-aa3c-4518-94bc-b2a5dadeb8af",
              "geometry": null
            },
            "contactInfo": {
              "email": "gonzalezrojasj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "75-35 31st Ave. Suite 206B (2nd Floor), East Elmhurst, NY 11370",
              "phone1": null,
              "phone2": "718-457-0384",
              "fax1": null,
              "fax2": "718-335-8254",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Jessica-Gonzalez-Rojas",
                "https://nyassembly.gov/mem/Jessica-Gonzalez-Rojas"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-34-lower-ocd-person-5a115673-aa3c-4518-94bc-b2a5dadeb8af": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/81041082-f06b-42c0-acb4-0747a81ca889",
            "firstName": "Jeffrion L.",
            "lastName": "Aubry",
            "fullName": "Jeffrion Aubry",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/035/headshot/035.jpg?hst=1660073338",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-NY-35-lower-ocd-person-81041082-f06b-42c0-acb4-0747a81ca889",
              "builtID": "ny-lower-35",
              "externalID": "ocd-person/81041082-f06b-42c0-acb4-0747a81ca889",
              "geometry": null
            },
            "contactInfo": {
              "email": "aubryj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "98-09 Northern Blvd., Corona, NY 11368",
              "phone1": null,
              "phone2": "718-457-3615",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Jeffrion-L-Aubry",
                "https://nyassembly.gov/mem/jeffrion-l-aubry"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-35-lower-ocd-person-81041082-f06b-42c0-acb4-0747a81ca889": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/7bf43536-ab00-4cce-84c2-e2cc1c418d9c",
            "firstName": "Zohran Kwame",
            "lastName": "Mamdani",
            "fullName": "Zohran Mamdani",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/036/headshot/036.jpg?hst=1660073357",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-NY-36-lower-ocd-person-7bf43536-ab00-4cce-84c2-e2cc1c418d9c",
              "builtID": "ny-lower-36",
              "externalID": "ocd-person/7bf43536-ab00-4cce-84c2-e2cc1c418d9c",
              "geometry": null
            },
            "contactInfo": {
              "email": "mamdaniz@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "24-08 32nd St. Suite 1002A, Astoria, NY 11102",
              "phone1": null,
              "phone2": "718-545-3889",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Zohran-Mamdani",
                "https://assembly.state.ny.us/mem/Zohran-K-Mamdani",
                "https://nyassembly.gov/mem/Zohran-K-Mamdani"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-36-lower-ocd-person-7bf43536-ab00-4cce-84c2-e2cc1c418d9c": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/ce3068b0-b101-4064-af9e-72a5ad1fbb47",
            "firstName": "Juan D.",
            "lastName": "Ardila",
            "fullName": "Juan Ardila",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/037/headshot/037.jpg?hst=1674494712",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-NY-37-lower-ocd-person-ce3068b0-b101-4064-af9e-72a5ad1fbb47",
              "builtID": "ny-lower-37",
              "externalID": "ocd-person/ce3068b0-b101-4064-af9e-72a5ad1fbb47",
              "geometry": null
            },
            "contactInfo": {
              "email": "ardilaj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "45-10 Skillman Ave. 1st Floor, Sunnyside, NY 11104",
              "phone1": null,
              "phone2": "718-784-3194",
              "fax1": null,
              "fax2": "718-472-0648",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Juan-Ardila",
                "https://nyassembly.gov/mem/Juan-Ardila"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-37-lower-ocd-person-ce3068b0-b101-4064-af9e-72a5ad1fbb47": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/029fb490-649e-4dbf-b7fc-6cf46ea433ff",
            "firstName": "Jenifer",
            "lastName": "Rajkumar",
            "fullName": "Jenifer Rajkumar",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/038/headshot/038.jpg?hst=1660073381",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-NY-38-lower-ocd-person-029fb490-649e-4dbf-b7fc-6cf46ea433ff",
              "builtID": "ny-lower-38",
              "externalID": "ocd-person/029fb490-649e-4dbf-b7fc-6cf46ea433ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "rajkumarj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "83-91 Woodhaven Blvd., Woodhaven, NY 11421",
              "phone1": null,
              "phone2": "718-805-0950",
              "fax1": null,
              "fax2": "718-805-0953",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Jenifer-Rajkumar",
                "https://nyassembly.gov/mem/Jenifer-Rajkumar"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-38-lower-ocd-person-029fb490-649e-4dbf-b7fc-6cf46ea433ff": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/864e417d-df2e-4207-83e4-98450555f48a",
            "firstName": "Catalina",
            "lastName": "Cruz",
            "fullName": "Catalina Cruz",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/039/headshot/039.jpg?hst=1683131280",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-NY-39-lower-ocd-person-864e417d-df2e-4207-83e4-98450555f48a",
              "builtID": "ny-lower-39",
              "externalID": "ocd-person/864e417d-df2e-4207-83e4-98450555f48a",
              "geometry": null
            },
            "contactInfo": {
              "email": "cruzc@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "41-40 Junction Blvd., Corona, NY 11368",
              "phone1": null,
              "phone2": "718-458-5367",
              "fax1": null,
              "fax2": "718-478-2371",
              "twitter": "CatalinaCruzNY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Catalina-Cruz",
                "https://nyassembly.gov/mem/Catalina-Cruz"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-39-lower-ocd-person-864e417d-df2e-4207-83e4-98450555f48a": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/f8732ad5-c692-4bab-bbaa-fb772a031a5d",
            "firstName": "Ronald Joseph",
            "lastName": "Kim",
            "fullName": "Ron Kim",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/040/headshot/040.jpg?hst=1660073405",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-NY-40-lower-ocd-person-f8732ad5-c692-4bab-bbaa-fb772a031a5d",
              "builtID": "ny-lower-40",
              "externalID": "ocd-person/f8732ad5-c692-4bab-bbaa-fb772a031a5d",
              "geometry": null
            },
            "contactInfo": {
              "email": "kimr@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "136-20 38th Ave. Suite 10A, Flushing, NY 11354",
              "phone1": null,
              "phone2": "718-939-0195",
              "fax1": null,
              "fax2": "718-939-1238",
              "twitter": "rontkim",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Ron-Kim",
                "https://nyassembly.gov/mem/Ron-Kim"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-40-lower-ocd-person-f8732ad5-c692-4bab-bbaa-fb772a031a5d": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/d796d2c5-66a3-44b6-9ef8-39beeebce9b7",
            "firstName": "Helene E.",
            "lastName": "Weinstein",
            "fullName": "Helene Weinstein",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/041/headshot/041.jpg?hst=1660073434",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-NY-41-lower-ocd-person-d796d2c5-66a3-44b6-9ef8-39beeebce9b7",
              "builtID": "ny-lower-41",
              "externalID": "ocd-person/d796d2c5-66a3-44b6-9ef8-39beeebce9b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "weinsth@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3520 Nostrand Ave., Brooklyn, NY 11229",
              "phone1": null,
              "phone2": "718-648-4700",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Helene-E-Weinstein",
                "https://nyassembly.gov/mem/helene-e-weinstein"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-41-lower-ocd-person-d796d2c5-66a3-44b6-9ef8-39beeebce9b7": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/b2811e4c-0b05-4775-a778-0539d22f1a6c",
            "firstName": "Rodneyse",
            "lastName": "Bichotte Hermelyn",
            "fullName": "Rodneyse Bichotte Hermelyn",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/042/headshot/042.jpg?hst=1660073454",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-NY-42-lower-ocd-person-b2811e4c-0b05-4775-a778-0539d22f1a6c",
              "builtID": "ny-lower-42",
              "externalID": "ocd-person/b2811e4c-0b05-4775-a778-0539d22f1a6c",
              "geometry": null
            },
            "contactInfo": {
              "email": "bichotter@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1312 Flatbush Ave., Brooklyn, NY 11210",
              "phone1": null,
              "phone2": "718-940-0428",
              "fax1": null,
              "fax2": "718-940-0154",
              "twitter": "NYSBichotteHerm",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Rodneyse-Bichotte-Hermelyn",
                "https://nyassembly.gov/mem/Rodneyse-Bichotte-Hermelyn/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-42-lower-ocd-person-b2811e4c-0b05-4775-a778-0539d22f1a6c": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/27a087c4-fad6-4245-b680-20aff5c3251c",
            "firstName": "Brian-Christopher A.",
            "lastName": "Cunningham",
            "fullName": "Brian Cunningham",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/043/headshot/043.jpg?hst=1660073468",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-NY-43-lower-ocd-person-27a087c4-fad6-4245-b680-20aff5c3251c",
              "builtID": "ny-lower-43",
              "externalID": "ocd-person/27a087c4-fad6-4245-b680-20aff5c3251c",
              "geometry": null
            },
            "contactInfo": {
              "email": "cunninghamba@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "249 Empire Blvd., Brooklyn, NY 11225",
              "phone1": null,
              "phone2": "718-771-3105",
              "fax1": null,
              "fax2": "718-771-3276",
              "twitter": "NYAMCunningham",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Brian-Cunningham",
                "https://nyassembly.gov/mem/Brian-Cunningham"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-43-lower-ocd-person-27a087c4-fad6-4245-b680-20aff5c3251c": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/a0a7d4b0-2624-4560-8129-dbca30c4d4a1",
            "firstName": "Robert C.",
            "lastName": "Carroll",
            "fullName": "Robert Carroll",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/044/headshot/044.jpg?hst=1672953348",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-NY-44-lower-ocd-person-a0a7d4b0-2624-4560-8129-dbca30c4d4a1",
              "builtID": "ny-lower-44",
              "externalID": "ocd-person/a0a7d4b0-2624-4560-8129-dbca30c4d4a1",
              "geometry": null
            },
            "contactInfo": {
              "email": "carrollr@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "416 7th Ave., Brooklyn, NY 11215",
              "phone1": null,
              "phone2": "718-788-7221",
              "fax1": null,
              "fax2": "718-965-9378",
              "twitter": "Bobby4Brooklyn",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Robert-C-Carroll",
                "https://nyassembly.gov/mem/Robert-C-Carroll"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-44-lower-ocd-person-a0a7d4b0-2624-4560-8129-dbca30c4d4a1": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/3e2b0b14-23fb-4b1e-9d9f-48b97662ae1a",
            "firstName": "Mikhail",
            "lastName": "Novakhov",
            "fullName": "Misha Novakhov",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://assembly.state.ny.us/write/upload/member_files/045/headshot/045.jpg?hst=1672564058",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-NY-45-lower-ocd-person-3e2b0b14-23fb-4b1e-9d9f-48b97662ae1a",
              "builtID": "ny-lower-45",
              "externalID": "ocd-person/3e2b0b14-23fb-4b1e-9d9f-48b97662ae1a",
              "geometry": null
            },
            "contactInfo": {
              "email": "novakhovm@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1800 Sheepshead Bay Road, Brooklyn, NY 11235",
              "phone1": null,
              "phone2": "718-743-4078",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "profile.php?id=100093149859224",
              "urls": [
                "https://assembly.state.ny.us/mem/Michael-Novakhov",
                "https://nyassembly.gov/mem/Michael-Novakhov"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-45-lower-ocd-person-3e2b0b14-23fb-4b1e-9d9f-48b97662ae1a": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/69640dce-9d99-45a3-83b5-cbdc1dc2f2e5",
            "firstName": "Alec",
            "lastName": "Brook-Krasny",
            "fullName": "Alec Brook-Krasny",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://assembly.state.ny.us/write/upload/member_files/046/headshot/046.jpg?hst=1672572717",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-NY-46-lower-ocd-person-69640dce-9d99-45a3-83b5-cbdc1dc2f2e5",
              "builtID": "ny-lower-46",
              "externalID": "ocd-person/69640dce-9d99-45a3-83b5-cbdc1dc2f2e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "brook-krasnya@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "310 93rd St., Brooklyn, NY 11209",
              "phone1": null,
              "phone2": "347-560-6302",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Alec-Brook-Krasny",
                "https://assembly.state.ny.us/mem/alec-brook-krasny"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-46-lower-ocd-person-69640dce-9d99-45a3-83b5-cbdc1dc2f2e5": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/664246f6-8749-4d3e-8b03-524dd4273b0a",
            "firstName": "William",
            "lastName": "Colton",
            "fullName": "William Colton",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/047/headshot/047.jpg?hst=1660073520",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-NY-47-lower-ocd-person-664246f6-8749-4d3e-8b03-524dd4273b0a",
              "builtID": "ny-lower-47",
              "externalID": "ocd-person/664246f6-8749-4d3e-8b03-524dd4273b0a",
              "geometry": null
            },
            "contactInfo": {
              "email": "coltonw@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "155 Kings Highway, Brooklyn, NY 11223",
              "phone1": null,
              "phone2": "718-236-1598",
              "fax1": null,
              "fax2": null,
              "twitter": "AMWilliamColton",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/William-Colton",
                "https://nyassembly.gov/mem/william-colton"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-47-lower-ocd-person-664246f6-8749-4d3e-8b03-524dd4273b0a": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/a7950377-6f32-4fdf-b4a5-e9729bc5d842",
            "firstName": "Simcha",
            "lastName": "Eichenstein",
            "fullName": "Simcha Eichenstein",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/048/headshot/048.jpg?hst=1660073541",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-NY-48-lower-ocd-person-a7950377-6f32-4fdf-b4a5-e9729bc5d842",
              "builtID": "ny-lower-48",
              "externalID": "ocd-person/a7950377-6f32-4fdf-b4a5-e9729bc5d842",
              "geometry": null
            },
            "contactInfo": {
              "email": "eichensteins@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1310 48th St. Unit 204, Brooklyn, NY 11219",
              "phone1": null,
              "phone2": "718-853-9616",
              "fax1": null,
              "fax2": "718-436-5734",
              "twitter": "SEichenstein",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Simcha-Eichenstein",
                "https://nyassembly.gov/mem/Simcha-Eichenstein"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-48-lower-ocd-person-a7950377-6f32-4fdf-b4a5-e9729bc5d842": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/b43ab7e1-e8f3-4b46-905e-ebfdded05b80",
            "firstName": "Lester",
            "lastName": "Chang",
            "fullName": "Lester Chang",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://assembly.state.ny.us/write/upload/member_files/049/headshot/049.jpg?hst=1674060585",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-NY-49-lower-ocd-person-b43ab7e1-e8f3-4b46-905e-ebfdded05b80",
              "builtID": "ny-lower-49",
              "externalID": "ocd-person/b43ab7e1-e8f3-4b46-905e-ebfdded05b80",
              "geometry": null
            },
            "contactInfo": {
              "email": "changl@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6904 Fort Hamilton Parkway, Brooklyn, NY 11228",
              "phone1": null,
              "phone2": "718-236-1764",
              "fax1": null,
              "fax2": "718-234-0986",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Lester-Chang",
                "https://nyassembly.gov/mem/Lester-Chang"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-49-lower-ocd-person-b43ab7e1-e8f3-4b46-905e-ebfdded05b80": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/81456b86-152f-4764-a489-dade7cc572c6",
            "firstName": "Emily E.",
            "lastName": "Gallagher",
            "fullName": "Emily Gallagher",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/050/headshot/050.jpg?hst=1679601880",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-NY-50-lower-ocd-person-81456b86-152f-4764-a489-dade7cc572c6",
              "builtID": "ny-lower-50",
              "externalID": "ocd-person/81456b86-152f-4764-a489-dade7cc572c6",
              "geometry": null
            },
            "contactInfo": {
              "email": "gallaghere@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "685A Manhattan Ave., Brooklyn, NY 11222",
              "phone1": null,
              "phone2": "718-383-7474",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Emily-Gallagher",
                "https://nyassembly.gov/mem/Emily-Gallagher"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-50-lower-ocd-person-81456b86-152f-4764-a489-dade7cc572c6": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/1dd95774-3fbe-43a8-ac78-6b4d05146e27",
            "firstName": "Marcela",
            "lastName": "Mitaynes",
            "fullName": "Marcela Mitaynes",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/051/headshot/051.jpg?hst=1660073582",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-NY-51-lower-ocd-person-1dd95774-3fbe-43a8-ac78-6b4d05146e27",
              "builtID": "ny-lower-51",
              "externalID": "ocd-person/1dd95774-3fbe-43a8-ac78-6b4d05146e27",
              "geometry": null
            },
            "contactInfo": {
              "email": "mitaynesm@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4907 4th Ave. Suite 1A, Brooklyn, NY 11220",
              "phone1": null,
              "phone2": "718-492-6334",
              "fax1": null,
              "fax2": "718-765-4186",
              "twitter": "MMitaynes",
              "youtube": null,
              "instagram": null,
              "facebook": "MMitaynes",
              "urls": [
                "https://assembly.state.ny.us/mem/Marcela-Mitaynes",
                "https://nyassembly.gov/mem/Marcela-Mitaynes"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-51-lower-ocd-person-1dd95774-3fbe-43a8-ac78-6b4d05146e27": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/21da6116-bec3-44a4-9e0b-a68eef91222d",
            "firstName": "Jo Anne",
            "lastName": "Simon",
            "fullName": "Jo Anne Simon",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/052/headshot/052.jpg?hst=1660073592",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-NY-52-lower-ocd-person-21da6116-bec3-44a4-9e0b-a68eef91222d",
              "builtID": "ny-lower-52",
              "externalID": "ocd-person/21da6116-bec3-44a4-9e0b-a68eef91222d",
              "geometry": null
            },
            "contactInfo": {
              "email": "simonj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "341 Smith St., Brooklyn, NY 11231",
              "phone1": null,
              "phone2": "718-246-4889",
              "fax1": null,
              "fax2": null,
              "twitter": "JoAnneSimonBK52",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Jo-Anne-Simon",
                "https://nyassembly.gov/mem/Jo-Anne-Simon"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-52-lower-ocd-person-21da6116-bec3-44a4-9e0b-a68eef91222d": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/6dfd759c-edcb-45d9-ac9a-9064a69d5f4a",
            "firstName": "Maritza",
            "lastName": "Davila",
            "fullName": "Maritza Davila",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/053/headshot/053.jpg?hst=1660073605",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-NY-53-lower-ocd-person-6dfd759c-edcb-45d9-ac9a-9064a69d5f4a",
              "builtID": "ny-lower-53",
              "externalID": "ocd-person/6dfd759c-edcb-45d9-ac9a-9064a69d5f4a",
              "geometry": null
            },
            "contactInfo": {
              "email": "davilam@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "673 Hart St. Unit C2, Brooklyn, NY 11237",
              "phone1": null,
              "phone2": "718-443-1205",
              "fax1": null,
              "fax2": "718-443-1424",
              "twitter": "DavilaAssembly",
              "youtube": null,
              "instagram": null,
              "facebook": "MaritzaDavilaAssembly",
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Maritza-Davila",
                "https://nyassembly.gov/mem/Maritza-Davila"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-53-lower-ocd-person-6dfd759c-edcb-45d9-ac9a-9064a69d5f4a": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/489a01e1-6a0f-4907-bc00-110020e2f27d",
            "firstName": "Erik Martin",
            "lastName": "Dilan",
            "fullName": "Erik Dilan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/054/headshot/054.jpg?hst=1660073635",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-NY-54-lower-ocd-person-489a01e1-6a0f-4907-bc00-110020e2f27d",
              "builtID": "ny-lower-54",
              "externalID": "ocd-person/489a01e1-6a0f-4907-bc00-110020e2f27d",
              "geometry": null
            },
            "contactInfo": {
              "email": "dilane@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "366 Cornelia St., Brooklyn, NY 11237",
              "phone1": null,
              "phone2": "718-386-4576",
              "fax1": null,
              "fax2": "718-386-4575",
              "twitter": "edilan37",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Erik-M-Dilan",
                "https://nyassembly.gov/mem/Erik-M-Dilan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-54-lower-ocd-person-489a01e1-6a0f-4907-bc00-110020e2f27d": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/88a4af90-81b8-4d72-a57c-518a20a0881b",
            "firstName": "Latrice Monique",
            "lastName": "Walker",
            "fullName": "Latrice Walker",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/055/headshot/055.jpg?hst=1660073650",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-NY-55-lower-ocd-person-88a4af90-81b8-4d72-a57c-518a20a0881b",
              "builtID": "ny-lower-55",
              "externalID": "ocd-person/88a4af90-81b8-4d72-a57c-518a20a0881b",
              "geometry": null
            },
            "contactInfo": {
              "email": "walkerl@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "400 Rockaway Ave. 2nd Floor, Brooklyn, NY 11212",
              "phone1": null,
              "phone2": "718-342-1256",
              "fax1": null,
              "fax2": "718-342-1258",
              "twitter": "TheRealLatriceW",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Latrice-Walker",
                "https://assembly.state.ny.us/mem/Latrice-M-Walker",
                "https://nyassembly.gov/mem/Latrice-Walker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-55-lower-ocd-person-88a4af90-81b8-4d72-a57c-518a20a0881b": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/d4d53fcb-59b0-4869-bad8-42963b716e59",
            "firstName": "Stefani L.",
            "lastName": "Zinerman",
            "fullName": "Stefani Zinerman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/056/headshot/056.jpg?hst=1660073661",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-NY-56-lower-ocd-person-d4d53fcb-59b0-4869-bad8-42963b716e59",
              "builtID": "ny-lower-56",
              "externalID": "ocd-person/d4d53fcb-59b0-4869-bad8-42963b716e59",
              "geometry": null
            },
            "contactInfo": {
              "email": "zinermans@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1368 Fulton St. 3rd Floor, NW, Brooklyn, NY 11216",
              "phone1": null,
              "phone2": "718-399-7630",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Stefani-Zinerman",
                "https://nyassembly.gov/mem/Stefani-Zinerman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-56-lower-ocd-person-d4d53fcb-59b0-4869-bad8-42963b716e59": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/8683c8ab-0d6c-471f-9259-a1b506109912",
            "firstName": "Phara",
            "lastName": "Souffrant Forrest",
            "fullName": "Phara Souffrant Forrest",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/057/headshot/057.jpg?hst=1660073678",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-NY-57-lower-ocd-person-8683c8ab-0d6c-471f-9259-a1b506109912",
              "builtID": "ny-lower-57",
              "externalID": "ocd-person/8683c8ab-0d6c-471f-9259-a1b506109912",
              "geometry": null
            },
            "contactInfo": {
              "email": "souffrantforrestp@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "55 Hanson Place, Brooklyn, NY 11217",
              "phone1": null,
              "phone2": "718-596-0100",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Phara-Souffrant-Forrest",
                "https://nyassembly.gov/mem/Phara-Souffrant-Forrest"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-57-lower-ocd-person-8683c8ab-0d6c-471f-9259-a1b506109912": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/24fb9f1e-0bd6-4ac2-8a49-1091016863d2",
            "firstName": "Monique R.",
            "lastName": "Chandler-Waterman",
            "fullName": "Monique Chandler-Waterman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/058/headshot/058.jpg?hst=1660073695",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-NY-58-lower-ocd-person-24fb9f1e-0bd6-4ac2-8a49-1091016863d2",
              "builtID": "ny-lower-58",
              "externalID": "ocd-person/24fb9f1e-0bd6-4ac2-8a49-1091016863d2",
              "geometry": null
            },
            "contactInfo": {
              "email": "chandlerwatermanm@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "903 Utica Ave., Brooklyn, NY 11203",
              "phone1": null,
              "phone2": "718-385-3336",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Monique-Chandler-Waterman",
                "https://nyassembly.gov/mem/Monique-Chandler-Waterman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-58-lower-ocd-person-24fb9f1e-0bd6-4ac2-8a49-1091016863d2": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/67047d73-acc1-4d6f-b580-fbeee33cc1f8",
            "firstName": "Jaime R.",
            "lastName": "Williams",
            "fullName": "Jaime Williams",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/059/headshot/059.jpg?hst=1660073711",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-NY-59-lower-ocd-person-67047d73-acc1-4d6f-b580-fbeee33cc1f8",
              "builtID": "ny-lower-59",
              "externalID": "ocd-person/67047d73-acc1-4d6f-b580-fbeee33cc1f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "williamsja@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5318 N Ave. 1st Floor Store, Brooklyn, NY 11234",
              "phone1": null,
              "phone2": "718-252-2124",
              "fax1": null,
              "fax2": "718-252-2417",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Jaime-R-Williams",
                "https://nyassembly.gov/mem/Jaime-R-Williams"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-59-lower-ocd-person-67047d73-acc1-4d6f-b580-fbeee33cc1f8": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/075a2bdd-9f2f-4160-bec6-dfa293da4c4e",
            "firstName": "Nikki I.",
            "lastName": "Lucas",
            "fullName": "Nikki Lucas",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/060/headshot/060.jpg?hst=1660073727",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-NY-60-lower-ocd-person-075a2bdd-9f2f-4160-bec6-dfa293da4c4e",
              "builtID": "ny-lower-60",
              "externalID": "ocd-person/075a2bdd-9f2f-4160-bec6-dfa293da4c4e",
              "geometry": null
            },
            "contactInfo": {
              "email": "lucasn@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "425 New Lots Ave. First Floor, Brooklyn, NY 11207",
              "phone1": null,
              "phone2": "718-257-5824",
              "fax1": null,
              "fax2": "718-257-2590",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Nikki-Lucas",
                "https://nyassembly.gov/mem/Nikki-Lucas"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-60-lower-ocd-person-075a2bdd-9f2f-4160-bec6-dfa293da4c4e": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/5390ebc9-7fbe-4be9-91c4-b28b59f7fd2f",
            "firstName": "Charles Dialor",
            "lastName": "Fall",
            "fullName": "Charles Fall",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/061/headshot/061.jpg?hst=1660073777",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-NY-61-lower-ocd-person-5390ebc9-7fbe-4be9-91c4-b28b59f7fd2f",
              "builtID": "ny-lower-61",
              "externalID": "ocd-person/5390ebc9-7fbe-4be9-91c4-b28b59f7fd2f",
              "geometry": null
            },
            "contactInfo": {
              "email": "fallc@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "853 Forest Ave., Staten Island, NY 10310",
              "phone1": null,
              "phone2": "718-442-9932",
              "fax1": null,
              "fax2": null,
              "twitter": "Charlesdfall",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Charles-D-Fall",
                "https://nyassembly.gov/mem/Charles-Fall"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-61-lower-ocd-person-5390ebc9-7fbe-4be9-91c4-b28b59f7fd2f": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/f827b8b8-7aa1-4b7b-b40e-d7af0f40d5eb",
            "firstName": "Michael W.",
            "lastName": "Reilly",
            "fullName": "Mike Reilly",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/062/headshot/062.jpg?hst=1660073788",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-NY-62-lower-ocd-person-f827b8b8-7aa1-4b7b-b40e-d7af0f40d5eb",
              "builtID": "ny-lower-62",
              "externalID": "ocd-person/f827b8b8-7aa1-4b7b-b40e-d7af0f40d5eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "reillym@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7001 Amboy Road Suite 202 E, Staten Island, NY 10307",
              "phone1": null,
              "phone2": "718-967-5194",
              "fax1": null,
              "fax2": "718-967-5282",
              "twitter": "TeamReillyNY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Michael-Reilly",
                "https://nyassembly.gov/mem/Michael-Reilly"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-62-lower-ocd-person-f827b8b8-7aa1-4b7b-b40e-d7af0f40d5eb": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/ec764287-6c45-40e2-929d-383838a4c4c7",
            "firstName": "Samuel",
            "lastName": "Pirozzolo",
            "fullName": "Sam Pirozzolo",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://assembly.state.ny.us/write/upload/member_files/063/headshot/063.jpg?hst=1672573792",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-NY-63-lower-ocd-person-ec764287-6c45-40e2-929d-383838a4c4c7",
              "builtID": "ny-lower-63",
              "externalID": "ocd-person/ec764287-6c45-40e2-929d-383838a4c4c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "pirozzolos@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2090 Victory Blvd., Staten Island, NY 10314",
              "phone1": null,
              "phone2": "718-370-1384",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Sam-Pirozzolo",
                "https://nyassembly.gov/mem/Sam-Pirozzolo"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-63-lower-ocd-person-ec764287-6c45-40e2-929d-383838a4c4c7": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/46506ffb-27af-4b91-b863-9b0a425195a8",
            "firstName": "Michael",
            "lastName": "Tannousis",
            "fullName": "Mike Tannousis",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://assembly.state.ny.us/write/upload/member_files/064/headshot/064.jpg?hst=1660073815",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-NY-64-lower-ocd-person-46506ffb-27af-4b91-b863-9b0a425195a8",
              "builtID": "ny-lower-64",
              "externalID": "ocd-person/46506ffb-27af-4b91-b863-9b0a425195a8",
              "geometry": null
            },
            "contactInfo": {
              "email": "tannousism@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7716 3rd Ave., Brooklyn, NY 11209",
              "phone1": null,
              "phone2": "718-439-8090",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Michael-Tannousis",
                "https://nyassembly.gov/mem/Michael-Tannousis"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-64-lower-ocd-person-46506ffb-27af-4b91-b863-9b0a425195a8": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/18830756-58c1-4fd1-b1b0-d16041b10e12",
            "firstName": "Grace",
            "lastName": "Lee",
            "fullName": "Grace Lee",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/065/headshot/065.jpg?hst=1672563219",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-NY-65-lower-ocd-person-18830756-58c1-4fd1-b1b0-d16041b10e12",
              "builtID": "ny-lower-65",
              "externalID": "ocd-person/18830756-58c1-4fd1-b1b0-d16041b10e12",
              "geometry": null
            },
            "contactInfo": {
              "email": "leeg@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 302, 64 Fulton St., New York, NY 10038",
              "phone1": null,
              "phone2": "212-312-1420",
              "fax1": null,
              "fax2": "212-227-8054",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Grace-Lee",
                "https://nyassembly.gov/mem/Grace-Lee"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-65-lower-ocd-person-18830756-58c1-4fd1-b1b0-d16041b10e12": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/5a9c40ed-69dc-4004-b34a-cc943428f8b1",
            "firstName": "Deborah J.",
            "lastName": "Glick",
            "fullName": "Deborah Glick",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/066/headshot/066.jpg?hst=1660073838",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-NY-66-lower-ocd-person-5a9c40ed-69dc-4004-b34a-cc943428f8b1",
              "builtID": "ny-lower-66",
              "externalID": "ocd-person/5a9c40ed-69dc-4004-b34a-cc943428f8b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "glickd@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "853 Broadway Suite 2007, New York, NY 10003",
              "phone1": null,
              "phone2": "212-674-5153",
              "fax1": null,
              "fax2": null,
              "twitter": "DeborahJGlick",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Deborah-J-Glick",
                "https://nyassembly.gov/mem/deborah-j-glick"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-66-lower-ocd-person-5a9c40ed-69dc-4004-b34a-cc943428f8b1": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/2c20fb3d-4c95-4494-ac3b-a1ab93f72439",
            "firstName": "Linda B.",
            "lastName": "Rosenthal",
            "fullName": "Linda Rosenthal",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/067/headshot/067.jpg?hst=1660073851",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-NY-67-lower-ocd-person-2c20fb3d-4c95-4494-ac3b-a1ab93f72439",
              "builtID": "ny-lower-67",
              "externalID": "ocd-person/2c20fb3d-4c95-4494-ac3b-a1ab93f72439",
              "geometry": null
            },
            "contactInfo": {
              "email": "rosentl@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "230 W. 72nd St. Suite 2F, New York, NY 10023",
              "phone1": null,
              "phone2": "212-873-6368",
              "fax1": null,
              "fax2": null,
              "twitter": "LindaBRosenthal",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Linda-B-Rosenthal",
                "https://nyassembly.gov/mem/linda-b-rosenthal"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-67-lower-ocd-person-2c20fb3d-4c95-4494-ac3b-a1ab93f72439": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/5764a626-de74-4d88-bc53-b43b523aa75c",
            "firstName": "Edward L.",
            "lastName": "Gibbs",
            "fullName": "Eddie Gibbs",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/068/headshot/068.jpg?hst=1662988090",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-NY-68-lower-ocd-person-5764a626-de74-4d88-bc53-b43b523aa75c",
              "builtID": "ny-lower-68",
              "externalID": "ocd-person/5764a626-de74-4d88-bc53-b43b523aa75c",
              "geometry": null
            },
            "contactInfo": {
              "email": "gibbse@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "55 E. 115th St. Ground Level, New York, NY 10029",
              "phone1": null,
              "phone2": "212-828-3953",
              "fax1": null,
              "fax2": null,
              "twitter": "AMEddieGibbs",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Eddie-Gibbs",
                "https://assembly.state.ny.us/mem/Edward-Gibbs",
                "https://nyassembly.gov/mem/Edward-Gibbs"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-68-lower-ocd-person-5764a626-de74-4d88-bc53-b43b523aa75c": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/984069b9-4c13-45cc-9917-568dc6ae9cc5",
            "firstName": "Daniel J.",
            "lastName": "O'Donnell",
            "fullName": "Danny O'Donnell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/147/headshot/147.jpg?hst=1660075005",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-NY-69-lower-ocd-person-984069b9-4c13-45cc-9917-568dc6ae9cc5",
              "builtID": "ny-lower-69",
              "externalID": "ocd-person/984069b9-4c13-45cc-9917-568dc6ae9cc5",
              "geometry": null
            },
            "contactInfo": {
              "email": "odonnelld@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "245 W. 104th St., New York, NY 10025",
              "phone1": null,
              "phone2": "212-866-3970",
              "fax1": null,
              "fax2": null,
              "twitter": "dannyodonnellny",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Daniel-J-O'Donnell",
                "https://nyassembly.gov/mem/Daniel-J-O'Donnell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-69-lower-ocd-person-984069b9-4c13-45cc-9917-568dc6ae9cc5": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/6e37cc73-dcbf-40b9-8919-b7cb0f67f1f6",
            "firstName": "Inez E.",
            "lastName": "Dickens",
            "fullName": "Inez Dickens",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/070/headshot/070.jpg?hst=1660073888",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-NY-70-lower-ocd-person-6e37cc73-dcbf-40b9-8919-b7cb0f67f1f6",
              "builtID": "ny-lower-70",
              "externalID": "ocd-person/6e37cc73-dcbf-40b9-8919-b7cb0f67f1f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "dickensi@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "163 W. 125th St. Suite 911, New York, NY 10027",
              "phone1": null,
              "phone2": "212-866-5809",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "iedickens70",
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Inez-E-Dickens",
                "https://nyassembly.gov/mem/Inez-E-Dickens"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-70-lower-ocd-person-6e37cc73-dcbf-40b9-8919-b7cb0f67f1f6": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/c197076b-7dde-4f90-b04f-352053fbab94",
            "firstName": "Alfred E.",
            "lastName": "Taylor",
            "fullName": "Al Taylor",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/071/headshot/071.jpg?hst=1660073901",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-NY-71-lower-ocd-person-c197076b-7dde-4f90-b04f-352053fbab94",
              "builtID": "ny-lower-71",
              "externalID": "ocd-person/c197076b-7dde-4f90-b04f-352053fbab94",
              "geometry": null
            },
            "contactInfo": {
              "email": "taylora@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2541-55 Adam Clayton Powell Jr. Blvd., New York, NY 10039",
              "phone1": null,
              "phone2": "212-234-1430",
              "fax1": null,
              "fax2": null,
              "twitter": "assemblymanalt1",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Al-Taylor",
                "https://nyassembly.gov/mem/Al-Taylor"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-71-lower-ocd-person-c197076b-7dde-4f90-b04f-352053fbab94": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/e9e827d2-5eb7-4c53-bec7-d6a9fdef82fa",
            "firstName": "Manny K.",
            "lastName": "De Los Santos",
            "fullName": "Manny De Los Santos",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/072/headshot/072.jpg?hst=1660073911",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-NY-72-lower-ocd-person-e9e827d2-5eb7-4c53-bec7-d6a9fdef82fa",
              "builtID": "ny-lower-72",
              "externalID": "ocd-person/e9e827d2-5eb7-4c53-bec7-d6a9fdef82fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "delossm@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "210 Sherman Ave. Suite A&C, New York, NY 10034",
              "phone1": null,
              "phone2": "212-544-2278",
              "fax1": null,
              "fax2": null,
              "twitter": "AMDeLosSantos72",
              "youtube": null,
              "instagram": null,
              "facebook": "AssemblymanMannyDeLosSantos",
              "urls": [
                "https://assembly.state.ny.us/mem/Manny-De-Los-Santos",
                "https://nyassembly.gov/mem/Manny-De-Los-Santos"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-72-lower-ocd-person-e9e827d2-5eb7-4c53-bec7-d6a9fdef82fa": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/9c986c4c-5371-4b77-b095-5a7283a93729",
            "firstName": "Alex",
            "lastName": "Bores",
            "fullName": "Alex Bores",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/073/headshot/073.jpg?hst=1672573990",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-NY-73-lower-ocd-person-9c986c4c-5371-4b77-b095-5a7283a93729",
              "builtID": "ny-lower-73",
              "externalID": "ocd-person/9c986c4c-5371-4b77-b095-5a7283a93729",
              "geometry": null
            },
            "contactInfo": {
              "email": "boresa@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "353 Lexington Ave, Suite 704, New York, NY 10016",
              "phone1": null,
              "phone2": "212-605-0937",
              "fax1": null,
              "fax2": "212-605-9948",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Alex-Bores",
                "https://nyassembly.gov/mem/Alex-Bores"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-73-lower-ocd-person-9c986c4c-5371-4b77-b095-5a7283a93729": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/eaf455e1-358d-4de0-ac5d-3d8c400eb4e3",
            "firstName": "Harvey",
            "lastName": "Epstein",
            "fullName": "Harvey Epstein",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/074/headshot/074.jpg?hst=1660073935",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-NY-74-lower-ocd-person-eaf455e1-358d-4de0-ac5d-3d8c400eb4e3",
              "builtID": "ny-lower-74",
              "externalID": "ocd-person/eaf455e1-358d-4de0-ac5d-3d8c400eb4e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "epsteinh@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "107 & 109 Ave. B, New York, NY 10009",
              "phone1": null,
              "phone2": "212-979-9696",
              "fax1": null,
              "fax2": null,
              "twitter": "HarveyforNY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Harvey-Epstein",
                "https://nyassembly.gov/mem/Harvey-Epstein"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-74-lower-ocd-person-eaf455e1-358d-4de0-ac5d-3d8c400eb4e3": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/83e08970-f529-4270-adcb-e15c660d8b7a",
            "firstName": "Tony",
            "lastName": "Simone",
            "fullName": "Tony Simone",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/075/headshot/075.jpg?hst=1672577505",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-NY-75-lower-ocd-person-83e08970-f529-4270-adcb-e15c660d8b7a",
              "builtID": "ny-lower-75",
              "externalID": "ocd-person/83e08970-f529-4270-adcb-e15c660d8b7a",
              "geometry": null
            },
            "contactInfo": {
              "email": "simonet@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "214 W. 29th St. Suite 1401, New York, NY 10001",
              "phone1": null,
              "phone2": "212-807-7900",
              "fax1": null,
              "fax2": "212-243-2035",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Tony-Simone",
                "https://nyassembly.gov/mem/Tony-Simone"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-75-lower-ocd-person-83e08970-f529-4270-adcb-e15c660d8b7a": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/78e9f465-ad6d-4067-a2df-1e2ddb34c860",
            "firstName": "Rebecca A.",
            "lastName": "Seawright",
            "fullName": "Rebecca Seawright",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/076/headshot/076.jpg?hst=1660073966",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-NY-76-lower-ocd-person-78e9f465-ad6d-4067-a2df-1e2ddb34c860",
              "builtID": "ny-lower-76",
              "externalID": "ocd-person/78e9f465-ad6d-4067-a2df-1e2ddb34c860",
              "geometry": null
            },
            "contactInfo": {
              "email": "seawrightr@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1485 York Ave., New York, NY 10075",
              "phone1": null,
              "phone2": "212-288-4607",
              "fax1": null,
              "fax2": "212-288-4369",
              "twitter": "SeawrightForNY",
              "youtube": null,
              "instagram": null,
              "facebook": "RebeccaASeawright",
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Rebecca-A-Seawright",
                "https://nyassembly.gov/mem/Rebecca-A-Seawright"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-76-lower-ocd-person-78e9f465-ad6d-4067-a2df-1e2ddb34c860": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/4f486e74-0735-42fd-ab1c-9816a0ced550",
            "firstName": "Landon C.",
            "lastName": "Dais",
            "fullName": "Landon Dais",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/077/headshot/077.jpg?hst=1707948419",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-NY-77-lower-ocd-person-4f486e74-0735-42fd-ab1c-9816a0ced550",
              "builtID": "ny-lower-77",
              "externalID": "ocd-person/4f486e74-0735-42fd-ab1c-9816a0ced550",
              "geometry": null
            },
            "contactInfo": {
              "email": "daisl@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "910 Grand Concourse Suite 1JK, Bronx, NY 10451",
              "phone1": null,
              "phone2": "718-538-2000",
              "fax1": null,
              "fax2": "718-538-3128",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://nyassembly.gov/mem/Landon-C-Dais/"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-77-lower-ocd-person-4f486e74-0735-42fd-ab1c-9816a0ced550": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/722e778a-7824-49fb-bb44-ba1da8bab675",
            "firstName": "George",
            "lastName": "Alvarez",
            "fullName": "George Alvarez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/078/headshot/078.jpg?hst=1672564700",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-NY-78-lower-ocd-person-722e778a-7824-49fb-bb44-ba1da8bab675",
              "builtID": "ny-lower-78",
              "externalID": "ocd-person/722e778a-7824-49fb-bb44-ba1da8bab675",
              "geometry": null
            },
            "contactInfo": {
              "email": "alvarezg@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2633 Webster Ave. 1st Floor, Bronx, NY 10458",
              "phone1": null,
              "phone2": "718-933-2204",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/George-Alvarez",
                "https://nyassembly.gov/mem/George-Alvarez"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-78-lower-ocd-person-722e778a-7824-49fb-bb44-ba1da8bab675": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/2a9ffeeb-e46e-4d98-8376-b07310112672",
            "firstName": "Chantel S.",
            "lastName": "Jackson",
            "fullName": "Chantel Jackson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/079/headshot/079.jpg?hst=1660074007",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-NY-79-lower-ocd-person-2a9ffeeb-e46e-4d98-8376-b07310112672",
              "builtID": "ny-lower-79",
              "externalID": "ocd-person/2a9ffeeb-e46e-4d98-8376-b07310112672",
              "geometry": null
            },
            "contactInfo": {
              "email": "jacksonc@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "780 Concourse Village West Ground Floor Professional, Bronx, NY 10451",
              "phone1": null,
              "phone2": "718-538-3829",
              "fax1": null,
              "fax2": "718-588-0159",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Chantel-Jackson",
                "https://nyassembly.gov/mem/Chantel-Jackson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-79-lower-ocd-person-2a9ffeeb-e46e-4d98-8376-b07310112672": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/a956438d-7d17-4148-a581-ad398baa0d6a",
            "firstName": "John D.",
            "lastName": "Zaccaro",
            "fullName": "John Zaccaro",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/080/headshot/080.jpg?hst=1672563867",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-NY-80-lower-ocd-person-a956438d-7d17-4148-a581-ad398baa0d6a",
              "builtID": "ny-lower-80",
              "externalID": "ocd-person/a956438d-7d17-4148-a581-ad398baa0d6a",
              "geometry": null
            },
            "contactInfo": {
              "email": "zaccaroj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2018 Williamsbridge Road, Bronx, NY 10461",
              "phone1": null,
              "phone2": "718-409-0109",
              "fax1": null,
              "fax2": "718-409-0431",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/John-Zaccaro-Jr",
                "https://nyassembly.gov/mem/John-Zaccaro-Jr"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-80-lower-ocd-person-a956438d-7d17-4148-a581-ad398baa0d6a": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/ff604a07-1aaf-4e61-b070-dd478f42cea5",
            "firstName": "Jeffrey",
            "lastName": "Dinowitz",
            "fullName": "Jeffrey Dinowitz",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/081/headshot/081.jpg?hst=1660074041",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-NY-81-lower-ocd-person-ff604a07-1aaf-4e61-b070-dd478f42cea5",
              "builtID": "ny-lower-81",
              "externalID": "ocd-person/ff604a07-1aaf-4e61-b070-dd478f42cea5",
              "geometry": null
            },
            "contactInfo": {
              "email": "dinowij@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3107 Kingsbridge Ave., Bronx, NY 10463",
              "phone1": null,
              "phone2": "718-796-5345",
              "fax1": null,
              "fax2": "718-796-0694",
              "twitter": "JeffreyDinowitz",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Jeffrey-Dinowitz",
                "https://nyassembly.gov/mem/jeffrey-dinowitz"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-81-lower-ocd-person-ff604a07-1aaf-4e61-b070-dd478f42cea5": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/b2b9862e-f665-4848-9d9c-5dcf2c986fed",
            "firstName": "Michael R.",
            "lastName": "Benedetto",
            "fullName": "Michael Benedetto",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/082/headshot/082.jpg?hst=1660074060",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-NY-82-lower-ocd-person-b2b9862e-f665-4848-9d9c-5dcf2c986fed",
              "builtID": "ny-lower-82",
              "externalID": "ocd-person/b2b9862e-f665-4848-9d9c-5dcf2c986fed",
              "geometry": null
            },
            "contactInfo": {
              "email": "benedettom@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 12, 177 Dreiser Loop, Bronx, NY 10475",
              "phone1": null,
              "phone2": "718-320-2220",
              "fax1": null,
              "fax2": null,
              "twitter": "mikebenedetto82?lang=en",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Michael-Benedetto",
                "https://nyassembly.gov/mem/michael-benedetto"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-82-lower-ocd-person-b2b9862e-f665-4848-9d9c-5dcf2c986fed": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/2049da3a-132c-47b4-b53d-c28b574fff63",
            "firstName": "Carl Edward",
            "lastName": "Heastie",
            "fullName": "Carl Heastie",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/083/headshot/083.jpg?hst=1660074070",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-NY-83-lower-ocd-person-2049da3a-132c-47b4-b53d-c28b574fff63",
              "builtID": "ny-lower-83",
              "externalID": "ocd-person/2049da3a-132c-47b4-b53d-c28b574fff63",
              "geometry": null
            },
            "contactInfo": {
              "email": "speaker@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 2301, 250 Broadway, New York, NY 10007",
              "phone1": null,
              "phone2": "212-312-1400",
              "fax1": null,
              "fax2": "212-312-1418",
              "twitter": "CarlHeastie",
              "youtube": null,
              "instagram": null,
              "facebook": "carl.heastie",
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Carl-E-Heastie",
                "https://nyassembly.gov/mem/carl-e-heastie"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-83-lower-ocd-person-2049da3a-132c-47b4-b53d-c28b574fff63": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/3647609d-4772-4afe-a2c0-1f628a6c11ec",
            "firstName": "Amanda N.",
            "lastName": "Septimo",
            "fullName": "Amanda Septimo",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/084/headshot/084.jpg?hst=1660074086",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-NY-84-lower-ocd-person-3647609d-4772-4afe-a2c0-1f628a6c11ec",
              "builtID": "ny-lower-84",
              "externalID": "ocd-person/3647609d-4772-4afe-a2c0-1f628a6c11ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "septimoa@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "384 E. 149th St. Suite 202, Bronx, NY 10455",
              "phone1": null,
              "phone2": "718-292-2901",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Amanda-Septimo",
                "https://nyassembly.gov/mem/Amanda-Septimo"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-84-lower-ocd-person-3647609d-4772-4afe-a2c0-1f628a6c11ec": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/e2697f3d-ad8b-4c63-a39b-abac782dfe05",
            "firstName": "Kenneth",
            "lastName": "Burgos",
            "fullName": "Kenny Burgos",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/085/headshot/085.jpg?hst=1660074100",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-NY-85-lower-ocd-person-e2697f3d-ad8b-4c63-a39b-abac782dfe05",
              "builtID": "ny-lower-85",
              "externalID": "ocd-person/e2697f3d-ad8b-4c63-a39b-abac782dfe05",
              "geometry": null
            },
            "contactInfo": {
              "email": "burgosk@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1163 Manor Ave. Store Front #1, Bronx, NY 10472",
              "phone1": null,
              "phone2": "718-893-0202",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Kenny-Burgos",
                "https://nyassembly.gov/mem/Kenny-Burgos"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-85-lower-ocd-person-e2697f3d-ad8b-4c63-a39b-abac782dfe05": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/e924d52f-f944-4beb-9b67-bc243639a107",
            "firstName": "Yudelka",
            "lastName": "Tapia",
            "fullName": "Yudelka Tapia",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/086/headshot/086.jpg?hst=1660074111",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-NY-86-lower-ocd-person-e924d52f-f944-4beb-9b67-bc243639a107",
              "builtID": "ny-lower-86",
              "externalID": "ocd-person/e924d52f-f944-4beb-9b67-bc243639a107",
              "geometry": null
            },
            "contactInfo": {
              "email": "tapiay@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2175C Jerome Ave., Bronx, NY 10453",
              "phone1": null,
              "phone2": "718-933-6909",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Yudelka-Tapia",
                "https://nyassembly.gov/mem/Yudelka-Tapia"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-86-lower-ocd-person-e924d52f-f944-4beb-9b67-bc243639a107": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/ddcffc59-f65a-426c-8390-9a28d1f1533d",
            "firstName": "Karines",
            "lastName": "Reyes",
            "fullName": "Karines Reyes",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/087/headshot/087.jpg?hst=1660074160",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-NY-87-lower-ocd-person-ddcffc59-f65a-426c-8390-9a28d1f1533d",
              "builtID": "ny-lower-87",
              "externalID": "ocd-person/ddcffc59-f65a-426c-8390-9a28d1f1533d",
              "geometry": null
            },
            "contactInfo": {
              "email": "reyesk@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1973 Westchester Ave., Bronx, NY 10462",
              "phone1": null,
              "phone2": "718-931-2620",
              "fax1": null,
              "fax2": "718-931-2915",
              "twitter": "KarinesReyes87",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Karines-Reyes",
                "https://nyassembly.gov/mem/Karines-Reyes"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-87-lower-ocd-person-ddcffc59-f65a-426c-8390-9a28d1f1533d": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/f0f08f55-cf1f-477c-a5f8-a7f2382e5fa8",
            "firstName": "Amy",
            "lastName": "Paulin",
            "fullName": "Amy Paulin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/088/headshot/088.jpg?hst=1673464404",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-NY-88-lower-ocd-person-f0f08f55-cf1f-477c-a5f8-a7f2382e5fa8",
              "builtID": "ny-lower-88",
              "externalID": "ocd-person/f0f08f55-cf1f-477c-a5f8-a7f2382e5fa8",
              "geometry": null
            },
            "contactInfo": {
              "email": "paulina@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "700 White Plains Road Suite 355, Scarsdale, NY 10583",
              "phone1": null,
              "phone2": "914-723-1115",
              "fax1": null,
              "fax2": null,
              "twitter": "amypaulin",
              "youtube": null,
              "instagram": null,
              "facebook": "assemblywomanpaulin",
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Amy-Paulin",
                "https://nyassembly.gov/mem/amy-paulin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-88-lower-ocd-person-f0f08f55-cf1f-477c-a5f8-a7f2382e5fa8": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/be7ad489-073e-476e-82a0-992ca0569b99",
            "firstName": "James Gary",
            "lastName": "Pretlow",
            "fullName": "Gary Pretlow",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/089/headshot/089.jpg?hst=1660074183",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-NY-89-lower-ocd-person-be7ad489-073e-476e-82a0-992ca0569b99",
              "builtID": "ny-lower-89",
              "externalID": "ocd-person/be7ad489-073e-476e-82a0-992ca0569b99",
              "geometry": null
            },
            "contactInfo": {
              "email": "pretloj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6 Gramatan Ave. Suite 201, Mt. Vernon, NY 10550",
              "phone1": null,
              "phone2": "914-667-0127",
              "fax1": null,
              "fax2": "914-667-0209",
              "twitter": "JGPretlow",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/J-Gary-Pretlow",
                "https://nyassembly.gov/mem/j-gary-pretlow"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-89-lower-ocd-person-be7ad489-073e-476e-82a0-992ca0569b99": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/12dbcb73-95de-4067-8e68-7d7af51eac55",
            "firstName": "Nader J.",
            "lastName": "Sayegh",
            "fullName": "Nader Sayegh",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/090/headshot/090.jpg?hst=1660074194",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-NY-90-lower-ocd-person-12dbcb73-95de-4067-8e68-7d7af51eac55",
              "builtID": "ny-lower-90",
              "externalID": "ocd-person/12dbcb73-95de-4067-8e68-7d7af51eac55",
              "geometry": null
            },
            "contactInfo": {
              "email": "sayeghn@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 406B, 35 E. Grassy Sprain Road, Yonkers, NY 10710",
              "phone1": null,
              "phone2": "914-779-8805",
              "fax1": null,
              "fax2": "914-779-8859",
              "twitter": "NaderJSayegh",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Nader-J-Sayegh",
                "https://nyassembly.gov/mem/Nader-J-Sayegh"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-90-lower-ocd-person-12dbcb73-95de-4067-8e68-7d7af51eac55": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/9a1eed19-c843-4853-bec1-aca4f7034a21",
            "firstName": "Steven",
            "lastName": "Otis",
            "fullName": "Steve Otis",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/091/headshot/091.jpg?hst=1661545963",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-NY-91-lower-ocd-person-9a1eed19-c843-4853-bec1-aca4f7034a21",
              "builtID": "ny-lower-91",
              "externalID": "ocd-person/9a1eed19-c843-4853-bec1-aca4f7034a21",
              "geometry": null
            },
            "contactInfo": {
              "email": "otiss@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "222 Grace Church St., Port Chester, NY 10573",
              "phone1": null,
              "phone2": "914-939-7028",
              "fax1": null,
              "fax2": "914-939-7167",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "303717623097704",
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Steven-Otis",
                "https://nyassembly.gov/mem/Steven-Otis"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-91-lower-ocd-person-9a1eed19-c843-4853-bec1-aca4f7034a21": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/18f58d92-0a20-4ec1-a2dd-e77a10280e9b",
            "firstName": "MaryJane",
            "lastName": "Shimsky",
            "fullName": "MaryJane Shimsky",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/092/headshot/092.jpg?hst=1672578886",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-NY-92-lower-ocd-person-18f58d92-0a20-4ec1-a2dd-e77a10280e9b",
              "builtID": "ny-lower-92",
              "externalID": "ocd-person/18f58d92-0a20-4ec1-a2dd-e77a10280e9b",
              "geometry": null
            },
            "contactInfo": {
              "email": "shimskym@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "303 S. Broadway Suite 229, Tarrytown, NY 10591",
              "phone1": null,
              "phone2": "914-631-1605",
              "fax1": null,
              "fax2": "914-631-1609",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/MaryJane-Shimsky",
                "https://nyassembly.gov/mem/MaryJane-Shimsky"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-92-lower-ocd-person-18f58d92-0a20-4ec1-a2dd-e77a10280e9b": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/35febe88-13a1-4611-b0e3-2088c719db24",
            "firstName": "Christopher",
            "lastName": "Burdick",
            "fullName": "Chris Burdick",
            "gender": "Male",
            "party": "democratic/independence/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/093/headshot/093.jpg?hst=1660074244",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-NY-93-lower-ocd-person-35febe88-13a1-4611-b0e3-2088c719db24",
              "builtID": "ny-lower-93",
              "externalID": "ocd-person/35febe88-13a1-4611-b0e3-2088c719db24",
              "geometry": null
            },
            "contactInfo": {
              "email": "burdickc@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "100 S. Bedford Road Suite 150, Mount Kisco, NY 10549",
              "phone1": null,
              "phone2": "914-244-4450",
              "fax1": null,
              "fax2": null,
              "twitter": "BurdickAD93",
              "youtube": null,
              "instagram": null,
              "facebook": "ChrisBurdickAD93",
              "urls": [
                "https://assembly.state.ny.us/mem/Chris-Burdick",
                "https://nyassembly.gov/mem/Chris-Burdick"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-93-lower-ocd-person-35febe88-13a1-4611-b0e3-2088c719db24": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/eba4d98e-baa7-4ee3-a705-ed38b137ee27",
            "firstName": "Matthew",
            "lastName": "Slater",
            "fullName": "Matt Slater",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://assembly.state.ny.us/write/upload/member_files/094/headshot/094.jpg?hst=1672574790",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-NY-94-lower-ocd-person-eba4d98e-baa7-4ee3-a705-ed38b137ee27",
              "builtID": "ny-lower-94",
              "externalID": "ocd-person/eba4d98e-baa7-4ee3-a705-ed38b137ee27",
              "geometry": null
            },
            "contactInfo": {
              "email": "slaterm@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3 Starr Ridge Road Suite 204, Brewster, NY 10509",
              "phone1": null,
              "phone2": "845-278-2923",
              "fax1": null,
              "fax2": "845-278-2926",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Matt-Slater",
                "https://nyassembly.gov/mem/Matt-Slater"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-94-lower-ocd-person-eba4d98e-baa7-4ee3-a705-ed38b137ee27": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/3069edfe-7ff7-4a14-8223-f23b294b3a04",
            "firstName": "Dana",
            "lastName": "Levenberg",
            "fullName": "Dana Levenberg",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/095/headshot/095.jpg?hst=1680123726",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-NY-95-lower-ocd-person-3069edfe-7ff7-4a14-8223-f23b294b3a04",
              "builtID": "ny-lower-95",
              "externalID": "ocd-person/3069edfe-7ff7-4a14-8223-f23b294b3a04",
              "geometry": null
            },
            "contactInfo": {
              "email": "levenbergd@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "8 Revolutionary Road, Ossining, NY 10562",
              "phone1": null,
              "phone2": "914-941-1111",
              "fax1": null,
              "fax2": "914-941-9132",
              "twitter": "AMDanaLevenberg",
              "youtube": null,
              "instagram": null,
              "facebook": "AMDanaLevenberg",
              "urls": [
                "https://assembly.state.ny.us/mem/Dana-Levenberg",
                "https://nyassembly.gov/mem/Dana-Levenberg"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-95-lower-ocd-person-3069edfe-7ff7-4a14-8223-f23b294b3a04": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/6eb9bfcc-dbc3-45cc-9995-1628a00ae110",
            "firstName": "John W.",
            "lastName": "McGowan",
            "fullName": "John McGowan",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://assembly.state.ny.us/write/upload/member_files/097/headshot/097.jpg?hst=1672575005",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-NY-97-lower-ocd-person-6eb9bfcc-dbc3-45cc-9995-1628a00ae110",
              "builtID": "ny-lower-97",
              "externalID": "ocd-person/6eb9bfcc-dbc3-45cc-9995-1628a00ae110",
              "geometry": null
            },
            "contactInfo": {
              "email": "mcgowanj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1549 1 Blue Hill Plaza, Suite 1116, Pearl River, NY 10965",
              "phone1": null,
              "phone2": "845-624-4601",
              "fax1": null,
              "fax2": "845-624-2911",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/John-W-McGowan",
                "https://nyassembly.gov/mem/John-W-McGowan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-97-lower-ocd-person-6eb9bfcc-dbc3-45cc-9995-1628a00ae110": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/8378f15a-1d99-4cd6-aaad-f36b3bc57e1e",
            "firstName": "Karl A.",
            "lastName": "Brabenec",
            "fullName": "Karl Brabenec",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/098/headshot/098.jpg?hst=1660074303",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-NY-98-lower-ocd-person-8378f15a-1d99-4cd6-aaad-f36b3bc57e1e",
              "builtID": "ny-lower-98",
              "externalID": "ocd-person/8378f15a-1d99-4cd6-aaad-f36b3bc57e1e",
              "geometry": null
            },
            "contactInfo": {
              "email": "brabeneck@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "28 N. Main St. Suite 2, Florida, NY 10921",
              "phone1": null,
              "phone2": "845-544-7551",
              "fax1": null,
              "fax2": "845-544-7553",
              "twitter": "KarlBrabenec",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Karl-Brabenec",
                "https://nyassembly.gov/mem/Karl-Brabenec"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-98-lower-ocd-person-8378f15a-1d99-4cd6-aaad-f36b3bc57e1e": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/fe66bda1-2564-4f24-8798-0cd8993a0f12",
            "firstName": "Christopher William",
            "lastName": "Eachus",
            "fullName": "Chris Eachus",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/099/headshot/099.jpg?hst=1672570603",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-NY-99-lower-ocd-person-fe66bda1-2564-4f24-8798-0cd8993a0f12",
              "builtID": "ny-lower-99",
              "externalID": "ocd-person/fe66bda1-2564-4f24-8798-0cd8993a0f12",
              "geometry": null
            },
            "contactInfo": {
              "email": "eachusc@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "34 Smith Clove Road, Central Valley, NY 10917",
              "phone1": null,
              "phone2": "845-469-6929",
              "fax1": null,
              "fax2": "845-460-2185",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Chris-Eachus",
                "https://nyassembly.gov/mem/Chris-Eachus"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-99-lower-ocd-person-fe66bda1-2564-4f24-8798-0cd8993a0f12": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/08a4ab21-c0f2-4ae5-ae36-70062cbc5c4e",
            "firstName": "Aileen M.",
            "lastName": "Gunther",
            "fullName": "Aileen Gunther",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/100/headshot/100.jpg?hst=1660074329",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-NY-100-lower-ocd-person-08a4ab21-c0f2-4ae5-ae36-70062cbc5c4e",
              "builtID": "ny-lower-100",
              "externalID": "ocd-person/08a4ab21-c0f2-4ae5-ae36-70062cbc5c4e",
              "geometry": null
            },
            "contactInfo": {
              "email": "gunthea@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Middletown City Hall 16 James St., 3rd Floor, Middletown, NY 10940",
              "phone1": null,
              "phone2": "845-342-9304",
              "fax1": null,
              "fax2": null,
              "twitter": "AileenMGunther",
              "youtube": null,
              "instagram": null,
              "facebook": "291645097649558?ref=hl",
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Aileen-M-Gunther",
                "https://nyassembly.gov/mem/aileen-m-gunther"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-100-lower-ocd-person-08a4ab21-c0f2-4ae5-ae36-70062cbc5c4e": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/2830bc0a-4507-4710-8c4f-17b3ac5d414b",
            "firstName": "Brian",
            "lastName": "Maher",
            "fullName": "Brian Maher",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://assembly.state.ny.us/write/upload/member_files/101/headshot/101.jpg?hst=1672566660",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-NY-101-lower-ocd-person-2830bc0a-4507-4710-8c4f-17b3ac5d414b",
              "builtID": "ny-lower-101",
              "externalID": "ocd-person/2830bc0a-4507-4710-8c4f-17b3ac5d414b",
              "geometry": null
            },
            "contactInfo": {
              "email": "maherb@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6 Depot St. Suite 103, Washingtonville, NY 10992",
              "phone1": null,
              "phone2": "845-379-5001",
              "fax1": null,
              "fax2": "845-497-0286",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Brian-Maher",
                "https://nyassembly.gov/mem/Brian-Maher"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-101-lower-ocd-person-2830bc0a-4507-4710-8c4f-17b3ac5d414b": 0
        }
      },
      "102": {
        "members": [
          {
            "API_ID": "ocd-person/4af5cab3-9d9c-4d27-8eda-d39dcaa74bd0",
            "firstName": "Christopher",
            "lastName": "Tague",
            "fullName": "Chris Tague",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/102/headshot/102.jpg?hst=1660074360",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "102",
              "chamber": "lower",
              "hashID": "SLDL-NY-102-lower-ocd-person-4af5cab3-9d9c-4d27-8eda-d39dcaa74bd0",
              "builtID": "ny-lower-102",
              "externalID": "ocd-person/4af5cab3-9d9c-4d27-8eda-d39dcaa74bd0",
              "geometry": null
            },
            "contactInfo": {
              "email": "taguec@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "45 Five Mile Woods Road Suite 3, Catskill, NY 12414",
              "phone1": null,
              "phone2": "518-943-1371",
              "fax1": null,
              "fax2": "518-943-0223",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Chris-Tague",
                "https://nyassembly.gov/mem/Chris-Tague/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-102-lower-ocd-person-4af5cab3-9d9c-4d27-8eda-d39dcaa74bd0": 0
        }
      },
      "103": {
        "members": [
          {
            "API_ID": "ocd-person/01fc9264-bdd4-4259-9e51-dbf2c888b285",
            "firstName": "Sarahana",
            "lastName": "Shrestha",
            "fullName": "Sarahana Shrestha",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/103/headshot/103.jpg?hst=1672577142",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "103",
              "chamber": "lower",
              "hashID": "SLDL-NY-103-lower-ocd-person-01fc9264-bdd4-4259-9e51-dbf2c888b285",
              "builtID": "ny-lower-103",
              "externalID": "ocd-person/01fc9264-bdd4-4259-9e51-dbf2c888b285",
              "geometry": null
            },
            "contactInfo": {
              "email": "shresthas@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "324 Washington Ave. Suite 1, Kingston, NY 12401",
              "phone1": null,
              "phone2": "845-338-9610",
              "fax1": null,
              "fax2": "845-338-9590",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Sarahana-Shrestha",
                "https://nyassembly.gov/mem/Sarahana-Shrestha"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-103-lower-ocd-person-01fc9264-bdd4-4259-9e51-dbf2c888b285": 0
        }
      },
      "104": {
        "members": [
          {
            "API_ID": "ocd-person/105874fa-018e-4e77-8190-7f6d59538386",
            "firstName": "Jonathan G.",
            "lastName": "Jacobson",
            "fullName": "Jonathan Jacobson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/104/headshot/104.jpg?hst=1660074380",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "104",
              "chamber": "lower",
              "hashID": "SLDL-NY-104-lower-ocd-person-105874fa-018e-4e77-8190-7f6d59538386",
              "builtID": "ny-lower-104",
              "externalID": "ocd-person/105874fa-018e-4e77-8190-7f6d59538386",
              "geometry": null
            },
            "contactInfo": {
              "email": "jacobsonj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "One Civic Center Plaza Suite 101, Poughkeepsie, NY 12601",
              "phone1": null,
              "phone2": "845-763-7011",
              "fax1": null,
              "fax2": null,
              "twitter": "JacobsonNY104",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Jonathan-G-Jacobson",
                "https://nyassembly.gov/mem/Jonathan-G-Jacobson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-104-lower-ocd-person-105874fa-018e-4e77-8190-7f6d59538386": 0
        }
      },
      "105": {
        "members": [
          {
            "API_ID": "ocd-person/b4820549-d7f0-4f0f-abef-74d3b1515ed9",
            "firstName": "Anil Roodal",
            "lastName": "Beephan",
            "fullName": "A.J. Beephan",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://assembly.state.ny.us/write/upload/member_files/105/headshot/105.jpg?hst=1672566071",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "105",
              "chamber": "lower",
              "hashID": "SLDL-NY-105-lower-ocd-person-b4820549-d7f0-4f0f-abef-74d3b1515ed9",
              "builtID": "ny-lower-105",
              "externalID": "ocd-person/b4820549-d7f0-4f0f-abef-74d3b1515ed9",
              "geometry": null
            },
            "contactInfo": {
              "email": "beephana@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "North Hopewell Plaza 1075 Route 82, Suite 1, Hopewell Junction, NY 12533",
              "phone1": null,
              "phone2": "845-221-2202",
              "fax1": null,
              "fax2": "845-221-2225",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Anil-Beephan-Jr",
                "https://nyassembly.gov/mem/Anil-Beephan-Jr"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-105-lower-ocd-person-b4820549-d7f0-4f0f-abef-74d3b1515ed9": 0
        }
      },
      "106": {
        "members": [
          {
            "API_ID": "ocd-person/da883f7a-698b-49d6-8519-11bab87823cf",
            "firstName": "Didi",
            "lastName": "Barrett",
            "fullName": "Didi Barrett",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/106/headshot/106.jpg?hst=1660074414",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "106",
              "chamber": "lower",
              "hashID": "SLDL-NY-106-lower-ocd-person-da883f7a-698b-49d6-8519-11bab87823cf",
              "builtID": "ny-lower-106",
              "externalID": "ocd-person/da883f7a-698b-49d6-8519-11bab87823cf",
              "geometry": null
            },
            "contactInfo": {
              "email": "barrettd@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "420 Warren St., Hudson, NY 12534",
              "phone1": null,
              "phone2": "518-828-1961",
              "fax1": null,
              "fax2": "518-828-5329",
              "twitter": "DidiBarrett106",
              "youtube": null,
              "instagram": null,
              "facebook": "AssemblymemberDidiBarrett",
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Didi-Barrett",
                "https://nyassembly.gov/mem/didi-barrett"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-106-lower-ocd-person-da883f7a-698b-49d6-8519-11bab87823cf": 0
        }
      },
      "107": {
        "members": [
          {
            "API_ID": "ocd-person/0ff06e12-5768-4c75-8d06-8e033796a78a",
            "firstName": "Scott Harris",
            "lastName": "Bendett",
            "fullName": "Scott Bendett",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://assembly.state.ny.us/write/upload/member_files/107/headshot/107.jpg?hst=1672572327",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "107",
              "chamber": "lower",
              "hashID": "SLDL-NY-107-lower-ocd-person-0ff06e12-5768-4c75-8d06-8e033796a78a",
              "builtID": "ny-lower-107",
              "externalID": "ocd-person/0ff06e12-5768-4c75-8d06-8e033796a78a",
              "geometry": null
            },
            "contactInfo": {
              "email": "bendetts@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1580 Columbia Turnpike Building 4, Suite 1A, Castleton-on-Hudson, NY 12033",
              "phone1": null,
              "phone2": "518-272-6149",
              "fax1": null,
              "fax2": "518-477-5404",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Scott-Bendett",
                "https://nyassembly.gov/mem/Scott-Bendett"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-107-lower-ocd-person-0ff06e12-5768-4c75-8d06-8e033796a78a": 0
        }
      },
      "108": {
        "members": [
          {
            "API_ID": "ocd-person/0ce05d4e-e231-4d3e-8ba5-179499230adc",
            "firstName": "John T.",
            "lastName": "McDonald",
            "fullName": "John McDonald",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/108/headshot/108.jpg?hst=1660074489",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "108",
              "chamber": "lower",
              "hashID": "SLDL-NY-108-lower-ocd-person-0ce05d4e-e231-4d3e-8ba5-179499230adc",
              "builtID": "ny-lower-108",
              "externalID": "ocd-person/0ce05d4e-e231-4d3e-8ba5-179499230adc",
              "geometry": null
            },
            "contactInfo": {
              "email": "mcdonaldj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "johnmcdonald108",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/John-T-McDonald-III",
                "https://nyassembly.gov/mem/John-T-McDonald-III"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-108-lower-ocd-person-0ce05d4e-e231-4d3e-8ba5-179499230adc": 0
        }
      },
      "109": {
        "members": [
          {
            "API_ID": "ocd-person/a3e0c7bc-45f6-4a6b-a092-1c124c149a6d",
            "firstName": "Patricia",
            "lastName": "Fahy",
            "fullName": "Pat Fahy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/109/headshot/109.jpg?hst=1660074584",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "109",
              "chamber": "lower",
              "hashID": "SLDL-NY-109-lower-ocd-person-a3e0c7bc-45f6-4a6b-a092-1c124c149a6d",
              "builtID": "ny-lower-109",
              "externalID": "ocd-person/a3e0c7bc-45f6-4a6b-a092-1c124c149a6d",
              "geometry": null
            },
            "contactInfo": {
              "email": "fahyp@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "PatriciaFahy109",
              "youtube": null,
              "instagram": null,
              "facebook": "AssemblymemberPatriciaFahy",
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Patricia-Fahy",
                "https://nyassembly.gov/mem/Patricia-Fahy"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-109-lower-ocd-person-a3e0c7bc-45f6-4a6b-a092-1c124c149a6d": 0
        }
      },
      "110": {
        "members": [
          {
            "API_ID": "ocd-person/968287e2-d20f-4e07-9f66-5765ca51b475",
            "firstName": "Phillip G.",
            "lastName": "Steck",
            "fullName": "Phil Steck",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/110/headshot/110.jpg?hst=1660074591",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "110",
              "chamber": "lower",
              "hashID": "SLDL-NY-110-lower-ocd-person-968287e2-d20f-4e07-9f66-5765ca51b475",
              "builtID": "ny-lower-110",
              "externalID": "ocd-person/968287e2-d20f-4e07-9f66-5765ca51b475",
              "geometry": null
            },
            "contactInfo": {
              "email": "steckp@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1609 Union St., Schenectady, NY 12309",
              "phone1": null,
              "phone2": "518-377-0902",
              "fax1": null,
              "fax2": "518-377-0458",
              "twitter": "steck_phil",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Phil-Steck",
                "https://nyassembly.gov/mem/Phil-Steck"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-110-lower-ocd-person-968287e2-d20f-4e07-9f66-5765ca51b475": 0
        }
      },
      "111": {
        "members": [
          {
            "API_ID": "ocd-person/0193e8ea-3830-45af-86e7-34427396a7b4",
            "firstName": "Angelo L.",
            "lastName": "Santabarbara",
            "fullName": "Angelo Santabarbara",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/111/headshot/111.jpg?hst=1660074600",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "111",
              "chamber": "lower",
              "hashID": "SLDL-NY-111-lower-ocd-person-0193e8ea-3830-45af-86e7-34427396a7b4",
              "builtID": "ny-lower-111",
              "externalID": "ocd-person/0193e8ea-3830-45af-86e7-34427396a7b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "santabarbaraa@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "433 State St., Schenectady, NY 12305",
              "phone1": null,
              "phone2": "518-382-2941",
              "fax1": null,
              "fax2": null,
              "twitter": "AsmSantabarbara",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Angelo-Santabarbara",
                "https://nyassembly.gov/mem/Angelo-Santabarbara/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-111-lower-ocd-person-0193e8ea-3830-45af-86e7-34427396a7b4": 0
        }
      },
      "112": {
        "members": [
          {
            "API_ID": "ocd-person/f83f11f2-bfdb-4f84-925a-ae04c97b990e",
            "firstName": "Mary Beth",
            "lastName": "Walsh",
            "fullName": "Mary Beth Walsh",
            "gender": "Female",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/112/headshot/112.jpg?hst=1660074618",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "112",
              "chamber": "lower",
              "hashID": "SLDL-NY-112-lower-ocd-person-f83f11f2-bfdb-4f84-925a-ae04c97b990e",
              "builtID": "ny-lower-112",
              "externalID": "ocd-person/f83f11f2-bfdb-4f84-925a-ae04c97b990e",
              "geometry": null
            },
            "contactInfo": {
              "email": "walshm@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "199 Milton Ave. Suite 2, Ballston Spa, NY 12020",
              "phone1": null,
              "phone2": "518-884-8010",
              "fax1": null,
              "fax2": "518-884-8041",
              "twitter": "MBWalsh112",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Mary-Beth-Walsh",
                "https://nyassembly.gov/mem/Mary-Beth-Walsh"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-112-lower-ocd-person-f83f11f2-bfdb-4f84-925a-ae04c97b990e": 0
        }
      },
      "113": {
        "members": [
          {
            "API_ID": "ocd-person/6d6f344e-39b8-401f-807e-c88924fb2793",
            "firstName": "Caroline Caird",
            "lastName": "Woerner",
            "fullName": "Carrie Woerner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/113/headshot/113.jpg?hst=1660074635",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "113",
              "chamber": "lower",
              "hashID": "SLDL-NY-113-lower-ocd-person-6d6f344e-39b8-401f-807e-c88924fb2793",
              "builtID": "ny-lower-113",
              "externalID": "ocd-person/6d6f344e-39b8-401f-807e-c88924fb2793",
              "geometry": null
            },
            "contactInfo": {
              "email": "woernerc@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "112 Spring St. Suite 205, Saratoga Springs, NY 12866",
              "phone1": null,
              "phone2": "518-584-5493",
              "fax1": null,
              "fax2": "518-584-5496",
              "twitter": "AMCarrieWoerner",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Carrie-Woerner",
                "https://nyassembly.gov/mem/Carrie-Woerner"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-113-lower-ocd-person-6d6f344e-39b8-401f-807e-c88924fb2793": 0
        }
      },
      "114": {
        "members": [
          {
            "API_ID": "ocd-person/22ef60e3-2d31-41a1-b063-03f307e6414e",
            "firstName": "Matthew J.",
            "lastName": "Simpson",
            "fullName": "Matt Simpson",
            "gender": "Male",
            "party": "republican/conservative/independence",
            "image": "https://assembly.state.ny.us/write/upload/member_files/114/headshot/114.jpg?hst=1660074645",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "114",
              "chamber": "lower",
              "hashID": "SLDL-NY-114-lower-ocd-person-22ef60e3-2d31-41a1-b063-03f307e6414e",
              "builtID": "ny-lower-114",
              "externalID": "ocd-person/22ef60e3-2d31-41a1-b063-03f307e6414e",
              "geometry": null
            },
            "contactInfo": {
              "email": "simpsonm@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4 Southwestern Ave. Suite 3, Queensbury, NY 12804",
              "phone1": null,
              "phone2": "518-792-4546",
              "fax1": null,
              "fax2": "518-792-5584",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Matthew-Simpson",
                "https://nyassembly.gov/mem/Matthew-Simpson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-114-lower-ocd-person-22ef60e3-2d31-41a1-b063-03f307e6414e": 0
        }
      },
      "115": {
        "members": [
          {
            "API_ID": "ocd-person/1be1802e-14ac-4fc2-afc2-e8087257ea34",
            "firstName": "D. Billy",
            "lastName": "Jones",
            "fullName": "Billy Jones",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/115/headshot/115.jpg?hst=1660074656",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "115",
              "chamber": "lower",
              "hashID": "SLDL-NY-115-lower-ocd-person-1be1802e-14ac-4fc2-afc2-e8087257ea34",
              "builtID": "ny-lower-115",
              "externalID": "ocd-person/1be1802e-14ac-4fc2-afc2-e8087257ea34",
              "geometry": null
            },
            "contactInfo": {
              "email": "jonesb@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "202 U.S. Oval, Plattsburgh, NY 12903",
              "phone1": null,
              "phone2": "518-562-1986",
              "fax1": null,
              "fax2": null,
              "twitter": "jonesnyassembly",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Billy-Jones",
                "https://nyassembly.gov/mem/Billy-Jones"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-115-lower-ocd-person-1be1802e-14ac-4fc2-afc2-e8087257ea34": 0
        }
      },
      "116": {
        "members": [
          {
            "API_ID": "ocd-person/556d38b7-19bd-491e-aa78-ac9bc94bc431",
            "firstName": "Scott Andrew",
            "lastName": "Gray",
            "fullName": "Scott Gray",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/116/headshot/116.jpg?hst=1672565401",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "116",
              "chamber": "lower",
              "hashID": "SLDL-NY-116-lower-ocd-person-556d38b7-19bd-491e-aa78-ac9bc94bc431",
              "builtID": "ny-lower-116",
              "externalID": "ocd-person/556d38b7-19bd-491e-aa78-ac9bc94bc431",
              "geometry": null
            },
            "contactInfo": {
              "email": "grays@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Dulles State Office Building 317 Washington St., Suite 210, Watertown, NY 13601",
              "phone1": null,
              "phone2": "315-786-0284",
              "fax1": null,
              "fax2": "315-786-0287",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Scott-Gray",
                "https://nyassembly.gov/mem/Scott-Gray"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-116-lower-ocd-person-556d38b7-19bd-491e-aa78-ac9bc94bc431": 0
        }
      },
      "117": {
        "members": [
          {
            "API_ID": "ocd-person/f9d3d599-6a28-4110-9347-8a5e35d2274a",
            "firstName": "Kenneth D.",
            "lastName": "Blankenbush",
            "fullName": "Ken Blankenbush",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/117/headshot/117.jpg?hst=1660074678",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "117",
              "chamber": "lower",
              "hashID": "SLDL-NY-117-lower-ocd-person-f9d3d599-6a28-4110-9347-8a5e35d2274a",
              "builtID": "ny-lower-117",
              "externalID": "ocd-person/f9d3d599-6a28-4110-9347-8a5e35d2274a",
              "geometry": null
            },
            "contactInfo": {
              "email": "blankenbushk@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "40 Franklin St. Suite 2, Carthage, NY 13619",
              "phone1": null,
              "phone2": "315-493-3909",
              "fax1": null,
              "fax2": null,
              "twitter": "blankenbush",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Ken-Blankenbush",
                "https://nyassembly.gov/mem/ken-blankenbush"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-117-lower-ocd-person-f9d3d599-6a28-4110-9347-8a5e35d2274a": 0
        }
      },
      "118": {
        "members": [
          {
            "API_ID": "ocd-person/1aed07cb-5108-40a1-a845-93f6848062dc",
            "firstName": "Robert J.",
            "lastName": "Smullen",
            "fullName": "Robert Smullen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/118/headshot/118.jpg?hst=1660074686",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "118",
              "chamber": "lower",
              "hashID": "SLDL-NY-118-lower-ocd-person-1aed07cb-5108-40a1-a845-93f6848062dc",
              "builtID": "ny-lower-118",
              "externalID": "ocd-person/1aed07cb-5108-40a1-a845-93f6848062dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "smullenr@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Fulton County Office Building 223 W. Main St., Suite B-2, Johnstown, NY 12095",
              "phone1": null,
              "phone2": "518-762-1427",
              "fax1": null,
              "fax2": "518-762-9871",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Robert-Smullen",
                "https://nyassembly.gov/mem/Robert-Smullen"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-118-lower-ocd-person-1aed07cb-5108-40a1-a845-93f6848062dc": 0
        }
      },
      "119": {
        "members": [
          {
            "API_ID": "ocd-person/ac084b72-95c0-46df-96c9-be727ccf35ff",
            "firstName": "Marianne Goodman",
            "lastName": "Buttenschon",
            "fullName": "Marianne Buttenschon",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/119/headshot/119.jpg?hst=1660074697",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "119",
              "chamber": "lower",
              "hashID": "SLDL-NY-119-lower-ocd-person-ac084b72-95c0-46df-96c9-be727ccf35ff",
              "builtID": "ny-lower-119",
              "externalID": "ocd-person/ac084b72-95c0-46df-96c9-be727ccf35ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "buttenschonm@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 401, 207 Genesee St., Utica, NY 13501",
              "phone1": null,
              "phone2": "315-732-1055",
              "fax1": null,
              "fax2": "315-732-1413",
              "twitter": "mbuttenschon_NY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Marianne-Buttenschon",
                "https://nyassembly.gov/mem/Marianne-Buttenschon"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-119-lower-ocd-person-ac084b72-95c0-46df-96c9-be727ccf35ff": 0
        }
      },
      "120": {
        "members": [
          {
            "API_ID": "ocd-person/285393ce-64cc-4c07-88ac-79fe18e1fc6b",
            "firstName": "William Anson",
            "lastName": "Barclay",
            "fullName": "Will Barclay",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/120/headshot/120.jpg?hst=1660074706",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "120",
              "chamber": "lower",
              "hashID": "SLDL-NY-120-lower-ocd-person-285393ce-64cc-4c07-88ac-79fe18e1fc6b",
              "builtID": "ny-lower-120",
              "externalID": "ocd-person/285393ce-64cc-4c07-88ac-79fe18e1fc6b",
              "geometry": null
            },
            "contactInfo": {
              "email": "barclaw@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "19 Canalview Mall, Fulton, NY 13069",
              "phone1": null,
              "phone2": "315-598-5185",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/William-A-Barclay",
                "https://nyassembly.gov/mem/william-a-barclay"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-120-lower-ocd-person-285393ce-64cc-4c07-88ac-79fe18e1fc6b": 0
        }
      },
      "121": {
        "members": [
          {
            "API_ID": "ocd-person/cc6881df-b386-488d-bbc1-71e71b808306",
            "firstName": "Joseph",
            "lastName": "Angelino",
            "fullName": "Joe Angelino",
            "gender": "Male",
            "party": "republican/conservative/independence",
            "image": "https://assembly.state.ny.us/write/upload/member_files/121/headshot/121.jpg?hst=1660074724",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "121",
              "chamber": "lower",
              "hashID": "SLDL-NY-121-lower-ocd-person-cc6881df-b386-488d-bbc1-71e71b808306",
              "builtID": "ny-lower-121",
              "externalID": "ocd-person/cc6881df-b386-488d-bbc1-71e71b808306",
              "geometry": null
            },
            "contactInfo": {
              "email": "angelinoj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1 Kattelville Road Suite 1, Binghamton, NY 13901",
              "phone1": null,
              "phone2": "607-648-6080",
              "fax1": null,
              "fax2": "607-648-6089",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Joe-Angelino",
                "https://nyassembly.gov/mem/Joe-Angelino"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-121-lower-ocd-person-cc6881df-b386-488d-bbc1-71e71b808306": 0
        }
      },
      "122": {
        "members": [
          {
            "API_ID": "ocd-person/ad93da57-8a9a-4dc0-bc2c-2488c0331d16",
            "firstName": "Brian D.",
            "lastName": "Miller",
            "fullName": "Brian Miller",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/122/headshot/122.jpg?hst=1660074350",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "122",
              "chamber": "lower",
              "hashID": "SLDL-NY-122-lower-ocd-person-ad93da57-8a9a-4dc0-bc2c-2488c0331d16",
              "builtID": "ny-lower-122",
              "externalID": "ocd-person/ad93da57-8a9a-4dc0-bc2c-2488c0331d16",
              "geometry": null
            },
            "contactInfo": {
              "email": "millerb@nysassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "48 Genesee St., New Hartford, NY 13413",
              "phone1": null,
              "phone2": "315-736-3879",
              "fax1": null,
              "fax2": "315-736-3947",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Brian-D-Miller",
                "https://nyassembly.gov/mem/Brian-D-Miller"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-122-lower-ocd-person-ad93da57-8a9a-4dc0-bc2c-2488c0331d16": 0
        }
      },
      "123": {
        "members": [
          {
            "API_ID": "ocd-person/d6939215-ec08-437b-beea-3b9898d0f61b",
            "firstName": "Donna A.",
            "lastName": "Lupardo",
            "fullName": "Donna Lupardo",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/123/headshot/123.jpg?hst=1674746628",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "123",
              "chamber": "lower",
              "hashID": "SLDL-NY-123-lower-ocd-person-d6939215-ec08-437b-beea-3b9898d0f61b",
              "builtID": "ny-lower-123",
              "externalID": "ocd-person/d6939215-ec08-437b-beea-3b9898d0f61b",
              "geometry": null
            },
            "contactInfo": {
              "email": "lupardod@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Office Building, 17th Floor 44 Hawley St., Binghamton, NY 13901",
              "phone1": null,
              "phone2": "607-723-9047",
              "fax1": null,
              "fax2": null,
              "twitter": "DonnaLupardo",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Donna-A-Lupardo",
                "https://nyassembly.gov/mem/donna-a-lupardo"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-123-lower-ocd-person-d6939215-ec08-437b-beea-3b9898d0f61b": 0
        }
      },
      "124": {
        "members": [
          {
            "API_ID": "ocd-person/a73679bf-ac98-4319-95b8-d226bc9aba77",
            "firstName": "Christopher S.",
            "lastName": "Friend",
            "fullName": "Chris Friend",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/124/headshot/124.jpg?hst=1660074744",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "124",
              "chamber": "lower",
              "hashID": "SLDL-NY-124-lower-ocd-person-a73679bf-ac98-4319-95b8-d226bc9aba77",
              "builtID": "ny-lower-124",
              "externalID": "ocd-person/a73679bf-ac98-4319-95b8-d226bc9aba77",
              "geometry": null
            },
            "contactInfo": {
              "email": "friendc@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1250 Schweizer Road, Horseheads, NY 14845",
              "phone1": null,
              "phone2": "607-562-3602",
              "fax1": null,
              "fax2": "607-739-1090",
              "twitter": "Friend4Assembly",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Christopher-S-Friend",
                "https://nyassembly.gov/mem/christopher-s-friend"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-124-lower-ocd-person-a73679bf-ac98-4319-95b8-d226bc9aba77": 0
        }
      },
      "125": {
        "members": [
          {
            "API_ID": "ocd-person/edde17c2-c810-4ec8-97c3-4cc0ea2e32d0",
            "firstName": "Anna R.",
            "lastName": "Kelles",
            "fullName": "Anna Kelles",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/125/headshot/125.jpg?hst=1660074752",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "125",
              "chamber": "lower",
              "hashID": "SLDL-NY-125-lower-ocd-person-edde17c2-c810-4ec8-97c3-4cc0ea2e32d0",
              "builtID": "ny-lower-125",
              "externalID": "ocd-person/edde17c2-c810-4ec8-97c3-4cc0ea2e32d0",
              "geometry": null
            },
            "contactInfo": {
              "email": "kellesa@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "83 Main St., Cortland, NY 13045",
              "phone1": null,
              "phone2": "607-208-2024",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Anna-Kelles",
                "https://assembly.state.ny.us/mem/Anna-R-Kelles",
                "https://nyassembly.gov/mem/Anna-R-Kelles"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-125-lower-ocd-person-edde17c2-c810-4ec8-97c3-4cc0ea2e32d0": 0
        }
      },
      "126": {
        "members": [
          {
            "API_ID": "ocd-person/21b7fbbb-6f39-4251-884d-3d4a146a0874",
            "firstName": "John",
            "lastName": "Lemondes",
            "fullName": "John Lemondes",
            "gender": "Male",
            "party": "republican/conservative/independence",
            "image": "https://assembly.state.ny.us/write/upload/member_files/126/headshot/126.jpg?hst=1660074773",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "126",
              "chamber": "lower",
              "hashID": "SLDL-NY-126-lower-ocd-person-21b7fbbb-6f39-4251-884d-3d4a146a0874",
              "builtID": "ny-lower-126",
              "externalID": "ocd-person/21b7fbbb-6f39-4251-884d-3d4a146a0874",
              "geometry": null
            },
            "contactInfo": {
              "email": "lemondesj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "69 South St., Auburn, NY 13021",
              "phone1": null,
              "phone2": "315-255-3045",
              "fax1": null,
              "fax2": "315-255-3048",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/John-Lemondes",
                "https://nyassembly.gov/mem/John-Lemondes"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-126-lower-ocd-person-21b7fbbb-6f39-4251-884d-3d4a146a0874": 0
        }
      },
      "127": {
        "members": [
          {
            "API_ID": "ocd-person/dff234fa-8563-4028-982e-c0a221d99fd9",
            "firstName": "Albert A.",
            "lastName": "Stirpe",
            "fullName": "Al Stirpe",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/127/headshot/127.jpg?hst=1660074782",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "127",
              "chamber": "lower",
              "hashID": "SLDL-NY-127-lower-ocd-person-dff234fa-8563-4028-982e-c0a221d99fd9",
              "builtID": "ny-lower-127",
              "externalID": "ocd-person/dff234fa-8563-4028-982e-c0a221d99fd9",
              "geometry": null
            },
            "contactInfo": {
              "email": "stirpea@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7293 Buckley Road Suite 201, North Syracuse, NY 13212",
              "phone1": null,
              "phone2": "315-452-1115",
              "fax1": null,
              "fax2": null,
              "twitter": "StirpeAl",
              "youtube": null,
              "instagram": null,
              "facebook": "AssemblymanAlStirpe",
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Al-Stirpe",
                "https://nyassembly.gov/mem/Al-Stirpe"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-127-lower-ocd-person-dff234fa-8563-4028-982e-c0a221d99fd9": 0
        }
      },
      "128": {
        "members": [
          {
            "API_ID": "ocd-person/10fb37a0-e41f-4129-9ed2-7c5b674807da",
            "firstName": "Pamela J.",
            "lastName": "Hunter",
            "fullName": "Pamela Hunter",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/128/headshot/128.jpg?hst=1660074792",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "128",
              "chamber": "lower",
              "hashID": "SLDL-NY-128-lower-ocd-person-10fb37a0-e41f-4129-9ed2-7c5b674807da",
              "builtID": "ny-lower-128",
              "externalID": "ocd-person/10fb37a0-e41f-4129-9ed2-7c5b674807da",
              "geometry": null
            },
            "contactInfo": {
              "email": "hunterp@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "711 E. Genesee St. 2nd Floor, Syracuse, NY 13210",
              "phone1": null,
              "phone2": "315-449-9536",
              "fax1": null,
              "fax2": null,
              "twitter": "PamelaHunter128",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Pamela-J-Hunter",
                "https://nyassembly.gov/mem/Pamela-J-Hunter"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-128-lower-ocd-person-10fb37a0-e41f-4129-9ed2-7c5b674807da": 0
        }
      },
      "129": {
        "members": [
          {
            "API_ID": "ocd-person/0507a521-7806-4d5c-ac7d-e50599bb32a6",
            "firstName": "William B.",
            "lastName": "Magnarelli",
            "fullName": "Bill Magnarelli",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/129/headshot/129.jpg?hst=1660074804",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "129",
              "chamber": "lower",
              "hashID": "SLDL-NY-129-lower-ocd-person-0507a521-7806-4d5c-ac7d-e50599bb32a6",
              "builtID": "ny-lower-129",
              "externalID": "ocd-person/0507a521-7806-4d5c-ac7d-e50599bb32a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "magnarw@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 840, 333 E. Washington St., Syracuse, NY 13202",
              "phone1": null,
              "phone2": "315-428-9651",
              "fax1": null,
              "fax2": null,
              "twitter": "BillMagnarelli",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/William-B-Magnarelli",
                "https://nyassembly.gov/mem/william-b-magnarelli"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-129-lower-ocd-person-0507a521-7806-4d5c-ac7d-e50599bb32a6": 0
        }
      },
      "130": {
        "members": [
          {
            "API_ID": "ocd-person/2ef71a27-afab-49e4-9c52-d3954352242f",
            "firstName": "Brian David",
            "lastName": "Manktelow",
            "fullName": "Brian Manktelow",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/130/headshot/130.jpg?hst=1660074813",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "130",
              "chamber": "lower",
              "hashID": "SLDL-NY-130-lower-ocd-person-2ef71a27-afab-49e4-9c52-d3954352242f",
              "builtID": "ny-lower-130",
              "externalID": "ocd-person/2ef71a27-afab-49e4-9c52-d3954352242f",
              "geometry": null
            },
            "contactInfo": {
              "email": "manktelowb@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "10 Leach Road, Lyons, NY 14489",
              "phone1": null,
              "phone2": "315-946-5166",
              "fax1": null,
              "fax2": "315-946-5229",
              "twitter": "BrianDManktelow",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Brian-Manktelow",
                "https://nyassembly.gov/mem/Brian-Manktelow"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-130-lower-ocd-person-2ef71a27-afab-49e4-9c52-d3954352242f": 0
        }
      },
      "131": {
        "members": [
          {
            "API_ID": "ocd-person/4910460d-6cb9-4f35-8459-19e5a4de579e",
            "firstName": "Jeff L.",
            "lastName": "Gallahan",
            "fullName": "Jeff Gallahan",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://assembly.state.ny.us/write/upload/member_files/131/headshot/131.jpg?hst=1660074826",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "131",
              "chamber": "lower",
              "hashID": "SLDL-NY-131-lower-ocd-person-4910460d-6cb9-4f35-8459-19e5a4de579e",
              "builtID": "ny-lower-131",
              "externalID": "ocd-person/4910460d-6cb9-4f35-8459-19e5a4de579e",
              "geometry": null
            },
            "contactInfo": {
              "email": "gallahanj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "70 Elizabeth Blackwell St., Geneva, NY 14456",
              "phone1": null,
              "phone2": "315-781-2030",
              "fax1": null,
              "fax2": "315-781-1746",
              "twitter": "JeffGallahan",
              "youtube": null,
              "instagram": null,
              "facebook": "Assemblyman-Jeff-Gallahan-100849288636894",
              "urls": [
                "https://assembly.state.ny.us/mem/Jeff-Gallahan",
                "https://nyassembly.gov/mem/Jeff-Gallahan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-131-lower-ocd-person-4910460d-6cb9-4f35-8459-19e5a4de579e": 0
        }
      },
      "132": {
        "members": [
          {
            "API_ID": "ocd-person/ea41f6b0-3275-4384-9425-c87f8a115da3",
            "firstName": "Philip A.",
            "lastName": "Palmesano",
            "fullName": "Phil Palmesano",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/132/headshot/132.jpg?hst=1660074839",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "132",
              "chamber": "lower",
              "hashID": "SLDL-NY-132-lower-ocd-person-ea41f6b0-3275-4384-9425-c87f8a115da3",
              "builtID": "ny-lower-132",
              "externalID": "ocd-person/ea41f6b0-3275-4384-9425-c87f8a115da3",
              "geometry": null
            },
            "contactInfo": {
              "email": "palmesanop@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "105 E. Steuben St., Bath, NY 14810",
              "phone1": null,
              "phone2": "607-776-9691",
              "fax1": null,
              "fax2": "607-776-5185",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Philip-A-Palmesano",
                "https://nyassembly.gov/mem/philip-a-palmesano"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-132-lower-ocd-person-ea41f6b0-3275-4384-9425-c87f8a115da3": 0
        }
      },
      "133": {
        "members": [
          {
            "API_ID": "ocd-person/d25b8537-c85f-4e66-a3da-9cb43cbd558e",
            "firstName": "Marjorie L.",
            "lastName": "Byrnes",
            "fullName": "Marjorie Byrnes",
            "gender": "Female",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/133/headshot/133.jpg?hst=1660074849",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "133",
              "chamber": "lower",
              "hashID": "SLDL-NY-133-lower-ocd-person-d25b8537-c85f-4e66-a3da-9cb43cbd558e",
              "builtID": "ny-lower-133",
              "externalID": "ocd-person/d25b8537-c85f-4e66-a3da-9cb43cbd558e",
              "geometry": null
            },
            "contactInfo": {
              "email": "byrnesm@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "79 Genesee St., Avon, NY 14414",
              "phone1": null,
              "phone2": "585-218-0038",
              "fax1": null,
              "fax2": "585-226-2022",
              "twitter": "Byrnes4Assembly",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Marjorie-Byrnes",
                "https://nyassembly.gov/mem/Marjorie-Byrnes"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-133-lower-ocd-person-d25b8537-c85f-4e66-a3da-9cb43cbd558e": 0
        }
      },
      "134": {
        "members": [
          {
            "API_ID": "ocd-person/74b2a05d-ca30-400a-b773-7fb8e24ada63",
            "firstName": "Joshua Thomas",
            "lastName": "Jensen",
            "fullName": "Josh Jensen",
            "gender": "Male",
            "party": "republican/conservative/independence",
            "image": "https://assembly.state.ny.us/write/upload/member_files/134/headshot/134.jpg?hst=1660074858",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "134",
              "chamber": "lower",
              "hashID": "SLDL-NY-134-lower-ocd-person-74b2a05d-ca30-400a-b773-7fb8e24ada63",
              "builtID": "ny-lower-134",
              "externalID": "ocd-person/74b2a05d-ca30-400a-b773-7fb8e24ada63",
              "geometry": null
            },
            "contactInfo": {
              "email": "jensenj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2496 W. Ridge Road, Rochester, NY 14626",
              "phone1": null,
              "phone2": "585-225-4190",
              "fax1": null,
              "fax2": "585-225-6502",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Josh-Jensen",
                "https://nyassembly.gov/mem/Josh-Jensen"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-134-lower-ocd-person-74b2a05d-ca30-400a-b773-7fb8e24ada63": 0
        }
      },
      "135": {
        "members": [
          {
            "API_ID": "ocd-person/f6394f20-1953-434b-a2bb-2d255d4fafbe",
            "firstName": "Jennifer",
            "lastName": "Lunsford",
            "fullName": "Jen Lunsford",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/135/headshot/135.jpg?hst=1675087664",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "135",
              "chamber": "lower",
              "hashID": "SLDL-NY-135-lower-ocd-person-f6394f20-1953-434b-a2bb-2d255d4fafbe",
              "builtID": "ny-lower-135",
              "externalID": "ocd-person/f6394f20-1953-434b-a2bb-2d255d4fafbe",
              "geometry": null
            },
            "contactInfo": {
              "email": "lunsfordj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "317 Main St. Suite 2032, East Rochester, NY 14445",
              "phone1": null,
              "phone2": "585-223-9130",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Jennifer-Lunsford",
                "https://assembly.state.ny.us/mem/Jen-Lunsford",
                "https://nyassembly.gov/mem/Jennifer-Lunsford"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-135-lower-ocd-person-f6394f20-1953-434b-a2bb-2d255d4fafbe": 0
        }
      },
      "136": {
        "members": [
          {
            "API_ID": "ocd-person/fbb1dc03-2c64-431f-9aeb-6ee54c1c8ac6",
            "firstName": "Sarah Anderson",
            "lastName": "Clark",
            "fullName": "Sarah Clark",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/136/headshot/136.jpg?hst=1660074877",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "136",
              "chamber": "lower",
              "hashID": "SLDL-NY-136-lower-ocd-person-fbb1dc03-2c64-431f-9aeb-6ee54c1c8ac6",
              "builtID": "ny-lower-136",
              "externalID": "ocd-person/fbb1dc03-2c64-431f-9aeb-6ee54c1c8ac6",
              "geometry": null
            },
            "contactInfo": {
              "email": "clarks@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room Suite 4, 1800 Hudson Ave. Second Floor, Rochester, NY 14617",
              "phone1": null,
              "phone2": "585-467-0410",
              "fax1": null,
              "fax2": "585-467-5342",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Sarah-Clark",
                "https://nyassembly.gov/mem/Sarah-Clark/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-136-lower-ocd-person-fbb1dc03-2c64-431f-9aeb-6ee54c1c8ac6": 0
        }
      },
      "137": {
        "members": [
          {
            "API_ID": "ocd-person/34f326e1-6e7e-40b2-9ece-10856986cb1b",
            "firstName": "Demond",
            "lastName": "Meeks",
            "fullName": "Demond Meeks",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/137/headshot/137.jpg?hst=1660074888",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "137",
              "chamber": "lower",
              "hashID": "SLDL-NY-137-lower-ocd-person-34f326e1-6e7e-40b2-9ece-10856986cb1b",
              "builtID": "ny-lower-137",
              "externalID": "ocd-person/34f326e1-6e7e-40b2-9ece-10856986cb1b",
              "geometry": null
            },
            "contactInfo": {
              "email": "meeksd@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "614 W. Main St. Apartments 1-4, Rochester, NY 14611",
              "phone1": null,
              "phone2": "585-454-3670",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/Demond-Meeks",
                "https://nyassembly.gov/mem/Demond-Meeks"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-137-lower-ocd-person-34f326e1-6e7e-40b2-9ece-10856986cb1b": 0
        }
      },
      "138": {
        "members": [
          {
            "API_ID": "ocd-person/a23e140f-4beb-43d5-b850-198ff81a75f3",
            "firstName": "Harry B.",
            "lastName": "Bronson",
            "fullName": "Harry Bronson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/138/headshot/138.jpg?hst=1660074897",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "138",
              "chamber": "lower",
              "hashID": "SLDL-NY-138-lower-ocd-person-a23e140f-4beb-43d5-b850-198ff81a75f3",
              "builtID": "ny-lower-138",
              "externalID": "ocd-person/a23e140f-4beb-43d5-b850-198ff81a75f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "bronsonh@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "840 University Ave., Rochester, NY 14607",
              "phone1": null,
              "phone2": "585-244-5255",
              "fax1": null,
              "fax2": null,
              "twitter": "HarryBBronson",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Harry-B-Bronson",
                "https://nyassembly.gov/mem/harry-b-bronson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-138-lower-ocd-person-a23e140f-4beb-43d5-b850-198ff81a75f3": 0
        }
      },
      "139": {
        "members": [
          {
            "API_ID": "ocd-person/fa5dc497-02d8-4810-bae4-4651b76f1597",
            "firstName": "Stephen M.",
            "lastName": "Hawley",
            "fullName": "Steve Hawley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/139/headshot/139.jpg?hst=1660074922",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "139",
              "chamber": "lower",
              "hashID": "SLDL-NY-139-lower-ocd-person-fa5dc497-02d8-4810-bae4-4651b76f1597",
              "builtID": "ny-lower-139",
              "externalID": "ocd-person/fa5dc497-02d8-4810-bae4-4651b76f1597",
              "geometry": null
            },
            "contactInfo": {
              "email": "hawleys@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "121 N. Main St. Suite 100, Albion, NY 14411",
              "phone1": null,
              "phone2": "585-589-5780",
              "fax1": null,
              "fax2": "585-589-5813",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Stephen-Hawley",
                "https://nyassembly.gov/mem/stephen-hawley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-139-lower-ocd-person-fa5dc497-02d8-4810-bae4-4651b76f1597": 0
        }
      },
      "140": {
        "members": [
          {
            "API_ID": "ocd-person/b03e8fcb-e9e1-4096-9483-654b3383341b",
            "firstName": "William Charles",
            "lastName": "Conrad",
            "fullName": "Bill Conrad",
            "gender": "Male",
            "party": "democratic/independence/working families",
            "image": "https://assembly.state.ny.us/write/upload/member_files/140/headshot/140.jpg?hst=1660074930",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "140",
              "chamber": "lower",
              "hashID": "SLDL-NY-140-lower-ocd-person-b03e8fcb-e9e1-4096-9483-654b3383341b",
              "builtID": "ny-lower-140",
              "externalID": "ocd-person/b03e8fcb-e9e1-4096-9483-654b3383341b",
              "geometry": null
            },
            "contactInfo": {
              "email": "conradw@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "34 Peuquet Parkway, Tonawanda, NY 14150",
              "phone1": null,
              "phone2": "716-873-2540",
              "fax1": null,
              "fax2": "716-744-2644",
              "twitter": "140thBill",
              "youtube": null,
              "instagram": null,
              "facebook": "NYSAssemblyWilliamConrad",
              "urls": [
                "https://assembly.state.ny.us/mem/William-Conrad",
                "https://nyassembly.gov/mem/William-Conrad"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-140-lower-ocd-person-b03e8fcb-e9e1-4096-9483-654b3383341b": 0
        }
      },
      "141": {
        "members": [
          {
            "API_ID": "ocd-person/7638e8d0-2a84-4325-bcfd-7a2e8e604077",
            "firstName": "Crystal Davis",
            "lastName": "Peoples-Stokes",
            "fullName": "Crystal Peoples-Stokes",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/141/headshot/141.jpg?hst=1660074942",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "141",
              "chamber": "lower",
              "hashID": "SLDL-NY-141-lower-ocd-person-7638e8d0-2a84-4325-bcfd-7a2e8e604077",
              "builtID": "ny-lower-141",
              "externalID": "ocd-person/7638e8d0-2a84-4325-bcfd-7a2e8e604077",
              "geometry": null
            },
            "contactInfo": {
              "email": "peoplec@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "425 Michigan Ave., Buffalo, NY 14203",
              "phone1": null,
              "phone2": "716-897-9714",
              "fax1": null,
              "fax2": null,
              "twitter": "CPeoplesStokes",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Crystal-D-Peoples-Stokes",
                "https://nyassembly.gov/mem/crystal-d-peoples-stokes"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-141-lower-ocd-person-7638e8d0-2a84-4325-bcfd-7a2e8e604077": 0
        }
      },
      "142": {
        "members": [
          {
            "API_ID": "ocd-person/85c8422b-5132-4147-bdfc-f1cdec6d4731",
            "firstName": "Patrick B.",
            "lastName": "Burke",
            "fullName": "Pat Burke",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/142/headshot/142.jpg?hst=1660074954",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "142",
              "chamber": "lower",
              "hashID": "SLDL-NY-142-lower-ocd-person-85c8422b-5132-4147-bdfc-f1cdec6d4731",
              "builtID": "ny-lower-142",
              "externalID": "ocd-person/85c8422b-5132-4147-bdfc-f1cdec6d4731",
              "geometry": null
            },
            "contactInfo": {
              "email": "burkep@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3686 Seneca St., West Seneca, NY 14224",
              "phone1": null,
              "phone2": "716-608-6099",
              "fax1": null,
              "fax2": null,
              "twitter": "PatBurkeNY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Pat-Burke",
                "https://assembly.state.ny.us/mem/Patrick-Burke",
                "https://nyassembly.gov/mem/Patrick-Burke"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-142-lower-ocd-person-85c8422b-5132-4147-bdfc-f1cdec6d4731": 0
        }
      },
      "143": {
        "members": [
          {
            "API_ID": "ocd-person/919026bc-cb4b-4f9e-bdc7-decf42912544",
            "firstName": "Monica Piga",
            "lastName": "Wallace",
            "fullName": "Monica Wallace",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/143/headshot/143.jpg?hst=1660074964",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "143",
              "chamber": "lower",
              "hashID": "SLDL-NY-143-lower-ocd-person-919026bc-cb4b-4f9e-bdc7-decf42912544",
              "builtID": "ny-lower-143",
              "externalID": "ocd-person/919026bc-cb4b-4f9e-bdc7-decf42912544",
              "geometry": null
            },
            "contactInfo": {
              "email": "wallacem@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2562 Walden Ave. Suite 102, Cheektowaga, NY 14225",
              "phone1": null,
              "phone2": "716-686-0080",
              "fax1": null,
              "fax2": "716-686-3752",
              "twitter": "MonicaPWallace",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Monica-P-Wallace",
                "https://nyassembly.gov/mem/Monica-P-Wallace"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-143-lower-ocd-person-919026bc-cb4b-4f9e-bdc7-decf42912544": 0
        }
      },
      "144": {
        "members": [
          {
            "API_ID": "ocd-person/bd052fee-36be-4bb5-9397-f6e4c6c4533e",
            "firstName": "Michael J.",
            "lastName": "Norris",
            "fullName": "Mike Norris",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/144/headshot/144.jpg?hst=1660074973",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "144",
              "chamber": "lower",
              "hashID": "SLDL-NY-144-lower-ocd-person-bd052fee-36be-4bb5-9397-f6e4c6c4533e",
              "builtID": "ny-lower-144",
              "externalID": "ocd-person/bd052fee-36be-4bb5-9397-f6e4c6c4533e",
              "geometry": null
            },
            "contactInfo": {
              "email": "norrism@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "8180 Main St., Clarence, NY 14221",
              "phone1": null,
              "phone2": "716-839-4691",
              "fax1": null,
              "fax2": "716-839-4693",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Michael-J-Norris",
                "https://nyassembly.gov/mem/Michael-J-Norris"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-144-lower-ocd-person-bd052fee-36be-4bb5-9397-f6e4c6c4533e": 0
        }
      },
      "145": {
        "members": [
          {
            "API_ID": "ocd-person/461e374c-cde4-4865-9fec-141502275a8b",
            "firstName": "Angelo J.",
            "lastName": "Morinello",
            "fullName": "Angelo Morinello",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/145/headshot/145.jpg?hst=1660074985",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "145",
              "chamber": "lower",
              "hashID": "SLDL-NY-145-lower-ocd-person-461e374c-cde4-4865-9fec-141502275a8b",
              "builtID": "ny-lower-145",
              "externalID": "ocd-person/461e374c-cde4-4865-9fec-141502275a8b",
              "geometry": null
            },
            "contactInfo": {
              "email": "morinelloa@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "800 Main St. Suite 2C, Niagara Falls, NY 14301",
              "phone1": null,
              "phone2": "716-282-6062",
              "fax1": null,
              "fax2": "716-282-4226",
              "twitter": "VoteJudgeAngelo",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Angelo-J-Morinello",
                "https://nyassembly.gov/mem/Angelo-J-Morinello"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-145-lower-ocd-person-461e374c-cde4-4865-9fec-141502275a8b": 0
        }
      },
      "146": {
        "members": [
          {
            "API_ID": "ocd-person/e37b1683-f103-4c4d-80bd-1b255cc1ce08",
            "firstName": "Karen",
            "lastName": "McMahon",
            "fullName": "Karen McMahon",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/146/headshot/146.jpg?hst=1660074994",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "146",
              "chamber": "lower",
              "hashID": "SLDL-NY-146-lower-ocd-person-e37b1683-f103-4c4d-80bd-1b255cc1ce08",
              "builtID": "ny-lower-146",
              "externalID": "ocd-person/e37b1683-f103-4c4d-80bd-1b255cc1ce08",
              "geometry": null
            },
            "contactInfo": {
              "email": "mcmahonk@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5500 Main St. Suite 224, Williamsville, NY 14221",
              "phone1": null,
              "phone2": "716-634-1895",
              "fax1": null,
              "fax2": null,
              "twitter": "McMahonforNYS",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Karen-McMahon",
                "https://nyassembly.gov/mem/Karen-McMahon"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-146-lower-ocd-person-e37b1683-f103-4c4d-80bd-1b255cc1ce08": 0
        }
      },
      "147": {
        "members": [
          {
            "API_ID": "ocd-person/67c280c8-c9a5-4eff-93de-480570d6a414",
            "firstName": "David J.",
            "lastName": "DiPietro",
            "fullName": "Dave DiPietro",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/147/headshot/147.jpg?hst=1660075005",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "147",
              "chamber": "lower",
              "hashID": "SLDL-NY-147-lower-ocd-person-67c280c8-c9a5-4eff-93de-480570d6a414",
              "builtID": "ny-lower-147",
              "externalID": "ocd-person/67c280c8-c9a5-4eff-93de-480570d6a414",
              "geometry": null
            },
            "contactInfo": {
              "email": "dipietrod@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "411 Main St., East Aurora, NY 14052",
              "phone1": null,
              "phone2": "716-655-0951",
              "fax1": null,
              "fax2": "716-655-0970",
              "twitter": "DiPietro4NY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/David-DiPietro",
                "https://nyassembly.gov/mem/David-DiPietro"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-147-lower-ocd-person-67c280c8-c9a5-4eff-93de-480570d6a414": 0
        }
      },
      "148": {
        "members": [
          {
            "API_ID": "ocd-person/329185be-50d1-4672-97e7-51c8fcbf6958",
            "firstName": "Joseph M.",
            "lastName": "Giglio",
            "fullName": "Joe Giglio",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/148/headshot/148.jpg?hst=1660075014",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "148",
              "chamber": "lower",
              "hashID": "SLDL-NY-148-lower-ocd-person-329185be-50d1-4672-97e7-51c8fcbf6958",
              "builtID": "ny-lower-148",
              "externalID": "ocd-person/329185be-50d1-4672-97e7-51c8fcbf6958",
              "geometry": null
            },
            "contactInfo": {
              "email": "giglioj@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "700 W. State St., Olean, NY 14760",
              "phone1": null,
              "phone2": "716-373-7103",
              "fax1": null,
              "fax2": "716-373-7105",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Joseph-M-Giglio",
                "https://nyassembly.gov/mem/joseph-m-giglio"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-148-lower-ocd-person-329185be-50d1-4672-97e7-51c8fcbf6958": 0
        }
      },
      "149": {
        "members": [
          {
            "API_ID": "ocd-person/869acf23-9c50-48da-897c-515c5a7adcfe",
            "firstName": "Jonathan D.",
            "lastName": "Rivera",
            "fullName": "Jon Rivera",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://assembly.state.ny.us/write/upload/member_files/149/headshot/149.jpg?hst=1660075024",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "149",
              "chamber": "lower",
              "hashID": "SLDL-NY-149-lower-ocd-person-869acf23-9c50-48da-897c-515c5a7adcfe",
              "builtID": "ny-lower-149",
              "externalID": "ocd-person/869acf23-9c50-48da-897c-515c5a7adcfe",
              "geometry": null
            },
            "contactInfo": {
              "email": "riverajd@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "65 Grant St., Buffalo, NY 14213",
              "phone1": null,
              "phone2": "716-885-9630",
              "fax1": null,
              "fax2": "716-885-9636",
              "twitter": "JonRivera149",
              "youtube": null,
              "instagram": null,
              "facebook": "JonRivera149",
              "urls": [
                "https://assembly.state.ny.us/mem/Jonathan-Rivera",
                "https://nyassembly.gov/mem/Jonathan-Rivera"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-149-lower-ocd-person-869acf23-9c50-48da-897c-515c5a7adcfe": 0
        }
      },
      "150": {
        "members": [
          {
            "API_ID": "ocd-person/8df5afaf-8007-4ed2-993a-e3663e5e3ec6",
            "firstName": "Andrew W.",
            "lastName": "Goodell",
            "fullName": "Andy Goodell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://assembly.state.ny.us/write/upload/member_files/150/headshot/150.jpg?hst=1660075032",
            "title": "NY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "150",
              "chamber": "lower",
              "hashID": "SLDL-NY-150-lower-ocd-person-8df5afaf-8007-4ed2-993a-e3663e5e3ec6",
              "builtID": "ny-lower-150",
              "externalID": "ocd-person/8df5afaf-8007-4ed2-993a-e3663e5e3ec6",
              "geometry": null
            },
            "contactInfo": {
              "email": "goodella@nyassembly.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Fenton Building 2 E. 2nd St., Suite 320, Jamestown, NY 14701",
              "phone1": null,
              "phone2": "716-664-7773",
              "fax1": null,
              "fax2": "716-483-0299",
              "twitter": "andygoodell",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Andy-Goodell",
                "https://nyassembly.gov/mem/andy-goodell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NY-150-lower-ocd-person-8df5afaf-8007-4ed2-993a-e3663e5e3ec6": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/f13bda4e-bfb4-49fc-a986-ba8d2b3e7e43",
            "firstName": "Anthony Howard",
            "lastName": "Palumbo",
            "fullName": "Tony Palumbo",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/palumbo-headshot2.jpg?itok=iMC_RWH8",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-NY-1-upper-ocd-person-f13bda4e-bfb4-49fc-a986-ba8d2b3e7e43",
              "builtID": "ny-upper-1",
              "externalID": "ocd-person/f13bda4e-bfb4-49fc-a986-ba8d2b3e7e43",
              "geometry": null
            },
            "contactInfo": {
              "email": "palumbo@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "400 W. Main St. Suite 201, Riverhead, NY 11901",
              "phone1": null,
              "phone2": "631-461-9100",
              "fax1": null,
              "fax2": null,
              "twitter": "AnthonyHPalumbo",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/anthony-h-palumbo/contact",
                "https://www.nysenate.gov/senators/anthony-h-palumbo"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-1-upper-ocd-person-f13bda4e-bfb4-49fc-a986-ba8d2b3e7e43": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/04f9beb9-b562-4f2f-9f23-4856fe0fd0dc",
            "firstName": "Mario R.",
            "lastName": "Mattera",
            "fullName": "Mario Mattera",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/mattera_official_headshot.2.jpg?itok=w5SiQVq0",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-NY-2-upper-ocd-person-04f9beb9-b562-4f2f-9f23-4856fe0fd0dc",
              "builtID": "ny-upper-2",
              "externalID": "ocd-person/04f9beb9-b562-4f2f-9f23-4856fe0fd0dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "mattera@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "180 E. Main St. Suite 210, Smithtown, NY 11787",
              "phone1": null,
              "phone2": "631-361-2154",
              "fax1": null,
              "fax2": "631-361-5367",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/mario-r-mattera/contact",
                "https://www.nysenate.gov/senators/mario-r-mattera"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-2-upper-ocd-person-04f9beb9-b562-4f2f-9f23-4856fe0fd0dc": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/8ff653e0-5aa6-4692-ac54-25ed36e32f97",
            "firstName": "Leonard Dean",
            "lastName": "Murray",
            "fullName": "Dean Murray",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/murray-hs.png?itok=MNZdJiUC",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-NY-3-upper-ocd-person-8ff653e0-5aa6-4692-ac54-25ed36e32f97",
              "builtID": "ny-upper-3",
              "externalID": "ocd-person/8ff653e0-5aa6-4692-ac54-25ed36e32f97",
              "geometry": null
            },
            "contactInfo": {
              "email": "murray@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "90-B W. Main St., Patchogue, NY 11772",
              "phone1": null,
              "phone2": "631-360-3356",
              "fax1": null,
              "fax2": "631-289-1035",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.nysenate.gov/senators/dean-murray"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-3-upper-ocd-person-8ff653e0-5aa6-4692-ac54-25ed36e32f97": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/622242c6-d42c-4557-b9ab-29ca87e6d141",
            "firstName": "Monica R.",
            "lastName": "Martinez",
            "fullName": "Monica Martinez",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/01-10-23_martinezhs.jpg?itok=aHOS1Fqm",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-NY-4-upper-ocd-person-622242c6-d42c-4557-b9ab-29ca87e6d141",
              "builtID": "ny-upper-4",
              "externalID": "ocd-person/622242c6-d42c-4557-b9ab-29ca87e6d141",
              "geometry": null
            },
            "contactInfo": {
              "email": "martinez@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 3B-41-42, Hauppauge State Office Building 250 Veterans Highway, Hauppauge, NY 11788",
              "phone1": null,
              "phone2": "631-341-7111",
              "fax1": null,
              "fax2": "631-382-9861",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.nysenate.gov/senators/monica-r-martinez"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-4-upper-ocd-person-622242c6-d42c-4557-b9ab-29ca87e6d141": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/f7d38428-2656-4fb3-ac28-2455d5533a0b",
            "firstName": "Steven D.",
            "lastName": "Rhoads",
            "fullName": "Steve Rhoads",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/rhoads_headshot_2.jpg?itok=yLpr6nCr",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-NY-5-upper-ocd-person-f7d38428-2656-4fb3-ac28-2455d5533a0b",
              "builtID": "ny-upper-5",
              "externalID": "ocd-person/f7d38428-2656-4fb3-ac28-2455d5533a0b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rhoads@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4236 Merrick Road 1st Floor, Massapequa, NY 11758",
              "phone1": null,
              "phone2": "516-882-0630",
              "fax1": null,
              "fax2": "516-882-0636",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators/steven-d-rhoads/contact",
                "https://www.nysenate.gov/senators/steven-d-rhoads"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-5-upper-ocd-person-f7d38428-2656-4fb3-ac28-2455d5533a0b": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/84c8d56f-00b5-4b9c-a95e-9cb98053e12c",
            "firstName": "Kevin M.",
            "lastName": "Thomas",
            "fullName": "Kevin Thomas",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/senator-thomas-sd6-member-head.jpg?itok=Zea6gVWR",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-NY-6-upper-ocd-person-84c8d56f-00b5-4b9c-a95e-9cb98053e12c",
              "builtID": "ny-upper-6",
              "externalID": "ocd-person/84c8d56f-00b5-4b9c-a95e-9cb98053e12c",
              "geometry": null
            },
            "contactInfo": {
              "email": "thomas@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "990 Stewart Ave. Suite LL45A, Garden City, NY 11530",
              "phone1": null,
              "phone2": "516-739-1700",
              "fax1": null,
              "fax2": "516-747-7430",
              "twitter": "KevinThomasNY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/kevin-thomas/contact",
                "https://www.nysenate.gov/senators/kevin-thomas"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-6-upper-ocd-person-84c8d56f-00b5-4b9c-a95e-9cb98053e12c": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/7f031836-0a9e-46d2-bcab-7cc6a14848df",
            "firstName": "Joaquim M.",
            "lastName": "Martins",
            "fullName": "Jack Martins",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/2023/08/22/jack_martins_hs_2023.jpg?itok=jqP_N4lM",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-NY-7-upper-ocd-person-7f031836-0a9e-46d2-bcab-7cc6a14848df",
              "builtID": "ny-upper-7",
              "externalID": "ocd-person/7f031836-0a9e-46d2-bcab-7cc6a14848df",
              "geometry": null
            },
            "contactInfo": {
              "email": "martins@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "173 Mineola Blvd. Suite 201, Mineola, NY 11501",
              "phone1": null,
              "phone2": "516-922-1811",
              "fax1": null,
              "fax2": "516-747-0203",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.nysenate.gov/senators/jack-m-martins"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-7-upper-ocd-person-7f031836-0a9e-46d2-bcab-7cc6a14848df": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/9dd382bd-0f4f-4a99-ad58-679e26114f70",
            "firstName": "Alexis",
            "lastName": "Weik",
            "fullName": "Alexis Weik",
            "gender": "Female",
            "party": "republican/conservative",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/alexis_weik_hs.jpg?itok=F8H4DVNB",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-NY-8-upper-ocd-person-9dd382bd-0f4f-4a99-ad58-679e26114f70",
              "builtID": "ny-upper-8",
              "externalID": "ocd-person/9dd382bd-0f4f-4a99-ad58-679e26114f70",
              "geometry": null
            },
            "contactInfo": {
              "email": "weik@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1 Corporate Drive Suite GL-005, Bohemia, NY 11716",
              "phone1": null,
              "phone2": "631-665-2311",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/alexis-weik/contact",
                "https://www.nysenate.gov/senators/alexis-weik"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-8-upper-ocd-person-9dd382bd-0f4f-4a99-ad58-679e26114f70": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/d04bd8aa-6178-419b-8738-a5f92141753a",
            "firstName": "Patricia",
            "lastName": "Canzoneri-Fitzpatrick",
            "fullName": "Patricia Canzoneri-Fitzpatrick",
            "gender": "Female",
            "party": "republican/conservative",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/canzoneri-fitzpatrick.jpg?itok=HCHxUy52",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-NY-9-upper-ocd-person-d04bd8aa-6178-419b-8738-a5f92141753a",
              "builtID": "ny-upper-9",
              "externalID": "ocd-person/d04bd8aa-6178-419b-8738-a5f92141753a",
              "geometry": null
            },
            "contactInfo": {
              "email": "canzoneri@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "265 E. Merrick Road Suite 101, Valley Stream, NY 11580",
              "phone1": null,
              "phone2": "516-766-8383",
              "fax1": null,
              "fax2": "516-766-8011",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators/patricia-canzoneri-fitzpatrick"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-9-upper-ocd-person-d04bd8aa-6178-419b-8738-a5f92141753a": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/e00ee209-a6a5-4ca1-8ac6-f403e1cbcca2",
            "firstName": "James",
            "lastName": "Sanders",
            "fullName": "James Sanders",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/2021sandersbio.jpg?itok=tKIC3Ymf",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-NY-10-upper-ocd-person-e00ee209-a6a5-4ca1-8ac6-f403e1cbcca2",
              "builtID": "ny-upper-10",
              "externalID": "ocd-person/e00ee209-a6a5-4ca1-8ac6-f403e1cbcca2",
              "geometry": null
            },
            "contactInfo": {
              "email": "sanders@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1931 Mott Ave. Suite 305, Far Rockaway, NY 11691",
              "phone1": null,
              "phone2": "718-327-7017",
              "fax1": null,
              "fax2": "718-327-7970",
              "twitter": "JSandersNYC",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/james-sanders-jr/contact",
                "https://www.nysenate.gov/senators/james-sanders-jr"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-10-upper-ocd-person-e00ee209-a6a5-4ca1-8ac6-f403e1cbcca2": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/3d51aca8-81d1-4f3e-8cef-47c999626a9a",
            "firstName": "Toby Ann",
            "lastName": "Stavisky",
            "fullName": "Toby Stavisky",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/%2802-09-09%29%20Stavisky-HS-034-ret.jpg?itok=7GY17zRh",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-NY-11-upper-ocd-person-3d51aca8-81d1-4f3e-8cef-47c999626a9a",
              "builtID": "ny-upper-11",
              "externalID": "ocd-person/3d51aca8-81d1-4f3e-8cef-47c999626a9a",
              "geometry": null
            },
            "contactInfo": {
              "email": "stavisky@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "134-01 20th Avenue 2nd Floor, College Point, NY 11356",
              "phone1": null,
              "phone2": "718-445-0004",
              "fax1": null,
              "fax2": null,
              "twitter": "tobystavisky",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/toby-ann-stavisky/contact",
                "https://www.nysenate.gov/senators/toby-ann-stavisky"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-11-upper-ocd-person-3d51aca8-81d1-4f3e-8cef-47c999626a9a": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/b0c3d86b-33ca-4001-a9af-b963fabc06c3",
            "firstName": "Michael N.",
            "lastName": "Gianaris",
            "fullName": "Michael Gianaris",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/hs_3.jpg?itok=0w8WWqFj",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-NY-12-upper-ocd-person-b0c3d86b-33ca-4001-a9af-b963fabc06c3",
              "builtID": "ny-upper-12",
              "externalID": "ocd-person/b0c3d86b-33ca-4001-a9af-b963fabc06c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "gianaris@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "22-07 45th St. Suite 1008, Astoria, NY 11105",
              "phone1": null,
              "phone2": "718-728-0960",
              "fax1": null,
              "fax2": "718-728-0963",
              "twitter": "SenGianaris",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/michael-gianaris/contact",
                "https://www.nysenate.gov/senators/michael-gianaris"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-12-upper-ocd-person-b0c3d86b-33ca-4001-a9af-b963fabc06c3": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/a0b3e01c-d323-4624-a0da-6a1e2b0dfb31",
            "firstName": "Jessica",
            "lastName": "Ramos",
            "fullName": "Jessica Ramos",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/sd13senator_jessica_ramos22_0.jpg?itok=WB6i4JL9",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-NY-13-upper-ocd-person-a0b3e01c-d323-4624-a0da-6a1e2b0dfb31",
              "builtID": "ny-upper-13",
              "externalID": "ocd-person/a0b3e01c-d323-4624-a0da-6a1e2b0dfb31",
              "geometry": null
            },
            "contactInfo": {
              "email": "ramos@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "74-09 37th Ave. Suite 302, Jackson Heights, NY 11372",
              "phone1": null,
              "phone2": "718-205-3881",
              "fax1": null,
              "fax2": "718-205-4145",
              "twitter": "jessicaramos",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/jessica-ramos/contact",
                "https://www.nysenate.gov/senators/jessica-ramos"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-13-upper-ocd-person-a0b3e01c-d323-4624-a0da-6a1e2b0dfb31": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/dbc5723f-2fad-4c3e-b051-5ea05706340f",
            "firstName": "Leroy George",
            "lastName": "Comrie",
            "fullName": "Leroy Comrie",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/%2801-12-15%29%20Comrie-HS-079.jpg?itok=SFD_SKfv",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-NY-14-upper-ocd-person-dbc5723f-2fad-4c3e-b051-5ea05706340f",
              "builtID": "ny-upper-14",
              "externalID": "ocd-person/dbc5723f-2fad-4c3e-b051-5ea05706340f",
              "geometry": null
            },
            "contactInfo": {
              "email": "comrie@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "113-43 Farmers Blvd., St. Albans, NY 11412",
              "phone1": null,
              "phone2": "718-765-6359",
              "fax1": null,
              "fax2": "718-454-0186",
              "twitter": "LeroyComrie",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/leroy-comrie/contact",
                "https://www.nysenate.gov/senators/leroy-comrie"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-14-upper-ocd-person-dbc5723f-2fad-4c3e-b051-5ea05706340f": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/f3a41618-3c28-4bab-9a56-ef49c9cafcec",
            "firstName": "Joseph Patrick",
            "lastName": "Addabbo",
            "fullName": "Joe Addabbo",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/headshot_addabbo-hs-profile.jpg?itok=gdgS9QTR",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-NY-15-upper-ocd-person-f3a41618-3c28-4bab-9a56-ef49c9cafcec",
              "builtID": "ny-upper-15",
              "externalID": "ocd-person/f3a41618-3c28-4bab-9a56-ef49c9cafcec",
              "geometry": null
            },
            "contactInfo": {
              "email": "addabbo@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "84-16 Jamaica Ave., Woodhaven, NY 11421",
              "phone1": null,
              "phone2": "718-738-1111",
              "fax1": null,
              "fax2": "718-296-0495",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/joseph-p-addabbo-jr/contact",
                "https://www.nysenate.gov/senators/joseph-p-addabbo-jr"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-15-upper-ocd-person-f3a41618-3c28-4bab-9a56-ef49c9cafcec": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/eee14916-d196-4857-a64a-b6e952db7a53",
            "firstName": "John Chun",
            "lastName": "Liu",
            "fullName": "John Liu",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/liu-760-article-photonysenate.jpg?itok=Nyrv_a2O",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-NY-16-upper-ocd-person-eee14916-d196-4857-a64a-b6e952db7a53",
              "builtID": "ny-upper-16",
              "externalID": "ocd-person/eee14916-d196-4857-a64a-b6e952db7a53",
              "geometry": null
            },
            "contactInfo": {
              "email": "liu@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "38-50 Bell Blvd. Suite C, Bayside, NY 11361",
              "phone1": null,
              "phone2": "718-765-6675",
              "fax1": null,
              "fax2": null,
              "twitter": "LiuNewYork",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/john-liu/contact",
                "https://www.nysenate.gov/senators/john-c-liu/contact",
                "https://www.nysenate.gov/senators/john-c-liu"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-16-upper-ocd-person-eee14916-d196-4857-a64a-b6e952db7a53": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/b7c0f263-868d-45cf-a5d8-9787e924fb7f",
            "firstName": "Iwen Irene",
            "lastName": "Chu",
            "fullName": "Iwen Chu",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/01-04-23_chu-hs_0.jpg?itok=ST0dfo2H",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-NY-17-upper-ocd-person-b7c0f263-868d-45cf-a5d8-9787e924fb7f",
              "builtID": "ny-upper-17",
              "externalID": "ocd-person/b7c0f263-868d-45cf-a5d8-9787e924fb7f",
              "geometry": null
            },
            "contactInfo": {
              "email": "iwenchu@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6605 Fort Hamilton Parkway, Brooklyn, NY 11219",
              "phone1": null,
              "phone2": "718-333-0311",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.nysenate.gov/senators/iwen-chu"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-17-upper-ocd-person-b7c0f263-868d-45cf-a5d8-9787e924fb7f": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/6c410f28-9316-45db-935d-52764c90b4ae",
            "firstName": "Julia",
            "lastName": "Salazar",
            "fullName": "Julia Salazar",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/screenshotjs.png?itok=WxwPAzfP",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-NY-18-upper-ocd-person-6c410f28-9316-45db-935d-52764c90b4ae",
              "builtID": "ny-upper-18",
              "externalID": "ocd-person/6c410f28-9316-45db-935d-52764c90b4ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "salazar@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "212 Evergreen Ave., Brooklyn, NY 11221",
              "phone1": null,
              "phone2": "718-573-1726",
              "fax1": null,
              "fax2": null,
              "twitter": "JuliaCarmel__",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/julia-salazar/contact",
                "https://www.nysenate.gov/senators/julia-salazar"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-18-upper-ocd-person-6c410f28-9316-45db-935d-52764c90b4ae": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/aa80fb3b-9add-463b-bfba-fe51e3409212",
            "firstName": "Roxanne Jacqueline",
            "lastName": "Persaud",
            "fullName": "Roxanne Persaud",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/03-21-17_persaud_hs-018.jpg?itok=ALiZNeZt",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-NY-19-upper-ocd-person-aa80fb3b-9add-463b-bfba-fe51e3409212",
              "builtID": "ny-upper-19",
              "externalID": "ocd-person/aa80fb3b-9add-463b-bfba-fe51e3409212",
              "geometry": null
            },
            "contactInfo": {
              "email": "persaud@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1222 E. 96th St., Brooklyn, NY 11236",
              "phone1": null,
              "phone2": "718-649-7653",
              "fax1": null,
              "fax2": null,
              "twitter": "SenatorPersaud",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/roxanne-j-persaud/contact",
                "https://www.nysenate.gov/senators/roxanne-j-persaud"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-19-upper-ocd-person-aa80fb3b-9add-463b-bfba-fe51e3409212": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/52c0e400-2385-4c12-8bf4-6b9205c18eb2",
            "firstName": "Zellnor Y.",
            "lastName": "Myrie",
            "fullName": "Zellnor Myrie",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/zmyriemainheadimage_0.jpg?itok=O2-EZTeD",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-NY-20-upper-ocd-person-52c0e400-2385-4c12-8bf4-6b9205c18eb2",
              "builtID": "ny-upper-20",
              "externalID": "ocd-person/52c0e400-2385-4c12-8bf4-6b9205c18eb2",
              "geometry": null
            },
            "contactInfo": {
              "email": "myrie@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1077 Nostrand Ave. Ground Floor, Brooklyn, NY 11225",
              "phone1": null,
              "phone2": "718-284-4700",
              "fax1": null,
              "fax2": "718-282-3585",
              "twitter": "zellnor4ny",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/zellnor-myrie/contact",
                "https://www.nysenate.gov/senators/zellnor-myrie"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-20-upper-ocd-person-52c0e400-2385-4c12-8bf4-6b9205c18eb2": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/dbf73f29-d4ce-4192-b8fe-42b3ac538dda",
            "firstName": "Kevin S.",
            "lastName": "Parker",
            "fullName": "Kevin Parker",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/2019kphs.jpg?itok=savOnro2",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-NY-21-upper-ocd-person-dbf73f29-d4ce-4192-b8fe-42b3ac538dda",
              "builtID": "ny-upper-21",
              "externalID": "ocd-person/dbf73f29-d4ce-4192-b8fe-42b3ac538dda",
              "geometry": null
            },
            "contactInfo": {
              "email": "parker@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3021 Tilden Ave. 1st Floor & Basement, Brooklyn, NY 11226",
              "phone1": null,
              "phone2": "718-629-6401",
              "fax1": null,
              "fax2": "718-629-6420",
              "twitter": "SenatorParker",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/kevin-s-parker/contact",
                "https://www.nysenate.gov/senators/kevin-s-parker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-21-upper-ocd-person-dbf73f29-d4ce-4192-b8fe-42b3ac538dda": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/76fd8f97-fe06-4b03-a7de-688e85648b8e",
            "firstName": "Simcha",
            "lastName": "Felder",
            "fullName": "Simcha Felder",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/sf_headshot_2017.jpg?itok=GU9KYbc2",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-NY-22-upper-ocd-person-76fd8f97-fe06-4b03-a7de-688e85648b8e",
              "builtID": "ny-upper-22",
              "externalID": "ocd-person/76fd8f97-fe06-4b03-a7de-688e85648b8e",
              "geometry": null
            },
            "contactInfo": {
              "email": "felder@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1412 Avenue J Suite 2E, Brooklyn, NY 11230",
              "phone1": null,
              "phone2": "718-253-2015",
              "fax1": null,
              "fax2": "718-253-2030",
              "twitter": "NYSenatorFelder",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/simcha-felder/contact",
                "https://www.nysenate.gov/senators/simcha-felder"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-22-upper-ocd-person-76fd8f97-fe06-4b03-a7de-688e85648b8e": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/84b5ed8b-c048-4d62-ab8f-17d217605301",
            "firstName": "Jessica",
            "lastName": "Scarcella-Spanton",
            "fullName": "Jessica Scarcella-Spanton",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/01-05-23_s-spanton-hs_official.jpg?itok=IomeKdft",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-NY-23-upper-ocd-person-84b5ed8b-c048-4d62-ab8f-17d217605301",
              "builtID": "ny-upper-23",
              "externalID": "ocd-person/84b5ed8b-c048-4d62-ab8f-17d217605301",
              "geometry": null
            },
            "contactInfo": {
              "email": "spanton@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "36 Richmond Terrace Suite 112, Staten Island, NY 10301",
              "phone1": null,
              "phone2": "718-727-9406",
              "fax1": null,
              "fax2": "718-727-9426",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators/jessica-scarcella-spanton"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-23-upper-ocd-person-84b5ed8b-c048-4d62-ab8f-17d217605301": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/8dd98240-93c9-486a-b6c6-3a794b232090",
            "firstName": "Andrew Joseph",
            "lastName": "Lanza",
            "fullName": "Andrew Lanza",
            "gender": "Male",
            "party": "republican/conservative/independence/reform",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/%2801-23-07%29%20Lanza-HS-029-8x10.jpg?itok=uv7iqzwV",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-NY-24-upper-ocd-person-8dd98240-93c9-486a-b6c6-3a794b232090",
              "builtID": "ny-upper-24",
              "externalID": "ocd-person/8dd98240-93c9-486a-b6c6-3a794b232090",
              "geometry": null
            },
            "contactInfo": {
              "email": "lanza@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3845 Richmond Ave. Suite 2A, Staten Island, NY 10312",
              "phone1": null,
              "phone2": "718-984-4073",
              "fax1": null,
              "fax2": "718-984-4455",
              "twitter": "senatorlanza",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/andrew-j-lanza/contact",
                "https://www.nysenate.gov/senators/andrew-j-lanza"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-24-upper-ocd-person-8dd98240-93c9-486a-b6c6-3a794b232090": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/e053b536-631c-47a7-a3af-adb30dab37de",
            "firstName": "Jabari",
            "lastName": "Brisport",
            "fullName": "Jabari Brisport",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/sd25jabar_brisport.jpg?itok=NzoQxMPy",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-NY-25-upper-ocd-person-e053b536-631c-47a7-a3af-adb30dab37de",
              "builtID": "ny-upper-25",
              "externalID": "ocd-person/e053b536-631c-47a7-a3af-adb30dab37de",
              "geometry": null
            },
            "contactInfo": {
              "email": "brisport@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "906 Broadway 2nd Floor, Brooklyn, NY 11206",
              "phone1": null,
              "phone2": "718-643-6140",
              "fax1": null,
              "fax2": null,
              "twitter": "JabariBrisport",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/jabari-brisport/contact",
                "https://www.nysenate.gov/senators/jabari-brisport"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-25-upper-ocd-person-e053b536-631c-47a7-a3af-adb30dab37de": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/ae1f415b-958c-4a4c-805b-626e6eca5fe3",
            "firstName": "Andrew Simeon",
            "lastName": "Gounardes",
            "fullName": "Andrew Gounardes",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/ag_headshot1.jpeg?itok=A9BZVK33",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-NY-26-upper-ocd-person-ae1f415b-958c-4a4c-805b-626e6eca5fe3",
              "builtID": "ny-upper-26",
              "externalID": "ocd-person/ae1f415b-958c-4a4c-805b-626e6eca5fe3",
              "geometry": null
            },
            "contactInfo": {
              "email": "gounardes@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "497 Carroll St. Suite 31, Brooklyn, NY 11215",
              "phone1": null,
              "phone2": "718-238-6044",
              "fax1": null,
              "fax2": null,
              "twitter": "agounardes",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/andrew-gounardes/contact",
                "https://www.nysenate.gov/senators/andrew-gounardes"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-26-upper-ocd-person-ae1f415b-958c-4a4c-805b-626e6eca5fe3": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/934d4a02-a27c-4682-a31f-5a8372ceaaf9",
            "firstName": "Brian P.",
            "lastName": "Kavanagh",
            "fullName": "Brian Kavanagh",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/briankavanaghsenatorspage.jpg?itok=VBk-Tnd3",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-NY-27-upper-ocd-person-934d4a02-a27c-4682-a31f-5a8372ceaaf9",
              "builtID": "ny-upper-27",
              "externalID": "ocd-person/934d4a02-a27c-4682-a31f-5a8372ceaaf9",
              "geometry": null
            },
            "contactInfo": {
              "email": "kavanagh@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 2011, 250 Broadway, New York, NY 10007",
              "phone1": null,
              "phone2": "212-298-5565",
              "fax1": null,
              "fax2": "212-431-7836",
              "twitter": "BrianKavanaghNY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/brian-kavanagh/contact",
                "https://www.nysenate.gov/senators/brian-kavanagh"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-27-upper-ocd-person-934d4a02-a27c-4682-a31f-5a8372ceaaf9": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/638d1d66-ccbe-4107-b7fb-f3d22cb2ec9c",
            "firstName": "Elizabeth",
            "lastName": "Krueger",
            "fullName": "Liz Krueger",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/%2803-05-09%29%20Liz%20Krueger8x10HS-002.jpg?itok=Uhcv13n6",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-NY-28-upper-ocd-person-638d1d66-ccbe-4107-b7fb-f3d22cb2ec9c",
              "builtID": "ny-upper-28",
              "externalID": "ocd-person/638d1d66-ccbe-4107-b7fb-f3d22cb2ec9c",
              "geometry": null
            },
            "contactInfo": {
              "email": "lkrueger@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "211 E. 43rd St. Suite 2000, New York, NY 10017",
              "phone1": null,
              "phone2": "212-490-9535",
              "fax1": null,
              "fax2": "212-499-2558",
              "twitter": "LizKrueger",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/liz-krueger/contact",
                "https://www.nysenate.gov/senators/liz-krueger"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-28-upper-ocd-person-638d1d66-ccbe-4107-b7fb-f3d22cb2ec9c": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/31d058e9-909a-46b5-8e66-21a3e86b5a56",
            "firstName": "Jose Marco",
            "lastName": "Serrano",
            "fullName": "Jose Serrano",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/%2802-11-13%29%20Serrano-HS-028RET.jpg?itok=U6wrLa0A",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-NY-29-upper-ocd-person-31d058e9-909a-46b5-8e66-21a3e86b5a56",
              "builtID": "ny-upper-29",
              "externalID": "ocd-person/31d058e9-909a-46b5-8e66-21a3e86b5a56",
              "geometry": null
            },
            "contactInfo": {
              "email": "serrano@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "335 E. 100th St., New York, NY 10029",
              "phone1": null,
              "phone2": "212-828-5829",
              "fax1": null,
              "fax2": "212-828-2420",
              "twitter": "SenatorSerrano",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/jos%C3%A9-m-serrano/contact",
                "https://www.nysenate.gov/senators/jose-m-serrano"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-29-upper-ocd-person-31d058e9-909a-46b5-8e66-21a3e86b5a56": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/c5c492e5-7c34-4b6f-862e-185248e89f18",
            "firstName": "Cordell",
            "lastName": "Cleare",
            "fullName": "Cordell Cleare",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/sd30-cleareofficialheadshot.jpg?itok=xjV5PmAM",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-NY-30-upper-ocd-person-c5c492e5-7c34-4b6f-862e-185248e89f18",
              "builtID": "ny-upper-30",
              "externalID": "ocd-person/c5c492e5-7c34-4b6f-862e-185248e89f18",
              "geometry": null
            },
            "contactInfo": {
              "email": "cleare@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Adam Clayton Powell, Jr. State Office Building 163 W. 125th St., Suite 912, New York, NY 10027",
              "phone1": null,
              "phone2": "212-222-7315",
              "fax1": null,
              "fax2": "212-678-0001",
              "twitter": "CordellCleare",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/cordell-cleare/contact",
                "https://www.nysenate.gov/senators/cordell-cleare"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-30-upper-ocd-person-c5c492e5-7c34-4b6f-862e-185248e89f18": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/3da548b5-8a96-4dbf-9bf8-da3755d6caa3",
            "firstName": "Robert",
            "lastName": "Jackson",
            "fullName": "Robert Jackson",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/01-03-19jackson-hs-048_2.jpg?itok=OXbnwl-9",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-NY-31-upper-ocd-person-3da548b5-8a96-4dbf-9bf8-da3755d6caa3",
              "builtID": "ny-upper-31",
              "externalID": "ocd-person/3da548b5-8a96-4dbf-9bf8-da3755d6caa3",
              "geometry": null
            },
            "contactInfo": {
              "email": "jackson@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5030 Broadway Suite 701, New York, NY 10034",
              "phone1": null,
              "phone2": "212-544-0173",
              "fax1": null,
              "fax2": "212-544-0256",
              "twitter": "SenatorRJackson",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/robert-jackson/contact",
                "https://www.nysenate.gov/senators/robert-jackson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-31-upper-ocd-person-3da548b5-8a96-4dbf-9bf8-da3755d6caa3": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/1e5af6bc-1643-4cfd-b4c1-8b2e86b02c2d",
            "firstName": "Luis R.",
            "lastName": "Sepulveda",
            "fullName": "Luis Sepúlveda",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/hssepulveda2.jpg?itok=WvXJvenH",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-NY-32-upper-ocd-person-1e5af6bc-1643-4cfd-b4c1-8b2e86b02c2d",
              "builtID": "ny-upper-32",
              "externalID": "ocd-person/1e5af6bc-1643-4cfd-b4c1-8b2e86b02c2d",
              "geometry": null
            },
            "contactInfo": {
              "email": "sepulveda@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "975 Kelly St. Suite 203, Bronx, NY 10459",
              "phone1": null,
              "phone2": "718-991-3161",
              "fax1": null,
              "fax2": null,
              "twitter": "LuisSepulvedaNY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/luis-r-sepulveda/contact",
                "https://www.nysenate.gov/senators/luis-r-sepulveda"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-32-upper-ocd-person-1e5af6bc-1643-4cfd-b4c1-8b2e86b02c2d": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/068528ac-bc5e-449f-a4df-4541f8c09415",
            "firstName": "Jose Gustavo",
            "lastName": "Rivera",
            "fullName": "Gustavo Rivera",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/%2801-29-14%29%20Rivera-HS-%20009.jpg?itok=Ny0U7p_x",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-NY-33-upper-ocd-person-068528ac-bc5e-449f-a4df-4541f8c09415",
              "builtID": "ny-upper-33",
              "externalID": "ocd-person/068528ac-bc5e-449f-a4df-4541f8c09415",
              "geometry": null
            },
            "contactInfo": {
              "email": "grivera@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2432 Grand Concourse, Suite 506, Bronx, NY 10458",
              "phone1": null,
              "phone2": "718-933-2034",
              "fax1": null,
              "fax2": "718-933-2825",
              "twitter": "NYSenatorRivera",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/gustavo-rivera/contact",
                "https://www.nysenate.gov/senators/gustavo-rivera"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-33-upper-ocd-person-068528ac-bc5e-449f-a4df-4541f8c09415": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/2a38ba4b-176e-479f-8083-2ff50aef693e",
            "firstName": "Nathalia",
            "lastName": "Fernández",
            "fullName": "Nathalia Fernández",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/12-27-22_fernandez-hs.jpg?itok=0m96AhyY",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-NY-34-upper-ocd-person-2a38ba4b-176e-479f-8083-2ff50aef693e",
              "builtID": "ny-upper-34",
              "externalID": "ocd-person/2a38ba4b-176e-479f-8083-2ff50aef693e",
              "geometry": null
            },
            "contactInfo": {
              "email": "fernandez@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3853 E. Tremont Ave., Bronx, NY 10465",
              "phone1": null,
              "phone2": "718-822-2049",
              "fax1": null,
              "fax2": "718-581-0310",
              "twitter": "Fernandez4NY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Nathalia-Fernandez",
                "https://www.nysenate.gov/senators/nathalia-fernandez"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-34-upper-ocd-person-2a38ba4b-176e-479f-8083-2ff50aef693e": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/5f3e7bcf-9e43-423b-946b-982cc6ecc154",
            "firstName": "Andrea Alice",
            "lastName": "Stewart-Cousins",
            "fullName": "Andrea Stewart-Cousins",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/asc-hs-new_0.jpg?itok=Amg0GHQ5",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-NY-35-upper-ocd-person-5f3e7bcf-9e43-423b-946b-982cc6ecc154",
              "builtID": "ny-upper-35",
              "externalID": "ocd-person/5f3e7bcf-9e43-423b-946b-982cc6ecc154",
              "geometry": null
            },
            "contactInfo": {
              "email": "scousins@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1930, 250 Broadway, New York, NY 10007",
              "phone1": null,
              "phone2": "212-298-5585",
              "fax1": null,
              "fax2": "212-298-5623",
              "twitter": "AndreaSCousins",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/andrea-stewart-cousins/contact",
                "https://www.nysenate.gov/senators/andrea-stewart-cousins"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-35-upper-ocd-person-5f3e7bcf-9e43-423b-946b-982cc6ecc154": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/6872ad9e-fcba-426f-a9ef-b4495dba17f5",
            "firstName": "Jamaal T.",
            "lastName": "Bailey",
            "fullName": "Jamaal Bailey",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/bailey-hs-020_2.jpg?itok=LKcRq5kH",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-NY-36-upper-ocd-person-6872ad9e-fcba-426f-a9ef-b4495dba17f5",
              "builtID": "ny-upper-36",
              "externalID": "ocd-person/6872ad9e-fcba-426f-a9ef-b4495dba17f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorjbailey@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "959 E. 233rd St., Bronx, NY 10466",
              "phone1": null,
              "phone2": "718-547-8854",
              "fax1": null,
              "fax2": null,
              "twitter": "jamaaltbailey",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/jamaal-t-bailey/contact",
                "https://www.nysenate.gov/senators/jamaal-t-bailey"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-36-upper-ocd-person-6872ad9e-fcba-426f-a9ef-b4495dba17f5": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/a9b13089-77fc-4e9d-a9e4-35933ea903a0",
            "firstName": "Shelley B.",
            "lastName": "Mayer",
            "fullName": "Shelley Mayer",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/senators-committee-160-photo-thumbsd37sm.jpg?itok=GVLoEXUn",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-NY-37-upper-ocd-person-a9b13089-77fc-4e9d-a9e4-35933ea903a0",
              "builtID": "ny-upper-37",
              "externalID": "ocd-person/a9b13089-77fc-4e9d-a9e4-35933ea903a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "smayer@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "235 Mamaroneck Ave. Suite 400, White Plains, NY 10605",
              "phone1": null,
              "phone2": "914-934-5250",
              "fax1": null,
              "fax2": "914-948-2469",
              "twitter": "ShelleyBMayer",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/shelley-mayer/contact",
                "https://www.nysenate.gov/senators/shelley-b-mayer/contact",
                "https://www.nysenate.gov/senators/shelley-b-mayer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-37-upper-ocd-person-a9b13089-77fc-4e9d-a9e4-35933ea903a0": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/8285be34-48a9-4bbe-b231-f8e5f40df301",
            "firstName": "William",
            "lastName": "Weber",
            "fullName": "Bill Weber",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/01-10-23_weber-hs-1.jpg?itok=aW-g6Y4y",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-NY-38-upper-ocd-person-8285be34-48a9-4bbe-b231-f8e5f40df301",
              "builtID": "ny-upper-38",
              "externalID": "ocd-person/8285be34-48a9-4bbe-b231-f8e5f40df301",
              "geometry": null
            },
            "contactInfo": {
              "email": "weber@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "163 Airport Executive Park, Nanuet, NY 10954",
              "phone1": null,
              "phone2": "845-623-3627",
              "fax1": null,
              "fax2": "845-425-4617",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.nysenate.gov/senators/bill-weber"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-38-upper-ocd-person-8285be34-48a9-4bbe-b231-f8e5f40df301": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/68cdb041-2986-42dc-a2fd-2b9d6c668665",
            "firstName": "Robert",
            "lastName": "Rolison",
            "fullName": "Rob Rolison",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/rgr_official_headshot_001_0.jpg?itok=C62GWztm",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-NY-39-upper-ocd-person-68cdb041-2986-42dc-a2fd-2b9d6c668665",
              "builtID": "ny-upper-39",
              "externalID": "ocd-person/68cdb041-2986-42dc-a2fd-2b9d6c668665",
              "geometry": null
            },
            "contactInfo": {
              "email": "rolison@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3 Neptune Road Suite N22, Poughkeepsie, NY 12601",
              "phone1": null,
              "phone2": "845-229-0106",
              "fax1": null,
              "fax2": "845-463-0740",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.nysenate.gov/senators/rob-rolison"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-39-upper-ocd-person-68cdb041-2986-42dc-a2fd-2b9d6c668665": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/ee71493b-ee0d-43ac-bac5-c7dde6a0ef1c",
            "firstName": "Peter B.",
            "lastName": "Harckham",
            "fullName": "Pete Harckham",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/good_harckham_headshot_0.jpg?itok=gnA8L0jv",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-NY-40-upper-ocd-person-ee71493b-ee0d-43ac-bac5-c7dde6a0ef1c",
              "builtID": "ny-upper-40",
              "externalID": "ocd-person/ee71493b-ee0d-43ac-bac5-c7dde6a0ef1c",
              "geometry": null
            },
            "contactInfo": {
              "email": "harckham@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "40 Gleneida Ave., Carmel, NY 10512",
              "phone1": null,
              "phone2": "845-225-3025",
              "fax1": null,
              "fax2": null,
              "twitter": "SenatorHarckham",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/peter-harckham/contact",
                "https://www.nysenate.gov/senators/pete-harckham/contact",
                "https://www.nysenate.gov/senators/pete-harckham"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-40-upper-ocd-person-ee71493b-ee0d-43ac-bac5-c7dde6a0ef1c": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/0f3cecb9-87fb-48c7-96af-fd3ff8e0cd8b",
            "firstName": "Michelle",
            "lastName": "Hinchey",
            "fullName": "Michelle Hinchey",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/hinchey-hs-0005.jpg?itok=vF5qXFPh",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-NY-41-upper-ocd-person-0f3cecb9-87fb-48c7-96af-fd3ff8e0cd8b",
              "builtID": "ny-upper-41",
              "externalID": "ocd-person/0f3cecb9-87fb-48c7-96af-fd3ff8e0cd8b",
              "geometry": null
            },
            "contactInfo": {
              "email": "hinchey@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Altamont, NY",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "MichelleHinchey",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/michelle-hinchey/contact",
                "https://www.nysenate.gov/senators/michelle-hinchey"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-41-upper-ocd-person-0f3cecb9-87fb-48c7-96af-fd3ff8e0cd8b": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/c1f048f2-6e1d-4e1b-b161-d97e2f16c1f8",
            "firstName": "James George",
            "lastName": "Skoufis",
            "fullName": "James Skoufis",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/skoufis_nysenate.gov_image.jpeg?itok=eNfSopav",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-NY-42-upper-ocd-person-c1f048f2-6e1d-4e1b-b161-d97e2f16c1f8",
              "builtID": "ny-upper-42",
              "externalID": "ocd-person/c1f048f2-6e1d-4e1b-b161-d97e2f16c1f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "skoufis@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "45 Quaker Ave. Suites 202 & 207, Cornwall, NY 12518",
              "phone1": null,
              "phone2": "845-567-1270",
              "fax1": null,
              "fax2": "845-534-2520",
              "twitter": "JamesSkoufis",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/james-skoufis/contact",
                "https://www.nysenate.gov/senators/james-skoufis"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-42-upper-ocd-person-c1f048f2-6e1d-4e1b-b161-d97e2f16c1f8": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/b03d69c0-0ea1-4524-86e6-c6b20797a2eb",
            "firstName": "Jacob Christopher",
            "lastName": "Ashby",
            "fullName": "Jake Ashby",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/107_500.jpg?itok=Y5CMJSoa",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "43",
              "chamber": "upper",
              "hashID": "SLDU-NY-43-upper-ocd-person-b03d69c0-0ea1-4524-86e6-c6b20797a2eb",
              "builtID": "ny-upper-43",
              "externalID": "ocd-person/b03d69c0-0ea1-4524-86e6-c6b20797a2eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "ashby@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "594 Columbia Turnpike, East Greenbush, NY 12061",
              "phone1": null,
              "phone2": "518-371-2751",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Jake-Ashby",
                "https://www.nysenate.gov/senators/jacob-ashby"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-43-upper-ocd-person-b03d69c0-0ea1-4524-86e6-c6b20797a2eb": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/975453b1-113a-40e9-8036-53e714a47b66",
            "firstName": "James Nicholas",
            "lastName": "Tedisco",
            "fullName": "Jim Tedisco",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/official_tedisco-hs.jpg?itok=4-C9InuB",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "44",
              "chamber": "upper",
              "hashID": "SLDU-NY-44-upper-ocd-person-975453b1-113a-40e9-8036-53e714a47b66",
              "builtID": "ny-upper-44",
              "externalID": "ocd-person/975453b1-113a-40e9-8036-53e714a47b66",
              "geometry": null
            },
            "contactInfo": {
              "email": "tedisco@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "636 Plank Road 2nd Floor, Clifton Park, NY 12065",
              "phone1": null,
              "phone2": "518-885-1829",
              "fax1": null,
              "fax2": "518-371-2649",
              "twitter": "JamesTedisco",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/james-tedisco/contact",
                "https://www.nysenate.gov/senators/james-tedisco"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-44-upper-ocd-person-975453b1-113a-40e9-8036-53e714a47b66": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/7bb8c8bc-0238-4fd2-8dff-e5f2b2aaa92a",
            "firstName": "Daniel George",
            "lastName": "Stec",
            "fullName": "Dan Stec",
            "gender": "Male",
            "party": "republican/conservative/independence",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/danielg_stec.jpg?itok=iADbdWhJ",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "45",
              "chamber": "upper",
              "hashID": "SLDU-NY-45-upper-ocd-person-7bb8c8bc-0238-4fd2-8dff-e5f2b2aaa92a",
              "builtID": "ny-upper-45",
              "externalID": "ocd-person/7bb8c8bc-0238-4fd2-8dff-e5f2b2aaa92a",
              "geometry": null
            },
            "contactInfo": {
              "email": "stec@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room B46, St. Lawrence County Courthouse 48 Court St., Canton, NY 13617",
              "phone1": null,
              "phone2": "315-229-3195",
              "fax1": null,
              "fax2": null,
              "twitter": "danstec",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/daniel-g-stec/contact",
                "https://www.nysenate.gov/senators/daniel-g-stec"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-45-upper-ocd-person-7bb8c8bc-0238-4fd2-8dff-e5f2b2aaa92a": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/2ef4aac9-fb53-43cc-b9a5-b51313534d36",
            "firstName": "Neil David",
            "lastName": "Breslin",
            "fullName": "Neil Breslin",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/07-20-11_breslin_-hs-032.jpg?itok=sNxOrDrN",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "46",
              "chamber": "upper",
              "hashID": "SLDU-NY-46-upper-ocd-person-2ef4aac9-fb53-43cc-b9a5-b51313534d36",
              "builtID": "ny-upper-46",
              "externalID": "ocd-person/2ef4aac9-fb53-43cc-b9a5-b51313534d36",
              "geometry": null
            },
            "contactInfo": {
              "email": "breslin@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "61 Church St., 3rd Floor, Amsterdam, NY 12010",
              "phone1": null,
              "phone2": "518-842-2159",
              "fax1": null,
              "fax2": null,
              "twitter": "NeilBreslin44",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/neil-d-breslin/contact",
                "https://www.nysenate.gov/senators/neil-d-breslin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-46-upper-ocd-person-2ef4aac9-fb53-43cc-b9a5-b51313534d36": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/5fed799f-9800-457a-9c39-e19d8735c74f",
            "firstName": "Brad Madison",
            "lastName": "Hoylman-Sigal",
            "fullName": "Brad Hoylman-Sigal",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/bh_headshot.jpg?itok=Q44ltiNA",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "47",
              "chamber": "upper",
              "hashID": "SLDU-NY-47-upper-ocd-person-5fed799f-9800-457a-9c39-e19d8735c74f",
              "builtID": "ny-upper-47",
              "externalID": "ocd-person/5fed799f-9800-457a-9c39-e19d8735c74f",
              "geometry": null
            },
            "contactInfo": {
              "email": "hoylman@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "322 8th Ave. Suite 1700, New York, NY 10001",
              "phone1": null,
              "phone2": "212-633-8052",
              "fax1": null,
              "fax2": null,
              "twitter": "bradhoylman",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/brad-hoylman/contact",
                "https://www.nysenate.gov/senators/brad-hoylman-sigal"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-47-upper-ocd-person-5fed799f-9800-457a-9c39-e19d8735c74f": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/92f3f397-dd30-4d35-95dc-6381a8d9dcd5",
            "firstName": "Rachel",
            "lastName": "May",
            "fullName": "Rachel May",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/may-2019portrait.jpg?itok=4i_QKFvz",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "48",
              "chamber": "upper",
              "hashID": "SLDU-NY-48-upper-ocd-person-92f3f397-dd30-4d35-95dc-6381a8d9dcd5",
              "builtID": "ny-upper-48",
              "externalID": "ocd-person/92f3f397-dd30-4d35-95dc-6381a8d9dcd5",
              "geometry": null
            },
            "contactInfo": {
              "email": "may@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "333 E. Washington St. Suite 805, Syracuse, NY 13202",
              "phone1": null,
              "phone2": "315-478-8745",
              "fax1": null,
              "fax2": null,
              "twitter": "RachelMayNY",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/rachel-may/contact",
                "https://www.nysenate.gov/senators/rachel-may"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-48-upper-ocd-person-92f3f397-dd30-4d35-95dc-6381a8d9dcd5": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/615c8d33-9652-4163-87b9-0fc82fc09b8c",
            "firstName": "Mark C.",
            "lastName": "Walczyk",
            "fullName": "Mark Walczyk",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/walczyk_hs_001.jpg?itok=DzABU3Xf",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "49",
              "chamber": "upper",
              "hashID": "SLDU-NY-49-upper-ocd-person-615c8d33-9652-4163-87b9-0fc82fc09b8c",
              "builtID": "ny-upper-49",
              "externalID": "ocd-person/615c8d33-9652-4163-87b9-0fc82fc09b8c",
              "geometry": null
            },
            "contactInfo": {
              "email": "walczyk@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Fulton County Office Building 223 W. Main St., Suite B10, Johnstown, NY 12095",
              "phone1": null,
              "phone2": "518-762-3733",
              "fax1": null,
              "fax2": null,
              "twitter": "MarkWalczyk",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://assembly.state.ny.us/mem/?sh=email",
                "https://assembly.state.ny.us/mem/Mark-Walczyk",
                "https://www.nysenate.gov/senators/mark-walczyk"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-49-upper-ocd-person-615c8d33-9652-4163-87b9-0fc82fc09b8c": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/fcfe534b-e6d4-4066-83c0-6d0d617aab82",
            "firstName": "John W.",
            "lastName": "Mannion",
            "fullName": "John Mannion",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/sd50-member-head-shot-160-mannion.jpg?itok=Csf_cnNL",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "50",
              "chamber": "upper",
              "hashID": "SLDU-NY-50-upper-ocd-person-fcfe534b-e6d4-4066-83c0-6d0d617aab82",
              "builtID": "ny-upper-50",
              "externalID": "ocd-person/fcfe534b-e6d4-4066-83c0-6d0d617aab82",
              "geometry": null
            },
            "contactInfo": {
              "email": "mannion@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 800, State Office Building 333 E. Washington St., Syracuse, NY 13202",
              "phone1": null,
              "phone2": "315-428-7632",
              "fax1": null,
              "fax2": null,
              "twitter": "SenJohnMannion",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/john-w-mannion/contact",
                "https://www.nysenate.gov/senators/john-w-mannion"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-50-upper-ocd-person-fcfe534b-e6d4-4066-83c0-6d0d617aab82": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/fe80b36a-56ba-49d6-a14c-05f35ba6f610",
            "firstName": "Peter K.",
            "lastName": "Oberacker",
            "fullName": "Peter Oberacker",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/sen_oberacker_hs_square.jpeg?itok=GEcwHkc9",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "51",
              "chamber": "upper",
              "hashID": "SLDU-NY-51-upper-ocd-person-fe80b36a-56ba-49d6-a14c-05f35ba6f610",
              "builtID": "ny-upper-51",
              "externalID": "ocd-person/fe80b36a-56ba-49d6-a14c-05f35ba6f610",
              "geometry": null
            },
            "contactInfo": {
              "email": "oberacker@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "41 S. Main St., Oneonta, NY 13820",
              "phone1": null,
              "phone2": "607-432-5524",
              "fax1": null,
              "fax2": null,
              "twitter": "peter4senate51",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/peter-oberacker/contact",
                "https://www.nysenate.gov/senators/peter-oberacker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-51-upper-ocd-person-fe80b36a-56ba-49d6-a14c-05f35ba6f610": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/a0876791-c573-4028-8c61-f7bbac545074",
            "firstName": "Lea",
            "lastName": "Webb",
            "fullName": "Lea Webb",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/senwebbhs.jpg?itok=Tz0a39KS",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "52",
              "chamber": "upper",
              "hashID": "SLDU-NY-52-upper-ocd-person-a0876791-c573-4028-8c61-f7bbac545074",
              "builtID": "ny-upper-52",
              "externalID": "ocd-person/a0876791-c573-4028-8c61-f7bbac545074",
              "geometry": null
            },
            "contactInfo": {
              "email": "leawebb@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "217 N. Aurora St., Ithaca, NY 14850",
              "phone1": null,
              "phone2": "607-773-8771",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.nysenate.gov/senators/lea-webb"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-52-upper-ocd-person-a0876791-c573-4028-8c61-f7bbac545074": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/738fe4f1-dfee-40c6-9f00-f793ea369dbc",
            "firstName": "Joseph A.",
            "lastName": "Griffo",
            "fullName": "Joe Griffo",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/%2811-18-08%29%20Griffo-HS-020.jpg?itok=rGK6kVTU",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "53",
              "chamber": "upper",
              "hashID": "SLDU-NY-53-upper-ocd-person-738fe4f1-dfee-40c6-9f00-f793ea369dbc",
              "builtID": "ny-upper-53",
              "externalID": "ocd-person/738fe4f1-dfee-40c6-9f00-f793ea369dbc",
              "geometry": null
            },
            "contactInfo": {
              "email": "griffo@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 408, 207 Genesee St., Utica, NY 13501",
              "phone1": null,
              "phone2": "315-793-9072",
              "fax1": null,
              "fax2": "315-793-0298",
              "twitter": "SenGriffo",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/joseph-griffo/contact",
                "https://www.nysenate.gov/senators/joseph-griffo"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-53-upper-ocd-person-738fe4f1-dfee-40c6-9f00-f793ea369dbc": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/fb2db4c8-a730-4fe7-b5e3-4d0156e46a1d",
            "firstName": "Pamela A.",
            "lastName": "Helming",
            "fullName": "Pam Helming",
            "gender": "Female",
            "party": "republican/conservative/independence",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/senator_helming_headshot_2022_1.jpg?itok=oitrgwih",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "54",
              "chamber": "upper",
              "hashID": "SLDU-NY-54-upper-ocd-person-fb2db4c8-a730-4fe7-b5e3-4d0156e46a1d",
              "builtID": "ny-upper-54",
              "externalID": "ocd-person/fb2db4c8-a730-4fe7-b5e3-4d0156e46a1d",
              "geometry": null
            },
            "contactInfo": {
              "email": "helming@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 304, 6 Court St., Geneseo, NY 14454",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SenatorHelming",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/pamela-helming/contact",
                "https://www.nysenate.gov/senators/pamela-helming"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-54-upper-ocd-person-fb2db4c8-a730-4fe7-b5e3-4d0156e46a1d": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/f1914b1e-ae9f-4b37-a12a-418b8618b98b",
            "firstName": "Samra G.",
            "lastName": "Brouk",
            "fullName": "Samra Brouk",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/brouk2023hs.jpg?itok=Y9a9shjq",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "55",
              "chamber": "upper",
              "hashID": "SLDU-NY-55-upper-ocd-person-f1914b1e-ae9f-4b37-a12a-418b8618b98b",
              "builtID": "ny-upper-55",
              "externalID": "ocd-person/f1914b1e-ae9f-4b37-a12a-418b8618b98b",
              "geometry": null
            },
            "contactInfo": {
              "email": "brouk@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "274 N. Goodman St. Suite B127, Rochester, NY 14607",
              "phone1": null,
              "phone2": "585-223-1800",
              "fax1": null,
              "fax2": null,
              "twitter": "samraforsenate",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/samra-g-brouk/contact",
                "https://www.nysenate.gov/senators/samra-g-brouk"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-55-upper-ocd-person-f1914b1e-ae9f-4b37-a12a-418b8618b98b": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/6d73b968-c1db-434e-9876-de2fdff30d89",
            "firstName": "Jeremy A.",
            "lastName": "Cooney",
            "fullName": "Jeremy Cooney",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/sd56_cooneyhs.jpg?itok=bMYwiWoQ",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "56",
              "chamber": "upper",
              "hashID": "SLDU-NY-56-upper-ocd-person-6d73b968-c1db-434e-9876-de2fdff30d89",
              "builtID": "ny-upper-56",
              "externalID": "ocd-person/6d73b968-c1db-434e-9876-de2fdff30d89",
              "geometry": null
            },
            "contactInfo": {
              "email": "cooney@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "280 E. Broad St. Suite 110, Rochester, NY 14604",
              "phone1": null,
              "phone2": "585-225-3650",
              "fax1": null,
              "fax2": null,
              "twitter": "JeremyCooneyROC",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/jeremy-cooney/contact",
                "https://www.nysenate.gov/senators/jeremy-cooney",
                "https://www.rocsenator.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-56-upper-ocd-person-6d73b968-c1db-434e-9876-de2fdff30d89": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/676247d1-048f-45de-b265-34069edb2be0",
            "firstName": "George M.",
            "lastName": "Borrello",
            "fullName": "George Borrello",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/12-11-19_hs_cropped_for_web0004m.jpg?itok=rraEgSVs",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "57",
              "chamber": "upper",
              "hashID": "SLDU-NY-57-upper-ocd-person-676247d1-048f-45de-b265-34069edb2be0",
              "builtID": "ny-upper-57",
              "externalID": "ocd-person/676247d1-048f-45de-b265-34069edb2be0",
              "geometry": null
            },
            "contactInfo": {
              "email": "borrello@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Westgate Plaza 700 W. State St., Olean, NY 14760",
              "phone1": null,
              "phone2": "716-372-4901",
              "fax1": null,
              "fax2": "716-372-5740",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/george-m-borrello/contact",
                "https://www.nysenate.gov/senators/george-m-borrello"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-57-upper-ocd-person-676247d1-048f-45de-b265-34069edb2be0": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/c7aec173-278b-4fe6-9237-7c8b751cda1e",
            "firstName": "Thomas F.",
            "lastName": "O'Mara",
            "fullName": "Tom O'Mara",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/02-10-15_official_hs_omara-hs-040_0.jpg?itok=0S6A6rmV",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "58",
              "chamber": "upper",
              "hashID": "SLDU-NY-58-upper-ocd-person-c7aec173-278b-4fe6-9237-7c8b751cda1e",
              "builtID": "ny-upper-58",
              "externalID": "ocd-person/c7aec173-278b-4fe6-9237-7c8b751cda1e",
              "geometry": null
            },
            "contactInfo": {
              "email": "omara@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Seneca County Office Building 1 Dipronio Drive, 3rd Floor, Waterloo, NY 13165",
              "phone1": null,
              "phone2": "315-393-3024",
              "fax1": null,
              "fax2": null,
              "twitter": "SenatorOMara",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/thomas-f-omara/contact",
                "https://www.nysenate.gov/senators/thomas-f-omara"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-58-upper-ocd-person-c7aec173-278b-4fe6-9237-7c8b751cda1e": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/f96b188a-83e0-4eab-876f-964e06d721d4",
            "firstName": "Kristen S.",
            "lastName": "Gonzalez",
            "fullName": "Kristen Gonzalez",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/gonzalez_hs-1_0.jpg?itok=Ji5anDju",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "59",
              "chamber": "upper",
              "hashID": "SLDU-NY-59-upper-ocd-person-f96b188a-83e0-4eab-876f-964e06d721d4",
              "builtID": "ny-upper-59",
              "externalID": "ocd-person/f96b188a-83e0-4eab-876f-964e06d721d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "gonzalez@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "801 2nd Ave. Suite 303, New York, NY 10017",
              "phone1": null,
              "phone2": "718-765-6674",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.nysenate.gov/senators/kristen-gonzalez"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-59-upper-ocd-person-f96b188a-83e0-4eab-876f-964e06d721d4": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/531f1b36-a809-496b-beb8-ba3cd0bc0c53",
            "firstName": "Patrick M.",
            "lastName": "Gallivan",
            "fullName": "Patrick Gallivan",
            "gender": "Male",
            "party": "republican/conservative",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/09-15-16_gallivan-hs-012a.jpg?itok=6Ayxm7iL",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "60",
              "chamber": "upper",
              "hashID": "SLDU-NY-60-upper-ocd-person-531f1b36-a809-496b-beb8-ba3cd0bc0c53",
              "builtID": "ny-upper-60",
              "externalID": "ocd-person/531f1b36-a809-496b-beb8-ba3cd0bc0c53",
              "geometry": null
            },
            "contactInfo": {
              "email": "gallivan@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2721 Transit Road Suite 116, Elma, NY 14059",
              "phone1": null,
              "phone2": "716-656-8544",
              "fax1": null,
              "fax2": "716-656-8961",
              "twitter": "senatorgallivan",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/patrick-m-gallivan/contact",
                "https://www.nysenate.gov/senators/patrick-m-gallivan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-60-upper-ocd-person-531f1b36-a809-496b-beb8-ba3cd0bc0c53": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/6913e7e0-3ad5-4593-a2b0-dc31e974cdf6",
            "firstName": "Sean M.",
            "lastName": "Ryan",
            "fullName": "Sean Ryan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/01-20-21_ryan-hs_0011.jpg?itok=Nvb1381k",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "61",
              "chamber": "upper",
              "hashID": "SLDU-NY-61-upper-ocd-person-6913e7e0-3ad5-4593-a2b0-dc31e974cdf6",
              "builtID": "ny-upper-61",
              "externalID": "ocd-person/6913e7e0-3ad5-4593-a2b0-dc31e974cdf6",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryan@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1485 Niagara St. Suite 350, Buffalo, NY 14213",
              "phone1": null,
              "phone2": "716-854-8705",
              "fax1": null,
              "fax2": null,
              "twitter": "SenSeanRyan",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/sean-m-ryan/contact",
                "https://www.nysenate.gov/senators/sean-m-ryan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-61-upper-ocd-person-6913e7e0-3ad5-4593-a2b0-dc31e974cdf6": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/3aaaa058-953f-4fd2-b905-af6bc8d3fca3",
            "firstName": "Robert Gary",
            "lastName": "Ortt",
            "fullName": "Rob Ortt",
            "gender": "Male",
            "party": "republican/conservative/independence",
            "image": "https://www.nysenate.gov/sites/default/files/styles/160x160/public/62-2019-bio.jpg?itok=-buQ_zqu",
            "title": "NY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NY",
              "stateFull": "New York",
              "district": "62",
              "chamber": "upper",
              "hashID": "SLDU-NY-62-upper-ocd-person-3aaaa058-953f-4fd2-b905-af6bc8d3fca3",
              "builtID": "ny-upper-62",
              "externalID": "ocd-person/3aaaa058-953f-4fd2-b905-af6bc8d3fca3",
              "geometry": null
            },
            "contactInfo": {
              "email": "ortt@nysenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "175 Walnut St. Suite 6, Lockport, NY 14094",
              "phone1": null,
              "phone2": "716-434-0680",
              "fax1": null,
              "fax2": "716-434-3297",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.nysenate.gov/senators-committees",
                "https://www.nysenate.gov/senators/robert-g-ortt/contact",
                "https://www.nysenate.gov/senators/robert-g-ortt"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NY-62-upper-ocd-person-3aaaa058-953f-4fd2-b905-af6bc8d3fca3": 0
        }
      }
    }
  },
  "NC": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "NC",
          "stateFull": "North Carolina",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "NC",
            "stateFull": "North Carolina",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-NC---",
            "builtID": "nc--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-NC---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "B001305",
          "in_office": true,
          "firstName": "Ted",
          "lastName": "Budd",
          "middleName": null,
          "fullName": "Ted Budd",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/B001305.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "NC",
            "stateFull": "North Carolina",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-NC---B001305",
            "builtID": "nc--",
            "externalID": "B001305",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "RepTedBudd",
            "youtube": null,
            "instagram": null,
            "facebook": "RepTedBudd",
            "urls": [],
            "rss_url": null
          },
          "title": "NC Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/B001305.json",
          "govtrack_id": "412712",
          "cspan_id": "103513",
          "votesmart_id": "171489",
          "icpsr_id": "21708",
          "crp_id": "N00039551",
          "google_entity_id": "/g/11c2nmnsj0",
          "state_rank": "junior",
          "lis_id": "S417",
          "suffix": null,
          "date_of_birth": "1971-10-21",
          "leadership_role": null,
          "fec_candidate_id": "H6NC13129",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 18,
          "total_present": 0,
          "last_updated": "2024-06-20 15:30:30 -0400",
          "missed_votes_pct": 3.25,
          "votes_with_party_pct": 93.92,
          "votes_against_party_pct": 6.08,
          "ocd_id": "ocd-division/country:us/state:nc"
        },
        {
          "API_ID": "T000476",
          "in_office": true,
          "firstName": "Thom",
          "lastName": "Tillis",
          "middleName": null,
          "fullName": "Thom Tillis",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/T000476.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "NC",
            "stateFull": "North Carolina",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-NC---T000476",
            "builtID": "nc--",
            "externalID": "T000476",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.tillis.senate.gov/public/index.cfm/email-me",
            "address1": "113 Dirksen Senate Office Building",
            "address2": null,
            "phone1": "202-224-6342",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "senthomtillis",
            "youtube": null,
            "instagram": null,
            "facebook": "SenatorThomTillis",
            "urls": ["https://www.tillis.senate.gov"],
            "rss_url": null
          },
          "title": "NC Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/T000476.json",
          "govtrack_id": "412668",
          "cspan_id": "77055",
          "votesmart_id": "57717",
          "icpsr_id": "41504",
          "crp_id": "N00035492",
          "google_entity_id": "/m/0fq0395",
          "state_rank": "senior",
          "lis_id": "S384",
          "suffix": null,
          "date_of_birth": "1960-08-30",
          "leadership_role": null,
          "fec_candidate_id": "S4NC00162",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "9",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 62,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 11.19,
          "votes_with_party_pct": 79.67,
          "votes_against_party_pct": 20.33,
          "ocd_id": "ocd-division/country:us/state:nc"
        }
      ],
      "hash": { "SENATE-NC---B001305": 0, "SENATE-NC---T000476": 1 }
    },
    "HOUSE": {
      "10": {
        "members": [
          {
            "API_ID": "M001156",
            "in_office": true,
            "firstName": "Patrick",
            "lastName": "McHenry",
            "middleName": "T.",
            "fullName": "Patrick T. McHenry",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001156.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "10",
              "chamber": "",
              "hashID": "HOUSE-NC-10--M001156",
              "builtID": "nc--10",
              "externalID": "M001156",
              "geometry": null,
              "geoid": "3710"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2134 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2576",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "PatrickMcHenry",
              "youtube": "CongressmanMcHenry",
              "instagram": null,
              "facebook": "CongressmanMcHenry",
              "urls": ["https://mchenry.house.gov"],
              "rss_url": "https://mchenry.house.gov/news/rss.aspx"
            },
            "title": "NC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001156.json",
            "govtrack_id": "400644",
            "cspan_id": "1007062",
            "votesmart_id": "21031",
            "icpsr_id": "20522",
            "crp_id": "N00026627",
            "google_entity_id": "/m/02b4rz",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1975-10-22",
            "leadership_role": null,
            "fec_candidate_id": "H4NC10047",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "20",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 155,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 14.69,
            "votes_with_party_pct": 92.1,
            "votes_against_party_pct": 5.76,
            "ocd_id": "ocd-division/country:us/state:nc/cd:10",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NC-10--M001156": 0 }
      },
      "11": {
        "members": [
          {
            "API_ID": "E000246",
            "in_office": true,
            "firstName": "Chuck",
            "lastName": "Edwards",
            "middleName": null,
            "fullName": "Chuck Edwards",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/E000246.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "11",
              "chamber": "",
              "hashID": "HOUSE-NC-11--E000246",
              "builtID": "nc--11",
              "externalID": "E000246",
              "geometry": null,
              "geoid": "3711"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1505 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-6401",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepEdwards",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://edwards.house.gov/"],
              "rss_url": null
            },
            "title": "NC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/E000246.json",
            "govtrack_id": "456914",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1960-09-13",
            "leadership_role": null,
            "fec_candidate_id": "H2NC14050",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 11,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.04,
            "votes_with_party_pct": 86.52,
            "votes_against_party_pct": 11.52,
            "ocd_id": "ocd-division/country:us/state:nc/cd:11",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NC-11--E000246": 0 }
      },
      "12": {
        "members": [
          {
            "API_ID": "A000370",
            "in_office": true,
            "firstName": "Alma",
            "lastName": "Adams",
            "middleName": null,
            "fullName": "Alma S. Adams",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/A000370.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "12",
              "chamber": "",
              "hashID": "HOUSE-NC-12--A000370",
              "builtID": "nc--12",
              "externalID": "A000370",
              "geometry": null,
              "geoid": "3712"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2436 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-1510",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAdams",
              "youtube": null,
              "instagram": null,
              "facebook": "CongresswomanAdams",
              "urls": ["https://adams.house.gov"],
              "rss_url": "https://adams.house.gov/rss.xml"
            },
            "title": "NC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/A000370.json",
            "govtrack_id": "412607",
            "cspan_id": "76386",
            "votesmart_id": "5935",
            "icpsr_id": "21545",
            "crp_id": "N00035451",
            "google_entity_id": "/m/02b45d",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1946-05-27",
            "leadership_role": null,
            "fec_candidate_id": "H4NC12100",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 25,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.37,
            "votes_with_party_pct": 96.67,
            "votes_against_party_pct": 1.37,
            "ocd_id": "ocd-division/country:us/state:nc/cd:12",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NC-12--A000370": 0 }
      },
      "13": {
        "members": [
          {
            "API_ID": "N000194",
            "in_office": true,
            "firstName": "Wiley",
            "lastName": "Nickel",
            "middleName": null,
            "fullName": "Wiley Nickel",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/N000194.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "13",
              "chamber": "",
              "hashID": "HOUSE-NC-13--N000194",
              "builtID": "nc--13",
              "externalID": "N000194",
              "geometry": null,
              "geoid": "3713"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1133 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4531",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://nickel.house.gov/"],
              "rss_url": null
            },
            "title": "NC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/N000194.json",
            "govtrack_id": "456915",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1975-11-23",
            "leadership_role": null,
            "fec_candidate_id": "H2NC14019",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 34,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 3.22,
            "votes_with_party_pct": 91.8,
            "votes_against_party_pct": 6.23,
            "ocd_id": "ocd-division/country:us/state:nc/cd:13",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NC-13--N000194": 0 }
      },
      "14": {
        "members": [
          {
            "API_ID": "J000308",
            "in_office": true,
            "firstName": "Jeffrey",
            "lastName": "Jackson",
            "middleName": null,
            "fullName": "Jeff Jackson",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/J000308.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "14",
              "chamber": "",
              "hashID": "HOUSE-NC-14--J000308",
              "builtID": "nc--14",
              "externalID": "J000308",
              "geometry": null,
              "geoid": "3714"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1318 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5634",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://jeffjackson.house.gov/"],
              "rss_url": null
            },
            "title": "NC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/J000308.json",
            "govtrack_id": "456916",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1982-09-12",
            "leadership_role": null,
            "fec_candidate_id": "H2NC14076",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 13,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.23,
            "votes_with_party_pct": 93.8,
            "votes_against_party_pct": 4.26,
            "ocd_id": "ocd-division/country:us/state:nc/cd:14",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NC-14--J000308": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "B001311",
            "in_office": true,
            "firstName": "Dan",
            "lastName": "Bishop",
            "middleName": null,
            "fullName": "Dan Bishop",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001311.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-NC-08--B001311",
              "builtID": "nc--08",
              "externalID": "B001311",
              "geometry": null,
              "geoid": "3708"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2459 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-1976",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDanBishop",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://danbishop.house.gov"],
              "rss_url": null
            },
            "title": "NC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001311.json",
            "govtrack_id": "412844",
            "cspan_id": null,
            "votesmart_id": "92423",
            "icpsr_id": null,
            "crp_id": "N00044335",
            "google_entity_id": "/m/013bg9xz",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1964-07-01",
            "leadership_role": null,
            "fec_candidate_id": "H0NC09187",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 63,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 5.97,
            "votes_with_party_pct": 84.88,
            "votes_against_party_pct": 13.07,
            "ocd_id": "ocd-division/country:us/state:nc/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NC-08--B001311": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "D000230",
            "in_office": true,
            "firstName": "Donald",
            "lastName": "Davis",
            "middleName": "G.",
            "fullName": "Donald G. Davis",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/D000230.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-NC-01--D000230",
              "builtID": "nc--01",
              "externalID": "D000230",
              "geometry": null,
              "geoid": "3701"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1123 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3101",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://dondavis.house.gov/"],
              "rss_url": null
            },
            "title": "NC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000230.json",
            "govtrack_id": "456912",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1971-08-29",
            "leadership_role": null,
            "fec_candidate_id": "H2NC02287",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 42,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 3.98,
            "votes_with_party_pct": 84.46,
            "votes_against_party_pct": 13.55,
            "ocd_id": "ocd-division/country:us/state:nc/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NC-01--D000230": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "F000477",
            "in_office": true,
            "firstName": "Valerie",
            "lastName": "Foushee",
            "middleName": null,
            "fullName": "Valerie P. Foushee",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/F000477.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-NC-04--F000477",
              "builtID": "nc--04",
              "externalID": "F000477",
              "geometry": null,
              "geoid": "3704"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1716 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-1784",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "ValerieFoushee",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://foushee.house.gov/"],
              "rss_url": null
            },
            "title": "NC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000477.json",
            "govtrack_id": "456913",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1956-05-07",
            "leadership_role": null,
            "fec_candidate_id": "H2NC06114",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 39,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.69,
            "votes_with_party_pct": 95.93,
            "votes_against_party_pct": 2.08,
            "ocd_id": "ocd-division/country:us/state:nc/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NC-04--F000477": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "F000450",
            "in_office": true,
            "firstName": "Virginia",
            "lastName": "Foxx",
            "middleName": null,
            "fullName": "Virginia Foxx",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/F000450.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-NC-05--F000450",
              "builtID": "nc--05",
              "externalID": "F000450",
              "geometry": null,
              "geoid": "3705"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2462 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2071",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "VirginiaFoxx",
              "youtube": "repvirginiafoxx",
              "instagram": null,
              "facebook": "RepVirginiaFoxx",
              "urls": ["https://foxx.house.gov"],
              "rss_url": "https://foxx.house.gov/news/rss.aspx"
            },
            "title": "NC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000450.json",
            "govtrack_id": "400643",
            "cspan_id": "1013052",
            "votesmart_id": "6051",
            "icpsr_id": "20521",
            "crp_id": "N00026166",
            "google_entity_id": "/m/02b5dz",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1943-06-29",
            "leadership_role": null,
            "fec_candidate_id": "H4NC05146",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "18",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.57,
            "votes_with_party_pct": 93.49,
            "votes_against_party_pct": 4.66,
            "ocd_id": "ocd-division/country:us/state:nc/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NC-05--F000450": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "H001067",
            "in_office": true,
            "firstName": "Richard",
            "lastName": "Hudson",
            "middleName": null,
            "fullName": "Richard Hudson",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/H001067.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-NC-09--H001067",
              "builtID": "nc--09",
              "externalID": "H001067",
              "geometry": null,
              "geoid": "3709"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2112 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3715",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRichHudson",
              "youtube": "RepRichHudson",
              "instagram": null,
              "facebook": "RepRichHudson",
              "urls": ["https://hudson.house.gov"],
              "rss_url": null
            },
            "title": "NC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001067.json",
            "govtrack_id": "412550",
            "cspan_id": "79622",
            "votesmart_id": "136448",
            "icpsr_id": "21346",
            "crp_id": "N00033630",
            "google_entity_id": "/m/0nbwfxs",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1971-11-04",
            "leadership_role": "House Republican Conference Secretary",
            "fec_candidate_id": "H2NC08185",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 27,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.56,
            "votes_with_party_pct": 93.05,
            "votes_against_party_pct": 5.06,
            "ocd_id": "ocd-division/country:us/state:nc/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NC-09--H001067": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "M001135",
            "in_office": true,
            "firstName": "Kathy",
            "lastName": "Manning",
            "middleName": null,
            "fullName": "Kathy E. Manning",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M001135.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-NC-06--M001135",
              "builtID": "nc--06",
              "externalID": "M001135",
              "geometry": null,
              "geoid": "3706"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "307 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3065",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepKManning",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://manning.house.gov"],
              "rss_url": null
            },
            "title": "NC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001135.json",
            "govtrack_id": "456832",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00042159",
            "google_entity_id": "/g/11dxpjz93v",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1956-12-03",
            "leadership_role": null,
            "fec_candidate_id": "H8NC13067",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 7,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.66,
            "votes_with_party_pct": 92.01,
            "votes_against_party_pct": 6.06,
            "ocd_id": "ocd-division/country:us/state:nc/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NC-06--M001135": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "M001210",
            "in_office": true,
            "firstName": "Greg",
            "lastName": "Murphy",
            "middleName": null,
            "fullName": "Gregory F. Murphy",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001210.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-NC-03--M001210",
              "builtID": "nc--03",
              "externalID": "M001210",
              "geometry": null,
              "geoid": "3703"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "407 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3415",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGregMurphy",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://murphy.house.gov"],
              "rss_url": null
            },
            "title": "NC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001210.json",
            "govtrack_id": "412845",
            "cspan_id": null,
            "votesmart_id": "166135",
            "icpsr_id": null,
            "crp_id": "N00044027",
            "google_entity_id": "/g/11clyw935r",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1963-03-05",
            "leadership_role": null,
            "fec_candidate_id": "H0NC03172",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 152,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 14.39,
            "votes_with_party_pct": 92.01,
            "votes_against_party_pct": 5.85,
            "ocd_id": "ocd-division/country:us/state:nc/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NC-03--M001210": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "R000305",
            "in_office": true,
            "firstName": "Deborah",
            "lastName": "Ross",
            "middleName": null,
            "fullName": "Deborah K. Ross",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/R000305.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-NC-02--R000305",
              "builtID": "nc--02",
              "externalID": "R000305",
              "geometry": null,
              "geoid": "3702"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1221 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3032",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDeborahRoss",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ross.house.gov"],
              "rss_url": null
            },
            "title": "NC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/R000305.json",
            "govtrack_id": "456831",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00038565",
            "google_entity_id": "/m/02b3x2",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1963-06-20",
            "leadership_role": null,
            "fec_candidate_id": "H0NC02125",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 21,
            "total_present": 6,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.99,
            "votes_with_party_pct": 96.1,
            "votes_against_party_pct": 1.95,
            "ocd_id": "ocd-division/country:us/state:nc/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NC-02--R000305": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "R000603",
            "in_office": true,
            "firstName": "David",
            "lastName": "Rouzer",
            "middleName": null,
            "fullName": "David Rouzer",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/R000603.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-NC-07--R000603",
              "builtID": "nc--07",
              "externalID": "R000603",
              "geometry": null,
              "geoid": "3707"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2333 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2731",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDavidRouzer",
              "youtube": null,
              "instagram": null,
              "facebook": "RepRouzer",
              "urls": ["https://rouzer.house.gov"],
              "rss_url": null
            },
            "title": "NC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/R000603.json",
            "govtrack_id": "412641",
            "cspan_id": "79710",
            "votesmart_id": "102964",
            "icpsr_id": "21544",
            "crp_id": "N00033527",
            "google_entity_id": "/m/0jt1s3l",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1972-02-16",
            "leadership_role": null,
            "fec_candidate_id": "H2NC07096",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 5,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.47,
            "votes_with_party_pct": 95.04,
            "votes_against_party_pct": 3.11,
            "ocd_id": "ocd-division/country:us/state:nc/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-NC-07--R000603": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/10d93679-f5f4-496f-8b7c-89f923038ac0",
            "firstName": "Edward Charles",
            "lastName": "Goodwin",
            "fullName": "Ed Goodwin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/744/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-NC-1-lower-ocd-person-10d93679-f5f4-496f-8b7c-89f923038ac0",
              "builtID": "nc-lower-1",
              "externalID": "ocd-person/10d93679-f5f4-496f-8b7c-89f923038ac0",
              "geometry": null
            },
            "contactInfo": {
              "email": "edward.goodwin@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/744",
                "https://www.ncleg.gov/Members/Votes/H/744",
                "https://www.ncleg.gov/Members/Committees/H/744",
                "https://www.ncleg.gov/Members/Biography/H/744"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-1-lower-ocd-person-10d93679-f5f4-496f-8b7c-89f923038ac0": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/064a56b9-d616-43a1-a3f2-ffd4938740d3",
            "firstName": "Brannon Ray",
            "lastName": "Jeffers",
            "fullName": "Ray Jeffers",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/805/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-NC-2-lower-ocd-person-064a56b9-d616-43a1-a3f2-ffd4938740d3",
              "builtID": "nc-lower-2",
              "externalID": "ocd-person/064a56b9-d616-43a1-a3f2-ffd4938740d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "ray.jeffers@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/805",
                "https://www.ncleg.gov/Members/Votes/H/805",
                "https://www.ncleg.gov/Members/Committees/H/805",
                "https://www.ncleg.gov/Members/Biography/H/805"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-2-lower-ocd-person-064a56b9-d616-43a1-a3f2-ffd4938740d3": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/e4c62004-8706-4984-acb1-0140967173f0",
            "firstName": "Steve",
            "lastName": "Tyson",
            "fullName": "Steve Tyson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/774/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-NC-3-lower-ocd-person-e4c62004-8706-4984-acb1-0140967173f0",
              "builtID": "nc-lower-3",
              "externalID": "ocd-person/e4c62004-8706-4984-acb1-0140967173f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.tyson@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/774",
                "https://www.ncleg.gov/Members/Votes/H/774",
                "https://www.ncleg.gov/Members/Committees/H/774",
                "https://www.ncleg.gov/Members/Biography/H/774"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-3-lower-ocd-person-e4c62004-8706-4984-acb1-0140967173f0": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/2fdb425d-47c9-4683-943e-15a566f3552b",
            "firstName": "James William",
            "lastName": "Dixon",
            "fullName": "Jimmy Dixon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/613/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-NC-4-lower-ocd-person-2fdb425d-47c9-4683-943e-15a566f3552b",
              "builtID": "nc-lower-4",
              "externalID": "ocd-person/2fdb425d-47c9-4683-943e-15a566f3552b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jimmy.dixon@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/613",
                "https://www.ncleg.gov/Members/Votes/H/613",
                "https://www.ncleg.gov/Members/Committees/H/613",
                "https://www.ncleg.gov/Members/Biography/H/613"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-4-lower-ocd-person-2fdb425d-47c9-4683-943e-15a566f3552b": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/f1d726fb-535d-456b-9712-b689afcb7763",
            "firstName": "Bill",
            "lastName": "Ward",
            "fullName": "Bill Ward",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/816/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-NC-5-lower-ocd-person-f1d726fb-535d-456b-9712-b689afcb7763",
              "builtID": "nc-lower-5",
              "externalID": "ocd-person/f1d726fb-535d-456b-9712-b689afcb7763",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.ward@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/816",
                "https://www.ncleg.gov/Members/Votes/H/816",
                "https://www.ncleg.gov/Members/Committees/H/816",
                "https://www.ncleg.gov/Members/Biography/H/816"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-5-lower-ocd-person-f1d726fb-535d-456b-9712-b689afcb7763": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/50435516-ccee-4b72-9075-e27a0bfe0a72",
            "firstName": "Joseph",
            "lastName": "Pike",
            "fullName": "Joe Pike",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/808/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-NC-6-lower-ocd-person-50435516-ccee-4b72-9075-e27a0bfe0a72",
              "builtID": "nc-lower-6",
              "externalID": "ocd-person/50435516-ccee-4b72-9075-e27a0bfe0a72",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.pike@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/808",
                "https://www.ncleg.gov/Members/Votes/H/808",
                "https://www.ncleg.gov/Members/Committees/H/808",
                "https://www.ncleg.gov/Members/Biography/H/808"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-6-lower-ocd-person-50435516-ccee-4b72-9075-e27a0bfe0a72": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/1dfa7f10-249d-43aa-930b-a5be9e4fce9f",
            "firstName": "Matthew",
            "lastName": "Winslow",
            "fullName": "Matthew Winslow",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/776/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-NC-7-lower-ocd-person-1dfa7f10-249d-43aa-930b-a5be9e4fce9f",
              "builtID": "nc-lower-7",
              "externalID": "ocd-person/1dfa7f10-249d-43aa-930b-a5be9e4fce9f",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.winslow@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/776",
                "https://www.ncleg.gov/Members/Votes/H/776",
                "https://www.ncleg.gov/Members/Committees/H/776",
                "https://www.ncleg.gov/Members/Biography/H/776"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-7-lower-ocd-person-1dfa7f10-249d-43aa-930b-a5be9e4fce9f": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/d2ab7bb8-d0d6-4824-a42e-d180c2644f9e",
            "firstName": "Gloristine Williams",
            "lastName": "Brown",
            "fullName": "Gloristine Brown",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/794/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-NC-8-lower-ocd-person-d2ab7bb8-d0d6-4824-a42e-d180c2644f9e",
              "builtID": "nc-lower-8",
              "externalID": "ocd-person/d2ab7bb8-d0d6-4824-a42e-d180c2644f9e",
              "geometry": null
            },
            "contactInfo": {
              "email": "gloristine.brown@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/794",
                "https://www.ncleg.gov/Members/Votes/H/794",
                "https://www.ncleg.gov/Members/Committees/H/794",
                "https://www.ncleg.gov/Members/Biography/H/794"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-8-lower-ocd-person-d2ab7bb8-d0d6-4824-a42e-d180c2644f9e": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/f836e608-2d16-4792-bec5-0993fa2db3aa",
            "firstName": "Timothy",
            "lastName": "Reeder",
            "fullName": "Tim Reeder",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/811/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-NC-9-lower-ocd-person-f836e608-2d16-4792-bec5-0993fa2db3aa",
              "builtID": "nc-lower-9",
              "externalID": "ocd-person/f836e608-2d16-4792-bec5-0993fa2db3aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "timothy.reeder@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/811",
                "https://www.ncleg.gov/Members/Votes/H/811",
                "https://www.ncleg.gov/Members/Committees/H/811",
                "https://www.ncleg.gov/Members/Biography/H/811"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-9-lower-ocd-person-f836e608-2d16-4792-bec5-0993fa2db3aa": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/cc410819-75a4-46dd-a3d5-edbd80d9f861",
            "firstName": "John Richard",
            "lastName": "Bell",
            "fullName": "John Bell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/661/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-NC-10-lower-ocd-person-cc410819-75a4-46dd-a3d5-edbd80d9f861",
              "builtID": "nc-lower-10",
              "externalID": "ocd-person/cc410819-75a4-46dd-a3d5-edbd80d9f861",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.bell@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/661",
                "https://www.ncleg.gov/Members/Votes/H/661",
                "https://www.ncleg.gov/Members/Committees/H/661",
                "https://www.ncleg.gov/Members/Biography/H/661"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-10-lower-ocd-person-cc410819-75a4-46dd-a3d5-edbd80d9f861": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/785927b3-34ca-4a2d-bc1c-df4cc8e16c47",
            "firstName": "Allison Anne",
            "lastName": "Dahle",
            "fullName": "Allison Dahle",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/740/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-NC-11-lower-ocd-person-785927b3-34ca-4a2d-bc1c-df4cc8e16c47",
              "builtID": "nc-lower-11",
              "externalID": "ocd-person/785927b3-34ca-4a2d-bc1c-df4cc8e16c47",
              "geometry": null
            },
            "contactInfo": {
              "email": "allison.dahle@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/740",
                "https://www.ncleg.gov/Members/Votes/H/740",
                "https://www.ncleg.gov/Members/Committees/H/740",
                "https://www.ncleg.gov/Members/Biography/H/740"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-11-lower-ocd-person-785927b3-34ca-4a2d-bc1c-df4cc8e16c47": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/fdf6de7b-aad3-403b-96c3-a6a007b11ccd",
            "firstName": "Thomas Christopher",
            "lastName": "Humphrey",
            "fullName": "Chris Humphrey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/747/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-NC-12-lower-ocd-person-fdf6de7b-aad3-403b-96c3-a6a007b11ccd",
              "builtID": "nc-lower-12",
              "externalID": "ocd-person/fdf6de7b-aad3-403b-96c3-a6a007b11ccd",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.humphrey@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/747",
                "https://www.ncleg.gov/Members/Votes/H/747",
                "https://www.ncleg.gov/Members/Committees/H/747",
                "https://www.ncleg.gov/Members/Biography/H/747"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-12-lower-ocd-person-fdf6de7b-aad3-403b-96c3-a6a007b11ccd": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/51b17197-aa93-4c8f-821d-81a32b41e2ae",
            "firstName": "Celeste Coppage",
            "lastName": "Cairns",
            "fullName": "Celeste Cairns",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/797/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-NC-13-lower-ocd-person-51b17197-aa93-4c8f-821d-81a32b41e2ae",
              "builtID": "nc-lower-13",
              "externalID": "ocd-person/51b17197-aa93-4c8f-821d-81a32b41e2ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "celeste.cairns@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/797",
                "https://www.ncleg.gov/Members/Votes/H/797",
                "https://www.ncleg.gov/Members/Committees/H/797",
                "https://www.ncleg.gov/Members/Biography/H/797"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-13-lower-ocd-person-51b17197-aa93-4c8f-821d-81a32b41e2ae": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/cc65900a-77d7-4ea4-9548-98c78ab2bb9a",
            "firstName": "George Grant",
            "lastName": "Cleveland",
            "fullName": "George Cleveland",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/476/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-NC-14-lower-ocd-person-cc65900a-77d7-4ea4-9548-98c78ab2bb9a",
              "builtID": "nc-lower-14",
              "externalID": "ocd-person/cc65900a-77d7-4ea4-9548-98c78ab2bb9a",
              "geometry": null
            },
            "contactInfo": {
              "email": "george.cleveland@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/476",
                "https://www.ncleg.gov/Members/Votes/H/476",
                "https://www.ncleg.gov/Members/Committees/H/476",
                "https://www.ncleg.gov/Members/Biography/H/476"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-14-lower-ocd-person-cc65900a-77d7-4ea4-9548-98c78ab2bb9a": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/eab6e9df-d2e4-4476-b09d-cda1de0cf654",
            "firstName": "Phillip Ray",
            "lastName": "Shepard",
            "fullName": "Phil Shepard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/628/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-NC-15-lower-ocd-person-eab6e9df-d2e4-4476-b09d-cda1de0cf654",
              "builtID": "nc-lower-15",
              "externalID": "ocd-person/eab6e9df-d2e4-4476-b09d-cda1de0cf654",
              "geometry": null
            },
            "contactInfo": {
              "email": "phil.shepard@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/628",
                "https://www.ncleg.gov/Members/Votes/H/628",
                "https://www.ncleg.gov/Members/Committees/H/628",
                "https://www.ncleg.gov/Members/Biography/H/628"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-15-lower-ocd-person-eab6e9df-d2e4-4476-b09d-cda1de0cf654": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/6f78b8a8-f77c-4aef-bfa4-b3dac3312e33",
            "firstName": "Carson Henry",
            "lastName": "Smith",
            "fullName": "Carson Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/755/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-NC-16-lower-ocd-person-6f78b8a8-f77c-4aef-bfa4-b3dac3312e33",
              "builtID": "nc-lower-16",
              "externalID": "ocd-person/6f78b8a8-f77c-4aef-bfa4-b3dac3312e33",
              "geometry": null
            },
            "contactInfo": {
              "email": "carson.smith@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/755",
                "https://www.ncleg.gov/Members/Votes/H/755",
                "https://www.ncleg.gov/Members/Committees/H/755",
                "https://www.ncleg.gov/Members/Biography/H/755"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-16-lower-ocd-person-6f78b8a8-f77c-4aef-bfa4-b3dac3312e33": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/f75593f8-b178-47f1-8e72-e403f04dfb5d",
            "firstName": "Francis Robertson",
            "lastName": "Iler",
            "fullName": "Frank Iler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/598/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-NC-17-lower-ocd-person-f75593f8-b178-47f1-8e72-e403f04dfb5d",
              "builtID": "nc-lower-17",
              "externalID": "ocd-person/f75593f8-b178-47f1-8e72-e403f04dfb5d",
              "geometry": null
            },
            "contactInfo": {
              "email": "frank.iler@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/598",
                "https://www.ncleg.gov/Members/Votes/H/598",
                "https://www.ncleg.gov/Members/Committees/H/598",
                "https://www.ncleg.gov/Members/Biography/H/598"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-17-lower-ocd-person-f75593f8-b178-47f1-8e72-e403f04dfb5d": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/cab978f1-1c91-41e4-9f2e-69af3c89bbf8",
            "firstName": "Deb",
            "lastName": "Butler",
            "fullName": "Deb Butler",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/730/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-NC-18-lower-ocd-person-cab978f1-1c91-41e4-9f2e-69af3c89bbf8",
              "builtID": "nc-lower-18",
              "externalID": "ocd-person/cab978f1-1c91-41e4-9f2e-69af3c89bbf8",
              "geometry": null
            },
            "contactInfo": {
              "email": "deb.butler@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/730",
                "https://www.ncleg.gov/Members/Votes/H/730",
                "https://www.ncleg.gov/Members/Committees/H/730",
                "https://www.ncleg.gov/Members/Biography/H/730"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-18-lower-ocd-person-cab978f1-1c91-41e4-9f2e-69af3c89bbf8": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/311f0605-29d2-4608-a964-323768582198",
            "firstName": "Charles W.",
            "lastName": "Miller",
            "fullName": "Charlie Miller",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/778/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-NC-19-lower-ocd-person-311f0605-29d2-4608-a964-323768582198",
              "builtID": "nc-lower-19",
              "externalID": "ocd-person/311f0605-29d2-4608-a964-323768582198",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.miller@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/778",
                "https://www.ncleg.gov/Members/Votes/H/778",
                "https://www.ncleg.gov/Members/Committees/H/778",
                "https://www.ncleg.gov/Members/Biography/H/778"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-19-lower-ocd-person-311f0605-29d2-4608-a964-323768582198": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/b654a887-2eca-41f7-83ba-60044df0fc1c",
            "firstName": "Robert Theodore",
            "lastName": "Davis",
            "fullName": "Ted Davis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/637/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-NC-20-lower-ocd-person-b654a887-2eca-41f7-83ba-60044df0fc1c",
              "builtID": "nc-lower-20",
              "externalID": "ocd-person/b654a887-2eca-41f7-83ba-60044df0fc1c",
              "geometry": null
            },
            "contactInfo": {
              "email": "ted.davis@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/637",
                "https://www.ncleg.gov/Members/Votes/H/637",
                "https://www.ncleg.gov/Members/Committees/H/637",
                "https://www.ncleg.gov/Members/Biography/H/637"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-20-lower-ocd-person-b654a887-2eca-41f7-83ba-60044df0fc1c": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/d48b59a1-cf27-438e-8a25-2f8f179992f2",
            "firstName": "Ya",
            "lastName": "Liu",
            "fullName": "Ya Liu",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/806/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-NC-21-lower-ocd-person-d48b59a1-cf27-438e-8a25-2f8f179992f2",
              "builtID": "nc-lower-21",
              "externalID": "ocd-person/d48b59a1-cf27-438e-8a25-2f8f179992f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "ya.liu@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/806",
                "https://www.ncleg.gov/Members/Votes/H/806",
                "https://www.ncleg.gov/Members/Committees/H/806",
                "https://www.ncleg.gov/Members/Biography/H/806"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-21-lower-ocd-person-d48b59a1-cf27-438e-8a25-2f8f179992f2": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/5eadd1cd-7d46-448d-9608-2901b596b24f",
            "firstName": "William Dale",
            "lastName": "Brisson",
            "fullName": "William Brisson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/558/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-NC-22-lower-ocd-person-5eadd1cd-7d46-448d-9608-2901b596b24f",
              "builtID": "nc-lower-22",
              "externalID": "ocd-person/5eadd1cd-7d46-448d-9608-2901b596b24f",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.brisson@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/558",
                "https://www.ncleg.gov/Members/Votes/H/558",
                "https://www.ncleg.gov/Members/Committees/H/558",
                "https://www.ncleg.gov/Members/Biography/H/558"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-22-lower-ocd-person-5eadd1cd-7d46-448d-9608-2901b596b24f": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/f0d9b7f4-abb7-4111-b640-766a1f81d653",
            "firstName": "Shelly",
            "lastName": "Willingham",
            "fullName": "Shelly Willingham",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/700/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-NC-23-lower-ocd-person-f0d9b7f4-abb7-4111-b640-766a1f81d653",
              "builtID": "nc-lower-23",
              "externalID": "ocd-person/f0d9b7f4-abb7-4111-b640-766a1f81d653",
              "geometry": null
            },
            "contactInfo": {
              "email": "shelly.willingham@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/700",
                "https://www.ncleg.gov/Members/Votes/H/700",
                "https://www.ncleg.gov/Members/Committees/H/700",
                "https://www.ncleg.gov/Members/Biography/H/700"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-23-lower-ocd-person-f0d9b7f4-abb7-4111-b640-766a1f81d653": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/ed1f7e11-da21-4d1a-aba4-52efd5a1422d",
            "firstName": "Kenneth James",
            "lastName": "Fontenot",
            "fullName": "Ken Fontenot",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/802/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-NC-24-lower-ocd-person-ed1f7e11-da21-4d1a-aba4-52efd5a1422d",
              "builtID": "nc-lower-24",
              "externalID": "ocd-person/ed1f7e11-da21-4d1a-aba4-52efd5a1422d",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.fontenot@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/802",
                "https://www.ncleg.gov/Members/Votes/H/802",
                "https://www.ncleg.gov/Members/Committees/H/802",
                "https://www.ncleg.gov/Members/Biography/H/802"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-24-lower-ocd-person-ed1f7e11-da21-4d1a-aba4-52efd5a1422d": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/fc3772c1-d98a-4325-a6c8-96b9da492ed6",
            "firstName": "Gregory Allen",
            "lastName": "Chesser",
            "fullName": "Allen Chesser",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/799/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-NC-25-lower-ocd-person-fc3772c1-d98a-4325-a6c8-96b9da492ed6",
              "builtID": "nc-lower-25",
              "externalID": "ocd-person/fc3772c1-d98a-4325-a6c8-96b9da492ed6",
              "geometry": null
            },
            "contactInfo": {
              "email": "allen.chesser@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/799",
                "https://www.ncleg.gov/Members/Votes/H/799",
                "https://www.ncleg.gov/Members/Committees/H/799",
                "https://www.ncleg.gov/Members/Biography/H/799"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-25-lower-ocd-person-fc3772c1-d98a-4325-a6c8-96b9da492ed6": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/7e1cb220-ff5e-4543-bc51-f44ba7ee0a2b",
            "firstName": "Donna McDowell",
            "lastName": "White",
            "fullName": "Donna White",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/728/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-NC-26-lower-ocd-person-7e1cb220-ff5e-4543-bc51-f44ba7ee0a2b",
              "builtID": "nc-lower-26",
              "externalID": "ocd-person/7e1cb220-ff5e-4543-bc51-f44ba7ee0a2b",
              "geometry": null
            },
            "contactInfo": {
              "email": "donna.white@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/728",
                "https://www.ncleg.gov/Members/Votes/H/728",
                "https://www.ncleg.gov/Members/Committees/H/728",
                "https://www.ncleg.gov/Members/Biography/H/728"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-26-lower-ocd-person-7e1cb220-ff5e-4543-bc51-f44ba7ee0a2b": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/9035ea5f-bff1-4ead-ad91-e9f9a7864205",
            "firstName": "Michael H.",
            "lastName": "Wray",
            "fullName": "Michael Wray",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/481/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-NC-27-lower-ocd-person-9035ea5f-bff1-4ead-ad91-e9f9a7864205",
              "builtID": "nc-lower-27",
              "externalID": "ocd-person/9035ea5f-bff1-4ead-ad91-e9f9a7864205",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.wray@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/481",
                "https://www.ncleg.gov/Members/Votes/H/481",
                "https://www.ncleg.gov/Members/Committees/H/481",
                "https://www.ncleg.gov/Members/Biography/H/481"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-27-lower-ocd-person-9035ea5f-bff1-4ead-ad91-e9f9a7864205": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/3ed64eae-ecec-4cbf-a436-e7c1471a4cef",
            "firstName": "Larry Craig",
            "lastName": "Strickland",
            "fullName": "Larry Strickland",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/727/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-NC-28-lower-ocd-person-3ed64eae-ecec-4cbf-a436-e7c1471a4cef",
              "builtID": "nc-lower-28",
              "externalID": "ocd-person/3ed64eae-ecec-4cbf-a436-e7c1471a4cef",
              "geometry": null
            },
            "contactInfo": {
              "email": "larry.strickland@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/727",
                "https://www.ncleg.gov/Members/Votes/H/727",
                "https://www.ncleg.gov/Members/Committees/H/727",
                "https://www.ncleg.gov/Members/Biography/H/727"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-28-lower-ocd-person-3ed64eae-ecec-4cbf-a436-e7c1471a4cef": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/baca6c05-f6c5-4239-bf9b-c4f57001273d",
            "firstName": "Vernetta R.",
            "lastName": "Alston",
            "fullName": "Vernetta Alston",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/765/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-NC-29-lower-ocd-person-baca6c05-f6c5-4239-bf9b-c4f57001273d",
              "builtID": "nc-lower-29",
              "externalID": "ocd-person/baca6c05-f6c5-4239-bf9b-c4f57001273d",
              "geometry": null
            },
            "contactInfo": {
              "email": "vernetta.alston@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/765",
                "https://www.ncleg.gov/Members/Votes/H/765",
                "https://www.ncleg.gov/Members/Committees/H/765",
                "https://www.ncleg.gov/Members/Biography/H/765"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-29-lower-ocd-person-baca6c05-f6c5-4239-bf9b-c4f57001273d": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/be5acee4-3874-4414-9aff-77043943c1c1",
            "firstName": "Marcia Helen",
            "lastName": "Morey",
            "fullName": "Marcia Morey",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/732/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-NC-30-lower-ocd-person-be5acee4-3874-4414-9aff-77043943c1c1",
              "builtID": "nc-lower-30",
              "externalID": "ocd-person/be5acee4-3874-4414-9aff-77043943c1c1",
              "geometry": null
            },
            "contactInfo": {
              "email": "marcia.morey@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/732",
                "https://www.ncleg.gov/Members/Votes/H/732",
                "https://www.ncleg.gov/Members/Committees/H/732",
                "https://www.ncleg.gov/Members/Biography/H/732"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-30-lower-ocd-person-be5acee4-3874-4414-9aff-77043943c1c1": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/6dea5a89-20c8-4370-94c2-0dac7fe29d77",
            "firstName": "Zack Anthony",
            "lastName": "Forde-Hawkins",
            "fullName": "Zack Hawkins",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/742/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-NC-31-lower-ocd-person-6dea5a89-20c8-4370-94c2-0dac7fe29d77",
              "builtID": "nc-lower-31",
              "externalID": "ocd-person/6dea5a89-20c8-4370-94c2-0dac7fe29d77",
              "geometry": null
            },
            "contactInfo": {
              "email": "zack.hawkins@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/742",
                "https://www.ncleg.gov/Members/Votes/H/742",
                "https://www.ncleg.gov/Members/Committees/H/742",
                "https://www.ncleg.gov/Members/Biography/H/742"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-31-lower-ocd-person-6dea5a89-20c8-4370-94c2-0dac7fe29d77": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/186fd7c1-26ec-44ab-9c1f-a05840eff477",
            "firstName": "Frank",
            "lastName": "Sossamon",
            "fullName": "Frank Sossamon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/814/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-NC-32-lower-ocd-person-186fd7c1-26ec-44ab-9c1f-a05840eff477",
              "builtID": "nc-lower-32",
              "externalID": "ocd-person/186fd7c1-26ec-44ab-9c1f-a05840eff477",
              "geometry": null
            },
            "contactInfo": {
              "email": "frank.sossamon@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/814",
                "https://www.ncleg.gov/Members/Votes/H/814",
                "https://www.ncleg.gov/Members/Committees/H/814",
                "https://www.ncleg.gov/Members/Biography/H/814"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-32-lower-ocd-person-186fd7c1-26ec-44ab-9c1f-a05840eff477": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/0c334cab-c747-4589-b525-ff9b54adce2e",
            "firstName": "Rosa Underwood",
            "lastName": "Gill",
            "fullName": "Rosa Gill",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/597/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-NC-33-lower-ocd-person-0c334cab-c747-4589-b525-ff9b54adce2e",
              "builtID": "nc-lower-33",
              "externalID": "ocd-person/0c334cab-c747-4589-b525-ff9b54adce2e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rosa.gill@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/597",
                "https://www.ncleg.gov/Members/Votes/H/597",
                "https://www.ncleg.gov/Members/Committees/H/597",
                "https://www.ncleg.gov/Members/Biography/H/597"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-33-lower-ocd-person-0c334cab-c747-4589-b525-ff9b54adce2e": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/1bc1818f-49fd-478a-affa-1f167adcfb51",
            "firstName": "Tim",
            "lastName": "Longest",
            "fullName": "Tim Longest",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/818/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-NC-34-lower-ocd-person-1bc1818f-49fd-478a-affa-1f167adcfb51",
              "builtID": "nc-lower-34",
              "externalID": "ocd-person/1bc1818f-49fd-478a-affa-1f167adcfb51",
              "geometry": null
            },
            "contactInfo": {
              "email": "tim.longest@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/818",
                "https://www.ncleg.gov/Members/Votes/H/818",
                "https://www.ncleg.gov/Members/Committees/H/818",
                "https://www.ncleg.gov/Members/Biography/H/818"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-34-lower-ocd-person-1bc1818f-49fd-478a-affa-1f167adcfb51": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/1217dc52-8590-4c6b-832b-666b57ef121c",
            "firstName": "Terence Jason",
            "lastName": "Everitt",
            "fullName": "Terence Everitt",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/741/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-NC-35-lower-ocd-person-1217dc52-8590-4c6b-832b-666b57ef121c",
              "builtID": "nc-lower-35",
              "externalID": "ocd-person/1217dc52-8590-4c6b-832b-666b57ef121c",
              "geometry": null
            },
            "contactInfo": {
              "email": "terence.everitt@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/741",
                "https://www.ncleg.gov/Members/Votes/H/741",
                "https://www.ncleg.gov/Members/Committees/H/741",
                "https://www.ncleg.gov/Members/Biography/H/741"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-35-lower-ocd-person-1217dc52-8590-4c6b-832b-666b57ef121c": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/7b4481a0-466b-44be-8b39-def9e1bc04f8",
            "firstName": "Julie Marie Olson",
            "lastName": "von Haefen",
            "fullName": "Julie von Haefen",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/758/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-NC-36-lower-ocd-person-7b4481a0-466b-44be-8b39-def9e1bc04f8",
              "builtID": "nc-lower-36",
              "externalID": "ocd-person/7b4481a0-466b-44be-8b39-def9e1bc04f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "julie.vonhaefen@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/758",
                "https://www.ncleg.gov/Members/Votes/H/758",
                "https://www.ncleg.gov/Members/Committees/H/758",
                "https://www.ncleg.gov/Members/Biography/H/758"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-36-lower-ocd-person-7b4481a0-466b-44be-8b39-def9e1bc04f8": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/ea801bb0-b3bd-4dbc-9164-25e7f4e63f84",
            "firstName": "Erin Pauling",
            "lastName": "Pare",
            "fullName": "Erin Paré",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/770/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-NC-37-lower-ocd-person-ea801bb0-b3bd-4dbc-9164-25e7f4e63f84",
              "builtID": "nc-lower-37",
              "externalID": "ocd-person/ea801bb0-b3bd-4dbc-9164-25e7f4e63f84",
              "geometry": null
            },
            "contactInfo": {
              "email": "erin.pare@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/770",
                "https://www.ncleg.gov/Members/Votes/H/770",
                "https://www.ncleg.gov/Members/Committees/H/770",
                "https://reperinpare.com/home",
                "https://www.ncleg.gov/Members/Biography/H/770"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-37-lower-ocd-person-ea801bb0-b3bd-4dbc-9164-25e7f4e63f84": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/559521af-e5f9-43c3-a75e-de9b242d364f",
            "firstName": "Abraham P.",
            "lastName": "Jones",
            "fullName": "Abe Jones",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/780/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-NC-38-lower-ocd-person-559521af-e5f9-43c3-a75e-de9b242d364f",
              "builtID": "nc-lower-38",
              "externalID": "ocd-person/559521af-e5f9-43c3-a75e-de9b242d364f",
              "geometry": null
            },
            "contactInfo": {
              "email": "abe.jones@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repabejones",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/780",
                "https://www.ncleg.gov/Members/Votes/H/780",
                "https://www.ncleg.gov/Members/Committees/H/780",
                "https://www.ncleg.gov/Members/Biography/H/780"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-38-lower-ocd-person-559521af-e5f9-43c3-a75e-de9b242d364f": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/4547831d-14b9-4d9d-9e3c-15b66c389054",
            "firstName": "James",
            "lastName": "Roberson",
            "fullName": "James Roberson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/786/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-NC-39-lower-ocd-person-4547831d-14b9-4d9d-9e3c-15b66c389054",
              "builtID": "nc-lower-39",
              "externalID": "ocd-person/4547831d-14b9-4d9d-9e3c-15b66c389054",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.roberson@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/786",
                "https://www.ncleg.gov/Members/Votes/H/786",
                "https://www.ncleg.gov/Members/Committees/H/786",
                "https://www.ncleg.gov/Members/Biography/H/786"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-39-lower-ocd-person-4547831d-14b9-4d9d-9e3c-15b66c389054": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/d681e650-a61c-4c78-9117-e98f7525eb6c",
            "firstName": "Joseph R.",
            "lastName": "John",
            "fullName": "Joe John",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/722/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-NC-40-lower-ocd-person-d681e650-a61c-4c78-9117-e98f7525eb6c",
              "builtID": "nc-lower-40",
              "externalID": "ocd-person/d681e650-a61c-4c78-9117-e98f7525eb6c",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.john@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/722",
                "https://www.ncleg.gov/Members/Votes/H/722",
                "https://www.ncleg.gov/Members/Committees/H/722",
                "https://www.ncleg.gov/Members/Biography/H/722"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-40-lower-ocd-person-d681e650-a61c-4c78-9117-e98f7525eb6c": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/7a50ad08-1f2a-4f5f-9463-b1672d3a60a3",
            "firstName": "Maria Louisa Saulog",
            "lastName": "Cervania",
            "fullName": "Maria Cervania",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/798/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-NC-41-lower-ocd-person-7a50ad08-1f2a-4f5f-9463-b1672d3a60a3",
              "builtID": "nc-lower-41",
              "externalID": "ocd-person/7a50ad08-1f2a-4f5f-9463-b1672d3a60a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "maria.cervania@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/798",
                "https://www.ncleg.gov/Members/Votes/H/798",
                "https://www.ncleg.gov/Members/Committees/H/798",
                "https://www.ncleg.gov/Members/Biography/H/798"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-41-lower-ocd-person-7a50ad08-1f2a-4f5f-9463-b1672d3a60a3": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/eb4a0388-67bd-4e8d-85ca-288b4d84ab43",
            "firstName": "Marvin Willis",
            "lastName": "Lucas",
            "fullName": "Marvin Lucas",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/216/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-NC-42-lower-ocd-person-eb4a0388-67bd-4e8d-85ca-288b4d84ab43",
              "builtID": "nc-lower-42",
              "externalID": "ocd-person/eb4a0388-67bd-4e8d-85ca-288b4d84ab43",
              "geometry": null
            },
            "contactInfo": {
              "email": "marvin.lucas@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/216",
                "https://www.ncleg.gov/Members/Votes/H/216",
                "https://www.ncleg.gov/Members/Committees/H/216",
                "https://www.ncleg.gov/Members/Biography/H/216"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-42-lower-ocd-person-eb4a0388-67bd-4e8d-85ca-288b4d84ab43": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/034ed56d-8058-4489-be3e-3d4b9977ac4b",
            "firstName": "Diane",
            "lastName": "Wheatley",
            "fullName": "Diane Wheatley",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/785/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-NC-43-lower-ocd-person-034ed56d-8058-4489-be3e-3d4b9977ac4b",
              "builtID": "nc-lower-43",
              "externalID": "ocd-person/034ed56d-8058-4489-be3e-3d4b9977ac4b",
              "geometry": null
            },
            "contactInfo": {
              "email": "diane.wheatley@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/785",
                "https://www.ncleg.gov/Members/Votes/H/785",
                "https://www.ncleg.gov/Members/Committees/H/785",
                "https://www.ncleg.gov/Members/Biography/H/785"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-43-lower-ocd-person-034ed56d-8058-4489-be3e-3d4b9977ac4b": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/2736ade6-4cc1-4833-ab0d-cf1953227726",
            "firstName": "Charles R.",
            "lastName": "Smith",
            "fullName": "Charles Smith",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/813/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-NC-44-lower-ocd-person-2736ade6-4cc1-4833-ab0d-cf1953227726",
              "builtID": "nc-lower-44",
              "externalID": "ocd-person/2736ade6-4cc1-4833-ab0d-cf1953227726",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.smith@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/813",
                "https://www.ncleg.gov/Members/Votes/H/813",
                "https://www.ncleg.gov/Members/Committees/H/813",
                "https://www.ncleg.gov/Members/Biography/H/813"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-44-lower-ocd-person-2736ade6-4cc1-4833-ab0d-cf1953227726": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/1a32e77b-9880-467c-88c8-6aeb738f6525",
            "firstName": "Frances Vinell",
            "lastName": "Jackson",
            "fullName": "Frances Jackson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/803/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-NC-45-lower-ocd-person-1a32e77b-9880-467c-88c8-6aeb738f6525",
              "builtID": "nc-lower-45",
              "externalID": "ocd-person/1a32e77b-9880-467c-88c8-6aeb738f6525",
              "geometry": null
            },
            "contactInfo": {
              "email": "frances.jackson@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/803",
                "https://www.ncleg.gov/Members/Votes/H/803",
                "https://www.ncleg.gov/Members/Committees/H/803",
                "https://www.ncleg.gov/Members/Biography/H/803"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-45-lower-ocd-person-1a32e77b-9880-467c-88c8-6aeb738f6525": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/1e419504-9efe-4b1f-b1e0-6e8d7d8c74a9",
            "firstName": "Brenden H.",
            "lastName": "Jones",
            "fullName": "Brenden Jones",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/723/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-NC-46-lower-ocd-person-1e419504-9efe-4b1f-b1e0-6e8d7d8c74a9",
              "builtID": "nc-lower-46",
              "externalID": "ocd-person/1e419504-9efe-4b1f-b1e0-6e8d7d8c74a9",
              "geometry": null
            },
            "contactInfo": {
              "email": "brenden.jones@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/723",
                "https://www.ncleg.gov/Members/Votes/H/723",
                "https://www.ncleg.gov/Members/Committees/H/723",
                "https://www.ncleg.gov/Members/Biography/H/723"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-46-lower-ocd-person-1e419504-9efe-4b1f-b1e0-6e8d7d8c74a9": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/f4173ea9-3104-444d-9af7-73e36e64c421",
            "firstName": "Jarrod Marshall",
            "lastName": "Lowery",
            "fullName": "Jarrod Lowery",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/807/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-NC-47-lower-ocd-person-f4173ea9-3104-444d-9af7-73e36e64c421",
              "builtID": "nc-lower-47",
              "externalID": "ocd-person/f4173ea9-3104-444d-9af7-73e36e64c421",
              "geometry": null
            },
            "contactInfo": {
              "email": "jarrod.lowery@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/807",
                "https://www.ncleg.gov/Members/Votes/H/807",
                "https://www.ncleg.gov/Members/Committees/H/807",
                "https://www.ncleg.gov/Members/Biography/H/807"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-47-lower-ocd-person-f4173ea9-3104-444d-9af7-73e36e64c421": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/32edee21-40a5-491f-b28e-316ba7262360",
            "firstName": "Garland E.",
            "lastName": "Pierce",
            "fullName": "Garland Pierce",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/497/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-NC-48-lower-ocd-person-32edee21-40a5-491f-b28e-316ba7262360",
              "builtID": "nc-lower-48",
              "externalID": "ocd-person/32edee21-40a5-491f-b28e-316ba7262360",
              "geometry": null
            },
            "contactInfo": {
              "email": "garland.pierce@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/497",
                "https://www.ncleg.gov/Members/Votes/H/497",
                "https://www.ncleg.gov/Members/Committees/H/497",
                "https://www.ncleg.gov/Members/Biography/H/497"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-48-lower-ocd-person-32edee21-40a5-491f-b28e-316ba7262360": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/a5d3d142-3601-429e-954c-fdb98b0e628a",
            "firstName": "Cynthia",
            "lastName": "Ball",
            "fullName": "Cynthia Ball",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/711/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-NC-49-lower-ocd-person-a5d3d142-3601-429e-954c-fdb98b0e628a",
              "builtID": "nc-lower-49",
              "externalID": "ocd-person/a5d3d142-3601-429e-954c-fdb98b0e628a",
              "geometry": null
            },
            "contactInfo": {
              "email": "cynthia.ball@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/711",
                "https://www.ncleg.gov/Members/Votes/H/711",
                "https://www.ncleg.gov/Members/Committees/H/711",
                "https://www.ncleg.gov/Members/Biography/H/711"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-49-lower-ocd-person-a5d3d142-3601-429e-954c-fdb98b0e628a": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/7ee23b19-064e-4531-a9e8-2e928f8f2203",
            "firstName": "Renee A.",
            "lastName": "Price",
            "fullName": "Renée Price",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/810/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-NC-50-lower-ocd-person-7ee23b19-064e-4531-a9e8-2e928f8f2203",
              "builtID": "nc-lower-50",
              "externalID": "ocd-person/7ee23b19-064e-4531-a9e8-2e928f8f2203",
              "geometry": null
            },
            "contactInfo": {
              "email": "renee.price@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/810",
                "https://www.ncleg.gov/Members/Votes/H/810",
                "https://www.ncleg.gov/Members/Committees/H/810",
                "https://www.ncleg.gov/Members/Biography/H/810"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-50-lower-ocd-person-7ee23b19-064e-4531-a9e8-2e928f8f2203": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/bde014bb-27c1-42a3-909f-c5d96cca5c3f",
            "firstName": "John Irwin",
            "lastName": "Sauls",
            "fullName": "John Sauls",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/393/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-NC-51-lower-ocd-person-bde014bb-27c1-42a3-909f-c5d96cca5c3f",
              "builtID": "nc-lower-51",
              "externalID": "ocd-person/bde014bb-27c1-42a3-909f-c5d96cca5c3f",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.sauls@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/393",
                "https://www.ncleg.gov/Members/Votes/H/393",
                "https://www.ncleg.gov/Members/Committees/H/393",
                "https://www.ncleg.gov/Members/Biography/H/393"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-51-lower-ocd-person-bde014bb-27c1-42a3-909f-c5d96cca5c3f": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/fdc7d25a-085c-4100-b5f4-a8ae51979292",
            "firstName": "Ben Thomas",
            "lastName": "Moss",
            "fullName": "Ben Moss",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/784/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-NC-52-lower-ocd-person-fdc7d25a-085c-4100-b5f4-a8ae51979292",
              "builtID": "nc-lower-52",
              "externalID": "ocd-person/fdc7d25a-085c-4100-b5f4-a8ae51979292",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.moss@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/784",
                "https://www.ncleg.gov/Members/Votes/H/784",
                "https://www.ncleg.gov/Members/Committees/H/784",
                "https://www.ncleg.gov/Members/Biography/H/784"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-52-lower-ocd-person-fdc7d25a-085c-4100-b5f4-a8ae51979292": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/9b6a3a48-9827-421f-9925-4717340110ab",
            "firstName": "Howard",
            "lastName": "Penny",
            "fullName": "Howard Penny",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/768/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-NC-53-lower-ocd-person-9b6a3a48-9827-421f-9925-4717340110ab",
              "builtID": "nc-lower-53",
              "externalID": "ocd-person/9b6a3a48-9827-421f-9925-4717340110ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "howard.penny@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/768",
                "https://www.ncleg.gov/Members/Votes/H/768",
                "https://www.ncleg.gov/Members/Committees/H/768",
                "https://www.ncleg.gov/Members/Biography/H/768"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-53-lower-ocd-person-9b6a3a48-9827-421f-9925-4717340110ab": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/3aa6f3c5-cd15-42e1-907e-92e6f5362c8f",
            "firstName": "Robert Tyrone",
            "lastName": "Reives",
            "fullName": "Robert Reives",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/684/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-NC-54-lower-ocd-person-3aa6f3c5-cd15-42e1-907e-92e6f5362c8f",
              "builtID": "nc-lower-54",
              "externalID": "ocd-person/3aa6f3c5-cd15-42e1-907e-92e6f5362c8f",
              "geometry": null
            },
            "contactInfo": {
              "email": "robert.reives@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/684",
                "https://www.ncleg.gov/Members/Votes/H/684",
                "https://www.ncleg.gov/Members/Committees/H/684",
                "https://www.ncleg.gov/Members/Biography/H/684"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-54-lower-ocd-person-3aa6f3c5-cd15-42e1-907e-92e6f5362c8f": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/cc238a7c-3222-4ac2-8d4d-3884dd342a5f",
            "firstName": "Mark Allen",
            "lastName": "Brody",
            "fullName": "Mark Brody",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/663/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-NC-55-lower-ocd-person-cc238a7c-3222-4ac2-8d4d-3884dd342a5f",
              "builtID": "nc-lower-55",
              "externalID": "ocd-person/cc238a7c-3222-4ac2-8d4d-3884dd342a5f",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.brody@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/663",
                "https://www.ncleg.gov/Members/Votes/H/663",
                "https://www.ncleg.gov/Members/Committees/H/663",
                "https://www.ncleg.gov/Members/Biography/H/663"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-55-lower-ocd-person-cc238a7c-3222-4ac2-8d4d-3884dd342a5f": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/95a533af-7933-4992-aea6-dfdc626b7ca6",
            "firstName": "Allen",
            "lastName": "Buansi",
            "fullName": "Allen Buansi",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/790/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-NC-56-lower-ocd-person-95a533af-7933-4992-aea6-dfdc626b7ca6",
              "builtID": "nc-lower-56",
              "externalID": "ocd-person/95a533af-7933-4992-aea6-dfdc626b7ca6",
              "geometry": null
            },
            "contactInfo": {
              "email": "allen.buansi@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/790",
                "https://www.ncleg.gov/Members/Votes/H/790",
                "https://www.ncleg.gov/Members/Committees/H/790",
                "https://www.ncleg.gov/Members/Biography/H/790"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-56-lower-ocd-person-95a533af-7933-4992-aea6-dfdc626b7ca6": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/0dea5927-d667-4202-9deb-336c83fc382c",
            "firstName": "Ashton Wheeler",
            "lastName": "Clemmons",
            "fullName": "Ashton Clemmons",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/739/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-NC-57-lower-ocd-person-0dea5927-d667-4202-9deb-336c83fc382c",
              "builtID": "nc-lower-57",
              "externalID": "ocd-person/0dea5927-d667-4202-9deb-336c83fc382c",
              "geometry": null
            },
            "contactInfo": {
              "email": "ashton.clemmons@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/739",
                "https://www.ncleg.gov/Members/Votes/H/739",
                "https://www.ncleg.gov/Members/Committees/H/739",
                "https://www.ncleg.gov/Members/Biography/H/739"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-57-lower-ocd-person-0dea5927-d667-4202-9deb-336c83fc382c": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/46275779-8191-48be-8b0b-8373376fa23d",
            "firstName": "Amos Lewis",
            "lastName": "Quick",
            "fullName": "Amos Quick",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/725/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-NC-58-lower-ocd-person-46275779-8191-48be-8b0b-8373376fa23d",
              "builtID": "nc-lower-58",
              "externalID": "ocd-person/46275779-8191-48be-8b0b-8373376fa23d",
              "geometry": null
            },
            "contactInfo": {
              "email": "amos.quick@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/725",
                "https://www.ncleg.gov/Members/Votes/H/725",
                "https://www.ncleg.gov/Members/Committees/H/725",
                "https://www.ncleg.gov/Members/Biography/H/725"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-58-lower-ocd-person-46275779-8191-48be-8b0b-8373376fa23d": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/6ca8b8ee-96ed-4b21-94cf-d67e49f9c1c8",
            "firstName": "Jerry Alan",
            "lastName": "Branson",
            "fullName": "Alan Branson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/alan_branson.jpg",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-NC-59-lower-ocd-person-6ca8b8ee-96ed-4b21-94cf-d67e49f9c1c8",
              "builtID": "nc-lower-59",
              "externalID": "ocd-person/6ca8b8ee-96ed-4b21-94cf-d67e49f9c1c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "alan.branson@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ncleg.gov/Members/Biography/H/821"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-59-lower-ocd-person-6ca8b8ee-96ed-4b21-94cf-d67e49f9c1c8": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/d898f4cb-965b-4cfa-8215-839a00ab0560",
            "firstName": "Cecil Antonio",
            "lastName": "Brockman",
            "fullName": "Cecil Brockman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/691/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-NC-60-lower-ocd-person-d898f4cb-965b-4cfa-8215-839a00ab0560",
              "builtID": "nc-lower-60",
              "externalID": "ocd-person/d898f4cb-965b-4cfa-8215-839a00ab0560",
              "geometry": null
            },
            "contactInfo": {
              "email": "cecil.brockman@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/691",
                "https://www.ncleg.gov/Members/Votes/H/691",
                "https://www.ncleg.gov/Members/Committees/H/691",
                "https://www.ncleg.gov/Members/Biography/H/691"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-60-lower-ocd-person-d898f4cb-965b-4cfa-8215-839a00ab0560": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/4c2e2654-2647-4394-8efe-f09532225ff6",
            "firstName": "Mary Price Taylor",
            "lastName": "Harrison",
            "fullName": "Pricey Harrison",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/504/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-NC-61-lower-ocd-person-4c2e2654-2647-4394-8efe-f09532225ff6",
              "builtID": "nc-lower-61",
              "externalID": "ocd-person/4c2e2654-2647-4394-8efe-f09532225ff6",
              "geometry": null
            },
            "contactInfo": {
              "email": "pricey.harrison@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/504",
                "https://www.ncleg.gov/Members/Votes/H/504",
                "https://www.ncleg.gov/Members/Committees/H/504",
                "https://www.ncleg.gov/Members/Biography/H/504"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-61-lower-ocd-person-4c2e2654-2647-4394-8efe-f09532225ff6": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/308cf6f1-2503-4b15-b83c-daa7e25bc9f1",
            "firstName": "Joseph Aubrey",
            "lastName": "Faircloth",
            "fullName": "John Faircloth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/603/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-NC-62-lower-ocd-person-308cf6f1-2503-4b15-b83c-daa7e25bc9f1",
              "builtID": "nc-lower-62",
              "externalID": "ocd-person/308cf6f1-2503-4b15-b83c-daa7e25bc9f1",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.faircloth@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/603",
                "https://www.ncleg.gov/Members/Votes/H/603",
                "https://www.ncleg.gov/Members/Committees/H/603",
                "https://www.ncleg.gov/Members/Biography/H/603"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-62-lower-ocd-person-308cf6f1-2503-4b15-b83c-daa7e25bc9f1": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/6b6dc936-a880-4bdb-9fba-11e0a2ff9f01",
            "firstName": "Stephen Miles",
            "lastName": "Ross",
            "fullName": "Stephen Ross",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/812/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-NC-63-lower-ocd-person-6b6dc936-a880-4bdb-9fba-11e0a2ff9f01",
              "builtID": "nc-lower-63",
              "externalID": "ocd-person/6b6dc936-a880-4bdb-9fba-11e0a2ff9f01",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephen.ross@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/812",
                "https://www.ncleg.gov/Members/Votes/H/812",
                "https://www.ncleg.gov/Members/Committees/H/812",
                "https://www.ncleg.gov/Members/Biography/H/812"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-63-lower-ocd-person-6b6dc936-a880-4bdb-9fba-11e0a2ff9f01": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/fcff21e8-b2a0-4fe5-8fb9-1b1c084a26e1",
            "firstName": "Dennis Patrick",
            "lastName": "Riddell",
            "fullName": "Dennis Riddell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/665/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-NC-64-lower-ocd-person-fcff21e8-b2a0-4fe5-8fb9-1b1c084a26e1",
              "builtID": "nc-lower-64",
              "externalID": "ocd-person/fcff21e8-b2a0-4fe5-8fb9-1b1c084a26e1",
              "geometry": null
            },
            "contactInfo": {
              "email": "dennis.riddell@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/665",
                "https://www.ncleg.gov/Members/Votes/H/665",
                "https://www.ncleg.gov/Members/Committees/H/665",
                "https://www.ncleg.gov/Members/Biography/H/665"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-64-lower-ocd-person-fcff21e8-b2a0-4fe5-8fb9-1b1c084a26e1": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/837ec59b-a7db-41dc-9e9f-a7a83b462213",
            "firstName": "A. Reece",
            "lastName": "Pyrtle",
            "fullName": "Reece Pyrtle",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/787/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-NC-65-lower-ocd-person-837ec59b-a7db-41dc-9e9f-a7a83b462213",
              "builtID": "nc-lower-65",
              "externalID": "ocd-person/837ec59b-a7db-41dc-9e9f-a7a83b462213",
              "geometry": null
            },
            "contactInfo": {
              "email": "reece.pyrtle@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/787",
                "https://www.ncleg.gov/Members/Votes/H/787",
                "https://www.ncleg.gov/Members/Committees/H/787",
                "https://www.ncleg.gov/Members/Biography/H/787"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-65-lower-ocd-person-837ec59b-a7db-41dc-9e9f-a7a83b462213": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/4eb017f8-318e-43ba-b0c5-9fecbc3d0530",
            "firstName": "Sarah",
            "lastName": "Crawford",
            "fullName": "Sarah Crawford",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/800/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-NC-66-lower-ocd-person-4eb017f8-318e-43ba-b0c5-9fecbc3d0530",
              "builtID": "nc-lower-66",
              "externalID": "ocd-person/4eb017f8-318e-43ba-b0c5-9fecbc3d0530",
              "geometry": null
            },
            "contactInfo": {
              "email": "sarah.crawford@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/800",
                "https://www.ncleg.gov/Members/Votes/H/800",
                "https://www.ncleg.gov/Members/Committees/H/800",
                "https://www.ncleg.gov/Members/Biography/H/800"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-66-lower-ocd-person-4eb017f8-318e-43ba-b0c5-9fecbc3d0530": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/a0fba91a-59a9-481a-a0b1-91b3fac0acd0",
            "firstName": "Clayton",
            "lastName": "Sasser",
            "fullName": "Wayne Sasser",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/754/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-NC-67-lower-ocd-person-a0fba91a-59a9-481a-a0b1-91b3fac0acd0",
              "builtID": "nc-lower-67",
              "externalID": "ocd-person/a0fba91a-59a9-481a-a0b1-91b3fac0acd0",
              "geometry": null
            },
            "contactInfo": {
              "email": "wayne.sasser@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/754",
                "https://www.ncleg.gov/Members/Votes/H/754",
                "https://www.ncleg.gov/Members/Committees/H/754",
                "https://www.ncleg.gov/Members/Biography/H/754"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-67-lower-ocd-person-a0fba91a-59a9-481a-a0b1-91b3fac0acd0": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/6b06a564-763d-421d-bc9b-4779f4dbc1a4",
            "firstName": "David",
            "lastName": "Willis",
            "fullName": "David Willis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/779/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-NC-68-lower-ocd-person-6b06a564-763d-421d-bc9b-4779f4dbc1a4",
              "builtID": "nc-lower-68",
              "externalID": "ocd-person/6b06a564-763d-421d-bc9b-4779f4dbc1a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.willis@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/779",
                "https://www.ncleg.gov/Members/Votes/H/779",
                "https://www.ncleg.gov/Members/Committees/H/779",
                "https://www.ncleg.gov/Members/Biography/H/779"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-68-lower-ocd-person-6b06a564-763d-421d-bc9b-4779f4dbc1a4": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/ffdbb3fa-0445-460a-9501-18b5028fbe50",
            "firstName": "Larry Dean",
            "lastName": "Arp",
            "fullName": "Dean Arp",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/640/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-NC-69-lower-ocd-person-ffdbb3fa-0445-460a-9501-18b5028fbe50",
              "builtID": "nc-lower-69",
              "externalID": "ocd-person/ffdbb3fa-0445-460a-9501-18b5028fbe50",
              "geometry": null
            },
            "contactInfo": {
              "email": "dean.arp@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/640",
                "https://www.ncleg.gov/Members/Votes/H/640",
                "https://www.ncleg.gov/Members/Committees/H/640",
                "https://www.ncleg.gov/Members/Biography/H/640"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-69-lower-ocd-person-ffdbb3fa-0445-460a-9501-18b5028fbe50": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/c476ab83-c1f7-44a7-b7c9-4cfa4679bed8",
            "firstName": "Brian Heather",
            "lastName": "Biggs",
            "fullName": "Brian Biggs",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/793/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-NC-70-lower-ocd-person-c476ab83-c1f7-44a7-b7c9-4cfa4679bed8",
              "builtID": "nc-lower-70",
              "externalID": "ocd-person/c476ab83-c1f7-44a7-b7c9-4cfa4679bed8",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.biggs@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/793",
                "https://www.ncleg.gov/Members/Votes/H/793",
                "https://www.ncleg.gov/Members/Committees/H/793",
                "https://www.ncleg.gov/Members/Biography/H/793"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-70-lower-ocd-person-c476ab83-c1f7-44a7-b7c9-4cfa4679bed8": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/91f28923-c52e-4a47-aed2-944672902823",
            "firstName": "Kanika",
            "lastName": "Brown",
            "fullName": "Kanika Brown",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/795/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-NC-71-lower-ocd-person-91f28923-c52e-4a47-aed2-944672902823",
              "builtID": "nc-lower-71",
              "externalID": "ocd-person/91f28923-c52e-4a47-aed2-944672902823",
              "geometry": null
            },
            "contactInfo": {
              "email": "kanika.brown@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/795",
                "https://www.ncleg.gov/Members/Votes/H/795",
                "https://www.ncleg.gov/Members/Committees/H/795",
                "https://www.ncleg.gov/Members/Biography/H/795"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-71-lower-ocd-person-91f28923-c52e-4a47-aed2-944672902823": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/628fc48c-a48b-4128-87f1-2391ab795e0a",
            "firstName": "Amber M.",
            "lastName": "Baker",
            "fullName": "Amber Baker",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/782/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-NC-72-lower-ocd-person-628fc48c-a48b-4128-87f1-2391ab795e0a",
              "builtID": "nc-lower-72",
              "externalID": "ocd-person/628fc48c-a48b-4128-87f1-2391ab795e0a",
              "geometry": null
            },
            "contactInfo": {
              "email": "amber.baker@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/782",
                "https://www.ncleg.gov/Members/Votes/H/782",
                "https://www.ncleg.gov/Members/Committees/H/782",
                "https://www.ncleg.gov/Members/Biography/H/782"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-72-lower-ocd-person-628fc48c-a48b-4128-87f1-2391ab795e0a": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/df8b5927-735d-4adf-81a0-a680523797d5",
            "firstName": "Diamond",
            "lastName": "Staton-Williams",
            "fullName": "Diamond Staton-Williams",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/815/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-NC-73-lower-ocd-person-df8b5927-735d-4adf-81a0-a680523797d5",
              "builtID": "nc-lower-73",
              "externalID": "ocd-person/df8b5927-735d-4adf-81a0-a680523797d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "diamond.staton-williams@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/815",
                "https://www.ncleg.gov/Members/Votes/H/815",
                "https://www.ncleg.gov/Members/Committees/H/815",
                "https://www.teamdiamondnc.com/",
                "https://www.ncleg.gov/Members/Biography/H/815"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-73-lower-ocd-person-df8b5927-735d-4adf-81a0-a680523797d5": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/f3f10d50-4117-41f8-b2af-c9273d3c425e",
            "firstName": "Jeff",
            "lastName": "Zenger",
            "fullName": "Jeff Zenger",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/775/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-NC-74-lower-ocd-person-f3f10d50-4117-41f8-b2af-c9273d3c425e",
              "builtID": "nc-lower-74",
              "externalID": "ocd-person/f3f10d50-4117-41f8-b2af-c9273d3c425e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.zenger@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/775",
                "https://www.ncleg.gov/Members/Votes/H/775",
                "https://www.ncleg.gov/Members/Committees/H/775",
                "https://www.ncleg.gov/Members/Biography/H/775"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-74-lower-ocd-person-f3f10d50-4117-41f8-b2af-c9273d3c425e": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/dd2200c1-2bdb-4afc-a557-ebcbb5f1eee8",
            "firstName": "Donny Carr",
            "lastName": "Lambeth",
            "fullName": "Donny Lambeth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/646/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-NC-75-lower-ocd-person-dd2200c1-2bdb-4afc-a557-ebcbb5f1eee8",
              "builtID": "nc-lower-75",
              "externalID": "ocd-person/dd2200c1-2bdb-4afc-a557-ebcbb5f1eee8",
              "geometry": null
            },
            "contactInfo": {
              "email": "donny.lambeth@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/646",
                "https://www.ncleg.gov/Members/Votes/H/646",
                "https://www.ncleg.gov/Members/Committees/H/646",
                "https://www.ncleg.gov/Members/Biography/H/646"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-75-lower-ocd-person-dd2200c1-2bdb-4afc-a557-ebcbb5f1eee8": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/940d4a15-ee12-4a3b-a7b5-70b9dea38379",
            "firstName": "Harry Joseph",
            "lastName": "Warren",
            "fullName": "Harry Warren",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/630/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-NC-76-lower-ocd-person-940d4a15-ee12-4a3b-a7b5-70b9dea38379",
              "builtID": "nc-lower-76",
              "externalID": "ocd-person/940d4a15-ee12-4a3b-a7b5-70b9dea38379",
              "geometry": null
            },
            "contactInfo": {
              "email": "harry.warren@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/630",
                "https://www.ncleg.gov/Members/Votes/H/630",
                "https://www.ncleg.gov/Members/Committees/H/630",
                "https://www.ncleg.gov/Members/Biography/H/630"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-76-lower-ocd-person-940d4a15-ee12-4a3b-a7b5-70b9dea38379": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/5318144e-d466-40ea-8e35-f0f09bf57760",
            "firstName": "Julia Craven",
            "lastName": "Howard",
            "fullName": "Julia Howard",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/53/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-NC-77-lower-ocd-person-5318144e-d466-40ea-8e35-f0f09bf57760",
              "builtID": "nc-lower-77",
              "externalID": "ocd-person/5318144e-d466-40ea-8e35-f0f09bf57760",
              "geometry": null
            },
            "contactInfo": {
              "email": "julia.howard@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/53",
                "https://www.ncleg.gov/Members/Votes/H/53",
                "https://www.ncleg.gov/Members/Committees/H/53",
                "https://www.ncleg.gov/Members/Biography/H/53"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-77-lower-ocd-person-5318144e-d466-40ea-8e35-f0f09bf57760": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/1e2d0ef7-d021-4427-af07-14fae1ad5aa0",
            "firstName": "Neal",
            "lastName": "Jackson",
            "fullName": "Neal Jackson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/804/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-NC-78-lower-ocd-person-1e2d0ef7-d021-4427-af07-14fae1ad5aa0",
              "builtID": "nc-lower-78",
              "externalID": "ocd-person/1e2d0ef7-d021-4427-af07-14fae1ad5aa0",
              "geometry": null
            },
            "contactInfo": {
              "email": "neal.jackson@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/804",
                "https://www.ncleg.gov/Members/Votes/H/804",
                "https://www.ncleg.gov/Members/Committees/H/804",
                "https://www.ncleg.gov/Members/Biography/H/804"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-78-lower-ocd-person-1e2d0ef7-d021-4427-af07-14fae1ad5aa0": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/a39903f1-cf67-494a-8c6a-4ae15c334fc9",
            "firstName": "Keith Douglas",
            "lastName": "Kidwell",
            "fullName": "Keith Kidwell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/749/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-NC-79-lower-ocd-person-a39903f1-cf67-494a-8c6a-4ae15c334fc9",
              "builtID": "nc-lower-79",
              "externalID": "ocd-person/a39903f1-cf67-494a-8c6a-4ae15c334fc9",
              "geometry": null
            },
            "contactInfo": {
              "email": "keith.kidwell@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/749",
                "https://www.ncleg.gov/Members/Votes/H/749",
                "https://www.ncleg.gov/Members/Committees/H/749",
                "https://www.ncleg.gov/Members/Biography/H/749"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-79-lower-ocd-person-a39903f1-cf67-494a-8c6a-4ae15c334fc9": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/4f490403-b729-4f33-b3ba-5417db20cb0b",
            "firstName": "Sam",
            "lastName": "Watford",
            "fullName": "Sam Watford",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/687/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-NC-80-lower-ocd-person-4f490403-b729-4f33-b3ba-5417db20cb0b",
              "builtID": "nc-lower-80",
              "externalID": "ocd-person/4f490403-b729-4f33-b3ba-5417db20cb0b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sam.watford@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/687",
                "https://www.ncleg.gov/Members/Votes/H/687",
                "https://www.ncleg.gov/Members/Committees/H/687",
                "https://www.ncleg.gov/Members/Biography/H/687"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-80-lower-ocd-person-4f490403-b729-4f33-b3ba-5417db20cb0b": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/11c903f8-ce25-4463-8a24-a49394c21955",
            "firstName": "Larry Wayne",
            "lastName": "Potts",
            "fullName": "Larry Potts",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/724/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-NC-81-lower-ocd-person-11c903f8-ce25-4463-8a24-a49394c21955",
              "builtID": "nc-lower-81",
              "externalID": "ocd-person/11c903f8-ce25-4463-8a24-a49394c21955",
              "geometry": null
            },
            "contactInfo": {
              "email": "larry.potts@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/724",
                "https://www.ncleg.gov/Members/Votes/H/724",
                "https://www.ncleg.gov/Members/Committees/H/724",
                "https://www.ncleg.gov/Members/Biography/H/724"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-81-lower-ocd-person-11c903f8-ce25-4463-8a24-a49394c21955": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/9f6b2b82-9747-4a89-9c58-b6580e9ca378",
            "firstName": "Kristin",
            "lastName": "Baker",
            "fullName": "Kristin Baker",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/764/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-NC-82-lower-ocd-person-9f6b2b82-9747-4a89-9c58-b6580e9ca378",
              "builtID": "nc-lower-82",
              "externalID": "ocd-person/9f6b2b82-9747-4a89-9c58-b6580e9ca378",
              "geometry": null
            },
            "contactInfo": {
              "email": "kristin.baker@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/764",
                "https://www.ncleg.gov/Members/Votes/H/764",
                "https://www.ncleg.gov/Members/Committees/H/764",
                "https://www.ncleg.gov/Members/Biography/H/764"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-82-lower-ocd-person-9f6b2b82-9747-4a89-9c58-b6580e9ca378": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/c8c86193-4cc2-4313-b692-322fdbd3c9be",
            "firstName": "Kevin",
            "lastName": "Crutchfield",
            "fullName": "Kevin Crutchfield",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/801/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-NC-83-lower-ocd-person-c8c86193-4cc2-4313-b692-322fdbd3c9be",
              "builtID": "nc-lower-83",
              "externalID": "ocd-person/c8c86193-4cc2-4313-b692-322fdbd3c9be",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.crutchfield@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/801",
                "https://www.ncleg.gov/Members/Votes/H/801",
                "https://www.ncleg.gov/Members/Committees/H/801",
                "https://www.ncleg.gov/Members/Biography/H/801"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-83-lower-ocd-person-c8c86193-4cc2-4313-b692-322fdbd3c9be": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/09d4ccb5-4456-4f33-aea7-36f3e2ef9e43",
            "firstName": "Jeffrey C.",
            "lastName": "McNeely",
            "fullName": "Jeff McNeely",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/761/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-NC-84-lower-ocd-person-09d4ccb5-4456-4f33-aea7-36f3e2ef9e43",
              "builtID": "nc-lower-84",
              "externalID": "ocd-person/09d4ccb5-4456-4f33-aea7-36f3e2ef9e43",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeffrey.mcneely@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/761",
                "https://www.ncleg.gov/Members/Votes/H/761",
                "https://www.ncleg.gov/Members/Committees/H/761",
                "https://www.ncleg.gov/Members/Biography/H/761"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-84-lower-ocd-person-09d4ccb5-4456-4f33-aea7-36f3e2ef9e43": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/0573192d-0557-449a-9c3e-8462844a79ab",
            "firstName": "Edwin Dudley",
            "lastName": "Greene",
            "fullName": "Dudley Greene",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/777/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-NC-85-lower-ocd-person-0573192d-0557-449a-9c3e-8462844a79ab",
              "builtID": "nc-lower-85",
              "externalID": "ocd-person/0573192d-0557-449a-9c3e-8462844a79ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "dudley.greene@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/777",
                "https://www.ncleg.gov/Members/Votes/H/777",
                "https://www.ncleg.gov/Members/Committees/H/777",
                "https://www.ncleg.gov/Members/Biography/H/777"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-85-lower-ocd-person-0573192d-0557-449a-9c3e-8462844a79ab": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/f7ffa24f-4c1f-416f-bf1d-30d762d9c6c3",
            "firstName": "Hugh Allen",
            "lastName": "Blackwell",
            "fullName": "Hugh Blackwell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/580/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-NC-86-lower-ocd-person-f7ffa24f-4c1f-416f-bf1d-30d762d9c6c3",
              "builtID": "nc-lower-86",
              "externalID": "ocd-person/f7ffa24f-4c1f-416f-bf1d-30d762d9c6c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "hugh.blackwell@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/580",
                "https://www.ncleg.gov/Members/Votes/H/580",
                "https://www.ncleg.gov/Members/Committees/H/580",
                "https://www.ncleg.gov/Members/Biography/H/580"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-86-lower-ocd-person-f7ffa24f-4c1f-416f-bf1d-30d762d9c6c3": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/636555c9-b55c-47f8-9fcc-c9cb93b27f50",
            "firstName": "Destin",
            "lastName": "Hall",
            "fullName": "Destin Hall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/719/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-NC-87-lower-ocd-person-636555c9-b55c-47f8-9fcc-c9cb93b27f50",
              "builtID": "nc-lower-87",
              "externalID": "ocd-person/636555c9-b55c-47f8-9fcc-c9cb93b27f50",
              "geometry": null
            },
            "contactInfo": {
              "email": "destin.hall@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/719",
                "https://www.ncleg.gov/Members/Votes/H/719",
                "https://www.ncleg.gov/Members/Committees/H/719",
                "https://www.ncleg.gov/Members/Biography/H/719"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-87-lower-ocd-person-636555c9-b55c-47f8-9fcc-c9cb93b27f50": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/819581b6-a44a-4d4a-8a08-14c2dccd86f5",
            "firstName": "Mary Denise Gardner",
            "lastName": "Belk",
            "fullName": "Mary Belk",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/713/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-NC-88-lower-ocd-person-819581b6-a44a-4d4a-8a08-14c2dccd86f5",
              "builtID": "nc-lower-88",
              "externalID": "ocd-person/819581b6-a44a-4d4a-8a08-14c2dccd86f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.belk@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/713",
                "https://www.ncleg.gov/Members/Votes/H/713",
                "https://www.ncleg.gov/Members/Committees/H/713",
                "https://www.ncleg.gov/Members/Biography/H/713"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-88-lower-ocd-person-819581b6-a44a-4d4a-8a08-14c2dccd86f5": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/d4713ec5-e36b-49bb-bfc8-6b7fe9c0e9a7",
            "firstName": "Mitchell Smith",
            "lastName": "Setzer",
            "fullName": "Mitchell Setzer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/149/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-NC-89-lower-ocd-person-d4713ec5-e36b-49bb-bfc8-6b7fe9c0e9a7",
              "builtID": "nc-lower-89",
              "externalID": "ocd-person/d4713ec5-e36b-49bb-bfc8-6b7fe9c0e9a7",
              "geometry": null
            },
            "contactInfo": {
              "email": "mitchell.setzer@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/149",
                "https://www.ncleg.gov/Members/Votes/H/149",
                "https://www.ncleg.gov/Members/Committees/H/149",
                "https://www.ncleg.gov/Members/Biography/H/149"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-89-lower-ocd-person-d4713ec5-e36b-49bb-bfc8-6b7fe9c0e9a7": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/09099128-27fc-463f-af36-28253d1e7a60",
            "firstName": "Sarah Suzanne",
            "lastName": "Stevens",
            "fullName": "Sarah Stevens",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/592/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-NC-90-lower-ocd-person-09099128-27fc-463f-af36-28253d1e7a60",
              "builtID": "nc-lower-90",
              "externalID": "ocd-person/09099128-27fc-463f-af36-28253d1e7a60",
              "geometry": null
            },
            "contactInfo": {
              "email": "sarah.stevens@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/592",
                "https://www.ncleg.gov/Members/Votes/H/592",
                "https://www.ncleg.gov/Members/Committees/H/592",
                "https://www.ncleg.gov/Members/Biography/H/592"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-90-lower-ocd-person-09099128-27fc-463f-af36-28253d1e7a60": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/6d8e0e56-939e-45c4-a9db-2fc370fbbdef",
            "firstName": "Kyle Ethan",
            "lastName": "Hall",
            "fullName": "Kyle Hall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/704/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-NC-91-lower-ocd-person-6d8e0e56-939e-45c4-a9db-2fc370fbbdef",
              "builtID": "nc-lower-91",
              "externalID": "ocd-person/6d8e0e56-939e-45c4-a9db-2fc370fbbdef",
              "geometry": null
            },
            "contactInfo": {
              "email": "kyle.hall@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/704",
                "https://www.ncleg.gov/Members/Votes/H/704",
                "https://www.ncleg.gov/Members/Committees/H/704",
                "https://www.ncleg.gov/Members/Biography/H/704"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-91-lower-ocd-person-6d8e0e56-939e-45c4-a9db-2fc370fbbdef": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/1ac38d5f-d089-4589-bcc4-bef1f1675956",
            "firstName": "Terry M.",
            "lastName": "Brown",
            "fullName": "Terry Brown",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/783/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-NC-92-lower-ocd-person-1ac38d5f-d089-4589-bcc4-bef1f1675956",
              "builtID": "nc-lower-92",
              "externalID": "ocd-person/1ac38d5f-d089-4589-bcc4-bef1f1675956",
              "geometry": null
            },
            "contactInfo": {
              "email": "terry.brown@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/783",
                "https://www.ncleg.gov/Members/Votes/H/783",
                "https://www.ncleg.gov/Members/Committees/H/783",
                "https://www.ncleg.gov/Members/Biography/H/783"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-92-lower-ocd-person-1ac38d5f-d089-4589-bcc4-bef1f1675956": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/27cea850-e6f2-4fe8-a02e-26f9867d00f1",
            "firstName": "Ray",
            "lastName": "Pickett",
            "fullName": "Ray Pickett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/772/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-NC-93-lower-ocd-person-27cea850-e6f2-4fe8-a02e-26f9867d00f1",
              "builtID": "nc-lower-93",
              "externalID": "ocd-person/27cea850-e6f2-4fe8-a02e-26f9867d00f1",
              "geometry": null
            },
            "contactInfo": {
              "email": "ray.pickett@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/772",
                "https://www.ncleg.gov/Members/Votes/H/772",
                "https://www.ncleg.gov/Members/Committees/H/772",
                "https://www.ncleg.gov/Members/Biography/H/772"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-93-lower-ocd-person-27cea850-e6f2-4fe8-a02e-26f9867d00f1": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/04f650b5-970c-456b-a4b5-91244029b455",
            "firstName": "Jeffrey Carter",
            "lastName": "Elmore",
            "fullName": "Jeffrey Elmore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/643/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-NC-94-lower-ocd-person-04f650b5-970c-456b-a4b5-91244029b455",
              "builtID": "nc-lower-94",
              "externalID": "ocd-person/04f650b5-970c-456b-a4b5-91244029b455",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeffrey.elmore@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/643",
                "https://www.ncleg.gov/Members/Votes/H/643",
                "https://www.ncleg.gov/Members/Committees/H/643",
                "https://www.ncleg.gov/Members/Biography/H/643"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-94-lower-ocd-person-04f650b5-970c-456b-a4b5-91244029b455": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/d7e20d53-bf34-45db-8ed1-d246f6849c44",
            "firstName": "Paul Grey",
            "lastName": "Mills",
            "fullName": "Grey Mills",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/588/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-NC-95-lower-ocd-person-d7e20d53-bf34-45db-8ed1-d246f6849c44",
              "builtID": "nc-lower-95",
              "externalID": "ocd-person/d7e20d53-bf34-45db-8ed1-d246f6849c44",
              "geometry": null
            },
            "contactInfo": {
              "email": "grey.mills@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/588",
                "https://www.ncleg.gov/Members/Votes/H/588",
                "https://www.ncleg.gov/Members/Committees/H/588",
                "https://www.ncleg.gov/Members/Biography/H/588"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-95-lower-ocd-person-d7e20d53-bf34-45db-8ed1-d246f6849c44": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/1a75f967-8f35-40aa-8b0a-990a135e70cb",
            "firstName": "James Cecil",
            "lastName": "Adams",
            "fullName": "Jay Adams",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/697/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-NC-96-lower-ocd-person-1a75f967-8f35-40aa-8b0a-990a135e70cb",
              "builtID": "nc-lower-96",
              "externalID": "ocd-person/1a75f967-8f35-40aa-8b0a-990a135e70cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "jay.adams@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/697",
                "https://www.ncleg.gov/Members/Votes/H/697",
                "https://www.ncleg.gov/Members/Committees/H/697",
                "https://www.ncleg.gov/Members/Biography/H/697"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-96-lower-ocd-person-1a75f967-8f35-40aa-8b0a-990a135e70cb": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/bbb0290b-f3f6-4e3b-a171-9c41d5f70abd",
            "firstName": "Jason Ray",
            "lastName": "Saine",
            "fullName": "Jason Saine",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/632/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-NC-97-lower-ocd-person-bbb0290b-f3f6-4e3b-a171-9c41d5f70abd",
              "builtID": "nc-lower-97",
              "externalID": "ocd-person/bbb0290b-f3f6-4e3b-a171-9c41d5f70abd",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.saine@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/632",
                "https://www.ncleg.gov/Members/Votes/H/632",
                "https://www.ncleg.gov/Members/Committees/H/632",
                "https://www.ncleg.gov/Members/Biography/H/632",
                "https://www.repjasonsaine.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-97-lower-ocd-person-bbb0290b-f3f6-4e3b-a171-9c41d5f70abd": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/5cd45bec-9ee6-4ea7-ace5-b09942ef7e2b",
            "firstName": "John Ray",
            "lastName": "Bradford",
            "fullName": "John Bradford",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/690/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-NC-98-lower-ocd-person-5cd45bec-9ee6-4ea7-ace5-b09942ef7e2b",
              "builtID": "nc-lower-98",
              "externalID": "ocd-person/5cd45bec-9ee6-4ea7-ace5-b09942ef7e2b",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.bradford@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/690",
                "https://www.ncleg.gov/Members/Votes/H/690",
                "https://www.ncleg.gov/Members/Committees/H/690",
                "https://www.ncleg.gov/Members/Biography/H/690"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-98-lower-ocd-person-5cd45bec-9ee6-4ea7-ace5-b09942ef7e2b": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/6acc3963-2724-4d88-8762-f78df333be94",
            "firstName": "Nasif Rashad",
            "lastName": "Majeed",
            "fullName": "Nasif Majeed",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/752/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-NC-99-lower-ocd-person-6acc3963-2724-4d88-8762-f78df333be94",
              "builtID": "nc-lower-99",
              "externalID": "ocd-person/6acc3963-2724-4d88-8762-f78df333be94",
              "geometry": null
            },
            "contactInfo": {
              "email": "nasif.majeed@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/752",
                "https://www.ncleg.gov/Members/Votes/H/752",
                "https://www.ncleg.gov/Members/Committees/H/752",
                "https://www.ncleg.gov/Members/Biography/H/752"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-99-lower-ocd-person-6acc3963-2724-4d88-8762-f78df333be94": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/9b9e51a1-a136-44d3-af3b-1e91e09df335",
            "firstName": "Johnnie Newton",
            "lastName": "Autry",
            "fullName": "John Autry",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/710/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-NC-100-lower-ocd-person-9b9e51a1-a136-44d3-af3b-1e91e09df335",
              "builtID": "nc-lower-100",
              "externalID": "ocd-person/9b9e51a1-a136-44d3-af3b-1e91e09df335",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.autry@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/710",
                "https://www.ncleg.gov/Members/Votes/H/710",
                "https://www.ncleg.gov/Members/Committees/H/710",
                "https://www.ncleg.gov/Members/Biography/H/710"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-100-lower-ocd-person-9b9e51a1-a136-44d3-af3b-1e91e09df335": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/80495071-4aea-43ff-9548-09860c77cc90",
            "firstName": "Carolyn Green",
            "lastName": "Logan",
            "fullName": "Carolyn Logan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/750/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-NC-101-lower-ocd-person-80495071-4aea-43ff-9548-09860c77cc90",
              "builtID": "nc-lower-101",
              "externalID": "ocd-person/80495071-4aea-43ff-9548-09860c77cc90",
              "geometry": null
            },
            "contactInfo": {
              "email": "carolyn.logan@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/750",
                "https://www.ncleg.gov/Members/Votes/H/750",
                "https://www.ncleg.gov/Members/Committees/H/750",
                "https://www.ncleg.gov/Members/Biography/H/750"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-101-lower-ocd-person-80495071-4aea-43ff-9548-09860c77cc90": 0
        }
      },
      "102": {
        "members": [
          {
            "API_ID": "ocd-person/367ad234-14bc-4d86-95ee-ab1176890d6e",
            "firstName": "Rebecca Ann",
            "lastName": "Carney",
            "fullName": "Becky Carney",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/322/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "102",
              "chamber": "lower",
              "hashID": "SLDL-NC-102-lower-ocd-person-367ad234-14bc-4d86-95ee-ab1176890d6e",
              "builtID": "nc-lower-102",
              "externalID": "ocd-person/367ad234-14bc-4d86-95ee-ab1176890d6e",
              "geometry": null
            },
            "contactInfo": {
              "email": "becky.carney@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/322",
                "https://www.ncleg.gov/Members/Votes/H/322",
                "https://www.ncleg.gov/Members/Committees/H/322",
                "https://www.ncleg.gov/Members/Biography/H/322"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-102-lower-ocd-person-367ad234-14bc-4d86-95ee-ab1176890d6e": 0
        }
      },
      "103": {
        "members": [
          {
            "API_ID": "ocd-person/283007b1-e7a2-486b-b19b-5a8126bc4128",
            "firstName": "Laura",
            "lastName": "Budd",
            "fullName": "Laura Budd",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/796/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "103",
              "chamber": "lower",
              "hashID": "SLDL-NC-103-lower-ocd-person-283007b1-e7a2-486b-b19b-5a8126bc4128",
              "builtID": "nc-lower-103",
              "externalID": "ocd-person/283007b1-e7a2-486b-b19b-5a8126bc4128",
              "geometry": null
            },
            "contactInfo": {
              "email": "laura.budd@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/796",
                "https://www.ncleg.gov/Members/Votes/H/796",
                "https://www.ncleg.gov/Members/Committees/H/796",
                "https://www.ncleg.gov/Members/Biography/H/796"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-103-lower-ocd-person-283007b1-e7a2-486b-b19b-5a8126bc4128": 0
        }
      },
      "104": {
        "members": [
          {
            "API_ID": "ocd-person/4142addf-2eb1-4557-b3d3-bcea559cbbd3",
            "firstName": "Brandon Marcus",
            "lastName": "Lofton",
            "fullName": "Brandon Lofton",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/751/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "104",
              "chamber": "lower",
              "hashID": "SLDL-NC-104-lower-ocd-person-4142addf-2eb1-4557-b3d3-bcea559cbbd3",
              "builtID": "nc-lower-104",
              "externalID": "ocd-person/4142addf-2eb1-4557-b3d3-bcea559cbbd3",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandon.lofton@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/751",
                "https://www.ncleg.gov/Members/Votes/H/751",
                "https://www.ncleg.gov/Members/Committees/H/751",
                "https://www.ncleg.gov/Members/Biography/H/751"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-104-lower-ocd-person-4142addf-2eb1-4557-b3d3-bcea559cbbd3": 0
        }
      },
      "105": {
        "members": [
          {
            "API_ID": "ocd-person/cc57c6c0-2012-4250-a27b-0c8479cc3eac",
            "firstName": "Wesley Ryan",
            "lastName": "Harris",
            "fullName": "Wesley Harris",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/746/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "105",
              "chamber": "lower",
              "hashID": "SLDL-NC-105-lower-ocd-person-cc57c6c0-2012-4250-a27b-0c8479cc3eac",
              "builtID": "nc-lower-105",
              "externalID": "ocd-person/cc57c6c0-2012-4250-a27b-0c8479cc3eac",
              "geometry": null
            },
            "contactInfo": {
              "email": "wesley.harris@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/746",
                "https://www.ncleg.gov/Members/Votes/H/746",
                "https://www.ncleg.gov/Members/Committees/H/746",
                "https://www.ncleg.gov/Members/Biography/H/746"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-105-lower-ocd-person-cc57c6c0-2012-4250-a27b-0c8479cc3eac": 0
        }
      },
      "106": {
        "members": [
          {
            "API_ID": "ocd-person/f1ba290a-c2a5-453a-884c-6d03a2c8e83a",
            "firstName": "Carla Dellette",
            "lastName": "Cunningham",
            "fullName": "Carla Cunningham",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/642/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "106",
              "chamber": "lower",
              "hashID": "SLDL-NC-106-lower-ocd-person-f1ba290a-c2a5-453a-884c-6d03a2c8e83a",
              "builtID": "nc-lower-106",
              "externalID": "ocd-person/f1ba290a-c2a5-453a-884c-6d03a2c8e83a",
              "geometry": null
            },
            "contactInfo": {
              "email": "carla.cunningham@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/642",
                "https://www.ncleg.gov/Members/Votes/H/642",
                "https://www.ncleg.gov/Members/Committees/H/642",
                "https://www.ncleg.gov/Members/Biography/H/642"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-106-lower-ocd-person-f1ba290a-c2a5-453a-884c-6d03a2c8e83a": 0
        }
      },
      "107": {
        "members": [
          {
            "API_ID": "ocd-person/eac9e7a4-8770-4f84-ba03-4332342651fb",
            "firstName": "Kelly M.",
            "lastName": "Alexander",
            "fullName": "Kelly Alexander",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/579/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "107",
              "chamber": "lower",
              "hashID": "SLDL-NC-107-lower-ocd-person-eac9e7a4-8770-4f84-ba03-4332342651fb",
              "builtID": "nc-lower-107",
              "externalID": "ocd-person/eac9e7a4-8770-4f84-ba03-4332342651fb",
              "geometry": null
            },
            "contactInfo": {
              "email": "kelly.alexander@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/579",
                "https://www.ncleg.gov/Members/Votes/H/579",
                "https://www.ncleg.gov/Members/Committees/H/579",
                "https://www.ncleg.gov/Members/Biography/H/579"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-107-lower-ocd-person-eac9e7a4-8770-4f84-ba03-4332342651fb": 0
        }
      },
      "108": {
        "members": [
          {
            "API_ID": "ocd-person/cb059250-c895-4a14-bfa2-997aed42155e",
            "firstName": "John A.",
            "lastName": "Torbett",
            "fullName": "John Torbett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/606/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "108",
              "chamber": "lower",
              "hashID": "SLDL-NC-108-lower-ocd-person-cb059250-c895-4a14-bfa2-997aed42155e",
              "builtID": "nc-lower-108",
              "externalID": "ocd-person/cb059250-c895-4a14-bfa2-997aed42155e",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.torbett@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/606",
                "https://www.ncleg.gov/Members/Votes/H/606",
                "https://www.ncleg.gov/Members/Committees/H/606",
                "https://www.ncleg.gov/Members/Biography/H/606"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-108-lower-ocd-person-cb059250-c895-4a14-bfa2-997aed42155e": 0
        }
      },
      "109": {
        "members": [
          {
            "API_ID": "ocd-person/d7308aed-454d-4091-b32c-606f516e9421",
            "firstName": "Donnie",
            "lastName": "Loftis",
            "fullName": "Donnie Loftis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/788/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "109",
              "chamber": "lower",
              "hashID": "SLDL-NC-109-lower-ocd-person-d7308aed-454d-4091-b32c-606f516e9421",
              "builtID": "nc-lower-109",
              "externalID": "ocd-person/d7308aed-454d-4091-b32c-606f516e9421",
              "geometry": null
            },
            "contactInfo": {
              "email": "donnie.loftis@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/788",
                "https://www.ncleg.gov/Members/Votes/H/788",
                "https://www.ncleg.gov/Members/Committees/H/788",
                "https://www.ncleg.gov/Members/Biography/H/788"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-109-lower-ocd-person-d7308aed-454d-4091-b32c-606f516e9421": 0
        }
      },
      "110": {
        "members": [
          {
            "API_ID": "ocd-person/4edb53d2-f5a1-447d-ac37-36da75a7a4ed",
            "firstName": "Kelly Eugene",
            "lastName": "Hastings",
            "fullName": "Kelly Hastings",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/618/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "110",
              "chamber": "lower",
              "hashID": "SLDL-NC-110-lower-ocd-person-4edb53d2-f5a1-447d-ac37-36da75a7a4ed",
              "builtID": "nc-lower-110",
              "externalID": "ocd-person/4edb53d2-f5a1-447d-ac37-36da75a7a4ed",
              "geometry": null
            },
            "contactInfo": {
              "email": "kelly.hastings@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/618",
                "https://www.ncleg.gov/Members/Votes/H/618",
                "https://www.ncleg.gov/Members/Committees/H/618",
                "https://www.ncleg.gov/Members/Biography/H/618"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-110-lower-ocd-person-4edb53d2-f5a1-447d-ac37-36da75a7a4ed": 0
        }
      },
      "111": {
        "members": [
          {
            "API_ID": "ocd-person/631d7157-ffae-4043-907b-f18fa9b978b0",
            "firstName": "Timothy Keith",
            "lastName": "Moore",
            "fullName": "Tim Moore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/339/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "111",
              "chamber": "lower",
              "hashID": "SLDL-NC-111-lower-ocd-person-631d7157-ffae-4043-907b-f18fa9b978b0",
              "builtID": "nc-lower-111",
              "externalID": "ocd-person/631d7157-ffae-4043-907b-f18fa9b978b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "tim.moore@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/339",
                "https://www.ncleg.gov/Members/Votes/H/339",
                "https://www.ncleg.gov/Members/Committees/H/339",
                "https://www.ncleg.gov/Members/Biography/H/339",
                "https://speakermoore.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-111-lower-ocd-person-631d7157-ffae-4043-907b-f18fa9b978b0": 0
        }
      },
      "112": {
        "members": [
          {
            "API_ID": "ocd-person/6045737f-cc7a-4cf5-b7e6-12419deed77b",
            "firstName": "Patricia Ann",
            "lastName": "Cotham",
            "fullName": "Tricia Cotham",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/817/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "112",
              "chamber": "lower",
              "hashID": "SLDL-NC-112-lower-ocd-person-6045737f-cc7a-4cf5-b7e6-12419deed77b",
              "builtID": "nc-lower-112",
              "externalID": "ocd-person/6045737f-cc7a-4cf5-b7e6-12419deed77b",
              "geometry": null
            },
            "contactInfo": {
              "email": "tricia.cotham@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/817",
                "https://www.ncleg.gov/Members/Votes/H/817",
                "https://www.ncleg.gov/Members/Committees/H/817",
                "https://www.ncleg.gov/Members/Biography/H/817"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-112-lower-ocd-person-6045737f-cc7a-4cf5-b7e6-12419deed77b": 0
        }
      },
      "113": {
        "members": [
          {
            "API_ID": "ocd-person/11c5404c-5cb6-4f99-84a5-711a23e6295f",
            "firstName": "Jake Hunter",
            "lastName": "Johnson",
            "fullName": "Jake Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/762/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "113",
              "chamber": "lower",
              "hashID": "SLDL-NC-113-lower-ocd-person-11c5404c-5cb6-4f99-84a5-711a23e6295f",
              "builtID": "nc-lower-113",
              "externalID": "ocd-person/11c5404c-5cb6-4f99-84a5-711a23e6295f",
              "geometry": null
            },
            "contactInfo": {
              "email": "jake.johnson@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/762",
                "https://www.ncleg.gov/Members/Votes/H/762",
                "https://www.ncleg.gov/Members/Committees/H/762",
                "https://www.ncleg.gov/Members/Biography/H/762"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-113-lower-ocd-person-11c5404c-5cb6-4f99-84a5-711a23e6295f": 0
        }
      },
      "114": {
        "members": [
          {
            "API_ID": "ocd-person/8c10abe1-4b81-404a-9164-9823e023cd2b",
            "firstName": "John Eric",
            "lastName": "Ager",
            "fullName": "Eric Ager",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/819/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "114",
              "chamber": "lower",
              "hashID": "SLDL-NC-114-lower-ocd-person-8c10abe1-4b81-404a-9164-9823e023cd2b",
              "builtID": "nc-lower-114",
              "externalID": "ocd-person/8c10abe1-4b81-404a-9164-9823e023cd2b",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.ager@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/819",
                "https://www.ncleg.gov/Members/Votes/H/819",
                "https://www.ncleg.gov/Members/Committees/H/819",
                "https://www.ncleg.gov/Members/Biography/H/819"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-114-lower-ocd-person-8c10abe1-4b81-404a-9164-9823e023cd2b": 0
        }
      },
      "115": {
        "members": [
          {
            "API_ID": "ocd-person/16a0c907-4efa-45a7-83d2-1c79cc541535",
            "firstName": "Lindsey",
            "lastName": "Prather",
            "fullName": "Lindsey Prather",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/809/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "115",
              "chamber": "lower",
              "hashID": "SLDL-NC-115-lower-ocd-person-16a0c907-4efa-45a7-83d2-1c79cc541535",
              "builtID": "nc-lower-115",
              "externalID": "ocd-person/16a0c907-4efa-45a7-83d2-1c79cc541535",
              "geometry": null
            },
            "contactInfo": {
              "email": "lindsey.prather@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/809",
                "https://www.ncleg.gov/Members/Votes/H/809",
                "https://www.ncleg.gov/Members/Committees/H/809",
                "https://www.ncleg.gov/Members/Biography/H/809"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-115-lower-ocd-person-16a0c907-4efa-45a7-83d2-1c79cc541535": 0
        }
      },
      "116": {
        "members": [
          {
            "API_ID": "ocd-person/a47657b4-dcb7-452d-876f-b7cddcf194a9",
            "firstName": "Caleb",
            "lastName": "Rudow",
            "fullName": "Caleb Rudow",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/789/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "116",
              "chamber": "lower",
              "hashID": "SLDL-NC-116-lower-ocd-person-a47657b4-dcb7-452d-876f-b7cddcf194a9",
              "builtID": "nc-lower-116",
              "externalID": "ocd-person/a47657b4-dcb7-452d-876f-b7cddcf194a9",
              "geometry": null
            },
            "contactInfo": {
              "email": "caleb.rudow@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/789",
                "https://www.ncleg.gov/Members/Votes/H/789",
                "https://www.ncleg.gov/Members/Committees/H/789",
                "https://www.ncleg.gov/Members/Biography/H/789"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-116-lower-ocd-person-a47657b4-dcb7-452d-876f-b7cddcf194a9": 0
        }
      },
      "117": {
        "members": [
          {
            "API_ID": "ocd-person/1c0b287a-2695-47e3-8c7a-1868db5c8d5c",
            "firstName": "Jennifer Capps",
            "lastName": "Balkcom",
            "fullName": "Jennifer Balkcom",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/820/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "117",
              "chamber": "lower",
              "hashID": "SLDL-NC-117-lower-ocd-person-1c0b287a-2695-47e3-8c7a-1868db5c8d5c",
              "builtID": "nc-lower-117",
              "externalID": "ocd-person/1c0b287a-2695-47e3-8c7a-1868db5c8d5c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jennifer.balkcom@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/820",
                "https://www.ncleg.gov/Members/Votes/H/820",
                "https://www.ncleg.gov/Members/Committees/H/820",
                "https://www.ncleg.gov/Members/Biography/H/820"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-117-lower-ocd-person-1c0b287a-2695-47e3-8c7a-1868db5c8d5c": 0
        }
      },
      "118": {
        "members": [
          {
            "API_ID": "ocd-person/601cab9a-8b58-42fa-a62e-4170ec23b955",
            "firstName": "Mark",
            "lastName": "Pless",
            "fullName": "Mark Pless",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/773/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "118",
              "chamber": "lower",
              "hashID": "SLDL-NC-118-lower-ocd-person-601cab9a-8b58-42fa-a62e-4170ec23b955",
              "builtID": "nc-lower-118",
              "externalID": "ocd-person/601cab9a-8b58-42fa-a62e-4170ec23b955",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.pless@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/773",
                "https://www.ncleg.gov/Members/Votes/H/773",
                "https://www.ncleg.gov/Members/Committees/H/773",
                "https://www.ncleg.gov/Members/Biography/H/773"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-118-lower-ocd-person-601cab9a-8b58-42fa-a62e-4170ec23b955": 0
        }
      },
      "119": {
        "members": [
          {
            "API_ID": "ocd-person/7bb5e97d-cc3b-4e78-94b1-92f6ec537afd",
            "firstName": "James Michael",
            "lastName": "Clampitt",
            "fullName": "Mike Clampitt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/715/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "119",
              "chamber": "lower",
              "hashID": "SLDL-NC-119-lower-ocd-person-7bb5e97d-cc3b-4e78-94b1-92f6ec537afd",
              "builtID": "nc-lower-119",
              "externalID": "ocd-person/7bb5e97d-cc3b-4e78-94b1-92f6ec537afd",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.clampitt@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/715",
                "https://www.ncleg.gov/Members/Votes/H/715",
                "https://www.ncleg.gov/Members/Committees/H/715",
                "https://www.ncleg.gov/Members/Biography/H/715"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-119-lower-ocd-person-7bb5e97d-cc3b-4e78-94b1-92f6ec537afd": 0
        }
      },
      "120": {
        "members": [
          {
            "API_ID": "ocd-person/c0ca675d-4bd2-462f-9bcb-b7ac8226dbdf",
            "firstName": "Karl Ellis",
            "lastName": "Gillespie",
            "fullName": "Karl Gillespie",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/H/771/Low",
            "title": "NC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "120",
              "chamber": "lower",
              "hashID": "SLDL-NC-120-lower-ocd-person-c0ca675d-4bd2-462f-9bcb-b7ac8226dbdf",
              "builtID": "nc-lower-120",
              "externalID": "ocd-person/c0ca675d-4bd2-462f-9bcb-b7ac8226dbdf",
              "geometry": null
            },
            "contactInfo": {
              "email": "karl.gillespie@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/H/771",
                "https://www.ncleg.gov/Members/Votes/H/771",
                "https://www.ncleg.gov/Members/Committees/H/771",
                "https://www.ncleg.gov/Members/Biography/H/771"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-NC-120-lower-ocd-person-c0ca675d-4bd2-462f-9bcb-b7ac8226dbdf": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/7493bee0-b1ed-4d45-b772-b7fce91be4ff",
            "firstName": "Norman Wesley",
            "lastName": "Sanderson",
            "fullName": "Norman Sanderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/375/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-NC-1-upper-ocd-person-7493bee0-b1ed-4d45-b772-b7fce91be4ff",
              "builtID": "nc-upper-1",
              "externalID": "ocd-person/7493bee0-b1ed-4d45-b772-b7fce91be4ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "norman.sanderson@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/375",
                "https://www.ncleg.gov/Members/Votes/S/375",
                "https://www.ncleg.gov/Members/Committees/S/375",
                "https://www.ncleg.gov/Members/Biography/S/375"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-1-upper-ocd-person-7493bee0-b1ed-4d45-b772-b7fce91be4ff": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/8911db0d-01aa-4e61-a0f5-727d07a5d232",
            "firstName": "Robert Otho",
            "lastName": "Hanig",
            "fullName": "Bobby Hanig",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/435/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-NC-3-upper-ocd-person-8911db0d-01aa-4e61-a0f5-727d07a5d232",
              "builtID": "nc-upper-3",
              "externalID": "ocd-person/8911db0d-01aa-4e61-a0f5-727d07a5d232",
              "geometry": null
            },
            "contactInfo": {
              "email": "bobby.hanig@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/435",
                "https://www.ncleg.gov/Members/Votes/S/435",
                "https://www.ncleg.gov/Members/Committees/S/435",
                "https://www.ncleg.gov/Members/Biography/S/435"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-3-upper-ocd-person-8911db0d-01aa-4e61-a0f5-727d07a5d232": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/b560f232-dfa2-49f7-81c8-42000cc9027b",
            "firstName": "Eldon Sharpe",
            "lastName": "Newton",
            "fullName": "Buck Newton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/443/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-NC-4-upper-ocd-person-b560f232-dfa2-49f7-81c8-42000cc9027b",
              "builtID": "nc-upper-4",
              "externalID": "ocd-person/b560f232-dfa2-49f7-81c8-42000cc9027b",
              "geometry": null
            },
            "contactInfo": {
              "email": "buck.newton@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncga.state.nc.us/gascripts/members/viewMember.pl?sChamber=Senate&nUserID=362",
                "https://www.ncleg.gov/Members/IntroducedBills/S/443",
                "https://www.ncleg.gov/Members/Votes/S/443",
                "https://www.ncleg.gov/Members/Committees/S/443",
                "https://www.ncleg.gov/Members/Biography/S/443"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-4-upper-ocd-person-b560f232-dfa2-49f7-81c8-42000cc9027b": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/5afbb4dc-a121-414a-b600-53af0936beb8",
            "firstName": "Kandie Diane",
            "lastName": "Smith",
            "fullName": "Kandie Smith",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/447/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-NC-5-upper-ocd-person-5afbb4dc-a121-414a-b600-53af0936beb8",
              "builtID": "nc-upper-5",
              "externalID": "ocd-person/5afbb4dc-a121-414a-b600-53af0936beb8",
              "geometry": null
            },
            "contactInfo": {
              "email": "kandie.smith@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/447",
                "https://www.ncleg.gov/Members/Votes/S/447",
                "https://www.ncleg.gov/Members/Committees/S/447",
                "https://www.ncleg.gov/Members/Biography/S/447"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-5-upper-ocd-person-5afbb4dc-a121-414a-b600-53af0936beb8": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/f0121b49-81cc-45f5-98e1-1fbeee882c81",
            "firstName": "Michael",
            "lastName": "Lazzara",
            "fullName": "Michael Lazzara",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/426/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-NC-6-upper-ocd-person-f0121b49-81cc-45f5-98e1-1fbeee882c81",
              "builtID": "nc-upper-6",
              "externalID": "ocd-person/f0121b49-81cc-45f5-98e1-1fbeee882c81",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.lazzara@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/426",
                "https://www.ncleg.gov/Members/Votes/S/426",
                "https://www.ncleg.gov/Members/Committees/S/426",
                "https://www.ncleg.gov/Members/Biography/S/426"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-6-upper-ocd-person-f0121b49-81cc-45f5-98e1-1fbeee882c81": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/082079ab-53d2-43e5-be5f-cb7c6054edd3",
            "firstName": "Michael Vincent",
            "lastName": "Lee",
            "fullName": "Michael Lee",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/387/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-NC-7-upper-ocd-person-082079ab-53d2-43e5-be5f-cb7c6054edd3",
              "builtID": "nc-upper-7",
              "externalID": "ocd-person/082079ab-53d2-43e5-be5f-cb7c6054edd3",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.lee@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/387",
                "https://www.ncleg.gov/Members/Votes/S/387",
                "https://www.ncleg.gov/Members/Committees/S/387",
                "https://www.ncleg.gov/Members/Biography/S/387"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-7-upper-ocd-person-082079ab-53d2-43e5-be5f-cb7c6054edd3": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/ac7e8e60-b5cb-41c2-92f9-1bdf9204f79a",
            "firstName": "William Peter",
            "lastName": "Rabon",
            "fullName": "Bill Rabon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/303/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-NC-8-upper-ocd-person-ac7e8e60-b5cb-41c2-92f9-1bdf9204f79a",
              "builtID": "nc-upper-8",
              "externalID": "ocd-person/ac7e8e60-b5cb-41c2-92f9-1bdf9204f79a",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.rabon@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/303",
                "https://www.ncleg.gov/Members/Votes/S/303",
                "https://www.ncleg.gov/Members/Committees/S/303",
                "https://www.ncleg.gov/Members/Biography/S/303"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-8-upper-ocd-person-ac7e8e60-b5cb-41c2-92f9-1bdf9204f79a": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/23f1da0b-34b2-4347-adb4-f5fc26e0475d",
            "firstName": "William Brent",
            "lastName": "Jackson",
            "fullName": "Brent Jackson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/281/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-NC-9-upper-ocd-person-23f1da0b-34b2-4347-adb4-f5fc26e0475d",
              "builtID": "nc-upper-9",
              "externalID": "ocd-person/23f1da0b-34b2-4347-adb4-f5fc26e0475d",
              "geometry": null
            },
            "contactInfo": {
              "email": "brent.jackson@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/281",
                "https://www.ncleg.gov/Members/Votes/S/281",
                "https://www.ncleg.gov/Members/Committees/S/281",
                "https://www.ncleg.gov/Members/Biography/S/281"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-9-upper-ocd-person-23f1da0b-34b2-4347-adb4-f5fc26e0475d": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/0c960acb-381f-4ce9-b9d3-313fd882b6e1",
            "firstName": "Benton G.",
            "lastName": "Sawrey",
            "fullName": "Benton Sawrey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/445/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-NC-10-upper-ocd-person-0c960acb-381f-4ce9-b9d3-313fd882b6e1",
              "builtID": "nc-upper-10",
              "externalID": "ocd-person/0c960acb-381f-4ce9-b9d3-313fd882b6e1",
              "geometry": null
            },
            "contactInfo": {
              "email": "benton.sawrey@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/445",
                "https://www.ncleg.gov/Members/Votes/S/445",
                "https://www.ncleg.gov/Members/Committees/S/445",
                "https://www.ncleg.gov/Members/Biography/S/445"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-10-upper-ocd-person-0c960acb-381f-4ce9-b9d3-313fd882b6e1": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/21577fbd-e06f-4bc3-8717-e050ff165a74",
            "firstName": "Lisa Stone",
            "lastName": "Barnes",
            "fullName": "Lisa Barnes",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/427/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-NC-11-upper-ocd-person-21577fbd-e06f-4bc3-8717-e050ff165a74",
              "builtID": "nc-upper-11",
              "externalID": "ocd-person/21577fbd-e06f-4bc3-8717-e050ff165a74",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisa.barnes@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/427",
                "https://www.ncleg.gov/Members/Votes/S/427",
                "https://www.ncleg.gov/Members/Committees/S/427",
                "https://www.ncleg.gov/Members/Biography/S/427"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-11-upper-ocd-person-21577fbd-e06f-4bc3-8717-e050ff165a74": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/9793e1e4-641c-4a5c-b1bc-7f6d0a65a655",
            "firstName": "James",
            "lastName": "Burgin",
            "fullName": "Jim Burgin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/412/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-NC-12-upper-ocd-person-9793e1e4-641c-4a5c-b1bc-7f6d0a65a655",
              "builtID": "nc-upper-12",
              "externalID": "ocd-person/9793e1e4-641c-4a5c-b1bc-7f6d0a65a655",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.burgin@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/412",
                "https://www.ncleg.gov/Members/Votes/S/412",
                "https://www.ncleg.gov/Members/Committees/S/412",
                "https://www.ncleg.gov/Members/Biography/S/412"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-12-upper-ocd-person-9793e1e4-641c-4a5c-b1bc-7f6d0a65a655": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/a370c6f1-338d-49c3-a0b4-41e885dc0d80",
            "firstName": "Lisa",
            "lastName": "Grafstein",
            "fullName": "Lisa Grafstein",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/439/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-NC-13-upper-ocd-person-a370c6f1-338d-49c3-a0b4-41e885dc0d80",
              "builtID": "nc-upper-13",
              "externalID": "ocd-person/a370c6f1-338d-49c3-a0b4-41e885dc0d80",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisa.grafstein@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/439",
                "https://www.ncleg.gov/Members/Votes/S/439",
                "https://www.ncleg.gov/Members/Committees/S/439",
                "https://www.ncleg.gov/Members/Biography/S/439"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-13-upper-ocd-person-a370c6f1-338d-49c3-a0b4-41e885dc0d80": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/06dca4de-5217-45fc-b78a-fc7870ae7faf",
            "firstName": "Daniel Terry",
            "lastName": "Blue",
            "fullName": "Dan Blue",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/268/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-NC-14-upper-ocd-person-06dca4de-5217-45fc-b78a-fc7870ae7faf",
              "builtID": "nc-upper-14",
              "externalID": "ocd-person/06dca4de-5217-45fc-b78a-fc7870ae7faf",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.blue@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/268",
                "https://www.ncleg.gov/Members/Votes/S/268",
                "https://www.ncleg.gov/Members/Committees/S/268",
                "https://www.ncleg.gov/Members/Biography/S/268"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-14-upper-ocd-person-06dca4de-5217-45fc-b78a-fc7870ae7faf": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/75860d99-3e31-4c38-b511-734f76477c9b",
            "firstName": "Jay J.",
            "lastName": "Chaudhuri",
            "fullName": "Jay Chaudhuri",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/395/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-NC-15-upper-ocd-person-75860d99-3e31-4c38-b511-734f76477c9b",
              "builtID": "nc-upper-15",
              "externalID": "ocd-person/75860d99-3e31-4c38-b511-734f76477c9b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jay.chaudhuri@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/395",
                "https://www.ncleg.gov/Members/Votes/S/395",
                "https://www.ncleg.gov/Members/Committees/S/395",
                "https://www.ncleg.gov/Members/Biography/S/395"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-15-upper-ocd-person-75860d99-3e31-4c38-b511-734f76477c9b": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/1ba9c7a6-3a28-47ec-9704-6f3437463845",
            "firstName": "Gale",
            "lastName": "Adcock",
            "fullName": "Gale Adcock",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/436/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-NC-16-upper-ocd-person-1ba9c7a6-3a28-47ec-9704-6f3437463845",
              "builtID": "nc-upper-16",
              "externalID": "ocd-person/1ba9c7a6-3a28-47ec-9704-6f3437463845",
              "geometry": null
            },
            "contactInfo": {
              "email": "gale.adcock@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/436",
                "https://www.ncleg.gov/Members/Votes/S/436",
                "https://www.ncleg.gov/Members/Committees/S/436",
                "https://www.ncleg.gov/Members/Biography/S/436"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-16-upper-ocd-person-1ba9c7a6-3a28-47ec-9704-6f3437463845": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/f29f268e-5a29-4f49-8c5e-3fa19688f4a2",
            "firstName": "Sydney Jeanene",
            "lastName": "Batch",
            "fullName": "Sydney Batch",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/434/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-NC-17-upper-ocd-person-f29f268e-5a29-4f49-8c5e-3fa19688f4a2",
              "builtID": "nc-upper-17",
              "externalID": "ocd-person/f29f268e-5a29-4f49-8c5e-3fa19688f4a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "sydney.batch@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/434",
                "https://www.ncleg.gov/Members/Votes/S/434",
                "https://www.ncleg.gov/Members/Committees/S/434",
                "https://www.ncleg.gov/Members/Biography/S/434"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-17-upper-ocd-person-f29f268e-5a29-4f49-8c5e-3fa19688f4a2": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/a5882cbf-9fdc-4aa7-b4a1-5456913c64b7",
            "firstName": "Mary Wills",
            "lastName": "Bode",
            "fullName": "Mary Wills Bode",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/441/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-NC-18-upper-ocd-person-a5882cbf-9fdc-4aa7-b4a1-5456913c64b7",
              "builtID": "nc-upper-18",
              "externalID": "ocd-person/a5882cbf-9fdc-4aa7-b4a1-5456913c64b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "marywills.bode@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/441",
                "https://www.ncleg.gov/Members/Votes/S/441",
                "https://www.ncleg.gov/Members/Committees/S/441",
                "https://www.ncleg.gov/Members/Biography/S/441"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-18-upper-ocd-person-a5882cbf-9fdc-4aa7-b4a1-5456913c64b7": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/1ba98521-ee10-414a-9cf0-8b389ad02ffe",
            "firstName": "Val",
            "lastName": "Applewhite",
            "fullName": "Val Applewhite",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/440/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-NC-19-upper-ocd-person-1ba98521-ee10-414a-9cf0-8b389ad02ffe",
              "builtID": "nc-upper-19",
              "externalID": "ocd-person/1ba98521-ee10-414a-9cf0-8b389ad02ffe",
              "geometry": null
            },
            "contactInfo": {
              "email": "val.applewhite@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/440",
                "https://www.ncleg.gov/Members/Votes/S/440",
                "https://www.ncleg.gov/Members/Committees/S/440",
                "https://www.ncleg.gov/Members/Biography/S/440"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-19-upper-ocd-person-1ba98521-ee10-414a-9cf0-8b389ad02ffe": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/121cec2b-d03f-45bc-a835-1ae5f4eb18d3",
            "firstName": "Natalie S.",
            "lastName": "Murdock",
            "fullName": "Natalie Murdock",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/422/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-NC-20-upper-ocd-person-121cec2b-d03f-45bc-a835-1ae5f4eb18d3",
              "builtID": "nc-upper-20",
              "externalID": "ocd-person/121cec2b-d03f-45bc-a835-1ae5f4eb18d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "natalie.murdock@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/422",
                "https://www.ncleg.gov/Members/Votes/S/422",
                "https://www.ncleg.gov/Members/Committees/S/422",
                "https://www.ncleg.gov/Members/Biography/S/422"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-20-upper-ocd-person-121cec2b-d03f-45bc-a835-1ae5f4eb18d3": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/81a19cd2-9493-4b85-b92b-44df8777530e",
            "firstName": "Thomas Moses",
            "lastName": "McInnis",
            "fullName": "Tom McInnis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/389/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-NC-21-upper-ocd-person-81a19cd2-9493-4b85-b92b-44df8777530e",
              "builtID": "nc-upper-21",
              "externalID": "ocd-person/81a19cd2-9493-4b85-b92b-44df8777530e",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.mcinnis@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/389",
                "https://www.ncleg.gov/Members/Votes/S/389",
                "https://www.ncleg.gov/Members/Committees/S/389",
                "https://www.ncleg.gov/Members/Biography/S/389"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-21-upper-ocd-person-81a19cd2-9493-4b85-b92b-44df8777530e": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/85528404-9f1b-411f-a361-5f418171c963",
            "firstName": "Mike",
            "lastName": "Woodard",
            "fullName": "Mike Woodard",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/379/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-NC-22-upper-ocd-person-85528404-9f1b-411f-a361-5f418171c963",
              "builtID": "nc-upper-22",
              "externalID": "ocd-person/85528404-9f1b-411f-a361-5f418171c963",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.woodard@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/379",
                "https://www.ncleg.gov/Members/Votes/S/379",
                "https://www.ncleg.gov/Members/Committees/S/379",
                "https://www.ncleg.gov/Members/Biography/S/379"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-22-upper-ocd-person-85528404-9f1b-411f-a361-5f418171c963": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/dea063a9-3548-4ec7-b226-a126d2eea0b2",
            "firstName": "Graig R.",
            "lastName": "Meyer",
            "fullName": "Graig Meyer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/437/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-NC-23-upper-ocd-person-dea063a9-3548-4ec7-b226-a126d2eea0b2",
              "builtID": "nc-upper-23",
              "externalID": "ocd-person/dea063a9-3548-4ec7-b226-a126d2eea0b2",
              "geometry": null
            },
            "contactInfo": {
              "email": "graig.meyer@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/437",
                "https://www.ncleg.gov/Members/Votes/S/437",
                "https://www.ncleg.gov/Members/Committees/S/437",
                "https://www.ncleg.gov/Members/Biography/S/437"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-23-upper-ocd-person-dea063a9-3548-4ec7-b226-a126d2eea0b2": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/91f238a6-29fd-4b65-bc48-1464da11786b",
            "firstName": "Danny Earl",
            "lastName": "Britt",
            "fullName": "Danny Britt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/399/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-NC-24-upper-ocd-person-91f238a6-29fd-4b65-bc48-1464da11786b",
              "builtID": "nc-upper-24",
              "externalID": "ocd-person/91f238a6-29fd-4b65-bc48-1464da11786b",
              "geometry": null
            },
            "contactInfo": {
              "email": "danny.britt@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/399",
                "https://www.ncleg.gov/Members/Votes/S/399",
                "https://www.ncleg.gov/Members/Committees/S/399",
                "https://www.ncleg.gov/Members/Biography/S/399"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-24-upper-ocd-person-91f238a6-29fd-4b65-bc48-1464da11786b": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/8be9deca-bbd6-404f-8726-0aecaeb19ca7",
            "firstName": "Amy S.",
            "lastName": "Galey",
            "fullName": "Amy Galey",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/429/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-NC-25-upper-ocd-person-8be9deca-bbd6-404f-8726-0aecaeb19ca7",
              "builtID": "nc-upper-25",
              "externalID": "ocd-person/8be9deca-bbd6-404f-8726-0aecaeb19ca7",
              "geometry": null
            },
            "contactInfo": {
              "email": "amy.galey@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/429",
                "https://www.ncleg.gov/Members/Votes/S/429",
                "https://www.ncleg.gov/Members/Committees/S/429",
                "https://www.ncleg.gov/Members/Biography/S/429"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-25-upper-ocd-person-8be9deca-bbd6-404f-8726-0aecaeb19ca7": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/f276e61a-c569-4924-b302-caeda17a306a",
            "firstName": "Philip Edward",
            "lastName": "Berger",
            "fullName": "Phil Berger",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/64/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-NC-26-upper-ocd-person-f276e61a-c569-4924-b302-caeda17a306a",
              "builtID": "nc-upper-26",
              "externalID": "ocd-person/f276e61a-c569-4924-b302-caeda17a306a",
              "geometry": null
            },
            "contactInfo": {
              "email": "phil.berger@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/64",
                "https://www.ncleg.gov/Members/Votes/S/64",
                "https://www.ncleg.gov/Members/Committees/S/64",
                "https://www.ncleg.gov/Members/Biography/S/64"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-26-upper-ocd-person-f276e61a-c569-4924-b302-caeda17a306a": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/f40a7222-92eb-4d8e-ad4d-7008b859aae7",
            "firstName": "Michael K.",
            "lastName": "Garrett",
            "fullName": "Michael Garrett",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/414/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-NC-27-upper-ocd-person-f40a7222-92eb-4d8e-ad4d-7008b859aae7",
              "builtID": "nc-upper-27",
              "externalID": "ocd-person/f40a7222-92eb-4d8e-ad4d-7008b859aae7",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.garrett@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/414",
                "https://www.ncleg.gov/Members/Votes/S/414",
                "https://www.ncleg.gov/Members/Committees/S/414",
                "https://www.ncleg.gov/Members/Biography/S/414"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-27-upper-ocd-person-f40a7222-92eb-4d8e-ad4d-7008b859aae7": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/af3f63da-d39a-4542-b0f2-41f118d96f55",
            "firstName": "Gladys Ashe",
            "lastName": "Robinson",
            "fullName": "Gladys Robinson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/364/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-NC-28-upper-ocd-person-af3f63da-d39a-4542-b0f2-41f118d96f55",
              "builtID": "nc-upper-28",
              "externalID": "ocd-person/af3f63da-d39a-4542-b0f2-41f118d96f55",
              "geometry": null
            },
            "contactInfo": {
              "email": "gladys.robinson@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/364",
                "https://www.ncleg.gov/Members/Votes/S/364",
                "https://www.ncleg.gov/Members/Committees/S/364",
                "https://www.ncleg.gov/Members/Biography/S/364"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-28-upper-ocd-person-af3f63da-d39a-4542-b0f2-41f118d96f55": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/7074c9f6-6a8e-4c63-b0b3-ae43b9cfba0d",
            "firstName": "David W.",
            "lastName": "Craven",
            "fullName": "Dave Craven",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/423/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-NC-29-upper-ocd-person-7074c9f6-6a8e-4c63-b0b3-ae43b9cfba0d",
              "builtID": "nc-upper-29",
              "externalID": "ocd-person/7074c9f6-6a8e-4c63-b0b3-ae43b9cfba0d",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.craven@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/423",
                "https://www.ncleg.gov/Members/Votes/S/423",
                "https://www.ncleg.gov/Members/Committees/S/423",
                "https://www.ncleg.gov/Members/Biography/S/423"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-29-upper-ocd-person-7074c9f6-6a8e-4c63-b0b3-ae43b9cfba0d": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/d0af18d6-b4e1-4a9a-92f3-a6950961e953",
            "firstName": "Steven Henry",
            "lastName": "Jarvis",
            "fullName": "Steve Jarvis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/430/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-NC-30-upper-ocd-person-d0af18d6-b4e1-4a9a-92f3-a6950961e953",
              "builtID": "nc-upper-30",
              "externalID": "ocd-person/d0af18d6-b4e1-4a9a-92f3-a6950961e953",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.jarvis@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/430",
                "https://www.ncleg.gov/Members/Votes/S/430",
                "https://www.ncleg.gov/Members/Committees/S/430",
                "https://www.ncleg.gov/Members/Biography/S/430"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-30-upper-ocd-person-d0af18d6-b4e1-4a9a-92f3-a6950961e953": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/1ec07f84-67fb-4fff-8a52-b16d5efd7546",
            "firstName": "Joyce",
            "lastName": "Krawiec",
            "fullName": "Joyce Krawiec",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/384/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-NC-31-upper-ocd-person-1ec07f84-67fb-4fff-8a52-b16d5efd7546",
              "builtID": "nc-upper-31",
              "externalID": "ocd-person/1ec07f84-67fb-4fff-8a52-b16d5efd7546",
              "geometry": null
            },
            "contactInfo": {
              "email": "joyce.krawiec@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/384",
                "https://www.ncleg.gov/Members/Votes/S/384",
                "https://www.ncleg.gov/Members/Committees/S/384",
                "https://www.ncleg.gov/Members/Biography/S/384"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-31-upper-ocd-person-1ec07f84-67fb-4fff-8a52-b16d5efd7546": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/a73e339a-9724-45a5-9ebd-f1ca506374a0",
            "firstName": "Paul A.",
            "lastName": "Lowe",
            "fullName": "Paul Lowe",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/394/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-NC-32-upper-ocd-person-a73e339a-9724-45a5-9ebd-f1ca506374a0",
              "builtID": "nc-upper-32",
              "externalID": "ocd-person/a73e339a-9724-45a5-9ebd-f1ca506374a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.lowe@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/394",
                "https://www.ncleg.gov/Members/Votes/S/394",
                "https://www.ncleg.gov/Members/Committees/S/394",
                "https://www.ncleg.gov/Members/Biography/S/394"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-32-upper-ocd-person-a73e339a-9724-45a5-9ebd-f1ca506374a0": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/91615553-5509-4625-ab20-7a81896438e0",
            "firstName": "Carl Lindsey",
            "lastName": "Ford",
            "fullName": "Carl Ford",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/410/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-NC-33-upper-ocd-person-91615553-5509-4625-ab20-7a81896438e0",
              "builtID": "nc-upper-33",
              "externalID": "ocd-person/91615553-5509-4625-ab20-7a81896438e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "carl.ford@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/410",
                "https://www.ncleg.gov/Members/Votes/S/410",
                "https://www.ncleg.gov/Members/Committees/S/410",
                "https://www.ncleg.gov/Members/Biography/S/410"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-33-upper-ocd-person-91615553-5509-4625-ab20-7a81896438e0": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/eba22231-5996-435e-b9a8-453a234d9949",
            "firstName": "Paul R.",
            "lastName": "Newton",
            "fullName": "Paul Newton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/402/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-NC-34-upper-ocd-person-eba22231-5996-435e-b9a8-453a234d9949",
              "builtID": "nc-upper-34",
              "externalID": "ocd-person/eba22231-5996-435e-b9a8-453a234d9949",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.newton@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/402",
                "https://www.ncleg.gov/Members/Votes/S/402",
                "https://www.ncleg.gov/Members/Committees/S/402",
                "https://www.ncleg.gov/Members/Biography/S/402"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-34-upper-ocd-person-eba22231-5996-435e-b9a8-453a234d9949": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/37e615d5-0c02-488c-83e2-e6e6df5b8184",
            "firstName": "Todd",
            "lastName": "Johnson",
            "fullName": "Todd Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/406/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-NC-35-upper-ocd-person-37e615d5-0c02-488c-83e2-e6e6df5b8184",
              "builtID": "nc-upper-35",
              "externalID": "ocd-person/37e615d5-0c02-488c-83e2-e6e6df5b8184",
              "geometry": null
            },
            "contactInfo": {
              "email": "todd.johnson@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/406",
                "https://www.ncleg.gov/Members/Votes/S/406",
                "https://www.ncleg.gov/Members/Committees/S/406",
                "https://www.ncleg.gov/Members/Biography/S/406"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-35-upper-ocd-person-37e615d5-0c02-488c-83e2-e6e6df5b8184": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/b4b6f0a7-baca-4062-947a-989cb9f98356",
            "firstName": "Eddie D.",
            "lastName": "Settle",
            "fullName": "Eddie Settle",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/446/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-NC-36-upper-ocd-person-b4b6f0a7-baca-4062-947a-989cb9f98356",
              "builtID": "nc-upper-36",
              "externalID": "ocd-person/b4b6f0a7-baca-4062-947a-989cb9f98356",
              "geometry": null
            },
            "contactInfo": {
              "email": "eddie.settle@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/446",
                "https://www.ncleg.gov/Members/Votes/S/446",
                "https://www.ncleg.gov/Members/Committees/S/446",
                "https://www.ncleg.gov/Members/Biography/S/446"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-36-upper-ocd-person-b4b6f0a7-baca-4062-947a-989cb9f98356": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/ece1f23b-841d-4145-9719-3807bf29f861",
            "firstName": "Victoria B.",
            "lastName": "Sawyer",
            "fullName": "Vickie Sawyer",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/405/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-NC-37-upper-ocd-person-ece1f23b-841d-4145-9719-3807bf29f861",
              "builtID": "nc-upper-37",
              "externalID": "ocd-person/ece1f23b-841d-4145-9719-3807bf29f861",
              "geometry": null
            },
            "contactInfo": {
              "email": "vickie.sawyer@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/405",
                "https://www.ncleg.gov/Members/Votes/S/405",
                "https://www.ncleg.gov/Members/Committees/S/405",
                "https://www.ncleg.gov/Members/Biography/S/405"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-37-upper-ocd-person-ece1f23b-841d-4145-9719-3807bf29f861": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/c733e47e-af6a-4f31-a350-010043b5164b",
            "firstName": "Mujtaba A.",
            "lastName": "Mohammed",
            "fullName": "Mujtaba Mohammed",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/417/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-NC-38-upper-ocd-person-c733e47e-af6a-4f31-a350-010043b5164b",
              "builtID": "nc-upper-38",
              "externalID": "ocd-person/c733e47e-af6a-4f31-a350-010043b5164b",
              "geometry": null
            },
            "contactInfo": {
              "email": "mujtaba.mohammed@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/417",
                "https://www.ncleg.gov/Members/Votes/S/417",
                "https://www.ncleg.gov/Members/Committees/S/417",
                "https://www.ncleg.gov/Members/Biography/S/417"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-38-upper-ocd-person-c733e47e-af6a-4f31-a350-010043b5164b": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/1c0d5d12-7bc0-4296-a148-423e4faa7dae",
            "firstName": "DeAndrea Newman",
            "lastName": "Salvador",
            "fullName": "DeAndrea Salvador",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/431/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-NC-39-upper-ocd-person-1c0d5d12-7bc0-4296-a148-423e4faa7dae",
              "builtID": "nc-upper-39",
              "externalID": "ocd-person/1c0d5d12-7bc0-4296-a148-423e4faa7dae",
              "geometry": null
            },
            "contactInfo": {
              "email": "deandrea.salvador@ncleg.go",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/431",
                "https://www.ncleg.gov/Members/Votes/S/431",
                "https://www.ncleg.gov/Members/Committees/S/431",
                "https://www.ncleg.gov/Members/Biography/S/431"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-39-upper-ocd-person-1c0d5d12-7bc0-4296-a148-423e4faa7dae": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/3e8067e6-6232-4ebb-979c-d077ab968446",
            "firstName": "Joyce Davis",
            "lastName": "Waddell",
            "fullName": "Joyce Waddell",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/393/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-NC-40-upper-ocd-person-3e8067e6-6232-4ebb-979c-d077ab968446",
              "builtID": "nc-upper-40",
              "externalID": "ocd-person/3e8067e6-6232-4ebb-979c-d077ab968446",
              "geometry": null
            },
            "contactInfo": {
              "email": "joyce.waddell@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/393",
                "https://www.ncleg.gov/Members/Votes/S/393",
                "https://www.ncleg.gov/Members/Committees/S/393",
                "https://www.ncleg.gov/Members/Biography/S/393"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-40-upper-ocd-person-3e8067e6-6232-4ebb-979c-d077ab968446": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/e58e14c4-5612-4d57-b08e-1596b82caaec",
            "firstName": "Natasha R.",
            "lastName": "Marcus",
            "fullName": "Natasha Marcus",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/411/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-NC-41-upper-ocd-person-e58e14c4-5612-4d57-b08e-1596b82caaec",
              "builtID": "nc-upper-41",
              "externalID": "ocd-person/e58e14c4-5612-4d57-b08e-1596b82caaec",
              "geometry": null
            },
            "contactInfo": {
              "email": "natasha.marcus@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/411",
                "https://www.ncleg.gov/Members/Votes/S/411",
                "https://www.ncleg.gov/Members/Committees/S/411",
                "https://www.ncleg.gov/Members/Biography/S/411"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-41-upper-ocd-person-e58e14c4-5612-4d57-b08e-1596b82caaec": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/4c3a1120-2dd5-4fbe-a084-e64894e57163",
            "firstName": "Rachel Henderson",
            "lastName": "Hunt",
            "fullName": "Rachel Hunt",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/442/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-NC-42-upper-ocd-person-4c3a1120-2dd5-4fbe-a084-e64894e57163",
              "builtID": "nc-upper-42",
              "externalID": "ocd-person/4c3a1120-2dd5-4fbe-a084-e64894e57163",
              "geometry": null
            },
            "contactInfo": {
              "email": "rachel.hunt@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/442",
                "https://www.ncleg.gov/Members/Votes/S/442",
                "https://www.ncleg.gov/Members/Committees/S/442",
                "https://www.ncleg.gov/Members/Biography/S/442"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-42-upper-ocd-person-4c3a1120-2dd5-4fbe-a084-e64894e57163": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/dfacde1f-3324-4ebe-bc96-ae473548e951",
            "firstName": "Brad",
            "lastName": "Overcash",
            "fullName": "Brad Overcash",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/444/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "43",
              "chamber": "upper",
              "hashID": "SLDU-NC-43-upper-ocd-person-dfacde1f-3324-4ebe-bc96-ae473548e951",
              "builtID": "nc-upper-43",
              "externalID": "ocd-person/dfacde1f-3324-4ebe-bc96-ae473548e951",
              "geometry": null
            },
            "contactInfo": {
              "email": "brad.overcash@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/444",
                "https://www.ncleg.gov/Members/Votes/S/444",
                "https://www.ncleg.gov/Members/Committees/S/444",
                "https://www.ncleg.gov/Members/Biography/S/444"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-43-upper-ocd-person-dfacde1f-3324-4ebe-bc96-ae473548e951": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/fe39f817-2024-401d-a0a8-3818ca22bdb1",
            "firstName": "W. Ted",
            "lastName": "Alexander",
            "fullName": "Ted Alexander",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/416/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "44",
              "chamber": "upper",
              "hashID": "SLDU-NC-44-upper-ocd-person-fe39f817-2024-401d-a0a8-3818ca22bdb1",
              "builtID": "nc-upper-44",
              "externalID": "ocd-person/fe39f817-2024-401d-a0a8-3818ca22bdb1",
              "geometry": null
            },
            "contactInfo": {
              "email": "ted.alexander@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/416",
                "https://www.ncleg.gov/Members/Votes/S/416",
                "https://www.ncleg.gov/Members/Committees/S/416",
                "https://www.ncleg.gov/Members/Biography/S/416"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-44-upper-ocd-person-fe39f817-2024-401d-a0a8-3818ca22bdb1": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/f02f17f5-e795-4950-a181-4dddfbe33dab",
            "firstName": "H. Dean",
            "lastName": "Proctor",
            "fullName": "Dean Proctor",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/424/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "45",
              "chamber": "upper",
              "hashID": "SLDU-NC-45-upper-ocd-person-f02f17f5-e795-4950-a181-4dddfbe33dab",
              "builtID": "nc-upper-45",
              "externalID": "ocd-person/f02f17f5-e795-4950-a181-4dddfbe33dab",
              "geometry": null
            },
            "contactInfo": {
              "email": "dean.proctor@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/424",
                "https://www.ncleg.gov/Members/Votes/S/424",
                "https://www.ncleg.gov/Members/Committees/S/424",
                "https://www.ncleg.gov/Members/Biography/S/424"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-45-upper-ocd-person-f02f17f5-e795-4950-a181-4dddfbe33dab": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/5397305f-16ba-460d-aa16-3bee6185299b",
            "firstName": "Warren",
            "lastName": "Daniel",
            "fullName": "Warren Daniel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/295/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "46",
              "chamber": "upper",
              "hashID": "SLDU-NC-46-upper-ocd-person-5397305f-16ba-460d-aa16-3bee6185299b",
              "builtID": "nc-upper-46",
              "externalID": "ocd-person/5397305f-16ba-460d-aa16-3bee6185299b",
              "geometry": null
            },
            "contactInfo": {
              "email": "warren.daniel@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/295",
                "https://www.ncleg.gov/Members/Votes/S/295",
                "https://www.ncleg.gov/Members/Committees/S/295",
                "https://www.ncleg.gov/Members/Biography/S/295"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-46-upper-ocd-person-5397305f-16ba-460d-aa16-3bee6185299b": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/25828c07-8295-45af-bd01-b044b59d530a",
            "firstName": "Ralph E.",
            "lastName": "Hise",
            "fullName": "Ralph Hise",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/298/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "47",
              "chamber": "upper",
              "hashID": "SLDU-NC-47-upper-ocd-person-25828c07-8295-45af-bd01-b044b59d530a",
              "builtID": "nc-upper-47",
              "externalID": "ocd-person/25828c07-8295-45af-bd01-b044b59d530a",
              "geometry": null
            },
            "contactInfo": {
              "email": "ralph.hise@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/298",
                "https://www.ncleg.gov/Members/Votes/S/298",
                "https://www.ncleg.gov/Members/Committees/S/298",
                "https://www.ncleg.gov/Members/Biography/S/298"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-47-upper-ocd-person-25828c07-8295-45af-bd01-b044b59d530a": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/20f45197-ec0c-4031-a889-2825bffb008a",
            "firstName": "Timothy Douglas",
            "lastName": "Moffitt",
            "fullName": "Tim Moffitt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/438/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "48",
              "chamber": "upper",
              "hashID": "SLDU-NC-48-upper-ocd-person-20f45197-ec0c-4031-a889-2825bffb008a",
              "builtID": "nc-upper-48",
              "externalID": "ocd-person/20f45197-ec0c-4031-a889-2825bffb008a",
              "geometry": null
            },
            "contactInfo": {
              "email": "tim.moffitt@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/Biography/H/623",
                "https://www.ncleg.gov/Members/IntroducedBills/H/623",
                "https://www.ncleg.gov/Members/Votes/H/623",
                "https://www.ncleg.gov/Members/Committees/H/623",
                "https://www.ncleg.gov/Members/IntroducedBills/S/438",
                "https://www.ncleg.gov/Members/Votes/S/438",
                "https://www.ncleg.gov/Members/Committees/S/438",
                "https://www.ncleg.gov/Members/Biography/S/438"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-48-upper-ocd-person-20f45197-ec0c-4031-a889-2825bffb008a": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/c8ac71ee-e3dc-450f-83f7-efeb363e88bc",
            "firstName": "Julie",
            "lastName": "Mayfield",
            "fullName": "Julie Mayfield",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/432/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "49",
              "chamber": "upper",
              "hashID": "SLDU-NC-49-upper-ocd-person-c8ac71ee-e3dc-450f-83f7-efeb363e88bc",
              "builtID": "nc-upper-49",
              "externalID": "ocd-person/c8ac71ee-e3dc-450f-83f7-efeb363e88bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "julie.mayfield@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/432",
                "https://www.ncleg.gov/Members/Votes/S/432",
                "https://www.ncleg.gov/Members/Committees/S/432",
                "https://www.ncleg.gov/Members/Biography/S/432"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-49-upper-ocd-person-c8ac71ee-e3dc-450f-83f7-efeb363e88bc": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/410836ee-4f48-4934-8f95-fca6b2d2f051",
            "firstName": "Harold Kevin",
            "lastName": "Corbin",
            "fullName": "Kevin Corbin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ncleg.gov/Members/MemberImage/S/433/Low",
            "title": "NC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "NC",
              "stateFull": "North Carolina",
              "district": "50",
              "chamber": "upper",
              "hashID": "SLDU-NC-50-upper-ocd-person-410836ee-4f48-4934-8f95-fca6b2d2f051",
              "builtID": "nc-upper-50",
              "externalID": "ocd-person/410836ee-4f48-4934-8f95-fca6b2d2f051",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.corbin@ncleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ncleg.gov/Members/IntroducedBills/S/433",
                "https://www.ncleg.gov/Members/Votes/S/433",
                "https://www.ncleg.gov/Members/Committees/S/433",
                "https://www.ncleg.gov/Members/Biography/S/433"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-NC-50-upper-ocd-person-410836ee-4f48-4934-8f95-fca6b2d2f051": 0
        }
      }
    }
  },
  "ND": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "ND",
          "stateFull": "North Dakota",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "ND",
            "stateFull": "North Dakota",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-ND---",
            "builtID": "nd--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-ND---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "C001096",
          "in_office": true,
          "firstName": "Kevin",
          "lastName": "Cramer",
          "middleName": null,
          "fullName": "Kevin Cramer",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/C001096.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "ND",
            "stateFull": "North Dakota",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-ND---C001096",
            "builtID": "nd--",
            "externalID": "C001096",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.cramer.senate.gov/contact/contact-kevin",
            "address1": "330 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-2043",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenKevinCramer",
            "youtube": "kevincramer",
            "instagram": null,
            "facebook": "CongressmanKevinCramer",
            "urls": ["https://www.cramer.senate.gov"],
            "rss_url": null
          },
          "title": "ND Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/C001096.json",
          "govtrack_id": "412555",
          "cspan_id": "7600",
          "votesmart_id": "444",
          "icpsr_id": "21350",
          "crp_id": "N00004614",
          "google_entity_id": "/m/02rhrnw",
          "state_rank": "junior",
          "lis_id": "S398",
          "suffix": null,
          "date_of_birth": "1961-01-21",
          "leadership_role": null,
          "fec_candidate_id": "H0ND01026",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "5",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 55,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 9.93,
          "votes_with_party_pct": 91.02,
          "votes_against_party_pct": 8.98,
          "ocd_id": "ocd-division/country:us/state:nd"
        },
        {
          "API_ID": "H001061",
          "in_office": true,
          "firstName": "John",
          "lastName": "Hoeven",
          "middleName": null,
          "fullName": "John Hoeven",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/H001061.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "ND",
            "stateFull": "North Dakota",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-ND---H001061",
            "builtID": "nd--",
            "externalID": "H001061",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenJohnHoeven",
            "youtube": "senatorjohnhoevennd",
            "instagram": null,
            "facebook": "SenatorJohnHoeven",
            "urls": ["https://www.hoeven.senate.gov"],
            "rss_url": null
          },
          "title": "ND Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/H001061.json",
          "govtrack_id": "412494",
          "cspan_id": "85233",
          "votesmart_id": "41788",
          "icpsr_id": "41107",
          "crp_id": "N00031688",
          "google_entity_id": "/m/01qb45",
          "state_rank": "senior",
          "lis_id": "S344",
          "suffix": null,
          "date_of_birth": "1957-03-13",
          "leadership_role": null,
          "fec_candidate_id": "S0ND00093",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "13",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 16,
          "total_present": 0,
          "last_updated": "2024-06-20 15:30:29 -0400",
          "missed_votes_pct": 2.89,
          "votes_with_party_pct": 91.75,
          "votes_against_party_pct": 8.25,
          "ocd_id": "ocd-division/country:us/state:nd"
        }
      ],
      "hash": { "SENATE-ND---C001096": 0, "SENATE-ND---H001061": 1 }
    },
    "HOUSE": {
      "00": {
        "members": [
          {
            "API_ID": "A000377",
            "in_office": true,
            "firstName": "Kelly",
            "lastName": "Armstrong",
            "middleName": null,
            "fullName": "Kelly Armstrong",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/A000377.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "00",
              "chamber": "",
              "hashID": "HOUSE-ND-00--A000377",
              "builtID": "nd--00",
              "externalID": "A000377",
              "geometry": null,
              "geoid": "3800"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2235 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2611",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepArmstrongND",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://armstrong.house.gov"],
              "rss_url": null
            },
            "title": "ND House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/A000377.json",
            "govtrack_id": "412794",
            "cspan_id": null,
            "votesmart_id": "139338",
            "icpsr_id": null,
            "crp_id": "N00042868",
            "google_entity_id": "/g/11hcszksh3",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-10-08",
            "leadership_role": null,
            "fec_candidate_id": "H8ND00096",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 23,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.18,
            "votes_with_party_pct": 91.7,
            "votes_against_party_pct": 6.32,
            "ocd_id": "ocd-division/country:us/state:nd/cd:1",
            "at_large": true
          }
        ],
        "hash": { "HOUSE-ND-00--A000377": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/633a61c8-9424-445f-b844-b0b40fc1781a",
            "firstName": "David",
            "lastName": "Richter",
            "fullName": "David Richter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/dwrichter2023.jpg?itok=QhmK8RaZ",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-ND-1-lower-ocd-person-633a61c8-9424-445f-b844-b0b40fc1781a",
              "builtID": "nd-lower-1",
              "externalID": "ocd-person/633a61c8-9424-445f-b844-b0b40fc1781a",
              "geometry": null
            },
            "contactInfo": {
              "email": "dwrichter@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-david-richter",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-david-richter",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-david-richter",
                "https://www.ndlegis.gov/biography/david-richter",
                "https://ndlegis.gov/biography/david-richter"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8fd64fab-cbe4-4ad3-bd63-447cc527e567",
            "firstName": "Patrick R.",
            "lastName": "Hatlestad",
            "fullName": "Patrick Hatlestad",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/phatlestad2023.jpg?itok=InESB1Wt",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-ND-1-lower-ocd-person-8fd64fab-cbe4-4ad3-bd63-447cc527e567",
              "builtID": "nd-lower-1",
              "externalID": "ocd-person/8fd64fab-cbe4-4ad3-bd63-447cc527e567",
              "geometry": null
            },
            "contactInfo": {
              "email": "phatlestad@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-patrick-hatlestad",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-patrick-hatlestad",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-patrick-hatlestad",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-patrick-hatlestad",
                "https://www.ndlegis.gov/biography/patrick-hatlestad",
                "https://ndlegis.gov/biography/patrick-hatlestad"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-1-lower-ocd-person-633a61c8-9424-445f-b844-b0b40fc1781a": 0,
          "SLDL-ND-1-lower-ocd-person-8fd64fab-cbe4-4ad3-bd63-447cc527e567": 1
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/7ba931d2-cba2-4913-92fa-f5b0c3a8baf0",
            "firstName": "Bert",
            "lastName": "Anderson",
            "fullName": "Bert Anderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/bertanderson2.jpg?itok=VRfZY6e1",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-ND-2-lower-ocd-person-7ba931d2-cba2-4913-92fa-f5b0c3a8baf0",
              "builtID": "nd-lower-2",
              "externalID": "ocd-person/7ba931d2-cba2-4913-92fa-f5b0c3a8baf0",
              "geometry": null
            },
            "contactInfo": {
              "email": "bertanderson@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-bert-anderson",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-bert-anderson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-bert-anderson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-bert-anderson",
                "https://www.ndlegis.gov/biography/bert-anderson",
                "https://ndlegis.gov/biography/bert-anderson"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/da7774ec-8498-4094-b7d7-87d301911015",
            "firstName": "Donald W.",
            "lastName": "Longmuir",
            "fullName": "Donald Longmuir",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/dlongmuir2023.jpg?itok=p9sLxmtn",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-ND-2-lower-ocd-person-da7774ec-8498-4094-b7d7-87d301911015",
              "builtID": "nd-lower-2",
              "externalID": "ocd-person/da7774ec-8498-4094-b7d7-87d301911015",
              "geometry": null
            },
            "contactInfo": {
              "email": "dlongmuir@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-donald-w-longmuir",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-donald-w-longmuir",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-donald-longmuir",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-donald-longmuir",
                "https://www.ndlegis.gov/biography/donald-w-longmuir",
                "https://ndlegis.gov/biography/donald-w-longmuir"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-2-lower-ocd-person-7ba931d2-cba2-4913-92fa-f5b0c3a8baf0": 0,
          "SLDL-ND-2-lower-ocd-person-da7774ec-8498-4094-b7d7-87d301911015": 1
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/4c53491d-adf0-4c18-a645-a63667f2c828",
            "firstName": "Jeff A.",
            "lastName": "Hoverson",
            "fullName": "Jeff Hoverson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jahoverson2023.jpg?itok=MxnKpSrt",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-ND-3-lower-ocd-person-4c53491d-adf0-4c18-a645-a63667f2c828",
              "builtID": "nd-lower-3",
              "externalID": "ocd-person/4c53491d-adf0-4c18-a645-a63667f2c828",
              "geometry": null
            },
            "contactInfo": {
              "email": "jahoverson@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-jeff-a-hoverson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-jeff-a-hoverson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-jeff-a-hoverson",
                "https://www.ndlegis.gov/biography/jeff-a-hoverson",
                "https://ndlegis.gov/biography/jeff-a-hoverson"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f1c4c314-4fe7-4aa1-b243-d075dce4444d",
            "firstName": "Lori",
            "lastName": "VanWinkle",
            "fullName": "Lori VanWinkle",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/lvanwinkle2023.jpg?itok=hakf2K65",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-ND-3-lower-ocd-person-f1c4c314-4fe7-4aa1-b243-d075dce4444d",
              "builtID": "nd-lower-3",
              "externalID": "ocd-person/f1c4c314-4fe7-4aa1-b243-d075dce4444d",
              "geometry": null
            },
            "contactInfo": {
              "email": "lvanwinkle@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/lori-vanwinkle",
                "https://ndlegis.gov/biography/lori-vanwinkle"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-3-lower-ocd-person-4c53491d-adf0-4c18-a645-a63667f2c828": 0,
          "SLDL-ND-3-lower-ocd-person-f1c4c314-4fe7-4aa1-b243-d075dce4444d": 1
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/c141f4ae-fef0-4dfa-8746-81c116e313c1",
            "firstName": "Jay",
            "lastName": "Fisher",
            "fullName": "Jay Fisher",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jayfisher2023.jpg?itok=YRt8TPe_",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-ND-5-lower-ocd-person-c141f4ae-fef0-4dfa-8746-81c116e313c1",
              "builtID": "nd-lower-5",
              "externalID": "ocd-person/c141f4ae-fef0-4dfa-8746-81c116e313c1",
              "geometry": null
            },
            "contactInfo": {
              "email": "jayfisher@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-jay-fisher",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-jay-fisher",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-jay-fisher",
                "https://www.ndlegis.gov/biography/jay-fisher",
                "https://ndlegis.gov/biography/jay-fisher"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/3a13b8c4-9366-4af8-b137-d3f959572a2a",
            "firstName": "Scott",
            "lastName": "Louser",
            "fullName": "Scott Louser",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/slouser2023.jpg?itok=8ryzbmHB",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-ND-5-lower-ocd-person-3a13b8c4-9366-4af8-b137-d3f959572a2a",
              "builtID": "nd-lower-5",
              "externalID": "ocd-person/3a13b8c4-9366-4af8-b137-d3f959572a2a",
              "geometry": null
            },
            "contactInfo": {
              "email": "slouser@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-scott-louser",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-scott-louser",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-scott-louser",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-scott-louser",
                "https://www.ndlegis.gov/biography/scott-louser",
                "https://ndlegis.gov/biography/scott-louser"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-5-lower-ocd-person-c141f4ae-fef0-4dfa-8746-81c116e313c1": 0,
          "SLDL-ND-5-lower-ocd-person-3a13b8c4-9366-4af8-b137-d3f959572a2a": 1
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/65381298-5fe3-49d8-9739-8678016f8383",
            "firstName": "Dick",
            "lastName": "Anderson",
            "fullName": "Dick Anderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/dickanderson2023.jpg?itok=5dz8kY0I",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-ND-6-lower-ocd-person-65381298-5fe3-49d8-9739-8678016f8383",
              "builtID": "nd-lower-6",
              "externalID": "ocd-person/65381298-5fe3-49d8-9739-8678016f8383",
              "geometry": null
            },
            "contactInfo": {
              "email": "dickanderson@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-dick-anderson",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-dick-anderson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-dick-anderson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-dick-anderson",
                "https://www.ndlegis.gov/biography/dick-anderson",
                "https://ndlegis.gov/biography/dick-anderson"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/960f301d-59c2-4047-96ca-8f026c7cc096",
            "firstName": "Paul J.",
            "lastName": "Thomas",
            "fullName": "Paul Thomas",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/paulthomas2023.jpg?itok=B81Ruz_Z",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-ND-6-lower-ocd-person-960f301d-59c2-4047-96ca-8f026c7cc096",
              "builtID": "nd-lower-6",
              "externalID": "ocd-person/960f301d-59c2-4047-96ca-8f026c7cc096",
              "geometry": null
            },
            "contactInfo": {
              "email": "paulthomas@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-paul-j-thomas",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-paul-j-thomas",
                "https://www.ndlegis.gov/biography/paul-j-thomas",
                "https://ndlegis.gov/biography/paul-j-thomas"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-6-lower-ocd-person-65381298-5fe3-49d8-9739-8678016f8383": 0,
          "SLDL-ND-6-lower-ocd-person-960f301d-59c2-4047-96ca-8f026c7cc096": 1
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/0b48f460-f41c-4f21-8620-c64728604e4c",
            "firstName": "Jason",
            "lastName": "Dockter",
            "fullName": "Jason Dockter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jddockter2023.jpg?itok=N9cIfPUY",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-ND-7-lower-ocd-person-0b48f460-f41c-4f21-8620-c64728604e4c",
              "builtID": "nd-lower-7",
              "externalID": "ocd-person/0b48f460-f41c-4f21-8620-c64728604e4c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jddockter@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-jason-dockter",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-jason-dockter",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-jason-dockter",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-jason-dockter",
                "https://www.ndlegis.gov/biography/jason-dockter",
                "https://ndlegis.gov/biography/jason-dockter"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/27436563-29f4-4508-be92-0adf91ea442a",
            "firstName": "Matthew",
            "lastName": "Heilman",
            "fullName": "Matt Heilman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/mheilman2023.jpg?itok=f63CHk7-",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-ND-7-lower-ocd-person-27436563-29f4-4508-be92-0adf91ea442a",
              "builtID": "nd-lower-7",
              "externalID": "ocd-person/27436563-29f4-4508-be92-0adf91ea442a",
              "geometry": null
            },
            "contactInfo": {
              "email": "mheilman@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/matt-heilman",
                "https://ndlegis.gov/biography/matt-heilman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-7-lower-ocd-person-0b48f460-f41c-4f21-8620-c64728604e4c": 0,
          "SLDL-ND-7-lower-ocd-person-27436563-29f4-4508-be92-0adf91ea442a": 1
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/c84e52de-dbe4-4677-af42-0cbe172f64b1",
            "firstName": "Brandon T.",
            "lastName": "Prichard",
            "fullName": "Brandon Prichard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/bprichard2023_3.jpg?itok=GTlhX_v_",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-ND-8-lower-ocd-person-c84e52de-dbe4-4677-af42-0cbe172f64b1",
              "builtID": "nd-lower-8",
              "externalID": "ocd-person/c84e52de-dbe4-4677-af42-0cbe172f64b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "bprichard@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/brandon-prichard",
                "https://ndlegis.gov/biography/brandon-prichard"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e3f46d13-b49b-4fd9-a604-16ad12393a71",
            "firstName": "SuAnn",
            "lastName": "Olson",
            "fullName": "SuAnn Olson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/solson2023.jpg?itok=DQSnHPuC",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-ND-8-lower-ocd-person-e3f46d13-b49b-4fd9-a604-16ad12393a71",
              "builtID": "nd-lower-8",
              "externalID": "ocd-person/e3f46d13-b49b-4fd9-a604-16ad12393a71",
              "geometry": null
            },
            "contactInfo": {
              "email": "solson@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/suann-olson",
                "https://ndlegis.gov/biography/suann-olson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-8-lower-ocd-person-c84e52de-dbe4-4677-af42-0cbe172f64b1": 0,
          "SLDL-ND-8-lower-ocd-person-e3f46d13-b49b-4fd9-a604-16ad12393a71": 1
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/79408425-175b-403f-855a-736c82d420c1",
            "firstName": "Hamida",
            "lastName": "Dakane",
            "fullName": "Hamida Dakane",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/hdakane2023.jpg?itok=iOmqmdL6",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-ND-10-lower-ocd-person-79408425-175b-403f-855a-736c82d420c1",
              "builtID": "nd-lower-10",
              "externalID": "ocd-person/79408425-175b-403f-855a-736c82d420c1",
              "geometry": null
            },
            "contactInfo": {
              "email": "hdakane@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/hamida-dakane",
                "https://ndlegis.gov/biography/hamida-dakane"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/705643fb-7605-4c42-9d55-6e774edf9fc6",
            "firstName": "Steve",
            "lastName": "Swiontek",
            "fullName": "Steve Swiontek",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/sswiontek2023.jpg?itok=yRicbbMk",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-ND-10-lower-ocd-person-705643fb-7605-4c42-9d55-6e774edf9fc6",
              "builtID": "nd-lower-10",
              "externalID": "ocd-person/705643fb-7605-4c42-9d55-6e774edf9fc6",
              "geometry": null
            },
            "contactInfo": {
              "email": "sswiontek@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/steve-swiontek",
                "https://ndlegis.gov/biography/steve-swiontek"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-10-lower-ocd-person-79408425-175b-403f-855a-736c82d420c1": 0,
          "SLDL-ND-10-lower-ocd-person-705643fb-7605-4c42-9d55-6e774edf9fc6": 1
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/752ba791-ab73-4945-b632-64fcae50c118",
            "firstName": "Gretchen",
            "lastName": "Dobervich",
            "fullName": "Gretchen Dobervich",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/gdobervich2023.jpg?itok=zDWXbvGB",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-ND-11-lower-ocd-person-752ba791-ab73-4945-b632-64fcae50c118",
              "builtID": "nd-lower-11",
              "externalID": "ocd-person/752ba791-ab73-4945-b632-64fcae50c118",
              "geometry": null
            },
            "contactInfo": {
              "email": "gdobervich@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-gretchen-dobervich",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-gretchen-dobervich",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-gretchen-dobervich",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-gretchen-dobervich",
                "https://www.ndlegis.gov/biography/gretchen-dobervich",
                "https://ndlegis.gov/biography/gretchen-dobervich"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d8e43158-838e-40fc-a960-c29c46aa512e",
            "firstName": "Liz",
            "lastName": "Conmy",
            "fullName": "Liz Conmy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/lconmy2023.jpg?itok=4p3lHbz-",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-ND-11-lower-ocd-person-d8e43158-838e-40fc-a960-c29c46aa512e",
              "builtID": "nd-lower-11",
              "externalID": "ocd-person/d8e43158-838e-40fc-a960-c29c46aa512e",
              "geometry": null
            },
            "contactInfo": {
              "email": "lconmy@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/liz-conmy",
                "https://ndlegis.gov/biography/liz-conmy"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-11-lower-ocd-person-752ba791-ab73-4945-b632-64fcae50c118": 0,
          "SLDL-ND-11-lower-ocd-person-d8e43158-838e-40fc-a960-c29c46aa512e": 1
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/3f885f7e-14b0-4ee7-b1fd-71ddbd72c539",
            "firstName": "Bernie",
            "lastName": "Satrom",
            "fullName": "Bernie Satrom",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/blsatrom2023.jpg?itok=QdHtHBmZ",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-ND-12-lower-ocd-person-3f885f7e-14b0-4ee7-b1fd-71ddbd72c539",
              "builtID": "nd-lower-12",
              "externalID": "ocd-person/3f885f7e-14b0-4ee7-b1fd-71ddbd72c539",
              "geometry": null
            },
            "contactInfo": {
              "email": "blsatrom@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-bernie-satrom",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-bernie-satrom",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-bernie-satrom",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-bernie-satrom",
                "https://www.ndlegis.gov/biography/bernie-satrom",
                "https://ndlegis.gov/biography/bernie-satrom"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a9b3e13f-b522-4383-b775-45afc7636520",
            "firstName": "Mitchell",
            "lastName": "Ostlie",
            "fullName": "Mitch Ostlie",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/mostlie2023.jpg?itok=1fWaBlwg",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-ND-12-lower-ocd-person-a9b3e13f-b522-4383-b775-45afc7636520",
              "builtID": "nd-lower-12",
              "externalID": "ocd-person/a9b3e13f-b522-4383-b775-45afc7636520",
              "geometry": null
            },
            "contactInfo": {
              "email": "mostlie@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-mitch-ostlie",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-mitch-ostlie",
                "https://www.ndlegis.gov/biography/mitch-ostlie",
                "https://ndlegis.gov/biography/mitch-ostlie"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-12-lower-ocd-person-3f885f7e-14b0-4ee7-b1fd-71ddbd72c539": 0,
          "SLDL-ND-12-lower-ocd-person-a9b3e13f-b522-4383-b775-45afc7636520": 1
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/860892bd-92d1-4a30-a5e6-3b5f2359e9df",
            "firstName": "Austen",
            "lastName": "Schauer",
            "fullName": "Austen Schauer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/aschauer2023.jpg?itok=nn6aFXan",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-ND-13-lower-ocd-person-860892bd-92d1-4a30-a5e6-3b5f2359e9df",
              "builtID": "nd-lower-13",
              "externalID": "ocd-person/860892bd-92d1-4a30-a5e6-3b5f2359e9df",
              "geometry": null
            },
            "contactInfo": {
              "email": "aschauer@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-austen-schauer",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-austen-schauer",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-austen-schauer",
                "https://www.ndlegis.gov/biography/austen-schauer",
                "https://ndlegis.gov/biography/austen-schauer"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/364ea7b0-8ff8-42b6-ac54-1453cc9d7785",
            "firstName": "Jim",
            "lastName": "Jonas",
            "fullName": "Jim Jonas",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jjonas2023.jpg?itok=Al-CmnoP",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-ND-13-lower-ocd-person-364ea7b0-8ff8-42b6-ac54-1453cc9d7785",
              "builtID": "nd-lower-13",
              "externalID": "ocd-person/364ea7b0-8ff8-42b6-ac54-1453cc9d7785",
              "geometry": null
            },
            "contactInfo": {
              "email": "jjonas@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/jim-jonas",
                "https://ndlegis.gov/biography/jim-jonas"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-13-lower-ocd-person-860892bd-92d1-4a30-a5e6-3b5f2359e9df": 0,
          "SLDL-ND-13-lower-ocd-person-364ea7b0-8ff8-42b6-ac54-1453cc9d7785": 1
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/b3f127cc-2ec4-434b-8258-b5b29a7690ca",
            "firstName": "Jon O.",
            "lastName": "Nelson",
            "fullName": "Jon Nelson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jonelson2023.jpg?itok=z7iyfOlj",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-ND-14-lower-ocd-person-b3f127cc-2ec4-434b-8258-b5b29a7690ca",
              "builtID": "nd-lower-14",
              "externalID": "ocd-person/b3f127cc-2ec4-434b-8258-b5b29a7690ca",
              "geometry": null
            },
            "contactInfo": {
              "email": "jonelson@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-jon-o-nelson",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-jon-o-nelson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-jon-o-nelson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-jon-o-nelson",
                "https://www.ndlegis.gov/biography/jon-o-nelson",
                "https://ndlegis.gov/biography/jon-o-nelson"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f96d48d1-23a3-4516-a3a5-5bf873f05eda",
            "firstName": "Robin",
            "lastName": "Weisz",
            "fullName": "Robin Weisz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/rweisz2023_3.jpg?itok=_m-no8ZP",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-ND-14-lower-ocd-person-f96d48d1-23a3-4516-a3a5-5bf873f05eda",
              "builtID": "nd-lower-14",
              "externalID": "ocd-person/f96d48d1-23a3-4516-a3a5-5bf873f05eda",
              "geometry": null
            },
            "contactInfo": {
              "email": "rweisz@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-robin-weisz",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-robin-weisz",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-robin-weisz",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-robin-weisz",
                "https://www.ndlegis.gov/biography/robin-weisz",
                "https://ndlegis.gov/biography/robin-weisz"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-14-lower-ocd-person-b3f127cc-2ec4-434b-8258-b5b29a7690ca": 0,
          "SLDL-ND-14-lower-ocd-person-f96d48d1-23a3-4516-a3a5-5bf873f05eda": 1
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/5d600d38-31f0-4a13-9ceb-9ed6630edb3c",
            "firstName": "Dennis",
            "lastName": "Johnson",
            "fullName": "Dennis Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/djohnson2023.jpg?itok=4qWvz7w6",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-ND-15-lower-ocd-person-5d600d38-31f0-4a13-9ceb-9ed6630edb3c",
              "builtID": "nd-lower-15",
              "externalID": "ocd-person/5d600d38-31f0-4a13-9ceb-9ed6630edb3c",
              "geometry": null
            },
            "contactInfo": {
              "email": "djohnson@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-dennis-johnson",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-dennis-johnson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-dennis-johnson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-dennis-johnson",
                "https://www.ndlegis.gov/biography/dennis-johnson",
                "https://ndlegis.gov/biography/dennis-johnson"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/2674934f-59c0-4caa-8ff4-be3deecc31b3",
            "firstName": "Kathy",
            "lastName": "Frelich",
            "fullName": "Kathy Frelich",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/kfrelich2023.jpg?itok=RkRwD-64",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-ND-15-lower-ocd-person-2674934f-59c0-4caa-8ff4-be3deecc31b3",
              "builtID": "nd-lower-15",
              "externalID": "ocd-person/2674934f-59c0-4caa-8ff4-be3deecc31b3",
              "geometry": null
            },
            "contactInfo": {
              "email": "kfrelich@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/kathy-frelich",
                "https://ndlegis.gov/biography/kathy-frelich"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-15-lower-ocd-person-5d600d38-31f0-4a13-9ceb-9ed6630edb3c": 0,
          "SLDL-ND-15-lower-ocd-person-2674934f-59c0-4caa-8ff4-be3deecc31b3": 1
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/6fbb2691-956f-4427-8927-66309b08ed68",
            "firstName": "Andrew",
            "lastName": "Marschall",
            "fullName": "Andrew Marschall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/amarschall2023.jpg?itok=hxGY-yV_",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-ND-16-lower-ocd-person-6fbb2691-956f-4427-8927-66309b08ed68",
              "builtID": "nd-lower-16",
              "externalID": "ocd-person/6fbb2691-956f-4427-8927-66309b08ed68",
              "geometry": null
            },
            "contactInfo": {
              "email": "amarschall@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-andrew-marschall",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-andrew-marschall",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-andrew-marschall",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-andrew-marschall",
                "https://www.ndlegis.gov/biography/andrew-marschall",
                "https://ndlegis.gov/biography/andrew-marschall"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/9335be7e-11ff-4577-af89-e61195143354",
            "firstName": "Ben",
            "lastName": "Koppelman",
            "fullName": "Ben Koppelman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/bkoppelman2023.jpg?itok=esaQXglY",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-ND-16-lower-ocd-person-9335be7e-11ff-4577-af89-e61195143354",
              "builtID": "nd-lower-16",
              "externalID": "ocd-person/9335be7e-11ff-4577-af89-e61195143354",
              "geometry": null
            },
            "contactInfo": {
              "email": "bkoppelman@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-ben-koppelman",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-ben-koppelman",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-ben-koppelman",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-ben-koppelman",
                "https://www.ndlegis.gov/biography/ben-koppelman",
                "https://ndlegis.gov/biography/ben-koppelman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-16-lower-ocd-person-6fbb2691-956f-4427-8927-66309b08ed68": 0,
          "SLDL-ND-16-lower-ocd-person-9335be7e-11ff-4577-af89-e61195143354": 1
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/a7fba7c3-b0ee-4b95-976e-4b38c0b8fa53",
            "firstName": "Landon",
            "lastName": "Bahl",
            "fullName": "Landon Bahl",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/lbahl2023.jpg?itok=DZdPabxt",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-ND-17-lower-ocd-person-a7fba7c3-b0ee-4b95-976e-4b38c0b8fa53",
              "builtID": "nd-lower-17",
              "externalID": "ocd-person/a7fba7c3-b0ee-4b95-976e-4b38c0b8fa53",
              "geometry": null
            },
            "contactInfo": {
              "email": "lbahl@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/landon-bahl",
                "https://ndlegis.gov/biography/landon-bahl"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/fdaab7d3-77b8-4cf4-bda2-1cb927f675a9",
            "firstName": "Mark S.",
            "lastName": "Sanford",
            "fullName": "Mark Sanford",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/masanford2023.jpg?itok=krQ5n8A5",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-ND-17-lower-ocd-person-fdaab7d3-77b8-4cf4-bda2-1cb927f675a9",
              "builtID": "nd-lower-17",
              "externalID": "ocd-person/fdaab7d3-77b8-4cf4-bda2-1cb927f675a9",
              "geometry": null
            },
            "contactInfo": {
              "email": "masanford@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-mark-sanford",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-mark-sanford",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-mark-sanford",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-mark-sanford",
                "https://www.ndlegis.gov/biography/mark-sanford",
                "https://ndlegis.gov/biography/mark-sanford"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-17-lower-ocd-person-a7fba7c3-b0ee-4b95-976e-4b38c0b8fa53": 0,
          "SLDL-ND-17-lower-ocd-person-fdaab7d3-77b8-4cf4-bda2-1cb927f675a9": 1
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/33add2cf-dc7a-4da0-b60d-95faaad1f261",
            "firstName": "Corey Ray",
            "lastName": "Mock",
            "fullName": "Corey Mock",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/crmock2023.jpg?itok=RNCK80GN",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-ND-18-lower-ocd-person-33add2cf-dc7a-4da0-b60d-95faaad1f261",
              "builtID": "nd-lower-18",
              "externalID": "ocd-person/33add2cf-dc7a-4da0-b60d-95faaad1f261",
              "geometry": null
            },
            "contactInfo": {
              "email": "crmock@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-corey-mock",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-corey-mock",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-corey-mock",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-corey-mock",
                "https://www.ndlegis.gov/biography/corey-mock",
                "https://ndlegis.gov/biography/corey-mock"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/9d903288-c374-46e7-8665-d9ba4ba377b5",
            "firstName": "Steve",
            "lastName": "Vetter",
            "fullName": "Steve Vetter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/smvetter2023.jpg?itok=Wpd0aojC",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-ND-18-lower-ocd-person-9d903288-c374-46e7-8665-d9ba4ba377b5",
              "builtID": "nd-lower-18",
              "externalID": "ocd-person/9d903288-c374-46e7-8665-d9ba4ba377b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "smvetter@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-steve-vetter",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-steve-vetter",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-steve-vetter",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-steve-vetter",
                "https://www.ndlegis.gov/biography/steve-vetter",
                "https://ndlegis.gov/biography/steve-vetter"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-18-lower-ocd-person-33add2cf-dc7a-4da0-b60d-95faaad1f261": 0,
          "SLDL-ND-18-lower-ocd-person-9d903288-c374-46e7-8665-d9ba4ba377b5": 1
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/5d387655-8234-48d2-a1dd-dd2f4a83ca6f",
            "firstName": "David C.",
            "lastName": "Monson",
            "fullName": "Dave Monson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/dmonson2023.jpg?itok=k-ar-UCY",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-ND-19-lower-ocd-person-5d387655-8234-48d2-a1dd-dd2f4a83ca6f",
              "builtID": "nd-lower-19",
              "externalID": "ocd-person/5d387655-8234-48d2-a1dd-dd2f4a83ca6f",
              "geometry": null
            },
            "contactInfo": {
              "email": "dmonson@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-david-monson",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-david-monson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-david-monson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-david-monson",
                "https://www.ndlegis.gov/biography/david-monson",
                "https://ndlegis.gov/biography/david-monson"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d0cd1bbe-d84e-49d8-bb5e-1ada10cf187d",
            "firstName": "Karen A.",
            "lastName": "Anderson",
            "fullName": "Karen Anderson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/kanderson2023.jpg?itok=T9FfLnKE",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-ND-19-lower-ocd-person-d0cd1bbe-d84e-49d8-bb5e-1ada10cf187d",
              "builtID": "nd-lower-19",
              "externalID": "ocd-person/d0cd1bbe-d84e-49d8-bb5e-1ada10cf187d",
              "geometry": null
            },
            "contactInfo": {
              "email": "kanderson@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/karen-a-anderson",
                "https://ndlegis.gov/biography/karen-a-anderson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-19-lower-ocd-person-5d387655-8234-48d2-a1dd-dd2f4a83ca6f": 0,
          "SLDL-ND-19-lower-ocd-person-d0cd1bbe-d84e-49d8-bb5e-1ada10cf187d": 1
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/cbb1ed7b-ca03-4c1b-b119-a171263e7661",
            "firstName": "Jared C.",
            "lastName": "Hagert",
            "fullName": "Jared Hagert",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jhagert2023.jpg?itok=Pdac7tKu",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-ND-20-lower-ocd-person-cbb1ed7b-ca03-4c1b-b119-a171263e7661",
              "builtID": "nd-lower-20",
              "externalID": "ocd-person/cbb1ed7b-ca03-4c1b-b119-a171263e7661",
              "geometry": null
            },
            "contactInfo": {
              "email": "jhagert@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-jared-c-hagert",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-jared-c-hagert",
                "https://www.ndlegis.gov/biography/jared-hagert",
                "https://ndlegis.gov/biography/jared-hagert"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/2e1bbdc9-a4e1-4ab2-953f-2e51910b7b58",
            "firstName": "Mike",
            "lastName": "Beltz",
            "fullName": "Mike Beltz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/mbeltz2023.jpg?itok=VV6jACpR",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-ND-20-lower-ocd-person-2e1bbdc9-a4e1-4ab2-953f-2e51910b7b58",
              "builtID": "nd-lower-20",
              "externalID": "ocd-person/2e1bbdc9-a4e1-4ab2-953f-2e51910b7b58",
              "geometry": null
            },
            "contactInfo": {
              "email": "mbeltz@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-mike-beltz",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-mike-beltz",
                "https://www.ndlegis.gov/biography/mike-beltz",
                "https://ndlegis.gov/biography/mike-beltz"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-20-lower-ocd-person-cbb1ed7b-ca03-4c1b-b119-a171263e7661": 0,
          "SLDL-ND-20-lower-ocd-person-2e1bbdc9-a4e1-4ab2-953f-2e51910b7b58": 1
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/f233c739-2bfe-4a96-ba9b-f3c4cbff539c",
            "firstName": "LaurieBeth",
            "lastName": "Hager",
            "fullName": "LaurieBeth Hager",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/lbhager2023.jpg?itok=A5Qsur4Z",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-ND-21-lower-ocd-person-f233c739-2bfe-4a96-ba9b-f3c4cbff539c",
              "builtID": "nd-lower-21",
              "externalID": "ocd-person/f233c739-2bfe-4a96-ba9b-f3c4cbff539c",
              "geometry": null
            },
            "contactInfo": {
              "email": "lbhager@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-lauriebeth-hager",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-lauriebeth-hager",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-lauriebeth-hager",
                "https://www.ndlegis.gov/biography/lauriebeth-hager",
                "https://ndlegis.gov/biography/lauriebeth-hager"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/891c379b-f866-4029-9f52-a416b5005272",
            "firstName": "Mary",
            "lastName": "Schneider",
            "fullName": "Mary Schneider",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/mschneider2023.jpg?itok=2DAOpzXC",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-ND-21-lower-ocd-person-891c379b-f866-4029-9f52-a416b5005272",
              "builtID": "nd-lower-21",
              "externalID": "ocd-person/891c379b-f866-4029-9f52-a416b5005272",
              "geometry": null
            },
            "contactInfo": {
              "email": "mschneider@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-mary-schneider",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-mary-schneider",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-mary-schneider",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-mary-schneider",
                "https://www.ndlegis.gov/biography/mary-schneider",
                "https://ndlegis.gov/biography/mary-schneider"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-21-lower-ocd-person-f233c739-2bfe-4a96-ba9b-f3c4cbff539c": 0,
          "SLDL-ND-21-lower-ocd-person-891c379b-f866-4029-9f52-a416b5005272": 1
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/0bae2d8b-4e48-4f31-9e23-9f438ccac477",
            "firstName": "Brandy L.",
            "lastName": "Pyle",
            "fullName": "Brandy Pyle",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/bpyle2023.jpg?itok=nzFe9KY1",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-ND-22-lower-ocd-person-0bae2d8b-4e48-4f31-9e23-9f438ccac477",
              "builtID": "nd-lower-22",
              "externalID": "ocd-person/0bae2d8b-4e48-4f31-9e23-9f438ccac477",
              "geometry": null
            },
            "contactInfo": {
              "email": "bpyle@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-brandy-pyle",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-brandy-pyle",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-brandy-pyle",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-brandy-pyle",
                "https://www.ndlegis.gov/biography/brandy-pyle",
                "https://ndlegis.gov/biography/brandy-pyle"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/35e63cc0-2313-4f4b-80f8-0d7356fa684a",
            "firstName": "Jonathan",
            "lastName": "Warrey",
            "fullName": "Jonathan Warrey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jwarrey2023.jpg?itok=aMx3ZNaU",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-ND-22-lower-ocd-person-35e63cc0-2313-4f4b-80f8-0d7356fa684a",
              "builtID": "nd-lower-22",
              "externalID": "ocd-person/35e63cc0-2313-4f4b-80f8-0d7356fa684a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jwarrey@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/jonathan-warrey",
                "https://ndlegis.gov/biography/jonathan-warrey"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-22-lower-ocd-person-0bae2d8b-4e48-4f31-9e23-9f438ccac477": 0,
          "SLDL-ND-22-lower-ocd-person-35e63cc0-2313-4f4b-80f8-0d7356fa684a": 1
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/4e998af9-35f0-457c-8f37-b2fdaa914dbd",
            "firstName": "Dennis",
            "lastName": "Nehring",
            "fullName": "Dennis Nehring",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-ND-23-lower-ocd-person-4e998af9-35f0-457c-8f37-b2fdaa914dbd",
              "builtID": "nd-lower-23",
              "externalID": "ocd-person/4e998af9-35f0-457c-8f37-b2fdaa914dbd",
              "geometry": null
            },
            "contactInfo": {
              "email": "denehring@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ndlegis.gov/biography/dennis-nehring"],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1a0c2ca8-e9ab-4698-a75b-4f40e65a413d",
            "firstName": "Nico",
            "lastName": "Rios",
            "fullName": "Nico Rios",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/nrios2023_2.jpg?itok=87de-j4E",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-ND-23-lower-ocd-person-1a0c2ca8-e9ab-4698-a75b-4f40e65a413d",
              "builtID": "nd-lower-23",
              "externalID": "ocd-person/1a0c2ca8-e9ab-4698-a75b-4f40e65a413d",
              "geometry": null
            },
            "contactInfo": {
              "email": "nrios@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/nico-rios",
                "https://ndlegis.gov/biography/nico-rios"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-23-lower-ocd-person-4e998af9-35f0-457c-8f37-b2fdaa914dbd": 0,
          "SLDL-ND-23-lower-ocd-person-1a0c2ca8-e9ab-4698-a75b-4f40e65a413d": 1
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/7e68311f-8c99-40d6-b606-c287d09ff3e9",
            "firstName": "Rose",
            "lastName": "Christensen",
            "fullName": "Rose Christensen",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/colechristensen2023.jpg?itok=UbjqOSsS",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-ND-24-lower-ocd-person-7e68311f-8c99-40d6-b606-c287d09ff3e9",
              "builtID": "nd-lower-24",
              "externalID": "ocd-person/7e68311f-8c99-40d6-b606-c287d09ff3e9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rchristensen@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-cole-christensen",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-cole-christensen",
                "https://www.ndlegis.gov/biography/cole-christensen",
                "https://ndlegis.gov/biography/rose-christensen"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/7c89a861-f0cf-402f-97e1-70953440ee36",
            "firstName": "Dwight",
            "lastName": "Kiefert",
            "fullName": "Dwight Kiefert",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/dhkiefert2023.jpg?itok=zmoi5VTK",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-ND-24-lower-ocd-person-7c89a861-f0cf-402f-97e1-70953440ee36",
              "builtID": "nd-lower-24",
              "externalID": "ocd-person/7c89a861-f0cf-402f-97e1-70953440ee36",
              "geometry": null
            },
            "contactInfo": {
              "email": "dhkiefert@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-dwight-kiefert",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-dwight-kiefert",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-dwight-kiefert",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-dwight-kiefert",
                "https://www.ndlegis.gov/biography/dwight-kiefert",
                "https://ndlegis.gov/biography/dwight-kiefert"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-24-lower-ocd-person-7e68311f-8c99-40d6-b606-c287d09ff3e9": 0,
          "SLDL-ND-24-lower-ocd-person-7c89a861-f0cf-402f-97e1-70953440ee36": 1
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/9c0f35c1-03de-443e-97bd-5cdf77581897",
            "firstName": "Alisa",
            "lastName": "Mitskog",
            "fullName": "Alisa Mitskog",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/amitskog2023_3.jpg?itok=vwMWQ-Ou",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-ND-25-lower-ocd-person-9c0f35c1-03de-443e-97bd-5cdf77581897",
              "builtID": "nd-lower-25",
              "externalID": "ocd-person/9c0f35c1-03de-443e-97bd-5cdf77581897",
              "geometry": null
            },
            "contactInfo": {
              "email": "amitskog@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-alisa-mitskog",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-alisa-mitskog",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-alisa-mitskog",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-alisa-mitskog",
                "https://www.ndlegis.gov/biography/alisa-mitskog",
                "https://ndlegis.gov/biography/alisa-mitskog"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/edc83bb0-e49f-42e6-8c3d-559da1f2ea91",
            "firstName": "Cynthia",
            "lastName": "Schreiber-Beck",
            "fullName": "Cindy Schreiber-Beck",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/cschreiberbeck2023_2.jpg?itok=OLFAtdxp",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-ND-25-lower-ocd-person-edc83bb0-e49f-42e6-8c3d-559da1f2ea91",
              "builtID": "nd-lower-25",
              "externalID": "ocd-person/edc83bb0-e49f-42e6-8c3d-559da1f2ea91",
              "geometry": null
            },
            "contactInfo": {
              "email": "cschreiberbeck@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-cynthia-schreiber-beck",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-cynthia-schreiber-beck",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-cynthia-schreiber-beck",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-cynthia-schreiber-beck",
                "https://www.ndlegis.gov/biography/cynthia-schreiber-beck",
                "https://ndlegis.gov/biography/cynthia-schreiber-beck"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-25-lower-ocd-person-9c0f35c1-03de-443e-97bd-5cdf77581897": 0,
          "SLDL-ND-25-lower-ocd-person-edc83bb0-e49f-42e6-8c3d-559da1f2ea91": 1
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/8435e9c2-7d83-4791-95da-73a6681e60a0",
            "firstName": "Jeremy",
            "lastName": "Olson",
            "fullName": "Jeremy Olson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jolson2023.jpg?itok=nhQZjWTV",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-ND-26-lower-ocd-person-8435e9c2-7d83-4791-95da-73a6681e60a0",
              "builtID": "nd-lower-26",
              "externalID": "ocd-person/8435e9c2-7d83-4791-95da-73a6681e60a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jolson@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/jeremy-olson",
                "https://ndlegis.gov/biography/jeremy-olson"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ed4f6720-01ed-42c3-9e33-8fa6ca237b49",
            "firstName": "Kelby",
            "lastName": "Timmons",
            "fullName": "Kelby Timmons",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/ktimmons2023.jpg?itok=NPPJqbct",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-ND-26-lower-ocd-person-ed4f6720-01ed-42c3-9e33-8fa6ca237b49",
              "builtID": "nd-lower-26",
              "externalID": "ocd-person/ed4f6720-01ed-42c3-9e33-8fa6ca237b49",
              "geometry": null
            },
            "contactInfo": {
              "email": "ktimmons@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/kelby-timmons",
                "https://ndlegis.gov/biography/kelby-timmons"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-26-lower-ocd-person-8435e9c2-7d83-4791-95da-73a6681e60a0": 0,
          "SLDL-ND-26-lower-ocd-person-ed4f6720-01ed-42c3-9e33-8fa6ca237b49": 1
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/3ef34927-6fe8-4693-a818-20f654ae6b31",
            "firstName": "Greg",
            "lastName": "Stemen",
            "fullName": "Greg Stemen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/gstemen2023.jpg?itok=YEl0RlY3",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-ND-27-lower-ocd-person-3ef34927-6fe8-4693-a818-20f654ae6b31",
              "builtID": "nd-lower-27",
              "externalID": "ocd-person/3ef34927-6fe8-4693-a818-20f654ae6b31",
              "geometry": null
            },
            "contactInfo": {
              "email": "gstemen@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-greg-stemen",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-greg-stemen",
                "https://www.ndlegis.gov/biography/greg-stemen",
                "https://ndlegis.gov/biography/greg-stemen"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/da9f11a8-d08f-4bce-a492-edd1af37d577",
            "firstName": "Joshua David",
            "lastName": "Christy",
            "fullName": "Josh Christy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jchristy2023.jpg?itok=MeuoYf6E",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-ND-27-lower-ocd-person-da9f11a8-d08f-4bce-a492-edd1af37d577",
              "builtID": "nd-lower-27",
              "externalID": "ocd-person/da9f11a8-d08f-4bce-a492-edd1af37d577",
              "geometry": null
            },
            "contactInfo": {
              "email": "jchristy@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/josh-christy",
                "https://ndlegis.gov/biography/josh-christy"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-27-lower-ocd-person-3ef34927-6fe8-4693-a818-20f654ae6b31": 0,
          "SLDL-ND-27-lower-ocd-person-da9f11a8-d08f-4bce-a492-edd1af37d577": 1
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/d9517a29-cf58-4538-953f-715eee39db16",
            "firstName": "James",
            "lastName": "Grueneich",
            "fullName": "Jim Grueneich",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jgrueneich2023.jpg?itok=DWgbvGCh",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-ND-28-lower-ocd-person-d9517a29-cf58-4538-953f-715eee39db16",
              "builtID": "nd-lower-28",
              "externalID": "ocd-person/d9517a29-cf58-4538-953f-715eee39db16",
              "geometry": null
            },
            "contactInfo": {
              "email": "jgrueneich@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-jim-grueneich",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-jim-grueneich",
                "https://www.ndlegis.gov/biography/jim-grueneich",
                "https://ndlegis.gov/biography/jim-grueneich"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/52538b14-697a-4632-854b-badf925cbfe6",
            "firstName": "Michael Don",
            "lastName": "Brandenburg",
            "fullName": "Mike Brandenburg",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/mbrandenburg2023.jpg?itok=bLtWJCpq",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-ND-28-lower-ocd-person-52538b14-697a-4632-854b-badf925cbfe6",
              "builtID": "nd-lower-28",
              "externalID": "ocd-person/52538b14-697a-4632-854b-badf925cbfe6",
              "geometry": null
            },
            "contactInfo": {
              "email": "mbrandenburg@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-mike-brandenburg",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-mike-brandenburg",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-mike-brandenburg",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-mike-brandenburg",
                "https://www.ndlegis.gov/biography/mike-brandenburg",
                "https://ndlegis.gov/biography/mike-brandenburg"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-28-lower-ocd-person-d9517a29-cf58-4538-953f-715eee39db16": 0,
          "SLDL-ND-28-lower-ocd-person-52538b14-697a-4632-854b-badf925cbfe6": 1
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/74e50305-a021-46bd-9d0d-dcd872645cfd",
            "firstName": "Craig",
            "lastName": "Headland",
            "fullName": "Craig Headland",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/cheadland2023.jpg?itok=_qgWKQP8",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-ND-29-lower-ocd-person-74e50305-a021-46bd-9d0d-dcd872645cfd",
              "builtID": "nd-lower-29",
              "externalID": "ocd-person/74e50305-a021-46bd-9d0d-dcd872645cfd",
              "geometry": null
            },
            "contactInfo": {
              "email": "cheadland@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-craig-headland",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-craig-headland",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-craig-headland",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-craig-headland",
                "https://www.ndlegis.gov/biography/craig-headland",
                "https://ndlegis.gov/biography/craig-headland"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/95e4c82b-ae91-4bc6-a3f1-14ad9b8758ce",
            "firstName": "Donald",
            "lastName": "Vigesaa",
            "fullName": "Don Vigesaa",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/dwvigesaa2023.jpg?itok=8zs60AtF",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-ND-29-lower-ocd-person-95e4c82b-ae91-4bc6-a3f1-14ad9b8758ce",
              "builtID": "nd-lower-29",
              "externalID": "ocd-person/95e4c82b-ae91-4bc6-a3f1-14ad9b8758ce",
              "geometry": null
            },
            "contactInfo": {
              "email": "dwvigesaa@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-don-vigesaa",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-don-vigesaa",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-don-vigesaa",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-don-vigesaa",
                "https://www.ndlegis.gov/biography/don-vigesaa",
                "https://ndlegis.gov/biography/don-vigesaa"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-29-lower-ocd-person-74e50305-a021-46bd-9d0d-dcd872645cfd": 0,
          "SLDL-ND-29-lower-ocd-person-95e4c82b-ae91-4bc6-a3f1-14ad9b8758ce": 1
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/489ce19c-875b-49c9-aa02-d8c05c09b3f5",
            "firstName": "Glenn",
            "lastName": "Bosch",
            "fullName": "Glenn Bosch",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/gdbosch2023.jpg?itok=v8w6sU_R",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-ND-30-lower-ocd-person-489ce19c-875b-49c9-aa02-d8c05c09b3f5",
              "builtID": "nd-lower-30",
              "externalID": "ocd-person/489ce19c-875b-49c9-aa02-d8c05c09b3f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "gdbosch@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-glenn-bosch",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-glenn-bosch",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-glenn-bosch",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-glenn-bosch",
                "https://www.ndlegis.gov/biography/glenn-bosch",
                "https://ndlegis.gov/biography/glenn-bosch"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/981c4da0-4ab9-46cb-aa42-397253399cba",
            "firstName": "Michael",
            "lastName": "Nathe",
            "fullName": "Mike Nathe",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/mrnathe2023.jpg?itok=1k0AbKZs",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-ND-30-lower-ocd-person-981c4da0-4ab9-46cb-aa42-397253399cba",
              "builtID": "nd-lower-30",
              "externalID": "ocd-person/981c4da0-4ab9-46cb-aa42-397253399cba",
              "geometry": null
            },
            "contactInfo": {
              "email": "mrnathe@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-mike-nathe",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-mike-nathe",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-mike-nathe",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-mike-nathe",
                "https://www.ndlegis.gov/biography/mike-nathe",
                "https://ndlegis.gov/biography/mike-nathe"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-30-lower-ocd-person-489ce19c-875b-49c9-aa02-d8c05c09b3f5": 0,
          "SLDL-ND-30-lower-ocd-person-981c4da0-4ab9-46cb-aa42-397253399cba": 1
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/5938951d-3c45-4913-8612-aa70ba790f88",
            "firstName": "Dawson",
            "lastName": "Holle",
            "fullName": "Dawson Holle",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/dholle2023.jpg?itok=RbaAfnjR",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-ND-31-lower-ocd-person-5938951d-3c45-4913-8612-aa70ba790f88",
              "builtID": "nd-lower-31",
              "externalID": "ocd-person/5938951d-3c45-4913-8612-aa70ba790f88",
              "geometry": null
            },
            "contactInfo": {
              "email": "dholle@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/dawson-holle",
                "https://ndlegis.gov/biography/dawson-holle"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/af21927a-d2f9-454d-93fc-04ec9d585b1e",
            "firstName": "Karen M.",
            "lastName": "Rohr",
            "fullName": "Karen Rohr",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/kmrohr2023.jpg?itok=fAV4fbCk",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-ND-31-lower-ocd-person-af21927a-d2f9-454d-93fc-04ec9d585b1e",
              "builtID": "nd-lower-31",
              "externalID": "ocd-person/af21927a-d2f9-454d-93fc-04ec9d585b1e",
              "geometry": null
            },
            "contactInfo": {
              "email": "kmrohr@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-karen-m-rohr",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-karen-m-rohr",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-karen-m-rohr",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-karen-m-rohr",
                "https://www.ndlegis.gov/biography/karen-m-rohr",
                "https://ndlegis.gov/biography/karen-m-rohr"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-31-lower-ocd-person-5938951d-3c45-4913-8612-aa70ba790f88": 0,
          "SLDL-ND-31-lower-ocd-person-af21927a-d2f9-454d-93fc-04ec9d585b1e": 1
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/39e75205-fa1e-45d4-a04a-2ccee0a83a07",
            "firstName": "Lisa M.",
            "lastName": "Meier",
            "fullName": "Lisa Meier",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/lmeier2023.jpg?itok=8-dZt4eW",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-ND-32-lower-ocd-person-39e75205-fa1e-45d4-a04a-2ccee0a83a07",
              "builtID": "nd-lower-32",
              "externalID": "ocd-person/39e75205-fa1e-45d4-a04a-2ccee0a83a07",
              "geometry": null
            },
            "contactInfo": {
              "email": "lmeier@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-lisa-meier",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-lisa-meier",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-lisa-meier",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-lisa-meier",
                "https://www.ndlegis.gov/biography/lisa-meier",
                "https://ndlegis.gov/biography/lisa-meier"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1f5bad71-f1e2-4729-896b-293137ed6a84",
            "firstName": "Patrick D.",
            "lastName": "Heinert",
            "fullName": "Pat Heinert",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/pdheinert2023.jpg?itok=NyBAAtQe",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-ND-32-lower-ocd-person-1f5bad71-f1e2-4729-896b-293137ed6a84",
              "builtID": "nd-lower-32",
              "externalID": "ocd-person/1f5bad71-f1e2-4729-896b-293137ed6a84",
              "geometry": null
            },
            "contactInfo": {
              "email": "pdheinert@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-pat-d-heinert",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-pat-d-heinert",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-pat-d-heinert",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-pat-d-heinert",
                "https://www.ndlegis.gov/biography/pat-d-heinert",
                "https://ndlegis.gov/biography/pat-d-heinert"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-32-lower-ocd-person-39e75205-fa1e-45d4-a04a-2ccee0a83a07": 0,
          "SLDL-ND-32-lower-ocd-person-1f5bad71-f1e2-4729-896b-293137ed6a84": 1
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/c40382fd-ce08-46af-8649-afd1f5e04091",
            "firstName": "Anna Garrett",
            "lastName": "Novak",
            "fullName": "Anna Novak",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/anovak2023.jpg?itok=EptvTScj",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-ND-33-lower-ocd-person-c40382fd-ce08-46af-8649-afd1f5e04091",
              "builtID": "nd-lower-33",
              "externalID": "ocd-person/c40382fd-ce08-46af-8649-afd1f5e04091",
              "geometry": null
            },
            "contactInfo": {
              "email": "anovak@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/anna-s-novak",
                "https://ndlegis.gov/biography/anna-s-novak"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/50e4864c-095a-43a6-9486-3b071e02ca09",
            "firstName": "Bill",
            "lastName": "Tveit",
            "fullName": "Bill Tveit",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/btveit2023.jpg?itok=cI3qL4LC",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-ND-33-lower-ocd-person-50e4864c-095a-43a6-9486-3b071e02ca09",
              "builtID": "nd-lower-33",
              "externalID": "ocd-person/50e4864c-095a-43a6-9486-3b071e02ca09",
              "geometry": null
            },
            "contactInfo": {
              "email": "btveit@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-bill-tveit",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-bill-tveit",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-bill-tveit",
                "https://www.ndlegis.gov/biography/bill-tveit",
                "https://ndlegis.gov/biography/bill-tveit"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-33-lower-ocd-person-c40382fd-ce08-46af-8649-afd1f5e04091": 0,
          "SLDL-ND-33-lower-ocd-person-50e4864c-095a-43a6-9486-3b071e02ca09": 1
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/bf16c102-641d-4296-96c4-eccab1c1d43f",
            "firstName": "Nathan",
            "lastName": "Toman",
            "fullName": "Nathan Toman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/nptoman2023.jpg?itok=585ikeOa",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-ND-34-lower-ocd-person-bf16c102-641d-4296-96c4-eccab1c1d43f",
              "builtID": "nd-lower-34",
              "externalID": "ocd-person/bf16c102-641d-4296-96c4-eccab1c1d43f",
              "geometry": null
            },
            "contactInfo": {
              "email": "nptoman@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-nathan-toman",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-nathan-toman",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-nathan-toman",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-nathan-toman",
                "https://www.ndlegis.gov/biography/nathan-toman",
                "https://ndlegis.gov/biography/nathan-toman"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/94b03c1b-39a3-495a-8a11-0c21a5f30f08",
            "firstName": "Todd",
            "lastName": "Porter",
            "fullName": "Todd Porter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/tkporter2023.jpg?itok=7kkm9IJN",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-ND-34-lower-ocd-person-94b03c1b-39a3-495a-8a11-0c21a5f30f08",
              "builtID": "nd-lower-34",
              "externalID": "ocd-person/94b03c1b-39a3-495a-8a11-0c21a5f30f08",
              "geometry": null
            },
            "contactInfo": {
              "email": "tkporter@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-todd-porter",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-todd-porter",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-todd-porter",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-todd-porter",
                "https://www.ndlegis.gov/biography/todd-porter",
                "https://ndlegis.gov/biography/todd-porter"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-34-lower-ocd-person-bf16c102-641d-4296-96c4-eccab1c1d43f": 0,
          "SLDL-ND-34-lower-ocd-person-94b03c1b-39a3-495a-8a11-0c21a5f30f08": 1
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/f8720088-51ae-4178-87f8-abbb73faf444",
            "firstName": "Bob",
            "lastName": "Martinson",
            "fullName": "Bob Martinson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/bmartinson2023.jpg?itok=G3GoKXQJ",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-ND-35-lower-ocd-person-f8720088-51ae-4178-87f8-abbb73faf444",
              "builtID": "nd-lower-35",
              "externalID": "ocd-person/f8720088-51ae-4178-87f8-abbb73faf444",
              "geometry": null
            },
            "contactInfo": {
              "email": "bmartinson@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-bob-martinson",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-bob-martinson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-bob-martinson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-bob-martinson",
                "https://www.ndlegis.gov/biography/bob-martinson",
                "https://ndlegis.gov/biography/bob-martinson"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a1829243-52bf-4ade-9ed8-6955241120c3",
            "firstName": "Karen",
            "lastName": "Karls",
            "fullName": "Karen Karls",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/kkarls2023.jpg?itok=gbGqbvNN",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-ND-35-lower-ocd-person-a1829243-52bf-4ade-9ed8-6955241120c3",
              "builtID": "nd-lower-35",
              "externalID": "ocd-person/a1829243-52bf-4ade-9ed8-6955241120c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "kkarls@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-karen-karls",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-karen-karls",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-karen-karls",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-karen-karls",
                "https://www.ndlegis.gov/biography/karen-karls",
                "https://ndlegis.gov/biography/karen-karls"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-35-lower-ocd-person-f8720088-51ae-4178-87f8-abbb73faf444": 0,
          "SLDL-ND-35-lower-ocd-person-a1829243-52bf-4ade-9ed8-6955241120c3": 1
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/6035e38b-ec58-44f7-9b47-7c6672c575f8",
            "firstName": "Dori",
            "lastName": "Hauck",
            "fullName": "Dori Hauck",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/dorihauck2023.jpg?itok=l1MjUhcA",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-ND-36-lower-ocd-person-6035e38b-ec58-44f7-9b47-7c6672c575f8",
              "builtID": "nd-lower-36",
              "externalID": "ocd-person/6035e38b-ec58-44f7-9b47-7c6672c575f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "dorihauck@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-dori-hauck",
                "https://www.ndlegis.gov/biography/dori-hauck",
                "https://ndlegis.gov/biography/dori-hauck"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f0938888-cc5c-4a00-a778-9d91efc07da4",
            "firstName": "Gary",
            "lastName": "Kreidt",
            "fullName": "Gary Kreidt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/gkreidt2023.jpg?itok=tGFxFzxD",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-ND-36-lower-ocd-person-f0938888-cc5c-4a00-a778-9d91efc07da4",
              "builtID": "nd-lower-36",
              "externalID": "ocd-person/f0938888-cc5c-4a00-a778-9d91efc07da4",
              "geometry": null
            },
            "contactInfo": {
              "email": "gkreidt@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-gary-kreidt",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-gary-kreidt",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-gary-kreidt",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-gary-kreidt",
                "https://www.ndlegis.gov/biography/gary-kreidt",
                "https://ndlegis.gov/biography/gary-kreidt"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-36-lower-ocd-person-6035e38b-ec58-44f7-9b47-7c6672c575f8": 0,
          "SLDL-ND-36-lower-ocd-person-f0938888-cc5c-4a00-a778-9d91efc07da4": 1
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/813e59ee-0d23-43d7-b6a9-82ff79cf8f4f",
            "firstName": "Michael",
            "lastName": "Lefor",
            "fullName": "Mike Lefor",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/mlefor2023.jpg?itok=jHFXKlg8",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-ND-37-lower-ocd-person-813e59ee-0d23-43d7-b6a9-82ff79cf8f4f",
              "builtID": "nd-lower-37",
              "externalID": "ocd-person/813e59ee-0d23-43d7-b6a9-82ff79cf8f4f",
              "geometry": null
            },
            "contactInfo": {
              "email": "mlefor@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-mike-lefor",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-mike-lefor",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-mike-lefor",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-mike-lefor",
                "https://www.ndlegis.gov/biography/mike-lefor",
                "https://ndlegis.gov/biography/mike-lefor"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/6408b9c3-3e70-4e47-a2c1-d31c11012f4c",
            "firstName": "Vicky",
            "lastName": "Steiner",
            "fullName": "Vicky Steiner",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/vsteiner2023.jpg?itok=MjR49cPZ",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-ND-37-lower-ocd-person-6408b9c3-3e70-4e47-a2c1-d31c11012f4c",
              "builtID": "nd-lower-37",
              "externalID": "ocd-person/6408b9c3-3e70-4e47-a2c1-d31c11012f4c",
              "geometry": null
            },
            "contactInfo": {
              "email": "vsteiner@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-vicky-steiner",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-vicky-steiner",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-vicky-steiner",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-vicky-steiner",
                "https://www.ndlegis.gov/biography/vicky-steiner",
                "https://ndlegis.gov/biography/vicky-steiner"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-37-lower-ocd-person-813e59ee-0d23-43d7-b6a9-82ff79cf8f4f": 0,
          "SLDL-ND-37-lower-ocd-person-6408b9c3-3e70-4e47-a2c1-d31c11012f4c": 1
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/a78a22ef-aefe-43ee-a40e-86026b341381",
            "firstName": "Dan",
            "lastName": "Ruby",
            "fullName": "Dan Ruby",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/druby2023.jpg?itok=71zc5cs6",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-ND-38-lower-ocd-person-a78a22ef-aefe-43ee-a40e-86026b341381",
              "builtID": "nd-lower-38",
              "externalID": "ocd-person/a78a22ef-aefe-43ee-a40e-86026b341381",
              "geometry": null
            },
            "contactInfo": {
              "email": "druby@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-dan-ruby",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-dan-ruby",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-dan-ruby",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-dan-ruby",
                "https://www.ndlegis.gov/biography/dan-ruby",
                "https://ndlegis.gov/biography/dan-ruby"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4cce8a12-29ae-475a-a3c2-016328783ff2",
            "firstName": "JoAnne",
            "lastName": "Rademacher",
            "fullName": "JoAnne Rademacher",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-ND-38-lower-ocd-person-4cce8a12-29ae-475a-a3c2-016328783ff2",
              "builtID": "nd-lower-38",
              "externalID": "ocd-person/4cce8a12-29ae-475a-a3c2-016328783ff2",
              "geometry": null
            },
            "contactInfo": {
              "email": "jrademacher@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/joanne-rademacher",
                "https://ndlegis.gov/biography/joanne-rademacher"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-38-lower-ocd-person-a78a22ef-aefe-43ee-a40e-86026b341381": 0,
          "SLDL-ND-38-lower-ocd-person-4cce8a12-29ae-475a-a3c2-016328783ff2": 1
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/3075343c-75ba-4957-a1a2-411eb1708c6b",
            "firstName": "Keith",
            "lastName": "Kempenich",
            "fullName": "Keith Kempenich",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/kkempenich2023.jpg?itok=yAuv-phX",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-ND-39-lower-ocd-person-3075343c-75ba-4957-a1a2-411eb1708c6b",
              "builtID": "nd-lower-39",
              "externalID": "ocd-person/3075343c-75ba-4957-a1a2-411eb1708c6b",
              "geometry": null
            },
            "contactInfo": {
              "email": "kkempenich@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-keith-kempenich",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-keith-kempenich",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-keith-kempenich",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-keith-kempenich",
                "https://www.ndlegis.gov/biography/keith-kempenich",
                "https://ndlegis.gov/biography/keith-kempenich"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a8e9d4c9-c6fa-41cf-8440-30ba429d4c63",
            "firstName": "Mike",
            "lastName": "Schatz",
            "fullName": "Mike Schatz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/mischatz2023.jpg?itok=uu_Ize75",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-ND-39-lower-ocd-person-a8e9d4c9-c6fa-41cf-8440-30ba429d4c63",
              "builtID": "nd-lower-39",
              "externalID": "ocd-person/a8e9d4c9-c6fa-41cf-8440-30ba429d4c63",
              "geometry": null
            },
            "contactInfo": {
              "email": "mischatz@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-mike-schatz",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-mike-schatz",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-mike-schatz",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-mike-schatz",
                "https://www.ndlegis.gov/biography/mike-schatz",
                "https://ndlegis.gov/biography/mike-schatz"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-39-lower-ocd-person-3075343c-75ba-4957-a1a2-411eb1708c6b": 0,
          "SLDL-ND-39-lower-ocd-person-a8e9d4c9-c6fa-41cf-8440-30ba429d4c63": 1
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/11d3d315-2e0d-4824-8ad2-6fd81962c9d1",
            "firstName": "Matthew",
            "lastName": "Ruby",
            "fullName": "Matt Ruby",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/mruby2023.jpg?itok=2-zeJhg7",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-ND-40-lower-ocd-person-11d3d315-2e0d-4824-8ad2-6fd81962c9d1",
              "builtID": "nd-lower-40",
              "externalID": "ocd-person/11d3d315-2e0d-4824-8ad2-6fd81962c9d1",
              "geometry": null
            },
            "contactInfo": {
              "email": "mruby@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-matthew-ruby",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-matthew-ruby",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-matthew-ruby",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-matthew-ruby",
                "https://www.ndlegis.gov/biography/matthew-ruby",
                "https://ndlegis.gov/biography/matthew-ruby"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8af43362-097c-4898-a147-0bfc87d3b38e",
            "firstName": "Randy A.",
            "lastName": "Schobinger",
            "fullName": "Randy Schobinger",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/rschobinger2023.jpg?itok=DQK5wUsL",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-ND-40-lower-ocd-person-8af43362-097c-4898-a147-0bfc87d3b38e",
              "builtID": "nd-lower-40",
              "externalID": "ocd-person/8af43362-097c-4898-a147-0bfc87d3b38e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rschobinger@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-randy-a-schobinger",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-randy-a-schobinger",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-randy-a-schobinger",
                "https://www.ndlegis.gov/biography/randy-a-schobinger",
                "https://ndlegis.gov/biography/randy-a-schobinger"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-40-lower-ocd-person-11d3d315-2e0d-4824-8ad2-6fd81962c9d1": 0,
          "SLDL-ND-40-lower-ocd-person-8af43362-097c-4898-a147-0bfc87d3b38e": 1
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/230e88ef-7b8d-4229-b121-c1df2328c78a",
            "firstName": "Jorin",
            "lastName": "Johnson",
            "fullName": "Jorin Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jjohnson2023.jpg?itok=jpJuUDcr",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-ND-41-lower-ocd-person-230e88ef-7b8d-4229-b121-c1df2328c78a",
              "builtID": "nd-lower-41",
              "externalID": "ocd-person/230e88ef-7b8d-4229-b121-c1df2328c78a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jjohnson@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/jorin-johnson",
                "https://ndlegis.gov/biography/jorin-johnson"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a6e733ce-ccdc-425a-bb9b-9010a09c3933",
            "firstName": "Michelle",
            "lastName": "Strinden",
            "fullName": "Michelle Strinden",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/mstrinden2023.jpg?itok=kiVZ9jcK",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-ND-41-lower-ocd-person-a6e733ce-ccdc-425a-bb9b-9010a09c3933",
              "builtID": "nd-lower-41",
              "externalID": "ocd-person/a6e733ce-ccdc-425a-bb9b-9010a09c3933",
              "geometry": null
            },
            "contactInfo": {
              "email": "mstrinden@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-michelle-strinden",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-michelle-strinden",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-michelle-strinden",
                "https://www.ndlegis.gov/biography/michelle-strinden",
                "https://ndlegis.gov/biography/michelle-strinden"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-41-lower-ocd-person-230e88ef-7b8d-4229-b121-c1df2328c78a": 0,
          "SLDL-ND-41-lower-ocd-person-a6e733ce-ccdc-425a-bb9b-9010a09c3933": 1
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/00d22ac4-d0e4-4b26-a521-729b177b3a01",
            "firstName": "Claire Mara",
            "lastName": "Cory",
            "fullName": "Claire Cory",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/clairecory2023.jpg?itok=iubraEWk",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-ND-42-lower-ocd-person-00d22ac4-d0e4-4b26-a521-729b177b3a01",
              "builtID": "nd-lower-42",
              "externalID": "ocd-person/00d22ac4-d0e4-4b26-a521-729b177b3a01",
              "geometry": null
            },
            "contactInfo": {
              "email": "clairecory@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-claire-cory",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-claire-cory",
                "https://www.ndlegis.gov/biography/claire-cory",
                "https://ndlegis.gov/biography/claire-cory"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/6e057049-e588-483b-bc2d-9e9f453284ba",
            "firstName": "Emily",
            "lastName": "O'Brien",
            "fullName": "Emily O'Brien",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/eobrien2023.jpg?itok=6WbXKYDq",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-ND-42-lower-ocd-person-6e057049-e588-483b-bc2d-9e9f453284ba",
              "builtID": "nd-lower-42",
              "externalID": "ocd-person/6e057049-e588-483b-bc2d-9e9f453284ba",
              "geometry": null
            },
            "contactInfo": {
              "email": "eobrien@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-emily-obrien",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-emily-obrien",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-emily-obrien",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-emily-obrien",
                "https://www.ndlegis.gov/biography/emily-obrien",
                "https://ndlegis.gov/biography/emily-obrien"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-42-lower-ocd-person-00d22ac4-d0e4-4b26-a521-729b177b3a01": 0,
          "SLDL-ND-42-lower-ocd-person-6e057049-e588-483b-bc2d-9e9f453284ba": 1
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/1caabacc-b2df-41c9-a0ff-5719a1d9dea5",
            "firstName": "Eric James",
            "lastName": "Murphy",
            "fullName": "Eric Murphy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/emurphy2023.jpg?itok=lb4RhNX6",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-ND-43-lower-ocd-person-1caabacc-b2df-41c9-a0ff-5719a1d9dea5",
              "builtID": "nd-lower-43",
              "externalID": "ocd-person/1caabacc-b2df-41c9-a0ff-5719a1d9dea5",
              "geometry": null
            },
            "contactInfo": {
              "email": "emurphy@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/eric-james-murphy",
                "https://ndlegis.gov/biography/eric-james-murphy"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/edf5f43d-33f6-4b77-a7c1-0b0e05a6b0be",
            "firstName": "Zachary Milo",
            "lastName": "Ista",
            "fullName": "Zac Ista",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/zmista2023.jpg?itok=KMVwRW52",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-ND-43-lower-ocd-person-edf5f43d-33f6-4b77-a7c1-0b0e05a6b0be",
              "builtID": "nd-lower-43",
              "externalID": "ocd-person/edf5f43d-33f6-4b77-a7c1-0b0e05a6b0be",
              "geometry": null
            },
            "contactInfo": {
              "email": "zmista@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-zachary-ista",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-zachary-ista",
                "https://www.ndlegis.gov/biography/zachary-ista",
                "https://ndlegis.gov/biography/zachary-ista"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-43-lower-ocd-person-1caabacc-b2df-41c9-a0ff-5719a1d9dea5": 0,
          "SLDL-ND-43-lower-ocd-person-edf5f43d-33f6-4b77-a7c1-0b0e05a6b0be": 1
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/50d67a3d-7267-4f21-93af-6827c45077f0",
            "firstName": "Joshua A.",
            "lastName": "Boschee",
            "fullName": "Josh Boschee",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jboschee2023.jpg?itok=dn-POEYf",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-ND-44-lower-ocd-person-50d67a3d-7267-4f21-93af-6827c45077f0",
              "builtID": "nd-lower-44",
              "externalID": "ocd-person/50d67a3d-7267-4f21-93af-6827c45077f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jboschee@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-joshua-a-boschee",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-josh-boschee",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-joshua-a-boschee",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-joshua-a-boschee",
                "https://www.ndlegis.gov/biography/josh-boschee",
                "https://ndlegis.gov/biography/josh-boschee"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/84b51f7f-dbd2-4d17-b698-4650964514cd",
            "firstName": "Karla Rose",
            "lastName": "Hanson",
            "fullName": "Karla Rose Hanson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/krhanson2023.jpg?itok=0UyDXXUY",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-ND-44-lower-ocd-person-84b51f7f-dbd2-4d17-b698-4650964514cd",
              "builtID": "nd-lower-44",
              "externalID": "ocd-person/84b51f7f-dbd2-4d17-b698-4650964514cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "krhanson@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-karla-rose-hanson",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-karla-rose-hanson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-karla-rose-hanson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-karla-rose-hanson",
                "https://www.ndlegis.gov/biography/karla-rose-hanson",
                "https://ndlegis.gov/biography/karla-rose-hanson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-44-lower-ocd-person-50d67a3d-7267-4f21-93af-6827c45077f0": 0,
          "SLDL-ND-44-lower-ocd-person-84b51f7f-dbd2-4d17-b698-4650964514cd": 1
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/57dff80c-9579-411d-a55e-b76846f15fd8",
            "firstName": "Carrie",
            "lastName": "McLeod",
            "fullName": "Carrie McLeod",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/cmcleod2023-4.jpg?itok=suT75cwX",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-ND-45-lower-ocd-person-57dff80c-9579-411d-a55e-b76846f15fd8",
              "builtID": "nd-lower-45",
              "externalID": "ocd-person/57dff80c-9579-411d-a55e-b76846f15fd8",
              "geometry": null
            },
            "contactInfo": {
              "email": "cmcleod@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/carrie-mcleod",
                "https://ndlegis.gov/biography/carrie-mcleod"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/29d5158e-c93e-4d69-9da5-70972c26c896",
            "firstName": "Scott",
            "lastName": "Wagner",
            "fullName": "Scott Wagner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/swagner2023.jpg?itok=qmmuUQ1o",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-ND-45-lower-ocd-person-29d5158e-c93e-4d69-9da5-70972c26c896",
              "builtID": "nd-lower-45",
              "externalID": "ocd-person/29d5158e-c93e-4d69-9da5-70972c26c896",
              "geometry": null
            },
            "contactInfo": {
              "email": "swagner@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/scott-wagner",
                "https://ndlegis.gov/biography/scott-wagner"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-45-lower-ocd-person-57dff80c-9579-411d-a55e-b76846f15fd8": 0,
          "SLDL-ND-45-lower-ocd-person-29d5158e-c93e-4d69-9da5-70972c26c896": 1
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/af2974f4-4889-4ea5-9cfd-93d68e2d39a2",
            "firstName": "James",
            "lastName": "Kasper",
            "fullName": "Jim Kasper",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jkasper2023.jpg?itok=5NfOEcjQ",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-ND-46-lower-ocd-person-af2974f4-4889-4ea5-9cfd-93d68e2d39a2",
              "builtID": "nd-lower-46",
              "externalID": "ocd-person/af2974f4-4889-4ea5-9cfd-93d68e2d39a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "jkasper@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-jim-kasper",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-jim-kasper",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-jim-kasper",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-jim-kasper",
                "https://www.ndlegis.gov/biography/jim-kasper",
                "https://ndlegis.gov/biography/jim-kasper"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/24ca8591-ef4f-4d63-ba3f-43f645005f19",
            "firstName": "Shannon M.",
            "lastName": "Roers Jones",
            "fullName": "Shannon Roers Jones",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/sroersjones2023.jpg?itok=BHF2qI6n",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-ND-46-lower-ocd-person-24ca8591-ef4f-4d63-ba3f-43f645005f19",
              "builtID": "nd-lower-46",
              "externalID": "ocd-person/24ca8591-ef4f-4d63-ba3f-43f645005f19",
              "geometry": null
            },
            "contactInfo": {
              "email": "sroersjones@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-shannon-m-roers-jones",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-shannon-roers-jones",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-shannon-roers-jones",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-shannon-roers-jones",
                "https://www.ndlegis.gov/biography/shannon-roers-jones",
                "https://ndlegis.gov/biography/shannon-roers-jones"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-46-lower-ocd-person-af2974f4-4889-4ea5-9cfd-93d68e2d39a2": 0,
          "SLDL-ND-46-lower-ocd-person-24ca8591-ef4f-4d63-ba3f-43f645005f19": 1
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/3de00ea0-0ad8-4875-8f7f-445b7e67a6d6",
            "firstName": "Lawrence R.",
            "lastName": "Klemin",
            "fullName": "Larry Klemin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/lklemin2023.jpg?itok=TpyVKyyr",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-ND-47-lower-ocd-person-3de00ea0-0ad8-4875-8f7f-445b7e67a6d6",
              "builtID": "nd-lower-47",
              "externalID": "ocd-person/3de00ea0-0ad8-4875-8f7f-445b7e67a6d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "lklemin@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-lawrence-r-klemin",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-lawrence-r-klemin",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-lawrence-r-klemin",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-lawrence-r-klemin",
                "https://www.ndlegis.gov/biography/lawrence-r-klemin",
                "https://ndlegis.gov/biography/lawrence-r-klemin"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a8e7843d-466a-4112-9238-707dbbac2696",
            "firstName": "Mike",
            "lastName": "Motschenbacher",
            "fullName": "Mike Motschenbacher",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/mmotschenbacher2023.jpg?itok=v3_JopNp",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-ND-47-lower-ocd-person-a8e7843d-466a-4112-9238-707dbbac2696",
              "builtID": "nd-lower-47",
              "externalID": "ocd-person/a8e7843d-466a-4112-9238-707dbbac2696",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmotschenbacher@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/mike-motschenbacher",
                "https://ndlegis.gov/biography/mike-motschenbacher"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-47-lower-ocd-person-3de00ea0-0ad8-4875-8f7f-445b7e67a6d6": 0,
          "SLDL-ND-47-lower-ocd-person-a8e7843d-466a-4112-9238-707dbbac2696": 1
        }
      },
      "4B": {
        "members": [
          {
            "API_ID": "ocd-person/0e57f5ef-a6aa-4019-bce7-da50c23b3a7a",
            "firstName": "Clayton",
            "lastName": "Fegley",
            "fullName": "Clayton Fegley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/cfegley2023.jpg?itok=e3uEeF1h",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "4B",
              "chamber": "lower",
              "hashID": "SLDL-ND-4B-lower-ocd-person-0e57f5ef-a6aa-4019-bce7-da50c23b3a7a",
              "builtID": "nd-lower-4b",
              "externalID": "ocd-person/0e57f5ef-a6aa-4019-bce7-da50c23b3a7a",
              "geometry": null
            },
            "contactInfo": {
              "email": "cfegley@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-clayton-fegley",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-clayton-fegley",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-clayton-fegley",
                "https://www.ndlegis.gov/biography/clayton-fegley",
                "https://ndlegis.gov/biography/clayton-fegley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-4B-lower-ocd-person-0e57f5ef-a6aa-4019-bce7-da50c23b3a7a": 0
        }
      },
      "9B": {
        "members": [
          {
            "API_ID": "ocd-person/56cc14e2-12cc-4d70-b37a-0aa0397bc841",
            "firstName": "Donna",
            "lastName": "Henderson",
            "fullName": "Donna Henderson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/dhenderson2023.jpg?itok=1gm0QdP4",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "9B",
              "chamber": "lower",
              "hashID": "SLDL-ND-9B-lower-ocd-person-56cc14e2-12cc-4d70-b37a-0aa0397bc841",
              "builtID": "nd-lower-9b",
              "externalID": "ocd-person/56cc14e2-12cc-4d70-b37a-0aa0397bc841",
              "geometry": null
            },
            "contactInfo": {
              "email": "dhenderson@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/donna-henderson",
                "https://ndlegis.gov/biography/donna-henderson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-9B-lower-ocd-person-56cc14e2-12cc-4d70-b37a-0aa0397bc841": 0
        }
      },
      "9A": {
        "members": [
          {
            "API_ID": "ocd-person/967219db-3714-49e4-8642-5fb444ef882c",
            "firstName": "Jayme M.",
            "lastName": "Davis",
            "fullName": "Jayme Davis",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jdavis2023.jpg?itok=u9HL5YrU",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "9A",
              "chamber": "lower",
              "hashID": "SLDL-ND-9A-lower-ocd-person-967219db-3714-49e4-8642-5fb444ef882c",
              "builtID": "nd-lower-9a",
              "externalID": "ocd-person/967219db-3714-49e4-8642-5fb444ef882c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jdavis@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/jayme-davis",
                "https://ndlegis.gov/biography/jayme-davis"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-9A-lower-ocd-person-967219db-3714-49e4-8642-5fb444ef882c": 0
        }
      },
      "4A": {
        "members": [
          {
            "API_ID": "ocd-person/bf09d70e-a605-43f0-bcca-57ece9c06214",
            "firstName": "Lisa",
            "lastName": "Finley-DeVille",
            "fullName": "Lisa Finley-DeVille",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/lfinleydeville2023.jpg?itok=__y0zred",
            "title": "ND Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "4A",
              "chamber": "lower",
              "hashID": "SLDL-ND-4A-lower-ocd-person-bf09d70e-a605-43f0-bcca-57ece9c06214",
              "builtID": "nd-lower-4a",
              "externalID": "ocd-person/bf09d70e-a605-43f0-bcca-57ece9c06214",
              "geometry": null
            },
            "contactInfo": {
              "email": "lfinleydeville@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/lisa-finley-deville",
                "https://ndlegis.gov/biography/lisa-finley-deville"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-ND-4A-lower-ocd-person-bf09d70e-a605-43f0-bcca-57ece9c06214": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/86d15ce1-f1e9-40f8-894c-490f3d960e15",
            "firstName": "Brad",
            "lastName": "Bekkedahl",
            "fullName": "Brad Bekkedahl",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/bbekkedahl2023_0.jpg?itok=VB17jlNw",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-ND-1-upper-ocd-person-86d15ce1-f1e9-40f8-894c-490f3d960e15",
              "builtID": "nd-upper-1",
              "externalID": "ocd-person/86d15ce1-f1e9-40f8-894c-490f3d960e15",
              "geometry": null
            },
            "contactInfo": {
              "email": "bbekkedahl@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-brad-bekkedahl",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-brad-bekkedahl",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-brad-bekkedahl",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-brad-bekkedahl",
                "https://www.ndlegis.gov/biography/brad-bekkedahl",
                "https://ndlegis.gov/biography/brad-bekkedahl"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-1-upper-ocd-person-86d15ce1-f1e9-40f8-894c-490f3d960e15": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/1707b739-8cd7-4aca-b26a-f1756c02d446",
            "firstName": "David S.",
            "lastName": "Rust",
            "fullName": "David Rust",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/drust2023.jpg?itok=yYs3nUa9",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-ND-2-upper-ocd-person-1707b739-8cd7-4aca-b26a-f1756c02d446",
              "builtID": "nd-upper-2",
              "externalID": "ocd-person/1707b739-8cd7-4aca-b26a-f1756c02d446",
              "geometry": null
            },
            "contactInfo": {
              "email": "drust@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-david-s-rust",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-david-s-rust",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-david-s-rust",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-david-s-rust",
                "https://www.ndlegis.gov/biography/david-s-rust",
                "https://ndlegis.gov/biography/david-s-rust"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-2-upper-ocd-person-1707b739-8cd7-4aca-b26a-f1756c02d446": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/983f512c-6f3f-4243-b6c8-b83d9d4f3696",
            "firstName": "Bob",
            "lastName": "Paulson",
            "fullName": "Bob Paulson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/bpaulson2023.jpg?itok=SDpdNURV",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-ND-3-upper-ocd-person-983f512c-6f3f-4243-b6c8-b83d9d4f3696",
              "builtID": "nd-upper-3",
              "externalID": "ocd-person/983f512c-6f3f-4243-b6c8-b83d9d4f3696",
              "geometry": null
            },
            "contactInfo": {
              "email": "bpaulson@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-bob-paulson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-bob-paulson",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-bob-paulson",
                "https://www.ndlegis.gov/biography/bob-paulson",
                "https://ndlegis.gov/biography/bob-paulson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-3-upper-ocd-person-983f512c-6f3f-4243-b6c8-b83d9d4f3696": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/b29605fa-8f3d-4486-a44f-c220349b318d",
            "firstName": "Jordan L.",
            "lastName": "Kannianen",
            "fullName": "Jordan Kannianen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jkannianen2023.jpg?itok=DYm2jFpj",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-ND-4-upper-ocd-person-b29605fa-8f3d-4486-a44f-c220349b318d",
              "builtID": "nd-upper-4",
              "externalID": "ocd-person/b29605fa-8f3d-4486-a44f-c220349b318d",
              "geometry": null
            },
            "contactInfo": {
              "email": "jkannianen@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-jordan-kannianen",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-jordan-kannianen",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-jordan-kannianen",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-jordan-kannianen",
                "https://www.ndlegis.gov/biography/jordan-l-kannianen",
                "https://ndlegis.gov/biography/jordan-l-kannianen"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-4-upper-ocd-person-b29605fa-8f3d-4486-a44f-c220349b318d": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/c7d25cc2-0a28-4001-b046-df3e3a996f21",
            "firstName": "Randall A.",
            "lastName": "Burckhard",
            "fullName": "Randy Burckhard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/raburckhard2023_2.jpg?itok=-URLZovB",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-ND-5-upper-ocd-person-c7d25cc2-0a28-4001-b046-df3e3a996f21",
              "builtID": "nd-upper-5",
              "externalID": "ocd-person/c7d25cc2-0a28-4001-b046-df3e3a996f21",
              "geometry": null
            },
            "contactInfo": {
              "email": "raburckhard@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-randall-a-burckhard",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-randy-burckhard",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-randy-a-burckhard",
                "https://www.ndlegis.gov/biography/randy-a-burckhard",
                "https://ndlegis.gov/biography/randy-a-burckhard"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-5-upper-ocd-person-c7d25cc2-0a28-4001-b046-df3e3a996f21": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/1ab78fc1-e64b-473f-8d89-54e1de0f47a4",
            "firstName": "Shawn A.",
            "lastName": "Vedaa",
            "fullName": "Shawn Vedaa",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/svedaa2023.jpg?itok=rE5xNVuB",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-ND-6-upper-ocd-person-1ab78fc1-e64b-473f-8d89-54e1de0f47a4",
              "builtID": "nd-upper-6",
              "externalID": "ocd-person/1ab78fc1-e64b-473f-8d89-54e1de0f47a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "svedaa@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-shawn-vedaa",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-shawn-vedaa",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-shawn-vedaa",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-shawn-vedaa",
                "https://www.ndlegis.gov/biography/shawn-vedaa",
                "https://ndlegis.gov/biography/shawn-vedaa"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-6-upper-ocd-person-1ab78fc1-e64b-473f-8d89-54e1de0f47a4": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/281253e2-e075-4cde-92dd-20d097bc2b3b",
            "firstName": "Michelle",
            "lastName": "Axtman",
            "fullName": "Michelle Axtman",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/maxtman2023.jpg?itok=RtENCCkC",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-ND-7-upper-ocd-person-281253e2-e075-4cde-92dd-20d097bc2b3b",
              "builtID": "nd-upper-7",
              "externalID": "ocd-person/281253e2-e075-4cde-92dd-20d097bc2b3b",
              "geometry": null
            },
            "contactInfo": {
              "email": "maxtman@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/michelle-axtman",
                "https://ndlegis.gov/biography/michelle-axtman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-7-upper-ocd-person-281253e2-e075-4cde-92dd-20d097bc2b3b": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/9c20611f-bea1-4176-b98f-3b20537e6818",
            "firstName": "Jeffery J.",
            "lastName": "Magrum",
            "fullName": "Jeff Magrum",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jmagrum2023.jpg?itok=nshWDgsW",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-ND-8-upper-ocd-person-9c20611f-bea1-4176-b98f-3b20537e6818",
              "builtID": "nd-upper-8",
              "externalID": "ocd-person/9c20611f-bea1-4176-b98f-3b20537e6818",
              "geometry": null
            },
            "contactInfo": {
              "email": "jmagrum@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-jeffery-j-magrum",
                "https://www.legis.nd.gov/assembly/66-2019/members/house/representative-jeffery-j-magrum",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-jeffery-j-magrum",
                "https://www.legis.nd.gov/assembly/67-2021/members/house/representative-jeffery-j-magrum",
                "https://www.ndlegis.gov/biography/jeffery-j-magrum",
                "https://ndlegis.gov/biography/jeffery-j-magrum"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-8-upper-ocd-person-9c20611f-bea1-4176-b98f-3b20537e6818": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/f1041f2b-2e6e-41b0-9313-9767f7c03dbe",
            "firstName": "Kent",
            "lastName": "Weston",
            "fullName": "Kent Weston",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/kweston2023.jpg?itok=uNPsAGmo",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-ND-9-upper-ocd-person-f1041f2b-2e6e-41b0-9313-9767f7c03dbe",
              "builtID": "nd-upper-9",
              "externalID": "ocd-person/f1041f2b-2e6e-41b0-9313-9767f7c03dbe",
              "geometry": null
            },
            "contactInfo": {
              "email": "kweston@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/kent-weston",
                "https://ndlegis.gov/biography/kent-weston"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-9-upper-ocd-person-f1041f2b-2e6e-41b0-9313-9767f7c03dbe": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/66b053e6-9766-40f0-8da3-2a40f517d9e1",
            "firstName": "Ryan",
            "lastName": "Braunberger",
            "fullName": "Ryan Braunberger",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/rbraunberger2023.jpg?itok=jt9B_X6q",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-ND-10-upper-ocd-person-66b053e6-9766-40f0-8da3-2a40f517d9e1",
              "builtID": "nd-upper-10",
              "externalID": "ocd-person/66b053e6-9766-40f0-8da3-2a40f517d9e1",
              "geometry": null
            },
            "contactInfo": {
              "email": "rbraunberger@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/ryan-braunberger",
                "https://ndlegis.gov/biography/ryan-braunberger"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-10-upper-ocd-person-66b053e6-9766-40f0-8da3-2a40f517d9e1": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/f715b93a-dcf7-4a67-b466-10c2cfad8a60",
            "firstName": "Timothy",
            "lastName": "Mathern",
            "fullName": "Tim Mathern",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/tmathern2023.jpg?itok=pMFcb5S4",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-ND-11-upper-ocd-person-f715b93a-dcf7-4a67-b466-10c2cfad8a60",
              "builtID": "nd-upper-11",
              "externalID": "ocd-person/f715b93a-dcf7-4a67-b466-10c2cfad8a60",
              "geometry": null
            },
            "contactInfo": {
              "email": "tmathern@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-tim-mathern",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-tim-mathern",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-tim-mathern",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-tim-mathern",
                "https://www.ndlegis.gov/biography/tim-mathern",
                "https://ndlegis.gov/biography/tim-mathern"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-11-upper-ocd-person-f715b93a-dcf7-4a67-b466-10c2cfad8a60": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/3b75961c-1c9e-4150-84ee-965ffb59fafa",
            "firstName": "Cole",
            "lastName": "Conley",
            "fullName": "Cole Conley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/cconley2023.jpg?itok=BTF7DH8T",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-ND-12-upper-ocd-person-3b75961c-1c9e-4150-84ee-965ffb59fafa",
              "builtID": "nd-upper-12",
              "externalID": "ocd-person/3b75961c-1c9e-4150-84ee-965ffb59fafa",
              "geometry": null
            },
            "contactInfo": {
              "email": "cconley@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-cole-conley",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-cole-conley",
                "https://www.ndlegis.gov/biography/cole-conley",
                "https://ndlegis.gov/biography/cole-conley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-12-upper-ocd-person-3b75961c-1c9e-4150-84ee-965ffb59fafa": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/75e58364-853b-4b08-b902-7c84deb98e4a",
            "firstName": "Judy",
            "lastName": "Lee",
            "fullName": "Judy Lee",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jlee2023.jpg?itok=Om_GrVrc",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-ND-13-upper-ocd-person-75e58364-853b-4b08-b902-7c84deb98e4a",
              "builtID": "nd-upper-13",
              "externalID": "ocd-person/75e58364-853b-4b08-b902-7c84deb98e4a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jlee@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-judy-lee",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-judy-lee",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-judy-lee",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-judy-lee",
                "https://www.ndlegis.gov/biography/judy-lee",
                "https://ndlegis.gov/biography/judy-lee"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-13-upper-ocd-person-75e58364-853b-4b08-b902-7c84deb98e4a": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/714275ca-f81c-4fae-af2e-248a5ba4d697",
            "firstName": "Jerry J.",
            "lastName": "Klein",
            "fullName": "Jerry Klein",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jklein2023.jpg?itok=tB7GzeMl",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-ND-14-upper-ocd-person-714275ca-f81c-4fae-af2e-248a5ba4d697",
              "builtID": "nd-upper-14",
              "externalID": "ocd-person/714275ca-f81c-4fae-af2e-248a5ba4d697",
              "geometry": null
            },
            "contactInfo": {
              "email": "jklein@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-jerry-klein",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-jerry-klein",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-jerry-klein",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-jerry-klein",
                "https://www.ndlegis.gov/biography/jerry-klein",
                "https://ndlegis.gov/biography/jerry-klein"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-14-upper-ocd-person-714275ca-f81c-4fae-af2e-248a5ba4d697": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/0a35d351-0a19-4bc9-850c-09894d104039",
            "firstName": "Judy",
            "lastName": "Estenson",
            "fullName": "Judy Estenson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jestenson2023_2.jpg?itok=HH3WxFig",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-ND-15-upper-ocd-person-0a35d351-0a19-4bc9-850c-09894d104039",
              "builtID": "nd-upper-15",
              "externalID": "ocd-person/0a35d351-0a19-4bc9-850c-09894d104039",
              "geometry": null
            },
            "contactInfo": {
              "email": "jestenson@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/judy-estenson",
                "https://ndlegis.gov/biography/judy-estenson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-15-upper-ocd-person-0a35d351-0a19-4bc9-850c-09894d104039": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/e93f45b9-72c9-4e10-bc05-4896381f29f4",
            "firstName": "David A.",
            "lastName": "Clemens",
            "fullName": "David Clemens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/dclemens2023.jpg?itok=suQ6Tlaw",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-ND-16-upper-ocd-person-e93f45b9-72c9-4e10-bc05-4896381f29f4",
              "builtID": "nd-upper-16",
              "externalID": "ocd-person/e93f45b9-72c9-4e10-bc05-4896381f29f4",
              "geometry": null
            },
            "contactInfo": {
              "email": "dclemens@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-david-a-clemens",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-david-a-clemens",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-david-a-clemens",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-david-a-clemens",
                "https://www.ndlegis.gov/biography/david-a-clemens",
                "https://ndlegis.gov/biography/david-a-clemens"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-16-upper-ocd-person-e93f45b9-72c9-4e10-bc05-4896381f29f4": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/e0da9b3a-45c3-4776-b6cc-eebd3d64d785",
            "firstName": "Jonathan Lee",
            "lastName": "Sickler",
            "fullName": "Jonathan Sickler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jlsickler2023.jpg?itok=fCgcmLaT",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-ND-17-upper-ocd-person-e0da9b3a-45c3-4776-b6cc-eebd3d64d785",
              "builtID": "nd-upper-17",
              "externalID": "ocd-person/e0da9b3a-45c3-4776-b6cc-eebd3d64d785",
              "geometry": null
            },
            "contactInfo": {
              "email": "jlsickler@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ndlegis.gov/biography/jonathan-sickler"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-17-upper-ocd-person-e0da9b3a-45c3-4776-b6cc-eebd3d64d785": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/5bcd9ecd-d547-4336-aa69-b4b258085802",
            "firstName": "Scott",
            "lastName": "Meyer",
            "fullName": "Scott Meyer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/scottmeyer2023.jpg?itok=1CtjZ6FW",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-ND-18-upper-ocd-person-5bcd9ecd-d547-4336-aa69-b4b258085802",
              "builtID": "nd-upper-18",
              "externalID": "ocd-person/5bcd9ecd-d547-4336-aa69-b4b258085802",
              "geometry": null
            },
            "contactInfo": {
              "email": "scottmeyer@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-scott-meyer",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-scott-meyer",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-scott-meyer",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-scott-meyer",
                "https://www.ndlegis.gov/biography/scott-meyer",
                "https://ndlegis.gov/biography/scott-meyer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-18-upper-ocd-person-5bcd9ecd-d547-4336-aa69-b4b258085802": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/4a64529e-c299-4979-a564-909dc9f2edd2",
            "firstName": "Janne",
            "lastName": "Myrdal",
            "fullName": "Janne Myrdal",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jmyrdal.jpg?itok=w1QxJMzq",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-ND-19-upper-ocd-person-4a64529e-c299-4979-a564-909dc9f2edd2",
              "builtID": "nd-upper-19",
              "externalID": "ocd-person/4a64529e-c299-4979-a564-909dc9f2edd2",
              "geometry": null
            },
            "contactInfo": {
              "email": "jmyrdal@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-janne-myrdal",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-janne-myrdal",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-janne-myrdal",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-janne-myrdal",
                "https://www.ndlegis.gov/biography/janne-myrdal",
                "https://ndlegis.gov/biography/janne-myrdal"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-19-upper-ocd-person-4a64529e-c299-4979-a564-909dc9f2edd2": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/c8e63920-e054-4bb3-aff3-e9caeb012d2c",
            "firstName": "Randy D.",
            "lastName": "Lemm",
            "fullName": "Randy Lemm",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/rlemm2023.jpg?itok=7pnwdctH",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-ND-20-upper-ocd-person-c8e63920-e054-4bb3-aff3-e9caeb012d2c",
              "builtID": "nd-upper-20",
              "externalID": "ocd-person/c8e63920-e054-4bb3-aff3-e9caeb012d2c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rlemm@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-randy-d-lemm",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-randy-d-lemm",
                "https://www.ndlegis.gov/biography/randy-d-lemm",
                "https://ndlegis.gov/biography/randy-d-lemm"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-20-upper-ocd-person-c8e63920-e054-4bb3-aff3-e9caeb012d2c": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/03924b26-a081-4722-947b-2738200f9063",
            "firstName": "Kathy",
            "lastName": "Hogan",
            "fullName": "Kathy Hogan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/khogan2023.jpg?itok=kb5LgV9d",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-ND-21-upper-ocd-person-03924b26-a081-4722-947b-2738200f9063",
              "builtID": "nd-upper-21",
              "externalID": "ocd-person/03924b26-a081-4722-947b-2738200f9063",
              "geometry": null
            },
            "contactInfo": {
              "email": "khogan@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/house/representative-kathy-hogan",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-kathy-hogan",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-kathy-hogan",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-kathy-hogan",
                "https://www.ndlegis.gov/biography/kathy-hogan",
                "https://ndlegis.gov/biography/kathy-hogan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-21-upper-ocd-person-03924b26-a081-4722-947b-2738200f9063": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/450cf40c-eb15-4f0d-843f-3a3e071d54fc",
            "firstName": "Mark F.",
            "lastName": "Weber",
            "fullName": "Mark Weber",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/markweber2023.jpg?itok=ae8Xlskt",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-ND-22-upper-ocd-person-450cf40c-eb15-4f0d-843f-3a3e071d54fc",
              "builtID": "nd-upper-22",
              "externalID": "ocd-person/450cf40c-eb15-4f0d-843f-3a3e071d54fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "markweber@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-mark-f-weber",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-mark-f-weber",
                "https://www.ndlegis.gov/biography/mark-f-weber",
                "https://ndlegis.gov/biography/mark-f-weber"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-22-upper-ocd-person-450cf40c-eb15-4f0d-843f-3a3e071d54fc": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/f01e3e40-01d3-4290-a85b-760683d4292e",
            "firstName": "Todd",
            "lastName": "Beard",
            "fullName": "Todd Beard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/tbeard2023.jpg?itok=tC5PWQQL",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-ND-23-upper-ocd-person-f01e3e40-01d3-4290-a85b-760683d4292e",
              "builtID": "nd-upper-23",
              "externalID": "ocd-person/f01e3e40-01d3-4290-a85b-760683d4292e",
              "geometry": null
            },
            "contactInfo": {
              "email": "tbeard@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/todd-beard",
                "https://ndlegis.gov/biography/todd-beard"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-23-upper-ocd-person-f01e3e40-01d3-4290-a85b-760683d4292e": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/a5665c05-d239-4297-a3dc-08557280d5f8",
            "firstName": "Michael A.",
            "lastName": "Wobbema",
            "fullName": "Mike Wobbema",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/mwobbema2023.jpg?itok=-RzV9t-2",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-ND-24-upper-ocd-person-a5665c05-d239-4297-a3dc-08557280d5f8",
              "builtID": "nd-upper-24",
              "externalID": "ocd-person/a5665c05-d239-4297-a3dc-08557280d5f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "mwobbema@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-michael-a-wobbema",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-michael-a-wobbema",
                "https://www.ndlegis.gov/biography/michael-a-wobbema",
                "https://ndlegis.gov/biography/michael-a-wobbema"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-24-upper-ocd-person-a5665c05-d239-4297-a3dc-08557280d5f8": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/bbce3e80-9e27-4dfe-a7db-0c2b3bcc0b94",
            "firstName": "Larry",
            "lastName": "Luick",
            "fullName": "Larry Luick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/lluick2023.jpg?itok=JQtt6TTF",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-ND-25-upper-ocd-person-bbce3e80-9e27-4dfe-a7db-0c2b3bcc0b94",
              "builtID": "nd-upper-25",
              "externalID": "ocd-person/bbce3e80-9e27-4dfe-a7db-0c2b3bcc0b94",
              "geometry": null
            },
            "contactInfo": {
              "email": "lluick@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-larry-luick",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-larry-luick",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-larry-luick",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-larry-luick",
                "https://www.ndlegis.gov/biography/larry-luick",
                "https://ndlegis.gov/biography/larry-luick"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-25-upper-ocd-person-bbce3e80-9e27-4dfe-a7db-0c2b3bcc0b94": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/ffc100fb-2fbe-47d1-bfde-a2e4b0acae3c",
            "firstName": "Dale",
            "lastName": "Patten",
            "fullName": "Dale Patten",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/dpatten2023.jpg?itok=wc6NP5DG",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-ND-26-upper-ocd-person-ffc100fb-2fbe-47d1-bfde-a2e4b0acae3c",
              "builtID": "nd-upper-26",
              "externalID": "ocd-person/ffc100fb-2fbe-47d1-bfde-a2e4b0acae3c",
              "geometry": null
            },
            "contactInfo": {
              "email": "dpatten@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-dale-patten",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-dale-patten",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-dale-patten",
                "https://www.ndlegis.gov/biography/dale-patten",
                "https://ndlegis.gov/biography/dale-patten"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-26-upper-ocd-person-ffc100fb-2fbe-47d1-bfde-a2e4b0acae3c": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/dd6fc6e2-9b7f-405a-9121-28afd911ecdd",
            "firstName": "Kristin",
            "lastName": "Roers",
            "fullName": "Kristin Roers",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/kroers2023_2.jpg?itok=aijums9Q",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-ND-27-upper-ocd-person-dd6fc6e2-9b7f-405a-9121-28afd911ecdd",
              "builtID": "nd-upper-27",
              "externalID": "ocd-person/dd6fc6e2-9b7f-405a-9121-28afd911ecdd",
              "geometry": null
            },
            "contactInfo": {
              "email": "kroers@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-kristin-roers",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-kristin-roers",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-kristin-roers",
                "https://www.ndlegis.gov/biography/kristin-roers",
                "https://ndlegis.gov/biography/kristin-roers"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-27-upper-ocd-person-dd6fc6e2-9b7f-405a-9121-28afd911ecdd": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/e3f498cc-b127-4f43-be89-1ad84b3b4e78",
            "firstName": "Robert",
            "lastName": "Erbele",
            "fullName": "Robert Erbele",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/rerbele2023.jpg?itok=o4UUUAGp",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-ND-28-upper-ocd-person-e3f498cc-b127-4f43-be89-1ad84b3b4e78",
              "builtID": "nd-upper-28",
              "externalID": "ocd-person/e3f498cc-b127-4f43-be89-1ad84b3b4e78",
              "geometry": null
            },
            "contactInfo": {
              "email": "rerbele@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-robert-erbele",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-robert-erbele",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-robert-erbele",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-robert-erbele",
                "https://www.ndlegis.gov/biography/robert-erbele",
                "https://ndlegis.gov/biography/robert-erbele"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-28-upper-ocd-person-e3f498cc-b127-4f43-be89-1ad84b3b4e78": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/b40f2559-d9ae-41c2-b539-bd9c673cd90d",
            "firstName": "Terry M.",
            "lastName": "Wanzek",
            "fullName": "Terry Wanzek",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/tmwanzek2023.jpg?itok=LOr-WCkO",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-ND-29-upper-ocd-person-b40f2559-d9ae-41c2-b539-bd9c673cd90d",
              "builtID": "nd-upper-29",
              "externalID": "ocd-person/b40f2559-d9ae-41c2-b539-bd9c673cd90d",
              "geometry": null
            },
            "contactInfo": {
              "email": "tmwanzek@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-terry-m-wanzek",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-terry-m-wanzek",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-terry-m-wanzek",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-terry-m-wanzek",
                "https://www.ndlegis.gov/biography/terry-m-wanzek",
                "https://ndlegis.gov/biography/terry-m-wanzek"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-29-upper-ocd-person-b40f2559-d9ae-41c2-b539-bd9c673cd90d": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/9844a188-809c-4711-8147-2c30206721ee",
            "firstName": "Diane",
            "lastName": "Larson",
            "fullName": "Diane Larson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/dlarson2023.jpg?itok=Yi-S-Uyp",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-ND-30-upper-ocd-person-9844a188-809c-4711-8147-2c30206721ee",
              "builtID": "nd-upper-30",
              "externalID": "ocd-person/9844a188-809c-4711-8147-2c30206721ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "dlarson@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-diane-larson",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-diane-larson",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-diane-larson",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-diane-larson",
                "https://www.ndlegis.gov/biography/diane-larson",
                "https://ndlegis.gov/biography/diane-larson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-30-upper-ocd-person-9844a188-809c-4711-8147-2c30206721ee": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/93c8011d-d8c2-4e7e-80ee-3b1cab991039",
            "firstName": "Donald",
            "lastName": "Schaible",
            "fullName": "Don Schaible",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/dgschaible2023.jpg?itok=Zjq3ZT4x",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-ND-31-upper-ocd-person-93c8011d-d8c2-4e7e-80ee-3b1cab991039",
              "builtID": "nd-upper-31",
              "externalID": "ocd-person/93c8011d-d8c2-4e7e-80ee-3b1cab991039",
              "geometry": null
            },
            "contactInfo": {
              "email": "dgschaible@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-donald-schaible",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-donald-schaible",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-donald-schaible",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-donald-schaible",
                "https://www.ndlegis.gov/biography/donald-schaible",
                "https://ndlegis.gov/biography/donald-schaible"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-31-upper-ocd-person-93c8011d-d8c2-4e7e-80ee-3b1cab991039": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/afb1de56-a1cf-439a-8a9f-3fcaa73c691a",
            "firstName": "Dick",
            "lastName": "Dever",
            "fullName": "Dick Dever",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/ddever2023.jpg?itok=W1J0-NtI",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-ND-32-upper-ocd-person-afb1de56-a1cf-439a-8a9f-3fcaa73c691a",
              "builtID": "nd-upper-32",
              "externalID": "ocd-person/afb1de56-a1cf-439a-8a9f-3fcaa73c691a",
              "geometry": null
            },
            "contactInfo": {
              "email": "ddever@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-dick-dever",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-dick-dever",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-dick-dever",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-dick-dever",
                "https://www.ndlegis.gov/biography/dick-dever",
                "https://ndlegis.gov/biography/dick-dever"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-32-upper-ocd-person-afb1de56-a1cf-439a-8a9f-3fcaa73c691a": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/61200f69-4501-4cfb-a9cf-a37e86d6604c",
            "firstName": "Keith",
            "lastName": "Boehm",
            "fullName": "Keith Boehm",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/kboehm2023.jpg?itok=U-O9r8u7",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-ND-33-upper-ocd-person-61200f69-4501-4cfb-a9cf-a37e86d6604c",
              "builtID": "nd-upper-33",
              "externalID": "ocd-person/61200f69-4501-4cfb-a9cf-a37e86d6604c",
              "geometry": null
            },
            "contactInfo": {
              "email": "kboehm@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/keith-boehm",
                "https://ndlegis.gov/biography/keith-boehm"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-33-upper-ocd-person-61200f69-4501-4cfb-a9cf-a37e86d6604c": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/17f0bfb4-083b-460d-9b3f-483079df42c6",
            "firstName": "Justin",
            "lastName": "Gerhardt",
            "fullName": "Justin Gerhardt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jgerhardt2.jpg?itok=0hJ_0R_C",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-ND-34-upper-ocd-person-17f0bfb4-083b-460d-9b3f-483079df42c6",
              "builtID": "nd-upper-34",
              "externalID": "ocd-person/17f0bfb4-083b-460d-9b3f-483079df42c6",
              "geometry": null
            },
            "contactInfo": {
              "email": "jgerhardt@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/justin-gerhardt",
                "https://ndlegis.gov/biography/justin-gerhardt"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-34-upper-ocd-person-17f0bfb4-083b-460d-9b3f-483079df42c6": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/ee32295a-16ef-45df-b47e-f74ac5f1765b",
            "firstName": "Sean D.",
            "lastName": "Cleary",
            "fullName": "Sean Cleary",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/scleary2023.jpg?itok=iooji8l4",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-ND-35-upper-ocd-person-ee32295a-16ef-45df-b47e-f74ac5f1765b",
              "builtID": "nd-upper-35",
              "externalID": "ocd-person/ee32295a-16ef-45df-b47e-f74ac5f1765b",
              "geometry": null
            },
            "contactInfo": {
              "email": "scleary@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/sean-cleary",
                "https://ndlegis.gov/biography/sean-cleary"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-35-upper-ocd-person-ee32295a-16ef-45df-b47e-f74ac5f1765b": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/3467e8b1-0f84-4f20-b816-aee408145592",
            "firstName": "Jay",
            "lastName": "Elkin",
            "fullName": "Jay Elkin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jayelkin2023.jpg?itok=8dfOxqlm",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-ND-36-upper-ocd-person-3467e8b1-0f84-4f20-b816-aee408145592",
              "builtID": "nd-upper-36",
              "externalID": "ocd-person/3467e8b1-0f84-4f20-b816-aee408145592",
              "geometry": null
            },
            "contactInfo": {
              "email": "jayelkin@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-jay-elkin",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-jay-r-elkin",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-jay-r-elkin",
                "https://www.ndlegis.gov/biography/jay-elkin",
                "https://ndlegis.gov/biography/jay-elkin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-36-upper-ocd-person-3467e8b1-0f84-4f20-b816-aee408145592": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/cc65fd55-6d87-4ef9-95ff-b98592bebc8f",
            "firstName": "Dean",
            "lastName": "Rummel",
            "fullName": "Dean Rummel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/drummel2023.jpg?itok=8NfcLHzU",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-ND-37-upper-ocd-person-cc65fd55-6d87-4ef9-95ff-b98592bebc8f",
              "builtID": "nd-upper-37",
              "externalID": "ocd-person/cc65fd55-6d87-4ef9-95ff-b98592bebc8f",
              "geometry": null
            },
            "contactInfo": {
              "email": "drummel@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/dean-rummel",
                "https://ndlegis.gov/biography/dean-rummel"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-37-upper-ocd-person-cc65fd55-6d87-4ef9-95ff-b98592bebc8f": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/e911d1c9-258a-47c6-acd4-d0f8a3befc2f",
            "firstName": "David",
            "lastName": "Hogue",
            "fullName": "David Hogue",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/dhogue2023.jpg?itok=aH-5nj6J",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-ND-38-upper-ocd-person-e911d1c9-258a-47c6-acd4-d0f8a3befc2f",
              "builtID": "nd-upper-38",
              "externalID": "ocd-person/e911d1c9-258a-47c6-acd4-d0f8a3befc2f",
              "geometry": null
            },
            "contactInfo": {
              "email": "dhogue@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-david-hogue",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-david-hogue",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-david-hogue",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-david-hogue",
                "https://www.ndlegis.gov/biography/david-hogue",
                "https://ndlegis.gov/biography/david-hogue"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-38-upper-ocd-person-e911d1c9-258a-47c6-acd4-d0f8a3befc2f": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/6c9671e4-274f-41cd-b1ba-d6b2dd55ba5e",
            "firstName": "Greg",
            "lastName": "Kessel",
            "fullName": "Greg Kessel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/gkessel2023.jpg?itok=VVvSgZzs",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-ND-39-upper-ocd-person-6c9671e4-274f-41cd-b1ba-d6b2dd55ba5e",
              "builtID": "nd-upper-39",
              "externalID": "ocd-person/6c9671e4-274f-41cd-b1ba-d6b2dd55ba5e",
              "geometry": null
            },
            "contactInfo": {
              "email": "gkessel@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/greg-kessel",
                "https://ndlegis.gov/biography/greg-kessel"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-39-upper-ocd-person-6c9671e4-274f-41cd-b1ba-d6b2dd55ba5e": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/647e01aa-f954-460b-ae0d-ca89ecdfc918",
            "firstName": "Karen K.",
            "lastName": "Krebsbach",
            "fullName": "Karen Krebsbach",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/kkrebsbach2023.jpg?itok=BDOrKzoG",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-ND-40-upper-ocd-person-647e01aa-f954-460b-ae0d-ca89ecdfc918",
              "builtID": "nd-upper-40",
              "externalID": "ocd-person/647e01aa-f954-460b-ae0d-ca89ecdfc918",
              "geometry": null
            },
            "contactInfo": {
              "email": "kkrebsbach@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-karen-k-krebsbach",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-karen-k-krebsbach",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-karen-k-krebsbach",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-karen-k-krebsbach",
                "https://www.ndlegis.gov/biography/karen-k-krebsbach",
                "https://ndlegis.gov/biography/karen-k-krebsbach"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-40-upper-ocd-person-647e01aa-f954-460b-ae0d-ca89ecdfc918": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/4412269d-782d-4ea2-a649-e62a30cd1739",
            "firstName": "Kyle R.",
            "lastName": "Davison",
            "fullName": "Kyle Davison",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/kdavison2023.jpg?itok=dKldTmD0",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-ND-41-upper-ocd-person-4412269d-782d-4ea2-a649-e62a30cd1739",
              "builtID": "nd-upper-41",
              "externalID": "ocd-person/4412269d-782d-4ea2-a649-e62a30cd1739",
              "geometry": null
            },
            "contactInfo": {
              "email": "kdavison@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-kyle-davison",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-kyle-davison",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-kyle-davison",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-kyle-davison",
                "https://www.ndlegis.gov/biography/kyle-davison",
                "https://ndlegis.gov/biography/kyle-davison"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-41-upper-ocd-person-4412269d-782d-4ea2-a649-e62a30cd1739": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/950c2497-4b0c-437d-bde0-776cd7aa2ce2",
            "firstName": "Curtiss",
            "lastName": "Kreun",
            "fullName": "Curt Kreun",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/ckreun2023.jpg?itok=1Mq-d47l",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-ND-42-upper-ocd-person-950c2497-4b0c-437d-bde0-776cd7aa2ce2",
              "builtID": "nd-upper-42",
              "externalID": "ocd-person/950c2497-4b0c-437d-bde0-776cd7aa2ce2",
              "geometry": null
            },
            "contactInfo": {
              "email": "ckreun@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-curt-kreun",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-curt-kreun",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-curt-kreun",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-curt-kreun",
                "https://www.ndlegis.gov/biography/curt-kreun",
                "https://ndlegis.gov/biography/curt-kreun"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-42-upper-ocd-person-950c2497-4b0c-437d-bde0-776cd7aa2ce2": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/49d467a4-d6ad-49bb-b498-445117bd1669",
            "firstName": "Jeff",
            "lastName": "Barta",
            "fullName": "Jeff Barta",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jbarta2023.jpg?itok=vFRoqtiw",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "43",
              "chamber": "upper",
              "hashID": "SLDU-ND-43-upper-ocd-person-49d467a4-d6ad-49bb-b498-445117bd1669",
              "builtID": "nd-upper-43",
              "externalID": "ocd-person/49d467a4-d6ad-49bb-b498-445117bd1669",
              "geometry": null
            },
            "contactInfo": {
              "email": "jbarta@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ndlegis.gov/biography/jeff-barta",
                "https://ndlegis.gov/biography/jeff-barta"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-43-upper-ocd-person-49d467a4-d6ad-49bb-b498-445117bd1669": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/d25f241a-0cd3-4cd0-a450-c58aa9a1c0de",
            "firstName": "Merrill",
            "lastName": "Piepkorn",
            "fullName": "Merrill Piepkorn",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/mpiepkorn_0.jpg?itok=tuMNX8i8",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "44",
              "chamber": "upper",
              "hashID": "SLDU-ND-44-upper-ocd-person-d25f241a-0cd3-4cd0-a450-c58aa9a1c0de",
              "builtID": "nd-upper-44",
              "externalID": "ocd-person/d25f241a-0cd3-4cd0-a450-c58aa9a1c0de",
              "geometry": null
            },
            "contactInfo": {
              "email": "mpiepkorn@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-merrill-piepkorn",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-merrill-piepkorn",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-merrill-piepkorn",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-merrill-piepkorn",
                "https://www.ndlegis.gov/biography/merrill-piepkorn",
                "https://ndlegis.gov/biography/merrill-piepkorn"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-44-upper-ocd-person-d25f241a-0cd3-4cd0-a450-c58aa9a1c0de": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/d313121d-2b0d-41e9-94a2-fe819a6e82b4",
            "firstName": "Ronald",
            "lastName": "Sorvaag",
            "fullName": "Ron Sorvaag",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/rsorvaag2023.jpg?itok=NY_Nca8x",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "45",
              "chamber": "upper",
              "hashID": "SLDU-ND-45-upper-ocd-person-d313121d-2b0d-41e9-94a2-fe819a6e82b4",
              "builtID": "nd-upper-45",
              "externalID": "ocd-person/d313121d-2b0d-41e9-94a2-fe819a6e82b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rsorvaag@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-ronald-sorvaag",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-ronald-sorvaag",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-ronald-sorvaag",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-ronald-sorvaag",
                "https://www.ndlegis.gov/biography/ronald-sorvaag",
                "https://ndlegis.gov/biography/ronald-sorvaag"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-45-upper-ocd-person-d313121d-2b0d-41e9-94a2-fe819a6e82b4": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/22f8f645-3aeb-4918-b026-1182683144de",
            "firstName": "James P.",
            "lastName": "Roers",
            "fullName": "Jim Roers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/jroers2023.jpg?itok=mXlsmMFt",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "46",
              "chamber": "upper",
              "hashID": "SLDU-ND-46-upper-ocd-person-22f8f645-3aeb-4918-b026-1182683144de",
              "builtID": "nd-upper-46",
              "externalID": "ocd-person/22f8f645-3aeb-4918-b026-1182683144de",
              "geometry": null
            },
            "contactInfo": {
              "email": "jroers@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/65-2017/members/senate/senator-jim-p-roers",
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-jim-p-roers",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-jim-p-roers",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-jim-p-roers",
                "https://www.ndlegis.gov/biography/jim-p-roers",
                "https://ndlegis.gov/biography/jim-p-roers"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-46-upper-ocd-person-22f8f645-3aeb-4918-b026-1182683144de": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/704150b0-5d7f-43ea-afd5-b6f059463575",
            "firstName": "Michael",
            "lastName": "Dwyer",
            "fullName": "Mike Dwyer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.ndlegis.gov/sites/default/files/styles/member_list_photo/public/person/photo/mdwyer2023.jpg?itok=8BekhLxi",
            "title": "ND Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "ND",
              "stateFull": "North Dakota",
              "district": "47",
              "chamber": "upper",
              "hashID": "SLDU-ND-47-upper-ocd-person-704150b0-5d7f-43ea-afd5-b6f059463575",
              "builtID": "nd-upper-47",
              "externalID": "ocd-person/704150b0-5d7f-43ea-afd5-b6f059463575",
              "geometry": null
            },
            "contactInfo": {
              "email": "mdwyer@ndlegis.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.nd.gov/assembly/66-2019/members/senate/senator-michael-dwyer",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-michael-dwyer",
                "https://www.legis.nd.gov/assembly/67-2021/members/senate/senator-michael-dwyer",
                "https://www.ndlegis.gov/biography/michael-dwyer",
                "https://ndlegis.gov/biography/michael-dwyer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-ND-47-upper-ocd-person-704150b0-5d7f-43ea-afd5-b6f059463575": 0
        }
      }
    }
  },
  "OH": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "OH",
          "stateFull": "Ohio",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "OH",
            "stateFull": "Ohio",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-OH---",
            "builtID": "oh--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-OH---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "B000944",
          "in_office": true,
          "firstName": "Sherrod",
          "lastName": "Brown",
          "middleName": null,
          "fullName": "Sherrod Brown",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/B000944.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "OH",
            "stateFull": "Ohio",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-OH---B000944",
            "builtID": "oh--",
            "externalID": "B000944",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.brown.senate.gov/contact/",
            "address1": "503 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-2315",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenSherrodBrown",
            "youtube": "SherrodBrownOhio",
            "instagram": null,
            "facebook": "SenatorSherrodBrown",
            "urls": ["https://www.brown.senate.gov"],
            "rss_url": null
          },
          "title": "OH Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/B000944.json",
          "govtrack_id": "400050",
          "cspan_id": "5051",
          "votesmart_id": "27018",
          "icpsr_id": "29389",
          "crp_id": "N00003535",
          "google_entity_id": "/m/034s80",
          "state_rank": "senior",
          "lis_id": "S307",
          "suffix": null,
          "date_of_birth": "1952-11-09",
          "leadership_role": null,
          "fec_candidate_id": "H2OH13033",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "17",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 9,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 1.62,
          "votes_with_party_pct": 96.69,
          "votes_against_party_pct": 3.31,
          "ocd_id": "ocd-division/country:us/state:oh"
        },
        {
          "API_ID": "V000137",
          "in_office": true,
          "firstName": "J.D.",
          "lastName": "Vance",
          "middleName": null,
          "fullName": "J. D. Vance",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/V000137.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "OH",
            "stateFull": "Ohio",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-OH---V000137",
            "builtID": "oh--",
            "externalID": "V000137",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": null,
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.vance.senate.gov/"],
            "rss_url": null
          },
          "title": "OH Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/V000137.json",
          "govtrack_id": "456876",
          "cspan_id": null,
          "votesmart_id": null,
          "icpsr_id": null,
          "crp_id": null,
          "google_entity_id": null,
          "state_rank": "junior",
          "lis_id": "S421",
          "suffix": null,
          "date_of_birth": "1984-08-02",
          "leadership_role": null,
          "fec_candidate_id": "S2OH00436",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 40,
          "total_present": 0,
          "last_updated": "2024-06-20 15:30:29 -0400",
          "missed_votes_pct": 7.22,
          "votes_with_party_pct": 85.46,
          "votes_against_party_pct": 14.54,
          "ocd_id": "ocd-division/country:us/state:oh"
        }
      ],
      "hash": { "SENATE-OH---B000944": 0, "SENATE-OH---V000137": 1 }
    },
    "HOUSE": {
      "10": {
        "members": [
          {
            "API_ID": "T000463",
            "in_office": true,
            "firstName": "Michael",
            "lastName": "Turner",
            "middleName": "R.",
            "fullName": "Michael R. Turner",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/T000463.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "10",
              "chamber": "",
              "hashID": "HOUSE-OH-10--T000463",
              "builtID": "oh--10",
              "externalID": "T000463",
              "geometry": null,
              "geoid": "3910"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2183 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6465",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMikeTurner",
              "youtube": "CongressmanTurner",
              "instagram": null,
              "facebook": "RepMikeTurner",
              "urls": ["https://turner.house.gov"],
              "rss_url": "https://turner.house.gov/rss.xml"
            },
            "title": "OH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000463.json",
            "govtrack_id": "400411",
            "cspan_id": "1003607",
            "votesmart_id": "45519",
            "icpsr_id": "20342",
            "crp_id": "N00025175",
            "google_entity_id": "/m/03440l",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1960-01-11",
            "leadership_role": null,
            "fec_candidate_id": "H2OH03067",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "22",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 31,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 2.94,
            "votes_with_party_pct": 82.97,
            "votes_against_party_pct": 15.14,
            "ocd_id": "ocd-division/country:us/state:oh/cd:10",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OH-10--T000463": 0 }
      },
      "11": {
        "members": [
          {
            "API_ID": "B001313",
            "in_office": true,
            "firstName": "Shontel",
            "lastName": "Brown",
            "middleName": null,
            "fullName": "Shontel M. Brown",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/B001313.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "11",
              "chamber": "",
              "hashID": "HOUSE-OH-11--B001313",
              "builtID": "oh--11",
              "externalID": "B001313",
              "geometry": null,
              "geoid": "3911"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "449 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-7032",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepShontelBrown",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://shontelbrown.house.gov"],
              "rss_url": null
            },
            "title": "OH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001313.json",
            "govtrack_id": "456863",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00047875",
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1975-06-24",
            "leadership_role": null,
            "fec_candidate_id": "H2OH11169",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 4,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.38,
            "votes_with_party_pct": 96.74,
            "votes_against_party_pct": 1.34,
            "ocd_id": "ocd-division/country:us/state:oh/cd:11",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OH-11--B001313": 0 }
      },
      "12": {
        "members": [
          {
            "API_ID": "B001306",
            "in_office": true,
            "firstName": "Troy",
            "lastName": "Balderson",
            "middleName": null,
            "fullName": "Troy Balderson",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001306.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "12",
              "chamber": "",
              "hashID": "HOUSE-OH-12--B001306",
              "builtID": "oh--12",
              "externalID": "B001306",
              "geometry": null,
              "geoid": "3912"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2429 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5355",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBalderson",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://balderson.house.gov"],
              "rss_url": null
            },
            "title": "OH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001306.json",
            "govtrack_id": "412747",
            "cspan_id": null,
            "votesmart_id": "102781",
            "icpsr_id": null,
            "crp_id": "N00042194",
            "google_entity_id": "/m/09ry4tm",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1962-01-16",
            "leadership_role": null,
            "fec_candidate_id": "H8OH12180",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 1,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.09,
            "votes_with_party_pct": 94.58,
            "votes_against_party_pct": 3.48,
            "ocd_id": "ocd-division/country:us/state:oh/cd:12",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OH-12--B001306": 0 }
      },
      "13": {
        "members": [
          {
            "API_ID": "S001223",
            "in_office": true,
            "firstName": "Emilia",
            "lastName": "Sykes",
            "middleName": null,
            "fullName": "Emilia Strong Sykes",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001223.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "13",
              "chamber": "",
              "hashID": "HOUSE-OH-13--S001223",
              "builtID": "oh--13",
              "externalID": "S001223",
              "geometry": null,
              "geoid": "3913"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1217 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-6265",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepEmiliaSykes",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://sykes.house.gov/"],
              "rss_url": null
            },
            "title": "OH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001223.json",
            "govtrack_id": "456930",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1986-01-04",
            "leadership_role": null,
            "fec_candidate_id": "H2OH13264",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 35,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.31,
            "votes_with_party_pct": 95.55,
            "votes_against_party_pct": 2.47,
            "ocd_id": "ocd-division/country:us/state:oh/cd:13",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OH-13--S001223": 0 }
      },
      "14": {
        "members": [
          {
            "API_ID": "J000295",
            "in_office": true,
            "firstName": "Dave",
            "lastName": "Joyce",
            "middleName": null,
            "fullName": "David P. Joyce",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/J000295.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "14",
              "chamber": "",
              "hashID": "HOUSE-OH-14--J000295",
              "builtID": "oh--14",
              "externalID": "J000295",
              "geometry": null,
              "geoid": "3914"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2065 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5731",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDaveJoyce",
              "youtube": "repdavejoyce",
              "instagram": null,
              "facebook": "RepDaveJoyce",
              "urls": ["https://joyce.house.gov"],
              "rss_url": "https://joyce.house.gov/rss/press-releases.xml"
            },
            "title": "OH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/J000295.json",
            "govtrack_id": "412566",
            "cspan_id": "68561",
            "votesmart_id": "143052",
            "icpsr_id": "21353",
            "crp_id": "N00035007",
            "google_entity_id": "/m/0ktwnp_",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1957-03-17",
            "leadership_role": null,
            "fec_candidate_id": "H2OH14064",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 86,
            "total_present": 3,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 8.15,
            "votes_with_party_pct": 82.91,
            "votes_against_party_pct": 15.08,
            "ocd_id": "ocd-division/country:us/state:oh/cd:14",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OH-14--J000295": 0 }
      },
      "15": {
        "members": [
          {
            "API_ID": "C001126",
            "in_office": true,
            "firstName": "Mike",
            "lastName": "Carey",
            "middleName": null,
            "fullName": "Mike Carey",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001126.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "15",
              "chamber": "",
              "hashID": "HOUSE-OH-15--C001126",
              "builtID": "oh--15",
              "externalID": "C001126",
              "geometry": null,
              "geoid": "3915"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1433 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2015",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMikeCarey",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://carey.house.gov"],
              "rss_url": null
            },
            "title": "OH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001126.json",
            "govtrack_id": "456864",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00048568",
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1971-03-13",
            "leadership_role": null,
            "fec_candidate_id": "H2OH15228",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.57,
            "votes_with_party_pct": 91.74,
            "votes_against_party_pct": 6.32,
            "ocd_id": "ocd-division/country:us/state:oh/cd:15",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OH-15--C001126": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "B001281",
            "in_office": true,
            "firstName": "Joyce",
            "lastName": "Beatty",
            "middleName": null,
            "fullName": "Joyce Beatty",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/B001281.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-OH-03--B001281",
              "builtID": "oh--03",
              "externalID": "B001281",
              "geometry": null,
              "geoid": "3903"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2079 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4324",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBeatty",
              "youtube": null,
              "instagram": null,
              "facebook": "RepJoyceBeatty",
              "urls": ["https://beatty.house.gov"],
              "rss_url": "https://beatty.house.gov/rss.xml"
            },
            "title": "OH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001281.json",
            "govtrack_id": "412565",
            "cspan_id": "67294",
            "votesmart_id": "2427",
            "icpsr_id": "21352",
            "crp_id": "N00033904",
            "google_entity_id": "/m/03lcwb",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1950-03-12",
            "leadership_role": null,
            "fec_candidate_id": "H2OH03125",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 32,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.03,
            "votes_with_party_pct": 96.84,
            "votes_against_party_pct": 1.28,
            "ocd_id": "ocd-division/country:us/state:oh/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OH-03--B001281": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "D000626",
            "in_office": true,
            "firstName": "Warren",
            "lastName": "Davidson",
            "middleName": null,
            "fullName": "Warren Davidson",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/D000626.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-OH-08--D000626",
              "builtID": "oh--08",
              "externalID": "D000626",
              "geometry": null,
              "geoid": "3908"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2113 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6205",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "WarrenDavidson",
              "youtube": null,
              "instagram": null,
              "facebook": "CongressmanWarrenDavidson",
              "urls": ["https://davidson.house.gov"],
              "rss_url": null
            },
            "title": "OH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000626.json",
            "govtrack_id": "412675",
            "cspan_id": "102555",
            "votesmart_id": "166760",
            "icpsr_id": "21564",
            "crp_id": "N00038767",
            "google_entity_id": "/g/11cmmch3q8",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1970-03-01",
            "leadership_role": null,
            "fec_candidate_id": "H6OH08315",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 14,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.33,
            "votes_with_party_pct": 85.41,
            "votes_against_party_pct": 12.63,
            "ocd_id": "ocd-division/country:us/state:oh/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OH-08--D000626": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "J000289",
            "in_office": true,
            "firstName": "Jim",
            "lastName": "Jordan",
            "middleName": null,
            "fullName": "Jim Jordan",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/J000289.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-OH-04--J000289",
              "builtID": "oh--04",
              "externalID": "J000289",
              "geometry": null,
              "geoid": "3904"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2056 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2676",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Jim_Jordan",
              "youtube": "RepJimJordan",
              "instagram": null,
              "facebook": "repjimjordan",
              "urls": ["https://jordan.house.gov"],
              "rss_url": null
            },
            "title": "OH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/J000289.json",
            "govtrack_id": "412226",
            "cspan_id": "1022879",
            "votesmart_id": "8158",
            "icpsr_id": "20738",
            "crp_id": "N00027894",
            "google_entity_id": "/m/04l4x5",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1964-02-17",
            "leadership_role": null,
            "fec_candidate_id": "H6OH04082",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "18",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 15,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.42,
            "votes_with_party_pct": 89.4,
            "votes_against_party_pct": 8.73,
            "ocd_id": "ocd-division/country:us/state:oh/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OH-04--J000289": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "K000009",
            "in_office": true,
            "firstName": "Marcy",
            "lastName": "Kaptur",
            "middleName": null,
            "fullName": "Marcy Kaptur",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/K000009.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-OH-09--K000009",
              "builtID": "oh--09",
              "externalID": "K000009",
              "geometry": null,
              "geoid": "3909"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2186 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4146",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMarcyKaptur",
              "youtube": "USRepMarcyKaptur",
              "instagram": null,
              "facebook": "RepresentativeMarcyKaptur",
              "urls": ["https://kaptur.house.gov"],
              "rss_url": "https://kaptur.house.gov/rss.xml"
            },
            "title": "OH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000009.json",
            "govtrack_id": "400211",
            "cspan_id": "1458",
            "votesmart_id": "27016",
            "icpsr_id": "15029",
            "crp_id": "N00003522",
            "google_entity_id": "/m/039s3l",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1946-06-17",
            "leadership_role": null,
            "fec_candidate_id": "H2OH09031",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "42",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 24,
            "total_present": 4,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.27,
            "votes_with_party_pct": 92.36,
            "votes_against_party_pct": 5.78,
            "ocd_id": "ocd-division/country:us/state:oh/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OH-09--K000009": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "L000601",
            "in_office": true,
            "firstName": "Greg",
            "lastName": "Landsman",
            "middleName": null,
            "fullName": "Greg Landsman",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/L000601.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-OH-01--L000601",
              "builtID": "oh--01",
              "externalID": "L000601",
              "geometry": null,
              "geoid": "3901"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1432 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2216",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://landsman.house.gov/"],
              "rss_url": null
            },
            "title": "OH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000601.json",
            "govtrack_id": "456928",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-12-04",
            "leadership_role": null,
            "fec_candidate_id": "H2OH01194",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 50,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 4.73,
            "votes_with_party_pct": 91.47,
            "votes_against_party_pct": 6.53,
            "ocd_id": "ocd-division/country:us/state:oh/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OH-01--L000601": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "L000566",
            "in_office": true,
            "firstName": "Robert",
            "lastName": "Latta",
            "middleName": "E.",
            "fullName": "Robert E. Latta",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/L000566.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-OH-05--L000566",
              "builtID": "oh--05",
              "externalID": "L000566",
              "geometry": null,
              "geoid": "3905"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2467 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6405",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "BobLatta",
              "youtube": "CongressmanBobLatta",
              "instagram": null,
              "facebook": "boblatta",
              "urls": ["https://latta.house.gov"],
              "rss_url": "https://latta.house.gov/news/rss.aspx"
            },
            "title": "OH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000566.json",
            "govtrack_id": "412256",
            "cspan_id": "1028071",
            "votesmart_id": "9926",
            "icpsr_id": "20755",
            "crp_id": "N00012233",
            "google_entity_id": "/m/02z6_j1",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1956-04-18",
            "leadership_role": null,
            "fec_candidate_id": "H8OH05036",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "18",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 0,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0,
            "votes_with_party_pct": 94.39,
            "votes_against_party_pct": 3.77,
            "ocd_id": "ocd-division/country:us/state:oh/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OH-05--L000566": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "M001222",
            "in_office": true,
            "firstName": "Max",
            "lastName": "Miller",
            "middleName": null,
            "fullName": "Max L. Miller",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001222.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-OH-07--M001222",
              "builtID": "oh--07",
              "externalID": "M001222",
              "geometry": null,
              "geoid": "3907"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "143 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3876",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://maxmiller.house.gov/"],
              "rss_url": null
            },
            "title": "OH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001222.json",
            "govtrack_id": "456929",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1988-11-13",
            "leadership_role": null,
            "fec_candidate_id": "H2OH16051",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 54,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 5.11,
            "votes_with_party_pct": 90.34,
            "votes_against_party_pct": 7.63,
            "ocd_id": "ocd-division/country:us/state:oh/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OH-07--M001222": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "R000619",
            "in_office": true,
            "firstName": "Michael",
            "lastName": "Rulli",
            "middleName": "A.",
            "fullName": "Michael Rulli",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/R000619.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-OH-06--R000619",
              "builtID": "oh--06",
              "externalID": "R000619",
              "geometry": null,
              "geoid": "3906"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://rulli.house.gov/"],
              "rss_url": ""
            },
            "title": "OH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/R000619.json",
            "govtrack_id": null,
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1969-03-11",
            "leadership_role": null,
            "fec_candidate_id": null,
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": null,
            "total_votes": 55,
            "missed_votes": 0,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0,
            "votes_with_party_pct": 88,
            "votes_against_party_pct": 10,
            "ocd_id": "ocd-division/country:us/state:oh/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OH-06--R000619": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "W000815",
            "in_office": true,
            "firstName": "Brad",
            "lastName": "Wenstrup",
            "middleName": null,
            "fullName": "Brad R. Wenstrup",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/W000815.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-OH-02--W000815",
              "builtID": "oh--02",
              "externalID": "W000815",
              "geometry": null,
              "geoid": "3902"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2335 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3164",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBradWenstrup",
              "youtube": "repbradwenstrup",
              "instagram": null,
              "facebook": "RepBradWenstrup",
              "urls": ["https://wenstrup.house.gov"],
              "rss_url": null
            },
            "title": "OH House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000815.json",
            "govtrack_id": "412564",
            "cspan_id": "1034044",
            "votesmart_id": "135326",
            "icpsr_id": "21351",
            "crp_id": "N00033310",
            "google_entity_id": "/m/0j63b7n",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1958-06-17",
            "leadership_role": null,
            "fec_candidate_id": "H2OH02085",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 4,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.38,
            "votes_with_party_pct": 94.66,
            "votes_against_party_pct": 3.5,
            "ocd_id": "ocd-division/country:us/state:oh/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OH-02--W000815": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/74c1210d-fb56-4522-8d3a-0a2bb08c972b",
            "firstName": "Dontavius L.",
            "lastName": "Jarrells",
            "fullName": "Dontavius Jarrells",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/dontavius-l-jarrells/headshots/thumbnails/medium/dontavius-l-jarrells-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-OH-1-lower-ocd-person-74c1210d-fb56-4522-8d3a-0a2bb08c972b",
              "builtID": "oh-lower-1",
              "externalID": "ocd-person/74c1210d-fb56-4522-8d3a-0a2bb08c972b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep01@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/dontavius-l-jarrells",
                "https://ohiohouse.gov/members/dontavius-l-jarrells"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-1-lower-ocd-person-74c1210d-fb56-4522-8d3a-0a2bb08c972b": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/b2c76bf8-569f-4e80-bd3d-fa8273a8356c",
            "firstName": "Latyna M.",
            "lastName": "Humphrey",
            "fullName": "Latyna Humphrey",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/latyna-m-humphrey/headshots/thumbnails/medium/latyna-m-humphrey-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-OH-2-lower-ocd-person-b2c76bf8-569f-4e80-bd3d-fa8273a8356c",
              "builtID": "oh-lower-2",
              "externalID": "ocd-person/b2c76bf8-569f-4e80-bd3d-fa8273a8356c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep02@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/latyna-m-humphrey",
                "https://ohiohouse.gov/members/latyna-m-humphrey"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-2-lower-ocd-person-b2c76bf8-569f-4e80-bd3d-fa8273a8356c": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/ed31c4e5-e835-4c4c-9352-217f5dcf1037",
            "firstName": "Ismail",
            "lastName": "Mohamed",
            "fullName": "Ismail Mohamed",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/ismail-mohamed/headshots/thumbnails/medium/ismail-mohamed-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-OH-3-lower-ocd-person-ed31c4e5-e835-4c4c-9352-217f5dcf1037",
              "builtID": "oh-lower-3",
              "externalID": "ocd-person/ed31c4e5-e835-4c4c-9352-217f5dcf1037",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep03@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/ismail-mohamed"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-3-lower-ocd-person-ed31c4e5-e835-4c4c-9352-217f5dcf1037": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/dc1a25bf-ebf6-4d5c-b5c3-32f9fdb8b2ed",
            "firstName": "Beryl Brown",
            "lastName": "Piccolantonio",
            "fullName": "Beryl Piccolantonio",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-OH-4-lower-ocd-person-dc1a25bf-ebf6-4d5c-b5c3-32f9fdb8b2ed",
              "builtID": "oh-lower-4",
              "externalID": "ocd-person/dc1a25bf-ebf6-4d5c-b5c3-32f9fdb8b2ed",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep04@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ohiohouse.gov/members/beryl-piccolantonio"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-4-lower-ocd-person-dc1a25bf-ebf6-4d5c-b5c3-32f9fdb8b2ed": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/2e5a68dc-fdb3-4517-8c09-ac0fe1d84c4f",
            "firstName": "Richard D.",
            "lastName": "Brown",
            "fullName": "Richard Brown",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/richard-d-brown/headshots/thumbnails/medium/richard-d-brown-august-2017-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-OH-5-lower-ocd-person-2e5a68dc-fdb3-4517-8c09-ac0fe1d84c4f",
              "builtID": "oh-lower-5",
              "externalID": "ocd-person/2e5a68dc-fdb3-4517-8c09-ac0fe1d84c4f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep05@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/richard-d-brown",
                "https://ohiohouse.gov/richard-d-brown",
                "https://www.ohiohouse.gov/members/richard-d-brown"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-5-lower-ocd-person-2e5a68dc-fdb3-4517-8c09-ac0fe1d84c4f": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/d9437df7-3071-434b-b64f-46a5e15e7578",
            "firstName": "Adam Clay",
            "lastName": "Miller",
            "fullName": "Adam Miller",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/adam-c-miller/headshots/thumbnails/medium/adam-c-miller-january-2017-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-OH-6-lower-ocd-person-d9437df7-3071-434b-b64f-46a5e15e7578",
              "builtID": "oh-lower-6",
              "externalID": "ocd-person/d9437df7-3071-434b-b64f-46a5e15e7578",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep06@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/adam-c-miller",
                "https://ohiohouse.gov/adam-c-miller",
                "https://www.ohiohouse.gov/members/adam-c-miller"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-6-lower-ocd-person-d9437df7-3071-434b-b64f-46a5e15e7578": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/c58b6767-730d-4486-a08e-462ea3af3bfe",
            "firstName": "C. Allison",
            "lastName": "Russo",
            "fullName": "Allison Russo",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/c-allison-russo/headshots/thumbnails/medium/c-allison-russo-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-OH-7-lower-ocd-person-c58b6767-730d-4486-a08e-462ea3af3bfe",
              "builtID": "oh-lower-7",
              "externalID": "ocd-person/c58b6767-730d-4486-a08e-462ea3af3bfe",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep07@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/c-allison-russo",
                "https://ohiohouse.gov/c-allison-russo",
                "https://www.ohiohouse.gov/members/c-allison-russo"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-7-lower-ocd-person-c58b6767-730d-4486-a08e-462ea3af3bfe": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/2d01ff0c-25a8-49a2-b571-ab3a7866fcf2",
            "firstName": "Beth Wagner",
            "lastName": "Liston",
            "fullName": "Beth Liston",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/beth-liston/headshots/thumbnails/medium/beth-liston-november-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-OH-8-lower-ocd-person-2d01ff0c-25a8-49a2-b571-ab3a7866fcf2",
              "builtID": "oh-lower-8",
              "externalID": "ocd-person/2d01ff0c-25a8-49a2-b571-ab3a7866fcf2",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep08@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/beth-liston",
                "https://ohiohouse.gov/beth-liston",
                "https://www.ohiohouse.gov/members/beth-liston"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-8-lower-ocd-person-2d01ff0c-25a8-49a2-b571-ab3a7866fcf2": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/538fefb8-d8ae-48f5-bb07-4161e2ccac55",
            "firstName": "Munira Yasin",
            "lastName": "Abdullahi",
            "fullName": "Munira Abdullahi",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/munira-abdullahi/headshots/thumbnails/medium/munira-yasin-abdullahi-february-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-OH-9-lower-ocd-person-538fefb8-d8ae-48f5-bb07-4161e2ccac55",
              "builtID": "oh-lower-9",
              "externalID": "ocd-person/538fefb8-d8ae-48f5-bb07-4161e2ccac55",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep09@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/munira-abdullahi"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-9-lower-ocd-person-538fefb8-d8ae-48f5-bb07-4161e2ccac55": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/de058978-37b3-4f2d-89b3-3aa8798b301e",
            "firstName": "David A.",
            "lastName": "Dobos",
            "fullName": "Dave Dobos",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/dave-dobos/headshots/thumbnails/medium/dave-dobos-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-OH-10-lower-ocd-person-de058978-37b3-4f2d-89b3-3aa8798b301e",
              "builtID": "oh-lower-10",
              "externalID": "ocd-person/de058978-37b3-4f2d-89b3-3aa8798b301e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep10@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/dave-dobos"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-10-lower-ocd-person-de058978-37b3-4f2d-89b3-3aa8798b301e": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/3bfe0748-5423-4fcf-9962-905d2b747f23",
            "firstName": "Anita",
            "lastName": "Somani",
            "fullName": "Anita Somani",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/anita-somani/headshots/thumbnails/medium/anita-somani-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-OH-11-lower-ocd-person-3bfe0748-5423-4fcf-9962-905d2b747f23",
              "builtID": "oh-lower-11",
              "externalID": "ocd-person/3bfe0748-5423-4fcf-9962-905d2b747f23",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep11@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/anita-somani"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-11-lower-ocd-person-3bfe0748-5423-4fcf-9962-905d2b747f23": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/f4e6c51a-89bd-4841-848b-b1097fd3fc9d",
            "firstName": "Brian",
            "lastName": "Stewart",
            "fullName": "Brian Stewart",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/brian-stewart/headshots/thumbnails/medium/brian-stewart-november-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-OH-12-lower-ocd-person-f4e6c51a-89bd-4841-848b-b1097fd3fc9d",
              "builtID": "oh-lower-12",
              "externalID": "ocd-person/f4e6c51a-89bd-4841-848b-b1097fd3fc9d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep12@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/brian-stewart",
                "https://ohiohouse.gov/members/brian-stewart"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-12-lower-ocd-person-f4e6c51a-89bd-4841-848b-b1097fd3fc9d": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/b37ab690-7668-422e-9926-0e817cd07b9b",
            "firstName": "Michael J.",
            "lastName": "Skindell",
            "fullName": "Mike Skindell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/michael-j-skindell/headshots/thumbnails/medium/michael-j-skindell-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-OH-13-lower-ocd-person-b37ab690-7668-422e-9926-0e817cd07b9b",
              "builtID": "oh-lower-13",
              "externalID": "ocd-person/b37ab690-7668-422e-9926-0e817cd07b9b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep13@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/michael-j-skindell",
                "https://ohiohouse.gov/michael-j-skindell",
                "https://www.ohiohouse.gov/members/michael-j-skindell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-13-lower-ocd-person-b37ab690-7668-422e-9926-0e817cd07b9b": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/993f089c-60bf-418d-9704-fa92e4f01c71",
            "firstName": "Sean Patrick",
            "lastName": "Brennan",
            "fullName": "Sean Brennan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/sean-p-brennan/headshots/thumbnails/medium/sean-p-brennan-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-OH-14-lower-ocd-person-993f089c-60bf-418d-9704-fa92e4f01c71",
              "builtID": "oh-lower-14",
              "externalID": "ocd-person/993f089c-60bf-418d-9704-fa92e4f01c71",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep14@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/sean-p-brennan"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-14-lower-ocd-person-993f089c-60bf-418d-9704-fa92e4f01c71": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/f831e20a-400e-4498-a3c9-719bb6e9ef66",
            "firstName": "Richard",
            "lastName": "Dell'Aquila",
            "fullName": "Rick Dell'Aquila",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/richard-dellaquila/headshots/thumbnails/medium/richard-dellaquila-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-OH-15-lower-ocd-person-f831e20a-400e-4498-a3c9-719bb6e9ef66",
              "builtID": "oh-lower-15",
              "externalID": "ocd-person/f831e20a-400e-4498-a3c9-719bb6e9ef66",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep15@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/richard-dellaquila"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-15-lower-ocd-person-f831e20a-400e-4498-a3c9-719bb6e9ef66": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/2481dfc7-d260-49c6-9461-9e6048ed27a2",
            "firstName": "Bride Rose",
            "lastName": "Sweeney",
            "fullName": "Bride Sweeney",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/bride-rose-sweeney/headshots/thumbnails/medium/bride-rose-sweeney-january-2019-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-OH-16-lower-ocd-person-2481dfc7-d260-49c6-9461-9e6048ed27a2",
              "builtID": "oh-lower-16",
              "externalID": "ocd-person/2481dfc7-d260-49c6-9461-9e6048ed27a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep16@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/bride-rose-sweeney",
                "https://ohiohouse.gov/bride-rose-sweeney",
                "https://www.ohiohouse.gov/members/bride-rose-sweeney"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-16-lower-ocd-person-2481dfc7-d260-49c6-9461-9e6048ed27a2": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/a57f6c78-ac58-4f7d-870e-0d8052984cd9",
            "firstName": "Thomas F.",
            "lastName": "Patton",
            "fullName": "Tom Patton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/thomas-f-patton/headshots/thumbnails/medium/thomas-f-patton-november-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-OH-17-lower-ocd-person-a57f6c78-ac58-4f7d-870e-0d8052984cd9",
              "builtID": "oh-lower-17",
              "externalID": "ocd-person/a57f6c78-ac58-4f7d-870e-0d8052984cd9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep17@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/thomas-f-patton",
                "https://ohiohouse.gov/thomas-f-patton",
                "https://www.ohiohouse.gov/members/thomas-f-patton"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-17-lower-ocd-person-a57f6c78-ac58-4f7d-870e-0d8052984cd9": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/a75181f0-f0e8-4af3-aefd-6cc08543af1a",
            "firstName": "Darnell T.",
            "lastName": "Brewer",
            "fullName": "Darnell Brewer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/darnell-t-brewer/headshots/thumbnails/medium/darnell-t-brewer-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-OH-18-lower-ocd-person-a75181f0-f0e8-4af3-aefd-6cc08543af1a",
              "builtID": "oh-lower-18",
              "externalID": "ocd-person/a75181f0-f0e8-4af3-aefd-6cc08543af1a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep18@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ohiohouse.gov/members/darnell-t-brewer"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-18-lower-ocd-person-a75181f0-f0e8-4af3-aefd-6cc08543af1a": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/78d5adbb-ec6a-4212-9cc7-335f5d9ded8c",
            "firstName": "Phillip M.",
            "lastName": "Robinson",
            "fullName": "Phil Robinson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/phillip-m-robinson-jr/headshots/thumbnails/medium/phillip-m-robinson-jr-january-2019-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-OH-19-lower-ocd-person-78d5adbb-ec6a-4212-9cc7-335f5d9ded8c",
              "builtID": "oh-lower-19",
              "externalID": "ocd-person/78d5adbb-ec6a-4212-9cc7-335f5d9ded8c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep19@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/phillip-m-robinson-jr",
                "https://ohiohouse.gov/phillip-m-robinson-jr",
                "https://www.ohiohouse.gov/members/phillip-m-robinson-jr"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-19-lower-ocd-person-78d5adbb-ec6a-4212-9cc7-335f5d9ded8c": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/a4c53220-d4a7-4591-b2d8-109516dc9401",
            "firstName": "Terrence Vincent",
            "lastName": "Upchurch",
            "fullName": "Terrence Upchurch",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/terrence-upchurch/headshots/thumbnails/medium/terrence-upchurch-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-OH-20-lower-ocd-person-a4c53220-d4a7-4591-b2d8-109516dc9401",
              "builtID": "oh-lower-20",
              "externalID": "ocd-person/a4c53220-d4a7-4591-b2d8-109516dc9401",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep20@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/terrence-upchurch",
                "https://ohiohouse.gov/terrence-upchurch",
                "https://www.ohiohouse.gov/members/terrence-upchurch"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-20-lower-ocd-person-a4c53220-d4a7-4591-b2d8-109516dc9401": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/9dea6ab9-d59d-48c6-822d-23c2c4bb84a5",
            "firstName": "Elliot",
            "lastName": "Forhan",
            "fullName": "Elliot Forhan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/elliot-forhan/headshots/thumbnails/medium/elliot-forhan-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-OH-21-lower-ocd-person-9dea6ab9-d59d-48c6-822d-23c2c4bb84a5",
              "builtID": "oh-lower-21",
              "externalID": "ocd-person/9dea6ab9-d59d-48c6-822d-23c2c4bb84a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep21@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/elliot-forhan"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-21-lower-ocd-person-9dea6ab9-d59d-48c6-822d-23c2c4bb84a5": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/ede2c8b0-9c35-4ebf-9a4a-4349fe4cc224",
            "firstName": "Juanita O.",
            "lastName": "Brent",
            "fullName": "Juanita Brent",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/juanita-o-brent/headshots/thumbnails/medium/juanita-o-brent-january-2019-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-OH-22-lower-ocd-person-ede2c8b0-9c35-4ebf-9a4a-4349fe4cc224",
              "builtID": "oh-lower-22",
              "externalID": "ocd-person/ede2c8b0-9c35-4ebf-9a4a-4349fe4cc224",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep22@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/juanita-o-brent",
                "https://ohiohouse.gov/juanita-o-brent",
                "https://www.ohiohouse.gov/members/juanita-o-brent"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-22-lower-ocd-person-ede2c8b0-9c35-4ebf-9a4a-4349fe4cc224": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/04d4dc4f-a38a-4d50-8962-ea961550b2ff",
            "firstName": "Daniel Patrick",
            "lastName": "Troy",
            "fullName": "Dan Troy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/daniel-p-troy/headshots/thumbnails/medium/daniel-p-troy-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-OH-23-lower-ocd-person-04d4dc4f-a38a-4d50-8962-ea961550b2ff",
              "builtID": "oh-lower-23",
              "externalID": "ocd-person/04d4dc4f-a38a-4d50-8962-ea961550b2ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep23@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/daniel-p-troy",
                "https://ohiohouse.gov/members/daniel-p-troy"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-23-lower-ocd-person-04d4dc4f-a38a-4d50-8962-ea961550b2ff": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/1cc15373-6101-48fb-9597-d731c115eb70",
            "firstName": "Dani",
            "lastName": "Isaacsohn",
            "fullName": "Dani Isaacsohn",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/dani-isaacsohn/headshots/thumbnails/medium/dani-isaacsohn-april-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-OH-24-lower-ocd-person-1cc15373-6101-48fb-9597-d731c115eb70",
              "builtID": "oh-lower-24",
              "externalID": "ocd-person/1cc15373-6101-48fb-9597-d731c115eb70",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep24@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/dani-isaacsohn"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-24-lower-ocd-person-1cc15373-6101-48fb-9597-d731c115eb70": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/5ba7ae7d-2f10-42b7-8784-8e734507ce33",
            "firstName": "Cecil",
            "lastName": "Thomas",
            "fullName": "Cecil Thomas",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/cecil-thomas/headshots/thumbnails/medium/cecil-thomas-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-OH-25-lower-ocd-person-5ba7ae7d-2f10-42b7-8784-8e734507ce33",
              "builtID": "oh-lower-25",
              "externalID": "ocd-person/5ba7ae7d-2f10-42b7-8784-8e734507ce33",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd09@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/thomas",
                "https://ohiohouse.gov/members/cecil-thomas"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-25-lower-ocd-person-5ba7ae7d-2f10-42b7-8784-8e734507ce33": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/6425a65f-bd3e-4ac8-a0a5-c047e38c145c",
            "firstName": "Sedrick",
            "lastName": "Denson",
            "fullName": "Sedrick Denson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/sedrick-denson/headshots/thumbnails/medium/sedrick-denson-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-OH-26-lower-ocd-person-6425a65f-bd3e-4ac8-a0a5-c047e38c145c",
              "builtID": "oh-lower-26",
              "externalID": "ocd-person/6425a65f-bd3e-4ac8-a0a5-c047e38c145c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep26@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/sedrick-denson",
                "https://ohiohouse.gov/sedrick-denson",
                "https://www.ohiohouse.gov/members/sedrick-denson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-26-lower-ocd-person-6425a65f-bd3e-4ac8-a0a5-c047e38c145c": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/36f320a8-be34-43c2-ac73-ee87fbce6b0c",
            "firstName": "Rachel B.",
            "lastName": "Baker",
            "fullName": "Rachel Baker",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/rachel-b-baker/headshots/thumbnails/medium/rachel-b-baker-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-OH-27-lower-ocd-person-36f320a8-be34-43c2-ac73-ee87fbce6b0c",
              "builtID": "oh-lower-27",
              "externalID": "ocd-person/36f320a8-be34-43c2-ac73-ee87fbce6b0c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep27@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/rachel-b-baker"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-27-lower-ocd-person-36f320a8-be34-43c2-ac73-ee87fbce6b0c": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/a1f0f5d7-8506-4b00-816f-598a2ddd7da4",
            "firstName": "Jodi",
            "lastName": "Whitted",
            "fullName": "Jodi Whitted",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.cincinnati.com/gcdn/authoring/authoring-images/2024/05/01/PCIN/73530283007-whitted.png?crop=399,225,x0,y40",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-OH-28-lower-ocd-person-a1f0f5d7-8506-4b00-816f-598a2ddd7da4",
              "builtID": "oh-lower-28",
              "externalID": "ocd-person/a1f0f5d7-8506-4b00-816f-598a2ddd7da4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep28@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ohiohouse.gov/members/jodi-whitted"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-28-lower-ocd-person-a1f0f5d7-8506-4b00-816f-598a2ddd7da4": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/9ae0194c-0f5b-44c6-b84f-a0ec4d3f3a61",
            "firstName": "Cindy",
            "lastName": "Abrams",
            "fullName": "Cindy Abrams",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/cindy-abrams/headshots/thumbnails/medium/cindy-abrams-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-OH-29-lower-ocd-person-9ae0194c-0f5b-44c6-b84f-a0ec4d3f3a61",
              "builtID": "oh-lower-29",
              "externalID": "ocd-person/9ae0194c-0f5b-44c6-b84f-a0ec4d3f3a61",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep29@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/cindy-abrams",
                "https://ohiohouse.gov/cindy-abrams",
                "https://www.ohiohouse.gov/members/cindy-abrams"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-29-lower-ocd-person-9ae0194c-0f5b-44c6-b84f-a0ec4d3f3a61": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/899dde54-f8bf-4579-bbf8-dfb63f298c0c",
            "firstName": "William",
            "lastName": "Seitz",
            "fullName": "Bill Seitz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/bill-seitz/headshots/thumbnails/medium/bill-seitz-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-OH-30-lower-ocd-person-899dde54-f8bf-4579-bbf8-dfb63f298c0c",
              "builtID": "oh-lower-30",
              "externalID": "ocd-person/899dde54-f8bf-4579-bbf8-dfb63f298c0c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep30@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/bill-seitz",
                "https://ohiohouse.gov/bill-seitz",
                "https://www.ohiohouse.gov/members/bill-seitz"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-30-lower-ocd-person-899dde54-f8bf-4579-bbf8-dfb63f298c0c": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/4cece9f3-e252-438b-8255-a2150a4ed599",
            "firstName": "William",
            "lastName": "Roemer",
            "fullName": "Bill Roemer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/bill-roemer/headshots/thumbnails/medium/bill-roemer-january-2019-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-OH-31-lower-ocd-person-4cece9f3-e252-438b-8255-a2150a4ed599",
              "builtID": "oh-lower-31",
              "externalID": "ocd-person/4cece9f3-e252-438b-8255-a2150a4ed599",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep31@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/bill-roemer",
                "https://ohiohouse.gov/bill-roemer",
                "https://www.ohiohouse.gov/members/bill-roemer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-31-lower-ocd-person-4cece9f3-e252-438b-8255-a2150a4ed599": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/9c459852-5ff7-4607-a09a-6ee2480dece1",
            "firstName": "John K.",
            "lastName": "Daniels",
            "fullName": "Jack Daniels",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/JackKDaniels24.png",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-OH-32-lower-ocd-person-9c459852-5ff7-4607-a09a-6ee2480dece1",
              "builtID": "oh-lower-32",
              "externalID": "ocd-person/9c459852-5ff7-4607-a09a-6ee2480dece1",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep32@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/jack-k-daniels"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-32-lower-ocd-person-9c459852-5ff7-4607-a09a-6ee2480dece1": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/8b367fce-9be2-4848-837a-a475c3d531e4",
            "firstName": "Veronica R.",
            "lastName": "Sims",
            "fullName": "Veronica Sims",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Veronica_Sims.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-OH-33-lower-ocd-person-8b367fce-9be2-4848-837a-a475c3d531e4",
              "builtID": "oh-lower-33",
              "externalID": "ocd-person/8b367fce-9be2-4848-837a-a475c3d531e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep33@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ohiohouse.gov/members/veronica-sims"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-33-lower-ocd-person-8b367fce-9be2-4848-837a-a475c3d531e4": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/d7949531-f267-4e99-9611-87b3ec3e04ac",
            "firstName": "Casey M.",
            "lastName": "Weinstein",
            "fullName": "Casey Weinstein",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/casey-weinstein/headshots/thumbnails/medium/casey-weinstein-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-OH-34-lower-ocd-person-d7949531-f267-4e99-9611-87b3ec3e04ac",
              "builtID": "oh-lower-34",
              "externalID": "ocd-person/d7949531-f267-4e99-9611-87b3ec3e04ac",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep34@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/casey-weinstein",
                "https://ohiohouse.gov/casey-weinstein",
                "https://www.ohiohouse.gov/members/casey-weinstein"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-34-lower-ocd-person-d7949531-f267-4e99-9611-87b3ec3e04ac": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/854b6ce3-a4e1-46db-959a-aa286e4e8942",
            "firstName": "Steve",
            "lastName": "Demetriou",
            "fullName": "Steve Demetriou",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/steve-demetriou/headshots/thumbnails/medium/steve-demetriou-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-OH-35-lower-ocd-person-854b6ce3-a4e1-46db-959a-aa286e4e8942",
              "builtID": "oh-lower-35",
              "externalID": "ocd-person/854b6ce3-a4e1-46db-959a-aa286e4e8942",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep35@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/steve-demetriou"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-35-lower-ocd-person-854b6ce3-a4e1-46db-959a-aa286e4e8942": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/07c054e9-9a51-41b2-994a-8f63b0148d07",
            "firstName": "Andrea Jacobs",
            "lastName": "White",
            "fullName": "Andrea White",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/andrea-white/headshots/thumbnails/medium/andrea-white-march-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-OH-36-lower-ocd-person-07c054e9-9a51-41b2-994a-8f63b0148d07",
              "builtID": "oh-lower-36",
              "externalID": "ocd-person/07c054e9-9a51-41b2-994a-8f63b0148d07",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep36@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/andrea-white",
                "https://ohiohouse.gov/members/andrea-white"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-36-lower-ocd-person-07c054e9-9a51-41b2-994a-8f63b0148d07": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/d2ecec1d-db13-4a47-836b-9e75f3a31c78",
            "firstName": "James",
            "lastName": "Young",
            "fullName": "Tom Young",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/tom-young/headshots/thumbnails/medium/tom-young-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-OH-37-lower-ocd-person-d2ecec1d-db13-4a47-836b-9e75f3a31c78",
              "builtID": "oh-lower-37",
              "externalID": "ocd-person/d2ecec1d-db13-4a47-836b-9e75f3a31c78",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep37@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/tom-young",
                "https://ohiohouse.gov/members/tom-young"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-37-lower-ocd-person-d2ecec1d-db13-4a47-836b-9e75f3a31c78": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/23c443b0-e2f7-4c7a-af1e-d67d7aa366ce",
            "firstName": "Willis E.",
            "lastName": "Blackshear",
            "fullName": "Willis Blackshear",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/willis-e-blackshear-jr/headshots/thumbnails/medium/willis-e-blackshear-jr-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-OH-38-lower-ocd-person-23c443b0-e2f7-4c7a-af1e-d67d7aa366ce",
              "builtID": "oh-lower-38",
              "externalID": "ocd-person/23c443b0-e2f7-4c7a-af1e-d67d7aa366ce",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep38@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/willis-e-blackshear-jr",
                "https://ohiohouse.gov/members/willis-e-blackshear-jr"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-38-lower-ocd-person-23c443b0-e2f7-4c7a-af1e-d67d7aa366ce": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/1e218942-95f6-43fe-b935-b19ab70fd5fc",
            "firstName": "Phil",
            "lastName": "Plummer",
            "fullName": "Phil Plummer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/phil-plummer/headshots/thumbnails/medium/phil-plummer-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-OH-39-lower-ocd-person-1e218942-95f6-43fe-b935-b19ab70fd5fc",
              "builtID": "oh-lower-39",
              "externalID": "ocd-person/1e218942-95f6-43fe-b935-b19ab70fd5fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep39@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/phil-plummer",
                "https://ohiohouse.gov/phil-plummer",
                "https://www.ohiohouse.gov/members/phil-plummer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-39-lower-ocd-person-1e218942-95f6-43fe-b935-b19ab70fd5fc": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/c34a852a-71fc-4b21-a594-bc4a75b799c8",
            "firstName": "Rodney Lee",
            "lastName": "Creech",
            "fullName": "Rodney Creech",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/rodney-creech/headshots/thumbnails/medium/rodney-creech-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-OH-40-lower-ocd-person-c34a852a-71fc-4b21-a594-bc4a75b799c8",
              "builtID": "oh-lower-40",
              "externalID": "ocd-person/c34a852a-71fc-4b21-a594-bc4a75b799c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep40@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/rodney-creech",
                "https://ohiohouse.gov/members/rodney-creech"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-40-lower-ocd-person-c34a852a-71fc-4b21-a594-bc4a75b799c8": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/1e52d1da-3e5c-442e-a460-d63b62709edf",
            "firstName": "Joshua E.",
            "lastName": "Williams",
            "fullName": "Josh Williams",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/josh-williams/headshots/thumbnails/medium/josh-williams-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-OH-41-lower-ocd-person-1e52d1da-3e5c-442e-a460-d63b62709edf",
              "builtID": "oh-lower-41",
              "externalID": "ocd-person/1e52d1da-3e5c-442e-a460-d63b62709edf",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep41@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/josh-williams"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-41-lower-ocd-person-1e52d1da-3e5c-442e-a460-d63b62709edf": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/3cc09045-f832-464a-8873-3c5775456670",
            "firstName": "Derek Scott",
            "lastName": "Merrin",
            "fullName": "Derek Merrin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/derek-merrin/headshots/thumbnails/medium/derek-merrin-july-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-OH-42-lower-ocd-person-3cc09045-f832-464a-8873-3c5775456670",
              "builtID": "oh-lower-42",
              "externalID": "ocd-person/3cc09045-f832-464a-8873-3c5775456670",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep42@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/derek-merrin",
                "https://ohiohouse.gov/derek-merrin",
                "https://www.ohiohouse.gov/members/derek-merrin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-42-lower-ocd-person-3cc09045-f832-464a-8873-3c5775456670": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/100ae6b0-0c5d-4b97-9ac5-8ccb42b9168b",
            "firstName": "Michele",
            "lastName": "Grim",
            "fullName": "Michele Grim",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/michele-grim/headshots/thumbnails/medium/michele-grim-november-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-OH-43-lower-ocd-person-100ae6b0-0c5d-4b97-9ac5-8ccb42b9168b",
              "builtID": "oh-lower-43",
              "externalID": "ocd-person/100ae6b0-0c5d-4b97-9ac5-8ccb42b9168b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep43@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/michele-grim"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-43-lower-ocd-person-100ae6b0-0c5d-4b97-9ac5-8ccb42b9168b": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/bfe003c4-6a28-46d6-b831-07bfe8662926",
            "firstName": "Elgin",
            "lastName": "Rogers",
            "fullName": "Elgin Rogers",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/elgin-rogers-jr/headshots/thumbnails/medium/elgin-rogers-jr-november-2022-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-OH-44-lower-ocd-person-bfe003c4-6a28-46d6-b831-07bfe8662926",
              "builtID": "oh-lower-44",
              "externalID": "ocd-person/bfe003c4-6a28-46d6-b831-07bfe8662926",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep44@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ohiohouse.gov/members/elgin-rogers-jr"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-44-lower-ocd-person-bfe003c4-6a28-46d6-b831-07bfe8662926": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/4817cf28-df1f-4ccc-bc09-25809222e530",
            "firstName": "Jennifer Sherwood",
            "lastName": "Gross",
            "fullName": "Jennifer Gross",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/jennifer-gross/headshots/thumbnails/medium/jennifer-gross-july-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-OH-45-lower-ocd-person-4817cf28-df1f-4ccc-bc09-25809222e530",
              "builtID": "oh-lower-45",
              "externalID": "ocd-person/4817cf28-df1f-4ccc-bc09-25809222e530",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep45@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/jennifer-gross",
                "https://ohiohouse.gov/members/jennifer-gross"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-45-lower-ocd-person-4817cf28-df1f-4ccc-bc09-25809222e530": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/7948afad-ec3c-4e4c-a073-305fb8cd1b21",
            "firstName": "Thomas",
            "lastName": "Hall",
            "fullName": "Thomas Hall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/thomas-hall/headshots/thumbnails/medium/thomas-hall-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-OH-46-lower-ocd-person-7948afad-ec3c-4e4c-a073-305fb8cd1b21",
              "builtID": "oh-lower-46",
              "externalID": "ocd-person/7948afad-ec3c-4e4c-a073-305fb8cd1b21",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep46@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/thomas-hall",
                "https://ohiohouse.gov/members/thomas-hall"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-46-lower-ocd-person-7948afad-ec3c-4e4c-a073-305fb8cd1b21": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/28b00914-a20f-4d23-8dcb-d21d0f38245e",
            "firstName": "Sara P.",
            "lastName": "Carruthers",
            "fullName": "Sara Carruthers",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/sara-p-carruthers/headshots/thumbnails/medium/sara-p-carruthers-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-OH-47-lower-ocd-person-28b00914-a20f-4d23-8dcb-d21d0f38245e",
              "builtID": "oh-lower-47",
              "externalID": "ocd-person/28b00914-a20f-4d23-8dcb-d21d0f38245e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep47@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/sara-p-carruthers",
                "https://ohiohouse.gov/sara-p-carruthers",
                "https://www.ohiohouse.gov/members/sara-p-carruthers"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-47-lower-ocd-person-28b00914-a20f-4d23-8dcb-d21d0f38245e": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/52424e91-a002-4908-a205-4ea27cfe68da",
            "firstName": "Scott",
            "lastName": "Oelslager",
            "fullName": "Scott Oelslager",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/scott-oelslager/headshots/thumbnails/medium/scott-oelslager-february-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-OH-48-lower-ocd-person-52424e91-a002-4908-a205-4ea27cfe68da",
              "builtID": "oh-lower-48",
              "externalID": "ocd-person/52424e91-a002-4908-a205-4ea27cfe68da",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep48@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/scott-oelslager",
                "https://ohiohouse.gov/scott-oelslager",
                "https://www.ohiohouse.gov/members/scott-oelslager"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-48-lower-ocd-person-52424e91-a002-4908-a205-4ea27cfe68da": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/ba95511f-3692-426c-bf85-c41ff737eeec",
            "firstName": "Jim",
            "lastName": "Thomas",
            "fullName": "Jim Thomas",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/jim-thomas/headshots/thumbnails/medium/jim-thomas-november-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-OH-49-lower-ocd-person-ba95511f-3692-426c-bf85-c41ff737eeec",
              "builtID": "oh-lower-49",
              "externalID": "ocd-person/ba95511f-3692-426c-bf85-c41ff737eeec",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep49@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/jim-thomas"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-49-lower-ocd-person-ba95511f-3692-426c-bf85-c41ff737eeec": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/279245fa-5112-413b-b52f-1115889a8185",
            "firstName": "Reggie",
            "lastName": "Stoltzfus",
            "fullName": "Reggie Stoltzfus",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/reggie-stoltzfus/headshots/thumbnails/medium/reggie-stoltzfus-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-OH-50-lower-ocd-person-279245fa-5112-413b-b52f-1115889a8185",
              "builtID": "oh-lower-50",
              "externalID": "ocd-person/279245fa-5112-413b-b52f-1115889a8185",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep50@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/reggie-stoltzfus",
                "https://ohiohouse.gov/reggie-stoltzfus",
                "https://www.ohiohouse.gov/members/reggie-stoltzfus"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-50-lower-ocd-person-279245fa-5112-413b-b52f-1115889a8185": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/6c432400-72bd-42d9-ade1-8aefd99fe993",
            "firstName": "Brett Hudson",
            "lastName": "Hillyer",
            "fullName": "Brett Hillyer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/brett-hudson-hillyer/headshots/thumbnails/medium/brett-hudson-hillyer-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-OH-51-lower-ocd-person-6c432400-72bd-42d9-ade1-8aefd99fe993",
              "builtID": "oh-lower-51",
              "externalID": "ocd-person/6c432400-72bd-42d9-ade1-8aefd99fe993",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep51@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/brett-hudson-hillyer",
                "https://ohiohouse.gov/brett-hudson-hillyer",
                "https://www.ohiohouse.gov/members/brett-hudson-hillyer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-51-lower-ocd-person-6c432400-72bd-42d9-ade1-8aefd99fe993": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/b29aadca-f2da-4f09-8a1d-7aa328768507",
            "firstName": "Gayle Gerhart",
            "lastName": "Manning",
            "fullName": "Gayle Manning",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/gayle-manning/headshots/thumbnails/medium/gayle-manning-january-2019-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-OH-52-lower-ocd-person-b29aadca-f2da-4f09-8a1d-7aa328768507",
              "builtID": "oh-lower-52",
              "externalID": "ocd-person/b29aadca-f2da-4f09-8a1d-7aa328768507",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep52@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/gayle-manning",
                "https://ohiohouse.gov/gayle-manning",
                "https://www.ohiohouse.gov/members/gayle-manning"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-52-lower-ocd-person-b29aadca-f2da-4f09-8a1d-7aa328768507": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/9b5a9ff9-b35a-49aa-aa64-5d2bab574843",
            "firstName": "Joseph A.",
            "lastName": "Miller",
            "fullName": "Joe Miller",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/joseph-a-miller-iii/headshots/thumbnails/medium/joseph-a-miller-iii-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-OH-53-lower-ocd-person-9b5a9ff9-b35a-49aa-aa64-5d2bab574843",
              "builtID": "oh-lower-53",
              "externalID": "ocd-person/9b5a9ff9-b35a-49aa-aa64-5d2bab574843",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep53@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/joseph-a-miller-iii",
                "https://ohiohouse.gov/joseph-a-miller-iii",
                "https://www.ohiohouse.gov/members/joseph-a-miller-iii"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-53-lower-ocd-person-9b5a9ff9-b35a-49aa-aa64-5d2bab574843": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/0e7997cd-9c4b-4f28-8ed5-1f9bda887e07",
            "firstName": "Dick",
            "lastName": "Stein",
            "fullName": "Dick Stein",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/dick-stein/headshots/thumbnails/medium/dick-stein-january-2017-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-OH-54-lower-ocd-person-0e7997cd-9c4b-4f28-8ed5-1f9bda887e07",
              "builtID": "oh-lower-54",
              "externalID": "ocd-person/0e7997cd-9c4b-4f28-8ed5-1f9bda887e07",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep54@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/dick-stein",
                "https://ohiohouse.gov/dick-stein",
                "https://www.ohiohouse.gov/members/dick-stein"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-54-lower-ocd-person-0e7997cd-9c4b-4f28-8ed5-1f9bda887e07": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/686478b9-1dbf-4903-a4df-27942ac0a58c",
            "firstName": "P. Scott",
            "lastName": "Lipps",
            "fullName": "Scott Lipps",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/p-scott-lipps/headshots/thumbnails/medium/p-scott-lipps-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-OH-55-lower-ocd-person-686478b9-1dbf-4903-a4df-27942ac0a58c",
              "builtID": "oh-lower-55",
              "externalID": "ocd-person/686478b9-1dbf-4903-a4df-27942ac0a58c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep55@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/p-scott-lipps",
                "https://ohiohouse.gov/p-scott-lipps",
                "https://www.ohiohouse.gov/members/p-scott-lipps"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-55-lower-ocd-person-686478b9-1dbf-4903-a4df-27942ac0a58c": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/b04e2962-e7c6-46b8-9a99-d7a6d3a32717",
            "firstName": "Adam",
            "lastName": "Mathews",
            "fullName": "Adam Mathews",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/adam-mathews/headshots/thumbnails/medium/adam-mathews-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-OH-56-lower-ocd-person-b04e2962-e7c6-46b8-9a99-d7a6d3a32717",
              "builtID": "oh-lower-56",
              "externalID": "ocd-person/b04e2962-e7c6-46b8-9a99-d7a6d3a32717",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep56@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/adam-mathews"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-56-lower-ocd-person-b04e2962-e7c6-46b8-9a99-d7a6d3a32717": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/4b7cbc16-7a67-4a61-8fd5-77a857270774",
            "firstName": "Jamie",
            "lastName": "Callender",
            "fullName": "Jamie Callender",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/jamie-callender/headshots/thumbnails/medium/jamie-callender-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-OH-57-lower-ocd-person-4b7cbc16-7a67-4a61-8fd5-77a857270774",
              "builtID": "oh-lower-57",
              "externalID": "ocd-person/4b7cbc16-7a67-4a61-8fd5-77a857270774",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep57@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/jamie-callender",
                "https://ohiohouse.gov/jamie-callender",
                "https://www.ohiohouse.gov/members/jamie-callender"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-57-lower-ocd-person-4b7cbc16-7a67-4a61-8fd5-77a857270774": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/16e469e4-8014-4ba2-8fc4-91804d6ef491",
            "firstName": "Tex",
            "lastName": "Fischer",
            "fullName": "Tex Fischer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://ogden_images.s3.amazonaws.com/www.salemnews.net/images/2024/06/27232837/Fischer-Tex.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-OH-58-lower-ocd-person-16e469e4-8014-4ba2-8fc4-91804d6ef491",
              "builtID": "oh-lower-58",
              "externalID": "ocd-person/16e469e4-8014-4ba2-8fc4-91804d6ef491",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep58@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ohiohouse.gov/houseapp/members/tex-fischer"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-58-lower-ocd-person-16e469e4-8014-4ba2-8fc4-91804d6ef491": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/19cbe255-2cdb-47c4-b70a-693b6bf6665b",
            "firstName": "Lauren",
            "lastName": "McNally",
            "fullName": "Lauren McNally",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/lauren-mcnally/headshots/thumbnails/medium/lauren-mcnally-february-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-OH-59-lower-ocd-person-19cbe255-2cdb-47c4-b70a-693b6bf6665b",
              "builtID": "oh-lower-59",
              "externalID": "ocd-person/19cbe255-2cdb-47c4-b70a-693b6bf6665b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep59@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/lauren-mcnally"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-59-lower-ocd-person-19cbe255-2cdb-47c4-b70a-693b6bf6665b": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/a3ccce64-ea28-47dc-8bab-3af6ed2dd860",
            "firstName": "Brian",
            "lastName": "Lorenz",
            "fullName": "Brian Lorenz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/brian-lorenz/headshots/thumbnails/medium/brian-lorenz-november-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-OH-60-lower-ocd-person-a3ccce64-ea28-47dc-8bab-3af6ed2dd860",
              "builtID": "oh-lower-60",
              "externalID": "ocd-person/a3ccce64-ea28-47dc-8bab-3af6ed2dd860",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep60@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ohiohouse.gov/members/brian-lorenz"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-60-lower-ocd-person-a3ccce64-ea28-47dc-8bab-3af6ed2dd860": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/de0a6483-7c16-43e1-a76b-c0cc98a9d7ed",
            "firstName": "Beth",
            "lastName": "Lear",
            "fullName": "Beth Lear",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/beth-lear/headshots/thumbnails/medium/beth-lear-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-OH-61-lower-ocd-person-de0a6483-7c16-43e1-a76b-c0cc98a9d7ed",
              "builtID": "oh-lower-61",
              "externalID": "ocd-person/de0a6483-7c16-43e1-a76b-c0cc98a9d7ed",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep61@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/beth-lear"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-61-lower-ocd-person-de0a6483-7c16-43e1-a76b-c0cc98a9d7ed": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/392629a2-7bb2-47df-8128-d4cd7d858324",
            "firstName": "Jeannette Mary",
            "lastName": "Schmidt",
            "fullName": "Jean Schmidt",
            "gender": "Female",
            "party": "Republican",
            "image": null,
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-OH-62-lower-ocd-person-392629a2-7bb2-47df-8128-d4cd7d858324",
              "builtID": "oh-lower-62",
              "externalID": "ocd-person/392629a2-7bb2-47df-8128-d4cd7d858324",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep62@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/jean-schmidt",
                "https://ohiohouse.gov/members/jean-schmidt"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-62-lower-ocd-person-392629a2-7bb2-47df-8128-d4cd7d858324": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/0c6a7bcf-e990-41a0-b59c-923f05771880",
            "firstName": "Adam",
            "lastName": "Bird",
            "fullName": "Adam Bird",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/adam-c-bird/headshots/thumbnails/medium/adam-c-bird-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-OH-63-lower-ocd-person-0c6a7bcf-e990-41a0-b59c-923f05771880",
              "builtID": "oh-lower-63",
              "externalID": "ocd-person/0c6a7bcf-e990-41a0-b59c-923f05771880",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep63@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/adam-c-bird",
                "https://ohiohouse.gov/members/adam-c-bird"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-63-lower-ocd-person-0c6a7bcf-e990-41a0-b59c-923f05771880": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/64305698-f25c-486e-b57f-00298cfbe15d",
            "firstName": "Nick",
            "lastName": "Santucci",
            "fullName": "Nick Santucci",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/nick-santucci/headshots/thumbnails/medium/nick-santucci-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-OH-64-lower-ocd-person-64305698-f25c-486e-b57f-00298cfbe15d",
              "builtID": "oh-lower-64",
              "externalID": "ocd-person/64305698-f25c-486e-b57f-00298cfbe15d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep64@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/nick-santucci"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-64-lower-ocd-person-64305698-f25c-486e-b57f-00298cfbe15d": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/495f037f-b054-4eea-956f-05f672bcf142",
            "firstName": "Michael",
            "lastName": "Loychik",
            "fullName": "Mike Loychik",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/mike-loychik/headshots/thumbnails/medium/mike-loychik-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-OH-65-lower-ocd-person-495f037f-b054-4eea-956f-05f672bcf142",
              "builtID": "oh-lower-65",
              "externalID": "ocd-person/495f037f-b054-4eea-956f-05f672bcf142",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep65@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/mike-loychik",
                "https://ohiohouse.gov/members/mike-loychik"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-65-lower-ocd-person-495f037f-b054-4eea-956f-05f672bcf142": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/352d7ee9-256c-4195-a673-17b7ee200948",
            "firstName": "Sharon A.",
            "lastName": "Ray",
            "fullName": "Sharon Ray",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/sharon-a-ray/headshots/thumbnails/medium/sharon-a-ray-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-OH-66-lower-ocd-person-352d7ee9-256c-4195-a673-17b7ee200948",
              "builtID": "oh-lower-66",
              "externalID": "ocd-person/352d7ee9-256c-4195-a673-17b7ee200948",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep66@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/sharon-a-ray",
                "https://ohiohouse.gov/members/sharon-a-ray"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-66-lower-ocd-person-352d7ee9-256c-4195-a673-17b7ee200948": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/fb957ddc-f41c-400d-ae32-47f7e729555b",
            "firstName": "Melanie Murphy",
            "lastName": "Miller",
            "fullName": "Melanie Miller",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/melanie-miller/headshots/thumbnails/medium/melanie-miller-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-OH-67-lower-ocd-person-fb957ddc-f41c-400d-ae32-47f7e729555b",
              "builtID": "oh-lower-67",
              "externalID": "ocd-person/fb957ddc-f41c-400d-ae32-47f7e729555b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep67@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/melanie-miller"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-67-lower-ocd-person-fb957ddc-f41c-400d-ae32-47f7e729555b": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/081534e7-8952-47f0-a722-ed627fb32069",
            "firstName": "Thaddeus J.",
            "lastName": "Claggett",
            "fullName": "Thad Claggett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/thaddeus-j-claggett/headshots/thumbnails/medium/thaddeus-j-claggett-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-OH-68-lower-ocd-person-081534e7-8952-47f0-a722-ed627fb32069",
              "builtID": "oh-lower-68",
              "externalID": "ocd-person/081534e7-8952-47f0-a722-ed627fb32069",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep68@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/thaddeus-j-claggett"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-68-lower-ocd-person-081534e7-8952-47f0-a722-ed627fb32069": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/cefb7a31-f46c-4145-9f38-901be3b70e60",
            "firstName": "Kevin D.",
            "lastName": "Miller",
            "fullName": "Kevin Miller",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/kevin-d-miller/headshots/thumbnails/medium/kevin-d-miller-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-OH-69-lower-ocd-person-cefb7a31-f46c-4145-9f38-901be3b70e60",
              "builtID": "oh-lower-69",
              "externalID": "ocd-person/cefb7a31-f46c-4145-9f38-901be3b70e60",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep69@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/kevin-d-miller",
                "https://ohiohouse.gov/members/kevin-d-miller"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-69-lower-ocd-person-cefb7a31-f46c-4145-9f38-901be3b70e60": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/1895e223-ba42-4e25-8cd0-915ca5cc2829",
            "firstName": "Brian E.",
            "lastName": "Lampton",
            "fullName": "Brian Lampton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/brian-e-lampton/headshots/thumbnails/medium/brian-e-lampton-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-OH-70-lower-ocd-person-1895e223-ba42-4e25-8cd0-915ca5cc2829",
              "builtID": "oh-lower-70",
              "externalID": "ocd-person/1895e223-ba42-4e25-8cd0-915ca5cc2829",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep70@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/brian-e-lampton",
                "https://ohiohouse.gov/members/brian-e-lampton"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-70-lower-ocd-person-1895e223-ba42-4e25-8cd0-915ca5cc2829": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/b9200c63-1930-48f6-b870-b15a378a4c0a",
            "firstName": "Bill",
            "lastName": "Dean",
            "fullName": "Bill Dean",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/bill-dean/headshots/thumbnails/medium/bill-dean-april-2016-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-OH-71-lower-ocd-person-b9200c63-1930-48f6-b870-b15a378a4c0a",
              "builtID": "oh-lower-71",
              "externalID": "ocd-person/b9200c63-1930-48f6-b870-b15a378a4c0a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep71@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/bill-dean",
                "https://ohiohouse.gov/bill-dean",
                "https://www.ohiohouse.gov/members/bill-dean"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-71-lower-ocd-person-b9200c63-1930-48f6-b870-b15a378a4c0a": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/4340da3c-c4d8-4381-9f20-f196d09e0896",
            "firstName": "Gail",
            "lastName": "Pavliga",
            "fullName": "Gail Pavliga",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/gail-k-pavliga/headshots/thumbnails/medium/gail-k-pavliga-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-OH-72-lower-ocd-person-4340da3c-c4d8-4381-9f20-f196d09e0896",
              "builtID": "oh-lower-72",
              "externalID": "ocd-person/4340da3c-c4d8-4381-9f20-f196d09e0896",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep72@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/gail-k-pavliga",
                "https://ohiohouse.gov/members/gail-k-pavliga"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-72-lower-ocd-person-4340da3c-c4d8-4381-9f20-f196d09e0896": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/60fdc824-a8ab-44fa-80ca-053d8698218e",
            "firstName": "Jeffrey",
            "lastName": "LaRe",
            "fullName": "Jeff LaRe",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/jeff-lare/headshots/thumbnails/medium/jeff-lare-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-OH-73-lower-ocd-person-60fdc824-a8ab-44fa-80ca-053d8698218e",
              "builtID": "oh-lower-73",
              "externalID": "ocd-person/60fdc824-a8ab-44fa-80ca-053d8698218e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep73@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/jeff-lare",
                "https://ohiohouse.gov/jeff-lare",
                "https://www.ohiohouse.gov/members/jeff-lare"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-73-lower-ocd-person-60fdc824-a8ab-44fa-80ca-053d8698218e": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/550986e6-b595-4fa8-9424-8e09327cf060",
            "firstName": "Bernard",
            "lastName": "Willis",
            "fullName": "Bernie Willis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/bernard-willis/headshots/thumbnails/medium/bernard-willis-february-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-OH-74-lower-ocd-person-550986e6-b595-4fa8-9424-8e09327cf060",
              "builtID": "oh-lower-74",
              "externalID": "ocd-person/550986e6-b595-4fa8-9424-8e09327cf060",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep74@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/bernard-willis"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-74-lower-ocd-person-550986e6-b595-4fa8-9424-8e09327cf060": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/82dd3548-078a-41a8-8c5b-6c652d88d0d2",
            "firstName": "Haraz N.",
            "lastName": "Ghanbari",
            "fullName": "Haraz Ghanbari",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/haraz-n-ghanbari/headshots/thumbnails/medium/haraz-n-ghanbari-november-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-OH-75-lower-ocd-person-82dd3548-078a-41a8-8c5b-6c652d88d0d2",
              "builtID": "oh-lower-75",
              "externalID": "ocd-person/82dd3548-078a-41a8-8c5b-6c652d88d0d2",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep75@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/haraz-n-ghanbari",
                "https://ohiohouse.gov/haraz-n-ghanbari",
                "https://www.ohiohouse.gov/members/haraz-n-ghanbari"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-75-lower-ocd-person-82dd3548-078a-41a8-8c5b-6c652d88d0d2": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/e4f9416c-1043-4737-899e-31410e94af1d",
            "firstName": "Marilyn Sue",
            "lastName": "John",
            "fullName": "Marilyn John",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/marilyn-s-john/headshots/thumbnails/medium/marilyn-s-john-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-OH-76-lower-ocd-person-e4f9416c-1043-4737-899e-31410e94af1d",
              "builtID": "oh-lower-76",
              "externalID": "ocd-person/e4f9416c-1043-4737-899e-31410e94af1d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep76@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/marilyn-s-john",
                "https://ohiohouse.gov/members/marilyn-s-john"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-76-lower-ocd-person-e4f9416c-1043-4737-899e-31410e94af1d": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/76ac1fd9-fc8e-47ae-9eb7-5278b4f14704",
            "firstName": "Scott",
            "lastName": "Wiggam",
            "fullName": "Scott Wiggam",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/scott-wiggam/headshots/thumbnails/medium/scott-wiggam-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-OH-77-lower-ocd-person-76ac1fd9-fc8e-47ae-9eb7-5278b4f14704",
              "builtID": "oh-lower-77",
              "externalID": "ocd-person/76ac1fd9-fc8e-47ae-9eb7-5278b4f14704",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep77@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/scott-wiggam",
                "https://ohiohouse.gov/scott-wiggam",
                "https://www.ohiohouse.gov/members/scott-wiggam"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-77-lower-ocd-person-76ac1fd9-fc8e-47ae-9eb7-5278b4f14704": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/0e44b586-f6a4-44ee-b6b7-37fa8dc2e8cd",
            "firstName": "Susan Annette",
            "lastName": "Manchester",
            "fullName": "Susan Manchester",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/susan-manchester/headshots/thumbnails/medium/susan-manchester-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-OH-78-lower-ocd-person-0e44b586-f6a4-44ee-b6b7-37fa8dc2e8cd",
              "builtID": "oh-lower-78",
              "externalID": "ocd-person/0e44b586-f6a4-44ee-b6b7-37fa8dc2e8cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep78@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/susan-manchester",
                "https://ohiohouse.gov/susan-manchester",
                "https://www.ohiohouse.gov/members/susan-manchester"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-78-lower-ocd-person-0e44b586-f6a4-44ee-b6b7-37fa8dc2e8cd": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/062b9622-c2fa-4af2-bbea-704f0450dd47",
            "firstName": "Monica Robb",
            "lastName": "Blasdel",
            "fullName": "Monica Blasdel",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/monica-robb-blasdel/headshots/thumbnails/medium/monica-robb-blasdel-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-OH-79-lower-ocd-person-062b9622-c2fa-4af2-bbea-704f0450dd47",
              "builtID": "oh-lower-79",
              "externalID": "ocd-person/062b9622-c2fa-4af2-bbea-704f0450dd47",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep79@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/monica-robb-blasdel"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-79-lower-ocd-person-062b9622-c2fa-4af2-bbea-704f0450dd47": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/56161a79-480c-4b58-b0a1-a802a8bfd2f4",
            "firstName": "Jena",
            "lastName": "Powell",
            "fullName": "Jena Powell",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/jena-powell/headshots/thumbnails/medium/jena-powell-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-OH-80-lower-ocd-person-56161a79-480c-4b58-b0a1-a802a8bfd2f4",
              "builtID": "oh-lower-80",
              "externalID": "ocd-person/56161a79-480c-4b58-b0a1-a802a8bfd2f4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep80@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/jena-powell",
                "https://ohiohouse.gov/jena-powell",
                "https://www.ohiohouse.gov/members/jena-powell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-80-lower-ocd-person-56161a79-480c-4b58-b0a1-a802a8bfd2f4": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/1a598246-01e8-44c8-beca-2c41e1bafb77",
            "firstName": "James M.",
            "lastName": "Hoops",
            "fullName": "Jim Hoops",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/james-m-hoops/headshots/thumbnails/medium/james-m-hoops-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-OH-81-lower-ocd-person-1a598246-01e8-44c8-beca-2c41e1bafb77",
              "builtID": "oh-lower-81",
              "externalID": "ocd-person/1a598246-01e8-44c8-beca-2c41e1bafb77",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep81@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/james-m-hoops",
                "https://ohiohouse.gov/james-m-hoops",
                "https://ohiohouse.gov/members/james-m-hoops"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-81-lower-ocd-person-1a598246-01e8-44c8-beca-2c41e1bafb77": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/73e72147-0d40-4478-bbf6-3419f0e00b46",
            "firstName": "Roy",
            "lastName": "Klopfenstein",
            "fullName": "Roy Klopfenstein",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/roy-klopfenstein/headshots/thumbnails/medium/roy-klopfenstein-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-OH-82-lower-ocd-person-73e72147-0d40-4478-bbf6-3419f0e00b46",
              "builtID": "oh-lower-82",
              "externalID": "ocd-person/73e72147-0d40-4478-bbf6-3419f0e00b46",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep82@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/roy-klopfenstein"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-82-lower-ocd-person-73e72147-0d40-4478-bbf6-3419f0e00b46": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/db1a5f41-b26c-4875-8e9d-6f8466b101cb",
            "firstName": "Jon",
            "lastName": "Cross",
            "fullName": "Jon Cross",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/jon-cross/headshots/thumbnails/medium/jon-cross-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-OH-83-lower-ocd-person-db1a5f41-b26c-4875-8e9d-6f8466b101cb",
              "builtID": "oh-lower-83",
              "externalID": "ocd-person/db1a5f41-b26c-4875-8e9d-6f8466b101cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep83@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/jon-cross",
                "https://ohiohouse.gov/jon-cross",
                "https://www.ohiohouse.gov/members/jon-cross"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-83-lower-ocd-person-db1a5f41-b26c-4875-8e9d-6f8466b101cb": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/4ed8184a-01ff-4bdc-a06c-cbf8b4c73fd6",
            "firstName": "Angela Noneman",
            "lastName": "King",
            "fullName": "Angie King",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/angela-n-king/headshots/thumbnails/medium/angela-n-king-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-OH-84-lower-ocd-person-4ed8184a-01ff-4bdc-a06c-cbf8b4c73fd6",
              "builtID": "oh-lower-84",
              "externalID": "ocd-person/4ed8184a-01ff-4bdc-a06c-cbf8b4c73fd6",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep84@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/angela-n-king"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-84-lower-ocd-person-4ed8184a-01ff-4bdc-a06c-cbf8b4c73fd6": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/fa5ff978-34ff-49d1-958e-4e356a8ef9d4",
            "firstName": "Timothy N.",
            "lastName": "Barhorst",
            "fullName": "Tim Barhorst",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/tim-barhorst/headshots/thumbnails/medium/tim-barhorst-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-OH-85-lower-ocd-person-fa5ff978-34ff-49d1-958e-4e356a8ef9d4",
              "builtID": "oh-lower-85",
              "externalID": "ocd-person/fa5ff978-34ff-49d1-958e-4e356a8ef9d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep85@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiohouse.gov/members/tim-barhorst"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-85-lower-ocd-person-fa5ff978-34ff-49d1-958e-4e356a8ef9d4": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/7bcb4dfa-e4d3-46cd-b158-057b1b4b9a0a",
            "firstName": "Tracy M.",
            "lastName": "Richardson",
            "fullName": "Tracy Richardson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/tracy-m-richardson/headshots/thumbnails/medium/tracy-m-richardson-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-OH-86-lower-ocd-person-7bcb4dfa-e4d3-46cd-b158-057b1b4b9a0a",
              "builtID": "oh-lower-86",
              "externalID": "ocd-person/7bcb4dfa-e4d3-46cd-b158-057b1b4b9a0a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep86@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/tracy-m-richardson",
                "https://ohiohouse.gov/tracy-m-richardson",
                "https://www.ohiohouse.gov/members/tracy-m-richardson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-86-lower-ocd-person-7bcb4dfa-e4d3-46cd-b158-057b1b4b9a0a": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/d6ca7e4f-dcf2-4aad-a919-a2740bae649a",
            "firstName": "Riordan T.",
            "lastName": "McClain",
            "fullName": "Riordan McClain",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/riordan-t-mcclain/headshots/thumbnails/medium/riordan-t-mcclain-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-OH-87-lower-ocd-person-d6ca7e4f-dcf2-4aad-a919-a2740bae649a",
              "builtID": "oh-lower-87",
              "externalID": "ocd-person/d6ca7e4f-dcf2-4aad-a919-a2740bae649a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep87@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/riordan-t-mcclain",
                "https://ohiohouse.gov/riordan-t-mcclain",
                "https://ohiohouse.gov/members/riordan-t-mcclain"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-87-lower-ocd-person-d6ca7e4f-dcf2-4aad-a919-a2740bae649a": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/04e3c98a-4ede-4501-b914-4fa13fbad194",
            "firstName": "Gary",
            "lastName": "Click",
            "fullName": "Gary Click",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/gary-click/headshots/thumbnails/medium/gary-click-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-OH-88-lower-ocd-person-04e3c98a-4ede-4501-b914-4fa13fbad194",
              "builtID": "oh-lower-88",
              "externalID": "ocd-person/04e3c98a-4ede-4501-b914-4fa13fbad194",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep88@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/gary-click",
                "https://ohiohouse.gov/members/gary-click"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-88-lower-ocd-person-04e3c98a-4ede-4501-b914-4fa13fbad194": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/cb2ddd04-e5b0-4c96-b217-d02b732eb896",
            "firstName": "Douglas J.",
            "lastName": "Swearingen",
            "fullName": "D.J. Swearingen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/d-j-swearingen/headshots/thumbnails/medium/d-j-swearingen-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-OH-89-lower-ocd-person-cb2ddd04-e5b0-4c96-b217-d02b732eb896",
              "builtID": "oh-lower-89",
              "externalID": "ocd-person/cb2ddd04-e5b0-4c96-b217-d02b732eb896",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep89@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/d-j-swearingen",
                "https://ohiohouse.gov/d-j-swearingen",
                "https://www.ohiohouse.gov/members/d-j-swearingen"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-89-lower-ocd-person-cb2ddd04-e5b0-4c96-b217-d02b732eb896": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/9892fcd7-9432-425f-85a6-7599fbe3177d",
            "firstName": "Justin",
            "lastName": "Pizzulli",
            "fullName": "Justin Pizzulli",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/justin-pizzulli/headshots/thumbnails/medium/justin-pizzulli-july-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-OH-90-lower-ocd-person-9892fcd7-9432-425f-85a6-7599fbe3177d",
              "builtID": "oh-lower-90",
              "externalID": "ocd-person/9892fcd7-9432-425f-85a6-7599fbe3177d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep90@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ohiohouse.gov/members/justin-pizzulli"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-90-lower-ocd-person-9892fcd7-9432-425f-85a6-7599fbe3177d": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/cdc9f705-514e-4445-ac17-9aeac8f67e97",
            "firstName": "Bob",
            "lastName": "Peterson",
            "fullName": "Bob Peterson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/bob-peterson/headshots/thumbnails/medium/bob-peterson-november-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-OH-91-lower-ocd-person-cdc9f705-514e-4445-ac17-9aeac8f67e97",
              "builtID": "oh-lower-91",
              "externalID": "ocd-person/cdc9f705-514e-4445-ac17-9aeac8f67e97",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep91@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/peterson",
                "https://ohiohouse.gov/members/bob-peterson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-91-lower-ocd-person-cdc9f705-514e-4445-ac17-9aeac8f67e97": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/4af0e8db-ad18-424f-abfa-ce31e37c0b7d",
            "firstName": "Mark",
            "lastName": "Johnson",
            "fullName": "Mark Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/mark-johnson/headshots/thumbnails/medium/mark-johnson-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-OH-92-lower-ocd-person-4af0e8db-ad18-424f-abfa-ce31e37c0b7d",
              "builtID": "oh-lower-92",
              "externalID": "ocd-person/4af0e8db-ad18-424f-abfa-ce31e37c0b7d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep92@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/mark-johnson",
                "https://ohiohouse.gov/members/mark-johnson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-92-lower-ocd-person-4af0e8db-ad18-424f-abfa-ce31e37c0b7d": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/fbd5ca34-d2ed-41d0-a0f8-03ee8eca8a4d",
            "firstName": "Jason C.",
            "lastName": "Stephens",
            "fullName": "Jason Stephens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/jason-stephens/headshots/thumbnails/medium/jason-stephens-november-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-OH-93-lower-ocd-person-fbd5ca34-d2ed-41d0-a0f8-03ee8eca8a4d",
              "builtID": "oh-lower-93",
              "externalID": "ocd-person/fbd5ca34-d2ed-41d0-a0f8-03ee8eca8a4d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep93@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/jason-stephens",
                "https://ohiohouse.gov/jason-stephens",
                "https://www.ohiohouse.gov/members/jason-stephens"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-93-lower-ocd-person-fbd5ca34-d2ed-41d0-a0f8-03ee8eca8a4d": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/996aa113-f421-4925-b943-336844b8814a",
            "firstName": "Jay",
            "lastName": "Edwards",
            "fullName": "Jay Edwards",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/jay-edwards/headshots/thumbnails/medium/jay-edwards-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-OH-94-lower-ocd-person-996aa113-f421-4925-b943-336844b8814a",
              "builtID": "oh-lower-94",
              "externalID": "ocd-person/996aa113-f421-4925-b943-336844b8814a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep94@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "jayedwardsohio",
              "youtube": null,
              "instagram": "jayedwardsohio",
              "facebook": "JayEdwardsOhio",
              "urls": [
                "https://www.ohiohouse.gov/jay-edwards",
                "https://ohiohouse.gov/jay-edwards",
                "https://www.ohiohouse.gov/members/jay-edwards"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-94-lower-ocd-person-996aa113-f421-4925-b943-336844b8814a": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/22d5a5b7-e1f4-4988-8242-eefd7468cc81",
            "firstName": "Don",
            "lastName": "Jones",
            "fullName": "Don Jones",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/don-jones/headshots/thumbnails/medium/don-jones-january-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-OH-95-lower-ocd-person-22d5a5b7-e1f4-4988-8242-eefd7468cc81",
              "builtID": "oh-lower-95",
              "externalID": "ocd-person/22d5a5b7-e1f4-4988-8242-eefd7468cc81",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep95@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/don-jones",
                "https://ohiohouse.gov/don-jones",
                "https://www.ohiohouse.gov/members/don-jones"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-95-lower-ocd-person-22d5a5b7-e1f4-4988-8242-eefd7468cc81": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/52608475-9678-45ce-823a-74f9c931682c",
            "firstName": "Ron",
            "lastName": "Ferguson",
            "fullName": "Ron Ferguson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/ron-ferguson/headshots/thumbnails/medium/ron-ferguson-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-OH-96-lower-ocd-person-52608475-9678-45ce-823a-74f9c931682c",
              "builtID": "oh-lower-96",
              "externalID": "ocd-person/52608475-9678-45ce-823a-74f9c931682c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep96@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/ron-ferguson",
                "https://ohiohouse.gov/members/ron-ferguson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-96-lower-ocd-person-52608475-9678-45ce-823a-74f9c931682c": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/78912d95-3dac-4487-810a-74be41508ebb",
            "firstName": "Adam",
            "lastName": "Holmes",
            "fullName": "Adam Holmes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/adam-holmes/headshots/thumbnails/medium/adam-holmes-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-OH-97-lower-ocd-person-78912d95-3dac-4487-810a-74be41508ebb",
              "builtID": "oh-lower-97",
              "externalID": "ocd-person/78912d95-3dac-4487-810a-74be41508ebb",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep97@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/adam-holmes",
                "https://ohiohouse.gov/adam-holmes",
                "https://www.ohiohouse.gov/members/adam-holmes"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-97-lower-ocd-person-78912d95-3dac-4487-810a-74be41508ebb": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/92de07aa-a6c7-4c4b-ada7-02ebbe4a17f6",
            "firstName": "Darrell D.",
            "lastName": "Kick",
            "fullName": "Darrell Kick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/darrell-kick/headshots/thumbnails/medium/darrell-kick-january-2021-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-OH-98-lower-ocd-person-92de07aa-a6c7-4c4b-ada7-02ebbe4a17f6",
              "builtID": "oh-lower-98",
              "externalID": "ocd-person/92de07aa-a6c7-4c4b-ada7-02ebbe4a17f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep98@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/darrell-kick",
                "https://ohiohouse.gov/darrell-kick",
                "https://www.ohiohouse.gov/members/darrell-kick"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-98-lower-ocd-person-92de07aa-a6c7-4c4b-ada7-02ebbe4a17f6": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/5b3a7d10-e745-4985-ade9-d7fff6ae6af4",
            "firstName": "Sarah Elaine",
            "lastName": "Fowler Arthur",
            "fullName": "Sarah Fowler Arthur",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/sarah-fowler-arthur/headshots/thumbnails/medium/sarah-fowler-arthur-february-2023-headshot_medium.jpg",
            "title": "OH Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-OH-99-lower-ocd-person-5b3a7d10-e745-4985-ade9-d7fff6ae6af4",
              "builtID": "oh-lower-99",
              "externalID": "ocd-person/5b3a7d10-e745-4985-ade9-d7fff6ae6af4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep99@ohiohouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/sarah-fowler-arthur",
                "https://ohiohouse.gov/members/sarah-fowler-arthur"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OH-99-lower-ocd-person-5b3a7d10-e745-4985-ade9-d7fff6ae6af4": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/e0d52f86-e997-4299-9e50-972a2c208c20",
            "firstName": "Robert",
            "lastName": "McColley",
            "fullName": "Rob McColley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/rob-mccolley/headshots/thumbnails/medium/rob-mccolley-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-OH-1-upper-ocd-person-e0d52f86-e997-4299-9e50-972a2c208c20",
              "builtID": "oh-upper-1",
              "externalID": "ocd-person/e0d52f86-e997-4299-9e50-972a2c208c20",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd01@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "rob_mccolley",
              "youtube": null,
              "instagram": null,
              "facebook": "mccolley4ohio",
              "urls": [
                "https://www.ohiosenate.gov/senators/mccolley",
                "https://www.ohiosenate.gov/members/rob-mccolley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-1-upper-ocd-person-e0d52f86-e997-4299-9e50-972a2c208c20": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/0fe32749-fe89-4f96-87d3-3d831bea2459",
            "firstName": "Theresa Charters",
            "lastName": "Gavarone",
            "fullName": "Theresa Gavarone",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/theresa-gavarone/headshots/thumbnails/medium/theresa-gavarone-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-OH-2-upper-ocd-person-0fe32749-fe89-4f96-87d3-3d831bea2459",
              "builtID": "oh-upper-2",
              "externalID": "ocd-person/0fe32749-fe89-4f96-87d3-3d831bea2459",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd02@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/gavarone",
                "https://www.ohiosenate.gov/members/theresa-gavarone"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-2-upper-ocd-person-0fe32749-fe89-4f96-87d3-3d831bea2459": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/79af20c0-6eb6-44a2-ba79-9c7e5e10ea04",
            "firstName": "Michele",
            "lastName": "Reynolds",
            "fullName": "Michele Reynolds",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/michele-reynolds/headshots/thumbnails/medium/michele-reynolds-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-OH-3-upper-ocd-person-79af20c0-6eb6-44a2-ba79-9c7e5e10ea04",
              "builtID": "oh-upper-3",
              "externalID": "ocd-person/79af20c0-6eb6-44a2-ba79-9c7e5e10ea04",
              "geometry": null
            },
            "contactInfo": {
              "email": "reynolds@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiosenate.gov/members/michele-reynolds"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-3-upper-ocd-person-79af20c0-6eb6-44a2-ba79-9c7e5e10ea04": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/445d3fc7-d9a5-48d8-8869-f023aa274ae7",
            "firstName": "George F.",
            "lastName": "Lang",
            "fullName": "George Lang",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/george-f-lang/headshots/thumbnails/medium/george-f-lang-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-OH-4-upper-ocd-person-445d3fc7-d9a5-48d8-8869-f023aa274ae7",
              "builtID": "oh-upper-4",
              "externalID": "ocd-person/445d3fc7-d9a5-48d8-8869-f023aa274ae7",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd04@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/george-f-lang",
                "https://www.ohiosenate.gov/senators/lang",
                "https://www.ohiosenate.gov/members/george-f-lang"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-4-upper-ocd-person-445d3fc7-d9a5-48d8-8869-f023aa274ae7": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/b21d9dcd-21e9-4071-936d-f89fc5d8f643",
            "firstName": "Stephen A.",
            "lastName": "Huffman",
            "fullName": "Steve Huffman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/stephen-a-huffman/headshots/thumbnails/medium/stephen-a-huffman-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-OH-5-upper-ocd-person-b21d9dcd-21e9-4071-936d-f89fc5d8f643",
              "builtID": "oh-upper-5",
              "externalID": "ocd-person/b21d9dcd-21e9-4071-936d-f89fc5d8f643",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd05@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/huffman-s",
                "https://www.ohiosenate.gov/members/stephen-a-huffman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-5-upper-ocd-person-b21d9dcd-21e9-4071-936d-f89fc5d8f643": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/5c7158e0-2f04-4021-8947-50d1d4cd5cae",
            "firstName": "Niraj Jaimini",
            "lastName": "Antani",
            "fullName": "Niraj Antani",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/niraj-antani/headshots/thumbnails/medium/niraj-antani-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-OH-6-upper-ocd-person-5c7158e0-2f04-4021-8947-50d1d4cd5cae",
              "builtID": "oh-upper-6",
              "externalID": "ocd-person/5c7158e0-2f04-4021-8947-50d1d4cd5cae",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd06@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/niraj-antani",
                "https://www.ohiosenate.gov/senators/antani",
                "https://www.ohiosenate.gov/members/niraj-antani"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-6-upper-ocd-person-5c7158e0-2f04-4021-8947-50d1d4cd5cae": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/26444e7e-72b2-4838-bc24-31899c90d504",
            "firstName": "Steve",
            "lastName": "Wilson",
            "fullName": "Steve Wilson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/steve-wilson/headshots/thumbnails/medium/steve-wilson-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-OH-7-upper-ocd-person-26444e7e-72b2-4838-bc24-31899c90d504",
              "builtID": "oh-upper-7",
              "externalID": "ocd-person/26444e7e-72b2-4838-bc24-31899c90d504",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd07@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "stevewilsonoh",
              "youtube": null,
              "instagram": null,
              "facebook": "stevewilsonoh",
              "urls": [
                "https://www.ohiosenate.gov/senators/wilson",
                "https://www.ohiosenate.gov/members/steve-wilson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-7-upper-ocd-person-26444e7e-72b2-4838-bc24-31899c90d504": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/5a9b2a16-6827-4e00-961a-f3de39538749",
            "firstName": "Louis William",
            "lastName": "Blessing",
            "fullName": "Bill Blessing",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/louis-w-blessing-iii/headshots/thumbnails/medium/louis-w-blessing-iii-march-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-OH-8-upper-ocd-person-5a9b2a16-6827-4e00-961a-f3de39538749",
              "builtID": "oh-upper-8",
              "externalID": "ocd-person/5a9b2a16-6827-4e00-961a-f3de39538749",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd08@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/blessing",
                "https://www.ohiosenate.gov/members/louis-w-blessing-iii"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-8-upper-ocd-person-5a9b2a16-6827-4e00-961a-f3de39538749": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/94ba65dd-57c5-4ab0-b607-5e310d50cae0",
            "firstName": "Catherine D.",
            "lastName": "Ingram",
            "fullName": "Catherine Ingram",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/catherine-d-ingram/headshots/thumbnails/medium/catherine-d-ingram-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-OH-9-upper-ocd-person-94ba65dd-57c5-4ab0-b607-5e310d50cae0",
              "builtID": "oh-upper-9",
              "externalID": "ocd-person/94ba65dd-57c5-4ab0-b607-5e310d50cae0",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd09@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "cingram32",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/catherine-d-ingram",
                "https://ohiohouse.gov/catherine-d-ingram",
                "https://www.ohiosenate.gov/members/catherine-d-ingram"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-9-upper-ocd-person-94ba65dd-57c5-4ab0-b607-5e310d50cae0": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/59a0776d-2fef-4ee5-8d80-9c4095e43d86",
            "firstName": "Robert D.",
            "lastName": "Hackett",
            "fullName": "Bob Hackett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/bob-d-hackett/headshots/thumbnails/medium/bob-d-hackett-march-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-OH-10-upper-ocd-person-59a0776d-2fef-4ee5-8d80-9c4095e43d86",
              "builtID": "oh-upper-10",
              "externalID": "ocd-person/59a0776d-2fef-4ee5-8d80-9c4095e43d86",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd10@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "bobdhackett",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/hackett",
                "https://www.ohiosenate.gov/members/bob-d-hackett"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-10-upper-ocd-person-59a0776d-2fef-4ee5-8d80-9c4095e43d86": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/3329274c-22b6-4b0a-b12a-e64c5733bef9",
            "firstName": "Paula S.",
            "lastName": "Hicks-Hudson",
            "fullName": "Paula Hicks-Hudson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/paula-hicks-hudson/headshots/thumbnails/medium/paula-hicks-hudson-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-OH-11-upper-ocd-person-3329274c-22b6-4b0a-b12a-e64c5733bef9",
              "builtID": "oh-upper-11",
              "externalID": "ocd-person/3329274c-22b6-4b0a-b12a-e64c5733bef9",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd11@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "paulahickshuds1",
              "youtube": null,
              "instagram": "hickshudsonpaula",
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/paula-hicks-hudson",
                "https://ohiohouse.gov/paula-hicks-hudson",
                "https://ohiosenate.gov/members/paula-hicks-hudson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-11-upper-ocd-person-3329274c-22b6-4b0a-b12a-e64c5733bef9": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/23d51717-c6eb-4a2c-bd55-038c72593a61",
            "firstName": "Matthew C.",
            "lastName": "Huffman",
            "fullName": "Matt Huffman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/matt-huffman/headshots/thumbnails/medium/matt-huffman-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-OH-12-upper-ocd-person-23d51717-c6eb-4a2c-bd55-038c72593a61",
              "builtID": "oh-upper-12",
              "externalID": "ocd-person/23d51717-c6eb-4a2c-bd55-038c72593a61",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd12@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "matthuffman1",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/huffman-m",
                "https://www.ohiosenate.gov/members/matt-huffman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-12-upper-ocd-person-23d51717-c6eb-4a2c-bd55-038c72593a61": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/d3508136-3927-461f-a2f0-a85665845ab1",
            "firstName": "Nathan H.",
            "lastName": "Manning",
            "fullName": "Nathan Manning",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/nathan-h-manning/headshots/thumbnails/medium/nathan-h-manning-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-OH-13-upper-ocd-person-d3508136-3927-461f-a2f0-a85665845ab1",
              "builtID": "oh-upper-13",
              "externalID": "ocd-person/d3508136-3927-461f-a2f0-a85665845ab1",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd13@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/manning",
                "https://www.ohiosenate.gov/members/nathan-h-manning"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-13-upper-ocd-person-d3508136-3927-461f-a2f0-a85665845ab1": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/57300c34-0e2d-4e85-bdce-c4fb8c781541",
            "firstName": "Terry A.",
            "lastName": "Johnson",
            "fullName": "Terry Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/terry-johnson/headshots/thumbnails/medium/terry-johnson-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-OH-14-upper-ocd-person-57300c34-0e2d-4e85-bdce-c4fb8c781541",
              "builtID": "oh-upper-14",
              "externalID": "ocd-person/57300c34-0e2d-4e85-bdce-c4fb8c781541",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd14@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/johnson",
                "https://www.ohiosenate.gov/members/terry-johnson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-14-upper-ocd-person-57300c34-0e2d-4e85-bdce-c4fb8c781541": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/ed858ef9-97dc-4be8-97ab-006ea96923ae",
            "firstName": "Hearcel F.",
            "lastName": "Craig",
            "fullName": "Hearcel Craig",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/hearcel-f-craig/headshots/thumbnails/medium/hearcel-f-craig-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-OH-15-upper-ocd-person-ed858ef9-97dc-4be8-97ab-006ea96923ae",
              "builtID": "oh-upper-15",
              "externalID": "ocd-person/ed858ef9-97dc-4be8-97ab-006ea96923ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd15@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "hearcelcraig",
              "youtube": null,
              "instagram": "hearcelcraig",
              "facebook": "senatorhearcelcraig",
              "urls": [
                "https://www.ohiosenate.gov/senators/craig",
                "https://www.ohiosenate.gov/members/hearcel-f-craig"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-15-upper-ocd-person-ed858ef9-97dc-4be8-97ab-006ea96923ae": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/fd6110b8-258e-4f63-95e2-39ba11778cca",
            "firstName": "Stephanie",
            "lastName": "Kunze",
            "fullName": "Stephanie Kunze",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/stephanie-kunze/headshots/thumbnails/medium/stephanie-kunze-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-OH-16-upper-ocd-person-fd6110b8-258e-4f63-95e2-39ba11778cca",
              "builtID": "oh-upper-16",
              "externalID": "ocd-person/fd6110b8-258e-4f63-95e2-39ba11778cca",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd16@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "stephaniekunze",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/kunze",
                "https://www.ohiosenate.gov/members/stephanie-kunze"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-16-upper-ocd-person-fd6110b8-258e-4f63-95e2-39ba11778cca": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/bb1ae016-cdfe-48aa-8bc5-9e3636e430f3",
            "firstName": "Shane",
            "lastName": "Wilkin",
            "fullName": "Shane Wilkin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/shane-wilkin/headshots/thumbnails/medium/shane-wilkin-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-OH-17-upper-ocd-person-bb1ae016-cdfe-48aa-8bc5-9e3636e430f3",
              "builtID": "oh-upper-17",
              "externalID": "ocd-person/bb1ae016-cdfe-48aa-8bc5-9e3636e430f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd17@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/shane-wilkin",
                "https://ohiohouse.gov/shane-wilkin",
                "https://ohiosenate.gov/members/shane-wilkin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-17-upper-ocd-person-bb1ae016-cdfe-48aa-8bc5-9e3636e430f3": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/b27fdceb-e9c1-46b8-9fc2-323c3f6f784c",
            "firstName": "Jerry C.",
            "lastName": "Cirino",
            "fullName": "Jerry Cirino",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/jerry-c-cirino/headshots/thumbnails/medium/jerry-c-cirino-march-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-OH-18-upper-ocd-person-b27fdceb-e9c1-46b8-9fc2-323c3f6f784c",
              "builtID": "oh-upper-18",
              "externalID": "ocd-person/b27fdceb-e9c1-46b8-9fc2-323c3f6f784c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd18@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/cirino",
                "https://ohiosenate.gov/members/jerry-c-cirino"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-18-upper-ocd-person-b27fdceb-e9c1-46b8-9fc2-323c3f6f784c": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/c61c8eaf-1de9-419d-a5dc-c96787315578",
            "firstName": "Andrew O.",
            "lastName": "Brenner",
            "fullName": "Andrew Brenner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/andrew-o-brenner/headshots/thumbnails/medium/andrew-o-brenner-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-OH-19-upper-ocd-person-c61c8eaf-1de9-419d-a5dc-c96787315578",
              "builtID": "oh-upper-19",
              "externalID": "ocd-person/c61c8eaf-1de9-419d-a5dc-c96787315578",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd19@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/brenner",
                "https://www.ohiosenate.gov/members/andrew-o-brenner"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-19-upper-ocd-person-c61c8eaf-1de9-419d-a5dc-c96787315578": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/01a80305-a667-4dda-9676-1e678bddde12",
            "firstName": "Timothy O.",
            "lastName": "Schaffer",
            "fullName": "Tim Schaffer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/tim-schaffer/headshots/thumbnails/medium/tim-schaffer-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-OH-20-upper-ocd-person-01a80305-a667-4dda-9676-1e678bddde12",
              "builtID": "oh-upper-20",
              "externalID": "ocd-person/01a80305-a667-4dda-9676-1e678bddde12",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd20@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/schaffer",
                "https://www.ohiosenate.gov/members/tim-schaffer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-20-upper-ocd-person-01a80305-a667-4dda-9676-1e678bddde12": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/d9c3dfce-8b7f-402f-9dec-5179e38b528f",
            "firstName": "Kent",
            "lastName": "Smith",
            "fullName": "Kent Smith",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/kent-smith/headshots/thumbnails/medium/kent-smith-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-OH-21-upper-ocd-person-d9c3dfce-8b7f-402f-9dec-5179e38b528f",
              "builtID": "oh-upper-21",
              "externalID": "ocd-person/d9c3dfce-8b7f-402f-9dec-5179e38b528f",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd21@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/kent-smith",
                "https://ohiohouse.gov/kent-smith",
                "https://ohiosenate.gov/members/kent-smith"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-21-upper-ocd-person-d9c3dfce-8b7f-402f-9dec-5179e38b528f": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/2ae62dc8-e902-4a8e-99b7-182477c50bc3",
            "firstName": "Mark James",
            "lastName": "Romanchuk",
            "fullName": "Mark Romanchuk",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/mark-romanchuk/headshots/thumbnails/medium/mark-romanchuk-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-OH-22-upper-ocd-person-2ae62dc8-e902-4a8e-99b7-182477c50bc3",
              "builtID": "oh-upper-22",
              "externalID": "ocd-person/2ae62dc8-e902-4a8e-99b7-182477c50bc3",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd22@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/romanchuk",
                "https://www.ohiosenate.gov/members/mark-romanchuk"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-22-upper-ocd-person-2ae62dc8-e902-4a8e-99b7-182477c50bc3": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/df5c391c-65e8-4d61-a9b9-0249ff6eddb7",
            "firstName": "Nickie J.",
            "lastName": "Antonio",
            "fullName": "Nickie Antonio",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/nickie-j-antonio/headshots/thumbnails/medium/nickie-j-antonio-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-OH-23-upper-ocd-person-df5c391c-65e8-4d61-a9b9-0249ff6eddb7",
              "builtID": "oh-upper-23",
              "externalID": "ocd-person/df5c391c-65e8-4d61-a9b9-0249ff6eddb7",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd23@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/antonio",
                "https://www.ohiosenate.gov/members/nickie-j-antonio"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-23-upper-ocd-person-df5c391c-65e8-4d61-a9b9-0249ff6eddb7": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/ecbfd63c-140e-458f-a2ea-d8224e227280",
            "firstName": "Matthew J.",
            "lastName": "Dolan",
            "fullName": "Matt Dolan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/matt-dolan/headshots/thumbnails/medium/matt-dolan-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-OH-24-upper-ocd-person-ecbfd63c-140e-458f-a2ea-d8224e227280",
              "builtID": "oh-upper-24",
              "externalID": "ocd-person/ecbfd63c-140e-458f-a2ea-d8224e227280",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd24@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "electmattdolan",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/dolan",
                "https://www.ohiosenate.gov/members/matt-dolan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-24-upper-ocd-person-ecbfd63c-140e-458f-a2ea-d8224e227280": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/bdc3493a-a22f-4a6b-b50e-b590216d8cfd",
            "firstName": "William P.",
            "lastName": "DeMora",
            "fullName": "Bill DeMora",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/william-p-demora/headshots/thumbnails/medium/william-p-demora-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-OH-25-upper-ocd-person-bdc3493a-a22f-4a6b-b50e-b590216d8cfd",
              "builtID": "oh-upper-25",
              "externalID": "ocd-person/bdc3493a-a22f-4a6b-b50e-b590216d8cfd",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd25@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "senbilldemora",
              "youtube": null,
              "instagram": "senbilldemora",
              "facebook": null,
              "urls": ["https://www.ohiosenate.gov/members/william-p-demora"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-25-upper-ocd-person-bdc3493a-a22f-4a6b-b50e-b590216d8cfd": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/10733ff7-ee19-4e86-9b5e-65619ddbf7bf",
            "firstName": "William F.",
            "lastName": "Reineke",
            "fullName": "Bill Reineke",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/bill-reineke/headshots/thumbnails/medium/bill-reineke-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-OH-26-upper-ocd-person-10733ff7-ee19-4e86-9b5e-65619ddbf7bf",
              "builtID": "oh-upper-26",
              "externalID": "ocd-person/10733ff7-ee19-4e86-9b5e-65619ddbf7bf",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd26@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiohouse.gov/bill-reineke",
                "https://www.ohiosenate.gov/senators/reineke",
                "https://www.ohiosenate.gov/members/bill-reineke"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-26-upper-ocd-person-10733ff7-ee19-4e86-9b5e-65619ddbf7bf": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/936e5d50-e145-494b-a397-f56570530816",
            "firstName": "Kristina Daley",
            "lastName": "Roegner",
            "fullName": "Kristina Roegner",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/kristina-d-roegner/headshots/thumbnails/medium/kristina-d-roegner-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-OH-27-upper-ocd-person-936e5d50-e145-494b-a397-f56570530816",
              "builtID": "oh-upper-27",
              "externalID": "ocd-person/936e5d50-e145-494b-a397-f56570530816",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd27@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/roegner",
                "https://www.ohiosenate.gov/members/kristina-d-roegner"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-27-upper-ocd-person-936e5d50-e145-494b-a397-f56570530816": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/c91ca7db-c471-4d6d-8e6d-e78a6f238e8f",
            "firstName": "Vernon",
            "lastName": "Sykes",
            "fullName": "Vernon Sykes",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legislature.ohio.gov/assets/people/vernon-sykes/headshots/thumbnails/medium/vernon-sykes-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-OH-28-upper-ocd-person-c91ca7db-c471-4d6d-8e6d-e78a6f238e8f",
              "builtID": "oh-upper-28",
              "externalID": "ocd-person/c91ca7db-c471-4d6d-8e6d-e78a6f238e8f",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd28@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "drvernonsykes",
              "youtube": null,
              "instagram": null,
              "facebook": "senatorvernonsykes",
              "urls": [
                "https://www.ohiosenate.gov/senators/sykes",
                "https://www.ohiosenate.gov/members/vernon-sykes"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-28-upper-ocd-person-c91ca7db-c471-4d6d-8e6d-e78a6f238e8f": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/953a1eac-8d01-4d46-93e6-ce6f71561897",
            "firstName": "Kirk",
            "lastName": "Schuring",
            "fullName": "Kirk Schuring",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/kirk-schuring/headshots/thumbnails/medium/kirk-schuring-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-OH-29-upper-ocd-person-953a1eac-8d01-4d46-93e6-ce6f71561897",
              "builtID": "oh-upper-29",
              "externalID": "ocd-person/953a1eac-8d01-4d46-93e6-ce6f71561897",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd29@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/schuring",
                "https://www.ohiosenate.gov/members/kirk-schuring"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-29-upper-ocd-person-953a1eac-8d01-4d46-93e6-ce6f71561897": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/eb5ff860-2126-4ac5-8267-9d2d924c3967",
            "firstName": "Brian M.",
            "lastName": "Chavez",
            "fullName": "Brian Chavez",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/brian-m-chavez/headshots/thumbnails/medium/brian-m-chavez-december-2023-headshot-10001385_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-OH-30-upper-ocd-person-eb5ff860-2126-4ac5-8267-9d2d924c3967",
              "builtID": "oh-upper-30",
              "externalID": "ocd-person/eb5ff860-2126-4ac5-8267-9d2d924c3967",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd30@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.ohiosenate.gov/members/brian-m-chavez"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-30-upper-ocd-person-eb5ff860-2126-4ac5-8267-9d2d924c3967": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/debec71d-fcfb-4422-9c9e-c6a8709cbb3a",
            "firstName": "Al",
            "lastName": "Landis",
            "fullName": "Al Landis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/al-landis/headshots/thumbnails/medium/al-landis-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-OH-31-upper-ocd-person-debec71d-fcfb-4422-9c9e-c6a8709cbb3a",
              "builtID": "oh-upper-31",
              "externalID": "ocd-person/debec71d-fcfb-4422-9c9e-c6a8709cbb3a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd31@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ohiosenate.gov/members/al-landis"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-31-upper-ocd-person-debec71d-fcfb-4422-9c9e-c6a8709cbb3a": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/d665e333-f7e3-43e2-b440-17920f62c60b",
            "firstName": "Sandra",
            "lastName": "O'Brien",
            "fullName": "Sandy O'Brien",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/sandra-obrien/headshots/thumbnails/medium/sandra-obrien-may-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-OH-32-upper-ocd-person-d665e333-f7e3-43e2-b440-17920f62c60b",
              "builtID": "oh-upper-32",
              "externalID": "ocd-person/d665e333-f7e3-43e2-b440-17920f62c60b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd32@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.ohiosenate.gov/senators/obrien",
                "https://www.ohiosenate.gov/members/sandra-obrien"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-32-upper-ocd-person-d665e333-f7e3-43e2-b440-17920f62c60b": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/6a1fc3b1-ebfd-47b7-adec-ddcd84d137c9",
            "firstName": "Alessandro Benjamin",
            "lastName": "Cutrona",
            "fullName": "Al Cutrona",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legislature.ohio.gov/assets/people/al-cutrona/headshots/thumbnails/medium/al-cutrona-february-2023-headshot_medium.jpg",
            "title": "OH Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OH",
              "stateFull": "Ohio",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-OH-33-upper-ocd-person-6a1fc3b1-ebfd-47b7-adec-ddcd84d137c9",
              "builtID": "oh-upper-33",
              "externalID": "ocd-person/6a1fc3b1-ebfd-47b7-adec-ddcd84d137c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "sd33@ohiosenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ohiohouse.gov/al-cutrona",
                "https://www.ohiohouse.gov/members/al-cutrona",
                "https://www.ohiosenate.gov/members/al-cutrona"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OH-33-upper-ocd-person-6a1fc3b1-ebfd-47b7-adec-ddcd84d137c9": 0
        }
      }
    }
  },
  "OK": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "OK",
          "stateFull": "Oklahoma",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "OK",
            "stateFull": "Oklahoma",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-OK---",
            "builtID": "ok--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-OK---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "L000575",
          "in_office": true,
          "firstName": "James",
          "lastName": "Lankford",
          "middleName": null,
          "fullName": "James Lankford",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/L000575.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "OK",
            "stateFull": "Oklahoma",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-OK---L000575",
            "builtID": "ok--",
            "externalID": "L000575",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorLankford",
            "youtube": "SenatorLankford",
            "instagram": null,
            "facebook": "SenatorLankford",
            "urls": ["https://www.lankford.senate.gov"],
            "rss_url": null
          },
          "title": "OK Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/L000575.json",
          "govtrack_id": "412464",
          "cspan_id": "1033847",
          "votesmart_id": "124938",
          "icpsr_id": "21166",
          "crp_id": "N00031129",
          "google_entity_id": "/m/0dgrrx6",
          "state_rank": "senior",
          "lis_id": "S378",
          "suffix": null,
          "date_of_birth": "1968-03-04",
          "leadership_role": null,
          "fec_candidate_id": "H0OK05114",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "9",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 5,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 0.9,
          "votes_with_party_pct": 95.12,
          "votes_against_party_pct": 4.88,
          "ocd_id": "ocd-division/country:us/state:ok"
        },
        {
          "API_ID": "M001190",
          "in_office": true,
          "firstName": "Markwayne",
          "lastName": "Mullin",
          "middleName": null,
          "fullName": "Markwayne Mullin",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/M001190.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "OK",
            "stateFull": "Oklahoma",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-OK---M001190",
            "builtID": "ok--",
            "externalID": "M001190",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "RepMullin",
            "youtube": null,
            "instagram": null,
            "facebook": "RepMullin",
            "urls": [],
            "rss_url": "https://mullin.house.gov/news/rss.aspx"
          },
          "title": "OK Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/M001190.json",
          "govtrack_id": "412568",
          "cspan_id": "1034045",
          "votesmart_id": "135898",
          "icpsr_id": "21355",
          "crp_id": "N00033410",
          "google_entity_id": "/m/0lq78pn",
          "state_rank": "junior",
          "lis_id": "S419",
          "suffix": null,
          "date_of_birth": "1977-07-26",
          "leadership_role": null,
          "fec_candidate_id": "H2OK02083",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 34,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 6.14,
          "votes_with_party_pct": 94.49,
          "votes_against_party_pct": 5.51,
          "ocd_id": "ocd-division/country:us/state:ok"
        }
      ],
      "hash": { "SENATE-OK---L000575": 0, "SENATE-OK---M001190": 1 }
    },
    "HOUSE": {
      "05": {
        "members": [
          {
            "API_ID": "B000740",
            "in_office": true,
            "firstName": "Stephanie",
            "lastName": "Bice",
            "middleName": null,
            "fullName": "Stephanie I. Bice",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B000740.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-OK-05--B000740",
              "builtID": "ok--05",
              "externalID": "B000740",
              "geometry": null,
              "geoid": "4005"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2437 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2132",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBice",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://bice.house.gov"],
              "rss_url": null
            },
            "title": "OK House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B000740.json",
            "govtrack_id": "456841",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00044579",
            "google_entity_id": "/g/11h2hmkrv8",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1973-11-11",
            "leadership_role": null,
            "fec_candidate_id": "H0OK05205",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 3,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.28,
            "votes_with_party_pct": 91.09,
            "votes_against_party_pct": 6.98,
            "ocd_id": "ocd-division/country:us/state:ok/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OK-05--B000740": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "B001317",
            "in_office": true,
            "firstName": "Josh",
            "lastName": "Brecheen",
            "middleName": null,
            "fullName": "Josh Brecheen",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001317.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-OK-02--B001317",
              "builtID": "ok--02",
              "externalID": "B001317",
              "geometry": null,
              "geoid": "4002"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1208 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2701",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://brecheen.house.gov/"],
              "rss_url": null
            },
            "title": "OK House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001317.json",
            "govtrack_id": "456931",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1979-06-19",
            "leadership_role": null,
            "fec_candidate_id": "H2OK02315",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 9,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 82.75,
            "votes_against_party_pct": 15.3,
            "ocd_id": "ocd-division/country:us/state:ok/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OK-02--B001317": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "C001053",
            "in_office": true,
            "firstName": "Tom",
            "lastName": "Cole",
            "middleName": null,
            "fullName": "Tom Cole",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001053.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-OK-04--C001053",
              "builtID": "ok--04",
              "externalID": "C001053",
              "geometry": null,
              "geoid": "4004"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2207 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6165",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "TomColeOK04",
              "youtube": "reptomcole",
              "instagram": null,
              "facebook": "TomColeOK04",
              "urls": ["https://cole.house.gov"],
              "rss_url": "https://cole.house.gov/rss.xml"
            },
            "title": "OK House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001053.json",
            "govtrack_id": "400077",
            "cspan_id": "1003609",
            "votesmart_id": "46034",
            "icpsr_id": "20344",
            "crp_id": "N00025726",
            "google_entity_id": "/m/03tqb0",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1949-04-28",
            "leadership_role": null,
            "fec_candidate_id": "H2OK04055",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "22",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 31,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 2.94,
            "votes_with_party_pct": 85.14,
            "votes_against_party_pct": 12.96,
            "ocd_id": "ocd-division/country:us/state:ok/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OK-04--C001053": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "H001082",
            "in_office": true,
            "firstName": "Kevin",
            "lastName": "Hern",
            "middleName": null,
            "fullName": "Kevin Hern",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/H001082.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-OK-01--H001082",
              "builtID": "ok--01",
              "externalID": "H001082",
              "geometry": null,
              "geoid": "4001"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1019 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2211",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repkevinhern",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://hern.house.gov"],
              "rss_url": null
            },
            "title": "OK House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001082.json",
            "govtrack_id": "412748",
            "cspan_id": null,
            "votesmart_id": "180004",
            "icpsr_id": null,
            "crp_id": "N00040829",
            "google_entity_id": "/g/11gzqw48lf",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1961-12-04",
            "leadership_role": null,
            "fec_candidate_id": "H8OK01157",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 4,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.38,
            "votes_with_party_pct": 91.08,
            "votes_against_party_pct": 6.98,
            "ocd_id": "ocd-division/country:us/state:ok/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OK-01--H001082": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "L000491",
            "in_office": true,
            "firstName": "Frank",
            "lastName": "Lucas",
            "middleName": "D.",
            "fullName": "Frank D. Lucas",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/L000491.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-OK-03--L000491",
              "builtID": "ok--03",
              "externalID": "L000491",
              "geometry": null,
              "geoid": "4003"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2405 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5565",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepFrankLucas",
              "youtube": "RepFrankLucas",
              "instagram": null,
              "facebook": "RepFrankLucas",
              "urls": ["https://lucas.house.gov"],
              "rss_url": "https://lucas.house.gov/rss.xml"
            },
            "title": "OK House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000491.json",
            "govtrack_id": "400247",
            "cspan_id": "35692",
            "votesmart_id": "27032",
            "icpsr_id": "29393",
            "crp_id": "N00005559",
            "google_entity_id": "/m/033tnk",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1960-01-06",
            "leadership_role": null,
            "fec_candidate_id": "H4OK06056",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "32",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 55,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 5.21,
            "votes_with_party_pct": 86.02,
            "votes_against_party_pct": 12.04,
            "ocd_id": "ocd-division/country:us/state:ok/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OK-03--L000491": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/d93c695c-f9d6-4f97-b92e-73249f89545a",
            "firstName": "James E.",
            "lastName": "Dempsey",
            "fullName": "Eddy Dempsey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Dempsey,%20Eddy.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-OK-1-lower-ocd-person-d93c695c-f9d6-4f97-b92e-73249f89545a",
              "builtID": "ok-lower-1",
              "externalID": "ocd-person/d93c695c-f9d6-4f97-b92e-73249f89545a",
              "geometry": null
            },
            "contactInfo": {
              "email": "eddy.dempsey@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=1",
                "https://www.okhouse.gov/representatives/eddy-dempsey"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-1-lower-ocd-person-d93c695c-f9d6-4f97-b92e-73249f89545a": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/f7a4c019-6e5c-40d8-8faf-9f7d44b83062",
            "firstName": "Jim",
            "lastName": "Olsen",
            "fullName": "Jim Olsen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Olsen,%20Jim.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-OK-2-lower-ocd-person-f7a4c019-6e5c-40d8-8faf-9f7d44b83062",
              "builtID": "ok-lower-2",
              "externalID": "ocd-person/f7a4c019-6e5c-40d8-8faf-9f7d44b83062",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.olsen@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=2",
                "https://www.okhouse.gov/Members/Contact.aspx?District=2",
                "https://www.okhouse.gov/representatives/jim-olsen"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-2-lower-ocd-person-f7a4c019-6e5c-40d8-8faf-9f7d44b83062": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/bae91554-72c9-4dbd-b042-b4bd800e4732",
            "firstName": "Rick",
            "lastName": "West",
            "fullName": "Rick West",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/West,%20Rick.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-OK-3-lower-ocd-person-bae91554-72c9-4dbd-b042-b4bd800e4732",
              "builtID": "ok-lower-3",
              "externalID": "ocd-person/bae91554-72c9-4dbd-b042-b4bd800e4732",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.west@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=3",
                "https://www.okhouse.gov/Members/Contact.aspx?District=3",
                "https://www.okhouse.gov/representatives/rick-west"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-3-lower-ocd-person-bae91554-72c9-4dbd-b042-b4bd800e4732": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/246cfe54-edbd-4844-9bdc-0213c4b501e9",
            "firstName": "Bob Ed",
            "lastName": "Culver",
            "fullName": "Bob Culver",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Culver,%20BobEd.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-OK-4-lower-ocd-person-246cfe54-edbd-4844-9bdc-0213c4b501e9",
              "builtID": "ok-lower-4",
              "externalID": "ocd-person/246cfe54-edbd-4844-9bdc-0213c4b501e9",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.culver@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=4",
                "https://www.okhouse.gov/representatives/bob-culver"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-4-lower-ocd-person-246cfe54-edbd-4844-9bdc-0213c4b501e9": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/6b71e61a-6f78-49b0-90ef-64b233dab860",
            "firstName": "Josh",
            "lastName": "West",
            "fullName": "Josh West",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/West,%20Josh.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-OK-5-lower-ocd-person-6b71e61a-6f78-49b0-90ef-64b233dab860",
              "builtID": "ok-lower-5",
              "externalID": "ocd-person/6b71e61a-6f78-49b0-90ef-64b233dab860",
              "geometry": null
            },
            "contactInfo": {
              "email": "josh.west@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=5",
                "https://www.okhouse.gov/Members/Contact.aspx?District=5",
                "https://www.okhouse.gov/representatives/josh-west"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-5-lower-ocd-person-6b71e61a-6f78-49b0-90ef-64b233dab860": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/818eac04-d5b7-49be-b740-84a8432cf5b3",
            "firstName": "Rusty",
            "lastName": "Cornwell",
            "fullName": "Rusty Cornwell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Cornwell,%20Rusty.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-OK-6-lower-ocd-person-818eac04-d5b7-49be-b740-84a8432cf5b3",
              "builtID": "ok-lower-6",
              "externalID": "ocd-person/818eac04-d5b7-49be-b740-84a8432cf5b3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rusty.cornwell@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=6",
                "https://www.okhouse.gov/Members/Contact.aspx?District=6",
                "https://www.okhouse.gov/representatives/rusty-cornwell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-6-lower-ocd-person-818eac04-d5b7-49be-b740-84a8432cf5b3": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/bf810dce-34f8-4db5-b242-efc008311228",
            "firstName": "Stephen",
            "lastName": "Bashore",
            "fullName": "Steve Bashore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Bashore,%20Steve.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-OK-7-lower-ocd-person-bf810dce-34f8-4db5-b242-efc008311228",
              "builtID": "ok-lower-7",
              "externalID": "ocd-person/bf810dce-34f8-4db5-b242-efc008311228",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.bashore@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=7",
                "https://www.okhouse.gov/representatives/steve-bashore"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-7-lower-ocd-person-bf810dce-34f8-4db5-b242-efc008311228": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/fc634688-e6d0-4b9b-919c-0c1f001d51a7",
            "firstName": "Tom",
            "lastName": "Gann",
            "fullName": "Tom Gann",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Gann,%20Tom.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-OK-8-lower-ocd-person-fc634688-e6d0-4b9b-919c-0c1f001d51a7",
              "builtID": "ok-lower-8",
              "externalID": "ocd-person/fc634688-e6d0-4b9b-919c-0c1f001d51a7",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.gann@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=8",
                "https://www.okhouse.gov/Members/Contact.aspx?District=8",
                "https://www.okhouse.gov/representatives/tom-gann"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-8-lower-ocd-person-fc634688-e6d0-4b9b-919c-0c1f001d51a7": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/a1d5d401-d767-4088-b9b6-e03af9d5ed49",
            "firstName": "Mark",
            "lastName": "Lepak",
            "fullName": "Mark Lepak",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Lepak,%20Mark.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-OK-9-lower-ocd-person-a1d5d401-d767-4088-b9b6-e03af9d5ed49",
              "builtID": "ok-lower-9",
              "externalID": "ocd-person/a1d5d401-d767-4088-b9b6-e03af9d5ed49",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.lepak@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=9",
                "https://www.okhouse.gov/Members/Contact.aspx?District=9",
                "https://www.okhouse.gov/representatives/mark-lepak"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-9-lower-ocd-person-a1d5d401-d767-4088-b9b6-e03af9d5ed49": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/47e6f0cc-bf0d-4375-b0f7-3e0c88779ca5",
            "firstName": "Judd",
            "lastName": "Strom",
            "fullName": "Judd Strom",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Strom,%20Judd.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-OK-10-lower-ocd-person-47e6f0cc-bf0d-4375-b0f7-3e0c88779ca5",
              "builtID": "ok-lower-10",
              "externalID": "ocd-person/47e6f0cc-bf0d-4375-b0f7-3e0c88779ca5",
              "geometry": null
            },
            "contactInfo": {
              "email": "judd.strom@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=10",
                "https://www.okhouse.gov/Members/Contact.aspx?District=10",
                "https://www.okhouse.gov/representatives/judd-strom"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-10-lower-ocd-person-47e6f0cc-bf0d-4375-b0f7-3e0c88779ca5": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/f7ab3ee8-1ec5-4218-ac6b-dba652f2a6d6",
            "firstName": "John B.",
            "lastName": "Kane",
            "fullName": "John Kane",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Kane,%20John.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-OK-11-lower-ocd-person-f7ab3ee8-1ec5-4218-ac6b-dba652f2a6d6",
              "builtID": "ok-lower-11",
              "externalID": "ocd-person/f7ab3ee8-1ec5-4218-ac6b-dba652f2a6d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.kane@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=11",
                "https://www.okhouse.gov/representatives/john-kane"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-11-lower-ocd-person-f7ab3ee8-1ec5-4218-ac6b-dba652f2a6d6": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/887d29f4-0c91-4bdf-89ce-57a5de014ff6",
            "firstName": "Kevin W.",
            "lastName": "McDugle",
            "fullName": "Kevin McDugle",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/McDugle,%20Kevin.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-OK-12-lower-ocd-person-887d29f4-0c91-4bdf-89ce-57a5de014ff6",
              "builtID": "ok-lower-12",
              "externalID": "ocd-person/887d29f4-0c91-4bdf-89ce-57a5de014ff6",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.mcdugle@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=12",
                "https://www.okhouse.gov/Members/Contact.aspx?District=12",
                "https://www.okhouse.gov/representatives/kevin-mcdugle"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-12-lower-ocd-person-887d29f4-0c91-4bdf-89ce-57a5de014ff6": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/3eb936f1-5bc8-40bb-832e-c617d461115c",
            "firstName": "William Neil",
            "lastName": "Hays",
            "fullName": "Neil Hays",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Hays,%20Neil.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-OK-13-lower-ocd-person-3eb936f1-5bc8-40bb-832e-c617d461115c",
              "builtID": "ok-lower-13",
              "externalID": "ocd-person/3eb936f1-5bc8-40bb-832e-c617d461115c",
              "geometry": null
            },
            "contactInfo": {
              "email": "neil.hays@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=13",
                "https://www.okhouse.gov/representatives/neil-hays"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-13-lower-ocd-person-3eb936f1-5bc8-40bb-832e-c617d461115c": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/f4d5b128-a016-4eda-b6c6-e298fe29bc29",
            "firstName": "Chris",
            "lastName": "Sneed",
            "fullName": "Chris Sneed",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Sneed,%20Chris.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-OK-14-lower-ocd-person-f4d5b128-a016-4eda-b6c6-e298fe29bc29",
              "builtID": "ok-lower-14",
              "externalID": "ocd-person/f4d5b128-a016-4eda-b6c6-e298fe29bc29",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.sneed@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=14",
                "https://www.okhouse.gov/Members/Contact.aspx?District=14",
                "https://www.okhouse.gov/representatives/chris-sneed"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-14-lower-ocd-person-f4d5b128-a016-4eda-b6c6-e298fe29bc29": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/3625e2bf-2150-4ec6-b58c-2a90522d0bd6",
            "firstName": "Randy",
            "lastName": "Randleman",
            "fullName": "Randy Randleman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Randy-Randleman.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-OK-15-lower-ocd-person-3625e2bf-2150-4ec6-b58c-2a90522d0bd6",
              "builtID": "ok-lower-15",
              "externalID": "ocd-person/3625e2bf-2150-4ec6-b58c-2a90522d0bd6",
              "geometry": null
            },
            "contactInfo": {
              "email": "randy.randleman@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=15",
                "https://www.okhouse.gov/Members/Contact.aspx?District=15",
                "https://www.okhouse.gov/representatives/randy-randleman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-15-lower-ocd-person-3625e2bf-2150-4ec6-b58c-2a90522d0bd6": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/a2be8726-7870-43b3-bbac-381824466f9c",
            "firstName": "Scott",
            "lastName": "Fetgatter",
            "fullName": "Scott Fetgatter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Fetgatter,%20Scott.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-OK-16-lower-ocd-person-a2be8726-7870-43b3-bbac-381824466f9c",
              "builtID": "ok-lower-16",
              "externalID": "ocd-person/a2be8726-7870-43b3-bbac-381824466f9c",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.fetgatter@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=16",
                "https://www.okhouse.gov/Members/Contact.aspx?District=16",
                "https://www.okhouse.gov/representatives/scott-fetgatter"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-16-lower-ocd-person-a2be8726-7870-43b3-bbac-381824466f9c": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/612db560-defe-471a-a45f-cf259a4ffeaa",
            "firstName": "Jim",
            "lastName": "Grego",
            "fullName": "Jim Grego",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Grego,%20Jim.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-OK-17-lower-ocd-person-612db560-defe-471a-a45f-cf259a4ffeaa",
              "builtID": "ok-lower-17",
              "externalID": "ocd-person/612db560-defe-471a-a45f-cf259a4ffeaa",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.grego@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=17",
                "https://www.okhouse.gov/Members/Contact.aspx?District=17",
                "https://www.okhouse.gov/representatives/jim-grego"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-17-lower-ocd-person-612db560-defe-471a-a45f-cf259a4ffeaa": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/84087e63-a192-4be6-9367-64b2c93a2dcc",
            "firstName": "David",
            "lastName": "Smith",
            "fullName": "David Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Smith,%20David.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-OK-18-lower-ocd-person-84087e63-a192-4be6-9367-64b2c93a2dcc",
              "builtID": "ok-lower-18",
              "externalID": "ocd-person/84087e63-a192-4be6-9367-64b2c93a2dcc",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.smith@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=18",
                "https://www.okhouse.gov/Members/Contact.aspx?District=18",
                "https://www.okhouse.gov/representatives/david-smith"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-18-lower-ocd-person-84087e63-a192-4be6-9367-64b2c93a2dcc": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/0b9df925-a78c-4ee4-a58c-5d7c2ace10fd",
            "firstName": "Justin",
            "lastName": "Humphrey",
            "fullName": "Justin Humphrey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Humphrey,%20Justin.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-OK-19-lower-ocd-person-0b9df925-a78c-4ee4-a58c-5d7c2ace10fd",
              "builtID": "ok-lower-19",
              "externalID": "ocd-person/0b9df925-a78c-4ee4-a58c-5d7c2ace10fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "justin.humphrey@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=19",
                "https://www.okhouse.gov/Members/Contact.aspx?District=19",
                "https://www.okhouse.gov/representatives/justin-humphrey"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-19-lower-ocd-person-0b9df925-a78c-4ee4-a58c-5d7c2ace10fd": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/0ce16141-28f9-424f-9747-ca2cb23bf53c",
            "firstName": "Sherrie",
            "lastName": "Conley",
            "fullName": "Sherrie Conley",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Conley,%20Sherrie.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-OK-20-lower-ocd-person-0ce16141-28f9-424f-9747-ca2cb23bf53c",
              "builtID": "ok-lower-20",
              "externalID": "ocd-person/0ce16141-28f9-424f-9747-ca2cb23bf53c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sherrie.conley@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=20",
                "https://www.okhouse.gov/Members/Contact.aspx?District=20",
                "https://www.okhouse.gov/representatives/sherrie-conley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-20-lower-ocd-person-0ce16141-28f9-424f-9747-ca2cb23bf53c": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/17d5c3a4-a468-4aca-b58c-314605214857",
            "firstName": "Cody",
            "lastName": "Maynard",
            "fullName": "Cody Maynard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Maynard,%20Cody.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-OK-21-lower-ocd-person-17d5c3a4-a468-4aca-b58c-314605214857",
              "builtID": "ok-lower-21",
              "externalID": "ocd-person/17d5c3a4-a468-4aca-b58c-314605214857",
              "geometry": null
            },
            "contactInfo": {
              "email": "cody.maynard@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=21",
                "https://www.okhouse.gov/representatives/cody-maynard"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-21-lower-ocd-person-17d5c3a4-a468-4aca-b58c-314605214857": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/b28a6474-e6ec-4d2c-be07-b39ca4900b5c",
            "firstName": "Charles Adelbert",
            "lastName": "McCall",
            "fullName": "Charles McCall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/McCall,%20Charles.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-OK-22-lower-ocd-person-b28a6474-e6ec-4d2c-be07-b39ca4900b5c",
              "builtID": "ok-lower-22",
              "externalID": "ocd-person/b28a6474-e6ec-4d2c-be07-b39ca4900b5c",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.mccall@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=22",
                "https://www.okhouse.gov/Members/Contact.aspx?District=22",
                "https://www.okhouse.gov/representatives/charles-mccall"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-22-lower-ocd-person-b28a6474-e6ec-4d2c-be07-b39ca4900b5c": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/5e39c9cf-fb99-4f15-9e19-faf114214f29",
            "firstName": "Terry",
            "lastName": "O'Donnell",
            "fullName": "Terry O'Donnell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/O'Donnell,%20Terry.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-OK-23-lower-ocd-person-5e39c9cf-fb99-4f15-9e19-faf114214f29",
              "builtID": "ok-lower-23",
              "externalID": "ocd-person/5e39c9cf-fb99-4f15-9e19-faf114214f29",
              "geometry": null
            },
            "contactInfo": {
              "email": "terry.odonnell@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=23",
                "https://www.okhouse.gov/Members/Contact.aspx?District=23",
                "https://www.okhouse.gov/representatives/terry-odonnell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-23-lower-ocd-person-5e39c9cf-fb99-4f15-9e19-faf114214f29": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/442374ea-1ad4-4793-bdbc-a5db81a1d219",
            "firstName": "Chris Lee",
            "lastName": "Banning",
            "fullName": "Chris Banning",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Banning,%20Chris.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-OK-24-lower-ocd-person-442374ea-1ad4-4793-bdbc-a5db81a1d219",
              "builtID": "ok-lower-24",
              "externalID": "ocd-person/442374ea-1ad4-4793-bdbc-a5db81a1d219",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.banning@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=24",
                "https://www.okhouse.gov/representatives/59th-chris-banning",
                "https://www.okhouse.gov/representatives/chris-banning"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-24-lower-ocd-person-442374ea-1ad4-4793-bdbc-a5db81a1d219": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/e5d78f97-752b-4bff-a413-4704ed7c230a",
            "firstName": "Ronny",
            "lastName": "Johns",
            "fullName": "Ronny Johns",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Johns,%20Ronny.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-OK-25-lower-ocd-person-e5d78f97-752b-4bff-a413-4704ed7c230a",
              "builtID": "ok-lower-25",
              "externalID": "ocd-person/e5d78f97-752b-4bff-a413-4704ed7c230a",
              "geometry": null
            },
            "contactInfo": {
              "email": "ronny.johns@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=25",
                "https://www.okhouse.gov/Members/Contact.aspx?District=25",
                "https://www.okhouse.gov/representatives/ronny-johns"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-25-lower-ocd-person-e5d78f97-752b-4bff-a413-4704ed7c230a": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/4d473c48-88f9-495a-a8da-b8e2d0310280",
            "firstName": "Dell",
            "lastName": "Kerbs",
            "fullName": "Dell Kerbs",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Kerbs,%20Dell.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-OK-26-lower-ocd-person-4d473c48-88f9-495a-a8da-b8e2d0310280",
              "builtID": "ok-lower-26",
              "externalID": "ocd-person/4d473c48-88f9-495a-a8da-b8e2d0310280",
              "geometry": null
            },
            "contactInfo": {
              "email": "dell.kerbs@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=26",
                "https://www.okhouse.gov/Members/Contact.aspx?District=26",
                "https://www.okhouse.gov/representatives/dell-kerbs"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-26-lower-ocd-person-4d473c48-88f9-495a-a8da-b8e2d0310280": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/61966500-f9d7-4f45-ad6b-761d6d839036",
            "firstName": "Danny",
            "lastName": "Sterling",
            "fullName": "Danny Sterling",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Danny-Sterling.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-OK-27-lower-ocd-person-61966500-f9d7-4f45-ad6b-761d6d839036",
              "builtID": "ok-lower-27",
              "externalID": "ocd-person/61966500-f9d7-4f45-ad6b-761d6d839036",
              "geometry": null
            },
            "contactInfo": {
              "email": "danny.sterling@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=27",
                "https://www.okhouse.gov/Members/Contact.aspx?District=27",
                "https://www.okhouse.gov/representatives/danny-sterling"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-27-lower-ocd-person-61966500-f9d7-4f45-ad6b-761d6d839036": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/db2c481d-6dc5-455e-905c-2daac4bbc631",
            "firstName": "Danny",
            "lastName": "Williams",
            "fullName": "Danny Williams",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Williams,%20Danny.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-OK-28-lower-ocd-person-db2c481d-6dc5-455e-905c-2daac4bbc631",
              "builtID": "ok-lower-28",
              "externalID": "ocd-person/db2c481d-6dc5-455e-905c-2daac4bbc631",
              "geometry": null
            },
            "contactInfo": {
              "email": "danny.williams@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=28",
                "https://www.okhouse.gov/representatives/danny-williams"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-28-lower-ocd-person-db2c481d-6dc5-455e-905c-2daac4bbc631": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/b0ab4df0-8cc1-43f6-9ed1-567b75e91468",
            "firstName": "Kyle",
            "lastName": "Hilbert",
            "fullName": "Kyle Hilbert",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Hilbert,%20Kyle.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-OK-29-lower-ocd-person-b0ab4df0-8cc1-43f6-9ed1-567b75e91468",
              "builtID": "ok-lower-29",
              "externalID": "ocd-person/b0ab4df0-8cc1-43f6-9ed1-567b75e91468",
              "geometry": null
            },
            "contactInfo": {
              "email": "kyle.hilbert@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=29",
                "https://www.okhouse.gov/Members/Contact.aspx?District=29",
                "https://www.okhouse.gov/representatives/kyle-hilbert"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-29-lower-ocd-person-b0ab4df0-8cc1-43f6-9ed1-567b75e91468": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/97351530-ef54-4859-acdd-effcb11eaf1f",
            "firstName": "Mark P.",
            "lastName": "Lawson",
            "fullName": "Mark Lawson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Lawson,%20Mark.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-OK-30-lower-ocd-person-97351530-ef54-4859-acdd-effcb11eaf1f",
              "builtID": "ok-lower-30",
              "externalID": "ocd-person/97351530-ef54-4859-acdd-effcb11eaf1f",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.lawson@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=30",
                "https://www.okhouse.gov/Members/Contact.aspx?District=30",
                "https://www.okhouse.gov/representatives/mark-lawson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-30-lower-ocd-person-97351530-ef54-4859-acdd-effcb11eaf1f": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/0cffa048-1b45-4609-a1b6-9e741ca1948c",
            "firstName": "Collin",
            "lastName": "Duel",
            "fullName": "Collin Duel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Duel,%20Collin.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-OK-31-lower-ocd-person-0cffa048-1b45-4609-a1b6-9e741ca1948c",
              "builtID": "ok-lower-31",
              "externalID": "ocd-person/0cffa048-1b45-4609-a1b6-9e741ca1948c",
              "geometry": null
            },
            "contactInfo": {
              "email": "collin.duel@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=31",
                "https://www.okhouse.gov/representatives/collin-duel"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-31-lower-ocd-person-0cffa048-1b45-4609-a1b6-9e741ca1948c": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/dd9558e2-76ad-4955-a4d0-9363a7bcbbec",
            "firstName": "Kevin",
            "lastName": "Wallace",
            "fullName": "Kevin Wallace",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Wallace,%20Kevin.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-OK-32-lower-ocd-person-dd9558e2-76ad-4955-a4d0-9363a7bcbbec",
              "builtID": "ok-lower-32",
              "externalID": "ocd-person/dd9558e2-76ad-4955-a4d0-9363a7bcbbec",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.wallace@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=32",
                "https://www.okhouse.gov/Members/Contact.aspx?District=32",
                "https://www.okhouse.gov/representatives/kevin-wallace"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-32-lower-ocd-person-dd9558e2-76ad-4955-a4d0-9363a7bcbbec": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/97d8456c-f3d3-46c9-82dd-50a5ffc64fe1",
            "firstName": "John",
            "lastName": "Talley",
            "fullName": "John Talley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Talley,%20John.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-OK-33-lower-ocd-person-97d8456c-f3d3-46c9-82dd-50a5ffc64fe1",
              "builtID": "ok-lower-33",
              "externalID": "ocd-person/97d8456c-f3d3-46c9-82dd-50a5ffc64fe1",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.talley@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=33",
                "https://www.okhouse.gov/Members/Contact.aspx?District=33",
                "https://www.okhouse.gov/representatives/john-talley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-33-lower-ocd-person-97d8456c-f3d3-46c9-82dd-50a5ffc64fe1": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/d3a66cdd-0469-4c20-90da-620f60c4b4be",
            "firstName": "Trish",
            "lastName": "Ranson",
            "fullName": "Trish Ranson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Ranson,%20Trish.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-OK-34-lower-ocd-person-d3a66cdd-0469-4c20-90da-620f60c4b4be",
              "builtID": "ok-lower-34",
              "externalID": "ocd-person/d3a66cdd-0469-4c20-90da-620f60c4b4be",
              "geometry": null
            },
            "contactInfo": {
              "email": "trish.ranson@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=34",
                "https://www.okhouse.gov/Members/Contact.aspx?District=34",
                "https://www.okhouse.gov/representatives/trish-ranson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-34-lower-ocd-person-d3a66cdd-0469-4c20-90da-620f60c4b4be": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/e0d87c6f-341b-49bc-b2bc-ae86e6db22e0",
            "firstName": "Ty",
            "lastName": "Burns",
            "fullName": "Ty Burns",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Burns,%20Ty.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-OK-35-lower-ocd-person-e0d87c6f-341b-49bc-b2bc-ae86e6db22e0",
              "builtID": "ok-lower-35",
              "externalID": "ocd-person/e0d87c6f-341b-49bc-b2bc-ae86e6db22e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "ty.burns@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=35",
                "https://www.okhouse.gov/Members/Contact.aspx?District=35",
                "https://www.okhouse.gov/representatives/ty-burns"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-35-lower-ocd-person-e0d87c6f-341b-49bc-b2bc-ae86e6db22e0": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/3af08d09-7d36-4f0b-b257-a0206a8c2778",
            "firstName": "John",
            "lastName": "George",
            "fullName": "John George",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/George,%20John.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-OK-36-lower-ocd-person-3af08d09-7d36-4f0b-b257-a0206a8c2778",
              "builtID": "ok-lower-36",
              "externalID": "ocd-person/3af08d09-7d36-4f0b-b257-a0206a8c2778",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.george@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=36",
                "https://www.okhouse.gov/representatives/john-george"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-36-lower-ocd-person-3af08d09-7d36-4f0b-b257-a0206a8c2778": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/428ba1b3-23b7-433a-8f18-ca53f6eed9aa",
            "firstName": "Ken G.",
            "lastName": "Luttrell",
            "fullName": "Ken Luttrell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Luttrell,%20Ken.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-OK-37-lower-ocd-person-428ba1b3-23b7-433a-8f18-ca53f6eed9aa",
              "builtID": "ok-lower-37",
              "externalID": "ocd-person/428ba1b3-23b7-433a-8f18-ca53f6eed9aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.luttrell@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "10 Ramblewood St., Ponca City, OK 74604",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=37",
                "https://www.okhouse.gov/Members/Contact.aspx?District=37",
                "https://www.okhouse.gov/representatives/ken-luttrell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-37-lower-ocd-person-428ba1b3-23b7-433a-8f18-ca53f6eed9aa": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/671bd947-18c8-4b39-906e-675209b80b73",
            "firstName": "John",
            "lastName": "Pfeiffer",
            "fullName": "John Pfeiffer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Pfeiffer,%20John.JPG",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-OK-38-lower-ocd-person-671bd947-18c8-4b39-906e-675209b80b73",
              "builtID": "ok-lower-38",
              "externalID": "ocd-person/671bd947-18c8-4b39-906e-675209b80b73",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.pfeiffer@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=38",
                "https://www.okhouse.gov/Members/Contact.aspx?District=38",
                "https://www.okhouse.gov/representatives/john-pfeiffer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-38-lower-ocd-person-671bd947-18c8-4b39-906e-675209b80b73": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/57fd0b98-5608-470a-a70b-971165572afb",
            "firstName": "Erick W.",
            "lastName": "Harris",
            "fullName": "Erick Harris",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/ErickHarris.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-OK-39-lower-ocd-person-57fd0b98-5608-470a-a70b-971165572afb",
              "builtID": "ok-lower-39",
              "externalID": "ocd-person/57fd0b98-5608-470a-a70b-971165572afb",
              "geometry": null
            },
            "contactInfo": {
              "email": "erick.harris@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.okhouse.gov/representatives/erick-harris"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-39-lower-ocd-person-57fd0b98-5608-470a-a70b-971165572afb": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/c5909558-d02d-438e-9c0c-50eb8c88f862",
            "firstName": "Chad",
            "lastName": "Caldwell",
            "fullName": "Chad Caldwell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Caldwell,%20Chad.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-OK-40-lower-ocd-person-c5909558-d02d-438e-9c0c-50eb8c88f862",
              "builtID": "ok-lower-40",
              "externalID": "ocd-person/c5909558-d02d-438e-9c0c-50eb8c88f862",
              "geometry": null
            },
            "contactInfo": {
              "email": "chad.caldwell@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=40",
                "https://www.okhouse.gov/Members/Contact.aspx?District=40",
                "https://www.okhouse.gov/representatives/chad-caldwell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-40-lower-ocd-person-c5909558-d02d-438e-9c0c-50eb8c88f862": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/e825fe1a-a46d-4a07-b9e4-c9e82e357c90",
            "firstName": "Denise Lorene",
            "lastName": "Crosswhite Hader",
            "fullName": "Denise Crosswhite Hader",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Crosswhite%20Hader,%20Denise.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-OK-41-lower-ocd-person-e825fe1a-a46d-4a07-b9e4-c9e82e357c90",
              "builtID": "ok-lower-41",
              "externalID": "ocd-person/e825fe1a-a46d-4a07-b9e4-c9e82e357c90",
              "geometry": null
            },
            "contactInfo": {
              "email": "denise.crosswhitehader@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=41",
                "https://www.okhouse.gov/Members/Contact.aspx?District=41",
                "https://www.okhouse.gov/representatives/denise-crosswhitehader"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-41-lower-ocd-person-e825fe1a-a46d-4a07-b9e4-c9e82e357c90": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/382dfd67-bd2a-4271-bb79-62fe045102ea",
            "firstName": "Cynthia",
            "lastName": "Roe",
            "fullName": "Cindy Roe",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Cindy-Roe.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-OK-42-lower-ocd-person-382dfd67-bd2a-4271-bb79-62fe045102ea",
              "builtID": "ok-lower-42",
              "externalID": "ocd-person/382dfd67-bd2a-4271-bb79-62fe045102ea",
              "geometry": null
            },
            "contactInfo": {
              "email": "cynthia.roe@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=42",
                "https://www.okhouse.gov/Members/Contact.aspx?District=42",
                "https://www.okhouse.gov/representatives/cynthia-roe"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-42-lower-ocd-person-382dfd67-bd2a-4271-bb79-62fe045102ea": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/0389a5f1-0777-4a48-8faf-98779dc16849",
            "firstName": "Jay",
            "lastName": "Steagall",
            "fullName": "Jay Steagall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Steagall,%20Jay.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-OK-43-lower-ocd-person-0389a5f1-0777-4a48-8faf-98779dc16849",
              "builtID": "ok-lower-43",
              "externalID": "ocd-person/0389a5f1-0777-4a48-8faf-98779dc16849",
              "geometry": null
            },
            "contactInfo": {
              "email": "jay.steagall@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=43",
                "https://www.okhouse.gov/Members/Contact.aspx?District=43",
                "https://www.okhouse.gov/representatives/jay-steagall"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-43-lower-ocd-person-0389a5f1-0777-4a48-8faf-98779dc16849": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/a4127dd3-e7f6-4bab-9910-8372e141f160",
            "firstName": "Jared Lesley",
            "lastName": "Deck",
            "fullName": "Jared Deck",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Deck,%20Jared.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-OK-44-lower-ocd-person-a4127dd3-e7f6-4bab-9910-8372e141f160",
              "builtID": "ok-lower-44",
              "externalID": "ocd-person/a4127dd3-e7f6-4bab-9910-8372e141f160",
              "geometry": null
            },
            "contactInfo": {
              "email": "jared.deck@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=44",
                "https://www.okhouse.gov/representatives/jared-deck"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-44-lower-ocd-person-a4127dd3-e7f6-4bab-9910-8372e141f160": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/3a5828d9-b8bf-491c-adfe-19f5331d1e20",
            "firstName": "Annie",
            "lastName": "Menz",
            "fullName": "Annie Menz",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Menz,%20Annie.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-OK-45-lower-ocd-person-3a5828d9-b8bf-491c-adfe-19f5331d1e20",
              "builtID": "ok-lower-45",
              "externalID": "ocd-person/3a5828d9-b8bf-491c-adfe-19f5331d1e20",
              "geometry": null
            },
            "contactInfo": {
              "email": "annie.menz@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=45",
                "https://www.okhouse.gov/representatives/annie-menz"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-45-lower-ocd-person-3a5828d9-b8bf-491c-adfe-19f5331d1e20": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/df4d2b89-39a6-4157-90ea-fd229c84f98b",
            "firstName": "Jacob",
            "lastName": "Rosecrants",
            "fullName": "Jacob Rosecrants",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Rosecrants,%20Jacob.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-OK-46-lower-ocd-person-df4d2b89-39a6-4157-90ea-fd229c84f98b",
              "builtID": "ok-lower-46",
              "externalID": "ocd-person/df4d2b89-39a6-4157-90ea-fd229c84f98b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jacob.rosecrants@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=46",
                "https://www.okhouse.gov/Members/Contact.aspx?District=46",
                "https://www.okhouse.gov/representatives/jacob-rosecrants"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-46-lower-ocd-person-df4d2b89-39a6-4157-90ea-fd229c84f98b": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/03af3716-0ac4-49f5-b25a-ff757420d87d",
            "firstName": "Brian",
            "lastName": "Hill",
            "fullName": "Brian Hill",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Hill,%20Brian.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-OK-47-lower-ocd-person-03af3716-0ac4-49f5-b25a-ff757420d87d",
              "builtID": "ok-lower-47",
              "externalID": "ocd-person/03af3716-0ac4-49f5-b25a-ff757420d87d",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.hill@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=47",
                "https://www.okhouse.gov/Members/Contact.aspx?District=47",
                "https://www.okhouse.gov/representatives/brian-hill"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-47-lower-ocd-person-03af3716-0ac4-49f5-b25a-ff757420d87d": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/1d6bc21f-6774-45de-b805-23cd8b5c32aa",
            "firstName": "Tammy Pevehouse",
            "lastName": "Townley",
            "fullName": "Tammy Townley",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Townley,%20Tammy.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-OK-48-lower-ocd-person-1d6bc21f-6774-45de-b805-23cd8b5c32aa",
              "builtID": "ok-lower-48",
              "externalID": "ocd-person/1d6bc21f-6774-45de-b805-23cd8b5c32aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "tammy.townley@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=48",
                "https://www.okhouse.gov/Members/Contact.aspx?District=48",
                "https://www.okhouse.gov/representatives/tammy-townley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-48-lower-ocd-person-1d6bc21f-6774-45de-b805-23cd8b5c32aa": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/7bfc4244-9498-4a20-bdf7-44383688b608",
            "firstName": "Josh",
            "lastName": "Cantrell",
            "fullName": "Josh Cantrell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Cantrell,%20Josh.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-OK-49-lower-ocd-person-7bfc4244-9498-4a20-bdf7-44383688b608",
              "builtID": "ok-lower-49",
              "externalID": "ocd-person/7bfc4244-9498-4a20-bdf7-44383688b608",
              "geometry": null
            },
            "contactInfo": {
              "email": "josh.cantrell@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=49",
                "https://www.okhouse.gov/representatives/josh-cantrell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-49-lower-ocd-person-7bfc4244-9498-4a20-bdf7-44383688b608": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/6fad0759-b23e-4fa9-9aab-7c20eb0b2479",
            "firstName": "Marcus",
            "lastName": "McEntire",
            "fullName": "Marcus McEntire",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Marcus-McEntire.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-OK-50-lower-ocd-person-6fad0759-b23e-4fa9-9aab-7c20eb0b2479",
              "builtID": "ok-lower-50",
              "externalID": "ocd-person/6fad0759-b23e-4fa9-9aab-7c20eb0b2479",
              "geometry": null
            },
            "contactInfo": {
              "email": "marcus.mcentire@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=50",
                "https://www.okhouse.gov/Members/Contact.aspx?District=50",
                "https://www.okhouse.gov/representatives/marcus-mcentire"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-50-lower-ocd-person-6fad0759-b23e-4fa9-9aab-7c20eb0b2479": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/be4edaae-b522-4ce0-bc1d-98b813424f57",
            "firstName": "Brad",
            "lastName": "Boles",
            "fullName": "Brad Boles",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Boles,%20Brad.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-OK-51-lower-ocd-person-be4edaae-b522-4ce0-bc1d-98b813424f57",
              "builtID": "ok-lower-51",
              "externalID": "ocd-person/be4edaae-b522-4ce0-bc1d-98b813424f57",
              "geometry": null
            },
            "contactInfo": {
              "email": "brad.boles@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=51",
                "https://www.okhouse.gov/Members/Contact.aspx?District=51",
                "https://www.okhouse.gov/representatives/brad-boles"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-51-lower-ocd-person-be4edaae-b522-4ce0-bc1d-98b813424f57": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/1120aff3-9773-4cc8-9c47-18026559363a",
            "firstName": "Gerrid",
            "lastName": "Kendrix",
            "fullName": "Gerrid Kendrix",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Kendrix,%20Gerrid.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-OK-52-lower-ocd-person-1120aff3-9773-4cc8-9c47-18026559363a",
              "builtID": "ok-lower-52",
              "externalID": "ocd-person/1120aff3-9773-4cc8-9c47-18026559363a",
              "geometry": null
            },
            "contactInfo": {
              "email": "gerrid.kendrix@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=52",
                "https://www.okhouse.gov/representatives/gerrid-kendrix"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-52-lower-ocd-person-1120aff3-9773-4cc8-9c47-18026559363a": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/9c6a6826-6d39-4bb1-9c69-c9bed446b800",
            "firstName": "Mark",
            "lastName": "McBride",
            "fullName": "Mark McBride",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/McBride,%20Mark.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-OK-53-lower-ocd-person-9c6a6826-6d39-4bb1-9c69-c9bed446b800",
              "builtID": "ok-lower-53",
              "externalID": "ocd-person/9c6a6826-6d39-4bb1-9c69-c9bed446b800",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.mcbride@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=53",
                "https://www.okhouse.gov/Members/Contact.aspx?District=53",
                "https://www.okhouse.gov/representatives/mark-mcbride"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-53-lower-ocd-person-9c6a6826-6d39-4bb1-9c69-c9bed446b800": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/fa002548-470f-4cf0-8365-f6f5aa0c50c7",
            "firstName": "Kevin",
            "lastName": "West",
            "fullName": "Kevin West",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Kevin-West.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-OK-54-lower-ocd-person-fa002548-470f-4cf0-8365-f6f5aa0c50c7",
              "builtID": "ok-lower-54",
              "externalID": "ocd-person/fa002548-470f-4cf0-8365-f6f5aa0c50c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.west@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=54",
                "https://www.okhouse.gov/Members/Contact.aspx?District=54",
                "https://www.okhouse.gov/representatives/kevin-west"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-54-lower-ocd-person-fa002548-470f-4cf0-8365-f6f5aa0c50c7": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/479b4def-b284-426b-8290-86bdce854bce",
            "firstName": "Nick",
            "lastName": "Archer",
            "fullName": "Nick Archer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Archer,%20Nick.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-OK-55-lower-ocd-person-479b4def-b284-426b-8290-86bdce854bce",
              "builtID": "ok-lower-55",
              "externalID": "ocd-person/479b4def-b284-426b-8290-86bdce854bce",
              "geometry": null
            },
            "contactInfo": {
              "email": "nick.archer@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=55",
                "https://www.okhouse.gov/representatives/nick-archer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-55-lower-ocd-person-479b4def-b284-426b-8290-86bdce854bce": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/dc094bcd-66c1-47f4-b9b0-94272636a7fe",
            "firstName": "Dick",
            "lastName": "Lowe",
            "fullName": "Dick Lowe",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Lowe,%20Dick.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-OK-56-lower-ocd-person-dc094bcd-66c1-47f4-b9b0-94272636a7fe",
              "builtID": "ok-lower-56",
              "externalID": "ocd-person/dc094bcd-66c1-47f4-b9b0-94272636a7fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "dick.lowe@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=56",
                "https://www.okhouse.gov/representatives/dick-lowe"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-56-lower-ocd-person-dc094bcd-66c1-47f4-b9b0-94272636a7fe": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/c1f71291-89c1-4109-a15c-2483743d333a",
            "firstName": "Anthony Scott",
            "lastName": "Moore",
            "fullName": "Anthony Moore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Moore,%20Anthony.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-OK-57-lower-ocd-person-c1f71291-89c1-4109-a15c-2483743d333a",
              "builtID": "ok-lower-57",
              "externalID": "ocd-person/c1f71291-89c1-4109-a15c-2483743d333a",
              "geometry": null
            },
            "contactInfo": {
              "email": "anthony.moore@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=57",
                "https://www.okhouse.gov/representatives/anthony-moore"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-57-lower-ocd-person-c1f71291-89c1-4109-a15c-2483743d333a": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/6cfaac2f-52af-4aad-8ec4-6373bb45bf24",
            "firstName": "Carl",
            "lastName": "Newton",
            "fullName": "Carl Newton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Newton,%20Carl.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-OK-58-lower-ocd-person-6cfaac2f-52af-4aad-8ec4-6373bb45bf24",
              "builtID": "ok-lower-58",
              "externalID": "ocd-person/6cfaac2f-52af-4aad-8ec4-6373bb45bf24",
              "geometry": null
            },
            "contactInfo": {
              "email": "carl.newton@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=58",
                "https://www.okhouse.gov/Members/Contact.aspx?District=58",
                "https://www.okhouse.gov/representatives/carl-newton"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-58-lower-ocd-person-6cfaac2f-52af-4aad-8ec4-6373bb45bf24": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/527c315f-7d62-4de1-a543-6c81a0ac11a3",
            "firstName": "Mike",
            "lastName": "Dobrinski",
            "fullName": "Mike Dobrinski",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Dobrinski,%20Mike.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-OK-59-lower-ocd-person-527c315f-7d62-4de1-a543-6c81a0ac11a3",
              "builtID": "ok-lower-59",
              "externalID": "ocd-person/527c315f-7d62-4de1-a543-6c81a0ac11a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.dobrinski@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=59",
                "https://www.okhouse.gov/representatives/mike-dobrinski"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-59-lower-ocd-person-527c315f-7d62-4de1-a543-6c81a0ac11a3": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/bc0edc8a-d7b4-4f90-a4c3-75e3fd83e80b",
            "firstName": "Rhonda",
            "lastName": "Baker",
            "fullName": "Rhonda Baker",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Baker,%20Rhonda.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-OK-60-lower-ocd-person-bc0edc8a-d7b4-4f90-a4c3-75e3fd83e80b",
              "builtID": "ok-lower-60",
              "externalID": "ocd-person/bc0edc8a-d7b4-4f90-a4c3-75e3fd83e80b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rhonda.baker@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=60",
                "https://www.okhouse.gov/Members/Contact.aspx?District=60",
                "https://www.okhouse.gov/representatives/rhonda-baker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-60-lower-ocd-person-bc0edc8a-d7b4-4f90-a4c3-75e3fd83e80b": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/91b38015-8cd5-410e-b850-34961760b8d7",
            "firstName": "Kenton",
            "lastName": "Patzkowsky",
            "fullName": "Kenton Patzkowsky",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Kenton-Patzkowsky,.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-OK-61-lower-ocd-person-91b38015-8cd5-410e-b850-34961760b8d7",
              "builtID": "ok-lower-61",
              "externalID": "ocd-person/91b38015-8cd5-410e-b850-34961760b8d7",
              "geometry": null
            },
            "contactInfo": {
              "email": "kenton.patzkowsky@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=61",
                "https://www.okhouse.gov/Members/Contact.aspx?District=61",
                "https://www.okhouse.gov/representatives/kenton-patzkowsky"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-61-lower-ocd-person-91b38015-8cd5-410e-b850-34961760b8d7": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/62d0d568-33cf-4ec6-b90e-5c2b45be82ba",
            "firstName": "Daniel",
            "lastName": "Pae",
            "fullName": "Daniel Pae",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Pae,%20Daniel.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-OK-62-lower-ocd-person-62d0d568-33cf-4ec6-b90e-5c2b45be82ba",
              "builtID": "ok-lower-62",
              "externalID": "ocd-person/62d0d568-33cf-4ec6-b90e-5c2b45be82ba",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.pae@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=62",
                "https://www.okhouse.gov/Members/Contact.aspx?District=62",
                "https://www.okhouse.gov/representatives/daniel-pae"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-62-lower-ocd-person-62d0d568-33cf-4ec6-b90e-5c2b45be82ba": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/da01678a-03b7-4c2f-b93b-f98338c638b0",
            "firstName": "Hurchel E.",
            "lastName": "Caldwell",
            "fullName": "Trey Caldwell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Caldwell,%20Trey.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-OK-63-lower-ocd-person-da01678a-03b7-4c2f-b93b-f98338c638b0",
              "builtID": "ok-lower-63",
              "externalID": "ocd-person/da01678a-03b7-4c2f-b93b-f98338c638b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "trey.caldwell@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=63",
                "https://www.okhouse.gov/Members/Contact.aspx?District=63",
                "https://www.okhouse.gov/representatives/trey-caldwell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-63-lower-ocd-person-da01678a-03b7-4c2f-b93b-f98338c638b0": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/cfa7e540-ce4e-4230-9f82-9784d11733cb",
            "firstName": "Rande",
            "lastName": "Worthen",
            "fullName": "Rande Worthen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Rande-Worthen.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-OK-64-lower-ocd-person-cfa7e540-ce4e-4230-9f82-9784d11733cb",
              "builtID": "ok-lower-64",
              "externalID": "ocd-person/cfa7e540-ce4e-4230-9f82-9784d11733cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "rande.worthen@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=64",
                "https://www.okhouse.gov/Members/Contact.aspx?District=64",
                "https://www.okhouse.gov/representatives/rande-worthen"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-64-lower-ocd-person-cfa7e540-ce4e-4230-9f82-9784d11733cb": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/25f65ce4-eb63-4beb-92d0-0d07db7f851a",
            "firstName": "Toni",
            "lastName": "Hasenbeck",
            "fullName": "Toni Hasenbeck",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Hasenbeck,%20Toni.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-OK-65-lower-ocd-person-25f65ce4-eb63-4beb-92d0-0d07db7f851a",
              "builtID": "ok-lower-65",
              "externalID": "ocd-person/25f65ce4-eb63-4beb-92d0-0d07db7f851a",
              "geometry": null
            },
            "contactInfo": {
              "email": "toni.hasenbeck@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=65",
                "https://www.okhouse.gov/Members/Contact.aspx?District=65",
                "https://www.okhouse.gov/representatives/toni-hasenbeck"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-65-lower-ocd-person-25f65ce4-eb63-4beb-92d0-0d07db7f851a": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/82e04ad9-64c8-4439-9853-cbc3e273d637",
            "firstName": "Clay",
            "lastName": "Staires",
            "fullName": "Clay Staires",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Staires,%20Clay.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-OK-66-lower-ocd-person-82e04ad9-64c8-4439-9853-cbc3e273d637",
              "builtID": "ok-lower-66",
              "externalID": "ocd-person/82e04ad9-64c8-4439-9853-cbc3e273d637",
              "geometry": null
            },
            "contactInfo": {
              "email": "clay.staires@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=66",
                "https://www.okhouse.gov/representatives/clay-staires"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-66-lower-ocd-person-82e04ad9-64c8-4439-9853-cbc3e273d637": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/82451cbe-531b-42f4-a9bd-41e469aa5a2a",
            "firstName": "Jeff",
            "lastName": "Boatman",
            "fullName": "Jeff Boatman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Jeff-Boatman.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-OK-67-lower-ocd-person-82451cbe-531b-42f4-a9bd-41e469aa5a2a",
              "builtID": "ok-lower-67",
              "externalID": "ocd-person/82451cbe-531b-42f4-a9bd-41e469aa5a2a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.boatman@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=67",
                "https://www.okhouse.gov/Members/Contact.aspx?District=67",
                "https://www.okhouse.gov/representatives/jeff-boatman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-67-lower-ocd-person-82451cbe-531b-42f4-a9bd-41e469aa5a2a": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/4c1db434-3489-4363-aec6-3519affd6b84",
            "firstName": "Lonnie",
            "lastName": "Sims",
            "fullName": "Lonnie Sims",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Sims,%20Lonnie.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-OK-68-lower-ocd-person-4c1db434-3489-4363-aec6-3519affd6b84",
              "builtID": "ok-lower-68",
              "externalID": "ocd-person/4c1db434-3489-4363-aec6-3519affd6b84",
              "geometry": null
            },
            "contactInfo": {
              "email": "lonnie.sims@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=68",
                "https://www.okhouse.gov/Members/Contact.aspx?District=68",
                "https://www.okhouse.gov/representatives/lonnie-sims"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-68-lower-ocd-person-4c1db434-3489-4363-aec6-3519affd6b84": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/6cac2a71-37ce-41be-8e9a-059f7a8667c5",
            "firstName": "Mark",
            "lastName": "Tedford",
            "fullName": "Mark Tedford",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Tedford,%20Mark.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-OK-69-lower-ocd-person-6cac2a71-37ce-41be-8e9a-059f7a8667c5",
              "builtID": "ok-lower-69",
              "externalID": "ocd-person/6cac2a71-37ce-41be-8e9a-059f7a8667c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.tedford@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=69",
                "https://www.okhouse.gov/representatives/mark-tedford"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-69-lower-ocd-person-6cac2a71-37ce-41be-8e9a-059f7a8667c5": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/3d8b5070-b96a-4976-b886-e889214ffd0b",
            "firstName": "Suzanne",
            "lastName": "Schreiber",
            "fullName": "Suzanne Schreiber",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Schreiber,%20Suzanne.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-OK-70-lower-ocd-person-3d8b5070-b96a-4976-b886-e889214ffd0b",
              "builtID": "ok-lower-70",
              "externalID": "ocd-person/3d8b5070-b96a-4976-b886-e889214ffd0b",
              "geometry": null
            },
            "contactInfo": {
              "email": "suzanne.schreiber@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=70",
                "https://www.okhouse.gov/representatives/suzanne-schreiber"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-70-lower-ocd-person-3d8b5070-b96a-4976-b886-e889214ffd0b": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/c258415a-84bc-4d81-ad6f-0509a037bfd5",
            "firstName": "Amanda",
            "lastName": "Swope",
            "fullName": "Amanda Swope",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Swope,%20Amanda.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-OK-71-lower-ocd-person-c258415a-84bc-4d81-ad6f-0509a037bfd5",
              "builtID": "ok-lower-71",
              "externalID": "ocd-person/c258415a-84bc-4d81-ad6f-0509a037bfd5",
              "geometry": null
            },
            "contactInfo": {
              "email": "amanda.swope@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=71",
                "https://www.okhouse.gov/representatives/amanda-swope"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-71-lower-ocd-person-c258415a-84bc-4d81-ad6f-0509a037bfd5": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/8c8adabd-e844-42d2-a3a9-85b5f38619c2",
            "firstName": "Monroe",
            "lastName": "Nichols",
            "fullName": "Monroe Nichols",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Nichols,%20Monroe.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-OK-72-lower-ocd-person-8c8adabd-e844-42d2-a3a9-85b5f38619c2",
              "builtID": "ok-lower-72",
              "externalID": "ocd-person/8c8adabd-e844-42d2-a3a9-85b5f38619c2",
              "geometry": null
            },
            "contactInfo": {
              "email": "monroe.nichols@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=72",
                "https://www.okhouse.gov/Members/Contact.aspx?District=72",
                "https://www.okhouse.gov/representatives/monroe-nichols"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-72-lower-ocd-person-8c8adabd-e844-42d2-a3a9-85b5f38619c2": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/2d438999-104f-4405-8cdc-d53ba4715a19",
            "firstName": "Regina T.",
            "lastName": "Goodwin",
            "fullName": "Regina Goodwin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Goodwin,%20Regina.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-OK-73-lower-ocd-person-2d438999-104f-4405-8cdc-d53ba4715a19",
              "builtID": "ok-lower-73",
              "externalID": "ocd-person/2d438999-104f-4405-8cdc-d53ba4715a19",
              "geometry": null
            },
            "contactInfo": {
              "email": "regina.goodwin@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=73",
                "https://www.okhouse.gov/Members/Contact.aspx?District=73",
                "https://www.okhouse.gov/representatives/regina-goodwin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-73-lower-ocd-person-2d438999-104f-4405-8cdc-d53ba4715a19": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/018150d0-40c4-47c4-abfb-dd8270f0af32",
            "firstName": "Mark",
            "lastName": "Vancuren",
            "fullName": "Mark Vancuren",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Mark-Vancuren.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-OK-74-lower-ocd-person-018150d0-40c4-47c4-abfb-dd8270f0af32",
              "builtID": "ok-lower-74",
              "externalID": "ocd-person/018150d0-40c4-47c4-abfb-dd8270f0af32",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.vancuren@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=74",
                "https://www.okhouse.gov/Members/Contact.aspx?District=74",
                "https://www.okhouse.gov/representatives/mark-vancuren"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-74-lower-ocd-person-018150d0-40c4-47c4-abfb-dd8270f0af32": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/71996394-7250-43ab-b69f-8c0afa3b144a",
            "firstName": "T.J.",
            "lastName": "Marti",
            "fullName": "T.J. Marti",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Marti,%20TJ.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-OK-75-lower-ocd-person-71996394-7250-43ab-b69f-8c0afa3b144a",
              "builtID": "ok-lower-75",
              "externalID": "ocd-person/71996394-7250-43ab-b69f-8c0afa3b144a",
              "geometry": null
            },
            "contactInfo": {
              "email": "tj.marti@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=75",
                "https://www.okhouse.gov/Members/Contact.aspx?District=75",
                "https://www.okhouse.gov/representatives/tj-marti"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-75-lower-ocd-person-71996394-7250-43ab-b69f-8c0afa3b144a": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/a6aa7540-02db-458d-9031-e6af94d63b44",
            "firstName": "Ross",
            "lastName": "Ford",
            "fullName": "Ross Ford",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Ford,%20Ross.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-OK-76-lower-ocd-person-a6aa7540-02db-458d-9031-e6af94d63b44",
              "builtID": "ok-lower-76",
              "externalID": "ocd-person/a6aa7540-02db-458d-9031-e6af94d63b44",
              "geometry": null
            },
            "contactInfo": {
              "email": "ross.ford@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=76",
                "https://www.okhouse.gov/Members/Contact.aspx?District=76",
                "https://www.okhouse.gov/representatives/ross-ford"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-76-lower-ocd-person-a6aa7540-02db-458d-9031-e6af94d63b44": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/6a662cc1-492b-4c65-a77c-9b8b202b1ae5",
            "firstName": "John",
            "lastName": "Waldron",
            "fullName": "John Waldron",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Waldron,%20John.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-OK-77-lower-ocd-person-6a662cc1-492b-4c65-a77c-9b8b202b1ae5",
              "builtID": "ok-lower-77",
              "externalID": "ocd-person/6a662cc1-492b-4c65-a77c-9b8b202b1ae5",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.waldron@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=77",
                "https://www.okhouse.gov/Members/Contact.aspx?District=77",
                "https://www.okhouse.gov/representatives/john-waldron"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-77-lower-ocd-person-6a662cc1-492b-4c65-a77c-9b8b202b1ae5": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/e9c3844d-e861-4848-8737-539fdb553eb9",
            "firstName": "Meloyde",
            "lastName": "Blancett",
            "fullName": "Meloyde Blancett",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Blancett,%20Meloyde.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-OK-78-lower-ocd-person-e9c3844d-e861-4848-8737-539fdb553eb9",
              "builtID": "ok-lower-78",
              "externalID": "ocd-person/e9c3844d-e861-4848-8737-539fdb553eb9",
              "geometry": null
            },
            "contactInfo": {
              "email": "meloyde.blancett@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=78",
                "https://www.okhouse.gov/Members/Contact.aspx?District=78",
                "https://www.okhouse.gov/representatives/meloyde-blancett"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-78-lower-ocd-person-e9c3844d-e861-4848-8737-539fdb553eb9": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/4085a19f-1853-48e4-88c8-8b5b9089054a",
            "firstName": "Melissa",
            "lastName": "Provenzano",
            "fullName": "Melissa Provenzano",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Melissa-Provenzano.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-OK-79-lower-ocd-person-4085a19f-1853-48e4-88c8-8b5b9089054a",
              "builtID": "ok-lower-79",
              "externalID": "ocd-person/4085a19f-1853-48e4-88c8-8b5b9089054a",
              "geometry": null
            },
            "contactInfo": {
              "email": "melissa.provenzano@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=79",
                "https://www.okhouse.gov/Members/Contact.aspx?District=79",
                "https://www.okhouse.gov/representatives/melissa-provenzano"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-79-lower-ocd-person-4085a19f-1853-48e4-88c8-8b5b9089054a": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/1ba99d3e-ebea-4426-b91a-0560da625e83",
            "firstName": "Stan",
            "lastName": "May",
            "fullName": "Stan May",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/May,%20Stan.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-OK-80-lower-ocd-person-1ba99d3e-ebea-4426-b91a-0560da625e83",
              "builtID": "ok-lower-80",
              "externalID": "ocd-person/1ba99d3e-ebea-4426-b91a-0560da625e83",
              "geometry": null
            },
            "contactInfo": {
              "email": "stan.may@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=80",
                "https://www.okhouse.gov/Members/Contact.aspx?District=80",
                "https://www.okhouse.gov/representatives/stan-may"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-80-lower-ocd-person-1ba99d3e-ebea-4426-b91a-0560da625e83": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/95734f94-939c-4774-82a5-c9222201f62f",
            "firstName": "Michael T.",
            "lastName": "Osburn",
            "fullName": "Mike Osburn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Osburn,%20Mike.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-OK-81-lower-ocd-person-95734f94-939c-4774-82a5-c9222201f62f",
              "builtID": "ok-lower-81",
              "externalID": "ocd-person/95734f94-939c-4774-82a5-c9222201f62f",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.osburn@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=81",
                "https://www.okhouse.gov/Members/Contact.aspx?District=81",
                "https://www.okhouse.gov/representatives/mike-osburn"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-81-lower-ocd-person-95734f94-939c-4774-82a5-c9222201f62f": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/4af77a1a-7011-4901-89fe-4619d558d34c",
            "firstName": "Nicole",
            "lastName": "Miller",
            "fullName": "Nicole Miller",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Nicole-Miller.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-OK-82-lower-ocd-person-4af77a1a-7011-4901-89fe-4619d558d34c",
              "builtID": "ok-lower-82",
              "externalID": "ocd-person/4af77a1a-7011-4901-89fe-4619d558d34c",
              "geometry": null
            },
            "contactInfo": {
              "email": "nicole.miller@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=82",
                "https://www.okhouse.gov/Members/Contact.aspx?District=82",
                "https://www.okhouse.gov/representatives/nicole-miller"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-82-lower-ocd-person-4af77a1a-7011-4901-89fe-4619d558d34c": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/752b02fd-2ce1-46d3-88a3-337d64df6b1a",
            "firstName": "Eric",
            "lastName": "Roberts",
            "fullName": "Eric Roberts",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Roberts,%20Eric.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-OK-83-lower-ocd-person-752b02fd-2ce1-46d3-88a3-337d64df6b1a",
              "builtID": "ok-lower-83",
              "externalID": "ocd-person/752b02fd-2ce1-46d3-88a3-337d64df6b1a",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.roberts@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=83",
                "https://www.okhouse.gov/representatives/eric-roberts"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-83-lower-ocd-person-752b02fd-2ce1-46d3-88a3-337d64df6b1a": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/8a0a65fd-9a33-4f93-a94a-131e0ec39e6b",
            "firstName": "Tammy",
            "lastName": "West",
            "fullName": "Tammy West",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Tammy-West.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-OK-84-lower-ocd-person-8a0a65fd-9a33-4f93-a94a-131e0ec39e6b",
              "builtID": "ok-lower-84",
              "externalID": "ocd-person/8a0a65fd-9a33-4f93-a94a-131e0ec39e6b",
              "geometry": null
            },
            "contactInfo": {
              "email": "tammy.west@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=84",
                "https://www.okhouse.gov/Members/Contact.aspx?District=84",
                "https://www.okhouse.gov/representatives/tammy-west"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-84-lower-ocd-person-8a0a65fd-9a33-4f93-a94a-131e0ec39e6b": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/f9153735-938a-418b-ac4f-6881904861ac",
            "firstName": "Cyndi Ann",
            "lastName": "Munson",
            "fullName": "Cyndi Munson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Munson,%20Cyndie.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-OK-85-lower-ocd-person-f9153735-938a-418b-ac4f-6881904861ac",
              "builtID": "ok-lower-85",
              "externalID": "ocd-person/f9153735-938a-418b-ac4f-6881904861ac",
              "geometry": null
            },
            "contactInfo": {
              "email": "cyndi.munson@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=85",
                "https://www.okhouse.gov/Members/Contact.aspx?District=85",
                "https://www.okhouse.gov/representatives/cyndi-munson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-85-lower-ocd-person-f9153735-938a-418b-ac4f-6881904861ac": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/b2857b06-9290-4141-9bc4-2a105bc51ce8",
            "firstName": "David",
            "lastName": "Hardin",
            "fullName": "David Hardin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Hardin,%20David.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-OK-86-lower-ocd-person-b2857b06-9290-4141-9bc4-2a105bc51ce8",
              "builtID": "ok-lower-86",
              "externalID": "ocd-person/b2857b06-9290-4141-9bc4-2a105bc51ce8",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.hardin@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=86",
                "https://www.okhouse.gov/Members/Contact.aspx?District=86",
                "https://www.okhouse.gov/representatives/david-hardin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-86-lower-ocd-person-b2857b06-9290-4141-9bc4-2a105bc51ce8": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/c9e547ff-6762-49f8-807c-32c78ce569cf",
            "firstName": "Ellyn Novak",
            "lastName": "Hefner",
            "fullName": "Ellyn Hefner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Hefner,%20Ellyn.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-OK-87-lower-ocd-person-c9e547ff-6762-49f8-807c-32c78ce569cf",
              "builtID": "ok-lower-87",
              "externalID": "ocd-person/c9e547ff-6762-49f8-807c-32c78ce569cf",
              "geometry": null
            },
            "contactInfo": {
              "email": "ellyn.hefner@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=87",
                "https://www.okhouse.gov/representatives/ellyn-hefner"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-87-lower-ocd-person-c9e547ff-6762-49f8-807c-32c78ce569cf": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/6533ee8e-f664-4339-b7ef-4be64c0f56e4",
            "firstName": "Mauree Nivek Rajah Salima",
            "lastName": "Turner",
            "fullName": "Mauree Turner",
            "gender": "X",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Turner,%20Mauree.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-OK-88-lower-ocd-person-6533ee8e-f664-4339-b7ef-4be64c0f56e4",
              "builtID": "ok-lower-88",
              "externalID": "ocd-person/6533ee8e-f664-4339-b7ef-4be64c0f56e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "mauree.turner@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=88",
                "https://www.okhouse.gov/representatives/mauree-turner"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-88-lower-ocd-person-6533ee8e-f664-4339-b7ef-4be64c0f56e4": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/4d0b52d3-ad1f-45c9-b46e-3676194ef82c",
            "firstName": "Arturo",
            "lastName": "Alonso-Sandoval",
            "fullName": "Arturo Alonso",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Alonso,%20Arturo.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-OK-89-lower-ocd-person-4d0b52d3-ad1f-45c9-b46e-3676194ef82c",
              "builtID": "ok-lower-89",
              "externalID": "ocd-person/4d0b52d3-ad1f-45c9-b46e-3676194ef82c",
              "geometry": null
            },
            "contactInfo": {
              "email": "arturo.alonso@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=89",
                "https://www.okhouse.gov/representatives/arturo-alonsosandoval"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-89-lower-ocd-person-4d0b52d3-ad1f-45c9-b46e-3676194ef82c": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/e3430b28-db75-4427-a9b3-9fe5e535e672",
            "firstName": "Jon",
            "lastName": "Echols",
            "fullName": "Jon Echols",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Echols,%20Jon.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-OK-90-lower-ocd-person-e3430b28-db75-4427-a9b3-9fe5e535e672",
              "builtID": "ok-lower-90",
              "externalID": "ocd-person/e3430b28-db75-4427-a9b3-9fe5e535e672",
              "geometry": null
            },
            "contactInfo": {
              "email": "jon.echols@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=90",
                "https://www.okhouse.gov/Members/Contact.aspx?District=90",
                "https://www.okhouse.gov/representatives/jon-echols"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-90-lower-ocd-person-e3430b28-db75-4427-a9b3-9fe5e535e672": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/5b2d71b9-21c7-4291-ac09-e2840fee2919",
            "firstName": "Christopher L.",
            "lastName": "Kannady",
            "fullName": "Chris Kannady",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Kannady,%20Chris.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-OK-91-lower-ocd-person-5b2d71b9-21c7-4291-ac09-e2840fee2919",
              "builtID": "ok-lower-91",
              "externalID": "ocd-person/5b2d71b9-21c7-4291-ac09-e2840fee2919",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.kannady@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=91",
                "https://www.okhouse.gov/Members/Contact.aspx?District=91",
                "https://www.okhouse.gov/representatives/chris-kannady"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-91-lower-ocd-person-5b2d71b9-21c7-4291-ac09-e2840fee2919": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/a6cd0df4-10a8-4dd8-a887-763e2aebcac2",
            "firstName": "Forrest Welch",
            "lastName": "Bennett",
            "fullName": "Forrest Bennett",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Bennett,%20Forrest.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-OK-92-lower-ocd-person-a6cd0df4-10a8-4dd8-a887-763e2aebcac2",
              "builtID": "ok-lower-92",
              "externalID": "ocd-person/a6cd0df4-10a8-4dd8-a887-763e2aebcac2",
              "geometry": null
            },
            "contactInfo": {
              "email": "forrest.bennett@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=92",
                "https://www.okhouse.gov/Members/Contact.aspx?District=92",
                "https://www.okhouse.gov/representatives/forrest-bennett"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-92-lower-ocd-person-a6cd0df4-10a8-4dd8-a887-763e2aebcac2": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/2c065b7f-f5eb-4c15-bd69-10a7fa339b28",
            "firstName": "Mickey",
            "lastName": "Dollens",
            "fullName": "Mickey Dollens",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Mickey-Dollens.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-OK-93-lower-ocd-person-2c065b7f-f5eb-4c15-bd69-10a7fa339b28",
              "builtID": "ok-lower-93",
              "externalID": "ocd-person/2c065b7f-f5eb-4c15-bd69-10a7fa339b28",
              "geometry": null
            },
            "contactInfo": {
              "email": "mickey.dollens@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=93",
                "https://www.okhouse.gov/Members/Contact.aspx?District=93",
                "https://www.okhouse.gov/representatives/mickey-dollens"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-93-lower-ocd-person-2c065b7f-f5eb-4c15-bd69-10a7fa339b28": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/987fd457-5b14-4300-95f1-e92f7cd5f748",
            "firstName": "Andy",
            "lastName": "Fugate",
            "fullName": "Andy Fugate",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Fugate,%20Andy.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-OK-94-lower-ocd-person-987fd457-5b14-4300-95f1-e92f7cd5f748",
              "builtID": "ok-lower-94",
              "externalID": "ocd-person/987fd457-5b14-4300-95f1-e92f7cd5f748",
              "geometry": null
            },
            "contactInfo": {
              "email": "andy.fugate@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=94",
                "https://www.okhouse.gov/Members/Contact.aspx?District=94",
                "https://www.okhouse.gov/representatives/andy-fugate"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-94-lower-ocd-person-987fd457-5b14-4300-95f1-e92f7cd5f748": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/190ef3e9-fdd4-4434-80da-06ebb08f2eaf",
            "firstName": "Max",
            "lastName": "Wolfley",
            "fullName": "Max Wolfley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Wolfley,%20Max.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-OK-95-lower-ocd-person-190ef3e9-fdd4-4434-80da-06ebb08f2eaf",
              "builtID": "ok-lower-95",
              "externalID": "ocd-person/190ef3e9-fdd4-4434-80da-06ebb08f2eaf",
              "geometry": null
            },
            "contactInfo": {
              "email": "max.wolfley@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=95",
                "https://www.okhouse.gov/representatives/max-wolfley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-95-lower-ocd-person-190ef3e9-fdd4-4434-80da-06ebb08f2eaf": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/016593c8-d702-434a-ba7c-aee48b14b6b3",
            "firstName": "Preston",
            "lastName": "Stinson",
            "fullName": "Preston Stinson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Stinson,%20Preston.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-OK-96-lower-ocd-person-016593c8-d702-434a-ba7c-aee48b14b6b3",
              "builtID": "ok-lower-96",
              "externalID": "ocd-person/016593c8-d702-434a-ba7c-aee48b14b6b3",
              "geometry": null
            },
            "contactInfo": {
              "email": "preston.stinson@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/Contact.aspx?District=96",
                "https://www.okhouse.gov/representatives/preston-stinson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-96-lower-ocd-person-016593c8-d702-434a-ba7c-aee48b14b6b3": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/bea035fb-f865-487f-a1f8-5e98dc5212da",
            "firstName": "Jason",
            "lastName": "Lowe",
            "fullName": "Jason Lowe",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Lowe,%20Jason.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-OK-97-lower-ocd-person-bea035fb-f865-487f-a1f8-5e98dc5212da",
              "builtID": "ok-lower-97",
              "externalID": "ocd-person/bea035fb-f865-487f-a1f8-5e98dc5212da",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.lowe@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=97",
                "https://www.okhouse.gov/Members/Contact.aspx?District=97",
                "https://www.okhouse.gov/representatives/jason-lowe"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-97-lower-ocd-person-bea035fb-f865-487f-a1f8-5e98dc5212da": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/5df27b4e-c704-4bbf-a775-a879e3a27e2b",
            "firstName": "Dean",
            "lastName": "Davis",
            "fullName": "Dean Davis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Davis,%20Dean.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-OK-98-lower-ocd-person-5df27b4e-c704-4bbf-a775-a879e3a27e2b",
              "builtID": "ok-lower-98",
              "externalID": "ocd-person/5df27b4e-c704-4bbf-a775-a879e3a27e2b",
              "geometry": null
            },
            "contactInfo": {
              "email": "dean.davis@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=98",
                "https://www.okhouse.gov/Members/Contact.aspx?District=98",
                "https://www.okhouse.gov/representatives/dean-davis"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-98-lower-ocd-person-5df27b4e-c704-4bbf-a775-a879e3a27e2b": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/719605ed-1748-4c0b-bc6a-79330db17af1",
            "firstName": "Ayshia K.M.",
            "lastName": "Pittman",
            "fullName": "Ajay Pittman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Ajay-Pittman.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-OK-99-lower-ocd-person-719605ed-1748-4c0b-bc6a-79330db17af1",
              "builtID": "ok-lower-99",
              "externalID": "ocd-person/719605ed-1748-4c0b-bc6a-79330db17af1",
              "geometry": null
            },
            "contactInfo": {
              "email": "ajay.pittman@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=99",
                "https://www.okhouse.gov/Members/Contact.aspx?District=99",
                "https://www.okhouse.gov/representatives/ajay-pittman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-99-lower-ocd-person-719605ed-1748-4c0b-bc6a-79330db17af1": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/aefe24ff-5a23-48bd-a5df-faa7b3dd0af9",
            "firstName": "Marilyn",
            "lastName": "Stark",
            "fullName": "Marilyn Stark",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Stark,%20Marilyn.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-OK-100-lower-ocd-person-aefe24ff-5a23-48bd-a5df-faa7b3dd0af9",
              "builtID": "ok-lower-100",
              "externalID": "ocd-person/aefe24ff-5a23-48bd-a5df-faa7b3dd0af9",
              "geometry": null
            },
            "contactInfo": {
              "email": "marilyn.stark@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=100",
                "https://www.okhouse.gov/Members/Contact.aspx?District=100",
                "https://www.okhouse.gov/representatives/marilyn-stark"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-100-lower-ocd-person-aefe24ff-5a23-48bd-a5df-faa7b3dd0af9": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/a865c489-d177-4058-af64-51c2f0abef68",
            "firstName": "Robert",
            "lastName": "Manger",
            "fullName": "Robert Manger",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.okhouse.gov/Members/Pictures/HiRes/Manger,%20Robert.jpg",
            "title": "OK Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-OK-101-lower-ocd-person-a865c489-d177-4058-af64-51c2f0abef68",
              "builtID": "ok-lower-101",
              "externalID": "ocd-person/a865c489-d177-4058-af64-51c2f0abef68",
              "geometry": null
            },
            "contactInfo": {
              "email": "robert.manger@okhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.okhouse.gov/Members/District.aspx?District=101",
                "https://www.okhouse.gov/Members/Contact.aspx?District=101",
                "https://www.okhouse.gov/representatives/robert-manger"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OK-101-lower-ocd-person-a865c489-d177-4058-af64-51c2f0abef68": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/49a41053-6a43-44a8-92b9-9fd5e97a56fe",
            "firstName": "Micheal Ray",
            "lastName": "Bergstrom",
            "fullName": "Micheal Bergstrom",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Bergstrom%2C%20Micheal%202022%20flags.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-OK-1-upper-ocd-person-49a41053-6a43-44a8-92b9-9fd5e97a56fe",
              "builtID": "ok-upper-1",
              "externalID": "ocd-person/49a41053-6a43-44a8-92b9-9fd5e97a56fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "bergstrom@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/bergstrom_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1838",
                "https://oksenate.gov/senators/micheal-bergstrom"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-1-upper-ocd-person-49a41053-6a43-44a8-92b9-9fd5e97a56fe": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/0e3e261d-437a-4e04-8328-70adb16c82d9",
            "firstName": "Alexandra",
            "lastName": "Seifried",
            "fullName": "Ally Seifried",
            "gender": "Female",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Seifried%2C%20Ally%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-OK-2-upper-ocd-person-0e3e261d-437a-4e04-8328-70adb16c82d9",
              "builtID": "ok-upper-2",
              "externalID": "ocd-person/0e3e261d-437a-4e04-8328-70adb16c82d9",
              "geometry": null
            },
            "contactInfo": {
              "email": "alexandra.seifried@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/contact-senator?sid=1523279",
                "https://oksenate.gov/senators/ally-seifried"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-2-upper-ocd-person-0e3e261d-437a-4e04-8328-70adb16c82d9": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/1e7de93b-e5e5-4c8c-8835-950bfa33f3b8",
            "firstName": "Blake",
            "lastName": "Stephens",
            "fullName": "Cowboy Stephens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Stephens%2C%20Blake%20Flag%20hat%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-OK-3-upper-ocd-person-1e7de93b-e5e5-4c8c-8835-950bfa33f3b8",
              "builtID": "ok-upper-3",
              "externalID": "ocd-person/1e7de93b-e5e5-4c8c-8835-950bfa33f3b8",
              "geometry": null
            },
            "contactInfo": {
              "email": "blake.stephens@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/contact-senator?sid=1521372",
                "https://oksenate.gov/senators/blake-stephens"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-3-upper-ocd-person-1e7de93b-e5e5-4c8c-8835-950bfa33f3b8": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/9b993707-70c1-4e3f-96ee-4257c0cf04e6",
            "firstName": "Tom",
            "lastName": "Woods",
            "fullName": "Tom Woods",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Woods%2C%20Tom%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-OK-4-upper-ocd-person-9b993707-70c1-4e3f-96ee-4257c0cf04e6",
              "builtID": "ok-upper-4",
              "externalID": "ocd-person/9b993707-70c1-4e3f-96ee-4257c0cf04e6",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.woods@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/contact-senator?sid=1523280",
                "https://oksenate.gov/senators/tom-woods"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-4-upper-ocd-person-9b993707-70c1-4e3f-96ee-4257c0cf04e6": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/a0948c13-b3a0-4040-b0db-9f8f8c67eeac",
            "firstName": "George",
            "lastName": "Burns",
            "fullName": "George Burns",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Burns%2C%20George_flags_1.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-OK-5-upper-ocd-person-a0948c13-b3a0-4040-b0db-9f8f8c67eeac",
              "builtID": "ok-upper-5",
              "externalID": "ocd-person/a0948c13-b3a0-4040-b0db-9f8f8c67eeac",
              "geometry": null
            },
            "contactInfo": {
              "email": "george.burns@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/contact-senator?sid=1521373",
                "https://oksenate.gov/senators/george-burns"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-5-upper-ocd-person-a0948c13-b3a0-4040-b0db-9f8f8c67eeac": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/f7e51701-fb8a-4d48-8f61-951096c0c76d",
            "firstName": "David",
            "lastName": "Bullard",
            "fullName": "David Bullard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Bullard_David.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-OK-6-upper-ocd-person-f7e51701-fb8a-4d48-8f61-951096c0c76d",
              "builtID": "ok-upper-6",
              "externalID": "ocd-person/f7e51701-fb8a-4d48-8f61-951096c0c76d",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.bullard@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/bullard_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1843",
                "https://oksenate.gov/senators/david-bullard"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-6-upper-ocd-person-f7e51701-fb8a-4d48-8f61-951096c0c76d": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/48d6aa7c-97e1-4c69-8014-8818ae9ad055",
            "firstName": "Warren",
            "lastName": "Hamilton",
            "fullName": "Warren Hamilton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Hamilton%2C%20Warren%202022%20flags.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-OK-7-upper-ocd-person-48d6aa7c-97e1-4c69-8014-8818ae9ad055",
              "builtID": "ok-upper-7",
              "externalID": "ocd-person/48d6aa7c-97e1-4c69-8014-8818ae9ad055",
              "geometry": null
            },
            "contactInfo": {
              "email": "warren.hamilton@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/contact-senator?sid=1521374",
                "https://oksenate.gov/senators/warren-hamilton"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-7-upper-ocd-person-48d6aa7c-97e1-4c69-8014-8818ae9ad055": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/6a9315ca-2e99-4473-a18a-7e1fab3b6462",
            "firstName": "Roger Lynn",
            "lastName": "Thompson",
            "fullName": "Roger Thompson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Thompson%2C%20Roger%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-OK-8-upper-ocd-person-6a9315ca-2e99-4473-a18a-7e1fab3b6462",
              "builtID": "ok-upper-8",
              "externalID": "ocd-person/6a9315ca-2e99-4473-a18a-7e1fab3b6462",
              "geometry": null
            },
            "contactInfo": {
              "email": "roger.thompson@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/thompson_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1885",
                "https://oksenate.gov/senators/roger-thompson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-8-upper-ocd-person-6a9315ca-2e99-4473-a18a-7e1fab3b6462": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/6f40934a-8f91-49c3-89a7-f4c4875617b1",
            "firstName": "DeWayne",
            "lastName": "Pemberton",
            "fullName": "DeWayne Pemberton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Pemberton%2C%20Dewayne_flags_0.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-OK-9-upper-ocd-person-6f40934a-8f91-49c3-89a7-f4c4875617b1",
              "builtID": "ok-upper-9",
              "externalID": "ocd-person/6f40934a-8f91-49c3-89a7-f4c4875617b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "dewayne.pemberton@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/pemberton_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1886",
                "https://oksenate.gov/senators/dewayne-pemberton"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-9-upper-ocd-person-6f40934a-8f91-49c3-89a7-f4c4875617b1": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/446468ba-e7a8-49ff-8edc-e9f96da9d613",
            "firstName": "Bill",
            "lastName": "Coleman",
            "fullName": "Bill Coleman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Coleman%2C%20Bill%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-OK-10-upper-ocd-person-446468ba-e7a8-49ff-8edc-e9f96da9d613",
              "builtID": "ok-upper-10",
              "externalID": "ocd-person/446468ba-e7a8-49ff-8edc-e9f96da9d613",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.coleman@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/coleman_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1844",
                "https://oksenate.gov/senators/bill-coleman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-10-upper-ocd-person-446468ba-e7a8-49ff-8edc-e9f96da9d613": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/934d09a3-b098-4209-aa68-c986efeba65c",
            "firstName": "Kevin L.",
            "lastName": "Matthews",
            "fullName": "Kevin Matthews",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://oksenate.gov/sites/default/files/Kevin%20Matthews%20w%20Flags.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-OK-11-upper-ocd-person-934d09a3-b098-4209-aa68-c986efeba65c",
              "builtID": "ok-upper-11",
              "externalID": "ocd-person/934d09a3-b098-4209-aa68-c986efeba65c",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthews@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/matthews_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1859",
                "https://oksenate.gov/senators/kevin-matthews"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-11-upper-ocd-person-934d09a3-b098-4209-aa68-c986efeba65c": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/54d37a3d-68cb-4e87-9916-c38f2f0f8ed3",
            "firstName": "Todd",
            "lastName": "Gollihare",
            "fullName": "Todd Gollihare",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Gollihare%2C%20Todd%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-OK-12-upper-ocd-person-54d37a3d-68cb-4e87-9916-c38f2f0f8ed3",
              "builtID": "ok-upper-12",
              "externalID": "ocd-person/54d37a3d-68cb-4e87-9916-c38f2f0f8ed3",
              "geometry": null
            },
            "contactInfo": {
              "email": "todd.gollihare@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/contact-senator?sid=1523282",
                "https://oksenate.gov/senators/todd-gollihare"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-12-upper-ocd-person-54d37a3d-68cb-4e87-9916-c38f2f0f8ed3": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/31d076dc-036f-47cf-9fa1-5be461aed33d",
            "firstName": "Greg",
            "lastName": "McCortney",
            "fullName": "Greg McCortney",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/McCortney%2C%20Greg%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-OK-13-upper-ocd-person-31d076dc-036f-47cf-9fa1-5be461aed33d",
              "builtID": "ok-upper-13",
              "externalID": "ocd-person/31d076dc-036f-47cf-9fa1-5be461aed33d",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.mccortney@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "117 S. Broadway, Ada, OK 74820",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/mccortney_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1860",
                "https://oksenate.gov/senators/greg-mccortney"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-13-upper-ocd-person-31d076dc-036f-47cf-9fa1-5be461aed33d": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/edf90069-7569-4cd7-9a8e-e3890e395ac7",
            "firstName": "Jerry",
            "lastName": "Alvord",
            "fullName": "Jerry Alvord",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Alvord%2C%20Jerry%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-OK-14-upper-ocd-person-edf90069-7569-4cd7-9a8e-e3890e395ac7",
              "builtID": "ok-upper-14",
              "externalID": "ocd-person/edf90069-7569-4cd7-9a8e-e3890e395ac7",
              "geometry": null
            },
            "contactInfo": {
              "email": "jerry.alvord@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/contact-senator?sid=1523283",
                "https://oksenate.gov/senators/jerry-alvord"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-14-upper-ocd-person-edf90069-7569-4cd7-9a8e-e3890e395ac7": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/1985c048-831a-4634-9a7a-b626ad9fc1b4",
            "firstName": "Rob",
            "lastName": "Standridge",
            "fullName": "Rob Standridge",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Standridge.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-OK-15-upper-ocd-person-1985c048-831a-4634-9a7a-b626ad9fc1b4",
              "builtID": "ok-upper-15",
              "externalID": "ocd-person/1985c048-831a-4634-9a7a-b626ad9fc1b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rob.standridge@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/standridge_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1889",
                "https://oksenate.gov/senators/rob-standridge"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-15-upper-ocd-person-1985c048-831a-4634-9a7a-b626ad9fc1b4": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/0172970c-3434-470b-98c8-6f7f0b167afb",
            "firstName": "Mary Brown",
            "lastName": "Boren",
            "fullName": "Mary Boren",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://oksenate.gov/sites/default/files/Boren%2C%20Mary%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-OK-16-upper-ocd-person-0172970c-3434-470b-98c8-6f7f0b167afb",
              "builtID": "ok-upper-16",
              "externalID": "ocd-person/0172970c-3434-470b-98c8-6f7f0b167afb",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.boren@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/boren_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1841",
                "https://oksenate.gov/senators/mary-b-boren"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-16-upper-ocd-person-0172970c-3434-470b-98c8-6f7f0b167afb": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/97650810-9219-451e-97d1-e3d3318c5d53",
            "firstName": "Shane David",
            "lastName": "Jett",
            "fullName": "Shane Jett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Jett%2C%20Shane_flags.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-OK-17-upper-ocd-person-97650810-9219-451e-97d1-e3d3318c5d53",
              "builtID": "ok-upper-17",
              "externalID": "ocd-person/97650810-9219-451e-97d1-e3d3318c5d53",
              "geometry": null
            },
            "contactInfo": {
              "email": "shane.jett@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/contact-senator?sid=1521375",
                "https://oksenate.gov/senators/shane-jett"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-17-upper-ocd-person-97650810-9219-451e-97d1-e3d3318c5d53": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/8e22d71d-fd9a-4d9d-aa7c-09af3da43118",
            "firstName": "Jack",
            "lastName": "Stewart",
            "fullName": "Jack Stewart",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Stewart%2C%20Jack%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-OK-18-upper-ocd-person-8e22d71d-fd9a-4d9d-aa7c-09af3da43118",
              "builtID": "ok-upper-18",
              "externalID": "ocd-person/8e22d71d-fd9a-4d9d-aa7c-09af3da43118",
              "geometry": null
            },
            "contactInfo": {
              "email": "jack.stewart@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/contact-senator?sid=1523284",
                "https://oksenate.gov/senators/jack-stewart"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-18-upper-ocd-person-8e22d71d-fd9a-4d9d-aa7c-09af3da43118": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/c0db5455-8d12-4525-ab57-afa8ed7e1d7f",
            "firstName": "Roland",
            "lastName": "Pederson",
            "fullName": "Roland Pederson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Pederson%2C%20Roland%202022%20flags.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-OK-19-upper-ocd-person-c0db5455-8d12-4525-ab57-afa8ed7e1d7f",
              "builtID": "ok-upper-19",
              "externalID": "ocd-person/c0db5455-8d12-4525-ab57-afa8ed7e1d7f",
              "geometry": null
            },
            "contactInfo": {
              "email": "roland.pederson@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/pederson_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1892",
                "https://oksenate.gov/senators/roland-pederson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-19-upper-ocd-person-c0db5455-8d12-4525-ab57-afa8ed7e1d7f": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/95f06268-cdfb-463b-aa2e-fb789fbc97c4",
            "firstName": "Charles R.",
            "lastName": "Hall",
            "fullName": "Chuck Hall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/hall.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-OK-20-upper-ocd-person-95f06268-cdfb-463b-aa2e-fb789fbc97c4",
              "builtID": "ok-upper-20",
              "externalID": "ocd-person/95f06268-cdfb-463b-aa2e-fb789fbc97c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "chuck.hall@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/hall_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1850",
                "https://oksenate.gov/senators/chuck-hall"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-20-upper-ocd-person-95f06268-cdfb-463b-aa2e-fb789fbc97c4": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/3025d79b-f373-43d0-ab05-97a6384f0098",
            "firstName": "Tom J.",
            "lastName": "Dugger",
            "fullName": "Tom Dugger",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Tomdugger_0.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-OK-21-upper-ocd-person-3025d79b-f373-43d0-ab05-97a6384f0098",
              "builtID": "ok-upper-21",
              "externalID": "ocd-person/3025d79b-f373-43d0-ab05-97a6384f0098",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.dugger@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/dugger_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1848",
                "https://oksenate.gov/senators/tom-dugger"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-21-upper-ocd-person-3025d79b-f373-43d0-ab05-97a6384f0098": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/c768c730-142e-4582-a137-ee09a0240784",
            "firstName": "Kristen",
            "lastName": "Thompson",
            "fullName": "Kristen Thompson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Thompson%2C%20Kristen%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-OK-22-upper-ocd-person-c768c730-142e-4582-a137-ee09a0240784",
              "builtID": "ok-upper-22",
              "externalID": "ocd-person/c768c730-142e-4582-a137-ee09a0240784",
              "geometry": null
            },
            "contactInfo": {
              "email": "kristen.thompson@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/contact-senator?sid=1523285",
                "https://oksenate.gov/senators/kristen-thompson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-22-upper-ocd-person-c768c730-142e-4582-a137-ee09a0240784": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/fe64cfd8-d85e-472e-8761-27b6a3774971",
            "firstName": "Lonnie",
            "lastName": "Paxton",
            "fullName": "Lonnie Paxton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/paxton.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-OK-23-upper-ocd-person-fe64cfd8-d85e-472e-8761-27b6a3774971",
              "builtID": "ok-upper-23",
              "externalID": "ocd-person/fe64cfd8-d85e-472e-8761-27b6a3774971",
              "geometry": null
            },
            "contactInfo": {
              "email": "paxton@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/paxton_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1893",
                "https://oksenate.gov/senators/lonnie-paxton"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-23-upper-ocd-person-fe64cfd8-d85e-472e-8761-27b6a3774971": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/bea1fd3e-9a8e-4cd6-bdb8-abedd9f8a888",
            "firstName": "R. Darrell",
            "lastName": "Weaver",
            "fullName": "Darrell Weaver",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Weaver%2C%20Darrell_flags.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-OK-24-upper-ocd-person-bea1fd3e-9a8e-4cd6-bdb8-abedd9f8a888",
              "builtID": "ok-upper-24",
              "externalID": "ocd-person/bea1fd3e-9a8e-4cd6-bdb8-abedd9f8a888",
              "geometry": null
            },
            "contactInfo": {
              "email": "darrell.weaver@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/weaver_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1894",
                "https://oksenate.gov/senators/darrell-weaver"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-24-upper-ocd-person-bea1fd3e-9a8e-4cd6-bdb8-abedd9f8a888": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/fbdd88d6-2125-4a58-8474-8804127576f3",
            "firstName": "Joseph W.",
            "lastName": "Newhouse",
            "fullName": "Joe Newhouse",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Joe%20Newhouse%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-OK-25-upper-ocd-person-fbdd88d6-2125-4a58-8474-8804127576f3",
              "builtID": "ok-upper-25",
              "externalID": "ocd-person/fbdd88d6-2125-4a58-8474-8804127576f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.newhouse@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "10026-A S. Mingo Road Suite 163, Tulsa, OK 74133",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/newhouse_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1863",
                "https://oksenate.gov/senators/joe-newhouse"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-25-upper-ocd-person-fbdd88d6-2125-4a58-8474-8804127576f3": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/7143be5e-df9e-40ad-8591-dd4c9ea51261",
            "firstName": "Darcy Allen",
            "lastName": "Jech",
            "fullName": "Darcy Jech",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/1200px-DarcyJech_0.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-OK-26-upper-ocd-person-7143be5e-df9e-40ad-8591-dd4c9ea51261",
              "builtID": "ok-upper-26",
              "externalID": "ocd-person/7143be5e-df9e-40ad-8591-dd4c9ea51261",
              "geometry": null
            },
            "contactInfo": {
              "email": "darcy.jech@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1702 Harvey Brown Drive, Kingfisher, OK 73750",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/jech_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1855",
                "https://oksenate.gov/senators/darcy-jech"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-26-upper-ocd-person-7143be5e-df9e-40ad-8591-dd4c9ea51261": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/8c8517c3-75ee-4b27-8d8c-3444c036ba78",
            "firstName": "William Casey",
            "lastName": "Murdock",
            "fullName": "Casey Murdock",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/murdock.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-OK-27-upper-ocd-person-8c8517c3-75ee-4b27-8d8c-3444c036ba78",
              "builtID": "ok-upper-27",
              "externalID": "ocd-person/8c8517c3-75ee-4b27-8d8c-3444c036ba78",
              "geometry": null
            },
            "contactInfo": {
              "email": "murdock@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/murdock_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1862",
                "https://oksenate.gov/senators/casey-murdock"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-27-upper-ocd-person-8c8517c3-75ee-4b27-8d8c-3444c036ba78": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/9f0cacea-112a-425a-b212-4fa3cfd3b3a0",
            "firstName": "Grant",
            "lastName": "Green",
            "fullName": "Grant Green",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Green%2C%20Grant%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-OK-28-upper-ocd-person-9f0cacea-112a-425a-b212-4fa3cfd3b3a0",
              "builtID": "ok-upper-28",
              "externalID": "ocd-person/9f0cacea-112a-425a-b212-4fa3cfd3b3a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "grant.green@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/contact-senator?sid=1523286",
                "https://oksenate.gov/senators/grant-green"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-28-upper-ocd-person-9f0cacea-112a-425a-b212-4fa3cfd3b3a0": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/14ac3593-aa7a-491e-aba0-93474e93673d",
            "firstName": "Julie",
            "lastName": "Daniels",
            "fullName": "Julie Daniels",
            "gender": "Female",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Daniels%2C%20Julie%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-OK-29-upper-ocd-person-14ac3593-aa7a-491e-aba0-93474e93673d",
              "builtID": "ok-upper-29",
              "externalID": "ocd-person/14ac3593-aa7a-491e-aba0-93474e93673d",
              "geometry": null
            },
            "contactInfo": {
              "email": "julie.daniels@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2191 Kyle Road, Bartlesville, OK 74006",
              "phone1": null,
              "phone2": "918-331-7267",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/daniels_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1846",
                "https://oksenate.gov/senators/julie-daniels"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-29-upper-ocd-person-14ac3593-aa7a-491e-aba0-93474e93673d": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/986cbe76-2b10-4fc8-92b0-39432b761e1a",
            "firstName": "Julia",
            "lastName": "Kirt",
            "fullName": "Julia Kirt",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://oksenate.gov/sites/default/files/Kirt%2C%20Julia%202023%20Flags%20S.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-OK-30-upper-ocd-person-986cbe76-2b10-4fc8-92b0-39432b761e1a",
              "builtID": "ok-upper-30",
              "externalID": "ocd-person/986cbe76-2b10-4fc8-92b0-39432b761e1a",
              "geometry": null
            },
            "contactInfo": {
              "email": "julia.kirt@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/kirt_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1857",
                "https://oksenate.gov/senators/julia-kirt"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-30-upper-ocd-person-986cbe76-2b10-4fc8-92b0-39432b761e1a": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/f4a27c36-bfc5-4e2c-8ea4-233e924b6d77",
            "firstName": "Chris",
            "lastName": "Kidd",
            "fullName": "Chris Kidd",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Kidd.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-OK-31-upper-ocd-person-f4a27c36-bfc5-4e2c-8ea4-233e924b6d77",
              "builtID": "ok-upper-31",
              "externalID": "ocd-person/f4a27c36-bfc5-4e2c-8ea4-233e924b6d77",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.kidd@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/kidd_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1856",
                "https://oksenate.gov/senators/chris-kidd"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-31-upper-ocd-person-f4a27c36-bfc5-4e2c-8ea4-233e924b6d77": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/56666b37-2788-433a-9c32-19af826613e4",
            "firstName": "Dusty",
            "lastName": "Deevers",
            "fullName": "Dusty Deevers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Deevers%2C%20Dusty%202023%20Flags%20S_0.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-OK-32-upper-ocd-person-56666b37-2788-433a-9c32-19af826613e4",
              "builtID": "ok-upper-32",
              "externalID": "ocd-person/56666b37-2788-433a-9c32-19af826613e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "dusty.deevers@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/contact-senator?sid=1861",
                "https://oksenate.gov/senators/dustydeevers"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-32-upper-ocd-person-56666b37-2788-433a-9c32-19af826613e4": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/a6ab9bdd-5ddd-41e1-b9b2-0ff5a5da9747",
            "firstName": "Nathan Ryan",
            "lastName": "Dahm",
            "fullName": "Nathan Dahm",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Nathan-Dahm-1.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-OK-33-upper-ocd-person-a6ab9bdd-5ddd-41e1-b9b2-0ff5a5da9747",
              "builtID": "ok-upper-33",
              "externalID": "ocd-person/a6ab9bdd-5ddd-41e1-b9b2-0ff5a5da9747",
              "geometry": null
            },
            "contactInfo": {
              "email": "dahm@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/dahm_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1845",
                "https://oksenate.gov/senators/nathan-dahm"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-33-upper-ocd-person-a6ab9bdd-5ddd-41e1-b9b2-0ff5a5da9747": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/74fd77d2-ffa9-42cd-9849-0b64464bcc6f",
            "firstName": "Dana",
            "lastName": "Prieto",
            "fullName": "Dana Prieto",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Prieto%2C%20Dana%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-OK-34-upper-ocd-person-74fd77d2-ffa9-42cd-9849-0b64464bcc6f",
              "builtID": "ok-upper-34",
              "externalID": "ocd-person/74fd77d2-ffa9-42cd-9849-0b64464bcc6f",
              "geometry": null
            },
            "contactInfo": {
              "email": "dana.prieto@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/contact-senator?sid=1523287",
                "https://oksenate.gov/senators/dana-prieto"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-34-upper-ocd-person-74fd77d2-ffa9-42cd-9849-0b64464bcc6f": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/164b3ba9-47d4-4c24-870d-4e6679e15cf8",
            "firstName": "Jo Anna",
            "lastName": "Dossett",
            "fullName": "Jo Anna Dossett",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://oksenate.gov/sites/default/files/Dossett%2C%20Jo%20Anna_flags.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-OK-35-upper-ocd-person-164b3ba9-47d4-4c24-870d-4e6679e15cf8",
              "builtID": "ok-upper-35",
              "externalID": "ocd-person/164b3ba9-47d4-4c24-870d-4e6679e15cf8",
              "geometry": null
            },
            "contactInfo": {
              "email": "joanna.dossett@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/contact-senator?sid=1521376",
                "https://oksenate.gov/senators/jo-anna-dossett"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-35-upper-ocd-person-164b3ba9-47d4-4c24-870d-4e6679e15cf8": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/08dca8d5-4264-4509-aebd-bbf7736b9e90",
            "firstName": "John",
            "lastName": "Haste",
            "fullName": "John Haste",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Haste%2C%20John%202022%20Flags.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-OK-36-upper-ocd-person-08dca8d5-4264-4509-aebd-bbf7736b9e90",
              "builtID": "ok-upper-36",
              "externalID": "ocd-person/08dca8d5-4264-4509-aebd-bbf7736b9e90",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.haste@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/haste_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1851",
                "https://oksenate.gov/senators/john-haste"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-36-upper-ocd-person-08dca8d5-4264-4509-aebd-bbf7736b9e90": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/ee540967-ff83-42c4-af73-e6704d91a285",
            "firstName": "Cody",
            "lastName": "Rogers",
            "fullName": "Cody Rogers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Rogers%2C%20Cody_flags.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-OK-37-upper-ocd-person-ee540967-ff83-42c4-af73-e6704d91a285",
              "builtID": "ok-upper-37",
              "externalID": "ocd-person/ee540967-ff83-42c4-af73-e6704d91a285",
              "geometry": null
            },
            "contactInfo": {
              "email": "cody.rogers@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/contact-senator?sid=1521377",
                "https://oksenate.gov/senators/cody-rogers"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-37-upper-ocd-person-ee540967-ff83-42c4-af73-e6704d91a285": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/bc85e399-f4bc-4690-b34b-685e15242904",
            "firstName": "Brent",
            "lastName": "Howard",
            "fullName": "Brent Howard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Howard%2C%20Brent_flags.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-OK-38-upper-ocd-person-bc85e399-f4bc-4690-b34b-685e15242904",
              "builtID": "ok-upper-38",
              "externalID": "ocd-person/bc85e399-f4bc-4690-b34b-685e15242904",
              "geometry": null
            },
            "contactInfo": {
              "email": "brent.howard@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/howard_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1853",
                "https://oksenate.gov/senators/brent-howard"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-38-upper-ocd-person-bc85e399-f4bc-4690-b34b-685e15242904": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/3714523f-ea6d-4bd5-a801-1397dd508208",
            "firstName": "David",
            "lastName": "Rader",
            "fullName": "Dave Rader",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Rader%2C%20Dave%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-OK-39-upper-ocd-person-3714523f-ea6d-4bd5-a801-1397dd508208",
              "builtID": "ok-upper-39",
              "externalID": "ocd-person/3714523f-ea6d-4bd5-a801-1397dd508208",
              "geometry": null
            },
            "contactInfo": {
              "email": "rader@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/rader_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1898",
                "https://oksenate.gov/senators/dave-rader"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-39-upper-ocd-person-3714523f-ea6d-4bd5-a801-1397dd508208": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/6ecf8a12-033a-41da-9b5f-3fbcf41f4841",
            "firstName": "Carri",
            "lastName": "Hicks",
            "fullName": "Carri Hicks",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://oksenate.gov/sites/default/files/Hicks%2C%20Carri%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-OK-40-upper-ocd-person-6ecf8a12-033a-41da-9b5f-3fbcf41f4841",
              "builtID": "ok-upper-40",
              "externalID": "ocd-person/6ecf8a12-033a-41da-9b5f-3fbcf41f4841",
              "geometry": null
            },
            "contactInfo": {
              "email": "carri.hicks@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/hicks_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1852",
                "https://oksenate.gov/senators/carri-hicks"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-40-upper-ocd-person-6ecf8a12-033a-41da-9b5f-3fbcf41f4841": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/7e356581-ff96-4e5d-b6bc-94b3c8e0005c",
            "firstName": "Adam",
            "lastName": "Pugh",
            "fullName": "Adam Pugh",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/pugh.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-OK-41-upper-ocd-person-7e356581-ff96-4e5d-b6bc-94b3c8e0005c",
              "builtID": "ok-upper-41",
              "externalID": "ocd-person/7e356581-ff96-4e5d-b6bc-94b3c8e0005c",
              "geometry": null
            },
            "contactInfo": {
              "email": "pugh@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/pugh_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1899",
                "https://oksenate.gov/senators/adam-pugh"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-41-upper-ocd-person-7e356581-ff96-4e5d-b6bc-94b3c8e0005c": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/55b572a7-2951-4597-b70c-e37b51163b03",
            "firstName": "Brenda",
            "lastName": "Stanley",
            "fullName": "Brenda Stanley",
            "gender": "Female",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Brenda-Stanley_1.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-OK-42-upper-ocd-person-55b572a7-2951-4597-b70c-e37b51163b03",
              "builtID": "ok-upper-42",
              "externalID": "ocd-person/55b572a7-2951-4597-b70c-e37b51163b03",
              "geometry": null
            },
            "contactInfo": {
              "email": "brenda.stanley@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/stanley_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1900",
                "https://oksenate.gov/senators/brenda-stanley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-42-upper-ocd-person-55b572a7-2951-4597-b70c-e37b51163b03": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/3258a3c4-6d4a-410c-ae77-67f20af6f600",
            "firstName": "Jessica",
            "lastName": "Garvin",
            "fullName": "Jessica Garvin",
            "gender": "Female",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Garvin%2C%20Jessica_flags.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "43",
              "chamber": "upper",
              "hashID": "SLDU-OK-43-upper-ocd-person-3258a3c4-6d4a-410c-ae77-67f20af6f600",
              "builtID": "ok-upper-43",
              "externalID": "ocd-person/3258a3c4-6d4a-410c-ae77-67f20af6f600",
              "geometry": null
            },
            "contactInfo": {
              "email": "jessica.garvin@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/contact-senator?sid=1521378",
                "https://oksenate.gov/senators/jessica-garvin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-43-upper-ocd-person-3258a3c4-6d4a-410c-ae77-67f20af6f600": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/faabecda-dfa0-4995-b574-2b0a33b039ef",
            "firstName": "Michael Andrew",
            "lastName": "Brooks-Jimenez",
            "fullName": "Michael Brooks",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://oksenate.gov/sites/default/files/Brooks%2C%20Michael%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "44",
              "chamber": "upper",
              "hashID": "SLDU-OK-44-upper-ocd-person-faabecda-dfa0-4995-b574-2b0a33b039ef",
              "builtID": "ok-upper-44",
              "externalID": "ocd-person/faabecda-dfa0-4995-b574-2b0a33b039ef",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.brooks@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/brooks_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1842",
                "https://oksenate.gov/senators/michael-brooks"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-44-upper-ocd-person-faabecda-dfa0-4995-b574-2b0a33b039ef": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/0388a9a8-8de3-4620-8c44-e887e0f1cecd",
            "firstName": "Paul",
            "lastName": "Rosino",
            "fullName": "Paul Rosino",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/Rosino%2C%20Paul%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "45",
              "chamber": "upper",
              "hashID": "SLDU-OK-45-upper-ocd-person-0388a9a8-8de3-4620-8c44-e887e0f1cecd",
              "builtID": "ok-upper-45",
              "externalID": "ocd-person/0388a9a8-8de3-4620-8c44-e887e0f1cecd",
              "geometry": null
            },
            "contactInfo": {
              "email": "rosino@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/rosino_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1902",
                "https://oksenate.gov/senators/paul-rosino"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-45-upper-ocd-person-0388a9a8-8de3-4620-8c44-e887e0f1cecd": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/c98205ed-1cb0-4dac-8acb-0102a61ee8a4",
            "firstName": "P. Kay",
            "lastName": "Floyd",
            "fullName": "Kay Floyd",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://oksenate.gov/sites/default/files/Floyd%2C%20Kay%20Flags%202022.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "46",
              "chamber": "upper",
              "hashID": "SLDU-OK-46-upper-ocd-person-c98205ed-1cb0-4dac-8acb-0102a61ee8a4",
              "builtID": "ok-upper-46",
              "externalID": "ocd-person/c98205ed-1cb0-4dac-8acb-0102a61ee8a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "floyd@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/floyd_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1849",
                "https://oksenate.gov/senators/kay-floyd"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-46-upper-ocd-person-c98205ed-1cb0-4dac-8acb-0102a61ee8a4": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/45cbdac2-6de7-4ed0-ac46-076fa79e631b",
            "firstName": "Gregory L.",
            "lastName": "Treat",
            "fullName": "Greg Treat",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oksenate.gov/sites/default/files/SenatorTreat.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "47",
              "chamber": "upper",
              "hashID": "SLDU-OK-47-upper-ocd-person-45cbdac2-6de7-4ed0-ac46-076fa79e631b",
              "builtID": "ok-upper-47",
              "externalID": "ocd-person/45cbdac2-6de7-4ed0-ac46-076fa79e631b",
              "geometry": null
            },
            "contactInfo": {
              "email": "treat@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/treat_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1835",
                "https://oksenate.gov/senators/greg-treat"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-47-upper-ocd-person-45cbdac2-6de7-4ed0-ac46-076fa79e631b": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/fd37f2df-5e46-464d-800c-28f96162bda0",
            "firstName": "George E.",
            "lastName": "Young",
            "fullName": "George Young",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://oksenate.gov/sites/default/files/Young%2C%20George%20Flags2022%201.jpg",
            "title": "OK Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OK",
              "stateFull": "Oklahoma",
              "district": "48",
              "chamber": "upper",
              "hashID": "SLDU-OK-48-upper-ocd-person-fd37f2df-5e46-464d-800c-28f96162bda0",
              "builtID": "ok-upper-48",
              "externalID": "ocd-person/fd37f2df-5e46-464d-800c-28f96162bda0",
              "geometry": null
            },
            "contactInfo": {
              "email": "george.young@oksenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://oksenate.gov/Senators/biographies/young_bio.aspx",
                "https://oksenate.gov/contact-senator?sid=1864",
                "https://oksenate.gov/senators/george-young"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OK-48-upper-ocd-person-fd37f2df-5e46-464d-800c-28f96162bda0": 0
        }
      }
    }
  },
  "OR": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "OR",
          "stateFull": "Oregon",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "OR",
            "stateFull": "Oregon",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-OR---",
            "builtID": "or--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-OR---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "M001176",
          "in_office": true,
          "firstName": "Jeff",
          "lastName": "Merkley",
          "middleName": null,
          "fullName": "Jeff Merkley",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/M001176.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "OR",
            "stateFull": "Oregon",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-OR---M001176",
            "builtID": "or--",
            "externalID": "M001176",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.merkley.senate.gov/contact/",
            "address1": "531 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-3753",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenJeffMerkley",
            "youtube": "SenatorJeffMerkley",
            "instagram": null,
            "facebook": "jeffmerkley",
            "urls": ["https://www.merkley.senate.gov"],
            "rss_url": "https://www.merkley.senate.gov/rss/feeds/"
          },
          "title": "OR Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/M001176.json",
          "govtrack_id": "412325",
          "cspan_id": "1029842",
          "votesmart_id": "23644",
          "icpsr_id": "40908",
          "crp_id": "N00029303",
          "google_entity_id": "/m/026k60f",
          "state_rank": "junior",
          "lis_id": "S322",
          "suffix": null,
          "date_of_birth": "1956-10-24",
          "leadership_role": null,
          "fec_candidate_id": "S8OR00207",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "15",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 17,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 3.07,
          "votes_with_party_pct": 94.39,
          "votes_against_party_pct": 5.61,
          "ocd_id": "ocd-division/country:us/state:or"
        },
        {
          "API_ID": "W000779",
          "in_office": true,
          "firstName": "Ron",
          "lastName": "Wyden",
          "middleName": null,
          "fullName": "Ron Wyden",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/W000779.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "OR",
            "stateFull": "Oregon",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-OR---W000779",
            "builtID": "or--",
            "externalID": "W000779",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "RonWyden",
            "youtube": "senronwyden",
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.wyden.senate.gov"],
            "rss_url": null
          },
          "title": "OR Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/W000779.json",
          "govtrack_id": "300100",
          "cspan_id": "1962",
          "votesmart_id": "27036",
          "icpsr_id": "14871",
          "crp_id": "N00007724",
          "google_entity_id": "/m/0178p9",
          "state_rank": "senior",
          "lis_id": "S247",
          "suffix": null,
          "date_of_birth": "1949-05-03",
          "leadership_role": null,
          "fec_candidate_id": "S6OR00110",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "28",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 2,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 0.36,
          "votes_with_party_pct": 96.73,
          "votes_against_party_pct": 3.27,
          "ocd_id": "ocd-division/country:us/state:or"
        }
      ],
      "hash": { "SENATE-OR---M001176": 0, "SENATE-OR---W000779": 1 }
    },
    "HOUSE": {
      "02": {
        "members": [
          {
            "API_ID": "B000668",
            "in_office": true,
            "firstName": "Cliff",
            "lastName": "Bentz",
            "middleName": null,
            "fullName": "Cliff Bentz",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B000668.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-OR-02--B000668",
              "builtID": "or--02",
              "externalID": "B000668",
              "geometry": null,
              "geoid": "4102"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "409 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-6730",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBentz",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://bentz.house.gov"],
              "rss_url": null
            },
            "title": "OR House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B000668.json",
            "govtrack_id": "456842",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00045773",
            "google_entity_id": "/m/0gfdsjb",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1952-01-12",
            "leadership_role": null,
            "fec_candidate_id": "H0OR02127",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 5,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.47,
            "votes_with_party_pct": 94.18,
            "votes_against_party_pct": 3.88,
            "ocd_id": "ocd-division/country:us/state:or/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OR-02--B000668": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "B000574",
            "in_office": true,
            "firstName": "Earl",
            "lastName": "Blumenauer",
            "middleName": null,
            "fullName": "Earl Blumenauer",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/B000574.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-OR-03--B000574",
              "builtID": "or--03",
              "externalID": "B000574",
              "geometry": null,
              "geoid": "4103"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1111 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4811",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "BlumenauerMedia",
              "youtube": "RepBlumenauer",
              "instagram": null,
              "facebook": null,
              "urls": ["https://blumenauer.house.gov"],
              "rss_url": "https://blumenauer.house.gov/rss.xml"
            },
            "title": "OR House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B000574.json",
            "govtrack_id": "400033",
            "cspan_id": "43809",
            "votesmart_id": "367",
            "icpsr_id": "29588",
            "crp_id": "N00007727",
            "google_entity_id": "/m/033tw8",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1948-08-16",
            "leadership_role": null,
            "fec_candidate_id": "H6OR03064",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "30",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 90,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 8.53,
            "votes_with_party_pct": 94.35,
            "votes_against_party_pct": 3.66,
            "ocd_id": "ocd-division/country:us/state:or/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OR-03--B000574": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "B001278",
            "in_office": true,
            "firstName": "Suzanne",
            "lastName": "Bonamici",
            "middleName": null,
            "fullName": "Suzanne Bonamici",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/B001278.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-OR-01--B001278",
              "builtID": "or--01",
              "externalID": "B001278",
              "geometry": null,
              "geoid": "4101"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2231 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-0855",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBonamici",
              "youtube": "RepSuzanneBonamici",
              "instagram": null,
              "facebook": "congresswomanbonamici",
              "urls": ["https://bonamici.house.gov"],
              "rss_url": "https://bonamici.house.gov/rss.xml"
            },
            "title": "OR House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001278.json",
            "govtrack_id": "412501",
            "cspan_id": "63966",
            "votesmart_id": "59641",
            "icpsr_id": "21198",
            "crp_id": "N00033474",
            "google_entity_id": "/m/02q453x",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1954-10-14",
            "leadership_role": null,
            "fec_candidate_id": "H2OR01133",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 65,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 6.16,
            "votes_with_party_pct": 94.49,
            "votes_against_party_pct": 3.57,
            "ocd_id": "ocd-division/country:us/state:or/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OR-01--B001278": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "C001135",
            "in_office": true,
            "firstName": "Lori",
            "lastName": "Chavez-DeRemer",
            "middleName": null,
            "fullName": "Lori Chavez-DeRemer",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001135.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-OR-05--C001135",
              "builtID": "or--05",
              "externalID": "C001135",
              "geometry": null,
              "geoid": "4105"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1722 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5711",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLCD",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://chavez-deremer.house.gov/"],
              "rss_url": null
            },
            "title": "OR House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001135.json",
            "govtrack_id": "456933",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1968-04-07",
            "leadership_role": null,
            "fec_candidate_id": "H2OR05209",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 3,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.28,
            "votes_with_party_pct": 78.78,
            "votes_against_party_pct": 19.28,
            "ocd_id": "ocd-division/country:us/state:or/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OR-05--C001135": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "H001094",
            "in_office": true,
            "firstName": "Valerie",
            "lastName": "Hoyle",
            "middleName": null,
            "fullName": "Val T. Hoyle",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/H001094.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-OR-04--H001094",
              "builtID": "or--04",
              "externalID": "H001094",
              "geometry": null,
              "geoid": "4104"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1620 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-6416",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repvalhoyle",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://hoyle.house.gov/"],
              "rss_url": null
            },
            "title": "OR House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001094.json",
            "govtrack_id": "456932",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1964-02-14",
            "leadership_role": null,
            "fec_candidate_id": "H2OR04095",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 27,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.56,
            "votes_with_party_pct": 94.31,
            "votes_against_party_pct": 3.73,
            "ocd_id": "ocd-division/country:us/state:or/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OR-04--H001094": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "S001226",
            "in_office": true,
            "firstName": "Andrea",
            "lastName": "Salinas",
            "middleName": null,
            "fullName": "Andrea Salinas",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001226.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-OR-06--S001226",
              "builtID": "or--06",
              "externalID": "S001226",
              "geometry": null,
              "geoid": "4106"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "109 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5643",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://salinas.house.gov/"],
              "rss_url": null
            },
            "title": "OR House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001226.json",
            "govtrack_id": "456934",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1969-12-06",
            "leadership_role": null,
            "fec_candidate_id": "H2OR06066",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 11,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.04,
            "votes_with_party_pct": 93.91,
            "votes_against_party_pct": 4.15,
            "ocd_id": "ocd-division/country:us/state:or/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-OR-06--S001226": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/aa0eac5e-9089-4c72-8edb-4493e1c4a0ad",
            "firstName": "Court Allen",
            "lastName": "Boice",
            "fullName": "Court Boice",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/boice/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-OR-1-lower-ocd-person-aa0eac5e-9089-4c72-8edb-4493e1c4a0ad",
              "builtID": "or-lower-1",
              "externalID": "ocd-person/aa0eac5e-9089-4c72-8edb-4493e1c4a0ad",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.courtboice@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/boice"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-1-lower-ocd-person-aa0eac5e-9089-4c72-8edb-4493e1c4a0ad": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/f5938046-4558-441b-9e63-6bff0e114bf4",
            "firstName": "Virgle",
            "lastName": "Osborne",
            "fullName": "Virgle Osborne",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/osborne/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-OR-2-lower-ocd-person-f5938046-4558-441b-9e63-6bff0e114bf4",
              "builtID": "or-lower-2",
              "externalID": "ocd-person/f5938046-4558-441b-9e63-6bff0e114bf4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.virgleosborne@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/osborne"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-2-lower-ocd-person-f5938046-4558-441b-9e63-6bff0e114bf4": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/497f7e2e-b1d8-4803-8fb8-22dc045bc0b0",
            "firstName": "Dwayne",
            "lastName": "Yunker",
            "fullName": "Dwayne Yunker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/yunker/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-OR-3-lower-ocd-person-497f7e2e-b1d8-4803-8fb8-22dc045bc0b0",
              "builtID": "or-lower-3",
              "externalID": "ocd-person/497f7e2e-b1d8-4803-8fb8-22dc045bc0b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.dwayneyunker@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/yunker"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-3-lower-ocd-person-497f7e2e-b1d8-4803-8fb8-22dc045bc0b0": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/87faee77-0559-48da-800f-6d417b15d927",
            "firstName": "Christine",
            "lastName": "Goodwin",
            "fullName": "Christine Goodwin",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/goodwin/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-OR-4-lower-ocd-person-87faee77-0559-48da-800f-6d417b15d927",
              "builtID": "or-lower-4",
              "externalID": "ocd-person/87faee77-0559-48da-800f-6d417b15d927",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.christinegoodwin@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/goodwin"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-4-lower-ocd-person-87faee77-0559-48da-800f-6d417b15d927": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/ec643441-bffe-4f43-80a4-ab834ae0f1bd",
            "firstName": "Pam",
            "lastName": "Marsh",
            "fullName": "Pam Marsh",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/marsh/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-OR-5-lower-ocd-person-ec643441-bffe-4f43-80a4-ab834ae0f1bd",
              "builtID": "or-lower-5",
              "externalID": "ocd-person/ec643441-bffe-4f43-80a4-ab834ae0f1bd",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.pammarsh@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/marsh"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-5-lower-ocd-person-ec643441-bffe-4f43-80a4-ab834ae0f1bd": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/a2e34475-ba6f-41af-bf61-83c7160d6c10",
            "firstName": "Kimberly King",
            "lastName": "Wallan",
            "fullName": "Kim Wallan",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/wallan/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-OR-6-lower-ocd-person-a2e34475-ba6f-41af-bf61-83c7160d6c10",
              "builtID": "or-lower-6",
              "externalID": "ocd-person/a2e34475-ba6f-41af-bf61-83c7160d6c10",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kimwallan@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/wallan"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-6-lower-ocd-person-a2e34475-ba6f-41af-bf61-83c7160d6c10": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/13afc78a-1028-462e-8f98-6df3f7f028d5",
            "firstName": "John D.",
            "lastName": "Lively",
            "fullName": "John Lively",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/lively/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-OR-7-lower-ocd-person-13afc78a-1028-462e-8f98-6df3f7f028d5",
              "builtID": "or-lower-7",
              "externalID": "ocd-person/13afc78a-1028-462e-8f98-6df3f7f028d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.johnlively@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/lively"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-7-lower-ocd-person-13afc78a-1028-462e-8f98-6df3f7f028d5": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/3a239fdb-e398-452a-bffc-f824743a496f",
            "firstName": "Paul",
            "lastName": "Holvey",
            "fullName": "Paul Holvey",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/holvey/PublishingImages/PaulHolveyHeadshot.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-OR-8-lower-ocd-person-3a239fdb-e398-452a-bffc-f824743a496f",
              "builtID": "or-lower-8",
              "externalID": "ocd-person/3a239fdb-e398-452a-bffc-f824743a496f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.paulholvey@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/holvey"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-8-lower-ocd-person-3a239fdb-e398-452a-bffc-f824743a496f": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/a95c31b3-9bfa-4ab1-93d1-278082ead92a",
            "firstName": "Boomer",
            "lastName": "Wright",
            "fullName": "Boomer Wright",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/wright/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-OR-9-lower-ocd-person-a95c31b3-9bfa-4ab1-93d1-278082ead92a",
              "builtID": "or-lower-9",
              "externalID": "ocd-person/a95c31b3-9bfa-4ab1-93d1-278082ead92a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.boomerwright@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/wright"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-9-lower-ocd-person-a95c31b3-9bfa-4ab1-93d1-278082ead92a": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/db52dfee-b099-4ec9-800c-075d09710ca6",
            "firstName": "David",
            "lastName": "Gomberg",
            "fullName": "David Gomberg",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/gomberg/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-OR-10-lower-ocd-person-db52dfee-b099-4ec9-800c-075d09710ca6",
              "builtID": "or-lower-10",
              "externalID": "ocd-person/db52dfee-b099-4ec9-800c-075d09710ca6",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.davidgomberg@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/gomberg"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-10-lower-ocd-person-db52dfee-b099-4ec9-800c-075d09710ca6": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/e0ac3b1f-565d-4766-b83d-3f08d6c0116b",
            "firstName": "Jami",
            "lastName": "Cate",
            "fullName": "Jami Cate",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/cate/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-OR-11-lower-ocd-person-e0ac3b1f-565d-4766-b83d-3f08d6c0116b",
              "builtID": "or-lower-11",
              "externalID": "ocd-person/e0ac3b1f-565d-4766-b83d-3f08d6c0116b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jamicate@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/cate"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-11-lower-ocd-person-e0ac3b1f-565d-4766-b83d-3f08d6c0116b": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/034a9941-0306-49c7-897a-e85cdc60c192",
            "firstName": "Charlie",
            "lastName": "Conrad",
            "fullName": "Charlie Conrad",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/conrad/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-OR-12-lower-ocd-person-034a9941-0306-49c7-897a-e85cdc60c192",
              "builtID": "or-lower-12",
              "externalID": "ocd-person/034a9941-0306-49c7-897a-e85cdc60c192",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.charlieconrad@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/conrad"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-12-lower-ocd-person-034a9941-0306-49c7-897a-e85cdc60c192": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/dd5774a0-d6fe-44cc-86b3-b197225666eb",
            "firstName": "Nancy",
            "lastName": "Nathanson",
            "fullName": "Nancy Nathanson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/nathanson/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-OR-13-lower-ocd-person-dd5774a0-d6fe-44cc-86b3-b197225666eb",
              "builtID": "or-lower-13",
              "externalID": "ocd-person/dd5774a0-d6fe-44cc-86b3-b197225666eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.nancynathanson@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/nathanson"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-13-lower-ocd-person-dd5774a0-d6fe-44cc-86b3-b197225666eb": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/b523dd9a-d913-4a8e-95e2-c546016f2b1a",
            "firstName": "Julie",
            "lastName": "Fahey",
            "fullName": "Julie Fahey",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/fahey/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-OR-14-lower-ocd-person-b523dd9a-d913-4a8e-95e2-c546016f2b1a",
              "builtID": "or-lower-14",
              "externalID": "ocd-person/b523dd9a-d913-4a8e-95e2-c546016f2b1a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.juliefahey@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/fahey"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-14-lower-ocd-person-b523dd9a-d913-4a8e-95e2-c546016f2b1a": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/52af68c5-c141-4e35-912b-11d71b83af33",
            "firstName": "Shelly Boshart",
            "lastName": "Davis",
            "fullName": "Shelly Davis",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/boshartdavis/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-OR-15-lower-ocd-person-52af68c5-c141-4e35-912b-11d71b83af33",
              "builtID": "or-lower-15",
              "externalID": "ocd-person/52af68c5-c141-4e35-912b-11d71b83af33",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.shellyboshartdavis@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/boshartdavis"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-15-lower-ocd-person-52af68c5-c141-4e35-912b-11d71b83af33": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/201cf42b-d036-4582-a6ff-f23b3ab3f442",
            "firstName": "Daniel A.",
            "lastName": "Rayfield",
            "fullName": "Dan Rayfield",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/rayfield/PublishingImages/Dan.Rayfield.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-OR-16-lower-ocd-person-201cf42b-d036-4582-a6ff-f23b3ab3f442",
              "builtID": "or-lower-16",
              "externalID": "ocd-person/201cf42b-d036-4582-a6ff-f23b3ab3f442",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.danrayfield@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/rayfield"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-16-lower-ocd-person-201cf42b-d036-4582-a6ff-f23b3ab3f442": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/fa290a38-0cea-4dc8-a981-33ee4ff341f4",
            "firstName": "Edwin L.",
            "lastName": "Diehl",
            "fullName": "Ed Diehl",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/diehl/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-OR-17-lower-ocd-person-fa290a38-0cea-4dc8-a981-33ee4ff341f4",
              "builtID": "or-lower-17",
              "externalID": "ocd-person/fa290a38-0cea-4dc8-a981-33ee4ff341f4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.eddiehl@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/diehl"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-17-lower-ocd-person-fa290a38-0cea-4dc8-a981-33ee4ff341f4": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/dc371425-a173-439f-903c-6e2e3fd145cd",
            "firstName": "Rick",
            "lastName": "Lewis",
            "fullName": "Rick Lewis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/lewis/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-OR-18-lower-ocd-person-dc371425-a173-439f-903c-6e2e3fd145cd",
              "builtID": "or-lower-18",
              "externalID": "ocd-person/dc371425-a173-439f-903c-6e2e3fd145cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ricklewis@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/lewis"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-18-lower-ocd-person-dc371425-a173-439f-903c-6e2e3fd145cd": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/4d850bbf-df69-4431-bad6-9444c63d4f0c",
            "firstName": "Tom",
            "lastName": "Andersen",
            "fullName": "Tom Andersen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/andersen/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-OR-19-lower-ocd-person-4d850bbf-df69-4431-bad6-9444c63d4f0c",
              "builtID": "or-lower-19",
              "externalID": "ocd-person/4d850bbf-df69-4431-bad6-9444c63d4f0c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.tomandersen@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/andersen"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-19-lower-ocd-person-4d850bbf-df69-4431-bad6-9444c63d4f0c": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/8334f7e1-d712-44b4-8570-d0d0a6d131d3",
            "firstName": "Paul",
            "lastName": "Evans",
            "fullName": "Paul Evans",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/evans/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-OR-20-lower-ocd-person-8334f7e1-d712-44b4-8570-d0d0a6d131d3",
              "builtID": "or-lower-20",
              "externalID": "ocd-person/8334f7e1-d712-44b4-8570-d0d0a6d131d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.paulevans@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/evans"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-20-lower-ocd-person-8334f7e1-d712-44b4-8570-d0d0a6d131d3": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/af99b8a9-452f-44e0-8404-83dfe05dbfd7",
            "firstName": "Kevin Leese",
            "lastName": "Mannix",
            "fullName": "Kevin Mannix",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/mannix/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-OR-21-lower-ocd-person-af99b8a9-452f-44e0-8404-83dfe05dbfd7",
              "builtID": "or-lower-21",
              "externalID": "ocd-person/af99b8a9-452f-44e0-8404-83dfe05dbfd7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kevinmannix@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/mannix"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-21-lower-ocd-person-af99b8a9-452f-44e0-8404-83dfe05dbfd7": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/d75108e0-8e10-4a2b-8232-be657513e7d5",
            "firstName": "Tracy",
            "lastName": "Cramer",
            "fullName": "Tracy Cramer",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/cramer/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-OR-22-lower-ocd-person-d75108e0-8e10-4a2b-8232-be657513e7d5",
              "builtID": "or-lower-22",
              "externalID": "ocd-person/d75108e0-8e10-4a2b-8232-be657513e7d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.tracycramer@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/cramer"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-22-lower-ocd-person-d75108e0-8e10-4a2b-8232-be657513e7d5": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/a22ec130-3ba7-4304-9e6f-3a950b8454cb",
            "firstName": "Anna",
            "lastName": "Scharf",
            "fullName": "Anna Scharf",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/scharf/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-OR-23-lower-ocd-person-a22ec130-3ba7-4304-9e6f-3a950b8454cb",
              "builtID": "or-lower-23",
              "externalID": "ocd-person/a22ec130-3ba7-4304-9e6f-3a950b8454cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.annascharf@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/scharf"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-23-lower-ocd-person-a22ec130-3ba7-4304-9e6f-3a950b8454cb": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/20f31b04-63fd-45a8-8075-5e6e1beae485",
            "firstName": "Lucetta",
            "lastName": "Elmer",
            "fullName": "Lucetta Elmer",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/elmer/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-OR-24-lower-ocd-person-20f31b04-63fd-45a8-8075-5e6e1beae485",
              "builtID": "or-lower-24",
              "externalID": "ocd-person/20f31b04-63fd-45a8-8075-5e6e1beae485",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.lucettaelmer@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/elmer"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-24-lower-ocd-person-20f31b04-63fd-45a8-8075-5e6e1beae485": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/a19e8e1f-4859-4d05-8dc7-6f0a352c0757",
            "firstName": "Benjamin",
            "lastName": "Bowman",
            "fullName": "Ben Bowman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/bowman/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-OR-25-lower-ocd-person-a19e8e1f-4859-4d05-8dc7-6f0a352c0757",
              "builtID": "or-lower-25",
              "externalID": "ocd-person/a19e8e1f-4859-4d05-8dc7-6f0a352c0757",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.benbowman@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/bowman"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-25-lower-ocd-person-a19e8e1f-4859-4d05-8dc7-6f0a352c0757": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/2bf5a8ca-cadb-4544-89e2-749f13673599",
            "firstName": "Courtney",
            "lastName": "Neron",
            "fullName": "Courtney Neron",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/neron/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-OR-26-lower-ocd-person-2bf5a8ca-cadb-4544-89e2-749f13673599",
              "builtID": "or-lower-26",
              "externalID": "ocd-person/2bf5a8ca-cadb-4544-89e2-749f13673599",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.courtneyneron@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/neron"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-26-lower-ocd-person-2bf5a8ca-cadb-4544-89e2-749f13673599": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/3770e09c-3492-4388-8047-e3dc83f026f3",
            "firstName": "Ken",
            "lastName": "Helm",
            "fullName": "Ken Helm",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/helm/PublishingImages/HD18-784-Edit.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-OR-27-lower-ocd-person-3770e09c-3492-4388-8047-e3dc83f026f3",
              "builtID": "or-lower-27",
              "externalID": "ocd-person/3770e09c-3492-4388-8047-e3dc83f026f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kenhelm@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/helm"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-27-lower-ocd-person-3770e09c-3492-4388-8047-e3dc83f026f3": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/f08a3b2f-8e56-41e4-bf5f-84a43fd5aab5",
            "firstName": "Dacia",
            "lastName": "Grayber",
            "fullName": "Dacia Grayber",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/grayber/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-OR-28-lower-ocd-person-f08a3b2f-8e56-41e4-bf5f-84a43fd5aab5",
              "builtID": "or-lower-28",
              "externalID": "ocd-person/f08a3b2f-8e56-41e4-bf5f-84a43fd5aab5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.daciagrayber@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/grayber"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-28-lower-ocd-person-f08a3b2f-8e56-41e4-bf5f-84a43fd5aab5": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/62f98553-cc00-497f-ac14-97e0fc0e62c2",
            "firstName": "Susan",
            "lastName": "McLain",
            "fullName": "Susan McLain",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/mclain/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-OR-29-lower-ocd-person-62f98553-cc00-497f-ac14-97e0fc0e62c2",
              "builtID": "or-lower-29",
              "externalID": "ocd-person/62f98553-cc00-497f-ac14-97e0fc0e62c2",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.susanmclain@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/mclain"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-29-lower-ocd-person-62f98553-cc00-497f-ac14-97e0fc0e62c2": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/5615af6a-5232-45e0-b85b-bcb41e08391e",
            "firstName": "Nathan",
            "lastName": "Sosa",
            "fullName": "Nathan Sosa",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/sosa/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-OR-30-lower-ocd-person-5615af6a-5232-45e0-b85b-bcb41e08391e",
              "builtID": "or-lower-30",
              "externalID": "ocd-person/5615af6a-5232-45e0-b85b-bcb41e08391e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.nathansosa@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/sosa"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-30-lower-ocd-person-5615af6a-5232-45e0-b85b-bcb41e08391e": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/152e1e16-3ba9-447d-8471-61548c8778f8",
            "firstName": "Brian G.",
            "lastName": "Stout",
            "fullName": "Brian Stout",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/stout/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-OR-31-lower-ocd-person-152e1e16-3ba9-447d-8471-61548c8778f8",
              "builtID": "or-lower-31",
              "externalID": "ocd-person/152e1e16-3ba9-447d-8471-61548c8778f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.brianstout@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/stout"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-31-lower-ocd-person-152e1e16-3ba9-447d-8471-61548c8778f8": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/1b0e1c41-4914-4f3d-8a49-fd11a019526a",
            "firstName": "Cyrus",
            "lastName": "Javadi",
            "fullName": "Cyrus Javadi",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/javadi/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-OR-32-lower-ocd-person-1b0e1c41-4914-4f3d-8a49-fd11a019526a",
              "builtID": "or-lower-32",
              "externalID": "ocd-person/1b0e1c41-4914-4f3d-8a49-fd11a019526a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.cyrusjavadi@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/javadi"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-32-lower-ocd-person-1b0e1c41-4914-4f3d-8a49-fd11a019526a": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/99b3fef1-8899-45a9-bdcd-bd8d2ae54268",
            "firstName": "Maxine Elizabeth",
            "lastName": "Dexter",
            "fullName": "Maxine Dexter",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/dexter/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-OR-33-lower-ocd-person-99b3fef1-8899-45a9-bdcd-bd8d2ae54268",
              "builtID": "or-lower-33",
              "externalID": "ocd-person/99b3fef1-8899-45a9-bdcd-bd8d2ae54268",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.maxinedexter@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/dexter"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-33-lower-ocd-person-99b3fef1-8899-45a9-bdcd-bd8d2ae54268": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/53afaba4-8ddc-4784-87c3-aaede576ac44",
            "firstName": "Lisa",
            "lastName": "Reynolds",
            "fullName": "Lisa Reynolds",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/reynolds/PublishingImages/Pages/default/Lisa%20Reynolds%20-%20%20Headshot.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-OR-34-lower-ocd-person-53afaba4-8ddc-4784-87c3-aaede576ac44",
              "builtID": "or-lower-34",
              "externalID": "ocd-person/53afaba4-8ddc-4784-87c3-aaede576ac44",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.lisareynolds@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/reynolds"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-34-lower-ocd-person-53afaba4-8ddc-4784-87c3-aaede576ac44": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/bec9a2c9-a77c-4ac9-b649-2a7360a5c0ad",
            "firstName": "Farrah",
            "lastName": "Chaichi",
            "fullName": "Farrah Chaichi",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://www.oregonlegislature.gov/chaichi/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-OR-35-lower-ocd-person-bec9a2c9-a77c-4ac9-b649-2a7360a5c0ad",
              "builtID": "or-lower-35",
              "externalID": "ocd-person/bec9a2c9-a77c-4ac9-b649-2a7360a5c0ad",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.farrahchaichi@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/chaichi"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-35-lower-ocd-person-bec9a2c9-a77c-4ac9-b649-2a7360a5c0ad": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/a13db125-e6e2-4621-a1a7-7de714796b90",
            "firstName": "Hai",
            "lastName": "Pham",
            "fullName": "Hai Pham",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/phamh/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-OR-36-lower-ocd-person-a13db125-e6e2-4621-a1a7-7de714796b90",
              "builtID": "or-lower-36",
              "externalID": "ocd-person/a13db125-e6e2-4621-a1a7-7de714796b90",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.haipham@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/phamh"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-36-lower-ocd-person-a13db125-e6e2-4621-a1a7-7de714796b90": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/55ecfa8d-e89f-4a7b-af2d-6822f1025607",
            "firstName": "Jules",
            "lastName": "Walters",
            "fullName": "Jules Walters",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/house/MemberPhotos/walters.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-OR-37-lower-ocd-person-55ecfa8d-e89f-4a7b-af2d-6822f1025607",
              "builtID": "or-lower-37",
              "externalID": "ocd-person/55ecfa8d-e89f-4a7b-af2d-6822f1025607",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.juleswalters@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/walters"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-37-lower-ocd-person-55ecfa8d-e89f-4a7b-af2d-6822f1025607": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/4b30682c-7833-4375-815b-2412cb9c61c7",
            "firstName": "Daniel",
            "lastName": "Nguyen",
            "fullName": "Daniel Nguyen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/nguyend/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-OR-38-lower-ocd-person-4b30682c-7833-4375-815b-2412cb9c61c7",
              "builtID": "or-lower-38",
              "externalID": "ocd-person/4b30682c-7833-4375-815b-2412cb9c61c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.danielnguyen@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/nguyend"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-38-lower-ocd-person-4b30682c-7833-4375-815b-2412cb9c61c7": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/d4add60f-a052-4d57-aa04-c8dc54964898",
            "firstName": "Janelle",
            "lastName": "Bynum",
            "fullName": "Janelle Bynum",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/bynum/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-OR-39-lower-ocd-person-d4add60f-a052-4d57-aa04-c8dc54964898",
              "builtID": "or-lower-39",
              "externalID": "ocd-person/d4add60f-a052-4d57-aa04-c8dc54964898",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.janellebynum@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/bynum"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-39-lower-ocd-person-d4add60f-a052-4d57-aa04-c8dc54964898": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/7a36fd6a-105a-44b3-b7e3-b3447979db49",
            "firstName": "Annessa",
            "lastName": "Hartman",
            "fullName": "Annessa Hartman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.clackamas.us/sites/default/files/2021-12/annessa_hartman.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-OR-40-lower-ocd-person-7a36fd6a-105a-44b3-b7e3-b3447979db49",
              "builtID": "or-lower-40",
              "externalID": "ocd-person/7a36fd6a-105a-44b3-b7e3-b3447979db49",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.annessahartman@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/hartman"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-40-lower-ocd-person-7a36fd6a-105a-44b3-b7e3-b3447979db49": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/6adb2361-6526-450f-b2b1-e9f32311ca7d",
            "firstName": "Mark",
            "lastName": "Gamba",
            "fullName": "Mark Gamba",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/gamba/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-OR-41-lower-ocd-person-6adb2361-6526-450f-b2b1-e9f32311ca7d",
              "builtID": "or-lower-41",
              "externalID": "ocd-person/6adb2361-6526-450f-b2b1-e9f32311ca7d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.markgamba@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/gamba"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-41-lower-ocd-person-6adb2361-6526-450f-b2b1-e9f32311ca7d": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/2aa7ebf4-d8a7-42c6-8e20-1edef58aa545",
            "firstName": "Robert",
            "lastName": "Nosse",
            "fullName": "Rob Nosse",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.oregonlegislature.gov/house/MemberPhotos/nosse.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-OR-42-lower-ocd-person-2aa7ebf4-d8a7-42c6-8e20-1edef58aa545",
              "builtID": "or-lower-42",
              "externalID": "ocd-person/2aa7ebf4-d8a7-42c6-8e20-1edef58aa545",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.robnosse@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/nosse"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-42-lower-ocd-person-2aa7ebf4-d8a7-42c6-8e20-1edef58aa545": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/c138ec96-0a6f-4dee-be06-be286b68cfbb",
            "firstName": "Tawna D.",
            "lastName": "Sanchez",
            "fullName": "Tawna Sanchez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/sanchez/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-OR-43-lower-ocd-person-c138ec96-0a6f-4dee-be06-be286b68cfbb",
              "builtID": "or-lower-43",
              "externalID": "ocd-person/c138ec96-0a6f-4dee-be06-be286b68cfbb",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.tawnasanchez@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/sanchez"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-43-lower-ocd-person-c138ec96-0a6f-4dee-be06-be286b68cfbb": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/e2a96554-b503-4aad-856c-84266386e957",
            "firstName": "Travis E.",
            "lastName": "Nelson",
            "fullName": "Travis Nelson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/nelson/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-OR-44-lower-ocd-person-e2a96554-b503-4aad-856c-84266386e957",
              "builtID": "or-lower-44",
              "externalID": "ocd-person/e2a96554-b503-4aad-856c-84266386e957",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.travisnelson@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/nelson"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-44-lower-ocd-person-e2a96554-b503-4aad-856c-84266386e957": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/52058824-2f35-4f6b-877f-d48301bd99a0",
            "firstName": "Thuy",
            "lastName": "Tran",
            "fullName": "Thuy Tran",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/tran/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-OR-45-lower-ocd-person-52058824-2f35-4f6b-877f-d48301bd99a0",
              "builtID": "or-lower-45",
              "externalID": "ocd-person/52058824-2f35-4f6b-877f-d48301bd99a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.thuytran@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/tran"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-45-lower-ocd-person-52058824-2f35-4f6b-877f-d48301bd99a0": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/3b7edee5-fb20-48d2-98db-541721e24e65",
            "firstName": "Khanh",
            "lastName": "Pham",
            "fullName": "Khanh Pham",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/pham/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-OR-46-lower-ocd-person-3b7edee5-fb20-48d2-98db-541721e24e65",
              "builtID": "or-lower-46",
              "externalID": "ocd-person/3b7edee5-fb20-48d2-98db-541721e24e65",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.khanhpham@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/pham"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-46-lower-ocd-person-3b7edee5-fb20-48d2-98db-541721e24e65": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/3efec526-6660-45fa-9a10-b02716b2a779",
            "firstName": "Andrea",
            "lastName": "Valderrama",
            "fullName": "Andrea Valderrama",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/valderrama/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-OR-47-lower-ocd-person-3efec526-6660-45fa-9a10-b02716b2a779",
              "builtID": "or-lower-47",
              "externalID": "ocd-person/3efec526-6660-45fa-9a10-b02716b2a779",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.andreavalderrama@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.oregonlegislature.gov/valderrama/Pages/map.aspx",
                "https://www.oregonlegislature.gov/valderrama"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-47-lower-ocd-person-3efec526-6660-45fa-9a10-b02716b2a779": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/fb69d630-1f0b-49e1-999b-0aefb2bb502f",
            "firstName": "Hoa H.",
            "lastName": "Nguyen",
            "fullName": "Hoa Nguyen",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/nguyen/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-OR-48-lower-ocd-person-fb69d630-1f0b-49e1-999b-0aefb2bb502f",
              "builtID": "or-lower-48",
              "externalID": "ocd-person/fb69d630-1f0b-49e1-999b-0aefb2bb502f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.hoanguyen@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/nguyen"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-48-lower-ocd-person-fb69d630-1f0b-49e1-999b-0aefb2bb502f": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/83bbae41-6ba0-477e-a584-8ad7df1283d8",
            "firstName": "Zach",
            "lastName": "Hudson",
            "fullName": "Zach Hudson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/hudson/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-OR-49-lower-ocd-person-83bbae41-6ba0-477e-a584-8ad7df1283d8",
              "builtID": "or-lower-49",
              "externalID": "ocd-person/83bbae41-6ba0-477e-a584-8ad7df1283d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.zachhudson@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/hudson"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-49-lower-ocd-person-83bbae41-6ba0-477e-a584-8ad7df1283d8": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/fce6eed7-4438-4f31-b47a-ee054c28d4f7",
            "firstName": "Ricardo",
            "lastName": "Ruiz",
            "fullName": "Ricki Ruiz",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/RickiRuiz.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-OR-50-lower-ocd-person-fce6eed7-4438-4f31-b47a-ee054c28d4f7",
              "builtID": "or-lower-50",
              "externalID": "ocd-person/fce6eed7-4438-4f31-b47a-ee054c28d4f7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.rickiruiz@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/ruiz"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-50-lower-ocd-person-fce6eed7-4438-4f31-b47a-ee054c28d4f7": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/d97661f8-25e9-4b1d-8ad6-25e26ea802c4",
            "firstName": "James",
            "lastName": "Hieb",
            "fullName": "James Hieb",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/hieb/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-OR-51-lower-ocd-person-d97661f8-25e9-4b1d-8ad6-25e26ea802c4",
              "builtID": "or-lower-51",
              "externalID": "ocd-person/d97661f8-25e9-4b1d-8ad6-25e26ea802c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jameshieb@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/hieb"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-51-lower-ocd-person-d97661f8-25e9-4b1d-8ad6-25e26ea802c4": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/936e2bdf-97c6-4297-80e7-faef3bb2e3d7",
            "firstName": "Jeffrey A.",
            "lastName": "Helfrich",
            "fullName": "Jeff Helfrich",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api/storage/uploads/thumbs/200/300/crop/best/Jeffrey_Helfrich.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-OR-52-lower-ocd-person-936e2bdf-97c6-4297-80e7-faef3bb2e3d7",
              "builtID": "or-lower-52",
              "externalID": "ocd-person/936e2bdf-97c6-4297-80e7-faef3bb2e3d7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jeffhelfrich@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/helfrich"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-52-lower-ocd-person-936e2bdf-97c6-4297-80e7-faef3bb2e3d7": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/2be8d7ed-5767-4a67-a5e9-b519691c14ef",
            "firstName": "Emerson",
            "lastName": "Levy",
            "fullName": "Em Levy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/levye/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-OR-53-lower-ocd-person-2be8d7ed-5767-4a67-a5e9-b519691c14ef",
              "builtID": "or-lower-53",
              "externalID": "ocd-person/2be8d7ed-5767-4a67-a5e9-b519691c14ef",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.emersonlevy@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.oregonlegislature.gov/levye",
                "https://www.oregonlegislature.gov/Levye"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-53-lower-ocd-person-2be8d7ed-5767-4a67-a5e9-b519691c14ef": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/47e270fe-abc4-4f5d-9bad-2b7979442f5d",
            "firstName": "Jason",
            "lastName": "Kropf",
            "fullName": "Jason Kropf",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/kropf/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-OR-54-lower-ocd-person-47e270fe-abc4-4f5d-9bad-2b7979442f5d",
              "builtID": "or-lower-54",
              "externalID": "ocd-person/47e270fe-abc4-4f5d-9bad-2b7979442f5d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jasonkropf@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/kropf"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-54-lower-ocd-person-47e270fe-abc4-4f5d-9bad-2b7979442f5d": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/709441c1-d716-47bc-8ac6-353abb1e039e",
            "firstName": "Eric Werner",
            "lastName": "Reschke",
            "fullName": "Werner Reschke",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/reschke/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-OR-55-lower-ocd-person-709441c1-d716-47bc-8ac6-353abb1e039e",
              "builtID": "or-lower-55",
              "externalID": "ocd-person/709441c1-d716-47bc-8ac6-353abb1e039e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ewernerreschke@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/reschke"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-55-lower-ocd-person-709441c1-d716-47bc-8ac6-353abb1e039e": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/2ef0c138-ff50-4a2b-9914-1a5edb506b49",
            "firstName": "Emily",
            "lastName": "McIntire",
            "fullName": "Emily McIntire",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/mcintire/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-OR-56-lower-ocd-person-2ef0c138-ff50-4a2b-9914-1a5edb506b49",
              "builtID": "or-lower-56",
              "externalID": "ocd-person/2ef0c138-ff50-4a2b-9914-1a5edb506b49",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.emilymcintire@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/mcintire"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-56-lower-ocd-person-2ef0c138-ff50-4a2b-9914-1a5edb506b49": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/72dc94e8-e5d5-4376-aab5-06f6b3a771c7",
            "firstName": "Gregory Vincent",
            "lastName": "Smith",
            "fullName": "Greg Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.oregonlegislature.gov/house/MemberPhotos/smithg.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-OR-57-lower-ocd-person-72dc94e8-e5d5-4376-aab5-06f6b3a771c7",
              "builtID": "or-lower-57",
              "externalID": "ocd-person/72dc94e8-e5d5-4376-aab5-06f6b3a771c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.gregsmith@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/smithg"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-57-lower-ocd-person-72dc94e8-e5d5-4376-aab5-06f6b3a771c7": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/4c9f2660-6dd3-49f5-a503-6fe5bc5c7787",
            "firstName": "Bobby",
            "lastName": "Levy",
            "fullName": "Bobby Levy",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/levy/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-OR-58-lower-ocd-person-4c9f2660-6dd3-49f5-a503-6fe5bc5c7787",
              "builtID": "or-lower-58",
              "externalID": "ocd-person/4c9f2660-6dd3-49f5-a503-6fe5bc5c7787",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.bobbylevy@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/levy"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-58-lower-ocd-person-4c9f2660-6dd3-49f5-a503-6fe5bc5c7787": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/a3140c7f-befe-4b83-bc54-1451b5fe00f5",
            "firstName": "Vikki",
            "lastName": "Breese Iverson",
            "fullName": "Vikki Breese Iverson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/breese-iverson/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-OR-59-lower-ocd-person-a3140c7f-befe-4b83-bc54-1451b5fe00f5",
              "builtID": "or-lower-59",
              "externalID": "ocd-person/a3140c7f-befe-4b83-bc54-1451b5fe00f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.vikkibreeseiverson@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/breese-iverson"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-59-lower-ocd-person-a3140c7f-befe-4b83-bc54-1451b5fe00f5": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/f73d5af2-f2b6-47d0-a55a-84ac528343ff",
            "firstName": "Mark",
            "lastName": "Owens",
            "fullName": "Mark Owens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/owens/PublishingImages/member_photo.jpg",
            "title": "OR Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-OR-60-lower-ocd-person-f73d5af2-f2b6-47d0-a55a-84ac528343ff",
              "builtID": "or-lower-60",
              "externalID": "ocd-person/f73d5af2-f2b6-47d0-a55a-84ac528343ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.markowens@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/owens"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-OR-60-lower-ocd-person-f73d5af2-f2b6-47d0-a55a-84ac528343ff": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/26a734f0-51f9-4959-a213-bc345c1050ec",
            "firstName": "David Brock",
            "lastName": "Smith",
            "fullName": "David Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/smithd/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-OR-1-upper-ocd-person-26a734f0-51f9-4959-a213-bc345c1050ec",
              "builtID": "or-upper-1",
              "externalID": "ocd-person/26a734f0-51f9-4959-a213-bc345c1050ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.davidbrocksmith@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/smithd"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-1-upper-ocd-person-26a734f0-51f9-4959-a213-bc345c1050ec": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/ce972bc3-ab5d-406d-b1fc-a445225d7ca6",
            "firstName": "Arthur Brouhard",
            "lastName": "Robinson",
            "fullName": "Art Robinson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/robinson/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-OR-2-upper-ocd-person-ce972bc3-ab5d-406d-b1fc-a445225d7ca6",
              "builtID": "or-upper-2",
              "externalID": "ocd-person/ce972bc3-ab5d-406d-b1fc-a445225d7ca6",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.artrobinson@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1653, Grants Pass, OR 97528",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/robinson"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-2-upper-ocd-person-ce972bc3-ab5d-406d-b1fc-a445225d7ca6": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/0240017c-38e7-45da-8841-c40b007965dc",
            "firstName": "Jeffrey Simon",
            "lastName": "Golden",
            "fullName": "Jeff Golden",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/golden/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-OR-3-upper-ocd-person-0240017c-38e7-45da-8841-c40b007965dc",
              "builtID": "or-upper-3",
              "externalID": "ocd-person/0240017c-38e7-45da-8841-c40b007965dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.jeffgolden@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/golden"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-3-upper-ocd-person-0240017c-38e7-45da-8841-c40b007965dc": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/a36ed400-bbdd-4053-9ae4-576ba52e95ca",
            "firstName": "Floyd",
            "lastName": "Prozanski",
            "fullName": "Floyd Prozanski",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.oregonlegislature.gov/senate/MemberPhotos/prozanski.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-OR-4-upper-ocd-person-a36ed400-bbdd-4053-9ae4-576ba52e95ca",
              "builtID": "or-upper-4",
              "externalID": "ocd-person/a36ed400-bbdd-4053-9ae4-576ba52e95ca",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.floydprozanski@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 11511, Eugene, OR 97440",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/prozanski"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-4-upper-ocd-person-a36ed400-bbdd-4053-9ae4-576ba52e95ca": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/5e28a30c-5ca9-4221-a331-9321267fbae6",
            "firstName": "Richard",
            "lastName": "Anderson",
            "fullName": "Dick Anderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/anderson/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-OR-5-upper-ocd-person-5e28a30c-5ca9-4221-a331-9321267fbae6",
              "builtID": "or-upper-5",
              "externalID": "ocd-person/5e28a30c-5ca9-4221-a331-9321267fbae6",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.dickanderson@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/anderson"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-5-upper-ocd-person-5e28a30c-5ca9-4221-a331-9321267fbae6": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/95da50da-34df-41f5-8900-ff8bec038272",
            "firstName": "Cedric Ross",
            "lastName": "Hayden",
            "fullName": "Cedric Hayden",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/hayden/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-OR-6-upper-ocd-person-95da50da-34df-41f5-8900-ff8bec038272",
              "builtID": "or-upper-6",
              "externalID": "ocd-person/95da50da-34df-41f5-8900-ff8bec038272",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.cedrichayden@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/hayden"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-6-upper-ocd-person-95da50da-34df-41f5-8900-ff8bec038272": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/f1be7436-8ea5-48b2-8997-53dd566633b9",
            "firstName": "James I.",
            "lastName": "Manning",
            "fullName": "James Manning",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/manning/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-OR-7-upper-ocd-person-f1be7436-8ea5-48b2-8997-53dd566633b9",
              "builtID": "or-upper-7",
              "externalID": "ocd-person/f1be7436-8ea5-48b2-8997-53dd566633b9",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.jamesmanning@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.oregonlegislature.gov/Manning",
                "https://www.oregonlegislature.gov/Manning",
                "https://www.oregonlegislature.gov/manning"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-7-upper-ocd-person-f1be7436-8ea5-48b2-8997-53dd566633b9": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/10b42647-1509-4e86-9155-4622c2d17315",
            "firstName": "Sara Acres",
            "lastName": "Gelser Blouin",
            "fullName": "Sara Gelser Blouin",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.oregonlegislature.gov/senate/MemberPhotos/gelser.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-OR-8-upper-ocd-person-10b42647-1509-4e86-9155-4622c2d17315",
              "builtID": "or-upper-8",
              "externalID": "ocd-person/10b42647-1509-4e86-9155-4622c2d17315",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.saragelser@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/gelser"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-8-upper-ocd-person-10b42647-1509-4e86-9155-4622c2d17315": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/aba97bd3-cdf6-4860-9b2c-cbd62e825e79",
            "firstName": "Fred Frank",
            "lastName": "Girod",
            "fullName": "Fred Girod",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.oregonlegislature.gov/senate/MemberPhotos/girod.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-OR-9-upper-ocd-person-aba97bd3-cdf6-4860-9b2c-cbd62e825e79",
              "builtID": "or-upper-9",
              "externalID": "ocd-person/aba97bd3-cdf6-4860-9b2c-cbd62e825e79",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.fredgirod@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/girod"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-9-upper-ocd-person-aba97bd3-cdf6-4860-9b2c-cbd62e825e79": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/6c867cbf-9d0b-4dd8-9537-55e834a42dc4",
            "firstName": "Deb",
            "lastName": "Patterson",
            "fullName": "Deb Patterson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/patterson/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-OR-10-upper-ocd-person-6c867cbf-9d0b-4dd8-9537-55e834a42dc4",
              "builtID": "or-upper-10",
              "externalID": "ocd-person/6c867cbf-9d0b-4dd8-9537-55e834a42dc4",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.debpatterson@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.oregonlegislature.gov/patterson",
                "https://www.oregonlegislature.gov/patterson",
                "https://www.oregonlegislature.gov/Patterson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-10-upper-ocd-person-6c867cbf-9d0b-4dd8-9537-55e834a42dc4": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/5947e2cd-aa8b-4df1-869f-961357705a3c",
            "firstName": "Kim",
            "lastName": "Thatcher",
            "fullName": "Kim Thatcher",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.oregonlegislature.gov/senate/MemberPhotos/thatcher.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-OR-11-upper-ocd-person-5947e2cd-aa8b-4df1-869f-961357705a3c",
              "builtID": "or-upper-11",
              "externalID": "ocd-person/5947e2cd-aa8b-4df1-869f-961357705a3c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.kimthatcher@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/thatcher"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-11-upper-ocd-person-5947e2cd-aa8b-4df1-869f-961357705a3c": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/a3e2d670-179f-4614-bbfd-be3c8fd4df35",
            "firstName": "Brian James",
            "lastName": "Boquist",
            "fullName": "Brian Boquist",
            "gender": "Male",
            "party": "independent",
            "image": "https://bloximages.newyork1.vip.townnews.com/eastoregonian.com/content/tncms/assets/v3/editorial/7/cb/7cb23d08-fe98-11e8-8f24-3fcd42b9932c/5c11eeef82fe6.image.jpg?crop=874%2C998%2C62%2C1&resize=438%2C500&order=crop%2Cresize",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-OR-12-upper-ocd-person-a3e2d670-179f-4614-bbfd-be3c8fd4df35",
              "builtID": "or-upper-12",
              "externalID": "ocd-person/a3e2d670-179f-4614-bbfd-be3c8fd4df35",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.brianboquist@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/boquist"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-12-upper-ocd-person-a3e2d670-179f-4614-bbfd-be3c8fd4df35": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/b6d52f54-a740-4157-b73c-799e46567691",
            "firstName": "Aaron",
            "lastName": "Woods",
            "fullName": "Aaron Woods",
            "gender": "Male",
            "party": "democratic/working families",
            "image": "https://www.oregonlegislature.gov/woods/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-OR-13-upper-ocd-person-b6d52f54-a740-4157-b73c-799e46567691",
              "builtID": "or-upper-13",
              "externalID": "ocd-person/b6d52f54-a740-4157-b73c-799e46567691",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.aaronwoods@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/woods"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-13-upper-ocd-person-b6d52f54-a740-4157-b73c-799e46567691": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/cc87cb36-e12e-42e1-ac5e-c2d50e1d2018",
            "firstName": "Kate B.",
            "lastName": "Lieber",
            "fullName": "Kate Lieber",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/lieber/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-OR-14-upper-ocd-person-cc87cb36-e12e-42e1-ac5e-c2d50e1d2018",
              "builtID": "or-upper-14",
              "externalID": "ocd-person/cc87cb36-e12e-42e1-ac5e-c2d50e1d2018",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.katelieber@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/lieber"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-14-upper-ocd-person-cc87cb36-e12e-42e1-ac5e-c2d50e1d2018": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/9d3f5669-f452-4926-91ec-a74a4c4a48e5",
            "firstName": "Janeen A.",
            "lastName": "Sollman",
            "fullName": "Janeen Sollman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/sollman/PublishingImages/Pages/staff/alteza_films-66_43format.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-OR-15-upper-ocd-person-9d3f5669-f452-4926-91ec-a74a4c4a48e5",
              "builtID": "or-upper-15",
              "externalID": "ocd-person/9d3f5669-f452-4926-91ec-a74a4c4a48e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.janeensollman@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/sollman"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-15-upper-ocd-person-9d3f5669-f452-4926-91ec-a74a4c4a48e5": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/835d23bd-afb1-4e82-93c4-4b1b1822aeb4",
            "firstName": "Suzanne",
            "lastName": "Weber",
            "fullName": "Suzanne Weber",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/weber/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-OR-16-upper-ocd-person-835d23bd-afb1-4e82-93c4-4b1b1822aeb4",
              "builtID": "or-upper-16",
              "externalID": "ocd-person/835d23bd-afb1-4e82-93c4-4b1b1822aeb4",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.suzanneweber@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/weber"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-16-upper-ocd-person-835d23bd-afb1-4e82-93c4-4b1b1822aeb4": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/6d13ab21-8561-4e7b-aa00-82a9fe1b9519",
            "firstName": "Elizabeth",
            "lastName": "Steiner",
            "fullName": "Elizabeth Steiner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/steiner/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-OR-17-upper-ocd-person-6d13ab21-8561-4e7b-aa00-82a9fe1b9519",
              "builtID": "or-upper-17",
              "externalID": "ocd-person/6d13ab21-8561-4e7b-aa00-82a9fe1b9519",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.elizabethsteiner@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 2281, Portland, OR 97208",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.oregonlegislature.gov/steinerhayward",
                "https://www.oregonlegislature.gov/steinerhayward",
                "https://www.oregonlegislature.gov/steiner"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-17-upper-ocd-person-6d13ab21-8561-4e7b-aa00-82a9fe1b9519": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/72027f00-3e3c-47c7-ab62-cc89ebb1ed72",
            "firstName": "Wlnsvey",
            "lastName": "Campos",
            "fullName": "Wlnsvey Campos",
            "gender": "Female",
            "party": "democratic/working families",
            "image": "https://www.oregonlegislature.gov/campos/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-OR-18-upper-ocd-person-72027f00-3e3c-47c7-ab62-cc89ebb1ed72",
              "builtID": "or-upper-18",
              "externalID": "ocd-person/72027f00-3e3c-47c7-ab62-cc89ebb1ed72",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.wlnsveycampos@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/campos"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-18-upper-ocd-person-72027f00-3e3c-47c7-ab62-cc89ebb1ed72": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/e40f7f2c-4c6a-4064-9fde-e6b380dde2aa",
            "firstName": "Robert A.",
            "lastName": "Wagner",
            "fullName": "Rob Wagner",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/wagner/PublishingImages/Headshot%20in%20the%20shrub.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-OR-19-upper-ocd-person-e40f7f2c-4c6a-4064-9fde-e6b380dde2aa",
              "builtID": "or-upper-19",
              "externalID": "ocd-person/e40f7f2c-4c6a-4064-9fde-e6b380dde2aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.robwagner@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/wagner"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-19-upper-ocd-person-e40f7f2c-4c6a-4064-9fde-e6b380dde2aa": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/d6379fd3-d283-4c6e-864b-7dca170b593d",
            "firstName": "Mark W.",
            "lastName": "Meek",
            "fullName": "Mark Meek",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/meek/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-OR-20-upper-ocd-person-d6379fd3-d283-4c6e-864b-7dca170b593d",
              "builtID": "or-upper-20",
              "externalID": "ocd-person/d6379fd3-d283-4c6e-864b-7dca170b593d",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.markmeek@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/meek"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-20-upper-ocd-person-d6379fd3-d283-4c6e-864b-7dca170b593d": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/97c2b8fd-9e87-43b7-b202-6541af36bab7",
            "firstName": "Kathleen",
            "lastName": "Taylor",
            "fullName": "Kathleen Taylor",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/taylor/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-OR-21-upper-ocd-person-97c2b8fd-9e87-43b7-b202-6541af36bab7",
              "builtID": "or-upper-21",
              "externalID": "ocd-person/97c2b8fd-9e87-43b7-b202-6541af36bab7",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.kathleentaylor@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/taylor"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-21-upper-ocd-person-97c2b8fd-9e87-43b7-b202-6541af36bab7": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/2256d4a8-f19b-4e1d-ba35-771cc0349b0b",
            "firstName": "Lew",
            "lastName": "Frederick",
            "fullName": "Lew Frederick",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/frederick/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-OR-22-upper-ocd-person-2256d4a8-f19b-4e1d-ba35-771cc0349b0b",
              "builtID": "or-upper-22",
              "externalID": "ocd-person/2256d4a8-f19b-4e1d-ba35-771cc0349b0b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.lewfrederick@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/frederick"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-22-upper-ocd-person-2256d4a8-f19b-4e1d-ba35-771cc0349b0b": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/1bee2e7b-d7ee-4a86-b351-82ca5789612a",
            "firstName": "Michael E.",
            "lastName": "Dembrow",
            "fullName": "Michael Dembrow",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.oregonlegislature.gov/senate/MemberPhotos/dembrow.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-OR-23-upper-ocd-person-1bee2e7b-d7ee-4a86-b351-82ca5789612a",
              "builtID": "or-upper-23",
              "externalID": "ocd-person/1bee2e7b-d7ee-4a86-b351-82ca5789612a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.michaeldembrow@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/dembrow"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-23-upper-ocd-person-1bee2e7b-d7ee-4a86-b351-82ca5789612a": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/7532bf1f-c85e-4683-a401-ca87af088e5e",
            "firstName": "Kayse",
            "lastName": "Jama",
            "fullName": "Kayse Jama",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.oregonlegislature.gov/Jama/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-OR-24-upper-ocd-person-7532bf1f-c85e-4683-a401-ca87af088e5e",
              "builtID": "or-upper-24",
              "externalID": "ocd-person/7532bf1f-c85e-4683-a401-ca87af088e5e",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.jamakayse@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.oregonlegislature.gov/jama",
                "https://www.oregonlegislature.gov/jama",
                "https://www.oregonlegislature.gov/Jama"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-24-upper-ocd-person-7532bf1f-c85e-4683-a401-ca87af088e5e": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/d5d3b10e-9a5c-464a-93ad-bd824af1cbc0",
            "firstName": "Chris",
            "lastName": "Gorsek",
            "fullName": "Chris Gorsek",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.oregonlegislature.gov/house/MemberPhotos/gorsek.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-OR-25-upper-ocd-person-d5d3b10e-9a5c-464a-93ad-bd824af1cbc0",
              "builtID": "or-upper-25",
              "externalID": "ocd-person/d5d3b10e-9a5c-464a-93ad-bd824af1cbc0",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.chrisgorsek@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/gorsek"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-25-upper-ocd-person-d5d3b10e-9a5c-464a-93ad-bd824af1cbc0": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/9bb85e98-3001-46d7-85fb-5843675d17ba",
            "firstName": "Daniel",
            "lastName": "Bonham",
            "fullName": "Daniel Bonham",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/bonham/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-OR-26-upper-ocd-person-9bb85e98-3001-46d7-85fb-5843675d17ba",
              "builtID": "or-upper-26",
              "externalID": "ocd-person/9bb85e98-3001-46d7-85fb-5843675d17ba",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.danielbonham@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/bonham"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-26-upper-ocd-person-9bb85e98-3001-46d7-85fb-5843675d17ba": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/0fcb2bd6-04ad-498a-bc5d-a909aa7d2909",
            "firstName": "Tim",
            "lastName": "Knopp",
            "fullName": "Tim Knopp",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.oregonlegislature.gov/senate/MemberPhotos/knopp.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-OR-27-upper-ocd-person-0fcb2bd6-04ad-498a-bc5d-a909aa7d2909",
              "builtID": "or-upper-27",
              "externalID": "ocd-person/0fcb2bd6-04ad-498a-bc5d-a909aa7d2909",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.timknopp@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/knopp"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-27-upper-ocd-person-0fcb2bd6-04ad-498a-bc5d-a909aa7d2909": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/83901692-bfe8-483c-8546-06a60583530c",
            "firstName": "Dennis Bradley",
            "lastName": "Linthicum",
            "fullName": "Dennis Linthicum",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/linthicum/PublishingImages/member_photo.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-OR-28-upper-ocd-person-83901692-bfe8-483c-8546-06a60583530c",
              "builtID": "or-upper-28",
              "externalID": "ocd-person/83901692-bfe8-483c-8546-06a60583530c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.dennislinthicum@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/linthicum"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-28-upper-ocd-person-83901692-bfe8-483c-8546-06a60583530c": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/399d6ac3-b0a1-4175-bf62-761fa505150f",
            "firstName": "William S.",
            "lastName": "Hansell",
            "fullName": "Bill Hansell",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.oregonlegislature.gov/senate/MemberPhotos/hansell.jpg",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-OR-29-upper-ocd-person-399d6ac3-b0a1-4175-bf62-761fa505150f",
              "builtID": "or-upper-29",
              "externalID": "ocd-person/399d6ac3-b0a1-4175-bf62-761fa505150f",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.billhansell@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/hansell"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-29-upper-ocd-person-399d6ac3-b0a1-4175-bf62-761fa505150f": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/b7bcf615-b737-4911-b3f3-9fc8fbf32093",
            "firstName": "Lynn P.",
            "lastName": "Findley",
            "fullName": "Lynn Findley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.oregonlegislature.gov/findley/PublishingImages/RepFindley115x130.png",
            "title": "OR Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "OR",
              "stateFull": "Oregon",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-OR-30-upper-ocd-person-b7bcf615-b737-4911-b3f3-9fc8fbf32093",
              "builtID": "or-upper-30",
              "externalID": "ocd-person/b7bcf615-b737-4911-b3f3-9fc8fbf32093",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.lynnfindley@oregonlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://www.oregonlegislature.gov/findley"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-OR-30-upper-ocd-person-b7bcf615-b737-4911-b3f3-9fc8fbf32093": 0
        }
      }
    }
  },
  "PA": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "PA",
          "stateFull": "Pennsylvania",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "PA",
            "stateFull": "Pennsylvania",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-PA---",
            "builtID": "pa--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-PA---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "C001070",
          "in_office": true,
          "firstName": "Bob",
          "lastName": "Casey",
          "middleName": null,
          "fullName": "Robert P. Casey Jr.",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/C001070.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "PA",
            "stateFull": "Pennsylvania",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-PA---C001070",
            "builtID": "pa--",
            "externalID": "C001070",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.casey.senate.gov/contact",
            "address1": "393 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-6324",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenBobCasey",
            "youtube": "SenatorBobCasey",
            "instagram": null,
            "facebook": "SenatorBobCasey",
            "urls": ["https://www.casey.senate.gov"],
            "rss_url": null
          },
          "title": "PA Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/C001070.json",
          "govtrack_id": "412246",
          "cspan_id": "47036",
          "votesmart_id": "2541",
          "icpsr_id": "40703",
          "crp_id": "N00027503",
          "google_entity_id": "/m/047ymw",
          "state_rank": "senior",
          "lis_id": "S309",
          "suffix": null,
          "date_of_birth": "1960-04-13",
          "leadership_role": null,
          "fec_candidate_id": "S6PA00217",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "17",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 18,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 3.25,
          "votes_with_party_pct": 98.88,
          "votes_against_party_pct": 1.12,
          "ocd_id": "ocd-division/country:us/state:pa"
        },
        {
          "API_ID": "F000479",
          "in_office": true,
          "firstName": "John",
          "lastName": "Fetterman",
          "middleName": null,
          "fullName": "John Fetterman",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/F000479.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "PA",
            "stateFull": "Pennsylvania",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-PA---F000479",
            "builtID": "pa--",
            "externalID": "F000479",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": null,
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.fetterman.senate.gov/"],
            "rss_url": null
          },
          "title": "PA Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/F000479.json",
          "govtrack_id": "456877",
          "cspan_id": null,
          "votesmart_id": "166286",
          "icpsr_id": null,
          "crp_id": null,
          "google_entity_id": null,
          "state_rank": "junior",
          "lis_id": "S418",
          "suffix": null,
          "date_of_birth": "1969-08-15",
          "leadership_role": null,
          "fec_candidate_id": "S6PA00274",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 104,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 18.77,
          "votes_with_party_pct": 98.66,
          "votes_against_party_pct": 1.34,
          "ocd_id": "ocd-division/country:us/state:pa"
        }
      ],
      "hash": { "SENATE-PA---C001070": 0, "SENATE-PA---F000479": 1 }
    },
    "HOUSE": {
      "10": {
        "members": [
          {
            "API_ID": "P000605",
            "in_office": true,
            "firstName": "Scott",
            "lastName": "Perry",
            "middleName": null,
            "fullName": "Scott Perry",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/P000605.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "10",
              "chamber": "",
              "hashID": "HOUSE-PA-10--P000605",
              "builtID": "pa--10",
              "externalID": "P000605",
              "geometry": null,
              "geoid": "4210"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2160 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5836",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepScottPerry",
              "youtube": "RepScottPerry",
              "instagram": null,
              "facebook": "repscottperry",
              "urls": ["https://perry.house.gov"],
              "rss_url": null
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000605.json",
            "govtrack_id": "412569",
            "cspan_id": "79873",
            "votesmart_id": "59980",
            "icpsr_id": "21356",
            "crp_id": "N00034120",
            "google_entity_id": "/m/04mwt6y",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1962-05-27",
            "leadership_role": null,
            "fec_candidate_id": "H2PA04135",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 32,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 3.03,
            "votes_with_party_pct": 83.17,
            "votes_against_party_pct": 14.94,
            "ocd_id": "ocd-division/country:us/state:pa/cd:10",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-10--P000605": 0 }
      },
      "11": {
        "members": [
          {
            "API_ID": "S001199",
            "in_office": true,
            "firstName": "Lloyd",
            "lastName": "Smucker",
            "middleName": null,
            "fullName": "Lloyd Smucker",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S001199.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "11",
              "chamber": "",
              "hashID": "HOUSE-PA-11--S001199",
              "builtID": "pa--11",
              "externalID": "S001199",
              "geometry": null,
              "geoid": "4211"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "302 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2411",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepSmucker",
              "youtube": null,
              "instagram": null,
              "facebook": "RepSmucker",
              "urls": ["https://smucker.house.gov"],
              "rss_url": "https://smucker.house.gov/rss.xml"
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001199.json",
            "govtrack_id": "412722",
            "cspan_id": "103540",
            "votesmart_id": "102454",
            "icpsr_id": "21745",
            "crp_id": "N00038781",
            "google_entity_id": "/m/05t08cn",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1964-01-23",
            "leadership_role": null,
            "fec_candidate_id": "H6PA16320",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 13,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.23,
            "votes_with_party_pct": 93.25,
            "votes_against_party_pct": 4.79,
            "ocd_id": "ocd-division/country:us/state:pa/cd:11",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-11--S001199": 0 }
      },
      "12": {
        "members": [
          {
            "API_ID": "L000602",
            "in_office": true,
            "firstName": "Summer",
            "lastName": "Lee",
            "middleName": null,
            "fullName": "Summer L. Lee",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/L000602.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "12",
              "chamber": "",
              "hashID": "HOUSE-PA-12--L000602",
              "builtID": "pa--12",
              "externalID": "L000602",
              "geometry": null,
              "geoid": "4212"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "243 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2135",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://summerlee.house.gov/"],
              "rss_url": null
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000602.json",
            "govtrack_id": "456935",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1987-11-26",
            "leadership_role": null,
            "fec_candidate_id": "H2PA18200",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 1,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.09,
            "votes_with_party_pct": 89.96,
            "votes_against_party_pct": 8.13,
            "ocd_id": "ocd-division/country:us/state:pa/cd:12",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-12--L000602": 0 }
      },
      "13": {
        "members": [
          {
            "API_ID": "J000302",
            "in_office": true,
            "firstName": "John",
            "lastName": "Joyce",
            "middleName": null,
            "fullName": "John Joyce",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/J000302.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "13",
              "chamber": "",
              "hashID": "HOUSE-PA-13--J000302",
              "builtID": "pa--13",
              "externalID": "J000302",
              "geometry": null,
              "geoid": "4213"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "152 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2431",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJohnJoyce",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://johnjoyce.house.gov"],
              "rss_url": null
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/J000302.json",
            "govtrack_id": "412812",
            "cspan_id": null,
            "votesmart_id": "178911",
            "icpsr_id": null,
            "crp_id": "N00043242",
            "google_entity_id": "/g/11fgkqqf2l",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1957-02-08",
            "leadership_role": null,
            "fec_candidate_id": "H8PA13125",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 2,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.19,
            "votes_with_party_pct": 92.06,
            "votes_against_party_pct": 6,
            "ocd_id": "ocd-division/country:us/state:pa/cd:13",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-13--J000302": 0 }
      },
      "14": {
        "members": [
          {
            "API_ID": "R000610",
            "in_office": true,
            "firstName": "Guy",
            "lastName": "Reschenthaler",
            "middleName": null,
            "fullName": "Guy Reschenthaler",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/R000610.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "14",
              "chamber": "",
              "hashID": "HOUSE-PA-14--R000610",
              "builtID": "pa--14",
              "externalID": "R000610",
              "geometry": null,
              "geoid": "4214"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "342 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2065",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "GReschenthaler",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://reschenthaler.house.gov"],
              "rss_url": null
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/R000610.json",
            "govtrack_id": "412813",
            "cspan_id": null,
            "votesmart_id": "166004",
            "icpsr_id": null,
            "crp_id": "N00041871",
            "google_entity_id": "/g/11bwn3bsvm",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1983-04-17",
            "leadership_role": null,
            "fec_candidate_id": "H8PA18199",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.57,
            "votes_with_party_pct": 93.78,
            "votes_against_party_pct": 4.28,
            "ocd_id": "ocd-division/country:us/state:pa/cd:14",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-14--R000610": 0 }
      },
      "15": {
        "members": [
          {
            "API_ID": "T000467",
            "in_office": true,
            "firstName": "Glenn",
            "lastName": "Thompson",
            "middleName": null,
            "fullName": "Glenn Thompson",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/T000467.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "15",
              "chamber": "",
              "hashID": "HOUSE-PA-15--T000467",
              "builtID": "pa--15",
              "externalID": "T000467",
              "geometry": null,
              "geoid": "4215"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "400 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5121",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "CongressmanGT",
              "youtube": "CongressmanGT",
              "instagram": null,
              "facebook": "CongressmanGT",
              "urls": ["https://thompson.house.gov"],
              "rss_url": "https://thompson.house.gov/rss.xml"
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000467.json",
            "govtrack_id": "412317",
            "cspan_id": "1031359",
            "votesmart_id": "24046",
            "icpsr_id": "20946",
            "crp_id": "N00029736",
            "google_entity_id": "/m/0409b0_",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1959-07-27",
            "leadership_role": null,
            "fec_candidate_id": "H8PA05071",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "16",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 5,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.47,
            "votes_with_party_pct": 87.66,
            "votes_against_party_pct": 10.5,
            "ocd_id": "ocd-division/country:us/state:pa/cd:15",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-15--T000467": 0 }
      },
      "16": {
        "members": [
          {
            "API_ID": "K000376",
            "in_office": true,
            "firstName": "Mike",
            "lastName": "Kelly",
            "middleName": null,
            "fullName": "Mike Kelly",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/K000376.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "16",
              "chamber": "",
              "hashID": "HOUSE-PA-16--K000376",
              "builtID": "pa--16",
              "externalID": "K000376",
              "geometry": null,
              "geoid": "4216"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1707 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5406",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "MikeKellyPA",
              "youtube": "repmikekelly",
              "instagram": null,
              "facebook": "191056827594903",
              "urls": ["https://kelly.house.gov"],
              "rss_url": "https://kelly.house.gov/rss.xml"
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000376.json",
            "govtrack_id": "412465",
            "cspan_id": "62696",
            "votesmart_id": "119463",
            "icpsr_id": "21167",
            "crp_id": "N00031647",
            "google_entity_id": "/m/0ds6wcw",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1948-05-10",
            "leadership_role": null,
            "fec_candidate_id": "H0PA03271",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 117,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 11.09,
            "votes_with_party_pct": 88.79,
            "votes_against_party_pct": 9.14,
            "ocd_id": "ocd-division/country:us/state:pa/cd:16",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-16--K000376": 0 }
      },
      "17": {
        "members": [
          {
            "API_ID": "D000530",
            "in_office": true,
            "firstName": "Chris",
            "lastName": "Deluzio",
            "middleName": null,
            "fullName": "Christopher R. Deluzio",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/D000530.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "17",
              "chamber": "",
              "hashID": "HOUSE-PA-17--D000530",
              "builtID": "pa--17",
              "externalID": "D000530",
              "geometry": null,
              "geoid": "4217"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1222 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2301",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDeluzio",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://deluzio.house.gov/"],
              "rss_url": null
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000530.json",
            "govtrack_id": "456936",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1984-07-13",
            "leadership_role": null,
            "fec_candidate_id": "H2PA17103",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 9,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 94.12,
            "votes_against_party_pct": 3.95,
            "ocd_id": "ocd-division/country:us/state:pa/cd:17",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-17--D000530": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "B001296",
            "in_office": true,
            "firstName": "Brendan",
            "lastName": "Boyle",
            "middleName": null,
            "fullName": "Brendan F. Boyle",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/B001296.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-PA-02--B001296",
              "builtID": "pa--02",
              "externalID": "B001296",
              "geometry": null,
              "geoid": "4202"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1502 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-6111",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "CongBoyle",
              "youtube": null,
              "instagram": null,
              "facebook": "CongressmanBoyle",
              "urls": ["https://boyle.house.gov"],
              "rss_url": "https://boyle.house.gov/rss.xml"
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001296.json",
            "govtrack_id": "412652",
            "cspan_id": "76428",
            "votesmart_id": "47357",
            "icpsr_id": "21548",
            "crp_id": "N00035307",
            "google_entity_id": "/m/04f4fjk",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1977-02-06",
            "leadership_role": null,
            "fec_candidate_id": "H4PA13199",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 32,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.03,
            "votes_with_party_pct": 94.68,
            "votes_against_party_pct": 3.55,
            "ocd_id": "ocd-division/country:us/state:pa/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-02--B001296": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "C001090",
            "in_office": true,
            "firstName": "Matt",
            "lastName": "Cartwright",
            "middleName": null,
            "fullName": "Matt Cartwright",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001090.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-PA-08--C001090",
              "builtID": "pa--08",
              "externalID": "C001090",
              "geometry": null,
              "geoid": "4208"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2102 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5546",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepCartwright",
              "youtube": null,
              "instagram": null,
              "facebook": "CongressmanMattCartwright",
              "urls": ["https://cartwright.house.gov"],
              "rss_url": null
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001090.json",
            "govtrack_id": "412571",
            "cspan_id": "79865",
            "votesmart_id": "136236",
            "icpsr_id": "21358",
            "crp_id": "N00034128",
            "google_entity_id": "/m/0j_6b9d",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1961-05-01",
            "leadership_role": null,
            "fec_candidate_id": "H2PA17079",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 21,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.99,
            "votes_with_party_pct": 93.65,
            "votes_against_party_pct": 4.49,
            "ocd_id": "ocd-division/country:us/state:pa/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-08--C001090": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "D000631",
            "in_office": true,
            "firstName": "Madeleine",
            "lastName": "Dean",
            "middleName": null,
            "fullName": "Madeleine Dean",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/D000631.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-PA-04--D000631",
              "builtID": "pa--04",
              "externalID": "D000631",
              "geometry": null,
              "geoid": "4204"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "150 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-4731",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDean",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://dean.house.gov"],
              "rss_url": null
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000631.json",
            "govtrack_id": "412809",
            "cspan_id": null,
            "votesmart_id": "136484",
            "icpsr_id": null,
            "crp_id": "N00042894",
            "google_entity_id": "/m/0nfwmy_",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1959-06-06",
            "leadership_role": null,
            "fec_candidate_id": "H8PA04116",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 11,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.04,
            "votes_with_party_pct": 96.62,
            "votes_against_party_pct": 1.45,
            "ocd_id": "ocd-division/country:us/state:pa/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-04--D000631": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "E000296",
            "in_office": true,
            "firstName": "Dwight",
            "lastName": "Evans",
            "middleName": null,
            "fullName": "Dwight Evans",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/E000296.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-PA-03--E000296",
              "builtID": "pa--03",
              "externalID": "E000296",
              "geometry": null,
              "geoid": "4203"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1105 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4001",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDwightEvans",
              "youtube": null,
              "instagram": null,
              "facebook": "RepDwightEvans",
              "urls": ["https://evans.house.gov"],
              "rss_url": null
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/E000296.json",
            "govtrack_id": "412677",
            "cspan_id": "56729",
            "votesmart_id": "9128",
            "icpsr_id": "21566",
            "crp_id": "N00038450",
            "google_entity_id": "/m/03y8h6m",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1954-05-16",
            "leadership_role": null,
            "fec_candidate_id": "H6PA02171",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 196,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 18.56,
            "votes_with_party_pct": 95.29,
            "votes_against_party_pct": 2.47,
            "ocd_id": "ocd-division/country:us/state:pa/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-03--E000296": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "F000466",
            "in_office": true,
            "firstName": "Brian",
            "lastName": "Fitzpatrick",
            "middleName": null,
            "fullName": "Brian K. Fitzpatrick",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/F000466.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-PA-01--F000466",
              "builtID": "pa--01",
              "externalID": "F000466",
              "geometry": null,
              "geoid": "4201"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "271 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-4276",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repbrianfitz",
              "youtube": null,
              "instagram": null,
              "facebook": "repbrianfitzpatrick",
              "urls": ["https://fitzpatrick.house.gov"],
              "rss_url": null
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000466.json",
            "govtrack_id": "412721",
            "cspan_id": "103537",
            "votesmart_id": "167708",
            "icpsr_id": "21718",
            "crp_id": "N00038779",
            "google_entity_id": "/g/11c2l6lhlm",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1973-12-17",
            "leadership_role": null,
            "fec_candidate_id": "H6PA08277",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 2,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.19,
            "votes_with_party_pct": 68.54,
            "votes_against_party_pct": 29.53,
            "ocd_id": "ocd-division/country:us/state:pa/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-01--F000466": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "H001085",
            "in_office": true,
            "firstName": "Chrissy",
            "lastName": "Houlahan",
            "middleName": null,
            "fullName": "Chrissy Houlahan",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/H001085.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-PA-06--H001085",
              "builtID": "pa--06",
              "externalID": "H001085",
              "geometry": null,
              "geoid": "4206"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1727 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4315",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepHoulahan",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://houlahan.house.gov"],
              "rss_url": null
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001085.json",
            "govtrack_id": "412810",
            "cspan_id": null,
            "votesmart_id": "178893",
            "icpsr_id": null,
            "crp_id": "N00040949",
            "google_entity_id": "/g/11fghyq4ym",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1967-06-05",
            "leadership_role": null,
            "fec_candidate_id": "H8PA06087",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 22,
            "total_present": 5,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.08,
            "votes_with_party_pct": 92.68,
            "votes_against_party_pct": 5.37,
            "ocd_id": "ocd-division/country:us/state:pa/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-06--H001085": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "M001204",
            "in_office": true,
            "firstName": "Daniel",
            "lastName": "Meuser",
            "middleName": null,
            "fullName": "Daniel Meuser",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001204.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-PA-09--M001204",
              "builtID": "pa--09",
              "externalID": "M001204",
              "geometry": null,
              "geoid": "4209"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "350 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-6511",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMeuser",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://meuser.house.gov"],
              "rss_url": null
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001204.json",
            "govtrack_id": "412811",
            "cspan_id": null,
            "votesmart_id": "102438",
            "icpsr_id": null,
            "crp_id": "N00029416",
            "google_entity_id": "/m/03wf1q3",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1964-02-10",
            "leadership_role": null,
            "fec_candidate_id": "H8PA10147",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 30,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.84,
            "votes_with_party_pct": 93.14,
            "votes_against_party_pct": 4.87,
            "ocd_id": "ocd-division/country:us/state:pa/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-09--M001204": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "S001205",
            "in_office": true,
            "firstName": "Mary",
            "lastName": "Scanlon",
            "middleName": "Gay",
            "fullName": "Mary Gay Scanlon",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001205.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-PA-05--S001205",
              "builtID": "pa--05",
              "externalID": "S001205",
              "geometry": null,
              "geoid": "4205"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1227 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2011",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMGS",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://scanlon.house.gov"],
              "rss_url": "https://medium.com/feed/@repmgs"
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001205.json",
            "govtrack_id": "412750",
            "cspan_id": null,
            "votesmart_id": "178890",
            "icpsr_id": null,
            "crp_id": "N00042706",
            "google_entity_id": "/g/11ghrc58bb",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1959-08-30",
            "leadership_role": null,
            "fec_candidate_id": "H8PA07200",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 53,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 5.02,
            "votes_with_party_pct": 95.27,
            "votes_against_party_pct": 2.72,
            "ocd_id": "ocd-division/country:us/state:pa/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-05--S001205": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "W000826",
            "in_office": true,
            "firstName": "Susan",
            "lastName": "Wild",
            "middleName": null,
            "fullName": "Susan Wild",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/W000826.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-PA-07--W000826",
              "builtID": "pa--07",
              "externalID": "W000826",
              "geometry": null,
              "geoid": "4207"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1027 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-6411",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepSusanWild",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://wild.house.gov"],
              "rss_url": null
            },
            "title": "PA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000826.json",
            "govtrack_id": "412751",
            "cspan_id": null,
            "votesmart_id": "178895",
            "icpsr_id": null,
            "crp_id": "N00041997",
            "google_entity_id": "/g/11gtg86jn6",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1957-06-07",
            "leadership_role": null,
            "fec_candidate_id": "H8PA15229",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 37,
            "total_present": 8,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 3.5,
            "votes_with_party_pct": 91.58,
            "votes_against_party_pct": 6.44,
            "ocd_id": "ocd-division/country:us/state:pa/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-PA-07--W000826": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/c4a1a8f4-cb29-4700-8235-b103fcb40279",
            "firstName": "Patrick J.",
            "lastName": "Harkins",
            "fullName": "Pat Harkins",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1081.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-PA-1-lower-ocd-person-c4a1a8f4-cb29-4700-8235-b103fcb40279",
              "builtID": "pa-lower-1",
              "externalID": "ocd-person/c4a1a8f4-cb29-4700-8235-b103fcb40279",
              "geometry": null
            },
            "contactInfo": {
              "email": "pharkins@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "460 E. 26th St., Erie, PA 16504",
              "phone1": null,
              "phone2": "814-459-1949",
              "fax1": null,
              "fax2": "814-871-4854",
              "twitter": null,
              "youtube": "playlist?list=PLjtqNtfs0bgV_GcfsgVUt0PwAXkAWeMvn",
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1081",
                "https://pahouse.com/Harkins",
                "https://www.palegis.us/house/members/bio/1081/rep-harkins"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-1-lower-ocd-person-c4a1a8f4-cb29-4700-8235-b103fcb40279": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/bd4c3d05-059a-4084-b8f8-e8598e4eb955",
            "firstName": "Robert E.",
            "lastName": "Merski",
            "fullName": "Bob Merski",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1822.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-PA-2-lower-ocd-person-bd4c3d05-059a-4084-b8f8-e8598e4eb955",
              "builtID": "pa-lower-2",
              "externalID": "ocd-person/bd4c3d05-059a-4084-b8f8-e8598e4eb955",
              "geometry": null
            },
            "contactInfo": {
              "email": "rmerski@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "3921 Buffalo Road, Erie, PA 16510",
              "phone1": null,
              "phone2": "814-455-6319",
              "fax1": null,
              "fax2": "814-455-6593",
              "twitter": "bobmerski",
              "youtube": "playlist?list=PLjtqNtfs0bgXx81_87Iuo4q8-yfllNpqL",
              "instagram": null,
              "facebook": "bobmerski",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1822",
                "https://www.pahouse.com/merski",
                "https://www.palegis.us/house/members/bio/1822/rep-merski"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-2-lower-ocd-person-bd4c3d05-059a-4084-b8f8-e8598e4eb955": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/32370423-acb8-4a8e-8336-67af5ed32bb9",
            "firstName": "Ryan A.",
            "lastName": "Bizzarro",
            "fullName": "Ryan Bizzarro",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1619.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-PA-3-lower-ocd-person-32370423-acb8-4a8e-8336-67af5ed32bb9",
              "builtID": "pa-lower-3",
              "externalID": "ocd-person/32370423-acb8-4a8e-8336-67af5ed32bb9",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryanbizzarroerie@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "Peninsula Plaza, 1101 Peninsula Drive Suite 213, Erie, PA 16505",
              "phone1": null,
              "phone2": "814-835-2880",
              "fax1": null,
              "fax2": "814-835-2885",
              "twitter": "ryanbizzarro",
              "youtube": "playlist?list=PLjtqNtfs0bgX6evDUdDxKRGj6SSt-zw4e",
              "instagram": null,
              "facebook": "RepRyanBizzarro",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1619",
                "https://www.pahouse.com/Bizzarro/",
                "https://www.palegis.us/house/members/bio/1619/rep-bizzarro"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-3-lower-ocd-person-32370423-acb8-4a8e-8336-67af5ed32bb9": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/4657c161-af68-44a8-bd60-8b8e88e702d1",
            "firstName": "Jacob Daniel",
            "lastName": "Banta",
            "fullName": "Jake Banta",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1937.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-PA-4-lower-ocd-person-4657c161-af68-44a8-bd60-8b8e88e702d1",
              "builtID": "pa-lower-4",
              "externalID": "ocd-person/4657c161-af68-44a8-bd60-8b8e88e702d1",
              "geometry": null
            },
            "contactInfo": {
              "email": "jbanta@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "8 S. Lake St., North East, PA 16428",
              "phone1": null,
              "phone2": "814-347-7110",
              "fax1": null,
              "fax2": "814-347-7111",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepBanta",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1937",
                "https://www.repbanta.com/",
                "https://www.palegis.us/house/members/bio/1937/rep-banta"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-4-lower-ocd-person-4657c161-af68-44a8-bd60-8b8e88e702d1": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/1ebacf77-8895-4b52-af40-ccfdf6448ac6",
            "firstName": "Barry J.",
            "lastName": "Jozwiak",
            "fullName": "Barry Jozwiak",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1692.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-PA-5-lower-ocd-person-1ebacf77-8895-4b52-af40-ccfdf6448ac6",
              "builtID": "pa-lower-5",
              "externalID": "ocd-person/1ebacf77-8895-4b52-af40-ccfdf6448ac6",
              "geometry": null
            },
            "contactInfo": {
              "email": "bjozwiak@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "Reading Regional Airport, Main Terminal Building 2501 Bernville Road, Reading, PA 19605",
              "phone1": null,
              "phone2": "610-378-4407",
              "fax1": null,
              "fax2": "610-378-4412",
              "twitter": null,
              "youtube": "RepJozwiak",
              "instagram": null,
              "facebook": "PA-State-Rep-Barry-Jozwiak",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1692",
                "https://www.repjozwiak.com/",
                "https://www.palegis.us/house/members/bio/1692/rep-jozwiak"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-5-lower-ocd-person-1ebacf77-8895-4b52-af40-ccfdf6448ac6": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/979ea568-4c43-41bd-ba1e-4c2da172bb82",
            "firstName": "Bradley T.",
            "lastName": "Roae",
            "fullName": "Brad Roae",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1083.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-PA-6-lower-ocd-person-979ea568-4c43-41bd-ba1e-4c2da172bb82",
              "builtID": "pa-lower-6",
              "externalID": "ocd-person/979ea568-4c43-41bd-ba1e-4c2da172bb82",
              "geometry": null
            },
            "contactInfo": {
              "email": "broae@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "18937 Park Avenue Plaza, Meadville, PA 16335",
              "phone1": null,
              "phone2": "814-336-1136",
              "fax1": null,
              "fax2": "814-332-6929",
              "twitter": "RepRoae",
              "youtube": "RepRoae",
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1083",
                "https://www.reproae.com/",
                "https://www.palegis.us/house/members/bio/1083/rep-roae"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-6-lower-ocd-person-979ea568-4c43-41bd-ba1e-4c2da172bb82": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/8f7a2783-e165-4bf2-aead-113d98dd7dd2",
            "firstName": "Parke H.",
            "lastName": "Wentling",
            "fullName": "Parke Wentling",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1709.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-PA-7-lower-ocd-person-8f7a2783-e165-4bf2-aead-113d98dd7dd2",
              "builtID": "pa-lower-7",
              "externalID": "ocd-person/8f7a2783-e165-4bf2-aead-113d98dd7dd2",
              "geometry": null
            },
            "contactInfo": {
              "email": "pwentling@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "Greenville Area Chamber of Commerce 182 Main St., Greenville, PA 16125",
              "phone1": null,
              "phone2": "724-638-4101",
              "fax1": null,
              "fax2": "724-638-4102",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "PA-State-Rep-Parke-Wentling",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1709",
                "https://www.repwentling.com/",
                "https://www.palegis.us/house/members/bio/1709/rep-wentling"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-7-lower-ocd-person-8f7a2783-e165-4bf2-aead-113d98dd7dd2": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/844f3ed7-03df-420f-81bd-3f547c12e61e",
            "firstName": "Aaron Joseph",
            "lastName": "Bernstine",
            "fullName": "Aaron Bernstine",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1742.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-PA-8-lower-ocd-person-844f3ed7-03df-420f-81bd-3f547c12e61e",
              "builtID": "pa-lower-8",
              "externalID": "ocd-person/844f3ed7-03df-420f-81bd-3f547c12e61e",
              "geometry": null
            },
            "contactInfo": {
              "email": "abernstine@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "790 Pittsburgh Road Suite 3, Butler, PA 16002",
              "phone1": null,
              "phone2": "724-847-5291",
              "fax1": null,
              "fax2": "724-847-5294",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "repbernstine",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1742",
                "https://www.repbernstine.com/",
                "https://www.palegis.us/house/members/bio/1742/rep-bernstine"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-8-lower-ocd-person-844f3ed7-03df-420f-81bd-3f547c12e61e": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/68e26db9-10ba-414b-9d06-440dd1fd3f10",
            "firstName": "Marla A. Gallo",
            "lastName": "Brown",
            "fullName": "Marla Brown",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1938.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-PA-9-lower-ocd-person-68e26db9-10ba-414b-9d06-440dd1fd3f10",
              "builtID": "pa-lower-9",
              "externalID": "ocd-person/68e26db9-10ba-414b-9d06-440dd1fd3f10",
              "geometry": null
            },
            "contactInfo": {
              "email": "mbrown@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "28 N. Mill St., New Castle, PA 16101",
              "phone1": null,
              "phone2": "724-498-4397",
              "fax1": null,
              "fax2": "724-498-4398",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepMarlaBrown",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1938",
                "https://www.repmarlabrown.com/",
                "https://www.palegis.us/house/members/bio/1938/rep-m-brown"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-9-lower-ocd-person-68e26db9-10ba-414b-9d06-440dd1fd3f10": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/a41016aa-660f-4af7-afcd-ef182e544533",
            "firstName": "Amen R.",
            "lastName": "Brown",
            "fullName": "Amen Brown",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1919.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-PA-10-lower-ocd-person-a41016aa-660f-4af7-afcd-ef182e544533",
              "builtID": "pa-lower-10",
              "externalID": "ocd-person/a41016aa-660f-4af7-afcd-ef182e544533",
              "geometry": null
            },
            "contactInfo": {
              "email": "repbrown@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "4027 Market St., Philadelphia, PA 19104",
              "phone1": null,
              "phone2": "215-879-6615",
              "fax1": null,
              "fax2": null,
              "twitter": "RepAmenBrown",
              "youtube": null,
              "instagram": null,
              "facebook": "RepAmenBrown",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1919",
                "https://www.pahouse.com/Brown/",
                "https://www.palegis.us/house/members/bio/1919/rep-a-brown"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-10-lower-ocd-person-a41016aa-660f-4af7-afcd-ef182e544533": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/dc7a449c-43cf-4522-b2a3-efb4707907c4",
            "firstName": "Marci",
            "lastName": "Mustello",
            "fullName": "Marci Mustello",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1868.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-PA-11-lower-ocd-person-dc7a449c-43cf-4522-b2a3-efb4707907c4",
              "builtID": "pa-lower-11",
              "externalID": "ocd-person/dc7a449c-43cf-4522-b2a3-efb4707907c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmustello@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "Kerven Building 100 Barracks Road, Butler, PA 16001",
              "phone1": null,
              "phone2": "724-283-5852",
              "fax1": null,
              "fax2": "724-284-8253",
              "twitter": null,
              "youtube": "UCZO3JGkMnm7EOTz8uZG3DGQ",
              "instagram": null,
              "facebook": "RepMustello",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1868",
                "https://www.RepMustello.com/",
                "https://www.palegis.us/house/members/bio/1868/rep-mustello"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-11-lower-ocd-person-dc7a449c-43cf-4522-b2a3-efb4707907c4": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/a3db05d0-b346-4e85-b6b1-71247b190f8e",
            "firstName": "Stephenie Grace Anderson",
            "lastName": "Scialabba",
            "fullName": "Stephenie Scialabba",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1939.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-PA-12-lower-ocd-person-a3db05d0-b346-4e85-b6b1-71247b190f8e",
              "builtID": "pa-lower-12",
              "externalID": "ocd-person/a3db05d0-b346-4e85-b6b1-71247b190f8e",
              "geometry": null
            },
            "contactInfo": {
              "email": "sscialabba@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "Cranberry Township Municipal Center, 2525 Rochester Road, Suite 201, Cranberry Township, PA 16066",
              "phone1": null,
              "phone2": "724-772-3110",
              "fax1": null,
              "fax2": "724-772-2902",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "StateRepSS",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1939",
                "https://www.repscialabba.com/",
                "https://www.palegis.us/house/members/bio/1939/rep-scialabba"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-12-lower-ocd-person-a3db05d0-b346-4e85-b6b1-71247b190f8e": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/8c1966e4-80f4-4a98-bf55-b1de0b9705b3",
            "firstName": "John Adda",
            "lastName": "Lawrence",
            "fullName": "John Lawrence",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1215.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-PA-13-lower-ocd-person-8c1966e4-80f4-4a98-bf55-b1de0b9705b3",
              "builtID": "pa-lower-13",
              "externalID": "ocd-person/8c1966e4-80f4-4a98-bf55-b1de0b9705b3",
              "geometry": null
            },
            "contactInfo": {
              "email": "jlawrenc@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "1 Commerce Blvd. Suite 200, West Grove, PA 19390",
              "phone1": null,
              "phone2": "610-869-1602",
              "fax1": null,
              "fax2": "610-869-1605",
              "twitter": null,
              "youtube": "RepLawrence",
              "instagram": null,
              "facebook": "RepLawrence",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1215",
                "https://www.replawrence.com/",
                "https://www.palegis.us/house/members/bio/1215/rep-lawrence"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-13-lower-ocd-person-8c1966e4-80f4-4a98-bf55-b1de0b9705b3": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/5956a1da-5202-4043-8219-12250bba8c52",
            "firstName": "James E.",
            "lastName": "Marshall",
            "fullName": "Jim Marshall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1086.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-PA-14-lower-ocd-person-5956a1da-5202-4043-8219-12250bba8c52",
              "builtID": "pa-lower-14",
              "externalID": "ocd-person/5956a1da-5202-4043-8219-12250bba8c52",
              "geometry": null
            },
            "contactInfo": {
              "email": "jmarshal@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "Chippewa Township Municipal Building 2811 Darlington Road, Suite 1, Beaver Falls, PA 15010",
              "phone1": null,
              "phone2": "724-847-1352",
              "fax1": null,
              "fax2": "724-847-5283",
              "twitter": null,
              "youtube": "RepMarshall",
              "instagram": null,
              "facebook": "RepMarshall",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1086",
                "https://www.repmarshall.com/",
                "https://www.palegis.us/house/members/bio/1086/rep-marshall"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-14-lower-ocd-person-5956a1da-5202-4043-8219-12250bba8c52": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/fbb14b8a-8940-4679-9ed8-3ec48096a3a2",
            "firstName": "Joshua Daniel",
            "lastName": "Kail",
            "fullName": "Josh Kail",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1823.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-PA-15-lower-ocd-person-fbb14b8a-8940-4679-9ed8-3ec48096a3a2",
              "builtID": "pa-lower-15",
              "externalID": "ocd-person/fbb14b8a-8940-4679-9ed8-3ec48096a3a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "jkail@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 167 1569 Smith Township Road, Suite 5, Atlasburg, PA 15004",
              "phone1": null,
              "phone2": "724-587-3095",
              "fax1": null,
              "fax2": "724-587-3096",
              "twitter": null,
              "youtube": "UCpzQL17DfPDyIgOebGq3Zjg",
              "instagram": null,
              "facebook": "RepKail",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1823",
                "https://www.repkail.com/",
                "https://www.palegis.us/house/members/bio/1823/rep-kail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-15-lower-ocd-person-fbb14b8a-8940-4679-9ed8-3ec48096a3a2": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/a7b2e50f-90a6-4f35-b1fb-c33406dc4277",
            "firstName": "Robert F.",
            "lastName": "Matzie",
            "fullName": "Rob Matzie",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1173.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-PA-16-lower-ocd-person-a7b2e50f-90a6-4f35-b1fb-c33406dc4277",
              "builtID": "pa-lower-16",
              "externalID": "ocd-person/a7b2e50f-90a6-4f35-b1fb-c33406dc4277",
              "geometry": null
            },
            "contactInfo": {
              "email": "rmatzie@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "Aliquippa Office Franklin Center of Beaver County, Inc. 524 Franklin Ave., Aliquippa, PA 15001",
              "phone1": null,
              "phone2": "724-375-3052",
              "fax1": null,
              "fax2": "724-375-3058",
              "twitter": "RobMatzie",
              "youtube": "playlist?list=PLFCB4F49FABFC43BF",
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1173",
                "https://www.pahouse.com/Matzie",
                "https://www.palegis.us/house/members/bio/1173/rep-matzie"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-16-lower-ocd-person-a7b2e50f-90a6-4f35-b1fb-c33406dc4277": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/105c6361-0cb6-46f4-9222-ff0ffb727d29",
            "firstName": "Timothy R.",
            "lastName": "Bonner",
            "fullName": "Tim Bonner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1877.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-PA-17-lower-ocd-person-105c6361-0cb6-46f4-9222-ff0ffb727d29",
              "builtID": "pa-lower-17",
              "externalID": "ocd-person/105c6361-0cb6-46f4-9222-ff0ffb727d29",
              "geometry": null
            },
            "contactInfo": {
              "email": "tbonner@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "234 W. Pine St. Unit 1, Grove City, PA 16127",
              "phone1": null,
              "phone2": "724-458-4911",
              "fax1": null,
              "fax2": "724-450-4104",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepBonner",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1877",
                "https://www.repbonner.com/",
                "https://www.palegis.us/house/members/bio/1877/rep-bonner"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-17-lower-ocd-person-105c6361-0cb6-46f4-9222-ff0ffb727d29": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/26925ace-f614-409d-80a2-9b6a42e7ddb5",
            "firstName": "Kathleen Christine",
            "lastName": "Tomlinson",
            "fullName": "K.C. Tomlinson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1876.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-PA-18-lower-ocd-person-26925ace-f614-409d-80a2-9b6a42e7ddb5",
              "builtID": "pa-lower-18",
              "externalID": "ocd-person/26925ace-f614-409d-80a2-9b6a42e7ddb5",
              "geometry": null
            },
            "contactInfo": {
              "email": "kctomlinsonpa18@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "Neshaminy Valley Commons 2424 Bristol Road, Bensalem, PA 19020",
              "phone1": null,
              "phone2": "215-750-1017",
              "fax1": null,
              "fax2": "215-750-1295",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepTomlinson",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1876",
                "https://www.reptomlinson.com/",
                "https://www.palegis.us/house/members/bio/1876/rep-tomlinson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-18-lower-ocd-person-26925ace-f614-409d-80a2-9b6a42e7ddb5": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/1b5abb1e-0028-49a3-ae86-cfb90296c796",
            "firstName": "Aerion Andrew",
            "lastName": "Abney",
            "fullName": "Aerion Abney",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1933.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-PA-19-lower-ocd-person-1b5abb1e-0028-49a3-ae86-cfb90296c796",
              "builtID": "pa-lower-19",
              "externalID": "ocd-person/1b5abb1e-0028-49a3-ae86-cfb90296c796",
              "geometry": null
            },
            "contactInfo": {
              "email": "aabney@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "730 James St., Pittsburgh, PA 15212",
              "phone1": null,
              "phone2": "412-471-7760",
              "fax1": null,
              "fax2": "412-471-8056",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1933",
                "https://www.pahouse.com/abney/",
                "https://www.palegis.us/house/members/bio/1933/rep-abney"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-19-lower-ocd-person-1b5abb1e-0028-49a3-ae86-cfb90296c796": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/875b1add-2803-4cc3-964b-0e351ed6ca1c",
            "firstName": "Emily",
            "lastName": "Kinkead",
            "fullName": "Emily Kinkead",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1896.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-PA-20-lower-ocd-person-875b1add-2803-4cc3-964b-0e351ed6ca1c",
              "builtID": "pa-lower-20",
              "externalID": "ocd-person/875b1add-2803-4cc3-964b-0e351ed6ca1c",
              "geometry": null
            },
            "contactInfo": {
              "email": "repkinkead@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "658 Lincoln Ave., Bellevue, PA 15202",
              "phone1": null,
              "phone2": "412-321-5523",
              "fax1": null,
              "fax2": "412-761-2303",
              "twitter": "RepKinkead",
              "youtube": null,
              "instagram": null,
              "facebook": "RepKinkead",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1896",
                "https://www.palegis.us/house/members/bio/1896/rep-kinkead"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-20-lower-ocd-person-875b1add-2803-4cc3-964b-0e351ed6ca1c": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/b7c8d063-8db4-4cdf-a159-a0b064b7cae5",
            "firstName": "Lindsay",
            "lastName": "Powell",
            "fullName": "Lindsay Powell",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/2016.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-PA-21-lower-ocd-person-b7c8d063-8db4-4cdf-a159-a0b064b7cae5",
              "builtID": "pa-lower-21",
              "externalID": "ocd-person/b7c8d063-8db4-4cdf-a159-a0b064b7cae5",
              "geometry": null
            },
            "contactInfo": {
              "email": "reppowell@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "5154 Butler St., Pittsburgh, PA 15201",
              "phone1": null,
              "phone2": "412-781-2750",
              "fax1": null,
              "fax2": "412-781-2892",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=2016",
                "https://www.pahouse.com/Powell/",
                "https://www.palegis.us/house/members/bio/2016/rep-powell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-21-lower-ocd-person-b7c8d063-8db4-4cdf-a159-a0b064b7cae5": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/52f7d2da-ab1a-4cac-a08a-249b4d0507fe",
            "firstName": "Joshua M.",
            "lastName": "Siegel",
            "fullName": "Josh Siegel",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1940.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-PA-22-lower-ocd-person-52f7d2da-ab1a-4cac-a08a-249b4d0507fe",
              "builtID": "pa-lower-22",
              "externalID": "ocd-person/52f7d2da-ab1a-4cac-a08a-249b4d0507fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "repsiegel@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "1801 Union Blvd. Suite H-2, 2nd Floor, Allentown, PA 18109",
              "phone1": null,
              "phone2": "484-656-7744",
              "fax1": null,
              "fax2": null,
              "twitter": "RepJoshSiegel",
              "youtube": null,
              "instagram": null,
              "facebook": "RepJoshSiegel",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1940",
                "https://www.pahouse.com/Siegel",
                "https://www.palegis.us/house/members/bio/1940/rep-siegel"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-22-lower-ocd-person-52f7d2da-ab1a-4cac-a08a-249b4d0507fe": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/96667f6d-d967-4f6a-9cd9-23bf480a98c1",
            "firstName": "Daniel B.",
            "lastName": "Frankel",
            "fullName": "Dan Frankel",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/84.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-PA-23-lower-ocd-person-96667f6d-d967-4f6a-9cd9-23bf480a98c1",
              "builtID": "pa-lower-23",
              "externalID": "ocd-person/96667f6d-d967-4f6a-9cd9-23bf480a98c1",
              "geometry": null
            },
            "contactInfo": {
              "email": "repfrankel@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "2345 Murray Ave. Suite 205, Pittsburgh, PA 15217",
              "phone1": null,
              "phone2": "412-422-1774",
              "fax1": null,
              "fax2": "412-420-2011",
              "twitter": "RepDanFrankel",
              "youtube": "playlist?list=PL73E178C33BCAABA5",
              "instagram": null,
              "facebook": "RepDanFrankel",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=84",
                "https://www.pahouse.com/frankel",
                "https://www.palegis.us/house/members/bio/84/rep-frankel"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-23-lower-ocd-person-96667f6d-d967-4f6a-9cd9-23bf480a98c1": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/526967db-cef3-4f64-a380-1377f5c6f1fe",
            "firstName": "La'Tasha Denise",
            "lastName": "Mayes",
            "fullName": "La'Tasha Mayes",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1941.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-PA-24-lower-ocd-person-526967db-cef3-4f64-a380-1377f5c6f1fe",
              "builtID": "pa-lower-24",
              "externalID": "ocd-person/526967db-cef3-4f64-a380-1377f5c6f1fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "repmayes@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "2015-2017 Centre Ave. Floor 1, Pittsburgh, PA 15219",
              "phone1": null,
              "phone2": "412-665-5502",
              "fax1": null,
              "fax2": null,
              "twitter": "RepMayes",
              "youtube": null,
              "instagram": null,
              "facebook": "RepMayes",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1941",
                "https://www.pahouse.com/Mayes/",
                "https://www.palegis.us/house/members/bio/1941/rep-mayes"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-24-lower-ocd-person-526967db-cef3-4f64-a380-1377f5c6f1fe": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/9aea39f8-1503-4fea-9440-1e0e9c2f8fa3",
            "firstName": "Brandon J.",
            "lastName": "Markosek",
            "fullName": "Brandon Markosek",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1825.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-PA-25-lower-ocd-person-9aea39f8-1503-4fea-9440-1e0e9c2f8fa3",
              "builtID": "pa-lower-25",
              "externalID": "ocd-person/9aea39f8-1503-4fea-9440-1e0e9c2f8fa3",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandonmarkosek@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "Commerce Building 4232 Northern Pike, Monroeville, PA 15146",
              "phone1": null,
              "phone2": "412-856-8284",
              "fax1": null,
              "fax2": "412-374-9242",
              "twitter": "RepMarkosek",
              "youtube": "playlist?list=PLjtqNtfs0bgVCacDsFFGDNYpb65Amu0Xk",
              "instagram": null,
              "facebook": "RepMarkosek",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1825",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/house_bio.cfm?id=1825",
                "https://www.palegis.us/house/members/bio/1825/rep-markosek"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-25-lower-ocd-person-9aea39f8-1503-4fea-9440-1e0e9c2f8fa3": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/494797a5-3d84-414a-9c8c-caaadd621cdd",
            "firstName": "Paul",
            "lastName": "Friel",
            "fullName": "Paul Friel",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1942.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-PA-26-lower-ocd-person-494797a5-3d84-414a-9c8c-caaadd621cdd",
              "builtID": "pa-lower-26",
              "externalID": "ocd-person/494797a5-3d84-414a-9c8c-caaadd621cdd",
              "geometry": null
            },
            "contactInfo": {
              "email": "repfriel@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "68 Glocker Way, Pottstown, PA 19465",
              "phone1": null,
              "phone2": "610-427-8782",
              "fax1": null,
              "fax2": null,
              "twitter": "RepFriel",
              "youtube": null,
              "instagram": null,
              "facebook": "RepFriel",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1942",
                "https://www.pahouse.com/friel/",
                "https://www.palegis.us/house/members/bio/1942/rep-friel"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-26-lower-ocd-person-494797a5-3d84-414a-9c8c-caaadd621cdd": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/2608882d-9ae2-4522-b8b2-83d905913d3f",
            "firstName": "Daniel J.",
            "lastName": "Deasy",
            "fullName": "Dan Deasy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1166.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-PA-27-lower-ocd-person-2608882d-9ae2-4522-b8b2-83d905913d3f",
              "builtID": "pa-lower-27",
              "externalID": "ocd-person/2608882d-9ae2-4522-b8b2-83d905913d3f",
              "geometry": null
            },
            "contactInfo": {
              "email": "ddeasy@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "1074 Greentree Road, Pittsburgh, PA 15220",
              "phone1": null,
              "phone2": "412-928-9514",
              "fax1": null,
              "fax2": "412-565-3170",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1166",
                "https://www.pahouse.com/Deasy/",
                "https://www.palegis.us/house/members/bio/1166/rep-deasy"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-27-lower-ocd-person-2608882d-9ae2-4522-b8b2-83d905913d3f": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/4e42837c-a40f-4712-be14-70e14b438867",
            "firstName": "Robert W.",
            "lastName": "Mercuri",
            "fullName": "Rob Mercuri",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1897.jpg?1703415645672g",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-PA-28-lower-ocd-person-4e42837c-a40f-4712-be14-70e14b438867",
              "builtID": "pa-lower-28",
              "externalID": "ocd-person/4e42837c-a40f-4712-be14-70e14b438867",
              "geometry": null
            },
            "contactInfo": {
              "email": "rmercuri@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "5500 Brooktree Road Suite 100, Wexford, PA 15090",
              "phone1": null,
              "phone2": "412-369-2230",
              "fax1": null,
              "fax2": "724-934-6443",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "reprobmercuri",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.reprobmercuri.com/",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1897",
                "https://www.palegis.us/house/members/bio/1897/rep-mercuri"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-28-lower-ocd-person-4e42837c-a40f-4712-be14-70e14b438867": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/de68158e-3cc9-4651-b3a1-71f5e77b7dab",
            "firstName": "Timothy Patrick",
            "lastName": "Brennan",
            "fullName": "Tim Brennan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1943.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-PA-29-lower-ocd-person-de68158e-3cc9-4651-b3a1-71f5e77b7dab",
              "builtID": "pa-lower-29",
              "externalID": "ocd-person/de68158e-3cc9-4651-b3a1-71f5e77b7dab",
              "geometry": null
            },
            "contactInfo": {
              "email": "repbrennan@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "80 N. Main St. Suite 1A, Doylestown, PA 18901",
              "phone1": null,
              "phone2": "267-884-0395",
              "fax1": null,
              "fax2": "267-884-0382",
              "twitter": "RepTimBrennan",
              "youtube": null,
              "instagram": null,
              "facebook": "RepTimBrennan",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1943",
                "https://www.pahouse.com/brennan/",
                "https://www.palegis.us/house/members/bio/1943/rep-brennan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-29-lower-ocd-person-de68158e-3cc9-4651-b3a1-71f5e77b7dab": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/f4e98ad8-2ee6-45e4-8594-0deaaa51190e",
            "firstName": "Arvind",
            "lastName": "Venkat",
            "fullName": "Arvind Venkat",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1944.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-PA-30-lower-ocd-person-f4e98ad8-2ee6-45e4-8594-0deaaa51190e",
              "builtID": "pa-lower-30",
              "externalID": "ocd-person/f4e98ad8-2ee6-45e4-8594-0deaaa51190e",
              "geometry": null
            },
            "contactInfo": {
              "email": "repvenkat@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "9800B McKnight Road Suite 130, Pittsburgh, PA 15237",
              "phone1": null,
              "phone2": "412-348-8028",
              "fax1": null,
              "fax2": "412-348-8026",
              "twitter": "RepVenkat",
              "youtube": null,
              "instagram": null,
              "facebook": "RepVenkat",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1944",
                "https://www.pahouse.com/venkat/",
                "https://www.palegis.us/house/members/bio/1944/rep-venkat"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-30-lower-ocd-person-f4e98ad8-2ee6-45e4-8594-0deaaa51190e": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/e2bc8bc4-33ab-46dd-8f67-da218949efb6",
            "firstName": "Perry S.",
            "lastName": "Warren",
            "fullName": "Perry Warren",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1743.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-PA-31-lower-ocd-person-e2bc8bc4-33ab-46dd-8f67-da218949efb6",
              "builtID": "pa-lower-31",
              "externalID": "ocd-person/e2bc8bc4-33ab-46dd-8f67-da218949efb6",
              "geometry": null
            },
            "contactInfo": {
              "email": "repwarren@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "509 Floral Vale Blvd., Yardley, PA 19067",
              "phone1": null,
              "phone2": "215-493-5420",
              "fax1": null,
              "fax2": "215-493-5424",
              "twitter": "RepPerryWarren",
              "youtube": null,
              "instagram": null,
              "facebook": "RepPerryWarren",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1743",
                "https://www.pahouse.com/Warren/",
                "https://www.palegis.us/house/members/bio/1743/rep-warren"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-31-lower-ocd-person-e2bc8bc4-33ab-46dd-8f67-da218949efb6": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/d31e549e-2c3d-4ea3-850b-5285955c3c2f",
            "firstName": "Joseph M.",
            "lastName": "McAndrew",
            "fullName": "Joe McAndrew",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/2011.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-PA-32-lower-ocd-person-d31e549e-2c3d-4ea3-850b-5285955c3c2f",
              "builtID": "pa-lower-32",
              "externalID": "ocd-person/d31e549e-2c3d-4ea3-850b-5285955c3c2f",
              "geometry": null
            },
            "contactInfo": {
              "email": "repmcandrew@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "77 Universal Road Suite 1, Penn Hills, PA 15235",
              "phone1": null,
              "phone2": "412-793-2448",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=2011",
                "https://www.pahouse.com/mcandrew",
                "https://www.palegis.us/house/members/bio/2011/rep-mcandrew"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-32-lower-ocd-person-d31e549e-2c3d-4ea3-850b-5285955c3c2f": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/63f8d7ea-b621-48b7-a326-4e2a732a521c",
            "firstName": "Mandy",
            "lastName": "Steele",
            "fullName": "Mandy Steele",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1945.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-PA-33-lower-ocd-person-63f8d7ea-b621-48b7-a326-4e2a732a521c",
              "builtID": "pa-lower-33",
              "externalID": "ocd-person/63f8d7ea-b621-48b7-a326-4e2a732a521c",
              "geometry": null
            },
            "contactInfo": {
              "email": "repsteele@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "1296 Pittsburgh St., Cheswick, PA 15204",
              "phone1": null,
              "phone2": "724-826-5151",
              "fax1": null,
              "fax2": null,
              "twitter": "RepSteele",
              "youtube": null,
              "instagram": null,
              "facebook": "RepSteele",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1945",
                "https://www.pahouse.com/steele/",
                "https://www.palegis.us/house/members/bio/1945/rep-steele"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-33-lower-ocd-person-63f8d7ea-b621-48b7-a326-4e2a732a521c": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/4bc74848-3896-4a0a-9310-970458f48d1a",
            "firstName": "Abigail",
            "lastName": "Salisbury",
            "fullName": "Abigail Salisbury",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/2012.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-PA-34-lower-ocd-person-4bc74848-3896-4a0a-9310-970458f48d1a",
              "builtID": "pa-lower-34",
              "externalID": "ocd-person/4bc74848-3896-4a0a-9310-970458f48d1a",
              "geometry": null
            },
            "contactInfo": {
              "email": "repsalisbury@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "501 Braddock Ave., Braddock, PA 15104",
              "phone1": null,
              "phone2": "412-273-3400",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=2012",
                "https://www.pahouse.com/salisbury",
                "https://www.palegis.us/house/members/bio/2012/rep-salisbury"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-34-lower-ocd-person-4bc74848-3896-4a0a-9310-970458f48d1a": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/2a6db8e9-a94e-4f79-82d3-23cbba25e79a",
            "firstName": "Matthew R.",
            "lastName": "Gergely",
            "fullName": "Matt Gergely",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/2013.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-PA-35-lower-ocd-person-2a6db8e9-a94e-4f79-82d3-23cbba25e79a",
              "builtID": "pa-lower-35",
              "externalID": "ocd-person/2a6db8e9-a94e-4f79-82d3-23cbba25e79a",
              "geometry": null
            },
            "contactInfo": {
              "email": "repgergely@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "551 Ravensburg Blvd., Clairton, PA 15025",
              "phone1": null,
              "phone2": "412-233-2505",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=2013",
                "https://www.pahouse.com/gergely",
                "https://www.palegis.us/house/members/bio/2013/rep-gergely"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-35-lower-ocd-person-2a6db8e9-a94e-4f79-82d3-23cbba25e79a": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/8b6409e9-8ab6-4222-b6f3-fb8f33800c95",
            "firstName": "Jessica L.",
            "lastName": "Benham",
            "fullName": "Jessica Benham",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1899.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-PA-36-lower-ocd-person-8b6409e9-8ab6-4222-b6f3-fb8f33800c95",
              "builtID": "pa-lower-36",
              "externalID": "ocd-person/8b6409e9-8ab6-4222-b6f3-fb8f33800c95",
              "geometry": null
            },
            "contactInfo": {
              "email": "repbenham@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "1810 Brownsville Road, Pittsburgh, PA 15210",
              "phone1": null,
              "phone2": "412-881-4208",
              "fax1": null,
              "fax2": null,
              "twitter": "RepBenham",
              "youtube": null,
              "instagram": null,
              "facebook": "RepBenham",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1899",
                "https://www.pahouse.com/benham/",
                "https://www.palegis.us/house/members/bio/1899/rep-benham"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-36-lower-ocd-person-8b6409e9-8ab6-4222-b6f3-fb8f33800c95": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/f5b2ec4d-1d69-483a-a6ec-143d50ad395b",
            "firstName": "Melinda S.",
            "lastName": "Fee",
            "fullName": "Mindy Fee",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1625.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-PA-37-lower-ocd-person-f5b2ec4d-1d69-483a-a6ec-143d50ad395b",
              "builtID": "pa-lower-37",
              "externalID": "ocd-person/f5b2ec4d-1d69-483a-a6ec-143d50ad395b",
              "geometry": null
            },
            "contactInfo": {
              "email": "mfee@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "47 Market Square, Manheim, PA 17545",
              "phone1": null,
              "phone2": "717-664-4979",
              "fax1": null,
              "fax2": "717-664-4997",
              "twitter": null,
              "youtube": "RepFee",
              "instagram": null,
              "facebook": "RepFee",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1625",
                "https://www.repfee.com/",
                "https://www.palegis.us/house/members/bio/1625/rep-fee"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-37-lower-ocd-person-f5b2ec4d-1d69-483a-a6ec-143d50ad395b": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/c9f6833a-470e-480f-b3ad-d91c887aa4f9",
            "firstName": "Nickolas R.",
            "lastName": "Pisciottano",
            "fullName": "Nick Pisciottano",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1900.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-PA-38-lower-ocd-person-c9f6833a-470e-480f-b3ad-d91c887aa4f9",
              "builtID": "pa-lower-38",
              "externalID": "ocd-person/c9f6833a-470e-480f-b3ad-d91c887aa4f9",
              "geometry": null
            },
            "contactInfo": {
              "email": "reppisciottano@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "3563 Mountain View Drive, West Mifflin, PA 15122",
              "phone1": null,
              "phone2": "412-466-1940",
              "fax1": null,
              "fax2": null,
              "twitter": "RepPisciottano",
              "youtube": null,
              "instagram": null,
              "facebook": "RepPisciottano",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1900",
                "https://www.pahouse.com/pisciottano/",
                "https://www.palegis.us/house/members/bio/1900/rep-pisciottano"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-38-lower-ocd-person-c9f6833a-470e-480f-b3ad-d91c887aa4f9": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/0d86d77b-ff68-4182-be53-09ff196b8174",
            "firstName": "Andrew Michael",
            "lastName": "Kuzma",
            "fullName": "Andrew Kuzma",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1946.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-PA-39-lower-ocd-person-0d86d77b-ff68-4182-be53-09ff196b8174",
              "builtID": "pa-lower-39",
              "externalID": "ocd-person/0d86d77b-ff68-4182-be53-09ff196b8174",
              "geometry": null
            },
            "contactInfo": {
              "email": "akuzma@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "491 E. Bruceton Road Suite 102, Pleasant Hills, PA 15236",
              "phone1": null,
              "phone2": "412-382-2009",
              "fax1": null,
              "fax2": "412-384-3758",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepKuzma",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1946",
                "https://www.repkuzma.com/",
                "https://www.palegis.us/house/members/bio/1946/rep-kuzma"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-39-lower-ocd-person-0d86d77b-ff68-4182-be53-09ff196b8174": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/c5e053dd-a874-4a51-a071-661a20db33ab",
            "firstName": "Natalie Nichole",
            "lastName": "Mihalek Stuck",
            "fullName": "Natalie Mihalek",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1830.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-PA-40-lower-ocd-person-c5e053dd-a874-4a51-a071-661a20db33ab",
              "builtID": "pa-lower-40",
              "externalID": "ocd-person/c5e053dd-a874-4a51-a071-661a20db33ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "nmihalek@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "1121 Boyce Road Suite 2200A, Upper St. Clair, PA 15241",
              "phone1": null,
              "phone2": "724-942-2045",
              "fax1": null,
              "fax2": "724-942-2046",
              "twitter": null,
              "youtube": "UCBPJMqP9CJAgFlSNP3UlmxQ",
              "instagram": null,
              "facebook": "RepMihalek",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1830",
                "https://www.repmihalek.com/",
                "https://www.palegis.us/house/members/bio/1830/rep-mihalek"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-40-lower-ocd-person-c5e053dd-a874-4a51-a071-661a20db33ab": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/1a6e36d3-ff44-4e21-bb9d-397a56482b34",
            "firstName": "Brett R.",
            "lastName": "Miller",
            "fullName": "Brett Miller",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1699.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-PA-41-lower-ocd-person-1a6e36d3-ff44-4e21-bb9d-397a56482b34",
              "builtID": "pa-lower-41",
              "externalID": "ocd-person/1a6e36d3-ff44-4e21-bb9d-397a56482b34",
              "geometry": null
            },
            "contactInfo": {
              "email": "bmiller@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "132 Locust St. Suite 102, Columbia, PA 17512",
              "phone1": null,
              "phone2": "717-295-5050",
              "fax1": null,
              "fax2": "717-664-4982",
              "twitter": null,
              "youtube": "RepBrettMiller",
              "instagram": null,
              "facebook": "RepMiller",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1699",
                "https://www.repmiller.com/",
                "https://www.palegis.us/house/members/bio/1699/rep-b-miller"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-41-lower-ocd-person-1a6e36d3-ff44-4e21-bb9d-397a56482b34": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/cf94e0e7-498b-419b-9d3a-36c31d1eaf46",
            "firstName": "Daniel Laurenzano",
            "lastName": "Miller",
            "fullName": "Dan Miller",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1679.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-PA-42-lower-ocd-person-cf94e0e7-498b-419b-9d3a-36c31d1eaf46",
              "builtID": "pa-lower-42",
              "externalID": "ocd-person/cf94e0e7-498b-419b-9d3a-36c31d1eaf46",
              "geometry": null
            },
            "contactInfo": {
              "email": "repmiller@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "650 Washington Road Suite 102, Mt. Lebanon, PA 15228",
              "phone1": null,
              "phone2": "412-343-3870",
              "fax1": null,
              "fax2": "412-343-2050",
              "twitter": null,
              "youtube": "playlist?list=PLjtqNtfs0bgUKeWXUxQSWHzQzU74mgw-5",
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1679",
                "https://www.pahouse.com/Miller/",
                "https://www.palegis.us/house/members/bio/1679/rep-d-miller"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-42-lower-ocd-person-cf94e0e7-498b-419b-9d3a-36c31d1eaf46": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/dcd0037e-7833-416d-9523-5f25802bd43a",
            "firstName": "Keith J.",
            "lastName": "Greiner",
            "fullName": "Keith Greiner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1632.jpg?1703415645672g",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-PA-43-lower-ocd-person-dcd0037e-7833-416d-9523-5f25802bd43a",
              "builtID": "pa-lower-43",
              "externalID": "ocd-person/dcd0037e-7833-416d-9523-5f25802bd43a",
              "geometry": null
            },
            "contactInfo": {
              "email": "kgreiner@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "West Earl Township Building 157 W. Metzler Road, Suite 103, Ephrata, PA 17522",
              "phone1": null,
              "phone2": "717-588-2044",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": "RepGreiner",
              "instagram": null,
              "facebook": "RepGreiner",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1632",
                "https://www.repgreiner.com/",
                "https://www.palegis.us/house/members/bio/1632/rep-greiner"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-43-lower-ocd-person-dcd0037e-7833-416d-9523-5f25802bd43a": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/590df273-5587-46bd-98dc-f3943cd1ddd8",
            "firstName": "Valerie S.",
            "lastName": "Gaydos",
            "fullName": "Valerie Gaydos",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1831.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-PA-44-lower-ocd-person-590df273-5587-46bd-98dc-f3943cd1ddd8",
              "builtID": "pa-lower-44",
              "externalID": "ocd-person/590df273-5587-46bd-98dc-f3943cd1ddd8",
              "geometry": null
            },
            "contactInfo": {
              "email": "vgaydos@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "1005 Beaver Grade Road Suite 106, Moon Township, PA 15108",
              "phone1": null,
              "phone2": "412-262-3780",
              "fax1": null,
              "fax2": "412-262-3783",
              "twitter": null,
              "youtube": "UCdBkDQL4ync9p-qbvbNENkg",
              "instagram": "repgaydos",
              "facebook": "RepGaydos",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1831",
                "https://www.palegis.us/house/members/bio/1831/rep-gaydos"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-44-lower-ocd-person-590df273-5587-46bd-98dc-f3943cd1ddd8": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/999d1ffd-b893-4aac-9fbb-ae43b3039038",
            "firstName": "Anita Astorino",
            "lastName": "Kulik",
            "fullName": "Anita Kulik",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1744.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-PA-45-lower-ocd-person-999d1ffd-b893-4aac-9fbb-ae43b3039038",
              "builtID": "pa-lower-45",
              "externalID": "ocd-person/999d1ffd-b893-4aac-9fbb-ae43b3039038",
              "geometry": null
            },
            "contactInfo": {
              "email": "akulik@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "1350 5th Ave., Coraopolis, PA 15108",
              "phone1": null,
              "phone2": "412-264-4260",
              "fax1": null,
              "fax2": "412-269-2767",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1744",
                "https://www.pahouse.com/kotik",
                "https://www.palegis.us/house/members/bio/1744/rep-kulik"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-45-lower-ocd-person-999d1ffd-b893-4aac-9fbb-ae43b3039038": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/1871b5c7-35c4-4d32-9e09-9febf8ba3f66",
            "firstName": "Jason A.",
            "lastName": "Ortitay",
            "fullName": "Jason Ortitay",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1701.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-PA-46-lower-ocd-person-1871b5c7-35c4-4d32-9e09-9febf8ba3f66",
              "builtID": "pa-lower-46",
              "externalID": "ocd-person/1871b5c7-35c4-4d32-9e09-9febf8ba3f66",
              "geometry": null
            },
            "contactInfo": {
              "email": "jortitay@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "300 Old Pond Road Suite 205A, Bridgeville, PA 15017",
              "phone1": null,
              "phone2": "412-221-5110",
              "fax1": null,
              "fax2": "412-221-5113",
              "twitter": null,
              "youtube": "RepOrtitay",
              "instagram": null,
              "facebook": "PA-State-Rep-Jason-Ortitay",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1701",
                "https://www.reportitay.com/",
                "https://www.palegis.us/house/members/bio/1701/rep-ortitay"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-46-lower-ocd-person-1871b5c7-35c4-4d32-9e09-9febf8ba3f66": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/b698d343-137d-423b-a102-c471f1fd1768",
            "firstName": "Joseph Frank",
            "lastName": "D'Orsie",
            "fullName": "Joe D'Orsie",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1947.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-PA-47-lower-ocd-person-b698d343-137d-423b-a102-c471f1fd1768",
              "builtID": "pa-lower-47",
              "externalID": "ocd-person/b698d343-137d-423b-a102-c471f1fd1768",
              "geometry": null
            },
            "contactInfo": {
              "email": "jdorsie@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "4186 Lincoln Highway, York, PA 17406",
              "phone1": null,
              "phone2": "717-840-4711",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepJoeD",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1947",
                "https://www.repjoed.com/",
                "https://www.palegis.us/house/members/bio/1947/rep-d'orsie"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-47-lower-ocd-person-b698d343-137d-423b-a102-c471f1fd1768": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/2c5551d6-71a8-4099-b1a4-a30721320869",
            "firstName": "Timothy J.",
            "lastName": "O'Neal",
            "fullName": "Tim O'Neal",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1797.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-PA-48-lower-ocd-person-2c5551d6-71a8-4099-b1a4-a30721320869",
              "builtID": "pa-lower-48",
              "externalID": "ocd-person/2c5551d6-71a8-4099-b1a4-a30721320869",
              "geometry": null
            },
            "contactInfo": {
              "email": "toneal@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "190 N. Main St. Suite 308, Washington, PA 15301",
              "phone1": null,
              "phone2": "724-223-4541",
              "fax1": null,
              "fax2": "724-223-4544",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepTimONeal",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1797",
                "https://www.reponeal.com/",
                "https://www.palegis.us/house/members/bio/1797/rep-o'neal"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-48-lower-ocd-person-2c5551d6-71a8-4099-b1a4-a30721320869": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/fefff26b-b89a-4400-b633-99e5aa09523b",
            "firstName": "Ismail",
            "lastName": "Smith-Wade-El",
            "fullName": "Izzy Smith-Wade-El",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1948.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-PA-49-lower-ocd-person-fefff26b-b89a-4400-b633-99e5aa09523b",
              "builtID": "pa-lower-49",
              "externalID": "ocd-person/fefff26b-b89a-4400-b633-99e5aa09523b",
              "geometry": null
            },
            "contactInfo": {
              "email": "repsmithwadeel@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "150 E. King St. Suite B, Lancaster, PA 17602",
              "phone1": null,
              "phone2": "717-283-4218",
              "fax1": null,
              "fax2": "717-283-4146",
              "twitter": "RepIzzy",
              "youtube": null,
              "instagram": null,
              "facebook": "RepIzzy",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1948",
                "https://www.pahouse.com/Smith-Wade-El/",
                "https://www.palegis.us/house/members/bio/1948/rep-smith-wade-el"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-49-lower-ocd-person-fefff26b-b89a-4400-b633-99e5aa09523b": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/9b9857aa-5fad-4678-a4f9-7e40bc642f64",
            "firstName": "Donald",
            "lastName": "Cook",
            "fullName": "Bud Cook",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1745.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-PA-50-lower-ocd-person-9b9857aa-5fad-4678-a4f9-7e40bc642f64",
              "builtID": "pa-lower-50",
              "externalID": "ocd-person/9b9857aa-5fad-4678-a4f9-7e40bc642f64",
              "geometry": null
            },
            "contactInfo": {
              "email": "bcook@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "Center in the Woods 130 Woodland Court, Brownsville, PA 15417",
              "phone1": null,
              "phone2": "724-929-2660",
              "fax1": null,
              "fax2": "724-938-5352",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "repbudcook",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1745",
                "https://www.repbudcook.com/",
                "https://www.palegis.us/house/members/bio/1745/rep-cook"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-50-lower-ocd-person-9b9857aa-5fad-4678-a4f9-7e40bc642f64": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/d5bd036c-03f5-4111-b770-15cb2dbe3278",
            "firstName": "Charity Grimm",
            "lastName": "Krupa",
            "fullName": "Charity Krupa",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1949.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-PA-51-lower-ocd-person-d5bd036c-03f5-4111-b770-15cb2dbe3278",
              "builtID": "pa-lower-51",
              "externalID": "ocd-person/d5bd036c-03f5-4111-b770-15cb2dbe3278",
              "geometry": null
            },
            "contactInfo": {
              "email": "ckrupa@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "870 McClellandtown Road Suite 5/6, McClellandtown, PA 15458",
              "phone1": null,
              "phone2": "724-438-6100",
              "fax1": null,
              "fax2": "724-438-6101",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepGrimmKrupa",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1949",
                "https://www.repgrimmkrupa.com/",
                "https://www.palegis.us/house/members/bio/1949/rep-krupa"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-51-lower-ocd-person-d5bd036c-03f5-4111-b770-15cb2dbe3278": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/0d747b7a-0a5a-42cf-8bb6-1eb0fa69090f",
            "firstName": "Ryan James",
            "lastName": "Warner",
            "fullName": "Ryan Warner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1708.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-PA-52-lower-ocd-person-0d747b7a-0a5a-42cf-8bb6-1eb0fa69090f",
              "builtID": "pa-lower-52",
              "externalID": "ocd-person/0d747b7a-0a5a-42cf-8bb6-1eb0fa69090f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rwarner@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "Perryopolis Borough Building 312 E. Independence St., Perryopolis, PA 15473",
              "phone1": null,
              "phone2": "724-736-2290",
              "fax1": null,
              "fax2": "724-736-2293",
              "twitter": null,
              "youtube": "RepWarner",
              "instagram": null,
              "facebook": "RepWarner",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1708",
                "https://www.repwarner.com/",
                "https://www.palegis.us/house/members/bio/1708/rep-warner"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-52-lower-ocd-person-0d747b7a-0a5a-42cf-8bb6-1eb0fa69090f": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/fa1e1564-0a13-4b26-8416-098b39627df7",
            "firstName": "Steven R.",
            "lastName": "Malagari",
            "fullName": "Steve Malagari",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1832.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-PA-53-lower-ocd-person-fa1e1564-0a13-4b26-8416-098b39627df7",
              "builtID": "pa-lower-53",
              "externalID": "ocd-person/fa1e1564-0a13-4b26-8416-098b39627df7",
              "geometry": null
            },
            "contactInfo": {
              "email": "smalagari@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "100 W. Main St. Suite 110, Lansdale, PA 19446",
              "phone1": null,
              "phone2": "267-768-3671",
              "fax1": null,
              "fax2": "267-768-3673",
              "twitter": "RepMalagari",
              "youtube": "playlist?list=PLjtqNtfs0bgVFSzCIMki7jJ9BI-D9gi8Y",
              "instagram": null,
              "facebook": "RepMalagari",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1832",
                "https://www.pahouse.com/malagari",
                "https://www.palegis.us/house/members/bio/1832/rep-malagari"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-53-lower-ocd-person-fa1e1564-0a13-4b26-8416-098b39627df7": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/969ce6cb-ddcb-4347-b69d-2019cfbd6e18",
            "firstName": "Gregory L.",
            "lastName": "Scott",
            "fullName": "Greg Scott",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1950.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-PA-54-lower-ocd-person-969ce6cb-ddcb-4347-b69d-2019cfbd6e18",
              "builtID": "pa-lower-54",
              "externalID": "ocd-person/969ce6cb-ddcb-4347-b69d-2019cfbd6e18",
              "geometry": null
            },
            "contactInfo": {
              "email": "repscott@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "1019 W. Main St., Norristown, PA 19401",
              "phone1": null,
              "phone2": "484-685-3494",
              "fax1": null,
              "fax2": "484-685-3406",
              "twitter": "RepGregScott",
              "youtube": null,
              "instagram": null,
              "facebook": "RepGregScott",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1950",
                "https://www.pahouse.com/scott/",
                "https://www.palegis.us/house/members/bio/1950/rep-scott"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-54-lower-ocd-person-969ce6cb-ddcb-4347-b69d-2019cfbd6e18": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/6ae972c8-aff3-4886-a7d7-bd2cc5e40a38",
            "firstName": "Jill Nixon",
            "lastName": "Cooper",
            "fullName": "Jill Cooper",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1951.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-PA-55-lower-ocd-person-6ae972c8-aff3-4886-a7d7-bd2cc5e40a38",
              "builtID": "pa-lower-55",
              "externalID": "ocd-person/6ae972c8-aff3-4886-a7d7-bd2cc5e40a38",
              "geometry": null
            },
            "contactInfo": {
              "email": "jcooper@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "Murrysville Community Center 3091 Carson Ave., Murrysville, PA 15668",
              "phone1": null,
              "phone2": "724-387-1281",
              "fax1": null,
              "fax2": "724-387-1295",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepJillCooper",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1951",
                "https://www.repjillcooper.com/",
                "https://www.palegis.us/house/members/bio/1951/rep-cooper"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-55-lower-ocd-person-6ae972c8-aff3-4886-a7d7-bd2cc5e40a38": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/6a971ef6-eb92-434b-a390-c658cfbf2d00",
            "firstName": "George S.",
            "lastName": "Dunbar",
            "fullName": "George Dunbar",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1206.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-PA-56-lower-ocd-person-6a971ef6-eb92-434b-a390-c658cfbf2d00",
              "builtID": "pa-lower-56",
              "externalID": "ocd-person/6a971ef6-eb92-434b-a390-c658cfbf2d00",
              "geometry": null
            },
            "contactInfo": {
              "email": "gdunbar@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "2090 Harrison Ave. Suite 4, Lower Level Pine Center, Jeannette, PA 15644",
              "phone1": null,
              "phone2": "724-744-0305",
              "fax1": null,
              "fax2": "724-744-0380",
              "twitter": null,
              "youtube": "RepDunbar",
              "instagram": null,
              "facebook": "RepDunbar",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1206",
                "https://www.palegis.us/house/members/bio/1206/rep-dunbar"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-56-lower-ocd-person-6a971ef6-eb92-434b-a390-c658cfbf2d00": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/0910d394-3c42-48ee-aea7-6d4c0c144130",
            "firstName": "Eric R.",
            "lastName": "Nelson",
            "fullName": "Eric Nelson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1738.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-PA-57-lower-ocd-person-0910d394-3c42-48ee-aea7-6d4c0c144130",
              "builtID": "pa-lower-57",
              "externalID": "ocd-person/0910d394-3c42-48ee-aea7-6d4c0c144130",
              "geometry": null
            },
            "contactInfo": {
              "email": "enelson@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "17 S. 6th St., Youngwood, PA 15697",
              "phone1": null,
              "phone2": "724-925-5490",
              "fax1": null,
              "fax2": "724-925-5491",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1738",
                "https://www.repnelson.com/",
                "https://www.palegis.us/house/members/bio/1738/rep-e-nelson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-57-lower-ocd-person-0910d394-3c42-48ee-aea7-6d4c0c144130": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/80abce94-a517-4357-a70d-cbc791aa34a1",
            "firstName": "Eric",
            "lastName": "Davanzo",
            "fullName": "Eric Davanzo",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1875.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-PA-58-lower-ocd-person-80abce94-a517-4357-a70d-cbc791aa34a1",
              "builtID": "pa-lower-58",
              "externalID": "ocd-person/80abce94-a517-4357-a70d-cbc791aa34a1",
              "geometry": null
            },
            "contactInfo": {
              "email": "edavanzo@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "156C Clay Pike, North Huntingdon, PA 15642",
              "phone1": null,
              "phone2": "724-863-1586",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepDavanzo",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1875",
                "https://www.repdavanzo.com/",
                "https://www.palegis.us/house/members/bio/1875/rep-davanzo"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-58-lower-ocd-person-80abce94-a517-4357-a70d-cbc791aa34a1": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/71bb086e-11d9-496d-b274-91d7a8b85eaf",
            "firstName": "Leslie Baum",
            "lastName": "Rossi",
            "fullName": "Leslie Rossi",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1927.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-PA-59-lower-ocd-person-71bb086e-11d9-496d-b274-91d7a8b85eaf",
              "builtID": "pa-lower-59",
              "externalID": "ocd-person/71bb086e-11d9-496d-b274-91d7a8b85eaf",
              "geometry": null
            },
            "contactInfo": {
              "email": "lrossi@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 659 901 Jefferson St., Latrobe, PA 15650",
              "phone1": null,
              "phone2": "724-537-3300",
              "fax1": null,
              "fax2": "724-520-8058",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1927",
                "https://www.reprossi.com/",
                "https://www.palegis.us/house/members/bio/1927/rep-rossi"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-59-lower-ocd-person-71bb086e-11d9-496d-b274-91d7a8b85eaf": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/db196239-5fca-4218-82ee-ce1f6cf03ec4",
            "firstName": "Abigail E.",
            "lastName": "Major",
            "fullName": "Abby Major",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1926.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-PA-60-lower-ocd-person-db196239-5fca-4218-82ee-ce1f6cf03ec4",
              "builtID": "pa-lower-60",
              "externalID": "ocd-person/db196239-5fca-4218-82ee-ce1f6cf03ec4",
              "geometry": null
            },
            "contactInfo": {
              "email": "amajor@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "312 Ford St., Ford City, PA 16226",
              "phone1": null,
              "phone2": "724-763-3222",
              "fax1": null,
              "fax2": "724-763-9788",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1926",
                "https://www.repabbymajor.com/",
                "https://www.palegis.us/house/members/bio/1926/rep-major"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-60-lower-ocd-person-db196239-5fca-4218-82ee-ce1f6cf03ec4": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/0aa9e1d6-4552-4ad4-b660-e2f3cc17b7dd",
            "firstName": "Laura Elizabeth Francis",
            "lastName": "Hanbidge",
            "fullName": "Liz Hanbidge",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1834.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-PA-61-lower-ocd-person-0aa9e1d6-4552-4ad4-b660-e2f3cc17b7dd",
              "builtID": "pa-lower-61",
              "externalID": "ocd-person/0aa9e1d6-4552-4ad4-b660-e2f3cc17b7dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "lhanbidge@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "1098 W. Skippack Pike, Blue Bell, PA 19422",
              "phone1": null,
              "phone2": "610-277-3230",
              "fax1": null,
              "fax2": "484-200-8267",
              "twitter": "RepHanbidge",
              "youtube": "playlist?list=PLjtqNtfs0bgWHK4t2_JcE0FjGCJskjkgu",
              "instagram": null,
              "facebook": "RepHanbidge",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1834",
                "https://www.pahouse.com/hanbidge",
                "https://www.palegis.us/house/members/bio/1834/rep-hanbidge"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-61-lower-ocd-person-0aa9e1d6-4552-4ad4-b660-e2f3cc17b7dd": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/4a24dcc2-5619-4bb1-acb3-bf7a7d08e6b1",
            "firstName": "James B.",
            "lastName": "Struzzi",
            "fullName": "Jim Struzzi",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1835.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-PA-62-lower-ocd-person-4a24dcc2-5619-4bb1-acb3-bf7a7d08e6b1",
              "builtID": "pa-lower-62",
              "externalID": "ocd-person/4a24dcc2-5619-4bb1-acb3-bf7a7d08e6b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "jstruzzi@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "550 Philadelphia St., Indiana, PA 15701",
              "phone1": null,
              "phone2": "724-465-0220",
              "fax1": null,
              "fax2": "724-465-0221",
              "twitter": null,
              "youtube": "UCakfxrI6Njw5DpCRHqxuXXQ",
              "instagram": null,
              "facebook": "RepStruzzi",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1835",
                "https://www.repstruzzi.com/",
                "https://www.palegis.us/house/members/bio/1835/rep-struzzi"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-62-lower-ocd-person-4a24dcc2-5619-4bb1-acb3-bf7a7d08e6b1": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/2c39f854-9f84-4115-81a3-10b070d73794",
            "firstName": "Donna R.",
            "lastName": "Oberlander",
            "fullName": "Donna Oberlander",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1177.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-PA-63-lower-ocd-person-2c39f854-9f84-4115-81a3-10b070d73794",
              "builtID": "pa-lower-63",
              "externalID": "ocd-person/2c39f854-9f84-4115-81a3-10b070d73794",
              "geometry": null
            },
            "contactInfo": {
              "email": "doberlan@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "200 N. Jefferson St., Kittanning, PA 16201",
              "phone1": null,
              "phone2": "724-954-3613",
              "fax1": null,
              "fax2": "724-954-3614",
              "twitter": null,
              "youtube": "RepOberlander",
              "instagram": null,
              "facebook": "RepOberlander",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1177",
                "https://www.repoberlander.com/",
                "https://www.palegis.us/house/members/bio/1177/rep-oberlander"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-63-lower-ocd-person-2c39f854-9f84-4115-81a3-10b070d73794": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/b29f8685-d054-4c6c-a8ad-d64f80bd840f",
            "firstName": "Robert Lee",
            "lastName": "James",
            "fullName": "Lee James",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1634.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-PA-64-lower-ocd-person-b29f8685-d054-4c6c-a8ad-d64f80bd840f",
              "builtID": "pa-lower-64",
              "externalID": "ocd-person/b29f8685-d054-4c6c-a8ad-d64f80bd840f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rljames@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "3220 State Route 257, Suite 8 P.O. Box 547, Seneca, PA 16346",
              "phone1": null,
              "phone2": "814-677-6413",
              "fax1": null,
              "fax2": "814-676-1653",
              "twitter": null,
              "youtube": "RepLeeJames",
              "instagram": null,
              "facebook": "RepLeeJames",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1634",
                "https://www.repjames.com/",
                "https://www.palegis.us/house/members/bio/1634/rep-james"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-64-lower-ocd-person-b29f8685-d054-4c6c-a8ad-d64f80bd840f": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/36348b5e-47fe-404a-b6c3-99359115846c",
            "firstName": "Kathy L.",
            "lastName": "Rapp",
            "fullName": "Kathy Rapp",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1028.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-PA-65-lower-ocd-person-36348b5e-47fe-404a-b6c3-99359115846c",
              "builtID": "pa-lower-65",
              "externalID": "ocd-person/36348b5e-47fe-404a-b6c3-99359115846c",
              "geometry": null
            },
            "contactInfo": {
              "email": "klrapp23@verizon.net",
              "contactForm": null,
              "address1": null,
              "address2": "404 Market St., Warren, PA 16365",
              "phone1": null,
              "phone2": "814-723-5203",
              "fax1": null,
              "fax2": "814-728-3564",
              "twitter": null,
              "youtube": "RepKathyRapp",
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1028",
                "https://www.reprapp.com/",
                "https://www.palegis.us/house/members/bio/1028/rep-rapp"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-65-lower-ocd-person-36348b5e-47fe-404a-b6c3-99359115846c": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/ac7d02d3-9bb7-4cc1-8cee-4283094fe604",
            "firstName": "Brian",
            "lastName": "Smith",
            "fullName": "Brian Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1902.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-PA-66-lower-ocd-person-ac7d02d3-9bb7-4cc1-8cee-4283094fe604",
              "builtID": "pa-lower-66",
              "externalID": "ocd-person/ac7d02d3-9bb7-4cc1-8cee-4283094fe604",
              "geometry": null
            },
            "contactInfo": {
              "email": "bsmith@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "82 Barnett St., Brookville, PA 15825",
              "phone1": null,
              "phone2": "814-849-8008",
              "fax1": null,
              "fax2": "814-849-6710",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "PA-State-Rep-Brian-Smith-104029998216098",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1902",
                "https://www.repsmith.com/",
                "https://www.palegis.us/house/members/bio/1902/rep-smith"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-66-lower-ocd-person-ac7d02d3-9bb7-4cc1-8cee-4283094fe604": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/171af8ac-41e0-4047-be38-654cc912053b",
            "firstName": "Martin T.",
            "lastName": "Causer",
            "fullName": "Marty Causer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/982.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-PA-67-lower-ocd-person-171af8ac-41e0-4047-be38-654cc912053b",
              "builtID": "pa-lower-67",
              "externalID": "ocd-person/171af8ac-41e0-4047-be38-654cc912053b",
              "geometry": null
            },
            "contactInfo": {
              "email": "mcauser@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1, 107 S. Main St., Coudersport, PA 16915",
              "phone1": null,
              "phone2": "814-274-9769",
              "fax1": null,
              "fax2": "814-274-8159",
              "twitter": null,
              "youtube": "RepCauser",
              "instagram": null,
              "facebook": "RepCauser",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=982",
                "https://www.repcauser.com/",
                "https://www.palegis.us/house/members/bio/982/rep-causer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-67-lower-ocd-person-171af8ac-41e0-4047-be38-654cc912053b": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/626de4be-b2f3-4a37-bbbc-ede598381d6b",
            "firstName": "Clinton D.",
            "lastName": "Owlett",
            "fullName": "Clint Owlett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1796.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-PA-68-lower-ocd-person-626de4be-b2f3-4a37-bbbc-ede598381d6b",
              "builtID": "pa-lower-68",
              "externalID": "ocd-person/626de4be-b2f3-4a37-bbbc-ede598381d6b",
              "geometry": null
            },
            "contactInfo": {
              "email": "cowlett@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "74 Main St., Wellsboro, PA 16901",
              "phone1": null,
              "phone2": "570-724-1390",
              "fax1": null,
              "fax2": "570-724-2168",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1796",
                "https://www.repowlett.com/",
                "https://www.palegis.us/house/members/bio/1796/rep-owlett"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-68-lower-ocd-person-626de4be-b2f3-4a37-bbbc-ede598381d6b": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/462fc474-e481-436e-b47a-7bbde2de3e3a",
            "firstName": "Carl Walker",
            "lastName": "Metzgar",
            "fullName": "Carl Metzgar",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1174.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-PA-69-lower-ocd-person-462fc474-e481-436e-b47a-7bbde2de3e3a",
              "builtID": "pa-lower-69",
              "externalID": "ocd-person/462fc474-e481-436e-b47a-7bbde2de3e3a",
              "geometry": null
            },
            "contactInfo": {
              "email": "cmetzgar@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "1808 N. Center Ave. Suite 200, Somerset, PA 15501",
              "phone1": null,
              "phone2": "814-443-4230",
              "fax1": null,
              "fax2": "814-443-3855",
              "twitter": null,
              "youtube": "RepMetzgar",
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1174",
                "https://www.repmetzgar.com/",
                "https://www.palegis.us/house/members/bio/1174/rep-metzgar"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-69-lower-ocd-person-462fc474-e481-436e-b47a-7bbde2de3e3a": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/a15da78b-2475-4606-854f-7477dfc4233d",
            "firstName": "Matthew Douglas",
            "lastName": "Bradford",
            "fullName": "Matt Bradford",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1161.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-PA-70-lower-ocd-person-a15da78b-2475-4606-854f-7477dfc4233d",
              "builtID": "pa-lower-70",
              "externalID": "ocd-person/a15da78b-2475-4606-854f-7477dfc4233d",
              "geometry": null
            },
            "contactInfo": {
              "email": "repbradford@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "Intersection of Bustard Road and Skippack Pike 2000 Bustard Road, Suite 6, P.O. Box 118, Cedars, PA 19423",
              "phone1": null,
              "phone2": "610-222-3490",
              "fax1": null,
              "fax2": "610-222-3494",
              "twitter": "RepBradford",
              "youtube": "playlist?list=PL97682F1C72BC1829",
              "instagram": null,
              "facebook": "RepBradford",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1161",
                "https://www.pahouse.com/Bradford",
                "https://www.palegis.us/house/members/bio/1161/rep-bradford"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-70-lower-ocd-person-a15da78b-2475-4606-854f-7477dfc4233d": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/343e541b-fbd3-4622-bf42-7843494b1714",
            "firstName": "James Patrick",
            "lastName": "Rigby",
            "fullName": "Jim Rigby",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1836.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-PA-71-lower-ocd-person-343e541b-fbd3-4622-bf42-7843494b1714",
              "builtID": "pa-lower-71",
              "externalID": "ocd-person/343e541b-fbd3-4622-bf42-7843494b1714",
              "geometry": null
            },
            "contactInfo": {
              "email": "jrigby@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "7447 Admiral Peary Highway Suite 4, Cresson, PA 16630",
              "phone1": null,
              "phone2": "814-886-1055",
              "fax1": null,
              "fax2": "814-886-1056",
              "twitter": null,
              "youtube": "UCrrnV9bz8JpObHlPccnjmMQ",
              "instagram": null,
              "facebook": "RepJimRigby",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1836",
                "https://www.repjimrigby.com/",
                "https://www.palegis.us/house/members/bio/1836/rep-rigby"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-71-lower-ocd-person-343e541b-fbd3-4622-bf42-7843494b1714": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/a60da669-bae3-4e1d-a241-370a7ad2c769",
            "firstName": "Frank",
            "lastName": "Burns",
            "fullName": "Frank Burns",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1163.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-PA-72-lower-ocd-person-a60da669-bae3-4e1d-a241-370a7ad2c769",
              "builtID": "pa-lower-72",
              "externalID": "ocd-person/a60da669-bae3-4e1d-a241-370a7ad2c769",
              "geometry": null
            },
            "contactInfo": {
              "email": "repburns@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "535 Fairfield Ave., Johnstown, PA 15906",
              "phone1": null,
              "phone2": "814-536-8400",
              "fax1": null,
              "fax2": "814-533-2368",
              "twitter": "RepFrankBurns",
              "youtube": "playlist?list=PL0B6700769D99F473",
              "instagram": null,
              "facebook": "RepFrankBurns",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1163",
                "https://www.pahouse.com/Burns",
                "https://www.palegis.us/house/members/bio/1163/rep-burns"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-72-lower-ocd-person-a60da669-bae3-4e1d-a241-370a7ad2c769": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/70d0ff46-746e-47a0-8c5f-cbe73dbf72ce",
            "firstName": "Dallas Paul",
            "lastName": "Kephart",
            "fullName": "Dallas Kephart",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1952.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-PA-73-lower-ocd-person-70d0ff46-746e-47a0-8c5f-cbe73dbf72ce",
              "builtID": "pa-lower-73",
              "externalID": "ocd-person/70d0ff46-746e-47a0-8c5f-cbe73dbf72ce",
              "geometry": null
            },
            "contactInfo": {
              "email": "dkephart@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "600 Lingle St., Osceola Mills, PA 16666",
              "phone1": null,
              "phone2": "814-339-6544",
              "fax1": null,
              "fax2": "814-339-6546",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepKephart",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1952",
                "https://www.repkephart.com/",
                "https://www.palegis.us/house/members/bio/1952/rep-kephart"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-73-lower-ocd-person-70d0ff46-746e-47a0-8c5f-cbe73dbf72ce": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/8cb5e285-fdd9-4c63-b7c7-480bf9e477e1",
            "firstName": "Dan K.",
            "lastName": "Williams",
            "fullName": "Dan Williams",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1837.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-PA-74-lower-ocd-person-8cb5e285-fdd9-4c63-b7c7-480bf9e477e1",
              "builtID": "pa-lower-74",
              "externalID": "ocd-person/8cb5e285-fdd9-4c63-b7c7-480bf9e477e1",
              "geometry": null
            },
            "contactInfo": {
              "email": "dwilliams@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "3496 E. Lincoln Highway, Thorndale, PA 19372",
              "phone1": null,
              "phone2": "484-200-8256",
              "fax1": null,
              "fax2": "484-200-8257",
              "twitter": "RepDanWilliams",
              "youtube": "playlist?list=PLjtqNtfs0bgV65YUs6rjR2agLV_mJvFbI",
              "instagram": null,
              "facebook": "RepDanWilliams",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1837",
                "https://www.pahouse.com/williams",
                "https://www.palegis.us/house/members/bio/1837/rep-d-williams"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-74-lower-ocd-person-8cb5e285-fdd9-4c63-b7c7-480bf9e477e1": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/32c83f92-2bcc-4c2f-bcd0-8b367af29e28",
            "firstName": "Michael",
            "lastName": "Armanini",
            "fullName": "Mike Armanini",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1903.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-PA-75-lower-ocd-person-32c83f92-2bcc-4c2f-bcd0-8b367af29e28",
              "builtID": "pa-lower-75",
              "externalID": "ocd-person/32c83f92-2bcc-4c2f-bcd0-8b367af29e28",
              "geometry": null
            },
            "contactInfo": {
              "email": "marmanini@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "DuBois Area Plaza 1221 E. DuBois Ave., Suite 10, DuBois, PA 15801",
              "phone1": null,
              "phone2": "814-375-4688",
              "fax1": null,
              "fax2": "814-375-5955",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "PA-State-Rep-Mike-Armanini-101954888430243",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.repmikearmanini.com/",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1903",
                "https://www.palegis.us/house/members/bio/1903/rep-armanini"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-75-lower-ocd-person-32c83f92-2bcc-4c2f-bcd0-8b367af29e28": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/bccfe530-42ce-47b0-aadf-ae863b37cf9a",
            "firstName": "Stephanie Paige",
            "lastName": "Borowicz",
            "fullName": "Stephanie Borowicz",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1838.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-PA-76-lower-ocd-person-bccfe530-42ce-47b0-aadf-ae863b37cf9a",
              "builtID": "pa-lower-76",
              "externalID": "ocd-person/bccfe530-42ce-47b0-aadf-ae863b37cf9a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sborowicz@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "343 Chestnut St., Mifflinburg, PA 17844",
              "phone1": null,
              "phone2": "570-966-0052",
              "fax1": null,
              "fax2": "570-966-0053",
              "twitter": null,
              "youtube": "UCKuzlJcguG6meP1XsYWd8qg",
              "instagram": null,
              "facebook": "RepBorowicz",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1838",
                "https://www.repstephanie.com/",
                "https://www.palegis.us/house/members/bio/1838/rep-borowicz"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-76-lower-ocd-person-bccfe530-42ce-47b0-aadf-ae863b37cf9a": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/97b012cd-5cd7-4039-a9ae-fed936aabe37",
            "firstName": "Harry Scott",
            "lastName": "Conklin",
            "fullName": "Scott Conklin",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1096.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-PA-77-lower-ocd-person-97b012cd-5cd7-4039-a9ae-fed936aabe37",
              "builtID": "pa-lower-77",
              "externalID": "ocd-person/97b012cd-5cd7-4039-a9ae-fed936aabe37",
              "geometry": null
            },
            "contactInfo": {
              "email": "sconklin@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "301 S. Allen St., State College, PA 16801",
              "phone1": null,
              "phone2": "814-238-5477",
              "fax1": null,
              "fax2": "814-863-3898",
              "twitter": "scottconklin",
              "youtube": "playlist?list=PL1B2B3247C8F6FE1E",
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1096",
                "https://www.pahouse.com/Conklin",
                "https://www.palegis.us/house/members/bio/1096/rep-conklin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-77-lower-ocd-person-97b012cd-5cd7-4039-a9ae-fed936aabe37": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/865dbfc4-e6ec-400e-bd5c-257f9a947519",
            "firstName": "Jesse Willis",
            "lastName": "Topper",
            "fullName": "Jesse Topper",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1681.jpg?1703415645672g",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-PA-78-lower-ocd-person-865dbfc4-e6ec-400e-bd5c-257f9a947519",
              "builtID": "pa-lower-78",
              "externalID": "ocd-person/865dbfc4-e6ec-400e-bd5c-257f9a947519",
              "geometry": null
            },
            "contactInfo": {
              "email": "jtopper@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "421 Lincoln Way East Suite A, McConnellsburg, PA 17233",
              "phone1": null,
              "phone2": "717-485-4430",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1681",
                "https://www.reptopper.com/",
                "https://www.palegis.us/house/members/bio/1681/rep-topper"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-78-lower-ocd-person-865dbfc4-e6ec-400e-bd5c-257f9a947519": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/e19d3bae-7d48-4e27-af66-e9453633edca",
            "firstName": "Louis C.",
            "lastName": "Schmitt",
            "fullName": "Lou Schmitt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1839.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-PA-79-lower-ocd-person-e19d3bae-7d48-4e27-af66-e9453633edca",
              "builtID": "pa-lower-79",
              "externalID": "ocd-person/e19d3bae-7d48-4e27-af66-e9453633edca",
              "geometry": null
            },
            "contactInfo": {
              "email": "lschmitt@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "2301 Beale Ave. Suite A, Altoona, PA 16601",
              "phone1": null,
              "phone2": "814-946-7218",
              "fax1": null,
              "fax2": "814-940-5111",
              "twitter": "repschmitt",
              "youtube": "UCm6Ah9jIZvDQ8QcYvX3OcsA",
              "instagram": null,
              "facebook": "RepSchmitt",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1839",
                "https://www.repschmitt.com/",
                "https://www.palegis.us/house/members/bio/1839/rep-schmitt"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-79-lower-ocd-person-e19d3bae-7d48-4e27-af66-e9453633edca": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/dd479171-721b-4f39-9b67-56061f9f98a6",
            "firstName": "James",
            "lastName": "Gregory",
            "fullName": "Jim Gregory",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1840.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-PA-80-lower-ocd-person-dd479171-721b-4f39-9b67-56061f9f98a6",
              "builtID": "pa-lower-80",
              "externalID": "ocd-person/dd479171-721b-4f39-9b67-56061f9f98a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "jgregory@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "324 Allegheny St., Hollidaysburg, PA 16648",
              "phone1": null,
              "phone2": "814-695-2398",
              "fax1": null,
              "fax2": "814-946-7239",
              "twitter": null,
              "youtube": "UCQs49IhGjt72ANB33dQVdzA",
              "instagram": null,
              "facebook": "RepJimGregory",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1840",
                "https://www.repgregory.com/",
                "https://www.palegis.us/house/members/bio/1840/rep-gregory"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-80-lower-ocd-person-dd479171-721b-4f39-9b67-56061f9f98a6": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/ff2471f3-f3ff-4a46-8ca7-be5094f0dfdc",
            "firstName": "Richard S.",
            "lastName": "Irvin",
            "fullName": "Rich Irvin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1691.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-PA-81-lower-ocd-person-ff2471f3-f3ff-4a46-8ca7-be5094f0dfdc",
              "builtID": "pa-lower-81",
              "externalID": "ocd-person/ff2471f3-f3ff-4a46-8ca7-be5094f0dfdc",
              "geometry": null
            },
            "contactInfo": {
              "email": "rirvin@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "703 Municipal Drive, Shippensburg, PA 17257",
              "phone1": null,
              "phone2": "717-532-1707",
              "fax1": null,
              "fax2": "717-532-5043",
              "twitter": null,
              "youtube": "RepIrvin",
              "instagram": null,
              "facebook": "PA-State-Rep-Rich-Irvin",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1691",
                "https://www.repirvin.com/",
                "https://www.palegis.us/house/members/bio/1691/rep-irvin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-81-lower-ocd-person-ff2471f3-f3ff-4a46-8ca7-be5094f0dfdc": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/dd35ae63-5dee-4f87-b64e-65907b8adaba",
            "firstName": "Paul R.",
            "lastName": "Takac",
            "fullName": "Paul Takac",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1953.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-PA-82-lower-ocd-person-dd35ae63-5dee-4f87-b64e-65907b8adaba",
              "builtID": "pa-lower-82",
              "externalID": "ocd-person/dd35ae63-5dee-4f87-b64e-65907b8adaba",
              "geometry": null
            },
            "contactInfo": {
              "email": "reptakac@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "216 W. Hamilton Ave., State College, PA 16801",
              "phone1": null,
              "phone2": "814-308-0569",
              "fax1": null,
              "fax2": null,
              "twitter": "RepTakac",
              "youtube": null,
              "instagram": null,
              "facebook": "RepTakac",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1953",
                "https://www.pahouse.com/takac/",
                "https://www.palegis.us/house/members/bio/1953/rep-takac"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-82-lower-ocd-person-dd35ae63-5dee-4f87-b64e-65907b8adaba": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/e396f514-a357-404f-bdb9-1ba33d97d0a7",
            "firstName": "Jamie L.",
            "lastName": "Flick",
            "fullName": "Jamie Flick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1954.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-PA-83-lower-ocd-person-e396f514-a357-404f-bdb9-1ba33d97d0a7",
              "builtID": "pa-lower-83",
              "externalID": "ocd-person/e396f514-a357-404f-bdb9-1ba33d97d0a7",
              "geometry": null
            },
            "contactInfo": {
              "email": "jflick@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "1000 Commerce Park Drive Suite 103, Williamsport, PA 17701",
              "phone1": null,
              "phone2": "570-321-1270",
              "fax1": null,
              "fax2": "570-321-1271",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepFlick",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1954",
                "https://www.repflick.com/",
                "https://www.palegis.us/house/members/bio/1954/rep-flick"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-83-lower-ocd-person-e396f514-a357-404f-bdb9-1ba33d97d0a7": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/acc00fca-50e5-46f7-8676-d502ecfc94ac",
            "firstName": "Joseph D.",
            "lastName": "Hamm",
            "fullName": "Joe Hamm",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1904.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-PA-84-lower-ocd-person-acc00fca-50e5-46f7-8676-d502ecfc94ac",
              "builtID": "pa-lower-84",
              "externalID": "ocd-person/acc00fca-50e5-46f7-8676-d502ecfc94ac",
              "geometry": null
            },
            "contactInfo": {
              "email": "jhamm@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "7406 State Route 487 Suite 7, Mildred, PA 18632",
              "phone1": null,
              "phone2": "570-314-2036",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "PA-State-Rep-Joe-Hamm-106088111338800",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.repjoehamm.com/",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1904",
                "https://www.palegis.us/house/members/bio/1904/rep-hamm"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-84-lower-ocd-person-acc00fca-50e5-46f7-8676-d502ecfc94ac": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/c88ccd9a-3378-4284-9dc6-767a4826526e",
            "firstName": "David Hummer",
            "lastName": "Rowe",
            "fullName": "David Rowe",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1871.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-PA-85-lower-ocd-person-c88ccd9a-3378-4284-9dc6-767a4826526e",
              "builtID": "pa-lower-85",
              "externalID": "ocd-person/c88ccd9a-3378-4284-9dc6-767a4826526e",
              "geometry": null
            },
            "contactInfo": {
              "email": "drowe@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "74 Paxtonville Road, Middleburg, PA 17842",
              "phone1": null,
              "phone2": "570-966-0052",
              "fax1": null,
              "fax2": "570-966-0520",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1871",
                "https://www.repdavidrowe.com/",
                "https://www.palegis.us/house/members/bio/1871/rep-rowe"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-85-lower-ocd-person-c88ccd9a-3378-4284-9dc6-767a4826526e": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/eb33e206-fb08-40bc-8b9b-1d9a72fcb8fc",
            "firstName": "Perry A.",
            "lastName": "Stambaugh",
            "fullName": "Perry Stambaugh",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1905.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-PA-86-lower-ocd-person-eb33e206-fb08-40bc-8b9b-1d9a72fcb8fc",
              "builtID": "pa-lower-86",
              "externalID": "ocd-person/eb33e206-fb08-40bc-8b9b-1d9a72fcb8fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "pstambaugh@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 9 18 W. Main St., New Bloomfield, PA 17068",
              "phone1": null,
              "phone2": "717-582-8119",
              "fax1": null,
              "fax2": "717-582-8979",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "PA-State-Rep-Perry-Stambaugh-102591118365034",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1905",
                "https://www.repperrystambaugh.com/",
                "https://www.palegis.us/house/members/bio/1905/rep-stambaugh"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-86-lower-ocd-person-eb33e206-fb08-40bc-8b9b-1d9a72fcb8fc": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/932682ef-c7da-4383-8c50-58879fba0ca6",
            "firstName": "Thomas Holden",
            "lastName": "Kutz",
            "fullName": "Thomas Kutz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1955.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-PA-87-lower-ocd-person-932682ef-c7da-4383-8c50-58879fba0ca6",
              "builtID": "pa-lower-87",
              "externalID": "ocd-person/932682ef-c7da-4383-8c50-58879fba0ca6",
              "geometry": null
            },
            "contactInfo": {
              "email": "tkutz@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "South Middleton Township Building 520 Park Drive, Boiling Springs, PA 17007",
              "phone1": null,
              "phone2": "717-258-0247",
              "fax1": null,
              "fax2": "717-258-0283",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepKutz",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1955",
                "https://www.repkutz.com/",
                "https://www.palegis.us/house/members/bio/1955/rep-kutz"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-87-lower-ocd-person-932682ef-c7da-4383-8c50-58879fba0ca6": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/9e4954ab-971c-4620-9f9d-ebb68311e3f1",
            "firstName": "Sheryl M.",
            "lastName": "Delozier",
            "fullName": "Sheryl Delozier",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1167.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-PA-88-lower-ocd-person-9e4954ab-971c-4620-9f9d-ebb68311e3f1",
              "builtID": "pa-lower-88",
              "externalID": "ocd-person/9e4954ab-971c-4620-9f9d-ebb68311e3f1",
              "geometry": null
            },
            "contactInfo": {
              "email": "sdelozie@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "2929 Gettysburg Road Suite 6, Camp Hill, PA 17011",
              "phone1": null,
              "phone2": "717-761-4665",
              "fax1": null,
              "fax2": "717-731-7091",
              "twitter": null,
              "youtube": "RepDelozier",
              "instagram": null,
              "facebook": "RepDelozier",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1167",
                "https://www.repdelozier.com/",
                "https://www.palegis.us/house/members/bio/1167/rep-delozier"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-88-lower-ocd-person-9e4954ab-971c-4620-9f9d-ebb68311e3f1": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/17e1e676-8e83-4883-8cf5-4c8ddcce076e",
            "firstName": "Robert W.",
            "lastName": "Kauffman",
            "fullName": "Rob Kauffman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1022.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-PA-89-lower-ocd-person-17e1e676-8e83-4883-8cf5-4c8ddcce076e",
              "builtID": "pa-lower-89",
              "externalID": "ocd-person/17e1e676-8e83-4883-8cf5-4c8ddcce076e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rkauffma@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "Southampton Township Supervisor's Building 705 Municipal Drive, Shippensburg, PA 17257",
              "phone1": null,
              "phone2": "717-532-1707",
              "fax1": null,
              "fax2": "717-532-5043",
              "twitter": null,
              "youtube": "RepKauffman",
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1022",
                "https://www.repkauffman.com/",
                "https://www.palegis.us/house/members/bio/1022/rep-kauffman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-89-lower-ocd-person-17e1e676-8e83-4883-8cf5-4c8ddcce076e": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/37a38b6a-84c8-427b-bc6f-2256fffe9062",
            "firstName": "Paul Thomas",
            "lastName": "Schemel",
            "fullName": "Paul Schemel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1705.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-PA-90-lower-ocd-person-37a38b6a-84c8-427b-bc6f-2256fffe9062",
              "builtID": "pa-lower-90",
              "externalID": "ocd-person/37a38b6a-84c8-427b-bc6f-2256fffe9062",
              "geometry": null
            },
            "contactInfo": {
              "email": "pschemel@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "1402 E. Main St., Waynesboro, PA 17268",
              "phone1": null,
              "phone2": "717-749-7384",
              "fax1": null,
              "fax2": "717-762-4380",
              "twitter": null,
              "youtube": "RepSchemel",
              "instagram": null,
              "facebook": "RepSchemel",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1705",
                "https://www.repschemel.com/",
                "https://www.palegis.us/house/members/bio/1705/rep-schemel"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-90-lower-ocd-person-37a38b6a-84c8-427b-bc6f-2256fffe9062": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/f4071288-fa18-48bb-99f1-41abd60d215e",
            "firstName": "Daniel P.",
            "lastName": "Moul",
            "fullName": "Dan Moul",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1101.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-PA-91-lower-ocd-person-f4071288-fa18-48bb-99f1-41abd60d215e",
              "builtID": "pa-lower-91",
              "externalID": "ocd-person/f4071288-fa18-48bb-99f1-41abd60d215e",
              "geometry": null
            },
            "contactInfo": {
              "email": "dmoul@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "30 W. Middle St., Gettysburg, PA 17325",
              "phone1": null,
              "phone2": "866-646-4915",
              "fax1": null,
              "fax2": "717-334-8426",
              "twitter": null,
              "youtube": "RepMoul",
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1101",
                "https://www.repmoul.com/",
                "https://www.palegis.us/house/members/bio/1101/rep-moul"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-91-lower-ocd-person-f4071288-fa18-48bb-99f1-41abd60d215e": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/2a13f9df-b7e6-4281-82e4-f7263b6fa12e",
            "firstName": "Dawn Wetzel",
            "lastName": "Keefer",
            "fullName": "Dawn Keefer",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1748.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-PA-92-lower-ocd-person-2a13f9df-b7e6-4281-82e4-f7263b6fa12e",
              "builtID": "pa-lower-92",
              "externalID": "ocd-person/2a13f9df-b7e6-4281-82e4-f7263b6fa12e",
              "geometry": null
            },
            "contactInfo": {
              "email": "dkeefer@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "12 N. Baltimore St., Dillsburg, PA 17019",
              "phone1": null,
              "phone2": "717-432-0792",
              "fax1": null,
              "fax2": "717-432-0795",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "repkeefer",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1748",
                "https://www.repkeefer.com/",
                "https://www.palegis.us/house/members/bio/1748/rep-keefer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-92-lower-ocd-person-2a13f9df-b7e6-4281-82e4-f7263b6fa12e": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/ce5d2c01-e29c-4eac-b06b-208d8c302dff",
            "firstName": "Paul Michael",
            "lastName": "Jones",
            "fullName": "Mike Jones",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1842.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-PA-93-lower-ocd-person-ce5d2c01-e29c-4eac-b06b-208d8c302dff",
              "builtID": "pa-lower-93",
              "externalID": "ocd-person/ce5d2c01-e29c-4eac-b06b-208d8c302dff",
              "geometry": null
            },
            "contactInfo": {
              "email": "mjones@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "1949 Security Drive, York, PA 17402",
              "phone1": null,
              "phone2": "717-428-9889",
              "fax1": null,
              "fax2": "717-428-2003",
              "twitter": null,
              "youtube": "UCaC4s-tQTnXEtyonJZ2G7Ig",
              "instagram": null,
              "facebook": "RepMikeJonesPA",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1842",
                "https://www.repmikejones.com/",
                "https://www.palegis.us/house/members/bio/1842/rep-m-jones"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-93-lower-ocd-person-ce5d2c01-e29c-4eac-b06b-208d8c302dff": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/1300735f-1441-4861-b20f-c1fa3e222c08",
            "firstName": "Wendy",
            "lastName": "Fink",
            "fullName": "Wendy Fink",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1956.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-PA-94-lower-ocd-person-1300735f-1441-4861-b20f-c1fa3e222c08",
              "builtID": "pa-lower-94",
              "externalID": "ocd-person/1300735f-1441-4861-b20f-c1fa3e222c08",
              "geometry": null
            },
            "contactInfo": {
              "email": "wfink@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "100 Redco Ave., Red Lion, PA 17356",
              "phone1": null,
              "phone2": "717-244-9232",
              "fax1": null,
              "fax2": "717-244-1714",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepWendyFink",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1956",
                "https://www.repwendyfink.com/",
                "https://www.palegis.us/house/members/bio/1956/rep-fink"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-94-lower-ocd-person-1300735f-1441-4861-b20f-c1fa3e222c08": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/cafbffed-be4d-4e5b-9d99-9aec024675e2",
            "firstName": "Carol",
            "lastName": "Hill-Evans",
            "fullName": "Carol Hill-Evans",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1749.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-PA-95-lower-ocd-person-cafbffed-be4d-4e5b-9d99-9aec024675e2",
              "builtID": "pa-lower-95",
              "externalID": "ocd-person/cafbffed-be4d-4e5b-9d99-9aec024675e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "chill-evans@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "53 E. North St. Suite 3, York, PA 17401",
              "phone1": null,
              "phone2": "717-848-9595",
              "fax1": null,
              "fax2": "717-848-1871",
              "twitter": "RepHillEvans",
              "youtube": null,
              "instagram": null,
              "facebook": "RepHillEvans",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1749",
                "https://www.pahouse.com/Hill-Evans/",
                "https://www.palegis.us/house/members/bio/1749/rep-hill-evans"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-95-lower-ocd-person-cafbffed-be4d-4e5b-9d99-9aec024675e2": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/e214c588-7732-4df5-a44b-d1e71fce84a2",
            "firstName": "P. Michael",
            "lastName": "Sturla",
            "fullName": "Mike Sturla",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/274.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-PA-96-lower-ocd-person-e214c588-7732-4df5-a44b-d1e71fce84a2",
              "builtID": "pa-lower-96",
              "externalID": "ocd-person/e214c588-7732-4df5-a44b-d1e71fce84a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "repsturla@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "1560 Lititz Pike Suite 2, Lancaster, PA 17601",
              "phone1": null,
              "phone2": "717-295-3157",
              "fax1": null,
              "fax2": "717-295-7816",
              "twitter": "RepMikeSturla",
              "youtube": "playlist?list=PL9C9BA287CFBF3556",
              "instagram": "repmikesturla",
              "facebook": "RepMikeSturla",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=274",
                "https://www.pahouse.com/sturla",
                "https://www.palegis.us/house/members/bio/274/rep-sturla"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-96-lower-ocd-person-e214c588-7732-4df5-a44b-d1e71fce84a2": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/4463dd94-6a9d-41bd-830f-0d03e192f896",
            "firstName": "Steven Curtis",
            "lastName": "Mentzer",
            "fullName": "Steve Mentzer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1642.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-PA-97-lower-ocd-person-4463dd94-6a9d-41bd-830f-0d03e192f896",
              "builtID": "pa-lower-97",
              "externalID": "ocd-person/4463dd94-6a9d-41bd-830f-0d03e192f896",
              "geometry": null
            },
            "contactInfo": {
              "email": "smentzer@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "West Lampeter Township Building 852 Village Road, Lancaster, PA 17602",
              "phone1": null,
              "phone2": "717-464-5285",
              "fax1": null,
              "fax2": "717-295-7817",
              "twitter": null,
              "youtube": "RepMentzer",
              "instagram": null,
              "facebook": "RepMentzer",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1642",
                "https://www.repmentzer.com/",
                "https://www.palegis.us/house/members/bio/1642/rep-mentzer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-97-lower-ocd-person-4463dd94-6a9d-41bd-830f-0d03e192f896": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/7f6b3c0e-4446-4243-ae49-d2d33ab30273",
            "firstName": "Thomas M.",
            "lastName": "Jones",
            "fullName": "Tom Jones",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1957.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-PA-98-lower-ocd-person-7f6b3c0e-4446-4243-ae49-d2d33ab30273",
              "builtID": "pa-lower-98",
              "externalID": "ocd-person/7f6b3c0e-4446-4243-ae49-d2d33ab30273",
              "geometry": null
            },
            "contactInfo": {
              "email": "tjones@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "222 S. Market St., Suite 103, Elizabethtown, PA 17022",
              "phone1": null,
              "phone2": "717-367-5525",
              "fax1": null,
              "fax2": "717-367-6425",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepTomJones",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1957",
                "https://www.reptomjones.com/",
                "https://www.palegis.us/house/members/bio/1957/rep-t-jones"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-98-lower-ocd-person-7f6b3c0e-4446-4243-ae49-d2d33ab30273": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/2fbada46-9c48-4be5-a49f-e1ca0af132ed",
            "firstName": "David H.",
            "lastName": "Zimmerman",
            "fullName": "Dave Zimmerman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1711.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-PA-99-lower-ocd-person-2fbada46-9c48-4be5-a49f-e1ca0af132ed",
              "builtID": "pa-lower-99",
              "externalID": "ocd-person/2fbada46-9c48-4be5-a49f-e1ca0af132ed",
              "geometry": null
            },
            "contactInfo": {
              "email": "dzimmerman@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 425 3000 N. Reading Road, Adamstown, PA 17501",
              "phone1": null,
              "phone2": "717-556-0031",
              "fax1": null,
              "fax2": "717-556-0032",
              "twitter": null,
              "youtube": "RepZimmerman",
              "instagram": null,
              "facebook": "PA-State-Rep-Dave-Zimmerman",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1711",
                "https://www.repzimmerman.com/",
                "https://www.palegis.us/house/members/bio/1711/rep-zimmerman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-99-lower-ocd-person-2fbada46-9c48-4be5-a49f-e1ca0af132ed": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/fd5d0580-766c-482c-a12f-e4689e0bcf30",
            "firstName": "Bryan Dean",
            "lastName": "Cutler",
            "fullName": "Bryan Cutler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1105.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-PA-100-lower-ocd-person-fd5d0580-766c-482c-a12f-e4689e0bcf30",
              "builtID": "pa-lower-100",
              "externalID": "ocd-person/fd5d0580-766c-482c-a12f-e4689e0bcf30",
              "geometry": null
            },
            "contactInfo": {
              "email": "bcutler@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "33E Friendly Drive, Quarryville, PA 17566",
              "phone1": null,
              "phone2": "717-284-1965",
              "fax1": null,
              "fax2": "717-284-1968",
              "twitter": "repbryancutler",
              "youtube": "RepCutler",
              "instagram": null,
              "facebook": "RepBryanCutler",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1105",
                "https://www.repcutler.com/",
                "https://www.palegis.us/house/members/bio/1105/rep-cutler"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-100-lower-ocd-person-fd5d0580-766c-482c-a12f-e4689e0bcf30": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/b6a10f6b-3f2e-42df-adf9-7184222af2c2",
            "firstName": "John A.",
            "lastName": "Schlegel",
            "fullName": "John Schlegel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1958.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-PA-101-lower-ocd-person-b6a10f6b-3f2e-42df-adf9-7184222af2c2",
              "builtID": "pa-lower-101",
              "externalID": "ocd-person/b6a10f6b-3f2e-42df-adf9-7184222af2c2",
              "geometry": null
            },
            "contactInfo": {
              "email": "jschlegel@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "2272 Lebanon Valley Mall, Unit E2, Lebanon, PA 17042",
              "phone1": null,
              "phone2": "717-838-3823",
              "fax1": null,
              "fax2": "717-832-8191",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepSchlegel",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1958",
                "https://www.palegis.us/house/members/bio/1958/rep-schlegel",
                "https://www.RepSchlegel.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-101-lower-ocd-person-b6a10f6b-3f2e-42df-adf9-7184222af2c2": 0
        }
      },
      "102": {
        "members": [
          {
            "API_ID": "ocd-person/5348e28d-bbbe-493f-8f77-a509cb315abe",
            "firstName": "Russell H.",
            "lastName": "Diamond",
            "fullName": "Russ Diamond",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1686.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "102",
              "chamber": "lower",
              "hashID": "SLDL-PA-102-lower-ocd-person-5348e28d-bbbe-493f-8f77-a509cb315abe",
              "builtID": "pa-lower-102",
              "externalID": "ocd-person/5348e28d-bbbe-493f-8f77-a509cb315abe",
              "geometry": null
            },
            "contactInfo": {
              "email": "rdiamond@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "175 S. Lancaster St., Jonestown, PA 17038",
              "phone1": null,
              "phone2": "717-277-2101",
              "fax1": null,
              "fax2": "717-277-2105",
              "twitter": null,
              "youtube": "RepRussDiamond",
              "instagram": null,
              "facebook": "repdiamond",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1686",
                "https://www.repdiamond.com/",
                "https://www.palegis.us/house/members/bio/1686/rep-diamond"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-102-lower-ocd-person-5348e28d-bbbe-493f-8f77-a509cb315abe": 0
        }
      },
      "103": {
        "members": [
          {
            "API_ID": "ocd-person/b5ff17d0-36e8-4f29-8c9b-e51bb9b3ea29",
            "firstName": "Patty H.",
            "lastName": "Kim",
            "fullName": "Patty Kim",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1636.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "103",
              "chamber": "lower",
              "hashID": "SLDL-PA-103-lower-ocd-person-b5ff17d0-36e8-4f29-8c9b-e51bb9b3ea29",
              "builtID": "pa-lower-103",
              "externalID": "ocd-person/b5ff17d0-36e8-4f29-8c9b-e51bb9b3ea29",
              "geometry": null
            },
            "contactInfo": {
              "email": "repkim@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "717 Market St. Suite 110, Lemoyne, PA 17043",
              "phone1": null,
              "phone2": "717-303-3715",
              "fax1": null,
              "fax2": "717-839-5035",
              "twitter": "RepPattyKim",
              "youtube": "playlist?list=PLjtqNtfs0bgUwNH5u2dn_SWYxVa43nxUq",
              "instagram": null,
              "facebook": "RepPattyKim",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1636",
                "https://www.pahouse.com/Kim/",
                "https://www.palegis.us/house/members/bio/1636/rep-kim"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-103-lower-ocd-person-b5ff17d0-36e8-4f29-8c9b-e51bb9b3ea29": 0
        }
      },
      "104": {
        "members": [
          {
            "API_ID": "ocd-person/4a0c9e5e-07cc-4b4b-a47a-22be60d1623e",
            "firstName": "David A.",
            "lastName": "Madsen",
            "fullName": "Dave Madsen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1959.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "104",
              "chamber": "lower",
              "hashID": "SLDL-PA-104-lower-ocd-person-4a0c9e5e-07cc-4b4b-a47a-22be60d1623e",
              "builtID": "pa-lower-104",
              "externalID": "ocd-person/4a0c9e5e-07cc-4b4b-a47a-22be60d1623e",
              "geometry": null
            },
            "contactInfo": {
              "email": "repmadsen@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "Room 102B, East Wing P.O. Box 202104, Harrisburg, PA 17120",
              "phone1": null,
              "phone2": "717-772-2362",
              "fax1": null,
              "fax2": "717-780-6028",
              "twitter": "RepMadsen",
              "youtube": null,
              "instagram": null,
              "facebook": "RepMadsen",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1959",
                "https://www.pahouse.com/madsen/",
                "https://www.palegis.us/house/members/bio/1959/rep-madsen"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-104-lower-ocd-person-4a0c9e5e-07cc-4b4b-a47a-22be60d1623e": 0
        }
      },
      "105": {
        "members": [
          {
            "API_ID": "ocd-person/98b96b05-81eb-4871-a81b-2e03c0b92267",
            "firstName": "Justin C.",
            "lastName": "Fleming",
            "fullName": "Justin Fleming",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1960.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "105",
              "chamber": "lower",
              "hashID": "SLDL-PA-105-lower-ocd-person-98b96b05-81eb-4871-a81b-2e03c0b92267",
              "builtID": "pa-lower-105",
              "externalID": "ocd-person/98b96b05-81eb-4871-a81b-2e03c0b92267",
              "geometry": null
            },
            "contactInfo": {
              "email": "repfleming@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "4807 Jonestown Road Suite 149, 1st Floor, Harrisburg, PA 17109",
              "phone1": null,
              "phone2": "717-230-1172",
              "fax1": null,
              "fax2": "717-839-2742",
              "twitter": "RepJFleming",
              "youtube": null,
              "instagram": null,
              "facebook": "RepFleming",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1960",
                "https://www.pahouse.com/fleming/",
                "https://www.palegis.us/house/members/bio/1960/rep-fleming"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-105-lower-ocd-person-98b96b05-81eb-4871-a81b-2e03c0b92267": 0
        }
      },
      "106": {
        "members": [
          {
            "API_ID": "ocd-person/bda0ab75-036f-49bd-9dd5-cfc9c55eec02",
            "firstName": "Thomas L.",
            "lastName": "Mehaffie",
            "fullName": "Tom Mehaffie",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1751.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "106",
              "chamber": "lower",
              "hashID": "SLDL-PA-106-lower-ocd-person-bda0ab75-036f-49bd-9dd5-cfc9c55eec02",
              "builtID": "pa-lower-106",
              "externalID": "ocd-person/bda0ab75-036f-49bd-9dd5-cfc9c55eec02",
              "geometry": null
            },
            "contactInfo": {
              "email": "tmehaffie@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "1 E. Chocolate Ave. Suite 700, Hershey, PA 17033",
              "phone1": null,
              "phone2": "717-534-1323",
              "fax1": null,
              "fax2": "717-534-1457",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "repmehaffie",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1751",
                "https://www.repmehaffie.com/",
                "https://www.palegis.us/house/members/bio/1751/rep-mehaffie"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-106-lower-ocd-person-bda0ab75-036f-49bd-9dd5-cfc9c55eec02": 0
        }
      },
      "107": {
        "members": [
          {
            "API_ID": "ocd-person/6e7b8baa-4fa3-404e-b8f8-bab3077221b7",
            "firstName": "Joanne C.",
            "lastName": "Stehr",
            "fullName": "Joanne Stehr",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1961.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "107",
              "chamber": "lower",
              "hashID": "SLDL-PA-107-lower-ocd-person-6e7b8baa-4fa3-404e-b8f8-bab3077221b7",
              "builtID": "pa-lower-107",
              "externalID": "ocd-person/6e7b8baa-4fa3-404e-b8f8-bab3077221b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "jstehr@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "57 Park Lane, Hegins, PA 17938",
              "phone1": null,
              "phone2": "570-653-2018",
              "fax1": null,
              "fax2": "570-653-2019",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepStehr",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1961",
                "https://www.repstehr.com",
                "https://www.palegis.us/house/members/bio/1961/rep-stehr"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-107-lower-ocd-person-6e7b8baa-4fa3-404e-b8f8-bab3077221b7": 0
        }
      },
      "108": {
        "members": [
          {
            "API_ID": "ocd-person/ef4ff98e-baba-4655-b24f-66ec5933fcb0",
            "firstName": "Michael A.K.",
            "lastName": "Stender",
            "fullName": "Michael Stender",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/2014.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "108",
              "chamber": "lower",
              "hashID": "SLDL-PA-108-lower-ocd-person-ef4ff98e-baba-4655-b24f-66ec5933fcb0",
              "builtID": "pa-lower-108",
              "externalID": "ocd-person/ef4ff98e-baba-4655-b24f-66ec5933fcb0",
              "geometry": null
            },
            "contactInfo": {
              "email": "mstender@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "Montour County Administration Building 435 E. Front St., Danville, PA 17821",
              "phone1": null,
              "phone2": "570-275-3700",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "repstender",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=2014",
                "https://www.repstender.com/",
                "https://www.palegis.us/house/members/bio/2014/rep-stender"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-108-lower-ocd-person-ef4ff98e-baba-4655-b24f-66ec5933fcb0": 0
        }
      },
      "109": {
        "members": [
          {
            "API_ID": "ocd-person/1d775faf-9231-420a-b62e-979bb6a1fc78",
            "firstName": "Robert",
            "lastName": "Leadbeter",
            "fullName": "Robert Leadbeter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1962.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "109",
              "chamber": "lower",
              "hashID": "SLDL-PA-109-lower-ocd-person-1d775faf-9231-420a-b62e-979bb6a1fc78",
              "builtID": "pa-lower-109",
              "externalID": "ocd-person/1d775faf-9231-420a-b62e-979bb6a1fc78",
              "geometry": null
            },
            "contactInfo": {
              "email": "rleadbeter@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "134 W. Front St. Suite 102, Berwick, PA 18603",
              "phone1": null,
              "phone2": "570-759-8734",
              "fax1": null,
              "fax2": "570-759-4505",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepLeadbeter",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1962",
                "https://www.repleadbeter.com/",
                "https://www.palegis.us/house/members/bio/1962/rep-leadbeter"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-109-lower-ocd-person-1d775faf-9231-420a-b62e-979bb6a1fc78": 0
        }
      },
      "110": {
        "members": [
          {
            "API_ID": "ocd-person/8e174923-d514-4240-b6cd-cbb7927410ba",
            "firstName": "Tina L.",
            "lastName": "Pickett",
            "fullName": "Tina Pickett",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/97.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "110",
              "chamber": "lower",
              "hashID": "SLDL-PA-110-lower-ocd-person-8e174923-d514-4240-b6cd-cbb7927410ba",
              "builtID": "pa-lower-110",
              "externalID": "ocd-person/8e174923-d514-4240-b6cd-cbb7927410ba",
              "geometry": null
            },
            "contactInfo": {
              "email": "tpickett@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "320 Main St., Towanda, PA 18848",
              "phone1": null,
              "phone2": "570-265-3124",
              "fax1": null,
              "fax2": "570-265-9453",
              "twitter": null,
              "youtube": "RepPickett",
              "instagram": null,
              "facebook": "RepPickett",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=97",
                "https://www.reppickett.com/",
                "https://www.palegis.us/house/members/bio/97/rep-pickett"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-110-lower-ocd-person-8e174923-d514-4240-b6cd-cbb7927410ba": 0
        }
      },
      "111": {
        "members": [
          {
            "API_ID": "ocd-person/23a2b079-dc91-49b0-b08e-f3ae80fba11f",
            "firstName": "Jonathan A.",
            "lastName": "Fritz",
            "fullName": "Jonathan Fritz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1752.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "111",
              "chamber": "lower",
              "hashID": "SLDL-PA-111-lower-ocd-person-23a2b079-dc91-49b0-b08e-f3ae80fba11f",
              "builtID": "pa-lower-111",
              "externalID": "ocd-person/23a2b079-dc91-49b0-b08e-f3ae80fba11f",
              "geometry": null
            },
            "contactInfo": {
              "email": "jfritz@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "Preston Township Building 1515 Crosstown Highway, Lakewood, PA 18439",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "repfritz",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1752",
                "https://www.repfritz.com/",
                "https://www.palegis.us/house/members/bio/1752/rep-fritz"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-111-lower-ocd-person-23a2b079-dc91-49b0-b08e-f3ae80fba11f": 0
        }
      },
      "112": {
        "members": [
          {
            "API_ID": "ocd-person/625784c9-4cd0-417c-9c44-1acd53b34307",
            "firstName": "Kyle J.",
            "lastName": "Mullins",
            "fullName": "Kyle Mullins",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1844.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "112",
              "chamber": "lower",
              "hashID": "SLDL-PA-112-lower-ocd-person-625784c9-4cd0-417c-9c44-1acd53b34307",
              "builtID": "pa-lower-112",
              "externalID": "ocd-person/625784c9-4cd0-417c-9c44-1acd53b34307",
              "geometry": null
            },
            "contactInfo": {
              "email": "repmullins@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "520 Burke Bypass Suite 1, Olyphant, PA 18447",
              "phone1": null,
              "phone2": "570-383-9795",
              "fax1": null,
              "fax2": "570-383-9785",
              "twitter": "RepKyleMullins",
              "youtube": "playlist?list=PLjtqNtfs0bgVkHvgNtVPLCpQ6a-Sqde6E",
              "instagram": null,
              "facebook": "RepKyleMullins",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1844",
                "https://www.pahouse.com/mullins",
                "https://www.palegis.us/house/members/bio/1844/rep-mullins"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-112-lower-ocd-person-625784c9-4cd0-417c-9c44-1acd53b34307": 0
        }
      },
      "113": {
        "members": [
          {
            "API_ID": "ocd-person/009be4f2-d613-4353-8126-6b5642d868bc",
            "firstName": "Kyle T.",
            "lastName": "Donahue",
            "fullName": "Kyle Donahue",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1963.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "113",
              "chamber": "lower",
              "hashID": "SLDL-PA-113-lower-ocd-person-009be4f2-d613-4353-8126-6b5642d868bc",
              "builtID": "pa-lower-113",
              "externalID": "ocd-person/009be4f2-d613-4353-8126-6b5642d868bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "repdonahue@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "123 Van Brunt St., Moscow, PA 18444",
              "phone1": null,
              "phone2": "570-842-8116",
              "fax1": null,
              "fax2": "570-842-8120",
              "twitter": "RepDonahue",
              "youtube": null,
              "instagram": null,
              "facebook": "RepDonahue",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1963",
                "https://www.pahouse.com/Donahue/",
                "https://www.palegis.us/house/members/bio/1963/rep-donahue"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-113-lower-ocd-person-009be4f2-d613-4353-8126-6b5642d868bc": 0
        }
      },
      "114": {
        "members": [
          {
            "API_ID": "ocd-person/e5149ed2-f2bb-4054-8064-e2331ffaf1c0",
            "firstName": "Bridget",
            "lastName": "Malloy Kosierowski",
            "fullName": "Bridget Malloy Kosierowski",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1866.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "114",
              "chamber": "lower",
              "hashID": "SLDL-PA-114-lower-ocd-person-e5149ed2-f2bb-4054-8064-e2331ffaf1c0",
              "builtID": "pa-lower-114",
              "externalID": "ocd-person/e5149ed2-f2bb-4054-8064-e2331ffaf1c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "bkosierowski@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "235 Main St. Suite 103, Dickson City, PA 18519",
              "phone1": null,
              "phone2": "570-562-2350",
              "fax1": null,
              "fax2": null,
              "twitter": "RepBridget",
              "youtube": null,
              "instagram": null,
              "facebook": "RepBridget",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1866",
                "https://www.pahouse.com/Kosierowski/",
                "https://www.palegis.us/house/members/bio/1866/rep-kosierowski"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-114-lower-ocd-person-e5149ed2-f2bb-4054-8064-e2331ffaf1c0": 0
        }
      },
      "115": {
        "members": [
          {
            "API_ID": "ocd-person/8ec333cd-97c4-4f5a-92de-13bf8cbc0020",
            "firstName": "Maureen E.",
            "lastName": "Madden",
            "fullName": "Maureen Madden",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/2013.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "115",
              "chamber": "lower",
              "hashID": "SLDL-PA-115-lower-ocd-person-8ec333cd-97c4-4f5a-92de-13bf8cbc0020",
              "builtID": "pa-lower-115",
              "externalID": "ocd-person/8ec333cd-97c4-4f5a-92de-13bf8cbc0020",
              "geometry": null
            },
            "contactInfo": {
              "email": "maureen@maureenmadden.com",
              "contactForm": null,
              "address1": null,
              "address2": "354 Memorial Blvd. #4, Tobyhanna, PA 18466",
              "phone1": null,
              "phone2": "570-894-7905",
              "fax1": null,
              "fax2": "570-894-7906",
              "twitter": "RepMadden",
              "youtube": "playlist?list=jtqNtfs0bgXkOlXTyNgvXM3Q1eW28gRD",
              "instagram": null,
              "facebook": "RepMaureenMadden",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1753",
                "https://www.pahouse.com/Madden/",
                "https://www.palegis.us/house/members/bio/1753/rep-madden"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-115-lower-ocd-person-8ec333cd-97c4-4f5a-92de-13bf8cbc0020": 0
        }
      },
      "116": {
        "members": [
          {
            "API_ID": "ocd-person/d4de5cc2-b3b9-49a0-9f20-62c6f4f6a052",
            "firstName": "Dane Jeffery",
            "lastName": "Watro",
            "fullName": "Dane Watro",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1964.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "116",
              "chamber": "lower",
              "hashID": "SLDL-PA-116-lower-ocd-person-d4de5cc2-b3b9-49a0-9f20-62c6f4f6a052",
              "builtID": "pa-lower-116",
              "externalID": "ocd-person/d4de5cc2-b3b9-49a0-9f20-62c6f4f6a052",
              "geometry": null
            },
            "contactInfo": {
              "email": "dwatro@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "145 E. Broad St. Suite 1, Hazleton, PA 18201",
              "phone1": null,
              "phone2": "570-453-1344",
              "fax1": null,
              "fax2": "570-453-1343",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepWatro",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1964",
                "https://www.repwatro.com",
                "https://www.palegis.us/house/members/bio/1964/rep-watro"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-116-lower-ocd-person-d4de5cc2-b3b9-49a0-9f20-62c6f4f6a052": 0
        }
      },
      "117": {
        "members": [
          {
            "API_ID": "ocd-person/efb61438-e171-442c-bc81-897138ca998c",
            "firstName": "Michael",
            "lastName": "Cabell",
            "fullName": "Mike Cabell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1965.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "117",
              "chamber": "lower",
              "hashID": "SLDL-PA-117-lower-ocd-person-efb61438-e171-442c-bc81-897138ca998c",
              "builtID": "pa-lower-117",
              "externalID": "ocd-person/efb61438-e171-442c-bc81-897138ca998c",
              "geometry": null
            },
            "contactInfo": {
              "email": "mcabell@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "669 State Route 93, Sugarloaf, PA 18249",
              "phone1": null,
              "phone2": "570-359-2138",
              "fax1": null,
              "fax2": "570-359-2139",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepMikeCabell",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1965",
                "https://www.repcabell.com/",
                "https://www.palegis.us/house/members/bio/1965/rep-cabell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-117-lower-ocd-person-efb61438-e171-442c-bc81-897138ca998c": 0
        }
      },
      "118": {
        "members": [
          {
            "API_ID": "ocd-person/3b91abb8-4b49-4fa2-85ed-beea4919bccc",
            "firstName": "James L.",
            "lastName": "Haddock",
            "fullName": "Jim Haddock",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1966.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "118",
              "chamber": "lower",
              "hashID": "SLDL-PA-118-lower-ocd-person-3b91abb8-4b49-4fa2-85ed-beea4919bccc",
              "builtID": "pa-lower-118",
              "externalID": "ocd-person/3b91abb8-4b49-4fa2-85ed-beea4919bccc",
              "geometry": null
            },
            "contactInfo": {
              "email": "rephaddock@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "802 S. Main St., Taylor, PA 18517",
              "phone1": null,
              "phone2": "570-565-2014",
              "fax1": null,
              "fax2": "570-565-2013",
              "twitter": "RepHaddock",
              "youtube": null,
              "instagram": null,
              "facebook": "RepHaddock",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1966",
                "https://www.pahouse.com/Haddock/",
                "https://www.palegis.us/house/members/bio/1966/rep-haddock"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-118-lower-ocd-person-3b91abb8-4b49-4fa2-85ed-beea4919bccc": 0
        }
      },
      "119": {
        "members": [
          {
            "API_ID": "ocd-person/be809fa5-6ef4-4071-bb94-ed10fb051f69",
            "firstName": "Alec Joseph",
            "lastName": "Ryncavage",
            "fullName": "Alec Ryncavage",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1967.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "119",
              "chamber": "lower",
              "hashID": "SLDL-PA-119-lower-ocd-person-be809fa5-6ef4-4071-bb94-ed10fb051f69",
              "builtID": "pa-lower-119",
              "externalID": "ocd-person/be809fa5-6ef4-4071-bb94-ed10fb051f69",
              "geometry": null
            },
            "contactInfo": {
              "email": "aryncavage@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "Fairview Township 65 Shady Tree Drive, Mountain Top, PA 18707",
              "phone1": null,
              "phone2": "570-403-2371",
              "fax1": null,
              "fax2": "570-403-2372",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1967",
                "https://www.repryncavage.com/",
                "https://www.palegis.us/house/members/bio/1967/rep-ryncavage"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-119-lower-ocd-person-be809fa5-6ef4-4071-bb94-ed10fb051f69": 0
        }
      },
      "120": {
        "members": [
          {
            "API_ID": "ocd-person/2cab8939-be5a-492d-8933-ad9f55f12145",
            "firstName": "Aaron D.",
            "lastName": "Kaufer",
            "fullName": "Aaron Kaufer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1693.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "120",
              "chamber": "lower",
              "hashID": "SLDL-PA-120-lower-ocd-person-2cab8939-be5a-492d-8933-ad9f55f12145",
              "builtID": "pa-lower-120",
              "externalID": "ocd-person/2cab8939-be5a-492d-8933-ad9f55f12145",
              "geometry": null
            },
            "contactInfo": {
              "email": "akaufer@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "161 Main St. Suite 201, Keller Complex, Luzerne, PA 18709",
              "phone1": null,
              "phone2": "570-283-1001",
              "fax1": null,
              "fax2": "570-283-1008",
              "twitter": null,
              "youtube": "RepKaufer",
              "instagram": null,
              "facebook": "RepKaufer",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1693",
                "https://www.repkaufer.com/",
                "https://www.palegis.us/house/members/bio/1693/rep-kaufer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-120-lower-ocd-person-2cab8939-be5a-492d-8933-ad9f55f12145": 0
        }
      },
      "121": {
        "members": [
          {
            "API_ID": "ocd-person/c1cb6f95-37c8-4e25-802e-80387e70ad74",
            "firstName": "Edwin Day",
            "lastName": "Pashinski",
            "fullName": "Eddie Pashinski",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1112.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "121",
              "chamber": "lower",
              "hashID": "SLDL-PA-121-lower-ocd-person-c1cb6f95-37c8-4e25-802e-80387e70ad74",
              "builtID": "pa-lower-121",
              "externalID": "ocd-person/c1cb6f95-37c8-4e25-802e-80387e70ad74",
              "geometry": null
            },
            "contactInfo": {
              "email": "reppashinski@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "152 S. Pennsylvania Ave., Wilkes Barre, PA 18701",
              "phone1": null,
              "phone2": "570-825-5934",
              "fax1": null,
              "fax2": "570-826-5436",
              "twitter": "RepPashinski",
              "youtube": "playlist?list=PL39897094CCADCE42",
              "instagram": null,
              "facebook": "Rep-Eddie-Day-Pashinski",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1112",
                "https://pahouse.com/Pashinski",
                "https://www.palegis.us/house/members/bio/1112/rep-pashinski"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-121-lower-ocd-person-c1cb6f95-37c8-4e25-802e-80387e70ad74": 0
        }
      },
      "122": {
        "members": [
          {
            "API_ID": "ocd-person/885e9a2a-c18b-4f68-b5d0-0b867ec3268d",
            "firstName": "Doyle M.",
            "lastName": "Heffley",
            "fullName": "Doyle Heffley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1211.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "122",
              "chamber": "lower",
              "hashID": "SLDL-PA-122-lower-ocd-person-885e9a2a-c18b-4f68-b5d0-0b867ec3268d",
              "builtID": "pa-lower-122",
              "externalID": "ocd-person/885e9a2a-c18b-4f68-b5d0-0b867ec3268d",
              "geometry": null
            },
            "contactInfo": {
              "email": "dheffley@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "Weatherly Borough Hall 10 Wilbur St., Weatherly, PA 18255",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": "RepHeffley",
              "instagram": null,
              "facebook": "RepHeffley",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1211",
                "https://www.repheffley.com/",
                "https://www.palegis.us/house/members/bio/1211/rep-heffley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-122-lower-ocd-person-885e9a2a-c18b-4f68-b5d0-0b867ec3268d": 0
        }
      },
      "123": {
        "members": [
          {
            "API_ID": "ocd-person/686cdc9c-8133-4c1b-a7f0-34b420884559",
            "firstName": "Timothy",
            "lastName": "Twardzik",
            "fullName": "Tim Twardzik",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1906.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "123",
              "chamber": "lower",
              "hashID": "SLDL-PA-123-lower-ocd-person-686cdc9c-8133-4c1b-a7f0-34b420884559",
              "builtID": "pa-lower-123",
              "externalID": "ocd-person/686cdc9c-8133-4c1b-a7f0-34b420884559",
              "geometry": null
            },
            "contactInfo": {
              "email": "ttwardzik@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "15 St. John St., Schuylkill Haven, PA 17972",
              "phone1": null,
              "phone2": "570-593-8120",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "PA-State-Rep-Tim-Twardzik-102703721686271",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1906",
                "https://www.reptimtwardzik.com/",
                "https://www.palegis.us/house/members/bio/1906/rep-twardzik"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-123-lower-ocd-person-686cdc9c-8133-4c1b-a7f0-34b420884559": 0
        }
      },
      "124": {
        "members": [
          {
            "API_ID": "ocd-person/82938e14-97ce-44f0-942f-5bd2d7c532ba",
            "firstName": "James",
            "lastName": "Barton",
            "fullName": "Jamie Barton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1968.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "124",
              "chamber": "lower",
              "hashID": "SLDL-PA-124-lower-ocd-person-82938e14-97ce-44f0-942f-5bd2d7c532ba",
              "builtID": "pa-lower-124",
              "externalID": "ocd-person/82938e14-97ce-44f0-942f-5bd2d7c532ba",
              "geometry": null
            },
            "contactInfo": {
              "email": "jbarton@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "61 N. 3rd St., Hamburg, PA 19526",
              "phone1": null,
              "phone2": "484-660-3721",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepBarton",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1968",
                "https://www.repbarton.com/",
                "https://www.palegis.us/house/members/bio/1968/rep-barton"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-124-lower-ocd-person-82938e14-97ce-44f0-942f-5bd2d7c532ba": 0
        }
      },
      "125": {
        "members": [
          {
            "API_ID": "ocd-person/8a7494a6-3d82-432b-a5c2-6dc46d192611",
            "firstName": "Joseph P.",
            "lastName": "Kerwin",
            "fullName": "Joe Kerwin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1907.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "125",
              "chamber": "lower",
              "hashID": "SLDL-PA-125-lower-ocd-person-8a7494a6-3d82-432b-a5c2-6dc46d192611",
              "builtID": "pa-lower-125",
              "externalID": "ocd-person/8a7494a6-3d82-432b-a5c2-6dc46d192611",
              "geometry": null
            },
            "contactInfo": {
              "email": "jkerwin@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "5821 Linglestown Road Suite 204, Harrisburg, PA 17112",
              "phone1": null,
              "phone2": "717-652-6820",
              "fax1": null,
              "fax2": "717-652-3721",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "PA-State-Rep-Joe-Kerwin-100763238533524",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.repjoekerwin.com/",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1907",
                "https://www.palegis.us/house/members/bio/1907/rep-kerwin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-125-lower-ocd-person-8a7494a6-3d82-432b-a5c2-6dc46d192611": 0
        }
      },
      "126": {
        "members": [
          {
            "API_ID": "ocd-person/06b77ec4-3497-434f-8fdc-6fee29b517e4",
            "firstName": "Mark Lucio",
            "lastName": "Rozzi",
            "fullName": "Mark Rozzi",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1647.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "126",
              "chamber": "lower",
              "hashID": "SLDL-PA-126-lower-ocd-person-06b77ec4-3497-434f-8fdc-6fee29b517e4",
              "builtID": "pa-lower-126",
              "externalID": "ocd-person/06b77ec4-3497-434f-8fdc-6fee29b517e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "reprozzi@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "4933 Kutztown Road, Temple, PA 19560",
              "phone1": null,
              "phone2": "610-921-8921",
              "fax1": null,
              "fax2": "610-921-9369",
              "twitter": "RepRozzi",
              "youtube": "playlist?list=PLjtqNtfs0bgW43V45TivjTW2IPKVxsh-3",
              "instagram": null,
              "facebook": "RepRozzi",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1647",
                "https://www.pahouse.com/Rozzi/",
                "https://www.palegis.us/house/members/bio/1647/rep-rozzi"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-126-lower-ocd-person-06b77ec4-3497-434f-8fdc-6fee29b517e4": 0
        }
      },
      "127": {
        "members": [
          {
            "API_ID": "ocd-person/d26c016b-27e5-4171-849b-94773178023d",
            "firstName": "Manuel M.",
            "lastName": "Guzman",
            "fullName": "Manny Guzman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1908.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "127",
              "chamber": "lower",
              "hashID": "SLDL-PA-127-lower-ocd-person-d26c016b-27e5-4171-849b-94773178023d",
              "builtID": "pa-lower-127",
              "externalID": "ocd-person/d26c016b-27e5-4171-849b-94773178023d",
              "geometry": null
            },
            "contactInfo": {
              "email": "repguzman@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "645 Penn St. 5th Floor, Reading, PA 19601",
              "phone1": null,
              "phone2": "610-376-1529",
              "fax1": null,
              "fax2": "610-378-4406",
              "twitter": "RepMannyGuzman",
              "youtube": null,
              "instagram": null,
              "facebook": "RepGuzman",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1908",
                "https://www.pahouse.com/guzman/",
                "https://www.palegis.us/house/members/bio/1908/rep-guzman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-127-lower-ocd-person-d26c016b-27e5-4171-849b-94773178023d": 0
        }
      },
      "128": {
        "members": [
          {
            "API_ID": "ocd-person/cd92c819-d5aa-4df3-ba96-2315dbdf4555",
            "firstName": "Mark M.",
            "lastName": "Gillen",
            "fullName": "Mark Gillen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1209.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "128",
              "chamber": "lower",
              "hashID": "SLDL-PA-128-lower-ocd-person-cd92c819-d5aa-4df3-ba96-2315dbdf4555",
              "builtID": "pa-lower-128",
              "externalID": "ocd-person/cd92c819-d5aa-4df3-ba96-2315dbdf4555",
              "geometry": null
            },
            "contactInfo": {
              "email": "mgillen@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "29 Village Center Drive Suite A-7, Reading, PA 19607",
              "phone1": null,
              "phone2": "610-775-5130",
              "fax1": null,
              "fax2": "610-775-3736",
              "twitter": null,
              "youtube": "RepGillen",
              "instagram": null,
              "facebook": "RepGillen",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1209",
                "https://www.repgillen.com/",
                "https://www.palegis.us/house/members/bio/1209/rep-gillen"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-128-lower-ocd-person-cd92c819-d5aa-4df3-ba96-2315dbdf4555": 0
        }
      },
      "129": {
        "members": [
          {
            "API_ID": "ocd-person/8d5ab463-45d5-4cf0-9c65-85820c08e506",
            "firstName": "Johanny",
            "lastName": "Cepeda-Freytiz",
            "fullName": "Johanny Cepeda-Freytiz",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1969.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "129",
              "chamber": "lower",
              "hashID": "SLDL-PA-129-lower-ocd-person-8d5ab463-45d5-4cf0-9c65-85820c08e506",
              "builtID": "pa-lower-129",
              "externalID": "ocd-person/8d5ab463-45d5-4cf0-9c65-85820c08e506",
              "geometry": null
            },
            "contactInfo": {
              "email": "repcepedafreytiz@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "1111 Penn Ave., Wyomissing, PA 19610",
              "phone1": null,
              "phone2": "484-577-2756",
              "fax1": null,
              "fax2": null,
              "twitter": "RepJohanny",
              "youtube": null,
              "instagram": null,
              "facebook": "RepJohanny",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1969",
                "https://www.pahouse.com/Cepeda-Freytiz/",
                "https://www.palegis.us/house/members/bio/1969/rep-cepeda-freytiz"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-129-lower-ocd-person-8d5ab463-45d5-4cf0-9c65-85820c08e506": 0
        }
      },
      "130": {
        "members": [
          {
            "API_ID": "ocd-person/fb4a0115-dd63-4e25-8d36-0ea6afc55faf",
            "firstName": "David M.",
            "lastName": "Maloney",
            "fullName": "David Maloney",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1226.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "130",
              "chamber": "lower",
              "hashID": "SLDL-PA-130-lower-ocd-person-fb4a0115-dd63-4e25-8d36-0ea6afc55faf",
              "builtID": "pa-lower-130",
              "externalID": "ocd-person/fb4a0115-dd63-4e25-8d36-0ea6afc55faf",
              "geometry": null
            },
            "contactInfo": {
              "email": "dmaloney@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "46 E. Philadelphia Ave., Boyertown, PA 19512",
              "phone1": null,
              "phone2": "610-369-3010",
              "fax1": null,
              "fax2": "610-369-3011",
              "twitter": null,
              "youtube": "RepMaloney",
              "instagram": null,
              "facebook": "RepMaloney",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1226",
                "https://www.repmaloney.com/",
                "https://www.palegis.us/house/members/bio/1226/rep-maloney"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-130-lower-ocd-person-fb4a0115-dd63-4e25-8d36-0ea6afc55faf": 0
        }
      },
      "131": {
        "members": [
          {
            "API_ID": "ocd-person/b4559406-2139-4c7c-9ce9-7a2a604a8a95",
            "firstName": "Victoria Milou",
            "lastName": "Mackenzie",
            "fullName": "Milou Mackenzie",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1909.jpg?1703415645672g",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "131",
              "chamber": "lower",
              "hashID": "SLDL-PA-131-lower-ocd-person-b4559406-2139-4c7c-9ce9-7a2a604a8a95",
              "builtID": "pa-lower-131",
              "externalID": "ocd-person/b4559406-2139-4c7c-9ce9-7a2a604a8a95",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmackenzie@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "Red Hill Borough Building 56 W. 4th St., Red Hill, PA 18076",
              "phone1": null,
              "phone2": "215-679-3152",
              "fax1": null,
              "fax2": "215-679-3155",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "PA-State-Rep-Milou-Mackenzie-100834955213333",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.repmiloumackenziepa.com/",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1909",
                "https://www.palegis.us/house/members/bio/1909/rep-m-mackenzie"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-131-lower-ocd-person-b4559406-2139-4c7c-9ce9-7a2a604a8a95": 0
        }
      },
      "132": {
        "members": [
          {
            "API_ID": "ocd-person/fc1f0752-9166-4d7f-987a-ec15b8bf4048",
            "firstName": "Michael H.",
            "lastName": "Schlossberg",
            "fullName": "Mike Schlossberg",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1649.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "132",
              "chamber": "lower",
              "hashID": "SLDL-PA-132-lower-ocd-person-fc1f0752-9166-4d7f-987a-ec15b8bf4048",
              "builtID": "pa-lower-132",
              "externalID": "ocd-person/fc1f0752-9166-4d7f-987a-ec15b8bf4048",
              "geometry": null
            },
            "contactInfo": {
              "email": "repschlossberg@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "5100 Tilghman St. Suite 200, Allentown, PA 18104",
              "phone1": null,
              "phone2": "610-821-5577",
              "fax1": null,
              "fax2": null,
              "twitter": "RepSchlossberg",
              "youtube": "playlist?list=PLjtqNtfs0bgUKhrdudwl7UauY7EO1330Z",
              "instagram": null,
              "facebook": "RepSchlossberg",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1649",
                "https://www.pahouse.com/Schlossberg/",
                "https://www.palegis.us/house/members/bio/1649/rep-schlossberg"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-132-lower-ocd-person-fc1f0752-9166-4d7f-987a-ec15b8bf4048": 0
        }
      },
      "133": {
        "members": [
          {
            "API_ID": "ocd-person/ddd14a2f-ebef-4918-bf91-578b71c085e4",
            "firstName": "Jeanne Mudri",
            "lastName": "McNeill",
            "fullName": "Jeanne McNeill",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1793.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "133",
              "chamber": "lower",
              "hashID": "SLDL-PA-133-lower-ocd-person-ddd14a2f-ebef-4918-bf91-578b71c085e4",
              "builtID": "pa-lower-133",
              "externalID": "ocd-person/ddd14a2f-ebef-4918-bf91-578b71c085e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "jmcneill@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "1080 Schadt Ave., Whitehall, PA 18052",
              "phone1": null,
              "phone2": "610-266-1273",
              "fax1": null,
              "fax2": "610-266-2126",
              "twitter": "RepMcNeill",
              "youtube": null,
              "instagram": null,
              "facebook": "RepMcNeill",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1793",
                "https://www.pahouse.com/McNeill/",
                "https://www.palegis.us/house/members/bio/1793/rep-mcneill"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-133-lower-ocd-person-ddd14a2f-ebef-4918-bf91-578b71c085e4": 0
        }
      },
      "134": {
        "members": [
          {
            "API_ID": "ocd-person/63936c68-ec65-40a9-9a68-0d956939404a",
            "firstName": "Peter George",
            "lastName": "Schweyer",
            "fullName": "Pete Schweyer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1706.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "134",
              "chamber": "lower",
              "hashID": "SLDL-PA-134-lower-ocd-person-63936c68-ec65-40a9-9a68-0d956939404a",
              "builtID": "pa-lower-134",
              "externalID": "ocd-person/63936c68-ec65-40a9-9a68-0d956939404a",
              "geometry": null
            },
            "contactInfo": {
              "email": "repschweyer@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "1501 Lehigh St. Suite 206, Allentown, PA 18103",
              "phone1": null,
              "phone2": "610-791-6270",
              "fax1": null,
              "fax2": "610-791-6274",
              "twitter": "RepSchweyer",
              "youtube": null,
              "instagram": null,
              "facebook": "RepSchweyer",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1706",
                "https://www.pahouse.com/Schweyer/",
                "https://www.palegis.us/house/members/bio/1706/rep-schweyer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-134-lower-ocd-person-63936c68-ec65-40a9-9a68-0d956939404a": 0
        }
      },
      "135": {
        "members": [
          {
            "API_ID": "ocd-person/34472dfb-dae9-4db4-8901-088457d74904",
            "firstName": "Steven",
            "lastName": "Samuelson",
            "fullName": "Steve Samuelson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/80.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "135",
              "chamber": "lower",
              "hashID": "SLDL-PA-135-lower-ocd-person-34472dfb-dae9-4db4-8901-088457d74904",
              "builtID": "pa-lower-135",
              "externalID": "ocd-person/34472dfb-dae9-4db4-8901-088457d74904",
              "geometry": null
            },
            "contactInfo": {
              "email": "ssamuels@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "104 E. Broad St., Bethlehem, PA 18018",
              "phone1": null,
              "phone2": "610-867-3890",
              "fax1": null,
              "fax2": "610-861-2104",
              "twitter": null,
              "youtube": "playlist?list=PL3833B2698B7840D7",
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=80",
                "https://www.pahouse.com/samuelson",
                "https://www.palegis.us/house/members/bio/80/rep-samuelson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-135-lower-ocd-person-34472dfb-dae9-4db4-8901-088457d74904": 0
        }
      },
      "136": {
        "members": [
          {
            "API_ID": "ocd-person/13bfe11c-06e2-4a1d-844d-d195ef18dc06",
            "firstName": "Robert L.",
            "lastName": "Freeman",
            "fullName": "Bob Freeman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/136.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "136",
              "chamber": "lower",
              "hashID": "SLDL-PA-136-lower-ocd-person-13bfe11c-06e2-4a1d-844d-d195ef18dc06",
              "builtID": "pa-lower-136",
              "externalID": "ocd-person/13bfe11c-06e2-4a1d-844d-d195ef18dc06",
              "geometry": null
            },
            "contactInfo": {
              "email": "rfreeman@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "215 Northampton St., Easton, PA 18042",
              "phone1": null,
              "phone2": "610-253-5543",
              "fax1": null,
              "fax2": "610-250-2645",
              "twitter": null,
              "youtube": "playlist?list=PLD91FC5F7839C0E52",
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=136",
                "https://www.pahouse.com/freeman",
                "https://www.palegis.us/house/members/bio/136/rep-freeman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-136-lower-ocd-person-13bfe11c-06e2-4a1d-844d-d195ef18dc06": 0
        }
      },
      "137": {
        "members": [
          {
            "API_ID": "ocd-person/0e873ea0-c8a2-4fbb-a408-de0c5bd36de9",
            "firstName": "Joseph T.",
            "lastName": "Emrick",
            "fullName": "Joe Emrick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1207.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "137",
              "chamber": "lower",
              "hashID": "SLDL-PA-137-lower-ocd-person-0e873ea0-c8a2-4fbb-a408-de0c5bd36de9",
              "builtID": "pa-lower-137",
              "externalID": "ocd-person/0e873ea0-c8a2-4fbb-a408-de0c5bd36de9",
              "geometry": null
            },
            "contactInfo": {
              "email": "jemrick@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "301 Village at Stones Crossing Road, Easton, PA 18045",
              "phone1": null,
              "phone2": "484-544-0146",
              "fax1": null,
              "fax2": "484-544-0147",
              "twitter": null,
              "youtube": "RepEmrick",
              "instagram": null,
              "facebook": "RepEmrick",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1207",
                "https://www.repemrick.com/",
                "https://www.palegis.us/house/members/bio/1207/rep-emrick"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-137-lower-ocd-person-0e873ea0-c8a2-4fbb-a408-de0c5bd36de9": 0
        }
      },
      "138": {
        "members": [
          {
            "API_ID": "ocd-person/e00e0f63-7058-46a4-8b63-f31dc804c614",
            "firstName": "Ann Rissmiller",
            "lastName": "Flood",
            "fullName": "Ann Flood",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1910.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "138",
              "chamber": "lower",
              "hashID": "SLDL-PA-138-lower-ocd-person-e00e0f63-7058-46a4-8b63-f31dc804c614",
              "builtID": "pa-lower-138",
              "externalID": "ocd-person/e00e0f63-7058-46a4-8b63-f31dc804c614",
              "geometry": null
            },
            "contactInfo": {
              "email": "aflood@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "962 Pennsylvania Ave., Pen Argyl, PA 18072",
              "phone1": null,
              "phone2": "610-746-2100",
              "fax1": null,
              "fax2": "610-746-1504",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "PA-State-Rep-Ann-Flood-102454235027275",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.repannflood.com/",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1910",
                "https://www.palegis.us/house/members/bio/1910/rep-flood"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-138-lower-ocd-person-e00e0f63-7058-46a4-8b63-f31dc804c614": 0
        }
      },
      "139": {
        "members": [
          {
            "API_ID": "ocd-person/08211014-af0e-4d71-92a7-e0045ba8062a",
            "firstName": "Jeffrey H.",
            "lastName": "Olsommer",
            "fullName": "Jeff Olsommer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Jeff_Olsommer.jpeg",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "139",
              "chamber": "lower",
              "hashID": "SLDL-PA-139-lower-ocd-person-08211014-af0e-4d71-92a7-e0045ba8062a",
              "builtID": "pa-lower-139",
              "externalID": "ocd-person/08211014-af0e-4d71-92a7-e0045ba8062a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jolsommer@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "2523 Route 6, Suite 2, Hawley, PA 18428",
              "phone1": null,
              "phone2": "570-226-5959",
              "fax1": null,
              "fax2": "570-226-5955",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=2018",
                "https://www.palegis.us/house/members/bio/2018/rep-olsommer",
                "https://www.repolsommer.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-139-lower-ocd-person-08211014-af0e-4d71-92a7-e0045ba8062a": 0
        }
      },
      "140": {
        "members": [
          {
            "API_ID": "ocd-person/3c3cd25a-658f-4aab-a648-ef2509469c34",
            "firstName": "James G.",
            "lastName": "Prokopiak",
            "fullName": "Jim Prokopiak",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.palegis.us/resources/images/members/200/2017.jpg?20240411",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "140",
              "chamber": "lower",
              "hashID": "SLDL-PA-140-lower-ocd-person-3c3cd25a-658f-4aab-a648-ef2509469c34",
              "builtID": "pa-lower-140",
              "externalID": "ocd-person/3c3cd25a-658f-4aab-a648-ef2509469c34",
              "geometry": null
            },
            "contactInfo": {
              "email": "repprokopiak@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "8610 New Falls Road, Levitown, PA 19054",
              "phone1": null,
              "phone2": "215-943-7206",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=2017",
                "https://www.pahouse.com/Prokopiak/",
                "https://www.palegis.us/house/members/bio/2017/rep-prokopiak"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-140-lower-ocd-person-3c3cd25a-658f-4aab-a648-ef2509469c34": 0
        }
      },
      "141": {
        "members": [
          {
            "API_ID": "ocd-person/f9a1f1ab-9538-424c-87f1-65e2a4f4bfc7",
            "firstName": "Tina Marie",
            "lastName": "Davis",
            "fullName": "Tina Davis",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1204.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "141",
              "chamber": "lower",
              "hashID": "SLDL-PA-141-lower-ocd-person-f9a1f1ab-9538-424c-87f1-65e2a4f4bfc7",
              "builtID": "pa-lower-141",
              "externalID": "ocd-person/f9a1f1ab-9538-424c-87f1-65e2a4f4bfc7",
              "geometry": null
            },
            "contactInfo": {
              "email": "repdavis@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "3611 Green Lane, Levittown, PA 19057",
              "phone1": null,
              "phone2": "267-580-2660",
              "fax1": null,
              "fax2": "267-580-2665",
              "twitter": "RepTinaDavis",
              "youtube": "playlist?list=PL6C0B99856DF38563",
              "instagram": null,
              "facebook": "RepTinaDavis",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1204",
                "https://www.pahouse.com/davis",
                "https://www.palegis.us/house/members/bio/1204/rep-t-davis"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-141-lower-ocd-person-f9a1f1ab-9538-424c-87f1-65e2a4f4bfc7": 0
        }
      },
      "142": {
        "members": [
          {
            "API_ID": "ocd-person/b1beaa5c-93d2-479e-9408-47c7687cd212",
            "firstName": "Joseph F.",
            "lastName": "Hogan",
            "fullName": "Joe Hogan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1971.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "142",
              "chamber": "lower",
              "hashID": "SLDL-PA-142-lower-ocd-person-b1beaa5c-93d2-479e-9408-47c7687cd212",
              "builtID": "pa-lower-142",
              "externalID": "ocd-person/b1beaa5c-93d2-479e-9408-47c7687cd212",
              "geometry": null
            },
            "contactInfo": {
              "email": "jhogan@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "51 School Lane, Feasterville, PA 19053",
              "phone1": null,
              "phone2": "215-942-4067",
              "fax1": null,
              "fax2": "215-942-4069",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepHogan",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1971",
                "https://www.rephogan.com/",
                "https://www.palegis.us/house/members/bio/1971/rep-hogan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-142-lower-ocd-person-b1beaa5c-93d2-479e-9408-47c7687cd212": 0
        }
      },
      "143": {
        "members": [
          {
            "API_ID": "ocd-person/7c0b9792-8ece-4ae8-b0f3-f5d4b79ff9f1",
            "firstName": "Shelby",
            "lastName": "Labs",
            "fullName": "Shelby Labs",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1911.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "143",
              "chamber": "lower",
              "hashID": "SLDL-PA-143-lower-ocd-person-7c0b9792-8ece-4ae8-b0f3-f5d4b79ff9f1",
              "builtID": "pa-lower-143",
              "externalID": "ocd-person/7c0b9792-8ece-4ae8-b0f3-f5d4b79ff9f1",
              "geometry": null
            },
            "contactInfo": {
              "email": "slabs@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "1032 N. Easton Road Suite C, Doylestown, PA 18902",
              "phone1": null,
              "phone2": "215-489-2126",
              "fax1": null,
              "fax2": "215-345-1361",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "PA-State-Rep-Shelby-Labs-103315218289766",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1911",
                "https://www.repshelbylabs.net/",
                "https://www.palegis.us/house/members/bio/1911/rep-labs"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-143-lower-ocd-person-7c0b9792-8ece-4ae8-b0f3-f5d4b79ff9f1": 0
        }
      },
      "144": {
        "members": [
          {
            "API_ID": "ocd-person/0f035e7e-d035-4d8c-bc5b-876ba70452cc",
            "firstName": "Brian",
            "lastName": "Munroe",
            "fullName": "Brian Munroe",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1972.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "144",
              "chamber": "lower",
              "hashID": "SLDL-PA-144-lower-ocd-person-0f035e7e-d035-4d8c-bc5b-876ba70452cc",
              "builtID": "pa-lower-144",
              "externalID": "ocd-person/0f035e7e-d035-4d8c-bc5b-876ba70452cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "repmunroe@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "1410 W. Street Road Suite A, Warminster, PA 18974",
              "phone1": null,
              "phone2": "215-323-6997",
              "fax1": null,
              "fax2": "267-323-6697",
              "twitter": "RepMunroe",
              "youtube": null,
              "instagram": null,
              "facebook": "RepMunroe",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1972",
                "https://www.pahouse.com/munroe/",
                "https://www.palegis.us/house/members/bio/1972/rep-munroe"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-144-lower-ocd-person-0f035e7e-d035-4d8c-bc5b-876ba70452cc": 0
        }
      },
      "145": {
        "members": [
          {
            "API_ID": "ocd-person/bb324f66-3a5c-4e90-9c75-d142cce81c92",
            "firstName": "Craig T.",
            "lastName": "Staats",
            "fullName": "Craig Staats",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1707.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "145",
              "chamber": "lower",
              "hashID": "SLDL-PA-145-lower-ocd-person-bb324f66-3a5c-4e90-9c75-d142cce81c92",
              "builtID": "pa-lower-145",
              "externalID": "ocd-person/bb324f66-3a5c-4e90-9c75-d142cce81c92",
              "geometry": null
            },
            "contactInfo": {
              "email": "cstaats@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "10 S. 3rd St., Quakertown, PA 18951",
              "phone1": null,
              "phone2": "215-536-1434",
              "fax1": null,
              "fax2": "215-536-1437",
              "twitter": null,
              "youtube": "RepStaats",
              "instagram": null,
              "facebook": "PA-State-Rep-Craig-Staats",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1707",
                "https://www.repstaats.com/",
                "https://www.palegis.us/house/members/bio/1707/rep-staats"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-145-lower-ocd-person-bb324f66-3a5c-4e90-9c75-d142cce81c92": 0
        }
      },
      "146": {
        "members": [
          {
            "API_ID": "ocd-person/c9521d0e-56ca-40d2-b9bc-7a1f1be639bc",
            "firstName": "Joseph",
            "lastName": "Ciresi",
            "fullName": "Joe Ciresi",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1847.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "146",
              "chamber": "lower",
              "hashID": "SLDL-PA-146-lower-ocd-person-c9521d0e-56ca-40d2-b9bc-7a1f1be639bc",
              "builtID": "pa-lower-146",
              "externalID": "ocd-person/c9521d0e-56ca-40d2-b9bc-7a1f1be639bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "jciresi@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "Park Towne Plaza 301 N. Lewis Road, Suite 140C, Royersford, PA 19468",
              "phone1": null,
              "phone2": "484-200-8265",
              "fax1": null,
              "fax2": "484-200-8275",
              "twitter": "RepCiresi",
              "youtube": "playlist?list=PLjtqNtfs0bgVIhyB4HWbJiOz-5wKB7QG-",
              "instagram": "repciresi",
              "facebook": "RepCiresi",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1847",
                "https://www.pahouse.com/ciresi",
                "https://www.palegis.us/house/members/bio/1847/rep-ciresi"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-146-lower-ocd-person-c9521d0e-56ca-40d2-b9bc-7a1f1be639bc": 0
        }
      },
      "147": {
        "members": [
          {
            "API_ID": "ocd-person/5913495d-53f8-4c39-8507-650cfe51c08b",
            "firstName": "Donna",
            "lastName": "Scheuren",
            "fullName": "Donna Scheuren",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1973.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "147",
              "chamber": "lower",
              "hashID": "SLDL-PA-147-lower-ocd-person-5913495d-53f8-4c39-8507-650cfe51c08b",
              "builtID": "pa-lower-147",
              "externalID": "ocd-person/5913495d-53f8-4c39-8507-650cfe51c08b",
              "geometry": null
            },
            "contactInfo": {
              "email": "dscheuren@pahousegop.gov",
              "contactForm": null,
              "address1": null,
              "address2": "410 Main St., Suite 200, Harleysville, PA 19438",
              "phone1": null,
              "phone2": "215-256-6030",
              "fax1": null,
              "fax2": "215-256-6069",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepScheuren",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1973",
                "https://www.repscheuren.com",
                "https://www.palegis.us/house/members/bio/1973/rep-scheuren"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-147-lower-ocd-person-5913495d-53f8-4c39-8507-650cfe51c08b": 0
        }
      },
      "148": {
        "members": [
          {
            "API_ID": "ocd-person/2b9c199b-dd4e-477a-a5d0-849180c956a0",
            "firstName": "Mary Josephine",
            "lastName": "Daley",
            "fullName": "Mary Jo Daley",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1622.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "148",
              "chamber": "lower",
              "hashID": "SLDL-PA-148-lower-ocd-person-2b9c199b-dd4e-477a-a5d0-849180c956a0",
              "builtID": "pa-lower-148",
              "externalID": "ocd-person/2b9c199b-dd4e-477a-a5d0-849180c956a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "repmaryjodaley@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "312 Old Lancaster Rd., Merion Station, PA 19066",
              "phone1": null,
              "phone2": "610-832-1679",
              "fax1": null,
              "fax2": "610-832-1684",
              "twitter": "RepMJDaley",
              "youtube": "playlist?list=PLjtqNtfs0bgVcpT_30Ku0bBEfTC5kGzUr",
              "instagram": null,
              "facebook": "RepMJDaley",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1622",
                "https://www.pahouse.com/MDaley/",
                "https://www.palegis.us/house/members/bio/1622/rep-daley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-148-lower-ocd-person-2b9c199b-dd4e-477a-a5d0-849180c956a0": 0
        }
      },
      "149": {
        "members": [
          {
            "API_ID": "ocd-person/5c752a95-ba5d-4f1f-81aa-6cb1c7875581",
            "firstName": "Timothy P.",
            "lastName": "Briggs",
            "fullName": "Tim Briggs",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1159.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "149",
              "chamber": "lower",
              "hashID": "SLDL-PA-149-lower-ocd-person-5c752a95-ba5d-4f1f-81aa-6cb1c7875581",
              "builtID": "pa-lower-149",
              "externalID": "ocd-person/5c752a95-ba5d-4f1f-81aa-6cb1c7875581",
              "geometry": null
            },
            "contactInfo": {
              "email": "repbriggs@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "554 Shoemaker Road Suite 149, King of Prussia, PA 19406",
              "phone1": null,
              "phone2": "610-768-3135",
              "fax1": null,
              "fax2": "610-768-3112",
              "twitter": "RepBriggs",
              "youtube": "playlist?list=PL4F4B6A26E4DBF5A8",
              "instagram": null,
              "facebook": "Rep-Tim-Briggs",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1159",
                "https://www.pahouse.com/Briggs",
                "https://www.palegis.us/house/members/bio/1159/rep-briggs"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-149-lower-ocd-person-5c752a95-ba5d-4f1f-81aa-6cb1c7875581": 0
        }
      },
      "150": {
        "members": [
          {
            "API_ID": "ocd-person/676410b6-c3aa-4c17-856f-7d896e6fbc83",
            "firstName": "Joseph Gerald",
            "lastName": "Webster",
            "fullName": "Joe Webster",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1848.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "150",
              "chamber": "lower",
              "hashID": "SLDL-PA-150-lower-ocd-person-676410b6-c3aa-4c17-856f-7d896e6fbc83",
              "builtID": "pa-lower-150",
              "externalID": "ocd-person/676410b6-c3aa-4c17-856f-7d896e6fbc83",
              "geometry": null
            },
            "contactInfo": {
              "email": "repwebster@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "35 Evansburg Road, Collegeville, PA 19426",
              "phone1": null,
              "phone2": "484-200-8258",
              "fax1": null,
              "fax2": null,
              "twitter": "RepJoeWebster",
              "youtube": "playlist?list=PLjtqNtfs0bgVqc-0u5IRw2yt5F84yWNoy",
              "instagram": null,
              "facebook": "RepJoeWebster",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1848",
                "https://www.pahouse.com/webster",
                "https://www.palegis.us/house/members/bio/1848/rep-webster"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-150-lower-ocd-person-676410b6-c3aa-4c17-856f-7d896e6fbc83": 0
        }
      },
      "151": {
        "members": [
          {
            "API_ID": "ocd-person/610a353c-8973-48ea-a4d4-dac21efc3422",
            "firstName": "Melissa",
            "lastName": "Cerrato",
            "fullName": "Missy Cerrato",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1974.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "151",
              "chamber": "lower",
              "hashID": "SLDL-PA-151-lower-ocd-person-610a353c-8973-48ea-a4d4-dac21efc3422",
              "builtID": "pa-lower-151",
              "externalID": "ocd-person/610a353c-8973-48ea-a4d4-dac21efc3422",
              "geometry": null
            },
            "contactInfo": {
              "email": "repcerrato@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "605 Horsham Road, Horsham, PA 19044",
              "phone1": null,
              "phone2": "267-282-6695",
              "fax1": null,
              "fax2": null,
              "twitter": "RepCerrato",
              "youtube": null,
              "instagram": null,
              "facebook": "RepCerrato",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1974",
                "https://www.pahouse.com/cerrato/",
                "https://www.palegis.us/house/members/bio/1974/rep-cerrato"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-151-lower-ocd-person-610a353c-8973-48ea-a4d4-dac21efc3422": 0
        }
      },
      "152": {
        "members": [
          {
            "API_ID": "ocd-person/d18ccbd7-270a-46c2-8bbd-a19ea82d0e51",
            "firstName": "Nancy",
            "lastName": "Guenst",
            "fullName": "Nancy Guenst",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1913.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "152",
              "chamber": "lower",
              "hashID": "SLDL-PA-152-lower-ocd-person-d18ccbd7-270a-46c2-8bbd-a19ea82d0e51",
              "builtID": "pa-lower-152",
              "externalID": "ocd-person/d18ccbd7-270a-46c2-8bbd-a19ea82d0e51",
              "geometry": null
            },
            "contactInfo": {
              "email": "repguenst@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "19 S. York Road, Hatboro, PA 19040",
              "phone1": null,
              "phone2": "215-259-5962",
              "fax1": null,
              "fax2": "215-259-5856",
              "twitter": "RepGuenst",
              "youtube": null,
              "instagram": null,
              "facebook": "RepGuenst",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1913",
                "https://www.pahouse.com/Guenst/",
                "https://www.palegis.us/house/members/bio/1913/rep-guenst"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-152-lower-ocd-person-d18ccbd7-270a-46c2-8bbd-a19ea82d0e51": 0
        }
      },
      "153": {
        "members": [
          {
            "API_ID": "ocd-person/b64463e2-41f6-4d58-a725-03db71798c1c",
            "firstName": "Benjamin V.",
            "lastName": "Sanchez",
            "fullName": "Ben Sanchez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1849.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "153",
              "chamber": "lower",
              "hashID": "SLDL-PA-153-lower-ocd-person-b64463e2-41f6-4d58-a725-03db71798c1c",
              "builtID": "pa-lower-153",
              "externalID": "ocd-person/b64463e2-41f6-4d58-a725-03db71798c1c",
              "geometry": null
            },
            "contactInfo": {
              "email": "repsanchez@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "1175 Old York Road, Abington, PA 19001",
              "phone1": null,
              "phone2": "215-517-6800",
              "fax1": null,
              "fax2": "215-517-6828",
              "twitter": "RepBenSanchez",
              "youtube": "playlist?list=PLjtqNtfs0bgUooLKfgdclJXjFq2HnB_CL",
              "instagram": null,
              "facebook": "RepBenSanchez",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1849",
                "https://www.pahouse.com/sanchez",
                "https://www.palegis.us/house/members/bio/1849/rep-sanchez"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-153-lower-ocd-person-b64463e2-41f6-4d58-a725-03db71798c1c": 0
        }
      },
      "154": {
        "members": [
          {
            "API_ID": "ocd-person/94e57e39-e58d-4374-9506-ece1a01e0ebd",
            "firstName": "Napoleon Jason",
            "lastName": "Nelson",
            "fullName": "Napoleon Nelson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1914.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "154",
              "chamber": "lower",
              "hashID": "SLDL-PA-154-lower-ocd-person-94e57e39-e58d-4374-9506-ece1a01e0ebd",
              "builtID": "pa-lower-154",
              "externalID": "ocd-person/94e57e39-e58d-4374-9506-ece1a01e0ebd",
              "geometry": null
            },
            "contactInfo": {
              "email": "repnelson@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "115 E. Glenside Ave. Suite 8, Glenside, PA 19038",
              "phone1": null,
              "phone2": "215-572-5210",
              "fax1": null,
              "fax2": "215-517-1423",
              "twitter": "RepNapoleon",
              "youtube": null,
              "instagram": null,
              "facebook": "RepNapoleon",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1914",
                "https://www.pahouse.com/nelson/",
                "https://www.palegis.us/house/members/bio/1914/rep-n-nelson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-154-lower-ocd-person-94e57e39-e58d-4374-9506-ece1a01e0ebd": 0
        }
      },
      "155": {
        "members": [
          {
            "API_ID": "ocd-person/6678bcaf-292f-408a-aa36-90ed8bc8fd08",
            "firstName": "Danielle Friel",
            "lastName": "Otten",
            "fullName": "Danielle Otten",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1850.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "155",
              "chamber": "lower",
              "hashID": "SLDL-PA-155-lower-ocd-person-6678bcaf-292f-408a-aa36-90ed8bc8fd08",
              "builtID": "pa-lower-155",
              "externalID": "ocd-person/6678bcaf-292f-408a-aa36-90ed8bc8fd08",
              "geometry": null
            },
            "contactInfo": {
              "email": "repotten@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "631 N. Pottstown Pike, Exton, PA 19341",
              "phone1": null,
              "phone2": "484-200-8259",
              "fax1": null,
              "fax2": "484-200-8269",
              "twitter": "RepDanielle",
              "youtube": "playlist?list=PLjtqNtfs0bgVr-fZ-diFi9mzN-IRKsx2e",
              "instagram": null,
              "facebook": "RepDanielle",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1850",
                "https://www.pahouse.com/frielotten",
                "https://www.palegis.us/house/members/bio/1850/rep-otten"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-155-lower-ocd-person-6678bcaf-292f-408a-aa36-90ed8bc8fd08": 0
        }
      },
      "156": {
        "members": [
          {
            "API_ID": "ocd-person/d83a0679-02c9-469b-89ed-11f9b46166c5",
            "firstName": "Christopher",
            "lastName": "Pielli",
            "fullName": "Chris Pielli",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1975.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "156",
              "chamber": "lower",
              "hashID": "SLDL-PA-156-lower-ocd-person-d83a0679-02c9-469b-89ed-11f9b46166c5",
              "builtID": "pa-lower-156",
              "externalID": "ocd-person/d83a0679-02c9-469b-89ed-11f9b46166c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "reppielli@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "121 W. Miner St. Suite 1E, West Chester, PA 19382",
              "phone1": null,
              "phone2": "610-696-4990",
              "fax1": null,
              "fax2": null,
              "twitter": "RepPielli",
              "youtube": null,
              "instagram": null,
              "facebook": "RepPielli",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1975",
                "https://www.pahouse.com/Pielli/",
                "https://www.palegis.us/house/members/bio/1975/rep-pielli"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-156-lower-ocd-person-d83a0679-02c9-469b-89ed-11f9b46166c5": 0
        }
      },
      "157": {
        "members": [
          {
            "API_ID": "ocd-person/2cb7688e-e8ea-431e-9f56-34ee9a9a3ecc",
            "firstName": "Melissa L.",
            "lastName": "Shusterman",
            "fullName": "Melissa Shusterman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1851.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "157",
              "chamber": "lower",
              "hashID": "SLDL-PA-157-lower-ocd-person-2cb7688e-e8ea-431e-9f56-34ee9a9a3ecc",
              "builtID": "pa-lower-157",
              "externalID": "ocd-person/2cb7688e-e8ea-431e-9f56-34ee9a9a3ecc",
              "geometry": null
            },
            "contactInfo": {
              "email": "repshusterman@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "42 E. Lancaster Ave. Suite A, Paoli, PA 19301",
              "phone1": null,
              "phone2": "484-200-8260",
              "fax1": null,
              "fax2": "484-200-8272",
              "twitter": "RepShusterman",
              "youtube": "playlist?list=PLjtqNtfs0bgXjeB8QUgnxtsPuHEN1Zzs1",
              "instagram": null,
              "facebook": "RepShusterman",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1851",
                "https://www.pahouse.com/shusterman",
                "https://www.palegis.us/house/members/bio/1851/rep-shusterman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-157-lower-ocd-person-2cb7688e-e8ea-431e-9f56-34ee9a9a3ecc": 0
        }
      },
      "158": {
        "members": [
          {
            "API_ID": "ocd-person/43d9c3c2-aeec-416c-859d-ad90600bbe0e",
            "firstName": "Christina D.",
            "lastName": "Sappey",
            "fullName": "Christina Sappey",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1852.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "158",
              "chamber": "lower",
              "hashID": "SLDL-PA-158-lower-ocd-person-43d9c3c2-aeec-416c-859d-ad90600bbe0e",
              "builtID": "pa-lower-158",
              "externalID": "ocd-person/43d9c3c2-aeec-416c-859d-ad90600bbe0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "repsappey@pahouse.com",
              "contactForm": null,
              "address1": null,
              "address2": "Willowdale Town Center 698 Unionville Road, Kennett Square, PA 19348",
              "phone1": null,
              "phone2": "484-200-8264",
              "fax1": null,
              "fax2": "484-200-8274",
              "twitter": "RepSappey",
              "youtube": "playlist?list=PLjtqNtfs0bgV3NyvY5Qd4uDKRvBW5uSLl",
              "instagram": null,
              "facebook": "RepSappey",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1852",
                "https://www.pahouse.com/sappey",
                "https://www.palegis.us/house/members/bio/1852/rep-sappey"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-158-lower-ocd-person-43d9c3c2-aeec-416c-859d-ad90600bbe0e": 0
        }
      },
      "159": {
        "members": [
          {
            "API_ID": "ocd-person/05f5a4fa-4a1e-4242-8664-eca508f36a3c",
            "firstName": "Carol",
            "lastName": "Kazeem",
            "fullName": "Carol Kazeem",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1976.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "159",
              "chamber": "lower",
              "hashID": "SLDL-PA-159-lower-ocd-person-05f5a4fa-4a1e-4242-8664-eca508f36a3c",
              "builtID": "pa-lower-159",
              "externalID": "ocd-person/05f5a4fa-4a1e-4242-8664-eca508f36a3c",
              "geometry": null
            },
            "contactInfo": {
              "email": "repkazeem@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "624 Avenue of the States, Chester, PA 19013",
              "phone1": null,
              "phone2": "610-876-6420",
              "fax1": null,
              "fax2": null,
              "twitter": "RepKazeem",
              "youtube": null,
              "instagram": null,
              "facebook": "RepKazeem",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1976",
                "https://www.pahouse.com/Kazeem/",
                "https://www.palegis.us/house/members/bio/1976/rep-kazeem"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-159-lower-ocd-person-05f5a4fa-4a1e-4242-8664-eca508f36a3c": 0
        }
      },
      "160": {
        "members": [
          {
            "API_ID": "ocd-person/e17c51da-556e-4afe-aad4-137ee3a0881e",
            "firstName": "Wendell Craig",
            "lastName": "Williams",
            "fullName": "Craig Williams",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1916.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "160",
              "chamber": "lower",
              "hashID": "SLDL-PA-160-lower-ocd-person-e17c51da-556e-4afe-aad4-137ee3a0881e",
              "builtID": "pa-lower-160",
              "externalID": "ocd-person/e17c51da-556e-4afe-aad4-137ee3a0881e",
              "geometry": null
            },
            "contactInfo": {
              "email": "craig.williams@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "1 Beaver Valley Road Suite 100, Chadds Ford, PA 19317",
              "phone1": null,
              "phone2": "610-358-5925",
              "fax1": null,
              "fax2": "610-358-5933",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "PA-State-Rep-Craig-Williams-100998925196205",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1916",
                "https://www.repcraigwilliams.com/",
                "https://www.palegis.us/house/members/bio/1916/rep-c-williams"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-160-lower-ocd-person-e17c51da-556e-4afe-aad4-137ee3a0881e": 0
        }
      },
      "161": {
        "members": [
          {
            "API_ID": "ocd-person/8187ecb2-3e4b-4ba0-819e-a7cfb4b9ac93",
            "firstName": "Leanne T.",
            "lastName": "Krueger",
            "fullName": "Leanne Krueger",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1735.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "161",
              "chamber": "lower",
              "hashID": "SLDL-PA-161-lower-ocd-person-8187ecb2-3e4b-4ba0-819e-a7cfb4b9ac93",
              "builtID": "pa-lower-161",
              "externalID": "ocd-person/8187ecb2-3e4b-4ba0-819e-a7cfb4b9ac93",
              "geometry": null
            },
            "contactInfo": {
              "email": "repkrueger@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "115 E. Brookhaven Road, Brookhaven, PA 19015",
              "phone1": null,
              "phone2": "610-534-6880",
              "fax1": null,
              "fax2": null,
              "twitter": "RepLeanne",
              "youtube": "watch?v=7sQ9tqMo_3I&list=PLjtqNtfs0bgXePTgy_OAmbAVHgnIR5qWd",
              "instagram": null,
              "facebook": "RepLeanne",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1735",
                "https://www.pahouse.com/Krueger/",
                "https://www.palegis.us/house/members/bio/1735/rep-krueger"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-161-lower-ocd-person-8187ecb2-3e4b-4ba0-819e-a7cfb4b9ac93": 0
        }
      },
      "162": {
        "members": [
          {
            "API_ID": "ocd-person/c05046a6-87dd-4956-a172-cad035960391",
            "firstName": "David M.",
            "lastName": "Delloso",
            "fullName": "Dave Delloso",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1853.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "162",
              "chamber": "lower",
              "hashID": "SLDL-PA-162-lower-ocd-person-c05046a6-87dd-4956-a172-cad035960391",
              "builtID": "pa-lower-162",
              "externalID": "ocd-person/c05046a6-87dd-4956-a172-cad035960391",
              "geometry": null
            },
            "contactInfo": {
              "email": "repdelloso@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "605 E. Chester Pike, Ridley Park, PA 19078",
              "phone1": null,
              "phone2": "610-534-1002",
              "fax1": null,
              "fax2": "484-200-8266",
              "twitter": "RepDelloso",
              "youtube": "playlist?list=PLjtqNtfs0bgVG_fUOnUUk1MHAO05yPmoU",
              "instagram": null,
              "facebook": "RepDelloso",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1853",
                "https://www.pahouse.com/delloso",
                "https://www.palegis.us/house/members/bio/1853/rep-delloso"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-162-lower-ocd-person-c05046a6-87dd-4956-a172-cad035960391": 0
        }
      },
      "163": {
        "members": [
          {
            "API_ID": "ocd-person/d5f1bbaf-5daf-4266-9b18-16042f49db1b",
            "firstName": "Heather L.",
            "lastName": "Boyd",
            "fullName": "Heather Boyd",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/2015.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "163",
              "chamber": "lower",
              "hashID": "SLDL-PA-163-lower-ocd-person-d5f1bbaf-5daf-4266-9b18-16042f49db1b",
              "builtID": "pa-lower-163",
              "externalID": "ocd-person/d5f1bbaf-5daf-4266-9b18-16042f49db1b",
              "geometry": null
            },
            "contactInfo": {
              "email": "hboyd@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "6 S. Springfield Road Unit A, Clifton Heights, PA 19018",
              "phone1": null,
              "phone2": "484-200-1563",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=2015",
                "https://www.pahouse.com/boyd/",
                "https://www.palegis.us/house/members/bio/2015/rep-boyd"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-163-lower-ocd-person-d5f1bbaf-5daf-4266-9b18-16042f49db1b": 0
        }
      },
      "164": {
        "members": [
          {
            "API_ID": "ocd-person/4c88ce64-4ba6-4f5e-a22f-86bf6a0d5b7f",
            "firstName": "Gina Hackett",
            "lastName": "Curry",
            "fullName": "Gina Curry",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1932.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "164",
              "chamber": "lower",
              "hashID": "SLDL-PA-164-lower-ocd-person-4c88ce64-4ba6-4f5e-a22f-86bf6a0d5b7f",
              "builtID": "pa-lower-164",
              "externalID": "ocd-person/4c88ce64-4ba6-4f5e-a22f-86bf6a0d5b7f",
              "geometry": null
            },
            "contactInfo": {
              "email": "repcurry@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "Barclay Square Shopping Center 1500 Garrett Road, Upper Darby, PA 19082",
              "phone1": null,
              "phone2": "610-259-7016",
              "fax1": null,
              "fax2": "610-259-5575",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1932",
                "https://www.pahouse.com/Curry/",
                "https://www.palegis.us/house/members/bio/1932/rep-curry"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-164-lower-ocd-person-4c88ce64-4ba6-4f5e-a22f-86bf6a0d5b7f": 0
        }
      },
      "165": {
        "members": [
          {
            "API_ID": "ocd-person/7c8e87fe-1c8e-4736-a03c-35cf729b80e9",
            "firstName": "Jennifer",
            "lastName": "O'Mara",
            "fullName": "Jenn O'Mara",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1855.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "165",
              "chamber": "lower",
              "hashID": "SLDL-PA-165-lower-ocd-person-7c8e87fe-1c8e-4736-a03c-35cf729b80e9",
              "builtID": "pa-lower-165",
              "externalID": "ocd-person/7c8e87fe-1c8e-4736-a03c-35cf729b80e9",
              "geometry": null
            },
            "contactInfo": {
              "email": "repomara@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "905 W. Sproul Road Suite 203, Springfield, PA 19064",
              "phone1": null,
              "phone2": "610-544-9878",
              "fax1": null,
              "fax2": "484-200-8270",
              "twitter": "RepOMara",
              "youtube": "playlist?list=PLjtqNtfs0bgXg2DAZxC52J8-enyFJd-5I",
              "instagram": null,
              "facebook": "RepOMara",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1855",
                "https://www.pahouse.com/omara",
                "https://www.palegis.us/house/members/bio/1855/rep-o'mara"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-165-lower-ocd-person-7c8e87fe-1c8e-4736-a03c-35cf729b80e9": 0
        }
      },
      "166": {
        "members": [
          {
            "API_ID": "ocd-person/7a1f510f-12e0-4018-b6da-556b404399be",
            "firstName": "Gregory S.",
            "lastName": "Vitali",
            "fullName": "Greg Vitali",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/210.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "166",
              "chamber": "lower",
              "hashID": "SLDL-PA-166-lower-ocd-person-7a1f510f-12e0-4018-b6da-556b404399be",
              "builtID": "pa-lower-166",
              "externalID": "ocd-person/7a1f510f-12e0-4018-b6da-556b404399be",
              "geometry": null
            },
            "contactInfo": {
              "email": "gvitali@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "1001 E. Darby Road, Havertown, PA 19083",
              "phone1": null,
              "phone2": "610-789-3900",
              "fax1": null,
              "fax2": "215-560-4197",
              "twitter": "GregVitali",
              "youtube": "playlist?list=PL77EFAB7F8763B560",
              "instagram": null,
              "facebook": "RepVitali",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=210",
                "https://www.pahouse.com/vitali",
                "https://www.palegis.us/house/members/bio/210/rep-vitali"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-166-lower-ocd-person-7a1f510f-12e0-4018-b6da-556b404399be": 0
        }
      },
      "167": {
        "members": [
          {
            "API_ID": "ocd-person/f451e90b-e93a-4768-a294-67d1ff000396",
            "firstName": "Kristine C.",
            "lastName": "Howard",
            "fullName": "Kristine Howard",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1856.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "167",
              "chamber": "lower",
              "hashID": "SLDL-PA-167-lower-ocd-person-f451e90b-e93a-4768-a294-67d1ff000396",
              "builtID": "pa-lower-167",
              "externalID": "ocd-person/f451e90b-e93a-4768-a294-67d1ff000396",
              "geometry": null
            },
            "contactInfo": {
              "email": "khoward@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "Greentree Office Building 40 Lloyd Ave., Suite 309, Malvern, PA 19355",
              "phone1": null,
              "phone2": "610-251-1070",
              "fax1": null,
              "fax2": "484-200-8271",
              "twitter": "RepKHoward",
              "youtube": "playlist?list=PLjtqNtfs0bgXdN3e9Z20k1NPzYCHqrUhX",
              "instagram": null,
              "facebook": "RepKHoward",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1856",
                "https://www.pahouse.com/howard",
                "https://www.palegis.us/house/members/bio/1856/rep-howard"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-167-lower-ocd-person-f451e90b-e93a-4768-a294-67d1ff000396": 0
        }
      },
      "168": {
        "members": [
          {
            "API_ID": "ocd-person/d927a6f9-9878-4375-ac7d-9996e8968c9d",
            "firstName": "Lisa Cianciulli",
            "lastName": "Borowski",
            "fullName": "Lisa Borowski",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1977.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "168",
              "chamber": "lower",
              "hashID": "SLDL-PA-168-lower-ocd-person-d927a6f9-9878-4375-ac7d-9996e8968c9d",
              "builtID": "pa-lower-168",
              "externalID": "ocd-person/d927a6f9-9878-4375-ac7d-9996e8968c9d",
              "geometry": null
            },
            "contactInfo": {
              "email": "repborowski@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "24 S. Newtown St. Road Suite 100, Newtown Square, PA 19073",
              "phone1": null,
              "phone2": "484-427-2884",
              "fax1": null,
              "fax2": "484-427-2530",
              "twitter": "RepBorowski",
              "youtube": null,
              "instagram": null,
              "facebook": "RepBorowski",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1977",
                "https://www.pahouse.com/borowski/",
                "https://www.palegis.us/house/members/bio/1977/rep-borowski"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-168-lower-ocd-person-d927a6f9-9878-4375-ac7d-9996e8968c9d": 0
        }
      },
      "169": {
        "members": [
          {
            "API_ID": "ocd-person/08283fbe-18bc-4daf-972c-617d1e35d746",
            "firstName": "Kate Anne",
            "lastName": "Klunk",
            "fullName": "Kate Klunk",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1694.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "169",
              "chamber": "lower",
              "hashID": "SLDL-PA-169-lower-ocd-person-08283fbe-18bc-4daf-972c-617d1e35d746",
              "builtID": "pa-lower-169",
              "externalID": "ocd-person/08283fbe-18bc-4daf-972c-617d1e35d746",
              "geometry": null
            },
            "contactInfo": {
              "email": "kklunk@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "118 Carlisle St. Suite 300, Hanover, PA 17331",
              "phone1": null,
              "phone2": "717-630-8942",
              "fax1": null,
              "fax2": "717-630-9731",
              "twitter": null,
              "youtube": "RepKlunk",
              "instagram": null,
              "facebook": "repklunk",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1694",
                "https://www.repklunk.com/",
                "https://www.palegis.us/house/members/bio/1694/rep-klunk"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-169-lower-ocd-person-08283fbe-18bc-4daf-972c-617d1e35d746": 0
        }
      },
      "170": {
        "members": [
          {
            "API_ID": "ocd-person/93435394-e853-45f2-9e88-65399b3f9042",
            "firstName": "Martina A.",
            "lastName": "White",
            "fullName": "Martina White",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1732.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "170",
              "chamber": "lower",
              "hashID": "SLDL-PA-170-lower-ocd-person-93435394-e853-45f2-9e88-65399b3f9042",
              "builtID": "pa-lower-170",
              "externalID": "ocd-person/93435394-e853-45f2-9e88-65399b3f9042",
              "geometry": null
            },
            "contactInfo": {
              "email": "mwhite@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "2901 Southampton Road, Philadelphia, PA 19154",
              "phone1": null,
              "phone2": "215-330-3711",
              "fax1": null,
              "fax2": "215-330-3709",
              "twitter": null,
              "youtube": "UCUOL82HXazpV73JY-PxuofQ",
              "instagram": null,
              "facebook": "repmartinawhite",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1732",
                "https://www.repwhite.com/",
                "https://www.palegis.us/house/members/bio/1732/rep-white"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-170-lower-ocd-person-93435394-e853-45f2-9e88-65399b3f9042": 0
        }
      },
      "171": {
        "members": [
          {
            "API_ID": "ocd-person/5a5a7a42-bc5e-4a07-aee1-d1a01e810385",
            "firstName": "Kerry Albert",
            "lastName": "Benninghoff",
            "fullName": "Kerry Benninghoff",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/215.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "171",
              "chamber": "lower",
              "hashID": "SLDL-PA-171-lower-ocd-person-5a5a7a42-bc5e-4a07-aee1-d1a01e810385",
              "builtID": "pa-lower-171",
              "externalID": "ocd-person/5a5a7a42-bc5e-4a07-aee1-d1a01e810385",
              "geometry": null
            },
            "contactInfo": {
              "email": "kbenning@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 592 77 N. Main St., Reedsville, PA 17084",
              "phone1": null,
              "phone2": "717-667-1175",
              "fax1": null,
              "fax2": "717-667-6025",
              "twitter": null,
              "youtube": "RepBenninghoff",
              "instagram": null,
              "facebook": "RepBenninghoff",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=215",
                "https://www.kerrybenninghoff.com/",
                "https://www.palegis.us/house/members/bio/215/rep-benninghoff"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-171-lower-ocd-person-5a5a7a42-bc5e-4a07-aee1-d1a01e810385": 0
        }
      },
      "172": {
        "members": [
          {
            "API_ID": "ocd-person/1acc497e-f643-4138-9bf5-58983cf03270",
            "firstName": "Kevin J.",
            "lastName": "Boyle",
            "fullName": "Kevin Boyle",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1225.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "172",
              "chamber": "lower",
              "hashID": "SLDL-PA-172-lower-ocd-person-1acc497e-f643-4138-9bf5-58983cf03270",
              "builtID": "pa-lower-172",
              "externalID": "ocd-person/1acc497e-f643-4138-9bf5-58983cf03270",
              "geometry": null
            },
            "contactInfo": {
              "email": "kboyle@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "7801-A Hasbrook Ave., Philadelphia, PA 19111",
              "phone1": null,
              "phone2": "215-331-2600",
              "fax1": null,
              "fax2": "215-695-1028",
              "twitter": "RepKevinBoyle",
              "youtube": "playlist?list=PLEDE88FE8CA4FDEC0",
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1225",
                "https://www.pahouse.com/kboyle",
                "https://www.palegis.us/house/members/bio/1225/rep-boyle"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-172-lower-ocd-person-1acc497e-f643-4138-9bf5-58983cf03270": 0
        }
      },
      "173": {
        "members": [
          {
            "API_ID": "ocd-person/36e7e815-87a3-4c58-baae-71f210e48114",
            "firstName": "Patrick L.",
            "lastName": "Gallagher",
            "fullName": "Pat Gallagher",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1978.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "173",
              "chamber": "lower",
              "hashID": "SLDL-PA-173-lower-ocd-person-36e7e815-87a3-4c58-baae-71f210e48114",
              "builtID": "pa-lower-173",
              "externalID": "ocd-person/36e7e815-87a3-4c58-baae-71f210e48114",
              "geometry": null
            },
            "contactInfo": {
              "email": "repgallagher@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "8760 Frankford Ave., Philadelphia, PA 19136",
              "phone1": null,
              "phone2": "215-281-3414",
              "fax1": null,
              "fax2": null,
              "twitter": "ReppGallagher",
              "youtube": null,
              "instagram": null,
              "facebook": "RepGallagher",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1978",
                "https://www.pahouse.com/Gallagher/",
                "https://www.palegis.us/house/members/bio/1978/rep-gallagher"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-173-lower-ocd-person-36e7e815-87a3-4c58-baae-71f210e48114": 0
        }
      },
      "174": {
        "members": [
          {
            "API_ID": "ocd-person/3d3d4070-dadd-4382-9d40-be2892745773",
            "firstName": "Edward James",
            "lastName": "Neilson",
            "fullName": "Ed Neilson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1615.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "174",
              "chamber": "lower",
              "hashID": "SLDL-PA-174-lower-ocd-person-3d3d4070-dadd-4382-9d40-be2892745773",
              "builtID": "pa-lower-174",
              "externalID": "ocd-person/3d3d4070-dadd-4382-9d40-be2892745773",
              "geometry": null
            },
            "contactInfo": {
              "email": "repneilson@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "16 Old Ashton Road, Philadelphia, PA 19152",
              "phone1": null,
              "phone2": "215-330-3714",
              "fax1": null,
              "fax2": "215-330-3717",
              "twitter": "RepNeilson",
              "youtube": "playlist?list=PLjtqNtfs0bgVZ7r6AcAd8hdSCnGWOcJHA",
              "instagram": null,
              "facebook": "RepNeilson",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1615",
                "https://www.pahouse.com/Neilson/",
                "https://www.palegis.us/house/members/bio/1615/rep-neilson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-174-lower-ocd-person-3d3d4070-dadd-4382-9d40-be2892745773": 0
        }
      },
      "175": {
        "members": [
          {
            "API_ID": "ocd-person/ac9d319c-4df3-489a-890e-f7daba8c4bda",
            "firstName": "MaryLouise",
            "lastName": "Isaacson",
            "fullName": "Mary Isaacson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1857.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "175",
              "chamber": "lower",
              "hashID": "SLDL-PA-175-lower-ocd-person-ac9d319c-4df3-489a-890e-f7daba8c4bda",
              "builtID": "pa-lower-175",
              "externalID": "ocd-person/ac9d319c-4df3-489a-890e-f7daba8c4bda",
              "geometry": null
            },
            "contactInfo": {
              "email": "misaacson@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "610 N. 2nd St., Philadelphia, PA 19123",
              "phone1": null,
              "phone2": "215-503-3245",
              "fax1": null,
              "fax2": "215-503-7850",
              "twitter": "RepMaryIsaacson",
              "youtube": "playlist?list=PLjtqNtfs0bgVb5i89inOtrRfz1Y1OEzcQ",
              "instagram": null,
              "facebook": "RepMaryIsaacson",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1857",
                "https://www.pahouse.com/isaacson",
                "https://www.palegis.us/house/members/bio/1857/rep-isaacson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-175-lower-ocd-person-ac9d319c-4df3-489a-890e-f7daba8c4bda": 0
        }
      },
      "176": {
        "members": [
          {
            "API_ID": "ocd-person/abca93cc-5058-473c-93bc-c8e099c7fa20",
            "firstName": "Jack B.",
            "lastName": "Rader",
            "fullName": "Jack Rader",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1703.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "176",
              "chamber": "lower",
              "hashID": "SLDL-PA-176-lower-ocd-person-abca93cc-5058-473c-93bc-c8e099c7fa20",
              "builtID": "pa-lower-176",
              "externalID": "ocd-person/abca93cc-5058-473c-93bc-c8e099c7fa20",
              "geometry": null
            },
            "contactInfo": {
              "email": "jrader@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "Carriage House Square 2785 Route 115, Suite 103, Effort, PA 18330",
              "phone1": null,
              "phone2": "570-620-4341",
              "fax1": null,
              "fax2": "570-620-4349",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "PA-State-Rep-Jack-Rader",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1703",
                "https://www.reprader.com/",
                "https://www.palegis.us/house/members/bio/1703/rep-rader"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-176-lower-ocd-person-abca93cc-5058-473c-93bc-c8e099c7fa20": 0
        }
      },
      "177": {
        "members": [
          {
            "API_ID": "ocd-person/99728978-f905-406c-8b52-f2912f374602",
            "firstName": "Joseph Cornelius",
            "lastName": "Hohenstein",
            "fullName": "Joe Hohenstein",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1858.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "177",
              "chamber": "lower",
              "hashID": "SLDL-PA-177-lower-ocd-person-99728978-f905-406c-8b52-f2912f374602",
              "builtID": "pa-lower-177",
              "externalID": "ocd-person/99728978-f905-406c-8b52-f2912f374602",
              "geometry": null
            },
            "contactInfo": {
              "email": "jhohenstein@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "7104 Torresdale Ave., Philadelphia, PA 19135",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepHohenstein",
              "youtube": "playlist?list=PLjtqNtfs0bgWV5fUZWfhnX6qiEWplNToK",
              "instagram": null,
              "facebook": "RepHohenstein",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1858",
                "https://www.pahouse.com/hohenstein",
                "https://www.palegis.us/house/members/bio/1858/rep-hohenstein"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-177-lower-ocd-person-99728978-f905-406c-8b52-f2912f374602": 0
        }
      },
      "178": {
        "members": [
          {
            "API_ID": "ocd-person/f1c8d8ab-0220-4b1e-96d0-6e0ae0b6df87",
            "firstName": "Kristin Schrader",
            "lastName": "Marcell",
            "fullName": "Kristin Marcell",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1979.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "178",
              "chamber": "lower",
              "hashID": "SLDL-PA-178-lower-ocd-person-f1c8d8ab-0220-4b1e-96d0-6e0ae0b6df87",
              "builtID": "pa-lower-178",
              "externalID": "ocd-person/f1c8d8ab-0220-4b1e-96d0-6e0ae0b6df87",
              "geometry": null
            },
            "contactInfo": {
              "email": "kmarcell@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "1038 2nd Street Pike, Suite 101, Richboro, PA 18954",
              "phone1": null,
              "phone2": "215-364-3414",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RepMarcell",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1979",
                "https://www.repmarcell.com/",
                "https://www.palegis.us/house/members/bio/1979/rep-marcell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-178-lower-ocd-person-f1c8d8ab-0220-4b1e-96d0-6e0ae0b6df87": 0
        }
      },
      "179": {
        "members": [
          {
            "API_ID": "ocd-person/4a2176cc-292b-4d19-aff6-8b4c4b460ba0",
            "firstName": "Jason",
            "lastName": "Dawkins",
            "fullName": "Jason Dawkins",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1685.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "179",
              "chamber": "lower",
              "hashID": "SLDL-PA-179-lower-ocd-person-4a2176cc-292b-4d19-aff6-8b4c4b460ba0",
              "builtID": "pa-lower-179",
              "externalID": "ocd-person/4a2176cc-292b-4d19-aff6-8b4c4b460ba0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jdawkins@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "4667 Paul St., Philadelphia, PA 19124",
              "phone1": null,
              "phone2": "215-744-7901",
              "fax1": null,
              "fax2": "215-744-7906",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1685",
                "https://www.pahouse.com/Dawkins/",
                "https://www.palegis.us/house/members/bio/1685/rep-dawkins"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-179-lower-ocd-person-4a2176cc-292b-4d19-aff6-8b4c4b460ba0": 0
        }
      },
      "180": {
        "members": [
          {
            "API_ID": "ocd-person/d390f1ac-1f16-478a-9e0d-462c9ed79818",
            "firstName": "Jose A.",
            "lastName": "Giral",
            "fullName": "José Giral",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1980.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "180",
              "chamber": "lower",
              "hashID": "SLDL-PA-180-lower-ocd-person-d390f1ac-1f16-478a-9e0d-462c9ed79818",
              "builtID": "pa-lower-180",
              "externalID": "ocd-person/d390f1ac-1f16-478a-9e0d-462c9ed79818",
              "geometry": null
            },
            "contactInfo": {
              "email": "repgiral@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "3503 B St. Unit 7, Philadelphia, PA 19134",
              "phone1": null,
              "phone2": "215-291-5643",
              "fax1": null,
              "fax2": null,
              "twitter": "repgiral",
              "youtube": null,
              "instagram": null,
              "facebook": "RepGiral",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1980",
                "https://www.pahouse.com/Giral/",
                "https://www.palegis.us/house/members/bio/1980/rep-giral"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-180-lower-ocd-person-d390f1ac-1f16-478a-9e0d-462c9ed79818": 0
        }
      },
      "181": {
        "members": [
          {
            "API_ID": "ocd-person/96711736-a47d-4bd6-bcc3-92297ec01516",
            "firstName": "Malcolm",
            "lastName": "Kenyatta",
            "fullName": "Malcolm Kenyatta",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1860.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "181",
              "chamber": "lower",
              "hashID": "SLDL-PA-181-lower-ocd-person-96711736-a47d-4bd6-bcc3-92297ec01516",
              "builtID": "pa-lower-181",
              "externalID": "ocd-person/96711736-a47d-4bd6-bcc3-92297ec01516",
              "geometry": null
            },
            "contactInfo": {
              "email": "mkenyatta@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "1501 N. Broad St. Suite 17, Philadelphia, PA 19122",
              "phone1": null,
              "phone2": "215-978-0311",
              "fax1": null,
              "fax2": "215-978-0316",
              "twitter": "RepKenyatta",
              "youtube": "playlist?list=PLjtqNtfs0bgWV5sfi05EqUSILVtdNkaLc",
              "instagram": null,
              "facebook": "RepKenyatta",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1860",
                "https://www.pahouse.com/kenyatta",
                "https://www.palegis.us/house/members/bio/1860/rep-kenyatta"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-181-lower-ocd-person-96711736-a47d-4bd6-bcc3-92297ec01516": 0
        }
      },
      "182": {
        "members": [
          {
            "API_ID": "ocd-person/1f2c3093-8ce7-41f7-8df0-6cd14ddd354b",
            "firstName": "Benjamin R.",
            "lastName": "Waxman",
            "fullName": "Ben Waxman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1981.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "182",
              "chamber": "lower",
              "hashID": "SLDL-PA-182-lower-ocd-person-1f2c3093-8ce7-41f7-8df0-6cd14ddd354b",
              "builtID": "pa-lower-182",
              "externalID": "ocd-person/1f2c3093-8ce7-41f7-8df0-6cd14ddd354b",
              "geometry": null
            },
            "contactInfo": {
              "email": "repwaxman@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "1500 Walnut St. 10th Floor, Philadelphia, PA 19102",
              "phone1": null,
              "phone2": "215-246-1501",
              "fax1": null,
              "fax2": null,
              "twitter": "RepBenWaxman",
              "youtube": null,
              "instagram": null,
              "facebook": "RepBenWaxman",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1981",
                "https://www.pahouse.com/Waxman/",
                "https://www.palegis.us/house/members/bio/1981/rep-waxman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-182-lower-ocd-person-1f2c3093-8ce7-41f7-8df0-6cd14ddd354b": 0
        }
      },
      "183": {
        "members": [
          {
            "API_ID": "ocd-person/c3b27985-614c-434d-b3c0-3e032fad4d6c",
            "firstName": "Zachary Allen",
            "lastName": "Mako",
            "fullName": "Zach Mako",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1758.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "183",
              "chamber": "lower",
              "hashID": "SLDL-PA-183-lower-ocd-person-c3b27985-614c-434d-b3c0-3e032fad4d6c",
              "builtID": "pa-lower-183",
              "externalID": "ocd-person/c3b27985-614c-434d-b3c0-3e032fad4d6c",
              "geometry": null
            },
            "contactInfo": {
              "email": "zmako@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "2030 Center St., Northampton, PA 18067",
              "phone1": null,
              "phone2": "610-502-2701",
              "fax1": null,
              "fax2": "610-502-2704",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "repmako",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1758",
                "https://www.repmako.com/",
                "https://www.palegis.us/house/members/bio/1758/rep-mako"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-183-lower-ocd-person-c3b27985-614c-434d-b3c0-3e032fad4d6c": 0
        }
      },
      "184": {
        "members": [
          {
            "API_ID": "ocd-person/90d95881-f6ae-4b29-af31-31a8821b8a7c",
            "firstName": "Elizabeth",
            "lastName": "Fiedler",
            "fullName": "Elizabeth Fiedler",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1861.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "184",
              "chamber": "lower",
              "hashID": "SLDL-PA-184-lower-ocd-person-90d95881-f6ae-4b29-af31-31a8821b8a7c",
              "builtID": "pa-lower-184",
              "externalID": "ocd-person/90d95881-f6ae-4b29-af31-31a8821b8a7c",
              "geometry": null
            },
            "contactInfo": {
              "email": "repfiedler@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "2400 S. 9th St., Philadelphia, PA 19148",
              "phone1": null,
              "phone2": "215-271-9190",
              "fax1": null,
              "fax2": "215-952-1025",
              "twitter": "RepFiedler",
              "youtube": "playlist?list=PLjtqNtfs0bgW1m-Wwj6HuiHHhXuQgyDVx",
              "instagram": null,
              "facebook": "RepFiedler",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1861",
                "https://www.pahouse.com/fiedler",
                "https://www.palegis.us/house/members/bio/1861/rep-fiedler"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-184-lower-ocd-person-90d95881-f6ae-4b29-af31-31a8821b8a7c": 0
        }
      },
      "185": {
        "members": [
          {
            "API_ID": "ocd-person/a737f4d4-5b45-469b-8d51-98c7aa78183a",
            "firstName": "Regina G.",
            "lastName": "Young",
            "fullName": "Regina Young",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1917.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "185",
              "chamber": "lower",
              "hashID": "SLDL-PA-185-lower-ocd-person-a737f4d4-5b45-469b-8d51-98c7aa78183a",
              "builtID": "pa-lower-185",
              "externalID": "ocd-person/a737f4d4-5b45-469b-8d51-98c7aa78183a",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryoung@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "900 Sharon Ave., Sharon Hill, PA 19079",
              "phone1": null,
              "phone2": "610-522-2290",
              "fax1": null,
              "fax2": null,
              "twitter": "RepReginaYoung",
              "youtube": null,
              "instagram": null,
              "facebook": "RepReginaYoung",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1917",
                "https://www.pahouse.com/Young/",
                "https://www.palegis.us/house/members/bio/1917/rep-young"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-185-lower-ocd-person-a737f4d4-5b45-469b-8d51-98c7aa78183a": 0
        }
      },
      "186": {
        "members": [
          {
            "API_ID": "ocd-person/2313ce98-7ac4-44b9-a61d-5b128338a405",
            "firstName": "Jordan A.",
            "lastName": "Harris",
            "fullName": "Jordan Harris",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1633.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "186",
              "chamber": "lower",
              "hashID": "SLDL-PA-186-lower-ocd-person-2313ce98-7ac4-44b9-a61d-5b128338a405",
              "builtID": "pa-lower-186",
              "externalID": "ocd-person/2313ce98-7ac4-44b9-a61d-5b128338a405",
              "geometry": null
            },
            "contactInfo": {
              "email": "repharris@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "5515 Chester Ave., Chester, PA 19143",
              "phone1": null,
              "phone2": "215-755-9185",
              "fax1": null,
              "fax2": null,
              "twitter": "RepHarris",
              "youtube": "playlist?list=PLjtqNtfs0bgXlZde0v6mYg9x10OHBiZmp",
              "instagram": null,
              "facebook": "RepJordanHarris",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1633",
                "https://www.pahouse.com/Harris/",
                "https://www.palegis.us/house/members/bio/1633/rep-harris"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-186-lower-ocd-person-2313ce98-7ac4-44b9-a61d-5b128338a405": 0
        }
      },
      "187": {
        "members": [
          {
            "API_ID": "ocd-person/7c9d9526-f375-446d-afd5-e2601c585f9a",
            "firstName": "Ryan Edward",
            "lastName": "Mackenzie",
            "fullName": "Ryan Mackenzie",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1614.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "187",
              "chamber": "lower",
              "hashID": "SLDL-PA-187-lower-ocd-person-7c9d9526-f375-446d-afd5-e2601c585f9a",
              "builtID": "pa-lower-187",
              "externalID": "ocd-person/7c9d9526-f375-446d-afd5-e2601c585f9a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rmackenzie@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "6806 Route 309, Suite 200A, New Tripoli, PA 18066",
              "phone1": null,
              "phone2": "610-760-7082",
              "fax1": null,
              "fax2": null,
              "twitter": "RepMackenzie",
              "youtube": "repmackenzie",
              "instagram": null,
              "facebook": "RepMackenzie",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1614",
                "https://www.repmackenzie.com/",
                "https://www.palegis.us/house/members/bio/1614/rep-r-mackenzie"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-187-lower-ocd-person-7c9d9526-f375-446d-afd5-e2601c585f9a": 0
        }
      },
      "188": {
        "members": [
          {
            "API_ID": "ocd-person/42ffbed1-2284-4018-a60f-b5bfa3f35833",
            "firstName": "Rick Chester",
            "lastName": "Krajewski",
            "fullName": "Rick Krajewski",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1918.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "188",
              "chamber": "lower",
              "hashID": "SLDL-PA-188-lower-ocd-person-42ffbed1-2284-4018-a60f-b5bfa3f35833",
              "builtID": "pa-lower-188",
              "externalID": "ocd-person/42ffbed1-2284-4018-a60f-b5bfa3f35833",
              "geometry": null
            },
            "contactInfo": {
              "email": "repkrajewski@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "4712 Baltimore Ave., Philadelphia, PA 19143",
              "phone1": null,
              "phone2": "215-724-2227",
              "fax1": null,
              "fax2": null,
              "twitter": "RepKrajewski",
              "youtube": null,
              "instagram": null,
              "facebook": "RepKrajewski",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1918",
                "https://www.pahouse.com/krajewski/",
                "https://www.palegis.us/house/members/bio/1918/rep-krajewski"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-188-lower-ocd-person-42ffbed1-2284-4018-a60f-b5bfa3f35833": 0
        }
      },
      "189": {
        "members": [
          {
            "API_ID": "ocd-person/8caab7df-f601-45f0-9be5-35d94175e766",
            "firstName": "Tarah Dorothea",
            "lastName": "Probst",
            "fullName": "Tarah Probst",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1982.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "189",
              "chamber": "lower",
              "hashID": "SLDL-PA-189-lower-ocd-person-8caab7df-f601-45f0-9be5-35d94175e766",
              "builtID": "pa-lower-189",
              "externalID": "ocd-person/8caab7df-f601-45f0-9be5-35d94175e766",
              "geometry": null
            },
            "contactInfo": {
              "email": "repprobst@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "193 Municipal Drive, Bushkill, PA 18324",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepProbst",
              "youtube": null,
              "instagram": null,
              "facebook": "RepProbst",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1982",
                "https://www.pahouse.com/Probst/",
                "https://www.palegis.us/house/members/bio/1982/rep-probst"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-189-lower-ocd-person-8caab7df-f601-45f0-9be5-35d94175e766": 0
        }
      },
      "190": {
        "members": [
          {
            "API_ID": "ocd-person/c3ad9b17-c8f5-450e-9aaa-f002e1414bd5",
            "firstName": "Gwendolyn Veronica",
            "lastName": "Green",
            "fullName": "Roni Green",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1874.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "190",
              "chamber": "lower",
              "hashID": "SLDL-PA-190-lower-ocd-person-c3ad9b17-c8f5-450e-9aaa-f002e1414bd5",
              "builtID": "pa-lower-190",
              "externalID": "ocd-person/c3ad9b17-c8f5-450e-9aaa-f002e1414bd5",
              "geometry": null
            },
            "contactInfo": {
              "email": "repgreen@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "3333 W. Hunting Park Ave., Philadelphia, PA 19132",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRoniGreen",
              "youtube": null,
              "instagram": null,
              "facebook": "RepRoniGreen",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1874",
                "https://www.pahouse.com/Green",
                "https://www.palegis.us/house/members/bio/1874/rep-green"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-190-lower-ocd-person-c3ad9b17-c8f5-450e-9aaa-f002e1414bd5": 0
        }
      },
      "191": {
        "members": [
          {
            "API_ID": "ocd-person/1951ae06-881a-4c43-9635-224878c3ecb5",
            "firstName": "Joanna E.",
            "lastName": "McClinton",
            "fullName": "Joanna McClinton",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1734.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "191",
              "chamber": "lower",
              "hashID": "SLDL-PA-191-lower-ocd-person-1951ae06-881a-4c43-9635-224878c3ecb5",
              "builtID": "pa-lower-191",
              "externalID": "ocd-person/1951ae06-881a-4c43-9635-224878c3ecb5",
              "geometry": null
            },
            "contactInfo": {
              "email": "repjoanna191@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": "149 S. 60th St., Philadelphia, PA 19139",
              "phone1": null,
              "phone2": "215-748-6712",
              "fax1": null,
              "fax2": "215-748-1687",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1734",
                "https://www.pahouse.com/McClinton/",
                "https://www.palegis.us/house/members/bio/1734/rep-mcclinton"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-191-lower-ocd-person-1951ae06-881a-4c43-9635-224878c3ecb5": 0
        }
      },
      "192": {
        "members": [
          {
            "API_ID": "ocd-person/05326e52-202a-46cd-bbbe-cc8d01d9a44b",
            "firstName": "Morgan B.",
            "lastName": "Cephas",
            "fullName": "Morgan Cephas",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1759.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "192",
              "chamber": "lower",
              "hashID": "SLDL-PA-192-lower-ocd-person-05326e52-202a-46cd-bbbe-cc8d01d9a44b",
              "builtID": "pa-lower-192",
              "externalID": "ocd-person/05326e52-202a-46cd-bbbe-cc8d01d9a44b",
              "geometry": null
            },
            "contactInfo": {
              "email": "mcephas@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "7538 Haverford Ave., Philadelphia, PA 19151",
              "phone1": null,
              "phone2": "215-879-6625",
              "fax1": null,
              "fax2": "215-879-8566",
              "twitter": "RepCephas",
              "youtube": null,
              "instagram": null,
              "facebook": "RepCephas",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1759",
                "https://www.pahouse.com/Cephas/",
                "https://www.palegis.us/house/members/bio/1759/rep-cephas"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-192-lower-ocd-person-05326e52-202a-46cd-bbbe-cc8d01d9a44b": 0
        }
      },
      "193": {
        "members": [
          {
            "API_ID": "ocd-person/6f375652-451b-4c39-9b29-6ba5a07073c6",
            "firstName": "Torren C.",
            "lastName": "Ecker",
            "fullName": "Torren Ecker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1862.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "193",
              "chamber": "lower",
              "hashID": "SLDL-PA-193-lower-ocd-person-6f375652-451b-4c39-9b29-6ba5a07073c6",
              "builtID": "pa-lower-193",
              "externalID": "ocd-person/6f375652-451b-4c39-9b29-6ba5a07073c6",
              "geometry": null
            },
            "contactInfo": {
              "email": "tecker@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "68 W. King St., Shippensburg, PA 17257",
              "phone1": null,
              "phone2": "717-477-0905",
              "fax1": null,
              "fax2": null,
              "twitter": "eckerrep",
              "youtube": "UC71AIhiyA0MJ0x6ScnYvJQg",
              "instagram": null,
              "facebook": "RepTorrenEcker",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1862",
                "https://www.repecker.com/",
                "https://www.palegis.us/house/members/bio/1862/rep-ecker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-193-lower-ocd-person-6f375652-451b-4c39-9b29-6ba5a07073c6": 0
        }
      },
      "194": {
        "members": [
          {
            "API_ID": "ocd-person/6fa30029-d933-4f6d-b0fb-343a0c82772c",
            "firstName": "Tarik S.",
            "lastName": "Khan",
            "fullName": "Tarik Khan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1983.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "194",
              "chamber": "lower",
              "hashID": "SLDL-PA-194-lower-ocd-person-6fa30029-d933-4f6d-b0fb-343a0c82772c",
              "builtID": "pa-lower-194",
              "externalID": "ocd-person/6fa30029-d933-4f6d-b0fb-343a0c82772c",
              "geometry": null
            },
            "contactInfo": {
              "email": "reptarik@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "4001 Main St. Suite 101, Philadelphia, PA 19127",
              "phone1": null,
              "phone2": "215-482-8726",
              "fax1": null,
              "fax2": null,
              "twitter": "RepTarik",
              "youtube": null,
              "instagram": null,
              "facebook": "RepTarik",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1983",
                "https://www.pahouse.com/Tarik/",
                "https://www.palegis.us/house/members/bio/1983/rep-khan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-194-lower-ocd-person-6fa30029-d933-4f6d-b0fb-343a0c82772c": 0
        }
      },
      "196": {
        "members": [
          {
            "API_ID": "ocd-person/f10a1cb3-df7f-432c-8d33-e6d3293b7f88",
            "firstName": "Seth Michael",
            "lastName": "Grove",
            "fullName": "Seth Grove",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1171.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "196",
              "chamber": "lower",
              "hashID": "SLDL-PA-196-lower-ocd-person-f10a1cb3-df7f-432c-8d33-e6d3293b7f88",
              "builtID": "pa-lower-196",
              "externalID": "ocd-person/f10a1cb3-df7f-432c-8d33-e6d3293b7f88",
              "geometry": null
            },
            "contactInfo": {
              "email": "sgrove@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "2501 Catherine St. Suite 10, York, PA 17408",
              "phone1": null,
              "phone2": "717-767-3947",
              "fax1": null,
              "fax2": "717-767-9857",
              "twitter": "RepGrove",
              "youtube": "RepGrove",
              "instagram": null,
              "facebook": "RepSethGrove",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1171",
                "https://www.repgrove.com/",
                "https://www.palegis.us/house/members/bio/1171/rep-grove"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-196-lower-ocd-person-f10a1cb3-df7f-432c-8d33-e6d3293b7f88": 0
        }
      },
      "197": {
        "members": [
          {
            "API_ID": "ocd-person/a2579707-5a31-4f4b-a9ed-49847d35eae6",
            "firstName": "Danilo",
            "lastName": "Burgos",
            "fullName": "Danilo Burgos",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1863.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "197",
              "chamber": "lower",
              "hashID": "SLDL-PA-197-lower-ocd-person-a2579707-5a31-4f4b-a9ed-49847d35eae6",
              "builtID": "pa-lower-197",
              "externalID": "ocd-person/a2579707-5a31-4f4b-a9ed-49847d35eae6",
              "geometry": null
            },
            "contactInfo": {
              "email": "dburgos@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "635 W. Erie Ave., Philadelphia, PA 19140",
              "phone1": null,
              "phone2": "215-223-1890",
              "fax1": null,
              "fax2": "215-223-1959",
              "twitter": "RepBurgos",
              "youtube": "playlist?list=PLjtqNtfs0bgXt_x4JyRcCrfs9KrY14amC",
              "instagram": null,
              "facebook": "RepBurgos",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1863",
                "https://www.pahouse.com/burgos",
                "https://www.palegis.us/house/members/bio/1863/rep-burgos"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-197-lower-ocd-person-a2579707-5a31-4f4b-a9ed-49847d35eae6": 0
        }
      },
      "198": {
        "members": [
          {
            "API_ID": "ocd-person/943fdd89-fe0a-43f6-8077-82cd252bdeb2",
            "firstName": "Darisha K.",
            "lastName": "Parker",
            "fullName": "Darisha Parker",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1920.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "198",
              "chamber": "lower",
              "hashID": "SLDL-PA-198-lower-ocd-person-943fdd89-fe0a-43f6-8077-82cd252bdeb2",
              "builtID": "pa-lower-198",
              "externalID": "ocd-person/943fdd89-fe0a-43f6-8077-82cd252bdeb2",
              "geometry": null
            },
            "contactInfo": {
              "email": "repparker@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "1335 W. Tabor Road Suite 109, Philadelphia, PA 19141",
              "phone1": null,
              "phone2": "215-849-6426",
              "fax1": null,
              "fax2": "717-772-1313",
              "twitter": "RepDarisha",
              "youtube": null,
              "instagram": null,
              "facebook": "RepDarishaParker",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1920",
                "https://www.pahouse.com/dparker/",
                "https://www.palegis.us/house/members/bio/1920/rep-parker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-198-lower-ocd-person-943fdd89-fe0a-43f6-8077-82cd252bdeb2": 0
        }
      },
      "199": {
        "members": [
          {
            "API_ID": "ocd-person/48c5b966-eef2-456a-8ca0-f47d584e7883",
            "firstName": "Barbara",
            "lastName": "Gleim",
            "fullName": "Barb Gleim",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1864.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "199",
              "chamber": "lower",
              "hashID": "SLDL-PA-199-lower-ocd-person-48c5b966-eef2-456a-8ca0-f47d584e7883",
              "builtID": "pa-lower-199",
              "externalID": "ocd-person/48c5b966-eef2-456a-8ca0-f47d584e7883",
              "geometry": null
            },
            "contactInfo": {
              "email": "bgleim@pahousegop.com",
              "contactForm": null,
              "address1": null,
              "address2": "437 E. North St., Carlisle, PA 17013",
              "phone1": null,
              "phone2": "717-249-1990",
              "fax1": null,
              "fax2": "717-249-8775",
              "twitter": "repgleim",
              "youtube": "UChtuvXCwuGvDP_EJAf8ZU5w",
              "instagram": null,
              "facebook": "RepGleim",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1864",
                "https://www.repgleim.com/",
                "https://www.palegis.us/house/members/bio/1864/rep-gleim"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-199-lower-ocd-person-48c5b966-eef2-456a-8ca0-f47d584e7883": 0
        }
      },
      "200": {
        "members": [
          {
            "API_ID": "ocd-person/e6a8e555-0d65-4a1f-9673-56ad1e3c7855",
            "firstName": "Christopher M.",
            "lastName": "Rabb",
            "fullName": "Chris Rabb",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1760.jpg?1703415645672g",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "200",
              "chamber": "lower",
              "hashID": "SLDL-PA-200-lower-ocd-person-e6a8e555-0d65-4a1f-9673-56ad1e3c7855",
              "builtID": "pa-lower-200",
              "externalID": "ocd-person/e6a8e555-0d65-4a1f-9673-56ad1e3c7855",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris@chrisrabb.com",
              "contactForm": null,
              "address1": null,
              "address2": "7216 Germantown Ave., Philadelphia, PA 19119",
              "phone1": null,
              "phone2": "215-242-7300",
              "fax1": null,
              "fax2": "215-242-7303",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1760",
                "https://www.pahouse.com/Rabb/",
                "https://www.palegis.us/house/members/bio/1760/rep-rabb"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-200-lower-ocd-person-e6a8e555-0d65-4a1f-9673-56ad1e3c7855": 0
        }
      },
      "202": {
        "members": [
          {
            "API_ID": "ocd-person/6b6602c0-8b45-4e1e-89ba-1782da5d67c6",
            "firstName": "Jared Garth",
            "lastName": "Solomon",
            "fullName": "Jared Solomon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1761.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "202",
              "chamber": "lower",
              "hashID": "SLDL-PA-202-lower-ocd-person-6b6602c0-8b45-4e1e-89ba-1782da5d67c6",
              "builtID": "pa-lower-202",
              "externalID": "ocd-person/6b6602c0-8b45-4e1e-89ba-1782da5d67c6",
              "geometry": null
            },
            "contactInfo": {
              "email": "jsolomon@pahouse.net",
              "contactForm": null,
              "address1": null,
              "address2": "7104 Frankford Ave., Philadelphia, PA 19135",
              "phone1": null,
              "phone2": "215-543-6755",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1761",
                "https://www.pahouse.com/Solomon/",
                "https://www.palegis.us/house/members/bio/1761/rep-solomon"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-202-lower-ocd-person-6b6602c0-8b45-4e1e-89ba-1782da5d67c6": 0
        }
      },
      "203": {
        "members": [
          {
            "API_ID": "ocd-person/57be936d-802b-4d09-b95e-2a5354cb9984",
            "firstName": "Anthony Andrew",
            "lastName": "Bellmon",
            "fullName": "Anthony Bellmon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1984.jpg?1703415645672",
            "title": "PA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "203",
              "chamber": "lower",
              "hashID": "SLDL-PA-203-lower-ocd-person-57be936d-802b-4d09-b95e-2a5354cb9984",
              "builtID": "pa-lower-203",
              "externalID": "ocd-person/57be936d-802b-4d09-b95e-2a5354cb9984",
              "geometry": null
            },
            "contactInfo": {
              "email": "repbellmon@pahouse.com",
              "contactForm": null,
              "address1": null,
              "address2": "7215-B Rising Sun Ave., Philadelphia, PA 19111",
              "phone1": null,
              "phone2": "215-560-1315",
              "fax1": null,
              "fax2": null,
              "twitter": "RepBellmon",
              "youtube": null,
              "instagram": null,
              "facebook": "RepBellmon",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1984",
                "https://www.pahouse.com/Bellmon/",
                "https://www.palegis.us/house/members/bio/1984/rep-bellmon"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-PA-203-lower-ocd-person-57be936d-802b-4d09-b95e-2a5354cb9984": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/6f172bc8-50b0-4dd3-aed6-b5fd48b70eeb",
            "firstName": "Nikil",
            "lastName": "Saval",
            "fullName": "Nikil Saval",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1921.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-PA-1-upper-ocd-person-6f172bc8-50b0-4dd3-aed6-b5fd48b70eeb",
              "builtID": "pa-upper-1",
              "externalID": "ocd-person/6f172bc8-50b0-4dd3-aed6-b5fd48b70eeb",
              "geometry": null
            },
            "contactInfo": {
              "email": "saval@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "1434 Germantown Ave., Philadelphia, PA 19122",
              "phone1": null,
              "phone2": "215-952-3119",
              "fax1": null,
              "fax2": null,
              "twitter": "senatorsaval",
              "youtube": null,
              "instagram": "senatorsaval",
              "facebook": "SenatorSaval",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1921",
                "https://www.pasenatorsaval.com/",
                "https://www.palegis.us/senate/members/bio/1921/sen-saval"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-1-upper-ocd-person-6f172bc8-50b0-4dd3-aed6-b5fd48b70eeb": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/f3b4fe8c-05f9-4610-81ef-354c0fcb0cdd",
            "firstName": "Christine M.",
            "lastName": "Tartaglione",
            "fullName": "Tina Tartaglione",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/277.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-PA-2-upper-ocd-person-f3b4fe8c-05f9-4610-81ef-354c0fcb0cdd",
              "builtID": "pa-upper-2",
              "externalID": "ocd-person/f3b4fe8c-05f9-4610-81ef-354c0fcb0cdd",
              "geometry": null
            },
            "contactInfo": {
              "email": "tartaglione@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "5321 Oxford Ave., Philadelphia, PA 19124",
              "phone1": null,
              "phone2": "215-533-0440",
              "fax1": null,
              "fax2": "215-560-2627",
              "twitter": "sentartaglione",
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorTinaTartaglione",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=277",
                "https://www.senatortartaglione.com/",
                "https://www.palegis.us/senate/members/bio/277/sen-tartaglione"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-2-upper-ocd-person-f3b4fe8c-05f9-4610-81ef-354c0fcb0cdd": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/b0d20b05-428a-4e3a-bda1-bbba5c3cfd6a",
            "firstName": "Sharif",
            "lastName": "Street",
            "fullName": "Sharif Street",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1767.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-PA-3-upper-ocd-person-b0d20b05-428a-4e3a-bda1-bbba5c3cfd6a",
              "builtID": "pa-upper-3",
              "externalID": "ocd-person/b0d20b05-428a-4e3a-bda1-bbba5c3cfd6a",
              "geometry": null
            },
            "contactInfo": {
              "email": "street@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "4458 A Germantown Ave., Philadelphia, PA 19140",
              "phone1": null,
              "phone2": "215-457-5200",
              "fax1": null,
              "fax2": "215-457-5206",
              "twitter": "SenSharifStreet",
              "youtube": null,
              "instagram": "SenSharifstreet",
              "facebook": "SenSharifStreet",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1767",
                "https://www.senatorsharifstreet.com/",
                "https://www.palegis.us/senate/members/bio/1767/sen-street"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-3-upper-ocd-person-b0d20b05-428a-4e3a-bda1-bbba5c3cfd6a": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/ea712eba-1da5-45e2-8d9f-022e8307a614",
            "firstName": "Arthur L.",
            "lastName": "Haywood",
            "fullName": "Art Haywood",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1689.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-PA-4-upper-ocd-person-ea712eba-1da5-45e2-8d9f-022e8307a614",
              "builtID": "pa-upper-4",
              "externalID": "ocd-person/ea712eba-1da5-45e2-8d9f-022e8307a614",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorhaywood@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "7106 Germantown Ave., Philadelphia, PA 19119",
              "phone1": null,
              "phone2": "215-242-8171",
              "fax1": null,
              "fax2": "215-242-6118",
              "twitter": "SenatorHaywood",
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorArtHaywood",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1689",
                "https://www.senatorhaywood.com/",
                "https://www.palegis.us/senate/members/bio/1689/sen-haywood"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-4-upper-ocd-person-ea712eba-1da5-45e2-8d9f-022e8307a614": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/617166b6-9d7a-46ff-aae2-b6d5f525aefa",
            "firstName": "James",
            "lastName": "Dillon",
            "fullName": "Jimmy Dillon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1936.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-PA-5-upper-ocd-person-617166b6-9d7a-46ff-aae2-b6d5f525aefa",
              "builtID": "pa-upper-5",
              "externalID": "ocd-person/617166b6-9d7a-46ff-aae2-b6d5f525aefa",
              "geometry": null
            },
            "contactInfo": {
              "email": "dillon@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "12361 Academy Road, Philadelphia, PA 19154",
              "phone1": null,
              "phone2": "215-281-2539",
              "fax1": null,
              "fax2": null,
              "twitter": "senjimmydillon",
              "youtube": null,
              "instagram": "senjimmydillon",
              "facebook": "SenJimmyDillon",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1936",
                "https://www.palegis.us/senate/members/bio/1936/sen-dillon",
                "https://senatordillon.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-5-upper-ocd-person-617166b6-9d7a-46ff-aae2-b6d5f525aefa": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/611d3c65-f30f-4dde-8f80-7db4ec405a36",
            "firstName": "Frank Anthony",
            "lastName": "Farry",
            "fullName": "Frank Farry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1169.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-PA-6-upper-ocd-person-611d3c65-f30f-4dde-8f80-7db4ec405a36",
              "builtID": "pa-upper-6",
              "externalID": "ocd-person/611d3c65-f30f-4dde-8f80-7db4ec405a36",
              "geometry": null
            },
            "contactInfo": {
              "email": "ffarry@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "370 E. Maple Ave. Suite 203, Langhorne, PA 19047",
              "phone1": null,
              "phone2": "215-638-1784",
              "fax1": null,
              "fax2": null,
              "twitter": "SenFrankFarry",
              "youtube": "RepFarry",
              "instagram": null,
              "facebook": "SenatorFarry",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1169",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1169",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1169",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senate_bio.cfm?id=1169",
                "https://senatorfarry.com/",
                "https://www.palegis.us/senate/members/bio/1169/sen-farry"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-6-upper-ocd-person-611d3c65-f30f-4dde-8f80-7db4ec405a36": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/53a598e0-3595-4b19-8f65-4a538bdc7a74",
            "firstName": "Vincent J.",
            "lastName": "Hughes",
            "fullName": "Vincent Hughes",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/152.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-PA-7-upper-ocd-person-53a598e0-3595-4b19-8f65-4a538bdc7a74",
              "builtID": "pa-upper-7",
              "externalID": "ocd-person/53a598e0-3595-4b19-8f65-4a538bdc7a74",
              "geometry": null
            },
            "contactInfo": {
              "email": "hughes@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "2401 N. 54th St., Philadelphia, PA 19131",
              "phone1": null,
              "phone2": "215-879-7777",
              "fax1": null,
              "fax2": "215-879-7778",
              "twitter": "SenatorHughes",
              "youtube": null,
              "instagram": "senhughesoffice",
              "facebook": "vincent.hughes.7",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=152",
                "https://www.senatorhughes.com/",
                "https://www.palegis.us/senate/members/bio/152/sen-hughes"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-7-upper-ocd-person-53a598e0-3595-4b19-8f65-4a538bdc7a74": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/fe79a0f0-7dfd-442a-8b79-f8792613a4de",
            "firstName": "Anthony Hardy",
            "lastName": "Williams",
            "fullName": "Tony Williams",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/153.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-PA-8-upper-ocd-person-fe79a0f0-7dfd-442a-8b79-f8792613a4de",
              "builtID": "pa-upper-8",
              "externalID": "ocd-person/fe79a0f0-7dfd-442a-8b79-f8792613a4de",
              "geometry": null
            },
            "contactInfo": {
              "email": "williams@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "2901 Island Ave., Suite 100, Philadelphia, PA 19153",
              "phone1": null,
              "phone2": "215-492-2980",
              "fax1": null,
              "fax2": "215-492-2990",
              "twitter": "SenTonyWilliams",
              "youtube": null,
              "instagram": "senator_anthonyhwilliams",
              "facebook": "anthony.h.williams",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=153",
                "https://www.senatoranthonyhwilliams.com/",
                "https://www.palegis.us/senate/members/bio/153/sen-a-williams"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-8-upper-ocd-person-fe79a0f0-7dfd-442a-8b79-f8792613a4de": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/c45052c1-f3e4-485e-a3d2-f346fa51839b",
            "firstName": "John Ignatius",
            "lastName": "Kane",
            "fullName": "John Kane",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1925.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-PA-9-upper-ocd-person-c45052c1-f3e4-485e-a3d2-f346fa51839b",
              "builtID": "pa-upper-9",
              "externalID": "ocd-person/c45052c1-f3e4-485e-a3d2-f346fa51839b",
              "geometry": null
            },
            "contactInfo": {
              "email": "kane@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "504 Avenue of the States 1st Floor, Chester, PA 19013",
              "phone1": null,
              "phone2": "610-447-5845",
              "fax1": null,
              "fax2": "610-447-3196",
              "twitter": "senatorjohnkane",
              "youtube": null,
              "instagram": "senatorjohnkane",
              "facebook": "SenatorJohnKane",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1925",
                "https://pasenatorkane.com/",
                "https://www.palegis.us/senate/members/bio/1925/sen-kane"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-9-upper-ocd-person-c45052c1-f3e4-485e-a3d2-f346fa51839b": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/91f290d0-3405-4091-bb16-822a97f45af5",
            "firstName": "Steven J.",
            "lastName": "Santarsiero",
            "fullName": "Steve Santarsiero",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1179.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-PA-10-upper-ocd-person-91f290d0-3405-4091-bb16-822a97f45af5",
              "builtID": "pa-upper-10",
              "externalID": "ocd-person/91f290d0-3405-4091-bb16-822a97f45af5",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorsantarsiero@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "Doylestown Borough Hall 10 Doyle St., Doylestown, PA 18901",
              "phone1": null,
              "phone2": "215-489-5000",
              "fax1": null,
              "fax2": null,
              "twitter": "SenSantarsiero",
              "youtube": null,
              "instagram": null,
              "facebook": "SenSantarsiero",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1179",
                "https://www.senatorstevesantarsiero.com/",
                "https://www.palegis.us/senate/members/bio/1179/sen-santarsiero"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-10-upper-ocd-person-91f290d0-3405-4091-bb16-822a97f45af5": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/35362d6e-d00a-4cf1-806d-b4f2f2a55f72",
            "firstName": "Judith L.",
            "lastName": "Schwank",
            "fullName": "Judy Schwank",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1234.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-PA-11-upper-ocd-person-35362d6e-d00a-4cf1-806d-b4f2f2a55f72",
              "builtID": "pa-upper-11",
              "externalID": "ocd-person/35362d6e-d00a-4cf1-806d-b4f2f2a55f72",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorschwank@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "210 George St. Suite 201, Reading, PA 19605",
              "phone1": null,
              "phone2": "610-929-2151",
              "fax1": null,
              "fax2": "610-929-2576",
              "twitter": "senjudyschwank",
              "youtube": null,
              "instagram": null,
              "facebook": "senatorjudyschwank",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1234",
                "https://www.senatorschwank.com/",
                "https://www.palegis.us/senate/members/bio/1234/sen-schwank"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-11-upper-ocd-person-35362d6e-d00a-4cf1-806d-b4f2f2a55f72": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/323c52e8-f841-4a0b-a49e-91103949fbc8",
            "firstName": "Maria Tzanakis",
            "lastName": "Collett",
            "fullName": "Maria Collett",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1799.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-PA-12-upper-ocd-person-323c52e8-f841-4a0b-a49e-91103949fbc8",
              "builtID": "pa-upper-12",
              "externalID": "ocd-person/323c52e8-f841-4a0b-a49e-91103949fbc8",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorcollett@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "1035 Virginia Drive Suite 201, Fort Washington, PA 19034",
              "phone1": null,
              "phone2": "215-368-1429",
              "fax1": null,
              "fax2": "215-368-2374",
              "twitter": "SenatorCollett",
              "youtube": null,
              "instagram": "senatorcollett",
              "facebook": "senatorcollett",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1799",
                "https://www.senatorcollett.com/",
                "https://www.palegis.us/senate/members/bio/1799/sen-collett"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-12-upper-ocd-person-323c52e8-f841-4a0b-a49e-91103949fbc8": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/c2fcd2dc-22f8-4ec2-b70a-439bc6d60802",
            "firstName": "Scott",
            "lastName": "Martin",
            "fullName": "Scott Martin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1763.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-PA-13-upper-ocd-person-c2fcd2dc-22f8-4ec2-b70a-439bc6d60802",
              "builtID": "pa-upper-13",
              "externalID": "ocd-person/c2fcd2dc-22f8-4ec2-b70a-439bc6d60802",
              "geometry": null
            },
            "contactInfo": {
              "email": "smartin@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "135 E. Main St. Suite 1-A, Strasburg, PA 17579",
              "phone1": null,
              "phone2": "717-397-1309",
              "fax1": null,
              "fax2": "717-299-7798",
              "twitter": "ScottFMartin",
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorScottMartinPA",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1763",
                "https://senatorscottmartinpa.com/",
                "https://www.palegis.us/senate/members/bio/1763/sen-martin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-13-upper-ocd-person-c2fcd2dc-22f8-4ec2-b70a-439bc6d60802": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/a160699d-cc7d-4ed7-b486-57008ee0e642",
            "firstName": "Nicholas P.",
            "lastName": "Miller",
            "fullName": "Nick Miller",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/2009.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-PA-14-upper-ocd-person-a160699d-cc7d-4ed7-b486-57008ee0e642",
              "builtID": "pa-upper-14",
              "externalID": "ocd-person/a160699d-cc7d-4ed7-b486-57008ee0e642",
              "geometry": null
            },
            "contactInfo": {
              "email": "miller@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "740 W. Hamilton St. Suite 200, Allentown, PA 18101",
              "phone1": null,
              "phone2": "610-821-8468",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=2009",
                "https://pasenatormiller.com/",
                "https://www.palegis.us/senate/members/bio/2009/sen-miller"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-14-upper-ocd-person-a160699d-cc7d-4ed7-b486-57008ee0e642": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/01ce8a7c-29e4-4439-be3a-b919797dc912",
            "firstName": "Giovanni M.",
            "lastName": "DiSanto",
            "fullName": "John DiSanto",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1765.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-PA-15-upper-ocd-person-01ce8a7c-29e4-4439-be3a-b919797dc912",
              "builtID": "pa-upper-15",
              "externalID": "ocd-person/01ce8a7c-29e4-4439-be3a-b919797dc912",
              "geometry": null
            },
            "contactInfo": {
              "email": "jdisanto@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "SenatorDiSanto",
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorDiSanto",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1765",
                "https://www.senatordisanto.com/",
                "https://www.palegis.us/senate/members/bio/1765/sen-disanto"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-15-upper-ocd-person-01ce8a7c-29e4-4439-be3a-b919797dc912": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/ecd51b53-a59b-4b92-b203-c1d24f748cb1",
            "firstName": "Jarrett Charles",
            "lastName": "Coleman",
            "fullName": "Jarrett Coleman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/2008.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-PA-16-upper-ocd-person-ecd51b53-a59b-4b92-b203-c1d24f748cb1",
              "builtID": "pa-upper-16",
              "externalID": "ocd-person/ecd51b53-a59b-4b92-b203-c1d24f748cb1",
              "geometry": null
            },
            "contactInfo": {
              "email": "jcoleman@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 102, 314 Broad St., Quakertown, PA 18951",
              "phone1": null,
              "phone2": "267-551-3744",
              "fax1": null,
              "fax2": "267-551-3764",
              "twitter": "SenatorJColeman",
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorJarrettColeman",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=2008",
                "https://senatorcoleman.com/",
                "https://www.palegis.us/senate/members/bio/2008/sen-coleman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-16-upper-ocd-person-ecd51b53-a59b-4b92-b203-c1d24f748cb1": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/77755456-0f2a-4bbd-ac96-03d83398d6fc",
            "firstName": "Amanda M.",
            "lastName": "Cappelletti",
            "fullName": "Amanda Cappelletti",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1923.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-PA-17-upper-ocd-person-77755456-0f2a-4bbd-ac96-03d83398d6fc",
              "builtID": "pa-upper-17",
              "externalID": "ocd-person/77755456-0f2a-4bbd-ac96-03d83398d6fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "cappelletti@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "221 W. Main St., Norristown, PA 19401",
              "phone1": null,
              "phone2": "610-768-4200",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1923",
                "https://pasenatorcappelletti.com/",
                "https://www.palegis.us/senate/members/bio/1923/sen-cappelletti"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-17-upper-ocd-person-77755456-0f2a-4bbd-ac96-03d83398d6fc": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/de81d407-bc84-48e6-9796-4a56be984cbd",
            "firstName": "Lisa M.",
            "lastName": "Boscola",
            "fullName": "Lisa Boscola",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/179.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-PA-18-upper-ocd-person-de81d407-bc84-48e6-9796-4a56be984cbd",
              "builtID": "pa-upper-18",
              "externalID": "ocd-person/de81d407-bc84-48e6-9796-4a56be984cbd",
              "geometry": null
            },
            "contactInfo": {
              "email": "boscola@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "1701 Washington Blvd., Easton, PA 18042",
              "phone1": null,
              "phone2": "610-250-5627",
              "fax1": null,
              "fax2": null,
              "twitter": "SenLisaBoscola",
              "youtube": null,
              "instagram": null,
              "facebook": "lisa.boscola",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=179",
                "https://senatorboscola.com/",
                "https://www.palegis.us/senate/members/bio/179/sen-boscola"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-18-upper-ocd-person-de81d407-bc84-48e6-9796-4a56be984cbd": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/91c58c1d-de9d-471b-8178-f6ef89c1286b",
            "firstName": "Carolyn T.",
            "lastName": "Comitta",
            "fullName": "Carolyn Comitta",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1790.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-PA-19-upper-ocd-person-91c58c1d-de9d-471b-8178-f6ef89c1286b",
              "builtID": "pa-upper-19",
              "externalID": "ocd-person/91c58c1d-de9d-471b-8178-f6ef89c1286b",
              "geometry": null
            },
            "contactInfo": {
              "email": "carolyn@carolyncomitta.com",
              "contactForm": null,
              "address1": null,
              "address2": "17 E. Gay St. Suite 301, West Chester, PA 19380",
              "phone1": null,
              "phone2": "610-692-2112",
              "fax1": null,
              "fax2": "610-436-1721",
              "twitter": "senatorcomitta",
              "youtube": null,
              "instagram": "senatorcomitta",
              "facebook": "SenatorComitta",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1790",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1790",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senate_bio.cfm?id=1790",
                "https://www.palegis.us/senate/members/bio/1790/sen-comitta",
                "https://www.pasenatorcomitta.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-19-upper-ocd-person-91c58c1d-de9d-471b-8178-f6ef89c1286b": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/aa421a2c-4203-45b7-9766-8826c02a659d",
            "firstName": "Elizabeth J.",
            "lastName": "Baker",
            "fullName": "Lisa Baker",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1077.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-PA-20-upper-ocd-person-aa421a2c-4203-45b7-9766-8826c02a659d",
              "builtID": "pa-upper-20",
              "externalID": "ocd-person/aa421a2c-4203-45b7-9766-8826c02a659d",
              "geometry": null
            },
            "contactInfo": {
              "email": "lbaker@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "50 N. Walnut St. Suite 105, Nanticoke, PA 18634",
              "phone1": null,
              "phone2": "570-740-2432",
              "fax1": null,
              "fax2": null,
              "twitter": "SenLisaBaker",
              "youtube": null,
              "instagram": null,
              "facebook": "senatorlisabaker",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1077",
                "https://www.senatorbaker.com/",
                "https://www.palegis.us/senate/members/bio/1077/sen-baker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-20-upper-ocd-person-aa421a2c-4203-45b7-9766-8826c02a659d": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/5d3e2203-b11c-4471-8f35-5106c30263ec",
            "firstName": "Scott E.",
            "lastName": "Hutchinson",
            "fullName": "Scott Hutchinson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1629.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-PA-21-upper-ocd-person-5d3e2203-b11c-4471-8f35-5106c30263ec",
              "builtID": "pa-upper-21",
              "externalID": "ocd-person/5d3e2203-b11c-4471-8f35-5106c30263ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "mhutchin19@verizon.net",
              "contactForm": null,
              "address1": null,
              "address2": "229 Elm St., Oil City, PA 16301",
              "phone1": null,
              "phone2": "814-677-6345",
              "fax1": null,
              "fax2": "814-677-6331",
              "twitter": "SenHutchinson",
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorScottEHutchinson",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1629",
                "https://www.senatorscotthutchinson.com/",
                "https://www.palegis.us/senate/members/bio/1629/sen-hutchinson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-21-upper-ocd-person-5d3e2203-b11c-4471-8f35-5106c30263ec": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/7061c75d-7eeb-40aa-841e-8121edba5d8a",
            "firstName": "Martin Bradshaw",
            "lastName": "Flynn",
            "fullName": "Marty Flynn",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1626.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-PA-22-upper-ocd-person-7061c75d-7eeb-40aa-841e-8121edba5d8a",
              "builtID": "pa-upper-22",
              "externalID": "ocd-person/7061c75d-7eeb-40aa-841e-8121edba5d8a",
              "geometry": null
            },
            "contactInfo": {
              "email": "flynn@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "Jay’s Commons 409 N. Main Ave., Scranton, PA 18504",
              "phone1": null,
              "phone2": "570-207-2881",
              "fax1": null,
              "fax2": null,
              "twitter": "senmartyflynn",
              "youtube": "playlist?list=PLjtqNtfs0bgU0MZChdMxeZnvHw-jlVCVb",
              "instagram": "senmartyflynn",
              "facebook": "SenatorMartyFlynn",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1626",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1626",
                "https://www.senatorflynn.com/",
                "https://www.palegis.us/senate/members/bio/1626/sen-flynn"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-22-upper-ocd-person-7061c75d-7eeb-40aa-841e-8121edba5d8a": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/18127660-d04e-43c2-b931-084f0c87142c",
            "firstName": "E. Eugene",
            "lastName": "Yaw",
            "fullName": "Gene Yaw",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1186.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-PA-23-upper-ocd-person-18127660-d04e-43c2-b931-084f0c87142c",
              "builtID": "pa-upper-23",
              "externalID": "ocd-person/18127660-d04e-43c2-b931-084f0c87142c",
              "geometry": null
            },
            "contactInfo": {
              "email": "gyaw@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5 Main St., Wellsboro, PA 16901",
              "phone1": null,
              "phone2": "570-724-5231",
              "fax1": null,
              "fax2": null,
              "twitter": "SenatorGeneYaw",
              "youtube": "UCfLs1ZD65qG1wGNc7xB9PBg",
              "instagram": "senatoryaw",
              "facebook": "SenatorGeneYaw",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1186",
                "https://www.senatorgeneyaw.com/",
                "https://www.palegis.us/senate/members/bio/1186/sen-yaw"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-23-upper-ocd-person-18127660-d04e-43c2-b931-084f0c87142c": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/d9ad47a6-7e74-45f2-93a5-1b7e40ad36db",
            "firstName": "Tracy",
            "lastName": "Pennycuick",
            "fullName": "Tracy Pennycuick",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1912.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-PA-24-upper-ocd-person-d9ad47a6-7e74-45f2-93a5-1b7e40ad36db",
              "builtID": "pa-upper-24",
              "externalID": "ocd-person/d9ad47a6-7e74-45f2-93a5-1b7e40ad36db",
              "geometry": null
            },
            "contactInfo": {
              "email": "tpennycuick@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "56 W. 4th St. Floor 2, Red Hill, PA 18076",
              "phone1": null,
              "phone2": "215-541-2388",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "PA-State-Rep-Tracy-Pennycuick-106417357971506",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1912",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1912",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senate_bio.cfm?id=1912",
                "https://senatorpennycuick.com/",
                "https://www.palegis.us/senate/members/bio/1912/sen-pennycuick"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-24-upper-ocd-person-d9ad47a6-7e74-45f2-93a5-1b7e40ad36db": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/402d7e3b-1935-47fa-8124-61490037d016",
            "firstName": "Cris E.",
            "lastName": "Dush",
            "fullName": "Cris Dush",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1687.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-PA-25-upper-ocd-person-402d7e3b-1935-47fa-8124-61490037d016",
              "builtID": "pa-upper-25",
              "externalID": "ocd-person/402d7e3b-1935-47fa-8124-61490037d016",
              "geometry": null
            },
            "contactInfo": {
              "email": "cdush@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "73 S. White St. Suite 5, Brookville, PA 15825",
              "phone1": null,
              "phone2": "814-646-7272",
              "fax1": null,
              "fax2": "814-646-7275",
              "twitter": null,
              "youtube": "RepDush",
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1687",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1687",
                "https://senatordush.com/",
                "https://www.palegis.us/senate/members/bio/1687/sen-dush"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-25-upper-ocd-person-402d7e3b-1935-47fa-8124-61490037d016": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/f9f2479f-0c37-47d1-a4eb-666146e2856f",
            "firstName": "Timothy P.",
            "lastName": "Kearney",
            "fullName": "Tim Kearney",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1800.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-PA-26-upper-ocd-person-f9f2479f-0c37-47d1-a4eb-666146e2856f",
              "builtID": "pa-upper-26",
              "externalID": "ocd-person/f9f2479f-0c37-47d1-a4eb-666146e2856f",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorkearney@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "51 Long Lane, Upper Darby, PA 19082",
              "phone1": null,
              "phone2": "610-352-3409",
              "fax1": null,
              "fax2": null,
              "twitter": "SenTimKearney",
              "youtube": null,
              "instagram": "sentimkearney",
              "facebook": "SenTimKearney",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1800",
                "https://www.senatorkearney.com/",
                "https://www.palegis.us/senate/members/bio/1800/sen-kearney"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-26-upper-ocd-person-f9f2479f-0c37-47d1-a4eb-666146e2856f": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/02117eb4-a7b3-4095-831b-1857910660fb",
            "firstName": "Lynda Joy Schlegel",
            "lastName": "Culver",
            "fullName": "Lynda Culver",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1202.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-PA-27-upper-ocd-person-02117eb4-a7b3-4095-831b-1857910660fb",
              "builtID": "pa-upper-27",
              "externalID": "ocd-person/02117eb4-a7b3-4095-831b-1857910660fb",
              "geometry": null
            },
            "contactInfo": {
              "email": "lculver@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Luzerne County, PA",
              "phone1": null,
              "phone2": "570-387-4267",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": "RepCulver",
              "instagram": null,
              "facebook": "SenatorCulver",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1202",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1202",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1202",
                "https://www.palegis.us/senate/members/bio/1202/sen-culver",
                "https://senatorculver.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-27-upper-ocd-person-02117eb4-a7b3-4095-831b-1857910660fb": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/cb724e4a-0e5e-4ebd-ac0c-f3dfda73aef6",
            "firstName": "Kristin Lee",
            "lastName": "Phillips-Hill",
            "fullName": "Kristin Phillips-Hill",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1801.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-PA-28-upper-ocd-person-cb724e4a-0e5e-4ebd-ac0c-f3dfda73aef6",
              "builtID": "pa-upper-28",
              "externalID": "ocd-person/cb724e4a-0e5e-4ebd-ac0c-f3dfda73aef6",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorkristin@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6872 Susquehanna Trail South P.O. Box 277, Jacobus, PA 17407",
              "phone1": null,
              "phone2": "717-741-4648",
              "fax1": null,
              "fax2": "717-741-4655",
              "twitter": "SenatorKristin",
              "youtube": "UCCVUqHEfIFP1Ex1x0t9mELg",
              "instagram": "SenatorKristin",
              "facebook": "SenatorKristin",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1801",
                "https://senatorkristin.com/",
                "https://www.palegis.us/senate/members/bio/1801/sen-phillips-hill"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-28-upper-ocd-person-cb724e4a-0e5e-4ebd-ac0c-f3dfda73aef6": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/2a2df3c1-9ede-46b7-8d73-896b1e317aff",
            "firstName": "David G.",
            "lastName": "Argall",
            "fullName": "Dave Argall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/69.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-PA-29-upper-ocd-person-2a2df3c1-9ede-46b7-8d73-896b1e317aff",
              "builtID": "pa-upper-29",
              "externalID": "ocd-person/2a2df3c1-9ede-46b7-8d73-896b1e317aff",
              "geometry": null
            },
            "contactInfo": {
              "email": "dargall@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 150 1 W. Centre St., Mahanoy City, PA 17948",
              "phone1": null,
              "phone2": "570-773-0891",
              "fax1": null,
              "fax2": "570-773-1675",
              "twitter": "SenatorArgall",
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorArgall",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=69",
                "https://www.senatorargall.com/",
                "https://www.palegis.us/senate/members/bio/69/sen-argall"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-29-upper-ocd-person-2a2df3c1-9ede-46b7-8d73-896b1e317aff": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/b6ed7151-9ea4-4828-b639-785f9e358eb1",
            "firstName": "Judith F.",
            "lastName": "Ward",
            "fullName": "Judy Ward",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1683.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-PA-30-upper-ocd-person-b6ed7151-9ea4-4828-b639-785f9e358eb1",
              "builtID": "pa-upper-30",
              "externalID": "ocd-person/b6ed7151-9ea4-4828-b639-785f9e358eb1",
              "geometry": null
            },
            "contactInfo": {
              "email": "jward@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3 W. Market St. Suite 201, Lewistown, PA 17044",
              "phone1": null,
              "phone2": "717-210-5700",
              "fax1": null,
              "fax2": "717-242-4775",
              "twitter": null,
              "youtube": "RepJudyWard",
              "instagram": null,
              "facebook": "SenJudyWard",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1683",
                "https://senatorjudyward.com/",
                "https://www.palegis.us/senate/members/bio/1683/sen-j-ward"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-30-upper-ocd-person-b6ed7151-9ea4-4828-b639-785f9e358eb1": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/ce0d90e3-b4dc-436f-a808-dc40946b6352",
            "firstName": "Michael",
            "lastName": "Regan",
            "fullName": "Mike Regan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1646.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-PA-31-upper-ocd-person-ce0d90e3-b4dc-436f-a808-dc40946b6352",
              "builtID": "pa-upper-31",
              "externalID": "ocd-person/ce0d90e3-b4dc-436f-a808-dc40946b6352",
              "geometry": null
            },
            "contactInfo": {
              "email": "mregan@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1 E. Harrisburg St., Dillsburg, PA 17019",
              "phone1": null,
              "phone2": "717-432-1730",
              "fax1": null,
              "fax2": "717-432-1733",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1646",
                "https://senatorregan.com/",
                "https://www.palegis.us/senate/members/bio/1646/sen-regan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-31-upper-ocd-person-ce0d90e3-b4dc-436f-a808-dc40946b6352": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/14106428-709f-4018-8881-5c31ee078ff8",
            "firstName": "Patrick J.",
            "lastName": "Stefano",
            "fullName": "Pat Stefano",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1697.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-PA-32-upper-ocd-person-14106428-709f-4018-8881-5c31ee078ff8",
              "builtID": "pa-upper-32",
              "externalID": "ocd-person/14106428-709f-4018-8881-5c31ee078ff8",
              "geometry": null
            },
            "contactInfo": {
              "email": "pstefano@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "171 W. Crawford Ave. 2nd Floor, Connellsville, PA 15425",
              "phone1": null,
              "phone2": "724-626-1611",
              "fax1": null,
              "fax2": "724-626-1665",
              "twitter": "Senatorstefano",
              "youtube": null,
              "instagram": "Senator_Stefano",
              "facebook": "Senatorstefano",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1697",
                "https://www.senatorstefano.com/",
                "https://www.palegis.us/senate/members/bio/1697/sen-stefano"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-32-upper-ocd-person-14106428-709f-4018-8881-5c31ee078ff8": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/cda65f80-13e8-463c-88fa-6f5569a599bf",
            "firstName": "Douglas Vincent",
            "lastName": "Mastriano",
            "fullName": "Doug Mastriano",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1869.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-PA-33-upper-ocd-person-cda65f80-13e8-463c-88fa-6f5569a599bf",
              "builtID": "pa-upper-33",
              "externalID": "ocd-person/cda65f80-13e8-463c-88fa-6f5569a599bf",
              "geometry": null
            },
            "contactInfo": {
              "email": "dmastriano@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "37 S. Main St. Suite 200, Chambersburg, PA 17201",
              "phone1": null,
              "phone2": "717-264-6100",
              "fax1": null,
              "fax2": "717-264-3652",
              "twitter": "senmastriano",
              "youtube": null,
              "instagram": "senatormastriano",
              "facebook": "SenatorDougMastriano",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1869",
                "https://senatormastriano.com/",
                "https://www.palegis.us/senate/members/bio/1869/sen-mastriano"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-33-upper-ocd-person-cda65f80-13e8-463c-88fa-6f5569a599bf": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/3e99b4f3-2efa-4330-b0b9-fb273b1322ea",
            "firstName": "William Gregory",
            "lastName": "Rothman",
            "fullName": "Greg Rothman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1733.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-PA-34-upper-ocd-person-3e99b4f3-2efa-4330-b0b9-fb273b1322ea",
              "builtID": "pa-upper-34",
              "externalID": "ocd-person/3e99b4f3-2efa-4330-b0b9-fb273b1322ea",
              "geometry": null
            },
            "contactInfo": {
              "email": "grothman@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 434 81 Walnut Bottom Road, Shippensburg, PA 17257",
              "phone1": null,
              "phone2": "717-844-5441",
              "fax1": null,
              "fax2": null,
              "twitter": "rothman_greg",
              "youtube": "UCz6KZftvAbpwiPwOtYc1tDQ",
              "instagram": "senatorrothman",
              "facebook": "SenatorRothman",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1733",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1733",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1733",
                "https://senatorrothman.com/",
                "https://www.palegis.us/senate/members/bio/1733/sen-rothman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-34-upper-ocd-person-3e99b4f3-2efa-4330-b0b9-fb273b1322ea": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/7b3a6244-335b-4407-957d-b7d6b6ab1d97",
            "firstName": "Wayne",
            "lastName": "Langerholc",
            "fullName": "Wayne Langerholc",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1764.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-PA-35-upper-ocd-person-7b3a6244-335b-4407-957d-b7d6b6ab1d97",
              "builtID": "pa-upper-35",
              "externalID": "ocd-person/7b3a6244-335b-4407-957d-b7d6b6ab1d97",
              "geometry": null
            },
            "contactInfo": {
              "email": "wlangerholc@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "999 Eisenhower Blvd. Suite E, Johnstown, PA 15904",
              "phone1": null,
              "phone2": "814-266-2277",
              "fax1": null,
              "fax2": "814-266-0057",
              "twitter": "senlangerholc",
              "youtube": null,
              "instagram": "senlangerholc",
              "facebook": "senatorwaynelangerholcjr",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1764",
                "https://www.senatorlangerholc.com/",
                "https://www.palegis.us/senate/members/bio/1764/sen-langerholc"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-35-upper-ocd-person-7b3a6244-335b-4407-957d-b7d6b6ab1d97": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/84be9cf4-8e9f-4e9c-91f0-f371efba7895",
            "firstName": "Ryan P.",
            "lastName": "Aument",
            "fullName": "Ryan Aument",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1198.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-PA-36-upper-ocd-person-84be9cf4-8e9f-4e9c-91f0-f371efba7895",
              "builtID": "pa-upper-36",
              "externalID": "ocd-person/84be9cf4-8e9f-4e9c-91f0-f371efba7895",
              "geometry": null
            },
            "contactInfo": {
              "email": "raument@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "301 E. Main St., Lititz, PA 17543",
              "phone1": null,
              "phone2": "717-627-0036",
              "fax1": null,
              "fax2": "717-627-1389",
              "twitter": "SenatorAument",
              "youtube": null,
              "instagram": "senator_aument",
              "facebook": "senatoraument",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1198",
                "https://www.senatoraument.com/",
                "https://www.palegis.us/senate/members/bio/1198/sen-aument"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-36-upper-ocd-person-84be9cf4-8e9f-4e9c-91f0-f371efba7895": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/5271803b-8b46-449c-bf89-44f7d09c6cd2",
            "firstName": "Devlin J.",
            "lastName": "Robinson",
            "fullName": "Devlin Robinson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1924.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-PA-37-upper-ocd-person-5271803b-8b46-449c-bf89-44f7d09c6cd2",
              "builtID": "pa-upper-37",
              "externalID": "ocd-person/5271803b-8b46-449c-bf89-44f7d09c6cd2",
              "geometry": null
            },
            "contactInfo": {
              "email": "drobinson@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "200 Cedar Ridge Drive Suite 206, Pittsburgh, PA 15205",
              "phone1": null,
              "phone2": "412-643-3020",
              "fax1": null,
              "fax2": null,
              "twitter": "SenRobinsonPA",
              "youtube": null,
              "instagram": null,
              "facebook": "SenRobinsonPA",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1924",
                "https://senatorrobinson.com/",
                "https://www.palegis.us/senate/members/bio/1924/sen-robinson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-37-upper-ocd-person-5271803b-8b46-449c-bf89-44f7d09c6cd2": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/bc64322a-faaf-4b33-b6c2-cf5059f4121c",
            "firstName": "Lindsey Marie",
            "lastName": "Williams",
            "fullName": "Lindsey Williams",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1803.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-PA-38-upper-ocd-person-bc64322a-faaf-4b33-b6c2-cf5059f4121c",
              "builtID": "pa-upper-38",
              "externalID": "ocd-person/bc64322a-faaf-4b33-b6c2-cf5059f4121c",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorlindseywilliams@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "5000 McKnight Road Suite 405, Pittsburgh, PA 15237",
              "phone1": null,
              "phone2": "412-364-0469",
              "fax1": null,
              "fax2": "412-364-0928",
              "twitter": "SenWilliamsPA",
              "youtube": null,
              "instagram": "SenWilliamsPA",
              "facebook": "SenWilliamsPA",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1803",
                "https://senatorlindseywilliams.com/",
                "https://www.palegis.us/senate/members/bio/1803/sen-l-williams"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-38-upper-ocd-person-bc64322a-faaf-4b33-b6c2-cf5059f4121c": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/c48a0179-5770-410d-ba97-bdbc05d20786",
            "firstName": "Kim Lee",
            "lastName": "Ward",
            "fullName": "Kim Ward",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1188.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-PA-39-upper-ocd-person-c48a0179-5770-410d-ba97-bdbc05d20786",
              "builtID": "pa-upper-39",
              "externalID": "ocd-person/c48a0179-5770-410d-ba97-bdbc05d20786",
              "geometry": null
            },
            "contactInfo": {
              "email": "kward@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Westmoreland Crossroads Plaza 1075 S. Main St., Suite 116, Greensburg, PA 15601",
              "phone1": null,
              "phone2": "724-600-7002",
              "fax1": null,
              "fax2": "724-600-7008",
              "twitter": "SenatorKimWard",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1188",
                "https://senatorward.com/",
                "https://www.palegis.us/senate/members/bio/1188/sen-k-ward"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-39-upper-ocd-person-c48a0179-5770-410d-ba97-bdbc05d20786": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/3e2840e6-f904-43be-911e-235b906458a5",
            "firstName": "Rosemary Maula",
            "lastName": "Brown",
            "fullName": "Rosemary Brown",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1200.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-PA-40-upper-ocd-person-3e2840e6-f904-43be-911e-235b906458a5",
              "builtID": "pa-upper-40",
              "externalID": "ocd-person/3e2840e6-f904-43be-911e-235b906458a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rbrown@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "487 Cortez Road, Jefferson Township, PA 18436",
              "phone1": null,
              "phone2": "570-213-5705",
              "fax1": null,
              "fax2": null,
              "twitter": "SenatorRBrown",
              "youtube": "RepBrown",
              "instagram": null,
              "facebook": "SenatorRosemaryBrown",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1200",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/representatives_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/House_bio.cfm?id=1200",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1200",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senate_bio.cfm?id=1200",
                "https://www.palegis.us/senate/members/bio/1200/sen-brown",
                "https://senatorbrown40.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-40-upper-ocd-person-3e2840e6-f904-43be-911e-235b906458a5": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/722f324f-db47-4ce2-9cbc-93f29ae38158",
            "firstName": "Joseph A.",
            "lastName": "Pittman",
            "fullName": "Joe Pittman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1870.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-PA-41-upper-ocd-person-722f324f-db47-4ce2-9cbc-93f29ae38158",
              "builtID": "pa-upper-41",
              "externalID": "ocd-person/722f324f-db47-4ce2-9cbc-93f29ae38158",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.pittman@pasenator41.com",
              "contactForm": null,
              "address1": null,
              "address2": "618 Philadelphia St., Indiana, PA 15701",
              "phone1": null,
              "phone2": "724-357-0151",
              "fax1": null,
              "fax2": "724-357-0148",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorPittman",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1870",
                "https://senatorpittman.com/",
                "https://www.palegis.us/senate/members/bio/1870/sen-pittman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-41-upper-ocd-person-722f324f-db47-4ce2-9cbc-93f29ae38158": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/b93e4f38-764f-4e95-bd51-4d40dba0aaa4",
            "firstName": "Wayne D.",
            "lastName": "Fontana",
            "fullName": "Wayne Fontana",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1041.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-PA-42-upper-ocd-person-b93e4f38-764f-4e95-bd51-4d40dba0aaa4",
              "builtID": "pa-upper-42",
              "externalID": "ocd-person/b93e4f38-764f-4e95-bd51-4d40dba0aaa4",
              "geometry": null
            },
            "contactInfo": {
              "email": "fontana@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "Kenmawr Plaza 524 Pine Hollow Road, Kennedy Township, PA 15136",
              "phone1": null,
              "phone2": "412-331-1208",
              "fax1": null,
              "fax2": null,
              "twitter": "waynedfontana",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1041",
                "https://www.senatorfontana.com/",
                "https://www.palegis.us/senate/members/bio/1041/sen-fontana"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-42-upper-ocd-person-b93e4f38-764f-4e95-bd51-4d40dba0aaa4": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/ee6b52c3-d92c-4cd6-a6ea-6869a8c93df0",
            "firstName": "Jay",
            "lastName": "Costa",
            "fullName": "Jay Costa",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/254.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "43",
              "chamber": "upper",
              "hashID": "SLDU-PA-43-upper-ocd-person-ee6b52c3-d92c-4cd6-a6ea-6869a8c93df0",
              "builtID": "pa-upper-43",
              "externalID": "ocd-person/ee6b52c3-d92c-4cd6-a6ea-6869a8c93df0",
              "geometry": null
            },
            "contactInfo": {
              "email": "costa@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "4736 Liberty Ave. Suite 1, Pittsburgh, PA 15224",
              "phone1": null,
              "phone2": "412-578-8457",
              "fax1": null,
              "fax2": "412-578-9874",
              "twitter": "senatorcosta",
              "youtube": null,
              "instagram": "senatorcosta",
              "facebook": "senatorjaycosta",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=254",
                "https://senatorcosta.com/",
                "https://www.palegis.us/senate/members/bio/254/sen-costa"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-43-upper-ocd-person-ee6b52c3-d92c-4cd6-a6ea-6869a8c93df0": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/ade3ef98-a98c-4448-a974-ec4e0eede98f",
            "firstName": "Katie J.",
            "lastName": "Muth",
            "fullName": "Katie Muth",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1802.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "44",
              "chamber": "upper",
              "hashID": "SLDU-PA-44-upper-ocd-person-ade3ef98-a98c-4448-a974-ec4e0eede98f",
              "builtID": "pa-upper-44",
              "externalID": "ocd-person/ade3ef98-a98c-4448-a974-ec4e0eede98f",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatormuth@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "338 Main St., Royersford, PA 19468",
              "phone1": null,
              "phone2": "610-792-2137",
              "fax1": null,
              "fax2": "610-948-3037",
              "twitter": "senatormuth",
              "youtube": null,
              "instagram": null,
              "facebook": "senatormuth",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1802",
                "https://www.senatormuth.com/",
                "https://www.palegis.us/senate/members/bio/1802/sen-muth"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-44-upper-ocd-person-ade3ef98-a98c-4448-a974-ec4e0eede98f": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/cac6095b-9793-4edb-835a-70bc551dfd84",
            "firstName": "James R.",
            "lastName": "Brewster",
            "fullName": "Jim Brewster",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.legis.state.pa.us/images/members/200/1197.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "45",
              "chamber": "upper",
              "hashID": "SLDU-PA-45-upper-ocd-person-cac6095b-9793-4edb-835a-70bc551dfd84",
              "builtID": "pa-upper-45",
              "externalID": "ocd-person/cac6095b-9793-4edb-835a-70bc551dfd84",
              "geometry": null
            },
            "contactInfo": {
              "email": "brewster@pasenate.com",
              "contactForm": null,
              "address1": null,
              "address2": "One Monroeville Center 3824 Northern Pike, Suite 1015, Monroeville, PA 15146",
              "phone1": null,
              "phone2": "412-380-2242",
              "fax1": null,
              "fax2": "412-380-2249",
              "twitter": "senatorbrewster",
              "youtube": null,
              "instagram": null,
              "facebook": "senatorbrewster",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1197",
                "https://www.senatorbrewster.com/",
                "https://www.palegis.us/senate/members/bio/1197/sen-brewster"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-45-upper-ocd-person-cac6095b-9793-4edb-835a-70bc551dfd84": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/66b8c0a7-f8be-4acd-aafb-29ca02fcde65",
            "firstName": "Camera Chatham",
            "lastName": "Bartolotta",
            "fullName": "Camera Bartolotta",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1698.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "46",
              "chamber": "upper",
              "hashID": "SLDU-PA-46-upper-ocd-person-66b8c0a7-f8be-4acd-aafb-29ca02fcde65",
              "builtID": "pa-upper-46",
              "externalID": "ocd-person/66b8c0a7-f8be-4acd-aafb-29ca02fcde65",
              "geometry": null
            },
            "contactInfo": {
              "email": "cbartolotta@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "95 W. Beau St. Suite 107, Washington, PA 15301",
              "phone1": null,
              "phone2": "724-225-4380",
              "fax1": null,
              "fax2": "724-225-4386",
              "twitter": "senbartolotta",
              "youtube": null,
              "instagram": null,
              "facebook": "senatorbartolotta",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1698",
                "https://www.senatorbartolotta.com/",
                "https://www.palegis.us/senate/members/bio/1698/sen-bartolotta"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-46-upper-ocd-person-66b8c0a7-f8be-4acd-aafb-29ca02fcde65": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/8a2a9b56-cdfa-45e0-a26e-b45b547a2eae",
            "firstName": "Elder A.",
            "lastName": "Vogel",
            "fullName": "Elder Vogel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1189.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "47",
              "chamber": "upper",
              "hashID": "SLDU-PA-47-upper-ocd-person-8a2a9b56-cdfa-45e0-a26e-b45b547a2eae",
              "builtID": "pa-upper-47",
              "externalID": "ocd-person/8a2a9b56-cdfa-45e0-a26e-b45b547a2eae",
              "geometry": null
            },
            "contactInfo": {
              "email": "evogel@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "8001 Rowan Road Suite 205, Cranberry Township, PA 16066",
              "phone1": null,
              "phone2": "878-978-2575",
              "fax1": null,
              "fax2": null,
              "twitter": "SenElderVogelJr",
              "youtube": null,
              "instagram": "seneldervogel",
              "facebook": "senator.elder.vogel",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1189",
                "https://www.senatoreldervogel.com/",
                "https://www.palegis.us/senate/members/bio/1189/sen-vogel"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-47-upper-ocd-person-8a2a9b56-cdfa-45e0-a26e-b45b547a2eae": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/965b26f8-5a4a-4458-a327-a25d89605e65",
            "firstName": "Christopher",
            "lastName": "Gebhard",
            "fullName": "Chris Gebhard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1928.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "48",
              "chamber": "upper",
              "hashID": "SLDU-PA-48-upper-ocd-person-965b26f8-5a4a-4458-a327-a25d89605e65",
              "builtID": "pa-upper-48",
              "externalID": "ocd-person/965b26f8-5a4a-4458-a327-a25d89605e65",
              "geometry": null
            },
            "contactInfo": {
              "email": "cgebhard@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "61 N. 3rd St., Hamburg, PA 19526",
              "phone1": null,
              "phone2": "717-274-6735",
              "fax1": null,
              "fax2": null,
              "twitter": "SenatorGebhard",
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorGebhard48",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1928",
                "https://senatorgebhard.com/",
                "https://www.palegis.us/senate/members/bio/1928/sen-gebhard"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-48-upper-ocd-person-965b26f8-5a4a-4458-a327-a25d89605e65": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/431a823e-0268-4921-a62d-5f698211e706",
            "firstName": "Daniel",
            "lastName": "Laughlin",
            "fullName": "Dan Laughlin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1766.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "49",
              "chamber": "upper",
              "hashID": "SLDU-PA-49-upper-ocd-person-431a823e-0268-4921-a62d-5f698211e706",
              "builtID": "pa-upper-49",
              "externalID": "ocd-person/431a823e-0268-4921-a62d-5f698211e706",
              "geometry": null
            },
            "contactInfo": {
              "email": "dlaughlin@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1314 Griswold Plaza, Erie, PA 16501",
              "phone1": null,
              "phone2": "814-453-2515",
              "fax1": null,
              "fax2": null,
              "twitter": "senatorlaughlin",
              "youtube": null,
              "instagram": null,
              "facebook": "senatorlaughlin",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1766",
                "https://www.senatorlaughlin.com/",
                "https://www.palegis.us/senate/members/bio/1766/sen-laughlin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-49-upper-ocd-person-431a823e-0268-4921-a62d-5f698211e706": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/406baa79-8352-4fa4-a1fa-70cc55e34406",
            "firstName": "Michele",
            "lastName": "Brooks",
            "fullName": "Michele Brooks",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.legis.state.pa.us/images/members/200/1087.jpg?1703415645672",
            "title": "PA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "PA",
              "stateFull": "Pennsylvania",
              "district": "50",
              "chamber": "upper",
              "hashID": "SLDU-PA-50-upper-ocd-person-406baa79-8352-4fa4-a1fa-70cc55e34406",
              "builtID": "pa-upper-50",
              "externalID": "ocd-person/406baa79-8352-4fa4-a1fa-70cc55e34406",
              "geometry": null
            },
            "contactInfo": {
              "email": "mbrooks@pasen.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1905 W. State St., New Castle, PA 16101",
              "phone1": null,
              "phone2": "724-654-1444",
              "fax1": null,
              "fax2": "724-656-3182",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "senatormichelebrooks",
              "urls": [
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/senators_alpha.cfm",
                "https://www.legis.state.pa.us/cfdocs/legis/home/member_information/Senate_bio.cfm?id=1087",
                "https://www.senatorbrooks.com/",
                "https://www.palegis.us/senate/members/bio/1087/sen-brooks"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-PA-50-upper-ocd-person-406baa79-8352-4fa4-a1fa-70cc55e34406": 0
        }
      }
    }
  },
  "RI": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "RI",
          "stateFull": "Rhode Island",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "RI",
            "stateFull": "Rhode Island",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-RI---",
            "builtID": "ri--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-RI---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "R000122",
          "in_office": true,
          "firstName": "Jack",
          "lastName": "Reed",
          "middleName": null,
          "fullName": "Jack Reed",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/R000122.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "RI",
            "stateFull": "Rhode Island",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-RI---R000122",
            "builtID": "ri--",
            "externalID": "R000122",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.reed.senate.gov/contact/",
            "address1": "728 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-4642",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenJackReed",
            "youtube": "SenatorReed",
            "instagram": null,
            "facebook": "SenJackReed",
            "urls": ["https://www.reed.senate.gov"],
            "rss_url": null
          },
          "title": "RI Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/R000122.json",
          "govtrack_id": "300081",
          "cspan_id": "24239",
          "votesmart_id": "27060",
          "icpsr_id": "29142",
          "crp_id": "N00000362",
          "google_entity_id": "/m/0202mc",
          "state_rank": "senior",
          "lis_id": "S259",
          "suffix": null,
          "date_of_birth": "1949-11-12",
          "leadership_role": null,
          "fec_candidate_id": "S6RI00163",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "27",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 0,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 0,
          "votes_with_party_pct": 99.28,
          "votes_against_party_pct": 0.72,
          "ocd_id": "ocd-division/country:us/state:ri"
        },
        {
          "API_ID": "W000802",
          "in_office": true,
          "firstName": "Sheldon",
          "lastName": "Whitehouse",
          "middleName": null,
          "fullName": "Sheldon Whitehouse",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/W000802.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "RI",
            "stateFull": "Rhode Island",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-RI---W000802",
            "builtID": "ri--",
            "externalID": "W000802",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.whitehouse.senate.gov/contact/email-sheldon",
            "address1": "530 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-2921",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenWhitehouse",
            "youtube": "SenatorWhitehouse",
            "instagram": null,
            "facebook": "SenatorWhitehouse",
            "urls": ["https://www.whitehouse.senate.gov"],
            "rss_url": "https://www.whitehouse.senate.gov/rss/feeds/?type=all&cachebuster=1"
          },
          "title": "RI Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/W000802.json",
          "govtrack_id": "412247",
          "cspan_id": "92235",
          "votesmart_id": "2572",
          "icpsr_id": "40704",
          "crp_id": "N00027533",
          "google_entity_id": "/m/07qw94",
          "state_rank": "junior",
          "lis_id": "S316",
          "suffix": null,
          "date_of_birth": "1955-10-20",
          "leadership_role": null,
          "fec_candidate_id": "S6RI00221",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "17",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 11,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 1.99,
          "votes_with_party_pct": 99.45,
          "votes_against_party_pct": 0.55,
          "ocd_id": "ocd-division/country:us/state:ri"
        }
      ],
      "hash": { "SENATE-RI---R000122": 0, "SENATE-RI---W000802": 1 }
    },
    "HOUSE": {
      "01": {
        "members": [
          {
            "API_ID": "A000380",
            "in_office": true,
            "firstName": "Gabe",
            "lastName": "Amo",
            "middleName": null,
            "fullName": "Gabe Amo",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/A000380.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-RI-01--A000380",
              "builtID": "ri--01",
              "externalID": "A000380",
              "geometry": null,
              "geoid": "4401"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [],
              "rss_url": ""
            },
            "title": "RI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/A000380.json",
            "govtrack_id": "456955",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1987-12-11",
            "leadership_role": null,
            "fec_candidate_id": null,
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": null,
            "total_votes": 416,
            "missed_votes": 2,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.48,
            "votes_with_party_pct": 98.04,
            "votes_against_party_pct": 1.71,
            "ocd_id": "ocd-division/country:us/state:ri/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-RI-01--A000380": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "M001223",
            "in_office": true,
            "firstName": "Seth",
            "lastName": "Magaziner",
            "middleName": null,
            "fullName": "Seth Magaziner",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M001223.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-RI-02--M001223",
              "builtID": "ri--02",
              "externalID": "M001223",
              "geometry": null,
              "geoid": "4402"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1218 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2735",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://magaziner.house.gov/"],
              "rss_url": null
            },
            "title": "RI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001223.json",
            "govtrack_id": "456937",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1983-07-22",
            "leadership_role": null,
            "fec_candidate_id": "H2RI02184",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 114,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 10.8,
            "votes_with_party_pct": 95.18,
            "votes_against_party_pct": 2.68,
            "ocd_id": "ocd-division/country:us/state:ri/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-RI-02--M001223": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/e073b501-75f0-4fc6-9e61-392b9931f070",
            "firstName": "Edith H.",
            "lastName": "Ajello",
            "fullName": "Edith Ajello",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/representatives/Pictures/ajello.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-RI-1-lower-ocd-person-e073b501-75f0-4fc6-9e61-392b9931f070",
              "builtID": "ri-lower-1",
              "externalID": "ocd-person/e073b501-75f0-4fc6-9e61-392b9931f070",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-ajello@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/ajello/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/ajello/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-1-lower-ocd-person-e073b501-75f0-4fc6-9e61-392b9931f070": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/a9ba95ac-22f3-4aeb-8fdd-eb236675d8d4",
            "firstName": "Christopher R.",
            "lastName": "Blazejewski",
            "fullName": "Chris Blazejewski",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/blazejewski.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-RI-2-lower-ocd-person-a9ba95ac-22f3-4aeb-8fdd-eb236675d8d4",
              "builtID": "ri-lower-2",
              "externalID": "ocd-person/a9ba95ac-22f3-4aeb-8fdd-eb236675d8d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-blazejewski@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/blazejewski/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/blazejewski/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-2-lower-ocd-person-a9ba95ac-22f3-4aeb-8fdd-eb236675d8d4": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/39bdc05c-bda5-4ea9-b4ad-4cc7172adf56",
            "firstName": "Nathan",
            "lastName": "Biah",
            "fullName": "Nathan Biah",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/biah.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-RI-3-lower-ocd-person-39bdc05c-bda5-4ea9-b4ad-4cc7172adf56",
              "builtID": "ri-lower-3",
              "externalID": "ocd-person/39bdc05c-bda5-4ea9-b4ad-4cc7172adf56",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-biah@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/biah/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/biah/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-3-lower-ocd-person-39bdc05c-bda5-4ea9-b4ad-4cc7172adf56": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/dd5636ac-e9a1-4d98-8f21-6376b8977706",
            "firstName": "Rebecca",
            "lastName": "Kislak",
            "fullName": "Rebecca Kislak",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/kislak.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-RI-4-lower-ocd-person-dd5636ac-e9a1-4d98-8f21-6376b8977706",
              "builtID": "ri-lower-4",
              "externalID": "ocd-person/dd5636ac-e9a1-4d98-8f21-6376b8977706",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-kislak@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/kislak/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/kislak/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-4-lower-ocd-person-dd5636ac-e9a1-4d98-8f21-6376b8977706": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/b9359c6a-fbb2-4165-af9b-a6c63290d851",
            "firstName": "Anthony J.",
            "lastName": "DeSimone",
            "fullName": "Anthony DeSimone",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/representatives/Pictures/DeSimone.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-RI-5-lower-ocd-person-b9359c6a-fbb2-4165-af9b-a6c63290d851",
              "builtID": "ri-lower-5",
              "externalID": "ocd-person/b9359c6a-fbb2-4165-af9b-a6c63290d851",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-desimone@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/disemonea/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/desimone/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-5-lower-ocd-person-b9359c6a-fbb2-4165-af9b-a6c63290d851": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/da9a4b15-1cbf-4645-bd98-58ce413b68e1",
            "firstName": "Raymond A.",
            "lastName": "Hull",
            "fullName": "Raymond Hull",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/hull.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-RI-6-lower-ocd-person-da9a4b15-1cbf-4645-bd98-58ce413b68e1",
              "builtID": "ri-lower-6",
              "externalID": "ocd-person/da9a4b15-1cbf-4645-bd98-58ce413b68e1",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-hull@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/hull/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/hull/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-6-lower-ocd-person-da9a4b15-1cbf-4645-bd98-58ce413b68e1": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/75bd1298-3fc9-42cc-87c6-23bdc20a7b56",
            "firstName": "David",
            "lastName": "Morales",
            "fullName": "David Morales",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/Morales.JPG",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-RI-7-lower-ocd-person-75bd1298-3fc9-42cc-87c6-23bdc20a7b56",
              "builtID": "ri-lower-7",
              "externalID": "ocd-person/75bd1298-3fc9-42cc-87c6-23bdc20a7b56",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-morales@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/morales/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/morales/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-7-lower-ocd-person-75bd1298-3fc9-42cc-87c6-23bdc20a7b56": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/f9877000-1919-4f5b-a8b7-f05a1092bdab",
            "firstName": "John Joseph",
            "lastName": "Lombardi",
            "fullName": "John Lombardi",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/lombardi.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-RI-8-lower-ocd-person-f9877000-1919-4f5b-a8b7-f05a1092bdab",
              "builtID": "ri-lower-8",
              "externalID": "ocd-person/f9877000-1919-4f5b-a8b7-f05a1092bdab",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-lombardi@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/lombardi/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/lombardi/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-8-lower-ocd-person-f9877000-1919-4f5b-a8b7-f05a1092bdab": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/e70e3449-7587-4a64-a940-922d543f5a2d",
            "firstName": "Enrique George",
            "lastName": "Sanchez",
            "fullName": "Enrique Sanchez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/representatives/Pictures/Sanchez.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-RI-9-lower-ocd-person-e70e3449-7587-4a64-a940-922d543f5a2d",
              "builtID": "ri-lower-9",
              "externalID": "ocd-person/e70e3449-7587-4a64-a940-922d543f5a2d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-sanchez@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/sancheze/Pages/biography.aspx",
                "https://www.rilegislature.gov/representatives/sanchez/Pages/biography.aspx",
                "https://www.rilegislature.gov/representatives/Sanchez/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-9-lower-ocd-person-e70e3449-7587-4a64-a940-922d543f5a2d": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/1cd78f67-6adb-47c6-98cd-f49ca8eb603f",
            "firstName": "Scott A.",
            "lastName": "Slater",
            "fullName": "Scott Slater",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/slater.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-RI-10-lower-ocd-person-1cd78f67-6adb-47c6-98cd-f49ca8eb603f",
              "builtID": "ri-lower-10",
              "externalID": "ocd-person/1cd78f67-6adb-47c6-98cd-f49ca8eb603f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-slater@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/slater/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/slater/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-10-lower-ocd-person-1cd78f67-6adb-47c6-98cd-f49ca8eb603f": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/44424388-03a6-452b-992b-3c082949f62f",
            "firstName": "Grace",
            "lastName": "Diaz",
            "fullName": "Grace Diaz",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/diaz.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-RI-11-lower-ocd-person-44424388-03a6-452b-992b-3c082949f62f",
              "builtID": "ri-lower-11",
              "externalID": "ocd-person/44424388-03a6-452b-992b-3c082949f62f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-diaz@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/diaz/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/diaz/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-11-lower-ocd-person-44424388-03a6-452b-992b-3c082949f62f": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/be85e7a9-0455-4e02-92f7-834710d0e401",
            "firstName": "Jose F.",
            "lastName": "Batista",
            "fullName": "Jose Batista",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/batista.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-RI-12-lower-ocd-person-be85e7a9-0455-4e02-92f7-834710d0e401",
              "builtID": "ri-lower-12",
              "externalID": "ocd-person/be85e7a9-0455-4e02-92f7-834710d0e401",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-batista@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/batista/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/batista/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-12-lower-ocd-person-be85e7a9-0455-4e02-92f7-834710d0e401": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/181465b6-b392-4222-acec-456182d23aa5",
            "firstName": "Ramon A.",
            "lastName": "Perez",
            "fullName": "Ramon Perez",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/perez.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-RI-13-lower-ocd-person-181465b6-b392-4222-acec-456182d23aa5",
              "builtID": "ri-lower-13",
              "externalID": "ocd-person/181465b6-b392-4222-acec-456182d23aa5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-perez@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/perez/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/perez/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-13-lower-ocd-person-181465b6-b392-4222-acec-456182d23aa5": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/8fad7738-c303-432e-9442-ce2b6dfe87e5",
            "firstName": "Charlene M.",
            "lastName": "Lima",
            "fullName": "Charlene Lima",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/lima.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-RI-14-lower-ocd-person-8fad7738-c303-432e-9442-ce2b6dfe87e5",
              "builtID": "ri-lower-14",
              "externalID": "ocd-person/8fad7738-c303-432e-9442-ce2b6dfe87e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-lima@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/lima/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/lima/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-14-lower-ocd-person-8fad7738-c303-432e-9442-ce2b6dfe87e5": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/1b484c91-da0b-497b-b7ec-60b20593a0a1",
            "firstName": "Barbara Ann",
            "lastName": "Fenton-Fung",
            "fullName": "Barbara Ann Fenton-Fung",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.rilegislature.gov/representatives/Pictures/fenton-fung.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-RI-15-lower-ocd-person-1b484c91-da0b-497b-b7ec-60b20593a0a1",
              "builtID": "ri-lower-15",
              "externalID": "ocd-person/1b484c91-da0b-497b-b7ec-60b20593a0a1",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-fenton-fung@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/fenton-fung/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/fenton-fung/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-15-lower-ocd-person-1b484c91-da0b-497b-b7ec-60b20593a0a1": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/6ddaaa6f-48c9-468e-a744-714728251586",
            "firstName": "Brandon",
            "lastName": "Potter",
            "fullName": "Brandon Potter",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/potter.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-RI-16-lower-ocd-person-6ddaaa6f-48c9-468e-a744-714728251586",
              "builtID": "ri-lower-16",
              "externalID": "ocd-person/6ddaaa6f-48c9-468e-a744-714728251586",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-potter@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/potter/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/potter/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-16-lower-ocd-person-6ddaaa6f-48c9-468e-a744-714728251586": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/c2b18728-5409-42b0-a789-d7f5bd717780",
            "firstName": "Jacquelyn M.",
            "lastName": "Baginski",
            "fullName": "Jackie Baginski",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/Baginski.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-RI-17-lower-ocd-person-c2b18728-5409-42b0-a789-d7f5bd717780",
              "builtID": "ri-lower-17",
              "externalID": "ocd-person/c2b18728-5409-42b0-a789-d7f5bd717780",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-baginski@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/baginski/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/baginski/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-17-lower-ocd-person-c2b18728-5409-42b0-a789-d7f5bd717780": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/6d4c7be7-4e68-4aa1-9262-81995fcc231e",
            "firstName": "Arthur",
            "lastName": "Handy",
            "fullName": "Arthur Handy",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/handy.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-RI-18-lower-ocd-person-6d4c7be7-4e68-4aa1-9262-81995fcc231e",
              "builtID": "ri-lower-18",
              "externalID": "ocd-person/6d4c7be7-4e68-4aa1-9262-81995fcc231e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-handy@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/handy/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/handy/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-18-lower-ocd-person-6d4c7be7-4e68-4aa1-9262-81995fcc231e": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/ce92c860-ad04-4cc5-91eb-52add74c98e5",
            "firstName": "Joseph M.",
            "lastName": "McNamara",
            "fullName": "Joseph McNamara",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/mcnamara.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-RI-19-lower-ocd-person-ce92c860-ad04-4cc5-91eb-52add74c98e5",
              "builtID": "ri-lower-19",
              "externalID": "ocd-person/ce92c860-ad04-4cc5-91eb-52add74c98e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-mcnamara@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/mcnamara/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/mcnamara/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-19-lower-ocd-person-ce92c860-ad04-4cc5-91eb-52add74c98e5": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/b12f1651-fc9e-440c-9bc3-ff326505cca4",
            "firstName": "David A.",
            "lastName": "Bennett",
            "fullName": "David Bennett",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/bennett.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-RI-20-lower-ocd-person-b12f1651-fc9e-440c-9bc3-ff326505cca4",
              "builtID": "ri-lower-20",
              "externalID": "ocd-person/b12f1651-fc9e-440c-9bc3-ff326505cca4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-bennett@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/bennett/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/bennett/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-20-lower-ocd-person-b12f1651-fc9e-440c-9bc3-ff326505cca4": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/d57e5872-90a3-4da3-8b5c-193076a998e3",
            "firstName": "Camille F.J.",
            "lastName": "Vella-Wilkinson",
            "fullName": "Camille Vella-Wilkinson",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/vella-wilkinson.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-RI-21-lower-ocd-person-d57e5872-90a3-4da3-8b5c-193076a998e3",
              "builtID": "ri-lower-21",
              "externalID": "ocd-person/d57e5872-90a3-4da3-8b5c-193076a998e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-vella-wilkinson@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/vella-wilkinson/default.aspx",
                "https://www.rilegislature.gov/representatives/vella-wilkinson/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/Vella-Wilkinson/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-21-lower-ocd-person-d57e5872-90a3-4da3-8b5c-193076a998e3": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/0b700db6-fe30-4fb4-a988-05ae0d618720",
            "firstName": "Joseph J.",
            "lastName": "Solomon",
            "fullName": "Joseph Solomon",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/solomon.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-RI-22-lower-ocd-person-0b700db6-fe30-4fb4-a988-05ae0d618720",
              "builtID": "ri-lower-22",
              "externalID": "ocd-person/0b700db6-fe30-4fb4-a988-05ae0d618720",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-solomon@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/solomon/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/solomon/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-22-lower-ocd-person-0b700db6-fe30-4fb4-a988-05ae0d618720": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/00644fec-e128-4e7f-9af0-8e79d0903da3",
            "firstName": "K. Joseph",
            "lastName": "Shekarchi",
            "fullName": "Joe Shekarchi",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/shekarchi.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-RI-23-lower-ocd-person-00644fec-e128-4e7f-9af0-8e79d0903da3",
              "builtID": "ri-lower-23",
              "externalID": "ocd-person/00644fec-e128-4e7f-9af0-8e79d0903da3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-shekarchi@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/shekarchi/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/shekarchi/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-23-lower-ocd-person-00644fec-e128-4e7f-9af0-8e79d0903da3": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/c5f9a1f5-502b-41b6-8848-bfa359b1bad9",
            "firstName": "Evan P.",
            "lastName": "Shanley",
            "fullName": "Evan Shanley",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/shanley.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-RI-24-lower-ocd-person-c5f9a1f5-502b-41b6-8848-bfa359b1bad9",
              "builtID": "ri-lower-24",
              "externalID": "ocd-person/c5f9a1f5-502b-41b6-8848-bfa359b1bad9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-shanley@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/shanley/default.aspx",
                "https://www.rilegislature.gov/representatives/shanley/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/Shanley/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-24-lower-ocd-person-c5f9a1f5-502b-41b6-8848-bfa359b1bad9": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/1f8f3c9b-ba19-45de-af45-b0501d0607f0",
            "firstName": "Thomas",
            "lastName": "Noret",
            "fullName": "Tom Noret",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/noret.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-RI-25-lower-ocd-person-1f8f3c9b-ba19-45de-af45-b0501d0607f0",
              "builtID": "ri-lower-25",
              "externalID": "ocd-person/1f8f3c9b-ba19-45de-af45-b0501d0607f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-noret@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/noret/default.aspx",
                "https://www.rilegislature.gov/representatives/noret/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/Noret/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-25-lower-ocd-person-1f8f3c9b-ba19-45de-af45-b0501d0607f0": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/f7363aea-836d-4e8f-bdb7-8f42687d084c",
            "firstName": "Patricia L.",
            "lastName": "Morgan",
            "fullName": "Patricia Morgan",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.rilegislature.gov/representatives/pictures/morgan.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-RI-26-lower-ocd-person-f7363aea-836d-4e8f-bdb7-8f42687d084c",
              "builtID": "ri-lower-26",
              "externalID": "ocd-person/f7363aea-836d-4e8f-bdb7-8f42687d084c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-morgan@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/morgan/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/morgan/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-26-lower-ocd-person-f7363aea-836d-4e8f-bdb7-8f42687d084c": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/45fd70a7-613e-405d-959f-2900eea0f264",
            "firstName": "Patricia A.",
            "lastName": "Serpa",
            "fullName": "Pat Serpa",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/serpa.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-RI-27-lower-ocd-person-45fd70a7-613e-405d-959f-2900eea0f264",
              "builtID": "ri-lower-27",
              "externalID": "ocd-person/45fd70a7-613e-405d-959f-2900eea0f264",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-serpa@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/serpa/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/serpa/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-27-lower-ocd-person-45fd70a7-613e-405d-959f-2900eea0f264": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/5d976bb8-2481-45d2-bae9-df4b565d9526",
            "firstName": "George A.",
            "lastName": "Nardone",
            "fullName": "George Nardone",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.rilegislature.gov/representatives/pictures/nardone.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-RI-28-lower-ocd-person-5d976bb8-2481-45d2-bae9-df4b565d9526",
              "builtID": "ri-lower-28",
              "externalID": "ocd-person/5d976bb8-2481-45d2-bae9-df4b565d9526",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-nardone@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/nardone/default.aspx",
                "https://www.rilegislature.gov/representatives/nardone/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/Nardone/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-28-lower-ocd-person-5d976bb8-2481-45d2-bae9-df4b565d9526": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/6c7285bd-fdd3-4629-be6f-4a01d19e9c25",
            "firstName": "Sherry",
            "lastName": "Roberts",
            "fullName": "Sherry Roberts",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.rilegislature.gov/representatives/pictures/roberts.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-RI-29-lower-ocd-person-6c7285bd-fdd3-4629-be6f-4a01d19e9c25",
              "builtID": "ri-lower-29",
              "externalID": "ocd-person/6c7285bd-fdd3-4629-be6f-4a01d19e9c25",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-roberts@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/roberts/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/roberts/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-29-lower-ocd-person-6c7285bd-fdd3-4629-be6f-4a01d19e9c25": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/38c539f2-0698-4fa2-a761-477608365838",
            "firstName": "Justine A.",
            "lastName": "Caldwell",
            "fullName": "Justine Caldwell",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/caldwell.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-RI-30-lower-ocd-person-38c539f2-0698-4fa2-a761-477608365838",
              "builtID": "ri-lower-30",
              "externalID": "ocd-person/38c539f2-0698-4fa2-a761-477608365838",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-caldwell@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/caldwell/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/caldwell/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-30-lower-ocd-person-38c539f2-0698-4fa2-a761-477608365838": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/6e1cd170-ab5c-4095-9fb2-8e92138f6db3",
            "firstName": "Julie A.",
            "lastName": "Casimiro",
            "fullName": "Julie Casimiro",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/casimiro.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-RI-31-lower-ocd-person-6e1cd170-ab5c-4095-9fb2-8e92138f6db3",
              "builtID": "ri-lower-31",
              "externalID": "ocd-person/6e1cd170-ab5c-4095-9fb2-8e92138f6db3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-casimiro@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/casimiro/default.aspx",
                "https://www.rilegislature.gov/representatives/casimiro/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/Casimiro/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-31-lower-ocd-person-6e1cd170-ab5c-4095-9fb2-8e92138f6db3": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/a6d519b0-89bd-45d5-9067-3ae0b421eee7",
            "firstName": "Robert E.",
            "lastName": "Craven",
            "fullName": "Robert Craven",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/craven.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-RI-32-lower-ocd-person-a6d519b0-89bd-45d5-9067-3ae0b421eee7",
              "builtID": "ri-lower-32",
              "externalID": "ocd-person/a6d519b0-89bd-45d5-9067-3ae0b421eee7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-craven@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/craven/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/craven/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-32-lower-ocd-person-a6d519b0-89bd-45d5-9067-3ae0b421eee7": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/7daab32c-5d7f-4db2-b141-026d3595f69f",
            "firstName": "Carol Hagan",
            "lastName": "McEntee",
            "fullName": "Carol McEntee",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/McEntee.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-RI-33-lower-ocd-person-7daab32c-5d7f-4db2-b141-026d3595f69f",
              "builtID": "ri-lower-33",
              "externalID": "ocd-person/7daab32c-5d7f-4db2-b141-026d3595f69f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-mcentee@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/mcentee/default.aspx",
                "https://www.rilegislature.gov/representatives/mcentee/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/McEntee/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-33-lower-ocd-person-7daab32c-5d7f-4db2-b141-026d3595f69f": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/bdccc3f1-4b2d-4f64-8653-ada4322d6a10",
            "firstName": "Teresa Ann",
            "lastName": "Tanzi",
            "fullName": "Teresa Tanzi",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/tanzi.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-RI-34-lower-ocd-person-bdccc3f1-4b2d-4f64-8653-ada4322d6a10",
              "builtID": "ri-lower-34",
              "externalID": "ocd-person/bdccc3f1-4b2d-4f64-8653-ada4322d6a10",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-tanzi@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/tanzi/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/tanzi/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-34-lower-ocd-person-bdccc3f1-4b2d-4f64-8653-ada4322d6a10": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/570c899e-34b2-4e30-93e2-79f588ff8aef",
            "firstName": "Kathleen A.",
            "lastName": "Fogarty",
            "fullName": "Kathleen Fogarty",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/fogarty.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-RI-35-lower-ocd-person-570c899e-34b2-4e30-93e2-79f588ff8aef",
              "builtID": "ri-lower-35",
              "externalID": "ocd-person/570c899e-34b2-4e30-93e2-79f588ff8aef",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-fogarty@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/fogarty/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/fogarty/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-35-lower-ocd-person-570c899e-34b2-4e30-93e2-79f588ff8aef": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/2c3d82e7-8bdc-4da9-bbab-e42bb5e223fb",
            "firstName": "Tina L.",
            "lastName": "Spears",
            "fullName": "Tina Spears",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/representatives/Pictures/Tina%20Spears.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-RI-36-lower-ocd-person-2c3d82e7-8bdc-4da9-bbab-e42bb5e223fb",
              "builtID": "ri-lower-36",
              "externalID": "ocd-person/2c3d82e7-8bdc-4da9-bbab-e42bb5e223fb",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-spears@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/spearst/pages/biography.aspx",
                "https://www.rilegislature.gov/representatives/spears/pages/biography.aspx",
                "https://www.rilegislature.gov/representatives/spears/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-36-lower-ocd-person-2c3d82e7-8bdc-4da9-bbab-e42bb5e223fb": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/c9229f5a-b4ac-4cf2-b227-a0b4e56cca2f",
            "firstName": "Samuel A.",
            "lastName": "Azzinaro",
            "fullName": "Samuel Azzinaro",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/azzinaro.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-RI-37-lower-ocd-person-c9229f5a-b4ac-4cf2-b227-a0b4e56cca2f",
              "builtID": "ri-lower-37",
              "externalID": "ocd-person/c9229f5a-b4ac-4cf2-b227-a0b4e56cca2f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-azzinaro@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/azzinaro/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/azzinaro/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-37-lower-ocd-person-c9229f5a-b4ac-4cf2-b227-a0b4e56cca2f": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/632b3bf0-a3f8-4c0d-9818-d03c861318dc",
            "firstName": "Brian Patrick",
            "lastName": "Kennedy",
            "fullName": "Brian Kennedy",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/kennedy.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-RI-38-lower-ocd-person-632b3bf0-a3f8-4c0d-9818-d03c861318dc",
              "builtID": "ri-lower-38",
              "externalID": "ocd-person/632b3bf0-a3f8-4c0d-9818-d03c861318dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-kennedy@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/kennedy/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/kennedy/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-38-lower-ocd-person-632b3bf0-a3f8-4c0d-9818-d03c861318dc": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/4ad02cf0-3ead-4bc4-84e3-d1c08c3aa11a",
            "firstName": "Megan L.",
            "lastName": "Cotter",
            "fullName": "Megan Cotter",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/representatives/Pictures/Cotter.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-RI-39-lower-ocd-person-4ad02cf0-3ead-4bc4-84e3-d1c08c3aa11a",
              "builtID": "ri-lower-39",
              "externalID": "ocd-person/4ad02cf0-3ead-4bc4-84e3-d1c08c3aa11a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-cotter@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/cotterm/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/cotter/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/cotter/Pages/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-39-lower-ocd-person-4ad02cf0-3ead-4bc4-84e3-d1c08c3aa11a": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/02f4f018-3639-4fb1-aced-f8684161121d",
            "firstName": "Michael W.",
            "lastName": "Chippendale",
            "fullName": "Michael Chippendale",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.rilegislature.gov/representatives/pictures/chippendale.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-RI-40-lower-ocd-person-02f4f018-3639-4fb1-aced-f8684161121d",
              "builtID": "ri-lower-40",
              "externalID": "ocd-person/02f4f018-3639-4fb1-aced-f8684161121d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-chippendale@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/chippendale/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/chippendale/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-40-lower-ocd-person-02f4f018-3639-4fb1-aced-f8684161121d": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/11bc8a51-bee7-422d-8bb7-6a9177e55735",
            "firstName": "Robert J.",
            "lastName": "Quattrocchi",
            "fullName": "Bob Quattrocchi",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.rilegislature.gov/representatives/Pictures/quattrocchi.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-RI-41-lower-ocd-person-11bc8a51-bee7-422d-8bb7-6a9177e55735",
              "builtID": "ri-lower-41",
              "externalID": "ocd-person/11bc8a51-bee7-422d-8bb7-6a9177e55735",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-quattrocchi@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/quattrocchi/default.aspx",
                "https://www.rilegislature.gov/representatives/quattrocchi/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/Quattrocchi/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-41-lower-ocd-person-11bc8a51-bee7-422d-8bb7-6a9177e55735": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/64e906f4-f52c-4168-a830-3a4249d0f603",
            "firstName": "Edward",
            "lastName": "Cardillo",
            "fullName": "Edward Cardillo",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/cardillo.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-RI-42-lower-ocd-person-64e906f4-f52c-4168-a830-3a4249d0f603",
              "builtID": "ri-lower-42",
              "externalID": "ocd-person/64e906f4-f52c-4168-a830-3a4249d0f603",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-cardillo@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/cardillo/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/cardillo/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-42-lower-ocd-person-64e906f4-f52c-4168-a830-3a4249d0f603": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/3efcf2f9-f7f8-4441-9ec7-4f6d8c17940f",
            "firstName": "Deborah A.",
            "lastName": "Fellela",
            "fullName": "Deb Fellela",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/fellela.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-RI-43-lower-ocd-person-3efcf2f9-f7f8-4441-9ec7-4f6d8c17940f",
              "builtID": "ri-lower-43",
              "externalID": "ocd-person/3efcf2f9-f7f8-4441-9ec7-4f6d8c17940f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-fellela@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/fellela/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/fellela/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-43-lower-ocd-person-3efcf2f9-f7f8-4441-9ec7-4f6d8c17940f": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/4b45cbc5-99f3-4a08-a154-cac0cc90ae1a",
            "firstName": "Gregory J.",
            "lastName": "Costantino",
            "fullName": "Greg Costantino",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/costantino.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-RI-44-lower-ocd-person-4b45cbc5-99f3-4a08-a154-cac0cc90ae1a",
              "builtID": "ri-lower-44",
              "externalID": "ocd-person/4b45cbc5-99f3-4a08-a154-cac0cc90ae1a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-costantino@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/costantino/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/costantino/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-44-lower-ocd-person-4b45cbc5-99f3-4a08-a154-cac0cc90ae1a": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/7c4d8cad-55e5-474d-ac05-8db9a59da7fd",
            "firstName": "Mia A.",
            "lastName": "Ackerman",
            "fullName": "Mia Ackerman",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/ackerman.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-RI-45-lower-ocd-person-7c4d8cad-55e5-474d-ac05-8db9a59da7fd",
              "builtID": "ri-lower-45",
              "externalID": "ocd-person/7c4d8cad-55e5-474d-ac05-8db9a59da7fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-ackerman@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/ackerman/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/ackerman/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-45-lower-ocd-person-7c4d8cad-55e5-474d-ac05-8db9a59da7fd": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/20543a6c-f763-4297-a19b-c670fbe8a68b",
            "firstName": "Mary Ann",
            "lastName": "Shallcross-Smith",
            "fullName": "Mary Ann Shallcross-Smith",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/shallcross%20smith.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-RI-46-lower-ocd-person-20543a6c-f763-4297-a19b-c670fbe8a68b",
              "builtID": "ri-lower-46",
              "externalID": "ocd-person/20543a6c-f763-4297-a19b-c670fbe8a68b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-shallcross-smith@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/shallcrosssmith/default.aspx",
                "https://www.rilegislature.gov/representatives/shallcross%20smith/default.aspx",
                "https://www.rilegislature.gov/representatives/shallcross%20smith/default.aspx",
                "https://www.rilegislature.gov/representatives/shallcross%20smith/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-46-lower-ocd-person-20543a6c-f763-4297-a19b-c670fbe8a68b": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/785df766-de25-496b-a823-e4ad64c462cb",
            "firstName": "David",
            "lastName": "Place",
            "fullName": "David Place",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.rilegislature.gov/representatives/pictures/place.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-RI-47-lower-ocd-person-785df766-de25-496b-a823-e4ad64c462cb",
              "builtID": "ri-lower-47",
              "externalID": "ocd-person/785df766-de25-496b-a823-e4ad64c462cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-place@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/place/default.aspx",
                "https://www.rilegislature.gov/representatives/place/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/Place/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-47-lower-ocd-person-785df766-de25-496b-a823-e4ad64c462cb": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/397f8729-c37c-4882-af7e-d7235e9f06f2",
            "firstName": "Brian C.",
            "lastName": "Newberry",
            "fullName": "Brian Newberry",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.rilegislature.gov/representatives/pictures/newberry.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-RI-48-lower-ocd-person-397f8729-c37c-4882-af7e-d7235e9f06f2",
              "builtID": "ri-lower-48",
              "externalID": "ocd-person/397f8729-c37c-4882-af7e-d7235e9f06f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-newberry@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/newberry/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/newberry/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-48-lower-ocd-person-397f8729-c37c-4882-af7e-d7235e9f06f2": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/89e9f600-0af5-471c-89bb-b27142def4c9",
            "firstName": "Jon D.",
            "lastName": "Brien",
            "fullName": "Jon Brien",
            "gender": "Male",
            "party": "independent",
            "image": "https://www.rilegislature.gov/representatives/Pictures/Brien.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-RI-49-lower-ocd-person-89e9f600-0af5-471c-89bb-b27142def4c9",
              "builtID": "ri-lower-49",
              "externalID": "ocd-person/89e9f600-0af5-471c-89bb-b27142def4c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-brien@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/brienj/Pages/biography.aspx",
                "https://www.rilegislature.gov/representatives/brien/Pages/biography.aspx",
                "https://www.rilegislature.gov/representatives/brien/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-49-lower-ocd-person-89e9f600-0af5-471c-89bb-b27142def4c9": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/0b20a11e-e90b-4ff2-aa25-5334a8592071",
            "firstName": "Stephen M.",
            "lastName": "Casey",
            "fullName": "Stephen Casey",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/casey.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-RI-50-lower-ocd-person-0b20a11e-e90b-4ff2-aa25-5334a8592071",
              "builtID": "ri-lower-50",
              "externalID": "ocd-person/0b20a11e-e90b-4ff2-aa25-5334a8592071",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-casey@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/casey/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/casey/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-50-lower-ocd-person-0b20a11e-e90b-4ff2-aa25-5334a8592071": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/53f07987-a534-4194-ba51-2fcd62f0f63e",
            "firstName": "Robert D.",
            "lastName": "Phillips",
            "fullName": "Bob Phillips",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/representatives/pictures/phillips.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-RI-51-lower-ocd-person-53f07987-a534-4194-ba51-2fcd62f0f63e",
              "builtID": "ri-lower-51",
              "externalID": "ocd-person/53f07987-a534-4194-ba51-2fcd62f0f63e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-phillips@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/phillips/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-51-lower-ocd-person-53f07987-a534-4194-ba51-2fcd62f0f63e": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/7769ff96-3f6e-4747-b14b-de0a8648af61",
            "firstName": "Alex",
            "lastName": "Marszalkowski",
            "fullName": "Alex Marszalkowski",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/marszalkowski.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-RI-52-lower-ocd-person-7769ff96-3f6e-4747-b14b-de0a8648af61",
              "builtID": "ri-lower-52",
              "externalID": "ocd-person/7769ff96-3f6e-4747-b14b-de0a8648af61",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-marszalkowski@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/Marszalkowski/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/Marszalkowski/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-52-lower-ocd-person-7769ff96-3f6e-4747-b14b-de0a8648af61": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/5235f8ee-0724-4a9f-85c4-fee2580e3504",
            "firstName": "Brian J.",
            "lastName": "Rea",
            "fullName": "Brian Rea",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.rilegislature.gov/representatives/Pictures/Rea.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-RI-53-lower-ocd-person-5235f8ee-0724-4a9f-85c4-fee2580e3504",
              "builtID": "ri-lower-53",
              "externalID": "ocd-person/5235f8ee-0724-4a9f-85c4-fee2580e3504",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-rea@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/reab/pages/biography.aspx",
                "https://www.rilegislature.gov/representatives/rea/pages/biography.aspx",
                "https://www.rilegislature.gov/representatives/rea/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-53-lower-ocd-person-5235f8ee-0724-4a9f-85c4-fee2580e3504": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/4352dbd3-90e5-4cf3-9bf6-accff7592c33",
            "firstName": "William W.",
            "lastName": "O'Brien",
            "fullName": "Bill O'Brien",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/o'brien.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-RI-54-lower-ocd-person-4352dbd3-90e5-4cf3-9bf6-accff7592c33",
              "builtID": "ri-lower-54",
              "externalID": "ocd-person/4352dbd3-90e5-4cf3-9bf6-accff7592c33",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-obrien@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/obrien/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/obrien/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-54-lower-ocd-person-4352dbd3-90e5-4cf3-9bf6-accff7592c33": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/6cb1ca03-2ea8-4ee8-9ecb-faa37f4c5966",
            "firstName": "Arthur J.",
            "lastName": "Corvese",
            "fullName": "Doc Corvese",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/corvese.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-RI-55-lower-ocd-person-6cb1ca03-2ea8-4ee8-9ecb-faa37f4c5966",
              "builtID": "ri-lower-55",
              "externalID": "ocd-person/6cb1ca03-2ea8-4ee8-9ecb-faa37f4c5966",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-corvese@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/corvese/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/corvese/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-55-lower-ocd-person-6cb1ca03-2ea8-4ee8-9ecb-faa37f4c5966": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/b6a9a38c-0f39-4150-b041-fd6c75f9c8ad",
            "firstName": "Joshua J.",
            "lastName": "Giraldo",
            "fullName": "Joshua Giraldo",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/Giraldo.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-RI-56-lower-ocd-person-b6a9a38c-0f39-4150-b041-fd6c75f9c8ad",
              "builtID": "ri-lower-56",
              "externalID": "ocd-person/b6a9a38c-0f39-4150-b041-fd6c75f9c8ad",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-giraldo@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/giraldo/default.aspx",
                "https://www.rilegislature.gov/representatives/giraldo/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/Giraldo/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-56-lower-ocd-person-b6a9a38c-0f39-4150-b041-fd6c75f9c8ad": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/f0206346-2c07-4316-a556-cee7fe595e9a",
            "firstName": "Brandon T.",
            "lastName": "Voas",
            "fullName": "Brandon Voas",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/representatives/Pictures/Brandon%20Voas.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-RI-57-lower-ocd-person-f0206346-2c07-4316-a556-cee7fe595e9a",
              "builtID": "ri-lower-57",
              "externalID": "ocd-person/f0206346-2c07-4316-a556-cee7fe595e9a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-voas@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/voasb/pages/biography.aspx",
                "https://www.rilegislature.gov/representatives/voas/pages/biography.aspx",
                "https://www.rilegislature.gov/representatives/voas/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-57-lower-ocd-person-f0206346-2c07-4316-a556-cee7fe595e9a": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/386a8117-95b5-441f-91d0-665effac1af4",
            "firstName": "Cherie L.",
            "lastName": "Cruz",
            "fullName": "Cherie Cruz",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/representatives/Pictures/Cruz%20preferred%20pic.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-RI-58-lower-ocd-person-386a8117-95b5-441f-91d0-665effac1af4",
              "builtID": "ri-lower-58",
              "externalID": "ocd-person/386a8117-95b5-441f-91d0-665effac1af4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-cruz@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/cruzc/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/cruz/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-58-lower-ocd-person-386a8117-95b5-441f-91d0-665effac1af4": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/c11b7057-fe39-42b6-8a38-91d9e7349118",
            "firstName": "Jennifer A.",
            "lastName": "Stewart",
            "fullName": "Jennifer Stewart",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/representatives/Pictures/Stewart.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-RI-59-lower-ocd-person-c11b7057-fe39-42b6-8a38-91d9e7349118",
              "builtID": "ri-lower-59",
              "externalID": "ocd-person/c11b7057-fe39-42b6-8a38-91d9e7349118",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-stewart@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/stewartj/pages/biography.aspx",
                "https://www.rilegislature.gov/representatives/stewart/pages/biography.aspx",
                "https://www.rilegislature.gov/representatives/stewart/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-59-lower-ocd-person-c11b7057-fe39-42b6-8a38-91d9e7349118": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/016b40cf-1be0-4e66-acdf-78a029822e1c",
            "firstName": "Karen",
            "lastName": "Alzate",
            "fullName": "Karen Alzate",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/alzate.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-RI-60-lower-ocd-person-016b40cf-1be0-4e66-acdf-78a029822e1c",
              "builtID": "ri-lower-60",
              "externalID": "ocd-person/016b40cf-1be0-4e66-acdf-78a029822e1c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-alzate@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/alzate/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/alzate/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-60-lower-ocd-person-016b40cf-1be0-4e66-acdf-78a029822e1c": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/ad453635-2239-4a63-a153-a9f925b32621",
            "firstName": "Leonela",
            "lastName": "Felix",
            "fullName": "Leo Felix",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/Felix.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-RI-61-lower-ocd-person-ad453635-2239-4a63-a153-a9f925b32621",
              "builtID": "ri-lower-61",
              "externalID": "ocd-person/ad453635-2239-4a63-a153-a9f925b32621",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-felix@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/felix/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/felix/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-61-lower-ocd-person-ad453635-2239-4a63-a153-a9f925b32621": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/cad61cfb-b3e8-417a-934e-1add601d4cba",
            "firstName": "Mary Duffy",
            "lastName": "Messier",
            "fullName": "Mary Messier",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/messier.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-RI-62-lower-ocd-person-cad61cfb-b3e8-417a-934e-1add601d4cba",
              "builtID": "ri-lower-62",
              "externalID": "ocd-person/cad61cfb-b3e8-417a-934e-1add601d4cba",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-messier@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/messier/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/messier/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-62-lower-ocd-person-cad61cfb-b3e8-417a-934e-1add601d4cba": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/bd30e693-11a2-412f-b81a-bebc78d91145",
            "firstName": "Katherine S.",
            "lastName": "Kazarian",
            "fullName": "Katie Kazarian",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/kazarian.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-RI-63-lower-ocd-person-bd30e693-11a2-412f-b81a-bebc78d91145",
              "builtID": "ri-lower-63",
              "externalID": "ocd-person/bd30e693-11a2-412f-b81a-bebc78d91145",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-kazarian@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/kazarian/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/kazarian/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-63-lower-ocd-person-bd30e693-11a2-412f-b81a-bebc78d91145": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/d39c4994-3b73-43d8-bbe2-90a96a641a31",
            "firstName": "Brianna Escelia",
            "lastName": "Henries",
            "fullName": "Brianna Henries",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/Henries.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-RI-64-lower-ocd-person-d39c4994-3b73-43d8-bbe2-90a96a641a31",
              "builtID": "ri-lower-64",
              "externalID": "ocd-person/d39c4994-3b73-43d8-bbe2-90a96a641a31",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-henries@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/henries/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/henries/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-64-lower-ocd-person-d39c4994-3b73-43d8-bbe2-90a96a641a31": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/bffdb553-5c5c-403e-8e2c-47b34a7b50f5",
            "firstName": "Matthew S.",
            "lastName": "Dawson",
            "fullName": "Matthew Dawson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.lynchpine.com/wp-content/uploads/sites/1101520/2020/04/dawson_matthew_s.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-RI-65-lower-ocd-person-bffdb553-5c5c-403e-8e2c-47b34a7b50f5",
              "builtID": "ri-lower-65",
              "externalID": "ocd-person/bffdb553-5c5c-403e-8e2c-47b34a7b50f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-dawson@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/dawsonm/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/dawson/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-65-lower-ocd-person-bffdb553-5c5c-403e-8e2c-47b34a7b50f5": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/32458c0f-8b82-4f49-a191-90d68c3f375f",
            "firstName": "Jennifer Smith",
            "lastName": "Boylan",
            "fullName": "Jennifer Boylan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/representatives/Pictures/Jennifer%20Boylan.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-RI-66-lower-ocd-person-32458c0f-8b82-4f49-a191-90d68c3f375f",
              "builtID": "ri-lower-66",
              "externalID": "ocd-person/32458c0f-8b82-4f49-a191-90d68c3f375f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-boylan@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/boylanj/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/boylan/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-66-lower-ocd-person-32458c0f-8b82-4f49-a191-90d68c3f375f": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/cef11d28-cbbb-4ffd-b8f2-23c71e73f1b3",
            "firstName": "Jason P.",
            "lastName": "Knight",
            "fullName": "Jason Knight",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/knight.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-RI-67-lower-ocd-person-cef11d28-cbbb-4ffd-b8f2-23c71e73f1b3",
              "builtID": "ri-lower-67",
              "externalID": "ocd-person/cef11d28-cbbb-4ffd-b8f2-23c71e73f1b3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-knight@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/knight/default.aspx",
                "https://www.rilegislature.gov/representatives/knight/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/Knight/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-67-lower-ocd-person-cef11d28-cbbb-4ffd-b8f2-23c71e73f1b3": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/9386224e-9603-4695-8f46-a1033d8534a5",
            "firstName": "June S.",
            "lastName": "Speakman",
            "fullName": "June Speakman",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/speakman.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-RI-68-lower-ocd-person-9386224e-9603-4695-8f46-a1033d8534a5",
              "builtID": "ri-lower-68",
              "externalID": "ocd-person/9386224e-9603-4695-8f46-a1033d8534a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-speakman@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/speakman/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/speakman/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-68-lower-ocd-person-9386224e-9603-4695-8f46-a1033d8534a5": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/160de7ca-e70f-4cd4-b218-e9600c24a5aa",
            "firstName": "Susan R.",
            "lastName": "Donovan",
            "fullName": "Susan Donovan",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/donovan.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-RI-69-lower-ocd-person-160de7ca-e70f-4cd4-b218-e9600c24a5aa",
              "builtID": "ri-lower-69",
              "externalID": "ocd-person/160de7ca-e70f-4cd4-b218-e9600c24a5aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-donovan@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/donovan/default.aspx",
                "https://www.rilegislature.gov/representatives/donovan/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/Donovan/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-69-lower-ocd-person-160de7ca-e70f-4cd4-b218-e9600c24a5aa": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/85b2ef21-1f02-4d9e-9f74-a40a2e942f7c",
            "firstName": "John Gustav",
            "lastName": "Edwards",
            "fullName": "Jay Edwards",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/edwards.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-RI-70-lower-ocd-person-85b2ef21-1f02-4d9e-9f74-a40a2e942f7c",
              "builtID": "ri-lower-70",
              "externalID": "ocd-person/85b2ef21-1f02-4d9e-9f74-a40a2e942f7c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-edwards@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/edwards/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/edwards/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-70-lower-ocd-person-85b2ef21-1f02-4d9e-9f74-a40a2e942f7c": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/8318a7d4-6d81-4825-8821-4e7aed69b4dc",
            "firstName": "Michelle",
            "lastName": "McGaw",
            "fullName": "Michelle McGaw",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/Pictures/mcgaw.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-RI-71-lower-ocd-person-8318a7d4-6d81-4825-8821-4e7aed69b4dc",
              "builtID": "ri-lower-71",
              "externalID": "ocd-person/8318a7d4-6d81-4825-8821-4e7aed69b4dc",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-mcgaw@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/mcgaw/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/mcgaw/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-71-lower-ocd-person-8318a7d4-6d81-4825-8821-4e7aed69b4dc": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/0e459c44-8d77-408e-9db6-c0e0b79274fd",
            "firstName": "Terri-Denise",
            "lastName": "Cortvriend",
            "fullName": "Terri Cortvriend",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/cortvriend.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-RI-72-lower-ocd-person-0e459c44-8d77-408e-9db6-c0e0b79274fd",
              "builtID": "ri-lower-72",
              "externalID": "ocd-person/0e459c44-8d77-408e-9db6-c0e0b79274fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-cortvriend@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/cortvriend/default.aspx",
                "https://www.rilegislature.gov/representatives/cortvriend/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/Cortvriend/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-72-lower-ocd-person-0e459c44-8d77-408e-9db6-c0e0b79274fd": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/ed735cca-8f53-4304-a1ec-9e212744ec17",
            "firstName": "Marvin L.",
            "lastName": "Abney",
            "fullName": "Marvin Abney",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/abney.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-RI-73-lower-ocd-person-ed735cca-8f53-4304-a1ec-9e212744ec17",
              "builtID": "ri-lower-73",
              "externalID": "ocd-person/ed735cca-8f53-4304-a1ec-9e212744ec17",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-abney@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/default.aspx",
                "https://www.rilegislature.gov/representatives/abney/default.aspx",
                "https://www.rilegislature.gov/representatives/abney/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/Abney/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-73-lower-ocd-person-ed735cca-8f53-4304-a1ec-9e212744ec17": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/202a1def-abb3-4f77-8ef1-5c453d2d5423",
            "firstName": "Alex S.",
            "lastName": "Finkelman",
            "fullName": "Alex Finkelman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/representatives/Pictures/Alex%20Finkelman.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-RI-74-lower-ocd-person-202a1def-abb3-4f77-8ef1-5c453d2d5423",
              "builtID": "ri-lower-74",
              "externalID": "ocd-person/202a1def-abb3-4f77-8ef1-5c453d2d5423",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-finkelman@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/representatives/finkelmana/Pages/biography.aspx",
                "https://www.rilegislature.gov/representatives/finkelman/Pages/biography.aspx",
                "https://www.rilegislature.gov/representatives/finkelman/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-74-lower-ocd-person-202a1def-abb3-4f77-8ef1-5c453d2d5423": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/320f08df-f7d3-4ebe-a696-9c2b8d98f75b",
            "firstName": "Lauren H.",
            "lastName": "Carson",
            "fullName": "Lauren Carson",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/representatives/pictures/carson.jpg",
            "title": "RI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-RI-75-lower-ocd-person-320f08df-f7d3-4ebe-a696-9c2b8d98f75b",
              "builtID": "ri-lower-75",
              "externalID": "ocd-person/320f08df-f7d3-4ebe-a696-9c2b8d98f75b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep-carson@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/representatives/carson/",
                "https://www.rilegislature.gov/representatives/carson/Pages/Biography.aspx",
                "https://www.rilegislature.gov/representatives/carson/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-RI-75-lower-ocd-person-320f08df-f7d3-4ebe-a696-9c2b8d98f75b": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/e9b6ecf5-de39-4026-83fa-3766ed43cc49",
            "firstName": "Jacob",
            "lastName": "Bissaillon",
            "fullName": "Jake Bissaillon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/senators/Pictures/Bissaillon%20NEW.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-RI-1-upper-ocd-person-e9b6ecf5-de39-4026-83fa-3766ed43cc49",
              "builtID": "ri-upper-1",
              "externalID": "ocd-person/e9b6ecf5-de39-4026-83fa-3766ed43cc49",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-goodwin@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/senators/bissaillon/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-1-upper-ocd-person-e9b6ecf5-de39-4026-83fa-3766ed43cc49": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/a857e27b-7ced-43ad-bd08-a954e5f781ac",
            "firstName": "Ana B.",
            "lastName": "Quezada",
            "fullName": "Ana Quezada",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/senators/pictures/quezada.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-RI-2-upper-ocd-person-a857e27b-7ced-43ad-bd08-a954e5f781ac",
              "builtID": "ri-upper-2",
              "externalID": "ocd-person/a857e27b-7ced-43ad-bd08-a954e5f781ac",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-quezada@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/quezada/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/Quezada/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-2-upper-ocd-person-a857e27b-7ced-43ad-bd08-a954e5f781ac": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/3a4fd015-1c56-4d7b-9e57-1a2a6b9b4c9b",
            "firstName": "Samuel D.",
            "lastName": "Zurier",
            "fullName": "Sam Zurier",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/senators/Pictures/Zurier-2022.jpg?csf=1&e=5y4LAP",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-RI-3-upper-ocd-person-3a4fd015-1c56-4d7b-9e57-1a2a6b9b4c9b",
              "builtID": "ri-upper-3",
              "externalID": "ocd-person/3a4fd015-1c56-4d7b-9e57-1a2a6b9b4c9b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-zurier@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/senators/zurier/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-3-upper-ocd-person-3a4fd015-1c56-4d7b-9e57-1a2a6b9b4c9b": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/ff074cb5-653d-4dc6-8e7f-e20b43006a86",
            "firstName": "Dominick J.",
            "lastName": "Ruggerio",
            "fullName": "Dominick Ruggerio",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/images/RuggerioPIC.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-RI-4-upper-ocd-person-ff074cb5-653d-4dc6-8e7f-e20b43006a86",
              "builtID": "ri-upper-4",
              "externalID": "ocd-person/ff074cb5-653d-4dc6-8e7f-e20b43006a86",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-ruggerio@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/ruggerio/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/ruggerio/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-4-upper-ocd-person-ff074cb5-653d-4dc6-8e7f-e20b43006a86": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/8d466b6a-ea53-46bb-aa86-3131ac76533f",
            "firstName": "Samuel W.",
            "lastName": "Bell",
            "fullName": "Sam Bell",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/Senators/Pictures/bell.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-RI-5-upper-ocd-person-8d466b6a-ea53-46bb-aa86-3131ac76533f",
              "builtID": "ri-upper-5",
              "externalID": "ocd-person/8d466b6a-ea53-46bb-aa86-3131ac76533f",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-bell@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/bell/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/Bell/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-5-upper-ocd-person-8d466b6a-ea53-46bb-aa86-3131ac76533f": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/b3d5296c-05df-46f5-9a93-4caf96121a84",
            "firstName": "Tiara T.",
            "lastName": "Mack",
            "fullName": "Tiara Mack",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://media3.s-nbcnews.com/j/newscms/2020_47/3429284/201118-MAIN-tiara-mack-benjamin-branchaud-ew-1140a_41fc9dcc361b3077b5b335fab1dc4a55.fit-760w.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-RI-6-upper-ocd-person-b3d5296c-05df-46f5-9a93-4caf96121a84",
              "builtID": "ri-upper-6",
              "externalID": "ocd-person/b3d5296c-05df-46f5-9a93-4caf96121a84",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-mack@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/mack/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/mack/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-6-upper-ocd-person-b3d5296c-05df-46f5-9a93-4caf96121a84": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/d688b16c-5bf2-41df-b1c9-e8c5de7e437c",
            "firstName": "Frank A.",
            "lastName": "Ciccone",
            "fullName": "Frank Ciccone",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/Senators/Pictures/ciccone.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-RI-7-upper-ocd-person-d688b16c-5bf2-41df-b1c9-e8c5de7e437c",
              "builtID": "ri-upper-7",
              "externalID": "ocd-person/d688b16c-5bf2-41df-b1c9-e8c5de7e437c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-ciccone@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/ciccone/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/ciccone/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-7-upper-ocd-person-d688b16c-5bf2-41df-b1c9-e8c5de7e437c": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/8535397a-2fdd-4ffe-8a78-e9633f45ebf1",
            "firstName": "Sandra C.",
            "lastName": "Cano",
            "fullName": "Sandra Cano",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/senators/Pictures/cano.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-RI-8-upper-ocd-person-8535397a-2fdd-4ffe-8a78-e9633f45ebf1",
              "builtID": "ri-upper-8",
              "externalID": "ocd-person/8535397a-2fdd-4ffe-8a78-e9633f45ebf1",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-cano@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/cano/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/cano/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-8-upper-ocd-person-8535397a-2fdd-4ffe-8a78-e9633f45ebf1": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/a5f646b2-0370-494c-aebc-25869f019a1b",
            "firstName": "John",
            "lastName": "Burke",
            "fullName": "John Burke",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/senators/pictures/burke.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-RI-9-upper-ocd-person-a5f646b2-0370-494c-aebc-25869f019a1b",
              "builtID": "ri-upper-9",
              "externalID": "ocd-person/a5f646b2-0370-494c-aebc-25869f019a1b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-burke@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/burke/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/burke/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-9-upper-ocd-person-a5f646b2-0370-494c-aebc-25869f019a1b": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/037e3b02-7f48-4cab-bb04-2524aa9a883d",
            "firstName": "Walter S.",
            "lastName": "Felag",
            "fullName": "Walter Felag",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/senators/Pictures/Felag%202023.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-RI-10-upper-ocd-person-037e3b02-7f48-4cab-bb04-2524aa9a883d",
              "builtID": "ri-upper-10",
              "externalID": "ocd-person/037e3b02-7f48-4cab-bb04-2524aa9a883d",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-felag@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/felag/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/felag/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-10-upper-ocd-person-037e3b02-7f48-4cab-bb04-2524aa9a883d": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/825b006a-e38e-4f0c-81b7-4df73e2a90fa",
            "firstName": "Linda Lee",
            "lastName": "Ujifusa",
            "fullName": "Linda Ujifusa",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/senators/Pictures/Ujifusa%20FINAL.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-RI-11-upper-ocd-person-825b006a-e38e-4f0c-81b7-4df73e2a90fa",
              "builtID": "ri-upper-11",
              "externalID": "ocd-person/825b006a-e38e-4f0c-81b7-4df73e2a90fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-ujifusa@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/Senators/ujifusal/pages/biography.aspx",
                "https://www.rilegislature.gov/Senators/ujifusa/pages/biography.aspx",
                "https://www.rilegislature.gov/senators/ujifusa/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-11-upper-ocd-person-825b006a-e38e-4f0c-81b7-4df73e2a90fa": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/71da5365-46a9-4f2c-abc8-308bde2577ec",
            "firstName": "Louis P.",
            "lastName": "DiPalma",
            "fullName": "Lou DiPalma",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/Senators/Pictures/dipalma.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-RI-12-upper-ocd-person-71da5365-46a9-4f2c-abc8-308bde2577ec",
              "builtID": "ri-upper-12",
              "externalID": "ocd-person/71da5365-46a9-4f2c-abc8-308bde2577ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-dipalma@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/dipalma/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/dipalma/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-12-upper-ocd-person-71da5365-46a9-4f2c-abc8-308bde2577ec": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/b747e057-fdd2-480a-beb9-c4ef37ae1e12",
            "firstName": "Dawn",
            "lastName": "Euer",
            "fullName": "Dawn Euer",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/senators/pictures/euer.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-RI-13-upper-ocd-person-b747e057-fdd2-480a-beb9-c4ef37ae1e12",
              "builtID": "ri-upper-13",
              "externalID": "ocd-person/b747e057-fdd2-480a-beb9-c4ef37ae1e12",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-euer@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/euer/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/Euer/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-13-upper-ocd-person-b747e057-fdd2-480a-beb9-c4ef37ae1e12": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/bfb76f36-00b6-47da-89da-e1db5421ff55",
            "firstName": "Valarie J.",
            "lastName": "Lawson",
            "fullName": "Val Lawson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/LegislationPictures/valarie_lawson.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-RI-14-upper-ocd-person-bfb76f36-00b6-47da-89da-e1db5421ff55",
              "builtID": "ri-upper-14",
              "externalID": "ocd-person/bfb76f36-00b6-47da-89da-e1db5421ff55",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-lawson@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/lawson/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/Lawson/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-14-upper-ocd-person-bfb76f36-00b6-47da-89da-e1db5421ff55": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/2cd8c21d-f576-4060-a311-0459e01ffef6",
            "firstName": "Meghan Elizabeth",
            "lastName": "Kallman",
            "fullName": "Meghan Kallman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/senators/pictures/kallman.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-RI-15-upper-ocd-person-2cd8c21d-f576-4060-a311-0459e01ffef6",
              "builtID": "ri-upper-15",
              "externalID": "ocd-person/2cd8c21d-f576-4060-a311-0459e01ffef6",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-kallman@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/senators/kallman/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/kallman/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-15-upper-ocd-person-2cd8c21d-f576-4060-a311-0459e01ffef6": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/23ac0ada-a340-4539-a4c9-33e4c437add6",
            "firstName": "Jonathon",
            "lastName": "Acosta",
            "fullName": "Jonathon Acosta",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/senators/pictures/acosta.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-RI-16-upper-ocd-person-23ac0ada-a340-4539-a4c9-33e4c437add6",
              "builtID": "ri-upper-16",
              "externalID": "ocd-person/23ac0ada-a340-4539-a4c9-33e4c437add6",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-acosta@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/acosta/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/acosta/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-16-upper-ocd-person-23ac0ada-a340-4539-a4c9-33e4c437add6": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/285c33e3-5c40-44d8-9c16-22e49b6a0a81",
            "firstName": "Thomas J.",
            "lastName": "Paolino",
            "fullName": "Thomas Paolino",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.rilegislature.gov/senators/Pictures/Paolino%202022.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-RI-17-upper-ocd-person-285c33e3-5c40-44d8-9c16-22e49b6a0a81",
              "builtID": "ri-upper-17",
              "externalID": "ocd-person/285c33e3-5c40-44d8-9c16-22e49b6a0a81",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-paolino@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/paolino/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/Paolino/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-17-upper-ocd-person-285c33e3-5c40-44d8-9c16-22e49b6a0a81": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/01ddc920-772c-4098-b9f8-8704783940e5",
            "firstName": "Robert",
            "lastName": "Britto",
            "fullName": "Bob Britto",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/senators/Pictures/Britto%202023.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-RI-18-upper-ocd-person-01ddc920-772c-4098-b9f8-8704783940e5",
              "builtID": "ri-upper-18",
              "externalID": "ocd-person/01ddc920-772c-4098-b9f8-8704783940e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-britto@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/senators/britor/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/britto/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-18-upper-ocd-person-01ddc920-772c-4098-b9f8-8704783940e5": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/1378a3d6-95fe-4a21-8fcb-11fefdb97d84",
            "firstName": "Ryan William",
            "lastName": "Pearson",
            "fullName": "Ryan Pearson",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/Senators/Pictures/pearson.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-RI-19-upper-ocd-person-1378a3d6-95fe-4a21-8fcb-11fefdb97d84",
              "builtID": "ri-upper-19",
              "externalID": "ocd-person/1378a3d6-95fe-4a21-8fcb-11fefdb97d84",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-pearson@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/pearson/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/pearson/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-19-upper-ocd-person-1378a3d6-95fe-4a21-8fcb-11fefdb97d84": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/42151160-e080-4220-8641-853eab759b4f",
            "firstName": "Roger A.",
            "lastName": "Picard",
            "fullName": "Roger Picard",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/Senators/Pictures/picard.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-RI-20-upper-ocd-person-42151160-e080-4220-8641-853eab759b4f",
              "builtID": "ri-upper-20",
              "externalID": "ocd-person/42151160-e080-4220-8641-853eab759b4f",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-picard@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/picard/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/Picard/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-20-upper-ocd-person-42151160-e080-4220-8641-853eab759b4f": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/961061c2-4ce9-4ac4-85c4-28b20c800e9d",
            "firstName": "Gordon E.",
            "lastName": "Rogers",
            "fullName": "Gordon Rogers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.rilegislature.gov/LegislationPictures/gordon_rogers.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-RI-21-upper-ocd-person-961061c2-4ce9-4ac4-85c4-28b20c800e9d",
              "builtID": "ri-upper-21",
              "externalID": "ocd-person/961061c2-4ce9-4ac4-85c4-28b20c800e9d",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-rogers@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/rogers/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/Rogers/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-21-upper-ocd-person-961061c2-4ce9-4ac4-85c4-28b20c800e9d": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/63bb0194-1993-4149-b39d-23f229c3040e",
            "firstName": "David P.",
            "lastName": "Tikoian",
            "fullName": "David Tikoian",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/senators/Pictures/Tikoian%202023.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-RI-22-upper-ocd-person-63bb0194-1993-4149-b39d-23f229c3040e",
              "builtID": "ri-upper-22",
              "externalID": "ocd-person/63bb0194-1993-4149-b39d-23f229c3040e",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-tikoian@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/Senators/tikoiand/pages/biography.aspx",
                "https://www.rilegislature.gov/Senators/tikoian/pages/biography.aspx",
                "https://www.rilegislature.gov/senators/tikoian/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-22-upper-ocd-person-63bb0194-1993-4149-b39d-23f229c3040e": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/03fdc4f3-715d-4f83-ab31-1a63f86a4dcf",
            "firstName": "Jessica",
            "lastName": "de la Cruz",
            "fullName": "Jessica de la Cruz",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.rilegislature.gov/Senators/Pictures/de%20la%20cruz%20NEW%202022.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-RI-23-upper-ocd-person-03fdc4f3-715d-4f83-ab31-1a63f86a4dcf",
              "builtID": "ri-upper-23",
              "externalID": "ocd-person/03fdc4f3-715d-4f83-ab31-1a63f86a4dcf",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-delacruz@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/delacruz/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/de%20la%20Cruz/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-23-upper-ocd-person-03fdc4f3-715d-4f83-ab31-1a63f86a4dcf": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/e7d41b59-5619-4a67-a1ec-221fdc24e6c3",
            "firstName": "Melissa A.",
            "lastName": "Murray",
            "fullName": "Melissa Murray",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/Senators/Pictures/murray.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-RI-24-upper-ocd-person-e7d41b59-5619-4a67-a1ec-221fdc24e6c3",
              "builtID": "ri-upper-24",
              "externalID": "ocd-person/e7d41b59-5619-4a67-a1ec-221fdc24e6c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-murray@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/murray/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/Murray/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-24-upper-ocd-person-e7d41b59-5619-4a67-a1ec-221fdc24e6c3": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/e65ea2dc-d434-4c22-b156-346155e77f06",
            "firstName": "Frank S.",
            "lastName": "Lombardi",
            "fullName": "Frank Lombardi",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/Senators/Pictures/lombardi.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-RI-26-upper-ocd-person-e65ea2dc-d434-4c22-b156-346155e77f06",
              "builtID": "ri-upper-26",
              "externalID": "ocd-person/e65ea2dc-d434-4c22-b156-346155e77f06",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-lombardi@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/lombardi/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/lombardi/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-26-upper-ocd-person-e65ea2dc-d434-4c22-b156-346155e77f06": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/75750ad6-0bec-4d81-8561-85036c0d99df",
            "firstName": "Hanna M.",
            "lastName": "Gallo",
            "fullName": "Hanna Gallo",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/Senators/Pictures/gallo.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-RI-27-upper-ocd-person-75750ad6-0bec-4d81-8561-85036c0d99df",
              "builtID": "ri-upper-27",
              "externalID": "ocd-person/75750ad6-0bec-4d81-8561-85036c0d99df",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-gallo@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/gallo/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/gallo/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-27-upper-ocd-person-75750ad6-0bec-4d81-8561-85036c0d99df": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/ab7875ef-c849-4f9e-bce6-e5c42330ffb5",
            "firstName": "Joshua",
            "lastName": "Miller",
            "fullName": "Josh Miller",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/Senators/Pictures/miller.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-RI-28-upper-ocd-person-ab7875ef-c849-4f9e-bce6-e5c42330ffb5",
              "builtID": "ri-upper-28",
              "externalID": "ocd-person/ab7875ef-c849-4f9e-bce6-e5c42330ffb5",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-miller@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/miller/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/miller/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-28-upper-ocd-person-ab7875ef-c849-4f9e-bce6-e5c42330ffb5": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/89e97b3f-598f-4f81-8927-28a32afc4bbc",
            "firstName": "Anthony Phillip",
            "lastName": "DeLuca",
            "fullName": "Anthony DeLuca",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.rilegislature.gov/senators/Pictures/DeLuca%202023.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-RI-29-upper-ocd-person-89e97b3f-598f-4f81-8927-28a32afc4bbc",
              "builtID": "ri-upper-29",
              "externalID": "ocd-person/89e97b3f-598f-4f81-8927-28a32afc4bbc",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-deluca@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/Senators/delucaa/pages/biography.aspx",
                "https://www.rilegislature.gov/Senators/deluca/pages/biography.aspx",
                "https://www.rilegislature.gov/senators/deluca/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-29-upper-ocd-person-89e97b3f-598f-4f81-8927-28a32afc4bbc": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/580b2e15-faff-4b83-bbea-fb6110a9f975",
            "firstName": "Mark P.",
            "lastName": "McKenney",
            "fullName": "Mark McKenney",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/senators/Pictures/McKenney%202023.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-RI-30-upper-ocd-person-580b2e15-faff-4b83-bbea-fb6110a9f975",
              "builtID": "ri-upper-30",
              "externalID": "ocd-person/580b2e15-faff-4b83-bbea-fb6110a9f975",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-mckenney@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/senators/mckenney/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-30-upper-ocd-person-580b2e15-faff-4b83-bbea-fb6110a9f975": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/57b194bd-2b6a-46b8-a7b4-35b629bb0b04",
            "firstName": "Matthew Liam",
            "lastName": "LaMountain",
            "fullName": "Matt LaMountain",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/senators/Pictures/LaMountain%202023.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-RI-31-upper-ocd-person-57b194bd-2b6a-46b8-a7b4-35b629bb0b04",
              "builtID": "ri-upper-31",
              "externalID": "ocd-person/57b194bd-2b6a-46b8-a7b4-35b629bb0b04",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-lamountain@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/Senators/lamountainml/pages/biography.aspx",
                "https://www.rilegislature.gov/Senators/lamountain/pages/biography.aspx",
                "https://www.rilegislature.gov/senators/lamountain/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-31-upper-ocd-person-57b194bd-2b6a-46b8-a7b4-35b629bb0b04": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/c5991f11-e719-473e-93c4-cfc52fae3e6d",
            "firstName": "Pamela J.",
            "lastName": "Lauria",
            "fullName": "Pam Lauria",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/senators/Pictures/Lauria%202023.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-RI-32-upper-ocd-person-c5991f11-e719-473e-93c4-cfc52fae3e6d",
              "builtID": "ri-upper-32",
              "externalID": "ocd-person/c5991f11-e719-473e-93c4-cfc52fae3e6d",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-lauria@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/Senators/lauriapj/pages/biography.aspx",
                "https://www.rilegislature.gov/Senators/lauria/pages/biography.aspx",
                "https://www.rilegislature.gov/senators/lauria/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-32-upper-ocd-person-c5991f11-e719-473e-93c4-cfc52fae3e6d": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/ef7ddd55-3351-4ca9-adfc-aef71505ec95",
            "firstName": "Leonidas P.",
            "lastName": "Raptakis",
            "fullName": "Lou Raptakis",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/Senators/Pictures/raptakis.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-RI-33-upper-ocd-person-ef7ddd55-3351-4ca9-adfc-aef71505ec95",
              "builtID": "ri-upper-33",
              "externalID": "ocd-person/ef7ddd55-3351-4ca9-adfc-aef71505ec95",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-raptakis@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/raptakis/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/raptakis/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-33-upper-ocd-person-ef7ddd55-3351-4ca9-adfc-aef71505ec95": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/6913b936-2e96-414a-80d6-6518f5accb7e",
            "firstName": "Elaine J.",
            "lastName": "Morgan",
            "fullName": "Elaine Morgan",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.rilegislature.gov/Senators/Pictures/morgan.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-RI-34-upper-ocd-person-6913b936-2e96-414a-80d6-6518f5accb7e",
              "builtID": "ri-upper-34",
              "externalID": "ocd-person/6913b936-2e96-414a-80d6-6518f5accb7e",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-morgan@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/morgan/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/morgan/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-34-upper-ocd-person-6913b936-2e96-414a-80d6-6518f5accb7e": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/80931d0d-c52e-492a-8b92-c191aa00c03f",
            "firstName": "Bridget Geraci",
            "lastName": "Valverde",
            "fullName": "Bridget Valverde",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/Senators/Pictures/valverde.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-RI-35-upper-ocd-person-80931d0d-c52e-492a-8b92-c191aa00c03f",
              "builtID": "ri-upper-35",
              "externalID": "ocd-person/80931d0d-c52e-492a-8b92-c191aa00c03f",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-valverde@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/valverde/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/Valverde/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-35-upper-ocd-person-80931d0d-c52e-492a-8b92-c191aa00c03f": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/9dbec823-aab4-40bb-a26a-e51a896dcc1f",
            "firstName": "Alana M.",
            "lastName": "DiMario",
            "fullName": "Alana DiMario",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/senators/pictures/dimario.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-RI-36-upper-ocd-person-9dbec823-aab4-40bb-a26a-e51a896dcc1f",
              "builtID": "ri-upper-36",
              "externalID": "ocd-person/9dbec823-aab4-40bb-a26a-e51a896dcc1f",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-dimario@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/dimario/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/dimario/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-36-upper-ocd-person-9dbec823-aab4-40bb-a26a-e51a896dcc1f": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/c5757781-d437-4b9b-b7a1-9bb96c9b74ab",
            "firstName": "Virginia Susan",
            "lastName": "Sosnowski",
            "fullName": "Sue Sosnowski",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.rilegislature.gov/Senators/Pictures/sosnowski.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-RI-37-upper-ocd-person-c5757781-d437-4b9b-b7a1-9bb96c9b74ab",
              "builtID": "ri-upper-37",
              "externalID": "ocd-person/c5757781-d437-4b9b-b7a1-9bb96c9b74ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-sosnowski@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilin.state.ri.us/senators/Algiere/",
                "https://www.rilegislature.gov/senators/algiere/default.aspx",
                "https://www.rilegislature.gov/senators/sosnowski/Pages/Biography.aspx",
                "https://www.rilegislature.gov/senators/sosnowski/default.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-37-upper-ocd-person-c5757781-d437-4b9b-b7a1-9bb96c9b74ab": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/afeed427-2ef1-4197-840d-7ab06883e186",
            "firstName": "Victoria",
            "lastName": "Gu",
            "fullName": "Victoria Gu",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.rilegislature.gov/senators/Pictures/Gu%202023.jpg",
            "title": "RI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "RI",
              "stateFull": "Rhode Island",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-RI-38-upper-ocd-person-afeed427-2ef1-4197-840d-7ab06883e186",
              "builtID": "ri-upper-38",
              "externalID": "ocd-person/afeed427-2ef1-4197-840d-7ab06883e186",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen-gu@rilegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.rilegislature.gov/Senators/guvi/Pages/biography.aspx",
                "https://www.rilegislature.gov/Senators/gu/Pages/biography.aspx",
                "https://www.rilegislature.gov/senators/gu/Pages/Biography.aspx"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-RI-38-upper-ocd-person-afeed427-2ef1-4197-840d-7ab06883e186": 0
        }
      }
    }
  },
  "SC": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "SC",
          "stateFull": "South Carolina",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "SC",
            "stateFull": "South Carolina",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-SC---",
            "builtID": "sc--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-SC---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "G000359",
          "in_office": true,
          "firstName": "Lindsey",
          "lastName": "Graham",
          "middleName": null,
          "fullName": "Lindsey Graham",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/G000359.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "SC",
            "stateFull": "South Carolina",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-SC---G000359",
            "builtID": "sc--",
            "externalID": "G000359",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.lgraham.senate.gov/public/index.cfm/e-mail-senator-graham",
            "address1": "290 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-5972",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "GrahamBlog",
            "youtube": "USSenLindseyGraham",
            "instagram": null,
            "facebook": "USSenatorLindseyGraham",
            "urls": ["https://www.lgraham.senate.gov/public"],
            "rss_url": "https://www.lgraham.senate.gov/public/?a=rss.feed"
          },
          "title": "SC Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/G000359.json",
          "govtrack_id": "300047",
          "cspan_id": "36782",
          "votesmart_id": "21992",
          "icpsr_id": "29566",
          "crp_id": "N00009975",
          "google_entity_id": "/m/01_pdg",
          "state_rank": "senior",
          "lis_id": "S293",
          "suffix": null,
          "date_of_birth": "1955-07-09",
          "leadership_role": null,
          "fec_candidate_id": "S0SC00149",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "21",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 33,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:21 -0400",
          "missed_votes_pct": 5.96,
          "votes_with_party_pct": 66.34,
          "votes_against_party_pct": 33.66,
          "ocd_id": "ocd-division/country:us/state:sc"
        },
        {
          "API_ID": "S001184",
          "in_office": true,
          "firstName": "Tim",
          "lastName": "Scott",
          "middleName": null,
          "fullName": "Tim Scott",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/S001184.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "SC",
            "stateFull": "South Carolina",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-SC---S001184",
            "builtID": "sc--",
            "externalID": "S001184",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorTimScott",
            "youtube": "SenatorTimScott",
            "instagram": null,
            "facebook": "SenatorTimScott",
            "urls": ["https://www.scott.senate.gov"],
            "rss_url": null
          },
          "title": "SC Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/S001184.json",
          "govtrack_id": "412471",
          "cspan_id": "623506",
          "votesmart_id": "11940",
          "icpsr_id": "21173",
          "crp_id": "N00031782",
          "google_entity_id": "/m/0c407g6",
          "state_rank": "junior",
          "lis_id": "S365",
          "suffix": null,
          "date_of_birth": "1965-09-19",
          "leadership_role": null,
          "fec_candidate_id": "H0SC01279",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "11",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 141,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 25.45,
          "votes_with_party_pct": 94.04,
          "votes_against_party_pct": 5.96,
          "ocd_id": "ocd-division/country:us/state:sc"
        }
      ],
      "hash": { "SENATE-SC---G000359": 0, "SENATE-SC---S001184": 1 }
    },
    "HOUSE": {
      "06": {
        "members": [
          {
            "API_ID": "C000537",
            "in_office": true,
            "firstName": "James",
            "lastName": "Clyburn",
            "middleName": "E.",
            "fullName": "James E. Clyburn",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C000537.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-SC-06--C000537",
              "builtID": "sc--06",
              "externalID": "C000537",
              "geometry": null,
              "geoid": "4506"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "274 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3315",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "WhipClyburn",
              "youtube": "repjamesclyburn",
              "instagram": null,
              "facebook": "jameseclyburn",
              "urls": ["https://clyburn.house.gov"],
              "rss_url": null
            },
            "title": "SC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C000537.json",
            "govtrack_id": "400075",
            "cspan_id": "21607",
            "votesmart_id": "27066",
            "icpsr_id": "39301",
            "crp_id": "N00002408",
            "google_entity_id": "/m/03c469",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1940-07-21",
            "leadership_role": "House Majority Whip",
            "fec_candidate_id": "H2SC02042",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "32",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 9,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 96.62,
            "votes_against_party_pct": 1.54,
            "ocd_id": "ocd-division/country:us/state:sc/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-SC-06--C000537": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "D000615",
            "in_office": true,
            "firstName": "Jeffrey",
            "lastName": "Duncan",
            "middleName": null,
            "fullName": "Jeff Duncan",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/D000615.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-SC-03--D000615",
              "builtID": "sc--03",
              "externalID": "D000615",
              "geometry": null,
              "geoid": "4503"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2229 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5301",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJeffDuncan",
              "youtube": "congjeffduncan",
              "instagram": null,
              "facebook": "RepJeffDuncan",
              "urls": ["https://jeffduncan.house.gov"],
              "rss_url": "https://jeffduncan.house.gov/rss.xml"
            },
            "title": "SC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000615.json",
            "govtrack_id": "412472",
            "cspan_id": "62713",
            "votesmart_id": "47967",
            "icpsr_id": "21174",
            "crp_id": "N00030752",
            "google_entity_id": "/m/0ddgp1k",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1966-01-07",
            "leadership_role": null,
            "fec_candidate_id": "H0SC03077",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 8,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.76,
            "votes_with_party_pct": 89.77,
            "votes_against_party_pct": 8.38,
            "ocd_id": "ocd-division/country:us/state:sc/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-SC-03--D000615": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "F000478",
            "in_office": true,
            "firstName": "Russell",
            "lastName": "Fry",
            "middleName": null,
            "fullName": "Russell Fry",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/F000478.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-SC-07--F000478",
              "builtID": "sc--07",
              "externalID": "F000478",
              "geometry": null,
              "geoid": "4507"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1626 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-9895",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRussellFry",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://fry.house.gov/"],
              "rss_url": null
            },
            "title": "SC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000478.json",
            "govtrack_id": "456938",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1985-01-31",
            "leadership_role": null,
            "fec_candidate_id": "H2SC07280",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 4,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.38,
            "votes_with_party_pct": 89.62,
            "votes_against_party_pct": 8.44,
            "ocd_id": "ocd-division/country:us/state:sc/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-SC-07--F000478": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "M000194",
            "in_office": true,
            "firstName": "Nancy",
            "lastName": "Mace",
            "middleName": null,
            "fullName": "Nancy Mace",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M000194.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-SC-01--M000194",
              "builtID": "sc--01",
              "externalID": "M000194",
              "geometry": null,
              "geoid": "4501"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1728 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3176",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepNancyMace",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://mace.house.gov"],
              "rss_url": null
            },
            "title": "SC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M000194.json",
            "govtrack_id": "456843",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00035670",
            "google_entity_id": "/m/048nls",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1977-12-04",
            "leadership_role": null,
            "fec_candidate_id": "H0SC01394",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 53,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 5.02,
            "votes_with_party_pct": 82.59,
            "votes_against_party_pct": 15.38,
            "ocd_id": "ocd-division/country:us/state:sc/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-SC-01--M000194": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "N000190",
            "in_office": true,
            "firstName": "Ralph",
            "lastName": "Norman",
            "middleName": null,
            "fullName": "Ralph Norman",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/N000190.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-SC-05--N000190",
              "builtID": "sc--05",
              "externalID": "N000190",
              "geometry": null,
              "geoid": "4505"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "569 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5501",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRalphNorman",
              "youtube": null,
              "instagram": null,
              "facebook": "RepRalphNorman",
              "urls": ["https://norman.house.gov"],
              "rss_url": null
            },
            "title": "SC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/N000190.json",
            "govtrack_id": "412738",
            "cspan_id": "61996",
            "votesmart_id": "47930",
            "icpsr_id": "21753",
            "crp_id": "N00027783",
            "google_entity_id": "/m/0f9kbx",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1953-06-20",
            "leadership_role": null,
            "fec_candidate_id": "H8SC05158",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 26,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 2.46,
            "votes_with_party_pct": 83.85,
            "votes_against_party_pct": 14.17,
            "ocd_id": "ocd-division/country:us/state:sc/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-SC-05--N000190": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "T000480",
            "in_office": true,
            "firstName": "William",
            "lastName": "Timmons",
            "middleName": null,
            "fullName": "William R. Timmons IV",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/T000480.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-SC-04--T000480",
              "builtID": "sc--04",
              "externalID": "T000480",
              "geometry": null,
              "geoid": "4504"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "267 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-6030",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepTimmons",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://timmons.house.gov"],
              "rss_url": null
            },
            "title": "SC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000480.json",
            "govtrack_id": "412815",
            "cspan_id": null,
            "votesmart_id": "168923",
            "icpsr_id": null,
            "crp_id": "N00042715",
            "google_entity_id": "/g/11g6ns21_f",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1984-04-30",
            "leadership_role": null,
            "fec_candidate_id": "H8SC04250",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.57,
            "votes_with_party_pct": 92.23,
            "votes_against_party_pct": 5.83,
            "ocd_id": "ocd-division/country:us/state:sc/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-SC-04--T000480": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "W000795",
            "in_office": true,
            "firstName": "Joe",
            "lastName": "Wilson",
            "middleName": null,
            "fullName": "Joe Wilson",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/W000795.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-SC-02--W000795",
              "builtID": "sc--02",
              "externalID": "W000795",
              "geometry": null,
              "geoid": "4502"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1436 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2452",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJoeWilson",
              "youtube": "RepJoeWilson",
              "instagram": null,
              "facebook": "JoeWilson",
              "urls": ["https://joewilson.house.gov"],
              "rss_url": "https://joewilson.house.gov/rss.xml"
            },
            "title": "SC House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000795.json",
            "govtrack_id": "400433",
            "cspan_id": "1002567",
            "votesmart_id": "3985",
            "icpsr_id": "20138",
            "crp_id": "N00024809",
            "google_entity_id": "/m/03tll0",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1947-07-31",
            "leadership_role": null,
            "fec_candidate_id": "H2SC02059",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "24",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 18,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.71,
            "votes_with_party_pct": 93.03,
            "votes_against_party_pct": 5.11,
            "ocd_id": "ocd-division/country:us/state:sc/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-SC-02--W000795": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/ae3ba290-90f6-4121-874d-ba80e16ccbe7",
            "firstName": "William R.",
            "lastName": "Whitmire",
            "fullName": "Bill Whitmire",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1914204316.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-SC-1-lower-ocd-person-ae3ba290-90f6-4121-874d-ba80e16ccbe7",
              "builtID": "sc-lower-1",
              "externalID": "ocd-person/ae3ba290-90f6-4121-874d-ba80e16ccbe7",
              "geometry": null
            },
            "contactInfo": {
              "email": "billwhitmire@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1914204316",
                "https://www.scstatehouse.gov/member.php?code=1914204316"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-1-lower-ocd-person-ae3ba290-90f6-4121-874d-ba80e16ccbe7": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/85033c9a-276a-4464-a304-4fcd9a31461e",
            "firstName": "William E.",
            "lastName": "Sandifer",
            "fullName": "Bill Sandifer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1629545259.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-SC-2-lower-ocd-person-85033c9a-276a-4464-a304-4fcd9a31461e",
              "builtID": "sc-lower-2",
              "externalID": "ocd-person/85033c9a-276a-4464-a304-4fcd9a31461e",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill@sandifer.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1629545259",
                "https://www.scstatehouse.gov/member.php?code=1629545259"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-2-lower-ocd-person-85033c9a-276a-4464-a304-4fcd9a31461e": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/57efcc11-08ad-4b77-8d49-39fbbefbe5da",
            "firstName": "Jerry T.",
            "lastName": "Carter",
            "fullName": "Jerry Carter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0323863597.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-SC-3-lower-ocd-person-57efcc11-08ad-4b77-8d49-39fbbefbe5da",
              "builtID": "sc-lower-3",
              "externalID": "ocd-person/57efcc11-08ad-4b77-8d49-39fbbefbe5da",
              "geometry": null
            },
            "contactInfo": {
              "email": "jerrycarter@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0323863597",
                "https://www.scstatehouse.gov/member.php?code=0323863597"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-3-lower-ocd-person-57efcc11-08ad-4b77-8d49-39fbbefbe5da": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/14b0abeb-70fd-404b-9f55-ff2e75e2c7ef",
            "firstName": "David Rudolph",
            "lastName": "Hiott",
            "fullName": "Davey Hiott",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0848863535.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-SC-4-lower-ocd-person-14b0abeb-70fd-404b-9f55-ff2e75e2c7ef",
              "builtID": "sc-lower-4",
              "externalID": "ocd-person/14b0abeb-70fd-404b-9f55-ff2e75e2c7ef",
              "geometry": null
            },
            "contactInfo": {
              "email": "daveyhiott@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0848863535",
                "https://www.scstatehouse.gov/member.php?code=0848863535"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-4-lower-ocd-person-14b0abeb-70fd-404b-9f55-ff2e75e2c7ef": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/73b7f73e-705e-409a-b582-51060e573fd5",
            "firstName": "Neal A.",
            "lastName": "Collins",
            "fullName": "Neal Collins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0388636317.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-SC-5-lower-ocd-person-73b7f73e-705e-409a-b582-51060e573fd5",
              "builtID": "sc-lower-5",
              "externalID": "ocd-person/73b7f73e-705e-409a-b582-51060e573fd5",
              "geometry": null
            },
            "contactInfo": {
              "email": "nealcollins@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0388636317",
                "https://www.scstatehouse.gov/member.php?code=0388636317"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-5-lower-ocd-person-73b7f73e-705e-409a-b582-51060e573fd5": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/dbb8fc98-5116-44c4-8009-8c91e514dec1",
            "firstName": "April",
            "lastName": "Cromer",
            "fullName": "April Cromer",
            "gender": "Female",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0434090857.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-SC-6-lower-ocd-person-dbb8fc98-5116-44c4-8009-8c91e514dec1",
              "builtID": "sc-lower-6",
              "externalID": "ocd-person/dbb8fc98-5116-44c4-8009-8c91e514dec1",
              "geometry": null
            },
            "contactInfo": {
              "email": "aprilcromer@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0434090857",
                "https://www.scstatehouse.gov/member.php?code=0434090857"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-6-lower-ocd-person-dbb8fc98-5116-44c4-8009-8c91e514dec1": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/61ad39ff-e788-4232-b68a-d9c8f128af7e",
            "firstName": "John Taliaferro",
            "lastName": "West",
            "fullName": "Jay West",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1903408863.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-SC-7-lower-ocd-person-61ad39ff-e788-4232-b68a-d9c8f128af7e",
              "builtID": "sc-lower-7",
              "externalID": "ocd-person/61ad39ff-e788-4232-b68a-d9c8f128af7e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jaywest@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1903408863",
                "https://www.scstatehouse.gov/member.php?code=1903408863"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-7-lower-ocd-person-61ad39ff-e788-4232-b68a-d9c8f128af7e": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/7a7a6119-3a67-4b02-9313-d8454421494e",
            "firstName": "Donald G.",
            "lastName": "Chapman",
            "fullName": "Don Chapman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0347727231.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-SC-8-lower-ocd-person-7a7a6119-3a67-4b02-9313-d8454421494e",
              "builtID": "sc-lower-8",
              "externalID": "ocd-person/7a7a6119-3a67-4b02-9313-d8454421494e",
              "geometry": null
            },
            "contactInfo": {
              "email": "donchapman@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0347727231",
                "https://www.scstatehouse.gov/member.php?code=0347727231"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-8-lower-ocd-person-7a7a6119-3a67-4b02-9313-d8454421494e": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/6b6644b8-f861-4969-be50-9844287b4a60",
            "firstName": "Anne J.",
            "lastName": "Thayer",
            "fullName": "Anne Thayer",
            "gender": "Female",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1812499783.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-SC-9-lower-ocd-person-6b6644b8-f861-4969-be50-9844287b4a60",
              "builtID": "sc-lower-9",
              "externalID": "ocd-person/6b6644b8-f861-4969-be50-9844287b4a60",
              "geometry": null
            },
            "contactInfo": {
              "email": "annethayer@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1812499783",
                "https://www.scstatehouse.gov/member.php?code=1812499783"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-9-lower-ocd-person-6b6644b8-f861-4969-be50-9844287b4a60": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/2ac35060-d236-438e-a3ec-6ad2be0f53c6",
            "firstName": "Thomas",
            "lastName": "Beach",
            "fullName": "Thomas Beach",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0129545439.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-SC-10-lower-ocd-person-2ac35060-d236-438e-a3ec-6ad2be0f53c6",
              "builtID": "sc-lower-10",
              "externalID": "ocd-person/2ac35060-d236-438e-a3ec-6ad2be0f53c6",
              "geometry": null
            },
            "contactInfo": {
              "email": "thomasbeach@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0129545439",
                "https://www.scstatehouse.gov/member.php?code=0129545439"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-10-lower-ocd-person-2ac35060-d236-438e-a3ec-6ad2be0f53c6": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/52ab3f52-ec8b-4268-bfb2-16322df47839",
            "firstName": "Craig A.",
            "lastName": "Gagnon",
            "fullName": "Craig Gagnon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0632954470.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-SC-11-lower-ocd-person-52ab3f52-ec8b-4268-bfb2-16322df47839",
              "builtID": "sc-lower-11",
              "externalID": "ocd-person/52ab3f52-ec8b-4268-bfb2-16322df47839",
              "geometry": null
            },
            "contactInfo": {
              "email": "craiggagnon@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0632954470",
                "https://www.scstatehouse.gov/member.php?code=0632954470"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-11-lower-ocd-person-52ab3f52-ec8b-4268-bfb2-16322df47839": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/fd827c3d-df23-4497-b39e-7c7ab43138fd",
            "firstName": "Daniel",
            "lastName": "Gibson",
            "fullName": "Daniel Gibson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0667045375.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-SC-12-lower-ocd-person-fd827c3d-df23-4497-b39e-7c7ab43138fd",
              "builtID": "sc-lower-12",
              "externalID": "ocd-person/fd827c3d-df23-4497-b39e-7c7ab43138fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "danielgibson@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0667045375",
                "https://www.scstatehouse.gov/member.php?code=0667045375"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-12-lower-ocd-person-fd827c3d-df23-4497-b39e-7c7ab43138fd": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/f946b369-cfbd-4cc5-84f2-4cc343beb03a",
            "firstName": "John Robinson",
            "lastName": "McCravy",
            "fullName": "John McCravy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1217045309.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-SC-13-lower-ocd-person-f946b369-cfbd-4cc5-84f2-4cc343beb03a",
              "builtID": "sc-lower-13",
              "externalID": "ocd-person/f946b369-cfbd-4cc5-84f2-4cc343beb03a",
              "geometry": null
            },
            "contactInfo": {
              "email": "johnmccravy@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1217045309",
                "https://www.scstatehouse.gov/member.php?code=1217045309"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-13-lower-ocd-person-f946b369-cfbd-4cc5-84f2-4cc343beb03a": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/d62a848e-7a5b-48ae-8546-2e4003b95bdd",
            "firstName": "Stewart O.",
            "lastName": "Jones",
            "fullName": "Stewart Jones",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0952272613.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-SC-14-lower-ocd-person-d62a848e-7a5b-48ae-8546-2e4003b95bdd",
              "builtID": "sc-lower-14",
              "externalID": "ocd-person/d62a848e-7a5b-48ae-8546-2e4003b95bdd",
              "geometry": null
            },
            "contactInfo": {
              "email": "stewartjones@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0952272613",
                "https://www.scstatehouse.gov/member.php?code=0952272613"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-14-lower-ocd-person-d62a848e-7a5b-48ae-8546-2e4003b95bdd": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/6f92ad6a-95c7-4e74-aa91-05fd5448c99a",
            "firstName": "J.A.",
            "lastName": "Moore",
            "fullName": "J.A. Moore",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1356818019.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-SC-15-lower-ocd-person-6f92ad6a-95c7-4e74-aa91-05fd5448c99a",
              "builtID": "sc-lower-15",
              "externalID": "ocd-person/6f92ad6a-95c7-4e74-aa91-05fd5448c99a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jamoore@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1356818019",
                "https://www.scstatehouse.gov/member.php?code=1356818019"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-15-lower-ocd-person-6f92ad6a-95c7-4e74-aa91-05fd5448c99a": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/45f11431-96b7-4bfe-85f5-ce986002a233",
            "firstName": "Mark N.",
            "lastName": "Willis",
            "fullName": "Mark Willis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1942613404.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-SC-16-lower-ocd-person-45f11431-96b7-4bfe-85f5-ce986002a233",
              "builtID": "sc-lower-16",
              "externalID": "ocd-person/45f11431-96b7-4bfe-85f5-ce986002a233",
              "geometry": null
            },
            "contactInfo": {
              "email": "markwillis@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1942613404",
                "https://www.scstatehouse.gov/member.php?code=1942613404"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-16-lower-ocd-person-45f11431-96b7-4bfe-85f5-ce986002a233": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/6ffe4e57-021a-4424-bc36-b933c8e18477",
            "firstName": "James Mikell",
            "lastName": "Burns",
            "fullName": "Mike Burns",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0257954515.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-SC-17-lower-ocd-person-6ffe4e57-021a-4424-bc36-b933c8e18477",
              "builtID": "sc-lower-17",
              "externalID": "ocd-person/6ffe4e57-021a-4424-bc36-b933c8e18477",
              "geometry": null
            },
            "contactInfo": {
              "email": "mikeburns@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0257954515",
                "https://www.scstatehouse.gov/member.php?code=0257954515"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-17-lower-ocd-person-6ffe4e57-021a-4424-bc36-b933c8e18477": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/10d51991-3dec-499f-8ed2-9c5685de9b00",
            "firstName": "Timothy Alan",
            "lastName": "Morgan",
            "fullName": "Alan Morgan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1374999835.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-SC-18-lower-ocd-person-10d51991-3dec-499f-8ed2-9c5685de9b00",
              "builtID": "sc-lower-18",
              "externalID": "ocd-person/10d51991-3dec-499f-8ed2-9c5685de9b00",
              "geometry": null
            },
            "contactInfo": {
              "email": "alanmorgan@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1374999835",
                "https://www.scstatehouse.gov/member.php?code=1374999835"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-18-lower-ocd-person-10d51991-3dec-499f-8ed2-9c5685de9b00": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/08f0816c-5d19-47df-90fb-1249e3f50fae",
            "firstName": "Patrick B.",
            "lastName": "Haddon",
            "fullName": "Patrick Haddon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0733806731.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-SC-19-lower-ocd-person-08f0816c-5d19-47df-90fb-1249e3f50fae",
              "builtID": "sc-lower-19",
              "externalID": "ocd-person/08f0816c-5d19-47df-90fb-1249e3f50fae",
              "geometry": null
            },
            "contactInfo": {
              "email": "patrick@patrickhaddon.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0733806731",
                "https://www.scstatehouse.gov/member.php?code=0733806731"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-19-lower-ocd-person-08f0816c-5d19-47df-90fb-1249e3f50fae": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/d1f0e6e5-5d28-4e9e-a0f9-04d6164b4173",
            "firstName": "Adam M.",
            "lastName": "Morgan",
            "fullName": "Adam Morgan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1370454381.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-SC-20-lower-ocd-person-d1f0e6e5-5d28-4e9e-a0f9-04d6164b4173",
              "builtID": "sc-lower-20",
              "externalID": "ocd-person/d1f0e6e5-5d28-4e9e-a0f9-04d6164b4173",
              "geometry": null
            },
            "contactInfo": {
              "email": "adammorgan@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1370454381",
                "https://www.scstatehouse.gov/member.php?code=1370454381"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-20-lower-ocd-person-d1f0e6e5-5d28-4e9e-a0f9-04d6164b4173": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/083dd694-e9e1-43fb-a857-760acfb78ef8",
            "firstName": "Bobby J.",
            "lastName": "Cox",
            "fullName": "Bobby Cox",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0427840858.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-SC-21-lower-ocd-person-083dd694-e9e1-43fb-a857-760acfb78ef8",
              "builtID": "sc-lower-21",
              "externalID": "ocd-person/083dd694-e9e1-43fb-a857-760acfb78ef8",
              "geometry": null
            },
            "contactInfo": {
              "email": "bobbycox@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0427840858",
                "https://www.scstatehouse.gov/member.php?code=0427840858"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-21-lower-ocd-person-083dd694-e9e1-43fb-a857-760acfb78ef8": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/3d40ff3f-ddbf-4611-a366-9be7d55b9247",
            "firstName": "Jason T.",
            "lastName": "Elliott",
            "fullName": "Jason Elliott",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0529545391.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-SC-22-lower-ocd-person-3d40ff3f-ddbf-4611-a366-9be7d55b9247",
              "builtID": "sc-lower-22",
              "externalID": "ocd-person/3d40ff3f-ddbf-4611-a366-9be7d55b9247",
              "geometry": null
            },
            "contactInfo": {
              "email": "jasonelliott@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0529545391",
                "https://www.scstatehouse.gov/member.php?code=0529545391"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-22-lower-ocd-person-3d40ff3f-ddbf-4611-a366-9be7d55b9247": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/2432a9be-a476-4dae-966c-74f01e24fdbb",
            "firstName": "Chandra E.",
            "lastName": "Dillard",
            "fullName": "Chandra Dillard",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0479545397.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-SC-23-lower-ocd-person-2432a9be-a476-4dae-966c-74f01e24fdbb",
              "builtID": "sc-lower-23",
              "externalID": "ocd-person/2432a9be-a476-4dae-966c-74f01e24fdbb",
              "geometry": null
            },
            "contactInfo": {
              "email": "chandra@chandradillard.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0479545397",
                "https://www.scstatehouse.gov/member.php?code=0479545397"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-23-lower-ocd-person-2432a9be-a476-4dae-966c-74f01e24fdbb": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/d4974849-0939-472c-9862-4d760889125e",
            "firstName": "Bruce Wyche",
            "lastName": "Bannister",
            "fullName": "Bruce Bannister",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0103409079.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-SC-24-lower-ocd-person-d4974849-0939-472c-9862-4d760889125e",
              "builtID": "sc-lower-24",
              "externalID": "ocd-person/d4974849-0939-472c-9862-4d760889125e",
              "geometry": null
            },
            "contactInfo": {
              "email": "brucebannister@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0103409079",
                "https://www.scstatehouse.gov/member.php?code=0103409079"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-24-lower-ocd-person-d4974849-0939-472c-9862-4d760889125e": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/7a7e6769-eee4-4bb5-8246-a6463457f342",
            "firstName": "Wendell K.",
            "lastName": "Jones",
            "fullName": "Wendell Jones",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0953408977.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-SC-25-lower-ocd-person-7a7e6769-eee4-4bb5-8246-a6463457f342",
              "builtID": "sc-lower-25",
              "externalID": "ocd-person/7a7e6769-eee4-4bb5-8246-a6463457f342",
              "geometry": null
            },
            "contactInfo": {
              "email": "wendelljones@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0953408977",
                "https://www.scstatehouse.gov/member.php?code=0953408977"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-25-lower-ocd-person-7a7e6769-eee4-4bb5-8246-a6463457f342": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/dafa79f2-1f22-4fb4-9620-09d86ecddcb7",
            "firstName": "Regina Raye",
            "lastName": "Felder",
            "fullName": "Raye Felder",
            "gender": "Female",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0570454477.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-SC-26-lower-ocd-person-dafa79f2-1f22-4fb4-9620-09d86ecddcb7",
              "builtID": "sc-lower-26",
              "externalID": "ocd-person/dafa79f2-1f22-4fb4-9620-09d86ecddcb7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rayefleder@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0570454477",
                "https://www.scstatehouse.gov/member.php?code=0570454477"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-26-lower-ocd-person-dafa79f2-1f22-4fb4-9620-09d86ecddcb7": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/96fcbf84-e1e8-4e69-bc26-eeced30abaf4",
            "firstName": "David M.",
            "lastName": "Vaughan",
            "fullName": "David Vaughan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1865908867.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-SC-27-lower-ocd-person-96fcbf84-e1e8-4e69-bc26-eeced30abaf4",
              "builtID": "sc-lower-27",
              "externalID": "ocd-person/96fcbf84-e1e8-4e69-bc26-eeced30abaf4",
              "geometry": null
            },
            "contactInfo": {
              "email": "davidvaughan@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1865908867",
                "https://www.scstatehouse.gov/member.php?code=1865908867"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-27-lower-ocd-person-96fcbf84-e1e8-4e69-bc26-eeced30abaf4": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/14cfa78e-9970-4c17-97dc-420de50ed081",
            "firstName": "Ashley Burch",
            "lastName": "Trantham",
            "fullName": "Ashley Trantham",
            "gender": "Female",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1841477052.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-SC-28-lower-ocd-person-14cfa78e-9970-4c17-97dc-420de50ed081",
              "builtID": "sc-lower-28",
              "externalID": "ocd-person/14cfa78e-9970-4c17-97dc-420de50ed081",
              "geometry": null
            },
            "contactInfo": {
              "email": "ashleytrantham@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1841477052",
                "https://www.scstatehouse.gov/member.php?code=1841477052"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-28-lower-ocd-person-14cfa78e-9970-4c17-97dc-420de50ed081": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/08cc0e0f-26e0-4117-8591-2ac463f6fbe6",
            "firstName": "Dennis Carroll",
            "lastName": "Moss",
            "fullName": "Dennis Moss",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1379545289.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-SC-29-lower-ocd-person-08cc0e0f-26e0-4117-8591-2ac463f6fbe6",
              "builtID": "sc-lower-29",
              "externalID": "ocd-person/08cc0e0f-26e0-4117-8591-2ac463f6fbe6",
              "geometry": null
            },
            "contactInfo": {
              "email": "dennismoss@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1379545289",
                "https://www.scstatehouse.gov/member.php?code=1379545289"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-29-lower-ocd-person-08cc0e0f-26e0-4117-8591-2ac463f6fbe6": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/d2019dc3-160d-4229-b681-280c52561435",
            "firstName": "Michael Brian",
            "lastName": "Lawson",
            "fullName": "Brian Lawson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1067613508.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-SC-30-lower-ocd-person-d2019dc3-160d-4229-b681-280c52561435",
              "builtID": "sc-lower-30",
              "externalID": "ocd-person/d2019dc3-160d-4229-b681-280c52561435",
              "geometry": null
            },
            "contactInfo": {
              "email": "brianlawson@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1067613508",
                "https://www.scstatehouse.gov/member.php?code=1067613508"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-30-lower-ocd-person-d2019dc3-160d-4229-b681-280c52561435": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/6a6c75f2-f3be-4764-9b7d-04b13c28b4aa",
            "firstName": "Rosalyn Denise",
            "lastName": "Henderson Myers",
            "fullName": "Rosalyn Henderson Myers",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0824999901.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-SC-31-lower-ocd-person-6a6c75f2-f3be-4764-9b7d-04b13c28b4aa",
              "builtID": "sc-lower-31",
              "externalID": "ocd-person/6a6c75f2-f3be-4764-9b7d-04b13c28b4aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "rosalynhenderson-myers@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0824999901",
                "https://www.scstatehouse.gov/member.php?code=0824999901"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-31-lower-ocd-person-6a6c75f2-f3be-4764-9b7d-04b13c28b4aa": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/f17c0073-e7c8-4482-bd40-0703fc296f2b",
            "firstName": "Max T.",
            "lastName": "Hyde",
            "fullName": "Max Hyde",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0914772618.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-SC-32-lower-ocd-person-f17c0073-e7c8-4482-bd40-0703fc296f2b",
              "builtID": "sc-lower-32",
              "externalID": "ocd-person/f17c0073-e7c8-4482-bd40-0703fc296f2b",
              "geometry": null
            },
            "contactInfo": {
              "email": "maxhyde@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0914772618",
                "https://www.scstatehouse.gov/member.php?code=0914772618"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-32-lower-ocd-person-f17c0073-e7c8-4482-bd40-0703fc296f2b": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/3b778652-2293-4f1d-ae0c-7d2308cbe9af",
            "firstName": "Travis A.",
            "lastName": "Moore",
            "fullName": "Travis Moore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1365908927.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-SC-33-lower-ocd-person-3b778652-2293-4f1d-ae0c-7d2308cbe9af",
              "builtID": "sc-lower-33",
              "externalID": "ocd-person/3b778652-2293-4f1d-ae0c-7d2308cbe9af",
              "geometry": null
            },
            "contactInfo": {
              "email": "travismoore@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1365908927",
                "https://www.scstatehouse.gov/member.php?code=1365908927"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-33-lower-ocd-person-3b778652-2293-4f1d-ae0c-7d2308cbe9af": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/46debaf0-1a2a-4816-bc4e-ddaa74b4bed0",
            "firstName": "Roger Allen",
            "lastName": "Nutt",
            "fullName": "Roger Nutt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1424999829.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-SC-34-lower-ocd-person-46debaf0-1a2a-4816-bc4e-ddaa74b4bed0",
              "builtID": "sc-lower-34",
              "externalID": "ocd-person/46debaf0-1a2a-4816-bc4e-ddaa74b4bed0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rogernutt@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1424999829",
                "https://www.scstatehouse.gov/member.php?code=1424999829"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-34-lower-ocd-person-46debaf0-1a2a-4816-bc4e-ddaa74b4bed0": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/20240c0c-69e5-445f-b8ca-d33dd377e086",
            "firstName": "William M.",
            "lastName": "Chumley",
            "fullName": "Bill Chumley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0351704504.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-SC-35-lower-ocd-person-20240c0c-69e5-445f-b8ca-d33dd377e086",
              "builtID": "sc-lower-35",
              "externalID": "ocd-person/20240c0c-69e5-445f-b8ca-d33dd377e086",
              "geometry": null
            },
            "contactInfo": {
              "email": "billchumley@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0351704504",
                "https://www.scstatehouse.gov/member.php?code=0351704504"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-35-lower-ocd-person-20240c0c-69e5-445f-b8ca-d33dd377e086": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/5bc3db4e-8055-4952-a217-1520e26c8929",
            "firstName": "Robert J.",
            "lastName": "Harris",
            "fullName": "Rob Harris",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0764772636.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-SC-36-lower-ocd-person-5bc3db4e-8055-4952-a217-1520e26c8929",
              "builtID": "sc-lower-36",
              "externalID": "ocd-person/5bc3db4e-8055-4952-a217-1520e26c8929",
              "geometry": null
            },
            "contactInfo": {
              "email": "robharris@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0764772636",
                "https://www.scstatehouse.gov/member.php?code=0764772636"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-36-lower-ocd-person-5bc3db4e-8055-4952-a217-1520e26c8929": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/72f330b5-9ae0-40e6-b1d2-0ab6725cccf2",
            "firstName": "Steven Wayne",
            "lastName": "Long",
            "fullName": "Steven Long",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1120454411.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-SC-37-lower-ocd-person-72f330b5-9ae0-40e6-b1d2-0ab6725cccf2",
              "builtID": "sc-lower-37",
              "externalID": "ocd-person/72f330b5-9ae0-40e6-b1d2-0ab6725cccf2",
              "geometry": null
            },
            "contactInfo": {
              "email": "stevenlong@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1120454411",
                "https://www.scstatehouse.gov/member.php?code=1120454411"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-37-lower-ocd-person-72f330b5-9ae0-40e6-b1d2-0ab6725cccf2": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/6db09ea1-fe89-497a-8502-dce892c13fe8",
            "firstName": "Josiah",
            "lastName": "Magnuson",
            "fullName": "Josiah Magnuson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1130965774.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-SC-38-lower-ocd-person-6db09ea1-fe89-497a-8502-dce892c13fe8",
              "builtID": "sc-lower-38",
              "externalID": "ocd-person/6db09ea1-fe89-497a-8502-dce892c13fe8",
              "geometry": null
            },
            "contactInfo": {
              "email": "josiahmagnuson@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1130965774",
                "https://www.scstatehouse.gov/member.php?code=1130965774"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-38-lower-ocd-person-6db09ea1-fe89-497a-8502-dce892c13fe8": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/bbff9a4c-7b59-4538-b869-64ba0c8431ff",
            "firstName": "Cally Ralph",
            "lastName": "Forrest",
            "fullName": "Cal Forrest",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0608238564.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-SC-39-lower-ocd-person-bbff9a4c-7b59-4538-b869-64ba0c8431ff",
              "builtID": "sc-lower-39",
              "externalID": "ocd-person/bbff9a4c-7b59-4538-b869-64ba0c8431ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "calforrest@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0608238564",
                "https://www.scstatehouse.gov/member.php?code=0608238564"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-39-lower-ocd-person-bbff9a4c-7b59-4538-b869-64ba0c8431ff": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/e6db7b68-2d69-4585-816a-ad2c7ee9b1cc",
            "firstName": "Joseph S.",
            "lastName": "White",
            "fullName": "Joe White",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1912499771.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-SC-40-lower-ocd-person-e6db7b68-2d69-4585-816a-ad2c7ee9b1cc",
              "builtID": "sc-lower-40",
              "externalID": "ocd-person/e6db7b68-2d69-4585-816a-ad2c7ee9b1cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "joewhite@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1912499771",
                "https://www.scstatehouse.gov/member.php?code=1912499771"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-40-lower-ocd-person-e6db7b68-2d69-4585-816a-ad2c7ee9b1cc": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/86e53967-3383-4df1-b3b6-49aa2b327110",
            "firstName": "Annie E.",
            "lastName": "McDaniel",
            "fullName": "Annie McDaniel",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1221590763.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-SC-41-lower-ocd-person-86e53967-3383-4df1-b3b6-49aa2b327110",
              "builtID": "sc-lower-41",
              "externalID": "ocd-person/86e53967-3383-4df1-b3b6-49aa2b327110",
              "geometry": null
            },
            "contactInfo": {
              "email": "anniemcdaniel@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1221590763",
                "https://www.scstatehouse.gov/member.php?code=1221590763"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-41-lower-ocd-person-86e53967-3383-4df1-b3b6-49aa2b327110": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/3a09cbd2-aa21-4fa8-9511-d1f842816e49",
            "firstName": "Leon D.",
            "lastName": "Gilliam",
            "fullName": "Doug Gilliam",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0675426055.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-SC-42-lower-ocd-person-3a09cbd2-aa21-4fa8-9511-d1f842816e49",
              "builtID": "sc-lower-42",
              "externalID": "ocd-person/3a09cbd2-aa21-4fa8-9511-d1f842816e49",
              "geometry": null
            },
            "contactInfo": {
              "email": "douggilliam@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0675426055",
                "https://www.scstatehouse.gov/member.php?code=0675426055"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-42-lower-ocd-person-3a09cbd2-aa21-4fa8-9511-d1f842816e49": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/e4ab57f5-cf10-42ea-9c31-a6a4cc1a8c29",
            "firstName": "Thomas Randolph",
            "lastName": "Ligon",
            "fullName": "Randy Ligon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1087499870.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-SC-43-lower-ocd-person-e4ab57f5-cf10-42ea-9c31-a6a4cc1a8c29",
              "builtID": "sc-lower-43",
              "externalID": "ocd-person/e4ab57f5-cf10-42ea-9c31-a6a4cc1a8c29",
              "geometry": null
            },
            "contactInfo": {
              "email": "randyligon@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1087499870",
                "https://www.scstatehouse.gov/member.php?code=1087499870"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-43-lower-ocd-person-e4ab57f5-cf10-42ea-9c31-a6a4cc1a8c29": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/31e694fa-e3e1-4df4-8ca7-4977dbf6e321",
            "firstName": "James M.",
            "lastName": "Neese",
            "fullName": "Mike Neese",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1403977105.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-SC-44-lower-ocd-person-31e694fa-e3e1-4df4-8ca7-4977dbf6e321",
              "builtID": "sc-lower-44",
              "externalID": "ocd-person/31e694fa-e3e1-4df4-8ca7-4977dbf6e321",
              "geometry": null
            },
            "contactInfo": {
              "email": "mikeneese@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1403977105",
                "https://www.scstatehouse.gov/member.php?code=1403977105"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-44-lower-ocd-person-31e694fa-e3e1-4df4-8ca7-4977dbf6e321": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/aa158d61-1373-4c4a-9ad3-ea74bf422cc3",
            "firstName": "Brandon Michael",
            "lastName": "Newton",
            "fullName": "Brandon Newton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1418607785.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-SC-45-lower-ocd-person-aa158d61-1373-4c4a-9ad3-ea74bf422cc3",
              "builtID": "sc-lower-45",
              "externalID": "ocd-person/aa158d61-1373-4c4a-9ad3-ea74bf422cc3",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandonnewton@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1418607785",
                "https://www.scstatehouse.gov/member.php?code=1418607785"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-45-lower-ocd-person-aa158d61-1373-4c4a-9ad3-ea74bf422cc3": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/dbc0a5ad-b105-4cd9-8849-e2a7c77d23f0",
            "firstName": "Christopher Heath",
            "lastName": "Sessions",
            "fullName": "Heath Sessions",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1649715712.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-SC-46-lower-ocd-person-dbc0a5ad-b105-4cd9-8849-e2a7c77d23f0",
              "builtID": "sc-lower-46",
              "externalID": "ocd-person/dbc0a5ad-b105-4cd9-8849-e2a7c77d23f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "heathsessions@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1649715712",
                "https://www.scstatehouse.gov/member.php?code=1649715712"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-46-lower-ocd-person-dbc0a5ad-b105-4cd9-8849-e2a7c77d23f0": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/7a31b7ee-6b6c-487b-bc69-c3c323357c05",
            "firstName": "Thomas E.",
            "lastName": "Pope",
            "fullName": "Tommy Pope",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1484090731.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-SC-47-lower-ocd-person-7a31b7ee-6b6c-487b-bc69-c3c323357c05",
              "builtID": "sc-lower-47",
              "externalID": "ocd-person/7a31b7ee-6b6c-487b-bc69-c3c323357c05",
              "geometry": null
            },
            "contactInfo": {
              "email": "tommypope@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1484090731",
                "https://www.scstatehouse.gov/member.php?code=1484090731"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-47-lower-ocd-person-7a31b7ee-6b6c-487b-bc69-c3c323357c05": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/f89d05de-1f18-466c-bd51-f718a208407b",
            "firstName": "Brandon Earl",
            "lastName": "Guffey",
            "fullName": "Brandon Guffey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0730681731.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-SC-48-lower-ocd-person-f89d05de-1f18-466c-bd51-f718a208407b",
              "builtID": "sc-lower-48",
              "externalID": "ocd-person/f89d05de-1f18-466c-bd51-f718a208407b",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandonguffey@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0730681731",
                "https://www.scstatehouse.gov/member.php?code=0730681731"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-48-lower-ocd-person-f89d05de-1f18-466c-bd51-f718a208407b": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/12f4e61d-b847-4a7d-8029-613580ebfd9f",
            "firstName": "John Richard Christopher",
            "lastName": "King",
            "fullName": "John King",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1002272607.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-SC-49-lower-ocd-person-12f4e61d-b847-4a7d-8029-613580ebfd9f",
              "builtID": "sc-lower-49",
              "externalID": "ocd-person/12f4e61d-b847-4a7d-8029-613580ebfd9f",
              "geometry": null
            },
            "contactInfo": {
              "email": "johnking@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1002272607",
                "https://www.scstatehouse.gov/member.php?code=1002272607"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-49-lower-ocd-person-12f4e61d-b847-4a7d-8029-613580ebfd9f": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/911b30fa-34dd-48ff-b35a-b3202031b031",
            "firstName": "William W.",
            "lastName": "Wheeler",
            "fullName": "Will Wheeler",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1907386135.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-SC-50-lower-ocd-person-911b30fa-34dd-48ff-b35a-b3202031b031",
              "builtID": "sc-lower-50",
              "externalID": "ocd-person/911b30fa-34dd-48ff-b35a-b3202031b031",
              "geometry": null
            },
            "contactInfo": {
              "email": "willwheeler@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1907386135",
                "https://www.scstatehouse.gov/member.php?code=1907386135"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-50-lower-ocd-person-911b30fa-34dd-48ff-b35a-b3202031b031": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/10d7814d-009b-4d21-a048-2672f48fe130",
            "firstName": "J. David",
            "lastName": "Weeks",
            "fullName": "David Weeks",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1898863409.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-SC-51-lower-ocd-person-10d7814d-009b-4d21-a048-2672f48fe130",
              "builtID": "sc-lower-51",
              "externalID": "ocd-person/10d7814d-009b-4d21-a048-2672f48fe130",
              "geometry": null
            },
            "contactInfo": {
              "email": "davidweeks@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1898863409",
                "https://www.scstatehouse.gov/member.php?code=1898863409"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-51-lower-ocd-person-10d7814d-009b-4d21-a048-2672f48fe130": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/7285606e-c2ea-4812-aab0-4d8bf497cd24",
            "firstName": "Joseph Benjamin",
            "lastName": "Connell",
            "fullName": "Ben Connell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0389772681.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-SC-52-lower-ocd-person-7285606e-c2ea-4812-aab0-4d8bf497cd24",
              "builtID": "sc-lower-52",
              "externalID": "ocd-person/7285606e-c2ea-4812-aab0-4d8bf497cd24",
              "geometry": null
            },
            "contactInfo": {
              "email": "benconnell@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0389772681",
                "https://www.scstatehouse.gov/member.php?code=0389772681"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-52-lower-ocd-person-7285606e-c2ea-4812-aab0-4d8bf497cd24": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/ebfb814e-0b47-4d8f-be94-63718f71a96c",
            "firstName": "Richard L.",
            "lastName": "Yow",
            "fullName": "Richie Yow",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1998863577.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-SC-53-lower-ocd-person-ebfb814e-0b47-4d8f-be94-63718f71a96c",
              "builtID": "sc-lower-53",
              "externalID": "ocd-person/ebfb814e-0b47-4d8f-be94-63718f71a96c",
              "geometry": null
            },
            "contactInfo": {
              "email": "richieyow@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1998863577",
                "https://www.scstatehouse.gov/member.php?code=1998863577"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-53-lower-ocd-person-ebfb814e-0b47-4d8f-be94-63718f71a96c": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/951188b7-b57f-4705-b966-a24c806fef62",
            "firstName": "Patricia Moore",
            "lastName": "Henegan",
            "fullName": "Pat Henegan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0829545355.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-SC-54-lower-ocd-person-951188b7-b57f-4705-b966-a24c806fef62",
              "builtID": "sc-lower-54",
              "externalID": "ocd-person/951188b7-b57f-4705-b966-a24c806fef62",
              "geometry": null
            },
            "contactInfo": {
              "email": "henegan7@aol.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0829545355",
                "https://www.scstatehouse.gov/member.php?code=0829545355"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-54-lower-ocd-person-951188b7-b57f-4705-b966-a24c806fef62": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/0193ab02-383a-4b92-a810-00fd0bf0fb98",
            "firstName": "Jackie E.",
            "lastName": "Hayes",
            "fullName": "Jackie Hayes",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0802272631.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-SC-55-lower-ocd-person-0193ab02-383a-4b92-a810-00fd0bf0fb98",
              "builtID": "sc-lower-55",
              "externalID": "ocd-person/0193ab02-383a-4b92-a810-00fd0bf0fb98",
              "geometry": null
            },
            "contactInfo": {
              "email": "jackiehayes@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0802272631",
                "https://www.scstatehouse.gov/member.php?code=0802272631"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-55-lower-ocd-person-0193ab02-383a-4b92-a810-00fd0bf0fb98": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/023895b1-aeb4-410a-84d0-0059b3f10ee3",
            "firstName": "Timothy A.",
            "lastName": "McGinnis",
            "fullName": "Tim McGinnis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1256818031.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-SC-56-lower-ocd-person-023895b1-aeb4-410a-84d0-0059b3f10ee3",
              "builtID": "sc-lower-56",
              "externalID": "ocd-person/023895b1-aeb4-410a-84d0-0059b3f10ee3",
              "geometry": null
            },
            "contactInfo": {
              "email": "timmcginnis@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1256818031",
                "https://www.scstatehouse.gov/member.php?code=1256818031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-56-lower-ocd-person-023895b1-aeb4-410a-84d0-0059b3f10ee3": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/e3d0a679-af6a-4861-aac2-be366fda3210",
            "firstName": "Frank",
            "lastName": "Atkinson",
            "fullName": "Lucas Atkinson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0066477265.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-SC-57-lower-ocd-person-e3d0a679-af6a-4861-aac2-be366fda3210",
              "builtID": "sc-lower-57",
              "externalID": "ocd-person/e3d0a679-af6a-4861-aac2-be366fda3210",
              "geometry": null
            },
            "contactInfo": {
              "email": "lucasatkinson@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0066477265",
                "https://www.scstatehouse.gov/member.php?code=0066477265"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-57-lower-ocd-person-e3d0a679-af6a-4861-aac2-be366fda3210": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/7b2dc589-67e0-4286-9a21-73a69a299ef7",
            "firstName": "Jeffrey E.",
            "lastName": "Johnson",
            "fullName": "Jeff Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0938636251.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-SC-58-lower-ocd-person-7b2dc589-67e0-4286-9a21-73a69a299ef7",
              "builtID": "sc-lower-58",
              "externalID": "ocd-person/7b2dc589-67e0-4286-9a21-73a69a299ef7",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeffjohnson@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0938636251",
                "https://www.scstatehouse.gov/member.php?code=0938636251"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-58-lower-ocd-person-7b2dc589-67e0-4286-9a21-73a69a299ef7": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/ae65876e-c27c-49ff-a49f-d58bcbbd4bd5",
            "firstName": "Terry",
            "lastName": "Alexander",
            "fullName": "Terry Alexander",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0011363635.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-SC-59-lower-ocd-person-ae65876e-c27c-49ff-a49f-d58bcbbd4bd5",
              "builtID": "sc-lower-59",
              "externalID": "ocd-person/ae65876e-c27c-49ff-a49f-d58bcbbd4bd5",
              "geometry": null
            },
            "contactInfo": {
              "email": "terryalexander@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0011363635",
                "https://www.scstatehouse.gov/member.php?code=0011363635"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-59-lower-ocd-person-ae65876e-c27c-49ff-a49f-d58bcbbd4bd5": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/92bc68c6-b20b-4eec-a315-b0d5495db8ec",
            "firstName": "Phillip D.",
            "lastName": "Lowe",
            "fullName": "Phillip Lowe",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1125568047.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-SC-60-lower-ocd-person-92bc68c6-b20b-4eec-a315-b0d5495db8ec",
              "builtID": "sc-lower-60",
              "externalID": "ocd-person/92bc68c6-b20b-4eec-a315-b0d5495db8ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "philliplowe@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1125568047",
                "https://www.scstatehouse.gov/member.php?code=1125568047"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-60-lower-ocd-person-92bc68c6-b20b-4eec-a315-b0d5495db8ec": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/b082de67-acd5-42db-bdfc-643650ad7e34",
            "firstName": "Carla",
            "lastName": "Schuessler",
            "fullName": "Carla Schuessler",
            "gender": "Female",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1635227077.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-SC-61-lower-ocd-person-b082de67-acd5-42db-bdfc-643650ad7e34",
              "builtID": "sc-lower-61",
              "externalID": "ocd-person/b082de67-acd5-42db-bdfc-643650ad7e34",
              "geometry": null
            },
            "contactInfo": {
              "email": "carlaschuessler@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1635227077",
                "https://www.scstatehouse.gov/member.php?code=1635227077"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-61-lower-ocd-person-b082de67-acd5-42db-bdfc-643650ad7e34": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/1b83cb36-84ea-44b1-b234-14ca1151c7a4",
            "firstName": "Robert Quintin",
            "lastName": "Williams",
            "fullName": "Robert Williams",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1942045222.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-SC-62-lower-ocd-person-1b83cb36-84ea-44b1-b234-14ca1151c7a4",
              "builtID": "sc-lower-62",
              "externalID": "ocd-person/1b83cb36-84ea-44b1-b234-14ca1151c7a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "robertwilliams@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1942045222",
                "https://www.scstatehouse.gov/member.php?code=1942045222"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-62-lower-ocd-person-1b83cb36-84ea-44b1-b234-14ca1151c7a4": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/00d450b3-f427-4e64-b0bd-45609b0a433b",
            "firstName": "Wallace H.",
            "lastName": "Jordan",
            "fullName": "Jay Jordan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0957954431.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-SC-63-lower-ocd-person-00d450b3-f427-4e64-b0bd-45609b0a433b",
              "builtID": "sc-lower-63",
              "externalID": "ocd-person/00d450b3-f427-4e64-b0bd-45609b0a433b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jayjordan@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0957954431",
                "https://www.scstatehouse.gov/member.php?code=0957954431"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-63-lower-ocd-person-00d450b3-f427-4e64-b0bd-45609b0a433b": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/5861846a-d293-416f-b5ab-227f8fd12b94",
            "firstName": "Fawn M.",
            "lastName": "Pedalino",
            "fullName": "Fawn Pedalino",
            "gender": "Female",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1453408917.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-SC-64-lower-ocd-person-5861846a-d293-416f-b5ab-227f8fd12b94",
              "builtID": "sc-lower-64",
              "externalID": "ocd-person/5861846a-d293-416f-b5ab-227f8fd12b94",
              "geometry": null
            },
            "contactInfo": {
              "email": "fawnpedalino@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1453408917",
                "https://www.scstatehouse.gov/member.php?code=1453408917"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-64-lower-ocd-person-5861846a-d293-416f-b5ab-227f8fd12b94": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/16726551-937d-43ca-a13b-c94c608d1ba3",
            "firstName": "Cody Tarlton",
            "lastName": "Mitchell",
            "fullName": "Cody Mitchell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1344033930.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-SC-65-lower-ocd-person-16726551-937d-43ca-a13b-c94c608d1ba3",
              "builtID": "sc-lower-65",
              "externalID": "ocd-person/16726551-937d-43ca-a13b-c94c608d1ba3",
              "geometry": null
            },
            "contactInfo": {
              "email": "codymitchell@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1344033930",
                "https://www.scstatehouse.gov/member.php?code=1344033930"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-65-lower-ocd-person-16726551-937d-43ca-a13b-c94c608d1ba3": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/016ba21f-2b57-4d94-885c-0fca0465728e",
            "firstName": "David L.",
            "lastName": "O'Neal",
            "fullName": "David O'Neal",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1429545283.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-SC-66-lower-ocd-person-016ba21f-2b57-4d94-885c-0fca0465728e",
              "builtID": "sc-lower-66",
              "externalID": "ocd-person/016ba21f-2b57-4d94-885c-0fca0465728e",
              "geometry": null
            },
            "contactInfo": {
              "email": "davidoneal@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1429545283",
                "https://www.scstatehouse.gov/member.php?code=1429545283"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-66-lower-ocd-person-016ba21f-2b57-4d94-885c-0fca0465728e": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/bc549137-780f-4b77-9438-61a92d3dce48",
            "firstName": "George Murrell",
            "lastName": "Smith",
            "fullName": "Murrell Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1703408887.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-SC-67-lower-ocd-person-bc549137-780f-4b77-9438-61a92d3dce48",
              "builtID": "sc-lower-67",
              "externalID": "ocd-person/bc549137-780f-4b77-9438-61a92d3dce48",
              "geometry": null
            },
            "contactInfo": {
              "email": "murrellsmith@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1703408887",
                "https://www.scstatehouse.gov/member.php?code=1703408887"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-67-lower-ocd-person-bc549137-780f-4b77-9438-61a92d3dce48": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/814c55e9-568f-403e-9e8a-ee3859c07300",
            "firstName": "Heather Ammons",
            "lastName": "Crawford",
            "fullName": "Heather Crawford",
            "gender": "Female",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0428977222.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-SC-68-lower-ocd-person-814c55e9-568f-403e-9e8a-ee3859c07300",
              "builtID": "sc-lower-68",
              "externalID": "ocd-person/814c55e9-568f-403e-9e8a-ee3859c07300",
              "geometry": null
            },
            "contactInfo": {
              "email": "heathercrawford@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0428977222",
                "https://www.scstatehouse.gov/member.php?code=0428977222"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-68-lower-ocd-person-814c55e9-568f-403e-9e8a-ee3859c07300": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/728e5331-d2ec-4c21-a271-be37ae8843ff",
            "firstName": "Christopher Sloan",
            "lastName": "Wooten",
            "fullName": "Chris Wooten",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1978408854.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-SC-69-lower-ocd-person-728e5331-d2ec-4c21-a271-be37ae8843ff",
              "builtID": "sc-lower-69",
              "externalID": "ocd-person/728e5331-d2ec-4c21-a271-be37ae8843ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "chriswooten@schouse.gov.",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1978408854",
                "https://www.scstatehouse.gov/member.php?code=1978408854"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-69-lower-ocd-person-728e5331-d2ec-4c21-a271-be37ae8843ff": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/4ae40a76-8a57-40b1-b4cd-0218170e3ff8",
            "firstName": "Jermaine L.",
            "lastName": "Johnson",
            "fullName": "Jermaine Johnson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0943181705.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-SC-70-lower-ocd-person-4ae40a76-8a57-40b1-b4cd-0218170e3ff8",
              "builtID": "sc-lower-70",
              "externalID": "ocd-person/4ae40a76-8a57-40b1-b4cd-0218170e3ff8",
              "geometry": null
            },
            "contactInfo": {
              "email": "jermainejohnson@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0943181705",
                "https://www.scstatehouse.gov/member.php?code=0943181705"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-70-lower-ocd-person-4ae40a76-8a57-40b1-b4cd-0218170e3ff8": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/129fde97-b4fa-4004-bfb9-f96169a7c0a5",
            "firstName": "Nathan",
            "lastName": "Ballentine",
            "fullName": "Nathan Ballentine",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0102272715.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-SC-71-lower-ocd-person-129fde97-b4fa-4004-bfb9-f96169a7c0a5",
              "builtID": "sc-lower-71",
              "externalID": "ocd-person/129fde97-b4fa-4004-bfb9-f96169a7c0a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "nathanballentine@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0102272715",
                "https://www.scstatehouse.gov/member.php?code=0102272715"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-71-lower-ocd-person-129fde97-b4fa-4004-bfb9-f96169a7c0a5": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/6f3e5d6a-0e85-4af0-9711-1eaa7e3da981",
            "firstName": "Seth C.",
            "lastName": "Rose",
            "fullName": "Seth Rose",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1584090719.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-SC-72-lower-ocd-person-6f3e5d6a-0e85-4af0-9711-1eaa7e3da981",
              "builtID": "sc-lower-72",
              "externalID": "ocd-person/6f3e5d6a-0e85-4af0-9711-1eaa7e3da981",
              "geometry": null
            },
            "contactInfo": {
              "email": "sethrose@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1584090719",
                "https://www.scstatehouse.gov/member.php?code=1584090719"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-72-lower-ocd-person-6f3e5d6a-0e85-4af0-9711-1eaa7e3da981": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/f9925f2e-d43e-47ff-ba74-f670b65d96e7",
            "firstName": "Christopher R.",
            "lastName": "Hart",
            "fullName": "Chris Hart",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0770454453.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-SC-73-lower-ocd-person-f9925f2e-d43e-47ff-ba74-f670b65d96e7",
              "builtID": "sc-lower-73",
              "externalID": "ocd-person/f9925f2e-d43e-47ff-ba74-f670b65d96e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "hartc@schouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0770454453",
                "https://www.scstatehouse.gov/member.php?code=0770454453"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-73-lower-ocd-person-f9925f2e-d43e-47ff-ba74-f670b65d96e7": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/ad25d5ef-8d4f-425f-9dbd-0b018f1771cc",
            "firstName": "James Todd",
            "lastName": "Rutherford",
            "fullName": "Todd Rutherford",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1614772534.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-SC-74-lower-ocd-person-ad25d5ef-8d4f-425f-9dbd-0b018f1771cc",
              "builtID": "sc-lower-74",
              "externalID": "ocd-person/ad25d5ef-8d4f-425f-9dbd-0b018f1771cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "toddrutherford@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1614772534",
                "https://www.scstatehouse.gov/member.php?code=1614772534"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-74-lower-ocd-person-ad25d5ef-8d4f-425f-9dbd-0b018f1771cc": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/573c5456-8b53-4a00-9538-1013f0fc12c5",
            "firstName": "Heather",
            "lastName": "Bauer",
            "fullName": "Heather Bauer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0125568167.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-SC-75-lower-ocd-person-573c5456-8b53-4a00-9538-1013f0fc12c5",
              "builtID": "sc-lower-75",
              "externalID": "ocd-person/573c5456-8b53-4a00-9538-1013f0fc12c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "heatherbauer@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0125568167",
                "https://www.scstatehouse.gov/member.php?code=0125568167"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-75-lower-ocd-person-573c5456-8b53-4a00-9538-1013f0fc12c5": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/a7665bc0-b03c-419e-976c-b3a25ebf358e",
            "firstName": "Leon",
            "lastName": "Howard",
            "fullName": "Leon Howard",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0879545349.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-SC-76-lower-ocd-person-a7665bc0-b03c-419e-976c-b3a25ebf358e",
              "builtID": "sc-lower-76",
              "externalID": "ocd-person/a7665bc0-b03c-419e-976c-b3a25ebf358e",
              "geometry": null
            },
            "contactInfo": {
              "email": "lh@schouse.org",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0879545349",
                "https://www.scstatehouse.gov/member.php?code=0879545349"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-76-lower-ocd-person-a7665bc0-b03c-419e-976c-b3a25ebf358e": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/904331e5-36ae-43d5-8e3e-62a845c7965e",
            "firstName": "Kambrell Houston",
            "lastName": "Garvin",
            "fullName": "Kambrell Garvin",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0657954467.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-SC-77-lower-ocd-person-904331e5-36ae-43d5-8e3e-62a845c7965e",
              "builtID": "sc-lower-77",
              "externalID": "ocd-person/904331e5-36ae-43d5-8e3e-62a845c7965e",
              "geometry": null
            },
            "contactInfo": {
              "email": "kambrellgarvin@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0657954467",
                "https://www.scstatehouse.gov/member.php?code=0657954467"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-77-lower-ocd-person-904331e5-36ae-43d5-8e3e-62a845c7965e": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/a45d8c37-372d-4d32-b9a5-cce96299ea3d",
            "firstName": "Beth E.",
            "lastName": "Bernstein",
            "fullName": "Beth Bernstein",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0141477256.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-SC-78-lower-ocd-person-a45d8c37-372d-4d32-b9a5-cce96299ea3d",
              "builtID": "sc-lower-78",
              "externalID": "ocd-person/a45d8c37-372d-4d32-b9a5-cce96299ea3d",
              "geometry": null
            },
            "contactInfo": {
              "email": "bethbernstein@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0141477256",
                "https://www.scstatehouse.gov/member.php?code=0141477256"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-78-lower-ocd-person-a45d8c37-372d-4d32-b9a5-cce96299ea3d": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/13c06705-600b-49d0-a5a0-393668a03e73",
            "firstName": "Ivory Torrey",
            "lastName": "Thigpen",
            "fullName": "Ivory Thigpen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1815908873.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-SC-79-lower-ocd-person-13c06705-600b-49d0-a5a0-393668a03e73",
              "builtID": "sc-lower-79",
              "externalID": "ocd-person/13c06705-600b-49d0-a5a0-393668a03e73",
              "geometry": null
            },
            "contactInfo": {
              "email": "ivorythigpen@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1815908873",
                "https://www.scstatehouse.gov/member.php?code=1815908873"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-79-lower-ocd-person-13c06705-600b-49d0-a5a0-393668a03e73": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/07274f31-3348-491d-af78-4eefad9d39c8",
            "firstName": "Katherine DuBeau",
            "lastName": "Landing",
            "fullName": "Kathy Landing",
            "gender": "Female",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1066477145.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-SC-80-lower-ocd-person-07274f31-3348-491d-af78-4eefad9d39c8",
              "builtID": "sc-lower-80",
              "externalID": "ocd-person/07274f31-3348-491d-af78-4eefad9d39c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "kathylanding@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1066477145",
                "https://www.scstatehouse.gov/member.php?code=1066477145"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-80-lower-ocd-person-07274f31-3348-491d-af78-4eefad9d39c8": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/7b3e5580-ce7c-4c48-b021-173b8cbd8a77",
            "firstName": "Bart T.",
            "lastName": "Blackwell",
            "fullName": "Bart Blackwell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0144318165.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-SC-81-lower-ocd-person-7b3e5580-ce7c-4c48-b021-173b8cbd8a77",
              "builtID": "sc-lower-81",
              "externalID": "ocd-person/7b3e5580-ce7c-4c48-b021-173b8cbd8a77",
              "geometry": null
            },
            "contactInfo": {
              "email": "bartblackwell@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0144318165",
                "https://www.scstatehouse.gov/member.php?code=0144318165"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-81-lower-ocd-person-7b3e5580-ce7c-4c48-b021-173b8cbd8a77": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/58d2072a-c96a-49b2-8591-a4e186dc28eb",
            "firstName": "William",
            "lastName": "Clyburn",
            "fullName": "Bill Clyburn",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0363636320.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-SC-82-lower-ocd-person-58d2072a-c96a-49b2-8591-a4e186dc28eb",
              "builtID": "sc-lower-82",
              "externalID": "ocd-person/58d2072a-c96a-49b2-8591-a4e186dc28eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "billclyburn@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0363636320",
                "https://www.scstatehouse.gov/member.php?code=0363636320"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-82-lower-ocd-person-58d2072a-c96a-49b2-8591-a4e186dc28eb": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/d1f25d1c-bd0e-4d0d-be0e-f8f3edad8a3d",
            "firstName": "William M.",
            "lastName": "Hixon",
            "fullName": "Bill Hixon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0849715808.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-SC-83-lower-ocd-person-d1f25d1c-bd0e-4d0d-be0e-f8f3edad8a3d",
              "builtID": "sc-lower-83",
              "externalID": "ocd-person/d1f25d1c-bd0e-4d0d-be0e-f8f3edad8a3d",
              "geometry": null
            },
            "contactInfo": {
              "email": "billhixon@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0849715808",
                "https://www.scstatehouse.gov/member.php?code=0849715808"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-83-lower-ocd-person-d1f25d1c-bd0e-4d0d-be0e-f8f3edad8a3d": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/b3dcafed-7f7a-4b8b-8a5e-a517d768ed42",
            "firstName": "Melissa Lackey",
            "lastName": "Oremus",
            "fullName": "Melissa Oremus",
            "gender": "Female",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1432386192.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-SC-84-lower-ocd-person-b3dcafed-7f7a-4b8b-8a5e-a517d768ed42",
              "builtID": "sc-lower-84",
              "externalID": "ocd-person/b3dcafed-7f7a-4b8b-8a5e-a517d768ed42",
              "geometry": null
            },
            "contactInfo": {
              "email": "melissaoremus@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1432386192",
                "https://www.scstatehouse.gov/member.php?code=1432386192"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-84-lower-ocd-person-b3dcafed-7f7a-4b8b-8a5e-a517d768ed42": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/5a7a2735-d539-4aa0-b1a9-2f0e3828a02b",
            "firstName": "John Gregory",
            "lastName": "Kilmartin",
            "fullName": "Jay Kilmartin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0993323745.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-SC-85-lower-ocd-person-5a7a2735-d539-4aa0-b1a9-2f0e3828a02b",
              "builtID": "sc-lower-85",
              "externalID": "ocd-person/5a7a2735-d539-4aa0-b1a9-2f0e3828a02b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jaykilmartin@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0993323745",
                "https://www.scstatehouse.gov/member.php?code=0993323745"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-85-lower-ocd-person-5a7a2735-d539-4aa0-b1a9-2f0e3828a02b": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/010940bd-8041-4f17-b425-5f42c8c07526",
            "firstName": "Bill",
            "lastName": "Taylor",
            "fullName": "Bill Taylor",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1811363419.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-SC-86-lower-ocd-person-010940bd-8041-4f17-b425-5f42c8c07526",
              "builtID": "sc-lower-86",
              "externalID": "ocd-person/010940bd-8041-4f17-b425-5f42c8c07526",
              "geometry": null
            },
            "contactInfo": {
              "email": "billtaylor@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1811363419",
                "https://www.scstatehouse.gov/member.php?code=1811363419"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-86-lower-ocd-person-010940bd-8041-4f17-b425-5f42c8c07526": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/ee278d2b-ce89-40bf-af2c-42ede04e3afd",
            "firstName": "Paula Rawl",
            "lastName": "Calhoon",
            "fullName": "Paula Calhoon",
            "gender": "Female",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0293181783.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-SC-87-lower-ocd-person-ee278d2b-ce89-40bf-af2c-42ede04e3afd",
              "builtID": "sc-lower-87",
              "externalID": "ocd-person/ee278d2b-ce89-40bf-af2c-42ede04e3afd",
              "geometry": null
            },
            "contactInfo": {
              "email": "paulacalhoon@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0293181783",
                "https://www.scstatehouse.gov/member.php?code=0293181783"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-87-lower-ocd-person-ee278d2b-ce89-40bf-af2c-42ede04e3afd": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/4c9eaeab-83b6-4c51-b72f-eecc48736673",
            "firstName": "Robert J.",
            "lastName": "May",
            "fullName": "R.J. May",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1195454402.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-SC-88-lower-ocd-person-4c9eaeab-83b6-4c51-b72f-eecc48736673",
              "builtID": "sc-lower-88",
              "externalID": "ocd-person/4c9eaeab-83b6-4c51-b72f-eecc48736673",
              "geometry": null
            },
            "contactInfo": {
              "email": "rjmay@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1195454402",
                "https://www.scstatehouse.gov/member.php?code=1195454402"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-88-lower-ocd-person-4c9eaeab-83b6-4c51-b72f-eecc48736673": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/32c6ccd9-4b56-4075-a99b-1882f4dd176d",
            "firstName": "Micajah Pickett",
            "lastName": "Caskey",
            "fullName": "Micah Caskey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0329545415.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-SC-89-lower-ocd-person-32c6ccd9-4b56-4075-a99b-1882f4dd176d",
              "builtID": "sc-lower-89",
              "externalID": "ocd-person/32c6ccd9-4b56-4075-a99b-1882f4dd176d",
              "geometry": null
            },
            "contactInfo": {
              "email": "micahcaskey@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0329545415",
                "https://www.scstatehouse.gov/member.php?code=0329545415"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-89-lower-ocd-person-32c6ccd9-4b56-4075-a99b-1882f4dd176d": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/88861a7f-ca46-4ed7-a053-0d463cca48e0",
            "firstName": "Justin T.",
            "lastName": "Bamberg",
            "fullName": "Justin Bamberg",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0102840897.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-SC-90-lower-ocd-person-88861a7f-ca46-4ed7-a053-0d463cca48e0",
              "builtID": "sc-lower-90",
              "externalID": "ocd-person/88861a7f-ca46-4ed7-a053-0d463cca48e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "justinbamberg@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0102840897",
                "https://www.scstatehouse.gov/member.php?code=0102840897"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-90-lower-ocd-person-88861a7f-ca46-4ed7-a053-0d463cca48e0": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/a02e7674-1693-446b-8863-3d7df87a20fa",
            "firstName": "Lonnie",
            "lastName": "Hosey",
            "fullName": "Lonnie Hosey",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0874999895.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-SC-91-lower-ocd-person-a02e7674-1693-446b-8863-3d7df87a20fa",
              "builtID": "sc-lower-91",
              "externalID": "ocd-person/a02e7674-1693-446b-8863-3d7df87a20fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "onniehosey@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0874999895",
                "https://www.scstatehouse.gov/member.php?code=0874999895"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-91-lower-ocd-person-a02e7674-1693-446b-8863-3d7df87a20fa": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/d09020dc-87bc-49b4-a039-2a2809ab88cc",
            "firstName": "Brandon L.",
            "lastName": "Cox",
            "fullName": "Brandon Cox",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0428124949.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-SC-92-lower-ocd-person-d09020dc-87bc-49b4-a039-2a2809ab88cc",
              "builtID": "sc-lower-92",
              "externalID": "ocd-person/d09020dc-87bc-49b4-a039-2a2809ab88cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandoncox@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0428124949",
                "https://www.scstatehouse.gov/member.php?code=0428124949"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-92-lower-ocd-person-d09020dc-87bc-49b4-a039-2a2809ab88cc": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/458d191f-6584-4548-b464-a3a24cda3cf3",
            "firstName": "Russell L.",
            "lastName": "Ott",
            "fullName": "Russell Ott",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1433096420.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-SC-93-lower-ocd-person-458d191f-6584-4548-b464-a3a24cda3cf3",
              "builtID": "sc-lower-93",
              "externalID": "ocd-person/458d191f-6584-4548-b464-a3a24cda3cf3",
              "geometry": null
            },
            "contactInfo": {
              "email": "russellott@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1433096420",
                "https://www.scstatehouse.gov/member.php?code=1433096420"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-93-lower-ocd-person-458d191f-6584-4548-b464-a3a24cda3cf3": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/21d2b4a4-f5fa-415b-8394-02e9b63c4ba0",
            "firstName": "Gil A.",
            "lastName": "Gatch",
            "fullName": "Gil Gatch",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0658522648.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-SC-94-lower-ocd-person-21d2b4a4-f5fa-415b-8394-02e9b63c4ba0",
              "builtID": "sc-lower-94",
              "externalID": "ocd-person/21d2b4a4-f5fa-415b-8394-02e9b63c4ba0",
              "geometry": null
            },
            "contactInfo": {
              "email": "gilgatch@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0658522648",
                "https://www.scstatehouse.gov/member.php?code=0658522648"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-94-lower-ocd-person-21d2b4a4-f5fa-415b-8394-02e9b63c4ba0": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/b494d1f6-d98e-4a8c-895c-6cb73ec892b8",
            "firstName": "Gilda",
            "lastName": "Cobb-Hunter",
            "fullName": "Gilda Cobb-Hunter",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0372727228.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-SC-95-lower-ocd-person-b494d1f6-d98e-4a8c-895c-6cb73ec892b8",
              "builtID": "sc-lower-95",
              "externalID": "ocd-person/b494d1f6-d98e-4a8c-895c-6cb73ec892b8",
              "geometry": null
            },
            "contactInfo": {
              "email": "gildacobbhunter@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0372727228",
                "https://www.scstatehouse.gov/member.php?code=0372727228"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-95-lower-ocd-person-b494d1f6-d98e-4a8c-895c-6cb73ec892b8": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/68934441-84b3-48c7-b8a5-a694744f9e3b",
            "firstName": "Donald Ryan",
            "lastName": "McCabe",
            "fullName": "Ryan McCabe",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1206818037.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-SC-96-lower-ocd-person-68934441-84b3-48c7-b8a5-a694744f9e3b",
              "builtID": "sc-lower-96",
              "externalID": "ocd-person/68934441-84b3-48c7-b8a5-a694744f9e3b",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryanmccabe@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1206818037",
                "https://www.scstatehouse.gov/member.php?code=1206818037"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-96-lower-ocd-person-68934441-84b3-48c7-b8a5-a694744f9e3b": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/ea7da5ce-c132-44b0-8b3c-33745f9d3e4b",
            "firstName": "Robert D.",
            "lastName": "Robbins",
            "fullName": "Robby Robbins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1565340722.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-SC-97-lower-ocd-person-ea7da5ce-c132-44b0-8b3c-33745f9d3e4b",
              "builtID": "sc-lower-97",
              "externalID": "ocd-person/ea7da5ce-c132-44b0-8b3c-33745f9d3e4b",
              "geometry": null
            },
            "contactInfo": {
              "email": "robertrobbins@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1565340722",
                "https://www.scstatehouse.gov/member.php?code=1565340722"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-97-lower-ocd-person-ea7da5ce-c132-44b0-8b3c-33745f9d3e4b": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/1dad9d19-81d6-445c-8c7e-3c014bff257b",
            "firstName": "Christopher J.",
            "lastName": "Murphy",
            "fullName": "Chris Murphy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1394318015.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-SC-98-lower-ocd-person-1dad9d19-81d6-445c-8c7e-3c014bff257b",
              "builtID": "sc-lower-98",
              "externalID": "ocd-person/1dad9d19-81d6-445c-8c7e-3c014bff257b",
              "geometry": null
            },
            "contactInfo": {
              "email": "chrismurphy@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1394318015",
                "https://www.scstatehouse.gov/member.php?code=1394318015"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-98-lower-ocd-person-1dad9d19-81d6-445c-8c7e-3c014bff257b": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/9d1ecabb-7f76-4f2e-9951-03599223ca4c",
            "firstName": "Marvin M.",
            "lastName": "Smith",
            "fullName": "Mark Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1724999793.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-SC-99-lower-ocd-person-9d1ecabb-7f76-4f2e-9951-03599223ca4c",
              "builtID": "sc-lower-99",
              "externalID": "ocd-person/9d1ecabb-7f76-4f2e-9951-03599223ca4c",
              "geometry": null
            },
            "contactInfo": {
              "email": "marksmith@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1724999793",
                "https://www.scstatehouse.gov/member.php?code=1724999793"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-99-lower-ocd-person-9d1ecabb-7f76-4f2e-9951-03599223ca4c": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/36dea3e2-2ea8-47e8-84df-cfc4a28073e7",
            "firstName": "Sylleste Helms",
            "lastName": "Davis",
            "fullName": "Sylleste Davis",
            "gender": "Female",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0456249946.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-SC-100-lower-ocd-person-36dea3e2-2ea8-47e8-84df-cfc4a28073e7",
              "builtID": "sc-lower-100",
              "externalID": "ocd-person/36dea3e2-2ea8-47e8-84df-cfc4a28073e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "syllestedavis@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0456249946",
                "https://www.scstatehouse.gov/member.php?code=0456249946"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-100-lower-ocd-person-36dea3e2-2ea8-47e8-84df-cfc4a28073e7": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/9aa18702-15a2-4281-926b-4e980f2e8aa0",
            "firstName": "Roger K.",
            "lastName": "Kirby",
            "fullName": "Roger Kirby",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1006818061.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-SC-101-lower-ocd-person-9aa18702-15a2-4281-926b-4e980f2e8aa0",
              "builtID": "sc-lower-101",
              "externalID": "ocd-person/9aa18702-15a2-4281-926b-4e980f2e8aa0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rogerkirby61@gmail.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1006818061",
                "https://www.scstatehouse.gov/member.php?code=1006818061"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-101-lower-ocd-person-9aa18702-15a2-4281-926b-4e980f2e8aa0": 0
        }
      },
      "102": {
        "members": [
          {
            "API_ID": "ocd-person/5fa08360-7109-4622-aac7-7bdf55c6c245",
            "firstName": "Joseph H.",
            "lastName": "Jefferson",
            "fullName": "Joseph Jefferson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0924999889.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "102",
              "chamber": "lower",
              "hashID": "SLDL-SC-102-lower-ocd-person-5fa08360-7109-4622-aac7-7bdf55c6c245",
              "builtID": "sc-lower-102",
              "externalID": "ocd-person/5fa08360-7109-4622-aac7-7bdf55c6c245",
              "geometry": null
            },
            "contactInfo": {
              "email": "joesphjefferson@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0924999889",
                "https://www.scstatehouse.gov/member.php?code=0924999889"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-102-lower-ocd-person-5fa08360-7109-4622-aac7-7bdf55c6c245": 0
        }
      },
      "103": {
        "members": [
          {
            "API_ID": "ocd-person/c3b55867-85d7-4bf2-b1a0-30dd924ae734",
            "firstName": "Carl L.",
            "lastName": "Anderson",
            "fullName": "Carl Anderson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0021590907.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "103",
              "chamber": "lower",
              "hashID": "SLDL-SC-103-lower-ocd-person-c3b55867-85d7-4bf2-b1a0-30dd924ae734",
              "builtID": "sc-lower-103",
              "externalID": "ocd-person/c3b55867-85d7-4bf2-b1a0-30dd924ae734",
              "geometry": null
            },
            "contactInfo": {
              "email": "carlanderson@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0021590907",
                "https://www.scstatehouse.gov/member.php?code=0021590907"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-103-lower-ocd-person-c3b55867-85d7-4bf2-b1a0-30dd924ae734": 0
        }
      },
      "104": {
        "members": [
          {
            "API_ID": "ocd-person/40032da4-e72a-445f-b2df-7975724e64bb",
            "firstName": "William H.",
            "lastName": "Bailey",
            "fullName": "William Bailey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0088636353.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "104",
              "chamber": "lower",
              "hashID": "SLDL-SC-104-lower-ocd-person-40032da4-e72a-445f-b2df-7975724e64bb",
              "builtID": "sc-lower-104",
              "externalID": "ocd-person/40032da4-e72a-445f-b2df-7975724e64bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "williambailey@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0088636353",
                "https://www.scstatehouse.gov/member.php?code=0088636353"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-104-lower-ocd-person-40032da4-e72a-445f-b2df-7975724e64bb": 0
        }
      },
      "105": {
        "members": [
          {
            "API_ID": "ocd-person/ad6c27e9-3571-477c-9d94-92127d0020e7",
            "firstName": "Kevin J.",
            "lastName": "Hardee",
            "fullName": "Kevin Hardee",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0743749911.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "105",
              "chamber": "lower",
              "hashID": "SLDL-SC-105-lower-ocd-person-ad6c27e9-3571-477c-9d94-92127d0020e7",
              "builtID": "sc-lower-105",
              "externalID": "ocd-person/ad6c27e9-3571-477c-9d94-92127d0020e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevinhardee@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0743749911",
                "https://www.scstatehouse.gov/member.php?code=0743749911"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-105-lower-ocd-person-ad6c27e9-3571-477c-9d94-92127d0020e7": 0
        }
      },
      "106": {
        "members": [
          {
            "API_ID": "ocd-person/f8956197-cf73-4a14-927d-c3a0f62cff4c",
            "firstName": "Thomas Duval",
            "lastName": "Guest",
            "fullName": "Val Guest",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0730113549.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "106",
              "chamber": "lower",
              "hashID": "SLDL-SC-106-lower-ocd-person-f8956197-cf73-4a14-927d-c3a0f62cff4c",
              "builtID": "sc-lower-106",
              "externalID": "ocd-person/f8956197-cf73-4a14-927d-c3a0f62cff4c",
              "geometry": null
            },
            "contactInfo": {
              "email": "valguest@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0730113549",
                "https://www.scstatehouse.gov/member.php?code=0730113549"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-106-lower-ocd-person-f8956197-cf73-4a14-927d-c3a0f62cff4c": 0
        }
      },
      "107": {
        "members": [
          {
            "API_ID": "ocd-person/30329193-5519-45c5-a7de-f841e62b91ac",
            "firstName": "Thomas C.",
            "lastName": "Brittain",
            "fullName": "Case Brittain",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0202272703.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "107",
              "chamber": "lower",
              "hashID": "SLDL-SC-107-lower-ocd-person-30329193-5519-45c5-a7de-f841e62b91ac",
              "builtID": "sc-lower-107",
              "externalID": "ocd-person/30329193-5519-45c5-a7de-f841e62b91ac",
              "geometry": null
            },
            "contactInfo": {
              "email": "casebrittain@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0202272703",
                "https://www.scstatehouse.gov/member.php?code=0202272703"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-107-lower-ocd-person-30329193-5519-45c5-a7de-f841e62b91ac": 0
        }
      },
      "108": {
        "members": [
          {
            "API_ID": "ocd-person/eba98c82-1f71-45c1-951f-40076eb34a8b",
            "firstName": "Lee",
            "lastName": "Hewitt",
            "fullName": "Lee Hewitt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0834658991.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "108",
              "chamber": "lower",
              "hashID": "SLDL-SC-108-lower-ocd-person-eba98c82-1f71-45c1-951f-40076eb34a8b",
              "builtID": "sc-lower-108",
              "externalID": "ocd-person/eba98c82-1f71-45c1-951f-40076eb34a8b",
              "geometry": null
            },
            "contactInfo": {
              "email": "leehewitt@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0834658991",
                "https://www.scstatehouse.gov/member.php?code=0834658991"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-108-lower-ocd-person-eba98c82-1f71-45c1-951f-40076eb34a8b": 0
        }
      },
      "109": {
        "members": [
          {
            "API_ID": "ocd-person/e7352f8b-8faf-42e3-8216-b480056968c0",
            "firstName": "Tiffany R.",
            "lastName": "Spann-Wilder",
            "fullName": "Tiffany Spann-Wilder",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "109",
              "chamber": "lower",
              "hashID": "SLDL-SC-109-lower-ocd-person-e7352f8b-8faf-42e3-8216-b480056968c0",
              "builtID": "sc-lower-109",
              "externalID": "ocd-person/e7352f8b-8faf-42e3-8216-b480056968c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "tiffanyspannwilder@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.scstatehouse.gov/member.php?code=1742897519"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-109-lower-ocd-person-e7352f8b-8faf-42e3-8216-b480056968c0": 0
        }
      },
      "110": {
        "members": [
          {
            "API_ID": "ocd-person/3635c44f-6b49-4e42-bbd7-10dac688f92f",
            "firstName": "Thomas F.",
            "lastName": "Hartnett",
            "fullName": "Tom Hartnett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0774999907.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "110",
              "chamber": "lower",
              "hashID": "SLDL-SC-110-lower-ocd-person-3635c44f-6b49-4e42-bbd7-10dac688f92f",
              "builtID": "sc-lower-110",
              "externalID": "ocd-person/3635c44f-6b49-4e42-bbd7-10dac688f92f",
              "geometry": null
            },
            "contactInfo": {
              "email": "tomhartnett@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0774999907",
                "https://www.scstatehouse.gov/member.php?code=0774999907"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-110-lower-ocd-person-3635c44f-6b49-4e42-bbd7-10dac688f92f": 0
        }
      },
      "111": {
        "members": [
          {
            "API_ID": "ocd-person/7d11e90b-a028-4947-89d1-ac0788530446",
            "firstName": "Wendell G.",
            "lastName": "Gilliard",
            "fullName": "Wendell Gilliard",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0675568101.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "111",
              "chamber": "lower",
              "hashID": "SLDL-SC-111-lower-ocd-person-7d11e90b-a028-4947-89d1-ac0788530446",
              "builtID": "sc-lower-111",
              "externalID": "ocd-person/7d11e90b-a028-4947-89d1-ac0788530446",
              "geometry": null
            },
            "contactInfo": {
              "email": "wendellgilliard@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0675568101",
                "https://www.scstatehouse.gov/member.php?code=0675568101"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-111-lower-ocd-person-7d11e90b-a028-4947-89d1-ac0788530446": 0
        }
      },
      "112": {
        "members": [
          {
            "API_ID": "ocd-person/35125937-cf55-4408-883f-6f81e45b0063",
            "firstName": "Joseph M.",
            "lastName": "Bustos",
            "fullName": "Joe Bustos",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0278409057.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "112",
              "chamber": "lower",
              "hashID": "SLDL-SC-112-lower-ocd-person-35125937-cf55-4408-883f-6f81e45b0063",
              "builtID": "sc-lower-112",
              "externalID": "ocd-person/35125937-cf55-4408-883f-6f81e45b0063",
              "geometry": null
            },
            "contactInfo": {
              "email": "joebustos@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0278409057",
                "https://www.scstatehouse.gov/member.php?code=0278409057"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-112-lower-ocd-person-35125937-cf55-4408-883f-6f81e45b0063": 0
        }
      },
      "113": {
        "members": [
          {
            "API_ID": "ocd-person/b8b48cdf-733e-4317-988f-9262b2a4ba77",
            "firstName": "Marvin R.",
            "lastName": "Pendarvis",
            "fullName": "Marvin Pendarvis",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1457812326.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "113",
              "chamber": "lower",
              "hashID": "SLDL-SC-113-lower-ocd-person-b8b48cdf-733e-4317-988f-9262b2a4ba77",
              "builtID": "sc-lower-113",
              "externalID": "ocd-person/b8b48cdf-733e-4317-988f-9262b2a4ba77",
              "geometry": null
            },
            "contactInfo": {
              "email": "marvinpendarvis@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1457812326",
                "https://www.scstatehouse.gov/member.php?code=1457812326"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-113-lower-ocd-person-b8b48cdf-733e-4317-988f-9262b2a4ba77": 0
        }
      },
      "114": {
        "members": [
          {
            "API_ID": "ocd-person/774393b0-877d-4c4b-81cf-daf0494328f9",
            "firstName": "Gary S.",
            "lastName": "Brewer",
            "fullName": "Gary Brewer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0192045432.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "114",
              "chamber": "lower",
              "hashID": "SLDL-SC-114-lower-ocd-person-774393b0-877d-4c4b-81cf-daf0494328f9",
              "builtID": "sc-lower-114",
              "externalID": "ocd-person/774393b0-877d-4c4b-81cf-daf0494328f9",
              "geometry": null
            },
            "contactInfo": {
              "email": "garybrewer@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0192045432",
                "https://www.scstatehouse.gov/member.php?code=0192045432"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-114-lower-ocd-person-774393b0-877d-4c4b-81cf-daf0494328f9": 0
        }
      },
      "115": {
        "members": [
          {
            "API_ID": "ocd-person/a0512457-cf73-4c3a-8190-d381a90af2d8",
            "firstName": "Elizabeth",
            "lastName": "Wetmore",
            "fullName": "Spencer Wetmore",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1905681590.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "115",
              "chamber": "lower",
              "hashID": "SLDL-SC-115-lower-ocd-person-a0512457-cf73-4c3a-8190-d381a90af2d8",
              "builtID": "sc-lower-115",
              "externalID": "ocd-person/a0512457-cf73-4c3a-8190-d381a90af2d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "spencerwetmore@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1905681590",
                "https://www.scstatehouse.gov/member.php?code=1905681590"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-115-lower-ocd-person-a0512457-cf73-4c3a-8190-d381a90af2d8": 0
        }
      },
      "116": {
        "members": [
          {
            "API_ID": "ocd-person/1bf0b19d-9fa1-474a-99f2-16e7835064cf",
            "firstName": "Matthew W.",
            "lastName": "Leber",
            "fullName": "Matt Leber",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1074431690.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "116",
              "chamber": "lower",
              "hashID": "SLDL-SC-116-lower-ocd-person-1bf0b19d-9fa1-474a-99f2-16e7835064cf",
              "builtID": "sc-lower-116",
              "externalID": "ocd-person/1bf0b19d-9fa1-474a-99f2-16e7835064cf",
              "geometry": null
            },
            "contactInfo": {
              "email": "mattleber@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1074431690",
                "https://www.scstatehouse.gov/member.php?code=1074431690"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-116-lower-ocd-person-1bf0b19d-9fa1-474a-99f2-16e7835064cf": 0
        }
      },
      "117": {
        "members": [
          {
            "API_ID": "ocd-person/ec3b270f-6396-440c-89c1-e42262d02477",
            "firstName": "Jordan Scott",
            "lastName": "Pace",
            "fullName": "Jordan Pace",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1433984204.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "117",
              "chamber": "lower",
              "hashID": "SLDL-SC-117-lower-ocd-person-ec3b270f-6396-440c-89c1-e42262d02477",
              "builtID": "sc-lower-117",
              "externalID": "ocd-person/ec3b270f-6396-440c-89c1-e42262d02477",
              "geometry": null
            },
            "contactInfo": {
              "email": "jordanpace@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1433984204",
                "https://www.scstatehouse.gov/member.php?code=1433984204"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-117-lower-ocd-person-ec3b270f-6396-440c-89c1-e42262d02477": 0
        }
      },
      "118": {
        "members": [
          {
            "API_ID": "ocd-person/e27051a1-60e8-4ad9-b1d2-55360e09ea8c",
            "firstName": "William G.",
            "lastName": "Herbkersman",
            "fullName": "Bill Herbkersman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0832954446.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "118",
              "chamber": "lower",
              "hashID": "SLDL-SC-118-lower-ocd-person-e27051a1-60e8-4ad9-b1d2-55360e09ea8c",
              "builtID": "sc-lower-118",
              "externalID": "ocd-person/e27051a1-60e8-4ad9-b1d2-55360e09ea8c",
              "geometry": null
            },
            "contactInfo": {
              "email": "billherbkerman@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0832954446",
                "https://www.scstatehouse.gov/member.php?code=0832954446"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-118-lower-ocd-person-e27051a1-60e8-4ad9-b1d2-55360e09ea8c": 0
        }
      },
      "119": {
        "members": [
          {
            "API_ID": "ocd-person/3faa0b04-4277-4a07-ae78-bc9cb0d20e07",
            "firstName": "Leonidas E.",
            "lastName": "Stavrinakis",
            "fullName": "Leon Stavrinakis",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1747727063.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "119",
              "chamber": "lower",
              "hashID": "SLDL-SC-119-lower-ocd-person-3faa0b04-4277-4a07-ae78-bc9cb0d20e07",
              "builtID": "sc-lower-119",
              "externalID": "ocd-person/3faa0b04-4277-4a07-ae78-bc9cb0d20e07",
              "geometry": null
            },
            "contactInfo": {
              "email": "leonstavrinakis@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1747727063",
                "https://www.scstatehouse.gov/member.php?code=1747727063"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-119-lower-ocd-person-3faa0b04-4277-4a07-ae78-bc9cb0d20e07": 0
        }
      },
      "120": {
        "members": [
          {
            "API_ID": "ocd-person/eba4b1a4-14ca-44dd-93ab-25cdd3cbd5fd",
            "firstName": "William Weston Jones",
            "lastName": "Newton",
            "fullName": "Weston Newton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1418749830.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "120",
              "chamber": "lower",
              "hashID": "SLDL-SC-120-lower-ocd-person-eba4b1a4-14ca-44dd-93ab-25cdd3cbd5fd",
              "builtID": "sc-lower-120",
              "externalID": "ocd-person/eba4b1a4-14ca-44dd-93ab-25cdd3cbd5fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "westonnewton@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1418749830",
                "https://www.scstatehouse.gov/member.php?code=1418749830"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-120-lower-ocd-person-eba4b1a4-14ca-44dd-93ab-25cdd3cbd5fd": 0
        }
      },
      "121": {
        "members": [
          {
            "API_ID": "ocd-person/ebf046cf-233e-4c98-b78e-4cefbb07615b",
            "firstName": "Michael F.",
            "lastName": "Rivers",
            "fullName": "Michael Rivers",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1564346404.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "121",
              "chamber": "lower",
              "hashID": "SLDL-SC-121-lower-ocd-person-ebf046cf-233e-4c98-b78e-4cefbb07615b",
              "builtID": "sc-lower-121",
              "externalID": "ocd-person/ebf046cf-233e-4c98-b78e-4cefbb07615b",
              "geometry": null
            },
            "contactInfo": {
              "email": "michaelrivers@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1564346404",
                "https://www.scstatehouse.gov/member.php?code=1564346404"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-121-lower-ocd-person-ebf046cf-233e-4c98-b78e-4cefbb07615b": 0
        }
      },
      "122": {
        "members": [
          {
            "API_ID": "ocd-person/066a2879-ab95-4082-803d-9c4dd582f04e",
            "firstName": "William",
            "lastName": "Hager",
            "fullName": "Bill Hager",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0733948776.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "122",
              "chamber": "lower",
              "hashID": "SLDL-SC-122-lower-ocd-person-066a2879-ab95-4082-803d-9c4dd582f04e",
              "builtID": "sc-lower-122",
              "externalID": "ocd-person/066a2879-ab95-4082-803d-9c4dd582f04e",
              "geometry": null
            },
            "contactInfo": {
              "email": "billhager@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0733948776",
                "https://www.scstatehouse.gov/member.php?code=0733948776"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-122-lower-ocd-person-066a2879-ab95-4082-803d-9c4dd582f04e": 0
        }
      },
      "123": {
        "members": [
          {
            "API_ID": "ocd-person/99dd735b-3ab4-4362-a2c5-f1b5c9257e52",
            "firstName": "Jeffrey A.",
            "lastName": "Bradley",
            "fullName": "Jeff Bradley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0171590889.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "123",
              "chamber": "lower",
              "hashID": "SLDL-SC-123-lower-ocd-person-99dd735b-3ab4-4362-a2c5-f1b5c9257e52",
              "builtID": "sc-lower-123",
              "externalID": "ocd-person/99dd735b-3ab4-4362-a2c5-f1b5c9257e52",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeffbradley@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 11867, Columbia, SC 29211",
              "phone1": null,
              "phone2": "843-342-6918",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0171590889",
                "https://www.scstatehouse.gov/member.php?code=0171590889"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-123-lower-ocd-person-99dd735b-3ab4-4362-a2c5-f1b5c9257e52": 0
        }
      },
      "124": {
        "members": [
          {
            "API_ID": "ocd-person/2bc4de0d-45ee-4cb7-8fda-f0a54a9a7492",
            "firstName": "Shannon S.",
            "lastName": "Erickson",
            "fullName": "Shannon Erickson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0539772663.jpg",
            "title": "SC Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "124",
              "chamber": "lower",
              "hashID": "SLDL-SC-124-lower-ocd-person-2bc4de0d-45ee-4cb7-8fda-f0a54a9a7492",
              "builtID": "sc-lower-124",
              "externalID": "ocd-person/2bc4de0d-45ee-4cb7-8fda-f0a54a9a7492",
              "geometry": null
            },
            "contactInfo": {
              "email": "shannonerickson@schouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0539772663",
                "https://www.scstatehouse.gov/member.php?code=0539772663"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SC-124-lower-ocd-person-2bc4de0d-45ee-4cb7-8fda-f0a54a9a7492": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/06c7c886-4087-499e-855c-f36b0fa75195",
            "firstName": "Thomas C.",
            "lastName": "Alexander",
            "fullName": "Thomas Alexander",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0013636362.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-SC-1-upper-ocd-person-06c7c886-4087-499e-855c-f36b0fa75195",
              "builtID": "sc-upper-1",
              "externalID": "ocd-person/06c7c886-4087-499e-855c-f36b0fa75195",
              "geometry": null
            },
            "contactInfo": {
              "email": "thomasalexander@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0013636362",
                "https://www.scstatehouse.gov/member.php?code=0013636362"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-1-upper-ocd-person-06c7c886-4087-499e-855c-f36b0fa75195": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/bfea6436-5d17-4d26-bc37-15f1a975575e",
            "firstName": "Rex Fontaine",
            "lastName": "Rice",
            "fullName": "Rex Rice",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1547727087.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-SC-2-upper-ocd-person-bfea6436-5d17-4d26-bc37-15f1a975575e",
              "builtID": "sc-upper-2",
              "externalID": "ocd-person/bfea6436-5d17-4d26-bc37-15f1a975575e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rexrice@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1547727087",
                "https://www.scstatehouse.gov/member.php?code=1547727087"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-2-upper-ocd-person-bfea6436-5d17-4d26-bc37-15f1a975575e": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/69162972-6151-4fe2-b5fe-dee599d86ad3",
            "firstName": "Richard J.",
            "lastName": "Cash",
            "fullName": "Richard Cash",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0328409052.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-SC-3-upper-ocd-person-69162972-6151-4fe2-b5fe-dee599d86ad3",
              "builtID": "sc-upper-3",
              "externalID": "ocd-person/69162972-6151-4fe2-b5fe-dee599d86ad3",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard@richardcash.sc",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0328409052",
                "https://www.scstatehouse.gov/member.php?code=0328409052"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-3-upper-ocd-person-69162972-6151-4fe2-b5fe-dee599d86ad3": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/52d745c3-ccb7-41db-9b4a-624c757b678a",
            "firstName": "Michael W.",
            "lastName": "Gambrell",
            "fullName": "Mike Gambrell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0635227197.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-SC-4-upper-ocd-person-52d745c3-ccb7-41db-9b4a-624c757b678a",
              "builtID": "sc-upper-4",
              "externalID": "ocd-person/52d745c3-ccb7-41db-9b4a-624c757b678a",
              "geometry": null
            },
            "contactInfo": {
              "email": "mwgambrell@bellsouth.net",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0635227197",
                "https://www.scstatehouse.gov/member.php?code=0635227197"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-4-upper-ocd-person-52d745c3-ccb7-41db-9b4a-624c757b678a": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/25450820-f565-4644-95aa-885948c253c3",
            "firstName": "Thomas D.",
            "lastName": "Corbin",
            "fullName": "Tom Corbin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0402272679.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-SC-5-upper-ocd-person-25450820-f565-4644-95aa-885948c253c3",
              "builtID": "sc-upper-5",
              "externalID": "ocd-person/25450820-f565-4644-95aa-885948c253c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "tomcorbin@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0402272679",
                "https://www.scstatehouse.gov/member.php?code=0402272679"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-5-upper-ocd-person-25450820-f565-4644-95aa-885948c253c3": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/7f17bd25-fbc5-48a1-a69a-5c9be03b66f5",
            "firstName": "Dwight A.",
            "lastName": "Loftis",
            "fullName": "Dwight Loftis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1111363503.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-SC-6-upper-ocd-person-7f17bd25-fbc5-48a1-a69a-5c9be03b66f5",
              "builtID": "sc-upper-6",
              "externalID": "ocd-person/7f17bd25-fbc5-48a1-a69a-5c9be03b66f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "dwightloftis@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1111363503",
                "https://www.scstatehouse.gov/member.php?code=1111363503"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-6-upper-ocd-person-7f17bd25-fbc5-48a1-a69a-5c9be03b66f5": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/946afea5-c89c-4a8f-a80e-a841bf7e899a",
            "firstName": "Karl B.",
            "lastName": "Allen",
            "fullName": "Karl Allen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0015340908.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-SC-7-upper-ocd-person-946afea5-c89c-4a8f-a80e-a841bf7e899a",
              "builtID": "sc-upper-7",
              "externalID": "ocd-person/946afea5-c89c-4a8f-a80e-a841bf7e899a",
              "geometry": null
            },
            "contactInfo": {
              "email": "karlallen@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0015340908",
                "https://www.scstatehouse.gov/member.php?code=0015340908"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-7-upper-ocd-person-946afea5-c89c-4a8f-a80e-a841bf7e899a": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/bf0e5259-d4e3-472c-a3a0-384eab1e8ae2",
            "firstName": "Clarence Ross",
            "lastName": "Turner",
            "fullName": "Ross Turner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1847727051.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-SC-8-upper-ocd-person-bf0e5259-d4e3-472c-a3a0-384eab1e8ae2",
              "builtID": "sc-upper-8",
              "externalID": "ocd-person/bf0e5259-d4e3-472c-a3a0-384eab1e8ae2",
              "geometry": null
            },
            "contactInfo": {
              "email": "rossturner@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1847727051",
                "https://www.scstatehouse.gov/member.php?code=1847727051"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-8-upper-ocd-person-bf0e5259-d4e3-472c-a3a0-384eab1e8ae2": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/0ed8d824-c5b2-47c6-9970-2c475cbfd9ac",
            "firstName": "Daniel Byron",
            "lastName": "Verdin",
            "fullName": "Danny Verdin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1874999775.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-SC-9-upper-ocd-person-0ed8d824-c5b2-47c6-9970-2c475cbfd9ac",
              "builtID": "sc-upper-9",
              "externalID": "ocd-person/0ed8d824-c5b2-47c6-9970-2c475cbfd9ac",
              "geometry": null
            },
            "contactInfo": {
              "email": "dannyverdin@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1874999775",
                "https://www.scstatehouse.gov/member.php?code=1874999775"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-9-upper-ocd-person-0ed8d824-c5b2-47c6-9970-2c475cbfd9ac": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/898bb0dc-8dd1-4c29-b7a6-04f87c7cbd2d",
            "firstName": "Billy",
            "lastName": "Garrett",
            "fullName": "Billy Garrett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0638636287.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-SC-10-upper-ocd-person-898bb0dc-8dd1-4c29-b7a6-04f87c7cbd2d",
              "builtID": "sc-upper-10",
              "externalID": "ocd-person/898bb0dc-8dd1-4c29-b7a6-04f87c7cbd2d",
              "geometry": null
            },
            "contactInfo": {
              "email": "billygarrett@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0638636287",
                "https://www.scstatehouse.gov/member.php?code=0638636287"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-10-upper-ocd-person-898bb0dc-8dd1-4c29-b7a6-04f87c7cbd2d": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/abab175f-5281-4f94-9550-09131ee156ad",
            "firstName": "Joshua Brett",
            "lastName": "Kimbrell",
            "fullName": "Josh Kimbrell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0993465790.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-SC-11-upper-ocd-person-abab175f-5281-4f94-9550-09131ee156ad",
              "builtID": "sc-upper-11",
              "externalID": "ocd-person/abab175f-5281-4f94-9550-09131ee156ad",
              "geometry": null
            },
            "contactInfo": {
              "email": "joshkimbrell@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0993465790",
                "https://www.scstatehouse.gov/member.php?code=0993465790"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-11-upper-ocd-person-abab175f-5281-4f94-9550-09131ee156ad": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/51171d8d-a51b-4684-8e45-a88ad5250314",
            "firstName": "Scott",
            "lastName": "Talley",
            "fullName": "Scott Talley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1784090695.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-SC-12-upper-ocd-person-51171d8d-a51b-4684-8e45-a88ad5250314",
              "builtID": "sc-upper-12",
              "externalID": "ocd-person/51171d8d-a51b-4684-8e45-a88ad5250314",
              "geometry": null
            },
            "contactInfo": {
              "email": "scotttalley@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1784090695",
                "https://www.scstatehouse.gov/member.php?code=1784090695"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-12-upper-ocd-person-51171d8d-a51b-4684-8e45-a88ad5250314": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/6409590b-b759-47e0-ba61-cfe5aee94228",
            "firstName": "Shane R.",
            "lastName": "Martin",
            "fullName": "Shane Martin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1179545313.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-SC-13-upper-ocd-person-6409590b-b759-47e0-ba61-cfe5aee94228",
              "builtID": "sc-upper-13",
              "externalID": "ocd-person/6409590b-b759-47e0-ba61-cfe5aee94228",
              "geometry": null
            },
            "contactInfo": {
              "email": "shanemartin@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1179545313",
                "https://www.scstatehouse.gov/member.php?code=1179545313"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-13-upper-ocd-person-6409590b-b759-47e0-ba61-cfe5aee94228": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/8b81073b-87da-4f5c-aacb-f387b69456cc",
            "firstName": "Harvey S.",
            "lastName": "Peeler",
            "fullName": "Harvey Peeler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1454545280.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-SC-14-upper-ocd-person-8b81073b-87da-4f5c-aacb-f387b69456cc",
              "builtID": "sc-upper-14",
              "externalID": "ocd-person/8b81073b-87da-4f5c-aacb-f387b69456cc",
              "geometry": null
            },
            "contactInfo": {
              "email": "harveypeeler@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1454545280",
                "https://www.scstatehouse.gov/member.php?code=1454545280"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-14-upper-ocd-person-8b81073b-87da-4f5c-aacb-f387b69456cc": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/cccdb27c-0ed3-4d26-b2e1-5dd7c94e79ab",
            "firstName": "David Wesley",
            "lastName": "Climer",
            "fullName": "Wes Climer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0356818139.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-SC-15-upper-ocd-person-cccdb27c-0ed3-4d26-b2e1-5dd7c94e79ab",
              "builtID": "sc-upper-15",
              "externalID": "ocd-person/cccdb27c-0ed3-4d26-b2e1-5dd7c94e79ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "wesclimer@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0356818139",
                "https://www.scstatehouse.gov/member.php?code=0356818139"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-15-upper-ocd-person-cccdb27c-0ed3-4d26-b2e1-5dd7c94e79ab": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/ef901990-e178-4836-b40c-1b5822227650",
            "firstName": "Michael",
            "lastName": "Johnson",
            "fullName": "Michael Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0949431704.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-SC-16-upper-ocd-person-ef901990-e178-4836-b40c-1b5822227650",
              "builtID": "sc-upper-16",
              "externalID": "ocd-person/ef901990-e178-4836-b40c-1b5822227650",
              "geometry": null
            },
            "contactInfo": {
              "email": "michaeljohnson@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0949431704",
                "https://www.scstatehouse.gov/member.php?code=0949431704"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-16-upper-ocd-person-ef901990-e178-4836-b40c-1b5822227650": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/46099016-9fd8-442b-a9fe-ed58055f7672",
            "firstName": "Michael William",
            "lastName": "Fanning",
            "fullName": "Mike Fanning",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0556818115.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-SC-17-upper-ocd-person-46099016-9fd8-442b-a9fe-ed58055f7672",
              "builtID": "sc-upper-17",
              "externalID": "ocd-person/46099016-9fd8-442b-a9fe-ed58055f7672",
              "geometry": null
            },
            "contactInfo": {
              "email": "mikefanning@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0556818115",
                "https://www.scstatehouse.gov/member.php?code=0556818115"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-17-upper-ocd-person-46099016-9fd8-442b-a9fe-ed58055f7672": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/a8c8738a-88f3-4a32-a61e-96004425b2c3",
            "firstName": "Ronnie W.",
            "lastName": "Cromer",
            "fullName": "Ronnie Cromer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0438636311.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-SC-18-upper-ocd-person-a8c8738a-88f3-4a32-a61e-96004425b2c3",
              "builtID": "sc-upper-18",
              "externalID": "ocd-person/a8c8738a-88f3-4a32-a61e-96004425b2c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "ronniecromer@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0438636311",
                "https://www.scstatehouse.gov/member.php?code=0438636311"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-18-upper-ocd-person-a8c8738a-88f3-4a32-a61e-96004425b2c3": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/51410bac-c642-4cd8-9935-dd87b16a6972",
            "firstName": "Tameika",
            "lastName": "Isaac Devine",
            "fullName": "Tameika Isaac Devine",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-SC-19-upper-ocd-person-51410bac-c642-4cd8-9935-dd87b16a6972",
              "builtID": "sc-upper-19",
              "externalID": "ocd-person/51410bac-c642-4cd8-9935-dd87b16a6972",
              "geometry": null
            },
            "contactInfo": {
              "email": "tameikaisaacdevine@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.scstatehouse.gov/member.php?code=0477840852"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-19-upper-ocd-person-51410bac-c642-4cd8-9935-dd87b16a6972": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/26d51af3-b5c2-4869-be73-0ea9c2fa2a22",
            "firstName": "Richard A.",
            "lastName": "Harpootlian",
            "fullName": "Dick Harpootlian",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0747159001.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-SC-20-upper-ocd-person-26d51af3-b5c2-4869-be73-0ea9c2fa2a22",
              "builtID": "sc-upper-20",
              "externalID": "ocd-person/26d51af3-b5c2-4869-be73-0ea9c2fa2a22",
              "geometry": null
            },
            "contactInfo": {
              "email": "dickharpootlian@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0747159001",
                "https://www.scstatehouse.gov/member.php?code=0747159001"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-20-upper-ocd-person-26d51af3-b5c2-4869-be73-0ea9c2fa2a22": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/4eca904a-5153-440d-8e5c-4c9e056a1dd1",
            "firstName": "Darrell",
            "lastName": "Jackson",
            "fullName": "Darrell Jackson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0920454435.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-SC-21-upper-ocd-person-4eca904a-5153-440d-8e5c-4c9e056a1dd1",
              "builtID": "sc-upper-21",
              "externalID": "ocd-person/4eca904a-5153-440d-8e5c-4c9e056a1dd1",
              "geometry": null
            },
            "contactInfo": {
              "email": "darrelljackson@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0920454435",
                "https://www.scstatehouse.gov/member.php?code=0920454435"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-21-upper-ocd-person-4eca904a-5153-440d-8e5c-4c9e056a1dd1": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/9b4e7cb9-4e4e-484d-8ce9-4fe4a73b8c59",
            "firstName": "Mia S.",
            "lastName": "McLeod",
            "fullName": "Mia McLeod",
            "gender": "Female",
            "party": "independent",
            "image": "https://scstatehouse.gov/images/members/0279545421.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-SC-22-upper-ocd-person-9b4e7cb9-4e4e-484d-8ce9-4fe4a73b8c59",
              "builtID": "sc-upper-22",
              "externalID": "ocd-person/9b4e7cb9-4e4e-484d-8ce9-4fe4a73b8c59",
              "geometry": null
            },
            "contactInfo": {
              "email": "mia@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0279545421",
                "https://www.scstatehouse.gov/member.php?code=0279545421"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-22-upper-ocd-person-9b4e7cb9-4e4e-484d-8ce9-4fe4a73b8c59": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/0a07f819-4966-4b86-8e1f-d6b66b447460",
            "firstName": "Katrina Frye",
            "lastName": "Shealy",
            "fullName": "Katrina Shealy",
            "gender": "Female",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1656817983.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-SC-23-upper-ocd-person-0a07f819-4966-4b86-8e1f-d6b66b447460",
              "builtID": "sc-upper-23",
              "externalID": "ocd-person/0a07f819-4966-4b86-8e1f-d6b66b447460",
              "geometry": null
            },
            "contactInfo": {
              "email": "katrinashealy@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1656817983",
                "https://www.scstatehouse.gov/member.php?code=1656817983"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-23-upper-ocd-person-0a07f819-4966-4b86-8e1f-d6b66b447460": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/b01d47f8-2131-4fe5-a611-d1b571696346",
            "firstName": "Thomas R.",
            "lastName": "Young",
            "fullName": "Tom Young",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1996590730.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-SC-24-upper-ocd-person-b01d47f8-2131-4fe5-a611-d1b571696346",
              "builtID": "sc-upper-24",
              "externalID": "ocd-person/b01d47f8-2131-4fe5-a611-d1b571696346",
              "geometry": null
            },
            "contactInfo": {
              "email": "tomyoung@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1996590730",
                "https://www.scstatehouse.gov/member.php?code=1996590730"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-24-upper-ocd-person-b01d47f8-2131-4fe5-a611-d1b571696346": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/340b7b78-4f07-49ef-8872-dab35cf48c5c",
            "firstName": "A. Shane",
            "lastName": "Massey",
            "fullName": "Shane Massey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1185227131.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-SC-25-upper-ocd-person-340b7b78-4f07-49ef-8872-dab35cf48c5c",
              "builtID": "sc-upper-25",
              "externalID": "ocd-person/340b7b78-4f07-49ef-8872-dab35cf48c5c",
              "geometry": null
            },
            "contactInfo": {
              "email": "shanemassey@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1185227131",
                "https://www.scstatehouse.gov/member.php?code=1185227131"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-25-upper-ocd-person-340b7b78-4f07-49ef-8872-dab35cf48c5c": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/e93ca357-56f3-453f-aacc-fdeb0f8a77e8",
            "firstName": "Nikki Giles",
            "lastName": "Setzler",
            "fullName": "Nikki Setzler",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1649999802.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-SC-26-upper-ocd-person-e93ca357-56f3-453f-aacc-fdeb0f8a77e8",
              "builtID": "sc-upper-26",
              "externalID": "ocd-person/e93ca357-56f3-453f-aacc-fdeb0f8a77e8",
              "geometry": null
            },
            "contactInfo": {
              "email": "nikkisetzler@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1649999802",
                "https://www.scstatehouse.gov/member.php?code=1649999802"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-26-upper-ocd-person-e93ca357-56f3-453f-aacc-fdeb0f8a77e8": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/f6e15a17-0043-49a3-a74d-8a3ba75977f0",
            "firstName": "Penry",
            "lastName": "Gustafson",
            "fullName": "Penry Gustafson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0733664686.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-SC-27-upper-ocd-person-f6e15a17-0043-49a3-a74d-8a3ba75977f0",
              "builtID": "sc-upper-27",
              "externalID": "ocd-person/f6e15a17-0043-49a3-a74d-8a3ba75977f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "penrygustafson@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0733664686",
                "https://www.scstatehouse.gov/member.php?code=0733664686"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-27-upper-ocd-person-f6e15a17-0043-49a3-a74d-8a3ba75977f0": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/d09cb270-bb44-44e7-86d6-a880ce40d93d",
            "firstName": "Greg",
            "lastName": "Hembree",
            "fullName": "Greg Hembree",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0819318084.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-SC-28-upper-ocd-person-d09cb270-bb44-44e7-86d6-a880ce40d93d",
              "builtID": "sc-upper-28",
              "externalID": "ocd-person/d09cb270-bb44-44e7-86d6-a880ce40d93d",
              "geometry": null
            },
            "contactInfo": {
              "email": "greghembree@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0819318084",
                "https://www.scstatehouse.gov/member.php?code=0819318084"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-28-upper-ocd-person-d09cb270-bb44-44e7-86d6-a880ce40d93d": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/2b3fc123-0a9b-4324-b406-1421260f7ee6",
            "firstName": "Gerald",
            "lastName": "Malloy",
            "fullName": "Gerald Malloy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1131533956.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-SC-29-upper-ocd-person-2b3fc123-0a9b-4324-b406-1421260f7ee6",
              "builtID": "sc-upper-29",
              "externalID": "ocd-person/2b3fc123-0a9b-4324-b406-1421260f7ee6",
              "geometry": null
            },
            "contactInfo": {
              "email": "geraldmalloy@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1131533956",
                "https://www.scstatehouse.gov/member.php?code=1131533956"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-29-upper-ocd-person-2b3fc123-0a9b-4324-b406-1421260f7ee6": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/0fdf2e22-badf-4b5c-be34-969781dc40a3",
            "firstName": "Kent M.",
            "lastName": "Williams",
            "fullName": "Kent Williams",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1938636131.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-SC-30-upper-ocd-person-0fdf2e22-badf-4b5c-be34-969781dc40a3",
              "builtID": "sc-upper-30",
              "externalID": "ocd-person/0fdf2e22-badf-4b5c-be34-969781dc40a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "kentwilliams@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1938636131",
                "https://www.scstatehouse.gov/member.php?code=1938636131"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-30-upper-ocd-person-0fdf2e22-badf-4b5c-be34-969781dc40a3": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/c2a237ba-67b6-4280-9cf2-430f1d10d28e",
            "firstName": "Johnathan Michael",
            "lastName": "Reichenbach",
            "fullName": "Mike Reichenbach",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1528408908.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-SC-31-upper-ocd-person-c2a237ba-67b6-4280-9cf2-430f1d10d28e",
              "builtID": "sc-upper-31",
              "externalID": "ocd-person/c2a237ba-67b6-4280-9cf2-430f1d10d28e",
              "geometry": null
            },
            "contactInfo": {
              "email": "mikereichenbach@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1528408908",
                "https://www.scstatehouse.gov/member.php?code=1528408908"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-31-upper-ocd-person-c2a237ba-67b6-4280-9cf2-430f1d10d28e": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/92a0b883-789a-4291-b2f1-330ec804ba12",
            "firstName": "Ronnie A.",
            "lastName": "Sabb",
            "fullName": "Ronnie Sabb",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1617045261.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-SC-32-upper-ocd-person-92a0b883-789a-4291-b2f1-330ec804ba12",
              "builtID": "sc-upper-32",
              "externalID": "ocd-person/92a0b883-789a-4291-b2f1-330ec804ba12",
              "geometry": null
            },
            "contactInfo": {
              "email": "ronniesabb@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1617045261",
                "https://www.scstatehouse.gov/member.php?code=1617045261"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-32-upper-ocd-person-92a0b883-789a-4291-b2f1-330ec804ba12": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/27fb6caf-f757-44a3-8973-6ce72aacd7e2",
            "firstName": "Luke A.",
            "lastName": "Rankin",
            "fullName": "Luke Rankin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1511363455.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-SC-33-upper-ocd-person-27fb6caf-f757-44a3-8973-6ce72aacd7e2",
              "builtID": "sc-upper-33",
              "externalID": "ocd-person/27fb6caf-f757-44a3-8973-6ce72aacd7e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "lukerankin@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1511363455",
                "https://www.scstatehouse.gov/member.php?code=1511363455"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-33-upper-ocd-person-27fb6caf-f757-44a3-8973-6ce72aacd7e2": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/9837e1c5-5014-4306-9f78-1550b1a9a2c2",
            "firstName": "Stephen L.",
            "lastName": "Goldfinch",
            "fullName": "Stephen Goldfinch",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0682386282.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-SC-34-upper-ocd-person-9837e1c5-5014-4306-9f78-1550b1a9a2c2",
              "builtID": "sc-upper-34",
              "externalID": "ocd-person/9837e1c5-5014-4306-9f78-1550b1a9a2c2",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephen@stephengoldfinch.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0682386282",
                "https://www.scstatehouse.gov/member.php?code=0682386282"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-34-upper-ocd-person-9837e1c5-5014-4306-9f78-1550b1a9a2c2": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/f4d5a02c-1b98-4fd1-9db4-35b1b8a79d18",
            "firstName": "Joseph Thomas",
            "lastName": "McElveen",
            "fullName": "Thomas McElveen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1234090761.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-SC-35-upper-ocd-person-f4d5a02c-1b98-4fd1-9db4-35b1b8a79d18",
              "builtID": "sc-upper-35",
              "externalID": "ocd-person/f4d5a02c-1b98-4fd1-9db4-35b1b8a79d18",
              "geometry": null
            },
            "contactInfo": {
              "email": "thomasmcelveen@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1234090761",
                "https://www.scstatehouse.gov/member.php?code=1234090761"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-35-upper-ocd-person-f4d5a02c-1b98-4fd1-9db4-35b1b8a79d18": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/87089a99-47f3-42ae-a55f-cd1a356656b5",
            "firstName": "Kevin L.",
            "lastName": "Johnson",
            "fullName": "Kevin Johnson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0947727159.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-SC-36-upper-ocd-person-87089a99-47f3-42ae-a55f-cd1a356656b5",
              "builtID": "sc-upper-36",
              "externalID": "ocd-person/87089a99-47f3-42ae-a55f-cd1a356656b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevinjohnson@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0947727159",
                "https://www.scstatehouse.gov/member.php?code=0947727159"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-36-upper-ocd-person-87089a99-47f3-42ae-a55f-cd1a356656b5": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/cc6f617e-1af7-4aa5-a9cc-a2214b2c21e2",
            "firstName": "Lawrence K.",
            "lastName": "Grooms",
            "fullName": "Larry Grooms",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0729545367.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-SC-37-upper-ocd-person-cc6f617e-1af7-4aa5-a9cc-a2214b2c21e2",
              "builtID": "sc-upper-37",
              "externalID": "ocd-person/cc6f617e-1af7-4aa5-a9cc-a2214b2c21e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "stranscomm@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0729545367",
                "https://www.scstatehouse.gov/member.php?code=0729545367"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-37-upper-ocd-person-cc6f617e-1af7-4aa5-a9cc-a2214b2c21e2": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/3265c6c1-e300-4fd3-9d61-1cef57279d67",
            "firstName": "Sean M.",
            "lastName": "Bennett",
            "fullName": "Sean Bennett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0141193165.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-SC-38-upper-ocd-person-3265c6c1-e300-4fd3-9d61-1cef57279d67",
              "builtID": "sc-upper-38",
              "externalID": "ocd-person/3265c6c1-e300-4fd3-9d61-1cef57279d67",
              "geometry": null
            },
            "contactInfo": {
              "email": "seanbennett@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0141193165",
                "https://www.scstatehouse.gov/member.php?code=0141193165"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-38-upper-ocd-person-3265c6c1-e300-4fd3-9d61-1cef57279d67": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/291bed1b-d11e-449d-88d2-25e0ef04ea3c",
            "firstName": "Vernon",
            "lastName": "Stephens",
            "fullName": "Vernon Stephens",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1752272517.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-SC-39-upper-ocd-person-291bed1b-d11e-449d-88d2-25e0ef04ea3c",
              "builtID": "sc-upper-39",
              "externalID": "ocd-person/291bed1b-d11e-449d-88d2-25e0ef04ea3c",
              "geometry": null
            },
            "contactInfo": {
              "email": "vernonstephens@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1752272517",
                "https://www.scstatehouse.gov/member.php?code=1752272517"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-39-upper-ocd-person-291bed1b-d11e-449d-88d2-25e0ef04ea3c": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/16ba6021-423d-4b0d-b899-d2fe18778c12",
            "firstName": "C. Bradley",
            "lastName": "Hutto",
            "fullName": "Brad Hutto",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0912499891.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-SC-40-upper-ocd-person-16ba6021-423d-4b0d-b899-d2fe18778c12",
              "builtID": "sc-upper-40",
              "externalID": "ocd-person/16ba6021-423d-4b0d-b899-d2fe18778c12",
              "geometry": null
            },
            "contactInfo": {
              "email": "bradhutto@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0912499891",
                "https://www.scstatehouse.gov/member.php?code=0912499891"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-40-upper-ocd-person-16ba6021-423d-4b0d-b899-d2fe18778c12": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/9bd3d162-efdd-4c99-8cdd-cccd2ad40baa",
            "firstName": "Sandra J.",
            "lastName": "Senn",
            "fullName": "Sandy Senn",
            "gender": "Female",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/1649431621.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-SC-41-upper-ocd-person-9bd3d162-efdd-4c99-8cdd-cccd2ad40baa",
              "builtID": "sc-upper-41",
              "externalID": "ocd-person/9bd3d162-efdd-4c99-8cdd-cccd2ad40baa",
              "geometry": null
            },
            "contactInfo": {
              "email": "sandysenn@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1649431621",
                "https://www.scstatehouse.gov/member.php?code=1649431621"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-41-upper-ocd-person-9bd3d162-efdd-4c99-8cdd-cccd2ad40baa": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/4f07a054-384b-4fc7-b60a-f5848ea514d4",
            "firstName": "Deon Terrell",
            "lastName": "Tedder",
            "fullName": "Deon Tedder",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/1811931601.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-SC-42-upper-ocd-person-4f07a054-384b-4fc7-b60a-f5848ea514d4",
              "builtID": "sc-upper-42",
              "externalID": "ocd-person/4f07a054-384b-4fc7-b60a-f5848ea514d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "deontedder@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=1811931601",
                "https://www.scstatehouse.gov/member.php?code=1811931601"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-42-upper-ocd-person-4f07a054-384b-4fc7-b60a-f5848ea514d4": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/2ff21c9e-e03e-4f5c-ac4e-7b1e5633969d",
            "firstName": "George E.",
            "lastName": "Campsen",
            "fullName": "Chip Campsen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0302272691.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "43",
              "chamber": "upper",
              "hashID": "SLDU-SC-43-upper-ocd-person-2ff21c9e-e03e-4f5c-ac4e-7b1e5633969d",
              "builtID": "sc-upper-43",
              "externalID": "ocd-person/2ff21c9e-e03e-4f5c-ac4e-7b1e5633969d",
              "geometry": null
            },
            "contactInfo": {
              "email": "chipcampsen@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0302272691",
                "https://www.scstatehouse.gov/member.php?code=0302272691"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-43-upper-ocd-person-2ff21c9e-e03e-4f5c-ac4e-7b1e5633969d": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/9f665ce8-ed4c-4bef-841f-3036ac03a181",
            "firstName": "Brian",
            "lastName": "Adams",
            "fullName": "Brian Adams",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0002272727.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "44",
              "chamber": "upper",
              "hashID": "SLDU-SC-44-upper-ocd-person-9f665ce8-ed4c-4bef-841f-3036ac03a181",
              "builtID": "sc-upper-44",
              "externalID": "ocd-person/9f665ce8-ed4c-4bef-841f-3036ac03a181",
              "geometry": null
            },
            "contactInfo": {
              "email": "brianadams@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0002272727",
                "https://www.scstatehouse.gov/member.php?code=0002272727"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-44-upper-ocd-person-9f665ce8-ed4c-4bef-841f-3036ac03a181": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/829bd3ab-d117-43fd-b274-f9ff8177c789",
            "firstName": "Margie",
            "lastName": "Bright Matthews",
            "fullName": "Margie Bright Matthews",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://scstatehouse.gov/images/members/0194318159.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "45",
              "chamber": "upper",
              "hashID": "SLDU-SC-45-upper-ocd-person-829bd3ab-d117-43fd-b274-f9ff8177c789",
              "builtID": "sc-upper-45",
              "externalID": "ocd-person/829bd3ab-d117-43fd-b274-f9ff8177c789",
              "geometry": null
            },
            "contactInfo": {
              "email": "margiebrightmatthews@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0194318159",
                "https://www.scstatehouse.gov/member.php?code=0194318159"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-45-upper-ocd-person-829bd3ab-d117-43fd-b274-f9ff8177c789": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/33fe6bed-f8b0-4002-90fa-870813b72caf",
            "firstName": "Thomas C.",
            "lastName": "Davis",
            "fullName": "Tom Davis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://scstatehouse.gov/images/members/0456818127.jpg",
            "title": "SC Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SC",
              "stateFull": "South Carolina",
              "district": "46",
              "chamber": "upper",
              "hashID": "SLDU-SC-46-upper-ocd-person-33fe6bed-f8b0-4002-90fa-870813b72caf",
              "builtID": "sc-upper-46",
              "externalID": "ocd-person/33fe6bed-f8b0-4002-90fa-870813b72caf",
              "geometry": null
            },
            "contactInfo": {
              "email": "tomdavis@scsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://scstatehouse.gov/member.php?code=0456818127",
                "https://www.scstatehouse.gov/member.php?code=0456818127"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SC-46-upper-ocd-person-33fe6bed-f8b0-4002-90fa-870813b72caf": 0
        }
      }
    }
  },
  "SD": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "SD",
          "stateFull": "South Dakota",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "SD",
            "stateFull": "South Dakota",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-SD---",
            "builtID": "sd--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-SD---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "R000605",
          "in_office": true,
          "firstName": "Mike",
          "lastName": "Rounds",
          "middleName": null,
          "fullName": "Mike Rounds",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/R000605.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "SD",
            "stateFull": "South Dakota",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-SD---R000605",
            "builtID": "sd--",
            "externalID": "R000605",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.rounds.senate.gov/contact/email-mike",
            "address1": "716 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-5842",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorRounds",
            "youtube": null,
            "instagram": null,
            "facebook": "SenatorMikeRounds",
            "urls": ["https://www.rounds.senate.gov"],
            "rss_url": null
          },
          "title": "SD Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/R000605.json",
          "govtrack_id": "412669",
          "cspan_id": "78317",
          "votesmart_id": "7455",
          "icpsr_id": "41505",
          "crp_id": "N00035187",
          "google_entity_id": "/m/020z2p",
          "state_rank": "junior",
          "lis_id": "S381",
          "suffix": null,
          "date_of_birth": "1954-10-24",
          "leadership_role": null,
          "fec_candidate_id": "S4SD00049",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "9",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 10,
          "total_present": 0,
          "last_updated": "2024-06-20 15:30:29 -0400",
          "missed_votes_pct": 1.81,
          "votes_with_party_pct": 80.68,
          "votes_against_party_pct": 19.32,
          "ocd_id": "ocd-division/country:us/state:sd"
        },
        {
          "API_ID": "T000250",
          "in_office": true,
          "firstName": "John",
          "lastName": "Thune",
          "middleName": null,
          "fullName": "John Thune",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/T000250.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "SD",
            "stateFull": "South Dakota",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-SD---T000250",
            "builtID": "sd--",
            "externalID": "T000250",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenJohnThune",
            "youtube": "johnthune",
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.thune.senate.gov"],
            "rss_url": "https://www.thune.senate.gov/public/?a=RSS.Feed"
          },
          "title": "SD Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/T000250.json",
          "govtrack_id": "400546",
          "cspan_id": "45552",
          "votesmart_id": "398",
          "icpsr_id": "29754",
          "crp_id": "N00004572",
          "google_entity_id": "/m/03ybyn",
          "state_rank": "senior",
          "lis_id": "S303",
          "suffix": null,
          "date_of_birth": "1961-01-07",
          "leadership_role": "Senate Minority Whip",
          "fec_candidate_id": "S2SD00068",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "19",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 0,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 0,
          "votes_with_party_pct": 93.48,
          "votes_against_party_pct": 6.52,
          "ocd_id": "ocd-division/country:us/state:sd"
        }
      ],
      "hash": { "SENATE-SD---R000605": 0, "SENATE-SD---T000250": 1 }
    },
    "HOUSE": {
      "00": {
        "members": [
          {
            "API_ID": "J000301",
            "in_office": true,
            "firstName": "Dusty",
            "lastName": "Johnson",
            "middleName": null,
            "fullName": "Dusty Johnson",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/J000301.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "00",
              "chamber": "",
              "hashID": "HOUSE-SD-00--J000301",
              "builtID": "sd--00",
              "externalID": "J000301",
              "geometry": null,
              "geoid": "4600"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1714 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2801",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJohnson",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://dustyjohnson.house.gov"],
              "rss_url": null
            },
            "title": "SD House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/J000301.json",
            "govtrack_id": "412816",
            "cspan_id": null,
            "votesmart_id": "48307",
            "icpsr_id": null,
            "crp_id": "N00040559",
            "google_entity_id": "/g/11dyzlcnbr",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1976-09-30",
            "leadership_role": null,
            "fec_candidate_id": "H8SD01055",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 10,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.95,
            "votes_with_party_pct": 94.93,
            "votes_against_party_pct": 3.12,
            "ocd_id": "ocd-division/country:us/state:sd/cd:1",
            "at_large": true
          }
        ],
        "hash": { "HOUSE-SD-00--J000301": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/18b14181-6761-487c-92d7-1e5c973485a2",
            "firstName": "Joe",
            "lastName": "Donnell",
            "fullName": "Joe Donnell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://gray-ksfy-prod.cdn.arcpublishing.com/resizer/5Ubq8i7Inpp7Ndvq018j9j8D-4g=/980x0/smart/filters:quality(85)/cloudfront-us-east-1.images.arcpublishing.com/gray/ACJICH22EFEOTN7QABRKCCQSHA.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-SD-1-lower-ocd-person-18b14181-6761-487c-92d7-1e5c973485a2",
              "builtID": "sd-lower-1",
              "externalID": "ocd-person/18b14181-6761-487c-92d7-1e5c973485a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.donnell@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4442/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/52a778c4-427c-41bc-b36c-b75210a16d97",
            "firstName": "Tamara",
            "lastName": "St. John",
            "fullName": "Tamara St. John",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/TamaraStJohn.jpeg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-SD-1-lower-ocd-person-52a778c4-427c-41bc-b36c-b75210a16d97",
              "builtID": "sd-lower-1",
              "externalID": "ocd-person/52a778c4-427c-41bc-b36c-b75210a16d97",
              "geometry": null
            },
            "contactInfo": {
              "email": "tamara.stjohn@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1610&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1855/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4418/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4563/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-1-lower-ocd-person-18b14181-6761-487c-92d7-1e5c973485a2": 0,
          "SLDL-SD-1-lower-ocd-person-52a778c4-427c-41bc-b36c-b75210a16d97": 1
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/4d2f522f-336a-4794-8b89-48d65d250f6e",
            "firstName": "David",
            "lastName": "Kull",
            "fullName": "David Kull",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cityofbrandon.org/vertical/Sites/%7B23CB10F0-8C35-4CA4-9AD1-B693F0F58E76%7D/uploads/Dave_Kull_Web.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-SD-2-lower-ocd-person-4d2f522f-336a-4794-8b89-48d65d250f6e",
              "builtID": "sd-lower-2",
              "externalID": "ocd-person/4d2f522f-336a-4794-8b89-48d65d250f6e",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.kull@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4438/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4521/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/fe5950b8-b6e8-4a95-87b5-fd569f43b286",
            "firstName": "John",
            "lastName": "Sjaarda",
            "fullName": "John Sjaarda",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.johnsjaarda.net/images/103440/Profile_Photo.png",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-SD-2-lower-ocd-person-fe5950b8-b6e8-4a95-87b5-fd569f43b286",
              "builtID": "sd-lower-2",
              "externalID": "ocd-person/fe5950b8-b6e8-4a95-87b5-fd569f43b286",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.sjaarda@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4439/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-2-lower-ocd-person-4d2f522f-336a-4794-8b89-48d65d250f6e": 0,
          "SLDL-SD-2-lower-ocd-person-fe5950b8-b6e8-4a95-87b5-fd569f43b286": 1
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/713ca571-304b-48f0-a853-b49fa859134e",
            "firstName": "Brandei",
            "lastName": "Schaefbauer",
            "fullName": "Brandei Schaefbauer",
            "gender": "Female",
            "party": "Republican",
            "image": "https://gray-ksfy-prod.cdn.arcpublishing.com/resizer/3wAp2PAmslSttABhdOPnmasLDYI=/980x0/smart/filters:quality(85)/cloudfront-us-east-1.images.arcpublishing.com/gray/Y4ZM7RT3TRB2ZBPHKDXEI5HL6E.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-SD-3-lower-ocd-person-713ca571-304b-48f0-a853-b49fa859134e",
              "builtID": "sd-lower-3",
              "externalID": "ocd-person/713ca571-304b-48f0-a853-b49fa859134e",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandei.schaefbauer@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4444/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4556/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/5a5c92d7-0b2c-418e-a0ce-8ba0a6e4790a",
            "firstName": "Carl E.",
            "lastName": "Perry",
            "fullName": "Carl Perry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.carlperry.org/uploads/1/1/7/5/117553499/carl-perry-profile-1200x1200_orig.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-SD-3-lower-ocd-person-5a5c92d7-0b2c-418e-a0ce-8ba0a6e4790a",
              "builtID": "sd-lower-3",
              "externalID": "ocd-person/5a5c92d7-0b2c-418e-a0ce-8ba0a6e4790a",
              "geometry": null
            },
            "contactInfo": {
              "email": "carl.perry@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1612&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1834/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4401/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4543/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-3-lower-ocd-person-713ca571-304b-48f0-a853-b49fa859134e": 0,
          "SLDL-SD-3-lower-ocd-person-5a5c92d7-0b2c-418e-a0ce-8ba0a6e4790a": 1
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/ae3c1f46-9aa0-497a-93de-f8ea74f24c91",
            "firstName": "Fred E.",
            "lastName": "Deutsch",
            "fullName": "Fred Deutsch",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/DEUTSCH_FRED_2015.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-SD-4-lower-ocd-person-ae3c1f46-9aa0-497a-93de-f8ea74f24c91",
              "builtID": "sd-lower-4",
              "externalID": "ocd-person/ae3c1f46-9aa0-497a-93de-f8ea74f24c91",
              "geometry": null
            },
            "contactInfo": {
              "email": "fred.deutsch@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1613&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1782/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4492/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/229f3180-5808-47f9-b473-3da3c9585bc5",
            "firstName": "Stephanie Lakness",
            "lastName": "Sauder",
            "fullName": "Stephanie Sauder",
            "gender": "Female",
            "party": "Republican",
            "image": "https://img1.wsimg.com/isteam/ip/79627b3f-ed98-40d7-87ae-835dc317bdea/Sauder-27.jpg/:/cr=t:11.04%25,l:0%25,w:100%25,h:50.11%25/rs=w:1240,h:932,cg:true",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-SD-4-lower-ocd-person-229f3180-5808-47f9-b473-3da3c9585bc5",
              "builtID": "sd-lower-4",
              "externalID": "ocd-person/229f3180-5808-47f9-b473-3da3c9585bc5",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephanie.sauder@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4455/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-4-lower-ocd-person-ae3c1f46-9aa0-497a-93de-f8ea74f24c91": 0,
          "SLDL-SD-4-lower-ocd-person-229f3180-5808-47f9-b473-3da3c9585bc5": 1
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/46dbd685-82cc-4452-96cf-0487efa67d7d",
            "firstName": "Byron I.",
            "lastName": "Callies",
            "fullName": "Byron Callies",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gannett-cdn.com/presto/2022/01/03/NWAT/e8ed83ae-868a-4a84-b9af-bec94e7fcdef-Byron_Callies.jpg?crop=323,491,x37,y0&width=300&height=457&format=pjpg&auto=webp",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-SD-5-lower-ocd-person-46dbd685-82cc-4452-96cf-0487efa67d7d",
              "builtID": "sd-lower-5",
              "externalID": "ocd-person/46dbd685-82cc-4452-96cf-0487efa67d7d",
              "geometry": null
            },
            "contactInfo": {
              "email": "byron.callies@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4459/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4482/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/5091d5a4-1ff0-47cf-a313-79bfd6f5e81d",
            "firstName": "Hugh M.",
            "lastName": "Bartels",
            "fullName": "Hugh Bartels",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Bartels_2017.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-SD-5-lower-ocd-person-5091d5a4-1ff0-47cf-a313-79bfd6f5e81d",
              "builtID": "sd-lower-5",
              "externalID": "ocd-person/5091d5a4-1ff0-47cf-a313-79bfd6f5e81d",
              "geometry": null
            },
            "contactInfo": {
              "email": "hugh.bartels@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1531&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1614&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1767/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4334/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-5-lower-ocd-person-46dbd685-82cc-4452-96cf-0487efa67d7d": 0,
          "SLDL-SD-5-lower-ocd-person-5091d5a4-1ff0-47cf-a313-79bfd6f5e81d": 1
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/d3d7563b-420c-4498-8bc5-4f9d6ed90806",
            "firstName": "Aaron",
            "lastName": "Aylward",
            "fullName": "Aaron Aylward",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Aaron-Aylward.PNG",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-SD-6-lower-ocd-person-d3d7563b-420c-4498-8bc5-4f9d6ed90806",
              "builtID": "sd-lower-6",
              "externalID": "ocd-person/d3d7563b-420c-4498-8bc5-4f9d6ed90806",
              "geometry": null
            },
            "contactInfo": {
              "email": "aaron.aylward@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3786/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4333/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4474/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/dda7bd46-d880-453e-b830-269e79425a9a",
            "firstName": "Ernest E.",
            "lastName": "Otten",
            "fullName": "Ernie Otten",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/OTTEN_2017.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-SD-6-lower-ocd-person-dda7bd46-d880-453e-b830-269e79425a9a",
              "builtID": "sd-lower-6",
              "externalID": "ocd-person/dda7bd46-d880-453e-b830-269e79425a9a",
              "geometry": null
            },
            "contactInfo": {
              "email": "ernie.otten@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1479&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1558&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1830/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4398/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-6-lower-ocd-person-d3d7563b-420c-4498-8bc5-4f9d6ed90806": 0,
          "SLDL-SD-6-lower-ocd-person-dda7bd46-d880-453e-b830-269e79425a9a": 1
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/82afbbac-f85b-47ec-aad9-c7218f35d44c",
            "firstName": "Mellissa",
            "lastName": "Heermann",
            "fullName": "Mellissa Heermann",
            "gender": "Female",
            "party": "Republican",
            "image": "https://img1.wsimg.com/isteam/ip/805d1e30-7bc9-464e-a3b8-c366e5bfe518/IMG_1300%20(1).JPG",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-SD-7-lower-ocd-person-82afbbac-f85b-47ec-aad9-c7218f35d44c",
              "builtID": "sd-lower-7",
              "externalID": "ocd-person/82afbbac-f85b-47ec-aad9-c7218f35d44c",
              "geometry": null
            },
            "contactInfo": {
              "email": "mellissa.heermann@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4450/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/69b0ca35-e513-4250-90fd-3a8c44dbeae8",
            "firstName": "Roger",
            "lastName": "DeGroot",
            "fullName": "Roger DeGroot",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.brookingsregister.com/uploads/images/2022/03/4ef6ebb1f7fc2b505ff531f25a010074.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-SD-7-lower-ocd-person-69b0ca35-e513-4250-90fd-3a8c44dbeae8",
              "builtID": "sd-lower-7",
              "externalID": "ocd-person/69b0ca35-e513-4250-90fd-3a8c44dbeae8",
              "geometry": null
            },
            "contactInfo": {
              "email": "roger.degroot@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4449/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-7-lower-ocd-person-82afbbac-f85b-47ec-aad9-c7218f35d44c": 0,
          "SLDL-SD-7-lower-ocd-person-69b0ca35-e513-4250-90fd-3a8c44dbeae8": 1
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/2a54bf8f-a01c-49da-8abc-c88a91a65f6e",
            "firstName": "John",
            "lastName": "Mills",
            "fullName": "John Mills",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Mills_2017.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-SD-8-lower-ocd-person-2a54bf8f-a01c-49da-8abc-c88a91a65f6e",
              "builtID": "sd-lower-8",
              "externalID": "ocd-person/2a54bf8f-a01c-49da-8abc-c88a91a65f6e",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.mills@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1529&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1435&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1822/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4389/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/3c82e6e9-348f-40cd-818e-76d4901d9ec5",
            "firstName": "Timothy A.",
            "lastName": "Reisch",
            "fullName": "Tim Reisch",
            "gender": "Male",
            "party": "Republican",
            "image": "https://lawmakerdocuments.blob.core.usgovcloudapi.net/photos/https://lawmakerdocuments.blob.core.usgovcloudapi.net/photos/reisch, tim rep-1757 rt.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-SD-8-lower-ocd-person-3c82e6e9-348f-40cd-818e-76d4901d9ec5",
              "builtID": "sd-lower-8",
              "externalID": "ocd-person/3c82e6e9-348f-40cd-818e-76d4901d9ec5",
              "geometry": null
            },
            "contactInfo": {
              "email": "tim.reisch@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4458/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-8-lower-ocd-person-2a54bf8f-a01c-49da-8abc-c88a91a65f6e": 0,
          "SLDL-SD-8-lower-ocd-person-3c82e6e9-348f-40cd-818e-76d4901d9ec5": 1
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/3b05e975-1b79-42ec-94e8-7a258142dd46",
            "firstName": "Bethany Bostron",
            "lastName": "Soye",
            "fullName": "Bethany Soye",
            "gender": "Female",
            "party": "Republican",
            "image": "https://static.wixstatic.com/media/8bfd3e_dbac551f01a34584a6bd53e0bccca5ef~mv2.jpg/v1/fill/w_640,h_1144,fp_0.44_0.17,q_85,usm_0.66_1.00_0.01/8bfd3e_dbac551f01a34584a6bd53e0bccca5ef~mv2.webp",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-SD-9-lower-ocd-person-3b05e975-1b79-42ec-94e8-7a258142dd46",
              "builtID": "sd-lower-9",
              "externalID": "ocd-person/3b05e975-1b79-42ec-94e8-7a258142dd46",
              "geometry": null
            },
            "contactInfo": {
              "email": "bethany.soye@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3793/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4417/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4562/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4c400993-01fe-43df-98b3-71a1f352b8a2",
            "firstName": "Kenneth",
            "lastName": "Teunissen",
            "fullName": "Ken Teunissen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gannett-cdn.com/presto/2022/09/30/PSIF/e4985764-8db7-4ee6-8c9b-faa42b1a6817-Kenneth_Teunissen.JPG?width=300&height=375&fit=crop&format=pjpg&auto=webp",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-SD-9-lower-ocd-person-4c400993-01fe-43df-98b3-71a1f352b8a2",
              "builtID": "sd-lower-9",
              "externalID": "ocd-person/4c400993-01fe-43df-98b3-71a1f352b8a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "kenneth.teunissen@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4464/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4566/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-9-lower-ocd-person-3b05e975-1b79-42ec-94e8-7a258142dd46": 0,
          "SLDL-SD-9-lower-ocd-person-4c400993-01fe-43df-98b3-71a1f352b8a2": 1
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/85bda678-d416-4caa-b439-8a579288f2ec",
            "firstName": "Erin",
            "lastName": "Healy",
            "fullName": "Erin Healy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Healy_2019.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-SD-10-lower-ocd-person-85bda678-d416-4caa-b439-8a579288f2ec",
              "builtID": "sd-lower-10",
              "externalID": "ocd-person/85bda678-d416-4caa-b439-8a579288f2ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "erin.healy@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1624&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1800/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4368/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f06dbffd-11b0-4949-a0b0-fa80591c2afd",
            "firstName": "Kameron",
            "lastName": "Nelson",
            "fullName": "Kameron Nelson",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://t2.gstatic.com/licensed-image?q=tbn:ANd9GcREROpqpFs7mDZXUu2svs4MGwYyteRX7d5Usx8lzEsaQP9BrGT4RoQMH43lYH-PcJsN",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-SD-10-lower-ocd-person-f06dbffd-11b0-4949-a0b0-fa80591c2afd",
              "builtID": "sd-lower-10",
              "externalID": "ocd-person/f06dbffd-11b0-4949-a0b0-fa80591c2afd",
              "geometry": null
            },
            "contactInfo": {
              "email": "kameron.nelson@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4467/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-10-lower-ocd-person-85bda678-d416-4caa-b439-8a579288f2ec": 0,
          "SLDL-SD-10-lower-ocd-person-f06dbffd-11b0-4949-a0b0-fa80591c2afd": 1
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/d9d8a84f-96df-4dbf-b638-d63e216166d4",
            "firstName": "Brian",
            "lastName": "Mulder",
            "fullName": "Brian Mulder",
            "gender": "Male",
            "party": "Republican",
            "image": "https://img1.wsimg.com/isteam/ip/27b7dcd4-adbb-4603-9579-073f083153b8/Brian_BriCowanPhotography-11.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:400,cg:true",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-SD-11-lower-ocd-person-d9d8a84f-96df-4dbf-b638-d63e216166d4",
              "builtID": "sd-lower-11",
              "externalID": "ocd-person/d9d8a84f-96df-4dbf-b638-d63e216166d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.mulder@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4447/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/85616a5d-63b7-4794-8eb0-63ab7b121761",
            "firstName": "Chris G.",
            "lastName": "Karr",
            "fullName": "Chris Karr",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Karr_2017.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-SD-11-lower-ocd-person-85616a5d-63b7-4794-8eb0-63ab7b121761",
              "builtID": "sd-lower-11",
              "externalID": "ocd-person/85616a5d-63b7-4794-8eb0-63ab7b121761",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.karr@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1549&Session=2018s",
                "https://sdlegislature.gov/Legislators/Profile/1809/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4379/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-11-lower-ocd-person-d9d8a84f-96df-4dbf-b638-d63e216166d4": 0,
          "SLDL-SD-11-lower-ocd-person-85616a5d-63b7-4794-8eb0-63ab7b121761": 1
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/f9dd87da-ac23-450d-9574-1efa95ef0d81",
            "firstName": "Amber Stern",
            "lastName": "Arlint",
            "fullName": "Amber Arlint",
            "gender": "Female",
            "party": "Republican",
            "image": "https://siouxfallschamber.com/wp-content/uploads/sites/3/2022/11/District-12-Amber-Arlint.webp",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-SD-12-lower-ocd-person-f9dd87da-ac23-450d-9574-1efa95ef0d81",
              "builtID": "sd-lower-12",
              "externalID": "ocd-person/f9dd87da-ac23-450d-9574-1efa95ef0d81",
              "geometry": null
            },
            "contactInfo": {
              "email": "amber.arlint@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4456/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/fe9a44c7-7241-4395-98e3-8445b1bc2d54",
            "firstName": "Greg",
            "lastName": "Jamison",
            "fullName": "Greg Jamison",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Jamison_2017.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-SD-12-lower-ocd-person-fe9a44c7-7241-4395-98e3-8445b1bc2d54",
              "builtID": "sd-lower-12",
              "externalID": "ocd-person/fe9a44c7-7241-4395-98e3-8445b1bc2d54",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.jamison@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1536&Session=2018s",
                "https://sdlegislature.gov/Legislators/Profile/4373/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-12-lower-ocd-person-f9dd87da-ac23-450d-9574-1efa95ef0d81": 0,
          "SLDL-SD-12-lower-ocd-person-fe9a44c7-7241-4395-98e3-8445b1bc2d54": 1
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/10c3de09-4e65-4885-bbbb-78b2903a36e2",
            "firstName": "Sue K.",
            "lastName": "Peterson",
            "fullName": "Sue Peterson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/PetersonSue_2017.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-SD-13-lower-ocd-person-10c3de09-4e65-4885-bbbb-78b2903a36e2",
              "builtID": "sd-lower-13",
              "externalID": "ocd-person/10c3de09-4e65-4885-bbbb-78b2903a36e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "sue.peterson@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1537&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1431&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1836/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4403/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c8d6a31f-a4e9-454a-a75a-28b668c1b729",
            "firstName": "Tony",
            "lastName": "Venhuizen",
            "fullName": "Tony Venhuizen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/TonyVenhuizen_.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-SD-13-lower-ocd-person-c8d6a31f-a4e9-454a-a75a-28b668c1b729",
              "builtID": "sd-lower-13",
              "externalID": "ocd-person/c8d6a31f-a4e9-454a-a75a-28b668c1b729",
              "geometry": null
            },
            "contactInfo": {
              "email": "tony.venhuizen@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4465/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4569/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-13-lower-ocd-person-10c3de09-4e65-4885-bbbb-78b2903a36e2": 0,
          "SLDL-SD-13-lower-ocd-person-c8d6a31f-a4e9-454a-a75a-28b668c1b729": 1
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/ba249bd9-5b3e-42f3-a367-061bf63d620a",
            "firstName": "Taylor Rae Longville",
            "lastName": "Rehfeldt",
            "fullName": "Taylor Rehfeldt",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.gannett-cdn.com/presto/2020/05/22/PSIF/4cc05ff5-5458-4aa1-a67d-b9f2ac8e2cd2-Taylor_Rae_Rehfeldt.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-SD-14-lower-ocd-person-ba249bd9-5b3e-42f3-a367-061bf63d620a",
              "builtID": "sd-lower-14",
              "externalID": "ocd-person/ba249bd9-5b3e-42f3-a367-061bf63d620a",
              "geometry": null
            },
            "contactInfo": {
              "email": "taylor.rehfeldt@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3799/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4408/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/5cc58d39-fb32-43b6-8a18-41c83be5f449",
            "firstName": "Tyler Lee",
            "lastName": "Tordsen",
            "fullName": "Tyler Tordsen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://lawmakerdocuments.blob.core.usgovcloudapi.net/photos/tordsen%20tyler%20rep-0237%20rt.webp",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-SD-14-lower-ocd-person-5cc58d39-fb32-43b6-8a18-41c83be5f449",
              "builtID": "sd-lower-14",
              "externalID": "ocd-person/5cc58d39-fb32-43b6-8a18-41c83be5f449",
              "geometry": null
            },
            "contactInfo": {
              "email": "tyler.tordsen@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4446/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-14-lower-ocd-person-ba249bd9-5b3e-42f3-a367-061bf63d620a": 0,
          "SLDL-SD-14-lower-ocd-person-5cc58d39-fb32-43b6-8a18-41c83be5f449": 1
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/892b6289-0bd8-441d-94bf-30e6f1cfce49",
            "firstName": "Kadyn",
            "lastName": "Wittman",
            "fullName": "Kadyn Wittman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://images.squarespace-cdn.com/content/v1/61c2066b9cdd312b88c3dfac/1654115334020-X29OZ31K2AHG56PMQ1BG/RDSN_KW_CP2022_C-43+%282%29.jpg?format=750w",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-SD-15-lower-ocd-person-892b6289-0bd8-441d-94bf-30e6f1cfce49",
              "builtID": "sd-lower-15",
              "externalID": "ocd-person/892b6289-0bd8-441d-94bf-30e6f1cfce49",
              "geometry": null
            },
            "contactInfo": {
              "email": "kadyn.wittman@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4461/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4575/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/dda1d676-f5ad-4491-b66a-cd9c8b94ffa7",
            "firstName": "Linda K.",
            "lastName": "Duba",
            "fullName": "Linda Duba",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/lduba.jpeg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-SD-15-lower-ocd-person-dda1d676-f5ad-4491-b66a-cd9c8b94ffa7",
              "builtID": "sd-lower-15",
              "externalID": "ocd-person/dda1d676-f5ad-4491-b66a-cd9c8b94ffa7",
              "geometry": null
            },
            "contactInfo": {
              "email": "linda.duba@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1594&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1784/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4354/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-15-lower-ocd-person-892b6289-0bd8-441d-94bf-30e6f1cfce49": 0,
          "SLDL-SD-15-lower-ocd-person-dda1d676-f5ad-4491-b66a-cd9c8b94ffa7": 1
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/0c864308-04a8-4b22-af5b-5d718046b99f",
            "firstName": "Karla J.",
            "lastName": "Lems",
            "fullName": "Karla Lems",
            "gender": "Female",
            "party": "Republican",
            "image": "https://gray-ksfy-prod.cdn.arcpublishing.com/resizer/fX4I5GWdwjL88Z9n8a1Dah-NctM=/980x0/smart/filters:quality(85)/cloudfront-us-east-1.images.arcpublishing.com/gray/72HOS665ZZDWHPEMYA6BWMS6BA.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-SD-16-lower-ocd-person-0c864308-04a8-4b22-af5b-5d718046b99f",
              "builtID": "sd-lower-16",
              "externalID": "ocd-person/0c864308-04a8-4b22-af5b-5d718046b99f",
              "geometry": null
            },
            "contactInfo": {
              "email": "karla.lems@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4443/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4524/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1de26fd7-bcde-486b-b290-afdee6756c7c",
            "firstName": "Kevin D.",
            "lastName": "Jensen",
            "fullName": "Kevin Jensen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Jensen_2017.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-SD-16-lower-ocd-person-1de26fd7-bcde-486b-b290-afdee6756c7c",
              "builtID": "sd-lower-16",
              "externalID": "ocd-person/1de26fd7-bcde-486b-b290-afdee6756c7c",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.jensen@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1540&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1427&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1804/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4374/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-16-lower-ocd-person-0c864308-04a8-4b22-af5b-5d718046b99f": 0,
          "SLDL-SD-16-lower-ocd-person-1de26fd7-bcde-486b-b290-afdee6756c7c": 1
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/87c4b9d3-1e14-4c64-af35-e9a95a1f4bf3",
            "firstName": "Chris",
            "lastName": "Kassin",
            "fullName": "Chris Kassin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://lawmakerdocuments.blob.core.usgovcloudapi.net/photos/kassin,%20chris%20rep-0599%20rt.webp",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-SD-17-lower-ocd-person-87c4b9d3-1e14-4c64-af35-e9a95a1f4bf3",
              "builtID": "sd-lower-17",
              "externalID": "ocd-person/87c4b9d3-1e14-4c64-af35-e9a95a1f4bf3",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.kassin@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4462/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/705bda08-a613-4be4-9c90-ac7e30eef6bf",
            "firstName": "William J.",
            "lastName": "Shorma",
            "fullName": "William Shorma",
            "gender": "Male",
            "party": "Republican",
            "image": "https://lawmakerdocuments.blob.core.usgovcloudapi.net/photos/shorma,%20william%20rep-1194%20rt.webp",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-SD-17-lower-ocd-person-705bda08-a613-4be4-9c90-ac7e30eef6bf",
              "builtID": "sd-lower-17",
              "externalID": "ocd-person/705bda08-a613-4be4-9c90-ac7e30eef6bf",
              "geometry": null
            },
            "contactInfo": {
              "email": "william.shorma@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.sd.gov/Legislators/Legislators/MemberDetail.aspx?Member=1066&Session=2016",
                "https://sdlegislature.gov/Legislators/Profile/4463/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4560/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-17-lower-ocd-person-87c4b9d3-1e14-4c64-af35-e9a95a1f4bf3": 0,
          "SLDL-SD-17-lower-ocd-person-705bda08-a613-4be4-9c90-ac7e30eef6bf": 1
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/8fabf252-4409-4c1f-b754-ab54759ea3ce",
            "firstName": "Julie K.",
            "lastName": "Auch",
            "fullName": "Julie Auch",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/JulieAuch.png",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-SD-18-lower-ocd-person-8fabf252-4409-4c1f-b754-ab54759ea3ce",
              "builtID": "sd-lower-18",
              "externalID": "ocd-person/8fabf252-4409-4c1f-b754-ab54759ea3ce",
              "geometry": null
            },
            "contactInfo": {
              "email": "julie.auch@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4445/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4473/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0121d607-e75c-4427-bd76-a6e01434afca",
            "firstName": "Mike",
            "lastName": "Stevens",
            "fullName": "Mike Stevens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/STEVENS_2017.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-SD-18-lower-ocd-person-0121d607-e75c-4427-bd76-a6e01434afca",
              "builtID": "sd-lower-18",
              "externalID": "ocd-person/0121d607-e75c-4427-bd76-a6e01434afca",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.stevens@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3790/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4421/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-18-lower-ocd-person-8fabf252-4409-4c1f-b754-ab54759ea3ce": 0,
          "SLDL-SD-18-lower-ocd-person-0121d607-e75c-4427-bd76-a6e01434afca": 1
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/ce0a6958-a2d1-4e9e-ab06-943bc84a271a",
            "firstName": "Drew",
            "lastName": "Peterson",
            "fullName": "Drew Peterson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.drewpetersonforsd.com/hubfs/DPH_22_mailer1_11x6_DRAFT%202_Page_1.png",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-SD-19-lower-ocd-person-ce0a6958-a2d1-4e9e-ab06-943bc84a271a",
              "builtID": "sd-lower-19",
              "externalID": "ocd-person/ce0a6958-a2d1-4e9e-ab06-943bc84a271a",
              "geometry": null
            },
            "contactInfo": {
              "email": "drew.peterson@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4440/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/7d5691be-08ff-4684-978d-81aaa8bdd99b",
            "firstName": "Jessica",
            "lastName": "Bahmuller",
            "fullName": "Jessica Bahmuller",
            "gender": "Female",
            "party": "Republican",
            "image": "https://gray-ksfy-prod.cdn.arcpublishing.com/resizer/uVfdl5DqYxBK5BAgVB1sNda9-dw=/980x0/smart/filters:quality(85)/cloudfront-us-east-1.images.arcpublishing.com/gray/XEYMZWQPXBGYFJAKREMXYXNXCI.png",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-SD-19-lower-ocd-person-7d5691be-08ff-4684-978d-81aaa8bdd99b",
              "builtID": "sd-lower-19",
              "externalID": "ocd-person/7d5691be-08ff-4684-978d-81aaa8bdd99b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jessica.bahmuller@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4441/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4475/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-19-lower-ocd-person-ce0a6958-a2d1-4e9e-ab06-943bc84a271a": 0,
          "SLDL-SD-19-lower-ocd-person-7d5691be-08ff-4684-978d-81aaa8bdd99b": 1
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/950a3662-c92b-46da-8ad9-39eaa9152dca",
            "firstName": "Ben",
            "lastName": "Krohmer",
            "fullName": "Ben Krohmer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://gray-ksfy-prod.cdn.arcpublishing.com/resizer/yy5f2gzxF86Lv1hJDws179MlTtQ=/980x0/smart/filters:quality(85)/cloudfront-us-east-1.images.arcpublishing.com/gray/7VPKL3VVU5DBHJCPNT22W3PBCE.png",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-SD-20-lower-ocd-person-950a3662-c92b-46da-8ad9-39eaa9152dca",
              "builtID": "sd-lower-20",
              "externalID": "ocd-person/950a3662-c92b-46da-8ad9-39eaa9152dca",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.krohmer@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4452/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4519/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/fa1bd1e9-d5bb-461b-ba4e-27c7b19438b6",
            "firstName": "Lance R.",
            "lastName": "Koth",
            "fullName": "Lance Koth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Koth_2019.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-SD-20-lower-ocd-person-fa1bd1e9-d5bb-461b-ba4e-27c7b19438b6",
              "builtID": "sd-lower-20",
              "externalID": "ocd-person/fa1bd1e9-d5bb-461b-ba4e-27c7b19438b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "lance.koth@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1631&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1813/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4383/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-20-lower-ocd-person-950a3662-c92b-46da-8ad9-39eaa9152dca": 0,
          "SLDL-SD-20-lower-ocd-person-fa1bd1e9-d5bb-461b-ba4e-27c7b19438b6": 1
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/7dc4b8d9-148b-4622-a6de-b27dc27030be",
            "firstName": "Marty",
            "lastName": "Overweg",
            "fullName": "Marty Overweg",
            "gender": "Male",
            "party": "Republican",
            "image": "http://sdlegislature.gov/inc/img/Legislators/overweg_2020.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-SD-21-lower-ocd-person-7dc4b8d9-148b-4622-a6de-b27dc27030be",
              "builtID": "sd-lower-21",
              "externalID": "ocd-person/7dc4b8d9-148b-4622-a6de-b27dc27030be",
              "geometry": null
            },
            "contactInfo": {
              "email": "marty.overweg@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/1832/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4400/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4542/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4b8dc90a-ed50-4f4e-8d9a-dceb0344afd3",
            "firstName": "Rocky Dale",
            "lastName": "Blare",
            "fullName": "Rocky Blare",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api/storage/uploads/thumbs/200/300/crop/best/Rocky_Blare.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-SD-21-lower-ocd-person-4b8dc90a-ed50-4f4e-8d9a-dceb0344afd3",
              "builtID": "sd-lower-21",
              "externalID": "ocd-person/4b8dc90a-ed50-4f4e-8d9a-dceb0344afd3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rocky.blare@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/1770/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4337/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-21-lower-ocd-person-7dc4b8d9-148b-4622-a6de-b27dc27030be": 0,
          "SLDL-SD-21-lower-ocd-person-4b8dc90a-ed50-4f4e-8d9a-dceb0344afd3": 1
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/3546dbf4-9885-4e13-a2a3-05f201932adf",
            "firstName": "Lynn",
            "lastName": "Schneider",
            "fullName": "Lynn Schneider",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Schneider_2020.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-SD-22-lower-ocd-person-3546dbf4-9885-4e13-a2a3-05f201932adf",
              "builtID": "sd-lower-22",
              "externalID": "ocd-person/3546dbf4-9885-4e13-a2a3-05f201932adf",
              "geometry": null
            },
            "contactInfo": {
              "email": "lynn.schneider@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/1883/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4412/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a6fbd7fe-c97b-424c-8561-de59b137263c",
            "firstName": "Roger D.",
            "lastName": "Chase",
            "fullName": "Roger Chase",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Chase_2017.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-SD-22-lower-ocd-person-a6fbd7fe-c97b-424c-8561-de59b137263c",
              "builtID": "sd-lower-22",
              "externalID": "ocd-person/a6fbd7fe-c97b-424c-8561-de59b137263c",
              "geometry": null
            },
            "contactInfo": {
              "email": "roger.chase@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1541&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1560&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1778/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4486/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-22-lower-ocd-person-3546dbf4-9885-4e13-a2a3-05f201932adf": 0,
          "SLDL-SD-22-lower-ocd-person-a6fbd7fe-c97b-424c-8561-de59b137263c": 1
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/0e44b9b9-f7d6-4bc1-99a5-e60bc5c67f0e",
            "firstName": "James D.",
            "lastName": "Wangsness",
            "fullName": "J.D. Wangsness",
            "gender": "Male",
            "party": "Republican",
            "image": "http://sdlegislature.gov/inc/img/Legislators/Wangsness_2019.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-SD-23-lower-ocd-person-0e44b9b9-f7d6-4bc1-99a5-e60bc5c67f0e",
              "builtID": "sd-lower-23",
              "externalID": "ocd-person/0e44b9b9-f7d6-4bc1-99a5-e60bc5c67f0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.wangsness@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4470/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f6efc9b6-c985-4765-bd47-8da24d06712b",
            "firstName": "Scott",
            "lastName": "Moore",
            "fullName": "Scott Moore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://gray-ksfy-prod.cdn.arcpublishing.com/resizer/PaauCW6h0UcuEU2uhSkymFUQnhU=/980x0/smart/filters:quality(85)/cloudfront-us-east-1.images.arcpublishing.com/gray/WIET46SP6JBKTLV2GTADMR27PY.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-SD-23-lower-ocd-person-f6efc9b6-c985-4765-bd47-8da24d06712b",
              "builtID": "sd-lower-23",
              "externalID": "ocd-person/f6efc9b6-c985-4765-bd47-8da24d06712b",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.moore@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4469/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-23-lower-ocd-person-0e44b9b9-f7d6-4bc1-99a5-e60bc5c67f0e": 0,
          "SLDL-SD-23-lower-ocd-person-f6efc9b6-c985-4765-bd47-8da24d06712b": 1
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/f611d7e5-8438-49da-a323-374bbf0af218",
            "firstName": "Michael",
            "lastName": "Weisgram",
            "fullName": "Mike Weisgram",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.chicago2.vip.townnews.com/capjournal.com/content/tncms/assets/v3/editorial/5/dd/5dd71c64-93d9-11ea-a386-83ab371b2512/5eb9d679c54ee.image.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-SD-24-lower-ocd-person-f611d7e5-8438-49da-a323-374bbf0af218",
              "builtID": "sd-lower-24",
              "externalID": "ocd-person/f611d7e5-8438-49da-a323-374bbf0af218",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.weisgram@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3789/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4429/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/46a0838c-0bb9-446a-8828-21a3f2124d1b",
            "firstName": "Will D.",
            "lastName": "Mortenson",
            "fullName": "Will Mortenson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.gannett-cdn.com/-mm-/7d2e32043f35f170234758f26dd14f0d4c05b2fa/c=0-17-3772-2148/local/-/media/2016/12/28/SiouxFalls/SiouxFalls/636185485920955826-Will-Mortenson.jpg?width=3200&height=1808&fit=crop&format=pjpg&auto=webp",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-SD-24-lower-ocd-person-46a0838c-0bb9-446a-8828-21a3f2124d1b",
              "builtID": "sd-lower-24",
              "externalID": "ocd-person/46a0838c-0bb9-446a-8828-21a3f2124d1b",
              "geometry": null
            },
            "contactInfo": {
              "email": "will.mortenson@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3788/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4392/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-24-lower-ocd-person-f611d7e5-8438-49da-a323-374bbf0af218": 0,
          "SLDL-SD-24-lower-ocd-person-46a0838c-0bb9-446a-8828-21a3f2124d1b": 1
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/912b0c53-5674-42b0-8f22-8ccd01c3264c",
            "firstName": "Jonathon",
            "lastName": "Hansen",
            "fullName": "Jon Hansen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Jon_Hansen.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-SD-25-lower-ocd-person-912b0c53-5674-42b0-8f22-8ccd01c3264c",
              "builtID": "sd-lower-25",
              "externalID": "ocd-person/912b0c53-5674-42b0-8f22-8ccd01c3264c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jon.hansen@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1635&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1798/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4366/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/596803af-d72c-4cfc-b482-e86b1fc699d4",
            "firstName": "Randy",
            "lastName": "Gross",
            "fullName": "Randy Gross",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Gross_2019.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-SD-25-lower-ocd-person-596803af-d72c-4cfc-b482-e86b1fc699d4",
              "builtID": "sd-lower-25",
              "externalID": "ocd-person/596803af-d72c-4cfc-b482-e86b1fc699d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "randy.gross@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1616&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1796/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4503/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-25-lower-ocd-person-912b0c53-5674-42b0-8f22-8ccd01c3264c": 0,
          "SLDL-SD-25-lower-ocd-person-596803af-d72c-4cfc-b482-e86b1fc699d4": 1
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/2b581087-9814-4f0d-80e5-b29a6e7aee67",
            "firstName": "Elizabeth Marty",
            "lastName": "May",
            "fullName": "Liz May",
            "gender": "Female",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/MAY_2017.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-SD-27-lower-ocd-person-2b581087-9814-4f0d-80e5-b29a6e7aee67",
              "builtID": "sd-lower-27",
              "externalID": "ocd-person/2b581087-9814-4f0d-80e5-b29a6e7aee67",
              "geometry": null
            },
            "contactInfo": {
              "email": "liz.may@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3806/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4388/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8dc5a857-0514-4a81-8215-a2f21a069075",
            "firstName": "Peri",
            "lastName": "Pourier",
            "fullName": "Peri Pourier",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Pourier_2019.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-SD-27-lower-ocd-person-8dc5a857-0514-4a81-8215-a2f21a069075",
              "builtID": "sd-lower-27",
              "externalID": "ocd-person/8dc5a857-0514-4a81-8215-a2f21a069075",
              "geometry": null
            },
            "contactInfo": {
              "email": "peri.pourier@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1638&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1839/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4405/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-27-lower-ocd-person-2b581087-9814-4f0d-80e5-b29a6e7aee67": 0,
          "SLDL-SD-27-lower-ocd-person-8dc5a857-0514-4a81-8215-a2f21a069075": 1
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/cb133c78-a55d-4c78-ad45-cbcf5c76b0d0",
            "firstName": "Gary L.",
            "lastName": "Cammack",
            "fullName": "Gary Cammack",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/CAMMACK_2017.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-SD-29-lower-ocd-person-cb133c78-a55d-4c78-ad45-cbcf5c76b0d0",
              "builtID": "sd-lower-29",
              "externalID": "ocd-person/cb133c78-a55d-4c78-ad45-cbcf5c76b0d0",
              "geometry": null
            },
            "contactInfo": {
              "email": "gary.cammack@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.sd.gov/Legislators/Legislators/MemberDetail.aspx?Member=1037&Session=2016",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1498&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1582&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1775/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4483/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/70cba44a-8c32-4046-8f56-86f9e69ef78f",
            "firstName": "Kirk",
            "lastName": "Chaffee",
            "fullName": "Kirk Chaffee",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Chaffee_2019.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-SD-29-lower-ocd-person-70cba44a-8c32-4046-8f56-86f9e69ef78f",
              "builtID": "sd-lower-29",
              "externalID": "ocd-person/70cba44a-8c32-4046-8f56-86f9e69ef78f",
              "geometry": null
            },
            "contactInfo": {
              "email": "kirk.chaffee@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1641&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1777/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4485/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-29-lower-ocd-person-cb133c78-a55d-4c78-ad45-cbcf5c76b0d0": 0,
          "SLDL-SD-29-lower-ocd-person-70cba44a-8c32-4046-8f56-86f9e69ef78f": 1
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/c19caeea-aeb4-48cd-86b3-ef08455d1cd1",
            "firstName": "Dennis",
            "lastName": "Krull",
            "fullName": "Dennis Krull",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.chicago2.vip.townnews.com/rapidcityjournal.com/content/tncms/assets/v3/editorial/7/9d/79d6d491-cb48-5fbc-830d-104457d69fb9/61e05e532f315.image.jpg?resize=400%2C500",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-SD-30-lower-ocd-person-c19caeea-aeb4-48cd-86b3-ef08455d1cd1",
              "builtID": "sd-lower-30",
              "externalID": "ocd-person/c19caeea-aeb4-48cd-86b3-ef08455d1cd1",
              "geometry": null
            },
            "contactInfo": {
              "email": "dennis.krull@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4454/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4520/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e7bb9291-fd90-4db8-b3e6-c90ebc41c997",
            "firstName": "Trish",
            "lastName": "Ladner",
            "fullName": "Trish Ladner",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Trish_Ladner.png",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-SD-30-lower-ocd-person-e7bb9291-fd90-4db8-b3e6-c90ebc41c997",
              "builtID": "sd-lower-30",
              "externalID": "ocd-person/e7bb9291-fd90-4db8-b3e6-c90ebc41c997",
              "geometry": null
            },
            "contactInfo": {
              "email": "trish.ladner@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3802/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4384/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4522/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-30-lower-ocd-person-c19caeea-aeb4-48cd-86b3-ef08455d1cd1": 0,
          "SLDL-SD-30-lower-ocd-person-e7bb9291-fd90-4db8-b3e6-c90ebc41c997": 1
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/e03644b8-7ddd-4ae0-a734-6f6e76e735ba",
            "firstName": "Mary",
            "lastName": "Fitzgerald",
            "fullName": "Mary Fitzgerald",
            "gender": "Female",
            "party": "Republican",
            "image": "https://bloximages.chicago2.vip.townnews.com/capjournal.com/content/tncms/assets/v3/editorial/6/bb/6bb95f06-3413-11eb-b8a6-77bddc8623d9/5fc6d265841ef.image.png?resize=630%2C630",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-SD-31-lower-ocd-person-e03644b8-7ddd-4ae0-a734-6f6e76e735ba",
              "builtID": "sd-lower-31",
              "externalID": "ocd-person/e03644b8-7ddd-4ae0-a734-6f6e76e735ba",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.fitzgerald@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3785/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4358/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/18602d7b-fb7f-495b-bf94-2b0da2aae00f",
            "firstName": "Scott James",
            "lastName": "Odenbach",
            "fullName": "Scott Odenbach",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.chicago2.vip.townnews.com/bhpioneer.com/content/tncms/assets/v3/editorial/c/7c/c7c81b8a-953e-11ea-815e-e3a0ee958b50/5ebc2daf301cc.image.jpg?resize=400%2C600",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-SD-31-lower-ocd-person-18602d7b-fb7f-495b-bf94-2b0da2aae00f",
              "builtID": "sd-lower-31",
              "externalID": "ocd-person/18602d7b-fb7f-495b-bf94-2b0da2aae00f",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.odenbach@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3784/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4396/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4538/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-31-lower-ocd-person-e03644b8-7ddd-4ae0-a734-6f6e76e735ba": 0,
          "SLDL-SD-31-lower-ocd-person-18602d7b-fb7f-495b-bf94-2b0da2aae00f": 1
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/a1f89521-6a79-4111-aca2-035fc00b91a0",
            "firstName": "Kristin A.",
            "lastName": "Conzet",
            "fullName": "Kris Conzet",
            "gender": "Female",
            "party": "Republican",
            "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3aw1j-KXxkPnD_3MO7VJvCkWUyLrtnzqwfzDH9xaX5A&s",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-SD-32-lower-ocd-person-a1f89521-6a79-4111-aca2-035fc00b91a0",
              "builtID": "sd-lower-32",
              "externalID": "ocd-person/a1f89521-6a79-4111-aca2-035fc00b91a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "kristin.conzet@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4577/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a86e2b88-677f-4819-9fc2-0e9ba4c04377",
            "firstName": "Steven",
            "lastName": "Duffy",
            "fullName": "Steve Duffy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/SteveDuffy.png",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-SD-32-lower-ocd-person-a86e2b88-677f-4819-9fc2-0e9ba4c04377",
              "builtID": "sd-lower-32",
              "externalID": "ocd-person/a86e2b88-677f-4819-9fc2-0e9ba4c04377",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.duffy@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4468/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-32-lower-ocd-person-a1f89521-6a79-4111-aca2-035fc00b91a0": 0,
          "SLDL-SD-32-lower-ocd-person-a86e2b88-677f-4819-9fc2-0e9ba4c04377": 1
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/132b0654-ebb2-4b6b-81b3-d39b41110d45",
            "firstName": "Curt",
            "lastName": "Massie",
            "fullName": "Curt Massie",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cloudfront-us-east-1.images.arcpublishing.com/gray/JN4WUIDOKVFC5NL33TGXE5YFOM.JPG",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-SD-33-lower-ocd-person-132b0654-ebb2-4b6b-81b3-d39b41110d45",
              "builtID": "sd-lower-33",
              "externalID": "ocd-person/132b0654-ebb2-4b6b-81b3-d39b41110d45",
              "geometry": null
            },
            "contactInfo": {
              "email": "curt.massie@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4451/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/cc4fe32a-b912-4876-96e5-32341ccdd06d",
            "firstName": "Phil",
            "lastName": "Jensen",
            "fullName": "Phil Jensen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/JensenPhil_2017.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-SD-33-lower-ocd-person-cc4fe32a-b912-4876-96e5-32341ccdd06d",
              "builtID": "sd-lower-33",
              "externalID": "ocd-person/cc4fe32a-b912-4876-96e5-32341ccdd06d",
              "geometry": null
            },
            "contactInfo": {
              "email": "phil.jensen@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1471&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1442&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1805/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4375/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4511/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-33-lower-ocd-person-132b0654-ebb2-4b6b-81b3-d39b41110d45": 0,
          "SLDL-SD-33-lower-ocd-person-cc4fe32a-b912-4876-96e5-32341ccdd06d": 1
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/6e973aee-319a-423a-aec7-8de125d1b45d",
            "firstName": "Becky J.",
            "lastName": "Drury",
            "fullName": "Becky Drury",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Becky-Drury.PNG",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-SD-34-lower-ocd-person-6e973aee-319a-423a-aec7-8de125d1b45d",
              "builtID": "sd-lower-34",
              "externalID": "ocd-person/6e973aee-319a-423a-aec7-8de125d1b45d",
              "geometry": null
            },
            "contactInfo": {
              "email": "becky.drury@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3792/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4353/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4495/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/082e4c6e-c16a-4bfa-8c17-9c46952da446",
            "firstName": "Mike",
            "lastName": "Derby",
            "fullName": "Mike Derby",
            "gender": "Male",
            "party": "Republican",
            "image": "https://lloydcompanies.com/wp/wp-content/uploads/2020/07/Mike-Derby-816x1024.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-SD-34-lower-ocd-person-082e4c6e-c16a-4bfa-8c17-9c46952da446",
              "builtID": "sd-lower-34",
              "externalID": "ocd-person/082e4c6e-c16a-4bfa-8c17-9c46952da446",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.derby@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3791/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4350/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-34-lower-ocd-person-6e973aee-319a-423a-aec7-8de125d1b45d": 0,
          "SLDL-SD-34-lower-ocd-person-082e4c6e-c16a-4bfa-8c17-9c46952da446": 1
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/ebadc687-f53a-4ec4-9c37-b362a2ea6842",
            "firstName": "Tina L.",
            "lastName": "Mulally",
            "fullName": "Tina Mulally",
            "gender": "Female",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Mulally_2019.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-SD-35-lower-ocd-person-ebadc687-f53a-4ec4-9c37-b362a2ea6842",
              "builtID": "sd-lower-35",
              "externalID": "ocd-person/ebadc687-f53a-4ec4-9c37-b362a2ea6842",
              "geometry": null
            },
            "contactInfo": {
              "email": "tina.mulally@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1646&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1826/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4393/Detail"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/74bc4404-28a3-413b-b01f-3f303da1c8a9",
            "firstName": "Tony E.",
            "lastName": "Randolph",
            "fullName": "Tony Randolph",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Randolph_2019.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-SD-35-lower-ocd-person-74bc4404-28a3-413b-b01f-3f303da1c8a9",
              "builtID": "sd-lower-35",
              "externalID": "ocd-person/74bc4404-28a3-413b-b01f-3f303da1c8a9",
              "geometry": null
            },
            "contactInfo": {
              "email": "tony.randolph@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1647&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1841/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4406/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-35-lower-ocd-person-ebadc687-f53a-4ec4-9c37-b362a2ea6842": 0,
          "SLDL-SD-35-lower-ocd-person-74bc4404-28a3-413b-b01f-3f303da1c8a9": 1
        }
      },
      "26A": {
        "members": [
          {
            "API_ID": "ocd-person/589ced64-f78f-447d-ab9a-8ead6965c61b",
            "firstName": "Eric E.",
            "lastName": "Emery",
            "fullName": "Eric Emery",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.sfsimplified.com/content/images/2022/09/Eric-Emery.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "26A",
              "chamber": "lower",
              "hashID": "SLDL-SD-26A-lower-ocd-person-589ced64-f78f-447d-ab9a-8ead6965c61b",
              "builtID": "sd-lower-26a",
              "externalID": "ocd-person/589ced64-f78f-447d-ab9a-8ead6965c61b",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.emery@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4471/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4499/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-26A-lower-ocd-person-589ced64-f78f-447d-ab9a-8ead6965c61b": 0
        }
      },
      "28B": {
        "members": [
          {
            "API_ID": "ocd-person/2eb5d637-866c-4f84-af30-2121f635ff10",
            "firstName": "Neal",
            "lastName": "Pinnow",
            "fullName": "Neal Pinnow",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.chicago2.vip.townnews.com/rapidcityjournal.com/content/tncms/assets/v3/editorial/5/12/512f92d1-0c1f-5c4d-8ad3-c9b81f45871b/6206955df0b07.image.png",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "28B",
              "chamber": "lower",
              "hashID": "SLDL-SD-28B-lower-ocd-person-2eb5d637-866c-4f84-af30-2121f635ff10",
              "builtID": "sd-lower-28b",
              "externalID": "ocd-person/2eb5d637-866c-4f84-af30-2121f635ff10",
              "geometry": null
            },
            "contactInfo": {
              "email": "neal.pinnow@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4437/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-28B-lower-ocd-person-2eb5d637-866c-4f84-af30-2121f635ff10": 0
        }
      },
      "28A": {
        "members": [
          {
            "API_ID": "ocd-person/65de0102-da54-4913-9745-3c8a6867b85e",
            "firstName": "Oren L.",
            "lastName": "Lesmeister",
            "fullName": "Oren Lesmeister",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Lesmeister_2017.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "28A",
              "chamber": "lower",
              "hashID": "SLDL-SD-28A-lower-ocd-person-65de0102-da54-4913-9745-3c8a6867b85e",
              "builtID": "sd-lower-28a",
              "externalID": "ocd-person/65de0102-da54-4913-9745-3c8a6867b85e",
              "geometry": null
            },
            "contactInfo": {
              "email": "oren.lesmeister@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1527&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1593&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1817/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4385/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-28A-lower-ocd-person-65de0102-da54-4913-9745-3c8a6867b85e": 0
        }
      },
      "26B": {
        "members": [
          {
            "API_ID": "ocd-person/6ce3a563-339c-4bf4-8a70-0f5de85e5693",
            "firstName": "Rebecca",
            "lastName": "Reimer",
            "fullName": "Rebecca Reimer",
            "gender": "Female",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Reimer_2017.jpg",
            "title": "SD Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "26B",
              "chamber": "lower",
              "hashID": "SLDL-SD-26B-lower-ocd-person-6ce3a563-339c-4bf4-8a70-0f5de85e5693",
              "builtID": "sd-lower-26b",
              "externalID": "ocd-person/6ce3a563-339c-4bf4-8a70-0f5de85e5693",
              "geometry": null
            },
            "contactInfo": {
              "email": "rebecca.reimer@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1554&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1637&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1844/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4409/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4552/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-SD-26B-lower-ocd-person-6ce3a563-339c-4bf4-8a70-0f5de85e5693": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/9f5c460a-6f5d-4198-8be2-e78a9d4980bf",
            "firstName": "Michael H.",
            "lastName": "Rohl",
            "fullName": "Michael Rohl",
            "gender": "Male",
            "party": "Republican",
            "image": "https://lawmakerdocuments.blob.core.usgovcloudapi.net/photos/rohl,%20michael%20sen-2772%20full%20rt.webp",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-SD-1-upper-ocd-person-9f5c460a-6f5d-4198-8be2-e78a9d4980bf",
              "builtID": "sd-upper-1",
              "externalID": "ocd-person/9f5c460a-6f5d-4198-8be2-e78a9d4980bf",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.rohl@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3805/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4410/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4554/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-1-upper-ocd-person-9f5c460a-6f5d-4198-8be2-e78a9d4980bf": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/d8b7a70b-2c34-4319-aaaf-54112c5ca471",
            "firstName": "Steve",
            "lastName": "Kolbeck",
            "fullName": "Steve Kolbeck",
            "gender": "Male",
            "party": "Republican",
            "image": "https://kolbeckforsouthdakota.com/wp-content/uploads/2022/02/Kolbeck-Headshot1.png-684x1024.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-SD-2-upper-ocd-person-d8b7a70b-2c34-4319-aaaf-54112c5ca471",
              "builtID": "sd-upper-2",
              "externalID": "ocd-person/d8b7a70b-2c34-4319-aaaf-54112c5ca471",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.kolbeck@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4453/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-2-upper-ocd-person-d8b7a70b-2c34-4319-aaaf-54112c5ca471": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/02f0edff-2879-4fbb-9b23-796358690d07",
            "firstName": "Al R.",
            "lastName": "Novstrup",
            "fullName": "Al Novstrup",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/NOVSTRUP_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-SD-3-upper-ocd-person-02f0edff-2879-4fbb-9b23-796358690d07",
              "builtID": "sd-upper-3",
              "externalID": "ocd-person/02f0edff-2879-4fbb-9b23-796358690d07",
              "geometry": null
            },
            "contactInfo": {
              "email": "al.novstrup@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1456&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1595&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1828/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4395/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-3-upper-ocd-person-02f0edff-2879-4fbb-9b23-796358690d07": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/a49e8075-4515-434c-b43b-fb8f01d5072d",
            "firstName": "John J.",
            "lastName": "Wiik",
            "fullName": "John Wiik",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/WIIK_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-SD-4-upper-ocd-person-a49e8075-4515-434c-b43b-fb8f01d5072d",
              "builtID": "sd-upper-4",
              "externalID": "ocd-person/a49e8075-4515-434c-b43b-fb8f01d5072d",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.wiik@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1510&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1433&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1865/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4432/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4573/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-4-upper-ocd-person-a49e8075-4515-434c-b43b-fb8f01d5072d": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/a2152143-6b32-48e5-bda2-57eed76df09f",
            "firstName": "Lee Anton",
            "lastName": "Schoenbeck",
            "fullName": "Lee Schoenbeck",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Schoenbeck_2019.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-SD-5-upper-ocd-person-a2152143-6b32-48e5-bda2-57eed76df09f",
              "builtID": "sd-upper-5",
              "externalID": "ocd-person/a2152143-6b32-48e5-bda2-57eed76df09f",
              "geometry": null
            },
            "contactInfo": {
              "email": "lee.schoenbeck@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1561&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1850/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4413/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-5-upper-ocd-person-a2152143-6b32-48e5-bda2-57eed76df09f": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/369487ea-58ed-4945-ab2e-c33fc8767002",
            "firstName": "Herman",
            "lastName": "Otten",
            "fullName": "Herman Otten",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/OttenHerman_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-SD-6-upper-ocd-person-369487ea-58ed-4945-ab2e-c33fc8767002",
              "builtID": "sd-upper-6",
              "externalID": "ocd-person/369487ea-58ed-4945-ab2e-c33fc8767002",
              "geometry": null
            },
            "contactInfo": {
              "email": "herman.otten@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1484&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1575&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1831/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4399/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-6-upper-ocd-person-369487ea-58ed-4945-ab2e-c33fc8767002": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/6206c534-4bec-4228-b86f-5005547c6957",
            "firstName": "Tim",
            "lastName": "Reed",
            "fullName": "Tim Reed",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Reed_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-SD-7-upper-ocd-person-6206c534-4bec-4228-b86f-5005547c6957",
              "builtID": "sd-upper-7",
              "externalID": "ocd-person/6206c534-4bec-4228-b86f-5005547c6957",
              "geometry": null
            },
            "contactInfo": {
              "email": "tim.reed@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1533&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1572&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1843/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4407/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-7-upper-ocd-person-6206c534-4bec-4228-b86f-5005547c6957": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/6dabb48d-f267-4fb3-9e8c-0a3c182d44d3",
            "firstName": "Casey",
            "lastName": "Crabtree",
            "fullName": "Casey Crabtree",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/crabtree_2020.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-SD-8-upper-ocd-person-6dabb48d-f267-4fb3-9e8c-0a3c182d44d3",
              "builtID": "sd-upper-8",
              "externalID": "ocd-person/6dabb48d-f267-4fb3-9e8c-0a3c182d44d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "casey.crabtree@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/1881/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4345/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-8-upper-ocd-person-6dabb48d-f267-4fb3-9e8c-0a3c182d44d3": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/85652e37-d4d4-4b20-b6c0-cb1e11b31353",
            "firstName": "Brent",
            "lastName": "Hoffman",
            "fullName": "B.R. Hoffman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://lawmakerdocuments.blob.core.usgovcloudapi.net/photos/hoffman,%20brent%20br%20sen-0417%20rt.webp",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-SD-9-upper-ocd-person-85652e37-d4d4-4b20-b6c0-cb1e11b31353",
              "builtID": "sd-upper-9",
              "externalID": "ocd-person/85652e37-d4d4-4b20-b6c0-cb1e11b31353",
              "geometry": null
            },
            "contactInfo": {
              "email": "brent.hoffman@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4457/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-9-upper-ocd-person-85652e37-d4d4-4b20-b6c0-cb1e11b31353": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/81df3498-7677-4749-9753-c1ae9799e444",
            "firstName": "Elizabeth",
            "lastName": "Larson",
            "fullName": "Liz Larson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://images.squarespace-cdn.com/content/v1/5f4289c48c50b9136fcef754/1603556963629-XEPO3FP0WARBK0TRBH52/BRAV6788_whitecoat.jpg?format=750w",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-SD-10-upper-ocd-person-81df3498-7677-4749-9753-c1ae9799e444",
              "builtID": "sd-upper-10",
              "externalID": "ocd-person/81df3498-7677-4749-9753-c1ae9799e444",
              "geometry": null
            },
            "contactInfo": {
              "email": "liz.larson@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4466/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-10-upper-ocd-person-81df3498-7677-4749-9753-c1ae9799e444": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/b4220d52-fc9f-49e7-9802-ef2aa4e99baa",
            "firstName": "James Bruce",
            "lastName": "Stalzer",
            "fullName": "Jim Stalzer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Stalzer_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-SD-11-upper-ocd-person-b4220d52-fc9f-49e7-9802-ef2aa4e99baa",
              "builtID": "sd-upper-11",
              "externalID": "ocd-person/b4220d52-fc9f-49e7-9802-ef2aa4e99baa",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.stalzer@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1486&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1584&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1856/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4419/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-11-upper-ocd-person-b4220d52-fc9f-49e7-9802-ef2aa4e99baa": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/169a8374-daaa-4884-9add-d1719eabd966",
            "firstName": "Arch E.",
            "lastName": "Beal",
            "fullName": "Arch Beal",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/BEAL_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-SD-12-upper-ocd-person-169a8374-daaa-4884-9add-d1719eabd966",
              "builtID": "sd-upper-12",
              "externalID": "ocd-person/169a8374-daaa-4884-9add-d1719eabd966",
              "geometry": null
            },
            "contactInfo": {
              "email": "arch.beal@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1518&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1567&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1769/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4336/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-12-upper-ocd-person-169a8374-daaa-4884-9add-d1719eabd966": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/fd692c4a-e6c1-45c1-b970-1fcdbe732cfe",
            "firstName": "Jack R.",
            "lastName": "Kolbeck",
            "fullName": "Jack Kolbeck",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Kolbeck_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-SD-13-upper-ocd-person-fd692c4a-e6c1-45c1-b970-1fcdbe732cfe",
              "builtID": "sd-upper-13",
              "externalID": "ocd-person/fd692c4a-e6c1-45c1-b970-1fcdbe732cfe",
              "geometry": null
            },
            "contactInfo": {
              "email": "jack.kolbeck@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1538&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1600&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1812/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4382/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-13-upper-ocd-person-fd692c4a-e6c1-45c1-b970-1fcdbe732cfe": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/5e23171d-49a3-471f-a6f8-24b057f2d836",
            "firstName": "Larry P.",
            "lastName": "Zikmund",
            "fullName": "Larry Zikmund",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/ZIKMUND_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-SD-14-upper-ocd-person-5e23171d-49a3-471f-a6f8-24b057f2d836",
              "builtID": "sd-upper-14",
              "externalID": "ocd-person/5e23171d-49a3-471f-a6f8-24b057f2d836",
              "geometry": null
            },
            "contactInfo": {
              "email": "larry.zikmund@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/1870/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4436/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4576/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-14-upper-ocd-person-5e23171d-49a3-471f-a6f8-24b057f2d836": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/20c7b82e-2a62-48b1-bcb3-d43646c460e1",
            "firstName": "Reynold F.",
            "lastName": "Nesiba",
            "fullName": "Reynold Nesiba",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Nesiba_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-SD-15-upper-ocd-person-20c7b82e-2a62-48b1-bcb3-d43646c460e1",
              "builtID": "sd-upper-15",
              "externalID": "ocd-person/20c7b82e-2a62-48b1-bcb3-d43646c460e1",
              "geometry": null
            },
            "contactInfo": {
              "email": "reynold.nesiba@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1523&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1437&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1827/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4394/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-15-upper-ocd-person-20c7b82e-2a62-48b1-bcb3-d43646c460e1": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/02a43643-ce8a-41fa-a43b-5d5cbf33f1f0",
            "firstName": "James W.",
            "lastName": "Bolin",
            "fullName": "Jim Bolin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/BOLIN_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-SD-16-upper-ocd-person-02a43643-ce8a-41fa-a43b-5d5cbf33f1f0",
              "builtID": "sd-upper-16",
              "externalID": "ocd-person/02a43643-ce8a-41fa-a43b-5d5cbf33f1f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.bolin@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1467&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1441&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1771/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4338/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-16-upper-ocd-person-02a43643-ce8a-41fa-a43b-5d5cbf33f1f0": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/c2149a50-34ee-42c5-8440-e1c5ddcf66e4",
            "firstName": "Sydney",
            "lastName": "Davis",
            "fullName": "Sydney Davis",
            "gender": "Female",
            "party": "Republican",
            "image": "https://bloximages.chicago2.vip.townnews.com/plaintalk.net/content/tncms/assets/v3/editorial/e/f2/ef29df3c-0515-11eb-b916-33278c4cf760/5f77d0e624605.image.jpg?resize=500%2C750",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-SD-17-upper-ocd-person-c2149a50-34ee-42c5-8440-e1c5ddcf66e4",
              "builtID": "sd-upper-17",
              "externalID": "ocd-person/c2149a50-34ee-42c5-8440-e1c5ddcf66e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "sydney.davis@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3783/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4348/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-17-upper-ocd-person-c2149a50-34ee-42c5-8440-e1c5ddcf66e4": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/c80f69e1-70f3-4ee8-b74b-53f532db335b",
            "firstName": "Jean M.",
            "lastName": "Hunhoff",
            "fullName": "Jean Hunhoff",
            "gender": "Female",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/HUNHOFF_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-SD-18-upper-ocd-person-c80f69e1-70f3-4ee8-b74b-53f532db335b",
              "builtID": "sd-upper-18",
              "externalID": "ocd-person/c80f69e1-70f3-4ee8-b74b-53f532db335b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jean.hunhoff@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/1803/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4372/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-18-upper-ocd-person-c80f69e1-70f3-4ee8-b74b-53f532db335b": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/bd74c8bf-cc11-4bfc-a721-269a01a6ccb1",
            "firstName": "Kyle",
            "lastName": "Schoenfish",
            "fullName": "Kyle Schoenfish",
            "gender": "Male",
            "party": "Republican",
            "image": "http://sdlegislature.gov/inc/img/Legislators/Schoenfish_2019.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-SD-19-upper-ocd-person-bd74c8bf-cc11-4bfc-a721-269a01a6ccb1",
              "builtID": "sd-upper-19",
              "externalID": "ocd-person/bd74c8bf-cc11-4bfc-a721-269a01a6ccb1",
              "geometry": null
            },
            "contactInfo": {
              "email": "kyle.schoenfish@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/1851/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4414/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4559/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-19-upper-ocd-person-bd74c8bf-cc11-4bfc-a721-269a01a6ccb1": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/f206bc39-14a5-42b5-804b-c4db9c3dd78d",
            "firstName": "Joshua M.",
            "lastName": "Klumb",
            "fullName": "Josh Klumb",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/KLUMB_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-SD-20-upper-ocd-person-f206bc39-14a5-42b5-804b-c4db9c3dd78d",
              "builtID": "sd-upper-20",
              "externalID": "ocd-person/f206bc39-14a5-42b5-804b-c4db9c3dd78d",
              "geometry": null
            },
            "contactInfo": {
              "email": "joshua.klumb@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1512&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1585&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1811/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4381/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-20-upper-ocd-person-f206bc39-14a5-42b5-804b-c4db9c3dd78d": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/efdd607a-14c2-4be2-97c9-68a703e47065",
            "firstName": "Erin Ann",
            "lastName": "Tobin",
            "fullName": "Erin Tobin",
            "gender": "Female",
            "party": "Republican",
            "image": "https://images.squarespace-cdn.com/content/v1/5e91f9066e69ba1148ea983f/1586723232612-H1O2JFD7KRC8G97HHEMO/ke17ZwdGBToddI8pDm48kMXRibDYMhUiookWqwUxEZ97gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0luUmcNM2NMBIHLdYyXL-Jww_XBra4mrrAHD6FMA3bNKOBm5vyMDUBjVQdcIrt03OQ/Erin+Tobin.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-SD-21-upper-ocd-person-efdd607a-14c2-4be2-97c9-68a703e47065",
              "builtID": "sd-upper-21",
              "externalID": "ocd-person/efdd607a-14c2-4be2-97c9-68a703e47065",
              "geometry": null
            },
            "contactInfo": {
              "email": "erin.tobin@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3796/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4426/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4567/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-21-upper-ocd-person-efdd607a-14c2-4be2-97c9-68a703e47065": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/3c09f15f-4284-45b8-b89b-e944990c8a9d",
            "firstName": "David",
            "lastName": "Wheeler",
            "fullName": "David Wheeler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/David-Wheeler.PNG",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-SD-22-upper-ocd-person-3c09f15f-4284-45b8-b89b-e944990c8a9d",
              "builtID": "sd-upper-22",
              "externalID": "ocd-person/3c09f15f-4284-45b8-b89b-e944990c8a9d",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.wheeler@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3795/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4430/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4572/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-22-upper-ocd-person-3c09f15f-4284-45b8-b89b-e944990c8a9d": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/43dc3de8-f174-41d4-8b95-4b8918989f08",
            "firstName": "Bryan J.",
            "lastName": "Breitling",
            "fullName": "Bryan Breitling",
            "gender": "Male",
            "party": "Republican",
            "image": "https://leadershipsouthdakota.com/wp-content/uploads/2018/10/Breitling_Bryan_300x300.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-SD-23-upper-ocd-person-43dc3de8-f174-41d4-8b95-4b8918989f08",
              "builtID": "sd-upper-23",
              "externalID": "ocd-person/43dc3de8-f174-41d4-8b95-4b8918989f08",
              "geometry": null
            },
            "contactInfo": {
              "email": "bryan.breitling@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3798/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4340/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4481/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-23-upper-ocd-person-43dc3de8-f174-41d4-8b95-4b8918989f08": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/c8ca77f5-97e7-4604-aec3-fe4ee5e7dc31",
            "firstName": "Jim",
            "lastName": "Mehlhaff",
            "fullName": "Jim Mehlhaff",
            "gender": "Male",
            "party": "Republican",
            "image": "https://jimmehlhaff.com/wp-content/uploads/2022/03/Jim-High-Res-819x1024.jpeg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-SD-24-upper-ocd-person-c8ca77f5-97e7-4604-aec3-fe4ee5e7dc31",
              "builtID": "sd-upper-24",
              "externalID": "ocd-person/c8ca77f5-97e7-4604-aec3-fe4ee5e7dc31",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.mehlhaff@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4460/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-24-upper-ocd-person-c8ca77f5-97e7-4604-aec3-fe4ee5e7dc31": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/f6b50a35-18e6-4e0c-bdd9-071f4a99bbed",
            "firstName": "Tom",
            "lastName": "Pischke",
            "fullName": "Tom Pischke",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Pischke_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-SD-25-upper-ocd-person-f6b50a35-18e6-4e0c-bdd9-071f4a99bbed",
              "builtID": "sd-upper-25",
              "externalID": "ocd-person/f6b50a35-18e6-4e0c-bdd9-071f4a99bbed",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.pischke@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1543&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1446&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1837/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4404/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-25-upper-ocd-person-f6b50a35-18e6-4e0c-bdd9-071f4a99bbed": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/c8946c60-0295-4bec-bb43-7565bbb565ab",
            "firstName": "Shawn L.",
            "lastName": "Bordeaux",
            "fullName": "Shawn Bordeaux",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://sdlegislature.gov/inc/img/Legislators/BORDEAUX_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-SD-26-upper-ocd-person-c8946c60-0295-4bec-bb43-7565bbb565ab",
              "builtID": "sd-upper-26",
              "externalID": "ocd-person/c8946c60-0295-4bec-bb43-7565bbb565ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "shawn.bordeaux@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1513&Session=2018s",
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1592&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1772/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4339/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4480/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-26-upper-ocd-person-c8946c60-0295-4bec-bb43-7565bbb565ab": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/d2b91681-6a72-42f8-94f6-c489309798b7",
            "firstName": "Red Dawn",
            "lastName": "Foster",
            "fullName": "Red Dawn Foster",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://listen.sdpb.org/sites/sdpb/files/201905/lege_red_dawn_foster_2019.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-SD-27-upper-ocd-person-d2b91681-6a72-42f8-94f6-c489309798b7",
              "builtID": "sd-upper-27",
              "externalID": "ocd-person/d2b91681-6a72-42f8-94f6-c489309798b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "reddawn.foster@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1605&Session=2019",
                "https://sdlegislature.gov/Legislators/Profile/1789/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4359/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-27-upper-ocd-person-d2b91681-6a72-42f8-94f6-c489309798b7": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/d1329f2b-43b5-4276-ba13-b4ceced4f0b0",
            "firstName": "Ryan M.",
            "lastName": "Maher",
            "fullName": "Ryan Maher",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Maher_2019.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-SD-28-upper-ocd-person-d1329f2b-43b5-4276-ba13-b4ceced4f0b0",
              "builtID": "sd-upper-28",
              "externalID": "ocd-person/d1329f2b-43b5-4276-ba13-b4ceced4f0b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryan.maher@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Legislators/MemberDetail.aspx?Member=1462&Session=2018s",
                "https://sdlegislature.gov/Legislators/Profile/1819/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4386/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-28-upper-ocd-person-d1329f2b-43b5-4276-ba13-b4ceced4f0b0": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/2de0c972-4ac8-48eb-8754-23f214079b40",
            "firstName": "Dean Albert",
            "lastName": "Wink",
            "fullName": "Dean Wink",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.thinkdeanwink.com/img/dean-wink.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-SD-29-upper-ocd-person-2de0c972-4ac8-48eb-8754-23f214079b40",
              "builtID": "sd-upper-29",
              "externalID": "ocd-person/2de0c972-4ac8-48eb-8754-23f214079b40",
              "geometry": null
            },
            "contactInfo": {
              "email": "dean.wink@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/3794/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4434/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-29-upper-ocd-person-2de0c972-4ac8-48eb-8754-23f214079b40": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/ba5d7a37-c0f2-42fe-b262-685623f0be32",
            "firstName": "Julie",
            "lastName": "Frye-Mueller",
            "fullName": "Julie Frye-Mueller",
            "gender": "Female",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/FryeMueller_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-SD-30-upper-ocd-person-ba5d7a37-c0f2-42fe-b262-685623f0be32",
              "builtID": "sd-upper-30",
              "externalID": "ocd-person/ba5d7a37-c0f2-42fe-b262-685623f0be32",
              "geometry": null
            },
            "contactInfo": {
              "email": "julie.fryemueller@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/1790/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4360/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-30-upper-ocd-person-ba5d7a37-c0f2-42fe-b262-685623f0be32": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/73f418c4-6d07-4ef5-924f-1664738d2b46",
            "firstName": "Randy",
            "lastName": "Deibert",
            "fullName": "Randy Deibert",
            "gender": "Male",
            "party": "Republican",
            "image": "https://deibert4senate31.com/wp-content/uploads/2022/05/cropped-Portrait_Logo2.png",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-SD-31-upper-ocd-person-73f418c4-6d07-4ef5-924f-1664738d2b46",
              "builtID": "sd-upper-31",
              "externalID": "ocd-person/73f418c4-6d07-4ef5-924f-1664738d2b46",
              "geometry": null
            },
            "contactInfo": {
              "email": "randy.deibert@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4448/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-31-upper-ocd-person-73f418c4-6d07-4ef5-924f-1664738d2b46": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/e0548110-ef50-435f-8c99-fbd53a06d8ff",
            "firstName": "Helene Marie",
            "lastName": "Duhamel Duffy",
            "fullName": "Helene Duhamel",
            "gender": "Female",
            "party": "Republican",
            "image": "http://sdlegislature.gov/inc/img/Legislators/Duhamel_2020.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-SD-32-upper-ocd-person-e0548110-ef50-435f-8c99-fbd53a06d8ff",
              "builtID": "sd-upper-32",
              "externalID": "ocd-person/e0548110-ef50-435f-8c99-fbd53a06d8ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "helene.duhamel@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/1785/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4355/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-32-upper-ocd-person-e0548110-ef50-435f-8c99-fbd53a06d8ff": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/19b6f18f-5afe-411f-a791-ed81213eafd0",
            "firstName": "David L.",
            "lastName": "Johnson",
            "fullName": "David Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Johnson_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-SD-33-upper-ocd-person-19b6f18f-5afe-411f-a791-ed81213eafd0",
              "builtID": "sd-upper-33",
              "externalID": "ocd-person/19b6f18f-5afe-411f-a791-ed81213eafd0",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.johnson@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/1808/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4378/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-33-upper-ocd-person-19b6f18f-5afe-411f-a791-ed81213eafd0": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/f3f53292-ca40-4a48-b307-1b88fa5f552b",
            "firstName": "Michael G.",
            "lastName": "Diedrich",
            "fullName": "Mike Diedrich",
            "gender": "Male",
            "party": "Republican",
            "image": "https://sdlegislature.gov/inc/img/Legislators/Diedrich_2017.jpg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-SD-34-upper-ocd-person-f3f53292-ca40-4a48-b307-1b88fa5f552b",
              "builtID": "sd-upper-34",
              "externalID": "ocd-person/f3f53292-ca40-4a48-b307-1b88fa5f552b",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.diedrich@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/1783/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4352/Detail",
                "https://sdlegislature.gov/Legislators/Profile/4493/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-34-upper-ocd-person-f3f53292-ca40-4a48-b307-1b88fa5f552b": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/4a69305c-ac69-477a-a03f-1ce66cd0de92",
            "firstName": "Michael",
            "lastName": "Walsh",
            "fullName": "Mike Walsh",
            "gender": "Male",
            "party": "Republican",
            "image": "https://substackcdn.com/image/fetch/w_1456,c_limit,f_webp,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F175c207c-1e92-4c15-9403-728a2baa0ad2_400x400.jpeg",
            "title": "SD Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "SD",
              "stateFull": "South Dakota",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-SD-35-upper-ocd-person-4a69305c-ac69-477a-a03f-1ce66cd0de92",
              "builtID": "sd-upper-35",
              "externalID": "ocd-person/4a69305c-ac69-477a-a03f-1ce66cd0de92",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.walsh@sdlegislature.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdlegislature.gov/Legislators/Profile/4578/Detail"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-SD-35-upper-ocd-person-4a69305c-ac69-477a-a03f-1ce66cd0de92": 0
        }
      }
    }
  },
  "TN": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "TN",
          "stateFull": "Tennessee",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "TN",
            "stateFull": "Tennessee",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-TN---",
            "builtID": "tn--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-TN---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "B001243",
          "in_office": true,
          "firstName": "Marsha",
          "lastName": "Blackburn",
          "middleName": null,
          "fullName": "Marsha Blackburn",
          "gender": "F",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/B001243.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "TN",
            "stateFull": "Tennessee",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-TN---B001243",
            "builtID": "tn--",
            "externalID": "B001243",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.blackburn.senate.gov/email-me",
            "address1": "357 Dirksen Senate Office Building",
            "address2": null,
            "phone1": "202-224-3344",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "MarshaBlackburn",
            "youtube": "RepMarshaBlackburn",
            "instagram": null,
            "facebook": "marshablackburn",
            "urls": ["https://www.blackburn.senate.gov"],
            "rss_url": null
          },
          "title": "TN Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/B001243.json",
          "govtrack_id": "400032",
          "cspan_id": "31226",
          "votesmart_id": "25186",
          "icpsr_id": "20351",
          "crp_id": "N00003105",
          "google_entity_id": "/m/01fnkt",
          "state_rank": "senior",
          "lis_id": "S396",
          "suffix": null,
          "date_of_birth": "1952-06-06",
          "leadership_role": null,
          "fec_candidate_id": "H2TN06030",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "5",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 30,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 5.42,
          "votes_with_party_pct": 93.22,
          "votes_against_party_pct": 6.78,
          "ocd_id": "ocd-division/country:us/state:tn"
        },
        {
          "API_ID": "H000601",
          "in_office": true,
          "firstName": "Bill",
          "lastName": "Hagerty",
          "middleName": null,
          "fullName": "Bill Hagerty",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/H000601.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "TN",
            "stateFull": "Tennessee",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-TN---H000601",
            "builtID": "tn--",
            "externalID": "H000601",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.hagerty.senate.gov/email-me/",
            "address1": "248 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-4944",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorHagerty",
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.hagerty.senate.gov"],
            "rss_url": "https://www.hagerty.senate.gov/press-releases/feed/"
          },
          "title": "TN Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/H000601.json",
          "govtrack_id": "456798",
          "cspan_id": null,
          "votesmart_id": "128466",
          "icpsr_id": null,
          "crp_id": "N00045369",
          "google_entity_id": "/g/11c1xp6h9v",
          "state_rank": "junior",
          "lis_id": "S407",
          "suffix": null,
          "date_of_birth": "1959-08-14",
          "leadership_role": null,
          "fec_candidate_id": "S0TN00169",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 58,
          "total_present": 0,
          "last_updated": "2024-06-20 15:30:30 -0400",
          "missed_votes_pct": 10.47,
          "votes_with_party_pct": 93.28,
          "votes_against_party_pct": 6.72,
          "ocd_id": "ocd-division/country:us/state:tn"
        }
      ],
      "hash": { "SENATE-TN---B001243": 0, "SENATE-TN---H000601": 1 }
    },
    "HOUSE": {
      "02": {
        "members": [
          {
            "API_ID": "B001309",
            "in_office": true,
            "firstName": "Tim",
            "lastName": "Burchett",
            "middleName": null,
            "fullName": "Tim Burchett",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001309.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-TN-02--B001309",
              "builtID": "tn--02",
              "externalID": "B001309",
              "geometry": null,
              "geoid": "4702"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1122 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5435",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepTimBurchett",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://burchett.house.gov"],
              "rss_url": null
            },
            "title": "TN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001309.json",
            "govtrack_id": "412817",
            "cspan_id": null,
            "votesmart_id": "24379",
            "icpsr_id": null,
            "crp_id": "N00041594",
            "google_entity_id": "/m/03yt07",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1964-08-25",
            "leadership_role": null,
            "fec_candidate_id": "H8TN02119",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 2,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.19,
            "votes_with_party_pct": 84.32,
            "votes_against_party_pct": 13.75,
            "ocd_id": "ocd-division/country:us/state:tn/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TN-02--B001309": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "C001068",
            "in_office": true,
            "firstName": "Steve",
            "lastName": "Cohen",
            "middleName": null,
            "fullName": "Steve Cohen",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001068.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-TN-09--C001068",
              "builtID": "tn--09",
              "externalID": "C001068",
              "geometry": null,
              "geoid": "4709"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2268 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3265",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepCohen",
              "youtube": "repcohen",
              "instagram": null,
              "facebook": "CongressmanSteveCohen",
              "urls": ["https://cohen.house.gov"],
              "rss_url": "https://cohen.house.gov/rss.xml"
            },
            "title": "TN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001068.json",
            "govtrack_id": "412236",
            "cspan_id": "1022876",
            "votesmart_id": "24340",
            "icpsr_id": "20748",
            "crp_id": "N00003225",
            "google_entity_id": "/m/04l8cr",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1949-05-24",
            "leadership_role": null,
            "fec_candidate_id": "H6TN09068",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "18",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 53,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 5.02,
            "votes_with_party_pct": 96.07,
            "votes_against_party_pct": 2.02,
            "ocd_id": "ocd-division/country:us/state:tn/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TN-09--C001068": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "D000616",
            "in_office": true,
            "firstName": "Scott",
            "lastName": "DesJarlais",
            "middleName": null,
            "fullName": "Scott DesJarlais",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/D000616.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-TN-04--D000616",
              "builtID": "tn--04",
              "externalID": "D000616",
              "geometry": null,
              "geoid": "4704"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2304 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6831",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "DesJarlaisTN04",
              "youtube": "ScottDesJarlaisTN04",
              "instagram": null,
              "facebook": "ScottDesJarlaisTN04",
              "urls": ["https://desjarlais.house.gov"],
              "rss_url": "https://desjarlais.house.gov/rss.xml"
            },
            "title": "TN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000616.json",
            "govtrack_id": "412477",
            "cspan_id": "623517",
            "votesmart_id": "123473",
            "icpsr_id": "21179",
            "crp_id": "N00030957",
            "google_entity_id": "/m/09k4h2_",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1964-02-21",
            "leadership_role": null,
            "fec_candidate_id": "H0TN04195",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 30,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.84,
            "votes_with_party_pct": 92.54,
            "votes_against_party_pct": 5.57,
            "ocd_id": "ocd-division/country:us/state:tn/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TN-04--D000616": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "F000459",
            "in_office": true,
            "firstName": "Chuck",
            "lastName": "Fleischmann",
            "middleName": null,
            "fullName": "Charles J. \"Chuck\" Fleischmann",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/F000459.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-TN-03--F000459",
              "builtID": "tn--03",
              "externalID": "F000459",
              "geometry": null,
              "geoid": "4703"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2187 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3271",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepChuck",
              "youtube": "repchuck",
              "instagram": null,
              "facebook": "repchuck",
              "urls": ["https://fleischmann.house.gov"],
              "rss_url": "https://fleischmann.house.gov/rss.xml"
            },
            "title": "TN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000459.json",
            "govtrack_id": "412476",
            "cspan_id": "95146",
            "votesmart_id": "123456",
            "icpsr_id": "21178",
            "crp_id": "N00030815",
            "google_entity_id": "/m/0ddd54f",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1962-10-11",
            "leadership_role": null,
            "fec_candidate_id": "H0TN03254",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 2,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.19,
            "votes_with_party_pct": 87.21,
            "votes_against_party_pct": 10.95,
            "ocd_id": "ocd-division/country:us/state:tn/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TN-03--F000459": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "G000590",
            "in_office": true,
            "firstName": "Mark",
            "lastName": "Green",
            "middleName": null,
            "fullName": "Mark E. Green",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000590.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-TN-07--G000590",
              "builtID": "tn--07",
              "externalID": "G000590",
              "geometry": null,
              "geoid": "4707"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2446 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2811",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMarkGreen",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://markgreen.house.gov"],
              "rss_url": null
            },
            "title": "TN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000590.json",
            "govtrack_id": "412819",
            "cspan_id": null,
            "votesmart_id": "139030",
            "icpsr_id": null,
            "crp_id": "N00041873",
            "google_entity_id": "/m/09rx6jq",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1964-11-08",
            "leadership_role": null,
            "fec_candidate_id": "H8TN07076",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 87,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 8.24,
            "votes_with_party_pct": 92.03,
            "votes_against_party_pct": 5.97,
            "ocd_id": "ocd-division/country:us/state:tn/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TN-07--G000590": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "H001086",
            "in_office": true,
            "firstName": "Diana",
            "lastName": "Harshbarger",
            "middleName": null,
            "fullName": "Diana Harshbarger",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/H001086.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-TN-01--H001086",
              "builtID": "tn--01",
              "externalID": "H001086",
              "geometry": null,
              "geoid": "4701"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "167 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-6356",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepHarshbarger",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://harshbarger.house.gov"],
              "rss_url": null
            },
            "title": "TN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001086.json",
            "govtrack_id": "456844",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00046688",
            "google_entity_id": "/g/11f67z977k",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1960-01-01",
            "leadership_role": null,
            "fec_candidate_id": "H0TN01118",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 3,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.28,
            "votes_with_party_pct": 88.95,
            "votes_against_party_pct": 9.11,
            "ocd_id": "ocd-division/country:us/state:tn/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TN-01--H001086": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "K000392",
            "in_office": true,
            "firstName": "David",
            "lastName": "Kustoff",
            "middleName": null,
            "fullName": "David Kustoff",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/K000392.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-TN-08--K000392",
              "builtID": "tn--08",
              "externalID": "K000392",
              "geometry": null,
              "geoid": "4708"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "560 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-4714",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "repdavidkustoff",
              "youtube": null,
              "instagram": null,
              "facebook": "RepDavidKustoff",
              "urls": ["https://kustoff.house.gov"],
              "rss_url": "https://kustoff.house.gov/rss.xml"
            },
            "title": "TN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000392.json",
            "govtrack_id": "412724",
            "cspan_id": "28903",
            "votesmart_id": "48997",
            "icpsr_id": "21731",
            "crp_id": "N00025445",
            "google_entity_id": "/g/11c1qmm_nk",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1966-10-08",
            "leadership_role": null,
            "fec_candidate_id": "H2TN07103",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 24,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.27,
            "votes_with_party_pct": 92.68,
            "votes_against_party_pct": 5.34,
            "ocd_id": "ocd-division/country:us/state:tn/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TN-08--K000392": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "O000175",
            "in_office": true,
            "firstName": "William",
            "lastName": "Ogles",
            "middleName": null,
            "fullName": "Andrew Ogles",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/O000175.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-TN-05--O000175",
              "builtID": "tn--05",
              "externalID": "O000175",
              "geometry": null,
              "geoid": "4705"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "151 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-4311",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ogles.house.gov/"],
              "rss_url": null
            },
            "title": "TN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/O000175.json",
            "govtrack_id": "456939",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1971-06-18",
            "leadership_role": null,
            "fec_candidate_id": "H2TN05446",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 39,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 3.69,
            "votes_with_party_pct": 85.36,
            "votes_against_party_pct": 12.64,
            "ocd_id": "ocd-division/country:us/state:tn/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TN-05--O000175": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "R000612",
            "in_office": true,
            "firstName": "John",
            "lastName": "Rose",
            "middleName": null,
            "fullName": "John W. Rose",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/R000612.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-TN-06--R000612",
              "builtID": "tn--06",
              "externalID": "R000612",
              "geometry": null,
              "geoid": "4706"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2238 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4231",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJohnRose",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://johnrose.house.gov"],
              "rss_url": null
            },
            "title": "TN House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/R000612.json",
            "govtrack_id": "412818",
            "cspan_id": null,
            "votesmart_id": "180452",
            "icpsr_id": null,
            "crp_id": "N00041599",
            "google_entity_id": "/g/11g8xhg1mz",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1965-02-23",
            "leadership_role": null,
            "fec_candidate_id": "H8TN06094",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 13,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.23,
            "votes_with_party_pct": 93.35,
            "votes_against_party_pct": 4.7,
            "ocd_id": "ocd-division/country:us/state:tn/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TN-06--R000612": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/d1ead0ef-cc7a-48b3-9fec-0afc8b9d013d",
            "firstName": "John",
            "lastName": "Crawford",
            "fullName": "John Crawford",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h1.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-TN-1-lower-ocd-person-d1ead0ef-cc7a-48b3-9fec-0afc8b9d013d",
              "builtID": "tn-lower-1",
              "externalID": "ocd-person/d1ead0ef-cc7a-48b3-9fec-0afc8b9d013d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.john.crawford@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 540, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-7623",
              "fax1": null,
              "fax2": "615-253-0272",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h1.html",
                "https://www.capitol.tn.gov/house/members/h1.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-1-lower-ocd-person-d1ead0ef-cc7a-48b3-9fec-0afc8b9d013d": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/1e3046c6-b44b-43dd-9cb2-937f40e97a6a",
            "firstName": "Charles",
            "lastName": "Hulsey",
            "fullName": "Bud Hulsey",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h2.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-TN-2-lower-ocd-person-1e3046c6-b44b-43dd-9cb2-937f40e97a6a",
              "builtID": "tn-lower-2",
              "externalID": "ocd-person/1e3046c6-b44b-43dd-9cb2-937f40e97a6a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.bud.hulsey@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 519, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2886",
              "fax1": null,
              "fax2": "615-253-0247",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h2.html",
                "https://www.capitol.tn.gov/house/members/h2.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H2"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-2-lower-ocd-person-1e3046c6-b44b-43dd-9cb2-937f40e97a6a": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/206d00d3-65a9-4922-82d8-48f09a411863",
            "firstName": "Timothy Aaron",
            "lastName": "Hill",
            "fullName": "Timothy Hill",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/TimothyHill.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-TN-3-lower-ocd-person-206d00d3-65a9-4922-82d8-48f09a411863",
              "builtID": "tn-lower-3",
              "externalID": "ocd-person/206d00d3-65a9-4922-82d8-48f09a411863",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.timothy.hill@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 582, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2050",
              "fax1": null,
              "fax2": "615-253-0298",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H3"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-3-lower-ocd-person-206d00d3-65a9-4922-82d8-48f09a411863": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/ab93d1cd-f6a8-4dd9-a861-071487e805a3",
            "firstName": "John B.",
            "lastName": "Holsclaw",
            "fullName": "John Holsclaw",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h4.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-TN-4-lower-ocd-person-ab93d1cd-f6a8-4dd9-a861-071487e805a3",
              "builtID": "tn-lower-4",
              "externalID": "ocd-person/ab93d1cd-f6a8-4dd9-a861-071487e805a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.john.holsclaw@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 618, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-7450",
              "fax1": null,
              "fax2": "615-253-0310",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h4.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H4"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-4-lower-ocd-person-ab93d1cd-f6a8-4dd9-a861-071487e805a3": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/646c2358-84c6-46bf-aca7-555283f31744",
            "firstName": "David B.",
            "lastName": "Hawk",
            "fullName": "David Hawk",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h5.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-TN-5-lower-ocd-person-646c2358-84c6-46bf-aca7-555283f31744",
              "builtID": "tn-lower-5",
              "externalID": "ocd-person/646c2358-84c6-46bf-aca7-555283f31744",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.david.hawk@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 648, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-7482",
              "fax1": null,
              "fax2": "615-253-0210",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h5.html",
                "https://www.capitol.tn.gov/house/members/h5.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H5"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-5-lower-ocd-person-646c2358-84c6-46bf-aca7-555283f31744": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/d3ad0df9-8812-46b9-8fb3-6433036a1a9f",
            "firstName": "Tim",
            "lastName": "Hicks",
            "fullName": "Tim Hicks",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h6.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-TN-6-lower-ocd-person-d3ad0df9-8812-46b9-8fb3-6433036a1a9f",
              "builtID": "tn-lower-6",
              "externalID": "ocd-person/d3ad0df9-8812-46b9-8fb3-6433036a1a9f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.tim.hicks@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 518, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1717",
              "fax1": null,
              "fax2": "615-253-0301",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h6.html",
                "https://www.capitol.tn.gov/house/members/h6.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H6"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-6-lower-ocd-person-d3ad0df9-8812-46b9-8fb3-6433036a1a9f": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/5110ee0d-fc68-4126-8ff2-7db3814afe66",
            "firstName": "Rebecca Keefauver",
            "lastName": "Alexander",
            "fullName": "Becky Jo Alexander",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.capitol.tn.gov/house/members/images/h7.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-TN-7-lower-ocd-person-5110ee0d-fc68-4126-8ff2-7db3814afe66",
              "builtID": "tn-lower-7",
              "externalID": "ocd-person/5110ee0d-fc68-4126-8ff2-7db3814afe66",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.rebecca.alexander@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 516, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2251",
              "fax1": null,
              "fax2": "615-253-0299",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h7.html",
                "https://www.capitol.tn.gov/house/members/h7.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H7"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-7-lower-ocd-person-5110ee0d-fc68-4126-8ff2-7db3814afe66": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/27331b48-e198-4de9-ac44-e30509c8c26b",
            "firstName": "Jerome",
            "lastName": "Moon",
            "fullName": "Jerome Moon",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h8.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-TN-8-lower-ocd-person-27331b48-e198-4de9-ac44-e30509c8c26b",
              "builtID": "tn-lower-8",
              "externalID": "ocd-person/27331b48-e198-4de9-ac44-e30509c8c26b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jerome.moon@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 530, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-5481",
              "fax1": null,
              "fax2": "615-253-0220",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h8.html",
                "https://www.capitol.tn.gov/house/members/h8.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H8"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-8-lower-ocd-person-27331b48-e198-4de9-ac44-e30509c8c26b": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/2c60cce4-42aa-44e7-85fe-ab30d8e38979",
            "firstName": "Gary W.",
            "lastName": "Hicks",
            "fullName": "Gary Hicks",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h9.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-TN-9-lower-ocd-person-2c60cce4-42aa-44e7-85fe-ab30d8e38979",
              "builtID": "tn-lower-9",
              "externalID": "ocd-person/2c60cce4-42aa-44e7-85fe-ab30d8e38979",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.gary.hicks@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 608, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-7480",
              "fax1": null,
              "fax2": "615-253-0307",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h9.html",
                "https://www.capitol.tn.gov/house/members/h9.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H9"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-9-lower-ocd-person-2c60cce4-42aa-44e7-85fe-ab30d8e38979": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/c79fb980-4ee3-40ce-9737-62e369c61fe0",
            "firstName": "Rick",
            "lastName": "Eldridge",
            "fullName": "Rick Eldridge",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/H10.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-TN-10-lower-ocd-person-c79fb980-4ee3-40ce-9737-62e369c61fe0",
              "builtID": "tn-lower-10",
              "externalID": "ocd-person/c79fb980-4ee3-40ce-9737-62e369c61fe0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.rick.eldridge@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 522, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-6877",
              "fax1": null,
              "fax2": "615-253-0182",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h10.html",
                "https://www.capitol.tn.gov/house/members/h10.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H10"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-10-lower-ocd-person-c79fb980-4ee3-40ce-9737-62e369c61fe0": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/c01a4deb-373d-48a9-b4a2-19125c6e1fe2",
            "firstName": "Jeremy",
            "lastName": "Faison",
            "fullName": "Jeremy Faison",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h11.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-TN-11-lower-ocd-person-c01a4deb-373d-48a9-b4a2-19125c6e1fe2",
              "builtID": "tn-lower-11",
              "externalID": "ocd-person/c01a4deb-373d-48a9-b4a2-19125c6e1fe2",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jeremy.faison@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 604, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-6871",
              "fax1": null,
              "fax2": "615-253-0225",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h11.html",
                "https://www.capitol.tn.gov/house/members/h11.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H11"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-11-lower-ocd-person-c01a4deb-373d-48a9-b4a2-19125c6e1fe2": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/1c93208e-be1e-4a7b-a0d9-ab454da5f011",
            "firstName": "Dale",
            "lastName": "Carr",
            "fullName": "Dale Carr",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h12.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-TN-12-lower-ocd-person-1c93208e-be1e-4a7b-a0d9-ab454da5f011",
              "builtID": "tn-lower-12",
              "externalID": "ocd-person/1c93208e-be1e-4a7b-a0d9-ab454da5f011",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.dale.carr@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 560, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-5981",
              "fax1": null,
              "fax2": "615-253-0303",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h12.html",
                "https://www.capitol.tn.gov/house/members/h12.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H12"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-12-lower-ocd-person-1c93208e-be1e-4a7b-a0d9-ab454da5f011": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/c8be1020-e805-46a9-a8b0-79e206b57540",
            "firstName": "Robert",
            "lastName": "Stevens",
            "fullName": "Robert Stevens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://capitol.tn.gov/house/members/images/H13.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-TN-13-lower-ocd-person-c8be1020-e805-46a9-a8b0-79e206b57540",
              "builtID": "tn-lower-13",
              "externalID": "ocd-person/c8be1020-e805-46a9-a8b0-79e206b57540",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.robert.stevens@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H13"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-13-lower-ocd-person-c8be1020-e805-46a9-a8b0-79e206b57540": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/984fd95f-0554-4b7b-aa3e-b72af6ae1aee",
            "firstName": "Jason",
            "lastName": "Zachary",
            "fullName": "Jason Zachary",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h14.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-TN-14-lower-ocd-person-984fd95f-0554-4b7b-aa3e-b72af6ae1aee",
              "builtID": "tn-lower-14",
              "externalID": "ocd-person/984fd95f-0554-4b7b-aa3e-b72af6ae1aee",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jason.zachary@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 616, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2264",
              "fax1": null,
              "fax2": "615-253-0317",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h14.html",
                "https://www.capitol.tn.gov/house/members/h14.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H14"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-14-lower-ocd-person-984fd95f-0554-4b7b-aa3e-b72af6ae1aee": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/810d9aeb-b666-4acc-b098-deaf265df277",
            "firstName": "Sam",
            "lastName": "McKenzie",
            "fullName": "Sam McKenzie",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.tn.gov/house/members/images/h15.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-TN-15-lower-ocd-person-810d9aeb-b666-4acc-b098-deaf265df277",
              "builtID": "tn-lower-15",
              "externalID": "ocd-person/810d9aeb-b666-4acc-b098-deaf265df277",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.sam.mckenzie@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 512, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-0768",
              "fax1": null,
              "fax2": "615-253-0316",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h15.html",
                "https://www.capitol.tn.gov/house/members/h15.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H15"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-15-lower-ocd-person-810d9aeb-b666-4acc-b098-deaf265df277": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/6e3a4ce2-b37c-4bd0-8def-100aba672a50",
            "firstName": "Michele",
            "lastName": "Carringer",
            "fullName": "Michele Carringer",
            "gender": "Female",
            "party": "Republican",
            "image": "http://nebula.wsimg.com/ec18d700041ee80017970cc4f5b0d8cb?AccessKeyId=C9D09D69010A6C1BCFA5&disposition=0&alloworigin=1",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-TN-16-lower-ocd-person-6e3a4ce2-b37c-4bd0-8def-100aba672a50",
              "builtID": "tn-lower-16",
              "externalID": "ocd-person/6e3a4ce2-b37c-4bd0-8def-100aba672a50",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.michele.carringer@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 510, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1721",
              "fax1": null,
              "fax2": "615-253-0276",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h16.html",
                "https://www.capitol.tn.gov/house/members/h16.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H16"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-16-lower-ocd-person-6e3a4ce2-b37c-4bd0-8def-100aba672a50": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/ad23d4a9-9a62-40a5-94a5-246f7741c3e2",
            "firstName": "Andrew Ellis",
            "lastName": "Farmer",
            "fullName": "Andrew Farmer",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h17.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-TN-17-lower-ocd-person-ad23d4a9-9a62-40a5-94a5-246f7741c3e2",
              "builtID": "tn-lower-17",
              "externalID": "ocd-person/ad23d4a9-9a62-40a5-94a5-246f7741c3e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.andrew.farmer@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 630, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-4419",
              "fax1": null,
              "fax2": "615-253-0203",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h17.html",
                "https://www.capitol.tn.gov/house/members/h17.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H17"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-17-lower-ocd-person-ad23d4a9-9a62-40a5-94a5-246f7741c3e2": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/4c4c679e-fb3d-4ad2-bdfe-f7c077f2d82a",
            "firstName": "Elaine",
            "lastName": "Davis",
            "fullName": "Elaine Davis",
            "gender": "Female",
            "party": "Republican",
            "image": "https://images.squarespace-cdn.com/content/v1/62669899a296e67b1d34963d/13e0ac0c-9c86-423e-baa5-655fb75829bf/ElaineDavis2022-1.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-TN-18-lower-ocd-person-4c4c679e-fb3d-4ad2-bdfe-f7c077f2d82a",
              "builtID": "tn-lower-18",
              "externalID": "ocd-person/4c4c679e-fb3d-4ad2-bdfe-f7c077f2d82a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.elaine.davis@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H18"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-18-lower-ocd-person-4c4c679e-fb3d-4ad2-bdfe-f7c077f2d82a": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/4d4a531e-1122-4155-a4e3-9c7069aaebc7",
            "firstName": "Dave",
            "lastName": "Wright",
            "fullName": "Dave Wright",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/H19.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-TN-19-lower-ocd-person-4d4a531e-1122-4155-a4e3-9c7069aaebc7",
              "builtID": "tn-lower-19",
              "externalID": "ocd-person/4d4a531e-1122-4155-a4e3-9c7069aaebc7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.dave.wright@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 588, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-6879",
              "fax1": null,
              "fax2": "615-253-0212",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h19.html",
                "https://www.capitol.tn.gov/house/members/h19.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H19"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-19-lower-ocd-person-4d4a531e-1122-4155-a4e3-9c7069aaebc7": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/e69ccd6e-f085-4e21-a8a6-c92ecdac9028",
            "firstName": "Bryan",
            "lastName": "Richey",
            "fullName": "Bryan Richey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/BryanRichey.jpeg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-TN-20-lower-ocd-person-e69ccd6e-f085-4e21-a8a6-c92ecdac9028",
              "builtID": "tn-lower-20",
              "externalID": "ocd-person/e69ccd6e-f085-4e21-a8a6-c92ecdac9028",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.bryan.richey@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 424, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-3560",
              "fax1": null,
              "fax2": "615-253-0376",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H20"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-20-lower-ocd-person-e69ccd6e-f085-4e21-a8a6-c92ecdac9028": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/df1bd786-8e1a-4c9d-bd7a-bf8250213dc3",
            "firstName": "Lowell",
            "lastName": "Russell",
            "fullName": "Lowell Russell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.capitol.tn.gov/house/members/images/H21.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-TN-21-lower-ocd-person-df1bd786-8e1a-4c9d-bd7a-bf8250213dc3",
              "builtID": "tn-lower-21",
              "externalID": "ocd-person/df1bd786-8e1a-4c9d-bd7a-bf8250213dc3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.lowell.russell@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 634, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-3736",
              "fax1": null,
              "fax2": "615-253-0312",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h21.html",
                "https://www.capitol.tn.gov/house/members/h21.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H21"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-21-lower-ocd-person-df1bd786-8e1a-4c9d-bd7a-bf8250213dc3": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/311de632-eabb-46da-b547-5fbd73b97769",
            "firstName": "Dan",
            "lastName": "Howell",
            "fullName": "Dan Howell",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h22.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-TN-22-lower-ocd-person-311de632-eabb-46da-b547-5fbd73b97769",
              "builtID": "tn-lower-22",
              "externalID": "ocd-person/311de632-eabb-46da-b547-5fbd73b97769",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.dan.howell@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 556, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-7799",
              "fax1": null,
              "fax2": "615-253-0252",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h22.html",
                "https://www.capitol.tn.gov/house/members/h22.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H22"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-22-lower-ocd-person-311de632-eabb-46da-b547-5fbd73b97769": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/a8461189-9673-4d76-a01d-fa7dddc33a28",
            "firstName": "Steven Mark",
            "lastName": "Cochran",
            "fullName": "Mark Cochran",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.capitol.tn.gov/house/members/images/H23.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-TN-23-lower-ocd-person-a8461189-9673-4d76-a01d-fa7dddc33a28",
              "builtID": "tn-lower-23",
              "externalID": "ocd-person/a8461189-9673-4d76-a01d-fa7dddc33a28",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.mark.cochran@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 642, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1725",
              "fax1": null,
              "fax2": "615-253-0309",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h23.html",
                "https://www.capitol.tn.gov/house/members/h23.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H23"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-23-lower-ocd-person-a8461189-9673-4d76-a01d-fa7dddc33a28": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/96936d32-69d1-4956-a3e7-12833239e2bb",
            "firstName": "Kevin",
            "lastName": "Raper",
            "fullName": "Kevin Raper",
            "gender": "Male",
            "party": "Republican",
            "image": "https://cdn3.creativecirclemedia.com/cleveland/original/20220805-115354-phpTh5sun.jpeg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-TN-24-lower-ocd-person-96936d32-69d1-4956-a3e7-12833239e2bb",
              "builtID": "tn-lower-24",
              "externalID": "ocd-person/96936d32-69d1-4956-a3e7-12833239e2bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kevin.raper@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 508, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1350",
              "fax1": null,
              "fax2": "615-253-0346",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H24"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-24-lower-ocd-person-96936d32-69d1-4956-a3e7-12833239e2bb": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/421c9380-99f4-47ff-9d70-5c5eced05a89",
            "firstName": "Cameron A.",
            "lastName": "Sexton",
            "fullName": "Cameron Sexton",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h25.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-TN-25-lower-ocd-person-421c9380-99f4-47ff-9d70-5c5eced05a89",
              "builtID": "tn-lower-25",
              "externalID": "ocd-person/421c9380-99f4-47ff-9d70-5c5eced05a89",
              "geometry": null
            },
            "contactInfo": {
              "email": "speaker.cameron.sexton@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 600, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2343",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h25.html",
                "https://www.capitol.tn.gov/house/members/h25.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H25"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-25-lower-ocd-person-421c9380-99f4-47ff-9d70-5c5eced05a89": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/3fb15ce3-c606-4e25-bc6f-1c374bb52cf6",
            "firstName": "Greg",
            "lastName": "Martin",
            "fullName": "Greg Martin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.capitol.tn.gov/house/members/images/h26.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-TN-26-lower-ocd-person-3fb15ce3-c606-4e25-bc6f-1c374bb52cf6",
              "builtID": "tn-lower-26",
              "externalID": "ocd-person/3fb15ce3-c606-4e25-bc6f-1c374bb52cf6",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.greg.martin@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 586, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2548",
              "fax1": null,
              "fax2": "615-253-0305",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h26.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H26"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-26-lower-ocd-person-3fb15ce3-c606-4e25-bc6f-1c374bb52cf6": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/dc0002aa-08b6-48b0-84b4-3352065cb7c9",
            "firstName": "Patsy",
            "lastName": "Hazlewood",
            "fullName": "Patsy Hazlewood",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h27.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-TN-27-lower-ocd-person-dc0002aa-08b6-48b0-84b4-3352065cb7c9",
              "builtID": "tn-lower-27",
              "externalID": "ocd-person/dc0002aa-08b6-48b0-84b4-3352065cb7c9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.patsy.hazlewood@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 606, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2746",
              "fax1": null,
              "fax2": "615-253-0304",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h27.html",
                "https://www.capitol.tn.gov/house/members/h27.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H27"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-27-lower-ocd-person-dc0002aa-08b6-48b0-84b4-3352065cb7c9": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/73d17744-fdda-495d-b5a8-c44342fc5b38",
            "firstName": "Yusuf",
            "lastName": "Hakeem",
            "fullName": "Yusuf Hakeem",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://data.openstates.org/images/small/ocd-person/73d17744-fdda-495d-b5a8-c44342fc5b38",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-TN-28-lower-ocd-person-73d17744-fdda-495d-b5a8-c44342fc5b38",
              "builtID": "tn-lower-28",
              "externalID": "ocd-person/73d17744-fdda-495d-b5a8-c44342fc5b38",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.yusuf.hakeem@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 524, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2702",
              "fax1": null,
              "fax2": "615-253-0351",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h28.html",
                "https://www.capitol.tn.gov/house/members/h28.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H28"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-28-lower-ocd-person-73d17744-fdda-495d-b5a8-c44342fc5b38": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/1035ecfa-8b33-472b-8aa9-2c6286c7fb59",
            "firstName": "Greg A.",
            "lastName": "Vital",
            "fullName": "Greg Vital",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.capitol.tn.gov/house/members/images/h29.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-TN-29-lower-ocd-person-1035ecfa-8b33-472b-8aa9-2c6286c7fb59",
              "builtID": "tn-lower-29",
              "externalID": "ocd-person/1035ecfa-8b33-472b-8aa9-2c6286c7fb59",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.greg.vital@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 550, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-3025",
              "fax1": null,
              "fax2": "615-253-0241",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h29.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H29"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-29-lower-ocd-person-1035ecfa-8b33-472b-8aa9-2c6286c7fb59": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/f0cdd239-6095-49bc-9d82-e8ebd7c10254",
            "firstName": "Esther",
            "lastName": "Helton-Haynes",
            "fullName": "Esther Helton-Haynes",
            "gender": "Female",
            "party": "Republican",
            "image": "https://capitol.tn.gov/house/members/images/H30.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-TN-30-lower-ocd-person-f0cdd239-6095-49bc-9d82-e8ebd7c10254",
              "builtID": "tn-lower-30",
              "externalID": "ocd-person/f0cdd239-6095-49bc-9d82-e8ebd7c10254",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.esther.helton@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 502, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1934",
              "fax1": null,
              "fax2": "615-253-0271",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h30.html",
                "https://www.capitol.tn.gov/house/members/h30.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H30"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-30-lower-ocd-person-f0cdd239-6095-49bc-9d82-e8ebd7c10254": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/7429d345-6ad7-421c-936b-3ef9755c6d1f",
            "firstName": "Ron",
            "lastName": "Travis",
            "fullName": "Ron Travis",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h31.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-TN-31-lower-ocd-person-7429d345-6ad7-421c-936b-3ef9755c6d1f",
              "builtID": "tn-lower-31",
              "externalID": "ocd-person/7429d345-6ad7-421c-936b-3ef9755c6d1f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ron.travis@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 534, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1450",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h31.html",
                "https://www.capitol.tn.gov/house/members/h31.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H31"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-31-lower-ocd-person-7429d345-6ad7-421c-936b-3ef9755c6d1f": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/eb738f26-e0b1-4c51-af77-024bfed96183",
            "firstName": "Monty",
            "lastName": "Fritts",
            "fullName": "Monty Fritts",
            "gender": "Male",
            "party": "Republican",
            "image": "https://static.wixstatic.com/media/fd35b0_10146244fb184e609fc7bce8e27b2b82~mv2.png/v1/fill/w_252,h_358,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Monty%20Image%20cropped%20(1)_edited.png",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-TN-32-lower-ocd-person-eb738f26-e0b1-4c51-af77-024bfed96183",
              "builtID": "tn-lower-32",
              "externalID": "ocd-person/eb738f26-e0b1-4c51-af77-024bfed96183",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.monty.fritts@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H32"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-32-lower-ocd-person-eb738f26-e0b1-4c51-af77-024bfed96183": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/bd3237b9-5968-4af2-b036-25a6dc4a304a",
            "firstName": "John",
            "lastName": "Ragan",
            "fullName": "John Ragan",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h33.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-TN-33-lower-ocd-person-bd3237b9-5968-4af2-b036-25a6dc4a304a",
              "builtID": "tn-lower-33",
              "externalID": "ocd-person/bd3237b9-5968-4af2-b036-25a6dc4a304a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.john.ragan@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 614, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-4400",
              "fax1": null,
              "fax2": "615-253-0297",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h33.html",
                "https://www.capitol.tn.gov/house/members/h33.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H33"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-33-lower-ocd-person-bd3237b9-5968-4af2-b036-25a6dc4a304a": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/78c1a569-dcc0-446c-a995-f871964df056",
            "firstName": "Tim",
            "lastName": "Rudd",
            "fullName": "Tim Rudd",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h34.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-TN-34-lower-ocd-person-78c1a569-dcc0-446c-a995-f871964df056",
              "builtID": "tn-lower-34",
              "externalID": "ocd-person/78c1a569-dcc0-446c-a995-f871964df056",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.tim.rudd@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 544, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2804",
              "fax1": null,
              "fax2": "615-253-0322",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h34.html",
                "https://www.capitol.tn.gov/house/members/h34.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H34"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-34-lower-ocd-person-78c1a569-dcc0-446c-a995-f871964df056": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/33aa517f-98cc-4c9a-8bae-5b41542555fa",
            "firstName": "William",
            "lastName": "Slater",
            "fullName": "William Slater",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.newyork1.vip.townnews.com/gallatinnews.com/content/tncms/assets/v3/editorial/e/3b/e3b758f4-03a2-11ed-9c70-97c00c9ae5a8/62d0609f6cb1e.image.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-TN-35-lower-ocd-person-33aa517f-98cc-4c9a-8bae-5b41542555fa",
              "builtID": "tn-lower-35",
              "externalID": "ocd-person/33aa517f-98cc-4c9a-8bae-5b41542555fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.william.slater@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 568, 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2534",
              "fax1": null,
              "fax2": "615-253-0273",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H35"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-35-lower-ocd-person-33aa517f-98cc-4c9a-8bae-5b41542555fa": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/67643e56-9e53-437b-999d-987f8b0692c1",
            "firstName": "Dennis",
            "lastName": "Powers",
            "fullName": "Dennis Powers",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h36.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-TN-36-lower-ocd-person-67643e56-9e53-437b-999d-987f8b0692c1",
              "builtID": "tn-lower-36",
              "externalID": "ocd-person/67643e56-9e53-437b-999d-987f8b0692c1",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.dennis.powers@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 674, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-3335",
              "fax1": null,
              "fax2": "615-253-0296",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h36.html",
                "https://www.capitol.tn.gov/house/members/h36.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H36"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-36-lower-ocd-person-67643e56-9e53-437b-999d-987f8b0692c1": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/7c874269-afdd-4d41-a9da-b0ddb903bb6f",
            "firstName": "Charlie L.",
            "lastName": "Baum",
            "fullName": "Charlie Baum",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/H37.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-TN-37-lower-ocd-person-7c874269-afdd-4d41-a9da-b0ddb903bb6f",
              "builtID": "tn-lower-37",
              "externalID": "ocd-person/7c874269-afdd-4d41-a9da-b0ddb903bb6f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.charlie.baum@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 620, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-6849",
              "fax1": null,
              "fax2": "615-253-0264",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h37.html",
                "https://www.capitol.tn.gov/house/members/h37.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H37"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-37-lower-ocd-person-7c874269-afdd-4d41-a9da-b0ddb903bb6f": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/51baddf9-a3d9-47c6-81e5-00b6608c6767",
            "firstName": "Kelly T.",
            "lastName": "Keisling",
            "fullName": "Kelly Keisling",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h38.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-TN-38-lower-ocd-person-51baddf9-a3d9-47c6-81e5-00b6608c6767",
              "builtID": "tn-lower-38",
              "externalID": "ocd-person/51baddf9-a3d9-47c6-81e5-00b6608c6767",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kelly.keisling@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 566, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-6852",
              "fax1": null,
              "fax2": "615-253-0234",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h38.html",
                "https://www.capitol.tn.gov/house/members/h38.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H38"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-38-lower-ocd-person-51baddf9-a3d9-47c6-81e5-00b6608c6767": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/96432d4d-ecdf-4fa0-8a20-e8a5636fb7dd",
            "firstName": "Iris",
            "lastName": "Rudder",
            "fullName": "Iris Rudder",
            "gender": "Female",
            "party": "Republican",
            "image": "https://data.openstates.org/images/small/ocd-person/96432d4d-ecdf-4fa0-8a20-e8a5636fb7dd",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-TN-39-lower-ocd-person-96432d4d-ecdf-4fa0-8a20-e8a5636fb7dd",
              "builtID": "tn-lower-39",
              "externalID": "ocd-person/96432d4d-ecdf-4fa0-8a20-e8a5636fb7dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.iris.rudder@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 576, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-8695",
              "fax1": null,
              "fax2": "615-253-0314",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h39.html",
                "https://www.capitol.tn.gov/house/members/h39.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H39"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-39-lower-ocd-person-96432d4d-ecdf-4fa0-8a20-e8a5636fb7dd": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/7bad87b9-12ec-4e74-a3ec-945bc78c5f5f",
            "firstName": "Michael",
            "lastName": "Hale",
            "fullName": "Michael Hale",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Michael_Hale_20230524_083209.jpeg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-TN-40-lower-ocd-person-7bad87b9-12ec-4e74-a3ec-945bc78c5f5f",
              "builtID": "tn-lower-40",
              "externalID": "ocd-person/7bad87b9-12ec-4e74-a3ec-945bc78c5f5f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.michael.hale@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 672, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2192",
              "fax1": null,
              "fax2": "615-253-0378",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H40"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-40-lower-ocd-person-7bad87b9-12ec-4e74-a3ec-945bc78c5f5f": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/a94ac42a-6b1e-4ecf-acd4-32c2e463e059",
            "firstName": "Ed",
            "lastName": "Butler",
            "fullName": "Ed Butler",
            "gender": "Male",
            "party": "Republican",
            "image": "http://edbutlerfortn.com/wp-content/uploads/2018/04/Ed-Butler.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-TN-41-lower-ocd-person-a94ac42a-6b1e-4ecf-acd4-32c2e463e059",
              "builtID": "tn-lower-41",
              "externalID": "ocd-person/a94ac42a-6b1e-4ecf-acd4-32c2e463e059",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ed.butler@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H41"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-41-lower-ocd-person-a94ac42a-6b1e-4ecf-acd4-32c2e463e059": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/df3f9935-b7e2-4f76-a461-115487c57721",
            "firstName": "Ryan",
            "lastName": "Williams",
            "fullName": "Ryan Williams",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h42.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-TN-42-lower-ocd-person-df3f9935-b7e2-4f76-a461-115487c57721",
              "builtID": "tn-lower-42",
              "externalID": "ocd-person/df3f9935-b7e2-4f76-a461-115487c57721",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ryan.williams@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 644, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1875",
              "fax1": null,
              "fax2": "615-253-0160",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h42.html",
                "https://www.capitol.tn.gov/house/members/h42.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H42"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-42-lower-ocd-person-df3f9935-b7e2-4f76-a461-115487c57721": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/3c4af105-7b55-4c00-af88-af574ea05493",
            "firstName": "Paul",
            "lastName": "Sherrell",
            "fullName": "Paul Sherrell",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h43.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-TN-43-lower-ocd-person-3c4af105-7b55-4c00-af88-af574ea05493",
              "builtID": "tn-lower-43",
              "externalID": "ocd-person/3c4af105-7b55-4c00-af88-af574ea05493",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.paul.sherrell@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 670, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1963",
              "fax1": null,
              "fax2": "615-253-0207",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h43.html",
                "https://www.capitol.tn.gov/house/members/h43.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H43"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-43-lower-ocd-person-3c4af105-7b55-4c00-af88-af574ea05493": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/5026d19f-d4ec-47b2-b106-f318a45e8585",
            "firstName": "William G.",
            "lastName": "Lamberth",
            "fullName": "William Lamberth",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h44.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-TN-44-lower-ocd-person-5026d19f-d4ec-47b2-b106-f318a45e8585",
              "builtID": "tn-lower-44",
              "externalID": "ocd-person/5026d19f-d4ec-47b2-b106-f318a45e8585",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.william.lamberth@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 602, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1980",
              "fax1": null,
              "fax2": "615-253-0336",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h44.html",
                "https://www.capitol.tn.gov/house/members/h44.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H44"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-44-lower-ocd-person-5026d19f-d4ec-47b2-b106-f318a45e8585": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/8aeb3cc6-b0ca-4af6-ae9d-06aca0c6ae14",
            "firstName": "Johnny",
            "lastName": "Garrett",
            "fullName": "Johnny Garrett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.newyork1.vip.townnews.com/hendersonvillestandard.com/content/tncms/assets/v3/editorial/f/2d/f2d14e14-85d9-11eb-876a-73ea1292cfff/604fd9822f33d.image.jpg?resize=1200%2C1569",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-TN-45-lower-ocd-person-8aeb3cc6-b0ca-4af6-ae9d-06aca0c6ae14",
              "builtID": "tn-lower-45",
              "externalID": "ocd-person/8aeb3cc6-b0ca-4af6-ae9d-06aca0c6ae14",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.johnny.garrett@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 636, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-3893",
              "fax1": null,
              "fax2": "615-253-0350",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h45.html",
                "https://www.capitol.tn.gov/house/members/h45.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H45"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-45-lower-ocd-person-8aeb3cc6-b0ca-4af6-ae9d-06aca0c6ae14": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/7fa67dd6-999c-4116-a136-32633710f6b0",
            "firstName": "Clark",
            "lastName": "Boyd",
            "fullName": "Clark Boyd",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h46.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-TN-46-lower-ocd-person-7fa67dd6-999c-4116-a136-32633710f6b0",
              "builtID": "tn-lower-46",
              "externalID": "ocd-person/7fa67dd6-999c-4116-a136-32633710f6b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.clark.boyd@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 528, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-7086",
              "fax1": null,
              "fax2": "615-253-0206",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h46.html",
                "https://www.capitol.tn.gov/house/members/h46.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H46"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-46-lower-ocd-person-7fa67dd6-999c-4116-a136-32633710f6b0": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/271c14e0-433b-42ce-91c5-c01ad5ccfc41",
            "firstName": "Rush",
            "lastName": "Bricken",
            "fullName": "Rush Bricken",
            "gender": "Male",
            "party": "Republican",
            "image": "https://data.openstates.org/images/small/ocd-person/271c14e0-433b-42ce-91c5-c01ad5ccfc41",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-TN-47-lower-ocd-person-271c14e0-433b-42ce-91c5-c01ad5ccfc41",
              "builtID": "tn-lower-47",
              "externalID": "ocd-person/271c14e0-433b-42ce-91c5-c01ad5ccfc41",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.rush.bricken@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 594, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-7448",
              "fax1": null,
              "fax2": "615-253-0226",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h47.html",
                "https://www.capitol.tn.gov/house/members/h47.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H47"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-47-lower-ocd-person-271c14e0-433b-42ce-91c5-c01ad5ccfc41": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/a9676141-e809-4554-9d0c-283916c36f63",
            "firstName": "Bryan",
            "lastName": "Terry",
            "fullName": "Bryan Terry",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h48.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-TN-48-lower-ocd-person-a9676141-e809-4554-9d0c-283916c36f63",
              "builtID": "tn-lower-48",
              "externalID": "ocd-person/a9676141-e809-4554-9d0c-283916c36f63",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.bryan.terry@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 646, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2180",
              "fax1": null,
              "fax2": "615-253-0372",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h48.html",
                "https://www.capitol.tn.gov/house/members/h48.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H48"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-48-lower-ocd-person-a9676141-e809-4554-9d0c-283916c36f63": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/092dd5d6-fb25-494c-ad16-78f0034df92f",
            "firstName": "Michael J.",
            "lastName": "Sparks",
            "fullName": "Mike Sparks",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h49.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-TN-49-lower-ocd-person-092dd5d6-fb25-494c-ad16-78f0034df92f",
              "builtID": "tn-lower-49",
              "externalID": "ocd-person/092dd5d6-fb25-494c-ad16-78f0034df92f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.mike.sparks@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 532, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-6829",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h49.html",
                "https://www.capitol.tn.gov/house/members/h49.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H49"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-49-lower-ocd-person-092dd5d6-fb25-494c-ad16-78f0034df92f": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/e0272220-99f1-4cfb-9d29-946b86279e0b",
            "firstName": "James R.",
            "lastName": "Mitchell",
            "fullName": "Bo Mitchell",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.tn.gov/house/members/images/h50.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-TN-50-lower-ocd-person-e0272220-99f1-4cfb-9d29-946b86279e0b",
              "builtID": "tn-lower-50",
              "externalID": "ocd-person/e0272220-99f1-4cfb-9d29-946b86279e0b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.bo.mitchell@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 400, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-4317",
              "fax1": null,
              "fax2": "615-741-0360",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h50.html",
                "https://www.capitol.tn.gov/house/members/h50.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H50"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-50-lower-ocd-person-e0272220-99f1-4cfb-9d29-946b86279e0b": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/6dc4d8e8-aa2c-423b-8f5c-8ed4666a27db",
            "firstName": "Aftyn Alyssa",
            "lastName": "Behn",
            "fullName": "Aftyn Behn",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Aftyn_Behn.png",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-TN-51-lower-ocd-person-6dc4d8e8-aa2c-423b-8f5c-8ed4666a27db",
              "builtID": "tn-lower-51",
              "externalID": "ocd-person/6dc4d8e8-aa2c-423b-8f5c-8ed4666a27db",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.aftyn.behn@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 574, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-3229",
              "fax1": null,
              "fax2": "615-253-0233",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H51",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=h51"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-51-lower-ocd-person-6dc4d8e8-aa2c-423b-8f5c-8ed4666a27db": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/7d04ddd1-fbe5-452e-a649-1ca3aac0e0a3",
            "firstName": "Justin Shea",
            "lastName": "Bautista-Jones",
            "fullName": "Justin Jones",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/JustinJones_TN_HD52.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-TN-52-lower-ocd-person-7d04ddd1-fbe5-452e-a649-1ca3aac0e0a3",
              "builtID": "tn-lower-52",
              "externalID": "ocd-person/7d04ddd1-fbe5-452e-a649-1ca3aac0e0a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.justin.jones@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 422, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2184",
              "fax1": null,
              "fax2": "615-253-0181",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H52"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-52-lower-ocd-person-7d04ddd1-fbe5-452e-a649-1ca3aac0e0a3": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/7a2b1f85-6a85-41af-8b81-44d585e0041a",
            "firstName": "Jason",
            "lastName": "Powell",
            "fullName": "Jason Powell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.tennessean.com/gcdn/-mm-/6f03ca527b37558797b9ff3612df6f1ca4cc9386/c=0-42-1946-2637/local/-/media/Nashville/2015/02/11/B9316214695Z.1_20150211202701_000_GF79UD55G.1-0.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-TN-53-lower-ocd-person-7a2b1f85-6a85-41af-8b81-44d585e0041a",
              "builtID": "tn-lower-53",
              "externalID": "ocd-person/7a2b1f85-6a85-41af-8b81-44d585e0041a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jason.powell@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 418, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-6861",
              "fax1": null,
              "fax2": "615-741-0325",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h53.html",
                "https://www.capitol.tn.gov/house/members/h53.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H53"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-53-lower-ocd-person-7a2b1f85-6a85-41af-8b81-44d585e0041a": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/ee804d8a-2f07-4271-83a7-0179f4c3445c",
            "firstName": "Vincent B.",
            "lastName": "Dixie",
            "fullName": "Vincent Dixie",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.capitol.tn.gov/house/members/images/H54.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-TN-54-lower-ocd-person-ee804d8a-2f07-4271-83a7-0179f4c3445c",
              "builtID": "tn-lower-54",
              "externalID": "ocd-person/ee804d8a-2f07-4271-83a7-0179f4c3445c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.vincent.dixie@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 408, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1997",
              "fax1": null,
              "fax2": "615-253-0361",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h54.html",
                "https://www.capitol.tn.gov/house/members/h54.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H54"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-54-lower-ocd-person-ee804d8a-2f07-4271-83a7-0179f4c3445c": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/87464fca-8690-4e1e-a7aa-499008a9c0b1",
            "firstName": "John Ray",
            "lastName": "Clemmons",
            "fullName": "John Clemmons",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.tn.gov/house/members/images/h55.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-TN-55-lower-ocd-person-87464fca-8690-4e1e-a7aa-499008a9c0b1",
              "builtID": "tn-lower-55",
              "externalID": "ocd-person/87464fca-8690-4e1e-a7aa-499008a9c0b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.john.ray.clemmons@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h55.html",
                "https://www.capitol.tn.gov/house/members/h55.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H55"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-55-lower-ocd-person-87464fca-8690-4e1e-a7aa-499008a9c0b1": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/c8a26be8-8de1-434b-864a-21697f8f15d4",
            "firstName": "Bob",
            "lastName": "Freeman",
            "fullName": "Bob Freeman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.capitol.tn.gov/house/members/images/H56.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-TN-56-lower-ocd-person-c8a26be8-8de1-434b-864a-21697f8f15d4",
              "builtID": "tn-lower-56",
              "externalID": "ocd-person/c8a26be8-8de1-434b-864a-21697f8f15d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.bob.freeman@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 592, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-0709",
              "fax1": null,
              "fax2": "615-253-0162",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h56.html",
                "https://www.capitol.tn.gov/house/members/h56.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H56"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-56-lower-ocd-person-c8a26be8-8de1-434b-864a-21697f8f15d4": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/b593203d-3346-4a33-8a97-94cc9614d5c5",
            "firstName": "Susan M.",
            "lastName": "Lynn",
            "fullName": "Susan Lynn",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h57.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-TN-57-lower-ocd-person-b593203d-3346-4a33-8a97-94cc9614d5c5",
              "builtID": "tn-lower-57",
              "externalID": "ocd-person/b593203d-3346-4a33-8a97-94cc9614d5c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.susan.lynn@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 426, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-7462",
              "fax1": null,
              "fax2": "615-741-0353",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h57.html",
                "https://www.capitol.tn.gov/house/members/h57.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H57"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-57-lower-ocd-person-b593203d-3346-4a33-8a97-94cc9614d5c5": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/2b7b0735-2adf-4d15-8dab-e32cf871d1d9",
            "firstName": "Harold M.",
            "lastName": "Love",
            "fullName": "Harold Love",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.tn.gov/house/members/images/h58.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-TN-58-lower-ocd-person-2b7b0735-2adf-4d15-8dab-e32cf871d1d9",
              "builtID": "tn-lower-58",
              "externalID": "ocd-person/2b7b0735-2adf-4d15-8dab-e32cf871d1d9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.harold.love@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 668, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-3831",
              "fax1": null,
              "fax2": "615-253-0323",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h58.html",
                "https://www.capitol.tn.gov/house/members/h58.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H58"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-58-lower-ocd-person-2b7b0735-2adf-4d15-8dab-e32cf871d1d9": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/625050ba-cb23-4c09-b62b-818e544d987d",
            "firstName": "Caleb",
            "lastName": "Hemmer",
            "fullName": "Caleb Hemmer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/hemmercaleb.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-TN-59-lower-ocd-person-625050ba-cb23-4c09-b62b-818e544d987d",
              "builtID": "tn-lower-59",
              "externalID": "ocd-person/625050ba-cb23-4c09-b62b-818e544d987d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.caleb.hemmer@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 412, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2035",
              "fax1": null,
              "fax2": "615-253-0290",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H59"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-59-lower-ocd-person-625050ba-cb23-4c09-b62b-818e544d987d": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/64763c0c-7ac4-4a76-8402-95cab163d713",
            "firstName": "Darren",
            "lastName": "Jernigan",
            "fullName": "Darren Jernigan",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.tn.gov/house/members/images/h60.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-TN-60-lower-ocd-person-64763c0c-7ac4-4a76-8402-95cab163d713",
              "builtID": "tn-lower-60",
              "externalID": "ocd-person/64763c0c-7ac4-4a76-8402-95cab163d713",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.darren.jernigan@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 428, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-6959",
              "fax1": null,
              "fax2": "615-253-0331",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h60.html",
                "https://www.capitol.tn.gov/house/members/h60.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H60"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-60-lower-ocd-person-64763c0c-7ac4-4a76-8402-95cab163d713": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/7e3d4405-71b3-44f8-8e51-9d329ce10838",
            "firstName": "Eugene N.",
            "lastName": "Bulso",
            "fullName": "Gino Bulso",
            "gender": "Male",
            "party": "Republican",
            "image": "https://votegino.com/wp-content/uploads/2022/04/gino.png",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-TN-61-lower-ocd-person-7e3d4405-71b3-44f8-8e51-9d329ce10838",
              "builtID": "tn-lower-61",
              "externalID": "ocd-person/7e3d4405-71b3-44f8-8e51-9d329ce10838",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.gino.bulso@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H61"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-61-lower-ocd-person-7e3d4405-71b3-44f8-8e51-9d329ce10838": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/2670b6b7-f648-454d-a70e-bd84792d304d",
            "firstName": "Pat",
            "lastName": "Marsh",
            "fullName": "Pat Marsh",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h62.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-TN-62-lower-ocd-person-2670b6b7-f648-454d-a70e-bd84792d304d",
              "builtID": "tn-lower-62",
              "externalID": "ocd-person/2670b6b7-f648-454d-a70e-bd84792d304d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.pat.marsh@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 610, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-6824",
              "fax1": null,
              "fax2": "615-253-0344",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h62.html",
                "https://www.capitol.tn.gov/house/members/h62.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H62"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-62-lower-ocd-person-2670b6b7-f648-454d-a70e-bd84792d304d": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/f954892c-82e4-48ff-ae0c-c7e10c251335",
            "firstName": "Jake",
            "lastName": "McCalmon",
            "fullName": "Jake McCalmon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://images.squarespace-cdn.com/content/v1/58f80179be65944c6f5eeb8a/94dc5fac-95f7-4b57-8c52-6bffa35d2a20/JakeMcCalmon-3.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-TN-63-lower-ocd-person-f954892c-82e4-48ff-ae0c-c7e10c251335",
              "builtID": "tn-lower-63",
              "externalID": "ocd-person/f954892c-82e4-48ff-ae0c-c7e10c251335",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jake.mccalmon@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 564, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-4389",
              "fax1": null,
              "fax2": "615-253-0229",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H63"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-63-lower-ocd-person-f954892c-82e4-48ff-ae0c-c7e10c251335": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/da306a9e-f227-4cf4-bfd8-c7d6cb4c7eac",
            "firstName": "Scott",
            "lastName": "Cepicky",
            "fullName": "Scott Cepicky",
            "gender": "Male",
            "party": "Republican",
            "image": "https://capitol.tn.gov/house/members/images/H64.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-TN-64-lower-ocd-person-da306a9e-f227-4cf4-bfd8-c7d6cb4c7eac",
              "builtID": "tn-lower-64",
              "externalID": "ocd-person/da306a9e-f227-4cf4-bfd8-c7d6cb4c7eac",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.scott.cepicky@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 678, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-3005",
              "fax1": null,
              "fax2": "615-253-0365",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h64.html",
                "https://www.capitol.tn.gov/house/members/h64.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H64"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-64-lower-ocd-person-da306a9e-f227-4cf4-bfd8-c7d6cb4c7eac": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/3ec7aed7-4927-419a-b9e3-5bb337fd4a6c",
            "firstName": "Sam",
            "lastName": "Whitson",
            "fullName": "Sam Whitson",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h65.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-TN-65-lower-ocd-person-3ec7aed7-4927-419a-b9e3-5bb337fd4a6c",
              "builtID": "tn-lower-65",
              "externalID": "ocd-person/3ec7aed7-4927-419a-b9e3-5bb337fd4a6c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.sam.whitson@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 552, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1864",
              "fax1": null,
              "fax2": "615-253-0228",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h65.html",
                "https://www.capitol.tn.gov/house/members/h65.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H65"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-65-lower-ocd-person-3ec7aed7-4927-419a-b9e3-5bb337fd4a6c": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/57b51b23-1a38-47dd-bd4f-49954df01971",
            "firstName": "Sabi",
            "lastName": "Kumar",
            "fullName": "Doc Kumar",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h66.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-TN-66-lower-ocd-person-57b51b23-1a38-47dd-bd4f-49954df01971",
              "builtID": "tn-lower-66",
              "externalID": "ocd-person/57b51b23-1a38-47dd-bd4f-49954df01971",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.sabi.kumar@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 654, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2860",
              "fax1": null,
              "fax2": "615-253-0283",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h66.html",
                "https://www.capitol.tn.gov/house/members/h66.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H66"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-66-lower-ocd-person-57b51b23-1a38-47dd-bd4f-49954df01971": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/12e6200c-0859-4582-9f55-31dd55eb3b0e",
            "firstName": "Ronnie",
            "lastName": "Glynn",
            "fullName": "Ronnie Glynn",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/1Aug232020602PM_80182230_BLUE-min.png",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-TN-67-lower-ocd-person-12e6200c-0859-4582-9f55-31dd55eb3b0e",
              "builtID": "tn-lower-67",
              "externalID": "ocd-person/12e6200c-0859-4582-9f55-31dd55eb3b0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ronnie.glynn@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H67"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-67-lower-ocd-person-12e6200c-0859-4582-9f55-31dd55eb3b0e": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/b8b3f196-58d2-48ea-806c-f357251a5cb4",
            "firstName": "Curtis G.",
            "lastName": "Johnson",
            "fullName": "Curtis Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h68.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-TN-68-lower-ocd-person-b8b3f196-58d2-48ea-806c-f357251a5cb4",
              "builtID": "tn-lower-68",
              "externalID": "ocd-person/b8b3f196-58d2-48ea-806c-f357251a5cb4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.curtis.johnson@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 612, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-4341",
              "fax1": null,
              "fax2": "615-253-0269",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h68.html",
                "https://www.capitol.tn.gov/house/members/h68.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H68"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-68-lower-ocd-person-b8b3f196-58d2-48ea-806c-f357251a5cb4": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/2689e641-85b8-4753-bd0f-28e2cdfc1568",
            "firstName": "Jody",
            "lastName": "Barrett",
            "fullName": "Jody Barrett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Jody-Barrett.PNG",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-TN-69-lower-ocd-person-2689e641-85b8-4753-bd0f-28e2cdfc1568",
              "builtID": "tn-lower-69",
              "externalID": "ocd-person/2689e641-85b8-4753-bd0f-28e2cdfc1568",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jody.barrett@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H69"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-69-lower-ocd-person-2689e641-85b8-4753-bd0f-28e2cdfc1568": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/bdfd00ac-13a8-4208-b687-e46e980d79de",
            "firstName": "Clay",
            "lastName": "Doggett",
            "fullName": "Clay Doggett",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/H70.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-TN-70-lower-ocd-person-bdfd00ac-13a8-4208-b687-e46e980d79de",
              "builtID": "tn-lower-70",
              "externalID": "ocd-person/bdfd00ac-13a8-4208-b687-e46e980d79de",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.clay.doggett@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 520, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-7476",
              "fax1": null,
              "fax2": "615-253-0258",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h70.html",
                "https://www.capitol.tn.gov/house/members/h70.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H70"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-70-lower-ocd-person-bdfd00ac-13a8-4208-b687-e46e980d79de": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/1ea53db6-5fee-4603-9f8e-fd63694b45fa",
            "firstName": "Kip",
            "lastName": "Capley",
            "fullName": "Kip Capley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://votecapley.com/wp-content/uploads/2022/01/capley2-4.png",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-TN-71-lower-ocd-person-1ea53db6-5fee-4603-9f8e-fd63694b45fa",
              "builtID": "tn-lower-71",
              "externalID": "ocd-person/1ea53db6-5fee-4603-9f8e-fd63694b45fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kip.capley@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H71"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-71-lower-ocd-person-1ea53db6-5fee-4603-9f8e-fd63694b45fa": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/65b1d39e-414c-46c2-9aca-8cea5b6cba8b",
            "firstName": "Kirk",
            "lastName": "Haston",
            "fullName": "Kirk Haston",
            "gender": "Male",
            "party": "Republican",
            "image": "https://capitol.tn.gov/house/members/images/H72.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-TN-72-lower-ocd-person-65b1d39e-414c-46c2-9aca-8cea5b6cba8b",
              "builtID": "tn-lower-72",
              "externalID": "ocd-person/65b1d39e-414c-46c2-9aca-8cea5b6cba8b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kirk.haston@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 554, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-0750",
              "fax1": null,
              "fax2": "615-253-0213",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h72.html",
                "https://www.capitol.tn.gov/house/members/h72.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H72"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-72-lower-ocd-person-65b1d39e-414c-46c2-9aca-8cea5b6cba8b": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/5854880e-f10d-4c24-9593-4ae567fec8d7",
            "firstName": "Chris",
            "lastName": "Todd",
            "fullName": "Chris Todd",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.capitol.tn.gov/house/members/images/H73.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-TN-73-lower-ocd-person-5854880e-f10d-4c24-9593-4ae567fec8d7",
              "builtID": "tn-lower-73",
              "externalID": "ocd-person/5854880e-f10d-4c24-9593-4ae567fec8d7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.chris.todd@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 526, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-7475",
              "fax1": null,
              "fax2": "615-253-0373",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h73.html",
                "https://www.capitol.tn.gov/house/members/h73.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H73"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-73-lower-ocd-person-5854880e-f10d-4c24-9593-4ae567fec8d7": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/380a255b-fef0-4cd9-9f33-3d5b5d163c68",
            "firstName": "Jay Dean",
            "lastName": "Reedy",
            "fullName": "Jay Reedy",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h74.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-TN-74-lower-ocd-person-380a255b-fef0-4cd9-9f33-3d5b5d163c68",
              "builtID": "tn-lower-74",
              "externalID": "ocd-person/380a255b-fef0-4cd9-9f33-3d5b5d163c68",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jay.reedy@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 402, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-7098",
              "fax1": null,
              "fax2": "615-253-0315",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h74.html",
                "https://www.capitol.tn.gov/house/members/h74.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H74"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-74-lower-ocd-person-380a255b-fef0-4cd9-9f33-3d5b5d163c68": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/13511e3d-bcac-41dd-bed5-a922de506e77",
            "firstName": "Jeff",
            "lastName": "Burkhart",
            "fullName": "Jeff Burkhart",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Jeff_Burkhart_Photo-min.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-TN-75-lower-ocd-person-13511e3d-bcac-41dd-bed5-a922de506e77",
              "builtID": "tn-lower-75",
              "externalID": "ocd-person/13511e3d-bcac-41dd-bed5-a922de506e77",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jeff.burkhart@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H75"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-75-lower-ocd-person-13511e3d-bcac-41dd-bed5-a922de506e77": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/5cec9957-0b24-47d0-94b6-6f41e8f259bc",
            "firstName": "Tandy",
            "lastName": "Darby",
            "fullName": "Tandy Darby",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h76.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-TN-76-lower-ocd-person-5cec9957-0b24-47d0-94b6-6f41e8f259bc",
              "builtID": "tn-lower-76",
              "externalID": "ocd-person/5cec9957-0b24-47d0-94b6-6f41e8f259bc",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.tandy.darby@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 676, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-7847",
              "fax1": null,
              "fax2": "615-253-0293",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h76.html",
                "https://www.capitol.tn.gov/house/members/h76.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H76"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-76-lower-ocd-person-5cec9957-0b24-47d0-94b6-6f41e8f259bc": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/d00c0511-6872-4f2a-9d48-cf8d2a60e199",
            "firstName": "Rusty",
            "lastName": "Grills",
            "fullName": "Rusty Grills",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h77.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-TN-77-lower-ocd-person-d00c0511-6872-4f2a-9d48-cf8d2a60e199",
              "builtID": "tn-lower-77",
              "externalID": "ocd-person/d00c0511-6872-4f2a-9d48-cf8d2a60e199",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.rusty.grills@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 650, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-0718",
              "fax1": null,
              "fax2": "615-253-0214",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h77.html",
                "https://www.capitol.tn.gov/house/members/h77.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H77"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-77-lower-ocd-person-d00c0511-6872-4f2a-9d48-cf8d2a60e199": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/ec8815da-568a-436a-88ed-1e9da240fe80",
            "firstName": "Mary J.",
            "lastName": "Littleton",
            "fullName": "Mary Littleton",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h78.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-TN-78-lower-ocd-person-ec8815da-568a-436a-88ed-1e9da240fe80",
              "builtID": "tn-lower-78",
              "externalID": "ocd-person/ec8815da-568a-436a-88ed-1e9da240fe80",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.mary.littleton@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 632, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-7477",
              "fax1": null,
              "fax2": "615-253-0279",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h78.html",
                "https://www.capitol.tn.gov/house/members/h78.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H78"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-78-lower-ocd-person-ec8815da-568a-436a-88ed-1e9da240fe80": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/de8e2d6f-efd8-45f5-b261-5963db78b909",
            "firstName": "Brock",
            "lastName": "Martin",
            "fullName": "Brock Martin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://static.wixstatic.com/media/e786a5_3d8b40c28edc4423ad7371c244de049c~mv2.jpg/v1/crop/x_2630,y_0,w_4189,h_5464/fill/w_460,h_600,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/023A1186.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-TN-79-lower-ocd-person-de8e2d6f-efd8-45f5-b261-5963db78b909",
              "builtID": "tn-lower-79",
              "externalID": "ocd-person/de8e2d6f-efd8-45f5-b261-5963db78b909",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.brock.martin@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 652, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-7478",
              "fax1": null,
              "fax2": "615-253-0218",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H79"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-79-lower-ocd-person-de8e2d6f-efd8-45f5-b261-5963db78b909": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/36e97a50-c2e3-42a4-8f2d-97b4f176ddb1",
            "firstName": "Johnny",
            "lastName": "Shaw",
            "fullName": "Johnny Shaw",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.tn.gov/house/members/images/h80.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-TN-80-lower-ocd-person-36e97a50-c2e3-42a4-8f2d-97b4f176ddb1",
              "builtID": "tn-lower-80",
              "externalID": "ocd-person/36e97a50-c2e3-42a4-8f2d-97b4f176ddb1",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.johnny.shaw@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 570, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-4538",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h80.html",
                "https://www.capitol.tn.gov/house/members/h80.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H80"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-80-lower-ocd-person-36e97a50-c2e3-42a4-8f2d-97b4f176ddb1": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/d07683be-bda8-4af9-be0b-9bf3c1e441e7",
            "firstName": "Debra",
            "lastName": "Moody",
            "fullName": "Debra Moody",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h81.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-TN-81-lower-ocd-person-d07683be-bda8-4af9-be0b-9bf3c1e441e7",
              "builtID": "tn-lower-81",
              "externalID": "ocd-person/d07683be-bda8-4af9-be0b-9bf3c1e441e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.debra.moody@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 680, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-3774",
              "fax1": null,
              "fax2": "615-253-0263",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h81.html",
                "https://www.capitol.tn.gov/house/members/h81.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H81"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-81-lower-ocd-person-d07683be-bda8-4af9-be0b-9bf3c1e441e7": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/35764262-91cb-4a03-a2ee-5c87a0f5026e",
            "firstName": "Christopher Tabor",
            "lastName": "Hurt",
            "fullName": "Chris Hurt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://capitol.tn.gov/house/members/images/H82.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-TN-82-lower-ocd-person-35764262-91cb-4a03-a2ee-5c87a0f5026e",
              "builtID": "tn-lower-82",
              "externalID": "ocd-person/35764262-91cb-4a03-a2ee-5c87a0f5026e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.chris.hurt@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 500, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2134",
              "fax1": null,
              "fax2": "615-741-1446",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h82.html",
                "https://www.capitol.tn.gov/house/members/h82.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H82"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-82-lower-ocd-person-35764262-91cb-4a03-a2ee-5c87a0f5026e": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/8765a8d9-17ac-4a88-90e3-ef1f7d1b4e61",
            "firstName": "Hoyt",
            "lastName": "White",
            "fullName": "Mark White",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h83.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-TN-83-lower-ocd-person-8765a8d9-17ac-4a88-90e3-ef1f7d1b4e61",
              "builtID": "tn-lower-83",
              "externalID": "ocd-person/8765a8d9-17ac-4a88-90e3-ef1f7d1b4e61",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.mark.white@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 624, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-4415",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h83.html",
                "https://www.capitol.tn.gov/house/members/h83.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H83"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-83-lower-ocd-person-8765a8d9-17ac-4a88-90e3-ef1f7d1b4e61": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/7a3177a1-118c-48ce-bbb8-17ebb1240e00",
            "firstName": "Joe",
            "lastName": "Towns",
            "fullName": "Joe Towns",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.tn.gov/house/members/images/h84.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-TN-84-lower-ocd-person-7a3177a1-118c-48ce-bbb8-17ebb1240e00",
              "builtID": "tn-lower-84",
              "externalID": "ocd-person/7a3177a1-118c-48ce-bbb8-17ebb1240e00",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.joe.towns@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 416, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2189",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h84.html",
                "https://www.capitol.tn.gov/house/members/h84.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H84"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-84-lower-ocd-person-7a3177a1-118c-48ce-bbb8-17ebb1240e00": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/108691a2-9f24-41c5-b4c8-606c7cdf036b",
            "firstName": "Jesse",
            "lastName": "Chism",
            "fullName": "Jesse Chism",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.capitol.tn.gov/house/members/images/H85.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-TN-85-lower-ocd-person-108691a2-9f24-41c5-b4c8-606c7cdf036b",
              "builtID": "tn-lower-85",
              "externalID": "ocd-person/108691a2-9f24-41c5-b4c8-606c7cdf036b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jesse.chism@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 504, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-6954",
              "fax1": null,
              "fax2": "615-253-0339",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h85.html",
                "https://www.capitol.tn.gov/house/members/h85.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H85"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-85-lower-ocd-person-108691a2-9f24-41c5-b4c8-606c7cdf036b": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/df116f27-98bd-497d-a5bc-9f1d7ddff506",
            "firstName": "Justin J.",
            "lastName": "Pearson",
            "fullName": "Justin Pearson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://images.squarespace-cdn.com/content/v1/638e0e4731cb8444ce5a20e8/1d467f44-2f5f-42d0-96e9-8819eee165fb/Justin_21.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-TN-86-lower-ocd-person-df116f27-98bd-497d-a5bc-9f1d7ddff506",
              "builtID": "tn-lower-86",
              "externalID": "ocd-person/df116f27-98bd-497d-a5bc-9f1d7ddff506",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.justin.j.pearson@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 572, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-4295",
              "fax1": null,
              "fax2": "615-253-0327",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H86"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-86-lower-ocd-person-df116f27-98bd-497d-a5bc-9f1d7ddff506": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/807f5a72-fe61-460c-86a3-c65a6b9eaca1",
            "firstName": "Karen D.",
            "lastName": "Camper",
            "fullName": "Karen Camper",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.tn.gov/house/members/images/h87.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-TN-87-lower-ocd-person-807f5a72-fe61-460c-86a3-c65a6b9eaca1",
              "builtID": "tn-lower-87",
              "externalID": "ocd-person/807f5a72-fe61-460c-86a3-c65a6b9eaca1",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.karen.camper@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 664, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1898",
              "fax1": null,
              "fax2": "615-253-0211",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h87.html",
                "https://www.capitol.tn.gov/house/members/h87.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H87"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-87-lower-ocd-person-807f5a72-fe61-460c-86a3-c65a6b9eaca1": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/3e7acb18-4b9e-45d5-9787-dbce0f1959d8",
            "firstName": "Larry J.",
            "lastName": "Miller",
            "fullName": "Larry Miller",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.tn.gov/house/members/images/h88.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-TN-88-lower-ocd-person-3e7acb18-4b9e-45d5-9787-dbce0f1959d8",
              "builtID": "tn-lower-88",
              "externalID": "ocd-person/3e7acb18-4b9e-45d5-9787-dbce0f1959d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.larry.miller@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 580, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-4453",
              "fax1": null,
              "fax2": "615-253-0329",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h88.html",
                "https://www.capitol.tn.gov/house/members/h88.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H88"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-88-lower-ocd-person-3e7acb18-4b9e-45d5-9787-dbce0f1959d8": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/34114218-f0eb-4864-8f4a-156c4cc71cdb",
            "firstName": "Justin Augustus",
            "lastName": "Lafferty",
            "fullName": "Justin Lafferty",
            "gender": "Male",
            "party": "Republican",
            "image": "https://fluxconsole.com/files/image/131170",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-TN-89-lower-ocd-person-34114218-f0eb-4864-8f4a-156c4cc71cdb",
              "builtID": "tn-lower-89",
              "externalID": "ocd-person/34114218-f0eb-4864-8f4a-156c4cc71cdb",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.justin.lafferty@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 506, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-4110",
              "fax1": null,
              "fax2": "615-253-0195",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h89.html",
                "https://www.capitol.tn.gov/house/members/h89.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H89"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-89-lower-ocd-person-34114218-f0eb-4864-8f4a-156c4cc71cdb": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/4dbbf89b-69ab-459f-97ee-c53866e81296",
            "firstName": "Gloria S.",
            "lastName": "Johnson",
            "fullName": "Gloria Johnson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://capitol.tn.gov/house/members/images/H90.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-TN-90-lower-ocd-person-4dbbf89b-69ab-459f-97ee-c53866e81296",
              "builtID": "tn-lower-90",
              "externalID": "ocd-person/4dbbf89b-69ab-459f-97ee-c53866e81296",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.gloria.johnson@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 434, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2031",
              "fax1": null,
              "fax2": "615-253-0192",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h13.html",
                "https://www.capitol.tn.gov/house/members/h13.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-90-lower-ocd-person-4dbbf89b-69ab-459f-97ee-c53866e81296": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/8036dedc-9be1-4935-9a37-df3f95033110",
            "firstName": "Torrey",
            "lastName": "Harris",
            "fullName": "Torrey Harris",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://capitol.tn.gov/house/members/images/H91.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-TN-91-lower-ocd-person-8036dedc-9be1-4935-9a37-df3f95033110",
              "builtID": "tn-lower-91",
              "externalID": "ocd-person/8036dedc-9be1-4935-9a37-df3f95033110",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.torrey.harris@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 420, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2239",
              "fax1": null,
              "fax2": "615-253-0292",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h90.html",
                "https://www.capitol.tn.gov/house/members/h90.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H91"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-91-lower-ocd-person-8036dedc-9be1-4935-9a37-df3f95033110": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/a7947036-fe6d-4ace-8e6e-78445dc4e19f",
            "firstName": "Todd",
            "lastName": "Warner",
            "fullName": "Todd Warner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.capitol.tn.gov/house/members/images/h92.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-TN-92-lower-ocd-person-a7947036-fe6d-4ace-8e6e-78445dc4e19f",
              "builtID": "tn-lower-92",
              "externalID": "ocd-person/a7947036-fe6d-4ace-8e6e-78445dc4e19f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.todd.warner@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 414, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-4170",
              "fax1": null,
              "fax2": "615-253-0274",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h92.html",
                "https://www.capitol.tn.gov/house/members/h92.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H92"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-92-lower-ocd-person-a7947036-fe6d-4ace-8e6e-78445dc4e19f": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/740ad1ee-4445-41ef-b6b9-1d31185b4916",
            "firstName": "Goffrey A.",
            "lastName": "Hardaway",
            "fullName": "G.A. Hardaway",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.tn.gov/house/members/images/h93.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-TN-93-lower-ocd-person-740ad1ee-4445-41ef-b6b9-1d31185b4916",
              "builtID": "tn-lower-93",
              "externalID": "ocd-person/740ad1ee-4445-41ef-b6b9-1d31185b4916",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ga.hardaway@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 404, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-5625",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h93.html",
                "https://www.capitol.tn.gov/house/members/h93.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H93"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-93-lower-ocd-person-740ad1ee-4445-41ef-b6b9-1d31185b4916": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/e7a2a416-0d33-4d78-8ee5-cf79628df4bd",
            "firstName": "Ron M.",
            "lastName": "Gant",
            "fullName": "Ron Gant",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h94.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-TN-94-lower-ocd-person-e7a2a416-0d33-4d78-8ee5-cf79628df4bd",
              "builtID": "tn-lower-94",
              "externalID": "ocd-person/e7a2a416-0d33-4d78-8ee5-cf79628df4bd",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ron.gant@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 640, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-6890",
              "fax1": null,
              "fax2": "615-253-0380",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h94.html",
                "https://www.capitol.tn.gov/house/members/h94.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H94"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-94-lower-ocd-person-e7a2a416-0d33-4d78-8ee5-cf79628df4bd": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/6246e527-70a9-4591-bb46-6354c6e11107",
            "firstName": "Kevin",
            "lastName": "Vaughan",
            "fullName": "Kevin Vaughan",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/house/members/images/h95.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-TN-95-lower-ocd-person-6246e527-70a9-4591-bb46-6354c6e11107",
              "builtID": "tn-lower-95",
              "externalID": "ocd-person/6246e527-70a9-4591-bb46-6354c6e11107",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kevin.vaughan@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 622, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1866",
              "fax1": null,
              "fax2": "615-253-0208",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h95.html",
                "https://www.capitol.tn.gov/house/members/h95.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H95"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-95-lower-ocd-person-6246e527-70a9-4591-bb46-6354c6e11107": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/1405aba3-6359-491e-915b-0a9a0ecaaee8",
            "firstName": "Dwayne",
            "lastName": "Thompson",
            "fullName": "Dwayne Thompson",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.tn.gov/house/members/images/h96.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-TN-96-lower-ocd-person-1405aba3-6359-491e-915b-0a9a0ecaaee8",
              "builtID": "tn-lower-96",
              "externalID": "ocd-person/1405aba3-6359-491e-915b-0a9a0ecaaee8",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.dwayne.thompson@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 406, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1920",
              "fax1": null,
              "fax2": "615-253-0232",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h96.html",
                "https://www.capitol.tn.gov/house/members/h96.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H96"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-96-lower-ocd-person-1405aba3-6359-491e-915b-0a9a0ecaaee8": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/927edca0-8b67-40c6-b786-4cbfac5b344f",
            "firstName": "John",
            "lastName": "Gillespie",
            "fullName": "John Gillespie",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.capitol.tn.gov/house/members/images/h97.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-TN-97-lower-ocd-person-927edca0-8b67-40c6-b786-4cbfac5b344f",
              "builtID": "tn-lower-97",
              "externalID": "ocd-person/927edca0-8b67-40c6-b786-4cbfac5b344f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.john.gillespie@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 590, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-8201",
              "fax1": null,
              "fax2": "615-253-0267",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h97.html",
                "https://www.capitol.tn.gov/house/members/h97.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H97"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-97-lower-ocd-person-927edca0-8b67-40c6-b786-4cbfac5b344f": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/785aed65-3e87-42fe-8e71-307ce08f44ae",
            "firstName": "Antonio",
            "lastName": "Parkinson",
            "fullName": "Antonio Parkinson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Antonio_Parkinson.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-TN-98-lower-ocd-person-785aed65-3e87-42fe-8e71-307ce08f44ae",
              "builtID": "tn-lower-98",
              "externalID": "ocd-person/785aed65-3e87-42fe-8e71-307ce08f44ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.antonio.parkinson@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 419, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-4575",
              "fax1": null,
              "fax2": "615-253-0347",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h98.html",
                "https://www.capitol.tn.gov/house/members/h98.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H98"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-98-lower-ocd-person-785aed65-3e87-42fe-8e71-307ce08f44ae": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/37db89c5-6814-450a-8259-5e6d1a046e4f",
            "firstName": "Thomas F.",
            "lastName": "Leatherwood",
            "fullName": "Tom Leatherwood",
            "gender": "Male",
            "party": "Republican",
            "image": "https://d33byq9npfy6u9.cloudfront.net/4/tom-leatherwood/u/original/1333880/tomleatherwoodmug.jpg",
            "title": "TN Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-TN-99-lower-ocd-person-37db89c5-6814-450a-8259-5e6d1a046e4f",
              "builtID": "tn-lower-99",
              "externalID": "ocd-person/37db89c5-6814-450a-8259-5e6d1a046e4f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.tom.leatherwood@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 514, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-7084",
              "fax1": null,
              "fax2": "615-253-0294",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h99.html",
                "https://www.capitol.tn.gov/house/members/h99.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=H99"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TN-99-lower-ocd-person-37db89c5-6814-450a-8259-5e6d1a046e4f": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/b3bd4f95-946f-4b4b-b58f-ab78345847b7",
            "firstName": "J. Adam",
            "lastName": "Lowe",
            "fullName": "Adam Lowe",
            "gender": "Male",
            "party": "Republican",
            "image": "https://static.wixstatic.com/media/599b12_afa74a3390064dd386df25e882ac9659~mv2.jpeg/v1/fill/w_675,h_716,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/599b12_afa74a3390064dd386df25e882ac9659~mv2.jpeg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-TN-1-upper-ocd-person-b3bd4f95-946f-4b4b-b58f-ab78345847b7",
              "builtID": "tn-upper-1",
              "externalID": "ocd-person/b3bd4f95-946f-4b4b-b58f-ab78345847b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.adam.lowe@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 740, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1946",
              "fax1": null,
              "fax2": "615-253-0374",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-1-upper-ocd-person-b3bd4f95-946f-4b4b-b58f-ab78345847b7": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/9c438a2b-61bd-4cf5-80f7-d3cac3a86108",
            "firstName": "Art",
            "lastName": "Swann",
            "fullName": "Art Swann",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s2.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-TN-2-upper-ocd-person-9c438a2b-61bd-4cf5-80f7-d3cac3a86108",
              "builtID": "tn-upper-2",
              "externalID": "ocd-person/9c438a2b-61bd-4cf5-80f7-d3cac3a86108",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.art.swann@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 748, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-0981",
              "fax1": null,
              "fax2": "615-253-0224",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s2.html",
                "https://www.capitol.tn.gov/senate/members/s2.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S2"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-2-upper-ocd-person-9c438a2b-61bd-4cf5-80f7-d3cac3a86108": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/b8b305e5-ff02-4f8d-8ef1-61a4a7ecf54a",
            "firstName": "Dewey Rusty Edward",
            "lastName": "Crowe",
            "fullName": "Rusty Crowe",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s3.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-TN-3-upper-ocd-person-b8b305e5-ff02-4f8d-8ef1-61a4a7ecf54a",
              "builtID": "tn-upper-3",
              "externalID": "ocd-person/b8b305e5-ff02-4f8d-8ef1-61a4a7ecf54a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.rusty.crowe@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 720, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2468",
              "fax1": null,
              "fax2": "615-253-0359",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s3.html",
                "https://www.capitol.tn.gov/senate/members/s3.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S3"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-3-upper-ocd-person-b8b305e5-ff02-4f8d-8ef1-61a4a7ecf54a": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/98424377-d7d3-4c6d-b511-b63e4fd548e4",
            "firstName": "Jon",
            "lastName": "Lundberg",
            "fullName": "Jon Lundberg",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s4.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-TN-4-upper-ocd-person-98424377-d7d3-4c6d-b511-b63e4fd548e4",
              "builtID": "tn-upper-4",
              "externalID": "ocd-person/98424377-d7d3-4c6d-b511-b63e4fd548e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.jon.lundberg@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 744, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-5761",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s4.html",
                "https://www.capitol.tn.gov/senate/members/s4.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S4"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-4-upper-ocd-person-98424377-d7d3-4c6d-b511-b63e4fd548e4": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/e00c236d-6153-4618-96f8-12e6a1d639aa",
            "firstName": "James Rand",
            "lastName": "McNally",
            "fullName": "Randy McNally",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s5.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-TN-5-upper-ocd-person-e00c236d-6153-4618-96f8-12e6a1d639aa",
              "builtID": "tn-upper-5",
              "externalID": "ocd-person/e00c236d-6153-4618-96f8-12e6a1d639aa",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.randy.mcnally@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 700, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-6806",
              "fax1": null,
              "fax2": "615-253-0285",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s5.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S5"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-5-upper-ocd-person-e00c236d-6153-4618-96f8-12e6a1d639aa": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/592971a7-2e11-4bed-a57d-71187e174329",
            "firstName": "Becky Duncan",
            "lastName": "Massey",
            "fullName": "Becky Massey",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s6.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-TN-6-upper-ocd-person-592971a7-2e11-4bed-a57d-71187e174329",
              "builtID": "tn-upper-6",
              "externalID": "ocd-person/592971a7-2e11-4bed-a57d-71187e174329",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.becky.massey@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 776, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1648",
              "fax1": null,
              "fax2": "615-253-0270",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s6.html",
                "https://www.capitol.tn.gov/senate/members/s6.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S6"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-6-upper-ocd-person-592971a7-2e11-4bed-a57d-71187e174329": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/9d744074-d7ae-4004-8912-bae320633c46",
            "firstName": "Richard",
            "lastName": "Briggs",
            "fullName": "Richard Briggs",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s7.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-TN-7-upper-ocd-person-9d744074-d7ae-4004-8912-bae320633c46",
              "builtID": "tn-upper-7",
              "externalID": "ocd-person/9d744074-d7ae-4004-8912-bae320633c46",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.richard.briggs@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 774, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1766",
              "fax1": null,
              "fax2": "615-253-0199",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s7.html",
                "https://www.capitol.tn.gov/senate/members/s7.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S7"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-7-upper-ocd-person-9d744074-d7ae-4004-8912-bae320633c46": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/3622913f-ad6b-46d7-9bae-7dc83fee9da6",
            "firstName": "Frank Samuel",
            "lastName": "Niceley",
            "fullName": "Frank Niceley",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s8.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-TN-8-upper-ocd-person-3622913f-ad6b-46d7-9bae-7dc83fee9da6",
              "builtID": "tn-upper-8",
              "externalID": "ocd-person/3622913f-ad6b-46d7-9bae-7dc83fee9da6",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.frank.niceley@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 712, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2061",
              "fax1": null,
              "fax2": "615-253-0255",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s8.html",
                "https://www.capitol.tn.gov/senate/members/s8.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S8"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-8-upper-ocd-person-3622913f-ad6b-46d7-9bae-7dc83fee9da6": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/37dddeca-88ef-4254-b6f2-b2ddd6526665",
            "firstName": "Steve",
            "lastName": "Southerland",
            "fullName": "Steve Southerland",
            "gender": "Male",
            "party": "Republican",
            "image": "https://capitol.tn.gov/senate/members/images/S9.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-TN-9-upper-ocd-person-37dddeca-88ef-4254-b6f2-b2ddd6526665",
              "builtID": "tn-upper-9",
              "externalID": "ocd-person/37dddeca-88ef-4254-b6f2-b2ddd6526665",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.steve.southerland@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 722, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-3851",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s1.html",
                "https://www.capitol.tn.gov/senate/members/s1.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S9"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-9-upper-ocd-person-37dddeca-88ef-4254-b6f2-b2ddd6526665": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/d5d2e89a-5467-4f69-a77d-70caaa351086",
            "firstName": "R. Todd",
            "lastName": "Gardenhire",
            "fullName": "Todd Gardenhire",
            "gender": "Male",
            "party": "Republican",
            "image": "https://capitol.tn.gov/senate/members/images/S10.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-TN-10-upper-ocd-person-d5d2e89a-5467-4f69-a77d-70caaa351086",
              "builtID": "tn-upper-10",
              "externalID": "ocd-person/d5d2e89a-5467-4f69-a77d-70caaa351086",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.todd.gardenhire@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 716, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-6682",
              "fax1": null,
              "fax2": "615-253-0209",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s10.html",
                "https://www.capitol.tn.gov/senate/members/s10.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S10"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-10-upper-ocd-person-d5d2e89a-5467-4f69-a77d-70caaa351086": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/86d5ff12-369b-41a4-9626-237425063c1b",
            "firstName": "Foy W.",
            "lastName": "Watson",
            "fullName": "Bo Watson",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s11.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-TN-11-upper-ocd-person-86d5ff12-369b-41a4-9626-237425063c1b",
              "builtID": "tn-upper-11",
              "externalID": "ocd-person/86d5ff12-369b-41a4-9626-237425063c1b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.bo.watson@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 706, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-3227",
              "fax1": null,
              "fax2": "615-253-0280",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s11.html",
                "https://www.capitol.tn.gov/senate/members/s11.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S11"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-11-upper-ocd-person-86d5ff12-369b-41a4-9626-237425063c1b": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/908edc68-a862-455c-a609-7619977252d1",
            "firstName": "Ken",
            "lastName": "Yager",
            "fullName": "Ken Yager",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s12.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-TN-12-upper-ocd-person-908edc68-a862-455c-a609-7619977252d1",
              "builtID": "tn-upper-12",
              "externalID": "ocd-person/908edc68-a862-455c-a609-7619977252d1",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.ken.yager@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 704, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1449",
              "fax1": null,
              "fax2": "615-253-0237",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s12.html",
                "https://www.capitol.tn.gov/senate/members/s12.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S12"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-12-upper-ocd-person-908edc68-a862-455c-a609-7619977252d1": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/80bf42b1-4942-4187-af19-94e830333ce2",
            "firstName": "Dawn Throneberry",
            "lastName": "White",
            "fullName": "Dawn White",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s13.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-TN-13-upper-ocd-person-80bf42b1-4942-4187-af19-94e830333ce2",
              "builtID": "tn-upper-13",
              "externalID": "ocd-person/80bf42b1-4942-4187-af19-94e830333ce2",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.dawn.white@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 752, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-6853",
              "fax1": null,
              "fax2": "615-253-0282",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s13.html",
                "https://www.capitol.tn.gov/senate/members/s13.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S13"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-13-upper-ocd-person-80bf42b1-4942-4187-af19-94e830333ce2": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/fa4bd8d0-c567-4434-a24a-6d0bf9759f0b",
            "firstName": "Shane",
            "lastName": "Reeves",
            "fullName": "Shane Reeves",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s14.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-TN-14-upper-ocd-person-fa4bd8d0-c567-4434-a24a-6d0bf9759f0b",
              "builtID": "tn-upper-14",
              "externalID": "ocd-person/fa4bd8d0-c567-4434-a24a-6d0bf9759f0b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.shane.reeves@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 732, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1066",
              "fax1": null,
              "fax2": "615-253-0319",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s14.html",
                "https://www.capitol.tn.gov/senate/members/s14.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S14"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-14-upper-ocd-person-fa4bd8d0-c567-4434-a24a-6d0bf9759f0b": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/8e49fca4-e0d8-4273-adab-265632163d86",
            "firstName": "Paul",
            "lastName": "Bailey",
            "fullName": "Paul Bailey",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s15.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-TN-15-upper-ocd-person-8e49fca4-e0d8-4273-adab-265632163d86",
              "builtID": "tn-upper-15",
              "externalID": "ocd-person/8e49fca4-e0d8-4273-adab-265632163d86",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.paul.bailey@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 736, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-3978",
              "fax1": null,
              "fax2": "615-253-0381",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s15.html",
                "https://www.capitol.tn.gov/senate/members/s15.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S15"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-15-upper-ocd-person-8e49fca4-e0d8-4273-adab-265632163d86": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/f48dc5f3-1c31-4654-a6be-1b9d20ecb3b2",
            "firstName": "Janice",
            "lastName": "Bowling",
            "fullName": "Janice Bowling",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s16.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-TN-16-upper-ocd-person-f48dc5f3-1c31-4654-a6be-1b9d20ecb3b2",
              "builtID": "tn-upper-16",
              "externalID": "ocd-person/f48dc5f3-1c31-4654-a6be-1b9d20ecb3b2",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.janice.bowling@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 718, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-6694",
              "fax1": null,
              "fax2": "615-253-0260",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s16.html",
                "https://www.capitol.tn.gov/senate/members/s16.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S16"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-16-upper-ocd-person-f48dc5f3-1c31-4654-a6be-1b9d20ecb3b2": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/47dee87e-231d-4c39-b0a2-6905a85ddbf8",
            "firstName": "Mark",
            "lastName": "Pody",
            "fullName": "Mark Pody",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s17.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-TN-17-upper-ocd-person-47dee87e-231d-4c39-b0a2-6905a85ddbf8",
              "builtID": "tn-upper-17",
              "externalID": "ocd-person/47dee87e-231d-4c39-b0a2-6905a85ddbf8",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.mark.pody@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 754, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2421",
              "fax1": null,
              "fax2": "615-253-0205",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s17.html",
                "https://www.capitol.tn.gov/senate/members/s17.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S17"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-17-upper-ocd-person-47dee87e-231d-4c39-b0a2-6905a85ddbf8": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/f539ae98-f5d7-4290-9835-f64a1ab0a8c7",
            "firstName": "Ferrell",
            "lastName": "Haile",
            "fullName": "Ferrell Haile",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s18.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-TN-18-upper-ocd-person-f539ae98-f5d7-4290-9835-f64a1ab0a8c7",
              "builtID": "tn-upper-18",
              "externalID": "ocd-person/f539ae98-f5d7-4290-9835-f64a1ab0a8c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.ferrell.haile@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 708, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1999",
              "fax1": null,
              "fax2": "615-253-0306",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s18.html",
                "https://www.capitol.tn.gov/senate/members/s18.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S18"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-18-upper-ocd-person-f539ae98-f5d7-4290-9835-f64a1ab0a8c7": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/0ce3dbf9-c880-480b-a623-7d9da870bb01",
            "firstName": "Charlane J.",
            "lastName": "Oliver",
            "fullName": "Charlane Oliver",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/CharlaneOliver.png",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-TN-19-upper-ocd-person-0ce3dbf9-c880-480b-a623-7d9da870bb01",
              "builtID": "tn-upper-19",
              "externalID": "ocd-person/0ce3dbf9-c880-480b-a623-7d9da870bb01",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.charlane.oliver@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 766, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2453",
              "fax1": null,
              "fax2": "615-253-0268",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S19"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-19-upper-ocd-person-0ce3dbf9-c880-480b-a623-7d9da870bb01": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/b4356789-3561-4110-ad59-00c7cf36e0fd",
            "firstName": "Heidi",
            "lastName": "Campbell",
            "fullName": "Heidi Campbell",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.capitol.tn.gov/senate/members/images/S20.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-TN-20-upper-ocd-person-b4356789-3561-4110-ad59-00c7cf36e0fd",
              "builtID": "tn-upper-20",
              "externalID": "ocd-person/b4356789-3561-4110-ad59-00c7cf36e0fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.heidi.campbell@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 770, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-6679",
              "fax1": null,
              "fax2": "615-253-0313",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s20.html",
                "https://www.capitol.tn.gov/senate/members/s20.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S20"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-20-upper-ocd-person-b4356789-3561-4110-ad59-00c7cf36e0fd": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/eda112b7-c5bc-4d24-95b8-3651090e1da4",
            "firstName": "Jeff",
            "lastName": "Yarbro",
            "fullName": "Jeff Yarbro",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.capitol.tn.gov/senate/members/images/s21.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-TN-21-upper-ocd-person-eda112b7-c5bc-4d24-95b8-3651090e1da4",
              "builtID": "tn-upper-21",
              "externalID": "ocd-person/eda112b7-c5bc-4d24-95b8-3651090e1da4",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.jeff.yarbro@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s21.html",
                "https://www.capitol.tn.gov/senate/members/s21.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S21"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-21-upper-ocd-person-eda112b7-c5bc-4d24-95b8-3651090e1da4": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/46b6a1b2-f0c0-4e9a-a506-a81e12e329f5",
            "firstName": "Bill",
            "lastName": "Powers",
            "fullName": "Bill Powers",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s22.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-TN-22-upper-ocd-person-46b6a1b2-f0c0-4e9a-a506-a81e12e329f5",
              "builtID": "tn-upper-22",
              "externalID": "ocd-person/46b6a1b2-f0c0-4e9a-a506-a81e12e329f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.bill.powers@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 772, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2374",
              "fax1": null,
              "fax2": "615-253-0193",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s22.html",
                "https://www.capitol.tn.gov/senate/members/s22.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S22"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-22-upper-ocd-person-46b6a1b2-f0c0-4e9a-a506-a81e12e329f5": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/872517bf-9780-44e2-9532-87dfd816ec4c",
            "firstName": "Kerry",
            "lastName": "Roberts",
            "fullName": "Kerry Roberts",
            "gender": "Male",
            "party": "Republican",
            "image": "https://fluxconsole.com/files/image/131100",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-TN-23-upper-ocd-person-872517bf-9780-44e2-9532-87dfd816ec4c",
              "builtID": "tn-upper-23",
              "externalID": "ocd-person/872517bf-9780-44e2-9532-87dfd816ec4c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.kerry.roberts@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 730, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-4499",
              "fax1": null,
              "fax2": "615-253-0302",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s25.html",
                "https://www.capitol.tn.gov/senate/members/s25.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S23"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-23-upper-ocd-person-872517bf-9780-44e2-9532-87dfd816ec4c": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/cb6c477c-ceb7-41af-ab96-b03debc1b7e3",
            "firstName": "John",
            "lastName": "Stevens",
            "fullName": "John Stevens",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s24.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-TN-24-upper-ocd-person-cb6c477c-ceb7-41af-ab96-b03debc1b7e3",
              "builtID": "tn-upper-24",
              "externalID": "ocd-person/cb6c477c-ceb7-41af-ab96-b03debc1b7e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.john.stevens@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 710, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-4576",
              "fax1": null,
              "fax2": "615-253-0161",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s24.html",
                "https://www.capitol.tn.gov/senate/members/s24.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S24"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-24-upper-ocd-person-cb6c477c-ceb7-41af-ab96-b03debc1b7e3": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/93024b02-926b-4414-8826-147b14739a2b",
            "firstName": "Edward S.",
            "lastName": "Jackson",
            "fullName": "Ed Jackson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://capitol.tn.gov/senate/members/images/S25.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-TN-25-upper-ocd-person-93024b02-926b-4414-8826-147b14739a2b",
              "builtID": "tn-upper-25",
              "externalID": "ocd-person/93024b02-926b-4414-8826-147b14739a2b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.ed.jackson@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 746, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1810",
              "fax1": null,
              "fax2": "615-253-0179",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s27.html",
                "https://www.capitol.tn.gov/senate/members/s27.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S25"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-25-upper-ocd-person-93024b02-926b-4414-8826-147b14739a2b": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/88788ad3-d3e0-4846-9bd7-bc7b7f0b9759",
            "firstName": "Page",
            "lastName": "Walley",
            "fullName": "Page Walley",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/S26.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-TN-26-upper-ocd-person-88788ad3-d3e0-4846-9bd7-bc7b7f0b9759",
              "builtID": "tn-upper-26",
              "externalID": "ocd-person/88788ad3-d3e0-4846-9bd7-bc7b7f0b9759",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.page.walley@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 750, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2368",
              "fax1": null,
              "fax2": "615-253-0204",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s26.html",
                "https://www.capitol.tn.gov/senate/members/s26.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S26"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-26-upper-ocd-person-88788ad3-d3e0-4846-9bd7-bc7b7f0b9759": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/83e732c5-55af-4d56-b3ed-9ff358ed641e",
            "firstName": "Jack",
            "lastName": "Johnson",
            "fullName": "Jack Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.newyork1.vip.townnews.com/williamsonherald.com/content/tncms/assets/v3/editorial/b/5f/b5ffcba0-f751-11e8-8648-bf381c197480/5c05b9b74599d.image.jpg?resize=334%2C500",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-TN-27-upper-ocd-person-83e732c5-55af-4d56-b3ed-9ff358ed641e",
              "builtID": "tn-upper-27",
              "externalID": "ocd-person/83e732c5-55af-4d56-b3ed-9ff358ed641e",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.jack.johnson@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 702, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2495",
              "fax1": null,
              "fax2": "615-253-0321",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s23.html",
                "https://www.capitol.tn.gov/senate/members/s23.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S27"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-27-upper-ocd-person-83e732c5-55af-4d56-b3ed-9ff358ed641e": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/fccb09fc-b331-4bba-8b84-fbd4ab3e3227",
            "firstName": "Joey",
            "lastName": "Hensley",
            "fullName": "Joey Hensley",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s28.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-TN-28-upper-ocd-person-fccb09fc-b331-4bba-8b84-fbd4ab3e3227",
              "builtID": "tn-upper-28",
              "externalID": "ocd-person/fccb09fc-b331-4bba-8b84-fbd4ab3e3227",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.joey.hensley@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 742, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-3100",
              "fax1": null,
              "fax2": "615-253-0231",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s28.html",
                "https://www.capitol.tn.gov/senate/members/s28.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S28"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-28-upper-ocd-person-fccb09fc-b331-4bba-8b84-fbd4ab3e3227": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/9c949092-7bff-4859-bea7-46d9c37ed513",
            "firstName": "Raumesh Aleza",
            "lastName": "Akbari",
            "fullName": "Raumesh Akbari",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.tn.gov/senate/members/images/s29.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-TN-29-upper-ocd-person-9c949092-7bff-4859-bea7-46d9c37ed513",
              "builtID": "tn-upper-29",
              "externalID": "ocd-person/9c949092-7bff-4859-bea7-46d9c37ed513",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.raumesh.akbari@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 764, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1767",
              "fax1": null,
              "fax2": "615-253-0357",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s29.html",
                "https://www.capitol.tn.gov/senate/members/s29.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S29"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-29-upper-ocd-person-9c949092-7bff-4859-bea7-46d9c37ed513": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/f1eb839c-83c6-471f-865e-1d87344fb58c",
            "firstName": "Sara Peery",
            "lastName": "Kyle",
            "fullName": "Sara Kyle",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.capitol.tn.gov/senate/members/images/s30.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-TN-30-upper-ocd-person-f1eb839c-83c6-471f-865e-1d87344fb58c",
              "builtID": "tn-upper-30",
              "externalID": "ocd-person/f1eb839c-83c6-471f-865e-1d87344fb58c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.sara.kyle@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 760, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-4167",
              "fax1": null,
              "fax2": "615-253-0221",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s30.html",
                "https://www.capitol.tn.gov/senate/members/s30.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S30"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-30-upper-ocd-person-f1eb839c-83c6-471f-865e-1d87344fb58c": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/d3f48e28-4ee5-4027-9670-0d617ca6048e",
            "firstName": "Brent",
            "lastName": "Taylor",
            "fullName": "Brent Taylor",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/BrentTaylor_TN.jpeg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-TN-31-upper-ocd-person-d3f48e28-4ee5-4027-9670-0d617ca6048e",
              "builtID": "tn-upper-31",
              "externalID": "ocd-person/d3f48e28-4ee5-4027-9670-0d617ca6048e",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.brent.taylor@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 714, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-3036",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S31"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-31-upper-ocd-person-d3f48e28-4ee5-4027-9670-0d617ca6048e": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/1793564f-19a5-4ac9-91c3-7cbb47843c2b",
            "firstName": "Paul",
            "lastName": "Rose",
            "fullName": "Paul Rose",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.capitol.tn.gov/senate/members/images/s32.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-TN-32-upper-ocd-person-1793564f-19a5-4ac9-91c3-7cbb47843c2b",
              "builtID": "tn-upper-32",
              "externalID": "ocd-person/1793564f-19a5-4ac9-91c3-7cbb47843c2b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.paul.rose@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 734, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-1967",
              "fax1": null,
              "fax2": "615-253-0194",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/senate/members/s32.html",
                "https://www.capitol.tn.gov/senate/members/s32.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S32"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-32-upper-ocd-person-1793564f-19a5-4ac9-91c3-7cbb47843c2b": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/44fda210-d5be-4785-ae5e-0072b4111a67",
            "firstName": "London",
            "lastName": "Lamar",
            "fullName": "London Lamar",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.capitol.tn.gov/senate/members/images/s33.jpg",
            "title": "TN Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TN",
              "stateFull": "Tennessee",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-TN-33-upper-ocd-person-44fda210-d5be-4785-ae5e-0072b4111a67",
              "builtID": "tn-upper-33",
              "externalID": "ocd-person/44fda210-d5be-4785-ae5e-0072b4111a67",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.london.lamar@capitol.tn.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 762, Cordell Hull Building 425 Rep. John Lewis Way North, Nashville, TN 37243",
              "phone1": null,
              "phone2": "615-741-2509",
              "fax1": null,
              "fax2": "615-253-0167",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.capitol.tn.gov/house/members/h91.html",
                "https://www.capitol.tn.gov/senate/members/s33.html",
                "https://wapp.capitol.tn.gov/apps/legislatorinfo/member.aspx?district=S33"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TN-33-upper-ocd-person-44fda210-d5be-4785-ae5e-0072b4111a67": 0
        }
      }
    }
  },
  "TX": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "TX",
          "stateFull": "Texas",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "TX",
            "stateFull": "Texas",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-TX---",
            "builtID": "tx--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-TX---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "C001056",
          "in_office": true,
          "firstName": "John",
          "lastName": "Cornyn",
          "middleName": null,
          "fullName": "John Cornyn",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/C001056.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "TX",
            "stateFull": "Texas",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-TX---C001056",
            "builtID": "tx--",
            "externalID": "C001056",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.cornyn.senate.gov/contact",
            "address1": "517 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-2934",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "JohnCornyn",
            "youtube": "senjohncornyn",
            "instagram": null,
            "facebook": "sen.johncornyn",
            "urls": ["https://www.cornyn.senate.gov"],
            "rss_url": null
          },
          "title": "TX Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/C001056.json",
          "govtrack_id": "300027",
          "cspan_id": "93131",
          "votesmart_id": "15375",
          "icpsr_id": "40305",
          "crp_id": "N00024852",
          "google_entity_id": "/m/01xcqs",
          "state_rank": "senior",
          "lis_id": "S287",
          "suffix": null,
          "date_of_birth": "1952-02-02",
          "leadership_role": "Senate Majority Whip",
          "fec_candidate_id": "S2TX00106",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "21",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 14,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 2.53,
          "votes_with_party_pct": 91.32,
          "votes_against_party_pct": 8.68,
          "ocd_id": "ocd-division/country:us/state:tx"
        },
        {
          "API_ID": "C001098",
          "in_office": true,
          "firstName": "Ted",
          "lastName": "Cruz",
          "middleName": null,
          "fullName": "Ted Cruz",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/C001098.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "TX",
            "stateFull": "Texas",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-TX---C001098",
            "builtID": "tx--",
            "externalID": "C001098",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.cruz.senate.gov/?p=form&id=16",
            "address1": "127a Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-5922",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenTedCruz",
            "youtube": "sentedcruz",
            "instagram": null,
            "facebook": "SenatorTedCruz",
            "urls": ["https://www.cruz.senate.gov"],
            "rss_url": null
          },
          "title": "TX Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/C001098.json",
          "govtrack_id": "412573",
          "cspan_id": "1019953",
          "votesmart_id": "135705",
          "icpsr_id": "41304",
          "crp_id": "N00033085",
          "google_entity_id": "/m/07j6ty",
          "state_rank": "junior",
          "lis_id": "S355",
          "suffix": null,
          "date_of_birth": "1970-12-22",
          "leadership_role": null,
          "fec_candidate_id": "S2TX00312",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "11",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 40,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:24 -0400",
          "missed_votes_pct": 7.22,
          "votes_with_party_pct": 92.08,
          "votes_against_party_pct": 7.92,
          "ocd_id": "ocd-division/country:us/state:tx"
        }
      ],
      "hash": { "SENATE-TX---C001056": 0, "SENATE-TX---C001098": 1 }
    },
    "HOUSE": {
      "10": {
        "members": [
          {
            "API_ID": "M001157",
            "in_office": true,
            "firstName": "Michael",
            "lastName": "McCaul",
            "middleName": null,
            "fullName": "Michael T. McCaul",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001157.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "10",
              "chamber": "",
              "hashID": "HOUSE-TX-10--M001157",
              "builtID": "tx--10",
              "externalID": "M001157",
              "geometry": null,
              "geoid": "4810"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2300 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2401",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMcCaul",
              "youtube": "MichaelTMcCaul",
              "instagram": null,
              "facebook": "michaeltmccaul",
              "urls": ["https://mccaul.house.gov"],
              "rss_url": "https://mccaul.house.gov/rss.xml"
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001157.json",
            "govtrack_id": "400654",
            "cspan_id": "1013056",
            "votesmart_id": "49210",
            "icpsr_id": "20530",
            "crp_id": "N00026460",
            "google_entity_id": "/m/0492cz",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1962-01-14",
            "leadership_role": null,
            "fec_candidate_id": "H4TX10093",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "20",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 29,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 2.75,
            "votes_with_party_pct": 90.25,
            "votes_against_party_pct": 7.86,
            "ocd_id": "ocd-division/country:us/state:tx/cd:10",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-10--M001157": 0 }
      },
      "11": {
        "members": [
          {
            "API_ID": "P000048",
            "in_office": true,
            "firstName": "August",
            "lastName": "Pfluger",
            "middleName": null,
            "fullName": "August Pfluger",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/P000048.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "11",
              "chamber": "",
              "hashID": "HOUSE-TX-11--P000048",
              "builtID": "tx--11",
              "externalID": "P000048",
              "geometry": null,
              "geoid": "4811"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1124 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3605",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepPfluger",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://pfluger.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000048.json",
            "govtrack_id": "456846",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00045421",
            "google_entity_id": "/g/11f3szpd4x",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1978-12-28",
            "leadership_role": null,
            "fec_candidate_id": "H0TX11230",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.57,
            "votes_with_party_pct": 95.34,
            "votes_against_party_pct": 2.72,
            "ocd_id": "ocd-division/country:us/state:tx/cd:11",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-11--P000048": 0 }
      },
      "12": {
        "members": [
          {
            "API_ID": "G000377",
            "in_office": true,
            "firstName": "Kay",
            "lastName": "Granger",
            "middleName": null,
            "fullName": "Kay Granger",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000377.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "12",
              "chamber": "",
              "hashID": "HOUSE-TX-12--G000377",
              "builtID": "tx--12",
              "externalID": "G000377",
              "geometry": null,
              "geoid": "4812"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2308 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5071",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepKayGranger",
              "youtube": "RepKayGranger",
              "instagram": null,
              "facebook": "RepKayGranger",
              "urls": ["https://kaygranger.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000377.json",
            "govtrack_id": "400157",
            "cspan_id": "45709",
            "votesmart_id": "334",
            "icpsr_id": "29762",
            "crp_id": "N00008799",
            "google_entity_id": "/m/03tndx",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1943-01-18",
            "leadership_role": null,
            "fec_candidate_id": "H6TX12060",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "28",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 157,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 14.88,
            "votes_with_party_pct": 89.78,
            "votes_against_party_pct": 8.06,
            "ocd_id": "ocd-division/country:us/state:tx/cd:12",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-12--G000377": 0 }
      },
      "13": {
        "members": [
          {
            "API_ID": "J000304",
            "in_office": true,
            "firstName": "Ronny",
            "lastName": "Jackson",
            "middleName": null,
            "fullName": "Ronny Jackson",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/J000304.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "13",
              "chamber": "",
              "hashID": "HOUSE-TX-13--J000304",
              "builtID": "tx--13",
              "externalID": "J000304",
              "geometry": null,
              "geoid": "4813"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "446 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3706",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRonnyJackson",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://jackson.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/J000304.json",
            "govtrack_id": "456847",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00046055",
            "google_entity_id": "/g/11gfhx0vbg",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1967-05-04",
            "leadership_role": null,
            "fec_candidate_id": "H0TX13228",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 30,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.84,
            "votes_with_party_pct": 92.14,
            "votes_against_party_pct": 5.87,
            "ocd_id": "ocd-division/country:us/state:tx/cd:13",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-13--J000304": 0 }
      },
      "14": {
        "members": [
          {
            "API_ID": "W000814",
            "in_office": true,
            "firstName": "Randy",
            "lastName": "Weber",
            "middleName": null,
            "fullName": "Randy K. Weber, Sr.",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/W000814.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "14",
              "chamber": "",
              "hashID": "HOUSE-TX-14--W000814",
              "builtID": "tx--14",
              "externalID": "W000814",
              "geometry": null,
              "geoid": "4814"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "107 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2831",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "TXRandy14",
              "youtube": "TXRandy14",
              "instagram": null,
              "facebook": "TXRandy14",
              "urls": ["https://weber.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000814.json",
            "govtrack_id": "412574",
            "cspan_id": "79698",
            "votesmart_id": "102026",
            "icpsr_id": "21360",
            "crp_id": "N00033539",
            "google_entity_id": "/m/0km63t0",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1953-07-02",
            "leadership_role": null,
            "fec_candidate_id": "H2TX14149",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 14,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.33,
            "votes_with_party_pct": 91.28,
            "votes_against_party_pct": 6.86,
            "ocd_id": "ocd-division/country:us/state:tx/cd:14",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-14--W000814": 0 }
      },
      "15": {
        "members": [
          {
            "API_ID": "D000594",
            "in_office": true,
            "firstName": "Mónica",
            "lastName": "De La Cruz",
            "middleName": null,
            "fullName": "Monica De La Cruz",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/D000594.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "15",
              "chamber": "",
              "hashID": "HOUSE-TX-15--D000594",
              "builtID": "tx--15",
              "externalID": "D000594",
              "geometry": null,
              "geoid": "4815"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1415 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-9901",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://delacruz.house.gov/"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000594.json",
            "govtrack_id": "456943",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1974-11-11",
            "leadership_role": null,
            "fec_candidate_id": "H0TX15124",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 47,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 4.45,
            "votes_with_party_pct": 93.63,
            "votes_against_party_pct": 4.35,
            "ocd_id": "ocd-division/country:us/state:tx/cd:15",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-15--D000594": 0 }
      },
      "16": {
        "members": [
          {
            "API_ID": "E000299",
            "in_office": true,
            "firstName": "Veronica",
            "lastName": "Escobar",
            "middleName": null,
            "fullName": "Veronica Escobar",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/E000299.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "16",
              "chamber": "",
              "hashID": "HOUSE-TX-16--E000299",
              "builtID": "tx--16",
              "externalID": "E000299",
              "geometry": null,
              "geoid": "4816"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2448 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4831",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepEscobar",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://escobar.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/E000299.json",
            "govtrack_id": "412825",
            "cspan_id": null,
            "votesmart_id": "99345",
            "icpsr_id": null,
            "crp_id": "N00041702",
            "google_entity_id": "/g/11ghdw9g9p",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1969-09-15",
            "leadership_role": null,
            "fec_candidate_id": "H8TX16109",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 44,
            "total_present": 5,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 4.17,
            "votes_with_party_pct": 95.51,
            "votes_against_party_pct": 2.5,
            "ocd_id": "ocd-division/country:us/state:tx/cd:16",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-16--E000299": 0 }
      },
      "17": {
        "members": [
          {
            "API_ID": "S000250",
            "in_office": true,
            "firstName": "Pete",
            "lastName": "Sessions",
            "middleName": null,
            "fullName": "Pete Sessions",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S000250.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "17",
              "chamber": "",
              "hashID": "HOUSE-TX-17--S000250",
              "builtID": "tx--17",
              "externalID": "S000250",
              "geometry": null,
              "geoid": "4817"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2204 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6105",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "PeteSessions",
              "youtube": "PeteSessions",
              "instagram": null,
              "facebook": "petesessions",
              "urls": ["https://sessions.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S000250.json",
            "govtrack_id": "400367",
            "cspan_id": "36807",
            "votesmart_id": "288",
            "icpsr_id": "29759",
            "crp_id": "N00005681",
            "google_entity_id": "/m/03v39l",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1955-03-22",
            "leadership_role": null,
            "fec_candidate_id": "H2TX03126",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 58,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 5.49,
            "votes_with_party_pct": 94.27,
            "votes_against_party_pct": 3.68,
            "ocd_id": "ocd-division/country:us/state:tx/cd:17",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-17--S000250": 0 }
      },
      "18": {
        "members": [
          {
            "API_ID": "J000032",
            "in_office": true,
            "firstName": "Sheila",
            "lastName": "Jackson Lee",
            "middleName": null,
            "fullName": "Sheila Jackson Lee",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/J000032.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "18",
              "chamber": "",
              "hashID": "HOUSE-TX-18--J000032",
              "builtID": "tx--18",
              "externalID": "J000032",
              "geometry": null,
              "geoid": "4818"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2314 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3816",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "JacksonLeeTX18",
              "youtube": "TX18SJL",
              "instagram": null,
              "facebook": "CongresswomanSheilaJacksonLee",
              "urls": ["https://jacksonlee.house.gov"],
              "rss_url": "https://jacksonlee.house.gov/rss.xml"
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/J000032.json",
            "govtrack_id": "400199",
            "cspan_id": "36819",
            "votesmart_id": "21692",
            "icpsr_id": "29573",
            "crp_id": "N00005818",
            "google_entity_id": "/m/02fj6h",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1950-01-12",
            "leadership_role": null,
            "fec_candidate_id": "H4TX18054",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "30",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 390,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 36.97,
            "votes_with_party_pct": 94.69,
            "votes_against_party_pct": 2.43,
            "ocd_id": "ocd-division/country:us/state:tx/cd:18",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-18--J000032": 0 }
      },
      "19": {
        "members": [
          {
            "API_ID": "A000375",
            "in_office": true,
            "firstName": "Jodey",
            "lastName": "Arrington",
            "middleName": null,
            "fullName": "Jodey C. Arrington",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/A000375.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "19",
              "chamber": "",
              "hashID": "HOUSE-TX-19--A000375",
              "builtID": "tx--19",
              "externalID": "A000375",
              "geometry": null,
              "geoid": "4819"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1107 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4005",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepArrington",
              "youtube": null,
              "instagram": null,
              "facebook": "JodeyArrington",
              "urls": ["https://arrington.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/A000375.json",
            "govtrack_id": "412726",
            "cspan_id": "1016482",
            "votesmart_id": "155685",
            "icpsr_id": "21700",
            "crp_id": "N00038285",
            "google_entity_id": "/m/0g9_f_1",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1972-03-09",
            "leadership_role": null,
            "fec_candidate_id": "H6TX19099",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 50,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 4.73,
            "votes_with_party_pct": 91.37,
            "votes_against_party_pct": 6.6,
            "ocd_id": "ocd-division/country:us/state:tx/cd:19",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-19--A000375": 0 }
      },
      "20": {
        "members": [
          {
            "API_ID": "C001091",
            "in_office": true,
            "firstName": "Joaquín",
            "lastName": "Castro",
            "middleName": null,
            "fullName": "Joaquin Castro",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001091.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "20",
              "chamber": "",
              "hashID": "HOUSE-TX-20--C001091",
              "builtID": "tx--20",
              "externalID": "C001091",
              "geometry": null,
              "geoid": "4820"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2241 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3236",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "JoaquinCastrotx",
              "youtube": "JoaquinCastroTX",
              "instagram": null,
              "facebook": "326420614138023",
              "urls": ["https://castro.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001091.json",
            "govtrack_id": "412576",
            "cspan_id": "63974",
            "votesmart_id": "49227",
            "icpsr_id": "21362",
            "crp_id": "N00033316",
            "google_entity_id": "/m/0bmclg",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1974-09-16",
            "leadership_role": null,
            "fec_candidate_id": "H2TX35011",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 83,
            "total_present": 4,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 7.87,
            "votes_with_party_pct": 94.59,
            "votes_against_party_pct": 3.43,
            "ocd_id": "ocd-division/country:us/state:tx/cd:20",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-20--C001091": 0 }
      },
      "21": {
        "members": [
          {
            "API_ID": "R000614",
            "in_office": true,
            "firstName": "Chip",
            "lastName": "Roy",
            "middleName": null,
            "fullName": "Chip Roy",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/R000614.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "21",
              "chamber": "",
              "hashID": "HOUSE-TX-21--R000614",
              "builtID": "tx--21",
              "externalID": "R000614",
              "geometry": null,
              "geoid": "4821"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "103 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-4236",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepChipRoy",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://roy.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/R000614.json",
            "govtrack_id": "412826",
            "cspan_id": null,
            "votesmart_id": "177319",
            "icpsr_id": null,
            "crp_id": "N00042268",
            "google_entity_id": "/g/11fghyq4y1",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1972-08-07",
            "leadership_role": null,
            "fec_candidate_id": "H8TX21307",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 22,
            "total_present": 10,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 2.08,
            "votes_with_party_pct": 79.39,
            "votes_against_party_pct": 18.64,
            "ocd_id": "ocd-division/country:us/state:tx/cd:21",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-21--R000614": 0 }
      },
      "22": {
        "members": [
          {
            "API_ID": "N000026",
            "in_office": true,
            "firstName": "Troy",
            "lastName": "Nehls",
            "middleName": null,
            "fullName": "Troy E. Nehls",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/N000026.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "22",
              "chamber": "",
              "hashID": "HOUSE-TX-22--N000026",
              "builtID": "tx--22",
              "externalID": "N000026",
              "geometry": null,
              "geoid": "4822"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1104 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5951",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepTroyNehls",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://nehls.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/N000026.json",
            "govtrack_id": "456848",
            "cspan_id": null,
            "votesmart_id": "188334",
            "icpsr_id": null,
            "crp_id": "N00046067",
            "google_entity_id": "/g/11c580_xf2",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1968-04-07",
            "leadership_role": null,
            "fec_candidate_id": "H0TX22302",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 91,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 8.62,
            "votes_with_party_pct": 88.36,
            "votes_against_party_pct": 9.52,
            "ocd_id": "ocd-division/country:us/state:tx/cd:22",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-22--N000026": 0 }
      },
      "23": {
        "members": [
          {
            "API_ID": "G000594",
            "in_office": true,
            "firstName": "Tony",
            "lastName": "Gonzales",
            "middleName": null,
            "fullName": "Tony Gonzales",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000594.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "23",
              "chamber": "",
              "hashID": "HOUSE-TX-23--G000594",
              "builtID": "tx--23",
              "externalID": "G000594",
              "geometry": null,
              "geoid": "4823"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2244 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4511",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepTonyGonzales",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gonzales.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000594.json",
            "govtrack_id": "456849",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00044592",
            "google_entity_id": "/g/11fs52clcr",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1980-10-10",
            "leadership_role": null,
            "fec_candidate_id": "H0TX35015",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 82,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 7.77,
            "votes_with_party_pct": 89.21,
            "votes_against_party_pct": 8.69,
            "ocd_id": "ocd-division/country:us/state:tx/cd:23",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-23--G000594": 0 }
      },
      "24": {
        "members": [
          {
            "API_ID": "V000134",
            "in_office": true,
            "firstName": "Beth",
            "lastName": "Van Duyne",
            "middleName": null,
            "fullName": "Beth Van Duyne",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/V000134.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "24",
              "chamber": "",
              "hashID": "HOUSE-TX-24--V000134",
              "builtID": "tx--24",
              "externalID": "V000134",
              "geometry": null,
              "geoid": "4824"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1725 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-6605",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBethVanDuyne",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://vanduyne.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/V000134.json",
            "govtrack_id": "456850",
            "cspan_id": null,
            "votesmart_id": "79150",
            "icpsr_id": null,
            "crp_id": "N00045167",
            "google_entity_id": "/m/0k1c6d8",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1970-11-16",
            "leadership_role": null,
            "fec_candidate_id": "H0TX24209",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 9,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 92.4,
            "votes_against_party_pct": 5.65,
            "ocd_id": "ocd-division/country:us/state:tx/cd:24",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-24--V000134": 0 }
      },
      "25": {
        "members": [
          {
            "API_ID": "W000816",
            "in_office": true,
            "firstName": "Roger",
            "lastName": "Williams",
            "middleName": null,
            "fullName": "Roger Williams",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/W000816.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "25",
              "chamber": "",
              "hashID": "HOUSE-TX-25--W000816",
              "builtID": "tx--25",
              "externalID": "W000816",
              "geometry": null,
              "geoid": "4825"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2336 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-9896",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRWilliams",
              "youtube": null,
              "instagram": null,
              "facebook": "RepRogerWilliams",
              "urls": ["https://williams.house.gov"],
              "rss_url": "https://williams.house.gov/rss.xml"
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000816.json",
            "govtrack_id": "412578",
            "cspan_id": "623742",
            "votesmart_id": "50112",
            "icpsr_id": "21364",
            "crp_id": "N00030602",
            "google_entity_id": "/m/08rszb",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1949-09-13",
            "leadership_role": null,
            "fec_candidate_id": "H2TX33040",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 88,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 8.34,
            "votes_with_party_pct": 91.97,
            "votes_against_party_pct": 6.02,
            "ocd_id": "ocd-division/country:us/state:tx/cd:25",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-25--W000816": 0 }
      },
      "26": {
        "members": [
          {
            "API_ID": "B001248",
            "in_office": true,
            "firstName": "Michael",
            "lastName": "Burgess",
            "middleName": "C.",
            "fullName": "Michael C. Burgess",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001248.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "26",
              "chamber": "",
              "hashID": "HOUSE-TX-26--B001248",
              "builtID": "tx--26",
              "externalID": "B001248",
              "geometry": null,
              "geoid": "4826"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2161 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-7772",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "MichaelCBurgess",
              "youtube": "michaelcburgessmd",
              "instagram": null,
              "facebook": "michaelcburgess",
              "urls": ["https://burgess.house.gov"],
              "rss_url": "https://burgess.house.gov/news/rss.aspx"
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001248.json",
            "govtrack_id": "400052",
            "cspan_id": "1003620",
            "votesmart_id": "50120",
            "icpsr_id": "20355",
            "crp_id": "N00025219",
            "google_entity_id": "/m/03tq8_",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1950-12-23",
            "leadership_role": null,
            "fec_candidate_id": "H2TX26093",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "22",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 21,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.99,
            "votes_with_party_pct": 89.94,
            "votes_against_party_pct": 8.19,
            "ocd_id": "ocd-division/country:us/state:tx/cd:26",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-26--B001248": 0 }
      },
      "27": {
        "members": [
          {
            "API_ID": "C001115",
            "in_office": true,
            "firstName": "Michael",
            "lastName": "Cloud",
            "middleName": null,
            "fullName": "Michael Cloud",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001115.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "27",
              "chamber": "",
              "hashID": "HOUSE-TX-27--C001115",
              "builtID": "tx--27",
              "externalID": "C001115",
              "geometry": null,
              "geoid": "4827"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "171 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-7742",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepCloudTX",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://cloud.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001115.json",
            "govtrack_id": "412746",
            "cspan_id": "10322",
            "votesmart_id": "177350",
            "icpsr_id": null,
            "crp_id": "N00041882",
            "google_entity_id": "/g/11f6y3n6vk",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1975-05-13",
            "leadership_role": null,
            "fec_candidate_id": "H8TX27049",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 8,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.76,
            "votes_with_party_pct": 85.49,
            "votes_against_party_pct": 12.56,
            "ocd_id": "ocd-division/country:us/state:tx/cd:27",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-27--C001115": 0 }
      },
      "28": {
        "members": [
          {
            "API_ID": "C001063",
            "in_office": true,
            "firstName": "Henry",
            "lastName": "Cuellar",
            "middleName": null,
            "fullName": "Henry Cuellar",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001063.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "28",
              "chamber": "",
              "hashID": "HOUSE-TX-28--C001063",
              "builtID": "tx--28",
              "externalID": "C001063",
              "geometry": null,
              "geoid": "4828"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2372 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-1640",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepCuellar",
              "youtube": "henrycuellar",
              "instagram": null,
              "facebook": "152569121550",
              "urls": ["https://cuellar.house.gov"],
              "rss_url": "https://cuellar.house.gov/news/rss.aspx"
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001063.json",
            "govtrack_id": "400657",
            "cspan_id": "1013062",
            "votesmart_id": "5486",
            "icpsr_id": "20533",
            "crp_id": "N00024978",
            "google_entity_id": "/m/04clj6",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1955-09-19",
            "leadership_role": null,
            "fec_candidate_id": "H2TX23082",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "20",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 29,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 2.75,
            "votes_with_party_pct": 83.86,
            "votes_against_party_pct": 14.27,
            "ocd_id": "ocd-division/country:us/state:tx/cd:28",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-28--C001063": 0 }
      },
      "29": {
        "members": [
          {
            "API_ID": "G000587",
            "in_office": true,
            "firstName": "Sylvia",
            "lastName": "Garcia",
            "middleName": null,
            "fullName": "Sylvia R. Garcia",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/G000587.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "29",
              "chamber": "",
              "hashID": "HOUSE-TX-29--G000587",
              "builtID": "tx--29",
              "externalID": "G000587",
              "geometry": null,
              "geoid": "4829"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2419 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-1688",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepSylviaGarcia",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://sylviagarcia.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000587.json",
            "govtrack_id": "412827",
            "cspan_id": null,
            "votesmart_id": "49734",
            "icpsr_id": null,
            "crp_id": "N00042282",
            "google_entity_id": "/m/0ryt4bt",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1950-09-06",
            "leadership_role": null,
            "fec_candidate_id": "H8TX29052",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 39,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.69,
            "votes_with_party_pct": 95.44,
            "votes_against_party_pct": 2.58,
            "ocd_id": "ocd-division/country:us/state:tx/cd:29",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-29--G000587": 0 }
      },
      "30": {
        "members": [
          {
            "API_ID": "C001130",
            "in_office": true,
            "firstName": "Jasmine",
            "lastName": "Crockett",
            "middleName": null,
            "fullName": "Jasmine Crockett",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001130.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "30",
              "chamber": "",
              "hashID": "HOUSE-TX-30--C001130",
              "builtID": "tx--30",
              "externalID": "C001130",
              "geometry": null,
              "geoid": "4830"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1616 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-8885",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJasmine",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://crockett.house.gov/"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001130.json",
            "govtrack_id": "456944",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1981-03-29",
            "leadership_role": null,
            "fec_candidate_id": "H2TX30178",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 36,
            "total_present": 4,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.41,
            "votes_with_party_pct": 95.25,
            "votes_against_party_pct": 2.77,
            "ocd_id": "ocd-division/country:us/state:tx/cd:30",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-30--C001130": 0 }
      },
      "31": {
        "members": [
          {
            "API_ID": "C001051",
            "in_office": true,
            "firstName": "John",
            "lastName": "Carter",
            "middleName": null,
            "fullName": "John R. Carter",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001051.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "31",
              "chamber": "",
              "hashID": "HOUSE-TX-31--C001051",
              "builtID": "tx--31",
              "externalID": "C001051",
              "geometry": null,
              "geoid": "4831"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2208 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3864",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "JudgeCarter",
              "youtube": "repjohncarter",
              "instagram": null,
              "facebook": "judgecarter",
              "urls": ["https://carter.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001051.json",
            "govtrack_id": "400068",
            "cspan_id": "1004257",
            "votesmart_id": "49296",
            "icpsr_id": "20356",
            "crp_id": "N00025095",
            "google_entity_id": "/m/04m7rg",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1941-11-06",
            "leadership_role": null,
            "fec_candidate_id": "H2TX31044",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "22",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 156,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 14.79,
            "votes_with_party_pct": 91.03,
            "votes_against_party_pct": 6.81,
            "ocd_id": "ocd-division/country:us/state:tx/cd:31",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-31--C001051": 0 }
      },
      "32": {
        "members": [
          {
            "API_ID": "A000376",
            "in_office": true,
            "firstName": "Colin",
            "lastName": "Allred",
            "middleName": null,
            "fullName": "Colin Z. Allred",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/A000376.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "32",
              "chamber": "",
              "hashID": "HOUSE-TX-32--A000376",
              "builtID": "tx--32",
              "externalID": "A000376",
              "geometry": null,
              "geoid": "4832"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "348 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2231",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepColinAllred",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://allred.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/A000376.json",
            "govtrack_id": "412828",
            "cspan_id": null,
            "votesmart_id": "177357",
            "icpsr_id": null,
            "crp_id": "N00040989",
            "google_entity_id": "/m/03d066b",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1983-04-15",
            "leadership_role": null,
            "fec_candidate_id": "H8TX32098",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 13,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.23,
            "votes_with_party_pct": 91.97,
            "votes_against_party_pct": 6.09,
            "ocd_id": "ocd-division/country:us/state:tx/cd:32",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-32--A000376": 0 }
      },
      "33": {
        "members": [
          {
            "API_ID": "V000131",
            "in_office": true,
            "firstName": "Marc",
            "lastName": "Veasey",
            "middleName": null,
            "fullName": "Marc A. Veasey",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/V000131.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "33",
              "chamber": "",
              "hashID": "HOUSE-TX-33--V000131",
              "builtID": "tx--33",
              "externalID": "V000131",
              "geometry": null,
              "geoid": "4833"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2348 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-9897",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepVeasey",
              "youtube": "marcveasey",
              "instagram": null,
              "facebook": "CongressmanMarcVeasey",
              "urls": ["https://veasey.house.gov"],
              "rss_url": "https://veasey.house.gov/rss.xml"
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/V000131.json",
            "govtrack_id": "412579",
            "cspan_id": "79466",
            "votesmart_id": "49671",
            "icpsr_id": "21365",
            "crp_id": "N00033839",
            "google_entity_id": "/m/0kmnfw5",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1971-01-03",
            "leadership_role": null,
            "fec_candidate_id": "H2TX33073",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 15,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.42,
            "votes_with_party_pct": 93.5,
            "votes_against_party_pct": 4.66,
            "ocd_id": "ocd-division/country:us/state:tx/cd:33",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-33--V000131": 0 }
      },
      "34": {
        "members": [
          {
            "API_ID": "G000581",
            "in_office": true,
            "firstName": "Vicente",
            "lastName": "Gonzalez",
            "middleName": null,
            "fullName": "Vicente Gonzalez",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/G000581.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "34",
              "chamber": "",
              "hashID": "HOUSE-TX-34--G000581",
              "builtID": "tx--34",
              "externalID": "G000581",
              "geometry": null,
              "geoid": "4834"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "154 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2531",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGonzalez",
              "youtube": null,
              "instagram": null,
              "facebook": "USCongressmanVicenteGonzalez",
              "urls": ["https://gonzalez.house.gov"],
              "rss_url": "https://gonzalez.house.gov/rss.xml"
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000581.json",
            "govtrack_id": "412725",
            "cspan_id": "103568",
            "votesmart_id": "166483",
            "icpsr_id": "21722",
            "crp_id": "N00038809",
            "google_entity_id": "/g/11c266m1z7",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1967-09-04",
            "leadership_role": null,
            "fec_candidate_id": "H6TX15162",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 43,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 4.07,
            "votes_with_party_pct": 88.17,
            "votes_against_party_pct": 10.04,
            "ocd_id": "ocd-division/country:us/state:tx/cd:34",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-34--G000581": 0 }
      },
      "35": {
        "members": [
          {
            "API_ID": "C001131",
            "in_office": true,
            "firstName": "Greg",
            "lastName": "Casar",
            "middleName": null,
            "fullName": "Greg Casar",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001131.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "35",
              "chamber": "",
              "hashID": "HOUSE-TX-35--C001131",
              "builtID": "tx--35",
              "externalID": "C001131",
              "geometry": null,
              "geoid": "4835"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1339 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5645",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepCasar",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://casar.house.gov/"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001131.json",
            "govtrack_id": "456945",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1989-05-04",
            "leadership_role": null,
            "fec_candidate_id": "H2TX35144",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 15,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.42,
            "votes_with_party_pct": 91.38,
            "votes_against_party_pct": 6.69,
            "ocd_id": "ocd-division/country:us/state:tx/cd:35",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-35--C001131": 0 }
      },
      "36": {
        "members": [
          {
            "API_ID": "B001291",
            "in_office": true,
            "firstName": "Brian",
            "lastName": "Babin",
            "middleName": null,
            "fullName": "Brian Babin",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/B001291.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "36",
              "chamber": "",
              "hashID": "HOUSE-TX-36--B001291",
              "builtID": "tx--36",
              "externalID": "B001291",
              "geometry": null,
              "geoid": "4836"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2236 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-1555",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBrianBabin",
              "youtube": null,
              "instagram": null,
              "facebook": "RepBrianBabin",
              "urls": ["https://babin.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001291.json",
            "govtrack_id": "412655",
            "cspan_id": "44883",
            "votesmart_id": "360",
            "icpsr_id": "21551",
            "crp_id": "N00005736",
            "google_entity_id": "/m/010qgj35",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1948-03-23",
            "leadership_role": null,
            "fec_candidate_id": "H6TX02079",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 28,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.65,
            "votes_with_party_pct": 92.96,
            "votes_against_party_pct": 5.16,
            "ocd_id": "ocd-division/country:us/state:tx/cd:36",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-36--B001291": 0 }
      },
      "37": {
        "members": [
          {
            "API_ID": "D000399",
            "in_office": true,
            "firstName": "Lloyd",
            "lastName": "Doggett",
            "middleName": null,
            "fullName": "Lloyd Doggett",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/D000399.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "37",
              "chamber": "",
              "hashID": "HOUSE-TX-37--D000399",
              "builtID": "tx--37",
              "externalID": "D000399",
              "geometry": null,
              "geoid": "4837"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2307 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4865",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLloydDoggett",
              "youtube": "doggett",
              "instagram": null,
              "facebook": "lloyddoggett",
              "urls": ["https://doggett.house.gov"],
              "rss_url": "https://doggett.house.gov/rss.xml"
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000399.json",
            "govtrack_id": "400111",
            "cspan_id": "36810",
            "votesmart_id": "21689",
            "icpsr_id": "29571",
            "crp_id": "N00006023",
            "google_entity_id": "/m/03flzn",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1946-10-06",
            "leadership_role": null,
            "fec_candidate_id": "H4TX10028",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "30",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 33,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 3.13,
            "votes_with_party_pct": 94.37,
            "votes_against_party_pct": 3.75,
            "ocd_id": "ocd-division/country:us/state:tx/cd:37",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-37--D000399": 0 }
      },
      "38": {
        "members": [
          {
            "API_ID": "H001095",
            "in_office": true,
            "firstName": "Wesley",
            "lastName": "Hunt",
            "middleName": null,
            "fullName": "Wesley Hunt",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/H001095.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "38",
              "chamber": "",
              "hashID": "HOUSE-TX-38--H001095",
              "builtID": "tx--38",
              "externalID": "H001095",
              "geometry": null,
              "geoid": "4838"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1520 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5646",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepWesleyHunt",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://hunt.house.gov/"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001095.json",
            "govtrack_id": "456946",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1981-11-13",
            "leadership_role": null,
            "fec_candidate_id": "H0TX07170",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 179,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 16.95,
            "votes_with_party_pct": 89.22,
            "votes_against_party_pct": 8.92,
            "ocd_id": "ocd-division/country:us/state:tx/cd:38",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-38--H001095": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "C001120",
            "in_office": true,
            "firstName": "Dan",
            "lastName": "Crenshaw",
            "middleName": null,
            "fullName": "Dan Crenshaw",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001120.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-TX-02--C001120",
              "builtID": "tx--02",
              "externalID": "C001120",
              "geometry": null,
              "geoid": "4802"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "248 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-6565",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDanCrenshaw",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://crenshaw.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001120.json",
            "govtrack_id": "412820",
            "cspan_id": null,
            "votesmart_id": "177270",
            "icpsr_id": null,
            "crp_id": "N00042224",
            "google_entity_id": "/g/11gtg5t4wg",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1984-03-14",
            "leadership_role": null,
            "fec_candidate_id": "H8TX02166",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 90,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 8.52,
            "votes_with_party_pct": 90.49,
            "votes_against_party_pct": 7.4,
            "ocd_id": "ocd-division/country:us/state:tx/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-02--C001120": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "E000071",
            "in_office": true,
            "firstName": "Jake",
            "lastName": "Ellzey",
            "middleName": null,
            "fullName": "Jake Ellzey",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/E000071.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-TX-06--E000071",
              "builtID": "tx--06",
              "externalID": "E000071",
              "geometry": null,
              "geoid": "4806"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1721 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2002",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepEllzey",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://ellzey.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/E000071.json",
            "govtrack_id": "456862",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00042243",
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1970-01-24",
            "leadership_role": null,
            "fec_candidate_id": "H8TX06266",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 4,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.38,
            "votes_with_party_pct": 90.11,
            "votes_against_party_pct": 7.95,
            "ocd_id": "ocd-division/country:us/state:tx/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-06--E000071": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "F000246",
            "in_office": true,
            "firstName": "Pat",
            "lastName": "Fallon",
            "middleName": null,
            "fullName": "Pat Fallon",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/F000246.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-TX-04--F000246",
              "builtID": "tx--04",
              "externalID": "F000246",
              "geometry": null,
              "geoid": "4804"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2416 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-6673",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepPatFallon",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://fallon.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000246.json",
            "govtrack_id": "456845",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00047264",
            "google_entity_id": "/m/0100nvdw",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1967-12-19",
            "leadership_role": null,
            "fec_candidate_id": "H0TX04219",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 26,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.46,
            "votes_with_party_pct": 92.07,
            "votes_against_party_pct": 5.95,
            "ocd_id": "ocd-division/country:us/state:tx/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-04--F000246": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "F000468",
            "in_office": true,
            "firstName": "Lizzie",
            "lastName": "Fletcher",
            "middleName": null,
            "fullName": "Lizzie Fletcher",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/F000468.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-TX-07--F000468",
              "builtID": "tx--07",
              "externalID": "F000468",
              "geometry": null,
              "geoid": "4807"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "346 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2571",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepFletcher",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://fletcher.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000468.json",
            "govtrack_id": "412824",
            "cspan_id": null,
            "votesmart_id": "177031",
            "icpsr_id": null,
            "crp_id": "N00041194",
            "google_entity_id": "/g/11gzqssv7p",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1975-02-13",
            "leadership_role": null,
            "fec_candidate_id": "H8TX07140",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 17,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.61,
            "votes_with_party_pct": 92.33,
            "votes_against_party_pct": 5.73,
            "ocd_id": "ocd-division/country:us/state:tx/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-07--F000468": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "G000589",
            "in_office": true,
            "firstName": "Lance",
            "lastName": "Gooden",
            "middleName": null,
            "fullName": "Lance Gooden",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000589.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-TX-05--G000589",
              "builtID": "tx--05",
              "externalID": "G000589",
              "geometry": null,
              "geoid": "4805"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2431 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3484",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLanceGooden",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gooden.house.gov"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000589.json",
            "govtrack_id": "412822",
            "cspan_id": null,
            "votesmart_id": "177282",
            "icpsr_id": null,
            "crp_id": "N00042237",
            "google_entity_id": "/m/0jkx4bp",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1982-12-01",
            "leadership_role": null,
            "fec_candidate_id": "H8TX05144",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 14,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.33,
            "votes_with_party_pct": 91.38,
            "votes_against_party_pct": 6.66,
            "ocd_id": "ocd-division/country:us/state:tx/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-05--G000589": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "G000553",
            "in_office": true,
            "firstName": "Al",
            "lastName": "Green",
            "middleName": null,
            "fullName": "Al Green",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/G000553.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-TX-09--G000553",
              "builtID": "tx--09",
              "externalID": "G000553",
              "geometry": null,
              "geoid": "4809"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2347 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-7508",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAlGreen",
              "youtube": "RepAlGreen",
              "instagram": null,
              "facebook": "repalgreen",
              "urls": ["https://algreen.house.gov"],
              "rss_url": "https://algreen.house.gov/rss.xml"
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000553.json",
            "govtrack_id": "400653",
            "cspan_id": "1012969",
            "votesmart_id": "49680",
            "icpsr_id": "20529",
            "crp_id": "N00026686",
            "google_entity_id": "/m/02kn0_",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1947-09-01",
            "leadership_role": null,
            "fec_candidate_id": "H4TX09095",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "20",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 30,
            "total_present": 3,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.84,
            "votes_with_party_pct": 95.18,
            "votes_against_party_pct": 2.95,
            "ocd_id": "ocd-division/country:us/state:tx/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-09--G000553": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "L000603",
            "in_office": true,
            "firstName": "Morgan",
            "lastName": "Luttrell",
            "middleName": null,
            "fullName": "Morgan Luttrell",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/L000603.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-TX-08--L000603",
              "builtID": "tx--08",
              "externalID": "L000603",
              "geometry": null,
              "geoid": "4808"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1320 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4901",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepLuttrell",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://luttrell.house.gov/"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000603.json",
            "govtrack_id": "456942",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1975-11-07",
            "leadership_role": null,
            "fec_candidate_id": "H2TX08182",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 21,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.99,
            "votes_with_party_pct": 91.22,
            "votes_against_party_pct": 6.8,
            "ocd_id": "ocd-division/country:us/state:tx/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-08--L000603": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "M001224",
            "in_office": true,
            "firstName": "Nathaniel",
            "lastName": "Moran",
            "middleName": null,
            "fullName": "Nathaniel Moran",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001224.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-TX-01--M001224",
              "builtID": "tx--01",
              "externalID": "M001224",
              "geometry": null,
              "geoid": "4801"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1541 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3035",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://moran.house.gov/"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001224.json",
            "govtrack_id": "456940",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1974-07-22",
            "leadership_role": null,
            "fec_candidate_id": "H2TX01112",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 11,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.04,
            "votes_with_party_pct": 91.61,
            "votes_against_party_pct": 6.44,
            "ocd_id": "ocd-division/country:us/state:tx/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-01--M001224": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "S001224",
            "in_office": true,
            "firstName": "Keith",
            "lastName": "Self",
            "middleName": null,
            "fullName": "Keith Self",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S001224.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-TX-03--S001224",
              "builtID": "tx--03",
              "externalID": "S001224",
              "geometry": null,
              "geoid": "4803"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1113 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4201",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://keithself.house.gov/"],
              "rss_url": null
            },
            "title": "TX House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001224.json",
            "govtrack_id": "456941",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1953-03-20",
            "leadership_role": null,
            "fec_candidate_id": "H2TX03290",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 9,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 86.84,
            "votes_against_party_pct": 11.21,
            "ocd_id": "ocd-division/country:us/state:tx/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-TX-03--S001224": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/26617394-4276-4aa7-878c-d20552b260a4",
            "firstName": "Gary Wayne",
            "lastName": "VanDeaver",
            "fullName": "Gary VanDeaver",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2540.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-TX-1-lower-ocd-person-26617394-4276-4aa7-878c-d20552b260a4",
              "builtID": "tx-lower-1",
              "externalID": "ocd-person/26617394-4276-4aa7-878c-d20552b260a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "gary.vandeaver@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "710 James Bowie Drive, New Boston, TX 75570",
              "phone1": null,
              "phone2": "903-628-0361",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=1"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-1-lower-ocd-person-26617394-4276-4aa7-878c-d20552b260a4": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/127dce12-cb90-4469-a18a-3e3c7ad6bc9a",
            "firstName": "Jill Ashmore",
            "lastName": "Dutton",
            "fullName": "Jill Dutton",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4390.jpg?v=88.39",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-TX-2-lower-ocd-person-127dce12-cb90-4469-a18a-3e3c7ad6bc9a",
              "builtID": "tx-lower-2",
              "externalID": "ocd-person/127dce12-cb90-4469-a18a-3e3c7ad6bc9a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jill.dutton@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2410 Lee St. Suite 201, Greenville, TX 75402",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=2"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-2-lower-ocd-person-127dce12-cb90-4469-a18a-3e3c7ad6bc9a": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/33f871c9-15a6-4094-9181-ff822cd9eca4",
            "firstName": "Cecil Ivan",
            "lastName": "Bell",
            "fullName": "Cecil Bell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2335.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-TX-3-lower-ocd-person-33f871c9-15a6-4094-9181-ff822cd9eca4",
              "builtID": "tx-lower-3",
              "externalID": "ocd-person/33f871c9-15a6-4094-9181-ff822cd9eca4",
              "geometry": null
            },
            "contactInfo": {
              "email": "cecil.bell@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "18230 FM 1488 Suite 302, Magnolia, TX 77354",
              "phone1": null,
              "phone2": "281-259-3700",
              "fax1": null,
              "fax2": "281-259-3706",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=3"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-3-lower-ocd-person-33f871c9-15a6-4094-9181-ff822cd9eca4": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/fe47f6d5-2db3-495b-8da6-5cd12e0dc677",
            "firstName": "Gregory Keith",
            "lastName": "Bell",
            "fullName": "Keith Bell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3695.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-TX-4-lower-ocd-person-fe47f6d5-2db3-495b-8da6-5cd12e0dc677",
              "builtID": "tx-lower-4",
              "externalID": "ocd-person/fe47f6d5-2db3-495b-8da6-5cd12e0dc677",
              "geometry": null
            },
            "contactInfo": {
              "email": "keith.bell@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "771 E. Highway 80 Suite 208, Forney, TX 75126",
              "phone1": null,
              "phone2": "972-552-5573",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=4",
                "https://house.texas.gov/members/3695/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-4-lower-ocd-person-fe47f6d5-2db3-495b-8da6-5cd12e0dc677": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/43bbf34a-6bc4-41f9-9bdc-3e944c5b8495",
            "firstName": "Cole",
            "lastName": "Hefner",
            "fullName": "Cole Hefner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3505.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-TX-5-lower-ocd-person-43bbf34a-6bc4-41f9-9bdc-3e944c5b8495",
              "builtID": "tx-lower-5",
              "externalID": "ocd-person/43bbf34a-6bc4-41f9-9bdc-3e944c5b8495",
              "geometry": null
            },
            "contactInfo": {
              "email": "cole.hefner@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "520 N. Main St. Suite A-3, Lindale, TX 75711",
              "phone1": null,
              "phone2": "903-882-3254",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=5"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-5-lower-ocd-person-43bbf34a-6bc4-41f9-9bdc-3e944c5b8495": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/39511354-7239-40f6-b431-5ac53ef3c462",
            "firstName": "Matthew Ray",
            "lastName": "Schaefer",
            "fullName": "Matt Schaefer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2725.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-TX-6-lower-ocd-person-39511354-7239-40f6-b431-5ac53ef3c462",
              "builtID": "tx-lower-6",
              "externalID": "ocd-person/39511354-7239-40f6-b431-5ac53ef3c462",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.schaefer@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "200 E. Ferguson St., Suite 435, Tyler, TX 75702",
              "phone1": null,
              "phone2": "903-592-0900",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=6",
                "https://house.texas.gov/members/2725"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-6-lower-ocd-person-39511354-7239-40f6-b431-5ac53ef3c462": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/37886d81-901f-43b4-841b-f3ffbfeeb257",
            "firstName": "James Wallace",
            "lastName": "Dean",
            "fullName": "Jay Dean",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3515.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-TX-7-lower-ocd-person-37886d81-901f-43b4-841b-f3ffbfeeb257",
              "builtID": "tx-lower-7",
              "externalID": "ocd-person/37886d81-901f-43b4-841b-f3ffbfeeb257",
              "geometry": null
            },
            "contactInfo": {
              "email": "jay.dean@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "101 E. Methvin Suite 103, Longview, TX 75601",
              "phone1": null,
              "phone2": "903-238-8452",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=7",
                "https://house.texas.gov/members/7"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-7-lower-ocd-person-37886d81-901f-43b4-841b-f3ffbfeeb257": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/baafecd4-6f74-4a97-b564-691c1e938291",
            "firstName": "Cody",
            "lastName": "Harris",
            "fullName": "Cody Harris",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3580.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-TX-8-lower-ocd-person-baafecd4-6f74-4a97-b564-691c1e938291",
              "builtID": "tx-lower-8",
              "externalID": "ocd-person/baafecd4-6f74-4a97-b564-691c1e938291",
              "geometry": null
            },
            "contactInfo": {
              "email": "cody.harris@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "519 N. Sycamore, Palestine, TX 75801",
              "phone1": null,
              "phone2": "903-731-4005",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=8"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-8-lower-ocd-person-baafecd4-6f74-4a97-b564-691c1e938291": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/68f05872-16c8-4d3a-ab5f-ea667ca52b27",
            "firstName": "Trenton E.",
            "lastName": "Ashby",
            "fullName": "Trent Ashby",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2330.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-TX-9-lower-ocd-person-68f05872-16c8-4d3a-ab5f-ea667ca52b27",
              "builtID": "tx-lower-9",
              "externalID": "ocd-person/68f05872-16c8-4d3a-ab5f-ea667ca52b27",
              "geometry": null
            },
            "contactInfo": {
              "email": "trent.ashby@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2915 Atkinson Drive, Lufkin, TX 75901",
              "phone1": null,
              "phone2": "936-634-2762",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=57",
                "https://house.texas.gov/members/member-page/?district=09",
                "https://house.texas.gov/members/2330"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-9-lower-ocd-person-68f05872-16c8-4d3a-ab5f-ea667ca52b27": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/b1bf46ac-e99e-49f6-8f40-cf07aca12187",
            "firstName": "Brian Edward",
            "lastName": "Harrison",
            "fullName": "Brian Harrison",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4085.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-TX-10-lower-ocd-person-b1bf46ac-e99e-49f6-8f40-cf07aca12187",
              "builtID": "tx-lower-10",
              "externalID": "ocd-person/b1bf46ac-e99e-49f6-8f40-cf07aca12187",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.harrison@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "100 N. College St., Suite 306, Waxahachie, TX 75165",
              "phone1": null,
              "phone2": "972-923-0264",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=10",
                "https://house.texas.gov/members/4085"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-10-lower-ocd-person-b1bf46ac-e99e-49f6-8f40-cf07aca12187": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/9a249b0a-a69f-4ca8-b031-7a991baecb99",
            "firstName": "Travis Paul",
            "lastName": "Clardy",
            "fullName": "Travis Clardy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2355.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-TX-11-lower-ocd-person-9a249b0a-a69f-4ca8-b031-7a991baecb99",
              "builtID": "tx-lower-11",
              "externalID": "ocd-person/9a249b0a-a69f-4ca8-b031-7a991baecb99",
              "geometry": null
            },
            "contactInfo": {
              "email": "travis.clardy@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 310, 202 E. Pilar St., Nacogdoches, TX 75961",
              "phone1": null,
              "phone2": "936-560-3982",
              "fax1": null,
              "fax2": "936-564-0051",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legdir.capitol.texas.gov/MemberInfo.aspx?Chamber=H&Code=A2355",
                "https://house.texas.gov/members/member-page/?district=11"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-11-lower-ocd-person-9a249b0a-a69f-4ca8-b031-7a991baecb99": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/28512987-3275-4a5d-8af5-b8f3b7be2457",
            "firstName": "Kyle Jerome",
            "lastName": "Kacal",
            "fullName": "Kyle Kacal",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2445.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-TX-12-lower-ocd-person-28512987-3275-4a5d-8af5-b8f3b7be2457",
              "builtID": "tx-lower-12",
              "externalID": "ocd-person/28512987-3275-4a5d-8af5-b8f3b7be2457",
              "geometry": null
            },
            "contactInfo": {
              "email": "kyle.kacal@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3000 Briarcrest Drive Suite 203, Bryan, TX 77802",
              "phone1": null,
              "phone2": "979-774-7276",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=12"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-12-lower-ocd-person-28512987-3275-4a5d-8af5-b8f3b7be2457": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/aa0d12c3-c318-4c97-a0d4-c63795ec50d9",
            "firstName": "Angelia D.",
            "lastName": "Orr",
            "fullName": "Angelia Orr",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4340.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-TX-13-lower-ocd-person-aa0d12c3-c318-4c97-a0d4-c63795ec50d9",
              "builtID": "tx-lower-13",
              "externalID": "ocd-person/aa0d12c3-c318-4c97-a0d4-c63795ec50d9",
              "geometry": null
            },
            "contactInfo": {
              "email": "angelia.orr@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "214 E. Elm St., Hillsboro, TX 76645",
              "phone1": null,
              "phone2": "254-580-9008",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=13"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-13-lower-ocd-person-aa0d12c3-c318-4c97-a0d4-c63795ec50d9": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/6613aff4-3874-44c6-960b-121eb6b0b2d2",
            "firstName": "John Nathan",
            "lastName": "Raney",
            "fullName": "John Raney",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2315.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-TX-14-lower-ocd-person-6613aff4-3874-44c6-960b-121eb6b0b2d2",
              "builtID": "tx-lower-14",
              "externalID": "ocd-person/6613aff4-3874-44c6-960b-121eb6b0b2d2",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.raney@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4103 S. Texas Ave. Suite 103, Bryan, TX 77802",
              "phone1": null,
              "phone2": "979-260-5040",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=14"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-14-lower-ocd-person-6613aff4-3874-44c6-960b-121eb6b0b2d2": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/6c80914d-b07b-4d27-95e2-0e5aa2b0c7d9",
            "firstName": "Steven Hixson",
            "lastName": "Toth",
            "fullName": "Steve Toth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2825.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-TX-15-lower-ocd-person-6c80914d-b07b-4d27-95e2-0e5aa2b0c7d9",
              "builtID": "tx-lower-15",
              "externalID": "ocd-person/6c80914d-b07b-4d27-95e2-0e5aa2b0c7d9",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.toth@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "25700 Interstate Highway 45 Suite 100, Spring, TX 77386",
              "phone1": null,
              "phone2": "346-220-0300",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=15"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-15-lower-ocd-person-6c80914d-b07b-4d27-95e2-0e5aa2b0c7d9": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/e7a379ec-9de2-48a2-918b-5ba58e1fd3f3",
            "firstName": "Will T.",
            "lastName": "Metcalf",
            "fullName": "Will Metcalf",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2900.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-TX-16-lower-ocd-person-e7a379ec-9de2-48a2-918b-5ba58e1fd3f3",
              "builtID": "tx-lower-16",
              "externalID": "ocd-person/e7a379ec-9de2-48a2-918b-5ba58e1fd3f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "will.metcalf@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "100 Nugent St., Conroe, TX 77301",
              "phone1": null,
              "phone2": "936-539-0068",
              "fax1": null,
              "fax2": "936-539-0066",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=16"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-16-lower-ocd-person-e7a379ec-9de2-48a2-918b-5ba58e1fd3f3": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/97fc1e34-35fb-470f-a098-4ba8f0c3c8d4",
            "firstName": "Stan",
            "lastName": "Gerdes",
            "fullName": "Stan Gerdes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4195.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-TX-17-lower-ocd-person-97fc1e34-35fb-470f-a098-4ba8f0c3c8d4",
              "builtID": "tx-lower-17",
              "externalID": "ocd-person/97fc1e34-35fb-470f-a098-4ba8f0c3c8d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "stan.gerdes@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1011 Alley A St., #B, Bastrop, TX 78602",
              "phone1": null,
              "phone2": "512-321-3720",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=17"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-17-lower-ocd-person-97fc1e34-35fb-470f-a098-4ba8f0c3c8d4": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/0b54bd1c-fe8a-4af1-9627-ae9371a3ff30",
            "firstName": "Ernest James",
            "lastName": "Bailes",
            "fullName": "Ernest Bailes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3200.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-TX-18-lower-ocd-person-0b54bd1c-fe8a-4af1-9627-ae9371a3ff30",
              "builtID": "tx-lower-18",
              "externalID": "ocd-person/0b54bd1c-fe8a-4af1-9627-ae9371a3ff30",
              "geometry": null
            },
            "contactInfo": {
              "email": "ernest.bailes@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1116 10501 Highway 150, Suite B, Shepherd, TX 77371",
              "phone1": null,
              "phone2": "936-628-6687",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=18"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-18-lower-ocd-person-0b54bd1c-fe8a-4af1-9627-ae9371a3ff30": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/34590156-1109-4ab6-84bc-bbe07764f365",
            "firstName": "Ellen",
            "lastName": "Troxclair",
            "fullName": "Ellen Troxclair",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4385.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-TX-19-lower-ocd-person-34590156-1109-4ab6-84bc-bbe07764f365",
              "builtID": "tx-lower-19",
              "externalID": "ocd-person/34590156-1109-4ab6-84bc-bbe07764f365",
              "geometry": null
            },
            "contactInfo": {
              "email": "ellen.troxclair@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "624 N. Main St. Suite 102, Boerne, TX 78006",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=19",
                "https://house.texas.gov/members/19"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-19-lower-ocd-person-34590156-1109-4ab6-84bc-bbe07764f365": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/ec19bc97-3146-4100-8294-329b88aec0e8",
            "firstName": "Terry M.",
            "lastName": "Wilson",
            "fullName": "Terry Wilson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3525.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-TX-20-lower-ocd-person-ec19bc97-3146-4100-8294-329b88aec0e8",
              "builtID": "tx-lower-20",
              "externalID": "ocd-person/ec19bc97-3146-4100-8294-329b88aec0e8",
              "geometry": null
            },
            "contactInfo": {
              "email": "terry.wilson@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "710 Main St. Suite 242, Georgetown, TX 78626",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=20"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-20-lower-ocd-person-ec19bc97-3146-4100-8294-329b88aec0e8": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/7952d7d7-0fe5-4712-9967-6cb9bcedff05",
            "firstName": "Matthew McDade",
            "lastName": "Phelan",
            "fullName": "Dade Phelan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2905.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-TX-21-lower-ocd-person-7952d7d7-0fe5-4712-9967-6cb9bcedff05",
              "builtID": "tx-lower-21",
              "externalID": "ocd-person/7952d7d7-0fe5-4712-9967-6cb9bcedff05",
              "geometry": null
            },
            "contactInfo": {
              "email": "dade.phelan@speaker.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "812 N. 16th St., Orange, TX 77630",
              "phone1": null,
              "phone2": "409-745-2777",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/speaker/",
                "https://legdir.capitol.texas.gov/MemberInfo.aspx?Chamber=H&Code=A2905",
                "https://house.texas.gov/members/member-page/?district=21",
                "https://house.texas.gov/members/speaker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-21-lower-ocd-person-7952d7d7-0fe5-4712-9967-6cb9bcedff05": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/26090510-8782-4d64-b981-3d1cde573090",
            "firstName": "Christian",
            "lastName": "Manuel Hayes",
            "fullName": "Christian Manuel",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4255.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-TX-22-lower-ocd-person-26090510-8782-4d64-b981-3d1cde573090",
              "builtID": "tx-lower-22",
              "externalID": "ocd-person/26090510-8782-4d64-b981-3d1cde573090",
              "geometry": null
            },
            "contactInfo": {
              "email": "christian.manuel@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2300 Farm to Market 365 Suite 360, Nederland, TX 77627",
              "phone1": null,
              "phone2": "409-237-5190",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=22"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-22-lower-ocd-person-26090510-8782-4d64-b981-3d1cde573090": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/cee6cb7a-8d88-4c34-be27-dd10d1f31768",
            "firstName": "Teresa Susan",
            "lastName": "Leo-Wilson",
            "fullName": "Terri Leo-Wilson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4290.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-TX-23-lower-ocd-person-cee6cb7a-8d88-4c34-be27-dd10d1f31768",
              "builtID": "tx-lower-23",
              "externalID": "ocd-person/cee6cb7a-8d88-4c34-be27-dd10d1f31768",
              "geometry": null
            },
            "contactInfo": {
              "email": "terri.leo-wilson@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "305 21st St. Suite 241, Galveston, TX 77550",
              "phone1": null,
              "phone2": "409-762-0202",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=23"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-23-lower-ocd-person-cee6cb7a-8d88-4c34-be27-dd10d1f31768": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/d0fbfb15-2f0c-4167-b8b4-d6135bbb6616",
            "firstName": "James Gregory",
            "lastName": "Bonnen",
            "fullName": "Greg Bonnen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2875.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-TX-24-lower-ocd-person-d0fbfb15-2f0c-4167-b8b4-d6135bbb6616",
              "builtID": "tx-lower-24",
              "externalID": "ocd-person/d0fbfb15-2f0c-4167-b8b4-d6135bbb6616",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.bonnen@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "174 Calder Road Suite 1000, League City, TX 77573",
              "phone1": null,
              "phone2": "281-338-0924",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=24"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-24-lower-ocd-person-d0fbfb15-2f0c-4167-b8b4-d6135bbb6616": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/3194ee5e-f4d8-4449-8ff6-ec47c0822a3d",
            "firstName": "Cody Thane",
            "lastName": "Vasut",
            "fullName": "Cody Vasut",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4065.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-TX-25-lower-ocd-person-3194ee5e-f4d8-4449-8ff6-ec47c0822a3d",
              "builtID": "tx-lower-25",
              "externalID": "ocd-person/3194ee5e-f4d8-4449-8ff6-ec47c0822a3d",
              "geometry": null
            },
            "contactInfo": {
              "email": "cody.vasut@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "222 N. Velasco St. Suite 25, Angleton, TX 77515",
              "phone1": null,
              "phone2": "979-848-1770",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=25"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-25-lower-ocd-person-3194ee5e-f4d8-4449-8ff6-ec47c0822a3d": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/8c82ede2-a4c6-4625-92ca-2b24c370de17",
            "firstName": "Jacey Ray",
            "lastName": "Jetton",
            "fullName": "Jacey Jetton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3980.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-TX-26-lower-ocd-person-8c82ede2-a4c6-4625-92ca-2b24c370de17",
              "builtID": "tx-lower-26",
              "externalID": "ocd-person/8c82ede2-a4c6-4625-92ca-2b24c370de17",
              "geometry": null
            },
            "contactInfo": {
              "email": "jacey.jetton@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "22333 Grand Corner Drive Suite 151, Katy, TX 77494",
              "phone1": null,
              "phone2": "281-392-5898",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=26"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-26-lower-ocd-person-8c82ede2-a4c6-4625-92ca-2b24c370de17": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/72bad853-b403-49c2-9a99-32c5fb3222c7",
            "firstName": "Ron",
            "lastName": "Reynolds",
            "fullName": "Ron Reynolds",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/2040.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-TX-27-lower-ocd-person-72bad853-b403-49c2-9a99-32c5fb3222c7",
              "builtID": "tx-lower-27",
              "externalID": "ocd-person/72bad853-b403-49c2-9a99-32c5fb3222c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "ron.reynolds@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2440 Texas Parkway Suite 102, Missouri City, TX 77489",
              "phone1": null,
              "phone2": "281-208-3574",
              "fax1": null,
              "fax2": "281-208-3696",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=27"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-27-lower-ocd-person-72bad853-b403-49c2-9a99-32c5fb3222c7": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/5ff88048-ebd8-4ac3-af9c-d02e1305ef37",
            "firstName": "Gary Wilton",
            "lastName": "Gates",
            "fullName": "Gary Gates",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3920.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-TX-28-lower-ocd-person-5ff88048-ebd8-4ac3-af9c-d02e1305ef37",
              "builtID": "tx-lower-28",
              "externalID": "ocd-person/5ff88048-ebd8-4ac3-af9c-d02e1305ef37",
              "geometry": null
            },
            "contactInfo": {
              "email": "gary.gates@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=28"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-28-lower-ocd-person-5ff88048-ebd8-4ac3-af9c-d02e1305ef37": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/9fb69a65-467d-491c-9663-3892583795c5",
            "firstName": "Edward Lynn",
            "lastName": "Thompson",
            "fullName": "Ed Thompson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2815.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-TX-29-lower-ocd-person-9fb69a65-467d-491c-9663-3892583795c5",
              "builtID": "tx-lower-29",
              "externalID": "ocd-person/9fb69a65-467d-491c-9663-3892583795c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "ed.thompson@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2341 N. Galveston Ave. Suite 120, Pearland, TX 77581",
              "phone1": null,
              "phone2": "281-485-6565",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=29",
                "https://house.texas.gov/members/29"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-29-lower-ocd-person-9fb69a65-467d-491c-9663-3892583795c5": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/54769259-df18-4363-818e-127341188531",
            "firstName": "Geanie Williams",
            "lastName": "Morrison",
            "fullName": "Geanie Morrison",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3870.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-TX-30-lower-ocd-person-54769259-df18-4363-818e-127341188531",
              "builtID": "tx-lower-30",
              "externalID": "ocd-person/54769259-df18-4363-818e-127341188531",
              "geometry": null
            },
            "contactInfo": {
              "email": "geanie.morrison@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "311 E. Constitution St. Suite 100, Victoria, TX 77901",
              "phone1": null,
              "phone2": "361-572-0196",
              "fax1": null,
              "fax2": "361-576-0747",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=30"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-30-lower-ocd-person-54769259-df18-4363-818e-127341188531": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/f0ede995-2253-4f7b-a922-6da34e4de194",
            "firstName": "Ryan Anthony",
            "lastName": "Guillen",
            "fullName": "Ryan Guillen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3045.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-TX-31-lower-ocd-person-f0ede995-2253-4f7b-a922-6da34e4de194",
              "builtID": "tx-lower-31",
              "externalID": "ocd-person/f0ede995-2253-4f7b-a922-6da34e4de194",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryan.guillen@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "100 North F.M. 3167 Suite 212, Rio Grande City, TX 78582",
              "phone1": null,
              "phone2": "956-716-4838",
              "fax1": null,
              "fax2": "956-716-8219",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=31"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-31-lower-ocd-person-f0ede995-2253-4f7b-a922-6da34e4de194": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/9447575b-d8a1-45dd-bd68-77b12fe347ab",
            "firstName": "Todd Ames",
            "lastName": "Hunter",
            "fullName": "Todd Hunter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3365.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-TX-32-lower-ocd-person-9447575b-d8a1-45dd-bd68-77b12fe347ab",
              "builtID": "tx-lower-32",
              "externalID": "ocd-person/9447575b-d8a1-45dd-bd68-77b12fe347ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "todd.hunter@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "15217 S. Padre Island Drive Suite 201, Corpus Christi, TX 78418",
              "phone1": null,
              "phone2": "361-949-4603",
              "fax1": null,
              "fax2": "361-949-4634",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=32"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-32-lower-ocd-person-9447575b-d8a1-45dd-bd68-77b12fe347ab": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/798a9db1-dee4-48cd-ae0a-9b43d9f287a7",
            "firstName": "Justin Aldred",
            "lastName": "Holland",
            "fullName": "Justin Holland",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3205.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-TX-33-lower-ocd-person-798a9db1-dee4-48cd-ae0a-9b43d9f287a7",
              "builtID": "tx-lower-33",
              "externalID": "ocd-person/798a9db1-dee4-48cd-ae0a-9b43d9f287a7",
              "geometry": null
            },
            "contactInfo": {
              "email": "justin.holland@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "101 E. Rusk St. #201, Rockwall, TX 75087",
              "phone1": null,
              "phone2": "972-722-7521",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=33"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-33-lower-ocd-person-798a9db1-dee4-48cd-ae0a-9b43d9f287a7": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/19c22134-0632-4b9a-ac8e-64288ea9c1ba",
            "firstName": "Abel",
            "lastName": "Herrero",
            "fullName": "Abel Herrero",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3140.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-TX-34-lower-ocd-person-19c22134-0632-4b9a-ac8e-64288ea9c1ba",
              "builtID": "tx-lower-34",
              "externalID": "ocd-person/19c22134-0632-4b9a-ac8e-64288ea9c1ba",
              "geometry": null
            },
            "contactInfo": {
              "email": "abel.herrero@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "101 E. Main Ave., Robstown, TX 78380",
              "phone1": null,
              "phone2": "361-387-0457",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legdir.capitol.texas.gov/MemberInfo.aspx?Chamber=H&Code=A3140",
                "https://house.texas.gov/members/member-page/?district=34"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-34-lower-ocd-person-19c22134-0632-4b9a-ac8e-64288ea9c1ba": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/896e8f9c-7dca-4f7d-8554-c0324cfa7265",
            "firstName": "Oscar",
            "lastName": "Longoria",
            "fullName": "Oscar Longoria",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/2485.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-TX-35-lower-ocd-person-896e8f9c-7dca-4f7d-8554-c0324cfa7265",
              "builtID": "tx-lower-35",
              "externalID": "ocd-person/896e8f9c-7dca-4f7d-8554-c0324cfa7265",
              "geometry": null
            },
            "contactInfo": {
              "email": "oscar.longoria@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1029 1320 S. Main St., Penitas, TX 78576",
              "phone1": null,
              "phone2": "956-580-6944",
              "fax1": null,
              "fax2": "956-580-2233",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=35",
                "https://legdir.capitol.texas.gov/MemberInfo.aspx?Chamber=H&Code=A2485",
                "https://house.texas.gov/members/35"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-35-lower-ocd-person-896e8f9c-7dca-4f7d-8554-c0324cfa7265": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/b7c4e0e7-4949-4dff-bc4c-fdaaada3f37d",
            "firstName": "Sergio",
            "lastName": "Munoz",
            "fullName": "Sergio Muñoz",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/2060.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-TX-36-lower-ocd-person-b7c4e0e7-4949-4dff-bc4c-fdaaada3f37d",
              "builtID": "tx-lower-36",
              "externalID": "ocd-person/b7c4e0e7-4949-4dff-bc4c-fdaaada3f37d",
              "geometry": null
            },
            "contactInfo": {
              "email": "sergio.munoz@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "121 E. Tom Landry, Mission, TX 78572",
              "phone1": null,
              "phone2": "956-584-8999",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=36"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-36-lower-ocd-person-b7c4e0e7-4949-4dff-bc4c-fdaaada3f37d": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/a732704e-9b03-449c-b24f-f4c567b5acdd",
            "firstName": "Janie",
            "lastName": "Lopez",
            "fullName": "Janie Lopez",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4295.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-TX-37-lower-ocd-person-a732704e-9b03-449c-b24f-f4c567b5acdd",
              "builtID": "tx-lower-37",
              "externalID": "ocd-person/a732704e-9b03-449c-b24f-f4c567b5acdd",
              "geometry": null
            },
            "contactInfo": {
              "email": "janie.lopez@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1390 W. Expressway 83, San Benito, TX 78586",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=37"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-37-lower-ocd-person-a732704e-9b03-449c-b24f-f4c567b5acdd": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/2cadcb59-210a-4a71-af9f-7dba9ab06112",
            "firstName": "Erin Elizabeth",
            "lastName": "Gamez",
            "fullName": "Erin Gámez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4095.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-TX-38-lower-ocd-person-2cadcb59-210a-4a71-af9f-7dba9ab06112",
              "builtID": "tx-lower-38",
              "externalID": "ocd-person/2cadcb59-210a-4a71-af9f-7dba9ab06112",
              "geometry": null
            },
            "contactInfo": {
              "email": "erin.gamez@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "777 E. Harrison St. 2nd Floor, Brownsville, TX 78520",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=38",
                "https://house.texas.gov/members/38"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-38-lower-ocd-person-2cadcb59-210a-4a71-af9f-7dba9ab06112": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/e2d786e9-5e46-4807-be42-546203016996",
            "firstName": "Armando",
            "lastName": "Martinez",
            "fullName": "Mando Martinez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3780.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-TX-39-lower-ocd-person-e2d786e9-5e46-4807-be42-546203016996",
              "builtID": "tx-lower-39",
              "externalID": "ocd-person/e2d786e9-5e46-4807-be42-546203016996",
              "geometry": null
            },
            "contactInfo": {
              "email": "armando.martinez@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "914 W. Pike Blvd., Weslaco, TX 78596",
              "phone1": null,
              "phone2": "956-447-9473",
              "fax1": null,
              "fax2": "956-447-8683",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=39"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-39-lower-ocd-person-e2d786e9-5e46-4807-be42-546203016996": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/3e4ae7d4-dbb2-4991-8b44-8ada6023d7da",
            "firstName": "Terry",
            "lastName": "Canales",
            "fullName": "Terry Canales",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/2340.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-TX-40-lower-ocd-person-3e4ae7d4-dbb2-4991-8b44-8ada6023d7da",
              "builtID": "tx-lower-40",
              "externalID": "ocd-person/3e4ae7d4-dbb2-4991-8b44-8ada6023d7da",
              "geometry": null
            },
            "contactInfo": {
              "email": "terry.canales@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "602 W. University Drive Suite B, Edinburg, TX 78539",
              "phone1": null,
              "phone2": "956-383-0860",
              "fax1": null,
              "fax2": "956-383-4334",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=40"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-40-lower-ocd-person-3e4ae7d4-dbb2-4991-8b44-8ada6023d7da": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/51591894-e9ff-48d3-81c5-897ec1c5e600",
            "firstName": "Robert D.",
            "lastName": "Guerra",
            "fullName": "Bobby Guerra",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/2325.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-TX-41-lower-ocd-person-51591894-e9ff-48d3-81c5-897ec1c5e600",
              "builtID": "tx-lower-41",
              "externalID": "ocd-person/51591894-e9ff-48d3-81c5-897ec1c5e600",
              "geometry": null
            },
            "contactInfo": {
              "email": "bobby.guerra@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "10213 N. 10th St. Suite B, McAllen, TX 78504",
              "phone1": null,
              "phone2": "956-292-0407",
              "fax1": null,
              "fax2": "956-292-0418",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=41"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-41-lower-ocd-person-51591894-e9ff-48d3-81c5-897ec1c5e600": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/d4a94a68-02a7-44c0-901f-9cd57350229a",
            "firstName": "Richard Pena",
            "lastName": "Raymond",
            "fullName": "Richard Raymond",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4215.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-TX-42-lower-ocd-person-d4a94a68-02a7-44c0-901f-9cd57350229a",
              "builtID": "tx-lower-42",
              "externalID": "ocd-person/d4a94a68-02a7-44c0-901f-9cd57350229a",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.raymond@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "W. End Washington St., Laredo, TX 78040",
              "phone1": null,
              "phone2": "956-753-7722",
              "fax1": null,
              "fax2": "956-753-7729",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=42"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-42-lower-ocd-person-d4a94a68-02a7-44c0-901f-9cd57350229a": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/a43f2db5-4b24-4dfa-af7f-a2c7cbc5ea21",
            "firstName": "Jose Manuel",
            "lastName": "Lozano",
            "fullName": "J.M. Lozano",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2065.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-TX-43-lower-ocd-person-a43f2db5-4b24-4dfa-af7f-a2c7cbc5ea21",
              "builtID": "tx-lower-43",
              "externalID": "ocd-person/a43f2db5-4b24-4dfa-af7f-a2c7cbc5ea21",
              "geometry": null
            },
            "contactInfo": {
              "email": "jm.lozano@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1211 US Highway 181 #106, Portland, TX 78374",
              "phone1": null,
              "phone2": "361-643-0063",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=43"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-43-lower-ocd-person-a43f2db5-4b24-4dfa-af7f-a2c7cbc5ea21": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/93474b2b-546e-49e4-9399-5c208bbcb524",
            "firstName": "John Langston",
            "lastName": "Kuempel",
            "fullName": "John Kuempel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2310.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-TX-44-lower-ocd-person-93474b2b-546e-49e4-9399-5c208bbcb524",
              "builtID": "tx-lower-44",
              "externalID": "ocd-person/93474b2b-546e-49e4-9399-5c208bbcb524",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.kuempel@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "200 N. River St., #100-E, Seguin, TX 78155",
              "phone1": null,
              "phone2": "830-379-8732",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=44"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-44-lower-ocd-person-93474b2b-546e-49e4-9399-5c208bbcb524": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/b55d57f4-bd06-4487-8860-0b6eeb9a1dea",
            "firstName": "Erin Alisa",
            "lastName": "Zwiener",
            "fullName": "Erin Zwiener",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3710.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-TX-45-lower-ocd-person-b55d57f4-bd06-4487-8860-0b6eeb9a1dea",
              "builtID": "tx-lower-45",
              "externalID": "ocd-person/b55d57f4-bd06-4487-8860-0b6eeb9a1dea",
              "geometry": null
            },
            "contactInfo": {
              "email": "erin.zwiener@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=45"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-45-lower-ocd-person-b55d57f4-bd06-4487-8860-0b6eeb9a1dea": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/97805dd7-1f42-482c-bac3-049dfccda311",
            "firstName": "Sheryl",
            "lastName": "Cole",
            "fullName": "Sheryl Cole",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3625.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-TX-46-lower-ocd-person-97805dd7-1f42-482c-bac3-049dfccda311",
              "builtID": "tx-lower-46",
              "externalID": "ocd-person/97805dd7-1f42-482c-bac3-049dfccda311",
              "geometry": null
            },
            "contactInfo": {
              "email": "sheryl.cole@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=46",
                "https://house.texas.gov/members/46"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-46-lower-ocd-person-97805dd7-1f42-482c-bac3-049dfccda311": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/25157308-7474-4600-8b83-173d79e16a21",
            "firstName": "Vikki",
            "lastName": "Goodwin",
            "fullName": "Vikki Goodwin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3820.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-TX-47-lower-ocd-person-25157308-7474-4600-8b83-173d79e16a21",
              "builtID": "tx-lower-47",
              "externalID": "ocd-person/25157308-7474-4600-8b83-173d79e16a21",
              "geometry": null
            },
            "contactInfo": {
              "email": "vikki.goodwin@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=47"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-47-lower-ocd-person-25157308-7474-4600-8b83-173d79e16a21": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/349071fd-3c36-43c6-aaca-6d7f9d04322d",
            "firstName": "Donna",
            "lastName": "Howard",
            "fullName": "Donna Howard",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3310.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-TX-48-lower-ocd-person-349071fd-3c36-43c6-aaca-6d7f9d04322d",
              "builtID": "tx-lower-48",
              "externalID": "ocd-person/349071fd-3c36-43c6-aaca-6d7f9d04322d",
              "geometry": null
            },
            "contactInfo": {
              "email": "donna.howard@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 2910, Austin, TX 78768",
              "phone1": null,
              "phone2": "512-463-0631",
              "fax1": null,
              "fax2": "512-463-0901",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=48"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-48-lower-ocd-person-349071fd-3c36-43c6-aaca-6d7f9d04322d": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/1f9ed42e-27de-4cd1-b2bf-f890ee33cb49",
            "firstName": "Regina Inez",
            "lastName": "Hinojosa",
            "fullName": "Gina Hinojosa",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3210.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-TX-49-lower-ocd-person-1f9ed42e-27de-4cd1-b2bf-f890ee33cb49",
              "builtID": "tx-lower-49",
              "externalID": "ocd-person/1f9ed42e-27de-4cd1-b2bf-f890ee33cb49",
              "geometry": null
            },
            "contactInfo": {
              "email": "gina.hinojosa@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=49"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-49-lower-ocd-person-1f9ed42e-27de-4cd1-b2bf-f890ee33cb49": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/5e4e2fd2-e8a9-46f3-aab5-ffbc782afa8b",
            "firstName": "James Dell",
            "lastName": "Talarico",
            "fullName": "James Talarico",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3685.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-TX-50-lower-ocd-person-5e4e2fd2-e8a9-46f3-aab5-ffbc782afa8b",
              "builtID": "tx-lower-50",
              "externalID": "ocd-person/5e4e2fd2-e8a9-46f3-aab5-ffbc782afa8b",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.talarico@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=52",
                "https://house.texas.gov/members/member-page/?district=50"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-50-lower-ocd-person-5e4e2fd2-e8a9-46f3-aab5-ffbc782afa8b": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/ab3ddc65-0fb9-476b-993f-b3dc4050127e",
            "firstName": "Maria Luisa",
            "lastName": "Flores",
            "fullName": "Lulu Flores",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4150.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-TX-51-lower-ocd-person-ab3ddc65-0fb9-476b-993f-b3dc4050127e",
              "builtID": "tx-lower-51",
              "externalID": "ocd-person/ab3ddc65-0fb9-476b-993f-b3dc4050127e",
              "geometry": null
            },
            "contactInfo": {
              "email": "lulu.flores@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=51"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-51-lower-ocd-person-ab3ddc65-0fb9-476b-993f-b3dc4050127e": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/72920206-cbe7-4429-b42b-c6fc746a1d56",
            "firstName": "Caroline E.",
            "lastName": "Harris Davila",
            "fullName": "Caroline Harris Davila",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4205.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-TX-52-lower-ocd-person-72920206-cbe7-4429-b42b-c6fc746a1d56",
              "builtID": "tx-lower-52",
              "externalID": "ocd-person/72920206-cbe7-4429-b42b-c6fc746a1d56",
              "geometry": null
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": "3010 E. Old Settlers Blvd., Round Rock, TX 78665",
              "phone1": null,
              "phone2": "512-255-6612",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=52"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-52-lower-ocd-person-72920206-cbe7-4429-b42b-c6fc746a1d56": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/2f826108-f4d8-4af1-99c8-c0a335fb9c0a",
            "firstName": "Andrew Stevenson",
            "lastName": "Murr",
            "fullName": "Andy Murr",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2965.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-TX-53-lower-ocd-person-2f826108-f4d8-4af1-99c8-c0a335fb9c0a",
              "builtID": "tx-lower-53",
              "externalID": "ocd-person/2f826108-f4d8-4af1-99c8-c0a335fb9c0a",
              "geometry": null
            },
            "contactInfo": {
              "email": "andrew.murr@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "507 Earl Garrett St., Kerrville, TX 78028",
              "phone1": null,
              "phone2": "830-257-0432",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=53"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-53-lower-ocd-person-2f826108-f4d8-4af1-99c8-c0a335fb9c0a": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/e3533cbc-f3ab-46bc-a591-c68657f82527",
            "firstName": "Bradley Leo",
            "lastName": "Buckley",
            "fullName": "Brad Buckley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3585.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-TX-54-lower-ocd-person-e3533cbc-f3ab-46bc-a591-c68657f82527",
              "builtID": "tx-lower-54",
              "externalID": "ocd-person/e3533cbc-f3ab-46bc-a591-c68657f82527",
              "geometry": null
            },
            "contactInfo": {
              "email": "brad.buckley@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "501 E. 4th St. Suite 301, Lampasas, TX 76550",
              "phone1": null,
              "phone2": "512-556-5934",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=54"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-54-lower-ocd-person-e3533cbc-f3ab-46bc-a591-c68657f82527": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/0b3fe2fc-7445-4986-b34b-0c3ca545eab8",
            "firstName": "Hugh D.",
            "lastName": "Shine",
            "fullName": "Hugh Shine",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4455.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-TX-55-lower-ocd-person-0b3fe2fc-7445-4986-b34b-0c3ca545eab8",
              "builtID": "tx-lower-55",
              "externalID": "ocd-person/0b3fe2fc-7445-4986-b34b-0c3ca545eab8",
              "geometry": null
            },
            "contactInfo": {
              "email": "hugh.shine@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "101 E. Central Ave., Suite 122, Belton, TX 76513",
              "phone1": null,
              "phone2": "254-831-2298",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=55",
                "https://house.texas.gov/members/55"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-55-lower-ocd-person-0b3fe2fc-7445-4986-b34b-0c3ca545eab8": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/a289901c-76aa-4288-a4b9-3bb1bf081c89",
            "firstName": "Charles",
            "lastName": "Anderson",
            "fullName": "Doc Anderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2155.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-TX-56-lower-ocd-person-a289901c-76aa-4288-a4b9-3bb1bf081c89",
              "builtID": "tx-lower-56",
              "externalID": "ocd-person/a289901c-76aa-4288-a4b9-3bb1bf081c89",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.anderson@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "900 Austin Ave., Suite 804, Waco, TX 76701",
              "phone1": null,
              "phone2": "254-754-3892",
              "fax1": null,
              "fax2": "254-754-1604",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=56"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-56-lower-ocd-person-a289901c-76aa-4288-a4b9-3bb1bf081c89": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/f8e0a89e-889a-4fcf-8a0a-1e0e091d89d0",
            "firstName": "Richard",
            "lastName": "Hayes",
            "fullName": "Richard Hayes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4165.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-TX-57-lower-ocd-person-f8e0a89e-889a-4fcf-8a0a-1e0e091d89d0",
              "builtID": "tx-lower-57",
              "externalID": "ocd-person/f8e0a89e-889a-4fcf-8a0a-1e0e091d89d0",
              "geometry": null
            },
            "contactInfo": {
              "email": "richard.hayes@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2000 S. Stemmons Freeway Suite 203, Lake Dallas, TX 75065",
              "phone1": null,
              "phone2": "940-321-7574",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=57",
                "https://house.texas.gov/members/4165"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-57-lower-ocd-person-f8e0a89e-889a-4fcf-8a0a-1e0e091d89d0": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/a5e6ba30-dfa5-4e59-baa3-c96f0dcf775f",
            "firstName": "DeWayne",
            "lastName": "Burns",
            "fullName": "DeWayne Burns",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2975.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-TX-58-lower-ocd-person-a5e6ba30-dfa5-4e59-baa3-c96f0dcf775f",
              "builtID": "tx-lower-58",
              "externalID": "ocd-person/a5e6ba30-dfa5-4e59-baa3-c96f0dcf775f",
              "geometry": null
            },
            "contactInfo": {
              "email": "dewayne.burns@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "115 S. Main St. Suite 202, Cleburne, TX 76033",
              "phone1": null,
              "phone2": "817-645-3685",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=58"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-58-lower-ocd-person-a5e6ba30-dfa5-4e59-baa3-c96f0dcf775f": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/ddebb940-33f9-4b5c-a8fa-e22c63a594e9",
            "firstName": "Shelby",
            "lastName": "Slawson",
            "fullName": "Shelby Slawson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4055.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-TX-59-lower-ocd-person-ddebb940-33f9-4b5c-a8fa-e22c63a594e9",
              "builtID": "tx-lower-59",
              "externalID": "ocd-person/ddebb940-33f9-4b5c-a8fa-e22c63a594e9",
              "geometry": null
            },
            "contactInfo": {
              "email": "shelby.slawson@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Historical Hood County Courthouse 100 E. Pearl St., Room 305, Granbury, TX 76048",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=59"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-59-lower-ocd-person-ddebb940-33f9-4b5c-a8fa-e22c63a594e9": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/b95a6eac-71b2-47ef-918e-91a5ae413a4e",
            "firstName": "Glenn",
            "lastName": "Rogers",
            "fullName": "Glenn Rogers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4025.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-TX-60-lower-ocd-person-b95a6eac-71b2-47ef-918e-91a5ae413a4e",
              "builtID": "tx-lower-60",
              "externalID": "ocd-person/b95a6eac-71b2-47ef-918e-91a5ae413a4e",
              "geometry": null
            },
            "contactInfo": {
              "email": "glenn.rogers@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "103 South Oaks Suite 102, Mineral Wells, TX 76067",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=60"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-60-lower-ocd-person-b95a6eac-71b2-47ef-918e-91a5ae413a4e": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/10277692-2a2f-43d8-b120-70e591db636f",
            "firstName": "Frederick",
            "lastName": "Frazier",
            "fullName": "Frederick Frazier",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4155.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-TX-61-lower-ocd-person-10277692-2a2f-43d8-b120-70e591db636f",
              "builtID": "tx-lower-61",
              "externalID": "ocd-person/10277692-2a2f-43d8-b120-70e591db636f",
              "geometry": null
            },
            "contactInfo": {
              "email": "frederick.frazier@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=61"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-61-lower-ocd-person-10277692-2a2f-43d8-b120-70e591db636f": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/19313ba6-75f3-4979-8642-b55b1008bedc",
            "firstName": "Reginald B.",
            "lastName": "Smith",
            "fullName": "Reggie Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3765.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-TX-62-lower-ocd-person-19313ba6-75f3-4979-8642-b55b1008bedc",
              "builtID": "tx-lower-62",
              "externalID": "ocd-person/19313ba6-75f3-4979-8642-b55b1008bedc",
              "geometry": null
            },
            "contactInfo": {
              "email": "reggie.smith@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "300 N. Travis St. Suite 3, Sherman, TX 75090",
              "phone1": null,
              "phone2": "903-891-7297",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=62",
                "https://house.texas.gov/members/3765/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-62-lower-ocd-person-19313ba6-75f3-4979-8642-b55b1008bedc": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/ba43365e-63dc-4bdc-aadc-e3939aa7465b",
            "firstName": "Benjamin",
            "lastName": "Bumgarner",
            "fullName": "Ben Bumgarner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4125.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-TX-63-lower-ocd-person-ba43365e-63dc-4bdc-aadc-e3939aa7465b",
              "builtID": "tx-lower-63",
              "externalID": "ocd-person/ba43365e-63dc-4bdc-aadc-e3939aa7465b",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.bumgarner@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "108 S. Oak St., Roanoke, TX 76262",
              "phone1": null,
              "phone2": "817-491-7305",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=63",
                "https://house.texas.gov/members/63"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-63-lower-ocd-person-ba43365e-63dc-4bdc-aadc-e3939aa7465b": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/9c13f4e5-0ce2-488e-b2f3-50d4bfc2dd9c",
            "firstName": "Lynn Dale",
            "lastName": "Stucky",
            "fullName": "Lynn Stucky",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3220.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-TX-64-lower-ocd-person-9c13f4e5-0ce2-488e-b2f3-50d4bfc2dd9c",
              "builtID": "tx-lower-64",
              "externalID": "ocd-person/9c13f4e5-0ce2-488e-b2f3-50d4bfc2dd9c",
              "geometry": null
            },
            "contactInfo": {
              "email": "lynn.stucky@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "400 W. Oak St. Suite 106, Denton, TX 76201",
              "phone1": null,
              "phone2": "940-243-0230",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=64"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-64-lower-ocd-person-9c13f4e5-0ce2-488e-b2f3-50d4bfc2dd9c": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/5bbe665a-bde8-469f-8c92-958c457236f0",
            "firstName": "Kronda",
            "lastName": "Thimesch",
            "fullName": "Kronda Thimesch",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4365.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-TX-65-lower-ocd-person-5bbe665a-bde8-469f-8c92-958c457236f0",
              "builtID": "tx-lower-65",
              "externalID": "ocd-person/5bbe665a-bde8-469f-8c92-958c457236f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "kronda.thimesch@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6200 Canyon Falls Drive, Flower Mound, TX 76226",
              "phone1": null,
              "phone2": "940-240-0083",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=65",
                "https://house.texas.gov/members/65"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-65-lower-ocd-person-5bbe665a-bde8-469f-8c92-958c457236f0": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/71555c46-28b0-4887-98a1-997dc7482e75",
            "firstName": "Matthew F.",
            "lastName": "Shaheen",
            "fullName": "Matt Shaheen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2995.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-TX-66-lower-ocd-person-71555c46-28b0-4887-98a1-997dc7482e75",
              "builtID": "tx-lower-66",
              "externalID": "ocd-person/71555c46-28b0-4887-98a1-997dc7482e75",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.shaheen@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "TX",
              "phone1": null,
              "phone2": "469-642-8708",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=66",
                "https://legdir.capitol.texas.gov/MemberInfo.aspx?Chamber=H&Code=A2995",
                "https://house.texas.gov/members/66"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-66-lower-ocd-person-71555c46-28b0-4887-98a1-997dc7482e75": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/40a678a3-7d85-486e-bf38-5d070142aa83",
            "firstName": "Jeffrey Curtis",
            "lastName": "Leach",
            "fullName": "Jeff Leach",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2475.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-TX-67-lower-ocd-person-40a678a3-7d85-486e-bf38-5d070142aa83",
              "builtID": "tx-lower-67",
              "externalID": "ocd-person/40a678a3-7d85-486e-bf38-5d070142aa83",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.leach@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "300 E. Davis St., #170, McKinney, TX 75069",
              "phone1": null,
              "phone2": "214-491-1537",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=67"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-67-lower-ocd-person-40a678a3-7d85-486e-bf38-5d070142aa83": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/36338558-c2b0-4b43-b656-261dccbee871",
            "firstName": "David",
            "lastName": "Spiller",
            "fullName": "David Spiller",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4075.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-TX-68-lower-ocd-person-36338558-c2b0-4b43-b656-261dccbee871",
              "builtID": "tx-lower-68",
              "externalID": "ocd-person/36338558-c2b0-4b43-b656-261dccbee871",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.spiller@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 447, Jacksboro, TX 76458",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=68",
                "https://house.texas.gov/members/4075"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-68-lower-ocd-person-36338558-c2b0-4b43-b656-261dccbee871": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/6cbb02e6-5876-47a1-86aa-98566fe0073a",
            "firstName": "James Boisfeuillet",
            "lastName": "Frank",
            "fullName": "James Frank",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2385.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-TX-69-lower-ocd-person-6cbb02e6-5876-47a1-86aa-98566fe0073a",
              "builtID": "tx-lower-69",
              "externalID": "ocd-person/6cbb02e6-5876-47a1-86aa-98566fe0073a",
              "geometry": null
            },
            "contactInfo": {
              "email": "james.frank@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1206 Hatton Rd., Wichita Falls, TX 76302",
              "phone1": null,
              "phone2": "940-767-1700",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=69"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-69-lower-ocd-person-6cbb02e6-5876-47a1-86aa-98566fe0073a": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/b69579f7-74de-4a71-86ba-033264cc5bc7",
            "firstName": "Mihaela E.",
            "lastName": "Plesa",
            "fullName": "Mihaela Plesa",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4345.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-TX-70-lower-ocd-person-b69579f7-74de-4a71-86ba-033264cc5bc7",
              "builtID": "tx-lower-70",
              "externalID": "ocd-person/b69579f7-74de-4a71-86ba-033264cc5bc7",
              "geometry": null
            },
            "contactInfo": {
              "email": "mihaela.plesa@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "900 E. Park Blvd. Suite 140-C, Plano, TX 75074",
              "phone1": null,
              "phone2": "972-423-1227",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=70",
                "https://house.texas.gov/members/70"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-70-lower-ocd-person-b69579f7-74de-4a71-86ba-033264cc5bc7": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/a4e4115b-d488-4e93-8354-80e361c0c260",
            "firstName": "Standard Dwight",
            "lastName": "Lambert",
            "fullName": "Stan Lambert",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3225.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-TX-71-lower-ocd-person-a4e4115b-d488-4e93-8354-80e361c0c260",
              "builtID": "tx-lower-71",
              "externalID": "ocd-person/a4e4115b-d488-4e93-8354-80e361c0c260",
              "geometry": null
            },
            "contactInfo": {
              "email": "stan.lambert@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=71"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-71-lower-ocd-person-a4e4115b-d488-4e93-8354-80e361c0c260": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/40f0e868-e021-4453-8675-a9d2357e41e1",
            "firstName": "William Drew",
            "lastName": "Darby",
            "fullName": "Drew Darby",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2645.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-TX-72-lower-ocd-person-40f0e868-e021-4453-8675-a9d2357e41e1",
              "builtID": "tx-lower-72",
              "externalID": "ocd-person/40f0e868-e021-4453-8675-a9d2357e41e1",
              "geometry": null
            },
            "contactInfo": {
              "email": "drew.darby@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "136 W. Twohig Ave. Suite E, San Angelo, TX 76903",
              "phone1": null,
              "phone2": "325-658-7313",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=72"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-72-lower-ocd-person-40f0e868-e021-4453-8675-a9d2357e41e1": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/64221b86-e3ca-4b67-b2bb-3f4f9641e133",
            "firstName": "Carrie Crain",
            "lastName": "Isaac",
            "fullName": "Carrie Isaac",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4265.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-TX-73-lower-ocd-person-64221b86-e3ca-4b67-b2bb-3f4f9641e133",
              "builtID": "tx-lower-73",
              "externalID": "ocd-person/64221b86-e3ca-4b67-b2bb-3f4f9641e133",
              "geometry": null
            },
            "contactInfo": {
              "email": "carrie.isaac@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "137 Fredericksburg Road, New Braunfels, TX 78130",
              "phone1": null,
              "phone2": "830-730-6248",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=73"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-73-lower-ocd-person-64221b86-e3ca-4b67-b2bb-3f4f9641e133": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/c84056d0-47e0-4a0c-94e1-9f90c2d90a15",
            "firstName": "Heriberto",
            "lastName": "Morales",
            "fullName": "Eddie Morales",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4000.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-TX-74-lower-ocd-person-c84056d0-47e0-4a0c-94e1-9f90c2d90a15",
              "builtID": "tx-lower-74",
              "externalID": "ocd-person/c84056d0-47e0-4a0c-94e1-9f90c2d90a15",
              "geometry": null
            },
            "contactInfo": {
              "email": "eddie.morales@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1915 Veterans Blvd., Del Rio, TX 78840",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=74"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-74-lower-ocd-person-c84056d0-47e0-4a0c-94e1-9f90c2d90a15": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/93379bbe-5fb3-400f-853a-d0b3553e7772",
            "firstName": "Mary Edna",
            "lastName": "Gonzalez",
            "fullName": "Mary González",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/2410.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-TX-75-lower-ocd-person-93379bbe-5fb3-400f-853a-d0b3553e7772",
              "builtID": "tx-lower-75",
              "externalID": "ocd-person/93379bbe-5fb3-400f-853a-d0b3553e7772",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.gonzalez@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1343 1498 Main St., San Elizario, TX 79849",
              "phone1": null,
              "phone2": "915-851-6632",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=75"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-75-lower-ocd-person-93379bbe-5fb3-400f-853a-d0b3553e7772": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/51927936-c6fc-453b-8afd-5f0b53de7d46",
            "firstName": "Suleman",
            "lastName": "Lalani",
            "fullName": "Suleman Lalani",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4285.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-TX-76-lower-ocd-person-51927936-c6fc-453b-8afd-5f0b53de7d46",
              "builtID": "tx-lower-76",
              "externalID": "ocd-person/51927936-c6fc-453b-8afd-5f0b53de7d46",
              "geometry": null
            },
            "contactInfo": {
              "email": "suleman.lalani@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "12550 Emily Court Suite 300, Sugar Land, TX 77478",
              "phone1": null,
              "phone2": "281-494-0151",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=76"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-76-lower-ocd-person-51927936-c6fc-453b-8afd-5f0b53de7d46": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/d731c9f0-44ff-4abf-92c4-0069c07f6b26",
            "firstName": "Evelina",
            "lastName": "Ortega",
            "fullName": "Lina Ortega",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3545.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-TX-77-lower-ocd-person-d731c9f0-44ff-4abf-92c4-0069c07f6b26",
              "builtID": "tx-lower-77",
              "externalID": "ocd-person/d731c9f0-44ff-4abf-92c4-0069c07f6b26",
              "geometry": null
            },
            "contactInfo": {
              "email": "lina.ortega@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "310 N. Mesa Suite 424, El Paso, TX 79901",
              "phone1": null,
              "phone2": "915-351-4031",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=77"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-77-lower-ocd-person-d731c9f0-44ff-4abf-92c4-0069c07f6b26": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/5898294d-5b10-4aaf-a084-b8522e69623c",
            "firstName": "Joseph E.",
            "lastName": "Moody",
            "fullName": "Joe Moody",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3850.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-TX-78-lower-ocd-person-5898294d-5b10-4aaf-a084-b8522e69623c",
              "builtID": "tx-lower-78",
              "externalID": "ocd-person/5898294d-5b10-4aaf-a084-b8522e69623c",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.moody@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7365 Remcon Circle, C-301, El Paso, TX 79912",
              "phone1": null,
              "phone2": "915-751-2700",
              "fax1": null,
              "fax2": "915-751-2702",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legdir.capitol.texas.gov/MemberInfo.aspx?Chamber=H&Code=A3850",
                "https://house.texas.gov/members/member-page/?district=78"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-78-lower-ocd-person-5898294d-5b10-4aaf-a084-b8522e69623c": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/6feda596-fd20-429b-ae4e-bedab7e5a1c5",
            "firstName": "Claudia",
            "lastName": "Ordaz",
            "fullName": "Claudia Ordaz",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4015.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-TX-79-lower-ocd-person-6feda596-fd20-429b-ae4e-bedab7e5a1c5",
              "builtID": "tx-lower-79",
              "externalID": "ocd-person/6feda596-fd20-429b-ae4e-bedab7e5a1c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "claudia.ordaz@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1200 Golden Key Suite 310, El Paso, TX 79925",
              "phone1": null,
              "phone2": "915-591-0299",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=76",
                "https://house.texas.gov/members/member-page/?district=79",
                "https://house.texas.gov/members/4015"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-79-lower-ocd-person-6feda596-fd20-429b-ae4e-bedab7e5a1c5": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/ed59d369-82b9-485f-9007-c5c5b77d9468",
            "firstName": "Tracy Ogden",
            "lastName": "King",
            "fullName": "Tracy King",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3465.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-TX-80-lower-ocd-person-ed59d369-82b9-485f-9007-c5c5b77d9468",
              "builtID": "tx-lower-80",
              "externalID": "ocd-person/ed59d369-82b9-485f-9007-c5c5b77d9468",
              "geometry": null
            },
            "contactInfo": {
              "email": "tracy.king@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 130, 5500 S. Zapata Highway Building F, Laredo, TX 78046",
              "phone1": null,
              "phone2": "956-307-6248",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=80"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-80-lower-ocd-person-ed59d369-82b9-485f-9007-c5c5b77d9468": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/83030f8b-5f1f-4475-b564-a07ae42f6d98",
            "firstName": "Brooks Frederick",
            "lastName": "Landgraf",
            "fullName": "Brooks Landgraf",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3040.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-TX-81-lower-ocd-person-83030f8b-5f1f-4475-b564-a07ae42f6d98",
              "builtID": "tx-lower-81",
              "externalID": "ocd-person/83030f8b-5f1f-4475-b564-a07ae42f6d98",
              "geometry": null
            },
            "contactInfo": {
              "email": "brooks.landgraf@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "119 W. 4th St., Odessa, TX 79761",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=81"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-81-lower-ocd-person-83030f8b-5f1f-4475-b564-a07ae42f6d98": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/d4d5c127-1ef6-445d-a89b-d9f02c812365",
            "firstName": "Thomas Russell",
            "lastName": "Craddick",
            "fullName": "Tom Craddick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2610.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-TX-82-lower-ocd-person-d4d5c127-1ef6-445d-a89b-d9f02c812365",
              "builtID": "tx-lower-82",
              "externalID": "ocd-person/d4d5c127-1ef6-445d-a89b-d9f02c812365",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.craddick@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "500 W. Texas Suite 880, Midland, TX 79701",
              "phone1": null,
              "phone2": "432-682-3000",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=82"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-82-lower-ocd-person-d4d5c127-1ef6-445d-a89b-d9f02c812365": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/fff5eb4f-2c41-4d00-a1c5-e566a15b063e",
            "firstName": "Dustin Ray",
            "lastName": "Burrows",
            "fullName": "Dustin Burrows",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3055.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-TX-83-lower-ocd-person-fff5eb4f-2c41-4d00-a1c5-e566a15b063e",
              "builtID": "tx-lower-83",
              "externalID": "ocd-person/fff5eb4f-2c41-4d00-a1c5-e566a15b063e",
              "geometry": null
            },
            "contactInfo": {
              "email": "dustin.burrows@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "10507 Quaker Ave. Suite 103, Lubbock, TX 79424",
              "phone1": null,
              "phone2": "806-795-0635",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=83"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-83-lower-ocd-person-fff5eb4f-2c41-4d00-a1c5-e566a15b063e": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/8f7f161e-6ef4-4c12-8eea-de88a313b36c",
            "firstName": "Carl H.",
            "lastName": "Tepper",
            "fullName": "Carl Tepper",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4360.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-TX-84-lower-ocd-person-8f7f161e-6ef4-4c12-8eea-de88a313b36c",
              "builtID": "tx-lower-84",
              "externalID": "ocd-person/8f7f161e-6ef4-4c12-8eea-de88a313b36c",
              "geometry": null
            },
            "contactInfo": {
              "email": "carl.tepper@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6515 68th St. Suite 200-7, Lubbock, TX 79424",
              "phone1": null,
              "phone2": "806-407-5633",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=84"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-84-lower-ocd-person-8f7f161e-6ef4-4c12-8eea-de88a313b36c": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/2662f888-95e0-4de3-be5b-3d023623c447",
            "firstName": "Stan",
            "lastName": "Kitzman",
            "fullName": "Stan Kitzman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4280.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-TX-85-lower-ocd-person-2662f888-95e0-4de3-be5b-3d023623c447",
              "builtID": "tx-lower-85",
              "externalID": "ocd-person/2662f888-95e0-4de3-be5b-3d023623c447",
              "geometry": null
            },
            "contactInfo": {
              "email": "stan.kitzman@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1 E. Main, Suite 202, Bellville, TX 77418",
              "phone1": null,
              "phone2": "979-865-4560",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=85"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-85-lower-ocd-person-2662f888-95e0-4de3-be5b-3d023623c447": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/3cac81cd-62ca-41d6-aa4a-8e4156244dec",
            "firstName": "John True",
            "lastName": "Smithee",
            "fullName": "John Smithee",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4530.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-TX-86-lower-ocd-person-3cac81cd-62ca-41d6-aa4a-8e4156244dec",
              "builtID": "tx-lower-86",
              "externalID": "ocd-person/3cac81cd-62ca-41d6-aa4a-8e4156244dec",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.smithee@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "320 S. Polk 1st Floor, Lobby Box 28, Amarillo, TX 79101",
              "phone1": null,
              "phone2": "806-372-3327",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=86"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-86-lower-ocd-person-3cac81cd-62ca-41d6-aa4a-8e4156244dec": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/7e01abb6-d4c5-40e9-bdef-7a7efd11aaca",
            "firstName": "Walter Thomas",
            "lastName": "Price",
            "fullName": "Four Price",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2185.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-TX-87-lower-ocd-person-7e01abb6-d4c5-40e9-bdef-7a7efd11aaca",
              "builtID": "tx-lower-87",
              "externalID": "ocd-person/7e01abb6-d4c5-40e9-bdef-7a7efd11aaca",
              "geometry": null
            },
            "contactInfo": {
              "email": "four.price@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Amarillo National Bank 500 S. Taylor St., Plaza Two, Suite 506, Amarillo, TX 79101",
              "phone1": null,
              "phone2": "806-374-8787",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=87"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-87-lower-ocd-person-7e01abb6-d4c5-40e9-bdef-7a7efd11aaca": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/d9f32e07-7835-4edf-b986-7a2cf16d608b",
            "firstName": "Kenneth Paul",
            "lastName": "King",
            "fullName": "Ken King",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2455.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-TX-88-lower-ocd-person-d9f32e07-7835-4edf-b986-7a2cf16d608b",
              "builtID": "tx-lower-88",
              "externalID": "ocd-person/d9f32e07-7835-4edf-b986-7a2cf16d608b",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.king@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 507, Canadian, TX 79014",
              "phone1": null,
              "phone2": "806-323-8870",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=88"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-88-lower-ocd-person-d9f32e07-7835-4edf-b986-7a2cf16d608b": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/f4976334-0e0e-4474-b5b4-3264e8d73f1d",
            "firstName": "Candy",
            "lastName": "Noble",
            "fullName": "Candy Noble",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3740.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-TX-89-lower-ocd-person-f4976334-0e0e-4474-b5b4-3264e8d73f1d",
              "builtID": "tx-lower-89",
              "externalID": "ocd-person/f4976334-0e0e-4474-b5b4-3264e8d73f1d",
              "geometry": null
            },
            "contactInfo": {
              "email": "candy.noble@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "206 N. Murphy Road, Murphy, TX 75094",
              "phone1": null,
              "phone2": "972-423-6542",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=89"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-89-lower-ocd-person-f4976334-0e0e-4474-b5b4-3264e8d73f1d": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/c0fc4283-3ed5-4165-a066-80de16f492b7",
            "firstName": "Ramon",
            "lastName": "Romero",
            "fullName": "Ramon Romero",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3060.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-TX-90-lower-ocd-person-c0fc4283-3ed5-4165-a066-80de16f492b7",
              "builtID": "tx-lower-90",
              "externalID": "ocd-person/c0fc4283-3ed5-4165-a066-80de16f492b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "ramon.romero@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3663 Airport Freeway Suite 102, Fort Worth, TX 76111",
              "phone1": null,
              "phone2": "817-924-6788",
              "fax1": null,
              "fax2": "817-924-1997",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=90"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-90-lower-ocd-person-c0fc4283-3ed5-4165-a066-80de16f492b7": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/0cd2f092-40a7-46d1-a9c2-507d14282954",
            "firstName": "Stephanie D.",
            "lastName": "Klick",
            "fullName": "Stephanie Klick",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2460.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-TX-91-lower-ocd-person-0cd2f092-40a7-46d1-a9c2-507d14282954",
              "builtID": "tx-lower-91",
              "externalID": "ocd-person/0cd2f092-40a7-46d1-a9c2-507d14282954",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephanie.klick@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6851 NE Loop 820 Suite 230, North Richland, TX 76180",
              "phone1": null,
              "phone2": "817-281-0079",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=91"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-91-lower-ocd-person-0cd2f092-40a7-46d1-a9c2-507d14282954": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/4fb2e1fd-fbb3-4a55-8417-761da2f6048e",
            "firstName": "Salman",
            "lastName": "Bhojani",
            "fullName": "Salman Bhojani",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4115.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-TX-92-lower-ocd-person-4fb2e1fd-fbb3-4a55-8417-761da2f6048e",
              "builtID": "tx-lower-92",
              "externalID": "ocd-person/4fb2e1fd-fbb3-4a55-8417-761da2f6048e",
              "geometry": null
            },
            "contactInfo": {
              "email": "salman.bhojani@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1001 W. Euless Blvd. Suite 210, Euless, TX 75040",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=92",
                "https://house.texas.gov/members/92"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-92-lower-ocd-person-4fb2e1fd-fbb3-4a55-8417-761da2f6048e": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/c6308fcd-b1e1-4ce3-9a29-c05e420e8012",
            "firstName": "Nate",
            "lastName": "Schatzline",
            "fullName": "Nate Schatzline",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4355.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-TX-93-lower-ocd-person-c6308fcd-b1e1-4ce3-9a29-c05e420e8012",
              "builtID": "tx-lower-93",
              "externalID": "ocd-person/c6308fcd-b1e1-4ce3-9a29-c05e420e8012",
              "geometry": null
            },
            "contactInfo": {
              "email": "nate.schatzline@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6642 N. Riverside Drive Suite 620, Fort Worth, TX 76137",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=93"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-93-lower-ocd-person-c6308fcd-b1e1-4ce3-9a29-c05e420e8012": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/1ca78d00-1ede-4486-b14e-16bd81ba73e4",
            "firstName": "Tony Dale",
            "lastName": "Tinderholt",
            "fullName": "Tony Tinderholt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3065.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-TX-94-lower-ocd-person-1ca78d00-1ede-4486-b14e-16bd81ba73e4",
              "builtID": "tx-lower-94",
              "externalID": "ocd-person/1ca78d00-1ede-4486-b14e-16bd81ba73e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "tony.tinderholt@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1000 Ballpark Way Suite 301, Arlington, TX 76011",
              "phone1": null,
              "phone2": "817-476-2660",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=94"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-94-lower-ocd-person-1ca78d00-1ede-4486-b14e-16bd81ba73e4": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/ea169f77-0bd8-4ca0-ac59-f894c9a09e71",
            "firstName": "Nicole Denise Johnson",
            "lastName": "Collier",
            "fullName": "Nicole Collier",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/2360.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-TX-95-lower-ocd-person-ea169f77-0bd8-4ca0-ac59-f894c9a09e71",
              "builtID": "tx-lower-95",
              "externalID": "ocd-person/ea169f77-0bd8-4ca0-ac59-f894c9a09e71",
              "geometry": null
            },
            "contactInfo": {
              "email": "nicole.collier@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "101. S. Jennings Suite 103A, Fort Worth, TX 76104",
              "phone1": null,
              "phone2": "817-332-1180",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=95",
                "https://legdir.capitol.texas.gov/MemberInfo.aspx?Chamber=H&Code=A2360",
                "https://house.texas.gov/members/95"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-95-lower-ocd-person-ea169f77-0bd8-4ca0-ac59-f894c9a09e71": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/2d5f629c-76a7-455b-aff7-262d8c1c23f6",
            "firstName": "David",
            "lastName": "Cook",
            "fullName": "David Cook",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3960.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-TX-96-lower-ocd-person-2d5f629c-76a7-455b-aff7-262d8c1c23f6",
              "builtID": "tx-lower-96",
              "externalID": "ocd-person/2d5f629c-76a7-455b-aff7-262d8c1c23f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.cook@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "309 E. Broad St., Mansfield, TX 76063",
              "phone1": null,
              "phone2": "817-473-1960",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=96",
                "https://house.texas.gov/members/3960"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-96-lower-ocd-person-2d5f629c-76a7-455b-aff7-262d8c1c23f6": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/74225274-40f8-4ef8-b2b5-bb7e4f8b5115",
            "firstName": "Craig Alan",
            "lastName": "Goldman",
            "fullName": "Craig Goldman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2395.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-TX-97-lower-ocd-person-74225274-40f8-4ef8-b2b5-bb7e4f8b5115",
              "builtID": "tx-lower-97",
              "externalID": "ocd-person/74225274-40f8-4ef8-b2b5-bb7e4f8b5115",
              "geometry": null
            },
            "contactInfo": {
              "email": "craig.goldman@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4521 S. Hulen St. Suite 208, Fort Worth, TX 76109",
              "phone1": null,
              "phone2": "817-920-5912",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=97"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-97-lower-ocd-person-74225274-40f8-4ef8-b2b5-bb7e4f8b5115": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/40ecf6ee-92a4-4daf-b950-ac70b6cdfeee",
            "firstName": "Giovanni S.",
            "lastName": "Capriglione",
            "fullName": "Giovanni Capriglione",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2345.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-TX-98-lower-ocd-person-40ecf6ee-92a4-4daf-b950-ac70b6cdfeee",
              "builtID": "tx-lower-98",
              "externalID": "ocd-person/40ecf6ee-92a4-4daf-b950-ac70b6cdfeee",
              "geometry": null
            },
            "contactInfo": {
              "email": "giovanni.capriglione@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 770 1100 Bear Creek Parkway, Keller, TX 76244",
              "phone1": null,
              "phone2": "817-431-5339",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legdir.capitol.texas.gov/MemberInfo.aspx?Chamber=H&Code=A2345",
                "https://house.texas.gov/members/member-page/?district=98"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-98-lower-ocd-person-40ecf6ee-92a4-4daf-b950-ac70b6cdfeee": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/65a42043-6243-4f79-b1e0-69b3b8b4af29",
            "firstName": "Charlie",
            "lastName": "Geren",
            "fullName": "Charlie Geren",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2945.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-TX-99-lower-ocd-person-65a42043-6243-4f79-b1e0-69b3b8b4af29",
              "builtID": "tx-lower-99",
              "externalID": "ocd-person/65a42043-6243-4f79-b1e0-69b3b8b4af29",
              "geometry": null
            },
            "contactInfo": {
              "email": "charlie.geren@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6713 Telephone Road Suite 301, Lake Worth, TX 76135",
              "phone1": null,
              "phone2": "817-237-2251",
              "fax1": null,
              "fax2": "817-237-2264",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=99"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-99-lower-ocd-person-65a42043-6243-4f79-b1e0-69b3b8b4af29": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/6b2d7f89-d7e0-44d1-b861-3167915f6925",
            "firstName": "Venton",
            "lastName": "Jones",
            "fullName": "Venton Jones",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4275.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-TX-100-lower-ocd-person-6b2d7f89-d7e0-44d1-b861-3167915f6925",
              "builtID": "tx-lower-100",
              "externalID": "ocd-person/6b2d7f89-d7e0-44d1-b861-3167915f6925",
              "geometry": null
            },
            "contactInfo": {
              "email": "venton.jones@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3535 Grand Ave., Dallas, TX 75210",
              "phone1": null,
              "phone2": "214-421-0744",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=100"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-100-lower-ocd-person-6b2d7f89-d7e0-44d1-b861-3167915f6925": 0
        }
      },
      "101": {
        "members": [
          {
            "API_ID": "ocd-person/a5e2021c-8221-41ba-b45c-cc89d13b2af2",
            "firstName": "Christopher",
            "lastName": "Turner",
            "fullName": "Chris Turner",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4680.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "101",
              "chamber": "lower",
              "hashID": "SLDL-TX-101-lower-ocd-person-a5e2021c-8221-41ba-b45c-cc89d13b2af2",
              "builtID": "tx-lower-101",
              "externalID": "ocd-person/a5e2021c-8221-41ba-b45c-cc89d13b2af2",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.turner@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "320 Westway Place Suite 501, Arlington, TX 76018",
              "phone1": null,
              "phone2": "817-459-2800",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=101",
                "https://house.texas.gov/members/4680"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-101-lower-ocd-person-a5e2021c-8221-41ba-b45c-cc89d13b2af2": 0
        }
      },
      "102": {
        "members": [
          {
            "API_ID": "ocd-person/7f893a20-0547-4ad9-9a94-1084a9ef6469",
            "firstName": "Ana-Maria",
            "lastName": "Ramos",
            "fullName": "Ana-Maria Ramos",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3735.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "102",
              "chamber": "lower",
              "hashID": "SLDL-TX-102-lower-ocd-person-7f893a20-0547-4ad9-9a94-1084a9ef6469",
              "builtID": "tx-lower-102",
              "externalID": "ocd-person/7f893a20-0547-4ad9-9a94-1084a9ef6469",
              "geometry": null
            },
            "contactInfo": {
              "email": "ana-maria.ramos@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "13140 Coit Road Suite 412, Dallas, TX 75240",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=102",
                "https://www.texasrepramos.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-102-lower-ocd-person-7f893a20-0547-4ad9-9a94-1084a9ef6469": 0
        }
      },
      "103": {
        "members": [
          {
            "API_ID": "ocd-person/f72eb41b-2ff9-434f-96cf-75b9724289ab",
            "firstName": "Rafael Michael",
            "lastName": "Anchia",
            "fullName": "Rafael Anchía",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/2150.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "103",
              "chamber": "lower",
              "hashID": "SLDL-TX-103-lower-ocd-person-f72eb41b-2ff9-434f-96cf-75b9724289ab",
              "builtID": "tx-lower-103",
              "externalID": "ocd-person/f72eb41b-2ff9-434f-96cf-75b9724289ab",
              "geometry": null
            },
            "contactInfo": {
              "email": "rafael.anchia@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1111 W. Mockingbird Lane Suite 1010, Dallas, TX 75247",
              "phone1": null,
              "phone2": "214-943-6081",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=103"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-103-lower-ocd-person-f72eb41b-2ff9-434f-96cf-75b9724289ab": 0
        }
      },
      "104": {
        "members": [
          {
            "API_ID": "ocd-person/8eeb1aad-24b6-487b-bbc0-31c2238b1ccd",
            "firstName": "Jessica Araceli",
            "lastName": "Gonzalez",
            "fullName": "Jessica González",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3335.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "104",
              "chamber": "lower",
              "hashID": "SLDL-TX-104-lower-ocd-person-8eeb1aad-24b6-487b-bbc0-31c2238b1ccd",
              "builtID": "tx-lower-104",
              "externalID": "ocd-person/8eeb1aad-24b6-487b-bbc0-31c2238b1ccd",
              "geometry": null
            },
            "contactInfo": {
              "email": "jessica.gonzalez@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "400 S. Zang Blvd. Suite 1214, Dallas, TX 75208",
              "phone1": null,
              "phone2": "214-944-5441",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=104",
                "https://house.texas.gov/members/3335"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-104-lower-ocd-person-8eeb1aad-24b6-487b-bbc0-31c2238b1ccd": 0
        }
      },
      "105": {
        "members": [
          {
            "API_ID": "ocd-person/bdecc388-4028-48e2-b211-75c59c1c5ec2",
            "firstName": "Thresa",
            "lastName": "Meza",
            "fullName": "Terry Meza",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3455.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "105",
              "chamber": "lower",
              "hashID": "SLDL-TX-105-lower-ocd-person-bdecc388-4028-48e2-b211-75c59c1c5ec2",
              "builtID": "tx-lower-105",
              "externalID": "ocd-person/bdecc388-4028-48e2-b211-75c59c1c5ec2",
              "geometry": null
            },
            "contactInfo": {
              "email": "terry.meza@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "613 N. O'Connor Road Suite 21B, Irving, TX 75061",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=105"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-105-lower-ocd-person-bdecc388-4028-48e2-b211-75c59c1c5ec2": 0
        }
      },
      "106": {
        "members": [
          {
            "API_ID": "ocd-person/e926ca11-64dd-4e15-97bd-1f7b0915de36",
            "firstName": "Jared L.",
            "lastName": "Patterson",
            "fullName": "Jared Patterson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3655.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "106",
              "chamber": "lower",
              "hashID": "SLDL-TX-106-lower-ocd-person-e926ca11-64dd-4e15-97bd-1f7b0915de36",
              "builtID": "tx-lower-106",
              "externalID": "ocd-person/e926ca11-64dd-4e15-97bd-1f7b0915de36",
              "geometry": null
            },
            "contactInfo": {
              "email": "jared.patterson@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5533 FM 423 Suite 503, Frisco, TX 75034",
              "phone1": null,
              "phone2": "214-494-6498",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=106"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-106-lower-ocd-person-e926ca11-64dd-4e15-97bd-1f7b0915de36": 0
        }
      },
      "107": {
        "members": [
          {
            "API_ID": "ocd-person/e6b8a84b-b408-4f26-9f73-eebca76f3fff",
            "firstName": "Victoria",
            "lastName": "Neave Criado",
            "fullName": "Victoria Neave Criado",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3235.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "107",
              "chamber": "lower",
              "hashID": "SLDL-TX-107-lower-ocd-person-e6b8a84b-b408-4f26-9f73-eebca76f3fff",
              "builtID": "tx-lower-107",
              "externalID": "ocd-person/e6b8a84b-b408-4f26-9f73-eebca76f3fff",
              "geometry": null
            },
            "contactInfo": {
              "email": "victoria.neave@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "317 S. Galloway Suite B, Mesquite, TX 75149",
              "phone1": null,
              "phone2": "972-288-9438",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=107"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-107-lower-ocd-person-e6b8a84b-b408-4f26-9f73-eebca76f3fff": 0
        }
      },
      "108": {
        "members": [
          {
            "API_ID": "ocd-person/2639e096-d327-4c4d-823f-759b1ce373e0",
            "firstName": "Morgan Daniel",
            "lastName": "Meyer",
            "fullName": "Morgan Meyer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3075.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "108",
              "chamber": "lower",
              "hashID": "SLDL-TX-108-lower-ocd-person-2639e096-d327-4c4d-823f-759b1ce373e0",
              "builtID": "tx-lower-108",
              "externalID": "ocd-person/2639e096-d327-4c4d-823f-759b1ce373e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "morgan.meyer@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3131 McKinney Ave. #649, Dallas, TX 75204",
              "phone1": null,
              "phone2": "214-239-1257",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=108"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-108-lower-ocd-person-2639e096-d327-4c4d-823f-759b1ce373e0": 0
        }
      },
      "109": {
        "members": [
          {
            "API_ID": "ocd-person/c5d16702-0b70-4f46-a0ba-3c47acd352f0",
            "firstName": "Carl O.",
            "lastName": "Sherman",
            "fullName": "Carl Sherman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3675.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "109",
              "chamber": "lower",
              "hashID": "SLDL-TX-109-lower-ocd-person-c5d16702-0b70-4f46-a0ba-3c47acd352f0",
              "builtID": "tx-lower-109",
              "externalID": "ocd-person/c5d16702-0b70-4f46-a0ba-3c47acd352f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "carl.sherman@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "103 N. Dallas Ave., Lancaster, TX 75146",
              "phone1": null,
              "phone2": "972-227-1064",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=109"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-109-lower-ocd-person-c5d16702-0b70-4f46-a0ba-3c47acd352f0": 0
        }
      },
      "110": {
        "members": [
          {
            "API_ID": "ocd-person/0acfbb85-b501-45ce-9a9b-90537090a455",
            "firstName": "Toni",
            "lastName": "Rose",
            "fullName": "Toni Rose",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/2555.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "110",
              "chamber": "lower",
              "hashID": "SLDL-TX-110-lower-ocd-person-0acfbb85-b501-45ce-9a9b-90537090a455",
              "builtID": "tx-lower-110",
              "externalID": "ocd-person/0acfbb85-b501-45ce-9a9b-90537090a455",
              "geometry": null
            },
            "contactInfo": {
              "email": "toni.rose@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3155 S. Lancaster Road Suite 220, Dallas, TX 75216",
              "phone1": null,
              "phone2": "214-371-3300",
              "fax1": null,
              "fax2": "214-371-3304",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=110"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-110-lower-ocd-person-0acfbb85-b501-45ce-9a9b-90537090a455": 0
        }
      },
      "111": {
        "members": [
          {
            "API_ID": "ocd-person/3f38bf62-86ba-4833-9737-2e084ae6255d",
            "firstName": "Yvonne",
            "lastName": "Davis",
            "fullName": "Yvonne Davis",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://cdn.ballotpedia.org/images/thumb/0/06/Yvonne_Davis.jpg/200px-Yvonne_Davis.jpg",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "111",
              "chamber": "lower",
              "hashID": "SLDL-TX-111-lower-ocd-person-3f38bf62-86ba-4833-9737-2e084ae6255d",
              "builtID": "tx-lower-111",
              "externalID": "ocd-person/3f38bf62-86ba-4833-9737-2e084ae6255d",
              "geometry": null
            },
            "contactInfo": {
              "email": "yvonne.davis@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5787 S. Hampton Road Suite 447, Dallas, TX 75232",
              "phone1": null,
              "phone2": "214-941-3895",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=111"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-111-lower-ocd-person-3f38bf62-86ba-4833-9737-2e084ae6255d": 0
        }
      },
      "112": {
        "members": [
          {
            "API_ID": "ocd-person/2a9478ef-b573-4de6-ab96-bb3f6091346f",
            "firstName": "Angie Chen",
            "lastName": "Button",
            "fullName": "Angie Button",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/2510.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "112",
              "chamber": "lower",
              "hashID": "SLDL-TX-112-lower-ocd-person-2a9478ef-b573-4de6-ab96-bb3f6091346f",
              "builtID": "tx-lower-112",
              "externalID": "ocd-person/2a9478ef-b573-4de6-ab96-bb3f6091346f",
              "geometry": null
            },
            "contactInfo": {
              "email": "angie.button@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1201 International Parkway Suite 130, Richardson, TX 75081",
              "phone1": null,
              "phone2": "972-234-8980",
              "fax1": null,
              "fax2": "972-470-0789",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=112",
                "https://house.texas.gov/members/2510"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-112-lower-ocd-person-2a9478ef-b573-4de6-ab96-bb3f6091346f": 0
        }
      },
      "113": {
        "members": [
          {
            "API_ID": "ocd-person/69ef7d69-9a5e-42be-b463-8873cb52ad10",
            "firstName": "Rhetta Andrews",
            "lastName": "Bowers",
            "fullName": "Rhetta Bowers",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3565.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "113",
              "chamber": "lower",
              "hashID": "SLDL-TX-113-lower-ocd-person-69ef7d69-9a5e-42be-b463-8873cb52ad10",
              "builtID": "tx-lower-113",
              "externalID": "ocd-person/69ef7d69-9a5e-42be-b463-8873cb52ad10",
              "geometry": null
            },
            "contactInfo": {
              "email": "rhetta.bowers@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3200 Broadway Blvd. Suite 275, Garland, TX 75043",
              "phone1": null,
              "phone2": "972-272-6237",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=113"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-113-lower-ocd-person-69ef7d69-9a5e-42be-b463-8873cb52ad10": 0
        }
      },
      "114": {
        "members": [
          {
            "API_ID": "ocd-person/4e07d8dc-f5b5-47e7-a1de-83f36d3b734f",
            "firstName": "John Wiley",
            "lastName": "Bryant",
            "fullName": "John Bryant",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4120.jpg?v=88.39",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "114",
              "chamber": "lower",
              "hashID": "SLDL-TX-114-lower-ocd-person-4e07d8dc-f5b5-47e7-a1de-83f36d3b734f",
              "builtID": "tx-lower-114",
              "externalID": "ocd-person/4e07d8dc-f5b5-47e7-a1de-83f36d3b734f",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.bryant@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6301 Gaston Ave. Suite 1110, Dallas, TX 75214",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=114",
                "https://house.texas.gov/members/4120"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-114-lower-ocd-person-4e07d8dc-f5b5-47e7-a1de-83f36d3b734f": 0
        }
      },
      "115": {
        "members": [
          {
            "API_ID": "ocd-person/b40c5c95-3141-4ac5-9b0e-28b3b3162bf5",
            "firstName": "Julie",
            "lastName": "Johnson",
            "fullName": "Julie Johnson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3770.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "115",
              "chamber": "lower",
              "hashID": "SLDL-TX-115-lower-ocd-person-b40c5c95-3141-4ac5-9b0e-28b3b3162bf5",
              "builtID": "tx-lower-115",
              "externalID": "ocd-person/b40c5c95-3141-4ac5-9b0e-28b3b3162bf5",
              "geometry": null
            },
            "contactInfo": {
              "email": "julie.johnson@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=115",
                "https://house.texas.gov/members/3770"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-115-lower-ocd-person-b40c5c95-3141-4ac5-9b0e-28b3b3162bf5": 0
        }
      },
      "116": {
        "members": [
          {
            "API_ID": "ocd-person/07f5e898-8d5d-435a-bf97-7bfb26fce5ac",
            "firstName": "Trey",
            "lastName": "Martinez Fischer",
            "fullName": "Trey Martinez Fischer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/2835.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "116",
              "chamber": "lower",
              "hashID": "SLDL-TX-116-lower-ocd-person-07f5e898-8d5d-435a-bf97-7bfb26fce5ac",
              "builtID": "tx-lower-116",
              "externalID": "ocd-person/07f5e898-8d5d-435a-bf97-7bfb26fce5ac",
              "geometry": null
            },
            "contactInfo": {
              "email": "trey.martinezfischer@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4243 E. Piedras Drive Suite 256, San Antonio, TX 78228",
              "phone1": null,
              "phone2": "210-733-8391",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=116"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-116-lower-ocd-person-07f5e898-8d5d-435a-bf97-7bfb26fce5ac": 0
        }
      },
      "117": {
        "members": [
          {
            "API_ID": "ocd-person/12d67093-ab4c-425d-afa3-e5777db09d1c",
            "firstName": "Philip A.",
            "lastName": "Cortez",
            "fullName": "Philip Cortez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/2365.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "117",
              "chamber": "lower",
              "hashID": "SLDL-TX-117-lower-ocd-person-12d67093-ab4c-425d-afa3-e5777db09d1c",
              "builtID": "tx-lower-117",
              "externalID": "ocd-person/12d67093-ab4c-425d-afa3-e5777db09d1c",
              "geometry": null
            },
            "contactInfo": {
              "email": "philip.cortez@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2600 SW Military Drive Suite 211, San Antonio, TX 78224",
              "phone1": null,
              "phone2": "210-923-3638",
              "fax1": null,
              "fax2": "210-923-3646",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=117"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-117-lower-ocd-person-12d67093-ab4c-425d-afa3-e5777db09d1c": 0
        }
      },
      "118": {
        "members": [
          {
            "API_ID": "ocd-person/fbd66d9f-2a93-4cbf-b75d-b51cb01e955e",
            "firstName": "John",
            "lastName": "Lujan",
            "fullName": "John Lujan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3145.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "118",
              "chamber": "lower",
              "hashID": "SLDL-TX-118-lower-ocd-person-fbd66d9f-2a93-4cbf-b75d-b51cb01e955e",
              "builtID": "tx-lower-118",
              "externalID": "ocd-person/fbd66d9f-2a93-4cbf-b75d-b51cb01e955e",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.lujan@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=118",
                "https://house.texas.gov/members/3145"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-118-lower-ocd-person-fbd66d9f-2a93-4cbf-b75d-b51cb01e955e": 0
        }
      },
      "119": {
        "members": [
          {
            "API_ID": "ocd-person/b91e1420-1732-4063-9f3d-edcc5e71d416",
            "firstName": "Elizabeth",
            "lastName": "Campos",
            "fullName": "Liz Campos",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3950.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "119",
              "chamber": "lower",
              "hashID": "SLDL-TX-119-lower-ocd-person-b91e1420-1732-4063-9f3d-edcc5e71d416",
              "builtID": "tx-lower-119",
              "externalID": "ocd-person/b91e1420-1732-4063-9f3d-edcc5e71d416",
              "geometry": null
            },
            "contactInfo": {
              "email": "elizabeth.campos@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3124 Sidney Brooks Suite A, San Antonio, TX 78235",
              "phone1": null,
              "phone2": "210-806-0899",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=119",
                "https://house.texas.gov/members/119"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-119-lower-ocd-person-b91e1420-1732-4063-9f3d-edcc5e71d416": 0
        }
      },
      "120": {
        "members": [
          {
            "API_ID": "ocd-person/9c37e80d-af10-4433-a78a-408b2b798fb6",
            "firstName": "Barbara",
            "lastName": "Gervin-Hawkins",
            "fullName": "Barbara Gervin-Hawkins",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3445.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "120",
              "chamber": "lower",
              "hashID": "SLDL-TX-120-lower-ocd-person-9c37e80d-af10-4433-a78a-408b2b798fb6",
              "builtID": "tx-lower-120",
              "externalID": "ocd-person/9c37e80d-af10-4433-a78a-408b2b798fb6",
              "geometry": null
            },
            "contactInfo": {
              "email": "barbara.gervin-hawkins@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "3503 NE Parkway, San Antonio, TX 78218",
              "phone1": null,
              "phone2": "210-822-2534",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=120",
                "https://house.texas.gov/members/120"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-120-lower-ocd-person-9c37e80d-af10-4433-a78a-408b2b798fb6": 0
        }
      },
      "121": {
        "members": [
          {
            "API_ID": "ocd-person/a8926c8d-b4bb-4b7a-96ae-5dac9987c117",
            "firstName": "Steve",
            "lastName": "Allison",
            "fullName": "Steve Allison",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3800.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "121",
              "chamber": "lower",
              "hashID": "SLDL-TX-121-lower-ocd-person-a8926c8d-b4bb-4b7a-96ae-5dac9987c117",
              "builtID": "tx-lower-121",
              "externalID": "ocd-person/a8926c8d-b4bb-4b7a-96ae-5dac9987c117",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.allison@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1635 NE Loop 410 Suite 506, San Antonio, TX 78209",
              "phone1": null,
              "phone2": "512-463-0686",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=121",
                "https://house.texas.gov/members/3800"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-121-lower-ocd-person-a8926c8d-b4bb-4b7a-96ae-5dac9987c117": 0
        }
      },
      "122": {
        "members": [
          {
            "API_ID": "ocd-person/2c0a4352-9871-4122-97fd-832ec998e907",
            "firstName": "Mark",
            "lastName": "Dorazio",
            "fullName": "Mark Dorazio",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4145.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "122",
              "chamber": "lower",
              "hashID": "SLDL-TX-122-lower-ocd-person-2c0a4352-9871-4122-97fd-832ec998e907",
              "builtID": "tx-lower-122",
              "externalID": "ocd-person/2c0a4352-9871-4122-97fd-832ec998e907",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.dorazio@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4634 De Zavala Road, San Antonio, TX 78249",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=122",
                "https://house.texas.gov/members/122"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-122-lower-ocd-person-2c0a4352-9871-4122-97fd-832ec998e907": 0
        }
      },
      "123": {
        "members": [
          {
            "API_ID": "ocd-person/7847295c-78f3-4f8c-adb6-3536fca2782e",
            "firstName": "Diego M.",
            "lastName": "Bernal",
            "fullName": "Diego Bernal",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3110.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "123",
              "chamber": "lower",
              "hashID": "SLDL-TX-123-lower-ocd-person-7847295c-78f3-4f8c-adb6-3536fca2782e",
              "builtID": "tx-lower-123",
              "externalID": "ocd-person/7847295c-78f3-4f8c-adb6-3536fca2782e",
              "geometry": null
            },
            "contactInfo": {
              "email": "diego.bernal@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "9862 Lorene Lane, Suite 102, San Antonio, TX",
              "phone1": null,
              "phone2": "210-308-9700",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=123",
                "https://house.texas.gov/members/3110"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-123-lower-ocd-person-7847295c-78f3-4f8c-adb6-3536fca2782e": 0
        }
      },
      "124": {
        "members": [
          {
            "API_ID": "ocd-person/1ec50565-379f-4a8b-ab18-c54d31df9929",
            "firstName": "Josey",
            "lastName": "Garcia",
            "fullName": "Josey Garcia",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4170.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "124",
              "chamber": "lower",
              "hashID": "SLDL-TX-124-lower-ocd-person-1ec50565-379f-4a8b-ab18-c54d31df9929",
              "builtID": "tx-lower-124",
              "externalID": "ocd-person/1ec50565-379f-4a8b-ab18-c54d31df9929",
              "geometry": null
            },
            "contactInfo": {
              "email": "josey.garcia@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=124",
                "https://house.texas.gov/members/124"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-124-lower-ocd-person-1ec50565-379f-4a8b-ab18-c54d31df9929": 0
        }
      },
      "125": {
        "members": [
          {
            "API_ID": "ocd-person/01ce2f6b-0500-4baa-a47f-386f9c6286b0",
            "firstName": "Reynaldo Trevino",
            "lastName": "Lopez",
            "fullName": "Ray Lopez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3915.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "125",
              "chamber": "lower",
              "hashID": "SLDL-TX-125-lower-ocd-person-01ce2f6b-0500-4baa-a47f-386f9c6286b0",
              "builtID": "tx-lower-125",
              "externalID": "ocd-person/01ce2f6b-0500-4baa-a47f-386f9c6286b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "ray.lopez@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5309 Wurzbach Road #100-7, San Antonio, TX 78238",
              "phone1": null,
              "phone2": "210-684-5419",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=125"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-125-lower-ocd-person-01ce2f6b-0500-4baa-a47f-386f9c6286b0": 0
        }
      },
      "126": {
        "members": [
          {
            "API_ID": "ocd-person/2db06a9d-ae17-4881-98b4-5172fdfca19f",
            "firstName": "Eric Sam",
            "lastName": "Harless",
            "fullName": "Sam Harless",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3775.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "126",
              "chamber": "lower",
              "hashID": "SLDL-TX-126-lower-ocd-person-2db06a9d-ae17-4881-98b4-5172fdfca19f",
              "builtID": "tx-lower-126",
              "externalID": "ocd-person/2db06a9d-ae17-4881-98b4-5172fdfca19f",
              "geometry": null
            },
            "contactInfo": {
              "email": "sam.harless@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6630 Cypresswood Drive Suite 150, Spring, TX 77379",
              "phone1": null,
              "phone2": "281-251-0194",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=126"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-126-lower-ocd-person-2db06a9d-ae17-4881-98b4-5172fdfca19f": 0
        }
      },
      "127": {
        "members": [
          {
            "API_ID": "ocd-person/41f4a5e2-a2d5-4e54-aae3-9c99f9ea5eba",
            "firstName": "Charles",
            "lastName": "Cunningham",
            "fullName": "Charles Cunningham",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4130.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "127",
              "chamber": "lower",
              "hashID": "SLDL-TX-127-lower-ocd-person-41f4a5e2-a2d5-4e54-aae3-9c99f9ea5eba",
              "builtID": "tx-lower-127",
              "externalID": "ocd-person/41f4a5e2-a2d5-4e54-aae3-9c99f9ea5eba",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.cunningham@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "110 W. Main St., Humble, TX 77338",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=127"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-127-lower-ocd-person-41f4a5e2-a2d5-4e54-aae3-9c99f9ea5eba": 0
        }
      },
      "128": {
        "members": [
          {
            "API_ID": "ocd-person/065c24a0-401a-4dc5-a19c-e7d96dcbffc5",
            "firstName": "Briscoe",
            "lastName": "Cain",
            "fullName": "Briscoe Cain",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3265.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "128",
              "chamber": "lower",
              "hashID": "SLDL-TX-128-lower-ocd-person-065c24a0-401a-4dc5-a19c-e7d96dcbffc5",
              "builtID": "tx-lower-128",
              "externalID": "ocd-person/065c24a0-401a-4dc5-a19c-e7d96dcbffc5",
              "geometry": null
            },
            "contactInfo": {
              "email": "briscoe.cain@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "120 E. 8th St., Suite 23, Deer Park, TX 77536",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=128"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-128-lower-ocd-person-065c24a0-401a-4dc5-a19c-e7d96dcbffc5": 0
        }
      },
      "129": {
        "members": [
          {
            "API_ID": "ocd-person/e05f502a-0d27-4c51-8cad-d3b5eca585b1",
            "firstName": "Dennis Robert",
            "lastName": "Paul",
            "fullName": "Dennis Paul",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3090.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "129",
              "chamber": "lower",
              "hashID": "SLDL-TX-129-lower-ocd-person-e05f502a-0d27-4c51-8cad-d3b5eca585b1",
              "builtID": "tx-lower-129",
              "externalID": "ocd-person/e05f502a-0d27-4c51-8cad-d3b5eca585b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "dennis.paul@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "17225 El Camino Real Blvd. Suite 415, Houston, TX 77058",
              "phone1": null,
              "phone2": "281-488-8900",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=129"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-129-lower-ocd-person-e05f502a-0d27-4c51-8cad-d3b5eca585b1": 0
        }
      },
      "130": {
        "members": [
          {
            "API_ID": "ocd-person/79ffab1c-4d79-4cfa-89b4-5f7ee95ca1bf",
            "firstName": "Tom",
            "lastName": "Oliverson",
            "fullName": "Tom Oliverson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3535.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "130",
              "chamber": "lower",
              "hashID": "SLDL-TX-130-lower-ocd-person-79ffab1c-4d79-4cfa-89b4-5f7ee95ca1bf",
              "builtID": "tx-lower-130",
              "externalID": "ocd-person/79ffab1c-4d79-4cfa-89b4-5f7ee95ca1bf",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.oliverson@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "11803 Grant Road Suite 201, Cypress, TX 77429",
              "phone1": null,
              "phone2": "281-257-4984",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=130",
                "https://house.texas.gov/members/3535"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-130-lower-ocd-person-79ffab1c-4d79-4cfa-89b4-5f7ee95ca1bf": 0
        }
      },
      "131": {
        "members": [
          {
            "API_ID": "ocd-person/2fd6fcd3-9676-42a4-b879-e317576ff18f",
            "firstName": "Alma A.",
            "lastName": "Allen",
            "fullName": "Alma Allen",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/2100.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "131",
              "chamber": "lower",
              "hashID": "SLDL-TX-131-lower-ocd-person-2fd6fcd3-9676-42a4-b879-e317576ff18f",
              "builtID": "tx-lower-131",
              "externalID": "ocd-person/2fd6fcd3-9676-42a4-b879-e317576ff18f",
              "geometry": null
            },
            "contactInfo": {
              "email": "alma.allen@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "10101 Fondren Road Suite 500, Houston, TX 77096",
              "phone1": null,
              "phone2": "713-776-0505",
              "fax1": null,
              "fax2": "713-776-1490",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=131"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-131-lower-ocd-person-2fd6fcd3-9676-42a4-b879-e317576ff18f": 0
        }
      },
      "132": {
        "members": [
          {
            "API_ID": "ocd-person/df010291-7350-4262-9220-4c576730b2db",
            "firstName": "Michael Joseph",
            "lastName": "Schofield",
            "fullName": "Mike Schofield",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3095.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "132",
              "chamber": "lower",
              "hashID": "SLDL-TX-132-lower-ocd-person-df010291-7350-4262-9220-4c576730b2db",
              "builtID": "tx-lower-132",
              "externalID": "ocd-person/df010291-7350-4262-9220-4c576730b2db",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.schofield@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "22910 Colonial Parkway Suite 1001, Katy, TX 77449",
              "phone1": null,
              "phone2": "281-492-0684",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=132",
                "https://house.texas.gov/members/3095"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-132-lower-ocd-person-df010291-7350-4262-9220-4c576730b2db": 0
        }
      },
      "133": {
        "members": [
          {
            "API_ID": "ocd-person/a61b0fd1-9fdf-4b4c-abe6-b790cc856023",
            "firstName": "Mano",
            "lastName": "DeAyala",
            "fullName": "Mano DeAyala",
            "gender": "Male",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/4135.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "133",
              "chamber": "lower",
              "hashID": "SLDL-TX-133-lower-ocd-person-a61b0fd1-9fdf-4b4c-abe6-b790cc856023",
              "builtID": "tx-lower-133",
              "externalID": "ocd-person/a61b0fd1-9fdf-4b4c-abe6-b790cc856023",
              "geometry": null
            },
            "contactInfo": {
              "email": "mano.deayala@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "9525 Katy Freeway Suite 215B, Houston, TX 77024",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=133"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-133-lower-ocd-person-a61b0fd1-9fdf-4b4c-abe6-b790cc856023": 0
        }
      },
      "134": {
        "members": [
          {
            "API_ID": "ocd-person/45f3ddfb-5994-4f05-b2c8-e0e3b2a99c47",
            "firstName": "Annette Elizabeth",
            "lastName": "Johnson",
            "fullName": "Ann Johnson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3985.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "134",
              "chamber": "lower",
              "hashID": "SLDL-TX-134-lower-ocd-person-45f3ddfb-5994-4f05-b2c8-e0e3b2a99c47",
              "builtID": "tx-lower-134",
              "externalID": "ocd-person/45f3ddfb-5994-4f05-b2c8-e0e3b2a99c47",
              "geometry": null
            },
            "contactInfo": {
              "email": "ann.johnson@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5601 W. Loop South Suite C218, Houston, TX 77081",
              "phone1": null,
              "phone2": "713-592-0006",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=134",
                "https://house.texas.gov/members/134"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-134-lower-ocd-person-45f3ddfb-5994-4f05-b2c8-e0e3b2a99c47": 0
        }
      },
      "135": {
        "members": [
          {
            "API_ID": "ocd-person/ab211984-06a9-447c-9ded-564d200bee60",
            "firstName": "Jon E.",
            "lastName": "Rosenthal",
            "fullName": "Jon Rosenthal",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3635.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "135",
              "chamber": "lower",
              "hashID": "SLDL-TX-135-lower-ocd-person-ab211984-06a9-447c-9ded-564d200bee60",
              "builtID": "tx-lower-135",
              "externalID": "ocd-person/ab211984-06a9-447c-9ded-564d200bee60",
              "geometry": null
            },
            "contactInfo": {
              "email": "jon.rosenthal@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=135",
                "https://house.texas.gov/members/135"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-135-lower-ocd-person-ab211984-06a9-447c-9ded-564d200bee60": 0
        }
      },
      "136": {
        "members": [
          {
            "API_ID": "ocd-person/53b19d1e-5b07-4f6e-87a9-27baecddaf70",
            "firstName": "John H.",
            "lastName": "Bucy",
            "fullName": "John Bucy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3595.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "136",
              "chamber": "lower",
              "hashID": "SLDL-TX-136-lower-ocd-person-53b19d1e-5b07-4f6e-87a9-27baecddaf70",
              "builtID": "tx-lower-136",
              "externalID": "ocd-person/53b19d1e-5b07-4f6e-87a9-27baecddaf70",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.bucy@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=136"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-136-lower-ocd-person-53b19d1e-5b07-4f6e-87a9-27baecddaf70": 0
        }
      },
      "137": {
        "members": [
          {
            "API_ID": "ocd-person/5556a81a-eca2-451d-bb29-74cfb00d1959",
            "firstName": "Gene",
            "lastName": "Wu",
            "fullName": "Gene Wu",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/2865.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "137",
              "chamber": "lower",
              "hashID": "SLDL-TX-137-lower-ocd-person-5556a81a-eca2-451d-bb29-74cfb00d1959",
              "builtID": "tx-lower-137",
              "externalID": "ocd-person/5556a81a-eca2-451d-bb29-74cfb00d1959",
              "geometry": null
            },
            "contactInfo": {
              "email": "gene.wu@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6500 Rookin, Building C, Houston, TX 77074",
              "phone1": null,
              "phone2": "713-271-3900",
              "fax1": null,
              "fax2": "713-271-3902",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=137"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-137-lower-ocd-person-5556a81a-eca2-451d-bb29-74cfb00d1959": 0
        }
      },
      "138": {
        "members": [
          {
            "API_ID": "ocd-person/2609ba13-abf1-40b5-9f4c-a4c1135e56e2",
            "firstName": "Lacey",
            "lastName": "Hull",
            "fullName": "Lacey Hull",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3975.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "138",
              "chamber": "lower",
              "hashID": "SLDL-TX-138-lower-ocd-person-2609ba13-abf1-40b5-9f4c-a4c1135e56e2",
              "builtID": "tx-lower-138",
              "externalID": "ocd-person/2609ba13-abf1-40b5-9f4c-a4c1135e56e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "lacey.hull@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "10190 Katy Freeway Suite 555G, Houston, TX 77043",
              "phone1": null,
              "phone2": "713-647-0042",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=138"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-138-lower-ocd-person-2609ba13-abf1-40b5-9f4c-a4c1135e56e2": 0
        }
      },
      "139": {
        "members": [
          {
            "API_ID": "ocd-person/1ffa3f66-7055-4dda-b497-f2d368e6cdc7",
            "firstName": "Jarvis D.",
            "lastName": "Johnson",
            "fullName": "Jarvis Johnson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3185.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "139",
              "chamber": "lower",
              "hashID": "SLDL-TX-139-lower-ocd-person-1ffa3f66-7055-4dda-b497-f2d368e6cdc7",
              "builtID": "tx-lower-139",
              "externalID": "ocd-person/1ffa3f66-7055-4dda-b497-f2d368e6cdc7",
              "geometry": null
            },
            "contactInfo": {
              "email": "jarvis.johnson@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "6112 Wheatley St., Houston, TX 77091",
              "phone1": null,
              "phone2": "713-699-3043",
              "fax1": null,
              "fax2": "713-699-3498",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=139"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-139-lower-ocd-person-1ffa3f66-7055-4dda-b497-f2d368e6cdc7": 0
        }
      },
      "140": {
        "members": [
          {
            "API_ID": "ocd-person/3caafbc8-6561-4bf8-8247-aaf398573ba8",
            "firstName": "Armando Lucio",
            "lastName": "Walle",
            "fullName": "Armando Walle",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4930.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "140",
              "chamber": "lower",
              "hashID": "SLDL-TX-140-lower-ocd-person-3caafbc8-6561-4bf8-8247-aaf398573ba8",
              "builtID": "tx-lower-140",
              "externalID": "ocd-person/3caafbc8-6561-4bf8-8247-aaf398573ba8",
              "geometry": null
            },
            "contactInfo": {
              "email": "armando.walle@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2909 E. Aldine Amphitheatre Drive, Suite 207, Houston, TX 77039",
              "phone1": null,
              "phone2": "281-442-2206",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=140",
                "https://house.texas.gov/members/4930"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-140-lower-ocd-person-3caafbc8-6561-4bf8-8247-aaf398573ba8": 0
        }
      },
      "141": {
        "members": [
          {
            "API_ID": "ocd-person/ace50d18-fc98-4460-9d90-928c0e88c79a",
            "firstName": "Senfronia Calpernia",
            "lastName": "Thompson",
            "fullName": "Senfronia Thompson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4630.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "141",
              "chamber": "lower",
              "hashID": "SLDL-TX-141-lower-ocd-person-ace50d18-fc98-4460-9d90-928c0e88c79a",
              "builtID": "tx-lower-141",
              "externalID": "ocd-person/ace50d18-fc98-4460-9d90-928c0e88c79a",
              "geometry": null
            },
            "contactInfo": {
              "email": "senfronia.thompson@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "10527 Homestead Road, Houston, TX 77016",
              "phone1": null,
              "phone2": "713-633-3390",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=141"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-141-lower-ocd-person-ace50d18-fc98-4460-9d90-928c0e88c79a": 0
        }
      },
      "142": {
        "members": [
          {
            "API_ID": "ocd-person/85d064ed-6f3d-4a81-93ee-1baf49c7f2c3",
            "firstName": "Harold V.",
            "lastName": "Dutton",
            "fullName": "Harold Dutton",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/2650.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "142",
              "chamber": "lower",
              "hashID": "SLDL-TX-142-lower-ocd-person-85d064ed-6f3d-4a81-93ee-1baf49c7f2c3",
              "builtID": "tx-lower-142",
              "externalID": "ocd-person/85d064ed-6f3d-4a81-93ee-1baf49c7f2c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "harold.dutton@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "8799 N. Loop East Suite 305, Houston, TX 77029",
              "phone1": null,
              "phone2": "713-692-9192",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=142"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-142-lower-ocd-person-85d064ed-6f3d-4a81-93ee-1baf49c7f2c3": 0
        }
      },
      "143": {
        "members": [
          {
            "API_ID": "ocd-person/4f98520b-d88b-4053-acc5-3cb2fff14660",
            "firstName": "Ana E.",
            "lastName": "Hernandez",
            "fullName": "Ana Hernandez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3155.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "143",
              "chamber": "lower",
              "hashID": "SLDL-TX-143-lower-ocd-person-4f98520b-d88b-4053-acc5-3cb2fff14660",
              "builtID": "tx-lower-143",
              "externalID": "ocd-person/4f98520b-d88b-4053-acc5-3cb2fff14660",
              "geometry": null
            },
            "contactInfo": {
              "email": "ana.hernandez@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1233 Mercury Drive, Houston, TX 77029",
              "phone1": null,
              "phone2": "713-675-8596",
              "fax1": null,
              "fax2": "713-675-8599",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=143"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-143-lower-ocd-person-4f98520b-d88b-4053-acc5-3cb2fff14660": 0
        }
      },
      "144": {
        "members": [
          {
            "API_ID": "ocd-person/26244d6a-f0d5-4dbe-b9ed-a38df74a7bac",
            "firstName": "Mary Ann",
            "lastName": "Perez",
            "fullName": "Mary Ann Perez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/2535.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "144",
              "chamber": "lower",
              "hashID": "SLDL-TX-144-lower-ocd-person-26244d6a-f0d5-4dbe-b9ed-a38df74a7bac",
              "builtID": "tx-lower-144",
              "externalID": "ocd-person/26244d6a-f0d5-4dbe-b9ed-a38df74a7bac",
              "geometry": null
            },
            "contactInfo": {
              "email": "maryann.perez@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "101 S. Richey St. Suite F107, Pasadena, TX 77506",
              "phone1": null,
              "phone2": "713-740-8153",
              "fax1": null,
              "fax2": "512-463-0763",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=144"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-144-lower-ocd-person-26244d6a-f0d5-4dbe-b9ed-a38df74a7bac": 0
        }
      },
      "145": {
        "members": [
          {
            "API_ID": "ocd-person/791e4399-56bb-4d4c-8561-71f0979fb999",
            "firstName": "Christina",
            "lastName": "Morales",
            "fullName": "Christina Morales",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3910.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "145",
              "chamber": "lower",
              "hashID": "SLDL-TX-145-lower-ocd-person-791e4399-56bb-4d4c-8561-71f0979fb999",
              "builtID": "tx-lower-145",
              "externalID": "ocd-person/791e4399-56bb-4d4c-8561-71f0979fb999",
              "geometry": null
            },
            "contactInfo": {
              "email": "christina.morales@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room Suite 107, 6960 Rustic St. Parking Garage, Building C, Houston, TX 77087",
              "phone1": null,
              "phone2": "713-718-5915",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=145"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-145-lower-ocd-person-791e4399-56bb-4d4c-8561-71f0979fb999": 0
        }
      },
      "146": {
        "members": [
          {
            "API_ID": "ocd-person/26b8304b-24ea-411d-8d85-e348ba6951c7",
            "firstName": "Shawn Nicole",
            "lastName": "Thierry",
            "fullName": "Shawn Thierry",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/3390.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "146",
              "chamber": "lower",
              "hashID": "SLDL-TX-146-lower-ocd-person-26b8304b-24ea-411d-8d85-e348ba6951c7",
              "builtID": "tx-lower-146",
              "externalID": "ocd-person/26b8304b-24ea-411d-8d85-e348ba6951c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "shawn.thierry@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4410 Reed Road, Office #2122, Houston, TX 77051",
              "phone1": null,
              "phone2": "713-667-4146",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=146",
                "https://house.texas.gov/members/146"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-146-lower-ocd-person-26b8304b-24ea-411d-8d85-e348ba6951c7": 0
        }
      },
      "147": {
        "members": [
          {
            "API_ID": "ocd-person/45cd43ff-77b3-467c-aa61-890ebee8ece5",
            "firstName": "Jolanda Felicia",
            "lastName": "Jones",
            "fullName": "Jo Jones",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4105.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "147",
              "chamber": "lower",
              "hashID": "SLDL-TX-147-lower-ocd-person-45cd43ff-77b3-467c-aa61-890ebee8ece5",
              "builtID": "tx-lower-147",
              "externalID": "ocd-person/45cd43ff-77b3-467c-aa61-890ebee8ece5",
              "geometry": null
            },
            "contactInfo": {
              "email": "jolanda.jones@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5445 Almeda Road Suite 501, Houston, TX 77004",
              "phone1": null,
              "phone2": "713-522-0555",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=147"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-147-lower-ocd-person-45cd43ff-77b3-467c-aa61-890ebee8ece5": 0
        }
      },
      "148": {
        "members": [
          {
            "API_ID": "ocd-person/51a281ff-2983-4b4b-baf4-63ee17812835",
            "firstName": "Penny",
            "lastName": "Morales Shaw",
            "fullName": "Penny Morales Shaw",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4035.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "148",
              "chamber": "lower",
              "hashID": "SLDL-TX-148-lower-ocd-person-51a281ff-2983-4b4b-baf4-63ee17812835",
              "builtID": "tx-lower-148",
              "externalID": "ocd-person/51a281ff-2983-4b4b-baf4-63ee17812835",
              "geometry": null
            },
            "contactInfo": {
              "email": "penny.moralesshaw@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "10900 NW Freeway, Suite 210 D, Houston, TX 77092",
              "phone1": null,
              "phone2": "713-861-6721",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=148"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-148-lower-ocd-person-51a281ff-2983-4b4b-baf4-63ee17812835": 0
        }
      },
      "149": {
        "members": [
          {
            "API_ID": "ocd-person/5c2743d2-fe0e-41d6-969d-de830d5787f8",
            "firstName": "Hubert",
            "lastName": "Vo",
            "fullName": "Hubert Vo",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://house.texas.gov/members/photos/4900.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "149",
              "chamber": "lower",
              "hashID": "SLDL-TX-149-lower-ocd-person-5c2743d2-fe0e-41d6-969d-de830d5787f8",
              "builtID": "tx-lower-149",
              "externalID": "ocd-person/5c2743d2-fe0e-41d6-969d-de830d5787f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "hubert.vo@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "7474 S. Kirkwood Suite 106, Houston, TX 77072",
              "phone1": null,
              "phone2": "281-988-0212",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=149"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-149-lower-ocd-person-5c2743d2-fe0e-41d6-969d-de830d5787f8": 0
        }
      },
      "150": {
        "members": [
          {
            "API_ID": "ocd-person/7298c6a9-c6be-41cd-a2ba-0cc79c851373",
            "firstName": "Valoree Hanson",
            "lastName": "Swanson",
            "fullName": "Valoree Swanson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://house.texas.gov/members/photos/3425.jpg?v=88.25",
            "title": "TX Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "150",
              "chamber": "lower",
              "hashID": "SLDL-TX-150-lower-ocd-person-7298c6a9-c6be-41cd-a2ba-0cc79c851373",
              "builtID": "tx-lower-150",
              "externalID": "ocd-person/7298c6a9-c6be-41cd-a2ba-0cc79c851373",
              "geometry": null
            },
            "contactInfo": {
              "email": "valoree.swanson@house.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "23008 Northcrest Drive, Spring, TX 77389",
              "phone1": null,
              "phone2": "281-257-4222",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=150"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-TX-150-lower-ocd-person-7298c6a9-c6be-41cd-a2ba-0cc79c851373": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/8326538c-c907-43b2-a6db-76434036afd7",
            "firstName": "Bryan",
            "lastName": "Hughes",
            "fullName": "Bryan Hughes",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d01/img/Hughes_86-0702D-030-web.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-TX-1-upper-ocd-person-8326538c-c907-43b2-a6db-76434036afd7",
              "builtID": "tx-upper-1",
              "externalID": "ocd-person/8326538c-c907-43b2-a6db-76434036afd7",
              "geometry": null
            },
            "contactInfo": {
              "email": "bryan.hughes@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Texarkana College 2500 N. Robinson Road, Suite 190, Texarkana, TX 75599",
              "phone1": null,
              "phone2": "903-223-7958",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=1"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-1-upper-ocd-person-8326538c-c907-43b2-a6db-76434036afd7": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/013d69b7-a958-490c-a4a2-7224a30efa08",
            "firstName": "Robert Lee",
            "lastName": "Hall",
            "fullName": "Bob Hall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d02/img/headshot.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-TX-2-upper-ocd-person-013d69b7-a958-490c-a4a2-7224a30efa08",
              "builtID": "tx-upper-2",
              "externalID": "ocd-person/013d69b7-a958-490c-a4a2-7224a30efa08",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.hall@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Alliance Building 2 6537 Horizon Road, Suite B-1, Rockwall, TX 75032",
              "phone1": null,
              "phone2": "972-722-3131",
              "fax1": null,
              "fax2": "972-722-3132",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=2"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-2-upper-ocd-person-013d69b7-a958-490c-a4a2-7224a30efa08": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/26b83cfe-10c0-4ac6-ae02-dac30e2856b1",
            "firstName": "Robert Lee",
            "lastName": "Nichols",
            "fullName": "Robert Nichols",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d03/img/headshot.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-TX-3-upper-ocd-person-26b83cfe-10c0-4ac6-ae02-dac30e2856b1",
              "builtID": "tx-upper-3",
              "externalID": "ocd-person/26b83cfe-10c0-4ac6-ae02-dac30e2856b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "robert.nichols@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "769 S. Main St. Suite 100, Lumberton, TX 77657",
              "phone1": null,
              "phone2": "409-755-9893",
              "fax1": null,
              "fax2": "409-755-9899",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=3"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-3-upper-ocd-person-26b83cfe-10c0-4ac6-ae02-dac30e2856b1": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/7adac635-f380-409b-8ec4-1b220bbc5025",
            "firstName": "Charles Brandon",
            "lastName": "Creighton",
            "fullName": "Brandon Creighton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d04/img/BC-Headshot-2020.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-TX-4-upper-ocd-person-7adac635-f380-409b-8ec4-1b220bbc5025",
              "builtID": "tx-upper-4",
              "externalID": "ocd-person/7adac635-f380-409b-8ec4-1b220bbc5025",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandon.creighton@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2829 Technology Forest Suite 240, Woodlands, TX 77381",
              "phone1": null,
              "phone2": "281-292-4128",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=4"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-4-upper-ocd-person-7adac635-f380-409b-8ec4-1b220bbc5025": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/d7d0bd69-2615-4d0c-84df-4cdb75809343",
            "firstName": "Charles J.",
            "lastName": "Schwertner",
            "fullName": "Charles Schwertner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d05/img/headshot.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-TX-5-upper-ocd-person-d7d0bd69-2615-4d0c-84df-4cdb75809343",
              "builtID": "tx-upper-5",
              "externalID": "ocd-person/d7d0bd69-2615-4d0c-84df-4cdb75809343",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.schwertner@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Block 27, 501 S. Austin Ave., Georgetown, TX 78626",
              "phone1": null,
              "phone2": "512-863-8456",
              "fax1": null,
              "fax2": "512-863-8436",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=5"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-5-upper-ocd-person-d7d0bd69-2615-4d0c-84df-4cdb75809343": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/e78373de-6d96-44db-b552-6da34ed992fc",
            "firstName": "Carol",
            "lastName": "Alvarado",
            "fullName": "Carol Alvarado",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senate.texas.gov/members/d06/img/CA-2018_web.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-TX-6-upper-ocd-person-e78373de-6d96-44db-b552-6da34ed992fc",
              "builtID": "tx-upper-6",
              "externalID": "ocd-person/e78373de-6d96-44db-b552-6da34ed992fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "carol.alvarado@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4450 Harrisburg Suite 436, Houston, TX 77011",
              "phone1": null,
              "phone2": "713-926-6257",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=6"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-6-upper-ocd-person-e78373de-6d96-44db-b552-6da34ed992fc": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/a9f861bf-1a13-4d3b-816a-69589e9b25e7",
            "firstName": "Paul David",
            "lastName": "Bettencourt",
            "fullName": "Paul Bettencourt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d07/img/headshot.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-TX-7-upper-ocd-person-a9f861bf-1a13-4d3b-816a-69589e9b25e7",
              "builtID": "tx-upper-7",
              "externalID": "ocd-person/a9f861bf-1a13-4d3b-816a-69589e9b25e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.bettencourt@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "11451 Katy Freeway Suite 209, Houston, TX 77079",
              "phone1": null,
              "phone2": "713-464-0282",
              "fax1": null,
              "fax2": "713-461-0108",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=7"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-7-upper-ocd-person-a9f861bf-1a13-4d3b-816a-69589e9b25e7": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/895887ba-73cd-4321-827d-bb06d4eedfdf",
            "firstName": "Angela Allen",
            "lastName": "Paxton",
            "fullName": "Angela Paxton",
            "gender": "Female",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d08/img/Paxton_2019.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-TX-8-upper-ocd-person-895887ba-73cd-4321-827d-bb06d4eedfdf",
              "builtID": "tx-upper-8",
              "externalID": "ocd-person/895887ba-73cd-4321-827d-bb06d4eedfdf",
              "geometry": null
            },
            "contactInfo": {
              "email": "angela.paxton@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "604 S. Watters Road Suite 100, Allen, TX 75013",
              "phone1": null,
              "phone2": "972-908-3424",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=8"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-8-upper-ocd-person-895887ba-73cd-4321-827d-bb06d4eedfdf": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/043dfef3-3605-4c75-b72d-965985edfdd5",
            "firstName": "Kelly Gene",
            "lastName": "Hancock",
            "fullName": "Kelly Hancock",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d09/img/Hancock_87-0441D-110-web.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-TX-9-upper-ocd-person-043dfef3-3605-4c75-b72d-965985edfdd5",
              "builtID": "tx-upper-9",
              "externalID": "ocd-person/043dfef3-3605-4c75-b72d-965985edfdd5",
              "geometry": null
            },
            "contactInfo": {
              "email": "kelly.hancock@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "306 W. 7th St. Suite 508, Fort Worth, TX 76102",
              "phone1": null,
              "phone2": "817-332-1131",
              "fax1": null,
              "fax2": "817-332-1176",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=9"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-9-upper-ocd-person-043dfef3-3605-4c75-b72d-965985edfdd5": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/98bef50a-73c7-4139-9b8e-628d0e89d659",
            "firstName": "Phillip Stephen",
            "lastName": "King",
            "fullName": "Phil King",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d10/img/Phil-King-Headshot-2023.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-TX-10-upper-ocd-person-98bef50a-73c7-4139-9b8e-628d0e89d659",
              "builtID": "tx-upper-10",
              "externalID": "ocd-person/98bef50a-73c7-4139-9b8e-628d0e89d659",
              "geometry": null
            },
            "contactInfo": {
              "email": "phil.king@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Nan and Bob Kingsley Building 1710 Martin Drive, Weatherford, TX 76086",
              "phone1": null,
              "phone2": "817-596-4796",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=61",
                "https://senate.texas.gov/member.php?d=10"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-10-upper-ocd-person-98bef50a-73c7-4139-9b8e-628d0e89d659": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/1399b6ba-cbfa-45ca-bf91-6ba89fe777bf",
            "firstName": "David Mayes",
            "lastName": "Middleton",
            "fullName": "Mayes Middleton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d11/img/Middleton_87-0662D-009.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-TX-11-upper-ocd-person-1399b6ba-cbfa-45ca-bf91-6ba89fe777bf",
              "builtID": "tx-upper-11",
              "externalID": "ocd-person/1399b6ba-cbfa-45ca-bf91-6ba89fe777bf",
              "geometry": null
            },
            "contactInfo": {
              "email": "mayes.middleton@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "825 Rollingbrook Drive Suite B, Baytown, TX 77521",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=23",
                "https://senate.texas.gov/member.php?d=11"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-11-upper-ocd-person-1399b6ba-cbfa-45ca-bf91-6ba89fe777bf": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/fa4817bf-6dc6-4deb-9965-cf3f208ec976",
            "firstName": "Nathaniel Willis",
            "lastName": "Parker",
            "fullName": "Tan Parker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d12/img/86_20190619_SAM_0008.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-TX-12-upper-ocd-person-fa4817bf-6dc6-4deb-9965-cf3f208ec976",
              "builtID": "tx-upper-12",
              "externalID": "ocd-person/fa4817bf-6dc6-4deb-9965-cf3f208ec976",
              "geometry": null
            },
            "contactInfo": {
              "email": "tan.parker@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Weatherford College 502 Big Sandy Court, Bridgeport, TX 76426",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=63",
                "https://www.txdirectory.com/online/person/?id=28288",
                "https://senate.texas.gov/member.php?d=12"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-12-upper-ocd-person-fa4817bf-6dc6-4deb-9965-cf3f208ec976": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/3475b324-1c9b-4513-90e6-063aba5ac04e",
            "firstName": "Borris L.",
            "lastName": "Miles",
            "fullName": "Borris Miles",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senate.texas.gov/members/d13/img/87-0267D-008-web.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-TX-13-upper-ocd-person-3475b324-1c9b-4513-90e6-063aba5ac04e",
              "builtID": "tx-upper-13",
              "externalID": "ocd-person/3475b324-1c9b-4513-90e6-063aba5ac04e",
              "geometry": null
            },
            "contactInfo": {
              "email": "borris.miles@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5302 Almeda Road Suite A, Houston, TX 77004",
              "phone1": null,
              "phone2": "713-665-8322",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=13"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-13-upper-ocd-person-3475b324-1c9b-4513-90e6-063aba5ac04e": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/20cda9bf-a1e6-4427-8115-e5655ddd8eb0",
            "firstName": "Sarah",
            "lastName": "Eckhardt",
            "fullName": "Sarah Eckhardt",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senate.texas.gov/members/d14/img/SE_headshot_2020.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-TX-14-upper-ocd-person-20cda9bf-a1e6-4427-8115-e5655ddd8eb0",
              "builtID": "tx-upper-14",
              "externalID": "ocd-person/20cda9bf-a1e6-4427-8115-e5655ddd8eb0",
              "geometry": null
            },
            "contactInfo": {
              "email": "sarah.eckhardt@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=14"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-14-upper-ocd-person-20cda9bf-a1e6-4427-8115-e5655ddd8eb0": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/06aed58e-f403-43a5-b4b9-8785a4acf981",
            "firstName": "Molly",
            "lastName": "Cook",
            "fullName": "Molly Cook",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/mcook.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-TX-15-upper-ocd-person-06aed58e-f403-43a5-b4b9-8785a4acf981",
              "builtID": "tx-upper-15",
              "externalID": "ocd-person/06aed58e-f403-43a5-b4b9-8785a4acf981",
              "geometry": null
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=15"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-15-upper-ocd-person-06aed58e-f403-43a5-b4b9-8785a4acf981": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/dee454b1-6da0-4b1b-a232-033e7de8551f",
            "firstName": "Nathan Matthew",
            "lastName": "Johnson",
            "fullName": "Nathan Johnson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senate.texas.gov/members/d16/img/SNJ-Headshot-2023-web.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-TX-16-upper-ocd-person-dee454b1-6da0-4b1b-a232-033e7de8551f",
              "builtID": "tx-upper-16",
              "externalID": "ocd-person/dee454b1-6da0-4b1b-a232-033e7de8551f",
              "geometry": null
            },
            "contactInfo": {
              "email": "nathan.johnson@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Merit Tower 12222 Merit Drive, Suite 1010, Dallas, TX 75251",
              "phone1": null,
              "phone2": "972-701-0349",
              "fax1": null,
              "fax2": "972-661-1512",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=16"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-16-upper-ocd-person-dee454b1-6da0-4b1b-a232-033e7de8551f": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/1997b231-45f7-4f29-a435-9f52e60ebea4",
            "firstName": "Joan J.",
            "lastName": "Huffman",
            "fullName": "Joan Huffman",
            "gender": "Female",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d17/img/Huffman_9S5A0096-2021web.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-TX-17-upper-ocd-person-1997b231-45f7-4f29-a435-9f52e60ebea4",
              "builtID": "tx-upper-17",
              "externalID": "ocd-person/1997b231-45f7-4f29-a435-9f52e60ebea4",
              "geometry": null
            },
            "contactInfo": {
              "email": "joan.huffman@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "16010 Barkers Point Lane Suite 265, Houston, TX 77079",
              "phone1": null,
              "phone2": "281-497-2450",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=17"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-17-upper-ocd-person-1997b231-45f7-4f29-a435-9f52e60ebea4": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/28eb71be-bce7-4b32-908d-289ecc265c3c",
            "firstName": "Lois W.",
            "lastName": "Kolkhorst",
            "fullName": "Lois Kolkhorst",
            "gender": "Female",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d18/img/LWK_2020.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-TX-18-upper-ocd-person-28eb71be-bce7-4b32-908d-289ecc265c3c",
              "builtID": "tx-upper-18",
              "externalID": "ocd-person/28eb71be-bce7-4b32-908d-289ecc265c3c",
              "geometry": null
            },
            "contactInfo": {
              "email": "lois.kolkhorst@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "311 E. Constitution St. Suite 120, Victoria, TX 77901",
              "phone1": null,
              "phone2": "361-573-7300",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=18"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-18-upper-ocd-person-28eb71be-bce7-4b32-908d-289ecc265c3c": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/68767ec3-151c-413f-aeb8-f35dd6c5f35c",
            "firstName": "Roland",
            "lastName": "Gutierrez",
            "fullName": "Roland Gutierrez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senate.texas.gov/members/d19/img/Gutierrez_headshot_2021.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-TX-19-upper-ocd-person-68767ec3-151c-413f-aeb8-f35dd6c5f35c",
              "builtID": "tx-upper-19",
              "externalID": "ocd-person/68767ec3-151c-413f-aeb8-f35dd6c5f35c",
              "geometry": null
            },
            "contactInfo": {
              "email": "roland.gutierrez@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2401 Garner Field Road Building Q2, Uvalde, TX 78801",
              "phone1": null,
              "phone2": "830-278-1234",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=119",
                "https://senate.texas.gov/member.php?d=19"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-19-upper-ocd-person-68767ec3-151c-413f-aeb8-f35dd6c5f35c": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/72766791-084c-4a20-a12c-6e809b51e95c",
            "firstName": "Juan Jesus",
            "lastName": "Hinojosa",
            "fullName": "Chuy Hinojosa",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senate.texas.gov/members/d20/img/Hinojosa_84-0784D-006.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-TX-20-upper-ocd-person-72766791-084c-4a20-a12c-6e809b51e95c",
              "builtID": "tx-upper-20",
              "externalID": "ocd-person/72766791-084c-4a20-a12c-6e809b51e95c",
              "geometry": null
            },
            "contactInfo": {
              "email": "juan.hinojosa@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "602 N. Staples St. Suite 200, Corpus Christi, TX 78401",
              "phone1": null,
              "phone2": "361-882-0900",
              "fax1": null,
              "fax2": "361-882-0902",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=20"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-20-upper-ocd-person-72766791-084c-4a20-a12c-6e809b51e95c": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/c7aad3b7-ca16-4989-bf68-87ed72498a6b",
            "firstName": "Judith Pappas",
            "lastName": "Zaffirini",
            "fullName": "Judith Zaffirini",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senate.texas.gov/members/d21/img/Zaffirini_2017.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-TX-21-upper-ocd-person-c7aad3b7-ca16-4989-bf68-87ed72498a6b",
              "builtID": "tx-upper-21",
              "externalID": "ocd-person/c7aad3b7-ca16-4989-bf68-87ed72498a6b",
              "geometry": null
            },
            "contactInfo": {
              "email": "judith.zaffirini@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1407 Washington St., Laredo, TX 78040",
              "phone1": null,
              "phone2": "956-722-2293",
              "fax1": null,
              "fax2": "956-722-8586",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=21"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-21-upper-ocd-person-c7aad3b7-ca16-4989-bf68-87ed72498a6b": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/44a23037-5ccd-4781-9e93-c4b950f74355",
            "firstName": "Brian D.",
            "lastName": "Birdwell",
            "fullName": "Brian Birdwell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d22/img/headshot.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-TX-22-upper-ocd-person-44a23037-5ccd-4781-9e93-c4b950f74355",
              "builtID": "tx-upper-22",
              "externalID": "ocd-person/44a23037-5ccd-4781-9e93-c4b950f74355",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.birdwell@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "900 Austin Ave. Suite 500, Waco, TX 76701",
              "phone1": null,
              "phone2": "254-772-6225",
              "fax1": null,
              "fax2": "254-776-2843",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=22"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-22-upper-ocd-person-44a23037-5ccd-4781-9e93-c4b950f74355": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/d6f40682-7b90-4735-980c-7c95ef402a78",
            "firstName": "Royce Barry",
            "lastName": "West",
            "fullName": "Royce West",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senate.texas.gov/members/d23/img/West_86-0535D-073_web.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-TX-23-upper-ocd-person-d6f40682-7b90-4735-980c-7c95ef402a78",
              "builtID": "tx-upper-23",
              "externalID": "ocd-person/d6f40682-7b90-4735-980c-7c95ef402a78",
              "geometry": null
            },
            "contactInfo": {
              "email": "royce.west@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5787 S. Hampton Road Suite 385, Dallas, TX 75232",
              "phone1": null,
              "phone2": "214-467-0123",
              "fax1": null,
              "fax2": "214-467-0050",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=23"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-23-upper-ocd-person-d6f40682-7b90-4735-980c-7c95ef402a78": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/682e8ef8-8fc1-4490-a04d-63a7c6d92185",
            "firstName": "Peter P.",
            "lastName": "Flores",
            "fullName": "Pete Flores",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d24/img/Flores_86-0634D-001-web.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-TX-24-upper-ocd-person-682e8ef8-8fc1-4490-a04d-63a7c6d92185",
              "builtID": "tx-upper-24",
              "externalID": "ocd-person/682e8ef8-8fc1-4490-a04d-63a7c6d92185",
              "geometry": null
            },
            "contactInfo": {
              "email": "pete.flores@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Schreiner One Center 819 Water St., Suite 164, Kerrville, TX 78028",
              "phone1": null,
              "phone2": "830-896-9816",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=24"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-24-upper-ocd-person-682e8ef8-8fc1-4490-a04d-63a7c6d92185": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/9f80cd7f-1067-4322-8777-62bc129cd6f2",
            "firstName": "Donna Sue Burrows",
            "lastName": "Campbell",
            "fullName": "Donna Campbell",
            "gender": "Female",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d25/img/headshot.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-TX-25-upper-ocd-person-9f80cd7f-1067-4322-8777-62bc129cd6f2",
              "builtID": "tx-upper-25",
              "externalID": "ocd-person/9f80cd7f-1067-4322-8777-62bc129cd6f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "donna.campbell@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "229 Hunters Village Suite 105, New Braunfels, TX 78132",
              "phone1": null,
              "phone2": "830-626-0065",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=25"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-25-upper-ocd-person-9f80cd7f-1067-4322-8777-62bc129cd6f2": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/8bb90ee9-e764-44da-b27f-b5bc3cdb394e",
            "firstName": "Jose Antonio",
            "lastName": "Menendez",
            "fullName": "José Menéndez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senate.texas.gov/members/d26/img/headshot.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-TX-26-upper-ocd-person-8bb90ee9-e764-44da-b27f-b5bc3cdb394e",
              "builtID": "tx-upper-26",
              "externalID": "ocd-person/8bb90ee9-e764-44da-b27f-b5bc3cdb394e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jose.menendez@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4522 Fredericksburg Road, A-22, San Antonio, TX 78201",
              "phone1": null,
              "phone2": "210-733-6604",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=26"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-26-upper-ocd-person-8bb90ee9-e764-44da-b27f-b5bc3cdb394e": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/df4db9dd-3640-4eaf-bc7e-1ce125a57199",
            "firstName": "Morgan",
            "lastName": "LaMantia",
            "fullName": "Morgan LaMantia",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://senate.texas.gov/members/d27/img/Lamantia-00271-Edit-web.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-TX-27-upper-ocd-person-df4db9dd-3640-4eaf-bc7e-1ce125a57199",
              "builtID": "tx-upper-27",
              "externalID": "ocd-person/df4db9dd-3640-4eaf-bc7e-1ce125a57199",
              "geometry": null
            },
            "contactInfo": {
              "email": "morgan.lamantia@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "700 Farm to Market Road 3168, Raymondville, TX 78580",
              "phone1": null,
              "phone2": "956-542-2211",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=27"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-27-upper-ocd-person-df4db9dd-3640-4eaf-bc7e-1ce125a57199": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/6522ba2d-f263-455a-9470-cec0aa03033b",
            "firstName": "Charles",
            "lastName": "Perry",
            "fullName": "Charles Perry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d28/img/Perry-Headshot-2019.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-TX-28-upper-ocd-person-6522ba2d-f263-455a-9470-cec0aa03033b",
              "builtID": "tx-upper-28",
              "externalID": "ocd-person/6522ba2d-f263-455a-9470-cec0aa03033b",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.perry@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "36 W. Beauregard Ave. #510, San Angelo, TX 76903",
              "phone1": null,
              "phone2": "325-481-0028",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=28"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-28-upper-ocd-person-6522ba2d-f263-455a-9470-cec0aa03033b": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/3e0f0e51-20c0-4da4-82b5-ff81606148d0",
            "firstName": "Cesar Jose",
            "lastName": "Blanco",
            "fullName": "César Blanco",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://senate.texas.gov/members/d29/img/SCBBio88th-web.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-TX-29-upper-ocd-person-3e0f0e51-20c0-4da4-82b5-ff81606148d0",
              "builtID": "tx-upper-29",
              "externalID": "ocd-person/3e0f0e51-20c0-4da4-82b5-ff81606148d0",
              "geometry": null
            },
            "contactInfo": {
              "email": "cesar.blanco@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 126, 1618 Airport Drive, Fort Stockton, TX 79735",
              "phone1": null,
              "phone2": "432-336-2910",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.texas.gov/members/member-page/?district=76",
                "https://senate.texas.gov/member.php?d=29"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-29-upper-ocd-person-3e0f0e51-20c0-4da4-82b5-ff81606148d0": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/f1332c39-f120-4ccd-ac64-cae40b2be91a",
            "firstName": "Drew Alan",
            "lastName": "Springer",
            "fullName": "Drew Springer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d30/img/Springer_87-0036D-009-web.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-TX-30-upper-ocd-person-f1332c39-f120-4ccd-ac64-cae40b2be91a",
              "builtID": "tx-upper-30",
              "externalID": "ocd-person/f1332c39-f120-4ccd-ac64-cae40b2be91a",
              "geometry": null
            },
            "contactInfo": {
              "email": "drew.springer@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Denton Square Business Center 723 S-I35 East, Suite 222, Denton, TX 76205",
              "phone1": null,
              "phone2": "940-323-9338",
              "fax1": null,
              "fax2": "940-323-9341",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=30"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-30-upper-ocd-person-f1332c39-f120-4ccd-ac64-cae40b2be91a": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/aa6deb86-b21b-4ed2-ad67-bd6217238afc",
            "firstName": "Kevin",
            "lastName": "Sparks",
            "fullName": "Kevin Sparks",
            "gender": "Male",
            "party": "Republican",
            "image": "https://senate.texas.gov/members/d31/img/Sparks_88-0063D-012-web.jpg",
            "title": "TX Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "TX",
              "stateFull": "Texas",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-TX-31-upper-ocd-person-aa6deb86-b21b-4ed2-ad67-bd6217238afc",
              "builtID": "tx-upper-31",
              "externalID": "ocd-person/aa6deb86-b21b-4ed2-ad67-bd6217238afc",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.sparks@senate.texas.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 2463, Big Spring, TX 79721",
              "phone1": null,
              "phone2": "432-994-2377",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://senate.texas.gov/member.php?d=31"],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-TX-31-upper-ocd-person-aa6deb86-b21b-4ed2-ad67-bd6217238afc": 0
        }
      }
    }
  },
  "UT": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "UT",
          "stateFull": "Utah",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "UT",
            "stateFull": "Utah",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-UT---",
            "builtID": "ut--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-UT---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "L000577",
          "in_office": true,
          "firstName": "Mike",
          "lastName": "Lee",
          "middleName": null,
          "fullName": "Mike Lee",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/L000577.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "UT",
            "stateFull": "Utah",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-UT---L000577",
            "builtID": "ut--",
            "externalID": "L000577",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenMikeLee",
            "youtube": "senatormikelee",
            "instagram": null,
            "facebook": "senatormikelee",
            "urls": ["https://www.lee.senate.gov"],
            "rss_url": "https://www.lee.senate.gov/public/?a=rss.feed"
          },
          "title": "UT Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/L000577.json",
          "govtrack_id": "412495",
          "cspan_id": "9267977",
          "votesmart_id": "66395",
          "icpsr_id": "41110",
          "crp_id": "N00031696",
          "google_entity_id": "/m/09v5q9x",
          "state_rank": "senior",
          "lis_id": "S346",
          "suffix": null,
          "date_of_birth": "1971-06-04",
          "leadership_role": null,
          "fec_candidate_id": "S0UT00165",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "13",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 32,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 5.78,
          "votes_with_party_pct": 83.79,
          "votes_against_party_pct": 16.21,
          "ocd_id": "ocd-division/country:us/state:ut"
        },
        {
          "API_ID": "R000615",
          "in_office": true,
          "firstName": "Mitt",
          "lastName": "Romney",
          "middleName": null,
          "fullName": "Mitt Romney",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/R000615.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "UT",
            "stateFull": "Utah",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-UT---R000615",
            "builtID": "ut--",
            "externalID": "R000615",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.romney.senate.gov/contact-senator-romney",
            "address1": "354 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-5251",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorRomney",
            "youtube": null,
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.romney.senate.gov"],
            "rss_url": null
          },
          "title": "UT Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/R000615.json",
          "govtrack_id": "412841",
          "cspan_id": null,
          "votesmart_id": "21942",
          "icpsr_id": null,
          "crp_id": "N00000286",
          "google_entity_id": "/m/0271_s",
          "state_rank": "junior",
          "lis_id": "S401",
          "suffix": null,
          "date_of_birth": "1947-03-12",
          "leadership_role": null,
          "fec_candidate_id": "S8UT00176",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 37,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 6.68,
          "votes_with_party_pct": 82.64,
          "votes_against_party_pct": 17.36,
          "ocd_id": "ocd-division/country:us/state:ut"
        }
      ],
      "hash": { "SENATE-UT---L000577": 0, "SENATE-UT---R000615": 1 }
    },
    "HOUSE": {
      "03": {
        "members": [
          {
            "API_ID": "C001114",
            "in_office": true,
            "firstName": "John",
            "lastName": "Curtis",
            "middleName": null,
            "fullName": "John R. Curtis",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001114.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-UT-03--C001114",
              "builtID": "ut--03",
              "externalID": "C001114",
              "geometry": null,
              "geoid": "4903"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2323 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-7751",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJohnCurtis",
              "youtube": null,
              "instagram": null,
              "facebook": "RepJohnCurtis",
              "urls": ["https://curtis.house.gov"],
              "rss_url": "https://curtis.house.gov/category/press-releases/feed/"
            },
            "title": "UT House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001114.json",
            "govtrack_id": "412740",
            "cspan_id": null,
            "votesmart_id": "123390",
            "icpsr_id": "21755",
            "crp_id": "N00041221",
            "google_entity_id": "/m/0bmdhtb",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1960-05-10",
            "leadership_role": null,
            "fec_candidate_id": "H8UT03238",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 57,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 5.4,
            "votes_with_party_pct": 90.19,
            "votes_against_party_pct": 7.87,
            "ocd_id": "ocd-division/country:us/state:ut/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-UT-03--C001114": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "M001228",
            "in_office": true,
            "firstName": "Celeste",
            "lastName": "Maloy",
            "middleName": null,
            "fullName": "Celeste Maloy",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001228.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-UT-02--M001228",
              "builtID": "ut--02",
              "externalID": "M001228",
              "geometry": null,
              "geoid": "4902"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [],
              "rss_url": ""
            },
            "title": "UT House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001228.json",
            "govtrack_id": null,
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1981-05-22",
            "leadership_role": null,
            "fec_candidate_id": null,
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 384,
            "missed_votes": 6,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.56,
            "votes_with_party_pct": 96.2,
            "votes_against_party_pct": 3.53,
            "ocd_id": "ocd-division/country:us/state:ut/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-UT-02--M001228": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "M001213",
            "in_office": true,
            "firstName": "Blake",
            "lastName": "Moore",
            "middleName": null,
            "fullName": "Blake D. Moore",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001213.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-UT-01--M001213",
              "builtID": "ut--01",
              "externalID": "M001213",
              "geometry": null,
              "geoid": "4901"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1131 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-0453",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBlakeMoore",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://blakemoore.house.gov"],
              "rss_url": null
            },
            "title": "UT House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001213.json",
            "govtrack_id": "456851",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00046598",
            "google_entity_id": "/g/11ftchfsgn",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1980-06-22",
            "leadership_role": null,
            "fec_candidate_id": "H0UT01205",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 14,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.33,
            "votes_with_party_pct": 87.86,
            "votes_against_party_pct": 10.19,
            "ocd_id": "ocd-division/country:us/state:ut/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-UT-01--M001213": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "O000086",
            "in_office": true,
            "firstName": "Burgess",
            "lastName": "Owens",
            "middleName": null,
            "fullName": "Burgess Owens",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/O000086.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-UT-04--O000086",
              "builtID": "ut--04",
              "externalID": "O000086",
              "geometry": null,
              "geoid": "4904"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "309 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3011",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBurgessOwens",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://owens.house.gov"],
              "rss_url": null
            },
            "title": "UT House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/O000086.json",
            "govtrack_id": "456852",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00045812",
            "google_entity_id": "/m/0286z2f",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1951-08-02",
            "leadership_role": null,
            "fec_candidate_id": "H0UT04076",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 5,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.47,
            "votes_with_party_pct": 91.65,
            "votes_against_party_pct": 6.41,
            "ocd_id": "ocd-division/country:us/state:ut/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-UT-04--O000086": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/faa88790-ac92-4a09-b00e-7049cc8521b6",
            "firstName": "Thomas",
            "lastName": "Peterson",
            "fullName": "Tom Peterson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/PETERT.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-UT-1-lower-ocd-person-faa88790-ac92-4a09-b00e-7049cc8521b6",
              "builtID": "ut-lower-1",
              "externalID": "ocd-person/faa88790-ac92-4a09-b00e-7049cc8521b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "tpeterson@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=PETERT",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist01.pdf",
                "https://house.utah.gov/rep/PETERT/",
                "https://house.utleg.gov/rep/PETERT/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-1-lower-ocd-person-faa88790-ac92-4a09-b00e-7049cc8521b6": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/e9792114-e077-4663-96c4-94d679c66ace",
            "firstName": "Michael J.",
            "lastName": "Petersen",
            "fullName": "Mike Petersen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/PETERM.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-UT-2-lower-ocd-person-e9792114-e077-4663-96c4-94d679c66ace",
              "builtID": "ut-lower-2",
              "externalID": "ocd-person/e9792114-e077-4663-96c4-94d679c66ace",
              "geometry": null
            },
            "contactInfo": {
              "email": "mpetersen@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/PETERM",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=PETERM",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist03.pdf",
                "https://house.utah.gov/rep/PETERM/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=PETERM",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415376",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist02.pdf",
                "https://house.utleg.gov/rep/PETERM/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-2-lower-ocd-person-e9792114-e077-4663-96c4-94d679c66ace": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/93cbbcce-172d-4857-b12d-3ea97f10ef66",
            "firstName": "Dan N.",
            "lastName": "Johnson",
            "fullName": "Dan Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/JOHNSDN.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-UT-3-lower-ocd-person-93cbbcce-172d-4857-b12d-3ea97f10ef66",
              "builtID": "ut-lower-3",
              "externalID": "ocd-person/93cbbcce-172d-4857-b12d-3ea97f10ef66",
              "geometry": null
            },
            "contactInfo": {
              "email": "dnjohnson@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/JOHNSDN",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=JOHNSDN",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist04.pdf",
                "https://le.utah.gov/house2/CofI/COFIJOHNSDN.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414963",
                "https://house.utah.gov/rep/JOHNSDN/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=JOHNSDN",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist03.pdf",
                "https://house.utleg.gov/rep/JOHNSDN/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-3-lower-ocd-person-93cbbcce-172d-4857-b12d-3ea97f10ef66": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/c4a52e84-76f2-48e1-ada3-2fe4d9a12d00",
            "firstName": "Kera Yates",
            "lastName": "Birkeland",
            "fullName": "Kera Birkeland",
            "gender": "Female",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/BIRKEK.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-UT-4-lower-ocd-person-c4a52e84-76f2-48e1-ada3-2fe4d9a12d00",
              "builtID": "ut-lower-4",
              "externalID": "ocd-person/c4a52e84-76f2-48e1-ada3-2fe4d9a12d00",
              "geometry": null
            },
            "contactInfo": {
              "email": "kbirkeland@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/BIRKEK",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=BIRKEK",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist53.pdf",
                "https://house.utah.gov/rep/BIRKEK/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=BIRKEK",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415564",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist04.pdf",
                "https://house.utleg.gov/rep/BIRKEK/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-4-lower-ocd-person-c4a52e84-76f2-48e1-ada3-2fe4d9a12d00": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/5d8ada2a-75c6-4aa6-b359-29720794fb7e",
            "firstName": "Casey D.",
            "lastName": "Snider",
            "fullName": "Casey Snider",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/SNIDEC.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-UT-5-lower-ocd-person-5d8ada2a-75c6-4aa6-b359-29720794fb7e",
              "builtID": "ut-lower-5",
              "externalID": "ocd-person/5d8ada2a-75c6-4aa6-b359-29720794fb7e",
              "geometry": null
            },
            "contactInfo": {
              "email": "csnider@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/SNIDEC",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=SNIDEC",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist05.pdf",
                "https://le.utah.gov/house2/CofI/COFISNIDEC3.pdf",
                "https://le.utah.gov/house2/CofI/COFISNIDEC2.pdf",
                "https://le.utah.gov/house2/CofI/COFISNIDEC1.pdf",
                "https://le.utah.gov/house2/CofI/COFISNIDEC.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414881",
                "https://house.utah.gov/rep/SNIDEC/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=SNIDEC",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist05.pdf",
                "https://house.utleg.gov/rep/SNIDEC/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-5-lower-ocd-person-5d8ada2a-75c6-4aa6-b359-29720794fb7e": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/8d2d4368-9f6a-4f8e-a7a6-782b3cb23eda",
            "firstName": "Matthew H.",
            "lastName": "Gwynn",
            "fullName": "Matt Gwynn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/GWYNNM.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-UT-6-lower-ocd-person-8d2d4368-9f6a-4f8e-a7a6-782b3cb23eda",
              "builtID": "ut-lower-6",
              "externalID": "ocd-person/8d2d4368-9f6a-4f8e-a7a6-782b3cb23eda",
              "geometry": null
            },
            "contactInfo": {
              "email": "mgwynn@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/GWYNNM",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=GWYNNM",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist29.pdf",
                "https://house.utah.gov/rep/GWYNNM/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=GWYNNM",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415356",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist06.pdf",
                "https://house.utleg.gov/rep/GWYNNM/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-6-lower-ocd-person-8d2d4368-9f6a-4f8e-a7a6-782b3cb23eda": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/9657724b-ade7-467e-b77d-b77fb98891c5",
            "firstName": "Ryan D.",
            "lastName": "Wilcox",
            "fullName": "Ryan Wilcox",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/WILCORD.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-UT-7-lower-ocd-person-9657724b-ade7-467e-b77d-b77fb98891c5",
              "builtID": "ut-lower-7",
              "externalID": "ocd-person/9657724b-ade7-467e-b77d-b77fb98891c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryanwilcox@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/WILCORD",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=WILCORD",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist07.pdf",
                "https://house.utah.gov/rep/WILCORD/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=WILCORD",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415472",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist07.pdf",
                "https://house.utleg.gov/rep/WILCORD/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-7-lower-ocd-person-9657724b-ade7-467e-b77d-b77fb98891c5": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/cb6f79ec-b7b4-418e-950e-f5f066489929",
            "firstName": "Jason B.",
            "lastName": "Kyle",
            "fullName": "Jason Kyle",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/KYLEJB.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-UT-8-lower-ocd-person-cb6f79ec-b7b4-418e-950e-f5f066489929",
              "builtID": "ut-lower-8",
              "externalID": "ocd-person/cb6f79ec-b7b4-418e-950e-f5f066489929",
              "geometry": null
            },
            "contactInfo": {
              "email": "jkyle@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=KYLEJB",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist08.pdf",
                "https://house.utah.gov/rep/KYLEJB/",
                "https://house.utleg.gov/rep/KYLEJB/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-8-lower-ocd-person-cb6f79ec-b7b4-418e-950e-f5f066489929": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/036ab735-ad82-450e-a5ca-098b1436cb90",
            "firstName": "Calvin Ray",
            "lastName": "Musselman",
            "fullName": "Calvin Musselman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/MUSSECR.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-UT-9-lower-ocd-person-036ab735-ad82-450e-a5ca-098b1436cb90",
              "builtID": "ut-lower-9",
              "externalID": "ocd-person/036ab735-ad82-450e-a5ca-098b1436cb90",
              "geometry": null
            },
            "contactInfo": {
              "email": "cmusselman@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/MUSSECR",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=MUSSECR",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist09.pdf",
                "https://le.utah.gov/house2/CofI/COFIMUSSECR1.pdf",
                "https://le.utah.gov/house2/CofI/COFIMUSSECR.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415056",
                "https://house.utah.gov/rep/MUSSECR/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=MUSSECR",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist09.pdf",
                "https://house.utleg.gov/rep/MUSSECR/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-9-lower-ocd-person-036ab735-ad82-450e-a5ca-098b1436cb90": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/0989a302-a2e5-4baa-a5be-42a7a20486ed",
            "firstName": "Rosemary T.",
            "lastName": "Lesser",
            "fullName": "Rosemary Lesser",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/LESSER.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-UT-10-lower-ocd-person-0989a302-a2e5-4baa-a5be-42a7a20486ed",
              "builtID": "ut-lower-10",
              "externalID": "ocd-person/0989a302-a2e5-4baa-a5be-42a7a20486ed",
              "geometry": null
            },
            "contactInfo": {
              "email": "rlesser@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/LESSER",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=LESSER",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist10.pdf",
                "https://house.utah.gov/rep/LESSER/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=LESSER",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415683",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist10.pdf",
                "https://house.utleg.gov/rep/LESSER/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-10-lower-ocd-person-0989a302-a2e5-4baa-a5be-42a7a20486ed": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/37301850-0f06-4bbd-ba85-faa7c4d39906",
            "firstName": "Katy",
            "lastName": "Hall",
            "fullName": "Katy Hall",
            "gender": "Female",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/HALLK.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-UT-11-lower-ocd-person-37301850-0f06-4bbd-ba85-faa7c4d39906",
              "builtID": "ut-lower-11",
              "externalID": "ocd-person/37301850-0f06-4bbd-ba85-faa7c4d39906",
              "geometry": null
            },
            "contactInfo": {
              "email": "khall@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=HALLK",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist11.pdf",
                "https://house.utah.gov/rep/HALLK/",
                "https://house.utleg.gov/rep/HALLK/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-11-lower-ocd-person-37301850-0f06-4bbd-ba85-faa7c4d39906": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/d1046a0c-ddd3-4fed-be41-13da161d6231",
            "firstName": "Mike",
            "lastName": "Schultz",
            "fullName": "Mike Schultz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/SCHULM.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-UT-12-lower-ocd-person-d1046a0c-ddd3-4fed-be41-13da161d6231",
              "builtID": "ut-lower-12",
              "externalID": "ocd-person/d1046a0c-ddd3-4fed-be41-13da161d6231",
              "geometry": null
            },
            "contactInfo": {
              "email": "mikeschultz@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/SCHULM",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=SCHULM",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist12.pdf",
                "https://le.utah.gov/house2/CofI/COFISCHULM1.pdf",
                "https://le.utah.gov/house2/CofI/COFISCHULM.pdf",
                "https://le.utah.gov/house2/CofI/mschultz4.pdf",
                "https://le.utah.gov/house2/CofI/mschultz3.pdf",
                "https://le.utah.gov/house2/CofI/mschultz2.pdf",
                "https://le.utah.gov/house2/CofI/mschultz.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1412688",
                "https://house.utah.gov/rep/SCHULM/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=SCHULM",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist12.pdf",
                "https://house.utleg.gov/rep/SCHULM/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-12-lower-ocd-person-d1046a0c-ddd3-4fed-be41-13da161d6231": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/3c2d4efe-dd4e-48cf-a83b-2a15791706a3",
            "firstName": "Karen M.",
            "lastName": "Peterson",
            "fullName": "Karen Peterson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/PETERK.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-UT-13-lower-ocd-person-3c2d4efe-dd4e-48cf-a83b-2a15791706a3",
              "builtID": "ut-lower-13",
              "externalID": "ocd-person/3c2d4efe-dd4e-48cf-a83b-2a15791706a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "kpeterson@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=PETERK",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist13.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415760",
                "https://house.utah.gov/rep/PETERK/",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist13.pdf",
                "https://house.utleg.gov/rep/PETERK/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-13-lower-ocd-person-3c2d4efe-dd4e-48cf-a83b-2a15791706a3": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/59eb30b5-da7b-4161-85b3-8b48c01f4c2b",
            "firstName": "Karianne",
            "lastName": "Lisonbee",
            "fullName": "Karianne Lisonbee",
            "gender": "Female",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/LISONK.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-UT-14-lower-ocd-person-59eb30b5-da7b-4161-85b3-8b48c01f4c2b",
              "builtID": "ut-lower-14",
              "externalID": "ocd-person/59eb30b5-da7b-4161-85b3-8b48c01f4c2b",
              "geometry": null
            },
            "contactInfo": {
              "email": "karilisonbee@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/LISONK",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=LISONK",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist14.pdf",
                "https://le.utah.gov/house2/CofI/COFILISONK3.pdf",
                "https://le.utah.gov/house2/CofI/COFILISONK2.pdf",
                "https://le.utah.gov/house2/CofI/COFILISONK1.pdf",
                "https://le.utah.gov/house2/CofI/COFILISONK.pdf",
                "https://le.utah.gov/house2/CofI/LISONK.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414516",
                "https://house.utah.gov/rep/LISONK/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=LISONK",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist14.pdf",
                "https://house.utleg.gov/rep/LISONK/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-14-lower-ocd-person-59eb30b5-da7b-4161-85b3-8b48c01f4c2b": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/6d3d4043-1ec3-454c-a517-4036722c50ee",
            "firstName": "Ariel Thrapp",
            "lastName": "Defay",
            "fullName": "Ariel Defay",
            "gender": "Female",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/DEFAYA.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-UT-15-lower-ocd-person-6d3d4043-1ec3-454c-a517-4036722c50ee",
              "builtID": "ut-lower-15",
              "externalID": "ocd-person/6d3d4043-1ec3-454c-a517-4036722c50ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "adefay@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=DEFAYA",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist15.pdf",
                "https://house.utah.gov/rep/DEFAYA/",
                "https://house.utleg.gov/rep/DEFAYA/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-15-lower-ocd-person-6d3d4043-1ec3-454c-a517-4036722c50ee": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/3187c9ea-c509-45f3-9eb4-a8ba2d8fd7d9",
            "firstName": "Trevor",
            "lastName": "Lee",
            "fullName": "Trevor Lee",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/LEETA.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-UT-16-lower-ocd-person-3187c9ea-c509-45f3-9eb4-a8ba2d8fd7d9",
              "builtID": "ut-lower-16",
              "externalID": "ocd-person/3187c9ea-c509-45f3-9eb4-a8ba2d8fd7d9",
              "geometry": null
            },
            "contactInfo": {
              "email": "tlee@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=LEETA",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist16.pdf",
                "https://house.utah.gov/rep/LEETA/",
                "https://house.utleg.gov/rep/LEETA/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-16-lower-ocd-person-3187c9ea-c509-45f3-9eb4-a8ba2d8fd7d9": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/b5285658-1e71-48a2-8641-a2bc75957b24",
            "firstName": "Stewart E.",
            "lastName": "Barlow",
            "fullName": "Stewart Barlow",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/BARLOSE.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-UT-17-lower-ocd-person-b5285658-1e71-48a2-8641-a2bc75957b24",
              "builtID": "ut-lower-17",
              "externalID": "ocd-person/b5285658-1e71-48a2-8641-a2bc75957b24",
              "geometry": null
            },
            "contactInfo": {
              "email": "sbarlow@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/BARLOSE",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=BARLOSE",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist17.pdf",
                "https://le.utah.gov/house2/CofI/COFIBARLOSE2.pdf",
                "https://le.utah.gov/house2/CofI/COFIBARLOSE1.pdf",
                "https://le.utah.gov/house2/CofI/COFIBARLOSE.pdf",
                "https://le.utah.gov/house2/CofI/sbarlow3.pdf",
                "https://le.utah.gov/house2/CofI/sbarlow2.pdf",
                "https://le.utah.gov/house2/CofI/sbarlow.pdf",
                "https://le.utah.gov/house2/CofI/BARLOSE.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1411299",
                "https://house.utah.gov/rep/BARLOSE/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=BARLOSE",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist17.pdf",
                "https://house.utleg.gov/rep/BARLOSE/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-17-lower-ocd-person-b5285658-1e71-48a2-8641-a2bc75957b24": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/d8c2c3ff-83a7-45f7-88bb-fb01b8cd624e",
            "firstName": "Paul",
            "lastName": "Cutler",
            "fullName": "Paul Cutler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/CUTLEP.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-UT-18-lower-ocd-person-d8c2c3ff-83a7-45f7-88bb-fb01b8cd624e",
              "builtID": "ut-lower-18",
              "externalID": "ocd-person/d8c2c3ff-83a7-45f7-88bb-fb01b8cd624e",
              "geometry": null
            },
            "contactInfo": {
              "email": "pcutler@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=CUTLEP",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist18.pdf",
                "https://house.utah.gov/rep/CUTLEP/",
                "https://house.utleg.gov/rep/CUTLEP/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-18-lower-ocd-person-d8c2c3ff-83a7-45f7-88bb-fb01b8cd624e": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/dbd3789a-5040-4015-9474-ac27b4a32010",
            "firstName": "Raymond P.",
            "lastName": "Ward",
            "fullName": "Raymond Ward",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/WARDR.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-UT-19-lower-ocd-person-dbd3789a-5040-4015-9474-ac27b4a32010",
              "builtID": "ut-lower-19",
              "externalID": "ocd-person/dbd3789a-5040-4015-9474-ac27b4a32010",
              "geometry": null
            },
            "contactInfo": {
              "email": "rayward@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/WARDR",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=WARDR",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist19.pdf",
                "https://le.utah.gov/house2/CofI/COFIWARDR1.pdf",
                "https://le.utah.gov/house2/CofI/COFIWARDR.pdf",
                "https://le.utah.gov/house2/CofI/WARDR.pdf",
                "https://le.utah.gov/house2/CofI/rward2.pdf",
                "https://le.utah.gov/house2/CofI/rward.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1412633",
                "https://house.utah.gov/rep/WARDR/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=WARDR",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist19.pdf",
                "https://house.utleg.gov/rep/WARDR/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-19-lower-ocd-person-dbd3789a-5040-4015-9474-ac27b4a32010": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/861428f5-6456-4c94-b1ed-838b01a7d845",
            "firstName": "Melissa Garff",
            "lastName": "Ballard",
            "fullName": "Melissa Ballard",
            "gender": "Female",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/BALLAMG.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-UT-20-lower-ocd-person-861428f5-6456-4c94-b1ed-838b01a7d845",
              "builtID": "ut-lower-20",
              "externalID": "ocd-person/861428f5-6456-4c94-b1ed-838b01a7d845",
              "geometry": null
            },
            "contactInfo": {
              "email": "mballard@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/BALLAMG",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=BALLAMG",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist20.pdf",
                "https://le.utah.gov/house2/CofI/COFIBALLAMG1.pdf",
                "https://le.utah.gov/house2/CofI/COFIBALLAMG.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415006",
                "https://house.utah.gov/rep/BALLAMG/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=BALLAMG",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist20.pdf",
                "https://house.utleg.gov/rep/BALLAMG/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-20-lower-ocd-person-861428f5-6456-4c94-b1ed-838b01a7d845": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/8c8d32a7-32ed-4ef1-a03d-9661ca98fc5a",
            "firstName": "Sandra",
            "lastName": "Hollins",
            "fullName": "Sandra Hollins",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/HOLLIS.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-UT-21-lower-ocd-person-8c8d32a7-32ed-4ef1-a03d-9661ca98fc5a",
              "builtID": "ut-lower-21",
              "externalID": "ocd-person/8c8d32a7-32ed-4ef1-a03d-9661ca98fc5a",
              "geometry": null
            },
            "contactInfo": {
              "email": "shollins@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/HOLLIS",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=HOLLIS",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist23.pdf",
                "https://le.utah.gov/house2/CofI/COFIHOLLIS3.pdf",
                "https://le.utah.gov/house2/CofI/COFIHOLLIS2.pdf",
                "https://le.utah.gov/house2/CofI/COFIHOLLIS1.pdf",
                "https://le.utah.gov/house2/CofI/COFIHOLLIS.pdf",
                "https://le.utah.gov/house2/CofI/HOLLIS.pdf",
                "https://le.utah.gov/house2/CofI/shollins4.pdf",
                "https://le.utah.gov/house2/CofI/shollins3.pdf",
                "https://le.utah.gov/house2/CofI/shollins2.pdf",
                "https://le.utah.gov/house2/CofI/shollins.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1412641",
                "https://house.utah.gov/rep/HOLLIS/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=HOLLIS",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist21.pdf",
                "https://house.utleg.gov/rep/HOLLIS/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-21-lower-ocd-person-8c8d32a7-32ed-4ef1-a03d-9661ca98fc5a": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/b32f9174-73af-4db3-877b-1c368bd4e62e",
            "firstName": "Jennifer",
            "lastName": "Dailey-Provost",
            "fullName": "Jen Dailey-Provost",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/DAILEJ.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-UT-22-lower-ocd-person-b32f9174-73af-4db3-877b-1c368bd4e62e",
              "builtID": "ut-lower-22",
              "externalID": "ocd-person/b32f9174-73af-4db3-877b-1c368bd4e62e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jdprovost@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/DAILEJ",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=DAILEJ",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist24.pdf",
                "https://le.utah.gov/house2/CofI/COFIDAILEJ1.pdf",
                "https://le.utah.gov/house2/CofI/COFIDAILEJ.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414811",
                "https://house.utah.gov/rep/DAILEJ/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=DAILEJ",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist22.pdf",
                "https://house.utleg.gov/rep/DAILEJ/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-22-lower-ocd-person-b32f9174-73af-4db3-877b-1c368bd4e62e": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/4711de1a-bc47-4ae2-b22e-de982ca7d42e",
            "firstName": "Brian S.",
            "lastName": "King",
            "fullName": "Brian King",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/KINGBS.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-UT-23-lower-ocd-person-4711de1a-bc47-4ae2-b22e-de982ca7d42e",
              "builtID": "ut-lower-23",
              "externalID": "ocd-person/4711de1a-bc47-4ae2-b22e-de982ca7d42e",
              "geometry": null
            },
            "contactInfo": {
              "email": "briansking@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/KINGBS",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=KINGBS",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist28.pdf",
                "https://le.utah.gov/house2/CofI/COFIKINGBS1.pdf",
                "https://le.utah.gov/house2/CofI/COFIKINGBS.pdf",
                "https://le.utah.gov/house2/CofI/brianking3.pdf",
                "https://le.utah.gov/house2/CofI/brianking2.pdf",
                "https://le.utah.gov/house2/CofI/brianking.pdf",
                "https://le.utah.gov/house2/CofI/KINGBS.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1883",
                "https://house.utah.gov/rep/KINGBS/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=KINGBS",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist23.pdf",
                "https://house.utleg.gov/rep/KINGBS/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-23-lower-ocd-person-4711de1a-bc47-4ae2-b22e-de982ca7d42e": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/664044a3-6f66-4292-8a35-9393c3a0cce2",
            "firstName": "Joel K.",
            "lastName": "Briscoe",
            "fullName": "Joel Briscoe",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/BRISCJK.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-UT-24-lower-ocd-person-664044a3-6f66-4292-8a35-9393c3a0cce2",
              "builtID": "ut-lower-24",
              "externalID": "ocd-person/664044a3-6f66-4292-8a35-9393c3a0cce2",
              "geometry": null
            },
            "contactInfo": {
              "email": "jbriscoe@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/BRISCJK",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=BRISCJK",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist25.pdf",
                "https://le.utah.gov/house2/CofI/COFIBRISCJK2.pdf",
                "https://le.utah.gov/house2/CofI/COFIBRISCJK1.pdf",
                "https://le.utah.gov/house2/CofI/COFIBRISCJK.pdf",
                "https://le.utah.gov/house2/CofI/BRISCJK1.pdf",
                "https://le.utah.gov/house2/CofI/jbriscoe2.pdf",
                "https://le.utah.gov/house2/CofI/jbriscoe.pdf",
                "https://le.utah.gov/house2/CofI/BRISCJK.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1409583",
                "https://house.utah.gov/rep/BRISCJK/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=BRISCJK",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist24.pdf",
                "https://house.utleg.gov/rep/BRISCJK/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-24-lower-ocd-person-664044a3-6f66-4292-8a35-9393c3a0cce2": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/d987aff4-abcf-4f2a-826d-b5741d065d44",
            "firstName": "Angela",
            "lastName": "Romero",
            "fullName": "Angela Romero",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/ROMERAY.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-UT-25-lower-ocd-person-d987aff4-abcf-4f2a-826d-b5741d065d44",
              "builtID": "ut-lower-25",
              "externalID": "ocd-person/d987aff4-abcf-4f2a-826d-b5741d065d44",
              "geometry": null
            },
            "contactInfo": {
              "email": "angelaromero@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/ROMERAY",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=ROMERAY",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist26.pdf",
                "https://le.utah.gov/house2/CofI/COFIROMERAY1.pdf",
                "https://le.utah.gov/house2/CofI/COFIROMERAY.pdf",
                "https://le.utah.gov/house2/CofI/ROMERAY1.pdf",
                "https://le.utah.gov/house2/CofI/aromero2.pdf",
                "https://le.utah.gov/house2/CofI/aromero.pdf",
                "https://le.utah.gov/house2/CofI/ROMERAY.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1411502",
                "https://house.utah.gov/rep/ROMERAY/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=ROMERAY",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist25.pdf",
                "https://house.utleg.gov/rep/ROMERAY/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-25-lower-ocd-person-d987aff4-abcf-4f2a-826d-b5741d065d44": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/ad88a882-c27b-4ceb-8476-b7a3d2a9dd19",
            "firstName": "Matthew",
            "lastName": "MacPherson",
            "fullName": "Matt MacPherson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/MACPHM.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-UT-26-lower-ocd-person-ad88a882-c27b-4ceb-8476-b7a3d2a9dd19",
              "builtID": "ut-lower-26",
              "externalID": "ocd-person/ad88a882-c27b-4ceb-8476-b7a3d2a9dd19",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmacpherson@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=MACPHM",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist26.pdf",
                "https://house.utah.gov/rep/MACPHM/",
                "https://house.utleg.gov/rep/MACPHM/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-26-lower-ocd-person-ad88a882-c27b-4ceb-8476-b7a3d2a9dd19": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/7c0df02d-1ef0-4fa9-b708-ccafff35649e",
            "firstName": "Anthony Edward",
            "lastName": "Loubet",
            "fullName": "Anthony Loubet",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/LOUBEA.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-UT-27-lower-ocd-person-7c0df02d-1ef0-4fa9-b708-ccafff35649e",
              "builtID": "ut-lower-27",
              "externalID": "ocd-person/7c0df02d-1ef0-4fa9-b708-ccafff35649e",
              "geometry": null
            },
            "contactInfo": {
              "email": "aloubet@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=LOUBEA",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist27.pdf",
                "https://house.utah.gov/rep/LOUBEA/",
                "https://house.utleg.gov/rep/LOUBEA/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-27-lower-ocd-person-7c0df02d-1ef0-4fa9-b708-ccafff35649e": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/cac348fd-397e-4f58-ae30-1a54fa68cc12",
            "firstName": "Timothy Adrian",
            "lastName": "Jimenez",
            "fullName": "Tim Jimenez",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/JIMENT.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-UT-28-lower-ocd-person-cac348fd-397e-4f58-ae30-1a54fa68cc12",
              "builtID": "ut-lower-28",
              "externalID": "ocd-person/cac348fd-397e-4f58-ae30-1a54fa68cc12",
              "geometry": null
            },
            "contactInfo": {
              "email": "tjimenez@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=JIMENT",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist28.pdf",
                "https://house.utah.gov/rep/JIMENT/",
                "https://house.utleg.gov/rep/JIMENT/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-28-lower-ocd-person-cac348fd-397e-4f58-ae30-1a54fa68cc12": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/3e388e2d-1b4a-49dc-9aa5-38353166e08f",
            "firstName": "Bridger",
            "lastName": "Bolinder",
            "fullName": "Bridger Bolinder",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/BOLINB.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-UT-29-lower-ocd-person-3e388e2d-1b4a-49dc-9aa5-38353166e08f",
              "builtID": "ut-lower-29",
              "externalID": "ocd-person/3e388e2d-1b4a-49dc-9aa5-38353166e08f",
              "geometry": null
            },
            "contactInfo": {
              "email": "bbolinder@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=BOLINB",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist29.pdf",
                "https://house.utah.gov/rep/BOLINB/",
                "https://house.utleg.gov/rep/BOLINB/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-29-lower-ocd-person-3e388e2d-1b4a-49dc-9aa5-38353166e08f": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/cbf85db9-a39c-44af-bed9-78826eed85af",
            "firstName": "Judy",
            "lastName": "Weeks Rohner",
            "fullName": "Judy Weeks Rohner",
            "gender": "Female",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/ROHNEJ.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-UT-30-lower-ocd-person-cbf85db9-a39c-44af-bed9-78826eed85af",
              "builtID": "ut-lower-30",
              "externalID": "ocd-person/cbf85db9-a39c-44af-bed9-78826eed85af",
              "geometry": null
            },
            "contactInfo": {
              "email": "jrohner@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=ROHNEJ",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist30.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415738",
                "https://house.utah.gov/rep/ROHNEJ/",
                "https://house.utleg.gov/rep/ROHNEJ/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-30-lower-ocd-person-cbf85db9-a39c-44af-bed9-78826eed85af": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/e2970b9c-6ce8-4b35-bf40-54713435cf46",
            "firstName": "Brett",
            "lastName": "Garner",
            "fullName": "Brett Garner",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/GARNEB.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-UT-31-lower-ocd-person-e2970b9c-6ce8-4b35-bf40-54713435cf46",
              "builtID": "ut-lower-31",
              "externalID": "ocd-person/e2970b9c-6ce8-4b35-bf40-54713435cf46",
              "geometry": null
            },
            "contactInfo": {
              "email": "bgarner@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=GARNEB",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist31.pdf",
                "https://house.utah.gov/rep/GARNEB/",
                "https://house.utleg.gov/rep/GARNEB/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-31-lower-ocd-person-e2970b9c-6ce8-4b35-bf40-54713435cf46": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/b0a5ca40-5e04-495c-80e0-2c5d3608273d",
            "firstName": "Sahara",
            "lastName": "Hayes",
            "fullName": "Sahara Hayes",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/HAYESS.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-UT-32-lower-ocd-person-b0a5ca40-5e04-495c-80e0-2c5d3608273d",
              "builtID": "ut-lower-32",
              "externalID": "ocd-person/b0a5ca40-5e04-495c-80e0-2c5d3608273d",
              "geometry": null
            },
            "contactInfo": {
              "email": "shayes@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=HAYESS",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist32.pdf",
                "https://house.utah.gov/rep/HAYESS/",
                "https://house.utleg.gov/rep/HAYESS/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-32-lower-ocd-person-b0a5ca40-5e04-495c-80e0-2c5d3608273d": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/b60609f0-e6df-42fc-bbca-9b67a2ed7254",
            "firstName": "Doug",
            "lastName": "Owens",
            "fullName": "Doug Owens",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/OWENSDO.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-UT-33-lower-ocd-person-b60609f0-e6df-42fc-bbca-9b67a2ed7254",
              "builtID": "ut-lower-33",
              "externalID": "ocd-person/b60609f0-e6df-42fc-bbca-9b67a2ed7254",
              "geometry": null
            },
            "contactInfo": {
              "email": "dougowens@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/OWENSDO",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=OWENSDO",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist36.pdf",
                "https://house.utah.gov/rep/OWENSDO/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=OWENSDO",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415426",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist33.pdf",
                "https://house.utleg.gov/rep/OWENSDO/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-33-lower-ocd-person-b60609f0-e6df-42fc-bbca-9b67a2ed7254": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/8d06fd93-cc42-47e3-a00c-2229718b7226",
            "firstName": "Carol Spackman",
            "lastName": "Moss",
            "fullName": "Carol Moss",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/MOSSCS.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-UT-34-lower-ocd-person-8d06fd93-cc42-47e3-a00c-2229718b7226",
              "builtID": "ut-lower-34",
              "externalID": "ocd-person/8d06fd93-cc42-47e3-a00c-2229718b7226",
              "geometry": null
            },
            "contactInfo": {
              "email": "csmoss@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/MOSSCS",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=MOSSCS",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist37.pdf",
                "https://le.utah.gov/house2/CofI/COFIMOSSCS1.pdf",
                "https://le.utah.gov/house2/CofI/COFIMOSSCS.pdf",
                "https://le.utah.gov/house2/CofI/MOSSCS3.pdf",
                "https://le.utah.gov/house2/CofI/MOSSCS2.pdf",
                "https://le.utah.gov/house2/CofI/MOSSCS1.pdf",
                "https://le.utah.gov/house2/CofI/cmoss2.pdf",
                "https://le.utah.gov/house2/CofI/cmoss.pdf",
                "https://le.utah.gov/house2/CofI/MOSSCS.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1248",
                "https://house.utah.gov/rep/MOSSCS/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=MOSSCS",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist34.pdf",
                "https://house.utleg.gov/rep/MOSSCS/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-34-lower-ocd-person-8d06fd93-cc42-47e3-a00c-2229718b7226": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/82aa8212-b406-4766-b870-85a0333daba9",
            "firstName": "Mark Archuleta",
            "lastName": "Wheatley",
            "fullName": "Mark Wheatley",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/WHEATMA.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-UT-35-lower-ocd-person-82aa8212-b406-4766-b870-85a0333daba9",
              "builtID": "ut-lower-35",
              "externalID": "ocd-person/82aa8212-b406-4766-b870-85a0333daba9",
              "geometry": null
            },
            "contactInfo": {
              "email": "markwheatley@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/WHEATMA",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=WHEATMA",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist35.pdf",
                "https://le.utah.gov/house2/CofI/COFIWHEATMA3.pdf",
                "https://le.utah.gov/house2/CofI/COFIWHEATMA2.pdf",
                "https://le.utah.gov/house2/CofI/COFIWHEATMA1.pdf",
                "https://le.utah.gov/house2/CofI/COFIWHEATMA.pdf",
                "https://le.utah.gov/house2/CofI/mwheatley3.pdf",
                "https://le.utah.gov/house2/CofI/mwheatley2.pdf",
                "https://le.utah.gov/house2/CofI/mwheatley.pdf",
                "https://le.utah.gov/house2/CofI/WHEATMA.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1878",
                "https://house.utah.gov/rep/WHEATMA/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=WHEATMA",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist35.pdf",
                "https://house.utleg.gov/rep/WHEATMA/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-35-lower-ocd-person-82aa8212-b406-4766-b870-85a0333daba9": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/89b3cebe-884b-4798-b063-794d359ea891",
            "firstName": "James A.",
            "lastName": "Dunnigan",
            "fullName": "Jim Dunnigan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/DUNNIJA.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-UT-36-lower-ocd-person-89b3cebe-884b-4798-b063-794d359ea891",
              "builtID": "ut-lower-36",
              "externalID": "ocd-person/89b3cebe-884b-4798-b063-794d359ea891",
              "geometry": null
            },
            "contactInfo": {
              "email": "jdunnigan@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/DUNNIJA",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=DUNNIJA",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist39.pdf",
                "https://le.utah.gov/house2/CofI/COFIDUNNIJA1.pdf",
                "https://le.utah.gov/house2/CofI/COFIDUNNIJA.pdf",
                "https://le.utah.gov/house2/CofI/DUNNIJA3.pdf",
                "https://le.utah.gov/house2/CofI/DUNNIJA2.pdf",
                "https://le.utah.gov/house2/CofI/DUNNIJA1.pdf",
                "https://le.utah.gov/house2/CofI/jdunnigan2.pdf",
                "https://le.utah.gov/house2/CofI/jdunnigan.pdf",
                "https://le.utah.gov/house2/CofI/DUNNIJA.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1389",
                "https://house.utah.gov/rep/DUNNIJA/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=DUNNIJA",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist36.pdf",
                "https://house.utleg.gov/rep/DUNNIJA/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-36-lower-ocd-person-89b3cebe-884b-4798-b063-794d359ea891": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/8a770a9d-d24e-4468-9194-fa2ff1f422e3",
            "firstName": "Ashlee Salazar",
            "lastName": "Matthews",
            "fullName": "Ashlee Matthews",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/MATTHA.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-UT-37-lower-ocd-person-8a770a9d-d24e-4468-9194-fa2ff1f422e3",
              "builtID": "ut-lower-37",
              "externalID": "ocd-person/8a770a9d-d24e-4468-9194-fa2ff1f422e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "amatthews@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/MATTHA",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=MATTHA",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist38.pdf",
                "https://house.utah.gov/rep/MATTHA/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=MATTHA",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415432",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist37.pdf",
                "https://house.utleg.gov/rep/MATTHA/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-37-lower-ocd-person-8a770a9d-d24e-4468-9194-fa2ff1f422e3": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/54fcc31c-a3e5-47ea-a044-d1223c6bce65",
            "firstName": "Cheryl K.",
            "lastName": "Acton",
            "fullName": "Cheryl Acton",
            "gender": "Female",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/ACTONCK.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-UT-38-lower-ocd-person-54fcc31c-a3e5-47ea-a044-d1223c6bce65",
              "builtID": "ut-lower-38",
              "externalID": "ocd-person/54fcc31c-a3e5-47ea-a044-d1223c6bce65",
              "geometry": null
            },
            "contactInfo": {
              "email": "cacton@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/ACTONCK",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=ACTONCK",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist43.pdf",
                "https://le.utah.gov/house2/CofI/COFIACTONCK1.pdf",
                "https://le.utah.gov/house2/CofI/COFIACTONCK.pdf",
                "https://le.utah.gov/house2/CofI/ACTONCK2.pdf",
                "https://le.utah.gov/house2/CofI/ACTONCK1.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414828",
                "https://house.utah.gov/rep/ACTONCK/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=ACTONCK",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist38.pdf",
                "https://house.utleg.gov/rep/ACTONCK/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-38-lower-ocd-person-54fcc31c-a3e5-47ea-a044-d1223c6bce65": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/c76bd4cf-9149-46e8-a001-e2c81c5942d5",
            "firstName": "Kenneth Robert",
            "lastName": "Ivory",
            "fullName": "Ken Ivory",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/IVORYK.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-UT-39-lower-ocd-person-c76bd4cf-9149-46e8-a001-e2c81c5942d5",
              "builtID": "ut-lower-39",
              "externalID": "ocd-person/c76bd4cf-9149-46e8-a001-e2c81c5942d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "kivory@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/IVORYK",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=IVORYK",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist47.pdf",
                "https://le.utah.gov/house2/CofI/COFIIVORYK1.pdf",
                "https://le.utah.gov/house2/CofI/COFIIVORYK.pdf",
                "https://le.utah.gov/house2/CofI/IVORYK2.pdf",
                "https://le.utah.gov/house2/CofI/IVORYK1.pdf",
                "https://le.utah.gov/house2/CofI/kivory3.pdf",
                "https://le.utah.gov/house2/CofI/kivory2.pdf",
                "https://le.utah.gov/house2/CofI/kivory.pdf",
                "https://le.utah.gov/house2/CofI/IVORYK.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1409302",
                "https://house.utah.gov/rep/IVORYK/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=IVORYK",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist39.pdf",
                "https://house.utleg.gov/rep/IVORYK/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-39-lower-ocd-person-c76bd4cf-9149-46e8-a001-e2c81c5942d5": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/eac36970-1c63-4b4c-81b1-3a39536bd69d",
            "firstName": "Andrew",
            "lastName": "Stoddard",
            "fullName": "Andrew Stoddard",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/STODDA.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-UT-40-lower-ocd-person-eac36970-1c63-4b4c-81b1-3a39536bd69d",
              "builtID": "ut-lower-40",
              "externalID": "ocd-person/eac36970-1c63-4b4c-81b1-3a39536bd69d",
              "geometry": null
            },
            "contactInfo": {
              "email": "astoddard@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/STODDA",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=STODDA",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist44.pdf",
                "https://le.utah.gov/house2/CofI/COFISTODDA.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415014",
                "https://house.utah.gov/rep/STODDA/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=STODDA",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist40.pdf",
                "https://house.utleg.gov/rep/STODDA/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-40-lower-ocd-person-eac36970-1c63-4b4c-81b1-3a39536bd69d": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/64375897-db2e-4def-8168-85cb2ff94b76",
            "firstName": "Gay Lynn",
            "lastName": "Bennion",
            "fullName": "Gay Lynn Bennion",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/BENNIGL.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-UT-41-lower-ocd-person-64375897-db2e-4def-8168-85cb2ff94b76",
              "builtID": "ut-lower-41",
              "externalID": "ocd-person/64375897-db2e-4def-8168-85cb2ff94b76",
              "geometry": null
            },
            "contactInfo": {
              "email": "glbennion@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/BENNIGL",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=BENNIGL",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist46.pdf",
                "https://house.utah.gov/rep/BENNIGL/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=BENNIGL",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415470",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist41.pdf",
                "https://house.utleg.gov/rep/BENNIGL/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-41-lower-ocd-person-64375897-db2e-4def-8168-85cb2ff94b76": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/cc2bc191-8e1f-482e-bf62-2387e13d99fa",
            "firstName": "Robert M.",
            "lastName": "Spendlove",
            "fullName": "Robert Spendlove",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/SPENDRM.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-UT-42-lower-ocd-person-cc2bc191-8e1f-482e-bf62-2387e13d99fa",
              "builtID": "ut-lower-42",
              "externalID": "ocd-person/cc2bc191-8e1f-482e-bf62-2387e13d99fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "rspendlove@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=SPENDRM",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist42.pdf",
                "https://le.utah.gov/house2/CofI/COFISPENDRM1.pdf",
                "https://le.utah.gov/house2/CofI/COFISPENDRM.pdf",
                "https://le.utah.gov/house2/CofI/SPENDRM1.pdf",
                "https://le.utah.gov/house2/CofI/rspendlove2.pdf",
                "https://le.utah.gov/house2/CofI/rspendlove.pdf",
                "https://le.utah.gov/house2/CofI/SPENDRM.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1412510",
                "https://house.utah.gov/rep/SPENDRM/",
                "https://house.utleg.gov/rep/SPENDRM/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-42-lower-ocd-person-cc2bc191-8e1f-482e-bf62-2387e13d99fa": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/06d334f0-dd19-4a8e-b1c9-5f1ae1d90e26",
            "firstName": "Steven",
            "lastName": "Eliason",
            "fullName": "Steve Eliason",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/ELIASS.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-UT-43-lower-ocd-person-06d334f0-dd19-4a8e-b1c9-5f1ae1d90e26",
              "builtID": "ut-lower-43",
              "externalID": "ocd-person/06d334f0-dd19-4a8e-b1c9-5f1ae1d90e26",
              "geometry": null
            },
            "contactInfo": {
              "email": "seliason@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/ELIASS",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=ELIASS",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist45.pdf",
                "https://le.utah.gov/house2/CofI/COFIELIASS1.pdf",
                "https://le.utah.gov/house2/CofI/COFIELIASS.pdf",
                "https://le.utah.gov/house2/CofI/seliason6.pdf",
                "https://le.utah.gov/house2/CofI/seliason5.pdf",
                "https://le.utah.gov/house2/CofI/seliason4.pdf",
                "https://le.utah.gov/house2/CofI/seliason3.pdf",
                "https://le.utah.gov/house2/CofI/seliason2.pdf",
                "https://le.utah.gov/house2/CofI/seliason.pdf",
                "https://le.utah.gov/house2/CofI/ELIASS.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1409528",
                "https://house.utah.gov/rep/ELIASS/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=ELIASS",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist43.pdf",
                "https://house.utleg.gov/rep/ELIASS/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-43-lower-ocd-person-06d334f0-dd19-4a8e-b1c9-5f1ae1d90e26": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/4fb12ece-15d9-4fd2-9b0a-e96a0dbdd2ad",
            "firstName": "Jordan D.",
            "lastName": "Teuscher",
            "fullName": "Jordan Teuscher",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/TEUSCJ.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-UT-44-lower-ocd-person-4fb12ece-15d9-4fd2-9b0a-e96a0dbdd2ad",
              "builtID": "ut-lower-44",
              "externalID": "ocd-person/4fb12ece-15d9-4fd2-9b0a-e96a0dbdd2ad",
              "geometry": null
            },
            "contactInfo": {
              "email": "jteuscher@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/TEUSCJ",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=TEUSCJ",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist42.pdf",
                "https://house.utah.gov/rep/TEUSCJ/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=TEUSCJ",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415423",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist44.pdf",
                "https://house.utleg.gov/rep/TEUSCJ/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-44-lower-ocd-person-4fb12ece-15d9-4fd2-9b0a-e96a0dbdd2ad": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/863ea116-e9a6-41b1-b765-1f369148a400",
            "firstName": "Susan",
            "lastName": "Pulsipher",
            "fullName": "Susan Pulsipher",
            "gender": "Female",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/PULSIS.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-UT-45-lower-ocd-person-863ea116-e9a6-41b1-b765-1f369148a400",
              "builtID": "ut-lower-45",
              "externalID": "ocd-person/863ea116-e9a6-41b1-b765-1f369148a400",
              "geometry": null
            },
            "contactInfo": {
              "email": "susanpulsipher@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/PULSIS",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=PULSIS",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist50.pdf",
                "https://le.utah.gov/house2/CofI/COFIPULSIS1.pdf",
                "https://le.utah.gov/house2/CofI/COFIPULSIS.pdf",
                "https://le.utah.gov/house2/CofI/PULSIS1.pdf",
                "https://le.utah.gov/house2/CofI/PULSIS.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1413350",
                "https://house.utah.gov/rep/PULSIS/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=PULSIS",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist45.pdf",
                "https://house.utleg.gov/rep/PULSIS/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-45-lower-ocd-person-863ea116-e9a6-41b1-b765-1f369148a400": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/58a35084-a6c6-421a-81d9-91d9c3dc6d5f",
            "firstName": "Jeffrey D.",
            "lastName": "Stenquist",
            "fullName": "Jeff Stenquist",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/STENQJ.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-UT-46-lower-ocd-person-58a35084-a6c6-421a-81d9-91d9c3dc6d5f",
              "builtID": "ut-lower-46",
              "externalID": "ocd-person/58a35084-a6c6-421a-81d9-91d9c3dc6d5f",
              "geometry": null
            },
            "contactInfo": {
              "email": "jstenquist@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=STENQJ",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist46.pdf",
                "https://le.utah.gov/house2/CofI/COFISTENQJ2.pdf",
                "https://le.utah.gov/house2/CofI/COFISTENQJ1.pdf",
                "https://le.utah.gov/house2/CofI/COFISTENQJ.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414943",
                "https://house.utah.gov/rep/STENQJ/",
                "https://house.utleg.gov/rep/STENQJ/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-46-lower-ocd-person-58a35084-a6c6-421a-81d9-91d9c3dc6d5f": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/5d383c2b-0450-48db-92bf-e20977953cd8",
            "firstName": "Mark A.",
            "lastName": "Strong",
            "fullName": "Mark Strong",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/STRONMA.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-UT-47-lower-ocd-person-5d383c2b-0450-48db-92bf-e20977953cd8",
              "builtID": "ut-lower-47",
              "externalID": "ocd-person/5d383c2b-0450-48db-92bf-e20977953cd8",
              "geometry": null
            },
            "contactInfo": {
              "email": "mstrong@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/STRONMA",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=STRONMA",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist41.pdf",
                "https://le.utah.gov/house2/CofI/COFISTRONMA.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415024",
                "https://house.utah.gov/rep/STRONMA/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=STRONMA",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist47.pdf",
                "https://house.utleg.gov/rep/STRONMA/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-47-lower-ocd-person-5d383c2b-0450-48db-92bf-e20977953cd8": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/a19f4bd4-e07a-46a7-85c6-813baae00c43",
            "firstName": "James Francis",
            "lastName": "Cobb",
            "fullName": "Jay Cobb",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/COBBJ.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-UT-48-lower-ocd-person-a19f4bd4-e07a-46a7-85c6-813baae00c43",
              "builtID": "ut-lower-48",
              "externalID": "ocd-person/a19f4bd4-e07a-46a7-85c6-813baae00c43",
              "geometry": null
            },
            "contactInfo": {
              "email": "jcobb@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=COBBJ",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist48.pdf",
                "https://house.utah.gov/rep/COBBJ/",
                "https://house.utleg.gov/rep/COBBJ/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-48-lower-ocd-person-a19f4bd4-e07a-46a7-85c6-813baae00c43": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/b4977f98-8a0d-4e4b-a43f-b8ee80adba2b",
            "firstName": "Darlene Candice Backus",
            "lastName": "Pierucci",
            "fullName": "Candice Pierucci",
            "gender": "Female",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/PIERUC.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-UT-49-lower-ocd-person-b4977f98-8a0d-4e4b-a43f-b8ee80adba2b",
              "builtID": "ut-lower-49",
              "externalID": "ocd-person/b4977f98-8a0d-4e4b-a43f-b8ee80adba2b",
              "geometry": null
            },
            "contactInfo": {
              "email": "cpierucci@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/PIERUC",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=PIERUC",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist52.pdf",
                "https://house.utah.gov/rep/PIERUC/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=PIERUC",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415318",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist49.pdf",
                "https://house.utleg.gov/rep/PIERUC/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-49-lower-ocd-person-b4977f98-8a0d-4e4b-a43f-b8ee80adba2b": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/236ec233-4d24-4514-82a5-05a028c1de14",
            "firstName": "Stephanie",
            "lastName": "Gricius",
            "fullName": "Stephanie Gricius",
            "gender": "Female",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/GRICIS.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-UT-50-lower-ocd-person-236ec233-4d24-4514-82a5-05a028c1de14",
              "builtID": "ut-lower-50",
              "externalID": "ocd-person/236ec233-4d24-4514-82a5-05a028c1de14",
              "geometry": null
            },
            "contactInfo": {
              "email": "sgricius@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=GRICIS",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist50.pdf",
                "https://house.utah.gov/rep/GRICIS/",
                "https://house.utleg.gov/rep/GRICIS/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-50-lower-ocd-person-236ec233-4d24-4514-82a5-05a028c1de14": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/39f80bf5-e4c8-4b0f-9ca6-5f43508a5755",
            "firstName": "Jefferson",
            "lastName": "Moss",
            "fullName": "Jefferson Moss",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/MOSSJ.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-UT-51-lower-ocd-person-39f80bf5-e4c8-4b0f-9ca6-5f43508a5755",
              "builtID": "ut-lower-51",
              "externalID": "ocd-person/39f80bf5-e4c8-4b0f-9ca6-5f43508a5755",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeffersonmoss@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/MOSSJ",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=MOSSJ",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist02.pdf",
                "https://le.utah.gov/house2/CofI/COFIMOSSJ1.pdf",
                "https://le.utah.gov/house2/CofI/COFIMOSSJ.pdf",
                "https://le.utah.gov/house2/CofI/MOSSJ1.pdf",
                "https://le.utah.gov/house2/CofI/MOSSJ.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1411562",
                "https://house.utah.gov/rep/MOSSJ/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=MOSSJ",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist51.pdf",
                "https://house.utleg.gov/rep/MOSSJ/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-51-lower-ocd-person-39f80bf5-e4c8-4b0f-9ca6-5f43508a5755": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/8487aadf-5bd8-46b1-97a3-2db855c534a0",
            "firstName": "A. Cory",
            "lastName": "Maloy",
            "fullName": "Cory Maloy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/MALOYC.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-UT-52-lower-ocd-person-8487aadf-5bd8-46b1-97a3-2db855c534a0",
              "builtID": "ut-lower-52",
              "externalID": "ocd-person/8487aadf-5bd8-46b1-97a3-2db855c534a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "corymaloy@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/MALOYC",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=MALOYC",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist06.pdf",
                "https://le.utah.gov/house2/CofI/COFIMALOYC1.pdf",
                "https://le.utah.gov/house2/CofI/COFIMALOYC.pdf",
                "https://le.utah.gov/house2/CofI/MALOYC3.pdf",
                "https://le.utah.gov/house2/CofI/MALOYC2.pdf",
                "https://le.utah.gov/house2/CofI/MALOYC.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414486",
                "https://house.utah.gov/rep/MALOYC/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=MALOYC",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist52.pdf",
                "https://house.utleg.gov/rep/MALOYC/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-52-lower-ocd-person-8487aadf-5bd8-46b1-97a3-2db855c534a0": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/aee56bc8-022b-4351-81c0-9b5050846a8b",
            "firstName": "Kay J.",
            "lastName": "Christofferson",
            "fullName": "Kay Christofferson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/CHRISKJ.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-UT-53-lower-ocd-person-aee56bc8-022b-4351-81c0-9b5050846a8b",
              "builtID": "ut-lower-53",
              "externalID": "ocd-person/aee56bc8-022b-4351-81c0-9b5050846a8b",
              "geometry": null
            },
            "contactInfo": {
              "email": "kchristofferson@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/CHRISKJ",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=CHRISKJ",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist56.pdf",
                "https://le.utah.gov/house2/CofI/COFICHRISKJ3.pdf",
                "https://le.utah.gov/house2/CofI/COFICHRISKJ2.pdf",
                "https://le.utah.gov/house2/CofI/COFICHRISKJ1.pdf",
                "https://le.utah.gov/house2/CofI/COFICHRISKJ.pdf",
                "https://le.utah.gov/house2/CofI/CHRISKJ2.pdf",
                "https://le.utah.gov/house2/CofI/CHRISKJ1.pdf",
                "https://le.utah.gov/house2/CofI/kchristoff2.pdf",
                "https://le.utah.gov/house2/CofI/kchristoff.pdf",
                "https://le.utah.gov/house2/CofI/CHRISKJ.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1411621",
                "https://house.utah.gov/rep/CHRISKJ/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=CHRISKJ",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist53.pdf",
                "https://house.utleg.gov/rep/CHRISKJ/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-53-lower-ocd-person-aee56bc8-022b-4351-81c0-9b5050846a8b": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/b8751ce0-ba9b-44a0-8658-9cc9dc5f4c2c",
            "firstName": "Brady",
            "lastName": "Brammer",
            "fullName": "Brady Brammer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/BRAMMB.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-UT-54-lower-ocd-person-b8751ce0-ba9b-44a0-8658-9cc9dc5f4c2c",
              "builtID": "ut-lower-54",
              "externalID": "ocd-person/b8751ce0-ba9b-44a0-8658-9cc9dc5f4c2c",
              "geometry": null
            },
            "contactInfo": {
              "email": "bbrammer@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/BRAMMB",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=BRAMMB",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist27.pdf",
                "https://le.utah.gov/house2/CofI/COFIBRAMMB.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415048",
                "https://house.utah.gov/rep/BRAMMB/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=BRAMMB",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist54.pdf",
                "https://house.utleg.gov/rep/BRAMMB/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-54-lower-ocd-person-b8751ce0-ba9b-44a0-8658-9cc9dc5f4c2c": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/e214e4ef-2df9-41a2-9113-98715f5a791c",
            "firstName": "Jon",
            "lastName": "Hawkins",
            "fullName": "Jon Hawkins",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/HAWKIJ.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-UT-55-lower-ocd-person-e214e4ef-2df9-41a2-9113-98715f5a791c",
              "builtID": "ut-lower-55",
              "externalID": "ocd-person/e214e4ef-2df9-41a2-9113-98715f5a791c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jhawkins@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/HAWKIJ",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=HAWKIJ",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist57.pdf",
                "https://le.utah.gov/house2/CofI/COFIHAWKIJ.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414969",
                "https://house.utah.gov/rep/HAWKIJ/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=HAWKIJ",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist55.pdf",
                "https://house.utleg.gov/rep/HAWKIJ/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-55-lower-ocd-person-e214e4ef-2df9-41a2-9113-98715f5a791c": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/8662eb2d-1ff1-4bd2-9a3b-41fa8770cc18",
            "firstName": "Val L.",
            "lastName": "Peterson",
            "fullName": "Val Peterson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/PETERVL.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-UT-56-lower-ocd-person-8662eb2d-1ff1-4bd2-9a3b-41fa8770cc18",
              "builtID": "ut-lower-56",
              "externalID": "ocd-person/8662eb2d-1ff1-4bd2-9a3b-41fa8770cc18",
              "geometry": null
            },
            "contactInfo": {
              "email": "vpeterson@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/PETERVL",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=PETERVL",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist59.pdf",
                "https://le.utah.gov/house2/CofI/COFIPETERVL3.pdf",
                "https://le.utah.gov/house2/CofI/COFIPETERVL2.pdf",
                "https://le.utah.gov/house2/CofI/COFIPETERVL1.pdf",
                "https://le.utah.gov/house2/CofI/COFIPETERVL.pdf",
                "https://le.utah.gov/house2/CofI/PETERVL3.pdf",
                "https://le.utah.gov/house2/CofI/PETERVL2.pdf",
                "https://le.utah.gov/house2/CofI/PETERVL1.pdf",
                "https://le.utah.gov/house2/CofI/vpeterson2.pdf",
                "https://le.utah.gov/house2/CofI/vpeterson.pdf",
                "https://le.utah.gov/house2/CofI/PETERVL.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1409531",
                "https://house.utah.gov/rep/PETERVL/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=PETERVL",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist56.pdf",
                "https://house.utleg.gov/rep/PETERVL/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-56-lower-ocd-person-8662eb2d-1ff1-4bd2-9a3b-41fa8770cc18": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/00670b53-62f7-4c0f-b229-eb1fad6dbbbc",
            "firstName": "Nelson T.",
            "lastName": "Abbott",
            "fullName": "Nelson Abbott",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/ABBOTN.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-UT-57-lower-ocd-person-00670b53-62f7-4c0f-b229-eb1fad6dbbbc",
              "builtID": "ut-lower-57",
              "externalID": "ocd-person/00670b53-62f7-4c0f-b229-eb1fad6dbbbc",
              "geometry": null
            },
            "contactInfo": {
              "email": "nabbott@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/ABBOTN",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=ABBOTN",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist60.pdf",
                "https://house.utah.gov/rep/ABBOTN/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=ABBOTN",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415393",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist57.pdf",
                "https://house.utleg.gov/rep/ABBOTN/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-57-lower-ocd-person-00670b53-62f7-4c0f-b229-eb1fad6dbbbc": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/a919fa9b-f3f7-480f-b578-4a49bb4b572e",
            "firstName": "Keven J.",
            "lastName": "Stratton",
            "fullName": "Keven Stratton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/STRATKJ.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-UT-58-lower-ocd-person-a919fa9b-f3f7-480f-b578-4a49bb4b572e",
              "builtID": "ut-lower-58",
              "externalID": "ocd-person/a919fa9b-f3f7-480f-b578-4a49bb4b572e",
              "geometry": null
            },
            "contactInfo": {
              "email": "kstratton@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/STRATKJ",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=STRATKJ",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist48.pdf",
                "https://le.utah.gov/house2/CofI/COFISTRATKJ2.pdf",
                "https://le.utah.gov/house2/CofI/COFISTRATKJ1.pdf",
                "https://le.utah.gov/house2/CofI/COFISTRATKJ.pdf",
                "https://le.utah.gov/house2/CofI/kstratton3.pdf",
                "https://le.utah.gov/house2/CofI/kstratton2.pdf",
                "https://le.utah.gov/house2/CofI/kstratton.pdf",
                "https://le.utah.gov/house2/CofI/STRATKJ.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1413031",
                "https://house.utah.gov/rep/STRATKJ/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=STRATKJ",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist58.pdf",
                "https://house.utleg.gov/rep/STRATKJ/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-58-lower-ocd-person-a919fa9b-f3f7-480f-b578-4a49bb4b572e": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/656dec4e-3c95-43b1-9059-f2259300f88b",
            "firstName": "Michael L.",
            "lastName": "Kohler",
            "fullName": "Mike Kohler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/KOHLEM.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-UT-59-lower-ocd-person-656dec4e-3c95-43b1-9059-f2259300f88b",
              "builtID": "ut-lower-59",
              "externalID": "ocd-person/656dec4e-3c95-43b1-9059-f2259300f88b",
              "geometry": null
            },
            "contactInfo": {
              "email": "mkohler@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/KOHLEM",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=KOHLEM",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist54.pdf",
                "https://house.utah.gov/rep/KOHLEM/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=KOHLEM",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415490",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist59.pdf",
                "https://house.utleg.gov/rep/KOHLEM/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-59-lower-ocd-person-656dec4e-3c95-43b1-9059-f2259300f88b": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/739625fa-268f-48d6-9ffa-b80fc19797d5",
            "firstName": "Tyler James",
            "lastName": "Clancy",
            "fullName": "Tyler Clancy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/CLANCT.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-UT-60-lower-ocd-person-739625fa-268f-48d6-9ffa-b80fc19797d5",
              "builtID": "ut-lower-60",
              "externalID": "ocd-person/739625fa-268f-48d6-9ffa-b80fc19797d5",
              "geometry": null
            },
            "contactInfo": {
              "email": "tclancy@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=CLANCT",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist60.pdf",
                "https://house.utah.gov/rep/CLANCT/",
                "https://house.utleg.gov/rep/CLANCT/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-60-lower-ocd-person-739625fa-268f-48d6-9ffa-b80fc19797d5": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/cd3aab10-3336-46d5-acb1-084a9e779cf1",
            "firstName": "Marsha",
            "lastName": "Judkins",
            "fullName": "Marsha Judkins",
            "gender": "Female",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/JUDKIM.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-UT-61-lower-ocd-person-cd3aab10-3336-46d5-acb1-084a9e779cf1",
              "builtID": "ut-lower-61",
              "externalID": "ocd-person/cd3aab10-3336-46d5-acb1-084a9e779cf1",
              "geometry": null
            },
            "contactInfo": {
              "email": "mjudkins@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/JUDKIM",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=JUDKIM",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist61.pdf",
                "https://le.utah.gov/house2/CofI/COFIJUDKIM1.pdf",
                "https://le.utah.gov/house2/CofI/COFIJUDKIM.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414979",
                "https://house.utah.gov/rep/JUDKIM/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=JUDKIM",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist61.pdf",
                "https://house.utleg.gov/rep/JUDKIM/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-61-lower-ocd-person-cd3aab10-3336-46d5-acb1-084a9e779cf1": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/8e36fabe-9e2a-4ccd-b29a-1395d6318560",
            "firstName": "Norman K.",
            "lastName": "Thurston",
            "fullName": "Norm Thurston",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/THURSNK.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-UT-62-lower-ocd-person-8e36fabe-9e2a-4ccd-b29a-1395d6318560",
              "builtID": "ut-lower-62",
              "externalID": "ocd-person/8e36fabe-9e2a-4ccd-b29a-1395d6318560",
              "geometry": null
            },
            "contactInfo": {
              "email": "normthurston@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/THURSNK",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=THURSNK",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist64.pdf",
                "https://le.utah.gov/house2/CofI/COFITHURSNK2.pdf",
                "https://le.utah.gov/house2/CofI/COFITHURSNK1.pdf",
                "https://le.utah.gov/house2/CofI/COFITHURSNK.pdf",
                "https://le.utah.gov/house2/CofI/THURSNK.pdf",
                "https://le.utah.gov/house2/CofI/nthurston2.pdf",
                "https://le.utah.gov/house2/CofI/nthurston.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1412615",
                "https://house.utah.gov/rep/THURSNK/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=THURSNK",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist62.pdf",
                "https://house.utleg.gov/rep/THURSNK/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-62-lower-ocd-person-8e36fabe-9e2a-4ccd-b29a-1395d6318560": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/451ecbcb-42a9-467b-98c7-e0b3e5bd32d3",
            "firstName": "Stephen L.",
            "lastName": "Whyte",
            "fullName": "Stephen Whyte",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/WHYTESL.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-UT-63-lower-ocd-person-451ecbcb-42a9-467b-98c7-e0b3e5bd32d3",
              "builtID": "ut-lower-63",
              "externalID": "ocd-person/451ecbcb-42a9-467b-98c7-e0b3e5bd32d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "swhyte@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=WHYTESL",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist65.pdf",
                "https://house.utah.gov/rep/WHYTESL/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=WHYTESL",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415754",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist63.pdf",
                "https://house.utleg.gov/rep/WHYTESL/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-63-lower-ocd-person-451ecbcb-42a9-467b-98c7-e0b3e5bd32d3": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/1773d5d5-4967-4af5-9880-3106ace372d7",
            "firstName": "Jefferson S.",
            "lastName": "Burton",
            "fullName": "Jeff Burton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/BURTOJS.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-UT-64-lower-ocd-person-1773d5d5-4967-4af5-9880-3106ace372d7",
              "builtID": "ut-lower-64",
              "externalID": "ocd-person/1773d5d5-4967-4af5-9880-3106ace372d7",
              "geometry": null
            },
            "contactInfo": {
              "email": "jburton@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/BURTOJS",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=BURTOJS",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist66.pdf",
                "https://house.utah.gov/rep/BURTOJS/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=BURTOJS",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415546",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist64.pdf",
                "https://house.utleg.gov/rep/BURTOJS/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-64-lower-ocd-person-1773d5d5-4967-4af5-9880-3106ace372d7": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/725abf07-1cca-47a5-a5a7-223d763f5da0",
            "firstName": "Douglas R.",
            "lastName": "Welton",
            "fullName": "Doug Welton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/WELTOD.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-UT-65-lower-ocd-person-725abf07-1cca-47a5-a5a7-223d763f5da0",
              "builtID": "ut-lower-65",
              "externalID": "ocd-person/725abf07-1cca-47a5-a5a7-223d763f5da0",
              "geometry": null
            },
            "contactInfo": {
              "email": "dwelton@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/WELTOD",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=WELTOD",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist67.pdf",
                "https://house.utah.gov/rep/WELTOD/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=WELTOD",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415458",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist65.pdf",
                "https://house.utleg.gov/rep/WELTOD/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-65-lower-ocd-person-725abf07-1cca-47a5-a5a7-223d763f5da0": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/381c0817-041f-4bfc-8c51-a83212b7b229",
            "firstName": "Steven J.",
            "lastName": "Lund",
            "fullName": "Steve Lund",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/LUNDSJ.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-UT-66-lower-ocd-person-381c0817-041f-4bfc-8c51-a83212b7b229",
              "builtID": "ut-lower-66",
              "externalID": "ocd-person/381c0817-041f-4bfc-8c51-a83212b7b229",
              "geometry": null
            },
            "contactInfo": {
              "email": "slund@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/LUNDSJ",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=LUNDSJ",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist58.pdf",
                "https://house.utah.gov/rep/LUNDSJ/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=LUNDSJ",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415447",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist66.pdf",
                "https://house.utleg.gov/rep/LUNDSJ/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-66-lower-ocd-person-381c0817-041f-4bfc-8c51-a83212b7b229": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/052622aa-50ab-47c7-84b8-175d144a9d59",
            "firstName": "Christine Facer",
            "lastName": "Watkins",
            "fullName": "Christine Watkins",
            "gender": "Female",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/WATKICF.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-UT-67-lower-ocd-person-052622aa-50ab-47c7-84b8-175d144a9d59",
              "builtID": "ut-lower-67",
              "externalID": "ocd-person/052622aa-50ab-47c7-84b8-175d144a9d59",
              "geometry": null
            },
            "contactInfo": {
              "email": "christinewatkins@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/WATKICF",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=WATKICF",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist69.pdf",
                "https://le.utah.gov/house2/CofI/COFIWATKICF2.pdf",
                "https://le.utah.gov/house2/CofI/COFIWATKICF1.pdf",
                "https://le.utah.gov/house2/CofI/COFIWATKICF.pdf",
                "https://le.utah.gov/house2/CofI/WATKICF.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/2078",
                "https://house.utah.gov/rep/WATKICF/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=WATKICF",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist67.pdf",
                "https://house.utleg.gov/rep/WATKICF/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-67-lower-ocd-person-052622aa-50ab-47c7-84b8-175d144a9d59": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/d7a3c337-4395-4e23-9e11-74cb55744f14",
            "firstName": "Scott H.",
            "lastName": "Chew",
            "fullName": "Scott Chew",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/CHEWSH.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-UT-68-lower-ocd-person-d7a3c337-4395-4e23-9e11-74cb55744f14",
              "builtID": "ut-lower-68",
              "externalID": "ocd-person/d7a3c337-4395-4e23-9e11-74cb55744f14",
              "geometry": null
            },
            "contactInfo": {
              "email": "scottchew@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/CHEWSH",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=CHEWSH",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist55.pdf",
                "https://le.utah.gov/house2/CofI/COFICHEWSH1.pdf",
                "https://le.utah.gov/house2/CofI/COFICHEWSH.pdf",
                "https://le.utah.gov/house2/CofI/schew4.pdf",
                "https://le.utah.gov/house2/CofI/schew3.pdf",
                "https://le.utah.gov/house2/CofI/schew2.pdf",
                "https://le.utah.gov/house2/CofI/schew.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1412742",
                "https://house.utah.gov/rep/CHEWSH/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=CHEWSH",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist68.pdf",
                "https://house.utleg.gov/rep/CHEWSH/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-68-lower-ocd-person-d7a3c337-4395-4e23-9e11-74cb55744f14": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/e3cfd26d-2088-4bed-b4e6-0bc77b436ef9",
            "firstName": "Phil",
            "lastName": "Lyman",
            "fullName": "Phil Lyman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/LYMANP.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-UT-69-lower-ocd-person-e3cfd26d-2088-4bed-b4e6-0bc77b436ef9",
              "builtID": "ut-lower-69",
              "externalID": "ocd-person/e3cfd26d-2088-4bed-b4e6-0bc77b436ef9",
              "geometry": null
            },
            "contactInfo": {
              "email": "plyman@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/LYMANP",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=LYMANP",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist73.pdf",
                "https://le.utah.gov/house2/CofI/COFILYMANP.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415153",
                "https://house.utah.gov/rep/LYMANP/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=LYMANP",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist69.pdf",
                "https://house.utleg.gov/rep/LYMANP/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-69-lower-ocd-person-e3cfd26d-2088-4bed-b4e6-0bc77b436ef9": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/8967df77-dc79-46e0-873c-b79f9cf73e1f",
            "firstName": "Carl R.",
            "lastName": "Albrecht",
            "fullName": "Carl Albrecht",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/ALBRECR.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-UT-70-lower-ocd-person-8967df77-dc79-46e0-873c-b79f9cf73e1f",
              "builtID": "ut-lower-70",
              "externalID": "ocd-person/8967df77-dc79-46e0-873c-b79f9cf73e1f",
              "geometry": null
            },
            "contactInfo": {
              "email": "carlalbrecht@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/ALBRECR",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=ALBRECR",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist70.pdf",
                "https://le.utah.gov/house2/CofI/COFIALBRECR1.pdf",
                "https://le.utah.gov/house2/CofI/COFIALBRECR.pdf",
                "https://le.utah.gov/house2/CofI/ALBRECR.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414402",
                "https://house.utah.gov/rep/ALBRECR/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=ALBRECR",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist70.pdf",
                "https://house.utleg.gov/rep/ALBRECR/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-70-lower-ocd-person-8967df77-dc79-46e0-873c-b79f9cf73e1f": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/66ec0903-117f-4006-8e05-e7326330fc63",
            "firstName": "Rex P.",
            "lastName": "Shipp",
            "fullName": "Rex Shipp",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/SHIPPRP.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-UT-71-lower-ocd-person-66ec0903-117f-4006-8e05-e7326330fc63",
              "builtID": "ut-lower-71",
              "externalID": "ocd-person/66ec0903-117f-4006-8e05-e7326330fc63",
              "geometry": null
            },
            "contactInfo": {
              "email": "rshipp@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/SHIPPRP",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=SHIPPRP",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist72.pdf",
                "https://le.utah.gov/house2/CofI/COFISHIPPRP2.pdf",
                "https://le.utah.gov/house2/CofI/COFISHIPPRP1.pdf",
                "https://le.utah.gov/house2/CofI/COFISHIPPRP.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414994",
                "https://house.utah.gov/rep/SHIPPRP/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=SHIPPRP",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist71.pdf",
                "https://house.utleg.gov/rep/SHIPPRP/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-71-lower-ocd-person-66ec0903-117f-4006-8e05-e7326330fc63": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/0d22b3d0-94f2-4f88-95e9-b830480f4be8",
            "firstName": "Joseph",
            "lastName": "Elison",
            "fullName": "Joseph Elison",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/ELISOJ.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-UT-72-lower-ocd-person-0d22b3d0-94f2-4f88-95e9-b830480f4be8",
              "builtID": "ut-lower-72",
              "externalID": "ocd-person/0d22b3d0-94f2-4f88-95e9-b830480f4be8",
              "geometry": null
            },
            "contactInfo": {
              "email": "jelison@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=ELISOJ",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist72.pdf",
                "https://house.utah.gov/rep/ELISOJ/",
                "https://house.utleg.gov/rep/ELISOJ/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-72-lower-ocd-person-0d22b3d0-94f2-4f88-95e9-b830480f4be8": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/2fb1aaec-83c2-41f3-b98a-8a8a34e7301e",
            "firstName": "Colin W.",
            "lastName": "Jack",
            "fullName": "Colin Jack",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/JACKC.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-UT-73-lower-ocd-person-2fb1aaec-83c2-41f3-b98a-8a8a34e7301e",
              "builtID": "ut-lower-73",
              "externalID": "ocd-person/2fb1aaec-83c2-41f3-b98a-8a8a34e7301e",
              "geometry": null
            },
            "contactInfo": {
              "email": "cjack@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=JACKC",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist73.pdf",
                "https://house.utah.gov/rep/JACKC/",
                "https://house.utleg.gov/rep/JACKC/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-73-lower-ocd-person-2fb1aaec-83c2-41f3-b98a-8a8a34e7301e": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/5b90793c-f90a-4b0e-b9e6-78943bfaab1c",
            "firstName": "R. Neil",
            "lastName": "Walter",
            "fullName": "Neil Walter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/WALTER.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-UT-74-lower-ocd-person-5b90793c-f90a-4b0e-b9e6-78943bfaab1c",
              "builtID": "ut-lower-74",
              "externalID": "ocd-person/5b90793c-f90a-4b0e-b9e6-78943bfaab1c",
              "geometry": null
            },
            "contactInfo": {
              "email": "nwalter@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=WALTER",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist74.pdf",
                "https://house.utah.gov/rep/WALTER/",
                "https://house.utleg.gov/rep/WALTER/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-74-lower-ocd-person-5b90793c-f90a-4b0e-b9e6-78943bfaab1c": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/5cef5a01-f3ea-4c28-aa39-036449806388",
            "firstName": "Walt",
            "lastName": "Brooks",
            "fullName": "Walt Brooks",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/BROOKW.jpg",
            "title": "UT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-UT-75-lower-ocd-person-5cef5a01-f3ea-4c28-aa39-036449806388",
              "builtID": "ut-lower-75",
              "externalID": "ocd-person/5cef5a01-f3ea-4c28-aa39-036449806388",
              "geometry": null
            },
            "contactInfo": {
              "email": "wbrooks@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/BROOKW",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=BROOKW",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist75.pdf",
                "https://le.utah.gov/house2/CofI/COFIBROOKW1.pdf",
                "https://le.utah.gov/house2/CofI/COFIBROOKW.pdf",
                "https://le.utah.gov/house2/CofI/BROOKW1.pdf",
                "https://le.utah.gov/house2/CofI/BROOKW.pdf",
                "https://le.utah.gov/house2/CofI/wbrooks.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414382",
                "https://house.utah.gov/rep/BROOKW/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=BROOKW",
                "https://le.utah.gov/Documents/demographic/profiles/2022/House_Dist75.pdf",
                "https://house.utleg.gov/rep/BROOKW/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-UT-75-lower-ocd-person-5cef5a01-f3ea-4c28-aa39-036449806388": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/d1a1c031-9dce-4727-a298-ec92cb34fe40",
            "firstName": "Scott D.",
            "lastName": "Sandall",
            "fullName": "Scott Sandall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/SANDASD.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-UT-1-upper-ocd-person-d1a1c031-9dce-4727-a298-ec92cb34fe40",
              "builtID": "ut-upper-1",
              "externalID": "ocd-person/d1a1c031-9dce-4727-a298-ec92cb34fe40",
              "geometry": null
            },
            "contactInfo": {
              "email": "ssandall@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/scott-sandall",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=SANDASD",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist17.pdf",
                "https://le.utah.gov/senate/CofI/COFISANDASD.pdf",
                "https://le.utah.gov/house2/CofI/COFISANDASD1.pdf",
                "https://le.utah.gov/house2/CofI/COFISANDASD.pdf",
                "https://le.utah.gov/house2/CofI/SANDASD1.pdf",
                "https://le.utah.gov/house2/CofI/SANDASD.pdf",
                "https://le.utah.gov/house2/CofI/ssandall2.pdf",
                "https://le.utah.gov/house2/CofI/ssandall.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1412644",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist01.pdf",
                "https://senate.utah.gov/sen/SANDASD/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-1-upper-ocd-person-d1a1c031-9dce-4727-a298-ec92cb34fe40": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/3acc854e-2a4d-43ff-9cfb-63d527bb8186",
            "firstName": "Chris",
            "lastName": "Wilson",
            "fullName": "Chris Wilson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/WILSOCH.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-UT-2-upper-ocd-person-3acc854e-2a4d-43ff-9cfb-63d527bb8186",
              "builtID": "ut-upper-2",
              "externalID": "ocd-person/3acc854e-2a4d-43ff-9cfb-63d527bb8186",
              "geometry": null
            },
            "contactInfo": {
              "email": "cwilson@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/chris-wilson",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=WILSOCH",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist25.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415396",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist02.pdf",
                "https://senate.utah.gov/sen/WILSOCH/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-2-upper-ocd-person-3acc854e-2a4d-43ff-9cfb-63d527bb8186": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/0c8df9e4-cb46-4dc1-ab5b-6d181efd58e0",
            "firstName": "John D.",
            "lastName": "Johnson",
            "fullName": "John Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/JOHNSJD.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-UT-3-upper-ocd-person-0c8df9e4-cb46-4dc1-ab5b-6d181efd58e0",
              "builtID": "ut-upper-3",
              "externalID": "ocd-person/0c8df9e4-cb46-4dc1-ab5b-6d181efd58e0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jjohnson@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/john-johnson",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=JOHNSJD",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist19.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415473",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist03.pdf",
                "https://senate.utah.gov/sen/JOHNSJD/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-3-upper-ocd-person-0c8df9e4-cb46-4dc1-ab5b-6d181efd58e0": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/f30c3419-d198-4c97-89ed-271e469ba883",
            "firstName": "David Gregg",
            "lastName": "Buxton",
            "fullName": "Gregg Buxton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/BUXTODG.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-UT-4-upper-ocd-person-f30c3419-d198-4c97-89ed-271e469ba883",
              "builtID": "ut-upper-4",
              "externalID": "ocd-person/f30c3419-d198-4c97-89ed-271e469ba883",
              "geometry": null
            },
            "contactInfo": {
              "email": "gbuxton@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/david-buxton",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=BUXTODG",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist20.pdf",
                "https://le.utah.gov/senate/CofI/COFIBUXTODG.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414407",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist04.pdf",
                "https://senate.utah.gov/sen/BUXTODG/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-4-upper-ocd-person-f30c3419-d198-4c97-89ed-271e469ba883": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/6b86542a-9b8a-418f-a275-6c261cdd86d2",
            "firstName": "F. Ann",
            "lastName": "Millner",
            "fullName": "Ann Millner",
            "gender": "Female",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/MILLNA.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-UT-5-upper-ocd-person-6b86542a-9b8a-418f-a275-6c261cdd86d2",
              "builtID": "ut-upper-5",
              "externalID": "ocd-person/6b86542a-9b8a-418f-a275-6c261cdd86d2",
              "geometry": null
            },
            "contactInfo": {
              "email": "amillner@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/ann-millner",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=MILLNA",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist18.pdf",
                "https://le.utah.gov/senate/CofI/COFIMILLNA.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1412594",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist05.pdf",
                "https://senate.utah.gov/sen/MILLNA/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-5-upper-ocd-person-6b86542a-9b8a-418f-a275-6c261cdd86d2": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/3a04e48f-6d8c-47aa-b0aa-0e14994cd961",
            "firstName": "Jerry W.",
            "lastName": "Stevenson",
            "fullName": "Jerry Stevenson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/STEVEJW.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-UT-6-upper-ocd-person-3a04e48f-6d8c-47aa-b0aa-0e14994cd961",
              "builtID": "ut-upper-6",
              "externalID": "ocd-person/3a04e48f-6d8c-47aa-b0aa-0e14994cd961",
              "geometry": null
            },
            "contactInfo": {
              "email": "jwstevenson@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/jerry-stevenson",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=STEVEJW",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist21.pdf",
                "https://le.utah.gov/senate/CofI/COFISTEVEJW2.pdf",
                "https://le.utah.gov/senate/CofI/COFISTEVEJW.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1409402",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist06.pdf",
                "https://senate.utah.gov/sen/STEVEJW/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-6-upper-ocd-person-3a04e48f-6d8c-47aa-b0aa-0e14994cd961": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/5b47c889-e555-4d3c-9b30-97d52ee02960",
            "firstName": "J. Stuart",
            "lastName": "Adams",
            "fullName": "Stuart Adams",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/ADAMSJS.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-UT-7-upper-ocd-person-5b47c889-e555-4d3c-9b30-97d52ee02960",
              "builtID": "ut-upper-7",
              "externalID": "ocd-person/5b47c889-e555-4d3c-9b30-97d52ee02960",
              "geometry": null
            },
            "contactInfo": {
              "email": "jsadams@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/stuart-adams",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=ADAMSJS",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist22.pdf",
                "https://le.utah.gov/senate/CofI/COFIADAMSJS2.pdf",
                "https://le.utah.gov/senate/CofI/COFIADAMSJS.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1408736",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist07.pdf",
                "https://senate.utah.gov/sen/ADAMSJS/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-7-upper-ocd-person-5b47c889-e555-4d3c-9b30-97d52ee02960": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/6d49f384-ea91-41f2-b703-229a0a7452d9",
            "firstName": "Todd",
            "lastName": "Weiler",
            "fullName": "Todd Weiler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/WEILET.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-UT-8-upper-ocd-person-6d49f384-ea91-41f2-b703-229a0a7452d9",
              "builtID": "ut-upper-8",
              "externalID": "ocd-person/6d49f384-ea91-41f2-b703-229a0a7452d9",
              "geometry": null
            },
            "contactInfo": {
              "email": "tweiler@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/todd-weiler",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=WEILET",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist23.pdf",
                "https://le.utah.gov/senate/CofI/COFIWEILET.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1411361",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist08.pdf",
                "https://senate.utah.gov/sen/WEILET/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-8-upper-ocd-person-6d49f384-ea91-41f2-b703-229a0a7452d9": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/bd870ade-5b3f-4ed3-9e6f-6bbab93a7d35",
            "firstName": "Jennifer",
            "lastName": "Plumb",
            "fullName": "Jen Plumb",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/PLUMBJ.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-UT-9-upper-ocd-person-bd870ade-5b3f-4ed3-9e6f-6bbab93a7d35",
              "builtID": "ut-upper-9",
              "externalID": "ocd-person/bd870ade-5b3f-4ed3-9e6f-6bbab93a7d35",
              "geometry": null
            },
            "contactInfo": {
              "email": "jplumb@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=PLUMBJ",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist09.pdf",
                "https://senate.utah.gov/sen/PLUMBJ/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-9-upper-ocd-person-bd870ade-5b3f-4ed3-9e6f-6bbab93a7d35": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/6983c74c-3687-4e48-b462-38a80caeb260",
            "firstName": "Luz Robles",
            "lastName": "Escamilla",
            "fullName": "Luz Escamilla",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/ESCAML.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-UT-10-upper-ocd-person-6983c74c-3687-4e48-b462-38a80caeb260",
              "builtID": "ut-upper-10",
              "externalID": "ocd-person/6983c74c-3687-4e48-b462-38a80caeb260",
              "geometry": null
            },
            "contactInfo": {
              "email": "lescamilla@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/luz-escamilla",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=ESCAML",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist01.pdf",
                "https://le.utah.gov/senate/CofI/COFIESCAML1.pdf",
                "https://le.utah.gov/senate/CofI/COFIESCAML.pdf",
                "https://le.utah.gov/senate/CofI/COFIESCAML2.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1875",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist10.pdf",
                "https://senate.utah.gov/sen/ESCAML/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-10-upper-ocd-person-6983c74c-3687-4e48-b462-38a80caeb260": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/98a1bd6d-57c3-4c15-8dcd-f31ea41c2472",
            "firstName": "Daniel W.",
            "lastName": "Thatcher",
            "fullName": "Daniel Thatcher",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/THATCDW.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-UT-11-upper-ocd-person-98a1bd6d-57c3-4c15-8dcd-f31ea41c2472",
              "builtID": "ut-upper-11",
              "externalID": "ocd-person/98a1bd6d-57c3-4c15-8dcd-f31ea41c2472",
              "geometry": null
            },
            "contactInfo": {
              "email": "dthatcher@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=THATCDW",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist11.pdf",
                "https://le.utah.gov/senate/CofI/COFITHATCDW.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1409399",
                "https://senate.utah.gov/sen/THATCDW/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-11-upper-ocd-person-98a1bd6d-57c3-4c15-8dcd-f31ea41c2472": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/3ceb7500-0ea7-46ef-9a75-d00d378b86b7",
            "firstName": "Karen",
            "lastName": "Kwan",
            "fullName": "Karen Kwan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/KWANK.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-UT-12-upper-ocd-person-3ceb7500-0ea7-46ef-9a75-d00d378b86b7",
              "builtID": "ut-upper-12",
              "externalID": "ocd-person/3ceb7500-0ea7-46ef-9a75-d00d378b86b7",
              "geometry": null
            },
            "contactInfo": {
              "email": "kkwan@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/KWANK",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=KWANK",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist34.pdf",
                "https://le.utah.gov/house2/CofI/COFIKWANK1.pdf",
                "https://le.utah.gov/house2/CofI/COFIKWANK.pdf",
                "https://le.utah.gov/house2/CofI/KWANK1.pdf",
                "https://le.utah.gov/house2/CofI/KWANK.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1412727",
                "https://house.utah.gov/rep/KWANK/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=KWANK",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist12.pdf",
                "https://senate.utah.gov/sen/KWANK/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-12-upper-ocd-person-3ceb7500-0ea7-46ef-9a75-d00d378b86b7": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/c9f2ef36-ee69-4e91-9da9-6c9311334912",
            "firstName": "Nate",
            "lastName": "Blouin",
            "fullName": "Nate Blouin",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/BLOUIN.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-UT-13-upper-ocd-person-c9f2ef36-ee69-4e91-9da9-6c9311334912",
              "builtID": "ut-upper-13",
              "externalID": "ocd-person/c9f2ef36-ee69-4e91-9da9-6c9311334912",
              "geometry": null
            },
            "contactInfo": {
              "email": "nblouin@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=BLOUIN",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist13.pdf",
                "https://senate.utah.gov/sen/BLOUIN/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-13-upper-ocd-person-c9f2ef36-ee69-4e91-9da9-6c9311334912": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/edf0f18c-4b89-4d34-a2eb-6ce79accdaa7",
            "firstName": "Stephanie",
            "lastName": "Pitcher",
            "fullName": "Stephanie Pitcher",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/PITCHS.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-UT-14-upper-ocd-person-edf0f18c-4b89-4d34-a2eb-6ce79accdaa7",
              "builtID": "ut-upper-14",
              "externalID": "ocd-person/edf0f18c-4b89-4d34-a2eb-6ce79accdaa7",
              "geometry": null
            },
            "contactInfo": {
              "email": "spitcher@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://house.utah.gov/rep/PITCHS",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=PITCHS",
                "https://le.utah.gov/Documents/demographic/profiles/2020/House_Dist40.pdf",
                "https://le.utah.gov/house2/CofI/COFIPITCHS.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414982",
                "https://house.utah.gov/rep/PITCHS/",
                "https://le.utah.gov/asp/billsintro/RepResults.asp?Listbox3=PITCHS",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist14.pdf",
                "https://senate.utah.gov/sen/PITCHS/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-14-upper-ocd-person-edf0f18c-4b89-4d34-a2eb-6ce79accdaa7": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/67c6161b-0222-4702-85ce-7409bc5bc86a",
            "firstName": "Kathleen",
            "lastName": "Riebe",
            "fullName": "Kathleen Riebe",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://le.utah.gov/images/legislator/RIEBEK.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-UT-15-upper-ocd-person-67c6161b-0222-4702-85ce-7409bc5bc86a",
              "builtID": "ut-upper-15",
              "externalID": "ocd-person/67c6161b-0222-4702-85ce-7409bc5bc86a",
              "geometry": null
            },
            "contactInfo": {
              "email": "kriebe@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/kathleen-riebe",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=RIEBEK",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist08.pdf",
                "https://le.utah.gov/senate/CofI/RIEBEK.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414459",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist15.pdf",
                "https://senate.utah.gov/sen/RIEBEK/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-15-upper-ocd-person-67c6161b-0222-4702-85ce-7409bc5bc86a": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/c351d354-5397-4f6e-b67d-98c41cea0d2c",
            "firstName": "Wayne A.",
            "lastName": "Harper",
            "fullName": "Wayne Harper",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/HARPEWA.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-UT-16-upper-ocd-person-c351d354-5397-4f6e-b67d-98c41cea0d2c",
              "builtID": "ut-upper-16",
              "externalID": "ocd-person/c351d354-5397-4f6e-b67d-98c41cea0d2c",
              "geometry": null
            },
            "contactInfo": {
              "email": "wharper@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/wayne-harper",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=HARPEWA",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist06.pdf",
                "https://le.utah.gov/senate/CofI/HARPEWA.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1070",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist16.pdf",
                "https://senate.utah.gov/sen/HARPEWA/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-16-upper-ocd-person-c351d354-5397-4f6e-b67d-98c41cea0d2c": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/74f25c67-1e6f-48f1-b3e3-49bbdf85539a",
            "firstName": "Lincoln",
            "lastName": "Fillmore",
            "fullName": "Lincoln Fillmore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/FILLML.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-UT-17-upper-ocd-person-74f25c67-1e6f-48f1-b3e3-49bbdf85539a",
              "builtID": "ut-upper-17",
              "externalID": "ocd-person/74f25c67-1e6f-48f1-b3e3-49bbdf85539a",
              "geometry": null
            },
            "contactInfo": {
              "email": "lfillmore@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=FILLML",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist17.pdf",
                "https://le.utah.gov/senate/CofI/COFIFILLML.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1413349",
                "https://senate.utah.gov/sen/FILLML/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-17-upper-ocd-person-74f25c67-1e6f-48f1-b3e3-49bbdf85539a": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/6540aa74-9456-4b29-9b56-cadd31104db2",
            "firstName": "Daniel",
            "lastName": "McCay",
            "fullName": "Dan McCay",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/MCCAYD.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-UT-18-upper-ocd-person-6540aa74-9456-4b29-9b56-cadd31104db2",
              "builtID": "ut-upper-18",
              "externalID": "ocd-person/6540aa74-9456-4b29-9b56-cadd31104db2",
              "geometry": null
            },
            "contactInfo": {
              "email": "dmccay@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/daniel-mccay",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=MCCAYD",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist11.pdf",
                "https://le.utah.gov/senate/CofI/COFIMCCAYD.pdf",
                "https://le.utah.gov/house2/CofI/COFIMCCAYD.pdf",
                "https://le.utah.gov/house2/CofI/MCCAYD1.pdf",
                "https://le.utah.gov/house2/CofI/dmccay2.pdf",
                "https://le.utah.gov/house2/CofI/dmccay.pdf",
                "https://le.utah.gov/house2/CofI/MCCAYD.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1411368",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist18.pdf",
                "https://senate.utah.gov/sen/MCCAYD/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-18-upper-ocd-person-6540aa74-9456-4b29-9b56-cadd31104db2": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/c421a26f-c1c1-4398-be94-d77d312ea3fe",
            "firstName": "Kirk A.",
            "lastName": "Cullimore",
            "fullName": "Kirk Cullimore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/CULLIKA.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-UT-19-upper-ocd-person-c421a26f-c1c1-4398-be94-d77d312ea3fe",
              "builtID": "ut-upper-19",
              "externalID": "ocd-person/c421a26f-c1c1-4398-be94-d77d312ea3fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "kcullimore@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/kirk-cullimore",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=CULLIKA",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist09.pdf",
                "https://le.utah.gov/senate/CofI/COFICULLIKA.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1415051",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist19.pdf",
                "https://senate.utah.gov/sen/CULLIKA/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-19-upper-ocd-person-c421a26f-c1c1-4398-be94-d77d312ea3fe": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/5079192a-3d15-4a00-b0c7-2cf29d320035",
            "firstName": "Ronald",
            "lastName": "Winterton",
            "fullName": "Ron Winterton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/WINTER.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-UT-20-upper-ocd-person-5079192a-3d15-4a00-b0c7-2cf29d320035",
              "builtID": "ut-upper-20",
              "externalID": "ocd-person/5079192a-3d15-4a00-b0c7-2cf29d320035",
              "geometry": null
            },
            "contactInfo": {
              "email": "rwinterton@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/ronald-winterton",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=WINTER",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist26.pdf",
                "https://le.utah.gov/senate/CofI/COFIWINTER.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414932",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist20.pdf",
                "https://senate.utah.gov/sen/WINTER/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-20-upper-ocd-person-5079192a-3d15-4a00-b0c7-2cf29d320035": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/53a3ee25-bf36-4c14-8b89-8efece8c6021",
            "firstName": "Michael Stevan",
            "lastName": "Kennedy",
            "fullName": "Mike Kennedy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/KENNEMS.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-UT-21-upper-ocd-person-53a3ee25-bf36-4c14-8b89-8efece8c6021",
              "builtID": "ut-upper-21",
              "externalID": "ocd-person/53a3ee25-bf36-4c14-8b89-8efece8c6021",
              "geometry": null
            },
            "contactInfo": {
              "email": "mkennedy@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/michael-kennedy",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=KENNEMS",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist14.pdf",
                "https://le.utah.gov/house2/CofI/COFIKENNEMS.pdf",
                "https://le.utah.gov/house2/CofI/KENNEMS3.pdf",
                "https://le.utah.gov/house2/CofI/KENNEMS2.pdf",
                "https://le.utah.gov/house2/CofI/KENNEMS1.pdf",
                "https://le.utah.gov/house2/CofI/mkennedy2.pdf",
                "https://le.utah.gov/house2/CofI/mkennedy.pdf",
                "https://le.utah.gov/house2/CofI/KENNEMS.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1411326",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist21.pdf",
                "https://senate.utah.gov/sen/KENNEMS/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-21-upper-ocd-person-53a3ee25-bf36-4c14-8b89-8efece8c6021": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/650616ca-3c57-4995-a514-37644fe1fa8f",
            "firstName": "Heidi",
            "lastName": "Balderree",
            "fullName": "Heidi Balderree",
            "gender": "Female",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/BALDEH.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-UT-22-upper-ocd-person-650616ca-3c57-4995-a514-37644fe1fa8f",
              "builtID": "ut-upper-22",
              "externalID": "ocd-person/650616ca-3c57-4995-a514-37644fe1fa8f",
              "geometry": null
            },
            "contactInfo": {
              "email": "hbalderree@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=BALDEH",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist22.pdf",
                "https://senate.utah.gov/sen/BALDEH/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-22-upper-ocd-person-650616ca-3c57-4995-a514-37644fe1fa8f": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/7634ce2b-d172-4852-919c-7089eaa38db8",
            "firstName": "Keith",
            "lastName": "Grover",
            "fullName": "Keith Grover",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/GROVEK.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-UT-23-upper-ocd-person-7634ce2b-d172-4852-919c-7089eaa38db8",
              "builtID": "ut-upper-23",
              "externalID": "ocd-person/7634ce2b-d172-4852-919c-7089eaa38db8",
              "geometry": null
            },
            "contactInfo": {
              "email": "keithgrover@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/keith-grover",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=GROVEK",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist15.pdf",
                "https://le.utah.gov/senate/CofI/GROVEK.pdf",
                "https://le.utah.gov/house2/CofI/COFIGROVEK.pdf",
                "https://le.utah.gov/house2/CofI/GROVEK1.pdf",
                "https://le.utah.gov/house2/CofI/kgrover2.pdf",
                "https://le.utah.gov/house2/CofI/kgrover.pdf",
                "https://le.utah.gov/house2/CofI/GROVEK.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1913",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist23.pdf",
                "https://senate.utah.gov/sen/GROVEK/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-23-upper-ocd-person-7634ce2b-d172-4852-919c-7089eaa38db8": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/2c94421c-009b-4e64-9e10-b5e1f71afadd",
            "firstName": "Curtis Scott",
            "lastName": "Bramble",
            "fullName": "Curt Bramble",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/BRAMBCS.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-UT-24-upper-ocd-person-2c94421c-009b-4e64-9e10-b5e1f71afadd",
              "builtID": "ut-upper-24",
              "externalID": "ocd-person/2c94421c-009b-4e64-9e10-b5e1f71afadd",
              "geometry": null
            },
            "contactInfo": {
              "email": "curt@cbramble.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/curtis-bramble",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=BRAMBCS",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist16.pdf",
                "https://le.utah.gov/senate/CofI/COFIBRAMBCS1.pdf",
                "https://le.utah.gov/senate/CofI/BRAMBCS.pdf",
                "https://le.utah.gov/senate/CofI/COFIBRAMBCS.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1010",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist24.pdf",
                "https://senate.utah.gov/sen/BRAMBCS/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-24-upper-ocd-person-2c94421c-009b-4e64-9e10-b5e1f71afadd": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/8ffe791d-6a74-412b-a435-ee04045e0ad0",
            "firstName": "Michael K.",
            "lastName": "McKell",
            "fullName": "Mike McKell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/MCKELMK.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-UT-25-upper-ocd-person-8ffe791d-6a74-412b-a435-ee04045e0ad0",
              "builtID": "ut-upper-25",
              "externalID": "ocd-person/8ffe791d-6a74-412b-a435-ee04045e0ad0",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmckell@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/michael-mckell",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=MCKELMK",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist07.pdf",
                "https://le.utah.gov/house2/CofI/COFIMCKELMK2.pdf",
                "https://le.utah.gov/house2/CofI/COFIMCKELMK1.pdf",
                "https://le.utah.gov/house2/CofI/COFIMCKELMK.pdf",
                "https://le.utah.gov/house2/CofI/MCKELMK1.pdf",
                "https://le.utah.gov/house2/CofI/mmckell2.pdf",
                "https://le.utah.gov/house2/CofI/mmckell.pdf",
                "https://le.utah.gov/house2/CofI/MCKELMK.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1411332",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist25.pdf",
                "https://senate.utah.gov/sen/MCKELMK/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-25-upper-ocd-person-8ffe791d-6a74-412b-a435-ee04045e0ad0": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/78b7c95c-45a7-40cb-a82c-681db75f9529",
            "firstName": "David P.",
            "lastName": "Hinkins",
            "fullName": "David Hinkins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/HINKIDP.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-UT-26-upper-ocd-person-78b7c95c-45a7-40cb-a82c-681db75f9529",
              "builtID": "ut-upper-26",
              "externalID": "ocd-person/78b7c95c-45a7-40cb-a82c-681db75f9529",
              "geometry": null
            },
            "contactInfo": {
              "email": "dhinkins@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/david-hinkins",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=HINKIDP",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist27.pdf",
                "https://le.utah.gov/senate/CofI/COFIHINKIDP.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/2095",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist26.pdf",
                "https://senate.utah.gov/sen/HINKIDP/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-26-upper-ocd-person-78b7c95c-45a7-40cb-a82c-681db75f9529": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/ef1c9af3-b4f9-4eeb-b63d-a22f01c84262",
            "firstName": "Derrin R.",
            "lastName": "Owens",
            "fullName": "Derrin Owens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/OWENSD.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-UT-27-upper-ocd-person-ef1c9af3-b4f9-4eeb-b63d-a22f01c84262",
              "builtID": "ut-upper-27",
              "externalID": "ocd-person/ef1c9af3-b4f9-4eeb-b63d-a22f01c84262",
              "geometry": null
            },
            "contactInfo": {
              "email": "derrinowens@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=OWENSD",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist27.pdf",
                "https://le.utah.gov/house2/CofI/COFIOWENSD4.pdf",
                "https://le.utah.gov/house2/CofI/COFIOWENSD3.pdf",
                "https://le.utah.gov/house2/CofI/COFIOWENSD2.pdf",
                "https://le.utah.gov/house2/CofI/COFIOWENSD1.pdf",
                "https://le.utah.gov/house2/CofI/COFIOWENSD.pdf",
                "https://le.utah.gov/house2/CofI/OWENSD1.pdf",
                "https://le.utah.gov/house2/CofI/OWENSD.pdf",
                "https://le.utah.gov/house2/CofI/dowens2.pdf",
                "https://le.utah.gov/house2/CofI/dowens.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/1414375",
                "https://senate.utah.gov/sen/OWENSD/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-27-upper-ocd-person-ef1c9af3-b4f9-4eeb-b63d-a22f01c84262": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/3ee27d8b-c865-4d86-b33e-9cfb34c3bac4",
            "firstName": "Evan J.",
            "lastName": "Vickers",
            "fullName": "Evan Vickers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/VICKEEJ.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-UT-28-upper-ocd-person-3ee27d8b-c865-4d86-b33e-9cfb34c3bac4",
              "builtID": "ut-upper-28",
              "externalID": "ocd-person/3ee27d8b-c865-4d86-b33e-9cfb34c3bac4",
              "geometry": null
            },
            "contactInfo": {
              "email": "evickers@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/evan-vickers",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=VICKEEJ",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist28.pdf",
                "https://le.utah.gov/senate/CofI/COFIVICKEEJ.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/2085",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist28.pdf",
                "https://senate.utah.gov/sen/VICKEEJ/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-28-upper-ocd-person-3ee27d8b-c865-4d86-b33e-9cfb34c3bac4": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/cbfab19e-80f2-4edc-ae55-08f9e03a6d2f",
            "firstName": "Don L.",
            "lastName": "Ipson",
            "fullName": "Don Ipson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://le.utah.gov/images/legislator/IPSONDL.jpg",
            "title": "UT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "UT",
              "stateFull": "Utah",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-UT-29-upper-ocd-person-cbfab19e-80f2-4edc-ae55-08f9e03a6d2f",
              "builtID": "ut-upper-29",
              "externalID": "ocd-person/cbfab19e-80f2-4edc-ae55-08f9e03a6d2f",
              "geometry": null
            },
            "contactInfo": {
              "email": "dipson@le.utah.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://senate.utah.gov/don-ipson",
                "https://le.utah.gov/asp/billsintro/SenResults.asp?Listbox2=IPSONDL",
                "https://le.utah.gov/Documents/demographic/profiles/2020/Senate_Dist29.pdf",
                "https://le.utah.gov/senate/CofI/COFIIPSONDL2.pdf",
                "https://le.utah.gov/house2/CofI/dipson3.pdf",
                "https://le.utah.gov/house2/CofI/dipson2.pdf",
                "https://le.utah.gov/house2/CofI/dipson.pdf",
                "https://le.utah.gov/house2/CofI/IPSONDL.pdf",
                "https://disclosures.utah.gov/Search/PublicSearch/FolderDetails/2052",
                "https://le.utah.gov/Documents/demographic/profiles/2022/Senate_Dist29.pdf",
                "https://senate.utah.gov/sen/IPSONDL/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-UT-29-upper-ocd-person-cbfab19e-80f2-4edc-ae55-08f9e03a6d2f": 0
        }
      }
    }
  },
  "VT": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "VT",
          "stateFull": "Vermont",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "VT",
            "stateFull": "Vermont",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-VT---",
            "builtID": "vt--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-VT---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "S000033",
          "in_office": true,
          "firstName": "Bernard",
          "lastName": "Sanders",
          "middleName": null,
          "fullName": "Bernard Sanders",
          "gender": "M",
          "party": "Independent",
          "image": "https://theunitedstates.io/images/congress/225x275/S000033.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "VT",
            "stateFull": "Vermont",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-VT---S000033",
            "builtID": "vt--",
            "externalID": "S000033",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.sanders.senate.gov/contact/",
            "address1": "332 Dirksen Senate Office Building",
            "address2": null,
            "phone1": "202-224-5141",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenSanders",
            "youtube": "senatorsanders",
            "instagram": null,
            "facebook": "senatorsanders",
            "urls": ["https://www.sanders.senate.gov"],
            "rss_url": null
          },
          "title": "VT Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/S000033.json",
          "govtrack_id": "400357",
          "cspan_id": "994",
          "votesmart_id": "27110",
          "icpsr_id": "29147",
          "crp_id": "N00000528",
          "google_entity_id": "/m/01_gbv",
          "state_rank": "senior",
          "lis_id": "S313",
          "suffix": null,
          "date_of_birth": "1941-09-08",
          "leadership_role": null,
          "fec_candidate_id": "H8VT01016",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "17",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 59,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 10.65,
          "votes_with_party_pct": 88.44,
          "votes_against_party_pct": 11.56,
          "ocd_id": "ocd-division/country:us/state:vt"
        },
        {
          "API_ID": "W000800",
          "in_office": true,
          "firstName": "Peter",
          "lastName": "Welch",
          "middleName": null,
          "fullName": "Peter Welch",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/W000800.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "VT",
            "stateFull": "Vermont",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-VT---W000800",
            "builtID": "vt--",
            "externalID": "W000800",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "PeterWelch",
            "youtube": "reppeterwelch",
            "instagram": null,
            "facebook": "PeterWelch",
            "urls": [],
            "rss_url": "https://welch.house.gov/rss.xml"
          },
          "title": "VT Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/W000800.json",
          "govtrack_id": "412239",
          "cspan_id": "1019990",
          "votesmart_id": "51272",
          "icpsr_id": "20750",
          "crp_id": "N00000515",
          "google_entity_id": "/m/0db4gl",
          "state_rank": "junior",
          "lis_id": "S422",
          "suffix": null,
          "date_of_birth": "1947-05-02",
          "leadership_role": null,
          "fec_candidate_id": "H6VT00160",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "1",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 10,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 1.81,
          "votes_with_party_pct": 95.94,
          "votes_against_party_pct": 4.06,
          "ocd_id": "ocd-division/country:us/state:vt"
        }
      ],
      "hash": { "SENATE-VT---S000033": 0, "SENATE-VT---W000800": 1 }
    },
    "HOUSE": {
      "00": {
        "members": [
          {
            "API_ID": "B001318",
            "in_office": true,
            "firstName": "Becca",
            "lastName": "Balint",
            "middleName": null,
            "fullName": "Becca Balint",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/B001318.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "00",
              "chamber": "",
              "hashID": "HOUSE-VT-00--B001318",
              "builtID": "vt--00",
              "externalID": "B001318",
              "geometry": null,
              "geoid": "5000"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1408 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4115",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBeccaB",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://balint.house.gov/"],
              "rss_url": null
            },
            "title": "VT House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001318.json",
            "govtrack_id": "456948",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1968-05-04",
            "leadership_role": null,
            "fec_candidate_id": "H2VT01076",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 18,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.7,
            "votes_with_party_pct": 94.75,
            "votes_against_party_pct": 3.31,
            "ocd_id": "ocd-division/country:us/state:vt/cd:1",
            "at_large": true
          }
        ],
        "hash": { "HOUSE-VT-00--B001318": 0 }
      }
    },
    "SLDL": {
      "Windsor 3": {
        "members": [
          {
            "API_ID": "ocd-person/24194dba-4d25-4b8c-be33-8ff50fcd8a8f",
            "firstName": "Alice M.",
            "lastName": "Emmons",
            "fullName": "Alice Emmons",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/AEmmons.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windsor 3-lower-ocd-person-24194dba-4d25-4b8c-be33-8ff50fcd8a8f",
              "builtID": "vt-lower-windsor-3",
              "externalID": "ocd-person/24194dba-4d25-4b8c-be33-8ff50fcd8a8f",
              "geometry": null
            },
            "contactInfo": {
              "email": "aemmons@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/14512",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDR-3-2.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windsor-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/14512"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/aa90c35b-88cb-4f97-9d66-70d777f7cae4",
            "firstName": "Kristi",
            "lastName": "Morris",
            "fullName": "Kristi Morris",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/KristiMorris.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windsor 3-lower-ocd-person-aa90c35b-88cb-4f97-9d66-70d777f7cae4",
              "builtID": "vt-lower-windsor-3",
              "externalID": "ocd-person/aa90c35b-88cb-4f97-9d66-70d777f7cae4",
              "geometry": null
            },
            "contactInfo": {
              "email": "kmorris2@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/33012",
                "https://legislature.vermont.gov/people/single/2022/33012",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDR-3-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/33012"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windsor 3-lower-ocd-person-24194dba-4d25-4b8c-be33-8ff50fcd8a8f": 0,
          "SLDL-VT-Windsor 3-lower-ocd-person-aa90c35b-88cb-4f97-9d66-70d777f7cae4": 1
        }
      },
      "Chittenden 24": {
        "members": [
          {
            "API_ID": "ocd-person/cd4ceb1c-8960-4b10-b6f8-bd0b8ae0c68c",
            "firstName": "Alyssa Hughes",
            "lastName": "Black",
            "fullName": "Alyssa Black",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Black,Alyssa.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 24",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 24-lower-ocd-person-cd4ceb1c-8960-4b10-b6f8-bd0b8ae0c68c",
              "builtID": "vt-lower-chittenden-24",
              "externalID": "ocd-person/cd4ceb1c-8960-4b10-b6f8-bd0b8ae0c68c",
              "geometry": null
            },
            "contactInfo": {
              "email": "ablack@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34710",
                "https://legislature.vermont.gov/people/single/2022/34710",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-8-3.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-24.pdf",
                "https://legislature.vermont.gov/people/single/2024/34710"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 24-lower-ocd-person-cd4ceb1c-8960-4b10-b6f8-bd0b8ae0c68c": 0
        }
      },
      "Addison 1": {
        "members": [
          {
            "API_ID": "ocd-person/f490d01d-80b7-40ae-9577-572bd1c8567a",
            "firstName": "Amy",
            "lastName": "Sheldon",
            "fullName": "Amy Sheldon",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Sheldon.Amy.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Addison 1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Addison 1-lower-ocd-person-f490d01d-80b7-40ae-9577-572bd1c8567a",
              "builtID": "vt-lower-addison-1",
              "externalID": "ocd-person/f490d01d-80b7-40ae-9577-572bd1c8567a",
              "geometry": null
            },
            "contactInfo": {
              "email": "asheldon@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/24022",
                "https://legislature.vermont.gov/Documents/SmallMaps/ADD-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/24022"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b463b201-086b-4f98-ab96-75a136e8be35",
            "firstName": "Robin",
            "lastName": "Scheu",
            "fullName": "Robin Scheu",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Scheu.Robin.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Addison 1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Addison 1-lower-ocd-person-b463b201-086b-4f98-ab96-75a136e8be35",
              "builtID": "vt-lower-addison-1",
              "externalID": "ocd-person/b463b201-086b-4f98-ab96-75a136e8be35",
              "geometry": null
            },
            "contactInfo": {
              "email": "rscheu@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/27154",
                "https://legislature.vermont.gov/people/single/2022/27154",
                "https://legislature.vermont.gov/Documents/SmallMaps/ADD-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/27154"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Addison 1-lower-ocd-person-f490d01d-80b7-40ae-9577-572bd1c8567a": 0,
          "SLDL-VT-Addison 1-lower-ocd-person-b463b201-086b-4f98-ab96-75a136e8be35": 1
        }
      },
      "Chittenden 2": {
        "members": [
          {
            "API_ID": "ocd-person/91f08063-02d6-4167-aa23-bbfb2289bbf1",
            "firstName": "Angela",
            "lastName": "Arsenault",
            "fullName": "Angela Arsenault",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Arsenault,Andela.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 2-lower-ocd-person-91f08063-02d6-4167-aa23-bbfb2289bbf1",
              "builtID": "vt-lower-chittenden-2",
              "externalID": "ocd-person/91f08063-02d6-4167-aa23-bbfb2289bbf1",
              "geometry": null
            },
            "contactInfo": {
              "email": "aarsenault@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/30952",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-19.pdf",
                "https://legislature.vermont.gov/people/single/2024/37362"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e334b770-4785-459a-89cd-1e16ef2444ad",
            "firstName": "Erin K.",
            "lastName": "Brady",
            "fullName": "Erin Brady",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Brady,%20Erin.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 2-lower-ocd-person-e334b770-4785-459a-89cd-1e16ef2444ad",
              "builtID": "vt-lower-chittenden-2",
              "externalID": "ocd-person/e334b770-4785-459a-89cd-1e16ef2444ad",
              "geometry": null
            },
            "contactInfo": {
              "email": "ebrady@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34740",
                "https://legislature.vermont.gov/people/single/2022/34740",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-2.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/34740"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 2-lower-ocd-person-91f08063-02d6-4167-aa23-bbfb2289bbf1": 0,
          "SLDL-VT-Chittenden 2-lower-ocd-person-e334b770-4785-459a-89cd-1e16ef2444ad": 1
        }
      },
      "Washington 1": {
        "members": [
          {
            "API_ID": "ocd-person/5321cd58-caae-4d94-8ba0-f1df47d4e75b",
            "firstName": "Anne de la Blanchetai",
            "lastName": "Donahue",
            "fullName": "Anne Donahue",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Donahue.Anne.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington 1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Washington 1-lower-ocd-person-5321cd58-caae-4d94-8ba0-f1df47d4e75b",
              "builtID": "vt-lower-washington-1",
              "externalID": "ocd-person/5321cd58-caae-4d94-8ba0-f1df47d4e75b",
              "geometry": null
            },
            "contactInfo": {
              "email": "adonahue@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/14557",
                "https://legislature.vermont.gov/Documents/SmallMaps/WAS-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Washington-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/14557"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/723a6dd0-9f92-4fdf-b506-f6947a3ccfe7",
            "firstName": "Kenneth W.",
            "lastName": "Goslant",
            "fullName": "Kenneth Goslant",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Goslant.Kenneth.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington 1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Washington 1-lower-ocd-person-723a6dd0-9f92-4fdf-b506-f6947a3ccfe7",
              "builtID": "vt-lower-washington-1",
              "externalID": "ocd-person/723a6dd0-9f92-4fdf-b506-f6947a3ccfe7",
              "geometry": null
            },
            "contactInfo": {
              "email": "kgoslant@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30968",
                "https://legislature.vermont.gov/people/single/2022/30968",
                "https://legislature.vermont.gov/Documents/SmallMaps/WAS-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/30968"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Washington 1-lower-ocd-person-5321cd58-caae-4d94-8ba0-f1df47d4e75b": 0,
          "SLDL-VT-Washington 1-lower-ocd-person-723a6dd0-9f92-4fdf-b506-f6947a3ccfe7": 1
        }
      },
      "Rutland 2": {
        "members": [
          {
            "API_ID": "ocd-person/8fa0db7d-01c5-483f-9e25-e06aa0236616",
            "firstName": "Arthur J.L.",
            "lastName": "Peterson",
            "fullName": "Art Peterson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Peterson,Arthur.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Rutland 2-lower-ocd-person-8fa0db7d-01c5-483f-9e25-e06aa0236616",
              "builtID": "vt-lower-rutland-2",
              "externalID": "ocd-person/8fa0db7d-01c5-483f-9e25-e06aa0236616",
              "geometry": null
            },
            "contactInfo": {
              "email": "apeterson@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34707",
                "https://legislature.vermont.gov/people/single/2022/34707",
                "https://legislature.vermont.gov/Documents/SmallMaps/RUT-2.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Rutland-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/34707"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/68d53f5e-f99c-4167-9dff-3c802fc6e857",
            "firstName": "Thomas",
            "lastName": "Burditt",
            "fullName": "Tom Burditt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/TBurditt.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Rutland 2-lower-ocd-person-68d53f5e-f99c-4167-9dff-3c802fc6e857",
              "builtID": "vt-lower-rutland-2",
              "externalID": "ocd-person/68d53f5e-f99c-4167-9dff-3c802fc6e857",
              "geometry": null
            },
            "contactInfo": {
              "email": "tburditt@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/15771",
                "https://legislature.vermont.gov/people/single/2022/15771",
                "https://legislature.vermont.gov/Documents/SmallMaps/RUT-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/15771"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Rutland 2-lower-ocd-person-8fa0db7d-01c5-483f-9e25-e06aa0236616": 0,
          "SLDL-VT-Rutland 2-lower-ocd-person-68d53f5e-f99c-4167-9dff-3c802fc6e857": 1
        }
      },
      "Franklin 1": {
        "members": [
          {
            "API_ID": "ocd-person/62781794-15d5-4362-82db-d6542a0e2f75",
            "firstName": "Ashley",
            "lastName": "Bartley",
            "fullName": "Ashley Bartley",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Bartley,Ashley.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Franklin 1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Franklin 1-lower-ocd-person-62781794-15d5-4362-82db-d6542a0e2f75",
              "builtID": "vt-lower-franklin-1",
              "externalID": "ocd-person/62781794-15d5-4362-82db-d6542a0e2f75",
              "geometry": null
            },
            "contactInfo": {
              "email": "abartley@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Franklin-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/37389"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b910e00e-ce75-426a-a0cc-29948edfce5d",
            "firstName": "Carolyn Whitney",
            "lastName": "Branagan",
            "fullName": "Carolyn Branagan",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/assets/Documents/Legislators/CBranagan.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Franklin 1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Franklin 1-lower-ocd-person-b910e00e-ce75-426a-a0cc-29948edfce5d",
              "builtID": "vt-lower-franklin-1",
              "externalID": "ocd-person/b910e00e-ce75-426a-a0cc-29948edfce5d",
              "geometry": null
            },
            "contactInfo": {
              "email": "cbranagan@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2018.1/27151",
                "https://www.leg.state.vt.us/legdir/LegDirMain.cfm",
                "https://legislature.vermont.gov/people/single/2024/37388"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Franklin 1-lower-ocd-person-62781794-15d5-4362-82db-d6542a0e2f75": 0,
          "SLDL-VT-Franklin 1-lower-ocd-person-b910e00e-ce75-426a-a0cc-29948edfce5d": 1
        }
      },
      "Lamoille Washington": {
        "members": [
          {
            "API_ID": "ocd-person/419b4d42-7a60-4ced-b361-1f344263a856",
            "firstName": "Avram",
            "lastName": "Patt",
            "fullName": "Avram Patt",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Patt.Avram.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Lamoille Washington",
              "chamber": "lower",
              "hashID": "SLDL-VT-Lamoille Washington-lower-ocd-person-419b4d42-7a60-4ced-b361-1f344263a856",
              "builtID": "vt-lower-lamoille-washington",
              "externalID": "ocd-person/419b4d42-7a60-4ced-b361-1f344263a856",
              "geometry": null
            },
            "contactInfo": {
              "email": "apatt@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30962",
                "https://legislature.vermont.gov/people/single/2022/30962",
                "https://legislature.vermont.gov/Documents/SmallMaps/LAM-WAS.pdf",
                "https://legislature.vermont.gov/people/single/2024/30962"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4d7ff594-22ad-4a37-b2ed-2190c6be9e54",
            "firstName": "Saudia",
            "lastName": "Lamont",
            "fullName": "Saudia Lamont",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/LaMont,Saudia.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Lamoille Washington",
              "chamber": "lower",
              "hashID": "SLDL-VT-Lamoille Washington-lower-ocd-person-4d7ff594-22ad-4a37-b2ed-2190c6be9e54",
              "builtID": "vt-lower-lamoille-washington",
              "externalID": "ocd-person/4d7ff594-22ad-4a37-b2ed-2190c6be9e54",
              "geometry": null
            },
            "contactInfo": {
              "email": "slamont@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Lamoille-Washington.pdf",
                "https://legislature.vermont.gov/people/single/2024/37383"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Lamoille Washington-lower-ocd-person-419b4d42-7a60-4ced-b361-1f344263a856": 0,
          "SLDL-VT-Lamoille Washington-lower-ocd-person-4d7ff594-22ad-4a37-b2ed-2190c6be9e54": 1
        }
      },
      "Chittenden 14": {
        "members": [
          {
            "API_ID": "ocd-person/26b89cd1-55b7-49ef-b47b-d3b21ed81635",
            "firstName": "Barbara",
            "lastName": "Rachelson",
            "fullName": "Barbara Rachelson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Rachelson.Barb.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 14",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 14-lower-ocd-person-26b89cd1-55b7-49ef-b47b-d3b21ed81635",
              "builtID": "vt-lower-chittenden-14",
              "externalID": "ocd-person/26b89cd1-55b7-49ef-b47b-d3b21ed81635",
              "geometry": null
            },
            "contactInfo": {
              "email": "brachelson@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/20377",
                "https://legislature.vermont.gov/people/single/2022/20377",
                "https://legislature.vermont.gov/people/single/2022/20377",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-6-6.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-14.pdf",
                "https://legislature.vermont.gov/people/single/2024/20377"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/eda9290c-b27b-4b39-bfc2-c760a63506ef",
            "firstName": "Mary-Katherine Abdel-Ghany",
            "lastName": "Stone",
            "fullName": "Mary-Katherine Stone",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Stone,Mary-Katherine.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 14",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 14-lower-ocd-person-eda9290c-b27b-4b39-bfc2-c760a63506ef",
              "builtID": "vt-lower-chittenden-14",
              "externalID": "ocd-person/eda9290c-b27b-4b39-bfc2-c760a63506ef",
              "geometry": null
            },
            "contactInfo": {
              "email": "mstone@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-14.pdf",
                "https://legislature.vermont.gov/people/single/2024/37395"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 14-lower-ocd-person-26b89cd1-55b7-49ef-b47b-d3b21ed81635": 0,
          "SLDL-VT-Chittenden 14-lower-ocd-person-eda9290c-b27b-4b39-bfc2-c760a63506ef": 1
        }
      },
      "Caledonia 1": {
        "members": [
          {
            "API_ID": "ocd-person/7970691b-e92f-4e18-8ed3-7414af2a21c0",
            "firstName": "Bobby",
            "lastName": "Farlice-Rubio",
            "fullName": "Bobby Farlice-Rubio",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Farlice-Rubio,Bobby.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Caledonia 1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Caledonia 1-lower-ocd-person-7970691b-e92f-4e18-8ed3-7414af2a21c0",
              "builtID": "vt-lower-caledonia-1",
              "externalID": "ocd-person/7970691b-e92f-4e18-8ed3-7414af2a21c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "bfarlice-rubio@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Caledonia-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/37359"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Caledonia 1-lower-ocd-person-7970691b-e92f-4e18-8ed3-7414af2a21c0": 0
        }
      },
      "Chittenden 15": {
        "members": [
          {
            "API_ID": "ocd-person/35504508-c008-4ecd-869f-6d8e33a4ad56",
            "firstName": "Brian",
            "lastName": "Cina",
            "fullName": "Brian Cina",
            "gender": "Male",
            "party": "democratic/progressive",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Cina.Brian.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 15",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 15-lower-ocd-person-35504508-c008-4ecd-869f-6d8e33a4ad56",
              "builtID": "vt-lower-chittenden-15",
              "externalID": "ocd-person/35504508-c008-4ecd-869f-6d8e33a4ad56",
              "geometry": null
            },
            "contactInfo": {
              "email": "bcina@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/27162",
                "https://legislature.vermont.gov/people/single/2022/27162",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-6-4.pdf",
                "https://legislature.vermont.gov/people/single/2024/27162"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4da3dea2-48bb-4162-a1a5-ff0525f7cbf6",
            "firstName": "Troy",
            "lastName": "Headrick",
            "fullName": "Troy Headrick",
            "gender": "Male",
            "party": "democratic/progressive",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Headrick,Troy.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 15",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 15-lower-ocd-person-4da3dea2-48bb-4162-a1a5-ff0525f7cbf6",
              "builtID": "vt-lower-chittenden-15",
              "externalID": "ocd-person/4da3dea2-48bb-4162-a1a5-ff0525f7cbf6",
              "geometry": null
            },
            "contactInfo": {
              "email": "theadrick@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-15.pdf",
                "https://legislature.vermont.gov/people/single/2024/37392"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 15-lower-ocd-person-35504508-c008-4ecd-869f-6d8e33a4ad56": 0,
          "SLDL-VT-Chittenden 15-lower-ocd-person-4da3dea2-48bb-4162-a1a5-ff0525f7cbf6": 1
        }
      },
      "Chittenden 11": {
        "members": [
          {
            "API_ID": "ocd-person/d44ada7b-9678-4a17-a66c-aa658b03bf38",
            "firstName": "Brian",
            "lastName": "Minier",
            "fullName": "Brian Minier",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Minier,%20Brian.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 11",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 11-lower-ocd-person-d44ada7b-9678-4a17-a66c-aa658b03bf38",
              "builtID": "vt-lower-chittenden-11",
              "externalID": "ocd-person/d44ada7b-9678-4a17-a66c-aa658b03bf38",
              "geometry": null
            },
            "contactInfo": {
              "email": "bminier@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-11.pdf",
                "https://legislature.vermont.gov/people/single/2024/37396"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 11-lower-ocd-person-d44ada7b-9678-4a17-a66c-aa658b03bf38": 0
        }
      },
      "Orleans 1": {
        "members": [
          {
            "API_ID": "ocd-person/a118c779-cb90-4746-8930-cfba85045430",
            "firstName": "Brian",
            "lastName": "Smith",
            "fullName": "Brian Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Smith,Brian.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Orleans 1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Orleans 1-lower-ocd-person-a118c779-cb90-4746-8930-cfba85045430",
              "builtID": "vt-lower-orleans-1",
              "externalID": "ocd-person/a118c779-cb90-4746-8930-cfba85045430",
              "geometry": null
            },
            "contactInfo": {
              "email": "bsmith@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/27176",
                "https://legislature.vermont.gov/people/single/2022/27176",
                "https://legislature.vermont.gov/Documents/SmallMaps/ORL-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Orleans-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/27176"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Orleans 1-lower-ocd-person-a118c779-cb90-4746-8930-cfba85045430": 0
        }
      },
      "Addison 4": {
        "members": [
          {
            "API_ID": "ocd-person/9c460746-5296-40dc-bad6-273f38574c1a",
            "firstName": "Caleb",
            "lastName": "Elder",
            "fullName": "Caleb Elder",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Elder.Caleb.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Addison 4",
              "chamber": "lower",
              "hashID": "SLDL-VT-Addison 4-lower-ocd-person-9c460746-5296-40dc-bad6-273f38574c1a",
              "builtID": "vt-lower-addison-4",
              "externalID": "ocd-person/9c460746-5296-40dc-bad6-273f38574c1a",
              "geometry": null
            },
            "contactInfo": {
              "email": "celder@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30940",
                "https://legislature.vermont.gov/people/single/2022/30940",
                "https://legislature.vermont.gov/Documents/SmallMaps/ADD-4.pdf",
                "https://legislature.vermont.gov/people/single/2024/30940"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1f501dde-37ba-49b3-ac83-0c77102ed8c3",
            "firstName": "Mari K.",
            "lastName": "Cordes",
            "fullName": "Mari Cordes",
            "gender": "Female",
            "party": "democratic/progressive",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Cordes.Mari.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Addison 4",
              "chamber": "lower",
              "hashID": "SLDL-VT-Addison 4-lower-ocd-person-1f501dde-37ba-49b3-ac83-0c77102ed8c3",
              "builtID": "vt-lower-addison-4",
              "externalID": "ocd-person/1f501dde-37ba-49b3-ac83-0c77102ed8c3",
              "geometry": null
            },
            "contactInfo": {
              "email": "mcordes@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30939",
                "https://legislature.vermont.gov/people/single/2022/30939",
                "https://legislature.vermont.gov/Documents/SmallMaps/ADD-4.pdf",
                "https://legislature.vermont.gov/people/single/2024/30939"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Addison 4-lower-ocd-person-9c460746-5296-40dc-bad6-273f38574c1a": 0,
          "SLDL-VT-Addison 4-lower-ocd-person-1f501dde-37ba-49b3-ac83-0c77102ed8c3": 1
        }
      },
      "Orange 1": {
        "members": [
          {
            "API_ID": "ocd-person/8e48ba22-bc5e-4986-b335-9a621a97e6b5",
            "firstName": "Carl Caverly",
            "lastName": "Demrow",
            "fullName": "Carl Demrow",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Demrow.Carl.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Orange 1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Orange 1-lower-ocd-person-8e48ba22-bc5e-4986-b335-9a621a97e6b5",
              "builtID": "vt-lower-orange-1",
              "externalID": "ocd-person/8e48ba22-bc5e-4986-b335-9a621a97e6b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "cdemrow@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30963",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Orange-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/37404"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Orange 1-lower-ocd-person-8e48ba22-bc5e-4986-b335-9a621a97e6b5": 0
        }
      },
      "Chittenden 18": {
        "members": [
          {
            "API_ID": "ocd-person/e9036574-9eac-4f0c-9bd2-1b425e96bb8c",
            "firstName": "Carol",
            "lastName": "Ode",
            "fullName": "Carol Ode",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Ode,Carol.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 18",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 18-lower-ocd-person-e9036574-9eac-4f0c-9bd2-1b425e96bb8c",
              "builtID": "vt-lower-chittenden-18",
              "externalID": "ocd-person/e9036574-9eac-4f0c-9bd2-1b425e96bb8c",
              "geometry": null
            },
            "contactInfo": {
              "email": "code@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/27161",
                "https://legislature.vermont.gov/people/single/2022/27161",
                "https://legislature.vermont.gov/people/single/2022/27161",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-6-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-18.pdf",
                "https://legislature.vermont.gov/people/single/2024/27161"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/dec4c236-1cd1-465f-969c-0bb4b8a04992",
            "firstName": "Robert",
            "lastName": "Hooper",
            "fullName": "Bob Hooper",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Hooper.Robert.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 18",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 18-lower-ocd-person-dec4c236-1cd1-465f-969c-0bb4b8a04992",
              "builtID": "vt-lower-chittenden-18",
              "externalID": "ocd-person/dec4c236-1cd1-465f-969c-0bb4b8a04992",
              "geometry": null
            },
            "contactInfo": {
              "email": "rhooper@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30948",
                "https://legislature.vermont.gov/people/single/2022/30948",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-6-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/30948"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 18-lower-ocd-person-e9036574-9eac-4f0c-9bd2-1b425e96bb8c": 0,
          "SLDL-VT-Chittenden 18-lower-ocd-person-dec4c236-1cd1-465f-969c-0bb4b8a04992": 1
        }
      },
      "Franklin 8": {
        "members": [
          {
            "API_ID": "ocd-person/704de547-43b6-4dfa-bf95-c4137f263e00",
            "firstName": "Casey",
            "lastName": "Toof",
            "fullName": "Casey Toof",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Toof,Casey.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Franklin 8",
              "chamber": "lower",
              "hashID": "SLDL-VT-Franklin 8-lower-ocd-person-704de547-43b6-4dfa-bf95-c4137f263e00",
              "builtID": "vt-lower-franklin-8",
              "externalID": "ocd-person/704de547-43b6-4dfa-bf95-c4137f263e00",
              "geometry": null
            },
            "contactInfo": {
              "email": "ctoof@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30955",
                "https://legislature.vermont.gov/people/single/2022/30955",
                "https://legislature.vermont.gov/Documents/SmallMaps/FRA-3-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Franklin-8.pdf",
                "https://legislature.vermont.gov/people/single/2024/30955"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Franklin 8-lower-ocd-person-704de547-43b6-4dfa-bf95-c4137f263e00": 0
        }
      },
      "Rutland 8": {
        "members": [
          {
            "API_ID": "ocd-person/6783c18b-dfdc-4b50-a27a-6457ba1a811c",
            "firstName": "Charles H.",
            "lastName": "Shaw",
            "fullName": "Butch Shaw",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Shaw.Butch.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland 8",
              "chamber": "lower",
              "hashID": "SLDL-VT-Rutland 8-lower-ocd-person-6783c18b-dfdc-4b50-a27a-6457ba1a811c",
              "builtID": "vt-lower-rutland-8",
              "externalID": "ocd-person/6783c18b-dfdc-4b50-a27a-6457ba1a811c",
              "geometry": null
            },
            "contactInfo": {
              "email": "butchshaws@aol.com",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/15553",
                "https://legislature.vermont.gov/people/single/2022/15553",
                "https://legislature.vermont.gov/Documents/SmallMaps/RUT-6.pdf",
                "https://legislature.vermont.gov/people/single/2024/15553"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Rutland 8-lower-ocd-person-6783c18b-dfdc-4b50-a27a-6457ba1a811c": 0
        }
      },
      "Caledonia 3": {
        "members": [
          {
            "API_ID": "ocd-person/3dca13eb-6f59-49b1-b0dc-2202d345c74d",
            "firstName": "Charles",
            "lastName": "Wilson",
            "fullName": "Charles Wilson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Wilson,Charles.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Caledonia 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Caledonia 3-lower-ocd-person-3dca13eb-6f59-49b1-b0dc-2202d345c74d",
              "builtID": "vt-lower-caledonia-3",
              "externalID": "ocd-person/3dca13eb-6f59-49b1-b0dc-2202d345c74d",
              "geometry": null
            },
            "contactInfo": {
              "email": "cwilson@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Caledonia-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/37361"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/606c8d52-5ba5-45fa-afef-7b21cd2428cf",
            "firstName": "Dennis",
            "lastName": "LaBounty",
            "fullName": "Dennis LaBounty",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/LaBounty,Dennis.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Caledonia 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Caledonia 3-lower-ocd-person-606c8d52-5ba5-45fa-afef-7b21cd2428cf",
              "builtID": "vt-lower-caledonia-3",
              "externalID": "ocd-person/606c8d52-5ba5-45fa-afef-7b21cd2428cf",
              "geometry": null
            },
            "contactInfo": {
              "email": "dlabounty@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Caledonia-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/37360"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Caledonia 3-lower-ocd-person-3dca13eb-6f59-49b1-b0dc-2202d345c74d": 0,
          "SLDL-VT-Caledonia 3-lower-ocd-person-606c8d52-5ba5-45fa-afef-7b21cd2428cf": 1
        }
      },
      "Chittenden 5": {
        "members": [
          {
            "API_ID": "ocd-person/0f7b93b3-9e96-4e44-8bb4-9c60e4be4b72",
            "firstName": "Chea Waters",
            "lastName": "Evans",
            "fullName": "Chea Evans",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Waters%20Evans,Chea.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 5",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 5-lower-ocd-person-0f7b93b3-9e96-4e44-8bb4-9c60e4be4b72",
              "builtID": "vt-lower-chittenden-5",
              "externalID": "ocd-person/0f7b93b3-9e96-4e44-8bb4-9c60e4be4b72",
              "geometry": null
            },
            "contactInfo": {
              "email": "cevans@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-5.pdf",
                "https://legislature.vermont.gov/people/single/2024/37367"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 5-lower-ocd-person-0f7b93b3-9e96-4e44-8bb4-9c60e4be4b72": 0
        }
      },
      "Chittenden Franklin": {
        "members": [
          {
            "API_ID": "ocd-person/8a7c0a35-c3bc-4b94-a1c3-c726c8ecaaae",
            "firstName": "Christopher",
            "lastName": "Taylor",
            "fullName": "Chris Taylor",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Taylor,Chris.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden Franklin",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden Franklin-lower-ocd-person-8a7c0a35-c3bc-4b94-a1c3-c726c8ecaaae",
              "builtID": "vt-lower-chittenden-franklin",
              "externalID": "ocd-person/8a7c0a35-c3bc-4b94-a1c3-c726c8ecaaae",
              "geometry": null
            },
            "contactInfo": {
              "email": "cataylor@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-Franklin.pdf",
                "https://legislature.vermont.gov/people/single/2024/37400"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1526326f-c2a8-4103-af27-1805f9a8e32a",
            "firstName": "Christopher P.",
            "lastName": "Mattos",
            "fullName": "Chris Mattos",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/CMattos.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden Franklin",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden Franklin-lower-ocd-person-1526326f-c2a8-4103-af27-1805f9a8e32a",
              "builtID": "vt-lower-chittenden-franklin",
              "externalID": "ocd-person/1526326f-c2a8-4103-af27-1805f9a8e32a",
              "geometry": null
            },
            "contactInfo": {
              "email": "cmattos@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/29153",
                "https://legislature.vermont.gov/people/single/2022/29153",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-10.pdf",
                "https://legislature.vermont.gov/people/single/2024/29153"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden Franklin-lower-ocd-person-8a7c0a35-c3bc-4b94-a1c3-c726c8ecaaae": 0,
          "SLDL-VT-Chittenden Franklin-lower-ocd-person-1526326f-c2a8-4103-af27-1805f9a8e32a": 1
        }
      },
      "Washington 4": {
        "members": [
          {
            "API_ID": "ocd-person/526f33a9-fcbe-4963-ae6c-abccde1fe4da",
            "firstName": "Conor",
            "lastName": "Casey",
            "fullName": "Conor Casey",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Casey,Conor.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington 4",
              "chamber": "lower",
              "hashID": "SLDL-VT-Washington 4-lower-ocd-person-526f33a9-fcbe-4963-ae6c-abccde1fe4da",
              "builtID": "vt-lower-washington-4",
              "externalID": "ocd-person/526f33a9-fcbe-4963-ae6c-abccde1fe4da",
              "geometry": null
            },
            "contactInfo": {
              "email": "ccasey@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Washington-4.pdf",
                "https://legislature.vermont.gov/people/single/2024/37376"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/2f2db82f-4812-4a06-8dc0-1e03877aabf9",
            "firstName": "Kate",
            "lastName": "McCann",
            "fullName": "Kate McCann",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/McCann,Kate.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington 4",
              "chamber": "lower",
              "hashID": "SLDL-VT-Washington 4-lower-ocd-person-2f2db82f-4812-4a06-8dc0-1e03877aabf9",
              "builtID": "vt-lower-washington-4",
              "externalID": "ocd-person/2f2db82f-4812-4a06-8dc0-1e03877aabf9",
              "geometry": null
            },
            "contactInfo": {
              "email": "kmccann@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Washington-4.pdf",
                "https://legislature.vermont.gov/people/single/2024/37375"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Washington 4-lower-ocd-person-526f33a9-fcbe-4963-ae6c-abccde1fe4da": 0,
          "SLDL-VT-Washington 4-lower-ocd-person-2f2db82f-4812-4a06-8dc0-1e03877aabf9": 1
        }
      },
      "Chittenden 20": {
        "members": [
          {
            "API_ID": "ocd-person/e4fb0d53-dde1-4035-a648-b2bf19eb4919",
            "firstName": "Curt",
            "lastName": "Taylor",
            "fullName": "Curt Taylor",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Taylor.Curt.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 20",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 20-lower-ocd-person-e4fb0d53-dde1-4035-a648-b2bf19eb4919",
              "builtID": "vt-lower-chittenden-20",
              "externalID": "ocd-person/e4fb0d53-dde1-4035-a648-b2bf19eb4919",
              "geometry": null
            },
            "contactInfo": {
              "email": "ctaylor@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/27167",
                "https://legislature.vermont.gov/people/single/2022/27167",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-9-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/27167"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e6fefc15-f4c0-449f-a34a-9116f656bfb8",
            "firstName": "Seth",
            "lastName": "Chase",
            "fullName": "Seth Chase",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Chase.Seth.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 20",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 20-lower-ocd-person-e6fefc15-f4c0-449f-a34a-9116f656bfb8",
              "builtID": "vt-lower-chittenden-20",
              "externalID": "ocd-person/e6fefc15-f4c0-449f-a34a-9116f656bfb8",
              "geometry": null
            },
            "contactInfo": {
              "email": "schase@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30951",
                "https://legislature.vermont.gov/people/single/2022/30951",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-9-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/30951"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 20-lower-ocd-person-e4fb0d53-dde1-4035-a648-b2bf19eb4919": 0,
          "SLDL-VT-Chittenden 20-lower-ocd-person-e6fefc15-f4c0-449f-a34a-9116f656bfb8": 1
        }
      },
      "Chittenden 21": {
        "members": [
          {
            "API_ID": "ocd-person/c7019f00-84e8-405f-9efe-055112cbb4c4",
            "firstName": "Daisy",
            "lastName": "Berbeco",
            "fullName": "Daisy Berbeco",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Berbeco,Daisy.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 21",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 21-lower-ocd-person-c7019f00-84e8-405f-9efe-055112cbb4c4",
              "builtID": "vt-lower-chittenden-21",
              "externalID": "ocd-person/c7019f00-84e8-405f-9efe-055112cbb4c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "dberbeco@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-21.pdf",
                "https://legislature.vermont.gov/people/single/2024/37391"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/9b973817-7739-4b62-828c-e0b80e2f2548",
            "firstName": "Taylor",
            "lastName": "Small",
            "fullName": "Taylor Small",
            "gender": "Female",
            "party": "democratic/progressive",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Small,Taylor.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 21",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 21-lower-ocd-person-9b973817-7739-4b62-828c-e0b80e2f2548",
              "builtID": "vt-lower-chittenden-21",
              "externalID": "ocd-person/9b973817-7739-4b62-828c-e0b80e2f2548",
              "geometry": null
            },
            "contactInfo": {
              "email": "tsmall@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34700",
                "https://legislature.vermont.gov/people/single/2022/34700",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-6-7.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-21.pdf",
                "https://legislature.vermont.gov/people/single/2024/34700"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 21-lower-ocd-person-c7019f00-84e8-405f-9efe-055112cbb4c4": 0,
          "SLDL-VT-Chittenden 21-lower-ocd-person-9b973817-7739-4b62-828c-e0b80e2f2548": 1
        }
      },
      "Bennington 2": {
        "members": [
          {
            "API_ID": "ocd-person/ff4d67b1-bbdc-4bf8-a356-5b6ba5c9326c",
            "firstName": "Dane",
            "lastName": "Whitman",
            "fullName": "Dane Whitman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Whitman.Dane.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Bennington 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Bennington 2-lower-ocd-person-ff4d67b1-bbdc-4bf8-a356-5b6ba5c9326c",
              "builtID": "vt-lower-bennington-2",
              "externalID": "ocd-person/ff4d67b1-bbdc-4bf8-a356-5b6ba5c9326c",
              "geometry": null
            },
            "contactInfo": {
              "email": "dwhitman@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34713",
                "https://legislature.vermont.gov/people/single/2022/34713",
                "https://legislature.vermont.gov/Documents/SmallMaps/BEN-2-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Bennington-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/34713"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/bd42ebbc-3e03-4929-bbd8-f2fe5141d604",
            "firstName": "Timothy R.",
            "lastName": "Corcoran",
            "fullName": "Timothy Corcoran",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Corcoran,%20Timothy.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Bennington 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Bennington 2-lower-ocd-person-bd42ebbc-3e03-4929-bbd8-f2fe5141d604",
              "builtID": "vt-lower-bennington-2",
              "externalID": "ocd-person/bd42ebbc-3e03-4929-bbd8-f2fe5141d604",
              "geometry": null
            },
            "contactInfo": {
              "email": "tcorcoran@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/14546",
                "https://legislature.vermont.gov/Documents/SmallMaps/BEN-2-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Bennington-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/14546"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Bennington 2-lower-ocd-person-ff4d67b1-bbdc-4bf8-a356-5b6ba5c9326c": 0,
          "SLDL-VT-Bennington 2-lower-ocd-person-bd42ebbc-3e03-4929-bbd8-f2fe5141d604": 1
        }
      },
      "Lamoille 2": {
        "members": [
          {
            "API_ID": "ocd-person/95cd4184-c07f-48a5-993a-e924ad71184f",
            "firstName": "Daniel",
            "lastName": "Noyes",
            "fullName": "Dan Noyes",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Noyes.Daniel.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Lamoille 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Lamoille 2-lower-ocd-person-95cd4184-c07f-48a5-993a-e924ad71184f",
              "builtID": "vt-lower-lamoille-2",
              "externalID": "ocd-person/95cd4184-c07f-48a5-993a-e924ad71184f",
              "geometry": null
            },
            "contactInfo": {
              "email": "dnoyes@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/27171",
                "https://legislature.vermont.gov/people/single/2022/27171",
                "https://legislature.vermont.gov/Documents/SmallMaps/LAM-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/27171"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/2a7aefc2-bbcc-49a4-85b6-000f59f97c9e",
            "firstName": "Melanie",
            "lastName": "Carpenter",
            "fullName": "Melanie Carpenter",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Carpenter,Melanie.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Lamoille 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Lamoille 2-lower-ocd-person-2a7aefc2-bbcc-49a4-85b6-000f59f97c9e",
              "builtID": "vt-lower-lamoille-2",
              "externalID": "ocd-person/2a7aefc2-bbcc-49a4-85b6-000f59f97c9e",
              "geometry": null
            },
            "contactInfo": {
              "email": "mcarpenter@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Lamoille-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/38437"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Lamoille 2-lower-ocd-person-95cd4184-c07f-48a5-993a-e924ad71184f": 0,
          "SLDL-VT-Lamoille 2-lower-ocd-person-2a7aefc2-bbcc-49a4-85b6-000f59f97c9e": 1
        }
      },
      "Washington 2": {
        "members": [
          {
            "API_ID": "ocd-person/46a9d6df-bd07-4fa4-b6b9-b91c0fa37f94",
            "firstName": "Dara",
            "lastName": "Torre",
            "fullName": "Dara Torre",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Torre,Dana.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Washington 2-lower-ocd-person-46a9d6df-bd07-4fa4-b6b9-b91c0fa37f94",
              "builtID": "vt-lower-washington-2",
              "externalID": "ocd-person/46a9d6df-bd07-4fa4-b6b9-b91c0fa37f94",
              "geometry": null
            },
            "contactInfo": {
              "email": "dtorre@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Washington-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/37378"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d2eb927f-91d2-48e3-ba49-71cf02e69ed5",
            "firstName": "Katherine",
            "lastName": "Dolan",
            "fullName": "Kari Dolan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Dolan.Kari.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Washington 2-lower-ocd-person-d2eb927f-91d2-48e3-ba49-71cf02e69ed5",
              "builtID": "vt-lower-washington-2",
              "externalID": "ocd-person/d2eb927f-91d2-48e3-ba49-71cf02e69ed5",
              "geometry": null
            },
            "contactInfo": {
              "email": "kdolan@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30970",
                "https://legislature.vermont.gov/people/single/2022/30970",
                "https://legislature.vermont.gov/people/single/2024/30970"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Washington 2-lower-ocd-person-46a9d6df-bd07-4fa4-b6b9-b91c0fa37f94": 0,
          "SLDL-VT-Washington 2-lower-ocd-person-d2eb927f-91d2-48e3-ba49-71cf02e69ed5": 1
        }
      },
      "Orleans 3": {
        "members": [
          {
            "API_ID": "ocd-person/506baeb1-baed-4c6a-bf1e-b12f7faeac61",
            "firstName": "David",
            "lastName": "Templeman",
            "fullName": "Dave Templeman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Templeman,David.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Orleans 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Orleans 3-lower-ocd-person-506baeb1-baed-4c6a-bf1e-b12f7faeac61",
              "builtID": "vt-lower-orleans-3",
              "externalID": "ocd-person/506baeb1-baed-4c6a-bf1e-b12f7faeac61",
              "geometry": null
            },
            "contactInfo": {
              "email": "dtempleman@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Orleans-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/37381"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Orleans 3-lower-ocd-person-506baeb1-baed-4c6a-bf1e-b12f7faeac61": 0
        }
      },
      "Bennington 3": {
        "members": [
          {
            "API_ID": "ocd-person/500ffe66-07b0-4ebd-9084-d017872daa84",
            "firstName": "David K.",
            "lastName": "Durfee",
            "fullName": "David Durfee",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Durfee,David.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Bennington 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Bennington 3-lower-ocd-person-500ffe66-07b0-4ebd-9084-d017872daa84",
              "builtID": "vt-lower-bennington-3",
              "externalID": "ocd-person/500ffe66-07b0-4ebd-9084-d017872daa84",
              "geometry": null
            },
            "contactInfo": {
              "email": "ddurfee@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30944",
                "https://legislature.vermont.gov/people/single/2022/30944",
                "https://legislature.vermont.gov/people/single/2022/30944",
                "https://legislature.vermont.gov/Documents/SmallMaps/BEN-3.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Bennington-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/30944"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Bennington 3-lower-ocd-person-500ffe66-07b0-4ebd-9084-d017872daa84": 0
        }
      },
      "Addison 3": {
        "members": [
          {
            "API_ID": "ocd-person/a072e1d3-94fe-4327-b3b0-14f033457e0a",
            "firstName": "Diane M.",
            "lastName": "Lanpher",
            "fullName": "Diane Lanpher",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Lanpher.Diane.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Addison 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Addison 3-lower-ocd-person-a072e1d3-94fe-4327-b3b0-14f033457e0a",
              "builtID": "vt-lower-addison-3",
              "externalID": "ocd-person/a072e1d3-94fe-4327-b3b0-14f033457e0a",
              "geometry": null
            },
            "contactInfo": {
              "email": "dlanpher@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14667",
                "https://legislature.vermont.gov/people/single/2022/14667",
                "https://legislature.vermont.gov/Documents/SmallMaps/ADD-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/14667"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e3e3144c-e166-4b0f-a7e7-e3e24cd40039",
            "firstName": "Matthew",
            "lastName": "Birong",
            "fullName": "Matt Birong",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Birong.Matt.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Addison 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Addison 3-lower-ocd-person-e3e3144c-e166-4b0f-a7e7-e3e24cd40039",
              "builtID": "vt-lower-addison-3",
              "externalID": "ocd-person/e3e3144c-e166-4b0f-a7e7-e3e24cd40039",
              "geometry": null
            },
            "contactInfo": {
              "email": "mbirong@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30938",
                "https://legislature.vermont.gov/people/single/2022/30938",
                "https://legislature.vermont.gov/Documents/SmallMaps/ADD-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/30938"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Addison 3-lower-ocd-person-a072e1d3-94fe-4327-b3b0-14f033457e0a": 0,
          "SLDL-VT-Addison 3-lower-ocd-person-e3e3144c-e166-4b0f-a7e7-e3e24cd40039": 1
        }
      },
      "Chittenden 3": {
        "members": [
          {
            "API_ID": "ocd-person/2f46ddc5-1564-4215-b201-cc3c1521f4c4",
            "firstName": "Edye",
            "lastName": "Graning",
            "fullName": "Edye Graning",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Graning,Edye.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 3-lower-ocd-person-2f46ddc5-1564-4215-b201-cc3c1521f4c4",
              "builtID": "vt-lower-chittenden-3",
              "externalID": "ocd-person/2f46ddc5-1564-4215-b201-cc3c1521f4c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "egraning@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/37363"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/7e019459-6d2e-4c45-8092-72cb8334bf25",
            "firstName": "Trevor J.",
            "lastName": "Squirrell",
            "fullName": "Trevor Squirrell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Squirrell.Trevor.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 3-lower-ocd-person-7e019459-6d2e-4c45-8092-72cb8334bf25",
              "builtID": "vt-lower-chittenden-3",
              "externalID": "ocd-person/7e019459-6d2e-4c45-8092-72cb8334bf25",
              "geometry": null
            },
            "contactInfo": {
              "email": "tsquirrell@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/27159",
                "https://legislature.vermont.gov/people/single/2022/27159",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/27159"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 3-lower-ocd-person-2f46ddc5-1564-4215-b201-cc3c1521f4c4": 0,
          "SLDL-VT-Chittenden 3-lower-ocd-person-7e019459-6d2e-4c45-8092-72cb8334bf25": 1
        }
      },
      "Franklin 2": {
        "members": [
          {
            "API_ID": "ocd-person/d0698c2f-d5fb-475b-abc9-042971d26a5f",
            "firstName": "Eileen G.",
            "lastName": "Dickinson",
            "fullName": "Lynn Dickinson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/EDickinson.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Franklin 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Franklin 2-lower-ocd-person-d0698c2f-d5fb-475b-abc9-042971d26a5f",
              "builtID": "vt-lower-franklin-2",
              "externalID": "ocd-person/d0698c2f-d5fb-475b-abc9-042971d26a5f",
              "geometry": null
            },
            "contactInfo": {
              "email": "edickinson@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14677",
                "https://legislature.vermont.gov/people/single/2022/14677",
                "https://legislature.vermont.gov/people/single/2022/14677",
                "https://legislature.vermont.gov/Documents/SmallMaps/FRA-3-2.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Franklin-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/14677"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Franklin 2-lower-ocd-person-d0698c2f-d5fb-475b-abc9-042971d26a5f": 0
        }
      },
      "Washington 5": {
        "members": [
          {
            "API_ID": "ocd-person/dda44e05-7cb0-49e7-ac32-49963b4aaa7c",
            "firstName": "Elanor Abrams",
            "lastName": "Chapin",
            "fullName": "Ela Chapin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Chapin,Ela.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington 5",
              "chamber": "lower",
              "hashID": "SLDL-VT-Washington 5-lower-ocd-person-dda44e05-7cb0-49e7-ac32-49963b4aaa7c",
              "builtID": "vt-lower-washington-5",
              "externalID": "ocd-person/dda44e05-7cb0-49e7-ac32-49963b4aaa7c",
              "geometry": null
            },
            "contactInfo": {
              "email": "echapin@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Washington-5.pdf",
                "https://legislature.vermont.gov/people/single/2024/37374"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Washington 5-lower-ocd-person-dda44e05-7cb0-49e7-ac32-49963b4aaa7c": 0
        }
      },
      "Windsor 1": {
        "members": [
          {
            "API_ID": "ocd-person/e7232e6f-4a66-45dc-9a4b-ee9d8b763544",
            "firstName": "Elizabeth Leprestre Cornell",
            "lastName": "Burrows",
            "fullName": "Elizabeth Burrows",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Burrows,%20Elizabeth.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor 1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windsor 1-lower-ocd-person-e7232e6f-4a66-45dc-9a4b-ee9d8b763544",
              "builtID": "vt-lower-windsor-1",
              "externalID": "ocd-person/e7232e6f-4a66-45dc-9a4b-ee9d8b763544",
              "geometry": null
            },
            "contactInfo": {
              "email": "eburrows@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34722",
                "https://legislature.vermont.gov/people/single/2022/34722",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDR-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windsor-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/34722"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d12670e1-fd0b-4e78-a471-ec5d4ddfafb8",
            "firstName": "John L.",
            "lastName": "Bartholomew",
            "fullName": "John Bartholomew",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Bartholomew.John.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor 1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windsor 1-lower-ocd-person-d12670e1-fd0b-4e78-a471-ec5d4ddfafb8",
              "builtID": "vt-lower-windsor-1",
              "externalID": "ocd-person/d12670e1-fd0b-4e78-a471-ec5d4ddfafb8",
              "geometry": null
            },
            "contactInfo": {
              "email": "jbartholomew@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/15769",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDR-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windsor-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/15769"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windsor 1-lower-ocd-person-e7232e6f-4a66-45dc-9a4b-ee9d8b763544": 0,
          "SLDL-VT-Windsor 1-lower-ocd-person-d12670e1-fd0b-4e78-a471-ec5d4ddfafb8": 1
        }
      },
      "Windham 7": {
        "members": [
          {
            "API_ID": "ocd-person/cb033790-7578-41c3-87b6-c9794b289c77",
            "firstName": "Emilie",
            "lastName": "Kornheiser",
            "fullName": "Emilie Kornheiser",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/EKornheiser.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windham 7",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windham 7-lower-ocd-person-cb033790-7578-41c3-87b6-c9794b289c77",
              "builtID": "vt-lower-windham-7",
              "externalID": "ocd-person/cb033790-7578-41c3-87b6-c9794b289c77",
              "geometry": null
            },
            "contactInfo": {
              "email": "ekornheiser@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30972",
                "https://legislature.vermont.gov/people/single/2022/30972",
                "https://legislature.vermont.gov/people/single/2022/30972",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDH-2-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windham-7.pdf",
                "https://legislature.vermont.gov/people/single/2024/30972"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windham 7-lower-ocd-person-cb033790-7578-41c3-87b6-c9794b289c77": 0
        }
      },
      "Chittenden 9": {
        "members": [
          {
            "API_ID": "ocd-person/0ab172d4-ecc7-46e4-a4f1-ee71429595bd",
            "firstName": "Emily",
            "lastName": "Krasnow",
            "fullName": "Emilie Krasnow",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Krasnow,Emilie.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 9",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 9-lower-ocd-person-0ab172d4-ecc7-46e4-a4f1-ee71429595bd",
              "builtID": "vt-lower-chittenden-9",
              "externalID": "ocd-person/0ab172d4-ecc7-46e4-a4f1-ee71429595bd",
              "geometry": null
            },
            "contactInfo": {
              "email": "ekrasnow@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-9.pdf",
                "https://legislature.vermont.gov/people/single/2024/37394"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 9-lower-ocd-person-0ab172d4-ecc7-46e4-a4f1-ee71429595bd": 0
        }
      },
      "Windham 5": {
        "members": [
          {
            "API_ID": "ocd-person/36bd1884-fd78-46b4-9ac3-318ca6358a67",
            "firstName": "Emily J.",
            "lastName": "Long",
            "fullName": "Emily Long",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Long,%20Emily.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windham 5",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windham 5-lower-ocd-person-36bd1884-fd78-46b4-9ac3-318ca6358a67",
              "builtID": "vt-lower-windham-5",
              "externalID": "ocd-person/36bd1884-fd78-46b4-9ac3-318ca6358a67",
              "geometry": null
            },
            "contactInfo": {
              "email": "elong@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/24012",
                "https://legislature.vermont.gov/people/single/2022/24012",
                "https://legislature.vermont.gov/people/single/2022/24012",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDH-5.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windham-5.pdf",
                "https://legislature.vermont.gov/people/single/2024/24012"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windham 5-lower-ocd-person-36bd1884-fd78-46b4-9ac3-318ca6358a67": 0
        }
      },
      "Chittenden 17": {
        "members": [
          {
            "API_ID": "ocd-person/01f630ce-e871-4f16-866e-d26433b45387",
            "firstName": "Emma",
            "lastName": "Mulvaney-Stanak",
            "fullName": "Emma Mulvaney-Stanak",
            "gender": "Female",
            "party": "democratic/progressive",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Mulvany-Stanak,%20Emma.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 17",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 17-lower-ocd-person-01f630ce-e871-4f16-866e-d26433b45387",
              "builtID": "vt-lower-chittenden-17",
              "externalID": "ocd-person/01f630ce-e871-4f16-866e-d26433b45387",
              "geometry": null
            },
            "contactInfo": {
              "email": "emulvaneystanak@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34701",
                "https://legislature.vermont.gov/people/single/2022/34701",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-6-2.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-17.pdf",
                "https://legislature.vermont.gov/people/single/2024/34701"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 17-lower-ocd-person-01f630ce-e871-4f16-866e-d26433b45387": 0
        }
      },
      "Rutland 5": {
        "members": [
          {
            "API_ID": "ocd-person/ed272e8b-3ef7-47ce-84c9-9163efac3734",
            "firstName": "Eric",
            "lastName": "Maguire",
            "fullName": "Eric Maguire",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Maguire,Eric.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland 5",
              "chamber": "lower",
              "hashID": "SLDL-VT-Rutland 5-lower-ocd-person-ed272e8b-3ef7-47ce-84c9-9163efac3734",
              "builtID": "vt-lower-rutland-5",
              "externalID": "ocd-person/ed272e8b-3ef7-47ce-84c9-9163efac3734",
              "geometry": null
            },
            "contactInfo": {
              "email": "emaguire@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Rutland-5.pdf",
                "https://legislature.vermont.gov/people/single/2024/37379"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Rutland 5-lower-ocd-person-ed272e8b-3ef7-47ce-84c9-9163efac3734": 0
        }
      },
      "Windsor 6": {
        "members": [
          {
            "API_ID": "ocd-person/62b30d56-d938-4882-b763-2c2495ae6fe5",
            "firstName": "Esme",
            "lastName": "Cole",
            "fullName": "Esme Cole",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Cole,Esme.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor 6",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windsor 6-lower-ocd-person-62b30d56-d938-4882-b763-2c2495ae6fe5",
              "builtID": "vt-lower-windsor-6",
              "externalID": "ocd-person/62b30d56-d938-4882-b763-2c2495ae6fe5",
              "geometry": null
            },
            "contactInfo": {
              "email": "ecole@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windsor-6.pdf",
                "https://legislature.vermont.gov/people/single/2024/37368"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/dfe297f7-6d88-48d5-8b81-f2605f791de0",
            "firstName": "Kevin B.",
            "lastName": "Christie",
            "fullName": "Kevin Christie",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/KChristie.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor 6",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windsor 6-lower-ocd-person-dfe297f7-6d88-48d5-8b81-f2605f791de0",
              "builtID": "vt-lower-windsor-6",
              "externalID": "ocd-person/dfe297f7-6d88-48d5-8b81-f2605f791de0",
              "geometry": null
            },
            "contactInfo": {
              "email": "kchristie@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/15774",
                "https://legislature.vermont.gov/people/single/2022/15774",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDR-4-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/15774"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windsor 6-lower-ocd-person-62b30d56-d938-4882-b763-2c2495ae6fe5": 0,
          "SLDL-VT-Windsor 6-lower-ocd-person-dfe297f7-6d88-48d5-8b81-f2605f791de0": 1
        }
      },
      "Washington Orange": {
        "members": [
          {
            "API_ID": "ocd-person/a7c7d0f6-8795-4f50-928b-15b6c3831ac4",
            "firstName": "Francis M.",
            "lastName": "McFaun",
            "fullName": "Topper McFaun",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/FMcfaun.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington Orange",
              "chamber": "lower",
              "hashID": "SLDL-VT-Washington Orange-lower-ocd-person-a7c7d0f6-8795-4f50-928b-15b6c3831ac4",
              "builtID": "vt-lower-washington-orange",
              "externalID": "ocd-person/a7c7d0f6-8795-4f50-928b-15b6c3831ac4",
              "geometry": null
            },
            "contactInfo": {
              "email": "fmcfaun@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14579",
                "https://legislature.vermont.gov/people/single/2022/14579",
                "https://legislature.vermont.gov/Documents/SmallMaps/WAS-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/14579"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/39c4dae9-aee7-46f6-858d-e1eece72d79e",
            "firstName": "Gina",
            "lastName": "Galfetti",
            "fullName": "Gina Galfetti",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Galfetti,Gina.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington Orange",
              "chamber": "lower",
              "hashID": "SLDL-VT-Washington Orange-lower-ocd-person-39c4dae9-aee7-46f6-858d-e1eece72d79e",
              "builtID": "vt-lower-washington-orange",
              "externalID": "ocd-person/39c4dae9-aee7-46f6-858d-e1eece72d79e",
              "geometry": null
            },
            "contactInfo": {
              "email": "ggalfetti@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Washington-Orange.pdf",
                "https://legislature.vermont.gov/people/single/2024/37372"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Washington Orange-lower-ocd-person-a7c7d0f6-8795-4f50-928b-15b6c3831ac4": 0,
          "SLDL-VT-Washington Orange-lower-ocd-person-39c4dae9-aee7-46f6-858d-e1eece72d79e": 1
        }
      },
      "Chittenden 13": {
        "members": [
          {
            "API_ID": "ocd-person/7b75911f-38d7-4da0-8be7-935e7ee9523c",
            "firstName": "Gabrielle",
            "lastName": "Stebbins",
            "fullName": "Gabrielle Stebbins",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Stebbins,%20Gabrielle.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 13",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 13-lower-ocd-person-7b75911f-38d7-4da0-8be7-935e7ee9523c",
              "builtID": "vt-lower-chittenden-13",
              "externalID": "ocd-person/7b75911f-38d7-4da0-8be7-935e7ee9523c",
              "geometry": null
            },
            "contactInfo": {
              "email": "gstebbins@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34705",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-6-5.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-13.pdf",
                "https://legislature.vermont.gov/people/single/2024/34705"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8210ead4-c6b3-4316-ab90-7564b9f23cdb",
            "firstName": "Tiffany",
            "lastName": "Bluemle",
            "fullName": "Tiff Bluemle",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Blumle.Tiff.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 13",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 13-lower-ocd-person-8210ead4-c6b3-4316-ab90-7564b9f23cdb",
              "builtID": "vt-lower-chittenden-13",
              "externalID": "ocd-person/8210ead4-c6b3-4316-ab90-7564b9f23cdb",
              "geometry": null
            },
            "contactInfo": {
              "email": "tbluemle@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34720",
                "https://legislature.vermont.gov/people/single/2022/34720",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-6-5.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-13.pdf",
                "https://legislature.vermont.gov/people/single/2024/34720"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 13-lower-ocd-person-7b75911f-38d7-4da0-8be7-935e7ee9523c": 0,
          "SLDL-VT-Chittenden 13-lower-ocd-person-8210ead4-c6b3-4316-ab90-7564b9f23cdb": 1
        }
      },
      "Windsor Windham": {
        "members": [
          {
            "API_ID": "ocd-person/0522f150-ee6f-4f9c-b1be-52ef20850133",
            "firstName": "Heather Collie",
            "lastName": "Chase",
            "fullName": "Heather Chase",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Chase,Heather.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor Windham",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windsor Windham-lower-ocd-person-0522f150-ee6f-4f9c-b1be-52ef20850133",
              "builtID": "vt-lower-windsor-windham",
              "externalID": "ocd-person/0522f150-ee6f-4f9c-b1be-52ef20850133",
              "geometry": null
            },
            "contactInfo": {
              "email": "hchase@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windsor-Windham.pdf",
                "https://legislature.vermont.gov/people/single/2024/37364"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windsor Windham-lower-ocd-person-0522f150-ee6f-4f9c-b1be-52ef20850133": 0
        }
      },
      "Windsor 4": {
        "members": [
          {
            "API_ID": "ocd-person/a424d253-1d98-4865-a5b7-998d3ffd1bc8",
            "firstName": "Heather",
            "lastName": "Surprenant",
            "fullName": "Heather Surprenant",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/HSurprenant.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor 4",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windsor 4-lower-ocd-person-a424d253-1d98-4865-a5b7-998d3ffd1bc8",
              "builtID": "vt-lower-windsor-4",
              "externalID": "ocd-person/a424d253-1d98-4865-a5b7-998d3ffd1bc8",
              "geometry": null
            },
            "contactInfo": {
              "email": "hsurprenant@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34695",
                "https://legislature.vermont.gov/people/single/2022/34695",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDR-4-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windsor-4.pdf",
                "https://legislature.vermont.gov/people/single/2024/34695"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windsor 4-lower-ocd-person-a424d253-1d98-4865-a5b7-998d3ffd1bc8": 0
        }
      },
      "Caledonia Washington": {
        "members": [
          {
            "API_ID": "ocd-person/f4043c71-d09d-46b4-badc-bda937e77bf3",
            "firstName": "Henry",
            "lastName": "Pearl",
            "fullName": "Henry Pearl",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Pearl,%20Henry.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Caledonia Washington",
              "chamber": "lower",
              "hashID": "SLDL-VT-Caledonia Washington-lower-ocd-person-f4043c71-d09d-46b4-badc-bda937e77bf3",
              "builtID": "vt-lower-caledonia-washington",
              "externalID": "ocd-person/f4043c71-d09d-46b4-badc-bda937e77bf3",
              "geometry": null
            },
            "contactInfo": {
              "email": "hpearl@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34709",
                "https://legislature.vermont.gov/people/single/2022/34709",
                "https://legislature.vermont.gov/Documents/SmallMaps/CAL-WAS.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Caledonia-Washington.pdf",
                "https://legislature.vermont.gov/people/single/2024/34709"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Caledonia Washington-lower-ocd-person-f4043c71-d09d-46b4-badc-bda937e77bf3": 0
        }
      },
      "Franklin 6": {
        "members": [
          {
            "API_ID": "ocd-person/2219c217-1804-40be-8183-ae139cd93f1c",
            "firstName": "James A.R.",
            "lastName": "Gregoire",
            "fullName": "James Gregoire",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Gregoire,James.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Franklin 6",
              "chamber": "lower",
              "hashID": "SLDL-VT-Franklin 6-lower-ocd-person-2219c217-1804-40be-8183-ae139cd93f1c",
              "builtID": "vt-lower-franklin-6",
              "externalID": "ocd-person/2219c217-1804-40be-8183-ae139cd93f1c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jgregoire@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30958",
                "https://legislature.vermont.gov/people/single/2022/30958",
                "https://legislature.vermont.gov/people/single/2022/30958",
                "https://legislature.vermont.gov/Documents/SmallMaps/FRA-6.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Franklin-6.pdf",
                "https://legislature.vermont.gov/people/single/2024/30958"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Franklin 6-lower-ocd-person-2219c217-1804-40be-8183-ae139cd93f1c": 0
        }
      },
      "Rutland 11": {
        "members": [
          {
            "API_ID": "ocd-person/bd5d142d-90c3-41f6-b8af-7591316951da",
            "firstName": "James",
            "lastName": "Harrison",
            "fullName": "Jim Harrison",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Harrison,James.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland 11",
              "chamber": "lower",
              "hashID": "SLDL-VT-Rutland 11-lower-ocd-person-bd5d142d-90c3-41f6-b8af-7591316951da",
              "builtID": "vt-lower-rutland-11",
              "externalID": "ocd-person/bd5d142d-90c3-41f6-b8af-7591316951da",
              "geometry": null
            },
            "contactInfo": {
              "email": "jharrison@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/28878",
                "https://legislature.vermont.gov/people/single/2022/28878",
                "https://legislature.vermont.gov/people/single/2022/28878",
                "https://legislature.vermont.gov/Documents/SmallMaps/RUT-WDR-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Rutland-11.pdf",
                "https://legislature.vermont.gov/people/single/2024/28878"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Rutland 11-lower-ocd-person-bd5d142d-90c3-41f6-b8af-7591316951da": 0
        }
      },
      "Windsor Orange-2": {
        "members": [
          {
            "API_ID": "ocd-person/5342eafc-7f58-4ebe-ad1e-0ec4aefe82fe",
            "firstName": "James W.",
            "lastName": "Masland",
            "fullName": "Jim Masland",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Masland.James.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor Orange-2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windsor Orange-2-lower-ocd-person-5342eafc-7f58-4ebe-ad1e-0ec4aefe82fe",
              "builtID": "vt-lower-windsor-orange-2",
              "externalID": "ocd-person/5342eafc-7f58-4ebe-ad1e-0ec4aefe82fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "jmasland@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14523",
                "https://legislature.vermont.gov/people/single/2022/14523",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDR-ORA-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/14523"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/610c8e4d-f0c3-4792-a5b6-f935eddc8960",
            "firstName": "Rebecca",
            "lastName": "Holcombe",
            "fullName": "Rebecca Holcombe",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Holcombe,%20Rebecca.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor Orange-2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windsor Orange-2-lower-ocd-person-610c8e4d-f0c3-4792-a5b6-f935eddc8960",
              "builtID": "vt-lower-windsor-orange-2",
              "externalID": "ocd-person/610c8e4d-f0c3-4792-a5b6-f935eddc8960",
              "geometry": null
            },
            "contactInfo": {
              "email": "rholcombe@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windsor-Orange-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/37366"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windsor Orange-2-lower-ocd-person-5342eafc-7f58-4ebe-ad1e-0ec4aefe82fe": 0,
          "SLDL-VT-Windsor Orange-2-lower-ocd-person-610c8e4d-f0c3-4792-a5b6-f935eddc8960": 1
        }
      },
      "Chittenden 1": {
        "members": [
          {
            "API_ID": "ocd-person/9ce762cd-72a4-4835-876b-71d83b16d44d",
            "firstName": "Jana",
            "lastName": "Brown",
            "fullName": "Jana Brown",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Brown,Jana.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 1-lower-ocd-person-9ce762cd-72a4-4835-876b-71d83b16d44d",
              "builtID": "vt-lower-chittenden-1",
              "externalID": "ocd-person/9ce762cd-72a4-4835-876b-71d83b16d44d",
              "geometry": null
            },
            "contactInfo": {
              "email": "jbrown@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34716",
                "https://legislature.vermont.gov/people/single/2022/34716",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/34716"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 1-lower-ocd-person-9ce762cd-72a4-4835-876b-71d83b16d44d": 0
        }
      },
      "Rutland 3": {
        "members": [
          {
            "API_ID": "ocd-person/7d1924ed-6c6d-4244-bf64-1ba412a723f8",
            "firstName": "Jarrod E.",
            "lastName": "Sammis",
            "fullName": "Jarrod Sammis",
            "gender": "Male",
            "party": "libertarian",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Sammis,%20Jarrod.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Rutland 3-lower-ocd-person-7d1924ed-6c6d-4244-bf64-1ba412a723f8",
              "builtID": "vt-lower-rutland-3",
              "externalID": "ocd-person/7d1924ed-6c6d-4244-bf64-1ba412a723f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "jsammis@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Rutland-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/37402"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Rutland 3-lower-ocd-person-7d1924ed-6c6d-4244-bf64-1ba412a723f8": 0
        }
      },
      "Lamoille 1": {
        "members": [
          {
            "API_ID": "ocd-person/5581159f-1776-41de-ab76-72c8fe5167c0",
            "firstName": "Jed",
            "lastName": "Lipsky",
            "fullName": "Jed Lipsky",
            "gender": "Male",
            "party": "independent",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Lipsky,Jed.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Lamoille 1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Lamoille 1-lower-ocd-person-5581159f-1776-41de-ab76-72c8fe5167c0",
              "builtID": "vt-lower-lamoille-1",
              "externalID": "ocd-person/5581159f-1776-41de-ab76-72c8fe5167c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jlipsky@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Lamoille-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/37385"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Lamoille 1-lower-ocd-person-5581159f-1776-41de-ab76-72c8fe5167c0": 0
        }
      },
      "Chittenden 7": {
        "members": [
          {
            "API_ID": "ocd-person/f45bc8b4-9df5-40f6-9e14-1acc6987e058",
            "firstName": "Jessica",
            "lastName": "Brumsted",
            "fullName": "Jessica Brumsted",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/JBrumsted.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 7",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 7-lower-ocd-person-f45bc8b4-9df5-40f6-9e14-1acc6987e058",
              "builtID": "vt-lower-chittenden-7",
              "externalID": "ocd-person/f45bc8b4-9df5-40f6-9e14-1acc6987e058",
              "geometry": null
            },
            "contactInfo": {
              "email": "jbrumsted@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/27160",
                "https://legislature.vermont.gov/people/single/2022/27160",
                "https://legislature.vermont.gov/people/single/2022/27160",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-5-2.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-7.pdf",
                "https://legislature.vermont.gov/people/single/2024/27160"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 7-lower-ocd-person-f45bc8b4-9df5-40f6-9e14-1acc6987e058": 0
        }
      },
      "Chittenden 16": {
        "members": [
          {
            "API_ID": "ocd-person/ca098cac-17de-4e6b-ac7c-178d77998970",
            "firstName": "Jill L.",
            "lastName": "Krowinski",
            "fullName": "Jill Krowinski",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/JKrowinski.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 16",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 16-lower-ocd-person-ca098cac-17de-4e6b-ac7c-178d77998970",
              "builtID": "vt-lower-chittenden-16",
              "externalID": "ocd-person/ca098cac-17de-4e6b-ac7c-178d77998970",
              "geometry": null
            },
            "contactInfo": {
              "email": "jkrowinski@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/19342",
                "https://legislature.vermont.gov/people/single/2022/19342",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-6-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/19342"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/52b7db57-8a72-4d36-9cfa-5f1a5b69a044",
            "firstName": "Kate",
            "lastName": "Logan",
            "fullName": "Kate Logan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Logan,Kate.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 16",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 16-lower-ocd-person-52b7db57-8a72-4d36-9cfa-5f1a5b69a044",
              "builtID": "vt-lower-chittenden-16",
              "externalID": "ocd-person/52b7db57-8a72-4d36-9cfa-5f1a5b69a044",
              "geometry": null
            },
            "contactInfo": {
              "email": "klogan@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-16.pdf",
                "https://legislature.vermont.gov/people/single/2024/37405"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 16-lower-ocd-person-ca098cac-17de-4e6b-ac7c-178d77998970": 0,
          "SLDL-VT-Chittenden 16-lower-ocd-person-52b7db57-8a72-4d36-9cfa-5f1a5b69a044": 1
        }
      },
      "Bennington 5": {
        "members": [
          {
            "API_ID": "ocd-person/e0dc51a6-8a81-4140-8693-15106fd1a000",
            "firstName": "James",
            "lastName": "Carroll",
            "fullName": "Jim Carroll",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Carroll.Jim.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Bennington 5",
              "chamber": "lower",
              "hashID": "SLDL-VT-Bennington 5-lower-ocd-person-e0dc51a6-8a81-4140-8693-15106fd1a000",
              "builtID": "vt-lower-bennington-5",
              "externalID": "ocd-person/e0dc51a6-8a81-4140-8693-15106fd1a000",
              "geometry": null
            },
            "contactInfo": {
              "email": "jcarroll@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Bennington-5.pdf",
                "https://legislature.vermont.gov/people/single/2024/37399"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/337fe4da-28df-42bf-82b1-e806bb50e8f3",
            "firstName": "Mary A.",
            "lastName": "Morrissey",
            "fullName": "Mary Morrissey",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/MMorrissey.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Bennington 5",
              "chamber": "lower",
              "hashID": "SLDL-VT-Bennington 5-lower-ocd-person-337fe4da-28df-42bf-82b1-e806bb50e8f3",
              "builtID": "vt-lower-bennington-5",
              "externalID": "ocd-person/337fe4da-28df-42bf-82b1-e806bb50e8f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmorrissey@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/14525",
                "https://legislature.vermont.gov/Documents/SmallMaps/BEN-2-2.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Bennington-5.pdf",
                "https://legislature.vermont.gov/people/single/2024/14525"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Bennington 5-lower-ocd-person-e0dc51a6-8a81-4140-8693-15106fd1a000": 0,
          "SLDL-VT-Bennington 5-lower-ocd-person-337fe4da-28df-42bf-82b1-e806bb50e8f3": 1
        }
      },
      "Windsor 2": {
        "members": [
          {
            "API_ID": "ocd-person/7928b236-54e5-4ec9-8d5f-8aa91d80cda9",
            "firstName": "John",
            "lastName": "Arrison",
            "fullName": "John Arrison",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Arrison,%20John.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windsor 2-lower-ocd-person-7928b236-54e5-4ec9-8d5f-8aa91d80cda9",
              "builtID": "vt-lower-windsor-2",
              "externalID": "ocd-person/7928b236-54e5-4ec9-8d5f-8aa91d80cda9",
              "geometry": null
            },
            "contactInfo": {
              "email": "jarrison@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34717",
                "https://legislature.vermont.gov/people/single/2022/34717",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDR-2.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windsor-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/34717"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windsor 2-lower-ocd-person-7928b236-54e5-4ec9-8d5f-8aa91d80cda9": 0
        }
      },
      "Windsor Orange-1": {
        "members": [
          {
            "API_ID": "ocd-person/6b0c1c32-609e-4d13-a9c5-699b73bd1470",
            "firstName": "John",
            "lastName": "O'Brien",
            "fullName": "John O'Brien",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/O'Brien,John.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor Orange-1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windsor Orange-1-lower-ocd-person-6b0c1c32-609e-4d13-a9c5-699b73bd1470",
              "builtID": "vt-lower-windsor-orange-1",
              "externalID": "ocd-person/6b0c1c32-609e-4d13-a9c5-699b73bd1470",
              "geometry": null
            },
            "contactInfo": {
              "email": "jobrien@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30977",
                "https://legislature.vermont.gov/people/single/2022/30977",
                "https://legislature.vermont.gov/people/single/2022/30977",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDR-ORA-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windsor-Orange-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/30977"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windsor Orange-1-lower-ocd-person-6b0c1c32-609e-4d13-a9c5-699b73bd1470": 0
        }
      },
      "Washington 3": {
        "members": [
          {
            "API_ID": "ocd-person/d366ba84-697f-4b98-b031-05c78d46ea6c",
            "firstName": "Jonathan",
            "lastName": "Williams",
            "fullName": "Jonathan Williams",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Williams,Jonathan.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Washington 3-lower-ocd-person-d366ba84-697f-4b98-b031-05c78d46ea6c",
              "builtID": "vt-lower-washington-3",
              "externalID": "ocd-person/d366ba84-697f-4b98-b031-05c78d46ea6c",
              "geometry": null
            },
            "contactInfo": {
              "email": "jwilliams@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Washington-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/37377"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/681ff7d0-07df-4aa7-bd84-89b5dfb9d377",
            "firstName": "Peter D.",
            "lastName": "Anthony",
            "fullName": "Peter Anthony",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Anthony.Peter.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Washington 3-lower-ocd-person-681ff7d0-07df-4aa7-bd84-89b5dfb9d377",
              "builtID": "vt-lower-washington-3",
              "externalID": "ocd-person/681ff7d0-07df-4aa7-bd84-89b5dfb9d377",
              "geometry": null
            },
            "contactInfo": {
              "email": "panthony@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30969",
                "https://legislature.vermont.gov/people/single/2022/30969",
                "https://legislature.vermont.gov/Documents/SmallMaps/WAS-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/30969"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Washington 3-lower-ocd-person-d366ba84-697f-4b98-b031-05c78d46ea6c": 0,
          "SLDL-VT-Washington 3-lower-ocd-person-681ff7d0-07df-4aa7-bd84-89b5dfb9d377": 1
        }
      },
      "Addison Rutland": {
        "members": [
          {
            "API_ID": "ocd-person/c6d9e283-2fb6-47ea-93b5-1d6b7c222da9",
            "firstName": "Joseph",
            "lastName": "Andriano",
            "fullName": "Joe Andriano",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Andriano,Joe.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Addison Rutland",
              "chamber": "lower",
              "hashID": "SLDL-VT-Addison Rutland-lower-ocd-person-c6d9e283-2fb6-47ea-93b5-1d6b7c222da9",
              "builtID": "vt-lower-addison-rutland",
              "externalID": "ocd-person/c6d9e283-2fb6-47ea-93b5-1d6b7c222da9",
              "geometry": null
            },
            "contactInfo": {
              "email": "jandriano@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Addison-Rutland.pdf",
                "https://legislature.vermont.gov/people/single/2024/37356"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Addison Rutland-lower-ocd-person-c6d9e283-2fb6-47ea-93b5-1d6b7c222da9": 0
        }
      },
      "Caledonia 2": {
        "members": [
          {
            "API_ID": "ocd-person/0ae074ad-454d-4c05-98f2-d118fd5841d4",
            "firstName": "Joseph",
            "lastName": "Troiano",
            "fullName": "Chip Troiano",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Troiano.Chip.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Caledonia 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Caledonia 2-lower-ocd-person-0ae074ad-454d-4c05-98f2-d118fd5841d4",
              "builtID": "vt-lower-caledonia-2",
              "externalID": "ocd-person/0ae074ad-454d-4c05-98f2-d118fd5841d4",
              "geometry": null
            },
            "contactInfo": {
              "email": "ctroiano@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/24026",
                "https://legislature.vermont.gov/people/single/2022/24026",
                "https://legislature.vermont.gov/people/single/2022/24026",
                "https://legislature.vermont.gov/Documents/SmallMaps/CAL-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/24026"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Caledonia 2-lower-ocd-person-0ae074ad-454d-4c05-98f2-d118fd5841d4": 0
        }
      },
      "Orange Caledonia": {
        "members": [
          {
            "API_ID": "ocd-person/54baf00b-c1da-4dc9-95cc-3210c50360e8",
            "firstName": "Joseph",
            "lastName": "Parsons",
            "fullName": "Joseph Parsons",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Parsons,%20Joe.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Orange Caledonia",
              "chamber": "lower",
              "hashID": "SLDL-VT-Orange Caledonia-lower-ocd-person-54baf00b-c1da-4dc9-95cc-3210c50360e8",
              "builtID": "vt-lower-orange-caledonia",
              "externalID": "ocd-person/54baf00b-c1da-4dc9-95cc-3210c50360e8",
              "geometry": null
            },
            "contactInfo": {
              "email": "jparsons@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34718",
                "https://legislature.vermont.gov/people/single/2022/34718",
                "https://legislature.vermont.gov/Documents/SmallMaps/ORA-CAL.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Orange-Caledonia.pdf",
                "https://legislature.vermont.gov/people/single/2024/34718"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Orange Caledonia-lower-ocd-person-54baf00b-c1da-4dc9-95cc-3210c50360e8": 0
        }
      },
      "Grand Isle Chittenden": {
        "members": [
          {
            "API_ID": "ocd-person/8ab64e17-d301-4569-9e5e-9acaff8907eb",
            "firstName": "Josie",
            "lastName": "Leavitt",
            "fullName": "Josie Leavitt",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Grand Isle Chittenden",
              "chamber": "lower",
              "hashID": "SLDL-VT-Grand Isle Chittenden-lower-ocd-person-8ab64e17-d301-4569-9e5e-9acaff8907eb",
              "builtID": "vt-lower-grand isle-chittenden",
              "externalID": "ocd-person/8ab64e17-d301-4569-9e5e-9acaff8907eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "jleavitt@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Grand%20Isle-Chittenden.pdf",
                "https://legislature.vermont.gov/people/single/2024/37401"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ede0cb44-cafd-43ef-9b1c-3e6efdd759e7",
            "firstName": "Michael R.",
            "lastName": "Morgan",
            "fullName": "Mike Morgan",
            "gender": "Male",
            "party": "Republican",
            "image": null,
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Grand Isle Chittenden",
              "chamber": "lower",
              "hashID": "SLDL-VT-Grand Isle Chittenden-lower-ocd-person-ede0cb44-cafd-43ef-9b1c-3e6efdd759e7",
              "builtID": "vt-lower-grand isle-chittenden",
              "externalID": "ocd-person/ede0cb44-cafd-43ef-9b1c-3e6efdd759e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmorgan@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2024/34693"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Grand Isle Chittenden-lower-ocd-person-8ab64e17-d301-4569-9e5e-9acaff8907eb": 0,
          "SLDL-VT-Grand Isle Chittenden-lower-ocd-person-ede0cb44-cafd-43ef-9b1c-3e6efdd759e7": 1
        }
      },
      "Addison 5": {
        "members": [
          {
            "API_ID": "ocd-person/829926f6-054a-45e2-be75-1c1da860f0c4",
            "firstName": "Jubilee",
            "lastName": "McGill",
            "fullName": "Jubilee McGill",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/McGill,Jubilee.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Addison 5",
              "chamber": "lower",
              "hashID": "SLDL-VT-Addison 5-lower-ocd-person-829926f6-054a-45e2-be75-1c1da860f0c4",
              "builtID": "vt-lower-addison-5",
              "externalID": "ocd-person/829926f6-054a-45e2-be75-1c1da860f0c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "jmcgill@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Addison-5.pdf",
                "https://legislature.vermont.gov/people/single/2024/37355"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Addison 5-lower-ocd-person-829926f6-054a-45e2-be75-1c1da860f0c4": 0
        }
      },
      "Chittenden 25": {
        "members": [
          {
            "API_ID": "ocd-person/16c5c8a2-5835-42ed-bed1-25947171a80d",
            "firstName": "Julia",
            "lastName": "Andrews",
            "fullName": "Julia Andrews",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Andrews,Julia.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 25",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 25-lower-ocd-person-16c5c8a2-5835-42ed-bed1-25947171a80d",
              "builtID": "vt-lower-chittenden-25",
              "externalID": "ocd-person/16c5c8a2-5835-42ed-bed1-25947171a80d",
              "geometry": null
            },
            "contactInfo": {
              "email": "jandrews@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-25.pdf",
                "https://legislature.vermont.gov/people/single/2024/37398"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 25-lower-ocd-person-16c5c8a2-5835-42ed-bed1-25947171a80d": 0
        }
      },
      "Chittenden 22": {
        "members": [
          {
            "API_ID": "ocd-person/b2d2f2a9-28a3-40c5-a818-9f839adac1fa",
            "firstName": "Karen",
            "lastName": "Dolan",
            "fullName": "Karen Dolan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Dolan,Kari.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 22",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 22-lower-ocd-person-b2d2f2a9-28a3-40c5-a818-9f839adac1fa",
              "builtID": "vt-lower-chittenden-22",
              "externalID": "ocd-person/b2d2f2a9-28a3-40c5-a818-9f839adac1fa",
              "geometry": null
            },
            "contactInfo": {
              "email": "kndolan@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34708",
                "https://legislature.vermont.gov/people/single/2022/34708",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-8-2.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-22.pdf",
                "https://legislature.vermont.gov/people/single/2024/30970",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Washington-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/34708"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/acf44b62-a884-4de2-8deb-055363849c5b",
            "firstName": "Lori",
            "lastName": "Houghton",
            "fullName": "Lori Houghton",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Houghton,%20Lori.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 22",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 22-lower-ocd-person-acf44b62-a884-4de2-8deb-055363849c5b",
              "builtID": "vt-lower-chittenden-22",
              "externalID": "ocd-person/acf44b62-a884-4de2-8deb-055363849c5b",
              "geometry": null
            },
            "contactInfo": {
              "email": "lhoughton@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/27166",
                "https://legislature.vermont.gov/people/single/2022/27166",
                "https://legislature.vermont.gov/people/single/2022/27166",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-8-2.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-22.pdf",
                "https://legislature.vermont.gov/people/single/2024/27166"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 22-lower-ocd-person-b2d2f2a9-28a3-40c5-a818-9f839adac1fa": 0,
          "SLDL-VT-Chittenden 22-lower-ocd-person-acf44b62-a884-4de2-8deb-055363849c5b": 1
        }
      },
      "Chittenden 6": {
        "members": [
          {
            "API_ID": "ocd-person/f9d0a963-ca4d-4ab2-a761-ad4aa6983055",
            "firstName": "Kate",
            "lastName": "Lalley",
            "fullName": "Kate Lalley",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Lalley,Kate.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 6",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 6-lower-ocd-person-f9d0a963-ca4d-4ab2-a761-ad4aa6983055",
              "builtID": "vt-lower-chittenden-6",
              "externalID": "ocd-person/f9d0a963-ca4d-4ab2-a761-ad4aa6983055",
              "geometry": null
            },
            "contactInfo": {
              "email": "klalley@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-6.pdf",
                "https://legislature.vermont.gov/people/single/2024/37370"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 6-lower-ocd-person-f9d0a963-ca4d-4ab2-a761-ad4aa6983055": 0
        }
      },
      "Chittenden 10": {
        "members": [
          {
            "API_ID": "ocd-person/edf5128d-7630-41be-8b16-c183d7cb24e5",
            "firstName": "Kathryn",
            "lastName": "Nugent",
            "fullName": "Kate Nugent",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Nugent.Kate.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 10",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 10-lower-ocd-person-edf5128d-7630-41be-8b16-c183d7cb24e5",
              "builtID": "vt-lower-chittenden-10",
              "externalID": "ocd-person/edf5128d-7630-41be-8b16-c183d7cb24e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "knugent@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-10.pdf",
                "https://legislature.vermont.gov/people/single/2024/37397"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 10-lower-ocd-person-edf5128d-7630-41be-8b16-c183d7cb24e5": 0
        }
      },
      "Orleans 4": {
        "members": [
          {
            "API_ID": "ocd-person/16f6cd78-f334-422c-a5dc-468fdc40db3b",
            "firstName": "Katherine",
            "lastName": "Sims",
            "fullName": "Katherine Sims",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Sims,Katherine.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Orleans 4",
              "chamber": "lower",
              "hashID": "SLDL-VT-Orleans 4-lower-ocd-person-16f6cd78-f334-422c-a5dc-468fdc40db3b",
              "builtID": "vt-lower-orleans-4",
              "externalID": "ocd-person/16f6cd78-f334-422c-a5dc-468fdc40db3b",
              "geometry": null
            },
            "contactInfo": {
              "email": "ksims@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34712",
                "https://legislature.vermont.gov/people/single/2022/34712",
                "https://legislature.vermont.gov/Documents/SmallMaps/ORL-CAL.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Orleans-4.pdf",
                "https://legislature.vermont.gov/people/single/2024/34712"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Orleans 4-lower-ocd-person-16f6cd78-f334-422c-a5dc-468fdc40db3b": 0
        }
      },
      "Bennington 4": {
        "members": [
          {
            "API_ID": "ocd-person/8b47b675-97d4-4cdc-a3ae-d44c05594b5c",
            "firstName": "Kathleen",
            "lastName": "James",
            "fullName": "Kathleen James",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/James.Kath.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Bennington 4",
              "chamber": "lower",
              "hashID": "SLDL-VT-Bennington 4-lower-ocd-person-8b47b675-97d4-4cdc-a3ae-d44c05594b5c",
              "builtID": "vt-lower-bennington-4",
              "externalID": "ocd-person/8b47b675-97d4-4cdc-a3ae-d44c05594b5c",
              "geometry": null
            },
            "contactInfo": {
              "email": "kjames@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30945",
                "https://legislature.vermont.gov/people/single/2022/30945",
                "https://legislature.vermont.gov/Documents/SmallMaps/BEN-4.pdf",
                "https://legislature.vermont.gov/people/single/2024/30945"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/28f82298-2bd5-4f82-9e4a-3e2261f0062c",
            "firstName": "Seth B.",
            "lastName": "Bongartz",
            "fullName": "Seth Bongartz",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Bongartz,%20Seth.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Bennington 4",
              "chamber": "lower",
              "hashID": "SLDL-VT-Bennington 4-lower-ocd-person-28f82298-2bd5-4f82-9e4a-3e2261f0062c",
              "builtID": "vt-lower-bennington-4",
              "externalID": "ocd-person/28f82298-2bd5-4f82-9e4a-3e2261f0062c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sbongartz@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34697",
                "https://legislature.vermont.gov/people/single/2022/34697",
                "https://legislature.vermont.gov/Documents/SmallMaps/BEN-4.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Bennington-4.pdf",
                "https://legislature.vermont.gov/people/single/2024/34697"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Bennington 4-lower-ocd-person-8b47b675-97d4-4cdc-a3ae-d44c05594b5c": 0,
          "SLDL-VT-Bennington 4-lower-ocd-person-28f82298-2bd5-4f82-9e4a-3e2261f0062c": 1
        }
      },
      "Windham Windsor-Bennington": {
        "members": [
          {
            "API_ID": "ocd-person/bc95d135-723c-4f4d-8519-ed8800b3aa57",
            "firstName": "Kelly MacLaury",
            "lastName": "Pajala",
            "fullName": "Kelly Pajala",
            "gender": "Female",
            "party": "independent",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Pajala,Kelly.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windham Windsor-Bennington",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windham Windsor-Bennington-lower-ocd-person-bc95d135-723c-4f4d-8519-ed8800b3aa57",
              "builtID": "vt-lower-windham-windsor-bennington",
              "externalID": "ocd-person/bc95d135-723c-4f4d-8519-ed8800b3aa57",
              "geometry": null
            },
            "contactInfo": {
              "email": "kpajala@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/29214",
                "https://legislature.vermont.gov/people/single/2022/29214",
                "https://legislature.vermont.gov/people/single/2022/29214",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDH-BEN-WDR.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windham-Windsor-Bennington.pdf",
                "https://legislature.vermont.gov/people/single/2024/29214"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windham Windsor-Bennington-lower-ocd-person-bc95d135-723c-4f4d-8519-ed8800b3aa57": 0
        }
      },
      "Windsor Addison": {
        "members": [
          {
            "API_ID": "ocd-person/60ea24d4-7a38-4d78-b07d-2fc414be2e4b",
            "firstName": "Kirk",
            "lastName": "White",
            "fullName": "Kirk White",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/White,Kirk.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor Addison",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windsor Addison-lower-ocd-person-60ea24d4-7a38-4d78-b07d-2fc414be2e4b",
              "builtID": "vt-lower-windsor-addison",
              "externalID": "ocd-person/60ea24d4-7a38-4d78-b07d-2fc414be2e4b",
              "geometry": null
            },
            "contactInfo": {
              "email": "kwhite@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34694",
                "https://legislature.vermont.gov/people/single/2022/34694",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDR-RUT.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windsor-Addison.pdf",
                "https://legislature.vermont.gov/people/single/2024/34694"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windsor Addison-lower-ocd-person-60ea24d4-7a38-4d78-b07d-2fc414be2e4b": 0
        }
      },
      "Essex Orleans": {
        "members": [
          {
            "API_ID": "ocd-person/b9ffa5dc-833e-4936-9d62-0b653ae44842",
            "firstName": "Larry",
            "lastName": "Labor",
            "fullName": "Larry Labor",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Labor,Larry.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Essex Orleans",
              "chamber": "lower",
              "hashID": "SLDL-VT-Essex Orleans-lower-ocd-person-b9ffa5dc-833e-4936-9d62-0b653ae44842",
              "builtID": "vt-lower-essex-orleans",
              "externalID": "ocd-person/b9ffa5dc-833e-4936-9d62-0b653ae44842",
              "geometry": null
            },
            "contactInfo": {
              "email": "llabor@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/36152",
                "https://legislature.vermont.gov/Documents/SmallMaps/ORL-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Essex-Orleans.pdf",
                "https://legislature.vermont.gov/people/single/2024/36152"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Essex Orleans-lower-ocd-person-b9ffa5dc-833e-4936-9d62-0b653ae44842": 0
        }
      },
      "Orange Washington-Addison": {
        "members": [
          {
            "API_ID": "ocd-person/d6092ff2-3e4d-4c9a-9317-6d243c7bc0e8",
            "firstName": "Larry",
            "lastName": "Satcowitz",
            "fullName": "Larry Satcowitz",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Satcowitz,%20Larry.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Orange Washington-Addison",
              "chamber": "lower",
              "hashID": "SLDL-VT-Orange Washington-Addison-lower-ocd-person-d6092ff2-3e4d-4c9a-9317-6d243c7bc0e8",
              "builtID": "vt-lower-orange-washington-addison",
              "externalID": "ocd-person/d6092ff2-3e4d-4c9a-9317-6d243c7bc0e8",
              "geometry": null
            },
            "contactInfo": {
              "email": "lsatcowitz@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34703",
                "https://legislature.vermont.gov/people/single/2022/34703",
                "https://legislature.vermont.gov/Documents/SmallMaps/ORA-WAS-ADD.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Orange-Washington-Addison.pdf",
                "https://legislature.vermont.gov/people/single/2024/34703"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/fe86164f-d4c6-4ed6-b9f4-db9a7fb70f6a",
            "firstName": "Philip Jay",
            "lastName": "Hooper",
            "fullName": "Jay Hooper",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Hooper,Jay.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Orange Washington-Addison",
              "chamber": "lower",
              "hashID": "SLDL-VT-Orange Washington-Addison-lower-ocd-person-fe86164f-d4c6-4ed6-b9f4-db9a7fb70f6a",
              "builtID": "vt-lower-orange-washington-addison",
              "externalID": "ocd-person/fe86164f-d4c6-4ed6-b9f4-db9a7fb70f6a",
              "geometry": null
            },
            "contactInfo": {
              "email": "jhooper@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/27174",
                "https://legislature.vermont.gov/Documents/SmallMaps/ORA-WAS-ADD.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Orange-Washington-Addison.pdf",
                "https://legislature.vermont.gov/people/single/2024/27174"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Orange Washington-Addison-lower-ocd-person-d6092ff2-3e4d-4c9a-9317-6d243c7bc0e8": 0,
          "SLDL-VT-Orange Washington-Addison-lower-ocd-person-fe86164f-d4c6-4ed6-b9f4-db9a7fb70f6a": 1
        }
      },
      "Windham 2": {
        "members": [
          {
            "API_ID": "ocd-person/57c62cd8-8701-4af6-9627-b89e735b5632",
            "firstName": "Laura",
            "lastName": "Sibilia",
            "fullName": "Laura Sibilia",
            "gender": "Female",
            "party": "independent",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Sibilia,Laura.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windham 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windham 2-lower-ocd-person-57c62cd8-8701-4af6-9627-b89e735b5632",
              "builtID": "vt-lower-windham-2",
              "externalID": "ocd-person/57c62cd8-8701-4af6-9627-b89e735b5632",
              "geometry": null
            },
            "contactInfo": {
              "email": "lsibilia@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/24023",
                "https://legislature.vermont.gov/people/single/2022/24023",
                "https://legislature.vermont.gov/people/single/2022/24023",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDH-BEN.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windham-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/24023"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windham 2-lower-ocd-person-57c62cd8-8701-4af6-9627-b89e735b5632": 0
        }
      },
      "Chittenden 23": {
        "members": [
          {
            "API_ID": "ocd-person/a7ecda71-d1ad-4e61-bc68-16e929cfc11d",
            "firstName": "Leonora",
            "lastName": "Dodge",
            "fullName": "Leonora Dodge",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Dodge,Leonora.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 23",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 23-lower-ocd-person-a7ecda71-d1ad-4e61-bc68-16e929cfc11d",
              "builtID": "vt-lower-chittenden-23",
              "externalID": "ocd-person/a7ecda71-d1ad-4e61-bc68-16e929cfc11d",
              "geometry": null
            },
            "contactInfo": {
              "email": "ldodge@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-23.pdf",
                "https://legislature.vermont.gov/people/single/2024/37390"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/9fdba4e0-d367-4fd1-86d6-815042abd075",
            "firstName": "Golrang",
            "lastName": "Garofano",
            "fullName": "Rey Garofano",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Garofano,Rey.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 23",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 23-lower-ocd-person-9fdba4e0-d367-4fd1-86d6-815042abd075",
              "builtID": "vt-lower-chittenden-23",
              "externalID": "ocd-person/9fdba4e0-d367-4fd1-86d6-815042abd075",
              "geometry": null
            },
            "contactInfo": {
              "email": "rgarofano@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/36257",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-8-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-23.pdf",
                "https://legislature.vermont.gov/people/single/2024/36257"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 23-lower-ocd-person-a7ecda71-d1ad-4e61-bc68-16e929cfc11d": 0,
          "SLDL-VT-Chittenden 23-lower-ocd-person-9fdba4e0-d367-4fd1-86d6-815042abd075": 1
        }
      },
      "Windham 3": {
        "members": [
          {
            "API_ID": "ocd-person/90a243b6-e5b2-4f16-b19f-612508863535",
            "firstName": "Leslie",
            "lastName": "Goldman",
            "fullName": "Leslie Goldman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Goldman,Leslie.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windham 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windham 3-lower-ocd-person-90a243b6-e5b2-4f16-b19f-612508863535",
              "builtID": "vt-lower-windham-3",
              "externalID": "ocd-person/90a243b6-e5b2-4f16-b19f-612508863535",
              "geometry": null
            },
            "contactInfo": {
              "email": "lgoldman@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34698",
                "https://legislature.vermont.gov/people/single/2022/34698",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDH-3.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windham-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/34698"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a039bd2d-a206-4976-857e-c6434b26fde1",
            "firstName": "Michelle",
            "lastName": "Bos-Lun",
            "fullName": "Michelle Bos-Lun",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Bos-Lun,%20Michelle.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windham 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windham 3-lower-ocd-person-a039bd2d-a206-4976-857e-c6434b26fde1",
              "builtID": "vt-lower-windham-3",
              "externalID": "ocd-person/a039bd2d-a206-4976-857e-c6434b26fde1",
              "geometry": null
            },
            "contactInfo": {
              "email": "mboslun@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34699",
                "https://legislature.vermont.gov/people/single/2022/34699",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDH-4.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windham-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/34699"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windham 3-lower-ocd-person-90a243b6-e5b2-4f16-b19f-612508863535": 0,
          "SLDL-VT-Windham 3-lower-ocd-person-a039bd2d-a206-4976-857e-c6434b26fde1": 1
        }
      },
      "Franklin 5": {
        "members": [
          {
            "API_ID": "ocd-person/99b59993-b8ed-4046-b343-2958d25a58a4",
            "firstName": "Lisa Abbott",
            "lastName": "Hango",
            "fullName": "Lisa Hango",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/LHango.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Franklin 5",
              "chamber": "lower",
              "hashID": "SLDL-VT-Franklin 5-lower-ocd-person-99b59993-b8ed-4046-b343-2958d25a58a4",
              "builtID": "vt-lower-franklin-5",
              "externalID": "ocd-person/99b59993-b8ed-4046-b343-2958d25a58a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "lhango@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/31854",
                "https://legislature.vermont.gov/people/single/2022/31854",
                "https://legislature.vermont.gov/Documents/SmallMaps/FRA-5.pdf",
                "https://legislature.vermont.gov/people/single/2024/31854"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1ae38fee-1328-4298-aa0a-11b5d0265730",
            "firstName": "Wayne A.",
            "lastName": "Laroche",
            "fullName": "Wayne Laroche",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Laroche.Wayne.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Franklin 5",
              "chamber": "lower",
              "hashID": "SLDL-VT-Franklin 5-lower-ocd-person-1ae38fee-1328-4298-aa0a-11b5d0265730",
              "builtID": "vt-lower-franklin-5",
              "externalID": "ocd-person/1ae38fee-1328-4298-aa0a-11b5d0265730",
              "geometry": null
            },
            "contactInfo": {
              "email": "wlaroche@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/36828",
                "https://legislature.vermont.gov/Documents/SmallMaps/FRA-5.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Franklin-5.pdf",
                "https://legislature.vermont.gov/people/single/2024/36828"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Franklin 5-lower-ocd-person-99b59993-b8ed-4046-b343-2958d25a58a4": 0,
          "SLDL-VT-Franklin 5-lower-ocd-person-1ae38fee-1328-4298-aa0a-11b5d0265730": 1
        }
      },
      "Rutland Windsor": {
        "members": [
          {
            "API_ID": "ocd-person/33459ac0-b2b3-4dde-8f2d-85fcd37cd691",
            "firstName": "Logan",
            "lastName": "Nicoll",
            "fullName": "Logan Nicoll",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Nicoll,%20Logan.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland Windsor",
              "chamber": "lower",
              "hashID": "SLDL-VT-Rutland Windsor-lower-ocd-person-33459ac0-b2b3-4dde-8f2d-85fcd37cd691",
              "builtID": "vt-lower-rutland-windsor",
              "externalID": "ocd-person/33459ac0-b2b3-4dde-8f2d-85fcd37cd691",
              "geometry": null
            },
            "contactInfo": {
              "email": "lnicoll@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30967",
                "https://legislature.vermont.gov/people/single/2022/30967",
                "https://legislature.vermont.gov/people/single/2022/30967",
                "https://legislature.vermont.gov/Documents/SmallMaps/RUT-WDR-2.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Rutland-Windsor.pdf",
                "https://legislature.vermont.gov/people/single/2024/30967"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Rutland Windsor-lower-ocd-person-33459ac0-b2b3-4dde-8f2d-85fcd37cd691": 0
        }
      },
      "Lamoille 3": {
        "members": [
          {
            "API_ID": "ocd-person/95e547fd-21f0-489d-a558-a8937d6341e5",
            "firstName": "Lucy",
            "lastName": "Boyden",
            "fullName": "Lucy Boyden",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Boyden,%20Lucy.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Lamoille 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Lamoille 3-lower-ocd-person-95e547fd-21f0-489d-a558-a8937d6341e5",
              "builtID": "vt-lower-lamoille-3",
              "externalID": "ocd-person/95e547fd-21f0-489d-a558-a8937d6341e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "lboyden@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Lamoille-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/37384"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Lamoille 3-lower-ocd-person-95e547fd-21f0-489d-a558-a8937d6341e5": 0
        }
      },
      "Washington 6": {
        "members": [
          {
            "API_ID": "ocd-person/1f0c898f-0199-4b66-a432-d81c85ad9528",
            "firstName": "Marc Bramer",
            "lastName": "Mihaly",
            "fullName": "Marc Mihaly",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Mihaly,Marc.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington 6",
              "chamber": "lower",
              "hashID": "SLDL-VT-Washington 6-lower-ocd-person-1f0c898f-0199-4b66-a432-d81c85ad9528",
              "builtID": "vt-lower-washington-6",
              "externalID": "ocd-person/1f0c898f-0199-4b66-a432-d81c85ad9528",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmihaly@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Washington-6.pdf",
                "https://legislature.vermont.gov/people/single/2024/37373"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Washington 6-lower-ocd-person-1f0c898f-0199-4b66-a432-d81c85ad9528": 0
        }
      },
      "Orleans Lamoille": {
        "members": [
          {
            "API_ID": "ocd-person/67ac8d03-20fe-47a8-9997-61467b4a8e30",
            "firstName": "Mark A.",
            "lastName": "Higley",
            "fullName": "Mark Higley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/MHigley.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Orleans Lamoille",
              "chamber": "lower",
              "hashID": "SLDL-VT-Orleans Lamoille-lower-ocd-person-67ac8d03-20fe-47a8-9997-61467b4a8e30",
              "builtID": "vt-lower-orleans-lamoille",
              "externalID": "ocd-person/67ac8d03-20fe-47a8-9997-61467b4a8e30",
              "geometry": null
            },
            "contactInfo": {
              "email": "mhigley@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14681",
                "https://legislature.vermont.gov/people/single/2022/14681",
                "https://legislature.vermont.gov/people/single/2022/14681",
                "https://legislature.vermont.gov/Documents/SmallMaps/ORL-LAM.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Orleans-Lamoille.pdf",
                "https://legislature.vermont.gov/people/single/2024/14681"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/cffd5316-4723-43a2-bd54-2d41a9e61e16",
            "firstName": "Michael J.",
            "lastName": "Marcotte",
            "fullName": "Michael Marcotte",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Marcotte.Michael.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Orleans Lamoille",
              "chamber": "lower",
              "hashID": "SLDL-VT-Orleans Lamoille-lower-ocd-person-cffd5316-4723-43a2-bd54-2d41a9e61e16",
              "builtID": "vt-lower-orleans-lamoille",
              "externalID": "ocd-person/cffd5316-4723-43a2-bd54-2d41a9e61e16",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmarcotte@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14568",
                "https://legislature.vermont.gov/people/single/2022/14568",
                "https://legislature.vermont.gov/Documents/SmallMaps/ORL-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/14568"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Orleans Lamoille-lower-ocd-person-67ac8d03-20fe-47a8-9997-61467b4a8e30": 0,
          "SLDL-VT-Orleans Lamoille-lower-ocd-person-cffd5316-4723-43a2-bd54-2d41a9e61e16": 1
        }
      },
      "Chittenden 12": {
        "members": [
          {
            "API_ID": "ocd-person/73ee356a-069a-4f2c-938d-2bab3d81b49a",
            "firstName": "Martin",
            "lastName": "LaLonde",
            "fullName": "Martin LaLonde",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/MLaLonde.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 12",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 12-lower-ocd-person-73ee356a-069a-4f2c-938d-2bab3d81b49a",
              "builtID": "vt-lower-chittenden-12",
              "externalID": "ocd-person/73ee356a-069a-4f2c-938d-2bab3d81b49a",
              "geometry": null
            },
            "contactInfo": {
              "email": "mlalonde@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/24010",
                "https://legislature.vermont.gov/people/single/2022/24010",
                "https://legislature.vermont.gov/people/single/2022/24010",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-7-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-12.pdf",
                "https://legislature.vermont.gov/people/single/2024/24010"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 12-lower-ocd-person-73ee356a-069a-4f2c-938d-2bab3d81b49a": 0
        }
      },
      "Rutland 6": {
        "members": [
          {
            "API_ID": "ocd-person/3d835c85-50e3-4a02-8891-baa377f63fa5",
            "firstName": "Mary E.",
            "lastName": "Howard",
            "fullName": "Mary Howard",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Howard,Mary.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland 6",
              "chamber": "lower",
              "hashID": "SLDL-VT-Rutland 6-lower-ocd-person-3d835c85-50e3-4a02-8891-baa377f63fa5",
              "builtID": "vt-lower-rutland-6",
              "externalID": "ocd-person/3d835c85-50e3-4a02-8891-baa377f63fa5",
              "geometry": null
            },
            "contactInfo": {
              "email": "mhoward@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/27177",
                "https://legislature.vermont.gov/people/single/2022/27177",
                "https://legislature.vermont.gov/Documents/SmallMaps/RUT-5-3.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Rutland-6.pdf",
                "https://legislature.vermont.gov/people/single/2024/27177"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Rutland 6-lower-ocd-person-3d835c85-50e3-4a02-8891-baa377f63fa5": 0
        }
      },
      "Franklin 4": {
        "members": [
          {
            "API_ID": "ocd-person/36d8fa28-e00c-4894-96b3-ef2946001d88",
            "firstName": "Matthew E.",
            "lastName": "Walker",
            "fullName": "Matt Walker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Walker,%20Matt.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Franklin 4",
              "chamber": "lower",
              "hashID": "SLDL-VT-Franklin 4-lower-ocd-person-36d8fa28-e00c-4894-96b3-ef2946001d88",
              "builtID": "vt-lower-franklin-4",
              "externalID": "ocd-person/36d8fa28-e00c-4894-96b3-ef2946001d88",
              "geometry": null
            },
            "contactInfo": {
              "email": "mwalker@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/36564",
                "https://legislature.vermont.gov/Documents/SmallMaps/FRA-4.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Franklin-4.pdf",
                "https://legislature.vermont.gov/people/single/2024/36564"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/fe8a848a-09ff-4cca-b3b7-5f7c2750556f",
            "firstName": "Thomas",
            "lastName": "Oliver",
            "fullName": "Tom Oliver",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Oliver,Thomas.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Franklin 4",
              "chamber": "lower",
              "hashID": "SLDL-VT-Franklin 4-lower-ocd-person-fe8a848a-09ff-4cca-b3b7-5f7c2750556f",
              "builtID": "vt-lower-franklin-4",
              "externalID": "ocd-person/fe8a848a-09ff-4cca-b3b7-5f7c2750556f",
              "geometry": null
            },
            "contactInfo": {
              "email": "toliver@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Franklin-4.pdf",
                "https://legislature.vermont.gov/people/single/2024/37387"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Franklin 4-lower-ocd-person-36d8fa28-e00c-4894-96b3-ef2946001d88": 0,
          "SLDL-VT-Franklin 4-lower-ocd-person-fe8a848a-09ff-4cca-b3b7-5f7c2750556f": 1
        }
      },
      "Franklin 3": {
        "members": [
          {
            "API_ID": "ocd-person/31ea201b-86e9-4222-8840-cd787f477361",
            "firstName": "Michael",
            "lastName": "McCarthy",
            "fullName": "Mike McCarthy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/McCarthy.Mike.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Franklin 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Franklin 3-lower-ocd-person-31ea201b-86e9-4222-8840-cd787f477361",
              "builtID": "vt-lower-franklin-3",
              "externalID": "ocd-person/31ea201b-86e9-4222-8840-cd787f477361",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmccarthy@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30954",
                "https://legislature.vermont.gov/people/single/2022/30954",
                "https://legislature.vermont.gov/Documents/SmallMaps/FRA-3-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Franklin-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/30954"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Franklin 3-lower-ocd-person-31ea201b-86e9-4222-8840-cd787f477361": 0
        }
      },
      "Windham 4": {
        "members": [
          {
            "API_ID": "ocd-person/4258ac6c-c453-4faf-b15c-4cbd8ea91ebd",
            "firstName": "Michael",
            "lastName": "Mrowicki",
            "fullName": "Mike Mrowicki",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Mrowicki,Mike.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windham 4",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windham 4-lower-ocd-person-4258ac6c-c453-4faf-b15c-4cbd8ea91ebd",
              "builtID": "vt-lower-windham-4",
              "externalID": "ocd-person/4258ac6c-c453-4faf-b15c-4cbd8ea91ebd",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmrowicki@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14598",
                "https://legislature.vermont.gov/people/single/2022/14598",
                "https://legislature.vermont.gov/people/single/2022/14598",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDH-4.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windham-4.pdf",
                "https://legislature.vermont.gov/people/single/2024/14598"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windham 4-lower-ocd-person-4258ac6c-c453-4faf-b15c-4cbd8ea91ebd": 0
        }
      },
      "Bennington Rutland": {
        "members": [
          {
            "API_ID": "ocd-person/4200ebd4-7bf4-4f44-91ca-ed60008fe278",
            "firstName": "Mike",
            "lastName": "Rice",
            "fullName": "Mike Rice",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Rice,Mike.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Bennington Rutland",
              "chamber": "lower",
              "hashID": "SLDL-VT-Bennington Rutland-lower-ocd-person-4200ebd4-7bf4-4f44-91ca-ed60008fe278",
              "builtID": "vt-lower-bennington-rutland",
              "externalID": "ocd-person/4200ebd4-7bf4-4f44-91ca-ed60008fe278",
              "geometry": null
            },
            "contactInfo": {
              "email": "mrice@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Bennington-Rutland.pdf",
                "https://legislature.vermont.gov/people/single/2024/37357"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Bennington Rutland-lower-ocd-person-4200ebd4-7bf4-4f44-91ca-ed60008fe278": 0
        }
      },
      "Windham 8": {
        "members": [
          {
            "API_ID": "ocd-person/2bf6abc2-667f-494f-896a-812721ee77d3",
            "firstName": "Mollie S.",
            "lastName": "Burke",
            "fullName": "Mollie Burke",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Burke.Mollie.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windham 8",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windham 8-lower-ocd-person-2bf6abc2-667f-494f-896a-812721ee77d3",
              "builtID": "vt-lower-windham-8",
              "externalID": "ocd-person/2bf6abc2-667f-494f-896a-812721ee77d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "mburke@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/14690",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDH-2-2.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windham-8.pdf",
                "https://legislature.vermont.gov/people/single/2024/14690"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windham 8-lower-ocd-person-2bf6abc2-667f-494f-896a-812721ee77d3": 0
        }
      },
      "Orange 2": {
        "members": [
          {
            "API_ID": "ocd-person/64ee14bb-3a28-4062-ba6d-4ec89c25b64c",
            "firstName": "Monique",
            "lastName": "Priestley",
            "fullName": "Monique Priestley",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Priestley,%20Monique.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Orange 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Orange 2-lower-ocd-person-64ee14bb-3a28-4062-ba6d-4ec89c25b64c",
              "builtID": "vt-lower-orange-2",
              "externalID": "ocd-person/64ee14bb-3a28-4062-ba6d-4ec89c25b64c",
              "geometry": null
            },
            "contactInfo": {
              "email": "mpriestley@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Orange-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/37382"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Orange 2-lower-ocd-person-64ee14bb-3a28-4062-ba6d-4ec89c25b64c": 0
        }
      },
      "Bennington 1": {
        "members": [
          {
            "API_ID": "ocd-person/805deeb4-55c0-4f3c-a01f-3fd3c6096f21",
            "firstName": "Nelson",
            "lastName": "Brownell",
            "fullName": "Nelson Brownell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Brownell.Nelson.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Bennington 1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Bennington 1-lower-ocd-person-805deeb4-55c0-4f3c-a01f-3fd3c6096f21",
              "builtID": "vt-lower-bennington-1",
              "externalID": "ocd-person/805deeb4-55c0-4f3c-a01f-3fd3c6096f21",
              "geometry": null
            },
            "contactInfo": {
              "email": "nbrownell@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30941",
                "https://legislature.vermont.gov/people/single/2022/30941",
                "https://legislature.vermont.gov/people/single/2022/30941",
                "https://legislature.vermont.gov/Documents/SmallMaps/BEN-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Bennington-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/30941"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Bennington 1-lower-ocd-person-805deeb4-55c0-4f3c-a01f-3fd3c6096f21": 0
        }
      },
      "Chittenden 8": {
        "members": [
          {
            "API_ID": "ocd-person/794e3611-eecd-4316-a204-fb0ce2110a87",
            "firstName": "Noah",
            "lastName": "Hyman",
            "fullName": "Noah Hyman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Hyman,%20Noah.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 8",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 8-lower-ocd-person-794e3611-eecd-4316-a204-fb0ce2110a87",
              "builtID": "vt-lower-chittenden-8",
              "externalID": "ocd-person/794e3611-eecd-4316-a204-fb0ce2110a87",
              "geometry": null
            },
            "contactInfo": {
              "email": "nhyman@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-8.pdf",
                "https://legislature.vermont.gov/people/single/2024/37393"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 8-lower-ocd-person-794e3611-eecd-4316-a204-fb0ce2110a87": 0
        }
      },
      "Rutland 1": {
        "members": [
          {
            "API_ID": "ocd-person/599c54f9-34b6-4e31-bb50-e36708c3509b",
            "firstName": "Patricia A.",
            "lastName": "McCoy",
            "fullName": "Pattie McCoy",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/McCoy,%20Pattie.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland 1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Rutland 1-lower-ocd-person-599c54f9-34b6-4e31-bb50-e36708c3509b",
              "builtID": "vt-lower-rutland-1",
              "externalID": "ocd-person/599c54f9-34b6-4e31-bb50-e36708c3509b",
              "geometry": null
            },
            "contactInfo": {
              "email": "pmccoy@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/24015",
                "https://legislature.vermont.gov/Documents/SmallMaps/RUT-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Rutland-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/24015"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Rutland 1-lower-ocd-person-599c54f9-34b6-4e31-bb50-e36708c3509b": 0
        }
      },
      "Chittenden 19": {
        "members": [
          {
            "API_ID": "ocd-person/44bcd67b-5b5f-4fca-a61a-20f91072c5e7",
            "firstName": "Patrick M.",
            "lastName": "Brennan",
            "fullName": "Patrick Brennan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Brennan.Patrick.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 19",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 19-lower-ocd-person-44bcd67b-5b5f-4fca-a61a-20f91072c5e7",
              "builtID": "vt-lower-chittenden-19",
              "externalID": "ocd-person/44bcd67b-5b5f-4fca-a61a-20f91072c5e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "pbrennan@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14549",
                "https://legislature.vermont.gov/people/single/2022/14549",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-9-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/14549"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c028faa3-479d-440c-b2a5-e4320933c10f",
            "firstName": "Sarah",
            "lastName": "Austin",
            "fullName": "Sarita Austin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Austin.Sarah.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 19",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 19-lower-ocd-person-c028faa3-479d-440c-b2a5-e4320933c10f",
              "builtID": "vt-lower-chittenden-19",
              "externalID": "ocd-person/c028faa3-479d-440c-b2a5-e4320933c10f",
              "geometry": null
            },
            "contactInfo": {
              "email": "saustin@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30952",
                "https://legislature.vermont.gov/people/single/2022/30952",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-9-2.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-19.pdf",
                "https://legislature.vermont.gov/people/single/2024/30952"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 19-lower-ocd-person-44bcd67b-5b5f-4fca-a61a-20f91072c5e7": 0,
          "SLDL-VT-Chittenden 19-lower-ocd-person-c028faa3-479d-440c-b2a5-e4320933c10f": 1
        }
      },
      "Rutland 4": {
        "members": [
          {
            "API_ID": "ocd-person/853db6f7-8758-4f02-b121-5ff15f0746b8",
            "firstName": "Paul",
            "lastName": "Clifford",
            "fullName": "Paul Clifford",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Clifford,Paul.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland 4",
              "chamber": "lower",
              "hashID": "SLDL-VT-Rutland 4-lower-ocd-person-853db6f7-8758-4f02-b121-5ff15f0746b8",
              "builtID": "vt-lower-rutland-4",
              "externalID": "ocd-person/853db6f7-8758-4f02-b121-5ff15f0746b8",
              "geometry": null
            },
            "contactInfo": {
              "email": "pclifford@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Rutland-4.pdf",
                "https://legislature.vermont.gov/people/single/2024/37380"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Rutland 4-lower-ocd-person-853db6f7-8758-4f02-b121-5ff15f0746b8": 0
        }
      },
      "Franklin 7": {
        "members": [
          {
            "API_ID": "ocd-person/7d3fc311-0a19-48c1-a1ff-d8e27f521479",
            "firstName": "Allen R.",
            "lastName": "Demar",
            "fullName": "Penny Demar",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Demar,Allen.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Franklin 7",
              "chamber": "lower",
              "hashID": "SLDL-VT-Franklin 7-lower-ocd-person-7d3fc311-0a19-48c1-a1ff-d8e27f521479",
              "builtID": "vt-lower-franklin-7",
              "externalID": "ocd-person/7d3fc311-0a19-48c1-a1ff-d8e27f521479",
              "geometry": null
            },
            "contactInfo": {
              "email": "ademar@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Franklin-7.pdf",
                "https://legislature.vermont.gov/people/single/2024/37386"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Franklin 7-lower-ocd-person-7d3fc311-0a19-48c1-a1ff-d8e27f521479": 0
        }
      },
      "Addison 2": {
        "members": [
          {
            "API_ID": "ocd-person/842ac286-38fb-428e-af54-1176d06b6144",
            "firstName": "Peter",
            "lastName": "Conlon",
            "fullName": "Peter Conlon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/PConlon.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Addison 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Addison 2-lower-ocd-person-842ac286-38fb-428e-af54-1176d06b6144",
              "builtID": "vt-lower-addison-2",
              "externalID": "ocd-person/842ac286-38fb-428e-af54-1176d06b6144",
              "geometry": null
            },
            "contactInfo": {
              "email": "pconlon@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/27155",
                "https://legislature.vermont.gov/people/single/2022/27155",
                "https://legislature.vermont.gov/people/single/2022/27155",
                "https://legislature.vermont.gov/Documents/SmallMaps/ADD-2.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Addison-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/27155"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Addison 2-lower-ocd-person-842ac286-38fb-428e-af54-1176d06b6144": 0
        }
      },
      "Chittenden 4": {
        "members": [
          {
            "API_ID": "ocd-person/424e2c2d-e874-444f-8a1e-e64e2be1b001",
            "firstName": "Phillip",
            "lastName": "Pouech",
            "fullName": "Phil Pouech",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Pouech,Phil.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden 4",
              "chamber": "lower",
              "hashID": "SLDL-VT-Chittenden 4-lower-ocd-person-424e2c2d-e874-444f-8a1e-e64e2be1b001",
              "builtID": "vt-lower-chittenden-4",
              "externalID": "ocd-person/424e2c2d-e874-444f-8a1e-e64e2be1b001",
              "geometry": null
            },
            "contactInfo": {
              "email": "ppouech@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Chittenden-4.pdf",
                "https://legislature.vermont.gov/people/single/2024/37365"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Chittenden 4-lower-ocd-person-424e2c2d-e874-444f-8a1e-e64e2be1b001": 0
        }
      },
      "Caledonia Essex": {
        "members": [
          {
            "API_ID": "ocd-person/b64a9bcc-5c1d-4e8b-931f-81f89ea5137e",
            "firstName": "R. Scott",
            "lastName": "Campbell",
            "fullName": "Scott Campbell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Campbell.Scott.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Caledonia Essex",
              "chamber": "lower",
              "hashID": "SLDL-VT-Caledonia Essex-lower-ocd-person-b64a9bcc-5c1d-4e8b-931f-81f89ea5137e",
              "builtID": "vt-lower-caledonia-essex",
              "externalID": "ocd-person/b64a9bcc-5c1d-4e8b-931f-81f89ea5137e",
              "geometry": null
            },
            "contactInfo": {
              "email": "scampbell@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30946",
                "https://legislature.vermont.gov/people/single/2022/30946",
                "https://legislature.vermont.gov/people/single/2022/30946",
                "https://legislature.vermont.gov/Documents/SmallMaps/CAL-3.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Caledonia-Essex.pdf",
                "https://legislature.vermont.gov/people/single/2024/30946"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d626c3d1-769d-456c-a073-f1efb326fe1d",
            "firstName": "Scott",
            "lastName": "Beck",
            "fullName": "Scott Beck",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/SBeck.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Caledonia Essex",
              "chamber": "lower",
              "hashID": "SLDL-VT-Caledonia Essex-lower-ocd-person-d626c3d1-769d-456c-a073-f1efb326fe1d",
              "builtID": "vt-lower-caledonia-essex",
              "externalID": "ocd-person/d626c3d1-769d-456c-a073-f1efb326fe1d",
              "geometry": null
            },
            "contactInfo": {
              "email": "sbeck@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/23996",
                "https://legislature.vermont.gov/people/single/2022/23996",
                "https://legislature.vermont.gov/Documents/SmallMaps/CAL-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/23996"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Caledonia Essex-lower-ocd-person-b64a9bcc-5c1d-4e8b-931f-81f89ea5137e": 0,
          "SLDL-VT-Caledonia Essex-lower-ocd-person-d626c3d1-769d-456c-a073-f1efb326fe1d": 1
        }
      },
      "Rutland Bennington": {
        "members": [
          {
            "API_ID": "ocd-person/2f45cec4-4094-4a82-a11e-66e8527bb0d0",
            "firstName": "Robin",
            "lastName": "Chesnut-Tangerman",
            "fullName": "Robin Chesnut-Tangerman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Chesnut-Tangerman,Robin.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland Bennington",
              "chamber": "lower",
              "hashID": "SLDL-VT-Rutland Bennington-lower-ocd-person-2f45cec4-4094-4a82-a11e-66e8527bb0d0",
              "builtID": "vt-lower-rutland-bennington",
              "externalID": "ocd-person/2f45cec4-4094-4a82-a11e-66e8527bb0d0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rchesnut-tangerman@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Rutland-Bennington.pdf",
                "https://legislature.vermont.gov/people/single/2024/15774",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windsor-6.pdf",
                "https://legislature.vermont.gov/people/single/2024/37403"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Rutland Bennington-lower-ocd-person-2f45cec4-4094-4a82-a11e-66e8527bb0d0": 0
        }
      },
      "Orange 3": {
        "members": [
          {
            "API_ID": "ocd-person/1a110b8d-4a65-4070-a674-3c3316b3087d",
            "firstName": "Rodney",
            "lastName": "Graham",
            "fullName": "Rodney Graham",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Graham.Rodney.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Orange 3",
              "chamber": "lower",
              "hashID": "SLDL-VT-Orange 3-lower-ocd-person-1a110b8d-4a65-4070-a674-3c3316b3087d",
              "builtID": "vt-lower-orange-3",
              "externalID": "ocd-person/1a110b8d-4a65-4070-a674-3c3316b3087d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rgraham@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/24008",
                "https://legislature.vermont.gov/people/single/2022/24008",
                "https://legislature.vermont.gov/Documents/SmallMaps/ORA-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Orange-3.pdf",
                "https://legislature.vermont.gov/people/single/2024/24008"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Orange 3-lower-ocd-person-1a110b8d-4a65-4070-a674-3c3316b3087d": 0
        }
      },
      "Windham 1": {
        "members": [
          {
            "API_ID": "ocd-person/64c5c421-b168-478c-ba1e-70991679eb39",
            "firstName": "Sara",
            "lastName": "Coffey",
            "fullName": "Sara Coffey",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Coffey,Sara.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windham 1",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windham 1-lower-ocd-person-64c5c421-b168-478c-ba1e-70991679eb39",
              "builtID": "vt-lower-windham-1",
              "externalID": "ocd-person/64c5c421-b168-478c-ba1e-70991679eb39",
              "geometry": null
            },
            "contactInfo": {
              "email": "scoffey@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30971",
                "https://legislature.vermont.gov/people/single/2022/30971",
                "https://legislature.vermont.gov/people/single/2022/30971",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDH-1.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windham-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/30971"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windham 1-lower-ocd-person-64c5c421-b168-478c-ba1e-70991679eb39": 0
        }
      },
      "Rutland 9": {
        "members": [
          {
            "API_ID": "ocd-person/7ad3e2d3-1251-4af4-858d-4a168274fa9b",
            "firstName": "Stephanie Zak",
            "lastName": "Jerome",
            "fullName": "Stephanie Jerome",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Jerome,Stephanie.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland 9",
              "chamber": "lower",
              "hashID": "SLDL-VT-Rutland 9-lower-ocd-person-7ad3e2d3-1251-4af4-858d-4a168274fa9b",
              "builtID": "vt-lower-rutland-9",
              "externalID": "ocd-person/7ad3e2d3-1251-4af4-858d-4a168274fa9b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sjerome@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30966",
                "https://legislature.vermont.gov/people/single/2022/30966",
                "https://legislature.vermont.gov/Documents/SmallMaps/RUT-6.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Rutland-9.pdf",
                "https://legislature.vermont.gov/people/single/2024/30966"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Rutland 9-lower-ocd-person-7ad3e2d3-1251-4af4-858d-4a168274fa9b": 0
        }
      },
      "Essex Caledonia": {
        "members": [
          {
            "API_ID": "ocd-person/3ccfc509-4351-477a-869a-849eb6f10a5d",
            "firstName": "Terri Lynn",
            "lastName": "Williams",
            "fullName": "Terri Williams",
            "gender": "Female",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Williams.Terri.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Essex Caledonia",
              "chamber": "lower",
              "hashID": "SLDL-VT-Essex Caledonia-lower-ocd-person-3ccfc509-4351-477a-869a-849eb6f10a5d",
              "builtID": "vt-lower-essex-caledonia",
              "externalID": "ocd-person/3ccfc509-4351-477a-869a-849eb6f10a5d",
              "geometry": null
            },
            "contactInfo": {
              "email": "twilliams@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34711",
                "https://legislature.vermont.gov/people/single/2022/34711",
                "https://legislature.vermont.gov/Documents/SmallMaps/ESX-CAL.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Essex-Caledonia.pdf",
                "https://legislature.vermont.gov/people/single/2024/34711"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Essex Caledonia-lower-ocd-person-3ccfc509-4351-477a-869a-849eb6f10a5d": 0
        }
      },
      "Windsor 5": {
        "members": [
          {
            "API_ID": "ocd-person/692d0afe-6f0e-4fd1-80de-fe2a4f451c92",
            "firstName": "Tesha",
            "lastName": "Buss",
            "fullName": "Tesha Buss",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Buss,Tesha.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor 5",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windsor 5-lower-ocd-person-692d0afe-6f0e-4fd1-80de-fe2a4f451c92",
              "builtID": "vt-lower-windsor-5",
              "externalID": "ocd-person/692d0afe-6f0e-4fd1-80de-fe2a4f451c92",
              "geometry": null
            },
            "contactInfo": {
              "email": "tbuss@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windsor-5.pdf",
                "https://legislature.vermont.gov/people/single/2024/37369"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windsor 5-lower-ocd-person-692d0afe-6f0e-4fd1-80de-fe2a4f451c92": 0
        }
      },
      "Washington Chittenden": {
        "members": [
          {
            "API_ID": "ocd-person/f68d3f17-24ca-4b87-8837-6a30737d21d8",
            "firstName": "Theresa A.",
            "lastName": "Wood",
            "fullName": "Theresa Wood",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Wood.Theresa.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington Chittenden",
              "chamber": "lower",
              "hashID": "SLDL-VT-Washington Chittenden-lower-ocd-person-f68d3f17-24ca-4b87-8837-6a30737d21d8",
              "builtID": "vt-lower-washington-chittenden",
              "externalID": "ocd-person/f68d3f17-24ca-4b87-8837-6a30737d21d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "twood@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/25699",
                "https://legislature.vermont.gov/people/single/2022/25699",
                "https://legislature.vermont.gov/Documents/SmallMaps/WAS-CHI.pdf",
                "https://legislature.vermont.gov/people/single/2024/25699"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0acb9a2c-6cbb-44b3-828c-4c7ad3fd8ce6",
            "firstName": "Thomas S.",
            "lastName": "Stevens",
            "fullName": "Tom Stevens",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Stevens.Thomas.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington Chittenden",
              "chamber": "lower",
              "hashID": "SLDL-VT-Washington Chittenden-lower-ocd-person-0acb9a2c-6cbb-44b3-828c-4c7ad3fd8ce6",
              "builtID": "vt-lower-washington-chittenden",
              "externalID": "ocd-person/0acb9a2c-6cbb-44b3-828c-4c7ad3fd8ce6",
              "geometry": null
            },
            "contactInfo": {
              "email": "tstevens@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14689",
                "https://legislature.vermont.gov/people/single/2022/14689",
                "https://legislature.vermont.gov/Documents/SmallMaps/WAS-CHI.pdf",
                "https://legislature.vermont.gov/people/single/2024/14689"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Washington Chittenden-lower-ocd-person-f68d3f17-24ca-4b87-8837-6a30737d21d8": 0,
          "SLDL-VT-Washington Chittenden-lower-ocd-person-0acb9a2c-6cbb-44b3-828c-4c7ad3fd8ce6": 1
        }
      },
      "Windham 6": {
        "members": [
          {
            "API_ID": "ocd-person/36ac8304-67e7-4971-955c-fdf586588c1b",
            "firstName": "Tristan",
            "lastName": "Roberts",
            "fullName": "Tristan Roberts",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Roberts,Tristan.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windham 6",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windham 6-lower-ocd-person-36ac8304-67e7-4971-955c-fdf586588c1b",
              "builtID": "vt-lower-windham-6",
              "externalID": "ocd-person/36ac8304-67e7-4971-955c-fdf586588c1b",
              "geometry": null
            },
            "contactInfo": {
              "email": "troberts@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windham-6.pdf",
                "https://legislature.vermont.gov/people/single/2024/37371"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windham 6-lower-ocd-person-36ac8304-67e7-4971-955c-fdf586588c1b": 0
        }
      },
      "Windham 9": {
        "members": [
          {
            "API_ID": "ocd-person/e4716ff3-5ee2-4fe4-9822-4db560d0c8d8",
            "firstName": "Tristan D.",
            "lastName": "Toleno",
            "fullName": "Tristan Toleno",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Toleno,Tristan.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windham 9",
              "chamber": "lower",
              "hashID": "SLDL-VT-Windham 9-lower-ocd-person-e4716ff3-5ee2-4fe4-9822-4db560d0c8d8",
              "builtID": "vt-lower-windham-9",
              "externalID": "ocd-person/e4716ff3-5ee2-4fe4-9822-4db560d0c8d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "ttoleno@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/20391",
                "https://legislature.vermont.gov/people/single/2022/20391",
                "https://legislature.vermont.gov/people/single/2022/20391",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDH-2-3.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Windham-9.pdf",
                "https://legislature.vermont.gov/people/single/2024/20391"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Windham 9-lower-ocd-person-e4716ff3-5ee2-4fe4-9822-4db560d0c8d8": 0
        }
      },
      "Rutland 10": {
        "members": [
          {
            "API_ID": "ocd-person/c66f104c-310a-40a1-9222-6d366b42e576",
            "firstName": "William P.",
            "lastName": "Canfield",
            "fullName": "Bill Canfield",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Canfield.William.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland 10",
              "chamber": "lower",
              "hashID": "SLDL-VT-Rutland 10-lower-ocd-person-c66f104c-310a-40a1-9222-6d366b42e576",
              "builtID": "vt-lower-rutland-10",
              "externalID": "ocd-person/c66f104c-310a-40a1-9222-6d366b42e576",
              "geometry": null
            },
            "contactInfo": {
              "email": "wcanfield@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14569",
                "https://legislature.vermont.gov/people/single/2022/14569",
                "https://legislature.vermont.gov/Documents/SmallMaps/RUT-3.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Rutland-10.pdf",
                "https://legislature.vermont.gov/people/single/2024/14569"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Rutland 10-lower-ocd-person-c66f104c-310a-40a1-9222-6d366b42e576": 0
        }
      },
      "Rutland 7": {
        "members": [
          {
            "API_ID": "ocd-person/bcff864b-5660-4a36-a6c1-e16a732fae97",
            "firstName": "William",
            "lastName": "Notte",
            "fullName": "William Notte",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Notte.William.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland 7",
              "chamber": "lower",
              "hashID": "SLDL-VT-Rutland 7-lower-ocd-person-bcff864b-5660-4a36-a6c1-e16a732fae97",
              "builtID": "vt-lower-rutland-7",
              "externalID": "ocd-person/bcff864b-5660-4a36-a6c1-e16a732fae97",
              "geometry": null
            },
            "contactInfo": {
              "email": "wnotte@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30965",
                "https://legislature.vermont.gov/people/single/2022/30965",
                "https://legislature.vermont.gov/people/single/2022/30965",
                "https://legislature.vermont.gov/Documents/SmallMaps/RUT-5-4.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Rutland-7.pdf",
                "https://legislature.vermont.gov/people/single/2024/30965"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Rutland 7-lower-ocd-person-bcff864b-5660-4a36-a6c1-e16a732fae97": 0
        }
      },
      "Orleans 2": {
        "members": [
          {
            "API_ID": "ocd-person/0c2d8e34-55f1-4b34-a6a3-bd7708d2578c",
            "firstName": "Woodman H.",
            "lastName": "Page",
            "fullName": "Woody Page",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Page,%20Woody.jpg",
            "title": "VT Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Orleans 2",
              "chamber": "lower",
              "hashID": "SLDL-VT-Orleans 2-lower-ocd-person-0c2d8e34-55f1-4b34-a6a3-bd7708d2578c",
              "builtID": "vt-lower-orleans-2",
              "externalID": "ocd-person/0c2d8e34-55f1-4b34-a6a3-bd7708d2578c",
              "geometry": null
            },
            "contactInfo": {
              "email": "wpage@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30964",
                "https://legislature.vermont.gov/people/single/2022/30964",
                "https://legislature.vermont.gov/Documents/SmallMaps/ORL-2.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/House/Orleans-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/30964"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VT-Orleans 2-lower-ocd-person-0c2d8e34-55f1-4b34-a6a3-bd7708d2578c": 0
        }
      }
    },
    "SLDU": {
      "Windsor": {
        "members": [
          {
            "API_ID": "ocd-person/a366bc32-20d8-41a8-a2cf-939650b7a2c2",
            "firstName": "Alison Hudnut",
            "lastName": "Clarkson",
            "fullName": "Alison Clarkson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Clarkson,Alison.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor",
              "chamber": "upper",
              "hashID": "SLDU-VT-Windsor-upper-ocd-person-a366bc32-20d8-41a8-a2cf-939650b7a2c2",
              "builtID": "vt-upper-windsor",
              "externalID": "ocd-person/a366bc32-20d8-41a8-a2cf-939650b7a2c2",
              "geometry": null
            },
            "contactInfo": {
              "email": "aclarkson@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/27152",
                "https://legislature.vermont.gov/people/single/2022/27152",
                "https://legislature.vermont.gov/people/single/2022/27152",
                "https://legislature.vermont.gov/Documents/SmallMaps/WSRDIST.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/Senate/Windsor.pdf",
                "https://legislature.vermont.gov/people/single/2024/27152"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/3287ebb4-7592-484d-8194-d44d886e0d41",
            "firstName": "Richard John",
            "lastName": "McCormack",
            "fullName": "Richard McCormack",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/RMccormack.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor",
              "chamber": "upper",
              "hashID": "SLDU-VT-Windsor-upper-ocd-person-3287ebb4-7592-484d-8194-d44d886e0d41",
              "builtID": "vt-upper-windsor",
              "externalID": "ocd-person/3287ebb4-7592-484d-8194-d44d886e0d41",
              "geometry": null
            },
            "contactInfo": {
              "email": "rmccormack@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14625",
                "https://legislature.vermont.gov/people/single/2022/14625",
                "https://legislature.vermont.gov/Documents/SmallMaps/WSRDIST.pdf",
                "https://legislature.vermont.gov/people/single/2024/14625"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d798a240-afe8-451d-8b56-9d2badd4820c",
            "firstName": "Rebecca",
            "lastName": "White",
            "fullName": "Becca White",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/White.Becca.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windsor",
              "chamber": "upper",
              "hashID": "SLDU-VT-Windsor-upper-ocd-person-d798a240-afe8-451d-8b56-9d2badd4820c",
              "builtID": "vt-upper-windsor",
              "externalID": "ocd-person/d798a240-afe8-451d-8b56-9d2badd4820c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rwhite@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30976",
                "https://legislature.vermont.gov/people/single/2022/30976",
                "https://legislature.vermont.gov/Documents/SmallMaps/WDR-4-2.pdf",
                "https://legislature.vermont.gov/people/single/2024/37414"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VT-Windsor-upper-ocd-person-a366bc32-20d8-41a8-a2cf-939650b7a2c2": 0,
          "SLDU-VT-Windsor-upper-ocd-person-3287ebb4-7592-484d-8194-d44d886e0d41": 1,
          "SLDU-VT-Windsor-upper-ocd-person-d798a240-afe8-451d-8b56-9d2badd4820c": 2
        }
      },
      "Washington": {
        "members": [
          {
            "API_ID": "ocd-person/1463485e-f279-44f6-a368-f75f213dc7cd",
            "firstName": "Andrew John",
            "lastName": "Perchlik",
            "fullName": "Andrew Perchlik",
            "gender": "Male",
            "party": "democratic/progressive",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Perchlik.Andrew.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington",
              "chamber": "upper",
              "hashID": "SLDU-VT-Washington-upper-ocd-person-1463485e-f279-44f6-a368-f75f213dc7cd",
              "builtID": "vt-upper-washington",
              "externalID": "ocd-person/1463485e-f279-44f6-a368-f75f213dc7cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "aperchlik@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/30982",
                "https://legislature.vermont.gov/Documents/SmallMaps/WASDIST.pdf",
                "https://legislature.vermont.gov/people/single/2024/30982"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c34f492a-7cdf-4830-a28a-0ff2fe7c8f71",
            "firstName": "Ann E.",
            "lastName": "Cummings",
            "fullName": "Ann Cummings",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Cummings.Ann.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington",
              "chamber": "upper",
              "hashID": "SLDU-VT-Washington-upper-ocd-person-c34f492a-7cdf-4830-a28a-0ff2fe7c8f71",
              "builtID": "vt-upper-washington",
              "externalID": "ocd-person/c34f492a-7cdf-4830-a28a-0ff2fe7c8f71",
              "geometry": null
            },
            "contactInfo": {
              "email": "acummings@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/14604",
                "https://legislature.vermont.gov/Documents/SmallMaps/WASDIST.pdf",
                "https://legislature.vermont.gov/people/single/2024/14604"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/70fa5e7e-8ab1-4599-a881-61d12120637c",
            "firstName": "Anne Elizabeth",
            "lastName": "Watson",
            "fullName": "Anne Watson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Watson,Anne.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Washington",
              "chamber": "upper",
              "hashID": "SLDU-VT-Washington-upper-ocd-person-70fa5e7e-8ab1-4599-a881-61d12120637c",
              "builtID": "vt-upper-washington",
              "externalID": "ocd-person/70fa5e7e-8ab1-4599-a881-61d12120637c",
              "geometry": null
            },
            "contactInfo": {
              "email": "awatson@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/Senate/Washington.pdf",
                "https://legislature.vermont.gov/people/single/2024/37411"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VT-Washington-upper-ocd-person-1463485e-f279-44f6-a368-f75f213dc7cd": 0,
          "SLDU-VT-Washington-upper-ocd-person-c34f492a-7cdf-4830-a28a-0ff2fe7c8f71": 1,
          "SLDU-VT-Washington-upper-ocd-person-70fa5e7e-8ab1-4599-a881-61d12120637c": 2
        }
      },
      "Bennington": {
        "members": [
          {
            "API_ID": "ocd-person/5e6451d0-e410-48e5-854a-bcd0a0a5b3c0",
            "firstName": "Brian A.",
            "lastName": "Campion",
            "fullName": "Brian Campion",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Campion.Brian.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Bennington",
              "chamber": "upper",
              "hashID": "SLDU-VT-Bennington-upper-ocd-person-5e6451d0-e410-48e5-854a-bcd0a0a5b3c0",
              "builtID": "vt-upper-bennington",
              "externalID": "ocd-person/5e6451d0-e410-48e5-854a-bcd0a0a5b3c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "bcampion@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/24030",
                "https://legislature.vermont.gov/people/single/2022/24030",
                "https://legislature.vermont.gov/Documents/SmallMaps/BENDIST.pdf",
                "https://legislature.vermont.gov/people/single/2024/24030"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ffba43ff-20d1-492d-a5f7-22855c1fbba4",
            "firstName": "Richard Warden",
            "lastName": "Sears",
            "fullName": "Dick Sears",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/RSears.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Bennington",
              "chamber": "upper",
              "hashID": "SLDU-VT-Bennington-upper-ocd-person-ffba43ff-20d1-492d-a5f7-22855c1fbba4",
              "builtID": "vt-upper-bennington",
              "externalID": "ocd-person/ffba43ff-20d1-492d-a5f7-22855c1fbba4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rsears@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14613",
                "https://legislature.vermont.gov/people/single/2022/14613",
                "https://legislature.vermont.gov/Documents/SmallMaps/BENDIST.pdf",
                "https://legislature.vermont.gov/people/single/2024/14613"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VT-Bennington-upper-ocd-person-5e6451d0-e410-48e5-854a-bcd0a0a5b3c0": 0,
          "SLDU-VT-Bennington-upper-ocd-person-ffba43ff-20d1-492d-a5f7-22855c1fbba4": 1
        }
      },
      "Rutland": {
        "members": [
          {
            "API_ID": "ocd-person/d4ead076-6425-4ab7-ad32-b10dce660170",
            "firstName": "Brian P.",
            "lastName": "Collamore",
            "fullName": "Brian Collamore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Collamore.Brian.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland",
              "chamber": "upper",
              "hashID": "SLDU-VT-Rutland-upper-ocd-person-d4ead076-6425-4ab7-ad32-b10dce660170",
              "builtID": "vt-upper-rutland",
              "externalID": "ocd-person/d4ead076-6425-4ab7-ad32-b10dce660170",
              "geometry": null
            },
            "contactInfo": {
              "email": "bcollamore@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/24031",
                "https://legislature.vermont.gov/people/single/2022/24031",
                "https://legislature.vermont.gov/Documents/SmallMaps/RUTDIST.pdf",
                "https://legislature.vermont.gov/people/single/2024/24031"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f076a53a-1ebd-485d-8d0f-adb7cf0b00be",
            "firstName": "David Hart",
            "lastName": "Weeks",
            "fullName": "Dave Weeks",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Weeks,%20David.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland",
              "chamber": "upper",
              "hashID": "SLDU-VT-Rutland-upper-ocd-person-f076a53a-1ebd-485d-8d0f-adb7cf0b00be",
              "builtID": "vt-upper-rutland",
              "externalID": "ocd-person/f076a53a-1ebd-485d-8d0f-adb7cf0b00be",
              "geometry": null
            },
            "contactInfo": {
              "email": "dweeks@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/Senate/Rutland.pdf",
                "https://legislature.vermont.gov/people/single/2024/37412"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ba524e3d-a578-4498-8226-6e947840c69f",
            "firstName": "Terry",
            "lastName": "Williams",
            "fullName": "Terry Williams",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Williams,Terry.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Rutland",
              "chamber": "upper",
              "hashID": "SLDU-VT-Rutland-upper-ocd-person-ba524e3d-a578-4498-8226-6e947840c69f",
              "builtID": "vt-upper-rutland",
              "externalID": "ocd-person/ba524e3d-a578-4498-8226-6e947840c69f",
              "geometry": null
            },
            "contactInfo": {
              "email": "tkwilliams@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/Senate/Rutland.pdf",
                "https://legislature.vermont.gov/people/single/2024/37413"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VT-Rutland-upper-ocd-person-d4ead076-6425-4ab7-ad32-b10dce660170": 0,
          "SLDU-VT-Rutland-upper-ocd-person-f076a53a-1ebd-485d-8d0f-adb7cf0b00be": 1,
          "SLDU-VT-Rutland-upper-ocd-person-ba524e3d-a578-4498-8226-6e947840c69f": 2
        }
      },
      "Addison": {
        "members": [
          {
            "API_ID": "ocd-person/932e6a17-d8a5-4573-b2a4-8f5ae59300c6",
            "firstName": "Christopher A.",
            "lastName": "Bray",
            "fullName": "Chris Bray",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Bray.Christopher.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Addison",
              "chamber": "upper",
              "hashID": "SLDU-VT-Addison-upper-ocd-person-932e6a17-d8a5-4573-b2a4-8f5ae59300c6",
              "builtID": "vt-upper-addison",
              "externalID": "ocd-person/932e6a17-d8a5-4573-b2a4-8f5ae59300c6",
              "geometry": null
            },
            "contactInfo": {
              "email": "cbray@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/20396",
                "https://legislature.vermont.gov/people/single/2022/20396",
                "https://legislature.vermont.gov/Documents/SmallMaps/ADDDIST.pdf",
                "https://legislature.vermont.gov/people/single/2024/20396"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/84f2c6bd-5fe7-486c-b2ad-555bf69654e8",
            "firstName": "Ruth",
            "lastName": "Hardy",
            "fullName": "Ruth Hardy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Hardy.Ruth.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Addison",
              "chamber": "upper",
              "hashID": "SLDU-VT-Addison-upper-ocd-person-84f2c6bd-5fe7-486c-b2ad-555bf69654e8",
              "builtID": "vt-upper-addison",
              "externalID": "ocd-person/84f2c6bd-5fe7-486c-b2ad-555bf69654e8",
              "geometry": null
            },
            "contactInfo": {
              "email": "rhardy@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30978",
                "https://legislature.vermont.gov/people/single/2022/30978",
                "https://legislature.vermont.gov/Documents/SmallMaps/ADDDIST.pdf",
                "https://legislature.vermont.gov/people/single/2024/30978"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VT-Addison-upper-ocd-person-932e6a17-d8a5-4573-b2a4-8f5ae59300c6": 0,
          "SLDU-VT-Addison-upper-ocd-person-84f2c6bd-5fe7-486c-b2ad-555bf69654e8": 1
        }
      },
      "Grand Isle": {
        "members": [
          {
            "API_ID": "ocd-person/1acec5ba-9c21-4166-9307-d6958608fb41",
            "firstName": "Richard Thomas",
            "lastName": "Mazza",
            "fullName": "Dick Mazza",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Mazza.Dick.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Grand Isle",
              "chamber": "upper",
              "hashID": "SLDU-VT-Grand Isle-upper-ocd-person-1acec5ba-9c21-4166-9307-d6958608fb41",
              "builtID": "vt-upper-grand isle",
              "externalID": "ocd-person/1acec5ba-9c21-4166-9307-d6958608fb41",
              "geometry": null
            },
            "contactInfo": {
              "email": "rmazza@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14610",
                "https://legislature.vermont.gov/people/single/2022/14610",
                "https://legislature.vermont.gov/people/single/2022/14610",
                "https://legislature.vermont.gov/Documents/SmallMaps/CGIDIST.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/Senate/Grand%20Isle.pdf",
                "https://legislature.vermont.gov/people/single/2024/14610"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VT-Grand Isle-upper-ocd-person-1acec5ba-9c21-4166-9307-d6958608fb41": 0
        }
      },
      "Chittenden North": {
        "members": [
          {
            "API_ID": "ocd-person/92538354-4e30-40ce-b89d-fe256843a9ba",
            "firstName": "Irene Ava",
            "lastName": "Wrenner",
            "fullName": "Irene Wrenner",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden North",
              "chamber": "upper",
              "hashID": "SLDU-VT-Chittenden North-upper-ocd-person-92538354-4e30-40ce-b89d-fe256843a9ba",
              "builtID": "vt-upper-chittenden north",
              "externalID": "ocd-person/92538354-4e30-40ce-b89d-fe256843a9ba",
              "geometry": null
            },
            "contactInfo": {
              "email": "iwrenner@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2024/37415"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VT-Chittenden North-upper-ocd-person-92538354-4e30-40ce-b89d-fe256843a9ba": 0
        }
      },
      "Caledonia": {
        "members": [
          {
            "API_ID": "ocd-person/34b76b5d-83b0-4e3e-9152-8ba48800399b",
            "firstName": "Martha Jane Beattie",
            "lastName": "Kitchel",
            "fullName": "Jane Kitchel",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Kitchel.Jane.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Caledonia",
              "chamber": "upper",
              "hashID": "SLDU-VT-Caledonia-upper-ocd-person-34b76b5d-83b0-4e3e-9152-8ba48800399b",
              "builtID": "vt-upper-caledonia",
              "externalID": "ocd-person/34b76b5d-83b0-4e3e-9152-8ba48800399b",
              "geometry": null
            },
            "contactInfo": {
              "email": "jkitchel@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14622",
                "https://legislature.vermont.gov/people/single/2022/14622",
                "https://legislature.vermont.gov/people/single/2022/14622",
                "https://legislature.vermont.gov/Documents/SmallMaps/CALDIST.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/Senate/Caledonia.pdf",
                "https://legislature.vermont.gov/people/single/2024/14616",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/Senate/Chittenden-Southeast.pdf",
                "https://legislature.vermont.gov/people/single/2024/14622"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VT-Caledonia-upper-ocd-person-34b76b5d-83b0-4e3e-9152-8ba48800399b": 0
        }
      },
      "Chittenden Southeast": {
        "members": [
          {
            "API_ID": "ocd-person/06f7e87d-e1f3-4d29-bd2c-11cadb89069e",
            "firstName": "Kesha K.",
            "lastName": "Ram Hinsdale",
            "fullName": "Kesha Ram Hinsdale",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden Southeast",
              "chamber": "upper",
              "hashID": "SLDU-VT-Chittenden Southeast-upper-ocd-person-06f7e87d-e1f3-4d29-bd2c-11cadb89069e",
              "builtID": "vt-upper-chittenden southeast",
              "externalID": "ocd-person/06f7e87d-e1f3-4d29-bd2c-11cadb89069e",
              "geometry": null
            },
            "contactInfo": {
              "email": "kramhinsdale@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2024/34727"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c3b03c7d-9be1-4da5-994a-aa2980ae35b1",
            "firstName": "Thomas Ira",
            "lastName": "Chittenden",
            "fullName": "Thomas Chittenden",
            "gender": "Male",
            "party": "Democrat",
            "image": null,
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden Southeast",
              "chamber": "upper",
              "hashID": "SLDU-VT-Chittenden Southeast-upper-ocd-person-c3b03c7d-9be1-4da5-994a-aa2980ae35b1",
              "builtID": "vt-upper-chittenden southeast",
              "externalID": "ocd-person/c3b03c7d-9be1-4da5-994a-aa2980ae35b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "tchittenden@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34724",
                "https://legislature.vermont.gov/people/single/2022/34724",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHIDIST.pdf",
                "https://legislature.vermont.gov/people/single/2024/34724"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/91dafbcc-d313-4ef3-a506-0113d4ce1975",
            "firstName": "Virginia V.",
            "lastName": "Lyons",
            "fullName": "Ginny Lyons",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Lyons.Ginny.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden Southeast",
              "chamber": "upper",
              "hashID": "SLDU-VT-Chittenden Southeast-upper-ocd-person-91dafbcc-d313-4ef3-a506-0113d4ce1975",
              "builtID": "vt-upper-chittenden southeast",
              "externalID": "ocd-person/91dafbcc-d313-4ef3-a506-0113d4ce1975",
              "geometry": null
            },
            "contactInfo": {
              "email": "vlyons@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14616",
                "https://legislature.vermont.gov/people/single/2022/14616",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHIDIST.pdf",
                "https://legislature.vermont.gov/people/single/2024/14616"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VT-Chittenden Southeast-upper-ocd-person-06f7e87d-e1f3-4d29-bd2c-11cadb89069e": 0,
          "SLDU-VT-Chittenden Southeast-upper-ocd-person-c3b03c7d-9be1-4da5-994a-aa2980ae35b1": 1,
          "SLDU-VT-Chittenden Southeast-upper-ocd-person-91dafbcc-d313-4ef3-a506-0113d4ce1975": 2
        }
      },
      "Orange": {
        "members": [
          {
            "API_ID": "ocd-person/a2922c8e-7f90-44ec-a5e6-63daf45d9c25",
            "firstName": "Mark Alexander",
            "lastName": "MacDonald",
            "fullName": "Mark MacDonald",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/MacDonald,Mark.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Orange",
              "chamber": "upper",
              "hashID": "SLDU-VT-Orange-upper-ocd-person-a2922c8e-7f90-44ec-a5e6-63daf45d9c25",
              "builtID": "vt-upper-orange",
              "externalID": "ocd-person/a2922c8e-7f90-44ec-a5e6-63daf45d9c25",
              "geometry": null
            },
            "contactInfo": {
              "email": "mmacdonald@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14608",
                "https://legislature.vermont.gov/people/single/2022/14608",
                "https://legislature.vermont.gov/people/single/2022/14608",
                "https://legislature.vermont.gov/Documents/SmallMaps/ORADIST.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/Senate/Orange.pdf",
                "https://legislature.vermont.gov/people/single/2024/14608"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VT-Orange-upper-ocd-person-a2922c8e-7f90-44ec-a5e6-63daf45d9c25": 0
        }
      },
      "Chittenden Central": {
        "members": [
          {
            "API_ID": "ocd-person/a1812863-f6a9-4ce2-8131-d701923dd507",
            "firstName": "Martine Larocque",
            "lastName": "Gulick",
            "fullName": "Martine Gulick",
            "gender": "Female",
            "party": "Democrat",
            "image": null,
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden Central",
              "chamber": "upper",
              "hashID": "SLDU-VT-Chittenden Central-upper-ocd-person-a1812863-f6a9-4ce2-8131-d701923dd507",
              "builtID": "vt-upper-chittenden central",
              "externalID": "ocd-person/a1812863-f6a9-4ce2-8131-d701923dd507",
              "geometry": null
            },
            "contactInfo": {
              "email": "mgulick@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2024/37408"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ba794ad6-45fa-40cc-8642-55e759a94d71",
            "firstName": "Philip E.",
            "lastName": "Baruth",
            "fullName": "Phil Baruth",
            "gender": "Male",
            "party": "democratic/progressive",
            "image": null,
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden Central",
              "chamber": "upper",
              "hashID": "SLDU-VT-Chittenden Central-upper-ocd-person-ba794ad6-45fa-40cc-8642-55e759a94d71",
              "builtID": "vt-upper-chittenden central",
              "externalID": "ocd-person/ba794ad6-45fa-40cc-8642-55e759a94d71",
              "geometry": null
            },
            "contactInfo": {
              "email": "pbaruth@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2024/15791"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/3c832fcc-6a3b-415d-9db8-25b87719082c",
            "firstName": "Tanya C.",
            "lastName": "Vyhovsky",
            "fullName": "Tanya Vyhovsky",
            "gender": "Female",
            "party": "democratic/progressive",
            "image": null,
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Chittenden Central",
              "chamber": "upper",
              "hashID": "SLDU-VT-Chittenden Central-upper-ocd-person-3c832fcc-6a3b-415d-9db8-25b87719082c",
              "builtID": "vt-upper-chittenden central",
              "externalID": "ocd-person/3c832fcc-6a3b-415d-9db8-25b87719082c",
              "geometry": null
            },
            "contactInfo": {
              "email": "tvyhovsky@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34715",
                "https://legislature.vermont.gov/people/single/2022/34715",
                "https://legislature.vermont.gov/Documents/SmallMaps/CHI-8-1.pdf",
                "https://legislature.vermont.gov/people/single/2024/37410"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VT-Chittenden Central-upper-ocd-person-a1812863-f6a9-4ce2-8131-d701923dd507": 0,
          "SLDU-VT-Chittenden Central-upper-ocd-person-ba794ad6-45fa-40cc-8642-55e759a94d71": 1,
          "SLDU-VT-Chittenden Central-upper-ocd-person-3c832fcc-6a3b-415d-9db8-25b87719082c": 2
        }
      },
      "Windham": {
        "members": [
          {
            "API_ID": "ocd-person/4cae5971-93ee-4029-b5ef-4c69574256da",
            "firstName": "Nader",
            "lastName": "Hashim",
            "fullName": "Nader Hashim",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Hashim.Nadar.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windham",
              "chamber": "upper",
              "hashID": "SLDU-VT-Windham-upper-ocd-person-4cae5971-93ee-4029-b5ef-4c69574256da",
              "builtID": "vt-upper-windham",
              "externalID": "ocd-person/4cae5971-93ee-4029-b5ef-4c69574256da",
              "geometry": null
            },
            "contactInfo": {
              "email": "nhashim@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/30973",
                "https://legislature.vermont.gov/people/single/2024/37407"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/5d4b135e-4bed-4f7f-960a-250691fca6a8",
            "firstName": "Wendy",
            "lastName": "Harrison",
            "fullName": "Wendy Harrison",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Harrison,Wendy.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Windham",
              "chamber": "upper",
              "hashID": "SLDU-VT-Windham-upper-ocd-person-5d4b135e-4bed-4f7f-960a-250691fca6a8",
              "builtID": "vt-upper-windham",
              "externalID": "ocd-person/5d4b135e-4bed-4f7f-960a-250691fca6a8",
              "geometry": null
            },
            "contactInfo": {
              "email": "wharrison@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/Senate/Windham.pdf",
                "https://legislature.vermont.gov/people/single/2024/37406"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VT-Windham-upper-ocd-person-4cae5971-93ee-4029-b5ef-4c69574256da": 0,
          "SLDU-VT-Windham-upper-ocd-person-5d4b135e-4bed-4f7f-960a-250691fca6a8": 1
        }
      },
      "Franklin": {
        "members": [
          {
            "API_ID": "ocd-person/e8028cf6-fe46-49bf-b8fc-6c9bcd1fa020",
            "firstName": "Randolph D.",
            "lastName": "Brock",
            "fullName": "Randy Brock",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/RBrock.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Franklin",
              "chamber": "upper",
              "hashID": "SLDU-VT-Franklin-upper-ocd-person-e8028cf6-fe46-49bf-b8fc-6c9bcd1fa020",
              "builtID": "vt-upper-franklin",
              "externalID": "ocd-person/e8028cf6-fe46-49bf-b8fc-6c9bcd1fa020",
              "geometry": null
            },
            "contactInfo": {
              "email": "rbrock@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/29338",
                "https://legislature.vermont.gov/people/single/2022/29338",
                "https://legislature.vermont.gov/Documents/SmallMaps/FRADIST.pdf",
                "https://legislature.vermont.gov/people/single/2024/29338"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/2f1d3491-871e-4eb6-988b-57d3c9ea7a19",
            "firstName": "Robert W.",
            "lastName": "Norris",
            "fullName": "Bob Norris",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Norris.Robert.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Franklin",
              "chamber": "upper",
              "hashID": "SLDU-VT-Franklin-upper-ocd-person-2f1d3491-871e-4eb6-988b-57d3c9ea7a19",
              "builtID": "vt-upper-franklin",
              "externalID": "ocd-person/2f1d3491-871e-4eb6-988b-57d3c9ea7a19",
              "geometry": null
            },
            "contactInfo": {
              "email": "rnorris@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34702",
                "https://legislature.vermont.gov/people/single/2022/34702",
                "https://legislature.vermont.gov/Documents/SmallMaps/FRA-4.pdf",
                "https://legislature.vermont.gov/people/single/2024/37409"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VT-Franklin-upper-ocd-person-e8028cf6-fe46-49bf-b8fc-6c9bcd1fa020": 0,
          "SLDU-VT-Franklin-upper-ocd-person-2f1d3491-871e-4eb6-988b-57d3c9ea7a19": 1
        }
      },
      "Lamoille": {
        "members": [
          {
            "API_ID": "ocd-person/9a4f5d0d-7c71-4f80-bcf0-da9b4882d0d7",
            "firstName": "Richard A.",
            "lastName": "Westman",
            "fullName": "Richard Westman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/RWestman.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Lamoille",
              "chamber": "upper",
              "hashID": "SLDU-VT-Lamoille-upper-ocd-person-9a4f5d0d-7c71-4f80-bcf0-da9b4882d0d7",
              "builtID": "vt-upper-lamoille",
              "externalID": "ocd-person/9a4f5d0d-7c71-4f80-bcf0-da9b4882d0d7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rwestman@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/15796",
                "https://legislature.vermont.gov/people/single/2022/15796",
                "https://legislature.vermont.gov/people/single/2022/15796",
                "https://legislature.vermont.gov/Documents/SmallMaps/LAMDIST.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/Senate/Lamoille.pdf",
                "https://legislature.vermont.gov/people/single/2024/15796"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VT-Lamoille-upper-ocd-person-9a4f5d0d-7c71-4f80-bcf0-da9b4882d0d7": 0
        }
      },
      "Orleans": {
        "members": [
          {
            "API_ID": "ocd-person/0a318106-59a2-45a3-9d33-27043d5b9277",
            "firstName": "Robert A.",
            "lastName": "Starr",
            "fullName": "Robert Starr",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Starr,Bobby.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Orleans",
              "chamber": "upper",
              "hashID": "SLDU-VT-Orleans-upper-ocd-person-0a318106-59a2-45a3-9d33-27043d5b9277",
              "builtID": "vt-upper-orleans",
              "externalID": "ocd-person/0a318106-59a2-45a3-9d33-27043d5b9277",
              "geometry": null
            },
            "contactInfo": {
              "email": "rstarr@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2020/14614",
                "https://legislature.vermont.gov/people/single/2022/14614",
                "https://legislature.vermont.gov/Documents/SmallMaps/E-ODIST.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/Senate/Orleans.pdf",
                "https://legislature.vermont.gov/people/single/2024/14614"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VT-Orleans-upper-ocd-person-0a318106-59a2-45a3-9d33-27043d5b9277": 0
        }
      },
      "Essex": {
        "members": [
          {
            "API_ID": "ocd-person/ec8b2fa8-32e3-43ef-a8e9-101958b5d652",
            "firstName": "Russ",
            "lastName": "Ingalls",
            "fullName": "Russ Ingalls",
            "gender": "Male",
            "party": "Republican",
            "image": "https://legislature.vermont.gov/Documents/Legislators/Ingalls,%20Russ.jpg",
            "title": "VT Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VT",
              "stateFull": "Vermont",
              "district": "Essex",
              "chamber": "upper",
              "hashID": "SLDU-VT-Essex-upper-ocd-person-ec8b2fa8-32e3-43ef-a8e9-101958b5d652",
              "builtID": "vt-upper-essex",
              "externalID": "ocd-person/ec8b2fa8-32e3-43ef-a8e9-101958b5d652",
              "geometry": null
            },
            "contactInfo": {
              "email": "ringalls@leg.state.vt.us",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legislature.vermont.gov/people/single/2022/34725",
                "https://legislature.vermont.gov/people/single/2022/34725",
                "https://legislature.vermont.gov/Documents/SmallMaps/E-ODIST.pdf",
                "https://legislature.vermont.gov/Documents/SmallMaps/2022/Senate/Essex.pdf",
                "https://legislature.vermont.gov/people/single/2024/34725"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VT-Essex-upper-ocd-person-ec8b2fa8-32e3-43ef-a8e9-101958b5d652": 0
        }
      }
    }
  },
  "VA": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "VA",
          "stateFull": "Virginia",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "VA",
            "stateFull": "Virginia",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-VA---",
            "builtID": "va--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-VA---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "K000384",
          "in_office": true,
          "firstName": "Tim",
          "lastName": "Kaine",
          "middleName": null,
          "fullName": "Tim Kaine",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/K000384.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "VA",
            "stateFull": "Virginia",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-VA---K000384",
            "builtID": "va--",
            "externalID": "K000384",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.kaine.senate.gov/contact",
            "address1": "231 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-4024",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": null,
            "youtube": "SenatorTimKaine",
            "instagram": null,
            "facebook": "SenatorKaine",
            "urls": ["https://www.kaine.senate.gov"],
            "rss_url": null
          },
          "title": "VA Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/K000384.json",
          "govtrack_id": "412582",
          "cspan_id": "49219",
          "votesmart_id": "50772",
          "icpsr_id": "41305",
          "crp_id": "N00033177",
          "google_entity_id": "/m/053f8h",
          "state_rank": "junior",
          "lis_id": "S362",
          "suffix": null,
          "date_of_birth": "1958-02-26",
          "leadership_role": null,
          "fec_candidate_id": "S2VA00142",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "11",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 2,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 0.36,
          "votes_with_party_pct": 97.64,
          "votes_against_party_pct": 2.36,
          "ocd_id": "ocd-division/country:us/state:va"
        },
        {
          "API_ID": "W000805",
          "in_office": true,
          "firstName": "Mark",
          "lastName": "Warner",
          "middleName": null,
          "fullName": "Mark R. Warner",
          "gender": "M",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/W000805.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "VA",
            "stateFull": "Virginia",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-VA---W000805",
            "builtID": "va--",
            "externalID": "W000805",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.warner.senate.gov/public/index.cfm?p=Contact",
            "address1": "703 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-2023",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "MarkWarner",
            "youtube": "SenatorMarkWarner",
            "instagram": null,
            "facebook": "MarkRWarner",
            "urls": ["https://www.warner.senate.gov"],
            "rss_url": "https://www.warner.senate.gov/public/?a=RSS.Feed"
          },
          "title": "VA Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/W000805.json",
          "govtrack_id": "412321",
          "cspan_id": "7630",
          "votesmart_id": "535",
          "icpsr_id": "40909",
          "crp_id": "N00002097",
          "google_entity_id": "/m/024mm1",
          "state_rank": "senior",
          "lis_id": "S327",
          "suffix": null,
          "date_of_birth": "1954-12-15",
          "leadership_role": null,
          "fec_candidate_id": "S6VA00093",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "15",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 6,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 1.08,
          "votes_with_party_pct": 98.72,
          "votes_against_party_pct": 1.28,
          "ocd_id": "ocd-division/country:us/state:va"
        }
      ],
      "hash": { "SENATE-VA---K000384": 0, "SENATE-VA---W000805": 1 }
    },
    "HOUSE": {
      "10": {
        "members": [
          {
            "API_ID": "W000825",
            "in_office": true,
            "firstName": "Jennifer",
            "lastName": "Wexton",
            "middleName": null,
            "fullName": "Jennifer Wexton",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/W000825.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "10",
              "chamber": "",
              "hashID": "HOUSE-VA-10--W000825",
              "builtID": "va--10",
              "externalID": "W000825",
              "geometry": null,
              "geoid": "5110"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1210 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5136",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepWexton",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://wexton.house.gov"],
              "rss_url": null
            },
            "title": "VA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000825.json",
            "govtrack_id": "412834",
            "cspan_id": null,
            "votesmart_id": "147013",
            "icpsr_id": null,
            "crp_id": "N00041002",
            "google_entity_id": "/m/0_flt0x",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1968-05-27",
            "leadership_role": null,
            "fec_candidate_id": "H8VA10106",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 136,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 12.88,
            "votes_with_party_pct": 96.72,
            "votes_against_party_pct": 1.09,
            "ocd_id": "ocd-division/country:us/state:va/cd:10",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-VA-10--W000825": 0 }
      },
      "11": {
        "members": [
          {
            "API_ID": "C001078",
            "in_office": true,
            "firstName": "Gerald",
            "lastName": "Connolly",
            "middleName": "E.",
            "fullName": "Gerald E. Connolly",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/C001078.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "11",
              "chamber": "",
              "hashID": "HOUSE-VA-11--C001078",
              "builtID": "va--11",
              "externalID": "C001078",
              "geometry": null,
              "geoid": "5111"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2265 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-1492",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "GerryConnolly",
              "youtube": "repconnolly",
              "instagram": null,
              "facebook": "CongressmanGerryConnolly",
              "urls": ["https://connolly.house.gov"],
              "rss_url": null
            },
            "title": "VA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001078.json",
            "govtrack_id": "412272",
            "cspan_id": "1015936",
            "votesmart_id": "95078",
            "icpsr_id": "20952",
            "crp_id": "N00029891",
            "google_entity_id": "/m/04f40kn",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1950-03-30",
            "leadership_role": null,
            "fec_candidate_id": "H8VA11062",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "16",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 17,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.61,
            "votes_with_party_pct": 95.33,
            "votes_against_party_pct": 2.82,
            "ocd_id": "ocd-division/country:us/state:va/cd:11",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-VA-11--C001078": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "B001292",
            "in_office": true,
            "firstName": "Donald",
            "lastName": "Beyer",
            "middleName": null,
            "fullName": "Donald S. Beyer, Jr.",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/B001292.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-VA-08--B001292",
              "builtID": "va--08",
              "externalID": "B001292",
              "geometry": null,
              "geoid": "5108"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1119 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4376",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDonBeyer",
              "youtube": null,
              "instagram": null,
              "facebook": "RepDonBeyer",
              "urls": ["https://beyer.house.gov"],
              "rss_url": null
            },
            "title": "VA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/B001292.json",
            "govtrack_id": "412657",
            "cspan_id": "21141",
            "votesmart_id": "1707",
            "icpsr_id": "21554",
            "crp_id": "N00036018",
            "google_entity_id": "/m/053g16",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1950-06-20",
            "leadership_role": null,
            "fec_candidate_id": "H4VA08224",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 29,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 2.75,
            "votes_with_party_pct": 95.47,
            "votes_against_party_pct": 2.66,
            "ocd_id": "ocd-division/country:us/state:va/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-VA-08--B001292": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "C001118",
            "in_office": true,
            "firstName": "Ben",
            "lastName": "Cline",
            "middleName": null,
            "fullName": "Ben Cline",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/C001118.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-VA-06--C001118",
              "builtID": "va--06",
              "externalID": "C001118",
              "geometry": null,
              "geoid": "5106"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2443 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-5431",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBenCline",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://cline.house.gov"],
              "rss_url": null
            },
            "title": "VA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/C001118.json",
            "govtrack_id": "412832",
            "cspan_id": null,
            "votesmart_id": "50959",
            "icpsr_id": null,
            "crp_id": "N00042296",
            "google_entity_id": "/m/04yf6mk",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1972-02-29",
            "leadership_role": null,
            "fec_candidate_id": "H8VA06104",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 7,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.66,
            "votes_with_party_pct": 87.65,
            "votes_against_party_pct": 10.41,
            "ocd_id": "ocd-division/country:us/state:va/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-VA-06--C001118": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "G000595",
            "in_office": true,
            "firstName": "Robert",
            "lastName": "Good",
            "middleName": null,
            "fullName": "Bob Good",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000595.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-VA-05--G000595",
              "builtID": "va--05",
              "externalID": "G000595",
              "geometry": null,
              "geoid": "5105"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "461 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-4711",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBobGood",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://good.house.gov"],
              "rss_url": null
            },
            "title": "VA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000595.json",
            "govtrack_id": "456853",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00045557",
            "google_entity_id": "/g/11fsfm06kc",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1965-09-11",
            "leadership_role": null,
            "fec_candidate_id": "H0VA05160",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 23,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 2.18,
            "votes_with_party_pct": 82.91,
            "votes_against_party_pct": 15.22,
            "ocd_id": "ocd-division/country:us/state:va/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-VA-05--G000595": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "G000568",
            "in_office": true,
            "firstName": "Morgan",
            "lastName": "Griffith",
            "middleName": null,
            "fullName": "H. Morgan Griffith",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000568.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-VA-09--G000568",
              "builtID": "va--09",
              "externalID": "G000568",
              "geometry": null,
              "geoid": "5109"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2202 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3861",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMGriffith",
              "youtube": "RepMorganGriffith",
              "instagram": null,
              "facebook": "RepMorganGriffith",
              "urls": ["https://morgangriffith.house.gov"],
              "rss_url": "https://morgangriffith.house.gov/news/rss.aspx"
            },
            "title": "VA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000568.json",
            "govtrack_id": "412485",
            "cspan_id": "62766",
            "votesmart_id": "5148",
            "icpsr_id": "21191",
            "crp_id": "N00032029",
            "google_entity_id": "/m/025vdrq",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1958-03-15",
            "leadership_role": null,
            "fec_candidate_id": "H0VA09055",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 52,
            "total_present": 14,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 4.93,
            "votes_with_party_pct": 88.09,
            "votes_against_party_pct": 9.98,
            "ocd_id": "ocd-division/country:us/state:va/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-VA-09--G000568": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "K000399",
            "in_office": true,
            "firstName": "Jennifer",
            "lastName": "Kiggans",
            "middleName": null,
            "fullName": "Jennifer Kiggans",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/K000399.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-VA-02--K000399",
              "builtID": "va--02",
              "externalID": "K000399",
              "geometry": null,
              "geoid": "5102"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1037 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-4215",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://kiggans.house.gov/"],
              "rss_url": null
            },
            "title": "VA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000399.json",
            "govtrack_id": "456947",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1971-06-18",
            "leadership_role": null,
            "fec_candidate_id": "H2VA02064",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 29,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 2.75,
            "votes_with_party_pct": 83.7,
            "votes_against_party_pct": 14.31,
            "ocd_id": "ocd-division/country:us/state:va/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-VA-02--K000399": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "M001227",
            "in_office": true,
            "firstName": "Jennifer",
            "lastName": "McClellan",
            "middleName": null,
            "fullName": "Jennifer L. McClellan",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M001227.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-VA-04--M001227",
              "builtID": "va--04",
              "externalID": "M001227",
              "geometry": null,
              "geoid": "5104"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://mcclellan.house.gov/"],
              "rss_url": ""
            },
            "title": "VA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001227.json",
            "govtrack_id": "456953",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1972-12-28",
            "leadership_role": null,
            "fec_candidate_id": null,
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": null,
            "total_votes": 924,
            "missed_votes": 15,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.62,
            "votes_with_party_pct": 97.89,
            "votes_against_party_pct": 1.56,
            "ocd_id": "ocd-division/country:us/state:va/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-VA-04--M001227": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "S000185",
            "in_office": true,
            "firstName": "Robert",
            "lastName": "Scott",
            "middleName": "C.",
            "fullName": "Robert C. \"Bobby\" Scott",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S000185.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-VA-03--S000185",
              "builtID": "va--03",
              "externalID": "S000185",
              "geometry": null,
              "geoid": "5103"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2328 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-8351",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "BobbyScott",
              "youtube": "repbobbyscott",
              "instagram": null,
              "facebook": "RepBobbyScott",
              "urls": ["https://bobbyscott.house.gov"],
              "rss_url": "https://bobbyscott.house.gov/rss.xml"
            },
            "title": "VA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S000185.json",
            "govtrack_id": "400364",
            "cspan_id": "25888",
            "votesmart_id": "27117",
            "icpsr_id": "39307",
            "crp_id": "N00002147",
            "google_entity_id": "/m/03twgl",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1947-04-30",
            "leadership_role": null,
            "fec_candidate_id": "H6VA01117",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "32",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 2,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.19,
            "votes_with_party_pct": 94.83,
            "votes_against_party_pct": 3.35,
            "ocd_id": "ocd-division/country:us/state:va/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-VA-03--S000185": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "S001209",
            "in_office": true,
            "firstName": "Abigail",
            "lastName": "Spanberger",
            "middleName": null,
            "fullName": "Abigail Davis Spanberger",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001209.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-VA-07--S001209",
              "builtID": "va--07",
              "externalID": "S001209",
              "geometry": null,
              "geoid": "5107"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "562 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-2815",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepSpanberger",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://spanberger.house.gov"],
              "rss_url": null
            },
            "title": "VA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001209.json",
            "govtrack_id": "412833",
            "cspan_id": null,
            "votesmart_id": "179682",
            "icpsr_id": null,
            "crp_id": "N00041418",
            "google_entity_id": "/g/11fgm1ffs7",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1979-08-07",
            "leadership_role": null,
            "fec_candidate_id": "H8VA07094",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 9,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.85,
            "votes_with_party_pct": 92.57,
            "votes_against_party_pct": 5.5,
            "ocd_id": "ocd-division/country:us/state:va/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-VA-07--S001209": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "W000804",
            "in_office": true,
            "firstName": "Robert",
            "lastName": "Wittman",
            "middleName": "J.",
            "fullName": "Robert J. Wittman",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/W000804.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-VA-01--W000804",
              "builtID": "va--01",
              "externalID": "W000804",
              "geometry": null,
              "geoid": "5101"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2055 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4261",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RobWittman",
              "youtube": "RobWittman",
              "instagram": null,
              "facebook": "RepRobWittman",
              "urls": ["https://wittman.house.gov"],
              "rss_url": null
            },
            "title": "VA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/W000804.json",
            "govtrack_id": "412255",
            "cspan_id": "1028089",
            "votesmart_id": "58133",
            "icpsr_id": "20756",
            "crp_id": "N00029459",
            "google_entity_id": "/m/03cx7ld",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1959-02-03",
            "leadership_role": null,
            "fec_candidate_id": "H8VA01147",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "18",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 17,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.61,
            "votes_with_party_pct": 94.79,
            "votes_against_party_pct": 3.34,
            "ocd_id": "ocd-division/country:us/state:va/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-VA-01--W000804": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/c10414ad-5251-4fba-8ad9-a541311f2b9f",
            "firstName": "Patrick Alan",
            "lastName": "Hope",
            "fullName": "Patrick Hope",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0219",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-VA-1-lower-ocd-person-c10414ad-5251-4fba-8ad9-a541311f2b9f",
              "builtID": "va-lower-1",
              "externalID": "ocd-person/c10414ad-5251-4fba-8ad9-a541311f2b9f",
              "geometry": null
            },
            "contactInfo": {
              "email": "delphope@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 709, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1001",
              "fax1": null,
              "fax2": null,
              "twitter": "HopeforVirginia",
              "youtube": null,
              "instagram": null,
              "facebook": "hopeforvirginia",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H219",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H219",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H219",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H219",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0219"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-1-lower-ocd-person-c10414ad-5251-4fba-8ad9-a541311f2b9f": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/d0de7acb-ce8d-4bb2-b6e5-99cefe5e76a6",
            "firstName": "Adele Y.",
            "lastName": "McClure",
            "fullName": "Adele McClure",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0375",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-VA-2-lower-ocd-person-d0de7acb-ce8d-4bb2-b6e5-99cefe5e76a6",
              "builtID": "va-lower-2",
              "externalID": "ocd-person/d0de7acb-ce8d-4bb2-b6e5-99cefe5e76a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "delamcclure@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 5512, Arlington, VA 22205",
              "phone1": null,
              "phone2": "703-239-4425",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H375",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0375"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-2-lower-ocd-person-d0de7acb-ce8d-4bb2-b6e5-99cefe5e76a6": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/ed935430-db59-4962-91f7-c0eeca2ef8e2",
            "firstName": "Alfonso Hoffman",
            "lastName": "Lopez",
            "fullName": "Alfonso Lopez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0239",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-VA-3-lower-ocd-person-ed935430-db59-4962-91f7-c0eeca2ef8e2",
              "builtID": "va-lower-3",
              "externalID": "ocd-person/ed935430-db59-4962-91f7-c0eeca2ef8e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "delalopez@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1014, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1003",
              "fax1": null,
              "fax2": null,
              "twitter": "Lopez4VA",
              "youtube": null,
              "instagram": null,
              "facebook": "Lopez4VA",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H239",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H239",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H239",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H239",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0239"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-3-lower-ocd-person-ed935430-db59-4962-91f7-c0eeca2ef8e2": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/a0b9b444-ee25-48a1-ab61-cf0be462e89d",
            "firstName": "Charniele LeRhonda",
            "lastName": "Herring",
            "fullName": "Charniele Herring",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0208",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-VA-4-lower-ocd-person-a0b9b444-ee25-48a1-ab61-cf0be462e89d",
              "builtID": "va-lower-4",
              "externalID": "ocd-person/a0b9b444-ee25-48a1-ab61-cf0be462e89d",
              "geometry": null
            },
            "contactInfo": {
              "email": "delcherring@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1402, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1004",
              "fax1": null,
              "fax2": null,
              "twitter": "c_herring",
              "youtube": null,
              "instagram": null,
              "facebook": "charnieleherring",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H208",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H208",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H208",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H208",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0208"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-4-lower-ocd-person-a0b9b444-ee25-48a1-ab61-cf0be462e89d": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/c3b0c2fc-c7bf-45f8-8ee3-50d5a57bad08",
            "firstName": "Elizabeth B.",
            "lastName": "Bennett-Parker",
            "fullName": "Elizabeth Bennett-Parker",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0334",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-VA-5-lower-ocd-person-c3b0c2fc-c7bf-45f8-8ee3-50d5a57bad08",
              "builtID": "va-lower-5",
              "externalID": "ocd-person/c3b0c2fc-c7bf-45f8-8ee3-50d5a57bad08",
              "geometry": null
            },
            "contactInfo": {
              "email": "delebennett-parker@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1105, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1005",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H334",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H334",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0334"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-5-lower-ocd-person-c3b0c2fc-c7bf-45f8-8ee3-50d5a57bad08": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/658209cc-5e85-4c57-aa94-878089f3d816",
            "firstName": "Richard Cyril",
            "lastName": "Sullivan",
            "fullName": "Rip Sullivan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0269",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-VA-6-lower-ocd-person-658209cc-5e85-4c57-aa94-878089f3d816",
              "builtID": "va-lower-6",
              "externalID": "ocd-person/658209cc-5e85-4c57-aa94-878089f3d816",
              "geometry": null
            },
            "contactInfo": {
              "email": "delrsullivan@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 908, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1006",
              "fax1": null,
              "fax2": null,
              "twitter": "RipSullivan48",
              "youtube": null,
              "instagram": null,
              "facebook": "RipSullivanVA",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H269",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H269",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H269",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H269",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0269"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-6-lower-ocd-person-658209cc-5e85-4c57-aa94-878089f3d816": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/7c045b50-e18d-422e-b87f-26eb7b61d651",
            "firstName": "Karen",
            "lastName": "Keys-Gamarra",
            "fullName": "Karen Keys-Gamarra",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0370",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-VA-7-lower-ocd-person-7c045b50-e18d-422e-b87f-26eb7b61d651",
              "builtID": "va-lower-7",
              "externalID": "ocd-person/7c045b50-e18d-422e-b87f-26eb7b61d651",
              "geometry": null
            },
            "contactInfo": {
              "email": "delkkeys-gamarra@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 212, Oakton, VA 22124",
              "phone1": null,
              "phone2": "804-698-1007",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H370",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0370"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-7-lower-ocd-person-7c045b50-e18d-422e-b87f-26eb7b61d651": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/3f02dc5d-5107-4620-add7-c32a2ea8bf44",
            "firstName": "Irene",
            "lastName": "Shin",
            "fullName": "Irene Shin",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0344",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-VA-8-lower-ocd-person-3f02dc5d-5107-4620-add7-c32a2ea8bf44",
              "builtID": "va-lower-8",
              "externalID": "ocd-person/3f02dc5d-5107-4620-add7-c32a2ea8bf44",
              "geometry": null
            },
            "contactInfo": {
              "email": "delishin@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1115, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1008",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H344",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H344",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0344"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-8-lower-ocd-person-3f02dc5d-5107-4620-add7-c32a2ea8bf44": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/7ec1cbe4-667d-4dbb-a840-459449ccd5df",
            "firstName": "Karrie K.",
            "lastName": "Delaney",
            "fullName": "Karrie Delaney",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0294",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-VA-9-lower-ocd-person-7ec1cbe4-667d-4dbb-a840-459449ccd5df",
              "builtID": "va-lower-9",
              "externalID": "ocd-person/7ec1cbe4-667d-4dbb-a840-459449ccd5df",
              "geometry": null
            },
            "contactInfo": {
              "email": "delkdelaney@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1112, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1009",
              "fax1": null,
              "fax2": null,
              "twitter": "karriekdelaney",
              "youtube": null,
              "instagram": null,
              "facebook": "DelegateKarrieDelaney",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H294",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H294",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H294",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H294",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0294"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-9-lower-ocd-person-7ec1cbe4-667d-4dbb-a840-459449ccd5df": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/5e5e025d-7e50-4c2f-ba81-720f19aa0d81",
            "firstName": "Daniel Isaac",
            "lastName": "Helmer",
            "fullName": "Dan Helmer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0317",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-VA-10-lower-ocd-person-5e5e025d-7e50-4c2f-ba81-720f19aa0d81",
              "builtID": "va-lower-10",
              "externalID": "ocd-person/5e5e025d-7e50-4c2f-ba81-720f19aa0d81",
              "geometry": null
            },
            "contactInfo": {
              "email": "deldhelmer@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1013, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1010",
              "fax1": null,
              "fax2": null,
              "twitter": "helmerVA",
              "youtube": null,
              "instagram": null,
              "facebook": "HelmerVA",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H317",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H317",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H317",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0317"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-10-lower-ocd-person-5e5e025d-7e50-4c2f-ba81-720f19aa0d81": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/07903462-a2a0-4dd2-9b67-1dc42eec240a",
            "firstName": "David L.",
            "lastName": "Bulova",
            "fullName": "David Bulova",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0177",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-VA-11-lower-ocd-person-07903462-a2a0-4dd2-9b67-1dc42eec240a",
              "builtID": "va-lower-11",
              "externalID": "ocd-person/07903462-a2a0-4dd2-9b67-1dc42eec240a",
              "geometry": null
            },
            "contactInfo": {
              "email": "deldbulova@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 909, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1011",
              "fax1": null,
              "fax2": null,
              "twitter": "davidbulova",
              "youtube": null,
              "instagram": null,
              "facebook": "DelegateDavidBulova",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H177",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H177",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H177",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H177",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0177"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-11-lower-ocd-person-07903462-a2a0-4dd2-9b67-1dc42eec240a": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/745d2197-96c4-4e10-be1d-19f70c35f541",
            "firstName": "Holly M.",
            "lastName": "Seibold",
            "fullName": "Holly Seibold",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0351",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-VA-12-lower-ocd-person-745d2197-96c4-4e10-be1d-19f70c35f541",
              "builtID": "va-lower-12",
              "externalID": "ocd-person/745d2197-96c4-4e10-be1d-19f70c35f541",
              "geometry": null
            },
            "contactInfo": {
              "email": "delhseibold@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 902, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1012",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H351",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0351"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-12-lower-ocd-person-745d2197-96c4-4e10-be1d-19f70c35f541": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/ca122856-dfc5-4ba1-84bb-48fdcf7a36f5",
            "firstName": "Marcus Bertram",
            "lastName": "Simon",
            "fullName": "Marcus Simon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0264",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-VA-13-lower-ocd-person-ca122856-dfc5-4ba1-84bb-48fdcf7a36f5",
              "builtID": "va-lower-13",
              "externalID": "ocd-person/ca122856-dfc5-4ba1-84bb-48fdcf7a36f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "delmsimon@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1114, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1013",
              "fax1": null,
              "fax2": null,
              "twitter": "marcussimon",
              "youtube": null,
              "instagram": null,
              "facebook": "DelegateSimon",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H264",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H264",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H264",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H264",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0264"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-13-lower-ocd-person-ca122856-dfc5-4ba1-84bb-48fdcf7a36f5": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/b251a648-d2d3-4799-8213-e803fed621de",
            "firstName": "Vivian Edna",
            "lastName": "Watts",
            "fullName": "Vivian Watts",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0108",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-VA-14-lower-ocd-person-b251a648-d2d3-4799-8213-e803fed621de",
              "builtID": "va-lower-14",
              "externalID": "ocd-person/b251a648-d2d3-4799-8213-e803fed621de",
              "geometry": null
            },
            "contactInfo": {
              "email": "delvwatts@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 907, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1014",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H108",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H108",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H108",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H108",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0108"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-14-lower-ocd-person-b251a648-d2d3-4799-8213-e803fed621de": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/6840c55c-6496-44ce-8a60-3288e222ddc0",
            "firstName": "Laura Jane",
            "lastName": "Cohen",
            "fullName": "Laura Jane Cohen",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/LauraJaneCohen.jpg",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-VA-15-lower-ocd-person-6840c55c-6496-44ce-8a60-3288e222ddc0",
              "builtID": "va-lower-15",
              "externalID": "ocd-person/6840c55c-6496-44ce-8a60-3288e222ddc0",
              "geometry": null
            },
            "contactInfo": {
              "email": "delljcohen@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 273, Burke, VA 22009",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H355",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0355"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-15-lower-ocd-person-6840c55c-6496-44ce-8a60-3288e222ddc0": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/d90e6a36-4464-4481-b00d-74ed9964d45e",
            "firstName": "Paul Eugene",
            "lastName": "Krizek",
            "fullName": "Paul Krizek",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0281",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-VA-16-lower-ocd-person-d90e6a36-4464-4481-b00d-74ed9964d45e",
              "builtID": "va-lower-16",
              "externalID": "ocd-person/d90e6a36-4464-4481-b00d-74ed9964d45e",
              "geometry": null
            },
            "contactInfo": {
              "email": "delpkrizek@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1010, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1016",
              "fax1": null,
              "fax2": null,
              "twitter": "KrizekForVA",
              "youtube": null,
              "instagram": null,
              "facebook": "KrizekForVA",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H281",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H281",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H281",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H281",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0281"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-16-lower-ocd-person-d90e6a36-4464-4481-b00d-74ed9964d45e": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/aa6ad05b-c593-4b81-abf4-299f8669839d",
            "firstName": "Mark David",
            "lastName": "Sickles",
            "fullName": "Mark Sickles",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://moranstrategies.com/wp-content/uploads/Mark-Sickles.jpg",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-VA-17-lower-ocd-person-aa6ad05b-c593-4b81-abf4-299f8669839d",
              "builtID": "va-lower-17",
              "externalID": "ocd-person/aa6ad05b-c593-4b81-abf4-299f8669839d",
              "geometry": null
            },
            "contactInfo": {
              "email": "delmsickles@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1232, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1017",
              "fax1": null,
              "fax2": null,
              "twitter": "MarkSicklesVA",
              "youtube": null,
              "instagram": null,
              "facebook": "MarkSicklesVA",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H172",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H172",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H172",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H172",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0172"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-17-lower-ocd-person-aa6ad05b-c593-4b81-abf4-299f8669839d": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/ddba8b1b-8a1d-42c2-aba5-adc1331ee6f1",
            "firstName": "Kathy K.L.",
            "lastName": "Tran",
            "fullName": "Kathy Tran",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0305",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-VA-18-lower-ocd-person-ddba8b1b-8a1d-42c2-aba5-adc1331ee6f1",
              "builtID": "va-lower-18",
              "externalID": "ocd-person/ddba8b1b-8a1d-42c2-aba5-adc1331ee6f1",
              "geometry": null
            },
            "contactInfo": {
              "email": "delktran@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1113, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1018",
              "fax1": null,
              "fax2": null,
              "twitter": "kathykltran",
              "youtube": null,
              "instagram": null,
              "facebook": "KathyForDelegate",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H305",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H305",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H305",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H305",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0305"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-18-lower-ocd-person-ddba8b1b-8a1d-42c2-aba5-adc1331ee6f1": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/892b7901-d71e-4d8b-b8d0-9034a3d4a066",
            "firstName": "Rozia Armstrong",
            "lastName": "Henson",
            "fullName": "J.R. Henson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0365",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-VA-19-lower-ocd-person-892b7901-d71e-4d8b-b8d0-9034a3d4a066",
              "builtID": "va-lower-19",
              "externalID": "ocd-person/892b7901-d71e-4d8b-b8d0-9034a3d4a066",
              "geometry": null
            },
            "contactInfo": {
              "email": "delrhenson@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 818, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1019",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H365",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0365"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-19-lower-ocd-person-892b7901-d71e-4d8b-b8d0-9034a3d4a066": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/47e6d456-740e-4707-ae93-4b6efe8c0ce1",
            "firstName": "Michelle-Ann E. Lopes",
            "lastName": "Maldonado",
            "fullName": "Michelle Maldonado",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Michelle-Maldonado.jpg",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-VA-20-lower-ocd-person-47e6d456-740e-4707-ae93-4b6efe8c0ce1",
              "builtID": "va-lower-20",
              "externalID": "ocd-person/47e6d456-740e-4707-ae93-4b6efe8c0ce1",
              "geometry": null
            },
            "contactInfo": {
              "email": "delmmaldonado@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 819, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1020",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H340",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H340",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0340"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-20-lower-ocd-person-47e6d456-740e-4707-ae93-4b6efe8c0ce1": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/b6db5cc9-d9d3-4cbd-9532-6126a47c2ab3",
            "firstName": "Joshua",
            "lastName": "Thomas",
            "fullName": "Josh Thomas",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Thomas_Headshot_HiRes-1-edited-1536x1536.jpg",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-VA-21-lower-ocd-person-b6db5cc9-d9d3-4cbd-9532-6126a47c2ab3",
              "builtID": "va-lower-21",
              "externalID": "ocd-person/b6db5cc9-d9d3-4cbd-9532-6126a47c2ab3",
              "geometry": null
            },
            "contactInfo": {
              "email": "deljthomas@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H382",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0382"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-21-lower-ocd-person-b6db5cc9-d9d3-4cbd-9532-6126a47c2ab3": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/30d05986-77ba-4259-8153-734be250ce93",
            "firstName": "Ian Travis",
            "lastName": "Lovejoy",
            "fullName": "Ian Lovejoy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0373",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-VA-22-lower-ocd-person-30d05986-77ba-4259-8153-734be250ce93",
              "builtID": "va-lower-22",
              "externalID": "ocd-person/30d05986-77ba-4259-8153-734be250ce93",
              "geometry": null
            },
            "contactInfo": {
              "email": "delilovejoy@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "8665 Sudley Road, Box 195, Manassas, VA 20110",
              "phone1": null,
              "phone2": "571-774-4322",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H373",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0373"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-22-lower-ocd-person-30d05986-77ba-4259-8153-734be250ce93": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/b04d518c-c077-4f41-82d7-8cd55ac1a54b",
            "firstName": "Candi Patrice Mundon",
            "lastName": "King",
            "fullName": "Candi King",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0330",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-VA-23-lower-ocd-person-b04d518c-c077-4f41-82d7-8cd55ac1a54b",
              "builtID": "va-lower-23",
              "externalID": "ocd-person/b04d518c-c077-4f41-82d7-8cd55ac1a54b",
              "geometry": null
            },
            "contactInfo": {
              "email": "delcmundonking@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 906, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1023",
              "fax1": null,
              "fax2": null,
              "twitter": "CandiKingForHD2",
              "youtube": null,
              "instagram": null,
              "facebook": "candikingforvahd2",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H330",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H330",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0330"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-23-lower-ocd-person-b04d518c-c077-4f41-82d7-8cd55ac1a54b": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/44ebbeab-055c-402b-a56e-bb7487d0da62",
            "firstName": "Luke E.",
            "lastName": "Torian",
            "fullName": "Luke Torian",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0227",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-VA-24-lower-ocd-person-44ebbeab-055c-402b-a56e-bb7487d0da62",
              "builtID": "va-lower-24",
              "externalID": "ocd-person/44ebbeab-055c-402b-a56e-bb7487d0da62",
              "geometry": null
            },
            "contactInfo": {
              "email": "delltorian@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1223, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1024",
              "fax1": null,
              "fax2": null,
              "twitter": "DelegateTorian",
              "youtube": null,
              "instagram": null,
              "facebook": "luke.torian",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H227",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H227",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H227",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H227",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0227"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-24-lower-ocd-person-44ebbeab-055c-402b-a56e-bb7487d0da62": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/23775904-887c-4a2c-9424-0683608193ba",
            "firstName": "Briana D.",
            "lastName": "Sewell",
            "fullName": "Briana Sewell",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0343",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-VA-25-lower-ocd-person-23775904-887c-4a2c-9424-0683608193ba",
              "builtID": "va-lower-25",
              "externalID": "ocd-person/23775904-887c-4a2c-9424-0683608193ba",
              "geometry": null
            },
            "contactInfo": {
              "email": "delbsewell@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 905, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1025",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H343",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H343",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0343"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-25-lower-ocd-person-23775904-887c-4a2c-9424-0683608193ba": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/eb7a5152-f275-4dfe-a303-742f7218fb27",
            "firstName": "Kannan",
            "lastName": "Srinivasan",
            "fullName": "Kannan Srinivasan",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0381",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-VA-26-lower-ocd-person-eb7a5152-f275-4dfe-a303-742f7218fb27",
              "builtID": "va-lower-26",
              "externalID": "ocd-person/eb7a5152-f275-4dfe-a303-742f7218fb27",
              "geometry": null
            },
            "contactInfo": {
              "email": "delksrinivasan@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 4268, Broadlands, VA 20148",
              "phone1": null,
              "phone2": "804-698-1026",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H381",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0381"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-26-lower-ocd-person-eb7a5152-f275-4dfe-a303-742f7218fb27": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/38f25fcb-2f54-411a-84d5-2d1f748d4183",
            "firstName": "Atoosa",
            "lastName": "Reaser",
            "fullName": "Atoosa Reaser",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.lcps.org/cms/lib/VA01000195/Centricity/Domain/30434/Reaser_DSC_3305.jpg",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-VA-27-lower-ocd-person-38f25fcb-2f54-411a-84d5-2d1f748d4183",
              "builtID": "va-lower-27",
              "externalID": "ocd-person/38f25fcb-2f54-411a-84d5-2d1f748d4183",
              "geometry": null
            },
            "contactInfo": {
              "email": "delareaser@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H380",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0380"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-27-lower-ocd-person-38f25fcb-2f54-411a-84d5-2d1f748d4183": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/0258144f-e8e8-4710-a80c-362f2077f57b",
            "firstName": "David Alan",
            "lastName": "Reid",
            "fullName": "David Reid",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0301",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-VA-28-lower-ocd-person-0258144f-e8e8-4710-a80c-362f2077f57b",
              "builtID": "va-lower-28",
              "externalID": "ocd-person/0258144f-e8e8-4710-a80c-362f2077f57b",
              "geometry": null
            },
            "contactInfo": {
              "email": "deldreid@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 913, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1028",
              "fax1": null,
              "fax2": null,
              "twitter": "davidreidva",
              "youtube": null,
              "instagram": null,
              "facebook": "delegatedavid",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H301",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H301",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H301",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H301",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0301"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-28-lower-ocd-person-0258144f-e8e8-4710-a80c-362f2077f57b": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/beaab5ae-3e8d-4c12-b388-a59cdc467771",
            "firstName": "Fernando J.",
            "lastName": "Martinez",
            "fullName": "Marty Martinez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0374",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-VA-29-lower-ocd-person-beaab5ae-3e8d-4c12-b388-a59cdc467771",
              "builtID": "va-lower-29",
              "externalID": "ocd-person/beaab5ae-3e8d-4c12-b388-a59cdc467771",
              "geometry": null
            },
            "contactInfo": {
              "email": "delmmartinez@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 6366, Leesburg, VA 20178",
              "phone1": null,
              "phone2": "804-698-1029",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H374",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0374"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-29-lower-ocd-person-beaab5ae-3e8d-4c12-b388-a59cdc467771": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/cf02d849-a9f6-44a3-a360-42fd96e1e14b",
            "firstName": "Geary Michael",
            "lastName": "Higgins",
            "fullName": "Geary Higgins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0367",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-VA-30-lower-ocd-person-cf02d849-a9f6-44a3-a360-42fd96e1e14b",
              "builtID": "va-lower-30",
              "externalID": "ocd-person/cf02d849-a9f6-44a3-a360-42fd96e1e14b",
              "geometry": null
            },
            "contactInfo": {
              "email": "delghiggins@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 388, Waterford, VA 20197",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H367",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0367"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-30-lower-ocd-person-cf02d849-a9f6-44a3-a360-42fd96e1e14b": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/aabaa796-77f3-4b39-a626-ae05944ec484",
            "firstName": "Delores Riley",
            "lastName": "Oates",
            "fullName": "Delores Oates",
            "gender": "Female",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0377",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-VA-31-lower-ocd-person-aabaa796-77f3-4b39-a626-ae05944ec484",
              "builtID": "va-lower-31",
              "externalID": "ocd-person/aabaa796-77f3-4b39-a626-ae05944ec484",
              "geometry": null
            },
            "contactInfo": {
              "email": "deldoates@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "424A South St. Box #230, Front Royal, VA 22630",
              "phone1": null,
              "phone2": "804-698-1031",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H377",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0377"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-31-lower-ocd-person-aabaa796-77f3-4b39-a626-ae05944ec484": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/ec0c5f08-2974-4f9c-896c-34c9d5d33fac",
            "firstName": "William D.",
            "lastName": "Wiley",
            "fullName": "Bill Wiley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0329",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-VA-32-lower-ocd-person-ec0c5f08-2974-4f9c-896c-34c9d5d33fac",
              "builtID": "va-lower-32",
              "externalID": "ocd-person/ec0c5f08-2974-4f9c-896c-34c9d5d33fac",
              "geometry": null
            },
            "contactInfo": {
              "email": "delbwiley@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1006, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1032",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://virginiageneralassembly.gov/house/members/members.php?id=V0329",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H329",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H329",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0329"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-32-lower-ocd-person-ec0c5f08-2974-4f9c-896c-34c9d5d33fac": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/a399b7a4-a0d9-4cf3-9f0b-39f57d9f7c90",
            "firstName": "Christopher Todd",
            "lastName": "Gilbert",
            "fullName": "Todd Gilbert",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0181",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-VA-33-lower-ocd-person-a399b7a4-a0d9-4cf3-9f0b-39f57d9f7c90",
              "builtID": "va-lower-33",
              "externalID": "ocd-person/a399b7a4-a0d9-4cf3-9f0b-39f57d9f7c90",
              "geometry": null
            },
            "contactInfo": {
              "email": "deltgilbert@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1401, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1033",
              "fax1": null,
              "fax2": null,
              "twitter": "ctoddgilbert",
              "youtube": null,
              "instagram": null,
              "facebook": "DelegateToddGilbert",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H181",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H181",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H181",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H181",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0181"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-33-lower-ocd-person-a399b7a4-a0d9-4cf3-9f0b-39f57d9f7c90": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/94fa59c7-00b0-4e15-9887-8af534217476",
            "firstName": "Tony O.",
            "lastName": "Wilt",
            "fullName": "Tony Wilt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0231",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-VA-34-lower-ocd-person-94fa59c7-00b0-4e15-9887-8af534217476",
              "builtID": "va-lower-34",
              "externalID": "ocd-person/94fa59c7-00b0-4e15-9887-8af534217476",
              "geometry": null
            },
            "contactInfo": {
              "email": "deltwilt@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 814, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1034",
              "fax1": null,
              "fax2": null,
              "twitter": "DelTonyWilt",
              "youtube": null,
              "instagram": null,
              "facebook": "DelTonyWilt",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H231",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H231",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H231",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H231",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0231"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-34-lower-ocd-person-94fa59c7-00b0-4e15-9887-8af534217476": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/bbb38d6c-3b3d-4ce8-bb2f-3d9346ff86f7",
            "firstName": "Christopher Scott",
            "lastName": "Runion",
            "fullName": "Chris Runion",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0321",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-VA-35-lower-ocd-person-bbb38d6c-3b3d-4ce8-bb2f-3d9346ff86f7",
              "builtID": "va-lower-35",
              "externalID": "ocd-person/bbb38d6c-3b3d-4ce8-bb2f-3d9346ff86f7",
              "geometry": null
            },
            "contactInfo": {
              "email": "delcrunion@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 811, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1035",
              "fax1": null,
              "fax2": null,
              "twitter": "chrisrunionva",
              "youtube": null,
              "instagram": null,
              "facebook": "chrisrunionforva",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H321",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H321",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H321",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0321"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-35-lower-ocd-person-bbb38d6c-3b3d-4ce8-bb2f-3d9346ff86f7": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/318cc276-b981-4744-82ff-1c7cc7d0c0b6",
            "firstName": "Ellen H.",
            "lastName": "Campbell",
            "fullName": "Ellen Campbell",
            "gender": "Female",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0350",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-VA-36-lower-ocd-person-318cc276-b981-4744-82ff-1c7cc7d0c0b6",
              "builtID": "va-lower-36",
              "externalID": "ocd-person/318cc276-b981-4744-82ff-1c7cc7d0c0b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "delecampbell@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 803, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1036",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H350",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0350"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-36-lower-ocd-person-318cc276-b981-4744-82ff-1c7cc7d0c0b6": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/cb9573ab-1bf6-45d5-a8d7-476d7bed94a8",
            "firstName": "Terry L.",
            "lastName": "Austin",
            "fullName": "Terry Austin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0253",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-VA-37-lower-ocd-person-cb9573ab-1bf6-45d5-a8d7-476d7bed94a8",
              "builtID": "va-lower-37",
              "externalID": "ocd-person/cb9573ab-1bf6-45d5-a8d7-476d7bed94a8",
              "geometry": null
            },
            "contactInfo": {
              "email": "deltaustin@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 708, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1037",
              "fax1": null,
              "fax2": null,
              "twitter": "TerryAustin4HOD",
              "youtube": null,
              "instagram": null,
              "facebook": "TerryAustin4delegate",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H253",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H253",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H253",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H253",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0253"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-37-lower-ocd-person-cb9573ab-1bf6-45d5-a8d7-476d7bed94a8": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/6ab67007-e48a-4048-bb7b-20a608a88f77",
            "firstName": "Salam",
            "lastName": "Rasoul",
            "fullName": "Sam Rasoul",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0266",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-VA-38-lower-ocd-person-6ab67007-e48a-4048-bb7b-20a608a88f77",
              "builtID": "va-lower-38",
              "externalID": "ocd-person/6ab67007-e48a-4048-bb7b-20a608a88f77",
              "geometry": null
            },
            "contactInfo": {
              "email": "delsrasoul@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 910, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1038",
              "fax1": null,
              "fax2": null,
              "twitter": "Sam_Rasoul",
              "youtube": null,
              "instagram": null,
              "facebook": "DelSamRasoul",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H266",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H266",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H266",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H266",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0266"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-38-lower-ocd-person-6ab67007-e48a-4048-bb7b-20a608a88f77": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/53937ad8-9c5b-4493-95fc-a18565577dd8",
            "firstName": "William P.",
            "lastName": "Davis",
            "fullName": "Will Davis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0357",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-VA-39-lower-ocd-person-53937ad8-9c5b-4493-95fc-a18565577dd8",
              "builtID": "va-lower-39",
              "externalID": "ocd-person/53937ad8-9c5b-4493-95fc-a18565577dd8",
              "geometry": null
            },
            "contactInfo": {
              "email": "delwdavis@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 829, Rocky Mount, VA 24251",
              "phone1": null,
              "phone2": "540-963-5221",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H357",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0357"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-39-lower-ocd-person-53937ad8-9c5b-4493-95fc-a18565577dd8": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/a0178eeb-c6bb-4e20-9024-f859d4780be6",
            "firstName": "Joseph P.",
            "lastName": "McNamara",
            "fullName": "Joe McNamara",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0308",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-VA-40-lower-ocd-person-a0178eeb-c6bb-4e20-9024-f859d4780be6",
              "builtID": "va-lower-40",
              "externalID": "ocd-person/a0178eeb-c6bb-4e20-9024-f859d4780be6",
              "geometry": null
            },
            "contactInfo": {
              "email": "deljmcnamara@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 813, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1040",
              "fax1": null,
              "fax2": null,
              "twitter": "JoeforVA",
              "youtube": null,
              "instagram": null,
              "facebook": "JoeForVA",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H308",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H308",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H308",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H308",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0308"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-40-lower-ocd-person-a0178eeb-c6bb-4e20-9024-f859d4780be6": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/20d63af7-dbae-4e1c-912c-8105dff5b2fd",
            "firstName": "Joseph Christian",
            "lastName": "Obenshain",
            "fullName": "Chris Obenshain",
            "gender": "Male",
            "party": "Republican",
            "image": "http://memdata.virginiageneralassembly.gov/images/display_image/H0378",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-VA-41-lower-ocd-person-20d63af7-dbae-4e1c-912c-8105dff5b2fd",
              "builtID": "va-lower-41",
              "externalID": "ocd-person/20d63af7-dbae-4e1c-912c-8105dff5b2fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "delcobenshain@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1999 S. Main St., Blacksburg, VA 24060",
              "phone1": null,
              "phone2": "804-647-0372",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H378",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0378"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-41-lower-ocd-person-20d63af7-dbae-4e1c-912c-8105dff5b2fd": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/80c98569-2ce0-44e9-8cf4-632f2dce684c",
            "firstName": "Jason S.",
            "lastName": "Ballard",
            "fullName": "Jason Ballard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0333",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-VA-42-lower-ocd-person-80c98569-2ce0-44e9-8cf4-632f2dce684c",
              "builtID": "va-lower-42",
              "externalID": "ocd-person/80c98569-2ce0-44e9-8cf4-632f2dce684c",
              "geometry": null
            },
            "contactInfo": {
              "email": "deljballard@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 805, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1042",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H333",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H333",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0333"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-42-lower-ocd-person-80c98569-2ce0-44e9-8cf4-632f2dce684c": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/0f77b7d1-a89e-41cd-ae83-28d267dab0e2",
            "firstName": "James William",
            "lastName": "Morefield",
            "fullName": "Will Morefield",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0224",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-VA-43-lower-ocd-person-0f77b7d1-a89e-41cd-ae83-28d267dab0e2",
              "builtID": "va-lower-43",
              "externalID": "ocd-person/0f77b7d1-a89e-41cd-ae83-28d267dab0e2",
              "geometry": null
            },
            "contactInfo": {
              "email": "deljmorefield@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 808, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1043",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "DelegateMorefield",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H224",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H224",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H224",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H224",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0224"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-43-lower-ocd-person-0f77b7d1-a89e-41cd-ae83-28d267dab0e2": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/4524f0db-f682-4b90-9cd5-628eb1d16ca7",
            "firstName": "Israel D.",
            "lastName": "O'Quinn",
            "fullName": "Israel O'Quinn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0242",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-VA-44-lower-ocd-person-4524f0db-f682-4b90-9cd5-628eb1d16ca7",
              "builtID": "va-lower-44",
              "externalID": "ocd-person/4524f0db-f682-4b90-9cd5-628eb1d16ca7",
              "geometry": null
            },
            "contactInfo": {
              "email": "delioquinn@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 16325, Bristol, VA 24209",
              "phone1": null,
              "phone2": "276-525-1311",
              "fax1": null,
              "fax2": null,
              "twitter": "IsraelOQuinn",
              "youtube": null,
              "instagram": null,
              "facebook": "israeloquinn",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H242",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H242",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H242",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H242",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0242"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-44-lower-ocd-person-4524f0db-f682-4b90-9cd5-628eb1d16ca7": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/9303514b-3bce-4ddc-87d2-17a6ba4d6194",
            "firstName": "Terry Gene",
            "lastName": "Kilgore",
            "fullName": "Terry Kilgore",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0056",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-VA-45-lower-ocd-person-9303514b-3bce-4ddc-87d2-17a6ba4d6194",
              "builtID": "va-lower-45",
              "externalID": "ocd-person/9303514b-3bce-4ddc-87d2-17a6ba4d6194",
              "geometry": null
            },
            "contactInfo": {
              "email": "deltkilgore@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1007, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1045",
              "fax1": null,
              "fax2": null,
              "twitter": "delterrykilgore",
              "youtube": null,
              "instagram": null,
              "facebook": "DelTerryKilgore",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H56",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H56",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H56",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H56",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0056"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-45-lower-ocd-person-9303514b-3bce-4ddc-87d2-17a6ba4d6194": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/2cbed7cf-5edc-45de-8d08-26e7454577de",
            "firstName": "Jonathan E.P.",
            "lastName": "Arnold",
            "fullName": "Jed Arnold",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0352",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-VA-46-lower-ocd-person-2cbed7cf-5edc-45de-8d08-26e7454577de",
              "builtID": "va-lower-46",
              "externalID": "ocd-person/2cbed7cf-5edc-45de-8d08-26e7454577de",
              "geometry": null
            },
            "contactInfo": {
              "email": "deljarnold@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 802, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1046",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H352",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0352"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-46-lower-ocd-person-2cbed7cf-5edc-45de-8d08-26e7454577de": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/d3a4b614-4a6f-4b38-b3cc-8990f0f7d4d3",
            "firstName": "Wren Montgomery",
            "lastName": "Williams",
            "fullName": "Wren Williams",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0348",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-VA-47-lower-ocd-person-d3a4b614-4a6f-4b38-b3cc-8990f0f7d4d3",
              "builtID": "va-lower-47",
              "externalID": "ocd-person/d3a4b614-4a6f-4b38-b3cc-8990f0f7d4d3",
              "geometry": null
            },
            "contactInfo": {
              "email": "delwwilliams@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 705, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1047",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H348",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H348",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0348"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-47-lower-ocd-person-d3a4b614-4a6f-4b38-b3cc-8990f0f7d4d3": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/704cecad-ef53-44e4-ad55-6c7a7e385e5c",
            "firstName": "Eric",
            "lastName": "Phillips",
            "fullName": "Eric Phillips",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0384",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-VA-48-lower-ocd-person-704cecad-ef53-44e4-ad55-6c7a7e385e5c",
              "builtID": "va-lower-48",
              "externalID": "ocd-person/704cecad-ef53-44e4-ad55-6c7a7e385e5c",
              "geometry": null
            },
            "contactInfo": {
              "email": "delephillips@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 57, Axton, VA 24054",
              "phone1": null,
              "phone2": "434-326-5878",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H384",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0384"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-48-lower-ocd-person-704cecad-ef53-44e4-ad55-6c7a7e385e5c": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/edd2e848-f30a-48fc-811a-35f6591f0a34",
            "firstName": "Daniel Webster",
            "lastName": "Marshall",
            "fullName": "Danny Marshall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0150",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-VA-49-lower-ocd-person-edd2e848-f30a-48fc-811a-35f6591f0a34",
              "builtID": "va-lower-49",
              "externalID": "ocd-person/edd2e848-f30a-48fc-811a-35f6591f0a34",
              "geometry": null
            },
            "contactInfo": {
              "email": "deldmarshall@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 807, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1049",
              "fax1": null,
              "fax2": null,
              "twitter": "dwmarshall3",
              "youtube": null,
              "instagram": null,
              "facebook": "Danny-Marshall-80885677477",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H150",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H150",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H150",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H150",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0150"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-49-lower-ocd-person-edd2e848-f30a-48fc-811a-35f6591f0a34": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/894118dd-1470-48b7-b84b-831ddba58e1b",
            "firstName": "Thomas C.",
            "lastName": "Wright",
            "fullName": "Tommy Wright",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0136",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-VA-50-lower-ocd-person-894118dd-1470-48b7-b84b-831ddba58e1b",
              "builtID": "va-lower-50",
              "externalID": "ocd-person/894118dd-1470-48b7-b84b-831ddba58e1b",
              "geometry": null
            },
            "contactInfo": {
              "email": "deltwright@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1109, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1050",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H136",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H136",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H136",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H136",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0136"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-50-lower-ocd-person-894118dd-1470-48b7-b84b-831ddba58e1b": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/0bd1b9d6-e02e-4cb4-91c8-c87466ebf1db",
            "firstName": "Eric Robert",
            "lastName": "Zehr",
            "fullName": "Eric Zehr",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0383",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-VA-51-lower-ocd-person-0bd1b9d6-e02e-4cb4-91c8-c87466ebf1db",
              "builtID": "va-lower-51",
              "externalID": "ocd-person/0bd1b9d6-e02e-4cb4-91c8-c87466ebf1db",
              "geometry": null
            },
            "contactInfo": {
              "email": "delezehr@house.virginia.go",
              "contactForm": null,
              "address1": null,
              "address2": "Room 801, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1051",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H383",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0383"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-51-lower-ocd-person-0bd1b9d6-e02e-4cb4-91c8-c87466ebf1db": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/18756686-0b6c-4a7d-ade9-db2cafcac870",
            "firstName": "Wendell Scott",
            "lastName": "Walker",
            "fullName": "Wendell Walker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0325",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-VA-52-lower-ocd-person-18756686-0b6c-4a7d-ade9-db2cafcac870",
              "builtID": "va-lower-52",
              "externalID": "ocd-person/18756686-0b6c-4a7d-ade9-db2cafcac870",
              "geometry": null
            },
            "contactInfo": {
              "email": "delwwalker@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 812, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1052",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "DelegateWendellWalker",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H325",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H325",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H325",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0325"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-52-lower-ocd-person-18756686-0b6c-4a7d-ade9-db2cafcac870": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/d112b6c3-e52f-4da4-a6fe-5866bf3f6799",
            "firstName": "Timothy Paul",
            "lastName": "Griffin",
            "fullName": "Tim Griffin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0364",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-VA-53-lower-ocd-person-d112b6c3-e52f-4da4-a6fe-5866bf3f6799",
              "builtID": "va-lower-53",
              "externalID": "ocd-person/d112b6c3-e52f-4da4-a6fe-5866bf3f6799",
              "geometry": null
            },
            "contactInfo": {
              "email": "deltgriffin@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 394, Forest, VA 24551",
              "phone1": null,
              "phone2": "434-515-1752",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H364",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0364"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-53-lower-ocd-person-d112b6c3-e52f-4da4-a6fe-5866bf3f6799": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/d0263d88-91b1-414d-b58b-1ff0523f75ec",
            "firstName": "Katrina",
            "lastName": "Callsen",
            "fullName": "Katrina Callsen",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0354",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-VA-54-lower-ocd-person-d0263d88-91b1-414d-b58b-1ff0523f75ec",
              "builtID": "va-lower-54",
              "externalID": "ocd-person/d0263d88-91b1-414d-b58b-1ff0523f75ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "delkcallsen@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 442, Charlottesville, VA 22902",
              "phone1": null,
              "phone2": "434-443-5400",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H354",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0354"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-54-lower-ocd-person-d0263d88-91b1-414d-b58b-1ff0523f75ec": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/7e0776e4-a915-4758-a7b3-70ca42ce3fe4",
            "firstName": "Amy J.",
            "lastName": "Laufer",
            "fullName": "Amy Laufer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0371",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-VA-55-lower-ocd-person-7e0776e4-a915-4758-a7b3-70ca42ce3fe4",
              "builtID": "va-lower-55",
              "externalID": "ocd-person/7e0776e4-a915-4758-a7b3-70ca42ce3fe4",
              "geometry": null
            },
            "contactInfo": {
              "email": "delalaufer@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 257, Keswick, VA 22947",
              "phone1": null,
              "phone2": "732-672-3263",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H371",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0371"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-55-lower-ocd-person-7e0776e4-a915-4758-a7b3-70ca42ce3fe4": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/0bba07ba-7713-4ca7-8f44-699c832e0d2a",
            "firstName": "Thomas Alexander",
            "lastName": "Garrett",
            "fullName": "Tom Garrett",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0362",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-VA-56-lower-ocd-person-0bba07ba-7713-4ca7-8f44-699c832e0d2a",
              "builtID": "va-lower-56",
              "externalID": "ocd-person/0bba07ba-7713-4ca7-8f44-699c832e0d2a",
              "geometry": null
            },
            "contactInfo": {
              "email": "deltgarrett@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0362"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-56-lower-ocd-person-0bba07ba-7713-4ca7-8f44-699c832e0d2a": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/94f7a270-e63f-45e5-be68-84ffbaca3711",
            "firstName": "David",
            "lastName": "Owen",
            "fullName": "David Owen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0379",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-VA-57-lower-ocd-person-94f7a270-e63f-45e5-be68-84ffbaca3711",
              "builtID": "va-lower-57",
              "externalID": "ocd-person/94f7a270-e63f-45e5-be68-84ffbaca3711",
              "geometry": null
            },
            "contactInfo": {
              "email": "deldowen@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H379",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0379"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-57-lower-ocd-person-94f7a270-e63f-45e5-be68-84ffbaca3711": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/8ec90d7b-fd5f-4157-b779-1979e7a54e76",
            "firstName": "Rodney T.",
            "lastName": "Willett",
            "fullName": "Rod Willett",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0327",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-VA-58-lower-ocd-person-8ec90d7b-fd5f-4157-b779-1979e7a54e76",
              "builtID": "va-lower-58",
              "externalID": "ocd-person/8ec90d7b-fd5f-4157-b779-1979e7a54e76",
              "geometry": null
            },
            "contactInfo": {
              "email": "delrwillett@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1012, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1058",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "RodneyforDelegate",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H327",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H327",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H327",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0327"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-58-lower-ocd-person-8ec90d7b-fd5f-4157-b779-1979e7a54e76": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/e2ce02ec-6a92-435d-8ae2-bead5abe5ace",
            "firstName": "Hyland Franklin",
            "lastName": "Fowler",
            "fullName": "Buddy Fowler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0259",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-VA-59-lower-ocd-person-e2ce02ec-6a92-435d-8ae2-bead5abe5ace",
              "builtID": "va-lower-59",
              "externalID": "ocd-person/e2ce02ec-6a92-435d-8ae2-bead5abe5ace",
              "geometry": null
            },
            "contactInfo": {
              "email": "delbfowler@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1108, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1059",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "H-Buddy-Fowler-55th-District-of-VA-House-of-Delegates-580739761936432",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H259",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H259",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H259",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H259",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0259"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-59-lower-ocd-person-e2ce02ec-6a92-435d-8ae2-bead5abe5ace": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/2a831795-d6fc-4635-88bf-4fd5f72ab1e7",
            "firstName": "Scott Andrew",
            "lastName": "Wyatt",
            "fullName": "Scott Wyatt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0328",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-VA-60-lower-ocd-person-2a831795-d6fc-4635-88bf-4fd5f72ab1e7",
              "builtID": "va-lower-60",
              "externalID": "ocd-person/2a831795-d6fc-4635-88bf-4fd5f72ab1e7",
              "geometry": null
            },
            "contactInfo": {
              "email": "delswyatt@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 806, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1060",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "DelSWyatt",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H328",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H328",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H328",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0328"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-60-lower-ocd-person-2a831795-d6fc-4635-88bf-4fd5f72ab1e7": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/bf425b62-d078-45bd-957c-baceb4bfad1b",
            "firstName": "Michael J.",
            "lastName": "Webert",
            "fullName": "Mike Webert",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0247",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-VA-61-lower-ocd-person-bf425b62-d078-45bd-957c-baceb4bfad1b",
              "builtID": "va-lower-61",
              "externalID": "ocd-person/bf425b62-d078-45bd-957c-baceb4bfad1b",
              "geometry": null
            },
            "contactInfo": {
              "email": "delmwebert@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 714, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1061",
              "fax1": null,
              "fax2": null,
              "twitter": "MichaelWebert",
              "youtube": null,
              "instagram": null,
              "facebook": "michaelwebertfordelegate",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H247",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H247",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H247",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H247",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0247"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-61-lower-ocd-person-bf425b62-d078-45bd-957c-baceb4bfad1b": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/8ebe480b-ef45-4fb9-8acf-bdfb9a510d0f",
            "firstName": "Nicholas Jason",
            "lastName": "Freitas",
            "fullName": "Nick Freitas",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0279",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-VA-62-lower-ocd-person-8ebe480b-ef45-4fb9-8acf-bdfb9a510d0f",
              "builtID": "va-lower-62",
              "externalID": "ocd-person/8ebe480b-ef45-4fb9-8acf-bdfb9a510d0f",
              "geometry": null
            },
            "contactInfo": {
              "email": "delnfreitas@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 716, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1062",
              "fax1": null,
              "fax2": null,
              "twitter": "NickForVA",
              "youtube": null,
              "instagram": null,
              "facebook": "NickFreitasVA",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H279",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H279",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H279",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H279",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0279"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-62-lower-ocd-person-8ebe480b-ef45-4fb9-8acf-bdfb9a510d0f": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/6a07d7b6-a4b4-42f8-9a06-6a860249f5b9",
            "firstName": "Phillip A.",
            "lastName": "Scott",
            "fullName": "Phil Scott",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0342",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-VA-63-lower-ocd-person-6a07d7b6-a4b4-42f8-9a06-6a860249f5b9",
              "builtID": "va-lower-63",
              "externalID": "ocd-person/6a07d7b6-a4b4-42f8-9a06-6a860249f5b9",
              "geometry": null
            },
            "contactInfo": {
              "email": "delpscott@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 919, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1063",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H342",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H342",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0342"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-63-lower-ocd-person-6a07d7b6-a4b4-42f8-9a06-6a860249f5b9": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/761a90f6-f20c-4820-a179-0525d9692d17",
            "firstName": "Paul V.",
            "lastName": "Milde",
            "fullName": "Paul Milde",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0376",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-VA-64-lower-ocd-person-761a90f6-f20c-4820-a179-0525d9692d17",
              "builtID": "va-lower-64",
              "externalID": "ocd-person/761a90f6-f20c-4820-a179-0525d9692d17",
              "geometry": null
            },
            "contactInfo": {
              "email": "delpmilde@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "25 Centreport Parkway Suite 102, Fredricksburg, VA 22406",
              "phone1": null,
              "phone2": "703-728-2828",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H376",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0376"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-64-lower-ocd-person-761a90f6-f20c-4820-a179-0525d9692d17": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/986f0601-025b-4fa7-acda-b2d3c2280784",
            "firstName": "Joshua Gregory",
            "lastName": "Cole",
            "fullName": "Josh Cole",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0314",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-VA-65-lower-ocd-person-986f0601-025b-4fa7-acda-b2d3c2280784",
              "builtID": "va-lower-65",
              "externalID": "ocd-person/986f0601-025b-4fa7-acda-b2d3c2280784",
              "geometry": null
            },
            "contactInfo": {
              "email": "deljcole@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 73, Fredericksburg, VA 22404",
              "phone1": null,
              "phone2": "540-642-0165",
              "fax1": null,
              "fax2": null,
              "twitter": "jcole4va",
              "youtube": null,
              "instagram": null,
              "facebook": "jcole4VA",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H314",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H314",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0314"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-65-lower-ocd-person-986f0601-025b-4fa7-acda-b2d3c2280784": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/b101b5d4-b57b-4123-82da-7628f9430de2",
            "firstName": "Robert Dickson",
            "lastName": "Orrock",
            "fullName": "Bobby Orrock",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0073",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-VA-66-lower-ocd-person-b101b5d4-b57b-4123-82da-7628f9430de2",
              "builtID": "va-lower-66",
              "externalID": "ocd-person/b101b5d4-b57b-4123-82da-7628f9430de2",
              "geometry": null
            },
            "contactInfo": {
              "email": "delborrock@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1107, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1066",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "delegateorrock",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H73",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H73",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H73",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H73",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0073"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-66-lower-ocd-person-b101b5d4-b57b-4123-82da-7628f9430de2": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/ec11a019-459d-4184-b0d7-8e88ab5f7dd6",
            "firstName": "Hillary",
            "lastName": "Pugh Kent",
            "fullName": "Hillary Pugh Kent",
            "gender": "Female",
            "party": "Republican",
            "image": "https://static.wixstatic.com/media/8f5763_3acf103cae51400f8e2caa87b932d971~mv2.jpg/v1/fill/w_402,h_357,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/8f5763_3acf103cae51400f8e2caa87b932d971~mv2.jpg",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-VA-67-lower-ocd-person-ec11a019-459d-4184-b0d7-8e88ab5f7dd6",
              "builtID": "va-lower-67",
              "externalID": "ocd-person/ec11a019-459d-4184-b0d7-8e88ab5f7dd6",
              "geometry": null
            },
            "contactInfo": {
              "email": "delhkent@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 785, Warsaw, VA 22572",
              "phone1": null,
              "phone2": "804-456-8360",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H369",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0369"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-67-lower-ocd-person-ec11a019-459d-4184-b0d7-8e88ab5f7dd6": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/06c0d909-22c8-4d71-9b6c-cc363a0c9553",
            "firstName": "Myron Keith",
            "lastName": "Hodges",
            "fullName": "Keith Hodges",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0238",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-VA-68-lower-ocd-person-06c0d909-22c8-4d71-9b6c-cc363a0c9553",
              "builtID": "va-lower-68",
              "externalID": "ocd-person/06c0d909-22c8-4d71-9b6c-cc363a0c9553",
              "geometry": null
            },
            "contactInfo": {
              "email": "delkhodges@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 810, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1068",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "FriendsOfKeithHodges",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H238",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H238",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H238",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H238",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0238"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-68-lower-ocd-person-06c0d909-22c8-4d71-9b6c-cc363a0c9553": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/3b2fe27a-5bd7-4381-a8a9-498515a447be",
            "firstName": "William Chad",
            "lastName": "Green",
            "fullName": "Chad Green",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0363",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-VA-69-lower-ocd-person-3b2fe27a-5bd7-4381-a8a9-498515a447be",
              "builtID": "va-lower-69",
              "externalID": "ocd-person/3b2fe27a-5bd7-4381-a8a9-498515a447be",
              "geometry": null
            },
            "contactInfo": {
              "email": "delcgreen@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 816, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1069",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H363",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0363"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-69-lower-ocd-person-3b2fe27a-5bd7-4381-a8a9-498515a447be": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/8329b3a6-9d6f-427e-9342-e53277e7c6ff",
            "firstName": "Shelly Anne",
            "lastName": "Simonds",
            "fullName": "Shelly Simonds",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0323",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-VA-70-lower-ocd-person-8329b3a6-9d6f-427e-9342-e53277e7c6ff",
              "builtID": "va-lower-70",
              "externalID": "ocd-person/8329b3a6-9d6f-427e-9342-e53277e7c6ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "delssimonds@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1106, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1070",
              "fax1": null,
              "fax2": null,
              "twitter": "shelly_simonds",
              "youtube": null,
              "instagram": null,
              "facebook": "simondsfordelegate",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H323",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H323",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H323",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0323"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-70-lower-ocd-person-8329b3a6-9d6f-427e-9342-e53277e7c6ff": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/3978e9b7-e219-4863-9788-1bf7991d32fb",
            "firstName": "Amanda Etter",
            "lastName": "Batten",
            "fullName": "Amanda Batten",
            "gender": "Female",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0313",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-VA-71-lower-ocd-person-3978e9b7-e219-4863-9788-1bf7991d32fb",
              "builtID": "va-lower-71",
              "externalID": "ocd-person/3978e9b7-e219-4863-9788-1bf7991d32fb",
              "geometry": null
            },
            "contactInfo": {
              "email": "delabatten@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 712, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1071",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "AmandaBatten4Delegate",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H313",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H313",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H313",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0313"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-71-lower-ocd-person-3978e9b7-e219-4863-9788-1bf7991d32fb": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/865dace5-518d-4f4f-b393-c197064affe6",
            "firstName": "Robert Lee",
            "lastName": "Ware",
            "fullName": "Lee Ware",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0124",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-VA-72-lower-ocd-person-865dace5-518d-4f4f-b393-c197064affe6",
              "builtID": "va-lower-72",
              "externalID": "ocd-person/865dace5-518d-4f4f-b393-c197064affe6",
              "geometry": null
            },
            "contactInfo": {
              "email": "dellware@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 707, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1072",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H124",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H124",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H124",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H124",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0124"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-72-lower-ocd-person-865dace5-518d-4f4f-b393-c197064affe6": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/2e727cfb-7466-48c5-9d80-3f5eab6bc429",
            "firstName": "Mark Lawrence",
            "lastName": "Earley",
            "fullName": "Mark Earley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/MarkEarleyJr.jpeg",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-VA-73-lower-ocd-person-2e727cfb-7466-48c5-9d80-3f5eab6bc429",
              "builtID": "va-lower-73",
              "externalID": "ocd-person/2e727cfb-7466-48c5-9d80-3f5eab6bc429",
              "geometry": null
            },
            "contactInfo": {
              "email": "delmearley@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 406, Richmond, VA 23218",
              "phone1": null,
              "phone2": "804-698-1173",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H358",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0358"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-73-lower-ocd-person-2e727cfb-7466-48c5-9d80-3f5eab6bc429": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/32b96a0d-662a-4c8f-9b2b-f86365640624",
            "firstName": "Michael A.",
            "lastName": "Cherry",
            "fullName": "Mike Cherry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0335",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-VA-74-lower-ocd-person-32b96a0d-662a-4c8f-9b2b-f86365640624",
              "builtID": "va-lower-74",
              "externalID": "ocd-person/32b96a0d-662a-4c8f-9b2b-f86365640624",
              "geometry": null
            },
            "contactInfo": {
              "email": "delmcherry@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 706, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1074",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H335",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H335",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0335"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-74-lower-ocd-person-32b96a0d-662a-4c8f-9b2b-f86365640624": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/924fd509-0a9c-45c2-a51b-68ed24bd11fe",
            "firstName": "Carrie Emerson",
            "lastName": "Coyner",
            "fullName": "Carrie Coyner",
            "gender": "Female",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0315",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-VA-75-lower-ocd-person-924fd509-0a9c-45c2-a51b-68ed24bd11fe",
              "builtID": "va-lower-75",
              "externalID": "ocd-person/924fd509-0a9c-45c2-a51b-68ed24bd11fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "delccoyner@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 710, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1075",
              "fax1": null,
              "fax2": null,
              "twitter": "CarrieCoyner",
              "youtube": null,
              "instagram": null,
              "facebook": "CarrieCoyner",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H315",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H315",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H315",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0315"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-75-lower-ocd-person-924fd509-0a9c-45c2-a51b-68ed24bd11fe": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/28a2a753-16db-4236-baf0-398c0cafa259",
            "firstName": "Debra",
            "lastName": "Gardner",
            "fullName": "Debra Gardner",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0361",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-VA-76-lower-ocd-person-28a2a753-16db-4236-baf0-398c0cafa259",
              "builtID": "va-lower-76",
              "externalID": "ocd-person/28a2a753-16db-4236-baf0-398c0cafa259",
              "geometry": null
            },
            "contactInfo": {
              "email": "deldgardner@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 7451, North Chesterfield, VA 23236",
              "phone1": null,
              "phone2": "804-698-1076",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H361",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0361"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-76-lower-ocd-person-28a2a753-16db-4236-baf0-398c0cafa259": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/3ceca66a-bf31-47c8-a1a6-e2f789b41be3",
            "firstName": "Michael",
            "lastName": "Jones",
            "fullName": "Mike Jones",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://lirp.cdn-website.com/861687ff/dms3rep/multi/opt/IMG_0223-960w.JPG",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-VA-77-lower-ocd-person-3ceca66a-bf31-47c8-a1a6-e2f789b41be3",
              "builtID": "va-lower-77",
              "externalID": "ocd-person/3ceca66a-bf31-47c8-a1a6-e2f789b41be3",
              "geometry": null
            },
            "contactInfo": {
              "email": "delmjones@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H368",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0368"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-77-lower-ocd-person-3ceca66a-bf31-47c8-a1a6-e2f789b41be3": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/a6f78488-1c78-4409-8c4a-bf0d0920e852",
            "firstName": "Betsy Brooks",
            "lastName": "Carr",
            "fullName": "Betsy Carr",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0212",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-VA-78-lower-ocd-person-a6f78488-1c78-4409-8c4a-bf0d0920e852",
              "builtID": "va-lower-78",
              "externalID": "ocd-person/a6f78488-1c78-4409-8c4a-bf0d0920e852",
              "geometry": null
            },
            "contactInfo": {
              "email": "delbcarr@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1110, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1078",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "delegatebetsycarr",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H212",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H212",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H212",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H212",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0212"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-78-lower-ocd-person-a6f78488-1c78-4409-8c4a-bf0d0920e852": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/359eaeba-903c-42b4-b9e3-5c5b4e9d721d",
            "firstName": "Rae",
            "lastName": "Cousins",
            "fullName": "Rae Cousins",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://static.wixstatic.com/media/3afe7f_6e3f3bb3374b4f20ac2f776006dc2122~mv2.jpg/v1/fill/w_319,h_410,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/DSC_8060%20(1).jpg",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-VA-79-lower-ocd-person-359eaeba-903c-42b4-b9e3-5c5b4e9d721d",
              "builtID": "va-lower-79",
              "externalID": "ocd-person/359eaeba-903c-42b4-b9e3-5c5b4e9d721d",
              "geometry": null
            },
            "contactInfo": {
              "email": "delrcousins@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H356",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0356"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-79-lower-ocd-person-359eaeba-903c-42b4-b9e3-5c5b4e9d721d": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/43d936f6-2ae8-42fe-a9a9-a4f139842afa",
            "firstName": "Destiny",
            "lastName": "LeVere Bolling",
            "fullName": "Destiny LeVere Bolling",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Destiny_LeVere_Bolling.jpg",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-VA-80-lower-ocd-person-43d936f6-2ae8-42fe-a9a9-a4f139842afa",
              "builtID": "va-lower-80",
              "externalID": "ocd-person/43d936f6-2ae8-42fe-a9a9-a4f139842afa",
              "geometry": null
            },
            "contactInfo": {
              "email": "deldleverebolling@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H372",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0372"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-80-lower-ocd-person-43d936f6-2ae8-42fe-a9a9-a4f139842afa": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/4ae07d5d-1da3-4370-b796-7698013ca994",
            "firstName": "Delores L.",
            "lastName": "McQuinn",
            "fullName": "Delores McQuinn",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0207",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-VA-81-lower-ocd-person-4ae07d5d-1da3-4370-b796-7698013ca994",
              "builtID": "va-lower-81",
              "externalID": "ocd-person/4ae07d5d-1da3-4370-b796-7698013ca994",
              "geometry": null
            },
            "contactInfo": {
              "email": "deldmcquinn@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 914, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1081",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "mcquinncampaign",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H207",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H207",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H207",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H207",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0207"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-81-lower-ocd-person-4ae07d5d-1da3-4370-b796-7698013ca994": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/66c4b409-9613-4d42-9d53-4adc065e3a91",
            "firstName": "Kimberly A.",
            "lastName": "Taylor",
            "fullName": "Kim Taylor",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Kim-Taylor.jpg",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-VA-82-lower-ocd-person-66c4b409-9613-4d42-9d53-4adc065e3a91",
              "builtID": "va-lower-82",
              "externalID": "ocd-person/66c4b409-9613-4d42-9d53-4adc065e3a91",
              "geometry": null
            },
            "contactInfo": {
              "email": "delktaylor@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1005, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1082",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H346",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H346",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0346"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-82-lower-ocd-person-66c4b409-9613-4d42-9d53-4adc065e3a91": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/496f9aa1-91ba-4386-9530-f52ea56e17bf",
            "firstName": "Howard Otto",
            "lastName": "Wachsmann",
            "fullName": "Otto Wachsmann",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0347",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-VA-83-lower-ocd-person-496f9aa1-91ba-4386-9530-f52ea56e17bf",
              "builtID": "va-lower-83",
              "externalID": "ocd-person/496f9aa1-91ba-4386-9530-f52ea56e17bf",
              "geometry": null
            },
            "contactInfo": {
              "email": "delowachsmann@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 704, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1083",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H347",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H347",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0347"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-83-lower-ocd-person-496f9aa1-91ba-4386-9530-f52ea56e17bf": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/a7fd18db-f30c-45c6-aa90-bdb6f2ce412b",
            "firstName": "Nadarius E.",
            "lastName": "Clark",
            "fullName": "Nadarius Clark",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0336",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-VA-84-lower-ocd-person-a7fd18db-f30c-45c6-aa90-bdb6f2ce412b",
              "builtID": "va-lower-84",
              "externalID": "ocd-person/a7fd18db-f30c-45c6-aa90-bdb6f2ce412b",
              "geometry": null
            },
            "contactInfo": {
              "email": "delnclark@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 820, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1084",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H336",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H336",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0336"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-84-lower-ocd-person-a7fd18db-f30c-45c6-aa90-bdb6f2ce412b": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/58b46062-f618-4b27-b0ec-3bbb57db839b",
            "firstName": "Marcia Simone",
            "lastName": "Price",
            "fullName": "Cia Price",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0284",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-VA-85-lower-ocd-person-58b46062-f618-4b27-b0ec-3bbb57db839b",
              "builtID": "va-lower-85",
              "externalID": "ocd-person/58b46062-f618-4b27-b0ec-3bbb57db839b",
              "geometry": null
            },
            "contactInfo": {
              "email": "delmprice@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 911, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1085",
              "fax1": null,
              "fax2": null,
              "twitter": "PriceForDel95",
              "youtube": null,
              "instagram": null,
              "facebook": "PriceForDel95",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H284",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H284",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H284",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H284",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0284"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-85-lower-ocd-person-58b46062-f618-4b27-b0ec-3bbb57db839b": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/893606ce-e251-4dad-8a0f-f101acde8a7f",
            "firstName": "Aijalon",
            "lastName": "Cordoza",
            "fullName": "A.C. Cordoza",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Aijalon_Cordoza.jpg",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-VA-86-lower-ocd-person-893606ce-e251-4dad-8a0f-f101acde8a7f",
              "builtID": "va-lower-86",
              "externalID": "ocd-person/893606ce-e251-4dad-8a0f-f101acde8a7f",
              "geometry": null
            },
            "contactInfo": {
              "email": "delaccordoza@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 719, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1086",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H337",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H337",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0337"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-86-lower-ocd-person-893606ce-e251-4dad-8a0f-f101acde8a7f": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/f3e15ab0-8419-43c2-ab7a-f3984419d1eb",
            "firstName": "Jeion Antonia",
            "lastName": "Ward",
            "fullName": "Jeion Ward",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0173",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-VA-87-lower-ocd-person-f3e15ab0-8419-43c2-ab7a-f3984419d1eb",
              "builtID": "va-lower-87",
              "externalID": "ocd-person/f3e15ab0-8419-43c2-ab7a-f3984419d1eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "deljward@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1009, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1087",
              "fax1": null,
              "fax2": null,
              "twitter": "jeionward",
              "youtube": null,
              "instagram": null,
              "facebook": "delegatejeionward",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H173",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H173",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H173",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H173",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0173"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-87-lower-ocd-person-f3e15ab0-8419-43c2-ab7a-f3984419d1eb": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/f8cafb3b-ed12-40e9-b062-318790434179",
            "firstName": "Don L.",
            "lastName": "Scott",
            "fullName": "Don Scott",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0322",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-VA-88-lower-ocd-person-f8cafb3b-ed12-40e9-b062-318790434179",
              "builtID": "va-lower-88",
              "externalID": "ocd-person/f8cafb3b-ed12-40e9-b062-318790434179",
              "geometry": null
            },
            "contactInfo": {
              "email": "deldscott@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1403, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1088",
              "fax1": null,
              "fax2": null,
              "twitter": "donscott757",
              "youtube": null,
              "instagram": null,
              "facebook": "friendsofdonscott",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H322",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H322",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H322",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0322"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-88-lower-ocd-person-f8cafb3b-ed12-40e9-b062-318790434179": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/aa4f492f-1d36-4605-a6fa-0b74767171ed",
            "firstName": "N. Baxter",
            "lastName": "Ennis",
            "fullName": "Baxter Ennis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0359",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-VA-89-lower-ocd-person-aa4f492f-1d36-4605-a6fa-0b74767171ed",
              "builtID": "va-lower-89",
              "externalID": "ocd-person/aa4f492f-1d36-4605-a6fa-0b74767171ed",
              "geometry": null
            },
            "contactInfo": {
              "email": "delbennis@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 15807, Chesapeake, VA 23328",
              "phone1": null,
              "phone2": "757-354-3751",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H359",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0359"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-89-lower-ocd-person-aa4f492f-1d36-4605-a6fa-0b74767171ed": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/a551cb4b-014c-4423-a9d7-7a35cc0c7bdd",
            "firstName": "James Asbury",
            "lastName": "Leftwich",
            "fullName": "Jay Leftwich",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0262",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-VA-90-lower-ocd-person-a551cb4b-014c-4423-a9d7-7a35cc0c7bdd",
              "builtID": "va-lower-90",
              "externalID": "ocd-person/a551cb4b-014c-4423-a9d7-7a35cc0c7bdd",
              "geometry": null
            },
            "contactInfo": {
              "email": "deljleftwich@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1008, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1090",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "Delegate-Jay-Leftwich-237237813089964",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H262",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H262",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H262",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H262",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0262"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-90-lower-ocd-person-a551cb4b-014c-4423-a9d7-7a35cc0c7bdd": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/77b071e3-b8fa-4455-b2e6-3d487a8145c8",
            "firstName": "Clifton Eugene",
            "lastName": "Hayes",
            "fullName": "Cliff Hayes",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0285",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-VA-91-lower-ocd-person-77b071e3-b8fa-4455-b2e6-3d487a8145c8",
              "builtID": "va-lower-91",
              "externalID": "ocd-person/77b071e3-b8fa-4455-b2e6-3d487a8145c8",
              "geometry": null
            },
            "contactInfo": {
              "email": "delchayes@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1111, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1091",
              "fax1": null,
              "fax2": null,
              "twitter": "CliffHayesJr",
              "youtube": null,
              "instagram": null,
              "facebook": "CliffHayesJr",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H285",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H285",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H285",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H285",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0285"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-91-lower-ocd-person-77b071e3-b8fa-4455-b2e6-3d487a8145c8": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/725be36e-2485-4f9f-a15f-86c574cc8d4b",
            "firstName": "Bonita Grace",
            "lastName": "Anthony",
            "fullName": "Bonita Anthony",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0353",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-VA-92-lower-ocd-person-725be36e-2485-4f9f-a15f-86c574cc8d4b",
              "builtID": "va-lower-92",
              "externalID": "ocd-person/725be36e-2485-4f9f-a15f-86c574cc8d4b",
              "geometry": null
            },
            "contactInfo": {
              "email": "delbanthony@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1853, Norfolk, VA 23501",
              "phone1": null,
              "phone2": "757-204-5492",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H353",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0353"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-92-lower-ocd-person-725be36e-2485-4f9f-a15f-86c574cc8d4b": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/dae8ce89-647a-407a-9550-60c404a89e29",
            "firstName": "Jackie Hope",
            "lastName": "Glass",
            "fullName": "Jackie Glass",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0349",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-VA-93-lower-ocd-person-dae8ce89-647a-407a-9550-60c404a89e29",
              "builtID": "va-lower-93",
              "externalID": "ocd-person/dae8ce89-647a-407a-9550-60c404a89e29",
              "geometry": null
            },
            "contactInfo": {
              "email": "deljglass@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1011, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1093",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H349",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H349",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0349"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-93-lower-ocd-person-dae8ce89-647a-407a-9550-60c404a89e29": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/bbd31428-1b2b-48c6-aa76-cb785e91ca8f",
            "firstName": "Philip",
            "lastName": "Hernandez",
            "fullName": "Phil Hernandez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0366",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-VA-94-lower-ocd-person-bbd31428-1b2b-48c6-aa76-cb785e91ca8f",
              "builtID": "va-lower-94",
              "externalID": "ocd-person/bbd31428-1b2b-48c6-aa76-cb785e91ca8f",
              "geometry": null
            },
            "contactInfo": {
              "email": "delphernandez@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 8927, Norfolk, VA 23503",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H366",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0366"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-94-lower-ocd-person-bbd31428-1b2b-48c6-aa76-cb785e91ca8f": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/3a48fc34-a650-4fd5-acea-e703e2b8de90",
            "firstName": "Alex Q.",
            "lastName": "Askew",
            "fullName": "Alex Askew",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0311",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-VA-95-lower-ocd-person-3a48fc34-a650-4fd5-acea-e703e2b8de90",
              "builtID": "va-lower-95",
              "externalID": "ocd-person/3a48fc34-a650-4fd5-acea-e703e2b8de90",
              "geometry": null
            },
            "contactInfo": {
              "email": "delaaskew@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 64544, Virginia Beach, VA 23467",
              "phone1": null,
              "phone2": "757-574-0637",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H311",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0311"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-95-lower-ocd-person-3a48fc34-a650-4fd5-acea-e703e2b8de90": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/bf558d98-23da-41c4-8ae2-4dc45631808c",
            "firstName": "Kelly Kristen",
            "lastName": "Convirs-Fowler",
            "fullName": "Kelly Fowler",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0295",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-VA-96-lower-ocd-person-bf558d98-23da-41c4-8ae2-4dc45631808c",
              "builtID": "va-lower-96",
              "externalID": "ocd-person/bf558d98-23da-41c4-8ae2-4dc45631808c",
              "geometry": null
            },
            "contactInfo": {
              "email": "delkconvirs-fowler@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 912, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1096",
              "fax1": null,
              "fax2": null,
              "twitter": "delegatefowler",
              "youtube": null,
              "instagram": null,
              "facebook": "DelegateFowler",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H295",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H295",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H295",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H295",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0295"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-96-lower-ocd-person-bf558d98-23da-41c4-8ae2-4dc45631808c": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/cbf3268e-ced2-4aad-8ed1-18e4f37534ec",
            "firstName": "Michael Benjamin",
            "lastName": "Feggans",
            "fullName": "Michael Feggans",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://images.squarespace-cdn.com/content/v1/598f6428d482e9b47f6c2f82/56c91f83-d16a-4ba2-adcb-75d312e265bd/Michael_Feggans_HD97.jpeg",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-VA-97-lower-ocd-person-cbf3268e-ced2-4aad-8ed1-18e4f37534ec",
              "builtID": "va-lower-97",
              "externalID": "ocd-person/cbf3268e-ced2-4aad-8ed1-18e4f37534ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "delmfeggans@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "900 Commonwealth Place PMB 2141, Virginia Beach, VA 23464",
              "phone1": null,
              "phone2": "757-204-1524",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H360",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0360"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-97-lower-ocd-person-cbf3268e-ced2-4aad-8ed1-18e4f37534ec": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/ad635a25-a109-47ab-97dc-9b7042d65da1",
            "firstName": "Barry D.",
            "lastName": "Knight",
            "fullName": "Barry Knight",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0206",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-VA-98-lower-ocd-person-ad635a25-a109-47ab-97dc-9b7042d65da1",
              "builtID": "va-lower-98",
              "externalID": "ocd-person/ad635a25-a109-47ab-97dc-9b7042d65da1",
              "geometry": null
            },
            "contactInfo": {
              "email": "delbknight@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 809, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1098",
              "fax1": null,
              "fax2": null,
              "twitter": "BarryKnight81",
              "youtube": null,
              "instagram": null,
              "facebook": "BarryKnight81",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H206",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H206",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H206",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H206",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0206"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-98-lower-ocd-person-ad635a25-a109-47ab-97dc-9b7042d65da1": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/9fd6fbd7-5e4e-4426-9d5a-43173ab01489",
            "firstName": "Anne Ferrell Hager",
            "lastName": "Tata",
            "fullName": "Anne Ferrell Tata",
            "gender": "Female",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/_Anne_Ferrell_Tata_.jpg",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-VA-99-lower-ocd-person-9fd6fbd7-5e4e-4426-9d5a-43173ab01489",
              "builtID": "va-lower-99",
              "externalID": "ocd-person/9fd6fbd7-5e4e-4426-9d5a-43173ab01489",
              "geometry": null
            },
            "contactInfo": {
              "email": "delaftata@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1019, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1099",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H345",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H345",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0345"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-99-lower-ocd-person-9fd6fbd7-5e4e-4426-9d5a-43173ab01489": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/10868c64-b4b8-41ca-90ac-a981f9fa47ff",
            "firstName": "Robert Spurgeon",
            "lastName": "Bloxom",
            "fullName": "Rob Bloxom",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0267",
            "title": "VA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-VA-100-lower-ocd-person-10868c64-b4b8-41ca-90ac-a981f9fa47ff",
              "builtID": "va-lower-100",
              "externalID": "ocd-person/10868c64-b4b8-41ca-90ac-a981f9fa47ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "delrbloxom@house.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 711, General Assembly Building 201 N. 9th St., Richmond, VA 23219",
              "phone1": null,
              "phone2": "804-698-1000",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "electbloxomfordelegate",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H267",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H267",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H267",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+H267",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0267"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-VA-100-lower-ocd-person-10868c64-b4b8-41ca-90ac-a981f9fa47ff": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/ba57d68e-01e5-46ed-b305-ec08dece665b",
            "firstName": "Timmy F.",
            "lastName": "French",
            "fullName": "Timmy French",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/TimmyFrench2023.jpg",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-VA-1-upper-ocd-person-ba57d68e-01e5-46ed-b305-ec08dece665b",
              "builtID": "va-upper-1",
              "externalID": "ocd-person/ba57d68e-01e5-46ed-b305-ec08dece665b",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorfrench@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 628, Woodstock, VA 22664",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S121",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S121"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-1-upper-ocd-person-ba57d68e-01e5-46ed-b305-ec08dece665b": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/b5ce9d0e-c76e-4424-bb84-57ceb335dc52",
            "firstName": "Mark Dudley",
            "lastName": "Obenshain",
            "fullName": "Mark Obenshain",
            "gender": "Male",
            "party": "Republican",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Obenshain2",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-VA-2-upper-ocd-person-b5ce9d0e-c76e-4424-bb84-57ceb335dc52",
              "builtID": "va-upper-2",
              "externalID": "ocd-person/b5ce9d0e-c76e-4424-bb84-57ceb335dc52",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorobenshain@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 555, Harrisonburg, VA 22803",
              "phone1": null,
              "phone2": "540-437-1451",
              "fax1": null,
              "fax2": "540-437-3101",
              "twitter": "MarkObenshain",
              "youtube": null,
              "instagram": null,
              "facebook": "markobenshain",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S68",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S68",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S68",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S68",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S68"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-2-upper-ocd-person-b5ce9d0e-c76e-4424-bb84-57ceb335dc52": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/3a8cbf64-e1b7-43b6-ade9-57808519405a",
            "firstName": "Christopher T.",
            "lastName": "Head",
            "fullName": "Chris Head",
            "gender": "Male",
            "party": "Republican",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Head3",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-VA-3-upper-ocd-person-3a8cbf64-e1b7-43b6-ade9-57808519405a",
              "builtID": "va-upper-3",
              "externalID": "ocd-person/3a8cbf64-e1b7-43b6-ade9-57808519405a",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorhead@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 19130, Roanoke, VA 24019",
              "phone1": null,
              "phone2": "540-283-2839",
              "fax1": null,
              "fax2": null,
              "twitter": "DelChrisHead",
              "youtube": null,
              "instagram": null,
              "facebook": "DelChrisHead",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H237",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H237",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H237",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S122",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S122"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-3-upper-ocd-person-3a8cbf64-e1b7-43b6-ade9-57808519405a": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/73e0de29-93b1-4c5c-af72-6c4a3d3950bd",
            "firstName": "David Robert",
            "lastName": "Suetterlein",
            "fullName": "David Suetterlein",
            "gender": "Male",
            "party": "Republican",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Suetterlein4",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-VA-4-upper-ocd-person-73e0de29-93b1-4c5c-af72-6c4a3d3950bd",
              "builtID": "va-upper-4",
              "externalID": "ocd-person/73e0de29-93b1-4c5c-af72-6c4a3d3950bd",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorsuetterlein@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 20237, Roanoke, VA 24018",
              "phone1": null,
              "phone2": "540-302-8486",
              "fax1": null,
              "fax2": null,
              "twitter": "DRSuetterlein",
              "youtube": null,
              "instagram": null,
              "facebook": "suetterlein",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S101",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S101",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S101",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S101",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S101"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-4-upper-ocd-person-73e0de29-93b1-4c5c-af72-6c4a3d3950bd": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/3605a4dd-d800-4820-a50c-6cd3b0ab4511",
            "firstName": "Thurmon Travis",
            "lastName": "Hackworth",
            "fullName": "Travis Hackworth",
            "gender": "Male",
            "party": "Republican",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Hackworth5",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-VA-5-upper-ocd-person-3605a4dd-d800-4820-a50c-6cd3b0ab4511",
              "builtID": "va-upper-5",
              "externalID": "ocd-person/3605a4dd-d800-4820-a50c-6cd3b0ab4511",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorhackworth@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "1515 2nd St., Richlands, VA 24641",
              "phone1": null,
              "phone2": "276-345-9112",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S112",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S112",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S112"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-5-upper-ocd-person-3605a4dd-d800-4820-a50c-6cd3b0ab4511": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/0d2983ef-93a5-4d84-9fa3-2c796024562a",
            "firstName": "Todd Edward",
            "lastName": "Pillion",
            "fullName": "Todd Pillion",
            "gender": "Male",
            "party": "Republican",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Pillion6",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-VA-6-upper-ocd-person-0d2983ef-93a5-4d84-9fa3-2c796024562a",
              "builtID": "va-upper-6",
              "externalID": "ocd-person/0d2983ef-93a5-4d84-9fa3-2c796024562a",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorpillion@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "102 Court St. NE Suite 201, Abingdon, VA 24210",
              "phone1": null,
              "phone2": "276-220-1209",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H270",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S111",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S111",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S111",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S111"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-6-upper-ocd-person-0d2983ef-93a5-4d84-9fa3-2c796024562a": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/eacc546e-93d4-4189-92fb-8c969b1b545c",
            "firstName": "William M.",
            "lastName": "Stanley",
            "fullName": "Bill Stanley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Bill_Stanley.jpg",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-VA-7-upper-ocd-person-eacc546e-93d4-4189-92fb-8c969b1b545c",
              "builtID": "va-upper-7",
              "externalID": "ocd-person/eacc546e-93d4-4189-92fb-8c969b1b545c",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorstanley@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 96, Glade Hill, VA 24092",
              "phone1": null,
              "phone2": "540-821-3066",
              "fax1": null,
              "fax2": "540-721-6405",
              "twitter": "BillStanley",
              "youtube": null,
              "instagram": null,
              "facebook": "stanleyforsenate",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S82",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S82",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S82",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S82",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S82"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-7-upper-ocd-person-eacc546e-93d4-4189-92fb-8c969b1b545c": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/e4396bfa-d22d-457e-b79a-f4bc36046a08",
            "firstName": "Mark Joseph",
            "lastName": "Peake",
            "fullName": "Mark Peake",
            "gender": "Male",
            "party": "Republican",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Peake8",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-VA-8-upper-ocd-person-e4396bfa-d22d-457e-b79a-f4bc36046a08",
              "builtID": "va-upper-8",
              "externalID": "ocd-person/e4396bfa-d22d-457e-b79a-f4bc36046a08",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorpeake@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "4925 Boonsboro Road Box 172, Lynchburg, VA 24503",
              "phone1": null,
              "phone2": "434-455-3392",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": "peakeforsenate",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S105",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S105",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S105",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S105",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S105"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-8-upper-ocd-person-e4396bfa-d22d-457e-b79a-f4bc36046a08": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/c3391ffd-a783-4d34-81ca-ecad4cd6d16a",
            "firstName": "Tammy Brankley",
            "lastName": "Mulchi",
            "fullName": "Tammy Mulchi",
            "gender": "Female",
            "party": "Republican",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Mulchi9",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-VA-9-upper-ocd-person-c3391ffd-a783-4d34-81ca-ecad4cd6d16a",
              "builtID": "va-upper-9",
              "externalID": "ocd-person/c3391ffd-a783-4d34-81ca-ecad4cd6d16a",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatormulchi@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 145, Clarksville, VA 23927",
              "phone1": null,
              "phone2": "434-374-5129",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/241/mbr/MBR.HTM",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S131"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-9-upper-ocd-person-c3391ffd-a783-4d34-81ca-ecad4cd6d16a": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/0dd787e8-c62a-46a9-aa0e-915f574e75d2",
            "firstName": "John Joseph",
            "lastName": "McGuire",
            "fullName": "John McGuire",
            "gender": "Male",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0300",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-VA-10-upper-ocd-person-0dd787e8-c62a-46a9-aa0e-915f574e75d2",
              "builtID": "va-upper-10",
              "externalID": "ocd-person/0dd787e8-c62a-46a9-aa0e-915f574e75d2",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatormcguire@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 168, Manakin-Sabot, VA 23103",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H300",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H300",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H300",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S123",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S123"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-10-upper-ocd-person-0dd787e8-c62a-46a9-aa0e-915f574e75d2": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/ad107e73-7e1e-45dd-ab19-52005a0b948c",
            "firstName": "Robert Creigh",
            "lastName": "Deeds",
            "fullName": "Creigh Deeds",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Deeds11",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-VA-11-upper-ocd-person-ad107e73-7e1e-45dd-ab19-52005a0b948c",
              "builtID": "va-upper-11",
              "externalID": "ocd-person/ad107e73-7e1e-45dd-ab19-52005a0b948c",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatordeeds@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 5462, Charlottesville, VA 22905",
              "phone1": null,
              "phone2": "434-296-5491",
              "fax1": null,
              "fax2": null,
              "twitter": "CreighDeeds",
              "youtube": null,
              "instagram": null,
              "facebook": "creigh",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S62",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S62",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S62",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S62",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S62"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-11-upper-ocd-person-ad107e73-7e1e-45dd-ab19-52005a0b948c": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/815f7b31-d344-481a-9922-ad77d16b808a",
            "firstName": "Glen Howard",
            "lastName": "Sturtevant",
            "fullName": "Glen Sturtevant",
            "gender": "Male",
            "party": "Republican",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Sturtevant12",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-VA-12-upper-ocd-person-815f7b31-d344-481a-9922-ad77d16b808a",
              "builtID": "va-upper-12",
              "externalID": "ocd-person/815f7b31-d344-481a-9922-ad77d16b808a",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorsturtevant@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 2535, Midlothian, VA 23113",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S99",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S99"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-12-upper-ocd-person-815f7b31-d344-481a-9922-ad77d16b808a": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/448bfee3-3314-4608-8531-811fdf9d0c23",
            "firstName": "Lashrecse Dianna",
            "lastName": "Aird",
            "fullName": "Lashrecse Aird",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Aird13",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-VA-13-upper-ocd-person-448bfee3-3314-4608-8531-811fdf9d0c23",
              "builtID": "va-upper-13",
              "externalID": "ocd-person/448bfee3-3314-4608-8531-811fdf9d0c23",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatoraird@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 3943, Petersburg, VA 23805",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S115",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S115"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-13-upper-ocd-person-448bfee3-3314-4608-8531-811fdf9d0c23": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/fa49c701-d235-4f56-b9af-b772dec36d02",
            "firstName": "Lamont",
            "lastName": "Bagby",
            "fullName": "Lamont Bagby",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Bagby14",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-VA-14-upper-ocd-person-fa49c701-d235-4f56-b9af-b772dec36d02",
              "builtID": "va-upper-14",
              "externalID": "ocd-person/fa49c701-d235-4f56-b9af-b772dec36d02",
              "geometry": null
            },
            "contactInfo": {
              "email": "lbagby@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 601, General Assembly Building P.O. Box 396, Richmond, VA 23218",
              "phone1": null,
              "phone2": "804-698-7509",
              "fax1": null,
              "fax2": null,
              "twitter": "delegatebagby",
              "youtube": null,
              "instagram": null,
              "facebook": "lamont.bagby",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H273",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H273",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H273",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S114",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S114"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-14-upper-ocd-person-fa49c701-d235-4f56-b9af-b772dec36d02": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/2ae6c10e-e6d0-4033-927f-0b067b2ba0fc",
            "firstName": "Ghazala Firdous",
            "lastName": "Hashmi",
            "fullName": "Ghazala Hashmi",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Hashmi15",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-VA-15-upper-ocd-person-2ae6c10e-e6d0-4033-927f-0b067b2ba0fc",
              "builtID": "va-upper-15",
              "externalID": "ocd-person/2ae6c10e-e6d0-4033-927f-0b067b2ba0fc",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorhashmi@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "Hashmi4Va",
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorGhazalaHashmi",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S108",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S108",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S108",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S108"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-15-upper-ocd-person-2ae6c10e-e6d0-4033-927f-0b067b2ba0fc": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/aec7ee58-7da0-41e1-88d0-5c9e68737a0f",
            "firstName": "Schuyler Thomas",
            "lastName": "VanValkenburg",
            "fullName": "Schuyler VanValkenburg",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/VanValkenburg16",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-VA-16-upper-ocd-person-aec7ee58-7da0-41e1-88d0-5c9e68737a0f",
              "builtID": "va-upper-16",
              "externalID": "ocd-person/aec7ee58-7da0-41e1-88d0-5c9e68737a0f",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorvanvalkenburg@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 28782, Richmond, VA 23228",
              "phone1": null,
              "phone2": "804-223-0669",
              "fax1": null,
              "fax2": null,
              "twitter": "vvfordelegate",
              "youtube": null,
              "instagram": null,
              "facebook": "VanValkenburg4VA",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H307",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H307",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H307",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S129",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S129"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-16-upper-ocd-person-aec7ee58-7da0-41e1-88d0-5c9e68737a0f": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/f1a906cd-6602-492c-88dc-61887401c346",
            "firstName": "Emily M.",
            "lastName": "Jordan",
            "fullName": "Emily Jordan",
            "gender": "Female",
            "party": "Republican",
            "image": "https://memdata.virginiageneralassembly.gov/images/display_image/H0291",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-VA-17-upper-ocd-person-f1a906cd-6602-492c-88dc-61887401c346",
              "builtID": "va-upper-17",
              "externalID": "ocd-person/f1a906cd-6602-492c-88dc-61887401c346",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorjordan@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 5, Smithfield, VA 23431",
              "phone1": null,
              "phone2": "757-239-1213",
              "fax1": null,
              "fax2": null,
              "twitter": "BrewerForVA",
              "youtube": null,
              "instagram": null,
              "facebook": "BrewerForVA",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H291",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H291",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H291",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S116",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S116"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-17-upper-ocd-person-f1a906cd-6602-492c-88dc-61887401c346": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/5be2e2f9-b644-4fb4-bfbf-d4c38e858ee2",
            "firstName": "Lillie Louise Boone",
            "lastName": "Lucas",
            "fullName": "Louise Lucas",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://apps.dlas.virginia.gov/senatepics/newbiopics/Lucas2018.jpg",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-VA-18-upper-ocd-person-5be2e2f9-b644-4fb4-bfbf-d4c38e858ee2",
              "builtID": "va-upper-18",
              "externalID": "ocd-person/5be2e2f9-b644-4fb4-bfbf-d4c38e858ee2",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorlucas@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 1404, General Assembly Building P.O. Box 396, Richmond, VA 23218",
              "phone1": null,
              "phone2": "804-698-7518",
              "fax1": null,
              "fax2": "804-698-7651",
              "twitter": "SenLouiseLucas",
              "youtube": null,
              "instagram": null,
              "facebook": "SenLouiseLucas",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S19",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S19",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S19",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S19",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S19"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-18-upper-ocd-person-5be2e2f9-b644-4fb4-bfbf-d4c38e858ee2": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/f17d0fd9-266c-4192-af9b-2cf9a621867a",
            "firstName": "Christie New",
            "lastName": "Craig",
            "fullName": "Christie Craig",
            "gender": "Female",
            "party": "Republican",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Craig19",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-VA-19-upper-ocd-person-f17d0fd9-266c-4192-af9b-2cf9a621867a",
              "builtID": "va-upper-19",
              "externalID": "ocd-person/f17d0fd9-266c-4192-af9b-2cf9a621867a",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorcraig@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 16926, Chesapeake, VA 23228",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S118",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S118"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-19-upper-ocd-person-f17d0fd9-266c-4192-af9b-2cf9a621867a": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/ec14a514-ce1c-4fba-b053-fcf133793445",
            "firstName": "William Robert",
            "lastName": "DeSteph",
            "fullName": "Bill DeSteph",
            "gender": "Male",
            "party": "Republican",
            "image": "https://apps.dlas.virginia.gov/senatepics/newbiopics/DeSteph08.jpg",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-VA-20-upper-ocd-person-ec14a514-ce1c-4fba-b053-fcf133793445",
              "builtID": "va-upper-20",
              "externalID": "ocd-person/ec14a514-ce1c-4fba-b053-fcf133793445",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatordesteph@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "588 Central Drive, Virginia Beach, VA 23454",
              "phone1": null,
              "phone2": "757-321-8180",
              "fax1": null,
              "fax2": "757-631-6150",
              "twitter": "BillDeSteph",
              "youtube": null,
              "instagram": null,
              "facebook": "destephforva",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S96",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S96",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S96",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S96",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S96"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-20-upper-ocd-person-ec14a514-ce1c-4fba-b053-fcf133793445": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/f747c790-f609-4da1-b125-73f1807e1430",
            "firstName": "Angelia Marie Williams",
            "lastName": "Graves",
            "fullName": "Angelia Graves",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Williams%20Graves21",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-VA-21-upper-ocd-person-f747c790-f609-4da1-b125-73f1807e1430",
              "builtID": "va-upper-21",
              "externalID": "ocd-person/f747c790-f609-4da1-b125-73f1807e1430",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorwilliamsgraves@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 41359, Norfolk, VA 23502",
              "phone1": null,
              "phone2": "757-524-4941",
              "fax1": null,
              "fax2": null,
              "twitter": "AWGNorfolk",
              "youtube": null,
              "instagram": null,
              "facebook": "awgnorfolk",
              "urls": [
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0331",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H331",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S130",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S130"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-21-upper-ocd-person-f747c790-f609-4da1-b125-73f1807e1430": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/4a329580-86d3-4db3-915e-9f1af71e0a5a",
            "firstName": "Aaron Roosevelt",
            "lastName": "Rouse",
            "fullName": "Aaron Rouse",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Rouse22",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-VA-22-upper-ocd-person-4a329580-86d3-4db3-915e-9f1af71e0a5a",
              "builtID": "va-upper-22",
              "externalID": "ocd-person/4a329580-86d3-4db3-915e-9f1af71e0a5a",
              "geometry": null
            },
            "contactInfo": {
              "email": "district07@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 2864, Virginia Beach, VA 23450",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S113",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S113"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-22-upper-ocd-person-4a329580-86d3-4db3-915e-9f1af71e0a5a": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/48911708-f8c9-4fa5-bf7c-7d035b08874e",
            "firstName": "Mamie Evelyn",
            "lastName": "Locke",
            "fullName": "Mamie Locke",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://www.senatorlocke.com/assets/img/mamie_locke.jpg",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-VA-23-upper-ocd-person-48911708-f8c9-4fa5-bf7c-7d035b08874e",
              "builtID": "va-upper-23",
              "externalID": "ocd-person/48911708-f8c9-4fa5-bf7c-7d035b08874e",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorlocke@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 9048, Hampton, VA 23670",
              "phone1": null,
              "phone2": "757-825-5880",
              "fax1": null,
              "fax2": "757-825-7327",
              "twitter": "SenatorLocke",
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorLocke",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S67",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S67",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S67",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S67",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S67"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-23-upper-ocd-person-48911708-f8c9-4fa5-bf7c-7d035b08874e": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/ebdc449e-b987-4c44-895b-5047e343c0a0",
            "firstName": "Joseph Daniel",
            "lastName": "Diggs",
            "fullName": "Danny Diggs",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/DiggsDanny.jpg",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-VA-24-upper-ocd-person-ebdc449e-b987-4c44-895b-5047e343c0a0",
              "builtID": "va-upper-24",
              "externalID": "ocd-person/ebdc449e-b987-4c44-895b-5047e343c0a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatordiggs@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1, Hampton, VA 23690",
              "phone1": null,
              "phone2": "757-888-7524",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S119",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S119"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-24-upper-ocd-person-ebdc449e-b987-4c44-895b-5047e343c0a0": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/52e67ab6-2039-458e-b7a8-9bb5f3694e11",
            "firstName": "Richard Henry",
            "lastName": "Stuart",
            "fullName": "Richard Stuart",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.newyork1.vip.townnews.com/princewilliamtimes.com/content/tncms/assets/v3/editorial/a/a8/aa81dd7a-f682-11e7-8022-5fcb464ec635/5a56df307838b.image.jpg?resize=350%2C467",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-VA-25-upper-ocd-person-52e67ab6-2039-458e-b7a8-9bb5f3694e11",
              "builtID": "va-upper-25",
              "externalID": "ocd-person/52e67ab6-2039-458e-b7a8-9bb5f3694e11",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorstuart@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 1146, Montross, VA 22520",
              "phone1": null,
              "phone2": "804-493-8892",
              "fax1": null,
              "fax2": "804-493-8897",
              "twitter": "RichardStuartVA",
              "youtube": null,
              "instagram": null,
              "facebook": "richardhstuart",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S78",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S78",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S78",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S78",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S78"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-25-upper-ocd-person-52e67ab6-2039-458e-b7a8-9bb5f3694e11": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/995be769-49d1-40b6-8177-e8ee31087af1",
            "firstName": "Ryan Todd",
            "lastName": "McDougle",
            "fullName": "Ryan McDougle",
            "gender": "Male",
            "party": "Republican",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/McDougle26",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-VA-26-upper-ocd-person-995be769-49d1-40b6-8177-e8ee31087af1",
              "builtID": "va-upper-26",
              "externalID": "ocd-person/995be769-49d1-40b6-8177-e8ee31087af1",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatormcdougle@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 187, Mechanicsville, VA 23111",
              "phone1": null,
              "phone2": "804-730-1026",
              "fax1": null,
              "fax2": "804-730-1051",
              "twitter": "ryanmcdougle",
              "youtube": null,
              "instagram": null,
              "facebook": "SenatorRyanMcDougle",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S69",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S69",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S69",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S69",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S69"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-26-upper-ocd-person-995be769-49d1-40b6-8177-e8ee31087af1": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/e142c869-a7a9-411a-8d73-563403f6ddd4",
            "firstName": "Tara A.",
            "lastName": "Durant",
            "fullName": "Tara Durant",
            "gender": "Female",
            "party": "Republican",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Durant27",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-VA-27-upper-ocd-person-e142c869-a7a9-411a-8d73-563403f6ddd4",
              "builtID": "va-upper-27",
              "externalID": "ocd-person/e142c869-a7a9-411a-8d73-563403f6ddd4",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatordurant@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 7113, Fredricksburg, VA 22404",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H338",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S120",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S120"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-27-upper-ocd-person-e142c869-a7a9-411a-8d73-563403f6ddd4": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/adb58f21-f2fd-4830-85b6-f490b0867d14",
            "firstName": "Bryce E.",
            "lastName": "Reeves",
            "fullName": "Bryce Reeves",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.newyork1.vip.townnews.com/dailyprogress.com/content/tncms/assets/v3/editorial/6/69/669c79dc-5e4d-11e5-9a41-035a0cf0ae04/55fc84263e8ca.image.jpg?resize=1200%2C1628",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-VA-28-upper-ocd-person-adb58f21-f2fd-4830-85b6-f490b0867d14",
              "builtID": "va-upper-28",
              "externalID": "ocd-person/adb58f21-f2fd-4830-85b6-f490b0867d14",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorreeves@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 7021, Fredericksburg, VA 22404",
              "phone1": null,
              "phone2": "540-645-8440",
              "fax1": null,
              "fax2": null,
              "twitter": "ReevesVA",
              "youtube": null,
              "instagram": null,
              "facebook": "BryceReevesVA",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S88",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S88",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S88",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S88",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S88"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-28-upper-ocd-person-adb58f21-f2fd-4830-85b6-f490b0867d14": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/500ff29f-8ae7-4886-93ec-eec1ad91d299",
            "firstName": "Jeremy Scott",
            "lastName": "McPike",
            "fullName": "Jeremy McPike",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/McPike29.jpg",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-VA-29-upper-ocd-person-500ff29f-8ae7-4886-93ec-eec1ad91d299",
              "builtID": "va-upper-29",
              "externalID": "ocd-person/500ff29f-8ae7-4886-93ec-eec1ad91d299",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatormcpike@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 2819, Woodbridge, VA 22195",
              "phone1": null,
              "phone2": "571-316-0581",
              "fax1": null,
              "fax2": null,
              "twitter": "jeremymcpike",
              "youtube": null,
              "instagram": null,
              "facebook": "McPikeforSenate",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S98",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S98",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S98",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S98",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S98"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-29-upper-ocd-person-500ff29f-8ae7-4886-93ec-eec1ad91d299": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/3a2855f7-5ff7-4c5e-9d28-803d99f19d3f",
            "firstName": "Danica A.",
            "lastName": "Roem",
            "fullName": "Danica Roem",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Roem30",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-VA-30-upper-ocd-person-3a2855f7-5ff7-4c5e-9d28-803d99f19d3f",
              "builtID": "va-upper-30",
              "externalID": "ocd-person/3a2855f7-5ff7-4c5e-9d28-803d99f19d3f",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorroem@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 726, Manassas, VA 20113",
              "phone1": null,
              "phone2": "571-393-0242",
              "fax1": null,
              "fax2": null,
              "twitter": "pwcdanica",
              "youtube": null,
              "instagram": null,
              "facebook": "danicafordelegate",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+H303",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H303",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H303",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S126",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0303",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S126"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-30-upper-ocd-person-3a2855f7-5ff7-4c5e-9d28-803d99f19d3f": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/bf0211bf-d8ac-4343-bcd8-181e2d2e58a6",
            "firstName": "Russet Wycuff",
            "lastName": "Perry",
            "fullName": "Russet Perry",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://cdn.runforsomething.net/candidates/2023/04/campaign/3388.jpg",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-VA-31-upper-ocd-person-bf0211bf-d8ac-4343-bcd8-181e2d2e58a6",
              "builtID": "va-upper-31",
              "externalID": "ocd-person/bf0211bf-d8ac-4343-bcd8-181e2d2e58a6",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorperry@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 6434, Leesburg, VA 20178",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S125",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S125"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-31-upper-ocd-person-bf0211bf-d8ac-4343-bcd8-181e2d2e58a6": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/d2a28b1a-6991-4b0b-b582-0414025b7389",
            "firstName": "Suhas",
            "lastName": "Subramanyam",
            "fullName": "Suhas Subramanyam",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Subramanyam32",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-VA-32-upper-ocd-person-d2a28b1a-6991-4b0b-b582-0414025b7389",
              "builtID": "va-upper-32",
              "externalID": "ocd-person/d2a28b1a-6991-4b0b-b582-0414025b7389",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorsubramanyam@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 302, Ashburn, VA 20146",
              "phone1": null,
              "phone2": "571-707-4566",
              "fax1": null,
              "fax2": null,
              "twitter": "SuhasforVA",
              "youtube": null,
              "instagram": null,
              "facebook": "suhasforva",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+H324",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+H324",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S128",
                "https://virginiageneralassembly.gov/house/members/members.php?id=H0324",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S128"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-32-upper-ocd-person-d2a28b1a-6991-4b0b-b582-0414025b7389": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/32dae8e8-9901-4705-b012-f8ce186f8403",
            "firstName": "Jennifer D.",
            "lastName": "Carroll Foy",
            "fullName": "Jennifer Carroll Foy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Carroll%20Foy33",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-VA-33-upper-ocd-person-32dae8e8-9901-4705-b012-f8ce186f8403",
              "builtID": "va-upper-33",
              "externalID": "ocd-person/32dae8e8-9901-4705-b012-f8ce186f8403",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorcarrollfoy@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P. O. Box 5002, Woodbridge, VA 22194",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S117",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S117"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-33-upper-ocd-person-32dae8e8-9901-4705-b012-f8ce186f8403": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/50a6c704-2ba7-4ecc-a24c-8f55b400709b",
            "firstName": "Scott Anthony",
            "lastName": "Surovell",
            "fullName": "Scott Surovell",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Surovell34",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-VA-34-upper-ocd-person-50a6c704-2ba7-4ecc-a24c-8f55b400709b",
              "builtID": "va-upper-34",
              "externalID": "ocd-person/50a6c704-2ba7-4ecc-a24c-8f55b400709b",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorsurovell@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 289, Mount Vernon, VA 22121",
              "phone1": null,
              "phone2": "571-249-4484",
              "fax1": null,
              "fax2": null,
              "twitter": "ssurovell",
              "youtube": null,
              "instagram": null,
              "facebook": "Surovell",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S100",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S100",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S100",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S100",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S100"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-34-upper-ocd-person-50a6c704-2ba7-4ecc-a24c-8f55b400709b": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/22817a2c-911a-458a-b2a6-b7f237ef68f0",
            "firstName": "David W.",
            "lastName": "Marsden",
            "fullName": "Dave Marsden",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Marsden35",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-VA-35-upper-ocd-person-22817a2c-911a-458a-b2a6-b7f237ef68f0",
              "builtID": "va-upper-35",
              "externalID": "ocd-person/22817a2c-911a-458a-b2a6-b7f237ef68f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatormarsden@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 10889, Burke, VA 22009",
              "phone1": null,
              "phone2": "571-249-3037",
              "fax1": null,
              "fax2": null,
              "twitter": "SenDaveMarsden",
              "youtube": null,
              "instagram": null,
              "facebook": "Dave-Marsden-for-Senate-193359812373",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S80",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S80",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S80",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S80",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S80"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-35-upper-ocd-person-22817a2c-911a-458a-b2a6-b7f237ef68f0": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/138f964d-7137-487b-96d6-ad836b67db83",
            "firstName": "Stella Kakissis",
            "lastName": "Pekarsky",
            "fullName": "Stella Pekarsky",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://womencount.org/wp-content/uploads/2023/04/stella-transparent.png",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-VA-36-upper-ocd-person-138f964d-7137-487b-96d6-ad836b67db83",
              "builtID": "va-upper-36",
              "externalID": "ocd-person/138f964d-7137-487b-96d6-ad836b67db83",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorpekarsky@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 231191, Centreville, VA 20120",
              "phone1": null,
              "phone2": "571-449-6794",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S124",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S124"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-36-upper-ocd-person-138f964d-7137-487b-96d6-ad836b67db83": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/8a50ceeb-1ded-4586-a04a-19115fddc3c5",
            "firstName": "Saddam Azlan",
            "lastName": "Salim",
            "fullName": "Saddam Salim",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Salim37",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-VA-37-upper-ocd-person-8a50ceeb-1ded-4586-a04a-19115fddc3c5",
              "builtID": "va-upper-37",
              "externalID": "ocd-person/8a50ceeb-1ded-4586-a04a-19115fddc3c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorsalim@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 249, Merrifield, VA 22116",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S127",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S127"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-37-upper-ocd-person-8a50ceeb-1ded-4586-a04a-19115fddc3c5": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/27eeaa71-8938-412b-90ce-f83370824485",
            "firstName": "Jennifer Barton",
            "lastName": "Boysko",
            "fullName": "Jennifer Boysko",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Boysko38",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-VA-38-upper-ocd-person-27eeaa71-8938-412b-90ce-f83370824485",
              "builtID": "va-upper-38",
              "externalID": "ocd-person/27eeaa71-8938-412b-90ce-f83370824485",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorboysko@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 520, General Assembly Building P.O. Box 396, Richmond, VA 23218",
              "phone1": null,
              "phone2": "804-698-7533",
              "fax1": null,
              "fax2": null,
              "twitter": "JenniferBoysko",
              "youtube": null,
              "instagram": null,
              "facebook": "BoyskoForSenate",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S106",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S106",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S106",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S106",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S106"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-38-upper-ocd-person-27eeaa71-8938-412b-90ce-f83370824485": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/2a48661b-97f1-4b4c-a131-26290542f1ec",
            "firstName": "Adam Paul",
            "lastName": "Ebbin",
            "fullName": "Adam Ebbin",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Ebbin39",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-VA-39-upper-ocd-person-2a48661b-97f1-4b4c-a131-26290542f1ec",
              "builtID": "va-upper-39",
              "externalID": "ocd-person/2a48661b-97f1-4b4c-a131-26290542f1ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorebbin@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "P.O. Box 26415, Alexandria, VA 22313",
              "phone1": null,
              "phone2": "571-384-8957",
              "fax1": null,
              "fax2": null,
              "twitter": "AdamEbbin",
              "youtube": null,
              "instagram": null,
              "facebook": "EbbinCampaign",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S85",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S85",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S85",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S85",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S85"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-39-upper-ocd-person-2a48661b-97f1-4b4c-a131-26290542f1ec": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/72ecc30c-7175-4aef-9762-fda3ba5b451e",
            "firstName": "Barbara A.",
            "lastName": "Favola",
            "fullName": "Barbara Favola",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://apps.senate.virginia.gov/Senator/images/member_photos/Favola40",
            "title": "VA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "VA",
              "stateFull": "Virginia",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-VA-40-upper-ocd-person-72ecc30c-7175-4aef-9762-fda3ba5b451e",
              "builtID": "va-upper-40",
              "externalID": "ocd-person/72ecc30c-7175-4aef-9762-fda3ba5b451e",
              "geometry": null
            },
            "contactInfo": {
              "email": "senatorfavola@senate.virginia.gov",
              "contactForm": null,
              "address1": null,
              "address2": "2319 18th St. North, Arlington, VA 22201",
              "phone1": null,
              "phone2": "703-721-8582",
              "fax1": null,
              "fax2": null,
              "twitter": "BarbaraFavola",
              "youtube": null,
              "instagram": null,
              "facebook": "BarbaraFavola",
              "urls": [
                "https://lis.virginia.gov/cgi-bin/legp604.exe?191+mbr+S86",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?201+mbr+S86",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?221+mbr+S86",
                "https://lis.virginia.gov/cgi-bin/legp604.exe?241+mbr+S86",
                "https://apps.senate.virginia.gov/Senator/memberpage.php?id=S86"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-VA-40-upper-ocd-person-72ecc30c-7175-4aef-9762-fda3ba5b451e": 0
        }
      }
    }
  },
  "WA": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "WA",
          "stateFull": "Washington",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "WA",
            "stateFull": "Washington",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-WA---",
            "builtID": "wa--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-WA---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "C000127",
          "in_office": true,
          "firstName": "Maria",
          "lastName": "Cantwell",
          "middleName": null,
          "fullName": "Maria Cantwell",
          "gender": "F",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/C000127.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "WA",
            "stateFull": "Washington",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-WA---C000127",
            "builtID": "wa--",
            "externalID": "C000127",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.cantwell.senate.gov/public/index.cfm/email-maria",
            "address1": "511 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-3441",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorCantwell",
            "youtube": "SenatorCantwell",
            "instagram": null,
            "facebook": "senatorcantwell",
            "urls": ["https://www.cantwell.senate.gov"],
            "rss_url": null
          },
          "title": "WA Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/C000127.json",
          "govtrack_id": "300018",
          "cspan_id": "26137",
          "votesmart_id": "27122",
          "icpsr_id": "39310",
          "crp_id": "N00007836",
          "google_entity_id": "/m/01x68t",
          "state_rank": "junior",
          "lis_id": "S275",
          "suffix": null,
          "date_of_birth": "1958-10-13",
          "leadership_role": null,
          "fec_candidate_id": "S8WA00194",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "23",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 7,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 1.26,
          "votes_with_party_pct": 98.53,
          "votes_against_party_pct": 1.47,
          "ocd_id": "ocd-division/country:us/state:wa"
        },
        {
          "API_ID": "M001111",
          "in_office": true,
          "firstName": "Patty",
          "lastName": "Murray",
          "middleName": null,
          "fullName": "Patty Murray",
          "gender": "F",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/M001111.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "WA",
            "stateFull": "Washington",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-WA---M001111",
            "builtID": "wa--",
            "externalID": "M001111",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "PattyMurray",
            "youtube": "SenatorPattyMurray",
            "instagram": null,
            "facebook": null,
            "urls": ["https://www.murray.senate.gov"],
            "rss_url": "https://www.murray.senate.gov/category/press-releases/feed/"
          },
          "title": "WA Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/M001111.json",
          "govtrack_id": "300076",
          "cspan_id": "25277",
          "votesmart_id": "53358",
          "icpsr_id": "49308",
          "crp_id": "N00007876",
          "google_entity_id": "/m/018qx5",
          "state_rank": "senior",
          "lis_id": "S229",
          "suffix": null,
          "date_of_birth": "1950-10-11",
          "leadership_role": "Assistant Senate Majority Leader",
          "fec_candidate_id": "S2WA00189",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "31",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 11,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 1.99,
          "votes_with_party_pct": 98.89,
          "votes_against_party_pct": 1.11,
          "ocd_id": "ocd-division/country:us/state:wa"
        }
      ],
      "hash": { "SENATE-WA---C000127": 0, "SENATE-WA---M001111": 1 }
    },
    "HOUSE": {
      "10": {
        "members": [
          {
            "API_ID": "S001159",
            "in_office": true,
            "firstName": "Marilyn",
            "lastName": "Strickland",
            "middleName": null,
            "fullName": "Marilyn Strickland",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001159.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "10",
              "chamber": "",
              "hashID": "HOUSE-WA-10--S001159",
              "builtID": "wa--10",
              "externalID": "S001159",
              "geometry": null,
              "geoid": "5310"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1708 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-9740",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepStricklandWA",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://strickland.house.gov"],
              "rss_url": null
            },
            "title": "WA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001159.json",
            "govtrack_id": "456854",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": "N00046320",
            "google_entity_id": "/m/0bhb38l",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1962-09-25",
            "leadership_role": null,
            "fec_candidate_id": "H0WA10034",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 20,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.89,
            "votes_with_party_pct": 95.91,
            "votes_against_party_pct": 2.14,
            "ocd_id": "ocd-division/country:us/state:wa/cd:10",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WA-10--S001159": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "D000617",
            "in_office": true,
            "firstName": "Suzan",
            "lastName": "DelBene",
            "middleName": "K.",
            "fullName": "Suzan K. DelBene",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/D000617.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-WA-01--D000617",
              "builtID": "wa--01",
              "externalID": "D000617",
              "geometry": null,
              "geoid": "5301"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2330 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-6311",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDelBene",
              "youtube": null,
              "instagram": null,
              "facebook": "RepDelBene",
              "urls": ["https://delbene.house.gov"],
              "rss_url": null
            },
            "title": "WA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/D000617.json",
            "govtrack_id": "412505",
            "cspan_id": "1033929",
            "votesmart_id": "126272",
            "icpsr_id": "31101",
            "crp_id": "N00030693",
            "google_entity_id": "/m/05p0dnk",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1962-02-17",
            "leadership_role": null,
            "fec_candidate_id": "H0WA08046",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "14",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 4,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 0.38,
            "votes_with_party_pct": 96.54,
            "votes_against_party_pct": 1.63,
            "ocd_id": "ocd-division/country:us/state:wa/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WA-01--D000617": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "G000600",
            "in_office": true,
            "firstName": "Marie",
            "lastName": "Gluesenkamp Perez",
            "middleName": null,
            "fullName": "Marie Gluesenkamp Perez",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/G000600.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-WA-03--G000600",
              "builtID": "wa--03",
              "externalID": "G000600",
              "geometry": null,
              "geoid": "5303"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1431 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3536",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMGP",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://gluesenkampperez.house.gov/"],
              "rss_url": null
            },
            "title": "WA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000600.json",
            "govtrack_id": "456949",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1988-06-06",
            "leadership_role": null,
            "fec_candidate_id": "H2WA03217",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 5,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 0.47,
            "votes_with_party_pct": 80.69,
            "votes_against_party_pct": 17.39,
            "ocd_id": "ocd-division/country:us/state:wa/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WA-03--G000600": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "J000298",
            "in_office": true,
            "firstName": "Pramila",
            "lastName": "Jayapal",
            "middleName": null,
            "fullName": "Pramila Jayapal",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/J000298.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-WA-07--J000298",
              "builtID": "wa--07",
              "externalID": "J000298",
              "geometry": null,
              "geoid": "5307"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2346 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-3106",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepJayapal",
              "youtube": null,
              "instagram": null,
              "facebook": "RepJayapal",
              "urls": ["https://jayapal.house.gov"],
              "rss_url": "https://jayapal.house.gov/category/news/feed/"
            },
            "title": "WA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/J000298.json",
            "govtrack_id": "412730",
            "cspan_id": "9267128",
            "votesmart_id": "153141",
            "icpsr_id": "21726",
            "crp_id": "N00038858",
            "google_entity_id": "/m/0c3dxcq",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1965-09-21",
            "leadership_role": null,
            "fec_candidate_id": "H6WA07458",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 26,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.46,
            "votes_with_party_pct": 92.25,
            "votes_against_party_pct": 5.78,
            "ocd_id": "ocd-division/country:us/state:wa/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WA-07--J000298": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "K000381",
            "in_office": true,
            "firstName": "Derek",
            "lastName": "Kilmer",
            "middleName": null,
            "fullName": "Derek Kilmer",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/K000381.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-WA-06--K000381",
              "builtID": "wa--06",
              "externalID": "K000381",
              "geometry": null,
              "geoid": "5306"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1226 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5916",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepDerekKilmer",
              "youtube": null,
              "instagram": null,
              "facebook": "derek.kilmer",
              "urls": ["https://kilmer.house.gov"],
              "rss_url": null
            },
            "title": "WA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/K000381.json",
            "govtrack_id": "412583",
            "cspan_id": "68310",
            "votesmart_id": "51516",
            "icpsr_id": "21368",
            "crp_id": "N00034453",
            "google_entity_id": "/m/03w9yd6",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1974-01-01",
            "leadership_role": null,
            "fec_candidate_id": "H2WA06129",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 13,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.23,
            "votes_with_party_pct": 96.41,
            "votes_against_party_pct": 1.74,
            "ocd_id": "ocd-division/country:us/state:wa/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WA-06--K000381": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "L000560",
            "in_office": true,
            "firstName": "Rick",
            "lastName": "Larsen",
            "middleName": null,
            "fullName": "Rick Larsen",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/L000560.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-WA-02--L000560",
              "builtID": "wa--02",
              "externalID": "L000560",
              "geometry": null,
              "geoid": "5302"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2163 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2605",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepRickLarsen",
              "youtube": "congressmanlarsen",
              "instagram": null,
              "facebook": "RepRickLarsen",
              "urls": ["https://larsen.house.gov"],
              "rss_url": null
            },
            "title": "WA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/L000560.json",
            "govtrack_id": "400232",
            "cspan_id": "86610",
            "votesmart_id": "56231",
            "icpsr_id": "20145",
            "crp_id": "N00009759",
            "google_entity_id": "/m/025953",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1965-06-15",
            "leadership_role": null,
            "fec_candidate_id": "H0WA02080",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "24",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 54,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 5.12,
            "votes_with_party_pct": 95.56,
            "votes_against_party_pct": 2.52,
            "ocd_id": "ocd-division/country:us/state:wa/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WA-02--L000560": 0 }
      },
      "05": {
        "members": [
          {
            "API_ID": "M001159",
            "in_office": true,
            "firstName": "Cathy",
            "lastName": "McMorris Rodgers",
            "middleName": null,
            "fullName": "Cathy McMorris Rodgers",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001159.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-WA-05--M001159",
              "builtID": "wa--05",
              "externalID": "M001159",
              "geometry": null,
              "geoid": "5305"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2188 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2006",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "CathyMcMorris",
              "youtube": "mcmorrisrodgers",
              "instagram": null,
              "facebook": "mcmorrisrodgers",
              "urls": ["https://mcmorris.house.gov"],
              "rss_url": null
            },
            "title": "WA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001159.json",
            "govtrack_id": "400659",
            "cspan_id": "1013063",
            "votesmart_id": "3217",
            "icpsr_id": "20535",
            "crp_id": "N00026314",
            "google_entity_id": "/m/03dlf3",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1969-05-22",
            "leadership_role": null,
            "fec_candidate_id": "H4WA05077",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "20",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 33,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 3.13,
            "votes_with_party_pct": 94.71,
            "votes_against_party_pct": 3.4,
            "ocd_id": "ocd-division/country:us/state:wa/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WA-05--M001159": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "N000189",
            "in_office": true,
            "firstName": "Dan",
            "lastName": "Newhouse",
            "middleName": null,
            "fullName": "Dan Newhouse",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/N000189.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-WA-04--N000189",
              "builtID": "wa--04",
              "externalID": "N000189",
              "geometry": null,
              "geoid": "5304"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "504 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-5816",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepNewhouse",
              "youtube": null,
              "instagram": null,
              "facebook": "RepNewhouse",
              "urls": ["https://newhouse.house.gov"],
              "rss_url": null
            },
            "title": "WA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/N000189.json",
            "govtrack_id": "412660",
            "cspan_id": "78315",
            "votesmart_id": "51522",
            "icpsr_id": "21556",
            "crp_id": "N00036403",
            "google_entity_id": "/m/0_s0bn3",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1955-07-10",
            "leadership_role": null,
            "fec_candidate_id": "H4WA04104",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 43,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 4.08,
            "votes_with_party_pct": 86.38,
            "votes_against_party_pct": 11.71,
            "ocd_id": "ocd-division/country:us/state:wa/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WA-04--N000189": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "S001216",
            "in_office": true,
            "firstName": "Kim",
            "lastName": "Schrier",
            "middleName": null,
            "fullName": "Kim Schrier",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S001216.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-WA-08--S001216",
              "builtID": "wa--08",
              "externalID": "S001216",
              "geometry": null,
              "geoid": "5308"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1110 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-7761",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepKimSchrier",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://schrier.house.gov"],
              "rss_url": null
            },
            "title": "WA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001216.json",
            "govtrack_id": "412835",
            "cspan_id": null,
            "votesmart_id": "181124",
            "icpsr_id": null,
            "crp_id": "N00041606",
            "google_entity_id": "/g/11gzqmm209",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1968-08-23",
            "leadership_role": null,
            "fec_candidate_id": "H8WA08189",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 13,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.23,
            "votes_with_party_pct": 91.38,
            "votes_against_party_pct": 6.68,
            "ocd_id": "ocd-division/country:us/state:wa/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WA-08--S001216": 0 }
      },
      "09": {
        "members": [
          {
            "API_ID": "S000510",
            "in_office": true,
            "firstName": "Adam",
            "lastName": "Smith",
            "middleName": null,
            "fullName": "Adam Smith",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/S000510.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "09",
              "chamber": "",
              "hashID": "HOUSE-WA-09--S000510",
              "builtID": "wa--09",
              "externalID": "S000510",
              "geometry": null,
              "geoid": "5309"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2264 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-8901",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAdamSmith",
              "youtube": "CongressmanAdamSmith",
              "instagram": null,
              "facebook": "RepAdamSmith",
              "urls": ["https://adamsmith.house.gov"],
              "rss_url": null
            },
            "title": "WA House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S000510.json",
            "govtrack_id": "400379",
            "cspan_id": "44329",
            "votesmart_id": "845",
            "icpsr_id": "29768",
            "crp_id": "N00007833",
            "google_entity_id": "/m/024zj_",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1965-06-15",
            "leadership_role": null,
            "fec_candidate_id": "H6WA09025",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "28",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 18,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:14 -0400",
            "missed_votes_pct": 1.71,
            "votes_with_party_pct": 97.28,
            "votes_against_party_pct": 0.87,
            "ocd_id": "ocd-division/country:us/state:wa/cd:9",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WA-09--S000510": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/5fd91c2d-1223-47e4-8133-ef0da7ef1898",
            "firstName": "Davina Williams",
            "lastName": "Duerr",
            "fullName": "Davina Duerr",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29877",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-WA-1-lower-ocd-person-5fd91c2d-1223-47e4-8133-ef0da7ef1898",
              "builtID": "wa-lower-1",
              "externalID": "ocd-person/5fd91c2d-1223-47e4-8133-ef0da7ef1898",
              "geometry": null
            },
            "contactInfo": {
              "email": "davina.duerr@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 322, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7928",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Duerr/",
                "https://housedemocrats.wa.gov/duerr/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e802bd51-458a-4976-b7ad-25ba4ceb0581",
            "firstName": "Shelley",
            "lastName": "Kloba",
            "fullName": "Shelley Kloba",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=26168",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-WA-1-lower-ocd-person-e802bd51-458a-4976-b7ad-25ba4ceb0581",
              "builtID": "wa-lower-1",
              "externalID": "ocd-person/e802bd51-458a-4976-b7ad-25ba4ceb0581",
              "geometry": null
            },
            "contactInfo": {
              "email": "shelley.kloba@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 132A, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7900",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Shelley-Kloba/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Kloba/",
                "https://housedemocrats.wa.gov/kloba/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-1-lower-ocd-person-5fd91c2d-1223-47e4-8133-ef0da7ef1898": 0,
          "SLDL-WA-1-lower-ocd-person-e802bd51-458a-4976-b7ad-25ba4ceb0581": 1
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/9c1fefff-fa5a-49aa-90ca-a9ca80b6f669",
            "firstName": "Andrew",
            "lastName": "Barkis",
            "fullName": "Andrew Barkis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=24075",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-WA-2-lower-ocd-person-9c1fefff-fa5a-49aa-90ca-a9ca80b6f669",
              "builtID": "wa-lower-2",
              "externalID": "ocd-person/9c1fefff-fa5a-49aa-90ca-a9ca80b6f669",
              "geometry": null
            },
            "contactInfo": {
              "email": "andrew.barkis@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 427A, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7824",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Andrew-Barkis/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://AndrewBarkis.houserepublicans.wa.gov/",
                "https://andrewbarkis.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d3572bf1-190b-4170-8ffd-1d9dd77c1fe4",
            "firstName": "James Truman",
            "lastName": "Wilcox",
            "fullName": "J.T. Wilcox",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=15810",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-WA-2-lower-ocd-person-d3572bf1-190b-4170-8ffd-1d9dd77c1fe4",
              "builtID": "wa-lower-2",
              "externalID": "ocd-person/d3572bf1-190b-4170-8ffd-1d9dd77c1fe4",
              "geometry": null
            },
            "contactInfo": {
              "email": "jt.wilcox@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 122A, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7912",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/JT-Wilcox/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://JTWilcox.houserepublicans.wa.gov/",
                "https://J.T.Wilcox.houserepublicans.wa.gov/",
                "https://jtwilcox.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-2-lower-ocd-person-9c1fefff-fa5a-49aa-90ca-a9ca80b6f669": 0,
          "SLDL-WA-2-lower-ocd-person-d3572bf1-190b-4170-8ffd-1d9dd77c1fe4": 1
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/1ecc75cc-95c9-4ae6-a8a4-1a0f87a10d0e",
            "firstName": "Marcus Michael",
            "lastName": "Riccelli",
            "fullName": "Marcus Riccelli",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=15706",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-WA-3-lower-ocd-person-1ecc75cc-95c9-4ae6-a8a4-1a0f87a10d0e",
              "builtID": "wa-lower-3",
              "externalID": "ocd-person/1ecc75cc-95c9-4ae6-a8a4-1a0f87a10d0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "marcus.riccelli@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 320, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7888",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Marcus-Riccelli/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Riccelli/",
                "https://housedemocrats.wa.gov/riccelli/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/77fe53b5-3ed7-48b2-9c9a-1b59d5faf50f",
            "firstName": "Timothy Sean",
            "lastName": "Ormsby",
            "fullName": "Timm Ormsby",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=9207",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-WA-3-lower-ocd-person-77fe53b5-3ed7-48b2-9c9a-1b59d5faf50f",
              "builtID": "wa-lower-3",
              "externalID": "ocd-person/77fe53b5-3ed7-48b2-9c9a-1b59d5faf50f",
              "geometry": null
            },
            "contactInfo": {
              "email": "timm.ormsby@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 315, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7946",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Timm-Ormsby/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Ormsby/",
                "https://housedemocrats.wa.gov/ormsby/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-3-lower-ocd-person-1ecc75cc-95c9-4ae6-a8a4-1a0f87a10d0e": 0,
          "SLDL-WA-3-lower-ocd-person-77fe53b5-3ed7-48b2-9c9a-1b59d5faf50f": 1
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/4d01a3aa-64e5-439d-9574-c1b749c1d52a",
            "firstName": "Leonard Glenn",
            "lastName": "Christian",
            "fullName": "Leonard Christian",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=18458",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-WA-4-lower-ocd-person-4d01a3aa-64e5-439d-9574-c1b749c1d52a",
              "builtID": "wa-lower-4",
              "externalID": "ocd-person/4d01a3aa-64e5-439d-9574-c1b749c1d52a",
              "geometry": null
            },
            "contactInfo": {
              "email": "leonard.christian@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 122C, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7984",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.wa.gov/house/representatives/Pages/christian.aspx",
                "https://LeonardChristian.houserepublicans.wa.gov/",
                "https://leonardchristian.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/31197273-764c-4520-b5dc-bc85695bf36f",
            "firstName": "Suzanne",
            "lastName": "Schmidt",
            "fullName": "Suzanne Schmidt",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34035",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-WA-4-lower-ocd-person-31197273-764c-4520-b5dc-bc85695bf36f",
              "builtID": "wa-lower-4",
              "externalID": "ocd-person/31197273-764c-4520-b5dc-bc85695bf36f",
              "geometry": null
            },
            "contactInfo": {
              "email": "suzanne.schmidt@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://SuzanneSchmidt.houserepublicans.wa.gov/",
                "https://suzanneschmidt.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-4-lower-ocd-person-4d01a3aa-64e5-439d-9574-c1b749c1d52a": 0,
          "SLDL-WA-4-lower-ocd-person-31197273-764c-4520-b5dc-bc85695bf36f": 1
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/b6f7474d-cf29-42de-9190-08ea9d000779",
            "firstName": "Bill George",
            "lastName": "Ramos",
            "fullName": "Bill Ramos",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29091",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-WA-5-lower-ocd-person-b6f7474d-cf29-42de-9190-08ea9d000779",
              "builtID": "wa-lower-5",
              "externalID": "ocd-person/b6f7474d-cf29-42de-9190-08ea9d000779",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.ramos@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 325, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7852",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Bill-Ramos/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Ramos/",
                "https://housedemocrats.wa.gov/ramos/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c1457fe8-6e6a-4fc8-96e9-63b23fc90ef8",
            "firstName": "Lisa",
            "lastName": "Callan",
            "fullName": "Lisa Callan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29092",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-WA-5-lower-ocd-person-c1457fe8-6e6a-4fc8-96e9-63b23fc90ef8",
              "builtID": "wa-lower-5",
              "externalID": "ocd-person/c1457fe8-6e6a-4fc8-96e9-63b23fc90ef8",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisa.callan@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 335, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7876",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Lisa-Callan/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Callan/",
                "https://housedemocrats.wa.gov/callan/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-5-lower-ocd-person-b6f7474d-cf29-42de-9190-08ea9d000779": 0,
          "SLDL-WA-5-lower-ocd-person-c1457fe8-6e6a-4fc8-96e9-63b23fc90ef8": 1
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/591e8fb8-a660-4769-a9b1-3c085281b5c5",
            "firstName": "Virginia",
            "lastName": "Graham",
            "fullName": "Jenny Graham",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29093",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-WA-6-lower-ocd-person-591e8fb8-a660-4769-a9b1-3c085281b5c5",
              "builtID": "wa-lower-6",
              "externalID": "ocd-person/591e8fb8-a660-4769-a9b1-3c085281b5c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "jenny.graham@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 435, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7962",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Jenny-Graham/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://JennyGraham.houserepublicans.wa.gov/",
                "https://jennygraham.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/54f84d93-982c-4028-8c94-ccd250ae6896",
            "firstName": "Mike",
            "lastName": "Volz",
            "fullName": "Mike Volz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=26170",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-WA-6-lower-ocd-person-54f84d93-982c-4028-8c94-ccd250ae6896",
              "builtID": "wa-lower-6",
              "externalID": "ocd-person/54f84d93-982c-4028-8c94-ccd250ae6896",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.volz@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 427, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7922",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Mike-Volz/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://MikeVolz.houserepublicans.wa.gov/",
                "https://mikevolz.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-6-lower-ocd-person-591e8fb8-a660-4769-a9b1-3c085281b5c5": 0,
          "SLDL-WA-6-lower-ocd-person-54f84d93-982c-4028-8c94-ccd250ae6896": 1
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/eb39b09d-4691-40e5-a453-01bff4c66996",
            "firstName": "Jacquelin",
            "lastName": "Maycumber",
            "fullName": "Jacquelin Maycumber",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=14115",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-WA-7-lower-ocd-person-eb39b09d-4691-40e5-a453-01bff4c66996",
              "builtID": "wa-lower-7",
              "externalID": "ocd-person/eb39b09d-4691-40e5-a453-01bff4c66996",
              "geometry": null
            },
            "contactInfo": {
              "email": "jacquelin.maycumber@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 425B, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7908",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Jacquelin-Maycumber/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://JacquelinMaycumber.houserepublicans.wa.gov/",
                "https://jacquelinmaycumber.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/92ad38ff-915f-4fc0-b87d-714da42119b2",
            "firstName": "Joel Andrew",
            "lastName": "Kretz",
            "fullName": "Joel Kretz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=10041",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-WA-7-lower-ocd-person-92ad38ff-915f-4fc0-b87d-714da42119b2",
              "builtID": "wa-lower-7",
              "externalID": "ocd-person/92ad38ff-915f-4fc0-b87d-714da42119b2",
              "geometry": null
            },
            "contactInfo": {
              "email": "joel.kretz@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 425A, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7988",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Joel-Kretz/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://JoelKretz.houserepublicans.wa.gov/",
                "https://joelkretz.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-7-lower-ocd-person-eb39b09d-4691-40e5-a453-01bff4c66996": 0,
          "SLDL-WA-7-lower-ocd-person-92ad38ff-915f-4fc0-b87d-714da42119b2": 1
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/ee68f6b7-3cb2-4269-90df-ce11e4027c52",
            "firstName": "April",
            "lastName": "Connors",
            "fullName": "April Connors",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34027",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-WA-8-lower-ocd-person-ee68f6b7-3cb2-4269-90df-ce11e4027c52",
              "builtID": "wa-lower-8",
              "externalID": "ocd-person/ee68f6b7-3cb2-4269-90df-ce11e4027c52",
              "geometry": null
            },
            "contactInfo": {
              "email": "april.connors@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 434, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7882",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://AprilConnors.houserepublicans.wa.gov/",
                "https://aprilconnors.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/fed97a36-088f-46e6-9b96-ca2d679c955b",
            "firstName": "Stephanie",
            "lastName": "Barnard",
            "fullName": "Stephanie Barnard",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34025",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-WA-8-lower-ocd-person-fed97a36-088f-46e6-9b96-ca2d679c955b",
              "builtID": "wa-lower-8",
              "externalID": "ocd-person/fed97a36-088f-46e6-9b96-ca2d679c955b",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephanie.barnard@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 469, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7986",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://StephanieBarnard.houserepublicans.wa.gov/",
                "https://stephaniebarnard.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-8-lower-ocd-person-ee68f6b7-3cb2-4269-90df-ce11e4027c52": 0,
          "SLDL-WA-8-lower-ocd-person-fed97a36-088f-46e6-9b96-ca2d679c955b": 1
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/eedeb2a4-a7f1-414c-b9d0-504c4450f3f6",
            "firstName": "Joseph Scott",
            "lastName": "Schmick",
            "fullName": "Joe Schmick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=13209",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-WA-9-lower-ocd-person-eedeb2a4-a7f1-414c-b9d0-504c4450f3f6",
              "builtID": "wa-lower-9",
              "externalID": "ocd-person/eedeb2a4-a7f1-414c-b9d0-504c4450f3f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.schmick@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 426B, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7844",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Joe-Schmick/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://JoeSchmick.houserepublicans.wa.gov/",
                "https://joeschmick.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/69c32c6a-707c-4789-823c-30462b054bcf",
            "firstName": "Mary Lurintha",
            "lastName": "Dye",
            "fullName": "Mary Dye",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=21490",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-WA-9-lower-ocd-person-69c32c6a-707c-4789-823c-30462b054bcf",
              "builtID": "wa-lower-9",
              "externalID": "ocd-person/69c32c6a-707c-4789-823c-30462b054bcf",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.dye@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 432, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7942",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Mary-Dye/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://MaryDye.houserepublicans.wa.gov/",
                "https://marydye.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-9-lower-ocd-person-eedeb2a4-a7f1-414c-b9d0-504c4450f3f6": 0,
          "SLDL-WA-9-lower-ocd-person-69c32c6a-707c-4789-823c-30462b054bcf": 1
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/9fd9a839-0414-46a4-84b8-7ee0d2f2f09a",
            "firstName": "Clyde",
            "lastName": "Shavers",
            "fullName": "Clyde Shavers",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34050",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-WA-10-lower-ocd-person-9fd9a839-0414-46a4-84b8-7ee0d2f2f09a",
              "builtID": "wa-lower-10",
              "externalID": "ocd-person/9fd9a839-0414-46a4-84b8-7ee0d2f2f09a",
              "geometry": null
            },
            "contactInfo": {
              "email": "clyde.shavers@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 311, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7884",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Shavers/",
                "https://housedemocrats.wa.gov/shavers/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/7ab5a10d-3d4a-4187-ac98-9afa60e34b61",
            "firstName": "David",
            "lastName": "Paul",
            "fullName": "Dave Paul",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29095",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-WA-10-lower-ocd-person-7ab5a10d-3d4a-4187-ac98-9afa60e34b61",
              "builtID": "wa-lower-10",
              "externalID": "ocd-person/7ab5a10d-3d4a-4187-ac98-9afa60e34b61",
              "geometry": null
            },
            "contactInfo": {
              "email": "dave.paul@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 424, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-553-4226",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Dave-Paul/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Paul/",
                "https://housedemocrats.wa.gov/paul/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-10-lower-ocd-person-9fd9a839-0414-46a4-84b8-7ee0d2f2f09a": 0,
          "SLDL-WA-10-lower-ocd-person-7ab5a10d-3d4a-4187-ac98-9afa60e34b61": 1
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/c6f2fa42-706f-41eb-a9bd-b664b593f5a2",
            "firstName": "David",
            "lastName": "Hackney",
            "fullName": "David Hackney",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=31523",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-WA-11-lower-ocd-person-c6f2fa42-706f-41eb-a9bd-b664b593f5a2",
              "builtID": "wa-lower-11",
              "externalID": "ocd-person/c6f2fa42-706f-41eb-a9bd-b664b593f5a2",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.hackney@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 330, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7956",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Hackney/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/hackney/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/89960b3d-2697-4941-8682-9a4888b70ff0",
            "firstName": "Steven Anton",
            "lastName": "Bergquist",
            "fullName": "Steve Bergquist",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=17227",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-WA-11-lower-ocd-person-89960b3d-2697-4941-8682-9a4888b70ff0",
              "builtID": "wa-lower-11",
              "externalID": "ocd-person/89960b3d-2697-4941-8682-9a4888b70ff0",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.bergquist@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 438B, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7862",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Steve-Bergquist/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Bergquist/",
                "https://housedemocrats.wa.gov/bergquist/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-11-lower-ocd-person-c6f2fa42-706f-41eb-a9bd-b664b593f5a2": 0,
          "SLDL-WA-11-lower-ocd-person-89960b3d-2697-4941-8682-9a4888b70ff0": 1
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/f1d29afa-f381-46f2-a6e4-17ab801b3fdb",
            "firstName": "Keith",
            "lastName": "Goehner",
            "fullName": "Keith Goehner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29096",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-WA-12-lower-ocd-person-f1d29afa-f381-46f2-a6e4-17ab801b3fdb",
              "builtID": "wa-lower-12",
              "externalID": "ocd-person/f1d29afa-f381-46f2-a6e4-17ab801b3fdb",
              "geometry": null
            },
            "contactInfo": {
              "email": "keith.goehner@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 122B, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7954",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Keith-Goehner/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://KeithGoehner.houserepublicans.wa.gov/",
                "https://keithgoehner.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/816e7215-dcff-46c3-b1a5-f3c3387b14be",
            "firstName": "Mike",
            "lastName": "Steele",
            "fullName": "Mike Steele",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=10546",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-WA-12-lower-ocd-person-816e7215-dcff-46c3-b1a5-f3c3387b14be",
              "builtID": "wa-lower-12",
              "externalID": "ocd-person/816e7215-dcff-46c3-b1a5-f3c3387b14be",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.steele@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 335A, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7832",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Mike-Steele/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://MikeSteele.houserepublicans.wa.gov/",
                "https://mikesteele.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-12-lower-ocd-person-f1d29afa-f381-46f2-a6e4-17ab801b3fdb": 0,
          "SLDL-WA-12-lower-ocd-person-816e7215-dcff-46c3-b1a5-f3c3387b14be": 1
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/ad28df67-a1bf-4580-b55f-f0917c6e69b2",
            "firstName": "Alex",
            "lastName": "Ybarra",
            "fullName": "Alex Ybarra",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29318",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-WA-13-lower-ocd-person-ad28df67-a1bf-4580-b55f-f0917c6e69b2",
              "builtID": "wa-lower-13",
              "externalID": "ocd-person/ad28df67-a1bf-4580-b55f-f0917c6e69b2",
              "geometry": null
            },
            "contactInfo": {
              "email": "alex.ybarra@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 469, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7808",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Alex-Ybarra/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://AlexYbarra.houserepublicans.wa.gov/",
                "https://alexybarra.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a954dff9-39d6-4340-8070-5ef437438cda",
            "firstName": "Thomas Emmett",
            "lastName": "Dent",
            "fullName": "Tom Dent",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=20761",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-WA-13-lower-ocd-person-a954dff9-39d6-4340-8070-5ef437438cda",
              "builtID": "wa-lower-13",
              "externalID": "ocd-person/a954dff9-39d6-4340-8070-5ef437438cda",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.dent@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 437, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7932",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Tom-Dent/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://TomDent.houserepublicans.wa.gov/",
                "https://tomdent.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-13-lower-ocd-person-ad28df67-a1bf-4580-b55f-f0917c6e69b2": 0,
          "SLDL-WA-13-lower-ocd-person-a954dff9-39d6-4340-8070-5ef437438cda": 1
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/a0a48572-259f-4a6c-b035-a85b80e187d6",
            "firstName": "Chris",
            "lastName": "Corry",
            "fullName": "Chris Corry",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29097",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-WA-14-lower-ocd-person-a0a48572-259f-4a6c-b035-a85b80e187d6",
              "builtID": "wa-lower-14",
              "externalID": "ocd-person/a0a48572-259f-4a6c-b035-a85b80e187d6",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.corry@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 122F, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7810",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Chris-Corry/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://ChrisCorry.houserepublicans.wa.gov/",
                "https://chriscorry.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/20038beb-45da-48cd-a00e-fadbcede45c4",
            "firstName": "Gina R.",
            "lastName": "Mosbrucker",
            "fullName": "Gina Mosbrucker",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=20759",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-WA-14-lower-ocd-person-20038beb-45da-48cd-a00e-fadbcede45c4",
              "builtID": "wa-lower-14",
              "externalID": "ocd-person/20038beb-45da-48cd-a00e-fadbcede45c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "gina.mosbrucker@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 431, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7856",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Gina-Mosbrucker/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://GinaMosbrucker.houserepublicans.wa.gov/",
                "https://ginamosbrucker.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-14-lower-ocd-person-a0a48572-259f-4a6c-b035-a85b80e187d6": 0,
          "SLDL-WA-14-lower-ocd-person-20038beb-45da-48cd-a00e-fadbcede45c4": 1
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/02a9c583-7358-41e7-861a-e2085fdd18ad",
            "firstName": "Bruce",
            "lastName": "Chandler",
            "fullName": "Bruce Chandler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=3469",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-WA-15-lower-ocd-person-02a9c583-7358-41e7-861a-e2085fdd18ad",
              "builtID": "wa-lower-15",
              "externalID": "ocd-person/02a9c583-7358-41e7-861a-e2085fdd18ad",
              "geometry": null
            },
            "contactInfo": {
              "email": "bruce.chandler@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 427B, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7960",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Bruce-Chandler/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://BruceChandler.houserepublicans.wa.gov/",
                "https://brucechandler.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ed8aff0d-7c47-46f1-a136-c5d7339d8157",
            "firstName": "Bryan",
            "lastName": "Sandlin",
            "fullName": "Bryan Sandlin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34034",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-WA-15-lower-ocd-person-ed8aff0d-7c47-46f1-a136-c5d7339d8157",
              "builtID": "wa-lower-15",
              "externalID": "ocd-person/ed8aff0d-7c47-46f1-a136-c5d7339d8157",
              "geometry": null
            },
            "contactInfo": {
              "email": "bryan.sandlin@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://BryanSandlin.houserepublicans.wa.gov/",
                "https://bryansandlin.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-15-lower-ocd-person-02a9c583-7358-41e7-861a-e2085fdd18ad": 0,
          "SLDL-WA-15-lower-ocd-person-ed8aff0d-7c47-46f1-a136-c5d7339d8157": 1
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/db28a021-1409-4f8f-8d2b-4a2f8c2243ef",
            "firstName": "Mark",
            "lastName": "Klicker",
            "fullName": "Mark Klicker",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=31524",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-WA-16-lower-ocd-person-db28a021-1409-4f8f-8d2b-4a2f8c2243ef",
              "builtID": "wa-lower-16",
              "externalID": "ocd-person/db28a021-1409-4f8f-8d2b-4a2f8c2243ef",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.klicker@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://MarkKlicker.houserepublicans.wa.gov/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://markklicker.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/43c0e02f-19f5-4c29-bcb7-e9975baa0e61",
            "firstName": "Skyler David",
            "lastName": "Rude",
            "fullName": "Skyler Rude",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=20837",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-WA-16-lower-ocd-person-43c0e02f-19f5-4c29-bcb7-e9975baa0e61",
              "builtID": "wa-lower-16",
              "externalID": "ocd-person/43c0e02f-19f5-4c29-bcb7-e9975baa0e61",
              "geometry": null
            },
            "contactInfo": {
              "email": "skyler.rude@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 122G, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7828",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Skyler-Rude/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://SkylerRude.houserepublicans.wa.gov/",
                "https://skylerrude.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-16-lower-ocd-person-db28a021-1409-4f8f-8d2b-4a2f8c2243ef": 0,
          "SLDL-WA-16-lower-ocd-person-43c0e02f-19f5-4c29-bcb7-e9975baa0e61": 1
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/8310a7ed-ce79-4b0e-b13a-7857230bfcde",
            "firstName": "Kevin",
            "lastName": "Waters",
            "fullName": "Kevin Waters",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34038",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-WA-17-lower-ocd-person-8310a7ed-ce79-4b0e-b13a-7857230bfcde",
              "builtID": "wa-lower-17",
              "externalID": "ocd-person/8310a7ed-ce79-4b0e-b13a-7857230bfcde",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.waters@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 467, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7994",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://KevinWaters.houserepublicans.wa.gov/",
                "https://kevinwaters.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/053234a7-c97e-41f7-8b71-c96cf19c80d7",
            "firstName": "Paul",
            "lastName": "Harris",
            "fullName": "Paul Harris",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=15813",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-WA-17-lower-ocd-person-053234a7-c97e-41f7-8b71-c96cf19c80d7",
              "builtID": "wa-lower-17",
              "externalID": "ocd-person/053234a7-c97e-41f7-8b71-c96cf19c80d7",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.harris@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 426A, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7976",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Paul-Harris/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://PaulHarris.houserepublicans.wa.gov/",
                "https://paulharris.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-17-lower-ocd-person-8310a7ed-ce79-4b0e-b13a-7857230bfcde": 0,
          "SLDL-WA-17-lower-ocd-person-053234a7-c97e-41f7-8b71-c96cf19c80d7": 1
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/6c1d47b4-00c8-4988-81bf-5d336a3c1c16",
            "firstName": "Gregory Scott",
            "lastName": "Cheney",
            "fullName": "Greg Cheney",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34026",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-WA-18-lower-ocd-person-6c1d47b4-00c8-4988-81bf-5d336a3c1c16",
              "builtID": "wa-lower-18",
              "externalID": "ocd-person/6c1d47b4-00c8-4988-81bf-5d336a3c1c16",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.cheney@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 406, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7812",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://GregCheney.houserepublicans.wa.gov/",
                "https://gregcheney.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/4b9b64c6-60eb-4d41-8b52-2f0b3f1ce2a8",
            "firstName": "Stephanie",
            "lastName": "McClintock",
            "fullName": "Stephanie McClintock",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34032",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-WA-18-lower-ocd-person-4b9b64c6-60eb-4d41-8b52-2f0b3f1ce2a8",
              "builtID": "wa-lower-18",
              "externalID": "ocd-person/4b9b64c6-60eb-4d41-8b52-2f0b3f1ce2a8",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephanie.mcclintock@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 466, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7850",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://StephanieMcClintock.houserepublicans.wa.gov/",
                "https://stephaniemcclintock.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-18-lower-ocd-person-6c1d47b4-00c8-4988-81bf-5d336a3c1c16": 0,
          "SLDL-WA-18-lower-ocd-person-4b9b64c6-60eb-4d41-8b52-2f0b3f1ce2a8": 1
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/3c66c8e4-ae7a-4297-99e9-618336e215ea",
            "firstName": "Jim",
            "lastName": "Walsh",
            "fullName": "Jim Walsh",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=27181",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-WA-19-lower-ocd-person-3c66c8e4-ae7a-4297-99e9-618336e215ea",
              "builtID": "wa-lower-19",
              "externalID": "ocd-person/3c66c8e4-ae7a-4297-99e9-618336e215ea",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.walsh@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 428, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7806",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Jim-Walsh/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://JimWalsh.houserepublicans.wa.gov/",
                "https://jimwalsh.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/dcc383e0-945d-4580-b8fd-e0914e1af1f0",
            "firstName": "Joel",
            "lastName": "McEntire",
            "fullName": "Joel McEntire",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=31525",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-WA-19-lower-ocd-person-dcc383e0-945d-4580-b8fd-e0914e1af1f0",
              "builtID": "wa-lower-19",
              "externalID": "ocd-person/dcc383e0-945d-4580-b8fd-e0914e1af1f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "joel.mcentire@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://JoelMcEntire.houserepublicans.wa.gov/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://joelmcentire.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-19-lower-ocd-person-3c66c8e4-ae7a-4297-99e9-618336e215ea": 0,
          "SLDL-WA-19-lower-ocd-person-dcc383e0-945d-4580-b8fd-e0914e1af1f0": 1
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/afd768fd-c4fc-4ee9-a845-2183793ebe0e",
            "firstName": "Edmund Thomas",
            "lastName": "Orcutt",
            "fullName": "Ed Orcutt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=7635",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-WA-20-lower-ocd-person-afd768fd-c4fc-4ee9-a845-2183793ebe0e",
              "builtID": "wa-lower-20",
              "externalID": "ocd-person/afd768fd-c4fc-4ee9-a845-2183793ebe0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "ed.orcutt@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 408, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7990",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Ed-Orcutt/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://EdOrcutt.houserepublicans.wa.gov/",
                "https://edorcutt.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e227d7d6-49f5-4c75-a754-e54f2447e664",
            "firstName": "Peter Jaret",
            "lastName": "Abbarno",
            "fullName": "Peter Abbarno",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=31526",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-WA-20-lower-ocd-person-e227d7d6-49f5-4c75-a754-e54f2447e664",
              "builtID": "wa-lower-20",
              "externalID": "ocd-person/e227d7d6-49f5-4c75-a754-e54f2447e664",
              "geometry": null
            },
            "contactInfo": {
              "email": "peter.abbarno@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://PeterAbbarno.houserepublicans.wa.gov/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://peterabbarno.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-20-lower-ocd-person-afd768fd-c4fc-4ee9-a845-2183793ebe0e": 0,
          "SLDL-WA-20-lower-ocd-person-e227d7d6-49f5-4c75-a754-e54f2447e664": 1
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/09d83bae-b84b-436e-8d7f-5cebc05e74a3",
            "firstName": "Lillian",
            "lastName": "Ortiz-Self",
            "fullName": "Lillian Ortiz-Self",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=18546",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-WA-21-lower-ocd-person-09d83bae-b84b-436e-8d7f-5cebc05e74a3",
              "builtID": "wa-lower-21",
              "externalID": "ocd-person/09d83bae-b84b-436e-8d7f-5cebc05e74a3",
              "geometry": null
            },
            "contactInfo": {
              "email": "lillian.ortiz-self@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 434B, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7972",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Lillian-Ortiz-Self/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Ortiz-Self/",
                "https://housedemocrats.wa.gov/ortiz-self/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/361c95de-b7b2-434f-bdea-89fbb3896fea",
            "firstName": "Strom H.",
            "lastName": "Peterson",
            "fullName": "Strom Peterson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=20755",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-WA-21-lower-ocd-person-361c95de-b7b2-434f-bdea-89fbb3896fea",
              "builtID": "wa-lower-21",
              "externalID": "ocd-person/361c95de-b7b2-434f-bdea-89fbb3896fea",
              "geometry": null
            },
            "contactInfo": {
              "email": "strom.peterson@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 324, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7950",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Strom-Peterson/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Peterson/",
                "https://housedemocrats.wa.gov/peterson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-21-lower-ocd-person-09d83bae-b84b-436e-8d7f-5cebc05e74a3": 0,
          "SLDL-WA-21-lower-ocd-person-361c95de-b7b2-434f-bdea-89fbb3896fea": 1
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/0bbe7652-d8a7-46aa-bdbc-90b411347343",
            "firstName": "Beth M.",
            "lastName": "Doglio",
            "fullName": "Beth Doglio",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=26175",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-WA-22-lower-ocd-person-0bbe7652-d8a7-46aa-bdbc-90b411347343",
              "builtID": "wa-lower-22",
              "externalID": "ocd-person/0bbe7652-d8a7-46aa-bdbc-90b411347343",
              "geometry": null
            },
            "contactInfo": {
              "email": "beth.doglio@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 318, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7940",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Beth-Doglio/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Doglio/",
                "https://housedemocrats.wa.gov/doglio/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ea4e5e77-aa55-421f-8ac5-48c65fbc2ab0",
            "firstName": "Jessica Danielle",
            "lastName": "Bateman",
            "fullName": "Jessica Bateman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=17117",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-WA-22-lower-ocd-person-ea4e5e77-aa55-421f-8ac5-48c65fbc2ab0",
              "builtID": "wa-lower-22",
              "externalID": "ocd-person/ea4e5e77-aa55-421f-8ac5-48c65fbc2ab0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jessica.bateman@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 317, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7992",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Bateman/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/bateman/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-22-lower-ocd-person-0bbe7652-d8a7-46aa-bdbc-90b411347343": 0,
          "SLDL-WA-22-lower-ocd-person-ea4e5e77-aa55-421f-8ac5-48c65fbc2ab0": 1
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/e7caacff-8129-4f34-805a-abc2265e868d",
            "firstName": "Gregory Dylan",
            "lastName": "Nance",
            "fullName": "Greg Nance",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34760",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-WA-23-lower-ocd-person-e7caacff-8129-4f34-805a-abc2265e868d",
              "builtID": "wa-lower-23",
              "externalID": "ocd-person/e7caacff-8129-4f34-805a-abc2265e868d",
              "geometry": null
            },
            "contactInfo": {
              "email": "greg.nance@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 3FL, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7842",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Nance/",
                "https://housedemocrats.wa.gov/nance/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/b6b3538a-d8ce-409f-a0e8-8f517037d748",
            "firstName": "Tarra",
            "lastName": "Simmons",
            "fullName": "Tarra Simmons",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=31527",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-WA-23-lower-ocd-person-b6b3538a-d8ce-409f-a0e8-8f517037d748",
              "builtID": "wa-lower-23",
              "externalID": "ocd-person/b6b3538a-d8ce-409f-a0e8-8f517037d748",
              "geometry": null
            },
            "contactInfo": {
              "email": "tarra.simmons@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 132F, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7934",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Simmons/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/simmons/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-23-lower-ocd-person-e7caacff-8129-4f34-805a-abc2265e868d": 0,
          "SLDL-WA-23-lower-ocd-person-b6b3538a-d8ce-409f-a0e8-8f517037d748": 1
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/ea005988-0e0d-4fd2-9960-91083ae8ab25",
            "firstName": "Mike",
            "lastName": "Chapman",
            "fullName": "Mike Chapman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=26176",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-WA-24-lower-ocd-person-ea005988-0e0d-4fd2-9960-91083ae8ab25",
              "builtID": "wa-lower-24",
              "externalID": "ocd-person/ea005988-0e0d-4fd2-9960-91083ae8ab25",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.chapman@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 132B, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7916",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Mike-Chapman/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Chapman/",
                "https://housedemocrats.wa.gov/chapman/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a7654dc1-5240-437e-9579-48640c8564d8",
            "firstName": "Stephen Platner",
            "lastName": "Tharinger",
            "fullName": "Steve Tharinger",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=15816",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-WA-24-lower-ocd-person-a7654dc1-5240-437e-9579-48640c8564d8",
              "builtID": "wa-lower-24",
              "externalID": "ocd-person/a7654dc1-5240-437e-9579-48640c8564d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.tharinger@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 314, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7904",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Steve-Tharinger/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Tharinger/",
                "https://housedemocrats.wa.gov/tharinger/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-24-lower-ocd-person-ea005988-0e0d-4fd2-9960-91083ae8ab25": 0,
          "SLDL-WA-24-lower-ocd-person-a7654dc1-5240-437e-9579-48640c8564d8": 1
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/faa48cee-18ae-434e-8079-c8137dfd4acf",
            "firstName": "Cynthia P.",
            "lastName": "Jacobsen",
            "fullName": "Cyndy Jacobsen",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=31528",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-WA-25-lower-ocd-person-faa48cee-18ae-434e-8079-c8137dfd4acf",
              "builtID": "wa-lower-25",
              "externalID": "ocd-person/faa48cee-18ae-434e-8079-c8137dfd4acf",
              "geometry": null
            },
            "contactInfo": {
              "email": "cyndy.jacobsen@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://CyndyJacobsen.houserepublicans.wa.gov/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://cyndyjacobsen.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/dd170302-0a3f-4235-865a-a117996519d8",
            "firstName": "Kelly",
            "lastName": "Chambers",
            "fullName": "Kelly Chambers",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29100",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-WA-25-lower-ocd-person-dd170302-0a3f-4235-865a-a117996519d8",
              "builtID": "wa-lower-25",
              "externalID": "ocd-person/dd170302-0a3f-4235-865a-a117996519d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "kelly.chambers@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 426, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7948",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Kelly-Chambers/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://KellyChambers.houserepublicans.wa.gov/",
                "https://kellychambers.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-25-lower-ocd-person-faa48cee-18ae-434e-8079-c8137dfd4acf": 0,
          "SLDL-WA-25-lower-ocd-person-dd170302-0a3f-4235-865a-a117996519d8": 1
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/fdd79a57-f6f9-4435-84f6-92708c65b716",
            "firstName": "Michelle L. Downey",
            "lastName": "Caldier",
            "fullName": "Michelle Caldier",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=20760",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-WA-26-lower-ocd-person-fdd79a57-f6f9-4435-84f6-92708c65b716",
              "builtID": "wa-lower-26",
              "externalID": "ocd-person/fdd79a57-f6f9-4435-84f6-92708c65b716",
              "geometry": null
            },
            "contactInfo": {
              "email": "michelle.caldier@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 122H, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7802",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Michelle-Caldier/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://MichelleCaldier.houserepublicans.wa.gov/",
                "https://michellecaldier.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ae325c9c-1a8d-4cf4-a455-8d2d5693dcd1",
            "firstName": "Spencer W.",
            "lastName": "Hutchins",
            "fullName": "Spencer Hutchins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=5775",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-WA-26-lower-ocd-person-ae325c9c-1a8d-4cf4-a455-8d2d5693dcd1",
              "builtID": "wa-lower-26",
              "externalID": "ocd-person/ae325c9c-1a8d-4cf4-a455-8d2d5693dcd1",
              "geometry": null
            },
            "contactInfo": {
              "email": "spencer.hutchines@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 122D, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7964",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://SpencerHutchins.houserepublicans.wa.gov/",
                "https://spencerhutchins.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-26-lower-ocd-person-fdd79a57-f6f9-4435-84f6-92708c65b716": 0,
          "SLDL-WA-26-lower-ocd-person-ae325c9c-1a8d-4cf4-a455-8d2d5693dcd1": 1
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/4ed1ee96-ea0f-4fc6-9c74-14cea6328716",
            "firstName": "Jake",
            "lastName": "Fey",
            "fullName": "Jake Fey",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=17241",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-WA-27-lower-ocd-person-4ed1ee96-ea0f-4fc6-9c74-14cea6328716",
              "builtID": "wa-lower-27",
              "externalID": "ocd-person/4ed1ee96-ea0f-4fc6-9c74-14cea6328716",
              "geometry": null
            },
            "contactInfo": {
              "email": "jake.fey@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 415, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7974",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Jake-Fey/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Fey/",
                "https://housedemocrats.wa.gov/fey/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/072af7b6-1fdb-44c5-a6b9-3e3383873231",
            "firstName": "Laurie A.",
            "lastName": "Jinkins",
            "fullName": "Laurie Jinkins",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=15817",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-WA-27-lower-ocd-person-072af7b6-1fdb-44c5-a6b9-3e3383873231",
              "builtID": "wa-lower-27",
              "externalID": "ocd-person/072af7b6-1fdb-44c5-a6b9-3e3383873231",
              "geometry": null
            },
            "contactInfo": {
              "email": "laurie.jinkins@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 339C, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7930",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Laurie-Jinkins/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Jinkins/",
                "https://housedemocrats.wa.gov/jinkins/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-27-lower-ocd-person-4ed1ee96-ea0f-4fc6-9c74-14cea6328716": 0,
          "SLDL-WA-27-lower-ocd-person-072af7b6-1fdb-44c5-a6b9-3e3383873231": 1
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/ff625fe2-be44-459e-8361-73af49b48fbc",
            "firstName": "Dan",
            "lastName": "Bronoske",
            "fullName": "Dan Bronoske",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=31529",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-WA-28-lower-ocd-person-ff625fe2-be44-459e-8361-73af49b48fbc",
              "builtID": "wa-lower-28",
              "externalID": "ocd-person/ff625fe2-be44-459e-8361-73af49b48fbc",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.bronoske@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Bronoske/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/bronoske/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/a203698b-5fe6-4a54-b939-5341937c57ec",
            "firstName": "Mari Kruger",
            "lastName": "Leavitt",
            "fullName": "Mari Leavitt",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29102",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-WA-28-lower-ocd-person-a203698b-5fe6-4a54-b939-5341937c57ec",
              "builtID": "wa-lower-28",
              "externalID": "ocd-person/a203698b-5fe6-4a54-b939-5341937c57ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "mari.leavitt@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 330, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7890",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Mari-Leavitt/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Leavitt/",
                "https://housedemocrats.wa.gov/leavitt/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-28-lower-ocd-person-ff625fe2-be44-459e-8361-73af49b48fbc": 0,
          "SLDL-WA-28-lower-ocd-person-a203698b-5fe6-4a54-b939-5341937c57ec": 1
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/4929d403-f712-4fe4-9e99-ed62ad22b44f",
            "firstName": "Melanie Virginia",
            "lastName": "Morgan",
            "fullName": "Melanie Morgan",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29103",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-WA-29-lower-ocd-person-4929d403-f712-4fe4-9e99-ed62ad22b44f",
              "builtID": "wa-lower-29",
              "externalID": "ocd-person/4929d403-f712-4fe4-9e99-ed62ad22b44f",
              "geometry": null
            },
            "contactInfo": {
              "email": "melanie.morgan@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 417, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7906",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Melanie-Morgan/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Morgan/",
                "https://housedemocrats.wa.gov/morgan/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/bae8eb88-3355-400e-82d6-f60101b8daed",
            "firstName": "Sharlett",
            "lastName": "Mena",
            "fullName": "Sharlett Mena",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=27494",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-WA-29-lower-ocd-person-bae8eb88-3355-400e-82d6-f60101b8daed",
              "builtID": "wa-lower-29",
              "externalID": "ocd-person/bae8eb88-3355-400e-82d6-f60101b8daed",
              "geometry": null
            },
            "contactInfo": {
              "email": "sharlett.mena@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Mena/",
                "https://housedemocrats.wa.gov/mena/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-29-lower-ocd-person-4929d403-f712-4fe4-9e99-ed62ad22b44f": 0,
          "SLDL-WA-29-lower-ocd-person-bae8eb88-3355-400e-82d6-f60101b8daed": 1
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/9ed4e759-d24a-48f1-a691-635984357174",
            "firstName": "Jamila E.",
            "lastName": "Taylor",
            "fullName": "Jamila Taylor",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=31530",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-WA-30-lower-ocd-person-9ed4e759-d24a-48f1-a691-635984357174",
              "builtID": "wa-lower-30",
              "externalID": "ocd-person/9ed4e759-d24a-48f1-a691-635984357174",
              "geometry": null
            },
            "contactInfo": {
              "email": "jamila.taylor@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 418, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7898",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Taylor/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/taylor/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/93110c28-de58-4b9c-8681-85ee5ad9c339",
            "firstName": "Kristine M.",
            "lastName": "Reeves",
            "fullName": "Kristine Reeves",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=27182",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-WA-30-lower-ocd-person-93110c28-de58-4b9c-8681-85ee5ad9c339",
              "builtID": "wa-lower-30",
              "externalID": "ocd-person/93110c28-de58-4b9c-8681-85ee5ad9c339",
              "geometry": null
            },
            "contactInfo": {
              "email": "kristine.reeves@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 132E, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7830",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Kristine-Reeves/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Reeves/",
                "https://housedemocrats.wa.gov/reeves/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-30-lower-ocd-person-9ed4e759-d24a-48f1-a691-635984357174": 0,
          "SLDL-WA-30-lower-ocd-person-93110c28-de58-4b9c-8681-85ee5ad9c339": 1
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/d6399a90-9938-429f-a433-1dd9755de1f6",
            "firstName": "Drew",
            "lastName": "Stokesbary",
            "fullName": "Drew Stokesbary",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=20756",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-WA-31-lower-ocd-person-d6399a90-9938-429f-a433-1dd9755de1f6",
              "builtID": "wa-lower-31",
              "externalID": "ocd-person/d6399a90-9938-429f-a433-1dd9755de1f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "drew.stokesbary@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 335C, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7846",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Drew-Stokesbary/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://DrewStokesbary.houserepublicans.wa.gov/",
                "https://drewstokesbary.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/3b2cc6f1-1fc7-4a1e-be17-891d7f8bde45",
            "firstName": "Eric E.",
            "lastName": "Robertson",
            "fullName": "Eric Robertson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=1580",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-WA-31-lower-ocd-person-3b2cc6f1-1fc7-4a1e-be17-891d7f8bde45",
              "builtID": "wa-lower-31",
              "externalID": "ocd-person/3b2cc6f1-1fc7-4a1e-be17-891d7f8bde45",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.robertson@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://EricRobertson.houserepublicans.wa.gov/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://ericrobertson.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-31-lower-ocd-person-d6399a90-9938-429f-a433-1dd9755de1f6": 0,
          "SLDL-WA-31-lower-ocd-person-3b2cc6f1-1fc7-4a1e-be17-891d7f8bde45": 1
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/c7438b67-c6a5-4048-8a13-a8bc2d99cba6",
            "firstName": "Cindy",
            "lastName": "Ryu",
            "fullName": "Cindy Ryu",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=15736",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-WA-32-lower-ocd-person-c7438b67-c6a5-4048-8a13-a8bc2d99cba6",
              "builtID": "wa-lower-32",
              "externalID": "ocd-person/c7438b67-c6a5-4048-8a13-a8bc2d99cba6",
              "geometry": null
            },
            "contactInfo": {
              "email": "cindy.ryu@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 303, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7880",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Cindy-Ryu/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Ryu/",
                "https://housedemocrats.wa.gov/ryu/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/f972f883-deca-43fb-9367-52d8878d6234",
            "firstName": "Lauren",
            "lastName": "Davis",
            "fullName": "Lauren Davis",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29104",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-WA-32-lower-ocd-person-f972f883-deca-43fb-9367-52d8878d6234",
              "builtID": "wa-lower-32",
              "externalID": "ocd-person/f972f883-deca-43fb-9367-52d8878d6234",
              "geometry": null
            },
            "contactInfo": {
              "email": "lauren.davis@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 331, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7910",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Lauren-Davis/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Davis/",
                "https://housedemocrats.wa.gov/davis/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-32-lower-ocd-person-c7438b67-c6a5-4048-8a13-a8bc2d99cba6": 0,
          "SLDL-WA-32-lower-ocd-person-f972f883-deca-43fb-9367-52d8878d6234": 1
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/e9244579-d4da-4320-bbae-a1c604a17c75",
            "firstName": "Mia Su-Ling",
            "lastName": "Gregerson-Dahle",
            "fullName": "Mia Gregerson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=18264",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-WA-33-lower-ocd-person-e9244579-d4da-4320-bbae-a1c604a17c75",
              "builtID": "wa-lower-33",
              "externalID": "ocd-person/e9244579-d4da-4320-bbae-a1c604a17c75",
              "geometry": null
            },
            "contactInfo": {
              "email": "mia.gregerson@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 328, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7868",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Mia-Gregerson/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Gregerson/",
                "https://housedemocrats.wa.gov/gregerson/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ef711ad1-b4e6-44c9-a971-7d57e8140f04",
            "firstName": "Tina L.",
            "lastName": "Orwall",
            "fullName": "Tina Orwall",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=14205",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-WA-33-lower-ocd-person-ef711ad1-b4e6-44c9-a971-7d57e8140f04",
              "builtID": "wa-lower-33",
              "externalID": "ocd-person/ef711ad1-b4e6-44c9-a971-7d57e8140f04",
              "geometry": null
            },
            "contactInfo": {
              "email": "tina.orwall@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 430, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7834",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Tina-Orwall/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Orwall/",
                "https://housedemocrats.wa.gov/orwall/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-33-lower-ocd-person-e9244579-d4da-4320-bbae-a1c604a17c75": 0,
          "SLDL-WA-33-lower-ocd-person-ef711ad1-b4e6-44c9-a971-7d57e8140f04": 1
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/950b27fd-99cb-43a2-b594-9f721e1b1844",
            "firstName": "Emily",
            "lastName": "Alvarado",
            "fullName": "Emily Alvarado",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34024",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-WA-34-lower-ocd-person-950b27fd-99cb-43a2-b594-9f721e1b1844",
              "builtID": "wa-lower-34",
              "externalID": "ocd-person/950b27fd-99cb-43a2-b594-9f721e1b1844",
              "geometry": null
            },
            "contactInfo": {
              "email": "emily.alvarado@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 425, John L. O’Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7978",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Alvarado/",
                "https://housedemocrats.wa.gov/alvarado/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c9f41cc2-815d-4a97-b354-b6ef3383c1e1",
            "firstName": "Joe",
            "lastName": "Fitzgibbon",
            "fullName": "Joe Fitzgibbon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=13198",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-WA-34-lower-ocd-person-c9f41cc2-815d-4a97-b354-b6ef3383c1e1",
              "builtID": "wa-lower-34",
              "externalID": "ocd-person/c9f41cc2-815d-4a97-b354-b6ef3383c1e1",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.fitzgibbon@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Joe-Fitzgibbon/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Fitzgibbon/",
                "https://housedemocrats.wa.gov/fitzgibbon/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-34-lower-ocd-person-950b27fd-99cb-43a2-b594-9f721e1b1844": 0,
          "SLDL-WA-34-lower-ocd-person-c9f41cc2-815d-4a97-b354-b6ef3383c1e1": 1
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/6a94e7b0-0c19-4377-ac1d-468d2bd3e892",
            "firstName": "Dan",
            "lastName": "Griffey",
            "fullName": "Dan Griffey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=20752",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-WA-35-lower-ocd-person-6a94e7b0-0c19-4377-ac1d-468d2bd3e892",
              "builtID": "wa-lower-35",
              "externalID": "ocd-person/6a94e7b0-0c19-4377-ac1d-468d2bd3e892",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.griffey@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 403, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7966",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Dan-Griffey/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://DanGriffey.houserepublicans.wa.gov/",
                "https://dangriffey.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d1fd0f7b-a1d2-4af6-a9b7-3212e9fadc72",
            "firstName": "Travis",
            "lastName": "Couture",
            "fullName": "Travis Couture",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34029",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-WA-35-lower-ocd-person-d1fd0f7b-a1d2-4af6-a9b7-3212e9fadc72",
              "builtID": "wa-lower-35",
              "externalID": "ocd-person/d1fd0f7b-a1d2-4af6-a9b7-3212e9fadc72",
              "geometry": null
            },
            "contactInfo": {
              "email": "travis.couture@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 404, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7902",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://TravisCouture.houserepublicans.wa.gov/",
                "https://traviscouture.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-35-lower-ocd-person-6a94e7b0-0c19-4377-ac1d-468d2bd3e892": 0,
          "SLDL-WA-35-lower-ocd-person-d1fd0f7b-a1d2-4af6-a9b7-3212e9fadc72": 1
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/f99df1f1-a5ee-4fba-b497-e04ac6e4972d",
            "firstName": "Julia G.",
            "lastName": "Reed",
            "fullName": "Julia Reed",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34033",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-WA-36-lower-ocd-person-f99df1f1-a5ee-4fba-b497-e04ac6e4972d",
              "builtID": "wa-lower-36",
              "externalID": "ocd-person/f99df1f1-a5ee-4fba-b497-e04ac6e4972d",
              "geometry": null
            },
            "contactInfo": {
              "email": "julia.reed@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Reed/",
                "https://housedemocrats.wa.gov/reed/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/1c9235d6-a9c2-420e-991c-94281108df59",
            "firstName": "Elizabeth Jean",
            "lastName": "Berry",
            "fullName": "Liz Berry",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=31531",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-WA-36-lower-ocd-person-1c9235d6-a9c2-420e-991c-94281108df59",
              "builtID": "wa-lower-36",
              "externalID": "ocd-person/1c9235d6-a9c2-420e-991c-94281108df59",
              "geometry": null
            },
            "contactInfo": {
              "email": "liz.berry@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 327, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7860",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Berry/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/berry/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-36-lower-ocd-person-f99df1f1-a5ee-4fba-b497-e04ac6e4972d": 0,
          "SLDL-WA-36-lower-ocd-person-1c9235d6-a9c2-420e-991c-94281108df59": 1
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/84aaed1c-e50a-4bbc-8f63-afd22e825ec2",
            "firstName": "Chipalo",
            "lastName": "Street",
            "fullName": "Chipalo Street",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34036",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-WA-37-lower-ocd-person-84aaed1c-e50a-4bbc-8f63-afd22e825ec2",
              "builtID": "wa-lower-37",
              "externalID": "ocd-person/84aaed1c-e50a-4bbc-8f63-afd22e825ec2",
              "geometry": null
            },
            "contactInfo": {
              "email": "chipalo.street@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 310, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7838",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Street/",
                "https://housedemocrats.wa.gov/street/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/50800e36-5c44-43d0-b786-7828523e92cd",
            "firstName": "Sharon Tomiko",
            "lastName": "Santos",
            "fullName": "Sharon Tomiko Santos",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=3483",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-WA-37-lower-ocd-person-50800e36-5c44-43d0-b786-7828523e92cd",
              "builtID": "wa-lower-37",
              "externalID": "ocd-person/50800e36-5c44-43d0-b786-7828523e92cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "sharontomiko.santos@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 321, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7944",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Sharon-Tomiko-Santos/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Santos/",
                "https://housedemocrats.wa.gov/santos/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-37-lower-ocd-person-84aaed1c-e50a-4bbc-8f63-afd22e825ec2": 0,
          "SLDL-WA-37-lower-ocd-person-50800e36-5c44-43d0-b786-7828523e92cd": 1
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/0805e7bc-f274-4f20-81b4-e8e86951eaba",
            "firstName": "Julio",
            "lastName": "Cortes",
            "fullName": "Julio Cortes",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34028",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-WA-38-lower-ocd-person-0805e7bc-f274-4f20-81b4-e8e86951eaba",
              "builtID": "wa-lower-38",
              "externalID": "ocd-person/0805e7bc-f274-4f20-81b4-e8e86951eaba",
              "geometry": null
            },
            "contactInfo": {
              "email": "julio.cortes@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 309, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7840",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Cortes/",
                "https://housedemocrats.wa.gov/cortes/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0f013ac8-914a-4ade-acbd-de309228f174",
            "firstName": "Mary",
            "lastName": "Fosse",
            "fullName": "Mary Fosse",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=31542",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-WA-38-lower-ocd-person-0f013ac8-914a-4ade-acbd-de309228f174",
              "builtID": "wa-lower-38",
              "externalID": "ocd-person/0f013ac8-914a-4ade-acbd-de309228f174",
              "geometry": null
            },
            "contactInfo": {
              "email": "mary.fosse@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Fosse/",
                "https://housedemocrats.wa.gov/fosse/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-38-lower-ocd-person-0805e7bc-f274-4f20-81b4-e8e86951eaba": 0,
          "SLDL-WA-38-lower-ocd-person-0f013ac8-914a-4ade-acbd-de309228f174": 1
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/b8603b1c-5df7-4c4d-a785-ed3f5b2284e4",
            "firstName": "Carolyn",
            "lastName": "Eslick",
            "fullName": "Carolyn Eslick",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=27988",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-WA-39-lower-ocd-person-b8603b1c-5df7-4c4d-a785-ed3f5b2284e4",
              "builtID": "wa-lower-39",
              "externalID": "ocd-person/b8603b1c-5df7-4c4d-a785-ed3f5b2284e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "carolyn.eslick@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 436, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7816",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Carolyn-Eslick/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://CarolynEslick.houserepublicans.wa.gov/",
                "https://carolyneslick.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d3783097-d29a-46e1-a0da-ed7d8cab5a04",
            "firstName": "Sam",
            "lastName": "Low",
            "fullName": "Sam Low",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34031",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-WA-39-lower-ocd-person-d3783097-d29a-46e1-a0da-ed7d8cab5a04",
              "builtID": "wa-lower-39",
              "externalID": "ocd-person/d3783097-d29a-46e1-a0da-ed7d8cab5a04",
              "geometry": null
            },
            "contactInfo": {
              "email": "sam.low@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 430, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7967",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://SamLow.houserepublicans.wa.gov/",
                "https://samlow.houserepublicans.wa.gov/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-39-lower-ocd-person-b8603b1c-5df7-4c4d-a785-ed3f5b2284e4": 0,
          "SLDL-WA-39-lower-ocd-person-d3783097-d29a-46e1-a0da-ed7d8cab5a04": 1
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/193dce49-ab25-4608-b4d2-65d80fd3d46c",
            "firstName": "Alexander W.",
            "lastName": "Ramel",
            "fullName": "Alex Ramel",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=30127",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-WA-40-lower-ocd-person-193dce49-ab25-4608-b4d2-65d80fd3d46c",
              "builtID": "wa-lower-40",
              "externalID": "ocd-person/193dce49-ab25-4608-b4d2-65d80fd3d46c",
              "geometry": null
            },
            "contactInfo": {
              "email": "alex.ramel@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 132D, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7970",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Ramel/",
                "https://housedemocrats.wa.gov/ramel/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/5f974a76-f712-4412-89c9-a35919dcc11e",
            "firstName": "Debra E.",
            "lastName": "Lekanoff",
            "fullName": "Debra Lekanoff",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29106",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-WA-40-lower-ocd-person-5f974a76-f712-4412-89c9-a35919dcc11e",
              "builtID": "wa-lower-40",
              "externalID": "ocd-person/5f974a76-f712-4412-89c9-a35919dcc11e",
              "geometry": null
            },
            "contactInfo": {
              "email": "debra.lekanoff@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 422, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7800",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Debra-Lekanoff/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Lekanoff/",
                "https://housedemocrats.wa.gov/lekanoff/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-40-lower-ocd-person-193dce49-ab25-4608-b4d2-65d80fd3d46c": 0,
          "SLDL-WA-40-lower-ocd-person-5f974a76-f712-4412-89c9-a35919dcc11e": 1
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/581f4e17-5947-4df8-8440-6f267ef7b528",
            "firstName": "My-Linh",
            "lastName": "Thai",
            "fullName": "My-Linh Thai",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29107",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-WA-41-lower-ocd-person-581f4e17-5947-4df8-8440-6f267ef7b528",
              "builtID": "wa-lower-41",
              "externalID": "ocd-person/581f4e17-5947-4df8-8440-6f267ef7b528",
              "geometry": null
            },
            "contactInfo": {
              "email": "my-linh.thai@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 421, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7926",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/My-Linh-Thai/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Thai/",
                "https://housedemocrats.wa.gov/thai/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8fe4e10b-713a-4ffa-8347-97c1c17d7ae1",
            "firstName": "Tana",
            "lastName": "Senn",
            "fullName": "Tana Senn",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=18057",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-WA-41-lower-ocd-person-8fe4e10b-713a-4ffa-8347-97c1c17d7ae1",
              "builtID": "wa-lower-41",
              "externalID": "ocd-person/8fe4e10b-713a-4ffa-8347-97c1c17d7ae1",
              "geometry": null
            },
            "contactInfo": {
              "email": "tana.senn@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 420, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7894",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Tana-Senn/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Senn/",
                "https://housedemocrats.wa.gov/senn/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-41-lower-ocd-person-581f4e17-5947-4df8-8440-6f267ef7b528": 0,
          "SLDL-WA-41-lower-ocd-person-8fe4e10b-713a-4ffa-8347-97c1c17d7ae1": 1
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/b735531c-ec99-4e19-9f43-967d951f8010",
            "firstName": "Alicia",
            "lastName": "Rule",
            "fullName": "Alicia Rule",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=31533",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-WA-42-lower-ocd-person-b735531c-ec99-4e19-9f43-967d951f8010",
              "builtID": "wa-lower-42",
              "externalID": "ocd-person/b735531c-ec99-4e19-9f43-967d951f8010",
              "geometry": null
            },
            "contactInfo": {
              "email": "alicia.rule@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 334, John L. O’Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7980",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Rule/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/rule/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/ea2c1a95-5b70-4068-8135-8166147bafa9",
            "firstName": "Joseph A.",
            "lastName": "Timmons",
            "fullName": "Joe Timmons",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34037",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-WA-42-lower-ocd-person-ea2c1a95-5b70-4068-8135-8166147bafa9",
              "builtID": "wa-lower-42",
              "externalID": "ocd-person/ea2c1a95-5b70-4068-8135-8166147bafa9",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.timmons@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 419, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7854",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Timmons/",
                "https://housedemocrats.wa.gov/timmons/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-42-lower-ocd-person-b735531c-ec99-4e19-9f43-967d951f8010": 0,
          "SLDL-WA-42-lower-ocd-person-ea2c1a95-5b70-4068-8135-8166147bafa9": 1
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/9496ea2e-3fb2-4562-850d-4b3d064e8af6",
            "firstName": "Frank Vana",
            "lastName": "Chopp",
            "fullName": "Frank Chopp",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=1659",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-WA-43-lower-ocd-person-9496ea2e-3fb2-4562-850d-4b3d064e8af6",
              "builtID": "wa-lower-43",
              "externalID": "ocd-person/9496ea2e-3fb2-4562-850d-4b3d064e8af6",
              "geometry": null
            },
            "contactInfo": {
              "email": "frank.chopp@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 429A, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7920",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Frank-Chopp/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Chopp/",
                "https://housedemocrats.wa.gov/chopp/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/989733b9-6846-465f-9ed6-44b1b823e7c4",
            "firstName": "Nicole",
            "lastName": "Macri",
            "fullName": "Nicole Macri",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=26178",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-WA-43-lower-ocd-person-989733b9-6846-465f-9ed6-44b1b823e7c4",
              "builtID": "wa-lower-43",
              "externalID": "ocd-person/989733b9-6846-465f-9ed6-44b1b823e7c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "nicole.macri@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 336, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7826",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Nicole-Macri/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Macri/",
                "https://housedemocrats.wa.gov/macri/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-43-lower-ocd-person-9496ea2e-3fb2-4562-850d-4b3d064e8af6": 0,
          "SLDL-WA-43-lower-ocd-person-989733b9-6846-465f-9ed6-44b1b823e7c4": 1
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/aebe068c-accc-4740-9e0d-97d20404ee95",
            "firstName": "April",
            "lastName": "Berg",
            "fullName": "April Berg",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=31534",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-WA-44-lower-ocd-person-aebe068c-accc-4740-9e0d-97d20404ee95",
              "builtID": "wa-lower-44",
              "externalID": "ocd-person/aebe068c-accc-4740-9e0d-97d20404ee95",
              "geometry": null
            },
            "contactInfo": {
              "email": "april.berg@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 132C, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7892",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Berg/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/berg/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0f60580f-c121-4661-ae02-5acdfb3923d8",
            "firstName": "Brandy",
            "lastName": "Donaghy",
            "fullName": "Brandy Donaghy",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=33818",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-WA-44-lower-ocd-person-0f60580f-c121-4661-ae02-5acdfb3923d8",
              "builtID": "wa-lower-44",
              "externalID": "ocd-person/0f60580f-c121-4661-ae02-5acdfb3923d8",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandy.donaghy@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Donaghy/",
                "https://housedemocrats.wa.gov/donaghy/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-44-lower-ocd-person-aebe068c-accc-4740-9e0d-97d20404ee95": 0,
          "SLDL-WA-44-lower-ocd-person-0f60580f-c121-4661-ae02-5acdfb3923d8": 1
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/a677ddb3-2d16-479d-aeaf-7d90e7c17509",
            "firstName": "Larry S.",
            "lastName": "Springer",
            "fullName": "Larry Springer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=10039",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-WA-45-lower-ocd-person-a677ddb3-2d16-479d-aeaf-7d90e7c17509",
              "builtID": "wa-lower-45",
              "externalID": "ocd-person/a677ddb3-2d16-479d-aeaf-7d90e7c17509",
              "geometry": null
            },
            "contactInfo": {
              "email": "larry.springer@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 437A, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7822",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Larry-Springer/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Springer/",
                "https://housedemocrats.wa.gov/springer/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c9908a1e-4d33-45e2-9caa-ace8a8232d7e",
            "firstName": "Roger",
            "lastName": "Goodman",
            "fullName": "Roger Goodman",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=11999",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-WA-45-lower-ocd-person-c9908a1e-4d33-45e2-9caa-ace8a8232d7e",
              "builtID": "wa-lower-45",
              "externalID": "ocd-person/c9908a1e-4d33-45e2-9caa-ace8a8232d7e",
              "geometry": null
            },
            "contactInfo": {
              "email": "roger.goodman@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 436B, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7878",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Roger-Goodman/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Goodman/",
                "https://housedemocrats.wa.gov/goodman/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-45-lower-ocd-person-a677ddb3-2d16-479d-aeaf-7d90e7c17509": 0,
          "SLDL-WA-45-lower-ocd-person-c9908a1e-4d33-45e2-9caa-ace8a8232d7e": 1
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/1effdaf6-73b9-413e-8161-8d2e7080d8df",
            "firstName": "Darya",
            "lastName": "Farivar",
            "fullName": "Darya Farivar",
            "gender": "X",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34030",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-WA-46-lower-ocd-person-1effdaf6-73b9-413e-8161-8d2e7080d8df",
              "builtID": "wa-lower-46",
              "externalID": "ocd-person/1effdaf6-73b9-413e-8161-8d2e7080d8df",
              "geometry": null
            },
            "contactInfo": {
              "email": "darya.farivar@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 369, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7818",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Farivar/",
                "https://housedemocrats.wa.gov/farivar/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/7f883cd5-f6d1-499c-b14f-0fa2774a0c5c",
            "firstName": "Gerry",
            "lastName": "Pollet",
            "fullName": "Gerry Pollet",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=16596",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-WA-46-lower-ocd-person-7f883cd5-f6d1-499c-b14f-0fa2774a0c5c",
              "builtID": "wa-lower-46",
              "externalID": "ocd-person/7f883cd5-f6d1-499c-b14f-0fa2774a0c5c",
              "geometry": null
            },
            "contactInfo": {
              "email": "gerry.pollet@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 438A, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7886",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Gerry-Pollet/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Pollet/",
                "https://housedemocrats.wa.gov/pollet/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-46-lower-ocd-person-1effdaf6-73b9-413e-8161-8d2e7080d8df": 0,
          "SLDL-WA-46-lower-ocd-person-7f883cd5-f6d1-499c-b14f-0fa2774a0c5c": 1
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/248654b7-4166-4d2d-8578-cbb6d9210e06",
            "firstName": "Christopher T.",
            "lastName": "Stearns",
            "fullName": "Chris Stearns",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34023",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-WA-47-lower-ocd-person-248654b7-4166-4d2d-8578-cbb6d9210e06",
              "builtID": "wa-lower-47",
              "externalID": "ocd-person/248654b7-4166-4d2d-8578-cbb6d9210e06",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.stearns@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 306, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7858",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/Stearns/",
                "https://housedemocrats.wa.gov/stearns/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/76f7fe2c-a48e-4f55-9607-29c89fe3d203",
            "firstName": "Debra Jean",
            "lastName": "Entenman",
            "fullName": "Debra Entenman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=11403",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-WA-47-lower-ocd-person-76f7fe2c-a48e-4f55-9607-29c89fe3d203",
              "builtID": "wa-lower-47",
              "externalID": "ocd-person/76f7fe2c-a48e-4f55-9607-29c89fe3d203",
              "geometry": null
            },
            "contactInfo": {
              "email": "debra.entenman@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 305, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7918",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Debra-Entenman/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Entenman/",
                "https://housedemocrats.wa.gov/entenman/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-47-lower-ocd-person-248654b7-4166-4d2d-8578-cbb6d9210e06": 0,
          "SLDL-WA-47-lower-ocd-person-76f7fe2c-a48e-4f55-9607-29c89fe3d203": 1
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/80548fd7-06a0-44f1-b121-7caae4819ed6",
            "firstName": "Amy",
            "lastName": "Walen",
            "fullName": "Amy Walen",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29109",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-WA-48-lower-ocd-person-80548fd7-06a0-44f1-b121-7caae4819ed6",
              "builtID": "wa-lower-48",
              "externalID": "ocd-person/80548fd7-06a0-44f1-b121-7caae4819ed6",
              "geometry": null
            },
            "contactInfo": {
              "email": "amy.walen@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 437B, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "425-883-3366",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Amy-Walen/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Walen/",
                "https://housedemocrats.wa.gov/walen/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/22583293-d377-4c51-84ca-d00ba4d7e944",
            "firstName": "Vandana",
            "lastName": "Slatter",
            "fullName": "Vandana Slatter",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=27504",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-WA-48-lower-ocd-person-22583293-d377-4c51-84ca-d00ba4d7e944",
              "builtID": "wa-lower-48",
              "externalID": "ocd-person/22583293-d377-4c51-84ca-d00ba4d7e944",
              "geometry": null
            },
            "contactInfo": {
              "email": "vandana.slatter@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 308, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7936",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Vandana-Slatter/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Slatter/",
                "https://housedemocrats.wa.gov/slatter/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-48-lower-ocd-person-80548fd7-06a0-44f1-b121-7caae4819ed6": 0,
          "SLDL-WA-48-lower-ocd-person-22583293-d377-4c51-84ca-d00ba4d7e944": 1
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/fabb9834-0e6d-476b-ba0f-50d4ecf74a43",
            "firstName": "Monica Jurado",
            "lastName": "Stonier",
            "fullName": "Monica Stonier",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=17279",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-WA-49-lower-ocd-person-fabb9834-0e6d-476b-ba0f-50d4ecf74a43",
              "builtID": "wa-lower-49",
              "externalID": "ocd-person/fabb9834-0e6d-476b-ba0f-50d4ecf74a43",
              "geometry": null
            },
            "contactInfo": {
              "email": "monica.stonier@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 436A, Legislative Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7872",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Monica-Jurado-Stonier/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Stonier/",
                "https://housedemocrats.wa.gov/stonier/"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/6f15e225-ea56-4f95-90c6-64627f367424",
            "firstName": "Sharon",
            "lastName": "Wylie",
            "fullName": "Sharon Wylie",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=16462",
            "title": "WA Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-WA-49-lower-ocd-person-6f15e225-ea56-4f95-90c6-64627f367424",
              "builtID": "wa-lower-49",
              "externalID": "ocd-person/6f15e225-ea56-4f95-90c6-64627f367424",
              "geometry": null
            },
            "contactInfo": {
              "email": "sharon.wylie@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 414, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7924",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Sharon-Wylie/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Wylie/",
                "https://housedemocrats.wa.gov/wylie/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WA-49-lower-ocd-person-fabb9834-0e6d-476b-ba0f-50d4ecf74a43": 0,
          "SLDL-WA-49-lower-ocd-person-6f15e225-ea56-4f95-90c6-64627f367424": 1
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/25ac8674-b208-4d11-a579-c58c004f825e",
            "firstName": "Derek",
            "lastName": "Stanford",
            "fullName": "Derek Stanford",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=15809",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-WA-1-upper-ocd-person-25ac8674-b208-4d11-a579-c58c004f825e",
              "builtID": "wa-upper-1",
              "externalID": "ocd-person/25ac8674-b208-4d11-a579-c58c004f825e",
              "geometry": null
            },
            "contactInfo": {
              "email": "derek.stanford@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 402, Legislative Building P.O. Box 40401, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7600",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/stanford/",
                "https://leg.wa.gov/Senate/Senators/Pages/default.aspx",
                "https://sdc.wastateleg.org/Stanford/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/stanford/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-1-upper-ocd-person-25ac8674-b208-4d11-a579-c58c004f825e": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/fc68fcd2-66ca-481a-b699-7a4ba113d4d7",
            "firstName": "Jim",
            "lastName": "McCune",
            "fullName": "Jim McCune",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=2584",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-WA-2-upper-ocd-person-fc68fcd2-66ca-481a-b699-7a4ba113d4d7",
              "builtID": "wa-upper-2",
              "externalID": "ocd-person/fc68fcd2-66ca-481a-b699-7a4ba113d4d7",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.mccune@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.leg.wa.gov/house/representatives/Pages/mccune.aspx",
                "https://JimMcCune.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://jimmccune.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-2-upper-ocd-person-fc68fcd2-66ca-481a-b699-7a4ba113d4d7": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/7c66f731-b19a-4855-af96-0dbd0ff0eb7a",
            "firstName": "Andrew Swire",
            "lastName": "Billig",
            "fullName": "Andy Billig",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=15811",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-WA-3-upper-ocd-person-7c66f731-b19a-4855-af96-0dbd0ff0eb7a",
              "builtID": "wa-upper-3",
              "externalID": "ocd-person/7c66f731-b19a-4855-af96-0dbd0ff0eb7a",
              "geometry": null
            },
            "contactInfo": {
              "email": "andy.billig@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 307, Legislative Building P.O. Box 40403, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7604",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Billig/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/billig/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-3-upper-ocd-person-7c66f731-b19a-4855-af96-0dbd0ff0eb7a": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/4bb9917a-eef5-4041-8f81-e97129e178be",
            "firstName": "Michael John",
            "lastName": "Padden",
            "fullName": "Mike Padden",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=322",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-WA-4-upper-ocd-person-4bb9917a-eef5-4041-8f81-e97129e178be",
              "builtID": "wa-upper-4",
              "externalID": "ocd-person/4bb9917a-eef5-4041-8f81-e97129e178be",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.padden@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5105 E. 3rd Ave. Suite 102, Spokane Valley, WA 99212",
              "phone1": null,
              "phone2": "509-921-2460",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://MikePadden.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://mikepadden.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-4-upper-ocd-person-4bb9917a-eef5-4041-8f81-e97129e178be": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/f3cede47-c452-4c44-acda-5449dafacad6",
            "firstName": "Mark Douglas",
            "lastName": "Mullet",
            "fullName": "Mark Mullet",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=17226",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-WA-5-upper-ocd-person-f3cede47-c452-4c44-acda-5449dafacad6",
              "builtID": "wa-upper-5",
              "externalID": "ocd-person/f3cede47-c452-4c44-acda-5449dafacad6",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.mullet@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 415, Legislative Building P.O. Box 40405, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7608",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Mullet/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/mullet/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-5-upper-ocd-person-f3cede47-c452-4c44-acda-5449dafacad6": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/0396b4de-01b1-4784-a604-aafc516f5e39",
            "firstName": "Jeffrey Mark",
            "lastName": "Holy",
            "fullName": "Jeff Holy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=17223",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-WA-6-upper-ocd-person-0396b4de-01b1-4784-a604-aafc516f5e39",
              "builtID": "wa-upper-6",
              "externalID": "ocd-person/0396b4de-01b1-4784-a604-aafc516f5e39",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.holy@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "9 S. Washington St. Suite 302, Spokane, WA 99201",
              "phone1": null,
              "phone2": "509-456-2747",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://JeffHoly.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://jeffholy.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-6-upper-ocd-person-0396b4de-01b1-4784-a604-aafc516f5e39": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/a45d8fe7-d052-4d18-bc5f-b5911948d42c",
            "firstName": "Shelly Anne",
            "lastName": "Short",
            "fullName": "Shelly Short",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=11952",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-WA-7-upper-ocd-person-a45d8fe7-d052-4d18-bc5f-b5911948d42c",
              "builtID": "wa-upper-7",
              "externalID": "ocd-person/a45d8fe7-d052-4d18-bc5f-b5911948d42c",
              "geometry": null
            },
            "contactInfo": {
              "email": "shelly.short@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 409, Legislative Building P.O. Box 40407, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7612",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://ShellyShort.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://shellyshort.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-7-upper-ocd-person-a45d8fe7-d052-4d18-bc5f-b5911948d42c": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/bfbe4a83-bf2f-45da-83c6-5ac1225ff0b8",
            "firstName": "Matthew A.",
            "lastName": "Boehnke",
            "fullName": "Matt Boehnke",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29094",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-WA-8-upper-ocd-person-bfbe4a83-bf2f-45da-83c6-5ac1225ff0b8",
              "builtID": "wa-upper-8",
              "externalID": "ocd-person/bfbe4a83-bf2f-45da-83c6-5ac1225ff0b8",
              "geometry": null
            },
            "contactInfo": {
              "email": "matt.boehnke@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 117, Legislative Modular Building P.O. Box 40408, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7614",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Matt-Boehnke/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://MattBoehnke.houserepublicans.wa.gov/",
                "https://MattBoehnke.src.wastateleg.org/",
                "https://mattboehnke.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-8-upper-ocd-person-bfbe4a83-bf2f-45da-83c6-5ac1225ff0b8": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/1f837050-2dca-48fa-b429-a562d1c858f8",
            "firstName": "Mark G.",
            "lastName": "Schoesler",
            "fullName": "Mark Schoesler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=652",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-WA-9-upper-ocd-person-1f837050-2dca-48fa-b429-a562d1c858f8",
              "builtID": "wa-upper-9",
              "externalID": "ocd-person/1f837050-2dca-48fa-b429-a562d1c858f8",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.schoesler@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://MarkSchoesler.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://markschoesler.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-9-upper-ocd-person-1f837050-2dca-48fa-b429-a562d1c858f8": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/57efa2de-d8dc-4d0b-92a3-7ca12549c339",
            "firstName": "Ron",
            "lastName": "Muzzall",
            "fullName": "Ron Muzzall",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29908",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-WA-10-upper-ocd-person-57efa2de-d8dc-4d0b-92a3-7ca12549c339",
              "builtID": "wa-upper-10",
              "externalID": "ocd-person/57efa2de-d8dc-4d0b-92a3-7ca12549c339",
              "geometry": null
            },
            "contactInfo": {
              "email": "ron.muzzall@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 115, Irv Newhouse Building P.O. Box 40410, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7618",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://leg.wa.gov/Senate/Senators/Pages/default.aspx",
                "https://RonMuzzall.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://ronmuzzall.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-10-upper-ocd-person-57efa2de-d8dc-4d0b-92a3-7ca12549c339": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/86f82668-8d68-45c5-bbcd-a5557b4dad54",
            "firstName": "Robert Alan",
            "lastName": "Hasegawa",
            "fullName": "Bob Hasegawa",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=10030",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-WA-11-upper-ocd-person-86f82668-8d68-45c5-bbcd-a5557b4dad54",
              "builtID": "wa-upper-11",
              "externalID": "ocd-person/86f82668-8d68-45c5-bbcd-a5557b4dad54",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.hasegawa@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 305, Legislative Building P.O. Box 40411, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7616",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Hasegawa/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/hasegawa/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-11-upper-ocd-person-86f82668-8d68-45c5-bbcd-a5557b4dad54": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/11de0f04-3a78-49cf-a5a9-7d7f59bf94b3",
            "firstName": "Bradley Matthew",
            "lastName": "Hawkins",
            "fullName": "Brad Hawkins",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=2006",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-WA-12-upper-ocd-person-11de0f04-3a78-49cf-a5a9-7d7f59bf94b3",
              "builtID": "wa-upper-12",
              "externalID": "ocd-person/11de0f04-3a78-49cf-a5a9-7d7f59bf94b3",
              "geometry": null
            },
            "contactInfo": {
              "email": "brad.hawkins@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://BradHawkins.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://bradhawkins.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-12-upper-ocd-person-11de0f04-3a78-49cf-a5a9-7d7f59bf94b3": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/cce36d0b-a3d8-4375-bf70-06760a2eebc3",
            "firstName": "Judith",
            "lastName": "Warnick",
            "fullName": "Judy Warnick",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=12084",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-WA-13-upper-ocd-person-cce36d0b-a3d8-4375-bf70-06760a2eebc3",
              "builtID": "wa-upper-13",
              "externalID": "ocd-person/cce36d0b-a3d8-4375-bf70-06760a2eebc3",
              "geometry": null
            },
            "contactInfo": {
              "email": "judy.warnick@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 316, Legislative Building P.O. Box 40413, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7624",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://JudyWarnick.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://judywarnick.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-13-upper-ocd-person-cce36d0b-a3d8-4375-bf70-06760a2eebc3": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/a8e7617c-7cbb-45ed-a577-3dd7341a3bd7",
            "firstName": "Curtis",
            "lastName": "King",
            "fullName": "Curtis King",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=13199",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-WA-14-upper-ocd-person-a8e7617c-7cbb-45ed-a577-3dd7341a3bd7",
              "builtID": "wa-upper-14",
              "externalID": "ocd-person/a8e7617c-7cbb-45ed-a577-3dd7341a3bd7",
              "geometry": null
            },
            "contactInfo": {
              "email": "curtis.king@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 414, Legislative Building P.O. Box 40414, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7626",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://CurtisKing.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://curtisking.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-14-upper-ocd-person-a8e7617c-7cbb-45ed-a577-3dd7341a3bd7": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/10ad8a88-bd96-4a0d-9b8f-099b8cdfa35b",
            "firstName": "Nikki",
            "lastName": "Torres",
            "fullName": "Nikki Torres",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=34047",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-WA-15-upper-ocd-person-10ad8a88-bd96-4a0d-9b8f-099b8cdfa35b",
              "builtID": "wa-upper-15",
              "externalID": "ocd-person/10ad8a88-bd96-4a0d-9b8f-099b8cdfa35b",
              "geometry": null
            },
            "contactInfo": {
              "email": "nikki.torres@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 111, Legislative Modular Building P.O. Box 40415, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7684",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://NikkiTorres.src.wastateleg.org/",
                "https://nikkitorres.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-15-upper-ocd-person-10ad8a88-bd96-4a0d-9b8f-099b8cdfa35b": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/b7caabda-31e5-48b5-a4b1-82d2bffae0ae",
            "firstName": "Perry L.",
            "lastName": "Dozier",
            "fullName": "Perry Dozier",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=31535",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-WA-16-upper-ocd-person-b7caabda-31e5-48b5-a4b1-82d2bffae0ae",
              "builtID": "wa-upper-16",
              "externalID": "ocd-person/b7caabda-31e5-48b5-a4b1-82d2bffae0ae",
              "geometry": null
            },
            "contactInfo": {
              "email": "perry.dozier@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 102, Irv Newhouse Building P.O. Box 40416, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7630",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://PerryDozier.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://perrydozier.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-16-upper-ocd-person-b7caabda-31e5-48b5-a4b1-82d2bffae0ae": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/29e84b77-1702-4eb9-970b-4089bea31b21",
            "firstName": "Lynda",
            "lastName": "Wilson",
            "fullName": "Lynda Wilson",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=20758",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-WA-17-upper-ocd-person-29e84b77-1702-4eb9-970b-4089bea31b21",
              "builtID": "wa-upper-17",
              "externalID": "ocd-person/29e84b77-1702-4eb9-970b-4089bea31b21",
              "geometry": null
            },
            "contactInfo": {
              "email": "lynda.wilson@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 205, Irv Newhouse Building P.O. Box 40417, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7632",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://LyndaWilson.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://lyndawilson.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-17-upper-ocd-person-29e84b77-1702-4eb9-970b-4089bea31b21": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/e8716b1c-7767-4a07-b0a8-346e6fe175f5",
            "firstName": "Ann",
            "lastName": "Rivers",
            "fullName": "Ann Rivers",
            "gender": "Female",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=15814",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-WA-18-upper-ocd-person-e8716b1c-7767-4a07-b0a8-346e6fe175f5",
              "builtID": "wa-upper-18",
              "externalID": "ocd-person/e8716b1c-7767-4a07-b0a8-346e6fe175f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "ann.rivers@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://AnnRivers.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://annrivers.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-18-upper-ocd-person-e8716b1c-7767-4a07-b0a8-346e6fe175f5": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/7f7dab20-f60b-494e-a8b1-6527165777fb",
            "firstName": "Jeff",
            "lastName": "Wilson",
            "fullName": "Jeff Wilson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=31537",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-WA-19-upper-ocd-person-7f7dab20-f60b-494e-a8b1-6527165777fb",
              "builtID": "wa-upper-19",
              "externalID": "ocd-person/7f7dab20-f60b-494e-a8b1-6527165777fb",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.wilson@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 109B, Irv Newhouse Building P.O. Box 40419, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7636",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://JeffWilson.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://jeffwilson.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-19-upper-ocd-person-7f7dab20-f60b-494e-a8b1-6527165777fb": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/b4856c5b-d3e0-4227-a4c0-ff6abe81fda7",
            "firstName": "John E.",
            "lastName": "Braun",
            "fullName": "John Braun",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=17289",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-WA-20-upper-ocd-person-b4856c5b-d3e0-4227-a4c0-ff6abe81fda7",
              "builtID": "wa-upper-20",
              "externalID": "ocd-person/b4856c5b-d3e0-4227-a4c0-ff6abe81fda7",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.braun@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 314, Legislative Building P.O. Box 40420, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7638",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://JohnBraun.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://johnbraun.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-20-upper-ocd-person-b4856c5b-d3e0-4227-a4c0-ff6abe81fda7": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/3aa56ec5-a087-40b1-960a-acb9426ccb2a",
            "firstName": "Marko Sakari",
            "lastName": "Liias",
            "fullName": "Marko Liias",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=13546",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-WA-21-upper-ocd-person-3aa56ec5-a087-40b1-960a-acb9426ccb2a",
              "builtID": "wa-upper-21",
              "externalID": "ocd-person/3aa56ec5-a087-40b1-960a-acb9426ccb2a",
              "geometry": null
            },
            "contactInfo": {
              "email": "marko.liias@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 305, John A. Cherberg Building 304 15th Ave. SW, Olympia, WA 98501",
              "phone1": null,
              "phone2": "360-786-7640",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Liias/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/liias/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-21-upper-ocd-person-3aa56ec5-a087-40b1-960a-acb9426ccb2a": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/917d24db-26e0-4ba4-8f98-26c7940327f9",
            "firstName": "Samuel W.",
            "lastName": "Hunt",
            "fullName": "Sam Hunt",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=5155",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-WA-22-upper-ocd-person-917d24db-26e0-4ba4-8f98-26c7940327f9",
              "builtID": "wa-upper-22",
              "externalID": "ocd-person/917d24db-26e0-4ba4-8f98-26c7940327f9",
              "geometry": null
            },
            "contactInfo": {
              "email": "sam.hunt@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 405, Legislative Building P.O. Box 40422, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7642",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Hunt/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/hunt/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-22-upper-ocd-person-917d24db-26e0-4ba4-8f98-26c7940327f9": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/25a5746b-d7c3-425c-a5b4-a942f86576f2",
            "firstName": "Drew Derrick",
            "lastName": "Hansen",
            "fullName": "Drew Hansen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=16499",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-WA-23-upper-ocd-person-25a5746b-d7c3-425c-a5b4-a942f86576f2",
              "builtID": "wa-upper-23",
              "externalID": "ocd-person/25a5746b-d7c3-425c-a5b4-a942f86576f2",
              "geometry": null
            },
            "contactInfo": {
              "email": "drew.hansen@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Drew-Hansen/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Hansen/",
                "https://senatedemocrats.wa.gov/hansen/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-23-upper-ocd-person-25a5746b-d7c3-425c-a5b4-a942f86576f2": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/18f36c55-d647-4041-a989-50440b2d977a",
            "firstName": "Kevin",
            "lastName": "Van De Wege",
            "fullName": "Kevin Van De Wege",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=12003",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-WA-24-upper-ocd-person-18f36c55-d647-4041-a989-50440b2d977a",
              "builtID": "wa-upper-24",
              "externalID": "ocd-person/18f36c55-d647-4041-a989-50440b2d977a",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.vandewege@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 212, John A. Cherberg Building P.O. Box 40424, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7646",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/VanDeWege/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/vandewege/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-24-upper-ocd-person-18f36c55-d647-4041-a989-50440b2d977a": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/1e20aaf3-ffbb-4666-82c2-374b232b41ac",
            "firstName": "Christopher Don",
            "lastName": "Gildon",
            "fullName": "Chris Gildon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29101",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-WA-25-upper-ocd-person-1e20aaf3-ffbb-4666-82c2-374b232b41ac",
              "builtID": "wa-upper-25",
              "externalID": "ocd-person/1e20aaf3-ffbb-4666-82c2-374b232b41ac",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.gildon@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "101 S. Meridian Suite C, Puyallup, WA 98371",
              "phone1": null,
              "phone2": "253-840-4523",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Chris-Gildon/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://ChrisGildon.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://chrisgildon.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-25-upper-ocd-person-1e20aaf3-ffbb-4666-82c2-374b232b41ac": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/f93ca36c-e072-46a3-a70a-2605b425517e",
            "firstName": "Emily Elissa",
            "lastName": "Randall",
            "fullName": "Emily Randall",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29112",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-WA-26-upper-ocd-person-f93ca36c-e072-46a3-a70a-2605b425517e",
              "builtID": "wa-upper-26",
              "externalID": "ocd-person/f93ca36c-e072-46a3-a70a-2605b425517e",
              "geometry": null
            },
            "contactInfo": {
              "email": "emily.randall@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 215, John A. Cherberg Building P.O. Box 40426, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7650",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Randall/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/randall/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-26-upper-ocd-person-f93ca36c-e072-46a3-a70a-2605b425517e": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/28de7c48-9b66-4597-a15f-ce7b132fba5a",
            "firstName": "Yasmin",
            "lastName": "Trudeau",
            "fullName": "Yasmin Trudeau",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=20732",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-WA-27-upper-ocd-person-28de7c48-9b66-4597-a15f-ce7b132fba5a",
              "builtID": "wa-upper-27",
              "externalID": "ocd-person/28de7c48-9b66-4597-a15f-ce7b132fba5a",
              "geometry": null
            },
            "contactInfo": {
              "email": "yasmin.trudeau@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 227, John A. Cherberg Building, 304 15th Ave. SW P.O. Box 40427, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7652",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Trudeau/",
                "https://senatedemocrats.wa.gov/trudeau/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-27-upper-ocd-person-28de7c48-9b66-4597-a15f-ce7b132fba5a": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/dd5d76ce-7efe-4c95-aae3-a59a4676fe78",
            "firstName": "T'wina",
            "lastName": "Nobles",
            "fullName": "T'wina Nobles",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=31536",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-WA-28-upper-ocd-person-dd5d76ce-7efe-4c95-aae3-a59a4676fe78",
              "builtID": "wa-upper-28",
              "externalID": "ocd-person/dd5d76ce-7efe-4c95-aae3-a59a4676fe78",
              "geometry": null
            },
            "contactInfo": {
              "email": "t'wina.nobles@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 416, Legislative Building P.O. Box 40428, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7654",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Nobles/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/nobles/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-28-upper-ocd-person-dd5d76ce-7efe-4c95-aae3-a59a4676fe78": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/0999a0d6-1c62-47cf-aa0d-8250967d3a10",
            "firstName": "Steven E.",
            "lastName": "Conway",
            "fullName": "Steve Conway",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=972",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-WA-29-upper-ocd-person-0999a0d6-1c62-47cf-aa0d-8250967d3a10",
              "builtID": "wa-upper-29",
              "externalID": "ocd-person/0999a0d6-1c62-47cf-aa0d-8250967d3a10",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.conway@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 241, John A. Cherberg Building P.O. Box 40429, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7656",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Conway/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/conway/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-29-upper-ocd-person-0999a0d6-1c62-47cf-aa0d-8250967d3a10": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/d2d282e4-8f73-46f9-8cb1-3beadd003b44",
            "firstName": "Claire E.",
            "lastName": "Wilson",
            "fullName": "Claire Wilson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29090",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-WA-30-upper-ocd-person-d2d282e4-8f73-46f9-8cb1-3beadd003b44",
              "builtID": "wa-upper-30",
              "externalID": "ocd-person/d2d282e4-8f73-46f9-8cb1-3beadd003b44",
              "geometry": null
            },
            "contactInfo": {
              "email": "claire.wilson@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 407, Legislative Building P.O. Box 40430, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7658",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Wilson/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/wilson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-30-upper-ocd-person-d2d282e4-8f73-46f9-8cb1-3beadd003b44": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/2d0faef3-45a8-489c-84bd-9491c8ce0315",
            "firstName": "Philip D.",
            "lastName": "Fortunato",
            "fullName": "Phil Fortunato",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=3474",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-WA-31-upper-ocd-person-2d0faef3-45a8-489c-84bd-9491c8ce0315",
              "builtID": "wa-upper-31",
              "externalID": "ocd-person/2d0faef3-45a8-489c-84bd-9491c8ce0315",
              "geometry": null
            },
            "contactInfo": {
              "email": "phil.fortunato@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 216, Legislative Modular Building, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7660",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://PhilFortunato.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://philfortunato.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-31-upper-ocd-person-2d0faef3-45a8-489c-84bd-9491c8ce0315": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/f2eae839-31f5-4f33-be94-b532774da896",
            "firstName": "Jesse Michael",
            "lastName": "Salomon",
            "fullName": "Jesse Salomon",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29089",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-WA-32-upper-ocd-person-f2eae839-31f5-4f33-be94-b532774da896",
              "builtID": "wa-upper-32",
              "externalID": "ocd-person/f2eae839-31f5-4f33-be94-b532774da896",
              "geometry": null
            },
            "contactInfo": {
              "email": "jesse.salomon@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 404, Legislative Building P.O. Box 40432, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7662",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Salomon/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/salomon/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-32-upper-ocd-person-f2eae839-31f5-4f33-be94-b532774da896": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/6932b1b2-deae-4d39-ac5c-7b75334fb51d",
            "firstName": "Karen Lynne",
            "lastName": "Keiser",
            "fullName": "Karen Keiser",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=1950",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-WA-33-upper-ocd-person-6932b1b2-deae-4d39-ac5c-7b75334fb51d",
              "builtID": "wa-upper-33",
              "externalID": "ocd-person/6932b1b2-deae-4d39-ac5c-7b75334fb51d",
              "geometry": null
            },
            "contactInfo": {
              "email": "karen.keiser@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 219, John A. Cherberg Building P.O. Box 40433, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7664",
              "fax1": null,
              "fax2": "360-786-1999",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Keiser/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/keiser/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-33-upper-ocd-person-6932b1b2-deae-4d39-ac5c-7b75334fb51d": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/f7511475-21ef-4127-bc59-f5e7b76f2579",
            "firstName": "Joseph Thanh Tan",
            "lastName": "Nguyen",
            "fullName": "Joe Nguyen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29088",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "34",
              "chamber": "upper",
              "hashID": "SLDU-WA-34-upper-ocd-person-f7511475-21ef-4127-bc59-f5e7b76f2579",
              "builtID": "wa-upper-34",
              "externalID": "ocd-person/f7511475-21ef-4127-bc59-f5e7b76f2579",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.nguyen@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 235, John A. Cherberg Building P.O. Box 40434, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7667",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Nguyen/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/nguyen/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-34-upper-ocd-person-f7511475-21ef-4127-bc59-f5e7b76f2579": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/908379de-8759-4a6f-97ee-7dbcf2f32fcc",
            "firstName": "Drew C.",
            "lastName": "MacEwen",
            "fullName": "Drew MacEwen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=17221",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "35",
              "chamber": "upper",
              "hashID": "SLDU-WA-35-upper-ocd-person-908379de-8759-4a6f-97ee-7dbcf2f32fcc",
              "builtID": "wa-upper-35",
              "externalID": "ocd-person/908379de-8759-4a6f-97ee-7dbcf2f32fcc",
              "geometry": null
            },
            "contactInfo": {
              "email": "drew.macewen@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 115, Legislative Modular Building Capitol Campus, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7668",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://houserepublicans.wa.gov/Drew-MacEwen/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://DrewMacEwen.houserepublicans.wa.gov/",
                "https://DrewMacEwen.src.wastateleg.org/",
                "https://src.wastateleg.org/drew-macewen/",
                "https://drewmacewen.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-35-upper-ocd-person-908379de-8759-4a6f-97ee-7dbcf2f32fcc": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/e9b85600-e33e-4a0a-af9f-bbcb98c0153f",
            "firstName": "Noel Christina",
            "lastName": "Frame",
            "fullName": "Noel Frame",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=23902",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "36",
              "chamber": "upper",
              "hashID": "SLDU-WA-36-upper-ocd-person-e9b85600-e33e-4a0a-af9f-bbcb98c0153f",
              "builtID": "wa-upper-36",
              "externalID": "ocd-person/e9b85600-e33e-4a0a-af9f-bbcb98c0153f",
              "geometry": null
            },
            "contactInfo": {
              "email": "noel.frame@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 411, Legislative Building P.O. Box 40436, Olympia, WA 98504",
              "phone1": null,
              "phone2": "206-962-5098",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Noel-Frame/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Frame/",
                "https://sdc.wastateleg.org/Frame/",
                "https://senatedemocrats.wa.gov/frame/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-36-upper-ocd-person-e9b85600-e33e-4a0a-af9f-bbcb98c0153f": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/1d4a95b2-4a4f-4acb-b5c6-64850b8e34b9",
            "firstName": "Rebecca J.",
            "lastName": "Saldana",
            "fullName": "Rebecca Saldaña",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=27290",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "37",
              "chamber": "upper",
              "hashID": "SLDU-WA-37-upper-ocd-person-1d4a95b2-4a4f-4acb-b5c6-64850b8e34b9",
              "builtID": "wa-upper-37",
              "externalID": "ocd-person/1d4a95b2-4a4f-4acb-b5c6-64850b8e34b9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rebecca.saldana@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 237, John A. Cherberg Building P.O. Box 40437, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7688",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Saldana/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/saldana/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-37-upper-ocd-person-1d4a95b2-4a4f-4acb-b5c6-64850b8e34b9": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/4f14e499-9199-43c4-a4c0-9d7320b9d769",
            "firstName": "June G.",
            "lastName": "Robinson",
            "fullName": "June Robinson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=18265",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "38",
              "chamber": "upper",
              "hashID": "SLDU-WA-38-upper-ocd-person-4f14e499-9199-43c4-a4c0-9d7320b9d769",
              "builtID": "wa-upper-38",
              "externalID": "ocd-person/4f14e499-9199-43c4-a4c0-9d7320b9d769",
              "geometry": null
            },
            "contactInfo": {
              "email": "june.robinson@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 303, John A. Cherberg Building P.O. Box 40438, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7674",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/June-Robinson/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://sdc.wastateleg.org/Robinson/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/robinson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-38-upper-ocd-person-4f14e499-9199-43c4-a4c0-9d7320b9d769": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/71e64843-6301-40ba-bafc-633ee347c482",
            "firstName": "Keith",
            "lastName": "Wagoner",
            "fullName": "Keith Wagoner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=28317",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "39",
              "chamber": "upper",
              "hashID": "SLDU-WA-39-upper-ocd-person-71e64843-6301-40ba-bafc-633ee347c482",
              "builtID": "wa-upper-39",
              "externalID": "ocd-person/71e64843-6301-40ba-bafc-633ee347c482",
              "geometry": null
            },
            "contactInfo": {
              "email": "keith.wagoner@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 112, Legislative Modular Building, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7676",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://KeithWagoner.src.wastateleg.org/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://keithwagoner.src.wastateleg.org/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-39-upper-ocd-person-71e64843-6301-40ba-bafc-633ee347c482": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/f82a6162-120e-4bf2-90c4-02e22278d96e",
            "firstName": "Elizabeth",
            "lastName": "Lovelett",
            "fullName": "Liz Lovelett",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29548",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "40",
              "chamber": "upper",
              "hashID": "SLDU-WA-40-upper-ocd-person-f82a6162-120e-4bf2-90c4-02e22278d96e",
              "builtID": "wa-upper-40",
              "externalID": "ocd-person/f82a6162-120e-4bf2-90c4-02e22278d96e",
              "geometry": null
            },
            "contactInfo": {
              "email": "liz.lovelett@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 233, John A. Cherberg Building P.O. Box 40440, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7678",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Lovelett/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/lovelett/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-40-upper-ocd-person-f82a6162-120e-4bf2-90c4-02e22278d96e": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/0c274c6c-6813-4a77-b7ed-5576e9989493",
            "firstName": "Lisa",
            "lastName": "Wellman",
            "fullName": "Lisa Wellman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=27211",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "41",
              "chamber": "upper",
              "hashID": "SLDU-WA-41-upper-ocd-person-0c274c6c-6813-4a77-b7ed-5576e9989493",
              "builtID": "wa-upper-41",
              "externalID": "ocd-person/0c274c6c-6813-4a77-b7ed-5576e9989493",
              "geometry": null
            },
            "contactInfo": {
              "email": "lisa.wellman@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 218, John A. Cherberg Building P.O. Box 40441, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7641",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Wellman/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/wellman/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-41-upper-ocd-person-0c274c6c-6813-4a77-b7ed-5576e9989493": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/d95d04c7-e3d0-4120-91c6-2bbeba4c8acb",
            "firstName": "Sharon",
            "lastName": "Shewmake",
            "fullName": "Sharon Shewmake",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=29108",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "42",
              "chamber": "upper",
              "hashID": "SLDU-WA-42-upper-ocd-person-d95d04c7-e3d0-4120-91c6-2bbeba4c8acb",
              "builtID": "wa-upper-42",
              "externalID": "ocd-person/d95d04c7-e3d0-4120-91c6-2bbeba4c8acb",
              "geometry": null
            },
            "contactInfo": {
              "email": "sharon.shewmake@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 310, John L. O'Brien Building P.O. Box 40600, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-746-6939",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Sharon-Shewmake/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Shewmake/",
                "https://sdc.wastateleg.org/Shewmake/",
                "https://senatedemocrats.wa.gov/shewmake/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-42-upper-ocd-person-d95d04c7-e3d0-4120-91c6-2bbeba4c8acb": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/f6d8dcb4-c212-4f2b-a043-a541e47dede0",
            "firstName": "Jamie D.",
            "lastName": "Pedersen",
            "fullName": "Jamie Pedersen",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=12002",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "43",
              "chamber": "upper",
              "hashID": "SLDU-WA-43-upper-ocd-person-f6d8dcb4-c212-4f2b-a043-a541e47dede0",
              "builtID": "wa-upper-43",
              "externalID": "ocd-person/f6d8dcb4-c212-4f2b-a043-a541e47dede0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jamie.pedersen@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 309, Legislative Building P.O. Box 40443, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7628",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Pedersen/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/pedersen/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-43-upper-ocd-person-f6d8dcb4-c212-4f2b-a043-a541e47dede0": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/9849a792-2307-40c8-97ae-388d731fa89b",
            "firstName": "John R.",
            "lastName": "Lovick",
            "fullName": "John Lovick",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=3476",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "44",
              "chamber": "upper",
              "hashID": "SLDU-WA-44-upper-ocd-person-9849a792-2307-40c8-97ae-388d731fa89b",
              "builtID": "wa-upper-44",
              "externalID": "ocd-person/9849a792-2307-40c8-97ae-388d731fa89b",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.lovick@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 226, John A. Cherberg Building P.O. Box 40444, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7686",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/John-Lovick/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Lovick/",
                "https://sdc.wastateleg.org/Lovick/",
                "https://senatedemocrats.wa.gov/lovick/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-44-upper-ocd-person-9849a792-2307-40c8-97ae-388d731fa89b": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/92192e1a-e86f-4605-9d7d-ce4a922681f0",
            "firstName": "Manka",
            "lastName": "Dhingra",
            "fullName": "Manka Dhingra",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=28022",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "45",
              "chamber": "upper",
              "hashID": "SLDU-WA-45-upper-ocd-person-92192e1a-e86f-4605-9d7d-ce4a922681f0",
              "builtID": "wa-upper-45",
              "externalID": "ocd-person/92192e1a-e86f-4605-9d7d-ce4a922681f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "manka.dhingra@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 239, John A. Cherberg Building P.O. Box 40445, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7672",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Dhingra/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/dhingra/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-45-upper-ocd-person-92192e1a-e86f-4605-9d7d-ce4a922681f0": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/aba2c522-236c-4553-bda2-c02d72e79981",
            "firstName": "Javier",
            "lastName": "Valdez",
            "fullName": "Javier Valdez",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=27975",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "46",
              "chamber": "upper",
              "hashID": "SLDU-WA-46-upper-ocd-person-aba2c522-236c-4553-bda2-c02d72e79981",
              "builtID": "wa-upper-46",
              "externalID": "ocd-person/aba2c522-236c-4553-bda2-c02d72e79981",
              "geometry": null
            },
            "contactInfo": {
              "email": "javier.valdez@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 403, Legislative Building P.O. Box 40446, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7690",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://housedemocrats.wa.gov/legislators/Javier-Valdez/",
                "https://www.leg.wa.gov/house/representatives/Pages/default.aspx",
                "https://housedemocrats.wa.gov/Valdez/",
                "https://sdc.wastateleg.org/Valdez/",
                "https://senatedemocrats.wa.gov/valdez/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-46-upper-ocd-person-aba2c522-236c-4553-bda2-c02d72e79981": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/04ed2bc3-648f-4924-8183-1e593572e894",
            "firstName": "Claudia",
            "lastName": "Kauffman",
            "fullName": "Claudia Kauffman",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=12073",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "47",
              "chamber": "upper",
              "hashID": "SLDU-WA-47-upper-ocd-person-04ed2bc3-648f-4924-8183-1e593572e894",
              "builtID": "wa-upper-47",
              "externalID": "ocd-person/04ed2bc3-648f-4924-8183-1e593572e894",
              "geometry": null
            },
            "contactInfo": {
              "email": "claudia.kauffman@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 218, John A. Cherberg Building P.O. Box 40447, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7692",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Kauffman/",
                "https://senatedemocrats.wa.gov/kauffman/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-47-upper-ocd-person-04ed2bc3-648f-4924-8183-1e593572e894": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/ef6a28f2-da94-4a3f-850a-b005cffa884d",
            "firstName": "Patricia",
            "lastName": "Kuderer",
            "fullName": "Patty Kuderer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=21520",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "48",
              "chamber": "upper",
              "hashID": "SLDU-WA-48-upper-ocd-person-ef6a28f2-da94-4a3f-850a-b005cffa884d",
              "builtID": "wa-upper-48",
              "externalID": "ocd-person/ef6a28f2-da94-4a3f-850a-b005cffa884d",
              "geometry": null
            },
            "contactInfo": {
              "email": "patty.kuderer@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Kuderer/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/kuderer/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-48-upper-ocd-person-ef6a28f2-da94-4a3f-850a-b005cffa884d": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/50c73eec-2f66-450b-b8b4-b8bce995e221",
            "firstName": "Annette M.",
            "lastName": "Cleveland",
            "fullName": "Annette Cleveland",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://app.leg.wa.gov/ContentParts/MemberDirectory/LargePhoto?m=17294",
            "title": "WA Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WA",
              "stateFull": "Washington",
              "district": "49",
              "chamber": "upper",
              "hashID": "SLDU-WA-49-upper-ocd-person-50c73eec-2f66-450b-b8b4-b8bce995e221",
              "builtID": "wa-upper-49",
              "externalID": "ocd-person/50c73eec-2f66-450b-b8b4-b8bce995e221",
              "geometry": null
            },
            "contactInfo": {
              "email": "annette.cleveland@leg.wa.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Room 220, John A. Cherberg Building P.O. Box 40449, Olympia, WA 98504",
              "phone1": null,
              "phone2": "360-786-7696",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://sdc.wastateleg.org/Cleveland/",
                "https://www.leg.wa.gov/senate/senators/Pages/default.aspx",
                "https://senatedemocrats.wa.gov/cleveland/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WA-49-upper-ocd-person-50c73eec-2f66-450b-b8b4-b8bce995e221": 0
        }
      }
    }
  },
  "WV": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "WV",
          "stateFull": "West Virginia",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "WV",
            "stateFull": "West Virginia",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-WV---",
            "builtID": "wv--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-WV---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "C001047",
          "in_office": true,
          "firstName": "Shelley",
          "lastName": "Capito",
          "middleName": "Moore",
          "fullName": "Shelley Moore Capito",
          "gender": "F",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/C001047.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "WV",
            "stateFull": "West Virginia",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-WV---C001047",
            "builtID": "wv--",
            "externalID": "C001047",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.capito.senate.gov/contact/contact-shelley",
            "address1": "172 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-6472",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenCapito",
            "youtube": null,
            "instagram": null,
            "facebook": "senshelley",
            "urls": ["https://www.capito.senate.gov"],
            "rss_url": null
          },
          "title": "WV Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/C001047.json",
          "govtrack_id": "400061",
          "cspan_id": "83737",
          "votesmart_id": "11701",
          "icpsr_id": "20146",
          "crp_id": "N00009771",
          "google_entity_id": "/m/024pwq",
          "state_rank": "junior",
          "lis_id": "S372",
          "suffix": null,
          "date_of_birth": "1953-11-26",
          "leadership_role": null,
          "fec_candidate_id": "H0WV02138",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "9",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 26,
          "total_present": 0,
          "last_updated": "2024-06-20 15:30:29 -0400",
          "missed_votes_pct": 4.69,
          "votes_with_party_pct": 87.64,
          "votes_against_party_pct": 12.36,
          "ocd_id": "ocd-division/country:us/state:wv"
        },
        {
          "API_ID": "M001183",
          "in_office": true,
          "firstName": "Joe",
          "lastName": "Manchin",
          "middleName": null,
          "fullName": "Joe Manchin III",
          "gender": "M",
          "party": "Independent",
          "image": "https://theunitedstates.io/images/congress/225x275/M001183.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "WV",
            "stateFull": "West Virginia",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-WV---M001183",
            "builtID": "wv--",
            "externalID": "M001183",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.manchin.senate.gov/public/index.cfm/contact-form",
            "address1": "306 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-3954",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "Sen_JoeManchin",
            "youtube": "SenatorJoeManchin",
            "instagram": null,
            "facebook": "JoeManchinIII",
            "urls": ["https://www.manchin.senate.gov"],
            "rss_url": null
          },
          "title": "WV Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/M001183.json",
          "govtrack_id": "412391",
          "cspan_id": "62864",
          "votesmart_id": "7547",
          "icpsr_id": "40915",
          "crp_id": "N00032838",
          "google_entity_id": "/m/04lc5t",
          "state_rank": "senior",
          "lis_id": "S338",
          "suffix": "III",
          "date_of_birth": "1947-08-24",
          "leadership_role": null,
          "fec_candidate_id": "S0WV00090",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "15",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 48,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 8.66,
          "votes_with_party_pct": 82.38,
          "votes_against_party_pct": 17.62,
          "ocd_id": "ocd-division/country:us/state:wv"
        }
      ],
      "hash": { "SENATE-WV---C001047": 0, "SENATE-WV---M001183": 1 }
    },
    "HOUSE": {
      "01": {
        "members": [
          {
            "API_ID": "M001205",
            "in_office": true,
            "firstName": "Carol",
            "lastName": "Miller",
            "middleName": null,
            "fullName": "Carol D. Miller",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001205.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-WV-01--M001205",
              "builtID": "wv--01",
              "externalID": "M001205",
              "geometry": null,
              "geoid": "5401"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "465 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3452",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepCarolMiller",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://miller.house.gov"],
              "rss_url": null
            },
            "title": "WV House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001205.json",
            "govtrack_id": "412837",
            "cspan_id": null,
            "votesmart_id": "52123",
            "icpsr_id": null,
            "crp_id": "N00041542",
            "google_entity_id": "/m/0102fnyn",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1950-11-04",
            "leadership_role": null,
            "fec_candidate_id": "H8WV03097",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 22,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 2.08,
            "votes_with_party_pct": 93.48,
            "votes_against_party_pct": 4.54,
            "ocd_id": "ocd-division/country:us/state:wv/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WV-01--M001205": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "M001195",
            "in_office": true,
            "firstName": "Alex",
            "lastName": "Mooney",
            "middleName": null,
            "fullName": "Alexander X. Mooney",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/M001195.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-WV-02--M001195",
              "builtID": "wv--02",
              "externalID": "M001195",
              "geometry": null,
              "geoid": "5402"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2228 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-2711",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepAlexMooney",
              "youtube": null,
              "instagram": null,
              "facebook": "CongressmanAlexMooney",
              "urls": ["https://mooney.house.gov"],
              "rss_url": "https://mooney.house.gov/rss.xml"
            },
            "title": "WV House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001195.json",
            "govtrack_id": "412662",
            "cspan_id": "76588",
            "votesmart_id": "145943",
            "icpsr_id": "21557",
            "crp_id": "N00033814",
            "google_entity_id": "/m/02qqy78",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1971-06-05",
            "leadership_role": null,
            "fec_candidate_id": "H4WV02080",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 123,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 11.66,
            "votes_with_party_pct": 89.06,
            "votes_against_party_pct": 8.86,
            "ocd_id": "ocd-division/country:us/state:wv/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WV-02--M001195": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/aa7bf09a-ce90-4e58-a893-42c813a16434",
            "firstName": "Patrick Riley",
            "lastName": "McGeehan",
            "fullName": "Pat McGeehan",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/mcgeehan_pat.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-WV-1-lower-ocd-person-aa7bf09a-ce90-4e58-a893-42c813a16434",
              "builtID": "wv-lower-1",
              "externalID": "ocd-person/aa7bf09a-ce90-4e58-a893-42c813a16434",
              "geometry": null
            },
            "contactInfo": {
              "email": "pat.mcgeehan@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+McGeehan",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20McGeehan",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate+McGeehan"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-1-lower-ocd-person-aa7bf09a-ce90-4e58-a893-42c813a16434": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/af0b8606-3832-40d4-91e2-3b71975e4b8f",
            "firstName": "Mark Peter",
            "lastName": "Zatezalo",
            "fullName": "Mark Zatezalo",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/zatezalo_mark.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-WV-2-lower-ocd-person-af0b8606-3832-40d4-91e2-3b71975e4b8f",
              "builtID": "wv-lower-2",
              "externalID": "ocd-person/af0b8606-3832-40d4-91e2-3b71975e4b8f",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.zatezalo@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Zatezalo",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Zatezalo"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-2-lower-ocd-person-af0b8606-3832-40d4-91e2-3b71975e4b8f": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/5a2928e1-3371-480d-be6f-1c3133de4609",
            "firstName": "James",
            "lastName": "Willis",
            "fullName": "Jimmy Willis",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/willis_jimmy.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-WV-3-lower-ocd-person-5a2928e1-3371-480d-be6f-1c3133de4609",
              "builtID": "wv-lower-3",
              "externalID": "ocd-person/5a2928e1-3371-480d-be6f-1c3133de4609",
              "geometry": null
            },
            "contactInfo": {
              "email": "jimmy.willis@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Willis"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-3-lower-ocd-person-5a2928e1-3371-480d-be6f-1c3133de4609": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/b98b5313-ef53-4eed-99ee-6fed1ad2ff62",
            "firstName": "Diana",
            "lastName": "Winzenreid",
            "fullName": "Diana Winzenreid",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/house/winzenreid_diana.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-WV-4-lower-ocd-person-b98b5313-ef53-4eed-99ee-6fed1ad2ff62",
              "builtID": "wv-lower-4",
              "externalID": "ocd-person/b98b5313-ef53-4eed-99ee-6fed1ad2ff62",
              "geometry": null
            },
            "contactInfo": {
              "email": "diana.winzenreid@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Winzenreid"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-4-lower-ocd-person-b98b5313-ef53-4eed-99ee-6fed1ad2ff62": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/bd0e9ba2-fe37-4251-96f2-44715a186750",
            "firstName": "Shawn Lucas",
            "lastName": "Fluharty",
            "fullName": "Shawn Fluharty",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/fluharty_shawn.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-WV-5-lower-ocd-person-bd0e9ba2-fe37-4251-96f2-44715a186750",
              "builtID": "wv-lower-5",
              "externalID": "ocd-person/bd0e9ba2-fe37-4251-96f2-44715a186750",
              "geometry": null
            },
            "contactInfo": {
              "email": "shawn.fluharty@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Fluharty",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Fluharty",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate+Fluharty"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-5-lower-ocd-person-bd0e9ba2-fe37-4251-96f2-44715a186750": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/0092e49c-3dfe-4a2b-b053-e7a0360accf1",
            "firstName": "Jeffrey R.",
            "lastName": "Stephens",
            "fullName": "Jeff Stephens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2023/house/stephens_jeffrey.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-WV-6-lower-ocd-person-0092e49c-3dfe-4a2b-b053-e7a0360accf1",
              "builtID": "wv-lower-6",
              "externalID": "ocd-person/0092e49c-3dfe-4a2b-b053-e7a0360accf1",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeffrey.stephens@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Stephens"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-6-lower-ocd-person-0092e49c-3dfe-4a2b-b053-e7a0360accf1": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/338acc2a-d910-4c29-aa94-bf39fbebdeec",
            "firstName": "Charles R.",
            "lastName": "Sheedy",
            "fullName": "Chuck Sheedy",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/sheedy_charles.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-WV-7-lower-ocd-person-338acc2a-d910-4c29-aa94-bf39fbebdeec",
              "builtID": "wv-lower-7",
              "externalID": "ocd-person/338acc2a-d910-4c29-aa94-bf39fbebdeec",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.sheedy@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Sheedy"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-7-lower-ocd-person-338acc2a-d910-4c29-aa94-bf39fbebdeec": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/a92b2786-7194-4393-8a69-04dbeb31a2c1",
            "firstName": "David L.",
            "lastName": "Kelly",
            "fullName": "David Kelly",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2024/house/kelly_david.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-WV-8-lower-ocd-person-a92b2786-7194-4393-8a69-04dbeb31a2c1",
              "builtID": "wv-lower-8",
              "externalID": "ocd-person/a92b2786-7194-4393-8a69-04dbeb31a2c1",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.kelly@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Kelly%2C+D.",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Kelly,%20D.",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Kelly"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-8-lower-ocd-person-a92b2786-7194-4393-8a69-04dbeb31a2c1": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/4a909c49-37c6-4105-ac77-afe4745a1b1b",
            "firstName": "Trenton Carl",
            "lastName": "Barnhart",
            "fullName": "Trenton Barnhart",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/barnhart_trenton.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-WV-9-lower-ocd-person-4a909c49-37c6-4105-ac77-afe4745a1b1b",
              "builtID": "wv-lower-9",
              "externalID": "ocd-person/4a909c49-37c6-4105-ac77-afe4745a1b1b",
              "geometry": null
            },
            "contactInfo": {
              "email": "trenton.barnhart@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Barnhart",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Barnhart"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-9-lower-ocd-person-4a909c49-37c6-4105-ac77-afe4745a1b1b": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/b5b3734c-fa9f-422b-b0d4-ce8b2b2624c5",
            "firstName": "Everette William",
            "lastName": "Anderson",
            "fullName": "Bill Anderson",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2022/house/anderson_bill.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-WV-10-lower-ocd-person-b5b3734c-fa9f-422b-b0d4-ce8b2b2624c5",
              "builtID": "wv-lower-10",
              "externalID": "ocd-person/b5b3734c-fa9f-422b-b0d4-ce8b2b2624c5",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.anderson@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Anderson",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Anderson,%20B.",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Anderson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-10-lower-ocd-person-b5b3734c-fa9f-422b-b0d4-ce8b2b2624c5": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/a70a295b-82a5-44a0-ba22-a462823f7abb",
            "firstName": "Robert Joseph",
            "lastName": "Fehrenbacher",
            "fullName": "Bob Fehrenbacher",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/fehrenbacher_bob.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-WV-11-lower-ocd-person-a70a295b-82a5-44a0-ba22-a462823f7abb",
              "builtID": "wv-lower-11",
              "externalID": "ocd-person/a70a295b-82a5-44a0-ba22-a462823f7abb",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.fehrenbacher@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Fehrenbacher"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-11-lower-ocd-person-a70a295b-82a5-44a0-ba22-a462823f7abb": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/381e0784-9202-4308-a138-399c84740804",
            "firstName": "Vernon",
            "lastName": "Criss",
            "fullName": "Vernon Criss",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/criss_vernon.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-WV-12-lower-ocd-person-381e0784-9202-4308-a138-399c84740804",
              "builtID": "wv-lower-12",
              "externalID": "ocd-person/381e0784-9202-4308-a138-399c84740804",
              "geometry": null
            },
            "contactInfo": {
              "email": "vernon.criss@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Criss",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Criss"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-12-lower-ocd-person-381e0784-9202-4308-a138-399c84740804": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/5cd39fcf-7682-4cb0-8ac3-f7c4816f950d",
            "firstName": "Scot Craig",
            "lastName": "Heckert",
            "fullName": "Scot Heckert",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/heckert_scot.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-WV-13-lower-ocd-person-5cd39fcf-7682-4cb0-8ac3-f7c4816f950d",
              "builtID": "wv-lower-13",
              "externalID": "ocd-person/5cd39fcf-7682-4cb0-8ac3-f7c4816f950d",
              "geometry": null
            },
            "contactInfo": {
              "email": "scot.heckert@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Heckert"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-13-lower-ocd-person-5cd39fcf-7682-4cb0-8ac3-f7c4816f950d": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/9ad2e11c-daf4-4f33-9c2d-0075beaeada8",
            "firstName": "David",
            "lastName": "Foggin",
            "fullName": "Dave Foggin",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/foggin_dave.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-WV-14-lower-ocd-person-9ad2e11c-daf4-4f33-9c2d-0075beaeada8",
              "builtID": "wv-lower-14",
              "externalID": "ocd-person/9ad2e11c-daf4-4f33-9c2d-0075beaeada8",
              "geometry": null
            },
            "contactInfo": {
              "email": "dave.foggin@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Foggin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-14-lower-ocd-person-9ad2e11c-daf4-4f33-9c2d-0075beaeada8": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/74fee589-c9e9-4dd7-a719-d9b165b2b2c7",
            "firstName": "Erica J.",
            "lastName": "Moore",
            "fullName": "Erica Moore",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/moore_erica.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-WV-15-lower-ocd-person-74fee589-c9e9-4dd7-a719-d9b165b2b2c7",
              "builtID": "wv-lower-15",
              "externalID": "ocd-person/74fee589-c9e9-4dd7-a719-d9b165b2b2c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "erica.moore@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Moore"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-15-lower-ocd-person-74fee589-c9e9-4dd7-a719-d9b165b2b2c7": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/8e9eb277-8028-4715-9053-7483ad50c5fb",
            "firstName": "Steve",
            "lastName": "Westfall",
            "fullName": "Steve Westfall",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/westfall_steve.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-WV-16-lower-ocd-person-8e9eb277-8028-4715-9053-7483ad50c5fb",
              "builtID": "wv-lower-16",
              "externalID": "ocd-person/8e9eb277-8028-4715-9053-7483ad50c5fb",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.westfall@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Westfall",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Westfall",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate+Westfall"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-16-lower-ocd-person-8e9eb277-8028-4715-9053-7483ad50c5fb": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/a71cd621-aa1e-4338-b0bd-ca9f7a03f667",
            "firstName": "Jonathan Adam",
            "lastName": "Pinson",
            "fullName": "Jonathan Pinson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/house/pinson_jonathan.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-WV-17-lower-ocd-person-a71cd621-aa1e-4338-b0bd-ca9f7a03f667",
              "builtID": "wv-lower-17",
              "externalID": "ocd-person/a71cd621-aa1e-4338-b0bd-ca9f7a03f667",
              "geometry": null
            },
            "contactInfo": {
              "email": "jonathan.pinson@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Pinson",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Pinson"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-17-lower-ocd-person-a71cd621-aa1e-4338-b0bd-ca9f7a03f667": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/905290bd-c655-4a3d-9961-65a2f9c0e271",
            "firstName": "James Harry",
            "lastName": "Butler",
            "fullName": "Jim Butler",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/butler_jim.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-WV-18-lower-ocd-person-905290bd-c655-4a3d-9961-65a2f9c0e271",
              "builtID": "wv-lower-18",
              "externalID": "ocd-person/905290bd-c655-4a3d-9961-65a2f9c0e271",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.butler@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Butler",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Butler",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Butler",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate+Butler"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-18-lower-ocd-person-905290bd-c655-4a3d-9961-65a2f9c0e271": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/0686b8e2-2d6a-4950-b3c3-0dbfe5a6e719",
            "firstName": "Kathryn Lynn",
            "lastName": "Hess Crouse",
            "fullName": "Kathie Hess Crouse",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/house/crouse_kathie.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-WV-19-lower-ocd-person-0686b8e2-2d6a-4950-b3c3-0dbfe5a6e719",
              "builtID": "wv-lower-19",
              "externalID": "ocd-person/0686b8e2-2d6a-4950-b3c3-0dbfe5a6e719",
              "geometry": null
            },
            "contactInfo": {
              "email": "kathie.crouse@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "Buffalo, WV",
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Crouse"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-19-lower-ocd-person-0686b8e2-2d6a-4950-b3c3-0dbfe5a6e719": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/f4aa8149-e34f-4a7d-b43a-fdc975e797de",
            "firstName": "Geoffrey Bruce",
            "lastName": "Foster",
            "fullName": "Geoff Foster",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/foster_geoff.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-WV-20-lower-ocd-person-f4aa8149-e34f-4a7d-b43a-fdc975e797de",
              "builtID": "wv-lower-20",
              "externalID": "ocd-person/f4aa8149-e34f-4a7d-b43a-fdc975e797de",
              "geometry": null
            },
            "contactInfo": {
              "email": "geoff.foster@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Foster%2C+G.",
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Foster",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Foster"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-20-lower-ocd-person-f4aa8149-e34f-4a7d-b43a-fdc975e797de": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/e617259d-e2c9-4043-a759-321c7e3ff7f0",
            "firstName": "Jarred Alexander",
            "lastName": "Cannon",
            "fullName": "Jarred Cannon",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2022/house/cannon_jarred.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-WV-21-lower-ocd-person-e617259d-e2c9-4043-a759-321c7e3ff7f0",
              "builtID": "wv-lower-21",
              "externalID": "ocd-person/e617259d-e2c9-4043-a759-321c7e3ff7f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jarred.cannon@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Cannon"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-21-lower-ocd-person-e617259d-e2c9-4043-a759-321c7e3ff7f0": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/32c4e5cf-81f5-4564-bb9b-568d13156dec",
            "firstName": "Daniel",
            "lastName": "Linville",
            "fullName": "Daniel Linville",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/linville_daniel.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-WV-22-lower-ocd-person-32c4e5cf-81f5-4564-bb9b-568d13156dec",
              "builtID": "wv-lower-22",
              "externalID": "ocd-person/32c4e5cf-81f5-4564-bb9b-568d13156dec",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.linville@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Linville",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Linville"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-22-lower-ocd-person-32c4e5cf-81f5-4564-bb9b-568d13156dec": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/b875b7d6-4941-42a6-bcdf-c5da19bf0ced",
            "firstName": "Evan",
            "lastName": "Worrell",
            "fullName": "Evan Worrell",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/worrell_evan.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-WV-23-lower-ocd-person-b875b7d6-4941-42a6-bcdf-c5da19bf0ced",
              "builtID": "wv-lower-23",
              "externalID": "ocd-person/b875b7d6-4941-42a6-bcdf-c5da19bf0ced",
              "geometry": null
            },
            "contactInfo": {
              "email": "evan.worrell@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Worrell",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Worrell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-23-lower-ocd-person-b875b7d6-4941-42a6-bcdf-c5da19bf0ced": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/9a7c2d4d-002a-439e-b095-e7b86329fc1c",
            "firstName": "Patrick",
            "lastName": "Lucas",
            "fullName": "Patrick Lucas",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/lucas_patrick.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-WV-24-lower-ocd-person-9a7c2d4d-002a-439e-b095-e7b86329fc1c",
              "builtID": "wv-lower-24",
              "externalID": "ocd-person/9a7c2d4d-002a-439e-b095-e7b86329fc1c",
              "geometry": null
            },
            "contactInfo": {
              "email": "patrick.lucas@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Lucas"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-24-lower-ocd-person-9a7c2d4d-002a-439e-b095-e7b86329fc1c": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/916ee127-a228-4dac-b945-312a5f76121a",
            "firstName": "Sean",
            "lastName": "Hornbuckle",
            "fullName": "Sean Hornbuckle",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/hornbuckle_sean.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-WV-25-lower-ocd-person-916ee127-a228-4dac-b945-312a5f76121a",
              "builtID": "wv-lower-25",
              "externalID": "ocd-person/916ee127-a228-4dac-b945-312a5f76121a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sean.hornbuckle@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Hornbuckle",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Hornbuckle",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate+Hornbuckle"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-25-lower-ocd-person-916ee127-a228-4dac-b945-312a5f76121a": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/074a2aa9-d012-437a-8d59-d3f4b9d73905",
            "firstName": "Matthew",
            "lastName": "Rohrbach",
            "fullName": "Matthew Rohrbach",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/rohrbach_matthew.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-WV-26-lower-ocd-person-074a2aa9-d012-437a-8d59-d3f4b9d73905",
              "builtID": "wv-lower-26",
              "externalID": "ocd-person/074a2aa9-d012-437a-8d59-d3f4b9d73905",
              "geometry": null
            },
            "contactInfo": {
              "email": "matthew.rohrbach@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Rohrbach",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Rohrbach",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate+Rohrbach"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-26-lower-ocd-person-074a2aa9-d012-437a-8d59-d3f4b9d73905": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/8d3bb7bf-d02a-4d5f-a3c3-04a6218ffbea",
            "firstName": "Ric",
            "lastName": "Griffith",
            "fullName": "Ric Griffith",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/griffith_ric.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-WV-27-lower-ocd-person-8d3bb7bf-d02a-4d5f-a3c3-04a6218ffbea",
              "builtID": "wv-lower-27",
              "externalID": "ocd-person/8d3bb7bf-d02a-4d5f-a3c3-04a6218ffbea",
              "geometry": null
            },
            "contactInfo": {
              "email": "ric.griffith@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Griffith",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Griffith"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-27-lower-ocd-person-8d3bb7bf-d02a-4d5f-a3c3-04a6218ffbea": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/c95f5292-14c3-4e79-8465-05cca13242b6",
            "firstName": "Ryan",
            "lastName": "Browning",
            "fullName": "Ryan Browning",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/house/browning_ryan.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-WV-28-lower-ocd-person-c95f5292-14c3-4e79-8465-05cca13242b6",
              "builtID": "wv-lower-28",
              "externalID": "ocd-person/c95f5292-14c3-4e79-8465-05cca13242b6",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryan.browning@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Browning"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-28-lower-ocd-person-c95f5292-14c3-4e79-8465-05cca13242b6": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/e05b927b-a702-4143-87b2-79a8c81d4eee",
            "firstName": "Henry Corbett",
            "lastName": "Dillon",
            "fullName": "Corby Dillon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/house/dillon_henry.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-WV-29-lower-ocd-person-e05b927b-a702-4143-87b2-79a8c81d4eee",
              "builtID": "wv-lower-29",
              "externalID": "ocd-person/e05b927b-a702-4143-87b2-79a8c81d4eee",
              "geometry": null
            },
            "contactInfo": {
              "email": "henry.dillon@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Dillon"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-29-lower-ocd-person-e05b927b-a702-4143-87b2-79a8c81d4eee": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/30543e71-ae37-4efa-af20-7d00492c459f",
            "firstName": "David A.",
            "lastName": "Adkins",
            "fullName": "Flimsy Adkins",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/adkins_david.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-WV-30-lower-ocd-person-30543e71-ae37-4efa-af20-7d00492c459f",
              "builtID": "wv-lower-30",
              "externalID": "ocd-person/30543e71-ae37-4efa-af20-7d00492c459f",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.adkins@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Adkins"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-30-lower-ocd-person-30543e71-ae37-4efa-af20-7d00492c459f": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/92599af8-32f7-4aa4-bab2-8c7b461f54a5",
            "firstName": "Margitta",
            "lastName": "Mazzocchi",
            "fullName": "Margitta Mazzocchi",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/mazzocchi_margitta.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-WV-31-lower-ocd-person-92599af8-32f7-4aa4-bab2-8c7b461f54a5",
              "builtID": "wv-lower-31",
              "externalID": "ocd-person/92599af8-32f7-4aa4-bab2-8c7b461f54a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "margitta.mazzocchi@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Mazzocchi",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Mazzocchi"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-31-lower-ocd-person-92599af8-32f7-4aa4-bab2-8c7b461f54a5": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/9d8c89be-2ece-4dff-8a6d-65dccb8e647e",
            "firstName": "Joshua Allen",
            "lastName": "Holstein",
            "fullName": "Josh Holstein",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/house/holstein_josh.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-WV-32-lower-ocd-person-9d8c89be-2ece-4dff-8a6d-65dccb8e647e",
              "builtID": "wv-lower-32",
              "externalID": "ocd-person/9d8c89be-2ece-4dff-8a6d-65dccb8e647e",
              "geometry": null
            },
            "contactInfo": {
              "email": "josh.holstein@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Holstein",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Holstein"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-32-lower-ocd-person-9d8c89be-2ece-4dff-8a6d-65dccb8e647e": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/be682c29-e66f-4a19-89cc-761c60330eea",
            "firstName": "Jordan Ray",
            "lastName": "Bridges",
            "fullName": "Jordan Bridges",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/bridges_jordan.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-WV-33-lower-ocd-person-be682c29-e66f-4a19-89cc-761c60330eea",
              "builtID": "wv-lower-33",
              "externalID": "ocd-person/be682c29-e66f-4a19-89cc-761c60330eea",
              "geometry": null
            },
            "contactInfo": {
              "email": "jordan.bridges@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Bridges",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Bridges"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-33-lower-ocd-person-be682c29-e66f-4a19-89cc-761c60330eea": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/2461647c-b7dc-4df2-b1b8-6acee9700b7c",
            "firstName": "Mark",
            "lastName": "Dean",
            "fullName": "Mark Dean",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/dean_mark.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-WV-34-lower-ocd-person-2461647c-b7dc-4df2-b1b8-6acee9700b7c",
              "builtID": "wv-lower-34",
              "externalID": "ocd-person/2461647c-b7dc-4df2-b1b8-6acee9700b7c",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.dean@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Dean",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Dean"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-34-lower-ocd-person-2461647c-b7dc-4df2-b1b8-6acee9700b7c": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/c15b4eb1-1dc4-498f-9929-43ff34445317",
            "firstName": "Adam",
            "lastName": "Vance",
            "fullName": "Adam Vance",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/vance_adam.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-WV-35-lower-ocd-person-c15b4eb1-1dc4-498f-9929-43ff34445317",
              "builtID": "wv-lower-35",
              "externalID": "ocd-person/c15b4eb1-1dc4-498f-9929-43ff34445317",
              "geometry": null
            },
            "contactInfo": {
              "email": "adam.vance@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Vance"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-35-lower-ocd-person-c15b4eb1-1dc4-498f-9929-43ff34445317": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/b0526275-df8b-4f42-b16b-064aa721a858",
            "firstName": "Stephen David",
            "lastName": "Green",
            "fullName": "David Green",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/house/green_david.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-WV-36-lower-ocd-person-b0526275-df8b-4f42-b16b-064aa721a858",
              "builtID": "wv-lower-36",
              "externalID": "ocd-person/b0526275-df8b-4f42-b16b-064aa721a858",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.green@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "WV",
              "phone1": null,
              "phone2": "304-938-7065",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Green"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-36-lower-ocd-person-b0526275-df8b-4f42-b16b-064aa721a858": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/c56adc82-a476-4d3c-bd7f-51b2257b0032",
            "firstName": "Gary Martin",
            "lastName": "Gearheart",
            "fullName": "Marty Gearheart",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/gearheart_marty.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-WV-37-lower-ocd-person-c56adc82-a476-4d3c-bd7f-51b2257b0032",
              "builtID": "wv-lower-37",
              "externalID": "ocd-person/c56adc82-a476-4d3c-bd7f-51b2257b0032",
              "geometry": null
            },
            "contactInfo": {
              "email": "marty.gearheart@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Gearheart",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Gearheart",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate+Gearheart"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-37-lower-ocd-person-c56adc82-a476-4d3c-bd7f-51b2257b0032": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/fe6a6b8e-91f2-427f-9060-2cac2b9eb66c",
            "firstName": "Joe Carey",
            "lastName": "Ellington",
            "fullName": "Joe Ellington",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2023/house/ellington_joe.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-WV-38-lower-ocd-person-fe6a6b8e-91f2-427f-9060-2cac2b9eb66c",
              "builtID": "wv-lower-38",
              "externalID": "ocd-person/fe6a6b8e-91f2-427f-9060-2cac2b9eb66c",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.ellington@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Ellington",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Ellington",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate+Ellington"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-38-lower-ocd-person-fe6a6b8e-91f2-427f-9060-2cac2b9eb66c": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/70ac0a53-79d8-426b-950b-83e9be022bf3",
            "firstName": "Douglas D.",
            "lastName": "Smith",
            "fullName": "Doug Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/smith_doug.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-WV-39-lower-ocd-person-70ac0a53-79d8-426b-950b-83e9be022bf3",
              "builtID": "wv-lower-39",
              "externalID": "ocd-person/70ac0a53-79d8-426b-950b-83e9be022bf3",
              "geometry": null
            },
            "contactInfo": {
              "email": "doug.smith@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Smith",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Smith"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-39-lower-ocd-person-70ac0a53-79d8-426b-950b-83e9be022bf3": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/f7a82cd3-d2c6-4f57-b0d0-d38322d1ad54",
            "firstName": "Roy Gale",
            "lastName": "Cooper",
            "fullName": "Roy Cooper",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/house/cooper_roy.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-WV-40-lower-ocd-person-f7a82cd3-d2c6-4f57-b0d0-d38322d1ad54",
              "builtID": "wv-lower-40",
              "externalID": "ocd-person/f7a82cd3-d2c6-4f57-b0d0-d38322d1ad54",
              "geometry": null
            },
            "contactInfo": {
              "email": "roy.cooper@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Cooper",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Cooper",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate+Cooper"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-40-lower-ocd-person-f7a82cd3-d2c6-4f57-b0d0-d38322d1ad54": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/487dd54b-3091-4b0e-b798-0df99523b4c6",
            "firstName": "Jordan A.",
            "lastName": "Maynor",
            "fullName": "Jordan Maynor",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/maynor_jordan.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-WV-41-lower-ocd-person-487dd54b-3091-4b0e-b798-0df99523b4c6",
              "builtID": "wv-lower-41",
              "externalID": "ocd-person/487dd54b-3091-4b0e-b798-0df99523b4c6",
              "geometry": null
            },
            "contactInfo": {
              "email": "jordan.maynor@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Maynor"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-41-lower-ocd-person-487dd54b-3091-4b0e-b798-0df99523b4c6": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/98b93926-7d9c-4553-8278-7e585a1482b9",
            "firstName": "Brandon",
            "lastName": "Steele",
            "fullName": "Brandon Steele",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/steele_brandon.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-WV-42-lower-ocd-person-98b93926-7d9c-4553-8278-7e585a1482b9",
              "builtID": "wv-lower-42",
              "externalID": "ocd-person/98b93926-7d9c-4553-8278-7e585a1482b9",
              "geometry": null
            },
            "contactInfo": {
              "email": "brandon.steele@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Steele",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Steele"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-42-lower-ocd-person-98b93926-7d9c-4553-8278-7e585a1482b9": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/cdd73d73-98d4-43f0-9eec-a030c4ce080f",
            "firstName": "Christopher Wayne",
            "lastName": "Toney",
            "fullName": "Chris Toney",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/toney_christopher.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-WV-43-lower-ocd-person-cdd73d73-98d4-43f0-9eec-a030c4ce080f",
              "builtID": "wv-lower-43",
              "externalID": "ocd-person/cdd73d73-98d4-43f0-9eec-a030c4ce080f",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.toney@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Toney",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Toney"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-43-lower-ocd-person-cdd73d73-98d4-43f0-9eec-a030c4ce080f": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/db5d813b-3cd2-4a6d-b656-b48624f61094",
            "firstName": "Eric",
            "lastName": "Brooks",
            "fullName": "Eric Brooks",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/brooks_eric.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-WV-45-lower-ocd-person-db5d813b-3cd2-4a6d-b656-b48624f61094",
              "builtID": "wv-lower-45",
              "externalID": "ocd-person/db5d813b-3cd2-4a6d-b656-b48624f61094",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.brooks@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Brooks"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-45-lower-ocd-person-db5d813b-3cd2-4a6d-b656-b48624f61094": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/81cad520-b015-42c8-b533-64a9e4596197",
            "firstName": "Jeffrey Scott",
            "lastName": "Campbell",
            "fullName": "Jeff Campbell",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/campbell_jeff.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-WV-46-lower-ocd-person-81cad520-b015-42c8-b533-64a9e4596197",
              "builtID": "wv-lower-46",
              "externalID": "ocd-person/81cad520-b015-42c8-b533-64a9e4596197",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeff.campbell@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Campbell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-46-lower-ocd-person-81cad520-b015-42c8-b533-64a9e4596197": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/cf20b0ed-4156-43a4-80f2-ae97acde2362",
            "firstName": "W. Todd",
            "lastName": "Longanacre",
            "fullName": "Todd Longanacre",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/longanacre_todd.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-WV-47-lower-ocd-person-cf20b0ed-4156-43a4-80f2-ae97acde2362",
              "builtID": "wv-lower-47",
              "externalID": "ocd-person/cf20b0ed-4156-43a4-80f2-ae97acde2362",
              "geometry": null
            },
            "contactInfo": {
              "email": "todd.longanacre@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Longanacre",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Longanacre"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-47-lower-ocd-person-cf20b0ed-4156-43a4-80f2-ae97acde2362": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/e9f7081c-2e1d-4140-922c-abb75aad765e",
            "firstName": "Thomas C.",
            "lastName": "Clark",
            "fullName": "Tom Clark",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/house/clark_thomas.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-WV-48-lower-ocd-person-e9f7081c-2e1d-4140-922c-abb75aad765e",
              "builtID": "wv-lower-48",
              "externalID": "ocd-person/e9f7081c-2e1d-4140-922c-abb75aad765e",
              "geometry": null
            },
            "contactInfo": {
              "email": "thomas.clark@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5 Euclyde Ave., Webster Springs, WV 26288",
              "phone1": null,
              "phone2": "304-651-3888",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Clark,%20T."
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-48-lower-ocd-person-e9f7081c-2e1d-4140-922c-abb75aad765e": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/fb9d86da-ab88-49c4-86dd-aa840a4b3010",
            "firstName": "Heather",
            "lastName": "Glasko-Tully",
            "fullName": "Heather Tully",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/tully_heather.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-WV-49-lower-ocd-person-fb9d86da-ab88-49c4-86dd-aa840a4b3010",
              "builtID": "wv-lower-49",
              "externalID": "ocd-person/fb9d86da-ab88-49c4-86dd-aa840a4b3010",
              "geometry": null
            },
            "contactInfo": {
              "email": "heather.tully@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Tully",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Tully"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-49-lower-ocd-person-fb9d86da-ab88-49c4-86dd-aa840a4b3010": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/d7f119af-3e41-40a1-9541-bfa1a1e7a9b4",
            "firstName": "David Elliott",
            "lastName": "Pritt",
            "fullName": "Elliott Pritt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/house/pritt_david.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-WV-50-lower-ocd-person-d7f119af-3e41-40a1-9541-bfa1a1e7a9b4",
              "builtID": "wv-lower-50",
              "externalID": "ocd-person/d7f119af-3e41-40a1-9541-bfa1a1e7a9b4",
              "geometry": null
            },
            "contactInfo": {
              "email": "elliott.pritt@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Pritt,%20E."
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-50-lower-ocd-person-d7f119af-3e41-40a1-9541-bfa1a1e7a9b4": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/73210692-d1b7-4e60-8a25-0eb321cd8792",
            "firstName": "Tom",
            "lastName": "Fast",
            "fullName": "Tom Fast",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/fast_tom.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-WV-51-lower-ocd-person-73210692-d1b7-4e60-8a25-0eb321cd8792",
              "builtID": "wv-lower-51",
              "externalID": "ocd-person/73210692-d1b7-4e60-8a25-0eb321cd8792",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.fast@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Fast",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Fast",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate+Fast"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-51-lower-ocd-person-73210692-d1b7-4e60-8a25-0eb321cd8792": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/3e02fba2-4d07-4ec5-a41a-6ad05759db97",
            "firstName": "Larry Linwell",
            "lastName": "Rowe",
            "fullName": "Larry Rowe",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/rowe_larry.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-WV-52-lower-ocd-person-3e02fba2-4d07-4ec5-a41a-6ad05759db97",
              "builtID": "wv-lower-52",
              "externalID": "ocd-person/3e02fba2-4d07-4ec5-a41a-6ad05759db97",
              "geometry": null
            },
            "contactInfo": {
              "email": "larry.rowe@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Rowe",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Rowe",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate+Rowe"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-52-lower-ocd-person-3e02fba2-4d07-4ec5-a41a-6ad05759db97": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/0d824d1b-fea4-42aa-ab59-9a4dc542dbd2",
            "firstName": "Christopher Todd",
            "lastName": "Pritt",
            "fullName": "Chris Pritt",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/pritt_chris.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-WV-53-lower-ocd-person-0d824d1b-fea4-42aa-ab59-9a4dc542dbd2",
              "builtID": "wv-lower-53",
              "externalID": "ocd-person/0d824d1b-fea4-42aa-ab59-9a4dc542dbd2",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.pritt@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Pritt",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Pritt",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Pritt,%20C."
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-53-lower-ocd-person-0d824d1b-fea4-42aa-ab59-9a4dc542dbd2": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/1a97b137-218f-43cc-a24f-d806c6ac9fd2",
            "firstName": "Michael Ari",
            "lastName": "Pushkin",
            "fullName": "Mike Pushkin",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/pushkin_mike.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-WV-54-lower-ocd-person-1a97b137-218f-43cc-a24f-d806c6ac9fd2",
              "builtID": "wv-lower-54",
              "externalID": "ocd-person/1a97b137-218f-43cc-a24f-d806c6ac9fd2",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.pushkin@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Pushkin",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Pushkin",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate+Pushkin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-54-lower-ocd-person-1a97b137-218f-43cc-a24f-d806c6ac9fd2": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/33e56ab1-3106-45fa-a992-fab181983a99",
            "firstName": "James Robert",
            "lastName": "Akers",
            "fullName": "J.B. Akers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/house/akers_james.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-WV-55-lower-ocd-person-33e56ab1-3106-45fa-a992-fab181983a99",
              "builtID": "wv-lower-55",
              "externalID": "ocd-person/33e56ab1-3106-45fa-a992-fab181983a99",
              "geometry": null
            },
            "contactInfo": {
              "email": "jb.akers@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "815 Cedar Road, Charleston, WV 25314",
              "phone1": null,
              "phone2": "304-458-3515",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/house/lawmaker.cfm?member=Delegate%20Akers"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-55-lower-ocd-person-33e56ab1-3106-45fa-a992-fab181983a99": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/3317959e-9fbd-40dc-aa7a-0f0a9cc06c3f",
            "firstName": "Kayla",
            "lastName": "Young",
            "fullName": "Kayla Young",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/young_kayla.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-WV-56-lower-ocd-person-3317959e-9fbd-40dc-aa7a-0f0a9cc06c3f",
              "builtID": "wv-lower-56",
              "externalID": "ocd-person/3317959e-9fbd-40dc-aa7a-0f0a9cc06c3f",
              "geometry": null
            },
            "contactInfo": {
              "email": "kayla.young@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "WV",
              "phone1": null,
              "phone2": "304-561-4234",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Young",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Young"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-56-lower-ocd-person-3317959e-9fbd-40dc-aa7a-0f0a9cc06c3f": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/68163122-16de-4528-8eda-fd1f9783c45d",
            "firstName": "Hollis T.",
            "lastName": "Lewis",
            "fullName": "Hollis Lewis",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://www.wvlegislature.gov/images/members/2023/house/lewis_hollis.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-WV-57-lower-ocd-person-68163122-16de-4528-8eda-fd1f9783c45d",
              "builtID": "wv-lower-57",
              "externalID": "ocd-person/68163122-16de-4528-8eda-fd1f9783c45d",
              "geometry": null
            },
            "contactInfo": {
              "email": "hollis.lewis@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Lewis"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-57-lower-ocd-person-68163122-16de-4528-8eda-fd1f9783c45d": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/542b49b1-72ed-4e96-98bf-e7c236b92e12",
            "firstName": "Walter",
            "lastName": "Hall",
            "fullName": "Walter Hall",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/hall_walter.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-WV-58-lower-ocd-person-542b49b1-72ed-4e96-98bf-e7c236b92e12",
              "builtID": "wv-lower-58",
              "externalID": "ocd-person/542b49b1-72ed-4e96-98bf-e7c236b92e12",
              "geometry": null
            },
            "contactInfo": {
              "email": "walter.hall@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Hall,%20W."
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-58-lower-ocd-person-542b49b1-72ed-4e96-98bf-e7c236b92e12": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/e6deff1e-fb70-4bf0-a2b6-9c30567fcd9d",
            "firstName": "Andy",
            "lastName": "Shamblin",
            "fullName": "Andy Shamblin",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/shamblin_andy.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-WV-59-lower-ocd-person-e6deff1e-fb70-4bf0-a2b6-9c30567fcd9d",
              "builtID": "wv-lower-59",
              "externalID": "ocd-person/e6deff1e-fb70-4bf0-a2b6-9c30567fcd9d",
              "geometry": null
            },
            "contactInfo": {
              "email": "andy.shamblin@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Shamblin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-59-lower-ocd-person-e6deff1e-fb70-4bf0-a2b6-9c30567fcd9d": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/6c7fa52c-c313-4f10-aab9-ba9cc0269743",
            "firstName": "Dana J.",
            "lastName": "Ferrell",
            "fullName": "Dana Ferrell",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/ferrell_dana.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-WV-60-lower-ocd-person-6c7fa52c-c313-4f10-aab9-ba9cc0269743",
              "builtID": "wv-lower-60",
              "externalID": "ocd-person/6c7fa52c-c313-4f10-aab9-ba9cc0269743",
              "geometry": null
            },
            "contactInfo": {
              "email": "dana.ferrell@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Ferrell",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Ferrell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-60-lower-ocd-person-6c7fa52c-c313-4f10-aab9-ba9cc0269743": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/94a511d8-31ee-4f7d-9227-005b143f9c47",
            "firstName": "Warren",
            "lastName": "Jeffries",
            "fullName": "Dean Jeffries",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2022/house/jeffries_dean.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-WV-61-lower-ocd-person-94a511d8-31ee-4f7d-9227-005b143f9c47",
              "builtID": "wv-lower-61",
              "externalID": "ocd-person/94a511d8-31ee-4f7d-9227-005b143f9c47",
              "geometry": null
            },
            "contactInfo": {
              "email": "dean.jeffries@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Jeffries",
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Jeffries%2C+D.",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Jeffries,%20D.",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Jeffries"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-61-lower-ocd-person-94a511d8-31ee-4f7d-9227-005b143f9c47": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/a5fb80fa-41db-4bb7-8d0c-9f643b029b86",
            "firstName": "Roger",
            "lastName": "Hanshaw",
            "fullName": "Roger Hanshaw",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/hanshaw_roger.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-WV-62-lower-ocd-person-a5fb80fa-41db-4bb7-8d0c-9f643b029b86",
              "builtID": "wv-lower-62",
              "externalID": "ocd-person/a5fb80fa-41db-4bb7-8d0c-9f643b029b86",
              "geometry": null
            },
            "contactInfo": {
              "email": "roger.hanshaw@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": "5035 Wallback Road, Wallback, WV 25285",
              "phone1": null,
              "phone2": "304-587-9947",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Speaker+Hanshaw",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Speaker%20Hanshaw"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-62-lower-ocd-person-a5fb80fa-41db-4bb7-8d0c-9f643b029b86": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/3f45442d-ffd9-4566-a661-42efa25f8e18",
            "firstName": "Lori Cowger",
            "lastName": "Dittman",
            "fullName": "Lori Dittman",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/dittman_lori.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-WV-63-lower-ocd-person-3f45442d-ffd9-4566-a661-42efa25f8e18",
              "builtID": "wv-lower-63",
              "externalID": "ocd-person/3f45442d-ffd9-4566-a661-42efa25f8e18",
              "geometry": null
            },
            "contactInfo": {
              "email": "lori.dittman@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Dittman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-63-lower-ocd-person-3f45442d-ffd9-4566-a661-42efa25f8e18": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/76544899-58ec-4065-a3e8-4a4d8a12b4eb",
            "firstName": "Adam",
            "lastName": "Burkhammer",
            "fullName": "Adam Burkhammer",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/burkhammer_adam.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-WV-64-lower-ocd-person-76544899-58ec-4065-a3e8-4a4d8a12b4eb",
              "builtID": "wv-lower-64",
              "externalID": "ocd-person/76544899-58ec-4065-a3e8-4a4d8a12b4eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "adam.burkhammer@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Burkhammer",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Burkhammer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-64-lower-ocd-person-76544899-58ec-4065-a3e8-4a4d8a12b4eb": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/aa01a651-9834-4b98-b9c3-25aee388087a",
            "firstName": "Carl Robert",
            "lastName": "Martin",
            "fullName": "Robbie Martin",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2019/house/martin_carl.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-WV-65-lower-ocd-person-aa01a651-9834-4b98-b9c3-25aee388087a",
              "builtID": "wv-lower-65",
              "externalID": "ocd-person/aa01a651-9834-4b98-b9c3-25aee388087a",
              "geometry": null
            },
            "contactInfo": {
              "email": "carl.martin@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Martin%2C+C.",
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Martin",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Martin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-65-lower-ocd-person-aa01a651-9834-4b98-b9c3-25aee388087a": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/f99b624e-62db-48df-be01-5a1d555d69c0",
            "firstName": "William Tyler",
            "lastName": "Nestor",
            "fullName": "Ty Nestor",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/nestor_ty.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-WV-66-lower-ocd-person-f99b624e-62db-48df-be01-5a1d555d69c0",
              "builtID": "wv-lower-66",
              "externalID": "ocd-person/f99b624e-62db-48df-be01-5a1d555d69c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "ty.nestor@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Nestor",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Nestor"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-66-lower-ocd-person-f99b624e-62db-48df-be01-5a1d555d69c0": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/0714e7e4-9da4-46bf-a8f5-e48be1af2242",
            "firstName": "Elias Gorden",
            "lastName": "Coop-Gonzalez",
            "fullName": "Elías Coop-González",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/coopgonzalez_elias.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-WV-67-lower-ocd-person-0714e7e4-9da4-46bf-a8f5-e48be1af2242",
              "builtID": "wv-lower-67",
              "externalID": "ocd-person/0714e7e4-9da4-46bf-a8f5-e48be1af2242",
              "geometry": null
            },
            "contactInfo": {
              "email": "elias.coop-gonzalez@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Coop-Gonzalez"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-67-lower-ocd-person-0714e7e4-9da4-46bf-a8f5-e48be1af2242": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/eb6d633a-5526-413b-872d-bd36f4d9a906",
            "firstName": "Chris",
            "lastName": "Phillips",
            "fullName": "Chris Phillips",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2019/house/phillips_chris.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-WV-68-lower-ocd-person-eb6d633a-5526-413b-872d-bd36f4d9a906",
              "builtID": "wv-lower-68",
              "externalID": "ocd-person/eb6d633a-5526-413b-872d-bd36f4d9a906",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.phillips@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Phillips",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Phillips"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-68-lower-ocd-person-eb6d633a-5526-413b-872d-bd36f4d9a906": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/13a851e4-7ecb-4609-bd19-3bd4ff7fbc1c",
            "firstName": "Keith",
            "lastName": "Marple",
            "fullName": "Keith Marple",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/marple_keith.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-WV-69-lower-ocd-person-13a851e4-7ecb-4609-bd19-3bd4ff7fbc1c",
              "builtID": "wv-lower-69",
              "externalID": "ocd-person/13a851e4-7ecb-4609-bd19-3bd4ff7fbc1c",
              "geometry": null
            },
            "contactInfo": {
              "email": "keith.marple@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Marple"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-69-lower-ocd-person-13a851e4-7ecb-4609-bd19-3bd4ff7fbc1c": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/354b56ae-dee4-4536-879e-cfb0d80b4069",
            "firstName": "Mickey",
            "lastName": "Petitto",
            "fullName": "Mickey Petitto",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/petitto_mickey.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-WV-70-lower-ocd-person-354b56ae-dee4-4536-879e-cfb0d80b4069",
              "builtID": "wv-lower-70",
              "externalID": "ocd-person/354b56ae-dee4-4536-879e-cfb0d80b4069",
              "geometry": null
            },
            "contactInfo": {
              "email": "mickey.petitto@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Petitto"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-70-lower-ocd-person-354b56ae-dee4-4536-879e-cfb0d80b4069": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/ce1e7053-19d4-4405-8e31-f5c39af87ae9",
            "firstName": "Laura",
            "lastName": "Kimble",
            "fullName": "Laura Kimble",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/house/kimble_laura.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-WV-71-lower-ocd-person-ce1e7053-19d4-4405-8e31-f5c39af87ae9",
              "builtID": "wv-lower-71",
              "externalID": "ocd-person/ce1e7053-19d4-4405-8e31-f5c39af87ae9",
              "geometry": null
            },
            "contactInfo": {
              "email": "laura.kimble@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Kimble",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Kimble"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-71-lower-ocd-person-ce1e7053-19d4-4405-8e31-f5c39af87ae9": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/cf728c9e-40e8-4f73-a5fa-0d46844aa38e",
            "firstName": "Clay",
            "lastName": "Riley",
            "fullName": "Clay Riley",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/riley_clay.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-WV-72-lower-ocd-person-cf728c9e-40e8-4f73-a5fa-0d46844aa38e",
              "builtID": "wv-lower-72",
              "externalID": "ocd-person/cf728c9e-40e8-4f73-a5fa-0d46844aa38e",
              "geometry": null
            },
            "contactInfo": {
              "email": "clay.riley@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Riley",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Riley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-72-lower-ocd-person-cf728c9e-40e8-4f73-a5fa-0d46844aa38e": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/7a87143b-8365-4687-b116-aa6dc4ff096e",
            "firstName": "Amy Elder",
            "lastName": "Summers",
            "fullName": "Amy Summers",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2022/house/summers_amy.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-WV-73-lower-ocd-person-7a87143b-8365-4687-b116-aa6dc4ff096e",
              "builtID": "wv-lower-73",
              "externalID": "ocd-person/7a87143b-8365-4687-b116-aa6dc4ff096e",
              "geometry": null
            },
            "contactInfo": {
              "email": "amy.summers@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Summers",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Summers",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate+Summers"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-73-lower-ocd-person-7a87143b-8365-4687-b116-aa6dc4ff096e": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/f1ac96d8-f6f8-4e3d-af5d-05544e874f72",
            "firstName": "Mike",
            "lastName": "DeVault",
            "fullName": "Mike DeVault",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/devault_mike.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-WV-74-lower-ocd-person-f1ac96d8-f6f8-4e3d-af5d-05544e874f72",
              "builtID": "wv-lower-74",
              "externalID": "ocd-person/f1ac96d8-f6f8-4e3d-af5d-05544e874f72",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.devault@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20DeVault"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-74-lower-ocd-person-f1ac96d8-f6f8-4e3d-af5d-05544e874f72": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/ca7ec436-00a0-447d-8592-00fc7d358571",
            "firstName": "Philip Wayne",
            "lastName": "Mallow",
            "fullName": "Phil Mallow",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/mallow_phil.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-WV-75-lower-ocd-person-ca7ec436-00a0-447d-8592-00fc7d358571",
              "builtID": "wv-lower-75",
              "externalID": "ocd-person/ca7ec436-00a0-447d-8592-00fc7d358571",
              "geometry": null
            },
            "contactInfo": {
              "email": "phil.mallow@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Mallow",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Mallow"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-75-lower-ocd-person-ca7ec436-00a0-447d-8592-00fc7d358571": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/387d11ce-ecf1-4c80-a338-bd8c86f52004",
            "firstName": "Joseph David",
            "lastName": "Garcia",
            "fullName": "Joey Garcia",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/garcia_joey.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-WV-76-lower-ocd-person-387d11ce-ecf1-4c80-a338-bd8c86f52004",
              "builtID": "wv-lower-76",
              "externalID": "ocd-person/387d11ce-ecf1-4c80-a338-bd8c86f52004",
              "geometry": null
            },
            "contactInfo": {
              "email": "joey.garcia@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Garcia",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Garcia"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-76-lower-ocd-person-387d11ce-ecf1-4c80-a338-bd8c86f52004": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/79271821-3d79-4f99-bba3-a147779bb3d0",
            "firstName": "Joe",
            "lastName": "Statler",
            "fullName": "Joe Statler",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/statler_joe.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-WV-77-lower-ocd-person-79271821-3d79-4f99-bba3-a147779bb3d0",
              "builtID": "wv-lower-77",
              "externalID": "ocd-person/79271821-3d79-4f99-bba3-a147779bb3d0",
              "geometry": null
            },
            "contactInfo": {
              "email": "joe.statler@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Statler",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Statler"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-77-lower-ocd-person-79271821-3d79-4f99-bba3-a147779bb3d0": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/409397ae-ceea-4b1b-bce5-b042af057232",
            "firstName": "Eugene Michael",
            "lastName": "Chiarelli",
            "fullName": "Geno Chiarelli",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/chiarelli_geno.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-WV-78-lower-ocd-person-409397ae-ceea-4b1b-bce5-b042af057232",
              "builtID": "wv-lower-78",
              "externalID": "ocd-person/409397ae-ceea-4b1b-bce5-b042af057232",
              "geometry": null
            },
            "contactInfo": {
              "email": "geno.chiarelli@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Chiarelli"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-78-lower-ocd-person-409397ae-ceea-4b1b-bce5-b042af057232": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/10dbdccd-3754-49b8-ae28-8f75c4d6936f",
            "firstName": "Evan",
            "lastName": "Hansen",
            "fullName": "Evan Hansen",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.wvlegislature.gov/images/members/2019/house/hansen_evan.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-WV-79-lower-ocd-person-10dbdccd-3754-49b8-ae28-8f75c4d6936f",
              "builtID": "wv-lower-79",
              "externalID": "ocd-person/10dbdccd-3754-49b8-ae28-8f75c4d6936f",
              "geometry": null
            },
            "contactInfo": {
              "email": "evan.hansen@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Hansen",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Hansen"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-79-lower-ocd-person-10dbdccd-3754-49b8-ae28-8f75c4d6936f": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/2c8a36d4-5afa-4b68-a6a0-1a9b170401b0",
            "firstName": "John",
            "lastName": "Williams",
            "fullName": "John Williams",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/williams_john.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-WV-80-lower-ocd-person-2c8a36d4-5afa-4b68-a6a0-1a9b170401b0",
              "builtID": "wv-lower-80",
              "externalID": "ocd-person/2c8a36d4-5afa-4b68-a6a0-1a9b170401b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.williams@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Williams",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Williams"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-80-lower-ocd-person-2c8a36d4-5afa-4b68-a6a0-1a9b170401b0": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/86a1fdf7-e6af-41b4-8d69-0d0815fc9512",
            "firstName": "Anitra L.",
            "lastName": "Hamilton",
            "fullName": "Anitra Hamilton",
            "gender": "Female",
            "party": "Democrat",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/hamilton_anitra.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-WV-81-lower-ocd-person-86a1fdf7-e6af-41b4-8d69-0d0815fc9512",
              "builtID": "wv-lower-81",
              "externalID": "ocd-person/86a1fdf7-e6af-41b4-8d69-0d0815fc9512",
              "geometry": null
            },
            "contactInfo": {
              "email": "anitra.hamilton@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Hamilton"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-81-lower-ocd-person-86a1fdf7-e6af-41b4-8d69-0d0815fc9512": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/d61ad539-8647-4e68-a2a9-2955db1cd510",
            "firstName": "Debbie Law",
            "lastName": "Warner",
            "fullName": "Debbie Warner",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/warner_debbie.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-WV-82-lower-ocd-person-d61ad539-8647-4e68-a2a9-2955db1cd510",
              "builtID": "wv-lower-82",
              "externalID": "ocd-person/d61ad539-8647-4e68-a2a9-2955db1cd510",
              "geometry": null
            },
            "contactInfo": {
              "email": "debbie.warner@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Warner"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-82-lower-ocd-person-d61ad539-8647-4e68-a2a9-2955db1cd510": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/2ee0335d-f067-4ca3-99ce-e10944fa8875",
            "firstName": "George",
            "lastName": "Street",
            "fullName": "George Street",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/street_george.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-WV-83-lower-ocd-person-2ee0335d-f067-4ca3-99ce-e10944fa8875",
              "builtID": "wv-lower-83",
              "externalID": "ocd-person/2ee0335d-f067-4ca3-99ce-e10944fa8875",
              "geometry": null
            },
            "contactInfo": {
              "email": "george.street@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Street"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-83-lower-ocd-person-2ee0335d-f067-4ca3-99ce-e10944fa8875": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/34e1e6dd-469b-4e3d-9cc5-604bffe79730",
            "firstName": "D. Rolland",
            "lastName": "Jennings",
            "fullName": "Buck Jennings",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/jennings_d.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-WV-84-lower-ocd-person-34e1e6dd-469b-4e3d-9cc5-604bffe79730",
              "builtID": "wv-lower-84",
              "externalID": "ocd-person/34e1e6dd-469b-4e3d-9cc5-604bffe79730",
              "geometry": null
            },
            "contactInfo": {
              "email": "buck.jennings@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Jennings",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Jennings"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-84-lower-ocd-person-34e1e6dd-469b-4e3d-9cc5-604bffe79730": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/06c041be-0a45-4bfe-8e2d-49055af1205f",
            "firstName": "John Paul",
            "lastName": "Hott",
            "fullName": "John Paul Hott",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/hott_john.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-WV-85-lower-ocd-person-06c041be-0a45-4bfe-8e2d-49055af1205f",
              "builtID": "wv-lower-85",
              "externalID": "ocd-person/06c041be-0a45-4bfe-8e2d-49055af1205f",
              "geometry": null
            },
            "contactInfo": {
              "email": "johnpaul.hott@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Hott",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Hott"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-85-lower-ocd-person-06c041be-0a45-4bfe-8e2d-49055af1205f": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/6e588e9a-4d48-444d-92b4-629e00900440",
            "firstName": "Bryan C.",
            "lastName": "Ward",
            "fullName": "Bryan Ward",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/ward_bryan.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-WV-86-lower-ocd-person-6e588e9a-4d48-444d-92b4-629e00900440",
              "builtID": "wv-lower-86",
              "externalID": "ocd-person/6e588e9a-4d48-444d-92b4-629e00900440",
              "geometry": null
            },
            "contactInfo": {
              "email": "bryan.ward@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Ward%2C+B.",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Ward,%20B.",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Ward"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-86-lower-ocd-person-6e588e9a-4d48-444d-92b4-629e00900440": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/405fa851-873f-4895-acd5-d394fbb06be3",
            "firstName": "Gary G.",
            "lastName": "Howell",
            "fullName": "Gary Howell",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/howell_gary.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-WV-87-lower-ocd-person-405fa851-873f-4895-acd5-d394fbb06be3",
              "builtID": "wv-lower-87",
              "externalID": "ocd-person/405fa851-873f-4895-acd5-d394fbb06be3",
              "geometry": null
            },
            "contactInfo": {
              "email": "gary.howell@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Howell",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Howell",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate+Howell"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-87-lower-ocd-person-405fa851-873f-4895-acd5-d394fbb06be3": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/5e9dad08-2267-4354-b695-814e1014a0ec",
            "firstName": "Frederick",
            "lastName": "Hillenbrand",
            "fullName": "Rick Hillenbrand",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/house/hillenbrand_rick.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-WV-88-lower-ocd-person-5e9dad08-2267-4354-b695-814e1014a0ec",
              "builtID": "wv-lower-88",
              "externalID": "ocd-person/5e9dad08-2267-4354-b695-814e1014a0ec",
              "geometry": null
            },
            "contactInfo": {
              "email": "rick.hillenbrand@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Hillenbrand"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-88-lower-ocd-person-5e9dad08-2267-4354-b695-814e1014a0ec": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/60b7f294-2c42-40ab-ab7d-2a639e5be5e5",
            "firstName": "Darren J.",
            "lastName": "Thorne",
            "fullName": "Darren Thorne",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/thorne_darren.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-WV-89-lower-ocd-person-60b7f294-2c42-40ab-ab7d-2a639e5be5e5",
              "builtID": "wv-lower-89",
              "externalID": "ocd-person/60b7f294-2c42-40ab-ab7d-2a639e5be5e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "darren.thorne@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Thorne"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-89-lower-ocd-person-60b7f294-2c42-40ab-ab7d-2a639e5be5e5": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/5bb7ff93-88f4-42ec-ba11-6921dead66a1",
            "firstName": "George A.",
            "lastName": "Miller",
            "fullName": "George Miller",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/miller_george.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-WV-90-lower-ocd-person-5bb7ff93-88f4-42ec-ba11-6921dead66a1",
              "builtID": "wv-lower-90",
              "externalID": "ocd-person/5bb7ff93-88f4-42ec-ba11-6921dead66a1",
              "geometry": null
            },
            "contactInfo": {
              "email": "george.miller@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Miller",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Miller"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-90-lower-ocd-person-5bb7ff93-88f4-42ec-ba11-6921dead66a1": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/97371095-32ea-4675-acf1-8cbaf49e9fc7",
            "firstName": "Donald",
            "lastName": "Forsht",
            "fullName": "Don Forsht",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/forsht_don.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-WV-91-lower-ocd-person-97371095-32ea-4675-acf1-8cbaf49e9fc7",
              "builtID": "wv-lower-91",
              "externalID": "ocd-person/97371095-32ea-4675-acf1-8cbaf49e9fc7",
              "geometry": null
            },
            "contactInfo": {
              "email": "don.forsht@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Forsht",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Forsht"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-91-lower-ocd-person-97371095-32ea-4675-acf1-8cbaf49e9fc7": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/0ef791a3-2e24-4751-8686-21ffac958ce0",
            "firstName": "Michael",
            "lastName": "Hite",
            "fullName": "Mike Hite",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/hite_michael.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-WV-92-lower-ocd-person-0ef791a3-2e24-4751-8686-21ffac958ce0",
              "builtID": "wv-lower-92",
              "externalID": "ocd-person/0ef791a3-2e24-4751-8686-21ffac958ce0",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.hite@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Hite"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-92-lower-ocd-person-0ef791a3-2e24-4751-8686-21ffac958ce0": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/b76899a3-5189-495c-af2f-9ca8a4e8036e",
            "firstName": "Michael",
            "lastName": "Hornby",
            "fullName": "Mike Hornby",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/hornby_mike.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-WV-93-lower-ocd-person-b76899a3-5189-495c-af2f-9ca8a4e8036e",
              "builtID": "wv-lower-93",
              "externalID": "ocd-person/b76899a3-5189-495c-af2f-9ca8a4e8036e",
              "geometry": null
            },
            "contactInfo": {
              "email": "michael.hornby@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Hornby"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-93-lower-ocd-person-b76899a3-5189-495c-af2f-9ca8a4e8036e": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/17ae499e-4b87-4631-851d-5e55185da510",
            "firstName": "Larry Douglas",
            "lastName": "Kump",
            "fullName": "Larry Kump",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2019/house/kump_larry.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-WV-94-lower-ocd-person-17ae499e-4b87-4631-851d-5e55185da510",
              "builtID": "wv-lower-94",
              "externalID": "ocd-person/17ae499e-4b87-4631-851d-5e55185da510",
              "geometry": null
            },
            "contactInfo": {
              "email": "larry.kump@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Kump",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Kump"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-94-lower-ocd-person-17ae499e-4b87-4631-851d-5e55185da510": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/1f91d255-ce76-40b4-86fd-f9412407f869",
            "firstName": "Charles K.",
            "lastName": "Horst",
            "fullName": "Chuck Horst",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/horst_chuck.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-WV-95-lower-ocd-person-1f91d255-ce76-40b4-86fd-f9412407f869",
              "builtID": "wv-lower-95",
              "externalID": "ocd-person/1f91d255-ce76-40b4-86fd-f9412407f869",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.horst@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Horst",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Horst"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-95-lower-ocd-person-1f91d255-ce76-40b4-86fd-f9412407f869": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/c71cb4ca-cc90-4ba3-8ae8-1a41199ee29f",
            "firstName": "Eric L.",
            "lastName": "Householder",
            "fullName": "Eric Householder",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2017/house/householder_eric.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-WV-96-lower-ocd-person-c71cb4ca-cc90-4ba3-8ae8-1a41199ee29f",
              "builtID": "wv-lower-96",
              "externalID": "ocd-person/c71cb4ca-cc90-4ba3-8ae8-1a41199ee29f",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.householder@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Householder",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Householder",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate+Householder"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-96-lower-ocd-person-c71cb4ca-cc90-4ba3-8ae8-1a41199ee29f": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/98c9a037-948e-4039-bd4c-8881808dec34",
            "firstName": "John",
            "lastName": "Hardy",
            "fullName": "John Hardy",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2019/house/hardy_john.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-WV-97-lower-ocd-person-98c9a037-948e-4039-bd4c-8881808dec34",
              "builtID": "wv-lower-97",
              "externalID": "ocd-person/98c9a037-948e-4039-bd4c-8881808dec34",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.hardy@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Hardy",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Hardy"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-97-lower-ocd-person-98c9a037-948e-4039-bd4c-8881808dec34": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/93c6a37e-8b80-4a72-9f07-2b22579af272",
            "firstName": "Paul Allen",
            "lastName": "Espinosa",
            "fullName": "Paul Espinosa",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/espinosa_paul.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-WV-98-lower-ocd-person-93c6a37e-8b80-4a72-9f07-2b22579af272",
              "builtID": "wv-lower-98",
              "externalID": "ocd-person/93c6a37e-8b80-4a72-9f07-2b22579af272",
              "geometry": null
            },
            "contactInfo": {
              "email": "paul.espinosa@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Espinosa",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Espinosa",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate+Espinosa"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-98-lower-ocd-person-93c6a37e-8b80-4a72-9f07-2b22579af272": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/de66a26a-0d5d-44d4-a687-58cffa1a2512",
            "firstName": "Wayne",
            "lastName": "Clark",
            "fullName": "Wayne Clark",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/house/clark_wayne.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-WV-99-lower-ocd-person-de66a26a-0d5d-44d4-a687-58cffa1a2512",
              "builtID": "wv-lower-99",
              "externalID": "ocd-person/de66a26a-0d5d-44d4-a687-58cffa1a2512",
              "geometry": null
            },
            "contactInfo": {
              "email": "wayne.clark@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Clark",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Clark"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-99-lower-ocd-person-de66a26a-0d5d-44d4-a687-58cffa1a2512": 0
        }
      },
      "100": {
        "members": [
          {
            "API_ID": "ocd-person/ad2e5607-a6ef-4458-88fc-bea91da19ac6",
            "firstName": "William",
            "lastName": "Ridenour",
            "fullName": "Bill Ridenour",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/house/ridenour_bill.jpg",
            "title": "WV Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "100",
              "chamber": "lower",
              "hashID": "SLDL-WV-100-lower-ocd-person-ad2e5607-a6ef-4458-88fc-bea91da19ac6",
              "builtID": "wv-lower-100",
              "externalID": "ocd-person/ad2e5607-a6ef-4458-88fc-bea91da19ac6",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.ridenour@wvhouse.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Ridenour"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WV-100-lower-ocd-person-ad2e5607-a6ef-4458-88fc-bea91da19ac6": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/599fd572-0475-4215-8d43-1474237f5a78",
            "firstName": "Laura Wakim",
            "lastName": "Chapman",
            "fullName": "Laura Chapman",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/senate/chapman_laura.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-WV-1-upper-ocd-person-599fd572-0475-4215-8d43-1474237f5a78",
              "builtID": "wv-upper-1",
              "externalID": "ocd-person/599fd572-0475-4215-8d43-1474237f5a78",
              "geometry": null
            },
            "contactInfo": {
              "email": "laura.chapman@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Chapman"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/680aa2ce-6408-4047-a36b-0b3badc82851",
            "firstName": "Ryan William",
            "lastName": "Weld",
            "fullName": "Ryan Weld",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/senate/weld_ryan.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-WV-1-upper-ocd-person-680aa2ce-6408-4047-a36b-0b3badc82851",
              "builtID": "wv-upper-1",
              "externalID": "ocd-person/680aa2ce-6408-4047-a36b-0b3badc82851",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryan.weld@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Weld",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Weld"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-1-upper-ocd-person-599fd572-0475-4215-8d43-1474237f5a78": 0,
          "SLDU-WV-1-upper-ocd-person-680aa2ce-6408-4047-a36b-0b3badc82851": 1
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/8941d02d-cee0-47a4-9c68-de73382b20af",
            "firstName": "Charles H.",
            "lastName": "Clements",
            "fullName": "Charles Clements",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/senate/clements_charles.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-WV-2-upper-ocd-person-8941d02d-cee0-47a4-9c68-de73382b20af",
              "builtID": "wv-upper-2",
              "externalID": "ocd-person/8941d02d-cee0-47a4-9c68-de73382b20af",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.clements@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Clements",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Clements"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/3d0139c5-77eb-4891-8466-5b35afdf0b60",
            "firstName": "Michael J.",
            "lastName": "Maroney",
            "fullName": "Mike Maroney",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/senate/maroney_michael.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-WV-2-upper-ocd-person-3d0139c5-77eb-4891-8466-5b35afdf0b60",
              "builtID": "wv-upper-2",
              "externalID": "ocd-person/3d0139c5-77eb-4891-8466-5b35afdf0b60",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.maroney@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Maroney",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Maroney"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-2-upper-ocd-person-8941d02d-cee0-47a4-9c68-de73382b20af": 0,
          "SLDU-WV-2-upper-ocd-person-3d0139c5-77eb-4891-8466-5b35afdf0b60": 1
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/3d093b6a-24b4-4a7c-807b-b81def2c38a4",
            "firstName": "Donna Jean",
            "lastName": "Boley",
            "fullName": "Donna Boley",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2020/senate/boley_donna.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-WV-3-upper-ocd-person-3d093b6a-24b4-4a7c-807b-b81def2c38a4",
              "builtID": "wv-upper-3",
              "externalID": "ocd-person/3d093b6a-24b4-4a7c-807b-b81def2c38a4",
              "geometry": null
            },
            "contactInfo": {
              "email": "donna.boley@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Boley",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Boley",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator+Boley"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/8fe81e26-b0ce-4349-a3e3-91d25247b980",
            "firstName": "Michael Thomas",
            "lastName": "Azinger",
            "fullName": "Mike Azinger",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/senate/azinger_michael.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-WV-3-upper-ocd-person-8fe81e26-b0ce-4349-a3e3-91d25247b980",
              "builtID": "wv-upper-3",
              "externalID": "ocd-person/8fe81e26-b0ce-4349-a3e3-91d25247b980",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.azinger@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Azinger",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Azinger"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-3-upper-ocd-person-3d093b6a-24b4-4a7c-807b-b81def2c38a4": 0,
          "SLDU-WV-3-upper-ocd-person-8fe81e26-b0ce-4349-a3e3-91d25247b980": 1
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/57c08164-a673-4269-84d9-6017e740eaac",
            "firstName": "Amy Nichole",
            "lastName": "Grady",
            "fullName": "Amy Grady",
            "gender": "Female",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/senate/grady_amy.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-WV-4-upper-ocd-person-57c08164-a673-4269-84d9-6017e740eaac",
              "builtID": "wv-upper-4",
              "externalID": "ocd-person/57c08164-a673-4269-84d9-6017e740eaac",
              "geometry": null
            },
            "contactInfo": {
              "email": "amy.grady@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Grady",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Grady"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/18d03332-9bd9-4070-9101-6cf93ab2105e",
            "firstName": "Eric J.",
            "lastName": "Tarr",
            "fullName": "Eric Tarr",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/senate/tarr_eric.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-WV-4-upper-ocd-person-18d03332-9bd9-4070-9101-6cf93ab2105e",
              "builtID": "wv-upper-4",
              "externalID": "ocd-person/18d03332-9bd9-4070-9101-6cf93ab2105e",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.tarr@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Tarr",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Tarr"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-4-upper-ocd-person-57c08164-a673-4269-84d9-6017e740eaac": 0,
          "SLDU-WV-4-upper-ocd-person-18d03332-9bd9-4070-9101-6cf93ab2105e": 1
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/500ddd59-2fb2-406a-869c-2b06f6cc2385",
            "firstName": "Michael A.",
            "lastName": "Woelfel",
            "fullName": "Mike Woelfel",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.wvlegislature.gov/images/members/2020/senate/woelfel_michael.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-WV-5-upper-ocd-person-500ddd59-2fb2-406a-869c-2b06f6cc2385",
              "builtID": "wv-upper-5",
              "externalID": "ocd-person/500ddd59-2fb2-406a-869c-2b06f6cc2385",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.woelfel@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Woelfel",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Woelfel",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator+Woelfel"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/d6033218-5c7e-4ee6-b24f-6d70c4c0ed9c",
            "firstName": "Robert Hugh",
            "lastName": "Plymale",
            "fullName": "Bob Plymale",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.wvlegislature.gov/images/members/2020/senate/plymale_robert.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-WV-5-upper-ocd-person-d6033218-5c7e-4ee6-b24f-6d70c4c0ed9c",
              "builtID": "wv-upper-5",
              "externalID": "ocd-person/d6033218-5c7e-4ee6-b24f-6d70c4c0ed9c",
              "geometry": null
            },
            "contactInfo": {
              "email": "robert.plymale@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Plymale",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Plymale",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator+Plymale"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-5-upper-ocd-person-500ddd59-2fb2-406a-869c-2b06f6cc2385": 0,
          "SLDU-WV-5-upper-ocd-person-d6033218-5c7e-4ee6-b24f-6d70c4c0ed9c": 1
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/ee4376b1-df2e-4d56-867b-b8585de9b441",
            "firstName": "Chandler",
            "lastName": "Swope",
            "fullName": "Chandler Swope",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/senate/swope_chandler.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-WV-6-upper-ocd-person-ee4376b1-df2e-4d56-867b-b8585de9b441",
              "builtID": "wv-upper-6",
              "externalID": "ocd-person/ee4376b1-df2e-4d56-867b-b8585de9b441",
              "geometry": null
            },
            "contactInfo": {
              "email": "chandler.swope@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Swope",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Swope"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e98e90d4-7e6d-47e0-b837-6f5f8b492d9c",
            "firstName": "Mark R.",
            "lastName": "Maynard",
            "fullName": "Mark Maynard",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/senate/maynard_mark.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-WV-6-upper-ocd-person-e98e90d4-7e6d-47e0-b837-6f5f8b492d9c",
              "builtID": "wv-upper-6",
              "externalID": "ocd-person/e98e90d4-7e6d-47e0-b837-6f5f8b492d9c",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.maynard@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Maynard",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Maynard",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator+Maynard"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-6-upper-ocd-person-ee4376b1-df2e-4d56-867b-b8585de9b441": 0,
          "SLDU-WV-6-upper-ocd-person-e98e90d4-7e6d-47e0-b837-6f5f8b492d9c": 1
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/9237b2d2-52dd-4e7c-98e0-6c5a346356f6",
            "firstName": "Michael Bryan",
            "lastName": "Stuart",
            "fullName": "Mike Stuart",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/senate/stuart_mike.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-WV-7-upper-ocd-person-9237b2d2-52dd-4e7c-98e0-6c5a346356f6",
              "builtID": "wv-upper-7",
              "externalID": "ocd-person/9237b2d2-52dd-4e7c-98e0-6c5a346356f6",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.stuart@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Stuart"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c1187148-471e-4bdc-acf5-16fa0d5d998e",
            "firstName": "Rupert Wilson",
            "lastName": "Phillips",
            "fullName": "Rupie Phillips",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/senate/phillips_rupie.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-WV-7-upper-ocd-person-c1187148-471e-4bdc-acf5-16fa0d5d998e",
              "builtID": "wv-upper-7",
              "externalID": "ocd-person/c1187148-471e-4bdc-acf5-16fa0d5d998e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rupie.phillips@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Phillips",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Phillips"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-7-upper-ocd-person-9237b2d2-52dd-4e7c-98e0-6c5a346356f6": 0,
          "SLDU-WV-7-upper-ocd-person-c1187148-471e-4bdc-acf5-16fa0d5d998e": 1
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/de2f5e4b-192a-463d-91b0-73f212e8f953",
            "firstName": "Glenn D.",
            "lastName": "Jeffries",
            "fullName": "Glenn Jeffries",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/senate/jeffries_glenn.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-WV-8-upper-ocd-person-de2f5e4b-192a-463d-91b0-73f212e8f953",
              "builtID": "wv-upper-8",
              "externalID": "ocd-person/de2f5e4b-192a-463d-91b0-73f212e8f953",
              "geometry": null
            },
            "contactInfo": {
              "email": "glenn.jeffries@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Jeffries",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Jeffries"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/0f9b6e27-110c-49ed-acb5-6bc10cab45d7",
            "firstName": "Mark Allen",
            "lastName": "Hunt",
            "fullName": "Mark Hunt",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/senate/hunt_mark.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-WV-8-upper-ocd-person-0f9b6e27-110c-49ed-acb5-6bc10cab45d7",
              "builtID": "wv-upper-8",
              "externalID": "ocd-person/0f9b6e27-110c-49ed-acb5-6bc10cab45d7",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.hunt@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Hunt",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Hunt"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-8-upper-ocd-person-de2f5e4b-192a-463d-91b0-73f212e8f953": 0,
          "SLDU-WV-8-upper-ocd-person-0f9b6e27-110c-49ed-acb5-6bc10cab45d7": 1
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/7a16536b-bf43-4b38-a7df-7aee402754a5",
            "firstName": "David Allen",
            "lastName": "Stover",
            "fullName": "Bugs Stover",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/senate/stover_david.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-WV-9-upper-ocd-person-7a16536b-bf43-4b38-a7df-7aee402754a5",
              "builtID": "wv-upper-9",
              "externalID": "ocd-person/7a16536b-bf43-4b38-a7df-7aee402754a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.stover@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Stover",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Stover"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/e0166da1-e7d4-42ed-bf99-fe2a9cd3e82e",
            "firstName": "Rollan A.",
            "lastName": "Roberts",
            "fullName": "Rollan Roberts",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/senate/roberts_rollan.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-WV-9-upper-ocd-person-e0166da1-e7d4-42ed-bf99-fe2a9cd3e82e",
              "builtID": "wv-upper-9",
              "externalID": "ocd-person/e0166da1-e7d4-42ed-bf99-fe2a9cd3e82e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rollan.roberts@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Roberts",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Roberts"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-9-upper-ocd-person-7a16536b-bf43-4b38-a7df-7aee402754a5": 0,
          "SLDU-WV-9-upper-ocd-person-e0166da1-e7d4-42ed-bf99-fe2a9cd3e82e": 1
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/b4df4940-ac0e-4e1a-ba3c-940d604b4ad2",
            "firstName": "Jack David",
            "lastName": "Woodrum",
            "fullName": "Jack Woodrum",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/senate/woodrum_jack.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-WV-10-upper-ocd-person-b4df4940-ac0e-4e1a-ba3c-940d604b4ad2",
              "builtID": "wv-upper-10",
              "externalID": "ocd-person/b4df4940-ac0e-4e1a-ba3c-940d604b4ad2",
              "geometry": null
            },
            "contactInfo": {
              "email": "jack.woodrum@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Woodrum",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Woodrum"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/147a2b54-846b-4fec-b394-be4b50827f5f",
            "firstName": "Vincent S.",
            "lastName": "Deeds",
            "fullName": "Vince Deeds",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/senate/deeds_vince.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-WV-10-upper-ocd-person-147a2b54-846b-4fec-b394-be4b50827f5f",
              "builtID": "wv-upper-10",
              "externalID": "ocd-person/147a2b54-846b-4fec-b394-be4b50827f5f",
              "geometry": null
            },
            "contactInfo": {
              "email": "vince.deeds@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Deeds"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-10-upper-ocd-person-b4df4940-ac0e-4e1a-ba3c-940d604b4ad2": 0,
          "SLDU-WV-10-upper-ocd-person-147a2b54-846b-4fec-b394-be4b50827f5f": 1
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/8bd21671-34c1-4ba3-aced-0dcaa110029c",
            "firstName": "William David",
            "lastName": "Hamilton",
            "fullName": "Bill Hamilton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/senate/hamilton_bill.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-WV-11-upper-ocd-person-8bd21671-34c1-4ba3-aced-0dcaa110029c",
              "builtID": "wv-upper-11",
              "externalID": "ocd-person/8bd21671-34c1-4ba3-aced-0dcaa110029c",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.hamilton@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Hamilton",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Hamilton"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/23dcdb98-284d-458a-8736-8e8902fc7612",
            "firstName": "Robert Lee",
            "lastName": "Karnes",
            "fullName": "Robert Karnes",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/senate/karnes_robert.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-WV-11-upper-ocd-person-23dcdb98-284d-458a-8736-8e8902fc7612",
              "builtID": "wv-upper-11",
              "externalID": "ocd-person/23dcdb98-284d-458a-8736-8e8902fc7612",
              "geometry": null
            },
            "contactInfo": {
              "email": "robert.karnes@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Karnes",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Karnes"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-11-upper-ocd-person-8bd21671-34c1-4ba3-aced-0dcaa110029c": 0,
          "SLDU-WV-11-upper-ocd-person-23dcdb98-284d-458a-8736-8e8902fc7612": 1
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/de50d28d-6a58-4244-8c74-36ca3f41767a",
            "firstName": "Ben",
            "lastName": "Queen",
            "fullName": "Ben Queen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2023/senate/queen_ben.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-WV-12-upper-ocd-person-de50d28d-6a58-4244-8c74-36ca3f41767a",
              "builtID": "wv-upper-12",
              "externalID": "ocd-person/de50d28d-6a58-4244-8c74-36ca3f41767a",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.queen@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Queen",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Queen",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Queen"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/69547a9d-4e96-4ea8-94eb-528e40d5d004",
            "firstName": "Patrick S.",
            "lastName": "Martin",
            "fullName": "Patrick Martin",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/senate/martin_patrick.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-WV-12-upper-ocd-person-69547a9d-4e96-4ea8-94eb-528e40d5d004",
              "builtID": "wv-upper-12",
              "externalID": "ocd-person/69547a9d-4e96-4ea8-94eb-528e40d5d004",
              "geometry": null
            },
            "contactInfo": {
              "email": "patrick.martin@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Martin",
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Martin",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Martin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-12-upper-ocd-person-de50d28d-6a58-4244-8c74-36ca3f41767a": 0,
          "SLDU-WV-12-upper-ocd-person-69547a9d-4e96-4ea8-94eb-528e40d5d004": 1
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/1fb783cd-eb4b-4afd-90f8-8e69f79e3eb5",
            "firstName": "Michael",
            "lastName": "Caputo",
            "fullName": "Mike Caputo",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://www.wvlegislature.gov/images/members/2019/house/caputo_mike.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-WV-13-upper-ocd-person-1fb783cd-eb4b-4afd-90f8-8e69f79e3eb5",
              "builtID": "wv-upper-13",
              "externalID": "ocd-person/1fb783cd-eb4b-4afd-90f8-8e69f79e3eb5",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.caputo@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Caputo",
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Caputo",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Caputo"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c00003a6-3518-496e-b972-fd5aa566d4c4",
            "firstName": "Michael Angelo",
            "lastName": "Oliverio",
            "fullName": "Mike Oliverio",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/senate/oliverio_michael.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-WV-13-upper-ocd-person-c00003a6-3518-496e-b972-fd5aa566d4c4",
              "builtID": "wv-upper-13",
              "externalID": "ocd-person/c00003a6-3518-496e-b972-fd5aa566d4c4",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.oliverio@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Oliverio"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-13-upper-ocd-person-1fb783cd-eb4b-4afd-90f8-8e69f79e3eb5": 0,
          "SLDU-WV-13-upper-ocd-person-c00003a6-3518-496e-b972-fd5aa566d4c4": 1
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/a7ae16a1-8a1e-4280-8d00-9125719a893e",
            "firstName": "John R.",
            "lastName": "Taylor",
            "fullName": "Jay Taylor",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/senate/taylor_jay.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-WV-14-upper-ocd-person-a7ae16a1-8a1e-4280-8d00-9125719a893e",
              "builtID": "wv-upper-14",
              "externalID": "ocd-person/a7ae16a1-8a1e-4280-8d00-9125719a893e",
              "geometry": null
            },
            "contactInfo": {
              "email": "jay.taylor@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Taylor"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/28321ca7-5b8c-4b1a-9a85-2ae30e52045b",
            "firstName": "Randy Edward",
            "lastName": "Smith",
            "fullName": "Randy Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/senate/smith_randy.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-WV-14-upper-ocd-person-28321ca7-5b8c-4b1a-9a85-2ae30e52045b",
              "builtID": "wv-upper-14",
              "externalID": "ocd-person/28321ca7-5b8c-4b1a-9a85-2ae30e52045b",
              "geometry": null
            },
            "contactInfo": {
              "email": "randy.smith@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Smith",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Smith"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-14-upper-ocd-person-a7ae16a1-8a1e-4280-8d00-9125719a893e": 0,
          "SLDU-WV-14-upper-ocd-person-28321ca7-5b8c-4b1a-9a85-2ae30e52045b": 1
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/a16d38e6-6769-4bd3-8f7d-246e75850ccf",
            "firstName": "Charles Samuel",
            "lastName": "Trump",
            "fullName": "Charles Trump",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2023/senate/trump_charles.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-WV-15-upper-ocd-person-a16d38e6-6769-4bd3-8f7d-246e75850ccf",
              "builtID": "wv-upper-15",
              "externalID": "ocd-person/a16d38e6-6769-4bd3-8f7d-246e75850ccf",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.trump@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Trump",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Trump",
                "https://www.wvlegislature.gov/senate1/lawmaker.cfm?member=Senator%20Trump"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c54a9a2b-0e66-41e3-b977-4bc4904e3642",
            "firstName": "Craig Philip",
            "lastName": "Blair",
            "fullName": "Craig Blair",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/senate/blair_craig.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-WV-15-upper-ocd-person-c54a9a2b-0e66-41e3-b977-4bc4904e3642",
              "builtID": "wv-upper-15",
              "externalID": "ocd-person/c54a9a2b-0e66-41e3-b977-4bc4904e3642",
              "geometry": null
            },
            "contactInfo": {
              "email": "craig.blair@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Blair",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Blair",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=President%20Blair"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-15-upper-ocd-person-a16d38e6-6769-4bd3-8f7d-246e75850ccf": 0,
          "SLDU-WV-15-upper-ocd-person-c54a9a2b-0e66-41e3-b977-4bc4904e3642": 1
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/bcbe03de-3df0-4c07-8973-0304dafa2cb8",
            "firstName": "Jason",
            "lastName": "Barrett",
            "fullName": "Jason Barrett",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/senate/barrett_jason.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-WV-16-upper-ocd-person-bcbe03de-3df0-4c07-8973-0304dafa2cb8",
              "builtID": "wv-upper-16",
              "externalID": "ocd-person/bcbe03de-3df0-4c07-8973-0304dafa2cb8",
              "geometry": null
            },
            "contactInfo": {
              "email": "jason.barrett@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Barrett",
                "https://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Barrett",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Barrett"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/61c66718-ebd4-4ded-827d-14b21ed01b0a",
            "firstName": "Patricia Elena Puertas",
            "lastName": "Rucker",
            "fullName": "Patricia Rucker",
            "gender": "Female",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2023/senate/rucker_patricia.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-WV-16-upper-ocd-person-61c66718-ebd4-4ded-827d-14b21ed01b0a",
              "builtID": "wv-upper-16",
              "externalID": "ocd-person/61c66718-ebd4-4ded-827d-14b21ed01b0a",
              "geometry": null
            },
            "contactInfo": {
              "email": "patricia.rucker@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Rucker",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Rucker"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-16-upper-ocd-person-bcbe03de-3df0-4c07-8973-0304dafa2cb8": 0,
          "SLDU-WV-16-upper-ocd-person-61c66718-ebd4-4ded-827d-14b21ed01b0a": 1
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/c440f489-4e7a-4351-a7c7-7137ed79d94e",
            "firstName": "Fredrik Eric",
            "lastName": "Nelson",
            "fullName": "Eric Nelson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://www.wvlegislature.gov/images/members/2024/senate/nelson_eric.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-WV-17-upper-ocd-person-c440f489-4e7a-4351-a7c7-7137ed79d94e",
              "builtID": "wv-upper-17",
              "externalID": "ocd-person/c440f489-4e7a-4351-a7c7-7137ed79d94e",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.nelson@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/House/lawmaker.cfm?member=Delegate+Nelson",
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Nelson",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Nelson"
              ],
              "rss_url": null
            }
          },
          {
            "API_ID": "ocd-person/c6a91d2b-9230-4d3e-a3d0-c3844ad9e985",
            "firstName": "Tom",
            "lastName": "Takubo",
            "fullName": "Tom Takubo",
            "gender": "Male",
            "party": "Republican",
            "image": "http://www.wvlegislature.gov/images/members/2021/senate/takubo_tom.jpg",
            "title": "WV Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WV",
              "stateFull": "West Virginia",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-WV-17-upper-ocd-person-c6a91d2b-9230-4d3e-a3d0-c3844ad9e985",
              "builtID": "wv-upper-17",
              "externalID": "ocd-person/c6a91d2b-9230-4d3e-a3d0-c3844ad9e985",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.takubo@wvsenate.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://www.legis.state.wv.us/Senate1/lawmaker.cfm?member=Senator+Takubo",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Takubo",
                "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator+Takubo"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WV-17-upper-ocd-person-c440f489-4e7a-4351-a7c7-7137ed79d94e": 0,
          "SLDU-WV-17-upper-ocd-person-c6a91d2b-9230-4d3e-a3d0-c3844ad9e985": 1
        }
      }
    }
  },
  "WI": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "WI",
          "stateFull": "Wisconsin",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "WI",
            "stateFull": "Wisconsin",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-WI---",
            "builtID": "wi--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-WI---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "B001230",
          "in_office": true,
          "firstName": "Tammy",
          "lastName": "Baldwin",
          "middleName": null,
          "fullName": "Tammy Baldwin",
          "gender": "F",
          "party": "Democrat",
          "image": "https://theunitedstates.io/images/congress/225x275/B001230.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "WI",
            "stateFull": "Wisconsin",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-WI---B001230",
            "builtID": "wi--",
            "externalID": "B001230",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.baldwin.senate.gov/feedback",
            "address1": "709 Hart Senate Office Building",
            "address2": null,
            "phone1": "202-224-5653",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenatorBaldwin",
            "youtube": "witammybaldwin",
            "instagram": null,
            "facebook": "senatortammybaldwin",
            "urls": ["https://www.baldwin.senate.gov"],
            "rss_url": null
          },
          "title": "WI Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/B001230.json",
          "govtrack_id": "400013",
          "cspan_id": "57884",
          "votesmart_id": "3470",
          "icpsr_id": "29940",
          "crp_id": "N00004367",
          "google_entity_id": "/m/024v02",
          "state_rank": "junior",
          "lis_id": "S354",
          "suffix": null,
          "date_of_birth": "1962-02-11",
          "leadership_role": "Senate Democratic Caucus Secretary",
          "fec_candidate_id": "H8WI00018",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "11",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 3,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:30 -0400",
          "missed_votes_pct": 0.54,
          "votes_with_party_pct": 97.27,
          "votes_against_party_pct": 2.73,
          "ocd_id": "ocd-division/country:us/state:wi"
        },
        {
          "API_ID": "J000293",
          "in_office": true,
          "firstName": "Ron",
          "lastName": "Johnson",
          "middleName": null,
          "fullName": "Ron Johnson",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/J000293.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "WI",
            "stateFull": "Wisconsin",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-WI---J000293",
            "builtID": "wi--",
            "externalID": "J000293",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": null,
            "address1": null,
            "address2": null,
            "phone1": null,
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenRonJohnson",
            "youtube": "SenatorRonJohnson",
            "instagram": null,
            "facebook": "senronjohnson",
            "urls": ["https://www.ronjohnson.senate.gov"],
            "rss_url": "https://www.ronjohnson.senate.gov/public/?a=rss.feed"
          },
          "title": "WI Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/J000293.json",
          "govtrack_id": "412496",
          "cspan_id": "62835",
          "votesmart_id": "126217",
          "icpsr_id": "41111",
          "crp_id": "N00032546",
          "google_entity_id": "/m/0cmdpzc",
          "state_rank": "senior",
          "lis_id": "S345",
          "suffix": null,
          "date_of_birth": "1955-04-08",
          "leadership_role": null,
          "fec_candidate_id": "S0WI00197",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "13",
          "next_election": "2028",
          "total_votes": 554,
          "missed_votes": 18,
          "total_present": 0,
          "last_updated": "2024-06-20 15:30:30 -0400",
          "missed_votes_pct": 3.25,
          "votes_with_party_pct": 91.46,
          "votes_against_party_pct": 8.54,
          "ocd_id": "ocd-division/country:us/state:wi"
        }
      ],
      "hash": { "SENATE-WI---B001230": 0, "SENATE-WI---J000293": 1 }
    },
    "HOUSE": {
      "05": {
        "members": [
          {
            "API_ID": "F000471",
            "in_office": true,
            "firstName": "Scott",
            "lastName": "Fitzgerald",
            "middleName": null,
            "fullName": "Scott Fitzgerald",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/F000471.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "05",
              "chamber": "",
              "hashID": "HOUSE-WI-05--F000471",
              "builtID": "wi--05",
              "externalID": "F000471",
              "geometry": null,
              "geoid": "5505"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1507 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5101",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepFitzgerald",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://fitzgerald.house.gov"],
              "rss_url": null
            },
            "title": "WI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/F000471.json",
            "govtrack_id": "456855",
            "cspan_id": null,
            "votesmart_id": "3446",
            "icpsr_id": null,
            "crp_id": "N00045434",
            "google_entity_id": "/m/03nvqsc",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1963-11-16",
            "leadership_role": null,
            "fec_candidate_id": "H0WI05113",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "4",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 7,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.66,
            "votes_with_party_pct": 93.29,
            "votes_against_party_pct": 4.77,
            "ocd_id": "ocd-division/country:us/state:wi/cd:5",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WI-05--F000471": 0 }
      },
      "06": {
        "members": [
          {
            "API_ID": "G000576",
            "in_office": true,
            "firstName": "Glenn",
            "lastName": "Grothman",
            "middleName": null,
            "fullName": "Glenn Grothman",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000576.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "06",
              "chamber": "",
              "hashID": "HOUSE-WI-06--G000576",
              "builtID": "wi--06",
              "externalID": "G000576",
              "geometry": null,
              "geoid": "5506"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1511 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2476",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGrothman",
              "youtube": null,
              "instagram": null,
              "facebook": "RepGrothman",
              "urls": ["https://grothman.house.gov"],
              "rss_url": null
            },
            "title": "WI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000576.json",
            "govtrack_id": "412661",
            "cspan_id": "77282",
            "votesmart_id": "3493",
            "icpsr_id": "21559",
            "crp_id": "N00036409",
            "google_entity_id": "/m/02pnsk2",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1955-07-03",
            "leadership_role": null,
            "fec_candidate_id": "H4WI06048",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "10",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 15,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 1.42,
            "votes_with_party_pct": 93.23,
            "votes_against_party_pct": 4.91,
            "ocd_id": "ocd-division/country:us/state:wi/cd:6",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WI-06--G000576": 0 }
      },
      "04": {
        "members": [
          {
            "API_ID": "M001160",
            "in_office": true,
            "firstName": "Gwen",
            "lastName": "Moore",
            "middleName": null,
            "fullName": "Gwen Moore",
            "gender": "F",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/M001160.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "04",
              "chamber": "",
              "hashID": "HOUSE-WI-04--M001160",
              "builtID": "wi--04",
              "externalID": "M001160",
              "geometry": null,
              "geoid": "5504"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "2252 Rayburn House Office Building",
              "address2": null,
              "phone1": "202-225-4572",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGwenMoore",
              "youtube": "RepGwenMoore",
              "instagram": null,
              "facebook": "GwenSMoore",
              "urls": ["https://gwenmoore.house.gov"],
              "rss_url": null
            },
            "title": "WI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/M001160.json",
            "govtrack_id": "400661",
            "cspan_id": "42548",
            "votesmart_id": "3457",
            "icpsr_id": "20537",
            "crp_id": "N00026914",
            "google_entity_id": "/m/04cvds",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1951-04-18",
            "leadership_role": null,
            "fec_candidate_id": "H4WI04183",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "20",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 52,
            "total_present": 1,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 4.93,
            "votes_with_party_pct": 93.77,
            "votes_against_party_pct": 4.32,
            "ocd_id": "ocd-division/country:us/state:wi/cd:4",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WI-04--M001160": 0 }
      },
      "02": {
        "members": [
          {
            "API_ID": "P000607",
            "in_office": true,
            "firstName": "Mark",
            "lastName": "Pocan",
            "middleName": null,
            "fullName": "Mark Pocan",
            "gender": "M",
            "party": "Democrat",
            "image": "https://theunitedstates.io/images/congress/225x275/P000607.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "02",
              "chamber": "",
              "hashID": "HOUSE-WI-02--P000607",
              "builtID": "wi--02",
              "externalID": "P000607",
              "geometry": null,
              "geoid": "5502"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1026 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2906",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepMarkPocan",
              "youtube": "repmarkpocan",
              "instagram": null,
              "facebook": "repmarkpocan",
              "urls": ["https://pocan.house.gov"],
              "rss_url": "https://pocan.house.gov/rss.xml"
            },
            "title": "WI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/P000607.json",
            "govtrack_id": "412585",
            "cspan_id": "79688",
            "votesmart_id": "26238",
            "icpsr_id": "21370",
            "crp_id": "N00033549",
            "google_entity_id": "/m/0gyhpz",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1964-08-14",
            "leadership_role": null,
            "fec_candidate_id": "H2WI02124",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "12",
            "next_election": "2024",
            "total_votes": 1055,
            "missed_votes": 47,
            "total_present": 2,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 4.45,
            "votes_with_party_pct": 93.39,
            "votes_against_party_pct": 4.71,
            "ocd_id": "ocd-division/country:us/state:wi/cd:2",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WI-02--P000607": 0 }
      },
      "01": {
        "members": [
          {
            "API_ID": "S001213",
            "in_office": true,
            "firstName": "Bryan",
            "lastName": "Steil",
            "middleName": null,
            "fullName": "Bryan Steil",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/S001213.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "01",
              "chamber": "",
              "hashID": "HOUSE-WI-01--S001213",
              "builtID": "wi--01",
              "externalID": "S001213",
              "geometry": null,
              "geoid": "5501"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1526 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-3031",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepBryanSteil",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://steil.house.gov"],
              "rss_url": null
            },
            "title": "WI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/S001213.json",
            "govtrack_id": "412836",
            "cspan_id": null,
            "votesmart_id": "181289",
            "icpsr_id": null,
            "crp_id": "N00043379",
            "google_entity_id": "/g/11fhnfybnd",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1981-03-30",
            "leadership_role": null,
            "fec_candidate_id": "H8WI01156",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 7,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:13 -0400",
            "missed_votes_pct": 0.66,
            "votes_with_party_pct": 94.07,
            "votes_against_party_pct": 3.99,
            "ocd_id": "ocd-division/country:us/state:wi/cd:1",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WI-01--S001213": 0 }
      },
      "07": {
        "members": [
          {
            "API_ID": "T000165",
            "in_office": true,
            "firstName": "Tom",
            "lastName": "Tiffany",
            "middleName": null,
            "fullName": "Thomas P. Tiffany",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/T000165.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "07",
              "chamber": "",
              "hashID": "HOUSE-WI-07--T000165",
              "builtID": "wi--07",
              "externalID": "T000165",
              "geometry": null,
              "geoid": "5507"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "451 Cannon House Office Building",
              "address2": null,
              "phone1": "202-225-3365",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepTiffany",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://tiffany.house.gov"],
              "rss_url": null
            },
            "title": "WI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/T000165.json",
            "govtrack_id": "456791",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": "9303",
            "crp_id": "N00045307",
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1957-12-30",
            "leadership_role": null,
            "fec_candidate_id": "H0WI07101",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "6",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 19,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.8,
            "votes_with_party_pct": 88.09,
            "votes_against_party_pct": 9.94,
            "ocd_id": "ocd-division/country:us/state:wi/cd:7",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WI-07--T000165": 0 }
      },
      "03": {
        "members": [
          {
            "API_ID": "V000135",
            "in_office": true,
            "firstName": "Derrick",
            "lastName": "Van Orden",
            "middleName": null,
            "fullName": "Derrick Van Orden",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/V000135.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "03",
              "chamber": "",
              "hashID": "HOUSE-WI-03--V000135",
              "builtID": "wi--03",
              "externalID": "V000135",
              "geometry": null,
              "geoid": "5503"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1513 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5506",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepVanOrden",
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://vanorden.house.gov/"],
              "rss_url": null
            },
            "title": "WI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/V000135.json",
            "govtrack_id": "456950",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1969-09-15",
            "leadership_role": null,
            "fec_candidate_id": "H0WI03175",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 36,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 3.41,
            "votes_with_party_pct": 89.52,
            "votes_against_party_pct": 8.68,
            "ocd_id": "ocd-division/country:us/state:wi/cd:3",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WI-03--V000135": 0 }
      },
      "08": {
        "members": [
          {
            "API_ID": "G000579",
            "in_office": false,
            "firstName": "Mike",
            "lastName": "Gallagher",
            "middleName": null,
            "fullName": "Mike Gallagher",
            "gender": "M",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/G000579.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "08",
              "chamber": "",
              "hashID": "HOUSE-WI-08--G000579",
              "builtID": "wi--08",
              "externalID": "G000579",
              "geometry": null,
              "geoid": "5508"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1211 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-5665",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": "RepGallagher",
              "youtube": null,
              "instagram": null,
              "facebook": "RepMikeGallagher",
              "urls": ["https://gallagher.house.gov"],
              "rss_url": "https://gallagher.house.gov/rss.xml"
            },
            "title": "WI House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/G000579.json",
            "govtrack_id": "412731",
            "cspan_id": "104067",
            "votesmart_id": "171843",
            "icpsr_id": "21720",
            "crp_id": "N00039330",
            "google_entity_id": "/g/11c5qns1cc",
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1984-03-03",
            "leadership_role": null,
            "fec_candidate_id": "H6WI08155",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "8",
            "next_election": "2024",
            "total_votes": 872,
            "missed_votes": 19,
            "total_present": 0,
            "last_updated": "2024-06-28 17:03:36 -0400",
            "missed_votes_pct": 2.18,
            "votes_with_party_pct": 92.72,
            "votes_against_party_pct": 5.01,
            "ocd_id": "ocd-division/country:us/state:wi/cd:8",
            "at_large": false
          }
        ],
        "hash": { "HOUSE-WI-08--G000579": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/e127b754-c756-4017-a414-83cff1bb30f5",
            "firstName": "Joel C.",
            "lastName": "Kitchens",
            "fullName": "Joel Kitchens",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2476.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-WI-1-lower-ocd-person-e127b754-c756-4017-a414-83cff1bb30f5",
              "builtID": "wi-lower-1",
              "externalID": "ocd-person/e127b754-c756-4017-a414-83cff1bb30f5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kitchens@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1572",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1795",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2152",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2152",
                "https://legis.wisconsin.gov/assembly/01/kitchens",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2476",
                "https://legis.wisconsin.gov/assembly/01/kitchens/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-1-lower-ocd-person-e127b754-c756-4017-a414-83cff1bb30f5": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/82745ad9-d4b8-48e7-bbb9-3c5d73535c30",
            "firstName": "Shae",
            "lastName": "Sortwell",
            "fullName": "Shae Sortwell",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2516.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-WI-2-lower-ocd-person-82745ad9-d4b8-48e7-bbb9-3c5d73535c30",
              "builtID": "wi-lower-2",
              "externalID": "ocd-person/82745ad9-d4b8-48e7-bbb9-3c5d73535c30",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.sortwell@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1932",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2192",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2192",
                "https://legis.wisconsin.gov/assembly/02/Sortwell/",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2516"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-2-lower-ocd-person-82745ad9-d4b8-48e7-bbb9-3c5d73535c30": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/1c2038cc-ee27-475c-a578-0236dba3f3f3",
            "firstName": "Ron",
            "lastName": "Tusler",
            "fullName": "Ron Tusler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2528.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-WI-3-lower-ocd-person-1c2038cc-ee27-475c-a578-0236dba3f3f3",
              "builtID": "wi-lower-3",
              "externalID": "ocd-person/1c2038cc-ee27-475c-a578-0236dba3f3f3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.tusler@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1632",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1797",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2207",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2207",
                "https://legis.wisconsin.gov/assembly/03/tusler",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2528",
                "https://legis.wisconsin.gov/assembly/03/tusler/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-3-lower-ocd-person-1c2038cc-ee27-475c-a578-0236dba3f3f3": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/2a067a93-dffc-4ce7-8975-d37fb67b4512",
            "firstName": "David",
            "lastName": "Steffen",
            "fullName": "David Steffen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2519.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-WI-4-lower-ocd-person-2a067a93-dffc-4ce7-8975-d37fb67b4512",
              "builtID": "wi-lower-4",
              "externalID": "ocd-person/2a067a93-dffc-4ce7-8975-d37fb67b4512",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.steffen@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1614",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1798",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2196",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2196",
                "https://legis.wisconsin.gov/assembly/04/steffen",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2519",
                "https://legis.wisconsin.gov/assembly/04/steffen/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-4-lower-ocd-person-2a067a93-dffc-4ce7-8975-d37fb67b4512": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/b8666d84-956f-40a7-8faa-75709f9d4a12",
            "firstName": "Joy L.",
            "lastName": "Goeben",
            "fullName": "Joy Goeben",
            "gender": "Female",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2542.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-WI-5-lower-ocd-person-b8666d84-956f-40a7-8faa-75709f9d4a12",
              "builtID": "wi-lower-5",
              "externalID": "ocd-person/b8666d84-956f-40a7-8faa-75709f9d4a12",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.goeben@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2542"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-5-lower-ocd-person-b8666d84-956f-40a7-8faa-75709f9d4a12": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/040f0780-36b0-43b2-a3a2-52e55420c432",
            "firstName": "Peter A.",
            "lastName": "Schmidt",
            "fullName": "Peter Schmidt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2543.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-WI-6-lower-ocd-person-040f0780-36b0-43b2-a3a2-52e55420c432",
              "builtID": "wi-lower-6",
              "externalID": "ocd-person/040f0780-36b0-43b2-a3a2-52e55420c432",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.schmidt@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2543",
                "https://legis.wisconsin.gov/assembly/06/schmidt/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-6-lower-ocd-person-040f0780-36b0-43b2-a3a2-52e55420c432": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/1ef2eb8d-6538-41e1-8cb4-f83d87d10a77",
            "firstName": "Daniel",
            "lastName": "Riemer",
            "fullName": "Daniel Riemer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2504.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-WI-7-lower-ocd-person-1ef2eb8d-6538-41e1-8cb4-f83d87d10a77",
              "builtID": "wi-lower-7",
              "externalID": "ocd-person/1ef2eb8d-6538-41e1-8cb4-f83d87d10a77",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.riemer@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1602",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1801",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2182",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2182",
                "https://legis.wisconsin.gov/assembly/07/riemer",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2504",
                "https://legis.wisconsin.gov/assembly/07/riemer/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-7-lower-ocd-person-1ef2eb8d-6538-41e1-8cb4-f83d87d10a77": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/710a050b-1721-4e81-bc01-ad3193836e4d",
            "firstName": "Sylvia",
            "lastName": "Ortiz-Velez",
            "fullName": "Sylvia Ortiz-Velez",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2496.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-WI-8-lower-ocd-person-710a050b-1721-4e81-bc01-ad3193836e4d",
              "builtID": "wi-lower-8",
              "externalID": "ocd-person/710a050b-1721-4e81-bc01-ad3193836e4d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ortiz-velez@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2262",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2262",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2496"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-8-lower-ocd-person-710a050b-1721-4e81-bc01-ad3193836e4d": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/8bec24d0-14a7-42bf-ae33-515c6c88dc7a",
            "firstName": "Marisabel",
            "lastName": "Cabrera",
            "fullName": "Marisabel Cabrera",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2455.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-WI-9-lower-ocd-person-8bec24d0-14a7-42bf-ae33-515c6c88dc7a",
              "builtID": "wi-lower-9",
              "externalID": "ocd-person/8bec24d0-14a7-42bf-ae33-515c6c88dc7a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.cabrera@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1927",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2130",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2130",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2455"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-9-lower-ocd-person-8bec24d0-14a7-42bf-ae33-515c6c88dc7a": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/d9f84b91-88ac-4abc-8d47-0672e2de5970",
            "firstName": "Darrin Brian",
            "lastName": "Madison",
            "fullName": "Darrin Madison",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2536.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-WI-10-lower-ocd-person-d9f84b91-88ac-4abc-8d47-0672e2de5970",
              "builtID": "wi-lower-10",
              "externalID": "ocd-person/d9f84b91-88ac-4abc-8d47-0672e2de5970",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.madison@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2536",
                "https://legis.wisconsin.gov/assembly/10/madison/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-10-lower-ocd-person-d9f84b91-88ac-4abc-8d47-0672e2de5970": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/798979da-9683-41b4-b663-d5c73ec93364",
            "firstName": "Dora Elizabeth",
            "lastName": "Drake",
            "fullName": "Dora Drake",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2462.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-WI-11-lower-ocd-person-798979da-9683-41b4-b663-d5c73ec93364",
              "builtID": "wi-lower-11",
              "externalID": "ocd-person/798979da-9683-41b4-b663-d5c73ec93364",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.drake@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2263",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2263",
                "https://legis.wisconsin.gov/assembly/11/drake",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2462",
                "https://legis.wisconsin.gov/assembly/11/drake/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-11-lower-ocd-person-798979da-9683-41b4-b663-d5c73ec93364": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/5a42b812-cb61-49fe-a984-f32b84bda9fd",
            "firstName": "LaKeshia N.",
            "lastName": "Myers",
            "fullName": "LaKeshia Myers",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2490.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-WI-12-lower-ocd-person-5a42b812-cb61-49fe-a984-f32b84bda9fd",
              "builtID": "wi-lower-12",
              "externalID": "ocd-person/5a42b812-cb61-49fe-a984-f32b84bda9fd",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.myers@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1928",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2167",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2167",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2490",
                "https://legis.wisconsin.gov/assembly/12/myers"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-12-lower-ocd-person-5a42b812-cb61-49fe-a984-f32b84bda9fd": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/793ae98d-d7e9-4da1-9af2-f3037d051d07",
            "firstName": "Thomas A.",
            "lastName": "Michalski",
            "fullName": "Tom Michalski",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2548.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-WI-13-lower-ocd-person-793ae98d-d7e9-4da1-9af2-f3037d051d07",
              "builtID": "wi-lower-13",
              "externalID": "ocd-person/793ae98d-d7e9-4da1-9af2-f3037d051d07",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.michalski@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2548",
                "https://legis.wisconsin.gov/assembly/13/michalski/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-13-lower-ocd-person-793ae98d-d7e9-4da1-9af2-f3037d051d07": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/5faa0c8c-6ceb-4629-819b-b13779b93754",
            "firstName": "Robyn Beckley",
            "lastName": "Vining",
            "fullName": "Robyn Vining",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2530.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-WI-14-lower-ocd-person-5faa0c8c-6ceb-4629-819b-b13779b93754",
              "builtID": "wi-lower-14",
              "externalID": "ocd-person/5faa0c8c-6ceb-4629-819b-b13779b93754",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.vining@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1945",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2209",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2209",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2530",
                "https://legis.wisconsin.gov/assembly/14/vining"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-14-lower-ocd-person-5faa0c8c-6ceb-4629-819b-b13779b93754": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/f20f569f-1f36-4856-9193-b4b938dfcdaf",
            "firstName": "David G.",
            "lastName": "Maxey",
            "fullName": "Dave Maxey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2537.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-WI-15-lower-ocd-person-f20f569f-1f36-4856-9193-b4b938dfcdaf",
              "builtID": "wi-lower-15",
              "externalID": "ocd-person/f20f569f-1f36-4856-9193-b4b938dfcdaf",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.maxey@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2537",
                "https://legis.wisconsin.gov/assembly/15/maxey/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-15-lower-ocd-person-f20f569f-1f36-4856-9193-b4b938dfcdaf": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/ac00fa62-2863-4ce4-97fb-57c094eaa158",
            "firstName": "Kalan",
            "lastName": "Haywood",
            "fullName": "Kalan Haywood",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2468.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-WI-16-lower-ocd-person-ac00fa62-2863-4ce4-97fb-57c094eaa158",
              "builtID": "wi-lower-16",
              "externalID": "ocd-person/ac00fa62-2863-4ce4-97fb-57c094eaa158",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.haywood@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1929",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2142",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2142",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2468",
                "https://legis.wisconsin.gov/assembly/16/haywood/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-16-lower-ocd-person-ac00fa62-2863-4ce4-97fb-57c094eaa158": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/bf0fa47b-889e-49fb-8fab-059452acdbf9",
            "firstName": "Supreme",
            "lastName": "Moore Omokunde",
            "fullName": "Supreme Moore Omokunde",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2486.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-WI-17-lower-ocd-person-bf0fa47b-889e-49fb-8fab-059452acdbf9",
              "builtID": "wi-lower-17",
              "externalID": "ocd-person/bf0fa47b-889e-49fb-8fab-059452acdbf9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.mooreomokunde@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2265",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2265",
                "https://legis.wisconsin.gov/assembly/17/moore-omokunde",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2486",
                "https://legis.wisconsin.gov/assembly/17/moore-omokunde/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-17-lower-ocd-person-bf0fa47b-889e-49fb-8fab-059452acdbf9": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/b09f91bf-8fbe-41a7-9492-4fdf5f1783e5",
            "firstName": "Evan C.",
            "lastName": "Goyke",
            "fullName": "Evan Goyke",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2466.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-WI-18-lower-ocd-person-b09f91bf-8fbe-41a7-9492-4fdf5f1783e5",
              "builtID": "wi-lower-18",
              "externalID": "ocd-person/b09f91bf-8fbe-41a7-9492-4fdf5f1783e5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.goyke@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1556",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1812",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2139",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2139",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2466",
                "https://legis.wisconsin.gov/assembly/18/goyke"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-18-lower-ocd-person-b09f91bf-8fbe-41a7-9492-4fdf5f1783e5": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/91423371-a92c-4006-ad7d-e21f2f7e666b",
            "firstName": "Ryan M.",
            "lastName": "Clancy",
            "fullName": "Ryan Clancy",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2538.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-WI-19-lower-ocd-person-91423371-a92c-4006-ad7d-e21f2f7e666b",
              "builtID": "wi-lower-19",
              "externalID": "ocd-person/91423371-a92c-4006-ad7d-e21f2f7e666b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.clancy@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2538"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-19-lower-ocd-person-91423371-a92c-4006-ad7d-e21f2f7e666b": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/2da8406a-4a68-4167-887d-22fe1b872940",
            "firstName": "Christine M.",
            "lastName": "Sinicki",
            "fullName": "Christine Sinicki",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2512.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-WI-20-lower-ocd-person-2da8406a-4a68-4167-887d-22fe1b872940",
              "builtID": "wi-lower-20",
              "externalID": "ocd-person/2da8406a-4a68-4167-887d-22fe1b872940",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.sinicki@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1610",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1814",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2189",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2189",
                "https://legis.wisconsin.gov/assembly/20/sinicki",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2512"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-20-lower-ocd-person-2da8406a-4a68-4167-887d-22fe1b872940": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/1dd290f4-6504-4967-9566-55cb3340d303",
            "firstName": "Jessie",
            "lastName": "Rodriguez",
            "fullName": "Jessie Rodriguez",
            "gender": "Female",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2505.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-WI-21-lower-ocd-person-1dd290f4-6504-4967-9566-55cb3340d303",
              "builtID": "wi-lower-21",
              "externalID": "ocd-person/1dd290f4-6504-4967-9566-55cb3340d303",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.rodriguez@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1604",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1815",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2183",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2183",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2505",
                "https://legis.wisconsin.gov/assembly/21/rodriguez"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-21-lower-ocd-person-1dd290f4-6504-4967-9566-55cb3340d303": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/9f1155fd-1c79-4160-8ca2-97cce750f397",
            "firstName": "Janel",
            "lastName": "Brandtjen",
            "fullName": "Janel Brandtjen",
            "gender": "Female",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2451.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-WI-22-lower-ocd-person-9f1155fd-1c79-4160-8ca2-97cce750f397",
              "builtID": "wi-lower-22",
              "externalID": "ocd-person/9f1155fd-1c79-4160-8ca2-97cce750f397",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.brandtjen@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1543",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1816",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2127",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2127",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2451",
                "https://legis.wisconsin.gov/assembly/22/brandtjen/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-22-lower-ocd-person-9f1155fd-1c79-4160-8ca2-97cce750f397": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/bcca1ebf-1a4c-4624-97a6-dbfd3e255191",
            "firstName": "Deborah Jane Anderson",
            "lastName": "Andraca",
            "fullName": "Deb Andraca",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2443.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-WI-23-lower-ocd-person-bcca1ebf-1a4c-4624-97a6-dbfd3e255191",
              "builtID": "wi-lower-23",
              "externalID": "ocd-person/bcca1ebf-1a4c-4624-97a6-dbfd3e255191",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.andraca@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2266",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2266",
                "https://legis.wisconsin.gov/assembly/23/andraca",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2443",
                "https://legis.wisconsin.gov/assembly/23/andraca/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-23-lower-ocd-person-bcca1ebf-1a4c-4624-97a6-dbfd3e255191": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/dab88795-9c78-44c5-a0c7-c93cb677f2e3",
            "firstName": "Paul D.",
            "lastName": "Melotik",
            "fullName": "Paul Melotik",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2690.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-WI-24-lower-ocd-person-dab88795-9c78-44c5-a0c7-c93cb677f2e3",
              "builtID": "wi-lower-24",
              "externalID": "ocd-person/dab88795-9c78-44c5-a0c7-c93cb677f2e3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.melotik@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2690",
                "https://legis.wisconsin.gov/assembly/24/melotik/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-24-lower-ocd-person-dab88795-9c78-44c5-a0c7-c93cb677f2e3": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/2e759534-4cf1-43f5-85d0-bf48fea3a048",
            "firstName": "Paul",
            "lastName": "Tittl",
            "fullName": "Paul Tittl",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2526.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-WI-25-lower-ocd-person-2e759534-4cf1-43f5-85d0-bf48fea3a048",
              "builtID": "wi-lower-25",
              "externalID": "ocd-person/2e759534-4cf1-43f5-85d0-bf48fea3a048",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.tittl@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1622",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1819",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2205",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2205",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2526",
                "https://legis.wisconsin.gov/assembly/25/tittl"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-25-lower-ocd-person-2e759534-4cf1-43f5-85d0-bf48fea3a048": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/cafea297-20c4-4973-b476-71dbd3bfd881",
            "firstName": "Terry",
            "lastName": "Katsma",
            "fullName": "Terry Katsma",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2475.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-WI-26-lower-ocd-person-cafea297-20c4-4973-b476-71dbd3bfd881",
              "builtID": "wi-lower-26",
              "externalID": "ocd-person/cafea297-20c4-4973-b476-71dbd3bfd881",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.katsma@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1569",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1820",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2150",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2150",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2475",
                "https://legis.wisconsin.gov/assembly/26/katsma"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-26-lower-ocd-person-cafea297-20c4-4973-b476-71dbd3bfd881": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/7ec80da8-b6c1-47d1-98bf-d4940cce195d",
            "firstName": "Amy E.",
            "lastName": "Binsfeld",
            "fullName": "Amy Binsfeld",
            "gender": "Female",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2541.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-WI-27-lower-ocd-person-7ec80da8-b6c1-47d1-98bf-d4940cce195d",
              "builtID": "wi-lower-27",
              "externalID": "ocd-person/7ec80da8-b6c1-47d1-98bf-d4940cce195d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.binsfeld@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2541"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-27-lower-ocd-person-7ec80da8-b6c1-47d1-98bf-d4940cce195d": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/ac67be66-960a-4cae-a931-9b5012b4a319",
            "firstName": "Gae",
            "lastName": "Magnafici",
            "fullName": "Gae Magnafici",
            "gender": "Female",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2482.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-WI-28-lower-ocd-person-ac67be66-960a-4cae-a931-9b5012b4a319",
              "builtID": "wi-lower-28",
              "externalID": "ocd-person/ac67be66-960a-4cae-a931-9b5012b4a319",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.magnafici@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1933",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2161",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2161",
                "https://legis.wisconsin.gov/assembly/28/Magnafici/",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2482"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-28-lower-ocd-person-ac67be66-960a-4cae-a931-9b5012b4a319": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/21e310b1-c14b-42e6-b3fc-84c1613c3063",
            "firstName": "Clint",
            "lastName": "Moses",
            "fullName": "Clint Moses",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2487.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-WI-29-lower-ocd-person-21e310b1-c14b-42e6-b3fc-84c1613c3063",
              "builtID": "wi-lower-29",
              "externalID": "ocd-person/21e310b1-c14b-42e6-b3fc-84c1613c3063",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.moses@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2253",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2253",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2487",
                "https://legis.wisconsin.gov/assembly/29/moses/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-29-lower-ocd-person-21e310b1-c14b-42e6-b3fc-84c1613c3063": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/d949fee5-765b-43ea-913c-386fe2b3c441",
            "firstName": "Shannon M.",
            "lastName": "Zimmerman",
            "fullName": "Shannon Zimmerman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2535.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-WI-30-lower-ocd-person-d949fee5-765b-43ea-913c-386fe2b3c441",
              "builtID": "wi-lower-30",
              "externalID": "ocd-person/d949fee5-765b-43ea-913c-386fe2b3c441",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.zimmerman@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1636",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1824",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2216",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2216",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2535",
                "https://legis.wisconsin.gov/assembly/30/zimmerman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-30-lower-ocd-person-d949fee5-765b-43ea-913c-386fe2b3c441": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/62a56543-1edc-4606-bc82-9ec10082e2df",
            "firstName": "Ellen L.",
            "lastName": "Schutt",
            "fullName": "Ellen Schutt",
            "gender": "Female",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2544.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-WI-31-lower-ocd-person-62a56543-1edc-4606-bc82-9ec10082e2df",
              "builtID": "wi-lower-31",
              "externalID": "ocd-person/62a56543-1edc-4606-bc82-9ec10082e2df",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.schutt@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2544",
                "https://legis.wisconsin.gov/assembly/31/schutt/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-31-lower-ocd-person-62a56543-1edc-4606-bc82-9ec10082e2df": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/b4554989-ede4-44a0-a541-c2aea2659d39",
            "firstName": "Tyler",
            "lastName": "August",
            "fullName": "Tyler August",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2445.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-WI-32-lower-ocd-person-b4554989-ede4-44a0-a541-c2aea2659d39",
              "builtID": "wi-lower-32",
              "externalID": "ocd-person/b4554989-ede4-44a0-a541-c2aea2659d39",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.august@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1534",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1826",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2122",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2122",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2445",
                "https://legis.wisconsin.gov/assembly/32/august/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-32-lower-ocd-person-b4554989-ede4-44a0-a541-c2aea2659d39": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/ce3fcf14-f082-4df6-99e1-eb14bb7b1e07",
            "firstName": "Scott L.",
            "lastName": "Johnson",
            "fullName": "Scott Johnson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2620.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-WI-33-lower-ocd-person-ce3fcf14-f082-4df6-99e1-eb14bb7b1e07",
              "builtID": "wi-lower-33",
              "externalID": "ocd-person/ce3fcf14-f082-4df6-99e1-eb14bb7b1e07",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.johnson@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2620",
                "https://legis.wisconsin.gov/assembly/33/Johnson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-33-lower-ocd-person-ce3fcf14-f082-4df6-99e1-eb14bb7b1e07": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/3a7f3611-78c4-453a-ae50-3cad3761dc9b",
            "firstName": "Rob",
            "lastName": "Swearingen",
            "fullName": "Rob Swearingen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2523.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-WI-34-lower-ocd-person-3a7f3611-78c4-453a-ae50-3cad3761dc9b",
              "builtID": "wi-lower-34",
              "externalID": "ocd-person/3a7f3611-78c4-453a-ae50-3cad3761dc9b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.swearingen@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1618",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1828",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2202",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2202",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2523",
                "https://legis.wisconsin.gov/assembly/34/swearingen"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-34-lower-ocd-person-3a7f3611-78c4-453a-ae50-3cad3761dc9b": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/23c03244-880a-4263-8e2f-18c0c5556b45",
            "firstName": "Calvin Thomas",
            "lastName": "Callahan",
            "fullName": "Calvin Callahan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2456.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-WI-35-lower-ocd-person-23c03244-880a-4263-8e2f-18c0c5556b45",
              "builtID": "wi-lower-35",
              "externalID": "ocd-person/23c03244-880a-4263-8e2f-18c0c5556b45",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.callahan@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2260",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2260",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2456",
                "https://legis.wisconsin.gov/assembly/35/callahan/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-35-lower-ocd-person-23c03244-880a-4263-8e2f-18c0c5556b45": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/5254db79-a23b-4496-ad9b-cc0e7a9b7f3a",
            "firstName": "Jeffrey",
            "lastName": "Mursau",
            "fullName": "Jeff Mursau",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2489.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-WI-36-lower-ocd-person-5254db79-a23b-4496-ad9b-cc0e7a9b7f3a",
              "builtID": "wi-lower-36",
              "externalID": "ocd-person/5254db79-a23b-4496-ad9b-cc0e7a9b7f3a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.mursau@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1589",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1830",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2166",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2166",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2489",
                "https://legis.wisconsin.gov/assembly/36/mursau/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-36-lower-ocd-person-5254db79-a23b-4496-ad9b-cc0e7a9b7f3a": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/790447a2-d03d-4bc7-b15c-a72b25689eb3",
            "firstName": "William Lee",
            "lastName": "Penterman",
            "fullName": "Will Penterman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2497.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-WI-37-lower-ocd-person-790447a2-d03d-4bc7-b15c-a72b25689eb3",
              "builtID": "wi-lower-37",
              "externalID": "ocd-person/790447a2-d03d-4bc7-b15c-a72b25689eb3",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.penterman@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2398",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2398",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2497",
                "https://legis.wisconsin.gov/assembly/37/penterman/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-37-lower-ocd-person-790447a2-d03d-4bc7-b15c-a72b25689eb3": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/0580ebef-0cfa-40ae-a4ea-d960e0ea30ee",
            "firstName": "Barbara",
            "lastName": "Dittrich",
            "fullName": "Barbara Dittrich",
            "gender": "Female",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2460.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-WI-38-lower-ocd-person-0580ebef-0cfa-40ae-a4ea-d960e0ea30ee",
              "builtID": "wi-lower-38",
              "externalID": "ocd-person/0580ebef-0cfa-40ae-a4ea-d960e0ea30ee",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.dittrich@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1934",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2132",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2132",
                "https://legis.wisconsin.gov/assembly/38/Dittrich",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2460"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-38-lower-ocd-person-0580ebef-0cfa-40ae-a4ea-d960e0ea30ee": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/b6559841-9e69-4435-8788-0d8bc087baed",
            "firstName": "Mark",
            "lastName": "Born",
            "fullName": "Mark Born",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2449.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-WI-39-lower-ocd-person-b6559841-9e69-4435-8788-0d8bc087baed",
              "builtID": "wi-lower-39",
              "externalID": "ocd-person/b6559841-9e69-4435-8788-0d8bc087baed",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.born@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1541",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1833",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2125",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2125",
                "https://legis.wisconsin.gov/assembly/39/born",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2449",
                "https://legis.wisconsin.gov/assembly/39/born/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-39-lower-ocd-person-b6559841-9e69-4435-8788-0d8bc087baed": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/43c33bdc-3c41-47cc-8ffb-10a043ecdc93",
            "firstName": "Kevin David",
            "lastName": "Petersen",
            "fullName": "Kevin Petersen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2498.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-WI-40-lower-ocd-person-43c33bdc-3c41-47cc-8ffb-10a043ecdc93",
              "builtID": "wi-lower-40",
              "externalID": "ocd-person/43c33bdc-3c41-47cc-8ffb-10a043ecdc93",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.petersen@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1598",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1834",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2175",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2175",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2498",
                "https://legis.wisconsin.gov/assembly/40/petersen"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-40-lower-ocd-person-43c33bdc-3c41-47cc-8ffb-10a043ecdc93": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/c4afc94c-3bad-4e77-9f81-de613c406860",
            "firstName": "Alex A.",
            "lastName": "Dallman",
            "fullName": "Alex Dallman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2459.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-WI-41-lower-ocd-person-c4afc94c-3bad-4e77-9f81-de613c406860",
              "builtID": "wi-lower-41",
              "externalID": "ocd-person/c4afc94c-3bad-4e77-9f81-de613c406860",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.dallman@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2256",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2256",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2459",
                "https://legis.wisconsin.gov/assembly/41/dallman"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-41-lower-ocd-person-c4afc94c-3bad-4e77-9f81-de613c406860": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/b8437fd2-e489-47eb-9634-6f29c6314fed",
            "firstName": "Jon",
            "lastName": "Plumer",
            "fullName": "Jon Plumer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2500.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-WI-42-lower-ocd-person-b8437fd2-e489-47eb-9634-6f29c6314fed",
              "builtID": "wi-lower-42",
              "externalID": "ocd-person/b8437fd2-e489-47eb-9634-6f29c6314fed",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.plumer@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1793",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1836",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2177",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2177",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2500",
                "https://legis.wisconsin.gov/assembly/42/plumer/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-42-lower-ocd-person-b8437fd2-e489-47eb-9634-6f29c6314fed": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/a38a3643-e446-419c-b255-97d88f880634",
            "firstName": "Jennifer Shannon",
            "lastName": "Jacobson",
            "fullName": "Jenna Jacobson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2545.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-WI-43-lower-ocd-person-a38a3643-e446-419c-b255-97d88f880634",
              "builtID": "wi-lower-43",
              "externalID": "ocd-person/a38a3643-e446-419c-b255-97d88f880634",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.jacobson@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2545",
                "https://legis.wisconsin.gov/assembly/43/jacobson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-43-lower-ocd-person-a38a3643-e446-419c-b255-97d88f880634": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/c0d4d48c-37a0-470f-923e-017c77e60ca9",
            "firstName": "Sue Seibert",
            "lastName": "Conley",
            "fullName": "Sue Conley",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2457.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-WI-44-lower-ocd-person-c0d4d48c-37a0-470f-923e-017c77e60ca9",
              "builtID": "wi-lower-44",
              "externalID": "ocd-person/c0d4d48c-37a0-470f-923e-017c77e60ca9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.conley@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2257",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2257",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2457",
                "https://legis.wisconsin.gov/assembly/44/conley"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-44-lower-ocd-person-c0d4d48c-37a0-470f-923e-017c77e60ca9": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/d670cb97-7d46-4d57-95ec-1a3654a6fe0c",
            "firstName": "Clinton M.",
            "lastName": "Anderson",
            "fullName": "Clint Anderson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2559.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-WI-45-lower-ocd-person-d670cb97-7d46-4d57-95ec-1a3654a6fe0c",
              "builtID": "wi-lower-45",
              "externalID": "ocd-person/d670cb97-7d46-4d57-95ec-1a3654a6fe0c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.canderson@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2559"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-45-lower-ocd-person-d670cb97-7d46-4d57-95ec-1a3654a6fe0c": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/784b437c-b5d1-4adc-909a-2bc28c9d6e88",
            "firstName": "Melissa Amy",
            "lastName": "Ratcliff",
            "fullName": "Melissa Ratcliff",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2546.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-WI-46-lower-ocd-person-784b437c-b5d1-4adc-909a-2bc28c9d6e88",
              "builtID": "wi-lower-46",
              "externalID": "ocd-person/784b437c-b5d1-4adc-909a-2bc28c9d6e88",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ratcliff@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2546",
                "https://legis.wisconsin.gov/assembly/46/ratcliff/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-46-lower-ocd-person-784b437c-b5d1-4adc-909a-2bc28c9d6e88": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/4fce2362-c755-42fd-81f6-585363f76883",
            "firstName": "Jimmy P.",
            "lastName": "Anderson",
            "fullName": "Jimmy Anderson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2442.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-WI-47-lower-ocd-person-4fce2362-c755-42fd-81f6-585363f76883",
              "builtID": "wi-lower-47",
              "externalID": "ocd-person/4fce2362-c755-42fd-81f6-585363f76883",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.anderson@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1638",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1841",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2121",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2121",
                "https://legis.wisconsin.gov/assembly/47/anderson",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2442",
                "https://legis.wisconsin.gov/assembly/47/anderson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-47-lower-ocd-person-4fce2362-c755-42fd-81f6-585363f76883": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/c82281d5-e352-473c-8b53-aaa8556ce06a",
            "firstName": "Samba",
            "lastName": "Baldeh",
            "fullName": "Samba Baldeh",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2446.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-WI-48-lower-ocd-person-c82281d5-e352-473c-8b53-aaa8556ce06a",
              "builtID": "wi-lower-48",
              "externalID": "ocd-person/c82281d5-e352-473c-8b53-aaa8556ce06a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.baldeh@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2259",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2259",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2446",
                "https://legis.wisconsin.gov/assembly/48/baldeh"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-48-lower-ocd-person-c82281d5-e352-473c-8b53-aaa8556ce06a": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/97dae592-4e4a-4cf2-9d56-069d7432b8d9",
            "firstName": "Travis",
            "lastName": "Tranel",
            "fullName": "Travis Tranel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2527.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-WI-49-lower-ocd-person-97dae592-4e4a-4cf2-9d56-069d7432b8d9",
              "builtID": "wi-lower-49",
              "externalID": "ocd-person/97dae592-4e4a-4cf2-9d56-069d7432b8d9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.tranel@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1623",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1843",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2206",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2206",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2527",
                "https://legis.wisconsin.gov/assembly/49/tranel"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-49-lower-ocd-person-97dae592-4e4a-4cf2-9d56-069d7432b8d9": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/5967b2fb-a060-4262-b10d-6fd27d81bf3f",
            "firstName": "Anthony Michael",
            "lastName": "Kurtz",
            "fullName": "Tony Kurtz",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2479.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-WI-50-lower-ocd-person-5967b2fb-a060-4262-b10d-6fd27d81bf3f",
              "builtID": "wi-lower-50",
              "externalID": "ocd-person/5967b2fb-a060-4262-b10d-6fd27d81bf3f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.kurtz@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1935",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2158",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2158",
                "https://legis.wisconsin.gov/assembly/50/Kurtz/",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2479"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-50-lower-ocd-person-5967b2fb-a060-4262-b10d-6fd27d81bf3f": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/c1e8d1c1-380d-4f59-893e-a18f77be21dd",
            "firstName": "Todd D.",
            "lastName": "Novak",
            "fullName": "Todd Novak",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2493.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-WI-51-lower-ocd-person-c1e8d1c1-380d-4f59-893e-a18f77be21dd",
              "builtID": "wi-lower-51",
              "externalID": "ocd-person/c1e8d1c1-380d-4f59-893e-a18f77be21dd",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.novak@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1593",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1845",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2170",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2170",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2493",
                "https://legis.wisconsin.gov/assembly/51/novak/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-51-lower-ocd-person-c1e8d1c1-380d-4f59-893e-a18f77be21dd": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/4e1004c1-d397-4a50-baeb-2d3fbbd2912f",
            "firstName": "Jerry L.",
            "lastName": "O'Connor",
            "fullName": "Jerry O'Connor",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2549.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-WI-52-lower-ocd-person-4e1004c1-d397-4a50-baeb-2d3fbbd2912f",
              "builtID": "wi-lower-52",
              "externalID": "ocd-person/4e1004c1-d397-4a50-baeb-2d3fbbd2912f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.o'connor@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2549",
                "https://legis.wisconsin.gov/assembly/52/oconnor/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-52-lower-ocd-person-4e1004c1-d397-4a50-baeb-2d3fbbd2912f": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/cbf82833-b48a-4189-9433-d4d33e273f83",
            "firstName": "Michael",
            "lastName": "Schraa",
            "fullName": "Michael Schraa",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2509.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-WI-53-lower-ocd-person-cbf82833-b48a-4189-9433-d4d33e273f83",
              "builtID": "wi-lower-53",
              "externalID": "ocd-person/cbf82833-b48a-4189-9433-d4d33e273f83",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.schraa@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1608",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1847",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2187",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2187",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2509",
                "https://legis.wisconsin.gov/assembly/53/schraa/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-53-lower-ocd-person-cbf82833-b48a-4189-9433-d4d33e273f83": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/aef40b88-72b7-4001-b336-9210a55a2bde",
            "firstName": "Lori Ann",
            "lastName": "Palmeri",
            "fullName": "Lori Palmeri",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2547.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-WI-54-lower-ocd-person-aef40b88-72b7-4001-b336-9210a55a2bde",
              "builtID": "wi-lower-54",
              "externalID": "ocd-person/aef40b88-72b7-4001-b336-9210a55a2bde",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.palmeri@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2547",
                "https://legis.wisconsin.gov/assembly/54/Palmeri/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-54-lower-ocd-person-aef40b88-72b7-4001-b336-9210a55a2bde": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/58b31138-e0a5-41ae-bd2f-5713e6c0cc0c",
            "firstName": "Nate L.",
            "lastName": "Gustafson",
            "fullName": "Gus Gustafson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2550.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-WI-55-lower-ocd-person-58b31138-e0a5-41ae-bd2f-5713e6c0cc0c",
              "builtID": "wi-lower-55",
              "externalID": "ocd-person/58b31138-e0a5-41ae-bd2f-5713e6c0cc0c",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.gustafson@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2550",
                "https://legis.wisconsin.gov/assembly/55/gustafson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-55-lower-ocd-person-58b31138-e0a5-41ae-bd2f-5713e6c0cc0c": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/1e2717f2-3426-47b6-8e86-a63f0a507c81",
            "firstName": "David",
            "lastName": "Murphy",
            "fullName": "Dave Murphy",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2488.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-WI-56-lower-ocd-person-1e2717f2-3426-47b6-8e86-a63f0a507c81",
              "builtID": "wi-lower-56",
              "externalID": "ocd-person/1e2717f2-3426-47b6-8e86-a63f0a507c81",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.murphy@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1588",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1850",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2165",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2165",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2488",
                "https://legis.wisconsin.gov/assembly/56/murphy"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-56-lower-ocd-person-1e2717f2-3426-47b6-8e86-a63f0a507c81": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/54a3a633-bff9-47f1-9951-ae3a82d012a8",
            "firstName": "Lee Alyson",
            "lastName": "Snodgrass",
            "fullName": "Lee Snodgrass",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2514.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-WI-57-lower-ocd-person-54a3a633-bff9-47f1-9951-ae3a82d012a8",
              "builtID": "wi-lower-57",
              "externalID": "ocd-person/54a3a633-bff9-47f1-9951-ae3a82d012a8",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.snodgrass@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2267",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2267",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2514",
                "https://legis.wisconsin.gov/assembly/57/snodgrass/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-57-lower-ocd-person-54a3a633-bff9-47f1-9951-ae3a82d012a8": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/264f7afa-69cb-46f3-97f3-ff8f21a1d68a",
            "firstName": "Rick",
            "lastName": "Gundrum",
            "fullName": "Rick Gundrum",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2467.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-WI-58-lower-ocd-person-264f7afa-69cb-46f3-97f3-ff8f21a1d68a",
              "builtID": "wi-lower-58",
              "externalID": "ocd-person/264f7afa-69cb-46f3-97f3-ff8f21a1d68a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.gundrum@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1780",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1852",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2141",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2141",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2467",
                "https://legis.wisconsin.gov/assembly/58/gundrum/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-58-lower-ocd-person-264f7afa-69cb-46f3-97f3-ff8f21a1d68a": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/cc464351-1e0f-4213-9fa4-d87aed85fb11",
            "firstName": "Ty Alan",
            "lastName": "Bodden",
            "fullName": "Ty Bodden",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2539.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-WI-59-lower-ocd-person-cc464351-1e0f-4213-9fa4-d87aed85fb11",
              "builtID": "wi-lower-59",
              "externalID": "ocd-person/cc464351-1e0f-4213-9fa4-d87aed85fb11",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.bodden@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2539"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-59-lower-ocd-person-cc464351-1e0f-4213-9fa4-d87aed85fb11": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/d8956a67-d280-4219-853f-c21ec173f80a",
            "firstName": "Robert A.",
            "lastName": "Brooks",
            "fullName": "Rob Brooks",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2452.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-WI-60-lower-ocd-person-d8956a67-d280-4219-853f-c21ec173f80a",
              "builtID": "wi-lower-60",
              "externalID": "ocd-person/d8956a67-d280-4219-853f-c21ec173f80a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.rob.brooks@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1545",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1854",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2128",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2128",
                "https://legis.wisconsin.gov/assembly/60/brooks",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2452"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-60-lower-ocd-person-d8956a67-d280-4219-853f-c21ec173f80a": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/5c70d5df-b7a6-471c-a766-05816ee009a5",
            "firstName": "Amanda Marie",
            "lastName": "Nedweski",
            "fullName": "Amanda Nedweski",
            "gender": "Female",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2551.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-WI-61-lower-ocd-person-5c70d5df-b7a6-471c-a766-05816ee009a5",
              "builtID": "wi-lower-61",
              "externalID": "ocd-person/5c70d5df-b7a6-471c-a766-05816ee009a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.nedweski@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2551",
                "https://legis.wisconsin.gov/assembly/61/Nedweski/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-61-lower-ocd-person-5c70d5df-b7a6-471c-a766-05816ee009a5": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/ea4d8d73-19b6-408f-a802-22b2ddf816d9",
            "firstName": "Robert Otto",
            "lastName": "Wittke",
            "fullName": "Robert Wittke",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2534.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-WI-62-lower-ocd-person-ea4d8d73-19b6-408f-a802-22b2ddf816d9",
              "builtID": "wi-lower-62",
              "externalID": "ocd-person/ea4d8d73-19b6-408f-a802-22b2ddf816d9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.wittke@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1936",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2214",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2214",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2534"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-62-lower-ocd-person-ea4d8d73-19b6-408f-a802-22b2ddf816d9": 0
        }
      },
      "63": {
        "members": [
          {
            "API_ID": "ocd-person/c8381cb6-1fcf-4a82-a6c5-5c60773a85e1",
            "firstName": "Robin Joseph",
            "lastName": "Vos",
            "fullName": "Robin Vos",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2531.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "63",
              "chamber": "lower",
              "hashID": "SLDL-WI-63-lower-ocd-person-c8381cb6-1fcf-4a82-a6c5-5c60773a85e1",
              "builtID": "wi-lower-63",
              "externalID": "ocd-person/c8381cb6-1fcf-4a82-a6c5-5c60773a85e1",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.vos@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1626",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1857",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2211",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2211",
                "https://legis.wisconsin.gov/assembly/63/vos",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2531",
                "https://legis.wisconsin.gov/assembly/63/vos/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-63-lower-ocd-person-c8381cb6-1fcf-4a82-a6c5-5c60773a85e1": 0
        }
      },
      "64": {
        "members": [
          {
            "API_ID": "ocd-person/c66ed13a-e5a7-49e0-9cce-02d677c5da15",
            "firstName": "Thaddeus",
            "lastName": "McGuire",
            "fullName": "Tip McGuire",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2483.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "64",
              "chamber": "lower",
              "hashID": "SLDL-WI-64-lower-ocd-person-c66ed13a-e5a7-49e0-9cce-02d677c5da15",
              "builtID": "wi-lower-64",
              "externalID": "ocd-person/c66ed13a-e5a7-49e0-9cce-02d677c5da15",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.mcguire@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/2075",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2162",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2162",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2483",
                "https://legis.wisconsin.gov/assembly/64/mcguire"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-64-lower-ocd-person-c66ed13a-e5a7-49e0-9cce-02d677c5da15": 0
        }
      },
      "65": {
        "members": [
          {
            "API_ID": "ocd-person/f4271f3a-606f-4cfa-b0b7-a5ce7fcc229a",
            "firstName": "Tod",
            "lastName": "Ohnstad",
            "fullName": "Tod Ohnstad",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2494.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "65",
              "chamber": "lower",
              "hashID": "SLDL-WI-65-lower-ocd-person-f4271f3a-606f-4cfa-b0b7-a5ce7fcc229a",
              "builtID": "wi-lower-65",
              "externalID": "ocd-person/f4271f3a-606f-4cfa-b0b7-a5ce7fcc229a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.ohnstad@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1595",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1859",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2172",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2172",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2494",
                "https://legis.wisconsin.gov/assembly/65/ohnstad"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-65-lower-ocd-person-f4271f3a-606f-4cfa-b0b7-a5ce7fcc229a": 0
        }
      },
      "66": {
        "members": [
          {
            "API_ID": "ocd-person/62f218bf-b308-4240-84b3-4be181466b42",
            "firstName": "Gretchen Stephens",
            "lastName": "Neubauer",
            "fullName": "Greta Neubauer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2491.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "66",
              "chamber": "lower",
              "hashID": "SLDL-WI-66-lower-ocd-person-62f218bf-b308-4240-84b3-4be181466b42",
              "builtID": "wi-lower-66",
              "externalID": "ocd-person/62f218bf-b308-4240-84b3-4be181466b42",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.neubauer@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1782",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1860",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2168",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2168",
                "https://legis.wisconsin.gov/assembly/66/neubauer",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2491",
                "https://legis.wisconsin.gov/assembly/66/neubauer/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-66-lower-ocd-person-62f218bf-b308-4240-84b3-4be181466b42": 0
        }
      },
      "67": {
        "members": [
          {
            "API_ID": "ocd-person/e23ff9c6-f6d5-4ada-8974-74c6ce90c422",
            "firstName": "Rob",
            "lastName": "Summerfield",
            "fullName": "Rob Summerfield",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2522.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "67",
              "chamber": "lower",
              "hashID": "SLDL-WI-67-lower-ocd-person-e23ff9c6-f6d5-4ada-8974-74c6ce90c422",
              "builtID": "wi-lower-67",
              "externalID": "ocd-person/e23ff9c6-f6d5-4ada-8974-74c6ce90c422",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.summerfield@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1639",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1861",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2201",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2201",
                "https://legis.wisconsin.gov/assembly/67/summerfield",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2522",
                "https://legis.wisconsin.gov/assembly/67/summerfield/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-67-lower-ocd-person-e23ff9c6-f6d5-4ada-8974-74c6ce90c422": 0
        }
      },
      "68": {
        "members": [
          {
            "API_ID": "ocd-person/1332581f-d860-43a0-b7d9-ddf619451efa",
            "firstName": "Karen Ruth",
            "lastName": "Hurd",
            "fullName": "Karen Hurd",
            "gender": "Female",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2552.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "68",
              "chamber": "lower",
              "hashID": "SLDL-WI-68-lower-ocd-person-1332581f-d860-43a0-b7d9-ddf619451efa",
              "builtID": "wi-lower-68",
              "externalID": "ocd-person/1332581f-d860-43a0-b7d9-ddf619451efa",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.hurd@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2552",
                "https://legis.wisconsin.gov/assembly/68/hurd/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-68-lower-ocd-person-1332581f-d860-43a0-b7d9-ddf619451efa": 0
        }
      },
      "69": {
        "members": [
          {
            "API_ID": "ocd-person/70930132-323e-42ef-ad9f-0338f2068de8",
            "firstName": "Donna Kay Mummau",
            "lastName": "Rozar",
            "fullName": "Donna Rozar",
            "gender": "Female",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2507.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "69",
              "chamber": "lower",
              "hashID": "SLDL-WI-69-lower-ocd-person-70930132-323e-42ef-ad9f-0338f2068de8",
              "builtID": "wi-lower-69",
              "externalID": "ocd-person/70930132-323e-42ef-ad9f-0338f2068de8",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.rozar@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2255",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2255",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2507",
                "https://legis.wisconsin.gov/assembly/69/rozar/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-69-lower-ocd-person-70930132-323e-42ef-ad9f-0338f2068de8": 0
        }
      },
      "70": {
        "members": [
          {
            "API_ID": "ocd-person/5858f406-f96a-4846-8a9e-0bede48d94ed",
            "firstName": "Nancy L.",
            "lastName": "VanderMeer",
            "fullName": "Nancy VanderMeer",
            "gender": "Female",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2529.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "70",
              "chamber": "lower",
              "hashID": "SLDL-WI-70-lower-ocd-person-5858f406-f96a-4846-8a9e-0bede48d94ed",
              "builtID": "wi-lower-70",
              "externalID": "ocd-person/5858f406-f96a-4846-8a9e-0bede48d94ed",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.vandermeer@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1624",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1864",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2208",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2208",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2529",
                "https://legis.wisconsin.gov/assembly/70/vandermeer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-70-lower-ocd-person-5858f406-f96a-4846-8a9e-0bede48d94ed": 0
        }
      },
      "71": {
        "members": [
          {
            "API_ID": "ocd-person/89b800b6-401d-4685-a58a-3faef8b3e079",
            "firstName": "Katrina",
            "lastName": "Shankland",
            "fullName": "Katrina Shankland",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2510.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "71",
              "chamber": "lower",
              "hashID": "SLDL-WI-71-lower-ocd-person-89b800b6-401d-4685-a58a-3faef8b3e079",
              "builtID": "wi-lower-71",
              "externalID": "ocd-person/89b800b6-401d-4685-a58a-3faef8b3e079",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.shankland@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1609",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1865",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2188",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2188",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2510",
                "https://legis.wisconsin.gov/assembly/71/shankland"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-71-lower-ocd-person-89b800b6-401d-4685-a58a-3faef8b3e079": 0
        }
      },
      "72": {
        "members": [
          {
            "API_ID": "ocd-person/77257644-eb9a-447d-bcd8-f3e3de7e49e4",
            "firstName": "Scott",
            "lastName": "Krug",
            "fullName": "Scott Krug",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2478.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "72",
              "chamber": "lower",
              "hashID": "SLDL-WI-72-lower-ocd-person-77257644-eb9a-447d-bcd8-f3e3de7e49e4",
              "builtID": "wi-lower-72",
              "externalID": "ocd-person/77257644-eb9a-447d-bcd8-f3e3de7e49e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.krug@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1579",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1866",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2155",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2155",
                "https://legis.wisconsin.gov/assembly/72/krug",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2478",
                "https://legis.wisconsin.gov/assembly/72/krug/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-72-lower-ocd-person-77257644-eb9a-447d-bcd8-f3e3de7e49e4": 0
        }
      },
      "73": {
        "members": [
          {
            "API_ID": "ocd-person/90927533-d9df-4b09-84fa-e49346b106d9",
            "firstName": "Angela Mary",
            "lastName": "Sapik",
            "fullName": "Angie Sapik",
            "gender": "Female",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2557.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "73",
              "chamber": "lower",
              "hashID": "SLDL-WI-73-lower-ocd-person-90927533-d9df-4b09-84fa-e49346b106d9",
              "builtID": "wi-lower-73",
              "externalID": "ocd-person/90927533-d9df-4b09-84fa-e49346b106d9",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.sapik@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2557",
                "https://legis.wisconsin.gov/assembly/73/sapik/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-73-lower-ocd-person-90927533-d9df-4b09-84fa-e49346b106d9": 0
        }
      },
      "74": {
        "members": [
          {
            "API_ID": "ocd-person/2a6503b4-d030-4569-b5b2-8fe62ff6132d",
            "firstName": "Chanz Jeffery",
            "lastName": "Green",
            "fullName": "Chanz Green",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2558.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "74",
              "chamber": "lower",
              "hashID": "SLDL-WI-74-lower-ocd-person-2a6503b4-d030-4569-b5b2-8fe62ff6132d",
              "builtID": "wi-lower-74",
              "externalID": "ocd-person/2a6503b4-d030-4569-b5b2-8fe62ff6132d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.green@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2558"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-74-lower-ocd-person-2a6503b4-d030-4569-b5b2-8fe62ff6132d": 0
        }
      },
      "75": {
        "members": [
          {
            "API_ID": "ocd-person/28caa8bd-605b-4755-85a0-52434a9be02f",
            "firstName": "David B.",
            "lastName": "Armstrong",
            "fullName": "Dave Armstrong",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2444.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "75",
              "chamber": "lower",
              "hashID": "SLDL-WI-75-lower-ocd-person-28caa8bd-605b-4755-85a0-52434a9be02f",
              "builtID": "wi-lower-75",
              "externalID": "ocd-person/28caa8bd-605b-4755-85a0-52434a9be02f",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.armstrong@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2268",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2268",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2444",
                "https://legis.wisconsin.gov/assembly/75/armstrong"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-75-lower-ocd-person-28caa8bd-605b-4755-85a0-52434a9be02f": 0
        }
      },
      "76": {
        "members": [
          {
            "API_ID": "ocd-person/17879a06-e9fc-4e11-88bf-3c684c460c07",
            "firstName": "Francesca",
            "lastName": "Hong",
            "fullName": "Francesca Hong",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2472.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "76",
              "chamber": "lower",
              "hashID": "SLDL-WI-76-lower-ocd-person-17879a06-e9fc-4e11-88bf-3c684c460c07",
              "builtID": "wi-lower-76",
              "externalID": "ocd-person/17879a06-e9fc-4e11-88bf-3c684c460c07",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.hong@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 122 North, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-237-9176",
              "fax1": null,
              "fax2": "608-282-3676",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2261",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2261",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2472",
                "https://legis.wisconsin.gov/assembly/76/hong/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-76-lower-ocd-person-17879a06-e9fc-4e11-88bf-3c684c460c07": 0
        }
      },
      "77": {
        "members": [
          {
            "API_ID": "ocd-person/5d04e8d6-f9aa-4c1e-b1d4-102a0caff933",
            "firstName": "Shelia",
            "lastName": "Stubbs",
            "fullName": "Shelia Stubbs",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2520.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "77",
              "chamber": "lower",
              "hashID": "SLDL-WI-77-lower-ocd-person-5d04e8d6-f9aa-4c1e-b1d4-102a0caff933",
              "builtID": "wi-lower-77",
              "externalID": "ocd-person/5d04e8d6-f9aa-4c1e-b1d4-102a0caff933",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.stubbs@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1931",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2198",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2198",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2520",
                "https://legis.wisconsin.gov/assembly/77/stubbs"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-77-lower-ocd-person-5d04e8d6-f9aa-4c1e-b1d4-102a0caff933": 0
        }
      },
      "78": {
        "members": [
          {
            "API_ID": "ocd-person/eed49381-7c5b-4890-bba3-d63b8681463d",
            "firstName": "Lisa",
            "lastName": "Subeck",
            "fullName": "Lisa Subeck",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2521.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "78",
              "chamber": "lower",
              "hashID": "SLDL-WI-78-lower-ocd-person-eed49381-7c5b-4890-bba3-d63b8681463d",
              "builtID": "wi-lower-78",
              "externalID": "ocd-person/eed49381-7c5b-4890-bba3-d63b8681463d",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.subeck@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1617",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1872",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2200",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2200",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2521",
                "https://legis.wisconsin.gov/assembly/78/subeck"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-78-lower-ocd-person-eed49381-7c5b-4890-bba3-d63b8681463d": 0
        }
      },
      "79": {
        "members": [
          {
            "API_ID": "ocd-person/1638f0f9-1d5a-4790-aee2-63cad736b137",
            "firstName": "Alex Robert",
            "lastName": "Joers",
            "fullName": "Alex Joers",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2553.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "79",
              "chamber": "lower",
              "hashID": "SLDL-WI-79-lower-ocd-person-1638f0f9-1d5a-4790-aee2-63cad736b137",
              "builtID": "wi-lower-79",
              "externalID": "ocd-person/1638f0f9-1d5a-4790-aee2-63cad736b137",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.joers@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2553",
                "https://legis.wisconsin.gov/assembly/79/joers/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-79-lower-ocd-person-1638f0f9-1d5a-4790-aee2-63cad736b137": 0
        }
      },
      "80": {
        "members": [
          {
            "API_ID": "ocd-person/1b0b0777-af08-40f2-a45b-3c2431b37722",
            "firstName": "Michael Andrew",
            "lastName": "Bare",
            "fullName": "Mike Bare",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2554.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "80",
              "chamber": "lower",
              "hashID": "SLDL-WI-80-lower-ocd-person-1b0b0777-af08-40f2-a45b-3c2431b37722",
              "builtID": "wi-lower-80",
              "externalID": "ocd-person/1b0b0777-af08-40f2-a45b-3c2431b37722",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.bare@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2554",
                "https://legis.wisconsin.gov/assembly/80/Bare/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-80-lower-ocd-person-1b0b0777-af08-40f2-a45b-3c2431b37722": 0
        }
      },
      "81": {
        "members": [
          {
            "API_ID": "ocd-person/3b668507-c98b-4b0b-8c8d-84f87de7ed24",
            "firstName": "Dave",
            "lastName": "Considine",
            "fullName": "Dave Considine",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2458.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "81",
              "chamber": "lower",
              "hashID": "SLDL-WI-81-lower-ocd-person-3b668507-c98b-4b0b-8c8d-84f87de7ed24",
              "builtID": "wi-lower-81",
              "externalID": "ocd-person/3b668507-c98b-4b0b-8c8d-84f87de7ed24",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.considine@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1547",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1875",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2131",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2131",
                "https://legis.wisconsin.gov/assembly/81/considine",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2458",
                "https://daveconsidine.com/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-81-lower-ocd-person-3b668507-c98b-4b0b-8c8d-84f87de7ed24": 0
        }
      },
      "82": {
        "members": [
          {
            "API_ID": "ocd-person/ef921fbc-cb1a-4208-a4ab-b2ea99114f0a",
            "firstName": "Chuck",
            "lastName": "Wichgers",
            "fullName": "Chuck Wichgers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2556.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "82",
              "chamber": "lower",
              "hashID": "SLDL-WI-82-lower-ocd-person-ef921fbc-cb1a-4208-a4ab-b2ea99114f0a",
              "builtID": "wi-lower-82",
              "externalID": "ocd-person/ef921fbc-cb1a-4208-a4ab-b2ea99114f0a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.wichgers@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1640",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1877",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2213",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2213",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2556",
                "https://legis.wisconsin.gov/assembly/82/wichgers/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-82-lower-ocd-person-ef921fbc-cb1a-4208-a4ab-b2ea99114f0a": 0
        }
      },
      "83": {
        "members": [
          {
            "API_ID": "ocd-person/c586d68e-fa48-46d6-8782-ce41bd690254",
            "firstName": "Nikalaus P.",
            "lastName": "Rettinger",
            "fullName": "Nik Rettinger",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2540.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "83",
              "chamber": "lower",
              "hashID": "SLDL-WI-83-lower-ocd-person-c586d68e-fa48-46d6-8782-ce41bd690254",
              "builtID": "wi-lower-83",
              "externalID": "ocd-person/c586d68e-fa48-46d6-8782-ce41bd690254",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.rettinger@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2540",
                "https://legis.wisconsin.gov/assembly/83/rettinger/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-83-lower-ocd-person-c586d68e-fa48-46d6-8782-ce41bd690254": 0
        }
      },
      "84": {
        "members": [
          {
            "API_ID": "ocd-person/54522ac6-81ad-4b83-929f-b94e94b8acb4",
            "firstName": "Robert G.",
            "lastName": "Donovan",
            "fullName": "Bob Donovan",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2555.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "84",
              "chamber": "lower",
              "hashID": "SLDL-WI-84-lower-ocd-person-54522ac6-81ad-4b83-929f-b94e94b8acb4",
              "builtID": "wi-lower-84",
              "externalID": "ocd-person/54522ac6-81ad-4b83-929f-b94e94b8acb4",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.donovan@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2555"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-84-lower-ocd-person-54522ac6-81ad-4b83-929f-b94e94b8acb4": 0
        }
      },
      "85": {
        "members": [
          {
            "API_ID": "ocd-person/38649f7a-eba4-4908-b264-f95fe908fc7a",
            "firstName": "Patrick J.",
            "lastName": "Snyder",
            "fullName": "Pat Snyder",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2515.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "85",
              "chamber": "lower",
              "hashID": "SLDL-WI-85-lower-ocd-person-38649f7a-eba4-4908-b264-f95fe908fc7a",
              "builtID": "wi-lower-85",
              "externalID": "ocd-person/38649f7a-eba4-4908-b264-f95fe908fc7a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.snyder@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1641",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1879",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2191",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2191",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2515",
                "https://legis.wisconsin.gov/assembly/85/snyder"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-85-lower-ocd-person-38649f7a-eba4-4908-b264-f95fe908fc7a": 0
        }
      },
      "86": {
        "members": [
          {
            "API_ID": "ocd-person/38070b4e-d50c-4bc1-86ad-6739c5edc60a",
            "firstName": "John",
            "lastName": "Spiros",
            "fullName": "John Spiros",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2517.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "86",
              "chamber": "lower",
              "hashID": "SLDL-WI-86-lower-ocd-person-38070b4e-d50c-4bc1-86ad-6739c5edc60a",
              "builtID": "wi-lower-86",
              "externalID": "ocd-person/38070b4e-d50c-4bc1-86ad-6739c5edc60a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.spiros@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1612",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1880",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2193",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2193",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2517",
                "https://legis.wisconsin.gov/assembly/86/spiros/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-86-lower-ocd-person-38070b4e-d50c-4bc1-86ad-6739c5edc60a": 0
        }
      },
      "87": {
        "members": [
          {
            "API_ID": "ocd-person/6821a69d-15a7-48ea-85d5-35b9e2d09ae0",
            "firstName": "James W.",
            "lastName": "Edming",
            "fullName": "James Edming",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2464.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "87",
              "chamber": "lower",
              "hashID": "SLDL-WI-87-lower-ocd-person-6821a69d-15a7-48ea-85d5-35b9e2d09ae0",
              "builtID": "wi-lower-87",
              "externalID": "ocd-person/6821a69d-15a7-48ea-85d5-35b9e2d09ae0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.edming@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1553",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1881",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2135",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2135",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2464",
                "https://legis.wisconsin.gov/assembly/87/edming"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-87-lower-ocd-person-6821a69d-15a7-48ea-85d5-35b9e2d09ae0": 0
        }
      },
      "88": {
        "members": [
          {
            "API_ID": "ocd-person/e3db9597-e362-4588-a6af-251a09b1ad03",
            "firstName": "John Joseph",
            "lastName": "Macco",
            "fullName": "John Macco",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2481.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "88",
              "chamber": "lower",
              "hashID": "SLDL-WI-88-lower-ocd-person-e3db9597-e362-4588-a6af-251a09b1ad03",
              "builtID": "wi-lower-88",
              "externalID": "ocd-person/e3db9597-e362-4588-a6af-251a09b1ad03",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.macco@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1584",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1882",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2160",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2160",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2481",
                "https://legis.wisconsin.gov/assembly/88/macco/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-88-lower-ocd-person-e3db9597-e362-4588-a6af-251a09b1ad03": 0
        }
      },
      "89": {
        "members": [
          {
            "API_ID": "ocd-person/de5dfe78-e3a2-4a58-a7f1-bb184bfd1ade",
            "firstName": "Elijah R.",
            "lastName": "Behnke",
            "fullName": "Elijah Behnke",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2447.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "89",
              "chamber": "lower",
              "hashID": "SLDL-WI-89-lower-ocd-person-de5dfe78-e3a2-4a58-a7f1-bb184bfd1ade",
              "builtID": "wi-lower-89",
              "externalID": "ocd-person/de5dfe78-e3a2-4a58-a7f1-bb184bfd1ade",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.behnke@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2396",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2396",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2447",
                "https://legis.wisconsin.gov/assembly/89/behnke/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-89-lower-ocd-person-de5dfe78-e3a2-4a58-a7f1-bb184bfd1ade": 0
        }
      },
      "90": {
        "members": [
          {
            "API_ID": "ocd-person/9951c48c-3c21-4c73-94b2-4d9da8edd6f7",
            "firstName": "Kristina Marie",
            "lastName": "Shelton",
            "fullName": "Kristina Shelton",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2511.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "90",
              "chamber": "lower",
              "hashID": "SLDL-WI-90-lower-ocd-person-9951c48c-3c21-4c73-94b2-4d9da8edd6f7",
              "builtID": "wi-lower-90",
              "externalID": "ocd-person/9951c48c-3c21-4c73-94b2-4d9da8edd6f7",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.shelton@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2258",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2258",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2511",
                "https://legis.wisconsin.gov/assembly/90/shelton/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-90-lower-ocd-person-9951c48c-3c21-4c73-94b2-4d9da8edd6f7": 0
        }
      },
      "91": {
        "members": [
          {
            "API_ID": "ocd-person/71d596fa-95f2-4411-9b7c-9e250380b701",
            "firstName": "Jodi",
            "lastName": "Emerson",
            "fullName": "Jodi Emerson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2465.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "91",
              "chamber": "lower",
              "hashID": "SLDL-WI-91-lower-ocd-person-71d596fa-95f2-4411-9b7c-9e250380b701",
              "builtID": "wi-lower-91",
              "externalID": "ocd-person/71d596fa-95f2-4411-9b7c-9e250380b701",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.emerson@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1939",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2136",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2136",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2465",
                "https://legis.wisconsin.gov/assembly/91/emerson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-91-lower-ocd-person-71d596fa-95f2-4411-9b7c-9e250380b701": 0
        }
      },
      "92": {
        "members": [
          {
            "API_ID": "ocd-person/0ea516ae-e0f5-4d3e-baee-124e0b00d70e",
            "firstName": "Treig E.",
            "lastName": "Pronschinske",
            "fullName": "Treig Pronschinske",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2502.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "92",
              "chamber": "lower",
              "hashID": "SLDL-WI-92-lower-ocd-person-0ea516ae-e0f5-4d3e-baee-124e0b00d70e",
              "builtID": "wi-lower-92",
              "externalID": "ocd-person/0ea516ae-e0f5-4d3e-baee-124e0b00d70e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.pronschinske@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1642",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1886",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2179",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2179",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2502",
                "https://legis.wisconsin.gov/assembly/92/Pronschinske"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-92-lower-ocd-person-0ea516ae-e0f5-4d3e-baee-124e0b00d70e": 0
        }
      },
      "93": {
        "members": [
          {
            "API_ID": "ocd-person/bc4ea771-c3d7-40ee-84e0-f160ae541b2a",
            "firstName": "Warren",
            "lastName": "Petryk",
            "fullName": "Warren Petryk",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2499.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "93",
              "chamber": "lower",
              "hashID": "SLDL-WI-93-lower-ocd-person-bc4ea771-c3d7-40ee-84e0-f160ae541b2a",
              "builtID": "wi-lower-93",
              "externalID": "ocd-person/bc4ea771-c3d7-40ee-84e0-f160ae541b2a",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.petryk@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1599",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1887",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2176",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2176",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2499",
                "https://legis.wisconsin.gov/assembly/93/petryk/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-93-lower-ocd-person-bc4ea771-c3d7-40ee-84e0-f160ae541b2a": 0
        }
      },
      "94": {
        "members": [
          {
            "API_ID": "ocd-person/b3064a65-976e-4324-a66d-dfd44a0f0e3e",
            "firstName": "Steve",
            "lastName": "Doyle",
            "fullName": "Steve Doyle",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2461.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "94",
              "chamber": "lower",
              "hashID": "SLDL-WI-94-lower-ocd-person-b3064a65-976e-4324-a66d-dfd44a0f0e3e",
              "builtID": "wi-lower-94",
              "externalID": "ocd-person/b3064a65-976e-4324-a66d-dfd44a0f0e3e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.doyle@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1551",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1888",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2133",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2133",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2461",
                "https://legis.wisconsin.gov/assembly/94/doyle"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-94-lower-ocd-person-b3064a65-976e-4324-a66d-dfd44a0f0e3e": 0
        }
      },
      "95": {
        "members": [
          {
            "API_ID": "ocd-person/7da63875-0a3c-4b03-aaa8-fe48c718eb45",
            "firstName": "Jill Elizabeth",
            "lastName": "Billings",
            "fullName": "Jill Billings",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2448.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "95",
              "chamber": "lower",
              "hashID": "SLDL-WI-95-lower-ocd-person-7da63875-0a3c-4b03-aaa8-fe48c718eb45",
              "builtID": "wi-lower-95",
              "externalID": "ocd-person/7da63875-0a3c-4b03-aaa8-fe48c718eb45",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.billings@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1540",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1889",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2124",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2124",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2448",
                "https://legis.wisconsin.gov/assembly/95/billings"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-95-lower-ocd-person-7da63875-0a3c-4b03-aaa8-fe48c718eb45": 0
        }
      },
      "96": {
        "members": [
          {
            "API_ID": "ocd-person/bd77826c-edea-48d3-b6a6-b123a5fd4c4b",
            "firstName": "Loren",
            "lastName": "Oldenburg",
            "fullName": "Loren Oldenburg",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2495.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "96",
              "chamber": "lower",
              "hashID": "SLDL-WI-96-lower-ocd-person-bd77826c-edea-48d3-b6a6-b123a5fd4c4b",
              "builtID": "wi-lower-96",
              "externalID": "ocd-person/bd77826c-edea-48d3-b6a6-b123a5fd4c4b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.oldenburg@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1940",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2173",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2173",
                "https://legis.wisconsin.gov/assembly/96/Oldenburg/",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2495"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-96-lower-ocd-person-bd77826c-edea-48d3-b6a6-b123a5fd4c4b": 0
        }
      },
      "97": {
        "members": [
          {
            "API_ID": "ocd-person/06b28490-4fb0-43fe-9577-60bdb921164b",
            "firstName": "Scott",
            "lastName": "Allen",
            "fullName": "Scott Allen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2441.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "97",
              "chamber": "lower",
              "hashID": "SLDL-WI-97-lower-ocd-person-06b28490-4fb0-43fe-9577-60bdb921164b",
              "builtID": "wi-lower-97",
              "externalID": "ocd-person/06b28490-4fb0-43fe-9577-60bdb921164b",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.allen@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1533",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1891",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2120",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2120",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2441",
                "https://legis.wisconsin.gov/assembly/97/allen/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-97-lower-ocd-person-06b28490-4fb0-43fe-9577-60bdb921164b": 0
        }
      },
      "98": {
        "members": [
          {
            "API_ID": "ocd-person/22d8a510-3b36-432a-9d3d-08efe7e74d5e",
            "firstName": "Adam Thomas",
            "lastName": "Neylon",
            "fullName": "Adam Neylon",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2492.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "98",
              "chamber": "lower",
              "hashID": "SLDL-WI-98-lower-ocd-person-22d8a510-3b36-432a-9d3d-08efe7e74d5e",
              "builtID": "wi-lower-98",
              "externalID": "ocd-person/22d8a510-3b36-432a-9d3d-08efe7e74d5e",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.neylon@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1592",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1892",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2169",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2169",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2492",
                "https://legis.wisconsin.gov/assembly/98/neylon"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-98-lower-ocd-person-22d8a510-3b36-432a-9d3d-08efe7e74d5e": 0
        }
      },
      "99": {
        "members": [
          {
            "API_ID": "ocd-person/171aec6e-ba95-4244-9854-84cddf028d90",
            "firstName": "Cindi S.",
            "lastName": "Duchow",
            "fullName": "Cindi Duchow",
            "gender": "Female",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2463.jpg",
            "title": "WI Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "99",
              "chamber": "lower",
              "hashID": "SLDL-WI-99-lower-ocd-person-171aec6e-ba95-4244-9854-84cddf028d90",
              "builtID": "wi-lower-99",
              "externalID": "ocd-person/171aec6e-ba95-4244-9854-84cddf028d90",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.duchow@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1552",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1893",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2134",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2134",
                "https://legis.wisconsin.gov/assembly/99/Duchow",
                "https://docs.legis.wisconsin.gov/2023/legislators/assembly/2463",
                "https://legis.wisconsin.gov/assembly/99/Duchow/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WI-99-lower-ocd-person-171aec6e-ba95-4244-9854-84cddf028d90": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/b0101cd0-ad49-466b-a46e-8bb8671cb709",
            "firstName": "Andre",
            "lastName": "Jacque",
            "fullName": "André Jacque",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2419.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-WI-1-upper-ocd-person-b0101cd0-ad49-466b-a46e-8bb8671cb709",
              "builtID": "wi-upper-1",
              "externalID": "ocd-person/b0101cd0-ad49-466b-a46e-8bb8671cb709",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.jacque@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 7 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-3512",
              "fax1": null,
              "fax2": "608-282-3541",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2019/legislators/senate/1941",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2099",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2099",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2419",
                "https://legis.wisconsin.gov/senate/01/jacque"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-1-upper-ocd-person-b0101cd0-ad49-466b-a46e-8bb8671cb709": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/e43a45e3-6a0d-49a8-971d-ae0ef30a3201",
            "firstName": "Robert L.",
            "lastName": "Cowles",
            "fullName": "Rob Cowles",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2414.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-WI-2-upper-ocd-person-e43a45e3-6a0d-49a8-971d-ae0ef30a3201",
              "builtID": "wi-upper-2",
              "externalID": "ocd-person/e43a45e3-6a0d-49a8-971d-ae0ef30a3201",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.cowles@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 118 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-0484",
              "fax1": null,
              "fax2": "608-282-3542",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.wisconsin.gov/Pages/leg-info.aspx?d=2&h=S",
                "https://docs.legis.wisconsin.gov/2017/legislators/senate/1502",
                "https://docs.legis.wisconsin.gov/2019/legislators/senate/1896",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2092",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2092",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2414",
                "https://legis.wisconsin.gov/senate/02/cowles"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-2-upper-ocd-person-e43a45e3-6a0d-49a8-971d-ae0ef30a3201": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/5afcecdf-8000-4e54-bcf2-329f919ed0bd",
            "firstName": "Timothy W.",
            "lastName": "Carpenter",
            "fullName": "Tim Carpenter",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2413.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-WI-3-upper-ocd-person-5afcecdf-8000-4e54-bcf2-329f919ed0bd",
              "builtID": "wi-upper-3",
              "externalID": "ocd-person/5afcecdf-8000-4e54-bcf2-329f919ed0bd",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.carpenter@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 109 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-8535",
              "fax1": null,
              "fax2": "608-282-3543",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/senate/1501",
                "https://docs.legis.wisconsin.gov/2019/legislators/senate/1895",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2091",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2091",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2413",
                "https://legis.wisconsin.gov/senate/03/carpenter/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-3-upper-ocd-person-5afcecdf-8000-4e54-bcf2-329f919ed0bd": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/49d245a5-ad19-46cf-9857-581573c0b99b",
            "firstName": "Rob",
            "lastName": "Hutton",
            "fullName": "Rob Hutton",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2560.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-WI-5-upper-ocd-person-49d245a5-ad19-46cf-9857-581573c0b99b",
              "builtID": "wi-upper-5",
              "externalID": "ocd-person/49d245a5-ad19-46cf-9857-581573c0b99b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.hutton@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 313 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-2512",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1562",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1807",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2560",
                "https://legis.wisconsin.gov/senate/05/hutton/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-5-upper-ocd-person-49d245a5-ad19-46cf-9857-581573c0b99b": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/eba6f26b-65c9-42fe-a8a5-09e11c8d089a",
            "firstName": "LaTonya",
            "lastName": "Johnson",
            "fullName": "LaTonya Johnson",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2421.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-WI-6-upper-ocd-person-eba6f26b-65c9-42fe-a8a5-09e11c8d089a",
              "builtID": "wi-upper-6",
              "externalID": "ocd-person/eba6f26b-65c9-42fe-a8a5-09e11c8d089a",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.johnson@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 106 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-2500",
              "fax1": null,
              "fax2": "608-282-3546",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/senate/1643",
                "https://docs.legis.wisconsin.gov/2019/legislators/senate/1904",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2100",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2100",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2421",
                "https://legis.wisconsin.gov/senate/06/johnson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-6-upper-ocd-person-eba6f26b-65c9-42fe-a8a5-09e11c8d089a": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/48d90383-6c55-47c7-979c-2b95a806ec4c",
            "firstName": "Christopher James",
            "lastName": "Larson",
            "fullName": "Chris Larson",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2424.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-WI-7-upper-ocd-person-48d90383-6c55-47c7-979c-2b95a806ec4c",
              "builtID": "wi-upper-7",
              "externalID": "ocd-person/48d90383-6c55-47c7-979c-2b95a806ec4c",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.larson@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 20 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-7505",
              "fax1": null,
              "fax2": "608-282-3547",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/senate/1511",
                "https://docs.legis.wisconsin.gov/2019/legislators/senate/1906",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2103",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2103",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2424",
                "https://legis.wisconsin.gov/senate/07/Larson/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-7-upper-ocd-person-48d90383-6c55-47c7-979c-2b95a806ec4c": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/1152309b-c82f-4660-bd5d-3e16322f1200",
            "firstName": "Daniel Raymond",
            "lastName": "Knodl",
            "fullName": "Dan Knodl",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2688.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-WI-8-upper-ocd-person-1152309b-c82f-4660-bd5d-3e16322f1200",
              "builtID": "wi-upper-8",
              "externalID": "ocd-person/1152309b-c82f-4660-bd5d-3e16322f1200",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.knodl@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 108 South, P.O. Box 7882, Madison, WI 53707",
              "phone1": null,
              "phone2": "608-266-5830",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1574",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1818",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2153",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2153",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2688",
                "https://legis.wisconsin.gov/senate/08/knodl/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-8-upper-ocd-person-1152309b-c82f-4660-bd5d-3e16322f1200": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/be7a6f06-6d2c-49d1-908d-451adef564eb",
            "firstName": "Devin",
            "lastName": "LeMahieu",
            "fullName": "Devin LeMahieu",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2425.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-WI-9-upper-ocd-person-be7a6f06-6d2c-49d1-908d-451adef564eb",
              "builtID": "wi-upper-9",
              "externalID": "ocd-person/be7a6f06-6d2c-49d1-908d-451adef564eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.lemahieu@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 211 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-2056",
              "fax1": null,
              "fax2": "608-282-3549",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/senate/1515",
                "https://docs.legis.wisconsin.gov/2019/legislators/senate/1907",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2104",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2104",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2425",
                "https://legis.wisconsin.gov/senate/09/LeMahieu/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-9-upper-ocd-person-be7a6f06-6d2c-49d1-908d-451adef564eb": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/a1407176-afc6-45ce-ad6c-d7f69b5b3b78",
            "firstName": "Robert R.",
            "lastName": "Stafsholt",
            "fullName": "Rob Stafsholt",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2434.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-WI-10-upper-ocd-person-a1407176-afc6-45ce-ad6c-d7f69b5b3b78",
              "builtID": "wi-upper-10",
              "externalID": "ocd-person/a1407176-afc6-45ce-ad6c-d7f69b5b3b78",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.stafsholt@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 15 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-7745",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1635",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1823",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2248",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2248",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2434",
                "https://legis.wisconsin.gov/senate/10/stafsholt/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-10-upper-ocd-person-a1407176-afc6-45ce-ad6c-d7f69b5b3b78": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/0301a2cd-37fc-46f6-948a-f1fade57b5c0",
            "firstName": "Stephen L.",
            "lastName": "Nass",
            "fullName": "Steve Nass",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2427.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-WI-11-upper-ocd-person-0301a2cd-37fc-46f6-948a-f1fade57b5c0",
              "builtID": "wi-upper-11",
              "externalID": "ocd-person/0301a2cd-37fc-46f6-948a-f1fade57b5c0",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.nass@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 10 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-2635",
              "fax1": null,
              "fax2": "608-282-3551",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.wisconsin.gov/Pages/leg-info.aspx?d=33&h=A",
                "https://docs.legis.wisconsin.gov/2017/legislators/senate/1519",
                "https://docs.legis.wisconsin.gov/2019/legislators/senate/1911",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2107",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2107",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2427",
                "https://legis.wisconsin.gov/senate/11/nass"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-11-upper-ocd-person-0301a2cd-37fc-46f6-948a-f1fade57b5c0": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/c67b7b29-0932-4667-bb92-63f46cdfaef0",
            "firstName": "Mary Czaja",
            "lastName": "Felzkowski",
            "fullName": "Mary Felzkowski",
            "gender": "Female",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2417.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-WI-12-upper-ocd-person-c67b7b29-0932-4667-bb92-63f46cdfaef0",
              "builtID": "wi-upper-12",
              "externalID": "ocd-person/c67b7b29-0932-4667-bb92-63f46cdfaef0",
              "geometry": null
            },
            "contactInfo": {
              "email": "rep.felzkowski@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 415 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-2509",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1549",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1829",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2249",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2249",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2417",
                "https://legis.wisconsin.gov/senate/12/felzkowski"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-12-upper-ocd-person-c67b7b29-0932-4667-bb92-63f46cdfaef0": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/13728fa5-9484-4767-b642-8bf9adf80a8b",
            "firstName": "John",
            "lastName": "Jagler",
            "fullName": "John Jagler",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2420.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-WI-13-upper-ocd-person-13728fa5-9484-4767-b642-8bf9adf80a8b",
              "builtID": "wi-upper-13",
              "externalID": "ocd-person/13728fa5-9484-4767-b642-8bf9adf80a8b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.jagler@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 131 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-5660",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1564",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1831",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2148",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2397",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2420"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-13-upper-ocd-person-13728fa5-9484-4767-b642-8bf9adf80a8b": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/3092ab11-6aa8-44af-a204-3719c6f0e4b5",
            "firstName": "Joan",
            "lastName": "Ballweg",
            "fullName": "Joan Ballweg",
            "gender": "Female",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2409.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-WI-14-upper-ocd-person-3092ab11-6aa8-44af-a204-3719c6f0e4b5",
              "builtID": "wi-upper-14",
              "externalID": "ocd-person/3092ab11-6aa8-44af-a204-3719c6f0e4b5",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.ballweg@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 409 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-0751",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1535",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1835",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2250",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2250",
                "https://legis.wisconsin.gov/senate/14/ballweg",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2409",
                "https://legis.wisconsin.gov/senate/14/ballweg/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-14-upper-ocd-person-3092ab11-6aa8-44af-a204-3719c6f0e4b5": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/3bd551f3-a086-4f32-9a6e-31d04d0529ce",
            "firstName": "Mark E.",
            "lastName": "Spreitzer",
            "fullName": "Mark Spreitzer",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2561.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-WI-15-upper-ocd-person-3bd551f3-a086-4f32-9a6e-31d04d0529ce",
              "builtID": "wi-upper-15",
              "externalID": "ocd-person/3bd551f3-a086-4f32-9a6e-31d04d0529ce",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.spreitzer@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 126 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-2253",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1613",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1839",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2194",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2194",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2561",
                "https://legis.wisconsin.gov/senate/15/spreitzer"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-15-upper-ocd-person-3bd551f3-a086-4f32-9a6e-31d04d0529ce": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/83cddc19-9686-4ff8-ab25-c006b2148c7b",
            "firstName": "Melissa Kristen",
            "lastName": "Agard",
            "fullName": "Melissa Agard",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2408.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-WI-16-upper-ocd-person-83cddc19-9686-4ff8-ab25-c006b2148c7b",
              "builtID": "wi-upper-16",
              "externalID": "ocd-person/83cddc19-9686-4ff8-ab25-c006b2148c7b",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.agard@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 3 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-9170",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2251",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2251",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2408",
                "https://legis.wisconsin.gov/senate/16/agard"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-16-upper-ocd-person-83cddc19-9686-4ff8-ab25-c006b2148c7b": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/c405ad68-fdbd-43fe-abc2-f449f33c89b0",
            "firstName": "Howard L.",
            "lastName": "Marklein",
            "fullName": "Howard Marklein",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2426.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-WI-17-upper-ocd-person-c405ad68-fdbd-43fe-abc2-f449f33c89b0",
              "builtID": "wi-upper-17",
              "externalID": "ocd-person/c405ad68-fdbd-43fe-abc2-f449f33c89b0",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.marklein@legis.wi.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 316 East, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-0703",
              "fax1": null,
              "fax2": "608-282-3557",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.wisconsin.gov/Pages/leg-info.aspx?d=51&h=A",
                "https://docs.legis.wisconsin.gov/2017/legislators/senate/1516",
                "https://docs.legis.wisconsin.gov/2019/legislators/senate/1908",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2105",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2105",
                "https://legis.wisconsin.gov/senate/17/marklein",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2426",
                "https://legis.wisconsin.gov/senate/17/marklein/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-17-upper-ocd-person-c405ad68-fdbd-43fe-abc2-f449f33c89b0": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/a0284290-ef4c-4f10-a5be-485115057ab5",
            "firstName": "Dan",
            "lastName": "Feyen",
            "fullName": "Dan Feyen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2418.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-WI-18-upper-ocd-person-a0284290-ef4c-4f10-a5be-485115057ab5",
              "builtID": "wi-upper-18",
              "externalID": "ocd-person/a0284290-ef4c-4f10-a5be-485115057ab5",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.feyen@legis.wi.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 306 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-5300",
              "fax1": null,
              "fax2": "608-282-3558",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/senate/1644",
                "https://docs.legis.wisconsin.gov/2019/legislators/senate/1900",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2096",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2096",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2418",
                "https://legis.wisconsin.gov/senate/18/feyen/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-18-upper-ocd-person-a0284290-ef4c-4f10-a5be-485115057ab5": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/5fec547c-aff0-4c6c-880b-bc0f52d28e30",
            "firstName": "Rachael",
            "lastName": "Cabral-Guevara",
            "fullName": "Rachael Cabral-Guevara",
            "gender": "Female",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2562.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-WI-19-upper-ocd-person-5fec547c-aff0-4c6c-880b-bc0f52d28e30",
              "builtID": "wi-upper-19",
              "externalID": "ocd-person/5fec547c-aff0-4c6c-880b-bc0f52d28e30",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.cabral-guevara@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 323 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-0718",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2254",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2254",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2562",
                "https://legis.wisconsin.gov/senate/19/cabral-guevara/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-19-upper-ocd-person-5fec547c-aff0-4c6c-880b-bc0f52d28e30": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/e5e13795-0170-405f-95f3-407a483d5967",
            "firstName": "Sherburn Duane",
            "lastName": "Stroebel",
            "fullName": "Duey Stroebel",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2435.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-WI-20-upper-ocd-person-e5e13795-0170-405f-95f3-407a483d5967",
              "builtID": "wi-upper-20",
              "externalID": "ocd-person/e5e13795-0170-405f-95f3-407a483d5967",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.stroebel@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 18 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-7513",
              "fax1": null,
              "fax2": "608-282-3560",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/senate/1526",
                "https://docs.legis.wisconsin.gov/2019/legislators/senate/1919",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2115",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2115",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2435",
                "https://legis.wisconsin.gov/senate/20/stroebel"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-20-upper-ocd-person-e5e13795-0170-405f-95f3-407a483d5967": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/b2ad4345-9408-4238-a1f9-13e747f599b3",
            "firstName": "Van H.",
            "lastName": "Wanggaard",
            "fullName": "Van Wanggaard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2438.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-WI-21-upper-ocd-person-b2ad4345-9408-4238-a1f9-13e747f599b3",
              "builtID": "wi-upper-21",
              "externalID": "ocd-person/b2ad4345-9408-4238-a1f9-13e747f599b3",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.wanggaard@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 122 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-1832",
              "fax1": null,
              "fax2": "608-282-3561",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.wi.gov/w3asp/contact/legislatorpages.aspx?house=Senate&district=21",
                "https://docs.legis.wisconsin.gov/2017/legislators/senate/1531",
                "https://docs.legis.wisconsin.gov/2019/legislators/senate/1925",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2118",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2118",
                "https://legis.wisconsin.gov/senate/21/wanggaard",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2438",
                "https://legis.wisconsin.gov/senate/21/wanggaard/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-21-upper-ocd-person-b2ad4345-9408-4238-a1f9-13e747f599b3": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/dc69e926-a090-4561-bb0f-2f81ec0dc977",
            "firstName": "Robert W.",
            "lastName": "Wirch",
            "fullName": "Bob Wirch",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2440.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-WI-22-upper-ocd-person-dc69e926-a090-4561-bb0f-2f81ec0dc977",
              "builtID": "wi-upper-22",
              "externalID": "ocd-person/dc69e926-a090-4561-bb0f-2f81ec0dc977",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.wirch@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 127 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-267-8979",
              "fax1": null,
              "fax2": "608-282-3562",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://legis.wisconsin.gov/Pages/leg-info.aspx?d=22&h=S",
                "https://docs.legis.wisconsin.gov/2017/legislators/senate/1532",
                "https://docs.legis.wisconsin.gov/2019/legislators/senate/1926",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2119",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2119",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2440",
                "https://legis.wisconsin.gov/senate/22/wirch"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-22-upper-ocd-person-dc69e926-a090-4561-bb0f-2f81ec0dc977": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/79d7550b-bb75-453b-bfd7-3f273debe3e4",
            "firstName": "Jesse",
            "lastName": "James",
            "fullName": "Jesse James",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2563.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-WI-23-upper-ocd-person-79d7550b-bb75-453b-bfd7-3f273debe3e4",
              "builtID": "wi-upper-23",
              "externalID": "ocd-person/79d7550b-bb75-453b-bfd7-3f273debe3e4",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.james@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 319 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-7511",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1937",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2149",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2149",
                "https://legis.wisconsin.gov/senate/23/james/",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2563"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-23-upper-ocd-person-79d7550b-bb75-453b-bfd7-3f273debe3e4": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/13416c83-b50d-4578-b721-83ff5c1486ad",
            "firstName": "Patrick",
            "lastName": "Testin",
            "fullName": "Patrick Testin",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2437.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-WI-24-upper-ocd-person-13416c83-b50d-4578-b721-83ff5c1486ad",
              "builtID": "wi-upper-24",
              "externalID": "ocd-person/13416c83-b50d-4578-b721-83ff5c1486ad",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.testin@legis.wi.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 8 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-3123",
              "fax1": null,
              "fax2": "608-282-3564",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/senate/1645",
                "https://docs.legis.wisconsin.gov/2019/legislators/senate/1921",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2117",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2117",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2437",
                "https://legis.wisconsin.gov/senate/24/testin"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-24-upper-ocd-person-13416c83-b50d-4578-b721-83ff5c1486ad": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/003644e8-cb82-45c6-b875-e52bc28424bb",
            "firstName": "Romaine Robert",
            "lastName": "Quinn",
            "fullName": "Romaine Quinn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2564.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-WI-25-upper-ocd-person-003644e8-cb82-45c6-b875-e52bc28424bb",
              "builtID": "wi-upper-25",
              "externalID": "ocd-person/003644e8-cb82-45c6-b875-e52bc28424bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.quinn@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 123 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-3510",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1601",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1869",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2564",
                "https://legis.wisconsin.gov/senate/25/quinn/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-25-upper-ocd-person-003644e8-cb82-45c6-b875-e52bc28424bb": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/967afe3c-119d-4051-a8b1-674f328c4775",
            "firstName": "Kelda Helen",
            "lastName": "Roys",
            "fullName": "Kelda Roys",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2432.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-WI-26-upper-ocd-person-967afe3c-119d-4051-a8b1-674f328c4775",
              "builtID": "wi-upper-26",
              "externalID": "ocd-person/967afe3c-119d-4051-a8b1-674f328c4775",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.roys@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 130 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-1627",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2252",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2252",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2432",
                "https://legis.wisconsin.gov/senate/26/roys/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-26-upper-ocd-person-967afe3c-119d-4051-a8b1-674f328c4775": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/57a316e3-b233-4460-abf5-193dd042291f",
            "firstName": "Dianne",
            "lastName": "Hesselbein",
            "fullName": "Dianne Hesselbein",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2566.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-WI-27-upper-ocd-person-57a316e3-b233-4460-abf5-193dd042291f",
              "builtID": "wi-upper-27",
              "externalID": "ocd-person/57a316e3-b233-4460-abf5-193dd042291f",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.hesselbein@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 206 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-6670",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/assembly/1559",
                "https://docs.legis.wisconsin.gov/2019/legislators/assembly/1873",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2144",
                "https://docs.legis.wisconsin.gov/2021/legislators/assembly/2144",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2566",
                "https://legis.wisconsin.gov/senate/27/hesselbein/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-27-upper-ocd-person-57a316e3-b233-4460-abf5-193dd042291f": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/91a901ca-e295-4843-b368-e9da162c0ae4",
            "firstName": "Marc Julian",
            "lastName": "Bradley",
            "fullName": "Julian Bradley",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2412.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-WI-28-upper-ocd-person-91a901ca-e295-4843-b368-e9da162c0ae4",
              "builtID": "wi-upper-28",
              "externalID": "ocd-person/91a901ca-e295-4843-b368-e9da162c0ae4",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.bradley@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 316 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-5400",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2245",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2245",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2412",
                "https://legis.wisconsin.gov/senate/28/bradley/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-28-upper-ocd-person-91a901ca-e295-4843-b368-e9da162c0ae4": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/365aa5a9-3584-4baa-86b5-51f096764f69",
            "firstName": "Cory",
            "lastName": "Tomczyk",
            "fullName": "Cory Tomczyk",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2567.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-WI-29-upper-ocd-person-365aa5a9-3584-4baa-86b5-51f096764f69",
              "builtID": "wi-upper-29",
              "externalID": "ocd-person/365aa5a9-3584-4baa-86b5-51f096764f69",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.tomczyk@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 310 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-2502",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2567",
                "https://legis.wisconsin.gov/senate/29/tomczyk/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-29-upper-ocd-person-365aa5a9-3584-4baa-86b5-51f096764f69": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/86a5289a-d18f-4869-ac53-432022c7f9c7",
            "firstName": "Eric",
            "lastName": "Wimberger",
            "fullName": "Eric Wimberger",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2439.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-WI-30-upper-ocd-person-86a5289a-d18f-4869-ac53-432022c7f9c7",
              "builtID": "wi-upper-30",
              "externalID": "ocd-person/86a5289a-d18f-4869-ac53-432022c7f9c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.wimberger@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 104 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-5670",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2246",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2246",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2439",
                "https://legis.wisconsin.gov/senate/30/wimberger"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-30-upper-ocd-person-86a5289a-d18f-4869-ac53-432022c7f9c7": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/8a1ee7b3-8450-45b4-a1cf-5e05788c0653",
            "firstName": "Jeff E.",
            "lastName": "Smith",
            "fullName": "Jeff Smith",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2433.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-WI-31-upper-ocd-person-8a1ee7b3-8450-45b4-a1cf-5e05788c0653",
              "builtID": "wi-upper-31",
              "externalID": "ocd-person/8a1ee7b3-8450-45b4-a1cf-5e05788c0653",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.smith@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 19 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-8546",
              "fax1": null,
              "fax2": "608-282-3571",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2019/legislators/senate/1944",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2114",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2114",
                "https://legis.wisconsin.gov/senate/31/smith/",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2433"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-31-upper-ocd-person-8a1ee7b3-8450-45b4-a1cf-5e05788c0653": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/1c95c957-d9c9-4905-8dc6-37b13272c1f0",
            "firstName": "Bradley M.",
            "lastName": "Pfaff",
            "fullName": "Brad Pfaff",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2429.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "32",
              "chamber": "upper",
              "hashID": "SLDU-WI-32-upper-ocd-person-1c95c957-d9c9-4905-8dc6-37b13272c1f0",
              "builtID": "wi-upper-32",
              "externalID": "ocd-person/1c95c957-d9c9-4905-8dc6-37b13272c1f0",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.pfaff@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 22 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-5490",
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2247",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2247",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2429",
                "https://legis.wisconsin.gov/senate/32/pfaff"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-32-upper-ocd-person-1c95c957-d9c9-4905-8dc6-37b13272c1f0": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/8d93f6ba-19eb-4684-a47d-6de08e009741",
            "firstName": "Chris",
            "lastName": "Kapenga",
            "fullName": "Chris Kapenga",
            "gender": "Male",
            "party": "Republican",
            "image": "https://docs.legis.wisconsin.gov/2023/legislators/senate/2422.jpg",
            "title": "WI Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WI",
              "stateFull": "Wisconsin",
              "district": "33",
              "chamber": "upper",
              "hashID": "SLDU-WI-33-upper-ocd-person-8d93f6ba-19eb-4684-a47d-6de08e009741",
              "builtID": "wi-upper-33",
              "externalID": "ocd-person/8d93f6ba-19eb-4684-a47d-6de08e009741",
              "geometry": null
            },
            "contactInfo": {
              "email": "sen.kapenga@legis.wisconsin.gov",
              "contactForm": null,
              "address1": null,
              "address2": "State Capitol Room 220 South, 2 E. Main St., Madison, WI 53702",
              "phone1": null,
              "phone2": "608-266-9174",
              "fax1": null,
              "fax2": "608-282-3573",
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://docs.legis.wisconsin.gov/2017/legislators/senate/1510",
                "https://docs.legis.wisconsin.gov/2019/legislators/senate/1905",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2101",
                "https://docs.legis.wisconsin.gov/2021/legislators/senate/2101",
                "https://docs.legis.wisconsin.gov/2023/legislators/senate/2422",
                "https://legis.wisconsin.gov/senate/33/kapenga/"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WI-33-upper-ocd-person-8d93f6ba-19eb-4684-a47d-6de08e009741": 0
        }
      }
    }
  },
  "WY": {
    "STATE_SUM": {
      "members": [
        {
          "stateAbbrev": "WY",
          "stateFull": "Wyoming",
          "identityInfo": {
            "type": "STATE_SUM",
            "stateAbbrev": "WY",
            "stateFull": "Wyoming",
            "district": "",
            "chamber": "",
            "hashID": "STATE_SUM-WY---",
            "builtID": "wy--",
            "externalID": "",
            "geometry": null
          }
        }
      ],
      "hash": { "STATE_SUM-WY---": 0 }
    },
    "SENATE": {
      "members": [
        {
          "API_ID": "B001261",
          "in_office": true,
          "firstName": "John",
          "lastName": "Barrasso",
          "middleName": null,
          "fullName": "John Barrasso",
          "gender": "M",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/B001261.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "WY",
            "stateFull": "Wyoming",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-WY---B001261",
            "builtID": "wy--",
            "externalID": "B001261",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.barrasso.senate.gov/public/index.cfm/contact-form",
            "address1": "307 Dirksen Senate Office Building",
            "address2": null,
            "phone1": "202-224-6441",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenJohnBarrasso",
            "youtube": "barrassowyo",
            "instagram": null,
            "facebook": "johnbarrasso",
            "urls": ["https://www.barrasso.senate.gov"],
            "rss_url": "https://www.barrasso.senate.gov/public/?a=rss.feed"
          },
          "title": "WY Senior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/B001261.json",
          "govtrack_id": "412251",
          "cspan_id": "1024777",
          "votesmart_id": "52662",
          "icpsr_id": "40707",
          "crp_id": "N00006236",
          "google_entity_id": "/m/02rsm32",
          "state_rank": "senior",
          "lis_id": "S317",
          "suffix": null,
          "date_of_birth": "1952-07-21",
          "leadership_role": "Senate Republican Conference Chair",
          "fec_candidate_id": "S6WY00068",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "17",
          "next_election": "2024",
          "total_votes": 554,
          "missed_votes": 113,
          "total_present": 0,
          "last_updated": "2024-06-20 15:30:30 -0400",
          "missed_votes_pct": 20.4,
          "votes_with_party_pct": 97.01,
          "votes_against_party_pct": 2.99,
          "ocd_id": "ocd-division/country:us/state:wy"
        },
        {
          "API_ID": "L000571",
          "in_office": true,
          "firstName": "Cynthia",
          "lastName": "Lummis",
          "middleName": "M.",
          "fullName": "Cynthia M. Lummis",
          "gender": "F",
          "party": "Republican",
          "image": "https://theunitedstates.io/images/congress/225x275/L000571.jpg",
          "identityInfo": {
            "type": "SENATE",
            "stateAbbrev": "WY",
            "stateFull": "Wyoming",
            "district": "",
            "chamber": "",
            "hashID": "SENATE-WY---L000571",
            "builtID": "wy--",
            "externalID": "L000571",
            "geometry": null
          },
          "contactInfo": {
            "email": null,
            "contactForm": "https://www.lummis.senate.gov/contact/contact-form/",
            "address1": "124 Russell Senate Office Building",
            "address2": null,
            "phone1": "202-224-3424",
            "phone2": null,
            "fax1": null,
            "fax2": null,
            "twitter": "SenLummis",
            "youtube": "CynthiaLummis",
            "instagram": null,
            "facebook": "152754318103332",
            "urls": ["https://www.lummis.senate.gov"],
            "rss_url": "https://www.lummis.senate.gov/press-releases/feed/"
          },
          "title": "WY Junior Senator",
          "short_title": "Sen.",
          "api_uri": "https://api.propublica.org/congress/v1/members/L000571.json",
          "govtrack_id": "412294",
          "cspan_id": "1031365",
          "votesmart_id": "15546",
          "icpsr_id": "20953",
          "crp_id": "N00029788",
          "google_entity_id": "/m/02qvjv3",
          "state_rank": "junior",
          "lis_id": "S410",
          "suffix": null,
          "date_of_birth": "1954-09-10",
          "leadership_role": null,
          "fec_candidate_id": "H8WY00148",
          "cook_pvi": null,
          "dw_nominate": null,
          "ideal_point": null,
          "seniority": "3",
          "next_election": "2026",
          "total_votes": 554,
          "missed_votes": 30,
          "total_present": 0,
          "last_updated": "2024-06-21 09:45:31 -0400",
          "missed_votes_pct": 5.42,
          "votes_with_party_pct": 90.33,
          "votes_against_party_pct": 9.67,
          "ocd_id": "ocd-division/country:us/state:wy"
        }
      ],
      "hash": { "SENATE-WY---B001261": 0, "SENATE-WY---L000571": 1 }
    },
    "HOUSE": {
      "00": {
        "members": [
          {
            "API_ID": "H001096",
            "in_office": true,
            "firstName": "Harriet",
            "lastName": "Hageman",
            "middleName": null,
            "fullName": "Harriet M. Hageman",
            "gender": "F",
            "party": "Republican",
            "image": "https://theunitedstates.io/images/congress/225x275/H001096.jpg",
            "identityInfo": {
              "type": "HOUSE",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "00",
              "chamber": "",
              "hashID": "HOUSE-WY-00--H001096",
              "builtID": "wy--00",
              "externalID": "H001096",
              "geometry": null,
              "geoid": "5600"
            },
            "contactInfo": {
              "email": null,
              "contactForm": null,
              "address1": "1531 Longworth House Office Building",
              "address2": null,
              "phone1": "202-225-2311",
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": ["https://hageman.house.gov/"],
              "rss_url": null
            },
            "title": "WY House Member",
            "short_title": "Rep.",
            "api_uri": "https://api.propublica.org/congress/v1/members/H001096.json",
            "govtrack_id": "456951",
            "cspan_id": null,
            "votesmart_id": null,
            "icpsr_id": null,
            "crp_id": null,
            "google_entity_id": null,
            "state_rank": null,
            "lis_id": null,
            "suffix": null,
            "date_of_birth": "1962-10-18",
            "leadership_role": null,
            "fec_candidate_id": "H2WY00166",
            "cook_pvi": null,
            "dw_nominate": null,
            "ideal_point": null,
            "seniority": "2",
            "next_election": "2024",
            "total_votes": 1056,
            "missed_votes": 20,
            "total_present": 0,
            "last_updated": "2024-07-03 13:00:12 -0400",
            "missed_votes_pct": 1.89,
            "votes_with_party_pct": 87.29,
            "votes_against_party_pct": 10.74,
            "ocd_id": "ocd-division/country:us/state:wy/cd:1",
            "at_large": true
          }
        ],
        "hash": { "HOUSE-WY-00--H001096": 0 }
      }
    },
    "SLDL": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/b9414bc4-f900-474b-b99c-8f2716419683",
            "firstName": "Chip",
            "lastName": "Neiman",
            "fullName": "Chip Neiman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-NeimanChip.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "1",
              "chamber": "lower",
              "hashID": "SLDL-WY-1-lower-ocd-person-b9414bc4-f900-474b-b99c-8f2716419683",
              "builtID": "wy-lower-1",
              "externalID": "ocd-person/b9414bc4-f900-474b-b99c-8f2716419683",
              "geometry": null
            },
            "contactInfo": {
              "email": "chip.neiman@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2070",
                "https://wyoleg.gov/Legislators/2021/R/2070",
                "https://www.wyoleg.gov/Legislators/2023/H/2070"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-1-lower-ocd-person-b9414bc4-f900-474b-b99c-8f2716419683": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/657bbcca-afa8-4e20-986b-bb3b8b6d79b8",
            "firstName": "Allen",
            "lastName": "Slagle",
            "fullName": "Allen Slagle",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-SlagleAllen.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "2",
              "chamber": "lower",
              "hashID": "SLDL-WY-2-lower-ocd-person-657bbcca-afa8-4e20-986b-bb3b8b6d79b8",
              "builtID": "wy-lower-2",
              "externalID": "ocd-person/657bbcca-afa8-4e20-986b-bb3b8b6d79b8",
              "geometry": null
            },
            "contactInfo": {
              "email": "allen.slagle@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2092",
                "https://www.wyoleg.gov/Legislators/2023/H/2092"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-2-lower-ocd-person-657bbcca-afa8-4e20-986b-bb3b8b6d79b8": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/9995703e-90eb-4d20-a2f6-bfd9542102bd",
            "firstName": "Abby",
            "lastName": "Angelos",
            "fullName": "Abby Angelos",
            "gender": "Female",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-AngelosAbby.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "3",
              "chamber": "lower",
              "hashID": "SLDL-WY-3-lower-ocd-person-9995703e-90eb-4d20-a2f6-bfd9542102bd",
              "builtID": "wy-lower-3",
              "externalID": "ocd-person/9995703e-90eb-4d20-a2f6-bfd9542102bd",
              "geometry": null
            },
            "contactInfo": {
              "email": "abby.angelos@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2091",
                "https://www.wyoleg.gov/Legislators/2023/H/2091",
                "https://en.wikipedia.org/wiki/Abby_Angelos"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-3-lower-ocd-person-9995703e-90eb-4d20-a2f6-bfd9542102bd": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/b9bbea38-a84b-4173-a599-cd9f5500b181",
            "firstName": "Jeremy",
            "lastName": "Haroldson",
            "fullName": "Jeremy Haroldson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-HaroldsonJeremy.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "4",
              "chamber": "lower",
              "hashID": "SLDL-WY-4-lower-ocd-person-b9bbea38-a84b-4173-a599-cd9f5500b181",
              "builtID": "wy-lower-4",
              "externalID": "ocd-person/b9bbea38-a84b-4173-a599-cd9f5500b181",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeremy.haroldson@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2071",
                "https://wyoleg.gov/Legislators/2021/R/2071",
                "https://www.wyoleg.gov/Legislators/2023/H/2071"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-4-lower-ocd-person-b9bbea38-a84b-4173-a599-cd9f5500b181": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/201b5fe9-4060-4dfa-ae2c-c59a3a3120ff",
            "firstName": "Scott",
            "lastName": "Smith",
            "fullName": "Scott Smith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://i0.wp.com/scottsmithwyo.com/wp-content/uploads/2022/04/preview-dif.jpg?resize=768%2C952&ssl=1",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "5",
              "chamber": "lower",
              "hashID": "SLDL-WY-5-lower-ocd-person-201b5fe9-4060-4dfa-ae2c-c59a3a3120ff",
              "builtID": "wy-lower-5",
              "externalID": "ocd-person/201b5fe9-4060-4dfa-ae2c-c59a3a3120ff",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.smith@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2093",
                "https://www.wyoleg.gov/Legislators/2023/H/2093"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-5-lower-ocd-person-201b5fe9-4060-4dfa-ae2c-c59a3a3120ff": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/d1ac363d-41ed-4c2c-a661-6e400d0b7d57",
            "firstName": "Tomi",
            "lastName": "Strock",
            "fullName": "Tomi Strock",
            "gender": "Female",
            "party": "Republican",
            "image": "https://static.wixstatic.com/media/eccebe_d5018ec8ebd54c8ab8d4297108ccdb0a~mv2.jpg/v1/fill/w_597,h_796,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/IMG_4030.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "6",
              "chamber": "lower",
              "hashID": "SLDL-WY-6-lower-ocd-person-d1ac363d-41ed-4c2c-a661-6e400d0b7d57",
              "builtID": "wy-lower-6",
              "externalID": "ocd-person/d1ac363d-41ed-4c2c-a661-6e400d0b7d57",
              "geometry": null
            },
            "contactInfo": {
              "email": "tomi.strock@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2094",
                "https://www.wyoleg.gov/Legislators/2023/H/2094"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-6-lower-ocd-person-d1ac363d-41ed-4c2c-a661-6e400d0b7d57": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/6ff4eff9-e68c-42a1-9aa5-b964daa37a89",
            "firstName": "Robert A.",
            "lastName": "Nicholas",
            "fullName": "Bob Nicholas",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/photos/NicholasH8.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "7",
              "chamber": "lower",
              "hashID": "SLDL-WY-7-lower-ocd-person-6ff4eff9-e68c-42a1-9aa5-b964daa37a89",
              "builtID": "wy-lower-7",
              "externalID": "ocd-person/6ff4eff9-e68c-42a1-9aa5-b964daa37a89",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.nicholas@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/1980",
                "https://wyoleg.gov/Legislators/2021/R/1980",
                "https://wyoleg.gov/Legislators/2021/R/1980",
                "https://www.wyoleg.gov/Legislators/2023/H/1980"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-7-lower-ocd-person-6ff4eff9-e68c-42a1-9aa5-b964daa37a89": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/0155ef8d-b4a9-48df-86db-65acf7b47dd2",
            "firstName": "David L.",
            "lastName": "Zwonitzer",
            "fullName": "Dave Zwonitzer",
            "gender": "Male",
            "party": "Republican",
            "image": "http://legisweb.state.wy.us/LegislatorSummary/photos/ZwonitzerDvH09.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "8",
              "chamber": "lower",
              "hashID": "SLDL-WY-8-lower-ocd-person-0155ef8d-b4a9-48df-86db-65acf7b47dd2",
              "builtID": "wy-lower-8",
              "externalID": "ocd-person/0155ef8d-b4a9-48df-86db-65acf7b47dd2",
              "geometry": null
            },
            "contactInfo": {
              "email": "dave.zwonitzer@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/1142",
                "https://www.wyoleg.gov/Legislators/2023/H/1142"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-8-lower-ocd-person-0155ef8d-b4a9-48df-86db-65acf7b47dd2": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/7052600a-c1b3-484d-80a3-232fc9815d7c",
            "firstName": "Landon",
            "lastName": "Brown",
            "fullName": "Landon Brown",
            "gender": "Male",
            "party": "Republican",
            "image": "http://legisweb.state.wy.us/LegislatorSummary/photos/BrownLH09.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "9",
              "chamber": "lower",
              "hashID": "SLDL-WY-9-lower-ocd-person-7052600a-c1b3-484d-80a3-232fc9815d7c",
              "builtID": "wy-lower-9",
              "externalID": "ocd-person/7052600a-c1b3-484d-80a3-232fc9815d7c",
              "geometry": null
            },
            "contactInfo": {
              "email": "landon.brown@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2034",
                "https://wyoleg.gov/Legislators/2021/R/2034",
                "https://wyoleg.gov/Legislators/2021/R/2034",
                "https://www.wyoleg.gov/Legislators/2023/H/2034"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-9-lower-ocd-person-7052600a-c1b3-484d-80a3-232fc9815d7c": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/08a52b9d-3ce0-4496-9b1b-ba41fbb3b165",
            "firstName": "John Charles",
            "lastName": "Eklund",
            "fullName": "John Eklund",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-EklundJohn.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "10",
              "chamber": "lower",
              "hashID": "SLDL-WY-10-lower-ocd-person-08a52b9d-3ce0-4496-9b1b-ba41fbb3b165",
              "builtID": "wy-lower-10",
              "externalID": "ocd-person/08a52b9d-3ce0-4496-9b1b-ba41fbb3b165",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.eklund@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/1978",
                "https://wyoleg.gov/Legislators/2021/R/1978",
                "https://wyoleg.gov/Legislators/2021/R/1978",
                "https://www.wyoleg.gov/Legislators/2023/H/1978"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-10-lower-ocd-person-08a52b9d-3ce0-4496-9b1b-ba41fbb3b165": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/63e25ff3-1832-45c8-a9af-669bb74a3aa4",
            "firstName": "Jared S.",
            "lastName": "Olsen",
            "fullName": "Jared Olsen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-OlsenJared.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "11",
              "chamber": "lower",
              "hashID": "SLDL-WY-11-lower-ocd-person-63e25ff3-1832-45c8-a9af-669bb74a3aa4",
              "builtID": "wy-lower-11",
              "externalID": "ocd-person/63e25ff3-1832-45c8-a9af-669bb74a3aa4",
              "geometry": null
            },
            "contactInfo": {
              "email": "jared.olsen@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2035",
                "https://wyoleg.gov/Legislators/2021/R/2035",
                "https://wyoleg.gov/Legislators/2021/R/2035",
                "https://www.wyoleg.gov/Legislators/2023/H/2035"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-11-lower-ocd-person-63e25ff3-1832-45c8-a9af-669bb74a3aa4": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/0df4327b-df46-4886-ab09-0c8a55639548",
            "firstName": "Clarence",
            "lastName": "Styvar",
            "fullName": "Clarence Styvar",
            "gender": "Male",
            "party": "Republican",
            "image": "http://wyoleg.gov/LegislatorSummary/Photos/StyvarH12.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "12",
              "chamber": "lower",
              "hashID": "SLDL-WY-12-lower-ocd-person-0df4327b-df46-4886-ab09-0c8a55639548",
              "builtID": "wy-lower-12",
              "externalID": "ocd-person/0df4327b-df46-4886-ab09-0c8a55639548",
              "geometry": null
            },
            "contactInfo": {
              "email": "clarence.styvar@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2051",
                "https://wyoleg.gov/Legislators/2021/R/2051",
                "https://wyoleg.gov/Legislators/2021/R/2051",
                "https://www.wyoleg.gov/Legislators/2023/H/2051"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-12-lower-ocd-person-0df4327b-df46-4886-ab09-0c8a55639548": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/dcc60e43-41b3-44ef-976c-0604a295b9cd",
            "firstName": "Kenneth D.",
            "lastName": "Chestek",
            "fullName": "Ken Chestek",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://img1.wsimg.com/isteam/ip/d01b1415-0c08-4d98-9c0d-4c209fc46e5f/Me%20(cropped)-0001.jpg/:/cr=t:0%25,l:3.11%25,w:93.79%25,h:100%25/rs=w:600,h:600,cg:true",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "13",
              "chamber": "lower",
              "hashID": "SLDL-WY-13-lower-ocd-person-dcc60e43-41b3-44ef-976c-0604a295b9cd",
              "builtID": "wy-lower-13",
              "externalID": "ocd-person/dcc60e43-41b3-44ef-976c-0604a295b9cd",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.chestek@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/D/2095",
                "https://www.wyoleg.gov/Legislators/2023/H/2095"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-13-lower-ocd-person-dcc60e43-41b3-44ef-976c-0604a295b9cd": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/b2265fcb-f768-4617-ad03-15f5b65af79f",
            "firstName": "Trey",
            "lastName": "Sherwood",
            "fullName": "Trey Sherwood",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-SherwoodTrey.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "14",
              "chamber": "lower",
              "hashID": "SLDL-WY-14-lower-ocd-person-b2265fcb-f768-4617-ad03-15f5b65af79f",
              "builtID": "wy-lower-14",
              "externalID": "ocd-person/b2265fcb-f768-4617-ad03-15f5b65af79f",
              "geometry": null
            },
            "contactInfo": {
              "email": "trey.sherwood@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/D/2072",
                "https://wyoleg.gov/Legislators/2021/D/2072",
                "https://www.wyoleg.gov/Legislators/2023/H/2072"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-14-lower-ocd-person-b2265fcb-f768-4617-ad03-15f5b65af79f": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/7a466dd5-8c7d-4cd1-ac2e-97fe4645145f",
            "firstName": "Donald E.",
            "lastName": "Burkhart",
            "fullName": "Donald Burkhart",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-BurkhartDonaldJr.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "15",
              "chamber": "lower",
              "hashID": "SLDL-WY-15-lower-ocd-person-7a466dd5-8c7d-4cd1-ac2e-97fe4645145f",
              "builtID": "wy-lower-15",
              "externalID": "ocd-person/7a466dd5-8c7d-4cd1-ac2e-97fe4645145f",
              "geometry": null
            },
            "contactInfo": {
              "email": "donald.burkhart@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/1973",
                "https://wyoleg.gov/Legislators/2021/R/1973",
                "https://wyoleg.gov/Legislators/2021/R/1973",
                "https://www.wyoleg.gov/Legislators/2023/H/1973"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-15-lower-ocd-person-7a466dd5-8c7d-4cd1-ac2e-97fe4645145f": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/28649646-1108-4954-9381-4aa2d3b6f31b",
            "firstName": "Michael",
            "lastName": "Yin",
            "fullName": "Mike Yin",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-YinMike.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "16",
              "chamber": "lower",
              "hashID": "SLDL-WY-16-lower-ocd-person-28649646-1108-4954-9381-4aa2d3b6f31b",
              "builtID": "wy-lower-16",
              "externalID": "ocd-person/28649646-1108-4954-9381-4aa2d3b6f31b",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.yin@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/D/2054",
                "https://wyoleg.gov/Legislators/2021/D/2054",
                "https://wyoleg.gov/Legislators/2021/D/2054",
                "https://www.wyoleg.gov/Legislators/2023/H/2054"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-16-lower-ocd-person-28649646-1108-4954-9381-4aa2d3b6f31b": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/2a0be03f-4f0a-4b2e-850b-50b4d0dade13",
            "firstName": "Joshua Thomas",
            "lastName": "Larson",
            "fullName": "J.T. Larson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/J_T_Larson.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "17",
              "chamber": "lower",
              "hashID": "SLDL-WY-17-lower-ocd-person-2a0be03f-4f0a-4b2e-850b-50b4d0dade13",
              "builtID": "wy-lower-17",
              "externalID": "ocd-person/2a0be03f-4f0a-4b2e-850b-50b4d0dade13",
              "geometry": null
            },
            "contactInfo": {
              "email": "jt.larson@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2096",
                "https://www.wyoleg.gov/Legislators/2023/H/2096"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-17-lower-ocd-person-2a0be03f-4f0a-4b2e-850b-50b4d0dade13": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/232d6600-4cfd-40a1-abb9-8a1eecd84768",
            "firstName": "Scott",
            "lastName": "Heiner",
            "fullName": "Scott Heiner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-HeinerScott.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "18",
              "chamber": "lower",
              "hashID": "SLDL-WY-18-lower-ocd-person-232d6600-4cfd-40a1-abb9-8a1eecd84768",
              "builtID": "wy-lower-18",
              "externalID": "ocd-person/232d6600-4cfd-40a1-abb9-8a1eecd84768",
              "geometry": null
            },
            "contactInfo": {
              "email": "scott.heiner@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2074",
                "https://wyoleg.gov/Legislators/2021/R/2074",
                "https://www.wyoleg.gov/Legislators/2023/H/2074"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-18-lower-ocd-person-232d6600-4cfd-40a1-abb9-8a1eecd84768": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/e3e287d4-038d-42c5-8926-8d2fdff485eb",
            "firstName": "Jon",
            "lastName": "Conrad",
            "fullName": "Jon Conrad",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-ConradJon.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "19",
              "chamber": "lower",
              "hashID": "SLDL-WY-19-lower-ocd-person-e3e287d4-038d-42c5-8926-8d2fdff485eb",
              "builtID": "wy-lower-19",
              "externalID": "ocd-person/e3e287d4-038d-42c5-8926-8d2fdff485eb",
              "geometry": null
            },
            "contactInfo": {
              "email": "jon.conrad@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2097",
                "https://www.wyoleg.gov/Legislators/2023/H/2097"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-19-lower-ocd-person-e3e287d4-038d-42c5-8926-8d2fdff485eb": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/a0478325-589a-465b-af7d-1b255fd250c7",
            "firstName": "Albert P.",
            "lastName": "Sommers",
            "fullName": "Albert Sommers",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-SommersAlbert.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "20",
              "chamber": "lower",
              "hashID": "SLDL-WY-20-lower-ocd-person-a0478325-589a-465b-af7d-1b255fd250c7",
              "builtID": "wy-lower-20",
              "externalID": "ocd-person/a0478325-589a-465b-af7d-1b255fd250c7",
              "geometry": null
            },
            "contactInfo": {
              "email": "albert.sommers@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/1991",
                "https://wyoleg.gov/Legislators/2021/R/1991",
                "https://wyoleg.gov/Legislators/2021/R/1991",
                "https://www.wyoleg.gov/Legislators/2023/H/1991"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-20-lower-ocd-person-a0478325-589a-465b-af7d-1b255fd250c7": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/ad38dd1a-94ef-457f-bd6a-6e93195ac74f",
            "firstName": "Lane",
            "lastName": "Allred",
            "fullName": "Lane Allred",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-AllredLane.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "21",
              "chamber": "lower",
              "hashID": "SLDL-WY-21-lower-ocd-person-ad38dd1a-94ef-457f-bd6a-6e93195ac74f",
              "builtID": "wy-lower-21",
              "externalID": "ocd-person/ad38dd1a-94ef-457f-bd6a-6e93195ac74f",
              "geometry": null
            },
            "contactInfo": {
              "email": "lane.allred@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2098",
                "https://www.wyoleg.gov/Legislators/2023/H/2098"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-21-lower-ocd-person-ad38dd1a-94ef-457f-bd6a-6e93195ac74f": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/3198aa67-829e-4827-8b26-8955cbf36f4a",
            "firstName": "Andrew",
            "lastName": "Byron",
            "fullName": "Andrew Byron",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-ByronAndrew.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "22",
              "chamber": "lower",
              "hashID": "SLDL-WY-22-lower-ocd-person-3198aa67-829e-4827-8b26-8955cbf36f4a",
              "builtID": "wy-lower-22",
              "externalID": "ocd-person/3198aa67-829e-4827-8b26-8955cbf36f4a",
              "geometry": null
            },
            "contactInfo": {
              "email": "andrew.byron@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2099",
                "https://www.wyoleg.gov/Legislators/2023/H/2099"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-22-lower-ocd-person-3198aa67-829e-4827-8b26-8955cbf36f4a": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/7d3ea3c1-de6a-433f-83e2-c53dde09be61",
            "firstName": "Elizabeth",
            "lastName": "Storer",
            "fullName": "Liz Storer",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-StorerLiz.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "23",
              "chamber": "lower",
              "hashID": "SLDL-WY-23-lower-ocd-person-7d3ea3c1-de6a-433f-83e2-c53dde09be61",
              "builtID": "wy-lower-23",
              "externalID": "ocd-person/7d3ea3c1-de6a-433f-83e2-c53dde09be61",
              "geometry": null
            },
            "contactInfo": {
              "email": "liz.storer@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/D/2100",
                "https://www.wyoleg.gov/Legislators/2023/H/2100"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-23-lower-ocd-person-7d3ea3c1-de6a-433f-83e2-c53dde09be61": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/289d26f0-c883-40c2-b75f-aef6413e29cb",
            "firstName": "Sandy Montgomery",
            "lastName": "Newsome",
            "fullName": "Sandy Newsome",
            "gender": "Female",
            "party": "Republican",
            "image": "https://bloximages.chicago2.vip.townnews.com/trib.com/content/tncms/assets/v3/editorial/4/58/45833187-73bf-54ff-9bd1-caaf776c0371/57f67bf0820f0.image.jpg?resize=400%2C560",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "24",
              "chamber": "lower",
              "hashID": "SLDL-WY-24-lower-ocd-person-289d26f0-c883-40c2-b75f-aef6413e29cb",
              "builtID": "wy-lower-24",
              "externalID": "ocd-person/289d26f0-c883-40c2-b75f-aef6413e29cb",
              "geometry": null
            },
            "contactInfo": {
              "email": "sandy.newsome@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2055",
                "https://wyoleg.gov/Legislators/2021/R/2055",
                "https://wyoleg.gov/Legislators/2021/R/2055",
                "https://www.wyoleg.gov/Legislators/2023/H/2055"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-24-lower-ocd-person-289d26f0-c883-40c2-b75f-aef6413e29cb": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/d7089cdb-4701-4777-82ea-b17217194aad",
            "firstName": "David",
            "lastName": "Northrup",
            "fullName": "David Northrup",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-NorthrupDavid.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "25",
              "chamber": "lower",
              "hashID": "SLDL-WY-25-lower-ocd-person-d7089cdb-4701-4777-82ea-b17217194aad",
              "builtID": "wy-lower-25",
              "externalID": "ocd-person/d7089cdb-4701-4777-82ea-b17217194aad",
              "geometry": null
            },
            "contactInfo": {
              "email": "david.northrup@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/1998",
                "https://www.wyoleg.gov/Legislators/2023/H/1998"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-25-lower-ocd-person-d7089cdb-4701-4777-82ea-b17217194aad": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/416ee113-2983-43ab-bf24-3d7134767b41",
            "firstName": "Dalton",
            "lastName": "Banks",
            "fullName": "Dalton Banks",
            "gender": "Male",
            "party": "Republican",
            "image": "http://greybullstandard.com/sites/default/files/field/image/Dalton%20Banks.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "26",
              "chamber": "lower",
              "hashID": "SLDL-WY-26-lower-ocd-person-416ee113-2983-43ab-bf24-3d7134767b41",
              "builtID": "wy-lower-26",
              "externalID": "ocd-person/416ee113-2983-43ab-bf24-3d7134767b41",
              "geometry": null
            },
            "contactInfo": {
              "email": "dalton.banks@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2101",
                "https://www.wyoleg.gov/Legislators/2023/H/2101"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-26-lower-ocd-person-416ee113-2983-43ab-bf24-3d7134767b41": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/c954b74f-1933-4be4-801d-cc93f7f37ac0",
            "firstName": "Martha Bell",
            "lastName": "Lawley",
            "fullName": "Martha Lawley",
            "gender": "Female",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-LawleyMartha.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "27",
              "chamber": "lower",
              "hashID": "SLDL-WY-27-lower-ocd-person-c954b74f-1933-4be4-801d-cc93f7f37ac0",
              "builtID": "wy-lower-27",
              "externalID": "ocd-person/c954b74f-1933-4be4-801d-cc93f7f37ac0",
              "geometry": null
            },
            "contactInfo": {
              "email": "martha.lawley@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2102",
                "https://www.wyoleg.gov/Legislators/2023/H/2102"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-27-lower-ocd-person-c954b74f-1933-4be4-801d-cc93f7f37ac0": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/e79049b8-a6d9-4356-a190-fc1e90837d5d",
            "firstName": "John",
            "lastName": "Winter",
            "fullName": "John Winter",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-WinterJohn.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "28",
              "chamber": "lower",
              "hashID": "SLDL-WY-28-lower-ocd-person-e79049b8-a6d9-4356-a190-fc1e90837d5d",
              "builtID": "wy-lower-28",
              "externalID": "ocd-person/e79049b8-a6d9-4356-a190-fc1e90837d5d",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.winter@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2056",
                "https://wyoleg.gov/Legislators/2021/R/2056",
                "https://wyoleg.gov/Legislators/2021/R/2056",
                "https://www.wyoleg.gov/Legislators/2023/H/2056"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-28-lower-ocd-person-e79049b8-a6d9-4356-a190-fc1e90837d5d": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/b7a5cb78-6473-4b7b-9c27-90c8ab2130a5",
            "firstName": "Ken",
            "lastName": "Pendergraft",
            "fullName": "Ken Pendergraft",
            "gender": "Male",
            "party": "Republican",
            "image": "https://bloximages.newyork1.vip.townnews.com/thesheridanpress.com/content/tncms/assets/v3/editorial/e/38/e385f8ec-1e35-11ed-898a-b39b6ee19880/62fcf60fbb349.image.jpg?resize=395%2C500",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "29",
              "chamber": "lower",
              "hashID": "SLDL-WY-29-lower-ocd-person-b7a5cb78-6473-4b7b-9c27-90c8ab2130a5",
              "builtID": "wy-lower-29",
              "externalID": "ocd-person/b7a5cb78-6473-4b7b-9c27-90c8ab2130a5",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.pendergraft@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2103",
                "https://www.wyoleg.gov/Legislators/2023/H/2103"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-29-lower-ocd-person-b7a5cb78-6473-4b7b-9c27-90c8ab2130a5": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/a6a6a5e3-7bd2-4194-ad25-28d2721feb55",
            "firstName": "Mark",
            "lastName": "Jennings",
            "fullName": "Mark Jennings",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-JenningsMark.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "30",
              "chamber": "lower",
              "hashID": "SLDL-WY-30-lower-ocd-person-a6a6a5e3-7bd2-4194-ad25-28d2721feb55",
              "builtID": "wy-lower-30",
              "externalID": "ocd-person/a6a6a5e3-7bd2-4194-ad25-28d2721feb55",
              "geometry": null
            },
            "contactInfo": {
              "email": "mark.jennings@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2015",
                "https://wyoleg.gov/Legislators/2021/R/2015",
                "https://wyoleg.gov/Legislators/2021/R/2015",
                "https://www.wyoleg.gov/Legislators/2023/H/2015"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-30-lower-ocd-person-a6a6a5e3-7bd2-4194-ad25-28d2721feb55": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/931a9e71-33a6-4e65-be91-703ad612447e",
            "firstName": "John",
            "lastName": "Bear",
            "fullName": "John Bear",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-BearJohn.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "31",
              "chamber": "lower",
              "hashID": "SLDL-WY-31-lower-ocd-person-931a9e71-33a6-4e65-be91-703ad612447e",
              "builtID": "wy-lower-31",
              "externalID": "ocd-person/931a9e71-33a6-4e65-be91-703ad612447e",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.bear@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2075",
                "https://wyoleg.gov/Legislators/2021/R/2075",
                "https://www.wyoleg.gov/Legislators/2023/H/2075"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-31-lower-ocd-person-931a9e71-33a6-4e65-be91-703ad612447e": 0
        }
      },
      "32": {
        "members": [
          {
            "API_ID": "ocd-person/15ae95ba-274d-4b7b-a2c7-a5cfb5e2b089",
            "firstName": "Ken",
            "lastName": "Clouston",
            "fullName": "Ken Clouston",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-CloustonKen.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "32",
              "chamber": "lower",
              "hashID": "SLDL-WY-32-lower-ocd-person-15ae95ba-274d-4b7b-a2c7-a5cfb5e2b089",
              "builtID": "wy-lower-32",
              "externalID": "ocd-person/15ae95ba-274d-4b7b-a2c7-a5cfb5e2b089",
              "geometry": null
            },
            "contactInfo": {
              "email": "ken.clouston@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2104",
                "https://www.wyoleg.gov/Legislators/2023/H/2104"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-32-lower-ocd-person-15ae95ba-274d-4b7b-a2c7-a5cfb5e2b089": 0
        }
      },
      "33": {
        "members": [
          {
            "API_ID": "ocd-person/d8e024e6-1e42-4f92-945a-2000fed1542f",
            "firstName": "Sarah",
            "lastName": "Penn",
            "fullName": "Sarah Penn",
            "gender": "Female",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-PennSarah.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "33",
              "chamber": "lower",
              "hashID": "SLDL-WY-33-lower-ocd-person-d8e024e6-1e42-4f92-945a-2000fed1542f",
              "builtID": "wy-lower-33",
              "externalID": "ocd-person/d8e024e6-1e42-4f92-945a-2000fed1542f",
              "geometry": null
            },
            "contactInfo": {
              "email": "sarah.penn@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2105",
                "https://www.wyoleg.gov/Legislators/2023/H/2105"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-33-lower-ocd-person-d8e024e6-1e42-4f92-945a-2000fed1542f": 0
        }
      },
      "34": {
        "members": [
          {
            "API_ID": "ocd-person/9c9a9e1b-8107-40ac-b667-a8e75c5f806d",
            "firstName": "Pepper L.",
            "lastName": "Ottman",
            "fullName": "Pepper Ottman",
            "gender": "Female",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-OttmanPepper.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "34",
              "chamber": "lower",
              "hashID": "SLDL-WY-34-lower-ocd-person-9c9a9e1b-8107-40ac-b667-a8e75c5f806d",
              "builtID": "wy-lower-34",
              "externalID": "ocd-person/9c9a9e1b-8107-40ac-b667-a8e75c5f806d",
              "geometry": null
            },
            "contactInfo": {
              "email": "pepper.ottman@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2076",
                "https://wyoleg.gov/Legislators/2021/R/2076",
                "https://www.wyoleg.gov/Legislators/2023/H/2076"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-34-lower-ocd-person-9c9a9e1b-8107-40ac-b667-a8e75c5f806d": 0
        }
      },
      "35": {
        "members": [
          {
            "API_ID": "ocd-person/0cd9d0d3-09eb-4cf1-8e5b-bfc15a71b337",
            "firstName": "Tony",
            "lastName": "Locke",
            "fullName": "Tony Locke",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-LockeTony.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "35",
              "chamber": "lower",
              "hashID": "SLDL-WY-35-lower-ocd-person-0cd9d0d3-09eb-4cf1-8e5b-bfc15a71b337",
              "builtID": "wy-lower-35",
              "externalID": "ocd-person/0cd9d0d3-09eb-4cf1-8e5b-bfc15a71b337",
              "geometry": null
            },
            "contactInfo": {
              "email": "tony.locke@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2106",
                "https://www.wyoleg.gov/Legislators/2023/H/2106"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-35-lower-ocd-person-0cd9d0d3-09eb-4cf1-8e5b-bfc15a71b337": 0
        }
      },
      "36": {
        "members": [
          {
            "API_ID": "ocd-person/394ba273-63d7-409d-90cd-5d183e750ea4",
            "firstName": "Arthur",
            "lastName": "Washut",
            "fullName": "Art Washut",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-WashutArt.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "36",
              "chamber": "lower",
              "hashID": "SLDL-WY-36-lower-ocd-person-394ba273-63d7-409d-90cd-5d183e750ea4",
              "builtID": "wy-lower-36",
              "externalID": "ocd-person/394ba273-63d7-409d-90cd-5d183e750ea4",
              "geometry": null
            },
            "contactInfo": {
              "email": "art.washut@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2058",
                "https://wyoleg.gov/Legislators/2021/R/2058",
                "https://wyoleg.gov/Legislators/2021/R/2058",
                "https://www.wyoleg.gov/Legislators/2023/H/2058"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-36-lower-ocd-person-394ba273-63d7-409d-90cd-5d183e750ea4": 0
        }
      },
      "37": {
        "members": [
          {
            "API_ID": "ocd-person/1f6a3489-e152-487f-852a-23fc40a22185",
            "firstName": "Steve",
            "lastName": "Harshman",
            "fullName": "Steve Harshman",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-HarshmanSteve.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "37",
              "chamber": "lower",
              "hashID": "SLDL-WY-37-lower-ocd-person-1f6a3489-e152-487f-852a-23fc40a22185",
              "builtID": "wy-lower-37",
              "externalID": "ocd-person/1f6a3489-e152-487f-852a-23fc40a22185",
              "geometry": null
            },
            "contactInfo": {
              "email": "steve.harshman@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/717",
                "https://wyoleg.gov/Legislators/2021/R/717",
                "https://wyoleg.gov/Legislators/2021/R/717",
                "https://www.wyoleg.gov/Legislators/2023/H/717"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-37-lower-ocd-person-1f6a3489-e152-487f-852a-23fc40a22185": 0
        }
      },
      "38": {
        "members": [
          {
            "API_ID": "ocd-person/769a4c49-d52b-467e-a801-ee3c95a2dd8c",
            "firstName": "Tom",
            "lastName": "Walters",
            "fullName": "Tom Walters",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-WaltersTom.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "38",
              "chamber": "lower",
              "hashID": "SLDL-WY-38-lower-ocd-person-769a4c49-d52b-467e-a801-ee3c95a2dd8c",
              "builtID": "wy-lower-38",
              "externalID": "ocd-person/769a4c49-d52b-467e-a801-ee3c95a2dd8c",
              "geometry": null
            },
            "contactInfo": {
              "email": "tom.walters@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/1993",
                "https://wyoleg.gov/Legislators/2021/R/1993",
                "https://wyoleg.gov/Legislators/2021/R/1993",
                "https://www.wyoleg.gov/Legislators/2023/H/1993"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-38-lower-ocd-person-769a4c49-d52b-467e-a801-ee3c95a2dd8c": 0
        }
      },
      "39": {
        "members": [
          {
            "API_ID": "ocd-person/9b6e58cd-4dbc-4554-a677-b362c913c069",
            "firstName": "Cody",
            "lastName": "Wylie",
            "fullName": "Cody Wylie",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-WylieCody.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "39",
              "chamber": "lower",
              "hashID": "SLDL-WY-39-lower-ocd-person-9b6e58cd-4dbc-4554-a677-b362c913c069",
              "builtID": "wy-lower-39",
              "externalID": "ocd-person/9b6e58cd-4dbc-4554-a677-b362c913c069",
              "geometry": null
            },
            "contactInfo": {
              "email": "cody.wylie@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2107",
                "https://www.wyoleg.gov/Legislators/2023/H/2107"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-39-lower-ocd-person-9b6e58cd-4dbc-4554-a677-b362c913c069": 0
        }
      },
      "40": {
        "members": [
          {
            "API_ID": "ocd-person/c6578593-1ca0-4b7d-8635-67249df87c36",
            "firstName": "Barry Vincent",
            "lastName": "Crago",
            "fullName": "Barry Crago",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-CragoBarry.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "40",
              "chamber": "lower",
              "hashID": "SLDL-WY-40-lower-ocd-person-c6578593-1ca0-4b7d-8635-67249df87c36",
              "builtID": "wy-lower-40",
              "externalID": "ocd-person/c6578593-1ca0-4b7d-8635-67249df87c36",
              "geometry": null
            },
            "contactInfo": {
              "email": "barry.crago@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2078",
                "https://wyoleg.gov/Legislators/2021/R/2078",
                "https://www.wyoleg.gov/Legislators/2023/H/2078"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-40-lower-ocd-person-c6578593-1ca0-4b7d-8635-67249df87c36": 0
        }
      },
      "41": {
        "members": [
          {
            "API_ID": "ocd-person/c8560c84-4f07-42f1-b6c0-40ab7df52f33",
            "firstName": "Bill",
            "lastName": "Henderson",
            "fullName": "Bill Henderson",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-HendersonBill.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "41",
              "chamber": "lower",
              "hashID": "SLDL-WY-41-lower-ocd-person-c8560c84-4f07-42f1-b6c0-40ab7df52f33",
              "builtID": "wy-lower-41",
              "externalID": "ocd-person/c8560c84-4f07-42f1-b6c0-40ab7df52f33",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.henderson@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2042",
                "https://wyoleg.gov/Legislators/2021/R/2042",
                "https://wyoleg.gov/Legislators/2021/R/2042",
                "https://www.wyoleg.gov/Legislators/2023/H/2042"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-41-lower-ocd-person-c8560c84-4f07-42f1-b6c0-40ab7df52f33": 0
        }
      },
      "42": {
        "members": [
          {
            "API_ID": "ocd-person/0fc4b869-00b1-4ecf-a84f-2731edeb2ca1",
            "firstName": "Ben",
            "lastName": "Hornok",
            "fullName": "Ben Hornok",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-HornokBen.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "42",
              "chamber": "lower",
              "hashID": "SLDL-WY-42-lower-ocd-person-0fc4b869-00b1-4ecf-a84f-2731edeb2ca1",
              "builtID": "wy-lower-42",
              "externalID": "ocd-person/0fc4b869-00b1-4ecf-a84f-2731edeb2ca1",
              "geometry": null
            },
            "contactInfo": {
              "email": "ben.hornok@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2108",
                "https://www.wyoleg.gov/Legislators/2023/H/2108"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-42-lower-ocd-person-0fc4b869-00b1-4ecf-a84f-2731edeb2ca1": 0
        }
      },
      "43": {
        "members": [
          {
            "API_ID": "ocd-person/8e1a6db3-6b6a-4937-a25c-1b6e77800d41",
            "firstName": "Daniel",
            "lastName": "Zwonitzer",
            "fullName": "Dan Zwonitzer",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-ZwonitzerDan.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "43",
              "chamber": "lower",
              "hashID": "SLDL-WY-43-lower-ocd-person-8e1a6db3-6b6a-4937-a25c-1b6e77800d41",
              "builtID": "wy-lower-43",
              "externalID": "ocd-person/8e1a6db3-6b6a-4937-a25c-1b6e77800d41",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.zwonitzer@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/1141",
                "https://wyoleg.gov/Legislators/2021/R/1141",
                "https://wyoleg.gov/Legislators/2021/R/1141",
                "https://www.wyoleg.gov/Legislators/2023/H/1141"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-43-lower-ocd-person-8e1a6db3-6b6a-4937-a25c-1b6e77800d41": 0
        }
      },
      "44": {
        "members": [
          {
            "API_ID": "ocd-person/a37decb6-73ec-4b6c-96e7-4ae196b3fdc5",
            "firstName": "Tamara N.",
            "lastName": "Trujillo",
            "fullName": "Tamara Trujillo",
            "gender": "Female",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-TrujilloTamara.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "44",
              "chamber": "lower",
              "hashID": "SLDL-WY-44-lower-ocd-person-a37decb6-73ec-4b6c-96e7-4ae196b3fdc5",
              "builtID": "wy-lower-44",
              "externalID": "ocd-person/a37decb6-73ec-4b6c-96e7-4ae196b3fdc5",
              "geometry": null
            },
            "contactInfo": {
              "email": "tamara.trujillo@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2109",
                "https://www.wyoleg.gov/Legislators/2023/H/2109"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-44-lower-ocd-person-a37decb6-73ec-4b6c-96e7-4ae196b3fdc5": 0
        }
      },
      "45": {
        "members": [
          {
            "API_ID": "ocd-person/588c810c-0a65-475b-9593-46fa556cb962",
            "firstName": "Karlee R.",
            "lastName": "Provenza",
            "fullName": "Karlee Provenza",
            "gender": "Female",
            "party": "Democrat",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-ProvenzaKarlee.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "45",
              "chamber": "lower",
              "hashID": "SLDL-WY-45-lower-ocd-person-588c810c-0a65-475b-9593-46fa556cb962",
              "builtID": "wy-lower-45",
              "externalID": "ocd-person/588c810c-0a65-475b-9593-46fa556cb962",
              "geometry": null
            },
            "contactInfo": {
              "email": "karlee.provenza@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/D/2080",
                "https://wyoleg.gov/Legislators/2021/D/2080",
                "https://www.wyoleg.gov/Legislators/2023/H/2080"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-45-lower-ocd-person-588c810c-0a65-475b-9593-46fa556cb962": 0
        }
      },
      "46": {
        "members": [
          {
            "API_ID": "ocd-person/d811f813-1093-449a-a4dc-5d02c704ee49",
            "firstName": "Ocean",
            "lastName": "Andrew",
            "fullName": "Ocean Andrew",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-AndrewOcean.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "46",
              "chamber": "lower",
              "hashID": "SLDL-WY-46-lower-ocd-person-d811f813-1093-449a-a4dc-5d02c704ee49",
              "builtID": "wy-lower-46",
              "externalID": "ocd-person/d811f813-1093-449a-a4dc-5d02c704ee49",
              "geometry": null
            },
            "contactInfo": {
              "email": "ocean.andrew@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2081",
                "https://wyoleg.gov/Legislators/2021/R/2081",
                "https://www.wyoleg.gov/Legislators/2023/H/2081"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-46-lower-ocd-person-d811f813-1093-449a-a4dc-5d02c704ee49": 0
        }
      },
      "47": {
        "members": [
          {
            "API_ID": "ocd-person/8ddb8078-482c-4df5-983d-ece9e5825cc2",
            "firstName": "Robert",
            "lastName": "Davis",
            "fullName": "Bob Davis",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-DavisBob.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "47",
              "chamber": "lower",
              "hashID": "SLDL-WY-47-lower-ocd-person-8ddb8078-482c-4df5-983d-ece9e5825cc2",
              "builtID": "wy-lower-47",
              "externalID": "ocd-person/8ddb8078-482c-4df5-983d-ece9e5825cc2",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.davis@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2110",
                "https://www.wyoleg.gov/Legislators/2023/H/2110"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-47-lower-ocd-person-8ddb8078-482c-4df5-983d-ece9e5825cc2": 0
        }
      },
      "48": {
        "members": [
          {
            "API_ID": "ocd-person/eb9af80b-b2e8-4b32-9598-b9e12bb470ef",
            "firstName": "Clark",
            "lastName": "Stith",
            "fullName": "Clark Stith",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-StithClark.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "48",
              "chamber": "lower",
              "hashID": "SLDL-WY-48-lower-ocd-person-eb9af80b-b2e8-4b32-9598-b9e12bb470ef",
              "builtID": "wy-lower-48",
              "externalID": "ocd-person/eb9af80b-b2e8-4b32-9598-b9e12bb470ef",
              "geometry": null
            },
            "contactInfo": {
              "email": "clark.stith@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2049",
                "https://wyoleg.gov/Legislators/2021/R/2049",
                "https://wyoleg.gov/Legislators/2021/R/2049",
                "https://www.wyoleg.gov/Legislators/2023/H/2049"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-48-lower-ocd-person-eb9af80b-b2e8-4b32-9598-b9e12bb470ef": 0
        }
      },
      "49": {
        "members": [
          {
            "API_ID": "ocd-person/d52d47cc-06fa-4290-a8e3-25db3de21130",
            "firstName": "Ryan",
            "lastName": "Berger",
            "fullName": "Ryan Berger",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-BergerRyan.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "49",
              "chamber": "lower",
              "hashID": "SLDL-WY-49-lower-ocd-person-d52d47cc-06fa-4290-a8e3-25db3de21130",
              "builtID": "wy-lower-49",
              "externalID": "ocd-person/d52d47cc-06fa-4290-a8e3-25db3de21130",
              "geometry": null
            },
            "contactInfo": {
              "email": "ryan.berger@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2111",
                "https://www.wyoleg.gov/Legislators/2023/H/2111"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-49-lower-ocd-person-d52d47cc-06fa-4290-a8e3-25db3de21130": 0
        }
      },
      "50": {
        "members": [
          {
            "API_ID": "ocd-person/2fc47a70-0bf7-4d7c-bda2-5cdbf75a99ea",
            "firstName": "Rachel",
            "lastName": "Rodriguez-Williams",
            "fullName": "Rachel Rodriguez-Williams",
            "gender": "Female",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-Rodreguez-WilliamsRachel.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "50",
              "chamber": "lower",
              "hashID": "SLDL-WY-50-lower-ocd-person-2fc47a70-0bf7-4d7c-bda2-5cdbf75a99ea",
              "builtID": "wy-lower-50",
              "externalID": "ocd-person/2fc47a70-0bf7-4d7c-bda2-5cdbf75a99ea",
              "geometry": null
            },
            "contactInfo": {
              "email": "rachel.rodriguez-williams@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2083",
                "https://wyoleg.gov/Legislators/2021/R/2083",
                "https://www.wyoleg.gov/Legislators/2023/H/2083"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-50-lower-ocd-person-2fc47a70-0bf7-4d7c-bda2-5cdbf75a99ea": 0
        }
      },
      "51": {
        "members": [
          {
            "API_ID": "ocd-person/a41e4912-93e7-45e5-814a-f6cfe670d77e",
            "firstName": "Cyrus M.",
            "lastName": "Western",
            "fullName": "Cyrus Western",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-WesternCyrus.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "51",
              "chamber": "lower",
              "hashID": "SLDL-WY-51-lower-ocd-person-a41e4912-93e7-45e5-814a-f6cfe670d77e",
              "builtID": "wy-lower-51",
              "externalID": "ocd-person/a41e4912-93e7-45e5-814a-f6cfe670d77e",
              "geometry": null
            },
            "contactInfo": {
              "email": "cyrus.western@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2061",
                "https://wyoleg.gov/Legislators/2021/R/2061",
                "https://wyoleg.gov/Legislators/2021/R/2061",
                "https://www.wyoleg.gov/Legislators/2023/H/2061"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-51-lower-ocd-person-a41e4912-93e7-45e5-814a-f6cfe670d77e": 0
        }
      },
      "52": {
        "members": [
          {
            "API_ID": "ocd-person/8b636fd9-d6e2-4369-9764-c56803244076",
            "firstName": "Reuben",
            "lastName": "Tarver",
            "fullName": "Reuben Tarver",
            "gender": "Male",
            "party": "Republican",
            "image": "https://static.wixstatic.com/media/90d799_7b7ddfb7956a4493bf4f48a996100daf~mv2.jpg/v1/fill/w_640,h_594,fp_0.42_0.30,q_85,usm_0.66_1.00_0.01,enc_auto/90d799_7b7ddfb7956a4493bf4f48a996100daf~mv2.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "52",
              "chamber": "lower",
              "hashID": "SLDL-WY-52-lower-ocd-person-8b636fd9-d6e2-4369-9764-c56803244076",
              "builtID": "wy-lower-52",
              "externalID": "ocd-person/8b636fd9-d6e2-4369-9764-c56803244076",
              "geometry": null
            },
            "contactInfo": {
              "email": "reuben.tarver@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2112",
                "https://www.wyoleg.gov/Legislators/2023/H/2112"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-52-lower-ocd-person-8b636fd9-d6e2-4369-9764-c56803244076": 0
        }
      },
      "53": {
        "members": [
          {
            "API_ID": "ocd-person/ffe52a1f-bc33-424a-bd8c-a2df967ac83a",
            "firstName": "Christopher R.",
            "lastName": "Knapp",
            "fullName": "Chris Knapp",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-KnappChristopher.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "53",
              "chamber": "lower",
              "hashID": "SLDL-WY-53-lower-ocd-person-ffe52a1f-bc33-424a-bd8c-a2df967ac83a",
              "builtID": "wy-lower-53",
              "externalID": "ocd-person/ffe52a1f-bc33-424a-bd8c-a2df967ac83a",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.knapp@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2086",
                "https://wyoleg.gov/Legislators/2021/R/2086",
                "https://www.wyoleg.gov/Legislators/2023/H/2086"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-53-lower-ocd-person-ffe52a1f-bc33-424a-bd8c-a2df967ac83a": 0
        }
      },
      "54": {
        "members": [
          {
            "API_ID": "ocd-person/6466bd0e-d531-4ac0-8667-3e7f34a32f9a",
            "firstName": "Lloyd Charles",
            "lastName": "Larsen",
            "fullName": "Lloyd Larsen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-LarsenLloyd.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "54",
              "chamber": "lower",
              "hashID": "SLDL-WY-54-lower-ocd-person-6466bd0e-d531-4ac0-8667-3e7f34a32f9a",
              "builtID": "wy-lower-54",
              "externalID": "ocd-person/6466bd0e-d531-4ac0-8667-3e7f34a32f9a",
              "geometry": null
            },
            "contactInfo": {
              "email": "lloyd.larsen@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/1988",
                "https://wyoleg.gov/Legislators/2021/R/1988",
                "https://wyoleg.gov/Legislators/2021/R/1988",
                "https://www.wyoleg.gov/Legislators/2023/H/1988"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-54-lower-ocd-person-6466bd0e-d531-4ac0-8667-3e7f34a32f9a": 0
        }
      },
      "55": {
        "members": [
          {
            "API_ID": "ocd-person/6c134afb-330d-4a05-b0a3-cf6a84ce5526",
            "firstName": "Ember Ann",
            "lastName": "Oakley",
            "fullName": "Ember Oakley",
            "gender": "Female",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-OakleyEmber.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "55",
              "chamber": "lower",
              "hashID": "SLDL-WY-55-lower-ocd-person-6c134afb-330d-4a05-b0a3-cf6a84ce5526",
              "builtID": "wy-lower-55",
              "externalID": "ocd-person/6c134afb-330d-4a05-b0a3-cf6a84ce5526",
              "geometry": null
            },
            "contactInfo": {
              "email": "ember.oakley@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2085",
                "https://wyoleg.gov/Legislators/2021/R/2085",
                "https://www.wyoleg.gov/Legislators/2023/H/2085"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-55-lower-ocd-person-6c134afb-330d-4a05-b0a3-cf6a84ce5526": 0
        }
      },
      "56": {
        "members": [
          {
            "API_ID": "ocd-person/4338df64-c20b-4bc8-a51a-eb8cb619bbe4",
            "firstName": "Jerry",
            "lastName": "Obermueller",
            "fullName": "Jerry Obermueller",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-ObermuellerJerry.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "56",
              "chamber": "lower",
              "hashID": "SLDL-WY-56-lower-ocd-person-4338df64-c20b-4bc8-a51a-eb8cb619bbe4",
              "builtID": "wy-lower-56",
              "externalID": "ocd-person/4338df64-c20b-4bc8-a51a-eb8cb619bbe4",
              "geometry": null
            },
            "contactInfo": {
              "email": "jerry.obermueller@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2045",
                "https://wyoleg.gov/Legislators/2021/R/2045",
                "https://wyoleg.gov/Legislators/2021/R/2045",
                "https://www.wyoleg.gov/Legislators/2023/H/2045"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-56-lower-ocd-person-4338df64-c20b-4bc8-a51a-eb8cb619bbe4": 0
        }
      },
      "57": {
        "members": [
          {
            "API_ID": "ocd-person/ffda7703-f3ae-4323-919b-fd8286627286",
            "firstName": "Jeanette",
            "lastName": "Ward",
            "fullName": "Jeanette Ward",
            "gender": "Female",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-WardJeanette.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "57",
              "chamber": "lower",
              "hashID": "SLDL-WY-57-lower-ocd-person-ffda7703-f3ae-4323-919b-fd8286627286",
              "builtID": "wy-lower-57",
              "externalID": "ocd-person/ffda7703-f3ae-4323-919b-fd8286627286",
              "geometry": null
            },
            "contactInfo": {
              "email": "jeanette.ward@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2113",
                "https://www.wyoleg.gov/Legislators/2023/H/2113"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-57-lower-ocd-person-ffda7703-f3ae-4323-919b-fd8286627286": 0
        }
      },
      "58": {
        "members": [
          {
            "API_ID": "ocd-person/2f0d84dc-df29-4c3d-83ea-aa56beca9839",
            "firstName": "Bill",
            "lastName": "Allemand",
            "fullName": "Bill Allemand",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-AllemandBill.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "58",
              "chamber": "lower",
              "hashID": "SLDL-WY-58-lower-ocd-person-2f0d84dc-df29-4c3d-83ea-aa56beca9839",
              "builtID": "wy-lower-58",
              "externalID": "ocd-person/2f0d84dc-df29-4c3d-83ea-aa56beca9839",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.allemand@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2114",
                "https://www.wyoleg.gov/Legislators/2023/H/2114",
                "https://www.wyoleg.gov/Legislators/2024/H/2114"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-58-lower-ocd-person-2f0d84dc-df29-4c3d-83ea-aa56beca9839": 0
        }
      },
      "59": {
        "members": [
          {
            "API_ID": "ocd-person/5da65eaf-4523-4c60-8873-a59993ea5596",
            "firstName": "Kevin",
            "lastName": "O'Hearn",
            "fullName": "Kevin O'Hearn",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/OHearnK59.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "59",
              "chamber": "lower",
              "hashID": "SLDL-WY-59-lower-ocd-person-5da65eaf-4523-4c60-8873-a59993ea5596",
              "builtID": "wy-lower-59",
              "externalID": "ocd-person/5da65eaf-4523-4c60-8873-a59993ea5596",
              "geometry": null
            },
            "contactInfo": {
              "email": "kevin.ohearn@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2064",
                "https://wyoleg.gov/Legislators/2021/R/2064",
                "https://www.wyoleg.gov/Legislators/2023/H/2064"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-59-lower-ocd-person-5da65eaf-4523-4c60-8873-a59993ea5596": 0
        }
      },
      "60": {
        "members": [
          {
            "API_ID": "ocd-person/7be1f0fc-a31f-45b8-b686-fc580d3cb8bb",
            "firstName": "Tony",
            "lastName": "Niemiec",
            "fullName": "Tony Niemiec",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-NiemiecTony.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "60",
              "chamber": "lower",
              "hashID": "SLDL-WY-60-lower-ocd-person-7be1f0fc-a31f-45b8-b686-fc580d3cb8bb",
              "builtID": "wy-lower-60",
              "externalID": "ocd-person/7be1f0fc-a31f-45b8-b686-fc580d3cb8bb",
              "geometry": null
            },
            "contactInfo": {
              "email": "tony.niemiec@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2115",
                "https://www.wyoleg.gov/Legislators/2023/H/2115"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-60-lower-ocd-person-7be1f0fc-a31f-45b8-b686-fc580d3cb8bb": 0
        }
      },
      "61": {
        "members": [
          {
            "API_ID": "ocd-person/0a177e23-5f49-46dd-bc32-b2403ea7124c",
            "firstName": "Daniel J.",
            "lastName": "Singh",
            "fullName": "Daniel Singh",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-SinghDaniel.jpg",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "61",
              "chamber": "lower",
              "hashID": "SLDL-WY-61-lower-ocd-person-0a177e23-5f49-46dd-bc32-b2403ea7124c",
              "builtID": "wy-lower-61",
              "externalID": "ocd-person/0a177e23-5f49-46dd-bc32-b2403ea7124c",
              "geometry": null
            },
            "contactInfo": {
              "email": "daniel.singh@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2116",
                "https://www.wyoleg.gov/Legislators/2023/H/2116"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-61-lower-ocd-person-0a177e23-5f49-46dd-bc32-b2403ea7124c": 0
        }
      },
      "62": {
        "members": [
          {
            "API_ID": "ocd-person/214e2918-b5bb-48fc-b8df-9bf6311a38a0",
            "firstName": "Forrest",
            "lastName": "Chadwick",
            "fullName": "Forrest Chadwick",
            "gender": "Male",
            "party": "Republican",
            "image": "https://oilcitynews.nyc3.cdn.digitaloceanspaces.com/wp-content/uploads/2022/06/chadwick.png",
            "title": "WY Lower Chamber",
            "identityInfo": {
              "type": "SLDL",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "62",
              "chamber": "lower",
              "hashID": "SLDL-WY-62-lower-ocd-person-214e2918-b5bb-48fc-b8df-9bf6311a38a0",
              "builtID": "wy-lower-62",
              "externalID": "ocd-person/214e2918-b5bb-48fc-b8df-9bf6311a38a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "forrest.chadwick@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2117",
                "https://www.wyoleg.gov/Legislators/2023/H/2117"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDL-WY-62-lower-ocd-person-214e2918-b5bb-48fc-b8df-9bf6311a38a0": 0
        }
      }
    },
    "SLDU": {
      "1": {
        "members": [
          {
            "API_ID": "ocd-person/e72a31cf-cefd-42c1-9620-967af32a2f48",
            "firstName": "Ogden O.",
            "lastName": "Driskill",
            "fullName": "Ogden Driskill",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/photos/DriskillS1.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "1",
              "chamber": "upper",
              "hashID": "SLDU-WY-1-upper-ocd-person-e72a31cf-cefd-42c1-9620-967af32a2f48",
              "builtID": "wy-upper-1",
              "externalID": "ocd-person/e72a31cf-cefd-42c1-9620-967af32a2f48",
              "geometry": null
            },
            "contactInfo": {
              "email": "ogden.driskill@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/1972",
                "https://wyoleg.gov/Legislators/2021/R/1972",
                "https://wyoleg.gov/Legislators/2021/R/1972",
                "https://www.wyoleg.gov/Legislators/2023/S/1972"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-1-upper-ocd-person-e72a31cf-cefd-42c1-9620-967af32a2f48": 0
        }
      },
      "2": {
        "members": [
          {
            "API_ID": "ocd-person/cfed8eb6-ad00-4414-9ceb-bd5d46c5974f",
            "firstName": "Brian",
            "lastName": "Boner",
            "fullName": "Brian Boner",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-BonerBrian.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "2",
              "chamber": "upper",
              "hashID": "SLDU-WY-2-upper-ocd-person-cfed8eb6-ad00-4414-9ceb-bd5d46c5974f",
              "builtID": "wy-upper-2",
              "externalID": "ocd-person/cfed8eb6-ad00-4414-9ceb-bd5d46c5974f",
              "geometry": null
            },
            "contactInfo": {
              "email": "brian.boner@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2021",
                "https://wyoleg.gov/Legislators/2021/R/2021",
                "https://wyoleg.gov/Legislators/2021/R/2021",
                "https://www.wyoleg.gov/Legislators/2023/S/2021"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-2-upper-ocd-person-cfed8eb6-ad00-4414-9ceb-bd5d46c5974f": 0
        }
      },
      "3": {
        "members": [
          {
            "API_ID": "ocd-person/9fe08ab5-b31c-4f7b-bb23-c7aff3cd3e4d",
            "firstName": "Cheri E.",
            "lastName": "Steinmetz",
            "fullName": "Cheri Steinmetz",
            "gender": "Female",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-SteinmetzCheri.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "3",
              "chamber": "upper",
              "hashID": "SLDU-WY-3-upper-ocd-person-9fe08ab5-b31c-4f7b-bb23-c7aff3cd3e4d",
              "builtID": "wy-upper-3",
              "externalID": "ocd-person/9fe08ab5-b31c-4f7b-bb23-c7aff3cd3e4d",
              "geometry": null
            },
            "contactInfo": {
              "email": "cheri.steinmetz@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2011",
                "https://wyoleg.gov/Legislators/2021/R/2011",
                "https://wyoleg.gov/Legislators/2021/R/2011",
                "https://www.wyoleg.gov/Legislators/2023/S/2011"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-3-upper-ocd-person-9fe08ab5-b31c-4f7b-bb23-c7aff3cd3e4d": 0
        }
      },
      "4": {
        "members": [
          {
            "API_ID": "ocd-person/f20c9c17-f2bd-4c88-9f6f-208af6a61f26",
            "firstName": "Tara B.",
            "lastName": "Nethercott",
            "fullName": "Tara Nethercott",
            "gender": "Female",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-NethercottTara.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "4",
              "chamber": "upper",
              "hashID": "SLDU-WY-4-upper-ocd-person-f20c9c17-f2bd-4c88-9f6f-208af6a61f26",
              "builtID": "wy-upper-4",
              "externalID": "ocd-person/f20c9c17-f2bd-4c88-9f6f-208af6a61f26",
              "geometry": null
            },
            "contactInfo": {
              "email": "tara.nethercott@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2029",
                "https://wyoleg.gov/Legislators/2021/R/2029",
                "https://wyoleg.gov/Legislators/2021/R/2029",
                "https://www.wyoleg.gov/Legislators/2023/S/2029"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-4-upper-ocd-person-f20c9c17-f2bd-4c88-9f6f-208af6a61f26": 0
        }
      },
      "5": {
        "members": [
          {
            "API_ID": "ocd-person/b91459e7-2049-4cf9-8b0b-f769ce373613",
            "firstName": "Lynn",
            "lastName": "Hutchings",
            "fullName": "Lynn Hutchings",
            "gender": "Female",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-HutchingsLynn.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "5",
              "chamber": "upper",
              "hashID": "SLDU-WY-5-upper-ocd-person-b91459e7-2049-4cf9-8b0b-f769ce373613",
              "builtID": "wy-upper-5",
              "externalID": "ocd-person/b91459e7-2049-4cf9-8b0b-f769ce373613",
              "geometry": null
            },
            "contactInfo": {
              "email": "lynn.hutchings@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/1997",
                "https://wyoleg.gov/Legislators/2021/R/1997",
                "https://wyoleg.gov/Legislators/2021/R/1997",
                "https://www.wyoleg.gov/Legislators/2023/S/1997"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-5-upper-ocd-person-b91459e7-2049-4cf9-8b0b-f769ce373613": 0
        }
      },
      "6": {
        "members": [
          {
            "API_ID": "ocd-person/35a75333-f3ab-4460-96fd-33c8e17813ea",
            "firstName": "Anthony",
            "lastName": "Bouchard",
            "fullName": "Anthony Bouchard",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-BouchardAnthony.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "6",
              "chamber": "upper",
              "hashID": "SLDU-WY-6-upper-ocd-person-35a75333-f3ab-4460-96fd-33c8e17813ea",
              "builtID": "wy-upper-6",
              "externalID": "ocd-person/35a75333-f3ab-4460-96fd-33c8e17813ea",
              "geometry": null
            },
            "contactInfo": {
              "email": "anthony.bouchard@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2030",
                "https://wyoleg.gov/Legislators/2021/R/2030",
                "https://wyoleg.gov/Legislators/2021/R/2030",
                "https://www.wyoleg.gov/Legislators/2023/S/2030"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-6-upper-ocd-person-35a75333-f3ab-4460-96fd-33c8e17813ea": 0
        }
      },
      "7": {
        "members": [
          {
            "API_ID": "ocd-person/f46abbfe-3a0a-4657-a4ac-fbc418adfcc7",
            "firstName": "Stephan",
            "lastName": "Pappas",
            "fullName": "Stephan Pappas",
            "gender": "Male",
            "party": "Republican",
            "image": "http://legisweb.state.wy.us/LegislatorSummary/photos/pappassS07.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "7",
              "chamber": "upper",
              "hashID": "SLDU-WY-7-upper-ocd-person-f46abbfe-3a0a-4657-a4ac-fbc418adfcc7",
              "builtID": "wy-upper-7",
              "externalID": "ocd-person/f46abbfe-3a0a-4657-a4ac-fbc418adfcc7",
              "geometry": null
            },
            "contactInfo": {
              "email": "stephan.pappas@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2008",
                "https://wyoleg.gov/Legislators/2021/R/2008",
                "https://wyoleg.gov/Legislators/2021/R/2008",
                "https://www.wyoleg.gov/Legislators/2023/S/2008"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-7-upper-ocd-person-f46abbfe-3a0a-4657-a4ac-fbc418adfcc7": 0
        }
      },
      "8": {
        "members": [
          {
            "API_ID": "ocd-person/e45f8a3f-31ff-41af-8865-78dee30a7987",
            "firstName": "Affie Burnside",
            "lastName": "Ellis",
            "fullName": "Affie Ellis",
            "gender": "Female",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-EllisAffie.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "8",
              "chamber": "upper",
              "hashID": "SLDU-WY-8-upper-ocd-person-e45f8a3f-31ff-41af-8865-78dee30a7987",
              "builtID": "wy-upper-8",
              "externalID": "ocd-person/e45f8a3f-31ff-41af-8865-78dee30a7987",
              "geometry": null
            },
            "contactInfo": {
              "email": "affie.ellis@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2031",
                "https://wyoleg.gov/Legislators/2021/R/2031",
                "https://wyoleg.gov/Legislators/2021/R/2031",
                "https://www.wyoleg.gov/Legislators/2023/S/2031"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-8-upper-ocd-person-e45f8a3f-31ff-41af-8865-78dee30a7987": 0
        }
      },
      "9": {
        "members": [
          {
            "API_ID": "ocd-person/510546dd-c3ca-4ce4-9342-f77e05712f2e",
            "firstName": "Christopher J.",
            "lastName": "Rothfuss",
            "fullName": "Chris Rothfuss",
            "gender": "Male",
            "party": "Democrat",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-RothfussChris.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "9",
              "chamber": "upper",
              "hashID": "SLDU-WY-9-upper-ocd-person-510546dd-c3ca-4ce4-9342-f77e05712f2e",
              "builtID": "wy-upper-9",
              "externalID": "ocd-person/510546dd-c3ca-4ce4-9342-f77e05712f2e",
              "geometry": null
            },
            "contactInfo": {
              "email": "chris.rothfuss@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/D/1971",
                "https://wyoleg.gov/Legislators/2021/D/1971",
                "https://wyoleg.gov/Legislators/2021/D/1971",
                "https://www.wyoleg.gov/Legislators/2023/S/1971"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-9-upper-ocd-person-510546dd-c3ca-4ce4-9342-f77e05712f2e": 0
        }
      },
      "10": {
        "members": [
          {
            "API_ID": "ocd-person/b9d3820d-6368-41c3-8730-b287ff78f49e",
            "firstName": "Dan",
            "lastName": "Furphy",
            "fullName": "Dan Furphy",
            "gender": "Male",
            "party": "Republican",
            "image": "http://legisweb.state.wy.us/LegislatorSummary/photos/FurphyDH14.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "10",
              "chamber": "upper",
              "hashID": "SLDU-WY-10-upper-ocd-person-b9d3820d-6368-41c3-8730-b287ff78f49e",
              "builtID": "wy-upper-10",
              "externalID": "ocd-person/b9d3820d-6368-41c3-8730-b287ff78f49e",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.furphy@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2036",
                "https://wyoleg.gov/Legislators/2021/R/2036",
                "https://wyoleg.gov/Legislators/2021/R/2036",
                "https://www.wyoleg.gov/Legislators/2023/S/2036"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-10-upper-ocd-person-b9d3820d-6368-41c3-8730-b287ff78f49e": 0
        }
      },
      "11": {
        "members": [
          {
            "API_ID": "ocd-person/286af01e-ace3-4de2-b1c3-44756370d932",
            "firstName": "Larry S.",
            "lastName": "Hicks",
            "fullName": "Larry Hicks",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-HicksLarry.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "11",
              "chamber": "upper",
              "hashID": "SLDU-WY-11-upper-ocd-person-286af01e-ace3-4de2-b1c3-44756370d932",
              "builtID": "wy-upper-11",
              "externalID": "ocd-person/286af01e-ace3-4de2-b1c3-44756370d932",
              "geometry": null
            },
            "contactInfo": {
              "email": "larry.hicks@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/1963",
                "https://wyoleg.gov/Legislators/2021/R/1963",
                "https://wyoleg.gov/Legislators/2021/R/1963",
                "https://www.wyoleg.gov/Legislators/2023/S/1963"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-11-upper-ocd-person-286af01e-ace3-4de2-b1c3-44756370d932": 0
        }
      },
      "12": {
        "members": [
          {
            "API_ID": "ocd-person/a8a05d47-8306-4f20-a848-02c92db1fffe",
            "firstName": "John K.",
            "lastName": "Kolb",
            "fullName": "John Kolb",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-KolbJohn.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "12",
              "chamber": "upper",
              "hashID": "SLDU-WY-12-upper-ocd-person-a8a05d47-8306-4f20-a848-02c92db1fffe",
              "builtID": "wy-upper-12",
              "externalID": "ocd-person/a8a05d47-8306-4f20-a848-02c92db1fffe",
              "geometry": null
            },
            "contactInfo": {
              "email": "john.kolb@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2066",
                "https://wyoleg.gov/Legislators/2021/R/2066",
                "https://www.wyoleg.gov/Legislators/2023/S/2066"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-12-upper-ocd-person-a8a05d47-8306-4f20-a848-02c92db1fffe": 0
        }
      },
      "13": {
        "members": [
          {
            "API_ID": "ocd-person/343a76eb-5672-433a-9d39-f34b6f683cab",
            "firstName": "Stacy",
            "lastName": "Jones",
            "fullName": "Stacy Jones",
            "gender": "Female",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-JonesStacy.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "13",
              "chamber": "upper",
              "hashID": "SLDU-WY-13-upper-ocd-person-343a76eb-5672-433a-9d39-f34b6f683cab",
              "builtID": "wy-upper-13",
              "externalID": "ocd-person/343a76eb-5672-433a-9d39-f34b6f683cab",
              "geometry": null
            },
            "contactInfo": {
              "email": "stacy.jones@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2088",
                "https://www.wyoleg.gov/Legislators/2023/S/2088"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-13-upper-ocd-person-343a76eb-5672-433a-9d39-f34b6f683cab": 0
        }
      },
      "14": {
        "members": [
          {
            "API_ID": "ocd-person/d3aae123-a4f8-4882-9313-ba6a400c43fb",
            "firstName": "Fred A.",
            "lastName": "Baldwin",
            "fullName": "Fred Baldwin",
            "gender": "Male",
            "party": "Republican",
            "image": "http://legisweb.state.wy.us/LegislatorSummary/photos/BaldwinFS14.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "14",
              "chamber": "upper",
              "hashID": "SLDU-WY-14-upper-ocd-person-d3aae123-a4f8-4882-9313-ba6a400c43fb",
              "builtID": "wy-upper-14",
              "externalID": "ocd-person/d3aae123-a4f8-4882-9313-ba6a400c43fb",
              "geometry": null
            },
            "contactInfo": {
              "email": "fred.baldwin@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2007",
                "https://wyoleg.gov/Legislators/2021/R/2007",
                "https://wyoleg.gov/Legislators/2021/R/2007",
                "https://www.wyoleg.gov/Legislators/2023/S/2007"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-14-upper-ocd-person-d3aae123-a4f8-4882-9313-ba6a400c43fb": 0
        }
      },
      "15": {
        "members": [
          {
            "API_ID": "ocd-person/6dd13000-c423-47d8-9fbb-3cbcf730d7fe",
            "firstName": "Wendy Davis",
            "lastName": "Schuler",
            "fullName": "Wendy Schuler",
            "gender": "Female",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-SchulerWendyDavis.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "15",
              "chamber": "upper",
              "hashID": "SLDU-WY-15-upper-ocd-person-6dd13000-c423-47d8-9fbb-3cbcf730d7fe",
              "builtID": "wy-upper-15",
              "externalID": "ocd-person/6dd13000-c423-47d8-9fbb-3cbcf730d7fe",
              "geometry": null
            },
            "contactInfo": {
              "email": "wendy.schuler@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2062",
                "https://wyoleg.gov/Legislators/2021/R/2062",
                "https://wyoleg.gov/Legislators/2021/R/2062",
                "https://www.wyoleg.gov/Legislators/2023/S/2062"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-15-upper-ocd-person-6dd13000-c423-47d8-9fbb-3cbcf730d7fe": 0
        }
      },
      "16": {
        "members": [
          {
            "API_ID": "ocd-person/5395223c-9b56-410d-b18f-978d9e2a07a9",
            "firstName": "Daniel K.",
            "lastName": "Dockstader",
            "fullName": "Dan Dockstader",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-DockstaderDan.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "16",
              "chamber": "upper",
              "hashID": "SLDU-WY-16-upper-ocd-person-5395223c-9b56-410d-b18f-978d9e2a07a9",
              "builtID": "wy-upper-16",
              "externalID": "ocd-person/5395223c-9b56-410d-b18f-978d9e2a07a9",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.dockstader@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/1048",
                "https://wyoleg.gov/Legislators/2021/R/1048",
                "https://wyoleg.gov/Legislators/2021/R/1048",
                "https://www.wyoleg.gov/Legislators/2023/S/1048"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-16-upper-ocd-person-5395223c-9b56-410d-b18f-978d9e2a07a9": 0
        }
      },
      "17": {
        "members": [
          {
            "API_ID": "ocd-person/587874e4-f0c9-4679-aa08-a2442b7d33b1",
            "firstName": "Mike",
            "lastName": "Gierau",
            "fullName": "Mike Gierau",
            "gender": "Male",
            "party": "Democrat",
            "image": "http://legisweb.state.wy.us/LegislatorSummary/photos/GierauMH16.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "17",
              "chamber": "upper",
              "hashID": "SLDU-WY-17-upper-ocd-person-587874e4-f0c9-4679-aa08-a2442b7d33b1",
              "builtID": "wy-upper-17",
              "externalID": "ocd-person/587874e4-f0c9-4679-aa08-a2442b7d33b1",
              "geometry": null
            },
            "contactInfo": {
              "email": "mike.gierau@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/D/2027",
                "https://wyoleg.gov/Legislators/2021/D/2027",
                "https://wyoleg.gov/Legislators/2021/D/2027",
                "https://www.wyoleg.gov/Legislators/2023/S/2027"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-17-upper-ocd-person-587874e4-f0c9-4679-aa08-a2442b7d33b1": 0
        }
      },
      "18": {
        "members": [
          {
            "API_ID": "ocd-person/057afdb5-3c0e-4503-b6cd-50ba6df1aaab",
            "firstName": "Tim",
            "lastName": "French",
            "fullName": "Tim French",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-FrenchTim.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "18",
              "chamber": "upper",
              "hashID": "SLDU-WY-18-upper-ocd-person-057afdb5-3c0e-4503-b6cd-50ba6df1aaab",
              "builtID": "wy-upper-18",
              "externalID": "ocd-person/057afdb5-3c0e-4503-b6cd-50ba6df1aaab",
              "geometry": null
            },
            "contactInfo": {
              "email": "tim.french@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2067",
                "https://wyoleg.gov/Legislators/2021/R/2067",
                "https://www.wyoleg.gov/Legislators/2023/S/2067"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-18-upper-ocd-person-057afdb5-3c0e-4503-b6cd-50ba6df1aaab": 0
        }
      },
      "19": {
        "members": [
          {
            "API_ID": "ocd-person/982cdb50-2a81-47b4-97e8-cf80b92d1412",
            "firstName": "Dan",
            "lastName": "Laursen",
            "fullName": "Dan Laursen",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-LaursenDan.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "19",
              "chamber": "upper",
              "hashID": "SLDU-WY-19-upper-ocd-person-982cdb50-2a81-47b4-97e8-cf80b92d1412",
              "builtID": "wy-upper-19",
              "externalID": "ocd-person/982cdb50-2a81-47b4-97e8-cf80b92d1412",
              "geometry": null
            },
            "contactInfo": {
              "email": "dan.laursen@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2006",
                "https://www.wyoleg.gov/Legislators/2023/S/2006"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-19-upper-ocd-person-982cdb50-2a81-47b4-97e8-cf80b92d1412": 0
        }
      },
      "20": {
        "members": [
          {
            "API_ID": "ocd-person/13022d64-c748-4603-b60e-bd176383b8f1",
            "firstName": "Edward",
            "lastName": "Cooper",
            "fullName": "Ed Cooper",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-CooperEd.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "20",
              "chamber": "upper",
              "hashID": "SLDU-WY-20-upper-ocd-person-13022d64-c748-4603-b60e-bd176383b8f1",
              "builtID": "wy-upper-20",
              "externalID": "ocd-person/13022d64-c748-4603-b60e-bd176383b8f1",
              "geometry": null
            },
            "contactInfo": {
              "email": "ed.cooper@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2068",
                "https://wyoleg.gov/Legislators/2021/R/2068",
                "https://www.wyoleg.gov/Legislators/2023/S/2068"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-20-upper-ocd-person-13022d64-c748-4603-b60e-bd176383b8f1": 0
        }
      },
      "21": {
        "members": [
          {
            "API_ID": "ocd-person/016465da-4d49-4ffc-8bb0-cf9528bc6d0e",
            "firstName": "Bo",
            "lastName": "Biteman",
            "fullName": "Bo Biteman",
            "gender": "Male",
            "party": "Republican",
            "image": "http://legisweb.state.wy.us/LegislatorSummary/photos/BitemanDH51.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "21",
              "chamber": "upper",
              "hashID": "SLDU-WY-21-upper-ocd-person-016465da-4d49-4ffc-8bb0-cf9528bc6d0e",
              "builtID": "wy-upper-21",
              "externalID": "ocd-person/016465da-4d49-4ffc-8bb0-cf9528bc6d0e",
              "geometry": null
            },
            "contactInfo": {
              "email": "bo.biteman@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2044",
                "https://wyoleg.gov/Legislators/2021/R/2044",
                "https://wyoleg.gov/Legislators/2021/R/2044",
                "https://www.wyoleg.gov/Legislators/2018/H/2044",
                "https://www.wyoleg.gov/Legislators/2023/S/2044"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-21-upper-ocd-person-016465da-4d49-4ffc-8bb0-cf9528bc6d0e": 0
        }
      },
      "22": {
        "members": [
          {
            "API_ID": "ocd-person/6897cf2e-ec26-4a21-b88f-fcff48cb7557",
            "firstName": "Dave",
            "lastName": "Kinskey",
            "fullName": "Dave Kinskey",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-KinskeyDave.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "22",
              "chamber": "upper",
              "hashID": "SLDU-WY-22-upper-ocd-person-6897cf2e-ec26-4a21-b88f-fcff48cb7557",
              "builtID": "wy-upper-22",
              "externalID": "ocd-person/6897cf2e-ec26-4a21-b88f-fcff48cb7557",
              "geometry": null
            },
            "contactInfo": {
              "email": "dave.kinskey@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2003",
                "https://wyoleg.gov/Legislators/2021/R/2003",
                "https://wyoleg.gov/Legislators/2021/R/2003",
                "https://www.wyoleg.gov/Legislators/2023/S/2003"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-22-upper-ocd-person-6897cf2e-ec26-4a21-b88f-fcff48cb7557": 0
        }
      },
      "23": {
        "members": [
          {
            "API_ID": "ocd-person/afa67bb0-3191-4dce-94ab-ddb3a9e7475c",
            "firstName": "Eric",
            "lastName": "Barlow",
            "fullName": "Eric Barlow",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-BarlowEric.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "23",
              "chamber": "upper",
              "hashID": "SLDU-WY-23-upper-ocd-person-afa67bb0-3191-4dce-94ab-ddb3a9e7475c",
              "builtID": "wy-upper-23",
              "externalID": "ocd-person/afa67bb0-3191-4dce-94ab-ddb3a9e7475c",
              "geometry": null
            },
            "contactInfo": {
              "email": "eric.barlow@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/1995",
                "https://www.wyoleg.gov/Legislators/2023/S/1995"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-23-upper-ocd-person-afa67bb0-3191-4dce-94ab-ddb3a9e7475c": 0
        }
      },
      "24": {
        "members": [
          {
            "API_ID": "ocd-person/c1525bf9-8a43-4807-b72a-e42f94267b09",
            "firstName": "Troy",
            "lastName": "McKeown",
            "fullName": "Troy McKeown",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-McKeownTroy.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "24",
              "chamber": "upper",
              "hashID": "SLDU-WY-24-upper-ocd-person-c1525bf9-8a43-4807-b72a-e42f94267b09",
              "builtID": "wy-upper-24",
              "externalID": "ocd-person/c1525bf9-8a43-4807-b72a-e42f94267b09",
              "geometry": null
            },
            "contactInfo": {
              "email": "troy.mckeown@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2069",
                "https://wyoleg.gov/Legislators/2021/R/2069",
                "https://www.wyoleg.gov/Legislators/2023/S/2069"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-24-upper-ocd-person-c1525bf9-8a43-4807-b72a-e42f94267b09": 0
        }
      },
      "25": {
        "members": [
          {
            "API_ID": "ocd-person/0b07cbe8-73ba-4371-9acb-95cb7ecf6251",
            "firstName": "Cale",
            "lastName": "Case",
            "fullName": "Cale Case",
            "gender": "Male",
            "party": "Republican",
            "image": "http://legisweb.state.wy.us/LegislatorSummary/photos/CaseCS25.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "25",
              "chamber": "upper",
              "hashID": "SLDU-WY-25-upper-ocd-person-0b07cbe8-73ba-4371-9acb-95cb7ecf6251",
              "builtID": "wy-upper-25",
              "externalID": "ocd-person/0b07cbe8-73ba-4371-9acb-95cb7ecf6251",
              "geometry": null
            },
            "contactInfo": {
              "email": "cale.case@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/945",
                "https://wyoleg.gov/Legislators/2021/R/945",
                "https://wyoleg.gov/Legislators/2021/R/945",
                "https://www.wyoleg.gov/Legislators/2023/S/945"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-25-upper-ocd-person-0b07cbe8-73ba-4371-9acb-95cb7ecf6251": 0
        }
      },
      "26": {
        "members": [
          {
            "API_ID": "ocd-person/4f702d02-54ad-4143-837a-09be9a03ee6e",
            "firstName": "Tim",
            "lastName": "Salazar",
            "fullName": "Tim Salazar",
            "gender": "Male",
            "party": "Republican",
            "image": "http://legisweb.state.wy.us/LegislatorSummary/photos/SalazarTH34.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "26",
              "chamber": "upper",
              "hashID": "SLDU-WY-26-upper-ocd-person-4f702d02-54ad-4143-837a-09be9a03ee6e",
              "builtID": "wy-upper-26",
              "externalID": "ocd-person/4f702d02-54ad-4143-837a-09be9a03ee6e",
              "geometry": null
            },
            "contactInfo": {
              "email": "tim.salazar@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/2028",
                "https://wyoleg.gov/Legislators/2021/R/2028",
                "https://www.wyoleg.gov/Legislators/2023/S/2028"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-26-upper-ocd-person-4f702d02-54ad-4143-837a-09be9a03ee6e": 0
        }
      },
      "27": {
        "members": [
          {
            "API_ID": "ocd-person/e0c25487-a7fb-406b-b637-c3a4de3c6770",
            "firstName": "William R.",
            "lastName": "Landen",
            "fullName": "Bill Landen",
            "gender": "Male",
            "party": "Republican",
            "image": "http://legisweb.state.wy.us/LegislatorSummary/photos/LandenBS27.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "27",
              "chamber": "upper",
              "hashID": "SLDU-WY-27-upper-ocd-person-e0c25487-a7fb-406b-b637-c3a4de3c6770",
              "builtID": "wy-upper-27",
              "externalID": "ocd-person/e0c25487-a7fb-406b-b637-c3a4de3c6770",
              "geometry": null
            },
            "contactInfo": {
              "email": "bill.landen@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/434",
                "https://wyoleg.gov/Legislators/2021/R/434",
                "https://wyoleg.gov/Legislators/2021/R/434",
                "https://www.wyoleg.gov/Legislators/2023/S/434"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-27-upper-ocd-person-e0c25487-a7fb-406b-b637-c3a4de3c6770": 0
        }
      },
      "28": {
        "members": [
          {
            "API_ID": "ocd-person/f40c41d5-b371-49c5-a597-db8435e613a0",
            "firstName": "James Lee",
            "lastName": "Anderson",
            "fullName": "Jim Anderson",
            "gender": "Male",
            "party": "Republican",
            "image": "http://legisweb.state.wy.us/LegislatorSummary/photos/AndersonS28.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "28",
              "chamber": "upper",
              "hashID": "SLDU-WY-28-upper-ocd-person-f40c41d5-b371-49c5-a597-db8435e613a0",
              "builtID": "wy-upper-28",
              "externalID": "ocd-person/f40c41d5-b371-49c5-a597-db8435e613a0",
              "geometry": null
            },
            "contactInfo": {
              "email": "jim.anderson@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/1985",
                "https://wyoleg.gov/Legislators/2021/R/1985",
                "https://wyoleg.gov/Legislators/2021/R/1985",
                "https://www.wyoleg.gov/Legislators/2023/S/1985"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-28-upper-ocd-person-f40c41d5-b371-49c5-a597-db8435e613a0": 0
        }
      },
      "29": {
        "members": [
          {
            "API_ID": "ocd-person/6d1c2665-46ea-444d-b88b-b22e2623e660",
            "firstName": "Robert",
            "lastName": "Ide",
            "fullName": "Bob Ide",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-IdeBob.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "29",
              "chamber": "upper",
              "hashID": "SLDU-WY-29-upper-ocd-person-6d1c2665-46ea-444d-b88b-b22e2623e660",
              "builtID": "wy-upper-29",
              "externalID": "ocd-person/6d1c2665-46ea-444d-b88b-b22e2623e660",
              "geometry": null
            },
            "contactInfo": {
              "email": "bob.ide@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2089",
                "https://www.wyoleg.gov/Legislators/2023/S/2089"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-29-upper-ocd-person-6d1c2665-46ea-444d-b88b-b22e2623e660": 0
        }
      },
      "30": {
        "members": [
          {
            "API_ID": "ocd-person/a3ddc445-efa1-4c0c-809a-d21fc383fa59",
            "firstName": "Charles K.",
            "lastName": "Scott",
            "fullName": "Charles Scott",
            "gender": "Male",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/66th-ScottCharles.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "30",
              "chamber": "upper",
              "hashID": "SLDU-WY-30-upper-ocd-person-a3ddc445-efa1-4c0c-809a-d21fc383fa59",
              "builtID": "wy-upper-30",
              "externalID": "ocd-person/a3ddc445-efa1-4c0c-809a-d21fc383fa59",
              "geometry": null
            },
            "contactInfo": {
              "email": "charles.scott@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2019/R/294",
                "https://wyoleg.gov/Legislators/2021/R/294",
                "https://wyoleg.gov/Legislators/2021/R/294",
                "https://www.wyoleg.gov/Legislators/2023/S/294"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-30-upper-ocd-person-a3ddc445-efa1-4c0c-809a-d21fc383fa59": 0
        }
      },
      "31": {
        "members": [
          {
            "API_ID": "ocd-person/7f287d7d-8f05-4116-8bcf-33174ecf1e9c",
            "firstName": "Evie",
            "lastName": "Brennan",
            "fullName": "Evie Brennan",
            "gender": "Female",
            "party": "Republican",
            "image": "https://wyoleg.gov/LegislatorSummary/Photos/67th-BrennanEvie.jpg",
            "title": "WY Upper Chamber",
            "identityInfo": {
              "type": "SLDU",
              "stateAbbrev": "WY",
              "stateFull": "Wyoming",
              "district": "31",
              "chamber": "upper",
              "hashID": "SLDU-WY-31-upper-ocd-person-7f287d7d-8f05-4116-8bcf-33174ecf1e9c",
              "builtID": "wy-upper-31",
              "externalID": "ocd-person/7f287d7d-8f05-4116-8bcf-33174ecf1e9c",
              "geometry": null
            },
            "contactInfo": {
              "email": "evie.brennan@wyoleg.gov",
              "contactForm": null,
              "address1": null,
              "address2": null,
              "phone1": null,
              "phone2": null,
              "fax1": null,
              "fax2": null,
              "twitter": null,
              "youtube": null,
              "instagram": null,
              "facebook": null,
              "urls": [
                "https://wyoleg.gov/Legislators/2021/R/2090",
                "https://www.wyoleg.gov/Legislators/2023/S/2090"
              ],
              "rss_url": null
            }
          }
        ],
        "hash": {
          "SLDU-WY-31-upper-ocd-person-7f287d7d-8f05-4116-8bcf-33174ecf1e9c": 0
        }
      }
    }
  }
}
